import { buildingWhite, groupWhite, hospitalWhite, toolsWhite, trafficWhite, truckWhite } from "@assets/images/pricing";

export default {

    title : '견적 확인',
    link : '/pricing/1',


    step1 : {
        title : '어떤 산업에 관심이 있으신가요?',
        percent : '0%',
        common : '적용 사례',
        industry : [
            {
                title : '건설',
                example : '작업자 안전관리, 자재 관리, 출입 관리, 환경 관리 등',
                icon : trafficWhite
            },
            {
                title : '물류',
                example : '재고관리, 안전관리, 환경 관리, 출입 관리 등',
                icon : truckWhite
            },
            {
                title : '병원',
                example : '출입 관리, 환자 위치추적, 의료폐기물관리, 공기품질 관리 등',
                icon : hospitalWhite
            },
            {
                title : '오피스',
                example : '출입관리, 자산관리, 공기품질 관리 등',
                icon : buildingWhite
            },
            {
                title : '제조',
                example : '출입관리, 재고관리, 공기 품질 관리, 공정관리 등',
                icon : toolsWhite
            },
            {
                title : '그 외',
                example : '리테일, 항공, 항만, 유동인구 분석, 주차 관제, 공기품질 관리 등',
                icon : groupWhite
            }
        ],
        next : '다음'

    },

    step2 : {
        title : '어떤 제품에 관심이 있으신가요?',
        percent : '25%',
        common : '더 알아보기',
        product : [
            {
                title : '비콘 단말기',
                example : '방수와 방진에 강한, 산업 현장에서 검증된 비콘 단말기 입니다.'
            },
            {
                title : '실시간 위치 추적',
                example : '자산, 작업자에 태그를 부착하여 정확하고 빠른 실시간 위치 추적이 가능합니다.'
            },
            {
                title : '영상 위치추적',
                example : '인공지능 카메라를 통해서 사람이나 차량 등을 추적하고 분석 및 관리합니다.'
            },
            {
                title : '출입 시스템',
                example : '스마트하게 건물의 출입을 관리하고 운영합니다. 빠르고 동시에 안전합니다.'
            },
            {
                title : '산업용 센서',
                example : '온도, 습도, 압력, 미세먼지, 일산화탄소 등 현장의 환경 상태를 파악합니다.'
            },
            {
                title : '디지털 트윈',
                example : '공간의 모든 데이터를 수집하여 가상 현실을 구축합니다.'
            },
        ],
        prev : '이전',
        next : '다음'

    },


    step3 : {
        title : '아래 항목을 확인해 주세요.',
        percent : '50%',
        question : [
            
            //비콘 단말기
            {
                
                title : '비콘의 사용 목적과 수량이 어떻게 되나요?',
                parts : [
                    {
                        title : '사용 목적',
                        type : 'choice2',
                        option : [
                            {text : '단순 구매'},
                            {text : '연구 또는 시범사업'},
                            {text : '솔루션 도입 (서비스 활용)'},
                            {text : '기타'},
                        ]
                    }, {
                        title : '예상 수량',
                        type : 'choice1',
                        option : [
                            {text : '1 ~ 1,000개'},
                            {text : '1,000 ~ 2,000개'},
                            {text : '2,000 ~ 5,000개'},
                            {text : '5,000개 이상'},
                        ]
                    },
                ]
            },
            
            //전파 위치추적
            {
                title : '위치추적의 정확도와 도입 현장의 면적은 어느정도 되나요?',
                parts : [
                    {
                        title : '정확도',
                        type : 'choice1',
                        option : [
                            {text : '자산추적 (<5m이내 범위로 자산 추적)'},
                            {text : '고정밀 추적 (<50cm이내 범위로 자산 추적)'},
                            {text : '기타'},
                        ]
                    },
                    {
                        title : '현장 면적',
                        type : 'choice1',
                        option : [
                            {text : '0 ~ 100평'},
                            {text : '100 ~ 500평'},
                            {text : '500평 이상'},
                            {text : '잘 모름'},
                        ]
                    },
                ]

            },
            //영상 위치추적
            {
                title : '카메라 추적 대상과 설치 장소는 어디인가요?',
                parts : [
                    {
                        title : '추적 대상',
                        type : 'choice3',
                        option : [
                            {text : '사람'},
                            {text : '차량'},
                            {text : '기타'},
                        ]
                    },
                    {
                        title : '설치 장소',
                        type : 'choice3',
                        option : [
                            {text : '실내에 설치'},
                            {text : '실외에 설치'},
                            {text : '잘 모름'},
                        ]
                    },
                ]

            },
            //출입 시스템
            {
                title : '출입문의 장소와 개수는 몇개인가요?',
                parts : [
                    {
                        title : '출입문 장소',
                        type : 'choice3',
                        option : [
                            {text : '아파트'},
                            {text : '오피스 및 상가'},
                            {text : '기타'},
                        ]
                    },
                    {
                        title : '출입문 개수',
                        type : 'choice1',
                        option : [
                            {text : '1 ~ 10개'},
                            {text : '10 ~ 20개'},
                            {text : '20 ~ 50개'},
                            {text : '50개 이상'},
                        ]
                    },
                ]

            },
            //산업용 센서
            {
                
                title : '어떤 센서가 필요하신가요?',
                parts : [
                    {
                        title : '',
                        type : 'multichoice2',
                        option : [
                            {text : '비상벨'},
                            {text : '화재 감지'},
                            {text : '온/습도'},
                            {text : '누수 감지'},
                            {text : '차압'},
                            {text : '침입 감지'},
                            {text : '공기 품질'},
                            {text : '일산화탄소 감지'},
                        ]
                    },
                ]

            },
            //사물 인터넷
            {
                
                title : ' ',
                parts : [
                    {
                        title : ' ',
                        type : ' ',
                        option : [
                            {text : ' '},
                        ]
                    }, 
                ]
            },


        ],
        prev : '이전',
        next : '다음'

    },

    step4 : {
        title : '연락 가능한 이메일을 적어주세요.',
        percent : '75%',
        email : '이메일',
        emailHolder : 'example@orbro.io',
        


        prev : '이전',
        next : '다음'

    },


    step5 : {
        title : '담당자를 통해 연락 드리겠습니다.',
        desc : '상담에 필요한 정보를 입력해 주시면 응대에 도움이 됩니다.',

        name : '이름',
        nameHolder : '홍길동',

        phone : '전화번호',
        phoneHolder : '010-1234-5678',

        memo : '메모',
        memoHolder : '정보를 입력해주세요.',

        

        prev : '이메일로 정보 받기',
        next : '추가정보 제출하기'

    },

    step6 : {
        title : '완료 되었습니다',
        
        desc : '빠른 시일내에 확인 후 연락 드리도록 하겠습니다.<br/>전문 엔지니어의 전화 상담이 필요하시면 상담 예약을 진행해 주세요.',
        
        btn : '전화 상담 예약하기'
        

    },

    schedule : {
        title : '통화가능한 시간을 입력해 주세요.',
        company : '소속',
        companyHolder : '오브로',
        name : '성함',
        nameHolder : '홍길동',

        date : '날짜',
        time : '시간',
        submit : '제출하기',

        morning : '오전',
        noon : '오후',
    }

}