import {
    access2off, access3off, access4off,
    access2on, access3on, access4on,
} from '@assets/images/main/index';
import { BlueIconPhone, BlueIconRFID, BlueIconRemote, BlueIconWatch, BlueIconWidget } from "@assets/images/icon/blue";
import {
    AdvantageImgD, AdvantageImgM, AdvantageImgT,
    Falldown1D, Falldown1M, Falldown1T,
    Falldown2D, Falldown2M, Falldown2T,
    Falldown3D, Falldown3M, Falldown3T,

    installationImg1D, installationImg1M, installationImg1T,
    installationImg2D, installationImg2M, installationImg2T,
    installationImg3D, installationImg3M, installationImg3T,
    installationImg4D, installationImg4M, installationImg4T,
    installationImg5D, installationImg5M, installationImg5T,

    hardwareImg1D, hardwareImg1M, hardwareImg1T,
    hardwareImg2D, hardwareImg2M, hardwareImg2T,
    hardwareImg3D, hardwareImg3M, hardwareImg3T,
    hardwareImg4D, hardwareImg4M, hardwareImg4T,
    hardwareImg5D, hardwareImg5M, hardwareImg5T,
} from "@assets/images/main/access/components/index";
import { IconOsOff, IconOsOn } from '@assets/images/main/icon';


export default {
    banner: {
        title: 'Innovation of the Access System',
        desc: 'Innovate your access system by introducing ORBRO\'s access system.',

        schemeTitle: 'Choose the access system for you.',
        schemeDesc: 'Need help deciding? ',
        schemeDesc2: '<br/>Contact us at and our access expert will assist you.',
        phone: '(+82)1522-9928',
    },

    mainPage: {
        product: {
            title: 'Explore product configuration',
            desc: 'Check out the innovative access system product configuration.',
            mobile: {
                title: 'Mobile Access',
                desc: 'The beginning of the access system entering with mobile',
            },
            auto: {
                title: 'Automatic door access',
                desc: 'The beginning of the access system specialized for automatic doors<br/> Applicable to all doors',

            },
            face: {
                title: 'Face recognition access',
                desc: 'Methods of all access such as facial recognition, mobile access, NFC card, etc.',

            },
            accessory: {
                title: 'Access Accessories',
                desc: 'Find out all products related to the access system',

            }
        }

    },


    FAQ: [
        {
            title: 'What is the access method of ORBRO Access?',
            description: 'The access method of ORBRO Access is through the mobile application.<br/>Users can install the mobile application, and after obtaining permission, they can use this application for access.<br/><br/>Access through the mobile application allows you to open and close the access door using the user\'s smartphone. The application verifies the user\'s identity and authenticates access rights. Through this, users can conveniently use their mobile phones to easily experience access.<br/><br/>'
        },
        {

            title: 'Is it available in environments without automatic doors?',
            description: 'Yes, ORBRO Access can be used even in environments without automatic doors.<br/>However, if there is no automatic door, a locking device such as a deadbolt or EM Lock must be installed on the entrance door. This locking device helps you securely lock and open the access door.<br/><br/>Orbro Access terminal controls this locking device to unlock the entrance door. The access terminal accepts the user\'s authentication information and allows access only when it receives the correct authentication. In this way, you can operate the access control system even in environments without automatic doors by utilizing Orbro Access. Through the interlocking of the access terminal and the locking device, you can enhance the security of the access door and control so that only authenticated users can access.<br/><br/>',
        },
        {

            title: 'Is it possible to access without a mobile phone?',
            description: 'Yes, ORBRO Access is possible to access even without a mobile phone.<br/><br/>To access without a mobile phone, you need to use a separate accessory called Orbro Access Key. The Orbro Access Key is a device that transmits including user information, allowing people without mobile phones to receive access authentication. Through this, even if you do not have a mobile phone, you can smoothly perform access control through Orbro Access. <br/><br/>',
        },
        {
            title: 'Is it possible to use in an environment where Wi-Fi and internet installation is impossible?',
            description: 'Yes, ORBRO Access offers various system configurations depending on the environment, making it possible to enter and exit even in an environment where internet installation is impossible.<br/><br/>To do this, users can perform the entry and exit procedure using their smartphones. Users get access permission from the server through their smartphones and can enter and exit based on this. By doing this, Orbro Access can be effectively used even in environments where Wi-Fi and internet installation is difficult.<br/><br/>',
        },
        // ...
    ],

    bannerData: [
        {
            icon: access2on,
            iconInv: access2off,
            image: '',
            iconText: 'Automatic Door',
            titleKey: 'Applicable to All Entryways',
            descKey: 'access.banner.desc',
            sub: '',
            link: '/en/access/auto'
        },
        {
            icon: access3on,
            iconInv: access3off,
            image: '',
            iconText: 'Face Recognition',
            titleKey: 'Facial Recognition, Mobile Access, etc.',
            descKey: 'access.banner.desc',
            sub: 'New',
            link: '/en/access/face'
        },
        {
            icon: access4on,
            iconInv: access4off,
            image: '',
            iconText: 'Accessories',
            titleKey: 'All Products<br/>Related to Access Systems',
            descKey: 'access.banner.desc',
            sub: '',
            link: '/en/access/accessory'
        },

        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/en/os'
        },

        // Add more objects if there are more groups
    ],

    falldown: {
        left: {
            title: 'Integrate Smartphone Entry Methods,<br/>The Most Modern Access System',
            desc: 'From regular doors to automatic doors and speed gates, you can easily access various entry points in modern society using your smartphone. Experience the most efficient digital transformation using the system closest to our daily lives.',

            fall: [
                {
                    title: 'Smartphone Entry',
                    desc: 'Access is possible through all iOS/Android smartphones.',
                    img: {
                        desktop: Falldown1D,
                        tablet: Falldown1T,
                        mobile: Falldown1M
                    }
                },
                {
                    title: 'Smart Watch Entry',
                    desc: 'Enjoy more convenient access through a smartwatch.',
                    img: {
                        desktop: Falldown2D,
                        tablet: Falldown2T,
                        mobile: Falldown2M
                    }
                },
                {
                    title: 'RFID Card Entry',
                    desc: 'Access is possible using the existing employee ID card.',
                    img: {
                        desktop: Falldown3D,
                        tablet: Falldown3T,
                        mobile: Falldown3M
                    }
                },
            ]
        },

        rightImg: ''
    },

    functions: {
        title: 'The Core is Hardware,<br/>Everything Innovates Smoothly.',
        desc: '',
        line: 2,
        card: [
            {
                img: {
                    desktop: hardwareImg1D,
                    tablet: hardwareImg1T,
                    mobile: hardwareImg1M
                },
                tag: 'Status LED',
                title: 'Dual-Channel NFC/Bluetooth<br/>Access',
                desc: 'Access through NFC cards and more, from mobile to smartwatch entry.',
                size: '3',
            },
            {
                img: {
                    desktop: hardwareImg2D,
                    tablet: hardwareImg2T,
                    mobile: hardwareImg2M
                },
                tag: 'Power Supply',
                title: 'Integrated Communication and Power via PoE Usage',
                size: '1',
            },
            {
                img: {
                    desktop: hardwareImg3D,
                    tablet: hardwareImg3T,
                    mobile: hardwareImg3M
                },
                tag: 'Easy Installation',
                title: 'Convenient Installation<br/>with Dedicated Bracket',
                size: '2',
            },
            {
                img: {
                    desktop: hardwareImg4D,
                    tablet: hardwareImg4T,
                    mobile: hardwareImg4M
                },
                tag: 'Speaker',
                title: 'Entry Notifications<br/>via Speaker',
                size: '2',
            },
            {
                color: '#000',
                img: {
                    desktop: hardwareImg5D,
                    tablet: hardwareImg5T,
                    mobile: hardwareImg5M
                },
                tag: 'Status LED',
                darkTitle: 'Status Check<br/>',
                lightTitle: 'Even on Dark Nights',
                darkTitle2: '',
                desc: 'With RGB LED, you can check device status, entry confirmation, and more.',
                size: '2',
            },
        ]
    },

    installation: {
        title: `Don't Worry About Product Installation.< br /> We've Got It Covered for You.`,
        card: [
            {
                img: {
                    desktop: installationImg1D,
                    tablet: installationImg1T,
                    mobile: installationImg1M
                },
                tag: 'On-Premises Server Provided',
                title: 'Enterprise-Grade<br/>Server Provided',
            },
            {
                img: {
                    desktop: installationImg2D,
                    tablet: installationImg2T,
                    mobile: installationImg2M
                },
                tag: 'Professional On-Site Installation Support',
                title: 'Contact Us<br/> +82) 1522-9928',
                desc: 'Weekdays, 9 AM - 6 PM',
            },
            {
                img: {
                    desktop: installationImg3D,
                    tablet: installationImg3T,
                    mobile: installationImg3M
                },
                tag: 'Entry Accessories',
                title: 'Deadbolt Installation Possible',
            },
            {
                img: {
                    desktop: installationImg4D,
                    tablet: installationImg4T,
                    mobile: installationImg4M
                },
                tag: 'Entry Accessories',
                title: 'EM Lock Installation Possible',
            },
            {
                theme: 'black',
                img: {
                    desktop: installationImg5D,
                    tablet: installationImg5T,
                    mobile: installationImg5M
                },
                tag: 'Entry Accessories',
                title: 'Electric Strike Installation Possible',
            },
        ]
    },

    advantage: {
        img: {
            desktop: AdvantageImgD,
            tablet: AdvantageImgT,
            mobile: AdvantageImgM,
        },
        title: 'What Sets ORBRO Apart<br/>from Traditional Access Systems',
        desc: 'ORBRO is the most modern access terminal that offers various entry methods, adapting to the changing access methods in modern society, from traditional access methods to the latest smart technologies. It provides users with convenient and secure access environments.',
        card: [
            {
                icon: BlueIconPhone,
                title: 'Access with Your Smartphone',
                desc: 'Control entry points using iOS/Android apps.',
            },
            {
                icon: BlueIconWidget,
                title: 'Widget Available',
                desc: 'Widgets are a convenient way to use the mobile app.',
            },
            {
                icon: BlueIconWatch,
                title: 'Access with Smartwatch',
                desc: 'Experience convenient entry methods using a smartwatch.',
            },
            {
                icon: BlueIconRemote,
                title: 'Remote Control',
                desc: 'Administrators can manage entry points remotely.',
            },
            {
                icon: BlueIconRFID,
                title: 'RFID & NFC Cards',
                desc: 'Control entry points easily by tagging RFID or NFC tags.',
            },
        ]
    },


}