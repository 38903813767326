import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconPenSquare, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem3 } from "@assets/images/solution";
import video from "@src/assets/images/solution/retailExhibition/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/retailExhibition/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/retailExhibition/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/retailExhibition/video/sub/rtlsVideo3.mp4";

export default {
    banner: {
      title: '展示館の来場者管理と分析',
      desc: '正確かつ客観的なデータは、ビジネスの成長を促進することができます。無駄なリソースと費用を最小限に抑え、スマートな展示館イメージを提供し、差別化された運営が可能です。',
    },
    video: {
      title: 'スマートな方法で来場者数を増やす',
      desc: 'リアルタイム位置測位（RTLS）技術により、来場者の位置情報をリアルタイムで把握することができます。正確かつ客観的な来場者データを基に、人々が好む展示館を作り上げることができます。',
      play: video,
    },
    func: [
      {
        title: 'リアルタイムの来場者数把握',
        desc: 'リアルタイムの来場者数の動向を把握することで、展示館のレイアウトや展示物の配置など、効果的な展示館の運営計画を立てることができます。',
        sub1: 'リアルタイムの状況把握',
        icon1: IconClockGray,
        sub2: '迅速なデータ統合',
        icon2: IconShareGray,
        video: subVideo1,
      },
      {
        title: '来場者の分析',
        desc: '来場者が展示館内でどのように移動するかを分析することができます。また、好みの作品を把握することで、展示館の運営に有益な指標を提供します。',
        sub1: '統計データの提供',
        icon1: IconChartBarGray,
        sub2: '高い精度',
        icon2: IconRulerGray,
        video: subVideo2,
      },
      {
        title: '展示品の安全管理',
        desc: '展示品の破損や盗難をリアルタイムで監視します。これにより、迅速な事前対策が可能となります。',
        sub1: '高い精度',
        icon1: IconRulerGray,
        sub2: '迅速なデータ統合',
        icon2: IconChainLinkGray,
        video: subVideo3,
      },
    ],
    subfunc: {
      title: 'さまざまな機能',
      desc: '従来の方法では展示館の来場者から意味のあるデータを獲得することは容易ではありません。オフロの新機能を活用することで、展示館の運営効率を向上させ、ビジネスの成長を促進することができます。',
      data: [
        {
          icon: IconChartRadius,
          title: 'レポートおよび分析',
          desc: '資産の使用履歴に基づいて、レポートや分析データを生成することができます。',
        },
        {
          icon: IconBell,
          title: 'リアルタイムアラート機能',
          desc: '展示館で異常事態が発生した場合、アラートを送信して即時に対応することができます。',
        },
        {
          icon: IconRoute,
          title: '日別の来場者動向の確認',
          desc: '日別に来場者の動向を測位し、データ化することができます。',
        },
        {
          icon: IconDashboard,
          title: '状況把握',
          desc: '現在の来場者の移動状況を把握します。',
        },
        {
          icon: IconWarning,
          title: '脱走検知',
          desc: '来場者が立ち入り禁止エリアに接近することを検知します。',
        },
        {
          icon: IconPenSquare,
          title: '作業指示',
          desc: '展示館の状況に基づいて作業者に次の指示を出すことができます。',
        },
        {
          icon: IconChainLink,
          title: 'データ統合',
          desc: 'RFID、IoT、セキュリティ、ビデオなどのシステムと連携し、効率的に活用することができます。',
        },
        {
          icon: IconSecurity,
          title: 'データセキュリティ',
          desc: '高いレベルのデータセキュリティを維持し、信頼性の高いソリューションを提供します。',
        },
      ],
    },
    system: {
      title: 'システム構成',
      img: rtlsSystem3,
    },
    productTitle: '製品構成',
    product: [
      {
        tag: 'peoplePackage',
      },
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'manufacturingAsset',
      },
      {
        data: 'logisticsSafety',
      },
      {
        data: 'blogMedical',
      },
      {
        data: 'blogRTLS',
      },
    ],
  }
  