import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| 您的展览馆游客减少的原因',
  desc : '实时定位系统（RTLS）可以跟踪展览馆内访客的移动路径，分析访客流动，并实现展品的高效布置和战略性营销。此外，还为访客提供个性化信息，并提供互动体验。',
  keywords : '展览馆游客分析，移动路径分析，游客路径分析，展览馆管理，智能展览馆，展览馆运营，博物馆，美术馆，零售',

  ogTitle : '',
  ogDesc : '',
  ogSite : '您的展览馆游客减少的原因 :: 奥博罗博客',
  ogUrl : 'https://orbro.io/cn/blog/visitor',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4MMdkaL97d9T_gFLigXJSSbK2C0Or5jdSF6zVXkL2GI1HygPUMT2RCE0ttjlbTfLOl7PLSt3VsYQ6KBvvxEcPY3GuIa0uLL-mF35giNEbpMQNRgbpFHVcsqyA4o5rANv8gihSPfYbKM1HyvPt6_D1kZep6j_kGIaRAvWTOvABY9JXY295auK1rnwLsUAz1X331ALugJ2qNpT27T78NeS4AiEE_bH81MCbchU1l9ZcYBhY7YU7MoeuNdgO9gOc3fUkLNxPjMk9a0Egch5WFcyvcT6f6zbP4GStaxCf9r-AMdky5yya4HlObcx-cc9tJBlLRRmVjz1q73RJGDgIn8z6eIEBaOdJwi40d1Y697PV2niYZZAHlNQfCy7AaBz2m93TnUzj2I9Jf95EghdfJ2n0DE8C9g_JPlkCqJkjidGuNy8TepCsFr0_lzXEvxuYf-kgIqoykgB7hF8lkIyLegxSeY-4UtNLa63wKeVC0zuS8eMFrMyZLj8RzK5C7F4xVN546wg3P_OKi5cyKM1MEaA_E00yT-21yH5O4lo2TcH8vrAnUywRRPr6AU8nyWj3Ao3i1r7MdYeuvX00TTrkY9oCqtsPqjPBWatW5J2b1MAA9huMencK80d_RPn6K9MAJ46DxVfMEeOVGCZbVDP4Gw25D-_zZupP04L_a1yKnXuECxtYPD33mhyiT5ujUthjZENr-Iqh-8hjoblcAXTVW1B8MQVQPFwhXyg7Ku5mqxxR8qMu4XpbwPLFB0dOybWkairwNgv9Le1CI_2pTt-GkYKsC1U0ZxJUhFVd5gG6j8PPlaexJlRMIfslQVBwz6hFidjGuzeKOHz2BJBUG8iNu1DPSRGfaouJZmVtueDjlUYRXDpZbHZhmb7qtWqgX3cDComumpY4F8rE8CP3NESiewWR6aRWwuT2HM7t-9KovMjMuNC2WFZUeBFN7nGZOmXLITm4xGWZbYSsHMIoOvxVECF6sOKHdp9Hmx7veIXsSwhSdBh67hlaKJBXzXZ6uRnxi554EzB5xfZpExcxFpsEtPdFKFCPNnGVuudg_N2eYeVj6ZECRrqK7S-RUEzaiLLn5Dkw41DkxN4gJv7wxNmYNaH0q-pGU64OOMtrmJF9U9Ge9CLFze-95PFYYdjG_vO-E6ebt0N14ihC9qe1FYKkL7U3rUBKQdqGWnYU4OpKMhzmfI4U7S7yNQCbThTvPlusycMPcyuwfd_hIpmTxlwCymWUMbJKerk3mwqmyPdwhF4g5V0iYUVa2SeHyBzKAxMnulxIwCP89lUWJjUADIW_GepdLxyup3wFshCD3ljdM-KE43rP0H8ll55cJWUwwhgeuNdk4VrVNMkmsr5hqF54q15rcUE3ZeGr6wlwC0s-RskVI8B8RGU6h-ny7vy5u8Tf5YCoAm39v7KC6vG_zdOwzt5OFTZYSj1i1JYF3LmZXJmt86A6w6jN_K87xQtVOytpfk7W_Prwkm0tPBDnmTZGhe1V9qG7IH5aNI6nEEFoAYAPWwxWyCmTxBjCPgEZJoIkc0lPA9VjOeLLD88lcVF0fZHShvUOoqmGM-rBcIYwJ6dtkP9to=w2199-h1147',
  ogType : '网站',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '您的展览馆游客减少的原因',
  date : '2023-08-14',
  tag1 : '#展览馆',
  tag2 : '#游客分析',
  tag3 : '#路径分析',
  tag4 : '#智能展览馆',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'在当今时代，展览馆不仅仅是一个观赏艺术品的地方。人们在忙碌的日常生活中抽出时间进行文化活动，随着对艺术的期望在我们这个时代不断增加，参观展览馆充满了期待和惊奇。<br/>无论是更适合观众的展览馆，还是充满品味和文化气息的展览馆，都必须针对主要受众的喜好进行调整。然而，由于对艺术美的欣赏在很大程度上是主观的，很难明确地将某些作品标记为更好或更差。<br/><br/>尽管如此，某些作品往往受到人们的青睐。虽然在这个时代宣称哪些作品优越可能会有些困难（因为害怕被认为不文化），但参观者停留的时间较长的地方、充满活力的区域或经常被摄影的地点，都是特定作品受欢迎的指标。最终，为了吸引更多的游客，了解哪些作品引起人们的共鸣至关重要。问题在于，参观者很少向展览管理员传达这些信息。所以，应该完全依赖管理员的直觉吗？<br/><br/>随着近期实时位置追踪技术（RTLS）的进步，室内人员的移动情况可以被追踪。此外，这些模式可以显示在模拟展览馆布局的数字屏幕上，使其对任何人都易于理解。这项技术不仅追踪当前位置，还提供各种其他功能。让我们在下面探讨一些。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'人流分析',
      desc : '可以了解人们在设施内的分布情况。通过加入热图技术，可以识别人们在特定艺术品周围集聚的区域。从本质上讲，识别喜好作品的过程变得非常容易。无需进行调查，分析闭路电视镜头，或者让管理人员在设施周围漫游，您只需按下热图按钮。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'资产管理',
      desc : '实时位置追踪不仅限于人员。它还可以应用于需要特殊管理的独特资产，如艺术品，或者重要设备。实时监控这些资产的移动情况有助于预防安全事故，并确保更高效的移动和资产管理。此外，您可以通过搜索快速找到所需资产的位置。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'安全',
      desc : '实时库存追踪可以实现更快的交货时间。由于准确了解库存位置，您可以迅速找到所需的物品，缩短生产或交付所需的时间。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'',
      desc : '这些技术可以有多种应用。它们可以改进室内导航，还可以通过智能进出系统实现更安全、更高效的设施运营。此外，如果这些技术为公众所知，自然而然地可以提供智能、时尚的展览馆形象。',
      dot : ''
      
    },

  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc : '通过将UWB设备安装在工作人员或设备上，可以提供实时位置追踪支持的产品。',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : retailExhibition.thumb,
        data : 'retailExhibition',
        link : '/cn/enterprise/retail/exhibition'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/cn/enterprise/logistics/asset'
      }, 
      {
        img : officeAsset.thumb,
        data : 'officeAsset',
        link : '/cn/enterprise/office/asset'
      }
    ]

  }


}
