import React, { PropsWithChildren, useState, useRef, useEffect } from "react";
import {
  StyledDownloadPolicyLink, StyledDownloadPolicyLinkText,
  DialogRequestBox, DialogInputText, DialogInput, ClearButton, ClearIcon,
  DialogInputBoxRequest, DialogInputRequest, StyledUnderline, DialogCheckBox,
  DialogRequestBtn
} from "@pages/Main/Common/Purchase/Modal/Modal.style";

import { Trans } from "react-i18next";
import { IconCheckBoxBlack, IconCheckBoxEmpty, IconCloseIcon } from "@assets/images/icon/";
import emailjs from 'emailjs-com';

import { StyledLabel, StyledParagraph } from "@components/Text";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import { RequestBox, RequestInputBox, RequestPage, StyledDownloadPolicyBox } from "./Request.style";
import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";

import { Div100flex, DivF8F8F8 } from "@pages/CommonStyle/commonDiv.style";
import { LabelColor, Spacing } from "@pages/CommonStyle/common.style";
import { BoxLayout } from "@pages/Pricing/Pricing.style";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";
export const emailData = React.createContext('');


interface ModalDefaultType {
  pageName: string
}

const Request: React.FC<PropsWithChildren<ModalDefaultType>> = ({ pageName }) => {


  const location = useLocation();
  // Input event
  const handleInput = (event, callback) => {
    const value = event.target.value;
    callback(value);
  }

  let ph_name;
  let ph_company;
  let ph_mail;
  let ph_phone;
  let ph_option;

  //set placeholder
  if (location.pathname.includes('/en/')) {
    ph_name = 'John Doe';
    ph_company = 'ORBRO Corporation';
    ph_mail = 'example@orbro.io';
    ph_phone = '010-1234-5678';
    ph_option = 'Please enter any additional inquiries or comments. (Optional)';
  } else if (location.pathname.includes('/jp/')) {
    ph_name = '山田太郎';
    ph_company = 'オブロ株式会社';
    ph_mail = 'example@orbro.io';
    ph_phone = '090-1234-5678';
    ph_option = 'その他のお問い合わせ内容を入力してください。 (任意)';
  } else if (location.pathname.includes('/cn/')) {
    ph_name = '张三';
    ph_company = 'ORBRO 股份公司';
    ph_mail = 'example@orbro.io';
    ph_phone = '090-1234-5678';
    ph_option = '请输入其他咨询事项（选择）';
  } else {
    ph_name = '홍길동';
    ph_company = '오브로 주식회사';
    ph_mail = 'example@orbro.io';
    ph_phone = '010-1234-5678';
    ph_option = '기타 문의사항을 입력해 주세요. (선택)';
  }


  const [name, setName] = useState<string>('');
  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };
  const handleClearName = () => {
    setName('');
  };

  const [company, setCompany] = useState<string>('');
  const onChangeCompany = (e) => {
    const company = e.target.value;
    setCompany(company);
  };
  const handleClearCompany = () => {
    setCompany('');
  };

  // Email Handle
  const [email, setEmail] = useState<string>('');
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const handleClearEmail = () => {
    setEmail('');
  };

  const [phone, setPhone] = useState<string>('');
  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };
  const handleClearPhone = () => {
    setPhone('');
  };

  const [request, setRequest] = useState<string>('');
  const onChangeRequest = (e) => {
    const request = e.target.value;
    setRequest(request);
  };

  const handleClearRequest = () => {
    setRequest('');
  };

  const componentRef = useRef<HTMLDivElement>(null);

  const [focus, setFocus] = useState({
    name: false,
    company: false,
    email: false,
    phone: false,
    request: false,
  });


  const handleFocus = (field) => {
    setFocus((prevFocus) => {
      const updatedFocus = {} as {
        name: boolean;
        company: boolean;
        email: boolean;
        phone: boolean;
        request: boolean;
      };
      for (const key in prevFocus) {
        updatedFocus[key] = key === field;
      }
      return updatedFocus;
    });
  };

  const handleKeyDown = (e, currentField) => {
    if (e.key === "Tab") {
      e.preventDefault();

      const fields = ["name", "company", "email", "phone", "request"];
      const currentFieldIndex = fields.indexOf(currentField);
      const nextFieldIndex = currentFieldIndex === fields.length - 1 ? 0 : currentFieldIndex + 1;
      const nextField = fields[nextFieldIndex];

      const nextInputElement = document.getElementById(`${nextField}-input`);
      if (nextInputElement) {
        nextInputElement.focus();
      }
    }
  };


  const [checked, setChecked] = useState(false);
  const [submit, setSubmit] = useState(false);

  const policyCheck = () => {
    setChecked(!checked);
  };

  // Focus Handling
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setFocus({
          name: false,
          company: false,
          email: false,
          phone: false,
          request: false,
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    if (name && company && email && checked) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  }, [name, company, email, phone, checked]);


  const solutionDatas = {
    constructionSafety: '건설현장 작업자 안전',
    constructionAsset: '건설현장 자재 관리',
    constructionEquipment: '건설현장 중장비 관리',
    logisticsAsset: '물류 재고 위치 추적',
    logisticsSafety: '물류 창고 작업자 안전 관리',
    officeAsset: '오피스 자산 관리',
    medicalPatient: '의료시설 환자 위치 추적',
    medicalWaste: '의료 폐기물 관리',
    manufacturingProcess: '제조 공정 실시간 모니터링',
    manufacturingAsset: '제조 실시간 재고 관리',
    retailExhibition: '전시관 방문객 동선'
  }

  const onSubmit = () => {
    if (submit) {
      const solution = solutionDatas[pageName];

      emailjs.init('SoQtE3CThBY_wpeDn');
      const mailForm = {
        name: name,
        company: company,
        email: email,
        phone: phone,
        request: request,
        solution: solution
      }

      let alertMessage;
      if (location.pathname.includes('/en/')) {
        alertMessage = "Thank you for your inquiry. We'll get back to you as soon as possible. Thank you."
      } else if (location.pathname.includes('/jp/')) {
        alertMessage = "お問い合わせありがとうございます。 できるだけ早くご連絡いたします。 ありがとう"
      } else {
        alertMessage = "문의주셔서 감사합니다. 빠른 시일내로 다시 연락드리겠습니다. 감사합니다."
      }

      // 이메일 보내기
      emailjs.send('service_7yv5b7d', "template_sbrfpmp", mailForm)
        .then((response) => {
          console.log('이메일 보내기 성공:', response);
        })
        .catch((error) => {
          console.error('이메일 보내기 실패:', error);
        });
      alert(alertMessage);
      handleClearCompany();
      handleClearEmail();
      handleClearName();
      handleClearPhone();
      handleClearRequest();
      handleFocus('');
      setChecked(false);
      notionSubmit();
    }

  };


  // 데이터를 Notion에 전송하는 함수
  const notionSubmit = async () => {
    try {
      const response = await fetch("https://orbro.io/submitToNotion", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name: name,
          corporateName: company,
          email: email,
          phone: phone,
          memo: solutionDatas[pageName] + " / " + request
        })
      });

    } catch (error) {
      console.log('error', error);
    }

  }


  return (
    <DivF8F8F8>
      <StyledGridContainer>
        <StyledGridRow>
          <StyledGridColumn desktop={2} tablet={1} mobile={0} />

          <StyledGridColumn desktop={8} tablet={6} mobile={4}>
            <RequestPage>

              <DisplayH2 size='small'>
                <Trans i18nKey={`solutionCommon.request.title`} />
              </DisplayH2>

              <Spacing pixel="16" />
              <StyledParagraph size='medium'>
                <p>
                  <Trans i18nKey={`solutionCommon.request.desc`} />
                </p>
              </StyledParagraph>

              <Spacing pixel="32" />


              <DialogRequestBox>

                <RequestBox>
                  {/* 이름 입력 */}
                  <RequestInputBox inputValue={focus.name}>
                    <DialogInputText inputValue={focus.name}> {t(`productPurchase.modal.theme.name`)} </DialogInputText>
                    <Spacing pixel="4" />
                    <Div100flex>
                      <DialogInput
                        value={name}
                        placeholder={ph_name || ''}
                        onInput={(e) => { handleInput(e, setName) }}
                        onChange={onChangeName}
                        onClick={() => handleFocus('name')}
                        onKeyDown={(e) => handleKeyDown(e, "name")}
                      />

                      {name !== '' && focus.name && (
                        <ClearButton onClick={handleClearName}>
                          <ClearIcon src={IconCloseIcon} alt="close icon" />
                        </ClearButton>
                      )}
                    </Div100flex>
                  </RequestInputBox>

                  {/* 회사 입력 */}
                  <RequestInputBox inputValue={focus.company}>
                    <DialogInputText inputValue={focus.company}> {t(`productPurchase.modal.theme.company`)} </DialogInputText>
                    <Spacing pixel="4" />
                    <Div100flex>
                      <DialogInput
                        value={company}
                        placeholder={ph_company}
                        onInput={(e) => { handleInput(e, setCompany) }}
                        onChange={onChangeCompany}
                        onClick={() => handleFocus('company')}
                        onKeyDown={(e) => handleKeyDown(e, "name")}
                      />

                      {company !== '' && focus.company && (
                        <ClearButton onClick={handleClearCompany}>
                          <ClearIcon src={IconCloseIcon} alt="close icon" />
                        </ClearButton>
                      )}
                    </Div100flex>
                  </RequestInputBox>
                </RequestBox>

                <Spacing pixel="16" />

                <RequestBox>
                  {/* 이메일 입력 */}
                  <RequestInputBox inputValue={focus.email}>
                    <DialogInputText inputValue={focus.email}> {t(`productPurchase.modal.theme.email`)} </DialogInputText>
                    <Spacing pixel="4" />
                    <Div100flex>
                      <DialogInput
                        value={email}
                        placeholder={ph_mail}
                        onInput={(e) => { handleInput(e, setEmail) }}
                        onChange={onChangeEmail}
                        onClick={() => handleFocus('email')}
                        onKeyDown={(e) => handleKeyDown(e, "name")}
                      />

                      {email !== '' && focus.email && (
                        <ClearButton onClick={handleClearEmail}>
                          <ClearIcon src={IconCloseIcon} alt="close icon" />
                        </ClearButton>
                      )}
                    </Div100flex>
                  </RequestInputBox>

                  {/* 전화번호 입력 */}
                  <RequestInputBox inputValue={focus.phone}>
                    <DialogInputText inputValue={focus.phone} > {t(`productPurchase.modal.theme.phone`)} </DialogInputText>
                    <Spacing pixel="4" />
                    <Div100flex>
                      <DialogInput
                        value={phone}
                        placeholder={ph_phone}
                        onInput={(e) => { handleInput(e, setPhone) }}
                        onChange={onChangePhone}
                        onClick={() => handleFocus('phone')}
                        onKeyDown={(e) => handleKeyDown(e, "name")}
                      />

                      {phone !== '' && focus.phone && (
                        <ClearButton onClick={handleClearPhone}>
                          <ClearIcon src={IconCloseIcon} alt="close icon" />
                        </ClearButton>
                      )}
                    </Div100flex>
                  </RequestInputBox>
                </RequestBox>

                <Spacing pixel="16" />


                {/* 문의사항 입력 */}
                <DialogInputBoxRequest inputValue={focus.request} h={180}>
                  <DialogInputRequest
                    value={request}
                    placeholder={ph_option}
                    onInput={(e) => { handleInput(e, setRequest) }}
                    onChange={onChangeRequest}
                    onClick={() => handleFocus('request')}
                    onKeyDown={(e) => handleKeyDown(e, "name")}
                  />
                </DialogInputBoxRequest>

                <Spacing pixel="24" />

                <StyledDownloadPolicyBox >


                  {checked ? (
                    <DialogCheckBox src={IconCheckBoxBlack} onClick={policyCheck} alt="Checkbox Icon" />
                  ) : (
                    <DialogCheckBox src={IconCheckBoxEmpty} onClick={policyCheck} alt="Checkbox Icon" />
                  )}

                  <StyledLabel size="medium"><Trans i18nKey={"download.form.policy.check"} />  </StyledLabel>
                  <StyledDownloadPolicyLink href="/terms/2" target="_blank" >
                    <StyledDownloadPolicyLinkText size="medium"><Trans i18nKey={"download.form.policy.link"} /></StyledDownloadPolicyLinkText>
                    <StyledUnderline />
                  </StyledDownloadPolicyLink>
                  <StyledLabel size="medium"><Trans i18nKey={"download.form.policy.agree"} /> </StyledLabel>

                </StyledDownloadPolicyBox>

                <Spacing pixel="24" />

                <BoxLayout>
                  <DialogRequestBtn submit={submit} onClick={onSubmit}>
                    <LabelColor color='#FFFFFF' size="medium">
                      <Trans i18nKey={"productPurchase.submit"} />
                    </LabelColor>
                  </DialogRequestBtn>
                </BoxLayout>



              </DialogRequestBox>



            </RequestPage>
          </StyledGridColumn>
        </StyledGridRow>
      </StyledGridContainer>
    </DivF8F8F8>
  )
};

export default Request;