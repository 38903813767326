import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
    helmet: '',
    desc: 'Real-Time Location System (RTLS) technology offers the latest insights and strategies for inventory management and production process optimization in manufacturing facilities. Enhance safety, productivity, and competitiveness with real-time location tracking.',
    keywords: 'Manufacturing facility, RTLS technology, inventory management system, real-time location tracking, manufacturing companies, production process optimization, safety and security, automation, logistics management, manufacturing innovation, manufacturing productivity, robot utilization, 5G network, artificial intelligence, machine learning, production schedule management, factory operation optimization, inventory tracking, location-based systems, competitiveness, real-time location tracking, RTLS, Orbro, Kontech, ORBRO',
    ogTitle: '',
    ogDesc: '',
    ogSite: 'The Future of Manufacturing Facility Inventory Management Systems :: Orbro Blog',
    ogUrl: 'https://orbro.io/en/blog/nextManufacturing',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxbC6Lmz5d8iN1v3jEMtSZe3Ob88JgI_jXXAfJ7GZaTrf8KH3DEO1m4HlYFIDS9bFuaJ56cZ_wEcBOXyqi7Rd1uXIPtcB1SZL5Zzp4Zl2tEjAhAsyq98MX-gfwKbsfAr4dO1q5vsbz2K4nTUWHruzU-5hBlcQ1yxNQlsPiYw4RphqkypMB-pwo0G3P-xK2AXu-BK2gkkGECBMSaqNR4kGBEpmdgxTWhwccWsjJ-q7e0HIujysRfM0l0ypLuO4Tq-jlmwITla4reB_nvTWUkbQKvS3L0qLAUKFsKGUijjxU1e2K4uu9c2imXUJIAMcSwzhUuYwW68muAKugSd95IOSWlAtP8ygMwSe62DG3ytNQxSEOVw77XQP8MXzF61yzQn7TttUsTIEbaSPUkfS82CVlcwFZfj6Eu1TGVGPIBtzA5h2Ye0wCkx1uG2j9YWPT4I3onzdUX6_M7mLn--deX_zstjJWguDI_2zuFy31WB_lALFYps0Wm-iD9E_eCocJkE8sKpKvVfLD8uf1wiLAGX6r_6LtzfaGAxI3m3o1IG2MMTTXnMH6y-O3M6aUz3yqjmxF4T92JTEMFPIboDM657wvRyI-U9Qpt97oMe5HnrqwIa08nTpbjCqaT4H7tCiVm3ms4KDzEpQDKEOqlJIuc--1X0qcGvt7mp8YQAErkrY3fX0wyvBJbOjLS9ckhMpZnvQjz3HjWHSYqtQfo0_bre3v0tKk-dRNj8V9lo3VQEG9NSGTAAJWe-TAVQ6WSwGZpY5qS5C39YC5uFkS6CjMs4lFwQaiGWSqkBZu38_UFyvcZqPxFXU6-zwQJXf5P5VvNO9vV9PxIA45viCVrRJ5_N9NHS1KqxQnQf1dbqGmhbVcF9bE4ZQuaZPelOXIlXJTNA1buHW1HlFOWAr7-ptDHQRaOi0XStG1jluCzkkOqa8rmsmVDq54dvkmSTQToTR_iiZz_sHItPoyJrSpwOBsdj0JILyCwzJCON67Fj5ekj3Lp70M-oY5FfJ0h2SGgTIBR-AEW83ZzuSV7LCetAq4DfJJjFWberG95uPpPBTxfxnUDm8UD4tuCyEeqC0-aaP-6R9rJ__4kDk-HhcrkgYQ52rX2eUTWvX_HaBu_czaZ4_7n0W97P0HAXBII94mQ4CI-nyZKtya-i3R4Af6g826lPfcA-BN8mbIh_bvRi3dvrQD6-ikeEvXvtTDzkvqJ4NSW9UpYBoAAMx4u4PEKvDX5M6o10FceVzq_SJtSHC3PQ4LvvKBrdakrFs7tGHjQjUGEyyLWU-hKwZq2ImS95HpI9MTvV8MJCGQGuUcEn4PGilBE_Y8gik5a7EeanF5XYjw_sHHwXZXT3zq03vRnmXVoal3_zVl8fkohp_Ja3JZopz1hKqUtZj6TkyeoF8plJmaHXlNu_Sc2lG7JEeFwlfKdG-ZOhpUk387Q1YuFalokq_NBDxN471dJmii2uBokKcm0aZWHMkJE8JLrb5NF7jyCJawF6fNMUK-yh-OJvWFcNLfXHznai5Bdfx6bV0Uq1Q2FQIHJAhtflvB7IZcFiQJ3H8Ul-rWYyOHnxDTZJ5yt5IbVKmudE_SNi6ASGmkwwg=w2000-h4260',
    ogType: 'website',
    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',
    title: 'The Future of Manufacturing Facility Inventory Management Systems',
    date: '2023-10-16',
    tag1: '#ManufacturingFacility',
    tag2: '#InventoryManagement',
    tag3: '#SmartManufacturing',
    tag4: '#RTLS',
    text: [
        {
            head: '',
            subHead: '',
            desc: `Manufacturers continually strive to find ways to improve factory operations and inventory management. These efforts are considered vital for enhancing efficiency, profitability, and competitiveness. In recent years, Real-Time Location System (RTLS) technology has revolutionized inventory management in manufacturing facilities. In this article, we'll explore how RTLS technology significantly benefits inventory management in manufacturing facilities.`,
            dot: ''
        },
        {
            head: 'What Is RTLS?',
            subHead: '',
            desc: 'RTLS stands for "Real-Time Location System," which refers to a system that allows real-time tracking of the location of objects or people using a combination of various sensors and communication technologies. RTLS integrates technologies such as GPS, RFID, sensor networks, and wireless communication to provide users with accurate, real-time location information.',
            dot: ''
        },
        {
            head: 'Utilizing RTLS in Manufacturing Facilities',
            subHead: '1. Inventory Tracking and Management',
            desc: 'RTLS enables real-time tracking of raw materials, components, semi-finished products, and finished products within manufacturing facilities. This facilitates efficient inventory management, helping manufacturers easily locate necessary inventory and reduce waste. Real-time tracking of inventory location and movement prevents shortages and excesses, leading to cost savings in logistics.',
            dot: ''
        },
        {
            head: '',
            subHead: '2. Production Process Optimization',
            desc: 'RTLS allows tracking the location of workers and equipment on the production line, facilitating production process optimization. This leads to increased productivity and adherence to production schedules, a crucial factor in manufacturing. Manufacturers can analyze the workflow of workers and equipment to identify and address bottlenecks.',
            dot: ''
        },
        {
            head: '',
            subHead: '3. Enhanced Safety and Security',
            desc: 'RTLS plays a significant role in improving worker safety. It enables the tracking of workers operating in high-risk areas, helping to prevent accidents by monitoring and detecting potential hazards in real-time. Additionally, it ensures the safety and security of equipment and inventory, preventing theft.',
            dot: ''
        },
        {
            head: '',
            subHead: '4. Automation and Robot Utilization',
            desc: 'RTLS can be integrated with automated robot systems to precisely manage the work locations and movements of robots within a factory. This enables efficient collaboration between robots and humans. Robots can use RTLS to establish accurate paths and work locations when transporting inventory or automating processes.',
            dot: ''
        },
        {
            head: 'The Future of RTLS',
            subHead: '',
            desc: 'RTLS technology is continuously evolving, and the future of inventory management in manufacturing facilities holds even more innovation. Some possibilities for the future include:',
            dot: ''
        },
        {
            head: '',
            subHead: '1. Utilizing 5G Networks',
            desc: 'As 5G networks become more widespread, RTLS connectivity and bandwidth will improve. This will allow for the transmission and processing of even more data in real-time.',
            dot: ''
        },
        {
            head: '',
            subHead: '2. Applying Artificial Intelligence and Machine Learning',
            desc: 'Artificial intelligence and machine learning technologies will be applied to RTLS data for more accurate predictions and decision-making.',
            dot: ''
        },
        {
            head: '',
            subHead: '3. Ecosystem Expansion',
            desc: 'RTLS companies will develop and provide platforms that can integrate with other manufacturing facility systems, expanding the manufacturing facility ecosystem.',
            dot: ''
        },
        {
            head: 'Conclusion',
            subHead: '',
            desc: 'RTLS technology has brought a revolutionary change to inventory management in manufacturing facilities, and its future looks even brighter. Manufacturers using this technology will enhance efficiency and competitiveness. Keeping an eye on the future developments of RTLS, companies striving for innovation in the field of inventory management in manufacturing facilities are poised for greater success in the future.',
            dot: ''
        },
    ],
    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'A product that assists real-time location tracking by attaching UWB devices to workers or equipment.',
                img: productImg.product.workerTracking.thumbnail
            },
            {
                text: '',
                title: '',
                serial: '',
                desc: '',
                img: ''
            }
        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/en/enterprise/medical/patient'
            },
            {
                img: medicalWaste.thumb,
                data: 'medicalWaste',
                link: '/en/enterprise/medical/waste'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/en/enterprise/office/asset'
            }
        ]
    }
}
