import { CONTENT_DARK_PRIMARY, CONTENT_DARK_SECONDARY, CONTENT_PRIMARY } from "@assets/styled/color.style";
import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading } from "@components/Text";
import styled from "@emotion/styled";

export const StyledOurCulture = styled.div`
  padding: 192px 0;
  background-color: ${CONTENT_PRIMARY};
`;

export const StyledOurCultureDisplay = styled(StyledDisplay)`
  margin-top: 16px;
`;

export const StyledOurCultureDescription = styled(StyledHeading)`
  margin-top: 24px;
`;

export const StyledOurCultureMainImg = styled.img`
  width: 100%;
  margin-top: 64px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    object-fit: cover;
    height: 360px;
    border-radius: 16px;
    object-position: center;
  }
`;

export const StyledOurCultureRow = styled(StyledGridRow)`
  margin-top: 32px;
`;