import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| 스마트 물류창고가 산업을 리드하는 이유',
  desc : '스마트 물류창고는 실시간으로 재고 위치를 파악하여 물류 프로세스를 최적화하고 효율성을 극대화할 수 있기 때문에 산업을 리드합니다. 이는 정확한 재고 추적과 효율적인 재고 이동을 통해 비용 절감과 고객 서비스 수준 향상을 실현할 수 있기 때문입니다.',
  keywords : '대형 물류 창고 관리, 물류센터 관리, 물류창고 관리, 스마트 물류, 재고 관리, 스마트 재고 관리, 물류창고 재고 관리, 대형 물류 창고 재고 관리',

  ogTitle : '',
  ogDesc : '',
  ogSite : '스마트 물류창고가 산업을 리드하는 이유 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/smartLogistics',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4NDUS2r1YTByIJmtp6T8gd_h-Y0FvrgnSd4VlXMvLXHrcsoDQuYCCpyNiEnagSHA56J_cXX6ma4jVfg2btnQwzHH1T8dty-PlOi09CLRr0QCFPG5Y4vVqfn7C5PnTTsR0x0nbT2ON5OhY0dk5d-xXzCLE6vUBjcUa8fBILF1gIuf_N84MNO3N-VeWiksJFzVJc-xgHqholB4vdavvEz7lQXy-2H0Bd4mRgkpdEB_TKat6ecFPI1Iicj74Quxd7X80Jsf4K-0CLRxPmsRzqHK-9OTe65ZtvBh6uCdL9EkTWgEqIhYkBZurnVB2Xu1w5ehFm0YWCO4mUMXNlZ-Dl5zLrTMwASpHVsXgOer9HduJZ_tpy7yNSd1_eqR0YtzmRoll-I0lVS6kZFRtI8DFYaLVzENwJgYYzd7g6722BEKL9hnLql3Q5hQpwBGPeiSfZBLuwjIxTUqbuTlemEXD9BykaU6SchggZ0JNi9oy4MVSmKjvnHMAInxjwv2dhy0MyVb7f8gdr8lK41OhMhRx4OiMYRfCoJjXc6Ee9DOX-Z7g2HReiWKhMK4gGpS9wKp_fsj9PATynJFs2AJY8G1c4G84Vqpv7jFwYbpgyp11npXaIaG12FHyF7hBdvDtwmWQ2Ebb1M7Fl2CEQFB7GZREh7wslvCX6Tst9-y6HysrikAuUYviPj_IFrmp4eDDGbQIznKzXVpeegI-fbzkPhiPv5MREXUt_gCYR_CKVy916MV0gpoSXeKYuSjDEih9ebiLmq-kpGGAb6Cl3G9s5zsiWOZLaZlZKjoDCI9Gujoy7FTRDGFM3r6HaIFY7mehUppvhCS_9nsSGgk32eoWdThQu3oUDCJMbf4vDIWM7bBa6LW4_OqliCAqREvMwSx8EezvOQf01DLRZ1UMbWyA_QQl4qIa_g7P8_NAUSWbFSZf2rjA5WRMJ12rKhQrUsxiSTUOnFjee6CSe693z1ccTi0XCbjaPdu7LEg9Mbcb2wmJ-0Jcxjmggf_jEWTjwwGIGUrzcIPJ0PqDXNiCW5esg5ksxHkcunw8ra44iq0DjQx8vE2BZ62e4i5meo_tR91eEqPecKgYFSVnNfBmE7mPfBo5W-Y_rrffkTnIQxlgR8zFUselSqB-KFuai7uIoAniJzBPc8ReLT7OJzprQQyMgdDtCW3epD-bbknMF4stChNDuYgGa85BsShBc3_pEuvsoxINtMIUXtpn5s7IS18mmnVLyK8C-jqpisEXwk8A4CZjv3tU4GWJyb_ZZoL9ZpCb6h6w4Brla59Ia3dRU-ZUKtVXg7CNzMdllUkdXV64VHxjSmpvNtAJ92aE_qUOj8Q1BOwQuAh71_yBTW0zKAxhme_coBD55dETFQakhVS484NQqw2sj2Ypm7qUDu6UWHB8TZVpogTjCpoKhjT84dNSVIMMqHk6UT-4BiaXv1NMyFb6LtFhGmbUO8iEX1n9RsO2Sn5bNAwjs8aFmh_P5eX9Qfj3HJ5YX_ChNlBlkwTDgdj_V3_uJm_DErrCc4YCi_ODmOv_0Gj-2o3wiULGzOPSZQdtnhCSOCNOEokcoQSRMcy1l4IBEW5Hs=w1470-h1233',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '스마트 물류창고가 산업을 리드하는 이유',
  date : '2023-08-03',
  tag1 : '#대형물류센터',
  tag2 : '#스마트물류',
  tag3 : '#재고관리',
  tag4 : '#스마트재고',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'재고 관리는 물류 센터 공급망의 핵심입니다. 그래서 비즈니스를 성장시키고자 하는 물류 책임자들은 더욱 효율적인 재고 관리를 고민합니다. 물론 재고 관리뿐만 아니라, 전반적인 물류 시스템의 효율과 생산성을 높이기 위해서 가장 스마트한 방법을 찾으려 합니다.<br/>최근 실시간 위치 추적 시스템, RTLS(Real-time Location System)의 발전으로 물류 창고도 이전과는 다른 형태의 시스템을 구축할 수 있게 되었습니다.<br/>이제 물류 창고 내부의 모든 것을 추적할 수 있습니다. 물류 창고와 동일한 모습의 지도 위에 재고, 작업자, 지게차 등의 위치가 실시간으로 표시됩니다. 거의 오차가 없는 정교한 기술로, 현장의 모든 것을 한눈에 파악할 수 있게 되었습니다. 이는 단순한 위치 추적을 넘어서 다양한 기능으로써 활용될 가능성을 가지고 있습니다.<br/><br/>아래에서는 이를 통해 얻을 수 있는 다양한 기능을 설명하겠습니다.',
      dot : ''
      
    },
    {
      head : '재고 위치 파악',
      subHead :'',
      desc : '물류 창고 내부에 있는 재고의 위치를 파악할 수 있습니다. 구역을 선택하면 해당 구역에 존재하는 재고들의 위치와 수량을 파악할 수 있습니다. 만약 찾고자 하는 재고가 있다면, 검색기능을 통해서 창고 내부 어디에 위치하고 있는지 파악할 수 있습니다. 이동 중인 재고라면 실시간으로 동선을 파악할 수 있습니다. 품목의 인기도에 따라 재고를 배치하고 재고 회전율을 최적화할 수 있으며, 이는 비용 절감과 재고 부족이나 과잉을 방지하는 데 도움이 됩니다. 그리고 정확한 재고 위치 정보를 가지고 있다면, 물류 작업자들은 물품을 신속하게 찾아내고 필요한 위치로 이동시킬 수 있습니다. 이를 통해 작업 시간을 단축하고 생산성을 높일 수 있습니다.',
      dot : ''
      
    },
    {
      head : '입출고 자동화',
      subHead :'',
      desc : '미리 지정된 지점을 통과하는 재고들은 실시간으로 입출고 시간이 기록됩니다.  이 데이터는 정확하므로, 사람의 실수를 방지하고 인력을 절약할 수 있습니다. 또한, 해당 데이터는 클라우드를 통해서 저장되므로, 번거롭게 관리하거나 분실될 위험이 없습니다.',
      dot : ''
      
    },
    {
      head : '리드타임 단축',
      subHead :'',
      desc : '실시간 재고 추적을 통해 더 빠른 리드 타임을 구현할 수 있습니다. 재고 위치를 정확히 알기 때문에 필요한 재고를 빠르게 찾아낼 수 있고, 생산 또는 배송에 필요한 시간을 단축할 수 있습니다. ',
      dot : ''
      
    },
    {
      head : '안전 관리',
      subHead :'1. 작업자 추적',
      desc : '작업자의 위치를 실시간으로 추적하여 안전 상황을 모니터링하고, 위험한 지역에 접근하거나 긴급 상황이 발생할 경우, 신속하게 대응할 수 있도록 도와줍니다. 이로써 잠재적인 위험을 사전에 예방합니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'2. 위험구역 설정',
      desc : '현장에서 접근해서는 안 되는 곳이나 주의해야 하는 곳을 미리 설정하여, 근처를 지나가는 작업자에게 경고하거나 관리자에게 알림을 보낼 수 있습니다. 이로써 사고를 예방할 수 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'3. 지게차 관리',
      desc : '안전에 유의해야 하는 것은 사람뿐만이 아닙니다. 잠재적 사고 위험이 있는 중장비의 위치를 실시간으로 파악하여, 작업자가 근접하거나 잘못된 경로로 들어서는 경우를 파악하여 사고를 예방할 수 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'4. 자산 관리',
      desc : '재고 손실과 도난을 방지하는 데 도움이 됩니다. 재고가 각 위치에 할당되고 실시간으로 추적되면 재고의 유실이나 도난을 신속하게 감지할 수 있습니다. 이를 통해 재고 손실을 최소화하고 회수 조치를 할 수 있습니다.',
      dot : ''
      
    },

  ],

  link : {
    page : 'rtls',
    numb : '1',
    product : [
      {
        text : productPurchase.purchase.asset.assetTracking.title,
        title : productPurchase.purchase.asset.assetTracking.title,
        serial : productPurchase.purchase.asset.assetTracking.serial,
        desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img : productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution : [
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      },
      {
        img : logisticsSafety.thumb,
        data : 'logisticsSafety',
        link : '/enterprise/logistics/safety'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/enterprise/manufacturing/asset'
      }
    ]

  }


}