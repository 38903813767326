import React, { FC, useState } from 'react';
import { StyledControlPage } from '@pages/Main/Access/Products/ProductPage.style';
import Product from '../../Common/Product/Product';
import Spec from '@pages/Main/Common/Spec/Spec';
import Purchase from '@pages/Main/Common/Purchase/Purchase';
import BannerScroll from '@pages/Main/Common/Banner/BannerScroll';
import seoProduct from '@src/lang/ko/seoProduct';
import seoProductEng from '@src/lang/en/seoProduct';
import seoProductJP from '@src/lang/jp/seoProduct';
import seoProductCN from '@src/lang/cn/seoProduct';
import i18n from '@pages/i18n';
import SEO from '@pages/SEO';
import { useLocation } from 'react-router-dom';

interface ProductProps {
  category: string;

}

const Outdoor: FC<ProductProps> = ({ category }) => {

  let initialProduct = "ptz";

  if (category == "outdoor") { initialProduct = "ptz" }
  else if (category == "indoor") { initialProduct = "ip" }


  const [productName, setProduct] = useState<string>(initialProduct);
  const [imgGroupIndex, setImgGroupIndex] = useState<number>(0);


  let seoPath;
  const location = useLocation();
  
  if(location.pathname.includes('/en/')){
    seoPath = seoProductEng;
  } else if (location.pathname.includes('/jp/')){
    seoPath = seoProductJP;
  } else if (location.pathname.includes('/cn/')){
    seoPath = seoProductCN;
  } else{
    seoPath = seoProduct;
  }



  const currentLang = i18n.language;
  const helmetTitle = seoPath.outdoor.title;
  const ogKey = seoPath.outdoor.key;
  const ogDesc = seoPath.outdoor.desc;
  const ogImg = seoPath.outdoor.img;
  const ogUrl = seoPath.outdoor.url;

  
  const jsonLd = {
    "@context" : "https://schema.org",
    "@type": "Product",
    "name": helmetTitle,
    "description": ogDesc,
    "url":  ogUrl,
    "keywords": ogKey,
    "image": ogImg,
    "brand": {
      "@type": "Brand",
      "name": "ORBRO"
    },
   
    "review": {
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": 4.9,
        "bestRating": 5
      },
      "author": {
        "@type": "Person",
        "name": "ORBRO"
      }
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": 4.9,
      "reviewCount": 99
    },
    "offers": {
      "@type": "AggregateOffer",
      "offerCount": 5,
      "lowPrice": 2000000,
      "highPrice": 15000000,
      "priceCurrency": "KRW"
    },

  }


  return (
    <>
      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={ogKey}
        ogDesc={ogDesc}
        ogImg={ogImg}
        ogUrl={ogUrl}
        jsonLd={jsonLd}
      />
      <BannerScroll product={'camera'} selectedGroup={1} />
      <StyledControlPage>

        <Purchase category={category} productName={productName} setProduct={setProduct} setImgGroupIndex={setImgGroupIndex}></Purchase>
        <Product productName={productName} imgGroupIndex={imgGroupIndex} ></Product>
        <Spec productName={productName}></Spec>
      
      </StyledControlPage>
    </>
  );
};

export default Outdoor;
