import {
    functionImg1D, functionImg1M, functionImg1T,
    functionImg2D, functionImg2M, functionImg2T,
    functionImg3D, functionImg3M, functionImg3T,
    functionImg4D, functionImg4M, functionImg4T,
    functionImg5D, functionImg5M, functionImg5T,
    functionImg6D, functionImg6M, functionImg6T,
    functionImg7D, functionImg7M, functionImg7T,
} from "@assets/images/main/access/components/index";

export default {

    functions: {

        title: '누구나 쉽게 사용 가능한<br/>소프트웨어',
        desc: '오브로는 8년간 출입 관리 경험을 토대로 소프트웨어를 개발하였습니다. 사용자 피드백과 요구사항을 반영하여 디자인 되었으며,<br/>현장에서의 편의성을 극대화 하였습니다. 이는 실제 사용자들의 요구를 반영하여 개발된 결과물로, 출입 관리에서의 실질적인 혜택을 제공합니다',
        line : 3,
        card: [
            {
                size : '4',
                tag: '사용자 등록',
                title: '휴대폰번호, 이메일로<br/>사용자 초대 가능',
                label: '관리자',
                img: {
                    desktop: functionImg1D,
                    tablet: functionImg1T,
                    mobile: functionImg1M
                },
            },
            {
                size : '4',
                tag: '출입 권한 관리',
                title: '손쉬운<br/>출입권한 관리',
                label: '관리자',
                desc: '출입 가능한 기기만 등록하면<br/>출입 권한 생성 끝.',
                img: {
                    desktop: functionImg2D,
                    tablet: functionImg2T,
                    mobile: functionImg2M
                },
            },
            {
                size : '2',
                tag: '엑셀 다운로드',
                title: '출입 기록, 사용자 정보<br/>다운로드 제공',
                label: '관리자',
                img: {
                    desktop: functionImg3D,
                    tablet: functionImg3T,
                    mobile: functionImg3M
                },
            },
            {
                size : '2',
                tag: '원격 제어',
                title: '원격 출입문<br/>제어 가능',
                label: '관리자',
                img: {
                    desktop: functionImg4D,
                    tablet: functionImg4T,
                    mobile: functionImg4M
                },
            },
            {
                size : '2',
                tag: '자동출입',
                title: '앱을 켜지않아도<br/>출입 가능',
                label: '사용자',
                img: {
                    desktop: functionImg5D,
                    tablet: functionImg5T,
                    mobile: functionImg5M
                },
            },
            {
                size : '4',
                tag: '모바일 위젯',
                title: '빠른 앱 실행이 가능한<br/>위젯 제공',
                label: '사용자',
                img: {
                    desktop: functionImg6D,
                    tablet: functionImg6T,
                    mobile: functionImg6M
                },
            },
            {
                size : '4',
                tag: '자동출입',
                title: '문이 열리는 거리를<br/>쉽게 설정',
                label: '사용자',
                img: {
                    desktop: functionImg7D,
                    tablet: functionImg7T,
                    mobile: functionImg7M
                },
            },
        ]
    },

}