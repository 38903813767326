import {
  constructionAsset,
  constructionSafety,
  logisticsAsset,
  logisticsSafety,
  manufacturingAsset
} from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { BlogOrbroWorld } from "@assets/images/blog";

export default {
  helmet: "| The Essence and Potential of Digital Twins",
  desc:
    "A digital twin is a way of digitally replicating physical entities and processes from the real world, allowing modeling and simulation of real-world systems in a digital space.",
  keywords:
    "Digital Twins, Digital Twin Technology, Value of Digital Twins, Digital Twin Use Cases, Future Technology, Digital Innovation, Future Vision, orbro, 오브로, ConTech",

  ogTitle: "",
  ogDesc: "",
  ogSite: "The Essence and Potential of Digital Twins :: Orbro Blog",
  ogUrl: "https://orbro.io/en/blog/digitalTwin2",
  ogKey: "",
  ogImg: "https://lh3.googleusercontent.com/fife/AK0iWDyMknjvJ99ToTqI13n6sJ8BSaf3JZGOENkN_2JU7vwTVdJ1mg0nffpOx5r6tq6lv4F0bOXSJdbY8A6oX1AFY03LIJf2JtFF2wRcEJOJaquWjtZsd0mWpQBq_pEUGTJFnlWDkhyXb4I74BgzY1m4mqHU6tEW28PtAaGDrwMAQtY2jBxkHkkc2CElFc6iCNlhM0CBo5Q5nJ5smX5h-I7jl-DfT5xZkB0go6qpIOi66-rEsvQSbT33lnaaD5QvGwFOO0Qv2cvnKoewfm0pA5WcKdE2-m7UMQJBBnPNaQSttqkpy3a1ROL6WpobJ4hXVx6u7sgSCjox5fUSiLYwOz7Ogj48pxEWoqBOPMi8xU6SPj1GkXJlNbKSLFiPh1Gxx4gAj6yna064glGTGfkFz4utstmCQDRyUfHIBNWgoRIA6w7sZTibsxQw0kVLOs1zvoSpcVnuqfTeCeMc2zVn-RPDhOYs47NdtJw15gD-7g2SEnQoPQ0jPuPsUlQK3cZiwGJPXqaUpk_bJEHoKljrUPvZ4RskxzXd_Wd_iUR_fAsXgtfYJA2y69CplR2X8zI2xNr6o3QMeEnodvetxx_wijsIzX0VB-NY9ARHuYoDLWeuluJd3L5Jwbz7RUt9Daau7aK0xPzczhs0wCZukWXKxEHTa4vsViogKLwCwQCGRH0i-etPSDQoL6QDgCZYMpi19QP2c4EpWmal3bbzPuDTXE9c5KGhCoKeadtLOyfRIQSXO4j3qfTLZDhlfYui2qpfof_b8iQDXbrYnKdQFKCF1BsOgdB-x6BFgyxy4yiJFodhm11ZsU2hE-FHcFe57GTIa-9BoCesvhAASwxcG3Jb87SMGY20XeoYQRdKybgwKJVtyHYIyr-96-_YGoT3K0R1g8Rh_-5g8ieKsx9xYxgBP_SRy15quSjqbY_sb-Br-ABcrOvK-f_8B3Fcs4MUyawnsk1F3Iq757aU6O9vr5eRVr0p_e617DypRhC-EsoUOnfuneCpYiwISUNEh7dzIWRwMoAg0nsWPpsaZBAFDzkfkWsNzdkHNsR51jz0otfpLr1G_F5MN4-TRI9fLTeBG72qfhqwexJIJ3hty3NLh45kAfx6b4jQVDuVOEloTq3hBRamRttcG_zUXLYPChDX4_px_PqdCbBFebzcfjbJUSwJddmFGYkV8Oc0z7dze6CyvuHcWRubrB7_QHLFSrKfkJAQWWwuL6pILkMJathIpb7hFq7aW9OEeRdf7IWj4VFsGIRvc-B_Pi7Rkvw4yTyX18g6trPF-jBAa7kbSdyNvEqsfT7SJLR2-N3Gt8Y4u2rF8_YwJ8xywkibJnFJwrmqFbsLwJJbquz__yB38C0nECD5y5NUoN1ZJVQ4g2ZhUEJAKWxvXGu7SqhfmR-BssX_5pHjrMO5KNdT4GzxR6UMQmqq0P81ai916GVxC9u-klTiMQxnWqlOAVF8oxfYqPkwAQ9gjipWXh2CpHh12DVNM22CcBvVmV3r35ystH3OAURGJoOQ0RSPUw6ULmaboU1q4tLaJa08YT-WN9q9XNnbjgHFb654mIrS4AI-5Sm_OQxMxxO7bymwwgJH5VMy=w2000-h1916",
  ogType: "website",

  twitterCard: "",
  twitterTitle: "",
  twitterDesc: "",

  title: "The Essence and Potential of Digital Twins",
  date: "2023-10-26",
  tag1: "#DigitalTwins",
  tag2: "#DigitalTransformation",
  tag3: "#SmartCity",
  tag4: "#RTLS",

  text: [
    {
      img: BlogOrbroWorld,
      link : "https://home.orbro.io",
      head: "",
      subHead: "",
      desc:
        "Modern society is constantly undergoing a process of evolution and change, primarily driven by the rapid advancement of digital technology. This innovation is underpinned by the concept of digital twins. Digital twins replicate physical entities and processes from the real world in a digital form, allowing us to model and simulate real-world systems in a digital space. This is revolutionizing how we work, live, and use technology.",
      dot: "",
    },
    {
      head: "1. The Concept of Digital Twins",
      subHead: "",
      desc:
        "Digital twins are digital replicas of physical entities, enabling the reproduction and monitoring of real systems or processes in a digital space. This digital replication captures all aspects of the real-world using sensor data, IoT devices, simulations, and big data analysis. It allows us to predict and optimize system operations.",
      dot: "",
    },
    {
      head: "2. The Value of Digital Twins",
      subHead: "",
      desc:
        "Digital twins provide value in various industry sectors. In manufacturing, digital twins are used to manage product lifecycles and optimize production processes. Monitoring product performance and using predictive analysis helps prevent failures in advance. In the construction industry, digital twins support the construction and operation of buildings and facilities. Through project planning, simulation, and monitoring, projects can adhere to schedules and manage budgets effectively. In the medical field, digital twins track patients' health and support early diagnosis and prevention of diseases. They are also used to optimize the development and production processes of medical devices and pharmaceuticals. In the energy sector, digital twins optimize the operation of power plants, power grids, and energy systems, enhancing energy efficiency.",
      dot: "",
    },
    {
      head: "3. Use Cases of Digital Twins",
      subHead: "",
      desc:
        "Orbro has already executed numerous projects applying digital twins to various industries, increasing work efficiency and productivity in settings ranging from hospitals to manufacturing facilities. Furthermore, the application of digital twins is continuously expanding. In the aerospace industry, they are used to monitor the status of aircraft and improve maintenance. In urban planning, they optimize a city's infrastructure and transportation systems. In agriculture, digital twins track crop growth and health, improving agricultural productivity. Smart city projects model every aspect of a city as a digital twin, promoting urban intelligence and sustainability. Digital twins are an innovative way to merge the physical and digital worlds, providing value in various industries, enhancing innovation and efficiency. This technology is expected to become even more critical in the future, and companies and organizations should actively leverage digital twins to enhance their competitiveness and achieve sustainable growth.",
      dot: "",
    },
    {
      head: "Conclusion: The Future and Potential of Digital Twins",
      subHead: "",
      desc:
        "Digital twins will undoubtedly become a key technology of the future. Their value is boundless, driving innovation and productivity in various industries. Of course, important considerations such as security and personal data protection exist, but these issues will continue to be addressed alongside technological advancements. Digital twins will revolutionize our daily lives and businesses, breaking down the boundaries between the real and digital worlds, creating a smarter and more connected world. We should embrace this technology actively and explore new possibilities centered around digital twins as we shape the future.",
      dot: "",
    },
  ],

  link: {
    page: "rtls",
    numb: "1",
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc:
          "A product that assists real-time location tracking by attaching UWB terminals to workers or equipment.",
        img: productImg.product.assetTracking.key1,
      },
      {},
    ],
    solution: [
      {
        img: constructionSafety.thumb,
        data: "constructionSafety",
        link: "/en/enterprise/construction/safety",
      },
      {
        img: logisticsAsset.thumb,
        data: "logisticsAsset",
        link: "/en/enterprise/logistics/asset",
      },
      {
        img: manufacturingAsset.thumb,
        data: "manufacturingAsset",
        link: "/en/enterprise/manufacturing/asset",
      },
    ],
  },
};
