import React from "react";
import { useTranslation } from "react-i18next";
import TitleBanner from "../Common/TitleBanner/TitleBanner";
import { StyledTechnologyContent } from "../Technology.style";
import { UwbIconLocation, UwbIconRuler, UwbIconSecurity } from "@assets/images/technology/uwb";
import TechTable from "../Common/TechTable/TechTable";
import Advantage, { AdvantagePropsItem } from "../Common/Advantage/Advantage";
import TechRequest from "../Common/TechRequest/TechRequest";
import UwbContent from "./components/UwbContent/UwbContent";
import UseCase from "../Common/UseCase/UseCase";
import i18n from "@pages/i18n";
import SEO from "@pages/SEO";
import CommonLang from "@pages/CommonStyle/commonLang";
import { FontPage } from "@pages/CommonStyle/commonDiv.style";
import { Spacing, SpacingDTM } from "@pages/CommonStyle/common.style";

const advantageItems: AdvantagePropsItem[] = [
  {
    icon: UwbIconRuler,
    title: "uwb.advantage.items.accuracy.title",
    description: "uwb.advantage.items.accuracy.description",
  },
  {
    icon: UwbIconLocation,
    title: "uwb.advantage.items.position.title",
    description: "uwb.advantage.items.position.description",
  },
  {
    icon: UwbIconSecurity,
    title: "uwb.advantage.items.security.title",
    description: "uwb.advantage.items.security.description",
  },
]

const UwbPage = () => {

  CommonLang();
  const { t } = useTranslation();
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "headline": "UWB 위치 추적 및 포지셔닝 기술",
    "image": [
    ],
    "datePublished": "2023-04-01T08:00:00+08:00",
    "dateModified": "2023-04-01T08:00:00+08:00",
    "author": [{
      "@type": "Organization",
      "name": "ORBRO",
      "url": "https://orbro.io"
    }]
  }
  const helmetTitle = t(`seo.tech.uwb.title`);
  const key = t(`seo.tech.uwb.key`);
  const desc = t(`seo.tech.uwb.desc`);
  const currentLang = i18n.language;
  let isKorean;
  if (currentLang === 'ko') {
    isKorean = true;
  } else {
    isKorean = false;
  }


  return (
    <>

      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={key}
        ogDesc={desc}
        ogImg="https://orbro.io/images/25b1046629ce21e8061dfdd4597ceccb.png"
        ogUrl={isKorean ? "https://orbro.io/enterprise/technology/uwb" : `https://orbro.io/${currentLang}/enterprise/technology/uwb`}
        jsonLd={jsonLd}
      />

      <SpacingDTM d={"64"} t={"48"} m={"32"} />
      <FontPage>
        <TitleBanner
          subtitle={"uwb.titleBanner.subtitle"}
          title={"uwb.titleBanner.title"}
          description={"uwb.titleBanner.description"}
        />

        <StyledTechnologyContent>
          <UwbContent />

          <TechTable columns={['uwb', 'ble', 'wifi']} />

          <Advantage title={"uwb.advantage.title"} items={advantageItems} />
        </StyledTechnologyContent>

        <UseCase type="uwb" />

        <TechRequest />
      </FontPage>
    </>

  );
};

export default UwbPage;