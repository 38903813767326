
import React, { PropsWithChildren } from 'react';
import { StyledGridContainer } from '@components/Grid/Grid.style';
import { Trans } from 'react-i18next';
import { ContentBox, ContentImg, ContentPage, ContentPara, ContentSection, ContentTag } from './Contents.style';
import solutionContents from '@src/lang/ko/enterprise/solutionContents';
import { Link, useLocation } from 'react-router-dom';

import * as blogBanner from "@src/pages/Enterprise/Blog/Common/BlogBannerImg";
import * as solutionBanner from "../Banner/BannerImages";

import { CommonState } from '@redux/reducers/commonReducer';
import { useSelector } from 'react-redux';
import { LabelColor, Spacing } from '@pages/CommonStyle/common.style';
import { DisplayH2 } from '@components/Text/HeadingTagDisplay.style';
import { DivAllCenter } from '@pages/CommonStyle/commonDiv.style';
import { HeadingH3 } from '@components/Text/Heading/Heading.style';


interface ModalDefaultType {
  pageName: string
  path: any
}



const Contents: React.FC<PropsWithChildren<ModalDefaultType>> = ({ pageName, path }) => {

  const location = useLocation();
  const data = path;

  const notHome = location.pathname.includes('/enterprise/');

  const isSolutionPage = location.pathname.includes('/enterprise/');

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  function getTransKey(contentData) {
    if (contentData.includes("construction")) {
      return `solutionCommon.contents.tag.construction`;
    } else if (contentData.includes("logistics")) {
      return `solutionCommon.contents.tag.logistics`;
    } else if (contentData.includes("office")) {
      return `solutionCommon.contents.tag.office`;
    } else if (contentData.includes("medical")) {
      return `solutionCommon.contents.tag.medical`;
    } else if (contentData.includes("manufacturing")) {
      return `solutionCommon.contents.tag.manufacturing`;
    } else if (contentData.includes("retail")) {
      return `solutionCommon.contents.tag.retail`;
    }
  }


  let langPath;
  if (location.pathname.includes('/en/')) {
    langPath = 'en'
  } else if (location.pathname.includes('/jp/')) {
    langPath = 'jp'
  } else if (location.pathname.includes('/cn/')) {
    langPath = 'cn'
  } else {
    langPath = 'ko'
  }

  return (
    <ContentPage notHome={notHome}>
      <StyledGridContainer>

        <DivAllCenter>
          <DisplayH2 size='small'>
            {isSolutionPage ?
              (<Trans i18nKey={`solutionCommon.contents.title`} />)
              :
              (<Trans i18nKey={`enterprise.update.title`} />)
            }
          </DisplayH2>
        </DivAllCenter>

        <ContentSection>
          {data.contents.map((content, index) => (

            <ContentBox key={index}>
              <Link to={langPath !== 'ko' ? `/${langPath}${solutionContents[content.data].link}` : `${solutionContents[content.data].link}`} target="_blank">
                {content.data.includes('blog') ?
                  <ContentImg src={blogBanner[content.data][layoutType]} alt='orbroContent' /> :
                  <ContentImg src={solutionBanner[content.data].thumb} alt='orbroContent' />
                }
              </Link>
              <Spacing pixel="16" />
              <ContentTag bgcolor={content.data.includes('blog') ? '#F8F8F8' : '#EAF1FD'}>
                <LabelColor size='xsmall' color={content.data.includes('blog') ? '#646464' : '#2A70F0'}>
                  <Trans i18nKey={`solutionContents.${content.data}.tag`} />
                  {!content.data.includes('blog') && (
                    <>
                      <Trans i18nKey={` | `} />
                      <Trans i18nKey={getTransKey(content.data)} />
                    </>
                  )}
                </LabelColor>
              </ContentTag>
              <Spacing pixel="8" />
              <HeadingH3 size='xsmall'>
                <Trans i18nKey={`solutionContents.${content.data}.title`} />
              </HeadingH3>

              <Spacing pixel="8" />
              <ContentPara size={'small'}>
                <p>
                  <Trans i18nKey={`solutionContents.${content.data}.desc`} />
                </p>
              </ContentPara>




            </ContentBox>

          ))}

          <Spacing pixel="32" />

        </ContentSection>
      </StyledGridContainer>
    </ContentPage>

  )
};

export default Contents;