import React from "react";
import { Helmet } from 'react-helmet';

const SEO = ({
  lang,
  helmetTitle,
  ogKey,
  ogDesc,
  ogImg,
  ogUrl,
  jsonLd
}: {
  lang: string;
  helmetTitle: string;
  ogKey: string;
  ogDesc: string;
  ogImg: string;
  ogUrl: string;
  jsonLd: object;
}): React.ReactElement => {


  const getHrefUrl = (ogUrl) => {
    let hrefUrl1 = ogUrl;
    let hrefUrl2 = ogUrl;
    let hrefUrl3 = ogUrl;
    let hrefLang1 = 'ko';
    let hrefLang2 = 'ko';
    let hrefLang3 = 'ko';
    let hrefLangOG = 'ko';
  
    if (ogUrl.includes('/en/')) {
      hrefUrl1 = ogUrl.replace('/en/', '/jp/');
      hrefUrl2 = ogUrl.replace('/en/', '/cn/');
      hrefUrl3 = ogUrl.replace('/en/', '/');
      hrefLang1 = 'ja-JP';
      hrefLang2 = 'zh-CN';
      hrefLang3 = 'ko-KR';
      hrefLangOG = 'en'
    } else if (ogUrl.includes('/jp/')) {
      hrefUrl1 = ogUrl.replace('/jp/', '/en/');
      hrefUrl2 = ogUrl.replace('/jp/', '/cn/');
      hrefUrl3 = ogUrl.replace('/jp/', '/');
      hrefLang1 = 'en';
      hrefLang2 = 'zh-CN';
      hrefLang3 = 'ko-KR';
      hrefLangOG = 'ja-JP'
    } else if (ogUrl.includes('/cn/')) {
      hrefUrl1 = ogUrl.replace('/cn/', '/en/');
      hrefUrl2 = ogUrl.replace('/cn/', '/jp/');
      hrefUrl3 = ogUrl.replace('/cn/', '/');
      hrefLang1 = 'en';
      hrefLang2 = 'ja-JP';
      hrefLang3 = 'ko-KR';
      hrefLangOG = 'zh-CN'
    } else {
      if(ogUrl === 'https://orbro.io'){
        hrefUrl1 = ogUrl.replace('.io', '.io/en/');
        hrefUrl2 = ogUrl.replace('.io', '.io/cn/');
        hrefUrl3 = ogUrl.replace('.io', '.io/jp/');
        hrefLang1 = 'en';
        hrefLang2 = 'zh-CN';
        hrefLang3 = 'ja-JP';
        hrefLangOG = 'ko-KR'
      } else {
        hrefUrl1 = ogUrl.replace('io/', 'io/en/');
        hrefUrl2 = ogUrl.replace('io/', 'io/cn/');
        hrefUrl3 = ogUrl.replace('io/', 'io/jp/');
        hrefLang1 = 'en';
        hrefLang2 = 'zh-CN';
        hrefLang3 = 'ja-JP';
        hrefLangOG = 'ko-KR'
      }
    }
  
    return { hrefUrl1, hrefUrl2, hrefUrl3,
      hrefLang1, hrefLang2, hrefLang3, hrefLangOG };
  };

  
  return (
    <>
      <Helmet>
        <html lang={lang} />
        <title>{helmetTitle}</title>
        <meta name="description" content={ogDesc} />
        <meta name="keywords" content={ogKey} />
        <meta name="robots" content="index, follow"/>
        <meta property="og:title" content={helmetTitle} />
        <meta property="og:site_name" content={helmetTitle} />
        <meta property="og:description" content={ogDesc} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={ogImg} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content={getHrefUrl(ogUrl).hrefLangOG }/>

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={helmetTitle} />
        <meta name="twitter:description" content={ogDesc} />
        <link rel="canonical" href={ogUrl} />
        <link rel="alternate" href={ogUrl} hrefLang={getHrefUrl(ogUrl).hrefLangOG}/>
        <link rel="alternate" href={getHrefUrl(ogUrl).hrefUrl1} hrefLang={getHrefUrl(ogUrl).hrefLang1}/>
        <link rel="alternate" href={getHrefUrl(ogUrl).hrefUrl2} hrefLang={getHrefUrl(ogUrl).hrefLang2}/>
        <link rel="alternate" href={getHrefUrl(ogUrl).hrefUrl3} hrefLang={getHrefUrl(ogUrl).hrefLang3}/>

        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>

    </>
  )
};

export default SEO;