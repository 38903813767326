import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4"

export default {
    helmet: '| What is a Smart Factory?',
    desc: 'A smart factory refers to a factory that automates and optimizes production lines using IoT technology and artificial intelligence. This enhances the efficiency of the production process, reduces maintenance time, and contributes to improving production output and quality.',
    keywords: 'digital twin, smart factory, smart manufacturing, nvr, ip, ptz, rfid, nfc, uwb, wireless communication, location tracking, object tracking, personnel tracking, tags, beacons, rtls, orbro, contech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'What is a Smart Factory? | Location Tracking and Digital Twin Platform, Orbro',
    ogUrl: 'https://orbro.io/en/blog/what-is-a-smart-factory',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDlrNJZcN0y8rqN6rn4lIBsYzyCBFCLngoMNzRGAY6aBK05F5A7p7wMg7ZuILGtqR0GyG3qa5nWoqrTkhzMc8RRj0tMC2erUg8B95SfXjNpxqhWAlZI2ZNQ20zf6Bqvfx3ztoqtHXcz2hhYcN11wxSut__qBoWsflYyVOeQHJ35WsxS_wN8WaNJKzx-dhLVFusRshtRF7Zx4doNUOlm_KUYkgX0IUQ1IeB6yFe3WYvOyZC8sqY1FMnRAfO22PRRK4uVETtZYRhz1rrYA_xT7mbQP94D71TrIFaoebXtygq-yfvQOvJ4UfR6wr4I2M4V0oN6suppqQ8YAOYRD1WYR_cbQeuw_KsCJ2wLvHZYc2LaJwaDpP3CO-Ygqw0gsMrOMEtf5U1WEMf903qg20rF0D8hqpBPnjJRCQ6KY2leZG3Xv2k7q5gSOcKTnqSEQTe7DpWKAbnscWRoK7j1HGIopA0MWEyVIu5Ux9VhBZUSMEgfQkKoAX2UL71HTDwV7xf_AMP2cVdj7u49LCN1Pcdq-eKXMygl_EcCyGvoZ_wp4Bq7Uh1dbm7IJe5A2TaDKbuzgY9JL-ZFhxhwVES7tvZlWP-B_TeatTd2RKPQ6FMoeblJYuiAjHopqXL9JO8-y5wEcVgWwSo6_2W1AosdLSjlS2dqAE827GZP9HjudpoEd7lQXyP3bdXE6n3DzSuUOtextrsHpP6vN6tZ7q6krYTztpHpdIN8naMIOpdy4O2pdvv1uz-fhax_TyYVUEXg6ld7JVk-hPSvGPIAVUDpUx00G4uQgiBq8U0EZlVtIPynqiSn0VJjVTkArpnM_WX3E05A4Qwt-1AS3SagPFHUABELaSJyL1L34cKVxK_BLvKBZlGnfpC1LqOfmjS5iBamKqpm4ctvMkBIwLKcxad4eYpEH1H_yQ9AxantgCTB-GHWAdLrVk0cA3jMjJfx-OpQRCUEE2BSLWbuTCDEtJpMFwRzLdHzcRjg8qbJgjJTV7KeVZCHqn9DoghaNW2r_ON-IXwyfgNGPNPqeGx81epFjGIdFEjaCGAgIGdsth-CUeTGc5Q69DrT_PAuAYnbN3F7Bj1XLyz0Ie97ogOtcAHLzQwCjNd0Ll1vl2A35yNX905gQEctPSAY9e91nlTtH6KmIgLYgtpBt-w4xOQIXh4_z5hZulvwYKL2lh7Xt-qXqpV-PGYYbnGgz20mwGck4DxmCnXztmOUOMWkwvZ6BFpIoA7ZA2EntRJ8Z6hI17yexqq-384ja6SLh_3QqtEALCOtwyxIYCqwJsSFPyGPp17Pz1RyvB-j2RZ23GCX1YKYo6STGlKOmlYxmV0spVQQIQVgamCYHkKTDfSr3KFe2-SyaJEaPRoIansSf_NTtNvwC8RD0AMr2HYs7iQ35mFLUS5KF2dTLlah-AmnppP4WbuZ-4T0McWZKlfAFjR_nLifHSgTF8XTBEa9XMQsjhzP3c_SWzbqMOkYR82Q9zVg6wacsuo537pPMZn5oxZcSFNdLKsmywtFpS9Pg5dWlyWI7fgd7sn-lu7-vhp8j6iz36SG2OsnjAuOrlL92W_P9=w1984-h1208',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'What is a Smart Factory?',
    date: '2024-01-12',
    tag1: '#smartfactory',
    tag2: '#smartmanufacturing',
    tag3: '#digitaltwin',
    tag4: '#locationtracking',

    text: [
        {
            head: 'What is a Smart Factory?',
            subHead: '',
            desc: 'A smart factory refers to a factory that automates and optimizes production lines using IoT technology and artificial intelligence. This enhances the efficiency of the production process, reduces maintenance time, and contributes to improving production output and quality. It revolutionizes the traditional concept of manufacturing, reducing costs incurred in the production process while enhancing the quality of products.<br/><br/>Smart factories, as an innovative production method rapidly spreading in modern manufacturing, are gaining attention for effectively utilizing digital twins and location tracking technology to increase productivity and optimize production processes.',
            dot: ''
        },
        {
            head: 'Functions of a Smart Factory',
            subHead: '',
            desc: 'Smart factories focus on intelligently managing and optimizing the production process by integrating various technologies and systems. This enhances productivity, reduces costs, and improves quality. Key functions of a smart factory include:',
            dot: {
                a: '<strong><br/> • Automation and Robotics Technology: </strong>Smart factories automate tasks on the production line by introducing advanced robots and automation systems, reducing labor costs and increasing productivity.',
                b: '<strong><br/> • Digital Twin Technology: </strong>Create a digital twin of products or production processes, modeling physical objects in the real world digitally. This allows real-time monitoring and optimization of the production process.',
                c: '<strong><br/> • Internet of Things (IoT) Technology: </strong>Utilize IoT devices connected to sensors to network all devices and equipment on the production line. This enables real-time data collection and efficient management of the production process.',
                d: '<strong><br/> • Location Tracking Technology: </strong>Utilize location tracking technologies such as RFID or GPS to track the real-time location of raw materials, products, and workers. This aids in resource management and efficient task allocation on the production line.',
                e: '<strong><br/> • Environmental Monitoring and Energy Efficiency: </strong>Smart factories focus on monitoring and minimizing energy consumption and environmental impact during the production process. They pursue environmentally friendly production methods through sustainable management.',
                f: '<strong><br/> • Cloud Computing: </strong>Store and manage production data in the cloud, allowing real-time access and sharing of data even from geographically distant locations. Facilitates smooth collaboration across different locations.',
            }
        },
        {
            video: video,
            link: '/en/enterprise/manufacturing/process',
            videoDesc: '(Click to explore Smart Factory solution)',
            head: 'Advantages of Smart Factories',
            subHead: 'Improved Productivity',
            desc: 'Automating repetitive and labor-intensive tasks through the introduction of automation and robotics technology enhances productivity. Additionally, using digital twin for real-time monitoring and optimization increases production efficiency.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Enhanced Quality',
            desc: 'Collecting and analyzing data generated on the production line using sensor and IoT technology helps monitor the production process. This reduces defect rates and improves product quality.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Cost Savings',
            desc: 'Introducing automated systems reduces labor costs, and predictive maintenance through big data analysis minimizes maintenance costs. Additionally, optimizing the production process increases resource efficiency, leading to cost savings.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Flexibility and Quick Adaptation to Changes',
            desc: 'Utilizing digital twin and smart manufacturing systems allows flexible adjustment and modification of the production line. This provides the ability to quickly establish or modify production lines for various products.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Real-time Monitoring and Decision-Making',
            desc: 'Collecting real-time data on the production environment through sensor data and IoT technology, and analyzing it quickly with big data and AI, enables swift understanding of the production line status and quick decision-making.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Resource Efficiency',
            desc: 'Smart factories adopt energy-efficient production methods to minimize energy consumption. They use big data and AI to monitor and optimize the efficient use of resources.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Environmentally Friendly Production',
            desc: 'Creating an environmentally friendly production environment through the eco-friendly design of production processes and energy-efficient production methods. This increases sustainability and minimizes environmental impact.',
            dot: ''
        },
        {
            head: 'Smart Factory Examples',
            subHead: '',
            desc: '',
            dot: {
                a: '<strong>• Toyota: </strong>Toyota is considered a leader in smart manufacturing. They actively introduce advanced technologies such as digital twin, robotics, and artificial intelligence to innovate their manufacturing processes. For example, advanced robots coexist with workers, and digital twin is used to monitor and optimize the production process in real-time.',
                b: '<strong>• Bosch Smart Factory: </strong>Bosch is establishing smart factories in the automotive and industrial technology fields. They optimize manufacturing processes by integrating digital twin and IoT, and use location tracking technology to track the movement of raw materials and products in real-time.',
                c: '<strong>• GE Smart Manufacturing Facility: </strong>GE, active in various fields such as electric power, renewable energy, and healthcare, actively adopts smart manufacturing technology. They monitor the condition of production facilities using digital twin and IoT, perform predictive maintenance through big data analysis, maximizing production efficiency.',
            }
        },
        {
            head: 'Building a Smart Factory',
            subHead: '1. Vision Establishment and Strategic Planning:',
            desc: 'Before building a smart factory, a company needs to establish the vision and plan the strategy of the smart factory. Clearly define what technologies to adopt and what goals to achieve.',
            dot: ''
        },
        {
            head: '',
            subHead: '2. Current Situation Analysis and Requirement Definition:',
            desc: 'Analyze the current production system and technological level, and define the requirements needed for the introduction of a smart factory. Identify strengths and weaknesses in the production process and identify areas that need improvement.',
            dot: ''
        },
        {
            head: '',
            subHead: '3. Technology Selection and Integration:',
            desc: 'Select from various technologies that make up a smart factory, such as digital twin, IoT, big data analysis, artificial intelligence, and automation. Build a system that efficiently integrates these technologies for use.',
            dot: ''
        },
        {
            head: '',
            subHead: '4. Introduction of Automation and Robotics:',
            desc: 'Automate part or all of the production line and introduce robots to perform labor-intensive tasks automatically. This enhances productivity and',
            dot: ''
        },
        {
            head: '',
            subHead: '5. Building Digital Twin:',
            desc: 'Create a digital twin of products or the production process. This involves replicating physical objects or processes in the real world digitally, allowing for monitoring and optimization.',
            dot: ''
        },
        {
            head: '',
            subHead: '6. Introduction of Internet of Things (IoT) Technology:',
            desc: 'Introduce sensors and IoT technology to connect all devices and equipment on the production line and collect data in real-time. This enables monitoring and optimization of the production process.',
            dot: ''
        },
        {
            head: '',
            subHead: '7. Introduction of Location Tracking Technology:',
            desc: 'Introduce location tracking technologies such as RFID, GPS, UWB RTLS to track the real-time location of products, resources, and workers. This facilitates optimal resource allocation.',
            dot: ''
        },
        {
            head: '',
            subHead: '8. Application of Big Data and Artificial Intelligence (AI):',
            desc: 'Utilize big data technology to collect and analyze production data. Use AI to find patterns in data and perform predictive analysis. This optimizes the production process and allows for predictive maintenance.',
            dot: ''
        },
        {
            head: '',
            subHead: '9. Introduction of Cloud Computing:',
            desc: 'Store and process production data in the cloud, allowing real-time access and centralized monitoring of data.',
            dot: ''
        },
        {
            head: '',
            subHead: '10. Strengthening Security:',
            desc: 'Security is crucial in smart factories where a lot of data is exchanged. Introduce appropriate security systems to ensure data integrity and confidentiality.',
            dot: ''
        },
        {
            head: '',
            subHead: '11. Education and Infrastructure Improvement:',
            desc: 'Conduct training for employees to effectively use new technologies and systems. Improve infrastructure if necessary to support the establishment of a smart factory.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'A product that assists real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }
        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/en/enterprise/medical/patient'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/en/enterprise/retail/exhibition'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/en/enterprise/office/asset'
            }
        ]
    }
}
