import React, { PropsWithChildren, useState } from "react"
import franchise from "@src/lang/ko/support/franchise"
import { LabelColor } from "@pages/CommonStyle/common.style"
import { Trans } from "react-i18next"
import { DropdownMenu, DropdownMenuItem, DropdownLayout } from "./Dropdown.style"


interface ModalDefaultType {
  type : string,
  onClickToggleModal: (data : string, type : string) => void;
}

function Dropdown({
  type,
  onClickToggleModal,
}: PropsWithChildren<ModalDefaultType>) {


  return (
    <>
      <DropdownLayout>
        <DropdownMenu side={type}>
          {franchise.requestForm[type].map((data, i) => (
            <>
              <DropdownMenuItem onClick={() => onClickToggleModal(franchise.requestForm[type][i].text, type)}>
                <LabelColor color={""} size={"medium"}>
                  <Trans i18nKey={`franchise.requestForm.${type}.${i}.text`}/>
                </LabelColor>
              </DropdownMenuItem>
            </>
          ))}
          
        </DropdownMenu>
      </DropdownLayout>
    </>
  )
}

export default Dropdown;