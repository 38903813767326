import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet : '',
  desc : 'RTLS(Real-Time Location System)은 실시간으로 위치 정보를 추적하고 모니터링하기 위한 기술로, 이를 활용한 디지털 트윈은 현실 세계의 물리적인 시스템이나 공간을 디지털적으로 모사하여 실시간으로 관리하고 분석하는 시스템입니다.',
  keywords : 'Digital Twin, Digital Twins, 디지털 트윈, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle : '',
  ogDesc : '',
  ogSite : '디지털 트윈과 실시간 위치추적 시스템 :: 오브로 블로그 ',
  ogUrl : 'https://orbro.io/blog/digitalTwin',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EObM89yl-Fckym9cymsbb7S7KBZ-fORj-iI1ch019MZXKtGLBD8Q6iWqftjthjE-64HwCxCX7XRPR10bcSBzxfbbHdVe_fmhy-hRrTTbEqa1kmSlG5WJwe_aFIG3PMwmlcPU90rQL2e9sjumg_2pSrR0sgMw6IN_LEwA-To3jUhSTh42IZmzhH0_1e5TJi90KWyaQEDki6hm93269tWDF2Xfm3oZpVefWKgJ7Xnv0NVZ3NMHBGkLEZz236wZHYh8GuRwDBtgHG8EKrm3c0X_9wkhJsdZATZNeCHPBUqyEDFMGmqaOnj5TYLfoJ25Il3v1m91oZtuoSchfnhmgHThYM6qbzj5ID4OSkyrRre3W3EpjouXL3zLHCJSOvOoHA6Kc3r_K58o7PuCQtVLs0b-lmcGHhXfC4peLT-9_8cWEisLN9cPEA_4hg5HT3tZ3zW9xG0WaU809hPnE9sBfCUriBFXpQaHHI6SIZ6lDLYSYJ2AzxYXxTqNWcCd3cvdbm4Vv9zzDaYP8phmcP1-TVhwKjMS3djddsWAPjxzbbqx4X6P4S3O5qCgKsCPMzOSiYDGSneF9xLkcFpLm13KT7xUcDV4wWlR-PrM01GTS66BvLHflJa_pg0ZtJte4DAIBu8TPsiDoiLjxT1WB69G-OYqtGnDrXVpUJkk8hhnK2n02PqcAwDA6s60Zvo_qKRnDXqbwkYe1qc_pwctFBShNNA-2NZATqu3r4Fl2gLy07d1dG9Rz39jB7tKKIWSeTBl-bpslCsuyWQaK6IxF19oGBhANRlIwpMHQfSJOWVSeE0QtKA4hlzcONIb28Iz8FfnYJUlNL_mEEKfC2QsgCP9QhKJ9xHYPPn_GBZ8TFuQSZvsDQvJ64s1teGa1Z8KdsteqgEG7iP_QxD_hfYHTEZc4c-EiDHiwwlrKrHPp5hLPgjMr9mWLUinWGKPTnPS39qBnM9Qq4cscUTNNGRDPumUYh7NIDmCR5PZ60qw2qCTSOcmlOQGvIaiZSEj9VpXBoLgEmJQHBylfdfduwNMLkIbbBdu9HKVJhcKkcIM0pgxhUjIX1yLgaNBg2GPWwEW9ep8pBep3OFKcdtehYRxHKLcCC6-69tQcLLslYLGTFyGoVokZ4dtBpy1lLWIG_YraeJ7yalMNg2J4yOYvwoLTInt3G6hSnU37a6twsvEp9TJR6z9jo2A9chkGqWyeg7bXB4Sc8-lIwwhlEXGQgW1MWX-h9kBUvZNEs0-_Jx6Drdfi0vYIfaK5-6GhfEZTVGuS6zzHCCpUNMFfu3Cu06IA7BWvunUR10RDVViw1gKdqrBdd5tgyrTqBoPEUaaBJIK9Dw0UcMRSP97gbKb6Slr49dZCyN2toItkq1fWOxtM6Of7AUzY3aGinq1R675xtbTceH2_QpwYO9HwgJbLHYdDtsgeum0buiGPEhInEHCe44zxXvOYzNzs1fRs9aPAM-H_ukO3HPnSAJ8yHWnnKTZ90M-KQQ_PMMuVgY7EKiGZMDGPNxxN-yVxPjSbFoQPtWLvLWA2XkWK8ExgJN4YuLR29U-f8ZiwcIQQwNA3gWp3qNF7QwyMjjZCg=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
  
  title: '디지털 트윈과 실시간 위치추적 시스템',
  date : '2023-04-22',
  tag1 : '#디지털트윈',
  tag2 : '#RTLS',
  tag3 : '#위치추적',
  tag4 : '#UWB',
  
  // <br/>
  text : [
    {
      head : '',
      subHead :'',
      desc : '코로나와 전쟁으로 인한 장기간의 봉쇄와 글로벌 공급망의 붕괴로 인해 많은 산업 기술들이 침체되었지만, 산업에서는 많은 기술들이 부활하고 이러한 기술의 장점들이 더욱 분명해지고 있습니다. 많은 제조, 물류 및 대중 교통 회사들이 인력 부족과 더욱 엄격한 마감 기한으로 고민하고 있을 때, 능숙한 매니저들은 부담을 줄일 수 있는 기술적인 솔루션을 찾고 있습니다. 이에 따라 RTLS와 Digital Twins 시장 성장 전망은 확실해졌습니다. <br/>RTLS와 Digital Twins는 제조 산업에서 발전되었지만, 이제는 다양한 산업에서도 활용되며, 새로운 활용 분야를 찾아가고 있습니다. 이러한 기술들은 생산성 향상과 더불어, 인력부족과 촘촘한 업무 일정등에 대한 대응책으로 많은 기업에서 적극적으로 도입하고 있습니다.',
      dot : ''
      
    },
    {
      head : 'RTLS 수요 증가',
      subHead :'',
      desc : '조사에 따르면, RTLS 시장은 현재 25.1% CAGR 성장률로 성장할 것으로 예상됩니다. 2030년까지 더 많은 기기들이 인터넷에 연결됨에 따라, 기업들은 모바일 및 웨어러블 기술을 통해 생산성을 높이고 더 나은 인사이트를 얻을 수 있게 될 전망입니다. 위치 추적 기술의 사고 예방 및 오류 회피 애플리케이션 적용이 늘어나면서, 새로운 RTLS 판매를 촉진하고 있습니다. 또한, 팬데믹 이후 건강 관련 응용 분야의 급증도 이러한 성장을 견인하고 있습니다.<br/>아시아-태평양 지역에서 산업용 IoT 채택의 증가는 교통, 제조, 보건의료, 정부 및 국방 산업에서 RTLS 채택의 성장을 촉진하고 있습니다. 인도의 제조업 섹터가 1조 달러 기준선을 향해 나아가면서, 창고, 운송 및 물류 등에서도 거대한 RTLS 계약이 형성되고 있습니다.',
      dot :''
    },
    {
      head : '스포츠 분야에서의 RTLS',
      subHead :'',
      desc : '최고의 스포츠 선수와 팀은 항상 자신들의 경기력을 향상시키는 방법을 찾고, TV 네트워크는 항상 스포츠 팬들의 시청 경험을 개선하는 방법을 찾고 있습니다. 지난 일년간 RTLS는 이러한 두 가지 측면에서 다양한 방식으로 도움을 주고 있습니다. 우선, 농구 훈련과 경기에서 선수의 공 컨트롤 기술 및 속도, 가속도와 같은 중요한 지표를 분석하는 데 사용되는 위치 추적 시스템의 도입이 보여졌습니다. <br/> 카타르 월드컵에서는 축구공이 UWB 기술을 활용하여 공의 각 접촉마다 기록될 수 있었습니다. 이 데이터는 빠르고 정확한 오프사이드 판정을 내리는 데 도움을 주었으며, 라이브 TV 방송 및 경기장 모니터에 투영될 수 있는 이미지를 생성하여 팬들이 심판 판정을 이해할 수 있게 해주었습니다. 이는 카타르 월드컵이 기록상 가장 평화로운 대회가 된 것에 기여한 것으로 생각됩니다.',
      dot : {}
    },
    {
      head : '의료 분야에서의 RTLS 성장',
      subHead :'',
      desc: '병원 및 요양시설의 복잡성과 높은 위험성 때문에 의료 분야는 RTLS의 진출이 어렵던 분야 중 하나였습니다. 하지만 최근에는 다양한 목적으로 RTLS 솔루션 수요가 높아지고 있습니다. <br/><br/>많은 병원과 요양 시설에서는 코로나19 대유행을 예방하기 위해 환자를 추적하기 위해 RTLS를 사용하고 있습니다. 이는 병원의 운영에 큰 압박을 줄 수 있는 대유행을 방지하기 위한 목적입니다. 또한 RTLS를 사용하여 병원 내 인력과 자원의 흐름을 최적화하여 장비 사용 및 환자 예약에 대한 자동 일정을 생성하여 추가적인 비용 절감을 추구하고 있습니다. 심지어 소송도 RTLS를 이용한 비용 절감 방안의 일환으로 삼아지고 있습니다. 병원은 RTLS를 사용하여 운영을 모니터링하여 과실 비난을 방지하는데 도움이 될 것으로 기대하고 있습니다. <br/><br/>가상 병동은 새로운 개념은 아니지만, RTLS는 중앙 집중식 플랫폼에서 환자 관리를 더욱 원활하게 할 수 있게 해줍니다. 호주 정부 보건부는 "자동 보고서 작성은 노인 요양 직원이 보고서 작성에 할애하는 시간을 줄이고 관련 비용을 감소시킬 것입니다"라고 주장합니다. 착용형 센서를 사용하여 환자의 건강 상태를 집에서 모니터링하면 전통적인 방식보다 더 빠르게 의사에게 문제를 알릴 수 있습니다. 이로 인해 치료가 더 필요한 환자의 병상을 확보하게 되어 대기 환자를 줄이는데 도움이 되고 있습니다. 싱가포르에서는 성공적인 시범 운영 이후 건강부가 가상 병동 시범 사업을 확대하고 있습니다.',
    },
    {
      head : 'Digital Twin의 등장',
      subHead :'',
      desc :'한 보고서에 따르면, 디지털 트윈 시장은 현재 95억 달러 가치를 보유하고 있으며 2032년까지 726.5억 달러가 될 전망입니다. 현재는 운송 및 자동차 부문이 디지털 트윈 기술의 가장 큰 시장 점유율을 가지고 있지만, 의료, 통신, 부동산 및 소매업 등으로의 확산이 이미 진행 중입니다. RTLS와 디지털 트윈 기술이 밀접하게 연결되어 있기 때문에, 아시아 태평양 지역에서도 유사한 성장 패턴이 나타날 것으로 예상되며, 다음 몇 년간 40%의 CAGR이 가능할 것으로 예상됩니다.',
    },
    {
      head : '도시의 Digital Twin',
      subHead :'',
      desc :'이전에는 디지털 트윈이 일반적으로 생산 라인이나 자동차 공장, 물류 센터 등에 한정되어 있었으나, 최근에는 전체 도시에 적용된다는 개념이 현실이 되기 시작했습니다. ITC, BS 및 ET의 연구진은 디지털 트윈이 도시 계획 및 조정에 유용함을 증명한 연구 보고서를 발표했습니다. 이 연구의 주 저자인 Ville Lehtola 박사는 "디지털 트윈은 도시 관리자, 주민 및 기업들이 도시의 인프라와 서비스에 대한 최신 정보에 액세스할 수 있도록 함으로써 도시에 중요한 이점을 가져다 줄 수 있습니다"라고 말했습니다. <br/><br/> 도시 전체에서 디지털 트윈을 사용하는 광범위한 목적은 시간과 비용을 절약하고 도시 환경을 더 지속 가능하게 만드는 것입니다. 이를 위해서는 교통 소음, 태양 복사량, 도로 안전, 대중 교통 체계, 쓰레기 처리 및 경찰 및 소방 대응 시간 모니터링이 필요합니다. 이러한 영역에서 데이터를 처음으로 모아서 도시의 삶의 질을 극적으로 향상시킬 수 있습니다.<br/><br/>대부분의 도시에서는 이것이 가설적인 것으로 남아 있지만, 영국 버밍엄에서는 도시의 동쪽 지역에 이점을 제공하기 위해 디지털 트윈이 이미 구현되고 있습니다. 이 시스템을 디지털 "테스트베드"로 사용하여 스마트 홈, 깨끗한 공기 구역 및 지역 성장 전략의 영향을 확인하려는 것이 목표입니다. RTLS와 디지털 트윈 분야는 매우 흥미로운 발전을 보이고 있습니다. 이 공간은 빠르게 발전하고 있으며, 시장 성장 예측은 제조업 전반뿐만 아니라 항공우주, 헬스케어 및 대중교통 분야에서 엄청난 혜택을 제공할 것으로 보입니다.',
    },

  ],


  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      }
    ]


  }

}