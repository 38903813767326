import { TwrIconClock, TwrIconGridPlus, TwrIconScale, TWRImg1Desktop, TWRImg1Mobile, TWRImg1Tablet } from "@assets/images/technology/twr";
import { StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import Advantage, { AdvantagePropsItem } from "@pages/Enterprise/Technology/Common/Advantage/Advantage";
import { DivPaddingRight, IndentText, StyledTechnologyGroup, StyledTechnologyImg, StyledTechnologyText, StyledTechnologyTitle } from "@pages/Enterprise/Technology/Technology.style";
import { CommonState } from "@redux/reducers/commonReducer";
import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import twr from "@src/lang/ko/technology/twr";
import { Spacing } from "@pages/CommonStyle/common.style";
const advantageItems:AdvantagePropsItem[] = [
  { 
    icon: TwrIconClock, 
    title: "twr.advantage.items.realTime.title",
    description: "twr.advantage.items.realTime.description",
  },
  { 
    icon: TwrIconScale, 
    title: "twr.advantage.items.twoWay.title",
    description: "twr.advantage.items.twoWay.description",
  },
  { 
    icon: TwrIconGridPlus, 
    title: "twr.advantage.items.flexible.title",
    description: "twr.advantage.items.flexible.description",
  },
];

const img1 = {
  desktop: TWRImg1Desktop,
  tablet: TWRImg1Tablet,
  mobile: TWRImg1Mobile
};

const TWRContent = () => {
  

  // Redux
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  // Memo
  const textList = useMemo(() => twr.definition.list, []);

  return (
    <>
      <StyledTechnologyGroup>
        <StyledGridRow>
          <StyledGridColumn desktop={6} tablet={4} mobile={4}>
            <DivPaddingRight pixel={layoutType !== 'mobile' ? '40' : ''}>
              <StyledTechnologyTitle size="small"><Trans i18nKey={ "twr.definition.title"}/></StyledTechnologyTitle>
              <StyledTechnologyText size="medium">
                <Trans i18nKey={"twr.definition.text"} />
              </StyledTechnologyText>

              { textList.map((text, index) => (
                <IndentText 
                  size="medium"
                  key={`text-list-${index}`}
                >
                  <Trans i18nKey={ `twr.definition.list.${index}` }/>
                </IndentText>
              )) }

              <StyledTechnologyText size="medium">
                <Trans i18nKey={"twr.definition.text2"} />
              </StyledTechnologyText>
            </DivPaddingRight>
          </StyledGridColumn>

          <StyledGridColumn desktop={6} tablet={4} mobile={4}>
            <StyledTechnologyImg src={img1[layoutType]} alt="TWR" />
          </StyledGridColumn>
        </StyledGridRow>

        <Spacing pixel="64"/>
        <Advantage
          title={"twr.advantage.title"}
          items={advantageItems}
        />
      </StyledTechnologyGroup>
    </>
  );
};

export default TWRContent;