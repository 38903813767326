import { accessAuto1, accessAutoMain, accessPro1, accessProMain, accesscontroller1, accesscontrollerMain } from "@assets/images/main/access/product";

export default {

    specTitle : {

    },
    specData : [
      {
        data: [
          {title : "製品名"},
          {text1: "モバイルアクセス"},
          {text2: "自動ドア出入り"},
          {text3: "顔認識出入り"},
        ],

      },
      {
        data: [
          {text: "説明"},
          {text: "モバイルアプリで出入り口を制御する非常に簡単な機器"},
          {text: "アプリ実行なしで便利なアクセスが可能な機器"},
          {text: "顔認識,NFCカードなど様々な出入りを一度に使用できる機器"},
        ],

      },
      {
        data: [
          {text: "電源供給"},
          {text: "バッテリー使用(最大3年使用)"},
          {text : "DC 12V, 24V "},
          {text : "DC 12V, 3A"},
        ],

      }


  ],

  a: [
    {text: "製品名"},
    {text: "説明"},
    {text: "電源供給"},
    {text: "通信方式"},
    {text: "ネットワーク"},
    {text: "規格"},
    {text: "インストールサポート"},
    {text: "人測位"},
    {text: "ワンパス機能"},
    {text: "リアルタイム機器モニタリング"},
    {text: "遠隔制御"},
    {text: "NFCアクセス"},
    {text: "顔面認識出入り"},
    {text: "QRコードへのアクセス"},

  ],


  b: [
    {text: "モバイルアクセス"},
    {text: "モバイルアプリで出入り口を制御する非常に簡単な機器"},
    {text: "バッテリー使用(最大3年使用)"},
    {text : "Bluetooth, BLE"},
    {text: "不要"},
    {text : "85.4 x 85.4 x 24.5(mm)"},
    {text : ""},
    {text : ""},
    {text : ""},
    {text : ""},
    {text : ""},
    {text : ""},
    {text : ""},
    {text : ""},

  ],


  c: [
    {text: "自動ドア出入り"},
    {text: "アプリ実行なしで便利なアクセスが可能な機器"},
    {text : "DC 12V, 24V"},
    {text : "Bluetooth, BLE"},
    {text: "不要"},
    {text : "210.1 x 60.1 x 42.2(mm)"},
    {text : "1"},
    {text : "1"},
    {text : "1"},
    {text : ""},
    {text : ""},
    {text : ""},
    {text : ""},
    {text : ""},

  ],
  
  d: [
    {text: "顔認識出入り"},
    {text: "顔認識,NFCカードなど様々な出入りを一度に使用できる機器"},
    {text : "DC 12V, 3A"},
    {text : "Bletooth, BLE, Wi-Fi, NFC"},
    {text: "必要"},
    {text : "256 x 273 x 24(mm)"},
    {text : "1"},
    {text : "1"},
    {text : "1"},
    {text : "1"},
    {text : "1"},
    {text : "1"},
    {text : "1"},
    {text : "1"},

  ],
  spec: [
    
    {

    },

    {
      image : accesscontrollerMain,

    },


    {
      image : accessAutoMain,

    },

    {
      image : accessProMain,

    
    },
      
  ],
    
    
}

