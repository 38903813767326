import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledParagraph } from "@components/Text";
import React, { useEffect, useState } from 'react';
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";
import { LabelColor, ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import { BeaconPage, CardTextBox, DescBox, Layout, NormalCard } from "./Solution.style";
import { useLocation } from "react-router-dom";
import { HeadingH3white } from "@components/Text/Heading/Heading.style";

import home from "@src/lang/ko/main/home/home";
import homeEn from "@src/lang/en/main/home/home";
import homeJp from "@src/lang/jp/main/home/home";
import homeCn from "@src/lang/cn/main/home/home";
import { BannerBtnAtag } from "../Banner/Banner.style";
import i18n from "@pages/i18n";


const Solution = () => {


  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  const [layoutMaxWidth, setLayoutMaxWidth] = useState<any>(null);
  const location = useLocation();

  const getPath = (i : number) => {
    if(location.pathname.includes('/en/')){
      return homeEn.solution.card[i].link;
    } else if(location.pathname.includes('/jp/')){
      return homeJp.solution.card[i].link;
    } else if(location.pathname.includes('/cn/')){
      return homeCn.solution.card[i].link;
    } else {
      return home.solution.card[i].link;
    }
  }
  useEffect(() => {
    const handleResize = () => {
      setLayoutMaxWidth(window.innerWidth);
    }

    setLayoutMaxWidth(document.documentElement.clientWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <StyledGridContainer>
        <BeaconPage>
          <DivAllCenter>
            <DisplayH2 size="small">
              <Trans i18nKey={`home.solution.title`} />
            </DisplayH2>
            <Spacing pixel="16" />
            <StyledParagraph size="medium">
              <p>
                <Trans i18nKey={`home.solution.desc`} />
              </p>
            </StyledParagraph>
            <Spacing pixel="16" />
              <BannerBtnAtag href={i18n.language === 'ko' ? "/request" : `/${i18n.language}/request`}>
                <LabelColor color={"#FFF"} size={"large"}>
                  <Trans i18nKey={`home.solution.button`} />
                </LabelColor>
              </BannerBtnAtag>
            <Spacing pixel="64" />
          </DivAllCenter>
          
          <Layout>
            {home.solution.card.map((data, i) => (
              <>
                <NormalCard w={layoutMaxWidth} img={data.img[layoutType]}
                  href={getPath(i)}
                  target="_blank"
                  rel="noopener noreferrer">
                  <CardTextBox>
                    <DescBox>
                      <HeadingH3white size="small">
                        <Trans i18nKey={`home.solution.card.${i}.title`} />
                      </HeadingH3white>
                      <Spacing pixel="4" />
                      <ParagraphColor color={'#FFF'} size={"medium"}>
                        <p>
                          <Trans i18nKey={`home.solution.card.${i}.desc`} />
                        </p>
                      </ParagraphColor>
                    </DescBox>
                  </CardTextBox>
                </NormalCard>
              </>
            ))}
          </Layout>

        </BeaconPage>
      </StyledGridContainer>

    </>
  )
}

export default Solution;