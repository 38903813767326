import styled from "@emotion/styled";


export const DigitalTwinPage = styled.div`
    background-color : #000;

    & * {
        font-family : Pretendard ! important;
        font-display: swap;
    }
`;