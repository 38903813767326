import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconInfo, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo4.mp4";

export default {
    banner: {
      title: 'Real-time Asset Management',
      desc: 'Manufacturing facilities hold numerous inventories such as raw materials and semi-finished products. Properly managing these inventories is crucial in terms of time and economic aspects. It is a significant factor that affects the efficiency of the manufacturing industry.',
    },
    video: {
      title: 'How to Manage Asset More Accurately and Efficiently',
      desc: 'Real-time Location Tracking System (RTLS) technology provides real-time information about asset locations. It takes almost no time to locate the assets.<br/>Moreover, it enables quantity management, leading to a level of productivity that is different from before using RTLS.',
      play: video,
    },
    func: [
      {
        title: 'Asset Tracking',
        desc: 'Track asset locations in real-time, allowing for precise and efficient management of asset movements and rotations. It also provides meaningful data for production planning and forecasting.',
        sub1: 'High Accuracy',
        icon1: IconRulerGray,
        sub2: 'Fast Data Integration',
        icon2: IconShareGray,
        video: subVideo1,
      },
      {
        title: 'Loss Prevention',
        desc: 'Detect and take immediate action in case of loss or theft. Additionally, by storing asset in the correct location and using it at the right time, asset loss can be minimized.',
        sub1: 'Real-time Status Overview',
        icon1: IconClockGray,
        sub2: 'High Accuracy',
        icon2: IconRulerGray,
        video: subVideo2,
      },
      {
        title: 'Optimization of Turnover Rate',
        desc: 'Effectively arrange asset, reduce the turnover period of asset, and prioritize the use of older asset to improve turnover rate. This reduces asset holding costs and increases financial efficiency.',
        sub1: 'Real-time Location Tracking',
        icon1: IconBinocularsGray,
        sub2: 'Statistical Data Provision',
        icon2: IconChartBarGray,
        video: subVideo3,
      },
      {
        title: 'Accurate Shipment',
        desc: 'Quickly verify asset locations to extract the required products for orders from the correct location and ensure the accuracy of shipments. This reduces order processing time, minimizes errors, and improves customer service quality.',
        sub1: 'Real-time Status Overview',
        icon1: IconClockGray,
        sub2: 'High Accuracy',
        icon2: IconRulerGray,
        video: subVideo4,
      },
    ],
    subfunc: {
      title: 'Various Features',
      desc: 'Accurate asset management reduces wasted time and expenses while increasing productivity. ORBRO provides various features to create an optimized manufacturing environment.',
      data: [
        {
          icon: IconBinocularsGray,
          title: 'Asset Forecast',
          desc: 'Minimize holding costs and improve the responsiveness and efficiency of asset supply.',
        },
        {
          icon: IconBell,
          title: 'Real-time Alert Function',
          desc: 'Send alerts when abnormal situations occur with asset, allowing for immediate response.',
        },
        {
          icon: IconRoute,
          title: 'Route Tracking',
          desc: 'Track the movement routes of asset and convert them into data.',
        },
        {
          icon: IconDashboard,
          title: 'Status Overview',
          desc: 'Identify the current location of specific asset.',
        },
        {
          icon: IconWarning,
          title: 'Deviation Detection',
          desc: 'Detect when asset deviates or moves to the wrong location.',
        },
        {
          icon: IconInfo,
          title: 'Risk Management',
          desc: 'Ensure that asset is safely stored and managed in appropriate locations.',
        },
        {
          icon: IconChainLink,
          title: 'Data Integration',
          desc: 'Integrate with systems such as RFID, IoT, security, and video for efficient utilization.',
        },
        {
          icon: IconSecurity,
          title: 'Data Security',
          desc: 'Maintain a high level of data security to provide a reliable solution.',
        },
      ],
    },
    system: {
      title: 'System Configuration',
      img: rtlsSystem,
    },
    productTitle: 'Product Configuration',
    product: [
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'manufacturingProcess',
      },
      {
        data: 'logisticsAsset',
      },
      {
        data: 'blogMedical',
      },
      {
        data: 'blogRTLS',
      },
    ],
  }
  