import { IconOsOff, IconOsOn } from '@assets/images/main/icon';
import { loraGatewayOn, loraGatewayOff, loraSensorOn, loraSensorOff, airOn, airOff, coOn, coOff } from '@assets/images/main/index';

export default {
  bannerData: [
    {
      icon: loraGatewayOn,
      iconInv: loraGatewayOff,
      image: '',
      iconText: 'LoRa<br/>网关',
      sub: 'New',
      link: '/cn/sensor/LoRaGateway'
    },
    {
      icon: loraSensorOn,
      iconInv: loraSensorOff,
      image: '',
      iconText: 'LoRa<br/>传感器',
      sub: 'New',
      link: '/cn/sensor/LoRaSensor'
    },
    {
      icon: airOn,
      iconInv: airOff,
      image: '',
      iconText: '空气质量<br/>监测仪',
      sub: 'New',
      link: '/cn/sensor/air'
    },
    {
      icon: coOn,
      iconInv: coOff,
      image: '',
      iconText: '一氧化碳<br/>监测仪',
      sub: '',
      link: '/cn/sensor/co'
    },
    {
        icon: IconOsOn,
        iconInv: IconOsOff,
        image: '',
        iconText: 'ORBRO OS',
        sub: 'New',
        link: '/cn/os'
    },
  ],
}
