export default {
    
  download: "Download",
  safety: "Construction Site Safety Management Solution <br/> Download Material",
  assets: "Warehouse Assets Location Analysis <br/> Download Material",
  medical: "Hospital Medical Equipment Management and Tracking <br/> Download Material",
  process: "Real-time Production Process Monitoring <br/> Download Material",
  visitor: "Exhibition Visitor Path Analysis <br/> Download Material",
  description: "We will send the material to your email.",
  form: {
    emailPlaceholder: "name@company.com",
    policy: {
      info: "By providing information, you agree to Orbro's ",
      link: "Privacy Policy ",
      agree: "policy.",
      check : "[Required]"
    },
  },
  cancel: "Cancel",
  submit: "Download",
  send: "Sent the material <br/> to your email.",
  newsletter: "To subscribe to Orbro's newsletter, please fill out the form below! Subscribers will receive the latest news and information via email.",
  name: "Name (required)",
  namePlaceholder: "John Doe",
  team: "Affiliation (required)",
  teamPlaceholder: "Company Name",
  phone: "Phone Number (optional)",
  phonePlaceholder: "010-1234-5678",
  skip: "Skip",
  subscribe: "Subscribe",
  ask: "Would you like to subscribe to Orbro's newsletter to receive technology and various information?",
  thanks: "Thank you for subscribing.",
  thanksMore: "Thank you for subscribing to Orbro's newsletter! We will send you the latest news and information via email.",
  done: "Done",
};
