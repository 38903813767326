import { accessAuto1, accessAutoMain, accessPro1, accessProMain, accesscontroller1, accesscontrollerMain } from "@assets/images/main/access/product";
import { indoor280, outdoor280 } from "@assets/images/main/camera";
import { assetTracking1, workerTracking1 } from "@assets/images/main/rtls/product";
export default {

  a: [
    { text: "製品名" },
    { text: "説明" },
    { text: "通信方法" },
    { text: "解像度" },
    { text: "絞り" },
    { text: "防水防塵" },
    { text: "動作条件" },
    { text: "電源" },
    { text: "設置サポート" },
    { text: "映像保存" },
    { text: "人物追跡" },
    { text: "車両追跡" },
    { text: "ヒートマップ" },
    { text: "異常行動検知" }
  ],


  b: [
    { text: "屋外カメラ" },
    { text: "カメラを通して、人、車両など様々なオブジェクトの位置と異常行動を検知・管理できます。" },
    { text: "Ethernet" },
    { text: "2560 x 1440" },
    { text: "F 1.2" },
    { text: "IP67" },
    { text: "-40°C から +70°C、0% から 95%" },
    { text: "12VDV" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
  ],


  c: [
    { text: "屋内カメラ" },
    { text: "カメラを通して、室内の人々の移動経路を分析できます。" },
    { text: "Ethernet" },
    { text: "1920 x 1080" },
    { text: "F 1.4" },
    { text: "IP67" },
    { text: "-30°C から +60°C、0% から 95%" },
    { text: "12VDC" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
  ],

  spec: [

    {

    },

    {
      image: outdoor280,

    },


    {
      image: indoor280,

    },


  ],
}
