import React from 'react';
import i18n from '@pages/i18n';

import { ArrowImg, DivGridRowPadding, MenuBox, MenuColumn, MenuContainer, MenuHoverLabel, MenuOS, MenuOSIcon, MenuTreeLayout, StyledGridColumnMenu } from './Tree1.style';
import { StyledGridColumn } from '@components/Grid/Grid.style';
import navMenu from '@src/lang/ko/navMenu';
import { StyledHeading, StyledParagraph } from '@components/Text';
import { IconBlueArrowRight } from '@assets/images/icon';
import { Trans } from 'react-i18next';
import { LabelColor, Spacing } from '@pages/CommonStyle/common.style';
import { FitAtag } from './Tree3.style';
import { DivFlexRow } from '@pages/CommonStyle/commonDiv.style';


const Tree1 = ({ mouseLeave }) => {

  const currentLanguage = i18n.language;


  //currentLanguage !== 'ko' ? `/${currentLanguage}/` : `/`

  return (
    <MenuTreeLayout onMouseLeave={() => { mouseLeave(); }}>
      <MenuContainer>
        <DivGridRowPadding>
          <StyledGridColumnMenu desktop={9} tablet={5} mobile={2}>
            <MenuColumn>

              {navMenu.solution.map((data, index) => (
                <>
                  {index < 3 && <>
                    <MenuBox key={index}>
                      <StyledHeading size='xxxsmall' > <Trans i18nKey={`navMenu.solution.${index}.title`} /> </StyledHeading>
                      {data.path.map((product, i) => (
                        <FitAtag href={currentLanguage !== 'ko' ? `/${currentLanguage}${product.link}` : `${product.link}`} mt='8' key={i}>
                          <MenuHoverLabel size='medium'> <Trans i18nKey={`navMenu.solution.${index}.path.${i}.text`} /> </MenuHoverLabel>
                        </FitAtag>
                      ))}
                    </MenuBox>
                  </>}
                </>
              ))}
            </MenuColumn>
            <MenuColumn>
              {navMenu.solution.map((data, index) => (
                <>
                  {index > 2 && <>
                    <MenuBox key={index}>
                      <StyledHeading size='xxxsmall'> <Trans i18nKey={`navMenu.solution.${index}.title`} /> </StyledHeading>
                      {data.path.map((product, i) => (
                        <FitAtag href={currentLanguage !== 'ko' ? `/${currentLanguage}${product.link}` : `${product.link}`} mt='8' key={i}>
                          <MenuHoverLabel size='medium' > <Trans i18nKey={`navMenu.solution.${index}.path.${i}.text`} /> </MenuHoverLabel>
                        </FitAtag>
                      ))}
                    </MenuBox>
                  </>}
                </>
              ))}

            </MenuColumn>
          </StyledGridColumnMenu>
          <StyledGridColumn desktop={3} tablet={3} mobile={2}>

            <MenuOS>
              <MenuOSIcon src={navMenu.world.icon} alt='orbro os' />
              <Spacing pixel='16' />
              <StyledHeading size='xxxsmall'> <Trans i18nKey={`navMenu.world.title`} /> </StyledHeading>
              <Spacing pixel='4' />
              <StyledParagraph size='small'> <Trans i18nKey={`navMenu.world.desc`} /> </StyledParagraph>
              <FitAtag href={i18n.language === 'ko' ? `${navMenu.world.link}` : `/${i18n.language}${navMenu.world.link}` } target='_blank' rel='noopener noreferrer' mt='16'>
                <DivFlexRow>
                  <LabelColor size='medium' color='#2A70F0'> <Trans i18nKey={`navMenu.world.more`} /> </LabelColor>
                  <ArrowImg src={IconBlueArrowRight} alt='blue arrow right'/>
                </DivFlexRow>
              </FitAtag>
            </MenuOS>
          </StyledGridColumn>
        </DivGridRowPadding>

      </MenuContainer>
    </MenuTreeLayout>
  );
};

export default Tree1;
