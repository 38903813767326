import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: 'Create a lifelike digital twin of the city with realistic graphics and enhance urban visualization. Featuring graphics representation at LDO5 level, integration of external graphic data, support for graphic data based on latitude and longitude, interactive 3D models, digital preservation of historical and cultural heritage, and simulation of urban planning and development, it is utilized in city planning, design, and marketing.',
  keywords: 'Digital Twin, Digital Twins, 디지털 트윈, gis, 디지털 시티, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - Creating Vivid Graphic City :: ORBRO Blog',
  ogUrl: 'https://orbro.io/en/blog/digitaltwin-digitalcity',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twin - Creating Vivid Graphic City',
  date: '2023-12-26',
  tag1: '#DigitalTwin',
  tag2: '#CCTV',
  tag3: '#DigitalCity',
  tag4: '#VirtualReality',

  text: [
    {
      head: 'Digital Twin - Creating Vivid Graphic City',
      subHead: '',
      desc: `ORBRO utilizes digital twin technology to realistically implement the vibrant graphics of cities, enhancing urban visualization. For this purpose, it provides high-resolution graphics at the LDO5 level, integration of external graphic data, accurate graphic data support based on latitude and longitude, interactive 3D models, digital preservation of history and cultural heritage, and simulations for urban planning and development.<br/><br/>ORBRO's high-resolution graphic representation realistically visualizes the city, providing users with immersion. The integration of external graphic data effectively consolidates accurate and diverse urban information. Additionally, latitude and longitude-based graphic data enable precise location information and geographic analysis.<br/><br/>Interactive 3D models assist users in exploring and analyzing various parts of the city in real-time. Digital preservation of history and cultural heritage protects the city's value and enhances its educational aspects. Moreover, urban planning and development simulations support effective strategic planning and development decisions, promoting the sustainable development of the city.`,
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/KkvO62eClcU?si=AQmNMfH6nmQ5Aa3Z',
      head: '',
      subHead: 'Realistic Graphic Representation at LDO5 Level',
      desc: 'Visualize the city with the highest level of detail and resolution, providing realistic graphics for city planning, design, and marketing. Integration with virtual reality and augmented reality offers a new user experience.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Integration of External Graphic Data',
      desc: 'Collect and integrate graphic data from various external sources to provide a more accurate and rich city model. This enhances visual representation of the city and contributes to accurate city analysis and planning.',
    },
    {
      head: '',
      subHead: 'Support for Graphic Data Based on Latitude and Longitude',
      desc: 'Support graphic data based on latitude and longitude coordinates, enabling accurate location information and geographic analysis. This is utilized in city planning, real estate development, and the tourism industry.',
    },
    {
      head: '',
      subHead: 'Providing Interactive 3D Models',
      desc: 'Offer interactive 3D models that allow users to explore and analyze various elements of the city in real-time. This is utilized in planning, education, simulations, and more.',
    },
    {
      head: '',
      subHead: 'Digital Preservation of Historical and Cultural Heritage',
      desc: 'Digitize historical buildings and cultural heritage in 3D for preservation, enhancing the protection and educational value of cultural heritage.',
    },
    {
      head: '',
      subHead: 'Urban Planning and Development Simulation',
      desc: 'Simulate urban planning and development scenarios with realistic graphics. This provides essential information for effective planning and development decisions.',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that helps track the real-time location by attaching UWB terminals to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }
    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/en/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      }
    ]
  }
}
