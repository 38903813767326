import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4";

export default {
  helmet : '',
  desc:'UWB（Ultra-Wideband）を利用したRTLS（Real-Time Location System）技術は、非常に広い周波数帯域を利用して、高精度のリアルタイム位置追跡を実現します。この技術は、センサーとタグの間の距離を精密に測定し、屋内および屋外の環境で位置情報を提供します。',
  keywords:'UWB RTLS、室内の位置把握、資産の測位、非接触決済システム、シマトゥホーム、健康管理、自動車安全、UWB、RTLS、GPS、BLE、デジタルツイン',

  ogTitle:'',
  ogDesc:'',
  ogSite:'UWBとは何であり、ビジネスにどのように役になりましょうか? :: ・オブ・でブログ',

  ogUrl : 'https://orbro.io/jp/blog/uwb',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EObeDoLPWDY0m4J6RG5ivUvly0sFp1k2N8pJ6vwHvfEotnTR-c97QjquFgftQfhSf8tMXrczrcxhZelBBXIc0FoSa6ZZgE8na0u8s66yPDrdq0UrGeG-BtDEHMU8P-DPTgUQcwgdX_770Njyx6SNDF4Bchooan8k2Ghgf5DDf0A-rOW2q8-J9DNpNr9pjZRVCg6601CXYmQ-4C_DWZQrQPOpbyvi0Utlc-Az8WhS41m0SPy_AVoqe-fxEuq53t-bNal_-D9ko7jhgr3HHIaTqLbFKWfu5W_yVuf3-clrf0CtlwYyWeQTD4-blr1Vt6UMEpe6g0xh8vMdEG9KwIOY0We-an0hHPhqHco-cxMhZqnXuKuhdRngKsVJnmNzhoAGDcEovW0xEdbwNGGnaXf9HgDuwEyz0O0hBJ1VdMLVrZeF3yG2w0uOYvT1w1CGsNnOvF1HQ2O1tBB0OUXGi7kOuhASd34xinm8ZUoaopcdehwOHmFkiWhblS3O86GDhcSHi64o5o9yPXeKJKcg3aX4M1WKjSD4u-v_h7Cz7j2Ew_QRGSJUkV0ZqKIYM-LnidAHxkTwv5b0vujpTQTybOQctctEpk07QqA7jx3zz1AbtSgtB4SMS3BVYQ-LDQPwmRnkVEi15VK5I33OFLSw8UAoibRcC2y68nu5w0ZmwZ3yZPgRbGUhwVnNrbzmmWjnhyW6agO6gnb3iAAYmxGgUZ0Bxe6sU3F_hRAOk3K5adG-GTihhhRZ6Ny-1YfLjwBN2XWS9CQmHOiNL2XKyUmDIRFnYf6My62MYUpTGkqD3QGMb5QWsmjsVvMDeYmPK2pRmdXC2VuzOpXv1I32Uij5MECw_kBbt9J59P_jtayKMJb7MPa9mZTsvEtK68fS4kpKPiTqgmY6Fpu81ySeLX6iMqgw2mW8BqPuEyOz5VlDQR8kXkleyutpFfgwMLd0gDe-6V-1w-4lb3u6ccuCTShoIl4rzEil0_RJbe2Oe3td0WW3KYtfT_1Oeihe7jPGmD0JZrOSemw8nMa-oWOjkMSMFpCBJOfSPxpx5I42Jupgd2vOsaIezrcSGCGzHpoXM2-ryc14nPGOUDOc-hA1eFFGqVgCGUQN2dUMHISspskEVJBPI7647sxsyCnd0IUJLN5NSHR_sHivHXsX3SAq9g2Tk3WwbdpT8PW2l9hw6FfuxvjBv8DfsEILwnTGOQQMQ8iiQIZLz9QyCq1OEEDethUDWNsTZipSUMrcWUwpyNxE5586r4DxKO0R_AIGUIxL7kAAwP5cRdFP7CxE1UBRhidxzIVJeIaoHrk-OtutFpM4iFhXD6VY_zZRNDnT74KvBOONaMhB3Lhc801EddUkWf0-35rZksJbpbnWR-H_yNKiCBv8UP9iHJoUWB84wFs2dUNq8sK7fy316q-Coz-Yc58zY_S07R8mK_SjMcdJPDpUc-XzfMyXvXGhYWScpZjP766CDtHOd_ArlWxMsLBupquNSUuHJJ6FWmIoUzq31LSeNqPvc7MH_ywbqQd_efx2p8c73E_NvkVCJP8OYYT8IP8iUh9v7tRMD0-JBjW6yzXknRIpicPYTQ=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
  
  title:'UWBとは何であり、<br/>ビジネスにどのように役になりましょうか?',
  date:'2023-04-25',
  tag1:'#UWB',
  tag2:'#産業自動化',
  tag3:'#資産測位',
  tag4:'#室内位置把握',

  
  // <br/>
  text : [
    
    {
      head : 'UWB?',
      subHead :'',
      desc:'Ultra-wideband(UWB)とは、非常に高い周波数で低い電力消費で動作する無線通信プロトコルです。 BluetoothまたはWi-Fiのような他の無線通信プロトコルとは異なり、UWBは電磁エネルギーの短いパルスを使用してデータを送信します。 これらのパルスは通常数ナノ秒しか持続しませんが、数百メガヘルツでいくつかのギガヘルツの非常に広い帯域幅を持っています。 <br/><br/>UWBの広い帯域幅は、迅速かつ正確な大容量データ伝送が必要な産業自動化、ロボット及び自動運転車両といった応用分野に理想的です。 また、UWBは二つの物体間の距離を非常に正確に測定することができ、位置基盤アプリケーションで有用です。',
      dot : ''
      
    },
    {
      video : video,
      link : 'https://orbro.io/jp/enterprise/medical/patient',
      head:"ビジネスにUWBがどのように役立つでしょうか？",
      subHead :'',
      desc:'UWBは様々な潜在的な応用分野があり、多様な方法で事業を支援することができます。 以下はUWBの最も有望な応用分野の一部です。',
      dot :''
    },
    {
      head : '',
      subHead:"室内位置把握及び資産測位",
      desc:"UWB技術は建物内や他の室内空間で資産の位置を正確に測位するために使用できます。 これは、在庫管理、ツール測位、ツール制御、プロセス監視、車両測位、および正確な位置データが必要なその他のアプリケーションに役立ちます。 例えば、倉庫を運営する場合、UWBを使用して個別項目の位置をリアルタイムで測位して在庫を探して管理しやすくなります。",
      dot : {}
    },
    {
      head : '',
      subHead:"非接触決済システム",
      desc:"UWB技術は、従来のソリューションよりも安全で信頼性の高い非接触決済システムを実現するために使用できます。 モバイル機器と決済端末間で決済データをUWBを利用して伝送することで、決済が安全に行われ、詐欺や不法アクセスがないように保障できます。",
    },
    {
      head : '',
      subHead:"スマートホーム自動化",
      desc:'UWB技術は、従来のソリューションよりも正確で信頼性の高いスマートホーム自動化システムを作成するために使用できます。 家の中で装置の位置を決めるためにUWBを使用すると、特定の位置や動きに反応するより精巧な自動化ルールを作成できます。 例えば、誰かが部屋に入ってくると電気が点いて、出て行く時は電気が消えるように規則を作ることができます。' ,
    },
    {
      head : '',
      subHead:"健康管理モニタリング",
      desc:'UWB技術は、医療施設内の患者の位置と動きをモニタリングし、必要な治療を受けているかどうかを確認するために使用できます。 例えば、UWBは病院内で患者の動きを測位し、必要な助けが必要な患者を迅速に見つけることができます。',
    },
    {
      head : '',
      subHead:"自動車安全",
      desc:'UWB技術は車両の位置と動きに関するより正確なデータを提供し、自動車安全システムで使用できます。 これは衝突回避などの応用分野で有用であり、UWBを使用して両車両間の距離を正確に判断し、ドライバーに潜在的な危険性について警告することができます。',
    },
    {
      head : '',
      subHead:"産業自動化",
      desc:'UWB技術は、産業自動化応用分野で装置間のより正確で安定した通信を可能にすることができます。 例えば、UWBはロボットと他の産業用装備間のデータを伝送し、彼らがより効率的に協力できるようにすることができます。', 
    },
    {
      head : '結論',
      subHead :'',
      desc:'UWB技術は室内位置測位および資産管理からスマートホーム自動化および医療モニタリングまで多様な分野で使用できる強力なツールです。 高い精度と信頼性のため、正確な位置データまたは高速データ伝送が必要な応用分野で理想的な選択肢です。',
    },


  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/jp/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/jp/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/jp/enterprise/logistics/asset'
      }
    ]

  }


}