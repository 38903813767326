import { StyledGridColumnProps } from "@components/Grid/Grid.model";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";

export const StyledHomeContent = styled.div`

  z-index: 2;
`;


export const StyledTitleBanner = styled.div`
  display: flex;
  
  width: 100%; 
  height: auto;

  background: #F8F8F8;
  left: 0px;
  top: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    height: auto;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height: auto;
    padding-bottom : 50px;
  }
`;




export const StyledOsBannerIn = styled.div`
  max-width : 1232px;
  width : 100%;
  margin : 0 auto;
  justify-content: center;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding : 0 8px;
  }
`;




export const StyledOsTextBox = styled.div`

  display: flex;
  flex-direction: column;
  
  
  margin-top : 158px;
  padding-top : 64px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding-top : 32px;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-top : 0px;
    
  }


`;



export const StyledOsTitle = styled(StyledDisplay)`
  text-align : center;
  

`;

export const StyledOsDesc = styled(StyledParagraph)`
  margin-top : 16px;
  text-align : center;
  color : #646464;

`;

export const StyledOsImg = styled.img`
  max-width : 1232px;
  width : 100%;
`;