import { SCREEN_BREAKPOINT, StyledGridColumn } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";
import { StyledGridContainer } from "@pages/Enterprise/Blog/Detail/BlogDetail.style";

export const MainZlayout = styled.div`
  background-color : #fff;
  padding-bottom : 64px;
  z-index : 1;
  position : relative;
`;


export const StyledTitleBanner = styled.div<{ img: string }>`
  width: 100%; 
  height: 960px;
  position : absolute;
  z-index : 1;
  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ${({ img }) => img && `background-image : url(${img});`}



  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    height: 760px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height: 600px;
  }
`;


export const ImageInner = styled(StyledGridContainer)`
  height : 960px;

  display : flex; 
  z-index : 3;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    height : 760px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height : 600px;
    
  }
`;



export const BannerTextLayout = styled.div`
  display : flex;
  flex-direction : column;
  width : 100%;
  height : 100%; 

  padding-left : 16px;

`

export const BannerTextBox = styled.div<{w : number}>`
  margin-top : auto;
  padding-bottom : 128px;
  max-width : 680px;
  height : fit-content;


  ${({w})=> w&& `width : ${w}px; max-width : ${w}px;`}

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    max-width : 100%;
    margin-top : auto;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    max-width : 100%;
    padding-bottom : 96px;
  }
`;





export const StyledBannerTextBox = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: absolute;
  max-width: 1232px;
  width : 100%;

  height : auto;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;

  padding-bottom : 128px; 
  padding-left : 16px;
  
  & strong {
    
  }

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    
    
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    overflow-x : hidden;
    padding-bottom : 96px;
    
  }


`;


export const StyledHomeDisplay = styled(StyledDisplay)`
  color: #FFFFFF;
  
  width : 600px;



  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    width : 500px;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
    width : 361px;
  }


`;


export const StyledHomePara = styled(StyledParagraph)`
  color: #BDBDBD;
  margin-top : 16px;
  
  width : 680px;



  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    width : 400px;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
    width : 361px;
  }


`;



export const StyledHome = styled.div`
  width: 100%;

  & * {
    font-family: Pretendard !important;
    font-display: swap;
  } 




  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    
    
  }


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
  }


`;


export const StyledBlogCard = styled(StyledGridColumn)`
  height: 380px;
  border-radius: 16px;

  display : flex;
  flex-direction : column;
  
  &:hover {
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  }


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height: auto;
    
  }
`;


