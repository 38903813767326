export default {


    support : [

        {
            name: '企业',
            path: '/cn/enterprise'
        
        },
        {
            name: '支持',
            submenus: [
                { text: '技术', path: '/cn/enterprise/technology/uwb' },
                { text: '博客', path: '/cn/blog' },
                { text: '销售咨询', path: '/cn/request' },
                { text: '公司介绍', path: '/cn/company' },
            ]
        }
        

    ],


    lang : [
        {
            name : '언어',
            submenus :[
                {text:'한국어'},
                {text:'English'},
                {text:'日本語'}
            ]
        }


    ]


}