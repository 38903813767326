import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet: '',
  desc: "在医疗行业，RTLS（实时定位系统）通过实时跟踪患者和医疗设备的位置，使医务人员能够高效监视患者，并有效管理医疗设施内的设备和资产。",
  keywords: 'RTLS，室内定位，室内导航，定位解决方案，实时定位，资产追踪，智能办公，GPS，室内定位解决方案',

  ogTitle: '',
  ogDesc: '',
  ogSite: `医疗产业中的RTLS :: Orbro Blog`,

  ogUrl: 'https://orbro.io/cn/blog/medical',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EOZJaHlD_-JbEbsseg3VyR_yeMr1aCExLMWJX8T8GLTArDRbIStI6J1-0hax0bG6__oOWgnHyGPyvarcGsI-N_BiKN4cYDhs_2bM3hfycDf5l2N2df2hsBc0jlAcY_dysEr70kglQvb_fo8sUaG0ru2JfDJeZrPF1XghQ_RRBkDJ875-CCv9sf1V7SL2EOv-Zr3zfuUc1K9zWPyudhy3aV8sFP-CDO_Qqs7eKhqpYGZkwen7a5j5SWZ2uDUyfeILxjUiinPVpzoKRFiaCam-AsJ7r0faFjUPz2abau-VuB1gD0U6O4cMGZaiu341Tl8PyFCJZzL-sycERRlTXmvO3wqGjoUICFGSMkpkPJSlrbS4iCx6f7-_DAOOHNpbz-RXyE0tUVk1WR3rWbn4iR_bZJGICZ7--JH4t4OcYVCbvdUVgJnPN9j_9UuKRrCK0D5cz0QERE9Er6fTw0uTL7GdSOJozzq9i47R3TYtPO2hncXh4cYmM8UmIXwWgC4FGSP5kiyKNDziO3A_aZEg2sRvVSjzigCb73mkjswlIaJfO4LWJ258zbFwf7ft9F58M5Kfq2KHjAIV8o1svKZ_1llrr1owK8S7Fm-XHJMfU5Cuo2_n5agb6t-HF1N8DvZKyT0ylqZwBz2y4zmnt0my0cXuy_NrsnxEKPg_CVIITQoNOROTq4rEfnPyXFHsDF6Gh7-wmgOBRgbUR9TtVI7P9lP0LBU0puSoStWWW4DTJkuXmVDz4sM5j6yOGUzpq03SDL-QhpyZpq1U4wI1UfmhzOYlzYLcwJpl0u6jf44FDboZF9Iz4V9tVW86AwrI5ov_Rm_iImhjZgpIVtofL67UnvaJsSxCT_2PIZVD6X-3X2YcQ___BetQ_lTyYWhywPoI5WLoBJjEPyUwq_rFOTbCKJcFhgV-i49op1zkeCdPZtah3rOgCSo95wbPoAwaPv3d5VhDlDAfSdCLNzr6VBos3Ym9La7xU9GNLYIHwb--0ALdXL4GcV-nEhqrhBs46_fI6e3QMKwYPZHHUyniul-H7E7-Rbio-WbQC-J8SHO61CD-qiZWTZS_6jcbLTeKC20s6APzZmv91lV5viIWzkRQy_MfPgob9ft0G8Rinq1ywzZ5FGWlXeqbqqLDImJHcv5sbMbOSpdAftb4VMO7y8WHbQmKip38OP8tzNobikPBk1nibKhAgHp_ppJHlCxiW7EqBkXRY4D4dDcj-hwLusPNlh4LNdrxge7Z36lSWGIwzkpAaKiMAcssNMhsS9z4iS98Mg87bdJQT4x1tdmIocIxu6voXK2foTf-jVxbfCzhbDoD8brxsCj7nNytwO1wfQXwq2NC8FFG8Fma_SYpSTAfBOEFzt_SbKRO8usqLbqfCHIu5hs3tyUAaGOkFUUbhf8h2VGlgJKyEEwN9MKct5KqEVU9tPm0i5COlqkbDRj5PQy3pBKG9Ryuxe0C8lWW7cNyf7ARG6xGAvhoyXYjwV-gy-hSJpzs2ZxtJWB5KuYwjiHBbTNGpGFkqI89Z2AS379KYEV19-8MdLEab5Pgk2ml3sc0EFAq4pU-9Hbf9E6n2Ku3wAYJiw=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',


  title: '医疗行业中的RTLS',
  date: '2023-05-04',
  tag1: '#RTLS',
  tag2: '#医疗',
  tag3: '#资产管理',
  tag4: '#资产追踪',

  // <br/>
  text: [
    {
      head: '医疗行业中的RTLS',
      subHead: '',
      desc: '在新冠疫情的影响下，医疗行业对先进的IT技术变得越来越重视。<br/>特别是跟踪和管理患者成为技术的核心。<br/>实时位置系统(RTLS)，即所谓的实时定位系统，将在未来广泛应用于医疗行业的各个方面，并帮助提供比现在更高水平的服务。',
      dot: ''
    },
    {
      head: '',
      subHead: 'RTLS是什么？',
      desc: '在医疗领域，实时位置系统(RTLS)是一种用于实时追踪和管理医疗设备、员工和患者等各种资产的系统。可以将其视为医院内部的“室内GPS”。然而，RTLS解决方案不仅仅是用于查找资产，它还通过准确的位置掌握技术与其他医疗IT解决方案轻松集成，有助于改善运营流程、降低成本并提高临床质量。<br/>RTLS解决方案通常包括用于附在患者、员工或设备等各种资产上的位置传感器。系统使用唯一的ID来标识标签，并提供关于设施内位置的实时信息。',
      dot: ''
    },
    {
      head: '',
      subHead: '它能做什么？',
      desc: '在医疗设施中，设备故障、感染或身体攻击等风险始终是持续不断的威胁。实时位置系统可以减少这些风险，增进患者的安全。<br/>例如，医院经常因为未清洗受感染的医疗设备而再次使用，这可能导致医疗事故。<br/>为了减少此类人为错误，RTLS解决方案提供了解决方案。',
      dot: {
        a: '• 可以掌握与受感染患者或接触过患者的员工，从而生成接触追踪报告。对于像新冠这样高传染性的疾病，可以采取预防措施。',
        b: '• 可以立即识别需要清洗的污染设备，并立即通知有关感染风险。此外，它还可以监控医院内医疗设备的位置，并向管理员提供相关信息，从而控制和管理这些设备。',
        c: '• 可以管理设备的故障或需要维护的时机。',
        d: '• 对于医生、护士和其他员工以及患者，可以快速请求帮助，特别是在紧急情况或身体威胁发生时。',
        e: '• 在患者进入禁区或闲逛的情况下，可以实时追踪并作出相应处理。或者使用地理围栏技术自动发出警报，这对照顾患有失智症或阿尔茨海默症的患者特别重要。',
        f: '• 可以使用警报和安全系统、地理围栏技术等RTLS技术来保护和管理特别脆弱的对象，如急救患者或新生儿。',
      }
    },
    {
      head: '',
      subHead: '有什么好处？',
      desc: 'RTLS技术在安全方面尤其具有高效性，同时在经济和服务运营方面也非常有帮助。<br/>通过高效的设备管理，可以节省成本，阻止设备事故导致的费用。<br/><br/>对于运营者来说，可以收集监测信息并进行分析。可以了解医院使用的设备的使用情况和利用率，还可以掌握患者在医院停留或在建筑内活动的特定区域。<br/>此外，当医院患者和排队人数增多导致内部瓶颈时，可以加以缓解。在某些时间段等待时间过长时，可以采取措施进行顺畅运营，从而向更多人提供更好的体验和更高的满意度。',
      dot: ''
    },
  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: "将UWB终端机安装在工作者或设备上，帮助实时追踪位置的产品。",
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]


  }

}