import { ptzThumb } from "@assets/images/main/camera";

export default {


    default: {

        title: 'オブロ | 位置追跡およびデジタルツイン専門ソリューション',
        key: '位置測位、資産管理、アセットトラッキング、倉庫管理、倉庫資産管理、室内位置測位、RTLS、位置測位ソリューション、重大災害処罰法、建設現場の安全、来訪者の経路分析、出入りシステム、第4次産業革命、スマートファクトリー、デジタルツイン、ビーコン、Bluetooth、リアルタイム位置システム、室内位置測位、アセットトラッキング、資産管理、倉庫管理、倉庫資産管理、室内位置測位、RTLS、位置測位ソリューション、建設現場の安全、来訪者の経路分析、出入りシステム、第4次産業革命、スマートファクトリー、UWB RTLS、デジタルツイン、ビーコン、Bluetooth、BLE、UWB、IoT',
        desc: 'オブロは、UWB（Ultra-Wideband）、RTLS（リアルタイムロケーションシステム）、ビーコン、アクセスシステム、位置追跡などの最新技術をベースにスマートソリューションを提供します。デジタルツインを活用して、スマートシティの未来を探ってみてください。',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: 'https://orbro.io/jp/',
    },


    orbroOs: {
        title: 'オブロOS | デジタルツインスペース管理プラットフォーム',
        key: '室内トラッキング、RTLSソリューション、RTLS、デジタルツイン、室内位置測位、IoT RTLS、室内位置測位、位置測位、資産管理、資産測位、デジタルツイン、重大災害処罰法',
        desc: 'デジタルツインプラットフォームOrbro OSを使用すると、現実世界の空間を仮想で管理できます。リアルタイムの位置追跡により、資産を管理し、人の位置を特定し、空間を簡単に管理したり情報を取得したりできます。',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYkZbw7aOzICkoQi8HNk9s2d6VpUL4SVgujeUxCxLThYcyYLFnsiLZyVx8NwEWTiJIKKeTqFcuFZF4-0GJiF5k8YyZKXtU1iL8Qas-_DGIZPtogRYL6cnYimywaHxSkEe97rBb410rPXE2LymoexrdTIuXxKNli61FgKo4m_7JzUZXofk9HBe1CmLCLjcaJ4N8CusSji3C-D-W9XEtpZ_pdbROqqHehQbzJf3Lu7a9r04vRKR5XY6SlOsaHQXgGvyvf2EwBUoRIK1DKgjGg5Zzpi5ql-8O8MWh8pyV_NMWerNhx8Km30j95bwAyArdMfGv2x8l5jdUBxychGtfT5SECI01jw3yjOvdPPWgw9EfhlRlUPENdWxGAM8tN8fPTKID1cOaskpvwlCYjMeExZpPCYAmw8IWLq_KmhgyxcGK287oaey7vTTQ_AFc816pvys48-p5vA5qpp1-3VCFZEEVoF90jtIKxMU5dWbpeElJFg99AybHdS6Ka8QD1-oq7K-N2rat8r8uPyJCc-UGK2DfU301JI-BlolwsoqI9JH2eomiXOFFsCyPfbMImXi-ZM0r5R7DAY5j3VqdTVq3x2AUK_VMlLkFSCcOzlpo6_hUaTgAXdIGIdtArstnx9UYRPj1qdvT2zhTSXu43gxG5dnRsO8WVC4H6qMoiotvH-f-8UkXMZOSjV3C9TmUS3zLAzMIeP5_wTbaf7Pk8kT9Cf_h6NFml9VUIPZ-0AVWUfsGfBgqD2U_ToCKiOtKLRwy1d50veOBzfrvRlhAUVUyQj3B6Jf4cXxAgJyp7PnJUXUPmpYr3UVmjjyx3-7N1f1TAf5TpHSChyMrenXXD_BlVEYe2DX2o_oaZVmkZ4K3cPSUoaQsgcalbV-QMpW-cys5PIo1H5DRYxfH5WzurFBEEqXF0h_RJZD8uBz7u-nF3a_sGEXbSV1sIj-5oqlm0WHswVhSyFLy1AsEPsWKNGoN4vAOdFN3bvKfDXcj22FbtQv7oqpj35v0fiK0uFjxzUli6LA0lOcxxBTDX8K3TGhhmgZSGVIVN-RtMSnnYrzqGJ2Gdpfhs6Z_lrTrIL4IE9EiVsbWkZRlk9Ung0oFwlX9E0bpnv_oBa8cEsinqQms8LDrCXle81kS5jg_WHHUVoTBYawhdKqeBHbGj67EcTW5OfDNT5b-0ivlXNSO2rj2MfdTIXcPXHrFF0O1x3x4e_aZhiXwhxOdQpRn38jnYIu8XKjS4Ot07PLLE0sdyPidowRf1mGv3Fe3VZbO1cPCMUGmwJwDb1ow5AYNQsCpv9wLFrfkTfb9z8_lyUPmzo4t7o8N4difD44FvoqkfHmwLBBPkYXimpJ9jrIz25oS_B70pubRYpO-ZMwo1aQ2McWHBUd9oBRTr27FuBGJS8RIlZ5nxyeOeZ-Tp_kOeKWr8bcB5yrd96BSV8Cgb5P43XLS9SMCZrZ7oM6e3JipkAMJDP7kLBtvCET1199BhDG6TF44RHfOKXYImbDVIdgmSdsIw6nhcXUIDGaQCifrmOru2TFa0v2Wwz4K-=w2006-h1238',
        url: 'https://orbro.io/jp/os',
    },

    os: {
        beacon: {
            title: 'ビーコン位置追跡OS | 位置追跡とデジタルツインの専門ソリューション',
            desc: '',
        },
        rtls: {
            title: 'リアルタイム位置追跡OS | 位置追跡とデジタルツインの専門ソリューション',
            desc: '',
        },
        camera: {
            title: 'ビデオ位置追跡OS | 位置追跡とデジタルツインの専門ソリューション',
            desc: '',
        },
        access: {
            title: 'アクセス制御システムOS | 位置追跡とデジタルツインの専門ソリューション',
            desc: '',
        },
        sensor: {
            title: '産業用センサーOS | 位置追跡とデジタルツインの専門ソリューション',
            desc: '',
        },
    },


    accessProduct: {
        title: '新しいアクセスシステム | オブロ、デジタル変革の始まり',
        key: 'スマートアクセスシステム、アクセスコントロールシステム、アクセス管理システム、スマートドアロック、生体認証アクセスシステム、RFIDアクセスシステム、QRコードアクセスシステム、リモートアクセスシステム、アクセスカードシステム、アクセスログ記録、スマート出入りシステム、出入り制御システム、出入り管理システム、スマートドアロック、生体認証出入りシステム、RFID出入りシステム、QRコード出入りシステム、リモート出入りシステム、出入りカードシステム、出入りログ記録',
        desc: 'より便利で安全なアクセスシステムが必要な場合、オブロの未来型アクセスシステムを今すぐご確認ください。',
        img: 'https://lh3.googleusercontent.com/fife/APg5EObhIhVSc5l_YgEkV8ENDMUXJdxYXa-tWu2ob2CGj1T8JguKcr3Oh6Ywro_h3lU1aAEDTsm7Y33KDfdFlaO6IaSSuDYM6jnVKpOcIiAe0eCrjFHlDoXvptrgNbRQPpSce46Ge5-c77SpWj2XbOegeOH6WEQ54JhkhINdVTje6Mjr5QHnHYihwyoLoEY5brdu6vr7ku4EHibMaDRdaqhHwEnn_WCeTCmFTPbHm4KUoUp5saol3DT9810Q8H_Nmhgg9VvFYIn3JToTijZ5R2Vr2G7EHzbQmFSDSmkt60piBLbORnc52qceoWBGmSzhkMGsLF3sT3JdXCd2QPn-tC0MfwpaXWl5qm9-D4FMltwFgePmpiMZFDjy7O7pEwY-ScQ--sd2h6ptZnjjdO05ROX-m3AmdzXEEXvulJFRmx8ZyoryCIODzmathG0yJ0_vke5v6mE94-lyK8IFHisgptmGPzJG6m6-kkaK_EjlPxneaY8NDdT1cCxlyeAaHSCNgOps9RqcyrjM5BWr02C_P7cEswPoR9lT73V7zsWcTODPyUteFFSqvLjKrMZbQ269etOJjln97cW54V5y_3OplUpQ1Zt2-lppHEz_BnEgH_fsIfnyni9lbY1qP_-fdeXV6n3tlA3rNTSQZV5HLm5kzafsNPKWMzx3CKW-z7S6MxUpBGVCgA_fjiBt5YUoFQSwxmei1SlyV8zK-A3I5Yi-8DaPA5ty075aBejJ8r4fu0G_PvNx7dPUCjNgwfZOTgYXIXgKGZVv75WGo3qvXjP_BezP6LrsqC_Soid5hd1osgf6JmGsCSp7u0oPBw8KwjxUNHCWCIta4i0XFmK_6FkII6LV1KHbqmGiOoI75JSqjnneaPjueqkzBdZtOtDep3ynq0T_iTz_KsVWEHN9-zBjFAvintaIShl7gJRF__etrafFFB5wcImqgSJm7E5mO3CgRTRkaWfnI__wb3kx-6lby0oT2nII_K7cCJcE4fmn7xjaKW4Q6ql9_e9dHF7tri8sOpwP2AoBo7YgKD8QrNUPoPaGrUKpRLQwdnXChhEguPYTCZccG_agb8XmHBsrvRTXtMoGppbQM2j9KvMJ7VzAYAMpao7dxwFdgJwJMV_d08_UbF0k7eErXfDYFfODs6wHnTnNEVevxgq0sBog7O0-7M6CGTo-CnhP4I_gnMzeu-zHRbbjsK9cV9K7vlLnf8zhSGW9HkcZ659Gws5h13eSMKZjqqMQxZv5uRVqBCWOV3dhRbLHget2G3DfdjrJx-uFPWubEUAYfsp_vW6ZSJW6nKkY3r9WWDF-mea0DR1p72JASwZpyxzftA3-l-Lkz6Ruqk4uzF3MXpAIgsWMIfgBywUDTdxpkXEUmEWqa8CPqrfEDlNClOAOqypO2tLTeK3Fqn5ACl3jx6d3ScqvIMpqN3zW-J6HrLTpfK3cAJ1B9kxpqVzVuc0PwPYeQqOi-zFekLqzL08yNZgHmr_p7Ncq0dDn7IlkMIwfycNyiq6l9mlcQ9Q9Szk-Wze_Tcx-_rwXVmlocKD6lGRi=w2006-h1238',
        url: 'https://orbro.io/jp/access',
    },


    rtlsProduct: {
        title: '位置測位の未来 | オブロ、デジタル変革の始まり',
        key: '位置測位、資産管理、資産測位、倉庫管理、倉庫資産管理、室内位置測位、RTLS、位置測位ソリューション、重大災害処罰法、建設現場の安全、来訪者の経路分析、出入りシステム、第4次産業革命、スマートファクトリー、デジタルツイン、ビーコン、Bluetooth、BLE、UWB、IoT',
        desc: '室内位置測位、資産管理、勤務管理をより速く正確に管理することができます。さらに、オブロシステムはシンプルです。',
        img: 'https://lh3.googleusercontent.com/fife/APg5EObHma5EkyX2MCK6e2mdlbtvvAySBNi1Q6Sy0yCt3XGL-YOgvTEWmMgseI1eiYCBWZ6bCS-EHvV75JiwpvxCDhTKatr_wU7jMtlOwtex9EaelwxpicCYi7PgQNQIIe89wX_xVEPYAvj4dTBXP6aWIDMAio8cHy356CjoDnInZfZXqS-YvRCFK8gZBRMzv4fg1I1jHk0VTFtNo3GJEw7uBwbX5vckmGbYqiBPreTVy8b9t3iHACk5GKEEvGOH3EIN8L8g1V82KcZhn-iaqiRSpudUMASnFDyxdw-tEg7p8-qOAhypBhKaWsOFkTS_1Y3vi5hjRDHEKrNzeg7w9mDBoZdjbd3HaSR49-DFMFiaIjUjcdHeMGYNly4CBBoR0mOaNxvpFu2KLpVt683_8jSHK8YvRAZJCZFZlrUm5t1GRtOXjYbDgJ4scMmX9Agnjr24xOMup95HEUTukM9SFuxajBB8CTqfkX2g8seJ1H66X6zTjBqzzLdwQSRCvB82ItDZM1S4TCGMIQcO9gOGZM_QDFamVpM_RDBBrpMq2g5ErIYWBl3o7pZcOiAtlZOnfWCYagSLeUq4dvo963RZlRvA1XUMTAVVdTfmP8j3A0XcABOijSHaWSql7FtHiQuNE5kWlg91EZYLdnR5TmqD2ua88to5MCCiuJugKsEYTIA09FoIbPozNw3lJEvmKur3O2IRJmDjz5TA3UTHEHlLpBFACSyXXd70flICfMLlZIbkQqv9Ikzbell2A9-_cGDPw18kjU1i8doOYmKhg-fpQZhe0FRRBGtrZm2pEJruhGIjTYHfUrH5Q55qq1vMAFkb2OiCHNmhZ4IHhOL7iqZMyjuvXwMusC306K_yZFw5Md40a5K99WVQUhcXszyVefYM-tYlczn22kyfdM6hyCLQynNvs1bBNio2EdhXpe7tvwlpqQa9YJpnRfS8xS-UNd_3Tzlt5OlbXKZQFUL8NcGeeNyfYU9suciV7N2qFVw1go2Lf4HG9JlCNMgdIJG9gnM2-0bWM-E87vsxH4TvvgT8Y-8APLJlgZ5g0Y_jzTLaXWIEnn-9Z5Vw50MUNv2RTuKKzVlefLGlc24Y7bqebotC1PqdTAN5T4gWmJC33EsO7LmrZ1UpsoFhm3WzqTDTqZE_2MYpXGpNdm_FW3z44CvSgqwewSdmYW-O9kq9mTtjPGpKskUmQ7PlZJY1oKFy7f-vDHJE1PMGKunf6CtrA3pXnXNUls11PKhavf4RpmEnHzII85kSdHFKM_EJrVjVw6vb1qyW_QYLEQkUhM3PsLdc2WGbvGPiKRvIkxmeQ9cNzDPzyD8J-1tsibIhMrVrjzncEBP7r5iwtsqg5q8cZfwFJLFIF_EBh4V3JSqVHVj-pidYwaipmm0rxkhJGoppq5C3WrLM7R1uAiQBA3n1p1U2NAO2bNP3AcAsZUkuWuQbyKIm1kslrgY92ltA27GAc1crDXFbjE2M0AxBSYkKVYOcWpVRCC4FSv5itShGPqYGCd4rbtenPBRiMgtFRM32kdWGP1sWHx_g72cD=w2006-h1238',
        url: 'https://orbro.io/jp/rtls',
    },

    cameraProduct: {
        title: 'カメラ位置測位 | オブロ、デジタル変革の始まり',
        key: '位置測位、資産管理、資産測位、倉庫管理、倉庫資産管理、室内位置測位、RTLS、位置測位ソリューション、重大災害処罰法、建設現場の安全、来訪者の経路分析、出入りシステム、第4次産業革命、スマートファクトリー、デジタルツイン、ビーコン、Bluetooth、BLE、UWB、IoT',
        desc: 'カメラを使用して屋内および屋外の人々や資産を簡単かつ迅速に測位することができます。また、オブロシステムはシンプルです。',
        img: ptzThumb,
        url: 'https://orbro.io/jp/camera',
    },

    iotProduct: {
        title: 'IoT | オブロ、デジタル変革の始まり',
        key: 'Internet of Things、スマートホーム、IoTデバイス、接続デバイス、IoTソリューション、IoTセキュリティ、IoTアナリティクス、産業用IoT、IoTプラットフォーム、IoTセンサー、物のインターネット、スマートホーム技術、IoTデバイス、接続されたデバイス、IoTソリューション、IoTセキュリティ、IoTアナリティクス、産業用物のインターネット、IoTプラットフォーム、IoTセンサー',
        desc: '緊急時に迅速な通報を行うための安心ベル。安全が少しでも心配な場合は、オブロの安心ベルをご確認ください。',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYCH16slHwmaQG331-kV_CkBXnlfH1G9KrQOsfFjNqI7fC1rCmzgUg01Dizxv1tp9RuOIHrNhWUHRmxn0KTRaH1025_7yPSqXctHcfoKasdRVOxqYUom3UzM6ontDOvbiJwx021PbutfqbQZR8gLoT2Btt487SFAu-oBqfMkEaBftr6vSecMBx11J_KvKfDuVp79oWFJjP4HYEc9wJoel4oqj_B2CpPolo0ykC5oJ4MFipkMmGoCuDzxt-mKnQHqiGptEGd0eWtoriaBDaGGeDj18v3SSCdfRJQ9bBXN5M3YJRg2yBJVtFdlboqFrKw4CIvK0LqHyr_eZbrBFj56A_u9uLpgMoKT2azM0XOCFY4eKvjOUG26lUuFO-6TuATsfMAiHJs0pcKOEQfkj1O8ewSyT2Wn14K032rljAuc9rnAElCgFf0plndW4RmVaQAAEZeK3LFNctWNVdUvCz54tPSKulB5Om_n8r_pn-IgsU94nbquRGFkjfew_XBXhkNv5Moq90hwIyXy3WxBg0rAB8u3gkXB0EHJDBBqTOcr_-nE4F_wZ-BxsyMUURlg0m6vk23XftGlrZ1c-57Wdtgwk8YfIvK9GHsk9CwgEZ7e9JUTgb23if0YuTzlAob7bGBJGNrWbEOt35VKMT8BUzKT_lvQCALsHexYk-YO9LVzbHoAdwbYYqIyCg41xvkWltQu_w9Racs4ORCFBkfke1gsOvy51IFkmE2hqqrivGO8YLzNQ2zJwCaOw9Wsb0Cqj8PN3imRwa6VJ2IMENOkqjipW9hvnbn-U-Nm7IMjKTmCE5z-_I84D0-yJJLiKtfo3a7PJl0gCgEEBQTFseDbtyujfFv8BnW0Xvbhn6WsHIYiRmrx8HpB_8lTDuFFwFPan_SfMzoAicjyLHUQLeOSdYPhRNKbZ9Ah1i9ovTjy9rRqpF8XuHkiQPdcnOEoqmzlCdnXc0hFzORQZ6koWMG_nH8Car64KYjSp8uaPyXgKwPGRXc2F41TWuE1-kU0gjPssZAj2qNxkMVHom-lYdZ7dIabaFsmKGpQbTnafugm4e2lNmULTpFJQkzQnN25Y180bmsvRzCG-NroyymzpZGaicJCGjRKcKDMZ754a10zT3o2jAP11AwZVB3Em3mk8IRU8tMKja2TQItdDa0PXI-naiwGAbBgyo55z4gGfov-Xo-NWXbtBVMu3kZd2UYLglsjT_adJWLbPAw1Eja758K9PhE1l_9qQgBE7FNH5bYnxUD_ZvfZm_1-sP8uVnKbrgf2qcWQEnl9yaSRRWlCXwBN6HUDvMo1sM6L-t8oH-IJS40kNUQBEwfbjNXkWAFjf-QPzyvXaHrS_7qIS4-uFvl_f_lF08pp_INZIcfVz9dl2Ghi2bZ0lgUdwmxS8VeZYqIy-yFV1aImHAtYpQSV0TUw9Gvrqeh7XHSYQgEmXB5t6ky0FWmrZxsDfJ-KTbrFsZrl7Hsffa-zGtfUb4WfSmwfyfp2MamCB6S9lc9UThvhkKorxsD98tB1uDyy4Sak8o3jDNZw54krj5u=w2006-h1238',
        url: 'https://orbro.io/jp/iot',
    },


    integration: {
        title: '連携システム | オブロ、位置追跡とデジタルツインの専門プラットフォーム',
        key: 'Internet of Things, スマートホーム, IoTデバイス, 接続デバイス, IoTソリューション, IoTセキュリティ, IoTアナリティクス, インダストリアルIoT, IoTプラットフォーム, IoTセンサー, モノのインターネット, スマートホーム技術, IoTデバイス, 接続されたデバイス, IoTソリューション, IoTセキュリティ, IoT分析, 産業用モノのインターネット, IoTプラットフォーム, IoTセンサー, コングテック, Kong-tech',
        desc: '配送ロボット、無人積み降ろしシステム、消毒システムなど、さまざまな統合ソリューションを通じて、効率的で安全な運用をサポートします。オブロの統合システムは、位置追跡技術とリアルタイムモニタリングによって最適なパフォーマンスを提供します。',
        img: '',
        url: 'https://orbro.io/jp/integration'
    },

    home: {
        title: 'オブロ | ORBRO、デジタル変革の始まり',
        key: '位置測位、資産管理、資産測位、倉庫管理、倉庫資産管理、室内位置測位、RTLS、位置測位ソリューション、重大災害処罰法、建設現場の安全、来訪者の経路分析、出入りシステム、第4次産業革命、スマートファクトリー、デジタルツイン、ビーコン、ブルートゥース、リアルタイム位置システム、室内位置測位、資産測位、資産管理、倉庫管理、倉庫資産管理、室内位置測位、RTLS、位置測位ソリューション、建設現場の安全、来訪者の経路分析、出入りシステム、第4次産業革命、スマートファクトリー、デジタルツイン、ビーコン、ブルートゥース、BLE、UWB、IoT',
        desc: 'オブロ | ORBROは、現実をデジタルの世界に生き生きと再創造したデータプラットフォームです。'

    },


    tech: {
        uwb: {
            title: 'UWB位置測位およびポジショニング技術 :: オブロ | ORBRO、デジタル変革の始まり',
            key: '位置測位ソリューション、室内位置測位、GIS、UWB、RTLS、RTLSソリューション、UWB技術、位置測位技術、技術ソリューション、UWBタグ、Tdoa、TWR、AI技術',
            desc: 'UWBは、非常に広い帯域幅を使用して非常に短い時間で非常に強力な信号を送信するワイヤレス通信技術の一つです。'
        },
        ble: {
            title: 'BLE位置測位およびポジショニング技術 :: オブロ | ORBRO、デジタル変革の始まり',
            key: '位置測位ソリューション、室内位置測位、GIS、UWB、RTLS、RTLSソリューション、UWB技術、位置測位技術、技術ソリューション、UWBタグ、Tdoa、TWR、AI技術',
            desc: 'BLEは、低消費電力でデータ転送速度が低いワイヤレス通信技術の一つです。主に小型デバイスや低消費電力センサーで使用され、ビーコンなどの小型送信機としても利用されます。'
        },
        twr: {
            title: 'TWR位置測位技術 :: オブロ | ORBRO、デジタル変革の始まり',
            key: '位置測位ソリューション、室内位置測位、GIS、UWB、RTLS、RTLSソリューション、UWB技術、位置測位技術、技術ソリューション、UWBタグ、Tdoa、TWR、AI技術',
            desc: 'TWR（Two-Way Ranging）技術は、送信機と受信機間の往復信号の時間を測定して距離を計算する位置測位技術の一つです。'
        },
        tdoa: {
            title: 'TDoA位置測位技術 :: オブロ | ORBRO、デジタル変革の始まり',
            key: '位置測位ソリューション、室内位置測位、GIS、UWB、RTLS、RTLSソリューション、UWB技術、位置測位技術、技術ソリューション、UWBタグ、Tdoa、TWR、AI技術',
            desc: 'TDoA（Time Difference of Arrival）技術は、送信機からの信号到達時間の差を測定して受信機の位置を計算する位置測位技術の一つです。'
        },
        stereo: {
            title: 'ステレオビジョンを利用した位置測位およびポジショニング技術：オブロ | ORBRO、デジタル変革の始まり',
            key: 'ビジョン、ディープラーニング、位置、DepthEstimation、位置推定、ステレオビジョン、RTLSソリューション、RTLS技術',
            desc: 'ステレオビジョンは、2つの結合されたカメラを使用してオブジェクトを3次元で認識する技術です。'
        },
        mono: {
            title: 'モノキュラービジョンを利用した位置測位およびポジショニング技術：オブロ | ORBRO、デジタル変革の始まり',
            key: 'ビジョン、ディープラーニング、位置、DepthEstimation、位置推定、モノキュラービジョン、RTLSソリューション、RTLS技術',
            desc: 'モノキュラービジョンは、単眼カメラを利用して画像を取得し分析するコンピュータビジョン技術です。'
        }
    },


    product: {
        title: '製品紹介：オブロ | ORBRO、デジタル変革の始まり',
        key: 'ビーコン、室内位置測位機、UWB、BLE、RTLS、端末、オブロ、オブロ製品、GIS',
        desc: 'さまざまなオブロの製品をご覧ください。',
    },
    blog: {
        title: 'オブロブログ | ORBRO、デジタル変革の始まり',
        key: 'オブロ、オブロブログ、RTLS',
        desc: 'RTLSに関連するブログ記事をご覧ください。',
        url: 'https://orbro.io/jp/blog'
    },
    company: {
        title: '生き生きとした仮想世界の実現：オブロ | ORBRO、デジタル変革の始まり',
        key: 'デジタルツイン、DT、メタバース、公共情報、3Dワールド、オブロ、産業用プラットフォーム、産業用メタバースプラットフォーム、ORBRO、出入システム、空気管理、UWB、RTLS、技術ソリューション、位置測位ソリューション、技術営業、技術ソリューション、ピープルアンドテクノロジー',
        desc: 'オブロは、200ゼタバイト以上のデータを3D形状化し、革新的なデータ接続プラットフォームです。',
        url: 'https://orbro.io/jp/company'
    },
    request: {
        title: 'ORBROへのお問い合わせ :: オブロ | ORBRO、デジタル変革の始まり',
        key: 'オブロ、ORBRO、デジタルツイン、RTLS、IPS、GPS、GIS、MES、中大災罪処罰法、ビーコン、Bluetooth、超広帯域、BLE、UWB、IoT、モノのインターネット、位置測位、室内位置測位、アクセス、アセット、デジタル変革、DX、第4次産業革命、スマートファクトリー、スマートシティ、RTLSソリューション',
        desc: '室内位置測位、資産測位技術に関するご質問がございましたら、お気軽にお問い合わせください。',
        url: 'https://orbro.io/jp/request'
    },
    platform: {
        title: '生き生きとした仮想世界の実現：オブロ | ORBRO、デジタル変革の始まり',
        key: 'オブロ、ORBRO、デジタルツイン、RTLS、IPS、GPS、GIS、MES、中大災罪処罰法、ビーコン、Bluetooth、超広帯域、BLE、UWB、IoT、モノのインターネット、位置測位、室内位置測位、アクセス、アセット、デジタル変革、DX、第4次産業革命、スマートファクトリー、スマートシティ、RTLSソリューション',
        desc: 'より迅速な室内位置測位とより簡単な資産管理が可能です。オブロシステムはパワフルですが、同時に使いやすくシンプルです。',
    },
    pricing: {
        title: '見積もり依頼 :: ORBRO、デジタルトランスフォーメーションの始まり',
        key: '位置追跡、カメラ位置追跡、AIカメラ、出入口端末、アクセスコントロールシステム、自動ドア、BLE、UWB、産業用センサー、LoRaゲートウェイ、空気品質モニター、一酸化炭素モニター、非常ベル、ビーコン、産業用ビーコン、リアルタイム位置追跡、建設安全管理、物流在庫管理、資産管理、患者位置追跡、製造プロセス管理、スマートソリューション、来訪者分析、ORBRO、Kongtech、orbro、RTLS',
        desc: '私たちはさまざまな製品とサービスに関する無料の見積もりを提供する信頼性のあるパートナーです。お客様の要件に合わせてカスタマイズされた見積もりを提供し、価格の透明性とお客様満足度を最優先に考えています。見積もりの依頼を通じて、効率的で経済的なソリューションを見つけ、プロジェクトを進行するために必要な情報を取得してください。',
        url: 'https://orbro.io/jp/pricing/1'
    },
    schedule: {
        title: '相談予約 :: ORBRO、デジタル変革の始まり',
        key: '位置追跡、カメラ位置追跡、AIカメラ、出入口端末、アクセスコントロールシステム、自動ドア、BLE、UWB、産業用センサー、LoRaゲートウェイ、空気品質モニター、一酸化炭素モニター、非常ベル、ビーコン、産業用ビーコン、リアルタイム位置追跡、建設安全管理、物流在庫管理、資産管理、患者位置追跡、製造プロセス管理、スマートソリューション、来訪者分析、ORBRO、Kongtech、orbro、RTLS',
        desc: '私たちはさまざまな製品とサービスに関する無料の見積もりを提供する信頼性のあるパートナーです。お客様の要件に合わせてカスタマイズされた見積もりを提供し、価格の透明性とお客様満足度を最優先に考えています。見積もりの依頼を通じて、効率的で経済的なソリューションを見つけ、プロジェクトを進行するために必要な情報を取得してください。',
        url: 'https://orbro.io/jp/schedule'
    }



}