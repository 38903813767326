import { MainMenus, Menu } from '@layouts/Default/core/modelCombine';
import React, { useEffect, useState } from 'react';
import ArrowChevronUpLight from '@assets/icons/ic-arrow-chevron-up-light.svg';
import ArrowChevronDownLight from '@assets/icons/ic-arrow-chevron-down-light.svg';
import ArrowChevronDownDark from '@assets/icons/ic-arrow-chevron-down-dark.svg';

import './NavBox.styl';
import { Link, useLocation } from 'react-router-dom';

import MenuOpenIconLight from '@assets/icons/ic-triple-line-light.svg';
import MenuOpenIconDark from '@assets/icons/ic-triple-line-dark.svg';
import {
  StyledHeaderContainer, StyledNavBox,
  StyledHeaderLogo, StyledMenu, StyledMenus,
  StyledNavText, StyledGlobal, PricingBtn, StyledImage, StyledNavArrow, NavLogoBox, LabelColorNav, StyledNavTextBlog
} from './NavBox.style';

import useClickOutside from '@hooks/useClickOutside';
import { Trans } from 'react-i18next';
import SubListGlobal from './components/SubList/SubListGlobal';
import i18n from '@pages/i18n';
import { IconGlobal, IconGlobalWhite } from '@assets/images/icon'

import Tree2 from './components/MenuTree/Tree2';
import Tree3 from './components/MenuTree/Tree3';

import { logoBlack, logoWhite } from '@assets/images/logo';
import MobileNavCombine from './components/MobileNav/MobileNav';
import { ImgCursor, ImgWH, LabelColor, SpacingL } from '@pages/CommonStyle/common.style';
import { DivPaddingRight } from '@pages/Enterprise/Technology/Technology.style';


const NavBox = () => {

  const currentLanguage = i18n.language;
  const [isGNBFixed, setIsGNBFixed] = useState(false);
  const [isBannerColor, setBannerColor] = useState(false);

  const checkRoot = () => {
    if (location.pathname === '/'
      || location.pathname === '/en/'
      || location.pathname === '/jp/'
      || location.pathname === '/cn/') {
      return true;
    } else if (location.pathname.includes('digital-twin') && !location.pathname.includes('blog') && !location.pathname.includes('technology')) {
      return true;
    }
    else {
      return false;
    }

  }

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;
      let sizeLimit = 0;
      if (i18n.language === 'ko') {
        sizeLimit = 1114;
      } else if (i18n.language === 'jp') {
        sizeLimit = 1200;
      } else {
        sizeLimit = 1100;
      }
      if (innerWidth > sizeLimit) {
        setIsShowMobileNav(false);
      } else {
        setIsShowMobileNav(true);
      }
    };

    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      let gnbHeight = 960; // GNB의 높이에 맞게 수정해주세요.

      if (location.pathname === '/') {
        gnbHeight = 960; // GNB의 높이에 맞게 수정해주세요.
      } else {
        gnbHeight = 60;
      }

      if (scrollPosition > gnbHeight) {
        setIsGNBFixed(true);
        setBannerColor(true);
      } else {
        setIsGNBFixed(false);
        setBannerColor(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);

  // React
  const location = useLocation();
  const menuBox = useClickOutside(() => {
    setSubMenu(null);
    setShowLangMenu(false);
  });

  // States
  const [isShowMobileNav, setIsShowMobileNav] = useState<boolean>(false);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [subMenu, setSubMenu] = useState<Menu | null>(null);

  const [showLangMenu, setShowLangMenu] = useState<boolean>(false);

  const toggleLangMenu = () => {
    setShowLangMenu((prevShowSubMenu) => !prevShowSubMenu);
  };

  const [isDark, setDark] = useState<boolean>(false);

  useEffect(() => {
    setIsOpenMenu(false);
    setTreeHover(false);
    setNavHover(false);
    mouseLeaveCheck();
    if (checkRoot()) {
      setDark(true);
    } else if (location.pathname.includes('enterprise') && !location.pathname.includes('techno')) {
      setDark(false);
    } else {
      setDark(false);
    }

    if (location.pathname.includes('tech')
      || location.pathname.includes('request')
      || location.pathname.includes('company')) {
      setIndex(7);
    } else if (location.pathname.includes('enterprise')) {
      setIndex(6);
    } else if (location.pathname.includes('blog')) {
      setIndex(12);
    } else if (location.pathname.includes('/rtls')) {
      setIndex(0);
    } else if (location.pathname.includes('/beacon')) {
      setIndex(1);
    } else if (location.pathname.includes('/camera')) {
      setIndex(2);
    } else if (location.pathname.includes('/access')) {
      setIndex(3);
    } else if (location.pathname.includes('/sensor')) {
      setIndex(4);
    } else if (location.pathname.includes('/integration')) {
      setIndex(5);
    } else {
      setIndex(12);
    }

  }, [location.pathname])

  const currentLang = i18n.language;
  const [navIndex, setIndex] = useState<number>();
  const [isMenuHover, setMenuHover] = useState<boolean>(false);
  const [hoveredMenuIndex, setHoveredMenuIndex] = useState<number>(-1);

  const [onNavHover, setNavHover] = useState<boolean>(false);
  const [onTreeHover, setTreeHover] = useState<boolean>(false);
  const [onHeadHover, setHeadBox] = useState<boolean>(false);

  const mouseLeave = () => {
    setTreeHover(false);
    mouseLeaveCheck();
    setNavHover(false);
    setHoveredMenuIndex(0);
  }

  const mouseLeaveCheck = () => {
    if (!onNavHover && !onTreeHover && !onHeadHover) {
      setMenuHover(false);
      setHoveredMenuIndex(-1);
    }
  }

  const handleIndex = (index: number) => {
    if (index < 6) {
      setIndex(index);
    }
  }



  return (
    <>
      <StyledNavBox position={'absolute'} isGNBFixed={checkRoot() ? isGNBFixed : isGNBFixed}
        bgColor={(hoveredMenuIndex === 6)
          || (hoveredMenuIndex === 7)
          || isBannerColor
          || location.pathname.includes('/enter') ? '#FFFFFF' : 'transparent'}>
        <StyledHeaderContainer
          onMouseLeave={() => { setHeadBox(false); }}>
          <Link to={currentLanguage !== 'ko' ? `/${currentLanguage}/` : `/`}>
            <NavLogoBox>
              <StyledHeaderLogo src={isDark && !isBannerColor && hoveredMenuIndex !== 6 && hoveredMenuIndex !== 7 ? logoWhite : logoBlack}
                alt='ORBRO Logo'
                onClick={() => handleIndex(10)} />
            </NavLogoBox>
          </Link>

          {isShowMobileNav === false ? (
            <>

              <StyledMenus ref={menuBox}>
                {MainMenus.map((menu, index) => (
                  <div className="menu-container" key={index}>

                    <Link to={menu.path === 'none' ? location.pathname.toString() : currentLanguage !== 'ko' ? `/${currentLanguage}${menu.path}` : `${menu.path}`}>
                      <StyledMenu
                        active={Boolean(subMenu && subMenu.id === menu.id)}
                        onMouseEnter={() => {
                          setMenuHover(true);
                          setHoveredMenuIndex(index);
                          setNavHover(index === 6 || index === 7 ? true : false);
                          setTreeHover(false);
                        }}
                        onMouseLeave={() => {
                          mouseLeaveCheck();
                        }}
                        isLast={index === MainMenus.length - 1}
                        onClick={() => handleIndex(index)}
                        click={index === navIndex ? true : false}
                      >
                        {menu.sub === '1' ?
                          <>
                            <LabelColorNav size='small' color={hoveredMenuIndex === 6 || hoveredMenuIndex === 7 || !isDark || isBannerColor ? '#000000' : '#FFFFFF'}>
                              <Trans i18nKey={menu.name} />
                            </LabelColorNav>

                            <StyledNavArrow>
                              <SpacingL pixel={'4'}>
                                <ImgWH w='12' h='12'
                                  src={
                                    isDark ?
                                      (isGNBFixed || onNavHover) ?
                                        (hoveredMenuIndex === index ? ArrowChevronUpLight : ArrowChevronDownLight) :
                                        ArrowChevronDownDark :
                                      (hoveredMenuIndex === index ? ArrowChevronUpLight : ArrowChevronDownLight)
                                  } alt='ArrowIcon' />
                              </SpacingL>
                            </StyledNavArrow>
                          </>
                          :
                          <LabelColorNav size='small' color={hoveredMenuIndex === 6 || hoveredMenuIndex === 7 || !isDark || isBannerColor ? '#000000' : '#FFFFFF'}>
                            <Trans i18nKey={menu.name} />
                          </LabelColorNav>
                        }

                      </StyledMenu>
                    </Link>
                  </div>
                ))}
              </StyledMenus>

              {/* 블로그 */}
              <DivPaddingRight pixel={'8'} />
              <Link to={i18n.language === 'ko' ? '/blog' : `/${i18n.language}/blog`}>
                <StyledGlobal>
                  <StyledNavTextBlog size="small" color={(hoveredMenuIndex !== 6 && hoveredMenuIndex !== 7 && isDark && !isBannerColor) ? '#ffffff' : '#000000'}
                    click={location.pathname.includes('/blog') ? true : false}>
                    <Trans i18nKey={'nav.support.blog'} />
                  </StyledNavTextBlog>
                </StyledGlobal>
              </Link>

              <DivPaddingRight pixel={'8'} />

              {location.pathname.includes('pricing') ?
                <Link to={currentLang === 'ko' ? '/request' : `/${currentLang}/request`}>
                  <PricingBtn>
                    <LabelColorNav size='small' color='#FFF'>
                      <Trans i18nKey={'nav.support.request'} />
                    </LabelColorNav>
                  </PricingBtn>
                </Link>
                :
                <Link to={currentLang === 'ko' ? '/pricing/1' : `/${currentLang}/pricing/1`}>
                  <PricingBtn>
                    <LabelColorNav size='small' color='#FFF'>
                      <Trans i18nKey={'pricing.title'} />
                    </LabelColorNav>
                  </PricingBtn>
                </Link>
              }

              <DivPaddingRight pixel={'8'} />

              {/* 언어 */}
              <StyledGlobal ref={menuBox} onClick={(e) => { e.stopPropagation(); toggleLangMenu(); }}>
                <StyledNavText size="small" color={(hoveredMenuIndex !== 6 && hoveredMenuIndex !== 7 && isDark && !isBannerColor) ? '#ffffff' : '#000000'}>
                  <StyledImage src={(hoveredMenuIndex !== 6 && hoveredMenuIndex !== 7 && isDark && !isBannerColor) ? IconGlobalWhite : IconGlobal} alt='Global Icon Logo' />
                </StyledNavText>

                {showLangMenu && (
                  <SubListGlobal />
                )}
              </StyledGlobal>


              {hoveredMenuIndex === 6 && <Tree2 mouseLeave={mouseLeave} />}
              {hoveredMenuIndex === 7 && <Tree3 mouseLeave={mouseLeave} />}

            </>
          ) : (
            <ImgCursor
              src={isDark && !isBannerColor ? MenuOpenIconDark : MenuOpenIconLight} alt='ArrowIcon'
              className="icon"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenMenu(!isOpenMenu);
              }}
            />
          )}
        </StyledHeaderContainer>
      </StyledNavBox>

      {isShowMobileNav && isOpenMenu && (
        <MobileNavCombine
          onClose={() => {
            setIsOpenMenu(false);
          }}
        />
      )}

      {/*
         <StyledNavTopBox />
      */}

    </>
  );
};

export default NavBox;
