import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4';

export default {

  desc : '스마트 주차장은 현대 도시 환경에서 주차 문제를 해결하고 효율적인 주차 관리를 위해 첨단 기술과 인프라를 활용하는 주차 공간입니다. 이러한 주차장은 다양한 기술적 요소를 통합하여 주차 프로세스를 최적화하고 차량 이용자들에게 더 편리한 경험을 제공합니다.',
  keywords : '스마트 주차장, 주차관제, 휴게소, 디지털 트윈, 디지털 전환, digital twin, orbro, beacon, rtls, uwb, ble, ai camera, 오브로, 콩테크',

  ogSite : '스마트 주차장 시스템 | 위치추적과 디지털 트윈, 오브로',
  ogUrl : 'https://orbro.io/blog/smart-parking',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmD7VPkEf-FilCFjgoS9bLoX8jzIaaMktsjySXAPNjEeeFagK2_315Sl39yXx7L8iamkUCasZgYN_nmqjEzlcJ7b471GwtTQQuGwwOAfQQA6S8NE3asVOx8onF2HoacQcQFEPQ8CAhcl0rNpbh8bBS8FFHY4_D2fvH08mJkm9qWDYYAV69cnY6EoN7AbjCxcmjDp9MRJWdjSZLXFzgpaIIYwkOai0qq4fv00oAK7nW5aHmjQ7yW3Fs_dnUPcWjO02-SJvPDSXNRfqALw7R8fQ1xzAouT41STdr9FL0nvwawirXEQrJHdFYmtKxTMeYwrzrbvapEVr_9gbwWf7Xz6op2cCCQ9rlTypNRAUcoImxb8moTg_z4h0lB4He17_bFIKP8htIGOonJQ30cdXaym4saPjQhyRCio1wMUVwgbmNVC3b4zyJ5L9HnxyCsxDiVEDthb0FhoiTe75C-fAaLDxkhVfyjQTSX7lCYYG_JjPfTEo2kBb6fU79VYeUO4Kb_eWYiPR2Iwfzuu7StZDg_fEldXXzjVx6NJPi6R4a0r8VwuTtEwURqugwI57tg0kMwc1CN3P6SfBrzGPldfU6D5kntEPrH4rZvGkFLUOhGrKJWQrBodIb2rV5aACulW8o5W5D8wDEJkfyNS-F0oQHBEqzp9nZxKDuGMZ2gb_1rBuvwbphoql1q_W42SNGRTzjK3yZLzxxh9utXyY4bL4KVGrSVYX5BrshF1Uxy9qzyfmgNeI_sy5Tbah5GI3QhJG_MWU8nN5WicgsNpOWRVNSKyEhrt5hUOE5AVc60roL6Ar04TIJvjwBc85d0W_DonnbugsHZd-ZE2a1qACGExoN1GoO7gY8I0rGyaeXPXmz-eKFBYlni7VNvL_-WZlVfON20nmmnJGjJENADAWpBqdcJsKErZNryomDdplCibc53ZnYZ0-tE14kXFvQSMVx9rpq7xNi459vviazTPO_ZP6OKfTXX6tIrYW-vNA6r-vVM2nvber10dIqepSAUGPhm85RXyKGyHzU2sMQZA_0KKZR9ghdnDbzKyk6Ig0gcSclnaKoUAv0tV8ouS-IML_FSjTylgxNCHCeh5DRwimnmWs-M-Qj8E_pvYxpE5gDx5NFgO3KQtnEk7ZocAEpOSVFN5PHfWUhpTh-ZsM4joGNhdAiBAbc-VZzDs0jIIC2xpKDPdpUtVvIaVM9X_lf5FKcKd7VLx27m6hSL2bmbgdvx4t33C6fN69eGruOV7uvS1-K7vO6dqs-g4FWmrLDnOFmCGDyaa8gwvxozpfxaSUqklsYGSVuw6zCnAgAM-z6ApVjVb6P_aRbWW4ETJnzuMg3O4RVMoQZ1XGTM2kXC_0Um42ueBxfKmL7cAdcHi77Mxu7jLWBKoR1MI0uR-Flk-ouBsLn0joMzdQK6d3noiwmXVnNt26nLyiTY0OuyNhP5oDp6lXYyWjUsUwSZs2GpTcNDljddjYs1m1T7ckdu_JHlFsHpJ3RYQxuOcSAHdB4sv4hQohcrrbkctCpfLF8KUHAI4LbX1RqQ8eHJfD1wKMUSd7CydiQFcKD7FLnz=w1501-h1172',
  ogType : 'website',
    
    title: '스마트 주차장 시스템',
    date : '2024-01-24',
    tag1 : '#스마트주차장',
    tag2 : '#주차관제',
    tag3 : '#휴게소',
    tag4 : '#디지털트윈',
    
    // <br/>
    text : [
        {
          head : '스마트 주차장이란?',
          subHead :'',
          desc :'스마트 주차장은 현대 도시 환경에서 주차 문제를 해결하고 효율적인 주차 관리를 위해 첨단 기술과 인프라를 활용하는 주차 공간입니다. 이러한 주차장은 다양한 기술적 요소를 통합하여 주차 프로세스를 최적화하고 차량 이용자들에게 더 편리한 경험을 제공합니다. <br/>이러한 시스템을 위해서, 현실의 주차장 모습과 동일한 디지털 화면을 생성하고 데이터를 전달하는 디지털 트윈 기술을 적용해야 합니다. 스마트 주차장의 주요 특징과 기능은 다음과 같습니다:',
          dot : ''
        },
        {
          head : '스마트 주차장 기능',
          subHead :'',
          desc :'스마트 주차장은 다양한 첨단 기술을 활용하여 주차 관리와 이용자 경험을 개선하는 다양한 기능을 제공합니다. 이러한 기능은 주차장 관리자와 차량 이용자 모두에게 혜택을 제공하며 도시의 주차 문제를 해결하는 데 도움이 됩니다. 아래는 일반적으로 스마트 주차장에서 제공되는 주요 기능 목록입니다',
          dot : {
            a : '<strong> • 실시간 위치 추적 : </strong>AI 카메라, 센서 및 IoT 기술을 사용하여 주차장 내의 차량 위치를 실시간으로 추적하고 관리합니다. 이를 통해 빈 주차 공간을 신속하게 식별할 수 있습니다.',
            b : '<strong> • 주차 가이드 및 안내 : </strong>사용자에게 빈 주차 공간의 위치를 안내해주는 모바일 앱 또는 디스플레이를 통해 주차를 더 쉽게 할 수 있습니다.',
            c : '<strong> • 주차 예약 : </strong>사용자는 원격으로 주차 공간을 예약할 수 있으며, 도착 전에 주차 공간을 확보할 수 있습니다.',
            d : '<strong> • 유동인구 분석 : </strong>주차장 내부의 센서와 카메라를 사용하여 어느 시간대에 주차장이 가장 혼잡한지를 분석하고 이용자에게 이 정보를 제공합니다.',
            e : '<strong> • 번호판 인식 : </strong>차량의 번호판을 인식하여 불법 주차, 주차 시간 초과 및 기타 위반 사항을 자동으로 감지하고 관리합니다.',
            f : '<strong> • 보안 및 안전 : 주</strong>차장 내부에 CCTV 카메라와 센서를 설치하여 차량 및 주차장 내의 안전을 강화합니다.',
            g : '<strong> • 데이터 분석과 최적화 : </strong>수집된 데이터를 분석하여 주차장 운영을 최적화하고 개선점을 찾습니다. 이를 통해 주차장 관리자는 자원을 효율적으로 활용할 수 있습니다.',
          }
        },
        {
          video : video,
          link : '/enterprise/manufacturing/process',
          head : '스마트 주차장 기술',
          subHead :'AI 카메라 및 비전 기술',
          desc :'AI 카메라는 고급 컴퓨터 비전 기술을 기반으로 한 카메라 시스템입니다. 주차장 내의 모든 활동을 모니터링하고 분석하기 위해 사용됩니다. 예를 들어 차량 번호판, 차량 종류 및 색상, 차량의 위치 및 방향 등을 실시간으로 감지하고 인식합니다.<br/>이 정보는 주차장 운영을 최적화하고 차량 이용자들에게 주차 정보를 제공하기 위해 활용됩니다. 그리고 불법 주차나 위반 사항을 식별하고 관리하는 데도 사용됩니다.',
          dot : ''
        },
        {
          head : '',
          subHead :'IoT (사물 인터넷)',
          desc :'IoT는 주차장 내에 설치된 다양한 센서와 장치를 통해 데이터를 수집하고 전송하는 기술입니다. 센서는 차량의 이동, 주차 슬롯 가용성, 주차장 내의 온도, 습도 등과 같은 다양한 정보를 실시간으로 수집하며, 이 정보는 주차장 운영 및 관리에 사용되고 주차장의 상태를 모니터링하고 분석하여 차량 이용자들에게 편리한 주차 환경을 제공합니다.',
          dot : ''
        },
        {
          head : '',
          subHead :'모바일 앱 및 웹 서비스',
          desc :'모바일 앱 및 웹 서비스는 차량 이용자들에게 주차 정보를 제공하고 주차 프로세스를 관리하는 데 사용됩니다. 사용자들은 모바일 앱을 통해 빈 주차 공간의 위치를 확인하고 예약할 수 있습니다. 또한 주차 요금을 결제하고 주차 상태를 모니터링할 수 있습니다. 또한, 웹 서비스를 통해 사용자는 컴퓨터 브라우저에서도 주차 정보를 확인하고 관리할 수 있으며, 이용자들에게 다양한 편의성을 제공합니다.',
          dot : ''
        },
        {
          head : '',
          subHead :'빅 데이터 분석',
          desc :'빅 데이터 분석은 스마트 주차장에서 수집된 대량의 데이터를 심층적으로 분석하는 프로세스를 의미합니다. 수집된 데이터는 차량 이용자들의 행동 패턴, 주차장 내 혼잡도, 주차 요금 정책의 효과 등 다양한 측면에서 분석됩니다.',
          dot : {
            a : '<strong> • 주차장 운영 최적화 : </strong>주차장의 운영 시간, 주차 슬롯 배치, 주차 요금 정책 등을 최적화하여 효율성을 향상시킵니다.',
            b : '<strong> • 사용자 경험 향상 : </strong>사용자들의 행동을 이해하고, 이에 기반하여 주차 프로세스를 개선하여 사용자 경험을 향상시킵니다.',
            c : '<strong> • 수익 증대 : </strong>빅 데이터 분석을 통해 주차 요금 정책을 최적화하고, 수익을 증대시키는 방법을 찾습니다.',
            d : '<strong> • 예측 모델 개발 : </strong>데이터 분석을 통해 향후 주차 공간 가용성, 주차 수요 등을 예측하는 모델을 개발하여 운영을 계획합니다.',
            e : '<strong> • 문제 식별과 대응 : </strong>데이터 분석을 통해 주차장 내 문제점을 식별하고 빠른 대응 조치를 취합니다. 예를 들어, 주차장 내의 혼잡을 예방하기 위해 추가 주차 공간을 할당할 수 있습니다.',
          }
        },
        {
          head : '스마트 주차장 사례',
          subHead :'대한민국 휴게소 - 비콘을 활용한 주차장 관리',
          desc :'대한민국의 일부 주차장은 비콘 기술을 활용하여 스마트 주차장을 운영하고 있습니다. 비콘은 모바일 앱과 연동되어 주차 공간의 실시간 가용성을 제공하고, 주차장 내에서 차량을 식별하여 출입을 관리합니다. 또한 사용자에게 모바일 앱을 통해 주차 정보를 제공하고 결제를 간편하게 처리할 수 있도록 돕습니다.',
          dot : ''
        },
        {
          head : '',
          subHead :'로스앤젤레스, 미국 - 실시간 주차 정보 제공',
          desc :'로스앤젤레스는 도심 지역의 주차 문제를 해결하기 위해 스마트 주차장을 구축하고 실시간 주차 정보를 제공하는 서비스를 운영하고 있습니다. 이 서비스를 통해 드라이버들은 빈 주차 공간의 위치와 주차 요금 정보를 모바일 앱에서 확인할 수 있으며, 주차장 이용이 편리해졌습니다.',
          dot : ''
        },
        {
          head : '',
          subHead :'상하이, 중국 - 자율 주차 로봇',
          desc :'상하이의 일부 주차장은 자율 주차 로봇을 도입하여 차량을 자동으로 주차 공간에 주차시키고 검색하는 기술을 사용하고 있습니다. 드라이버는 차량을 주차장 입구에 들어냄으로써 로봇 시스템을 활용할 수 있으며, 이로써 주차 시간을 단축하고 편리함을 느낄 수 있습니다.',
          dot : ''
        },
        {
          head : '',
          subHead :'도쿄, 일본 - 주차 요금 자동 결제',
          desc :'도쿄의 일부 주차장은 차량의 번호판 인식 기술과 모바일 앱을 통해 주차 요금을 자동으로 계산하고 결제합니다. 이로써 드라이버들은 주차장을 빠르게 떠날 수 있으며, 현금이나 신용카드로 결제하는 번거로움을 덜 수 있습니다.',
          dot : ''
        },
        {
          head : '스마트 주차장 전망',
          subHead :'',
          desc :'미래에는 스마트 주차장이 더욱 중요한 역할을 하며, 자율 주행 차량과 통합하여 주차 프로세스를 최적화하고 자동 주차 기능을 활용할 것으로 예상됩니다. 또한 고속 데이터 통신 기술의 발전은 실시간 데이터 공유를 용이하게 하고, 머신 러닝과 인공 지능 기술은 주차 예측과 사용자 패턴 분석을 향상시킬 것입니다.<br/><br/>스마트 주차장은 스마트 도시 프로젝트와 통합되어 교통 관리와 조절을 위한 핵심 구성 요소가 될 것이며, 주차 데이터는 도시 계획과 교통 체증 관리에 활용될 것입니다. 보안 기술도 더욱 강화될 것으로 예상되며, 주차장 내의 차량 및 주차 시설에 대한 안전을 보장하는 데 큰 역할을 할 것입니다.',
          dot : ''
        },
    ],
  
    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/enterprise/construction/safety'
        },
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/enterprise/construction/asset'
        }, 
        {
          img : constructionEquipment.thumb,
          data : 'constructionEquipment',
          link : '/enterprise/construction/equipment'
        }
      ]
  
  
    }
  
  
  }