import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: 'ORBROは屋内外の建築構造およびインフラのデジタル化を通じて、建物の管理と計画を革新的に向上させます。これにより建築設計、施設管理、および安全モニタリングに重要な情報が提供されます。',
  keywords: 'Digital Twin, Digital Twins, デジタルツイン, gis, デジタルシティ, RTLS, 位置追跡システム, 位置追跡技術, スポーツ, 製造, ロジスティクス, 医療, 病院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'デジタルツイン - 建物および屋内情報の実装 :: ORBROブログ',
  ogUrl: 'https://orbro.io/jp/blog/digitaltwin-buildings',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'デジタルツイン - 建物および屋内情報の実装',
  date: '2023-12-26',
  tag1: '#デジタルツイン',
  tag2: '#デジタルビルディング',
  tag3: '#デジタルシティ',
  tag4: '#バーチャルリアリティ',

  text: [
    {
      head: 'デジタルツイン - 建物および屋内情報の実装',
      subHead: '',
      desc: 'ORBROは建物および室内情報を革新的に実装し、建物管理と計画を向上させています。これには建築構造とインフラをデジタル化し、多様な情報を通じて建築設計、施設管理、安全モニタリングに必要な重要な情報を提供します。<br/><br/>高解像度の画像と3Dモデリングを使用して建物の外観と構造的な特徴を詳細に表現し、室内マップは建物内部のレイアウトを精密に可視化します。このような情報は、建物の視覚的な表現と構造を明確に理解するのに役立ちます。<br/><br/>さらに、建物内のインフラ情報の表現、リアルタイムの機器状態の確認、外部機器の統合は、建物管理、保守、自動化において重要な役割を果たしています。これにより、建物の運用と管理に必要なデータをリアルタイムで確認し、効率的に管理することができます。',
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/KkvO62eClcU?si=AQmNMfH6nmQ5Aa3Z',
      head: '',
      subHead: '建物の詳細なグラフィック表現',
      desc: 'ORBROは高解像度の画像と先進的な3Dモデリング技術を活用して、建物の外観と構造的な特徴を詳細に表現します。これは建築家、エンジニア、都市計画者が建物のデザインと機能を評価する際に重要な役割を果たします。',
      dot: ''
    },
    {
      head: '',
      subHead: '屋内マップ表現',
      desc: '建物内部のレイアウトを正確に視覚化して屋内スペースの利用率を最大化し、安全および避難計画に必要な情報を提供します。',
      dot: ''
    },
    {
      head: '',
      subHead: '建物内のインフラ情報の表現',
      desc: 'HVACシステム、電気および配管システムなど建物内の重要なインフラをデジタル化し、建物管理および保守に必要な情報を提供します。',
    },
    {
      head: '',
      subHead: 'リアルタイムの機器状態確認',
      desc: '建物内の機器およびシステムのリアルタイムモニタリングを通じて、機器の性能低下や故障を迅速に検知し、即座の保守を行います。',
    },
    {
      head: '',
      subHead: '外部機器の統合',
      desc: 'スマートテクノロジーおよびIoTデバイスとの統合により、建物管理システムをより知的かつ自動化されたものにします。',
    },
    {
      head: '',
      subHead: '開発者センターサポート',
      desc: '開発者が建物関連データにアクセスし、カスタムアプリケーションを開発できるようサポートすることで、建物管理および運用に革新的なソリューションを導入します。',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を作業者や機器に取り付けて、リアルタイムで位置追跡を支援する製品です。',
        img: productImg.product.workerTracking.thumbnail
      },
      // 他の製品オブジェクトはここに追加できます
    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/jp/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/jp/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      }
    ]
  }
}
