import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {
  helmet: '| Latest Technology Trends Every Logistics Industry Worker Should Know',
  desc: 'Moving away from traditional passive inventory management methods, it is now possible to track the location and status of goods and equipment in real-time. This helps prevent issues such as stock shortages or excess inventory and can lead to cost savings in logistics.',
  keywords: 'Logistics inventory, Logistics inventory management, Inventory management, Asset management, Logistics center inventory management, Logistics center, Large logistics center, Smart logistics, Smart logistics center, Logistics facilities, Inventory tracking, RTLS, Real-Time Location System, ORBRO, ConTech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Latest Technology Trends Every Logistics Industry Worker Should Know :: ORBRO Blog',
  ogUrl: 'https://orbro.io/en/blog/logisticsTech',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4PVu4kuy9M18H7FmFyW0Q0eESSyhQnDGg14oHiyiErzgaj9C6c04JTCmvEdjNFy1RXc-dFULyWRt2kEo4Cc08fuynbaorsmbVA1WZzOjFxamTouK1BSdqmJMWpihlk4UT2DwWY82EunIjppF5ShLp_E_SC2kVFAxBNtgKGuiHxsQedwiLBHpCzoo9wHtnqwDT_VLhRXu6kCMciziqklxTVzgLUnGfJx4fwSZCUeP_97fRfmoRUa74mRFh7J3r_WHTjKEM6lA3ewY1aFfygHCHH9YILBsyiOObeQqrP-rwrQsFFN9tegAJUWXqMBIdU0iZrmP8BE5aKgWUlkcv4BGfmKVIsOLypMPvOlrwLGc69gq-Ry-75Kq70RLYMXTIGNBeFCX1OoWSQXyi8xtlMbio4P-R3SYRfST4i-NaC0uTHKYNFw4X2pi32DpBC6ABiJ36qYntNOyIjav_Gxsj1J1hX7f11VXEpXn99emstdDgDQWVe0GJFpuy3S1BLOUnHHYb5Yp_2rUPdF5zaDip7wpsdirHI_gwcVPrVnx5OVX7sN5sH0v5olOvB6lhlq_3tH8L4f_Ih_I--vbGOsYnN246AvMx8BGjB3rLXnW38UK3Kv0JxhnL3evbCmOTKHnYQnAPMDby0LP_J1vGGTSKJB4saPBtfth2jWZCp_D33p2wPA9m3rIJ1GioOjoX8aVnl2McRmhcN0ee-3khHAkTH9Sw5Mselb8HbNlXC-5exUaPJUbLnSf3wU6xV6KyRieN_TsqtfixN6mgR2fSkpi2AA_Pn73zqkACZWSox-925fxgQtg_hoEFlaR4ctPdpWcXQ9V9EN1bMwxcXtV8NT2WDiF7MdEVpabwIUTZHPoFOPvkghuiqSMTiwQzCFgFwMqRR08jXN8Hbo4keOhSlyUhbuIcC0_vdkNI_yh_kUYLpdIlBOwk-amXrN-hdOwqTPkyuRmSAaKUPWgVhktIsP_WOlCXBncp4asz71pEvRywDGh_h9ou449w69BkNSjl7l-ODWpERmlmSTxjEkb3GGqjvn7S-DjtWWD1w_Xac7yZmMcNlenRvfma_bkN_2S4XW7zKnTl7H4gcmDtlt2igFE2Rdcg18vvfDs6vZJIAhmUZ_wo_-ki7qRtKsV-NZXEhxNCPtPbyxuVAdzRL7HWzn8ZpF3eK-2k87_zuS9aU9ofyaMoWiEXplANKhTzbhPz0Do_-QxjH9qHlnJot70a7mOr2kGcV9pRM_frc9CgYJC11tSxs6Mwoi3BKJkqZ7PlpCzTwyI9Fb0sBI3v-TvJO47RzrytQVLmvZdtB_divnOJkV6CNET7k5gTn2ICf7eQb_8YapYc_2eH8DW1YPqCN09n8C5uGq8lkpYedIdlXTsFHrVqUEluhRuaLZSegaV1oMdbzvS90L5WrpSKP-YPBc4D_OTgGXjA7Jha06Ccb_vGhacNAfFznQ7SJXS-Pp2ufPn2ymczEn4LHRbDw-f7upsbuw9uLEMWNSvzZsulGa4DjUZLuP2oEHwtbNu_qq8M1bYNyUzAAD4z9_lTWLQrG6ErWZVmxc1R-Qq-_71PIB1v_zizOFA2EeBYuNCXK_B0J4Hw=w2052-h1199',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Latest Technology Trends Every Logistics Industry Worker Should Know',
  date: '2023-09-13',
  tag1: '#LogisticsCenter',
  tag2: '#GlobalLogistics',
  tag3: '#SCM',
  tag4: '#InventoryManagement',

  text: [
    {
      head: '',
      subHead: '',
      desc: 'The logistics industry plays a vital role in optimizing time and resources while enhancing customer service. However, to maintain competitiveness in today\'s logistics industry, it is crucial to actively adopt the latest technologies. As always, new innovations are accompanied by new technologies. One such technology that has gained significant attention in recent years is Real-Time Location System (RTLS) technology. RTLS technology is bringing innovative changes to various industries, especially the logistics sector. Let\'s explore how this technology can revolutionize and drive growth in the logistics industry.',
      dot: ''
    },
    {
      head: 'Concept of RTLS Technology',
      subHead: '',
      desc: 'RTLS stands for Real-Time Location System, which refers to a system that tracks and manages the real-time location of objects, equipment, or personnel by combining various technologies such as wireless communication, sensors, and data analysis. RTLS technology utilizes technologies such as RFID (Radio-Frequency Identification), GPS (Global Positioning System), and UWB (Ultra-Wideband) to collect location information and transmit it to a central system for real-time monitoring. So, how can this technology be applied to logistics facilities and what are its benefits?',
      dot: ''
    },
    {
      head: 'Utilization of RTLS in the Logistics Industry',
      subHead: '1. Inventory Management and Maintenance',
      desc: 'RTLS technology has brought innovative changes to inventory management in the logistics industry. It moves away from traditional manual inventory management methods and allows real-time tracking of the location and status of goods and equipment. This helps prevent issues like inventory shortages or overstocking and leads to cost savings in logistics.',
      dot: ''
    },
    {
      head: '',
      subHead: '2. Automated Workflow Processes',
      desc: 'RTLS enables the automation of logistics workflow processes. For example, on an automotive assembly line, it can track the location and movement of parts, allowing robots to automatically fetch and use components. This enhances operational efficiency and minimizes errors.',
      dot: ''
    },
    {
      head: '',
      subHead: '3. Transportation and Delivery Management',
      desc: 'RTLS technology plays a crucial role in transportation and delivery processes. By tracking the real-time location of goods, it becomes easier to optimize transportation routes and manage delivery schedules. Additionally, monitoring the condition of goods helps prevent damage and ensures faster and more accurate deliveries to customers.',
      dot: ''
    },
    {
      head: '',
      subHead: '4. Safety Management',
      desc: 'Real-time tracking is not limited to assets. It also extends to tracking workers and vehicles on-site, providing real-time alerts in case of potential risks, such as approaching hazardous zones or collision risks.',
      dot: ''
    },
    {
      head: 'Advantages and Value of RTLS',
      subHead: '1. Real-Time Monitoring',
      desc: 'RTLS allows real-time monitoring of the location and status of goods, equipment, or workers. This enables rapid response to issues and optimization of processes.',
      dot: ''
    },
    {
      head: '',
      subHead: '2. Accurate Data Collection',
      desc: 'RTLS collects precise location data, minimizing errors in tasks such as inventory management, transportation, and delivery, leading to improved logistics efficiency.',
      dot: ''
    },
    {
      head: '',
      subHead: '3. Cost Savings',
      desc: 'By utilizing RTLS, businesses can efficiently manage personnel and resources while reducing costs associated with inventory management and transportation processes.',
      dot: ''
    },
    {
      head: 'The Future of RTLS',
      subHead: '',
      desc: 'RTLS technology is expected to continue evolving and bringing innovation to the logistics industry. Through integration with machine learning and artificial intelligence, more sophisticated predictive and optimization capabilities are anticipated. Furthermore, advancements in security and personal data protection aspects are expected. Workers in the logistics industry should monitor these technology trends actively and adopt them to maintain competitiveness and prepare for the future.',
      dot: ''
    },
    {
      head: 'Conclusion',
      subHead: '',
      desc: 'RTLS technology offers innovative possibilities for workers in the logistics industry, streamlining logistics processes and reducing costs. This technology is expected to play a significant role in shaping the future of the logistics industry. Therefore, it is essential to actively embrace and implement RTLS technology. Let\'s contribute to the growth and development of the logistics industry by effectively utilizing RTLS technology.',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: 'A product that helps real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution: [
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      },
      {
        img: logisticsSafety.thumb,
        data: 'logisticsSafety',
        link: '/en/enterprise/logistics/safety'
      },
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/en/enterprise/manufacturing/asset'
      }
    ]
  }
}
