export default {
  visual: {
    
subtitle: '私たちのビジョン',
title: 'リアルな <br/> 仮想世界を実現する。',
description: 'Orbroは、世界中に存在する約200ゼタバイトのデータを目で見るために作られました。持続可能なデータエコシステムのために、私たちは地球の情報をOrbroに複製し、3Dで形成されたオブジェクトを通じて革新的なデータの接続を作り出しています。 <br/><br/> Orbroでは、専用製品から収集された位置情報とオンラインに散らばる情報がリアルタイムで結びつき、2万個以上のオブジェクトが動いています。私たちはこのスピードを加速するために、オープンなポリシーを通じて世界中の人々が参加できるエコシステムを構築しています。 <br/><br/> 自分だけの世界を作り、デジタルトランスフォーメーションに参加しましょう。',
items: [
    {
      title: '世界初',
      description: 'Webベースの仮想世界プラットフォーム'
      },
      {
      title: '世界最大',
      description: 'リアルとバーチャルの情報が結びついたプラットフォーム'
      },
      {
      title: '500億以上',
      description: 'アジア最大の分析済み位置情報データ'
      },
      {
      title: '1位',
      description: '産業用メタバースの実績（韓国基準）'
      },
      {
      title: '15万以上',
      description: 'Orbro製品の販売数量'
      },
      {
      title: '50万以上',
      description: '累計サービス利用者数を保有'
      },
      {
      title: '2万以上',
      description: 'リアルタイムオブジェクトに接続された数'
      },
      {
      title: '97.5％',
      description: '世界中のほとんどの建物をアップロード'
      },
      {
      title: '1,000以上',
      description: '累計顧客数を保有'
      },
    ]
  },
  ourPartners: {
    subtitle: 'パートナー',
    title: '多くの企業がOrbroを通じた <br/> デジタルトランスフォーメーションを体験しています。'
  },
  ourTechnology: {
    subtitle: '技術',
    title: 'Orbroは技術を革新します。',
    description: 'Orbroは長年の研究を通じて、位置分析の核心技術を保有しています。',
    policy: '関連特許',
    items: [
      {
        title: '無線信号の安定化',
        description: '2.4GHzの信号は周囲の媒質の影響を受けて信号の利用に難しさがあります。そのため、ビーコンを利用するシステムでは無線信号を安定化させる技術が必須です。Orbroの無線信号処理技術は、約10年間で300以上の現場で検証され、顧客の要件に合わせて迅速にチューニングできるように設計されています。',
        policy: [
        '無線信号ベースの移動式タグの位置を判定するシステム、方法、およびコンピュータ読み取り可能媒体',
        '使用者の危険状況を監視サーバーに通知する移動型ビーコンシステム'
        ]
        },
        {
        title: '指向性アンテナ設計',
        description: 'Bluetoothの信号は周囲の環境に大きく影響されるため、環境に合わせてアンテナを設計することは、無線通信技術の基本となります。非指向性アンテナだけでなく、信号の方向性を識別できる指向性設計が可能です。その結果、アンテナ、製品、信号処理システムを1つのパッケージ化し、より安定かつ効果的なサービスの実現が可能です。',
        policy: [
        'ビーコン信号に基づくドア制御方法およびシステム'
        ]
        },
        {
        title: '信号分割/多重信号処理',
        description: '複数のアンテナを使用して信号をより速く送受信したり、移動端末（またはモバイル）がどの信号に近づいているかを区別することができます。多重信号処理技術を応用することで、ドアの内側と外側、建物への入出などを区別することができるため、新しいサービスシナリオを作成することができます。',
        policy: [
        '無線信号ベースの移動式タグの位置を判定するシステム、方法、およびコンピュータ読み取り可能媒体',
        'セキュリティ機能を伴う店内電子機器の情報提供システム'
        ]
        },
        {
        title: '低消費電力ワイヤレストリガ',
        description: '低消費電力ワイヤレストリガ技術は、低消費電力で長時間送信を行わなければならない端末に対して受信機がトリガ信号を与え、一時的に双方向通信を行う技術を指します。ペアリング(pairing)してデータを送受信するよりも10倍以上高速で、20倍以上の低消費電力で動作します。',
        policy: [
        'ビーコンデバイスの信号を検出する方法およびシステム'
        ]
        },
        {
        title: 'メッシュネットワーク',
        description: 'Orbroのメッシュネットワークは、低消費電力通信を利用してより効果的な結果を生み出します。フラッディングメッシュアルゴリズムと低消費電力ワイヤレストリガ技術が基盤となり、従来のビーコンに比べ最大50倍以下の電力を消費し、3倍以上高速に動作しながら、マルチ情報の接続が可能です。電力供給が順調でない環境で複数のデバイスの情報を定期的に受信する場合に効果的です。',
        policy: [
        'ビーコンシステムでメッシュネットワークを実現する方法',
        'メッシュネットワーク通信技術を活用した安心ベルシステム'
        ]
        },
        {
        title: '非指向性信号の位置測位技術',
        description: 'OrbroのInsightは、設置された各Twintrackerと使用者端末間の相関関係を分析し、各TwintrackerごとのTagの方向ベクトルを推測します。理論的に推測されたベクトルの交差点を使用すると、Tagの方向を知ることができます。しかし、実際の環境では信号の不安定性や物理的な障害物の影響により、交差点が一箇所に集まることはありません。このような環境で2次元の位置を推定するために、2次元の図面上で確率密度を基に位置を推定します。',
        policy: [
        '使用者端末の2次元の位置を推定する方法およびシステム'
        ]
        },
        {
        title: '映像オブジェクトの座標抽出',
        description: 'Object DetectionとDepth Estimationという技術を適用し、映像内の人物を認識し、その人物との距離を推定することができます。これを基に、2次元の図面上で人物の位置を特定するために、カメラの視野角とピクセルを使用して、認識された人物の方向を識別し、それを基に座標を計算します。',
        policy: [
        'カメラ画像を利用してオブジェクトの位置を特定する方法およびシステム'
        ]
        },
        {
        title: '位置分析の後処理アルゴリズム',
        description: 'Object DetectionとDepth Estimationという技術を適用し、映像内の人物を認識し、その人物との距離と角度を利用して座標を推定することができます。この結果を補正するために、同じ空間に人がいなかった場合のカメラが認識する床面との距離と人が存在した場合の距離の比を利用して、認識された人の座標を予測し、測定した座標結果を補完します。',
        policy: [
        'カメラ画像を利用してオブジェクトの位置情報出力を補完する方法およびシステム'
        ]
        },
        {
        title: '制御による機械学習',
        description: '3C強化学習モデルを使用して、ユーザーが温度調節をするとペナルティを与え、一定時間温度調節をしないと報酬を与え、適切な温度を自動制御します。',
        policy: [
        '機械学習モデルを使用したエアコンの自動快適制御方法、システム、およびコンピュータ読み取り可能媒体',
        '空間内の複数のエアコンを制御するシステム、方法、およびコンピュータ読み取り可能媒体'
        ]
        },
        {
        title: 'デジタルツインインターフェース',
        description: 'Orbroは、デジタルツインに特化したインターフェース構造を持っています。3D空間上のオブジェクトをクリックすることで、関連するアプリに簡単にアクセスできます。',
        policy: [
        'オブジェクト中心のアプリ実行インターフェースを提供する方法、それを実行するサービスサーバーおよびコンピュータ読み取り可能媒体',
        'オブジェクトに関連するアプリリストインターフェースを提供する方法、それを実行するサービスサーバーおよびコンピュータ読み取り可能媒体',
        'オブジェクトとアプリ間の関連度を利用したアプリリストインターフェースを提供する方法、それを実行するサービスサーバーおよびコンピュータ読み取り可能媒体'
        ]
        },
    ]
  },
  ourCulture: {
    subtitle: '私たちの文化',
    title: '私たちの働き方',
    description: '私たちの旅は、想像を価値ある現実にすることです。',
    discovery: {
      title: '発見',
      description: '無限の可能性を秘めた世界で機会を見つけます。'
    },
    extension: {
      title: '拡張',
      description: '厳しい没頭を通じて思考を広げます。'
    },
    collaboration: {
      title: '協力',
      description: '緊密な協力を通じて想像を実現します。'
    },
    refine: {
      title: '洗練',
      description: '価値ある成果を創造し、磨き上げます。'
    },
  },
  ourHistory: {
    subtitle: '私たちの歴史',
    title: 'オブロの物語',
    histories: {
      '2022': [
        "創業7年で売上100億ウォン達成",
        "コングテック、世界3大デザイン賞「レッドドット」受賞",
        "デジタルツインベースのメタバースプラットフォーム「Orbro」のリリース",
        "京畿道パンギョオブロシステム導入"
      ],
      '2021': [
        "前年比売上高3倍成長、パンギョオフィス拡張",
        "環境部、友情バイオ、LG電子、アジュ大学と共同主催の「医療廃棄物非対面回収処理ロボット」事業開始",
        "科学技術情報通信部「AI技術実証テストベッド整備」事業受注、事業団運営",
        "韓国道路公社「AIベース建設現場の危険予測システム導入」事業受注"
      ],
      '2020': [
        "世界最高レベルのAI位置分析技術を導入し、リアルタイム位置測位システム(RTLS)「KongService」の本格供給",
        "コングチェック利用施設数前年比10倍以上増加",
        "行政安全部「地域に合わせた災害安全問題解決技術開発支援」事業受注、京畿安心ベル供給"
      ],
      '2019': [
        "コングチェックサービスローンチ(3か月で業界トップ3)、有料利用者1万人突破",
        "ガートナー(Gartner)ベンダーブリーフィング"
      ],
      '2018': [
        "スマートファクトリーキャリブレーションツールの世界販売開始",
        "国内大型マンション建設会社IoT出入管理システム供給開始"
      ],
      '2017': [
        "コンモニタリングサービスリリース、国内高速道路危険地帯全設置(約2,000箇所)",
        "子会社(コンモニタリング)設立"
      ],
    }
  },
  news: {
    subtitle: 'ニュース',
    title: '世界が見るオブロ',
    news: [
      {
        "agency": "毎日経済",
        "createdAt": "2022.6.27",
        "title": "コングテック、世界3大デザイン賞「レッドドット」受賞",
        "link": "https://www.mk.co.kr/news/business/view/2022/06/562542/"
      },
      {
        "agency": "毎日経済",
        "createdAt": "2022.4.19",
        "title": "「現実世界データをメタバースに実現」…コングテック、創業7年で売上100億ウォン",
        "link": "https://www.mk.co.kr/news/business/view/2022/04/349210/"
      },
      {
        "agency": "イートゥデイ",
        "createdAt": "2022.4.18",
        "title": "コングテック、昨年売上100億ウォン突破...パンギョに170億規模の拠点確保",
        "link": "https://www.etoday.co.kr/news/view/2125046"
      },
      {
        "agency": "データネット",
        "createdAt": "2021.10.29",
        "title": "コングテック、SKシルダス・チョンリム建設に最新IoT技術サポート",
        "link": "http://www.datanet.co.kr/news/articleView.html?idxno=165926"
      },
      {
        "agency": "ファイネンシャルニュース",
        "createdAt": "2021.10.14",
        "title": "コングテック、イハッキョン代表「経営に奇跡はない、常に厳しいプロセスがあるだけ」",
        "link": "http://www.jjan.kr/2118721"
      },
      {
        "agency": "ジーディーネットコリア",
        "createdAt": "2021.6.24",
        "title": "コングテック「スマートフォンがあればアパートにスルッと入る」",
        "link": "https://zdnet.co.kr/view/?no=20210624211558"
      },
      {
        "agency": "CCTVニュース",
        "createdAt": "2021.6.24",
        "title": "コングテック、スマートフォンベースのアパート出入りセキュリティソリューション「KongPass」リリース",
        "link": "http://www.cctvnews.co.kr/news/articleView.html?idxno=228019"
      },
      {
        "agency": "リクルートタイムス",
        "createdAt": "2021.5.27",
        "title": "コングテック、『病院医療廃棄物非対面回収処理ロボット』共同開発",
        "link": "https://www.medipana.com/article/view.php?news_idx=276992&sch_menu=1&sch_gubun=5"
      },
      {
        "agency": "データネット",
        "createdAt": "2020.11.16",
        "title": "コングテック、リアルタイム位置測位システム『KongService』本格供給",
        "link": "http://www.datanet.co.kr/news/articleView.html?idxno=152892"
      },
      {
        "agency": "グローバルエコノミック",
        "createdAt": "2020.2.31",
        "title": "勤怠管理ソリューションを提供する『コングテック㈜』…現場需要を反映した『コングチェック3.0』アップデート",
        "link": "https://news.g-enews.com/ko-kr/news/article/news_all/202002211426069349aca1a8c21a_1/article.html?md=20200221142829_U"
      },
      {
        "agency": "市民日報",
        "createdAt": "2020.2.5",
        "title": "コングテック、幼稚園電子出勤事業にビーコン25万台納品",
        "link": "http://www.siminilbo.co.kr/news/newsview.php?ncode=1065603668995186"
      },
      {
        "agency": "時事マガジン",
        "createdAt": "2019.12.19",
        "title": "コングテック、『2019家族親和認証』企業選定",
        "link": "https://www.sisamagazine.co.kr/news/articleView.html?idxno=307188"
      },
      {
        "agency": "時事ニュース",
        "createdAt": "2019.12.3",
        "title": "コングチェック、『2019韓国ブランド満足度IT部門1位』受賞",
        "link": "http://www.sisa-news.com/news/article.html?no=122374"
      },
      {
        "agency": "マガジン韓経",
        "createdAt": "2019.11.26",
        "title": "[2019韓国ブランド満足度1位] コングチェック、スマートな技術力の勤怠管理システム",
        "link": "https://magazine.hankyung.com/business/article/201911262429b"
      },
      {
        "agency": "時事マガジン",
        "createdAt": "2019.7.30",
        "title": "ビーコンソリューション技術をリードするコングテックイハッキョン代表",
        "link": "https://www.sisamagazine.co.kr/news/articleView.html?idxno=215613"
      },
      {
        "agency": "共感新聞",
        "createdAt": "2018.12.19",
        "title": "カードタグ・指紋認証不要で可能なスマート勤怠管理プログラム『コングチェック』リリース",
        "link": "https://www.gokorea.kr/news/articleView.html?idxno=62896"
      },
      {
        "agency": "東亜ドットコム",
        "createdAt": "2018.7.27",
        "title": "コングテック(KongTech)、ビーコンベースの技術『幼稚園通学車安全管理システム』9月リリース予定",
        "link": "https://www.donga.com/news/article/all/20180727/91250851/2"
      },
      {
        "agency": "エネルギー経済新聞",
        "createdAt": "2017.4.21",
        "title": "サムスンARTIK協力会社『コングテック』、ARTIK5 Standard EVAL開発ボードリリース",
        "link": "https://www.ekn.kr/web/view.php?key=282853"
      },
      {
        "agency": "データネット",
        "createdAt": "2017.4.18",
        "title": "IT企業『コングテック』パンギョに移転…成長のシグナル",
        "link": "http://www.datanet.co.kr/news/articleView.html?idxno=110048"
      }
    ]
  }

}