

export default {


    //////// 솔루션 컨텐츠 /////////


    constructionSafety: {
        tag: '解决方案',
        title: '施工工作人员安全管理',
        desc: '工作人员的安全是最重要的。在高风险环境中不保障工作人员的安全，不仅会影响生产力，还可能导致法律责任。',
        link: '/cn/enterprise/construction/safety'
    },

    constructionAsset: {
        tag: '解决方案',
        title: '建筑材料定位与管理',
        desc: '准确的材料管理最终将提高生产力。材料管理是建筑现场重要的一部分，可以减少资源浪费并保持质量，甚至会对安全产生影响。',
        link: '/cn/enterprise/construction/asset'
    },

    constructionEquipment: {
        tag: '解决方案',
        title: '重型设备管理',
        desc: '在建筑现场，重型设备是关键要素。因此，适当的维护和事故预防是必要的。正确管理重型设备将有助于维持安全并节省成本，提高现场生产力。',
        link: '/cn/enterprise/construction/equipment'
    },


    logisticsAsset: {
        tag: '解决方案',
        title: '库存定位与管理',
        desc: '库存管理是支持供应链效率和顺畅运作的重要部分。实时跟踪库存的位置可以有效监控库存水平并优化库存移动。',
        link: '/cn/enterprise/logistics/asset'
    },

    logisticsSafety: {
        tag: '解决方案',
        title: '仓库工作人员安全管理',
        desc: '工作人员的安全是最重要的。不仅会影响生产力，还可能导致法律责任。此外，安全的物流现场对企业形象和利益相关者信任的建立起着重要作用。',
        link: '/cn/enterprise/logistics/safety'
    },



    officeAsset: {
        tag: '解决方案',
        title: '资产管理',
        desc: '不熟练的资产管理会导致时间和金钱的损失。通过清晰迅速地管理资产，可以保证顺利的业务运营。',
        link: '/cn/enterprise/office/asset'
    },


    manufacturingProcess: {
        tag: '解决方案',
        title: '实时生产过程管理',
        desc: '可以一目了然地了解制造设施的运行流程。通过准确客观的数据，可以改进生产线，提高效率。这是制造业的核心。',
        link: '/cn/enterprise/manufacturing/process'
    },

    manufacturingAsset: {
        tag: '解决方案',
        title: '实时库存管理',
        desc: '制造设施拥有大量原材料、半成品等库存。正确管理这些库存在时间和经济方面都非常重要。这是影响制造业效率的重要因素。',
        link: '/cn/enterprise/manufacturing/asset'
    },



    medicalPatient: {
        tag: '解决方案',
        title: '患者定位',
        desc: '医疗设施中存在需要特别管理的患者。无需直接24小时监控，可以实时追踪患者的位置。',
        link: '/cn/enterprise/medical/patient'
    },

    medicalWaste: {
        tag: '解决方案',
        title: '医疗废弃物管理',
        desc: '通过实时跟踪医疗废弃物的状态和位置，可以预防医疗事故，提供安全的医疗服务。这是必不可少的工作，遵守法规有助于保护人体健康和资源。',
        link: '/cn/enterprise/medical/waste'
    },

    retailExhibition: {
        tag: '解决方案',
        title: '展览厅访客管理与分析',
        desc: '通过分析展览厅访客的移动路径并轻松管理资产，可以实现高效的设施运营。准确客观的数据有助于推动新业务增长。',
        link: '/cn/enterprise/retail/exhibition'
    },


    //////// 블로그 컨텐츠 /////////


    blogConstruction: {
        tag: '博客',
        title: 'RTLS 带来的建筑行业未来',
        desc: '建筑领域一直在寻求提高效率、产量和稳定性的方法，就像其他领域一样。尤其领先的企业为了解决这些问题，开始引入各种先进技术。',
        link: '/cn/blog/rtls'
    },

    blogRTLS: {
        tag: '博客',
        title: '建筑工地中的 RTLS',
        desc: '本文将介绍使用 RTLS 系统来定位和追踪建筑物内部位置的方法。当前许多建筑公司开始实施实时追踪资产和移动对象的系统，以优化建筑工地活动并提高建筑工人的效率。',
        link: '/cn/blog/rtls'
    },

    blogActiveRfid: {
        tag: '博客',
        title: '基于 Active RFID 技术的 RTLS',
        desc: 'Active RFID 是一种通过无线通信发送设备间信息的形式。Active RFID 标签拥有电源源，因此可以通过长距离传输信号。特别适合实时跟踪资产的追踪。',
        link: '/cn/blog/activerfid'
    },


    blogForklift: {
        tag: '博客',
        title: '改善叉车司机安全的 RTLS 解决方案',
        desc: '叉车每天都需要检查操作状态是否正常。检查清单包括停车刹车、灯光、液压油位等。如果发现问题，必须立即进行维修。建议每月至少由合格技术人员进行更彻底的检查。',
        link: '/cn/blog/forklift'
    },


    blogOffice: {
        tag: '博客',
        title: '打造智能办公室的方法',
        desc: '办公楼内的室内定位可能带来很多困扰。随着办公室、大厅和楼层数量的增加，访客可能很容易迷路，浪费很多时间寻找建筑物。在这种情况下，企业需要使用更智能的地图。先进的技术确保建筑相关导航的便利，提高客户和访客的满意度。',
        link: '/cn/blog/office'
    },


    blogPatient: {
        tag: '博客',
        title: '改善医疗设施患者流程的方法',
        desc: '拥挤是许多医疗设施，特别是急诊室的问题。这种情况可能由交通阻塞、员工短缺以及不正确的患者管理系统引起。在严重的情况下，患者可能会丧命。此外，在像冠状病毒流行期间或类似灾难期间，患者会更快地聚集在一起，使情况更加恶化。',
        link: '/cn/blog/patient'
    },


    blogElder: {
        tag: '博客',
        title: '为何在养老设施中使用 RTLS',
        desc: '本文将介绍在养老设施中使用 RTLS 技术。这可以节省成本，提高居民满意度，改善老年护理服务质量。让我们看看如何在养老设施中应用 RTLS 技术以及它能带来哪些好处。',
        link: '/cn/blog/elder'
    },



    blogFactory: {
        tag: '博客',
        title: '欢迎来到未来的工厂：工业 4.0 中实时追踪的角色',
        desc: '工厂的未来将经历创新性变革。这将很快成为现实。其中的背景是基于物联网、人工智能、大数据等先进技术。RTLS 技术是推动创新的关键要素之一。',
        link: '/cn/blog/factory'
    },



    blogAccess: {
        tag: '博客',
        title: '全新出入概念',
        desc: '很久以前，门由人来保护，然后近年来被门锁所取代。然而，出入系统的范式正在再次改变。',
        link: '/cn/blog/access'
    },


    blogMall: {
        tag: '博客',
        title: '提升大型购物中心体验的 RTLS',
        desc: '在大型购物中心里，找到所需的位置并不容易。即使有标志和导向屏幕，它们也不总是清晰易懂，并且不能随时追随我。这可能导致购物压力增加，为客户提供不好的体验。',
        link: '/cn/blog/access'
    },



    blogMedical: {
        tag: '博客',
        title: 'RTLS 在医疗行业中的应用',
        desc: '在经历了新冠疫情后，医疗行业对先进的 IT 技术变得更加重视。特别是追踪和管理患者是技术的关键。被称为实时定位系统（RTLS）的这种技术在未来将在医疗行业的各个方面得到广泛应用，并为提供比现在更优质的服务提供帮助。',
        link: '/cn/blog/access'
    },





}