import { accessAuto1, accessAutoThumb, accessKeyM1, accessKeyThumb, accessPro1, accesscontrollerThumb, apartThumb, deadbolt1, deadboltM1, deadboltThumb, emlockM1, emlockThumb } from '@assets/images/main/access/product';
import {
    access1off, access2off, access3off, access4off,
    access1on, access2on, access3on, access4on,
    accessKey1, accessKey2, accessKey3, accessKey4,
    accessOutlook,
    IconAccess, IconAccessInv, IconBattery, IconBluetooth,
    IconBox, IconChevRight, IconComputer, IconConsole,
    IconConsoleInv, IconCard, IconFacetime, IconGuarantee,
    IconQR, IconRTLS, IconRTLSInv, IconTemp, IconUsbc,
    IconWarn, IconWaterproof, IconCamera, IconZoom, IconTopology
} from '@assets/images/main/index';
import { bell1, esl1, idCard1, mCradle1, mCradleThumb, mTagThumb, switch1 } from '@assets/images/main/iot/product';
import { assetTag, assetTag1, assetTracking1, assetTracking2, tagUwb1, twinTracker1, twinTracker2, twinTracker3, twinTrackerBracket } from '@assets/images/main/rtls/product';
import { IconAtomGray, IconCalc, IconChainLinkGray, IconEyeline, IconInfo, IconMail, IconMonitorLineGray, IconPhone, IconPower, IconRoom, IconRulerGray, IconSignal, IconThumbUp, IconToolsGray, IconTvocGray, IconWifi } from '@assets/images/icon';
import { accessKeyThumbnail, accessproThumbnail, deadboltThumbnail, emlockThumbnail, eslThumbnail, orbroServerThumbnail, safetyBellThumbnail, switchThumbnail, twinTrackerThumbnail } from '@assets/images/main/thumbnail';
import { ipThumb, ptzThumb } from '@assets/images/main/camera';
import { loraGateway1, loraSensor1 } from '@assets/images/main/sensor';



export default {
    purchaseDesc: {
        mobile: "This product is equipped with a built-in battery, allowing for easy and fast mobile access control by attaching it next to the entrance door without the need for power installation.",
        auto: "This product is specialized for automatic door access, enabling contactless entry through the installation of a mobile app and replacement of the existing automatic door sensor.",
        face: "Access Pro is a product that controls and calls the entrance door of a building. With this product, users can make real-time video calls through a mobile app when someone is called, and remotely control the entrance door. It also allows for NFC card and password unlocking, making it the best replacement for existing building entrance control systems.",
        accessory: "",
        asset: "Attach a BLE tag device to the subject you want to monitor or track as an asset, enabling real-time location tracking.",
        worker: "Attach a UWB tag device to the subject you want to monitor or track as an asset, enabling real-time location tracking.",

        outdoor: 'Track the location of vehicles in the parking lot through PTZ outdoor cameras.',
        indoor: 'Analyzing the movement paths of people indoors through cameras.',
        monitoring: "Safety Bell is a product that can be easily attached and used anywhere with Wi-Fi, without any location constraints.",

        energy: "Smart Switch is a product that can easily replace analog switches with digital switches.",
        display: "ESL is a display device based on a beacon system. It uses E-ink Display, enabling display usage with very low power consumption.",
        cradle: "You can wirelessly manage your smartphone without the inconvenience of a cable lock.",
        idCard: 'The employee ID card is made in the form of a necklace, so it can be used compatibly in both industrial and service areas. It can be linked with RTLS systems and Bluetooth systems.',

        loraGateway: 'ALESS_GW equipment is a smart wireless communication concentrator optimized for industrial environments. It serves as a smart wireless gateway that transmits sensor data to network servers.',
        loraSensor: 'This product interfaces with various sensors such as temperature sensors, pressure sensors, leak sensors, humidity sensors, and more needed in the field. It measures sensor data and transmits it using wireless communication.',
        airQuality: 'A high-precision air quality monitor with built-in sensors for fine dust, carbon dioxide, total volatile organic compounds, temperature, and humidity. It displays real-time measurements of each sensor value on the display and can be integrated with server systems for data monitoring.',
        coMeasure: 'In addition to carbon monoxide, it also has built-in sensors for measuring fine dust (PM1.0/PM2.5/PM4.0/PM10), temperature, and humidity in real-time.',

        beaconIndustry: 'Waterproof and dustproof, this beacon terminal is validated for industrial use and can be used for up to 3 years.',
        beaconTag: 'A small tag beacon terminal that can be attached anywhere.',
        assetTag: 'Ultra-small Bluetooth tag terminal for asset management',

    },

    purchase: {
        mobile: {
            accesscontroller: {
                icon: accessKey1,
                title: "Access Controller",
                serial: "KB-BK02",
                price: "50,000",
                note: [
                    { text: 'Support for non-contact entry function using mobile', icon: IconInfo },
                    { text: 'Latest version of Bluetooth', icon: IconBluetooth },
                    { text: 'Access control within 10m', icon: IconWarn },
                    { text: 'Waterproof and dustproof of IP65 grade (-10 ~ 40°C)', icon: IconWaterproof },
                    { text: 'Up to 3 years of battery usage', icon: IconBattery },
                    { text: '1 year warranty period', icon: IconGuarantee },

                ],
                parts: [
                    {
                        title: "EM Lock",
                        price: "150,000",
                        img: emlockThumb,
                    },
                    {
                        title: "Deadbolt Lock",
                        price: "150,000",
                        img: deadboltThumb,
                    },
                ],
                mark: "2",


                mainProduct: [
                    {
                        img: accesscontrollerThumb,
                        title: 'Access Controller',
                        price: '50,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        auto: {
            accessauto: {
                icon: accessKey1,
                title: "Access Auto",
                serial: "OAC-AUBT5091",
                price: "150,000",
                note: [
                    { text: 'Support for non-contact entry function using mobile', icon: IconInfo },
                    { text: "Latest version of Bluetooth", icon: IconBluetooth },
                    { text: "Precise access control within 2m", icon: IconWarn },
                    { text: "0 ~ 50°C operating temperature", icon: IconTemp },
                    { text: "1 year warranty", icon: IconGuarantee },
                ],
                parts: [
                    {
                        title: "EM Lock",
                        price: "150,000",
                        img: emlockThumb,
                    },
                    {
                        title: "Deadbolt Lock",
                        price: "150,000",
                        img: deadboltThumb,
                    },
                    {
                        title: "Indoor Auto Door Sensor",
                        price: "99,000",
                        img: accessAutoThumb,
                    },
                ],
                mark: "",

                mainProduct: [
                    {
                        img: accessAutoThumb,
                        title: 'Access Auto',
                        price: '150,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },

            apart: {
                icon: accessOutlook,
                title: "Apartment Package",
                serial: "Access Auto 20 + Server 1",
                price: "5,000,000",
                note: [
                    { text: 'Support for non-contact entry function using mobile', icon: IconInfo },
                    { text: 'ORBRO OS included in ORBRO Server', icon: IconComputer },
                    { text: '1 year warranty period', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'EM Lock',
                        price: '150,000',
                        img: emlockThumb,
                    },
                    {
                        title: 'Deadbolt Lock',
                        price: '150,000',
                        img: deadboltThumb,
                    },
                    {
                        title: 'Indoor Auto Door Sensor',
                        price: '99,000',
                        img: accessAutoThumb,
                    }

                ],

                mark: "",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '2,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: accessAutoThumb,
                        title: 'Access Auto',
                        price: '150,000',
                        min: 20,
                        max: 999,
                    }
                ]
            },


            cloud: {
                icon: accessOutlook,
                title: "Access Auto (Cloud)",
                serial: "OAC-AUBT5091",
                price: "29,000",
                priceSub: '/month',
                note: [
                    { text: 'Support for non-contact entry function using mobile', icon: IconInfo },
                    { text: "Latest version of Bluetooth", icon: IconBluetooth },
                    { text: "Precise access control within 2m", icon: IconWarn },
                    { text: "0 ~ 50°C operating temperature", icon: IconTemp },
                    { text: "1 year warranty", icon: IconGuarantee },
                ],
                parts: [],
                mark: "1",

                mainProduct: [
                    {
                        img: accessAutoThumb,
                        title: 'Access Auto (Cloud)',
                        price: '29,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },


        face: {
            accesspro: {
                title: "Access Pro",
                serial: "KSB-SL02",
                price: "1,200,000",
                note: [
                    { text: 'Support for non-contact entry and video call function using mobile', icon: IconInfo },
                    { text: "Latest version of Bluetooth", icon: IconBluetooth },
                    { text: "NFC card access", icon: IconCard },
                    { text: "Visitor video call", icon: IconFacetime },
                    { text: "QR code access", icon: IconQR },
                    { text: "0 ~ 40°C operating temperature", icon: IconTemp },
                    { text: "1 year warranty", icon: IconGuarantee },
                ],
                parts: [
                    {
                        title: "EM Lock",
                        price: "150,000",
                        img: emlockThumb,
                    },
                    {
                        title: "Deadbolt Lock",
                        price: "150,000",
                        img: deadboltThumb,
                    },
                    {
                        title: "Indoor Auto Door Sensor",
                        price: "99,000",
                        img: accessAutoThumb,
                    },
                ],
                mark: "2",

                mainProduct: [
                    {
                        img: accessproThumbnail,
                        title: 'Access Pro',
                        price: '1,200,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },

        accessory: {
            accesskey: {
                title: "Access Key",
                serial: "KSB-AKC01",
                price: "30,000",
                note: [
                    { text: 'Support for non-contact entry function using beacon key', icon: IconInfo },
                    { text: "Latest version of Bluetooth", icon: IconBluetooth },
                    { text: "USB-C charging", icon: IconUsbc },
                    { text: "1 year warranty", icon: IconGuarantee },
                ],
                parts: [],
                mark: " ",


                mainProduct: [
                    {
                        img: accessKeyThumbnail,
                        title: 'Access Key',
                        price: '30,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },


            emlock: {
                title: "EM Lock",
                serial: "EM-Lock",
                price: "150,000",
                note: [
                    { text: "Electric lock mainly used for one-way access control on fire doors or wooden doors, utilizing magnetic force to fix the entrance door.", icon: "" },
                    { text: "1 year warranty", icon: IconGuarantee },
                ],
                parts: [],
                mark: " ",

                mainProduct: [
                    {
                        img: emlockThumbnail,
                        title: 'EM Lock',
                        price: '150,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },




            deadbolt: {
                title: "Deadbolt Lock",
                serial: "Deadbolt Lock",
                price: "150,000",
                note: [
                    { text: "An automatic locking deadbolt lock that automatically locks when the door is closed. It can be used for entrances, main doors, etc., and features automatic locking.", icon: "" },
                    { text: "1 year warranty", icon: IconGuarantee },
                ],
                parts: [],
                mark: " ",

                mainProduct: [
                    {
                        img: deadboltThumbnail,
                        title: 'Deadbolt Lock',
                        price: '150,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },

            autoDoorSensor: {
                title: 'Indoor Automatic Door Sensor',
                serial: 'Automatic Door Sensor',
                price: '99,000',
                note: [
                    { text: 'A high-sensitivity, high-performance product designed to detect the movement of humans or objects and control similar devices for automatic door opening and closing.', icon: '' },
                    { text: '1 year warranty period', icon: IconGuarantee },
                ],
                parts: [],
                mark: '',

                mainProduct: [
                    {
                        img: accessAuto1,
                        title: 'Indoor Automatic Door Sensor',
                        price: '99,000',
                        min: 1,
                        max: 999,
                    },
                ],
            },
        },


        asset: {
            assetTracking: {
                icon: accessKey1,
                title: "Asset Location Tracking Package",
                serial: 'TwinTracker 10 + Server 1',
                price: '9,500,000',
                note: [
                    { text: 'Tracking the location of assets using Bluetooth Tag terminal', icon: IconInfo },
                    { text: 'Per package, Can be installed up to 1,000 square meters', icon: IconBox },
                    { text: 'ORBRO OS included in ORBBRO Server', icon: IconComputer },
                    { text: '1 year warranty period', icon: IconGuarantee },
                ],
                parts: [
                    {
                        title: "Asset Tag (BLE)",
                        price: "30,000",
                        img: assetTracking2,
                    },
                    {
                        title: "TwinTracker Bracket",
                        price: "100,000",
                        img: twinTrackerBracket,
                    },
                ],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTracker',
                        price: '450,000',
                        min: 10,
                        max: 999,
                    }
                ]
            },
            twinTracker: {
                icon: twinTrackerThumbnail,
                title: "TwinTracker",
                serial: 'OTR-TTBT0001',
                price: '450,000',
                note: [
                    { text: 'Location Tracking Using Bluetooth Tag Terminal', icon: IconInfo },
                    { text: 'Ethernet and Wi-Fi Network Connectivity', icon: IconTopology },
                    { text: 'Power over Ethernet (PoE) and USB Type-C Power Supply', icon: IconPower },
                    { text: 'Up to 10m Accuracy', icon: IconRulerGray },
                    { text: '1-Year Warranty Period', icon: IconGuarantee },
                ],
                parts: [
                    {
                        title: "Asset Tag (BLE)",
                        price: "30,000",
                        img: assetTracking2,
                    },
                    {
                        title: "TwinTracker Bracket",
                        price: "150,000",
                        img: twinTrackerBracket,
                    },
                ],
                mark: "2",

                mainProduct: [
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTracker',
                        price: '450,000',
                        min: 10,
                        max: 999,
                    }
                ]
            },
        },


        worker: {
            workerTracking: {
                icon: accessKey1,
                title: "Worker Location Tracking Package",
                serial: 'TwinTrackerPro 10 + Server 1',
                price: "11,000,000",
                note: [
                    { text: 'High-precision location tracking using UWB Tag terminal', icon: IconInfo },
                    { text: 'Accuracy within 10cm', icon: IconWarn },
                    { text: 'Per package, Can be installed up to 600 square meters indoors', icon: IconRoom },
                    { text: 'Per package, Can be installed up to 1,000 square meters outdoors', icon: IconRoom },
                    { text: 'ORBRO OS included in ORBBRO Server', icon: IconComputer },
                    { text: '1 year warranty period', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: "Tag (UWB)",
                        price: "60,000",
                        img: tagUwb1,
                    },
                    {
                        title: "TwinTracker Bracket",
                        price: "100,000",
                        img: twinTrackerBracket,
                    },
                ],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 10,
                        max: 999,
                    }
                ]
            },

            twinTrackerPro: {
                icon: twinTrackerThumbnail,
                title: 'TwinTracker Pro',
                serial: 'OTR-TTUW0001',
                price: '600,000',

                note: [
                    { text: 'Location Tracking Using UWB Tag Terminal', icon: IconInfo },
                    { text: 'Ethernet and Wi-Fi Network Connectivity', icon: IconTopology },
                    { text: 'Power over Ethernet (PoE) and USB Type-C Power Supply', icon: IconPower },
                    { text: 'Up to 10cm Accuracy', icon: IconRulerGray },
                    { text: '1-Year Warranty Period', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'Tag(UWB)',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },

                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTracker Pro',
                        price: '600,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },

        outdoor: {
            carTracking: {
                icon: accessKey1,
                title: 'Vehicle Location Tracking Package',
                serial: 'Server 1 + NVR 1 + PTZ Camera 2',
                price: '11,000,000',
                note: [
                    { text: 'Parking lot vehicle tracking with PTZ camera', icon: IconInfo },
                    { text: 'IP67 waterproof and dustproof rating', icon: IconWaterproof },
                    { text: 'High-quality images with 4MP resolution', icon: IconCamera },
                    { text: 'Capture with a maximum IR distance of 400m', icon: IconWarn },
                    { text: '42x optical and 16x digital zoom', icon: IconZoom },
                    { text: '1-year warranty period', icon: IconGuarantee }
                ],
                parts: [],
                options: [],
                mark: "2",
                mainProduct: [
                    { img: orbroServerThumbnail, title: 'ORBRO Server + NVR', price: '7,000,000', min: 1, max: 999 },
                    { img: twinTrackerThumbnail, title: 'PTZ Camera', price: '4,000,000', min: 2, max: 4 }
                ]
            },
            ptz: {
                icon: ptzThumb,
                title: 'PTZ Camera',
                serial: 'OTR-CAPT0001',
                price: '2,000,000',

                note: [
                    { text: 'Tracking the location of vehicles in the parking lot through PTZ cameras.', icon: IconInfo },
                    { text: 'IP67 waterproof and dustproof rating', icon: IconWaterproof },
                    { text: 'High-quality images with 4MP resolution', icon: IconCamera },
                    { text: 'Capture with a maximum IR distance of 400m', icon: IconWarn },
                    { text: '42x optical and 16x digital zoom', icon: IconZoom },
                    { text: '1-year warranty period', icon: IconGuarantee }

                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: ptzThumb,
                        title: 'PTZ Camera',
                        price: '2,000,000',
                        min: 1,
                        max: 999,

                    }
                ]

            },
        },
        indoor: {
            peopleTracking: {
                icon: accessKey1,
                title: 'People Tracking and Analysis Package',
                serial: 'Server 1 + NVR 1 + IP Camera 4',
                price: '8,600,000',
                note: [
                    { text: 'Indoor person tracking with IP cameras', icon: IconInfo },
                    { text: 'IP67 waterproof and dustproof rating', icon: IconWaterproof },
                    { text: '2MP resolution', icon: IconCamera },
                    { text: '1-year warranty period', icon: IconGuarantee }
                ],
                parts: [],
                options: [],
                mark: "0",
                mainProduct: [
                    { img: orbroServerThumbnail, title: 'ORBRO Server + NVR', price: '7,000,000', min: 1, max: 999 },
                    { img: twinTrackerThumbnail, title: 'IP Camera', price: '1,600,000', min: 4, max: 8 }
                ]
            },
            ip: {
                icon: ipThumb,
                title: 'IP Camera',
                serial: 'OTR-CAIP0001',
                price: '1,600,000',

                note: [
                    { text: 'Tracking the location and detecting abnormal behavior with indoor IP cameras.', icon: IconInfo },
                    { text: 'IP67 waterproof and dustproof rating', icon: IconWaterproof },
                    { text: '2MP resolution', icon: IconCamera },
                    { text: '1-year warranty period', icon: IconGuarantee }

                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: ipThumb,
                        title: 'IP Camera',
                        price: '1,600,000',
                        min: 1,
                        max: 999,

                    }
                ]

            },
        },


        monitoring: {
            safetyBell: {
                icon: bell1,
                title: "Safety Bell",
                serial: "KB-EW02",
                price: "100,000",
                note: [
                    { text: 'If the button is pressed in an emergency, it reports to the administrator by text', icon: IconInfo },
                    { text: 'Latest version of Bluetooth', icon: IconBluetooth },
                    { text: 'Reporting through Wi-Fi connection', icon: IconWifi },
                    { text: 'Transmission of 100 report texts', icon: IconMail },
                    { text: 'Uses AAA batteries', icon: IconBattery },
                    { text: '1 year warranty period', icon: IconGuarantee },

                ],
                parts: [],
                mark: "",

                mainProduct: [
                    {
                        img: safetyBellThumbnail,
                        title: 'Safety Bell',
                        price: '100,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },


        energy: {
            smartSwitch: {
                icon: switch1,
                title: "Smart Switch",
                serial: "KSB-SS01",
                price: "80,000",
                note: [
                    { text: 'Smart switch that can be controlled remotely', icon: IconInfo },
                    { text: "Bluetooth 5.0", icon: IconBluetooth },
                    { text: "Mobile app for lighting control", icon: IconPhone },
                    { text: "Touch control", icon: IconThumbUp },
                    { text: "1 year warranty", icon: IconGuarantee },
                ],
                parts: [],
                mark: "",

                mainProduct: [
                    {
                        img: switchThumbnail,
                        title: 'Smart Switch',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },


        display: {
            esl: {
                icon: esl1,
                title: "ESL",
                serial: "KI-MDE1",
                price: "80,000",
                note: [
                    { text: 'Information display using ink display', icon: IconInfo },
                    { text: 'Bluetooth 5.0', icon: IconBluetooth },
                    { text: 'USB-C power supply', icon: IconPower },
                    { text: '230dpi resolution', icon: IconEyeline },
                    { text: 'NFC tag', icon: IconSignal },
                    { text: '1 year warranty period', icon: IconGuarantee },
                ],
                parts: [],
                mark: "",

                mainProduct: [
                    {
                        img: eslThumbnail,
                        title: 'ESL',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },


        cradle: {
            mTag: {
                icon: mTagThumb,
                title: 'Mobile Tag',
                serial: 'KI-MT02',
                price: '80,000',
                note: [
                    { text: 'Smartphone wireless security device', icon: IconInfo },
                    { text: 'Bluetooth 5.2', icon: IconBluetooth },
                    { text: 'Compatible with iPhone and Android', icon: IconPhone },
                    { text: '1-year warranty period', icon: IconGuarantee },

                ],

                options: [
                    { select: 'Lightning', imgIndex: 0 },
                    { select: 'USB-C', imgIndex: 1 },
                ],

                parts: [],
                mark: "",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: 'Mobile Tag',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

            mCradle: {
                icon: mCradle1,
                title: 'Mobile Cradle',
                serial: 'KI-MC02',
                price: '80,000',

                note: [
                    { text: 'Smartphone wireless security device', icon: IconInfo },
                    { text: 'Bluetooth 5.2', icon: IconBluetooth },
                    { text: 'USB-C charging', icon: IconPhone },
                    { text: '1-year warranty period', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: mCradleThumb,
                        title: 'Mobile Cradle',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },
        idCard: {
            idCard: {
                icon: idCard1,
                title: 'ID02',
                serial: 'KB-ID02',
                price: '100,000',

                note: [
                    { text: 'Bluetooth-based electronic employee card', icon: IconInfo },
                    { text: 'Bluetooth 5.0', icon: IconBluetooth },
                    { text: 'USB-C power supply', icon: IconPower },
                    { text: '1-year warranty period', icon: IconGuarantee },
                ],

                options: [],
                parts: [],
                mark: "",

                mainProduct: [
                    {
                        img: idCard1,
                        title: 'ID02',
                        price: '100,000',
                        min: 1,
                        max: 999,
                    }
                ]

            }
        },

        loraGateway: {
            loraGateway: {
                icon: loraGateway1,
                title: 'LoRa Gateway',
                serial: 'ALESS-GW',
                price: '1,500,000',

                note: [
                    { text: 'Data collection for LoRa sensor terminals', icon: IconInfo },
                    { text: 'IP67-rated waterproof and dustproof', icon: IconWaterproof },
                    { text: 'Operable in an industrial environment at -20 ~ 60°C', icon: IconTemp },
                    { text: '24VDC power supply', icon: IconPower },
                    { text: 'Maximum communication range of 6km', icon: IconWarn },
                    { text: 'Can accommodate up to 150 sensors', icon: IconSignal },
                    { text: 'Option to choose LTE/Wi-Fi communication', icon: IconWifi },
                    { text: '1-year warranty period', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: loraGateway1,
                        title: 'LoRa Gateway',
                        price: '1,500,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        loraSensor: {
            loraSensor: {
                icon: loraSensor1,
                title: 'LoRa Sensor',
                serial: 'ALESS-BA',
                price: '500,000',

                note: [
                    { text: 'Product that can be integrated with Universal Input Sensors, supporting various sensor interfaces', icon: IconInfo },
                    { text: 'IP67-rated waterproof and dustproof', icon: IconWaterproof },
                    { text: 'Operable in an industrial environment at -20 ~ 60°C', icon: IconTemp },
                    { text: 'Battery and external DC24V power supply available', icon: IconPower },
                    { text: 'Maximum communication range of 6km', icon: IconWarn },
                    { text: 'Various input interfaces can be applied', icon: IconChainLinkGray },
                    { text: '1-year warranty period', icon: IconGuarantee },
                ],

                parts: [],
                options: [
                    { select: 'Emergency<br/>Bell', imgIndex: 0 },
                    { select: 'Fire<br/>Detection', imgIndex: 1 },
                    { select: 'Temperature<br/>Humidity', imgIndex: 0 },
                    { select: 'Leak<br/>Detection', imgIndex: 1 },
                    { select: 'Differential<br/>Pressure', imgIndex: 0 },
                    { select: 'Intrusion Detection', imgIndex: 1 },
                ],

                mark: "2",

                mainProduct: [
                    {
                        img: loraSensor1,
                        title: 'LoRa Sensor - Emergency Bell',
                        price: '500,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        airQuality: {
            airQuality: {
                icon: mTagThumb,
                title: 'Air Quality Monitor',
                serial: 'VENTAX',
                price: '800,000',

                note: [
                    { text: 'Industrial air quality monitor', icon: IconInfo },
                    { text: 'Built-in Color LCD display', icon: IconMonitorLineGray },
                    { text: 'Supports RS-485 and LoRa communication', icon: IconSignal },
                    { text: 'Auto Cleaning function', icon: IconToolsGray },
                    { text: 'Certified as Grade 1 fine dust monitor by the Korea Environment Corporation', icon: IconAtomGray },
                    { text: '1-year warranty period', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: 'Air Quality Monitor',
                        price: '800,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        coMeasure: {
            coMeasure: {
                icon: mTagThumb,
                title: 'Carbon Monoxide Monitor',
                serial: 'VENTAX co+',
                price: '1,500,000',

                note: [
                    { text: 'Carbon monoxide and air quality monitor', icon: IconInfo },
                    { text: 'Supports RS-485 and Wi-Fi communication', icon: IconSignal },
                    { text: 'Measures fine dust, carbon monoxide, temperature, and humidity', icon: IconTvocGray },
                    { text: '1-year warranty period', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: "0",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: 'Carbon Monoxide Monitor',
                        price: '1,500,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        beaconIndustry: {
            plutoconPro: {
                icon: mTagThumb,
                title: 'Plutocon Pro',
                serial: 'KB-BK02',
                price: '50,000',

                note: [
                    { text: 'Industrial beacon terminal', icon: IconInfo },
                    { text: 'Latest version of Bluetooth', icon: IconBluetooth },
                    { text: 'IP65-rated waterproof and dustproof (-10 ~ 60°C)', icon: IconWaterproof },
                    { text: 'Up to 3 years of battery life', icon: IconBattery },
                    { text: '1-year warranty period', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: "0",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: 'Plutocon Pro',
                        price: '50,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        beaconTag: {
            orbroTag: {
                icon: mTagThumb,
                title: 'ORBRO Tag',
                serial: 'OAS-OTBT1991',
                price: '30,000',

                note: [
                    { text: 'Compact beacon terminal', icon: IconInfo },
                    { text: 'Latest version of Bluetooth', icon: IconBluetooth },
                    { text: 'IP65-rated waterproof and dustproof (-10 ~ 60°C)', icon: IconWaterproof },
                    { text: 'Up to 2 years of battery life', icon: IconBattery },
                    { text: '1-year warranty period', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: "0",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: 'ORBRO Tag',
                        price: '30,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        assetTag: {
            assetTag: {
                icon: assetTag1,
                title: 'Asset Tag (1,000EA)',
                serial: 'OAS-ABBT1991',
                price: '20,000,000',

                note: [
                    { text: 'Ultra-compact beacon device for asset management', icon: IconInfo },
                    { text: 'The latest version of Bluetooth', icon: IconBluetooth },
                    { text: 'Up to 1-year battery life', icon: IconBattery },
                    { text: '1-year warranty period', icon: IconGuarantee },
                ],

                parts: [],
                options: [],

                mark: "2",

                mainProduct: [
                    {
                        img: assetTag1,
                        title: 'Asset Tag (1,000EA)',
                        price: '20,000,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },

        robot: {
            robotBasic: {
                icon: accessKey1,
                title: 'Basic Delivery Robot Package',
                serial: 'Delivery Robot + TwinTrackerPro 10 + Server 1',
                price: 'Inquire',

                note: [
                    { text: 'Harmonious integration of indoor location tracking system and robot system, quick setup with simple configuration', icon: IconInfo },
                    { text: 'Delivery robot load capacity 20L', icon: IconWaterproof },
                    { text: 'Accuracy within 10cm', icon: IconCamera },
                    { text: 'Indoor installation up to 600 square meters', icon: IconGuarantee },
                    { text: 'Outdoor installation up to 1,000 square meters', icon: IconGuarantee },
                    { text: 'Includes ORBRO OS on ORBRO Server', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'Tag (UWB)',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker Rail Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },
                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 100,
                        max: 300,
                    },
                    {
                        img: ipThumb,
                        title: 'Delivery Robot',
                        price: 'Inquire',
                        min: 1,
                        max: 10,
                    }
                ]
            },

            delivery: {
                icon: accessKey1,
                title: 'Unmanned Loading/Unloading Package',
                serial: 'Delivery Robot + Loading Kiosk + Cobot + TwinTrackerPro 10 + Server 1',
                price: 'Inquire',

                note: [
                    { text: 'Package designed for unmanned loading/unloading of delivery robots', icon: IconInfo },
                    { text: 'Delivery robot load capacity 20L', icon: IconWaterproof },
                    { text: 'Collaborative robot max load 12kg', icon: IconCamera },
                    { text: 'Accuracy within 10cm', icon: IconGuarantee },
                    { text: 'Indoor installation up to 600 square meters', icon: IconGuarantee },
                    { text: 'Outdoor installation up to 1,000 square meters', icon: IconGuarantee },
                    { text: 'Includes ORBRO OS on ORBRO Server', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'Tag (UWB)',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker Rail Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },
                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 100,
                        max: 300,
                    },
                    {
                        img: ipThumb,
                        title: 'Delivery Robot',
                        price: 'Inquire',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: 'Loading Kiosk',
                        price: 'Inquire',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: 'Cobot',
                        price: 'Inquire',
                        min: 1,
                        max: 10,
                    }
                ]
            },

            disinfection: {
                icon: accessKey1,
                title: 'High-Risk Management Package',
                serial: 'Delivery Robot + Loading Kiosk + Cobot + Disinfection Device + etc',
                price: 'Inquire',

                note: [
                    { text: 'Package designed for managing the delivery of high-risk medical supplies', icon: IconInfo },
                    { text: 'Delivery robot load capacity 20L', icon: IconWaterproof },
                    { text: 'Collaborative robot max load 12kg', icon: IconCamera },
                    { text: '99.9% disinfection and sterilization', icon: IconGuarantee },
                    { text: 'Accuracy within 10cm', icon: IconGuarantee },
                    { text: 'Indoor installation up to 600 square meters', icon: IconGuarantee },
                    { text: 'Outdoor installation up to 1,000 square meters', icon: IconGuarantee },
                    { text: 'Includes ORBRO OS on ORBRO Server', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'Tag (UWB)',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker Rail Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },
                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 100,
                        max: 300,
                    },
                    {
                        img: ipThumb,
                        title: 'Delivery Robot',
                        price: 'Inquire',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: 'Loading Kiosk',
                        price: 'Inquire',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: 'Cobot',
                        price: 'Inquire',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: 'Disinfection Device Kit',
                        price: 'Inquire',
                        min: 1,
                        max: 10,
                    }
                ]
            },
        }




    },

    modal: {

        title: 'Product Inquiry',
        descHead: 'Please provide the following information and we will send you a quote through our representative.',
        descMid: 'If you want a quick response, please contact ',
        phone: '(+82) 1522-9928',
        descTail: '.',
        time: 'Weekdays (10:00 AM - 7:00 PM), KST',

        theme: {
            name: 'Name *',
            company: 'Company Name *',
            email: 'Business Email *',
            phone: 'Phone Number',
        },
        placeholder: {
            name: 'John Doe',
            company: 'ORBRO Corporation',
            email: 'example@orbro.io',
            phone: '010-1234-5678',
            ask: 'Please enter any additional inquiries or comments. (Optional)'
        }
    },



    accessory: 'accessory',
    soldOut: 'Sold Out',
    new: 'New',
    request: 'Inquiry',
    select: 'Select',


    submit: 'Submit',
    cancel: 'Cancel'
}