
import {
  accesscontroller1, accesscontroller2, accesscontroller3, accesscontroller4, accesscontrollerOutlook, accesscontrollerThumb,
  accessAuto1, accessAuto2, accessAuto3, accessAuto4, accessAutoOutlook, accessAutoThumb,
  accessKey1, accessKey2, accessKey3, accessKey4, accessKeyOutlook, accessKeyThumb, accessKeyM1,
  accessPro1, accessPro2, accessPro3, accessPro4, accessProOutlook, accessProThumb,
  apart1, apart2, apart3, apart4, apartThumb,
  deadbolt1, deadbolt2, deadbolt3, deadbolt4, deadboltThumb, deadboltM1,
  emlock1, emlock2, emlock3, emlock4, emlockThumb, emlockM1

} from "@assets/images/main/access/product";

import { assetTag1, assetTag2, assetTag3, assetTag4, assetTagOutlook, orbroTag1, orbroTag2, orbroTag3, orbroTag4, orbroTagOutlook } from "@assets/images/main/beacon/product";
import { carTracking1, carTracking2, carTracking3, carTracking4, carTrackingKey1, carTrackingKey2, carTrackingKey3, carTrackingKey4, carTrackingKey5, ipCam1, ipCam2, ipCam3, ipCam4, ipCamKey1, ipCamKey2, ipCamKey3, ipThumb, peopleTracking1, peopleTracking2, peopleTracking3, peopleTracking4, peopleTrackingKey1, peopleTrackingKey2, peopleTrackingKey3, peopleTrackingKey4, ptzCam1, ptzCam2, ptzCam3, ptzCam4, ptzCamKey1, ptzCamKey2, ptzCamKey3, ptzThumb } from "@assets/images/main/camera";
import { cobot1, cobot2, cobot3, deliverPackage, deliverRobot1, deliverRobot2, disinfection1, disinfection2, loadingKiosk1, loadingKiosk2 } from "@assets/images/main/integration";
import { bell1, bell2, bell3, bell4, bellOutlook, esl1, esl2, esl3, esl4, eslOutlook, idCard1, idCard2, idCard3, idCard4, idCard5, idCard6, idCard7, idCardOutlook, mCradle1, mCradle2, mCradle3, mCradle4, mCradle5, mCradle6, mCradle7, mCradleOutlook, mCradleThumb, mTag1, mTag2, mTag2_1, mTag2_2, mTag2_3, mTag2_4, mTag2_Outlook, mTag2_Thumb, mTag2_key1, mTag2_key2, mTag2_key3, mTag3, mTag4, mTagOutlook, mTagThumb, switch1, switch2, switch3, switch4, switchOutlook } from "@assets/images/main/iot/product";
import { assetTagBle1, assetTagBle2, assetTagBle3, assetTagBleOutlook, assetTracking1, assetTracking2, assetTracking3, assetTracking4, serverPc, tagUwb1, tagUwb2, tagUwbOutlook, twinTracker1, twinTracker2, twinTracker3, twinTracker4, twinTracker5, twinTrackerBracket, twinTrackerOutlook, workerTracking1 } from "@assets/images/main/rtls/product";
import { airQuality1, airQuality2, airQuality3, airQuality4, coMeasure1, coMeasure2, coMeasure3, coMeasure4, loraGateway1, loraGateway2, loraGateway3, loraGateway4, loraSensor1, loraSensor2, loraSensor3, loraSensor4 } from "@assets/images/main/sensor";
import { eslThumbnail, safetyBellThumbnail, switchThumbnail, twinTrackerThumbnail } from "@assets/images/main/thumbnail";


export default {
  document: '문서',
  product: {


    accesscontroller: {
      thumbnail: accesscontrollerThumb,

      mini1: accesscontroller1,
      mini2: accesscontroller2,
      mini3: accesscontroller3,
      mini4: accesscontroller4,

      key1: accesscontroller1,
      key2: accesscontroller2,
      key3: accesscontroller3,
      outlook: accesscontrollerOutlook,

      redDot: 0,
    },


    accessauto: {
      thumbnail: accessAutoThumb,

      mini1: accessAuto1,
      mini2: accessAuto2,
      mini3: accessAuto3,
      mini4: accessAuto4,

      key1: accessAuto1,
      key2: accessAuto3,
      key3: accessAuto2,

      outlook: accessAutoOutlook,

      redDot: 1,
      download: [
        {
          name: 'Access Auto - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'Access Auto',
        },
      ],

    },

    apart: {
      thumbnail: apartThumb,

      mini1: apart1,
      mini2: apart2,
      mini3: apart3,
      mini4: apart4,

      key1: apart2,
      key2: apart4,
      key3: apart3,
      key4: accessAuto1,

      outlook: accessAutoOutlook,

      redDot: 0,
    },


    cloud: {
      thumbnail: accessAutoThumb,

      mini1: accessAuto1,
      mini2: accessAuto2,
      mini3: accessAuto3,
      mini4: accessAuto4,

      key1: accessAuto1,
      key2: accessAuto3,
      key3: accessAuto2,

      outlook: accessAutoOutlook,

      download: [
        {
          name: 'Access Auto - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'Access Auto',
        },
      ],
      redDot: 1,
    },



    accesspro: {
      thumbnail: accessProThumb,

      mini1: accessPro1,
      mini2: accessPro2,
      mini3: accessPro3,
      mini4: accessPro4,

      key1: accessPro1,
      key2: accessPro2,
      key3: accessPro4,

      outlook: accessProOutlook,
      download: [
        {
          name: 'Access Pro - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'Access Pro',
        },
      ],

      redDot: 0,
    },



    accesskey: {
      thumbnail: accessKeyThumb,

      mini1: accessKeyM1,
      mini2: accessKey2,
      mini3: accessKey3,
      mini4: accessKey4,

      key1: accessKeyM1,
      key2: accessKey2,
      key3: accessKey3,

      outlook: accessKeyOutlook,


      redDot: 0,
    },



    emlock: {
      thumbnail: emlockThumb,

      mini1: emlockM1,
      mini2: emlock2,
      mini3: emlock3,
      mini4: emlock4,

      key1: emlockM1,
      key2: emlock2,
      key3: emlock3,

      outlook: "",


      redDot: 0,
    },



    deadbolt: {
      thumbnail: deadboltThumb,

      mini1: deadboltM1,
      mini2: deadbolt2,
      mini3: deadbolt3,
      mini4: deadbolt4,

      key1: deadboltM1,
      key2: deadbolt2,
      key3: deadbolt3,

      outlook: "",


      redDot: 0,
    },


    autoDoorSensor: {
      thumbnail: accessAutoThumb,

      mini1: accessAuto1,
      mini2: accessAuto2,
      mini3: accessAuto3,
      mini4: accessAuto4,

      key1: accessAuto1,
      key2: accessAuto3,
      key3: accessAuto2,

      outlook: accessAutoOutlook,


      redDot: 0,
    },

    twinTracker: {

      design: [
        {
          title: '',
          desc: '',
          img1: twinTracker1,
          img2: twinTracker2,
          img3: twinTracker3,

        },

      ],

      out: [
        {
          title: '',
          desc: '',
          img: twinTrackerOutlook,
        },

      ],


      thumbnail: twinTrackerThumbnail,

      mini1: twinTrackerThumbnail,
      mini2: twinTracker3,
      mini3: twinTracker4,
      mini4: twinTracker5,

      key1: assetTracking1,
      key2: assetTracking2,
      key3: assetTracking3,

      outlook: twinTrackerOutlook,

      title: 'TwinTracker',
      desc: '복잡한 설치 과정 없이 PoE(Power over Ethernet) 연결만으로 태그 단말기의 무선 신호를 추적합니다.',

      download: [
        {
          name: 'Twin Tracker - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'Twin Tracker',
        },
      ],

      redDot: 0,
    },

    twinTrackerPro: {

      design: [
        {
          title: '',
          desc: '',
          img1: twinTracker1,
          img2: twinTracker2,
          img3: twinTracker3,

        },

      ],

      out: [
        {
          title: '',
          desc: '',
          img: twinTrackerOutlook,
        },

      ],


      thumbnail: twinTrackerThumbnail,

      mini1: twinTrackerThumbnail,
      mini2: twinTracker3,
      mini3: twinTracker4,
      mini4: twinTracker5,

      key1: assetTracking1,
      key2: assetTracking2,
      key3: assetTracking3,

      outlook: twinTrackerOutlook,
      download: [
        {
          name: 'Twin Tracker Pro - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'Twin Tracker Pro',
        },
      ],

      title: 'TwinTracker',
      desc: '복잡한 설치 과정 없이 PoE(Power over Ethernet) 연결만으로 태그 단말기의 무선 신호를 추적합니다.',

      redDot: 0,
    },

    assetTracking: {

      design: [
        {
          video: 'https://www.youtube.com/embed/eZuulOz-Xe8?si=TuocF1X7qQu-lUit',
          videoEng: 'https://www.youtube.com/embed/WkUKiRbPFHs?si=3ExcEhloBiD3Fb-G',
          title: 'TwinTracker',
          desc: '복잡한 설치 과정 없이 PoE(Power over Ethernet) 연결만으로 자산 태그 단말기의 무선 신호를 추적합니다.',
          img1: twinTracker1,
          img2: twinTracker2,
          img3: twinTracker3,

        },

        {
          title: 'Asset Tag(BLE)',
          desc: '작고 손쉬운 부착으로 자산의 위치를 추적 할 수 있습니다.',
          img1: assetTagBle1,
          img2: assetTagBle2,
          img3: assetTagBle3,

        },

        {
          title: 'Server PC',
          desc: '자산의 위치를 저장하고 계산하는 역할을 합니다.',
          img1: serverPc,
          img2: '',
          img3: '',

        },

      ],

      out: [
        {
          title: 'TwinTracker',
          desc: '복잡한 설치 과정 없이 PoE(Power over Ethernet) 연결만으로 자산 태그 단말기의 무선 신호를 추적합니다.',
          img: twinTrackerOutlook,
        },
        {
          title: 'Asset Tag(BLE)',
          desc: '손쉬운 부착으로 작업자의 위치를 추적 할 수 있습니다.',
          img: assetTagBleOutlook,
        },

      ],


      thumbnail: assetTracking1,

      mini1: assetTracking1,
      mini2: assetTracking2,
      mini3: assetTracking3,
      mini4: assetTracking4,

      key1: assetTracking1,
      key2: assetTracking2,
      key3: assetTracking3,

      outlook: accessAutoOutlook,

      download: [
        {
          name: 'Server PC - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'Server PC',
        },
        {
          name: 'Twin Tracker - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'Twin Tracker',
        },
      ],

      title: 'TwinTracker',
      desc: '복잡한 설치 과정 없이 PoE(Power over Ethernet) 연결만으로 태그 단말기의 무선 신호를 추적합니다.',

      redDot: 0,
    },



    workerTracking: {


      design: [

        {
          video: 'https://www.youtube.com/embed/l9l7AwAHTok?si=ZXWnI-855hhb7bPu',
          videoEng: 'https://www.youtube.com/embed/gXDb-OgylZw?si=pMbDUgO-FutPZqud',
          title: 'TwinTracker Pro',
          desc: '복잡한 설치 과정 없이 PoE(Power over Ethernet) 연결만으로 자산 태그 단말기의 무선 신호를 추적합니다.',

          img1: twinTracker1,
          img2: twinTracker2,
          img3: twinTracker3,

        },

        {
          title: 'Tag(UWB)',
          desc: '손쉬운 부착으로 작업자의 위치를 추적 할 수 있습니다.',

          img1: '',
          img2: tagUwb1,
          img3: tagUwb2,

        },

        {
          title: 'Server PC',
          desc: '자산의 위치를 저장하고 계산하는 역할을 합니다.',

          img1: serverPc,
          img2: '',
          img3: '',

        },

      ],

      out: [
        {
          title: 'TwinTracker Pro',
          desc: '복잡한 설치 과정 없이 PoE(Power over Ethernet) 연결만으로 자산 태그 단말기의 무선 신호를 추적합니다.',
          img: twinTrackerOutlook,
        },
        {
          title: 'Tag(UWB)',
          desc: '손쉬운 부착으로 작업자의 위치를 추적 할 수 있습니다.',
          img: tagUwbOutlook,
        },

      ],


      thumbnail: workerTracking1,

      //제품 아래 4가지 이미지
      mini1: workerTracking1,
      mini2: tagUwb1,
      mini3: twinTracker1,
      mini4: assetTracking4,

      key1: workerTracking1,
      key2: tagUwb1,
      key3: twinTracker1,

      outlook: twinTrackerOutlook,

      download: [
        {
          name: 'Server PC - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'Server PC',
        },
        {
          name: 'TwinTracker Pro - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'TwinTracker Pro',
        },
      ],
      title: 'TwinTracker Pro',
      desc: '복잡한 설치 과정 없이 PoE(Power over Ethernet) 연결만으로 자산 태그 단말기의 무선 신호를 추적합니다.',

      redDot: 0,
    },




    carTracking: {


      design: [
        {
          video: 'https://www.youtube.com/embed/Z5dC0EKcnuY?si=zeRLmNn-RZXXcjF0',
          videoEng: 'https://www.youtube.com/embed/ZQ8mwjm3lBg?si=Y_89mX4kMcO3wKyl',
          title: 'PTZ Camera',
          desc: 'PTZ 실외 카메라를 통해 주차장의 차량의 위치를 추적합니다.',

          img1: carTrackingKey1,
          img2: carTrackingKey2,
          img3: carTrackingKey3,

        },

        {
          title: 'NVR',
          desc: '카메라의 이미지 정보를 저장하고 송출 합니다.',

          img1: carTrackingKey4,
          img2: '',
          img3: '',

        },

        {
          title: 'Server PC',
          desc: '이미지를 분석하여 위치를 계산하는 역할을 합니다.',

          img1: carTrackingKey5,
          img2: '',
          img3: '',

        },

      ],

      out: [],


      thumbnail: carTracking1,

      //제품 아래 4가지 이미지
      mini1: carTracking1,
      mini2: carTracking2,
      mini3: carTracking3,
      mini4: carTracking4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",
      download: [
        {
          name: 'Server PC - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'Server PC',
        },
        {
          name: 'NVR - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'NVR',
        },
        {
          name: 'PTZ Camera - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'PTZ Camera',
        }
      ],

      title: '',
      desc: '',

      redDot: 0,
    },

    peopleTracking: {


      design: [

        {
          video: 'https://www.youtube.com/embed/WLzYlbogr1I?si=Sh7P5KTFhHT52UpT',
          videoEng: 'https://www.youtube.com/embed/ioRVTrmYysY?si=spmc9b2JfwYpCIhz',
          title: 'IP Camera',
          desc: 'IP 카메라를 통해 실내 유동 인구를 추적합니다.',

          img1: '',
          img2: peopleTrackingKey1,
          img3: peopleTrackingKey2,

        },

        {
          title: 'NVR',
          desc: '카메라의 이미지 정보를 저장하고 송출 합니다.',

          img1: peopleTrackingKey3,
          img2: '',
          img3: '',

        },

        {
          title: 'Server PC',
          desc: '이미지를 분석하여 위치를 계산하는 역할을 합니다.',

          img1: peopleTrackingKey4,
          img2: '',
          img3: '',

        },

      ],

      out: [],


      thumbnail: peopleTracking1,

      //제품 아래 4가지 이미지
      mini1: peopleTracking1,
      mini2: peopleTracking2,
      mini3: peopleTracking3,
      mini4: peopleTracking4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",
      download: [
        {
          name: 'Server PC - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'Server PC',
        },
        {
          name: 'NVR - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'NVR',
        },
        {
          name: 'IP Camera - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'IP Camera',
        }
      ],

      title: '',
      desc: '',

      redDot: 0,
    },



    ptz: {


      design: [
        {
          title: 'PTZ Camera',
          desc: 'PTZ 실외 카메라를 통해 주차장의 차량의 위치를 추적합니다.',

          img1: ptzCamKey1,
          img2: ptzCamKey2,
          img3: ptzCamKey3,

        },

      ],

      out: [],


      thumbnail: ptzThumb,

      //제품 아래 4가지 이미지
      mini1: ptzCam1,
      mini2: ptzCam2,
      mini3: ptzCam3,
      mini4: ptzCam4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",
      download: [
        {
          name: 'PTZ Camera - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'PTZ Camera',
        }
      ],

      title: '',
      desc: '',

      redDot: 0,
    },

    ip: {


      design: [
        {
          title: 'IP Camera',
          desc: 'IP 카메라를 통해 실내 유동 인구를 추적합니다.',

          img1: ipCamKey1,
          img2: ipCamKey2,
          img3: ipCamKey3,

        },

      ],

      out: [],


      thumbnail: ipThumb,

      //제품 아래 4가지 이미지
      mini1: ipCam1,
      mini2: ipCam2,
      mini3: ipCam3,
      mini4: ipCam4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",
      download: [
        {
          name: 'IP Camera - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'IP Camera',
        }
      ],

      title: '',
      desc: '',

      redDot: 0,
    },



    safetyBell: {
      thumbnail: safetyBellThumbnail,

      mini1: bell1,
      mini2: bell2,
      mini3: bell3,
      mini4: bell4,

      key1: bell1,
      key2: bell2,
      key3: bell3,

      outlook: bellOutlook,
      download: [
        {
          name: 'Safety Bell - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'Safety Bell',
        }
      ],


      redDot: 0,
    },



    smartSwitch: {
      thumbnail: switchThumbnail,

      mini1: switch1,
      mini2: switch2,
      mini3: switch3,
      mini4: switch4,

      key1: switch1,
      key2: switch2,
      key3: switch3,

      outlook: switchOutlook,
      download: [
        {
          name: 'Smart Switch - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'Smart Switch',
        },
      ],


      redDot: 0,
    },


    esl: {
      thumbnail: eslThumbnail,

      mini1: esl1,
      mini2: esl2,
      mini3: esl3,
      mini4: esl4,

      key1: esl1,
      key2: esl2,
      key3: esl3,

      outlook: eslOutlook,
      download: [
        {
          name: 'E-ink Display - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'E-ink Display',
        },
      ],


      redDot: 0,
    },


    mTag: [
      {
        thumbnail: mTagThumb,

        mini1: mTag1,
        mini2: mTag2,
        mini3: mTag3,
        mini4: mTag4,

        key1: mTag1,
        key2: mTag2,
        key3: mTag3,

        outlook: mTagOutlook,
        redDot: 1,
      },

      {
        thumbnail: mTag2_Thumb,

        mini1: mTag2_1,
        mini2: mTag2_2,
        mini3: mTag2_3,
        mini4: mTag2_4,

        key1: mTag2_key1,
        key2: mTag2_key2,
        key3: mTag2_key3,

        outlook: mTag2_Outlook,
        redDot: 1,
      }


    ],

    mCradle: {
      thumbnail: mCradleThumb,

      mini1: mCradle1,
      mini2: mCradle2,
      mini3: mCradle3,
      mini4: mCradle4,

      key1: mCradle5,
      key2: mCradle6,
      key3: mCradle7,

      outlook: mCradleOutlook,

      redDot: 0,

    },


    idCard: {
      thumbnail: idCard1,

      mini1: idCard1,
      mini2: idCard2,
      mini3: idCard3,
      mini4: idCard4,

      key1: idCard5,
      key2: idCard6,
      key3: idCard7,

      outlook: idCardOutlook,

      redDot: 0,
    },

    loraGateway: {
      thumbnail: loraGateway1,

      mini1: loraGateway1,
      mini2: loraGateway2,
      mini3: loraGateway3,
      mini4: loraGateway4,

      key1: loraGateway1,
      key2: loraGateway2,
      key3: loraGateway3,

      outlook: "",

      redDot: 0,
    },

    loraSensor: {
      thumbnail: loraSensor1,

      mini1: loraSensor1,
      mini2: loraSensor2,
      mini3: loraSensor3,
      mini4: loraSensor4,

      key1: loraSensor1,
      key2: loraSensor2,
      key3: loraSensor3,

      outlook: "",

      redDot: 0,
    },

    airQuality: {
      thumbnail: airQuality1,

      mini1: airQuality1,
      mini2: airQuality2,
      mini3: airQuality3,
      mini4: airQuality4,

      key1: airQuality1,
      key2: airQuality2,
      key3: airQuality3,

      outlook: "",

      redDot: 0,
    },


    coMeasure: {
      thumbnail: coMeasure1,

      mini1: coMeasure1,
      mini2: coMeasure2,
      mini3: coMeasure3,
      mini4: coMeasure4,

      key1: coMeasure1,
      key2: coMeasure2,
      key3: coMeasure3,

      outlook: "",

      redDot: 0,
    },

    plutoconPro: {
      thumbnail: accesscontrollerThumb,

      mini1: accesscontroller1,
      mini2: accesscontroller2,
      mini3: accesscontroller3,
      mini4: accesscontroller4,

      key1: accesscontroller1,
      key2: accesscontroller2,
      key3: accesscontroller3,
      outlook: accesscontrollerOutlook,
      download: [
        {
          name: 'Plutocon Pro - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'Plutocon Pro',
        }
      ],

      redDot: 0,
    },

    orbroTag: {
      thumbnail: orbroTag1,

      mini1: orbroTag1,
      mini2: orbroTag2,
      mini3: orbroTag3,
      mini4: orbroTag4,

      key1: orbroTag1,
      key2: orbroTag2,
      key3: orbroTag3,

      outlook: orbroTagOutlook,
      download: [
        {
          name: 'ORBRO Tag - Datasheet v 1.0',
          date: '2023-11-20',
          data: 'ORBRO Tag',
        }
      ],

      redDot: 0,
    },

    assetTag: {
      thumbnail: assetTag1,

      mini1: assetTag1,
      mini2: assetTag2,
      mini3: assetTag3,
      mini4: assetTag4,

      key1: assetTag1,
      key2: assetTag4,
      key3: assetTag3,

      outlook: assetTagOutlook,

      redDot: 0,
    },



    //
    robotBasic: {


      design: [
        {
          title: 'Delivery Robot',
          desc: '오브로 시스템 연동을 통해 물건을 배송하는 역할을 합니다.',

          img2: deliverRobot1,
          img3: deliverRobot2,

        },

        {
          title: 'Twin Tracker Pro',
          desc: '복작한 설치 과정 없이 PoE(Power over Ethernet) 연결만으로 태그 단말기의 무선 신호를 추적합니다.',

          img1: twinTracker1,
          img2: twinTracker2,
          img3: twinTracker3,

        },

        {
          title: 'Tag(UWB)',
          desc: '손쉬운 부착으로 작업자의 위치를 추적 할 수 있습니다.',

          img2: tagUwb1,
          img3: tagUwb2,

        },

        {
          title: 'Server PC',
          desc: '자산의 위치를 저장하고 계산하는 역할을 합니다.',
          img1: serverPc,
          img2: '',
          img3: '',

        },

      ],

      out: [],


      thumbnail: deliverRobot1,

      //제품 아래 4가지 이미지
      mini1: deliverRobot1,
      mini2: tagUwb1,
      mini3: twinTracker1,
      mini4: assetTracking4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },


    delivery: {


      design: [
        {
          title: 'Delivery Robot',
          desc: '오브로 시스템 연동을 통해 물건을 배송하는 역할을 합니다.',

          img2: deliverRobot1,
          img3: deliverRobot2,

        },

        {
          title: 'Loading Kiosk',
          desc: '장치 위에 배송할 물품을 올려두고 로봇을 호출하면 자동으로 물품을 상차하는 역할을 담당합니다.',

          img2: loadingKiosk1,
          img3: loadingKiosk2,

        },

        {
          title: 'Cobot',
          desc: '배송로봇 도착시 배송 로봇에 있는 물건을 하차하는 역할을 담당합니다.',

          img2: cobot3,
          img3: cobot2,

        },

        {
          title: 'Twin Tracker Pro',
          desc: '복작한 설치 과정 없이 PoE(Power over Ethernet) 연결만으로 태그 단말기의 무선 신호를 추적합니다.',

          img1: twinTracker1,
          img2: twinTracker2,
          img3: twinTracker3,

        },

        {
          title: 'Tag(UWB)',
          desc: '손쉬운 부착으로 작업자의 위치를 추적 할 수 있습니다.',

          img2: tagUwb1,
          img3: tagUwb2,

        },

        {
          title: 'Server PC',
          desc: '자산의 위치를 저장하고 계산하는 역할을 합니다.',
          img1: serverPc,
          img2: '',
          img3: '',

        },
      ],

      out: [],


      thumbnail: deliverPackage,

      //제품 아래 4가지 이미지
      mini1: deliverPackage,
      mini2: loadingKiosk2,
      mini3: cobot1,
      mini4: workerTracking1,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },

    disinfection: {


      design: [
        {
          title: 'Delivery Robot',
          desc: '오브로 시스템 연동을 통해 물건을 배송하는 역할을 합니다.',

          img2: deliverRobot1,
          img3: deliverRobot2,

        },

        {
          title: 'Loading Kiosk',
          desc: '장치 위에 배송할 물품을 올려두고 로봇을 호출하면 자동으로 물품을 상차하는 역할을 담당합니다.',

          img2: loadingKiosk1,
          img3: loadingKiosk2,

        },

        {
          title: 'Cobot',
          desc: '배송로봇 도착시 배송 로봇에 있는 물건을 하차하는 역할을 담당합니다.',

          img2: cobot3,
          img3: cobot2,

        },


        {
          title: 'Disinfection Device Kit',
          desc: '배송로봇 상하차 프로세스에서 안전하게 소독 역할을 담당하는 제품입니다.',
          img1: disinfection2,

        },

        {
          title: 'Twin Tracker Pro',
          desc: '복작한 설치 과정 없이 PoE(Power over Ethernet) 연결만으로 태그 단말기의 무선 신호를 추적합니다.',

          img1: twinTracker1,
          img2: twinTracker2,
          img3: twinTracker3,

        },

        {
          title: 'Tag(UWB)',
          desc: '손쉬운 부착으로 작업자의 위치를 추적 할 수 있습니다.',

          img2: tagUwb1,
          img3: tagUwb2,

        },

        {
          title: 'Server PC',
          desc: '자산의 위치를 저장하고 계산하는 역할을 합니다.',
          img1: serverPc,
          img2: '',
          img3: '',

        },
      ],

      out: [],


      thumbnail: disinfection1,

      //제품 아래 4가지 이미지
      mini1: disinfection1,
      mini2: loadingKiosk2,
      mini3: cobot1,
      mini4: disinfection2,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },




  },



}