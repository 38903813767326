import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

    
  helmet : '',
  desc : '지게차와 작업장 내 태그를 통해 운전자의 위치를 실시간으로 추적하고, 위험 구역 진입 시 경고를 발생시켜 사고를 사전에 예방합니다. 또한, 이 솔루션은 운전자의 활동을 모니터링하여 작업 효율을 향상시키고, 안전 규정 준수를 강화합니다.',
  keywords : 'RTLS, 지게차, 노동자 안전, 노동안전, 실내 위치 추적, 위치 추적, 자산추적, 근로 안전, 물류 추적',

  ogTitle : '',
  ogDesc : '',
  ogSite : '지게차 운전자의 안전을 개선하는 RTLS 솔루션 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/forklift',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOYIuJxCZK1FJlYG0LyjYKrQCmcid1qnH-Lohcv1uYTGKb_GPVqY_SN8WE9jRoShLP0EX6b_VBXeTRzsO7mTk-z8QCbptyDW5Xe2QJeYxoTzMEDRgwOv8DvtKIbf4NcZZT_Zlplcg3Trlui87z3ElC0nCnI7pf1dZarBcSrb0LNt-ppIe2rFLvzS2ENcB9eWuw4z305W5GaBK0I-WjR0cY8MqBSBwIHoTY9di2xcTpbNtP65TVZDYzTDl7CQF157USeD7Tv9QhJl0LkphloREDPEYsqCkBmLCcGYAQju-OGqng3VUbIdyWaVOBofa0mNlwvO6D_amlwyTTiePAAysdHcsybGY8vPLjCQiidDTnfLYbE1UiI5Jl66uSVD0I6N3f9v7vospII2i5_YUcBaEEs4Uzb_F7XB5DIE3c7JrxIA_e6-nrn_YtHrMgid-5asfdU0L-Mu9fphtnVDL8fqDR7OZjb6-BISal_s9i8ORnx_iHMIcTCc9vXcP1Rq7C98YnMeMns2CqYZDaRVt-Pfmd_9ewR9v1hu0_YJG2TX-Je9BsZ0SXOx6fKHVjmWAppLp1kksJJxNxiIBWnSksv1hKhrn30d_QQmvYLDj-Jo88sbktqoX6Z-2TLIv3p2MSpCcGP7EwCnHLVPgc5ERRnv7zJkVHqh-07x-w5dq1R8qbdi7uRnG6yU3wAYTgilNH-USBwM2tL4FnMgAcZmQbSvFN61xcK3W1hGqOwfChKQ9aszr3gQdhYuf0zA-rBWbbuzB3mevQ5-MZPjI9p2tsS17nuFTROO8LR-WcMHO0bUmUfPbstaC4lgbyvZNrT2ZAMp9-4vQb8UzGs1SZTBzJFW8aqKfoKAvRIs9jMfZ0W7RDdjqTy6y4CYyQMyJXerwdeo8ODGTqvNux6rZeWraDDHjEhIJwHa68ntfE17VTeU9IhCi5Ax1xG5JKfDi89fGmyo0UGkByOqKXAUzQ0kb855-2_iUgo8bPO1rR4bdseYSRmP0A7VvhsrkW_cAe7shFm1dq5npgpxdlyqp7YSlii5O5nkSKPUTfBojpAN6nQQ31UtAIPu509dljo1GS1WzSIPWyw2LReT9-Cw6wR53NlceY4lcuqoVYfBHWxo7pz2gLr-0VfRlnJsTOa7u5skuZ6gxqycQuGkh1eWRDVwU9kPjERqK5HvRXQg2fmZgnb3ZLK8SAOpADOFjbSFGBizZAyNK5dP-73XpmaMWv60i64qqNS9zEEUtOzGyEhaAsBddF7caUjqcMq48beLtFcWzVl6nKMtZWS9v4GbJ79FqktHsqD2VNVKl5psGiqmamA97oR8Foggtisgpo_tXzUChfzvEPo4iLwNIS4e7cArRNt5MlE2L9My_ch8hwqmwWOjlLat1WgDZefk1f8TEzObNLoqW_fl0vZ2224rKOy8uRgK-smLBAJRvqxvrLdc8jqrYtsBmQbRW_5F9O38XaXXU86yJ6u4un2v2bZQs9lbD1U-Nj8419GHFhfQEyczZxSV2__aPX540BJW1T-CKQYO2-3UWpAuGLZnQuABSy6v5TAT3Zo6qgQgjs1CCnkpXLUnQZ_FCA=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
  
    
    title: '지게차 운전자의 안전을 개선하는 RTLS 솔루션',
    date : '2023-05-17',
    tag1 : '#RTLS',
    tag2 : '#노동자안전',
    tag3 : '#자산관리',
    tag4 : '#위치추적',
    
    // <br/>
    text : [
      {
        head : '지게차 정기 점검',
        subHead :'',
        desc : '지게차는 사용하기 매일 점검하여 작동 상태가 양호한지 확인해야 합니다. 점검 리스트에는 주차 브레이크, 조명, 유압 오일 레벨 등등이 포함됩니다.  만약 문제가 발견되었다면 모든 결함을 즉시 수리해야 합니다. 적어도 한 달에 한 번은 자격을 갖춘 기술자가 보다 철저한 검사를 진행하는 것이 좋습니다. <br/>하지만 사람이 하는 일에는 실수가 있기 마련이고 검사해야 하는 장비가 많아지면 시간소모와 실수도 비례해서 늘어나게 됩니다. <br/>RTLS 기술을 사용하면 지게차 또는 화물장비에 부착된 센서로, 장비들의 상태를 실시간으로 검사할 수 있습니다.<br/>효율적인 자산 관리는 안전과 비용절감에 직접적인 도움을 줍니다.',
        dot : ''
        
      },
      {
        head : '근접 경고 시스템',
        subHead :'',
        desc : '지게차는 크기가 크고 누군가나 무언가에 부딪히면 심각한 손상을 일으킬 수 있습니다.  <br/>그렇기 때문에 운전자들은 항상 주변을 의식하고 사람과 장애물을 조심하는 것이 중요합니다.  <br/>만약 그들이 길에서 누군가 또는 어떤 물체를 발견했다면, 속도를 줄이고 조심스럽게 진행해야 합니다. <br/>특히 활동량이 많은 바쁜 창고에서는  더욱 중요한 문제입니다.<br/> 이런 경우, 표지판을 세워서 위험 지역을 시각적으로 나타내는 것은 여전히 좋은 방법입니다.<br/>하지만 작업자 뿐만 아니라 일반 사람들도 포함해서, 간혹 표지판을 못보고 지나치거나 간과하는 경우가 있습니다. <br/>이런 경우에 근접 경고 시스템을 부착하는 것도 굉장히 좋은 방법입니다. 지게차에 근접하고 있는 사람 또는 사물이 있다면 이를 감지하고 실시간으로 경고 조치를 할 수 있습니다. <br/>이는 지게차 운전자 뿐만 아니라 관리자에게도 실시간으로 모니터링 되고 있어서,  관리자 측의 안전 규정 준수에도 도움이 됩니다.',
        dot : ''
        
      },
      {
        head : '올바른 속도로 작동',
        subHead :'',
        desc : '너무 빠른 속도로 운전하면 사고 확률이 높아지고, 그렇다고 느린 속도로 운전하면 생산성에 직결되므로 이 또한 좋은 방법은 아닙니다. <br/>안전하고 효율적으로 지게차를 작동시켜야 합니다. 하지만 작업에 몰두를 하다보면 운전자가 속도규정을 지키지 못할 수도 있습니다. 그런 경우 RTLS 시스템은 자동으로 속도 준수 여부를 판단하여 경고를 할 수 있습니다.',
        dot : ''
        
      },
      {
        head : '적재 위험',
        subHead :'',
        desc : '운전자들은 항상 자신이 얼마나 많은 무게를 싣고 있는지 아는 것이 중요합니다. 하중이 너무 무거우면 지게차가 넘어질 수 있습니다. 운전자는 자신이나 다른 사람을 위험에 빠뜨리지 않는 선에서 운반해야 합니다.  마찬가지로 지게차는 위험 물질을 운반하는 데 절대 사용해서는 안 됩니다. 이러한 물질은 누출, 유출 또는 화재가 발생하여 인근 작업자에게 심각한 안전 위험을 초래할 수 있습니다. <br/>RTLS 시스템은 이런 상황들을 모니터링하고 예측 및 대비가 용이하게 데이터를 제공해줍니다.',
        dot : ''
        
      },
      {
        head : '사각지대 위험',
        subHead :'',
        desc : '지게차의 사각지대는 일반적으로 기계 바로 뒤의 영역입니다. 지게차의 경로에 사람이나 물체가 있을 경우 예상치 못한 부상이 발생할 수 있으므로 이는 심각한 안전 위험을 초래할 수 있습니다.<br/>사각지대 위험에는 몇 가지 요인이 있습니다. 하나는 지게차가 종종 많은 양의 짐을 운반하기 때문에 운전자의 시야를 방해할 수 있다는 것입니다. 또 다른 하나는 조명이 잘 들어오지 않는 지역에서 지게차가 운행되는 경우가 많아 뒤를 보는 것이 훨씬 더 어렵다는 것입니다. 마지막으로, 지게차는 자주 정차하고 출발하는데, 이것은 사각지대 사고의 원인이 될 수도 있습니다. <br/> <br/>사람의 능력으로는 발생할 수 있는 안전사고를 RTLS 기술은 스마트하게 이를 예측할 수 있습니다.',
        dot : ''
        
      },
      {
        head : '자산 관리',
        subHead :'',
        desc : '장비 자체 관리에도 RTLS 솔루션은 효과적입니다! <br/> 필요한 장비들이 어디에 있는지 한번에 모니터링이 가능하며, 적절한 장비 배치까지 이어지므로 생산성 향상에도 크게 도움을 줍니다.<br/>혹은 도난당한 장비나 분실한 장비들 역시도 위치 추적이 가능하므로, 작업 중지와 같은 상황을 줄일 수 있습니다.<br/>RTLS 기술은 지게차 운전자와 관리자, 모두에게 자산관리의 효율성과 안전을 보장할 수 있습니다.',
        dot : ''
        
      },
    ],
  
    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/enterprise/construction/asset'
        }, 
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/enterprise/construction/safety'
        },
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/enterprise/logistics/asset'
        }
      ]
  
    }
  
  
  }