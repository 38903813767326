import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import { NotionRenderer } from "react-notion";
import React, { useState, useEffect, FC } from 'react';
import { DivAllCenter, DivPadding, FontPage } from "@pages/CommonStyle/commonDiv.style";
import { ImgWH, Spacing, SpacingDTM } from "@pages/CommonStyle/common.style";
import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledHeading } from "@components/Text";
import { Trans } from "react-i18next";
import { IconArrowLeft } from "@assets/images/icon";
import { NotionBack, NotionBackBtn, NotionBackBtnLayout } from "./ReleaseNote.style";



interface ProductProps {
  Page_ID: string;

}

const arrowLeft = IconArrowLeft;
const Detailpage: FC<ProductProps> = ({ Page_ID }) => {

  const [response, setResponse] = useState({});

  useEffect(() => {

    fetch(`https://notion-api.splitbee.io/v1/page/${Page_ID}`)
      .then(res => res.json())
      .then((resJson) => {
        setResponse(resJson);
      });
  }, [])

  return (
    <>
      <Spacing pixel="52" />
      <FontPage>
        <StyledGridContainer>
          
          <DivAllCenter>
            <NotionBackBtnLayout>
              <NotionBack>
                <NotionBackBtn href={"/release-note"}>
                  <ImgWH src={arrowLeft} alt="Left Arrow Icon" w={"20"} h={"20"} />
                  <DivPadding a={"0"} b={"0"} c={"0"} d={"16"} />
                  <StyledHeading size="xxxsmall">
                    <Trans i18nKey={"ORBRO OS 릴리즈 노트"} />
                  </StyledHeading>
                </NotionBackBtn>
              </NotionBack>
            </NotionBackBtnLayout>
          </DivAllCenter>

          <NotionRenderer
            blockMap={response}
            fullPage={true}
            hideHeader={true}
          />
          <SpacingDTM d={"128"} t={"96"} m={"64"} />
        </StyledGridContainer>
      </FontPage>
    </>
  );
}

export default Detailpage;