import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet: '',

  desc: '通过叉车和工作场所内的标签，实时跟踪驾驶员的位置，在进入危险区域时触发警报，以预防事故。此外，该解决方案监控驾驶员活动，提高运营效率，并加强对安全法规的遵守。',
  keywords: 'RTLS,叉车,工人安全,劳动安全,室内定位,定位,资产追踪,劳动安全,物流追踪',

  ogTitle: '',
  ogDesc: '',
  ogSite: '改善叉车司机安全的RTLS解决方案 :: Orbro Blog',

  ogUrl: 'https://orbro.io/cn/blog/forklift',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EOYIuJxCZK1FJlYG0LyjYKrQCmcid1qnH-Lohcv1uYTGKb_GPVqY_SN8WE9jRoShLP0EX6b_VBXeTRzsO7mTk-z8QCbptyDW5Xe2QJeYxoTzMEDRgwOv8DvtKIbf4NcZZT_Zlplcg3Trlui87z3ElC0nCnI7pf1dZarBcSrb0LNt-ppIe2rFLvzS2ENcB9eWuw4z305W5GaBK0I-WjR0cY8MqBSBwIHoTY9di2xcTpbNtP65TVZDYzTDl7CQF157USeD7Tv9QhJl0LkphloREDPEYsqCkBmLCcGYAQju-OGqng3VUbIdyWaVOBofa0mNlwvO6D_amlwyTTiePAAysdHcsybGY8vPLjCQiidDTnfLYbE1UiI5Jl66uSVD0I6N3f9v7vospII2i5_YUcBaEEs4Uzb_F7XB5DIE3c7JrxIA_e6-nrn_YtHrMgid-5asfdU0L-Mu9fphtnVDL8fqDR7OZjb6-BISal_s9i8ORnx_iHMIcTCc9vXcP1Rq7C98YnMeMns2CqYZDaRVt-Pfmd_9ewR9v1hu0_YJG2TX-Je9BsZ0SXOx6fKHVjmWAppLp1kksJJxNxiIBWnSksv1hKhrn30d_QQmvYLDj-Jo88sbktqoX6Z-2TLIv3p2MSpCcGP7EwCnHLVPgc5ERRnv7zJkVHqh-07x-w5dq1R8qbdi7uRnG6yU3wAYTgilNH-USBwM2tL4FnMgAcZmQbSvFN61xcK3W1hGqOwfChKQ9aszr3gQdhYuf0zA-rBWbbuzB3mevQ5-MZPjI9p2tsS17nuFTROO8LR-WcMHO0bUmUfPbstaC4lgbyvZNrT2ZAMp9-4vQb8UzGs1SZTBzJFW8aqKfoKAvRIs9jMfZ0W7RDdjqTy6y4CYyQMyJXerwdeo8ODGTqvNux6rZeWraDDHjEhIJwHa68ntfE17VTeU9IhCi5Ax1xG5JKfDi89fGmyo0UGkByOqKXAUzQ0kb855-2_iUgo8bPO1rR4bdseYSRmP0A7VvhsrkW_cAe7shFm1dq5npgpxdlyqp7YSlii5O5nkSKPUTfBojpAN6nQQ31UtAIPu509dljo1GS1WzSIPWyw2LReT9-Cw6wR53NlceY4lcuqoVYfBHWxo7pz2gLr-0VfRlnJsTOa7u5skuZ6gxqycQuGkh1eWRDVwU9kPjERqK5HvRXQg2fmZgnb3ZLK8SAOpADOFjbSFGBizZAyNK5dP-73XpmaMWv60i64qqNS9zEEUtOzGyEhaAsBddF7caUjqcMq48beLtFcWzVl6nKMtZWS9v4GbJ79FqktHsqD2VNVKl5psGiqmamA97oR8Foggtisgpo_tXzUChfzvEPo4iLwNIS4e7cArRNt5MlE2L9My_ch8hwqmwWOjlLat1WgDZefk1f8TEzObNLoqW_fl0vZ2224rKOy8uRgK-smLBAJRvqxvrLdc8jqrYtsBmQbRW_5F9O38XaXXU86yJ6u4un2v2bZQs9lbD1U-Nj8419GHFhfQEyczZxSV2__aPX540BJW1T-CKQYO2-3UWpAuGLZnQuABSy6v5TAT3Zo6qgQgjs1CCnkpXLUnQZ_FCA=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',


  title: '改善叉车司机安全的RTLS解决方案',
  date: '2023-05-17',
  tag1: '#RTLS',
  tag2: "#工人安全",
  tag3: "#资产管理",
  tag4: "#定位",


  // <br/>
  text: [
    {
      head: '叉车定期检查',
      subHead: '',
      desc: '叉车每天使用前应进行检查，确保其工作状态良好。检查清单应包括停车制动器、灯光、液压油位等等。如果发现问题，应立即修理所有缺陷。建议至少每月由合格的技术人员进行更彻底的检查。<br/>但是人的工作难免会出现错误，而且随着需要检查的设备增多，时间和错误也会成比例增加。<br/>使用RTLS技术，可以通过附加到叉车或货物设备上的传感器实时检查设备的状态。<br/>高效的资产管理直接有助于安全和节约成本。',
      dot: ''
    },
    {
      head: '接近警告系统',
      subHead: '',
      desc: '叉车的尺寸较大，如果撞到人或物体，可能会造成严重损害。<br/>因此，驾驶员始终要注意周围并小心人员和障碍物。如果发现路上有人或物体，应减速并谨慎前进。<br/>特别是在繁忙的仓库中更为重要。<br/>在这种情况下，设置标志来视觉表示危险区域仍然是一种不错的方法。<br/>但即使工人和其他人员也包括在内，有时也会忽略或忽视标志。<br/>在这种情况下，安装接近警告系统也是一个很好的方法。如果有人或物体接近叉车，可以检测到并及时采取警示措施。<br/>这不仅适用于叉车驾驶员，也有助于管理人员实时监控，有助于管理人员遵守安全规定。',
      dot: ''
    },
    {
      head: '以正确的速度操作',
      subHead: '',
      desc: '如果以过快的速度驾驶，事故的可能性会增加，然而以过慢的速度驾驶又会影响生产力，这也不是一个好方法。<br/>应安全且高效地操作叉车。但是在专注于工作时，驾驶员有时可能无法遵守速度规定。在这种情况下，RTLS系统可以自动判断是否遵守速度规定并发出警告。',
      dot: ''
    },
    {
      head: '装载风险',
      subHead: '',
      desc: '驾驶员应时刻知道自己装载的货物重量。如果负载过重，叉车可能会翻倒。驾驶员必须在不将自己或他人置于危险中的前提下运输货物。<br/>同样，叉车绝不能用于携带危险品。这些物品可能会泄漏、溢出或引发火灾，对附近的工人造成严重的安全风险。<br/>RTLS系统可以监控这些情况并提供数据，使预测和准备变得容易。',
      dot: ''
    },
    {
      head: '盲区风险',
      subHead: '',
      desc: '叉车的盲区通常是机器正后方的区域。如果有人或物体在叉车的行驶路线上，可能会导致意外伤害，因此这是一个严重的安全隐患。<br/>盲区风险涉及几个因素。首先，由于叉车经常运输大量货物，可能会遮挡驾驶员的视线。其次，叉车在光线不足的区域行驶，后方视线也更加困难。最后，叉车经常停车和启动，这可能是盲区事故的原因。<br/><br/>RTLS技术可以智能预测可能发生的安全事故。',
      dot: ''
    },
    {
      head: '资产管理',
      subHead: '',
      desc: '对于设备的管理，RTLS解决方案也非常有效！<br/>可以同时监控所需设备的位置，并持续进行适当的设备布局，从而大大提高生产力。<br/>或者，如果设备丢失或丢失，也可以进行定位，从而减少停工等情况。<br/>RTLS技术可以确保叉车驾驶员和管理者在资产管理方面的效率和安全性。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: "将UWB终端机安装在工作者或设备上，帮助实时追踪位置的产品。",
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]

  }


}