import { StyledGridColumn, StyledGridContainer } from "@components/Grid/Grid.style";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { StyledFaq, StyledFaqGridRow, StyledFaqTitle } from "./Faq.style";
import FaqItem from "./Item";



import access from "@src/lang/ko/main/access/access";
import accessEng from "@src/lang/en/main/access/access";
import accessJP from "@src/lang/jp/main/access/access";
import accessCN from "@src/lang/cn/main/access/access";

import camera from "@src/lang/ko/main/camera/camera";
import cameraEng from "@src/lang/en/main/camera/camera";
import cameraJP from "@src/lang/jp/main/camera/camera";
import cameraCN from "@src/lang/cn/main/camera/camera";


import rtls from "@src/lang/ko/main/rtls/rtls";
import rtlsEng from "@src/lang/en/main/rtls/rtls";
import rtlsJP from "@src/lang/jp/main/rtls/rtls";
import rtlsCN from "@src/lang/cn/main/rtls/rtls";

import os from "@src/lang/ko/main/os/os";
import osEng from "@src/lang/en/main/os/os";
import osJP from "@src/lang/jp/main/os/os";
import osCN from "@src/lang/cn/main/os/os";



import { useLocation } from "react-router-dom";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";
import { Spacing } from "@pages/CommonStyle/common.style";


export interface ServiceFaqItem {
  title: string,
  description: string
}

export interface ServiceFaqProps {
  items: ServiceFaqItem[],
  path : string
}

const Faq = (props: ServiceFaqProps):React.ReactElement => {
  
  const { items, path } = props;

  const [ isOpenIndex, setIsOpenIndex ] = useState<number>(-1);



  const handleClickItem = (index: number) => {
    setIsOpenIndex(isOpenIndex === index ? -1 : index);
  }


  let descPath;
  const location = useLocation();
  if (path === 'accessMain'){
    if (location.pathname.includes('/en/')){
      descPath = accessEng;
    } else if (location.pathname.includes('/jp/')){
      descPath = accessJP;
    } else if (location.pathname.includes('/cn/')){
      descPath = accessCN;
    } else {
      descPath = access;
    }
  } else if (path === 'camera'){
    if (location.pathname.includes('/en/')){
      descPath = cameraEng;
    } else if (location.pathname.includes('/jp/')){
      descPath = cameraJP;
    } else if (location.pathname.includes('/cn/')){
      descPath = cameraCN;
    } else {
      descPath = camera;
    }
  } else if (path === 'rtls'){
    if (location.pathname.includes('/en/')){
      descPath = rtlsEng;
    } else if (location.pathname.includes('/jp/')){
      descPath = rtlsJP;
    } else if (location.pathname.includes('/cn/')){
      descPath = rtlsCN;
    } else {
      descPath = rtls;
    }
  } else if (path === 'os'){
    if (location.pathname.includes('/en/')){
      descPath = osEng;
    } else if (location.pathname.includes('/jp/')){
      descPath = osJP;
    } else if (location.pathname.includes('/cn/')){
      descPath = osCN;
    } else {
      descPath = os;
    }
  }


  return (
    <StyledFaq>

      <StyledGridContainer>
        <DisplayH2 size="small">
          <Trans i18nKey={`os.faqTitle`} />
        </DisplayH2>
        
        <StyledFaqGridRow >

          <StyledGridColumn desktop={12} tablet={8} mobile={4}>
            { items.map((item, i) => {
              return (
                <FaqItem
                  key={`faq-item-${i}`}
                  title={`${path}.FAQ.${i}.title`}
                  description={descPath.FAQ[i].description}
                  isOpen={isOpenIndex === i}
                  handleClick={() => { handleClickItem(i) }}
                />
              ) 
            })}
          </StyledGridColumn>

        </StyledFaqGridRow>
        <Spacing pixel="64"/>
      </StyledGridContainer>
    </StyledFaq>
  )
};

export default Faq;