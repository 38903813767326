import { TdoaIconBuilding, TdoaIconClock, TdoaIconRuler, TdoaImg1Desktop, TdoaImg1Mobile, TdoaImg1Tablet } from "@assets/images/technology/tdoa";
import { StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import Advantage, { AdvantagePropsItem } from "@pages/Enterprise/Technology/Common/Advantage/Advantage";
import { DivPaddingRight, StyledTechnologyGroup, StyledTechnologyImg, StyledTechnologyText, StyledTechnologyTitle } from "@pages/Enterprise/Technology/Technology.style";
import { CommonState } from "@redux/reducers/commonReducer";
import React from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { Spacing } from "@pages/CommonStyle/common.style";
const advantageItems:AdvantagePropsItem[] = [
  { 
    icon: TdoaIconRuler, 
    title: "tdoa.advantage.items.accuracy.title",
    description: "tdoa.advantage.items.accuracy.description",
  },
  { 
    icon: TdoaIconClock, 
    title: "tdoa.advantage.items.wait.title",
    description: "tdoa.advantage.items.wait.description",
  },
  { 
    icon: TdoaIconBuilding, 
    title: "tdoa.advantage.items.expansion.title",
    description: "tdoa.advantage.items.expansion.description",
  },
];

const img1 = {
  desktop: TdoaImg1Desktop,
  tablet: TdoaImg1Tablet,
  mobile: TdoaImg1Mobile,
}

const TDoAContent = () => {
  
  // Redux
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  return (
    <>
      <StyledTechnologyGroup>
        <StyledGridRow>
          
          <StyledGridColumn desktop={6} tablet={4} mobile={4}>
            <DivPaddingRight pixel={layoutType !== 'mobile' ? '40' : ''}>
              <StyledTechnologyTitle size="small"><Trans i18nKey={ "tdoa.definition.title" }/></StyledTechnologyTitle>
              <StyledTechnologyText size="medium">
                <Trans i18nKey={"tdoa.definition.text"} />
              </StyledTechnologyText>
              </DivPaddingRight>
          </StyledGridColumn>

          <StyledGridColumn desktop={6} tablet={4} mobile={4}>
            <StyledTechnologyImg src={img1[layoutType]} alt="TDoA"/>
          </StyledGridColumn>

        </StyledGridRow>
      </StyledTechnologyGroup>

      <StyledTechnologyGroup>
        <StyledTechnologyTitle size="small"><Trans i18nKey={ "tdoa.sync.title" }/></StyledTechnologyTitle>
        <StyledTechnologyText size="medium">
          <Trans i18nKey={"tdoa.sync.text"} />
        </StyledTechnologyText>
        <Spacing pixel="64"/>
        <Advantage
          title={"tdoa.advantage.title"}
          items={advantageItems}
        />
      </StyledTechnologyGroup>
    </>
  );
};

export default TDoAContent;