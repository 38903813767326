import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../../en/main/product/productImg";
import productPurchase from "../../en/main/product/productPurchase";

export default {

    
  helmet : '| Real-time Patient Tracking for Patient Management',
  desc : `It supports medical staff in quickly responding to emergency situations by tracking the patient's current location in real-time and efficiently allocating resources needed for treatment and monitoring. This helps enhance patient safety and improve the quality of medical services.`,
  keywords : 'patient location tracking, patient management, hospital location tracking, hospital management, smart hospital, patient tracking, medical facility management, dementia patient management, Alzheimer\'s patient management, elderly patient management, hospital operations, medical center, nursing home management',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'Real-time Patient Tracking for Patient Management :: ORBRO Blog',
  ogUrl : 'https://orbro.io/en/blog/patientTracking',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4NuWirMGmbqpgIlWdCqK-ppWpzcgz_tlLlZI_o-5cHhj2j9qDRwsnEZiV8vOrMOlgGbh_KwwuV__dIaSBb4XYZB1HeJm3biM2fWsJG8fIJeS5V5kdQb1dPsENNDJZ_zA8YJEqLbd9oYhe7xJup9Cj0li6ASKbfdFzgjVSOv5nw9S1bq5HSdb3F-X1xE3Y3aAkqV1dkKqwPbFh5k9umUyucZ_TuNYaj2QcdjjdzsQfPpPJXnLHTYvP2jf2R80k52qNHygEEDSAP6if-9V8fj-RaWQ22wE13LmgV9GSECNTS9vVpS30mTAIwxI-GeD6m0J4Mu2OMwiArtjZQnqvHYKGzFumN9bqIchR5DBOue8f47fUqqH35eeZjPT6KFT0ykHX1UPgLuqhlouyD2V96TnHrcJjuoV6gj6n3qsfMYqj_-FLcJuy8biYIsFkBSzapyu4nuPfGB4aLMes_jDVehCX4VDdMVe5MlkWAXUqGZXvn_gsL8xrwcCn1wzGqA6pabPD-4W8aub76Rgsw4-Pch-5O3UQHuUkBEWDGzlYA3bmGZtL0It0WuJ2YrRICpcphRc-dkcsUpSMFFkRAs9wKE0iY_n-XdLq2SBW-p1sVPddxJuHi6s8sBXGYlI018XAOQfYkZ9iKUglZYhiwvfWTrp_MD_-xKSG5hRwPBRclRrIuqtQhCdmyLiG9PFc-k8Zzc0nrx9oLYcMOfOoCpKa3uNflVWy8JQF1jg6SWc_6IAzN3iex6DH7MiS1gjTRkd06IoN1ak0BjLij5u6JZa_2NoOCImCsjTG-saSx89XwTjolpf-0NeYZ7yC_7sHsUcLpFKtSoMNA2IMi1mfRtuPDpiuxiJISZ8WKPBm3HBfZqWs5D0MFWFd6HxrPotsmJoWiLBbJEbzcj6Q2r9zqygWKXnw12Kdv9WXPrOqSt3HJlmvd9u76idLGomybRZRJ_8z4q6IkYJwFotHED3XrmnxcD7076DgXVuhiRpSPI0w19qp6wccBXjOE2ySKWzLJO3NVFRXyi2YWpdZSKtZ34ZkHDDkhR29p6v4DI7wAxSn9oBNOXRdS83jsK8rE5DUHxS6GQjjgMhF08vjMlay-RB94xolSLw9nmiBfsmmCqHGEIReH-PBxHd6yEYhqR0V5YHFcAT6qUla5YiV4-CDftqlCmwRMuckmyx0d34NbvAUxWomI_-_8A8GwUdrcmAE5me-0RJYcrPd-McYwnI9Jr7_k8vQ-cUChbrrreLjukSuF5n93kEUvM6c9dUYGIZMnXLjjAMAYtg2Xkvf0_Iko5BlcvHIH4XZxUXOhxNZA3pvpgHI7OOt9GtIiSv6JkP1nr1VtFiy8MvV2LM19_SAcsitsu3Kh92FS8lUnTyo7PiQxxKmZTngLuMJToXuzVsXIqr6GuWEPzDBIyKZ7ti7MT57s-koDdp147Lx1bHkrwSIZ9PwC0MhHfJSS7hOUhyhSO-imUKDGcKUGAulSr_jiKtjiKlAbldoULjuMFSJeEM9OZ6Th9AbTNrgF0Br4Cj7MKqqqQJSJj2PE2hlGPBzFLo-qBP4x0ed_IHQB_8_czj6rpvIBt0YE=w2276-h1147',
  ogKey : '',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'Real-time Patient Tracking for Patient Management',
  date : '2023-08-07',
  tag1 : '#PatientManagement',
  tag2 : '#PatientTracking',
  tag3 : '#SmartHospital',
  tag4 : '#PatientSafety',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'Medical facilities like hospitals are places where patients gather (as expected). Therefore, a higher level of safety assurance system is required compared to other industries. Violating this requirement is not just a business loss, but a critical issue that can determine life and death for individuals. Similarly, as patients and caregivers, we prefer to receive medical services in safer healthcare facilities. In other words, meeting each other\'s needs can be seen as creating a safer healthcare facility.<br/><br/>The key is the patient. If we can monitor the patient\'s condition in real-time, detect accidents, and prevent them, much quicker responses will be possible. Real-time location tracking system (RTLS) forms the basis for building such a stable system.<br/>Shall we see what is possible below?',
      dot : ''
      
    },
    {
      head : 'Real-time Location Tracking',
      subHead :'',
      desc : 'Real-time tracking of patients\' locations within healthcare facilities is possible. If you need to quickly locate a specific patient, you can do so rapidly through searches. This would be particularly effective for patients requiring extra attention, such as Alzheimer\'s patients. Not only patients, you can also track the location of visitors who may be lost within the facility or keep tabs on the real-time positions of patients and medical staff in rooms to avoid wasteful routes.',
      dot : ''
      
    },
    {
      head : 'Fall Detection',
      subHead :'',
      desc : 'Falling incidents can be critical for patients with mobility issues or joint problems. If these incidents are detected in real-time, medical staff can be called immediately, preventing potentially larger accidents.',
      dot : ''
      
    },
    {
      head : 'Epidemiological Investigations',
      subHead :'',
      desc : 'Swift preemptive responses are crucial for deadly infections like COVID-19. To achieve this, suspected cases need to be identified, and to do that, their contacts must be traced. Real-time location tracking systems record not only the real-time position of the patient but also their movement within the facility. This enables quick and accurate epidemiological investigations and allows for rapid identification of contacts.',
      dot : ''
      
    },
    {
      head : 'Congestion Control',
      subHead :'',
      desc : 'Business growth is based on efficiency, a fact well-known to consumers (patients). Delays in medical appointments or facility congestion can lead to a negative experience and erode trust. A real-time location tracking system can assess facility congestion, control bottlenecks, and maintain smooth facility operations.',
      dot : ''
      
    },
    {
      head : 'Safe Zones',
      subHead :'',
      desc : 'Medical facilities house numerous assets that require special management, such as medication, medical equipment, and waste. To prevent theft, accidents, and losses, these areas can be designated as safe zones. Unauthorized individuals entering these zones can trigger alerts to administrators for prompt response.',
      dot : ''
      
    },
    {
      head : 'More Features',
      subHead :'',
      desc : 'Real-time location tracking systems are not limited to people. They can track all sorts of assets. Assets that require continuous monitoring and management, like medical waste or medical equipment, can be tracked in real-time as well.',
      dot : ''
      
    },
    {
      head : 'Conclusion',
      subHead :'',
      desc : 'Like many industries, healthcare facilities consider a wider range of factors when patients choose their medical services. Among these factors, "trust" can be deemed one of the most crucial. Therefore, real-time location tracking systems (RTLS) can be seen as the solution to create the most advanced healthcare facility services at the present moment.',
      dot : ''
      
    },

  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : medicalPatient.thumb,
        data : 'medicalPatient',
        link : '/en/enterprise/medical/patient'
      }, 
      {
        img : medicalWaste.thumb,
        data : 'medicalWaste',
        link : '/en/enterprise/medical/waste'
      },
      {
        img : officeAsset.thumb,
        data : 'officeAsset',
        link : '/en/enterprise/office/asset'
      }
    ]

  }


}
