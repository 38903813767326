export default {
  titleBanner: {
    subtitle: '立体视觉',
    title: '基于立体视觉的<br/>定位和定位技术',
    description: '* 立体视觉是利用两个相机联合使用的技术，用于三维物体识别。 <br/> 这项技术的原理类似于人类的双眼视觉。即两个相机同时从不同位置拍摄同一目标，从而获得物体的深度信息。'
  },
  tech: {
    title: '立体视觉是什么？',
    text: '立体视觉是一种利用两个相机拍摄图像，并利用这些图像估算三维空间深度信息的计算机视觉技术。为此，两个相机从不同位置拍摄同一目标，并利用获得的图像对来计算深度信息。通过计算两幅图像之间的时间差，即视差(disparity)，来估算深度。<br/><br/> 这项技术的原理类似于人类的双眼视觉，可以在三维环境中捕捉物体的位置和距离，广泛应用于机器人工程、自动驾驶汽车、视频游戏、图像处理等多个领域。与传统的计算机视觉技术相比，立体视觉技术能够提供更准确、实用的结果，在多个应用领域起着重要作用。'
  },
  feature: {
    title: '基于立体视觉的实时定位系统（RTLS）的优势',
    text: '基于立体视觉的实时定位系统（RTLS）利用两个相机收集视觉信息，实时跟踪和监视物体或人员的位置信息。<br/> 这种立体视觉技术具有非常准确的位置推断能力，提供高精度和可靠性。由于能够实时更新位置信息，因此在物流、制造、建筑等领域提供了重要的实时位置信息。<br/><br/> 此外，基于立体视觉的RTLS不需要使用标签，因此无需购买和维护目标的附加标签或信号发生设备。这有助于节省系统配置成本和维护费用，从而在成本效益和性能方面都具有优势。由于定位的准确性和可靠性较高，因此基于立体视觉的RTLS成为一种非常有用的技术，既能节省成本，又能提高系统性能。'
  },

  howItWorks: {
    title: '基于立体视觉的定位是如何工作的？',
    card: {
      detection: {
        title: '目标检测',
        description: '在基于视觉的RTLS中，使用深度学习目标识别技术来跟踪图像中的目标。这项技术是经过训练的人工智能算法，可以识别和分类图像中的各种物体。目前，它可以识别约80种不同类型的物体，并可以通过根据客户要求进行额外训练来识别更多种类的物体。'
      },
      estimation: {
        title: '深度估算',
        description: '我们使用从不同角度拍摄的两幅图像来推测物体的深度信息。这类似于人类使用两只眼睛来感知空间深度的原理。我们使用深度学习技术，相较于传统的计算机视觉技术，更准确地推测深度。'
      },
      calculation: {
        title: '坐标计算',
        description: '通过利用计算机视觉技术来执行目标识别、距离估算和角度计算等，可以计算出物体的位置信息。通过这种方式，可以实现实时跟踪物体或获取其位置等多种应用。'
      }
    }
  },

  core: {
    items: {
      title: `基于KongTech立体视觉的实时定位系统(RTLS)核心技术`,
      streaming: {
        title: `原始图像流传输`,
        description: `为了提高RTLS的位置跟踪准确性，高水平的深度图推断技术是必不可少的。人们通常通过经验和直觉就能快速准确地识别出物体的大致轮廓并估计距离，而深度图技术则着重于图像的细节，直接影响位置跟踪的精确推断。因此，我们不使用普通CCTV中常用的图像有损压缩方法，而是利用无损图像来最小化低级特征的损失。这样，基于立体视觉的RTLS能够最大程度地减少位置信息的损失。`,
        subDescription: `利用无损原始图像的深度图表现出物体表面更加连续。`,
        imgLabel1: `推断背景`,
        imgLabel2: `原始`,
        imgLabel3: `编码`
      },
      estimation: {
        title: `高级深度估计`,

        description: `应用立体相机图像进行深度推断在计算机视觉领域受到极大关注。特别是最近10年，应用深度学习方法比传统经典算法表现出更优异的结果。然而，许多领域仍然使用传统经典算法，因为它们可以保证适当的准确性和较高的实时性。通常，这意味着可以处理每秒超过15帧的帧率。`,
        imgLabel: `使用第三方深度图的计算机视觉`,

        description2: `利用传统计算机视觉算法进行深度推断的结果存在问题，例如细节部分和物体边缘出现畸变。例如，手持鼠标的上部或被隔离墙挡住的人的头部等部分未能被推断出来。但是，我们开发的基于深度学习的模型克服了这些问题。该模型已经优化，以供"基于立体视觉的RTLS"使用，并通过轻量化和微调来保证实时性（>15 FPS）。但是，如果处理多个相机输入，处理速度可能会降低到约4 FPS，但在考虑屏幕上人物移动速度时，仍能提供令人满意的位置信息。`,
        imgLabel2: `使用深度学习的KongTech深度图`


      },
      fineTuning: {
        title: `深度推断微调技术`,
        description: `采用自然光和人工照明拍摄的图像具有不同的特性，即使在立体相机的一个镜头上也可能出现光照差异。在这种情况下，左/右图像传感器的曝光值差异会导致左/右图像的特性不一致。`,
        imgTag: `由直接光照引起的左/右立体图像特性差异`,

        description2: `除了这些外部环境因素，还必须考虑在立体相机制造过程中可能发生的内部因素。例如，安装图像传感器时可能出现的上/下对齐误差、旋转误差以及镜头和图像传感器之间距离变化导致的缩放差异等。`,
        imgLabel: `(a): 亮度差异, (b): 图像传感器旋转误差, (c): 图像传感器上/下对齐误差, (d): 焦距误差`,
        imgLabela: `(a)`,
        imgLabelb: `(b)`,
        imgLabelc: `(c)`,
        imgLabeld: `(d)`,

        description3: `通过克服这些现实问题，基于立体视觉的RTLS可以提供高可靠性。KongTech的深度估计微调技术和专业知识使这成为可能，并最终使深度估计技术不依赖于特定硬件，且在环境变化下仍能正常运行。`

      },
      distance: {
        title: `考虑相机的目标偏差计算距离`,
        description: `使用立体相机获取的图像通过基于深度学习的深度推断技术转换时，会出现由广角镜头引起的深度失真问题。这种失真随着距离的缩短而增加，从光轴越远，表现出更加非线性的行为。`,
        imgLabel: `广角镜头引起的失真现象和A、B相机之间的目标偏差 <br/> (绿色：2.7米, 紫色：6.3米, 红色：8.8米)`,
        imgLabela: `相机 A`,
        imgLabelb: '相机 B',

        description2: `为解决这个问题，关键是准确建模失真现象，以使其与实际深度相匹配。这个校正过程是深度推断技术的重要部分，并考虑了相机在生产过程中可能发生的偏差或组装过程中的偏差等相机的个体不确定性。如果不应用失真校正模型，通常会在广角立体相机推断的深度值中观察到30%以上的偏差。但是，应用失真校正模型后，不仅可以消除这些误差，还可以将深度推断的平均误差率减少约50%。因此，我们在所有立体相机发货之前，使用我们自己开发的高级失真校正模型进行校正。通过这样做，我们能提供优秀的深度推断技术，为客户提供最佳性能和可靠性。`
      }
    }

  },

  advantage: {
    title: '立体视觉的主要优势',
    items: {
      accuracy: {
        title: '准确的位置估计',
        description: '使用立体相机，可以同时获取两幅图像，从而精确地识别物体在三维空间中的位置。通过这种方式，可以准确地估计物体的位置和运动。'
      },
      position: {
        title: '可扩展性',
        description: '立体视觉技术不受空间大小的限制，适用于各种室内和室外环境的不同规模和类型。此外，基于立体相机的RTLS系统可以通过添加附加传感器来扩展功能。'
      },
      security: {
        title: '成本效益',
        description: '与其他位置估计技术相比，立体视觉技术相对较便宜。此外，由于可以利用已安装的立体相机来构建RTLS系统，因此可以减少安装成本。'
      },
    }
  },


  useCase: {
    title: '应用立体视觉的定位使用案例',
    description: '立体视觉的可扩展性为各种室内定位使用案例提供了有效的选择。<br/>以下是立体视觉室内定位技术应用于某些使用案例和应用领域的一些例子。',
    card: {
      auto: {
        title: '机器人自动化',
        description: '应用立体视觉的机器人自动化在制造业领域广泛应用。机器人利用立体相机识别环境并使用位置估计技术自动移动、装配、检查或包装产品。',
        items: ` `
      },
      car: {
        title: '自动驾驶汽车',
        description: '立体视觉技术用于自动驾驶汽车的位置估计、障碍物检测和规避、车道保持等。利用立体相机的三维信息推断周围车辆的位置和方向，并计算车辆的行驶路径。',
        items: ` `
      },

      counting: {
        title: '人流计数',
        description: '为了监控商业和公共设施中客流量和人数，使用人流计数功能。基于立体视觉的RTLS技术通过安装在入口和出口的摄像头来区分行人的进出，并实时计算人数。',
        items: ` `
      },
      space: {
        title: '空间占用率分析',
        description: '通过收集建筑内部或停车场等空间的占用数据来优化空间利用率。基于立体视觉的RTLS技术通过在室内安装的摄像头来实时监测物体的位置和移动，从而了解空间内部的使用情况。可以通过这种方式了解内部空间的使用情况，并提高空间的利用效率。',
        items: ` `
      },
      safe: {
        title: '工业安全',
        description: '基于立体视觉的RTLS技术也应用于工业现场，以保持安全。例如，可以实时监测工作区内人员或物体的位置，从而帮助预防可能发生的事故。特别是在工业现场，当有人员跌倒或工人发生重大伤害时，立体视觉可以快速检测并确保按时采取行动。RTLS系统可以提前检测到可能发生的各种风险，从而及时做出反应。这将有助于保护工人的安全和健康，并提高生产力和效率。',
        items: ` `
      },
      security: {
        title: '安全',
        description: '立体视觉的RTLS技术在安全方面也有各种应用。例如，可以跟踪物体或人员的移动路径，用于非授权人员的访问控制系统、CCTV监控等。这些技术可以用于建筑出入口和重要设施的安全管理、军事工业等。',
        items: ` `
      },
    }
  }
}


