import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/retailExhibition/video/rtlsVideo.mp4';

export default {

    desc: 'AIoT (Artificial Intelligence of Things) is a concept that combines Artificial Intelligence (AI) and the Internet of Things (IoT), utilizing AI technologies such as machine learning, data analysis, and cognitive technologies to enable IoT devices to interact smartly, learn from each other, and become intelligent.',
    keywords: 'AIoT (Artificial Intelligence of Things), IoT (Internet of Things), Artificial Intelligence Camera, Digital Twin, Digital Transformation, Beacon, RTLS (Real-Time Location System), UWB (Ultra-Wideband), BLE (Bluetooth Low Energy), Orbro, KongTech.',

    ogSite: 'What is AIoT? | Definition, Technologies, Examples, Advantages',
    ogUrl: 'https://orbro.io/en/blog/what-is-aiot',
    ogImg: 'https://lh3.google.com/u/0/d/1beXwJR6Bc3ap2Kb_H7OaW4vPVlq3ovWM=w2000-h7622-iv1',
    ogType: 'website',

    title: 'What is AIoT? | Definition, Technologies, Examples, Advantages',
    date: '2024-05-09',
    tag1: '#AIoT',
    tag2: '#IoT',
    tag3: '#AI',
    tag4: '#ArtificialIntelligenceConvergenceTechnology',

    text: [
        {
            head: 'What is AIoT?',
            subHead: '',
            desc: 'AIoT (Artificial Intelligence of Things) is a concept that combines Artificial Intelligence (AI) and the Internet of Things (IoT). This involves leveraging AI technologies such as machine learning, data analysis, and cognitive technologies to enable IoT devices to interact smartly, learn from each other, and become intelligent. AIoT integrates AI technologies into existing IoT technologies to enable more efficient automation and data-driven decision-making. This enables productivity enhancement and convenient service provision.',
            dot: ''
        },
        {
            head: 'Representative AIoT Technologies',
            subHead: 'Artificial Intelligence (AI)',
            desc: 'AI technologies encompass machine learning, deep learning, natural language processing, and more. These technologies are utilized to analyze data, recognize patterns, and enable smart interactions among IoT devices.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Internet of Things (IoT)',
            desc: 'IoT refers to the technology where various objects are connected to networks to exchange data. Objects such as sensors, devices, and home appliances are connected to the internet to interact with each other and collect, transmit, and process data.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Sensors and Actuators',
            desc: 'Sensors collect data from the surrounding environment, while actuators are devices that affect the external environment. These are essential components of IoT devices.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Cloud Computing',
            desc: 'Cloud services are utilized in AIoT for large-scale data processing and analysis. Cloud-based servers store and process data to provide decision-making capabilities to users.',
            dot: ''
        },
        {
            video: video,
            link: '/en/enterprise/retail/exhibition',
            head: 'AIoT Use Cases',
            subHead: 'Smart Homes',
            desc: 'AI technologies are used to connect various devices within homes to provide a convenient living environment. For example, a smart home hub controls lighting, heating, security systems, and learns user habits to suggest optimal energy consumption methods.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Smart Healthcare',
            desc: 'Wearable devices and sensors are utilized to monitor individuals\' health status and provide personalized healthcare services. This involves collecting data such as heart rate, sleep patterns, and activity levels, and predicting health conditions or detecting diseases early using AI algorithms.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Smart Agriculture',
            desc: 'IoT sensors are used to collect information about soil moisture, temperature, pH, etc., optimizing crop cultivation processes. Farmers can monitor crop status in real-time via smartphone apps and take necessary actions.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Smart Cities',
            desc: 'Connecting various facilities and infrastructures within cities to increase efficiency and enhance citizens\' convenience. For instance, smart lighting adjusts based on environmental conditions, and smart transportation systems monitor traffic in real-time to prevent congestion or provide optimal routes.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Smart Manufacturing',
            desc: 'In manufacturing, IoT sensors and robots automate production lines and monitor production processes. AI algorithms analyze production data to optimize output and predict defects, enhancing production efficiency.',
            dot: ''
        },
        {
            head: 'Advantages of AIoT',
            subHead: 'Intelligent Automation',
            desc: 'AIoT enables smart control and management of IoT devices using AI technologies. This allows for automation and optimization of tasks, enhancing productivity by reducing human intervention.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Data-Driven Decision Making',
            desc: 'AIoT collects and analyzes large-scale data for decision-making. This enables real-time situational awareness and optimal actions.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Predictive Analysis and Maintenance',
            desc: 'AIoT detects signs of system anomalies in advance using sensor data and AI algorithms. This helps prevent equipment failures and optimize maintenance schedules.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Personalized Service Provision',
            desc: 'AIoT analyzes user data to offer personalized services. For example, smart home systems adjust lighting and heating based on user habits and preferences, providing a convenient and safe environment.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Energy Efficiency',
            desc: 'AIoT aids in optimizing energy consumption. By analyzing sensor data and employing AI algorithms, it suggests optimal energy-saving methods, thus enhancing energy efficiency.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Time and Cost Savings',
            desc: 'AIoT saves manpower and time through task automation and optimization. Additionally, predictive analysis helps prevent equipment failures in advance, leading to cost savings.',
            dot: ''
        },
        {
            head: '',
            subHead: '',
            desc: 'AIoT technology combines Artificial Intelligence and the Internet of Things to offer various advantages such as intelligent automation, data-driven decision-making, predictive analysis, and personalized service provision. This enables productivity enhancement, cost reduction, energy efficiency improvement, and fosters innovation and efficiency in various industries and daily life scenarios.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'A product that helps with real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/en/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/en/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/en/enterprise/construction/equipment'
            }
        ]


    }


}
