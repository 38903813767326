import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet : '',
  desc : '현실감 넘치는 그래픽으로 도시의 디지털 트윈을 생성하고, 도시 시각화를 강화합니다. LDO5 수준의 그래픽 표현, 외부 그래픽 데이터 연결, 위경도 기반의 그래픽데이터 지원, 상호작용 가능한 3D 모델, 역사적 및 문화적 유산의 디지털 보존, 도시 계획 및 개발 시뮬레이션을 통해 도시 계획, 디자인, 마케팅에 활용됩니다.',
  keywords : 'Digital Twin, Digital Twins, 디지털 트윈, gis, 디지털 시티, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle : '',
  ogDesc : '',
  ogSite : '디지털 트윈 - 생생한 그래픽 도시 구현 :: 오브로 블로그 ',
  ogUrl : 'https://orbro.io/blog/digitaltwin-digitalcity',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
  
  title: '디지털 트윈 - 생생한 그래픽 도시 구현',
  date : '2023-12-26',
  tag1 : '#디지털트윈',
  tag2 : '#CCTV',
  tag3 : '#디지털시티',
  tag4 : '#가상현실',
  
  // <br/>
  text : [
    {
      head : '디지털 트윈 - 생생한 그래픽 도시 구현',
      subHead :'',
      desc : 'ORBRO는 디지털 트윈 기술을 활용하여 도시의 생동감 넘치는 그래픽을 현실적으로 구현하고, 도시 시각화를 강화합니다. 이를 위해 LDO5 수준의 고해상도 그래픽 표현, 외부 그래픽 데이터 연동, 위경도 기반의 정확한 그래픽 데이터 지원, 상호작용 가능한 3D 모델, 역사와 문화 유산의 디지털 보존, 도시 계획 및 개발 시뮬레이션을 제공합니다.<br/><br/>ORBRO의 고해상도 그래픽 표현은 도시를 사실적으로 시각화하여 사용자에게 몰입감을 제공하고, 외부 그래픽 데이터 연동은 정확하고 다양한 도시 정보를 효과적으로 통합합니다. 또한, 위경도 기반의 그래픽 데이터는 정확한 위치 정보와 지리적 분석을 가능케 합니다.<br/><br/>상호작용 가능한 3D 모델은 사용자가 도시의 다양한 부분을 실시간으로 탐색하고 분석할 수 있도록 도와주며, 역사와 문화 유산의 디지털 보존은 도시의 가치를 보호하고 교육적인 측면을 강화합니다.<br/>뿐만 아니라, 도시 계획 및 개발 시뮬레이션은 효과적인 전략 수립과 개발 결정을 지원하여 도시의 지속 가능한 발전을 도모합니다.',
      dot : ''
    },
    {
      youtube : 'https://www.youtube.com/embed/KkvO62eClcU?si=AQmNMfH6nmQ5Aa3Z',
      head : '',
      subHead :'LDO5 수준의 생생한 그래픽 표현',
      desc : '최고 수준의 디테일과 해상도를 갖춘 그래픽으로 도시의 모습을 현실감 있게 시각화합니다. 이는 도시 계획, 디자인, 마케팅에 활용되며, 가상 현실 및 증강 현실과의 통합을 통해 새로운 사용자 경험을 제공합니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'외부 그래픽 데이터 연결',
      desc: '다양한 외부 소스로부터의 그래픽 데이터를 수집하고 통합하여, 보다 정확하고 풍부한 도시 모델을 제공합니다. 이는 도시의 시각적 표현을 향상시키고, 정확한 도시 분석 및 계획 수립에 기여합니다.',
    },
    {
      head : '',
      subHead :'위경도 기반의 그래픽데이터 지원',
      desc :'위경도 좌표를 기반으로 한 그래픽 데이터를 지원하여, 정확한 위치 정보와 지리적 분석을 가능하게 합니다. 이는 도시 계획, 부동산 개발, 관광 산업 등에 활용됩니다.',
    },
    {
      head : '',
      subHead :'상호작용 가능한 3D 모델 제공',
      desc :'사용자가 상호작용할 수 있는 3D 모델을 제공하여, 도시의 다양한 요소를 실시간으로 탐색하고 분석할 수 있게 합니다. 이는 계획, 교육, 시뮬레이션 등에 활용됩니다.',
    },
    {
      head : '',
      subHead :'역사적 및 문화적 유산 디지털 보존',
      desc :'역사적인 건물이나 문화적 유산을 3D로 디지털화하여 보존합니다. 이는 문화 유산의 보호 및 교육적 가치를 증진시킵니다.',
    },
    {
      head : '',
      subHead :'도시 계획 및 개발 시뮬레이션',
      desc :'실제와 유사한 그래픽을 사용하여 도시 계획 및 개발 시나리오를 시뮬레이션합니다. 이는 효과적인 계획 수립 및 개발 결정에 필수적인 정보를 제공합니다.',
    },

  ],


  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      }
    ]


  }

}