import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet : '',
  desc : '대중교통의 실시간 위치 추적을 통해 승객 경험을 향상시키고, 운영 효율성을 높입니다. 버스와 지하철의 실시간 위치, 정류장 정보 표현, 교통 흐름 분석 및 최적화, 안전 관리 및 모니터링, 앱 및 서비스 통합 등을 제공합니다.',
  keywords : 'Digital Twin, Digital Twins, 디지털 트윈, gis, 디지털 시티, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle : '',
  ogDesc : '',
  ogSite : '디지털 트윈 - 버스 및 지하철 정보 연결 :: 오브로 블로그 ',
  ogUrl : 'https://orbro.io/blog/digitaltwin-traffic',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
  
  title: '디지털 트윈 - 버스 및 지하철 정보 연결',
  date : '2023-12-26',
  tag1 : '#디지털트윈',
  tag2 : '#교통상황',
  tag3 : '#디지털시티',
  tag4 : '#가상현실',
  
  // <br/>
  text : [
    {
      head : '디지털 트윈 - 버스 및 지하철 정보 연결',
      subHead :'',
      desc : 'ORBRO는 대중교통의 실시간 위치 추적을 통해 승객 경험을 향상시키고 운영 효율성을 높이는 서비스를 제공합니다. 이를 위해 버스와 지하철의 실시간 위치 제공, 정류장 정보 표현, 교통 흐름 분석 및 최적화, 안전 관리 및 모니터링, 앱 및 서비스 통합을 제공합니다.<br/><br/>ORBRO의 GPS 및 추적 기술은 버스의 실시간 위치를 정확하게 제공하여 승객들이 대중교통 운행 정보를 실시간으로 파악할 수 있도록 합니다. 지하철의 실시간 위치 모니터링은 승객들에게 정확한 운행 정보를 제공하여 대중교통 이용의 신뢰성을 높입니다.<br/><br/>정류장 정보 제공은 대중교통 이용의 편리성을 높여주며, 교통 흐름 분석 및 최적화는 교통 체증을 감소시키고 승객 대기 시간을 단축하는 데 기여합니다. 또한, 안전 관리 및 모니터링은 승객의 안전을 중시하며 대중교통 시스템의 원활한 운영을 지원합니다.<br/><br/>결과적으로 ORBRO는 대중교통 이용자들에게 더 나은 경험을 제공하고, 동시에 대중교통 시스템의 효율성을 향상시켜 도시 교통 환경을 개선합니다.',
      dot : ''
      
    },
    {
      youtube : 'https://www.youtube.com/embed/tK8f-aMRh-g?si=Q6CVGXUz5ZTjLv-4',
      head : '',
      subHead :'버스의 실시간 위치 추적',
      desc : 'ORBRO는 GPS 및 추적 기술을 사용하여 버스의 실시간 위치를 제공합니다. 이는 승객들에게 정확한 도착 시간 예측을 가능하게 하며, 교통 관리자들에게 운영 효율성을 향상시키는 데 도움을 줍니다.',
      dot :''
    },
    {
      head : '',
      subHead :'지하철의 실시간 위치 모니터링',
      desc : '지하철 시스템 내의 차량 위치를 실시간으로 모니터링하고 표시하여, 승객들에게 정확한 운행 정보를 제공합니다. 이는 운영 효율성을 높이고 비상 상황 시 신속한 대응을 가능하게 합니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'정류장 정보 제공',
      desc: '각 정류장 및 역에 대한 상세한 정보를 디지털 맵에 표시하여, 승객들에게 정류장 위치, 시간표, 서비스 정보를 제공함으로써 대중교통 이용의 편리성을 증진시킵니다.',
    },
    {
      head : '',
      subHead :'교통 흐름 분석 및 최적화',
      desc :'실시간 위치 데이터를 활용하여 교통 흐름을 분석하고 최적화합니다. 이는 교통 체증 감소와 승객 대기 시간 단축에 기여합니다.',
    },
    {
      head : '',
      subHead :'안전 관리 및 모니터링',
      desc :'대중교통 내부의 안전 관리 및 모니터링 시스템을 강화하여, 승객의 안전을 보장하고 운행 중 위험 상황에 신속하게 대응합니다.',
    },
    {
      head : '',
      subHead :'앱 및 서비스 통합',
      desc :'대중교통 정보를 모바일 앱 및 다양한 디지털 서비스와 통합하여, 승객에게 더 나은 정보 접근성과 편의성을 제공합니다.',
    },

  ],


  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      }
    ]


  }

}