import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

   
  helmet : '',
  desc : 'RTLS 기술은 오피스 산업에서 장소 및 자산 관리를 최적화하고 직원의 활동을 모니터링하여 업무 효율성을 향상시킵니다. 또한, 실시간 위치 추적을 통해 회의실 예약, 작업 현황 파악 등 업무 프로세스를 스마트하게 관리할 수 있습니다.',
  keywords : 'RTLS, 근무관리, 근태관리, 근로관리, 스마트오피스, 4차산업혁명, 위치추적, 시간관리, 사업성장',

  ogTitle : '',
  ogDesc : '',
  ogSite : '2023년, 스마트 오피스를 구축하는 방법 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/office',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZq-6QxpQF15gjoXPsuEITf_QOrCQngx-0mGH811amVcoMdPYM3ilqBy7Xoz0830SCxjlpjUuueuzKWtZiEjWuVteCxytua-WdJxpnvVidHfU4TRtkkXlw0xHxxUhNQxvbb5bAwoYWtF7PCka-Od-il6dCHS5WmHz1gttbrgzmv7WLmU9SQYkEs6xvUmw1kB84m4rT91EAavf9ENZ1GzxhJtFsM23wREpJzxhm0kBUbz6YMna9plYIlxFD6SfUcvqI3yduebDgFVrNqNzYuSg4MXNYrgd5besqzBTlYJ9q2D_uLNLNVin8CekS0u6HEWRqm2LjOdw5tZCbsATqf_KZTaVe7bB5zLAJv5WyYdCvS-WJtkrZis6Lr7ks9hAcVUZ6OAnu-oOnotcsNqBM6Slmh6SgL637kMZVCCL2ZRIV4M2zMmtkSgoIpl2EhAmsO_MbEAVPS6hqULxTA2MUhF7VknsXzpP4-gqDtg93jTb6M1jJSVcZMeg7b8eWXjGDAPvZ3DhZ52xs5CsgxNFiHcORnJ3r4L2BhkrUEHxdaIxtNcpYnVaJEaugtx1yYoSJhypYwIMxeuw56xgtDuhwSeXp_M47ijuLYAQH3D_e2y1-ze3pGxLp-ZUNSwEpsIQE75duXj6pGnykP_X86pC_9RvRqtGCauBh03Q5lbXcLi8uZU3feFRPIEBpcuqWSzOvc9OF-gGQQNAJvg_C-XlG7DU5noByHvqhzA_NWYyevnv_GdUqXP86pPL_HXVVFBq9tdJewYtLAEu9moGil4If2TKpgbLU2DqEFZbk53pVq6nNEEuvkIxnmmqT5FRECRIjoycCaKpClyWU6KflQXTh_ajli8XstUOEb9464PjQbEk7hur5AQveRex4wVq8OI5z2_fhrjlcMHuCN8Mi5xDJO1YX2bYT6HuGrN8A2M4qzLvoLqv9qpwf8LN-eddAFPRRxMvxKMEcV4vpwmPsrDe05R40Iwju-ALvmfJgcWEU9W4wFW3uCzbpa7bsF6sM2OYa8xC1DXZpwBcMgXHr1cy68Fgc3-Bz3bmlRBq_DoIxX_B-R-SCQRPmfELhiX_1bX6T21K1tX29iMtlobYwfW0Rm-I9toicT-RbtqAIkwLb9PKEt9Tnbxku321EMkp-qP3ZSVtC1rn5aBH9KG8v5s-FQrNpXvcFgBGJQ11ubG2TJVGND98TOtFmJODYe6lRFuvgA6v8vPcEASZfEZ9Ud5d26C6v-c9piIwRf8jrciHUSEW8heb3vtIngX_afHpGYI_yb63svGjNo9f12aCQxY8HTUu9YwnDCRFXbEiEuvHdqibatUmRWd1Ov15pW7Bfn7ERM721O-P4lkjWurLZ-7IQkd_kod2tNUENcQjT5UCMVuCNgvSmsjHm9jUuSmqjhz_N_E4gfYUImTrGgMJaW1hsQdmlIE3rNGnoSDj4L58a8B7S6f9hFQqRwyqvbeFfXP4Or8aFnxIzM-cJjgL3xcE7IIyza9Q-JN1MuqyK9eC_2nFrT7mx-hMjBW11zCF93GQ4zKO5ILybEfpIEFnZSMRUkkevmWEqohIRPhuI6uS1H2tm_Fg=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
    
    title: '2023년, 스마트 오피스를 구축하는 방법',
    date : '2023-05-21',
    tag1 : '#스마트오피스',
    tag2 : '#RTLS',
    tag3 : '#4차산업혁명',
    tag4 : '#근무관리',
    
    // <br/>
    text : [
      {
        head : '',
        subHead :'',
        desc :'사무실 건물에서 실내 위치추적은 많은 어려움을 야기할 수 있습니다. <br/>사무실, 홀, 층의 수가 많아 질수록  방문객들은 쉽게 길을 잃을 수 있고 건물을 찾는 데 많은 시간을 허비할 수 있습니다.  이러한 상황에서 기업은 좀 더 스마트한 지도를 사용해야 합니다.<br/>최신 기술은 건물과 관련된 편리한 탐색을 보장하고 고객 및 방문자 의 만족도를 향상시킵니다.',
        dot : ''
        
      },
      {
        head : '사무실용 실내 내비게이션',
        subHead :'',
        desc :'실내 지도 도구를 사용하면 사무실 내비게이션 문제를 효과적으로 해결할 수 있습니다. <br/>디지털 지도를 통해 사무실을 빠르게 검색하거나, 행사를 준비하기 위해 회의실을 성공적으로 예약할 수 있습니다.<br/>만약, 해당 회의실이 이미 사용 중이라면 이 역시 실시간을 확인함으로써, 기존에 발생하던 중복예약과 같은 일을 방지합니다. 이는 실수로 생길 수 있는 시간 낭비를 방지합니다.<br/>이 시스템은 건물 내에 설치된 여러 개의 특수 태그 또는 비콘을 사용하는 것을 기반으로 합니다. 이러한 태그는 미리 설정된 간격으로 판독기가 탐지하여 서버로 전송하는 무선 신호를 전송합니다. <br/>애플리케이션이 미리 설치된 스마트폰을 가지고 건물에 들어가는 동안, 사용자는 경로를 구축하고 다른 작업을 수행할 수 있는 대화형 지도를 얻습니다.<br/><br/>BLE, Wi-Fi 및 UWB와 같은 기술을 사용하여 사무실 내부를 탐색할 수 있습니다.<br/>위치를 정의하고 필요한 공간을 찾는 것에서부터 직원의 안전을 보장하고 상세한 분석을 얻는 것에 이르기까지 다양한 기능을 수행하는 데 도움이 됩니다.<br/>한 통계에 따르면, 건물 내부에서 길을 찾는데 어려움이 있을 경우, 그렇지 않은 경우보다 작업 시간 효율이 평균 10~20% 감소합니다. 방문객들과 직원들은 장소를 찾는 데 많은 시간을 허비해야 하는데, 이것은 행사의 지연이나 늦게 시작하는 원인이 됩니다. <br/>실내 위치 매핑은 이러한 문제를 최소화합니다. 비즈니스 센터 인프라에 구현되는 이점은 다음과 같습니다.        ',
        dot : ''
        
      },
      {
        head : '',
        subHead :'실시간 단계별 탐색',
        desc :'관심장소(POI)로 가는 가장 짧고 편리한 경로를 만들 수 있습니다. 또한 사용자의 위치를 기준으로 알림을 보냅니다.<br/>사무실 매핑 소프트웨어는 건물이나 비즈니스 센터 내부의 탐색을 단순화합니다. 대화형 지도를 도입하면 근무 시간을 최적화하고 직원들이 일상적인 업무를 해결하는 데 있어 편안함을 높일 수 있습니다.',
        dot : ''
        
      },
      {
        head : '',
        subHead :'지도에서 개체 검색',
        desc :'대부분의 경우 일반적인 평면도는 정적 이미지처럼 보이지만 대화형 지도는 평면 확장성을 통해 사용자의 가능성을 확장할 수 있습니다.<br/>즉, 전체 층을 볼 수도 있고 별도의 방을 볼 수도 있습니다. 필요한 경우 규모를 변경할 수 있으며 가구 배치를 포함하여 일부 확실한 사무실을 자세히 검토할 수 있습니다.<br/>확장성은 지도를 보다 유연하게 만들고 지도에서 객체를 쉽게 찾을 수 있도록 도와줍니다. 사용자 또는 직원은 필요한 방, 회의장, 화장실 또는 기술실을 찾기 위해 모바일 앱을 사용하기만 하면 됩니다. <br/>경로를 구축할 수 있는 가능성과 결합하여, 어떤 사무실이든 진정한 혁신적인 공간으로 만듭니다.',
        dot : ''
        
      },
      {
        head : '',
        subHead :'방문자와의 사회적 상호작용',
        desc :'많은 사무실에서 가장 흔한 문제 중 하나는 사람들을 찾는 것과 관련된 어려움인데, 특히 그들이 그들의 자리에 없을 경우에 그렇습니다. <br/>위치 공유 기능은 원하는 직원을 찾기에 충분합니다. 매핑 덕분에 사용자는 사무실과 층을 찾아 뛰어다닐 필요가 없습니다. 이 시스템은 사회적 상호 작용을 상당히 단순화하고 시간을 절약합니다.',
        dot : ''
        
      },
      {
        head : '',
        subHead :'제한 구역 모니터링',
        desc :'많은 기업에서 실내 매핑은 제한 구역을 모니터링하는 효과적인 도구가 됩니다. 이러한 장소는 문서, 실험실 및 기타 유사한 전제가 있는 보관소가 될 수 있습니다. <br/>관리자는 실내 지도 기술을 사용하여 누가 이러한 구역에 출입하는지 쉽게 추적하고 무단 출입을 방지할 수 있습니다.<br/>이 시스템은 사람들에게 제한 구역에 접근하는 것에 대해 알릴 수 있습니다. 더 이상 표지판을 세우지 않아도 괜찮습니다. <br/>제한 구역은 응용 프로그램에서 ＇액세스할 수 없음＇으로 표시되며 시스템은 직원에게 해당 구역을 입력하지 말라고 경고합니다',
        dot : ''
        
      },
      {
        head : '',
        subHead :'회의실 점유율 분석',
        desc :'이 플랫폼을 통해 사무실 및 회의장 점유율에 대한 데이터를 얻을 수 있습니다. 시스템은 지속적인 정보 수집을 수행하고 실시간으로 실제 데이터를 제공합니다. <br/>비콘 또는 태그는 작업장에 있는 사용자를 알려주거나 방이 예약되었는지를 표시할 수 있습니다. ',
        dot : ''
        
      },
      {
        head : '',
        subHead :'안전관리',
        desc :'사람들의 적시 대피를 보장하기 위한 비상사태에 대한 정보 제공합니다. 일부 객실은 방음이 잘 되어 있어 직원들이 알람을 듣지 못할 수 있습니다. 하지만 화재와 같은 사고 발생 시, 그들은 모바일 애플리케이션을 사용하여 정보를 얻을 것입니다.<br/>이후 시스템은 해당 장소에서 가까운 직원을 찾아 현장으로 안내해 도움을 주거나 문제를 해결하는 데 도움을 줍니다.<br/>사회적 거리두기. 코로나19 범유행 기간 동안 직원과 방문객은 감염을 피할 수 있습니다. 사람들 사이의 거리를 주의 깊게 모니터링할 수 있기 때문이죠.<br/>사무실 장비가 건물 밖으로 나가는 것을 추적할 수도 있습니다. 장비가 건물을 벗어나면 즉시 이 사실을 알려줍니다.',
        dot : ''
        
      },
      {
        head : '',
        subHead :'',
        desc :'',
        dot : ''
        
      },
    ],
  
    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/enterprise/construction/asset'
        }, 
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/enterprise/construction/safety'
        },
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/enterprise/logistics/asset'
        }
      ]
  
  
    }
  
  
  }