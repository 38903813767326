import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
  
import Scheme from "@src/pages/Main/Common/Scheme/Scheme";
import Faq from "../../../Common/Faq/Faq";

import Func from '../Func/Func';
import rtls from '@src/lang/ko/main/rtls/rtls';
import { CommonState } from '@redux/reducers/commonReducer';
import { useSelector } from 'react-redux';
import SchemeMobile from "../Scheme/SchemeMobile"
import { StyledGridContainer } from '@components/Grid/Grid.style';
import Video from '@pages/Main/Common/Video/Video';
import { BorderBottomLine, SpacingDTM } from '@pages/CommonStyle/common.style';

import Hardware from '../Hardware/Hardware';
import { DisplayH2 } from '@components/Text/HeadingTagDisplay.style';
import { StyledControlPage } from '@pages/Main/Access/components/Main/Main.style';
import { StyledAppTextBox, StyledDesc } from '../../Banner/Banner.style';
import Functions from '@pages/Main/Common/Functions/Functions';
import Lineup from '@pages/Main/Common/Lineup/Lineup';

const Main = ({handleIconClick}): React.ReactElement => {

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const handleIconClickLocal = (group) => {
    handleIconClick(group);
  };

  const faqItems = useMemo(() => rtls.FAQ , []);



  return (
    <>

    <Video pageName={'rtls'}/>
    

    <Functions data={rtls} page={'rtls'}/>
    <Hardware/>

    <StyledControlPage>
      <StyledAppTextBox>
        <DisplayH2 size={'small'}>
          <Trans i18nKey={'simple.product.title'} />
        </DisplayH2>
        <StyledDesc size={'medium'}>
          <p>
            <Trans i18nKey={"simple.product.rtls"} />
          </p>
        </StyledDesc>
      </StyledAppTextBox>
    </StyledControlPage>

    <Lineup handleIconClickLocal={handleIconClickLocal} product={'rtls'}/>

    <StyledGridContainer>
      <BorderBottomLine/>
    </StyledGridContainer>
    
    {layoutType === 'mobile' ?
      (<SchemeMobile handleIconClickLocal={handleIconClickLocal}/>)
      : (<Scheme handleIconClickLocal={handleIconClickLocal} product={'rtls'}/>)
    }


    <Func/>
    
    <SpacingDTM d={'128'} t={'128'} m={'128'}/>
    <Faq items={faqItems}  path={'rtls'}/>
    
    </>
  );
};

export default Main;
