import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {

  helmet: '| RFID 替代 RTLS',
  desc: '为了克服RFID技术的局限，使用RTLS（实时定位系统）技术可以提供更现代和有效的解决方案。立即了解Orbro的技术。',
  keywords: 'rfid, rtls, uwb, 无线通信, 位置跟踪, 物体位置跟踪, 人员位置跟踪, 标签, 信标, rtls, orbro, 奥博罗, 康泰克',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RFID 替代 RTLS :: 奥博罗博客',
  ogUrl: 'https://orbro.io/cn/blog/rfid-rtls',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDydFqsSpxfUGJRfgotjd5r5s27p9Jf2p08DcmZQY7K9VFxeg9rqDdYY9Gty2zcbPdkTtDG8IT8VHVI8Cu9Xay4Y9sNkWKyhNf5MZZfEJmSwx5RxDXIBhNLiVFBCl6asQi7XkVXIwVpqgVKugt94uXMpRmAUIUCYNCbhGMRV0zD7wJHpv6g4sEO3b3FkYBmPo0wrorAZ2UZIwYz4wRbFewYqqUgH0P6BidU5KE0kQsn2HGiIRRQlmCsPEY3rI0XKSGLatvI5vRo7-7c8DOoNlcOIpsMLXxaBYF3suQGFhdxrqEhA8t5mY3vsnWX2JOTgDdhEae70uPkPmf6IqEUhYr4i-kDTR-rCIjoikpAUHb2w5BUtdC3vMMJ3K8zZ2WbXTUDfkeCyjCN8C95-hMsUQX_dK52Yb2k6kTPrFuJWsiL7OSFsXX7TyjBajZX3yx1DfjD2OitZ7pZZSD6HV37MrXk0sykUNnUTbjSdW3NNVWoIBs0F4ur3TY23NSm8UKCWdXDvmlGsQrur-inPNF8C0iYeb2fEkwmH-WPv92-_V2JM5Q7t--49WhtDqsZHE5jlndPD7lFWwQa2qfEKhOExytxc3Vxt4LvM9BWSz3eR4p0jQt8OX3pXvRFcoc6wZEGANIoVWjvXj8HyvYZaYE56ckP2tHBQoruYpNVs4AfP8_ECNMPz4tfxLmvLQF9XYGOu_QZAFxMshOsDsJmAFOiRvKRKtNYQzCaJFgZCWibWTWvkbo52R_irSOgQZqIahJbvebkjVJmY8s9HJ0ouwoM7hApeuHgaFpMQ8gN1ov_4CqlBoa_pGCFaZvMOiJzqLIImEuRDrGWGOb9XuHS6dI22h-k3C5498w8ybVmF45VfA76In3QlmWtMx7ua2zT_bsj1AWfMJWaglRKkImhInZNRwMZFSSifk4ept8JyDKQox_XnxyJkVdojTLFKruj3z_uJ6M8nVcbIspvyz1QggC63Fp3acz35N2EXmQ0J8JZeWUnyLrhuwfJ-Wc6Ovja6LEF9gbjgAoesYWEghHYi3ByOzsEcFhHsQM9cQ1UgZUrRg_nf3Ic1V49R3ewe6J5HmaIqXADdHXQ4fWuZjZ4RpRe9ffQThkcH9PwHGFH5eRvgMLO2Ey8q_PdaHnN-1B2tUJ7IWkt2GMllFbOnfC2F7Z7yhNLOWss-MAXrtBW702-RreLR8GFAlMB7UnW3xrKC7GJCnJqJQfL913t9SZJyXm_52svc-p4d0UlQvuYUN7N0AA34STZtooGf9cSNITDeb62jWztMNJFGTw5YVTHqdsVttLICWXOO1_Q8_ygXfLtKu_YvfGDfhZqIqyMgk8OcDk1rYzMYok0P0j6grBuhSs8IyoPctYZddBCbfjze8PiLcDu22_gIz6biHHDr1-R4WtPNoyWavEKXnWNLMRAnSxy67bG83C5wbqYWv4pDA8mTGd1QfYqxLh4e2a4TXm6vxN0SOt_CJZSEa6cO64dcsuX74_Y12xT2AtRXErifMIoUyB0JFZCUbLPWq1jd20L_J22kFD6e-uaGmBSUQH_gZsMmYuS9Sf82buto=w2087-h1196',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RFID 替代 RTLS',
  date: '2023-11-28',
  tag1: '#rfid',
  tag2: '#rtls',
  tag3: '#uwb',
  tag4: '#无线通信',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: 'RFID技术已经在物流和资产跟踪领域有效应用了数年。然而，最近，RFID的替代品RTLS（实时定位系统）技术在物流和资产管理领域引起了关注。本文探讨了RTLS如何在超越传统方法方面带来创新，代替了RFID。',
      dot: ''
    },
    {
      head: 'RTLS的概念和工作原理',
      subHead: '',
      desc: 'RFID通过物理标签或标签识别物体，仅在特定范围内收集信息。相比之下，RTLS是一种结合无线通信和位置跟踪技术的系统，可以实时追踪物体的位置。利用GPS、传感器、信标等，RTLS提供精确的位置信息，在物流和资产管理中表现出色。',
      dot: ''
    },
    {
      video: video,
      link: '/enterprise/manufacturing/asset',
      head: 'RTLS的优势',
      subHead: '实时位置追踪',
      desc: 'RTLS可以准确追踪物体的实时位置，使物流管理实现实时跟踪。这优化了产品的移动路径，提高了物流操作的效率。',
      dot: ''
    },
    {
      head: '',
      subHead: '提供准确的数据',
      desc: '由于RFID只能在特定范围内收集信息，其准确性受到限制。然而，RTLS与传感器结合，提供高精度的位置信息，因此在物流和资产管理中可以获得精确的数据。',
      dot: ''
    },
    {
      head: '选择哪种技术？',
      subHead: '多样化的应用领域',
      desc: 'RTLS不仅适用于物流，还适用于医疗、制造、运输等多个行业。这意味着公司可以将一种技术应用于多个领域，最大限度地发挥其效益。',
      dot: ''
    },
    {
      head: '成功案例和业务影响',
      subHead: '',
      desc: '全球范围内引入RTLS的公司实现了高生产力和高效率。例如，在医疗领域，使用RTLS追踪医疗设备和患者位置，实现了紧急情况的快速响应；在物流领域，通过优化产品的移动路径，实现了成本节省。',
      dot: ''
    },
    {
      head: '未来展望',
      subHead: '',
      desc: '尽管RFID在以前在复杂环境中也表现出色，但RTLS的出现使物流和资产管理变得更加复杂和高效。未来，我们可以期待RTLS系统与人工智能的结合，以及区块链技术的采用等进一步的演进。公司应积极采用这些技术，以确保在未来市场上具备竞争力。',
      dot: ''
    },
    {
      head: '结论',
      subHead: '',
      desc: 'RTLS之所以引起关注，是因为它能够进行实时位置追踪并提供准确的数据，使物流和资产管理更加高效。公司应积极采用创新技术，为更美好的未来做好准备。从RFID到RTLS的过渡是现代企业通过新技术迈出的一步。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '通过将UWB终端安装到工人或设备上，支持实时位置追踪的产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/cn/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/cn/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/cn/enterprise/office/asset'
      }
    ]
  }
}
