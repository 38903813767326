import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';

import Faq from "../../../Common/Faq/Faq";
import Func from '../Func/Func';
import { CommonState } from '@redux/reducers/commonReducer';
import { useSelector } from 'react-redux';
import SchemeMobile from "../Scheme/SchemeMobile"
import { StyledGridContainer } from '@components/Grid/Grid.style';
import camera from '@src/lang/ko/main/camera/camera';
import { BorderBottomLine } from '@pages/CommonStyle/common.style';
import Scheme from '@pages/Main/Common/Scheme/Scheme';
import { DisplayH2 } from '@components/Text/HeadingTagDisplay.style';
import { StyledControlPage } from '@pages/Main/Access/components/Main/Main.style';
import { StyledAppTextBox, StyledDesc } from '@pages/Main/Rtls/Banner/Banner.style';
import Functions from '@pages/Main/Common/Functions/Functions';
import Lineup from '@pages/Main/Common/Lineup/Lineup';
import Video from '@pages/Main/Common/Video/Video';

export interface HomeNewsItem {
  title: string;
  link: string;
  image: string;
  date: string;
}

const Main = ({ handleIconClick }): React.ReactElement => {

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const handleIconClickLocal = (group) => {
    handleIconClick(group);
  };

  const faqItems = useMemo(() => camera.FAQ, []);



  return (
    <>

      <Video pageName={'camera'}/>
      
      <Functions data={camera} page={'camera'}/>
      <StyledControlPage>
        <StyledAppTextBox>
          <DisplayH2 size={'small'}>
            <Trans i18nKey={'simple.product.title'} />
          </DisplayH2>
          <StyledDesc size={'medium'}>
            <p>
              <Trans i18nKey={"simple.product.camera"} />
            </p>
          </StyledDesc>
        </StyledAppTextBox>


      </StyledControlPage>

      <Lineup handleIconClickLocal={handleIconClickLocal} product={'camera'} />

      <StyledGridContainer>
        <BorderBottomLine />
      </StyledGridContainer>


      {layoutType === 'mobile' ?
        (<SchemeMobile handleIconClickLocal={handleIconClickLocal} />)
        : (<Scheme handleIconClickLocal={handleIconClickLocal} product={'camera'} />)
      }


      <Func />

      <Faq items={faqItems} path={'camera'} />

    </>
  );
};

export default Main;
