import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {

  helmet: '| RTLS instead of RFID',
  desc: 'To overcome the limitations of RFID technology, using RTLS (Real-Time Location System) can provide a more modern and effective solution. Explore the advancements in technology with Orbro today.',
  keywords: 'rfid, rtls, uwb, wireless communication, location tracking, object location tracking, people location tracking, tag, beacon, rtls, orbro, 오브로, 콩테크',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RTLS instead of RFID :: Orbro Blog',
  ogUrl: 'https://orbro.io/blog/rfid-rtls',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDydFqsSpxfUGJRfgotjd5r5s27p9Jf2p08DcmZQY7K9VFxeg9rqDdYY9Gty2zcbPdkTtDG8IT8VHVI8Cu9Xay4Y9sNkWKyhNf5MZZfEJmSwx5RxDXIBhNLiVFBCl6asQi7XkVXIwVpqgVKugt94uXMpRmAUIUCYNCbhGMRV0zD7wJHpv6g4sEO3b3FkYBmPo0wrorAZ2UZIwYz4wRbFewYqqUgH0P6BidU5KE0kQsn2HGiIRRQlmCsPEY3rI0XKSGLatvI5vRo7-7c8DOoNlcOIpsMLXxaBYF3suQGFhdxrqEhA8t5mY3vsnWX2JOTgDdhEae70uPkPmf6IqEUhYr4i-kDTR-rCIjoikpAUHb2w5BUtdC3vMMJ3K8zZ2WbXTUDfkeCyjCN8C95-hMsUQX_dK52Yb2k6kTPrFuJWsiL7OSFsXX7TyjBajZX3yx1DfjD2OitZ7pZZSD6HV37MrXk0sykUNnUTbjSdW3NNVWoIBs0F4ur3TY23NSm8UKCWdXDvmlGsQrur-inPNF8C0iYeb2fEkwmH-WPv92-_V2JM5Q7t--49WhtDqsZHE5jlndPD7lFWwQa2qfEKhOExytxc3Vxt4LvM9BWSz3eR4p0jQt8OX3pXvRFcoc6wZEGANIoVWjvXj8HyvYZaYE56ckP2tHBQoruYpNVs4AfP8_ECNMPz4tfxLmvLQF9XYGOu_QZAFxMshOsDsJmAFOiRvKRKtNYQzCaJFgZCWibWTWvkbo52R_irSOgQZqIahJbvebkjVJmY8s9HJ0ouwoM7hApeuHgaFpMQ8gN1ov_4CqlBoa_pGCFaZvMOiJzqLIImEuRDrGWGOb9XuHS6dI22h-k3C5498w8ybVmF45VfA76In3QlmWtMx7ua2zT_bsj1AWfMJWaglRKkImhInZNRwMZFSSifk4ept8JyDKQox_XnxyJkVdojTLFKruj3z_uJ6M8nVcbIspvyz1QggC63Fp3acz35N2EXmQ0J8JZeWUnyLrhuwfJ-Wc6Ovja6LEF9gbjgAoesYWEghHYi3ByOzsEcFhHsQM9cQ1UgZUrRg_nf3Ic1V49R3ewe6J5HmaIqXADdHXQ4fWuZjZ4RpRe9ffQThkcH9PwHGFH5eRvgMLO2Ey8q_PdaHnN-1B2tUJ7IWkt2GMllFbOnfC2F7Z7yhNLOWss-MAXrtBW702-RreLR8GFAlMB7UnW3xrKC7GJCnJqJQfL913t9SZJyXm_52svc-p4d0UlQvuYUN7N0AA34STZtooGf9cSNITDeb62jWztMNJFGTw5YVTHqdsVttLICWXOO1_Q8_ygXfLtKu_YvfGDfhZqIqyMgk8OcDk1rYzMYok0P0j6grBuhSs8IyoPctYZddBCbfjze8PiLcDu22_gIz6biHHDr1-R4WtPNoyWavEKXnWNLMRAnSxy67bG83C5wbqYWv4pDA8mTGd1QfYqxLh4e2a4TXm6vxN0SOt_CJZSEa6cO64dcsuX74_Y12xT2AtRXErifMIoUyB0JFZCUbLPWq1jd20L_J22kFD6e-uaGmBSUQH_gZsMmYuS9Sf82buto=w2087-h1196',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RTLS instead of RFID',
  date: '2023-11-28',
  tag1: '#rfid',
  tag2: '#rtls',
  tag3: '#uwb',
  tag4: '#location',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: 'RFID technology has been effectively utilized in the fields of logistics and asset tracking for several years. However, recently, RTLS (Real-Time Location System) technology has gained attention in the logistics and asset management sectors as an alternative to RFID. This post explores how RTLS, instead of RFID, is bringing innovation beyond traditional methods.',
      dot: ''
    },
    {
      head: 'Concept and Operation Principles of RTLS',
      subHead: '',
      desc: 'RFID identifies objects through physical labels or tags, collecting information within a specific range. In contrast, RTLS is a system that combines wireless communication and location tracking technology to track the real-time location of objects. Utilizing GPS, sensors, beacons, and more, RTLS provides accurate location information, demonstrating excellent performance in logistics and asset management.',
      dot: ''
    },
    {
      video: video,
      link: '/enterprise/manufacturing/asset',
      head: 'Advantages of RTLS',
      subHead: 'Real-Time Location Tracking',
      desc: `RTLS can accurately track the real-time location of objects, enabling real-time tracking in logistics management. This optimizes the product's movement path and enhances the efficiency of logistics operations.`,
      dot: ''
    },
    {
      head: '',
      subHead: 'Accurate Data Provision',
      desc: 'RFID is limited in accuracy as it can only collect information within a specific range. However, RTLS, combined with sensors, provides high-precision location information, allowing precise data acquisition in logistics and asset management.',
      dot: ''
    },
    {
      head: 'Which Technology to Choose?',
      subHead: 'Diverse Application Fields',
      desc: 'RTLS is applicable not only in logistics but also in various industries such as healthcare, manufacturing, and transportation. This means that companies can apply one technology to multiple areas, maximizing its effectiveness.',
      dot: ''
    },
    {
      head: 'Success Stories and Business Effects',
      subHead: '',
      desc: 'Companies globally that have implemented RTLS are achieving high productivity and efficiency. For example, in healthcare, RTLS is used to track medical devices and patient locations in real-time, enabling rapid responses in emergency situations. In logistics, optimizing the movement path of products has led to cost savings.',
      dot: ''
    },
    {
      head: 'Future Outlook',
      subHead: '',
      desc: 'While RFID has been effective in challenging environments, the emergence of RTLS has made logistics and asset management even more sophisticated and efficient. In the future, we can expect further evolution of RTLS systems with the integration of artificial intelligence and the adoption of blockchain technology. Companies should actively embrace such technologies to secure competitiveness in the future market.',
      dot: ''
    },
    {
      head: 'Conclusion',
      subHead: '',
      desc: 'The reason RTLS is gaining attention over RFID is due to its capability for real-time location tracking and precise data provision, making logistics and asset management more efficient. Companies should proactively adopt innovative technologies to prepare for a better future. The transition from RFID to RTLS is a step forward for modern enterprises preparing for a brighter future through new technologies.',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that assists real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/enterprise/office/asset'
      }
    ]
  }
}
