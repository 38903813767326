import {
    access2off, access3off, access4off,
    access2on, access3on, access4on,
} from '@assets/images/main/index';

import { BlueIconPhone, BlueIconRFID, BlueIconRemote, BlueIconWatch, BlueIconWidget } from "@assets/images/icon/blue";
import {
    AdvantageImgD, AdvantageImgM, AdvantageImgT,
    Falldown1D, Falldown1M, Falldown1T,
    Falldown2D, Falldown2M, Falldown2T,
    Falldown3D, Falldown3M, Falldown3T,

    installationImg1D, installationImg1M, installationImg1T,
    installationImg2D, installationImg2M, installationImg2T,
    installationImg3D, installationImg3M, installationImg3T,
    installationImg4D, installationImg4M, installationImg4T,
    installationImg5D, installationImg5M, installationImg5T,

    hardwareImg1D, hardwareImg1M, hardwareImg1T,
    hardwareImg2D, hardwareImg2M, hardwareImg2T,
    hardwareImg3D, hardwareImg3M, hardwareImg3T,
    hardwareImg4D, hardwareImg4M, hardwareImg4T,
    hardwareImg5D, hardwareImg5M, hardwareImg5T,
} from "@assets/images/main/access/components/index";
import { IconOsOff, IconOsOn } from '@assets/images/main/icon';

export default {


    banner: {
        title: '출입 시스템의 혁신',
        desc: 'Orbro의 출입시스템 도입을 통해 여러분의 출입 시스템을 혁신하세요.',

        schemeTitle: '당신을 위한 출입 시스템을 선택하세요.',
        schemeDesc: '결정하는데 도움이 필요하신가요? ',
        schemeDesc2: '로 연락 주시면<br/>출입 전문가가 도와드리도록 하겠습니다.',
        phone: '1522-9928',
    },

    mainPage: {
        product: {
            title: '제품 구성 알아보기',
            desc: '혁신적인 출입시스템 제품 구성을 확인 해 보세요.',

            auto: {
                title: '자동문 출입',
                desc: '자동문에 특화된 출입 시스템의 시작<br/>모든 출입문 적용 가능',

            },
            face: {
                title: '얼굴 인식 출입',
                desc: '안면인식, 모바일출입, NFC카드 등<br/>모든 출입이 가능한 방법',

            },
            accessory: {
                title: '출입 액세서리',
                desc: '출입 시스템과 관련된<br/>모든 제품을 알아 보기',

            }
        }

    },


    FAQ: [
        {
            title: 'Orbro Access의 출입 방식은 어떻게 되나요 ?',
            description: 'Orbro Access의 출입 방식은 모바일 어플리케이션을 통한 출입입니다.<br/>사용자는 모바일 어플리케이션을 설치하고, 권한을 받은 후에는 해당 어플리케이션을 사용하여 출입할 수 있습니다.<br/><br/>모바일 어플리케이션을 통한 출입은 사용자의 스마트폰을 이용하여 출입문을 개폐할 수 있게 해줍니다. 어플리케이션은 사용자의 신원을 확인하고 출입 권한을 인증하는 역할을 수행합니다. 이를 통해 사용자는 휴대폰을 이용하여 편리하게 이용하여 출입을 간편하게 경험할 수 있습니다.<br/><br/>'
        },
        {

            title: '자동문이 없는 환경에서도 사용이 가능한가요?',
            description: '네, Orbro Access는 자동문이 없는 환경에서도 사용이 가능합니다.<br/>다만, 자동문이 없는 경우 출입문에 데드볼트 또는 EM Lock과 같은 잠금장치를 설치해야 합니다. 이 잠금장치는 출입문을 안전하게 잠그고 열 수 있게 도와줍니다.<br/><br/>Orbro Access 출입 단말기는 이러한 잠금장치를 제어하여 출입문의 잠금을 해제할 수 있습니다. 출입 단말기는 사용자의 인증정보를 받아들이고, 올바른 인증을 받은 경우에만 잠금장치를 해제하여 출입을 허용합니다. 이러한 방식으로 Orbro Access를 활용하면 자동문에 없는 환경에서도 출입 관리 시스템을 운영 할 수 있습니다. 출입 단말기와 잠금장치의 연동을 통해 출입문의 보안을 강화하고, 인증된 사용자만이 출입할 수 있도록 제어할 수 있습니다.<br/><br/>',
        },
        {

            title: '휴대폰 없이 출입이 가능한가요?',
            description: '네, Orbro Access는 휴대폰 없이도 출입이 가능합니다.<br/><br/>휴대폰 없이 출입을 하기 위해서는 Orbro Access Key라는 별도의 악세서리를 사용해야 합니다. Orbro Access Key는 사용자의 정보를 포함해 송신하는 장비로, 휴대폰이 없는 사람도 출입 인증을 받을 수 있도록 해줍니다. 이를 통해 휴대폰이 없는 경우에도 Orbro Access를 통해 출입 제어를 원활하게 수행할 수 있습니다. <br/><br/>',
        },
        {

            title: 'Wi-Fi 및 인터넷 설치가 불가능 환경에서도 사용이 가능한가요?',
            description: '네, Orbro Access는 환경에 따라 다양한 시스템 구성을 제공하여 인터넷 설치가 불가능한 환경에서도 출입이 가능합니다.<br/><br/>이를 위해 사용자는 자신의 스마트폰을 활용하여 출입 절차를 수행할 수 있습니다. 사용자의 스마트폰을 매개로 하여 서버로부터 출입 권한을 받아오고, 이를 기반으로 출입을 할 수 있습니다. 이렇게 함으로써 Wi-Fi 및 인터넷 설치가 어려운 환경에서도 Orbro Access를 효과적으로 이용할 수 있습니다.<br/><br/>',
        }
    ],


    bannerData: [
        {
            icon: access2on,
            iconInv: access2off,
            image: '',
            iconText: '자동문 출입',
            titleKey: '자동문에 특화된 출입 시스템의 시작<br/>모든 출입문 적용 가능',
            descKey: 'access.banner.desc',
            sub: '',
            link: '/access/auto'
        },
        {
            icon: access3on,
            iconInv: access3off,
            image: '',
            iconText: '얼굴 인식 출입',
            titleKey: '안면인식, 모바일출입, NFC카드 등<br/>모든 출입이 가능한 방법',
            descKey: 'access.banner.desc',
            sub: 'New',
            link: '/access/face'
        },
        {
            icon: access4on,
            iconInv: access4off,
            image: '',
            iconText: '출입 액세서리',
            titleKey: '출입 시스템과 관련된<br/>모든 제품을 알아 보기',
            descKey: 'access.banner.desc',
            sub: '',
            link: '/access/accessory'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/os'
        },
        // Add more objects if there are more groups
    ],




    falldown: {
        left: {
            title: '출입 방식을 스마트폰에 통합,<br/>가장 현대적인 출입 시스템',
            desc: '일반문에서부터 자동문, 스피드게이트까지 현대사회의 다양한 출입문을 스마트폰으로 간편하게 이용할 수 있습니다. 우리의 일상과 가장 밀접한 시스템을 이용하여 가장 효과적인 디지털 전환을 경험하세요.',

            fall: [
                {
                    title: '스마트폰 출입',
                    desc: 'iOS/Android 모든 스마트폰을통해 출입이 가능합니다.',
                    img: {
                        desktop: Falldown1D,
                        tablet: Falldown1T,
                        mobile: Falldown1M
                    }
                },
                {
                    title: '스마트 워치 출입',
                    desc: '스마트 워치를 통해 더 편리한 출입 경험이 가능합니다.',
                    img: {
                        desktop: Falldown2D,
                        tablet: Falldown2T,
                        mobile: Falldown2M
                    }
                },
                {
                    title: 'RFID 카드 출입',
                    desc: '기존의 사원증 카드를 활용하여 출입이 가능합니다.',
                    img: {
                        desktop: Falldown3D,
                        tablet: Falldown3T,
                        mobile: Falldown3M
                    }
                },
            ]
        },

        rightImg: ''
    },

    functions: {
        title: '핵심은 하드웨어,<br/>모든 것을 가볍게 혁신.',
        desc :'',
        line : 2,
        card: [
            {
                img: {
                    desktop: hardwareImg1D,
                    tablet: hardwareImg1T,
                    mobile: hardwareImg1M
                },
                tag: '상태 LED',
                title: 'NFC/Bluetooth<br/>듀얼채널 방식의 출입',
                desc: 'NFC카드 출입은 물론,<br/>모바일 출입, 스마트 워치 출입까지 가능합니다.',
                size : '3',
            },
            {
                img: {
                    desktop: hardwareImg2D,
                    tablet: hardwareImg2T,
                    mobile: hardwareImg2M
                },
                tag: '전원 공급',
                title: 'PoE 사용으로,<br/>통신과 전원이 통합',
                size : '1',
            },
            {
                img: {
                    desktop: hardwareImg3D,
                    tablet: hardwareImg3T,
                    mobile: hardwareImg3M
                },
                tag: '간편한 설치',
                title: '전용 브라켓으로<br/>간편한 설치',
                size : '2',
            },
            {
                img: {
                    desktop: hardwareImg4D,
                    tablet: hardwareImg4T,
                    mobile: hardwareImg4M
                },
                tag: '스피커',
                title: '스피커를 통한<br/>출입 알림',
                size : '2',
            },
            {
                color: '#000',
                img: {
                    desktop: hardwareImg5D,
                    tablet: hardwareImg5T,
                    mobile: hardwareImg5M
                },
                tag: '상태 LED',
                darkTitle: '어두운밤에도<br/>',
                lightTitle: '상태확인 ',
                darkTitle2: '가능',
                desc: 'RGB LED 제공을통해<br/>기기의 상태, 출입 확인 등이 가능합니다.',
                size : '2',
            },
        ]

    },


    installation : {
        title : '제품설치, 걱정마세요.<br/>저희가 알아서 다해드려요.',
        card : [
            {  
                img : {
                    desktop : installationImg1D,
                    tablet : installationImg1T,
                    mobile : installationImg1M
                },
                tag : '온프레미스 서버제공',
                title : '엔터프라이즈급<br/>서버 제공',
            },
            {  
                img : {
                    desktop : installationImg2D,
                    tablet : installationImg2T,
                    mobile : installationImg2M
                },
                tag : '전문가 현장 설치 지원',
                title : '1522-9928로 연락주시면<br/>가장 빠른 날짜로 설치 가능',
                desc : '평일 오전 9 ~ 오후 6시',
            },
            {  
                img : {
                    desktop : installationImg3D,
                    tablet : installationImg3T,
                    mobile : installationImg3M
                },
                tag : '출입 액세서리',
                title : '데드볼트 시공 가능',
            },
            {  
                img : {
                    desktop : installationImg4D,
                    tablet : installationImg4T,
                    mobile : installationImg4M
                },
                tag : '출입 액세서리',
                title : 'EM Lock 시공 가능',
            },
            {  
                theme : 'black',
                img : {
                    desktop : installationImg5D,
                    tablet : installationImg5T,
                    mobile : installationImg5M
                },
                tag : '출입 액세서리',
                title : '일렉트릭 스트라이크 시공 가능',
            },
        ]

    },

    advantage: {
        img: {
            desktop: AdvantageImgD,
            tablet: AdvantageImgT,
            mobile: AdvantageImgM,
        },
        title: '오브로가<br/>기존 출입 시스템과 다른점',
        desc: '현대 사회에 변화하고 있는 다양한 출입방식을 하나의 디바이스에서 제공하는, 가장 현대적인 출입 단말기 입니다. 전통적인 출입방식에서부터 최신 스마트 기술까지 다양한 방식으로 출입을 관리하며, 사용자들에게 편리하고 안전한 출입 환경을 제공합니다.',
        card: [
            {
                icon: BlueIconPhone,
                title: '스마트폰을 이용한 출입',
                desc: 'iOS / Android 앱을 이용하여 출입문을 제어 할 수 있습니다.',
            },
            {
                icon: BlueIconWidget,
                title: '위젯 제공',
                desc: '위젯은 모바일 앱을 더 편하게 사용하기 위한 방법입니다.',
            },
            {
                icon: BlueIconWatch,
                title: '스마트 워치를 이용한 출입',
                desc: '스마트 워치를 이용하여 편리한 출입 방식을 경험하세요.',
            },
            {
                icon: BlueIconRemote,
                title: '원격 제어',
                desc: '관리자는 원격에서 출입문을 관리할 수 있습니다.',
            },
            {
                icon: BlueIconRFID,
                title: 'RFID & NFC 카드',
                desc: 'RFID or NFC Tag를 태깅하여 간편하게 출입문을 제어할 수 있습니다.',
            },
        ]

    },




}