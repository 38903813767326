import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

    
  helmet : '| 현장을 뒤흔드는 기술, 중장비 스마트 관리의 혁신',
  desc : '실시간 위치 추적 시스템(RTLS) 기술을 적용한 중장비 관리 솔루션은 건설현장의 운영 및 안전성을 혁신적으로 향상시키고 있습니다. 이를 통해 작업 일정 최적화, 자산 보호, 안전한 작업 환경 조성, 환경 친화성 증진, 비용 절감 등 다양한 이점을 얻을 수 있습니다.',
  keywords : '스마트 중장비 관리, RTLS 기술, 건설현장 혁신, 중장비 위치 추적, 중장비 보호, 건설현장 안전성, 중장비 자동화, RTLS 솔루션, 중장비 효율화, 실시간 위치 추적, 건설산업 기술 혁신, 중장비 데이터 분석, 환경 친화적 건설, 건설현장 비용 절감, 중장비 관리 시스템, 미래 건설 산업, 중장비 유지보수, 건설현장 스마트 솔루션, 중장비 안전 조치, rtls, orbro, 오브로, 콩테크',

  ogTitle : '',
  ogDesc : '',
  ogSite : '현장을 뒤흔드는 기술, 중장비 스마트 관리의 혁신 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/heavyEquipment',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDzGmkjkBoP6Xvw8I-rO2ghVp5PqT4G-TOxRZma9x_Alu5navIMlFCn09CKOn81H_Madb-IgbiyiSNy0fEz9PhBPlRbc4NuZIKYHZj06eaqjP7RSKmVw0WhGazP-pzOcpYeCHaToxFMOSXbhipZqKw3T89KUknQiF7z_dhxCgvtwJE1dL27fvS_Ux57UHXUtvl1yiQcS_SQlzL7JwSBg9NoGXqPzzLivQt203pHDtTo0FUNEkkp4ILcoK9jRwzMd6_V0_PZNuKAXYZEcdeZZ7zo7ry82rcT59_D6R41M5GU_XphFwzhYGe38An9N16es0rCgbhvoP2oa8-aOPDswbWK-EAGINoE44O4TUgmG2KRK-tcqpUsNhUu8fau_ZlH_YLSc9gGpv41zNj2Uc-JHSwC074gaimJdd3MAMZlTeDTgyOQDI7OEodIXoFmzzvplifLlBzUpkHxAbA7Chn7YpTus-ZM8OxmGBL2QQuRsApAVQLnHHxEmRZpMUMcRskj0vGevc85DiZukbBv92q6BocAt9lmrz7fCtT6UWFo0Dch3wPd-mxS_vELPx9itBdEhoGU1M0SI-urofonB6RXcgj3VODFNXvmKnUe7MbJCJmSWTz4M3S175wRj8DHoLLYOPbD0M5YJEkTmSiS4aM5mFngNyxx5szGgSIBMp-AY-lzTr1M-1JnEUuC_2oQQT88ax_eS6VXIr-KjlSj8XrAJ-2RwKEGJlCo4i8b5FABD0Vqeg5soicxR_tOy0xkq4W8F8BaEfVtapquvKNAB8zs8lmzXgLUVOYzpqyVjw9_1vvfirMHsCYZZ-J8H4Tx6dRBSoymoWsOinzjd2QV8pv9yiHvCBQMyesmVjWNFZAKUM4xkmIVR9DFmkutgA0zV6VnGwrnMpAzo94Tt1stWAxVdb4R7U7HLpwwMlga6xU5yD2ESMRa13LVj8zrE5JhRmTk8ZOBfqlkrhTNMCLU_HO5YZ3yhttXl1TgbEtmR1ojfgduZMA0WwBpellXtuyIPhMZEK8wykwfrW2i4zq3V5NosBaamzVFwAy0AIJxIuf2P9IG61SM-QSm-4I_kz-KKGUhOtJcaDI7G38qHvGLJU2Ye1AxPX9POfdnfY2RhYoBIxHWdYy9UvZZDI_dgT_NaVsDRaMMaBqYEWlmr1OHV3lBx-BaxmlKUQ_RmMGJApPB-6xN2j5L1h1vKnml06CuPUOJ9CvKGrVZCy38o_KF6O6KXXqDuCE-9wdHvE3cfjefcAv298ML2Sj2b25ugq0KbO96LKoRz61WklczBoCAouwaFpenu7xf7lCIfXVskkq8AC8UaHtC1kVooqQfyOLLxG6B1skFGcUudDX7TQY2IDywQ-213Y8BUgP1BXa0mlj2fHovr0munoE2r5VngsHXQGWnYNgAIlEGQvwqL52-7v5a2RShdVo8_4d0gV0-MHBMv0akT60cfaDBukms6Lk0hX_kk20AyF2jsLu3tPcty3RIi7VZgSOyP83n-Ax6ideyVryy31tfTJIr_vqMPpQX2rhm7iQ4PBGgopMM6PUYzqUMeL9IX3t2qA0VD_tHim2kKdE17NV9ffiDP2UmH4gdzjw=w2114-h1166',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
    
    title: '현장을 뒤흔드는 기술, 중장비 스마트 관리의 혁신',
    date : '2023-09-25',
    tag1 : '#스마트중장비',
    tag2 : '#중장비관리',
    tag3 : '#건설중장비',
    tag4 : '#RTLS',
    
    // <br/>
    text : [
      {
        head : '',
        subHead :'',
        desc : '현대 건설 산업은 급변하는 환경에서 더욱 스마트하고 효율적인 방법을 모색하고 있습니다. 중장비 스마트 관리 솔루션은 이러한 노력의 중요한 부분 중 하나입니다. 특히, 실시간 위치 추적 시스템(RTLS) 기술을 적용한 중장비 관리 솔루션은 건설현장의 운영 및 안전성을 혁신적으로 향상시키고 있습니다. 이번 글에서는 RTLS 기술이 중장비 관리에 미치는 혁신적인 영향과 그 장점을 자세히 알아보겠습니다.',
        dot : ''
      },
      {
        head : 'RTLS 기술이란?',
        subHead :'',
        desc : 'RTLS(Real-Time Location System)는 실시간 위치 추적 시스템의 약자로, 중장비 및 자산의 정확한 위치와 상태 정보를 실시간으로 추적, 모니터링, 및 관리할 수 있는 기술입니다. 이 기술은 GPS, RFID(Radio-Frequency Identification), UWB(Ultra-Wideband), 무선 네트워크 등 다양한 기술과 연계하여 사용됩니다.',
        dot : ''
      },
      {
        head : 'RTLS를 활용한 중장비 관리의 장점',
        subHead :'',
        desc : 'RTLS 기술을 중장비 관리에 접목하면 다음과 같은 혁신적인 장점을 얻을 수 있습니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'1. 실시간 위치 정보',
        desc : 'RTLS 기술은 중장비의 정확한 위치를 실시간으로 파악할 수 있습니다. 이로써 중장비의 현재 위치와 이동 경로를 실시간으로 확인할 수 있어, 필요한 장비를 빠르게 찾아 사용하거나 작업 일정을 최적화하는 데 도움이 됩니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'2. 중장비 보호와 관리',
        desc : '중장비는 건설 프로젝트의 핵심 자산 중 하나이며, 그 보호와 관리는 핵심 과제입니다. RTLS를 활용하면 중장비의 도난을 방지하고, 예방 정비 및 보수 일정을 예측하여 고장을 사전에 방지할 수 있습니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'3. 작업 일정 최적화',
        desc : '중장비의 실시간 위치 정보를 활용하면 작업 일정을 최적화할 수 있습니다. 필요한 장비를 빠르게 찾아 사용하므로 작업 지연을 방지하고 생산성을 향상시킬 수 있습니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'4. 안전한 작업 환경 조성',
        desc : 'RTLS 기술은 작업자의 안전을 향상시키는 데 도움이 됩니다. 중장비의 위치 정보를 공유하면 작업자들은 장비와의 충돌을 예방하고 안전한 건설 환경을 조성할 수 있습니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'5. 자동화 및 효율화',
        desc : 'RTLS 기술은 중장비 관리를 자동화하고 효율화하는 데 큰 역할을 합니다. 중장비의 상태를 실시간으로 감시하고 보고서를 자동으로 생성하여 업무 프로세스를 간소화하며 인력과 시간을 절약할 수 있습니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'6. 데이터 분석 및 의사 결정 지원',
        desc : 'RTLS 시스템은 많은 데이터를 생성하며, 이를 분석하여 건설 프로젝트의 성과를 개선하는 데 도움이 됩니다. 예를 들어, 장비 사용률을 평가하고 향상시키는 데 도움을 줄 수 있습니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'7. 환경 친화적',
        desc : '중장비의 효율적인 관리는 에너지 소비를 줄이고 자원을 절약하는 데 도움이 됩니다. 이는 건설 프로젝트를 더욱 환경 친화적으로 만드는데 기여합니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'8. 비용 절감',
        desc : 'RTLS 기술을 사용하면 중장비 관리 및 유지보수 비용을 절감할 수 있습니다. 예를 들어, 예방 정비 및 보수를 효율적으로 수행하여 고장 발생을 최소화하고, 장비 대여 및 구매 결정에 관한 데이터를 활용하여 예산을 최적화할 수 있습니다.',
        dot : ''
      },
      {
        head : '마무리',
        subHead :'',
        desc : 'RTLS 기술은 건설현장 스마트 중장비 관리에 혁신적인 솔루션을 제공하며, 이를 통해 작업 일정 최적화, 자산 보호, 안전한 작업 환경 조성, 환경 친화성 증진, 비용 절감 등 다양한 이점을 얻을 수 있습니다. 따라서 RTLS 기술은 건설 산업의 미래에 빛나는 역할을 할 것으로 기대됩니다. 건설 산업의 현장을 뒤흔들 것이며, 스마트한 미래를 열어갈 것입니다.',
        dot : ''
      },
    ],
  
    link : {
      page : 'rtls',
      numb : '1',
      product : [
        {
          text: productPurchase.purchase.asset.assetTracking.title,
          title: productPurchase.purchase.asset.assetTracking.title,
          serial: productPurchase.purchase.asset.assetTracking.serial,
          desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img: productImg.product.assetTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : constructionEquipment.thumb,
          data : 'constructionEquipment',
          link : '/enterprise/construction/equipment'
        },
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/enterprise/construction/asset'
        }, 
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/enterprise/construction/safety'
        }
      ]
  
  
    }
  
  
  }