import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: '都市のデジタルツインをリアルなグラフィックで作成し、都市の視覚化を向上させます。LDO5レベルのグラフィック表現、外部グラフィックデータの統合、緯度経度ベースのグラフィックデータサポート、対話可能な3Dモデル、歴史的および文化的遺産のデジタル保存、都市計画および開発シミュレーションを通じて都市計画、デザイン、マーケティングに活用されます。',
  keywords: 'Digital Twin, Digital Twins, デジタルツイン, gis, デジタルシティ, RTLS, 位置追跡システム, 位置追跡技術, スポーツ, 製造, ロジスティクス, 医療, 病院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'デジタルツイン - 生き生きとしたグラフィック都市の構築 :: ORBROブログ',
  ogUrl: 'https://orbro.io/jp/blog/digitaltwin-digitalcity',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'デジタルツイン - 生き生きとしたグラフィック都市の構築',
  date: '2023-12-26',
  tag1: '#デジタルツイン',
  tag2: '#CCTV',
  tag3: '#デジタルシティ',
  tag4: '#バーチャルリアリティ',

  text: [
    {
      head: 'デジタルツイン - 生き生きとしたグラフィック都市の構築',
      subHead: '',
      desc: 'ORBROはデジタルツイン技術を活用して都市の生き生きとしたグラフィックを現実的に実装し、都市の視覚化を強化しています。これにより、LDO5レベルの高解像度のグラフィック表現、外部グラフィックデータの連携、緯度経度に基づく正確なグラフィックデータのサポート、対話可能な3Dモデル、歴史と文化遺産のデジタル保存、都市計画と開発のシミュレーションを提供しています。<br/><br/>ORBROの高解像度のグラフィック表現は都市を現実的に視覚化し、ユーザーに没入感を提供します。外部グラフィックデータの連携は正確で多様な都市情報を効果的に統合します。さらに、緯度経度に基づくグラフィックデータは正確な位置情報と地理的分析を可能にします。<br/><br/>対話可能な3Dモデルはユーザーが都市のさまざまな部分をリアルタイムで探索し、分析できるよう支援し、歴史と文化遺産のデジタル保存は都市の価値を守り、教育的な側面を向上させます。さらに、都市計画と開発のシミュレーションは効果的な戦略の策定と開発の決定をサポートし、都市の持続可能な発展を促進します。',
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/KkvO62eClcU?si=AQmNMfH6nmQ5Aa3Z',
      head: '',
      subHead: 'LDO5レベルの生き生きとしたグラフィック表現',
      desc: '最高水準のディテールと解像度を備えたグラフィックで都市の様子をリアルに視覚化します。これは都市計画、デザイン、マーケティングに活用され、仮想現実および拡張現実との統合を通じて新しいユーザーエクスペリエンスを提供します。',
      dot: ''
    },
    {
      head: '',
      subHead: '外部グラフィックデータの統合',
      desc: 'さまざまな外部ソースからのグラフィックデータを収集し、統合して、より正確で豊かな都市モデルを提供します。これは都市の視覚的表現を向上させ、正確な都市分析および計画に寄与します。',
    },
    {
      head: '',
      subHead: '緯度経度ベースのグラフィックデータサポート',
      desc: '緯度経度座標を基にしたグラフィックデータをサポートして、正確な位置情報と地理的分析を可能にします。これは都市計画、不動産開発、観光産業などに活用されます。',
    },
    {
      head: '',
      subHead: '対話可能な3Dモデル提供',
      desc: 'ユーザーが対話できる3Dモデルを提供し、都市のさまざまな要素をリアルタイムで探索および分析できるようにします。これは計画、教育、シミュレーションなどに活用されます。',
    },
    {
      head: '',
      subHead: '歴史的および文化的遺産デジタル保存',
      desc: '歴史的な建物や文化的遺産を3Dでデジタル化して保存します。これは文化遺産の保護と教育的な価値を向上させます。',
    },
    {
      head: '',
      subHead: '都市計画および開発シミュレーション',
      desc: '実際と同様のグラフィックを使用して都市計画および開発のシナリオをシミュレートします。これは効果的な計画の策定と開発の意思決定に必要な情報を提供します。',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を作業者または機器に取り付け、リアルタイムでの位置追跡を支援する製品です。',
        img: productImg.product.workerTracking.thumbnail
      },
      {
        text: '',
        title: '',
        serial: '',
        desc: '',
        img: ''
      }
    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/jp/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/jp/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      }
    ]
  }
}
