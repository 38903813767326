import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

   
  helmet: '',
  desc: 'Ensuring safety and protecting workers is of paramount importance in the construction industry. To achieve this, state-of-the-art Real-Time Location System (RTLS) technology is being utilized to make managing worker safety on construction sites easier.',
  keywords: 'Construction safety, Construction site worker safety, Smart construction, Laborer safety, Occupational Safety and Health Act, Construction Safety Management Integrated Information Network, Construction union, Safe labor',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'A Comprehensive Solution for Smart Safety Management:: Orbro Blog',
  ogUrl: 'https://orbro.io/en/blog/smartSafety',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDyroUx1vmR8ckUGMDATiid3Dh8um-T9HPZwlPIYFAVQZ6gAqtjkC9yRmKCzc1E4y9OSYTPb_LCkF_SEylO00Rky0eS7weV0HFegyoQFnuWtZS4dJcgZhXg7NgKbB-lJHqoWX9iWFXOLGYRCck7Pzm9SecNQohk3gkJMABfK0W7SOahk5A_CpImCa6ZeYjnPrnJxJzATWPOAu_GUAv9tunvWcaHzwHNFGzd4wmKkzNRlpgiqTGmxGDiF1tnkU43_8zJ8Af3YPglR8V_Y8nr6hIKD0r_deC5eKuX9NhdkGi3_MwlH_6ID7fHAUY_4D8ay4UBjdHdIY39ZO5qmVVydqF4YA7_e2S6LGjkfGuhX06LkrzYLkNj0kAaBXMND1BhVSI-Zb-GpDot9xVAkg6mS0dhnTHGd4Dvh5P1F6CkjB2DJ8ZytwXnQJyQtOzhgYFcstok9388wIUDLs51CPoeUsytegmLUUG2NVsxXLfpB4yqeUz4gTQjH3WvGUQP3GKJkEsZ-fuzdaF3pTx9LLKB_xDvIeFhzmb4fONSHT2U9U_QEJjLCzt-NCZxHK3YK_xeHCQhB9h_8KLnCTSz8LgKKy80N4IfOpROiCh_oyYTiWXzhcWHWWh-OEvPtgY2xoCN2CtcClws0U3t-xRH5NQ9bsFsLU_41jxHLmULeU4UjUBfE50lAbgEhTIIi45ibRyNlruRzOzOsd3M129XYQM4F49mvW0c-MCe1D8fhLFbSg3TLCoO6lNHzOuGk5AhawFY6LXRmsNWIAf_orzFCaByHj8FIYhInRqA3JGuwUkGOVkqTrb2pAeqRb2Y0mO0a7Q5yuK7IEEVD6nNCJ9QrWtOBE8yYzrXVf54N7SL9aQz5ZCP4SCwp27j3V85cmk5dx-ZyDGiapVFg1rNA7Mwnx4L3F0eKNcRZzT9WWMWBXLD61fLbIfCcvoENjVSYEPO8VN5ipvNWSNwV5iHjace_sdYEzNMByEmrEjVt4slthi5itRPgSKrrgq1CQDcdZsSh1ToMCeUzPAcoPW9rnjGh8tngE7-VrPH5QLHldQu9mQy9skU-nCfY8A2DF9RRzB6So10votLNIb3bGaL-HSF5s0TOU8HlSAg2EKU6gM4LGwPjg5HztDXDoJyuRiBQcw6myNwpypRu3MPsD4EhS-uhn49VM4TVxsZMw6DJc5CXmZHhghyxX9GJyf2uHKWVOOzLkeJm2641BA8ZOpxpeg1bfNczrS9qVJDgRpzye_uh7uqihmtRlKAWH9tA2nouewAzocUyrzii7EX46YkP2GHg7DY_L_42aHc9EyTXPpGw3LDlJXHQ3sQ44RUyNV1EFE99w27XRmnxr_8YlZD9pegnY5GWNBiU35BwROQDKDsEzgKunAYgEhg3EspT4nJl4E_j19DHxsNer97Yudk2jhRWZ1QTcFWH67Ew8lCLdVw_yY0CqKWuMZK0o_379BLJt_dJIO5GVE-vhMEqdEN1zCUUp8yqBaruMbTm-_IfSHGOgQa4uue05eTTj-RcxLbAz-mRT8gzDo4yeOWNDBw4NsMtbQr-YOf89LvIvhhch-ND74zLC0KeIz5gM7VfV0VD4MkKBw=w2049-h1228',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',
    
    title: 'A Comprehensive Solution for Smart Safety Management',
    date: '2023-10-04',
    tag1: '#WorkerSafetyManagement',
    tag2: '#SmartConstruction',
    tag3: '#ConstructionSiteSafety',
    tag4: '#RTLS',
    
    // <br/>
    text: [
        {
          head: '',
          subHead: '',
          desc: 'Modern construction sites are complex and come with various inherent risks. Ensuring safety and protecting workers is of paramount importance. To achieve this, state-of-the-art Real-Time Location System (RTLS) technology is being utilized to make managing worker safety on construction sites easier. In this article, we will explore how RTLS technology contributes to enhancing worker safety.',
          dot: ''
        },
        {
          head: 'Introduction to RTLS Technology',
          subHead: '',
          desc: 'RTLS stands for Real-Time Location System. It is a technology that enables the precise tracking and monitoring of mobile elements such as workers and equipment. RTLS systems use various technologies such as GPS, RFID (Radio-Frequency Identification), UWB (Ultra-Wideband), Bluetooth, ultrasound, and other sensors to track the location of workers.',
          dot: ''
        },
        {
          head: 'Advantages of RTLS for Worker Safety',
          subHead: 'Accident Prevention',
          desc: 'RTLS systems can detect potential collisions or hazards by tracking the real-time location of workers. For example, during crane operations, it can detect proximity to other workers or equipment and send warnings to prevent accidents.',
          dot: ''
        },
        {
          head: '',
          subHead: 'Monitoring Worker Status',
          desc: 'RTLS can monitor workers’ activities and track their health status and fatigue levels. If a worker is becoming fatigued, it can provide guidance for taking appropriate breaks or moving away from hazardous tasks.',
          dot: ''
        },
        {
          head: '',
          subHead: 'Emergency Response',
          desc: 'In case of emergencies, RTLS systems provide precise location information, reducing the time required for rescue or emergency medical assistance. This increases the likelihood of saving a worker’s life.',
          dot: ''
        },
        {
          head: '',
          subHead: 'Work Logs and Reports',
          desc: 'RTLS systems record workers’ activities and generate work logs. This helps optimize work schedules and improve productivity. Additionally, it facilitates accident investigations and reporting by recording relevant information in case of accidents.',
          dot: ''
        },
        {
          head: 'Applications of RTLS',
          subHead: 'Construction Sites',
          desc: 'Construction sites are fraught with various risks, and many workers collaborate on tasks. RTLS systems track equipment and workers in real-time, creating a safer working environment.',
          dot: ''
        },
        {
          head: '',
          subHead: 'Factories and Manufacturing',
          desc: 'RTLS technology is also employed in factories and manufacturing to track worker locations and manage inventory. This optimization enhances production processes and improves efficiency.',
          dot: ''
        },
        {
          head: '',
          subHead: 'Logistics and Warehouses',
          desc: 'In logistics and warehouses, RTLS systems are used for inventory tracking, monitoring worker locations within warehouses, and optimizing shipping and delivery processes.',
          dot: ''
        },
        {
          head: 'Conclusion',
          subHead: '',
          desc: 'RTLS technology serves as a powerful tool for enhancing worker safety and productivity in construction sites and various other industries. With features such as accident prevention, worker status monitoring, emergency response, work logs, and reports, it offers significant benefits to site managers and workers alike. Looking ahead, we can anticipate further developments in RTLS technology across the construction industry and other sectors. It is essential to continue efforts to enhance worker safety in these environments.',
          dot: ''
        },
        {
          head: '',
          subHead: '',
          desc: '',
          dot: ''
        },
    ],
  
    link: {
      page: 'rtls',
      numb: '2',
      product: [
        {
          text: productPurchase.purchase.worker.workerTracking.title,
          title: productPurchase.purchase.worker.workerTracking.title,
          serial: productPurchase.purchase.worker.workerTracking.serial,
          desc: 'A product that aids real-time location tracking by attaching UWB devices to workers or equipment.',
          img: productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution: [
        {
          img: constructionSafety.thumb,
          data: 'constructionSafety',
          link: '/en/enterprise/construction/safety'
        },
        {
          img: constructionAsset.thumb,
          data: 'constructionAsset',
          link: '/en/enterprise/construction/asset'
        }, 
        {
          img: constructionEquipment.thumb,
          data: 'constructionEquipment',
          link: '/en/enterprise/construction/equipment'
        }
      ]
  
  
    }
  
  
  }
