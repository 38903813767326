import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

    helmet : '',
    desc : 'RTLS와 IoT는 제조 산업에 있어서 혁신적인 변화를 가져옵니다. 더욱 효율적이고 정확한 자산관리와 자산추적으로 비즈니스를 성장시킬 수 있습니다.',
    keywords : 'RTLS, IoT, 제조산업, 제조업, 자산관리, 자산추적, 위치추적, 근로자 관리, 근로관리, 근무관리, 실내 위치 추적, GPS',
    
    ogTitle : '',
    ogSite : 'RTLS와 IoT가 제조 운영을 혁신하는 방법 :: 오브로 블로그',
    ogUrl : 'https://orbro.io/blog/manufacture',
    ogDesc :'',
    ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOa6daqBDghCNor8iv-YzX1WxKH5TOKtIuZ9Iom5tWnX1rsigsNIOwNLoDBlg70uCuj1H-sg75MSHvBgEva42vfGoepNWA-Iw509j1WfkjEYFSyInBvchHQrT16H7m2qJIKHJMkYZIS2k9KhzJE-hoQ86Y9voT53nZQA7VyJOfQWTxxaUy1bjjnVKrn8H0GEP9W_85velE96d8kXtIjOWmjV6SDkKVxEnl_N1NejohAfWmQhvVCJVnlf7cPcn-5DB4LMdtZGSAGRVWLSXzygG8F8nP4b3YJTMGE4WZBoePBa0hM4SBgT7SpykyGlPzUay6TifllWLBvuvWKh9LCb0JZtYebb8isWpkOIERN0tmDCM_tBjsvibo27ZyP1yRfpmLKfsoNtpZOHY9NP9eHQnUAhLg1clyaBjR-hxMKhMXk2AQUhS1OWIcsN_tLo36LQyNQAeChEpFUJLr0hc0aXxrscDRHI4S80-5hqCmjPcfUERM-fKyyK6SfFJrEBtyCDbqhWgGuU8BOUvkcnPc68hRsSBXjkiZILl0tjKmrWzJe2UUBBVyduU__R3dktNC0FGRPLrkx9XavkeA3B5aZUCOggV9AjAUklOCEml0dfPOyCg-eKwU196LAIC6HdS-ja1H_q7aBURgW9RAK_jGYJmWGzfUPt9ZndbM4G7khUvJU4eWKvYkXFsP0QlkOszc2q3LD9zWxMFrjzbuAs7Gnus4QHrCk0VoS5WYhGBgDLuYfph53E3g6vKm3Z8VH1oGnD_MoZdX3NjdUmLbm2aN8qGe0uvq57HPT2AOUVTC-ntXkT2560j9Y1TnPzHczlkmaRCzxxMHdufPs1Xv147yCml7lz-IY3_3tYh88JhPpWKYMwsZ0gmDMD-k58uVl6M2nu2U2ZnDBIhQR5N2XG5W6-45lXcdsSMQ249MAkTUAoO1V3qu3l66NcOZ4f2K4eL8pHl1OgPuLlgp5xvHaaWG4DJP9-uOps1wYhRqnDZWGfsQaoNH27x3JwRvkKIoSojHEQ28-GqOxo7DHwZDbwGtSzAnh-oHGzqpbUgbtcUr9Urk8Kr4YZh5GbMrmwZLXAl5CyIzCxfCxgKHvxkRgKL81zT3bcX4n-L9seXjGliNOO9sUax87u662hezKTLkVnx4a0olXqQm4PuCUpdF_AO187KE0-8fqEHktIFUy2WujCmXpbyKXwj63VakYRGmnTF2DcVJ1F7lkdio4CGmBLhtQoQeXPabn1aRJ-QeuvDLFBO27AGJbhszDTGjIjQaha7Hp1Btx_AKN0NEdYl2PiFBF1y9QB1JCiexGRPeVBWBSnvf_FE69IkFXqkSkIi1kPKlA-ycurt5-uYVPe3ejmtTq54EHjy8uEBAyQ74EY0dCuSVc1zMRZPYot0mmlcxDw7oRtWRpzHRl2tudqm9bwTriTBMAjd101CAjfkwupjGycmcBCeEQ1KHkjkG6noU85cBX2uzWJgLxlQZKcw_RCypz5egZfAzkP4uiZIBauX2UAcOcLo_f8XBjlq6_-I9AGmNgzrS9hd_NNGBqcxS65JFZaT5gWU2Ousre_BtisMG4z84fsFw=w2059-h1216',
    ogType : 'website',
    
    twitterCard : 'summary',
    twitterTitle : '',
    twitterDesc : '',


    title: 'RTLS와 IoT가 제조 운영을 혁신하는 방법',
    date : '2023-05-14',
    tag1 : '#RTLS',
    tag2 : '#IoT',
    tag3 : '#제조업',
    tag4 : '#자산관리',
    
    // <br/>
    text : [
      {
        head : 'RTLS 란?',
        subHead :'',
        desc : 'RTLS(Real Time Location System) 는  위치 기반 서비스를 위해 사람이나 물체에 대한 실시간 위치 정보를 제공하는 자연스러운 보완 장치입니다. 이는 기업이 데이터를 사용하여 전반적인 성능, 효율성 및 수익성을 개선하는 방법에 대한 통찰력을 얻을 수 있도록 지원합니다.<br/>RTLS 기술 시장은 의료, 제조, 스포츠 및 기타 여러 분야에서 다양한 새로운 애플리케이션의 등장과 함께 빠르게 성장하고 있습니다. <br/>오늘날의 비즈니스 환경에서는 이익률을 높이고 고객 만족도를 높이는 것이 경쟁 우위를 확보하는 데 필수적입니다. IoT 장치와 결합된 이 기술은 생산 프로세스 속도 향상, 개발 주기 모니터링, 예측 유지보수, 창고 및 재고 관리를 통해 제조 산업을 혁신하고 새로운 수익 역학을 열 수 있는 잠재력을 가지고 있습니다. <br/><br/> 어떻게 작동하는지 아래에서 몇 가지 예시를 살펴보겠습니다.',
        dot : ''
        
      },
      {
        head : '',
        subHead :'생산 라인 효율 증가',
        desc : '생산 라인에 RTLS 기술을 통합하면 기업은 특정 생산물을 추적하고 장비가 어떻게 사용되고 있는지에 대한 데이터를 수집하고 공급망의 효율성을 평가할 수 있습니다.  이러한 방식으로 데이터에 액세스할 수 있다는 것은 일상적인 운영 또는 병목 현상을 즉시 파악하고 완화할 수 있다는 것을 의미합니다. 이는 궁극적으로 운영을 간소화하고 비용을 절감하는 데 도움이 됩니다. 마찬가지로 프로세스 편차가 발생하는 시기를 알면 제품이 고객에게 도달하기 전에 오류를 파악하는 데 도움이 됩니다.',
        dot :''
      },
      {
        head : '',
        subHead :'자산 추적 및 관리',
        desc : '제조 및 물류 산업 내에서 발생하는 지속적인 문제는 바로, 장비를 어디서 찾아야 하는지 아는 것입니다. 코로나19로 인해 특정 지역에서 제한된 수의 근로자만 허용되는 환경에서 이 같은 문제는 더욱 까다로워졌습니다. 더욱이 모든 산업에서 장비를 탐색하는데 소요되는 시간은 매출 손실의 원인이 됩니다.<br/>모든 장비에 RTLS 태그를 부착하는 것은 작업자가 자산이 어디에 있는지 항상 알 수 있다는 것을 의미합니다. 즉, 가장 가까운 서브미터까지 말입니다. 또한 관리자는 모든 장비와 기계에 태그를 부착하여 정확한 위치를 파악함으로써 유지보수 작업을 보다 신속하게 수행할 수 있습니다. 게다가, 기계의 사용 데이터를 알고 있다는 것은 작업자들이 정기적인 유지보수를 언제 예약해야 하는지를 더 잘 추정할 수 있다는 것을 의미합니다. 처음부터 기계가 고장 나지 않도록 말입니다.',
      },
      {
        head : '',
        subHead :'안전 관리',
        desc : '작업자와 장비의 안전은 특히 사람들이 매일 드나드는 대규모 시설에서 또 다른 중요한 관심사입니다. RTLS, LBS 및 IoT는 자산을 통제하는 것을 통해 업계에 이익을 줄 수 있습니다. RTLS는 출입구, 방문자 ID 배지 내, 휴대 전화 또는 키 카드에서 구현되어 특정 시설 영역에 대한 무단 액세스를 방지할 수 있습니다. <br/>마찬가지로 태그는 작업자에게 위험이 높은 영역에 진입하고 있음을 경고하고 작업자가 현장에 진입할 때 올바른 개인 보호 장비(PPE)를 착용하고 있는지 확인하여 기업이 규정 준수 및 보안 규정을 준수하도록 보장할 수 있습니다.',
        dot : ''
      },
      {
        head : '성공적인 솔루션을 위한 고려 사항 <br/>',
        subHead :'미래 요구사항',
        desc : 'RTLS의 가치는 비즈니스의 단기 및 장기 요구사항을 모두 고려하여 전체적인 접근 방식을 취하는 데서 비롯된다는 점을 기억하는 것이 중요합니다. 그러나 많은 기업이 위치 기반 서비스를 단일 애플리케이션으로 생각하는 경향이 있으며, 결과적으로 중장기적인 요구사항을 충족하는 솔루션이 아닌 최소한의 실행 가능한 솔루션에 대한 예산을 책정하게 됩니다.  선택된 RTLS가 여러 사용 사례를 동시에 충족할 수 있도록  해야 합니다.',  
      },
      {
        head : '',
        subHead :'현실적인 기대',
        desc : '최종 사용자는 비현실적인 기대를 하지 않고 문제가 발생할 때 문제를 해결할 수 있도록 교육을 받아야 합니다. 제품의 작동 방식을 완전히 이해하는 데 시간을 할애하여 원하는 결과와 필요한 실행 예산을 일치시키면 향후 문제를 예방하는 데 도움이 됩니다.',
      },
      {
        head : '',
        subHead :'소프트웨어 유형',
        desc : '위치 소프트웨어는 계층과 복잡성이 다르기 때문에 회사의 애플리케이션에 가장 적합한 소프트웨어 유형을 이해하는 것이 중요합니다. 달성하고자 하는 목표를 고려하고 비즈니스 전략에 맞는 최적의 솔루션을 찾으십시오. 이와 관련하여, 다양한 LBS 및 SW 애플리케이션 솔루션 간의 유연성을 보장하기 위해서는 처음부터 개방적이고 다재다능한 RTLS 플랫폼을 채택하는 것이 핵심입니다.',
      },
      {
        head : '',
        subHead :'성급함',
        desc : '초기 설정 후에는 실제 환경에서 테스트를 건너뛰는 경향이 있지만 그럴 경우 실패로 끝나는 경우가 많습니다. 기업은 기술을 규모에 맞게 롤아웃하기 전에 여러 시나리오에서 솔루션이 어떻게 확장되는지 이해해야 합니다. 또한 지속 가능한 확장성 계획과 총 소유 비용(TCO)에 대한 명확한 추정치를 보장하기 위해 예산 및 프로젝트 요구 사항을 일치시키는 것이 중요합니다.',
      },
      {
        head : '',
        subHead :'',
        desc : 'RTLS, IoT에 관심을 가지기에 흥미로운 시기입니다. 여러 업종에 걸쳐 점점 더 많은 기업이 혜택을 누리면서 성능과 수익이 개선되고 있습니다. 아직 이 기술을 채택하지 않은 사람들에게는 지금이 기회입니다. ',
      },

    ],

    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/enterprise/construction/asset'
        }, 
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/enterprise/construction/safety'
        },
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/enterprise/logistics/asset'
        }
      ]
  
  
    }
  

  }