import { BACKGROUND_SECONDARY, BORDER_TRANSLUCENT } from "@assets/styled/color.style";
import { FlexStyle } from "@assets/styled/flex";
import { StyledHeading } from "@components/Text";
import styled from "@emotion/styled";

export const StyledTechTableHeader = styled(FlexStyle)`
  width: 100%;
  height: 56px;
  background-color: ${BACKGROUND_SECONDARY};
  border-bottom: 2px solid ${BORDER_TRANSLUCENT};
`;

export const StyledTechTableColumn = styled(FlexStyle)`
  flex: 1;
  align-items: center;
`;

export const StyledTechTableHeaderText = styled(StyledHeading)`

`;

export const StyledTechTableRow = styled(FlexStyle)`
  width: 100%;
  height: 68px;
  border-bottom: 1px solid ${BORDER_TRANSLUCENT};

  &:last-of-type {
    border-bottom: none;
  }
`;
