export default {
  titleBanner: {
    subtitle: 'Stereo Vision',
    title: 'Position Tracking and Positioning Technology Using Stereo Vision',
    description: '*Stereo Vision is a technology that uses two combined cameras to perceive objects in 3D. <br/> This technology operates on a principle similar to human vision, where two cameras simultaneously capture the same subject from different positions to obtain depth information of the object.'
  },
  tech: {
  title: 'What is Stereo Vision?',
  text: 'Stereo Vision is a computer vision technology that uses two cameras to capture images and estimate the depth information of objects in 3D space. To achieve this, the two cameras capture the same object from different positions, and the acquired image pair is used to calculate the depth information. This is done by computing the disparity, which is the difference in pixel coordinates between the two images, and using it to estimate depth. <br/><br/> This technology operates on a principle similar to human vision, where two eyes are used to perceive depth information. It enables the perception of object positions and distances in a 3D environment, and it is widely used in various fields such as robotics, autonomous vehicles, video games, and image processing. Stereo Vision provides more accurate and practical results compared to traditional computer vision techniques, making it an important technology in various applications.'
  },
  feature: {
  title: 'Key Advantages of RTLS Using Stereo Vision',
  text: 'RTLS (Real-Time Location System) using Stereo Vision is a system that tracks and monitors the position of objects or people in real-time by analyzing visual information collected by two cameras. <br/> Stereo Vision enables highly accurate position inference with high precision and reliability. It can provide real-time position information, making it valuable in industries such as logistics, manufacturing, and construction where real-time location information is crucial. <br/><br/> Furthermore, Stereo Vision-based RTLS does not require tags or additional signal-emitting devices for the target, reducing the cost of purchasing and maintaining such infrastructure. This results in cost savings and improved system performance. With its high accuracy and reliability in target tracking, Stereo Vision-based RTLS offers both cost-effectiveness and performance improvement, making it a highly useful technology.'
  },
  howItWorks: {
    title: 'How Does Positioning with Stereo Vision Work?',
    card: {
      detection: {
      title: 'Object Detection',
      description: 'In vision-based RTLS, deep learning object detection techniques are used to track objects in images. These techniques involve training artificial intelligence algorithms to identify and classify various objects in images. Currently, they can recognize around 80 different object categories, and additional categories can be trained based on customer requirements.'
      },
      estimation: {
      title: 'Depth Estimation',
      description: 'We utilize two images taken from different angles to estimate depth information for tracking the position of objects. This process is similar to how humans perceive depth using two eyes. We employ deep learning techniques to achieve more accurate depth estimation compared to traditional computer vision methods.'
      },
      calculation: {
      title: 'Coordinate Calculation',
      description: 'By leveraging computer vision techniques, including object detection, depth estimation, and angle calculation, we can calculate the position information of objects. This enables real-time object tracking and position determination for various applications.'
      }
    }
  },
  core: {
    items: {
    title: 'Core Technologies of RTLS Using Stereo Vision by KongTech',
      streaming: {
      title: 'Original Image Streaming',
      description: 'To enhance the positioning accuracy in RTLS, high-level depth map inference technology is essential. While humans can quickly and accurately perceive distances based on experience and intuition with rough outlines, depth map technology directly correlates precise inference to detailed image features. To minimize the loss of low-level features, we utilize lossless image streaming instead of lossy compression methods used in conventional CCTV systems. This approach reduces the loss of positional information in Stereo Vision-based RTLS.',
      subDescription: 'Depth maps using lossless original images display more continuous surface representation of objects.',
      imgLabel1: 'Inference Background',
      imgLabel2: 'Original',
      imgLabel3: 'Encoding'
      },
      estimation: {
      title: 'Advanced Depth Estimation',
      description: 'Stereo camera image-based depth inference has received significant attention in the field of computer vision research. In particular, applying deep learning methodologies in the past decade has shown superior results compared to classical algorithms. However, classical algorithms are still used in many fields because they ensure adequate accuracy and real-time performance. Real-time performance is generally defined as processing speeds of over 15 frames per second (FPS).',
      imgLabel: 'Third-party Depth Map using Computer Vision',
      description2: `Classic computer vision algorithms for depth inference had limitations, such as distortion in detailed areas and object outlines. For example, the upper part of a hand holding a mouse or a person's head obscured by a partition was not accurately inferred. However, our deep learning-based model overcomes these limitations. The model is optimized for use in "RTLS Using Stereo Vision" and provides real-time performance (>15 FPS) through lightweight design and fine-tuning. While the processing speed may slow down to approximately 4 FPS when handling inputs from multiple cameras, it still provides satisfactory positioning accuracy considering the movement speed of humans in the field.`,
      imgLabel2: 'KongTech Depth Map using Deep Learning'
      },
      fineTuning: {
      title: 'Depth Estimation Finetuning',
      description: 'Images captured under natural lighting and artificial illumination have different characteristics. Even when only one lens of a stereo camera is illuminated directly, different characteristics can occur. This leads to inconsistent features between the left and right images due to exposure value differences between them.',
      imgTag: 'Characteristics difference of left/right stereo images due to direct illumination',

      description2: 'In addition to these external environmental factors, internal factors that can occur during the manufacturing process of stereo cameras should also be considered. For example, alignment errors or rotation errors between the image sensor and the lens, and scale differences due to changes in the distance between the lens and the image sensor can occur during the assembly process.',
      imgLabel: '(a): Brightness difference, (b): Image sensor rotation error, (c): Image sensor alignment error, (d): Focal length error',
      imgLabela: '(a)',
      imgLabelb: '(b)',
      imgLabelc: '(c)',
      imgLabeld: '(d)',

      description3: 'By overcoming these practical issues, we can provide reliable RTLS using Stereo Vision. KongTech\'s Depth Estimation Finetuning technology and expertise enable this, resulting in robust depth estimation technology that works independently of specific hardware or environmental changes.'
    },
    distance: {
      title: 'Distance Calculation Considering Camera Discrepancy',
      description: 'When converting images obtained from stereo cameras using deep learning-based depth inference, depth distortion issues arise due to the wide-angle lens. This distortion exhibits an increasing trend as the distance becomes closer and shows increasingly nonlinear behavior as it moves away from the optical axis.',
      imgLabel: 'Distortion pattern caused by a wide-angle lens and object discrepancy between Camera A and Camera B <br/> (Green: 2.7m, Purple: 6.3m, Red: 8.8m)',
      imgLabela: 'Camera A',
      imgLabelb: 'Camera B',

      description2: 'To address this issue, it is essential to accurately model the distortion pattern and correct it to match the actual depth. This correction process should consider the individual uncertainties of cameras, such as deviations during the camera lens production process or assembly process. If the distortion correction model is not applied, depth values inferred from a wide-angle stereo camera can exhibit more than 30% deviation. However, applying the distortion correction model can not only eliminate such errors but also reduce the average error rate of depth inference by approximately 50%. Therefore, before all stereo cameras are shipped, we apply our advanced distortion correction model developed in-house. This enables us to provide excellent depth estimation technology, delivering optimal performance and reliability to our customers.'
    }
  }
  },
  advantage: {
      title: 'Key Benefits of Stereo Vision',
      items: {
        accuracy: {
          title: 'Precise Position Estimation',
          description: 'By using stereo cameras, two images can be simultaneously captured to accurately determine the position of objects in 3D space. This enables precise estimation of object positions and movements.'
        },
        position: {
          title: 'Scalability',
          description: 'Stereo vision technology is not limited by the size of the space and can be applied in various indoor and outdoor environments of different scales and types. Additionally, RTLS systems based on stereo cameras can be expanded with additional sensors as needed.'
        },
        security: {
          title: 'Cost Efficiency',
          description: 'Stereo vision technology is relatively inexpensive compared to other position estimation techniques. Furthermore, utilizing existing stereo cameras for building RTLS systems helps reduce installation costs.'
        },
      }
  },

  useCase: {
    title: 'Use Cases of Positioning Using Stereo Vision',
    description: 'The scalability of stereo vision offers effective options for various indoor positioning use cases. The following are some use cases and application areas where stereo vision indoor positioning technology is utilized.',
      card: {
        auto: {
          title: 'Robotic Automation',
          description: 'Robotic automation using stereo vision is widely employed in the manufacturing industry. Robots can perceive their environment with stereo cameras and use position estimation technology to automatically move, assemble, inspect, or package products.'
          ,items:[{name : ' ', description:' '},{name : ' ', description:' '}]
        },
        car: {
          title: 'Autonomous Vehicles',
          description: `Stereo vision is used for position estimation, obstacle detection and avoidance, and lane keeping in autonomous vehicles. By leveraging 3D information from stereo cameras, the positions and directions of surrounding vehicles can be estimated to calculate the vehicle's trajectory.`
          ,items:[{name : ' ', description:' '},{name : ' ', description:' '}]
        },
        counting: {
          title: 'People Counting',
          description: 'People counting functionality is used to monitor customer entry and count the number of people in commercial or public facilities. RTLS technology based on stereo vision distinguishes the entry and exit of people using cameras installed at entrances and provides real-time headcount information.'
          ,items:[{name : ' ', description:' '},{name : ' ', description:' '}]
        },
        space: {
          title: 'Occupancy Analysis',
          description: 'Occupancy data collected from spaces such as buildings or parking lots can be utilized to optimize space utilization. RTLS technology using stereo vision enables real-time tracking of the positions and movements of objects within a space. This helps understand space usage and improve efficiency.'
          ,items:[{name : ' ', description:' '},{name : ' ', description:' '}]
        },
        safe: {
          title: 'Industrial Safety',
          description: 'RTLS technology using stereo vision is also employed for maintaining safety in industrial settings. For example, real-time tracking of the positions of people or objects within a workplace helps prevent accidents that may occur during operations. Particularly, in industrial environments, detecting a fallen person or a worker who has suffered a serious injury quickly can assist in adhering to the golden time for treatment. RTLS systems help detect various potential hazardous situations in industrial settings in advance, enabling early response. This ensures the safety and health of workers while enhancing productivity and efficiency.'
          ,items:[{name : ' ', description:' '},{name : ' ', description:' '}]
        },
        security: {
          title: 'Security',
          description: 'RTLS technology using stereo vision can be effectively utilized for security purposes. For instance, tracking the movement paths of objects or individuals can be used for access control systems, CCTV monitoring, and more. Such technology can be applied in managing the security of building entrances, key facilities, military industries, and other areas.'
          ,items:[{name : ' ', description:' '},{name : ' ', description:' '}]
        },
    }
  }
}