import { accessMainKey4 } from "@assets/images/main";
import { accessAuto1, accessAutoMain, accessPro1, accessProMain, accesscontroller1, accesscontrollerMain } from "@assets/images/main/access/product";

export default {

    specTitle : {

    },

    specData : [
        {
          data: [
            {title : "제품명"},
            {text1 : "자동문 출입"},
            {text2 : "얼굴 인식 출입"},
          ],

        },
        {
          data: [
            {text : "설명"},
            {text : "앱 실행없이 편리한 출입이 가능한 기기"},
            {text : "얼굴인식, NFC카드 등 다양한 출입을 한번에 사용가능한 기기"},
          ],

        },
        {
          data: [
            {text : "전원 공급"},
            {text : "DC 12V, 24V "},
            {text : "DC 12V, 3A"},
          ],

        }


    ],


    a: [
      {text : "제품명"},
      {text : "설명"},
      {text : "전원 공급"},
      {text : "통신 방식"},
      {text : "네트워크"},
      {text : "규격"},
      {text : "설치 지원"},
      {text : "사람 추적"},
      {text : "원패스 기능"},
      {text : "실시간 기기 모니터링"},
      {text : "원격 제어"},
      {text : "NFC 출입"},
      {text : "안면 인식 출입"},
      {text : "QR코드 출입"},

    ],


    b: [
      {text : "자동문 출입"},
      {text : "앱 실행없이 편리한 출입이 가능한 기기"},
      {text : "DC 12V, 24V"},
      {text : "Bluetooth, BLE"},
      {text : "불필요"},
      {text : "210.1 x 60.1 x 42.2(mm)"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},

    ],
    
    c: [
      {text : "얼굴 인식 출입"},
      {text : "얼굴인식, NFC카드 등 다양한 출입을 한번에 사용가능한 기기"},
      {text : "DC 12V, 3A"},
      {text : "Bletooth, BLE, Wi-Fi, NFC"},
      {text : "필요"},
      {text : "256 x 273 x 24(mm)"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},

    ],
    

    d: [
      {text : "출입 액세서리"},
      {text : "출입에 필요한 다양한 고효율, 고성능 액세서리"},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},

    ],

    spec: [
      
      {

      },

      {
        image : accessAutoMain,
        link : '/access/auto'
      },

      {
        image : accessProMain,
        link : '/access/face'
      },

      {
        image : accessMainKey4,
        link : '/access/accessory'
      },
        
    ],
    
    
}



/*


    b: [
      {text : "모바일 출입"},
      {text : "모바일 앱으로 출입문을 제어하는 매우 간편한 기기"},
      {text : "배터리 사용(최대 5년사용)"},
      {text : "Bluetooth, BLE"},
      {text : "불필요"},
      {text : "85.4 x 85.4 x 24.5(mm)"},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},
      {text : ""},

    ],

*/