import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4';

export default {
    desc: '智能建筑利用物联网传感器、人工智能和自动化系统等技术，连接和整合建筑内的各种系统，优化建筑运营。这提高了能源效率，改善了建筑的安全性、便利性和效率。',
    keywords: '智能建筑、RTLS、物联网、数字孪生、数字转型',

    ogSite: '什么是智能建筑？ | Orbro数字孪生平台',
    ogUrl: 'https://orbro.io/cn/blog/smart-buildings',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmNclLyXvFXAppGJYrqUeCnj1ItMcBFHWPvVUo5mAAclWqqXT2c7O3kn8SC3hs6LiHgOw7HC2-KUOHZpCjEK7FnzMSWAna_xAxwzmLzRZvzyR4y1i-8TbhmLBCUAAEfPsa5GgyVj98AunnYrTGFbIebivjQFTuGcC2LCdg_GLJkED01NGjfucz8TKJjCj8Gxa3QnH2ZcipuWUhWVUHPvgzHZe-w_TxeDOeywhDMKI9JWA2QiUM3pBtOpFLqmbd_dl7WMBDD_lw7xutd_UJTam_iuLegrr12cuEQJittPjU2a4YQ5SAqRcKbatpWFDrDsthws-65VTYSGgffeaODxqhQccffxpsoZpNQ9z5y6dydLbm02KFtQyjGQVkVztr00IBlvROvpALKGf8X4W1ZGeg7zEvsV8kyP084k_hUI6MWh6wqkVnF7SSV17RYtyumC1lq5VI-JdGn_YuyA7BZl4_Sgz8_fgeNXiC8z0qifLgrN2sczIe0CffI8piaJcUPxk8ADXBieKFniP5f3dG-AMDN8STr-B2nwFB_CNY7UDz76_s3FmVEnQTTU7MFqF5px-0ZgJhAJhWrOpAY93WGxfVRThfuMY1iGn-6_uuf6IrNAv5Cy7aGvagtrsi7F_UfGjbVbbB4bxQbcV-UqIHZP5wcgNU1JIW4Bztfjw5QUTidafK0ARwixIms3w1Dry4-O0KV-eksKbVrMtPAcL4GiiBaiuU-mpMDiT_c0yf2rCBeMOK_bAmkp7i8lrRMUQC4xYvav6-BDs8r3iuHEbvyBhO7i9Dlq1d5yT40AfmLvRs0lfU9S1d6_ltRRv3sNgUcuUiNxAMwHkco_LDoBxGg21-QCK6CahMJws-V4L60V7M-dRsIuhkIvCs0D7tSFmhBLMd33XEbOE0Jy79l0AgNMG0ChJPg8dvdSjBQ3ep_vAjQTgxJ_vWTy05yCwl6mFTl1zJWxcyjydP40t8D9SdAIQFXOXsxOaOGkauQGkO8t9igDMGIFCQWRJBW4JDC77EmIG3xHmhUnOZTUOmk2Y13SsSePHqLvXpEImCh-lG_Ehp5TgbK_mBfUUDOpZqSnEc3lHGC4z-9XMeOIGWYX689D07wskrtyNdEzShvTXWgrEPopU8c-D2XFw8X89i4f9wlVJ-Egn29eAhBCw4XaLwAA7_LUOXEVwlhYb7DmYxADRH_zXMl9gZE9_UJ_LkymE5ZoEMDT2mTv7cEUK9LPYkEBRmViKwxiFHwx2coDptiwVoR4-mLvPA7hzxRe7NjcreMJ6BEtpvhi4DwXeosAyHVQxd8R_Am7rw5Dtt5eap7Oca07-DDGWzSUzvpzt2Hde-_3NmrBxpOditdoDCuddXrXvyKByMIQmWnRtvIVhGgnGMjvYAkwNJBrd-1qxORsRMbhiBGX_l8HzgAYb6vuHjiaBhbo4a5_F-whRFJW1rO919s5ItElRg8Y6R3xI3xtKl3tza9Al_rKSvZ8lkiv44cUZ6a_rO8Zmi97pNd_aEgj1JhawhSHN3WGHkf7v5TdRC0CAZaxfDUrA4XupbrYuT1lZN7rnd_iIWvTEG2cFR08BcGyw0R9Yg=w1701-h1166',
    ogType: '网站',

    title: '什么是智能建筑？',
    date: '2024-01-30',
    tag1: '#智能建筑',
    tag2: '#物联网',
    tag3: '#位置追踪',
    tag4: '#数字孪生',

    text: [
        {
            head: '什么是智能建筑？',
            subHead: '',
            desc: '智能建筑利用物联网传感器、人工智能和自动化系统等技术，连接和整合建筑内的各种系统，优化建筑运营。这提高了能源效率，改善了建筑的安全性、便利性和效率。通过这些技术，建筑可以实时收集数据，并自动调整照明、供暖、制冷和安全系统等各种设施。智能建筑提高了能源效率，优化了能源消耗，实现了环保和经济运营。它提高了用户的便利性，简化了建筑运营和管理，提供了更好的生活和工作环境。',
            dot: ''
        },
        {
            head: '智能建筑的主要技术',
            subHead: '传感器技术',
            desc: '智能建筑利用各种传感器实时监测建筑内外的环境。这些传感器可以检测温度、湿度、照明、二氧化碳浓度、微粒子水平、运动等，并收集数据。此外，通过传感器数据与其他设备的连接，智能建筑可以实现实时信息交换和控制。利用物联网技术，智能建筑可以集成各种系统和设备，以实现高效运营。',
            dot: ''
        },
        {
            head: '',
            subHead: '实时定位系统（RTLS）',
            desc: 'RTLS技术可用于实时跟踪智能建筑内的设备、库存、物资等。这使得可以快速检测设备损坏、丢失、需要维护等情况，并提供了管理的机会。在智能建筑内发生紧急情况时，可以使用RTLS技术来引导撤离路径，并跟踪建筑内所有目标和个人的位置，以支持救援人员和紧急应对团队的有效响应。',
            dot: ''
        },
        {
            head: '',
            subHead: '人工智能（AI）',
            desc: '人工智能技术用于改进智能建筑的自动化系统和大数据分析。AI可以根据实时数据变化调整系统，以优化满足用户需求。',
            dot: ''
        },
        {
            head: '',
            subHead: '大数据分析',
            desc: '收集的数据将使用大数据分析技术识别模式并开发预测模型。这有助于优化建筑运营并早期检测问题。',
            dot: ''
        },
        {
            head: '',
            subHead: '数字孪生',
            desc: '数字孪生是将建筑的物理模型数字化复制的概念。它在数字屏幕上实现建筑的外观，使其易于理解和管理。这个数字模型支持建筑的运营和维护，反映了建筑的实时状态，用于诊断和预测问题。',
            dot: ''
        },
        {
            head: '智能建筑的优势',
            subHead: '',
            desc: '智能建筑通过整合现代建筑技术和信息通信技术提供各种优势。这些优势涵盖了建筑运营、效率、便利性、环境保护等多个方面。',
            dot: {
                a: '<strong>• 提高能源效率：</strong> 智能建筑使用传感器和自动化系统来优化能源消耗。例如，照明、暖气和冷气系统根据实时数据进行调整，降低能源消耗，提高能源效率。此外，借助大数据分析和人工智能，有助于节省能源成本。',
                b: '<strong>• 环保：</strong> 通过提高能源效率，智能建筑减少温室气体排放。智能建筑监测微粒子和空气污染，改善空气质量，创造健康的环境。',
                c: '<strong>• 成本削减：</strong> 通过提高能源效率，降低能源成本，并通过自动化系统节省人工成本，优化维护和运营成本。',
                d: '<strong>• 便利性和安全性：</strong> 智能建筑通过自动调整照明、暖气、冷气、安全系统等，使用户能够享受舒适的建筑。安全系统监视智能建筑内外的环境，防止入侵并为紧急情况做好准备。',
                e: '<strong>• 实时监测与管理：</strong> 智能建筑向建筑运营者提供实时数据和警报，支持问题的及时解决。因此，它可以监测建筑的健康状况，并优化维护计划。',
            }
        },
        {
            video: video,
            link: '/cn/enterprise/logistics/asset',
            head: '智能建筑案例',
            subHead: 'The Edge、阿姆斯特丹、荷兰',
            desc: 'The Edge位于荷兰阿姆斯特丹，是一座创新的智能建筑，被认为是最能源效率高的建筑之一。建筑内安装了数百个传感器，根据用户位置和活动来优化照明、暖气、冷气。通过实时数据和大数据分析，管理能源消耗，为员工提供各种服务，提高便利性。',
            dot: ''
        },
        {
            head: '',
            subHead: 'Microsoft Campus、Redmond、美国',
            desc: 'Microsoft Campus使用智能建筑和智能设施管理技术来管理建筑和设施。该建筑利用IoT传感器和大数据分析来优化供暖、冷却、照明和水管理，从而降低能源成本。通过为员工提供建筑服务并加强安全系统，提高了用户的便利性和安全性。',
            dot: ''
        },
        {
            head: '',
            subHead: 'One Angel Square、曼彻斯特、英国',
            desc: 'One Angel Square是英国Co-operative Group的总部，积极利用智能建筑技术。该建筑集成了地热、太阳能和环保能源系统，实现了高能源效率，优化了建筑的温度和环境。它使用建筑内外的传感器收集实时数据，并通过大数据分析管理建筑运营。',
            dot: ''
        },
        {
            head: '智能建筑的展望',
            subHead: '',
            desc: '未来的智能建筑将注重能源效率，并利用大数据和人工智能实现最佳运营。随着物联网技术和传感器网络的普及，实时监测和控制将成为可能，最大程度地提高用户便利性。此外，强大的安全系统将提供更安全的环境。智能建筑将在未来城市和建筑中发挥核心作用，促进能源效率和环保。这些功能将革新建筑运营和用户体验。',
            dot: ''
        },
        
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: '将UWB设备附在工人或设备上，以实时跟踪位置的产品。',
                img: productImg.product.workerTracking.thumbnail,
            },
            {},
        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/cn/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/cn/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/cn/enterprise/construction/equipment'
            }
        ]
    },
}
