import {
    access2off, access3off, access4off,
    access2on, access3on, access4on,
} from '@assets/images/main/index';
import { BlueIconPhone, BlueIconRFID, BlueIconRemote, BlueIconWatch, BlueIconWidget } from "@assets/images/icon/blue";
import {
    AdvantageImgD, AdvantageImgM, AdvantageImgT,
    Falldown1D, Falldown1M, Falldown1T,
    Falldown2D, Falldown2M, Falldown2T,
    Falldown3D, Falldown3M, Falldown3T,

    installationImg1D, installationImg1M, installationImg1T,
    installationImg2D, installationImg2M, installationImg2T,
    installationImg3D, installationImg3M, installationImg3T,
    installationImg4D, installationImg4M, installationImg4T,
    installationImg5D, installationImg5M, installationImg5T,

    hardwareImg1D, hardwareImg1M, hardwareImg1T,
    hardwareImg2D, hardwareImg2M, hardwareImg2T,
    hardwareImg3D, hardwareImg3M, hardwareImg3T,
    hardwareImg4D, hardwareImg4M, hardwareImg4T,
    hardwareImg5D, hardwareImg5M, hardwareImg5T,
} from "@assets/images/main/access/components/index";
import { IconOsOff, IconOsOn } from '@assets/images/main/icon';

export default {


    banner: {
        title: '入退室システムの革新',
        desc: 'Orbroの入退室システムを導入して、あなたの入退室システムを革新しましょう。',

        schemeTitle: 'あなたに合った入退室システムを選択してください。',
        schemeDesc: '決定する際にサポートが必要ですか？ ',
        schemeDesc2: 'に連絡してください。<br/>入退室の専門家がお手伝いします。',
        phone: '1522-9928',
    },

    mainPage: {
        product: {
            title: '製品構成を確認する',
            desc: '革新的な入退室システムの製品構成を確認しましょう。',
            mobile: {
                title: 'モバイル入退室',
                desc: 'モバイルを使用した入退室システムの始まり',
            },
            auto: {
                title: '自動ドア入退室',
                desc: '自動ドアに特化した入退室システムの始まり<br/>全ての入退室口に対応可能',

            },
            face: {
                title: '顔認識入退室',
                desc: '顔認識、モバイル入退室、NFCカードなど<br/>あらゆる入退室方法に対応',

            },
            accessory: {
                title: '入退室アクセサリ',
                desc: '入退室システムに関連する<br/>全ての製品をご覧ください',

            }
        }

    },


    FAQ: [
        {
            title: 'Orbro Accessの入退室方法はどのようなものですか？',
            description: 'Orbro Accessの入退室方法はモバイルアプリケーションを介した入退室です。<br/>ユーザーはモバイルアプリケーションをインストールし、権限を受けた後、該当のアプリケーションを使用して入退室することができます。<br/><br/>モバイルアプリケーションを介した入退室は、ユーザーのスマートフォンを使用して出入り口を開閉できるようにします。アプリケーションはユーザーの身元を確認し、入退室権限を認証する役割を果たします。これにより、ユーザーは携帯電話を使用して簡単に出入りを経験することができます。<br/><br/>'
        },
        {

            title: '自動ドアがない環境でも使用できますか？',
            description: 'はい、Orbro Accessは自動ドアがない環境でも使用できます。<br/>ただし、自動ドアがない場合は、デッドボルトロックやEMロックなどの施錠装置を出入り口に取り付ける必要があります。これらの施錠装置は、出入り口を安全に施錠および解錠するのに役立ちます。<br/><br/>Orbro Accessの出入り口端末は、このような施錠装置を制御して出入り口の施錠を解除することができます。出入り口端末はユーザーの認証情報を受け取り、正しい認証が行われた場合にのみ施錠装置を解除して入退室を許可します。この方法でOrbro Accessを活用すると、自動ドアがない環境でも入退室管理システムを運営することができます。出入り口端末と施錠装置の連携により、出入り口のセキュリティを強化し、認証されたユーザーのみが出入りできるように制御できます。<br/><br/>',
        },
        {

            title: '携帯電話なしで入退室できますか？',
            description: 'はい、Orbro Accessは携帯電話なしでも入退室が可能です。<br/><br/>携帯電話なしで入退室するためには、Orbro Access Keyという別のアクセサリを使用する必要があります。Orbro Access Keyはユーザーの情報を含み、送信する装置であり、携帯電話がない人でも入退室認証を受けることができます。これにより、携帯電話がない場合でもOrbro Accessを使用してスムーズに入退室制御を行うことができます。<br/><br/>',
        },
        {

            title: 'Wi-Fiやインターネットの設置ができない環境でも使用できますか？',
            description: 'はい、Orbro Accessは環境に応じてさまざまなシステム構成を提供し、Wi-Fiやインターネットの設置ができない環境でも入退室が可能です。<br/><br/>これにより、ユーザーは自身のスマートフォンを活用して入退室手続きを行うことができます。ユーザーのスマートフォンを媒介としてサーバーから入退室権限を取得し、それを基に入退室を行うことができます。これにより、Wi-Fiやインターネットの設置が困難な環境でもOrbro Accessを効果的に利用することができます。<br/><br/>',
        }
    ],


    bannerData: [
        {
            icon: access2on,
            iconInv: access2off,
            image: '',
            iconText: '自動ドア入退室',
            titleKey: '自動ドアに特化した入退室システムの始まり<br/>全ての入退室口に対応可能',
            descKey: 'access.banner.desc',
            sub: '',
            link: '/jp/access/auto'
        },
        {
            icon: access3on,
            iconInv: access3off,
            image: '',
            iconText: '顔認識入退室',
            titleKey: '顔認識、モバイル入退室、NFCカードなど<br/>あらゆる入退室方法に対応',
            descKey: 'access.banner.desc',
            sub: 'New',
            link: '/jp/access/face'
        },
        {
            icon: access4on,
            iconInv: access4off,
            image: '',
            iconText: '入退室アクセサリ',
            titleKey: '入退室システムに関連する<br/>全ての製品をご覧ください',
            descKey: 'access.banner.desc',
            sub: '',
            link: '/jp/access/accessory'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/jp/os'
        },
        // Add more objects if there are more groups
    ],

    falldown: {
        left: {
            title: 'スマートフォンでエントリーメソッドを統合、<br/>最もモダンなアクセスシステム',
            desc: '通常のドアから自動ドア、スピードゲートまで、スマートフォンを使用して現代社会のさまざまな入場口に簡単にアクセスできます。私たちの日常生活に最も近いシステムを使用して、最も効率的なデジタル変革を体験してください。',

            fall: [
                {
                    title: 'スマートフォンエントリー',
                    desc: 'すべてのiOS/Androidスマートフォンを介してアクセスできます。',
                    img: {
                        desktop: Falldown1D,
                        tablet: Falldown1T,
                        mobile: Falldown1M
                    }
                },
                {
                    title: 'スマートウォッチエントリー',
                    desc: 'スマートウォッチを使用してより便利なアクセスが可能です。',
                    img: {
                        desktop: Falldown2D,
                        tablet: Falldown2T,
                        mobile: Falldown2M
                    }
                },
                {
                    title: 'RFIDカードエントリー',
                    desc: '既存の従業員IDカードを使用してアクセスできます。',
                    img: {
                        desktop: Falldown3D,
                        tablet: Falldown3T,
                        mobile: Falldown3M
                    }
                },
            ]
        },

        rightImg: ''
    },

    functions: {
        title: 'コアはハードウェア、<br/>すべてがスムーズに革新します。',
        desc: '',
        line: 2,
        card: [
            {
                img: {
                    desktop: hardwareImg1D,
                    tablet: hardwareImg1T,
                    mobile: hardwareImg1M
                },
                tag: 'ステータスLED',
                title: 'デュアルチャンネルNFC/Bluetooth<br/>アクセス',
                desc: 'NFCカードなどを使用したアクセスはもちろん、モバイルからスマートウォッチのエントリーまで可能です。',
                size: '3',
            },
            {
                img: {
                    desktop: hardwareImg2D,
                    tablet: hardwareImg2T,
                    mobile: hardwareImg2M
                },
                tag: '電源供給',
                title: 'PoE使用により、<br/>通信と電力が統合',
                size: '1',
            },
            {
                img: {
                    desktop: hardwareImg3D,
                    tablet: hardwareImg3T,
                    mobile: hardwareImg3M
                },
                tag: '簡単な取り付け',
                title: '専用ブラケットで<br/>簡単な取り付け',
                size: '2',
            },
            {
                img: {
                    desktop: hardwareImg4D,
                    tablet: hardwareImg4T,
                    mobile: hardwareImg4M
                },
                tag: 'スピーカー',
                title: 'スピーカーを介した<br/>エントリー通知',
                size: '2',
            },
            {
                color: '#000',
                img: {
                    desktop: hardwareImg5D,
                    tablet: hardwareImg5T,
                    mobile: hardwareImg5M
                },
                tag: 'ステータスLED',
                darkTitle: '暗い夜にも<br/>',
                lightTitle: 'ステータス確認 ',
                darkTitle2: '可能',
                desc: 'RGB LEDを使用してデバイスのステータス、エントリー確認などを確認できます。',
                size: '2',
            },
        ]
    },

    installation: {
        title: '製品の取り付けについて心配しないでください。<br/>私たちがカバーします。',
        card: [
            {
                img: {
                    desktop: installationImg1D,
                    tablet: installationImg1T,
                    mobile: installationImg1M
                },
                tag: 'オンプレミスサーバー提供',
                title: 'エンタープライズグレード<br/>サーバー提供',
            },
            {
                img: {
                    desktop: installationImg2D,
                    tablet: installationImg2T,
                    mobile: installationImg2M
                },
                tag: 'プロフェッショナルオンサイトインストールサポート',
                title: '最速の取り付け日をご案内いたします。<br/>1522-9928までお問い合わせください。',
                desc: '平日、午前9時～午後6時',
            },
            {
                img: {
                    desktop: installationImg3D,
                    tablet: installationImg3T,
                    mobile: installationImg3M
                },
                tag: 'エントリーアクセサリー',
                title: 'デッドボルトの取り付け可能',
            },
            {
                img: {
                    desktop: installationImg4D,
                    tablet: installationImg4T,
                    mobile: installationImg4M
                },
                tag: 'エントリーアクセサリー',
                title: 'EMロックの取り付け可能',
            },
            {
                theme: 'black',
                img: {
                    desktop: installationImg5D,
                    tablet: installationImg5T,
                    mobile: installationImg5M
                },
                tag: 'エントリーアクセサリー',
                title: '電動ストライクの取り付け可能',
            },
        ]
    },

    advantage: {
        img: {
            desktop: AdvantageImgD,
            tablet: AdvantageImgT,
            mobile: AdvantageImgM,
        },
        title: 'オブロが従来のアクセスシステムと異なる点',
        desc: 'ORBROは、現代社会のアクセス方法の変化に適応し、従来のアクセス方法から最新のスマートテクノロジーまで、さまざまなエントリーメソッドを提供する最もモダンなアクセスターミナルです。ユーザーに便利で安全なアクセス環境を提供します。',
        card: [
            {
                icon: BlueIconPhone,
                title: 'スマートフォンを使用したエントリー',
                desc: 'iOS / Androidアプリを使用して入場ポイントを制御できます。',
            },
            {
                icon: BlueIconWidget,
                title: 'ウィジェット提供',
                desc: 'ウィジェットはモバイルアプリをより便利に使用する方法です。',
            },
            {
                icon: BlueIconWatch,
                title: 'スマートウォッチを使用したエントリー',
                desc: 'スマートウォッチを使用して便利なエントリーメソッドを体験してください。',
            },
            {
                icon: BlueIconRemote,
                title: 'リモート制御',
                desc: '管理者はリモートから入場ポイントを管理できます。',
            },
            {
                icon: BlueIconRFID,
                title: 'RFID＆NFCカード',
                desc: 'RFIDまたはNFCタグをタッグして入場ポイントを簡単に制御できます。',
            },
        ]
    },





}