

import React, { useState } from 'react';

import LogoLight from '@assets/images/icon/normal/mainSymbol.png';
import { ReactComponent as CloseIconDark } from '@assets/icons/ic-system-x-dark.svg';

import { IconArrowDownWhite, IconArrowLeftWhite, IconArrowRightWhite, IconChevronDownWhite, IconGlobalWhite } from '@assets/images/icon';
import { IconArrowUpWhite } from '@assets/images/icon';

import { MainMenus } from '@layouts/Default/core/modelCombine';

import { useSelector } from 'react-redux';
import { CommonState } from '@redux/reducers/commonReducer';
import { Trans } from 'react-i18next';
import { IconGlobal } from '@assets/images/icon';
import {
  StyledIcon, StyledLangBox, StyledLogo, StyledMobileNav, StyledMobileNavHeader, StyledMobileNavIconBox,
  StyledNavBody, StyledNavBottom, StyledNavBox, StyledNavDetail,
  StyledNavTitle, StyledTitleIcon, NavDepthLayout, StyledNavBoxDepth, StyledNavTitleDepth
} from './MobileNav.style';
import navSupport from '@src/lang/ko/navSupport';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import i18n from '@pages/i18n';
import { logoWhite } from '@assets/images/logo';
import { StyledHeaderLogo } from '../../NavBox.style';
import navMenu from '@src/lang/ko/navMenu';
import { ImgWH, LabelColor, Spacing } from '@pages/CommonStyle/common.style';


interface MobileNavProps {
  onClose: () => void
}

const MobileNav = (props: MobileNavProps): React.ReactElement => {
  // Props
  const { onClose } = props;

  const location = useLocation();

  const navigate = useNavigate();

  // Redux
  const darkMode = useSelector((state: CommonState) => state.darkMode);

  const changeLanguage = async (index) => {
    const currentPath = location.pathname;
    const langs = ['ko', 'en', 'jp', 'cn'];
    const language = langs[index];
    let newPath = currentPath;


    if (newPath.startsWith("/en/")) {
      if (language === "ko") {
        newPath = newPath.replace("/en/", "/");
      } else if (language === "jp") {
        newPath = newPath.replace("/en/", "/jp/");
      } else if (language === 'cn') {
        newPath = newPath.replace("/en/", "/cn/");
      }
    } else if (newPath.startsWith("/jp/")) {
      if (language === "ko") {
        newPath = newPath.replace("/jp/", "/");
      } else if (language === "en") {
        newPath = newPath.replace("/jp/", "/en/");
      } else if (language === 'cn') {
        newPath = newPath.replace("/jp/", "/cn/");
      }
    } else if (newPath.startsWith("/cn/")) {
      if (language === "ko") {
        newPath = newPath.replace("/cn/", "/");
      } else if (language === "en") {
        newPath = newPath.replace("/cn/", "/en/");
      } else if (language === 'jp') {
        newPath = newPath.replace("/cn/", "/jp/");
      }
    } else {
      if (language === "en") {
        newPath = newPath.replace("/", "/en/");
      } else if (language === "jp") {
        newPath = newPath.replace("/", "/jp/");
      } else if (language === 'cn') {
        newPath = newPath.replace("/", "/cn/");
      }
    }

    await navigate(newPath);
  };

  const currentLang = i18n.language;
  const handleMenuClick = (path) => {
    if (i18n.language !== 'ko') {
      window.location.href = `/${currentLang}${path}`;
    } else {
      window.location.href = path;
    }
  };

  let logo = IconGlobal;

  if (darkMode) {
    logo = LogoLight;
  } else {
    logo = LogoLight;
  }

  const [isOpenIndex, setIsOpenIndex] = useState<number>(-1);
  const [isOpenIndexDepth, setIsOpenIndexDepth] = useState<number>(-1);

  const handleClickItem = (index: number) => {
    setIsOpenIndex(isOpenIndex === index ? -1 : index);
    setIsOpenIndexDepth(-1);
  };
  const handleClickItemDepth = (index: number) => {
    setIsOpenIndexDepth(isOpenIndexDepth === index ? -1 : index);
  };


  const [isOpenLangIndex, setIsOpenLangIndex] = useState<number>(-1);
  const handleClickLangItem = (index: number) => {
    setIsOpenLangIndex(isOpenLangIndex === index ? -1 : index);
  };


  return (
    <StyledMobileNav>

      <StyledMobileNavHeader>

        <StyledLogo>
          <Link to={currentLang === 'ko' ? '/' : `/${currentLang}`}>
            <StyledHeaderLogo src={logoWhite} alt='orbro logo' />
          </Link>
        </StyledLogo>


        <StyledMobileNavIconBox onClick={onClose}>
          {darkMode ? <CloseIconDark /> : <CloseIconDark />}
        </StyledMobileNavIconBox>

      </StyledMobileNavHeader>

      <Spacing pixel="16" />



      {/* 위쪽 */}
      {/* 위쪽 */}
      {/* 위쪽 */}
      {/* 위쪽 */}

      {isOpenIndex === -1 && (
        <>
          <StyledNavBody>

            {MainMenus.map((menu, i) => (
              <>
                <StyledNavBox isLastItem={i === MainMenus.length - 1}>

                  {menu.sub === '1' ? (
                    <StyledNavTitle onClick={() => handleClickItem(i)}>
                      <LabelColor color='#FFFFFF' size="medium"><Trans i18nKey={menu.name} /></LabelColor>
                      <StyledTitleIcon>
                        <ImgWH src={IconArrowRightWhite}
                          alt="faq-item-title-img" w='16' h='16' />
                      </StyledTitleIcon>
                    </StyledNavTitle>
                  ) :
                    (
                      <StyledNavTitle onClick={() => { handleMenuClick(menu.path) }}>
                        <LabelColor color='#FFFFFF' size="medium"><Trans i18nKey={menu.name} /></LabelColor>
                      </StyledNavTitle>
                    )
                  }


                </StyledNavBox>
              </>
            ))}

          </StyledNavBody>



          {/* 언어 */}
          {/* 언어 */}
          {/* 언어 */}
          <StyledNavBottom>

          <Link to={currentLang === 'ko' ? '/blog' : `/${currentLang}/blog`}>
              <StyledNavBox isLastItem={false}>
                <StyledNavTitle >
                  <LabelColor color='#FFFFFF' size="medium">
                    <Trans i18nKey={`nav.support.blog`} />
                  </LabelColor>
                </StyledNavTitle>
              </StyledNavBox>
            </Link>

            <Link to={currentLang === 'ko' ? '/pricing/1' : `/${currentLang}/pricing/1`}>
              <StyledNavBox isLastItem={false}>
                <StyledNavTitle >
                  <StyledLangBox>
                    <StyledIcon src={IconChevronDownWhite} alt='chevron down white icon'></StyledIcon>
                    <LabelColor color='#FFFFFF' size="medium">
                      <Trans i18nKey={`pricing.title`} />
                    </LabelColor>
                  </StyledLangBox>
                </StyledNavTitle>
              </StyledNavBox>
            </Link>

            {navSupport.lang.map((menu, i) => (
              <>
                <StyledNavBox isLastItem={true}>

                  <StyledNavTitle onClick={() => handleClickLangItem(i)}>
                    <StyledLangBox>
                      <StyledIcon src={IconGlobalWhite} alt='Global Icon'></StyledIcon>
                      <LabelColor color='#FFFFFF' size="medium">
                        <Trans i18nKey={`layoutEnterprise.homeLang.${i18n.language}`} />
                      </LabelColor>
                    </StyledLangBox>

                    <StyledTitleIcon>
                      <ImgWH src={isOpenLangIndex === i ? IconArrowUpWhite : IconArrowDownWhite}
                        alt="faq-item-title-img" w='16' h='16' />
                    </StyledTitleIcon>
                  </StyledNavTitle>

                  {menu.submenus?.map((data, j) => (
                    <React.Fragment key={j}>
                      <StyledNavDetail key={j} isOpen={isOpenLangIndex === i} onClick={() => (changeLanguage(j))}>
                        <LabelColor size="medium" color='#FFFFFF'>
                          <Trans i18nKey={`navEntSupport.lang.0.submenus.${j}.text`} />
                        </LabelColor>
                      </StyledNavDetail>
                    </React.Fragment>
                  ))}

                </StyledNavBox>
              </>
            ))}


          </StyledNavBottom>
        </>
      )}


      {/** 제품 하위 메뉴 **/}
      {isOpenIndex === 99 && (

        <NavDepthLayout>
          <StyledNavBoxDepth>
            <StyledNavTitleDepth onClick={() => handleClickItem(-1)}>
              <StyledTitleIcon>
                <ImgWH src={IconArrowLeftWhite}
                  alt="faq-item-title-img" w='16' h='16' />
              </StyledTitleIcon>
              <LabelColor color='#FFFFFF' size="medium"><Trans i18nKey={`layout.menus.PRODUCT.name`} /></LabelColor>
            </StyledNavTitleDepth>
          </StyledNavBoxDepth>

          {navMenu.product.map((menu, i) => (
            <Link to={menu.link === 'none' ?
              currentLang === 'ko' ? `${menu.path[0].link}` : `/${currentLang}${menu.path[0].link}` :
              currentLang === 'ko' ? `${menu.link}` : `/${currentLang}${menu.link}`} key={i}>
              <StyledNavBox isLastItem={false} key={i}>
                <StyledNavTitle onClick={() => handleClickItemDepth(i)}>
                  <LabelColor color='#FFFFFF' size="medium"><Trans i18nKey={`navMenu.product.${i}.title`} /></LabelColor>
                </StyledNavTitle>
              </StyledNavBox>
            </Link>
          ))}

          <Link to={currentLang === 'ko' ? '/os' : `/${currentLang}/os`}>
            <StyledNavBox isLastItem={true} >
              <StyledNavTitle onClick={() => handleClickItemDepth(7)}>
                <LabelColor color='#FFFFFF' size="medium"><Trans i18nKey={`navMenu.OS.title`} /></LabelColor>
              </StyledNavTitle>
            </StyledNavBox>
          </Link>

        </NavDepthLayout>
      )}


      {/** 솔루션 하위 메뉴 **/}
      {isOpenIndex === 6 && (

        <NavDepthLayout>
          <StyledNavBoxDepth>
            <StyledNavTitleDepth onClick={() => handleClickItem(-1)}>
              <StyledTitleIcon>
                <ImgWH src={IconArrowLeftWhite}
                  alt="faq-item-title-img" w='16' h='16' />
              </StyledTitleIcon>
              <LabelColor color='#FFFFFF' size="medium"><Trans i18nKey={`layout.menus.SOLUTION.name`} /></LabelColor>
            </StyledNavTitleDepth>
          </StyledNavBoxDepth>

          {navMenu.solution.map((menu, i) => (
            <StyledNavBox isLastItem={false} key={i}>
              <StyledNavTitle onClick={() => handleClickItemDepth(i)}>
                <LabelColor color='#FFFFFF' size="medium"><Trans i18nKey={`navMenu.solution.${i}.title`} /></LabelColor>
                <StyledTitleIcon>
                  <ImgWH src={isOpenIndexDepth === i ? IconArrowUpWhite : IconArrowDownWhite}
                    alt="faq-item-title-img" w='16' h='16' />
                </StyledTitleIcon>
              </StyledNavTitle>

              {menu.path?.map((data, j) => (
                <React.Fragment key={j}>
                  <StyledNavDetail key={j} isOpen={isOpenIndexDepth === i}>
                    <Link to={currentLang === 'ko' ? `${data.link}` : `/${currentLang}${data.link}`}>
                      <LabelColor size="medium" color='#FFFFFF'>
                        <Trans i18nKey={`navMenu.solution.${i}.path.${j}.text`} />
                      </LabelColor>;
                    </Link>
                  </StyledNavDetail>
                </React.Fragment>
              ))}

            </StyledNavBox>
          ))}

          <Link to={'https://home.orbro.io'}>
            <StyledNavBox isLastItem={true} >
              <StyledNavTitle onClick={() => handleClickItemDepth(7)}>
                <LabelColor color='#FFFFFF' size="medium"><Trans i18nKey={`navMenu.world.title`} /></LabelColor>
              </StyledNavTitle>
            </StyledNavBox>
          </Link>

        </NavDepthLayout>
      )}



      {/** 지원 하위 메뉴 **/}
      {isOpenIndex === 7 && (

        <NavDepthLayout>
          <StyledNavBoxDepth>
            <StyledNavTitleDepth onClick={() => handleClickItem(-1)}>
              <StyledTitleIcon>
                <ImgWH src={IconArrowLeftWhite}
                  alt="faq-item-title-img" w='16' h='16' />
              </StyledTitleIcon>
              <LabelColor color='#FFFFFF' size="medium"><Trans i18nKey={`layout.menus.SUPPORT.name`} /></LabelColor>
            </StyledNavTitleDepth>
          </StyledNavBoxDepth>

          {navMenu.support.map((menu, i) => (
            <>
              {menu.title !== ' ' &&
                <StyledNavBox isLastItem={i === 3} key={i}>
                  <StyledNavTitle onClick={() => handleClickItemDepth(i)}>
                    <LabelColor color='#FFFFFF' size="medium"><Trans i18nKey={`navMenu.support.${i}.title`} /></LabelColor>
                    <StyledTitleIcon>
                      <ImgWH src={isOpenIndexDepth === i ? IconArrowUpWhite : IconArrowDownWhite}
                        alt="faq-item-title-img" w='16' h='16' />
                    </StyledTitleIcon>
                  </StyledNavTitle>

                  {menu.path?.map((data, j) => (
                    <React.Fragment key={j}>
                      <StyledNavDetail key={j} isOpen={isOpenIndexDepth === i}>
                        {menu?.link === 'new' ? <>
                          <a href={`${data.link}`} target="_blank" rel="noopener noreferrer">
                            <LabelColor size="medium" color='#FFFFFF' >
                              <Trans i18nKey={`navMenu.support.${i}.path.${j}.text`} />
                            </LabelColor>
                          </a>

                        </>
                          :
                          <Link to={currentLang === 'ko' ? `${data.link}` : `/${currentLang}${data.link}`}>
                            <LabelColor size="medium" color='#FFFFFF' >
                              <Trans i18nKey={`navMenu.support.${i}.path.${j}.text`} />
                            </LabelColor>
                          </Link>
                        }
                      </StyledNavDetail>
                    </React.Fragment>
                  ))}

                </StyledNavBox>
              }
            </>
          ))}


        </NavDepthLayout>
      )}


    </StyledMobileNav>
  );
};

export default MobileNav;
