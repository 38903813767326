import { SCREEN_BREAKPOINT, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledLabel, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";
import { StyledGridColumnProps } from '@components/Grid/Grid.model';

interface LayoutInfo {
  breakPoint: number;
  padding: number;
  gap: number;
  columns: number;
}

const DESKTOP: LayoutInfo = {
  breakPoint: 1280,
  gap: 32,
  padding: 24,
  columns: 12,
};

const TABLET: LayoutInfo = {
  breakPoint: 960,
  gap: 32,
  padding: 24,
  columns: 8,
};

const MOBILE: LayoutInfo = {
  breakPoint: 768,
  gap: 32,
  padding: 16,
  columns: 4,
};

interface StyledGridRowProps {
  desktop?: number
  tablet?: number
  mobile?: number
}


export const StyledBlog = styled.div`
  width: 100%;
  
  & * {
    font-family: Pretendard !important; 
    font-display: swap;
  }
`;

export const StyledGridContainer = styled.div`
  width: 100%;
  position: relative;
  justify-content: center;
  margin: 0 auto;

  max-width: ${DESKTOP.breakPoint}px;
  padding: 0 ${DESKTOP.padding}px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding: 0px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 0px;
  }
`;


export const StyledBack = styled.div`
  margin-top : 48px;
  margin-bottom : 48px;
  height : 20px;


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    display : none;
    margin-top : 0px;
    height : 0px;
  }

`;


export const StyledBackLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    display : none;
  }

`;



export const StyledBackArrow = styled.img`
  cursor : pointer;
  color : black;
  width : 20px;
  height : 20px;


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-left : 24px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 0px;
  }
`;


export const StyledTitleBanner = styled.div`
  width: 100%;
  height: 500px;
  border-radius: 24px;
  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;


  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    border-radius : 0px;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    border-radius : 0px;
    
  }
`;


export const StyledIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top : 16px;
  margin-bottom : 24px;
  
  height : 32px;
  gap : 8px;


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    justify-content: flex-start;
  }

  
`


export const StyledBackup = styled.a`
  width : 32px;
  height : 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius : 16px;
  background-color : #3676B0;
  padding: 9.2px;
  &:hover {
    cursor: pointer;
  }
`


export const StyledLinkedIn = styled.div`
  width : 32px;
  height : 32px;
  border-radius : 16px;
  &:hover {
    cursor: pointer;
  }
`


export const StyledTwitter = styled.div`
  width : 32px;
  height : 32px;
  border-radius : 16px;

  &:hover {
    cursor: pointer;
  }
`


export const StyledFacebook = styled.div`
  width : 32px;
  height : 32px;
  border-radius : 16px;
  

  &:hover {
    cursor: pointer;
  }
`


export const StyledIcon = styled.img`
  max-width: 100%;
  max-height: 100%;

`


export const StyledTagBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap : wrap;

  gap: 16px;


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    justify-content: flex-start;
    align-items: flex-start;
  }

`


export const StyledTagBoxBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap : wrap;

  gap: 16px;


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
  }


`


export const StyledTag = styled(StyledLabel)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 16px;

  color : white;
  background: rgba(255, 255, 255, 0.03);

  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;


`


export const StyledTagBottom = styled(StyledLabel)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 16px;

  color : black;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;


`


export const StyledTextGrid = styled.div`
  padding-left : 105px;
  padding-right : 105px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding-left : 24px;
    padding-right : 24px;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-left : 16px;
    padding-right : 16px;
    
  }

`






export const StyledBlogShareBox = styled(StyledHeading)`
  width : 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align : center;
  
  font-weight: 600;
  color : #818181;

`




export const StyledBlogDisplay = styled(StyledDisplay)`
  font-weight: normal;

  display : flex;
  text-align : center;
  font-weight: 700;
  color : #FFFFFF;

  & strong {
    
  }


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    text-align : left;
    
  }

`;

export const StyledBlogHeading = styled(StyledHeading)`

`;


export const StyledBlogPara = styled(StyledParagraph)`
  margin-top : 8px;

`;


export const StyledBlogParaSub = styled(StyledParagraph)`
  margin-top : 8px;

  text-indent: -1em;
  margin-left: 1em;
`;



export const StyledBlogIntro = styled(StyledParagraph)`
  margin-top : 64px;
  font-weight : 400;
`;


export const StyledBlogIntroHeading = styled(StyledHeading)`
  margin-top : 64px;
`;


export const StyledBlogIntroTheme = styled(StyledHeading)`
  margin-top : 64px;
  margin-bottom : 8px;
  font-weight : 700;
`;




export const StyledBlogLabel = styled(StyledLabel)`
`;


export const StyledBlogDateBox = styled.div`
  display : flex;
  margin-top: 16px;
  justify-content : center;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    justify-content : flex-start;
    
  }

`;

export const StyledBlogDateLogo = styled.img`
  width : 20px;
  height : 20px;
`;

export const StyledBlogDate = styled(StyledLabel)`

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #BDBDBD;
  margin-left : 4px;
`;




// Card - Start
export const StyledBlogRow = styled(StyledGridRow)`
  margin-top: 64px;
  row-gap : 32px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-top: 48px;
    
  }

`;


export const StyledBlogTable = styled.table`
  border-collapse: collapse;
  border-spacing : 0px;

  margin-top : 8px;
  table-layout: fixed;
  width: 100%;
  font-size: 16px;
  line-height : 1.5;
  

  & * {
    font-family: Pretendard !important; 
    font-display: swap;
  }

  td:first-child {
    width: 100px;
  }

  td:not(:first-child) {
    width: 150px;
  }
  td {
    text-align: center;
  }

`

export const StyledGridRowGap0 = styled.div<StyledGridRowProps>`
  width: 100%;
  position: relative;
  gap: 0px;
  flex-wrap: wrap;
  display: grid;
  display: -ms-grid;

  -ms-grid-columns: repeat(${({ desktop = DESKTOP.columns }) => desktop}, 1fr);
  grid-template-columns: repeat(${({ desktop = DESKTOP.columns }) => desktop}, 1fr);

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    -ms-grid-columns: repeat(${({ tablet = TABLET.columns }) => tablet}, 1fr);
    grid-template-columns: repeat(${({ tablet = TABLET.columns }) => tablet}, 1fr);
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    -ms-grid-columns: repeat(${({ mobile = MOBILE.columns }) => mobile}, 1fr);
    grid-template-columns: repeat(${({ mobile = MOBILE.columns }) => mobile}, 1fr);
    row-gap: 16px;
  }
`;


export const StyledGridContainerAbsolute = styled.div`
  width: 100%;
  position: absolute;
  justify-content: center;
  margin: 0 auto;

  max-width: ${DESKTOP.breakPoint}px;
  padding: 0 ${DESKTOP.padding}px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding: 0px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 0px;
  }
`;


export const BanenrText = styled.div`
  text-align : center;
  z-index : 1;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    width : 100%;
    text-align : start;
  }
`;