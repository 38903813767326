import {
  navImgAccess,
  navImgBeacon,
  navImgCamera,
  navImgIOT,
  navImgOS,
  navImgRTLS,
  navImgSensor,
  navImgWorld
} from "@assets/images/nav";

export default {
  product: [
    {
      icon: navImgRTLS,
      title: '無線位置追跡',
      link: '/jp/rtls',
      path: [
        {
          text: '高精度（UWB）位置追跡',
          link: '/jp/rtls/uwb',
        },
      ]
    },
    {
      icon: navImgBeacon,
      title: 'ビーコンデバイス',
      link: 'none',
      path: [
        {
          text: 'Bluetooth（BLE）位置追跡',
          link: '/jp/beacon/ble',
        },
        {
          text: '産業用ビーコン',
          link: '/jp/beacon/industry',
        },
        {
          text: '小型ビーコン',
          link: '/jp/beacon/tag',
        },
        {
          text: '資産用タグ',
          link: '/jp/beacon/asset-tag',
        },
      ]
    },
    {
      icon: navImgAccess,
      title: 'アクセスコントロールシステム',
      link: '/jp/access',
      path: [
        {
          text: '自動ドアアクセス',
          link: '/jp/access/auto',
        },
        {
          text: '顔認識アクセス',
          link: '/jp/access/face',
        },
        {
          text: 'アクセスコントロールアクセサリ',
          link: '/jp/access/accessory',
        }
      ]
    },
    {
      icon: navImgCamera,
      title: 'AI 位置追跡',
      link: '/jp/camera',
      path: [
        {
          text: '屋外カメラ',
          link: '/jp/camera/outdoor',
        },
        {
          text: '屋内カメラ',
          link: '/jp/camera/indoor',
        },
      ]
    },
    {
      icon: navImgSensor,
      title: '産業用IoT',
      link: 'none',
      path: [
        {
          text: 'LoRaゲートウェイ',
          link: '/jp/sensor/LoRaGateway',
        },
        {
          text: 'LoRaセンサー',
          link: '/jp/sensor/LoRaSensor',
        },
        {
          text: '空気品質モニター',
          link: '/jp/sensor/air',
        },
        {
          text: '一酸化炭素モニター',
          link: '/jp/sensor/co',
        }
      ]
    },
    {
      icon: navImgWorld,
      title: 'Integration',
      link: '/jp/integration',
      path: [
        {
          text: 'ロボットシステム',
          link: '/jp/integration/robot',
        },
      ]
    },
  ],

  OS: {
    icon: navImgOS,
    title: "ORBRO OS",
    desc: 'アクセスコントロールシステムからリアルタイム位置追跡、さまざまなIoT制御まで、ORBRO OSはあらゆる面でスペースをスマートにする統一されたサービスです。すぐにスマートな未来を体験してください。',
    more: '詳細を見る',
    link: '/jp/os',
  },

  solution: [
    {
      title: '建設',
      path: [
        {
          text: '労働者の安全管理',
          link: '/jp/enterprise/construction/safety'
        },
        {
          text: '建設資産管理',
          link: '/jp/enterprise/construction/asset'
        },
        {
          text: '重機管理',
          link: '/jp/enterprise/construction/equipment'
        },
      ]
    },
    {
      title: '物流',
      path: [
        {
          text: '物流在庫管理',
          link: '/jp/enterprise/logistics/asset'
        },
        {
          text: '倉庫作業員の安全管理',
          link: '/jp/enterprise/logistics/safety'
        },
      ]
    },
    {
      title: '病院',
      path: [
        {
          text: '患者の位置追跡',
          link: '/jp/enterprise/medical/patient'
        },
        {
          text: '医療廃棄物管理',
          link: '/jp/enterprise/medical/waste'
        },
      ]
    },
    {
      title: '製造',
      path: [
        {
          text: '製造プロセス管理',
          link: '/jp/enterprise/manufacturing/process'
        },
        {
          text: '製造資産管理',
          link: '/jp/enterprise/manufacturing/asset'
        },
      ]
    },
    {
      title: 'オフィス',
      path: [
        {
          text: 'オフィス資産管理',
          link: '/jp/enterprise/office/asset'
        },
      ]
    },
    {
      title: '小売',
      path: [
        {
          text: '展示場訪問者分析',
          link: '/jp/enterprise/retail/exhibition'
        },
      ]
    }
  ],

  world: {
    icon: navImgWorld,
    title: "ORBROデジタルツイン",
    desc: '現実世界の人、物、建物、そして都市のデータを受け取り、デジタル画面内の仮想現実でリアルタイムにモニタリングおよび管理することができます。これにより、生産性の向上と問題の予防が可能となり、主に産業と製造分野で活用されます。',
    more: '詳細を見る',
    link: '/jp/digital-twin',
  },


  support: [
    {
      title: 'カスタマーサポート',
      path: [
        {
          text: '会社紹介',
          link: '/jp/company'
        },
        {
          text: '営業お問い合わせ',
          link: '/jp/request'
        }
      ]
    },
    {
      title: '技術紹介',
      path: [
        {
          text: 'UWB',
          link: '/jp/enterprise/technology/uwb'
        },
        {
          text: 'BLE',
          link: '/jp/enterprise/technology/ble'
        },
        {
          text: 'ステレオビジョン',
          link: '/jp/enterprise/technology/stereo-vision'
        },
        {
          text: 'モノキュラービジョン',
          link: '/jp/enterprise/technology/monocular-vision'
        },
        {
          text: 'TDoA',
          link: '/jp/enterprise/technology/tdoa'
        },
        {
          text: 'TWR',
          link: '/jp/enterprise/technology/twr'
        },
        {
          text: 'Geofence',
          link: '/jp/enterprise/technology/what-is-geofence'
        },
        {
          text: 'Digital Twin',
          link: '/jp/enterprise/technology/what-is-digital-twin'
        },
      ]
    },
    {
      title: 'ソフトウェア',
      path: [
        {
          text: 'iOS',
          link: 'https://apps.apple.com/us/app/orbro-service/id1673356996'
        },
        {
          text: 'Android',
          link: 'https://play.google.com/store/apps/details?id=com.orbro.mobile'
        },
        {
          text: 'ORBRO World',
          link: 'https://home.orbro.io'
        },
        {
          text: 'Release Note (only KOR)',
          link: '/release-note'
        },
      ]
    },
    {
      title: 'ソーシャル',
      path: [
        {
          text: 'LinkedIn',
          link: 'https://www.linkedin.com/kongtech/'
        },
        {
          text: 'Naver',
          link: 'https://blog.naver.com/orbro_platform'
        },
        {
          text: 'Medium',
          link: 'https://medium.com/@orbro'
        },
      ]
    },
  ],
  footerSupport: [

    {
      title: 'サポート',
      path: [
        { text: "ブログ", link: "/jp/blog" },
        { text: "営業お問い合わせ", link: "/jp/request" },
        { text: 'Release Note (only KOR)', link: '/release-note' },
      ]
    },
    {
      title: 'サービス',
      path: [
        { text: "オルブロワールド", link: "https://home.orbro.io" },
      ]
    },
    {
      title: 'ダウンロード',
      path: [
        { text: "iOSアプリをダウンロード", link: "https://apps.apple.com/us/app/orbro-service/id1673356996" },
        { text: "Androidアプリをダウンロード", link: "https://play.google.com/store/apps/details?id=com.orbro.mobile" },
      ]
    },
  ]

}
