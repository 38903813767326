import { StyledGridContainer } from "@components/Grid/Grid.style";
import React from "react";
import { AdvantageInnerSection, AdvantagePage, AdvantageTextBox, Icon, IconBox, IconSection, IconTextBox, Rimg, Rsection } from "./Advantage.style";
import { Trans } from "react-i18next";
import { DisplayColor, HeadingColor, ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import access from "@src/lang/ko/main/access/access";
import { useSelector } from "react-redux"
import { CommonState } from "@redux/reducers/commonReducer";




const Advantage = () => {

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  return (
    <>
      <AdvantagePage>
        <StyledGridContainer>
          <AdvantageInnerSection>
            <AdvantageTextBox>
              <DisplayColor color="#FFF" size="small">
                <Trans i18nKey={`accessMain.advantage.title`} />
              </DisplayColor>
              <Spacing pixel="16"/>
              <ParagraphColor color="#FFF" size="medium">
                <Trans i18nKey={`accessMain.advantage.desc`} />
              </ParagraphColor>
              <Spacing pixel="64"/>
              <IconSection>
                {access.advantage.card.map((data,i)=>(
                  <>
                    <IconBox>
                      <Icon src={data.icon} alt="function icon"/>
                      <IconTextBox>
                        <HeadingColor color="#FFF" size="xxxsmall">
                          <Trans i18nKey={`accessMain.advantage.card.${i}.title`} />
                        </HeadingColor>
                        <Spacing pixel="4"/>
                        <ParagraphColor color="#BDBDBD" size="small">
                          <Trans i18nKey={`accessMain.advantage.card.${i}.desc`} />
                        </ParagraphColor>
                      </IconTextBox>
                    </IconBox>
                  </>
                ))}
              </IconSection>
            </AdvantageTextBox>
            <Rsection>
              <Rimg src={access.advantage.img[layoutType]} alt="ORBRO access product image"/>
            </Rsection>
          </AdvantageInnerSection>
        </StyledGridContainer>
      </AdvantagePage>
    </>
  )
}

export default Advantage;