
import { IconOsOff, IconOsOn } from '@assets/images/main/icon';
import { loraGatewayOn, loraGatewayOff, loraSensorOn, loraSensorOff, airOn, airOff, coOn, coOff } from '@assets/images/main/index';


export default {



    bannerData: [
        {
            icon: loraGatewayOn,
            iconInv: loraGatewayOff,
            image: '',
            iconText: 'LoRa<br/>중계기',
            sub: 'New',
            link: '/sensor/LoRaGateway'
        },
        {
            icon: loraSensorOn,
            iconInv: loraSensorOff,
            image: '',
            iconText: 'LoRa<br/>센서',
            sub: 'New',
            link: '/sensor/LoRaSensor'
        },
        {
            icon: airOn,
            iconInv: airOff,
            image: '',
            iconText: '공기품질<br/>측정기',
            sub: 'New',
            link: '/sensor/air'
        },
        {
            icon: coOn,
            iconInv: coOff,
            image: '',
            iconText: '일산화탄소<br/>측정기',
            sub: '',
            link: '/sensor/co'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/os'
        },


    ],





}