import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from '@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4';

export default {


  helmet: '| ビーコンとは？ | オルブロ、韓国No.1のビーコン企業',
  desc: 'ビーコンは特定の場所にあるスマートデバイスに情報を送信するために無線通信を利用する小さな無線送信機です。主にBluetooth Low Energy（BLE）技術を使用し、小さなサイズと低消費電力が特徴です。',
  keywords: 'ビーコン、IoT、無線通信、屋内位置特定、位置追跡、Bluetooth、BLE、プルトコン、オルブロ、韓国、コンテック',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'ビーコンとは？ | オルブロ、韓国No.1のビーコン企業 :: オルブロブログ',
  ogUrl: 'https://orbro.io/jp/blog/beacon',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxa-ucISW1k_6fC9t-ZpGPIV1qRXWXcZO1OhYuIe-j3MLl1BdXVIIv40aG69pNCT6AD0WANYJ1p4OjzDQF9yM8_m5CaFglWOtunF7ZF0uySt_dYAB4pE7shLBOrdeIkyxz_3esBC_Qd-x3FmnZ4jLNcZazyIYAPR_ZuRbLW2E_sui_6vQWc2_iABmwSImfVtoyWAhaCjoHOk7UonwVJTlXVq-NJmIqcpT0-KgFYxoTYR_i_qaS1PR8hzLVcOzzk1Z7ng11UP9IaQiWcX6pFAZnxvGsUJDNz9eiIxIRifGlSF2rGl3O6KQKRRdOU1LATUHY1Rkym6F5nLo87mxlA9iqybXGIED_3BG2ywgaf3uYgBloZH3CGqi4IMUUD-dN7cbbwxrQyiEppY9GA0xNk9uatZggX5TaYItKRL0fGmJXsHn18bQHWue-OCSVs_IjMmQ6omlyiJiQAVMSGu65C3hd8ZIQGZDq0EzutRLIXyb7f6OVVcogIw8fmAHnyhzYh-ffT1Rl0kp7BSYB0y-ooqoM_z3ut5Znu5TWC4EndXrRa4BB7KOnh--fa8H0-rbNiorUZmhoedmMZHCUuHKEjiyshQaX9UiAn2Rr-Uvtuvqs-TYIRAHroGgOBrzaGBEycwr3rifKxJx-fBbAO8fe7xhZD8RNeniCCIXkf4o_crQdpMZuDeKh5_f7URFr8NU_5vs74I1lhdV_Bgq5bYEI0-b7N7JV4-Wr_zSbOLTMb5EZ-vyj43hGUKLdgOUHTYYNP8plRV5_UMElexKEid5ZmX2aj1QrTT9zBm5c_SunfkZOEK6kY6GuV7NagmSgQDhcVmX_Qgf9tJoEw07dJxwXPxOzZPO5nZDkX_xA1oDKNNsgNpPXuVjmEI2K_O-syucjYFlVnVagL7aQ-V4givU9qKYBasb6s2hfajR4L4BAvyBp-EiItjTy3dK4H8fk1sdf6OlqMfNS3DQqBu5gpyMD28HyW0gk7wC-hs0C3EsglleW016V9ELEcMiX9OTvM1dozJQFzpMEXve9fw44h-D0lW5MuJIfDb_HvD4FB0ZVrab-8kKD-99NVk2mfZigqE3tPXhEb559joMsf5UYlRvjLz7DqnBmNwPBMQavjtKTmwq6gPHsLn4hR2ENgscKrdmGFYbt-pSwwinouRHIWBOAeLbIU4R9AOWId11R5oG0IFEuiAib3dTKuzObWCeWjN4aP-24uX46l_DnyL1K9zDS3MOZbqbQOaTjkTIsx7FlLrk1iw5P3RxjboS7VC048bElpy68OsGGr5c30D4owv38GeXx3eQm4P8RDq7Ubxe9W3Z1a-CNQhDMGEBpns6BLrZ2d8AO3TaS7HhGcvmbbGMsdgmoPRZgElmoN6l1a7ktrDjuTD2NWNvLUJ6d7WxCpEG9S5RClt8JSoR884w_PQAONR_WMbvHpL-xm_fuWqWDDpYT1_7ScbWFIwBwUTop8jrIiPrrgrHHNAVloR9YwiH3J_U5jMMK9rNENYv9rHahEOLrwcJzGzbnbbkocERqVusvbsC1pl-fPUAVDjtgKf89d9X6FPsVJ95xED_hnGthC4JfXqLnPbKG_=w3356-h2242',
  ogType: 'ウェブサイト',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'ビーコンとは？ | オルブロ、韓国No.1のビーコン企業',
  date: '2023-11-08',
  tag1: '#ビーコン',
  tag2: '#Bluetooth',
  tag3: '#BLE',
  tag4: '#オルブロ',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: 'ビーコンは現代のデジタル時代の革新的な技術の一つで、日常生活とビジネス分野に適用されています。ビーコンとは何か、どのように動作するか、およびその応用分野について調査しましょう。',
      dot: ''
    },
    {
      head: 'ビーコンとは？',
      subHead: '',
      desc: 'ビーコンは特定の場所にあるスマートデバイスに情報を送信するために無線通信を利用する小さな無線送信機です。主にBluetooth Low Energy（BLE）技術を使用し、小さなサイズと低消費電力が特徴です。ビーコンは周囲の環境にその存在と位置を通知できます。',
      dot: ''
    },
    {
      head: 'ビーコンの動作原理',
      subHead: '',
      desc: 'ビーコンは非常に単純な動作原理を持っています。定期的に信号を発信し、周囲のスマートデバイス（例：スマートフォン）はこれらの信号を検出し、ビーコンの位置を特定します。これにより、スマートデバイスは特定の場所で特定のアクションを実行できるようになります。',
      dot: ''
    },
    {
      head: '応用分野',
      subHead: '',
      desc: 'ビーコンはさまざまな分野で使用されており、特に次の分野で注目されています:',
      dot: {
        a: '<strong>マーケティングと広告:</strong> 店舗や博物館では、ビーコンを使用して顧客に特別な割引や情報を提供し、広告を効果的に行えます。',
        b: '<strong>位置情報サービス:</strong> ビーコンを使用して屋内で正確な位置情報を提供し、ナビゲーション機能を向上させることができます。',
        c: '<strong>スマートホームとIoT:</strong> ビーコンを使用して家庭内のさまざまなスマートデバイスを接続し、自動化と利便性を向上させることができます。',
        d: '<strong>ヘルスケア:</strong> 患者のモニタリングや医療機器で使用して、医療サービスの品質を向上させます。'
      }
    },
    {
      video: video,
      link: "https://orbro.io/jp/enterprise/medical/patient",
      head: '利点',
      subHead: '',
      desc: 'ビーコン技術は他の位置情報技術と比較していくつかの利点を持っています。',
      dot: {
        a: '<strong>精度:</strong> ビーコンは正確な位置情報を提供し、屋内などのGPS信号が届かない環境でも動作します。',
        b: '<strong>低消費電力:</strong> ビーコンは低消費電力のため、長寿命のバッテリーを持っています。',
        c: '<strong>使いやすさ:</strong> ビーコンの設定と管理は簡単です。'
      }
    },
    {
      head: 'まとめ',
      subHead: '',
      desc: 'ビーコン技術は現代の技術の一つであり、私たちの日常生活とビジネスに大きな影響を与える重要な技術の一つです。その応用分野は拡大し続けており、将来的にさまざまな革新的なサービスと機会を提供すると期待されています。ビーコンの未来に対する高い期待を持ち、この技術を活用して私たちの生活をよりスマートで便利にすることはいかがでしょうか？',
      dot: ''
    },

  ],

  link: {
    page: 'access',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.auto.accessauto.title,
        title: productPurchase.purchase.auto.accessauto.title,
        serial: productPurchase.purchase.auto.accessauto.serial,
        desc: '自動ドアのアクセスに特化した製品で、既存の自動ドアセンサーを交換し、モバイルアプリをインストールすることで非接触の出入りが可能になります。',
        img: productImg.product.accessauto.key1
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/jp/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/jp/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      }
    ]

  }


}
