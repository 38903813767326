import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


    helmet: '',
    desc: 'Real-Time Location Tracking System (RTLS) is a perfect solution for safely managing medical waste. By monitoring until risks are eliminated, it can prevent safety incidents.',
    keywords: 'medical waste management, medical waste disposal, waste management, waste disposal, medical waste, medical accident management, medical equipment management, safety accident management, medical facilities, hospital waste management, hospital waste disposal, hospital waste, contaminated equipment management, contaminated equipment disposal, real-time location tracking, RTLS, ORBRO, Kongtech, ORBRO',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'Smart Technology for Managing Medical Waste and Assets :: ORBRO Blog',
    ogUrl: 'https://orbro.io/en/blog/medicalWaste',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4MONQZidMWIaTrmLqrkg911u867GiKnQvRp2J_6pXN-o_ulq8j5B2yFxT7ir2txi5c7bIVIt8hiyAS_8bBcYHCvfHVgU19Cv-9Zti_3iLiSQjzwRtscyVXg8ra_zcueLOOTV98WMwQPJvqq6lLm9WCS1uuU0kQTDiObKEJgcPUH1VWuCsr2icfJp2XeQo37pAjWJRRYRO0V94Rfg7K5kCNY2i6xyCb9v7ScCCWn1TDV770PCeZCTaqxvMsBaKLqMGHrg7rel0zDkwCNNCmA3Ztbw-uSIi1yvmAFmD-UwvSJ_CDlAoODePdXtGUuvvbo7nwKL38Ku_KgOEwMjm_fu5OmSTGuXLj-6GyiDXO_BGO4kRGAVRu1XLrfWPPX4GUQvDghijtlavji5s42a6EmSNBQKQ5PNV6Xi1LKTyhsOFRT6H69kayRpXz0PGQr9o9PwF9V8wif_gyo5Yp3WupUyxj2Bjc7jiHRBLBbeT8jDbZzdNEcYtdGJm6AiYBu1d3eNxk3awkxVXesGtS7MS_zXLKg03XqO_AyofzksiJ4jqyJBJu-atlMmflMnpcow2pp_JmyyTwQqPnikDNlTZ3iywdxdsseVVU_LfDL_koySbuLXVfcbrwXThmZI0aBrW-7R3HsTN_axhMhnxoIf16Le7Oe_9dD2o29Udu271POE6W1-FFfn7mj9Qh3td3uOl5KqXAZKoy3pB9Zw5bn3wgCANBWgV5BuOaQS5tPDxrFloyRt8VHGipdKgP5Cvz8M0ZiHdq_S3Ykh3eXE2z8Mu5e1ruL-FRtmbxQTl-PaOT6LrZS3WPhkOZrgEPel4AFv93GXcBg1YXw4MHsW-w6k_pJWGv43aL2AOsJ0G-xSso8gxdCh8Z6A3j75V0HVSHZ77OVUrJsYgEIXN-XGaCdMmDEaIJEGaJkC6ryDiPWnbogd0em8sf7YiKictJq9jl-aD_OGuOALvvAAmKdAsVzzZGMUp19SWDeI4R1UT_uYZ1_vC8ocRAurJg-kwM_gZu2TlLRPMkLUdDFDNVDoOYGyq47vgWJNg6KX-VtP5NwZ1K0afRlbOl1ho7d69Hl15XsQiqOQla9c1j7YFVQiUFWpx6GR3QhPt6XHSOufQfw6OOqtMz5s3Ibt69c0hgIzV1I2dVXetfGUDWGSoYXmpbxpF2M0KrnAjCojSgEXMU6X4DEcdo_yJrru5Tl8J1_cBMr0nm09QJSNg-qXDoYtS6wq-L1L4uy-MlHr_N4G6minr4oerAf1zu8o9jL3e9XpOkJdIo0sYln8FRjzLZ4J8AdpQF4pDo768VucYPzCgSrOJ_4-ARZoCuJKqB_lQJ2bIAIST0_STyL4tpQPSFWwnrMgAdgMgAams6EhbAQCgMV1xTZjRvd5DptWwTMknoV2YJ4sVcLtlZ3sdE4wOhJH7Oucjab0uDCuYhl3AjrdtZB3TgemIMOWwuji09pERKa7q1rMo6mwI5TiHI6vt4OzEshaO1UQRMcB0mxtkJsU7xT7bTkz1c2i1SRNWplYz9TzJnWVFqcQQeQ5XcTMaHkjM1_774K3ZNMFShH_yqajpvElOIl_J4JSnz0n1gneniKhbI3DQ=w2000-h1794',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',


    title: 'Smart Technology for Managing Medical Waste and Assets',
    date: '2023-08-30',
    tag1: '#MedicalWaste',
    tag2: '#PreventingMedicalAccidents',
    tag3: '#MedicalEquipmentManagement',
    tag4: '#WasteDisposal',

    // <br/>
    text: [
        {
            head: '',
            subHead: '',
            desc: 'Administrators need to pay attention to more than just patients in medical facilities. Medical waste and equipment are significant assets that directly affect patients, requiring special care and attention. For instance, cases of secondary infection incidents due to improper disposal of contaminated syringes or waste are not hard to find. Additionally, while the amount of medical waste to manage increases each year, cases of facilities implementing systems for this purpose are hard to come by. Strengthening safety management protocols and providing education to staff might be the extent of it. However, since medical accidents are critical matters involving lives, a safer solution is needed. Recent growth in Real-Time Location Tracking (RTLS) technology offers a way to address these issues in the medical industry. RTLS technology allows for real-time tracking of the current location of objects. So, how could such technology benefit medical facilities?',
            dot: ''

        },
        {
            head: '',
            subHead: '1. Management of Medical Waste and Equipment Quantities',
            desc: `If you are responsible for waste disposal, it's important to know how much waste needs to be disposed of and how many times it's stored in the facility. While physically searching the entire facility is an option, adopting an RTLS solution allows for quick and easy retrieval. Similarly, in cases of surgeries, treatments, malfunctions, or losses, RTLS enables fast and accurate tracking of the location of medical equipment within the facility.`,
            dot: ''
        },
        {
            head: '',
            subHead: '2. Real-Time Location Tracking',
            desc: `During waste disposal, there's a possibility of items being mixed up or exchanged with other objects. To prevent this and monitor the process, real-time tracking of waste movement routes is established. If waste that hasn't been properly processed moves into patient rooms or restricted areas, automatic alerts are sent for immediate response. Furthermore, RTLS allows monitoring situations where medical equipment is brought into or taken out of the facility, enhancing asset management efficiency.`,
            dot: ''
        },
        {
            head: '',
            subHead: '3. Automatic Check-In and Check-Out',
            desc: 'Records and stores information about equipment or waste being checked in or out, eliminating the need for manual input, whether handwritten or computer-based. This process is stored in the cloud for safekeeping. It can serve as essential information for facility operation and be useful in cases where legal evidence is required, such as accidents.',
            dot: ''
        },
        {
            head: '',
            subHead: '4. Additional Functionality',
            desc: 'Real-time location tracking technology isn’t limited to objects alone. It can also be used to track the real-time location of patients, including those needing special care. Alerts can be automatically triggered when patients access restricted areas, including waste zones, enabling swift location identification. This facilitates the storage of patient movement data, which, in case of infectious outbreaks like COVID-19, can aid in accurate and quick epidemiological investigations. This paves the way for a more evolved patient management system based on real-time location tracking.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Conclusion',
            desc: 'Many industries are undergoing changes in the wake of the Fourth Industrial Revolution. The processes mentioned above transform traditional methods (which were prone to errors) of operation. By reducing manpower and costs while increasing efficiency and productivity, safer facilities can be established.',
            dot: ''
        },
    ],


    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'This product facilitates real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/en/enterprise/medical/patient'
            },
            {
                img: medicalWaste.thumb,
                data: 'medicalWaste',
                link: '/en/enterprise/medical/waste'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/en/enterprise/office/asset'
            }
        ]


    }

}
