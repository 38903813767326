import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { logisticsAsset, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/officeAsset/video/rtlsVideo.mp4";

export default {


    helmet: '',
    desc: '스마트 오피스는 자산관리, 근태 관리, 재고 관리, 직원 관리에 있어서 혁신적인 변화를 가져옵니다. 이전과는 다른 방식으로 오피스를 운영하고 관리하여 생산성을 높일 수 있습니다.',
    keywords: '스마트 오피스, 자산 관리, 재고 관리, 오피스 솔루션, 위치 추적, 근태 관리, 사무용품 관리, 사무용품, 콩테크, 오브로, rtls, orbro',

    ogTitle: '',
    ogDesc: '',
    ogSite: '스마트 오피스 구축 솔루션 :: 오브로 블로그',
    ogUrl: 'https://orbro.io/blog/officeSolution',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDz0dYjvT_dovrQIm8StET0JPtXyRSg67istWgj_G4Tk7ygKMfT0sSkYCFctUToVxdJt0GLQovf6SlDVOv8wRusG-hAYmfYmKYwY2UC2zapN2wPY8xmOdsmqfCmtKJE_ZgS5d3zp8jhdEh3kzrTI6wCtwXZsh2l8FoST49zwMy3hMKotCDruVcBK8e-90_kWC-DRfGXSqBUlqYqEvpb7suLGc-sG9uVyVzgpahbCluCQLjjYQp0FfaIMqi3qWmmpzn4UeKKFlFEKxo5npOoMkUfIPOIzQwrqMhKMvCPw19QwjLii04Vk779E1xu1B3yvdQA0SsTGt8xskOW8TEzvlXITVtsKdpUOAbHj6rKF-3gchc57T4eE_-3ycDx8gttnPLOy-milalJmjbIZrntwCeb8Qpocf-Ug-YSBcy46fdXlAD73CrLAkMZD_EibWYj6rcA1MkP7YlYgUDMFZsUHXCRAJn-FXeC1tFSMvFp9XkMXa0hQjhG7WFKQ9OY__bS3VMM3m653EIFAooJVI2JW5Lehmr8i7AoYvVwyVya-ECTAkyCOHE36ApWm2GXcuMaUEKVpFWCa6Uk5VT3y44pXzUjv7UyfKoAILkaIxCBJ90rb-hQj0SOmBbu6J5gp--cXveh_j_I1BpDL4yXkaY5roagR4KZV4HnPqPzoAuXRMkg9D1c2IIuxsH3XW2UbComnYB3ZP36_elzk3qsnT125p6OPuquaowy30SFBy65N7gknz-dVooAi8Bz_bREsVMZUQ1wevfQYy8CoL2buJo3oL-tQbJTKrbPBiZQYm3H1SZ7S9JXjEpyEJgoPnmvNE1Qk8ErzBGgEACyWUFED9vQmLonSf0sPZK2ipPzAfbSStrz0RdsuLXHASIrTRRXO2miidoWuq4JqeuHAzVXqVj8iiG6VnQSMN8hW29RelzkAFwfOg9QGOSQgroVEuHNbCZebcAa-WfMSPdOvklsFWIQIc7tEZ0Qc4oKkpyced7b9OF6nQAwaZH0Risyrl_jcCKd0dYWRazieZBi2P6EV3VqCdtI8xhpYvCer4uocgKohjK07UgUVaEC2ug-oLsCYkNC1MeYrEvVkM6lJCnqihOdgUQ7-28FDGc1mmL-l0En06_GFIkCWjuUL84NZSglIuAjAgx-XkQSRl6j9XiV_O-vcqE8qmjky4mXV-Wn9Mj4AUexdJBDWX9R1CgPvFsXQlYaAKg7sPSYXzv9cfTPCRBBZZ7HExJ0g18NzVBpn3JlbO76m7WOsMm20NMcBW_rFgPVVbcx_RkjFJZGYalie3GY6mewgx_VeKktH5GAlyxmgXqT2JeF_1GcqN6AFrQoTW_QgkDDyUkqJW-_olrQiVqerertlIZ8GOosa2chps5Yw1iCaH01YmTgG8u9YCrqbwtWdL2wE1StruepZYCM4DRqCS22OLJySm6TTeql6iQAxR3n6gNFmWutp83Z87o_7FuetzaZmM6Z31Pk7JOVigfT3FgDClioeRwOy_k3WkXA_FWF4xSORqO21iqrYzkjTzZKHUAiEqUt7VNXIcfn_bWJE2wDSqElWPnqG6MDtJwqdm6CK-l1uGp3c=w2110-h1166',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',


    title: '스마트 오피스 구축 솔루션',
    date: '2023-10-31',
    tag1: '#스마트오피스',
    tag2: '#재고관리',
    tag3: '#사무용품',
    tag4: '#근태관리',

    // <br/>
    text: [
        {
            video: video,
            link : '/enterprise/office/asset',
            head: '',
            subHead: '',
            desc: '스마트 오피스 구축 솔루션에 RTLS(Real-Time Location System) 기술을 통합하면 업무 환경을 더 스마트하게 만들 수 있습니다. RTLS는 자산과 작업자의 위치를 실시간으로 추적하는 기술로, 스마트 오피스에서 다양한 혁신적인 기회를 제공합니다.',
            dot: ''

        },
        {
            head: '1. RTLS 기술과 스마트 오피스',
            subHead: '',
            desc: 'RTLS 기술은, Real-Time Location System(실시간 위치 추적)의 줄임말로, 무선 통신과 센서 기술을 활용하여 실시간 위치 데이터를 수집하고 분석하는 시스템입니다. 이 기술을 스마트 오피스 환경에 접목하면 다음과 같은 이점을 얻을 수 있습니다.',
            dot: ''
        },
        {
            head: '2. 장점',
            subHead: '자산 관리',
            desc: 'RTLS를 통해 스마트 오피스 내의 모든 자산을 정확하게 추적할 수 있습니다. 예를 들어, 회의실 예약 시스템은 RTLS 데이터를 활용하여 회의실 내의 장비를 실시간으로 확인하고 예약할 수 있게 합니다. 이는 중복 예약을 방지하고 회의실 자원을 최대한 활용할 수 있게 도와줍니다. 또한, 자산 이동 및 위치 이력 데이터를 분석하여 자산 수명 주기를 최적화하고 예방 정기 점검을 계획할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '작업자 생산성',
            desc: '특정 자산을 찾아야 하는 작업자는 RTLS 애플리케이션을 통해 그 자산의 현재 위치를 확인하고 최단 경로로 이동할 수 있습니다. 또한, 작업자의 업무 일정을 최적화하는 데도 도움이 됩니다. RTLS 데이터를 활용하여 작업자의 업무 패턴을 분석하고, 업무 스케줄링을 최적화함으로써 생산성을 향상시킬 수 있습니다. 더욱 중요한 것은 작업자가 무엇을 어디서든 효율적으로 수행할 수 있게 되면, 노동자들의 만족도가 높아집니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '보안 강화',
            desc: '관리자는 허용되지 않는 장소로의 접근을 감지하고 경보 시스템을 활용하여 즉각 조치를 취할 수 있습니다. 작업자의 위치 데이터를 모니터링하여 위험한 영역에서의 작업을 감지하고, 작업자에게 경고를 보내거나 즉각 조치를 취할 수 있습니다. 또한, 자산 이동과 위치 변경을 감시하여 도난이나 손상을 방지하고, 보안 강화에 크게 기여합니다.',
            dot: ''
        },
        {
            head: '3. RTLS를 활용한 스마트 오피스 솔루션',
            subHead: '자산 관리 시스템',
            desc: '이 시스템은 오피스 내의 모든 자산을 실시간으로 추적하고 관리합니다. 각 자산은 RTLS 태그를 부착하고, 이 태그를 통해 자산의 위치, 이동 경로, 사용 이력을 추적합니다. 관리자는 웹 기반 대시보드를 통해 모든 자산의 위치를 실시간으로 확인할 수 있으며, 필요한 자산을 빠르게 찾을 수 있습니다. 또한, 자산 위치 정보를 토대로 자산의 사용률을 최적화하고, 필요한 장비를 무분별하게 재구매하지 않게 도와줍니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '작업자 현황 대시보드',
            desc: '스마트 오피스 환경에서 작업자의 위치를 실시간으로 모니터링하는 대시보드를 도입하면 업무 일정 및 협업을 최적화할 수 있습니다. 예를 들어, 회의실 예약 시스템과 연동하여 누가 어떤 회의실을 사용하고 있는지를 실시간으로 확인할 수 있습니다. 작업자의 위치 정보를 공유하고 작업자들 사이의 협업을 촉진하여 생산성을 높일 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '환경 제어 및 에너지 관리',
            desc: '스마트 조명 시스템은 작업자의 위치를 감지하고, 자동으로 조명을 조절하여 에너지를 절약하고 편안한 조명 환경을 제공합니다. 또한, 작업자의 위치를 파악하여 온도를 조절하고, 공기질을 모니터링하여 쾌적한 작업 환경을 유지합니다. 이것은 오피스 내의 에너지 소비를 최적화하고 환경을 스마트하게 관리할 수 있게 도와줍니다.',
            dot: ''
        },
        {
            head: '마무리',
            subHead: '',
            desc: 'RTLS를 통한 스마트 오피스 솔루션은 자산 관리, 작업자 생산성, 보안 강화 등 다양한 측면에서 업무 환경을 개선하고, 기업의 경쟁력을 향상시키는데 중요한 역할을 합니다. 이러한 솔루션을 활용하여 조직은 스마트 오피스 환경을 구축하고 미래에 대비하는데 기여할 것입니다.',
            dot: ''
        },
    ],


    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.assetTracking.thumbnail
            },
            {

            }

        ],
        solution: [

            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/enterprise/office/asset'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/enterprise/logistics/asset'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/enterprise/retail/exhibition'
            },
        ]


    }

}