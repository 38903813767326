
import styled from "@emotion/styled";
import { FlexStyle } from "@assets/styled/flex";

import { StyledLabel } from "@components/Text";
import { SCREEN_BREAKPOINT, StyledGridRowBtn } from "@components/Grid/Grid.style";


export const ModalTextBox = styled.div`
   margin : 24px;
   width: 372px;
`;



export const StyledDownloadBtnBox = styled(StyledGridRowBtn)`
    margin-top : 16px;
    width : 100%;
    height : 52px;
    gap : 16px;
    margin-bottom : 24px;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
        width : 313px;
    }

`;


export const StyledDownloadBtnlText = styled(StyledLabel)<{color : string}>`
    margin: auto;
    ${({color})=> color&& `color : ${color};`}
`;


