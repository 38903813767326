import { StyledGridColumnBlank } from "@components/Grid/Grid.style";
import { CommonState } from "@redux/reducers/commonReducer";
import React, { useMemo, useState, useCallback, PropsWithChildren, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  StyledBlog, StyledGridContainer,
  StyledBlogDisplay, StyledIconBox, StyledBlogDate, StyledBlogDateBox
  , StyledBlogDateLogo, StyledTagBox, StyledTag, StyledLinkedIn, StyledTwitter, StyledBack,
  StyledFacebook, StyledBlogHeading, StyledBlogPara, StyledBackArrow,
  StyledTagBottom, StyledBlogShareBox, StyledBackLink, StyledBlogIntroHeading,
  StyledTagBoxBottom, StyledGridRowGap0, StyledGridContainerAbsolute, BanenrText
} from "./BlogDetail.style";

import { IconCalenderLine, IconArrowLeft, IconChevronRight } from "@assets/images/icon";
import { StyledTitleBanner } from "./BlogDetail.style";
import BlogNewLetter from "../Common/BlogNewLetter";
import BlogRecent from "../Common/BlogRecent";
import ModalDone from "@pages/Enterprise/Download/ModalDone/ModalDone";

import * as banner from "../Common/BlogBannerImg";

import blogRTLS from '@src/lang/ko/blog/blogRTLS';
import blogRTLSEng from '@src/lang/en/blog/blogRTLS';
import blogRTLSJP from '@src/lang/jp/blog/blogRTLS';
import blogRTLSCN from "@src/lang/cn/blog/blogRTLS";
import BlogAdvantage from "../Common/BlogAdvantage";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";
import { StyledDisplay, StyledHeading, StyledParagraph } from "@components/Text";
import { StyledBannerData, StyledBannerImg, StyledGridColumnBlog, StyledIconBoxBottom, StyledProductBox, StyledProductImg, StyledProductImgBox, StyledProductTextSide, StyledShowMore, StyledSolutionBox, StyledSolutionImg, StyledSolutionLayout, StyledSolutionTextBox, StyledSpacing64Border } from "../Main/BlogModule.style";
import { setSelectedValue } from '@redux/action-creators/common';
import i18n from "@pages/i18n";
import SEO from "@pages/SEO";
import { BorderBottomLine, ImgWH, LabelColor, ParagraphColor, Spacing, SpacingL, SpacingR } from "@pages/CommonStyle/common.style";
import { DivAllCenter, DivPadding } from "@pages/CommonStyle/commonDiv.style";



const pageData = {
  blogRTLS
}
interface ProductCard {
  date: string;
  label: string;
  path: string;
}

const canlender = IconCalenderLine
const arrowLeft = IconArrowLeft


const sections = [
  'realtime',
  'predict',
  'geo',
];
const sections2 = [
  'core',
  'send',
  'rfid',
  'ble',
  'uwb',
  'smart',
  'gps',
];

interface ModalDefaultType {
  pageName: string
}

function BlogRTLS({
  pageName
}: PropsWithChildren<ModalDefaultType>) {


  const { t } = useTranslation();

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  const page = 'blog' + pageName;

  const ogSite = useMemo(() => pageData[page]?.ogSite || '', [page]);
  const ogUrl = useMemo(() => pageData[page]?.ogUrl || '', [page]);
  const ogImg = useMemo(() => pageData[page]?.ogImg || '', [page]);


  const date = useMemo(() => pageData[page]?.date || '', [page]);

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const onSuccess = useCallback(() => {
    setSuccess(!isSuccess);
  }, [isSuccess]);

  const [pageUrl, setPageUrl] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPageUrl(window.location.href);
    }
  }, []);

  const dispatch = useDispatch(); // 추가
  const handleIconClick = (value) => {
    dispatch(setSelectedValue(value)); // 추가: Redux 액션 디스패치
  };


  const location = useLocation();
  const isEnglishPage = location.pathname.includes('/en/');
  const isJapanPage = location.pathname.includes('/jp/');
  const isChinaPage = location.pathname.includes('/cn/');
  const currentLang = i18n.language;

  const navigate = useNavigate();
  useEffect(() => {
    if (isEnglishPage) {
      i18n.changeLanguage('en').then(() => {

      });
    } else if (isJapanPage) {
      i18n.changeLanguage('jp').then(() => {

      });
    } else if (isChinaPage) {
      i18n.changeLanguage('cn').then(() => {

      });
    } else if (!isEnglishPage) {
      i18n.changeLanguage('ko').then(() => {

      });
    }
  }, [location.pathname]);


  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "name": "ORBRO",
      "position": 1,
      "item": "https://orbro.io"
    }, {
      "@type": "ListItem",
      "position": 2,
      "name": "Blog",
      "item": "https://orbro.io/blog"
    }, {
      "@type": "ListItem",
      "position": 3,
      "name": ogSite,
      "item": "https://orbro.io/blog/" + pageName
    }]
  }

  let blogPath;
  if (location.pathname.includes('/en/')) {
    blogPath = blogRTLSEng;
  } else if (location.pathname.includes('/jp/')) {
    blogPath = blogRTLSJP;
  } else if (location.pathname.includes('/cn/')) {
    blogPath = blogRTLSCN;
  } else {
    blogPath = blogRTLS;
  }


  const helmetTitle = blogPath.ogSite;
  const key = blogPath.keywords;
  const ogDesc = blogPath.desc;

  return (
    <>

      <Spacing pixel="48" />
      <StyledBlog>

        <SEO
          lang={currentLang}
          helmetTitle={helmetTitle + ' :: ORBRO Blog'}
          ogKey={key}
          ogDesc={ogDesc}
          ogImg={ogImg}
          ogUrl={ogUrl}
          jsonLd={jsonLd}
        />

        <StyledGridContainer>



          <StyledBack>
            <StyledBackLink href={currentLang !== 'ko' ? `/${currentLang}/blog` : "/blog"}>
              <StyledBackArrow src={arrowLeft} alt="left arrow icon" />
              <DivPadding a={"0"} b={"0"} c={"0"} d={"16"} />
              <StyledHeading size="xxxsmall">
                <Trans i18nKey={"blog.back"} />
              </StyledHeading>
            </StyledBackLink>
          </StyledBack>




          <StyledTitleBanner >

            <StyledGridContainerAbsolute>
              <StyledBannerImg src={`${banner[page][layoutType]}`} alt="RTLS Solution" />
            </StyledGridContainerAbsolute>

            <BanenrText>

              <StyledBannerData>

                <StyledBlogDisplay size="medium">
                  <Trans i18nKey={blogPath.title} />
                </StyledBlogDisplay>

                <StyledBlogDateBox>
                  <StyledBlogDateLogo src={canlender} alt="calender icon" />
                  <StyledBlogDate size="medium">
                    <Trans i18nKey={date} />
                  </StyledBlogDate>

                </StyledBlogDateBox>

                <Spacing pixel="8" />
                <StyledIconBox>


                  <StyledLinkedIn >
                    <LinkedinShareButton url={pageUrl}>
                      <LinkedinIcon size={32} round={true} borderRadius={16}></LinkedinIcon>
                    </LinkedinShareButton>
                  </StyledLinkedIn>

                  <StyledTwitter >
                    <TwitterShareButton url={pageUrl}>
                      <TwitterIcon size={32} round={true} borderRadius={16}></TwitterIcon>
                    </TwitterShareButton>
                  </StyledTwitter>


                  <StyledFacebook >
                    <FacebookShareButton url={pageUrl}>
                      <FacebookIcon size={32} round={true} borderRadius={16}></FacebookIcon>
                    </FacebookShareButton>
                  </StyledFacebook>

                </StyledIconBox>


                <StyledTagBox>
                  <StyledTag size="medium">
                    <Trans i18nKey={blogPath.tag1} />
                  </StyledTag>
                  <StyledTag size="medium">
                    <Trans i18nKey={blogPath.tag2} />
                  </StyledTag>
                  <StyledTag size="medium">
                    <Trans i18nKey={blogPath.tag3} />
                  </StyledTag>
                  <StyledTag size="medium">
                    <Trans i18nKey={blogPath.tag4} />
                  </StyledTag>
                </StyledTagBox>
              </StyledBannerData>
            </BanenrText>

          </StyledTitleBanner>


          <StyledGridRowGap0>

            <StyledGridColumnBlank desktop={1} tablet={1} mobile={1} />
            <StyledGridColumnBlog desktop={10} tablet={8} mobile={4}>

              <StyledBlogIntroHeading size="small">
                <Trans i18nKey={blogPath.title} />
              </StyledBlogIntroHeading>
              <StyledBlogPara size="medium">
                <Trans i18nKey={blogPath.desc} />
              </StyledBlogPara>

              {sections.map((section) => (
                <div key={section}>

                  <Spacing pixel="64" />
                  <StyledBlogHeading size="xxsmall">
                    <Trans i18nKey={blogPath[section].title} />
                  </StyledBlogHeading>
                  <StyledBlogPara size="medium">
                    <Trans i18nKey={blogPath[section].desc} />
                  </StyledBlogPara>

                </div>
              ))}


              <StyledBlogIntroHeading size="small">
                <Trans i18nKey={blogPath.howItWorks} />
              </StyledBlogIntroHeading>

              <Spacing pixel="8" />
              {sections2.map((section) => (
                <div key={section}>

                  <StyledBlogHeading size="xxsmall">
                    <Trans i18nKey={blogPath[section].title} />
                  </StyledBlogHeading>
                  <StyledBlogPara size="medium">
                    <Trans i18nKey={blogPath[section].desc} />
                  </StyledBlogPara>

                  <Spacing pixel="64" />
                </div>
              ))}


              <StyledBlogHeading size="xxsmall">
                <Trans i18nKey={blogPath.receive.title} />
              </StyledBlogHeading>
              <StyledBlogPara size="medium">
                <Trans i18nKey={blogPath.receive.desc} />
              </StyledBlogPara>

              <Spacing pixel="64" />
              <StyledBlogHeading size="xxxsmall">
                <Trans i18nKey={blogPath.software.title} />
              </StyledBlogHeading>
              <StyledBlogPara size="medium">
                <Trans i18nKey={blogPath.software.desc} />
              </StyledBlogPara>

              <Spacing pixel="64" />
              <StyledBlogHeading size="small">
                <Trans i18nKey={blogPath.conclusion.title} />
              </StyledBlogHeading>
              <StyledBlogPara size="medium">
                <Trans i18nKey={blogPath.conclusion.desc} />
              </StyledBlogPara>




            </StyledGridColumnBlog>

          </StyledGridRowGap0>



          <BlogAdvantage />


          {layoutType !== "desktop" &&
            <>
              <StyledSpacing64Border />
              <Spacing pixel="64" />
            </>
          }

          <StyledGridRowGap0>
            <StyledGridColumnBlank desktop={1} tablet={1} mobile={1} />
            <StyledGridColumnBlog desktop={10} tablet={8} mobile={4}>
              {pageData[page]?.link.product &&
                <>

                  {layoutType === "desktop" &&
                    <>
                      <StyledSpacing64Border />
                      <Spacing pixel="64" />
                    </>
                  }


                  {/* 관련 제품 */}
                  <StyledHeading size="xsmall"> <Trans i18nKey={`blog.product`} /> </StyledHeading>
                  <StyledProductBox>

                    <StyledProductTextSide>
                      <StyledHeading size="xxxxsmall"> <Trans i18nKey={blogPath.link.product[0].text} /> </StyledHeading>
                      <Spacing pixel="4" />
                      <StyledDisplay size="small" > <Trans i18nKey={blogPath.link.product[0].title} /> </StyledDisplay>
                      <Spacing pixel="4" />
                      <LabelColor size="small" color='#818181'>  <Trans i18nKey={blogPath.link.product[0].serial} /> </LabelColor>
                      <Spacing pixel="16" />
                      <ParagraphColor size="medium" color='#818181'>  <Trans i18nKey={blogPath.link.product[0].desc} /> </ParagraphColor>
                      <Link to={currentLang !== 'ko' ? `/${currentLang}/${pageData[page]?.link.page}` : `/${pageData[page]?.link.page}`}>
                        <StyledShowMore onClick={() => handleIconClick(pageData[page]?.link.numb)}>
                          <LabelColor size="medium" color='#2A70F0'><Trans i18nKey={`home.showMore`} /> </LabelColor>
                          <DivPadding a={"0"} b={"0"} c={"0"} d={"4"} />
                          <ImgWH src={IconChevronRight} alt="Right Arrow Icon" w={"20"} h={"20"} />
                        </StyledShowMore>
                      </Link>

                    </StyledProductTextSide>

                    <StyledProductImgBox>
                      <StyledProductImg src={pageData[page]?.link.product[0].img} alt="RTLS Product Img" />
                    </StyledProductImgBox>

                  </StyledProductBox>

                </>
              }

            </StyledGridColumnBlog>
          </StyledGridRowGap0>

          {layoutType !== "mobile" ? (
            <>
              <StyledSpacing64Border />
              <Spacing pixel="64" />
            </>
          ) :
            (
              <>
                <SpacingL pixel="16">
                  <SpacingR pixel="16">
                    <BorderBottomLine />
                    <Spacing pixel="64" />
                  </SpacingR>
                </SpacingL>
              </>
            )
          }


          {/* 관련 솔루션 */}
          {pageData[page]?.link.solution && (
            <>
              <DivAllCenter>
                <StyledDisplay size="small"> <Trans i18nKey={`blog.solution`} /> </StyledDisplay>
              </DivAllCenter>

              <Spacing pixel='32' />
              <StyledSolutionLayout>
                {pageData[page]?.link.solution.map((name, index) => (
                  <StyledSolutionBox key={index} >
                    <Link to={currentLang !== 'ko' ? `/${currentLang}${name.link}` : `${name.link}`}>
                      <StyledSolutionImg src={name.img} alt="Location System Img" />
                      <StyledSolutionTextBox>
                        <Spacing pixel="24" />
                        <StyledHeading size="xsmall" > <Trans i18nKey={`solutionContents.${name.data}.title`} /> </StyledHeading>

                        <Spacing pixel="8" />
                        <StyledParagraph size="small" > <Trans i18nKey={`solutionContents.${name.data}.desc`} /> </StyledParagraph>
                      </StyledSolutionTextBox>
                    </Link>

                  </StyledSolutionBox>
                ))}
              </StyledSolutionLayout>
            </>
          )}




          <StyledBlogShareBox size="xxsmall">
            <Trans i18nKey={blogPath.share} />
          </StyledBlogShareBox>


          {isSuccess && (
            <ModalDone onClickToggleModal={onSuccess}>
            </ModalDone>
          )}

          <StyledIconBoxBottom>


            <StyledLinkedIn >
              <LinkedinShareButton url={pageUrl}>
                <LinkedinIcon size={32} round={true} borderRadius={16}></LinkedinIcon>
              </LinkedinShareButton>
            </StyledLinkedIn>

            <StyledTwitter >
              <TwitterShareButton url={pageUrl}>
                <TwitterIcon size={32} round={true} borderRadius={16}></TwitterIcon>
              </TwitterShareButton>
            </StyledTwitter>


            <StyledFacebook >
              <FacebookShareButton url={pageUrl}>
                <FacebookIcon size={32} round={true} borderRadius={16}></FacebookIcon>
              </FacebookShareButton>
            </StyledFacebook>

          </StyledIconBoxBottom>


          <StyledTagBoxBottom>
            <StyledTagBottom size="medium">
              <Trans i18nKey={blogPath.tag1} />
            </StyledTagBottom>
            <StyledTagBottom size="medium">
              <Trans i18nKey={blogPath.tag2} />
            </StyledTagBottom>
            <StyledTagBottom size="medium">
              <Trans i18nKey={blogPath.tag3} />
            </StyledTagBottom>
            <StyledTagBottom size="medium">
              <Trans i18nKey={blogPath.tag4} />
            </StyledTagBottom>
          </StyledTagBoxBottom>


          <BlogNewLetter onSuccess={onSuccess} />

          <StyledSpacing64Border />

          <BlogRecent />



        </StyledGridContainer>
      </StyledBlog>
    </>
  )
}

export default BlogRTLS; 