import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledLabel, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";

export const Spacing = styled.div<{pixel : string}>`
    ${({pixel}) => pixel && `margin-top : ${pixel}px;`}
`;

export const SpacingDTM = styled.div<{d : string; t : string; m : string;}>`
    ${({d}) => d && `margin-top : ${d}px;`}

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        ${({t}) => t && `margin-top : ${t}px;`}
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        ${({m}) => m && `margin-top : ${m}px;`}
    }
`;

export const SpacingR = styled.div<{pixel : string}>`
    ${({pixel}) => pixel && `margin-right : ${pixel}px;`}
`;
export const SpacingL = styled.div<{pixel : string}>`
    ${({pixel}) => pixel && `margin-left : ${pixel}px;`}
`;


export const BorderBottomLine = styled.div`
    border-bottom : 1px solid rgba(0,0,0,0.08);
`;


export const DisplayColor = styled(StyledDisplay)<{color : string}>`
    ${({color})=> color&& `color : ${color};`}
`;

export const ParagraphColor = styled(StyledParagraph)<{color : string}>`
    ${({color})=> color&& `color : ${color};`}
`;

export const HeadingColor = styled(StyledHeading)<{color:string}>`
${({color})=> color&& `color : ${color};`}
`;


export const LabelColor = styled(StyledLabel)<{color:string}>`
    ${({color})=> color&& `color : ${color};`}
`;

export const AtagColor = styled.a<{color:string}>`
    ${({color})=> color&& `color : ${color};`}
`;

export const SpanColor = styled.span<{color : string}>`
    ${({color})=> color && `color : ${color};`}
`;

export const PtagColor = styled.p<{color : string}>`
    ${({color})=> color && `color : ${color};`}
`;





export const ImgCursor = styled.img`
    cursor : pointer;
`;

export const ImgWidth100 = styled.img`
    width : 100%;
`;

export const ImgContainFit = styled.img`
    width : 100%;
    height : 100%;
    object-fit : contain;
`;


export const ImgWidth = styled.img<{width : string}>`
    ${({width})=> width&& `width : ${width}px;`}
`;

export const ImgWH = styled.img<{w : string, h : string}>`
    ${({w})=> w && `width : ${w}px;`}
    ${({h})=> h && `height : ${h}px;`}
`;
