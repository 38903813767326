import { constructionAsset, constructionEquipment, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| 스마트 건설 현장 안전 관리 시스템',
  desc : 'IoT 기술과 RTLS를 활용하여 건설 현장에서의 작업자 위치를 실시간으로 추적하고, 위험 구역에 접근 시 경고를 발생시켜 안전을 강화합니다. 이를 통해 작업자들의 안전을 보장하고 사고 예방 및 현장 관리 효율을 향상시킬 수 있습니다.',
  keywords : '건설 안전, 건설 현장 작업자 안전, 스마트 건설, 노동자 안전, 중대재해처벌법, 건설공사 안전관리 종합정보망, 건설노조, 안전 노동',

  ogTitle : '',
  ogDesc : '',
  ogSite : '스마트 건설 현장 안전 관리 시스템 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/smartConstruction',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4OLTH19o0lofjwwmkKAz-9CGPCQcIHeGEvzHeubn5er_MCfU-q7GYUawQUgiBhV0p2H76qh1eSAjvENS8D30uK8wsm0dvRVz9fbghVm-npKypYxWtsi_kYTYd1uZYhCTnPpxWWxuJjMsmCvjKN2aY8L6u8uqXYCtcS2irDtBPmqv0nFkB22q83mh1u0tHLtyMOjggWKiJKLrctPn4GE5QW3s9JSv6OmA8ZhKbRghTN42Hq8Et9cH8kU-Nkbv_b26v6aQUdGmrak5jXiAKE4sXCbRnLX5IT_XnZo660WsMWHlDftI9hI392gO7mJYmKHNYA0Hhdh8rCxv2YcyNz4KSiwSz9Jp3W-OhtcoLEyNuTfLi_3AS4__oyVTxTqqP61RHt_sRKPpHWzYWKD2-lRFFqGAQnxfdW-cS3KHJFgwx2bn3SlV2YmG8WDnLTN2xFmd3_t6DTG8QVZbDg2gHQz4sqmC91sVjpA-UACZ0Ea_8Z5tsVSsIUcZeOLqJ2OSf4CgoOXD7KX5QgQz0O8OI6hq8riIT0Q_ubXhp_-gNUyjqFj6XUxFp1N8l1vWcJz_vaq817pMKLQOh5tUhXUl1QPqo43LWZGk6FCPDhUDVOZbq731ORKRpWvfDieeQpaAHXBh1TIb104d8BxMZNoVwDMtQjqysfBeMFBDTJDq1cQOGrhcT1uLJ8p0sdYxZQrKha8F66CA9glk9n61-IowjHJKAFd8H0Rrapwp7UJwhug12o4UvffLxnbqNzmdX1RraJoFrfcs6Fhp8da4-TdfOtEqhwoTkqobcXUN07zWEQUl2CRHHRUG82xw4wlcF9BIunVPJe894pfmXE4C0ETayUHYXDJcIYK7SQUS08H7Xi2fyQHw_zZIHg8Lj_w3ejIUGBANJuIxQglvskR5g7NjbJ0EBv2bCKLqkGEsPZMG5ypfBu2BtfKkDZT4kekWaO9TXJrrVDYa_A18PBQSN9hv2G8JwBGt_bvRlGZMDsGu6VCwq3fJAB8gtzb_0U8PrztEHGSsojNVoVZ7BN6OUROQFTH9IQZHIv_NC9jPraNFThG8ydegAos8aSaqEMeohU8FOv1Lck-kOCLo-HB0qSX11IkppPvCaSgblbcpB9ET8m0I3gQGkJpyBdwmY70NMVKUe1az2MUpZRUgoQaLP7ZKujMw7syYLaZ_hCiXuYqsF_Fn7yZ9nmG1FjxDGVKEbijl33lTSgLAWVc1yhzuS-8__AcbhG2nI2aAii6ZxqZAfHMBHQQ5BLYQTJCpJZjLeJKgBg_XT6Gl56dGOq8vhdrl8NdBqsL54EVg_a_qn4cvrSaQ5Lt8IDGgYgNFvrPBwLgnqWb2Ll6oLL1MvhV2vru9BFadHclWr0Ly4nPy5DI1Lr4ghP2biSKLMQeXyUo97JePQN6ei9jdi7k1hzr9Kk1vrBIFz5Okp_FaZeKDikQ5oZ7yVctdAjvZIBO-h5i_Zin6sl878TaGDErBn301m-gHvF1G8AMlU46qZnNBPlPld-YUOqM6V79RFxmf_wpJWAFld9xB4ud-CLWzo6xdWzpanyQoaf7cj9Myian97M297lV5zEBj-Q=w2295-h1233',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '스마트 건설 현장 안전 관리 시스템',
  date : '2023-07-31',
  tag1 : '#건설안전',
  tag2 : '#노동자안전',
  tag3 : '#건설현장안전',
  tag4 : '#건설안전관리',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'건설 현장은 다른 산업보다 위험한 경우가 많습니다.그래서 작업자의 안전은 그 무엇보다 우선시 되어야 합니다. 이런 고위험 환경에서 작업자의 안전을 보장하지 않는 것은, 프로젝트에 차질이 생길 뿐만 아니라 법적인 책임을 물어야 하는 일이 발생하기도 합니다.<br/>즉, 건설 산업에 있어서 가장 치명적이고 가장 중요하다고 할 수도 있습니다.<br/><br/>많은 경우의 안전 사고는, 작업자가 안전 장비를 제대로 착용하지 않은 경우에 발생합니다. 또한 사고는 항상 아주 짧은 찰나의 시간에 발생하기 때문에, 빠른 조치를 취하지 않아서 생명을 잃게되는 일도 발생합니다. 하지만 실시간 위치 추적 기술, RTLS라고 하는 기술을 사용하면 건설 현장의 작업자를 비롯하여, 현장 상황을 실시간으로 추적, 관리할 수 있고 결국 훨씬 더 안전한 건설 현장을 만들 수 있습니다. 안전뿐만 아니라, 건설 현장에 사용되는 자재 관리를 비롯하여, 중장비를 관리하고 작업자의 동선을 분석하여 프로젝트의 효율성을 굉장히 많이 개선할 수 있습니다. <br/><br/>아래에서는 이러한 기술이 건설 현장이 어떻게 변화하고 성장할지 말씀드리겠습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'1.안전 장비 착용 관리',
      desc : '안전 장비 착용은 건설 현장에서 가장 기본적이고 필수적인 안전 프로토콜입니다. 하지만 여러 이유로 작업자들이 간혹 장비를 제대로 착용하지 않는 경우가 있습니다. 귀찮거나 불편하거나 방심은 결국 치명적 사고로 이어질 수 있습니다.<br/>실시간으로 작업자가 안전장비를 착용했는지 판단하고, 현재 위치가 위험 요소가 많은 현장이라면 더욱 신경써서 관리할 수 있습니다. 관리자는 이 상황을 실시간으로 모니터링하여 주의를 주거나 그에 상응하는 조치를 취할 수 있습니다.<br/>만약 사고가 발생한다면, 당시 상황이 어땠는지 파악할 수 있는 중요한 자료로서 사용될 수도 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'2. 실시간 위치 추적',
      desc : '간혹 빠르게 특정 작업자의 위치를 파악해야 할 때가 있거나, 현장의 전체적인 작업자현황을 파악해야 하는 경우, 실시간으로 추적되는 작업자들의 위치가 모니터에 나타납니다. 현장과 똑같은 지도 위에 실시간으로 움직이는 작업자들의 위치는, 상황을 빠르게 파악하고 판단할 수 있도록 도와줍니다. <br/>또는 지오펜스(geofence)라는 기술을 활용해, 특정 구역을 위험 구역으로 설정하여, 현장 작업자가 해당 구역에 들어서는 경우, 실시간으로 알람을 보내도록 할 수도 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'3. 근로 효율 관리',
      desc : '작업자의 동선과 체류 시간, 휴식 시간 등을 파악할 수 있습니다. 작업자 별로 어떤 근무 특성을 갖고 있는지 파악할 수 있고 전체적인 흐름과 프로젝트의 효율을 개선하는 데에 필요한 자료를 얻을 수 있습니다. ',
      dot : ''
      
    },
    {
      head : '',
      subHead :'4. 중장비 관리',
      desc : '현장에 작업자들만 이동하는 것은 아닙니다. 특히 안전과 관련해서는 중장비도 중요하게 고려해야 하는 대상입니다. 중장비를 실시간으로 추적하여, 현장 작업자들과 근접하는 경우, 즉시 알람을 보냅니다. 관리자는 충돌 위험을 미리 감지할 수 있습니다. 뿐만 아니라, 중장비를 실시간으로 추적한다는 것은 중장비 효율과도 연관 있습니다. 현장에서 필요한 최적의 배치를 하고, 가장 빠른 동선을 파악하여 시간을 절약할 수 있습니다. 또는 재고 관리와 유지 보수 측면에서도 유리합니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'결론',
      desc : '지금까지는 관리자가 직접 현장을 보고 판단하며 지시를 내리곤 했었습니다.<br/>하지만 이제는 새로운 기술을 통해 스마트한 건설 현장 안전 관리가 가능해졌습니다.<br/>안전을 넘어, 건설 프로젝트의 효율성과 생산성을 높이고 더욱 체계적인 작업도 함께 말입니다.',
      dot : ''
      
    },

  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/enterprise/construction/safety'
      },
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/enterprise/construction/asset'
      }, 
      {
        img : constructionEquipment.thumb,
        data : 'constructionEquipment',
        link : '/enterprise/construction/equipment'
      }
    ]

  }


}