import styled from "@emotion/styled";


export const SumamryBox = styled.div`
    display : flex;
    flex-direction : row;
    gap : 16px;
`;

export const SummaryImg = styled.img`
    width : 64px;
    height : 64px;
    border-radius : 12px;
    object-fit : contain;
`;


export const SummaryTextBox = styled.div`
    display : flex;
    flex-direction : column;
    gap : 4px;
    padding-right : 8px;
    padding-top : 8px;
`;