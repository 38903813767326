import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconChartRadiusGray, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconTagGray, IconTvocGray, IconUserCheck, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { IconTemp } from "@assets/images/main";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo4.mp4";
export default {
    banner: {
      title: 'リアルタイムのプロセス管理',
      desc: '製造施設で進行中のフローを一目で把握し、管理することができます。正確で客観的なデータを使用することで、プロセスラインを改善し、効率を向上させることができます。これは製造業の核心です。',
    },
    video: {
      title: '製造プロセスのすべてをトラッキング',
      desc: 'リアルタイムの位置測位（RTLS）技術により、製造プロセスをリアルタイムで把握することができます。製造ラインの現在の状況をリアルタイムで把握することで、最高の効率を実現できます。',
      play: video,
    },
    func: [
      {
        title: '製造ラインモニタリング',
        desc: '製造プロセス中に発生する異常を迅速に把握し、適切な対策を講じることで、生産効率を最大化し、障害を最小限に抑えることができます。',
        sub1: 'リアルタイムな状況把握',
        icon1: IconClockGray,
        sub2: '統計データの提供',
        icon2: IconChartBarGray,
        video: subVideo1,
      },
      {
        title: 'ボトルネック緩和',
        desc: '原材料、半製品、製品などの移動をリアルタイムで測位し、ボトルネックが発生する段階を特定します。また、予測し、迅速な対策を講じることができます。',
        sub1: 'リアルタイムな位置測位',
        icon1: IconBinocularsGray,
        sub2: '高い精度の提供',
        icon2: IconRulerGray,
        video: subVideo2,
      },
      {
        title: '安全管理',
        desc: '製造ラインで発生する事故をリアルタイムで検知します。これにより、事故発生時に素早く対処し、より深刻な事故を防ぐことができます。',
        sub1: '危険な資産の指定',
        icon1: IconWarningGray,
        sub2: '対応方法の設定',
        icon2: IconChartRadiusGray,
        video: subVideo3,
      },
      {
        title: 'プロセス分析',
        desc: 'データを収集して、より効率的な製造プロセスに改善することができます。問題点を簡単に把握し、ビジネスの成長につなげることができます。',
        sub1: 'データのクラウド保存',
        icon1: IconClockGray,
        sub2: '統計データの提供',
        icon2: IconChartBarGray,
        video: subVideo4,
      },
    ],
    subfunc: {
      title: 'さまざまな機能',
      desc: 'プロセスラインの生産性は、製造業の核心です。ORBROが提供する機能により、プロセスラインの効率性と生産性をさらに向上させることができます。',
      data: [
        {
          icon: IconChartRadius,
          title: 'レポートと分析',
          desc: '現地データを基にレポートや分析資料を生成することができます。',
        },
        {
          icon: IconBell,
          title: 'リアルタイムアラーム機能',
          desc: 'プロセスラインに異常が発生した場合にアラームを送信し、即座に対応することができます。',
        },
        {
          icon: IconRoute,
          title: 'ルート履歴の参照',
          desc: '内部の移動オブジェクトの経路と移動時間を測位して確認することができます。',
        },
        {
          icon: IconDashboard,
          title: 'ステータスの把握',
          desc: '現在のプロセス状況をリアルタイムで把握することができます。',
        },
        {
          icon: IconTemp,
          title: '温度、湿度などの環境分析',
          desc: '物流倉庫の内部環境要因をモニタリングし、異常がある場合にリアルタイムに検知することができます。',
        },
        {
          icon: IconUserCheck,
          title: '作業者への指示',
          desc: '現在の状況に基づいて作業者に次の作業を指示することができます。',
        },
        {
          icon: IconChainLink,
          title: 'データの統合',
          desc: 'RFID、IoT、セキュリティ、ビデオなどのシステムと統合し、効率的に活用することができます。',
        },
        {
          icon: IconSecurity,
          title: 'データのセキュリティ',
          desc: '高いデータのセキュリティレベルを維持し、信頼性の高いソリューションを提供します。',
        },
      ],
    },
    system: {
      title: 'システム構成',
      img: rtlsSystem,
    },
    productTitle: '製品構成',
    product: [
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'manufacturingAsset',
      },
      {
        data: 'logisticsAsset',
      },
      {
        data: 'blogOffice',
      },
      {
        data: 'blogRTLS',
      },
    ],
  }
  