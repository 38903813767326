import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4';

export default {

  desc: 'Smart parking is a parking space that utilizes advanced technology and infrastructure to address parking issues in modern urban environments and efficiently manage parking. Such parking spaces integrate various technological elements to optimize the parking process and provide a more convenient experience for vehicle users.',
  keywords: 'Smart parking, parking management, rest areas, digital twin, digital transformation, digital twin, orbro, beacon, rtls, uwb, ble, ai camera, orbro, contech',

  ogSite: 'Smart Parking System | Location Tracking and Digital Twin, orbro',
  ogUrl: 'https://orbro.io/en/blog/smart-parking',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmD7VPkEf-FilCFjgoS9bLoX8jzIaaMktsjySXAPNjEeeFagK2_315Sl39yXx7L8iamkUCasZgYN_nmqjEzlcJ7b471GwtTQQuGwwOAfQQA6S8NE3asVOx8onF2HoacQcQFEPQ8CAhcl0rNpbh8bBS8FFHY4_D2fvH08mJkm9qWDYYAV69cnY6EoN7AbjCxcmjDp9MRJWdjSZLXFzgpaIIYwkOai0qq4fv00oAK7nW5aHmjQ7yW3Fs_dnUPcWjO02-SJvPDSXNRfqALw7R8fQ1xzAouT41STdr9FL0nvwawirXEQrJHdFYmtKxTMeYwrzrbvapEVr_9gbwWf7Xz6op2cCCQ9rlTypNRAUcoImxb8moTg_z4h0lB4He17_bFIKP8htIGOonJQ30cdXaym4saPjQhyRCio1wMUVwgbmNVC3b4zyJ5L9HnxyCsxDiVEDthb0FhoiTe75C-fAaLDxkhVfyjQTSX7lCYYG_JjPfTEo2kBb6fU79VYeUO4Kb_eWYiPR2Iwfzuu7StZDg_fEldXXzjVx6NJPi6R4a0r8VwuTtEwURqugwI57tg0kMwc1CN3P6SfBrzGPldfU6D5kntEPrH4rZvGkFLUOhGrKJWQrBodIb2rV5aACulW8o5W5D8wDEJkfyNS-F0oQHBEqzp9nZxKDuGMZ2gb_1rBuvwbphoql1q_W42SNGRTzjK3yZLzxxh9utXyY4bL4KVGrSVYX5BrshF1Uxy9qzyfmgNeI_sy5Tbah5GI3QhJG_MWU8nN5WicgsNpOWRVNSKyEhrt5hUOE5AVc60roL6Ar04TIJvjwBc85d0W_DonnbugsHZd-ZE2a1qACGExoN1GoO7gY8I0rGyaeXPXmz-eKFBYlni7VNvL_-WZlVfON20nmmnJGjJENADAWpBqdcJsKErZNryomDdplCibc53ZnYZ0-tE14kXFvQSMVx9rpq7xNi459vviazTPO_ZP6OKfTXX6tIrYW-vNA6r-vVM2nvber10dIqepSAUGPhm85RXyKGyHzU2sMQZA_0KKZR9ghdnDbzKyk6Ig0gcSclnaKoUAv0tV8ouS-IML_FSjTylgxNCHCeh5DRwimnmWs-M-Qj8E_pvYxpE5gDx5NFgO3KQtnEk7ZocAEpOSVFN5PHfWUhpTh-ZsM4joGNhdAiBAbc-VZzDs0jIIC2xpKDPdpUtVvIaVM9X_lf5FKcKd7VLx27m6hSL2bmbgdvx4t33C6fN69eGruOV7uvS1-K7vO6dqs-g4FWmrLDnOFmCGDyaa8gwvxozpfxaSUqklsYGSVuw6zCnAgAM-z6ApVjVb6P_aRbWW4ETJnzuMg3O4RVMoQZ1XGTM2kXC_0Um42ueBxfKmL7cAdcHi77Mxu7jLWBKoR1MI0uR-Flk-ouBsLn0joMzdQK6d3noiwmXVnNt26nLyiTY0OuyNhP5oDp6lXYyWjUsUwSZs2GpTcNDljddjYs1m1T7ckdu_JHlFsHpJ3RYQxuOcSAHdB4sv4hQohcrrbkctCpfLF8KUHAI4LbX1RqQ8eHJfD1wKMUSd7CydiQFcKD7FLnz=w1501-h1172',
  ogType: 'website',

  title: 'Smart Parking System',
  date: '2024-01-24',
  tag1: '#SmartParking',
  tag2: '#ParkingManagement',
  tag3: '#RestAreas',
  tag4: '#DigitalTwin',

  text: [
    {
      head: 'What Is Smart Parking?',
      subHead: '',
      desc: 'Smart parking is a parking space that utilizes advanced technology and infrastructure to address parking issues in modern urban environments and efficiently manage parking. Such parking spaces integrate various technological elements to optimize the parking process and provide a more convenient experience for vehicle users. <br/>To implement such a system, digital twin technology must be applied to create a digital representation identical to the real-world parking lot and transmit data. The key features and functions include the following:',
      dot: ''
    },
    {
      head: 'Smart Parking Features',
      subHead: '',
      desc: 'Smart parking offers various features that improve parking management and user experience using various advanced technologies. These features benefit both parking lot administrators and vehicle users, helping to solve urban parking problems. Here is a list of key features typically offered in smart parking:',
      dot: {
        a: '<strong>• Real-time Location Tracking:</strong> Using AI cameras, sensors, and IoT technology to track and manage vehicle locations within the parking lot in real-time, quickly identifying available parking spaces.',
        b: '<strong>• Parking Guidance and Assistance:</strong> Providing users with the location of empty parking spaces through a mobile app or display, making parking easier.',
        c: '<strong>• Parking Reservations:</strong> Allowing users to remotely reserve parking spaces and secure them before arrival.',
        d: '<strong>• Traffic Flow Analysis:</strong> Using sensors and cameras within the parking lot to analyze when the parking lot is most congested and providing this information to users.',
        e: '<strong>• License Plate Recognition:</strong> Automatically detecting and managing illegal parking, exceeding parking time limits, and other violations by recognizing vehicle license plates.',
        f: '<strong>• Security and Safety:</strong> Enhancing vehicle and parking facility safety by installing CCTV cameras and sensors inside the parking lot.',
        g: '<strong>• Data Analysis and Optimization:</strong> Analyzing collected data to optimize parking lot operations and find areas for improvement. This allows parking lot administrators to efficiently use resources.'
      }
    },
    {
      video: video,
      link: '/en/enterprise/manufacturing/process',
      head: 'Smart Parking Technology',
      subHead: 'AI Cameras and Vision Technology',
      desc: 'AI cameras are camera systems based on advanced computer vision technology. They are used to monitor and analyze all activities within the parking lot. For example, they can real-time detect and recognize vehicle license plates, vehicle types, colors, vehicle locations, and directions. This information is used to optimize parking lot operations and provide parking information to vehicle users. It is also used to identify and manage illegal parking and violations.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Internet of Things (IoT)',
      desc: 'IoT is a technology that collects and transmits data through various sensors and devices installed in the parking lot. Sensors collect various real-time information such as vehicle movements, parking slot availability, temperature, humidity, and more. This information is used for parking lot operations and management, monitoring parking lot conditions, and providing a convenient parking environment for vehicle users.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Mobile Apps and Web Services',
      desc: 'Mobile apps and web services are used to provide parking information to vehicle users and manage the parking process. Users can use the mobile app to check the location of empty parking spaces, make reservations, pay parking fees, and monitor parking status. Additionally, web services allow users to access and manage parking information from computer browsers, providing various conveniences to users.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Big Data Analysis',
      desc: 'Big data analysis refers to the process of in-depth analysis of the large amount of data collected in smart parking lots. The collected data is analyzed from various aspects, including user behavior patterns, parking lot congestion, and the effectiveness of parking fee policies.',
      dot: {
        a: '<strong>• Parking Operation Optimization:</strong> Optimizing parking lot operation hours, parking slot arrangements, parking fee policies, and more to enhance efficiency.',
        b: '<strong>• Improved User Experience:</strong> Understanding user behavior and improving the parking process based on it to enhance user experience.',
        c: '<strong>• Revenue Increase:</strong> Optimizing parking fee policies through big data analysis and finding ways to increase revenue.',
        d: '<strong>• Development of Predictive Models:</strong> Developing models that predict future parking space availability, parking demand, and more through data analysis to plan operations.',
        e: '<strong>• Issue Identification and Response:</strong> Identifying parking lot issues through data analysis and taking quick corrective measures. For example, allocating additional parking spaces to prevent congestion.'
      }
    },
    {
      head: 'Smart Parking Examples',
      subHead: 'South Korea Rest Areas - Beacon-Based Parking Management',
      desc: 'Some parking lots in South Korea operate smart parking using beacon technology. Beacons are integrated with mobile apps to provide real-time parking space availability and manage vehicle entry and exit. They also provide users with parking information through the mobile app and facilitate easy payments.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Los Angeles, USA - Real-time Parking Information',
      desc: 'Los Angeles has implemented smart parking lots to solve parking problems in downtown areas and provide real-time parking information services. Through this service, drivers can check the location of available parking spaces and parking fee information on a mobile app, making parking more convenient.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Shanghai, China - Autonomous Parking Robots',
      desc: 'Some parking lots in Shanghai use autonomous parking robots to automatically park and retrieve vehicles in parking spaces. Drivers can utilize the robot system by dropping off their vehicles at the entrance of the parking lot, reducing parking time and enhancing convenience.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Tokyo, Japan - Automatic Parking Fee Payment',
      desc: 'Some parking lots in Tokyo automatically calculate and process parking fees through license plate recognition technology and mobile apps. This allows drivers to leave the parking lot quickly and eliminates the hassle of paying with cash or credit cards.',
      dot: ''
    },
    {
      head: 'Future Outlook for Smart Parking',
      subHead: '',
      desc: 'In the future, smart parking is expected to play an even more crucial role, integrating with autonomous vehicles to optimize parking processes and utilize automatic parking features. Furthermore, advancements in high-speed data communication technology will facilitate real-time data sharing, while machine learning and artificial intelligence technologies will enhance parking predictions and user pattern analysis. Smart parking will be integrated into smart city projects as a key component for traffic management and control, and parking data will be used for urban planning and congestion management. Security technologies are also expected to be further strengthened, ensuring the safety of vehicles and parking facilities.',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that helps with real-time location tracking by attaching UWB devices to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/en/enterprise/construction/asset'
      },
      {
        img: constructionEquipment.thumb,
        data: 'constructionEquipment',
        link: '/en/enterprise/construction/equipment'
      }
    ]


  }


}
