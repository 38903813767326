import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";

export const FuncBox = styled.div`
    display : grid;
    grid-template-columns : repeat(2, 1fr);
    gap : 32px;
    height : 400px;
    width: 100%;
    margin-top : 24px;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        display : flex;
        flex-direction: column;
        height : auto;
    }
`;

export const FuncTextBox =styled.div`
    display : flex;
    flex-direction : column;
    gap : 32px;
    order : 1;
    width : 100%;
    padding-top : 96px;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        order : 0;
        padding-top : 64px;
    }
`;



export const FuncVideoBox = styled.div<{index : number}>`
    width: 100%;
    height : 100%;
    display : flex;
    ${({index})=> index%2 === 0 ? 'order : 0;' : 'order : 1;'}

    border-radius : 12px;
    border : 1px solid rgba(0,0,0,0.08);
    
    max-height : 400px;
    object-fit : cover;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        order : 1;
    }
`;