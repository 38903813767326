import {
  HomeAccess1D, HomeAccess1M, HomeAccess1T,
  HomeAccess2D, HomeAccess2M, HomeAccess2T,
  HomeAccess3D, HomeAccess3M, HomeAccess3T,
  HomeAccess4D, HomeAccess4M, HomeAccess4T,
  HomeAccess5D, HomeAccess5M, HomeAccess5T,
  HomeBeacon1D, HomeBeacon1M, HomeBeacon1T,
  HomeBeacon2D, HomeBeacon2M, HomeBeacon2T,
  HomeBeacon3D, HomeBeacon3M, HomeBeacon3T,
  HomeBeacon4D, HomeBeacon4M, HomeBeacon4T,
  HomeBeacon5D, HomeBeacon5M, HomeBeacon5T,
  HomeBeacon6D, HomeBeacon6M, HomeBeacon6T,
  HomeRTLS1D, HomeRTLS1M, HomeRTLS1T,
  HomeRTLS2D, HomeRTLS2M, HomeRTLS2T,
  HomeRTLS3D, HomeRTLS3M, HomeRTLS3T,
  HomeSolution1D, HomeSolution1M, HomeSolution1T,
  HomeSolution2D, HomeSolution2M, HomeSolution2T,
  HomeSolution3D, HomeSolution3M, HomeSolution3T,
  HomeSolution4D, HomeSolution4M, HomeSolution4T
} from "@assets/images/main/home";
import { HomeDTBanner } from "@assets/images/main/home/dt";

export default {
  titleBanner: {
    title: `位置追踪和数字孪生专业平台`,
    description: '实时位置追踪、出入系统、数字孪生、工业物联网带来的空间创新'
  },

  productText: {
    title: `数字孪生，位置追踪产品`,
    description: `寻找变化的你，Orbro为你提供新的选择`
  },

  os: {
    title: '最了解空间的软件',
    desc: 'Orbro OS利用最新的通信技术，<br/>实时准确地追踪和管理空间中的所有元素。',
  },

  rtls: {
    title: '适用于所有场景的定位追踪阵容',
    desc: '提供利用蓝牙、UWB通信的无线电定位追踪和利用视频与AI技术的定位追踪阵容。',

    card: [
      {
        tag: '实时位置追踪',
        title: '在虚拟空间中呈现现实',
        link: 'https://orbro.io/cn/rtls',
        img: {
          desktop: HomeRTLS1D,
          tablet: HomeRTLS1T,
          mobile: HomeRTLS1M
        },
      },
      {
        title: '通过Beacon定位追踪',
        desc: '通过BLE的定位追踪系统',
        link: 'https://orbro.io/cn/beacon/ble',
        img: {
          desktop: HomeRTLS2D,
          tablet: HomeRTLS2T,
          mobile: HomeRTLS2M
        },
      },
      {
        title: '通过AI摄像头定位追踪',
        desc: '利用AI技术的定位追踪及异常行为检测',
        link: 'https://orbro.io/cn/camera',
        img: {
          desktop: HomeRTLS3D,
          tablet: HomeRTLS3T,
          mobile: HomeRTLS3M
        },
      },
    ]
  },

  access: {
    title: '利用手机实现自然的非接触出入',
    desc: '提供利用手机、面部识别技术的下一代出入方式和信息。',

    card: [
      {
        tag: '提供多种出入方式',
        title: 'NFC/蓝牙/智能手机<br/>所有方式的出入',
        sub: '不仅支持NFC卡出入，<br/>还支持手机出入、智能手表出入。',
        img: {
          desktop: HomeAccess1D,
          tablet: HomeAccess1T,
          mobile: HomeAccess1M
        },
      },
      {
        title: 'Access Pro',
        link: 'https://orbro.io/cn/access/face',
        desc: '支持面部识别、NFC卡、手机所有出入方式',
        img: {
          desktop: HomeAccess2D,
          tablet: HomeAccess2T,
          mobile: HomeAccess2M
        },
      },
      {
        title: 'Access Auto',
        link: 'https://orbro.io/cn/access/auto',
        desc: '手机出入的最佳优化',
        img: {
          desktop: HomeAccess3D,
          tablet: HomeAccess3T,
          mobile: HomeAccess3M
        },
      },
      {
        title: '配件',
        desc: '提供多种出入配件',
        link: 'https://orbro.io/cn/access/accessory',
        img: {
          desktop: HomeAccess4D,
          tablet: HomeAccess4T,
          mobile: HomeAccess4M
        },
      },
      {
        title: 'Access Lite',
        desc: '专注基础的手机出入系统，支持NFC和Apple Watch',
        tag: "即将发布",
        img: {
          desktop: HomeAccess5D,
          tablet: HomeAccess5T,
          mobile: HomeAccess5M
        },
      },
    ]
  },

  dt: {
    title: '全球首创第三代数字转型<br/>数字孪生专业平台',
    desc: '通过Orbro的数字孪生解决方案虚拟实现城市，体验更加进化的创新。',
    img: HomeDTBanner
  },

  beacon: {
    title: '工业物联网及Beacon系统',
    desc: '通过满足工业现场需求的多种传感器及Beacon系统，打造高效的工作环境。',

    card: [
      {
        title: 'ALESS-GW',
        desc: 'LoRa中继器',
        link: 'https://orbro.io/cn/sensor/LoRaGateway',
        img: {
          desktop: HomeBeacon1D,
          tablet: HomeBeacon1T,
          mobile: HomeBeacon1M
        },
      },
      {
        title: 'ALESS-BA',
        desc: '利用LoRa通信的工业传感器',
        link: 'https://orbro.io/cn/sensor/LoRaSensor',
        img: {
          desktop: HomeBeacon2D,
          tablet: HomeBeacon2T,
          mobile: HomeBeacon2M
        },
      },
      {
        title: 'VENTAX CO+',
        desc: '一氧化碳检测器',
        link: 'https://orbro.io/cn/sensor/co',
        img: {
          desktop: HomeBeacon3D,
          tablet: HomeBeacon3T,
          mobile: HomeBeacon3M
        },
      },
      {
        title: 'VENTAX',
        desc: '空气质量检测器',
        link: 'https://orbro.io/cn/sensor/air',
        img: {
          desktop: HomeBeacon4D,
          tablet: HomeBeacon4T,
          mobile: HomeBeacon4M
        },
      },
      {
        title: 'Plutocon Pro',
        desc: '工业用Beacon终端',
        link: 'https://orbro.io/cn/beacon/industry',
        img: {
          desktop: HomeBeacon5D,
          tablet: HomeBeacon5T,
          mobile: HomeBeacon5M
        },
      },
      {
        title: 'ORBRO Tag',
        desc: '小型Beacon终端',
        link: 'https://orbro.io/cn/beacon/tag',
        img: {
          desktop: HomeBeacon6D,
          tablet: HomeBeacon6T,
          mobile: HomeBeacon6M
        },
      },
    ]
  },

  solution: {
    title: '位置追踪与数字孪生的融合',
    desc: '在建筑、物流、制造、医疗等多个行业中，体验Orbro的先进解决方案。',
    button: '咨询',
    buttonLink: 'https://orbro.io/cn/request',
    card: [
      {
        title: '建筑',
        desc: '建筑工地安全管理、库存管理等，让建筑工地智能化转变。',
        link: 'https://orbro.io/cn/enterprise/construction/safety',
        img: {
          desktop: HomeSolution1D,
          tablet: HomeSolution1T,
          mobile: HomeSolution1M
        },
      },
      {
        title: '物流',
        desc: '在大型物流中心和仓库中，实时掌握库存位置及工作人员安全管理。',
        link: 'https://orbro.io/cn/enterprise/logistics/asset',
        img: {
          desktop: HomeSolution2D,
          tablet: HomeSolution2T,
          mobile: HomeSolution2M
        },
      },
      {
        title: '制造',
        desc: '实时管理制造过程，提高库存管理和生产效率。',
        link: 'https://orbro.io/cn/enterprise/manufacturing/process',
        img: {
          desktop: HomeSolution3D,
          tablet: HomeSolution3T,
          mobile: HomeSolution3M
        },
      },
      {
        title: '医疗',
        desc: '在医院和医疗设施中追踪和管理患者，保障安全。',
        link: 'https://orbro.io/cn/enterprise/medical/patient',
        img: {
          desktop: HomeSolution4D,
          tablet: HomeSolution4T,
          mobile: HomeSolution4M
        },
      },
    ]
  },

  //product.ts 
  product: {
    items: [
      {
        title: '无线位置追踪',
        description: '通过无线位置追踪<br/>实现完美的资产管理和准确的定位追踪',
        link: '/cn/rtls'
      },

      {
        title: '摄像头位置追踪',
        description: `利用AI技术的摄像头位置追踪系统`,
        link: '/cn/camera'
      },

      {
        title: '出入系统',
        description: '更快，更智能。<br/>新的出入系统',
        link: '/cn/access'
      },
      {
        title: '工业传感器',
        description: '从火灾检测到温湿度<br/>可立即应用于现场的传感器',
        link: '/cn/sensor/LoRaGateway'
      },
      {
        title: '物联网（IoT）',
        description: `从简单中带来特别的变化`,
        link: '/cn/iot/bell'
      },
      {
        title: 'Beacon终端',
        description: '完善的Beacon系统',
        link: '/cn/beacon/industry'
      },
    ]
  },

  control: {
    title: `通过ORBRO进行综合管理`,
    description: `一个软件<br/>从移动设备到桌面，您所需的一切简化`
  },
  showMore: '了解更多'
}
