import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledHeading, StyledLabel } from "@components/Text";
import styled from "@emotion/styled";




export const MenuTreeLayout = styled.div`

  position : absolute;
  top : 100%;
  left : 0;
  width : 100%;
  height : auto;
  

  border-bottom : 1px solid rgba(0,0,0,0.08);
  background-color : #FFFFFF;
  z-index : 6;
  
`;

export const MenuContainer = styled(StyledGridContainer)`

  padding : 32px 24px;
  height : 100%;
`;

export const MenuLayout = styled.div`
display: flex;
flex-wrap: wrap; /* flex 요소가 여러 줄로 떨어지도록 설정 */
margin: -16px; /* 각 MenuBox 주위의 간격을 조절 */

> div {
  width: calc(25% - 32px); /* 4개의 MenuBox가 한 줄에 들어감 (25% 너비), 간격 제외 */
  margin: 16px; /* 간격 추가 */
}
`;


export const MenuBox = styled.div`

  background-color : aqua;
  width : 100%;
  height : 100%;
  display : flex;
  flex-direction : column;
  
`;


export const MenuIcon = styled.img`
  width : 80px;
  height : 60px;
  display : flex;
  object-fit : contain;

`;


export const MenuOS = styled.div`

  width : 100%;
  display : flex;
  flex-direction : column;
  padding-left : 32px;
  border-left : 1px solid rgba(0,0,0,0.08);

`;

export const MenuOSIcon = styled.img`
  width : auto;
  height : 120px;
  display : flex;
  object-fit : contain;

`;

export const MenuHoverHeading = styled(StyledHeading)`
  width : fit-content;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }
`;
export const MenuHoverLabel = styled(StyledLabel)`
  width : fit-content;
  color : #646464;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }
`;

export const FitAtag = styled.a<{mt : string}>`
  width: fit-content;

  ${({mt})=> mt && `margin-top : ${mt}px;`}
`;