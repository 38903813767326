import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

import video from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

export default {

  helmet: '| デジタルトランスフォーメーション（DX）とは？',
  desc: 'デジタルトランスフォーメーションは、デジタルテクノロジーを使用して日常生活とビジネスの運用方法をデジタル化するプロセスを指します。これにより、効率的で生産的な方法が可能となり、市場の変化に適応し、新しい価値を創出できます。たとえば、作業者や在庫の位置をリアルタイムで追跡および管理することができます。',
  keywords: 'デジタルトランスフォーメーション、デジタルツイン、デジタルイノベーション、スマートシティ、ビジネスインテリジェンス、BI、イノベーション、スマートソリューション、第4次産業革命、ビジネス、DX、オルブロ、コンテック、RTLS、オルブロ、位置追跡',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'デジタルトランスフォーメーション（DX）とは？ :: オルブロ ブログ',
  ogUrl: 'https://orbro.io/jp/blog/digitalTwin2',
  ogKey: '',
  ogImg: '',
  ogType: 'ウェブサイト',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'デジタルトランスフォーメーション（DX）とは？',
  date: '2023-11-01',
  tag1: '#デジタルトランスフォーメーション',
  tag2: '#デジタルツイン',
  tag3: '#スマートシティ',
  tag4: '#ORBRO',

  text: [
    {
      head: '',
      subHead: '',
      desc: 'デジタルトランスフォーメーションは、従来のプロセスとビジネスモデルをデジタル技術で置き換えたり改善したりするプロセスを指します。これは伝統的なビジネスの方法を現代的で効率的な方法にアップグレードし、デジタル技術を活用して将来の機会を見つけることを含みます。デジタルトランスフォーメーションは、ビジネスから政府、ヘルスケア、教育、およびさまざまな他の分野に影響を与えており、その重要性はますます高まっています。<br/><br/>デジタルトランスフォーメーションの主要な目標は次のとおりです：<br/><br/><br/><strong>効率向上： </strong>より良いプロセスと作業方法により生産性を向上させ、コストを削減します。<br/><br/><strong>顧客体験の向上： </strong>デジタル技術を活用して顧客により良いサービスと体験を提供します。<br/><br/><strong>イノベーションの促進： </strong>新しいアイデアとビジネスモデルを開発して市場で競争力を確保します。<br/><br/><strong>データの活用： </strong>データを収集し、分析し、意思決定を支援するために活用します。',
      dot: ''
    },
    {
      head: 'RTLSテクノロジーとデジタルツイン',
      subHead: '',
      desc: 'リアルタイム位置追跡システム（RTLS）テクノロジーは、デジタルトランスフォーメーションの主要な構成要素の1つで、物理的な空間内のオブジェクト、リソース、または個人の位置を正確に追跡する技術です。 RTLSは、センサー、無線通信、データ分析、および可視化テクノロジーを介してリアルタイムの位置情報を収集および提供します。デジタルツインはRTLSテクノロジーと密接に関連しています。デジタルツインは現実の物理的な対象のデジタル複製であり、これを通じて物理的な対象の状態、位置、および相互作用をリアルタイムでモニタリングおよびシミュレーションできます。 RTLSテクノロジーはデジタルツインを維持および更新するために必要なデータを提供します。',
      dot: ''
    },
    {
      video: video,
      link: "https://orbro.io/enterprise/logistics/asset",
      head: '実際の事例',
      subHead: '',
      desc: 'デジタルツインに統合すると、産業環境で物理的な状態をシミュレーションすることができます。製造業では生産ラインの動きを監視および最適化し、医療分野では患者の位置を追跡し医療機器を管理します。スマートシティプロジェクトでは道路交通と災害管理を向上させ、物流および配送分野では在庫管理と注文処理を最適化します。例えば、自律型車両が商品を移動および配置するシナリオでは、RTLSテクノロジーはこれらの車両の位置を追跡し、デジタルツインを通じて衝突を回避し、経路を最適化する予測モデルを構築します。また、在庫レベルを最適化し、在庫の不足や過剰在庫の問題を防ぎ、これらのデータベースの洞察を活用すると、物流プロセスの効率を向上させ、顧客サービスの品質を向上させることができます。',
      dot: ''
    },
    {
      head: 'まとめ',
      subHead: '',
      desc: 'デジタルトランスフォーメーション（DX）は、現代社会でビジネスと組織の存続と成長のための重要な要因として登場しています。 RTLSテクノロジーとデジタルツインは、物理的な世界をデジタルに変換し、将来に備えるための鍵となる技術です。デジタルトランスフォーメーションを通じて、効率を向上させ、将来の課題に備えることができます。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: 'この製品は、作業者や装置にUWB端末を取り付け、リアルタイムの位置追跡をサポートします。',
        img: productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution: [
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/jp/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      },
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/jp/enterprise/manufacturing/asset'
      }
    ]

  }

}
