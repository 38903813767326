import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste } from "@pages/Enterprise/Solution/components/Banner/BannerImages";


export default {

  helmet: '',
  desc: '实时跟踪患者，并有效管理医务人员和资产，以优化治疗过程。此外，利用人工智能和数据分析监测患者健康状况，并提供最佳医疗服务。',
  keywords: '智能医院，患者管理，RTLS，医疗设施，患者流程，医院设备追踪，医院设备，位置追踪，ConTech',

  ogTitle: '',
  ogDesc: '',
  ogSite: '最先进的医院如何管理患者 :: Orbro博客',
  ogUrl: 'https://orbro.io/cn/blog/hospital',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZqhO9ezvqYIEyhhFrpVaYpW4QqZ73QOwXB7Atab7eG0KzsPeNRlfan0xcjBoTA4f9uOZsyyO3Aa7Ssy20vkBaALX1WP-ZEFqJmbRtvf8byaL_7pF89VhW9s1FZpdgvvCcvntuFyR8L7F4TIbbBzU78ARievgQnwDB_lzVgDXzEUFwCVB3-YfvTStDIeeQbBMYwXBRqeTxImshBEbYzfSrxzifccZi8NjY7581Zfdx8FsHKQxodIhZ29ZLBf2hUBttfK25Bt_5cKO11xm0Ub77S_b5M7F80YBvm8c9d8HWNm2lKwa0B9J7xK_8AnjeHNyqgk5fDREyJMJtElXsGgAsVr4esxClp1Jo-1Bu4q7FZ6x6Vst2Bdg9fgVC2FfZUkW0TMJf0TGQk4IHEdSi_tX-lHR-NuUPpslEUUTKpNuIBLM4trADP3kW3TMh-nuxDZ-W97b7Ml0EZEEhU_byAAqaH1P86CAJailGfFlXoPXY7se0SnBVgVcp9Bq4oNt8ejbJM6DuC3jMYWKNEsdSLoS9KuVf-cxKYrIx6HNENPs_tUYxZuA8kkIdeex_X5CkYZg8Z95iFRrmuR6-k-fS0ykwlLfiCPjbw_qyngo2P9vn2q-NkvMYRE1SUtS7MaMu1kDIk_f3yCqDzaDk8LIG9f42EzTAFM1QJxb6J39afoj_lF1hQOzM3OIyqithxbE4wq6iz0b70zB9MBMH2CP8btw5pT21L7GNXDa7w7dum6t7fiFkb249GmTOEbKr4brj6ZswEBzV6IsL4E9idjImLI5m-DrXPUUItPct4kt65sjT1yGoaEwqGk9lgbJOQWbI8UIk0WxzjIQEPjGj2Lw65GMqn5jj0k1LiyzE63GmSC4jyYG01Uk1oZQKuQVAiAIc0WNEPNlZtaufLrWeok3p-c-ZYIUvROQi8hyKxfZIWTtQC689Njt_974ehQ2JaJoUdkvHm4fWMuz1O2D_spUzxRTiztHX1N0uxC0Chhro0SH5fnIte5uAmlAlKfI5rAyDpNtSj1fDdPQJkntk-13pn6xAChyiCLn3jL8NaIu14FzJC4Hv9ZejC45VzaQYxl1UJkgC4eTuaJwLW2TWhJjYNcSXBV51iVuF73QisAxqadKjRmpLmZnEob03V2CkJ7hOZK-dqMjC3dtTeuFT4cqOotx_vFMdxjW83kXdCMWXqCVnL513yFcpJwBAWdniR9rrl-xjdIGyuZH-4v_2pFtpIUpdZkPIcRkw6KdnBbfzMjjIAu6ROi9gnSv7owjl5Dk_LeLqdKH98zhYoIqXrM9Z0FqvBgFdW2JqvTxkeOfRi1893ZVLvD4gXR1Nojbgnql_FwANyT5tVh5EPYyt2XvB9fCInBjzYkhHlZ2MPF92quV6_Ym9AkThfWXdPiC7vwKrQUT9B5CD5PpaoTyOzE8g1_-HydegZuWxfPLGCfb7VajMbW2hwA0uoC6vED-mcbtpZTKu2KCMH7CMEH-oAhQhRF-k4WkAGRz0Vuj4OY5OskCr4Jq8vQl-pEdGgh8b8T6Nnxx8mw7DohsssWwC9y4T1BIrPLZYGHhmRq2JJLKbJKrMRJA=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '最先进的医院如何管理患者',
  date: '2023-08-21',
  tag1: '#智能医院',
  tag2: '#患者追踪',
  tag3: '#患者管理',
  tag4: '#患者安全管理',

  text: [
    {
      head: '',
      subHead: '',
      desc: '是否有比医院更与我们的健康紧密相关的地方？由于健康可能是生活中最重要的方面，患者在选择医院时可能会设定最高标准。自然而然，此决定中的因素将是信任和技术。相信他们会受到良好照顾，并且支持该信念的医疗技术。如果这两个条件得到保证（在合理范围内），患者可能愿意为更好的服务付出更多。了解这一点的医疗设施管理者和行业专业人士越来越关注先进技术以提供更好的服务和业务。然而，许多人往往只关注医疗技术，经常忽视其他令人印象深刻的技术和系统。其中一个典型的例子是迅速增长的实时位置追踪技术（RTLS）技术。',
      dot: ''
    },
    {
      head: '什么是实时位置追踪系统（RTLS）？',
      subHead: '',
      desc: 'RTLS实际上是追踪物体实时位置的技术。仅凭此，您可能认为它与您熟悉的GPS没有太大区别。从位置追踪的角度来看，它们可能看起来相同，但实际上两种技术之间存在重大区别。首先，RTLS技术允许在室内实现更精确的位置追踪。例如，即使在需要快速判断或响应的情况下，也可以向管理员或相关人员发送实时警报。当然，RTLS技术本身并不一定意味着这些功能，但如果公司具有技术实力，它们可以构建具有多种功能的系统。那么，在医院中有哪些可能性呢？',
      dot: ''
    },
    {
      head: '',
      subHead: '医疗设备追踪',
      desc: '不久前，某家医院发生了一起医疗事故。这是由于对污染的医疗设备处理不当引起的感染事件。如果您是在遇到这种事故时的医院管理员，您会怎么做？遵循传统协议可能是一种方法，但如果医院工作人员（希望不会）可能有疏忽的潜力，或者如果医院的设备处理员是一名从未犯过错误的非常勤奋的工人，您会怎么做？事实是，人为错误很容易发生，结果不确定。RTLS技术可以帮助防止此类事件的可能性。污染的设备可以通过监视器进行实时监控，并且还可以设置系统以自动发送警报，如果设备超出特定路径，系统将自动发出警告。在完全安全处理之前，智能系统将持续监控。',
      dot: ''
    },
    {
      head: '',
      subHead: '危险区域管理',
      desc: 'RTLS系统可以用于维护安全设施，可以设置访问限制区域。如果有人进入该区域，系统将向管理员发送警报，以便迅速采取措施。例如，假设您在医院工作，并且突然收到警告称患者A正在医药保管区域进出。查看屏幕，您会发现患者A正在分院的药品保管室门前等待。幸运的是，您迅速呼叫了保安人员，成功地防止了事故的发生。',
      dot: ''
    },
    {
      head: '',
      subHead: '更多的扩展性',
      desc: 'RTLS技术未必提供上述功能。然而，通过RTLS可以构建具有各种功能的系统。将坠落检测技术整合进来，或者使用Orbro的出入系统，确保一切都得到适当处理，构建一个无懈可击且安全的智能医疗设施是可能的。如前所述，完全安全。此外，可以实时控制瓶颈或快速评估设施内资产的位置和数量，从运营的角度来看，效率得到提高。尽管人力和成本自然减少，但实际生产率会提高。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '通过将UWB终端附加到工作者或设备上，以实时方式支持位置追踪的产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {
        // 添加其他产品，如果有的话
      },
    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/cn/enterprise/medical/patient'
      }, 
      {
        img: medicalWaste.thumb,
        data: 'medicalWaste',
        link: '/cn/enterprise/medical/waste'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]
  }
}

