import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { logisticsAsset, manufacturingAsset, manufacturingProcess, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4"

export default {


    helmet: '',
    desc: '현장과 동일한 지도 위에서, 재고 위치와 공정 과정을 실시간으로 모니터링합니다. 디지털 자동화를 통해서 스마트공장을 구축하여 생산성, 품질을 향상할 수 있습니다.',
    keywords: '스마트 공장, 재고 관리, 자산 관리, 공정 모니터링, 생산 자동화, 공장 로봇, 자동 공장, 자동 공정, 스마트 팩토리, 콩테크, 오브로, rtls, orbro',

    ogTitle: '',
    ogDesc: '',
    ogSite: '스마트공장 구축 및 생산자동화 솔루션 :: 오브로 블로그',
    ogUrl: 'https://orbro.io/blog/productionAutomation',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxXE6MzArHBPAtgIpznMDdCH4J8pK06Pt6RC09sNHW7PTLIF0PmPta2ymrlAdxzXB1xM2c15BXwtMiUlMiXHV5-rX_y-MCj5rxn28ZcepfxYkwYGbTtkD2tiwtidfaA7UEiWPc_QZU5UvVQ_07DIs56mvdkD5LITBtqiZP7tZHrG0NN30d5aoj0cErsx5-AXHIQuv5zO6bwkV_42KLIq2YCCSe_xQfkSXyh6zwheUsNCyZV5Cs9oUd6ImOHrCWsB1JXDQljN2q2G8-li2gz1NQV2v7__j_4t3KgJDoCmqWuuFG495xjseOwBNsdbOkL9jBB79LwHj2EeEfqTUCmPYuKjA1HxHrHCe1kQDTXbjBxYc-GoornZ3F1PG1fmwyz5EQt9n7BVSD2smPwW9zHqSVYdcwpwdzjgnVBZ3tKehLBz7gRUtfjRxxmYWMbP6-WvApgEY0_TXrwuOnT_3R44fYEsAQeQSJU1xGt-FT7Kbs_cEtrKJ09pqHvl4tOeWBKTBgqNPQi6jC3AU8eKzNgSPVI641s3fQ9Bgfpq8FFw6Rt4ry87oCoCXJErzzb8G6TI-DWbBnvd0s49QfnIWK2e6fLVdGmnzgy4vMgGa8kwu4YUXRDNn4lju7F-Uwglr1KkkLrfsUgBJe-eLigJnmV32eHXFTRod_bwAstvo2SUiqPG-jwDDjG2mEyQlODDkYR9ghqohTDwpQWEen7XYXcAzlcuo-W2XT-KLvL_3K0LaSWaV9RfuH4lEvJZg_5O4AZVvbaoonwP8c3O2kPDidXj-aGYpEkZAiERMu_6E3LuqbK6LYx3XKDOKkv_BB5569dm6PjLKrCNvyKpsQHYuRzAD6KjmMC2M55q_b4QrIldAJ3OIGc3it9CKfkRPBchL0fmymgKDrP4bYSRskJ7ezbG3mrJlmftUW4zRbdV51Be_JnMCpDP7ZHni4RsYAeKni05hHJmQlYGqb3kj64d5eiJwctbRLV1PRD_TN5cT_1IILP4xyTKxXanxncYJeyv_1ju9rI5-ROwlCt_yt6IDKC6svINvZQGxFd27XpmvlkiFq2KNNTbKekQ7YI9QP9Wssadz30lALiW08lksJZ35fwt2xP_-Wsi6PcMQz-P3uXI_70uAZdl2X5Qe1NEjfoVSUv3r9e8L7Kq22Xo1x7MClBLI1lb88GAe4GaKiYNmhYL1A3YP-hn22TIwRvcJuFHOWWQa1GuowDDCu5xve-5jEasGKyBgkoliyCv4Whu5WYvSKb5uBwYRzJWXr4AuyUGPGTZwcO_067lWFWNoJz9s6XviIJ8aHA7_zJn5ZQrokTVhOC-Cv8m6SM8YfA0bKWlE4hUZjbYdzQDsCl95UzT9WaGLONhe7h7EUo3Nt_oIXYtUso9Lqo6fu8nvLxskCelBaZ5xLRDKYnrYpxehQPCO-iTxz9WGYxKbrTFeHIXJU4jwKn-Envs1kbg34tE5lnMSGW9mGEJLIsO8h7yJ2VrKeks-V2StiHHhLCtQvCZEKJIwLfeQ8wnOuTHDHNAnkuQDSTk6mGn7bOFAOHw7L0Aloc1_6w_HfzWqtEyxlm_9bWDuQyLv9LXHa1=w2000-h1548',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',


    title: '스마트공장 구축 및 생산자동화 솔루션',
    date: '2023-11-06',
    tag1: '#스마트공장',
    tag2: '#생산자동화',
    tag3: '#공정모니터링',
    tag4: '#스마트팩토리',

    // <br/>
    text: [
        {
            head: '',
            subHead: '',
            desc: '현대 제조업체는 경쟁력을 확보하고 생산성을 향상시키기 위해 스마트 공장 구축과 생산자동화 솔루션에 큰 관심을 가지고 있습니다. 이러한 추세는 현대 기술의 발전과 스마트 시스템의 확산으로 더욱 중요해졌으며, 실시간 위치추적 시스템인 RTLS(Real-Time Location System) 기술은 스마트 공장 구축의 핵심 기술 중 하나로 자리 잡았습니다.',
            dot: ''

        },
        {
            video: video,
            link : '/enterprise/manufacturing/process',
            head: '스마트 공장이란?',
            subHead: '',
            desc: '스마트 공장은 기존의 제조 시스템을 혁신적으로 개선하고 생산 프로세스를 최적화하는 목적으로 최첨단 기술과 자동화 시스템을 활용하는 제조환경을 말합니다. 스마트 공장은 제조업체에 다양한 이점을 제공하며, 이를 통해 품질 향상, 생산성 향상, 에너지 효율성 향상 등이 가능해집니다. 이러한 혜택을 실현하기 위해 RTLS 기술은 핵심적인 역할을 합니다.',
            dot: ''
        },
        {
            head: '스마트 공장의 모습',
            subHead: '',
            desc: 'RTLS 기술은 실시간 위치 추적 시스템을 의미하며, 다양한 기술과 센서를 사용하여 물체, 장비, 제품, 작업자 등의 위치 정보를 정확하게 추적하는 기술입니다. 이 기술을 스마트 공장에 적용하면 다음과 같은 이점을 얻을 수 있습니다.<br/><br/><strong>자원 관리 및 최적화 : </strong>RTLS를 사용하면 제조 시설 내의 모든 자원의 실시간 위치 정보를 파악할 수 있습니다. 이를 통해 장비, 자재, 작업자 등을 효율적으로 관리하고 최적화할 수 있습니다.<br/><br/><strong>생산 과정 모니터링 : </strong>RTLS는 제조 과정을 실시간으로 모니터링할 수 있도록 해줍니다. 생산 라인에서 발생하는 이상 현상을 빠르게 감지하고 대응할 수 있습니다.<br/><br/><strong>자동화와 로봇 활용 : </strong>RTLS를 이용하면 로봇 및 자동화 장비를 제어하는 데 사용할 수 있습니다. 이로써 생산 프로세스를 자동화하고 인력 투입을 최소화할 수 있습니다.<br/><br/><strong>재고 관리 : </strong>실시간 위치 정보를 기반으로 재고를 관리하고 재고 움직임을 추적할 수 있습니다. 이를 통해 재고 비용을 절감하고 효율적인 재고 관리가 가능해집니다.<br/><br/><strong>안전 및 보안 강화 : </strong>RTLS는 작업자의 위치를 추적하여 작업장 내 안전 및 보안을 강화할 수 있습니다. 비인가자의 출입을 감지하고 사고 예방에 도움을 줄 수 있습니다.',
            dot: ''
        },
        {
            head: '스마트 공장 구축 방법',
            subHead: '',
            desc: 'RTLS를 스마트 공장에 적용하기 위해서는 다음과 같은 단계를 거칠 수 있습니다.<br/><br/><strong>요구사항 분석 : </strong>먼저 제조업체의 요구사항을 분석하고 어떤 RTLS 시스템이 필요한지 결정합니다.<br/><br/><strong>센서 및 하드웨어 설치 : </strong>RTLS 시스템을 구축하기 위해 필요한 센서 및 하드웨어를 설치합니다. 이는 물체와 작업자의 위치를 추적하는 데 사용됩니다.<br/><br/><strong>데이터 수집 및 처리 : </strong>센서가 수집한 데이터를 처리하고 실시간 위치 정보를 생성합니다.<br/><br/><strong>시스템 통합 : </strong>RTLS 시스템을 다른 생산 시스템과 통합하여 자동화 및 모니터링에 활용합니다.<br/><br/><strong>최적화 및 개선 : </strong>시스템이 가동되면 생산 프로세스를 모니터링하고 최적화하기 위해 계속해서 개선 작업을 진행합니다.',
            dot: ''
        },
        {
            head: '생산자동화와 스마트 공장의 미래',
            subHead: '',
            desc: 'RTLS를 중심으로 구축된 스마트 공장은 미래의 제조업을 주도할 역할을 할 것으로 기대됩니다. 생산자동화와 스마트 시스템은 품질과 생산성을 향상시키는 데 중요한 역할을 합니다. 또한 인공 지능(AI)과 빅데이터 분석과의 통합을 통해 더욱 더 지능적이고 효율적인 공장 운영이 가능해질 것입니다.<br/><br/>스마트 공장 구축 및 생산자동화 솔루션은 현대 제조업체의 경쟁력을 강화하고 지속가능한 성장을 이루기 위한 필수적인 단계입니다. RTLS 기술은 이러한 미래 지향적인 노력을 지원하며, 혁신적인 제조 환경을 구축하는 데 기여하고 있습니다. 제조업체는 이러한 기술을 적극적으로 채택하여 스마트 공장의 성공적인 구축을 위한 노력을 지속해야 합니다.',
            dot: ''
        },
    ],


    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.assetTracking.thumbnail
            },
            {

            }

        ],
        solution: [

            {
                img: manufacturingProcess.thumb,
                data: 'manufacturingProcess',
                link: '/enterprise/manufacturing/process'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/enterprise/manufacturing/asset'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/enterprise/logistics/asset'
            },
        ]


    }

}