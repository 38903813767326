import { buildingWhite, groupWhite, hospitalWhite, toolsWhite, trafficWhite, truckWhite } from "@assets/images/pricing";

export default {

    title: 'Get a Quote',
    link: '/en/pricing/1',

    step1: {
        title: 'Which industry are you interested in?',
        percent: '0%',
        common: 'Use cases',
        industry: [
            {
                title: 'Construction',
                example: 'Worker safety management, material management, access control, environmental management, etc.',
                icon: trafficWhite
            },
            {
                title: 'Logistics',
                example: 'Inventory management, safety management, environmental management, access control, etc.',
                icon: truckWhite
            },
            {
                title: 'Hospitals',
                example: 'Access control, patient tracking, medical waste management, air quality control, etc.',
                icon: hospitalWhite
            },
            {
                title: 'Office',
                example: 'Access control, asset management, air quality control, etc.',
                icon: buildingWhite
            },
            {
                title: 'Manufacturing',
                example: 'Access control, inventory management, air quality control, process management, etc.',
                icon: toolsWhite
            },
            {
                title: "Etc",
                example: "Retail, aviation, port, pedestrian flow analysis, parking control, air quality management, etc.",
                icon: groupWhite
            }
              
        ],
        next: 'Next'
    },

    step2: {
        title: 'Which product are you interested in?',
        percent: '25%',
        common: 'Learn more',
        product: [
            
            {
                title: 'Beacon Devices',
                example: 'Waterproof and durable beacon devices validated for industrial use.'
            },
            {
                title: 'RTLS',
                example: 'Attach tags to assets and workers for precise and real-time location tracking.'
            },
            {
                title: 'Camera-based Tracking',
                example: 'Track and analyze people, vehicles, and more using AI-powered cameras.'
            },
            {
                title: 'Access Control System',
                example: 'Smartly manage building access quickly and securely.'
            },
            {
                title: 'Industrial Sensors',
                example: 'Monitor environmental conditions on-site, including temperature, humidity, pressure, leaks, particulate matter, carbon monoxide, etc.'
            },
            {
                title: 'Digital Twin',
                example: 'Collecting all data in space to construct virtual reality.'
            },
        ],
        prev: 'Previous',
        next: 'Next'
    },

    step3: {
        title: 'Please check the following items.',
        percent: '50%',
        question: [

            {
                title: 'What is the purpose and quantity of beacon devices?',
                parts: [
                    {
                        title: 'Purpose',
                        type: 'choice2',
                        option: [
                            { text: 'Simple purchase' },
                            { text: 'Research or pilot project' },
                            { text: 'Solution adoption (service usage)' },
                            { text: 'Other' },
                        ]
                    },
                    {
                        title: 'Expected Quantity',
                        type: 'choice1',
                        option: [
                            { text: '1 ~ 1,000 devices' },
                            { text: '1,000 ~ 2,000 devices' },
                            { text: '2,000 ~ 5,000 devices' },
                            { text: 'More than 5,000 devices' },
                        ]
                    },
                ]
            },
            {
                title: 'What is the accuracy and area size of your location tracking needs?',
                parts: [
                    {
                        title: 'Accuracy',
                        type: 'choice1',
                        option: [
                            { text: 'Asset tracking (Under 5m range for asset tracking)' },
                            { text: 'High-precision tracking (Under 50cm range for asset tracking)' },
                            { text: 'Other' },
                        ]
                    },
                    {
                        title: 'Area Size',
                        type: 'choice1',
                        option: [
                            { text: '0 ~ 100 square meters' },
                            { text: '100 ~ 500 square meters' },
                            { text: 'More than 500 square meters' },
                            { text: 'Not sure' },
                        ]
                    },
                ]
            },
            {
                title: 'Where will you use camera tracking, and what are the subjects to track?',
                parts: [
                    {
                        title: 'Subjects to Track',
                        type: 'choice3',
                        option: [
                            { text: 'People' },
                            { text: 'Vehicles' },
                            { text: 'Other' },
                        ]
                    },
                    {
                        title: 'Installation Location',
                        type: 'choice3',
                        option: [
                            { text: 'Indoors' },
                            { text: 'Outdoors' },
                            { text: 'Not sure' },
                        ]
                    },
                ]
            },
            {
                title: 'How many entry points do you have, and where are they located?',
                parts: [
                    {
                        title: 'Entry Points',
                        type: 'choice3',
                        option: [
                            { text: 'Apartments' },
                            { text: 'Offices and stores' },
                            { text: 'Other' },
                        ]
                    },
                    {
                        title: 'Number of Entry Points',
                        type: 'choice1',
                        option: [
                            { text: '1 ~ 10 entry points' },
                            { text: '10 ~ 20 entry points' },
                            { text: '20 ~ 50 entry points' },
                            { text: 'More than 50 entry points' },
                        ]
                    },
                ]
            },
            {
                title: 'What type of sensors do you need?',
                parts: [
                    {
                        title: '',
                        type: 'multichoice2',
                        option: [
                            { text: 'Emergency bell' },
                            { text: 'Fire detection' },
                            { text: 'Temperature/Humidity' },
                            { text: 'Leak detection' },
                            { text: 'Pressure' },
                            { text: 'Intrusion detection' },
                            { text: 'Air quality' },
                            { text: 'Carbon monoxide detection' },
                        ]
                    },
                ]
            },
            {
                title: ' ',
                parts: [
                    {
                        title: ' ',
                        type: ' ',
                        option: [
                            { text: ' ' },
                        ]
                    },
                ]
            },
        ],
        prev: 'Previous',
        next: 'Next'
    },

    step4: {
        title: 'Please enter your contactable email.',
        percent: '75%',
        email: 'Email',
        emailHolder: 'example@orbro.io',
        prev: 'Previous',
        next: 'Next'
    },

    step5: {
        title: 'We will contact you through the responsible person.',
        desc: 'Entering the required information for consultation will be helpful.',
        name: 'Name',
        nameHolder: 'John Doe',
        phone: 'Phone Number',
        phoneHolder: '123-456-7890',
        memo: 'Memo',
        memoHolder: 'Enter information here.',
        prev: 'Receive information by email',
        next: 'Submit additional information'
    },

    step6: {
        title: 'Completed',
        desc: 'We will contact you shortly after reviewing the information. If you need a consultation with a professional engineer, please proceed with the consultation reservation.',
        btn: 'Schedule a Phone Consultation'
    },

    schedule: {
        title: 'Please enter your available time for a call.',
        company: 'Company',
        companyHolder: 'Orbro',
        name: 'Name',
        nameHolder: 'John Doe',
        date: 'Date',
        time: 'Time',
        submit: 'Submit',
        morning: 'AM',
        noon: 'PM',
    }
}
