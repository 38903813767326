import { accessAuto1, accessAutoMain, accessPro1, accessProMain, accesscontroller1, accesscontrollerMain } from "@assets/images/main/access/product";
import { indoor280, outdoor280 } from "@assets/images/main/camera";
import { assetTracking1, workerTracking1 } from "@assets/images/main/rtls/product";

export default {


    a: [
      {text : "제품명"},
      {text : "설명"},
      {text : "통신 방식"},
      {text : "해상도"},
      {text : "조리개"},
      {text : "방수 방진"},
      {text : "작동 조건"},
      {text : "전원"},
      {text : "설치 지원"},
      {text : "영상 저장"},
      {text : "사람 추적"},
      {text : "차량 추적"},
      {text : "히트맵"},
      {text : "이상행동 감지"}

    ],


    b: [
      {text : "실외 카메라"},
      {text : "카메라를 통하여 사람, 차량, 등 다양한 객체의 위치 및 이상행동을 감지하고 관리 할 수 있습니다."},
      {text : "Ethernet"},
      {text : "2560 x 1440"},
      {text : "F 1.2"},
      {text : "IP67"},
      {text : "-40°C ~ +70°C, 0% ~ 95%"},
      {text : "12VDV"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},

    ],


    c: [
      {text : "실내 카메라"},
      {text : "카메라를 통하여 실내 사람들의 이동경로를 분석 할 수 있습니다."},
      {text : "Ethernet"},
      {text : "1920 x 1080"},
      {text : "F 1.4"},
      {text : "IP67"},
      {text : "-30°C ~ +60°C, 0% ~ 95%"},
      {text : "12VDC"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},

    ],
    
    
    spec: [
      
      {

      },

      {
        image : outdoor280,
        link : '/camera/outdoor'

      },


      {
        image : indoor280,
        link : '/camera/indoor'

      },
      
        
    ],
    
    
}

