import React, { PropsWithChildren, useState, useRef, useEffect } from "react";

import {
  ModalContainer, DialogBox, StyledDownloadPolicyLink, StyledDownloadPolicyBox, StyledDownloadPolicyLinkText,
  DialogTextBox, DialogBottomSection, DialogProductBox, DialogRequestBox,
  DialogInputBox, DialogInputText, DialogInput, ClearButton, ClearIcon,
  DialogInputBoxRequest, DialogInputRequest, StyledUnderline, DialogCheckBox,
  DialogRequestBtn, DialogRequestCancelBtn, DialogProductSection, DialogProductImg, DialogProductText, DialogProductPrice, DialogMainSection, DialogSpacing, DialogBorder, DialogReactText
} from "./Modal.style";


import { Trans } from "react-i18next";


import { IconCheckBoxBlack, IconCheckBoxEmpty, IconCloseIcon, IconXMark } from "@assets/images/icon/";
import emailjs from 'emailjs-com';

import { StyledHeading, StyledLabel } from "@components/Text";
import { StyledPrice, StyledPriceText, StyledRequestBtnWrapper } from "../PurchaseDetail.style";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import i18n from "@pages/i18n";
import { DialogSpanText } from "@pages/Enterprise/Solution/components/Banner/Modal/Modal.style";
import { BorderBottomLine, LabelColor, ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import { Div100flex, DivFlexRow, DivWidth100 } from "@pages/CommonStyle/commonDiv.style";
import { CloseBtn } from "@pages/CommonStyle/commonBtn";
import { Backdrop } from "@pages/Enterprise/Download/Modal/Modal.style";
export const emailData = React.createContext('');


export interface RequestFormData {
  email: string,
  theme: string,
  template: string,
}




interface ModalDefaultType {
  onClickToggleModal: () => void;
  mainProduct: Array<any>;
  mainProductCounts: Array<number>;
  mainTotalPrices: Array<string>;
  selectedProducts: Array<{
    title: string;
    partsCount: number;
    partPrice: string;
    image: string;
  }>;
  totalPrice: string;
  path: string,
}




function Modal({
  onClickToggleModal,
  mainProduct,
  mainProductCounts,
  mainTotalPrices,
  selectedProducts,
  totalPrice,
  path,

}: PropsWithChildren<ModalDefaultType>) {

  const location = useLocation();
  const formatPrice = (price) => {
    return price.toLocaleString();
  };

  const koAccessory = ['데드 볼트', '실내 자동문 센서'];
  const enAccessory = ['Dead Bolt', 'Indoor Auto Door Sensor'];


  // Input event
  const handleInput = (event, callback) => {
    const value = event.target.value;
    callback(value);
  }



  // Name Handle
  const [name, setName] = useState<string>('');
  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };
  const handleClearName = () => {
    setName('');
  };

  // Company Handle
  const [company, setCompany] = useState<string>('');
  const onChangeCompany = (e) => {
    const company = e.target.value;
    setCompany(company);
  };
  const handleClearCompany = () => {
    setCompany('');
  };

  // Email Handle
  const [email, setEmail] = useState<string>('');
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const handleClearEmail = () => {
    setEmail('');
  };

  // Phone Handle
  const [phone, setPhone] = useState<string>('');
  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };
  const handleClearPhone = () => {
    setPhone('');
  };

  // Request Handle
  const [request, setRequest] = useState<string>('');
  const onChangeRequest = (e) => {
    const request = e.target.value;
    setRequest(request);
  };

  const componentRef = useRef<HTMLDivElement>(null);

  const [focus, setFocus] = useState({
    name: false,
    company: false,
    email: false,
    phone: false,
    request: false,
  });


  const handleFocus = (field) => {
    setFocus((prevFocus) => {
      const updatedFocus = {} as {
        name: boolean;
        company: boolean;
        email: boolean;
        phone: boolean;
        request: boolean;
      };
      for (const key in prevFocus) {
        updatedFocus[key] = key === field;
      }
      return updatedFocus;
    });
  };


  const [checked, setChecked] = useState(false);
  const [submit, setSubmit] = useState(false);

  const policyCheck = () => {
    setChecked(!checked);
  };

  // Focus Handling
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setFocus({
          name: false,
          company: false,
          email: false,
          phone: false,
          request: false,
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    if (name && company && email && checked) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  }, [name, company, email, phone, checked]);

  let productText;
  const onSubmit = () => {

    if (submit) {
      let main = '';
      mainProduct.forEach((item, index) => {
        main += `메인 제품 : ${item.title} ` + `x ${mainProductCounts[index]} = ${mainTotalPrices[index]}\n`;
        // 원하는 형식에 따라 추가 필드를 가공하여 문자열에 저장할 수 있음
      });

      let select = '\n';
      selectedProducts.forEach((item, index) => {
        select += `액세서리 : ${item.title} ` + `x ${item.partsCount} = ${item.partPrice}\n`;
        // 원하는 형식에 따라 추가 필드를 가공하여 문자열에 저장할 수 있음
      });
      productText = main + select;
      emailjs.init('SoQtE3CThBY_wpeDn');
      const mailForm = {
        name: name,
        company: company,
        email: email,
        phone: phone,
        request: request,
        main: main,
        select: select,
      }

      let alertMessage;
      if (location.pathname.includes('/en/')) {
        alertMessage = "Thank you for your inquiry. We'll get back to you as soon as possible. Thank you."
      } else if (location.pathname.includes('/jp/')) {
        alertMessage = "お問い合わせありがとうございます。 できるだけ早くご連絡いたします。 ありがとう"
      } else {
        alertMessage = "문의주셔서 감사합니다. 빠른 시일내로 다시 연락드리겠습니다. 감사합니다."
      }

      // 이메일 보내기
      emailjs.send('service_7yv5b7d', "template_w67ngmr", mailForm)
        .then((response) => {
          console.log('이메일 보내기 성공:', response);
        })
        .catch((error) => {
          console.error('이메일 보내기 실패:', error);
        });

      alert(alertMessage);
      notionSubmit();
      onClickToggleModal();

    }
  };

  // 데이터를 Notion에 전송하는 함수
  const notionSubmit = async () => {
    try {
      const response = await fetch("https://orbro.io/submitToNotion", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name: name,
          corporateName: company,
          email: email,
          phone: phone,
          memo: productText + request
        })
      });

    } catch (error) {
      console.log('error', error);
    }
  }


  const lang = i18n.language;
  let currency;
  if (lang === 'en') {
    currency = '$';
  } else if (lang === 'jp') {
    currency = '￥';
  } else {
    currency = '₩';
  }

  return (

    <ModalContainer>
      <DialogBox>

        {/* 제품 문의하기 */}
        <DialogTextBox>
          <DivFlexRow >
            <StyledHeading size="small">   <Trans i18nKey={`productPurchase.modal.title`} /> </StyledHeading>
            <CloseBtn src={IconXMark}
              onClick={onClickToggleModal} size={"24"} alt="close icon" />
          </DivFlexRow>

        </DialogTextBox>

        <DialogBorder />

        {/* 아래부터는 스크롤 */}
        <DialogBottomSection>

          <DialogSpacing />
          <ParagraphColor size="medium" color='#818181'>  <Trans i18nKey={'productPurchase.modal.descHead'} /> </ParagraphColor>


          <div>

            <DialogSpanText deco={false} color={"#000"}>
              <Trans i18nKey={"productPurchase.modal.descMid"} />
            </DialogSpanText> {'\u00A0'}
            <DialogSpanText deco={true} color={"#000"}>
              <Trans i18nKey={"productPurchase.modal.phone"} />
            </DialogSpanText> {'\u00A0'}
            <DialogSpanText deco={false} color={"#000"}>
              <Trans i18nKey={"productPurchase.modal.descTail"} />
            </DialogSpanText>


          </div>

          <Spacing pixel="8" />
          <ParagraphColor size="small" color='#818181'>  <Trans i18nKey={'productPurchase.modal.time'} /> </ParagraphColor>

          <Spacing pixel="32" />



          {/* 하단 */}
          <DialogMainSection>


            {/* 왼쪽 */}
            <DialogProductSection>
              {mainProduct.map((item, index) => (
                <DialogProductBox key={index}>
                  <DialogProductImg src={item.img} alt="RTLS product" />
                  <DivWidth100>
                    <DialogProductText size="medium">
                      <Trans i18nKey={`${path}.mainProduct.${index}.title`} />
                      {' '}
                      {'x'}
                      {' '}
                      {mainProductCounts[index]}
                    </DialogProductText>
                  </DivWidth100>
                  <DialogProductPrice size="medium" color='#818181'>
                    {isNaN(parseFloat(mainTotalPrices[index].substring(1))) ?
                      <>
                        <Trans i18nKey={`simple.priceUnknown`} />
                      </>
                      :
                      <>
                        {formatPrice(mainTotalPrices[index])}</>
                    }
                  </DialogProductPrice>
                </DialogProductBox>

              ))}

              {selectedProducts.map((item, index) => (
                <DialogProductBox key={index}>
                  <DialogProductImg src={item.image} alt="RTLS product" />

                  <DialogProductText size="medium">
                    {/* 영어 페이지에서는 텍스트 검수 */}
                    {location.pathname.includes('/en/') && koAccessory.includes(item.title) ?
                      (
                        <>
                          {enAccessory[koAccessory.findIndex((koTitle) => koTitle.includes(item.title))]}
                        </>
                      )
                      :
                      (
                        (item.title)
                      )
                    }

                    {' '}
                    {'x'}
                    {' '}
                    {item.partsCount}
                  </DialogProductText>

                  <DialogProductPrice size="medium" color='#818181'>
                    {item.partPrice === '별도 문의' ?
                      <>
                        <Trans i18nKey={`simple.priceUnknown`} />
                      </>
                      :
                      <>
                        {item.partPrice}
                      </>
                    }
                  </DialogProductPrice>

                </DialogProductBox>
              ))}

              <Spacing pixel="16" />
              <BorderBottomLine />
              <Spacing pixel="16" />

              <DivFlexRow>
                {/* 총 가격 텍스트 */}
                <StyledPriceText size="xsmall" pixel={"0px"}>
                  <Trans i18nKey={`simple.price`} />{"\u00A0"}
                </StyledPriceText>

                {/* 총 가격 숫자 */}
                <StyledPrice size="xsmall" pixel={"0px"}>
                  {totalPrice === '별도 문의' ?
                    <>
                      <Trans i18nKey={`simple.priceUnknown`} />
                    </>
                    :
                    <>
                      {totalPrice}
                    </>}
                </StyledPrice>
              </DivFlexRow>


            </DialogProductSection>



            {/* 오른쪽 */}
            <DialogRequestBox>

              {/* 이름 입력 */}
              <DialogInputBox inputValue={focus.name}>
                <DialogInputText inputValue={focus.name}> {t(`productPurchase.modal.theme.name`)} </DialogInputText>
                <Spacing pixel="4" />
                <Div100flex>
                  <DialogInput
                    value={name}
                    placeholder={t(`productPurchase.modal.placeholder.name`) || ''}
                    onInput={(e) => { handleInput(e, setName) }}
                    onChange={onChangeName}
                    onClick={() => handleFocus('name')}
                  />

                  {name !== '' && focus.name && (
                    <ClearButton onClick={handleClearName}>
                      <ClearIcon src={IconCloseIcon} alt="close icon" />
                    </ClearButton>
                  )}
                </Div100flex>
              </DialogInputBox>

              <Spacing pixel="16" />

              {/* 회사 입력 */}
              <DialogInputBox inputValue={focus.company}>
                <DialogInputText inputValue={focus.company}> {t(`productPurchase.modal.theme.company`)} </DialogInputText>
                <Spacing pixel="4" />
                <Div100flex>
                  <DialogInput
                    value={company}
                    placeholder={t(`productPurchase.modal.placeholder.company`) || ''}
                    onInput={(e) => { handleInput(e, setCompany) }}
                    onChange={onChangeCompany}
                    onClick={() => handleFocus('company')}
                  />

                  {company !== '' && focus.company && (
                    <ClearButton onClick={handleClearCompany}>
                      <ClearIcon src={IconCloseIcon} alt="close icon" />
                    </ClearButton>
                  )}
                </Div100flex>
              </DialogInputBox>

              <Spacing pixel="16" />

              {/* 이메일 입력 */}
              <DialogInputBox inputValue={focus.email}>
                <DialogInputText inputValue={focus.email}> {t(`productPurchase.modal.theme.email`)} </DialogInputText>
                <Spacing pixel="4" />
                <Div100flex>
                  <DialogInput
                    value={email}
                    placeholder={t(`productPurchase.modal.placeholder.email`) || ''}
                    onInput={(e) => { handleInput(e, setEmail) }}
                    onChange={onChangeEmail}
                    onClick={() => handleFocus('email')}
                  />

                  {email !== '' && focus.email && (
                    <ClearButton onClick={handleClearEmail}>
                      <ClearIcon src={IconCloseIcon} alt="close icon" />
                    </ClearButton>
                  )}
                </Div100flex>
              </DialogInputBox>

              <Spacing pixel="16" />

              {/* 전화번호 입력 */}
              <DialogInputBox inputValue={focus.phone}>
                <DialogInputText inputValue={focus.phone}> {t(`productPurchase.modal.theme.phone`)} </DialogInputText>
                <Spacing pixel="4" />
                <Div100flex>
                  <DialogInput
                    value={phone}
                    placeholder={t(`productPurchase.modal.placeholder.phone`) || ''}
                    onInput={(e) => { handleInput(e, setPhone) }}
                    onChange={onChangePhone}
                    onClick={() => handleFocus('phone')}
                  />

                  {phone !== '' && focus.phone && (
                    <ClearButton onClick={handleClearPhone}>
                      <ClearIcon src={IconCloseIcon} alt="close icon" />
                    </ClearButton>
                  )}
                </Div100flex>
              </DialogInputBox>

              <Spacing pixel="16" />


              {/* 문의사항 입력 */}
              <DialogInputBoxRequest inputValue={focus.request} h={140}>
                <DialogInputRequest
                  value={request}
                  placeholder={t(`productPurchase.modal.placeholder.ask`) || ''}
                  onInput={(e) => { handleInput(e, setRequest) }}
                  onChange={onChangeRequest}
                  onClick={() => handleFocus('request')}
                />
              </DialogInputBoxRequest>

              <Spacing pixel="16" />

              <StyledDownloadPolicyBox>


                {checked ? (
                  <DialogCheckBox src={IconCheckBoxBlack} onClick={policyCheck} alt="checkbox" />
                ) : (
                  <DialogCheckBox src={IconCheckBoxEmpty} onClick={policyCheck} alt="checkbox" />
                )}

                <StyledLabel size="medium"><Trans i18nKey={"download.form.policy.check"} />  </StyledLabel>
                <StyledDownloadPolicyLink href="/terms/2" target="_blank" >
                  <StyledDownloadPolicyLinkText size="medium"><Trans i18nKey={"download.form.policy.link"} /></StyledDownloadPolicyLinkText>
                  <StyledUnderline />
                </StyledDownloadPolicyLink>
                <StyledLabel size="medium"><Trans i18nKey={"download.form.policy.agree"} /> </StyledLabel>

              </StyledDownloadPolicyBox>

              <Spacing pixel="32" />

              <StyledRequestBtnWrapper>

                <DialogRequestCancelBtn onClick={onClickToggleModal}>
                  <StyledLabel size="medium">
                    <Trans i18nKey={"productPurchase.cancel"} />
                  </StyledLabel>
                </DialogRequestCancelBtn>

                <DialogRequestBtn submit={submit} onClick={onSubmit}>
                  <LabelColor color='#FFFFFF' size="medium">
                    <Trans i18nKey={"productPurchase.submit"} />
                  </LabelColor>
                </DialogRequestBtn>

              </StyledRequestBtnWrapper>



            </DialogRequestBox>

          </DialogMainSection>

        </DialogBottomSection>
      </DialogBox>


      <Backdrop
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();

          if (onClickToggleModal) {
            onClickToggleModal();
          }
        }}
      />

    </ModalContainer>


  );
}

export default Modal;