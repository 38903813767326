import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: 'ORBRO通过数字化室内外建筑结构和基础设施，创新性地提升建筑管理和规划。这为建筑设计、设施管理和安全监控提供了关键信息。',
  keywords: 'Digital Twin, Digital Twins, 数字孪生, gis, 数字城市, RTLS, 位置追踪系统, 位置追踪技术, 体育, 制造, 物流, 医疗, 医院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - 建筑和室内信息的实施 :: ORBRO博客',
  ogUrl: 'https://orbro.io/cn/blog/digitaltwin-buildings',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twin - 建筑和室内信息的实施',
  date: '2023-12-26',
  tag1: '#数字孪生',
  tag2: '#数字建筑',
  tag3: '#数字城市',
  tag4: '#虚拟现实',

  text: [
    {
      head: 'Digital Twin - 建筑和室内信息的实施',
      subHead: '',
      desc:'ORBRO通过创新地实现建筑和室内信息，提升建筑管理和规划。为实现这一目标，它数字化建筑结构和基础设施，通过多样的数据提供建筑设计、设施管理和安全监控所需的关键信息。<br/><br/>通过高分辨率图像和3D建模详细呈现建筑外观和结构特征，而室内地图精确可视化建筑内部的布局。这些信息有助于清晰理解建筑的视觉表现和结构。<br/><br/>此外，建筑内基础设施信息的表达、实时设备状态检查以及外部设备集成在建筑管理、维护和自动化中起着重要作用。这使得能够实时确认建筑运营和管理所需的数据，并实现高效控制。',
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/KkvO62eClcU?si=AQmNMfH6nmQ5Aa3Z',
      head: '',
      subHead: '建筑的详细图形表示',
      desc: 'ORBRO利用高分辨率图像和先进的3D建模技术生动地展现建筑的外观和结构特征。这对建筑师、工程师和城市规划者评估建筑的设计和功能性至关重要。',
      dot: ''
    },
    {
      head: '',
      subHead: '室内地图表示',
      desc: '精确可视化建筑内部的布局，最大化室内空间的利用，并为安全和疏散规划提供必要的信息。',
      dot: ''
    },
    {
      head: '',
      subHead: '建筑内基础设施信息的表达',
      desc: '数字化建筑内部的关键基础设施，如暖通空调系统、电气和管道系统，提供建筑管理和维护所需的重要信息。',
    },
    {
      head: '',
      subHead: '实时设备状态检查',
      desc: '通过实时监控建筑内设备和系统，快速检测设备性能下降或故障，并进行及时的维护。',
    },
    {
      head: '',
      subHead: '外部设备集成',
      desc: '通过智能技术和物联网设备的集成，增强建筑管理系统的智能化和自动化。',
    },
    {
      head: '',
      subHead: '开发者中心支持',
      desc: '支持开发者访问建筑相关数据并开发自定义应用程序，引入建筑管理和运营的创新解决方案。',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '通过将UWB终端连接到工人或设备，支持实时位置追踪的产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      // 其他产品对象可以在这里添加
    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]
  }
}
