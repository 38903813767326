import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/medicalWaste/video/rtlsVideo.mp4";

export default {

    
  helmet : '| BLE是什么？| 蓝牙低功耗',
  desc : 'BLE（蓝牙低功耗）是一种主要用于低功耗设备之间数据通信的无线通信技术。BLE以低功耗和简便的实现而闻名，在建筑、制造和医疗领域得到广泛应用。',
  keywords : 'BLE、蓝牙低功耗、低功耗消耗、蓝牙、信标、蓝牙信标、rtls orbro、KongTech',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'BLE是什么？| 蓝牙低功耗 :: ORBRO博客',
  ogUrl : 'https://orbro.io/cn/blog/ble',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDnfNjLOQqRH35cwkWdJ-bHoz620gNvKlc65H16bDwbDmM9mn2WV9CZBz5ngF-qPG8Kaio7OwcThigmqtAyLII5-_7o_wuEr9IGXgBBhy_HG2TtVg8JvI3jjx47Vjw5bkjNMP9pqYdZG-uQE7MSvCn-fUZW5RKVlHT6Wej9ZUjr8AuM0noE2LJXIBs8o7Ik2bNeGQcps-3AfWrgOGhlIFNJuCK2uSB1AW_cjwmHTkEIusWJalOUeSVOcaarM8cTR1xUNWmao0lb1KbxQ8qqbL4_XhFZkkUct1UG8RrIN6Psi-AsTpgkFOrBdDPlqH541BEN9bwdOETx3Kgc9iRes1JUSRoP7nrOyakxoo3aqXTFdAoosGnVLKOiER0DPiU3G07ZA9bJlehSeDDCzu8kynXgnTFz91gKfMi3avcdxHqpzaiBhQCXLBUaUM95BEkE-EXHcK8Nl2WmqiTNP7CThyNvqwZdyy1JVK3ZAc5waSTB186EjteUi4k9pd65QfukNba5px9D4ul0TIzbpY_aBS2bxSfdqlASAVpqQacahwDH0yFO0S1gPaNEMW4Rcqk4iQBol7VLpCY1Snlty4mEaUfgoKOxdkkZU3nUI7YT5EXM3uWYUNTI7a2y9qeT4hrtloASb9mN6DcMg_aqnP7emz1CZ-imi3vFJic3ohSrWlQulA-3p__vjLjaVuslbfw63OjFvQAtmnYu1VRZInPMAtDL_vflRts6E5KekPnuL4Ca5Kze4Wh9CVzuPlcdqh4jXIkUI4GEE6n9fX8ZU3eWJ3vJXWl5AsFXSA5fbMZ399h7V9B5DzuThH9vBR99mQqMnDcu-wMSq8GtgfrQvtysw0KoPKwz2uo8D1Wg5qhsJ9KCDj_gm3BY67-wftHaGOtlwve9hBoHyAlPwsV0BA4t6_tpuqzg3jfkv8hlWyWZ7x9KUguu0Im_zMOGYfPw_YdhWZoe_erVB6bGz7UHbyN2Vo-aMOK4eAjPDtOgP0qR90Sg-KjmiPwCEGL1SFTjzDht-pBQVVLCOMRzOuRArCvUZai0a4G2-JBnIxVPxNfCsLrLu33X6-UgxzpkrQfBr0YcAINxqDA_IfOOJc3C9PKFPyhTgp7cP_KVk2QsY25pHgMDTc49Ui4nwk2FxSdvlshqEZHbPKbtQHQ_JK-y3veLdrgV9ADCimrqBeHANlcWKuovdxhGvrr2mlRG-t2pD_62qO-Gh0t93pn9qocK1oVmPweHfGPMC2E1Z_GtmeOr33-mwXkKK-R7LUT7aLOO359xDj0C__aXlIFScOt-8RVul4IJ8HQoENLTkwNTs-jt-Z_LMbO73LvC5YlngDpmg1n9_NLCcomYIxw6PBKksDaqWJM732sqJiEsvmm9NYyVnBneRLbCAIKbq6xsH1y98rbeCc8tlWYSBfUNFx-CvWG-L_8IrgL0bhnMKFzN4bHqJCVezRfIHgDdfdOwOSN3xxFZmg4KSWjdPA3VtyO57X_zgNjlVtEE6PDawKLNXUiaAd6nyZtpP-FLbph9jZ7-Ws8g_A_gHtSv2NQOq3CQ73x9_YgopHxq4rJ3W=w2189-h1213',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'BLE是什么？| 蓝牙低功耗',
  date : '2024-01-04',
  tag1 : '#BLE',
  tag2 : '#蓝牙低功耗',
  tag3 : '#蓝牙',
  tag4 : '#信标',
    
  text : [
    { 
      head : 'BLE是什么？',
      subHead : '',
      desc :'BLE（蓝牙低功耗）是一种主要用于低功耗设备之间数据通信的无线通信技术。BLE以低功耗和简便的实现而闻名，在建筑、制造和医疗领域得到广泛应用。在本博客中，我们将深入探讨BLE的基本概念、运行原理、主要特点以及各种应用案例。',
      dot : ''
    },
    {
      videoDesc : '(医疗废物管理解决方案，请点击查看)',
      video: video,
      link : '/enterprise/medical/waste',
      head : 'BLE的基本概念',
      subHead :'',
      desc : 'BLE（蓝牙低功耗）主要设计用于智能手机、可穿戴设备、传感器、医疗设备等低功耗设备之间的通信。类似于蓝牙，它使用2.4GHz频段，但通过最小化功耗来支持长时间的电池寿命。',
      dot : ''
    },
    {
      head : 'BLE的运作原理',
      subHead :'',
      desc : 'BLE基本上以定期广告（Advertising）和连接（Connection）两种模式运行。在广告模式下，设备通告其存在给周围环境，而在连接模式下，实际数据交换发生。这使得即使在低功耗状态下也能进行高效的通信。',
      dot : ''
    },
    {
      head : 'BLE的主要特点',
      subHead :'低功耗消耗',
      desc : 'BLE设计适用于由电池供电的设备。在广告模式下以低功率运行，并在连接时保持最佳功耗。',
      dot : ''
    },
    {
      head : '',
      subHead :'各种应用领域',
      desc : 'BLE广泛应用于各种领域，包括智能家居、智能医疗、智能城市等。可以实现传感器网络、基于位置的服务以及室内位置跟踪等各种应用。',
      dot : ''
    },
    {
      head : 'BLE的应用案例：RTLS技术',
      subHead :'实时位置追踪系统（RTLS）',
      desc : 'BLE在RTLS技术中发挥着关键作用。RTLS是一种追踪物体或实体实时位置的技术，在各种工业领域中得到应用。',
      dot : ''
    },
    {
      head : '',
      subHead :'物流和仓库管理',
      desc : '在物流和仓库中，使用BLE标签实时追踪商品或资产的位置。这优化了库存管理、物流采购以及仓库内移动路径，提高了效率。',
      dot : ''
    },
    {
      head : '',
      subHead :'室内定位服务（IPS）',
      desc : 'BLE在IPS中发挥着重要作用。它在购物中心、机场、博物馆等公共场所通过智能手机应用向访客提供室内地图、特别折扣信息或展品详细信息。',
      dot : ''
    },
    {
      head : '',
      subHead :'制造和生产过程管理',
      desc : '在制造业中，使用BLE实时监控生产过程中设备、零部件或产品的位置。这提高了生产过程的效率并最小化了故障处理时间。',
      dot : ''
    },
    {
      head : '',
      subHead :'医疗保健系统',
      desc : '在医院或医疗机构中，使用BLE追踪患者位置并监控医疗设备的状态。这有助于在紧急情况下迅速响应和患者监控。',
      dot : ''
    },
    {
      head : '展望',
      subHead :'',
      desc : 'BLE预计将继续发展，并应用于更多的产业和生活领域。更强大的安全功能、更高的传输速度以及更广泛的连接范围可能是未来BLE发展的一部分。这些发展有望创造出更高效、便利的无线通信环境。',
      dot : ''
    },
  ],

  link : {
    page : 'rtls',
    numb : '1',
    product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : '通过安装UWB终端在工人或设备上，提供实时位置追踪的产品。',
          img : productImg.product.workerTracking.key1
        },
      {

      }

    ],
    solution : [
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/cn/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/cn/enterprise/logistics/asset'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/cn/enterprise/manufacturing/asset'
      }
    ]

  }


}
