import styled from "@emotion/styled";
import { CommonTextProps } from "./CommonText.style";
import { TypeMap } from "@core/util";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";

type SizeTypes = 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall';
interface StyledParagraphProps extends CommonTextProps {
  size: SizeTypes;
}

type ParagraphData = {
  size: string,
  height: string,
}
const sizeMap: TypeMap<SizeTypes, ParagraphData> = {
  xlarge: { size: '20px', height: '30px' },
  large: { size: '18px', height: '1.56' },
  medium: { size: '16px', height: '1.5' },
  small: { size: '14px', height: '1.43' },
  xsmall: { size: '12px', height: '1.33' },
};


export const ParagraphH1Banner = styled.h1<StyledParagraphProps>`

  margin-top : 16px;
  color : #BDBDBD;
  width : 680px;

  font-size: ${({ size }) => sizeMap[size].size};
  line-height: ${({ size }) => sizeMap[size].height};
  font-style : normal;
  font-weight : 400;


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    width : 400px;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    max-width : 90%;
  }


`;




export const ParagraphH1 = styled.h1<StyledParagraphProps>`

  font-size: ${({ size }) => sizeMap[size].size};
  line-height: ${({ size }) => sizeMap[size].height};
  font-style : normal;
  font-weight : 400;

`;


export const ParagraphH1_purchase = styled.h1<StyledParagraphProps>`

  font-size: ${({ size }) => sizeMap[size].size};
  line-height: ${({ size }) => sizeMap[size].height};
  font-style : normal;
  font-weight : 400;
  color : #646464;
  margin-bottom : 8px;

`;



export const ParagraphH2_646464 = styled.h2<StyledParagraphProps>`

  font-size: ${({ size }) => sizeMap[size].size};
  line-height: ${({ size }) => sizeMap[size].height};
  font-style : normal;
  font-weight : 400;
  color : #646464;
`;


