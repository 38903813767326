import { FlexStyle, Vertical } from '@assets/styled/flex';
import { StyledLabel } from '@components/Text';
import styled from '@emotion/styled';

export const StyledSubList = styled(Vertical)`
  width: 204px;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 0 12px 0 ${({ theme }) => theme.BORDER_TRANSLUCENT};
  border: solid 1px ${({ theme }) => theme.BORDER_TRANSLUCENT};
  background-color: #fff;
  position: absolute;
  top: calc(100% + 24px);
  left: 50%;
  transform: translate(-50%);

  & * {
    font-family: Pretendard !important;
    font-display: swap;
  }
`;

export const StyledSubListSolution = styled(Vertical)`

  width: 204px;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 0 12px 0 ${({ theme }) => theme.BORDER_TRANSLUCENT};
  border: solid 1px ${({ theme }) => theme.BORDER_TRANSLUCENT};
  background-color: #fff;
  position: absolute;
  top: calc(100% + 24px);
  left: 50%;
  transform: translate(-50%);

  & * {
    font-family: Pretendard !important;
    font-display: swap;
  }
`;

export const StyledSubListTitle = styled(StyledLabel)`
  color: ${({ theme }) => theme.CONTENT_TERTIARY};
  padding: 8px;
`;

export const StyledSubListItems = styled.div``;

export const StyledSubListItem = styled(FlexStyle)`
  width: 100%;
  border-radius: 8px;
  padding: 16px 8px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

export const StyledUSbListItemText = styled(StyledLabel)``;
