
import {
  BannerImg, BannerPage, ImageInner, ImageInnerBox, BannerButton,
  BannerButtonRequestA, BannerLayout
} from './Banner.style';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import * as banner from "./BannerImages";
import Modal from './Modal/Modal';

import ModalSubscribe from './Modal/ModalSubscribe';
import ModalDone from './Modal/ModalDone';
import { useLocation } from 'react-router-dom';
import { LabelColor, ParagraphColor, Spacing } from '@pages/CommonStyle/common.style';
import { DisplayH1fff } from '@components/Text/HeadingTagDisplay.style';
import { DivFlexColumn, DivFlexRow } from '@pages/CommonStyle/commonDiv.style';

interface ModalDefaultType {
  pageName: string
  path: any
  onRequestSection: () => void;
}

const Banner = ({
  pageName,
  path,
  onRequestSection
}: PropsWithChildren<ModalDefaultType>) => {

  const location = useLocation();
  const [isChina, checkChina] = useState(false);
  useEffect(() => {
    if (location.pathname.includes('/cn/')) {
      checkChina(true);
    } else {
      checkChina(false);
    }
  }, [location.pathname])

  const onMoveRequest = () => {
    onRequestSection();
  };

  const [isDownload, setDownloadModal] = useState(false);
  const [isSubscribeOpen, setSubscribeOpen] = useState(false);
  const [isDoneOpen, setDoneOpen] = useState(false);

  const [getEmail, onGetEmail] = useState<string>('');
  const handleSaveEmail = (email) => {
    onGetEmail(email);
  }

  const onClickToggleModal = useCallback(() => {
    setDownloadModal(!isDownload);
  }, []);
  const onSuccess = useCallback(() => {
    setDownloadModal(false);
  }, []);

  const onSubscribe = () => {
    setSubscribeOpen(!isSubscribeOpen);
  }
  const onDone = () => {
    setDoneOpen(!isDoneOpen);
  }

  const getLang = () => {
    if (location.pathname.includes('jp')) {
      return '/jp';
    } else if (location.pathname.includes('cn/')) {
      return '/cn';
    } else if (location.pathname.includes('en/')) {
      return '/en';
    } else {
      return '';
    }
  }

  return (
    <BannerPage>
      <BannerImg img={banner[pageName].banner} />

      <BannerLayout>

        <ImageInner>

          <ImageInnerBox desktop={6} tablet={8} mobile={4}>

            <DivFlexColumn>
              <DisplayH1fff size="medium">
                <Trans i18nKey={path.banner.title} />
              </DisplayH1fff>
              <Spacing pixel="8" />
              <ParagraphColor size="medium" color={'#FFFFFF'}>
                <p>
                  <Trans i18nKey={path.banner.desc} />
                </p>
              </ParagraphColor>

              <Spacing pixel='32' />

              <DivFlexRow>

                {isChina ?
                  <><BannerButton onClick={isChina ? undefined : onClickToggleModal} ok={false} >
                    <LabelColor size="large" color={'#FFF'}>
                      <Trans i18nKey={"solutionCommon.banner.download"} />
                    </LabelColor>
                  </BannerButton></>
                  :
                  <>
                    <BannerButton onClick={isChina ? undefined : onClickToggleModal} ok={true} >
                      <LabelColor size="large" color={'#FFF'}>
                        <Trans i18nKey={"solutionCommon.banner.download"} />
                      </LabelColor>
                    </BannerButton></>}

                <BannerButtonRequestA href={`${getLang()}/pricing/1`}>
                  <LabelColor size="large" color='#000'>
                    <Trans i18nKey={"solutionCommon.banner.request"} />
                  </LabelColor>
                </BannerButtonRequestA>

              </DivFlexRow>

            </DivFlexColumn>
          </ImageInnerBox>

        </ImageInner>

        {isDownload && (
          <Modal onClickToggleModal={onSuccess} content='download' onSubscribe={onSubscribe} onSaveEmail={handleSaveEmail} pageName={pageName}>
          </Modal>
        )}

        {isSubscribeOpen && (
          <ModalSubscribe onClickToggleModal={onSubscribe} onDone={onDone} email={getEmail}>
          </ModalSubscribe>
        )}

        {isDoneOpen && (
          <ModalDone onClickToggleModal={onDone}>
          </ModalDone>
        )}

      </BannerLayout>

    </BannerPage>
  )
};

export default Banner;