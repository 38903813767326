import { FlexStyle } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";

interface FaqDetailProps {
  isOpen: boolean
}

export const StyledFaqItemTitleIcon = styled(FlexStyle)`
  width: 64px;
  height: 100%;
  user-select: none;
  justify-content: center;
  align-items: center;
  right : 0;


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 20px;
  }

`



export const StyledFaqItemDetail = styled(FlexStyle)<FaqDetailProps>`
  display: ${({isOpen}) => isOpen ? 'flex' : 'none'};
  margin-bottom : 20px;
  width : 100%;
  flex-direction : column;

`


export const StyledSpecTitleBox = styled.div`
  padding : 12px 0;
  width : 100%;

`
 

export const StyledSpecDescBox = styled.div`
  padding : 12px 0;
  width : 100%;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-left : 30px;
  }


`