import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {

  helmet: '| RFIDの代わりにRTLS',
  desc: 'RFID技術の制約を克服するために、RTLS（リアルタイムロケーションシステム）技術を使用すると、より現代的で効果的な解決策を体験できます。今すぐOrbroの技術を確認してください。',
  keywords: 'rfid, rtls, uwb, ワイヤレス通信, 位置追跡, オブジェクト位置追跡, 人の位置追跡, タグ, ビーコン, rtls, orbro, オブロ, コンテック',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RFIDの代わりにRTLS :: オブロブログ',
  ogUrl: 'https://orbro.io/jp/blog/rfid-rtls',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDydFqsSpxfUGJRfgotjd5r5s27p9Jf2p08DcmZQY7K9VFxeg9rqDdYY9Gty2zcbPdkTtDG8IT8VHVI8Cu9Xay4Y9sNkWKyhNf5MZZfEJmSwx5RxDXIBhNLiVFBCl6asQi7XkVXIwVpqgVKugt94uXMpRmAUIUCYNCbhGMRV0zD7wJHpv6g4sEO3b3FkYBmPo0wrorAZ2UZIwYz4wRbFewYqqUgH0P6BidU5KE0kQsn2HGiIRRQlmCsPEY3rI0XKSGLatvI5vRo7-7c8DOoNlcOIpsMLXxaBYF3suQGFhdxrqEhA8t5mY3vsnWX2JOTgDdhEae70uPkPmf6IqEUhYr4i-kDTR-rCIjoikpAUHb2w5BUtdC3vMMJ3K8zZ2WbXTUDfkeCyjCN8C95-hMsUQX_dK52Yb2k6kTPrFuJWsiL7OSFsXX7TyjBajZX3yx1DfjD2OitZ7pZZSD6HV37MrXk0sykUNnUTbjSdW3NNVWoIBs0F4ur3TY23NSm8UKCWdXDvmlGsQrur-inPNF8C0iYeb2fEkwmH-WPv92-_V2JM5Q7t--49WhtDqsZHE5jlndPD7lFWwQa2qfEKhOExytxc3Vxt4LvM9BWSz3eR4p0jQt8OX3pXvRFcoc6wZEGANIoVWjvXj8HyvYZaYE56ckP2tHBQoruYpNVs4AfP8_ECNMPz4tfxLmvLQF9XYGOu_QZAFxMshOsDsJmAFOiRvKRKtNYQzCaJFgZCWibWTWvkbo52R_irSOgQZqIahJbvebkjVJmY8s9HJ0ouwoM7hApeuHgaFpMQ8gN1ov_4CqlBoa_pGCFaZvMOiJzqLIImEuRDrGWGOb9XuHS6dI22h-k3C5498w8ybVmF45VfA76In3QlmWtMx7ua2zT_bsj1AWfMJWaglRKkImhInZNRwMZFSSifk4ept8JyDKQox_XnxyJkVdojTLFKruj3z_uJ6M8nVcbIspvyz1QggC63Fp3acz35N2EXmQ0J8JZeWUnyLrhuwfJ-Wc6Ovja6LEF9gbjgAoesYWEghHYi3ByOzsEcFhHsQM9cQ1UgZUrRg_nf3Ic1V49R3ewe6J5HmaIqXADdHXQ4fWuZjZ4RpRe9ffQThkcH9PwHGFH5eRvgMLO2Ey8q_PdaHnN-1B2tUJ7IWkt2GMllFbOnfC2F7Z7yhNLOWss-MAXrtBW702-RreLR8GFAlMB7UnW3xrKC7GJCnJqJQfL913t9SZJyXm_52svc-p4d0UlQvuYUN7N0AA34STZtooGf9cSNITDeb62jWztMNJFGTw5YVTHqdsVttLICWXOO1_Q8_ygXfLtKu_YvfGDfhZqIqyMgk8OcDk1rYzMYok0P0j6grBuhSs8IyoPctYZddBCbfjze8PiLcDu22_gIz6biHHDr1-R4WtPNoyWavEKXnWNLMRAnSxy67bG83C5wbqYWv4pDA8mTGd1QfYqxLh4e2a4TXm6vxN0SOt_CJZSEa6cO64dcsuX74_Y12xT2AtRXErifMIoUyB0JFZCUbLPWq1jd20L_J22kFD6e-uaGmBSUQH_gZsMmYuS9Sf82buto=w2087-h1196',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RFIDの代わりにRTLS',
  date: '2023-11-28',
  tag1: '#rfid',
  tag2: '#rtls',
  tag3: '#uwb',
  tag4: '#ワイヤレス通信',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: 'RFID技術は数年にわたり、物流および資産追跡の分野で効果的に活用されています。しかし最近では、RFIDの代わりにRTLS（リアルタイムロケーションシステム）技術が物流および資産管理の分野で注目を集めています。この投稿では、RFIDの代わりにRTLSが従来の方法を超えた革新をもたらしている方法について探ってみます。',
      dot: ''
    },
    {
      head: 'RTLSの概念と動作原理',
      subHead: '',
      desc: 'RFIDは物理的なラベルやタグを介してオブジェクトを識別する技術であり、特定の範囲内でのみ情報を収集できます。一方でRTLSは、ワイヤレス通信と位置追跡技術を組み合わせてオブジェクトの位置をリアルタイムで把握できるシステムです。GPS、センサー、ビーコンなどを活用して正確な位置情報を提供し、物流および資産管理に優れた性能を発揮します。',
      dot: ''
    },
    {
      video: video,
      link: '/jp/enterprise/manufacturing/asset',
      head: 'RTLSの利点',
      subHead: 'リアルタイム位置追跡',
      desc: 'RTLSはオブジェクトのリアルタイム位置を正確に追跡できるため、物流管理でのリアルタイムトラッキングが可能です。これにより製品の移動経路を最適化し、物流作業を効率的に実行できます。',
      dot: ''
    },
    {
      head: '',
      subHead: '正確なデータ提供',
      desc: 'RFIDは特定の範囲内でのみ情報を収集できるため、精度に制約があります。しかしRTLSはセンサーと組み合わせて高精度の位置情報を提供するため、物流および資産管理で正確なデータを取得できます。',
      dot: ''
    },
    {
      head: 'どの技術を選択すべきか？',
      subHead: '多様な応用分野',
      desc: 'RTLSは物流だけでなく、医療、製造、運輸などさまざまな産業分野で活用可能です。これは企業が1つの技術を複数の領域に適用し、その効果を最大限に引き出すことができることを意味します。',
      dot: ''
    },
    {
      head: '成功事例とビジネス効果',
      subHead: '',
      desc: '世界中のRTLSを導入した企業は高い生産性と効率を達成しています。たとえば、医療分野ではRTLSを使用して医療機器や患者の位置をリアルタイムで追跡し、緊急事態への迅速な対応が可能となり、物流分野では製品の移動経路を最適化してコスト削減が行われています。',
      dot: ''
    },
    {
      head: '将来の展望',
      subHead: '',
      desc: 'RFIDは従来は厳しい環境でも効果的に使用されてきましたが、RTLSの登場により物流および資産管理がより洗練され、効率的になりました。将来的には人工知能との統合、ブロックチェーン技術の採用などにより、より進化したRTLSシステムが登場すると予想され、企業はこれらの技術を積極的に取り入れて将来の市場競争に備えるべきです。',
      dot: ''
    },
    {
      head: 'まとめ',
      subHead: '',
      desc: 'RFIDからRTLSへの移行が注目されているのは、リアルタイムの位置追跡と正確なデータ提供が可能であるためです。企業は革新的な技術を積極的に採用してより良い未来に備えるべきです。RFIDからRTLSへの移行は、新しい技術を通じてより良い未来を準備する現代企業の一歩となるでしょう。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を労働者や設備に取り付けて、リアルタイムでの位置追跡を支援する製品です。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/jp/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/jp/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/jp/enterprise/office/asset'
      }
    ]
  }
}
