import { FlexStyle, Vertical } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading } from "@components/Text";
import styled from "@emotion/styled";

export const StyledNews = styled.div`
  background-color: #f8f8f8;
  padding: 160px 0 128px;
`;

export const StyledNewsNav = styled(FlexStyle)`
  margin-top: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const StyledNewsSubtitle = styled(StyledHeading)`

`;

export const StyledNewsTitle = styled(StyledDisplay)`

`;

export const StyledNewsArrow = styled.img`
  margin-left: 8px;
  cursor: pointer;
`;

export const StyledNewsItems = styled.div`
  margin-top: 64px;
`;

export const StyledNewsItem = styled(Vertical)`
  padding: 32px 0;
  border-bottom: 1px solid ${({ theme }) => theme['BORDER_TRANSLUCENT']};
`;

export const StyledNewsItemTag = styled(StyledHeading)`
  margin-right: 16px;
  color: ${({ theme }) => theme['CONTENT_SECONDARY']};
`;

export const StyledNewsItemDate = styled(StyledHeading)`
  color: ${({ theme }) => theme['CONTENT_TERTIARY']};

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    font-size: 18px;
  }
`;

export const StyledNewsItemTitle = styled(StyledHeading)`
  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const StyledNewsItemIcon = styled.img`
  margin-left: 16px;
`;