import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet: '',
  desc: 'ORBRO通过集成实时位置数据和3D可视化，有效管理城市内个体和车辆的移动，提供先进技术以增强交通系统的安全性和效率。',
  keywords: 'Digital Twin, Digital Twins, 数字孪生, gis, 数字城市, RTLS, 位置追踪系统, 位置追踪技术, 体育, 制造, 物流, 医疗, 医院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - 连接人和车辆的实时动态 :: ORBRO博客',
  ogUrl: 'https://orbro.io/cn/blog/digitaltwin-tracking',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twin - 连接人和车辆的实时动态',
  date: '2023-12-26',
  tag1: '#DigitalTwin',
  tag2: '#RTLS',
  tag3: '#DigitalCity',
  tag4: '#VirtualReality',

  // <br/>
  text: [
    {
      head: 'Digital Twin - 连接人和车辆的实时动态',
      subHead: '',
      desc:'ORBRO通过整合位置数据和3D可视化，有效管理城市内个体和车辆的运动。为实现这一目标，利用各种技术和系统提高交通系统的安全性和效率。<br/><br/>RTLS（实时定位系统）技术实时追踪人们的位置和动向，而视频技术监视车辆的移动。3D可视化直观呈现人员和车辆的动态移动，有效管理指定区域内的移动。<br/><br/>数字转型仪表板汇总收集的数据，提供见解深刻的信息，智能城市集成提高了城市运营的自动化和效率。这优化了城市内的交通流和基础设施使用，支持智能城市的实施。',
      dot: ''
      
    },
    {
      youtube: 'https://www.youtube.com/embed/g0Kv05InEtI?si=7EnfmOzAOOi-3ds2',
      head: '',
      subHead: '使用RTLS技术收集人员信息',
      desc: 'ORBRO使用高精度的实时位置跟踪系统（RTLS）追踪人员的位置和移动。这在特别是在大型公共场所或紧急情况下，确保人们的安全和安全性方面至关重要。',
      dot: ''
    },
    {
      head: '',
      subHead: '使用视频技术收集车辆信息',
      desc: '利用各种视频捕捉技术实时监控和分析城市内车辆的移动。这有助于优化交通流量并预防交通事故。',
      dot: ''
    },
    {
      head: '',
      subHead: '3D实时表示',
      desc: '通过3D建模可视化人和车辆的动态移动，为城市规划、交通管理、安全增强等提供必要的数据。',
    },
    {
      head: '',
      subHead: '区域指定',
      desc: '通过设置区域，有效管理和分析特定区域内人员和车辆的移动。这对于公共安全、活动管理和交通控制非常有用。',
    },
    {
      head: '',
      subHead: '有效的仪表板',
      desc: '通过汇总人员和车辆移动数据，提供交通管理、城市规划、公共安全等方面所需的见解丰富的仪表板。这支持决策者更有效地进行城市运营和管理。',
    },
    {
      head: '',
      subHead: '智能城市集成',
      desc: '将位置数据和分析集成到智能城市系统中，以提高城市运营的自动化和效率。这有助于优化能源管理、交通系统和公共服务。',
    },

  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '通过将UWB终端附加到工人或设备上，协助实时位置追踪的产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      }, 
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]


  }

}
