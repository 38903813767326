export default {

    

    enterprise : 'Enterprise',
    supportTitle : 'Support',
    start : 'Start',

    support :{

        technology : 'Technology',
        blog : 'Blog',
        request : 'Contact',
        company : 'Company'

    }


}