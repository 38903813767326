import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4';

export default {
    desc: 'The AI camera positioning tracking solution utilizes artificial intelligence technology to analyze and process video data captured by cameras, tracking the real-time positions of people and vehicles.',
    keywords: 'Smart parking, AI Camera, Artificial Intelligence Camera, Digital Twin, Digital Transformation, digital twin, orbro, beacon, rtls, uwb, ble, ai camera, orbro, contech',

    ogSite: 'AI Camera Location Tracking Solution | Digital Twin Platform, Orbro',
    ogUrl: 'https://orbro.io/en/blog/ai-camera',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkc4tGW9Sjxkfqer33MMng2ket3NNLJzV7Iz2LLirs_1Vbk4Lhm63aO_z8WWd2dIov4g4YuW4_LF8fiQn1v8CAQMxvfMbY58snCZaz5-tmYX7MljahebCQULlqUN3wwe4XqTP-9XdR2jE5QpHWOWD2ddnpV3jfnK3qIltSnKR3JEpPdmrECN49rXQbSa7jNtNAFPC71gTTRBtV-koIsqQYpX0un1kLVJJZxwlqQNH5_tGeA9HHAmQRhtGxmF0IkPCicaA2wexfmrwboGdPR9_tkKklNF54F6EulnMKypq_qaskVAEZvDlYArvBFhnatgUVy0Uh4yo-mw9wek4fNrsUOfIb2QjUoGzYVul27F9GFN5eXULvD3zPSTOeZHF_Fy7_VBfp_A0ugMGL_EuF2olRd-eBnVJt3xGOvMOIfgcA6AfXHOmhu7sbHo1yBoJ6cyTvJQzLMafMxmStxH5CKMzD_3FKEw0pJ25EuuAy7I5e8Pp_LxdZg_GjN8cj5qXbhIpnxCfSmd5KOol3tgfS1jCnXsAbDJ_XSVp4EBn_zgXhTswmP02m9R2XxafKdWMa-ZhZE5l4o_T_ZBOoWKwRfJXJtMcTJspT2tOfode7mVpQFhU5pa6KtAqcJtVuS1i2r4rjQDEI92DQCh0CDmnkM041jtKKL9oFXlO0GQLOX0j_Z7O1R3qZ0awsO_O7gBkBGK0CJ0PlT2_JW3eGFtPu5zROSwOKF-7rcJMH1f6R29Nf1bhejjB2zRRyISB5J6Psjf7wk3LffQaSUsmJy1Wl_J11fQUBTzMAl-mUnwqquWh2eEijlvT-y4Ixu8zeRJSM7Fk61_nWCu6F48eu4Ge_4geMR2DOjfQOnLqKKfSWY_XdzxDDeACmf6PTAgeWFDzx7MZJ6vN7yp75G8Ez9xTKMQGpcfn4qwI8oA2W3qx_NtVJ6AFT0dD7vLS87ML4D7eHI8uB2pjBlqb6v4rbxp41pQGAQ_VuldNTZNUZH8q2ddGnDJ1PVh_eKBB7uoGEPUO2Lbvws6VRw93FJDgIzqXUkPwDHMVOhhjsFBWBcBQtOh2fbVWyT1k9_jRFfeo2zJ3hPWat4kTns5Ew671LTXXGJwXGHGQB0bqGCqwhwBJGjwlPS7NFxhhDYocq8P-a1r6dyKzlEmh18hbWIc-Rz7z8h0_Fv-a4EeOmoNwTQMQl1QaWugyzqkqs1wUDKtJb7dHQBcMSkzJe-fylN3_peFQAyizDmhXmlZmjYqOOCHEXHuEyxbL8ILOK_8KxHyahhYwMSk13KrKVMR0ThHJkCcsfyA-hS9jzo4Ww8q3ARcr0R7Yb-361rI8aluKtbzPbGZHWwOQCMnDv8itfAOTiVU8l3vEfE6TPQUytUs03-_CGTmMrgpoxFxRDPkJFNwEa0671QbXV5AwJNTviSSe9q27H-I59Id0McwUZGQBn0z8XOjoJyCBZ27zRD9tSbBg-B1s1crfHk5RU8bIvgfwCsov_1oVufbxVy223DtdDzlernhw9DYzq5IUYKPoL-lZXZiS4er28lhBzPfXz4feDNHNSzB0cP_-4p-UlNtXaQtKzLGIu3WX-VqHW-=w2059-h1270',
    ogType: 'website',

    title: 'AI Camera Location Tracking Solution',
    date: '2024-02-07',
    tag1: '#AICamera',
    tag2: '#LocationTracking',
    tag3: '#VehicleTracking',
    tag4: '#DigitalTwin',

    text: [
        {
            head: 'What is an AI Camera?',
            subHead: '',
            desc: 'An AI camera location tracking solution utilizes artificial intelligence technology to analyze and process video data received from cameras, enabling real-time tracking of the positions of people and vehicles. This can be utilized in various fields such as security, traffic management, video monitoring, etc. In other words, unlike conventional cameras, it analyzes and processes video data to operate more intelligently.',
            dot: ''
        },
        {
            head: "Key Features of AI Camera",
            subHead: "Real-time Location Tracking",
            desc: "AI cameras rapidly and accurately track the movements of people and vehicles in real-time, identifying and recording their current locations. This enables security systems to promptly detect potential threats and traffic management systems to monitor traffic flow in real-time and take appropriate actions.",
        },
        {
            head: "",
            subHead: "Video Analysis and Recognition",
            desc: "AI cameras can automatically identify and classify various objects in videos through video analysis and object recognition. This enables security systems to quickly detect intruders or suspicious behavior and traffic management systems to accurately analyze traffic situations to prevent problems and efficiently manage them.",
        },
        {
            head: "",
            subHead: "Support for Multiple Cameras",
            desc: "AI cameras can integrate multiple cameras to monitor large areas simultaneously. This allows for broader coverage of areas and effective collection of information from various angles. Additionally, by utilizing the support for multiple cameras, more robust solutions can be provided for security and safety management in various environments such as intersections or large buildings.",
        },
        {
            head: "",
            subHead: "Customizable Features",
            desc: "AI cameras allow users to add and configure desired features according to their needs. Customizable features can be flexibly adjusted to various environments, providing accurate and effective monitoring according to specific requirements. Furthermore, customizable features enable optimization and upgrading of AI camera performance.",
        },
        {
            video : video,
            link : '/en/enterprise/construction/equipment',
            head: "AI Camera Technology",
            subHead: "Video Processing and Analysis",
            desc: "AI cameras analyze video data captured by the camera quickly and accurately in real-time. This involves the use of state-of-the-art artificial intelligence algorithms including object detection, tracking, classification, and more. With these technologies, AI cameras can rapidly process video data, accurately identify and extract objects such as people and vehicles.",
        },
        {
            head: "",
            subHead: "Object Detection",
            desc: "AI cameras rapidly detect objects such as people and vehicles in video footage. By utilizing advanced deep learning technology, objects can be accurately identified and extracted. This enables AI cameras to swiftly detect objects in various environments, allowing for additional analysis or actions to be taken based on these detections.",
        },
        {
            head: "",
            subHead: "Tracking and Positioning",
            desc: "Real-time tracking of detected objects' movements to determine their positions. This enables AI cameras to continuously monitor the movements of people and vehicles and respond promptly when necessary. Additionally, analyzing the tracked objects' movement paths can provide additional insights.",
        },
        {
            head: "",
            subHead: "Data Analysis and Storage",
            desc: "Tracked data is analyzed and stored. This enables AI cameras to analyze the movement patterns or behaviors of tracked objects. The data can be stored in a database for later review or used to generate reports. This allows security systems to analyze the causes and progression of events to respond more effectively.",
        },
        {
            head: "Applications",
            subHead: "Security Systems",
            desc: "AI cameras can enhance security by tracking the movements of people and vehicles in locations such as buildings or parking lots in real-time. Furthermore, analyzing this data can provide more accurate risk predictions and responses.",
        },
        {
            head: "",
            subHead: "Traffic Management",
            desc: "AI cameras can monitor and manage traffic situations in facilities such as roads or intersections in real-time. This can help prevent traffic congestion or accidents in advance and optimize traffic flow.",
        },
        {
            head: "",
            subHead: "Commercial Use",
            desc: "In commercial facilities such as stores or shopping malls, AI cameras can track customer movement paths and behaviors to optimize store layouts or develop marketing strategies. Additionally, this data can be used to analyze and improve customer preferences.",
        },
        {
            head: "",
            subHead: "Smart Cities",
            desc: "By installing AI cameras in various locations within a city, city planners can understand citizen movement patterns and efficiently manage urban infrastructure. This can enhance city safety, convenience, and contribute to building smart cities.",
        },
        {
            head: 'Conclusion',
            subHead: '',
            desc: 'The camera location tracking solution utilizing AI is an efficient technology that tracks the positions of people and vehicles in real-time, applicable in various fields. It confirms its potential for use in security, traffic management, commercial use, and more, providing better safety and efficiency.',
            dot: ''
        },
        {
            head: '',
            subHead: '',
            desc: '',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'This product aids in real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/en/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/en/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/en/enterprise/construction/equipment'
            }
        ]


    }


}
