import { Vertical } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";
import { CONTENT_SECONDARY } from "@assets/styled/color.style";

export const StyledVisual = styled.div<{img : string}>`
  width: 100%;
  background-size: auto 1100px;
  background-repeat: no-repeat;
  background-position: 50% 0;

  ${({img})=> img && `background-image : url(${img});`}

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    background-size: auto 1000px;
  }
`;

// Main - Start
export const StyledVisualMain = styled(StyledGridRow)`
  height: 1100px;
  padding-bottom: 136px;
  background-size: auto 1100px;
  background-repeat: no-repeat;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height: 1000px;
  }
`;

export const StyledVisualMainInfo = styled(Vertical)`
  margin-top: auto;
`;

export const StyledVisualMainHeading = styled(StyledHeading)`

`;

export const StyledVisualMainDisplay = styled(StyledDisplay)`
  margin-top: 16px;
`;

export const StyledVisualMainDescription = styled(StyledParagraph)`
  margin-top: 24px;
`;
// Main - End

// Items - Start
export const StyledVisualItems = styled(StyledGridRow)`
  padding-bottom: 96px;
`;

export const StyledVisualItem = styled(StyledGridColumn)`
  height: 148px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    height: 144px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height: 124px;
  }
`;

export const StyledVisualItemTitle = styled(StyledDisplay)`

`;

export const StyledVisualItemLabel = styled(StyledParagraph)`
  color: ${CONTENT_SECONDARY};
  margin-top: 8px;
`
// Items - End