import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| 물류센터 재고 관리의 선진화: RTLS 기반의 솔루션',
  desc : '물류 및 재고 관리는 현대 비즈니스 환경에서 핵심적인 요소 중 하나입니다. 물류센터는 생산 및 판매 프로세스를 효율적으로 관리하는 핵심 역할을 수행하며, 그 효율성은 기업의 성공과 직결됩니다.',
  keywords : '물류재고, 물류재고관리, 재고관리, 자산관리, 물류센터재고관리, 물류센터, 대형물류센터, 스마트물류, 스마트물류센터, 물류시설, 재고추적, RTLS, 실시간 위치 추적, 오브로, ORBRO, 콩테크',

  ogTitle : '',
  ogDesc : '',
  ogSite : '물류센터 재고 관리의 선진화: RTLS 기반의 솔루션 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/logisticsAsset',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4POsGyW8_6UaAHXbyNzVpk_9ryErunfmUV3yK3f4WmvwJkZnca4uxHbMjmgtlBtU0eyUNtbcUO-7wWCY_NtuoV_D1SNBJD5pUHVTtoGqonJUgcV5H8yfvd2A-hQO4IVx6J1DyDHLtNqYbspd44GBG-yAQwFvQlLNDdW8wU-mA3M9nPJFhcC9T9aR6PgH3sfGEXLve_5lvKF5XfXDYhLprnf8H1LzwLiix2kgKA00_RTCK1bbaRJEzBh0k5nHNZ9A5nJ9BpKRw10eXYPbfzK77pY2JI_lu6ERZzceBmKgpycXSZr8VZr3kaXFkFq2gqb1IM3hj3qvIDUKszW0O3rmfXXRSBMe7vpCm9KtllBv6KIRJDxeP2k6DydbeOk6uZV--YQJevEDWeWjZtGbsyILS8c9MKtSGO5L0-YOBkYKY1GQJ8vypXh-eB6n4vVr9TqBQ2xLF5XnjbOvUchPCmvGANVC3ZIZbnAB6d9OHed1xvW4hFVEoXBfEbtU2vhm9H_APEtD754ywgCvzhpWfpeJ6eLTAQ7E0uIWdbAf5PO3724DMSARXYPKPJzu4GxlXnD7E5QyttqRcPxZe-nrOW2YOsoXfwetLvak8QSFcdP42YEdih_wYjISf43d1x5dqWCSOlgWRPLXopdeX6rOole8ZBTHy4vv0TxkDZKWaVy-7hzo73uazr9ZUY7Dg_MB7qOAkEof0_f8GYhBYNABTj76pFmcJf12m4bsEas8IcfrDLDnyaWnLprNFIktVeN5TThyXlGFDWlvYGtmwmpcKGe-MzLPASU4qspqg1wvasKCdODklzCeBaYWuUFGr2BsbSKf_WLxOPDQjofRp-YFwef83luUrcqyUrmv6_v_Ylv8eUnKGFLTUF6OAv9Bvu6z5NfThURg-F961Zq9ldOh6i-2icRVd4IbqzpQrmrkhKXDfyJSF6zGOqeWEL20TZ4aufvWDxI89w4fEch-aweyAlwgG5ti5Sph300hnuWbdoGWZALDTySeEPgnEsbXTK5ZY-xHWc_lCAmHWxJYpL8h5AHcJGLyM8-i8oKQJkfn46SIDh-NvCB6G1BOtaRqc-GioWlIoo-l5EEyMwQnCVZ_Y_4RSlwtoYlk-zmIAAaMQns5mV5NH1xoiOjQLfKBs504gW8hxqKJEXtNhbeKOSh8iWrrjST3gRVpqlRvLAhFhtdDl3UoWc1mXW4MyYCci_UVC2vXGoaQS9-LAK47__t29nN2CyayOnGXejrN_ZFm68lwCjdlehA_CHMn7-VHo-T3yEr2rQbtt44dEBwZ25mEprNmtwak6dCDB1VyU1RYyoKX1TShaZguXXbnSTTwgIRKPZWD1fdToLBGf8uoYWuF60W42K2m55DZyJLHd4UCgwTP-OqDqxNAyD-SwaTzeHSCjHEctiAo5VvypNZj0K1YX32n0Tz951ly0vRFchzoSkIGMjs4lAvmA3DSXNDsCof-3ZfB-UZKY7xZybELiWtYxoS-cG233aezqbt9-yQNxvH5IfsOZxPoGTYO0nB2VOmHGMlPRcryGTIN1fTA8dBby0gz4w=w2000-h1992',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '물류센터 재고 관리의 선진화: RTLS 기반의 솔루션',
  date : '2023-09-08',
  tag1 : '#물류재고',
  tag2 : '#재고관리',
  tag3 : '#물류센터재고관리',
  tag4 : '#RTLS',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'물류 및 재고 관리는 현대 비즈니스 환경에서 핵심적인 요소 중 하나입니다. 물류센터는 생산 및 판매 프로세스를 효율적으로 관리하는 핵심 역할을 수행하며, 그 효율성은 기업의 성공과 직결됩니다. 최신 기술과 솔루션을 활용하여 물류센터 재고 관리를 혁신하는 한 가지 방법은 RTLS(Real-Time Location System) 기반의 솔루션을 도입하는 것입니다. 이 글에서는 RTLS가 어떻게 물류센터의 재고 관리를 선진화할 수 있는지 살펴보겠습니다.',
      dot : ''
      
    },
    {
      head : 'RTLS 소개',
      subHead :'',
      desc : 'RTLS는 물체나 개체의 실시간 위치를 추적하는 기술로, 물류센터에서는 재고, 장비, 작업자 및 운송 수단과 같은 다양한 자산의 위치를 정확하게 파악할 수 있도록 도와줍니다. RTLS 시스템은 주로 RFID(라디오 주파수 식별), UWB(초광대역), Wi-Fi, 블루투스 및 GPS와 같은 다양한 기술을 사용하여 위치 데이터를 수집하고 분석합니다.',
      dot : ''
      
    },
    {
      head : 'RTLS의 재고 관리 혁신',
      subHead :'실시간 재고 추적',
      desc : 'RTLS를 통해 모든 재고 항목의 실시간 위치를 파악할 수 있습니다. 이것은 재고의 이동과 변경을 실시간으로 모니터링하고 예측하는 데 도움이 됩니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'자동화된 인벤토리 관리',
      desc : 'RTLS 시스템은 자동으로 재고 수량을 추적하고 관리할 수 있습니다. 재고 부족 또는 과잉 구매와 같은 문제를 사전에 예방하고 비용을 절감할 수 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'고객 서비스 향상',
      desc : 'RTLS를 사용하여 주문 및 배송 과정을 최적화할 수 있으며, 주문 처리 시간을 단축하고 정확도를 향상시킵니다. 이로 인해 고객 서비스 품질이 향상됩니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'유연성과 확장성',
      desc : 'RTLS 시스템은 물류센터의 크기나 구조에 따라 쉽게 확장할 수 있습니다. 필요에 따라 추가 센서를 설치하거나 소프트웨어 업데이트를 통해 시스템을 조정할 수 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'분석과 예측',
      desc : 'RTLS 데이터는 재고 및 물류 작업에 대한 가치 있는 통찰력을 제공합니다. 데이터 분석을 통해 물류 프로세스를 최적화하고 미래의 트렌드를 예측할 수 있습니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'안전 및 보안 향상',
      desc : 'RTLS는 물류센터 내에서의 안전 및 보안 관리에도 도움이 됩니다. 장비와 재고의 위치를 실시간으로 파악하여 사고나 도난을 예방하거나 대응할 수 있습니다.',
      dot : ''
    },
    {
      head : '성공 사례',
      subHead :'',
      desc : 'RTLS를 도입한 기업들은 이미 물류 성능과 효율성을 크게 향상시키고 있습니다. Amazon과 같은 대규모 물류센터는 RTLS를 사용하여 주문 처리 시간을 단축하고 재고 정확도를 높이며, 전체적인 고객 만족도를 높이고 있습니다. 또한 의료 기관은 RTLS를 사용하여 의료 장비와 환자를 추적하고 안전성을 향상시키고 있습니다.',
      dot : ''
    },
    {
      head : '결론',
      subHead :'',
      desc : '물류센터 재고 관리의 선진화는 현대 비즈니스에서 경쟁력을 유지하고 성장하기 위한 필수적인 단계입니다. RTLS 기반의 솔루션은 실시간 위치 데이터를 통해 물류 프로세스를 최적화하고 물류센터의 효율성을 향상시키는 강력한 도구입니다. RTLS를 도입함으로써 기업은 비용을 절감하고 고객 서비스 품질을 향상시키며 더 스마트하고 효율적인 물류 관리를 실현할 수 있습니다. 따라서 물류센터 운영에 혁신을 가져오고자 하는 기업은 RTLS 기술을 주목해야 합니다.',
      dot : ''
    },

  ],

  link : {
    page : 'rtls',
    numb : '1',
    product : [
        {
          text : productPurchase.purchase.asset.assetTracking.title,
          title : productPurchase.purchase.asset.assetTracking.title,
          serial : productPurchase.purchase.asset.assetTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.assetTracking.key1
        },
      {

      }

    ],
    solution : [
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      },
      {
        img : logisticsSafety.thumb,
        data : 'logisticsSafety',
        link : '/enterprise/logistics/safety'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/enterprise/manufacturing/asset'
      }
    ]

  }


}