export default {

    mobile: {
        title: 'Mobile Access System | Orbro Products',
        key: 'Automatic access, entrance door, apartment access, access system, smart access, mobile access, RTLS, contactless access, beacon, Bluetooth, KongTech, Orbro',
        desc: `Orbro's mobile access system allows you to control entrance doors via mobile devices without the need for power installation. It is a next-generation access system that is fast, convenient, and cost-effective.`,
        img: '',
        url: 'https://orbro.io/en/access/mobile',
    },
    auto: {
        title: 'Automatic Door Access System | Orbro Products',
        key: 'Automatic door, automatic access, entrance door, apartment access, access system, smart access, mobile access, RTLS, beacon, Bluetooth, contactless access, KongTech, Orbro',
        desc: `Orbro's automatic door access system is designed for automatic door access and allows contactless entry through mobile app installation. It is a sleek and smart product designed for convenience.`,
        img: '',
        url: 'https://orbro.io/en/access/auto',
    },
    face: {
        title: 'Face Recognition System | Orbro Products',
        key: 'Facial recognition, facial authentication, face recognition, automatic access, entrance door, apartment access, access system, smart access, beacon, Bluetooth, smart access system, mobile access, RTLS, contactless access, KongTech, Orbro',
        desc: `Access Pro controls and calls users at the building's communal entrance. With this product, real-time video calls via mobile app are possible, and remote control of the entrance door is available. It can also replace traditional communal entrance control systems, offering a high-quality solution with NFC card and password unlocking options.`,
        img: '',
        url: 'https://orbro.io/en/access/face',
    },
    accessory: {
        title: 'Accessories | Orbro Products',
        key: 'Beacon, Bluetooth, facial recognition, facial authentication, face recognition, automatic access, entrance door, apartment access, access system, smart access, smart access system, mobile access, RTLS, contactless access, KongTech, Orbro',
        desc: 'Orbro offers efficient and economical smart access products. With various accessories, you can create a customized access system.',
        img: '',
        url: 'https://orbro.io/en/access/accessory',
    },
    ble: {
        title: 'Bluetooth(BLE) RTLS | Orbro Products',
        key: 'Location tracking, real-time location tracking, RTLS, Bluetooth, beacon, BLE, UWB, asset management, asset tracking, inventory management, inventory tracking, people tracking, indoor location tracking, indoor GPS, GPS, ORBRO, KongTech',
        desc: `Orbro's BLE-based location tracking products allow you to track the location of assets or subjects by attaching BLE tag devices. The system is based on a stable and efficient technology, providing accurate data.`,
        img: '',
        url: 'https://orbro.io/en/rtls/ble',
    },
    uwb: {
        title: 'UWB Precision RTLS | Orbro Products',
        key: 'Location tracking, real-time location tracking, RTLS, Bluetooth, beacon, BLE, UWB, asset management, asset tracking, inventory management, inventory tracking, people tracking, indoor location tracking, indoor GPS, GPS, ORBRO, KongTech',
        desc: `Orbro's UWB-based high-precision location tracking technology allows real-time tracking of the location of assets or people with great accuracy and stability. It offers exceptional performance in terms of stability, efficiency, and cost-effectiveness.`,
        img: '',
        url: 'https://orbro.io/en/rtls/uwb',
    },
    outdoor: {
        title: 'Outdoor Cameras | Orbro Products',
        key: 'Camera location tracking, PTZ, real-time location tracking, vehicle location tracking, smart parking, RTLS, Bluetooth, beacon, BLE, UWB, asset management, asset tracking, inventory management, inventory tracking, people tracking, indoor location tracking, indoor GPS, GPS, ORBRO, KongTech',
        desc: `With cameras, you can detect and manage the location and behavior of various objects, including people and vehicles. It provides real-time and accurate data on vehicle locations and people's movements.`,
        img: '',
        url: 'https://orbro.io/en/camera/outdoor',
    },
    indoor: {
        title: 'Indoor Cameras | Orbro Products',
        key: 'Camera location tracking, IP, real-time location tracking, vehicle location tracking, smart parking, RTLS, Bluetooth, beacon, BLE, UWB, asset management, asset tracking, inventory management, inventory tracking, people tracking, indoor location tracking, indoor GPS, GPS, ORBRO, KongTech',
        desc: 'Based on highly precise location tracking technology, indoor cameras can track the movement of people and inventory indoors, providing accurate data.',
        img: '',
        url: 'https://orbro.io/en/camera/indoor',
    },
    bell: {
        title: 'Emergency Bells | Orbro Products',
        key: `IoT, Internet of Things, safety bell, emergency call button, emergency call, call button, police call, emergency situation, safety bell, 119, 112, women's safety, beacon, Bluetooth, anti-stalking, anti-stalking report, KongTech, Orbro`,
        desc: 'The safety bell allows emergency calls in critical situations. It can be attached anywhere, offering the most efficient solution for quick emergency reporting.',
        img: '',
        url: 'https://orbro.io/en/iot/bell',
    },
    energy: {
        title: 'Power Control | Orbro Products',
        key: 'Power control, smart power, smart switch, smart button, digital switch, switch, Bluetooth, remote control switch, smart bulb, KongTech, Orbro',
        desc: 'Smart Switch allows easy replacement of analog switches with digital switches, reducing wasted power and minimizing unnecessary time consumption.',
        img: '',
        url: 'https://orbro.io/en/iot/energy',
    },
    display: {
        title: 'Displays | Orbro Products',
        key: 'Power control, smart power, smart switch, smart button, digital switch, switch, Bluetooth, remote control switch, smart bulb, KongTech, Orbro',
        desc: 'ESL is a display device based on beacon technology. It uses E-ink Display, offering energy-efficient display capabilities.',
        img: '',
        url: 'https://orbro.io/en/iot/display',
    },
    cradle: {
        title: 'Wireless Cradles | Orbro Products',
        key: `IoT, Internet of Things, women's safety, KongTech, Orbro`,
        desc: 'Manage your smartphone wirelessly without the inconvenience of cable locks.',
        img: '',
        url: 'https://orbro.io/en/iot/cradle',
    },
    idCard: {
        title: 'Employee ID Card | Orbro Product',
        key: `employee ID, smart employee ID, IoT, Internet of Things, women's safety, contactless technology, Orbro`,
        desc: 'The smart employee ID card is designed in a necklace form and can be used with high compatibility in both industrial and service areas. It can also be integrated with other Orbro services.',
        img: '',
        url: 'https://orbro.io/en/iot/id',
    },

    loraGateway: {
        title: 'LoRa Gateway | ORBRO Products',
        key: 'LoRa Gateway, LPWAN Gateway Device, Long Range Wireless Equipment, IoT Communication Solution, Wireless Sensor Network Device, LoRaWAN Gateway Device, Low-Power IoT Communication, Smart City IoT Solution, Wireless IoT Device Sales, Wireless Sensor Network Solution, Purchase IoT Equipment, Wireless Communication System, Wireless LoRa Gateway, Wireless IoT Device Manufacturer, LoRa Gateway Price, LPWAN IoT Equipment, IoT Communication Solution Provider, Sensor Network System, Smart City Communication Equipment, Low-Power Wireless Network, ORBRO, Kongtech, orbro, rtls, Real-time Location Tracking',
        desc: 'LoRa Gateway products are based on long-range communication technology and play a vital role in wireless communication in the field of IoT (Internet of Things). These gateways provide low-power solutions and are used in various fields such as smart cities, agriculture, environmental monitoring, industrial automation, and more. Our LoRa Gateways meet customer demands through stable and efficient data transmission, supporting the success of IoT projects.',
        img: '',
        url: 'https://orbro.io/en/sensor/LoRaGateway'
    },
    loraSensor: {
        title: 'LoRa Sensor | ORBRO Products',
        key: 'LoRa Sensor, LPWAN IoT Sensor, Long Range Wireless Sensor, IoT Sensor Network, Wireless Environmental Detection Sensor, Low-Power IoT Devices, Smart City Sensor Solution, Wireless Environmental Monitoring, IoT Sensor Devices, Wireless Environmental Monitoring Equipment, Environmental Sensor Network, Wireless LoRa Sensor Technology, Environmental Monitoring System, IoT Environmental Sensor Sales, LoRa Sensor Network, LPWAN IoT Technology, Smart City Environmental Monitoring, Wireless Environmental Sensor Manufacturer, LoRa Sensor Price, LPWAN IoT Environmental Sensor, IoT Sensor Solution Provider, ORBRO, Kongtech, orbro, rtls, Real-time Location Tracking',
        desc: 'LoRa Sensors are ideal solutions for environmental monitoring and real-time data collection, using low-power wireless technology. Our LoRa sensor products are essential for IoT (Internet of Things) projects, monitoring various parameters such as air quality, temperature, humidity, gas levels, and more. These sensors are critical in smart cities, agriculture, industrial automation, and environmental monitoring, enhancing data collection efficiency with reliability and accuracy.',
        img: '',
        url: 'https://orbro.io/en/sensor/LoRaSensor'
    },
    airQuality: {
        title: 'Air Quality Monitor | ORBRO Products',
        key: 'Industrial Air Quality Monitor, Atmospheric Quality Measurement Device, Factory Air Pollution Monitor, Air Quality Monitoring Equipment, Environmental Monitoring Solution, Air Quality Analysis Device, Industrial Air Pollution Sensor, Air Quality Monitoring Equipment, Industrial Air Quality Measurement Solution, Atmospheric Quality Monitoring System, Industrial Environmental Monitoring Equipment, Air Quality Sensor Network, Environmental Monitoring Solution Provider, Industrial Air Quality Sensor Sales, Air Quality Monitoring Device, Industrial Environmental Sensor Technology, Air Quality Measurement Equipment Price, Factory Environmental Monitoring System, Industrial Air Quality Sensor Manufacturer, Environmental Air Quality Monitoring Solution Provider, ORBRO, Kongtech, orbro, rtls, Real-time Location Tracking',
        desc: 'Air Quality Monitors are essential tools for accurately monitoring and improving air quality in indoor and outdoor environments. These monitors measure various air quality indicators such as fine dust, CO2, temperature, humidity, and more in real-time. They are used in smart cities, building management, agriculture, industry, and environmental protection, providing a necessary means to monitor and control atmospheric carbon levels for a safe and healthy environment.',
        img: '',
        url: 'https://orbro.io/en/sensor/air'
    },
    coMeasure: {
        title: 'Carbon Monoxide Detector | ORBRO Products',
        key: 'Carbon Monoxide Detector, CO Gas Sensor, Carbon Monoxide Concentration Measurement Device, CO Monitor Sales, Environmental CO Monitoring Equipment, Carbon Monoxide Detection Device, CO Sensor Price, Gas Concentration Measurement Device, Carbon Monoxide Sensor, CO Concentration Monitoring System, Carbon Monoxide Measurement Solution, CO Monitoring Equipment Provider, Environmental Gas Sensor Technology, CO Gas Sensor Manufacturer, Industrial CO Monitoring, Carbon Monoxide Level Measurement, CO Measurement Device Installation, Carbon Monoxide Detection Device Solution, Environmental CO Monitoring System Price, CO Monitor Level Adjustment, ORBRO, Kongtech, orbro, rtls, Real-time Location Tracking',
        desc: 'Carbon Monoxide Detectors are important tools for environmental and safety purposes, rapidly detecting and monitoring CO (carbon monoxide) levels in indoor and outdoor environments. These detectors are used in various locations, such as living environments, factories, garages, and more, contributing to the detection of toxic gas leaks and providing a safe environment. Monitor atmospheric carbon monoxide levels for safety and health with these essential CO detectors.',
        img: '',
        url: 'https://orbro.io/en/sensor/co'
    },
    beaconIndustry: {
        title: 'Industrial Beacon | ORBRO Products',
        key: 'Industrial Beacon, Beacon Tracking System, Beacon Location Tracking, Industrial Beacon Solution, Wireless Beacon Technology, Beacon Location Services, Beacon Communication Equipment, Beacon Tracker Sales, Smart Factory Beacon, Industrial Beacon Price, Beacon Indoor Location Services, Wireless Location Tracking System, Beacon Location-Based Services, Beacon Real-time Location Tracking, Industrial Beacon Manufacturer, Beacon Indoor Navigation, Beacon IoT Devices, Wireless Beacon Location System, Beacon Network Solution, Industrial Beacon Technology Platform',
        desc: 'Our Industrial Beacons provide innovative location tracking and wireless IoT solutions in the field of smart factories and industrial automation. These beacons use advanced Bluetooth technology and are used in various applications, including logistics, asset management, production process optimization, and more. By emphasizing safety and reliability, Industrial Beacons enhance production efficiency and support smooth factory operations. They are essential devices for building innovative IoT solutions for real-time location tracking and data collection.',
        img: '',
        url: 'https://orbro.io/en/beacon/industry'
    },
    beaconTag: {
        title: 'Small Beacon | ORBRO Products',
        key: 'Beacon, Small Beacon, Beacon Tag Tracking, Beacon Tag System, Indoor Location Tracking Technology, Bluetooth Tag Beacon, Real-time Location-Based Services, Beacon Tag Devices, Location Tag Tracker Sales, Bluetooth Beacon Tags, Tag Beacon Price, Bluetooth Indoor Location Services, Location-Based Tag System, Beacon Tag Real-time Tracking, Tag Beacon IoT Solution, Indoor Location Tracking Manufacturer, Bluetooth Tag IoT Devices, Tag Beacon Wireless Location System, Location-Based Tag Network Solution, Tag Beacon Technology Platform, Beacon Tag App Development.',
        desc: 'Small Beacons use Bluetooth-based location tracking technology to accurately identify and monitor the location of objects or entities indoors and outdoors. Our Tag Beacons play a crucial role in logistics, asset management, indoor navigation, smart spaces, and smart city projects. With an emphasis on stability and accuracy, Tag Beacons enable real-time location-based services and improve data collection efficiency.',
        img: '',
        url: 'https://orbro.io/en/beacon/tag'
    },
    assetTag: {
        title: 'Asset Tag | Orbro Beacon Product',
        key: 'asset management, beacon, compact beacon, beacon tag tracking, beacon tag system, indoor positioning technology, Bluetooth tag beacon, real-time location-based services, beacon tag device, location tag tracker sales, Bluetooth beacon tag, Bluetooth indoor location services, location-based tag system, beacon tag real-time tracking, tag beacon IoT solutions, indoor location tracking manufacturer, Bluetooth tag IoT devices, tag beacon wireless location system, location-based tag network solutions, tag beacon technology platform, beacon tag app development',
        desc: 'The asset tag is an ultra-compact Bluetooth tag device optimized for asset management. It is equipped with the latest version of Bluetooth and a battery life of up to one year.',
        img: '',
        url: 'https://orbro.io/en/beacon/asset-tag'
    },
    basicRobot: {
        title: 'Robot System | Orbro Beacon Products',
        key: 'Robot System, Asset Management, Beacon, Small Beacon, Beacon Tag Tracking, Beacon Tag System, Indoor Positioning Technology, Bluetooth Tag Beacon, Real-Time Location-Based Service, Beacon Tag Device, Location Tag Tracker Sales, Bluetooth Beacon Tag, Tag Beacon Price, Bluetooth Indoor Location Service, Location-Based Tag System, Beacon Tag Real-Time Tracking, Tag Beacon IoT Solution, Indoor Positioning Manufacturer, Bluetooth Tag IoT Device, Tag Beacon Wireless Location System, Location-Based Tag Network Solution, Tag Beacon Technology Platform, Beacon Tag App Development.',
        desc: 'Combining a real-time positioning system with a robot delivery system, we provide optimized robot services for delivering goods or performing tasks.',
        img: '',
        url: 'https://orbro.io/en/integration/robot'
    },

}
