import { StyledGridColumn } from "@components/Grid/Grid.style";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { StyledFaq, StyledProductContainer } from "./Spec.style";
import SpecItem from "./Item";
import SpecItemMulti from './ItemMulti';
import productSpec from "@src/lang/ko/main/product/productSpec";
import { useLocation } from "react-router-dom";
import i18n from "@pages/i18n";
import BlogNewLetter from "../NewsLetter/NewsLetter";
import { Spacing } from "@pages/CommonStyle/common.style";
import { StyledDisplay, StyledHeading } from "@components/Text";
import { StyledFaqGridRow } from "../Faq/Faq.style";
import ItemOpen from "./ItemOpen";


interface SpecProps {
  productName: string;
}


const Spec: React.FC<SpecProps> = ({ productName }) => {

  let multi = "0";
  let list, path, specPath;

  //Multiple Spec
  if (productSpec.product[productName].title === "multi") {
    list = productSpec?.product[productName].list, [productName];
    multi = "1";
  }
  //Single Spec
  else {
    path = productSpec.product[productName], [productName];
    specPath = productSpec.product[productName].spec, [productName];
  }



  useEffect(() => {
    setIsOpenIndexes([]);
  }, [productName]);

  const [isOpenIndexes, setIsOpenIndexes] = useState<{ [groupIndex: number]: number[] }>({});

  const handleClickItem = (groupIndex: number, itemIndex: number) => {
    setIsOpenIndexes((prev) => {
      const isOpen = prev[groupIndex]?.includes(itemIndex);
      const updatedIndexes = isOpen
        ? prev[groupIndex]?.filter((i) => i !== itemIndex)
        : [...(prev[groupIndex] ?? []), itemIndex];

      return { ...prev, [groupIndex]: updatedIndexes };
    });
  };


  const location = useLocation();
  const isEnglishPage = location.pathname.includes('/en');
  const isJapanPage = location.pathname.includes('/jp');
  const isChinaPage = location.pathname.includes('/cn');
  const currentLang = i18n.language;

  const [specText, setSpecText] = useState<string>("제품 사양");

  useEffect(() => {

    if (isEnglishPage) {
      setSpecText('specifications');
    } else if (isJapanPage) {
      setSpecText('製品仕様');
    } else if (isChinaPage) {
      setSpecText('产品规格');
    } else {
      setSpecText('제품 사양');
    }
  }, [location.pathname]);

  return (
    <StyledFaq>
      <StyledProductContainer>

        {/* 스펙이 여러개인 경우 */}
        {multi === "1" &&
          list.map((item, index) => (
            <>
              <Spacing pixel="64" />
              <StyledHeading size="xxxxsmall">
                <Trans i18nKey={specText} />
              </StyledHeading>
              <Spacing pixel="4" />
              <StyledDisplay size="small">
                <Trans i18nKey={productSpec.product[item.text].title} />
              </StyledDisplay>

              <StyledFaqGridRow >
                <StyledGridColumn desktop={12} tablet={8} mobile={4}>
                  {productSpec.product[item.text].spec.length !== 1 ? (
                    productSpec.product[item.text].spec.map((specItem, i) => (
                      <SpecItemMulti
                        key={`faq-item-${i}`}
                        title={`productSpec.product.${item.text}.spec.${i}.name`}
                        description={specItem.detail}
                        path={productSpec.product[productName].list[index]?.text}
                        i={i}
                        isOpen={isOpenIndexes[index]?.includes(i)}
                        handleClick={() => {
                          handleClickItem(index, i);
                        }}
                      />
                    ))
                  ) : (
                    productSpec.product[item.text].spec.map((specItem, i) => (
                      <ItemOpen
                        key={`faq-item-${i}`}
                        title={`productSpec.product.${item.text}.spec.${i}.name`}
                        description={specItem.detail}
                        path={productSpec.product[productName].list[index]?.text}
                        i={i}
                        handleClick={() => {
                          handleClickItem(index, i);
                        }}
                      />
                    ))
                  )}
                </StyledGridColumn>
              </StyledFaqGridRow>
            </>
          ))
        }

        {multi === "0" && <>
          <Spacing pixel="64" />
          <StyledHeading size="xxxxsmall">
            <Trans i18nKey={specText} />
          </StyledHeading>
          <Spacing pixel="4" />
          <StyledDisplay size="small">
            <Trans i18nKey={`productSpec.product.${productName}.title`} />
          </StyledDisplay>

          <StyledFaqGridRow >
            <StyledGridColumn desktop={12} tablet={8} mobile={4}>
              {specPath.map((item, i) => {
                return (
                  <SpecItem
                    key={`faq-item-${i}`}
                    title={`productSpec.product.${productName}.spec.${i}.name`}
                    description={item.detail}
                    path={`productSpec.product.${productName}.spec.${i}`}
                    isOpen={isOpenIndexes[i]?.includes(i)}
                    handleClick={() => {
                      handleClickItem(i, i);
                    }}
                  />
                )
              })}
            </StyledGridColumn>
          </StyledFaqGridRow>
        </>}

      </StyledProductContainer>
      <Spacing pixel="128" />
      <BlogNewLetter onSuccess={undefined} />
    </StyledFaq>
  )
};

export default Spec;