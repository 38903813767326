import React, { useEffect, useState } from 'react';

import './Footer.styl';
import {
  StyledFooter,
  StyledFooterBottom,
  StyledFooterATag,
  StyledFooterBottomParagraph,
  StyledFooterBottomRow,
  StyledFooterHead,
  StyledFooterSub,
  StyledFooterSubTitle,
  StyledFooterLogo,
  StyledFooterHeadColumn,
  StyledFooterBottomTitle,
  StyledFooterHeadColumn40gap,
  StyledFooterCombineSub,
  StyledFooterHeadColumn37,
  FooterLabel,
} from './Footer.style';
import { StyledGridColumn, StyledGridContainer } from '@components/Grid/Grid.style';
import { logoBlack, logoWhite } from '@assets/images/logo';

import { StyledLabel } from '@components/Text';

import { Trans } from 'react-i18next';
import { Vertical } from '@assets/styled/flex';
import { useDispatch, useSelector } from 'react-redux';
import { CommonState } from '@redux/reducers/commonReducer';
import { useLocation } from 'react-router-dom';
import { setSelectedValue } from '@redux/action-creators/common';
import navMenu from '@src/lang/ko/navMenu';
import navMenuEng from '@src/lang/en/navMenu';
import navMenuJP from '@src/lang/jp/navMenu';
import navMenuCN from '@src/lang/cn/navMenu';
import { AtagColor, LabelColor, Spacing } from '@pages/CommonStyle/common.style';

const FooterCombine = () => {


  const location = useLocation();

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const dispatch = useDispatch(); // 추가

  const handleIconClick = (value) => {
    dispatch(setSelectedValue(value)); // 추가: Redux 액션 디스패치
  };

  const [footerPath, setFooterPath] = useState<any>(navMenu);

  const isEnglishPage = location.pathname.includes('/en/');
  const isJapanPage = location.pathname.includes('/jp/');
  const isChinaPage = location.pathname.includes('/cn/');

  const [darkPage, setDark] = useState<boolean>(false);

  useEffect(() => {
    if (isEnglishPage) {
      setFooterPath(navMenuEng);
    } else if (isJapanPage) {
      setFooterPath(navMenuJP);
    } else if (isChinaPage) {
      setFooterPath(navMenuCN);
    } else {
      setFooterPath(navMenu);
    }

    if (location.pathname.includes('digital-twin') && !location.pathname.includes('blog') && !location.pathname.includes('technology')) {
      setDark(true);
    } else {
      setDark(false);
    }
  }, [location.pathname]);

  return (
    <StyledFooter dark={darkPage}>

      <StyledGridContainer>

        <StyledGridColumn desktop={2} tablet={2} mobile={4}>
          <StyledFooterLogo src={darkPage ? logoWhite : logoBlack} alt='ORBRO Logo' />
        </StyledGridColumn>

        <StyledFooterHead>

          <StyledFooterHeadColumn desktop={2} tablet={2} mobile={2} mt={layoutType === 'mobile' ? '32px' : ''} gr={layoutType === 'mobile' ? 'auto' : 'span 2 / auto'}>
            <Vertical rowGap={0}>
              <StyledFooterSub>
                {footerPath.product.slice(0, 4).map((menu, i) => (
                  <>
                    {i !== 2 &&
                      <>
                        {menu.link === 'none' ?
                          <>
                            <FooterLabel size="small" onClick={() => handleIconClick(1)} darkPage={darkPage}>
                              <Trans i18nKey={`navMenu.product.${i}.title`} />
                            </FooterLabel></>
                          :
                          <FooterLabel size="small" onClick={() => handleIconClick(1)} darkPage={darkPage}>
                            <AtagColor href={menu.link !== 'none' ? `${menu.link}` : ''} color={darkPage ? '#fff' : '#000'}>
                              <Trans i18nKey={`navMenu.product.${i}.title`} />
                            </AtagColor>
                          </FooterLabel>
                        }


                        {menu.path.map((data, j) =>
                          <>
                            <LabelColor size="small" color='#818181' onClick={() => handleIconClick(1)}>
                              <StyledFooterATag href={`${data.link}`}>
                                <Trans i18nKey={`navMenu.product.${i}.path.${j}.text`} />
                              </StyledFooterATag>
                            </LabelColor>
                          </>
                        )}
                      </>
                    }
                  </>
                ))}

              </StyledFooterSub>
            </Vertical>

          </StyledFooterHeadColumn>

          <StyledFooterHeadColumn desktop={2} tablet={2} mobile={2} mt={layoutType === 'mobile' ? '32px' : ''} gr={' auto'}>
            <Vertical rowGap={0}>
              <StyledFooterSub>
                {footerPath.product.map((menu, i) => (
                  <>
                    {i > 1 && i !== 3 && <>

                      {menu.link === 'none' ?

                        <FooterLabel size="small" onClick={() => handleIconClick(1)} darkPage={darkPage}>
                          <Trans i18nKey={`navMenu.product.${i}.title`} />
                        </FooterLabel>
                        :
                        <>
                          <FooterLabel size="small" onClick={() => handleIconClick(1)} darkPage={darkPage}>
                            <AtagColor href={menu.link !== 'none' ? `${menu.link}` : ''} color={darkPage ? '#fff' : '#000'}>
                              <Trans i18nKey={`navMenu.product.${i}.title`} />
                            </AtagColor>
                          </FooterLabel>
                        </>
                      }


                      {menu.path.map((data, j) =>
                        <>

                          <LabelColor size="small" color='#818181' onClick={() => handleIconClick(1)}>
                            <StyledFooterATag href={`${data.link}`}>
                              <Trans i18nKey={`navMenu.product.${i}.path.${j}.text`} />
                            </StyledFooterATag>
                          </LabelColor>

                        </>
                      )}
                    </>}
                  </>
                ))}

              </StyledFooterSub>
            </Vertical>

          </StyledFooterHeadColumn>



          <StyledFooterHeadColumn40gap desktop={2} tablet={2} mobile={2} type={layoutType === 'mobile' ? true : false}>
            <StyledFooterHeadColumn37 desktop={2} tablet={2} mobile={2} type={layoutType !== 'mobile' ? true : false} >
              <Vertical rowGap={16}>
                {footerPath.solution.slice(0, 3).map((menu, i) => (
                  <>
                    <StyledFooterSubTitle size="small" darkPage={darkPage}>
                      <Trans i18nKey={`navMenu.solution.${i}.title`} />
                    </StyledFooterSubTitle>

                    {menu.path.map((data, j) => (
                      <>
                        <StyledFooterCombineSub isLastIndex={j === menu.path.length - 1 && i < 2}>
                          <StyledLabel size="small">
                            <StyledFooterATag href={`${data.link}`}>
                              <Trans i18nKey={`navMenu.solution.${i}.path.${j}.text`} />
                            </StyledFooterATag>
                          </StyledLabel>
                        </StyledFooterCombineSub >
                      </>
                    ))}
                  </>
                ))}
              </Vertical>
            </StyledFooterHeadColumn37>
          </StyledFooterHeadColumn40gap>



          <StyledFooterHeadColumn40gap desktop={2} tablet={2} mobile={2} type={layoutType === 'mobile' ? true : false}>
            <StyledFooterHeadColumn37 desktop={2} tablet={2} mobile={2} type={layoutType !== 'mobile' ? true : false} >
              <Vertical rowGap={16}>
                {footerPath.solution.map((menu, i) => (
                  <>
                    {i > 2 &&
                      <>
                        <StyledFooterSubTitle size="small" darkPage={darkPage}>
                          <Trans i18nKey={`navMenu.solution.${i}.title`} />
                        </StyledFooterSubTitle>

                        {menu.path.map((data, j) => (
                          <>
                            <StyledFooterCombineSub isLastIndex={j === menu.path.length - 1 && i < 5}>
                              <StyledLabel size="small">
                                <StyledFooterATag href={`${data.link}`}>
                                  <Trans i18nKey={`navMenu.solution.${i}.path.${j}.text`} />
                                </StyledFooterATag>
                              </StyledLabel>
                            </StyledFooterCombineSub >
                          </>
                        ))}
                      </>
                    }
                  </>
                ))}
              </Vertical>
            </StyledFooterHeadColumn37>
          </StyledFooterHeadColumn40gap>





          {/** 지원 **/}
          <StyledFooterHeadColumn40gap desktop={2} tablet={2} mobile={2} type={layoutType === 'mobile' ? true : false}>
            <StyledFooterHeadColumn37 desktop={2} tablet={2} mobile={2} type={layoutType !== 'mobile' ? true : false} >
              <Vertical rowGap={16}>
                {footerPath.footerSupport.map((menu, i) => (
                  <>
                    <>
                      <StyledFooterSubTitle size="small" darkPage={darkPage}>
                        <Trans i18nKey={`navMenu.footerSupport.${i}.title`} />
                      </StyledFooterSubTitle>

                      {menu.path.map((data, j) => (
                        <>
                          <StyledFooterCombineSub isLastIndex={j === menu.path.length - 1 && i < 5}>
                            <StyledLabel size="small">
                              <StyledFooterATag href={`${data.link}`}>
                                <Trans i18nKey={`navMenu.footerSupport.${i}.path.${j}.text`} />
                              </StyledFooterATag>
                            </StyledLabel>
                          </StyledFooterCombineSub >
                        </>
                      ))}
                    </>
                  </>
                ))}
              </Vertical>
            </StyledFooterHeadColumn37>
          </StyledFooterHeadColumn40gap>



          {/** 기술 소개 **/}

          <StyledFooterHeadColumn desktop={2} tablet={2} mobile={2} mt={'0'} gr={'span 2 / auto'}>
            <Vertical rowGap={16}>
              {footerPath.support.map((menu, i) => (
                <>
                  {i === 1 &&
                    <>
                      <StyledFooterSubTitle size="small" darkPage={darkPage}>
                        <Trans i18nKey={`navMenu.support.${1}.title`} />
                      </StyledFooterSubTitle>

                      {menu.path.map((data, j) => (
                        <>
                          <StyledFooterCombineSub isLastIndex={j === menu.path.length - 1 && i < 5}>
                            <StyledLabel size="small">
                              <StyledFooterATag href={`${data.link}`}>
                                <Trans i18nKey={`navMenu.support.${1}.path.${j}.text`} />
                              </StyledFooterATag>
                            </StyledLabel>
                          </StyledFooterCombineSub >
                        </>
                      ))}
                    </>
                  }
                </>
              ))}
            </Vertical>
            <Vertical rowGap={16}>
              <StyledFooterSubTitle size="small" darkPage={darkPage}>
                <Trans i18nKey={`layout.footer.CS.name`} />
              </StyledFooterSubTitle>

              <StyledFooterSub>
                <LabelColor size="small" color='#818181' ><Trans i18nKey={`layout.footer.CS.call`} /></LabelColor>
                <LabelColor size="small" color='#818181' ><Trans i18nKey={`layout.footer.CS.email`} /></LabelColor>
              </StyledFooterSub>
            </Vertical>
          </StyledFooterHeadColumn>

        </StyledFooterHead>



        <StyledFooterBottom>
          <StyledFooterBottomTitle>
            <StyledLabel size="medium"><Trans i18nKey={`layout.footer.copyright`} /></StyledLabel>
          </StyledFooterBottomTitle>

          <StyledFooterBottomRow>
            <StyledFooterBottomParagraph size="small">
              <AtagColor href='https://kong-tech.com' target='_blank' rel='noopenner noreferrer' color={darkPage ? '#818181' : '#646464'}>
                <Trans i18nKey={`layout.footer.info`} />
                <Trans i18nKey={`layout.footer.info2`} />
                <Trans i18nKey={`layout.footer.info3`} />
              </AtagColor>
            </StyledFooterBottomParagraph>
          </StyledFooterBottomRow>

          <Spacing pixel='40' />
          <StyledFooterBottomRow columnGap={32}>
            <StyledFooterBottomParagraph size="small">
              <StyledFooterATag rel="noreferrer" href="/terms/1" target="_blank">
                <Trans i18nKey={`layout.footer.privacyPolicy`} />
              </StyledFooterATag>
            </StyledFooterBottomParagraph>

            <StyledFooterBottomParagraph size="small">
              <StyledFooterATag rel="noreferrer" href="/terms/2" target="_blank">
                <Trans i18nKey={`layout.footer.servicePolicy`} />
              </StyledFooterATag>
            </StyledFooterBottomParagraph>

            {/* <StyledFooterBottomParagraph size="small">
              <StyledFooterATag rel="noreferrer" href="https://orbro.io/sign-up-conditions/:3" target="_blank">
                <Trans i18nKey={`layout.footer.locationPolicy`} />
              </StyledFooterATag>
            </StyledFooterBottomParagraph> */}
          </StyledFooterBottomRow>
        </StyledFooterBottom>

      </StyledGridContainer>
    </StyledFooter>
  );
};

export default FooterCombine;
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}

