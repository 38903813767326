import { Vertical } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledHeading, StyledLabel } from "@components/Text";
import styled from "@emotion/styled";

export const StyledBlog = styled.div`
  width: 100%;
  padding: 64px 0;

  & * {
    font-family: Pretendard;
    font-display: swap;
  }


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-bottom : 0px;
  }

`;


export const StyledBlogRow = styled(StyledGridRow)`
  margin-top: 32px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    display : block;
  }

`;



export const StyledBlogCardBox = styled.div`
  display : flex;
  justify-content: space-between;
  gap: 20px;
  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
  }
`;


export const StyledBlogCard = styled.div`
  height: 380px;
  border-radius: 16px;

  display : flex;
  flex-direction : column;
  
  &:hover {
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height: auto;
    margin-bottom : 32px;
  }
`;


export const StyledBlogImg = styled.img`
  height : 220px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-size: cover;
  background-position: center;

  object-fit : cover;
  width : 100%;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height: auto;
    object-fit : contain;
  }
`

export const StyledBlogTextBox = styled(Vertical)`
  height: 160px;
  padding: 0 16px;
  position: relative;
  padding-top : 0px;

`;
