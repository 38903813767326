import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet: '',
  desc: 'ORBRO通过气象监测和先进的模拟提供了理解和适应气候变化影响的必要气候分析。通过夜间和白天的气象环境、气候和降水量、海洋气象数据、全球气象信息、大气污染监测、灾害预测和警报系统，提供了广泛的信息。这对气候研究、农业规划和水资源管理至关重要。气象卫星数据、日照时间和太阳位置信息可实时模拟昼夜气象环境，高分辨率气象模型和降水数据可模拟气象条件。海洋降雨、全球气象信息整合、大气污染监测、灾害预测和警报系统对海洋学、气候研究、公共卫生和环境保护至关重要。',
  keywords: 'Digital Twin, Digital Twins, 数字孪生, gis, 数字城市, RTLS, 位置追踪系统, 位置追踪技术, 体育, 制造, 物流, 医疗, 医院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - 大气与气象信息实现 :: ORBRO博客',
  ogUrl: 'https://orbro.io/cn/blog/digitaltwin-weather',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',
  
  title: 'Digital Twin - 大气与气象信息实现',
  date: '2023-12-26',
  tag1: '#数字孪生',
  tag2: '#气象信息',
  tag3: '#数字城市',
  tag4: '#虚拟现实',
  
  // <br/>
  text: [
    {
      head: 'Digital Twin - 大气与气象信息实现',
      subHead: '',
      desc: 'ORBRO利用数字孪生技术实现大气和气象信息的可视化，通过天气监测和先进模拟支持对气候变化的理解和适应。它通过提供气候分析，涵盖广泛的领域，包括昼夜天气条件、气候环境、降水量、海洋气象数据、全球气象信息、大气污染监测、灾害预测和警告系统，为气候研究、农业规划、水资源管理等提供必要信息。<br/><br/>利用天气卫星数据、日照时间和太阳位置信息实时模拟昼夜气象环境，并使用高分辨率气象模型和降水数据模拟气候条件。在海洋中的降雨、全球气象信息整合、大气污染监测、灾害预测和警告系统在海洋学、气候研究、公共卫生、环境保护等方面起着重要作用。',
      dot: ''
      
    },
    {
      youtube: 'https://www.youtube.com/embed/QB2sM1OdrXA?si=X3po5Cxz2Hu3QlGA',
      head: '',
      subHead: '夜间和白天的气象环境表达',
      desc: 'ORBRO利用气象卫星数据、日照时间和太阳位置信息实时模拟夜间和白天的气象环境。这为气候研究、灾害管理、农业规划和能源管理提供了重要数据。',
      dot: ''
    },
    {
      head: '',
      subHead: '气候和降水量表达',
      desc: '利用高分辨率气象模型和降水数据，实时模拟各种气候条件。这为气候研究、农业规划和水资源管理提供了必要的重要数据。',
      dot: ''
    },
    {
      head: '',
      subHead: '海洋降雨表达',
      desc: '利用基于卫星的海洋气象数据和海洋模型，可视化海洋区域的降雨现象。这些信息对海洋学、气候研究、海洋运输和安全管理至关重要。',
    },
    {
      head: '',
      subHead: '全球气象信息整合',
      desc: '整合全球气象观测站和卫星数据，收集并分析广泛的气象信息。这对全球气候变化监测和国际灾害响应至关重要。',
    },
    {
      head: '',
      subHead: '大气污染监测',
      desc: 'ORBRO追踪、预测大气质量和污染水平，支持公共卫生和环境保护政策。这为改善空气质量和防止污染提供了重要信息。',
    },
    {
      head: '',
      subHead: '灾害预测和警报系统',
      desc: '利用气象数据预测自然灾害，并开发相关的警报系统。这些系统可以改善对灾害的准备和响应，实现对自然灾害的迅速响应。',
    },

  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '将UWB终端安装在工人或设备上，以实时跟踪其位置的产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      }, 
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]


  }

}
