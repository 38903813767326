export default {
  titleBanner: {
    subtitle: 'BLE | 蓝牙低功耗',
    title: 'BLE定位追踪与定位技术',
    description: '*BLE是一种无线通信技术，其功耗较低，但数据传输速度较慢。<br/> 主要用于小型设备或低功耗传感器，并且也可用作类似信标的小型发射器。<br/> 在智能家居、智能城市、智能工厂和物联网技术等方面也得到了广泛应用。'
  },
  definition: {
    title: '什么是BLE？',
    text: '蓝牙低功耗（BLE）是一种用于无线通信的射频技术，可应用于各种室内定位用途。利用BLE技术，可以检测和追踪人员、设备、资产等位置，并可用于资产追踪、室内导航、接近服务等多种用途。与Wi-Fi和UWB等其他通信协议类似，BLE也可用于在无线设备之间传输数据。<br/><br/> BLE 4.0于2010年发布，相比前一版本的经典蓝牙，它设计用于更低的功耗。由于这种能源高效的设计，使得BLE在智能手机及各种无线设备集成、可穿戴设备和IoT设备开发，以及电池供电型BLE信标等新型蓝牙技术的出现得以实现。'
  },
  tech: {
    title: 'BLE技术的演进',
    text: 'BLE技术的发展对全球蓝牙生态系统产生了根本性的变化。许多蓝牙设备进入室内空间，现在全球范围内有超过80亿个蓝牙设备。新的蓝牙兼容基础设施和工具提供了创新性的位置感知功能。<br/><br/> 2013年，苹果的iBeacon首次发布后，出现了许多BLE技术。 iBeacon使物理信标能够与附近的无线设备通信，以便与iOS和Android应用程序配合使用。 2015年，谷歌推出了Eddystone，这是一种考虑到开放性和互操作性的信标协议。 这两种领先的信标协议以及与它们一起使用的许多物理信标产品和位置跟踪传感器等其他BLE技术，使得各种室内定位和位置服务应用成为可能，从而改变了人们和设备在室内空间中的互动方式。<br/><br/> 这些技术以及整体的蓝牙通信协议正在持续发展。 2016年，推出了新的蓝牙版本——蓝牙5.0。基于BLE 4.0的这个新版本可以在更长的距离上进行通信，并拥有更高的数据传输速率。 2019年发布的蓝牙5.1通过方向查找（DF）来实现更精确的位置定位，预计将提供厘米级的精度。<br/><br/> BLE是用于室内定位最受欢迎的射频技术之一，广泛用于无线设备，并具有低功耗、低成本、易于实现硬件选项且可在各种位置基础应用中灵活使用的特点。'
  },
  beacon: {
    title: '什么是BLE信标？',
    text: 'BLE信标是一种小型、多用途、低功耗的蓝牙发射器，可以被无线设备（如支持BLE的智能手机）检测到。信标可以固定在墙壁或建筑结构上，或放置在可移动资产上，为室内定位应用提供位置参考。这支持了BYOD（自带设备）概念，使得任何人都可以使用智能手机或其他内置设备与支持BLE的应用程序进行交互。BLE信标通过周期性地发射信号，可以被其他支持BLE的设备检测到。信标收集的位置数据会被传输到BLE设备，然后通过互联网定位系统（IPS）传送到设备的位置。这种方法支持各种位置感知应用程序，并可以触发特定的操作。<br/><br/> BLE信标有各种形状和尺寸。许多信标使用内置电池运行数年，或通过USB等连接方式供电。由于BLE技术的生产成本通常较低于其他射频技术，因此可提供定制的硬件选项，以满足特定需求。一些信标可以整合超越BLE的技术，例如加速度计或温度传感器，以实现更出色的结果。虚拟信标允许在不添加许多额外硬件的情况下添加BLE信标技术。使用虚拟蓝牙信标，可以将天线添加到兼容的Wi-Fi接入点，并与各种室内定位应用的额外软件工具一起使用。许多普通企业级Wi-Fi接入点现在已经内置了BLE技术。通过将这些接入点用作信标和传感器，组织可以无需部署辅助基础设施即可检测并定位正在传输的BLE设备。'
  },
  howItWorks: {
    title: '蓝牙定位是如何工作的？',
    text: 'BLE室内定位解决方案利用BLE支持的传感器或信标来检测和定位室内空间中的智能手机、追踪标签等蓝牙设备。传感器收集的位置数据或信标发送给移动设备的数据会被各种定位应用程序收集并进行分析，然后基于此实现各种位置感知用例。',
    items: [
      {
        title: '使用BLE信标的蓝牙定位',
        description: 'BLE信标持续发送BLE信号。这些信号可以被智能手机、BLE支持的传感器等周围设备检测到。当信标被放置在室内空间的固定位置时，连续发送的信号中包含该信标的唯一标识符。该标识代码会按照信标通信协议与其他数据周期性地传输。<br/><br/> 支持专用应用程序或预配置服务的智能手机或其他无线设备可以在信标范围内接收和分析信标发出的信号。通过启用基于接近的位置服务，可以确定信标和设备是否在范围内。通过多个战略性地放置在整个室内空间的信标之间的通信，可以提取信标与无线设备之间的关系并得出设备的位置。根据应用的不同，确定的设备位置可用于触发特定操作或应用于各种应用程序或服务。BLE信标也可以放置在移动设备上，从而可以在资产追踪场景中实用。在移动设备上安装的BLE信标可以被固定的BLE支持传感器检测并定位。'
      },
      {
        title: '使用传感器的BLE定位',
        description: '使用BLE传感器进行室内定位依赖于在室内空间中固定位置放置BLE支持的传感器。这些传感器根据来自蓝牙设备（如智能手机、资产追踪标签、信标、员工徽章、可穿戴设备等）发出的信号强度进行手动检测和定位。位置数据会被传输到中央室内定位系统（IPS）或实时定位系统（RTLS）。定位引擎会分析这些数据并提取向量地图，然后利用该向量地图确定发射设备的位置。这些坐标可以在室内地图上可视化设备或资产的位置，或根据特定的位置感知应用程序用于其他目的。'
      }
    ]
  },
  accuracy: {
    title: '蓝牙定位有多精确？',
    text: 'BLE室内定位和实时位置系统的准确性取决于系统架构、硬件选择和部署的传感器或信标密度。使用BLE传感器或信标进行室内定位时，在最佳条件和布置下，通常可以提供5米以下的位置准确性。',
    text2: '类似于Wi-Fi技术，蓝牙也通常使用设备的信号强度（RSSI）来估计位置。这种位置确定方法通常可以提供米级的位置准确性，相比于UWB等技术，精度要低得多。UWB使用基于距离的测量技术，如飞行时间（ToF），提供非常精确的厘米级精度。BLE目前不是室内定位方面最精确的射频技术，但仍然是最广泛使用的技术之一。在许多室内定位场景中，并不需要很高的精确度，因此BLE是一种灵活、低功耗和低成本的选择。 <br/><br/> 随着Bluetooth 5.1的发布以及新的方向查找功能，BLE将能够以亚米级的精度确定位置。这得益于新的角度到达（AoA）技术，它允许通过计算蓝牙信号的方向来确定设备和资产的位置。为了实现方向查找，移动资产（标签或信标）带有单个天线将其信号发送给带有多个天线阵列的固定BLE传感器。接收信号后，测量多个天线的相位变化以确定发送移动设备的入射角度。通过结合RSSI和AoA，可以生成以厘米为单位的设备坐标。'
  },
  range: {
    title: 'BLE的通信范围是多少？',
    text: 'BLE定位技术的范围取决于是使用BLE信标还是BLE活动传感器，以及室内空间的特性等因素。BLE通常在比UWB或Wi-Fi等其他射频技术更短的范围内工作，在最佳条件和部署下，最佳工作范围为0至25米，最大范围可达100米。',
  },
  difference: {
    title: 'BLE与其他射频技术的区别是什么？',
    text: '蓝牙与其他无线标准技术一样，具有独特的特性和优势，适用于不同的需求、预算、设施和特定的位置基础应用。BLE与其他技术的主要区别在于低功耗和在许多位置感知应用中的灵活性。BLE普遍用于室内定位，广泛用于许多定位系统，并可扩展到包括工业和各种用例的室内定位。',
    items: [
      {
        title: 'BLE与Wi-Fi',
        description: 'BLE和Wi-Fi是我们日常生活中和室内空间中最常见的两种RF技术。它们都在2.4GHz频段工作，具有大型生态系统和广泛的室内定位使用情况。BLE和Wi-Fi都使用RSSI来检测人员、设备和资产的位置。然而，BLE被认为可以实现更高的精确性。BLE以较低的功耗要求为优势，提供了更灵活的硬件选项和应用程序。然而，许多机构已经拥有Wi-Fi基础设施，引入BLE需要整合新的信标、传感器等。Wi-Fi可以支持更长的范围和更高的数据传输率，而BLE的范围和数据传输率相对较小。'
      },
      {
        title: 'BLE与UWB',
        description: 'BLE和UWB具有低功耗、低成本、资产跟踪技术等共同特点。然而，UWB可以提供比蓝牙更高的准确性。这是因为UWB使用ToF等距离测量技术，可提供非常精确的厘米级精度。BLE通常使用RSSI来估算设备的位置，但由于设备相对于信标的信号强弱而有相当大的不准确性。BLE的范围和数据传输速率相对较小。然而，蓝牙是一种灵活的射频技术，通过BLE信标、资产标签和传感器等多种硬件选项，可在许多室内空间中广泛应用。'
      }
    ]
  },
  advantage: {
    title: 'BLE的主要优势',
    items: {
      low: {
        title: '低功耗、低成本',
        description: 'BLE是一种低功耗、低成本的技术，非常适合使用在BLE传感器、信标、资产或人员标签等众多应用中。'
      },
      distribute: {
        title: '易于部署',
        description: 'BLE提供了易于部署的解决方案和与网络无关或独立的灵活硬件选项，可以轻松整合到蓝牙生态系统中。'
      },
      tech: {
        title: '可扩展的技术',
        description: '通过利用该技术，可以实现资产跟踪、蓝牙设备检测、室内位置感知和导航、近场服务等各种位置感知用例。'
      }
    }
  },
  useCase: {
    title: '蓝牙定位使用案例',
    description: 'BLE的可扩展性使其成为各种室内定位场景下有效的选择。以下是使用BLE室内定位技术的一些案例和应用领域。',
    card: {
      asset: {
        title: '资产追踪',
        description: 'BLE是实现物理资产追踪的关键射频技术之一。许多行业的企业都利用蓝牙的低功耗、灵活性和易于部署等技术，实时追踪重要资产和设备的位置与状态。',
        items: [
          {
            name: '医疗服务',
            description: '通过资产追踪功能，快速定位和跟踪重要设备（如呼吸机、轮椅等）的位置。'
          },
          {
            name: '智能制造',
            description: '通过追踪设备、机器和资源的位置和移动，实现可视化生产流程。'
          },
          {
            name: '仓库管理',
            description: '在大型设施中整合资产追踪功能，定位设备、工具和库存物品的位置。'
          }
        ]
      },
      employee: {
        title: '员工和人员追踪',
        description: '企业可以利用灵活的BLE信标、员工标签和传感器，实现员工和人员的定位，并实现各种基于位置的应用。',
        items: [
          {
            name: '无线设备检测',
            description: '在政府机构和企业办公楼等安全敏感的场所，使用传感器检测室内空间内的BLE和其他RF传输设备。'
          },
          {
            name: '工作区优化',
            description: '利用低成本的标签（如员工徽章、追踪贴纸等）追踪员工位置，优化空间利用和工作人员定位，提高运营效率和生产力。'
          },
          {
            name: '员工安全',
            description: '实现室内空间更安全，并在紧急情况或撤离事件时快速定位员工并发出警报。'
          }
        ]
      },
      location: {
        title: '位置服务',
        description: '通过采用基于BLE（低功耗蓝牙）的室内定位系统，机构或公司可以建立智能建筑，实时追踪人员在建筑内的位置信息，提供多种互动和消息传递等功能。',
        items: [
          {
            name: '接近消息',
            description: '引入BLE室内定位技术，识别客户和资产附近的目的地，并利用这些数据提供直接的客户互动，例如优惠券、营销活动和附近景点等超本地内容。'
          },
          {
            name: '地理围栏',
            description: '在室内空间周围创建虚拟地理边界，使用户在进入、离开或停留在指定区域时触发特定操作。'
          }
        ]
      },
      navigation: {
        title: '室内导航',
        description: '通过利用蓝牙位置技术，提供室内导航和路径指引功能，使人们能够快速熟悉空间并进行探索。',
        items: [
          {
            name: '室内导航',
            description: '利用BLE室内定位服务，为企业、医疗、零售、酒店、交通等多个行业的设施提供卓越的室内体验。'
          },
          {
            name: '蓝点导航',
            description: '利用BLE信标和其他室内定位技术，实现企业、医疗、零售、酒店、交通等各个行业设施内的蓝点体验，帮助顺利导航和路径规划。'
          }
        ]
      },
      business: {
        title: '商业智能',
        description: 'BLE可以收集有价值的位置数据，并转化为强大的组织洞察力。',
        items: [
          {
            name: '分析和商业智能',
            description: '定位和跟踪资产、人员和设备的位置，并随着时间推移分析这些位置数据。了解内部空间中访客的互动方式，为更智能、数据驱动的商业决策拓展视野。这些工作可以通过BLE实现。'
          }
        ]
      }
    }
  },
}