import { accessAuto1, accessAutoMain, accessPro1, accessProMain, accesscontroller1, accesscontrollerMain } from "@assets/images/main/access/product";
import { indoor280, outdoor280 } from "@assets/images/main/camera";
import { assetTracking1, workerTracking1 } from "@assets/images/main/rtls/product";


export default {

  a: [
    { text: "产品名" },
    { text: "说明" },
    { text: "通信方式" },
    { text: "分辨率" },
    { text: "光圈" },
    { text: "防水防尘" },
    { text: "工作条件" },
    { text: "电源" },
    { text: "安装支持" },
    { text: "视频保存" },
    { text: "人物追踪" },
    { text: "车辆追踪" },
    { text: "热力图" },
    { text: "异常行为检测" }
  ],


  b: [
    { text: "户外摄像头" },
    { text: "通过摄像头检测和管理人员、车辆等各种对象的位置和异常行为。" },
    { text: "Ethernet" },
    { text: "2560 x 1440" },
    { text: "F 1.2" },
    { text: "IP67" },
    { text: "-40°C ~ +70°C，0% ~ 95%" },
    { text: "12VDV" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
  ],


  c: [
    { text: "室内摄像头" },
    { text: "使用摄像头分析室内人员的移动路径。" },
    { text: "Ethernet" },
    { text: "1920 x 1080" },
    { text: "F 1.4" },
    { text: "IP67" },
    { text: "-30°C ~ +60°C，0% ~ 95%" },
    { text: "12VDC" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
  ],

  spec: [

    {

    },

    {
      image: outdoor280,

    },


    {
      image: indoor280,

    },


  ],
}
