import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { StyledControlPage } from './Main.style';

import Faq from "../../../Common/Faq/Faq";
import { CommonState } from '@redux/reducers/commonReducer';
import { useSelector } from 'react-redux';
import SchemeMobile from '../Scheme/SchemeMobile';
import { SpacingDTM } from '@pages/CommonStyle/common.style';
import Scheme from '@pages/Main/Common/Scheme/Scheme';
import { DisplayH2 } from '@components/Text/HeadingTagDisplay.style';
import { StyledAppTextBox, StyledDesc } from '@pages/Main/Rtls/Banner/Banner.style';
import Advantage from '../Advantage/Advantage';
import Falldown from '../Falldown/Falldown';
import Functions from '@pages/Main/Common/Functions/Functions';

import access from '@src/lang/ko/main/access/access';
import accessF1 from '@src/lang/ko/main/access/accessF1';
import Installation from '../Installation/Installation';
import Lineup from '@pages/Main/Common/Lineup/Lineup';


export interface HomeNewsItem {
  title: string;
  link: string;
  image: string;
  date: string;
}

const Main = ({ handleIconClick }): React.ReactElement => {
  // Selector
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  const handleIconClickLocal = (group) => {
    handleIconClick(group);
  };
  const faqItems = useMemo(() => access.FAQ, []);



  return (
    <>

      <Falldown/>
      <Functions data={access} page={'accessMain'}/>
      <Advantage/>
      <Functions data={accessF1} page={'accessF1'}/>
      <Installation/>

      <StyledControlPage>
        <StyledAppTextBox>
          <DisplayH2 size={'small'}>
            <Trans i18nKey={'simple.product.title'} />
          </DisplayH2>
          <StyledDesc size={'medium'}>
            <p>
              <Trans i18nKey={"simple.product.access"} />
            </p>
          </StyledDesc>
        </StyledAppTextBox>
      </StyledControlPage>


      <Lineup handleIconClickLocal={handleIconClickLocal} product={'accessMain'}/>

      {layoutType === 'mobile' ?
        (<SchemeMobile handleIconClickLocal={handleIconClickLocal} />)
        : (<Scheme handleIconClickLocal={handleIconClickLocal} product={'access'} />)
      }

      <SpacingDTM d={'128'} t={'0'} m={'0'} />

      <Faq items={faqItems} path={`accessMain`} />

    </>
  );
};

export default Main;


//<Slider handleIconClickLocal={handleIconClickLocal} />