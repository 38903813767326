import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
    helmet: '',
    desc: 'You can build a geofence system using UWB (Ultra-Wideband) technology. It allows real-time safety monitoring and efficient facility management.',
    keywords: 'uwb, geofence, geofencing, safety zone, secure facility, military security, military facility security, restricted area, access-prohibited area, smart security, security system',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'Innovation of Geofence System Using Ultra-Wideband (UWB) Technology',
    ogUrl: 'https://orbro.io/en/blog/uwbGeofence',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4MxDPsSDiu2Tyl6nzt6wk9OfJpf_lWTcfXeVRyvIzqQCB1pSV0Spq8jW3WvcY7Nw02jSYqstCQAImKsHtFRK-1bPx1X2mx0YaBIiayQ1ohB3l7WHUYrrwPX0KVYOmKZg-lo7OXiR53dsL16RqpSTSGdQaV46-p40ThUaFoYxY-hUVgD4l18QurSDnaKVaSL_qZ6ApBj7LSbjfbjVaUNEOXWI3bT5GNmDdlLfWBv8id417JIjgIeDW3iI_kkzevdCiKHN2ORcK4RYGPGFVeAOL8wlco80gszm93LArYd2nOvTK38arZmeYvJ0Ex0nqkjaxulQ7gJ5-a5bSBSfAaKAkqxv24246EQdlkBh9N5COrSoTqrAlmVNNf9PxF2fbxMevrZt-G9NMRIaxYkWT42AfcpxuTL1DikOcn2_q8jFMWhrvSwNYC2No2mZOgbqYoDKytKXp2xOcRM7RKZBhIZhDvfS59FVqMUMiO5aD2j2QizWcy5BI53-fnun3DZdVQTijx4yq1Gg_tQvY36Lu803m17BZ6Fia8uhoZVOdcuNpFsiAttmTR2X_wkCdeti3lj3ovDVjGE7kvZr1CdpkFft3MtDSoL2fhdU6YubXi_uuNfS_zCAwv6er3VjTfUTvPgtciMHUbAv5Ci63iew9qiOj3Fkr0ueSGZk_iqSYP-He2PUYp7TplTlsPd3CmzeGIf03yFNX1FK4RUVfIo55fXbW0n7cuga9vSRYuRF5dsHCTLILV1qr_nJGd-U4u-vO2jRh53ZHXTDgA8dZ_c4XUneNWJ0_JDYNOTmzrgJJsCDvH8-J28NyJVw-UBpgUGYZRMnhoPclDHRXAMcy5YvwsLVCLajck2ewTifU3gOr8SHNlFP_wOpK7_L5WgP7GHPYE04bZbHZ-bjCxcDM8E3wQYn32oUrEammRi0mbSb7xPPr9azb_14nmPjd1d-Ppay-29QzX8A1B5GRo4emyzqI0NMMPmDryb4QTIsKI9fYTbZsQ3fUoPH3HnNRZtpmVc-Ht1Gld7epvFpcrD199Ery-c1XIEEvw9PSDjteUY1-RMoyDutgDc06huKQxZv_wx-vfxmu-GnVz6kDHYQtEKULMtqxhF-8f65nC-p8nnbYD59CkksLRWPzzKmq7c51Sq1UDLuOqDPlnpema8t7VSg2iWUNi7pqgxfwnt1BSPeRt8XrPv8dmTmudreTbSR1g-7x4fAzf1PxU2QyGOmbVEq_5L4HNxOmJQzGnEYOxtPcTWL1G-vYS58iH6octXtcqiXoFymXxnt5sHnpCaYK1cpWTWUCjEMjJi8r2YOEPxBfzHv4VUeME1bB91xu2jsZhQC09SylsHJZZcLxW_jG4v2EzHsKI87CaHQCimSgcaP_8Iy3GPUVQdbOnRXT8XSow67w2sItu6ujbKZCr1aT7l54ree-kMoWfM3urhWArs2mQE2_oo0_eSh__SdYlxYh56m64zFkguxTpaecjUFam-ty20MdiuW5LkVjoNPR4A_9HsbmFYn_lQ8Pqc6jtL3FDml1vyeTYAYYv2XMQxoZvyGZBYbTU=w2000-h1736',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'Innovation of Geofence System Using Ultra-Wideband (UWB) Technology',
    date: '2023-09-01',
    tag1: '#uwb',
    tag2: '#geofence',
    tag3: '#geofencing',
    tag4: '#securezone',

    text: [
        {
            head: '',
            subHead: 'What is Ultra-Wideband (UWB) Technology?',
            desc: 'Ultra-Wideband (UWB) technology is a wireless communication technology that uses a very wide frequency bandwidth to transmit data or accurately measure the position of objects. UWB uses very short pulses over short periods of time to generate high-resolution signals. This high-resolution signal enables precise indoor and outdoor location tracking and distance measurement. UWB technology offers high data transmission rates and accuracy in location measurement even with low power, making it applicable in various fields.',
            dot: ''
        },
        {
            head: '',
            subHead: 'What is Geofencing?',
            desc: 'Geofencing is a technology that combines location-based services and wireless technology to send alerts or perform specific actions when events occur within a specific geographical area. Simplified, it means setting up a virtual fence and taking specific actions when entering or leaving that area. Geofencing can be used in combination with location tracking technologies like GPS, Wi-Fi, Bluetooth, and UWB. This enables precise geofence area setup based on real-time location information to send notifications or perform actions when users or devices enter or exit specific areas.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Innovation of Geofence System Using Ultra-Wideband Technology',
            desc: 'In recent years, technological innovations have been rapidly transforming our lives. Among them, the use of Ultra-Wideband (UWB) technology to create innovative geofence systems has garnered significant attention. This technology enables accurate and rapid location tracking and distance measurement, which was previously challenging. Let\'s explore how UWB technology is being utilized in the geofencing field and the innovations it brings.',
            dot: ''
        },
        {
            head: '',
            subHead: '1. Precision Location Tracking',
            desc: 'UWB technology provides highly precise location tracking capabilities. With this, geofence systems can track the real-time positions of objects or individuals. For example, in a facility with restricted access areas, the real-time positions of people entering these areas can be tracked to automatically send alerts. In military applications, this technology not only accurately monitors and controls enemy movements but also offers more effective management of facility and item locations in civilian sectors.',
            dot: ''
        },
        {
            head: '',
            subHead: '2. Visualization and Monitoring',
            desc: 'Geofence systems using UWB technology can visualize and monitor geographic information with high accuracy. Administrators can monitor movements and activities within an area in real time, enabling immediate responses to abnormal behaviors or unauthorized intrusions.',
            dot: ''
        },
        {
            head: '',
            subHead: '3. Enhanced Safety and Security',
            desc: 'UWB technology provides high levels of security. Radar-based location tracking is harder to detect compared to GPS signals, allowing geofence systems to maintain safety while avoiding external hacking or interference. This is particularly advantageous in sectors like military facilities and confidential information protection.',
            dot: ''
        },
        {
            head: '',
            subHead: '4. Versatility',
            desc: 'UWB technology offers versatility across various fields. Beyond location tracking, it can be integrated with various sensing functions for applications in environmental monitoring, disaster response, and automation systems, unlocking potential in various areas.',
            dot: ''
        },
        {
            head: '',
            subHead: '5. Future Outlook',
            desc: `UWB technology continues to evolve and is expected to provide even more refined sensing and location tracking capabilities. This evolution is anticipated to result in more powerful geofence systems with enhanced safety management capabilities. Moreover, due to UWB technology's provision of more efficient and accurate data compared to traditional methods, it is predicted to offer higher reliability in decision-making.`,
            dot: ''
        },
        {
            head: '',
            subHead: 'Conclusion',
            desc: 'The geofence system using UWB technology enables more effective defense and management through precise location tracking and monitoring capabilities. This technological innovation brings positive impacts to various areas such as civilian, military, and security sectors. Anticipate the emergence of more powerful geofence systems using advanced UWB technology in the future.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'This product aids real-time location tracking by attaching UWB devices to workers or equipment.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/en/enterprise/medical/patient'
            },
            {
                img: medicalWaste.thumb,
                data: 'medicalWaste',
                link: '/en/enterprise/medical/waste'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/en/enterprise/office/asset'
            }
        ]
    }
}
