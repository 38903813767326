import { TechUseCaseIcon3D, TechUseCaseIconBuilding } from "@assets/images/technology/common";
import { IconAnalytics, IconBattery, IconCar, IconChartInv, IconHeart, IconHome, IconLocation, IconRulerInv, IconSecurity, IconTagInv } from "@assets/images/icon";
import video from "@src/assets/images/solution/constructionSafety/video/rtlsVideo.mp4";

export default {

    seo: {
        title: '什么是地理围栏（Geofence）？| 奥布罗',
        desc: '地理围栏是指在地理区域虚拟设置边界，并检测该区域内发生的事件的技术。它结合了位置信息服务和传感器技术，实时检测和响应特定区域内的事件。',
        keywords: 'geofence, rtls, 实时定位系统, 数字孪生, 数字转型, 智能建筑, 虚拟现实, 定位跟踪, 信标, 奥布罗',
        img: '',
        url: 'https://orbro.io/jp/enterprise/technology/what-is-geofence',
        type: 'website',
    },

    tag: 'What is Geofence?',
    title: '什么是地理围栏（Geofence）？',
    desc: '',

    text: [
        {
            head: '什么是地理围栏（Geofence）？',
            desc: '地理围栏是指在地理区域虚拟设置边界，并检测该区域内发生的事件的技术。它结合了位置信息服务和传感器技术，实时检测和响应特定区域内的事件。',
            subHead: '',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/jp/enterprise/construction/safety',
            head: '地理围栏技术',
            desc: '地理围栏通常基于全球定位系统（GPS），但最近实时定位系统（RTLS）技术的应用逐渐增加。RTLS提供比GPS更准确的位置信息，即使在室内环境中也表现出色。使用信标、RFID、传感器网络等实时跟踪目标的位置，并记录系统。 RTLS主要用于车辆追踪、自动化、安全等多个行业领域。',
            subHead: [
                {
                    title: 'RTLS是什么？',
                    desc: '实时定位系统（RTLS）是一种实时跟踪目标位置的技术，与GPS不同，它在室内环境中也提供准确的位置信息。使用信标、RFID、传感器网络等技术实时检测和记录目标位置。 RTLS主要应用于车辆追踪、自动化和安全等各种行业领域。'
                },
                {
                    title: 'RTLS的优势',
                    desc: 'RTLS的主要优势是高精度，与GPS不同，即使在室内或复杂环境中也能进行准确的位置跟踪。高实时检测能力使得能够实时监测目标的移动，并通过这一能力实现快速响应和高效自动化。此外，通过组合多种技术，可以在各种行业中应用。 RTLS特别被认为是提高生产力和增强安全性的有效解决方案，尤其是在工业领域。'
                },
                {
                    title: 'RTLS的应用',
                    desc: 'RTLS技术的引入过程涉及整合各种组件。首先，选择用于识别目标的信标、RFID传感器或其他定位跟踪技术，并进行部署。然后，安装的技术连接到网络，收集实时位置数据。设置中央控制系统以处理数据，使其能够有效利用。最后，配置用户界面以监视位置信息，并采取必要的措施。'
                },
            ],
            dot: ''
        },
        {
            tag: 'noBottomLine',
            head: '地理围栏的应用领域',
            desc: '地理围栏在各个领域都得到了应用，主要有以下应用领域:',
            subHead: [
                {
                    title: '安全系统',
                    desc: '在企业大楼、住宅区、公共场所等各种区域，地理围栏可以检测到非法入侵并通过提供警告来增强安全性。它有时被用于危险区域的进出控制或作为先前警告系统。'
                },
                {
                    title: '车辆追踪',
                    desc: '在汽车和货运行业，地理围栏可以用于实时监控车辆移动，并设置警报，以便在特定区域外行驶时触发。'
                },
                {
                    title: '智能城市和物联网',
                    desc: '在智能城市项目中，地理围栏被用于监控人们在公共场所的移动，以优化城市基础设施。它还可以用于智能交通系统中的交通流分析和拥堵检测。'
                },
                {
                    title: '休闲和娱乐',
                    desc: '在旅游胜地和主题公园中，地理围栏可以跟踪游客的移动，提供更好的服务。此外，可以设置可移动的边界，以支持或限制特定区域内的活动。'
                },
            ],
            dot: ''
        },
        {
            videoDesc: '(建筑工地安全管理，点击跳转)',
            tag: 'noBottomLine',
            head: '地理围栏技术展望',
            desc: '地理围栏技术正在不断发展，并预计未来将进一步扩展。随着技术的进步，精确的位置追踪和实时检测能力提高，预计将在安全领域等方面得到加强。<br/>此外，室内定位技术的进步使得在建筑内也能准确了解目标位置，从而预测在车辆追踪、工业自动化、智能城市等各个领域的应用将进一步扩大。此外，通过与协作系统的集成，预计将通过与各种传感器和技术的协作来开发有效的地理围栏解决方案。这一技术的进步预计将在安全、物流、智能城市等多个行业领域带来创新性的变革。',
            subHead: [
            ],
            dot: ''
        },
        {
            tag: 'card',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
        {
            tag: 'advantage',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
    ],


    card: {
        title: '地理围栏的应用案例',
        desc: '地理围栏技术在各种领域中得到了有效的应用。以下是一些代表性的地理围栏应用案例。',
        cards: [
            {
                icon: IconSecurity,
                title: '安全与警报系统',
                desc: [
                    {
                        title: '建筑和设施保护',
                        desc: '地理围栏可在建筑物或设施周围设置虚拟边界，当有非法入侵发生时，可触发实时警报，提高安全性。'
                    },
                    {
                        title: '车辆和运输保护',
                        desc: '将地理围栏应用于车辆或运输车辆上，可以在车辆偏离特定区域时设置警报，从而支持防盗和安全运营。'
                    }
                ]
            },
            {
                icon: IconHome,
                title: '物流和仓库管理',
                desc: [
                    {
                        title: '实时货物跟踪',
                        desc: '通过在物流网络中引入地理围栏，可以监控货物的实时位置，为高效的仓库管理做出贡献。'
                    },
                    {
                        title: '配送和运输优化',
                        desc: '通过使用地理围栏优化配送和运输路线，可以促进成本削减并提供快速的服务。'
                    }
                ]
            },
            {
                icon: IconHeart,
                title: '医疗机构安全',
                desc: [
                    {
                        title: '患者位置追踪',
                        desc: '在医院和医疗机构引入地理围栏，可以实时追踪患者的位置，从而支持紧急情况的快速响应。'
                    },
                    {
                        title: '医疗设备管理',
                        desc: '通过在医疗设备上引入地理围栏，可以监控设备的位置和状态，提供精确和高效的医疗服务。'
                    }
                ]
            },
            {
                icon: TechUseCaseIconBuilding,
                title: '智能城市的安全',
                desc: [
                    {
                        title: '公共空间监视',
                        desc: '通过在城市的重要地点应用地理围栏，可以增强公共空间的安全性，支持犯罪防治。'
                    },
                    {
                        title: '交通流管理',
                        desc: '通过在道路和交通网络中引入地理围栏，可以监控交通流，并制定高效的交通计划。'
                    }
                ]
            },
            {
                icon: IconLocation,
                title: '教育机构安全',
                desc: [
                    {
                        title: '学校和大学校园保护',
                        desc: '通过在学校和大学内部构建地理围栏，可以防止非法访问，确保学生和员工的安全。'
                    },
                    {
                        title: '教育资源追踪',
                        desc: '通过将地理围栏应用于教育设备和资源，可以支持高效的资源管理和防止损失。'
                    }
                ]
            },
            {
                icon: IconAnalytics,
                title: '环境监测',
                desc: [
                    {
                        title: '自然环境监测',
                        desc: '通过在自然环境中应用地理围栏，可以实时监测大气质量、水质、气候等，为环境保护做出贡献。'
                    },
                    {
                        title: '工业环境管理',
                        desc: '通过监测工业设施内的环境参数，可以为生态友好的运营提供准确的数据。'
                    }
                ]
            }
        ]
    },
    advantage: {
        title: 'ジオフェンスの主な利点',
        cards: [
            {
                icon: IconRulerInv,
                title: "实时位置检测和警报",
                desc: "地理围栏实时检测对象位置，当进入或退出特定区域时立即触发警报，通过迅速响应和准确的警报提高安全性。"
            },
            {
                icon: IconTagInv,
                title: "为各种应用进行模拟",
                desc: "地理围栏可以模拟各种场景，允许设置虚拟边界以事先了解这些区域的情况。这使得在物流优化、交通流管理等方面能够有效地利用。"
            },
            {
                icon: IconChartInv,
                title: "降低成本和延长资产寿命",
                desc: "通过利用地理围栏实时监控物理对象的位置和状态，并通过预测分析优化维护计划，防止不必要的维护，预测故障，进行高效的修理和更换，降低综合维护成本，延长资产寿命。"
            }
        ]
    }

}
