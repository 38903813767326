import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet: '',
  desc: `RTLS（实时定位系统）是一种用于实时跟踪和监控位置信息的技术。利用这项技术的数字孪生技术可以数字化模拟和管理来自现实世界的物理系统或空间，实现实时管理和分析。`,
  keywords: 'Digital Twin, Digital Twins, 数字孪生, RTLS, 定位系统, 定位技术, 体育、制造、物流、医疗、医院',

  ogTitle: '',
  ogDesc: '',
  ogSite: "数字孪生和实时定位系统 :: Orbro Blog",

  ogUrl: 'https://orbro.io/cn/blog/digitalTwin',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EObM89yl-Fckym9cymsbb7S7KBZ-fORj-iI1ch019MZXKtGLBD8Q6iWqftjthjE-64HwCxCX7XRPR10bcSBzxfbbHdVe_fmhy-hRrTTbEqa1kmSlG5WJwe_aFIG3PMwmlcPU90rQL2e9sjumg_2pSrR0sgMw6IN_LEwA-To3jUhSTh42IZmzhH0_1e5TJi90KWyaQEDki6hm93269tWDF2Xfm3oZpVefWKgJ7Xnv0NVZ3NMHBGkLEZz236wZHYh8GuRwDBtgHG8EKrm3c0X_9wkhJsdZATZNeCHPBUqyEDFMGmqaOnj5TYLfoJ25Il3v1m91oZtuoSchfnhmgHThYM6qbzj5ID4OSkyrRre3W3EpjouXL3zLHCJSOvOoHA6Kc3r_K58o7PuCQtVLs0b-lmcGHhXfC4peLT-9_8cWEisLN9cPEA_4hg5HT3tZ3zW9xG0WaU809hPnE9sBfCUriBFXpQaHHI6SIZ6lDLYSYJ2AzxYXxTqNWcCd3cvdbm4Vv9zzDaYP8phmcP1-TVhwKjMS3djddsWAPjxzbbqx4X6P4S3O5qCgKsCPMzOSiYDGSneF9xLkcFpLm13KT7xUcDV4wWlR-PrM01GTS66BvLHflJa_pg0ZtJte4DAIBu8TPsiDoiLjxT1WB69G-OYqtGnDrXVpUJkk8hhnK2n02PqcAwDA6s60Zvo_qKRnDXqbwkYe1qc_pwctFBShNNA-2NZATqu3r4Fl2gLy07d1dG9Rz39jB7tKKIWSeTBl-bpslCsuyWQaK6IxF19oGBhANRlIwpMHQfSJOWVSeE0QtKA4hlzcONIb28Iz8FfnYJUlNL_mEEKfC2QsgCP9QhKJ9xHYPPn_GBZ8TFuQSZvsDQvJ64s1teGa1Z8KdsteqgEG7iP_QxD_hfYHTEZc4c-EiDHiwwlrKrHPp5hLPgjMr9mWLUinWGKPTnPS39qBnM9Qq4cscUTNNGRDPumUYh7NIDmCR5PZ60qw2qCTSOcmlOQGvIaiZSEj9VpXBoLgEmJQHBylfdfduwNMLkIbbBdu9HKVJhcKkcIM0pgxhUjIX1yLgaNBg2GPWwEW9ep8pBep3OFKcdtehYRxHKLcCC6-69tQcLLslYLGTFyGoVokZ4dtBpy1lLWIG_YraeJ7yalMNg2J4yOYvwoLTInt3G6hSnU37a6twsvEp9TJR6z9jo2A9chkGqWyeg7bXB4Sc8-lIwwhlEXGQgW1MWX-h9kBUvZNEs0-_Jx6Drdfi0vYIfaK5-6GhfEZTVGuS6zzHCCpUNMFfu3Cu06IA7BWvunUR10RDVViw1gKdqrBdd5tgyrTqBoPEUaaBJIK9Dw0UcMRSP97gbKb6Slr49dZCyN2toItkq1fWOxtM6Of7AUzY3aGinq1R675xtbTceH2_QpwYO9HwgJbLHYdDtsgeum0buiGPEhInEHCe44zxXvOYzNzs1fRs9aPAM-H_ukO3HPnSAJ8yHWnnKTZ90M-KQQ_PMMuVgY7EKiGZMDGPNxxN-yVxPjSbFoQPtWLvLWA2XkWK8ExgJN4YuLR29U-f8ZiwcIQQwNA3gWp3qNF7QwyMjjZCg=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: "数字孪生和实时定位系统",
  date: '2023-04-22',
  tag1: "#数字孪生",
  tag2: '#RTLS',
  tag3: "#定位",
  tag4: '#UWB',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: '新冠病毒和战争导致了长期的封锁和全球供应链的崩溃，许多工业技术陷入停滞，但是在工业界，许多技术正在复苏，并且这些技术的优势变得更加明显。许多制造、物流和公共交通公司在人手不足和更严格的截止日期下挣扎时，熟练的经理正在寻找可以减轻负担的技术解决方案。因此，RTLS和数字孪生市场的增长前景变得明确。<br/>RTLS和数字孪生技术在制造业中得到发展，但现在也在各种行业得到应用，并正在探索新的应用领域。这些技术不仅可以提高生产率，还可以作为应对人力短缺和紧密工作时间表的策略，许多企业积极采用它们。',
      dot: ''
    },
    {
      head: 'RTLS需求增加',
      subHead: '',
      desc: '根据调查，RTLS市场预计以目前的25.1%的年复合增长率增长。随着更多设备连接到互联网，企业将能够通过移动和可穿戴技术提高生产率并获得更好的洞察力。应用位置跟踪技术进行事故预防和错误回避的应用增加，推动了新的RTLS销售增长。此外，大流行后健康相关应用领域的激增也推动了这一增长。<br/>亚太地区工业物联网采用的增加推动了交通、制造、医疗保健、政府和国防等行业的RTLS采用增长。随着印度制造业向1万亿美元的目标迈进，仓储、运输和物流等领域也形成了庞大的RTLS合同。',
      dot: ''
    },
    {
      head: '体育领域中的RTLS',
      subHead: '',
      desc: '顶尖运动员和球队始终在寻找提高自己竞技水平的方法，电视网络也一直在寻找提高体育迷观赛体验的方法。在过去一年中，RTLS在这两个方面以多种方式提供了帮助。首先，位置跟踪系统用于分析篮球训练和比赛中球员的控球技巧、速度、加速度等重要指标。<br/>在卡塔尔世界杯上，足球利用UWB技术来记录每次对足球的接触。这些数据对于快速准确地判定越位是有帮助的，并且生成可在现场电视直播和球场监视器上投影的图像，让球迷能够理解裁判的判决。这被认为是卡塔尔世界杯成为有史以来最和平的比赛之一的原因之一。',
      dot: {}
    },
    {
      head: '医疗领域中的RTLS增长',
      subHead: '',
      desc: '由于医院和养老设施的复杂性和高风险性，医疗领域是RTLS进入困难的领域之一。然而，最近，对RTLS解决方案的需求因多种目的而增加。<br/><br/>许多医院和养老设施正在使用RTLS来追踪患者以防止冠状病毒19的传播。这是为了应对大流行对医院运营的巨大压力。此外，使用RTLS优化医院内人员和资源的流动，生成设备使用和患者预约的自动时间表以追求额外的成本节约。甚至诉讼也将RTLS作为降低成本的手段之一。医院希望通过使用RTLS来监测运营，以防止过失指责。<br/><br/>虚拟病房并不是新概念，但RTLS使得从中央集中平台更顺畅地管理患者。澳大利亚政府卫生部声称：“自动报告书编写将减少老年护理员花在报告书编写上的时间，并降低相关成本。”使用可穿戴传感器在家监测患者的健康状况，比传统方法更快地向医生报告问题。这导致获得更多需求治疗的患者床位，减少了等待治疗的患者。新加坡在试运营成功后，卫生部正在扩大虚拟病房试点项目。',
    },
    {
      head: '数字孪生的出现',
      subHead: '',
      desc: '根据一份报告，数字孪生市场目前拥有950亿美元的价值，并预计将在2032年达到7265亿美元。目前，运输和汽车领域占据数字孪生技术的最大市场份额，但在医疗、通信、房地产和零售等领域的扩散也已经开始。由于RTLS与数字孪生技术紧密相关，预计在亚太地区也将出现类似的增长模式，并有望在未来几年内实现40%的复合年增长率。',
      dot: ''
    },
    {
      head: '城市的数字孪生',
      subHead: '',
      desc: '过去，数字孪生通常局限于生产线、汽车工厂、物流中心等，但最近开始出现将其应用于整个城市的概念。ITC、BS和ET的研究人员发布了一份研究报告，证明了数字孪生在城市规划和协调方面的实用性。该研究的主要作者Ville Lehtola博士表示：“数字孪生使得城市管理者、居民和企业能够获得有关城市基础设施和服务的最新信息，从而为城市带来重要的优势”。<br/><br/>在整个城市中使用数字孪生的广泛目标是节省时间和成本，并使城市环境更加可持续。为此，需要监测交通噪音、太阳辐射量、道路安全、公共交通系统、垃圾处理以及警察和消防响应时间。通过首次收集这些领域的数据，可以大大提高城市生活质量。<br/><br/>大多数城市尚处于假设阶段，但英国伯明翰已经开始实施数字孪生以提供东部地区的优势。该系统将作为数字“试验台”，以验证智能家居、清洁空气区和区域增长战略的影响。RTLS和数字孪生领域正展现出非常有趣的发展。这个领域正在快速发展，并且市场增长预测表明，它将为制造业以及航空航天、医疗保健和公共交通等领域带来巨大的好处。',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: "将UWB终端机安装在工作者或设备上，帮助实时追踪位置的产品。",
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]


  }

}