import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| 당신의 전시관 방문객이 줄어드는 이유',
  desc : 'RTLS는 전시관 내 방문자의 이동 경로를 추적하고 관람 흐름을 분석하여 전시물에 대한 효율적인 배치 및 전략적인 마케팅을 구현할 수 있습니다. 또한, 방문자에게 개인화된 정보 제공과 인터랙티브한 체험을 제공합니다.',
  keywords : '전시관 방문객 분석, 동선 분석, 방문객 동선 분석, 전시관 관리, 스마트 전시관, 전시관운영, 박물관, 미술관, 리테일',

  ogTitle : '',
  ogDesc : '',
  ogSite : '당신의 전시관 방문객이 줄어드는 이유 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/visitor',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4MMdkaL97d9T_gFLigXJSSbK2C0Or5jdSF6zVXkL2GI1HygPUMT2RCE0ttjlbTfLOl7PLSt3VsYQ6KBvvxEcPY3GuIa0uLL-mF35giNEbpMQNRgbpFHVcsqyA4o5rANv8gihSPfYbKM1HyvPt6_D1kZep6j_kGIaRAvWTOvABY9JXY295auK1rnwLsUAz1X331ALugJ2qNpT27T78NeS4AiEE_bH81MCbchU1l9ZcYBhY7YU7MoeuNdgO9gOc3fUkLNxPjMk9a0Egch5WFcyvcT6f6zbP4GStaxCf9r-AMdky5yya4HlObcx-cc9tJBlLRRmVjz1q73RJGDgIn8z6eIEBaOdJwi40d1Y697PV2niYZZAHlNQfCy7AaBz2m93TnUzj2I9Jf95EghdfJ2n0DE8C9g_JPlkCqJkjidGuNy8TepCsFr0_lzXEvxuYf-kgIqoykgB7hF8lkIyLegxSeY-4UtNLa63wKeVC0zuS8eMFrMyZLj8RzK5C7F4xVN546wg3P_OKi5cyKM1MEaA_E00yT-21yH5O4lo2TcH8vrAnUywRRPr6AU8nyWj3Ao3i1r7MdYeuvX00TTrkY9oCqtsPqjPBWatW5J2b1MAA9huMencK80d_RPn6K9MAJ46DxVfMEeOVGCZbVDP4Gw25D-_zZupP04L_a1yKnXuECxtYPD33mhyiT5ujUthjZENr-Iqh-8hjoblcAXTVW1B8MQVQPFwhXyg7Ku5mqxxR8qMu4XpbwPLFB0dOybWkairwNgv9Le1CI_2pTt-GkYKsC1U0ZxJUhFVd5gG6j8PPlaexJlRMIfslQVBwz6hFidjGuzeKOHz2BJBUG8iNu1DPSRGfaouJZmVtueDjlUYRXDpZbHZhmb7qtWqgX3cDComumpY4F8rE8CP3NESiewWR6aRWwuT2HM7t-9KovMjMuNC2WFZUeBFN7nGZOmXLITm4xGWZbYSsHMIoOvxVECF6sOKHdp9Hmx7veIXsSwhSdBh67hlaKJBXzXZ6uRnxi554EzB5xfZpExcxFpsEtPdFKFCPNnGVuudg_N2eYeVj6ZECRrqK7S-RUEzaiLLn5Dkw41DkxN4gJv7wxNmYNaH0q-pGU64OOMtrmJF9U9Ge9CLFze-95PFYYdjG_vO-E6ebt0N14ihC9qe1FYKkL7U3rUBKQdqGWnYU4OpKMhzmfI4U7S7yNQCbThTvPlusycMPcyuwfd_hIpmTxlwCymWUMbJKerk3mwqmyPdwhF4g5V0iYUVa2SeHyBzKAxMnulxIwCP89lUWJjUADIW_GepdLxyup3wFshCD3ljdM-KE43rP0H8ll55cJWUwwhgeuNdk4VrVNMkmsr5hqF54q15rcUE3ZeGr6wlwC0s-RskVI8B8RGU6h-ny7vy5u8Tf5YCoAm39v7KC6vG_zdOwzt5OFTZYSj1i1JYF3LmZXJmt86A6w6jN_K87xQtVOytpfk7W_Prwkm0tPBDnmTZGhe1V9qG7IH5aNI6nEEFoAYAPWwxWyCmTxBjCPgEZJoIkc0lPA9VjOeLLD88lcVF0fZHShvUOoqmGM-rBcIYwJ6dtkP9to=w2199-h1147',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '당신의 전시관 방문객이 줄어드는 이유',
  date : '2023-08-14',
  tag1 : '#전시관',
  tag2 : '#방문객분석',
  tag3 : '#동선분석',
  tag4 : '#스마트전시관',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'요즘 같은 시대에 전시관은 단순히 작품을 보러 가는 곳이 아닙니다. 바쁜 일상에서 시간을 내어 문화생활을 하는 만큼, 그리고 예술에 대한 기대치가 더 높아진 지금 시대에서, 전시관을 방문하는 것은 꽤나 큰 기대와 놀라움을 바라는 것일 테니까요.<br/>조금 더 대중 친화적인 전시관이나, 꽤 수준 높은 교양과 고상함이 가득한 전시관이나, 어찌 되었든 모두 주 방문객층의 취향을 반영해야 한다는 것은 큰 차이가 없습니다. 하지만 예술적 아름다움을 느끼는 것은 꽤 주관적인 부분이 차지하므로, 어떤 작품이 더 좋다거나 나쁘다고 하기 어렵습니다. <br/><br/>그러나, 사람들이 선호하는 작품은 명확히 존재합니다. 물론, 어떤 작품이 더 훌륭하다고 선뜻 얘기하기 어려운 시대(예술적 교양이 없어 보일까 봐)이기는 합니다만, 전시관 내에서 더 오래 머무른 곳, 사람이 북적거리던 곳, 사진을 유독 많이 찍으려는 곳은, 다수의 사람이 그 작품을 좋아한다는 지표가 됩니다. 결과적으로 더 많은 방문객을 유입시키려면, 사람들이 좋아하는 전시품을 잘 파악해야 한다는 것입니다.<br/>문제는 사람들이 그런 얘기를 전시관 운영자에게 말해주지 않는다는 것입니다. 그렇다면 이는 운영자의 감에 의존해야 할까요?<br/><br/>최근 실시간 위치 추적 기술, RTLS의 발달로 실내 공간에서 걸어 다니는 사람들의 동선을 파악할 수 있게 되었습니다. 또한, 이를 전시관과 동일한 모습의 디지털 화면 위에 이미지를 표시하여, 누구라도 한눈에 쉽게 알아볼 수 있게 할 수 있습니다. 단순히 현재 위치를 파악하는 것뿐만 아니라 다양한 기능들이 가능해졌는데, 아래에서 몇 가지 알아보도록 합시다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'유동 인구 분석',
      desc : '시설 내에서 사람들이 어떤 식으로 분포되어 있는지 파악할 수 있습니다.여기에 히트맵 기술을 더한다면, 어떤 전시 작품 쪽에서 사람들이 많이 몰려있었는지 파악할 수 있습니다. 즉, 선호 작품을 파악하는 과정이 너무나도 쉬워진다는 의미입니다. 일일이 사람들에게 설문조사를 하거나, CCTV를 살펴보거나, 매니저가 시설을 돌아다니거나 할 필요없이, 그저 히트맵 버튼을 누르면 됩니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'자산 관리',
      desc : '실시간 위치 추적은 사람에게만 적용되는 것이 아닙니다. 특히나 전시작품과 같이 특별하게 관리되어야 하는 자산이나, 아니면 중요 장비들에도 이 기술이 적용될 수 있습니다. 해당 자산들이 이동하는 과정을 실시간으로 파악하여 안전사고를 예방할 수 있고, 좀 더 효율적인 동선과 자산관리가 이루어질 수 있습니다.<br/>게다가 검색을 통해서 찾고 싶은 자산의 위치를 한 번에 파악할 수도 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'보안',
      desc : '실시간 재고 추적을 통해 더 빠른 리드 타임을 구현할 수 있습니다. 재고 위치를 정확히 알기 때문에 필요한 재고를 빠르게 찾아낼 수 있고, 생산 또는 배송에 필요한 시간을 단축할 수 있습니다. ',
      dot : ''
      
    },
    {
      head : '',
      subHead :'',
      desc : '이러한 기술들은 다양하게 활용될 수 있습니다. 실내 내비게이션을 활용할 수도 있고, 스마트 출입 시스템을 활용해서 좀 더 안전하고 효율적인 시설 운영을 할 수도 있습니다. 또한, 이런 기술들이 사람들에게 알려진다면 자연스레 스마트하고 트렌디한 전시관의 이미지를 제공할 수도 있습니다.',
      dot : ''
      
    },

  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : retailExhibition.thumb,
        data : 'retailExhibition',
        link : '/enterprise/retail/exhibition'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      }, 
      {
        img : officeAsset.thumb,
        data : 'officeAsset',
        link : '/enterprise/office/asset'
      }
    ]

  }


}