import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {


  helmet: '| NFCタグに代わるUWBとBLEを活用した在庫管理',
  desc: 'NFCタグではなくUWBとBLEを利用して、よりスマートで便利な在庫管理システムを実現できます。すぐに導入可能なソリューションをご確認ください。',
  keywords: 'nfc、uwb、無線通信、位置追跡、オブジェクト位置追跡、人物位置追跡、タグ、ビーコン、rtls、orbro、kongtech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'NFCタグに代わるUWBとBLEを活用した在庫管理 :: オーブロブログ',
  ogUrl: 'https://orbro.io/jp/blog/nfc-uwb-ble',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDwxW_WSF_F7yNjIa07OkmVhKArBLWz8-_J1moJsQci7Ije4YbYRD5Oa0A3rsNOuVWMRMVKmnI6q7dJm894FnMM4irt8-NIgSx08LF7IIza4ONna0iwlNNedic3hlVYbDPl7746HMAAhJMopboCbqV9PlElgpHpCuSsWKAWobQKY1JrFwdltqWl5FsJqgC4DiIaxM9NUQNJHd4Tt2bOO1lPabNGc27n5QShIyfFcNuGdpozghgJAoszlfuiyD5CVSXKJOXYsfrj1Dhk0cbYrU_RHZfxcle6uATv2tw81Rq5eyY-OCCnNI9WQYLcp5PeiRUydm3S0Ba1eR0DQF-dHz8AvSGqnC2liVRiO-p1TSfr_dtXdAJcEDMSC1_zz2O2dksO3KqdbnvRkpDU-xKCqeel5YaHVAxFFOt19k4SHGVc493DTVSksZhLeWJPTBllKX8oc5zt5qErJMjPZWMUZ2iOr-geb_9fVyyuZogAAj-tFvkAF-mqBapjOwe_z2_8caQhLzg6vnNK3OSmDHQMOLUyZT22pB-OBf0QWVA-9wzMUyIo6BZbUXSj8n0bd6Hq72hFlNnNKwDe2aHoam2KguWNLfBx3PFqYkeO3fbdBRoc8vGkW_FcogVpvOusbERHosgwDP6T-foORllJuVUPjKZyx3CuilLxfRO-fo-4JF0yB8zqC-WZUHzpzyYTN5LZk0nln7wCJNPlMuRtxzJNq_4KrjorS6SdzMmpuYJog9QlrLImyeiR3wOLLvc2bklRyoEQ5T-DcDttw6dzFt1jFu8BjtXKIJ9KCc5PfIgl4XB2T5LshMXFDw7HU2qoFfYHvyQciti_HH59bUaP67x0KwbzAFSnMyP8edq6y4Wsvvhrl5cvYepph4sK7Dn5zkeP1Pv9qa5wNbYn--ZEAND3870LLFBqN5ttPfpxijnKszom0HGZG9G1EGUcU3GeMyAKg5Un-dXh7mWayLTFjU2UIBCRzpU5w509ZaRDokT94aFEyudm035LQQWLoRIV6TInKZ-LaBi6LNpXrykfPgmZb97nPCdULyDlhpp7oYwDLN7wgNy6b0-Wn5Tj9sl8qVTs1o_LrAT-8tl2UZ-uM9MPzHVKPmpPtnDymJklgsbMOp3TK45pIQ9voLx_ESS7YM1S2gw2ihX-Kvs3PqdKmxJ8y6LU8Op4OdaAeuWY1evq4apVEly7tAVqx7Gh5EiLl7PdZnpX1PiwLiz4WnDK0WLV_YOPYQlouBLWy7ojtfLu_C5UjgrjyKBg71KnsCv680b2sBcB-p2Wt6aD6IFtfAlT5J5nzg_cW01H3nwcRUKJdwKsVN-6_vVbyyBX8WGa4yR00BKZ0_scjPlWgID-43QPnz5X6P0IHyJUKtyUTiZM9a5gV7_Raq8rjHTOwYAR4r8je2xkZBJ_jSbV2Dk5yn6wEfK7XhzhLDCOrIoZdOCMhy-Qh610uh34OOtAoLyPBLfWcF_RXsMAGfz73LPe3WJx9YjYMPmRu-dvPHRiBlNrBU2OrvZN1lW3XYjKgGHTt7QlAKY2D5XpDjGA8NsfGVcpLghIXt-HCy32YCFiUdpQr5rq6mf7hfmJr=w1871-h1190',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'NFCタグに代わるUWBとBLEを活用した在庫管理',
  date: '2023-11-20',
  tag1: '#nfc',
  tag2: '#uwb',
  tag3: '#ble',
  tag4: '#在庫管理',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: '企業の生産および流通活動において中心的な役割を果たす在庫管理は、正確で効率的なシステムが求められています。このため、これまでにRFIDやNFCタグが積極的に使用されてきました。しかし、最近ではUWB（超広帯域）およびBLE（Bluetooth Low Energy）技術の登場により、より優れた在庫管理システムが可能になりました。特に、NFCタグを置き換えるUWBとBLEの活用が注目されています。',
      dot: ''
    },
    {
      video : video,
      link : '/jp/enterprise/manufacturing/asset',
      head: 'UWBとBLEの特徴',
      subHead: '1. UWB（超広帯域）',
      desc: 'UWBは非常に広い帯域を使用して精密な位置追跡が可能な無線通信技術です。高精度な位置データを提供するため、リアルタイムな在庫追跡および位置特定に優れた性能を発揮します。さらに、多重経路干渉を回避しながら安定した通信を提供し、正確なデータ収集が可能です。',
      dot: ''
    },
    {
      head: '',
      subHead: '2. BLE（Bluetooth Low Energy）',
      desc: 'BLEは低エネルギー消費のBluetooth技術で、低電力デバイス間の効率的なデータ通信に適しています。BLEタグは小型で、長寿命のバッテリーを備えており、エネルギー効率の良い在庫追跡システムの構築が可能です。また、BLEはスマートフォンとの高い互換性を持っており、利便性が高いです。',
      dot: ''
    },
    {
      head: 'NFCタグの代替の利点',
      subHead: '1. 正確な位置追跡',
      desc: 'UWB技術は非常に正確な位置情報を提供するため、倉庫や生産ラインでアイテムのリアルタイムな位置を正確に把握できます。これにより、在庫管理の精度が向上し、在庫の損失やエラーが最小限に抑えられます。',
      dot: ''
    },
    {
      head: '',
      subHead: '2. 低電力運転',
      desc: 'BLEの低電力特性は、タグの運転を長期間可能にします。これによりバッテリーの交換サイクルが延長され、メンテナンスコストが削減される利点があります。',
      dot: ''
    },
    {
      head: '',
      subHead: '3. 高い互換性',
      desc: 'BLEは多くのデバイスでデフォルトでサポートされているため、既存のインフラと簡単に統合できます。特にスマートフォンとの互換性は、ワイヤレスな在庫追跡において便利さを提供します。',
      dot: ''
    },
    {
      head: '実装戦略',
      subHead: '1. UWBおよびBLEタグの導入',
      desc: '最初のステップは、既存のNFCタグをUWBおよびBLEタグに置き換えることです。これにより、精密な位置追跡と低電力運転の利点が確保されます。',
      dot: ''
    },
    {
      head: '',
      subHead: '2. データプラットフォームの構築',
      desc: 'UWBおよびBLEから収集されたデータを効果的に管理するためには、効率的なデータプラットフォームが必要です。リアルタイムなデータ処理と分析が可能なクラウドベースのデータストレージおよび分析プラットフォームを導入します。',
      dot: ''
    },
    {
      head: '',
      subHead: '3. システムの統合',
      desc: 'UWBおよびBLEを使用した在庫管理システムを既存のシステムに統合します。これにより、すべてのデータが中央で統合されて管理され、生産性と効率性が向上します。',
      dot: ''
    },
    {
      head: '結論',
      subHead: '',
      desc: 'NFCタグを置き換えるUWBとBLEを活用した在庫管理システムは、精度、低電力運転、互換性などさまざまな利点を提供します。企業はこれらの技術の導入により、より効率的でスマートな在庫管理体系を構築できるでしょう。今後、多くの企業がこれらの革新的な技術を導入し、競争力を確保することが期待されます。',
      dot: ''
    },

  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を労働者や機器に取り付け、リアルタイムで位置追跡をサポートする製品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/jp/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/jp/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/jp/enterprise/office/asset'
      }
    ]

  }


}
