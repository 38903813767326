
import { outdoorOff, outdoorOn, indoorOn, indoorOff, IconAlarm, IconAnalyze, IconSDK, IconExcel, IconImage, IconTracking, IconLocation, IconHeatmap } from '@assets/images/main/index';
import { CameraFunctions1D, CameraFunctions1M, CameraFunctions1T,
    CameraFunctions2D, CameraFunctions2M, CameraFunctions2T, 
    CameraFunctions3D, CameraFunctions3M, CameraFunctions3T, 
    CameraFunctions4D, CameraFunctions4M, CameraFunctions4T, 
    CameraFunctions5D, CameraFunctions5M, CameraFunctions5T, 
    CameraFunctions6D, CameraFunctions6M, CameraFunctions6T, 
    CameraFunctions7D, CameraFunctions7M, CameraFunctions7T } from '@assets/images/main/camera/main/functions';
import { IconOsOff, IconOsOn } from '@assets/images/main/icon';


export default {


    mainPage: {
        product: {
            title: '了解产品配置',
            desc: '查看创新的位置追踪产品配置。',
            outdoor: {
                title: '户外摄像头',
                desc: '通过摄像头检测和管理人员、车辆等各种对象的位置和异常行为。',
            },
            indoor: {
                title: '室内摄像头',
                desc: '使用摄像头分析室内人员的移动路径。',
            }
        }
    
    },
    
    banner: {
        title: '奥博罗AI利用的摄像机位置追踪',
        desc: 'ORBRO摄像头系统管理各种对象（例如人员和车辆）的位置和异常行为。',
    
        schemeTitle: 'ORBRO提供的摄像头系统。',
        schemeDesc: '需要帮助做出决定吗？',
        schemeDesc2: '请联系我们，我们的入门专家将为您提供帮助。',
        phone: '1522-9928',
    },
    
    bannerData: [
        {
            icon: indoorOn,
            iconInv: indoorOff,
            image: '',
            iconText: '室内摄像头',
            sub: '',
            link : '/cn/camera/indoor'
        },
        {
            icon: outdoorOn,
            iconInv: outdoorOff,
            image: '',
            iconText: '户外摄像头',
            sub: 'New',
            link : '/cn/camera/outdoor'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/cn/os'
        },
    
    ],
    
    video: {
        title: 'ORBRO人工智能摄像头系统',
        desc: [
            {text: '收集和分析室外空间中车辆和人员的位置信息。'},
            {text: '识别室内移动的人员位置信息。'},
            {text: '可以与现有的摄像头基础设施（如公共闭路电视）集成。'},
            {text: '可以轻松添加所需的摄像头设备以供使用。'},
        ]
    },
    
    
    
    func: {
        title: '了解摄像头功能',
        desc: '查看摄像头的各种功能。',
    
        data: [
            {
                img: IconHeatmap,
                title: '热力图',
                desc: '实时累积数据，提供按时间段划分的热力图。'
    
            },
            {
                img: IconLocation,
                title: '实时位置追踪',
                desc: 'ORBRO的位置追踪系统实时跟踪位置。您可以使用生动而分析性的功能监视现场。'
    
            },
            {
                img: IconAnalyze,
                title: '分析工具',
                desc: '收集大量位置数据，获取统计数据和见解。'
    
            },
            {
                img: IconAlarm,
                title: '警报设置',
                desc: '设定人员不得进入的危险区域，并接收通知。'
    
            },
            {
                img: IconImage,
                title: '对象识别',
                desc: '通过分析拍摄的图像识别各种类型的对象。'
    
            },
            {
                img: IconTracking,
                title: 'SDK提供',
                desc: '无需标签终端即可分析空间并检测异常迹象。'
    
            },
            {
                img: IconExcel,
                title: 'Excel下载',
                desc: '整理所需数据，下载为Excel文件。'
    
            },
            {
                img: IconSDK,
                title: 'SDK提供',
                desc: 'ORBRO的位置追踪系统提供SDK，以支持客户的定制开发。'
    
            },
        ]
    
    },
    
    functions: {
        title: '利用AI的数字孪生位置跟踪技术',
        desc: 'ORBRO的AI摄像系统具有高精度和稳定性。它可以在室内和室外跟踪和分析人员和车辆的位置，向用户提供所需的数据。',
    
        line: 3,
        card: [
            {
                size: '3',
                tag: '主要功能',
                title: '视频中检测到的物体将实时显示在地图上',
                label: '管理员',
                img: {
                    desktop: CameraFunctions1D,
                    tablet: CameraFunctions1T,
                    mobile: CameraFunctions1M
                },
            },
            {
                color: '#fff',
                size: '1',
                tag: '移动服务',
                title: '所有空间的移动用户友好性提供',
                label: '共同提供',
                img: {
                    desktop: CameraFunctions2D,
                    tablet: CameraFunctions2T,
                    mobile: CameraFunctions2M
                },
            },
            {
                size: '2',
                tag: '与现有的闭路电视(CCTV)兼容性',
                title: '与所有视频数据兼容',
                label: '管理员',
                img: {
                    desktop: CameraFunctions3D,
                    tablet: CameraFunctions3T,
                    mobile: CameraFunctions3M
                },
            },
            {
                size: '2',
                tag: '人员和车辆的物体识别',
                title: '高识别率的人员和车辆检测',
                label: '管理员',
                img: {
                    desktop: CameraFunctions4D,
                    tablet: CameraFunctions4T,
                    mobile: CameraFunctions4M
                },
            },
            {
                size: '2',
                tag: '现场服务器设置',
                title: '最可靠和安全的安全系统',
                label: 'On-Premise',
                img: {
                    desktop: CameraFunctions5D,
                    tablet: CameraFunctions5T,
                    mobile: CameraFunctions5M
                },
            },
            {
                size: '1',
                tag: '企业级安装，适用于室外停车场等',
                title: '适用于大型停车设施',
                label: '企业',
                img: {
                    desktop: CameraFunctions6D,
                    tablet: CameraFunctions6T,
                    mobile: CameraFunctions6M
                },
            },
            {
                size: '3',
                tag: '仪表盘',
                title: '基于数据的决策的数据洞察',
                label: '管理员',
                img: {
                    desktop: CameraFunctions7D,
                    tablet: CameraFunctions7T,
                    mobile: CameraFunctions7M
                },
            },
        ]
    },
    
    FAQ: [
        {
            title: '一个服务器可以安装多少台摄像头？',
            description: '使用摄像头进行位置追踪主要涉及利用计算机视觉技术识别对象并追踪其位置的过程。为实现此功能，摄像头捕获的图像将实时进行分析以检测对象，并随后追踪其运动。<br/><br/>图像分析是一个计算密集型任务。为保持每台服务器处理视频流和执行对象追踪计算的适当性能，建议每台服务器最多安装8台摄像头。<br/><br/>'
        },
        {
            title: '是否需要购买NVR？',
            description: '使用摄像头进行位置追踪不需要NVR。<br/><br/>然而，ORBRO OS是专为分析图像和追踪对象位置而优化的软件，不提供NVR的独特功能。如果您希望实时监视CCTV视频并存储和使用它们，建议使用NVR。<br/><br/>',
        },
        {
            title: '系统是否可以使用现有的CCTV？',
            description: '是的，可以使用现有的CCTV来运行系统。<br/><br/>但是，现有CCTV的性能必须与ORBRO OS提出的规格类似。通常，已经安装的CCTV是为了安全目的而不是位置追踪目的，因此，如果您希望建立更全面的位置追踪基础设施，建议单独安装摄像头。<br/><br/>',
        },
        {
            title: '是否可以与我们自己的服务数据进行集成？',
            description: '是的，使用摄像头进行位置追踪的数据由ORBRO OS软件进行管理和存储。如果希望，可以调用和集成ORBRO OS管理的数据到您自己的系统中。<br/><br/>',
        }
    ],
    


}