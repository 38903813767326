import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/medicalWaste/video/rtlsVideo.mp4";

export default {

    
  helmet : '| BLE 란? | Bluetooth Low Energy',
  desc : 'BLE란, Bluetooth Low Energy (저전력 블루투스)로 주로 저전력 장치 간 데이터 통신을 위해 사용되는 무선 통신 기술입니다. 저전력 소비, 간편한 구현이 특징인 BLE는 건설, 제조, 의료 분야에서 다양하게 사용되고 있습니다.',
  keywords : 'BLE, Bluetooth Low Energy, 저전력 소비, 블루투스, 비콘, 블루트스 비콘, rtls orbro, 오브로, 콩테크',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'BLE 란? | Bluetooth Low Energy :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/ble',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDnfNjLOQqRH35cwkWdJ-bHoz620gNvKlc65H16bDwbDmM9mn2WV9CZBz5ngF-qPG8Kaio7OwcThigmqtAyLII5-_7o_wuEr9IGXgBBhy_HG2TtVg8JvI3jjx47Vjw5bkjNMP9pqYdZG-uQE7MSvCn-fUZW5RKVlHT6Wej9ZUjr8AuM0noE2LJXIBs8o7Ik2bNeGQcps-3AfWrgOGhlIFNJuCK2uSB1AW_cjwmHTkEIusWJalOUeSVOcaarM8cTR1xUNWmao0lb1KbxQ8qqbL4_XhFZkkUct1UG8RrIN6Psi-AsTpgkFOrBdDPlqH541BEN9bwdOETx3Kgc9iRes1JUSRoP7nrOyakxoo3aqXTFdAoosGnVLKOiER0DPiU3G07ZA9bJlehSeDDCzu8kynXgnTFz91gKfMi3avcdxHqpzaiBhQCXLBUaUM95BEkE-EXHcK8Nl2WmqiTNP7CThyNvqwZdyy1JVK3ZAc5waSTB186EjteUi4k9pd65QfukNba5px9D4ul0TIzbpY_aBS2bxSfdqlASAVpqQacahwDH0yFO0S1gPaNEMW4Rcqk4iQBol7VLpCY1Snlty4mEaUfgoKOxdkkZU3nUI7YT5EXM3uWYUNTI7a2y9qeT4hrtloASb9mN6DcMg_aqnP7emz1CZ-imi3vFJic3ohSrWlQulA-3p__vjLjaVuslbfw63OjFvQAtmnYu1VRZInPMAtDL_vflRts6E5KekPnuL4Ca5Kze4Wh9CVzuPlcdqh4jXIkUI4GEE6n9fX8ZU3eWJ3vJXWl5AsFXSA5fbMZ399h7V9B5DzuThH9vBR99mQqMnDcu-wMSq8GtgfrQvtysw0KoPKwz2uo8D1Wg5qhsJ9KCDj_gm3BY67-wftHaGOtlwve9hBoHyAlPwsV0BA4t6_tpuqzg3jfkv8hlWyWZ7x9KUguu0Im_zMOGYfPw_YdhWZoe_erVB6bGz7UHbyN2Vo-aMOK4eAjPDtOgP0qR90Sg-KjmiPwCEGL1SFTjzDht-pBQVVLCOMRzOuRArCvUZai0a4G2-JBnIxVPxNfCsLrLu33X6-UgxzpkrQfBr0YcAINxqDA_IfOOJc3C9PKFPyhTgp7cP_KVk2QsY25pHgMDTc49Ui4nwk2FxSdvlshqEZHbPKbtQHQ_JK-y3veLdrgV9ADCimrqBeHANlcWKuovdxhGvrr2mlRG-t2pD_62qO-Gh0t93pn9qocK1oVmPweHfGPMC2E1Z_GtmeOr33-mwXkKK-R7LUT7aLOO359xDj0C__aXlIFScOt-8RVul4IJ8HQoENLTkwNTs-jt-Z_LMbO73LvC5YlngDpmg1n9_NLCcomYIxw6PBKksDaqWJM732sqJiEsvmm9NYyVnBneRLbCAIKbq6xsH1y98rbeCc8tlWYSBfUNFx-CvWG-L_8IrgL0bhnMKFzN4bHqJCVezRfIHgDdfdOwOSN3xxFZmg4KSWjdPA3VtyO57X_zgNjlVtEE6PDawKLNXUiaAd6nyZtpP-FLbph9jZ7-Ws8g_A_gHtSv2NQOq3CQ73x9_YgopHxq4rJ3W=w2189-h1213',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'BLE 란? | Bluetooth Low Energy',
  date : '2024-01-04',
  tag1 : '#BLE',
  tag2 : '#BluetoothLowEnergy',
  tag3 : '#블루투스',
  tag4 : '#비콘',
    
  text : [
    { 
      head : 'BLE 란?',
      subHead : '',
      desc :'BLE란, Bluetooth Low Energy (저전력 블루투스)로 주로 저전력 장치 간 데이터 통신을 위해 사용되는 무선 통신 기술입니다. 저전력 소비, 간편한 구현이 특징인 BLE는 건설, 제조, 의료 분야에서 다양하게 사용되고 있습니다. 이 블로그에서는 BLE의 기본 개념, 동작 원리, 주요 특징 및 다양한 응용 사례에 대해 자세히 알아보겠습니다.',
      dot : ''
    },
    {
      videoDesc : '(병원 폐기물 관리 솔루션, 클릭하여 이동)',
      video: video,
      link : '/enterprise/medical/waste',
      head : 'BLE의 기본 개념',
      subHead :'',
      desc : 'BLE, Bluetooth Low Energy 는 주로 스마트폰, 웨어러블 기기, 센서, 헬스케어 장치 등과 같은 저전력 디바이스 간의 통신을 위해 디자인되었습니다. 블루투스와 마찬가지로 2.4GHz 주파수 대역을 사용하지만, 전력 소비를 최소화하여 장기간 동안 배터리 수명을 지원합니다.',
      dot : ''
    },
    {
      head : 'BLE의 동작 원리',
      subHead :'',
      desc : 'BLE는 기본적으로 주기적인 광고(Advertising)와 연결(Connection) 두 가지 모드로 동작합니다. 광고 모드에서는 디바이스가 주변에 자신의 존재를 알리며, 연결 모드에서는 실제 데이터 교환을 수행합니다. 이를 통해 저전력 상태에서도 효율적인 통신이 가능하게 됩니다.',
      dot : ''
    },
    {
      head : 'BLE의 주요 특징',
      subHead :'저전력 소비',
      desc : 'BLE는 주로 배터리로 작동하는 디바이스에 적합하도록 설계되었습니다. 광고 모드에서는 낮은 전력으로 동작하며, 연결 시에도 최적의 전력 소비를 유지합니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'다양한 적용 분야',
      desc : 'BLE는 다양한 응용 분야에서 활용됩니다. 스마트홈, 스마트헬스케어, 스마트시티 등에서의 센서 네트워킹, 위치 기반 서비스, 실내 위치 추적 등 다양한 응용이 가능합니다.',
      dot : ''
    },
    {
      head : 'BLE의 적용 사례 : RTLS 기술',
      subHead :'실시간 위치 추적 시스템 (RTLS)',
      desc : 'BLE는 RTLS 기술에서 핵심적으로 활용되고 있습니다. RTLS는 물체나 개체의 실시간 위치를 추적하는 기술로, 다양한 산업 분야에서 활용되고 있습니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'물류 및 창고 관리',
      desc : '물류 및 창고에서는 BLE 태그를 사용하여 상품 또는 자산의 위치를 실시간으로 추적할 수 있습니다. 이를 통해 재고 관리, 물류 조달, 및 창고 내 이동 경로를 최적화하여 효율성을 향상시킬 수 있습니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'실내 위치 기반 서비스 (IPS)',
      desc : 'BLE는 IPS에서 중요한 역할을 합니다. 쇼핑몰, 공항, 박물관 등의 공공 장소에서 스마트폰 앱을 통해 방문자에게 실내 지도, 특별한 할인 정보, 또는 전시물 정보를 제공하는 데 사용됩니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'제조업 및 생산 공정 관리',
      desc : '제조업에서는 BLE를 사용하여 생산 공정 내에서 장비, 부품 또는 제품의 위치를 실시간으로 모니터링할 수 있습니다. 이로써 공정의 효율성을 높이고 장애 처리 시간을 최소화할 수 있습니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'건강관리 시스템',
      desc : '병원이나 의료 시설에서는 환자의 위치를 추적하고 의료 기기의 상태를 모니터링하기 위해 BLE를 사용합니다. 이는 응급 상황에서 신속한 대응과 환자 모니터링에 도움을 줄 수 있습니다.',
      dot : ''
    },
    {
      head : '전망',
      subHead :'',
      desc : 'BLE는 지속적으로 발전하며 더 많은 산업 및 생활 분야에 적용될 것으로 예상됩니다. 더 나은 보안 기능, 더 높은 전송 속도, 그리고 더 넓은 범위의 연결이 향후 BLE의 발전 방향 중 일부일 것입니다. 이러한 발전은 더욱 효율적이고 편리한 무선 통신 환경을 조성할 것으로 기대됩니다.',
      dot : ''
    },
  ],

  link : {
    page : 'rtls',
    numb : '1',
    product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.workerTracking.key1
        },
      {

      }

    ],
    solution : [
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/enterprise/manufacturing/asset'
      }
    ]

  }


}