import { FlexStyle } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { StyledHeading, StyledLabel, StyledParagraph } from "@components/Text";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface DetailProps {
  isOpen: boolean
}

/* 오른쪽 제품 사진과 텍스트 */
export const StyledItemBox = styled(FlexStyle) <{ isSelected: boolean }>`
  flex-direction: column;
  width : 100%
  
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  margin-top : 16px;

  align-items: flex-start; 


  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 2px solid #2A70F0;
      border-radius: 16px;
  `}


`



export const StyledItem = styled(FlexStyle) <DetailProps>`
  cursor: pointer;
  width : 100%;
  border-radius: ${({ isOpen }) => isOpen ? '16px 16px 0 0' : '16px'};
  padding : 24px;

  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }
`


export const StyledItemIcon = styled.img`
  width: 80px;
  height : 80px;
  border-radius: 16px;
  object-fit: contain;

`

export const StyledItemTextBox = styled.div`
  display : flex;
  flex-direction : column;
  margin-left : 16px;
  margin-top : 4px;
  margin-bottom : 4px;
  

`


export const StyledItemTextPriceBox = styled.div`
  margin-top : 4px;
  display : flex;
  flex-direction : row;
  aling-items:center;

`

export const StyledItemSoldOut = styled.div`
  padding: 4px 8px;
  background: #E9E9E9;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`


export const StyledNewItem = styled.div`
  padding: 4px 8px;
  background: rgba(0, 195, 125, 0.08);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
`


export const StyledOptionsBox = styled.div<{ gap: string }>`
  margin-top : 16px;

  display : flex;
  flex-direction : row;
  gap : 16px;
  justify-content: center;
  align-items: center;

  width : 100%;

  flex-wrap : wrap;

  ${({ gap }) => gap && `gap : ${gap}px;`}

`

export const StyledOptionsTextBox = styled.div<{ isSelected: boolean }>`
  text-align : center;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);

  flex : 1;
  
  min-width : 30%;
  
  padding : 12px 24px;

  &:hover {
    cursor : pointer;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    border-color: #2A70F0;
    color: #2A70F0;
  `}

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    min-width : 40%;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    min-width : 90%;
  }

`




export const StyledSpecBox = styled.div`
  width : 100%;
  padding : 0 24px;
`



export const StyledNoteDetail = styled(FlexStyle) <DetailProps>`
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  width : 100%;
  flex-direction : column;
  padding : 16px 0;
`


export const StyledNoteBox = styled.div`
  padding : 8px 0;
  display : flex;
  flex-direction : row;

`

export const StyledNoteIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin : auto 0;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;


export const StyledNoteText = styled(StyledParagraph)`
  color: #646464;
  margin-left : 8px;
`

export const StyledNoteFullText = styled(StyledParagraph)`
  color: #646464;
`



export const StyledItemDetail = styled(FlexStyle) <DetailProps>`
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  width : 100%;
  flex-direction : column;
  padding : 16px 0;
`

export const StyledAddBox = styled.div`
  width : 100%;
  display : flex;
  flex-direction : row;
  align-items : center;
  
  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-top : 8px;
  }

  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-top : 16px;
  }


`






export const StyledCheckBox = styled.img`
  border : 1px solid rgba(0,0,0,0.08);
  border-radius : 4px;
  width : 16px;
  height : 16px;
  object-fit : contain;


`




export const StyledAddImg = styled.img`
  width : 60px;
  height : 60px;
  margin-left : 16px;
  object-fit: cover;
  
`

export const StyledAddTextBox = styled.div`
  margin : 10px 0;
  margin-left : 16px;
  display : flex;
  flex-direction : column;
  
`

export const StyledAddTitle = styled(StyledHeading)`
  
`
export const StyledAddDesc = styled(StyledLabel)`
  color : #2A70F0;
  margin-top : 4px;
  cursor : pointer;
`


export const StyledAddPrice = styled(StyledLabel) <{ color: string }>`
  display : flex;
  align-items : center;
  right : 0;

  ${({ color }) => color && `color : ${color};`}
  
`



export const StyledCalcBox = styled(FlexStyle) <DetailProps>`

  border-top: 1px solid rgba(0, 0, 0, 0.08);
  display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  width : 100%;
  flex-direction : row;
  padding : 16px 0;


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    
  }

  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
  }



`

export const StyledPriceText = styled(StyledHeading) <{ pixel: string }>`

  ${({ pixel }) => pixel && `margin : ${pixel};`}

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    
  }

  @media (max-width: 800px) and (min-width: 765px) {
    margin : 0;
  }

  
  @media (max-width: 408px) {
    margin : 0;
  }

`


export const StyledPrice = styled(StyledHeading) <{ pixel: string }>`

  ${({ pixel }) => pixel && `margin : ${pixel};`}

  @media (max-width: 800px) and (min-width: 765px) {
    margin : 0;
  }

  @media (max-width: 408px) {
    margin : 0;
  }

`



export const StyledBtnBox = styled.div`
  display : flex;
  height : auto;
  justify-content: center;
  align-items : center;
  margin-left : auto;
  
  padding : 12px 16px;
  margin-right : 8px;
    
  background: #F8F8F8;
  border-radius: 12px;
  height : 56px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-left : 0;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-left : 0;
  }
`



export const StyledCountBtn = styled.div`
  cursor : pointer;
  border: none;
  
  padding : 8px;

  width: 32px;
  height: 32px;
  display : flex;
  
  object-fit : fit;

  align-items : center;
  justify-content : center;
  
`;


export const StyledCountDisplay = styled.div`
  width : 30px;
  display : flex;
  align-items : center;
`;


export const StyledCount = styled(StyledLabel)`
  text-align : center;
  align-items : center;
  margin : auto;
`;



export const StyledRequestBtnWrapper = styled.div`

  margin-left: auto;
  display : flex;
  flex-direction : row;
  gap : 8px;
  align-items : center;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-left : auto;
    margin-right : 0;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-left : auto;
    margin-right : 0;
  }


`


export const StyledBtnBoxLeftSide = styled.div`

  display: flex;


  
  @media (max-width: 800px) and (min-width: 765px) {
    height: 100px;
    flex-direction: column;
    display: flex;
    gap: 2px;
    justify-content: center;
  }


  
  @media (max-width: 408px) {
    height : 100px;
    flex-direction : column;
    display: flex;
    gap : 2px;
    justify-content : center;
  }

`;


//background: #2A70F0;
//color : #FFFFFF;
export const StyledRequestBtn = styled.div`
  cursor : pointer;
  border-radius: 12px;

  width : auto;
  height : 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 4px;
  
  color : #FFFFFF;
  
  background: #2A70F0;
  
`


export const StyledBuyBtn = styled.div`
  cursor : pointer;
  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 4px;

  margin-left: 8px;
  color : #000000;

  background: #F8F8F8;

`


export const StyledMainProductBox = styled.div`
  width : 100%
  background-color : red;  
  display: flex;
  flex-direction: row;
  align-items: center;  
  margin-top : 16px;
  padding : 8px 0;
`



export const StyledMainProductIcon = styled.img`
  width: 60px;
  height : 60px;
  border-radius: 16px;
  object-fit: contain;

`


export const PurchaseBtn = styled.div`
  display : flex;
  justify-content: center;
  align-items : center;

  height : 100%;
  margin-left : auto;
  margin-top : auto;
  margin-bottom : auto;

  padding : 4px;
    
  background: #F8F8F8;
  border-radius: 8px;
  max-height : 36px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
  }
`


export const PurchaseBox = styled.div`

    display : flex;
    height : auto;
    justify-content: center;
    align-items : center;
    margin-left : auto;

    padding : 4px;
      
    background: #F8F8F8;
    border-radius: 8px;
    height : 36px;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
      margin-left : 0;
    }

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
      margin-left : 0;
    }

`


export const StyledPurchseCount = styled.div`
  cursor : pointer;
  border: none;
  
  padding : 8px;

  width: 28px;
  height: 28px;
  display : flex;
  
  object-fit : fit;

  align-items : center;
  justify-content : center;
  
`;

export const CountBtn = styled.img<{ opacity: string, w: string, aRatio: string }>`
  ${({ opacity }) => opacity && `opacity : ${opacity};`}
  ${({ w }) => w && `width : ${w}px;`}
  ${({ aRatio }) => aRatio && `aspect-ratio : ${aRatio};`}
    
`;



export const StyledCountBtnBox = styled.div`
  cursor : pointer;
  display: flex;
  flex-direction : row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;  


`


export const PurchaseIcon = styled.div`

  margin : auto 0;
  cursor : pointer;
  border: none;
  width : 28px;
  height : 28px;

  padding : 6px;
  
  
  object-fit : contain;
  align-items : center;
  justify-content : center;
`;



export const AccessoryTitle = styled(StyledHeading)`
    margin : 4px;
    color : #818181;
`;