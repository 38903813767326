import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet: '| 智能物流中心：借助RTLS成为游戏改变者',
  desc: `要建设智能物流中心，RTLS技术至关重要。让我们探讨RTLS技术在物流行业中的作为游戏改变者所带来的功能。`,
  keywords: '大型物流仓库管理、物流中心管理、物流仓库管理、智能物流、库存管理、智能库存管理、物流仓库库存管理、大型物流仓库库存管理、ORBRO、实时位置跟踪、rtls、uwb、KongTech',

  ogTitle: '',
  ogDesc: '',
  ogSite: '智能物流中心：借助RTLS成为游戏改变者 :: ORBRO博客',
  ogUrl: 'https://orbro.io/cn/blog/logistics',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4Nn92GBAhAnqBNHPYNQDpB-QpINtYBr8NP1whgOrOeiJ231ayd-ir1pFXYQyPNn6ORiGuT9r9NH8WMwpUVVIJuDIDCcxjED8rHcdEkrjVEabQroYsK8zKZBFb5y7MqX52j_Hsy7L5ePRwrLj1l5AgzzhwwwRDy0SSWbba5oN-o3v4VK9ulJDIn-AlRzUvK-2_ExWeqxWtqjdDVGF780Q1Fyw9IIdObKFPnjkNtFBBQtMXHmKC4K3NY-PU75is1fvZGtz95FAVwQW8NW-iuI9-V6GgxYKoSSaE0VpltJTXpVeUwTA-55-8pGVLMV1jPeO1vGqDiJJsSQfFQE7xxXm6-UZvVDalr763kudRcjaH6dWNitPT2w4_qxEV3EivvyxvKRAKilLymJIzk-KgoGkU4hhuVrqpelXsjpiyAVrTRehdny-SFdJeuDk7TnnLXSFTf8rG1zgj4OOJcXrXqDmjNcKnSmD3gyrnY_7gEkAde2Vn2Wnm9hQewDJ1YDCx926J3unTLJApvy7uGKDbgGwZY0jfHbj3_EaB-5t5TBucsd2CukDZvZ27jfvLUUe1Wrr0-U21oag_JP6AeJNO-Thzp8fvPd1rmpOiKsPwlCOEdTn-aVu9hoAWByLe-OAQ_weFNJB2jqIOrRDEiEdsF2Ux_D4H4UY0gjcEi6EuIl2SaJ7GQEfGt0uQ1BSYXOm6_Ljse4BNu1hSdj9Iqtg7MstD1cwgxIVmXk20C1GudLlOL14w-EOHYMhOg-CdEf_6i7jOS4F-HZVe98fBNM8s4m5pjIUFngbQ9Ig158BQ2ZwJB2t05BgqvZDYMA33z6z0iOiRAnmT6J9sYM2yx4itS08KP1CgjFduRqis5fJcg9eFZuZJf5Hfd-vWgWD6fl5ssh6du1AEaEBLfbbmiNhDihCB-M0jHlLuDCL-wWrFKTCo3ylcA89Xivl7Hca0rY3s6JW_VfSwboGpYKJnMKpZqynEuHGMXoYXQ3jFpIQPj2xOElK8OLo1o3oQdjWPzTb4vUKBHiBzM6qkfsUZAL4b-zqcL2KxE1jgAyweOBCYpp3CdQ7LCgDO6I5VfS8lzcNBPnPq8_5OkN_r6xGcemqmtbzIIFQonHkEIYSOl0km6axb9eXkmCacj_0lr5oyGA8xGjLFN85rAsznmLomsfvDmvk8qx1fT8OQGW0Fsqgeeoujl6HOsXsGEVr-4dP1EupFbf4WR-v8GPCBN-c9FSQ7472yBB8ES-QlDo-xEP0xB8bn0ogShH5PCnzG3aQ1Y8fpAK_nzqUPpkZDW0HEUKQw6shyWu9MqPLKD1IbYuzWo_9Vba83qhFFvye5IxqyGiZr2SG2wZpmu1kWNjSG89Br3ZMTKQ2XSVpURse0EKs4abuIUAjtf7O1t3PVWF1Hm8R2Pllgb36whs0ZsXEgvM3gAghdJ_T8ydCqOD76BitUXjI_oV_2cn7KlbYyMsT90CHLr6NrT0ZW3B0s-hDXnGSiwvDM7ZCywF8XguZFj2X3konPXb1o1blj-A6LDR-ROdDTdsc9jBotrseWFE7qTdeVNs92ZxvaiWGvziDNdVARHQAwQIY5krEfq8B6DpkghJNg=w2194-h1199',
  ogType: '网站',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '智能物流中心：借助RTLS成为游戏改变者',
  date: '2023-09-04',
  tag1: '#大型物流中心',
  tag2: '#智能物流',
  tag3: '#库存管理',
  tag4: '#物流管理',
    
  text: [
    {
      head: '',
      subHead: '',
      desc: `智能物流中心在效率和生产力方面与传统物流行业有显著的不同。通过高效管理资产、库存和劳动力，智能物流中心的建设对于业务增长和竞争力至关重要。要实现这一点，需要采用实时位置系统（RTLS）技术。 <br/> 让我们探讨实时位置跟踪解决方案引入智能物流中心后可能带来的改变。`,
      dot: ''
      
    },
    {
      head: '',
      subHead: '1. 资产管理和跟踪的优化',
      desc: 'RTLS技术用于实时跟踪物流中心内的所有资产和设备。这使得物流中心管理人员可以准确确定资产的位置和状态。例如，通过实时跟踪货车或叉车的位置，可以优化资产利用率，并在需要时有效规划维护，从而降低资产管理成本并延长资产寿命。',
      dot: ''
      
    },
    {
      head: '',
      subHead: '2. 增强库存管理的准确性',
      desc: '通过RTLS，可以实时跟踪产品和库存的位置。这使库存管理发生了革命性的变化。精确了解产品在物流中心的位置将最小化库存损失并优化库存周转。这将带来成本节省和提高效率。',
      dot: ''
      
    },
    {
      head: '',
      subHead: '3. 实时数据分析',
      desc: 'RTLS生成实时数据，使物流中心操作可以实时监测和优化。如果物流中心内的任何过程出现瓶颈，管理员可以立即采取措施解决问题。这也对客户服务产生积极影响。',
      dot: ''
      
    },
    {
      head: '',
      subHead: '4. 劳动力优化',
      desc: 'RTLS还有助于劳动力管理。通过跟踪工人的动向和工作时间，可以提高工人的效率，并优化工作时间表。在物流中心高效部署工人并使用RTLS调整任务可以节省劳动力成本并提高生产力。',
      dot: ''
      
    },
    {
      head: '',
      subHead: '5. 提高客户服务',
      desc: 'RTLS在订单处理和交付过程中发挥关键作用。通过跟踪产品的实时位置，可以提供准确的预计到达时间给客户，提高客户服务的质量。客户会得到产品何时到达的信息，从而提高满意度。',
      dot: ''
      
    },
    {
      head: '',
      subHead: '6. 增强安全性',
      desc: 'RTLS增强了物流中心的安全性。通过准确跟踪设备和资产的位置，可以防止未经授权的侵入和库存盗窃。设备的定期检查和维护也有助于确保安全。',
      dot: ''
      
    },
    {
      head: '',
      subHead: '7. 增强环保性',
      desc: '智能物流中心可以提高能源和资源效率。RTLS有助于优化资源使用并减少能源消耗。例如，在物流中心内自动调整照明和机械设备可以优化能源消耗。',
      dot: ''
      
    },
    {
      head: '',
      subHead: '',
      desc: 'RTLS技术正在推动物流和供应链管理领域的创新。通过在智能物流中心中实施RTLS技术，可以实现成本节省、效率提高、客户服务质量提高和环保性增强等各种优势。这些优势增强了竞争力，并提供了更有效的物流和供应链管理机会。因此，有意设立智能物流中心的公司应积极考虑RTLS技术。',
      dot: ''
      
    },

  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: '通过为工人或设备安装UWB终端来支持实时位置跟踪的产品。',
        img: productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution: [
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      },
      {
        img: logisticsSafety.thumb,
        data: 'logisticsSafety',
        link: '/cn/enterprise/logistics/safety'
      }, 
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/cn/enterprise/manufacturing/asset'
      }
    ]

  }


}
