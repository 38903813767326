import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4';

export default {
    desc: 'A smart building utilizes technologies such as IoT sensors, artificial intelligence, and automation systems to connect and integrate various systems within the building, optimizing building operations. This enhances energy efficiency and improves the safety, convenience, and efficiency of the building.',
    keywords: 'Smart building, RTLS, IoT, digital twin, digital transformation',

    ogSite: 'What is a Smart Building? | Digital Twin Platform',
    ogUrl: 'https://orbro.io/en/blog/smart-buildings',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmNclLyXvFXAppGJYrqUeCnj1ItMcBFHWPvVUo5mAAclWqqXT2c7O3kn8SC3hs6LiHgOw7HC2-KUOHZpCjEK7FnzMSWAna_xAxwzmLzRZvzyR4y1i-8TbhmLBCUAAEfPsa5GgyVj98AunnYrTGFbIebivjQFTuGcC2LCdg_GLJkED01NGjfucz8TKJjCj8Gxa3QnH2ZcipuWUhWVUHPvgzHZe-w_TxeDOeywhDMKI9JWA2QiUM3pBtOpFLqmbd_dl7WMBDD_lw7xutd_UJTam_iuLegrr12cuEQJittPjU2a4YQ5SAqRcKbatpWFDrDsthws-65VTYSGgffeaODxqhQccffxpsoZpNQ9z5y6dydLbm02KFtQyjGQVkVztr00IBlvROvpALKGf8X4W1ZGeg7zEvsV8kyP084k_hUI6MWh6wqkVnF7SSV17RYtyumC1lq5VI-JdGn_YuyA7BZl4_Sgz8_fgeNXiC8z0qifLgrN2sczIe0CffI8piaJcUPxk8ADXBieKFniP5f3dG-AMDN8STr-B2nwFB_CNY7UDz76_s3FmVEnQTTU7MFqF5px-0ZgJhAJhWrOpAY93WGxfVRThfuMY1iGn-6_uuf6IrNAv5Cy7aGvagtrsi7F_UfGjbVbbB4bxQbcV-UqIHZP5wcgNU1JIW4Bztfjw5QUTidafK0ARwixIms3w1Dry4-O0KV-eksKbVrMtPAcL4GiiBaiuU-mpMDiT_c0yf2rCBeMOK_bAmkp7i8lrRMUQC4xYvav6-BDs8r3iuHEbvyBhO7i9Dlq1d5yT40AfmLvRs0lfU9S1d6_ltRRv3sNgUcuUiNxAMwHkco_LDoBxGg21-QCK6CahMJws-V4L60V7M-dRsIuhkIvCs0D7tSFmhBLMd33XEbOE0Jy79l0AgNMG0ChJPg8dvdSjBQ3ep_vAjQTgxJ_vWTy05yCwl6mFTl1zJWxcyjydP40t8D9SdAIQFXOXsxOaOGkauQGkO8t9igDMGIFCQWRJBW4JDC77EmIG3xHmhUnOZTUOmk2Y13SsSePHqLvXpEImCh-lG_Ehp5TgbK_mBfUUDOpZqSnEc3lHGC4z-9XMeOIGWYX689D07wskrtyNdEzShvTXWgrEPopU8c-D2XFw8X89i4f9wlVJ-Egn29eAhBCw4XaLwAA7_LUOXEVwlhYb7DmYxADRH_zXMl9gZE9_UJ_LkymE5ZoEMDT2mTv7cEUK9LPYkEBRmViKwxiFHwx2coDptiwVoR4-mLvPA7hzxRe7NjcreMJ6BEtpvhi4DwXeosAyHVQxd8R_Am7rw5Dtt5eap7Oca07-DDGWzSUzvpzt2Hde-_3NmrBxpOditdoDCuddXrXvyKByMIQmWnRtvIVhGgnGMjvYAkwNJBrd-1qxORsRMbhiBGX_l8HzgAYb6vuHjiaBhbo4a5_F-whRFJW1rO919s5ItElRg8Y6R3xI3xtKl3tza9Al_rKSvZ8lkiv44cUZ6a_rO8Zmi97pNd_aEgj1JhawhSHN3WGHkf7v5TdRC0CAZaxfDUrA4XupbrYuT1lZN7rnd_iIWvTEG2cFR08BcGyw0R9Yg=w1701-h1166',
    ogType: 'website',

    title: 'What is a Smart Building?',
    date: '2024-01-30',
    tag1: '#SmartBuilding',
    tag2: '#IoT',
    tag3: '#LocationTracking',
    tag4: '#DigitalTwin',

    text: [
        {
            head: 'What is a Smart Building?',
            subHead: '',
            desc: 'A smart building utilizes technologies such as IoT sensors, artificial intelligence, and automation systems to connect and integrate various systems within the building, optimizing building operations. This enhances energy efficiency and improves the safety, convenience, and efficiency of the building. Through these technologies, buildings can collect data in real-time and automatically adjust various facilities such as lighting, heating, cooling, and security systems. Smart buildings enhance energy efficiency, optimize energy consumption, and operate environmentally and economically. They enhance user convenience and streamline building operations and management to provide a better living and working environment.',
            dot: ''
        },
        {
            head: 'Key Technologies of Smart Buildings',
            subHead: 'Sensor Technology',
            desc: 'Smart buildings use various sensors to monitor the interior and exterior environment of the building in real-time. These sensors detect temperature, humidity, lighting, carbon dioxide levels, particulate matter levels, movement, and collect data. In addition, through the connection with sensor data and other devices, smart buildings enable real-time information exchange and control. Using IoT technology, smart buildings can integrate various systems and devices for efficient operation.',
            dot: ''
        },
        {
            head: '',
            subHead: 'RTLS (Real-Time Location System)',
            desc: 'RTLS technology allows real-time tracking of equipment, inventory, and supplies within a smart building. This provides opportunities to quickly detect equipment damage, loss, and the need for maintenance. In case of emergencies within the smart building, RTLS technology can guide evacuation directions and track the location of all objects and individuals within the building, helping rescue workers and emergency response teams to respond efficiently.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Artificial Intelligence (AI)',
            desc: 'Artificial intelligence technology is used to improve the automation systems and big data analysis of smart buildings. AI helps adjust systems in real-time based on changing data and optimize them according to user requirements.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Big Data Analysis',
            desc: 'Collected data is used for pattern recognition and developing predictive models using big data analysis technology. This allows optimizing building operations and early detection of problems.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Digital Twin',
            desc: 'A digital twin is a digital replica of the physical model of a building. It is implemented on a digital screen with the same appearance as the building, making it easy to understand and manage. This digital model supports building operations and maintenance, reflecting the real-time state of the building to diagnose and predict issues.',
            dot: ''
        },
        {
            head: 'Advantages of Smart Buildings',
            subHead: '',
            desc: 'Smart buildings offer various advantages through the integration of modern architectural and information communication technologies. These advantages manifest in various aspects such as building operations, efficiency, convenience, and environmental protection.',
            dot: {
                a: '<strong> • Improved Energy Efficiency:</strong> Smart buildings optimize energy consumption through sensors and automation systems. For example, lighting, heating, and cooling systems are adjusted based on real-time data, reducing energy consumption and enhancing energy efficiency. Additionally, using big data analysis and artificial intelligence, predictive maintenance and energy usage predictions can be performed to save energy costs.',
                b: '<strong> • Environmentally Friendly:</strong> Increased energy efficiency reduces greenhouse gas emissions. Smart buildings monitor and manage fine dust and air pollution, improving air quality and creating a healthy environment.',
                c: '<strong> • Cost Savings:</strong> Improved energy efficiency leads to reduced energy costs, and automation systems help save labor costs and optimize maintenance and operation costs.',
                d: '<strong> • Convenience and Safety:</strong> Smart buildings enhance user convenience by automatically adjusting lighting, heating, cooling, security systems, and more, providing a comfortable building experience. Security systems monitor the interior and exterior environment of smart buildings, preventing unauthorized intrusion and preparing for emergencies.',
                e: '<strong> • Real-time Monitoring and Management:</strong> Smart buildings provide real-time data and alerts to building operators, helping to resolve issues more quickly. Therefore, the health of the building can be monitored, and maintenance schedules can be optimized.',
            }
        },
        {
            video: video,
            link: '/en/enterprise/logistics/asset',
            head: 'Smart Building Examples',
            subHead: 'The Edge, Amsterdam, Netherlands',
            desc: 'The Edge is an innovative smart building located in Amsterdam, Netherlands, and is considered one of the most energy-efficient buildings. Hundreds of sensors are installed inside the building to optimize lighting, heating, and cooling based on user location and activity. Real-time data and big data analysis are used to manage energy consumption and provide various services to employees to enhance convenience.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Microsoft Campus, Redmond, USA',
            desc: 'Microsoft Campus uses smart building and smart facility management technology to manage buildings and facilities. This building optimizes heating, cooling, lighting, and water management using IoT sensors and big data analysis, reducing energy costs. This enhances user convenience and safety by providing building services to employees and enhancing security systems.',
            dot: ''
        },
        {
            head: '',
            subHead: 'One Angel Square, Manchester, UK',
            desc: 'One Angel Square is the headquarters of the Co-operative Group in the UK and actively utilizes smart building technology. This building integrates geothermal, solar, and eco-friendly energy systems for high energy efficiency, optimizing building temperature and environment. It uses sensors inside and outside the building to collect real-time data and manages building operations through big data analysis.',
            dot: ''
        },
        {
            head: 'Smart Building Outlook',
            subHead: '',
            desc: 'Future smart buildings will emphasize energy efficiency and enable optimized operations through the use of big data and artificial intelligence. With the proliferation of IoT technology and sensor networks, real-time monitoring and control will be possible, maximizing user convenience. Additionally, having a robust security system will provide a safer environment. Smart buildings will play a central role in future cities and buildings, contributing to improved energy efficiency and environmental friendliness. These features will innovate building operations and user experiences.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'A product that assists in real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/en/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/en/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/en/enterprise/construction/equipment'
            }
        ]
    }
}
