import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconUserCheck, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { IconTemp } from "@assets/images/main";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/medicalWaste/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/medicalWaste/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/medicalWaste/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/medicalWaste/video/sub/rtlsVideo3.mp4";



export default {
    banner: {
      title: '医療廃棄物管理',
      desc: '医療廃棄物管理は、医療施設で発生するさまざまな廃棄物を安全かつ適切に処理し、環境汚染を予防し感染リスクを最小限に抑え、法的規制を遵守して人間の健康と資源保護に貢献する必要不可欠な作業です。医療廃棄物のリアルタイムの状況と位置を測位することで、医療事故を予防し安全な医療サービスを提供することが可能になります。',
    },
    video: {
      title: '最も信頼性の高い医療廃棄物管理方法',
      desc: 'リアルタイム位置測位システム（RTLS）技術は、医療廃棄物の状況と位置を測位します。安全規制を遵守しながら、医療廃棄物の正確かつ効果的な管理を実現します。',
      play: video,
    },
    func: [
      {
        title: 'リアルタイムの廃棄物状況モニタリング',
        desc: '医療廃棄物の種類と数量のリアルタイムモニタリングにより、廃棄物の量と処理期間を予測し、管理することができます。',
        sub1: '高い精度を提供',
        icon1: IconRulerGray,
        sub2: '高速データ統合',
        icon2: IconShareGray,
        video: subVideo1,
      },
      {
        title: 'リアルタイムの移動状況モニタリング',
        desc: '医療廃棄物は特別な管理が必要であり、医療従事者や患者との接触時にリアルタイムに測位し、アラートを送信することで事前に医療事故を防止し、患者に信頼感を与えることができます。',
        sub1: '危険資産の指定',
        icon1: IconWarningGray,
        sub2: '対応方法の設定',
        icon2: IconBellGray,
        video: subVideo2,
      },
      {
        title: '自動入出庫管理',
        desc: '医療廃棄物の自動入出庫管理により、効率的な運用を実現し、人為的なエラーを減らし、リアルタイムな対応能力を向上させ、安全な廃棄物処理を確保し、規制の遵守を実現します。',
        sub1: '高い精度を提供',
        icon1: IconRulerGray,
        sub2: '高速データ統合',
        icon2: IconChainLinkGray,
        video: subVideo3,
      },
    ],
    subfunc: {
      title: 'さまざまな機能',
      desc: '医療廃棄物管理は最後まで注意が必要であり、わずかな油断やミスでも重大な医療事故につながる可能性があります。そのため、正確な管理と保証された安全性が重要です。ORBROは、安全で効率的な医療施設を構築するためにさまざまな機能を提供しています。',
      data: [
        {
          icon: IconChartRadius,
          title: 'レポートと分析',
          desc: '廃棄物の流入および流出データに基づいて統計レポートを作成し、データ分析を行うことができます。',
        },
        {
          icon: IconBell,
          title: 'リアルタイムアラート機能',
          desc: '患者が高リスクの医療廃棄物に接触した場合、即座にアラートを送信して対応することができます。',
        },
        {
          icon: IconRoute,
          title: '廃棄物の移動経路測位',
          desc: '医療廃棄物の経路を測位し、安全事故を予防します。',
        },
        {
          icon: IconDashboard,
          title: '状況の把握',
          desc: '医療廃棄物保管倉庫内の廃棄物の状況をリアルタイムに監視します。',
        },
        {
          icon: IconTemp,
          title: '環境分析',
          desc: '医療廃棄物保管施設内の温度や湿度などの環境要因をモニタリングし、異常をリアルタイムに検知することができます。',
        },
        {
          icon: IconUserCheck,
          title: '効率的なワークフロー向上',
          desc: '自動入出庫システムにより、効率的な作業と人為的なエラーの削減が可能です。',
        },
        {
          icon: IconChainLink,
          title: 'データ統合',
          desc: 'RFID、IoT、セキュリティ、ビデオなどのシステムを統合し、データを効果的に活用します。',
        },
        {
          icon: IconSecurity,
          title: 'データセキュリティ',
          desc: '高いデータセキュリティレベルを維持し、信頼性の高いソリューションを提供します。',
        },
      ],
    },
    system: {
      title: 'システム構成',
      img: rtlsSystem,
    },
    productTitle: '製品構成',
    product: [
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'medicalPatient',
      },
      {
        data: 'officeAsset',
      },
      {
        data: 'blogMedical',
      },
      {
        data: 'blogOffice',
      },
    ],
  }
  