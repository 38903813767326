export default {


    
    enterprise: '企业',
    supportTitle: '支持',
    start: '开始',


    support: {
        technology: '技术',
        blog: '博客',
        request: '销售咨询',
        company: '公司介绍'
    }

}