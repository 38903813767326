import seo from "@src/lang/en/seo";

import navMenu from "@src/lang/en/navMenu";
import simple from "@src/lang/en/simple";
import nav from "@src/lang/en/nav";
import home from "../lang/en/main/home/home";
import pricing from "@src/lang/en/pricing/pricing";
import digitaltwin from "@src/lang/en/digitaltwin/digitaltwin";

import accessF1 from "../lang/en/main/access/accessF1";
import accessMain from "../lang/en/main/access/access";
import productSchemeAccess from "@src/lang/en/main/access/productScheme";

import iot from "@src/lang/en/main/iot/iot";

import os from "@src/lang/en/main/os/os";
import rtls from "@src/lang/en/main/rtls/rtls";
import camera from "@src/lang/en/main/camera/camera";
import sensor from "@src/lang/en/main/sensor/sensor";
import beacon from "@src/lang/en/main/sensor/sensor";
import integration from "@src/lang/en/main/integration/integration";

import video from "@src/lang/en/main/video/video";

import productSchemeRTLS from "@src/lang/en/main/rtls/productScheme";
import productSchemeCamera from "@src/lang/en/main/camera/productScheme";

import productImg from "@src/lang/en/main/product/productImg";
import productPurchase from "@src/lang/en/main/product/productPurchase";
import productSpec from "@src/lang/en/main/product/productSpec";


import layout from "../lang/en/layout.json";
import layoutEnterprise from "../lang/en/layoutEnterprise.json";
import platform from "@src/lang/en/platform";

import company from "../lang/en/company";

//technology
import technology from "../lang/en/technology/technology";
import uwb from '../lang/en/technology/uwb';
import ble from "../lang/en/technology/ble";
import tdoa from "../lang/en/technology/tdoa";
import twr from "../lang/en/technology/twr";
import stereo from "../lang/en/technology/stereo";
import monocular from '../lang/en/technology/monocular';
import whatisdigitaltwin from '../lang/en/technology/whatisdigitaltwin';
import whatisgeofence from '../lang/en/technology/whatisgeofence';

//request
import request from "../lang/en/request";
import download from '../lang/en/download';



import { MainMenus } from '../layouts/Default/core/model';

import navEntSupport from "@src/lang/en/navEntSupport";
import navSupport from "@src/lang/en/navSupport";


import footer from "@src/lang/en/footer";


import solutionProduct from "@src/lang/en/enterprise/solutionProduct";
import solutionCommon from "@src/lang/en/enterprise/solutionCommon";
import solutionContents from "@src/lang/en/enterprise/solutionContents";

import constructionSafety from "@src/lang/en/enterprise/construction/constructionSafety";
import constructionAsset from "@src/lang/en/enterprise/construction/constructionAsset";
import constructionEquipment from "@src/lang/en/enterprise/construction/constructionEquipment";

import logisticsAsset from "@src/lang/en/enterprise/logistics/logisticsAsset";
import logisticsSafety from "@src/lang/en/enterprise/logistics/logisticsSafety";

import officeAsset from "@src/lang/en/enterprise/office/officeAsset";

import manufacturingProcess from "@src/lang/en/enterprise/manufacturing/manufacturingProcess";
import manufacturingAsset from "@src/lang/en/enterprise/manufacturing/manufacturingAsset";

import medicalPatient from "@src/lang/en/enterprise/medical/medicalPatient";
import medicalWaste from "@src/lang/en/enterprise/medical/medicalWaste";

import retailExhibition from "@src/lang/en/enterprise/retail/retailExhibition";


//blog
import blog from '../lang/en/blog/blog';
import blogUwb from "../lang/en/blog/blogUwb";
import blogDigitalTwin from "../lang/en/blog/blogDigitalTwin";
import blogRTLS from "../lang/en/blog/blogRTLS";

import blogGeofence from "../lang/en/blog/blogGeofence";
import blogMedical from "../lang/en/blog/blogMedical";
import blogUwbble from "../lang/en/blog/blogUwbble";
import blogAirport from "../lang/en/blog/blogAirport";
import blogManufacture from "../lang/en/blog/blogManufacture";
import blogActiveRfid from "../lang/en/blog/blogActiveRfid";

import blogForklift from "../lang/en/blog/blogForklift";
import blogConsider from "../lang/en/blog/blogConsider";

import blogOffice from "../lang/en/blog/blogOffice";
import blogSmartConstruction from "@src/lang/en/blog/blogSmartConstruction";
import blogSmartLogistics from "@src/lang/en/blog/blogSmartLogistics";
import blogPatientTracking from "@src/lang/en/blog/blogPatientTracking";
import blogVisitor from "@src/lang/en/blog/blogVisitor";
import blogHospital from "@src/lang/en/blog/blogHospital";
import blogMedicalWaste from "@src/lang/en/blog/blogMedicalWaste";
import blogLogistics from "@src/lang/en/blog/blogLogistics";
import blogUwbGeofence from "@src/lang/en/blog/blogUwbGeofence";
import blogUwbLogistics from "@src/lang/en/blog/blogUwbLogistics";
import blogLogisticsWorker from "@src/lang/en/blog/blogLogisticsWorker";
import blogLogisticsAsset from "@src/lang/en/blog/blogLogisticsAsset";
import blogLogisticsTech from "@src/lang/en/blog/blogLogisticsTech";
import blogConstructionSafety from "@src/lang/en/blog/blogConstructionSafety";
import blogHeavyEquipment from "@src/lang/en/blog/blogHeavyEquipment";
import blogSmartSafety from "@src/lang/en/blog/blogSmartSafety";
import blogSmartFactory from "@src/lang/en/blog/blogSmartFactory";
import blogNextManufacturing from "@src/lang/en/blog/blogNextManufacturing";
import blogDigitalTwin2 from "@src/lang/en/blog/blogDigitalTwin2";
import blogOfficeSolution from "@src/lang/en/blog/blogOfficeSolution";
import blogDX from "@src/lang/en/blog/blogDX";
import blogRtlsHealthcare from "@src/lang/en/blog/blogRtlsHealthcare";
import blogUwbRtls from "@src/lang/en/blog/blogUwbRtls";
import blogProductionAutomation from "@src/lang/en/blog/blogProductionAutomation";
import blogBeacon from "@src/lang/en/blog/blogBeacon";
import blogRfidNfcUwb from "@src/lang/en/blog/blogRfidNfcUwb";
import blogRtlsCompany from "@src/lang/en/blog/blogRtlsCompany";
import blogNfcUwbBle from "@src/lang/en/blog/blogNfcUwbBle";
import blogRfidRtls from "@src/lang/en/blog/blogRfidRtls";
import blogConstructionSolution from "@src/lang/en/blog/blogConstructionSolution";
import blogQaUwb from "@src/lang/en/blog/blogQaUwb";
import blogDigitalTwinGis from "@src/lang/en/blog/blogDigitalTwinGis";
import blogDigitalTwinWeather from "@src/lang/en/blog/blogDigitalTwinWeather";
import blogDigitalTwinBuildings from "@src/lang/en/blog/blogDigitalTwinBuildings";
import blogDigitalTwinTracking from "@src/lang/en/blog/blogDigitalTwinTracking";
import blogDigitalTwinTraffic from "@src/lang/en/blog/blogDigitalTwinTraffic";
import blogDigitalTwinAirplane from "@src/lang/en/blog/blogDigitalTwinAirplane";
import blogDigitalTwinCCTV from "@src/lang/en/blog/blogDigitalTwinCCTV";
import blogDigitalTwinDigitalCity from "@src/lang/en/blog/blogDigitalTwinDigitalCity";
import blogDigitalTwin3 from "@src/lang/en/blog/blogDigitalTwin3";
import blogBLE from "@src/lang/en/blog/blogBLE";
import blogWhatIsSmartCity from "@src/lang/en/blog/blogWhatIsSmartCity";
import blogExampleOfSmartCity from "@src/lang/en/blog/blogExampleOfSmartCity";
import blogContainerTracking from "@src/lang/en/blog/blogContainerTracking";
import blogWhatIsDX from "@src/lang/en/blog/blogWhatIsDX";
import blogRFID from "@src/lang/en/blog/blogRFID";
import blogNFC from "@src/lang/en/blog/blogNFC";
import blogNVR from "@src/lang/en/blog/blogNVR";
import blogSmartFactory2 from "@src/lang/en/blog/blogSmartFactory2";
import blogLoRa from "@src/lang/en/blog/blogLoRa";
import blogSmartHospital from "@src/lang/en/blog/blogSmartHospital";
import blogSmartAirport from "@src/lang/en/blog/blogSmartAirport";
import blogIPS from "@src/lang/en/blog/blogIPS";
import blogSmartParking from "@src/lang/en/blog/blogSmartParking";
import blogSmartPort from "@src/lang/en/blog/blogSmartPort";
import blogSmartBuildings from "@src/lang/en/blog/blogSmartBuildings";
import blogIndoorGPS from "@src/lang/en/blog/blogIndoorGPS";
import blogAiCamera from "@src/lang/en/blog/blogAiCamera";
import blogAIoT from "@src/lang/en/blog/blogAIoT";
import blogSmartShipyard from "@src/lang/en/blog/blogSmartShipyard";



const en = {
  pricing,
  navMenu,
  digitaltwin,
  constructionSafety,
  constructionAsset,
  constructionEquipment,

  logisticsAsset,
  logisticsSafety,

  manufacturingProcess,
  manufacturingAsset,

  medicalPatient,
  medicalWaste,

  officeAsset,

  retailExhibition,

  solutionProduct,
  solutionCommon,
  solutionContents,

  footer,
  home,
  simple,
  nav,
  accessF1,
  accessMain,

  productSchemeAccess,
  productSchemeCamera,
  iot,
  os,
  rtls,
  camera,
  sensor,
  beacon,
  integration,

  video,

  productSchemeRTLS,
  productImg,
  productPurchase,
  productSpec,
  layout,
  navEntSupport,
  navSupport,
  MainMenus,
  seo,
  layoutEnterprise,
  platform,

  company,

  technology,
  uwb,
  ble,
  tdoa,
  twr,
  stereo,
  monocular,
  whatisdigitaltwin,
  whatisgeofence,

  request,
  download,


  blog,
  blogUwb,
  blogDigitalTwin,
  blogRTLS,
  blogGeofence,
  blogMedical,
  blogUwbble,
  blogAirport,
  blogManufacture,
  blogActiveRfid,
  blogForklift,
  blogConsider,
  blogOffice,
  blogSmartConstruction,
  blogSmartLogistics,
  blogPatientTracking,
  blogVisitor,
  blogHospital,
  blogMedicalWaste,
  blogUwbGeofence,
  blogLogistics,
  blogUwbLogistics,
  blogLogisticsWorker,
  blogLogisticsAsset,
  blogLogisticsTech,
  blogConstructionSafety,
  blogHeavyEquipment,
  blogSmartSafety,
  blogSmartFactory,
  blogNextManufacturing,
  blogDigitalTwin2,
  blogOfficeSolution,
  blogDX,
  blogRtlsHealthcare,
  blogUwbRtls,
  blogProductionAutomation,
  blogBeacon,
  blogRfidNfcUwb,
  blogRtlsCompany,
  blogNfcUwbBle,
  blogRfidRtls,
  blogConstructionSolution,
  blogQaUwb,
  blogDigitalTwinGis,
  blogDigitalTwinWeather,
  blogDigitalTwinBuildings,
  blogDigitalTwinTracking,
  blogDigitalTwinTraffic,
  blogDigitalTwinAirplane,
  blogDigitalTwinCCTV,
  blogDigitalTwinDigitalCity,
  blogDigitalTwin3,
  blogBLE,
  blogWhatIsSmartCity,
  blogExampleOfSmartCity,
  blogContainerTracking,
  blogWhatIsDX,
  blogRFID,
  blogNFC,
  blogNVR,
  blogSmartFactory2,
  blogLoRa,
  blogSmartHospital,
  blogSmartAirport,
  blogIPS,
  blogSmartParking,
  blogSmartPort,
  blogSmartBuildings,
  blogIndoorGPS,
  blogAiCamera,
  blogAIoT,
  blogSmartShipyard

};

export default en;

