import { carTracking1, peopleTracking1 } from "@assets/images/main/camera";
import { assetTracking1, workerTracking1 } from "@assets/images/main/rtls/product";

export default {


    assetPackage : {
        label : 'Assets Tracking Package',
        title : '资产定位套餐',
        product : 'TwinTracker 10 + Server 1',
        desc : '通过将BLE设备附加到工人或设备上，提供实时定位的产品。',
        img : assetTracking1,
        link : '/cn/rtls',
        index : '1'
    },

    workerPackage : {
        label : 'Workers Tracking Package',
        title : '工人定位套餐',
        product : 'TwinTrackerPro 10 + Server 1',
        desc : '通过将UWB设备附加到工人或设备上，提供实时定位的产品。',
        img : workerTracking1,
        link : '/cn/rtls',
        index : '2'
    },


    carPackage : {
        label : 'Vehicle Location Tracking Package',
        title : '车辆定位套餐',
        product : 'Server1 + NVR1 + PTZ Camera2',
        desc : '通过摄像头检测和管理人员、车辆等多种对象的位置和异常行为。',
        img : carTracking1,
        link : '/cn/camera',
        index : '1'
    },


    peoplePackage : {
        label : 'People Tracking and Analysis Package',
        title : '人员定位与分析套餐',
        product : 'Server1 + NVR1 + IP Camera4',
        desc : '通过摄像头分析室内人员的移动路径和检测突发事件。',
        img : peopleTracking1,
        link : '/cn/camera',
        index : '2'
    },

}