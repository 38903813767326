import { FlexStyle } from '@assets/styled/flex';
import { css } from '@emotion/react';
import styled from '@emotion/styled';


interface DetailProps {
  isOpen: boolean
}


export const StyledBackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  z-index: 9998; // 팝업보다 낮은 z-index 값
`;


export const StyledMobileNav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 0 16px;
  background-color: ${({ theme }) => (theme.BACKGROUND_TRANSLUCENT)};
  backdrop-filter: blur(50px);
  z-index: 9998;
  & * {
    font-family: Pretendard !important;
    font-display: swap;
  }
  
background: rgba(0, 0, 0, 0.7);
backdrop-filter: blur(25px);
overflow-y: auto;
padding-bottom : 32px;
`;

export const StyledMobileNavHeader = styled(FlexStyle)`
  width: 100%;
  height: 52px;
  align-items: center;
  justify-content: space-between;
`;

export const StyledMobileNavIconBox = styled.div`
  cursor: pointer;
`;


export const StyledMobileNavBody = styled.div`
  
    width: 100%;
    margin-top: 14px;
    position: relative;

`;


export const StyledNavBox = styled.div<{ isLastItem: boolean }>`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    flex-direction: column;
    width : 100%;
    height : auto;



  ${({ isLastItem }) =>
  isLastItem &&
  css`
    border-bottom: none;
  `}
`


export const StyledNavTitle = styled(FlexStyle)`
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width : 100%;
  display : flex;
  flex-direction : row;
  padding: 16px;
`



export const StyledTitleIcon = styled(FlexStyle)`
  width: 16px;
  height: 16px;
  user-select: none;
  justify-content: center;
  align-items: center;
  right : 0;
  object-fit : contain;

`


export const StyledNavBody = styled.div`

    background: rgba(255, 255, 255, 0.06);
    border-radius: 12px;
`





export const StyledNavDetail = styled(FlexStyle)<DetailProps>`
  display: ${({isOpen}) => isOpen ? 'flex' : 'none'};

  width : 100%;
  padding : 16px 32px;
  cursor : pointer;
`




export const StyledNavBottom = styled.div`
  margin-top : 16px;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 12px;
`


export const StyledLangBox = styled.div`
  display : flex;
  flex-direction : row;
  align-items : center;

`

export const StyledIcon = styled.img`
  width : 16px;
  object-fit : contain;
  margin-right : 10px;
`



export const StyledLogo = styled.div`
  display : flex;
  justify-content : center;
  object-fit : contain;
`



export const NavDepthLayout = styled.div`

  position: fixed;

  height : fix-content;
  width : calc(100% - 32px);
  background-color: yellow; /* 배경색은 원하는 색상으로 설정 */
  background: rgba(255, 255, 255, 0.06);
  border-radius: 12px;
  transition: width 0.3s ease; /* 애니메이션 효과 추가 */
  overflow-y: auto;
  z-index: 9999;


`;


export const StyledNavTitleDepth = styled.div`

  display : flex;
  flex-direction : row;
  gap : 10px;
  justfiy-content : center;
  align-items: center;
  cursor: pointer;
  width : 100%;
  padding: 16px;
`


export const StyledNavBoxDepth = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    flex-direction: column;
    width : 100%;
    height : auto;


`



