import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4"

export default {

    helmet: '| Smart Airport Solution',
    desc: 'Tracking the movement of travelers in real-time and integrating various systems within the airport to maximize operational efficiency. Services such as smart check-in, automated security screening, digital boarding passes, and gate notifications are available.',
    keywords: 'Smart airport, digital twin, NVR, IP, PTZ, RFID, NFC, UWB, wireless communication, location tracking, object location tracking, people location tracking, tags, beacons, RTLS, orbro, contech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'Smart Airport Solution | Digital Twin Platform, Orbro',
    ogUrl: 'https://orbro.io/en/blog/smart-airport-solution',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDnB84Bj2f8mz7hiRJi4WKgwiYq0yzs4GDe7VfFWOdEiQPlqEtyMMpSU-r7CcKy3BQ9FE63-XkxXVh-VAf440HOH1GA2iUOdHrknQN9GTI5y3ciXRGd5tlYsj4DhnmSkYTQpZas8pKO2QTEY2Yr1aKg7FEDLt0kRSvFKTZggTvuJepKze-kTLdpe_706Vc5UevQyGXNClLc9oJpNJDRuQC6tUHKnn_FiW52O6uSCoGFs28ONxKiUPRFE5Ju29eGYw_NfFWK5qCYQkem15ivnuMMmQSfx2L1JMQGPPP__JQ8WwQTWf0fqJZrIOChm-Ytl3cTDljPsL84S4SK3ww7wvpdBVVnfeI_Hg9DIMDCgf2m4_GsjXhNuJb8f9tyjQix7woDC35BeyLXjFP9o2ERnbG7fyvuPfPIRBgK5nDmmQ-wLv8t6GiBIp0c9271KpuXrz_4fq6Pq0V18HWuznis8j9ij1ifWmZi0BufNpYrlKZ2RdY4lq_6Gr9vc4ZPYRfJ-NFKGBI03EKiA9vlIrzcQTS3aEzIVovappIABtvO8PxqCkqhF0amIzISUH2Y5efT1YtPEGCevRdIC2I9_V7SvrQTvm0Qnp6vF-xmdybPAnJr0BD0vT05fpxoeHcN_WbUEtUQcbFsq6v1TanDDnB-XLp6Hj1LBhEApO0KLMdKr6hqANaGkrweuLO-ppA1rq_sNPCwSoX3tInq-mGVn9-HfzIH4u1P52EJjPxNZh60gOpVuZqkyH0P7dKgHso5PfMXAHJJwBIhCrzzILpZN8rrqZZvgP7AKPfAS06cevU6M0PaN0pZiHywpvCcQVdty1VzWLryj5hS4cQmTfrC3GcAHFJBDMgSUdZAhbiB7MSwtMhcD4ye-9kOD85_PonAQG9QMZMyrDPuna6Rnfr3NNhtq5VLL4M2wMxmiUDvxHg1WMRsQIoR-KUoTQ7imcUxIkCvzVkMe0UgWhSU3oamalc9rvOKud9cDgvAzuC1Pi4TUFJEZoHIvWiSKf6fqf7_yqlGHA5CggXGmBUVlSUJKMz4AF25WfOC3t3lBYczZ7sdmtLr4oU5MSzzsuoU1ZRAsXu21sJkggnYNxqgP12eulaDnKgNC2v2tUoVFG3FM0dx4L9exFCMlSC4o25cBb7aCwOiAArJL-pUeJfoo0RWP79r8X-Fai40OnK_c9Mu3JLlhPbxgOsjIIIqErT8CygE-qJyrNdf7TY6j9Onn6HFhBE3IeCPrkrAbSVNLPT43ZP_PQeII9yOMYE1U9feIAsW6V2sC75YSru67__inv-Qj90ltj2FbqExBTfA2Yr-kLz4oeF05ygNcG4IyVyMb40jXPlED8eAhW-C3QHc4YQUCMvuu6Di-Lbgtce_nkq_428rQwjdjJDzejxpTy6tyIKxtYbtWMLS2siVXz5PQ07dAbC6fOPlh5x1vyFEsMroUS20vNOqxpVKPYNog4lmhOQ5_KeNNrSPhRy2_zHR0c5l3sOApfG7vMjyOHlwSXmthwYqL1_Hgz_WbJ_B1jLdHt8fpAOw6KWq_yUsDx0yS9mIzNjDitluARF04p2PJ=w2038-h1285',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'Smart Airport Solution',
    date: '2024-01-16',
    tag1: '#SmartAirport',
    tag2: '#RTLS',
    tag3: '#DigitalTwin',
    tag4: '#LocationTracking',

    // <br/>
    text: [
        {
            head: 'Smart Airport Solution',
            subHead: '',
            desc: 'A smart airport refers to an airport that utilizes modern technology and advanced systems to provide optimal services to travelers. The goal is to maximize efficiency and innovate the travel experience through various smart technologies and digital advancements.<br/>This involves optimizing the travel experience and maximizing efficiency through various smart technologies such as indoor environmental control, AI-based security systems, and RTLS technology. Smart airports focus on providing optimized services at every stage of travel to enhance customer satisfaction.',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/en/enterprise/construction/equipment',
            head: 'Smart Airport Technology',
            subHead: '',
            desc: 'Smart airports provide innovative and convenient services to travelers by leveraging various advanced technologies. Technologies that can be implemented in smart airports include:',
            dot: ''
        },
        {
            head: '',
            subHead: 'Indoor Environmental Control System',
            desc: 'Smart airports introduce indoor environmental control systems to provide optimal conditions for travelers. By monitoring and adjusting temperature, humidity, and air quality in real-time, travelers can wait comfortably. Additionally, smart lighting systems enhance convenience for travelers by mimicking natural light and improving energy efficiency.',
            dot: ''
        },
        {
            head: '',
            subHead: 'AI-Based Security System',
            desc: 'Safety is a core value in travel. Smart airports build advanced security systems using AI cameras. Facial recognition technology, behavior analysis, and detection of illegal activities are combined to enhance security and provide peace of mind to travelers. Additionally, immediate action can be taken by detecting anomalies in real-time.',
            dot: ''
        },
        {
            head: '',
            subHead: 'RTLS (Real-Time Location System) Technology',
            desc: 'Utilizing Real-Time Location System (RTLS) technology, smart airports track the real-time locations of travelers. This enables efficient coordination with gates, shops, and baggage transport systems, optimizing the movement of travelers. It also provides meaningful data for route analysis and safety management.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Data Analysis and Artificial Intelligence',
            desc: 'Data analysis using big data and artificial intelligence plays a significant role in optimizing smart airport operations. Analyzing the movement patterns and service usage trends of travelers helps in efficient operation and identifying areas for improvement. This helps airport managers allocate resources effectively and provide better services to travelers.',
            dot: ''
        },
        {
            head: 'Advantages of Smart Airports',
            subHead: '',
            desc: '',
            dot: {
                a: '<strong> • Convenient Travel Experience: </strong>Smart airports provide a high level of convenience to travelers through automated check-in processes and real-time information provision. Automated check-ins and security verifications using facial recognition help travelers move smoothly and access services easily.',
                b: '<strong> • Enhanced Safety and Security: </strong>Smart security systems, utilizing AI cameras and facial recognition technology, increase the safety of travelers and detect potential incidents or threats within the airport premises in advance. This ensures a safer and more reliable travel environment.',
                c: '<strong> • Efficient Operations and Resource Management: </strong>Data analysis and the use of big data help optimize smart airport operations and efficiently manage resources. Artificial intelligence technology understands the movement patterns of travelers and optimizes resources for increased operational efficiency.',
                d: '<strong> • Optimized Indoor Environment: </strong>Indoor environmental control systems allow travelers to wait in comfortable conditions. Real-time monitoring and adjustment of temperature, humidity, and air quality enhance the convenience of travelers, providing a quality waiting environment.',
                e: '<strong> • Fast Processing and Entry Procedures: </strong>Automated processing reduces the inconvenience for travelers and provides fast check-in and entry processes. This helps travelers save time and move comfortably.'
            }
        },
        {
            head: "Smart Airport Case Studies",
            subHead: "Hamad International Airport (DOH), Qatar",
            desc: "Doha International Airport actively incorporates smart technology as part of a smart city, providing advanced services. Automated robot guidance services, smart security systems, and passenger convenience services through mobile applications have been introduced.",
            dot: ""
        },
        {
            head: "",
            subHead: "Changi Airport (SIN), Singapore",
            desc: "Changi Airport is recognized as one of the world's best airports due to its active adoption of innovative technology. Automated check-in systems, intelligent baggage handling, and smart stations within the airport provide advanced convenience to travelers.",
            dot: ""
        },
        {
            head: "",
            subHead: "Dubai International Airport (DXB), United Arab Emirates",
            desc: "Dubai International Airport is evolving into an international travel hub with smart technology and innovative services. AI-based smart security systems, autonomous vehicles, and improvements in passenger experience through mobile applications have been introduced.",
            dot: ""
        },
        {
            head: "",
            subHead: "San Francisco International Airport (SFO), USA",
            desc: "San Francisco International Airport is gaining attention for its efficient operations and smart services. Features include indoor environmental monitoring using IoT, smart baggage handling systems, and enhanced passenger convenience through a mobile app.",
            dot: ""
        }

    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'A product that aids real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/en/enterprise/medical/patient'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/en/enterprise/retail/exhibition'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/en/enterprise/office/asset'
            }
        ]

    }
}
