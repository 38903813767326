import React, { useEffect, useState } from "react";
import Banner from "./components/Banner/Banner";
import { DigitalTwinPage } from "./DigitalTwin.style";
import Header from "./components/Header/Header";
import Connection from "./components/Connection/Connection";
import Func from "./components/Func/Func";
import Video from "./components/Video/Video";
import Exam from "./components/Exam/Exam";
import Summary from "./components/Summary/Summary";
import Patent from "./components/Patent/Patent";
import SEO from "@pages/SEO";
import i18n from "@pages/i18n";
import { useLocation } from "react-router-dom";
import seoDT from "@src/lang/ko/seoDT";
import seoDTen from "@src/lang/en/seoDT";
import seoDTjp from "@src/lang/jp/seoDT";
import seoDTcn from "@src/lang/cn/seoDT";




const DigitalTwin = () => {


  const [seoPath, setSeo] = useState<any>(seoDT);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/en/')) {
      setSeo(seoDTen);
    } else if (location.pathname.includes('/jp/')) {
      setSeo(seoDTjp);
    } else if (location.pathname.includes('/cn/')) {
      setSeo(seoDTcn);
    } else {
      setSeo(seoDT);
    }
  }, [location.pathname])

  const seoTitle = seoPath.overview.title;
  const seoKey = seoPath.overview.key;
  const seoDesc = seoPath.overview.desc;
  const seoImg = seoPath.overview.img;
  const seoUrl = seoPath.overview.url;

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": seoTitle,
    "description": seoDesc,
    "alternateName": seoTitle,
    "url": seoUrl,
    "keywords": seoKey,
    "image": seoImg,
    "serviceType": "Technical Solution",
    "areaServed": "Global",
    "provider": {
      "@type": "Organization",
      "name": "ORBRO",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+82-1522-9928",
        "email": "orbro@orbro.io"
      }
    },
    "additionalProperty": {
      "@type": "PropertyValue",
      "name": "Digital Twin Technology",
      "value": "Digital Twin with Digital Transformation"
    }
  }


  return (
    <>
      <SEO
        lang={i18n.language}
        helmetTitle={seoTitle}
        ogKey={seoKey}
        ogDesc={seoDesc}
        ogImg={seoImg}
        ogUrl={seoUrl}
        jsonLd={jsonLd}
      />
      <DigitalTwinPage>
        <Banner/>
        <Header/>
        <Connection/>
        <Func/>
        <Video/>
        <Patent/>
        <Summary/>
        <Exam/>
      </DigitalTwinPage>
    </>
  )
}

export default DigitalTwin;