import {
    access2off, access3off, access4off,
    access2on, access3on, access4on,
} from '@assets/images/main/index';

import { BlueIconPhone, BlueIconRFID, BlueIconRemote, BlueIconWatch, BlueIconWidget } from "@assets/images/icon/blue";
import {
    AdvantageImgD, AdvantageImgM, AdvantageImgT,
    Falldown1D, Falldown1M, Falldown1T,
    Falldown2D, Falldown2M, Falldown2T,
    Falldown3D, Falldown3M, Falldown3T,

    installationImg1D, installationImg1M, installationImg1T,
    installationImg2D, installationImg2M, installationImg2T,
    installationImg3D, installationImg3M, installationImg3T,
    installationImg4D, installationImg4M, installationImg4T,
    installationImg5D, installationImg5M, installationImg5T,

    hardwareImg1D, hardwareImg1M, hardwareImg1T,
    hardwareImg2D, hardwareImg2M, hardwareImg2T,
    hardwareImg3D, hardwareImg3M, hardwareImg3T,
    hardwareImg4D, hardwareImg4M, hardwareImg4T,
    hardwareImg5D, hardwareImg5M, hardwareImg5T,
} from "@assets/images/main/access/components/index";
import { IconOsOff, IconOsOn } from '@assets/images/main/icon';


export default {


    banner: {
        title: '出入系统的创新',
        desc: '通过引入Orbro的出入系统，革新您的出入系统。',

        schemeTitle: '为您选择出入系统。',
        schemeDesc: '需要帮助做决定吗？ ',
        schemeDesc2: '致电 ',
        phone: '(+82)1522-9928',
    },

    mainPage: {
        product: {
            title: '了解产品组成',
            desc: '了解创新出入系统的产品组成。',
            mobile: {
                title: '移动出入',
                desc: '移动出入系统的起点',
            },
            auto: {
                title: '自动门出入',
                desc: '专为自动门定制的出入系统<br/>适用于所有出入口',

            },
            face: {
                title: '人脸识别出入',
                desc: '人脸识别、移动出入、NFC卡等<br/>多种出入方式',

            },
            accessory: {
                title: '出入配件',
                desc: '了解与出入系统相关的<br/>所有产品',

            }
        }

    },


    FAQ: [
        {
            title: 'Orbro Access的出入方式是什么？',
            description: 'Orbro Access的出入方式是通过移动应用程序进行出入。<br/>用户可以安装移动应用程序并获得授权后，使用该应用程序进行出入。<br/><br/>通过移动应用程序进行出入可以让用户使用智能手机开启和关闭出入门。该应用程序负责验证用户身份并授权出入权限。通过这种方式，用户可以通过手机方便地体验出入。<br/><br/>'
        },
        {

            title: '在没有自动门的环境下是否可以使用？',
            description: '是的，Orbro Access可以在没有自动门的环境下使用。<br/>但是，在没有自动门的情况下，需要安装死锁或电磁锁等门锁设备。这些门锁设备可以安全地锁定和打开出入门。<br/><br/>Orbro Access出入终端可以控制这些门锁设备，从而解锁出入门。出入终端接受用户的验证信息，并在验证成功后解锁门锁设备以允许出入。通过这种方式，Orbro Access可以在没有自动门的环境下运行出入管理系统。通过出入终端和门锁设备的连接，可以增强出入门的安全性，并控制只有经过授权的用户才能出入。<br/><br/>',
        },
        {

            title: '是否可以在没有手机的情况下出入？',
            description: '是的，Orbro Access可以在没有手机的情况下进行出入。<br/><br/>为了在没有手机的情况下进行出入，需要使用Orbro Access Key这个特殊配件。Orbro Access Key是一种可以携带用户信息并发送的设备，使得没有手机的人也能接受出入验证。通过这种方式，即使没有手机，也可以通过Orbro Access顺利地进行出入控制。<br/><br/>',
        },
        {

            title: '是否可以在无法安装Wi-Fi和互联网的环境下使用？',
            description: '是的，Orbro Access提供多种系统配置，可以在无法安装Wi-Fi和互联网的环境下进行出入。<br/><br/>为此，用户可以使用自己的智能手机执行出入程序。用户通过智能手机作为中介，从服务器获取出入权限，然后可以进行出入。通过这种方式，即使在无法安装Wi-Fi和互联网的环境下，也可以有效地利用Orbro Access。<br/><br/>',
        }
    ],


    bannerData: [
        {
            icon: access2on,
            iconInv: access2off,
            image: '',
            iconText: '自动门出入',
            titleKey: '专为自动门定制的出入系统<br/>适用于所有出入口',
            descKey: 'access.banner.desc',
            sub: '',
            link: '/cn/access/auto'
        },
        {
            icon: access3on,
            iconInv: access3off,
            image: '',
            iconText: '人脸识别出入',
            titleKey: '人脸识别、移动出入、NFC卡等<br/>多种出入方式',
            descKey: 'access.banner.desc',
            sub: 'New',
            link: '/cn/access/face'
        },
        {
            icon: access4on,
            iconInv: access4off,
            image: '',
            iconText: '出入配件',
            titleKey: '了解与出入系统相关的<br/>所有产品',
            descKey: 'access.banner.desc',
            sub: '',
            link: '/cn/access/accessory'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/cn/os'
        },
        // Add more objects if there are more groups
    ],

    falldown: {
        left: {
            title: '在智能手机上集成入口方式，<br/>最现代的访问系统',
            desc: '从普通门到自动门和速通门，您可以轻松使用智能手机访问现代社会的各种入口点。通过使用最接近我们日常生活的系统，体验最有效的数字转型。',

            fall: [
                {
                    title: '智能手机入口',
                    desc: '可以通过所有iOS/Android智能手机访问。',
                    img: {
                        desktop: Falldown1D,
                        tablet: Falldown1T,
                        mobile: Falldown1M
                    }
                },
                {
                    title: '智能手表入口',
                    desc: '通过智能手表享受更便捷的访问体验。',
                    img: {
                        desktop: Falldown2D,
                        tablet: Falldown2T,
                        mobile: Falldown2M
                    }
                },
                {
                    title: 'RFID卡入口',
                    desc: '可以使用现有的员工工作证访问。',
                    img: {
                        desktop: Falldown3D,
                        tablet: Falldown3T,
                        mobile: Falldown3M
                    }
                },
            ]
        },

        rightImg: ''
    },

    functions: {
        title: '核心是硬件，<br/>一切都在平稳创新。',
        desc: '',
        line: 2,
        card: [
            {
                img: {
                    desktop: hardwareImg1D,
                    tablet: hardwareImg1T,
                    mobile: hardwareImg1M
                },
                tag: '状态LED',
                title: '双通道NFC/蓝牙<br/>访问',
                desc: '通过NFC卡等访问，从移动到智能手表入口都可以。',
                size: '3',
            },
            {
                img: {
                    desktop: hardwareImg2D,
                    tablet: hardwareImg2T,
                    mobile: hardwareImg2M
                },
                tag: '电源供应',
                title: '通过PoE使用<br/>集成通信和电力',
                size: '1',
            },
            {
                img: {
                    desktop: hardwareImg3D,
                    tablet: hardwareImg3T,
                    mobile: hardwareImg3M
                },
                tag: '简便安装',
                title: '使用专用支架<br/>方便安装',
                size: '2',
            },
            {
                img: {
                    desktop: hardwareImg4D,
                    tablet: hardwareImg4T,
                    mobile: hardwareImg4M
                },
                tag: '扬声器',
                title: '通过扬声器<br/>进行访问通知',
                size: '2',
            },
            {
                color: '#000',
                img: {
                    desktop: hardwareImg5D,
                    tablet: hardwareImg5T,
                    mobile: hardwareImg5M
                },
                tag: '状态LED',
                darkTitle: '即使在黑夜中<br/>',
                lightTitle: '状态检查 ',
                darkTitle2: '也可以',
                desc: '通过RGB LED，您可以检查设备状态、入口确认等。',
                size: '2',
            },
        ]
    },

    installation: {
        title: '不用担心产品安装。<br/>我们为您搞定。',
        card: [
            {
                img: {
                    desktop: installationImg1D,
                    tablet: installationImg1T,
                    mobile: installationImg1M
                },
                tag: '提供本地服务器',
                title: '企业级<br/>服务器提供',
            },
            {
                img: {
                    desktop: installationImg2D,
                    tablet: installationImg2T,
                    mobile: installationImg2M
                },
                tag: '专业现场安装支持',
                title: '请拨打1522-9928<br/>了解最早的安装日期',
                desc: '工作日上午9点至下午6点',
            },
            {
                img: {
                    desktop: installationImg3D,
                    tablet: installationImg3T,
                    mobile: installationImg3M
                },
                tag: '入口配件',
                title: '可以安装死锁',
            },
            {
                img: {
                    desktop: installationImg4D,
                    tablet: installationImg4T,
                    mobile: installationImg4M
                },
                tag: '入口配件',
                title: '可以安装EM锁',
            },
            {
                theme: 'black',
                img: {
                    desktop: installationImg5D,
                    tablet: installationImg5T,
                    mobile: installationImg5M
                },
                tag: '入口配件',
                title: '可以安装电动锁',
            },
        ]
    },

    advantage: {
        img: {
            desktop: AdvantageImgD,
            tablet: AdvantageImgT,
            mobile: AdvantageImgM,
        },
        title: 'ORBRO与传统访问系统的不同之处',
        desc: 'ORBRO是提供各种入口方式的最现代访问终端，适应现代社会访问方式的变化，从传统的访问方式到最新的智能技术。它为用户提供了便捷和安全的访问环境。',
        card: [
            {
                icon: BlueIconPhone,
                title: '使用智能手机进行访问',
                desc: '使用iOS / Android应用程序控制入口点。',
            },
            {
                icon: BlueIconWidget,
                title: '提供小部件',
                desc: '小部件是更方便使用移动应用程序的方式。',
            },
            {
                icon: BlueIconWatch,
                title: '使用智能手表进行访问',
                desc: '通过使用智能手表体验便捷的入口方式。',
            },
            {
                icon: BlueIconRemote,
                title: '远程控制',
                desc: '管理员可以远程管理入口点。',
            },
            {
                icon: BlueIconRFID,
                title: 'RFID和NFC卡',
                desc: '通过标记RFID或NFC标签轻松控制入口点。',
            },
        ]
    },



}
