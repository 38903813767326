import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";


export const TextBox = styled.div`
    height : fit-content;
    display : flex;
    flex-direction : column;
    gap : 4px;
`;


export const HeaderGrid = styled.div`
    display : grid; 
    grid-template-columns : repeat(4,1fr);

    @media (max-width : 1080px){
        grid-template-columns : repeat(2,1fr);
        gap : 16px;
    }

    @media (max-width : 540px){
        display : flex; 
        flex-direction : column;
        gap : 24px;
        text-align : center;
    }
`;


export const HeaderImgBox = styled.div`
    width : 100%;
    height : 430px;
    max-height : 430px;

    display : flex;
    justify-content : center;

`;


export const HeaderImg = styled.img`
    width : 100%;
    object-fit : contain;
`;



export const HeaderImgBg = styled.div<{ bgImg: string }>`
    max-height : 450px;
    max-width : 100%;
    z-index : 2;
    
    ${({ bgImg }) => bgImg && `background-image : url(${bgImg});`}
    background-repeat : no-repeat;
    background-positon : center;
`;



const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;



export const HeaderImgSideL = styled.img`
    position : absolute;

    height : 320px;
    aspect-ratio : 480 / 320;

    object-fit : cover;
    left : 0;
    margin-left : 34px;
    margin-top : 90px;
    cursor : pointer;
    z-index : 3;

    &:hover {
        display : none;
        z-index : 0;
    }

`;

export const HeaderImgSideR = styled.img`
    position : absolute;
    
    height : 320px;
    aspect-ratio : 480 / 320;

    object-fit : cover;
    right : 0;
    margin-right : 34px;
    margin-top : 90px;
    cursor : pointer;
    z-index : 3;
  
    &:hover {
        display : none;
        z-index : 0;
    }
`;



export const HeaderImgSideLv = styled.video<{play : boolean}>`
    position : absolute;

    height : 320px;
    aspect-ratio : 480 / 320;

    object-fit : cover;
    left : 0;
    margin-left : 34px;
    margin-top : 90px;

    z-index : 2;


`;


export const HeaderImgSideRv = styled.video<{play : boolean}>`
    position : absolute;
    
    height : 320px;
    aspect-ratio : 480 / 320;

    object-fit : cover;
    right : 0;
    margin-right : 34px;
    margin-top : 90px;
    
    z-index : 2;

  
`;


