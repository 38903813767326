export default {
  titleBanner: {
  subtitle: 'TDoA | Time Difference of Arrival',
  title: 'TDoA Positioning Technology',
  description: '*TDoA (Time Difference of Arrival) is one of the positioning technologies that calculates the position of a receiver by measuring the time difference at which signals arrive from a transmitter. <br/> When multiple receivers receive the signals from a single transmitter, the time differences of signal arrival between the receivers can be measured to calculate the position of the transmitter. <br/> The receivers are synchronized with accurate timing, and the higher the accuracy of timing synchronization, the higher the accuracy of the position measurement.'
  },
  definition: {
  title: 'What is TDoA Technology?',
  text: `TDoA is a methodology for determining the arrival time differences of wireless signals to determine position. TDoA is used in real-time location systems (RTLS) and accurately calculates the position of tracked targets such as personnel or valuable assets attached with tracking tags in real-time. <br/><br/> For example, in Orbro's real-time location tracking system, TDoA utilizes the collected location data from UWB or chip (RTLS) anchors to tracking tags to accurately calculate the real-time position of the tracked targets. As shown in the diagram below, the RTLS tags (T) continuously transmit RF signals, i.e., location blinks, at regular intervals. Multiple RTLS anchors within the transmission range of the transmitting tag receive these location blinks and record the precise arrival times (ToA) as timestamps. The anchors then transmit the location data with these timestamps to the Orbro Edge Location Engine software. The Orbro Edge analyzes the arrival time differences of the received location blinks (e.g., ToA1 - ToA2) to calculate TDoA.`,
  },
  sync: {
  title: 'Virtual Anchor Synchronization',
  text: `To function properly, fixed anchors need to be accurately synchronized to run on precisely synchronized clocks. Orbro Edge's real-time location tracking system achieves this through wireless communication-based time synchronization. The calculated TDoA is represented by hyperbolas, indicating possible tag locations. Three intersecting hyperbolas related to one point describe the tag's location. It is recommended to use a minimum of four anchors for reliable location estimation. <br/><br/> The resulting position coordinates can be visualized on a map to represent the tag's location or used for specific applications depending on the purpose.`,
  },
  advantage: {
  title: 'Key Advantages of TDoA',
  items: {
  accuracy: {
  title: 'High Accuracy',
  description: 'TDoA guarantees location results with higher accuracy compared to traditional methods such as RSSI-based location measurements.'
  },
  wait: {
  title: 'Low Waiting Time',
  description: 'With TDoA, an RTLS can handle thousands of concurrent received location blinks, ensuring low latency and high performance reliability.'
  },
  expansion: {
  title: 'Enterprise Scalability',
  description: 'TDoA-based calculations enable scalable RTLS deployments that meet enterprise requirements.'
  }
  }
  },
  }