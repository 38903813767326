import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet : '',
  desc : '환자를 실시간으로 추적하고, 의료진 및 자산을 효율적으로 관리하여 치료 과정을 최적화합니다. 또한, 인공지능과 데이터 분석을 활용하여 환자의 건강 상태를 모니터링하고 최상의 의료 서비스를 제공합니다.',
  keywords : '스마트 병원, 환자 관리, RTLS, 의료시설, 환자흐름, 병원장비추적, 병원장비, 위치추적, 콩테크',

  ogTitle : '',
  ogDesc : '',
  ogSite : '최첨단 병원이 환자를 관리하는 방법 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/hospital',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZqhO9ezvqYIEyhhFrpVaYpW4QqZ73QOwXB7Atab7eG0KzsPeNRlfan0xcjBoTA4f9uOZsyyO3Aa7Ssy20vkBaALX1WP-ZEFqJmbRtvf8byaL_7pF89VhW9s1FZpdgvvCcvntuFyR8L7F4TIbbBzU78ARievgQnwDB_lzVgDXzEUFwCVB3-YfvTStDIeeQbBMYwXBRqeTxImshBEbYzfSrxzifccZi8NjY7581Zfdx8FsHKQxodIhZ29ZLBf2hUBttfK25Bt_5cKO11xm0Ub77S_b5M7F80YBvm8c9d8HWNm2lKwa0B9J7xK_8AnjeHNyqgk5fDREyJMJtElXsGgAsVr4esxClp1Jo-1Bu4q7FZ6x6Vst2Bdg9fgVC2FfZUkW0TMJf0TGQk4IHEdSi_tX-lHR-NuUPpslEUUTKpNuIBLM4trADP3kW3TMh-nuxDZ-W97b7Ml0EZEEhU_byAAqaH1P86CAJailGfFlXoPXY7se0SnBVgVcp9Bq4oNt8ejbJM6DuC3jMYWKNEsdSLoS9KuVf-cxKYrIx6HNENPs_tUYxZuA8kkIdeex_X5CkYZg8Z95iFRrmuR6-k-fS0ykwlLfiCPjbw_qyngo2P9vn2q-NkvMYRE1SUtS7MaMu1kDIk_f3yCqDzaDk8LIG9f42EzTAFM1QJxb6J39afoj_lF1hQOzM3OIyqithxbE4wq6iz0b70zB9MBMH2CP8btw5pT21L7GNXDa7w7dum6t7fiFkb249GmTOEbKr4brj6ZswEBzV6IsL4E9idjImLI5m-DrXPUUItPct4kt65sjT1yGoaEwqGk9lgbJOQWbI8UIk0WxzjIQEPjGj2Lw65GMqn5jj0k1LiyzE63GmSC4jyYG01Uk1oZQKuQVAiAIc0WNEPNlZtaufLrWeok3p-c-ZYIUvROQi8hyKxfZIWTtQC689Njt_974ehQ2JaJoUdkvHm4fWMuz1O2D_spUzxRTiztHX1N0uxC0Chhro0SH5fnIte5uAmlAlKfI5rAyDpNtSj1fDdPQJkntk-13pn6xAChyiCLn3jL8NaIu14FzJC4Hv9ZejC45VzaQYxl1UJkgC4eTuaJwLW2TWhJjYNcSXBV51iVuF73QisAxqadKjRmpLmZnEob03V2CkJ7hOZK-dqMjC3dtTeuFT4cqOotx_vFMdxjW83kXdCMWXqCVnL513yFcpJwBAWdniR9rrl-xjdIGyuZH-4v_2pFtpIUpdZkPIcRkw6KdnBbfzMjjIAu6ROi9gnSv7owjl5Dk_LeLqdKH98zhYoIqXrM9Z0FqvBgFdW2JqvTxkeOfRi1893ZVLvD4gXR1Nojbgnql_FwANyT5tVh5EPYyt2XvB9fCInBjzYkhHlZ2MPF92quV6_Ym9AkThfWXdPiC7vwKrQUT9B5CD5PpaoTyOzE8g1_-HydegZuWxfPLGCfb7VajMbW2hwA0uoC6vED-mcbtpZTKu2KCMH7CMEH-oAhQhRF-k4WkAGRz0Vuj4OY5OskCr4Jq8vQl-pEdGgh8b8T6Nnxx8mw7DohsssWwC9y4T1BIrPLZYGHhmRq2JJLKbJKrMRJA=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
  
    
    title: '최첨단 병원이 환자를 관리하는 방법',
    date : '2023-08-21',
    tag1 : '#스마트병원',
    tag2 : '#환자추적',
    tag3 : '#환자관리',
    tag4 : '#환자안전관리',
    
    // <br/>
    text : [
      {
        head : '',
        subHead :'',
        desc : '병원만큼 우리의 건강과 직결된 장소가 있을까요?<br/>삶에 있어서 가장 중요한 것이 건강인 만큼, 환자들은 병원을 선택할 때 가장 까다로운 조건을 내걸지도 모릅니다. 그리고 당연히 그 조건은 신뢰와 기술일 것입니다. 나를 잘 돌봐줄 것이라는 믿음과 그를 뒷받침할 수 있는 의료 기술. 환자들은 이 두 가지가 보장된다면 (가능한 선에서) 기꺼이 더 큰 비용을 지불할 의사가 있습니다. 이를 잘 알고 있는 의료 시설 관리자들과 산업관계자들은 더 나은 서비스와 비즈니스를 위해 첨단 기술에 더 관심을 갖습니다. 하지만 많은 사람이 의료 기술에만 집중하는 경우가 많아, 정작 다른 더 멋진 기술과 시스템에 대해서 간과하는 경우가 많습니다. 그 대표적인 예가 바로 요즘 비약적인 성장을 하고 있는 실시간 위치 추적 기술, RTLS 기술입니다.',
        dot : ''
        
      },
      {
        head : '실시간 위치 추적 시스템, RTLS란 무엇인가요?',
        subHead :'',
        desc : 'RTLS란, 말 그대로 대상의 실시간 위치를 추적하는 기술입니다. 여기까지만 들어본다면 그동안 여러분이 알고 있는 GPS와 다를 바가 없다고 생각할 수 있습니다. 위치를 추적한다는 점에서 보면 같은 기술이라고 할 수 있겠지만, 사실 두 기술에는 큰 차이가 있습니다.<br/>우선 RTLS 기술은 실내에서도 훨씬 정밀한 수준의 위치 추적이 가능합니다. 예를 들어, 빠르게 상황을 판단하거나 대응을 해야 하는 상황에서도 실시간으로 관리자나 관계자에게 알림을 보낼 수 있다는 것입니다.<br/>물론 RTLS 기술 자체가 이런 기능을 의미하는 것은 아닙니다만, 기술력이 갖춰진 회사라면 얼마든지 다양한 기능이 있는 시스템을 구축할 수 있다는 것입니다.<br/><br/>그렇다면 병원에서는 어떤 일들이 가능해지는지 확인해 보실까요?',
        dot : ''
        
      },
      {
        head : '',
        subHead :'의료 장비 추적',
        desc : '얼마 전 모 병원에서 의료 사고가 발생하였습니다. 오염된 의료 장비를 제대로 처리하지 않아서 생긴 감염 사고였습니다. 이 사건을 접한 당신이 병원 관리자라면 어떻게 할 것인가요?<br/>기존의 프로토콜 방식대로 사람이 직접 관리를 하는 것도 방법이겠지만, 만약 병원의 근로자가 (물론 아니길 바랍니다.) 근무 태만의 가능성이 있거나 이전에 비슷한 사고를 낼 뻔한 경험이 있는 사람이라면 어떨까요? 반대로, 아까 말씀드린 사고가 있던 병원의 의료 장비 처리 담당자가 실은 굉장히 성실하고 한 번도 실수한 적 없는 최고의 근로자였을지도 모릅니다. 결국, 사람이 하는 일은 실수가 생기기 마련이고 어떻게 될지는 모른다는 것이지요. <br/>RTLS 기술은 이런 사고 발생 가능성을 방지해줄 수 있습니다. 오염된 장비는 실시간으로 관리자가 모니터로 동선을 파악할 수 있습니다. 심지어 특정 경로를 벗어나면 경고를 보내는 자동 시스템도 가능합니다.<br/>완전하고 안전하게 처리될 때까지, 스마트 시스템이 지켜봐 주는 것이지요.',
        dot : ''
        
      },
      {
        head : '',
        subHead :'위험 구역 관리',
        desc : 'RTLS 시스템은 안전한 시설을 유지하기 위해, 접근 금지 구역을 설정할 수 있습니다.<br/>만약 그 구역에 접근하는 사람이 있다면, 먼저 관리자에게 알림을 보내서 대응할 수 있도록 합니다.<br/>예를 들어 당신이 병원에서 근무하고 있는데, 갑자기 의약품 창고에 환자 A씨가 접근을 했다는 경고가 나타납니다. 화면을 보니, 별관에 있는 의약품 창고 문 앞에서 A씨가 서성이고 있습니다. 다행히 당신은 빠르게 경비원을 호출하여, 사고를 예방할 수 있게 되었습니다.',
        dot : ''
        
      },
      {
        head : '',
        subHead :'더 많은 확장성',
        desc : 'RTLS 기술이 위와 같은 기능들을 제공하는 것을 의미하지는 않습니다.<br/>다만, RTLS를 통해서 다양한 기능을 갖춘 시스템을 만들 수 있다는 것입니다.<br/>여기에 낙상 감지 기술을 접목하거나, 오브로의 출입 시스템을 사용하여 어디 하나 빠지지 않는 스마트한 의료 시설을 만드는 것도 가능합니다. 아까 말씀드린 것 처럼 완전하고 안전하게 말이죠.<br/>또한 병목현상을 실시간으로 제어하거나, 시설 내의 자산의 위치와 수량을 빠르게 파악하여 운영적인 측면에서도 효율적입니다. 소모되는 인력과 비용은 자연스럽게 줄어들게 되지만 생산성을 오히려 늘어나게 됩니다.',
        dot : ''
        
      },
    ],
  
    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : medicalPatient.thumb,
          data : 'medicalPatient',
          link : '/enterprise/medical/patient'
        }, 
        {
          img : medicalWaste.thumb,
          data : 'medicalWaste',
          link : '/enterprise/medical/waste'
        },
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/enterprise/logistics/asset'
        }
      ]
  
  
    }
  
  
  }