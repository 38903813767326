

import React from "react";
import { Trans } from "react-i18next";


import {ModalTextBox, StyledDownloadBtnBox,
    StyledDownloadBtnlText} from "./ModalDoneText.style";

import {footerLogo} from "@assets/images/logo";
import { StyledHeading } from "@components/Text";
import { ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import { StyledDownloadBtn, StyledDownloadIcon, StyledDownloadIconBox } from "@pages/Enterprise/Solution/components/Banner/Modal/ModalDownload.style";

const icon = footerLogo


const ModalDonetext = ({onClickToggleModal}) => {

  const handleModalClose = () => {
    onClickToggleModal();
  }
  
  
  return (


    <ModalTextBox>
        
        <StyledDownloadIconBox>
          <StyledDownloadIcon src={icon} alt="Download Icon"/>
        </StyledDownloadIconBox>
       
        <Spacing pixel="8"/>
        <StyledHeading size="small"> <Trans i18nKey={"download.thanks"} /> </StyledHeading>

        <Spacing pixel="4"/>
        <ParagraphColor size="medium" color="#818181"> <Trans i18nKey={"download.thanksMore"}/> </ParagraphColor>
        <Spacing pixel="24"/>


        <StyledDownloadBtnBox>

            <StyledDownloadBtn onClick={handleModalClose}>
                <StyledDownloadBtnlText size="medium" color={"#fff"} ><Trans i18nKey={"download.done"} /> </StyledDownloadBtnlText>
            </StyledDownloadBtn>

        </StyledDownloadBtnBox>




    </ModalTextBox>

  )




}


export default ModalDonetext

