import { StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";

export const StyledOurTechnology = styled.div`
  padding: 128px 0;
`;

export const StyledOurTechnologyHeading = styled(StyledHeading)`

`;

export const StyledOurTechnologyDisplay = styled(StyledDisplay)`
  margin-top: 16px;
`;

export const StyledOurTechnologyParagraph = styled(StyledParagraph)`
  margin-top: 24px;
`;

export const StyledOurTechnologyItems = styled(StyledGridRow)`
  margin-top: 64px;
`;