import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from '@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4';

export default {

    
  helmet : `| What is a Beacon? | ORBRO, No. 1 Beacon Company`,
  desc : 'A beacon is a small radio transmitter device that uses wireless communication to deliver information to smart devices at specific locations. It primarily uses Bluetooth Low Energy (BLE) technology and is known for its small size and low power consumption.',
  keywords : 'beacon, IoT, wireless communication, indoor positioning, location tracking, Bluetooth, ble, Plutotcon, Plutocon, iBeacon, Orbro, Orbro, Kontech',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'What is a Beacon? | Orbro, Asia\'s No. 1 Beacon Company :: Orbro Blog',
  ogUrl : 'https://orbro.io/en/blog/beacon',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDxa-ucISW1k_6fC9t-ZpGPIV1qRXWXcZO1OhYuIe-j3MLl1BdXVIIv40aG69pNCT6AD0WANYJ1p4OjzDQF9yM8_m5CaFglWOtunF7ZF0uySt_dYAB4pE7shLBOrdeIkyxz_3esBC_Qd-x3FmnZ4jLNcZazyIYAPR_ZuRbLW2E_sui_6vQWc2_iABmwSImfVtoyWAhaCjoHOk7UonwVJTlXVq-NJmIqcpT0-KgFYxoTYR_i_qaS1PR8hzLVcOzzk1Z7ng11UP9IaQiWcX6pFAZnxvGsUJDNz9eiIxIRifGlSF2rGl3O6KQKRRdOU1LATUHY1Rkym6F5nLo87mxlA9iqybXGIED_3BG2ywgaf3uYgBloZH3CGqi4IMUUD-dN7cbbwxrQyiEppY9GA0xNk9uatZggX5TaYItKRL0fGmJXsHn18bQHWue-OCSVs_IjMmQ6omlyiJiQAVMSGu65C3hd8ZIQGZDq0EzutRLIXyb7f6OVVcogIw8fmAHnyhzYh-ffT1Rl0kp7BSYB0y-ooqoM_z3ut5Znu5TWC4EndXrRa4BB7KOnh--fa8H0-rbNiorUZmhoedmMZHCUuHKEjiyshQaX9UiAn2Rr-Uvtuvqs-TYIRAHroGgOBrzaGBEycwr3rifKxJx-fBbAO8fe7xhZD8RNeniCCIXkf4o_crQdpMZuDeKh5_f7URFr8NU_5vs74I1lhdV_Bgq5bYEI0-b7N7JV4-Wr_zSbOLTMb5EZ-vyj43hGUKLdgOUHTYYNP8plRV5_UMElexKEid5ZmX2aj1QrTT9zBm5c_SunfkZOEK6kY6GuV7NagmSgQDhcVmX_Qgf9tJoEw07dJxwXPxOzZPO5nZDkX_xA1oDKNNsgNpPXuVjmEI2K_O-syucjYFlVnVagL7aQ-V4givU9qKYBasb6s2hfajR4L4BAvyBp-EiItjTy3dK4H8fk1sdf6OlqMfNS3DQqBu5gpyMD28HyW0gk7wC-hs0C3EsglleW016V9ELEcMiX9OTvM1dozJQFzpMEXve9fw44h-D0lW5MuJIfDb_HvD4FB0ZVrab-8kKD-99NVk2mfZigqE3tPXhEb559joMsf5UYlRvjLz7DqnBmNwPBMQavjtKTmwq6gPHsLn4hR2ENgscKrdmGFYbt-pSwwinouRHIWBOAeLbIU4R9AOWId11R5oG0IFEuiAib3dTKuzObWCeWjN4aP-24uX46l_DnyL1K9zDS3MOZbqbQOaTjkTIsx7FlLrk1iw5P3RxjboS7VC048bElpy68OsGGr5c30D4owv38GeXx3eQm4P8RDq7Ubxe9W3Z1a-CNQhDMGEBpns6BLrZ2d8AO3TaS7HhGcvmbbGMsdgmoPRZgElmoN6l1a7ktrDjuTD2NWNvLUJ6d7WxCpEG9S5RClt8JSoR884w_PQAONR_WMbvHpL-xm_fuWqWDDpYT1_7ScbWFIwBwUTop8jrIiPrrgrHHNAVloR9YwiH3J_U5jMMK9rNENYv9rHahEOLrwcJzGzbnbbkocERqVusvbsC1pl-fPUAVDjtgKf89d9X6FPsVJ95xED_hnGthC4JfXqLnPbKG_=w3356-h2242',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'What is a Beacon? | Orbro, Asia\'s No. 1 Beacon Company',
  date : '2023-11-08',
  tag1 : '#beacon',
  tag2 : '#Bluetooth',
  tag3 : '#BLE',
  tag4 : '#ORBRO',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc : `A beacon is one of the innovative technologies in the modern digital age that is applied in our daily lives and business sectors. Let's find out what a beacon is, how it works, and its application areas.`,
      dot : ''
    },
    {
      head : 'What is a Beacon?',
      subHead : '',
      desc :'A beacon is a small radio transmitter device that uses wireless communication to deliver information to smart devices at specific locations. It primarily uses Bluetooth Low Energy (BLE) technology and is known for its small size and low power consumption. Beacons can notify their presence and location to the surrounding environment.',
      dot : ''
    },
    {
      head : 'How Beacons Work',
      subHead : '',
      desc :'Beacons have a very simple working principle. They periodically emit signals, and smart devices (e.g., smartphones) in the vicinity detect these signals and determine the location of the beacon. This enables smart devices to perform specific actions at a particular location.',
      dot : ''
    },
    {
      head : 'Application Areas',
      subHead : '',
      desc :'Beacons are used in various fields, and they are particularly gaining attention in the following areas:',
      dot : {
        a : '<strong>Marketing and Advertising:</strong> Stores or museums can use beacons to provide customers with special discounts or information, making advertising more effective.',
        b : '<strong>Location-Based Services:</strong> Beacons can provide accurate location information indoors and enhance navigation capabilities.',
        c : '<strong>Smart Homes and IoT:</strong> Beacons can connect various smart devices in a home, improving automation and convenience.',
        d : '<strong>Healthcare:</strong> They are used in patient monitoring and medical devices to enhance the quality of medical services.'
      }
    },
    {
    video : video,
    link : "https://orbro.io/en/enterprise/medical/patient",
      head : 'Advantages',
      subHead : '',
      desc :'Beacon technology has several advantages compared to other location-based technologies.',
      dot : {
        a : '<strong>Accuracy:</strong> Beacons provide precise location information and work even in environments where GPS signals do not reach, such as indoors.',
        b : '<strong>Low Power Consumption:</strong> Beacons have a long battery life due to their low power consumption.',
        c : '<strong>Ease of Use:</strong> Beacons are easy to set up and manage.'
      }
    },
    {
      head : 'Conclusion',
      subHead : '',
      desc :'Beacon technology is one of the significant technologies in the modern era that has a profound impact on our daily lives and businesses. Its application areas continue to expand, and it is expected to provide a variety of innovative services and opportunities in the future. With high expectations for the future of beacons, how about utilizing this technology to make our lives smarter and more convenient?',
      dot : ''
    },

  ],

  link : {
    page : 'access',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.auto.accessauto.title,
        title : productPurchase.purchase.auto.accessauto.title,
        serial : productPurchase.purchase.auto.accessauto.serial,
        desc : 'A product specialized for automatic door access, allowing contactless entry by replacing existing automatic door sensors and installing a mobile app.',
        img : productImg.product.accessauto.key1
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/en/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/en/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/en/enterprise/logistics/asset'
      }
    ]

  }


}
