import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo4.mp4";
export default {

    banner: {
        title: '库存定位和管理',
        desc: '库存管理是支持供应链的效率和顺畅运作的重要组成部分。实时追踪库存的位置可以有效监控库存水平并优化库存移动。例如，根据物品的受欢迎程度安排库存并优化库存周转率，有助于节约成本并防止库存短缺或过剩。',

    },

    video: {
        title: '智能物流库存管理方法。',
        desc: '实时定位(RTLS)技术可以实时追踪库存的位置。<br/>通过准确、快速、简单地管理库存，实现<strong>最佳效率</strong>。',
        play: video
    },

    func: [
        {
            title: '自动化出入库',
            desc: '库存通过特定地点时，自动生成出入库记录。这些数据准确可靠，可以避免人为错误，节约人力。',
            sub1: '高精度提供',
            icon1: IconRulerGray,
            sub2: '快速数据联动',
            icon2: IconShareGray,
            video: subVideo1

        },
        {
            title: '精确库存数量管理',
            desc: '根据物品的受欢迎程度安排库存并优化库存周转率，有助于节约成本并防止库存短缺或过剩。',
            sub1: '实时现状了解',
            icon1: IconClockGray,
            sub2: '统计数据提供',
            icon2: IconChartBarGray,
            video: subVideo2
        },
        {
            title: '节省时间',
            desc: '有了准确的库存位置信息，物流工作者可以迅速找到物品并将其移动到需要的位置。这有助于缩短工作时间并提高生产力。',
            sub1: '实时定位',
            icon1: IconBinocularsGray,
            sub2: '高精度提供',
            icon2: IconRulerGray,
            video: subVideo3
        },
        {
            title: '实时定位',
            desc: '有助于防止库存损失和盗窃。通过为库存分配位置并实时追踪，可以迅速发现库存丢失或盗窃的情况。这有助于最小化库存损失并采取回收措施。',
            sub1: '快速数据联动',
            icon1: IconShareGray,
            sub2: '实时现状了解',
            icon2: IconClockGray,
            video: subVideo4
        },


    ],



    subfunc: {

        title: '多种功能',
        desc: '物流行业的核心是供应链管理。而库存管理是支持供应链的效率和顺畅运作的重要组成部分。<br/>为了建立最优化的系统，Orbro提供多种功能。',

        data: [
            {
                icon: IconChartRadius,
                title: '报告和分析',
                desc: '基于现场数据生成报告和分析资料。',
            },
            {
                icon: IconBell,
                title: '实时警报功能',
                desc: '在出现库存数量异常情况时发送警报，可以及时应对。',
            },
            {
                icon: IconRoute,
                title: '轨迹历史查询',
                desc: '查询库存的移动路径并数据化。',
            },
            {
                icon: IconDashboard,
                title: '现状了解',
                desc: '了解特定库存当前的移动情况。',
            },
            {
                icon: IconWarning,
                title: '越界检测',
                desc: '检测库存是否超出指定位置。',
            },
            {
                icon: IconSearch,
                title: '轨迹模式分析',
                desc: '分析库存在现场使用情况。',
            },
            {
                icon: IconChainLink,
                title: '数据联动',
                desc: '与RFID、物联网、安全、视频等系统连接，实现高效利用。',
            },
            {
                icon: IconSecurity,
                title: '数据安全',
                desc: '保持高数据安全水平，提供可信赖的解决方案。',
            },


        ],
    },

    system: {
        title: '系统组成',
        img: rtlsSystem
    },

    productTitle: '产品配置',
    product: [

        {
            tag: 'assetPackage'
        },
        {
            tag: 'workerPackage'
        },
    ],


    contents: [
        {
            data: 'logisticsSafety'
        },
        {
            data: 'constructionAsset'
        },
        {
            data: 'blogRTLS'
        },
        {
            data: 'blogForklift'
        },

    ]

}
