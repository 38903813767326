
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  StyledNewsLetterBox, StyledSubscribeBox, StyledDownloadPolicyBox,
  StyledDownloadMailBox, StyledDownloadMail,
} from "./BlogNewLetter.style";
import axios from "axios";
import emailjs from 'emailjs-com';
import i18n from "@pages/i18n";
import { StyledDownloadSubmit } from "@pages/Main/Common/NewsLetter/NewsLetter.style";
import { HeadingH3blue } from "@components/Text/Heading/Heading.style";
import { StyledDisplay } from "@components/Text";
import { LabelColor, Spacing } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import { StyledDownloadPolicy, StyledDownloadPolicyLink, StyledDownloadPolicyLinkText } from "@pages/Enterprise/Solution/components/Newletter/NewsLetter.style";

export interface RequestFormData {
  email: string,
}

//블로그 뉴스레터

const BlogNewLetter = ({ onSuccess }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // Translation

  const onDone = () => {
    onSuccess();
  }

  const [email, setEmail] = useState<string>('');



  const onChange = (e) => {


    //Email 콜백
    const email = e.target.value;
    setEmail(email);
  };


  // Input event
  const handleInput = (event, callback) => {
    const value = event.target.value;
    callback(value);
  };

  const currentLang = i18n.language;

  const validateForm = () => {

    let msgEmpty = '';
    let msgForm = '';

    if (currentLang === 'jp') {
      msgEmpty = '空の値があります';
      msgForm = 'メールの形式が正しくありません。';
    } else if (currentLang === 'en') {
      msgEmpty = 'There are empty values';
      msgForm = 'Email format is not valid=.';
    } else {
      msgEmpty = '비어있는 값이 있습니다';
      msgForm = '이메일 형식이 올바르지 않습니다.';
    }

    if (email === '') {
      alert(msgEmpty)
      return;
    }
    else if (!email.includes('@') || !email.includes('.')) {
      alert(msgForm)
      return;
    }
    onDone();
    subscribe();
    if (!email.includes('tech4.')) {
      onClickEmail();
    } else {
      onClickEmailSpam();
    }
  };



  const onClickEmail = () => {
    emailjs.init('SoQtE3CThBY_wpeDn');
    const mailForm = {
      email: email,
    }

    emailjs.send('service_7yv5b7d', 'template_8o3j6bm', mailForm)
      .then((response) => {
        console.log('이메일 보내기 성공:', response);
      })
      .catch((error) => {
        console.error('이메일 보내기 실패:', error);
      });

  }


  const onClickEmailSpam = () => {
    emailjs.init('SoQtE3CThBY_wpeDn');
    const mailForm = {
      email: email,
    }

    emailjs.send('service_7yv5b7d', 'template_zt2sf0o', mailForm)
      .then((response) => {
        console.log('이메일 보내기 성공:', response);
      })
      .catch((error) => {
        console.error('이메일 보내기 실패:', error);
      });

  }

  const subscribe = () => {

    const stibee = 'https://api.stibee.com/v1/lists/253278/subscribers'
    const apiKey = '98bfb7a271ec803ad3d00fb3e5f5402d66b9ea53af2df75fcf5b0995b937696ea2c89f06d924bd28b75edb6bf58d10c364de29acde10a0a00946b9d48a13215f'
    const subData = {
      "eventOccuredBy": "SUBSCRIBER",
      "confirmEmailYN": "N",
      "subscribers": [
        {
          "email": `${email}`
        }
      ]
    }

    axios.post(stibee, subData, {
      headers: {
        'AccessToken': apiKey,
        'Content-Type': 'application/json',
      }
    })
      .then(function (res) {
        console.log(res);
      })
      .catch(function (err) {
        console.log(err);
      })
      ;

  };



  return (
    <StyledNewsLetterBox>

      <HeadingH3blue size="xxsmall" >
        <Trans i18nKey={"blog.newletter"} />
      </HeadingH3blue>

      <Spacing pixel="8" />
      <StyledDisplay size="small">
        <p>
          <Trans i18nKey={"blog.info"} />
        </p>
      </StyledDisplay>

      <StyledSubscribeBox>


        <StyledDownloadMailBox>
          <StyledDownloadMail
            value={email}
            placeholder={t(`blog.placeholder`) || ''}
            onInput={(e) => { handleInput(e, setEmail) }}
            onChange={onChange}
          />
        </StyledDownloadMailBox>


        <StyledDownloadSubmit onClick={validateForm} bgcolor={"black"} >
          <DivAllCenter>
            <LabelColor size="medium" color={"#fff"}> <Trans i18nKey={"blog.subscribe"} /> </LabelColor>
          </DivAllCenter>
        </StyledDownloadSubmit>


      </StyledSubscribeBox>

      <StyledDownloadPolicyBox>

        <StyledDownloadPolicy size="small"><Trans i18nKey={`simple.terms1`} />  </StyledDownloadPolicy>

        <StyledDownloadPolicyLink href="/terms/2" target="_blank" >
          <StyledDownloadPolicyLinkText size="small"><Trans i18nKey={`simple.terms3`} /> </StyledDownloadPolicyLinkText>
        </StyledDownloadPolicyLink>
        <StyledDownloadPolicy size="small"><Trans i18nKey={`simple.terms4`} /> </StyledDownloadPolicy>

      </StyledDownloadPolicyBox>


    </StyledNewsLetterBox>

  )
};

export default BlogNewLetter; 