import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

import video from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

export default {

    
  helmet : '| 디지털 전환(DX)이란?',
  desc : '디지털 전환은 디지털 기술을 활용하여 일상과 비즈니스의 운영 방식을 디지털화 하는 것을 말합니다. 더 효율적이고 생산적인 방법으로, 시장의 변화에 대응하며 새로운 가치를 창출할 수 있게 됩니다. 예를 들어, 작업자나 재고의 위치를 실시간으로 추적하여 관리할 수 있습니다.',
  keywords : '디지털 전환, 디지털 트윈, 디지털 혁신, 스마트 시티, 비즈니스 인텔리전스, BI, 혁신, 스마트 솔루션, 4차 산업혁명, 비즈니스, dx, 오브로, 콩테크, rtls, orbro, 위치추적',

  ogTitle : '',
  ogDesc : '',
  ogSite : '디지털 전환(DX)이란? | 디지털 트윈 전문 플랫폼, 오브로',
  ogUrl : 'https://orbro.io/blog/dx',
  ogKey : '',
  ogImg : '',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '디지털 전환(DX)이란?',
  date : '2023-11-01',
  tag1 : '#디지털전환',
  tag2 : '#디지털트윈',
  tag3 : '#스마트시티',
  tag4 : '#오브로',
    
  // <br/>
  text : [
    { 
      head : '',
      subHead : '',
      desc :'디지털 전환은 기존의 프로세스와 비즈니스 모델을 디지털 기술로 대체하거나 개선하는 과정을 의미합니다. 이것은 전통적인 업무 방식을 현대적이고 효율적인 방법으로 업그레이드하고, 디지털 기술의 활용을 통해 미래의 기회를 찾는 과정입니다. 디지털 전환은 기업에서부터 정부, 의료, 교육 및 여타 다양한 산업 및 분야에 영향을 미치고 있으며, 그 중요성은 계속해서 증가하고 있습니다. <br/><br/>디지털 전환의 핵심 목표는 다음과 같습니다.<br/><br/><br/><strong>효율성 향상 : </strong>더 나은 프로세스와 작업 방식을 통해 생산성을 증가시키고 비용을 절감합니다.<br/><br/><strong>고객 경험 개선 : </strong>디지털 기술을 통해 고객에게 더 나은 서비스와 경험을 제공합니다.<br/><br/><strong>혁신 촉진 : </strong>새로운 아이디어와 비즈니스 모델을 개발하여 시장에서 경쟁력을 확보합니다.<br/><br/><strong>데이터 활용 : </strong>데이터를 수집, 분석하고 활용하여 의사 결정에 도움을 줍니다.',
      dot : ''
    },
    {
      head : 'RTLS 기술과 디지털 트윈',
      subHead :'',
      desc : '실시간 위치추적 시스템(RTLS) 기술은 디지털 전환의 핵심 구성 요소 중 하나로, 물리적인 공간에서 물체, 자원 또는 인물의 위치를 정확하게 파악하는 기술입니다. RTLS는 센서, 무선 통신, 데이터 분석 및 시각화 기술을 통해 실시간 위치 정보를 수집하고 제공합니다.<br/>디지털 트윈은 RTLS 기술과 밀접하게 관련이 있습니다. 디지털 트윈은 현실 세계의 물리적 개체에 대한 디지털 복제물로, 이를 통해 물리적 객체의 상태, 위치 및 상호 작용을 실시간으로 모니터링하고 시뮬레이션할 수 있습니다. RTLS 기술은 디지털 트윈을 유지하고 업데이트하는 데 필수적인 데이터를 제공합니다.',
      dot : ''
    },
    {
      video : video,
      link : "https://orbro.io/enterprise/logistics/asset",
      head : '실제 사례',
      subHead :'',
      desc : '디지털 트윈에 반영하면 산업 현장의 물리적 상태를 디지털로 시뮬레이션할 수 있습니다.<br/>제조업에서는 생산 라인의 움직임을 모니터링하고 최적화하며, 의료 분야에서는 환자 위치를 추적하고 의료 장비를 관리하는 데 사용됩니다. 또한 스마트 시티 프로젝트에서는 도로 교통과 재난 관리를 향상시키는 데 적용되며, 물류 및 유통 분야에서는 재고 관리와 주문 처리를 최적화합니다.<br/><br/>예를 들어, 고정 자동차 로봇이 상품을 이동하고 배치할 때, RTLS 기술은 이들 로봇의 위치를 추적하고 디지털 트윈을 통해 예측 모델을 구축하여 충돌을 피하고 경로를 최적화할 수 있습니다.<br/>또한 재고 수준을 최적화하고 재고 부족 또는 과잉 재고 문제를 예방하며, 이러한 데이터 기반 인사이트를 활용하면 물류 프로세스의 효율성을 높이고 고객 서비스 품질을 향상시킬 수 있습니다.',
      dot : ''
    },
    {
      head : '결론',
      subHead :'',
      desc : '디지털 전환(DX)은 현대 사회에서 기업과 조직의 생존과 발전을 위한 중요한 요소로 부상하고 있습니다. RTLS 기술과 디지털 트윈은 이러한 전환을 주도하는 핵심 기술 중 하나로, 물리적인 세계를 디지털로 변환하여 미래를 준비하는 데 중요한 역할을 합니다. 디지털 전환을 통해 우리는 효율성을 향상시키고 미래의 도전에 대비할 수 있을 것입니다.',
      dot : ''
    },

  ],

  link : {
    page : 'rtls',
    numb : '1',
    product : [
        {
          text : productPurchase.purchase.asset.assetTracking.title,
          title : productPurchase.purchase.asset.assetTracking.title,
          serial : productPurchase.purchase.asset.assetTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.assetTracking.key1
        },
      {

      }

    ],
    solution : [
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/enterprise/manufacturing/asset'
      }
    ]

  }


}