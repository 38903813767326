import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

import video from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

export default {

  helmet: '| 什么是数字转型（DX）？',
  desc: '数字转型是指利用数字技术来数字化日常生活和业务运营方式的过程。它可以实现更高效和更有生产力的方法，帮助企业适应市场变化并创造新的价值。例如，它可以实时跟踪和管理工人和库存的位置。',
  keywords: '数字转型，数字双胞胎，数字创新，智慧城市，商业智能，BI，创新，智能解决方案，第四次工业革命，业务，DX，Orbro，ConTech，RTLS，Orbro，位置跟踪',

  ogTitle: '',
  ogDesc: '',
  ogSite: '什么是数字转型（DX）？ :: Orbro博客',
  ogUrl: 'https://orbro.io/cn/blog/dx',
  ogKey: '',
  ogImg: '',
  ogType: '网站',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '什么是数字转型（DX）？',
  date: '2023-11-01',
  tag1: '#数字转型',
  tag2: '#数字双胞胎',
  tag3: '#智慧城市',
  tag4: '#ORBRO',

  text: [
    {
      head: '',
      subHead: '',
      desc: '数字化转型是指用数字技术替代或改进传统流程和业务模式的过程。它包括通过采用数字技术升级传统的业务方法，以实现现代和高效的方式，并通过数字技术的应用寻找未来的机会。数字化转型影响着各种行业和领域，从企业到政府、医疗、教育以及其他各种领域，其重要性不断增加。<br/><br/>数字化转型的主要目标如下：<br/><br/><br/><strong>提高效率： </strong>通过改进流程和工作方法，提高生产率并降低成本。<br/><br/><strong>改善客户体验： </strong>通过数字技术为客户提供更好的服务和体验。<br/><br/><strong>促进创新： </strong>开发新的思路和业务模式，以在市场上获得竞争力。<br/><br/><strong>数据利用： </strong>收集、分析和利用数据以辅助决策。',
      dot: ''
    },
    {
      head: 'RTLS技术和数字双胞胎',
      subHead: '',
      desc: '实时位置跟踪系统（RTLS）技术是数字转型的核心组成部分之一，准确跟踪物体、资源或个体在物理空间中的位置。 RTLS通过传感器、无线通信、数据分析和可视化技术实时收集和提供位置信息。数字双胞胎与RTLS技术密切相关。它是实际世界物理对象的数字复制，允许实时监控物理对象的状态、位置和互动。 RTLS技术提供必要的数据以维护和更新数字双胞胎。',
      dot: ''
    },
    {
      video: video,
      link: "https://orbro.io/enterprise/logistics/asset",
      head: '实际案例',
      subHead: '',
      desc: '当集成到数字双胞胎中时，它可以模拟工业环境中的物理条件。在制造业中，它可以监控和优化生产线的运动，而在医疗领域，它可以跟踪患者的位置并管理医疗设备。在智慧城市项目中，它可以增强道路交通和灾难管理，而在物流和分销中，它可以优化库存管理和订单处理。例如，在自动驾驶车辆移动和放置货物的情况下，RTLS技术跟踪这些车辆的位置，构建通过数字双胞胎避免碰撞并优化路线的预测模型。此外，它可以优化库存水平，防止库存不足或过剩，并利用这些数据洞察，提高物流流程的效率并提高客户服务质量。',
      dot: ''
    },
    {
      head: '结论',
      subHead: '',
      desc: '数字转型（DX）已经成为现代社会中企业和组织生存和增长的关键因素。 RTLS技术和数字双胞胎是推动这一转型的关键技术，将物理世界转化为数字以为未来做好准备。通过数字转型，我们可以提高效率并为未来的挑战做好准备。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: '此产品将UWB终端连接到工人或设备，以协助实时位置跟踪。',
        img: productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution: [
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      },
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/cn/enterprise/manufacturing/asset'
      }
    ]

  }

}
