
export default {
    back: 'Go back to ORBRO blog',
    newletter: 'Subscribe to the newsletter',
    info: 'Receive various news from Orbro!',
    placeholder: 'Please enter your email',
    subscribe: 'Subscribe',
    terms1: "By providing information, You agree to",
    terms2: "",
    terms3: "Orbro's Privacy Policy",
    terms4: '',

    priceUnknown: 'Inquire',
    more: 'Read more',
    less: 'Collapse',

    recent: 'Recent blog posts',
    share: 'Did you like the article? <br/> Share it',

    price: 'Price : ',
    product: {
        title: 'Explore Product Configurations',
        access: 'Check out the innovative access control product configurations.',
        rtls: 'Check out the innovative real-time location tracking system product configurations.',
        camera: 'Check out the camera system product configuration.'
    },


    uwbVideoDesc: '<br/><strong>(ORBRO Solution using UWB technology, Hospital)</strong>',
    bleVideoDesc: '<br/><strong>(ORBRO Solution using UWB technology, Manufacturing Facility)</strong>',
    svVideoDesc: '<br/><strong>(ORBRO Solution using Camera Technology, Exhibition Hall)</strong>',
    requestHead: 'Please feel free to',
    request: 'contact us ',
    requestTail: 'for more detailed information..'

}