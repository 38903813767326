import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


    helmet: '',
    desc: 'RTLS 기술을 활용한 제조공장의 재고 관리와 생산 공정 최적화에 대한 최신 정보와 전략. 실시간 위치 추적으로 안전, 생산성, 그리고 경쟁력을 향상시키세요.',
    keywords: '제조공장, RTLS 기술, 재고 관리 시스템, 실시간 위치 추적, 제조업체, 생산 공정 최적화, 안전 및 보안, 자동화, 물류 관리, 제조업 혁신, 제조업 생산성, 로봇 활용, 5G 네트워크, 인공지능, 머신 러닝, 생산 일정 관리, 공장 운영 최적화, 재고 추적, 위치 기반 시스템, 경쟁력 향상, 실시간 위치 추적, RTLS, 오브로, 콩테크, ORBRO',

    ogTitle: '',
    ogDesc: '',
    ogSite: '제조공장 재고 관리 시스템의 미래 :: 오브로 블로그',
    ogUrl: 'https://orbro.io/blog/nextManufacturing',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxbC6Lmz5d8iN1v3jEMtSZe3Ob88JgI_jXXAfJ7GZaTrf8KH3DEO1m4HlYFIDS9bFuaJ56cZ_wEcBOXyqi7Rd1uXIPtcB1SZL5Zzp4Zl2tEjAhAsyq98MX-gfwKbsfAr4dO1q5vsbz2K4nTUWHruzU-5hBlcQ1yxNQlsPiYw4RphqkypMB-pwo0G3P-xK2AXu-BK2gkkGECBMSaqNR4kGBEpmdgxTWhwccWsjJ-q7e0HIujysRfM0l0ypLuO4Tq-jlmwITla4reB_nvTWUkbQKvS3L0qLAUKFsKGUijjxU1e2K4uu9c2imXUJIAMcSwzhUuYwW68muAKugSd95IOSWlAtP8ygMwSe62DG3ytNQxSEOVw77XQP8MXzF61yzQn7TttUsTIEbaSPUkfS82CVlcwFZfj6Eu1TGVGPIBtzA5h2Ye0wCkx1uG2j9YWPT4I3onzdUX6_M7mLn--deX_zstjJWguDI_2zuFy31WB_lALFYps0Wm-iD9E_eCocJkE8sKpKvVfLD8uf1wiLAGX6r_6LtzfaGAxI3m3o1IG2MMTTXnMH6y-O3M6aUz3yqjmxF4T92JTEMFPIboDM657wvRyI-U9Qpt97oMe5HnrqwIa08nTpbjCqaT4H7tCiVm3ms4KDzEpQDKEOqlJIuc--1X0qcGvt7mp8YQAErkrY3fX0wyvBJbOjLS9ckhMpZnvQjz3HjWHSYqtQfo0_bre3v0tKk-dRNj8V9lo3VQEG9NSGTAAJWe-TAVQ6WSwGZpY5qS5C39YC5uFkS6CjMs4lFwQaiGWSqkBZu38_UFyvcZqPxFXU6-zwQJXf5P5VvNO9vV9PxIA45viCVrRJ5_N9NHS1KqxQnQf1dbqGmhbVcF9bE4ZQuaZPelOXIlXJTNA1buHW1HlFOWAr7-ptDHQRaOi0XStG1jluCzkkOqa8rmsmVDq54dvkmSTQToTR_iiZz_sHItPoyJrSpwOBsdj0JILyCwzJCON67Fj5ekj3Lp70M-oY5FfJ0h2SGgTIBR-AEW83ZzuSV7LCetAq4DfJJjFWberG95uPpPBTxfxnUDm8UD4tuCyEeqC0-aaP-6R9rJ__4kDk-HhcrkgYQ52rX2eUTWvX_HaBu_czaZ4_7n0W97P0HAXBII94mQ4CI-nyZKtya-i3R4Af6g826lPfcA-BN8mbIh_bvRi3dvrQD6-ikeEvXvtTDzkvqJ4NSW9UpYBoAAMx4u4PEKvDX5M6o10FceVzq_SJtSHC3PQ4LvvKBrdakrFs7tGHjQjUGEyyLWU-hKwZq2ImS95HpI9MTvV8MJCGQGuUcEn4PGilBE_Y8gik5a7EeanF5XYjw_sHHwXZXT3zq03vRnmXVoal3_zVl8fkohp_Ja3JZopz1hKqUtZj6TkyeoF8plJmaHXlNu_Sc2lG7JEeFwlfKdG-ZOhpUk387Q1YuFalokq_NBDxN471dJmii2uBokKcm0aZWHMkJE8JLrb5NF7jyCJawF6fNMUK-yh-OJvWFcNLfXHznai5Bdfx6bV0Uq1Q2FQIHJAhtflvB7IZcFiQJ3H8Ul-rWYyOHnxDTZJ5yt5IbVKmudE_SNi6ASGmkwwg=w2000-h4260',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',


    title: '제조공장 재고 관리 시스템의 미래',
    date: '2023-10-16',
    tag1: '#제조시설',
    tag2: '#재고관리',
    tag3: '#스마트제조',
    tag4: '#RTLS',

    // <br/>
    text: [
        {
            head: '',
            subHead: '',
            desc: '제조업체들은 공장 운영 및 재고 관리를 개선하는 방법을 찾는 데 지속적으로 노력하고 있습니다. 이러한 노력은 기업의 효율성, 수익성, 및 경쟁력 향상을 위한 중요한 과제로 여겨집니다. 최근 몇 년 동안, 실시간 위치 추적 시스템(RTLS) 기술은 제조 공장 재고 관리에 혁명을 일으키고 있으며, 이 글에서는 RTLS 기술이 제조 공장 재고 관리에 어떻게 큰 도움을 주는지 살펴보겠습니다.',
            dot: ''

        },
        {
            head: 'RTLS란 무엇인가요?',
            subHead: '',
            desc: 'RTLS는 "Real-Time Location System"의 약자로, 실시간 위치 추적 시스템을 의미합니다. 이 기술은 다양한 센서 및 통신 기술을 활용하여 물체나 사람의 위치를 실시간으로 추적할 수 있게 해주는 시스템입니다. RTLS는 GPS, RFID, 센서 네트워크, 무선 통신 등 다양한 기술을 통합하여 사용자에게 정확하고 실시간의 위치 정보를 제공합니다.',
            dot: ''
        },
        {
            head: '제조공장에서의 RTLS 활용',
            subHead: '1. 재고 추적 및 관리',
            desc: 'RTLS는 제조 공장 내의 원자재, 부품, 반제품, 그리고 완제품의 위치를 실시간으로 추적할 수 있습니다. 이것은 재고 관리를 효율적으로 관리할 수 있게 해주며, 제조업체는 필요한 재고를 쉽게 찾을 수 있고 낭비를 줄일 수 있습니다. 또한, 재고의 위치 및 이동을 실시간으로 파악하므로 재고 부족이나 과다를 방지하고 물류 비용을 절감할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '2. 생산 공정 최적화',
            desc: 'RTLS는 제조 라인에서 작업자와 장비의 위치를 추적하여 생산 공정을 최적화할 수 있습니다. 이것은 생산성을 높이고 생산 일정을 준수하기 위한 중요한 요소입니다. 또한, 제조업체는 작업자와 장비의 작업 흐름을 분석하여 병목 현상을 식별하고 개선할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '3. 안전 및 보안 강화',
            desc: 'RTLS는 작업자의 안전을 강화하는 데 큰 역할을 합니다. 고위험 구역에서 작업 중인 작업자의 위치를 추적하고 위험 상황을 사전에 감지함으로써 사고를 방지하고 대처하는 데 도움을 줍니다. 또한, 장비 및 재고의 안전을 보장하며 도난을 방지할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '4. 자동화 및 로봇 활용',
            desc: 'RTLS는 자동화된 로봇 시스템과 통합하여 공장 내에서 로봇의 작업 위치와 이동을 정확하게 관리할 수 있습니다. 이로써 로봇과 인간의 협업이 효율적으로 이루어집니다. 로봇은 재고를 운반하거나 공정을 자동으로 수행할 때 RTLS를 활용하여 정확한 경로 및 작업 위치를 설정할 수 있습니다.',
            dot: ''
        },
        {
            head: 'RTLS의 미래',
            subHead: '',
            desc: 'RTLS 기술은 계속해서 진화하고 발전하고 있으며, 제조공장 재고 관리 시스템의 미래에는 더 많은 혁신이 예상됩니다. 몇 가지 향후 가능성은 다음과 같습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '1. 5G 네트워크 활용',
            desc: '5G 네트워크의 보급이 늘어나면, RTLS의 연결성과 대역폭이 향상될 것입니다. 이로써 더 많은 데이터를 실시간으로 전송하고 처리할 수 있게 될 것입니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '2. 인공지능과 머신 러닝 적용',
            desc: 'RTLS 데이터를 활용하여 더 정확한 예측 및 의사 결정을 내리기 위해 인공지능과 머신 러닝 기술이 적용될 것입니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '3. 생태계 확장',
            desc: 'RTLS 기업들은 다른 제조 공장 시스템과 통합할 수 있는 플랫폼을 개발하고 제공하며, 제조공장 생태계를 확장시킬 것입니다.',
            dot: ''
        },
        {
            head: '마무리',
            subHead: '',
            desc: 'RTLS 기술은 제조 공장 재고 관리 시스템에 혁명적인 변화를 가져왔으며, 그 미래는 더욱 밝아 보입니다. 이 기술을 활용하는 제조업체들은 효율성을 향상시키고 경쟁력을 강화할 것입니다. RTLS의 미래 발전을 주시하면서, 제조 공장 재고 관리 분야에서 혁신을 추구하는 기업은 미래에 더 큰 성공을 거둘 것입니다.',
            dot: ''
        },
    ],


    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/enterprise/medical/patient'
            },
            {
                img: medicalWaste.thumb,
                data: 'medicalWaste',
                link: '/enterprise/medical/waste'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/enterprise/office/asset'
            }
        ]


    }

}