import { FlexStyle, Vertical } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";

export const StyledAdvantage = styled.div`
  width: 100%;
  padding: 56px 48px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.CONTENT_PRIMARY};

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding: 48px 36px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 48px 24px;
  }
`;

export const StyledAdvantageItemIcon = styled.img`
  width: 48px;
  height : 48px;
  display : flex;
  object-fit : contain;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width: 36px;
  } 
`;
