import React, { PropsWithChildren, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";



interface ModalDefaultType {
  pageName : string
  setPageName : (page) => void;
  templateID : (template) => void;
}



function Template({
  pageName,
  setPageName,
  templateID
  
}: PropsWithChildren<ModalDefaultType>) {

  const [template, setTemplate] = useState<string>('');
  const [page, setPage] = useState<string>('');

  const location = useLocation();

  useEffect(() => {

    if (location.pathname.includes('/en/')){

      if (pageName === 'constructionSafety') {
        setTemplate('template_6pik76u');
      } else if (pageName === 'constructionAsset') {
        setTemplate('template_m9rsl2h');
      } else if (pageName === 'constructionEquipment') {
        setTemplate('template_wo7rjxq');
      } else if (pageName === 'logisticsAsset') {
        setTemplate('template_6h38j6j');
      } else if (pageName === 'logisticsSafety') {
        setTemplate('template_egexens');
      } else if (pageName === 'officeAsset') {
        setTemplate('template_xiqotee');
      } else if (pageName === 'manufacturingProcess') {
        setTemplate('template_gql1ywo');
      } else if (pageName === 'manufacturingAsset') {
        setTemplate('template_bil9dsn');
      } else if (pageName === 'medicalPatient') {
        setTemplate('template_961u37a');
      } else if (pageName === 'medicalWaste') {
        setTemplate('template_olc7oyb');
      } else if (pageName === 'retailExhibition') {
        setTemplate('template_63selv8');
      }          
    } 
    
    
    else if (location.pathname.includes('/jp/')){

      if (pageName === 'constructionSafety') {
        setTemplate('template_r7bc2ov');
      } else if (pageName === 'constructionAsset') {
        setTemplate('template_cfokcmt');
      } else if (pageName === 'constructionEquipment') {
        setTemplate('template_mlajmsa');
      } else if (pageName === 'logisticsAsset') {
        setTemplate('template_01wnr0l');
      } else if (pageName === 'logisticsSafety') {
        setTemplate('template_bqpso5l');
      } else if (pageName === 'officeAsset') {
        setTemplate('template_mu8kyxl');
      } else if (pageName === 'manufacturingProcess') {
        setTemplate('template_3v29n7l');
      } else if (pageName === 'manufacturingAsset') {
        setTemplate('template_7nl7lks');
      } else if (pageName === 'medicalPatient') {
        setTemplate('template_nslnrd5');
      } else if (pageName === 'medicalWaste') {
        setTemplate('template_haexsui');
      } else if (pageName === 'retailExhibition') {
        setTemplate('template_zgd1y4h');
      }          
    } 
    
    
    else {
      if (pageName === 'constructionSafety') {
        setTemplate('template_7tgxod8');
      } else if (pageName === 'constructionAsset') {
        setTemplate('template_2jki8c7');
      } else if (pageName === 'constructionEquipment') {
        setTemplate('template_8o6dndr');
      } else if (pageName === 'logisticsAsset') {
        setTemplate('template_vfs96xr');
      } else if (pageName === 'logisticsSafety') {
        setTemplate('template_ipnhreh');
      } else if (pageName === 'officeAsset') {
        setTemplate('template_v7u5yf9');
      } else if (pageName === 'manufacturingProcess') {
        setTemplate('template_51a2a6c');
      } else if (pageName === 'manufacturingAsset') {
        setTemplate('template_pufv8li');
      } else if (pageName === 'medicalPatient') {
        setTemplate('template_8i04xgh');
      } else if (pageName === 'medicalWaste') {
        setTemplate('template_2ohm2nm');
      } else if (pageName === 'retailExhibition') {
        setTemplate('template_o0a2d6a');
      }          
    }

    templateID(template);
  })

  useEffect(() => {

    if (pageName === 'constructionSafety') {
      setPage('건설 작업자 안전관리');
    } else if (pageName === 'constructionAsset') {
      setPage('건설자재 위치 추적');
    } else if (pageName === 'constructionEquipment') {
      setPage('건설 중장비 관리');
    } else if (pageName === 'logisticsAsset') {
      setPage('물류 재고 위치 추적');
    } else if (pageName === 'logisticsSafety') {
      setPage('물류 창고 작업자 안전관리');
    } else if (pageName === 'officeAsset') {
      setPage('오피스 자산관리');
    } else if (pageName === 'manufacturingProcess') {
      setPage('제조 실시간 공정 관리');
    } else if (pageName === 'manufacturingAsset') {
      setPage('제조 실시간 재고 관리');
    } else if (pageName === 'medicalPatient') {
      setPage('의료 환자 위치 추적');
    } else if (pageName === 'medicalWaste') {
      setPage('의료 폐기물 관리');
    } else if (pageName === 'retailExhibition') {
      setPage('리테일 전시관 방문객 분석');
    }   

    setPageName(page);

  })

  return (
      <>
      </>

  )


}




export default Template;
