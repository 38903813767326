export default {


    //////// 솔루션 컨텐츠 /////////

    constructionSafety : {
        tag : '솔루션',
        title : '작업자 안전 관리',
        desc : '작업자의 안전은 무엇보다도 중요합니다. 고위험 환경에서 작업자의 안전을 보장하지 않는 것은, 생산성에도 문제가 생길뿐더러 법적인 책임까지도 발생할 수 있습니다.',
        link : '/enterprise/construction/safety'
    },

    constructionAsset : {
        tag : '솔루션',
        title : '건설자재 위치 추적 및 관리',
        desc : '정확한 자재 관리는 결국 생산성을 향상시킵니다. 자원 낭비를 줄이고 품질을 유지하며 더 나아가 안전에 영향을 끼칠 정도로 자재 관리는 건설 현장에서 중요한 부분입니다.',
        link : '/enterprise/construction/asset'
    },

    constructionEquipment : {
        tag : '솔루션',
        title : '중장비 관리',
        desc : '건설 현장에서 중장비는 핵심 요소입니다. 따라서 적절한 유지 보수, 사고 예방은 필수입니다. 중장비를 제대로 관리 한다면, 안전을 유지하면서 비용은 절감될 것이고 현장의 생산성은 눈에 띄게 상승할 것입니다.',
        link : '/enterprise/construction/equipment'
    },

    
    logisticsAsset : {
        tag : '솔루션',
        title : '재고 위치 추적 및 관리',
        desc : '재고 관리는 공급망의 효율성과 원활한 운영을 지원하는 중요한 부분입니다. 실시간으로 재고의 위치를 파악하면 재고 수준을 효과적으로 모니터링하고 재고 이동을 최적화할 수 있습니다.',
        link : '/enterprise/logistics/asset'
    },

    logisticsSafety : {
        tag : '솔루션',
        title : '창고 작업자 안전 관리',
        desc : '작업자의 안전은 무엇보다도 중요합니다. 생산성에도 문제가 생길뿐더러 법적인 책임까지도 발생할 수 있습니다. 또한, 안전한 물류 현장은 기업의 이미지와 이해관계자들의 신뢰를 형성하는 데 중요한 역할을 합니다.',
        link : '/enterprise/logistics/safety'
    },



    officeAsset : {
        tag : '솔루션',
        title : '자산 관리',
        desc : '미숙한 자산 관리는 시간적, 금전적 손실을 발생시킵니다. 업무에 차질이 없도록 명료하고 빠르게 자산을 관리하여 원활한 비즈니스를 운영할 수 있습니다.',
        link : '/enterprise/office/asset'
    },


    manufacturingProcess : {
        tag : '솔루션',
        title : '실시간 공정 관리',
        desc : '제조 시설이 어떤 흐름으로 진행되고 있는지 한눈에 파악하여 관리할 수 있습니다. 정확하고 객관화된 데이터를 통해서 공정 라인을 개선하고, 효율성을 높일 수 있습니다. 이는 제조 산업의 핵심입니다.',
        link : '/enterprise/manufacturing/process'
    },

    manufacturingAsset : {
        tag : '솔루션',
        title : '실시간 재고 관리',
        desc : '제조 시설은 원자재, 반제품 등 수많은 재고를 보유하고 있습니다. 이들을 제대로 관리하는 것은, 시간과 경제적인 측면에서 매우 중요합니다. 즉, 제조 산업의 효율성에 영향을 끼치는 중요한 요소입니다.',
        link : '/enterprise/manufacturing/asset'
    },

    

    medicalPatient : {
        tag : '솔루션',
        title : '환자 위치 추적',
        desc : '의료 시설에는 각별히 관리해야만 하는 환자들이 존재합니다. 직접 24시간 감시하지 않아도 괜찮습니다. 더 정확하고 빠르기 환자의 위치를, 실시간으로 추적합니다.',
        link : '/enterprise/medical/patient'
    },

    medicalWaste : {
        tag : '솔루션',
        title : '의료 폐기물 관리',
        desc : '의료폐기물의 실시간 현황과 위치를 추적하여 의료사고 예방하고 안전한 의료 서비스를 제공할 수 있습니다. 법적 규제를 준수하여 인체 건강과 자원 보전에 기여하는 필수적인 작업입니다.',
        link : '/enterprise/medical/waste'
    },
    

    retailExhibition : {
        tag : '솔루션',
        title : '전시관 관람객 관리 및 분석',
        desc : '전시관 방문객들의 동선을 분석하고 자산을 쉽게 관리하여, 효율적인 시설 운영이 가능합니다. 정확하고 객관화된 데이터는 새롭게 비즈니스를 성장시킬 수 있습니다.',
        link : '/enterprise/retail/exhibition'
    },





    //////// 블로그 컨텐츠 /////////

    blogConstruction : {
        tag : '블로그',
        title : 'RTLS가 가져올 건설 업계의 미래',
        desc : '건설 분야도 다른 곳과 마찬가지로 항상 효율성과 생산량 그리고 안정성을 높이기 위한 방법을 강구하고 있습니다.특히나 선두를 앞서고 있는 기업들은 이러한 문제들을 해결하기 위해 여러 가지 최첨단 기술들을 도입하고자 합니다.',
        link : '/blog/rtls'
    },

    blogRTLS : {
        tag : '블로그',
        title : '건설 현장에서의 RTLS',
        desc : 'RTLS 시스템을 사용하여 건물 내부의 위치를 파악하고 탐색하는 방법에 대해 알아보고자 합니다.현재 많은 건축 회사들이 실시간으로 자산을 찾고 객체를 이동하기 위한 추적 시스템을 구현하기 시작하고 있습니다. 이는 건축 현장에서의 활동을 최적화하고 건설 노동자들의 효율성을 높일 수 있습니다.',
        link : '/blog/rtls'
    },

    blogActiveRfid : {
        tag : '블로그',
        title : 'Active RFID 기술 기반 RTLS',
        desc : 'Active RFID는 장치 사이의 정보를 송신하는 무선 통신 형태입니다. Active RFID 태그는 전원 소스를 가지고 있으며, 이는 긴 거리를 통해 신호를 전송할 수 있습니다. 특히 실시간 추적 자산을 추적하기 위해 이상적인 제품입니다.',
        link : '/blog/activerfid'
    },
/*
    blogAsset : {
        tag : '블로그',
        title : '실내/실외에서의 원활한 자산 및 프로세스 추적',
        desc : 'RTLS 시스템을 사용하여 건물 내부의 위치를 파악하고 탐색하는 방법에 대해 알아보고자 합니다.현재 많은 건축 회사들이 실시간으로 자산을 찾고 객체를 이동하기 위한 추적 시스템을 구현하기 시작하고 있습니다. 이는 건축 현장에서의 활동을 최적화하고 건설 노동자들의 효율성을 높일 수 있습니다.',
        link : '/blog/asset'
    },
*/

    blogForklift : {
        tag : '블로그',
        title : '지게차 운전자의 안전을 개선하는 RTLS 솔루션',
        desc : '지게차는 사용하기 매일 점검하여 작동 상태가 양호한지 확인해야 합니다. 점검 리스트에는 주차 브레이크, 조명, 유압 오일 레벨 등등이 포함됩니다. 만약 문제가 발견되었다면 모든 결함을 즉시 수리해야 합니다. 적어도 한 달에 한 번은 자격을 갖춘 기술자가 보다 철저한 검사를 진행하는 것이 좋습니다.',
        link : '/blog/forklift'
    },



    blogOffice : {
        tag : '블로그',
        title : '2023년, 스마트 오피스를 구축하는 방법',
        desc : '사무실 건물에서 실내 위치추적은 많은 어려움을 야기할 수 있습니다. 사무실, 홀, 층의 수가 많아 질수록 방문객들은 쉽게 길을 잃을 수 있고 건물을 찾는 데 많은 시간을 허비할 수 있습니다. 이러한 상황에서 기업은 좀 더 스마트한 지도를 사용해야 합니다. 최신 기술은 건물과 관련된 편리한 탐색을 보장하고 고객 및 방문자 의 만족도를 향상시킵니다.',
        link : '/blog/office'
    },



    blogFactory : {
        tag : '블로그',
        title : '미래의 공장에 오신 것을 환영합니다 : Industry 4.0에서 실시간 추적의 역할',
        desc : '앞으로 공장의 미래는 혁신적인 변화를 겪을 것입니다. 이는 머지 않아 다가올 현실입니다. 그 배경에는 IoT(사물 인터넷), 인공지능, 빅 데이터와 같은 첨단 기술들이 바탕이 됩니다. 그리고 여기에 RTLS 기술 역시 혁신을 이끄는 핵심 요소입니다. ',
        link : '/blog/factory'
    },




    blogMedical : {
        tag : '블로그',
        title : '의료 산업에서의 RTLS',
        desc : '코로나 펜데믹을 겪으면서 의료 산업에 있어서 발전된 IT기술은 더욱 중요시 되고 있습니다. 특히 환자를 추적하고 관리하는 것이 기술의 핵심이라고 할 수 있습니다. RTLS, 실시간 위치 시스템이라고 불리는 이 기술은 미래에 많은 의료 산업 전반에 걸쳐서 다양하게 활용될 것이며 지금보다 훨씬 나은 수준의 서비스를 제공할 수 있도록 도움을 줄 것입니다.',
        link : '/blog/medical'
    },







}