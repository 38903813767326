import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset, manufacturingProcess } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";


export default {
    helmet: '| 智能工厂如何改变制造业',
    desc: '它可以执行资产管理、生产线优化、自动化、数据分析、环境友好型生产、库存管理、订单处理等各种功能。这使得企业能够确保竞争力，并优化制造流程。',
    keywords: '智能工厂、RTLS技术、智能制造、制造创新、生产效率、资产管理、生产线优化、安全和保障、自动化、数据分析、资源分配、预防性维护、客户服务、质量改进、环保制造、库存管理、可视化、订单处理、质量检查、质量管理、生产透明化、orbro、오브로、콩테크',
    ogSite: '智能工厂如何改变制造业 :: Orbro博客',
    ogUrl: 'https://orbro.io/cn/blog/smartFactory',
    ogType: '网站',
    title: '智能工厂如何改变制造业',
    date: '2023-10-12',
    tag1: '#智能工厂',
    tag2: '#智能制造',
    tag3: '#生产线',
    tag4: '#库存管理',
    text: [
        {
            head: '',
            subHead: '',
            desc: '制造业受到技术创新的显著影响，近年来，智能工厂的概念在改变制造业、提高生产效率方面发挥着关键作用。这些智能工厂的关键要素之一就是实时定位系统（RTLS）技术。RTLS技术允许在制造设施内实时跟踪资产和工人的位置和移动，帮助制造公司优化生产流程并最大程度提高效率。在本文中，我们将讨论RTLS技术在智能工厂中的应用以及它如何重塑制造业的效率。',
            dot: '',
        },
        {
            head: '什么是RTLS？',
            subHead: '',
            desc: 'RTLS代表实时定位系统，这项技术可以精确跟踪和监控移动资产的位置，如工人和设备。RTLS系统使用GPS、射频识别（RFID）、超宽带（UWB）、蓝牙、超声波和其他传感器来跟踪工人的位置。',
            dot: '',
        },
        {
            head: '优化资源分配',
            subHead: '',
            desc: 'RTLS实现了原材料、零部件、工人和机器位置的实时跟踪，从而实现了资源的优化分配。这提高了生产线的效率，减少了资源浪费。',
            dot: '',
        },
        {
            head: '预防性维护和维护优化',
            subHead: '',
            desc: 'RTLS技术监控设备和机器的状况，允许进行预防性维护和优化的维护计划。早期检测设备状况的变化或故障可以防止生产停机并节省成本。',
            dot: '',
        },
        {
            head: '实时监测生产数据',
            subHead: '',
            desc: 'RTLS系统实时收集和监控制造设施内产生的数据。这实现了生产过程的实时监测，使在生产过程中可能出现的问题得以迅速响应。',
            dot: '',
        },
        {
            head: '改进客户服务和质量提升',
            subHead: '',
            desc: 'RTLS技术用于跟踪生产过程并记录产品的历史。这有助于改进产品质量管理并提高客户服务。',
            dot: '',
        },
        {
            head: '环保制造',
            subHead: '',
            desc: 'RTLS技术可以用于优化能源和资源使用，推动环保制造。支持遵守环保法规和注重环保制造实践。',
            dot: '',
        },
        {
            head: '自动化库存管理',
            subHead: '',
            desc: 'RTLS自动化库存管理并优化库存水平，降低库存管理成本并有效利用存储空间。',
            dot: '',
        },
        {
            head: '生产现场的实时可视化',
            subHead: '',
            desc: 'RTLS用于实时可视化制造过程，从而清晰了解生产现场的状态并实时监测生产过程。',
            dot: '',
        },
        {
            head: '缩短订单处理和交货时间',
            subHead: '',
            desc: 'RTLS技术可以缩短订单处理和生产周期时间，实现对客户需求的迅速响应。',
            dot: '',
        },
        {
            head: '质量检查和管理',
            subHead: '',
            desc: 'RTLS提高产品质量检查和管理。它可以识别次品产品并使质量问题得以迅速纠正。',
            dot: '',
        },
        {
            head: '提高生产透明度',
            subHead: '',
            desc: 'RTLS技术提高了生产过程的透明度，实现了错误和改进的实时跟踪。这有助于提高生产效率。',
            dot: '',
        },
        {
            head: '',
            subHead: '',
            desc: '在制造设施中成功实施RTLS技术需要正确的硬件和软件基础设施。数据安全和隐私也是重要考虑因素。尽管存在这些挑战，但RTLS技术的潜力为现代制造公司带来了重要优势。',
            dot: '',
        },
        {
            head: '',
            subHead: '',
            desc: '智能工厂和RTLS技术的结合是改变制造业的关键途径之一。这使制造公司能够提高效率、优化生产流程，维护和提高竞争力。因此，RTLS技术在塑造制造业未来方面发挥着关键作用。',
            dot: '',
        },
    ],
    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: '通过将UWB终端附加到工人或设备上支持实时位置跟踪的产品。',
                img: productImg.product.assetTracking.key1,
            },
            // 如有需要，可以添加更多产品详细信息。
        ],
        solution: [
            {
                img: manufacturingProcess.thumb,
                data: 'manufacturingProcess',
                link: '/cn/enterprise/manufacturing/process',
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/cn/enterprise/manufacturing/asset',
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/cn/enterprise/logistics/asset',
            },
        ],
    },
};
