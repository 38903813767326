import { carTracking1, peopleTracking1 } from "@assets/images/main/camera";
import { assetTracking1, workerTracking1 } from "@assets/images/main/rtls/product";

export default {

    assetPackage: {
        label: '資産位置測位パッケージ',
        title: '資産位置測位パッケージ',
        product: 'TwinTracker 10 + Server1',
        desc: 'BLEデバイスを作業者や機器に取り付けてリアルタイムで位置を測位する製品です。',
        img: assetTracking1,
        link: '/jp/rtls',
        index: '1'
    },
    
    workerPackage: {
        label: '作業者位置測位パッケージ',
        title: '作業者位置測位パッケージ',
        product: 'TwinTrackerPro 10 + Server1',
        desc: 'UWBデバイスを作業者や機器に取り付けてリアルタイムで位置を測位する製品です。',
        img: workerTracking1,
        link: '/jp/rtls',
        index: '2'
    },
    
    
    carPackage: {
        label: '車両位置測位パッケージ',
        title: '車両位置測位パッケージ',
        product: 'Server1 1 + NVR 1 + PTZ Camera2',
        desc: 'カメラを使用して人、車両などさまざまなオブジェクトの位置と異常な動きを検知し、管理することができます。',
        img: carTracking1,
        link: '/jp/camera',
        index: '1'
    },
    peoplePackage : {
        label : 'People Tracking and Analysis パッケージ',
        title: '流動人口分析パッケージ',
        product : 'Server1 + NVR1 + IP Camera4',
        desc: 'このカメラは、室内の人々の移動経路を分析し、予期せぬ状況を感知することができます。',
        img : peopleTracking1,
        link : '/camera',
        index : '2'
    },

}