import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconPenSquare, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/officeAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/officeAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/officeAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/officeAsset/video/sub/rtlsVideo3.mp4";

export default {
    
    banner : {
        title : '오피스 자산 관리',
        desc : '미숙한 자산 관리는 시간적, 금전적 손실을 발생시킵니다. 업무에 차질이 없도록 명료하고 빠르게 자산을 관리하여 원활한 비즈니스를 운영할 수 있습니다.',

    },

    video : {
        title : '자산 관리를 더 정확하게, 더 안전하게.',
        desc : '실시간 위치 추적(RTLS) 기술은 모든 것을 실시간으로 추적합니다.<br/>오피스 내의 모든 자산을 최적의 상태로 관리하며, <strong>가장 효율적인 운용</strong>이 가능해집니다.',
        play : video
    },

    func : [
        {
            title : '자산 위치 추적',
            desc : '현재 필요한 사무용품을 비롯한 자산의 위치를 실시간으로 파악합니다. 낭비되는 동선과 시간, 그리고 경비를 절감하여 오피스 운영에 효율을 높입니다.',
            sub1 : '실시간 위치 추적',
            icon1 : IconBinocularsGray,
            sub2 : '높은 정확도 제공',
            icon2 : IconRulerGray,
            video : subVideo1
            
        },
        {
            title : '재고 관리',
            desc : '정확한 재고 수량을 파악하는 것은 기본입니다. 또한, 위치를 실시간으로 파악하여 재고가 현재 어느 위치에 몇개가 있는지 파악합니다.',
            sub1 : '실시간 현황 파악',
            icon1 : IconClockGray,
            sub2 : '빠른 데이터 연동',
            icon2 : IconShareGray,
            video : subVideo2
        },
        {
            title : '자산 반출 알림',
            desc : '오피스 내의 용품들이 어떻게 반출되고 있는지 정확히 파악하는 것은 필수적입니다. 다만, 여기에 너무 많은 인력과 경비를 들이는 것은 비효율적인 일입니다. 이를 자동화하여 효율적인 오피스 환경을 제공합니다.',
            sub1 : '통계 데이터 제공',
            icon1 : IconChartBarGray,
            sub2 : '데이터 클라우드 저장',
            icon2 : IconCloudCheckGray,
            video : subVideo3
        },


    ],



    subfunc : {
        
        title : '다양한 기능',
        desc : '효율적인 재고 관리, 시간 및 비용 절감, 투명성 및 추적성 등 다양한 장점이 있습니다.<br/>최적의 근무 환경을 조성하고 최고의 효율을 만들어 낼 수 있도록, 오브로는 다음과 같은 기능들을 추가로 제공합니다.',
        
        data : [
            {
                icon : IconChartRadius,
                title : '리포트 및 분석',
                desc : '자산 사용 기록을 기반으로 보고서 및 분석 자료를 생성할 수 있습니다.',
            },
            {
                icon : IconBell,
                title : '실시간 알람 기능',
                desc : '특정 자산에 이상 상황이 발생하는 경우 알람을 보내 즉각적으로 대처할 수 있습니다.',
            },
            {
                icon : IconRoute    ,
                title : '자산 동선 조회',
                desc : '특정 자산이 오피스 내에서 이동한 동선을 조회하여 데이터화 할 수 있습니다.',
            },
            {
                icon : IconDashboard,
                title : '현황 파악',
                desc : '현재 특정 자산이 어디로 이동하고 있는지 파악합니다.',
            },
            {
                icon : IconWarning,
                title : '이탈 감지',
                desc : '미리 설정된 구역에서 벗어나는 자산이 있는 경우, 실시간으로 알림을 보냅니다.',
            },
            {
                icon : IconPenSquare,
                title : '수량 관리',
                desc : '자산의 남은 수량을 파악하여, 일정 수량을 넘어가거나 부족해지면 알려줍니다.',
            },
            {
                icon : IconChainLink,
                title : '데이터 연동',
                desc : 'RFID, IOT, 보안, 영상 등의 시스템과 연결하여 효율적으로 활용할 수 있습니다.',
            },
            {
                icon : IconSecurity,
                title : '데이터 보안',
                desc : '높은 데이터 보안 수준을 유지하여 신뢰성이 높은 솔루션을 제공합니다.',
            },


        ],
    },

    system : {
        title : '시스템 구성',
        img : rtlsSystem
    },

    productTitle : '제품 구성',
    product : [

        {
            tag : 'assetPackage'
        },
        {
            tag : 'workerPackage'
        },
    ],


    contents : [
        {
        data : 'logisticsAsset'
        },
        {
        data : 'manufacturingAsset'
        },
        {
        data : 'blogOffice'
        },
        {
        data : 'blogRTLS'
        },

    ]



}