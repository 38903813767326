import styled from "@emotion/styled";

export const BtnTransparent = styled.button<{border : string}>`

  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;

  border-radius: 12px;

  ${({border})=> border&& `border : ${border};`}

  background: transparent;
  cursor : pointer;
`;


export const ShowMoreButton = styled.a`
    cursor : pointer;
    width : 98px;
    height : 20px;
    display : flex;
    object-fit : contain;

    & img {
        width : 100%;
        height : 100%;
        
    }
`;


export const CloseBtn = styled.img<{size : string}>`
    cursor : pointer;
    margin-left : auto;
    ${({size})=> size && `width : ${size}px; height : ${size}px;`}
`;
