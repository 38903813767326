import { OurPartnersLogo1, OurPartnersLogo10, OurPartnersLogo10Mobile, OurPartnersLogo10Tablet, OurPartnersLogo11, OurPartnersLogo11Mobile, OurPartnersLogo11Tablet, OurPartnersLogo12, OurPartnersLogo12Mobile, OurPartnersLogo12Tablet, OurPartnersLogo13, OurPartnersLogo13Mobile, OurPartnersLogo13Tablet, OurPartnersLogo14, OurPartnersLogo14Mobile, OurPartnersLogo14Tablet, OurPartnersLogo15, OurPartnersLogo15Mobile, OurPartnersLogo15Tablet, OurPartnersLogo16, OurPartnersLogo16Mobile, OurPartnersLogo16Tablet, OurPartnersLogo1Mobile, OurPartnersLogo1Tablet, OurPartnersLogo2, OurPartnersLogo2Mobile, OurPartnersLogo2Tablet, OurPartnersLogo3, OurPartnersLogo3Mobile, OurPartnersLogo3Tablet, OurPartnersLogo4, OurPartnersLogo4Mobile, OurPartnersLogo4Tablet, OurPartnersLogo5, OurPartnersLogo5Mobile, OurPartnersLogo5Tablet, OurPartnersLogo6, OurPartnersLogo6Mobile, OurPartnersLogo6Tablet, OurPartnersLogo7, OurPartnersLogo7Mobile, OurPartnersLogo7Tablet, OurPartnersLogo8, OurPartnersLogo8Mobile, OurPartnersLogo8Tablet, OurPartnersLogo9, OurPartnersLogo9Mobile, OurPartnersLogo9Tablet } from "@assets/images/company";
import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledHeading } from "@components/Text";
import { CommonState } from "@redux/reducers/commonReducer";
import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { StyledOurPartners, StyledOurPartnersDisplay, StyledOurPartnersHeading, StyledOurPartnersLogo, StyledOurPartnersLogos } from "./OurPartners.style";
import company from "@src/lang/ko/company";
const OurPartners = ():React.ReactElement => {
  // Redux
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  // Memo
  const logos = useMemo(() => [
    { desktop: OurPartnersLogo1, tablet: OurPartnersLogo1Tablet, mobile: OurPartnersLogo1Mobile },
    { desktop: OurPartnersLogo2, tablet: OurPartnersLogo2Tablet, mobile: OurPartnersLogo2Mobile },
    { desktop: OurPartnersLogo3, tablet: OurPartnersLogo3Tablet, mobile: OurPartnersLogo3Mobile },
    { desktop: OurPartnersLogo4, tablet: OurPartnersLogo4Tablet, mobile: OurPartnersLogo4Mobile },
    { desktop: OurPartnersLogo5, tablet: OurPartnersLogo5Tablet, mobile: OurPartnersLogo5Mobile },
    { desktop: OurPartnersLogo6, tablet: OurPartnersLogo6Tablet, mobile: OurPartnersLogo6Mobile },
    { desktop: OurPartnersLogo7, tablet: OurPartnersLogo7Tablet, mobile: OurPartnersLogo7Mobile },
    { desktop: OurPartnersLogo8, tablet: OurPartnersLogo8Tablet, mobile: OurPartnersLogo8Mobile },
    { desktop: OurPartnersLogo9, tablet: OurPartnersLogo9Tablet, mobile: OurPartnersLogo9Mobile },
    { desktop: OurPartnersLogo10, tablet: OurPartnersLogo10Tablet, mobile: OurPartnersLogo10Mobile },
    { desktop: OurPartnersLogo11, tablet: OurPartnersLogo11Tablet, mobile: OurPartnersLogo11Mobile },
    { desktop: OurPartnersLogo12, tablet: OurPartnersLogo12Tablet, mobile: OurPartnersLogo12Mobile },
    { desktop: OurPartnersLogo13, tablet: OurPartnersLogo13Tablet, mobile: OurPartnersLogo13Mobile },
    { desktop: OurPartnersLogo14, tablet: OurPartnersLogo14Tablet, mobile: OurPartnersLogo14Mobile },
    { desktop: OurPartnersLogo15, tablet: OurPartnersLogo15Tablet, mobile: OurPartnersLogo15Mobile },
    { desktop: OurPartnersLogo16, tablet: OurPartnersLogo16Tablet, mobile: OurPartnersLogo16Mobile },
  ], []);
  
  return (
    <StyledOurPartners>
      <StyledGridContainer>
        <StyledOurPartnersHeading size="xxsmall">
          <Trans i18nKey={"company.ourPartners.subtitle"} />
        </StyledOurPartnersHeading>

        <StyledOurPartnersDisplay size="small">
          <Trans i18nKey={"company.ourPartners.title"} />
        </StyledOurPartnersDisplay>


        <StyledOurPartnersLogos>
          { logos.map((logo, index) => (
            <StyledOurPartnersLogo desktop={3} tablet={2} mobile={2} key={`our-partners-${index}`}> 
              <img src={logo[layoutType]} alt="ORBRO's Partners logos"/>
            </StyledOurPartnersLogo>
          ))}
        </StyledOurPartnersLogos>
      </StyledGridContainer>
    </StyledOurPartners>
  );
};

export default OurPartners;