import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

  helmet: '| RFID とは？',
  desc: 'RFID は "Radio-Frequency Identification" の略で、ワイヤレス射频識別技術を指し、データをワイヤレス通信を介して送受信してオブジェクトを一意に識別および管理するために使用されます。',
  keywords: 'rfid、nfc、uwb、ワイヤレス通信、位置追跡、オブジェクト位置追跡、人物位置追跡、タグ、ビーコン、rtls、orbro、コンテック',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RFID とは？ | 位置追跡とデジタルツインの専門プラットフォーム、Orbro',
  ogUrl: 'https://orbro.io/jp/blog/rfid',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDnHsX6sdpDkHidg6Qw1BNPI-8KHNunrbQX70t-RRiZFc_m0TXoYRKa1E9Qo01-Au848tK9S5Jzx1vOsreK1FoM7_WUn-aUPsNq2SoW1p0bgotbqEic-B-shh4JqyX1vRGz1BKJuuNybUGEV0Sntx7H_5LpXctuKvQDlllya8Qrt86WPh2Z8-5f8fKO1zJH2O8i96n9h1SvE8MHibKQqZzHRsPx4JboT3-j7Dq6nMb3Gsk-bMZkqaQVCKMuKmSG9s28DFXjSr2dDlwKXTgw6HPGwcGijF3sdo52c8MtyGSFhBtgtmGbjidDLlTEoYy5sTjCUl9KoRk5SbaOqCb-WHfrMzvlUE-KzSkFspk4Qe_oDLl4U2mcOBOC6tpykKzDDNY3P-si0yNBW_3kHTZd8ITgkwKE1AwK0hHOdS0FB7_AvrfgQW1VAIpkjYlJFHXsbP3Nac0v-XjWu9W-fozT8vIJC3P5c5imd_plMzSJorI4po9xbiqNGReeiJpbzilK2ym2B2i8MZT6w2SRjdf2nm2SU4Ru4aZR60ahb8bagWe_xc_ZzKNmdlZu5Avvkyc3_fUGnnqw3d3xcwaPdtRhYQul_0LDKxsgKrBjv2Co6CztB1mX2Et8-F5j68r2F9qy1yj6abH08yQokpafvTmxUsBYZ_i4OAw30nPlQkXflKfWFTob2y_o-X_FvVTGl4qerYTVBQLafVq7aSdlwkNpTvzZbXYkJMJasDN8JRO6mBJ8WhoJm9GOF5z75doXv-ds7jiNur5KLdA_UC2SR6-GpDrQPew6gUqn_1NvNGCXxkH9skkPX6gIuzc-9EAaXkSNajXWEc9_cRppxNODr4m1x_rl00fnHmVra7BhMXTjEgsB7rUJJw9OdKbTvB565TUXJXGMIfiFUAJfYBgm3FRZfJobff73T4LEa7d6e-znqcf64EINs_qnz2n-6yqf6_8Ww7A3P2K_iKCJymzKYy-dmjnxIW7c_msNP3sQZ0Ev0CpMW2neef16CpS2qPJjSc1Hv977oz4JSkrCL2GJ3RIUILnczDL8Ww96raGuIs058uNZu-pJwbIEbr9995dIPiHY-a6wstUW8xKthzZmdCFJ2JInX_Mos6jxXNorixlMOiseVcCXzuEcH_QEXGn9Mj7GzKkhAwgYeEq3D7EMlwjQ56-8mACmRW1ein59VVTW8JoXlBZ0enRBVmONwC4Y0aUUc_Hn70Zbktx4y1pKsehMCWy3GnGikZej6coDNKuKPnv8VE2ZBfCy8QdoI0i4P9rH2zFPqJWJEEgYmy3MpmnS5Asf8f1aScgjVS1S06wmBN_9Lpa-3cUJ-epljLU28BaHQWk9f2z4XojkWBWXVnGCidPhbxH-Cx4l-mNjXL8bh9PixgnFknFkWbwaqqGfjgS-GvOPqF0uGlIlEavfeovi1d_1opq7fxuM6wc-y17eImZEycGDKQuMRds6xvylNau1IgJTWCxWfnHArzBoJMxU91IlULuYkMFPAmoA-U2JjLAeiz35GX1eHmMQ3k_2JZmlfIvGQ73K2zf9ErNr-QzQPqixYph1iRaJi=w1984-h1214',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RFID とは？',
  date: '2024-01-11',
  tag1: '#rfid',
  tag2: '#rtls',
  tag3: '#ワイヤレス通信',
  tag4: '#位置追跡',

  // <br/>
  text: [
    {
      head: 'RFID とは？',
      subHead: '',
      desc: 'RFID は "Radio-Frequency Identification" の略で、ワイヤレス射频識別技術を指し、データをワイヤレス通信を介して送受信してオブジェクトを一意に識別および管理するために使用されます。<br/>RFID システムは通常、タグ、リーダー（またはインタロゲーター）、およびデータベースから構成されています。RFID システムの主要な構成要素であるタグは、小さなチップとアンテナで構成されています。このチップはオブジェクトに取り付けられたり組み込まれたりし、アンテナはワイヤレス信号の送受信を担当します。リーダーはアンテナを介してタグと通信し、データを読み取ったり書き込んだりし、この情報をデータベースに接続してリアルタイムで情報を追跡または管理することができます。',
      dot: ''
    },
    {
      head: 'RFID の活用事例',
      subHead: '',
      desc: 'RFID 技術はさまざまな産業分野で幅広く活用されています。以下は RFID の主要な活用事例の一部です。',
      dot: ''
    },
    {
      head: '',
      subHead: '流通と物流管理',
      desc: '',
      dot: {
        a : '<strong> • 在庫管理： </strong>RFID を使用して製品にタグを取り付け、リーダーを介してリアルタイムで在庫を追跡することで正確な在庫情報を提供します。',
        b : '<strong> • 自動物流処理： </strong>製品の運搬と分類のプロセスを自動化して物流作業の効率を向上させます。',
      }
    },
    {
      head: '',
      subHead: '製造業',
      desc: '',
      dot: {
        a : '<strong> • 製品追跡と生産管理： </strong>製造業者は製品に RFID タグを取り付け、生産ラインを追跡し、製品の製造および出荷段階でのリアルタイムモニタリングを実施します。',
      }
    },
    {
      head: '',
      subHead: '小売業',
      desc: '',
      dot: {
        a : '<strong> • スマートストア： </strong>顧客が製品を直接持っていくと自動的に請求書を作成し、支払いを行うスマートストアを実現するために RFID 技術を使用します。',
        b : '<strong> • 顧客エクスペリエンスの向上： </strong>RFID を利用して製品を簡単に見つけることができるようにしたり、割引やプロモーションを自動的に適用して顧客エクスペリエンスを向上させます。',
      }
    },
    {
      head: '',
      subHead: '医療および病院管理',
      desc: '',
      dot: {
        a : '<strong> • 患者識別と医療機器管理： </strong>RFID を使用して患者を正確に識別し、医療機器の位置と状態をモニタリングして医療機関の効率を向上させます。',
        b : '<strong> • 医薬品追跡： </strong>製造から患者への過程で医薬品の追跡性を高めて安全性を確保します。',
      }
    },
    {
      head: '',
      subHead: '車両および交通システム',
      desc: '',
      dot: {
        a : '<strong> • 自動通行料金： </strong>RFID を使用して自動的に通行料金を課し、車両を追跡して交通渋滞を減少させます。',
        b : '<strong> • 車両管理と保守： </strong>車両に RFID タグを取り付け、保守履歴を追跡し、車両の位置をモニタリングして効率的な運用を支援します。',
      }
    },
    {
      head: 'RFID の利点',
      subHead: '自動化およびリアルタイム追跡',
      desc: 'RFID はオブジェクトをワイヤレスで素早く識別し、データを収集できるため、手動プロセスに比べて高速に動作します。また、オブジェクトの位置と状態をリアルタイムで追跡できるため、物流、生産、および在庫管理を効率的に実行できます。',
      dot: ''
    },
    {
      head: '',
      subHead: '正確なデータ収集',
      desc: 'バーコードスキャンと比較してより迅速で正確なデータ収集が可能であり、これによりデータ入力のエラーが減少します。RFID は視覚的な可視性がない状況でも自動的にオブジェクトを識別できます。',
      dot: ''
    },
    {
      head: '',
      subHead: 'ビジョンおよび可視性の向上',
      desc: 'RFID を活用すると、供給チェーン全体でオブジェクトの移動を透明に追跡でき、可視性が向上します。生産ラインや倉庫で RFID を使用して作業状態をリアルタイムでモニタリングできます。',
      dot: ''
    },
    {
      head: '',
      subHead: 'セキュリティの強化',
      desc: 'RFID はオブジェクトの不正移動を検知し、セキュリティが必要なエリアに適用して不正アクセスを防ぐことができます。RFID はタグに個別の権限を付与し、特定のユーザーやグループだけがアクセスできるように設定できます。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB 端末を作業者または設備に取り付け、リアルタイムで位置追跡をサポートする製品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/jp/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/jp/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/jp/enterprise/office/asset'
      }
    ]

  }

}
