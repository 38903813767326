import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4";

export default {

  helmet: 'ジオペンスの機能',
  desc: 'ジオフェンス（Geofence）は、地理（Geography）とフェンス（Fence）を組み合わせた言葉で、現実の場所に仮想ゾーンや仮想のフェンス境界を作成する技術です。',
  keywords: 'ジオペンス、ジオペンシン、geofence、geofencing、RTLS、室内の位置測位、室内のナビゲーション、位置測位ソリューション、リアルタイム位置測位、資産の測位、スマートオフィス、GPS、室内の位置測位ソリューション',

  ogTitle: 'ジオペンスとは何ですか? :: ・オブ・でブログ',
  ogDesc: 'ジオペンスとは何か、どのように実生活で作動するかどうかを見てみます。',
  ogSite: 'ジオペンスの機能 | ORBRO, Digital Twins Platform',
  ogUrl: 'https://orbro.io/jp/blog/geofence',
  ogKey: '',
  ogImg: "https://lh3.googleusercontent.com/fife/APg5EOZ2VWDCFEvSR2JhseSzS-2cQQnuMSFaM4obXlnNyqODBBoOLK1WTlmDiYajp8HYXztTLEp85J505ZV5DYOw4XK3f1-uBLTCKdymXB4nkl-0IPByDA2WVhTi9FpkH6to_X-pf_w4WnDhm_8vFh7GpJQoCH2jVpjtqLF8i-1MvlxeG-_wdQc3GSE5gyxieKJPBXZ8-h0_v0W275RZ1buY2zhtDYW-Je3wEqRDi5ZAsE_4zx3kHHfBjUgPf7tXIf74EEuIJb11hTQvdt9_n0xPGdgUFS1jN_qXOt0I693By1-jF0KtPqBaUdKQLY0gRf72BYwMTcCZRXQVo33POJ_kdczRYP9s1aOYSsbil2Xm21xAepKz9UeeByVEcX4Xj-yHE0JQh7B3-djLNtAGljujc4PvseRrPymDygPFx9o46B9PNRNGuFrORa_oJ1MLhFeqyW9xNQQu2a84bqh7v9ZOh714RBLDjAqUSDa0ZRXXWhKJqcMGjSWnjPyfFw7ILKzwJGKoiFmCCwFqlovHO3zTKM3Zem4TEk4g2msD6wwMQ3Ptsft6tcszn1HdpRRZ2l6Qq3ha5k8ewubMa06Q-aFkk-4apY3DsX-8Ey4b9kTIQqFNLLKHFNpFZdC5iPT-LYi56yuQUJPpJ7KF6RRexa3HfDTWeT8FNwnqNuDyCrbQ06Ix4Es5S1jXOW8ofiK2NplaeNg_1tca9QbIe-FDTOPqTeEHqMwCfDVncRYh0Z3e0x4Ywk6ebn2F0kccqqb6F7cVLGaVLOTsRs4rQ1r43TmZoG2elSGIsBntEp49Zn521JghIhCHcDIFjaYb9-RcgDF5oO7GIJT_YhTgPnWvKmGoBuesfZQaVSWgY5lhRHjULVAv-sg9X_EMWY3VhMkHAeGJlePyaD1531N_6-6UAs-QY7Ny66DVNen_QukuG1oAfQ29qDuvq9zJz2RrbafnZ2rantG3F_r2W0jvZiWuI-V_qiu4xBaQAzNklEly2t1aC7QDEW5qgzvbiS3hjC7U9KaRgsKFl_IhwaEjJMa_jv8JyNItI4P0XMjpADTrBPEgLFIbKGPiwtK2Ijjj91Cx-y2ElqS_-fmTQd63XX7c3Mhy4gK6SISrs24Zx9VdSKueoy7cBXubjcSj9QghIZsDvu3UpcZBq9EF8zYSf9-WGJIPj9qFf5_x4S8Ty_cD_XKF_Ic6bRMa_jZToGam_G5X94ag30kD3Wnrlw4AI3YFIHn_AYPVRXyz9aWY4QL1hiMrAHHLhGNd0ozBYqtmxSxga0ZI9UcIhlolXBSRe4D2JksdHn1ixx_WgBPto76Lt99eGq30wecQJbnSQlylNuqtkAvO1XBneOkYEEI0BT8eNwwz1R910N416ZXKPkgxujAs-FZHJoZxlp90d9Kv1UzKcKNKCbZgO6x3No9f-nkHzKtMmz5pVltLwkh5Hb7R-hcshSq2N4Vr1fpbMOpaJumw-fa-XiCszh7LwMXHgUO_8_071RRZfW0tBNV2OSKvdLNUv7yM_MrJ71sMx88Dbz0L1g=w1680-h1184",
  ogType: 'website',


  twitterCard: 'summary',
  twitterTitle: 'ジオペンスの機能',
  twitterDesc: 'ジオペンスとは何か、どのように実生活で作動するかどうかを見てみます。',


  title: 'ジオフェンス（Geofence）とは何ですか？<br/>どのような役割を果たしますか？',
  date: '2023-05-01',
  tag1: '#ジオフェンス',
  tag2: '#資産測位',
  tag3: '#RTLS',
  tag4: '#安全',

  // <br/>
  text: [
    {
      head: 'ジオフェンス（Geofence）とは何ですか？どのような役割を果たしますか？',
      subHead: '',
      desc: 'ジオフェンス（Geofence）は、地理（Geography）とフェンス（Fence）を組み合わせた言葉で、現実の地域に仮想の領域や仮想のフェンスの境界を作る技術です。<br/>リアルタイムの位置測位サービス（RTLS）を使用して、人々が指定された領域を通過するかどうかをモニタリングすることができます。これは民間企業、医療、軍事、航空など、さまざまな分野で活用されます。<br/>また、ジオフェンシング市場は、2022年の19億ドルから2032年に95億ドルを超えると予測されるなど、将来の産業において大きな価値を生み出すものと考えられています。',
      dot: ''
    },
    {
      videoDesc: '(ジオフェンス技術を活用した建設現場の安全管理ソリューション)',
      video: video,
      link : '/jp/enterprise/construction/equipment',
      head: '',
      subHead: 'ジオフェンシングは実際にどのように機能しますか？',
      desc: '位置情報サービスデバイスはまず特定の境界やエリアを設定し、開発者がその周囲に仮想的な境界線を設定します。その後、ジオフェンスはWi-Fi、セルラーデータなどのネットワークやビーコンなどの他の補助技術と組み合わせて、ユーザーのリアルタイムの位置と出入り情報を把握します。モバイルデバイスが指定された領域に入ると、テキストメッセージ、メール、アプリの通知などを条件に応じて実行するように事前に定義されたアクションを実行します。<br/>一般的に、このプロセスはモバイルを介して行われ、ユーザーはアプリの位置アクセスを拒否したり、個別に設定したりすることもできます。<br/><br/>位置情報サービスの使用には、基本的にはユーザーの同意が優先されます。',
      dot: ''
    },
    {
      head: '',
      subHead: 'ジオフェンシングはどこで使用されるのでしょうか？',
      desc: '',
      dot: {
        a: ' • <strong>資産</strong>：車両、トラクター、コンピュータなどの資産にジオフェンス技術を導入し、それらの資産がジオフェンスの範囲外に移動するタイミングを測位できます。',
        b: ' • <strong>人的資源</strong>：従業員が会社にどれくらいの時間滞在しているか、通勤の有無を正確に把握することができます。現場労働者の場合、出入り制御エリアをより効果的に管理できます。',
        c: ' • <strong>店舗</strong>：観光客が店舗を通過する際にプロモーションメッセージを送ることができます。',
        d: ' • <strong>ホームIoT</strong>：スマートフォンユーザーがジオフェンスされた家を出ると、温度調節装置が下がります。',
        e: ' • <strong>軍事</strong>：GPSなどの位置情報技術に基づくジオフェンシング技術は、空港や軍事施設の近くに危険な物体や飛行が接近できないよう制限するサービスを提供します。',
        f: ' • <strong>空港</strong>：周辺施設に関与するメッセージを受け取ることができます。無料Wi-Fiサービスや空港周辺のガイドサービスも利用できます。',
      }
    },
    {
      head: 'ジオフェンシングはどれくらい役立つのでしょうか？',
      subHead: '1. 安全性とセキュリティ',
      desc: '建設現場、病院、軍事施設などの安全性やセキュリティに敏感なエリアを指定し、そこにアクセスする人々をモニタリングすることができます。<br/>これにより、安全事故や事件からの予防と迅速な対応が可能です。',
      dot: ''
    },
    {
      head: '',
      subHead: '2. 効率的な作業',
      desc: 'ジオフェンシング技術は、労働者と労働場所の安全性と効率性と直接的な関連があります。<br/>例えば、会社で働く従業員や現場で働く作業者の作業状況をモニタリングします。<br/>一定の時間内に作業場所から離れたり、作業中に行ってはいけないエリアにアクセスする場合、<br/>管理者は適切な対策を取ることで将来的に発生する可能性のある問題を予防することができます。',
    },
    {
      head: '',
      subHead: '3. パーソナライズされた顧客体験',
      desc: '特定のエリアを通過する人々を対象にクーポンや特典を提供することにより、顧客体験を個別化します。<br/>これにより、以前は無駄になっていたマーケティング費用を削減できます。<br/>最近、国内の多くのカード会社では、ユーザーが百貨店にアクセスした場合に、そのエリアの人々に通知を送ることも、ジオフェンシング技術を活用したものです。',
    },

  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/jp/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/jp/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      }
    ]

  }


}