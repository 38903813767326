export default {


    support : [

        {
            name : 'Orbro home',
            path : '/en/',
            start : 'start'

        },
        {
            name : 'Support',
            submenus : [
                {text:'Technology', path:'/en/enterprise/technology/uwb'},
                {text:'Blog', path:'/en/blog'},
                {text:'Contact', path:'/en/request'},
                {text:'Company', path:'/en/company'},
            ]
        }

    ],


    lang : [
        {
            name : 'Language',
            submenus :[
                {text:'한국어'},
                {text:'English'},
                {text:'日本語'},
                {text:"中文"}
            ]
        }


    ]


}