



import { StyledGridContainer } from '@components/Grid/Grid.style';
import React, { PropsWithChildren, useState } from 'react';
import { Trans } from 'react-i18next';
import { IndexBox, IndexBtn, IndexBtnBox, IndexImg, IndexMark, ProductBox, ProductImgBox, ProductPage, ProductTextBox } from './Product.style';
import product from '@src/lang/ko/enterprise/solutionProduct';
import { IconChevronRight, IconChevronRightBlack } from '@assets/images/icon';
import solutionProduct from '@src/lang/ko/enterprise/solutionProduct';
import { StyledProductImg, StyledShowMore } from '@pages/Enterprise/Blog/Main/BlogModule.style';
import { Link } from 'react-router-dom';
import { setSelectedProduct, setSelectedValue } from '@redux/action-creators/common';
import { useDispatch } from 'react-redux';
import i18n from '@pages/i18n';
import { HeadingColor, ImgWH, LabelColor, ParagraphColor, Spacing } from '@pages/CommonStyle/common.style';
import { HeadingH2 } from '@components/Text/Heading/Heading.style';
import { DisplayH3 } from '@components/Text/HeadingTagDisplay.style';
import { DivPadding } from '@pages/CommonStyle/commonDiv.style';

interface ModalDefaultType {
  pageName: string
  path: any
}

const Product: React.FC<PropsWithChildren<ModalDefaultType>> = ({ pageName, path }) => {

  const dispatch = useDispatch();
  const handleIconClick = (value, productIndex) => {
    dispatch(setSelectedValue(value));
    dispatch(setSelectedProduct(productIndex)); // 추가: Redux 액션 디스패치
  };

  const pageData = path;
  const productCount = pageData.product.length;
  const [currentProductIndex, setCurrentProductIndex] = useState(0);

  const [showProduct, setShowProduct] = useState(true);

  const handlePrevProduct = () => {
    setShowProduct(false);
    setTimeout(() => {
      setCurrentProductIndex((prevIndex) => prevIndex === 0 ? productCount - 1 : prevIndex - 1);
      setShowProduct(true);
    }, 260); // 500ms 이후에 제품 이미지와 텍스트 변경 및 애니메이션 효과 적용
  };

  const handleNextProduct = () => {
    setShowProduct(false);
    setTimeout(() => {
      setCurrentProductIndex((prevIndex) => prevIndex === productCount - 1 ? 0 : prevIndex + 1);
      setShowProduct(true);
    }, 260); // 500ms 이후에 제품 이미지와 텍스트 변경 및 애니메이션 효과 적용
  };


  const currentProduct = pageData.product[currentProductIndex].tag;
  const productData = product[currentProduct];
  const currentLang = i18n.language;


  return (
    <>
      <StyledGridContainer>
        <ProductPage>

          <HeadingH2 size={'small'}>
            <Trans i18nKey={`${pageName}.productTitle`} />
          </HeadingH2>


          <ProductBox show={showProduct}>

            <ProductTextBox>
              <HeadingColor size="xxxxsmall" color='#818181'><Trans i18nKey={`solutionProduct.${currentProduct}.label`} /></HeadingColor>
              <Spacing pixel='4' />
              <DisplayH3 size='small' > <Trans i18nKey={`solutionProduct.${currentProduct}.title`} />  </DisplayH3>
              <Spacing pixel='4' />
              <LabelColor size='small' color='#646464'> <Trans i18nKey={`solutionProduct.${currentProduct}.product`} />  </LabelColor>
              <Spacing pixel='16' />
              <ParagraphColor size="medium" color='#646464'>
                <p>
                  <Trans i18nKey={`solutionProduct.${currentProduct}.desc`} />
                </p>
              </ParagraphColor>
              <Link to={currentLang !== 'ko' ? `/${currentLang}${productData.link}` : `${productData.link}`} >
                <StyledShowMore onClick={() => handleIconClick(productData.index, productData.productIndex)}>
                  <LabelColor size="medium" color='#2A70F0'><Trans i18nKey={`home.showMore`} /> </LabelColor>
                  <DivPadding a={"0"} b={"0"} c={"0"} d={"4"} />
                  <ImgWH src={IconChevronRight} alt="Right Arrow Icon" w={"20"} h={"20"} />
                </StyledShowMore>
              </Link>
            </ProductTextBox>

            <ProductImgBox>
              <StyledProductImg src={solutionProduct[currentProduct].img} alt='orbroRtlsProduct' />
            </ProductImgBox>
          </ProductBox>


          <IndexBox>
            <IndexImg>

              {Array(productCount).fill(null).map((_, index) => (
                <IndexMark i={currentProductIndex} j={index} key={index} />
              ))}

            </IndexImg>

            <IndexBtnBox>
              <IndexBtn onClick={handlePrevProduct} src={IconChevronRightBlack} alt='arrowButton' direction={true} />
              <IndexBtn onClick={handleNextProduct} src={IconChevronRightBlack} alt='arrowButton' direction={false} />
            </IndexBtnBox>

          </IndexBox>

        </ProductPage>

      </StyledGridContainer>
    </>
  )
};

export default Product;
