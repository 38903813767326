import {  medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4";

export default {
  helmet: '| RTLS医疗保健，即时可用',
  desc: '通过实时跟踪患者的位置，并有效管理医疗设备和人员，它在提高医疗现场的工作效率和提高患者安全性方面发挥着重要作用。',
  keywords: '患者定位追踪，患者管理，医院定位追踪，医院管理，智能医院，患者追踪，医疗设施管理，痴呆患者管理，阿尔茨海默病患者管理，老年患者管理，医院运营，医疗中心，养老院管理，Orbro，Kongtech，RTLS，orbro',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RTLS医疗保健，即时可用 :: Orbro博客',
  ogUrl: 'https://orbro.io/cn/blog/rtlsHealthcare',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxCulBiV7gg1-xmVx62eta4ZCVo7bPai3vj9SN8ajL7XdyxO5_DwTc9AopMRDeHnIP1JRQ2aZs8YXRVegMIQ3XJAzQpGZPe7LsKZRYfG2TTKDwuITV4QU1esZgJZjvVrWFNk7tdYgfPUmag4WVUhkZm9kN2q9I3i-i7g39hykefddJoB-GGyLstWZXHZPGouEpHRHO-QSDUlk-5bfgHEXekgkw8khERLg03ek2jr9aZVu73vARqYrCfRjmXxpjdzbezfvYPPNqyHHEqz7ryYxrceLzeUag7SeXqCikIehVE0j-teEEB0twcIKzxDhQ926mrdivrYUsLL7Y73cfXmCIoQrwGuj_PWfjkx8---GZuGMDu2T0xCegVf_ixBlv-_DP0s4PYYug15P9K4RRQcy8wzkEIe8DXRrNFJxK06VwMVNt7IvsyGuGpztnYoPe9DTJVmh6DgvpkLVfrWOQROJ1mY3wPnWoRGDONddVgsZOg9IFlZ8XDLxhGIwf9_NUadZ72Sx1uku7HzFPuRiPUi_jEHwpZssTqer0dHqRrmEiUm7WIfEvksaY5tlVEdKCkt6rieJQk6XkQ7HLkH9Sft-gtWyMIbXXc-ezr8CI1QW-BSqTqW1u1RHOH_U8qsyeAMRsLziuReFdGt94MU0XLtJQjzm470ZW0Lyliq6-D-ACBxago-nL7bW3hByy9u4qrPcseGt9nDBtxez21nzH4j-kk-ikAGdM2lqgNjENR3W3iBBYZxCLEgg5FaWno-JnWFODzJ2EPDkL9YgV3eJXaSav9q-K9OGrHtGcdLoOoclYQdW6HGei2jvEzIvkJ7fOrE_BkaMDvwfP_mG1Wyfmazl4ZT-E3REcF7-H8fvG1Maj9lGE47sI9NaaLiuKxMUKkoOq7ATE-CsA56qjq0om1fHUHtL3k5dJaPw5dqFFc_I1pmQF2l6v32K36AQbL6URjYW2TRB5R_7PdZbxPltDvqVhv26deM1LrtslNSaQ1y8-v60TTOuJzwdCsBF2-a1Cgad2aG4c0gxmrniSlWna_ih_LT7LTZ78ekaoZK-QZbGYBhztrbJH8pG6OtYsABLu_EJbLwCZ8gemIFAL2str5ljuZjxGa8baorSqsutD4ZWG98EcUImwgTwfsXrg_fNe0Uyl2TmD1Jnr-cpdFvRHT9CtlmxJL0XV3CuWIB36kk7Sj451LQT1d3CUGDUYSob_cs6XtFvl8zo1L1iJO3CbaIL-X7qva6sYYjidWIQqBIwseA6TVk4ZZ4AB1lm8OMbmP-VwUqs4EahKtumqMiQXZoiUFyXyTKLz-soVKV_uVW4R9xcXy-pUYHMsYmRM2uDVc0eCkPkdr5NoidDFKrf03A4kPR2s3URy-bZdCORxE0KgtlQLx1eKtVi-Ku88pwYGe1IECBztZxTUfhisovrg-CZu2B3OOTHLZEZ3DTwH9ORlbfLRdJtLl21EkzHYHX485uwK40pglWChiD4G58da5pMDp2-tZtgrkAaEUw-gf3FoUya3HD4ywea6Tzxbua0wg9oJfPQYB4jUYPE8kQiPVTpz4TgFYI8iwU9sF8yUIRbW5c7kWjwIp=w2000-h1596',
  ogKey: '',
  ogType: '网站',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RTLS医疗保健，即时可用',
  date: '2023-11-02',
  tag1: '#医疗保健',
  tag2: '#患者定位追踪',
  tag3: '#智能医疗',
  tag4: '#患者管理',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: 'RTLS（实时定位系统）技术正在革新现代医疗领域。这项技术实时追踪患者的位置，高效管理医疗设备和人员，从而提高医疗场所的运营效率和患者安全。在本文中，我们将探讨RTLS医疗保健技术的重要性以及它所提供的即时好处。',
      dot: ''

    },
    {
      video : video,
      link : 'https://orbro.io/cn/enterprise/medical/patient',
      head: '如果您现在实施解决方案',
      subHead: '增强患者安全',
      desc: 'RTLS是通过患者位置追踪来增强患者安全的关键手段。实时跟踪患者位置使医务人员能够迅速评估患者的状况并立即应对紧急情况，减少紧急情况下的致命错误。',
      dot: ''
    },
    {
      head: '',
      subHead: '改善资源管理',
      desc: 'RTLS能够高效管理医疗设备和人员。获取医疗设备的实时位置信息有助于快速检索设备，高效维护和更换设备。此外，它优化医务人员的日程，避免日程冲突，使他们能够专注于患者护理。',
      dot: ''
    },
    {
      head: '',
      subHead: '预防感染',
      desc: 'RTLS有效管理医院内患者的活动，有助于预防感染的传播，并最小化医院内患者之间的接触。这限制了感染的传播并确保患者和医务人员的安全。',
      dot: ''
    },
    {
      head: '',
      subHead: '财务管理',
      desc: '引入RTLS可以通过高效利用资源实现成本节省。它防止了不必要的设备购买和库存浪费，并优化了劳动成本。这改善了医院的财务状况并允许有效的预算利用。',
      dot: ''
    },
    {
      head: '',
      subHead: '实时信息',
      desc: 'RTLS提供实时的位置信息，使医务人员能够更快速和更有效地做出决策。当患者的状况发生变化时，可以立即作出反应，改善治疗过程并实现最佳结果。',
      dot: ''
    },
    {
      head: '',
      subHead: '成本效益',
      desc: 'RTLS提供成本效益的解决方案，节省时间和资源，改善了组织的财务状况。它能够快速实现投资回报，并在长期内提高成本效益。',
      dot: ''
    },
    {
      head: '',
      subHead: '提高患者满意度',
      desc: '通过患者位置追踪，患者能够获得更个性化的护理，提高了他们对安全和便利的满意度。这为患者和家庭提供了信任和安心感。',
      dot: ''
    },
    {
      head: '总之，',
      subHead: '',
      desc: 'RTLS医疗保健技术正在革新现代医疗领域，通过立即实施可以提高患者安全和效率。它易于实施并能够快速取得成果，因此它是医疗机构和患者都受益的重要技术。这将使未来的医疗更安全且更高效。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '通过将UWB设备安装在工人或设备上，提供实时的位置追踪产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/cn/enterprise/medical/patient'
      },
      {
        img: medicalWaste.thumb,
        data: 'medicalWaste',
        link: '/cn/enterprise/medical/waste'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/cn/enterprise/office/asset'
      }
    ]

  }
}
