import { logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@assets/images/solution/logisticsSafety/video/rtlsVideo.mp4"

export default {

    helmet: '| What is IPS (Indoor Positioning System)?',
    desc: 'IPS, short for Indoor Positioning System, is a technology used to track the precise location of users or objects within a building. IPS is utilized in various applications and is needed in diverse areas such as indoor navigation, automation, security, environmental monitoring, and resource management.',
    keywords: '',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'What is IPS (Indoor Positioning System)? | Orbro',
    ogUrl: 'https://orbro.io/en/blog/indoor-positioning-system',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDk6TQ-h2KkQiYudK2spJrwM9-DVyXGqjpnyMQI2sZIQz1hxm14GXjbIy0_vPqBdcWNqBfevlZpTaHCDaKLTSInodrYeccUP3gpQNgAtrMwqEHlrjdh4WhikpPJ_iDLCi3PldIyG97g8uPiEGv2KbKurpE7JoCN5mRkfiH448dzhhpubMZxkB2ZKIJC7k8kNPYcSX1A-QOydJBw_yipl-pCZS_3oxhv2uYggjB0clzoEqTdYWRLydtMHV79DjszkbWJW3LTgrYEKIQP1Kbdw7ZItA-BwStqSuGehlMzlqxoFgQRChchtMnWac9VkUzOWnga-7_XUfmX90CtTqWRrFZg9Umjy8FX9288OQT_dXLNDDdowk_5t23b2mX-L7yW25zgxFHd3VtxgRongH9p94aLi4mMDO6fmT_8ajfcngVp9gOjPzHsmkOESsojVUQc706JPrK__OnMo7qr_aEVb15EIjZlqlJaJ4IhcIuXokNh-sp1nkFHuZ8qW_HXAB3FZu7r91T6rPAmKirnvFAtZdTghCXsX9nzmLybftlK1YYBWTAV0qcWkL5Ckpp2njt9pFjoXmTQqbdpz3duHY-AljIdMbCDzS444jPCLaKfXTkCmbV6WnZRaPfGR81_Q82hX3jxtuLKQmjMmCgLgCl3gp5L8CZi1hvvtQKMdcDSJ90ZUhsu2CZkmvwQ3Q_03VoJSlwwFZ3EUN3W_GB8e5cWXpNGvQccrSjeCnmIF9aVrHwtaIdWTvpskUJKjNzlqAafVUfTouYOSrV8OCfLPwSQTV8iSm2qnbRuoBigg9xouxN0bRwPkIiz67dGt4JKQcqivJnCoQxzvVomW1fVr4UbxMq6akImY3IxQ7ovGV81wA46n8tx5WPSPLMCEKQBK9FkzHiHqb8Pxxw9rzzuIa6TtoY6KRfti9zMv4fsmGqX6Q1_R5B-ABtExcOjVt-x_mS5Y3ZQR0jJIdKh4GEj98Pa9oTTF3saa28UWqTTjqRErmeVY04QTsy_SqFx9CyzU_2V1_zbdvez8hyZLuj5L9K-cvJyCKZTyBCrw0YzWwOX2MZ6fCyqvFzPfzzKJZRUncB0UrOVKw7dGDdDlaX5CqXBk-kR06RFpJIEf1fS0zEk2cBhS3NX2BU_PuueEkq1bQyLe9sG9kxqLXZ9jOc8ImIMr4X6JZgbKdO0-x0t_LkclmAjG3g4QuaNl63oDUgU1QUvQoQhDiCEHrK0xqXRaU7qSPbYXesuwkNkmOgVwPoJnNa-jYCoJ__RR4WZomXaiPckDuGTkSKx2dOfaSs7TbvIb7fqqcv5kVXnVeEtCheiIU220AnBQ6P4vLOzcQM3JP63j9eLnrIAgh2NMqXpyNVhSY5elNnry6i9vqriqjEwypIBg-eRxHD_S7iWd7gaTLIA_cu_QyeVrW7fYEMoc830qGVhoMH7VNFbffpm5PtC_9B2e2P4xe4fQIisHlT1tnhnFJynZshU2YAiNTWNWMEnpEsitAZMUPJ6oRGSQe_DK9xz-bmpANM-9WSuCR8vlKsLHuWX8A516ShbC0Y9wnZQrN3RK1Rt3xRlX=w2045-h1221',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'What is IPS (Indoor Positioning System)?',
    date: '2024-01-23',
    tag1: '#IPS',
    tag2: '#IndoorPositioning',
    tag3: '#RTLS',
    tag4: '#UWB',

    // <br/>
    text: [
        {
            head: 'What is IPS (Indoor Positioning System)?',
            subHead: '',
            desc: 'IPS stands for Indoor Positioning System, a technology used to track the precise location of users or objects within a building. IPS is used in various applications and is essential in various fields such as indoor navigation, automation, security, environmental monitoring, and resource management. While outdoor location tracking systems like GPS are widely used, indoor location tracking presents a more complex challenge. One of the technologies developed to address this issue is IPS. In this article, we will delve into the concept of IPS and more.',
            dot: ''
        },
        {
            head: 'The Importance of IPS',
            subHead: '',
            desc: 'The proliferation of smartphones and other mobile devices in modern society has significantly increased the importance of location determination technology. This technology plays a crucial role in providing efficient information when we use map applications to find our way or when shopping indoors. One of these location determination technologies is "Indoor Positioning System" or IPS. IPS is a technology and system used to accurately track and locate devices or users, primarily in indoor environments, unlike the Global Positioning System (GPS) which is not effective indoors.',
            dot: ''
        },
        {
            head: 'How IPS Works',
            subHead: '',
            desc: '',
            dot: {
                a : '<strong> • Utilizing UWB Technology : </strong> UWB technology accurately measures the distance between nearby devices or tags. It calculates the distance between the user and UWB tags (e.g., smartphones or bank cards) using this technology.',
                b : '<strong> • BLE (Bluetooth Low Energy) :</strong> This method uses Bluetooth LE beacons to determine indoor locations. Beacons transmit unique identifiers, and location estimation is based on these identifiers. While it offers high accuracy, deploying beacons can be costly.',
                c : `<strong> • Utilizing AI Cameras : </strong> AI cameras analyze images obtained from cameras to understand the surrounding environment and detect objects or people. This allows cameras to track the user's position and movements in real-time.`,
                d : `<strong> • Wi-Fi-Based Location Determination : </strong> It determines the user's location based on the signal strength of Wi-Fi access points. Each Wi-Fi access point has a unique MAC address, and this information is used to determine the user's location. However, accuracy may be lower, and there can be signal interference and limited range.`,
            }
        },
        {
            video : video,
            link : '/en/enterprise/logistics/safety',
            videoDesc : 'Orbro Logistics Digital Twin, click to navigate.',
            head: 'Advantages of IPS Technology',
            subHead: '',
            desc: 'Since GPS does not work indoors, IPS improves the accuracy of indoor location determination. It enables precise tracking of the exact location of users or assets based on accurate location data. Additionally, IPS allows real-time tracking of users or assets, enabling real-time monitoring and management in indoor environments. This is crucial for taking emergency actions or efficiently managing assets in critical situations.<br/><br/>IPS can automate and streamline operations and processes. For example, using IPS for location determination of autonomous robots in a car factory can enhance productivity. In the logistics industry, IPS can optimize logistics and asset management, leading to cost savings. It can also enhance emergency response in various places such as hospitals, schools, airports, by facilitating patient tracking, ensuring student and staff safety, and providing guidance for large-scale events, among other uses.',
            dot: ''
        },
        {
            head: 'Applications of IPS',
            subHead: 'Indoor Navigation and Marketing Inside Stores',
            desc: '',
            dot: {
                a : ' • Helps customers easily find desired products within stores.',
                b : ' • Simplifies ordering and payment processes to enhance the customer experience.',
                c : ' • Improves marketing by tracking customer locations and providing product recommendations and discounts.'
            }
        },
        {
            head: '',
            subHead: 'Patient Tracking Inside Hospitals and Medical Facilities',
            desc: '',
            dot: {
                a : ' • Tracks patients in medical facilities and monitors their locations in real-time.',
                b : ' • Enables medical staff to quickly locate patients and prepare for emergencies.',
                c : ' • Used for effective infection prevention and medical equipment management.',
            }
        },
        {
            head: '',
            subHead: 'Passenger Guidance and Security Inside Airports',
            desc: '',
            dot: {
                a : ' • Helps passengers easily find boarding gates, baggage claim areas, and service facilities within airports.',
                b : ' • Assists in security management and queue management.',
                c : ' • Guides passengers to gates on time, ensuring compliance with flight schedules.',
            }
        },
        {
            head: '',
            subHead: 'Management of Games and Events Inside Indoor Sports and Entertainment Facilities',
            desc: '',
            dot: {
                a : ' • Allows spectators to find seats and order food and drinks within sports arenas.',
                b : ' • Provides real-time game results and team information to enhance the entertainment experience.',
                c : ' • Efficiently manages games and events.',
            }
        },
        {
            head: '',
            subHead: 'Logistics and Asset Management Inside Factories and Warehouses',
            desc: '',
            dot: {
                a : ' • Tracks equipment, products, and assets within factories or warehouses and locates them.',
                b : ' • Optimizes logistics and supply chain management to increase productivity.',
                c : ' • Aids in inventory management and optimization of movement paths.',
            }
        },
        {
            head: '',
            subHead: 'Student and Staff Guidance Inside Schools and University Campuses',
            desc: '',
            dot: {
                a : ' • Helps students and staff easily find classrooms, libraries, cafeterias, and parking areas within campuses.',
                b : ' • Supports student attendance tracking and educational resource management.',
            }
        },
        {
            head: '',
            subHead: 'Indoor Autonomous Driving and Robot Collaboration',
            desc: '',
            dot: {
                a : ' • Indoor location determination technology assists autonomous vehicles and robots in following precise paths.',
                b : ' • Enhances the efficiency of robot operations in various tasks.',
            }
        },
        {
            head: 'The Future Outlook of IPS',
            subHead: '',
            desc: 'IPS technology continues to advance, and it is expected to improve the accuracy and efficiency of indoor location determination. Integration with 5G networks, the introduction of artificial intelligence and machine learning, advancements in sensor technology, and other developments are promising for the future of IPS. More accurate and rapid location determination is expected to bring innovation and efficiency to various industries.',
            dot: ''
        },
        {
            head: '',
            subHead: '',
            desc: '',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'A product that helps in real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/en/enterprise/logistics/asset'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/en/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/en/enterprise/manufacturing/asset'
            }
        ]

    }
}
