export default {

    back: 'ORBROブログに戻る',
    newsletter: 'ニュースレターの購読',
    info: 'さまざまな情報を受け取る',
    placeholder: 'メールアドレスを入力してください',
    subscribe: '購読する',
    terms1: "情報を提供することにより、Orbroの",
    terms2: "",
    terms3: 'プライバシーポリシー',
    terms4: 'に同意します。',

    more: 'もっと見る',
    less: '閉じる',

    recent: '最新のブログ記事',
    share: '記事が気に入りましたか？<br/>共有する',

    priceUnknown: '要問い合わせ',
    price: '合計金額：',
    product: {
        title: '製品構成を確認する',
        access: '革新的なアクセスシステムの製品構成を確認してください。',
        rtls: '革新的な位置システムの製品構成を確認してください。',
        camera: 'カメラシステムの製品構成を確認してください。'
    },

    uwbVideoDesc: '<br/><strong>(UWBテクノロジーを使用したORBROソリューション、病院)</strong>',
    bleVideoDesc: '<br/><strong>(UWBテクノロジーを使用したORBROソリューション、製造施設)</strong>',
    svVideoDesc: '<br/><strong>(カメラ技術を使用したORBROソリューション、展示会場)</strong>',
    requestHead: '詳細な情報が必要な場合は、',
    request: 'お気軽にお問い合わせ',
    requestTail: 'ください。',
}
