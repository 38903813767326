import { IconBell, IconBinocularsGray, IconChainLink, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import mainVideo from "@src/assets/images/solution/constructionSafety/video/rtlsVideo.mp4";
import subVideo1 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo4.mp4";
import subVideo5 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo5.mp4";
export default {

    banner: {
        title: '工作人员安全管理',
        desc: '工作人员的安全是最重要的。在高风险环境中，如果无法确保工作人员的安全，不仅会影响生产效率，还可能承担法律责任。此外，安全的建筑工地对于塑造企业形象和赢得利益相关者的信任至关重要。',

    },

    video: {
        title: '让建筑工地的工作人员更加安全。',
        desc: '实时位置跟踪(RTLS)技术能够实时提供建筑工地工作人员的位置信息。管理员可以预先检测到危险情况，并在问题发生时能更快地做出应对。<br/>此外，它支持遵守<strong>安全规定</strong>，并建立工作人员与管理人员之间的信任。',
        play: mainVideo
    },

    func: [
        {
            title: '确认安全装备佩戴',
            desc: '实时检测工作人员是否佩戴安全帽等安全装备，从而预防工作中的安全事故，维持安全的工作环境。加强现场安全管理，提高组织的安全性和可靠性。',
            sub1: '高精度提供',
            icon1: IconRulerGray,
            sub2: '实时状态了解',
            icon2: IconClockGray,
            video: subVideo1
        },
        {
            title: '实时工作人员位置跟踪',
            desc: '实时跟踪工作人员的位置，监测安全情况，以便及时监控潜在的危险区域或在紧急情况下迅速响应。预防潜在的风险。',
            sub1: '实时位置跟踪',
            icon1: IconBinocularsGray,
            sub2: '快速数据共享',
            icon2: IconTvocGray,
            video: subVideo2
        },
        {
            title: '实时重型设备位置跟踪',
            desc: '安全不仅关乎人，还关乎潜在事故风险的重型设备。实时跟踪潜在事故风险的重型设备的位置，监测工作人员是否靠近或错误地进入区域，以预防事故的发生。',
            sub1: '实时位置跟踪',
            icon1: IconBinocularsGray,
            sub2: '高精度提供',
            icon2: IconRulerGray,
            video: subVideo3
        },
        {
            title: '工作人员行动轨迹监测',
            desc: '如果工作人员的路径涉及到危险设备或材料，可以向管理人员发出警告。这样可以快速应对或进行紧急救援。',
            sub1: '提供统计数据',
            icon1: IconChartBarGray,
            sub2: '数据云存储',
            icon2: IconCloudCheckGray,
            video: subVideo4
        },
        {
            title: '设定安全区域',
            desc: '事先设定禁止进入的区域或需注意的区域，可向靠近该区域的工作人员发出警告，或向管理人员发送提醒。防范事故的发生。',
            sub1: '设定自由范围',
            icon1: IconZoneGray,
            sub2: '设置多个区域',
            icon2: IconTagGray,
            video: subVideo5
        },
    ],

    subfunc: {

        title: '多种功能',
        desc: '确保工作人员的安全将提高效率并减少因事故造成的损失。为了保护建筑工地工作人员的安全，Orbro构建了最佳功能，使系统能够妥善处理可能发生的安全事故，例如工作人员接近危险区域或事故风险的重型设备，以及在发生事故时进行适当响应。',

        data: [
            {
                icon: IconChartRadius,
                title: '报告和分析',
                desc: '基于现场数据生成报告和分析资料。',
            },
            {
                icon: IconBell,
                title: '实时警报功能',
                desc: '发生异常情况时，发送警报，以便立即采取措施。',
            },
            {
                icon: IconRoute,
                title: '历史轨迹查询',
                desc: '查询工作人员在现场的移动轨迹并对数据进行化。',
            },
            {
                icon: IconDashboard,
                title: '状态监控',
                desc: '实时查看工作人员的移动情况。',
            },
            {
                icon: IconWarning,
                title: '逃逸检测',
                desc: '实时跟踪工作人员是否离开现场或接近禁止区域。',
            },
            {
                icon: IconSearch,
                title: '轨迹模式分析',
                desc: '分析工作人员的工作模式，提高工作效率。',
            },
            {
                icon: IconChainLink,
                title: '数据链接',
                desc: '与RFID，IOT，安全，视频等系统连接，以有效利用。',
            },
            {
                icon: IconSecurity,
                title: '数据安全',
                desc: '保持高水平的数据安全，提供可靠的解决方案。',
            },
        ],
    },

    system: {
        title: '系统配置',
        img: rtlsSystem
    },

    productTitle: '产品配置',
    product: [
        {
            tag: 'workerPackage'
        },
        {
            tag: 'assetPackage'
        }
    ],

    contents: [
        {
            data: 'constructionAsset'
        },
        {
            data: 'constructionEquipment'
        },
        {
            data: 'blogRTLS'
        },
        {
            data: 'blogMedical'
        },
    ]
}
