import { IconBlueCircle, IconBlueCycle, IconBlueDevelop, IconBlueFigure, IconBluePlus, IconBluePower, IconBlueScan, IconBlueShield } from "@assets/images/main/icon/blue";
import { osBanner, osLogoDesktop, osLogoMobile, osLogoTablet } from "@assets/images/main/os/bannerImg";
import {
  OsSectionDesktop1, OsSectionTablet1, OsSectionMobile1,
  OsSectionDesktop2, OsSectionTablet2, OsSectionMobile2,
  OsSectionDesktop3, OsSectionTablet3, OsSectionMobile3,
  OsSectionDesktop4, OsSectionTablet4, OsSectionMobile4,
  OsSectionDesktop5, OsSectionTablet5, OsSectionMobile5,
  OsSectionDesktop6, OsSectionTablet6, OsSectionMobile6,
  OsSectionDesktop7, OsSectionTablet7, OsSectionMobile7,
  OsSectionDesktop8, OsSectionTablet8, OsSectionMobile8,
  OsSectionDesktop9, OsSectionTablet9, OsSectionMobile9,
  OsSectionDesktop10, OsSectionTablet10, OsSectionMobile10,
  OsSectionDesktop11, OsSectionTablet11, OsSectionMobile11,
  OsSectionDesktop12, OsSectionTablet12, OsSectionMobile12,
  OsSectionDesktop13, OsSectionTablet13, OsSectionMobile13,
  OsSectionDesktop14, OsSectionTablet14, OsSectionMobile14,
  OsSectionDesktop15, OsSectionTablet15, OsSectionMobile15,
  OsSectionDesktop16, OsSectionTablet16, OsSectionMobile16,
  OsSectionDesktop17, OsSectionTablet17, OsSectionMobile17,
  OsTextImg1, OsTextImg2, OsTextImg3, OsTextImg4,
} from "@assets/images/main/os/section";


export default {

  title: '디지털 트윈 공간 관리 플랫폼',
  osLogo: {
    desktop: osLogoDesktop,
    tablet: osLogoTablet,
    mobile: osLogoMobile
  },

  bannerImg: osBanner,

  section1: {
    img: OsTextImg1,
    title: '가상 공간을 현실처럼, 공간 관리',
    desc: '실제 공간과 동일한 가상의 공간에 특정 구역을 설정할 수 있습니다.<br/>피플 카운팅, 위험 구역, 역주행 감지 구역을 설정하여 관리합니다.',
    box: [
      {
        size: 1,
        title: '간단한 Zone 설정',
        desc: '관리하고 추작하고자 하는 영역을 쉽게 설정하여<br/>추적하고자 하는 데이터를 얻을 수 있습니다.',
        img: {
          desktop: OsSectionDesktop1,
          tablet: OsSectionTablet1,
          mobile: OsSectionMobile1,
        },
      },
      {
        size: 2,
        title: '위험 구역 설정과 알람 기능',
        desc: '위험 구역을 설정하고 외부인 접근 시, 알람을 보내어 경고합니다.',
        img: {
          desktop: OsSectionDesktop2,
          tablet: OsSectionTablet2,
          mobile: OsSectionMobile2,
        },
      },
      {
        size: 2,
        title: '피플 카운팅',
        desc: '특정 구역에 사람 및 자산 등의 입출입 데이터를 추출 할 수 있습니다.',
        img: {
          desktop: OsSectionDesktop3,
          tablet: OsSectionTablet3,
          mobile: OsSectionMobile3,
        },
      },
      {
        size: 2,
        title: '역주행 감지',
        desc: '특정 구역에 하나의 방향을 지정하고 해당 방향과 반대로 이동하는 객체 및 사물을 감지하고 알람을 보냅니다.',
        img: {
          desktop: OsSectionDesktop4,
          tablet: OsSectionTablet4,
          mobile: OsSectionMobile4,
        },
      },
      {
        size: 2,
        title: '시간대별 유입 추적',
        desc: '설정한 구역에 진입한 객체 및 사람 등의 데이터를 시간대별로 저장하여 데이터를 제공합니다.',
        img: {
          desktop: OsSectionDesktop5,
          tablet: OsSectionTablet5,
          mobile: OsSectionMobile5,
        },
      },
    ]
  },


  section2: {
    img: OsTextImg2,
    title: '실시간 데이터 관리',
    desc: '실제 공간에서 일어나는 일을 가상의 공간에서 실시간으로 파악합니다.<br/>필요한 특정 데이터는 검색을 통해 확인하거나, 자료를 받아 사용할 수 있습니다.',
    box: [
      {
        size: 2,
        title: '실시간 위치 파악',
        desc: '연결된 오브로 장비로부터 실시간 데이터를 수집하고 분석하여,<br/>신속한 상황 파악과 의사결정을 지원합니다.',
        img: {
          desktop: OsSectionDesktop6,
          tablet: OsSectionTablet6,
          mobile: OsSectionMobile6,
        },
      },
      {
        size: 2,
        title: '데이터 수집',
        desc: 'IoT 장비를 비롯하여 설치된 장비들이 수집하고 있는 데이터를 실시간으로 파악할 수 있습니다.',
        img: {
          desktop: OsSectionDesktop7,
          tablet: OsSectionTablet7,
          mobile: OsSectionMobile7,
        },
      },
      {
        size: 2,
        title: '디바이스 검색',
        desc: '현재 찾고자 하는 자산이나 사람과 같은 객체가 어디에 있는지 검색하여<br/>실시간으로 파악할 수 있습니다.',
        img: {
          desktop: OsSectionDesktop8,
          tablet: OsSectionTablet8,
          mobile: OsSectionMobile8,
        },
      },
      {
        size: 2,
        title: '데이터 다운로드',
        desc: '실시간 데이터 분석을 통해 이상 상황을 조기에 감지하여 알람을 보내어 조기 대응이 가능하도록 합니다.',
        img: {
          desktop: OsSectionDesktop9,
          tablet: OsSectionTablet9,
          mobile: OsSectionMobile9,
        },
      },

    ]
  },


  section3: {
    img: OsTextImg3,
    title: '편리한 관리 기능',
    desc: '실제 공간에서 일어나는 일을 가상의 공간에서 실시간으로 파악합니다.<br/>필요한 특정 데이터는 검색을 통해 확인하거나, 자료를 받아 사용할 수 있습니다.',
    box: [
      {
        size: 2,
        title: 'AI 알고리즘 적용',
        desc: '다양한 공간에 알맞게 개발된 오브로 알고리즘을 변경하여 최상의 데이터를 얻을 수 있습니다.',
        img: {
          desktop: OsSectionDesktop10,
          tablet: OsSectionTablet10,
          mobile: OsSectionMobile10,
        },
      },
      {
        size: 2,
        title: '장비 관리',
        desc: 'IoT 장비를 효과적으로 관리할 수 있는 유연한 구조를 가지고 있어, 새로운 장비를 추가하거나 기존 시스템을 업그레이드하는 데 용이합니다.',
        img: {
          desktop: OsSectionDesktop11,
          tablet: OsSectionTablet11,
          mobile: OsSectionMobile11,
        },
      },
      {
        size: 2,
        title: '맞춤형 대시보드',
        desc: '맞춤형 대시보드를 통해 사용자는 중요한 데이터를 쉽게 모니터링하고, 데이터를 빠르게 이해하고 효과적인 결정을 내릴 수 있는 인사이트를 제공합니다.',
        img: {
          desktop: OsSectionDesktop12,
          tablet: OsSectionTablet12,
          mobile: OsSectionMobile12,
        },
      },
      {
        size: 2,
        title: '모바일 위젯 제공',
        desc: '위젲 제공을 통해 앱 실행 없이 다양한 데이터를 편리하게 확인하고, 빠른 앱 접근이 가능합니다.',
        img: {
          desktop: OsSectionDesktop13,
          tablet: OsSectionTablet13,
          mobile: OsSectionMobile13,
        },
      },

    ]
  },



  section4: {
    img: OsTextImg4,
    title: '하나의 시스템에서 통합된 OS',
    desc: '오브로 OS는 강력하고 편리하지만 그 과정이 복잡하지 않습니다.<br/>어떤 제품이라도 손쉽게 설정하고 바로 사용할 수 있습니다.',
    box: [
      {
        link: '/rtls',
        size: 2,
        title: '위치 추적 시스템',
        desc: 'UWB, BLE, 비콘 등 설치된 제품을 오브로 OS에 등록할 수 있습니다.',
        img: {
          desktop: OsSectionDesktop14,
          tablet: OsSectionTablet14,
          mobile: OsSectionMobile14,
        },
      },
      {
        link: '/sensor/LoRaGateway',
        size: 2,
        title: '산업용 IoT',
        desc: '실시간 데이터 분석을 통해 이상 상황을 조기에 감지하여 알람을 보내어 조기 대응이 가능하도록 합니다.',
        img: {
          desktop: OsSectionDesktop15,
          tablet: OsSectionTablet15,
          mobile: OsSectionMobile15,
        },
      },
      {
        link: '/access',
        size: 2,
        title: '출입 시스템',
        desc: '오브로 출입 시스템을 등록하고 출입 데이터를 수집합니다.',
        img: {
          desktop: OsSectionDesktop16,
          tablet: OsSectionTablet16,
          mobile: OsSectionMobile16,
        },
      },
      {
        link: '/camera',
        size: 2,
        title: 'AI 카메라 위치추적',
        desc: '실제 공간에 설치된 카메라와 가상의 지도를 쉽게 매핑하여 설정할 수 있습니다.',
        img: {
          desktop: OsSectionDesktop17,
          tablet: OsSectionTablet17,
          mobile: OsSectionMobile17,
        },
      },

    ]
  },

  function: {
    title: '그 외의 다양한 기능들',
    box: [
      {
        icon: IconBlueScan,
        title: '실시간 데이터 분석 및 시각화',
        desc: 'OS는 수집된 데이터를 분석하고 시각화하여 사용자에게 제공할 수 있습니다. 이를 통해 디지털 트윈을 통해 수집된 정보를 실시간으로 이해하고 활용할 수 있습니다.',
      },
      {
        icon: IconBlueShield,
        title: '보안 및 개인 정보 보호',
        desc: '디지털 트윈을 보호하기 위한 강력한 보안 기능을 제공할 것입니다. 민감한 정보에 대한 액세스를 제한하고 데이터의 무단 수정을 방지하여 보안 문제를 최소화할 수 있습니다.',
      },
      {
        icon: IconBlueDevelop,
        title: '개발자 친화적',
        desc: '개발자들이 쉽게 디지털 트윈 기능을 활용하고 확장할 수 있도록 개발되었을 것입니다. 강력한 API 및 개발 도구를 제공하여 새로운 애플리케이션 및 서비스를 개발할 수 있습니다.',
      },
      {
        icon: IconBlueFigure,
        title: '인공지능 통합',
        desc: '인공지능(AI) 기술을 통합하여 디지털 트윈을 더욱 지능적으로 관리하고 최적화할 수 있습니다. AI 알고리즘을 사용하여 데이터를 분석하고 예측 모델을 구축하여 시스템의 성능을 향상시킬 수 있습니다.',
      },
      {
        icon: IconBluePower,
        title: '환경 모니터링 및 지능형 에너지 관리',
        desc: '환경 모니터링 센서를 통합하여 에너지 소비 및 자원 사용을 최적화할 수 있습니다. 실시간으로 환경 상태를 모니터링하고 필요에 따라 자동으로 시스템을 조절하여 에너지 효율성을 극대화할 수 있습니다.',
      },
      {
        icon: IconBlueCycle,
        title: '자동화 및 스마트 제어',
        desc: '디지털 트윈을 통해 수집된 정보를 기반으로 자동화된 결정을 내릴 수 있습니다. 예를 들어, 특정 조건이 충족되면 자동으로 장치를 제어하거나 프로세스를 실행할 수 있습니다.',
      },
      {
        icon: IconBluePlus,
        title: '사용자 정의 가능한 인터페이스',
        desc: '사용자가 필요에 따라 인터페이스를 사용자 정의할 수 있는 기능을 제공할 것입니다. 사용자는 디지털 트윈을 모니터링하고 제어하기 위한 대시보드나 애플리케이션을 자유롭게 구성할 수 있습니다.',
      },
      {
        icon: IconBlueCircle,
        title: '생태계 확장성',
        desc: '개발자 및 기업이 자체적으로 애드온이나 플러그인을 개발하여 시스템을 확장할 수 있는 생태계를 구축할 수 있습니다. 이를 통해 다양한 산업 및 응용 분야에 대한 커스터마이징된 솔루션을 제공할 수 있습니다.',
      },
    ]
  },

  faqTitle: '자주 묻는 질문',


  beacon: {
    index: 4
  },
  rtls: {
    index: 1
  },
  camera: {
    index: 2
  },
  access: {
    index: 3
  },
  sensor: {
    index: 4
  },
  integration: {
    index: 1
  },
}