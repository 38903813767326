import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledHeading, StyledLabel, StyledParagraph } from "@components/Text";
import React, { useEffect, useState } from 'react';
import { Trans } from "react-i18next";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";
import { ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import { LeftBox, RTLSPage, RightBox, RightSection } from "./RTLS.style";
import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import simple from "@src/lang/ko/simple";
import i18n from "@pages/i18n";
import { useLocation } from "react-router-dom";

import home from "@src/lang/ko/main/home/home";
import homeEn from "@src/lang/en/main/home/home";
import homeJp from "@src/lang/jp/main/home/home";
import homeCn from "@src/lang/cn/main/home/home";
import { HeadingH3 } from "@components/Text/Heading/Heading.style";
import { ShowMoreButton } from "@pages/CommonStyle/commonBtn";
import { CardTextBox } from "@pages/Main/Common/Functions/Functions.style";


const RTLS = () => {

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const location = useLocation();

  const getPath = (i : number) => {
    if(location.pathname.includes('/en/')){
      return homeEn.rtls.card[i].link;
    } else if(location.pathname.includes('/jp/')){
      return homeJp.rtls.card[i].link;
    } else if(location.pathname.includes('/cn/')){
      return homeCn.rtls.card[i].link;
    } else {
      return home.rtls.card[i].link;
    }
  }

  return (
    <>
      <StyledGridContainer>
        <RTLSPage>
          <DivAllCenter>
            <DisplayH2 size="small">
              <Trans i18nKey={`home.rtls.title`} />
            </DisplayH2>
            <Spacing pixel="16" />
            <StyledParagraph size="medium">
              <p>
                <Trans i18nKey={`home.rtls.desc`} />
              </p>
            </StyledParagraph>
            <Spacing pixel="64" />
          </DivAllCenter>

          <StyledGridRow>
            <StyledGridColumn desktop={6} tablet={4} mobile={4}>
              <LeftBox img={home.rtls.card[0].img[layoutType]}>
                <CardTextBox>
                  <StyledLabel size="xsmall">
                    <Trans i18nKey={`home.rtls.card.${0}.tag`} />
                  </StyledLabel>
                  <Spacing pixel="4" />
                  <HeadingH3 size="small">
                    <Trans i18nKey={`home.rtls.card.${0}.title`} />
                  </HeadingH3>
                  <Spacing pixel="16" />
                  <ShowMoreButton href={getPath(0)} target="_blank" rel="noopener noreferrer">
                    <img src={simple.showmoreImg[i18n.language]} alt="learn more button"/>
                  </ShowMoreButton>
                </CardTextBox>
              </LeftBox>
            </StyledGridColumn>

            <StyledGridColumn desktop={6} tablet={4} mobile={4}>
              <RightSection>
                <RightBox img={home.rtls.card[1].img[layoutType]}>
                  <CardTextBox>
                    <HeadingH3 size="small">
                      <Trans i18nKey={`home.rtls.card.${1}.title`} />
                    </HeadingH3>
                    <Spacing pixel="4" />
                    <ParagraphColor color="#646464" size="xsmall">
                      <p>
                        <Trans i18nKey={`home.rtls.card.${1}.desc`} />
                      </p>
                    </ParagraphColor>
                    <Spacing pixel="16" />
                    <ShowMoreButton href={getPath(1)} target="_blank" rel="noopener noreferrer">
                      <img src={simple.showmoreImg[i18n.language]} alt="learn more button"/>
                    </ShowMoreButton>
                  </CardTextBox>
                </RightBox>

                <RightBox img={home.rtls.card[2].img[layoutType]}>
                  <CardTextBox>
                    <HeadingH3 size="small">
                      <Trans i18nKey={`home.rtls.card.${2}.title`} />
                    </HeadingH3>
                    <Spacing pixel="4" />
                    <ParagraphColor color="#646464" size="xsmall">
                      <p>
                        <Trans i18nKey={`home.rtls.card.${2}.desc`} />
                      </p>
                    </ParagraphColor>
                    <Spacing pixel="16" />
                    <ShowMoreButton href={getPath(2)} target="_blank" rel="noopener noreferrer">
                      <img src={simple.showmoreImg[i18n.language]} alt="learn more button"/>
                    </ShowMoreButton>
                  </CardTextBox>
                </RightBox>
              </RightSection>
            </StyledGridColumn>
          </StyledGridRow>

        </RTLSPage>
      </StyledGridContainer>

    </>
  )
}

export default RTLS;