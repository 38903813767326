
import React, { useState } from "react";
import { Trans } from "react-i18next";

import {
  StyledNewsLetterBox, StyledSubscribeBox, StyledDownloadPolicyBox,
  StyledDownloadPolicy, StyledDownloadPolicyLink
  , StyledDownloadSubmit, StyledDownloadPolicyLinkText,
  StyledDownloadSubmitText
} from "./NewsLetter.style";
import axios from "axios";
import emailjs from 'emailjs-com';
import { StyledGridContainer } from "@components/Grid/Grid.style";
import { useLocation } from "react-router-dom";
import { HeadingH2blue } from "@components/Text/Heading/Heading.style";
import { StyledDisplay } from "@components/Text";
import { Spacing } from "@pages/CommonStyle/common.style";
import { StyledDownloadMail, StyledDownloadMailBox } from "@pages/Enterprise/Blog/Common/BlogNewLetter.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import ModalDone from "@pages/Enterprise/Download/ModalDone/ModalDone";

export interface RequestFormData {
  email: string,
}

//엔터프라이즈 페이지 뉴스레터

const BlogNewLetter = () => {



  let placeholder;
  let alertMsg;
  let emptyMsg;
  const location = useLocation();
  if (location.pathname.startsWith('/en/')) {
    placeholder = 'Please enter an email'
    alertMsg = 'Email format is invalid'
    emptyMsg = 'There are empty values'
  } else if (location.pathname.startsWith('/jp/')) {
    placeholder = 'メールを入力してください'
    alertMsg = 'メール形式が正しくありません'
    emptyMsg = '空の値があります'
  } else if (location.pathname.includes('/cn/')) {
    placeholder = "请输入电子邮箱。"
    alertMsg = "电子邮箱格式不正确"
    emptyMsg = "存在空值"
  } else {
    placeholder = '이메일을 입력해 주세요.'
    alertMsg = '이메일 형식이 올바르지 않습니다'
    emptyMsg = '비어있는 값이 있습니다'
  }


  const [isModalOpen, setModalOpen] = useState(false);

  const onSuccess = () => {
    setModalOpen(false);
  }


  const [email, setEmail] = useState<string>('');

  const onChange = (e) => {

    //Email 콜백
    const email = e.target.value;
    setEmail(email);
  }


  // Input event
  const handleInput = (event, callback) => {
    const value = event.target.value;
    callback(value);
  }

  const validateForm = () => {
    const data = { email };

    if (email === '') {
      alert(emptyMsg)
      return;
    }
    else if (!email.includes('@') || !email.includes('.')) {
      alert(alertMsg)
      return;
    }
    setModalOpen(!isModalOpen);
    subscribe();
    if (!email.includes('tech42')) {
      onClickEmail();
    } else {
      onClickEmailSpam();
    }
  }


  const onClickEmail = () => {
    emailjs.init('SoQtE3CThBY_wpeDn');
    const mailForm = {
      email: email,
    }

    emailjs.send('service_7yv5b7d', 'template_8o3j6bm', mailForm)
      .then((response) => {
        console.log('이메일 보내기 성공:', response);
      })
      .catch((error) => {
        console.error('이메일 보내기 실패:', error);
      });

  }

  const onClickEmailSpam = () => {
    emailjs.init('SoQtE3CThBY_wpeDn');
    const mailForm = {
      email: email,
    }

    emailjs.send('service_7yv5b7d', 'template_zt2sf0o', mailForm)
      .then((response) => {
        console.log('이메일 보내기 성공:', response);
      })
      .catch((error) => {
        console.error('이메일 보내기 실패:', error);
      });

  }

  const subscribe = () => {

    const stibee = 'https://api.stibee.com/v1/lists/253278/subscribers'
    const apiKey = '98bfb7a271ec803ad3d00fb3e5f5402d66b9ea53af2df75fcf5b0995b937696ea2c89f06d924bd28b75edb6bf58d10c364de29acde10a0a00946b9d48a13215f'
    const subData = {
      "eventOccuredBy": "SUBSCRIBER",
      "confirmEmailYN": "N",
      "subscribers": [
        {
          "email": `${email}`
        }
      ]
    }

    axios.post(stibee, subData, {
      headers: {
        'AccessToken': apiKey,
        'Content-Type': 'application/json',
      }
    })
      .then(function (res) {
        console.log(res);
      })
      .catch(function (err) {
        console.log(err);
      })
      ;

  };

  return (
    <StyledGridContainer>

      <StyledNewsLetterBox>

        <HeadingH2blue size="xxsmall" >
          <Trans i18nKey={"blog.newletter"} />
        </HeadingH2blue>

        <Spacing pixel="8" />
        <DivAllCenter>
          <StyledDisplay size="small">
            <p>
              <Trans i18nKey={"solutionCommon.news.title"} />
            </p>
          </StyledDisplay>
        </DivAllCenter>

        <StyledSubscribeBox>
          <StyledDownloadMailBox>
            <StyledDownloadMail
              value={email}
              placeholder={placeholder || ''}
              onInput={(e) => { handleInput(e, setEmail) }}
              onChange={onChange}
            />
          </StyledDownloadMailBox>


          <StyledDownloadSubmit onClick={validateForm} bgcolor={"#2A70F0"} >
            <StyledDownloadSubmitText size="medium"> <Trans i18nKey={"blog.subscribe"} /> </StyledDownloadSubmitText>
          </StyledDownloadSubmit>


        </StyledSubscribeBox>

        <StyledDownloadPolicyBox>


          <StyledDownloadPolicy size="small">
            <span>
              <Trans i18nKey={`simple.terms1`} />
            </span>
            {' '}
            <span>
              <a href="/terms/2" target="_blank" rel="noopener noreferrer">
                <Trans i18nKey={`simple.terms3`} />
              </a>
            </span>
            {' '}
            <span>
              <Trans i18nKey={`simple.terms4`} />
            </span>

          </StyledDownloadPolicy>
        </StyledDownloadPolicyBox>

        {isModalOpen && (
          <ModalDone onClickToggleModal={onSuccess}>
          </ModalDone>
        )}


      </StyledNewsLetterBox>

    </StyledGridContainer>

  )
};

export default BlogNewLetter; 