import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import digitaltwin from "@src/assets/images/digitaltwin/video/traffic.mp4";

export default {

    helmet: '| What is a Smart City?',
    desc: `A smart city is a concept that utilizes digital twin technology to digitally model the physical elements of a city, enabling intelligent management and optimization of the city's infrastructure and services.`,
    keywords: 'smart city, digital twin, digital transformation, rtls, orbro, 오브로, 콩테크"',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'What is a Smart City? :: Orbro Blog',
    ogUrl: 'https://orbro.io/en/blog/what-is-smart-city',
    ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDlPQWtEdPqpk5QG-xfimTOJPt6-WAQc9XeRZnvdeRnqh_rxt6qJtKZOqz_FUESiTUuXhz_YtQo1FyKz_g8EERCOUtHYJW3xiXXD2sNkav4DXtc1AZ-gu3i93GEY_jis-cy4O6GH1tGpeZ4nKJVSR86PDIoBBZF_H0P4oJxW8cQnvXQuhCLFts7lyVvPB7kmVKa1OMU-JySPR1iSsikgxErasD7CSbVUFcgQ1ta5zV8ucSdKb-wJcos_kHcj1LwrC_ZcOPRVd1aLU-qqBrA0MQpdzUPwdSap8VNPoTP5KEvrZFDmYEqXdDHW70S-815Duo6uAP5itzyDvT7LMIj6PJsucX39uXQuIgna2GZVvnFvV5Mzn0Ahzj6YGbEc4_-R8dKbmd7l-xQQn97-pBq93VtBRpWnsE8CZtKKzUe5hgvhKVKYxiG-xYBSdR5ofDnF7aW1M1I93_h77nSfLcx1Tt19ZtokM9H-Rd655Ovi9zrvx9HHZbvCCDwce6It0IKoyJahzNaatP9RxcCbIHXKs64i1a78WRZIGcAF54tvf702cITAZp-Z3Jie9aUOtwKlb0_egIbCBZGu5dQ9h7I-nlDcj8nk-6y7h-JOYwYNUsUUW5B-3Vl6Sfu6KR25Oq7YrQo7nAbbRk8cdqfGGyXkcugF1RVkYybD-qq4oTRAbsSaOtltD9IuMouwBm4zeQ9JaL_4i-bpnX2Ag-rFmjOb9ylnE5cwozwljUHSITuoJOTbj9KtFqzMIoHoG9BkgSaLSmTnEhUeScVp3hhhJXkJTM4xvgRTDSboBXdC7C2aFpLaZZ-YqsxRUa5DuIKjdfOFccVaL0YhoSD1GrckiDXmEwZNa8il2qaWLbSFfLYPtF6gjK79kIta-EJplhWiFs4ONxbwmyZIER1m1dLRj5ALQgPqrHSTit4coflnZ--wlKrOhTRcrgiw7IXdbPboS_-3zlQi2dK5Mrxy-cJHOIRWDfvAG0Cjf77xz86tvDC7feQY_926zrWMX8xOGH2mMmzXVjcLzTD45Sw6_cUUsdLakivhUrXMSbtL_Vgg9pCsTx1oLfwtYUF0ugK5dO7BhRojQZoxBIopnkdCm8uq2vmhq94jKesQediuYNGhMGoLE3_a2Bxd48cIhzQgHb2QGvE1eDvT2P4GldVE8p17vZhFvEmv3lXu-7RAKx_PaUzPXRbJ8UUESSX7ifbfg54onDWKtJ7qJ6dkZ2mSlhuKdEe9G9rF0CG8BE1JPI2MeZC4yR6-iQNGwzWrcicyyBTqkotflfSmhEV_Rrqpg1avycEeajNafwvwEUC1yZzShi15JDuVdDGvgVH2hZVKM3pOZ1JxEuwtUbqP-BFkg5aBtbALWMImy4XQYiSkd3EEdQPa02OfyER8CLl0Qy7g6czv2zTAukCrY7PE4v6VAhtPwV6cI7At2KAheOe7VZNPThDj5qCKG3duCLJl4Rbkqyrt0s7WFzlAke5TDxhEE_9ta0JtMzjrPZkQWcACu_7KOu3wXz7cpHrv906MtZzK83YCoVHtfCjmXVjrU0ArV66WqRuUbz9OCBOsngRV=w2152-h1214',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'What is a Smart City?',
    date: '2024-01-08',
    tag1: '#SmartCity',
    tag2: '#DigitalTwin',
    tag3: '#DigitalTransformation',
    tag4: '#FutureCity',

    // <br/>
    text: [
        {
            head: 'What is a Smart City?',
            subHead: '',
            desc: `A smart city is a concept that utilizes digital twin technology to digitally model the physical elements of a city, enabling intelligent management and optimization of the city's infrastructure and services. This has emerged as part of efforts to address various problems arising from traditional cities and to build sustainable and innovative cities.<br/><br/>Smart cities aim to enhance various aspects of urban life using various technologies and information and communication technologies (ICTs). It is perceived as a new paradigm for improving the problems of existing cities and for the sustainability and innovation of future cities. Key technologies include the Internet of Things (IoT), big data, artificial intelligence (AI), and autonomous vehicles.`,
            dot: ''
        },
        {
            head: 'Key Technologies of Smart Cities',
            subHead: '',
            desc: 'Various advanced technologies are used to implement smart cities, and these technologies are integrated to achieve urban intelligence. The key technologies receiving attention include the following.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Internet of Things (IoT)',
            desc: 'One of the key elements of smart cities is Internet of Things (IoT) technology. This involves applying sensors to various objects and connecting them through the internet to enable real-time data collection and sharing. Data collected from various sectors in the city, such as buildings, transportation systems, and the environment, are integrated and analyzed centrally, allowing real-time understanding of the city\'s situation.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Big Data',
            desc: 'Data is one of the core resources of smart cities, and big data technology is essential for effectively utilizing it. By rapidly collecting, storing, and analyzing large amounts of data, smart cities can understand and predict trends and patterns in city functions. Big data analysis enables optimization of city functions and early detection of problems, providing significant assistance in decision-making and planning.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Artificial Intelligence (AI)',
            desc: 'Artificial intelligence technology, used in conjunction with big data, plays a crucial role in making smart cities intelligent. By utilizing machine learning and deep learning to analyze data and optimize city systems, AI enhances the efficiency of smart cities in various areas such as optimizing traffic flow, predicting energy consumption, and operating automated systems.',
            dot: ''
        },
        {
            head: '',
            subHead: '5G Network',
            desc: 'Smart cities require fast transmission and processing of large amounts of data. The 5th generation mobile communication technology, 5G, provides high-speed data transmission and low latency, supporting the effective operation of various technologies in smart cities. 5G provides a crucial communication foundation for technologies such as autonomous vehicles, smart infrastructure, and industrial robots.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Blockchain',
            desc: 'Ensuring the secure protection and sharing of data in smart cities is an important issue. Blockchain technology secures the integrity and security of data through a distributed database, enabling transparent and secure transactions. This allows safe sharing and utilization of data in various sectors of the smart city.',
            dot: ''
        },
        {
            video: digitaltwin,
            link: 'https://orbro.io/en/digital-twin',
            videoDesc: '(Click the video to visit the Orbro Digital Twin page.)',
            head: 'Advantages of Smart Cities',
            subHead: 'Efficient Resource Management',
            desc: 'Smart cities use big data, Internet of Things (IoT), and artificial intelligence (AI) technologies to efficiently manage resources. By collecting and analyzing data on energy, water resources, transportation, and more, they find optimal operating methods, reducing energy consumption and minimizing resource waste.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Optimization of Traffic Flow',
            desc: 'Smart cities reduce road congestion by optimizing traffic flow through real-time data collection and analysis. Smart traffic lights, autonomous vehicles, and improvements to public transportation systems increase transportation efficiency, making it convenient for citizens to move around.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Enhanced Environmental Sustainability',
            desc: 'Smart cities increase energy efficiency and actively use renewable energy to reduce the environmental burden. Real-time monitoring of air and water quality enables early response to environmental issues.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Economic Activation',
            desc: 'The implementation of smart cities promotes the creation of new technologies and service industries, generating jobs and driving economic activity. Various companies and experts from different industrial sectors participate in smart city projects, expanding the industrial ecosystem by developing innovative technologies and business models.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Enhanced Emergency Response Capability',
            desc: 'Smart cities establish effective emergency response systems to prepare for natural disasters, social emergencies, and more. Real-time data collection and analysis assist in disaster prediction and management, and citizens receive timely safety information, enhancing their coping abilities.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Strengthening City Competitiveness',
            desc: 'Smart cities enhance the international competitiveness of cities by creating a modern and innovative image. Advanced technology and infrastructure attract global companies and talents, and international investments contribute to the economic development of the city.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Sustainable Urban Development',
            desc: 'The advantages of smart cities contribute to achieving sustainable urban development. By planning and operating the city considering sustainability in environmental, economic, and social aspects, smart cities enable sustainable development for future generations.',
            dot: ''
        },
        {
            head: 'Operation Principles of Smart Cities',
            subHead: 'Utilization of Sensors and IoT',
            desc: 'The core of smart cities is the use of sensors and Internet of Things (IoT) technology. Sensors installed throughout the city collect real-time data related to the environment, traffic, energy usage, etc. This data is used to monitor various aspects of the city and detect problems early.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Sustainable Urban Development',
            desc: 'The advantages of smart cities contribute to achieving sustainable urban development. By planning and operating the city considering sustainability in environmental, economic, and social aspects, smart cities enable sustainable development for future generations.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Utilization of Artificial Intelligence and Machine Learning',
            desc: 'Artificial intelligence (AI) and machine learning technologies, used in conjunction with big data, analyze data and optimize city systems. Predictive analysis optimizes traffic flow or predicts energy consumption for efficient management. AI finds creative and intelligent solutions to complex problems in the city.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Construction of Smart Infrastructure',
            desc: 'Smart cities build smart infrastructure that connects various parts of the city. Smart grids efficiently manage power, smart transportation systems optimize traffic flow, and smart buildings minimize energy usage. This smart infrastructure integrates various aspects of the city, leading to sustainable development.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Interconnected Devices through IoT',
            desc: 'IoT establishes a smart city by connecting interconnected devices. Smart appliances, vehicles, buildings, etc., exchange information, efficiently utilizing city resources and increasing convenience.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Citizen Participation and Digital Service Provision',
            desc: 'Citizens actively participate in smart cities through digital services. Through mobile applications, citizens access city information, report issues, and engage in activities, directly participating in the operation of the city.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Intelligent City Operations System',
            desc: 'The integrated intelligent city operations system, where all data and devices are interconnected, efficiently manages various parts of the city and provides optimal services. Automatic operations, such as optimizing traffic flow, managing energy efficiency, and monitoring the environment, intelligently operate the city.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Continuous Improvement and Evaluation',
            desc: 'Smart cities progress through continuous improvement and evaluation. By accepting feedback from citizens and introducing technological innovations, smart cities adapt to the city\'s demands, maintaining and advancing a sustainable smart city.',
            dot: ''
        },
        {
            head: 'Future Outlook of Smart Cities',
            subHead: '',
            desc: 'Smart cities are shaping the framework of future cities through sustainable development and innovative technological adoption. Emphasizing energy efficiency and environmental protection, increased use of renewable energy and eco-friendly transportation systems is expected. Additionally, innovative technologies such as big data, artificial intelligence, and the Internet of Things will be actively introduced, intelligently managing various aspects of the city and increasing convenience for citizens. Smart cities are anticipated to strengthen the city\'s competitiveness through continuous development and technological innovation.',
            dot: ''
        },
        {
            linkText: 'Experience Orbro World',
            link: 'https://home.orbro.io',
            head: '',
            subHead: '',
            desc: '',
            dot: '',
        },

    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'A product that helps real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/en/enterprise/construction/safety'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/en/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/en/enterprise/manufacturing/asset'
            }
        ]

    }
}
