import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| 스마트 물류 시설에서 작업자 안전을 관리하는 방법',
  desc : 'RTLS 기술로 실시간 작업자 위치를 파악하여, 안전 사고를 예방하고 물류 시설의 생산성과 효율성을 높일 수 있습니다.',
  keywords : '물류 작업자 안전, 물류 작업자, 작업자 안전관리, 물류센터 관리, 스마트 물류센터, 물류시설 안전, 실시간 위치 추적, rtls, uwb, orbro, 오브로, 콩테크, 대형 물류센터',

  ogTitle : '',
  ogDesc : '',
  ogSite : '스마트 물류 시설에서 작업자 안전을 관리하는 방법 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/logisticsWorker',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4NdDe6oJsqBton9AjaPbL4Q5NxAVY66XFiGJrNW6Ib8-vKVwi61wEFWFuSoe0IzzjXlQbOtwFN55XZKQq8-merMyuu_Kc-aCU9rXsAxqlu4jJu1W48RqVrOHIVfqqqsE6lyVOnqs0-udnyCNaiR_P-55-JDEg1q7Xen3jEtC_J7Vb-agtwtcKFwspoLjunKXVBr9DX9XtbURNHamoE8axj_QTsyRGYEjihiSkLTZsKjMAFQ7XEjKC5gypKGi-6h70LVzMWxFYES1IEFrj_g1Xm7X_87ZICuq9UkXYfF5_kSsePap3XCgzaGjXTxtewamebrNTcvtyyOGiDJzCop_9ps9SiDxBVMH5GHlXgzqGmO97RWoXrAzVM73ecqHfZvofOY6_mcID1tD3eGIZBqVBgd7U8JQKxRv5VzLnj2nylAh5JYmN5uKSjOqTtNmcH5ryzlmyojOD2ErsaCkaHV5ExP87eOjZKQy4uKs_zWCdrgvAmWTPvzw69EDOlKv2RoG16hSWS_Ak_ejmhNKHqiapfSKTGHaC-OyuAl909u40AIfMQLdhaDAfq0dLJve-7i1wj3R4CLlyviq_bE_NXXUK5qw3DbmY5cmW6vI1h_bt2hkpZNfi3JDz5QhJ9DAcFdAWrfv2leATnS45LJW4WOW1oXUJDbzkHUZQHW0ZW6wIGYVQ-xlZWJJqhrD_Njlp8FUHTGIx0P4nbQ9gwzfH5XHMncEv6nMznbvF1pSn3RUd018qa27lJlV03sBikmB34XLF4CDCNEO1bQw9jWtbwk_vYV4M8X13pC0IyanCVU2du9J_GkiVXzFXfbzpyqQ9WOMjzjr1fz7PFZ9K4m9oA5JTQT4J3Zdqjx740QlfdBPLL-2yRp1LOhhELK6pOVdTjGXtjX3wv47zRDndoJU1_JRmZ4jZVB7-7HzJYviS1og154IRi0RIUkjfgjfUAA8LCVjWDHS32tJzSqsZXqKTXYRk9Rhi_YcjuhZLNetMYWCuiyoFBsqFmv6ai_Ux9jN66QNYaXnxRFnLddvNA6yApVZwoKPn-IzhYO8Vqv-0kVcG5eU-71tpilnn85vP3jJ6t79hkLY3n-fxyWgmRvnYI29nFIXJVmMfS6WNeeoqxKWJXUKMhbTVHH2pLy3fBnuVsWrrFjp1exyh-ahC1VHmDZbVVT2OhrELUuXeed4tfK3fgFsq066oX6o3XaihsVsvxd_7ohiRB6yCLuR0wSKZfvVqT9HQbGXWjOB6TZtXQpmsDzt_Hm4jCIyzgGJhQL-iMQZzqxalaYhQYVocsX1i6DSrWQD54jIik7h_PJfgK-eulk6EBtGZS3Tzf0Iaureqspuc_I8f4hcXRLWO2U5NnbnPZUmwxp-__Br0RSKSdBAv39o-tNXhLUk0-WF5nNiPAK4XlxF4hKVQl6nVCf5_wzw5t95Acv8QlXvmGuwe9etTZWlibVBH-aqe5_XOETlbldEKqJj_JN2mk1bGd6U2D5jYxlz5mexjaGdd6aWxF48u6hG_15sIUgXGqEHPhXvDJk6bTv_k7wgvoN1ESF_WKxkZuAnBZWa3G7jWW4VT6NK9Q3RxYq3VXTACR2nFRVJQ=w2052-h1199',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '스마트 물류 시설에서 작업자 안전을 관리하는 방법',
  date : '2023-09-06',
  tag1 : '#물류시설안전',
  tag2 : '#대형물류센터',
  tag3 : '#물류센터작업자',
  tag4 : '#작업자안전관리',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'물류 시설에서 작업자의 안전문제는 물류 산업에서 매우 중요한 요소 중 하나입니다. 물품의 이동, 저장, 출하 등과 같이 다양한 작업을 하는 와중에도 안전사고의 위험들이 있으며, 작은 사고라도 그 즉시 안전, 생산성, 효율성에 악영향을 끼치게 됩니다. 그러나 실시간 위치 추적 시스템, RTLS(Real-Time Location System)를 활용한다면 작업자의 안전을 보장할 수 있습니다. 아래에서는 어떤 방식으로 작업자의 안전을 관리하고 스마트한 물류 시설을 운영할 수 있는지 알아보겠습니다.',
      dot : ''
      
    },
    {
      head : 'RTLS 기술이란?',
      subHead :'',
      desc : '우선 RTLS, 실시간 위치 추적 시스템은 실시간으로 위치 정보를 추적하는 기술로, GPS, RFID(Radio-Frequency Identification), UWB(Ultra-Wideband), BLE(Bluetooth Low Energy) 등의 다양한 기술을 활용합니다. 이를 통해 물류 시설 내 작업자의 위치를 정확하게 파악할 수 있으며, 이 위치 정보를 활용하여 안전한 작업 환경을 조성할 수 있습니다.',
      dot : ''
      
    },
    {
      head : '주요 이점',
      subHead :'1. 작업자 관리',
      desc : 'RTLS 기술을 통해 작업자의 위치를 실시간으로 추적할 수 있습니다. 작업자가 물류 시설에서 어디에 있는지를 정확하게 파악함으로써, 작업자 간의 충돌이나 중장비와의 충돌을 예방하고 긴급 상황이 발생할 경우 빠르게 대응할 수 있도록 합니다. 이는 안전뿐만 아니라, 작업 현장에서 작업자들을 효율적으로 배치하고 효율성을 높이는 데에 큰 도움이 될 수 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'2. 안전 영역 설정',
      desc : '물류 시설 내에서는 작업자가 접근해서는 안 되는 위험 지역이 있을 수 있습니다. RTLS를 활용하여 안전 영역을 설정하고, 외부인이나 허가되지 않은 작업자가 해당 영역에 접근하는 경우, 위험 알람을 즉시 알릴 수도 있습니다. 반대로 특정 작업자들이 이 영역을 벗어나면 경고를 발생시키는 등의 조치를 취할 수 있습니다. 이를 통해 사고를 사전에 예방할 수 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'3. 긴급 상황 대응',
      desc : '작업자가 긴급 상황에 처했을 때, RTLS 기술을 통해 위치 정보를 빠르게 파악할 수 있습니다. 이를 통해 구조 대응이나 응급 처치를 신속하게 진행할 수 있으며, 작업자의 안전을 보장할 수 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'4. 효율성과 생산성',
      desc : 'RTLS를 통해 작업자의 동선을 파악하는 것은, 작업 프로세스를 최적화시킬 수 있습니다. 만약 작업자의 동선뿐만 아니라, 자산추적 솔루션과 결합한다면, 굉장히 진보된 물류 시설을 운영할 수 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'결론',
      desc : '물류 시설에서 작업자의 안전은 물류 산업에서 큰 중요성을 가지며, 이를 관리하고 향상시키기 위해 RTLS 기술을 활용하는 것은 매우 효과적입니다. 작업자의 위치를 실시간으로 추적하고 안전한 작업 환경을 조성함으로써, 작업자의 안전성을 높이고 사고 예방에 기여할 수 있습니다. RTLS 기술의 활용은 물류 시설 운영에 있어서 더 나은 미래를 열어갈 것입니다.',
      dot : ''
      
    },

  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
        {
          text: productPurchase.purchase.worker.workerTracking.title,
          title: productPurchase.purchase.worker.workerTracking.title,
          serial: productPurchase.purchase.worker.workerTracking.serial,
          desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img: productImg.product.workerTracking.thumbnail
        },
      {

      }

    ],
    solution : [
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      },
      {
        img : logisticsSafety.thumb,
        data : 'logisticsSafety',
        link : '/enterprise/logistics/safety'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/enterprise/manufacturing/asset'
      }
    ]

  }


}