import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconPenSquare, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/officeAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/officeAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/officeAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/officeAsset/video/sub/rtlsVideo3.mp4";


export default {
    banner: {
      title: '資産管理',
      desc: '不適切な資産管理は時間と財務の損失を引き起こします。明確で迅速な資産管理により、スムーズなビジネス運営が可能となります。',
    },
    video: {
      title: 'より正確かつ安全な資産管理方法',
      desc: 'リアルタイム位置測位(RTLS)技術は、すべてをリアルタイムで測位します。<br/>オフィス内のすべての資産を最適な状態で管理し、最も効率的な運用が可能となります。',
      play: video,
    },
    func: [
      {
        title: '資産位置測位',
        desc: 'オフィス用品を含む資産のリアルタイムの位置を測位し、現在の位置を把握します。ムダな移動経路や時間、費用を削減し、オフィスの効率を向上させます。',
        sub1: 'リアルタイム位置測位',
        icon1: IconBinocularsGray,
        sub2: '高い精度',
        icon2: IconRulerGray,
        video: subVideo1,
      },
      {
        title: '在庫管理',
        desc: '正確な在庫数量の把握は基本です。また、位置をリアルタイムで把握することで、在庫が現在どの位置にあるかを把握できます。',
        sub1: 'リアルタイム状況のモニタリング',
        icon1: IconClockGray,
        sub2: '高速なデータ統合',
        icon2: IconShareGray,
        video: subVideo2,
      },
      {
        title: '資産の出庫通知',
        desc: 'オフィス内のアイテムの移動を正確に測位することは不可欠です。ただし、このタスクに過剰なリソースや費用を割り当てるのは非効率です。自動化により、効率的なオフィス環境を提供します。',
        sub1: '統計データの提供',
        icon1: IconChartBarGray,
        sub2: 'クラウドデータストレージ',
        icon2: IconCloudCheckGray,
        video: subVideo3,
      },
    ],
    subfunc: {
      title: 'さまざまな機能',
      desc: '効率的な在庫管理、時間とコストの節約、透明性と測位性など、さまざまな利点があります。<br/>ORBROでは、最適な作業環境を作り出し、最大の効率を実現するために、次の機能を追加で提供しています。',
      data: [
        {
          icon: IconChartRadius,
          title: 'レポートおよび分析',
          desc: '資産の使用履歴に基づいてレポートおよび分析データを生成できます。',
        },
        {
          icon: IconBell,
          title: 'リアルタイムアラート機能',
          desc: '特定の資産に異常な状況が発生した場合、アラートを受け取り、即座に対応できます。',
        },
        {
          icon: IconRoute,
          title: '資産の移動経路測位',
          desc: 'オフィス内で特定の資産が移動した経路を測位し、データ化できます。',
        },
        {
          icon: IconDashboard,
          title: '状況のモニタリング',
          desc: '特定の資産の現在の位置を把握します。',
        },
        {
          icon: IconWarning,
          title: '資産の逸脱検知',
          desc: '事前に定義されたエリアから逸脱する資産がある場合、リアルタイムでアラートを受け取ります。',
        },
        {
          icon: IconPenSquare,
          title: '数量管理',
          desc: '資産の残りの数量を監視し、一定の閾値を超えるか不足すると通知を受け取ります。',
        },
        {
          icon: IconChainLink,
          title: 'データ統合',
          desc: 'RFID、IoT、セキュリティ、ビデオなどのシステムと連携し、効率的に活用できます。',
        },
        {
          icon: IconSecurity,
          title: 'データのセキュリティ',
          desc: '高いレベルのデータセキュリティを維持し、信頼性の高いソリューションを提供します。',
        },
      ],
    },
    system: {
      title: 'システム構成',
      img: rtlsSystem,
    },
    productTitle: '製品構成',
    product: [
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'logisticsAsset',
      },
      {
        data: 'manufacturingAsset',
      },
      {
        data: 'blogOffice',
      },
      {
        data: 'blogRTLS',
      },
    ],
  }
  