import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";



export const BannerSpacing = styled.div`
  margin-top : 128px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    margin-top : 96px;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    margin-top : 64px;
  }

`;


export const BannerLayout = styled.div`
  height : fit-content;
  max-height : 960px;

  width : 100%;
  aspect-ratio : auto 1920 / 1008 ;


  
  @media (max-width : 1180px) {
    width : 100%; 
    height : auto;
    max-height : 984px;
    aspect-ratio : auto 960 / 800 ;
  }
  

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    max-height: 800px;
    aspect-ratio : 960 / 800 ;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    aspect-ratio : 393 / 480 ;
  }
`;


export const BannerSection = styled.div`
  width: 100%; 
  max-height: 960px;

  overflow-x: hidden;
  overflow-y: hidden;
  z-index : 0;
  position : fixed;
  display : flex;
  flex-direction : column;
  align-items : center;


  
  @media (max-width : 1180px) {
    width : 100%;
    max-height : 984px;
    aspect-ratio : auto 960 / 800 ;
  }

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    max-height: 800px;
    aspect-ratio : 960 / 800 ;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    aspect-ratio : 393 / 480 ;
    position : relative;
  }
`




export const BannerBtn = styled.button`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: var(--primitive-blue-600, #2A70F0);
  cursor : pointer;
  border : none;
`;

export const BannerBtnAtag = styled.a`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: var(--primitive-blue-600, #2A70F0);
  cursor : pointer;
`;


const moveFromRightToLeft = keyframes`
  from {
    background-position: 95% 5%; 
  }
  to {
    background-position: 15% 5%;
  }
`;


export const BannerImageSection = styled.div<{ img: string}>`

  position : absolute;
  z-index : 1;

  background-repeat: no-repeat;
 
  ${({ img }) => img && `background-image : url(${img});`}

  animation: ${moveFromRightToLeft} 70s ease-in-out infinite; /* 애니메이션 속성 추가 */

  width : 100%;
  aspect-ratio : auto 1920 / 1008 ;

  
  
  @media (max-width : 1180px) {
    width : 100%;
    aspect-ratio : 960 / 800 ;
  }


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    max-height: 800px;
    aspect-ratio : 960 / 800 ;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
    aspect-ratio : 393 / 480 ;
    animation : none;
    background-position : 7% 25%;

  }
`;

export const BannerVideoSection = styled.div<{scrollPosition : number }>`
  width : fit-content;
  display : flex;
  max-height : 480px;
  margin-top : auto;
  z-index : 4;

  display : flex;
  object-fit : contain;

  transform: translateY(1px); 
  
  aspect-ratio : 3840 / 1080;

  ${({ scrollPosition }) =>
  scrollPosition &&
  `transform: translateY(-${scrollPosition/1.2}px) scale(${100 - scrollPosition / 20}%); opacity : ${1-scrollPosition/500};`}

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    max-height : 400px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    max-height : 300px;
    transform : none;
    opacity : 1;
  }
`;



export const VideoBox = styled.div`

  position: relative;
  width: fit-content;
  display : flex;
  justify-content : center;
  overflow: hidden;
  
  border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
  
  clip-path: polygon(14% 0, 86% 0, 100% 100%, 0% 100%);
  border-top-left-radius: 16px; /* 상단 좌측 코너에 둥근 모서리 추가 */
  border-top-right-radius: 16px; /* 상단 우측 코너에 둥근 모서리 추가 */

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)); /* 그라데이션 추가 */
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    z-index: 4;
    transform: translateY(50%);
  }

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
  }
`


export const ImageInner = styled.div<{scrollPosition:number}>`
  display : flex; 
  z-index : 3;
  margin-bottom : 48px;

  padding : 0 24px;

  ${({ scrollPosition }) =>
  scrollPosition &&
  `transform: translateY(-${scrollPosition/4}px) scale(${100 - scrollPosition / 20}%); opacity : ${1-scrollPosition/300};`}


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-bottom : 0px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-bottom : 16px;
    transform : none;
    opacity : 1;
`;

