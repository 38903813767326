
import { IconOsOff, IconOsOn } from '@assets/images/main/icon';
import { industryOn, industryOff, tagOn, tagOff, assetTagOn, assetTagOff, assetOn, assetOff } from '@assets/images/main/index';


export default {



    bannerData: [
        {
            icon:assetOn,
            iconInv:assetOff,
            image:'',
            iconText: 'Bluetooth(BLE)<br/>Tracking',
            sub: 'New',
            link : '/en/beacon/ble'
        },
        {
            icon: industryOn,
            iconInv: industryOff,
            image: '',
            iconText: 'Industrial Beacon',
            sub: '',
            link: '/en/beacon/industry'
        },
        {
            icon: tagOn,
            iconInv: tagOff,
            image: '',
            iconText: 'Small<br/>Beacon',
            sub: '',
            link: '/en/beacon/tag'
        },
        {
            icon: assetTagOn,
            iconInv: assetTagOff,
            image: '',
            iconText: 'Asset<br/>Tag',
            sub: 'New',
            link: '/en/beacon/asset-tag'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/en/os'
        },



    ],





}