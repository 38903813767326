import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet: '',
  desc: 'ORBRO provides essential climate analysis to understand and adapt to the impact of climate change through weather monitoring and advanced simulation. It offers extensive information through night and day weather conditions, climate and precipitation, marine weather data, global weather information, atmospheric pollution monitoring, disaster prediction, and warning systems. This data is crucial for climate research, agricultural planning, and water resource management. Weather satellite data, sunlight duration, and sun position information simulate real-time day and night weather conditions, while high-resolution weather models and precipitation data simulate climate conditions. Rainfall in the ocean, global weather information integration, atmospheric pollution monitoring, disaster prediction, and warning systems are vital for oceanography, climate research, public health, and environmental protection.',
  keywords: 'Digital Twin, Digital Twins, 디지털 트윈, gis, 디지털 시티, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - Implementation of Atmosphere and Weather Information',
  ogUrl: 'https://orbro.io/en/blog/digitaltwin-weather',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',
  
  title: 'Digital Twin - Implementation of Atmosphere and Weather Information',
  date: '2023-12-26',
  tag1: '#DigitalTwin',
  tag2: '#WeatherInformation',
  tag3: '#DigitalCity',
  tag4: '#VirtualReality',
  
  // <br/>
  text: [
    {
      head: 'Digital Twin - Implementation of Atmosphere and Weather Information',
      subHead: '',
      desc: 'ORBRO utilizes digital twin technology to implement and support understanding and adaptation to climate change by visualizing atmospheric and meteorological information. It provides climate analysis through weather monitoring and advanced simulations, offering valuable insights across various fields.<br/><br/>Weather conditions during day and night, climate environments, precipitation, marine weather data, global meteorological information, atmospheric pollution monitoring, disaster prediction, and warning systems provide essential information for climate research, agricultural planning, water resource management, and more.<br/><br/>Real-time simulations of daytime and nighttime weather environments are conducted using weather satellite data, sunshine duration, and solar position information. Climate conditions are simulated using high-resolution weather models and precipitation data. Rainfall in the ocean, global meteorological information integration, atmospheric pollution monitoring, and disaster prediction and warning systems play crucial roles in oceanography, climate research, public health, and environmental protection.',
      dot: ''
      
    },
    {
      youtube: 'https://www.youtube.com/embed/QB2sM1OdrXA?si=X3po5Cxz2Hu3QlGA',
      head: '',
      subHead: 'Representation of Night and Day Weather Conditions',
      desc: 'ORBRO simulates real-time day and night weather conditions using weather satellite data, sunlight duration, and sun position information. This provides crucial data for climate research, disaster management, agricultural planning, and energy management.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Representation of Climate and Precipitation',
      desc: 'Simulates various climate conditions in real-time using high-resolution weather models and precipitation data. This provides essential data for climate research, agricultural planning, and water resource management.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Representation of Rainfall in the Ocean',
      desc: 'Visualizes rainfall phenomena in marine areas using satellite-based marine weather data and ocean models. This information provides crucial data for oceanography, climate research, marine transportation, and safety management.',
    },
    {
      head: '',
      subHead: 'Global Weather Information Integration',
      desc: 'Collects and analyzes extensive weather information by integrating global weather observation stations and satellite data. This plays an essential role in monitoring global climate change and international disaster response.',
    },
    {
      head: '',
      subHead: 'Atmospheric Pollution Monitoring',
      desc: 'ORBRO tracks and predicts air quality and pollution levels, supporting public health and environmental protection policies. This provides crucial information for improving air quality and preventing pollution.',
    },
    {
      head: '',
      subHead: 'Disaster Prediction and Warning Systems',
      desc: 'Utilizes weather data to predict natural disasters and develop related warning systems. These systems enhance disaster preparedness and response, enabling swift responses to natural disasters.',
    },

  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that assists real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/en/enterprise/construction/asset'
      }, 
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      }
    ]


  }

}
