import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
    helmet: '',
    desc: '您可以利用超宽带（UWB）技术构建地理围栏（geofence）系统。它能够实时监控安全情况并有效地管理设施。',
    keywords: 'uwb, 地理围栏, 定位, 安全区域, 安全设施, 军事安全, 军事设施安全, 限制区域, 禁止进入区域, 智能安全, 安全系统',

    ogTitle: '',
    ogDesc: '',
    ogSite: '利用超宽带（UWB）技术创新地理围栏系统 :: Orbro博客',
    ogUrl: 'https://orbro.io/cn/blog/uwbGeofence',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4MxDPsSDiu2Tyl6nzt6wk9OfJpf_lWTcfXeVRyvIzqQCB1pSV0Spq8jW3WvcY7Nw02jSYqstCQAImKsHtFRK-1bPx1X2mx0YaBIiayQ1ohB3l7WHUYrrwPX0KVYOmKZg-lo7OXiR53dsL16RqpSTSGdQaV46-p40ThUaFoYxY-hUVgD4l18QurSDnaKVaSL_qZ6ApBj7LSbjfbjVaUNEOXWI3bT5GNmDdlLfWBv8id417JIjgIeDW3iI_kkzevdCiKHN2ORcK4RYGPGFVeAOL8wlco80gszm93LArYd2nOvTK38arZmeYvJ0Ex0nqkjaxulQ7gJ5-a5bSBSfAaKAkqxv24246EQdlkBh9N5COrSoTqrAlmVNNf9PxF2fbxMevrZt-G9NMRIaxYkWT42AfcpxuTL1DikOcn2_q8jFMWhrvSwNYC2No2mZOgbqYoDKytKXp2xOcRM7RKZBhIZhDvfS59FVqMUMiO5aD2j2QizWcy5BI53-fnun3DZdVQTijx4yq1Gg_tQvY36Lu803m17BZ6Fia8uhoZVOdcuNpFsiAttmTR2X_wkCdeti3lj3ovDVjGE7kvZr1CdpkFft3MtDSoL2fhdU6YubXi_uuNfS_zCAwv6er3VjTfUTvPgtciMHUbAv5Ci63iew9qiOj3Fkr0ueSGZk_iqSYP-He2PUYp7TplTlsPd3CmzeGIf03yFNX1FK4RUVfIo55fXbW0n7cuga9vSRYuRF5dsHCTLILV1qr_nJGd-U4u-vO2jRh53ZHXTDgA8dZ_c4XUneNWJ0_JDYNOTmzrgJJsCDvH8-J28NyJVw-UBpgUGYZRMnhoPclDHRXAMcy5YvwsLVCLajck2ewTifU3gOr8SHNlFP_wOpK7_L5WgP7GHPYE04bZbHZ-bjCxcDM8E3wQYn32oUrEammRi0mbSb7xPPr9azb_14nmPjd1d-Ppay-29QzX8A1B5GRo4emyzqI0NMMPmDryb4QTIsKI9fYTbZsQ3fUoPH3HnNRZtpmVc-Ht1Gld7epvFpcrD199Ery-c1XIEEvw9PSDjteUY1-RMoyDutgDc06huKQxZv_wx-vfxmu-GnVz6kDHYQtEKULMtqxhF-8f65nC-p8nnbYD59CkksLRWPzzKmq7c51Sq1UDLuOqDPlnpema8t7VSg2iWUNi7pqgxfwnt1BSPeRt8XrPv8dmTmudreTbSR1g-7x4fAzf1PxU2QyGOmbVEq_5L4HNxOmJQzGnEYOxtPcTWL1G-vYS58iH6octXtcqiXoFymXxnt5sHnpCaYK1cpWTWUCjEMjJi8r2YOEPxBfzHv4VUeME1bB91xu2jsZhQC09SylsHJZZcLxW_jG4v2EzHsKI87CaHQCimSgcaP_8Iy3GPUVQdbOnRXT8XSow67w2sItu6ujbKZCr1aT7l54ree-kMoWfM3urhWArs2mQE2_oo0_eSh__SdYlxYh56m64zFkguxTpaecjUFam-ty20MdiuW5LkVjoNPR4A_9HsbmFYn_lQ8Pqc6jtL3FDml1vyeTYAYYv2XMQxoZvyGZBYbTU=w2000-h1736',
    ogType: '网站',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '利用超宽带（UWB）技术创新地理围栏系统',
    date: '2023-09-01',
    tag1: '#uwb',
    tag2: '#geofence',
    tag3: '#地理围栏',
    tag4: '#安全区域',

    text: [
        {
            head: '',
            subHead: '什么是超宽带（UWB）技术？',
            desc: '超宽带（UWB）技术是一种利用非常宽广的频率带宽来传输数据或精确测量物体位置的无线通信技术。 UWB使用极短脉冲在短时间内生成高分辨率信号。这种高分辨率信号使室内外位置跟踪和距离测量变得精确，并且即使在低功率下也能提供高数据传输速率和位置测量的精度，适用于各种领域。',
            dot: ''
        },
        {
            head: '',
            subHead: '什么是地理围栏（Geofencing）？',
            desc: '地理围栏是一种将基于位置的服务和无线技术结合在一起，以在特定地理区域内发生事件时发送警报或执行特定操作的技术。简而言之，就是设置虚拟围栏，当进入或离开该区域时采取特定动作。地理围栏可以与GPS、Wi-Fi、蓝牙和UWB等定位跟踪技术结合使用。这使得可以基于实时位置信息精确设置地理围栏区域，并在用户或设备进入或离开特定区域时发送通知或执行操作。',
            dot: ''
        },
        {
            head: '',
            subHead: '利用超宽带（UWB）技术创新地理围栏系统',
            desc: '近年来，技术创新迅速改变了我们的生活。其中，利用超宽带（UWB）技术创建创新的地理围栏系统引起了广泛关注。该技术实现了以前难以实现的精确和快速的位置跟踪和距离测量。让我们探讨一下UWB技术如何在地理围栏领域中得到应用，并带来了哪些创新。',
            dot: ''
        },
        {
            head: '',
            subHead: '1. 高精度位置跟踪',
            desc: 'UWB技术提供了高精度的位置跟踪能力。通过这项技术，地理围栏系统可以实时跟踪物体或个体的位置。例如，在有限制区域的设施中，可以实时跟踪进入这些区域的人的位置，并自动发送警报。在军事应用中，该技术不仅可以精确监控和控制敌人的移动，还可以更有效地管理市民领域的设施和物品位置。',
            dot: ''
        },
        {
            head: '',
            subHead: '2. 可视化和监控',
            desc: '利用UWB技术的地理围栏系统可以以高精度显示地理信息，并进行监控。管理员可以实时监视区域内的动态和活动，以便对异常行为或未经授权的侵入做出即时响应。',
            dot: ''
        },
        {
            head: '',
            subHead: '3. 增强的安全和安全性',
            desc: 'UWB技术提供了高度的安全性。基于雷达的位置跟踪与GPS信号相比更难被探测，因此地理围栏系统可以在避免外部黑客或干扰的情况下保持安全。这在军事设施或机密信息保护等领域特别有优势。',
            dot: ''
        },
        {
            head: '',
            subHead: '4. 多功能性',
            desc: 'UWB技术具有多功能性，适用于各种领域。它不仅适用于位置跟踪，还可以与各种传感功能集成，用于环境监测、灾害响应、自动化系统等，具有潜在应用潜力。',
            dot: ''
        },
        {
            head: '',
            subHead: '5. 未来展望',
            desc: 'UWB技术将继续发展，并预计将提供更精细的传感和位置跟踪能力。这将使得地理围栏系统更强大，更有效。此外，UWB技术相对于传统方法提供更高效、更准确的数据，因此在决策方面也将提供更高的可靠性。',
            dot: ''
        },
        {
            head: '',
            subHead: '结论',
            desc: '利用超宽带（UWB）技术创新的地理围栏系统通过精确的位置跟踪和监控能力实现更有效的防御和管理。这种技术创新对市民、军事和安全等领域都产生了积极影响，未来可以期待使用进一步发展的UWB技术打造更强大的地理围栏系统。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: '这款产品通过将UWB设备安装在工人或设备上，实时协助进行位置追踪。',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/cn/enterprise/medical/patient'
            },
            {
                img: medicalWaste.thumb,
                data: 'medicalWaste',
                link: '/cn/enterprise/medical/waste'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/cn/enterprise/office/asset'
            }
        ]
    }
}
