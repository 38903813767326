import { StyledGridContainer } from "@components/Grid/Grid.style";
import React, { PropsWithChildren } from "react";
import { Trans } from "react-i18next";
import { UseCaseCard, UseCaseCardSection, UseCaseIcon, UseCaseLayout } from "./UseCase.style";
import { Spacing } from "@pages/CommonStyle/common.style";
import { HeadingH3, StyledHeading } from "@components/Text/Heading/Heading.style";
import { StyledParagraph } from "@components/Text";
import { DisplayH2color } from "@components/Text/HeadingTagDisplay.style";


interface ModalDefaultType {
  pageName: string
  path: any
}

const UseCase: React.FC<PropsWithChildren<ModalDefaultType>> = ({ pageName, path }) => {

  return (
    <UseCaseLayout>
      <StyledGridContainer>
        <DisplayH2color size="small" color={"#000"}><Trans i18nKey={`${pageName}.card.title`} /></DisplayH2color>
        <Spacing pixel="16"/>
        <StyledParagraph size="medium">
          <p>
            <Trans i18nKey={`${pageName}.card.desc`} />
          </p>
        </StyledParagraph>

        <Spacing pixel="64" />

        <UseCaseCardSection>
          {
            path.card.cards.map((data, i) => (
              <>
                <UseCaseCard>
                  <UseCaseIcon src={data.icon} alt='Use Case Icon' />
                  <Spacing pixel="16" />
                  <HeadingH3 size={"small"}>
                    <Trans i18nKey={`${pageName}.card.cards.${i}.title`} />
                  </HeadingH3>
                  <Spacing pixel="32" />
                  <StyledHeading size="xxxsmall">
                    <p>
                      <Trans i18nKey={`${pageName}.card.cards.${i}.desc.${0}.title`} />
                    </p>
                  </StyledHeading>
                  <Spacing pixel="4" />
                  <StyledParagraph size="small">
                    <p>
                      <Trans i18nKey={`${pageName}.card.cards.${i}.desc.${0}.desc`} />
                    </p>
                  </StyledParagraph>
                  <Spacing pixel="24" />
                  <StyledHeading size="xxxsmall">
                    <p>
                      <Trans i18nKey={`${pageName}.card.cards.${i}.desc.${1}.title`} />
                    </p>
                  </StyledHeading>
                  <Spacing pixel="4" />
                  <StyledParagraph size="small">
                    <p>
                      <Trans i18nKey={`${pageName}.card.cards.${i}.desc.${1}.desc`} />
                    </p>
                  </StyledParagraph>
                </UseCaseCard>
              </>
            ))
          }

        </UseCaseCardSection>


      </StyledGridContainer>

    </UseCaseLayout>
  )
};

export default UseCase;