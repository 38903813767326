import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: `In the healthcare industry, RTLS enables efficient monitoring of patients by medical staff through real-time tracking of patient and medical equipment locations, and efficient management of equipment and assets within medical facilities.`,
  keywords: 'RTLS, indoor positioning, indoor navigation, location tracking solution, real-time location tracking, asset tracking, smart office, GPS, indoor positioning solution',
  
  ogTitle: '',
  ogDesc: '',
  ogSite: 'RTLS in the Healthcare Industry :: Orbro Blog',
  ogUrl: 'https://orbro.io/en/blog/medical',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZJaHlD_-JbEbsseg3VyR_yeMr1aCExLMWJX8T8GLTArDRbIStI6J1-0hax0bG6__oOWgnHyGPyvarcGsI-N_BiKN4cYDhs_2bM3hfycDf5l2N2df2hsBc0jlAcY_dysEr70kglQvb_fo8sUaG0ru2JfDJeZrPF1XghQ_RRBkDJ875-CCv9sf1V7SL2EOv-Zr3zfuUc1K9zWPyudhy3aV8sFP-CDO_Qqs7eKhqpYGZkwen7a5j5SWZ2uDUyfeILxjUiinPVpzoKRFiaCam-AsJ7r0faFjUPz2abau-VuB1gD0U6O4cMGZaiu341Tl8PyFCJZzL-sycERRlTXmvO3wqGjoUICFGSMkpkPJSlrbS4iCx6f7-_DAOOHNpbz-RXyE0tUVk1WR3rWbn4iR_bZJGICZ7--JH4t4OcYVCbvdUVgJnPN9j_9UuKRrCK0D5cz0QERE9Er6fTw0uTL7GdSOJozzq9i47R3TYtPO2hncXh4cYmM8UmIXwWgC4FGSP5kiyKNDziO3A_aZEg2sRvVSjzigCb73mkjswlIaJfO4LWJ258zbFwf7ft9F58M5Kfq2KHjAIV8o1svKZ_1llrr1owK8S7Fm-XHJMfU5Cuo2_n5agb6t-HF1N8DvZKyT0ylqZwBz2y4zmnt0my0cXuy_NrsnxEKPg_CVIITQoNOROTq4rEfnPyXFHsDF6Gh7-wmgOBRgbUR9TtVI7P9lP0LBU0puSoStWWW4DTJkuXmVDz4sM5j6yOGUzpq03SDL-QhpyZpq1U4wI1UfmhzOYlzYLcwJpl0u6jf44FDboZF9Iz4V9tVW86AwrI5ov_Rm_iImhjZgpIVtofL67UnvaJsSxCT_2PIZVD6X-3X2YcQ___BetQ_lTyYWhywPoI5WLoBJjEPyUwq_rFOTbCKJcFhgV-i49op1zkeCdPZtah3rOgCSo95wbPoAwaPv3d5VhDlDAfSdCLNzr6VBos3Ym9La7xU9GNLYIHwb--0ALdXL4GcV-nEhqrhBs46_fI6e3QMKwYPZHHUyniul-H7E7-Rbio-WbQC-J8SHO61CD-qiZWTZS_6jcbLTeKC20s6APzZmv91lV5viIWzkRQy_MfPgob9ft0G8Rinq1ywzZ5FGWlXeqbqqLDImJHcv5sbMbOSpdAftb4VMO7y8WHbQmKip38OP8tzNobikPBk1nibKhAgHp_ppJHlCxiW7EqBkXRY4D4dDcj-hwLusPNlh4LNdrxge7Z36lSWGIwzkpAaKiMAcssNMhsS9z4iS98Mg87bdJQT4x1tdmIocIxu6voXK2foTf-jVxbfCzhbDoD8brxsCj7nNytwO1wfQXwq2NC8FFG8Fma_SYpSTAfBOEFzt_SbKRO8usqLbqfCHIu5hs3tyUAaGOkFUUbhf8h2VGlgJKyEEwN9MKct5KqEVU9tPm0i5COlqkbDRj5PQy3pBKG9Ryuxe0C8lWW7cNyf7ARG6xGAvhoyXYjwV-gy-hSJpzs2ZxtJWB5KuYwjiHBbTNGpGFkqI89Z2AS379KYEV19-8MdLEab5Pgk2ml3sc0EFAq4pU-9Hbf9E6n2Ku3wAYJiw=w2059-h1216',
  ogType: 'website',
  
  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',
  
  title: 'RTLS in the Healthcare Industry',
  date: '2023-05-04',
  tag1: '#RTLS',
  tag2: '#Healthcare',
  tag3: '#AssetManagement',
  tag4: '#AssetTracking',
  
  // <br/>
  text: [
  {
    head: 'RTLS in the Healthcare Industry',
    subHead: '',
    desc: 'With the experience of the COVID-19 pandemic, advanced IT technologies are becoming increasingly important in the healthcare industry. Patient tracking and management can be considered as a key aspect of the technology. RTLS, which stands for Real-Time Location System, is a technology that will be extensively used across the healthcare industry in the future, providing much-improved levels of service than what is available now.',
    dot: ''
  },
  {
    head: '',
    subHead: 'What is RTLS?',
    desc: 'In the medical field, Real-Time Location System (RTLS) refers to a system used to track and manage medical equipment, staff, and patients in real-time within any type of patient care environment. It can be thought of as an "indoor GPS" for hospitals. However, beyond simply locating assets, this technology provides precise location tracking that easily integrates with other healthcare IT solutions, enabling facilities to improve workflow, reduce costs, and enhance clinical quality. RTLS solutions typically involve location sensors attached to various assets such as patients, staff, or equipment. The system uses unique IDs to locate tags and provide real-time information on their whereabouts within the facility.',
    dot: ''
  },
  {
    head: '',
    subHead: 'What can it do?',
    desc: 'Risks such as equipment malfunctions, infections, or physical attacks are constant threats within healthcare facilities. Real-Time Location Systems can mitigate such risks and enhance patient safety. For example, it is not uncommon for hospitals to reuse contaminated medical equipment without proper cleaning, leading to medical incidents. RTLS solutions provide the answer to such human errors.',
    dot: {
      a: '• Identifying both staff and patients who have had contact with infected patients or individuals and generating contact tracing reports based on this information. For highly contagious diseases like COVID-19, proactive measures can be taken.',
      b: '• Immediately identifying when contaminated equipment requires cleaning and providing immediate notifications of possible infection risks. It also provides information to administrators to monitor the locations of medical devices within the hospital and control and manage them.',
      c: '• Enabling management of devices that have experienced malfunctions or require maintenance.',
      d: '• Allowing healthcare workers, including doctors and nurses, to quickly request assistance in emergency situations or when facing physical threats.',
      e: '• Real-time tracking of patients to prevent them from wandering into unauthorized areas. Geofencing technology can automatically trigger alerts if necessary. This is particularly important when caring for patients with dementia or Alzheimer\'s.',
      f: '• Protecting and managing vulnerable individuals, such as emergency patients or newborns, using warning and security systems, including geofencing technology.',
    }
  },
  {
    head: '',
    subHead: 'What are the benefits?',
    desc: 'RTLS technology offers significant benefits in terms of safety, as well as economic and operational aspects. It can help reduce costs by efficient equipment management and prevent costs resulting from equipment incidents. From the perspective of administrators, they can collect and analyze monitored information. They can understand the usage status and utilization of the equipment used in hospitals, as well as track the locations of patients within the building. Additionally, if there is an increase in the number of patients and waiting times become longer, the system can help alleviate bottlenecks. By taking timely actions during periods of excessive waiting times, hospitals can provide a better experience and higher satisfaction to many people.',
    dot: ''
  },
  ],
 
  
  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/en/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/en/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/en/enterprise/logistics/asset'
      }
    ]


  }
  
}