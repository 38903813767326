import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

   
  helmet : '',
  desc : '안전을 유지하고 작업자를 보호하기 위한 노력은 절대적으로 중요합니다. 이를 위해 최신 기술인 실시간 위치추적 시스템(RTLS)이 사용되고 있으며, 이 기술은 건설 현장에서의 작업자 안전을 쉽게 관리할 수 있도록 도와줍니다.',
  keywords : '건설 안전, 건설 현장 작업자 안전, 스마트 건설, 노동자 안전, 중대재해처벌법, 건설공사 안전관리 종합정보망, 건설노조, 안전 노동',

  ogTitle : '',
  ogDesc : '',
  ogSite : '단 하나로 끝내는 스마트 안전관리 솔루션 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/smartSafety',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDyroUx1vmR8ckUGMDATiid3Dh8um-T9HPZwlPIYFAVQZ6gAqtjkC9yRmKCzc1E4y9OSYTPb_LCkF_SEylO00Rky0eS7weV0HFegyoQFnuWtZS4dJcgZhXg7NgKbB-lJHqoWX9iWFXOLGYRCck7Pzm9SecNQohk3gkJMABfK0W7SOahk5A_CpImCa6ZeYjnPrnJxJzATWPOAu_GUAv9tunvWcaHzwHNFGzd4wmKkzNRlpgiqTGmxGDiF1tnkU43_8zJ8Af3YPglR8V_Y8nr6hIKD0r_deC5eKuX9NhdkGi3_MwlH_6ID7fHAUY_4D8ay4UBjdHdIY39ZO5qmVVydqF4YA7_e2S6LGjkfGuhX06LkrzYLkNj0kAaBXMND1BhVSI-Zb-GpDot9xVAkg6mS0dhnTHGd4Dvh5P1F6CkjB2DJ8ZytwXnQJyQtOzhgYFcstok9388wIUDLs51CPoeUsytegmLUUG2NVsxXLfpB4yqeUz4gTQjH3WvGUQP3GKJkEsZ-fuzdaF3pTx9LLKB_xDvIeFhzmb4fONSHT2U9U_QEJjLCzt-NCZxHK3YK_xeHCQhB9h_8KLnCTSz8LgKKy80N4IfOpROiCh_oyYTiWXzhcWHWWh-OEvPtgY2xoCN2CtcClws0U3t-xRH5NQ9bsFsLU_41jxHLmULeU4UjUBfE50lAbgEhTIIi45ibRyNlruRzOzOsd3M129XYQM4F49mvW0c-MCe1D8fhLFbSg3TLCoO6lNHzOuGk5AhawFY6LXRmsNWIAf_orzFCaByHj8FIYhInRqA3JGuwUkGOVkqTrb2pAeqRb2Y0mO0a7Q5yuK7IEEVD6nNCJ9QrWtOBE8yYzrXVf54N7SL9aQz5ZCP4SCwp27j3V85cmk5dx-ZyDGiapVFg1rNA7Mwnx4L3F0eKNcRZzT9WWMWBXLD61fLbIfCcvoENjVSYEPO8VN5ipvNWSNwV5iHjace_sdYEzNMByEmrEjVt4slthi5itRPgSKrrgq1CQDcdZsSh1ToMCeUzPAcoPW9rnjGh8tngE7-VrPH5QLHldQu9mQy9skU-nCfY8A2DF9RRzB6So10votLNIb3bGaL-HSF5s0TOU8HlSAg2EKU6gM4LGwPjg5HztDXDoJyuRiBQcw6myNwpypRu3MPsD4EhS-uhn49VM4TVxsZMw6DJc5CXmZHhghyxX9GJyf2uHKWVOOzLkeJm2641BA8ZOpxpeg1bfNczrS9qVJDgRpzye_uh7uqihmtRlKAWH9tA2nouewAzocUyrzii7EX46YkP2GHg7DY_L_42aHc9EyTXPpGw3LDlJXHQ3sQ44RUyNV1EFE99w27XRmnxr_8YlZD9pegnY5GWNBiU35BwROQDKDsEzgKunAYgEhg3EspT4nJl4E_j19DHxsNer97Yudk2jhRWZ1QTcFWH67Ew8lCLdVw_yY0CqKWuMZK0o_379BLJt_dJIO5GVE-vhMEqdEN1zCUUp8yqBaruMbTm-_IfSHGOgQa4uue05eTTj-RcxLbAz-mRT8gzDo4yeOWNDBw4NsMtbQr-YOf89LvIvhhch-ND74zLC0KeIz5gM7VfV0VD4MkKBw=w2049-h1228',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
    
    title: '단 하나로 끝내는 스마트 안전관리 솔루션',
    date : '2023-10-04',
    tag1 : '#작업자안전관리',
    tag2 : '#스마트건설',
    tag3 : '#건설현장안전',
    tag4 : '#RTLS',
    
    // <br/>
    text : [
        {
          head : '',
          subHead :'',
          desc :'현대 건설 현장은 복잡하고 다양한 위험을 내포하고 있습니다. 안전을 유지하고 작업자를 보호하기 위한 노력은 절대적으로 중요합니다. 이를 위해 최신 기술인 실시간 위치추적 시스템(RTLS)이 사용되고 있으며, 이 기술은 건설 현장에서의 작업자 안전을 쉽게 관리할 수 있도록 도와줍니다. 이 글에서는 RTLS 기술이 어떻게 작업자의 안전을 향상시키는 데 도움을 주는지에 대해 알아보겠습니다.',
          dot : ''
        },
        {
          head : 'RTLS 기술 소개',
          subHead :'',
          desc :`RTLS는 'Real-Time Location System'의 약자로, 실시간 위치 추적 시스템을 의미합니다. 이 기술은 작업자나 장비와 같은 이동성이 있는 요소의 위치를 정확하게 파악하고 모니터링할 수 있게 해줍니다. RTLS 시스템은 GPS, RFID(라디오 주파수 식별), UWB(초광대역), 블루투스, 초음파 및 다른 센서를 사용하여 작업자의 위치를 추적합니다.`,
          dot : ''
        },
        {
          head : '작업자 안전을 위한 RTLS의 장점',
          subHead :'사고 예방',
          desc :'RTLS 시스템은 작업자의 실시간 위치를 파악하여 잠재적인 충돌이나 위험 상황을 사전에 감지할 수 있습니다. 예를 들어, 크레인 작업 중에 다른 작업자나 장비와의 근접을 감지하고 경고를 보내어 사고를 예방할 수 있습니다.',
          dot : ''
        },
        {
          head : '',
          subHead :'작업자의 상태 모니터링',
          desc :'RTLS는 작업자의 활동을 모니터링하고, 작업자의 건강 상태나 피로도를 추적할 수 있습니다. 작업자가 지칠 경우 적절한 휴식을 취하도록 안내하거나, 위험한 작업에서 벗어나도록 경고를 보낼 수 있습니다.',
          dot : ''
        },
        {
          head : '',
          subHead :'비상 상황 대응',
          desc :'작업자가 긴급 상황에 처한 경우, RTLS 시스템을 통해 정확한 위치 정보를 제공함으로써 구조나 응급 처치에 필요한 시간을 단축시킬 수 있습니다. 이로써 작업자의 생명을 구할 수 있는 가능성이 높아집니다.',
          dot : ''
        },
        {
          head : '',
          subHead :'업무 일지와 리포트',
          desc :'RTLS 시스템은 작업자의 활동을 기록하고 업무 일지를 생성합니다. 이를 통해 작업 일정을 최적화하고 생산성을 향상시킬 수 있습니다. 또한, 사고 발생 시 관련 정보를 기록하여 사고 조사와 보고를 용이하게 합니다.',
          dot : ''
        },
        {
          head : 'RTLS의 적용 사례',
          subHead :'건설 현장',
          desc :'건설 현장은 다양한 위험 요소가 존재하며 많은 작업자가 협업하여 작업합니다. RTLS 시스템은 크레인, 포크리프트, 덤프트럭 등의 장비와 작업자를 실시간으로 추적하여 안전한 작업 환경을 조성합니다.',
          dot : ''
        },
        {
          head : '',
          subHead :'공장 및 제조업',
          desc :'공장과 제조업 분야에서도 RTLS는 작업자의 위치 추적과 재고 관리에 활용됩니다. 이를 통해 생산 과정을 최적화하고 생산 효율을 향상시킬 수 있습니다.',
          dot : ''
        },
        {
          head : '',
          subHead :'물류 및 창고',
          desc :'물류 및 창고에서는 RTLS 시스템을 활용하여 재고 추적, 창고 내 작업자 위치 확인, 출하 및 배송 프로세스 최적화에 활용됩니다.',
          dot : ''
        },
        {
          head : '마무리',
          subHead :'',
          desc :'RTLS 기술은 건설 현장 및 다른 산업 분야에서 작업자의 안전과 생산성을 향상시키는 강력한 도구입니다. 이 기술은 사고 예방, 작업자 상태 모니터링, 비상 상황 대응, 업무 일지 및 리포트 생성과 같은 다양한 기능을 제공하여 현장 관리자와 작업자들에게 큰 혜택을 제공합니다. 앞으로 건설 산업과 다른 산업 분야에서 RTLS 기술의 발전을 기대하며, 작업자들의 안전을 높이는 데 지속적인 노력이 필요하다는 점을 기억해야 합니다.',
          dot : ''
        },
        {
          head : '',
          subHead :'',
          desc :'',
          dot : ''
        },
    ],
  
    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/enterprise/construction/safety'
        },
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/enterprise/construction/asset'
        }, 
        {
          img : constructionEquipment.thumb,
          data : 'constructionEquipment',
          link : '/enterprise/construction/equipment'
        }
      ]
  
  
    }
  
  
  }