import {
  navImgAccess,
  navImgBeacon,
  navImgCamera,
  navImgIOT,
  navImgOS,
  navImgRTLS,
  navImgSensor,
  navImgWorld
} from "@assets/images/nav";

export default {
  product: [
    {
      icon: navImgRTLS,
      title: '实时定位追踪',
      link: '/cn/rtls',
      path: [
        {
          text: '高精度（UWB）位置跟踪',
          link: '/cn/rtls/uwb',
        },
      ]
    },
    {
      icon: navImgBeacon,
      title: '信标设备',
      link: 'none',
      path: [
        {
          text: '蓝牙（BLE）位置跟踪',
          link: '/cn/beacon/ble',
        },
        {
          text: '工业信标',
          link: '/cn/beacon/industry',
        },
        {
          text: '小型信标',
          link: '/cn/beacon/tag',
        },
        {
          text: '资产用标签',
          link: '/cn/beacon/asset-tag',
        },
      ]
    },
    {
      icon: navImgAccess,
      title: 'AI定位追踪',
      link: '/cn/access',
      path: [
        {
          text: '自动门禁',
          link: '/cn/access/auto',
        },
        {
          text: '人脸识别门禁',
          link: '/cn/access/face',
        },
        {
          text: '门禁附件',
          link: '/cn/access/accessory',
        }
      ]
    },
    {
      icon: navImgCamera,
      title: '摄像机位置跟踪',
      link: '/cn/camera',
      path: [
        {
          text: '户外摄像机',
          link: '/cn/camera/outdoor',
        },
        {
          text: '室内摄像机',
          link: '/cn/camera/indoor',
        },
      ]
    },
    {
      icon: navImgSensor,
      title: '工业物联网',
      link: 'none',
      path: [
        {
          text: 'LoRa网关',
          link: '/cn/sensor/LoRaGateway',
        },
        {
          text: 'LoRa传感器',
          link: '/cn/sensor/LoRaSensor',
        },
        {
          text: '空气质量监测仪',
          link: '/cn/sensor/air',
        },
        {
          text: '一氧化碳监测仪',
          link: '/cn/sensor/co',
        }
      ]
    },
    {
      icon: navImgWorld,
      title: 'Integration',
      link: '/cn/integration',
      path: [
        {
          text: '机器人系统',
          link: '/cn/integration/robot',
        },
      ]
    },
  ],

  OS: {
    icon: navImgOS,
    title: "ORBRO操作系统",
    desc: '从门禁系统到实时位置跟踪和各种IoT控制，ORBRO操作系统是一个统一的服务，使空间在各个方面更加智能化。立刻体验智能未来。',
    more: '了解更多',
    link: '/cn/os',
  },

  solution: [
    {
      title: '建筑',
      path: [
        {
          text: '工人安全管理',
          link: '/cn/enterprise/construction/safety'
        },
        {
          text: '建筑资产管理',
          link: '/cn/enterprise/construction/asset'
        },
        {
          text: '重型设备管理',
          link: '/cn/enterprise/construction/equipment'
        },
      ]
    },
    {
      title: '物流',
      path: [
        {
          text: '物流库存管理',
          link: '/cn/enterprise/logistics/asset'
        },
        {
          text: '仓库工人安全管理',
          link: '/cn/enterprise/logistics/safety'
        },
      ]
    },
    {
      title: '医院',
      path: [
        {
          text: '患者位置跟踪',
          link: '/cn/enterprise/medical/patient'
        },
        {
          text: '医疗废物管理',
          link: '/cn/enterprise/medical/waste'
        },
      ]
    },
    {
      title: '制造',
      path: [
        {
          text: '制造流程管理',
          link: '/cn/enterprise/manufacturing/process'
        },
        {
          text: '制造资产管理',
          link: '/cn/enterprise/manufacturing/asset'
        },
      ]
    },
    {
      title: '办公室',
      path: [
        {
          text: '办公室资产管理',
          link: '/cn/enterprise/office/asset'
        },
      ]
    },
    {
      title: '零售',
      path: [
        {
          text: '展会访客分析',
          link: '/cn/enterprise/retail/exhibition'
        },
      ]
    }
  ],

  world: {
    icon: navImgWorld,
    title: "ORBRO数字孪生",
    desc: '接收现实世界中的人、物品、建筑和城市的数据，并在数字屏幕中的虚拟现实中进行实时监控和管理。通过这种方式，可以提高生产力和预防问题，主要用于工业和制造领域。',
    more: '了解更多',
    link: '/cn/digital-twin',
  },


  support: [
    {
      title: '客户支持',
      path: [
        {
          text: '公司介绍',
          link: '/cn/company'
        },
        {
          text: '销售咨询',
          link: '/cn/request'
        }
      ]
    },
    {
      title: '技术介绍',
      path: [
        {
          text: 'UWB',
          link: '/cn/enterprise/technology/uwb'
        },
        {
          text: 'BLE',
          link: '/cn/enterprise/technology/ble'
        },
        {
          text: '立体视觉',
          link: '/cn/enterprise/technology/stereo-vision'
        },
        {
          text: '单眼视觉',
          link: '/cn/enterprise/technology/monocular-vision'
        },
        {
          text: 'TDoA',
          link: '/cn/enterprise/technology/tdoa'
        },
        {
          text: 'TWR',
          link: '/cn/enterprise/technology/twr'
        },
        {
          text: 'Geofence',
          link: '/cn/enterprise/technology/what-is-geofence'
        },
        {
          text: 'Digital Twin',
          link: '/cn/enterprise/technology/what-is-digital-twin'
        },
      ]
    },
    {
      title: '软件',
      path: [
        {
          text: 'iOS',
          link: 'https://apps.apple.com/us/app/orbro-service/id1673356996'
        },
        {
          text: 'Android',
          link: 'https://play.google.com/store/apps/details?id=com.orbro.mobile'
        },
        {
          text: 'ORBRO世界',
          link: 'https://home.orbro.io'
        },
        {
          text: 'Release Note (only KOR)',
          link: '/release-note'
        },
      ]
    },
    {
      title: '社交媒体',
      path: [
        {
          text: 'LinkedIn',
          link: 'https://www.linkedin.com/company/kongtech/'
        },
        {
          text: 'Naver',
          link: 'https://blog.naver.com/orbro_platform'
        },
        {
          text: 'Medium',
          link: 'https://medium.com/@orbro'
        },
      ]
    },
  ],
  footerSupport: [

    {
      title: '支持',
      path: [
        { text: "博客", link: "/cn/blog" },
        { text: "销售咨询", link: "/cn/request" },
        { text: 'Release Note (only KOR)', link: '/release-note' },
      ]
    },
    {
      title: '服务',
      path: [
        { text: "Orbro世界", link: "https://home.orbro.io" },
      ]
    },
    {
      title: '下载',
      path: [
        { text: "下载iOS应用", link: "https://apps.apple.com/us/app/orbro-service/id1673356996" },
        { text: "下载Android应用", link: "https://play.google.com/store/apps/details?id=com.orbro.mobile" },
      ]
    },
  ]

}
