import { accessAutoThumb, deadboltThumb, emlockThumb } from '@assets/images/main/access/product';
import { IconOsOff, IconOsOn } from '@assets/images/main/icon';
import {
    accessKey1, accessKey2, accessKey3, accessKey4,
    accessOutlook,
    IconBox, IconComputer, IconGuarantee,
    assetOn, workerOn, workerOff, IconPath, IconLocation, IconAnalyze, IconAlarm, IconExcel, IconSDK, assetOff
} from '@assets/images/main/index';
import { RtlsFunctions1D, RtlsFunctions1M, RtlsFunctions1T, RtlsFunctions2D, RtlsFunctions2M, RtlsFunctions2T, RtlsFunctions3D, RtlsFunctions3M, RtlsFunctions3T, RtlsFunctions4D, RtlsFunctions4M, RtlsFunctions4T, RtlsFunctions5D, RtlsFunctions5M, RtlsFunctions5T } from '@assets/images/main/rtls/main/functions';
import { RtlsHardware1D, RtlsHardware1M, RtlsHardware1T, RtlsHardware2D, RtlsHardware2M, RtlsHardware2T, RtlsHardware3D, RtlsHardware3M, RtlsHardware3T, RtlsHardware4D, RtlsHardware4M, RtlsHardware4T } from '@assets/images/main/rtls/main/hardware';
import { assetTag, twinTracker1, twinTrackerBracket } from '@assets/images/main/rtls/product';




export default {

    banner: {
        title: '开始定位',
        desc: '通过引入定位系统，提高生产力和安全管理。',

        schemeTitle: '选择适合您的定位方案',
        schemeDesc: '需要帮助做出决定吗？',
        schemeDesc2: '请联系我们',
        phone: '1522-9928',

        card: [
            {
                title: '仅限韩国',
                desc: '用于位置跟踪<br/>制造网络设备',
            },
            {
                title: '超过50亿条记录',
                desc: '亚洲最大规模的<br/>实时位置数据',
            },
            {
                title: '世界一流性能',
                desc: '使用AI算法<br/>具有最高水平的位置精度',
            },
        ]

        
    },

    mainPage: {
        product: {
            title: '了解产品组合',
            desc: '了解创新的定位产品组合。',
            asset: {
                title: '蓝牙BLE定位追踪',
                desc: '将BLE标签设备附着到您要监视或追踪的对象，实现实时位置追踪。',
            },
            worker: {
                title: '高精度UWB定位追踪',
                desc: '将UWB标签设备附着到您要监视或追踪的对象，实现实时位置追踪。',
            }
        }

    },

    video: {
        title: 'ORBRO开始定位系统',
        desc: [
            { text: '通过仪表板功能，<br/>可快速确认设备状态标签终端的变动变化类别分类现状、区域现状。' },
            { text: '可通过设备快速确定目标位置和当前状态。<br/>提供准确的数据和简单的接口。' },
            { text: '可快速设置作业空间至危险区域，可快速掌握<br/>各区域的现状。' },
            { text: '包括进入危险区域在内，<br/>可快速发送适合各种情况的警报。' },
        ]

    },

    functions: {
        title: '创新的位置跟踪系统体验',
        desc: 'Orbro的位置跟踪系统通过提供基于Web的管理和移动应用程序，为用户提供最佳体验。通过这一方式，您可以在现有的位置跟踪系统中体验到最高级别的用户便利性。',

        line: 2,
        card: [
            {
                size: '3',
                tag: '集成管理软件',
                title: '基于室内地图的软件',
                label: '管理员',
                img: {
                    desktop: RtlsFunctions1D,
                    tablet: RtlsFunctions1T,
                    mobile: RtlsFunctions1M
                },
            },
            {
                size: '1',
                tag: '提供移动应用',
                title: '通过移动应用进行位置跟踪',
                label: '通用提供',
                img: {
                    desktop: RtlsFunctions2D,
                    tablet: RtlsFunctions2T,
                    mobile: RtlsFunctions2M
                },
            },
            {
                size: '2',
                tag: '设备历史管理',
                title: '按区域实时记录设备位置',
                label: '管理员',
                img: {
                    desktop: RtlsFunctions3D,
                    tablet: RtlsFunctions3T,
                    mobile: RtlsFunctions3M
                },
            },
            {
                size: '2',
                tag: '区域设置',
                title: '按区域实时跟踪设备',
                label: '管理员',
                img: {
                    desktop: RtlsFunctions4D,
                    tablet: RtlsFunctions4T,
                    mobile: RtlsFunctions4M
                },
            },
            {
                size: '2',
                tag: '设备管理',
                title: '便捷的设备管理',
                label: '管理员',
                img: {
                    desktop: RtlsFunctions5D,
                    tablet: RtlsFunctions5T,
                    mobile: RtlsFunctions5M
                },
            },
        ]
    },

    hardware: {
        title: '核心是硬件，一切都轻松创新。',
        desc: '我们的位置追踪系统设备是经过特殊设计的设备，考虑了定向天线和现场环境。应用定向天线以实现最佳性能和稳定性，可在各种情况下有效运行。',
        card: [
            {
                img: {
                    desktop: RtlsHardware1D,
                    tablet: RtlsHardware1T,
                    mobile: RtlsHardware1M
                },
                tag: '定向天线',
                title: '使用定向天线进行准确的位置追踪',
                desc: 'TwinTracker（位置追踪设备）的定向天线可以推断出无线信号的方向。'
            },
            {
                img: {
                    desktop: RtlsHardware2D,
                    tablet: RtlsHardware2T,
                    mobile: RtlsHardware2M
                },
                tag: '各种支架',
                title: '天花板嵌入式，可安装射灯',
                falldown: [
                    {
                        title: '天花板嵌入式支架',
                        desc: '可以安装在各种天花板上，<br/>包括石膏、tex、SMC、Myton等。'
                    },
                    {
                        title: '轨道式支架',
                        desc: '与照明使用的轨道规格相同，<br/>如果天花板上已经安装了轨道，随时可以安装。'
                    },
                ]

            },
            {
                img: {
                    desktop: RtlsHardware3D,
                    tablet: RtlsHardware3T,
                    mobile: RtlsHardware3M
                },
                tag: '创新技术',
                title: '使用TDoA技术的最小安装，最大性能',
                desc: 'TDoA技术是一种在保持高性能通信的同时创新性地降低电池消耗的通信技术。它在实时运行的同时，记录的位置精度远高于传统方法。'
            },
            {
                img: {
                    desktop: RtlsHardware4D,
                    tablet: RtlsHardware4T,
                    mobile: RtlsHardware4M
                },
                tag: '多种通信方式',
                title: '有线通信，无线通信，均可连接',
            },
        ]
    },


    faqRequest: '随时咨询',
    faqRequestTail: '。',
    FAQ: [
        {
            title: '没有技术方面的专业知识。 还能用吗？',
            description: 'ORBRO系统为不熟悉技术的用户提供了简单而强大的界面。 <br/>最重要的是,通过可视化的数据,建立了可以进行直观分析和使用的系统。<br/><br/>'
        },
        {

            title: '位置跟踪有多复杂？',
            description: 'ORBRO RTLS系统的精确度非常高。<br/>反映实时数据非常重要，因此可准确、迅速地进行数据连接，无误差。<br/><br/>',
        },
        {

            title: `是否可以跟踪这些产品的位置？ 添加其他功能。`,
            description: `ORBRO技术具有可扩展性。<br/>位置 除了跟踪之外，还可以应用各种应用程序技术，例如确定路由、偏离路由和设置不可访问区域。<br/><br/>`,
        }
    ],


    bannerData: [
        {
            icon: workerOn,
            iconInv: workerOff,
            image: '',
            iconText: '高精度UWB<br/>定位追踪',
            sub: 'New',
            link: '/cn/rtls/uwb'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/cn/os'
        },

    ],



    product: {
        asset: {
            func: '内置电池的产品，无需电源施工，只需贴在门旁边，即可通过手机轻松快速地控制门的进出。',
            key1: accessKey1,
            key2: accessKey2,
            key3: accessKey3,
            key4: accessKey4,
            outlook: accessOutlook,

        },


    },


    purchaseDesc: {
        asset: "将BLE标签设备附着到您要监视或追踪的对象，实现实时位置追踪。",
        worker: "将UWB标签设备附着到您要监视或追踪的对象，实现实时位置追踪。",


    },


    purchase: {
        //移动设备
        asset: {
            assetTracking: {
                icon: accessKey1,
                title: '资产定位',
                serial: 'Asset-Tracking',
                price: '5,900,000',

                note: [
                    { text: 'TwinTracker (BLE) 10台', icon: IconBox },
                    { text: '资产追踪标签 30个', icon: IconBox },
                    { text: 'Server PC 1台', icon: IconComputer },
                    { text: '3年保修期', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: '资产标签(BLE)',
                        price: '30,000',
                        img: assetTag,
                    },
                    {
                        title: 'TwinTracker(BLE)',
                        price: '300,000',
                        img: twinTracker1,
                    },
                    {
                        title: 'TwinTracker支架',
                        price: '100,000',
                        img: twinTrackerBracket,
                    },

                ],
                mark: ""

            },

        },



        //自动门进出
        worker: {
            workerTracking: {
                icon: accessKey1,
                title: '员工定位',
                serial: 'Worker-Tracking',
                price: '5,000,000',

                note: [
                    { text: 'TwinTracker (UWB) 10台', icon: IconBox },
                    { text: '员工追踪标签 10个', icon: IconBox },
                    { text: 'Server PC 1台', icon: IconComputer },
                    { text: '3年保修期', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'Tag(UWB)',
                        price: '60,000',
                        img: emlockThumb,
                    },
                    {
                        title: 'TwinTracker(UWB)',
                        price: '300,000',
                        img: deadboltThumb,
                    },
                    {
                        title: 'TwinTracker支架',
                        price: '100,000',
                        img: accessAutoThumb,
                    }

                ],
                mark: ""

            },
        },


    },




    func: {
        title: '了解定位功能',
        desc: '了解各种定位功能。',

        data: [
            {
                img: IconPath,
                title: '路径分析',
                desc: '实时累积数据并分析每个时间段的路径。'

            },
            {
                img: IconLocation,
                title: '实时定位',
                desc: 'Orbro的定位系统实时追踪位置。通过高度分析功能可以实时监视现场。'

            },
            {
                img: IconAnalyze,
                title: '分析工具',
                desc: '收集大量位置数据，并获得数据统计和洞察。'

            },
            {
                img: IconAlarm,
                title: '设置警报',
                desc: '在禁止人员进入的区域设置危险区域并接收警报通知。'

            },
            {
                img: IconExcel,
                title: '下载Excel',
                desc: '整理所需数据并下载Excel数据。'

            },
            {
                img: IconSDK,
                title: '提供SDK',
                desc: 'Orbro的定位系统提供SDK，支持根据客户需求进行开发。'

            },
        ]

    }



}