import { constructionAsset, constructionEquipment, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| 智能建筑现场安全管理系统',
  desc : '利用物联网技术和RTLS，在建筑工地上实时追踪工人的位置，并在进入危险区域时发出警告，从而增强安全性。这确保了工人的安全，提高了事故预防和现场管理的效率。',
  keywords : '建筑安全，建筑工地工人安全，智能建筑，工人安全，重大事故惩罚法，建筑工会，安全劳工',

  ogTitle : '',
  ogDesc : '',
  ogSite : '智能建筑现场安全管理系统 :: ORBRO Blog',
  ogUrl : 'https://orbro.io/cn/blog/smartConstruction',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4OLTH19o0lofjwwmkKAz-9CGPCQcIHeGEvzHeubn5er_MCfU-q7GYUawQUgiBhV0p2H76qh1eSAjvENS8D30uK8wsm0dvRVz9fbghVm-npKypYxWtsi_kYTYd1uZYhCTnPpxWWxuJjMsmCvjKN2aY8L6u8uqXYCtcS2irDtBPmqv0nFkB22q83mh1u0tHLtyMOjggWKiJKLrctPn4GE5QW3s9JSv6OmA8ZhKbRghTN42Hq8Et9cH8kU-Nkbv_b26v6aQUdGmrak5jXiAKE4sXCbRnLX5IT_XnZo660WsMWHlDftI9hI392gO7mJYmKHNYA0Hhdh8rCxv2YcyNz4KSiwSz9Jp3W-OhtcoLEyNuTfLi_3AS4__oyVTxTqqP61RHt_sRKPpHWzYWKD2-lRFFqGAQnxfdW-cS3KHJFgwx2bn3SlV2YmG8WDnLTN2xFmd3_t6DTG8QVZbDg2gHQz4sqmC91sVjpA-UACZ0Ea_8Z5tsVSsIUcZeOLqJ2OSf4CgoOXD7KX5QgQz0O8OI6hq8riIT0Q_ubXhp_-gNUyjqFj6XUxFp1N8l1vWcJz_vaq817pMKLQOh5tUhXUl1QPqo43LWZGk6FCPDhUDVOZbq731ORKRpWvfDieeQpaAHXBh1TIb104d8BxMZNoVwDMtQjqysfBeMFBDTJDq1cQOGrhcT1uLJ8p0sdYxZQrKha8F66CA9glk9n61-IowjHJKAFd8H0Rrapwp7UJwhug12o4UvffLxnbqNzmdX1RraJoFrfcs6Fhp8da4-TdfOtEqhwoTkqobcXUN07zWEQUl2CRHHRUG82xw4wlcF9BIunVPJe894pfmXE4C0ETayUHYXDJcIYK7SQUS08H7Xi2fyQHw_zZIHg8Lj_w3ejIUGBANJuIxQglvskR5g7NjbJ0EBv2bCKLqkGEsPZMG5ypfBu2BtfKkDZT4kekWaO9TXJrrVDYa_A18PBQSN9hv2G8JwBGt_bvRlGZMDsGu6VCwq3fJAB8gtzb_0U8PrztEHGSsojNVoVZ7BN6OUROQFTH9IQZHIv_NC9jPraNFThG8ydegAos8aSaqEMeohU8FOv1Lck-kOCLo-HB0qSX11IkppPvCaSgblbcpB9ET8m0I3gQGkJpyBdwmY70NMVKUe1az2MUpZRUgoQaLP7ZKujMw7syYLaZ_hCiXuYqsF_Fn7yZ9nmG1FjxDGVKEbijl33lTSgLAWVc1yhzuS-8__AcbhG2nI2aAii6ZxqZAfHMBHQQ5BLYQTJCpJZjLeJKgBg_XT6Gl56dGOq8vhdrl8NdBqsL54EVg_a_qn4cvrSaQ5Lt8IDGgYgNFvrPBwLgnqWb2Ll6oLL1MvhV2vru9BFadHclWr0Ly4nPy5DI1Lr4ghP2biSKLMQeXyUo97JePQN6ei9jdi7k1hzr9Kk1vrBIFz5Okp_FaZeKDikQ5oZ7yVctdAjvZIBO-h5i_Zin6sl878TaGDErBn301m-gHvF1G8AMlU46qZnNBPlPld-YUOqM6V79RFxmf_wpJWAFld9xB4ud-CLWzo6xdWzpanyQoaf7cj9Myian97M297lV5zEBj-Q=w2295-h1233',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '智能建筑现场安全管理系统',
  date : '2023-07-31',
  tag1: '#建筑安全',
  tag2: '#工人安全',
  tag3: '#建筑地盘安全',
  tag4 : '#建筑安全管理',
    
  
  text : [
    {
      head : '',
      subHead : '',
      desc :'建筑工地往往比其他行业更危险。因此，确保工人的安全应该是首要任务。在这样高风险的环境中忽视工人的安全不仅会扰乱项目，还会导致法律责任。<br/>事实上，在建筑行业中，可以说是最关键、最重要的方面。<br/><br/>许多安全事故发生在工人没有正确佩戴安全设备的情况下。此外，事故往往在一瞬间发生，如果不采取迅速的措施，可能导致人员伤亡。然而，通过使用实时位置追踪技术（即实时位置系统RTLS），建筑工地可以实时跟踪和管理工人及整个工地情况，从而创造一个更安全的建筑环境。这项技术还可以显著提高建筑项目的效率，包括物资管理和工人活动分析。<br/><br/>以下，我们将讨论这项技术将如何改变和增强建筑工地。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'1. 安全设备使用管理',
      desc : '在建筑工地上，佩戴安全设备是最基本和最重要的安全协议。然而，由于各种原因，工人有时可能忽略正确佩戴设备。忽视或感到不方便佩戴设备最终可能导致严重事故。<br/>实时跟踪让管理员可以确定工人是否佩戴了安全设备，并且如果当前位置是高风险区域，管理员可以更加关注管理。管理员可以实时监测情况，提供警告或采取相应措施。<br/>如果发生事故，这些数据也可以作为重要信息来了解当时的情况。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'2. 实时位置追踪',
      desc : '有时需要迅速找到特定的工人，或者需要整体了解工地上工人的位置。实时追踪显示工人在与工地布局相同的地图上移动的位置，有助于快速了解和评估情况。<br/>另外，该技术可以利用地理围栏设置特定区域为危险区域，如果工人进入这些指定区域，将触发实时警报。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'3. 工作效率管理',
      desc : '可以跟踪和分析工人的活动、停留时间、休息时间等信息。可以获得有关每个工人工作特点和整体流程的信息，以提高项目效率。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'4. 重型设备管理',
      desc : '工人不仅在工地上活动，重型设备也起着至关重要的作用，尤其是涉及安全问题。实时跟踪重型设备，可以在设备接近工人时立即发出警报，帮助管理员提前发现碰撞风险。此外，实时跟踪重型设备与其效率相关。可以在现场进行最佳布局，并确定最短路径以节省时间。还有助于库存管理和维护方面。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'结论',
      desc : '到目前为止，管理者依赖直接观察和判断来对建筑工地进行指示。<br/>然而，借助新技术，智能建筑工地安全管理成为可能。<br/>除了安全性，它还增强了建筑项目的效率和生产力，并为工作带来更系统化的方法。',
      dot : ''
      
    },
  
  ],
  


  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: "将UWB终端机安装在工作者或设备上，帮助实时追踪位置的产品。",
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/cn/enterprise/construction/safety'
      },
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/cn/enterprise/construction/asset'
      }, 
      {
        img : constructionEquipment.thumb,
        data : 'constructionEquipment',
        link : '/cn/enterprise/construction/equipment'
      }
    ]

  }


}