export default {


  helmet : '',
  desc : 'UWB와 BLE의 차이점',
  keywords : 'UWB, BLE, RTLS, 실내위치추적, 실내네비게이션, 위치추적솔루션, 실시간위치추적, 자산추적, 스마트오피스, GPS, 실내위치추적솔루션',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'UWB vs BLE, 무엇이 다를까 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/uwbble',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZZsLASGTnrScR1DV1QopMGclrJn5K8ZJInzLa94g6AIaAH6BzSuBPAH-wS2mHuNOA1mdIfcv1bdbL18qGLSOHMOehOeSSNzm2vDgBEjSvywRbuM8SYH8aJcLqRYYlEYJUObEzj8sEFYd3to4h_VjTHZXzDA9k8TC-o51xNmFufAUeZ1kuMqdQipZWGBm4HevoHCjoVf68HjlqL8-X7uhIEJmSiL6F_kZT2tn4pEECWPXLKGYZTTqVIqwHn2caiamWYj35dJaXH0dMQEvVic6co1unUFRxhUD3aDR4knN7J8taOLeVeznBzxF-C8PnEL1coYje3VprpZr5zfIcmssraJ9xIOjlwVGvW6IK1iBOr3wf9CCJm77Jw9tOEklvaCQ5oPPSeB_RE-ehchFegOPnMNBz7gEMJV_Mg89aTGSvkhXaziw1QnWJ0wDAtMvBmnQvL60PFKrOreGv9QDCaEOUfs_oXmurorIElLQP3MnFQP9vLyG3kurjmrfk3TDGAPXo5DR3-uiskrm5MuqKlpIhXgPz8z8mHs-unoOiu3vTijzwEzlSki-LUPnsboemprtC0BXMOoTQNJZy4knsYDiOkAW8xTQOTbJNxMS80g8QtjIiVwq54JIfxFXcP7eb9jxLb-vRWTYw6lfutNXZXo5LTGkb6VQkTt-3st7NBvrJ54ckNCG2MX7P8p1ov0je8Lw-WUJp0iqjYRTaxiNDx-bCqYQW-WXNS8xzCsd_OtzdDZSvwcmam5M_8qQRHQxGRrDuQAhmkP1DtpXb7rGW_vqhPb88Hg2DfLEhQZetR8bwXfhficuWpLJDe9-mDXTGtN7HAPgdcpgoXnIpH85t38Vhc1HVzpmvbeD7mTUkZVLsUNlut0NCpETr73mrpBUBdKLQRo19tsJN-sOTp_D8bgg0xTVr_TCpl5rR2BHRTu9wRWM5w7Bun4I7HnCN8_nnIe4Qr6prFwZxgTpEQCezOjB26O4G0wxo-Ri2hRAfMGa3thhal-CdxqHkWhqzDHxOZP2R2o7QhjEwk2NcaJGjDaGyb328bh7yWMAoatTvT_-Lg1W73FUCwKCymcuZtao5eH4dujXwzQamAodVDphMqW_1gLEgh8BX8uE868KrzJq4PABF4xom6uC4IJtQiiXGdRirYUPRDvqkivyokNFjFezZAVuEpAFRvObO5UUCjf8_igFbzbLqd8Z5toWeF4b5JqjtYG2FK9ViXjC-0qQYIdfTdRrQRDoveHeiJ5cLpJJHlKFDOqGB2kGNgM9TnCZJzdSaYJv5hFwn17x9HImcv8g4mbvugoe48db3cTJ24FjmQ9OL0KhWTKW12kdJJ1qhHqsIbtv7_wk2nTUuy7eUzWerXVrVY3QnDWio_AXGC4_NBQfCVTp7TkOZ71WGCqP0SkbtIk9mDmudkXbJI-_CCSLIH5sQjmKlOkLkNRd6a52YMklzWKvRFVw5fkIrknD_d-G4S9U886e1xdA6KwdqnjJtTS2H5dHxTx-AQL1qF6VjeOSxiT7imfn7A5ZPWXK4okXF_RBeL-hTBGxcdbWRcnnIw42BpTCntZQVGftEX3prPIQ=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  
  title: 'UWB vs BLE, 무엇이 다를까.',
  date : '2023-05-08',
  tag1 : '#UWB',
  tag2 : '#BLE',
  tag3 : '#RTLS',
  tag4 : '#무선통신',
  
  // <br/>
  text : [
    {
      head : 'UWB 란?',
      subHead :'',
      desc : 'UWB는 Ultra-Wideband의 약자로 근거리 무선 통신 프로토콜입니다.  UWB 무선 기술은 고주파의 매우 짧은 펄스를 사용하여 정보를 전송합니다 . <br/> UWB 신호는 매우 짧기 때문에 Bluetooth 또는 Wi-Fi와 같은 기존 무선 기술보다 훨씬 더 넓은 범위의 전자기 스펙트럼을 차지할 수 있습니다. 이를 통해 UWB 장치는 상대적으로 먼 거리(최대 수백 미터)에서 매우 빠른 속도(초당 최대 수 기가비트)로 데이터를 전송할 수 있습니다.<br/> UWB 기술은 다른 무선 기술보다 더 정확한 위치 추적에 사용할 수 있어 실내 내비게이션이나 자산 추적과 같은 애플리케이션에 이상적입니다.',
      dot : ''
      
    },
    {
      head : 'BLE 란?',
      subHead :'',
      desc : 'BLE(Bluetooth Low Energy)는 저전력 소모를 위해 설계된 무선 통신 프로토콜입니다. BLE는 전력 소모가 더 많은 Bluetooth Classic 프로토콜 의 대안으로 도입되었으며 더 적은 전력을 사용하면서 많은 동일한 기능을 제공합니다. <br/>BLE는 주파수 호핑 확산 스펙트럼이라는 기술을 사용하는데, 이는 신호의 주파수를 빠르게 변경하여 통신을 용이하게 하고 간섭으로 인해 통신이 중단되는 것을 더 어렵게 만듭니다.  BLE 장치는 37개의 서로 다른 주파수에서 다른 BLE 장치를 "수신"하고 전송할 때마다 새로운 주파수로 건너뜁니다. 이 프로세스는 동일하거나 유사한 주파수를 사용할 수 있는 다른 장치의 간섭을 줄이는 데 도움이 됩니다.<br/> BLE는 스마트폰 및 웨어러블을 포함한 기타 무선 장치와 쉽게 통합되며 배터리 구동식 BLE 비콘과 호환되므로 비용 효율적이고 다재다능하게 사용할 수 있습니다',
      dot : ''
    },
    {
      head : 'UWB vs BLE ?',
      subHead :'',
      desc : '',
      dot : {
      }
    },
    {
      head : '',
      subHead :'정확도',
      desc : 'UWB는 BLE보다 훨씬 더 높은 정확도를 제공합니다. BLE의 미터 수준 정확도와 비교할 때 센티미터 정도입니다. 이는 의료 시설이나 제조 환경과 같이 위치 정확도가 필요한 애플리케이션에 매우 중요합니다. 정확한 보고를 위해 자산이나 사람의 위치를 자동으로 식별할 수 있습니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'더 큰 데이터 전송',
      desc : 'UWB는 BLE보다 훨씬 높은 데이터 속도를 제공합니다. 즉, 태그와 리더 간에 더 많은 실시간 위치 데이터를 전송할 수 있어 더 자세한 추적 정보가 가능합니다. 이는 보안 또는 재고 관리와 같이 실시간 위치 서비스 및 자산 추적이 필요한 애플리케이션에서 특히 중요합니다.',
    },
    {
      head : '',
      subHead :'효율적 비용',
      desc : 'UWB 태그는 일반적으로 BLE 태그보다 비쌉니다. 그러나 UWB 태그의 정확도와 데이터 속도가 향상되어 많은 RTLS 애플리케이션에서 보다 비용 효율적인 옵션이 되었습니다. 장기적으로 UWB를 사용하는 운영 비용은 BLE보다 낮을 수 있습니다. BLE와 비교할 때 UWB는 더 까다로운 RTLS 애플리케이션을 위한 확실한 선택입니다.',
    },
    {
      head : '',
      subHead :'보안',
      desc : 'UWB는 BLE보다 안전합니다. BLE는 스니핑 및 재생 공격과 같은 공격에 취약한 반면 UWB는 훨씬 더 안전합니다. 따라서 UWB는 높은 보안 수준이 필요한 애플리케이션에 더 적합합니다.',
    },
    {
      head : '',
      subHead :'넓은 범위',
      desc : 'UWB는 BLE보다 범위가 훨씬 깁니다. BLE 태그의 최대 범위는 약 50m이며 UWB 태그의 최대 범위는 100m입니다. 따라서 UWB는 넓은 지역에 걸쳐 자산의 정확한 위치를 추적해야 하는 대규모 시설에 이상적입니다. <br/>RTLS 기술에는 많은 실용적인 용도가 있습니다. 가장 일반적인 응용 프로그램 중 일부는 다음과 같습니다.',
      dot : {
        a :' • 창고 또는 공장 현장의 자산 추적 및 모니터링',
        b :' • 의료 환경에서 환자 및 병원 장비 추적 및 관리',
        c :' • 운송 중인 차량 및 화물 추적',
        d :' • 건설현장, 유전 등 야외 환경에서 자원 및 인력 관리',
      }
    },
    {
      head : '',
      subHead :'결론',
      desc : '각 솔루션을 선택하기 전에 비즈니스 또는 조직의 특정 요구 사항을 이해하는 것이 중요합니다. ',
    },

  ],


}