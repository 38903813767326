import { StyledGridColumnProps } from "@components/Grid/Grid.model";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


interface FuncTextGridColumnProps extends StyledGridColumnProps {
  index: number;
}



export const FuncTextBox = styled.div<{index : number}>`
  width : 100%;
  max-height : 500px;
  height : 100%;
  display : flex;
  flex-direction : column;

  justify-content : center;


  ${({index}) =>
  index % 2 === 0
    ? `
    margin-right : 32px;
    
  `
    : `
    margin-left : 32px;
  `}



  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height : fit-content;
    margin : 0px;
  }
`


export const FuncLabelBox = styled.div`
  display : flex;
  flex-direction : row;
  align-itmes : center;
`;

export const FuncLabelIcon = styled.img`
  width : 20px;
  height : 20px;
  margin-right : 8px;
  object-fit : contain;
`


export const FuncImg = styled.img`

  width : 100%;
  height : auto;
  object-fit : contain;

  border-radius: 12px;
  border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));

`;

export const FuncSection = styled.div`
  padding : 64px 0;
  display: flex;
  justify-content: space-between;
  flex-direction : row;
  gap : 32px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    flex-direction : column;
    gap : 0px;
  }
`;

export const FuncTextSide = styled.div<{index : number}>`
  width : 100%;
  aspect-ratio: 100 / 83;
  display : flex;

  ${({index}) =>
  index % 2 === 0
    ? `
    order: 1;
  `
    : `
    order: 2;
  `}


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    aspect-ratio: 100 / 100;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    order: 1;
    aspect-ratio : auto;
  }

`;

export const FuncImgSide = styled.div<{i : number}>`
  width : 100%;
  aspect-ratio: 100 / 83;
  display : flex;

  ${({i})=> i%2 === 0 ? 'order : 2;' : 'order : 1;'}

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    aspect-ratio: 100 / 100;
  }

  
  @media (max-width: 767px) {
    aspect-ratio: 100 / 83;
    order: 2;
  }
`;


export const FuncVideoBox = styled.div`

  position: relative;
  width: 100%;
  justify-content : center;
  overflow: hidden;

  border-radius: 12px;
  border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));

  height : 100%;
  

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    aspect-ratio: 100 / 83;
    
  }

`

export const FuncVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    
    
  }

`


export const FuncTextGridColumn = styled.div<FuncTextGridColumnProps>`
  position: relative;
  display: grid;

  -ms-grid-column-span: ${({ desktop }) => desktop};
  grid-column-end: span ${({ desktop }) => desktop};


  ${({index}) =>
  index % 2 === 0
    ? `
    order: 1;
    padding-right : 32px;
  `
    : `
    order: 2;
    padding-left : 32px;
  `}


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    -ms-grid-column-span: ${({ tablet }) => tablet};
    grid-column-end: span ${({ tablet }) => tablet};
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    -ms-grid-column-span: ${({ mobile }) => mobile};
    grid-column-end: span ${({ mobile }) => mobile};
    padding : 0px;
    order : 1;
  }
`;



export const FuncBottomBorder = styled.div`
  padding-top : 64px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding : 0px;
  }
`