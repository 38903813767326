import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/constructionSafety/video/rtlsVideo.mp4";

export default {

    
  helmet : '| 중대재해처벌법 예방 솔루션 : 건설현장 안전관리',
  desc : '중대재해처벌법 예방을 위해 스마트 안전 건설 현장을 구축하여, 작업자의 실시간 위치를 통해 안전을 관리할 수 있습니다. uwb rtls 기술을 활용하여 건설 현장을 안전하게 만들 수 있습니다.',
  keywords : '작업자 안전관리, 건설현장 안전관리, 건설현장, 현장 안전, 건설 안전, 스마트 건설, 중장비 관리, 건설공사 안전관리 종합정보망, 실시간 위치추적, 안전관리, rtls, uwb, orbro, 오브로, 콩테크',

  ogTitle : '',
  ogDesc : '',
  ogSite : '중대재해처벌법 예방 솔루션 : 건설현장 안전관리 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/construction-solution',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDxcFbqyCgsLqX77SjZSbmhPyHShDbN57Y0uVQssyTRIySb5QaO86GwRQU2XDIM6wENVOKG_sn4SuK6BIHu6D3_n9v4DTFhHsRRVUg7UmWsG9qrjE9rGDPe6P0_Ox2um950sgEHtj-KLjzqld-ZdzNkaE9QjgTMEVmj2tkQ1pPVRozYUD-xIl94PQaGNp7tQuO1Hob1FvMuSLLEcdXdUxZmR9CoCl4ArjWfuuwr1d6lvN2umbSY0_y7mc8jTFyCu9Dk5Wi_v3aDRSH29LhUBlpZyeEyM5oCecT8fbW04YC3xCmuCmG41FwPtKf2akaJlFuBsSXINWwYzKejipOUhx5BayplcpMLZKsJoGI_6BI4RTrNIGGFpS4LZqAmgN3JWX43wftmGOlcwJ2np2WFszY2X5QqQdeAdnTXKLzgiYRCcjrM0cHzvzsLebg0KbpyAgCiLawwttYXLmqYEUsw-7it4OWqarLHXZxq4K2hggeMrpWJVFc6R84ruA7y4WPP4lNxVTbcGz3h0tcwGbYv8mGEc5UjMsDalMc8fOmsS5_Dy9qrOewKS-A4oZsW0bBJ1IYnhhHgCvKL4VP19n_PzOnu51vLF_B2xWmnR6pT5F3wa7GapNjG0QEN3185QO4AI9hysQXwb4wLEZqT5dGeHzwRvfIfrp_NCJ6xUbTHt3W070iXbqCG-7liIoaYzwbWYUA_U21h4elbMhPdZqvaIJBQRWBRRKxo8RdjyguhL-3b3LSOd4ffx3PJuvI6T7d5Q89IwZccvi2VLTR2Z5XiTUAveb8J_U3zPTKmIliM2rNcFCQ2zUW61y3Je0voWlaxLRy-_eHoH-tLuixemebIJo770TZZk69mhoNcuzZf0HepRpMoZ_gK7WX5gZ9ybogR9JvNsEQ6do078xyLvTKuZQpri86vobe9PvXeTkVM-_h6agSAcUfUihK0GLiehcw4MjrNaXaq-eqEEEf_aWtfPJAjuJ7JXtFXOiuO6hBMA1-qPq6e_VOOcRJl3PigwW66TzDYgjowTnaD7M1dWDXFYFz_OJS0u8UQbmeKd1TT5h30l70YXeabd4BvtI33YLCL_fJ2ReOIzwUmzZZiEBplwIAxzDcNPvmVwfd5YzvxVGdtm3WEBbzZR0q-MdVvbABRxn90yZ7U2vcwgvNbSTJbC5aWQyI7Jd6LiooIa8nWVkVwaMK_vOmDwotOk2vnamSORh837qPfvlDmUuBX7RRN19heNUGbFF20032J3_JRYcT3AA8eYmTV8LKhQuF0eNQAUe40lPKPGrdrnDHBsjBdHgoOxOHd-ChckYyCFOL3a-FpQv4OO_OpXL6lWWv4SwJsdzXxWT0h2uqoGu0ONpkDv7O3WiZF4E3cQ46IlBsU_K4JWsh1R9pjjLBSRy61vgbvfD4a7yzwU-qvZ6sd5wm6HyVVOU4pmgYoH1SG86ZISmHsUXYxdrWo4vuJDA1If2gUg6P7Y7uMooHmA2MlFPgVsT3xqakmYR-xx3Z3gZnIbcFzO7LldLuSvs8Ha8nMoI9HM2dh6uxK5_9OTfmQRIGHTZngWgCBZrRIz4ARaIxBZiw=w2249-h1298',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '중대재해처벌법 예방 솔루션 : 건설현장 안전관리',
  date : '2023-12-11',
  tag1 : '#중대재해처벌법',
  tag2 : '#노동안전',
  tag3 : '#건설현장',
  tag4 : '#RTLS',
    
  // <br/>
  text : [
    { 
      head : '',
      subHead : '',
      desc :'안전한 건설현장을 구축하기 위해서는 작업자의 안전을 실시간으로 파악하고 사고를 예방하며 발생 시 신속한 대처가 필수적입니다. 특히, 중대재해처벌법을 고려하여 안전사고를 최소화하는 핵심 도구로 위치추적 기술인 ‘실시간 위치추적기술’을 효과적으로 활용하는 것이 중요합니다.',
      dot : ''
    },
    {
      video: video,
      link : '/enterprise/construction/safety',
      head : '1. 실시간 위치추적기술의 핵심 기능',
      subHead :'a. 정확한 위치 파악',
      desc : 'RTLS 기술은 GPS, RFID, UWB 등을 활용하여 건설현장에서 작업자들의 정확한 위치를 실시간으로 추적합니다. 이를 통해 안전한 작업환경을 조성하고 위험 지역을 사전에 파악합니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'b. 작업자 안전 감시',
      desc : '위치 데이터를 기반으로 작업자의 움직임을 모니터링하여 작업 중 안전상태를 실시간으로 확인합니다. 위험한 상황이나 지역을 신속히 감지하여 적절한 조치를 취할 수 있습니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'c. 중장비 감시',
      desc : '작업자 뿐만 아니라, 중장비와 재고들의 위치를 실시간으로 파악하여 현장 상황을 파악할 수 있습니다. 이로써 작업자와의 추돌사고나 위험구역 접근 등을 파악하여 좀 더 안전하게 현장 상황을 통제 및 관리할 수 있습니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'d. 사고 발생 시 신속한 대응',
      desc : 'RTLS 시스템은 사고 발생 시 빠르게 해당 위치를 식별하고 사고 내용을 파악하여 신속한 대응을 가능하게 합니다. 응급 상황에서 효과적인 대처가 가능합니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'e. 데이터 수집과 분석',
      desc : '위치 데이터를 수집하고 분석하여 건설현장의 작업 패턴과 위험 지역을 파악할 수 있습니다. 이를 통해 예방적 대응과 안전 정책의 개선에 기여합니다.',
      dot : ''
    },
    {
      head : '2. 장점',
      subHead :'a. 안전성 강화',
      desc : 'RTLS는 작업자의 정확한 위치 파악을 통해 안전성을 강화합니다. 사고 발생 시 신속한 대응을 통해 중대재해처벌법 준수 및 작업자 안전을 보장합니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'b. 생산성 향상',
      desc : '정확한 위치 정보를 활용하여 작업자의 효율적인 배치와 업무 계획을 수립하여 건설 프로세스의 최적화와 생산성 향상을 도모합니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'c. 비용 절감',
      desc : '사고 예방과 빠른 대응으로 인해 발생하는 손실을 최소화하며, 생산성 향상으로 인한 비용 절감 효과를 가져옵니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'d. 효과적인 자원 관리',
      desc : 'RTLS를 통해 건설현장 내의 자원을 효과적으로 관리하여 불필요한 자원의 낭비를 최소화하고 효율적인 운영을 가능하게 합니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'',
      desc : '안전사고의 최소화와 작업환경 개선을 목표로 한 RTLS 기술은 건설업계에서 더욱 필수적인 역할을 하고 있습니다. 이를 통해 건설현장에서의 안전성과 효율성을 높이는 데 큰 도움이 될 것입니다.<br/><br/>(위의 영상을 클릭하시면 해당 솔루션으로 이동합니다)',
      dot : ''
    },

  ],

  link : {
    page : 'rtls',
    numb : '1',
    product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.workerTracking.key1
        },
      {

      }

    ],
    solution : [
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/enterprise/manufacturing/asset'
      }
    ]

  }


}