export default {
    
    download: "资料下载",

    description: "我们将通过电子邮箱发送资料给您。",
    
    form: {
      emailPlaceholder: "name@company.com",
      policy: {
        info: "通过提供信息，即表示您同意Orbro的",
        link : "个人信息处理政策",
        agree: "。 ",
        check : "[必填]"
      },
    },
    
    cancel: "取消",
    submit: "下载",
    
    
    send: "已将资料发送至您的邮箱。",
    newsletter : "如果您想订阅Orbro的通讯，请填写以下表格！我们会每周向订阅者发送最新新闻和信息。",
    name : "姓名（必填）",
    namePlaceholder : "张三",
    team : "所属单位（必填）",
    teamPlaceholder : "所属单位名称",
    phone : "电话号码（选填）",
    phonePlaceholder : "010-1234-5678",
    skip : "跳过",
    subscribe : "订阅",
    
    ask : "您是否愿意订阅Orbro的通讯以获取技术和其他信息？",
    thanks : "感谢您的订阅。",
    thanksMore : "感谢您订阅Orbro的通讯！我们将定期通过电子邮箱向订阅者发送最新的新闻和信息。与Orbro一起获取更多信息和知识。",
    done : "确认"

  }