import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet : '',
  desc : '공항에서 발생하는 분실, 도난, 장비 찾기 문제 및 리소스 낭비는 일반적인 문제입니다. 이러한 문제를 해결하기 위해 RTLS(실시간 위치 시스템)는 솔루션을 도입할 수 있습니다.',
  keywords : 'RTLS, 자산관리, 자산추적, 테러, 테러방지, 인천공항, 국제공항, 공항, 공항안전, 항공, 항공안전, 보안',

  ogTitle : '',
  ogDesc : '',
  ogSite : '공항에서 사용되는 RTLS :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/airport',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZesBoVKMwIFFry24uoX1306-E2-KIndjXjngk3HQJAqvdeMd-lpFrtUa5bQkacaOFn73rL9aZpISCh6BfSvBY8Rj6pgLzP098EmKNW5WO0k0RSNeqTxeIIE_LiFLF3sUcJc7VhuL6arfAuAQ4APzhaT4aTqEMd8WiAzJwEEVEK3UEevHgYg7ZmwsN-ImqYMFbr01G9VkLQVPdClEaxND6eKem2wvryX-MyUhMvVWmXvao5e8JdFoNh7pmf0ED7MFgYJTg8fxDAxUsbI2jFKwsQCXpbmLsVjR7VcwSNln_gq6BqHDdpB_gte6scXAcPWT5vxnkbmDs22PEDiPDoNcyewtDBBFdtglgRga94O9jfNboErHhvfBncigoMjDxtW7TYVYAps24D2VMURGxOoAx8EquJAIaYIcLlBk2zaSrqdtCeCJGD8Il7sLifXjYw4s7qUr9XFF35LROheqZAC_0kOQ5mI85j5IkNvcvUuT0Wpg9K9Tg1uovCttIBSCD5hfEs1i5gQOxwB7fK0t9kkppR_tDz4HPjIo3i-QfSV3VtiERhnb0gIWUVAFX5jrN8rwX4iTKGpNR55yhogdwRDaSLkHePzB-Xnsn0SwSjXlonVCX02p4Zn02NM8FsMa8fNMfP7Rp4-NIciOdAehNLDjZzwFH6hXgArjIg7_CtUHBeiV5gQ9GX7PqRU4TtZXbljhqePIobwwEd_XHrXRNxAyMxnCU45iqtZUwO_dZyKmhfAZ7XDrUUK0gMaNhguKiZEIm3ZeBZN_082FE9ifEja5-LCI4-QC60gzDs8bYmOrqsotPyR47V6zTrFzRiKDRfwmiLCdbRdQsy3iLV5ph0yhVab492zrx1LaPY39eCtfQzTyDRXrgrwxrAnp54XYMJYZ23TEVgan4Sg7MwiQiYw_Y9z2c1WwAt-TE7rpWOR5Gn2um6jOBrrfo_09UF9nfV4o02ZGF4NWQ99ZIRwBmOg6KODX6mQNtxOjU60pRIpQj0BUCj5JCE5P99E2FRUfxKE89tWwftzf0WpV4XuY3XCnSVpT1DW8OAdsfQyv5GyiSm2ikSlRAq8s0nJAoPv5gcqTR5rwl-QI7YOwucwvhQgM0N3jhPjqcfCsZ0tEP4V-IVLpT1k5_LGmhBueHvi8jZinIgZ0YWZov9jEWK4ysorc637zHhZpy_infWTuHbzXNlLpxoWRsQCbsvGYNKsAVJIi91R85rK5tRbEiMFXA6l67UP2CxWsZHXN_IofYYJmQrT7t6IreFaZrouew2I-5bE-grBY53BCNZ_uk2rE8BPkifZU4ldeMi-0qDyy93SH-XeiDqOWadwsB8yHsrx-6_2UNxAeQzzFEwJtNv0tB4WWtyXgGyX94icliihgFcgXBYPX-t4tGwNJd776YIxod1tvww4WR2a-m5idyeoKDjuWUQrMWHd6JuMKFGGxnIjzUKIQMTx6q2vzwdG62nR80hG1B0_osyET4FeVfNTRdVzDRNcEtrlSSbgcQfMg0Q9LKROQcN6smgad6gObnA6mQB3Ghvo_9zXSkfAufJdY9Kg4g7Px31v1Z2fV3IhA9rzOE5Qg=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  
  title: '공항에서 사용되는 RTLS',
  date : '2023-05-10',
  tag1 : '#RTLS',
  tag2 : '#공항',
  tag3 : '#자산관리',
  tag4 : '#안전',
  
  // <br/>
  text : [
    {
      head : '',
      subHead :'',
      desc : '공항에서 발생하는 분실, 도난, 장비 찾기 문제 및 리소스 낭비는 일반적인 문제입니다. 이러한 문제를 해결하기 위해 RTLS(실시간 위치 시스템)는 솔루션을 도입할 수 있습니다.',
      dot : ''
      
    },
    {
      head : 'RTLS 란?',
      subHead :'',
      desc : 'RTLS(Real-time Location Systems)란 실시간 위치 추적 시스템으로, 물체, 사람 등을 실시간으로 추적, 위치 정보를 수집하고 처리하여 이를 활용할 수 있습니다이로써 공항에서 이동하는 사람, 물건, 항공 기기 등 많은 것들의 움직임을 파악하고 통제 및 관리할 수 있게 하므로, 지난 몇 년 동안 많은 공항에서 자산 추적 시스템을 도입해왔습니다.<br/>실제로 시장 조사에 따르면 2019년 공항 자산 추적 시장의 가치는 3억 5000만 달러 였으며 2027년까지 9억 달러에 이를 것으로 예측되었습니다.<br/>다음으로는 RTLS 시스템이 어떻게 문제 해결에 도움이 되는지 알아보세요.<br/>',
      dot :''
    },
    {
      head : '',
      subHead :'자산 유지 및 관리',
      desc : '공항 직원들은 자산을 추적하기 위해 직접 눈으로 확인하고 종이에 기록해야 했습니다. 이를 태블릿에 기록하더라도 사람이 하는 일에는 항상 오류가 따르기 마련입니다. <br/>이를 태블릿에 기록하더라도 사람이 하는 일에는 항상 오류가 따르기 마련입니다. 동시에 해당 자산의 도난과 기물 파손을 파악할 수 있고 유지, 보수 또한 용이하게 관리할 수 있습니다. 공항의 자산 뿐만 아니라 승객들의 수화물도 통제가 용이해집니다. RTLS는 분실, 도난된 물건을 신속하게 파악하여, 과거에는 낭비되었던 인적, 물적자원을 줄일 수 있습니다.'
    },
    {
      head : '',
      subHead :'안전 강화',
      desc: '공항은 안전과 관련하여 매우 엄격해야 하는 시설입니다. 매일 공항을 통과하는 수천, 수만의 사람들과 자동차 그리고 수화물들을 감시하기란 결코 쉽지 않은 일입니다. 그것도 사람이 직접 하기엔 더더욱 말이죠. <br/> RTLS는 민간인이 들어서면 안되는 구역을 지정하여 모니터링하고 통제하며 이로써 사고 및 테러를 미연에 방지할 수 있습니다. 만약 있어서는 안되는 곳에 수화물이 놓여져 있다면, 관리자는 이에 즉시 대처할 수 있습니다.또한 앞서 언급한 자산 관리에 응급 장비들을 접목시킨다면, 사건 발생 시 소화기 및 제세동기와 같은 안전 장비들을 추적하여  응급 상황시에 더 나은 공항 안전에 기여할 수 있습니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'더 나은 고객 경험',
      desc:'관리자는 공항 내부의 유동 인구를 파악하여 더 원활한 공항 상태를 유지할 수 있습니다. 사람들이 많이 몰리는 시간대와 특정 출입구, 게이트를 파악하여 사람들을 안내 및 통제할 수 있도록 합니다. <br/>이로써 공항을 방문한 개개인은 낭비되는 시간을 줄이고 원활한 비행기 탑승을 할 수 있습니다.',
   },
    {
      head : '',
      subHead :'비용 절감',
      desc :'큰 공항을 한번에 통제 및 관리하기란 쉽지 않습니다. 하지만 RTLS 기술은 기존에 여러 사람이 수집하던(가끔 실수가 섞인) 공항 정보들을 한번에 모니터링 할 수 있습니다. 이로써 이전보다 소요되는 비용은 더 줄이며 서비스의 퀄리티는 더 높이는 결과를 만들어 낼 수 있습니다.',
    },

  ],


  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      }
    ]


  }

}