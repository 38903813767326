import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '| 颠覆建筑工地的智能重型设备管理技术革新',
  desc: '应用实时定位系统（RTLS）技术的重型设备管理解决方案正在革新建筑工地的运营和安全性。这为优化工作时间表、保护资产、创造更安全的工作环境、提高环保性以及节约成本等多种优势带来了可能。',
  keywords: '智能重型设备管理、RTLS技术、建筑工地创新、重型设备追踪、重型设备保护、建筑工地安全、重型设备自动化、RTLS解决方案、重型设备效率、实时定位追踪、建筑行业技术革新、重型设备数据分析、环保建筑、建筑工地成本节省、重型设备管理系统、未来建筑行业、重型设备维护、智能建筑解决方案、RTLS、orbro、Kongtech',

  ogTitle: '',
  ogDesc: '',
  ogSite: '颠覆建筑工地的智能重型设备管理技术革新 :: Orbro博客',
  ogUrl: 'https://orbro.io/cn/blog/heavyEquipment',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDzGmkjkBoP6Xvw8I-rO2ghVp5PqT4G-TOxRZma9x_Alu5navIMlFCn09CKOn81H_Madb-IgbiyiSNy0fEz9PhBPlRbc4NuZIKYHZj06eaqjP7RSKmVw0WhGazP-pzOcpYeCHaToxFMOSXbhipZqKw3T89KUknQiF7z_dhxCgvtwJE1dL27fvS_Ux57UHXUtvl1yiQcS_SQlzL7JwSBg9NoGXqPzzLivQt203pHDtTo0FUNEkkp4ILcoK9jRwzMd6_V0_PZNuKAXYZEcdeZZ7zo7ry82rcT59_D6R41M5GU_XphFwzhYGe38An9N16es0rCgbhvoP2oa8-aOPDswbWK-EAGINoE44O4TUgmG2KRK-tcqpUsNhUu8fau_ZlH_YLSc9gGpv41zNj2Uc-JHSwC074gaimJdd3MAMZlTeDTgyOQDI7OEodIXoFmzzvplifLlBzUpkHxAbA7Chn7YpTus-ZM8OxmGBL2QQuRsApAVQLnHHxEmRZpMUMcRskj0vGevc85DiZukbBv92q6BocAt9lmrz7fCtT6UWFo0Dch3wPd-mxS_vELPx9itBdEhoGU1M0SI-urofonB6RXcgj3VODFNXvmKnUe7MbJCJmSWTz4M3S175wRj8DHoLLYOPbD0M5YJEkTmSiS4aM5mFngNyxx5szGgSIBMp-AY-lzTr1M-1JnEUuC_2oQQT88ax_eS6VXIr-KjlSj8XrAJ-2RwKEGJlCo4i8b5FABD0Vqeg5soicxR_tOy0xkq4W8F8BaEfVtapquvKNAB8zs8lmzXgLUVOYzpqyVjw9_1vvfirMHsCYZZ-J8H4Tx6dRBSoymoWsOinzjd2QV8pv9yiHvCBQMyesmVjWNFZAKUM4xkmIVR9DFmkutgA0zV6VnGwrnMpAzo94Tt1stWAxVdb4R7U7HLpwwMlga6xU5yD2ESMRa13LVj8zrE5JhRmTk8ZOBfqlkrhTNMCLU_HO5YZ3yhttXl1TgbEtmR1ojfgduZMA0WwBpellXtuyIPhMZEK8wykwfrW2i4zq3V5NosBaamzVFwAy0AIJxIuf2P9IG61SM-QSm-4I_kz-KKGUhOtJcaDI7G38qHvGLJU2Ye1AxPX9POfdnfY2RhYoBIxHWdYy9UvZZDI_dgT_NaVsDRaMMaBqYEWlmr1OHV3lBx-BaxmlKUQ_RmMGJApPB-6xN2j5L1h1vKnml06CuPUOJ9CvKGrVZCy38o_KF6O6KXXqDuCE-9wdHvE3cfjefcAv298ML2Sj2b25ugq0KbO96LKoRz61WklczBoCAouwaFpenu7xf7lCIfXVskkq8AC8UaHtC1kVooqQfyOLLxG6B1skFGcUudDX7TQY2IDywQ-213Y8BUgP1BXa0mlj2fHovr0munoE2r5VngsHXQGWnYNgAIlEGQvwqL52-7v5a2RShdVo8_4d0gV0-MHBMv0akT60cfaDBukms6Lk0hX_kk20AyF2jsLu3tPcty3RIi7VZgSOyP83n-Ax6ideyVryy31tfTJIr_vqMPpQX2rhm7iQ4PBGgopMM6PUYzqUMeL9IX3t2qA0VD_tHim2kKdE17NV9ffiDP2UmH4gdzjw=w2114-h1166',
  ogType: '网站',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '颠覆建筑工地的智能重型设备管理技术革新',
  date: '2023-09-25',
  tag1: '#智能重型设备',
  tag2: '#重型设备管理',
  tag3: '#建筑重型设备',
  tag4: '#RTLS',

  text: [
    {
      head: '',
      subHead: '',
      desc: '现代建筑行业正在不断寻求更智能和高效的运营方式，智能重型设备管理解决方案是其中关键的一部分。特别是那些集成了实时定位系统（RTLS）技术的解决方案，正大幅改进建筑工地的运营和安全性。本文将深入探讨RTLS技术对重型设备管理的创新影响以及其众多优势。',
      dot: ''
    },
    {
      head: '什么是RTLS技术？',
      subHead: '',
      desc: 'RTLS（Real-Time Location System）是实时定位系统的缩写，它是一种能够实时跟踪、监控和管理重型设备和资产准确位置和状态信息的技术。这项技术与全球定位系统（GPS）、射频识别技术（RFID）、超宽带技术（UWB）、无线网络等各种技术相结合使用。',
      dot: ''
    },
    {
      head: '使用RTLS进行重型设备管理的优势',
      subHead: '',
      desc: '将RTLS技术应用于重型设备管理提供以下创新性优势：',
      dot: ''
    },
    {
      head: '',
      subHead: '1. 实时位置信息',
      desc: 'RTLS技术能够实时追踪重型设备的精确位置。这使得可以实时监控设备的当前位置和移动路径，有助于快速定位和使用所需设备，同时优化工作时间表。',
      dot: ''
    },
    {
      head: '',
      subHead: '2. 重型设备的保护与管理',
      desc: '重型设备是建筑项目的核心资产之一，其保护和管理是至关重要的任务。通过利用RTLS，可以预防重型设备被盗，建立预防性维护和服务计划，以预防故障。',
      dot: ''
    },
    {
      head: '',
      subHead: '3. 优化工作时间表',
      desc: '利用重型设备的实时位置信息，可以优化工作时间表。迅速找到所需设备有助于防止工作延误并提高生产力。',
      dot: ''
    },
    {
      head: '',
      subHead: '4. 创建安全的工作环境',
      desc: 'RTLS技术有助于提高工人的安全性。共享重型设备的位置信息有助于工人预防与设备的碰撞，创造安全的建筑环境。',
      dot: ''
    },
    {
      head: '',
      subHead: '5. 自动化和高效化',
      desc: 'RTLS技术在自动化和优化重型设备管理方面发挥着重要作用。它能够实时监测设备状态并自动生成报告，简化业务流程并节省人力和时间。',
      dot: ''
    },
    {
      head: '',
      subHead: '6. 数据分析和决策支持',
      desc: 'RTLS系统生成大量数据，有助于分析并改善建筑项目的性能。例如，评估和改进设备利用率。',
      dot: ''
    },
    {
      head: '',
      subHead: '7. 环保',
      desc: '有效管理重型设备有助于减少能源消耗并节省资源。这有助于使建筑项目更环保。',
      dot: ''
    },
    {
      head: '',
      subHead: '8. 成本节省',
      desc: '使用RTLS技术可以降低重型设备管理和维护成本。通过预防性维护和服务的优化，可以最小化故障，同时利用与设备租赁和购买相关的数据来优化预算。',
      dot: ''
    },
    {
      head: '总结',
      subHead: '',
      desc: 'RTLS技术为建筑工地的智能重型设备管理提供了革新性的解决方案，可实现工作时间表的优化、资产的保护、安全的工作环境、环保性以及成本节省等多种优势。因此，RTLS技术有望在建筑行业的未来发挥重要作用。它将改变建筑工地的运营方式，为更智能的未来开辟道路。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: '此产品可通过安装UWB终端设备在工人或设备上，以支持实时位置追踪。',
        img: productImg.product.assetTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionEquipment.thumb,
        data: 'constructionEquipment',
        link: '/cn/enterprise/construction/equipment'
      },
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      }
    ]


  }


}
