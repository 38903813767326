import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

   
  helmet : '',
  desc: "RTLSソリューションは、第4次産業時代におけるビジネス成長を促す素晴らしい選択です。 したがって、正しいRTLSソリューションを選択することが重要です。 以下はRTLSソリューションを選択する際に考慮すべき事項です。",
  keywords:"RTLS、第4次産業革命、事業成長、位置測位、室内位置測位",

  ogTitle : '',
  ogDesc : '',
  ogSite:"RTLSソリューションを選択する際に考慮すべき事項 :: オブローブログ",
  ogUrl : 'https://orbro.io/jp/blog/consider',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZcwlDQZU5H5qnmKjPCdFPeFV8_CDJMRUQZgpa1f1gRZkrk-n6YpwPszumPmKOPJnJFIlJShKqh_Q5wFcv3gmUpnEWmtobQ0b3vFCWKOqFl_3v4hkpS_t9J8Xii17KJEpKeSLt8T_ieoIYrWTgkSw35wq4vyGsWME_m4RNxlrtv45F_VObAxOwJ2GUEWq1wC4Bs4iSvdnNVnw7beJQ6VDOIpKyialic3nfvYBuzj7Cg7a1ZYNEz91Gw4ifCkDL5XMf6yIg9It_aR4VGT_qnUW5E1tAjOeYZvjX16A8i_6CapXmgXxT18PWHgjLwU5B7aLKeBiLI4OfYkeru88hyuTajrjyWXAMAexmkeM5hK9gE6163DFPgr7dn3EpQnHhwvl537Gw-aeQAgEoMXoKNNfjmhnoGbjBur1yDSA81JEh2fujJMtlIwoVQQYgeHQhvqh5ZbNAc1kJWx3u8Vu85TMLDudxXtCmkAAsJpEnKsK8H0f8FOKZnAnWvHCjZOfyaQVDCFqL5G-NBcjQl5f_XbGdizmytaouj5Qh7_bD-oWnmEzXhhIzW47zMmDei8tGOR6Mf3tj2Yc7rgoIV5u6sM-u7rkrwtIUq3ZDcLi4bjM3CI0nPMqFQKAnnSK3WuDHZi5gI7lgg4Z2fpGM446qKEFp7RlMo40wdQ3dDGM4onXDAOn8nsbvAD8RpJY6Oh88j_CclPLbtwl8MgE2mMC0lq3t9fPP6FKz1rc894KGlbpYh-CxCrSHow-eGoM5WzKfGCDI45s0Pd304BDuBsizABdkcHVyUuLIefrfFdm1xB4Dx42ostYrLcccYZbim5zxXfiDXFHxeydWnEaR_3JlR0yLvJ1YxiuPWEQJ39vr6QFMQh6vOM9-hIG5V2W9tZl1BldMwlHpqfXqfHX8QxTHXYKNVJ6okDuOqQBSoXQNnZyi45gSvhXcAFGXDnHVj07MF4L6OTTd9iGg8xBCKBnlB4glo7twHHpVLj2LTKNkQQoNsDTtWsTCqPeXsAGgwshNgiKy-SEHSPR4kzyBhzgs8mhVNGvnAKUDr_p9iOH56Ng_3rWMKm9eF2wubbi5ffi4M6O5SnQYfTqYo9ZN0fBibaVoDzfSxXS6sBZGsfWbmgHxVi5Nsmq6D-HCo6yfecXsHiCemyaUS2VxqGt6Sxq_uN4FeFNCeOUUXfUro_qyrrs8cnSxmC3jetAkdPXpA_KIukOkttU1TDuvHOKiRRd48YccqjhRT1LRh5iIRBYiowrDQi7ewnWTYajwzWPUqm7ygPvZR9qYdK2l76JMFxA8Ucznef6UP-ApuWqRaYpANDZYzkoussTQhGn2mtDmbH41EMoGLY7a8G9r9ghA4YWVFM9hb3U2YLujjDmKFRnntRhskbgxpOK01aYvoDSGH5iKKj3K6ggNfC9QqvCX31JrrJadskNS_1lsqfgZYTVRlQ4-Vhx48TXtAo-rpwhyFWECUbGgjVzThWzmNQheWVWbRH1WoNx5nnnL6skFUK2y-QqdTJPm_XtkUqwkblpJvRGIxrV53uYlfug204LpUQfGgcIRn5zCsmN7KyyQ9ANKRMygJmQ=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
  
    
  title: 'RTLSソリューションを選ぶ際に考慮すべき事項',
  date: '2023-05-19',
  tag1: '#RTLS',
  tag2: '#第4次産業革命',
  tag3: '#ビジネス成長',
  tag4: '#屋内位置測位',
    
  text: [
    {
      head: '',
      subHead: '',
      desc: '第4次産業革命（Industry 4.0）の到来により、製品の製造、改善、流通方法において急激な変化がもたらされました。その中で先駆者として多くの企業がRTLSを新たなソリューションとして採用しています。この技術は最近、急速な成長と共に多くのサービスで中枢的な役割を果たし始めました。',
      dot: ''
    },
    {
      head: 'RTLSとは？',
      subHead: '',
      desc: 'RTLSはリアルタイムロケーションシステム（Real-Time Location System）の略です。RTLS技術で収集した現実のデータを活用して仮想のマップを作成することができます。そして、このシステムは扱いやすい形式で処理され、管理者に意味のあるデータを提供します。',
      dot: ''
    },
    {
      head: 'RTLSの利点',
      subHead: '',
      desc: 'RTLSを導入した企業は、そうでない企業よりも多くの利点を享受することができます。従業員の割り当て、在庫管理と測位、安全プロトコルの開発などが可能です。また、他のIoTデバイスとも通信できるため、さまざまなサービスを効果的に実現することができます。RTLSは小売業、ホスピタリティ、医療など、さまざまな産業でも活用されています。例えば、病院では、RTLSを使用してスタッフの配置状況、病院ベッドの可用性、医療機器や医薬品の位置を把握することができます。このような幅広いアプリケーションは、RTLSを非常に急速に成長する新しい市場にしました。＂futuremarketinsights＂によれば、2022年には46億ドルだったRTLS市場は、2032年には373億ドルに成長すると予測されています。',
      dot: ''
    },
    {
      head: 'RTLSの実装時の考慮事項',
      subHead: '',
      desc: 'RTLSを実装する際には、ビジネスの要件をよく区別して計画する必要があります。次に、RTLSが効果的に実装されるようにするための考慮事項を紹介します。',
      dot: ''
    },
    {
      head: '',
      subHead: '個人的なニーズ',
      desc: '従来のGPSは世界中のどこでも資産のリアルタイムの位置を提供できますが、精度は5〜20m以内に限られており、屋内環境ではあまりにも不正確です。RFIDの精度は高いですが、動作範囲が制限されており、大規模な施設では何千もの受信器タグを使用する必要があります。他のRTLS技術には、その中に含まれるWi-Fiなどがあり、それぞれの技術には長所と短所があります。したがって、施設に対するRTLSを選択する際には、次の点を考慮する必要があります。',
      dot: {
        a: '• <strong>ターゲット</strong>: 人や資産を測位していますか？それとも両方を測位していますか？ウェアラブルタグやモバイルデバイスを使用して測位する予定ですか？どれくらいのデバイスを測位する予定ですか？',
        b: '• <strong>精度</strong>: RTLSはどれくらいの精度が必要ですか？特定の領域に資産があるかどうかを簡単に把握できるだけで十分ですか、それとも1m未満で資産の正確な位置を知る必要がありますか？',
        c: '• <strong>待ち時間</strong>: 測位はリアルタイムでどれくらい必要ですか？10〜20秒の待ち時間を要件に応じて設定できますか？それともより正確な情報が必要ですか？'
      }
    },
    {
      head: '',
      subHead: '適切なサプライヤーの選定',
      desc: '成功した技術の導入には、適切なサプライヤーの選択が重要です。RTLSの企業は、実績があり、あなたの要件を理解している必要があります。また、問題が発生した場合にどのようにサポートを利用するかについて幅広いトレーニングを提供することも求められます。将来に備え、拡張性の高いRTLSを選択することも重要です。また、RTLS技術の変化と成長の速度に合わせて、あなたのビジネスに適用できる能力を持つサプライヤーを見つけることも重要です。',
      dot: ''
    },
    {
      head: '',
      subHead: '予算とROI',
      desc: 'RTLSシステムに対する予算を決定することは、特に他の利害関係者が承認する必要がある場合、複雑なプロセスになる場合があります。まず、トラッキングタグやロケーターの調達費用、設置費用、IT統合費用、クラウドストレージや一般的な運用保守など、プロジェクトの各部分のコストを詳細に分析します。RTLSの導入と運用のための予算を立てた後、将来の10年間の予想ROI（総所有コスト）を決定します。TCO計算には、追加の収益だけでなく、効率性の向上による運用コストの削減も考慮する必要があります。',
      dot: ''
    },
    {
      head: '',
      subHead: '',
      desc: '',
      dot: ''
    }
  ],
  
    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/jp/enterprise/construction/asset'
        }, 
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/jp/enterprise/construction/safety'
        },
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/jp/enterprise/logistics/asset'
        }
      ]
  
    }
  
  }