import { IconOsOff, IconOsOn } from '@assets/images/main/icon';
import { industryOn, industryOff, tagOn, tagOff, assetTagOn, assetTagOff, assetOn, assetOff } from '@assets/images/main/index';

export default {
  bannerData: [
    {
        icon: assetOn,
        iconInv: assetOff,
        image: '',
        iconText: '蓝牙BLE<br/>定位追踪',
        sub: 'New',
        link: '/cn/beacon/ble'
    },
    {
      icon: industryOn,
      iconInv: industryOff,
      image: '',
      iconText: '工业信标',
      sub: '',
      link: '/cn/beacon/industry'
    },
    {
      icon: tagOn,
      iconInv: tagOff,
      image: '',
      iconText: '小型信标',
      sub: '',
      link: '/cn/beacon/tag'
    },
    {
      icon: assetTagOn,
      iconInv: assetTagOff,
      image: '',
      iconText: '资产用标签',
      sub: 'New',
      link: '/cn/beacon/asset-tag'
    },
    {
      icon: IconOsOn,
      iconInv: IconOsOff,
      image: '',
      iconText: 'ORBRO OS',
      sub: 'New',
      link: '/cn/os'
    },

  ],
}
