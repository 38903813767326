
import { StyledTechnologyGroup, StyledTechnologyText, StyledTechnologyTitle } from "@pages/Enterprise/Technology/Technology.style";
import { CommonState } from "@redux/reducers/commonReducer";
import React from "react"; 
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";


import AdvantageWhite from "@pages/Enterprise/Technology/Common/Advantage/AdvantageWhite";

import Advantage, { AdvantagePropsItem } from "@pages/Enterprise/Technology/Common/Advantage/Advantage";
import {StyledSVLabel, StyledSVLabelTop, 
  StyledSVImgBoxTwo, StyledSVImg , StyledSVImgBox, 
  StyledSVImgBoxThree, StyledTechnologyGroupNoBar, StyledSVImgGray, 
  StyledSpacing24, StyledSpacing16} from "./StereoVision.style";


import {SVImgL, SVImgLTablet, SVImgLMobile, 
  SVImgR, SVImgRTablet, SVImgRMobile, 
  SVImgM, SVImgMTablet, SVImgMMobile, 
  SVImgEstimate, SVImgEstimateTablet, SVImgEstimateMobile, 
  SVImgDeep, SVImgDeepTablet, SVImgDeepMobile, 
  SVImgFineL, SVImgFineLTablet, SVImgFineLMobile, 
  SVImgFineR, SVImgFineRTablet, SVImgFineRMobile, 
  SVImgA, SVImgATablet, SVImgAMobile, 
  SVImgB, SVImgBTablet, SVImgBMobile, 
  SVImgC, SVImgCTablet, SVImgCMobile, 
  SVImgD, SVImgDTablet, SVImgDMobile, 
  SVImgCamA, SVImgCamATablet, SVImgCamAMobile, 
  SVImgCamB, SVImgCamBTablet, SVImgCamBMobile, 
  } from "@assets/images/technology/stereo";


import {Icon3DLine, IconVector, IconCalc
    , IconRulerInv, IconMaxInv, IconCreditInv
  } from "@assets/images/icon";

import { StyledDisplay, StyledHeading, StyledParagraph } from "@components/Text";
import { VideoBox, VideoPlay } from "@pages/Enterprise/Solution/components/Video/Video.style";
import video from  "@src/assets/images/solution/retailExhibition/video/rtlsVideo.mp4";
import { useLocation } from "react-router-dom";
import { Spacing } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
  
interface layoutImg {
  desktop: string,
  tablet: string,
  mobile: string
}

const imgL: layoutImg = {
  desktop: SVImgL,
  tablet: SVImgLTablet,
  mobile: SVImgLMobile
}

const imgR: layoutImg = {
  desktop: SVImgR,
  tablet: SVImgRTablet,
  mobile: SVImgRMobile
}

const imgM: layoutImg = {
  desktop: SVImgM,
  tablet: SVImgMTablet,
  mobile: SVImgMMobile
}

const imgEst: layoutImg = {
  desktop: SVImgEstimate,
  tablet: SVImgEstimateTablet,
  mobile: SVImgEstimateMobile
}

const imgDeep: layoutImg = {
  desktop: SVImgDeep,
  tablet: SVImgDeepTablet,
  mobile: SVImgDeepMobile
}

const imgFineL: layoutImg = {
  desktop: SVImgFineL,
  tablet: SVImgFineLTablet,
  mobile: SVImgFineLMobile
}
const imgFineR: layoutImg = {
  desktop: SVImgFineR,
  tablet: SVImgFineRTablet,
  mobile: SVImgFineRMobile
}

const imgA: layoutImg = {
  desktop: SVImgA,
  tablet: SVImgATablet,
  mobile: SVImgAMobile
}
const imgB: layoutImg = {
  desktop: SVImgB,
  tablet: SVImgBTablet,
  mobile: SVImgBMobile
}
const imgC: layoutImg = {
  desktop: SVImgC,
  tablet: SVImgCTablet,
  mobile: SVImgCMobile
}
const imgD: layoutImg = {
  desktop: SVImgD,
  tablet: SVImgDTablet,
  mobile: SVImgDMobile
}


const imgCamA: layoutImg = {
  desktop: SVImgCamA,
  tablet: SVImgCamATablet,
  mobile: SVImgCamAMobile
}
const imgCamB: layoutImg = {
  desktop: SVImgCamB,
  tablet: SVImgCamBTablet,
  mobile: SVImgCamBMobile
}




const howWorks:AdvantagePropsItem[] = [
  { 
    icon: Icon3DLine, 
    title: "stereo.howItWorks.card.detection.title",
    description: "stereo.howItWorks.card.detection.description",
  },
  { 
    icon: IconVector, 
    title: "stereo.howItWorks.card.estimation.title",
    description: "stereo.howItWorks.card.estimation.description",
  },
  { 
    icon: IconCalc, 
    title: "stereo.howItWorks.card.calculation.title",
    description: "stereo.howItWorks.card.calculation.description",
  },
]



const advantageItems:AdvantagePropsItem[] = [
  { 
    icon: IconRulerInv, 
    title: "stereo.advantage.items.accuracy.title",
    description: "stereo.advantage.items.accuracy.description",
  },
  { 
    icon: IconMaxInv, 
    title: "stereo.advantage.items.position.title",
    description: "stereo.advantage.items.position.description",
  },
  { 
    icon: IconCreditInv, 
    title: "stereo.advantage.items.security.title",
    description: "stereo.advantage.items.security.description",
  },
]







const StereoVisionContent = () => {

  // Redux
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const location = useLocation();

  const getLang = () => {
    if (location.pathname.includes('jp')){
      return '/jp';
    } else if (location.pathname.includes('cn/')){
      return '/cn';
    } else if (location.pathname.includes('en/')){
      return '/en';
    } else {
      return '';
    }
  }


  return (
    <>
      <StyledTechnologyGroup>
        <StyledTechnologyTitle size="small"><Trans i18nKey={ "stereo.tech.title" }/></StyledTechnologyTitle>
        <StyledTechnologyText size="medium">
          <Trans i18nKey={"stereo.tech.text"} />
        </StyledTechnologyText>

        <DivAllCenter>
          <StyledParagraph size="medium">
            <Trans i18nKey={"simple.svVideoDesc"}/>
          </StyledParagraph>
          <a href={`${getLang()}/enterprise/retail/exhibition`} target="_blank" rel="noopener noreferrer">        
            <Spacing pixel="24"/>
            <VideoBox>
              <VideoPlay autoPlay loop muted playsInline>
                <source src={video} type="video/mp4" />
              </VideoPlay>
            </VideoBox>
          </a>
        </DivAllCenter>
      </StyledTechnologyGroup>

        

      <StyledTechnologyGroup>
        <StyledTechnologyTitle size="small"><Trans i18nKey={ "stereo.feature.title" }/></StyledTechnologyTitle>
        <StyledTechnologyText size="medium">
          <Trans i18nKey={"stereo.feature.text"} />
        </StyledTechnologyText>
      </StyledTechnologyGroup>



      <StyledTechnologyGroup>
        <StyledTechnologyTitle size="small"><Trans i18nKey={ "stereo.howItWorks.title" }/></StyledTechnologyTitle>
        <AdvantageWhite title={"stereo.advantage.title"} items={howWorks} />

      </StyledTechnologyGroup>
        


      <StyledTechnologyGroupNoBar>
        
        <StyledDisplay size="small"><Trans i18nKey={ "stereo.core.items.title" }/></StyledDisplay>
        <Spacing pixel="24"/>

    
        <Spacing pixel="8"/>
        <StyledHeading size="xsmall"><Trans i18nKey={ "stereo.core.items.streaming.title" }/></StyledHeading>
        <Spacing pixel="16"/>
        <StyledParagraph size="medium"><Trans i18nKey={ "stereo.core.items.streaming.description" }/></StyledParagraph>
        <Spacing pixel="36"/>
        <StyledSVLabelTop size="small"><Trans i18nKey={ "stereo.core.items.streaming.subDescription" }/></StyledSVLabelTop>
        
        <StyledSVImgBoxThree>
          <div>
            <StyledSVImg src={imgL[layoutType]} alt="Stereo Vision"/>
            <StyledSVLabel size="small"><Trans i18nKey={ "stereo.core.items.streaming.imgLabel1" }/></StyledSVLabel>
          </div>
          <div>
            <StyledSVImg src={imgM[layoutType]} alt="Stereo Vision"/>
            <StyledSVLabel size="small"><Trans i18nKey={ "stereo.core.items.streaming.imgLabel2" }/></StyledSVLabel>
          </div>
          <div>
            <StyledSVImg src={imgR[layoutType]} alt="Stereo Vision"/>
            <StyledSVLabel size="small"><Trans i18nKey={ "stereo.core.items.streaming.imgLabel3" }/></StyledSVLabel>
          </div>
        
        </StyledSVImgBoxThree> 


        <Spacing pixel="8"/>
        <StyledHeading size="xsmall"><Trans i18nKey={ "stereo.core.items.estimation.title" }/></StyledHeading>
        <Spacing pixel="16"/>
        <StyledParagraph size="medium"><Trans i18nKey={ "stereo.core.items.estimation.description" }/></StyledParagraph>
        <Spacing pixel="36"/>
        
        <StyledSVImgBox>
          
            <StyledSVImg src={imgEst[layoutType]} alt="Stereo Vision"/>
            <StyledSVLabel size="small"><Trans i18nKey={ "stereo.core.items.estimation.imgLabel" }/></StyledSVLabel>

        </StyledSVImgBox> 

        <StyledSpacing16/>

        <StyledParagraph size="medium"><Trans i18nKey={ "stereo.core.items.estimation.description2" }/></StyledParagraph>
        <Spacing pixel="36"/>
        
        <StyledSVImgBox>
          
            <StyledSVImg src={imgDeep[layoutType]} alt="Stereo Vision"/>
            <StyledSVLabel size="small"><Trans i18nKey={ "stereo.core.items.estimation.imgLabel2" }/></StyledSVLabel>

        </StyledSVImgBox> 



        <StyledSpacing16/>

        <Spacing pixel="8"/>
        <StyledHeading size="xsmall"><Trans i18nKey={ "stereo.core.items.fineTuning.title" }/></StyledHeading>
        <Spacing pixel="16"/>
        <StyledParagraph size="medium"><Trans i18nKey={ "stereo.core.items.fineTuning.description" }/></StyledParagraph>
        <Spacing pixel="36"/>
        
        <StyledSVImgBoxTwo>
          
            <StyledSVImg src={imgFineL[layoutType]} alt="Stereo Vision"/>
            <StyledSVImg src={imgFineR[layoutType]} alt="Stereo Vision"/>

        </StyledSVImgBoxTwo> 

        <StyledSVLabel size="small"><Trans i18nKey={ "stereo.core.items.fineTuning.imgTag" }/></StyledSVLabel>
        <StyledSpacing24/>

        <StyledParagraph size="medium"><Trans i18nKey={ "stereo.core.items.fineTuning.description2" }/></StyledParagraph>
        <Spacing pixel="36"/>
        
    
        <StyledSVImgBoxTwo>
          <div>
            <StyledSVImg src={imgA[layoutType]} alt="Stereo Vision"/>
            <StyledSVLabel size="small"><Trans i18nKey={ "stereo.core.items.fineTuning.imgLabela" }/></StyledSVLabel>
          </div>
          
          <div>
            <StyledSVImg src={imgB[layoutType]} alt="Stereo Vision"/>
            <StyledSVLabel size="small"><Trans i18nKey={ "stereo.core.items.fineTuning.imgLabelb" }/></StyledSVLabel>
          </div>

        </StyledSVImgBoxTwo> 

        
        <StyledSVImgBoxTwo>
          <div>
            <StyledSVImg src={imgC[layoutType]} alt="Stereo Vision"/>
            <StyledSVLabel size="small"><Trans i18nKey={ "stereo.core.items.fineTuning.imgLabelc"}/></StyledSVLabel>
          </div>
          <div>
            <StyledSVImg src={imgD[layoutType]} alt="Stereo Vision"/>
            <StyledSVLabel size="small"><Trans i18nKey={ "stereo.core.items.fineTuning.imgLabeld" }/></StyledSVLabel>
          </div>

        </StyledSVImgBoxTwo> 


        <StyledSVLabelTop size="small"><Trans i18nKey={ "stereo.core.items.fineTuning.imgLabel" }/></StyledSVLabelTop>

        <StyledSpacing16/>

        <StyledParagraph size="medium"><Trans i18nKey={ "stereo.core.items.fineTuning.description3" }/></StyledParagraph>
        <Spacing pixel="36"/>
        <Spacing pixel="8"/>
        <StyledHeading size="xsmall"><Trans i18nKey={ "stereo.core.items.distance.title"}/></StyledHeading>
        <Spacing pixel="16"/>
        <StyledParagraph size="medium"><Trans i18nKey={ "stereo.core.items.distance.description" }/></StyledParagraph>
        <Spacing pixel="36"/>



        <StyledSVImgBoxTwo>
          <div>
            <StyledSVImgGray src={imgCamA[layoutType]} alt="Stereo Vision"/>
            <StyledSVLabel size="small"><Trans i18nKey={ "stereo.core.items.distance.imgLabela"}/></StyledSVLabel>
          </div>
          <div>
            <StyledSVImgGray src={imgCamB[layoutType]} alt="Stereo Vision"/>
            <StyledSVLabel size="small"><Trans i18nKey={ "stereo.core.items.distance.imgLabelb"}/></StyledSVLabel>
          </div>

        </StyledSVImgBoxTwo> 
        <StyledSVLabelTop size="small"> <Trans i18nKey={"stereo.core.items.distance.imgLabel"} /> </StyledSVLabelTop>


        <StyledSpacing16/>

        <StyledParagraph size="medium"><Trans i18nKey={ "stereo.core.items.distance.description2" }/></StyledParagraph>
        <Spacing pixel="36"/>
        

        <Advantage 
          title={`stereo.advantage.title`}
          items={advantageItems} 
        />


      </StyledTechnologyGroupNoBar>



    </>
  );
};

export default StereoVisionContent;