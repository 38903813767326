import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| 건설현장을 안전하고 스마트하게 만드는 RTLS 솔루션',
  desc : '스마트한 건설현장은 생산성과 효율을 높이고 작업자의 안전을 지켜줍니다. RTLS 기술은 이러한 스마트 건설현장 시스템 구축이 가능하도록 돕는 가장 적절한 방안이 될 수 있습니다.',
  keywords : '작업자 안전관리, 건설현장 안전관리, 건설현장, 현장 안전, 건설 안전, 스마트 건설, 중장비 관리, 건설공사 안전관리 종합정보망, 실시간 위치추적, 안전관리, rtls, uwb, orbro, 오브로, 콩테크',

  ogTitle : '',
  ogDesc : '',
  ogSite : '건설현장을 안전하고 스마트하게 만드는 RTLS 솔루션 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/constructionSafety',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4ODZOuIwgi2GmgeN38XOohY2I6ZhTq1OX26xq5S1gJqAGQsMoshxsOsqKhEqbIAKQ4m2i0wnTaha9gJzWrOTQJjh-kG35v_PKDbGn6oQKyjdPrG83wKwq9aDBoWxLoDMDp5REkLIfvg86TqENuhdkKlvaCE8JtLLjZtoMWuiSQCYZYRXIk1QRwMiLlKrLdUR3Ae7WNDbQamCAEzOhA4iB7NibqGzN1cFrlfeKXbBN95eOv_6gdNvv8egQ9WRKK1ABThUboxhbiTLeGy74mZO217xUHPe9qAWl_BitXQj3yMJXo_3BQUooKtjvw8Lm1OAObOJX5ZUIyV_DF_WDnsYCP6ypezD9GmdGsasbiagdvp9zxbH2CvrIBSLYhNFv34TTAiztM6BukR-0OJInkIZHvaJQyilhLrE44lrqa8M3IzDhzWEOK-bb3Tz5mXAe370UpbaXxkMHaEJS72MZgBbyUj-J3f7J889zkMt7U7UffPNi4BGFhgb5prG4kOatNmsgh8pmVHsAk4xHZ8Fewny7ASDPV2Q4z9YuGgCynJaNDxYwb3FurwZ8G-Nfwgdt2KfslDc1yQ5I-IX-4gd8DTlSCr596Caq2a1UsBSEyl4J9lioE1CFUj22KymcIVvSBhkp285DCyho_1lztbytT6HMUyMOyaMMvEyNnug6qmzJeQEVITT9AqDTwv4crACqyBUCZXmdgcBNa45zkwL_Jsx1akoJohEvzXJ7qMFfZHXmPapV4uS5jSmJGSkvVPCjjhTqGFvy0NDStFwvdrwqGHnI34q1-p2wTpJG2kvUhUH7grFZhik9E7V--QY6HF4fkAjtjgmSiY3mrf4qGOxawVFTvH0n5R4fppcdngOJRnpXyeNUIm-1L8-Qe_A7xZl04bFY7AMwjt9u-DeXWOdd6buaBYjig4EHIovTR2IeuTI2BtFyttqCDwpea3cJuouIq4VZ_m8QEFTUGrq8hkviXmE8iVc80a_vpEfJebHjzWM5f48cjhEk-LVjsrECpYd1lU9TYRV3a6mLY0VxcVpNdN8rxI7cQe5YgRBJGSHR4ECjku3U9jvPJeMJ7hJ4V9D8donENtGVDWuiVPXUk6RH9XU9yeVxMTApzrsB58tA03IPp8f2Aejpzr8ksllmvuubwwbr3JXX6nqREowvgldTiKTGrvT0ca1ol6reTjqX5Xr06c0n5F2v-aYGnPNajdoQNBxtUcxpQRVd-yKyJryJXz56r_J6c2QiiVIADQMjYUH9ULyFT0d0UvnwJS4cTdqEGBzUoJNTLZZi2JtD3cJvG8vrrz5xHUsR2SIkyNjCGLZyOouOh7etxxjVK_MNFYqDGli4SQhp48hMgCc56c4vMuWmZXZlSgUueLUUtEBsLpUFaTERHwqNvJaRHFJOpIyNIOsibD94KowEbCGWg9pydqRNrAdY7TQ7HbAUqUTWFMdCD4Kzkdx4cKPIzL9YTVCvBtk23t0a9E343R1x38JavpVlYeMQVxXxDG5jpP5O9lXmBitXgEfDHBmTnT_BUAvgjYjkUG5djwJfZ6E8K_FRHXtgkB-M62IYcrVsaZAd_9c1Mq8YKMP-fJ=w2052-h1199',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '건설현장을 안전하고 스마트하게 만드는 RTLS 솔루션',
  date : '2023-09-19',
  tag1 : '#작업자안전관리',
  tag2 : '#건설현장안전',
  tag3 : '#스마트건설',
  tag4 : '#중장비관리',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'건설 산업은 현대 사회의 발전과 더불어 커다란 역할을 수행하고 있으며, 이에 따라 건설현장의 효율성과 안전성을 높이기 위한 기술의 도입이 중요한 과제로 대두되고 있습니다. 최근에는 실시간 위치추적 시스템(RTLS) 기술이 건설현장에서의 혁신적인 변화를 가져오고 있으며, 이 기술의 도입이 스마트한 건설현장을 구축하는 데 중요한 역할을 하고 있습니다. 이 블로그에서는 RTLS 기술의 개념과 이를 통해 어떻게 스마트한 건설현장을 구축할 수 있는지에 대해 알아보겠습니다.',
      dot : ''
      
    },
    
    {
        head : 'RTLS 기술의 개념',
        subHead : '',
        desc :'실시간 위치추적 시스템(RTLS)은 무선 통신 기술을 활용하여 물체나 개체의 정확한 위치를 실시간으로 추적하는 기술입니다. RTLS는 GPS, RFID(Radio-Frequency Identification), UWB(Ultra-Wideband), BLE(Bluetooth Low Energy) 등 다양한 기술을 활용하여 위치 정보를 수집하고 관리합니다. 건설현장에서는 이러한 기술을 활용하여 장비, 재료, 인력 등을 효율적으로 관리하고 모니터링할 수 있습니다.',
        dot : ''
        
      },
      {
        head : 'RTLS 기술의 장점',
        subHead : '',
        desc :'RTLS 기술을 건설현장에 도입하는 것은 여러 가지 이점을 가져옵니다.',
        dot : {
            a :' • <strong>자산 및 장비 관리</strong> : RTLS를 통해 건설장비와 자재의 위치와 상태를 실시간으로 파악할 수 있어, 재고 관리와 유지보수를 효율적으로 수행할 수 있습니다.',
            b :' • <strong>안전성 향상</strong> : 작업자의 위치를 실시간으로 모니터링하여 안전사고를 예방하고, 긴급 상황 시 신속한 대응이 가능합니다.',
            c :' • <strong>생산성 향상</strong> : 건설작업의 일정과 작업량을 관리하여 생산성을 향상시킬 수 있으며, 과도한 시간과 자원의 낭비를 방지합니다.',
            d :' • <strong>데이터 분석과 예측</strong> : RTLS는 데이터를 수집하고 분석하여 건설 작업의 패턴을 이해하고 미래 예측을 도와줍니다. 이를 통해 의사결정을 지원하고 효율적인 리소스 할당이 가능합니다.',
          }
        
      },
      {
        head : '스마트한 건설현장 구축을 위한 RTLS 응용 사례',
        subHead : '자동화된 장비 운용',
        desc :'RTLS를 활용한 자동화된 장비 운용은 건설 현장의 생산성을 향상시키고 비용을 절감하는 핵심적인 응용 사례 중 하나입니다. 건설장비에 RTLS 태그를 부착하면 해당 장비의 위치와 상태를 실시간으로 추적할 수 있습니다. 이를 통해 건설 관리자들은 장비의 이동 경로를 최적화하고 연료 소비를 최소화할 수 있습니다. 예를 들어, 크레인이 효율적으로 배치되지 않은 상황에서 물자를 운반하는 데 걸리는 시간과 에너지가 낭비될 수 있습니다. RTLS를 활용하면 크레인의 위치를 최적화하여 물자 운반 시간을 단축하고 에너지를 절약할 수 있습니다. 이러한 자동화된 시스템은 더 빠른 작업 진행과 생산성 향상을 실현하며, 동시에 에너지 효율성을 높이는 데 도움이 됩니다.',
        dot : ''
        
      },
      {
        head : '',
        subHead : '작업자 안전 강화',
        desc :'건설 현장에서 작업자의 안전은 절대적으로 중요합니다. RTLS 기술은 작업자의 안전을 강화하는 데 큰 도움을 줍니다. 작업자들은 RTLS 태그를 착용하고 있어야 합니다. 이러한 태그를 통해 작업자의 위치를 실시간으로 추적하고, 작업자가 위험 지역으로 진입하거나 사고가 발생할 가능성이 있는 경우 경고를 보낼 수 있습니다. 또한, 작업자의 건강 상태를 모니터링하고 열악한 환경에서 작업하고 있는 경우 이를 파악하여 작업 중단이나 적절한 조치를 취할 수 있습니다. 이러한 시스템은 사고 예방과 작업자의 안전을 강화하는 데 기여합니다.',
        dot : ''
        
      },
      {
        head : '',
        subHead : '자재 및 장비 관리',
        desc :'자재와 장비의 관리는 건설 프로젝트의 성패를 좌우하는 중요한 요소 중 하나입니다. RTLS를 활용하면 건설 현장에서 사용되는 자재와 장비의 위치와 상태를 실시간으로 파악할 수 있습니다. 이를 통해 재고 관리가 더욱 효율적으로 이루어질 수 있으며, 손실을 최소화할 수 있습니다. 예를 들어, 필요한 자재가 어떤 지점에 부족하거나 중복으로 보관되는 경우를 방지할 수 있습니다. 또한, 유휴 상태의 장비를 찾아내고 유지보수 일정을 최적화할 수 있습니다.',
        dot : ''
        
      },
      {
        head : '',
        subHead : '공정 최적화',
        desc :'RTLS는 건설 공정을 최적화하는 데도 활용됩니다. 건설 프로젝트는 다양한 작업 단계로 구성되며, 이러한 단계 간의 조화와 조정이 필요합니다. RTLS를 활용하여 각 단계의 작업량과 진행 상황을 실시간으로 모니터링하고 분석할 수 있습니다. 이를 통해 작업 일정을 준수하면서 품질을 유지하는 데 도움을 줄 뿐만 아니라 작업 간의 병목 현상을 예방하고 공정을 최적화하는데 도움이 됩니다.',
        dot : ''
        
      },
      {
        head : '',
        subHead : '환경 관리',
        desc :'환경 관리는 현대 건설 현장에서 점점 더 중요한 이슈로 부상하고 있습니다. RTLS를 활용하여 건설 현장의 에너지 사용량, 폐기물 관리, 노후화된 장비의 교체 등을 모니터링하고 관리할 수 있습니다. 이를 통해 친환경 건설과 에너지 효율성을 개선할 수 있으며, 지속 가능한 건설 현장을 구축하는 데 기여할 수 있습니다.',
        dot : ''
      },
      {
        head : '',
        subHead : '고객 서비스 향상',
        desc :'RTLS를 활용하면 고객 서비스도 향상시킬 수 있습니다. 고객은 프로젝트 진행 상황을 실시간으로 모니터링하고 문제가 발생한 경우 빠른 대응을 기대합니다. RTLS를 활용하여 프로젝트 진행 상황을 투명하게 제공하고, 문제가 발생할 때 빠르게 대응하여 고객 만족도를 높일 수 있습니다.',
        dot : ''
      },
      {
        head : '결론',
        subHead : '',
        desc :'RTLS 기술의 도입은 건설 산업에서 스마트한 현장을 구축하고 더 나은 효율성과 안전성을 확보하는 데 큰 도움이 됩니다. 이 기술을 활용하면 건설현장 관리자들은 자산, 작업자, 자재, 장비 등을 더 효율적으로 관리하고 모니터링할 수 있으며, 데이터를 분석하여 의사결정에 도움을 주는 스마트한 시스템을 구축할 수 있습니다. 따라서 건설 산업의 미래는 RTLS 기술과 함께 밝아보입니다. RTLS 기술을 적극적으로 도입하여 더욱 스마트하고 안전한 건설현장을 만들어 나가는 것이 중요한 과제입니다.',
        dot : ''
      },
  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
        {
          text: productPurchase.purchase.worker.workerTracking.title,
          title: productPurchase.purchase.worker.workerTracking.title,
          serial: productPurchase.purchase.worker.workerTracking.serial,
          desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img: productImg.product.workerTracking.thumbnail
        },
      {

      }

    ],
    solution : [
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/enterprise/construction/safety'
        },
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/enterprise/construction/asset'
        }, 
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/enterprise/logistics/asset'
        }
    ]

  }


}