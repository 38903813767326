import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '| Innovation in Smart Management of Heavy Equipment Technology Shaking Up Construction Sites',
  desc: 'The solution for managing heavy equipment with real-time location tracking system (RTLS) technology is revolutionizing the operation and safety of construction sites. This innovation brings various benefits such as optimizing work schedules, asset protection, creating a safer working environment, enhancing environmental sustainability, and cost savings.',
  keywords: 'Smart heavy equipment management, RTLS technology, Construction site innovation, Heavy equipment tracking, Heavy equipment protection, Construction site safety, Heavy equipment automation, RTLS solution, Heavy equipment efficiency, Real-time location tracking, Construction industry technology innovation, Heavy equipment data analysis, Environmentally friendly construction, Construction site cost savings, Heavy equipment management system, Future construction industry, Heavy equipment maintenance, Smart construction solutions, Heavy equipment safety measures, RTLS, orbro, Kongtech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Innovation in Smart Management of Heavy Equipment Technology Shaking Up Construction Sites :: Orbro Blog',
  ogUrl: 'https://orbro.io/en/blog/heavyEquipment',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDzGmkjkBoP6Xvw8I-rO2ghVp5PqT4G-TOxRZma9x_Alu5navIMlFCn09CKOn81H_Madb-IgbiyiSNy0fEz9PhBPlRbc4NuZIKYHZj06eaqjP7RSKmVw0WhGazP-pzOcpYeCHaToxFMOSXbhipZqKw3T89KUknQiF7z_dhxCgvtwJE1dL27fvS_Ux57UHXUtvl1yiQcS_SQlzL7JwSBg9NoGXqPzzLivQt203pHDtTo0FUNEkkp4ILcoK9jRwzMd6_V0_PZNuKAXYZEcdeZZ7zo7ry82rcT59_D6R41M5GU_XphFwzhYGe38An9N16es0rCgbhvoP2oa8-aOPDswbWK-EAGINoE44O4TUgmG2KRK-tcqpUsNhUu8fau_ZlH_YLSc9gGpv41zNj2Uc-JHSwC074gaimJdd3MAMZlTeDTgyOQDI7OEodIXoFmzzvplifLlBzUpkHxAbA7Chn7YpTus-ZM8OxmGBL2QQuRsApAVQLnHHxEmRZpMUMcRskj0vGevc85DiZukbBv92q6BocAt9lmrz7fCtT6UWFo0Dch3wPd-mxS_vELPx9itBdEhoGU1M0SI-urofonB6RXcgj3VODFNXvmKnUe7MbJCJmSWTz4M3S175wRj8DHoLLYOPbD0M5YJEkTmSiS4aM5mFngNyxx5szGgSIBMp-AY-lzTr1M-1JnEUuC_2oQQT88ax_eS6VXIr-KjlSj8XrAJ-2RwKEGJlCo4i8b5FABD0Vqeg5soicxR_tOy0xkq4W8F8BaEfVtapquvKNAB8zs8lmzXgLUVOYzpqyVjw9_1vvfirMHsCYZZ-J8H4Tx6dRBSoymoWsOinzjd2QV8pv9yiHvCBQMyesmVjWNFZAKUM4xkmIVR9DFmkutgA0zV6VnGwrnMpAzo94Tt1stWAxVdb4R7U7HLpwwMlga6xU5yD2ESMRa13LVj8zrE5JhRmTk8ZOBfqlkrhTNMCLU_HO5YZ3yhttXl1TgbEtmR1ojfgduZMA0WwBpellXtuyIPhMZEK8wykwfrW2i4zq3V5NosBaamzVFwAy0AIJxIuf2P9IG61SM-QSm-4I_kz-KKGUhOtJcaDI7G38qHvGLJU2Ye1AxPX9POfdnfY2RhYoBIxHWdYy9UvZZDI_dgT_NaVsDRaMMaBqYEWlmr1OHV3lBx-BaxmlKUQ_RmMGJApPB-6xN2j5L1h1vKnml06CuPUOJ9CvKGrVZCy38o_KF6O6KXXqDuCE-9wdHvE3cfjefcAv298ML2Sj2b25ugq0KbO96LKoRz61WklczBoCAouwaFpenu7xf7lCIfXVskkq8AC8UaHtC1kVooqQfyOLLxG6B1skFGcUudDX7TQY2IDywQ-213Y8BUgP1BXa0mlj2fHovr0munoE2r5VngsHXQGWnYNgAIlEGQvwqL52-7v5a2RShdVo8_4d0gV0-MHBMv0akT60cfaDBukms6Lk0hX_kk20AyF2jsLu3tPcty3RIi7VZgSOyP83n-Ax6ideyVryy31tfTJIr_vqMPpQX2rhm7iQ4PBGgopMM6PUYzqUMeL9IX3t2qA0VD_tHim2kKdE17NV9ffiDP2UmH4gdzjw=w2114-h1166',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Innovation in Smart Management of Heavy Equipment Technology Shaking Up Construction Sites',
  date: '2023-09-25',
  tag1: '#SmartHeavyEquipment',
  tag2: '#HeavyEquipmentManagement',
  tag3: '#ConstructionHeavyEquipment',
  tag4: '#RTLS',

  text: [
    {
      head: '',
      subHead: '',
      desc: 'The modern construction industry is constantly seeking smarter and more efficient ways to operate, and smart management solutions for heavy equipment are a crucial part of these efforts. In particular, solutions that incorporate Real-Time Location System (RTLS) technology are dramatically improving the operation and safety of construction sites. In this article, we will delve into the innovative impact of RTLS technology on heavy equipment management and its numerous advantages.',
      dot: ''
    },
    {
      head: 'What Is RTLS Technology?',
      subHead: '',
      desc: 'RTLS (Real-Time Location System) is an abbreviation for real-time location tracking system. It is a technology that allows precise tracking, monitoring, and management of the accurate location and status information of heavy equipment and assets in real-time. This technology is used in conjunction with various technologies such as GPS, RFID (Radio-Frequency Identification), UWB (Ultra-Wideband), and wireless networks.',
      dot: ''
    },
    {
      head: 'Advantages of Using RTLS for Heavy Equipment Management',
      subHead: '',
      desc: 'Incorporating RTLS technology into heavy equipment management provides the following innovative advantages:',
      dot: ''
    },
    {
      head: '',
      subHead: '1. Real-Time Location Information',
      desc: 'RTLS technology enables real-time tracking of the precise location of heavy equipment. This allows for real-time monitoring of the current location and movement paths of equipment, aiding in quickly locating and using the necessary equipment and optimizing work schedules.',
      dot: ''
    },
    {
      head: '',
      subHead: '2. Protection and Management of Heavy Equipment',
      desc: 'Heavy equipment is a core asset of construction projects, and its protection and management are critical tasks. By utilizing RTLS, theft of heavy equipment can be prevented, and predictive maintenance and servicing schedules can be established to prevent breakdowns proactively.',
      dot: ''
    },
    {
      head: '',
      subHead: '3. Optimization of Work Schedules',
      desc: 'Utilizing real-time location information of heavy equipment allows for the optimization of work schedules. Rapidly locating the required equipment helps prevent work delays and enhances productivity.',
      dot: ''
    },
    {
      head: '',
      subHead: '4. Creating a Safe Working Environment',
      desc: 'RTLS technology contributes to improving the safety of workers. Sharing the location information of heavy equipment helps workers prevent collisions with equipment and create a safe construction environment.',
      dot: ''
    },
    {
      head: '',
      subHead: '5. Automation and Efficiency',
      desc: 'RTLS technology plays a significant role in automating and streamlining heavy equipment management. It enables real-time monitoring of equipment status and automatic report generation, simplifying business processes and saving both labor and time.',
      dot: ''
    },
    {
      head: '',
      subHead: '6. Data Analysis and Decision Support',
      desc: 'RTLS systems generate a wealth of data that can be analyzed to improve the performance of construction projects. For example, it can help evaluate and enhance equipment utilization rates.',
      dot: ''
    },
    {
      head: '',
      subHead: '7. Environmentally Friendly',
      desc: 'Effective management of heavy equipment reduces energy consumption and conserves resources, contributing to making construction projects more environmentally friendly.',
      dot: ''
    },
    {
      head: '',
      subHead: '8. Cost Savings',
      desc: 'Using RTLS technology can lead to cost savings in heavy equipment management and maintenance. For example, efficient preventive maintenance and servicing can minimize breakdowns, and data on equipment rental and purchase decisions can be utilized to optimize budgets.',
      dot: ''
    },
    {
      head: 'Conclusion',
      subHead: '',
      desc: 'RTLS technology provides an innovative solution for smart management of heavy equipment on construction sites, offering benefits such as optimizing work schedules, asset protection, creating a safer working environment, enhancing environmental sustainability, and cost savings. Therefore, RTLS technology is expected to play a shining role in the future of the construction industry. It will disrupt construction site operations and open the door to a smarter future.',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: 'This product attaches UWB terminals to workers or equipment to assist in real-time location tracking.',
        img: productImg.product.assetTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionEquipment.thumb,
        data: 'constructionEquipment',
        link: '/en/enterprise/construction/equipment'
      },
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/en/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      }
    ]


  }


}
