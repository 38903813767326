import { ptzThumb } from "@assets/images/main/camera";

import * as banner from "@src/pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


    home : {
        title : '오브로 엔터프라이즈 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
        key :'실내위치추적, 실내 GPS, RTLS, 실시간 위치 추적, 콩테크, Kong-tech, 자산 관리, 자산 추적, 유동인구 분석, Real-time Location System, ',
        desc : '오브로의 다양한 제품을 만나보세요',
        img : 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url : 'https://orbro.io/enterprise/',

    },
    
    solution : {

        constructionAsset : {
            title : '건설 자재 관리 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key :'건설 자재 관리, 건설 현장 관리, 자재 관리, 자재 추적, 물건 추적, 건설 현장 추적, 실내 위치추적, 자산 관리, 작업자 안전 관리, 노동재해처벌법, 오브로,콩테크, Kong-tech',
            desc : '정확한 자재 관리는 결국 생산성을 향상시킵니다. 자원 낭비를 줄이고 품질을 유지하며 더 나아가 안전에 영향을 끼칠 정도로 자재 관리는 건설 현장에서 중요한 부분입니다.',
            img : banner.constructionAsset.banner,
            url : 'https://orbro.io/enterprise/construction/asset',
        },

        constructionSafety : {
            title : '작업자 안전 관리 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key :'노동자 안전, 노동자 안전 관리, 안전 관리, 작업자 안전, 건설 안전, 건설 현장 안전, 실내 위치추적, 자산 관리, 작업자 안전 관리, 노동재해처벌법, 오브로, 콩테크, Kong-tech, RTLS, 실시간 위치 추적, 노동자 추적, 위치 추적, 야외 위치 추적',
            desc : '작업자의 안전은 무엇보다도 중요합니다. 고위험 환경에서 작업자의 안전을 보장하지 않는 것은, 생산성에도 문제가 생길뿐더러 법적인 책임까지도 발생할 수 있습니다. 또한, 안전한 건설 현장은 기업의 이미지와 이해관계자들의 신뢰를 형성하는 데 중요한 역할을 합니다.',
            img : banner.constructionSafety.banner,
            url : 'https://orbro.io/enterprise/construction/safety',
        },
        constructionEquipment : {
            title : '중장비 관리 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key :'건설 중장비 관리, 건설 장비 관리, 중장비 관리, 중장비 유지보수, 포크레인 관리, 건설 중장비, 불도저 관리, 굴삭기 관리, 실시간 위치 추적, 야외 위치 추적, RTLS,콩테크, Kong-tech',
            desc : '건설 현장에서 중장비는 핵심 요소입니다. 따라서 적절한 유지 보수, 사고 예방은 필수입니다. 중장비를 제대로 관리 한다면, 안전을 유지하면서 비용은 절감될 것이고 현장의 생산성은 눈에 띄게 상승할 것입니다.',
            img : banner.constructionEquipment.banner,
            url : 'https://orbro.io/enterprise/construction/equipment',
        },


        logisticsAsset : {
            title : '물류 재고 관리 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key :'재고 관리, 창고 자산 관리, 창고 재고 관리, 창고 재고 추적, 재고 위치 추적, 창고 실내 위치 추적, 실내 GPS, 실내 위치 추적, RTLS, 창고 관리, 자산 관리, 창고 물건 관리, 재고 추적, 실시간 위치 추적,콩테크, Kong-tech',
            desc : '재고 관리는 공급망의 효율성과 원활한 운영을 지원하는 중요한 부분입니다. 실시간으로 재고의 위치를 파악하면 재고 수준을 효과적으로 모니터링하고 재고 이동을 최적화할 수 있습니다. 예를 들어, 품목의 인기도에 따라 재고를 배치하고 재고 회전율을 최적화할 수 있으며, 이는 비용 절감과 재고 부족이나 과잉을 방지하는 데 도움이 됩니다.',
            img : banner.logisticsAsset.banner,
            url : 'https://orbro.io/enterprise/logistics/asset',
        },

        logisticsSafety : {
            title : '창고 작업자 안전관리 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key :'창고 안전, 창고 안전 관리, 창고 작업자 안전 관리, 창고 작업자 관리, 실내 GPS, 작업자 안전, 창고 관리, 안전관리, 창고 노동자 안전, RTLS, 위치 추적, 실시간 위치 추적, 실내 위치 추적, 작업자 추적,콩테크, Kong-tech',
            desc : '작업자의 안전은 무엇보다도 중요합니다. 고위험 환경에서 작업자의 안전을 보장하지 않는 것은, 생산성에도 문제가 생길뿐더러 법적인 책임까지도 발생할 수 있습니다. 또한, 안전한 물류 현장은 기업의 이미지와 이해관계자들의 신뢰를 형성하는 데 중요한 역할을 합니다.',
            img : banner.logisticsSafety.banner,
            url : 'https://orbro.io/enterprise/logistics/safety',
        },


        officeAsset : {
            title : '오피스 자산 관리 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key :'사무 용품 관리, 자산 관리, 오피스 자산 관리, 오피스 재고 관리, 실내 GPS, 사무 관리, 재고 관리, 오피스 관리, 스마트 오피스, 실내 위치 추적, 재고 추적, 자산 추적, RTLS,콩테크, Kong-tech',
            desc : '미숙한 자산 관리는 시간적, 금전적 손실을 발생시킵니다. 업무에 차질이 없도록 명료하고 빠르게 자산을 관리하여 원활한 비즈니스를 운영할 수 있습니다.',
            img : banner.officeAsset.banner,
            url : 'https://orbro.io/enterprise/office/asset',
        },


        medicalPatient : {
            title : '환자 위치 추적 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key :'환자 위치 추적, 환자 관리, 감염자 추적, 병원 관리, 스마트 병원, 실내 GPS, 실내 위치 추적, 실시간 위치 추적, 환자 추적, 병실 관리, 환자 안전, 환자 모니터링, 실시간 모니터링, 실내 위치 추적, RTLS,콩테크, Kong-tech',
            desc : '의료 시설에는 각별히 관리해야만 하는 환자들이 존재합니다. 직접 24시간 감시하지 않아도 괜찮습니다. 더 정확하고 빠르기 환자의 위치를, 실시간으로 추적합니다.',
            img : banner.medicalPatient.banner,
            url : 'https://orbro.io/enterprise/medical/patient',
        },
        medicalWaste : {
            title : '의료 폐기물 및 자산 관리 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key :'의료 폐기물 관리, 의료 사고 관리, 의료 사고 방지, 의료 사고, 실내 GPS, 폐기물 관리, 폐기물 추적, 실내 위치추적, 실시간 위치 추적, 위치 추적, 자산 관리, 병원 자산 관리, 병원 재고 관리, 실시간 모니터링, 폐기물 모니터링, 병원 안전, RTLS,콩테크, Kong-tech',
            desc : '의료폐기물 관리는 의료 시설에서 발생하는 다양한 종류의 폐기물을 안전하고 적절하게 처리하여 환경 오염을 예방하고 감염 위험을 최소화하며, 법적 규제를 준수하여 인체 건강과 자원 보전에 기여하는 필수적인 작업입니다. 의료폐기물의 실시간 현황과 위치를 추적하여 의료사고 예방하고 안전한 의료 서비스를 제공할 수 있습니다.',
            img : banner.medicalWaste.banner,
            url : 'https://orbro.io/enterprise/medical/waste',
        },


        manufacturingProcess : {
            title : '실시간 공정 관리 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key :'제조 공정 관리, 제조 공정, 제조시설 관리, 재고 위치 추적, 실내 GPS, 실시간 위치 추적, 공정 관리, 공정 모니터링, 실시간 모니터링, 위치추적, 스마트 제조, 생산 라인 모니터링, 실내 위치추적, 공정 분석, RTLS, 제조시설 자산 관리,콩테크, Kong-tech',
            desc : '제조 시설이 어떤 흐름으로 진행되고 있는지 한눈에 파악하여 관리할 수 있습니다. 정확하고 객관화된 데이터를 통해서 공정 라인을 개선하고, 효율성을 높일 수 있습니다. 이는 제조 산업의 핵심입니다.',
            img : banner.manufacturingProcess.banner,
            url : 'https://orbro.io/enterprise/manufacturing/process',
        },
        manufacturingAsset : {
            title : '실시간 재고 관리 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key :'제조 재고 관리, 재고 관리, 스마트 제조, 공정 모니터링, 실내 GPS, 공정 관리, 원자재 관리, 모니터링, 실시간 모니터링, 실시간 위치 추적, 위치 추적, RTLS, 실내 위치추적, 생산 라인 모니터링, 공정 분석, RTLS,콩테크, Kong-tech',
            desc : '제조 시설은 원자재, 반제품 등 수많은 재고를 보유하고 있습니다. 이들을 제대로 관리하는 것은, 시간과 경제적인 측면에서 매우 중요합니다. 즉, 제조 산업의 효율성에 영향을 끼치는 중요한 요소입니다.',
            img : banner.manufacturingAsset.banner,
            url : 'https://orbro.io/enterprise/manufacturing/asset',
        },

        retailExhibition : {
            title : '방문객 관리 및 분석 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key :'방문객 동선 분석, 방문객 분석, 관람객 동선 분석, 관람객 분석, 동선 분석, 실내 위치추적, 실내 GPS, 실시간 위치추적, 방문자 분석, 방문자 파악, 위치 추적, RTLS,콩테크, Kong-tech',
            desc : '정확하고 객관화된 데이터는 새롭게 비즈니스를 성장시킬 수 있습니다. 낭비되는 인력과 경비를 최소화하고 스마트한 전시관 이미지를 제공하며, 차별화된 운영이 가능합니다.',
            img : banner.retailExhibition.banner,
            url : 'https://orbro.io/enterprise/retail/exhibition',
        },
    },


    

}