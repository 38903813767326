import { GRAY_50,TRANSPARENT } from "@assets/styled/color.style";
import { FlexStyle, Vertical } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledLabel, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledFooter = styled(FlexStyle)<{dark : boolean}>`
  width: 100%;
  justify-content: center;
  z-index: 2;
  width : 100%;
  padding-top : 64px;
  border-top : 1px solid rgba(0,0,0,0.08);

  ${({dark})=> dark ? `background-color : #000;` : `background-color : #fff;`}
  
  & * {
    font-family: Pretendard !important;
    font-display: swap;
  }
  
`;


export const StyledFooterCombineSub = styled(Vertical)<{isLastIndex : boolean}>`
  row-gap: 16px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
     ${({isLastIndex}) => isLastIndex ? 'margin-bottom : 24px;' : '0'};
  }

`;



export const StyledFooterLogo = styled.img`
  width: 130px;
  aspect-ratio: auto 130 / 30;
  object-fit: cover;
`;

export const StyledFooterSubTitle = styled(StyledLabel)<{darkPage : boolean}>`
padding-top : 24px;
${({darkPage})=> darkPage ? `color : #fff;` : `color : #000;`}
`

export const StyledFooterHead = styled(StyledGridRow)`
  padding-top: 16px;
  padding-bottom: 52px;


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-bottom: 64px;
    row-gap: 0;
  }
`;

export const StyledFooterHeadColumn = styled(StyledGridColumn)<{mt : string, gr : string}>`

  ${({mt})=> mt && `margin-top : ${mt}px;`}
  ${({gr})=> gr && `grid-row : ${gr};`}

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-top: 48px;
  }
`;


export const StyledFooterHeadColumn37 = styled(StyledGridColumn)<{type : boolean}>`

  ${({type})=> type ? 'grid-row : 3; grid-column : 7' : ''}

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-top: 48px;
  }
`;

export const StyledFooterSub = styled(Vertical)`
  row-gap: 16px;
`;



export const StyledFooterBottom = styled(Vertical)`
  border-top: 1px solid ${({ theme }) => (theme.BORDER_TRANSLUCENT)};
  padding: 24px 0 52px;
`

export const StyledFooterBottomRow = styled(FlexStyle)`
  
`;

export const StyledFooterBottomTitle = styled(FlexStyle)`
  height: 60px;
  align-items: center;
`;

export const StyledFooterLink = styled(Link)`
  color: ${({ theme }) => (theme.CONTENT_SECONDARY)};
`;

export const StyledFooterBottomParagraph = styled(StyledParagraph)`
  color: ${({ theme }) => (theme.CONTENT_SECONDARY)};
`;

export const StyledFooterATag = styled.a`
  cursor : pointer;
  color: ${({ theme }) => (theme.CONTENT_TERTIARY)};
`;





export const StyledFooterHeadColumn40gap = styled(StyledGridColumn)<{type : boolean}>`
    display: flex;
    flex-direction: column;
    gap: 40px;

    ${({type})=> type ? 'grid-row : span 1 / auto' : 'grid-row : span 2 / auto'}

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    gap : 0px;
  }
`;


export const StyledMobileSpacing48 = styled.div`

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding-top : 48px;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-top : 48px;
  }

`

export const FooterLabel = styled(StyledLabel)<{darkPage : boolean}>`
  padding-top : 24px;
  ${({darkPage})=> darkPage ? `color : #fff;` : `color : #000;` }
`;