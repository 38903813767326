import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet: '',
  desc: '通过实时公共交通位置追踪来提高乘客体验并提高运营效率。ORBRO提供巴士和地铁的实时位置、站点信息表示、交通流分析和优化、安全管理和监控、应用和服务集成等功能。',
  keywords: 'Digital Twin, Digital Twins, 数字孪生, gis, 数字城市, RTLS, 位置追踪系统, 位置追踪技术, 体育, 制造, 物流, 医疗, 医院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - 公共交通信息连接 :: ORBRO博客',
  ogUrl: 'https://orbro.io/cn/blog/digitaltwin-traffic',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twin - 公共交通信息连接',
  date: '2023-12-26',
  tag1: '#数字孪生',
  tag2: '#交通状况',
  tag3: '#数字城市',
  tag4: '#虚拟现实',

  // <br/>
  text: [
    {
      head: 'Digital Twin - 公共交通信息连接',
      subHead: '',
      desc:'ORBRO通过实时追踪公共交通来提供增强乘客体验和提高运营效率的服务。为此，它提供巴士和地铁的实时位置更新，停靠点信息表示，交通流分析和优化，安全管理和监控，以及与应用程序和服务的集成。<br/><br/>ORBRO的GPS和跟踪技术精确提供巴士的实时位置，使乘客能够获取有关公共交通运营的最新信息。地铁位置的实时监控为乘客提供准确的运营信息，提高了公共交通使用的可靠性。<br/><br/>提供停靠点信息增加了公共交通使用的便利性，而交通流分析和优化有助于减少交通拥堵并缩短乘客的等待时间。此外，安全管理和监控优先考虑乘客的安全，并支持公共交通系统的顺利运行。<br/><br/>最终，ORBRO提高了公共交通用户的体验，同时提高了公共交通系统的效率，促进了城市交通环境的提升。',
      dot: ''
      
    },
    {
      youtube: 'https://www.youtube.com/embed/tK8f-aMRh-g?si=Q6CVGXUz5ZTjLv-4',
      head: '',
      subHead: '巴士的实时位置追踪',
      desc: 'ORBRO使用GPS和追踪技术提供巴士的实时位置。这使得乘客能够准确预测到达时间，同时有助于交通管理员提高运营效率。',
      dot: ''
    },
    {
      head: '',
      subHead: '地铁的实时位置监控',
      desc: '实时监控地铁系统内车辆的位置并显示，为乘客提供准确的运行信息。这有助于提高运营效率，并在紧急情况下做出迅速响应。',
      dot: ''
    },
    {
      head: '',
      subHead: '站点信息提供',
      desc: '在数字地图上显示每个站点和车站的详细信息，为乘客提供站点位置、时刻表和服务信息，提高公共交通使用的便利性。',
    },
    {
      head: '',
      subHead: '交通流分析和优化',
      desc: '利用实时位置数据分析和优化交通流，有助于减少交通拥堵和缩短乘客等待时间。',
    },
    {
      head: '',
      subHead: '安全管理和监控',
      desc: '通过加强公共交通内部的安全管理和监控系统，确保乘客的安全，并能够迅速应对运行中的风险。',
    },
    {
      head: '',
      subHead: '应用和服务集成',
      desc: '将公共交通信息与移动应用和各种数字服务集成，为乘客提供更好的信息获取和便利性。',
    },

  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '安装UWB设备以实时支持位置追踪的产品，适用于工人或设备。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      }, 
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]


  }

}
