import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import {constructionAsset, constructionSafety, logisticsAsset} from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
    helmet: '',
    desc: `基于主动RFID技术的RTLS利用带电源的RFID标签，能够在较长距离上进行位置追踪，并能实时准确地监测物体或人员的位置，广泛应用于各个行业。`,
    keywords: 'Active RFID, Passive RFID, RFID, RTLS, 位置基础, 定位, 室内定位',

    ogTitle: '',
    ogDesc: '',
    ogSite: '基于Active RFID技术的RTLS :: Orbro Blog',
    ogUrl: 'https://orbro.io/cn/blog/activerfid',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/APg5EOaF0taI55etnk-7VQVh_JecDxbhJX529VY' +
            'v21nToxYe_qXD-w9k29bChDiW0Dj2JxEgZAtoeKjP5UhTJ0zB6tPZT6tGj5rygnex9J0bJt-k9_Hhd' +
            'DZPSetk87I3E-uDLFHG4xQF9ZgyEozwxxPAL_R0RPL-WNwKtkmGk1-d2GauxlTr2d4hGLWvllQl3_u' +
            'siu46eWZqw76MEhqjCOs1HfYNn6nZM1_tRdsFDF2YeHeVM_nxLUKocvLFuA1xOhxMMkn2O2EW51rmn' +
            'rEX66iYcxSgcpQix_5nMEcjBMJRqsrg2ULZuUeQ4R_-ji2u-RIAmKIt45RH7RVpxVW5jORDikiW-Lb' +
            'fzIQeDxdAPu8fq3k5o1IQ15lDVU7_nAae5wo1RxF_2JoYHgTqK1D5f6Zpt9eKA3OZReeim-MmTDklv' +
            '6WC4CRnzWF1YS6efSa5G_vOTnieHKYY_QQ_w6mQlR-Lzzik_xNgPachPN-w_ls-iM2a-AtLnitE0S5' +
            'O2xXPUlzsm_NyBM3NaA7BueOWETvdm64Es_4LjIsbnlQ-A_MIMbKZjiL7lIfWXUtNbYkpFryMZzUFe' +
            'HXV7j72VtEmvkbmnMeGI120Co5kuvlpsFxOfmcuQTgwQPbRgsgFIWBkAnt9vla-Y4F3szREBBYSt44' +
            'cgGIqknvJEv_7llpLaRXYYL0v1mKTnDRjXVKmGHugaxJNamcxLIHjGAgw_PfhHxMTYYeHs216wRGRE' +
            'OhMRsYnaIoi9httnwkNN911s1nK48m8YaTploLUIbMNoGasxH9yXlqXAcZUvDzbzAw4-bEzD4WBeX0' +
            'pNQe9TuF3QnV2GlnklwVCLrWL8O4jE6jpOvW7KYMKIdAGPTkpHGBsViFHHX6nWFYHtsfaFKj8hjAUJ' +
            'mLrR_To65RSXQRHqfPye2voD_IDE6XhqnFK5Zi9gcsBgwZo7KQuihZcE_ctUyQOfFYvDJA8NILsJsv' +
            'oGZQXQjPJvIaYmV9LfETM1MMCSL4AC4Vp4ZqQi7q6Fr1cvv1zrmYChkRYtxC00M5Eo2qg2-Qmb8SiA' +
            'NdUYeHxsSOLl5ybYsXBTbEUI9wY0jvT646VkLtHUf-AR9QdUnMqwj2YROz8lgVDZgZroHzXpzDUJaV' +
            't1BieJuuk5OLawO2eOrDN7VRU9i35NYRxx_eMOjeVvXJNJ7QxDISJgoCj_iLnilmShn8QmQfU5zINg' +
            'qzKac6Ru0cJvScz5k_IQxEx_L9LXfqjaljUjhkI65UDijCW-u328M_DEEGeW9sYavREHwtpdY97oSc' +
            's7VaBcxyOF2fi6XvP_5Gwig4GyydfN67d_49MmeMMSXVrJajT9i93QKvePas21Tiu8VaLtdSYFnuj_' +
            'Vy53U1EJihuJXEQG4DoTm_I6UsnaCxf4Ymi0Asp-m2oZlcoywdlRKdj9W8x5uVqd2baDzd4mjNO_CB' +
            'NMFTMmvGAMOS3XOi3jQYZ0a7tkbrcy1DSNhwv_Ds19DpQrDM5faV6GSaRtRfu-8hgESZSlBC_P3fJ9' +
            'QPREO5n0TspEozjIrZbHoyhmP7yQB58K-7V3bpk-fKuaw3ruD64ZeIeFyOiJwJDig=w2059-h1216',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',
    title: '基于主动RFID技术的RTLS',
    date: '2023-05-15',
    tag1: '#RFID',
    tag2: '#RTLS',
    tag3: '#定位',
    tag4: '#室内追踪',

    text: [
        {
            head: '什么是主动RFID？',
            subHead: '',
            desc: '主动RFID是一种通过无线通信传输设备间信息的技术形式。主动RFID标签具有自身电源，可以通过长距离传输信号。它特别适用于实时追踪资产。主动RFID标签的读' +
                    '取范围可达几百米，因此在大规模资产追踪解决方案中非常理想。',
            dot: ''
        }, {
            head: '主动RFID与被动RFID的区别',
            subHead: '',
            desc: '主动RFID标签带有内置发射器和电源，因此可以发射无需外部读取的信号。虽然主动RFID标签比被动RFID标签昂贵得多，但其覆盖范围更广。主动标签通常用于跟踪' +
                    '较广范围的物品，如仓库管理等。<br/><br/>被动RFID标签没有自身电源。它们依赖RFID读写器发出的功率来激活和传输信号。因此，被动RFID标签比主' +
                    '动RFID标签便宜得多，但其读取范围通常仅几米。被动RFID标签通常用于应用于读取范围较短的零售库存管理等应用。',
            dot: ''
        }, {
            head: '当今主要使用哪种RFID技术？',
            subHead: '',
            desc: '主动RFID标签主要分为Beacon标签和Transponder标签两种。Beacon标签周期性广播信息，范围内的主动RFID读取器可以接收该信息。Tran' +
                    'sponder标签仅在接收到主动RFID读取器的特定查询时进行通信。因此，每个标签只在查询时传输信息，更适合追踪特定的资产。',
            dot: ''
        }, {
            head: '主动RFID技术可以与RTLS解决方案结合吗？',
            subHead: '',
            desc: 'RTLS（实时定位系统）基本上就是一种主动RFID。RTLS通过放置在整个设施中的接收器来接收通过主动RFID标签发出的信号。通过这种方式，几乎可以立即追踪' +
                    '指定资产的位置，为资产的位置提供有价值且全面的信息。',
            dot: ''
        }, {
            head: 'RTLS解决方案的优势',
            subHead: '<br/>准确性',
            desc: '主动RFID标签通常可以实现高达几厘米的高水平定位准确性。因此，对于需要精确位置信息的应用，如医疗和制造等领域，主动RTLS非常适合。',
            dot: ''
        }, {
            head: '',
            subHead: '实时数据',
            desc: '主动RTLS系统提供标签所在对象的实时位置数据，因此可以随时查看资产的位置。这对于需要及时反应的应用，如零售和物流等领域非常有用。',
            dot: ''
        }, {
            head: '',
            subHead: '灵活扩展性',
            desc: '可以配置活动RTLS系统以跟踪包括人员、托盘和容器在内的所有类型的资产。即使在GPS信号较弱或不存在的室内环境中，也可以追踪资产。由于标签与接收器之间无需直' +
                    '线视线，因此在高密度环境中追踪资产非常理想。这些功能使得活动RTLS非常适用于广泛的应用。',
            dot: ''
        }, {
            head: '',
            subHead: '有效范围',
            desc: '主动RFID标签的范围最大可达100米，因此非常适合跨大范围分布的资产追踪。由于可以远距离传输信号，因此也非常适合追踪大型资产，如车辆。这些信息可以用于资产' +
                    '管理、安全和自动化库存管理等工作。',
            dot: ''
        }, {
            head: '<br/>基于主动RTLS的资产追踪系统的实际应用',
            subHead: '',
            desc: '主动RTLS系统可应用于以下各种应用中：',
            dot: ''
        }, {
            head: '',
            subHead: '医疗',
            desc: '用于医疗的主动RTLS系统用于追踪医院内患者、员工和设备的位置。这些信息可以改进患者管理和安全，并更有效地管理医院资源。',
            dot: ''
        }, {
            head: '',
            subHead: '制造业',
            desc: '用于制造的主动RTLS系统用于追踪工厂中原料、成品和生产设备的位置。这些信息有助于提高制造效率和质量管理。',
            dot: ''
        }, {
            head: '',
            subHead: '零售',
            desc: '主动RTLS系统用于零售店内的库存定位。这些信息可以改进客户服务。',
            dot: ''
        }, {
            head: '',
            subHead: '批发',
            desc: '用于批发的主动RTLS系统用于追踪仓库和物流中心的托盘、容器和其他资产的位置。这些信息可用于提高配送准确性和效率。',
            dot: ''
        }, {
            head: '',
            subHead: '',
            desc: '<br/>考虑到RTLS解决方案的实用性和灵活性，可以将其归类为成本效益高的系统。另外值得注意的是，近几年来这些RTLS系统的成本持续下降。',
            dot: ''
        }
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: "本产品是一种UWB设备，可安装在工人或设备上，以帮助实时跟踪位置。",
                img: productImg.product.workerTracking.thumbnail
            }, {}

        ],

        solution: [
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/cn/enterprise/construction/asset'
            }, {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/cn/enterprise/construction/safety'
            }, {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/cn/enterprise/logistics/asset'
            }
        ]

    }

}