import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4';

export default {

  desc: 'スマートパーキングは、現代の都市環境で駐車問題を解決し、効率的な駐車管理を実現するために先進技術とインフラを活用する駐車スペースです。このような駐車スペースは、さまざまな技術要素を統合し、駐車プロセスを最適化し、車両利用者により便利な体験を提供します。',
  keywords: 'スマートパーキング、駐車管理、休憩施設、デジタルツイン、デジタルトランスフォーメーション、デジタルツイン、orbro、ビーコン、rtls、uwb、ble、aiカメラ、orbro、contech',

  ogSite: 'スマートパーキングシステム | 位置追跡とデジタルツイン、orbro',
  ogUrl: 'https://orbro.io/jp/blog/smart-parking',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmD7VPkEf-FilCFjgoS9bLoX8jzIaaMktsjySXAPNjEeeFagK2_315Sl39yXx7L8iamkUCasZgYN_nmqjEzlcJ7b471GwtTQQuGwwOAfQQA6S8NE3asVOx8onF2HoacQcQFEPQ8CAhcl0rNpbh8bBS8FFHY4_D2fvH08mJkm9qWDYYAV69cnY6EoN7AbjCxcmjDp9MRJWdjSZLXFzgpaIIYwkOai0qq4fv00oAK7nW5aHmjQ7yW3Fs_dnUPcWjO02-SJvPDSXNRfqALw7R8fQ1xzAouT41STdr9FL0nvwawirXEQrJHdFYmtKxTMeYwrzrbvapEVr_9gbwWf7Xz6op2cCCQ9rlTypNRAUcoImxb8moTg_z4h0lB4He17_bFIKP8htIGOonJQ30cdXaym4saPjQhyRCio1wMUVwgbmNVC3b4zyJ5L9HnxyCsxDiVEDthb0FhoiTe75C-fAaLDxkhVfyjQTSX7lCYYG_JjPfTEo2kBb6fU79VYeUO4Kb_eWYiPR2Iwfzuu7StZDg_fEldXXzjVx6NJPi6R4a0r8VwuTtEwURqugwI57tg0kMwc1CN3P6SfBrzGPldfU6D5kntEPrH4rZvGkFLUOhGrKJWQrBodIb2rV5aACulW8o5W5D8wDEJkfyNS-F0oQHBEqzp9nZxKDuGMZ2gb_1rBuvwbphoql1q_W42SNGRTzjK3yZLzxxh9utXyY4bL4KVGrSVYX5BrshF1Uxy9qzyfmgNeI_sy5Tbah5GI3QhJG_MWU8nN5WicgsNpOWRVNSKyEhrt5hUOE5AVc60roL6Ar04TIJvjwBc85d0W_DonnbugsHZd-ZE2a1qACGExoN1GoO7gY8I0rGyaeXPXmz-eKFBYlni7VNvL_-WZlVfON20nmmnJGjJENADAWpBqdcJsKErZNryomDdplCibc53ZnYZ0-tE14kXFvQSMVx9rpq7xNi459vviazTPO_ZP6OKfTXX6tIrYW-vNA6r-vVM2nvber10dIqepSAUGPhm85RXyKGyHzU2sMQZA_0KKZR9ghdnDbzKyk6Ig0gcSclnaKoUAv0tV8ouS-IML_FSjTylgxNCHCeh5DRwimnmWs-M-Qj8E_pvYxpE5gDx5NFgO3KQtnEk7ZocAEpOSVFN5PHfWUhpTh-ZsM4joGNhdAiBAbc-VZzDs0jIIC2xpKDPdpUtVvIaVM9X_lf5FKcKd7VLx27m6hSL2bmbgdvx4t33C6fN69eGruOV7uvS1-K7vO6dqs-g4FWmrLDnOFmCGDyaa8gwvxozpfxaSUqklsYGSVuw6zCnAgAM-z6ApVjVb6P_aRbWW4ETJnzuMg3O4RVMoQZ1XGTM2kXC_0Um42ueBxfKmL7cAdcHi77Mxu7jLWBKoR1MI0uR-Flk-ouBsLn0joMzdQK6d3noiwmXVnNt26nLyiTY0OuyNhP5oDp6lXYyWjUsUwSZs2GpTcNDljddjYs1m1T7ckdu_JHlFsHpJ3RYQxuOcSAHdB4sv4hQohcrrbkctCpfLF8KUHAI4LbX1RqQ8eHJfD1wKMUSd7CydiQFcKD7FLnz=w1501-h1172',
  ogType: 'ウェブサイト',

  title: 'スマートパーキングシステム',
  date: '2024-01-24',
  tag1: '#スマートパーキング',
  tag2: '#駐車管理',
  tag3: '#休憩施設',
  tag4: '#デジタルツイン',

  text: [
    {
      head: 'スマートパーキングとは？',
      subHead: '',
      desc: 'スマートパーキングは、現代の都市環境で駐車問題を解決し、効率的な駐車管理を実現するために先進技術とインフラを活用する駐車スペースです。このような駐車スペースは、さまざまな技術要素を統合し、駐車プロセスを最適化し、車両利用者により便利な体験を提供します。<br/>このようなシステムを実装するために、デジタルツイン技術を適用して、現実の駐車場と同一のデジタル表現を作成し、データを送信する必要があります。主な特徴と機能は以下の通りです。',
      dot: ''
    },
    {
      head: 'スマートパーキングの機能',
      subHead: '',
      desc: 'スマートパーキングは、さまざまな先進技術を活用して駐車管理とユーザーエクスペリエンスを向上させるさまざまな機能を提供します。これらの機能は、駐車場の管理者と車両利用者の両方に利益をもたらし、都市の駐車問題を解決するのに役立ちます。以下は、通常スマートパーキングで提供される主要な機能のリストです。',
      dot: {
        a: '<strong>• リアルタイムの位置追跡：</strong> AIカメラ、センサー、IoT技術を使用して、駐車場内の車両の位置をリアルタイムで追跡し、管理します。これにより、空いている駐車スペースを迅速に特定できます。',
        b: '<strong>• 駐車ガイドと案内：</strong> ユーザーに空いている駐車スペースの場所をモバイルアプリまたはディスプレイで案内し、駐車を容易にします。',
        c: '<strong>• 駐車予約：</strong> ユーザーは遠隔地から駐車スペースを予約し、到着前に確保できます。',
        d: '<strong>• 交通流量分析：</strong> 駐車場内のセンサーとカメラを使用して、駐車場が最も混雑している時間帯を分析し、この情報をユーザーに提供します。',
        e: '<strong>• ナンバープレート認識：</strong> 車両のナンバープレートを認識して、違法駐車、駐車時間超過、その他の違反を自動的に検出および管理します。',
        f: '<strong>• セキュリティと安全性：</strong> 駐車場内にCCTVカメラとセンサーを設置して、車両と駐車施設の安全性を向上させます。',
        g: '<strong>• データ分析と最適化：</strong> 収集されたデータを分析して駐車場の運用を最適化し、改善点を見つけます。これにより、駐車場の管理者はリソースを効率的に活用できます。'
      }
    },
    {
      video: video,
      link: '/jp/enterprise/manufacturing/process',
      head: 'スマートパーキング技術',
      subHead: 'AIカメラとビジョン技術',
      desc: 'AIカメラは、高度なコンピュータービジョン技術を基にしたカメラシステムです。駐車場内のすべての活動を監視し、分析するために使用されます。たとえば、車両のナンバープレート、車両の種類や色、車両の位置や方向などをリアルタイムで検出し、認識します。この情報は、駐車場の運用を最適化し、車両利用者に駐車情報を提供するために活用されます。さらに、違法駐車や違反の識別と管理にも使用されます。',
      dot: ''
    },
    {
      head: '',
      subHead: 'IoT（インターネット・オブ・シングス）',
      desc: 'IoTは、駐車場内に設置されたさまざまなセンサーやデバイスを介してデータを収集し、送信する技術です。センサーは、車両の移動、駐車スロットの可用性、駐車場内の温度、湿度など、さまざまな情報をリアルタイムで収集し、この情報は駐車場の運用と管理に使用され、車両利用者に便利な駐車環境を提供します。',
      dot: ''
    },
    {
      head: '',
      subHead: 'モバイルアプリとウェブサービス',
      desc: 'モバイルアプリとウェブサービスは、車両利用者に駐車情報を提供し、駐車プロセスを管理するために使用されます。ユーザーはモバイルアプリを使用して空いている駐車スペースの場所を確認し、予約することができます。また、駐車料金を支払い、駐車状態をモニタリングすることもできます。さらに、ウェブサービスを介して、ユーザーはコンピューターブラウザからも駐車情報を確認し、管理でき、車両利用者にさまざまな便益を提供します。',
      dot: ''
    },
    {
      head: '',
      subHead: 'ビッグデータ分析',
      desc: 'ビッグデータ分析は、スマートパーキングで収集された大量のデータを深堀り分析するプロセスを指します。収集されたデータは、車両利用者の行動パターン、駐車場内の混雑度、駐車料金ポリシーの効果など、さまざまな側面で分析されます。',
      dot: {
        a: '<strong>• 駐車場の運用の最適化：</strong> 駐車場の運用時間、駐車スロットの配置、駐車料金ポリシーなどを最適化し、効率を向上させます。',
        b: '<strong>• ユーザーエクスペリエンスの向上：</strong> ユーザーの行動を理解し、これに基づいて駐車プロセスを改善し、ユーザーエクスペリエンスを向上させます。',
        c: '<strong>• 収益の増加：</strong> ビッグデータ分析を通じて駐車料金ポリシーを最適化し、収益を増加させる方法を見つけます。',
        d: '<strong>• 予測モデルの開発：</strong> データ分析を通じて将来の駐車スペースの可用性、駐車需要などを予測するモデルを開発し、運用計画を立てます。',
        e: '<strong>• 問題の識別と対応：</strong> データ分析を通じて駐車場内の問題を識別し、迅速な対応策を講じます。たとえば、駐車場内の混雑を防ぐために追加の駐車スペースを割り当てることがあります。'
      }
    },
    {
      head: 'スマートパーキングの事例',
      subHead: '韓国の休憩所 - ビーコンを活用した駐車管理',
      desc: '韓国の一部の駐車場では、ビーコン技術を活用してスマートパーキングを運用しています。ビーコンはモバイルアプリと連動し、駐車スペースのリアルタイムの可用性を提供し、駐車場内での車両を識別して出入りを管理します。また、ユーザーにモバイルアプリを通じて駐車情報を提供し、支払いを簡単に処理できるよう支援します。',
      dot: ''
    },
    {
      head: '',
      subHead: 'アメリカ、ロサンゼルス - リアルタイム駐車情報',
      desc: 'ロサンゼルスでは、ダウンタウンエリアの駐車問題を解決し、リアルタイムの駐車情報サービスを提供するためにスマートパーキングロットを導入しています。このサービスを通じて、ドライバーはモバイルアプリで利用可能な駐車スペースの場所や駐車料金情報を確認でき、駐車がより便利になります。',
      dot: ''
    },
    {
      head: '',
      subHead: '中国、上海 - 自律駐車ロボット',
      desc: '上海の一部の駐車場では、自律駐車ロボットを使用して駐車スペースでの自動駐車と車両の回収を行っています。ドライバーは駐車場の入り口に車両を置き、ロボットシステムを利用して駐車時間を短縮し、便益を向上させることができます。',
      dot: ''
    },
    {
      head: '',
      subHead: '日本、東京 - 自動駐車料金支払い',
      desc: '東京の一部の駐車場では、ナンバープレート認識技術とモバイルアプリを介して駐車料金を自動計算および処理します。これにより、ドライバーは迅速に駐車場を出ることができ、現金やクレジットカードでの支払いの手間が省かれます。',
      dot: ''
    },
    {
      head: 'スマートパーキングの将来展望',
      subHead: '',
      desc: '将来、スマートパーキングは自動運転車と統合して、駐車プロセスを最適化し、自動駐車機能を活用するより重要な役割を果たすと予想されています。さらに、高速データ通信技術の進化により、リアルタイムデータ共有が促進され、機械学習と人工知能技術が駐車予測とユーザーパターン分析を向上させるでしょう。スマートパーキングは、交通管理と制御の重要な要素としてスマートシティプロジェクトに統合され、駐車データは都市計画と混雑管理に活用されるでしょう。また、セキュリティ技術もさらに強化され、車両と駐車施設の安全性が確保されるでしょう。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWBデバイスを労働者や機器に取り付けてリアルタイムの位置追跡をサポートする製品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/jp/enterprise/construction/safety'
      },
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/jp/enterprise/construction/asset'
      },
      {
        img: constructionEquipment.thumb,
        data: 'constructionEquipment',
        link: '/jp/enterprise/construction/equipment'
      }
    ]


  }


}
