
import { IconOsOff, IconOsOn, IconRobotOff, IconRobotOn } from '@assets/images/main/icon';
import { IntegrationLabel, IntegrationLabel1, IntegrationLabel2, IntegrationLabel3, integrationOverviewDesktop11, integrationOverviewDesktop12, integrationOverviewDesktop13, integrationOverviewDesktop14, integrationOverviewDesktop15, integrationOverviewDesktop21, integrationOverviewDesktop22, integrationOverviewDesktop23, integrationOverviewDesktop24, integrationOverviewDesktop31, integrationOverviewDesktop32, integrationOverviewDesktop33, integrationOverviewDesktop34, integrationOverviewMobile11, integrationOverviewMobile12, integrationOverviewMobile13, integrationOverviewMobile14, integrationOverviewMobile15, integrationOverviewMobile21, integrationOverviewMobile22, integrationOverviewMobile23, integrationOverviewMobile24, integrationOverviewMobile31, integrationOverviewMobile32, integrationOverviewMobile33, integrationOverviewMobile34, integrationOverviewTablet11, integrationOverviewTablet12, integrationOverviewTablet13, integrationOverviewTablet14, integrationOverviewTablet15, integrationOverviewTablet21, integrationOverviewTablet22, integrationOverviewTablet23, integrationOverviewTablet24, integrationOverviewTablet31, integrationOverviewTablet32, integrationOverviewTablet33, integrationOverviewTablet34 } from '@assets/images/main/integration';
import { IntegrationDetailVideoDesktop1, IntegrationDetailVideoDesktop2, IntegrationDetailVideoDesktop3, IntegrationDetailVideoMobile1, IntegrationDetailVideoMobile2, IntegrationDetailVideoMobile3, IntegrationDetailVideoTablet1, IntegrationDetailVideoTablet2, IntegrationDetailVideoTablet3 } from '@assets/images/main/video/integration';


export default {

    mainPage: {
        product: {
            title: '适合使用环境的包配置',
            desc: '请查看在医疗环境中可以构建的产品包配置。',
            robotBasic: {
                title: '基本配送机器人包',
                desc: '室内定位系统与机器人系统的协调，简单配置实现快速构建',
            },
            delivery: {
                title: '无人装卸包',
                desc: '配置了使配送机器人能够无人装卸的包',
            },
            disinfection: {
                title: '高风险管理包',
                desc: '配置了能够管理高感染风险医疗物品配送的包',
            }
        },
    },

    banner: {
        label: IntegrationLabel,
        title: '为了更广泛扩展的集成系统',
        desc: '',

        schemeTitle: 'ORBRO 提议的摄像头系统。',
        schemeDesc: '需要帮助做决定吗？',
        schemeDesc2: '请联系我们，出入口专家将为您提供帮助。',
        phone: '1522-9928',
    },

    bannerData: [
        {
            icon: IconRobotOn,
            iconInv: IconRobotOff,
            image: '',
            iconText: '机器人系统',
            sub: '新',
            link: '/cn/integration/robot'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: '新',
            link: '/cn/os'
        },
    ],

    video: {
        title: '位置追踪技术与室内配送机器人的结合',
        desc: [
            { text: '基于ORBRO的定位追踪系统，机器人系统集成帮助您一目了然地监控机器人的实时位置和流程进展。' },
        ]
    },

    delivery: {
        label: IntegrationLabel1,
        title: '配送机器人系统集成',
        desc: '利用ORBRO位置追踪系统提供机器人的实时位置和工作状态监控。',

        card: [
            {
                videoTag: true,
                video: {
                    desktop: IntegrationDetailVideoDesktop1,
                    tablet: IntegrationDetailVideoTablet1,
                    mobile: IntegrationDetailVideoMobile1
                },
                title: '机器人位置追踪与管理的开始',
                desc: '基于ORBRO定位追踪系统进行实时机器人位置追踪，通过集成各种设备来监控和支持机器人的实时运营状况。',
                img: {
                    desktop: integrationOverviewDesktop11,
                    tablet: integrationOverviewTablet11,
                    mobile: integrationOverviewMobile11
                },
            },
            {
                title: '与ORBRO定位追踪系统的结合',
                desc: '通过ORBRO的集成系统轻松实现机器人和定位追踪系统的集成。',
                img: {
                    desktop: integrationOverviewDesktop12,
                    tablet: integrationOverviewTablet12,
                    mobile: integrationOverviewMobile12
                },
            },
            {
                title: '工作状态监控',
                desc: '实时检测机器人的工作状态并立即响应。',
                img: {
                    desktop: integrationOverviewDesktop13,
                    tablet: integrationOverviewTablet13,
                    mobile: integrationOverviewMobile13
                },
            },
            {
                videoTag: true,
                video: {
                    desktop: IntegrationDetailVideoDesktop2,
                    tablet: IntegrationDetailVideoTablet2,
                    mobile: IntegrationDetailVideoMobile2
                },
                title: '机器人位置监控',
                desc: '实时提供每个机器人的当前位置。',
                img: {
                    desktop: integrationOverviewDesktop14,
                    tablet: integrationOverviewTablet14,
                    mobile: integrationOverviewMobile14
                },
            },
            {
                videoTag: true,
                video: {
                    desktop: IntegrationDetailVideoDesktop3,
                    tablet: IntegrationDetailVideoTablet3,
                    mobile: IntegrationDetailVideoMobile3
                },
                title: '多机器人管理',
                desc: '可以同时有效管理多个机器人。',
                img: {
                    desktop: integrationOverviewDesktop15,
                    tablet: integrationOverviewTablet15,
                    mobile: integrationOverviewMobile15
                },
            },
        ]
    },

    loading: {
        label: IntegrationLabel2,
        title: '无人装卸系统集成',
        desc: '通过将装载设备、协作机器人和储物柜集成到一个系统中，实现从装载到卸载的无人操作。',
        card: [
            {
                title: '装载设备集成',
                desc: '通过装载设备集成可以呼叫机器人，并通过ORBRO接收机器人到达和装载准备状态，自动进行操作。',
                img: {
                    desktop: integrationOverviewDesktop21,
                    tablet: integrationOverviewTablet21,
                    mobile: integrationOverviewMobile21
                },
            },
            {
                title: '协作机器人集成',
                desc: '通过协作机器人集成，确认配送机器人到达时的状态，自动进行卸载。',
                img: {
                    desktop: integrationOverviewDesktop22,
                    tablet: integrationOverviewTablet22,
                    mobile: integrationOverviewMobile22
                },
            },
            {
                title: '智能储物柜集成',
                desc: '通过定制仪表板，用户可以轻松监控重要数据，快速理解数据并作出有效决策。',
                img: {
                    desktop: integrationOverviewDesktop23,
                    tablet: integrationOverviewTablet23,
                    mobile: integrationOverviewMobile23
                },
            },
            {
                title: '实时状态检查',
                desc: '在一个屏幕上提供实时库存状态、运营状态以及所有设备的运行状态。',
                img: {
                    desktop: integrationOverviewDesktop24,
                    tablet: integrationOverviewTablet24,
                    mobile: integrationOverviewMobile24
                },
            },
        ]
    },

    service: {
        label: IntegrationLabel3,
        title: '消毒系统集成',
        desc: 'ORBRO OS 强大而方便，但过程并不复杂。任何产品都可以轻松设置并立即使用。',
        card: [
            {
                title: '等离子消毒装置',
                desc: '迅速升温至医疗废物和器械灭菌所需的温度，缩短灭菌周期时间，通过均匀供应气化的灭菌剂提供完美的灭菌效果。',
                img: {
                    desktop: integrationOverviewDesktop31,
                    tablet: integrationOverviewTablet31,
                    mobile: integrationOverviewMobile31
                },
            },
            {
                title: 'UVC消毒装置',
                desc: '利用UVC的高杀菌力和LED灯的安全性，提供安全且高效的杀菌效果。',
                img: {
                    desktop: integrationOverviewDesktop32,
                    tablet: integrationOverviewTablet32,
                    mobile: integrationOverviewMobile32
                },
            },
            {
                title: 'HPV消毒装置集成',
                desc: '对经过污染区域的配送机器人进行非接触式自我消毒/灭菌。',
                img: {
                    desktop: integrationOverviewDesktop33,
                    tablet: integrationOverviewTablet33,
                    mobile: integrationOverviewMobile33
                },
            },
            {
                title: '提供设备工作状态的实时监控',
                desc: '不仅提供集成消毒设备的连接状态和设备状态，还提供工作状态的实时监控功能。',
                img: {
                    desktop: integrationOverviewDesktop34,
                    tablet: integrationOverviewTablet34,
                    mobile: integrationOverviewMobile34
                },
            },
        ]
    },
}
