import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconLocation, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconUserCheck, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/medicalPatient/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/medicalPatient/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/medicalPatient/video/sub/rtlsVideo3.mp4";

export default {
    banner: {
      title: 'リアルタイム患者トラッキング',
      desc: '医療施設には特別なケアが必要な患者がいます。24時間体制で直接監視する必要はありません。患者の位置をリアルタイムで正確に測位します。',
    },
    video: {
      title: 'より安全に患者を管理する方法',
      desc: 'リアルタイム位置測位システム（RTLS）技術は、患者の位置をリアルタイムで測位します。患者管理に関わる人員とコストを大幅に削減しながら、より安全なシステムを構築できます。',
      play: video,
    },
    func: [
      {
        title: 'リアルタイム位置測位',
        desc: '患者の現在の位置を医療施設内でリアルタイムに測位します。もし患者が施設内のアクセス禁止エリアに接近する場合、管理者に即時の通知を送ります。',
        sub1: '高い精度の提供',
        icon1: IconRulerGray,
        sub2: '迅速なデータ統合',
        icon2: IconShareGray,
        video: subVideo1,
      },
      {
        title: '感染症対策',
        desc: 'COVID-19以降、感染症の早期対応はさらに重要となりました。感染者の移動経路を測位し、接触者や疑わしい患者を特定することで、施設内での拡散を早期に防止できます。',
        sub1: 'リスクのある資産の指定',
        icon1: IconWarningGray,
        sub2: 'クラウドへのデータ保存',
        icon2: IconCloudCheckGray,
        video: subVideo2,
      },
      {
        title: '転倒検知',
        desc: '高齢者を含む、歩行が不安定な患者は常に転倒のリスクが存在します。即座に対応できる転倒検知システムを構築します。',
        sub1: '高い精度の提供',
        icon1: IconRulerGray,
        sub2: '迅速なデータ統合',
        icon2: IconShareGray,
        video: subVideo3,
      },
    ],
    subfunc: {
      title: 'さまざまな機能',
      desc: '医療施設において最も重要なのは患者の健康です。いつでもどこでも患者の状態をモニタリングし、状況が発生した場合には迅速な初期対応が必要です。ORBROは、常に安全な医療施設を維持するために以下の機能を提供します。',
      data: [
        {
          icon: IconChartRadius,
          title: 'レポートと分析',
          desc: '患者の移動経路を基に統計的なレポートやデータ分析を生成できます。',
        },
        {
          icon: IconBell,
          title: 'リアルタイムアラーム機能',
          desc: '患者の移動に異常な状況が発生した場合には即座にアラームを送信し、対応できます。',
        },
        {
          icon: IconRoute,
          title: '資産の移動履歴',
          desc: '特定の患者の経路を測位して安全事故を防止します。',
        },
        {
          icon: IconDashboard,
          title: '状況把握',
          desc: '特定の患者の現在の位置をリアルタイムで把握します。',
        },
        {
          icon: IconLocation,
          title: '屋内ナビゲーション',
          desc: '迷子の患者や訪問者を特定し、医療スタッフがサポートできるようにします。',
        },
        {
          icon: IconUserCheck,
          title: '利用体験の向上',
          desc: '病院を訪れる人々により高い信頼を与えることができます。',
        },
        {
          icon: IconChainLink,
          title: 'データ統合',
          desc: 'RFID、IoT、セキュリティ、ビデオなどのシステムを連携させ、効率的に活用できます。',
        },
        {
          icon: IconSecurity,
          title: 'データセキュリティ',
          desc: '高いデータセキュリティレベルを維持し、信頼性の高いソリューションを提供します。',
        },
      ],
    },
    system: {
      title: 'システム構成',
      img: rtlsSystem,
    },
    productTitle: '製品構成',
    product: [

      {
        tag: 'peoplePackage',
      },
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'medicalWaste',
      },
      {
        data: 'logisticsSafety',
      },
      {
        data: 'blogMedical',
      },
      {
        data: 'blogOffice',
      },
    ],
  }
  