import React from "react";
import {
  StyledFaqItem, StyledFaqItemDescription, StyledFaqItemDetail,
  StyledFaqItemTitle, StyledFaqItemTitleIcon
} from "./Item.style";

import ArrowUpIcon from "@assets/images/services/common/arrow-chevron-up.svg";
import ArrowDownIcon from "@assets/images/services/common/arrow-chevron-down.svg";
import { GRAY_600 } from "@assets/styled/color.style";

import { Trans } from "react-i18next"
import i18n from "@pages/i18n";
import { DialogSpanText } from "@pages/Enterprise/Solution/components/Banner/Modal/Modal.style";
import { Link } from "react-router-dom";
import { ImgWH } from "@pages/CommonStyle/common.style";
import { HeadingH3 } from "@components/Text/Heading/Heading.style";

interface FaqItemProps {
  title: string,
  description: string,
  isOpen: boolean,
  handleClick: () => void;
}

const FaqItem = (props: FaqItemProps): React.ReactElement => {
  const { title, description, isOpen, handleClick } = props;

  const currentLang = i18n.language;

  return (
    <StyledFaqItem >
      <StyledFaqItemTitle onClick={handleClick}>
        <HeadingH3 size="xxxsmall">
          <Trans i18nKey={title} />
        </HeadingH3>

        <StyledFaqItemTitleIcon>
          <ImgWH w="20" h="20" src={isOpen ? ArrowUpIcon : ArrowDownIcon} alt="faq-item-title-img" />
        </StyledFaqItemTitleIcon>
      </StyledFaqItemTitle>

      <StyledFaqItemDetail isOpen={isOpen}>
        <StyledFaqItemDescription
          size="medium"
          color={GRAY_600}>
          <p>
            <Trans i18nKey={description} />

            <DialogSpanText deco={false} color={"#000"}>
              <Trans i18nKey={"simple.requestHead"} />
            </DialogSpanText> {'\u00A0'}
            <Link to={currentLang === 'ko' ? '/request' : `/${currentLang}/request`}>
              <DialogSpanText deco={true} color={"#2A70F0"}>
                <Trans i18nKey={"simple.request"} />
              </DialogSpanText> {'\u00A0'}
            </Link>
            <DialogSpanText deco={false} color={"#000"}>
              <Trans i18nKey={"simple.requestTail"} />
            </DialogSpanText>
          </p>
        </StyledFaqItemDescription>
      </StyledFaqItemDetail>
    </StyledFaqItem>
  )
}

export default FaqItem;