import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet: '',
  desc: 'RTLS（实时定位系统）技术优化了办公室行业的位置和资产管理，并通过监控员工活动提高工作效率。此外，通过实时位置追踪，可以智能管理诸如会议室预订和任务状态跟踪等业务流程。',
  keywords: 'RTLS，工作管理，出勤管理，劳动管理，智能办公，第四次产业革命，定位，时间管理，事业成长',

  ogTitle: '',
  ogDesc: '',
  ogSite: "2023年，构建智能办公的方法 :: Orbro Blog",

  ogUrl: 'https://orbro.io/cn/blog/office',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EOZq-6QxpQF15gjoXPsuEITf_QOrCQngx-0mGH811amVcoMdPYM3ilqBy7Xoz0830SCxjlpjUuueuzKWtZiEjWuVteCxytua-WdJxpnvVidHfU4TRtkkXlw0xHxxUhNQxvbb5bAwoYWtF7PCka-Od-il6dCHS5WmHz1gttbrgzmv7WLmU9SQYkEs6xvUmw1kB84m4rT91EAavf9ENZ1GzxhJtFsM23wREpJzxhm0kBUbz6YMna9plYIlxFD6SfUcvqI3yduebDgFVrNqNzYuSg4MXNYrgd5besqzBTlYJ9q2D_uLNLNVin8CekS0u6HEWRqm2LjOdw5tZCbsATqf_KZTaVe7bB5zLAJv5WyYdCvS-WJtkrZis6Lr7ks9hAcVUZ6OAnu-oOnotcsNqBM6Slmh6SgL637kMZVCCL2ZRIV4M2zMmtkSgoIpl2EhAmsO_MbEAVPS6hqULxTA2MUhF7VknsXzpP4-gqDtg93jTb6M1jJSVcZMeg7b8eWXjGDAPvZ3DhZ52xs5CsgxNFiHcORnJ3r4L2BhkrUEHxdaIxtNcpYnVaJEaugtx1yYoSJhypYwIMxeuw56xgtDuhwSeXp_M47ijuLYAQH3D_e2y1-ze3pGxLp-ZUNSwEpsIQE75duXj6pGnykP_X86pC_9RvRqtGCauBh03Q5lbXcLi8uZU3feFRPIEBpcuqWSzOvc9OF-gGQQNAJvg_C-XlG7DU5noByHvqhzA_NWYyevnv_GdUqXP86pPL_HXVVFBq9tdJewYtLAEu9moGil4If2TKpgbLU2DqEFZbk53pVq6nNEEuvkIxnmmqT5FRECRIjoycCaKpClyWU6KflQXTh_ajli8XstUOEb9464PjQbEk7hur5AQveRex4wVq8OI5z2_fhrjlcMHuCN8Mi5xDJO1YX2bYT6HuGrN8A2M4qzLvoLqv9qpwf8LN-eddAFPRRxMvxKMEcV4vpwmPsrDe05R40Iwju-ALvmfJgcWEU9W4wFW3uCzbpa7bsF6sM2OYa8xC1DXZpwBcMgXHr1cy68Fgc3-Bz3bmlRBq_DoIxX_B-R-SCQRPmfELhiX_1bX6T21K1tX29iMtlobYwfW0Rm-I9toicT-RbtqAIkwLb9PKEt9Tnbxku321EMkp-qP3ZSVtC1rn5aBH9KG8v5s-FQrNpXvcFgBGJQ11ubG2TJVGND98TOtFmJODYe6lRFuvgA6v8vPcEASZfEZ9Ud5d26C6v-c9piIwRf8jrciHUSEW8heb3vtIngX_afHpGYI_yb63svGjNo9f12aCQxY8HTUu9YwnDCRFXbEiEuvHdqibatUmRWd1Ov15pW7Bfn7ERM721O-P4lkjWurLZ-7IQkd_kod2tNUENcQjT5UCMVuCNgvSmsjHm9jUuSmqjhz_N_E4gfYUImTrGgMJaW1hsQdmlIE3rNGnoSDj4L58a8B7S6f9hFQqRwyqvbeFfXP4Or8aFnxIzM-cJjgL3xcE7IIyza9Q-JN1MuqyK9eC_2nFrT7mx-hMjBW11zCF93GQ4zKO5ILybEfpIEFnZSMRUkkevmWEqohIRPhuI6uS1H2tm_Fg=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '构建智能办公室的方法 - 2023年',
  date: '2023-05-21',
  tag1: '#智能办公室',
  tag2: '#实时定位系统',
  tag3: '#第四次工业革命',
  tag4: '#工作管理',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: '在办公楼内实现室内定位可能会带来很多困难。<br/>当办公室、大厅、楼层增多时，访客可能会迷路，花费很多时间找到目的地。在这种情况下，企业需要使用更智能的地图。<br/>最新的技术可以确保建筑物内的便捷导航，提高客户和访客的满意度。',
      dot: ''
    },
    {
      head: '办公室室内导航',
      subHead: '',
      desc: '使用室内地图工具可以有效解决办公室导航问题。<br/>通过数字地图，可以快速搜索办公室，成功预订会议室以准备活动。<br/>如果该会议室已经被使用，也可以实时查看，避免重复预订。这可以防止由于疏忽而导致的时间浪费。<br/>这一系统基于建筑物内安装的多个特殊标签或信标。这些标签会定期被读写器检测到并传送无线信号到服务器。<br/>当用户携带预先安装的智能手机进入建筑物时，他们将获得交互式地图，可以建立路径并执行其他操作。<br/><br/>可以使用BLE、Wi-Fi和UWB等技术进行办公室内部的导航。<br/>这有助于定义位置、查找所需空间，甚至获得员工安全保障和详细分析。<br/>根据统计数据，如果在建筑物内找不到方向，工作效率将平均减少10-20%。访客和员工必须浪费大量时间寻找地点，这可能导致活动延迟或迟到。<br/>室内定位系统可以最大程度地减少这些问题。在实施到商务中心的基础设施中，可以获得以下优势。',
      dot: ''
    },
    {
      head: '',
      subHead: '实时逐步导航',
      desc: '可以创建通往感兴趣的地点（POI）的最短和最便捷的路径。此外，根据用户的位置发送提醒。<br/>办公室地图软件简化了建筑物或商务中心内部的导航。引入交互式地图可以优化工作时间，并提高员工解决日常任务的舒适度。',
      dot: ''
    },
    {
      head: '',
      subHead: '地图上的对象搜索',
      desc: '大多数情况下，普通平面图看起来像静态图像，但交互式地图通过平面扩展性扩展了用户的可能性。<br/>用户可以查看整个楼层，也可以查看单独的房间。如果需要，可以更改比例尺并详细审查一些特定的办公室布局。<br/>扩展性使地图更加灵活，有助于更轻松地在地图上找到对象。用户或员工只需要使用移动应用程序就能找到所需的房间、会议室、洗手间或技术间。结合了路径建立的可能性，将任何办公室转变为真正创新的空间。',
      dot: ''
    },
    {
      head: '',
      subHead: '与访客的社交互动',
      desc: '在许多办公室中，最常见的问题之一是与人们的交往难度，特别是当他们不在他们的座位上时。 <br/>位置共享功能足以找到所需的员工。由于有了地图，用户不需要在办公室和楼层间穿梭奔跑。该系统极大地简化了社交互动，节省了时间。',
      dot: ''
    },
    {
      head: '',
      subHead: '限制区域监控',
      desc: '许多企业的室内导航成为监控限制区域的有效工具。这些地点可能是存放文件、实验室和其他类似设施的库房。 <br/>管理员可以通过使用室内地图技术轻松跟踪谁进入这些区域，并防止未经授权的进入。<br/>该系统可以通知人们不要进入限制区域。再也不需要设置标志了。<br/>限制区域在应用程序中被标记为“无法访问”，并向员工发出相应区域的警告。',
      dot: ''
    },
    {
      head: '',
      subHead: '会议室占用率分析',
      desc: '通过该平台，可以获得有关办公室和会议室占用率的数据。系统进行持续的信息收集并实时提供实际数据。<br/>信标或标签可以通知用户哪些工作区域被使用或哪些房间已经预订。',
      dot: ''
    },
    {
      head: '',
      subHead: '安全管理',
      desc: '提供紧急情况的信息，以确保人员及时疏散。由于一些房间隔音很好，员工可能听不到警报。<br/>但是，如果发生火灾等事故，他们将使用移动应用程序获取信息。<br/>然后，该系统将找到最近的员工，并引导他们前往现场提供帮助或解决问题。<br/>社交疏远。在新冠病毒大流行期间，员工和访客可以避免感染。由于可以密切监视人与人之间的距离，因此可以及时采取措施来维持安全。<br/>办公室设备也可以跟踪是否进入建筑物外。一旦设备离开建筑物，立即通知这一事实。',
      dot: ''
    },
    {
      head: '',
      subHead: '',
      desc: '',
      dot: ''
    },
  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: "将UWB终端机安装在工作者或设备上，帮助实时追踪位置的产品。",
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]


  }


}