import { BlogRTLSimg1 } from "@assets/images/blog";
import { CommonState } from "@redux/reducers/commonReducer";
import { useSelector } from "react-redux";
import * as banner from "@src/pages/Enterprise/Blog/Common/BlogBannerImg";

export default {
    constructionSafety: {
      tag: 'ソリューション',
      title: '作業員の安全管理',
      desc: '作業員の安全は何よりも重要です。高危険環境で作業員の安全を確保しないことは、生産性の問題だけでなく、法的責任も引き起こす可能性があります。',
      link: '/jp/enterprise/construction/safety',
    },
    constructionAsset: {
      tag: 'ソリューション',
      title: '建設資材の測位と管理',
      desc: '正確な資材管理は生産性を向上させます。資源の無駄を削減し、品質を維持することは、建設現場で重要な要素です。',
      link: '/jp/enterprise/construction/asset',
    },
    constructionEquipment: {
      tag: 'ソリューション',
      title: '重機の管理',
      desc: '建設現場では重機が重要な要素です。適切な保守と事故予防が不可欠です。重機を適切に管理すれば、安全を維持しながらコストを削減し、現場の生産性を顕著に向上させることができます。',
      link: '/jp/enterprise/construction/equipment',
    },
    logisticsAsset: {
      tag: 'ソリューション',
      title: '在庫の測位と管理',
      desc: '在庫管理はサプライチェーンの効率性とスムーズな運営を支援する重要な要素です。在庫の位置をリアルタイムで把握することで、在庫レベルを効果的に監視し、在庫の移動を最適化することができます。',
      link: '/jp/enterprise/logistics/asset',
    },
    logisticsSafety: {
      tag: 'ソリューション',
      title: '倉庫作業員の安全管理',
      desc: '作業員の安全は何よりも重要です。作業員の安全を確保しないと、生産性の問題だけでなく、法的責任も生じる可能性があります。また、安全な物流現場は企業のイメージと関係者の信頼形成に重要な役割を果たします。',
      link: '/jp/enterprise/logistics/safety',
    },
    officeAsset: {
      tag: 'ソリューション',
      title: '資産管理',
      desc: '効果的な資産管理は時間と財務上の損失を防ぐために重要です。明確で迅速な資産管理により、スムーズなビジネス運営が可能となります。',
      link: '/jp/enterprise/office/asset',
    },
    manufacturingProcess: {
      tag: 'ソリューション',
      title: 'リアルタイムのプロセス管理',
      desc: '製造施設の進捗状況をリアルタイムで把握することで、効果的な管理が可能です。正確で客観的なデータを活用して製造ラインを改善し、効率性を向上させることができます。これは製造業の中核です。',
      link: '/jp/enterprise/manufacturing/process',
    },
    manufacturingAsset: {
      tag: 'ソリューション',
      title: 'リアルタイムの在庫管理',
      desc: '製造施設は原材料や半製品など多くの在庫を保持しています。これらを適切に管理することは、時間と経済の観点から非常に重要です。製造業の効率に大きな影響を与える重要な要素です。',
      link: '/jp/enterprise/manufacturing/asset',
    },
    medicalPatient: {
      tag: 'ソリューション',
      title: '患者の測位',
      desc: '医療施設には特別な管理が必要な患者がいます。患者の位置をリアルタイムで測位することで、常時監視する必要がありません。',
      link: '/jp/enterprise/medical/patient',
    },
    medicalWaste: {
      tag: 'ソリューション',
      title: '医療廃棄物の管理',
      desc: '医療廃棄物のリアルタイムな状況と位置を測位することで、事故を予防し、安全な医療サービスを提供することができます。法的規制に適合し、人間の健康と資源保護に貢献する必須の作業です。',
      link: '/jp/enterprise/medical/waste',
    },

    retailExhibition: {
      tag: 'ソリューション',
      title: '展示館の来場者管理と分析',
      desc: '展示会場の訪問者の動線を分析し、資産を簡単に管理することで、効率的な施設運営が可能となります。正確で客観的なデータは新しいビジネスの成長を促進できます。',
      link: '/jp/enterprise/retail/exhibition'
    },



    blogConstruction: {
      tag: 'ブログ',
      title: 'RTLSがもたらす建設業界の未来',
      desc: '建設業界も他の分野と同様に、効率性、生産量、安定性を向上させるための方法を常に模索しています。先進企業はこれらの課題に対処するため、さまざまな最先端技術を導入しようとしています。',
      link: '/blog/rtls',
    },
    blogRTLS: {
      tag: 'ブログ',
      title: '建設現場でのRTLS',
      desc: 'RTLSシステムを使用して建物内部の位置を特定し、測位する方法について調査します。現在、多くの建設会社がリアルタイムで資産を見つけ、オブジェクトを移動させるための測位システムの導入を開始しています。これにより、建設現場の活動を最適化し、労働者の効率を向上させることができます。',
      link: '/blog/rtls',
    },
    blogActiveRfid: {
      tag: 'ブログ',
      title: 'アクティブRFIDベースのRTLS',
      desc: 'アクティブRFIDは、デバイス間で情報を送信するワイヤレス通信形式です。アクティブRFIDタグは電源を持ち、長距離で信号を送信することができます。特にリアルタイムな資産測位に最適な製品です。',
      link: '/blog/activerfid',
    },
    blogForklift: {
      tag: 'ブログ',
      title: 'フォークリフトの安全を向上させるRTLSソリューション',
      desc: 'フォークリフトは日常的に点検して、動作状態が適切であることを確認する必要があります。点検リストには、駐車ブレーキ、照明、油圧オイルレベルなどが含まれます。問題が見つかった場合は、すべての欠陥を即座に修理する必要があります。少なくとも月に1回は、資格のある技術者がより詳細な検査を行うことが望ましいです。',
      link: '/blog/forklift',
    },
    
    blogOffice: {
      tag: 'ブログ',
      title: '2023年、スマートオフィスの構築方法',
      desc: 'オフィスビル内での屋内位置測位は、さまざまな困難を引き起こす場合があります。オフィス、ホール、階数が増えるほど、訪問者は迷子になりやすく、建物の探索に多くの時間を費やす可能性があります。このような状況では、よりスマートなマップを使用する必要があります。最新の技術は、建物に関連する便利なナビゲーションを提供し、顧客と訪問者の満足度を向上させます。',
      link: '/blog/office',
    },
    
    blogFactory: {
      tag: 'ブログ',
      title: '未来の工場へようこそ：Industry 4.0でのリアルタイム測位の役割',
      desc: '工場の未来は革新的な変化を経験するでしょう。これはまもなく現実となるものです。その背景には、IoT（モノのインターネット）、人工知能、ビッグデータなどの先端技術があります。そして、ここにRTLS技術も革新をリードする重要な要素です。',
      link: '/blog/factory',
    },
    blogMedical: {
      tag: 'ブログ',
      title: '医療業界におけるRTLS',
      desc: 'COVID-19パンデミックを経て、医療業界のIT技術の発展はますます重要性を増しています。特に患者の追跡と管理が技術の中心になっています。リアルタイムロケーションシステム（RTLS）と呼ばれるこの技術は、将来的には医療業界全体でさまざまな用途に活用され、現在よりもはるかに優れたサービスを提供するのに役立つでしょう。',
      link: '/blog/access'
  },
  
  };
  