import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../../cn/main/product/productImg";
import productPurchase from "../../cn/main/product/productPurchase";

export default {

    
  helmet : '| 用于患者管理的实时位置追踪',
  desc : '它支持医务人员通过实时跟踪患者的当前位置，并有效分配治疗和监测所需的资源，以便快速应对紧急情况。这有助于提高患者安全性并改善医疗服务质量。',
  keywords : '患者位置追踪、患者管理、医院位置追踪、医院管理、智能医院、患者追踪、医疗设施管理、痴呆患者管理、阿尔茨海默病患者管理、老年患者管理、医院运营、医疗中心、养老院管理',

  ogTitle : '',
  ogDesc : '',
  ogSite : '用于患者管理的实时位置追踪 :: ORBRO 博客',
  ogUrl : 'https://orbro.io/cn/blog/patientTracking',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4NuWirMGmbqpgIlWdCqK-ppWpzcgz_tlLlZI_o-5cHhj2j9qDRwsnEZiV8vOrMOlgGbh_KwwuV__dIaSBb4XYZB1HeJm3biM2fWsJG8fIJeS5V5kdQb1dPsENNDJZ_zA8YJEqLbd9oYhe7xJup9Cj0li6ASKbfdFzgjVSOv5nw9S1bq5HSdb3F-X1xE3Y3aAkqV1dkKqwPbFh5k9umUyucZ_TuNYaj2QcdjjdzsQfPpPJXnLHTYvP2jf2R80k52qNHygEEDSAP6if-9V8fj-RaWQ22wE13LmgV9GSECNTS9vVpS30mTAIwxI-GeD6m0J4Mu2OMwiArtjZQnqvHYKGzFumN9bqIchR5DBOue8f47fUqqH35eeZjPT6KFT0ykHX1UPgLuqhlouyD2V96TnHrcJjuoV6gj6n3qsfMYqj_-FLcJuy8biYIsFkBSzapyu4nuPfGB4aLMes_jDVehCX4VDdMVe5MlkWAXUqGZXvn_gsL8xrwcCn1wzGqA6pabPD-4W8aub76Rgsw4-Pch-5O3UQHuUkBEWDGzlYA3bmGZtL0It0WuJ2YrRICpcphRc-dkcsUpSMFFkRAs9wKE0iY_n-XdLq2SBW-p1sVPddxJuHi6s8sBXGYlI018XAOQfYkZ9iKUglZYhiwvfWTrp_MD_-xKSG5hRwPBRclRrIuqtQhCdmyLiG9PFc-k8Zzc0nrx9oLYcMOfOoCpKa3uNflVWy8JQF1jg6SWc_6IAzN3iex6DH7MiS1gjTRkd06IoN1ak0BjLij5u6JZa_2NoOCImCsjTG-saSx89XwTjolpf-0NeYZ7yC_7sHsUcLpFKtSoMNA2IMi1mfRtuPDpiuxiJISZ8WKPBm3HBfZqWs5D0MFWFd6HxrPotsmJoWiLBbJEbzcj6Q2r9zqygWKXnw12Kdv9WXPrOqSt3HJlmvd9u76idLGomybRZRJ_8z4q6IkYJwFotHED3XrmnxcD7076DgXVuhiRpSPI0w19qp6wccBXjOE2ySKWzLJO3NVFRXyi2YWpdZSKtZ34ZkHDDkhR29p6v4DI7wAxSn9oBNOXRdS83jsK8rE5DUHxS6GQjjgMhF08vjMlay-RB94xolSLw9nmiBfsmmCqHGEIReH-PBxHd6yEYhqR0V5YHFcAT6qUla5YiV4-CDftqlCmwRMuckmyx0d34NbvAUxWomI_-_8A8GwUdrcmAE5me-0RJYcrPd-McYwnI9Jr7_k8vQ-cUChbrrreLjukSuF5n93kEUvM6c9dUYGIZMnXLjjAMAYtg2Xkvf0_Iko5BlcvHIH4XZxUXOhxNZA3pvpgHI7OOt9GtIiSv6JkP1nr1VtFiy8MvV2LM19_SAcsitsu3Kh92FS8lUnTyo7PiQxxKmZTngLuMJToXuzVsXIqr6GuWEPzDBIyKZ7ti7MT57s-koDdp147Lx1bHkrwSIZ9PwC0MhHfJSS7hOUhyhSO-imUKDGcKUGAulSr_jiKtjiKlAbldoULjuMFSJeEM9OZ6Th9AbTNrgF0Br4Cj7MKqqqQJSJj2PE2hlGPBzFLo-qBP4x0ed_IHQB_8_czj6rpvIBt0YE=w2276-h1147',
  ogKey : '',
  ogType : '网站',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '用于患者管理的实时位置追踪',
  date : '2023-08-07',
  tag1 : '#患者管理',
  tag2 : '#患者位置追踪',
  tag3 : '#智能医院',
  tag4 : '#患者安全',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'医院等医疗设施是患者聚集的地方（当然）。因此，相对于其他行业，需要更高级别的安全保障系统。违反此要求不仅仅是业务损失，还是可能决定个人生死的重要问题。同样，作为患者和护理人员，我们更希望在更安全的医疗设施接受医疗服务。换句话说，满足彼此需求可以视为创建更安全的医疗设施。<br/><br/>关键是患者。如果我们能实时监测患者的状况，检测事故并防止事故，就可以实现更快速的响应。实时位置追踪系统（RTLS）为构建稳定系统提供了基础。<br/>让我们看看下面可能发生的事情。',
      dot : ''
      
    },
    {
      head : '实时位置追踪',
      subHead :'',
      desc : '可以实时追踪医疗设施内患者的位置。如果需要快速找到特定患者的位置，可以通过搜索快速实现。这对于需要额外关注的患者（如阿尔茨海默病患者）尤其有效。不仅如此，您还可以追踪可能在设施内迷路的访客位置，或者实时跟踪患者和医务人员在病房中的位置，以避免浪费路线。',
      dot : ''
      
    },
    {
      head : '跌倒检测',
      subHead :'',
      desc : '对于行动不便或关节问题的患者来说，摔倒可能是致命的。如果可以实时检测到这些事故并立即呼叫医务人员，可以防止更严重的事故发生。',
      dot : ''
      
    },
    {
      head : '流行病学调查',
      subHead :'',
      desc : '对于像COVID-19这样的致命感染，迅速的预防性响应至关重要。要实现这一点，需要识别出可疑病例，而要做到这一点，必须追踪其接触者。实时位置追踪系统不仅记录患者的实时位置，还记录了设施内的移动轨迹。这可以实现迅速准确的流行病学调查，快速识别接触者。',
      dot : ''
      
    },
    {
      head : '拥堵控制',
      subHead :'',
      desc : '业务增长基于效率，这一事实对消费者（患者）来说是众所周知的。医疗预约延误或设施拥堵可能导致负面体验并削弱信任。实时位置追踪系统可以评估设施的拥堵情况，控制瓶颈，并保持设施的顺畅运营。',
      dot : ''
      
    },
    {
      head : '安全区域',
      subHead :'',
      desc : '医疗设施内有许多需要特别管理的资产，如药物、医疗设备和废物。为防止盗窃、事故和损失，可以将这些区域指定为安全区域。未经授权的人进入这些区域会触发警报，以便及时响应。',
      dot : ''
      
    },
    {
      head : '更多功能',
      subHead :'',
      desc : '实时位置追踪系统不仅限于人。它还可以追踪各种类型的资产，如医疗废物或医疗设备，需要持续管理和监控。',
      dot : ''
      
    },
    {
      head : '结论',
      subHead :'',
      desc : '就像许多其他行业一样，医疗设施在患者选择医疗服务时会考虑多种因素。在这些因素中，“信任”尤为重要。<br/>因此，实时位置追踪系统（RTLS）可以被视为构建最先进医疗设施服务的解决方案。',
      dot : ''
      
    },

  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: "将UWB终端机安装在工作者或设备上，帮助实时追踪位置的产品。",
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : medicalPatient.thumb,
        data : 'medicalPatient',
        link : '/cn/enterprise/medical/patient'
      }, 
      {
        img : medicalWaste.thumb,
        data : 'medicalWaste',
        link : '/cn/enterprise/medical/waste'
      },
      {
        img : officeAsset.thumb,
        data : 'officeAsset',
        link : '/cn/enterprise/office/asset'
      }
    ]

  }


}
