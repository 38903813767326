import { SCREEN_BREAKPOINT, StyledGridContainer } from '@components/Grid/Grid.style';
import { StyledDisplay, StyledLabel, StyledParagraph } from '@components/Text';
import { TypeMap } from '@core/util';
import styled from '@emotion/styled';
import { LayoutType } from '@redux/actions/common';

export const newsItemWidthMap: TypeMap<LayoutType, number> = { 
  desktop: 389,
  tablet: 320,
  mobile: 300,
};

export const newsItemGapMap: TypeMap<LayoutType, number> = { 
  desktop: 32,
  tablet: 32,
  mobile: 16,
};



export const StyledAppPage = styled(StyledGridContainer)`

  
  margin-top : 64px;

  & * {
    font-family: Pretendard !important;
    font-display: swap;
  } 


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    height : 100%;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
    
  }
`;


export const StyledDetailPage = styled(StyledGridContainer)`

  margin-top : 64px;

  & * {
    font-family: Pretendard !important;
    font-display: swap;
  } 


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
    
  }
`;




export const StyledAppTextBox = styled.div`
  text-align : center;

`;



export const StyledTitle = styled(StyledDisplay)`

  text-align : center;


`;


export const StyledDesc = styled(StyledParagraph)`
  margin-top : 16px;
  text-align : center;

  color : #646464;

`;

export const StyledSpacing16 = styled.div`
  padding-top : 16px;

`;




export const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding : 16px 0px;
`;

export const StyledIconWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width : 80px;
  height : 74px;
`;

export const StyledIcon = styled.img`
  height: 36px;
  width: 80px;
  object-fit: contain;

`;

export const StyledIconText = styled(StyledLabel)<{color : string}>`
  margin-top: 8px;
  
  text-align : center;

  ${({color})=> color && `color : ${color};`}
`;

export const StyledIconTextSub = styled(StyledLabel)`
  margin-top: 2px;
  color: #00C37D;



`;


export const StyledTextContainer = styled.div`
  padding-top : 64px;
  text-align : center;
  

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding-top : 48px;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-top : 48px;
    
    
  }
`;



export const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width : 100%;
  
  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-top : 54px;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-top : 77px;
    
    
  }
`;

export const StyledImage = styled.img`
  max-width: 100%;
  width : 1100px;
  height: auto;

  aspect-ratio : auto 1100 / 450 ;

`;
