
import styled from "@emotion/styled";
import { FlexStyle } from "@assets/styled/flex";
import { StyledLabel,StyledParagraph } from "@components/Text";
import { SCREEN_BREAKPOINT, StyledGridRowBtn } from "@components/Grid/Grid.style";


export const StyledDownloadIconBox = styled.div`
  width : 84px;
  height : 14px;
  margin-bottom : 16px; 


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
    height : 14px;
    display : flex;
    text-align : center;
  }


`;


export const StyledDownloadIcon = styled.img`
  width : 84px;
  height : 14px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin : auto;
  }
`;



export const StyledDownloadDesc = styled(StyledParagraph)`
    margin-bottom : 24px;
    color: #818181;
    font-weight : 400;
    
  

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
      
  }

`;




export const StyledDownloadBtnBox = styled(StyledGridRowBtn)`
    margin-top : 16px;
    width : 100%;
    height : 52px;
    gap : 16px;
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
        width : 313px;
    }

`;


export const StyledDownloadBtn = styled(FlexStyle)`

    width: 92px;
    height: 52px;
    border-radius: 8px;
    border : none;
    margin-left : 280px;
    cursor: pointer;

    background-color : black;



    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
      width: 313px;
      height: 52px;
      border-radius: 8px;
      border : none;
      cursor: pointer;
  
      margin-left : 0px;
      background-color : black;

    }

`;




export const StyledDownloadBtnlTextCancel = styled(StyledLabel)<{color}>`
    margin: auto;
    ${({color})=> color&& `color : ${color};`}
`;


export const StyledDownloadBtnlTextSubmit = styled(StyledLabel)<{color}>`
    margin: auto;
    ${({color})=> color&& `color : ${color};`}
`;

