import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo4.mp4";

export default {
    banner: {
      title: '在庫の測位と管理',
      desc: '在庫管理は、サプライチェーンの効率とスムーズな運営を支援する重要な要素です。在庫の位置をリアルタイムで測位することで、在庫レベルを効果的にモニタリングし、在庫の移動を最適化することができます。例えば、商品の人気度に基づいて在庫を配置し、在庫回転率を最適化することで、コスト削減や在庫不足や過剰を防ぐのに役立ちます。',
    },
    video: {
      title: 'スマートな物流在庫管理方法',
      desc: 'リアルタイムの位置測位（RTLS）技術により、在庫の位置をリアルタイムで測位します。<br/>正確かつ迅速に、そして簡単に在庫を管理して、<strong>最高の効率</strong>を実現します。',
      play: video,
    },
    func: [
      {
        title: '自動入出庫プロセス',
        desc: '在庫が特定のポイントを通過すると、自動的に入出庫記録が生成されます。このデータは正確であり、ヒューマンエラーを防ぎ、人件費を節約するのに役立ちます。',
        sub1: '高い精度の提供',
        icon1: IconRulerGray,
        sub2: '迅速なデータ連携',
        icon2: IconShareGray,
        video: subVideo1,
      },
      {
        title: '正確な在庫数量管理',
        desc: '商品の人気度に基づいて在庫を配置し、在庫回転率を最適化することで、コスト削減や在庫不足や過剰を防ぐのに役立ちます。',
        sub1: 'リアルタイムの状況モニタリング',
        icon1: IconClockGray,
        sub2: '統計データの提供',
        icon2: IconChartBarGray,
        video: subVideo2,
      },
      {
        title: '時間の節約',
        desc: '正確な在庫位置情報を持っていれば、物流作業者は迅速に商品を見つけ出し、必要な場所に移動することができます。これにより作業時間を短縮し、生産性を向上させることができます。',
        sub1: 'リアルタイムの位置測位',
        icon1: IconBinocularsGray,
        sub2: '高い精度の提供',
        icon2: IconRulerGray,
        video: subVideo3,
      },
      {
        title: 'リアルタイムの位置測位',
        desc: '在庫の紛失や盗難を防ぐのに役立ちます。在庫が各場所に割り当てられ、リアルタイムで測位されることで、在庫の紛失や盗難を迅速に検知することができます。これにより、在庫の損失を最小限に抑え、回収措置を取ることができます。',
        sub1: '迅速なデータ連携',
        icon1: IconShareGray,
        sub2: 'リアルタイムの状況モニタリング',
        icon2: IconClockGray,
        video: subVideo4,
      },
    ],
    subfunc: {
      title: 'さまざまな機能',
      desc: '在庫管理は物流業界におけるサプライチェーン管理の重要な部分です。最適化されたシステムを構築するために、ORBROはさまざまな機能を提供しています。',
      data: [
        {
          icon: IconChartRadius,
          title: 'レポートと分析',
          desc: 'フィールドデータを基にレポートや分析データを生成できます。',
        },
        {
          icon: IconBell,
          title: 'リアルタイムアラート機能',
          desc: '在庫数量が一定の制限を超えた場合にアラートを受け取り、即座に対応できます。',
        },
        {
          icon: IconRoute,
          title: 'ルート履歴の検索',
          desc: '在庫の移動経路を調査し、データ化することができます。',
        },
        {
          icon: IconDashboard,
          title: '状況の把握',
          desc: '特定の在庫が現在どこに移動しているかを把握します。',
        },
        {
          icon: IconWarning,
          title: '逸脱の検知',
          desc: '在庫が指定された位置から外れる場合を検知します。',
        },
        {
          icon: IconSearch,
          title: 'ルートパターンの分析',
          desc: 'フィールドでの装置の使用量とタイミングを分析することができます。',
        },
        {
          icon: IconChainLink,
          title: 'データの連携',
          desc: 'RFID、IoT、セキュリティ、ビデオなどのシステムと連携して効率的に活用できます。',
        },
        {
          icon: IconSecurity,
          title: 'データのセキュリティ',
          desc: '高いデータのセキュリティレベルを維持し、信頼性の高いソリューションを提供します。',
        },
      ],
    },
    system: {
      title: 'システム構成',
      img: rtlsSystem,
    },
    productTitle: '製品構成',
    product: [
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'logisticsSafety',
      },
      {
        data: 'constructionAsset',
      },
      {
        data: 'blogRTLS',
      },
      {
        data: 'blogForklift',
      },
    ],
  }
  