import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionEquipment, logisticsAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4';

export default {

    desc: 'Indoor GPS is a system that measures the location of objects moving indoors. It allows real-time tracking of the positions of people and objects.',
    keywords: 'Indoor GPS, GPS, RTLS, UWB, BLE, beacon, AI camera, location tracking, indoor location tracking, real-time location tracking, beacon, orbro, contech',

    ogSite: 'Indoor GPS Solution | Location Tracking, Orbro',
    ogUrl: 'https://orbro.io/en/blog/indoor-gps',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDldPhj_PKmAYMbJqfxzx90kvwqvmaEJvuGa-Tilhn_LDEQtT2ogbSz96n2WA7ZAkr8WCRqOH4FmFoBufrCikZrXDwtb5It8GdPL_VxgMjGS1EnQAjJ4qrWYj4KcPq2uUwiEvls5sC1jYuNZWL9bM_ehwpvaclUZmuxqNlqIHYdw-FwmLbIsnVL148GQBP4aPXNwhvDLGmbonOGByrjok58YvYwxO339qVTHGs3T_pNB3bpk3KtiI87LFAQTozPEkOlHvJdDGXaK_LwvsTpFUd_Y38nRFI6ff6khHkuBibyrmO3mFIAaSxXYgd--mNSgro0BX8zdHQ8oeun8sW6yug8ZCW_V0fedhTrafZhPhB6nDSrdpkoQs10G4ftI0mB_kPLYFSeyTOOORJzy5jXFMNQmlE9TwHl3RNxv32lu3iDvi1odQiYyAywd92rwhHhlULlmvPWd111HclIULU9SqZ4agxxncGZtw6e2TnyrWoNGJueJfvaH5fNe1AEUTSlxwvK6z_lSaG4AO6elSZZcwVN5_z_GK5f9GbYZWX98FSOF8GuENEjrNIi-rqewypjL2c50sP3_C6v3rzWQnZeXsW31fgBWLAeWZWuJTLN0s0dJ5qPgh_FJliOSsh8w5i9RXV-6kBIjI4EOxtOvLP47aYE1GhfOvxS4ckLRSt_qxWTxU4D8gTX55bKtdogVsY85YNRVjOWb1XPLXo1-28zu_1LePcA2zEaUuqqntLjWTlSpr6Z-wLGNgRAg_T9hl3DPo2Rt04piFBRqjrdIpuwE-YH4n9Z7mlUXuAcPiq6n8RyQzWRG_c-6K1wE9RTRoEtR4qOPvVOCp58VLuf9ZyZ3fc12wznVHAaMFUA5k6_hWeGX-Lo_Fa5qY0yAgq6xhHE3qmu9iNue_6L3rSRz2JDy8FJjGddpCtZAZESd-tbSTG4-IlytOnvM1CViy2ygKKcbWecEfllglOtxIWzclw3EZ8wXQFBFSmmhnnoMVXv5ksBL6JpLrVF3rOyGKZWISqxoMn_KdCKyBVHDjkCKsEDSyHsUfvdlJIdsYzown--og4tGE0XLrE4pNXLyIVp45JOS0yUaykHqkRPQDs9g-iioTlotza8AE5gBtWK_SF_-ULnujQu1fKBH8CyAYkUVSHJ0wWxk8kIYcpT6AagBiYU9-jqARQkc5x9PghJFx6rTa61P615QnzFKlWCDYKYxkNmwMFutN-ucVMK2MwUCjp_gt5h6xBiTT3eOg1S0F7KupXENgRSYm7E0s4GYje4Eizvlcfe-0eLS8yENjj4sYl2DORd_mAlF-5DX37Dssx_oov83DQQe6W_8GjOw5ieJpA-klmSxjRGZ5vrMc4U3EY8D-cejqv61EFJo8BPbbZMzgj6no9VNG1TSQ2vuaj0VA5vDl03Hx_MKtsHsGR47umJQDLT2Zk6jOmcSN65erXXx4SqDnrqJn9xL8iky-Z9ZWHL_Jdl9eTPERAcH0OmmHzq8ltKodC4bSz63jP2XGVBtdrPJBvwYVJVrbXoLsVsNNRQx1TSSPO_HA8XpPrbumKkuRJiUhBaCrX7j-6PK_b3e_nP2PMpzIXBD=w1595-h1272',
    ogType: 'website',

    title: 'Indoor GPS Solution',
    date: '2024-01-31',
    tag1: '#IndoorGPS',
    tag2: '#RTLS',
    tag3: '#UWB',
    tag4: '#LocationTracking',

    text: [
        {
            head: 'What is Indoor GPS?',
            subHead: '',
            desc: 'Indoor GPS is a system that measures the location of objects moving indoors. It allows real-time tracking of the positions of people and objects. GPS is primarily used outdoors and relies on satellite signals for accurate location determination. However, indoors, GPS signals often weaken or get blocked, making precise indoor tracking challenging.',
            dot: ''
        },
        {
            head: 'Indoor GPS Technology',
            subHead: '',
            desc: 'To overcome these limitations, indoor GPS utilizes various technologies. One of the key technologies is Ultra-Wideband (UWB) technology. UWB uses a very wide bandwidth for precise location measurement in indoor environments. It can provide accurate location data within buildings using UWB tags and receivers. Additionally, AI (Artificial Intelligence) cameras can be used to recognize objects indoors and perform location tracking based on that information. AI cameras analyze images or videos to identify objects and their positions.',
            dot: ''
        },
        {
            head: '',
            subHead: 'UWB',
            desc: 'Ultra-Wideband (UWB) technology is a key technology used for implementing indoor GPS. UWB uses an extremely wide bandwidth to generate a large number of signals in a very short time. This wide bandwidth enables precise time and distance measurements, which are essential for accurate indoor location tracking. Here are the main features of UWB technology and its implementation for indoor GPS:',
            dot: {
                a: '<strong> • Ultra-Wideband (UWB): </strong>UWB technology uses an extremely wide bandwidth to generate a large number of signals in a very short time. This wide bandwidth allows for precise time and distance measurements.',
                b: '<strong> • Tags and Receivers: </strong>Indoor GPS systems using UWB technology typically consist of "tags" and "receivers." Tags are attached to objects, people, or animals and emit UWB signals. Receivers capture and process these signals to calculate positions.',
                c: `<strong> • Time-of-Flight (ToF) Measurement: </strong>UWB systems accurately measure the time it takes for a signal generated by a tag to reach a receiver. This precise timing enables the calculation of distances between devices in indoor spaces. With multiple receivers, it's possible to calculate the 3D positions of objects indoors.`,
                d: '<strong> • Real-Time Location Tracking: </strong>Systems using UWB provide fast and accurate location tracking, making it possible to offer real-time location data. This is highly valuable for tracking the positions of objects or people indoors.',
            }
        },
        {
            head: '',
            subHead: 'AI Camera',
            desc: 'AI cameras are camera systems that use artificial intelligence technology to analyze image data and perform various tasks based on that analysis. The operation of AI cameras involves the following steps:',
            dot: {
                a: '<strong> • Image Capture: </strong>AI cameras capture videos or images in their environment using lenses, image sensors, and other hardware components. The captured images are converted into digital images.',
                b: '<strong> • Data Preprocessing: </strong>The collected image data undergoes preprocessing to optimize it. This stage includes tasks like adjusting image resolution, noise reduction, and color correction.',
                c: '<strong> • Feature Extraction: </strong>AI cameras extract important features from the image data. These features contain information related to objects, faces, movements, and more. Feature extraction is typically done using computer vision algorithms.',
                d: '<strong> • Application of Deep Learning Models: </strong>AI cameras input the extracted features into deep learning models. These models are trained to analyze and make judgments based on the provided data. Deep learning models can perform real-time identification, classification, tracking, and prediction tasks.',
                e: '<strong> • Analysis and Decision-Making: </strong>AI cameras use deep learning models to perform analysis and decision-making. For example, a facial recognition system can identify faces, extract facial features, and confirm the identity of a person. Similarly, an object tracking system can detect and track the real-time position of objects.',
                f: '<strong> • Output of Results: </strong>AI cameras provide the results of their analysis and decision-making in various output formats. These results can be displayed in different ways and are typically used to convey information to users or connected systems.',
            }
        },
        {
            video: video,
            link: '/en/enterprise/logistics/asset',
            head: 'Differences from Conventional GPS',
            subHead: '',
            desc: 'There are several important differences between indoor GPS and conventional GPS (outdoor GPS). These differences stem from the fact that GPS systems are primarily optimized for outdoor use, making accurate indoor tracking challenging. Here are the key differences between indoor GPS and conventional GPS:',
            dot: ''
        },
        {
            head: '',
            subHead: 'Signal Strength and Obstruction',
            desc: '',
            dot: {
                a: '<strong> • Outdoor GPS: </strong>Conventional GPS determines location using satellite signals, which can freely propagate in the open air. As a result, outdoor GPS signals generally have high strength, and obstruction caused by buildings and obstacles is minimal.',
                b: '<strong> • Indoor GPS: </strong>Indoors, GPS signals are often weakened or blocked. Walls, ceilings, floors, and metal structures can degrade or completely block GPS signals.',
            }
        },
        {
            head: '',
            subHead: 'Satellite Signal Reception',
            desc: '',
            dot: {
                a: '<strong> • Outdoor GPS: </strong>Conventional GPS relies on receiving signals from at least three satellites to accurately determine location. Typically, using four or more satellites provides even more precise location information.',
                b: `<strong> • Indoor GPS: </strong>Indoors, it's common to receive fewer or no satellite signals at all. This makes accurate indoor tracking challenging.`,
            }
        },
        {
            head: '',
            subHead: 'Correction and Enhancement Technologies',
            desc: '',
            dot: {
                a: '<strong> • Indoor GPS: </strong>For indoor location tracking, various other technologies such as Ultra-Wideband (UWB), RFID (Radio-Frequency Identification), Wi-Fi, Bluetooth, and ground-based location tracking systems are used. Combining these technologies enhances indoor GPS systems and improves accuracy.',
                b: '',
            }
        },
        {
            head: '',
            subHead: 'Accuracy and Precision',
            desc: '',
            dot: {
                a: '<strong> • Outdoor GPS: </strong>Conventional GPS can achieve high accuracy of around 1 meter or less in optimal conditions.',
                b: '<strong> • Indoor GPS: </strong>Indoor GPS systems require more effort for precise location measurement, and accuracy can vary depending on the environment, technologies used, and implementation. Generally, indoor GPS has lower precision.',
            }
        },
        {
            head: 'Use Cases of Indoor GPS',
            subHead: '',
            desc: 'Indoor GPS technology is utilized in various application areas, enhancing convenience and efficiency. Users can obtain accurate location information even within buildings, improving the convenience and efficiency of related services and applications.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Tracking Product Locations in Shopping Malls',
            desc: 'When consumers visit shopping malls, they can use smartphone apps with indoor GPS to find the real-time locations of desired products. This allows consumers to quickly locate the products they want, enhancing their shopping experience, and improving store efficiency.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Tracking Patient Locations in Hospitals',
            desc: 'Hospitals can use indoor GPS to track the real-time locations of patients, ensuring the safety of medical staff and patients. For example, medical staff can quickly locate patients and respond to emergencies.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Passenger Guidance in Airports',
            desc: 'Large-scale airports utilize indoor GPS to help passengers easily find gates, restaurants, shops, and more. Passengers can use smartphone apps to locate themselves within the airport and access the information they need.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Automation and Robot Control in Factories',
            desc: 'Indoor GPS enables precise tracking of the locations of robots and automation equipment. This optimization of production processes enhances operational efficiency.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Indoor Navigation',
            desc: 'Indoor navigation within buildings is useful in places like museums, universities, and complex facilities. Visitors can easily find their destinations within the building, making it easier to explore tourist attractions or educational facilities.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Car Parking Management',
            desc: 'Indoor GPS is used to identify vacant parking spaces within parking lots and guide drivers to available spots. This reduces parking times and improves parking lot efficiency.',
            dot: ''
        },
        {
            head: 'Conclusion',
            subHead: '',
            desc: 'Indoor GPS is a technology for accurately tracking locations within buildings, utilizing technologies such as UWB and AI cameras. It enhances convenience and efficiency in various use cases, including tracking product locations in shopping malls, tracking patient locations in hospitals, passenger guidance in airports, automation and robot control in factories, indoor navigation, and car parking management. However, there are differences between indoor GPS and conventional GPS in terms of signal strength and obstruction, satellite signal reception, accuracy, and correction technologies. Indoor GPS is primarily specialized for indoor location tracking.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'A product that assists real-time location tracking by attaching UWB devices to workers or equipment.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }
        ],
        solution: [
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/en/enterprise/retail/exhibition'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/en/enterprise/logistics/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/en/enterprise/construction/equipment'
            }
        ]
    }
}
