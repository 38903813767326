import { accessAutoThumb, accesscontrollerThumb, deadboltThumb, emlockThumb } from '@assets/images/main/access/product';
import {
    accessKey1, accessOutlook,
    IconBattery, IconBluetooth,
    IconBox, IconComputer,
    IconCard, IconFacetime, IconGuarantee,
    IconQR, IconTemp, IconUsbc,
    IconWarn, IconWaterproof, IconCamera, IconZoom, IconTopology
} from '@assets/images/main/index';
import { bell1, esl1, idCard1, mCradle1, mCradleThumb, mTagThumb, switch1 } from '@assets/images/main/iot/product';
import { assetTracking2, tagUwb1, twinTrackerBracket } from '@assets/images/main/rtls/product';
import { IconAtomGray, IconChainLinkGray, IconEyeline, IconInfo, IconMail, IconMonitorLineGray, IconPhone, IconPower, IconRoom, IconRulerGray, IconSignal, IconThumbUp, IconTools, IconToolsGray, IconTvocGray, IconWifi, IconZoneGray } from '@assets/images/icon';
import { accessKeyThumbnail, accessproThumbnail, deadboltThumbnail, emlockThumbnail, eslThumbnail, orbroServerThumbnail, safetyBellThumbnail, switchThumbnail, twinTrackerThumbnail } from '@assets/images/main/thumbnail';
import { ipThumb, ptzThumb } from '@assets/images/main/camera';
import { airQuality1, coMeasure1, loraGateway1, loraSensor1 } from '@assets/images/main/sensor';
import { orbroTag1, plutoconPro1 } from '@assets/images/main/beacon/product';
import { assetTag1 } from "@assets/images/main/beacon/product";
import { cobot1, deliverPackage, deliverRobot1, disinfection1, disinfection2, loadingKiosk1, loadingKiosk2 } from '@assets/images/main/integration';





export default {



    purchaseDesc: {
        //mobile: "배터리가 내장된 제품으로 전원 공사 필요없이 출입문 옆에 부착하여 쉽고 빠르게 모바일로 출입문 제어가 가능합니다.",
        auto: "자동문 출입에 특화된 제품으로 기존 자동문 센서 교환으로 모바일 앱 설치를 통해 비접촉 출입이 가능하도록 합니다.",
        face: "Access Pro는 건물의 공동 현관의 출입문을 제어하고, 호출하는 기능을 가진 제품입니다. 해당 제품으로 사용자를 호출하면 모바일 앱으로 실시간 영상 통화가 가능하고 원격으로 출입문을 제어할 수 있습니다. 또한 NFC 카드 및 비밀번호 잠금 해제가 가능하여 기존 공동현관 제어기를 대체할 수 있는 최고의 제품입니다.",
        accessory: "",

        asset: '자산 또는 추적하고자 하는 대상에 BLE 태그 단말기를 부착하여 위치를 추적할 수 있습니다.',
        worker: '자산 또는 추적하고자 하는 대상에 UWB 태그 단말기를 부착하여 위치를 추적할 수 있습니다.',

        outdoor: 'PTZ 실외 카메라를 통해 주차장의 차량 위치를 추적합니다.',
        indoor: '카메라를 통하여 실내 사람들의 이동경로를 분석 할 수 있습니다.',

        monitoring: "Safety Bell은 장소 제약 없이 Wi-Fi가 있는 장소라면 어디든 쉽게 부착하고 바로 사용할 수 있는 제품입니다.",
        energy: "Smart Switch는 아날로그 스위치를 손쉽게 디지털 스위치로 교체할 수 있는 제품입니다.",
        display: "ESL은 비콘 시스템 기반의 디스플레이 장치입니다. E-ink Display를 사용하기 때문에 매우 낮은 전력으로 디스플레이 사용이 가능합니다.",
        cradle: "케이블 잠금장치의 불편함 없이 스마트폰을 무선으로 관리 할수 있습니다.",
        idCard: "사원증은 목걸이 형태로 제작되어 산업과 서비스 영역 모두에서 호환성 높게 사용이 가능합니다. 오브로 RTLS 시스템과 블루투스 시스템들과 연동이 가능합니다.",


        loraGateway: 'ALESS_GW장비는 산업현장에 최적화 되어있는 스마트 무선 통신 집중기로써 센서들의 데이터를 네트워크 서버로 전송하는 스마트 무선 중계기 역할을 하는 장비입니다.',
        loraSensor: '현장에 필요한 온도센서, 압력센서, 누수센서, 온습도센서 등 다양한 센서와 연동하여 센서의 데이터를 측정하고, 측정 데이터를 무선 통신을 이용하여 전송하는 제품입니다.',
        airQuality: '고정밀 공기질 측정기로 미세먼지, 이산화탄소, 총 휘발성 유기화합물, 온도, 습도 센서를 내장하여 각각의 센서 측정값을 실시간으로 지시부에 표시하고, 통신 출력을 통해 서버시스템과 연동하여 측정 데이터를 확인할 수 있는 제품입니다.',
        coMeasure: '일산화탄소 뿐만 아니라 미세먼지(PM1.0/PM2.5/PM4.0/PM10), 온도 및 습도 센서가 내장되어 실시간으로 데이터를 측정할 수 있습니다.',

        beaconIndustry: '산업용으로 사용 가능한 비콘입니다. <br/>IP65의 방수방진과 야외설치를 지원하며, 최대 5년간 배터리 사용이 가능 합니다.',
        beaconTag: '어디든 부착 가능한 소형 태그 비콘 단말기.',
        assetTag: '자산관리용 초소형 블루투스 태그 단말기',

        robotBasic: '실시간 위치추적 시스템과 로봇 배송 시스템을 연동한 패키지입니다.',
        robot: '실시간 위치추적 시스템과 로봇 배송 시스템을 연동한 패키지입니다.',


    },



    purchase: {

        mobile: {
            accesscontroller: {
                icon: accessKey1,
                title: 'Access Controller',
                serial: 'KB-BK02',
                price: '50,000',

                note: [
                    { text: '모바일을 이용한 비접촉 출입기능 지원', icon: IconInfo },
                    { text: '최신 버전의 블루투스', icon: IconBluetooth },
                    { text: '10m 이내 출입 제어', icon: IconWarn },
                    { text: 'IP65등급의 방수 방진(-10 ~ 40°C)', icon: IconWaterproof },
                    { text: '최대 5년 배터리 사용기간', icon: IconBattery },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'EM Lock',
                        price: '150,000',
                        img: emlockThumb,
                    },
                    {
                        title: '데드 볼트',
                        price: '150,000',
                        img: deadboltThumb,
                    },

                ],
                options: [],
                mark: "2",


                mainProduct: [
                    {
                        img: accesscontrollerThumb,
                        title: 'Access Controller',
                        price: '50,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },



        //자동문 출입
        auto: {
            accessauto: {
                icon: accessKey1,
                title: 'Access Auto',
                serial: 'OAC-AUBT5091',
                price: '450,000',

                note: [
                    { text: '모바일을 이용한 비접촉 출입기능 지원', icon: IconInfo },
                    { text: '최신 버전의 블루투스', icon: IconBluetooth },
                    { text: '2m 이내의 정밀한 출입 관리', icon: IconWarn },
                    { text: '0 ~ 50°C 작동 온도', icon: IconTemp },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'EM Lock',
                        price: '150,000',
                        img: emlockThumb,
                    },
                    {
                        title: '데드 볼트',
                        price: '150,000',
                        img: deadboltThumb,
                    },

                ],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: accessAutoThumb,
                        title: 'Access Auto',
                        price: '450,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
            apart: {
                icon: accessOutlook,
                title: '아파트 패키지',
                serial: 'Access Auto 20 + Server 1',
                price: 'request',

                note: [
                    { text: '모바일을 이용한 비접촉 출입기능 지원', icon: IconInfo },
                    { text: 'ORBRO Server에 ORBRO OS 포함', icon: IconComputer },
                    { text: '품질보증 1년', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'EM Lock',
                        price: '150,000',
                        img: emlockThumb,
                    },
                    {
                        title: '데드 볼트',
                        price: '150,000',
                        img: deadboltThumb,
                    },
                ],

                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '2,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: accessAutoThumb,
                        title: 'Access Auto',
                        price: '450,000',
                        min: 20,
                        max: 999,
                    }
                ]

            },
            cloud: {
                icon: accessOutlook,
                title: 'Access Auto (Cloud)',
                serial: 'OAC-AUBT5091',
                price: '29,000',
                priceSub: '/월',

                note: [
                    { text: '모바일을 이용한 비접촉 출입기능 지원', icon: IconInfo },
                    { text: '최신 버전의 블루투스', icon: IconBluetooth },
                    { text: '2m 이내의 정밀한 출입 관리', icon: IconWarn },
                    { text: '0 ~ 50°C 작동 온도', icon: IconTemp },
                    { text: '품질보증 1년', icon: IconGuarantee },
                ],
                parts: [],
                options: [],
                mark: "1",

                mainProduct: [
                    {
                        img: accessAutoThumb,
                        title: 'Access Auto (Cloud)',
                        price: '29,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },

        //얼굴 인식 출입
        face: {
            accesspro: {
                title: 'Access Pro',
                serial: 'KSB-SL02',
                price: '2,000,000',

                note: [
                    { text: '모바일을 이용한 비접촉 출입과 영상통화 기능 지원', icon: IconInfo },
                    { text: '최신 버전의 블루투스', icon: IconBluetooth },
                    { text: 'NFC 카드 출입', icon: IconCard },
                    { text: '방문객 화상 통화', icon: IconFacetime },
                    { text: 'QR코드 출입', icon: IconQR },
                    { text: '0 ~ 40°C 작동 온도', icon: IconTemp },
                    { text: '품질보증 1년', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'EM Lock',
                        price: '150,000',
                        img: emlockThumb,
                    },
                    {
                        title: '데드 볼트',
                        price: '150,000',
                        img: deadboltThumb,
                    },
                    {
                        title: '실내 자동문 센서',
                        price: '99,000',
                        img: accessAutoThumb,
                    },
                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: accessproThumbnail,
                        title: 'Access Pro',
                        price: '2,000,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },


        },


        //출입 액세서리
        accessory: {
            accesskey: {
                title: 'Access Key',
                serial: 'KSB-AKC01',
                price: '30,000',

                note: [
                    { text: '비콘 키를 이용한 비접촉 출입기능 지원', icon: IconInfo },
                    { text: '최신 버전의 블루투스', icon: IconBluetooth },
                    { text: 'USB-C 충전', icon: IconUsbc },
                    { text: '품질보증 1년', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: " ",

                mainProduct: [
                    {
                        img: accessKeyThumbnail,
                        title: 'Access Key',
                        price: '30,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

            emlock: {
                title: 'EM Lock',
                serial: 'EM-Lock',
                price: '150,000',

                note: [
                    { text: '자동문이 아닌 방화문이나 목재문등 단방향 출입에 주로 사용되는 Electric Lock으로 자력의 힘을 이용하여 출입문을 고정시키는 장치 입니다.', icon: '' },
                    { text: '품질보증 1년', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: " ",

                mainProduct: [
                    {
                        img: emlockThumbnail,
                        title: 'EM Lock',
                        price: '150,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

            deadbolt: {
                title: '데드볼트',
                serial: 'Deadbolt Lock',
                price: '150,000',
                note: [
                    { text: '문을 닫으면 자동으로 도어락이 잠기는 자동잠금 데드볼트록입니다. 아파트, 사무실 등의 출입구, 대문 등에 사용할 수 있는 자동잠금이 가능한 데드볼트락입니다.', icon: '' },
                    { text: '품질보증 1년', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: " ",

                mainProduct: [
                    {
                        img: deadboltThumbnail,
                        title: '데드볼트',
                        price: '150,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

            /*
                        autoDoorSensor: {
                            title: '실내 자동문 센서',
                            serial: 'Automatic Door Sensor',
                            price: '99,000',
                            note: [
                                { text: '인체 또는 사물의 움직임을 감지하여 자동문의 개.폐를 위한 유사한 장치 제어하기 위한 고감도, 고성능의 제품입니다.', icon: '' },
                                { text: '품질보증 1년', icon: IconGuarantee },
                            ],
            
                            parts: [],
                            options: [],
                            mark: " ",
            
                            mainProduct: [
                                {
                                    img: accessAuto1,
                                    title: '실내 자동문 센서',
                                    price: '99,000',
                                    min: 1,
                                    max: 999,
                                }
                            ]
            
                        },
            */

        },


        //자산 추적
        asset: {
            assetTracking: {
                icon: accessKey1,
                title: '자산 위치추적 패키지',
                serial: 'TwinTracker 10 + Server 1',
                price: '9,500,000',

                note: [
                    { text: 'Bluetooth Tag 단말기를 이용한 자산의 위치 추적', icon: IconInfo },
                    { text: '패키지 당, 최대 1,000제곱미터 설치 가능', icon: IconBox },
                    { text: 'ORBBRO Server에 ORBRO OS 포함', icon: IconComputer },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'Asset Tag(BLE)',
                        price: '30,000',
                        img: assetTracking2,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },

                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTracker',
                        price: '450,000',
                        min: 10,
                        max: 30,
                    }
                ]

            },
            twinTracker: {
                icon: twinTrackerThumbnail,
                title: 'TwinTracker',
                serial: 'OTR-TTBT0001',
                price: '450,000',

                note: [
                    { text: 'Bluetooth Tag 단말기를 이용한 위치 추적', icon: IconInfo },
                    { text: 'Ethernet 및 Wi-Fi 네트워크 연결', icon: IconTopology },
                    { text: 'PoE 및 USB-C타입 전원 제공', icon: IconPower },
                    { text: '최대 10m 정확도', icon: IconRulerGray },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'Asset Tag(BLE)',
                        price: '30,000',
                        img: assetTracking2,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },

                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTracker',
                        price: '450,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },


        //작업자 위치 추적
        worker: {
            workerTracking: {
                icon: accessKey1,
                title: '작업자 위치추적 패키지',
                serial: 'TwinTrackerPro 10 + Server 1',
                price: '11,000,000',

                note: [
                    { text: 'UWB Tag 단말기를 이용한 고정밀 위치추적', icon: IconInfo },
                    { text: '10cm이내의 정확도', icon: IconWarn },
                    { text: '패키지 당, 실내 최대 600제곱미터 설치 가능', icon: IconRoom },
                    { text: '패키지 당, 야외 최대 1,000제곱미터 설치 가능', icon: IconRoom },
                    { text: 'ORBBRO Server에 ORBRO OS 포함', icon: IconComputer },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'Tag(UWB)',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    }

                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 10,
                        max: 30,

                    }
                ]

            },
            twinTrackerPro: {
                icon: twinTrackerThumbnail,
                title: 'TwinTracker Pro',
                serial: 'OTR-TTUW0001',
                price: '600,000',

                note: [
                    { text: 'UWB Tag 단말기를 이용한 위치 추적', icon: IconInfo },
                    { text: 'Ethernet 및 Wi-Fi 네트워크 연결', icon: IconTopology },
                    { text: 'PoE 및 USB-C타입 전원 제공', icon: IconPower },
                    { text: '최대 10cm 정확도', icon: IconRulerGray },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'Tag(UWB)',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },

                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTracker Pro',
                        price: '600,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
        },


        //차량 위치 추적
        outdoor: {
            carTracking: {
                icon: accessKey1,
                title: '차량 위치 추적 패키지',
                serial: 'Server 1 + NVR 1 + PTZ Camera 2',
                price: '11,000,000',

                note: [
                    { text: 'PTZ 카메라를 통한 주차장 차량 위치 추적', icon: IconInfo },
                    { text: 'IP67등급의 방수 방진', icon: IconWaterproof },
                    { text: '4MP 해상도의 고품질 이미지', icon: IconCamera },
                    { text: '최대 400m의 IR 거리의 촬영', icon: IconWarn },
                    { text: '42배 광학, 16배 디지털 줌', icon: IconZoom },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server + NVR',
                        price: '7,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: ptzThumb,
                        title: 'PTZ Camera',
                        price: '2,000,000',
                        min: 2,
                        max: 4,

                    }
                ]

            },
            ptz: {
                icon: ptzThumb,
                title: 'PTZ Camera',
                serial: 'OTR-CAPT0001',
                price: '2,000,000',

                note: [
                    { text: 'PTZ 카메라를 통한 주차장 차량 위치 추적', icon: IconInfo },
                    { text: 'IP67등급의 방수 방진', icon: IconWaterproof },
                    { text: '4MP 해상도의 고품질 이미지', icon: IconCamera },
                    { text: '최대 400m의 IR 거리의 촬영', icon: IconWarn },
                    { text: '42배 광학, 16배 디지털 줌', icon: IconZoom },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: ptzThumb,
                        title: 'PTZ Camera',
                        price: '2,000,000',
                        min: 1,
                        max: 999,

                    }
                ]

            },
        },


        //유동 인구 위치 추적
        indoor: {
            peopleTracking: {
                icon: accessKey1,
                title: '유동 인구 분석 패키지',
                serial: 'Server 1 + NVR 1 + IP Camera 4',
                price: '13,400,000',

                note: [
                    { text: '실내 IP카메라를 통한 사람 위치 추적', icon: IconInfo },
                    { text: 'IP67등급의 방수 방진', icon: IconWaterproof },
                    { text: '2MP 해상도', icon: IconCamera },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "0",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server + NVR',
                        price: '7,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: ipThumb,
                        title: 'IP Camera',
                        price: '1,600,000',
                        min: 4,
                        max: 8,

                    }
                ]

            },
            ip: {
                icon: ipThumb,
                title: 'IP Camera',
                serial: 'OTR-CAIP0001',
                price: '1,600,000',

                note: [
                    { text: '실내 IP카메라를 위치 추적 및 이상행동 감지 추적', icon: IconInfo },
                    { text: 'IP67등급의 방수 방진', icon: IconWaterproof },
                    { text: '2MP 해상도', icon: IconCamera },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: ipThumb,
                        title: 'IP Camera',
                        price: '1,600,000',
                        min: 1,
                        max: 999,

                    }
                ]

            },
        },



        monitoring: {
            safetyBell: {
                icon: bell1,
                title: 'Safety Bell',
                serial: 'KB-EW02',
                price: '100,000',

                note: [
                    { text: '긴급상황에 버튼을 누르면 문자로 관리자에게 신고', icon: IconInfo },
                    { text: '최신버전의 블루투스', icon: IconBluetooth },
                    { text: 'Wi-Fi 연결을 통한 신고', icon: IconWifi },
                    { text: '100개 신고 문자 전송', icon: IconMail },
                    { text: 'AAA 배터리 사용', icon: IconBattery },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: safetyBellThumbnail,
                        title: 'Safety Bell',
                        price: '100,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },




        energy: {
            smartSwitch: {
                icon: switch1,
                title: 'Smart Switch',
                serial: 'KSB-SS01',
                price: '80,000',

                note: [
                    { text: '원격으로 제어 가능한 스마트 스위치', icon: IconInfo },
                    { text: '블루투스 5.0', icon: IconBluetooth },
                    { text: '모바일 앱 조명 제어', icon: IconPhone },
                    { text: '비접촉 터치로 스위치 제어', icon: IconThumbUp },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: switchThumbnail,
                        title: 'Smart Switch',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
        },


        display: {
            esl: {
                icon: esl1,
                title: 'ESL',
                serial: 'KI-MDE1',
                price: '80,000',

                note: [
                    { text: '잉크디스플레이를 이용한 정보 표현', icon: IconInfo },
                    { text: '블루투스 5.0', icon: IconBluetooth },
                    { text: 'USB-C 전원 공급', icon: IconPower },
                    { text: '230dpi 해상도', icon: IconEyeline },
                    { text: 'NFC 태그', icon: IconSignal },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: eslThumbnail,
                        title: 'ESL',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
        },


        cradle: {
            mTag: {
                icon: mTagThumb,
                title: 'Mobile Tag',
                serial: 'KI-MT02',
                price: '80,000',

                note: [
                    { text: '스마트폰 무선 보안 장치', icon: IconInfo },
                    { text: '블루투스 5.2', icon: IconBluetooth },
                    { text: 'iPhone, Android 호환', icon: IconPhone },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                options: [
                    { select: 'Lightning', imgIndex: 0 },
                    { select: 'USB-C', imgIndex: 1 },
                ],


                parts: [],
                mark: "",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: 'Mobile Tag',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

            mCradle: {
                icon: mCradle1,
                title: 'Mobile Cradle',
                serial: 'KI-MC02',
                price: '80,000',

                note: [
                    { text: '스마트폰 무선 보안 장치', icon: IconInfo },
                    { text: '블루투스 5.2', icon: IconBluetooth },
                    { text: 'USB - C 충전', icon: IconPhone },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: mCradleThumb,
                        title: 'Mobile Cradle',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },


        idCard: {
            idCard: {
                icon: idCard1,
                title: 'ID02',
                serial: 'KB-ID02',
                price: '100,000',

                note: [
                    { text: '블루투스 기반의 전자 사원증', icon: IconInfo },
                    { text: '블루투스 5.0', icon: IconBluetooth },
                    { text: 'USB-C 전원 공급', icon: IconPower },
                    { text: '1년 간 보증기간', icon: IconGuarantee },

                ],

                options: [],
                parts: [],
                mark: "",

                mainProduct: [
                    {
                        img: idCard1,
                        title: 'ID02',
                        price: '100,000',
                        min: 1,
                        max: 999,
                    }
                ]

            }

        },



        loraGateway: {
            loraGateway: {
                icon: loraGateway1,
                title: 'LoRa 중계기',
                serial: 'ALESS-GW',
                price: '1,500,000',

                note: [
                    { text: 'LoRa 센서 단말기의 데이터 수집', icon: IconInfo },
                    { text: 'IP67 등급의 방수 방진', icon: IconWaterproof },
                    { text: '-20 ~ 60°C 산업규격의 환경 동작 가능', icon: IconTemp },
                    { text: '24VDC 전원 공급', icon: IconPower },
                    { text: '최대 6km 통신 거리', icon: IconWarn },
                    { text: '최대 150개 센서 수용 가능', icon: IconSignal },
                    { text: 'LTE/Wi-Fi 통신 선택 가능', icon: IconWifi },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: loraGateway1,
                        title: 'LoRa 중계기',
                        price: '1,500,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
        },
        loraSensor: {
            loraSensor: {
                icon: loraSensor1,
                title: 'LoRa 센서',
                serial: 'ALESS-BA',
                price: '450,000',

                note: [
                    { text: 'Universal Input Sensor 연동이 가능한 제품으로 센서의 여러 가지 인터페이스를 지원', icon: IconInfo },
                    { text: 'IP67 등급의 방수 방진', icon: IconWaterproof },
                    { text: '-20 ~ 60°C 산업규격의 환경 동작 가능', icon: IconTemp },
                    { text: '배터리 및 외부 DC24V 전원 공급 가능', icon: IconPower },
                    { text: '최대 6km 통신 거리', icon: IconWarn },
                    { text: '다양한 입력 인터페이스 적용', icon: IconChainLinkGray },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [],
                options: [
                    { select: '비상벨', imgIndex: 10, price: '450,000' },
                    { select: '화재 감지', imgIndex: 10, price: '600,000' },
                    { select: '온/습도', imgIndex: 10, price: '500,000' },
                    { select: '누수 감지', imgIndex: 10, price: '600,000' },
                    { select: '차압', imgIndex: 10, price: '900,000' },
                    { select: '침입 감지', imgIndex: 10, price: '400,000' },
                ],

                mark: "2",

                mainProduct: [
                    {
                        img: loraSensor1,
                        title: 'LoRa 센서 - 비상벨',
                        price: '450,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },

        airQuality: {
            airQuality: {
                icon: airQuality1,
                title: '공기품질 측정기',
                serial: 'VENTAX',
                price: '800,000',

                note: [
                    { text: '산업용 공기질 측정기', icon: IconInfo },
                    { text: 'Color LCD 디스플레이 내장', icon: IconMonitorLineGray },
                    { text: 'RS-485 및 LoRa통신 지원', icon: IconSignal },
                    { text: 'Auto Cleaning 기능', icon: IconToolsGray },
                    { text: '한국 환경공단 미세먼지 1등급 인증', icon: IconAtomGray },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [],
                options: [],

                mark: "2",

                mainProduct: [
                    {
                        img: airQuality1,
                        title: '공기품질 측정기',
                        price: '800,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
        },
        coMeasure: {

            coMeasure: {
                icon: coMeasure1,
                title: '일산화탄소 측정기',
                serial: 'VENTAX co+',
                price: '1,500,000',

                note: [
                    { text: '일산화탄소 및 공기질 측정기', icon: IconInfo },
                    { text: 'RS-485 및 Wi-Fi 통신 지원', icon: IconSignal },
                    { text: '미세먼지, 이산화탄소, 온/습도 측정', icon: IconTvocGray },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [],
                options: [],

                mark: "0",

                mainProduct: [
                    {
                        img: coMeasure1,
                        title: '일산화탄소 측정기',
                        price: '1,500,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
        },


        beaconIndustry: {

            plutoconPro: {
                icon: plutoconPro1,
                title: 'Plutocon Pro',
                serial: 'KB-BK02',
                price: '100,000',

                note: [
                    { text: '산업용 비콘 단말기', icon: IconInfo },
                    { text: '최신 버전의 블루투스', icon: IconBluetooth },
                    { text: 'IP65등급의 방수 방진(-10 ~ 60°C)', icon: IconWaterproof },
                    { text: '최대 5년 배터리 사용기간', icon: IconBattery },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [],
                options: [],

                mark: "0",

                mainProduct: [
                    {
                        img: plutoconPro1,
                        title: 'Plutocon Pro',
                        price: '100,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
        },
        beaconTag: {

            orbroTag: {
                icon: orbroTag1,
                title: 'ORBRO Tag',
                serial: 'OAS-OTBT1991',
                price: '30,000',

                note: [
                    { text: '소형 비콘 단말기', icon: IconInfo },
                    { text: '최신 버전의 블루투스', icon: IconBluetooth },
                    { text: 'IP65등급의 방수 방진(-10 ~ 60°C)', icon: IconWaterproof },
                    { text: '최대 2년 배터리 사용기간', icon: IconBattery },
                    { text: '품질보증 1년', icon: IconGuarantee },

                ],

                parts: [],
                options: [],

                mark: "0",

                mainProduct: [
                    {
                        img: orbroTag1,
                        title: 'ORBRO Tag',
                        price: '30,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
        },

        assetTag: {

            assetTag: {
                icon: assetTag1,
                title: 'Asset Tag (1,000EA)',
                serial: 'OAS-ABBT1991',
                price: '20,000,000',

                note: [
                    { text: '자산 관리용 초소형 비콘 단말기', icon: IconInfo },
                    { text: '최신 버전의 블루투스', icon: IconBluetooth },
                    { text: '최대 1년 배터리 사용기간', icon: IconBattery },
                    { text: '1년 간 보증기간', icon: IconGuarantee },

                ],

                parts: [],
                options: [],

                mark: "2",

                mainProduct: [
                    {
                        img: assetTag1,
                        title: 'Asset Tag (1,000EA)',
                        price: '20,000,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
        },

        //
        robot: {
            robotBasic: {
                icon: deliverRobot1,
                title: '배송로봇 기본 패키지',
                serial: 'Delivery Robot + TwinTrackerPro 10 + Server 1',
                price: '별도 문의',

                note: [
                    { text: '실내 위치추적 시스템과 로봇 시스템의 조화, 간단한 구성으로 빠른 구축 가능', icon: IconInfo },
                    { text: '배송 로봇 적재 용량 20L', icon: IconBox },
                    { text: '10cm이내의 정확도', icon: IconRulerGray },
                    { text: '실내 최대 600제곱미터 설치 가능', icon: IconRoom },
                    { text: '야외 최대 1,000제곱미터 설치 가능', icon: IconRoom },
                    { text: 'ORBBRO Server에 ORBRO OS 포함', icon: IconComputer },

                ],


                parts: [
                    {
                        title: 'Tag(UWB)',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker Rail Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },

                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 10,
                        max: 30,

                    },
                    {
                        img: deliverRobot1,
                        title: 'Delivery Robot',
                        price: '별도 문의',
                        min: 1,
                        max: 10,

                    }
                ]

            },

            delivery: {
                icon: deliverPackage,
                title: '무인 상/하차 패키지',
                serial: 'Delivery Robot + Loading Kiosk + Cobot + TwinTrackerPro 10 + Server 1',
                price: '별도 문의',

                note: [
                    { text: '무인으로 배송로봇이 상/하차 가능하도록 구성된 패키지', icon: IconInfo },
                    { text: '배송 로봇 적재 용량 20L', icon: IconBox },
                    { text: '협동 로봇 최대 하중 12kg', icon: IconBox },
                    { text: '10cm이내의 정확도', icon: IconRulerGray },
                    { text: '실내 최대 600제곱미터 설치 가능', icon: IconRoom },
                    { text: '야외 최대 1,000제곱미터 설치 가능', icon: IconRoom },
                    { text: 'ORBBRO Server에 ORBRO OS 포함', icon: IconComputer },

                ],


                parts: [
                    {
                        title: 'Tag(UWB)',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker Rail Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },

                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 10,
                        max: 30,

                    },
                    {
                        img: deliverRobot1,
                        title: 'Delivery Robot',
                        price: '별도 문의',
                        min: 1,
                        max: 10,

                    },
                    {
                        img: loadingKiosk2,
                        title: 'Loading Kiosk',
                        price: '별도 문의',
                        min: 1,
                        max: 10,

                    },
                    {
                        img: cobot1,
                        title: 'Cobot',
                        price: '별도 문의',
                        min: 1,
                        max: 10,

                    }
                ]

            },
            disinfection: {
                icon: disinfection1,
                title: '고위험 관리 패키지',
                serial: 'Delivery Robot + Loading Kiosk + Cobot +  Disinfection Device + etc',
                price: '별도 문의',

                note: [
                    { text: '감염우려가 높은 의료 물품을 배송 관리가 가능하도록 구성된 패키지', icon: IconInfo },
                    { text: '배송 로봇 적재 용량 20L', icon: IconBox },
                    { text: '협동 로봇 최대 하중 12kg', icon: IconBox },
                    { text: '99.9% 소독 및 멸균', icon: IconTvocGray },
                    { text: '10cm이내의 정확도', icon: IconRulerGray },
                    { text: '실내 최대 600제곱미터 설치 가능', icon: IconRoom },
                    { text: '야외 최대 1,000제곱미터 설치 가능', icon: IconRoom },
                    { text: 'ORBBRO Server에 ORBRO OS 포함', icon: IconComputer },

                ],


                parts: [
                    {
                        title: 'Tag(UWB)',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker Rail Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },

                ],
                options: [],
                mark: "2",


                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 10,
                        max: 30,

                    },
                    {
                        img: deliverRobot1,
                        title: 'Delivery Robot',
                        price: '별도 문의',
                        min: 1,
                        max: 10,

                    },
                    {
                        img: loadingKiosk2,
                        title: 'Loading Kiosk',
                        price: '별도 문의',
                        min: 1,
                        max: 10,

                    },
                    {
                        img: cobot1,
                        title: 'Cobot',
                        price: '별도 문의',
                        min: 1,
                        max: 10,

                    },
                    {
                        img: disinfection2,
                        title: 'Disinfection Device Kit',
                        price: '별도 문의',
                        min: 1,
                        max: 10,

                    }
                ]

            },

        },



    },


    modal: {

        title: '제품 문의하기',
        descHead: '아래 항목에 정보를 입력해 주시면, 담당자를 통해 견적서를 전달해 드리겠습니다.',
        descMid: '빠른 답변이 필요하신 경우,',
        phone: '1522-9928',
        descTail: '로 연락 주시면 감사하겠습니다.',
        time: '평일 (10:00 - 19:00)',

        theme: {
            name: '이름 *',
            company: '회사명 *',
            email: '업무용 이메일 *',
            phone: '전화번호',
        },
        placeholder: {
            name: '홍길동',
            company: '오브로 주식회사',
            email: 'example@orbro.io',
            phone: '010-1234-5678',
            ask: '기타 문의사항을 입력해 주세요. (선택)'
        }


    },

    accessory: '액세서리',
    soldOut: '품절',
    new: '신제품',
    request: '제품 문의',
    select: '선택하기',

    submit: '제출하기',
    cancel: '취소'
}