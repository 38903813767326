import { SCREEN_BREAKPOINT, StyledGridContainer } from '@components/Grid/Grid.style';
import { TypeMap } from '@core/util';
import styled from '@emotion/styled';
import { LayoutType } from '@redux/actions/common';

export const newsItemWidthMap: TypeMap<LayoutType, number> = { 
  desktop: 389,
  tablet: 320,
  mobile: 300,
};

export const newsItemGapMap: TypeMap<LayoutType, number> = { 
  desktop: 32,
  tablet: 32,
  mobile: 16,
};



export const StyledControlPage = styled(StyledGridContainer)`
  padding-top: 128px;
  padding-bottom: 48px;
  
  & * {
    font-family : 'Pretendard' ! important;
    font-display: swap;
  }


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding-top: 96px;
    padding-bottom: 64px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-top: 64px;
    padding-bottom: 32px;
    
  }
`;
