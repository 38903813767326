import React, { useCallback, useEffect, useState } from "react";
import NewsLetter from "../Common/NewsLetter/NewsLetter";
import ModalDone from "@pages/Enterprise/Download/ModalDone/ModalDone";

import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";

import i18n from "@pages/i18n";
import SEO from "@pages/SEO";

import seo from "@src/lang/ko/seo";
import seoEng from "@src/lang/en/seo";
import seoJP from "@src/lang/jp/seo";
import seoCN from "@src/lang/cn/seo";

import BannerMain from "../Common/Banner/BannerMain";
import Main from "./components/Main/Main";
import { DivPadding, FontPage } from "@pages/CommonStyle/commonDiv.style";
import { StyledGridContainer } from "@pages/Enterprise/Blog/Detail/BlogDetail.style";
import CommonLang from "@pages/CommonStyle/commonLang";
import { useLocation } from "react-router-dom";


const Camera = (): React.ReactElement => {

  const location = useLocation();
  CommonLang();

  const selectedValue = useSelector((state: CommonState) => state.selectedValue);

  let seoPath;
  if (location.pathname.includes('/en/')) {
    seoPath = seoEng;
  } else if (location.pathname.includes('/jp/')) {
    seoPath = seoJP;
  } else if (location.pathname.includes('/cn/')) {
    seoPath = seoCN;
  } else {
    seoPath = seo;
  }


  const helmetTitle = seoPath.cameraProduct.title;
  const ogKey = seoPath.cameraProduct.key;
  const ogDesc = seoPath.cameraProduct.desc;
  const ogImg = seoPath.cameraProduct.img;
  const ogUrl = seoPath.cameraProduct.url;


  const currentLang = i18n.language;


  const [selectedGroup, setSelectedGroup] = useState<number>(selectedValue);

  const handleIconClick = useCallback((group) => {
    setSelectedGroup(group);
  }, []);

  useEffect(() => {
    setSelectedGroup(selectedValue); // selectedValue 값을 selectedGroup에 할당

  }, [selectedValue]);


  const [isSuccess, setSuccess] = useState<boolean>(false);
  const onSuccess = useCallback(() => {
    setSuccess(!isSuccess);
  }, [isSuccess]);



  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": `${helmetTitle}`,
    "description": `${ogDesc}`,
    "alternateName": `${helmetTitle}`,
    "url": `${ogUrl}`,
    "keywords": `${ogKey}`,
    "image": `${ogImg}`,
    "address": "#B-1203, 660, Daewangpangyo-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea",
    "email": "orbro@orbro.io",
    "brand": {
      "@type": "Brand",
      "name": "ORBRO"
    },
    "serviceType": "Product Description", // 솔루션 유형 정보 추가
    "areaServed": "Global", // 서비스 제공 지역 정보 추가

    "additionalProperty": {
      "@type": "PropertyValue",
      "name": "Smart Camera",
      "value": "Smart Camera System" // RTLS 기술 설명 추가
    },
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+8215229928",
      "contactType": "a sales contact point",
      "areaServed": "KR",
      "availableLanguage": "Korean"
    }],
    "sameAs": "https://kr.linkedin.com/company/kongtech",

  }



  return (
    <FontPage>
      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={ogKey}
        ogDesc={ogDesc}
        ogImg={ogImg}
        ogUrl={ogUrl}
        jsonLd={jsonLd}
      />

      <BannerMain handleIconClick={handleIconClick} product={'camera'} />
      <Main key="main" handleIconClick={handleIconClick} />

      <DivPadding a={"0"} b={"0"} c={"64"} d={"0"}>
        <StyledGridContainer>
          {isSuccess && (
            <ModalDone onClickToggleModal={onSuccess}>
            </ModalDone>
          )}
          <NewsLetter onSuccess={onSuccess} />
        </StyledGridContainer>
      </DivPadding>
    </FontPage>
  )
};

export default Camera; 