import { IconBlueShowmoreCn, IconBlueShowmoreEn, IconBlueShowmoreJp, IconBlueShowmoreKo } from "@assets/images/icon";

export default {

    back: 'ORBRO 블로그로 돌아가기',
    newletter: '뉴스레터 구독하기',
    info: '다양한 소식을 받아보세요!',
    placeholder: '이메일을 입력해주세요',
    subscribe: '구독하기',
    terms1: "정보를 제공함으로써 Orbro의",
    terms2: "",
    terms3: '개인정보처리방침',
    terms4: '에 동의합니다.',

    more: '더보기',
    less: '접기',

    recent: '최신 블로그 글',
    share: '글이 마음에 드셨나요? <br/> 공유하기',

    priceUnknown: '별도 문의',

    price: '총 가격 : ',
    product: {
        title: '제품 구성 알아보기',
        access: '혁신적인 출입 시스템 제품 구성을 확인 해 보세요.',
        rtls: '혁신적인 위치 시스템 제품 구성을 확인 해 보세요.',
        camera: '카메라 시스템 제품 구성을 확인 해 보세요.',
        integration: '의료 환경에서 구축가능한 제품 패키지 구성을 확인해 보세요.',

    },

    uwbVideoDesc: '<br/><strong>(UWB 기술을 활용한 오브로 솔루션, 병원)</strong>',
    bleVideoDesc: '<br/><strong>(BLE 기술을 활용한 오브로 솔루션, 제조 시설)</strong>',
    svVideoDesc: '<br/><strong>(카메라 기술을 활용한 오브로 솔루션, 전시관)</strong>',
    requestHead: '더 상세한 정보가 필요하시면',
    request: '언제든지 문의',
    requestTail: '주세요.',


    showmoreImg: {
        ko: IconBlueShowmoreKo,
        en: IconBlueShowmoreEn,
        jp: IconBlueShowmoreJp,
        cn: IconBlueShowmoreCn
    }
}