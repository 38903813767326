import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/medicalWaste/video/rtlsVideo.mp4";

export default {

    
  helmet : '| What is BLE? | Bluetooth Low Energy',
  desc : 'BLE, which stands for Bluetooth Low Energy, is a wireless communication technology primarily used for low-power device-to-device data communication. Known for its low power consumption and easy implementation, BLE is widely used in construction, manufacturing, and medical fields.',
  keywords : 'BLE, Bluetooth Low Energy, low power consumption, Bluetooth, beacon, Bluetooth beacon, rtls orbro, KongTech',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'What is BLE? | Bluetooth Low Energy :: ORBRO Blog',
  ogUrl : 'https://orbro.io/en/blog/ble',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDnfNjLOQqRH35cwkWdJ-bHoz620gNvKlc65H16bDwbDmM9mn2WV9CZBz5ngF-qPG8Kaio7OwcThigmqtAyLII5-_7o_wuEr9IGXgBBhy_HG2TtVg8JvI3jjx47Vjw5bkjNMP9pqYdZG-uQE7MSvCn-fUZW5RKVlHT6Wej9ZUjr8AuM0noE2LJXIBs8o7Ik2bNeGQcps-3AfWrgOGhlIFNJuCK2uSB1AW_cjwmHTkEIusWJalOUeSVOcaarM8cTR1xUNWmao0lb1KbxQ8qqbL4_XhFZkkUct1UG8RrIN6Psi-AsTpgkFOrBdDPlqH541BEN9bwdOETx3Kgc9iRes1JUSRoP7nrOyakxoo3aqXTFdAoosGnVLKOiER0DPiU3G07ZA9bJlehSeDDCzu8kynXgnTFz91gKfMi3avcdxHqpzaiBhQCXLBUaUM95BEkE-EXHcK8Nl2WmqiTNP7CThyNvqwZdyy1JVK3ZAc5waSTB186EjteUi4k9pd65QfukNba5px9D4ul0TIzbpY_aBS2bxSfdqlASAVpqQacahwDH0yFO0S1gPaNEMW4Rcqk4iQBol7VLpCY1Snlty4mEaUfgoKOxdkkZU3nUI7YT5EXM3uWYUNTI7a2y9qeT4hrtloASb9mN6DcMg_aqnP7emz1CZ-imi3vFJic3ohSrWlQulA-3p__vjLjaVuslbfw63OjFvQAtmnYu1VRZInPMAtDL_vflRts6E5KekPnuL4Ca5Kze4Wh9CVzuPlcdqh4jXIkUI4GEE6n9fX8ZU3eWJ3vJXWl5AsFXSA5fbMZ399h7V9B5DzuThH9vBR99mQqMnDcu-wMSq8GtgfrQvtysw0KoPKwz2uo8D1Wg5qhsJ9KCDj_gm3BY67-wftHaGOtlwve9hBoHyAlPwsV0BA4t6_tpuqzg3jfkv8hlWyWZ7x9KUguu0Im_zMOGYfPw_YdhWZoe_erVB6bGz7UHbyN2Vo-aMOK4eAjPDtOgP0qR90Sg-KjmiPwCEGL1SFTjzDht-pBQVVLCOMRzOuRArCvUZai0a4G2-JBnIxVPxNfCsLrLu33X6-UgxzpkrQfBr0YcAINxqDA_IfOOJc3C9PKFPyhTgp7cP_KVk2QsY25pHgMDTc49Ui4nwk2FxSdvlshqEZHbPKbtQHQ_JK-y3veLdrgV9ADCimrqBeHANlcWKuovdxhGvrr2mlRG-t2pD_62qO-Gh0t93pn9qocK1oVmPweHfGPMC2E1Z_GtmeOr33-mwXkKK-R7LUT7aLOO359xDj0C__aXlIFScOt-8RVul4IJ8HQoENLTkwNTs-jt-Z_LMbO73LvC5YlngDpmg1n9_NLCcomYIxw6PBKksDaqWJM732sqJiEsvmm9NYyVnBneRLbCAIKbq6xsH1y98rbeCc8tlWYSBfUNFx-CvWG-L_8IrgL0bhnMKFzN4bHqJCVezRfIHgDdfdOwOSN3xxFZmg4KSWjdPA3VtyO57X_zgNjlVtEE6PDawKLNXUiaAd6nyZtpP-FLbph9jZ7-Ws8g_A_gHtSv2NQOq3CQ73x9_YgopHxq4rJ3W=w2189-h1213',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'What is BLE? | Bluetooth Low Energy',
  date : '2024-01-04',
  tag1 : '#BLE',
  tag2 : '#BluetoothLowEnergy',
  tag3 : '#Bluetooth',
  tag4 : '#Beacon',
    
  text : [
    { 
      head : 'What is BLE?',
      subHead : '',
      desc :'BLE, which stands for Bluetooth Low Energy, is a wireless communication technology primarily used for low-power device-to-device data communication. Known for its low power consumption and easy implementation, BLE is widely used in construction, manufacturing, and medical fields. In this blog, we will delve into the basic concepts, operational principles, key features, and various application cases of BLE.',
      dot : ''
    },
    {
      videoDesc : '(Hospital Waste Management Solution, click to view)',
      video: video,
      link : '/en/enterprise/medical/waste',
      head : 'Basic Concepts of BLE',
      subHead :'',
      desc : 'BLE, Bluetooth Low Energy, is designed for communication between low-power devices such as smartphones, wearable devices, sensors, and healthcare devices. Like Bluetooth, it uses the 2.4GHz frequency band but minimizes power consumption to support long battery life.',
      dot : ''
    },
    {
      head : 'Operational Principles of BLE',
      subHead :'',
      desc : 'BLE operates in two modes: periodic advertising and connection. In advertising mode, the device announces its presence to the surroundings, while in connection mode, actual data exchange takes place. This allows efficient communication even in low-power states.',
      dot : ''
    },
    {
      head : 'Key Features of BLE',
      subHead :'Low Power Consumption',
      desc : 'BLE is designed to be suitable for devices powered by batteries. It operates at low power in advertising mode and maintains optimal power consumption during connection.',
      dot : ''
    },
    {
      head : '',
      subHead :'Various Application Areas',
      desc : 'BLE is utilized in various application areas, including smart homes, smart healthcare, smart cities, enabling sensor networking, location-based services, and indoor location tracking.',
      dot : ''
    },
    {
      head : 'Application Case of BLE: RTLS Technology',
      subHead :'Real-Time Location Tracking System (RTLS)',
      desc : 'BLE is crucially utilized in RTLS technology, which tracks the real-time location of objects or entities. It finds applications in various industrial sectors.',
      dot : ''
    },
    {
      head : '',
      subHead :'Logistics and Warehouse Management',
      desc : 'In logistics and warehouses, BLE tags are used to track the real-time location of goods or assets. This optimizes inventory management, logistics sourcing, and warehouse internal movement paths for increased efficiency.',
      dot : ''
    },
    {
      head : '',
      subHead :'Indoor Positioning Services (IPS)',
      desc : 'BLE plays a significant role in IPS. It is used in public places like shopping malls, airports, museums to provide visitors with indoor maps, special discount information, or exhibit details through smartphone apps.',
      dot : ''
    },
    {
      head : '',
      subHead :'Manufacturing and Production Process Management',
      desc : 'In manufacturing, BLE is used to monitor the real-time location of equipment, components, or products within the production process. This enhances process efficiency and minimizes downtime during fault handling.',
      dot : ''
    },
    {
      head : '',
      subHead :'Healthcare Systems',
      desc : 'In hospitals or medical facilities, BLE is used to track the location of patients and monitor the status of medical devices. This aids in quick response during emergencies and patient monitoring.',
      dot : ''
    },
    {
      head : 'Outlook',
      subHead :'',
      desc : 'BLE is expected to continue evolving and be applied to more industries and daily life fields. Better security features, higher transmission speeds, and a wider range of connections are anticipated directions for the future development of BLE. These advancements are expected to create a more efficient and convenient wireless communication environment.',
      dot : ''
    },
  ],

  link : {
    page : 'rtls',
    numb : '1',
    product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'A product that assists real-time location tracking by attaching UWB terminals to workers or equipment.',
          img : productImg.product.workerTracking.key1
        },
      {

      }

    ],
    solution : [
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/en/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/en/enterprise/logistics/asset'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/en/enterprise/manufacturing/asset'
      }
    ]

  }


}
