import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: 'RTLS is a technology for real-time tracking and monitoring of location information. Digital twins utilizing this technology digitally simulate and manage physical systems or spaces from the real world, allowing real-time management and analysis.',
  keywords: 'Digital Twin, Digital Twins, digital twin, RTLS, Real-Time Location System, location tracking system, sports, manufacturing, logistics, healthcare, hospitals',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twins and Real-Time Location Tracking System :: Orbro Blog',
  ogUrl: 'https://orbro.io/en/blog/digitalTwin',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EObM89yl-Fckym9cymsbb7S7KBZ-fORj-iI1ch019MZXKtGLBD8Q6iWqftjthjE-64HwCxCX7XRPR10bcSBzxfbbHdVe_fmhy-hRrTTbEqa1kmSlG5WJwe_aFIG3PMwmlcPU90rQL2e9sjumg_2pSrR0sgMw6IN_LEwA-To3jUhSTh42IZmzhH0_1e5TJi90KWyaQEDki6hm93269tWDF2Xfm3oZpVefWKgJ7Xnv0NVZ3NMHBGkLEZz236wZHYh8GuRwDBtgHG8EKrm3c0X_9wkhJsdZATZNeCHPBUqyEDFMGmqaOnj5TYLfoJ25Il3v1m91oZtuoSchfnhmgHThYM6qbzj5ID4OSkyrRre3W3EpjouXL3zLHCJSOvOoHA6Kc3r_K58o7PuCQtVLs0b-lmcGHhXfC4peLT-9_8cWEisLN9cPEA_4hg5HT3tZ3zW9xG0WaU809hPnE9sBfCUriBFXpQaHHI6SIZ6lDLYSYJ2AzxYXxTqNWcCd3cvdbm4Vv9zzDaYP8phmcP1-TVhwKjMS3djddsWAPjxzbbqx4X6P4S3O5qCgKsCPMzOSiYDGSneF9xLkcFpLm13KT7xUcDV4wWlR-PrM01GTS66BvLHflJa_pg0ZtJte4DAIBu8TPsiDoiLjxT1WB69G-OYqtGnDrXVpUJkk8hhnK2n02PqcAwDA6s60Zvo_qKRnDXqbwkYe1qc_pwctFBShNNA-2NZATqu3r4Fl2gLy07d1dG9Rz39jB7tKKIWSeTBl-bpslCsuyWQaK6IxF19oGBhANRlIwpMHQfSJOWVSeE0QtKA4hlzcONIb28Iz8FfnYJUlNL_mEEKfC2QsgCP9QhKJ9xHYPPn_GBZ8TFuQSZvsDQvJ64s1teGa1Z8KdsteqgEG7iP_QxD_hfYHTEZc4c-EiDHiwwlrKrHPp5hLPgjMr9mWLUinWGKPTnPS39qBnM9Qq4cscUTNNGRDPumUYh7NIDmCR5PZ60qw2qCTSOcmlOQGvIaiZSEj9VpXBoLgEmJQHBylfdfduwNMLkIbbBdu9HKVJhcKkcIM0pgxhUjIX1yLgaNBg2GPWwEW9ep8pBep3OFKcdtehYRxHKLcCC6-69tQcLLslYLGTFyGoVokZ4dtBpy1lLWIG_YraeJ7yalMNg2J4yOYvwoLTInt3G6hSnU37a6twsvEp9TJR6z9jo2A9chkGqWyeg7bXB4Sc8-lIwwhlEXGQgW1MWX-h9kBUvZNEs0-_Jx6Drdfi0vYIfaK5-6GhfEZTVGuS6zzHCCpUNMFfu3Cu06IA7BWvunUR10RDVViw1gKdqrBdd5tgyrTqBoPEUaaBJIK9Dw0UcMRSP97gbKb6Slr49dZCyN2toItkq1fWOxtM6Of7AUzY3aGinq1R675xtbTceH2_QpwYO9HwgJbLHYdDtsgeum0buiGPEhInEHCe44zxXvOYzNzs1fRs9aPAM-H_ukO3HPnSAJ8yHWnnKTZ90M-KQQ_PMMuVgY7EKiGZMDGPNxxN-yVxPjSbFoQPtWLvLWA2XkWK8ExgJN4YuLR29U-f8ZiwcIQQwNA3gWp3qNF7QwyMjjZCg=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twins and Real-Time Location Tracking System',
  date: '2023-04-22',
  tag1: '#DigitalTwin',
  tag2: '#RTLS',
  tag3: '#LocationTracking',
  tag4: '#UWB',

  text: [
    {
      head: '',
      subHead: '',
      desc: 'While many industrial technologies experienced setbacks due to prolonged lockdowns and disruptions in global supply chains caused by the pandemic and wars, these technologies are now experiencing a revival, and their advantages are becoming more apparent. When facing labor shortages and tighter deadlines, proficient managers are actively seeking technological solutions to alleviate the burden. As a result, the market outlook for RTLS and Digital Twins has become promising. <br/>RTLS and Digital Twins, which initially developed in the manufacturing industry, are now being utilized in various industries, exploring new application areas. These technologies are being actively adopted by many companies as a means to improve productivity and address labor shortages and tight schedules.',
      dot: ''
    },
    {
      head: 'Increasing Demand for RTLS',
      subHead: '',
      desc: `According to research, the RTLS market is expected to grow at a current CAGR of 25.1% and will continue to expand until 2030. With the increasing number of devices connected to the internet, businesses are projected to enhance productivity and gain better insights through mobile and wearable technologies. The application of location tracking technology for accident prevention and error avoidance is driving the adoption of new RTLS sales. Additionally, the surge in health-related applications following the pandemic is also contributing to this growth. <br/>The adoption of industrial IoT in the Asia-Pacific region is driving the growth of RTLS adoption in transportation, manufacturing, healthcare, government, and defense industries. As India's manufacturing sector moves toward the $1 trillion milestone, substantial RTLS contracts are being formed in areas such as warehouses, transportation, and logistics.`,
      dot: ''
    },
    {
      head: 'RTLS in the Sports Field',
      subHead: '',
      desc: 'Top sports players and teams are always seeking ways to enhance their performance, while TV networks are constantly looking to improve the viewing experience for sports fans. Over the past year, RTLS has proven to be helpful in both aspects. Firstly, the introduction of location tracking systems in basketball training and games has facilitated the analysis of crucial indicators such as players\' ball control skills, speed, and acceleration. <br/>In the Qatar World Cup, footballs were equipped with UWB technology, allowing each contact with the ball to be recorded. This data helped with rapid and accurate offside judgments and generated images that could be projected on live TV broadcasts and stadium monitors, enabling fans to understand referee decisions. This contributed to the Qatar World Cup being one of the most peaceful tournaments in history.',
      dot: ''
    },
    {
      head: 'RTLS Growth in the Healthcare Sector',
      subHead: '',
      desc: `The complexity and high risk of hospitals and healthcare facilities have made it challenging for RTLS to penetrate this field. However, recently, there has been a growing demand for RTLS solutions for various purposes. <br/><br/>Many hospitals and care facilities are using RTLS to track patients to prevent the spread of COVID-19. This serves as a measure to prevent major outbreaks that can put significant pressure on hospital operations. Furthermore, RTLS is being utilized to optimize the flow of personnel and resources within hospitals, creating automated schedules for equipment usage and patient reservations to achieve additional cost savings. RTLS is even being considered as part of cost-saving measures to prevent malpractice lawsuits. Hospitals expect that monitoring operations using RTLS will help prevent blame for negligence. <br/><br/>Virtual wards, although not a new concept, can be facilitated more smoothly with RTLS as a centralized platform for patient management. The Australian government's Department of Health claims that "automated reporting will reduce the time aged care workers spend on reporting and reduce associated costs." Wearable sensors enable monitoring of patients' health conditions at home, allowing for faster notification of issues to doctors compared to traditional methods. This helps secure beds for patients who need urgent treatment, reducing waiting lists. Following a successful pilot operation, Singapore's Ministry of Health is expanding the virtual ward pilot program.`,
    },
    {
      head: 'The Emergence of Digital Twins',
      subHead: '',
      desc: 'According to a report, the digital twin market currently holds a value of $9.5 billion and is projected to reach $72.65 billion by 2032. Currently, transportation and automotive sectors hold the largest market share for digital twin technology, but its expansion into healthcare, communications, real estate, and retail industries is already underway. Given the close connection between RTLS and digital twin technology, similar growth patterns are expected in the Asia-Pacific region, with a potential CAGR of 40% over the next few years.',
    },
    {
      head: 'Digital Twins for Cities',
      subHead: '',
      desc: 'Previously, digital twins were predominantly limited to production lines, car factories, and logistics centers. However, the concept of applying digital twins to entire cities has started to become a reality. Research teams from ITC, BS, and ET have published a research report demonstrating the usefulness of digital twins in city planning and coordination. Dr. Ville Lehtola, the lead author of the study, stated, "Digital twins can bring significant benefits to cities by enabling city administrators, residents, and businesses to access up-to-date information about the city\'s infrastructure and services." <br/><br/>The comprehensive use of digital twins in cities aims to save time, reduce costs, and make urban environments more sustainable. This requires monitoring of traffic noise, solar radiation levels, road safety, public transportation systems, waste management, as well as monitoring police and fire response times. Gathering data in these areas for the first time can dramatically improve the quality of life in cities. <br/><br/>While most cities are still in the hypothetical stage, the city of Birmingham in the UK has already implemented a digital twin in the eastern region of the city. The system serves as a digital "testbed" to examine the impact of smart homes, clean air zones, and local growth strategies. The fields of RTLS and digital twins are experiencing fascinating developments. This space is rapidly evolving, and market growth predictions suggest tremendous benefits not only in the manufacturing industry but also in aerospace, healthcare, and public transportation sectors.',
    },
  ],


  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/en/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/en/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/en/enterprise/logistics/asset'
      }
    ]


  }
  

  
}
