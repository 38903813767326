import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

 
  helmet : '',
  desc : '의료 산업에서의 RTLS는 환자 및 의료 장비의 실시간 위치 추적을 통해 의료진이 환자를 효율적으로 모니터링하고, 의료 시설 내에서 장비 및 자산을 효율적으로 관리할 수 있습니다.',
  keywords : 'RTLS, 실내위치추적, 실내네비게이션, 위치추적솔루션, 실시간위치추적, 자산추적, 스마트오피스, GPS, 실내위치추적솔루션',

  ogTitle : '',
  ogDesc : '',
  ogSite : '의료 산업에서의 RTLS :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/medical',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZJaHlD_-JbEbsseg3VyR_yeMr1aCExLMWJX8T8GLTArDRbIStI6J1-0hax0bG6__oOWgnHyGPyvarcGsI-N_BiKN4cYDhs_2bM3hfycDf5l2N2df2hsBc0jlAcY_dysEr70kglQvb_fo8sUaG0ru2JfDJeZrPF1XghQ_RRBkDJ875-CCv9sf1V7SL2EOv-Zr3zfuUc1K9zWPyudhy3aV8sFP-CDO_Qqs7eKhqpYGZkwen7a5j5SWZ2uDUyfeILxjUiinPVpzoKRFiaCam-AsJ7r0faFjUPz2abau-VuB1gD0U6O4cMGZaiu341Tl8PyFCJZzL-sycERRlTXmvO3wqGjoUICFGSMkpkPJSlrbS4iCx6f7-_DAOOHNpbz-RXyE0tUVk1WR3rWbn4iR_bZJGICZ7--JH4t4OcYVCbvdUVgJnPN9j_9UuKRrCK0D5cz0QERE9Er6fTw0uTL7GdSOJozzq9i47R3TYtPO2hncXh4cYmM8UmIXwWgC4FGSP5kiyKNDziO3A_aZEg2sRvVSjzigCb73mkjswlIaJfO4LWJ258zbFwf7ft9F58M5Kfq2KHjAIV8o1svKZ_1llrr1owK8S7Fm-XHJMfU5Cuo2_n5agb6t-HF1N8DvZKyT0ylqZwBz2y4zmnt0my0cXuy_NrsnxEKPg_CVIITQoNOROTq4rEfnPyXFHsDF6Gh7-wmgOBRgbUR9TtVI7P9lP0LBU0puSoStWWW4DTJkuXmVDz4sM5j6yOGUzpq03SDL-QhpyZpq1U4wI1UfmhzOYlzYLcwJpl0u6jf44FDboZF9Iz4V9tVW86AwrI5ov_Rm_iImhjZgpIVtofL67UnvaJsSxCT_2PIZVD6X-3X2YcQ___BetQ_lTyYWhywPoI5WLoBJjEPyUwq_rFOTbCKJcFhgV-i49op1zkeCdPZtah3rOgCSo95wbPoAwaPv3d5VhDlDAfSdCLNzr6VBos3Ym9La7xU9GNLYIHwb--0ALdXL4GcV-nEhqrhBs46_fI6e3QMKwYPZHHUyniul-H7E7-Rbio-WbQC-J8SHO61CD-qiZWTZS_6jcbLTeKC20s6APzZmv91lV5viIWzkRQy_MfPgob9ft0G8Rinq1ywzZ5FGWlXeqbqqLDImJHcv5sbMbOSpdAftb4VMO7y8WHbQmKip38OP8tzNobikPBk1nibKhAgHp_ppJHlCxiW7EqBkXRY4D4dDcj-hwLusPNlh4LNdrxge7Z36lSWGIwzkpAaKiMAcssNMhsS9z4iS98Mg87bdJQT4x1tdmIocIxu6voXK2foTf-jVxbfCzhbDoD8brxsCj7nNytwO1wfQXwq2NC8FFG8Fma_SYpSTAfBOEFzt_SbKRO8usqLbqfCHIu5hs3tyUAaGOkFUUbhf8h2VGlgJKyEEwN9MKct5KqEVU9tPm0i5COlqkbDRj5PQy3pBKG9Ryuxe0C8lWW7cNyf7ARG6xGAvhoyXYjwV-gy-hSJpzs2ZxtJWB5KuYwjiHBbTNGpGFkqI89Z2AS379KYEV19-8MdLEab5Pgk2ml3sc0EFAq4pU-9Hbf9E6n2Ku3wAYJiw=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  
  title: '의료 산업에서의 RTLS',
  date : '2023-05-04',
  tag1 : '#RTLS',
  tag2 : '#의료',
  tag3 : '#자산관리',
  tag4 : '#자산추적',
  
  // <br/>
  text : [
    {
      head : '의료 산업에서의 RTLS',
      subHead :'',
      desc : '코로나 펜데믹을 겪으면서 의료 산업에 있어서 발전된 IT기술은 더욱 중요시 되고 있습니다.<br/>특히 환자를 추적하고 관리하는 것이 기술의 핵심이라고 할 수 있습니다.<br/>RTLS, 실시간 위치 시스템이라고 불리는 이 기술은 미래에 많은 의료 산업 전반에 걸쳐서 다양하게 활용될 것이며 지금보다 훨씬 나은 수준의 서비스를 제공할 수 있도록 도움을 줄 것입니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'RTLS가 뭔가요?',
      desc : '의료 분야에서 실시간 위치 시스템(RTLS)은 모든 유형의 환자 치료 환경 내에서 의료 장비, 직원 및 환자를 실시간으로 추적하고 관리하는 데 사용되는 시스템입니다. 일종의 병원용 "실내 GPS"라고 생각할 수 있습니다. 그러나 단순히 자산을 찾는 것 이상으로 다른 의료 IT 솔루션과 쉽게 통합되는 정확한 위치 파악 기술을 통해 시설은 작업 흐름을 개선하고 비용을 절감하며 임상 품질을 높일 수 있습니다. <br/>RTLS 솔루션에는 일반적으로 환자, 직원 또는 장비 등 다양한 자산에 부착되는 위치 센서가 포함됩니다. 시스템은 고유 ID를 사용하여 태그를 찾고 시설 내 위치에 대한 실시간 정보를 제공할 수 있습니다. ',
      dot :''
    },
    {
      head : '',
      subHead :'무엇을 할 수 있나요?',
      desc : '병원 내 장비 오류,  감염 또는 물리적 공격을 포함한 위험은 의료 시설 내에서 끊임없는 위협입니다. 실시간 위치 시스템은 그러한 위험의 여지를 줄여 환자의 안전을 증진할 수 있습니다. <br/> 예를 들어, 종종 병원에서는 감염된 의료장비를 세척하지 않고 다시 사용하여 의료사고가 발생하기도 합니다. <br/> 이러한 인적 실수를 줄이기 위해 RTLS 솔루션은 해답을 제공합니다. ',
      dot : {
        a :' • 감염된 환자 또는 사람과 접촉한 직원과 환자를 파악하고 이를 기반으로 접촉 추적 보고서를 생성할 수 있습니다. 코로나와 같은 감염성이 높은 질환의 경우, 선제 조치가 가능합니다.',
        b :' • 오염된 장비는 세척해야 하는 시기를 즉시 파악하고 가능한 감염 위험에 대해서 즉시 통보해줍니다. 또한 해당 장비를 비롯하여 병원 내 의료기기들의 위치를 모니터링하고 이들을 통제, 관리할 수 있도록 관리자에게 정보를 제공해 줍니다.',
        c :' • 고장이 발생한 기기 또는 유지 보수가 필요한 시점이 다 된 장비에 한해서 이를 관리할 수 있습니다.',
        d :' • 의사, 간호사를 비롯한 근로자와 환자들에게 응급 상황이나 신체적 위협이 발생한 경우, 신속하게 도움을 요청할 수 있습니다.',
        e :' • 환자가 가서는 안되는 구역에서 방황하는 경우, 이를 실시간으로 파악하여 대처할 수 있습니다. 또는 지오펜싱 기술을 이용해 자동으로 알림이 울리게 할 수도 있습니다. 이는 특히 치매나 알츠하이머 환자를 돌볼 때 중요합니다. ',
        f :' • 경고 및 보안 시스템, 지오펜싱 기술을 비롯한 RTLS 기술을 사용하여 응급환자나 신생아 등 특히나 취약한 대상들을 보호하고 관리할 수 있습니다.',
      }
    },
    {
      head : '',
      subHead :'무엇이 좋을까요?',
      desc : 'RTLS 기술은 안전에서 특히나 높은 효용성을 발휘하지만 그 외에 경제적인 측면과 서비스 운영 면에서도 큰 도움이 됩니다. <br/> 효율적인 장비 관리로 누수되는 비용을 절감 할 수 있고 기기 사고로 발생하는 비용도 막을 수 있습니다.<br/><br/>운영자의 입장에서는 모니터링되는 정보들을 수집하여 분석할 수 있습니다.병원에서 활용되는 장비들의 사용 현황과 활용도를 파악하고 환자들이 병원에서 머물거나, 건물 내에서 돌아다니는 특정 지역들을 파악할 수 있습니다.<br/>또 병원에 환자와 대기자가 늘어나 건물 내에 병목 현상이 생기면 이를 해소할 수 있습니다. 특정 시간대에 대기 시간이 너무 길어지는 경우 원활한 운영을 위해 조치를 취할 수 있고,이로써 많은 사람들에게 더 나은 경험과 더 높은 만족도를 보장합니다.',
      dot : ''
    },
  ],


  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      }
    ]


  }

}