export default {



    enterprise: '엔터프라이즈',
    supportTitle: '지원',
    start: '시작하기',



    support: {
        technology: '기술',
        blog: '블로그',
        request: '영업 문의',
        company: '회사 소개'

    }


}