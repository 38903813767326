import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| 安全でスマートな建設現場のためのRTLSソリューション',
  desc : 'スマートな建設現場は生産性と効率を向上させ、労働者の安全を確保します。 RTLSテクノロジーは、こうしたスマートな建設現場のシステムを構築するための最適な方法となり得ます。',
  keywords : '労働者安全管理、建設現場安全管理、建設現場、現場安全、建設安全、スマート建設、重機管理、リアルタイム位置追跡、安全管理、RTLS、UWB、Orbro、KonTech',

  ogTitle : '',
  ogDesc : '',
  ogSite : '安全でスマートな建設現場のためのRTLSソリューション::オルブロブログ',
  ogUrl : 'https://orbro.io/jp/blog/constructionSafety',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4ODZOuIwgi2GmgeN38XOohY2I6ZhTq1OX26xq5S1gJqAGQsMoshxsOsqKhEqbIAKQ4m2i0wnTaha9gJzWrOTQJjh-kG35v_PKDbGn6oQKyjdPrG83wKwq9aDBoWxLoDMDp5REkLIfvg86TqENuhdkKlvaCE8JtLLjZtoMWuiSQCYZYRXIk1QRwMiLlKrLdUR3Ae7WNDbQamCAEzOhA4iB7NibqGzN1cFrlfeKXbBN95eOv_6gdNvv8egQ9WRKK1ABThUboxhbiTLeGy74mZO217xUHPe9qAWl_BitXQj3yMJXo_3BQUooKtjvw8Lm1OAObOJX5ZUIyV_DF_WDnsYCP6ypezD9GmdGsasbiagdvp9zxbH2CvrIBSLYhNFv34TTAiztM6BukR-0OJInkIZHvaJQyilhLrE44lrqa8M3IzDhzWEOK-bb3Tz5mXAe370UpbaXxkMHaEJS72MZgBbyUj-J3f7J889zkMt7U7UffPNi4BGFhgb5prG4kOatNmsgh8pmVHsAk4xHZ8Fewny7ASDPV2Q4z9YuGgCynJaNDxYwb3FurwZ8G-Nfwgdt2KfslDc1yQ5I-IX-4gd8DTlSCr596Caq2a1UsBSEyl4J9lioE1CFUj22KymcIVvSBhkp285DCyho_1lztbytT6HMUyMOyaMMvEyNnug6qmzJeQEVITT9AqDTwv4crACqyBUCZXmdgcBNa45zkwL_Jsx1akoJohEvzXJ7qMFfZHXmPapV4uS5jSmJGSkvVPCjjhTqGFvy0NDStFwvdrwqGHnI34q1-p2wTpJG2kvUhUH7grFZhik9E7V--QY6HF4fkAjtjgmSiY3mrf4qGOxawVFTvH0n5R4fppcdngOJRnpXyeNUIm-1L8-Qe_A7xZl04bFY7AMwjt9u-DeXWOdd6buaBYjig4EHIovTR2IeuTI2BtFyttqCDwpea3cJuouIq4VZ_m8QEFTUGrq8hkviXmE8iVc80a_vpEfJebHjzWM5f48cjhEk-LVjsrECpYd1lU9TYRV3a6mLY0VxcVpNdN8rxI7cQe5YgRBJGSHR4ECjku3U9jvPJeMJ7hJ4V9D8donENtGVDWuiVPXUk6RH9XU9yeVxMTApzrsB58tA03IPp8f2Aejpzr8ksllmvuubwwbr3JXX6nqREowvgldTiKTGrvT0ca1ol6reTjqX5Xr06c0n5F2v-aYGnPNajdoQNBxtUcxpQRVd-yKyJryJXz56r_J6c2QiiVIADQMjYUH9ULyFT0d0UvnwJS4cTdqEGBzUoJNTLZZi2JtD3cJvG8vrrz5xHUsR2SIkyNjCGLZyOouOh7etxxjVK_MNFYqDGli4SQhp48hMgCc56c4vMuWmZXZlSgUueLUUtEBsLpUFaTERHwqNvJaRHFJOpIyNIOsibD94KowEbCGWg9pydqRNrAdY7TQ7HbAUqUTWFMdCD4Kzkdx4cKPIzL9YTVCvBtk23t0a9E343R1x38JavpVlYeMQVxXxDG5jpP5O9lXmBitXgEfDHBmTnT_BUAvgjYjkUG5djwJfZ6E8K_FRHXtgkB-M62IYcrVsaZAd_9c1Mq8YKMP-fJ=w2052-h1199',
  ogType : 'ウェブサイト',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '安全でスマートな建設現場のためのRTLSソリューション',
  date : '2023-09-19',
  tag1 : '#労働者安全管理',
  tag2 : '#建設現場安全管理',
  tag3 : '#スマート建設',
  tag4 : '#重機管理',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'建設業界は現代社会で重要な役割を果たしており、それに伴い建設現場の効率と安全性を向上させるための技術導入は重要な課題となっています。 最近では、リアルタイム位置追跡システム（RTLS）技術が建設現場に革新的な変化をもたらし、その採用はスマートな建設現場の構築に重要な役割を果たしています。 このブログでは、RTLS技術の概念と、スマートな建設現場を構築するのにどのように役立つかについて探求します。',
      dot : ''
      
    },
    
    {
        head : 'RTLSテクノロジーの概念',
        subHead : '',
        desc :'リアルタイム位置追跡システム（RTLS）は、無線通信を利用して物体やエンティティの正確な位置をリアルタイムで追跡する技術です。 RTLSはGPS、RFID（Radio-Frequency Identification）、UWB（Ultra-Wideband）、BLE（Bluetooth Low Energy）など、位置情報を収集および管理するためのさまざまな技術を活用しています。 建設現場では、これらの技術を使用して効率的に設備、材料、労働力を管理することができます。',
        dot : ''
        
      },
      {
        head : 'RTLSテクノロジーの利点',
        subHead : '',
        desc :'建設現場にRTLSテクノロジーを導入することにはいくつかの利点があります。',
        dot : {
            a :' • <strong>資産および設備管理</strong>： RTLSを使用すると、建設設備および資材の位置と状態をリアルタイムで追跡し、効率的な在庫管理とメンテナンスが可能になります。',
            b :' • <strong>安全性の向上</strong>： 労働者の位置をリアルタイムで監視して事故を予防し、緊急時に迅速な対応が可能です。',
            c :' • <strong>生産性の向上</strong>： RTLSを使用して建設作業のスケジュールと作業量を管理することで、生産性を向上させ、過剰な時間とリソースの浪費を防ぎます。',
            d :' • <strong>データ分析と予測</strong>： RTLSはデータを収集し、分析して建設作業のパターンを理解し、意思決定をサポートし、効率的なリソース配分をサポートします。',
          }
        
      },
      {
        head : 'スマートな建設現場の構築におけるRTLSの応用事例',
        subHead : '自動化された設備運用',
        desc :'RTLSを使用した自動化された設備運用は、生産性の向上とコスト削減を図るための主要な応用事例の一つです。 建設設備にRTLSタグを取り付けると、その設備の位置と状態をリアルタイムで追跡できます。 これにより、建設マネージャーは設備の移動経路を最適化し、燃料消費を最小限に抑えることができます。 たとえば、クレーンが効率的に配置されていない状況では、材料の輸送に時間とエネルギーが浪費される可能性があります。 RTLSを使用することで、クレーンの位置を最適化し、材料の輸送時間を短縮し、エネルギーを節約できます。 このような自動化されたシステムは、作業進捗の迅速な達成、生産性の向上、エネルギー効率の向上を実現し、助けます。',
        dot : ''
        
      },
      {
        head : '',
        subHead : '労働者の安全性向上',
        desc :'建設現場では労働者の安全性が最重要です。 RTLSテクノロジーは、労働者の安全性を向上させるのに大きく貢献します。 労働者はRTLSタグを着用する必要があり、そのタグを使用して労働者の位置をリアルタイムで追跡し、危険なエリアに入るか、事故の可能性がある場合に警告を送信できます。 さらに、労働者の健康状態を監視し、厳しい環境で作業している場合、作業中断や適切な対策を取ることができます。 このシステムは事故の予防と労働者の安全性の強化に役立ちます。',
        dot : ''
        
      },
      {
        head : '',
        subHead : '材料と設備管理',
        desc :'材料と設備の管理は、建設プロジェクトの成功に影響を与える重要な要素の一つです。 RTLSを使用すると、建設現場で使用される材料と設備の位置と状態をリアルタイムで追跡できます。 これにより、在庫管理がより効率的に行え、損失を最小限に抑えることができます。 たとえば、特定のポイントで必要な材料が不足している場合や、重複して保管されている場合を防ぐことができます。 また、アイドル状態の設備を特定し、メンテナンススケジュールを最適化するのに役立ちます。',
        dot : ''
        
      },
      {
        head : '',
        subHead : 'プロセスの最適化',
        desc :'RTLSは建設プロセスを最適化するためにも使用されます。 建設プロジェクトはさまざまな作業段階から構成され、これらの段階間の調整と調整が必要です。 リアルタイムで各段階の作業量と進捗を監視および分析するためにRTLSを使用することで、工程間のボトルネックを防ぎ、プロセスを最適化するだけでなく、スケジュールを守り、品質を維持できます。',
        dot : ''
        
      },
      {
        head : '',
        subHead : '環境管理',
        desc :'環境管理は現代の建設現場でますます重要な問題となっています。 RTLSを使用してエネルギー消費、廃棄物管理、老朽化した設備の交換などを監視および管理できます。 これにより、エコフレンドリーな建設とエネルギー効率の向上が実現し、持続可能な建設現場を作成するのに役立ちます。',
        dot : ''
      },
      {
        head : '',
        subHead : '顧客サービスの向上',
        desc :'RTLSを使用することで、顧客サービスも向上させることができます。 顧客はプロジェクトの進捗状況をリアルタイムで監視し、問題が発生した場合には迅速な対応を期待しています。 RTLSを使用してプロジェクトの進捗状況を透明に提供し、問題が発生した場合には迅速に対応することで、顧客満足度を向上させることができます。',
        dot : ''
      },
      {
        head : '結論',
        subHead : '',
        desc :'RTLSテクノロジーの導入は、建設業界がより効率的で安全な現場を構築し、データ分析に支えられたスマートシステムを作成するために不可欠です。 したがって、RTLSテクノロジーを使用することは、建設業界の未来が明るくなることを示しています。 RTLSテクノロジーを積極的に導入し、よりスマートで安全な建設現場を作り上げることは重要な課題です。',
        dot : ''
      },
  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
        {
          text: productPurchase.purchase.worker.workerTracking.title,
          title: productPurchase.purchase.worker.workerTracking.title,
          serial: productPurchase.purchase.worker.workerTracking.serial,
          desc: 'UWBデバイスを作業者または設備に取り付け、リアルタイムの位置追跡を支援する製品です。',
          img: productImg.product.workerTracking.thumbnail
        },
      {

      }

    ],
    solution : [
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/jp/enterprise/construction/safety'
        },
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/jp/enterprise/construction/asset'
        }, 
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/jp/enterprise/logistics/asset'
        }
    ]

  }


}
