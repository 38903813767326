import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";



export const ProductPage = styled.div`
  padding-top : 128px;
  padding-bottom : 128px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    padding-top : 96px;
    padding-bottom : 96px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding-top : 96px;
    padding-bottom : 96px;
  }

`


export const ProductBox = styled.div<{show : boolean}>`
  width : 100%;
  display : flex;

  max-height : 300px;

  opacity: ${({ show }) => (show ? 1 : 0.2)};
  transition: opacity 0.4s ease;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    flex-direction : column;
    width : auto;
    max-height : fit-content;
  }

`;


export const ProductImgBox = styled.div`
  width : 50%;
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
  }

`

export const ProductTextBox = styled.div`
  width : 50%;
  padding : 64px 0;
  margin-right : 32px;
  word-wrap: break-word;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
    padding-top : 32px;
    padding-bottom : 0px;
    margin-right : 0px;
    margin-bottom : 64px;
  }
`;



export const ProductImg = styled.img`
  width : 50%;
  object-fit : contain;
`;


export const IndexBox = styled.div`
  position : relative;
  
  height : 48px;

  width : 100%;
  display : flex;
  justify-content : center;

  gap : 16px;

`;

export const IndexImg = styled.div`
  display : flex;
  flex-direction : row;
  align-items : center;
  gap : 16px;
`;


export const IndexMark = styled.div<{i : number, j : number}>`
  width: 20px;
  height: 2px;
  
  ${({i, j})=> i === j ? 'background-color : #000;' : 'background-color : rgba(0,0,0,0.08);'}
  
`;


export const IndexBtnBox = styled.div`
  display : flex;
  flex-direction : row;
  gap : 16px;

`;

export const IndexBtn = styled.img<{direction : boolean}>`
  width : 48px;
  height : 48px;
  display : flex;
  justify-content : center;
  align-items : center;
  padding : 12px;
  border-radius : 100px;
  border : 1px solid #000000;

  object-fit : contain;
  
  cursor : pointer;

  ${({direction})=> direction ? 'transform : scaleX(-1);' : ''}

`;

