import React, { useMemo } from 'react';
import { StyledGridContainer } from '@components/Grid/Grid.style';
import {
  StyledBtn, StyledLineupPage, StyledProductBox, StyledProductImg, StyledSpacing32
  , StyledTextLabel, StyledTextPara
} from './Lineup.style';

import { accessMainKey2, accessMainKey3, accessMainKey4, } from '@assets/images/main';
import { Trans } from 'react-i18next';
import { HeadingH3 } from '@components/Text/Heading/Heading.style';
import { assetTracking1, workerTracking1 } from '@assets/images/main/rtls/product';
import { indoor280, outdoor280 } from '@assets/images/main/camera';
import { useLocation } from 'react-router-dom';
import { deliverPackage, deliverRobot1, disinfection1 } from '@assets/images/main/integration';
import { Spacing } from '@pages/CommonStyle/common.style';


const Lineup = ({ handleIconClickLocal, product }): React.ReactElement => {

  const location = useLocation();
  const currentPath = location.pathname;

  let images;
  let lineup;
  let link;

  if (product === 'rtls') {
    images = [assetTracking1, workerTracking1];
    lineup = ['asset', 'worker'];
    link = ['ble', 'uwb'];
  } else if (product === 'camera') {
    images = [outdoor280, indoor280];
    lineup = ['outdoor', 'indoor'];
    link = ['outdoor', 'indoor'];
  } else if (product === 'integration') {
    images = [deliverRobot1, deliverPackage, disinfection1];
    lineup = ['robotBasic', 'delivery', 'disinfection'];
    link = ['robot', 'robot', 'robot'];
  }
  else {
    images = [accessMainKey2, accessMainKey3, accessMainKey4];
    lineup = ['auto', 'face', 'accessory'];
    link = ['auto', 'face', 'accessory'];
  }

  const handleSelectedProduct = (index) => {
    localStorage.setItem('selectedProduct', `${index}`);
  }


  return (
    <StyledGridContainer>
      <StyledLineupPage>

        {lineup.map((data, i) => (
          <>
            <StyledProductBox>
              <StyledProductImg src={images[i]} alt="RTLS product" />
              <Spacing pixel='16' />
              <HeadingH3 size="xsmall"> <Trans i18nKey={`${product}.mainPage.product.${data}.title`} /> </HeadingH3>
              <StyledTextPara size="medium">
                <p>
                  <Trans i18nKey={`${product}.mainPage.product.${data}.desc`} />
                </p>
              </StyledTextPara>
              <StyledSpacing32 />

              <StyledBtn href={`${currentPath}/${link[i]}`} onClick={() => handleSelectedProduct(i)}>
                <StyledTextLabel size='small'>
                  <Trans i18nKey={"home.showMore"} />
                </StyledTextLabel>
              </StyledBtn>
            </StyledProductBox>
          </>
        ))}

      </StyledLineupPage>
    </StyledGridContainer>
  );
};

export default Lineup;
