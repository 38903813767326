import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4";

export default {

  helmet: 'The Functionality of Geofencing',
  desc: `Geofence is a technology that combines geography and fence, creating virtual zones or virtual fence boundaries in real-world locations.`,
  keywords: 'geofence, geofencing, RTLS, indoor positioning, indoor navigation, location tracking solution, real-time location tracking, asset tracking, smart office, GPS, indoor positioning solution',

  ogTitle: 'What is Geofencing? :: Orbro Blog',
  ogDesc: 'Let\'s explore what geofencing is and how it works in real-life scenarios.',
  ogSite: 'The Functionality of Geofencing',
  ogUrl: 'https://orbro.io/en/blog/geofence',
  ogKey: '',
  ogImg : "https://lh3.googleusercontent.com/fife/APg5EOZ2VWDCFEvSR2JhseSzS-2cQQnuMSFaM4obXlnNyqODBBoOLK1WTlmDiYajp8HYXztTLEp85J505ZV5DYOw4XK3f1-uBLTCKdymXB4nkl-0IPByDA2WVhTi9FpkH6to_X-pf_w4WnDhm_8vFh7GpJQoCH2jVpjtqLF8i-1MvlxeG-_wdQc3GSE5gyxieKJPBXZ8-h0_v0W275RZ1buY2zhtDYW-Je3wEqRDi5ZAsE_4zx3kHHfBjUgPf7tXIf74EEuIJb11hTQvdt9_n0xPGdgUFS1jN_qXOt0I693By1-jF0KtPqBaUdKQLY0gRf72BYwMTcCZRXQVo33POJ_kdczRYP9s1aOYSsbil2Xm21xAepKz9UeeByVEcX4Xj-yHE0JQh7B3-djLNtAGljujc4PvseRrPymDygPFx9o46B9PNRNGuFrORa_oJ1MLhFeqyW9xNQQu2a84bqh7v9ZOh714RBLDjAqUSDa0ZRXXWhKJqcMGjSWnjPyfFw7ILKzwJGKoiFmCCwFqlovHO3zTKM3Zem4TEk4g2msD6wwMQ3Ptsft6tcszn1HdpRRZ2l6Qq3ha5k8ewubMa06Q-aFkk-4apY3DsX-8Ey4b9kTIQqFNLLKHFNpFZdC5iPT-LYi56yuQUJPpJ7KF6RRexa3HfDTWeT8FNwnqNuDyCrbQ06Ix4Es5S1jXOW8ofiK2NplaeNg_1tca9QbIe-FDTOPqTeEHqMwCfDVncRYh0Z3e0x4Ywk6ebn2F0kccqqb6F7cVLGaVLOTsRs4rQ1r43TmZoG2elSGIsBntEp49Zn521JghIhCHcDIFjaYb9-RcgDF5oO7GIJT_YhTgPnWvKmGoBuesfZQaVSWgY5lhRHjULVAv-sg9X_EMWY3VhMkHAeGJlePyaD1531N_6-6UAs-QY7Ny66DVNen_QukuG1oAfQ29qDuvq9zJz2RrbafnZ2rantG3F_r2W0jvZiWuI-V_qiu4xBaQAzNklEly2t1aC7QDEW5qgzvbiS3hjC7U9KaRgsKFl_IhwaEjJMa_jv8JyNItI4P0XMjpADTrBPEgLFIbKGPiwtK2Ijjj91Cx-y2ElqS_-fmTQd63XX7c3Mhy4gK6SISrs24Zx9VdSKueoy7cBXubjcSj9QghIZsDvu3UpcZBq9EF8zYSf9-WGJIPj9qFf5_x4S8Ty_cD_XKF_Ic6bRMa_jZToGam_G5X94ag30kD3Wnrlw4AI3YFIHn_AYPVRXyz9aWY4QL1hiMrAHHLhGNd0ozBYqtmxSxga0ZI9UcIhlolXBSRe4D2JksdHn1ixx_WgBPto76Lt99eGq30wecQJbnSQlylNuqtkAvO1XBneOkYEEI0BT8eNwwz1R910N416ZXKPkgxujAs-FZHJoZxlp90d9Kv1UzKcKNKCbZgO6x3No9f-nkHzKtMmz5pVltLwkh5Hb7R-hcshSq2N4Vr1fpbMOpaJumw-fa-XiCszh7LwMXHgUO_8_071RRZfW0tBNV2OSKvdLNUv7yM_MrJ71sMx88Dbz0L1g=w1680-h1184",
  ogType: 'website',


  twitterCard: 'summary',
  twitterTitle: 'The Functionality of Geofencing',
  twitterDesc: 'Let\'s explore what geofencing is and how it works in real-life scenarios.',


  title: 'What is Geofencing and What Does it Do?',
  date: '2023-05-01',
  tag1: '#geofence',
  tag2: '#assettracking',
  tag3: '#RTLS',
  tag4: '#safety',

  // <br/>
  text: [
    {
      head: 'What is Geofencing and What Does it Do?',
      subHead: '',
      desc: 'Geofencing is a combination of "geography" and "fence," which refers to creating virtual boundaries or fences in real-world areas. It utilizes real-time location tracking services (RTLS) to monitor whether individuals pass through specific areas. Geofencing finds applications in various fields such as private businesses, healthcare, military, aviation, and more. The geofencing market is predicted to exceed 9.5 billion dollars by 2032, starting from 1.9 billion dollars in 2022, indicating its significant value in future industries.',
      dot: ''
      
    },
    {
      videoDesc: '<br/><br/>(Construction Site Safety Management Solution using Geofence Technology)',
      video: video,
      link : '/en/enterprise/construction/equipment',
      head: '',
      subHead: 'How Does Geofencing Actually Work?',
      desc: 'Location-based service devices initially set specific boundaries and regions, and developers establish virtual boundaries around those specific locations. Geofencing then combines with other auxiliary technologies such as Wi-Fi, cellular data, beacons, and more to track users\' real-time locations and access information. When a mobile device with location information enters the designated area, predetermined actions, such as sending text messages, emails, or app notifications, are executed based on specific conditions. The process typically occurs through mobile devices, and users can choose to deny or configure app location access separately. Consent from users is prioritized for location-based service usage.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Where is Geofencing Used?',
      desc: '',
      dot: {
        a: ' • <strong>Assets</strong>: Geofencing technology can be installed on assets (vehicles, tractors, computers, etc.) to track when they move beyond geofenced locations.',
        b: ' • <strong>Human Resources</strong>: It allows accurate monitoring of employees\' duration of stay and commuting status. For field workers, it enables more efficient management of access control areas.',
        c: ' • <strong>Retail Stores</strong>: Promotional messages can be sent to tourists passing by stores to encourage visits.',
        d: ' • <strong>Home IoT</strong>: When smartphone users leave a geofenced home, temperature control devices can be adjusted accordingly.',
        e: ' • <strong>Military</strong>: Geofencing technology based on location information technologies like GPS provides services to restrict dangerous objects or flights from approaching airports and military zones.',
        f: ' • <strong>Airports</strong>: Messages regarding facilities in the vicinity can be received. Free Wi-Fi services and airport guide services near airports can also be provided.',
      }
    },
    {
      head: 'How Helpful is Geofencing?',
      subHead: '1. Safety and Security',
      desc: 'Sensitive areas regarding safety or security, such as construction sites, hospitals, military facilities, can be designated, and individuals accessing these areas can be monitored. This enables the prevention and swift response to safety accidents and incidents.',
      dot: ''
    },
    {
      head: '',
      subHead: '2. Work Efficiency',
      desc: 'Geofencing technology directly relates to the safety and efficiency of workers and workplaces. For example, it monitors the working status of employees at a company or workers at job sites. If someone is absent from the workplace for an extended period or accesses restricted areas during work, administrators can take appropriate measures to prevent potential issues.',
    },
    {
      head: '',
      subHead: '3. Personalized Customer Experience',
      desc: 'By offering coupons and benefits to individuals passing through specific areas, customer experiences can be personalized. This helps reduce marketing costs that were previously wasted. Recently, domestic credit card companies utilize geofencing technology to send notifications to people in certain areas, such as department stores.',
    },

  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/en/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/en/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/en/enterprise/logistics/asset'
      }
    ]


  }
  




}