import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const SystemPage = styled.div`
  padding-top : 128px;
  padding-bottom : 128px;
  border-bottom : 1px solid rgba(0,0,0,0.08);
  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    padding-top : 96px;
    padding-bottom : 96px;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding-top : 96px;
    padding-bottom : 64px;
  }

`

export const SystemImg = styled.img`
  width : 100%;
  max-height : 600px;
  aspect-ratio : auto 2 / 1;
 
  object-fit : contain;
`