import { constructionAsset, constructionEquipment, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| Smart Construction Site Safety Management System',
  desc : 'Using IoT technology and RTLS, it tracks the real-time location of workers on construction sites and enhances safety by generating warnings when entering hazardous areas. This ensures worker safety, improves accident prevention, and enhances on-site management efficiency.',
  keywords : 'Construction Safety, Construction Site Worker Safety, Smart Construction, Worker Safety, Serious Accident Punishment Act, Construction Union, Safety Labor',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'Smart Construction Site Safety Management System :: ORBRO Blog',
  ogUrl : 'https://orbro.io/en/blog/smartConstruction',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4OLTH19o0lofjwwmkKAz-9CGPCQcIHeGEvzHeubn5er_MCfU-q7GYUawQUgiBhV0p2H76qh1eSAjvENS8D30uK8wsm0dvRVz9fbghVm-npKypYxWtsi_kYTYd1uZYhCTnPpxWWxuJjMsmCvjKN2aY8L6u8uqXYCtcS2irDtBPmqv0nFkB22q83mh1u0tHLtyMOjggWKiJKLrctPn4GE5QW3s9JSv6OmA8ZhKbRghTN42Hq8Et9cH8kU-Nkbv_b26v6aQUdGmrak5jXiAKE4sXCbRnLX5IT_XnZo660WsMWHlDftI9hI392gO7mJYmKHNYA0Hhdh8rCxv2YcyNz4KSiwSz9Jp3W-OhtcoLEyNuTfLi_3AS4__oyVTxTqqP61RHt_sRKPpHWzYWKD2-lRFFqGAQnxfdW-cS3KHJFgwx2bn3SlV2YmG8WDnLTN2xFmd3_t6DTG8QVZbDg2gHQz4sqmC91sVjpA-UACZ0Ea_8Z5tsVSsIUcZeOLqJ2OSf4CgoOXD7KX5QgQz0O8OI6hq8riIT0Q_ubXhp_-gNUyjqFj6XUxFp1N8l1vWcJz_vaq817pMKLQOh5tUhXUl1QPqo43LWZGk6FCPDhUDVOZbq731ORKRpWvfDieeQpaAHXBh1TIb104d8BxMZNoVwDMtQjqysfBeMFBDTJDq1cQOGrhcT1uLJ8p0sdYxZQrKha8F66CA9glk9n61-IowjHJKAFd8H0Rrapwp7UJwhug12o4UvffLxnbqNzmdX1RraJoFrfcs6Fhp8da4-TdfOtEqhwoTkqobcXUN07zWEQUl2CRHHRUG82xw4wlcF9BIunVPJe894pfmXE4C0ETayUHYXDJcIYK7SQUS08H7Xi2fyQHw_zZIHg8Lj_w3ejIUGBANJuIxQglvskR5g7NjbJ0EBv2bCKLqkGEsPZMG5ypfBu2BtfKkDZT4kekWaO9TXJrrVDYa_A18PBQSN9hv2G8JwBGt_bvRlGZMDsGu6VCwq3fJAB8gtzb_0U8PrztEHGSsojNVoVZ7BN6OUROQFTH9IQZHIv_NC9jPraNFThG8ydegAos8aSaqEMeohU8FOv1Lck-kOCLo-HB0qSX11IkppPvCaSgblbcpB9ET8m0I3gQGkJpyBdwmY70NMVKUe1az2MUpZRUgoQaLP7ZKujMw7syYLaZ_hCiXuYqsF_Fn7yZ9nmG1FjxDGVKEbijl33lTSgLAWVc1yhzuS-8__AcbhG2nI2aAii6ZxqZAfHMBHQQ5BLYQTJCpJZjLeJKgBg_XT6Gl56dGOq8vhdrl8NdBqsL54EVg_a_qn4cvrSaQ5Lt8IDGgYgNFvrPBwLgnqWb2Ll6oLL1MvhV2vru9BFadHclWr0Ly4nPy5DI1Lr4ghP2biSKLMQeXyUo97JePQN6ei9jdi7k1hzr9Kk1vrBIFz5Okp_FaZeKDikQ5oZ7yVctdAjvZIBO-h5i_Zin6sl878TaGDErBn301m-gHvF1G8AMlU46qZnNBPlPld-YUOqM6V79RFxmf_wpJWAFld9xB4ud-CLWzo6xdWzpanyQoaf7cj9Myian97M297lV5zEBj-Q=w2295-h1233',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'Smart Construction Site<br/>Safety Management System',
  date : '2023-07-31',
  tag1: '#ConstructionSafety',
  tag2: '#WorkerSafety',
  tag3: '#ConstructionSiteSafety',
  tag4 : '#ConstructionSafetyManagement',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'Construction sites are often more hazardous than other industries. Therefore, ensuring the safety of workers should be the top priority. Neglecting worker safety in such a high-risk environment can not only disrupt projects but also lead to legal liabilities.<br/>In fact, in the construction industry, it can be said to be the most critical and most important aspect.<br/><br/>Many safety accidents occur when workers fail to properly wear safety equipment. Moreover, accidents often happen in a split second, leading to fatalities when swift actions are not taken. However, by using real-time location tracking technology known as RTLS (Real-time Location System), construction sites can track and manage workers and overall site conditions in real-time, ultimately creating a much safer construction environment. This technology can also significantly improve efficiency in construction projects, including material management and analysis of worker movement.<br/><br/>Below, we will discuss how this technology will transform and enhance construction sites.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'1. Safety Equipment Usage Management',
      desc : 'Wearing safety equipment is the most basic and essential safety protocol on construction sites. However, for various reasons, workers may sometimes neglect to wear their equipment properly. Neglecting or feeling inconvenienced to wear the equipment can ultimately lead to fatal accidents.<br/>Real-time tracking allows administrators to determine if workers are wearing their safety equipment and, if the current location is a high-risk area, they can pay closer attention to management. Administrators can monitor the situation in real-time to provide warnings or take corresponding actions.<br/>If an accident occurs, this data can also be used as important information to understand the situation at that time.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'2. Real-time Location Tracking',
      desc : 'There may be occasions when specific workers need to be rapidly located or when an overall view of worker positions on the site is required. Real-time tracking shows the positions of workers moving on a map identical to the site layout, enabling quick understanding and assessment of the situation.<br/>Alternatively, the technology can utilize geofencing to set specific areas as danger zones, triggering real-time alerts if workers enter these designated zones.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'3. Work Efficiency Management',
      desc : `Worker movement, dwell time, break time, and more can be tracked and analyzed. Information about individual workers' work characteristics and overall flow can be obtained to improve project efficiency.`,
      dot : ''
      
    },
    {
      head : '',
      subHead :'4. Heavy Equipment Management',
      desc : 'Not only workers move around the site, but heavy equipment also plays a crucial role, especially concerning safety. Real-time tracking of heavy equipment allows for immediate alerts if they come close to workers, helping administrators detect collision risks in advance. Additionally, real-time tracking of heavy equipment is related to its efficiency. Optimal placements can be made on-site, and the shortest routes can be identified to save time. It also benefits inventory management and maintenance aspects.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'Conclusion',
      desc : 'Until now, managers have relied on direct observation and judgment to give instructions on construction sites.<br/>However, with new technology, smart construction site safety management has become possible.<br/>Beyond safety, it enhances the efficiency and productivity of construction projects and brings a more systematic approach to the work.',
      dot : ''
      
    },

  ],


  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/en/enterprise/construction/safety'
      },
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/en/enterprise/construction/asset'
      }, 
      {
        img : constructionEquipment.thumb,
        data : 'constructionEquipment',
        link : '/en/enterprise/construction/equipment'
      }
    ]

  }


}