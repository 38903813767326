import {
    functionImg1D, functionImg1M, functionImg1T,
    functionImg2D, functionImg2M, functionImg2T,
    functionImg3D, functionImg3M, functionImg3T,
    functionImg4D, functionImg4M, functionImg4T,
    functionImg5D, functionImg5M, functionImg5T,
    functionImg6D, functionImg6M, functionImg6T,
    functionImg7D, functionImg7M, functionImg7T,
} from "@assets/images/main/access/components/index";

export default {

    functions: {

        title: '易于使用的软件<br/>人人可用',
        desc: 'Orbro基于8年的访问管理经验开发了其软件。它考虑了用户反馈和需求，最大程度地提高了现场便利性。这是根据实际用户需求开发的产品，为访问管理提供了真正的优势。',
        line: 3,
        card: [
            {
                size: '4',
                tag: '用户注册',
                title: '通过手机号码或电子邮件邀请用户',
                label: '管理员',
                img: {
                    desktop: functionImg1D,
                    tablet: functionImg1T,
                    mobile: functionImg1M
                },
            },
            {
                size: '4',
                tag: '访问权限管理',
                title: '简化的访问权限管理',
                label: '管理员',
                desc: '只需注册允许访问的设备，即可创建访问权限。',
                img: {
                    desktop: functionImg2D,
                    tablet: functionImg2T,
                    mobile: functionImg2M
                },
            },
            {
                size: '2',
                tag: 'Excel下载',
                title: '下载访问记录和用户信息',
                label: '管理员',
                img: {
                    desktop: functionImg3D,
                    tablet: functionImg3T,
                    mobile: functionImg3M
                },
            },
            {
                size: '2',
                tag: '远程控制',
                title: '远程控制访问门',
                label: '管理员',
                img: {
                    desktop: functionImg4D,
                    tablet: functionImg4T,
                    mobile: functionImg4M
                },
            },
            {
                size: '2',
                tag: '自动进入',
                title: '无需打开应用程序即可访问',
                label: '用户',
                img: {
                    desktop: functionImg5D,
                    tablet: functionImg5T,
                    mobile: functionImg5M
                },
            },
            {
                size: '4',
                tag: '移动小部件',
                title: '提供快速启动应用程序的小部件',
                label: '用户',
                img: {
                    desktop: functionImg6D,
                    tablet: functionImg6T,
                    mobile: functionImg6M
                },
            },
            {
                size: '4',
                tag: '自动进入',
                title: '轻松设置门的开启距离',
                label: '用户',
                img: {
                    desktop: functionImg7D,
                    tablet: functionImg7T,
                    mobile: functionImg7M
                },
            },
        ]
    },

}
