import { FlexStyle, Vertical } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const StyledAdvantageItems = styled(FlexStyle)`
  width: 100%;
  gap: 32px;
  margin-top: 48px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    flex-wrap: wrap;
    row-gap: 64px;
  }
`;

export const StyledAdvantageItem = styled(Vertical)`
  flex: 1;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width: 100%;
    flex: none;
  }
`;

export const StyledAdvantageItemIcon = styled.img`
  width: 48px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width: 36px;
  } 
`;


export const StyledAdvantageInv = styled.div`
  width: 100%;
  border-radius: 24px;
  margin-top: 128px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding: 48px 36px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 48px 24px;
  }
`;