import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import i18n from "@pages/i18n";
import SEO from "@pages/SEO";

import Video from "./components/Video/Video";
import Banner from "./components/Banner/Banner";
import Func from "./components/Func/Func";
import SubFunc from "./components/SubFunc/SubFunc";
import System from "./components/System/System";
import Product from "./components/Product/Product";
import Contents from "./components/Contents/Contents";
import Request from "./components/Request/Request";
import NewsLetter from './components/Newletter/NewsLetter';

import constructionSafety from "@src/lang/ko/enterprise/construction/constructionSafety";
import constructionAsset from "@src/lang/ko/enterprise/construction/constructionAsset";
import constructionEquipment from "@src/lang/ko/enterprise/construction/constructionEquipment";
import logisticsAsset from "@src/lang/ko/enterprise/logistics/logisticsAsset";
import logisticsSafety from "@src/lang/ko/enterprise/logistics/logisticsSafety";
import officeAsset from "@src/lang/ko/enterprise/office/officeAsset";
import manufacturingProcess from "@src/lang/ko/enterprise/manufacturing/manufacturingProcess";
import manufacturingAsset from "@src/lang/ko/enterprise/manufacturing/manufacturingAsset";
import medicalPatient from "@src/lang/ko/enterprise/medical/medicalPatient";
import medicalWaste from "@src/lang/ko/enterprise/medical/medicalWaste";
import retailExhibition from "@src/lang/ko/enterprise/retail/retailExhibition";

import seoEnterprise from "@src/lang/ko/seoEnterprise";
import { FontPage } from "@pages/CommonStyle/commonDiv.style";


interface ModalDefaultType {
  pageName : string
}

const Solution = ({
  pageName
}: PropsWithChildren<ModalDefaultType>) => {


    const paths = {
      constructionSafety: {
        path: constructionSafety,
      },
      constructionAsset: {
        path: constructionAsset,
      },
      constructionEquipment: {
        path: constructionEquipment,
      },

      logisticsAsset: {
        path: logisticsAsset,
      },
      logisticsSafety: {
        path: logisticsSafety,
      },

      officeAsset: {
        path: officeAsset,
      },


      manufacturingProcess: {
        path: manufacturingProcess,
      },
      manufacturingAsset: {
        path: manufacturingAsset,
      },


      medicalPatient: {
        path: medicalPatient,
      },
      medicalWaste: {
        path: medicalWaste,
      },

      retailExhibition : {
        path : retailExhibition,
      },


    };
    
    const location = useLocation();
    
    const helmetTitle = seoEnterprise.solution[pageName].title;
    const ogKey = seoEnterprise.solution[pageName].key;
    const ogDesc = seoEnterprise.solution[pageName].desc;
    const ogImg = seoEnterprise.solution[pageName].img;
    const ogUrl =seoEnterprise.solution[pageName].url;
    
    const jsonLd = {
      "@context" : "https://schema.org",
      "@type": "Service",
      "name": `${helmetTitle}`,
      "description": `${ogDesc}`,
      "alternateName": `${helmetTitle}`,
      "url":  `${ogUrl}`,
      "keywords": `${ogKey}`, 
      "image": `${ogImg}`,
      "serviceType": "Technical Solution", // 솔루션 유형 정보 추가
      "areaServed": "Global", // 서비스 제공 지역 정보 추가
      "provider": {
        "@type": "Organization",
        "name": "ORBRO",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+82-1522-9928", // 연락처 전화번호 정보 추가
          "email": "orbro@orbro.io" // 연락처 이메일 정보 추가
        }
      },
      "additionalProperty": {
        "@type": "PropertyValue",
        "name": "RTLS Technology",
        "value": "Real-Time Location System" // RTLS 기술 설명 추가
      },
      // 기타 추가 정보나 특징 등을 여기에 추가할 수 있습니다.
    }
    

    const requestRef = useRef<HTMLDivElement>(null);
    const onRequestSection = () => {
      if(requestRef.current){
        requestRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };

    const [path, setPath] = useState(paths[pageName].path);
    
    useEffect(() => {
      setPath(paths[pageName].path);
      
    }, [location.pathname]);
    

    const currentLang = i18n.language;
    const key = location.pathname; 

    return (
        <FontPage key={key}>
          <SEO
            lang={currentLang}
            helmetTitle={helmetTitle}
            ogKey={ogKey}
            ogDesc={ogDesc}
            ogImg={ogImg}
            ogUrl={ogUrl}
            jsonLd={jsonLd}
          />
          

          <Banner pageName = {pageName} path={path}  onRequestSection={onRequestSection}/>
          <Video pageName = {pageName}  path={path} />
          <Func pageName = {pageName} path={path} />
          <SubFunc pageName = {pageName} path={path} />
          <System pageName = {pageName} path={path} />
          <Product pageName = {pageName} path={path} />
          <div ref={requestRef}> 
            <Request pageName = {pageName} />
          </div>
          <Contents pageName = {pageName} path={path} />

          <NewsLetter/>
          
      
        </FontPage>
      )
};
    
export default Solution;