import { SCREEN_BREAKPOINT, StyledGridContainer } from '@components/Grid/Grid.style';
import { StyledDisplay, StyledLabel, StyledParagraph } from '@components/Text';
import { TypeMap } from '@core/util';
import styled from '@emotion/styled';
import { LayoutType } from '@redux/actions/common';

export const newsItemWidthMap: TypeMap<LayoutType, number> = { 
  desktop: 389,
  tablet: 320,
  mobile: 300,
};

export const newsItemGapMap: TypeMap<LayoutType, number> = { 
  desktop: 32,
  tablet: 32,
  mobile: 16,
};



export const StyledControlTitle = styled(StyledDisplay)`
  text-align : center;
`;


export const StyledControlDesc = styled(StyledParagraph)`
  margin-top : 16px;
  text-align : center;
`;




export const StyledControlPage = styled(StyledGridContainer)`


  & * {
    font-family : 'Pretendard' ! important;
    font-display: swap;
  }
  

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
    
  }
`;


export const StyledControlPageOrigin = styled(StyledGridContainer)`
  padding-top: 128px;
  padding-bottom: 64px;
  

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding-top: 96px;
    padding-bottom: 64px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-top: 96px;
    padding-bottom: 32px;
    
  }
`;



export const StyledTextLabel = styled(StyledLabel)`

`;
