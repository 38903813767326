import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledHeading, StyledParagraph } from "@components/Text";

import React, { useEffect, useState } from 'react';
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { BigCard, CardBox, CardGap, CardTextBox, DescBox, SmallCard } from "./Hardware.style";

import { CommonState } from "@redux/reducers/commonReducer";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";
import { BorderBottomLine, HeadingColor, ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import rtls from "@src/lang/ko/main/rtls/rtls";
import { FallTextBox, Falldown, FalldownBox, ProgressBar, ProgressBar2 } from "./Falldown.style";
import { LabelH3_818181 } from "@components/Text/Label/Label.style";
import { Card23Page } from "@pages/Main/Common/Functions/Functions.style";



const Hardware = () => {

  const [index, setIndex] = useState<number>(0);
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  const handleText = (i: number) => {
    if (i === 2) {
      setIndex(0);
    } else if (i < 0) {
      setIndex(1)
    } else {
      setIndex(i);
    }
  }

  useEffect(() => {

    const intervalId = setInterval(() => {
      if (index === 3) {
        handleText(0);
      } else {
        handleText(index + 1);
      }
    }, 4000);

    // 컴포넌트가 언마운트되면 인터벌 클리어
    return () => clearInterval(intervalId);


  }, [index, handleText]);


  const [layoutMaxWidth, setLayoutMaxWidth] = useState<any>(null);

  useEffect(() => {
    const handleResize = () => {
      setLayoutMaxWidth(window.innerWidth);
    }

    setLayoutMaxWidth(document.documentElement.clientWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <StyledGridContainer>
        <Card23Page>
          <DisplayH2 size="small">
            <Trans i18nKey={`rtls.hardware.title`} />
          </DisplayH2>
          <Spacing pixel="16" />
          <StyledParagraph size="medium">
            <p>
              <Trans i18nKey={`rtls.hardware.desc`} />
            </p>
          </StyledParagraph>

          <Spacing pixel="64" />

          <CardBox>
            {rtls.hardware.card.map((data, i) => (
              <>
                {i < 2 && <>
                  {i === 0 ?
                    <SmallCard w={layoutMaxWidth} img={data.img[layoutType]}>
                      <CardTextBox>
                        <LabelH3_818181 size={"xsmall"}>
                          <Trans i18nKey={`rtls.hardware.card.${i}.tag`} />
                        </LabelH3_818181>
                        <Spacing pixel="4" />
                        <StyledHeading size="small">
                          <p>
                            <Trans i18nKey={`rtls.hardware.card.${i}.title`} />
                          </p>
                        </StyledHeading>
                        {data.desc &&
                          <DescBox>
                            <ParagraphColor color="#818181" size="small">
                              <Trans i18nKey={`rtls.hardware.card.${i}.desc`} />
                            </ParagraphColor>
                          </DescBox>}
                      </CardTextBox>
                    </SmallCard>
                    :
                    <BigCard w={layoutMaxWidth} img={data.img[layoutType]}>
                      <CardTextBox>
                        <LabelH3_818181 size={"xsmall"}>
                          <Trans i18nKey={`rtls.hardware.card.${i}.tag`} />
                        </LabelH3_818181>
                        <Spacing pixel="4" />
                        <StyledHeading size="small">
                          <p>
                            <Trans i18nKey={`rtls.hardware.card.${i}.title`} />
                          </p>
                        </StyledHeading>
                        {data.desc &&
                          <DescBox>
                            <ParagraphColor color="#818181" size="small">
                              <Trans i18nKey={`rtls.hardware.card.${i}.desc`} />
                            </ParagraphColor>
                          </DescBox>}

                        {data.falldown &&
                          <>
                            <Falldown>
                              {data.falldown.map((data, j) => (
                                <>
                                  {j === index ?
                                    <FalldownBox>
                                      <ProgressBar h={"52"}>
                                        <ProgressBar2 />
                                      </ProgressBar>
                                      <FallTextBox onClick={() => handleText(j)}>
                                        <StyledHeading size="xxxxsmall"> <Trans i18nKey={`rtls.hardware.card.${i}.falldown.${j}.title`} /> </StyledHeading>
                                        <ParagraphColor color="#646464" size="xsmall"> <Trans i18nKey={`rtls.hardware.card.${i}.falldown.${j}.desc`} /> </ParagraphColor>
                                      </FallTextBox>
                                    </FalldownBox>
                                    :
                                    <FalldownBox>
                                      <ProgressBar h={"16"} />
                                      <FallTextBox onClick={() => handleText(j)}>
                                        <HeadingColor size="xxxxsmall" color={"#BDBDBD"}> <Trans i18nKey={`rtls.hardware.card.${i}.falldown.${j}.title`} /> </HeadingColor>
                                      </FallTextBox>
                                    </FalldownBox>
                                  }
                                </>
                              ))}
                            </Falldown>
                          </>}
                      </CardTextBox>
                    </BigCard>
                  }
                </>}
              </>
            ))}
          </CardBox>

          <CardGap />

          <CardBox>
            {rtls.hardware.card.map((data, i) => (
              <>
                {i > 1 && <>
                  {i === 3 ?
                    <SmallCard w={layoutMaxWidth} img={data.img[layoutType]}>
                      <CardTextBox>
                        <LabelH3_818181 size={"xsmall"}>
                          <Trans i18nKey={`rtls.hardware.card.${i}.tag`} />
                        </LabelH3_818181>
                        <Spacing pixel="4" />
                        <StyledHeading size="small">
                          <p>
                            <Trans i18nKey={`rtls.hardware.card.${i}.title`} />
                          </p>
                        </StyledHeading>
                        {data.desc &&
                          <DescBox>
                            <ParagraphColor color="#818181" size="small">
                              <Trans i18nKey={`rtls.hardware.card.${i}.desc`} />
                            </ParagraphColor>
                          </DescBox>}
                      </CardTextBox>
                    </SmallCard>
                    :
                    <BigCard w={layoutMaxWidth} img={data.img[layoutType]}>
                      <CardTextBox>
                        <LabelH3_818181 size={"xsmall"}>
                          <Trans i18nKey={`rtls.hardware.card.${i}.tag`} />
                        </LabelH3_818181>
                        <Spacing pixel="4" />
                        <StyledHeading size="small">
                          <p>
                            <Trans i18nKey={`rtls.hardware.card.${i}.title`} />
                          </p>
                        </StyledHeading>
                        {data.desc &&
                          <DescBox>
                            <ParagraphColor color="#818181" size="small">
                              <Trans i18nKey={`rtls.hardware.card.${i}.desc`} />
                            </ParagraphColor>
                          </DescBox>}
                      </CardTextBox>
                    </BigCard>
                  }
                </>}
              </>
            ))}
          </CardBox>

        </Card23Page>
        <BorderBottomLine />

      </StyledGridContainer>

    </>
  )
}

export default Hardware;