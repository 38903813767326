import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet: '',
  desc: 'リアルタイムな位置データと3Dビジュアライゼーションを統合して、都市内の個人や車両の動きを効果的に管理し、交通システムの安全性と効率を向上させる先進技術を提供します。',
  keywords: 'Digital Twin, Digital Twins, デジタルツイン, gis, デジタルシティ, RTLS, 位置追跡システム, 位置追跡技術, スポーツ, 製造, ロジスティクス, 医療, 病院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'デジタルツイン - 人々と車両のリアルタイムな動きをつなぐ :: ORBROブログ',
  ogUrl: 'https://orbro.io/jp/blog/digitaltwin-tracking',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'デジタルツイン - 人々と車両のリアルタイムな動きをつなぐ',
  date: '2023-12-26',
  tag1: '#DigitalTwin',
  tag2: '#RTLS',
  tag3: '#DigitalCity',
  tag4: '#VirtualReality',

  // <br/>
  text: [
    {
      head: 'デジタルツイン - 人々と車両のリアルタイムな動きをつなぐ',
      subHead: '',
      desc: 'ORBROは位置データと3Dビジュアライゼーションを統合して、都市内の個人および車両の動きを効果的に管理しています。これを実現するために、様々な技術とシステムが利用され、交通システムの安全性と効率性が向上しています。<br/><br/>RTLS（リアルタイムロケーションシステム）技術は人々の位置と動きをリアルタイムで追跡し、映像技術は車両の動きをモニタリングします。3Dビジュアライゼーションは人々と車両のダイナミックな動きを視覚的に表現し、ゾーン内の動きを効率的に管理します。<br/><br/>デジタルトランスフォーメーションダッシュボードは収集されたデータを集計して洞察に富んだ情報を提供し、スマートシティ統合は都市の運用の自動化と効率性を向上させます。これにより都市内の交通フローとインフラの使用を最適化し、スマートシティの実現を支援します。',
      dot: ''
      
    },
    {
      youtube: 'https://www.youtube.com/embed/g0Kv05InEtI?si=7EnfmOzAOOi-3ds2',
      head: '',
      subHead: 'RTLS技術を使用した人の情報収集',
      desc: 'ORBROは高精度なリアルタイム位置追跡システム（RTLS）を使用して、人々の位置と動きを追跡します。これは特に大規模な公共の場や緊急事態で人々の安全とセキュリティを確保するために重要です。',
      dot: ''
    },
    {
      head: '',
      subHead: 'ビデオ技術を使用した車両の情報収集',
      desc: 'さまざまなビデオキャプチャ技術を活用して、都市内の車両の動きをリアルタイムでモニタリングおよび分析します。これは交通の流れを最適化し、交通事故を予防に寄与します。',
      dot: ''
    },
    {
      head: '',
      subHead: '3Dでリアルタイム表現',
      desc: '3Dモデリングを使用して人々と車両のダイナミックな動きを視覚化し、これにより都市計画、交通管理、安全強化などに必要なデータを提供します。',
    },
    {
      head: '',
      subHead: 'ゾーン指定',
      desc: '特定のゾーンを設定してそのエリア内の人々と車両の動きを効果的に管理および分析します。これは公共の安全、イベント管理、交通制御に役立ちます。',
    },
    {
      head: '',
      subHead: '効果的なダッシュボード',
      desc: '人々と車両の動きのデータを集計して、交通管理、都市計画、公共の安全などに必要な洞察に富むダッシュボードを提供します。これにより意思決定者は都市の運営と管理をより効果的に行えるようにサポートされます。',
    },
    {
      head: '',
      subHead: 'スマートシティ統合',
      desc: '位置データと分析をスマートシティシステムに統合して都市運営の自動化と効率を向上させます。これによりエネルギー管理、交通システム、公共サービスの最適化に貢献します。',
    },

  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を労働者や機器に取り付け、リアルタイムの位置追跡を支援する製品です。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/jp/enterprise/construction/asset'
      }, 
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/jp/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      }
    ]


  }

}
