import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {
  helmet: '| 物流行业从业人员需要了解的最新技术趋势',
  desc: '従来の受動的な在庫管理方法から離れ、商品や機器の位置と状態をリアルタイムで追跡することが可能になりました。これにより、在庫不足や過剰在庫などの問題を防ぎ、物流コストを削減することができます。',
  keywords: '物流库存、物流库存管理、库存管理、资产管理、物流中心库存管理、物流中心、大型物流中心、智能物流、智能物流中心、物流设施、库存跟踪、RTLS、实时位置系统、ORBRO、ConTech',

  ogTitle: '',
  ogDesc: '',
  ogSite: '物流行业从业人员需要了解的最新技术趋势 :: ORBRO博客',
  ogUrl: 'https://orbro.io/cn/blog/logisticsTech',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4PVu4kuy9M18H7FmFyW0Q0eESSyhQnDGg14oHiyiErzgaj9C6c04JTCmvEdjNFy1RXc-dFULyWRt2kEo4Cc08fuynbaorsmbVA1WZzOjFxamTouK1BSdqmJMWpihlk4UT2DwWY82EunIjppF5ShLp_E_SC2kVFAxBNtgKGuiHxsQedwiLBHpCzoo9wHtnqwDT_VLhRXu6kCMciziqklxTVzgLUnGfJx4fwSZCUeP_97fRfmoRUa74mRFh7J3r_WHTjKEM6lA3ewY1aFfygHCHH9YILBsyiOObeQqrP-rwrQsFFN9tegAJUWXqMBIdU0iZrmP8BE5aKgWUlkcv4BGfmKVIsOLypMPvOlrwLGc69gq-Ry-75Kq70RLYMXTIGNBeFCX1OoWSQXyi8xtlMbio4P-R3SYRfST4i-NaC0uTHKYNFw4X2pi32DpBC6ABiJ36qYntNOyIjav_Gxsj1J1hX7f11VXEpXn99emstdDgDQWVe0GJFpuy3S1BLOUnHHYb5Yp_2rUPdF5zaDip7wpsdirHI_gwcVPrVnx5OVX7sN5sH0v5olOvB6lhlq_3tH8L4f_Ih_I--vbGOsYnN246AvMx8BGjB3rLXnW38UK3Kv0JxhnL3evbCmOTKHnYQnAPMDby0LP_J1vGGTSKJB4saPBtfth2jWZCp_D33p2wPA9m3rIJ1GioOjoX8aVnl2McRmhcN0ee-3khHAkTH9Sw5Mselb8HbNlXC-5exUaPJUbLnSf3wU6xV6KyRieN_TsqtfixN6mgR2fSkpi2AA_Pn73zqkACZWSox-925fxgQtg_hoEFlaR4ctPdpWcXQ9V9EN1bMwxcXtV8NT2WDiF7MdEVpabwIUTZHPoFOPvkghuiqSMTiwQzCFgFwMqRR08jXN8Hbo4keOhSlyUhbuIcC0_vdkNI_yh_kUYLpdIlBOwk-amXrN-hdOwqTPkyuRmSAaKUPWgVhktIsP_WOlCXBncp4asz71pEvRywDGh_h9ou449w69BkNSjl7l-ODWpERmlmSTxjEkb3GGqjvn7S-DjtWWD1w_Xac7yZmMcNlenRvfma_bkN_2S4XW7zKnTl7H4gcmDtlt2igFE2Rdcg18vvfDs6vZJIAhmUZ_wo_-ki7qRtKsV-NZXEhxNCPtPbyxuVAdzRL7HWzn8ZpF3eK-2k87_zuS9aU9ofyaMoWiEXplANKhTzbhPz0Do_-QxjH9qHlnJot70a7mOr2kGcV9pRM_frc9CgYJC11tSxs6Mwoi3BKJkqZ7PlpCzTwyI9Fb0sBI3v-TvJO47RzrytQVLmvZdtB_divnOJkV6CNET7k5gTn2ICf7eQb_8YapYc_2eH8DW1YPqCN09n8C5uGq8lkpYedIdlXTsFHrVqUEluhRuaLZSegaV1oMdbzvS90L5WrpSKP-YPBc4D_OTgGXjA7Jha06Ccb_vGhacNAfFznQ7SJXS-Pp2ufPn2ymczEn4LHRbDw-f7upsbuw9uLEMWNSvzZsulGa4DjUZLuP2oEHwtbNu_qq8M1bYNyUzAAD4z9_lTWLQrG6ErWZVmxc1R-Qq-_71PIB1v_zizOFA2EeBYuNCXK_B0J4Hw=w2052-h1199',
  ogType: '网站',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '物流行业从业人员需要了解的最新技术趋势',
  date: '2023-09-13',
  tag1: '#物流中心',
  tag2: '#全球物流',
  tag3: '#供应链管理',
  tag4: '#库存管理',

  text: [
    {
      head: '',
      subHead: '',
      desc: '物流行业在优化时间和资源、提高客户服务方面发挥着关键作用。然而，在现代物流行业中保持竞争力需要积极采用最新技术。一如既往地，新的创新总是伴随着新技术的出现。近年来备受关注的技术之一是实时定位系统（RTLS）技术。RTLS技术在多个行业中，特别是物流领域，正在带来革命性的变化。我们将探讨这项技术如何革新物流行业并促进其增长。',
      dot: ''
    },
    {
      head: 'RTLS技术的概念',
      subHead: '',
      desc: 'RTLS代表实时定位系统，是指结合了无线通信、传感器、数据分析等多种技术，实时跟踪和管理物体、设备或人员的位置的系统。RTLS技术利用射频识别（RFID）、全球定位系统（GPS）、超宽带（UWB）等多种技术来收集位置信息，并将该信息发送到中央系统，以便实时监控。那么，这项技术如何适用于物流设施，并带来了哪些好处呢？',
      dot: ''
    },
    {
      head: '物流行业中的RTLS应用案例',
      subHead: '1. 库存管理和维护',
      desc: 'RTLS技术为物流行业的库存管理带来了革命性的变化。它摆脱了传统的手动库存管理方式，使商品和设备的位置和状态能够实时跟踪。这有助于防止库存不足或过剩等问题，降低物流成本。',
      dot: ''
    },
    {
      head: '',
      subHead: '2. 自动化的工作流程',
      desc: '利用RTLS，可以自动化物流工作流程。例如，在汽车组装线上，可以跟踪零件的位置和移动，并使机器人能够自动取零件等工作成为可能。这提高了工作效率，减少了错误。',
      dot: ''
    },
    {
      head: '',
      subHead: '3. 运输和交付管理',
      desc: 'RTLS技术在运输和交付过程中也发挥着重要作用。通过实时跟踪商品的位置，可以优化运输路线并更轻松地管理交付时间表。此外，监控商品状态以防止损坏，可以提供更快速和准确的交付服务。',
      dot: ''
    },
    {
      head: '',
      subHead: '4. 安全管理',
      desc: '实时跟踪不仅限于资产，还包括工作人员和车辆的跟踪。如果存在潜在风险，可以实时发送警报并采取措施。',
      dot: ''
    },
    {
      head: 'RTLS的优势和价值',
      subHead: '1. 实时监控',
      desc: '通过RTLS技术，可以实时监控商品、设备或工作人员的位置和状态。这使得在出现问题时能够迅速响应并优化流程。',
      dot: ''
    },
    {
      head: '',
      subHead: '2. 精确的数据收集',
      desc: 'RTLS收集准确的位置数据，因此在库存管理、运输、交付等工作中可以最小化错误，提高物流效率。',
      dot: ''
    },
    {
      head: '',
      subHead: '3. 成本节约',
      desc: '利用RTLS技术，可以有效管理人员和资源，并降低与库存管理和运输过程相关的成本。',
      dot: ''
    },
    {
      head: 'RTLS的未来',
      subHead: '',
      desc: 'RTLS技术将继续发展，并预计将为物流行业带来创新。通过与机器学习和人工智能的结合，将提供更精密的预测和优化功能，同时在安全性和个人信息保护方面也将得到改进。物流行业从业人员应密切关注这些技术趋势，积极采用以保持竞争力并为未来做好准备。',
      dot: ''
    },
    {
      head: '总结',
      subHead: '',
      desc: 'RTLS技术为物流行业从业人员提供了创新的机会，可优化物流流程并降低成本。由于预计这项技术将在物流行业中发挥重要作用，因此积极采用并实施RTLS技术至关重要。让我们积极利用RTLS技术，为物流行业的增长和发展作出贡献。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: '通过安装UWB设备，支持实时位置追踪的产品，可附在工作人员或设备上。',
        img: productImg.product.assetTracking.key1
      },
      {

      }
    ],
    solution: [
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      },
      {
        img: logisticsSafety.thumb,
        data: 'logisticsSafety',
        link: '/cn/enterprise/logistics/safety'
      },
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/cn/enterprise/manufacturing/asset'
      }
    ]
  }
}
