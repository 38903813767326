import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet: '',
  desc: '公共交通のリアルタイム位置追跡を通じて、乗客のエクスペリエンスを向上させ、運行効率を向上させます。ORBROはバスと地下鉄のリアルタイム位置、停留所情報の表現、交通フローの分析と最適化、安全管理と監視、アプリとサービスの統合などを提供します。',
  keywords: 'Digital Twin, Digital Twins, デジタルツイン, gis, デジタルシティ, RTLS, 位置追跡システム, 位置追跡技術, スポーツ, 製造, ロジスティクス, 医療, 病院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'デジタルツイン - バスおよび地下鉄情報の連携 :: ORBROブログ',
  ogUrl: 'https://orbro.io/jp/blog/digitaltwin-traffic',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'デジタルツイン - バスおよび地下鉄情報の連携',
  date: '2023-12-26',
  tag1: '#デジタルツイン',
  tag2: '#TrafficSituation',
  tag3: '#DigitalCity',
  tag4: '#VirtualReality',

  // <br/>
  text: [
    {
      head: 'デジタルツイン - バスおよび地下鉄情報の連携',
      subHead: '',
      desc: 'ORBROは公共交通のリアルタイムな位置追跡を通じて乗客の体験を向上させ、運行効率を向上させるサービスを提供しています。このため、バスや地下鉄のリアルタイムな位置情報提供、停留所情報の表示、交通フローの分析と最適化、安全管理とモニタリング、アプリとサービスの統合を提供しています。<br/><br/>ORBROのGPSおよびトラッキング技術はバスのリアルタイムな位置情報を正確に提供し、乗客が公共交通運行情報をリアルタイムで把握できるようにします。地下鉄のリアルタイムな位置モニタリングは、乗客に正確な運行情報を提供し、公共交通利用の信頼性を向上させます。<br/><br/>停留所情報の提供は公共交通利用の利便性を向上させ、交通フローの分析と最適化は交通渋滞の軽減と乗客の待ち時間の短縮に寄与します。また、安全管理とモニタリングは乗客の安全を重視し、公共交通システムのスムーズな運行を支援します。<br/><br/>最終的にORBROは公共交通利用者の体験を向上させ、同時に公共交通システムの効率を向上させ、都市の交通環境を改善に貢献します。',
      dot: ''
      
    },
    {
      youtube: 'https://www.youtube.com/embed/tK8f-aMRh-g?si=Q6CVGXUz5ZTjLv-4',
      head: '',
      subHead: 'バスのリアルタイム位置追跡',
      desc: 'ORBROはGPSおよび追跡技術を使用してバスのリアルタイム位置を提供します。これにより、乗客は正確な到着予測が可能になり、交通管理者は運行効率を向上させるのに役立ちます。',
      dot: ''
    },
    {
      head: '',
      subHead: '地下鉄のリアルタイム位置モニタリング',
      desc: '地下鉄システム内の車両位置をリアルタイムでモニタリングおよび表示し、乗客に正確な運行情報を提供します。これにより運行効率が向上し、緊急時の迅速な対応が可能になります。',
      dot: ''
    },
    {
      head: '',
      subHead: '停留所情報の提供',
      desc: '各停留所および駅に関する詳細な情報がデジタルマップに表示され、乗客に停留所の位置、時刻表、サービス情報を提供することで、公共交通の利用の利便性が向上します。',
    },
    {
      head: '',
      subHead: '交通フローの分析と最適化',
      desc: 'リアルタイム位置データを活用して交通フローを分析および最適化することで、交通渋滞の軽減と乗客の待ち時間の短縮に寄与します。',
    },
    {
      head: '',
      subHead: '安全管理と監視',
      desc: '公共交通内部の安全管理および監視システムを強化して、乗客の安全を確保し、運行中のリスクに迅速に対応します。',
    },
    {
      head: '',
      subHead: 'アプリとサービスの統合',
      desc: '公共交通情報をモバイルアプリおよびさまざまなデジタルサービスと統合して、乗客に情報へのより良いアクセスと利便性を提供します。',
    },

  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を労働者または機器に取り付け、位置追跡をリアルタイムでサポートする製品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/jp/enterprise/construction/asset'
      }, 
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/jp/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      }
    ]


  }

}
