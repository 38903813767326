import { StyledDisplay, StyledLabel, StyledParagraph } from '@components/Text';
import styled from '@emotion/styled';




export const StyledTitle = styled(StyledDisplay)`
  text-align : center;
`;

export const StyledDesc = styled(StyledParagraph)`
  margin-top : 8px;
  text-align : center;
  color : #646464;
  
  margin-bottom : 48px;
`;

export const StyledLink = styled(StyledParagraph)<{ size?: string, inline?: boolean }>`
  color: #2A70F0;
  text-decoration: underline;
  ${props => props.inline && 'display: inline;'}

`;

export const StyledProductLayer = styled.div`

display : flex;
flex-direction : row; 
width : 100%;
  gap : 32px;

` 


export const StyledProducImgBox = styled.div`
  position: relative;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content : center;
  height : 280px;
  
  width : 100px;
  flex : 1;

`

export const StyledProductImage = styled.img`

max-height: 100%;
max-width: 100%;
object-fit: contain;
  height : 240px;
  aspect-ratio : auto 2/1;
  padding : 0 16px;
`;

export const StyledProductButton = styled.button`

  border : none;
  cursor : pointer;


  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin : auto;
  gap: 4px;
  align-self: flex-start;

  background: #2A70F0;
  border-radius: 8px;
  height : 40px;
  max-width : 300px;

  color : #FFFFFF;
  font-family: 'Pretendard';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  
  text-align: center;

`




export const StyledSchemeLine = styled.div`

  width: 100%;
  display: flex;
  gap: 32px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

`


export const StyledSchemeBox = styled.div`
  flex: 1;
  padding: 24px 16px;
  width: 100%;
  border-radius : 16px;
  
`


export const StyledSchemeDesc = styled(StyledLabel)`
  color : #646464;
  text-align : center;

`
