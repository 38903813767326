import { StyledGridContainer } from "@components/Grid/Grid.style";
import { ParagraphColor, Spacing, SpacingDTM } from "@pages/CommonStyle/common.style";
import { DivAllCenter, DivGrid } from "@pages/CommonStyle/commonDiv.style";
import digitaltwin from "@src/lang/ko/digitaltwin/digitaltwin";
import React from "react";
import { Trans } from "react-i18next";
import { SumamryBox, SummaryImg, SummaryTextBox } from "./Summary.style";
import { DisplayH2color } from "@components/Text/HeadingTagDisplay.style";
import { LabelH3color } from "@components/Text/Label/Label.style";


const Summary = () => {
  return (
    <>
      <StyledGridContainer>
        <DivAllCenter>
          <DisplayH2color color="#FFF" size="small">
            <Trans i18nKey={`digitaltwin.summary.title`} />
          </DisplayH2color>
          <Spacing pixel="8" />
          <ParagraphColor color="#FFF" size="large">
            <p>
              <Trans i18nKey={`digitaltwin.summary.desc`}/>
            </p>
          </ParagraphColor>
          <Spacing pixel="32" />
        </DivAllCenter>

        <DivGrid cnt={2} gap={32}>
          {digitaltwin.summary.card.map((data, i) => (
            <>
              <SumamryBox>
                <SummaryImg src={data.img} alt="ORBRO's digital twin function icon"/>
                <SummaryTextBox>
                  <LabelH3color size="large" color={"#FFF"}>
                    <Trans i18nKey={`digitaltwin.summary.card.${i}.title`} />
                  </LabelH3color>
                  <ParagraphColor size="medium" color="#818181">
                    <p>
                      <Trans i18nKey={`digitaltwin.summary.card.${i}.desc`} />
                    </p>
                  </ParagraphColor>
                </SummaryTextBox>
              </SumamryBox>
            </>
          ))}
        </DivGrid>
        <SpacingDTM d={"256"} t={"128"} m={"128"} />

      </StyledGridContainer >
    </>
  )
}

export default Summary;