import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

export default {


    helmet: '| UWB RTLS, 미래 산업의 시작',
    desc: 'UWB rtls 기술은 산업 현장을 디지털화 하여 사람과 사물을 추적하고 관리할 수 있도록 합니다. 높은 정확도와 실시간으로 반영되는 데이터로 비즈니스를 성장시킬 수 있습니다.',
    keywords: 'uwb, rtls, uwb rtls, 스마트 물류, 스마트 건설, 스마트 제조, 스마트 공정, 스마트 공장, 디지털 전환, 디지털 트윈, 실시간 위치 추적, rtls, orbro, 오브로, 콩테크',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'UWB RTLS, 미래 산업의 시작 :: 오브로 블로그',
    ogUrl: 'https://orbro.io/blog/uwbRtls',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDyMvH9u0-21RYnyyBVVc9guqVzCzv-ggtVgPCRQTLkEU33FS2rG9s1BImAzHdqx3slOcbgGEGcFnTwDAmmi0nBR-jQfAdavlymzwvoyWDbTWljNw2DPoaW_qaO4ciYcfvrO7YnaeT_zAYoHLLULjh7MSKToInBek_3WIXpnTfHBz83o4ryfNamREq7rU9e4ET1GsYzLjQ-33tSLxqcb1f1RUYR4qlgVm7ntqiwd4jyEZlj-33EJPgyvj3R-QwTvkFl1Ha2Z1oL3YBwdk33ugYt5wzdW-3IJa9T1gM6FWZbPZdnGWL0xDRYYzh0OTD7sckAN2dXqBwWWAqEjdyTKN6AfoQibnezcJUsWa12l8mton2PeSWa45J0NarK5Vrkbc_qrQzoC9iJ209QlewQrjmeCzUN2E9gIK8_O_eQ_seXYgBf-nQWy5BD5fX2goEJBEYQECwdmfhmRPWwkbQC8rXsKBhnXCZ9t3t65Dpn7Voj_tUI2w0wMMlJcsEpEpUdsd4hx6FrX5t4pHmpaJzEB5DxjLofbzSQXqEQcEQvkocvpBQ6HI9XVC5MFlwkonW-DUk_EwFlNGoCZYnZFFQjU-pK07aYDuZA4uvClTrr2OrxvC6iX2rVhJ01oGwN071DybCKXVsScEP4pQyzVkgMsbrOwoLXWRnDA5bl7_-nz4w1_PYbHs7kCJu14Zw1jaBhCb3vgIyFJPMHGaFGLUdtv00ej-qe1NVeGs5SGo8qkMOrkmAVxHx7cqR1ZqqeTvVy6xGAFPhJGvdZRlOV6T1M9HJ3a25z1couxxGM4TnjUSFrKXfhe--PNrqZxiX-4Yia8psajdHdqgD4I4KT6ryESC2umjrIiEI7oQyok08ry7UKuswV2OlxgJXmJYFOr6hBvriet1T1K8N7sko5tDVIDEyoVyGkGasOAifm6XgUZO3cbNLmvYib5LoKMKDFyldZpUxLWQ2yiI0ux0k-c303GbZ8h5nw-OP8JQekoR5do0QHpENpLqonOuBwnQZiBGmH2BGHHTYXGDNIl5s3QYqmWKNRQUMMvAau7nPXgQvmvJs3xRpcmPKFy1x149cg4cTWVvIL99qqF8lfTu8Dr0r2ycGSIm0NDz6WsrMA6cUDNEWZLdCFxEZMl6T7U2IFXBW0CLDmJ9UONldp_cQHYA-aIojbGabali2Q3YgwAKBlxyIytx5azfiIrUy3w7YzUE8BGiWS9faNVbZqk7TfUxA0mevS00orIB7fBajjFpHnkRPXLsXlm0d6pZTBHMzyu1s77gT-82xJSJVvvkHA3SwtYpap0571i6g8VCzO_OkwDlxeQkY9P_BbcVYzEUxoeIrASHDZuuhT2rkJ1qOY0UbdZlGS5KOOO6R10Wi0z8p-SXJQRT9kOR1N2mhdGk_Z_rag_ptC5lHxc7dYZaT2NCqxEVI-GZa3fHcvyge6Gu0OzWP0o2N8x_8XpdTaJuMTcUd-vUlQdg-o1YKkmcoqzVjfZVLzqDF7tsS3KS7hO8cTL23IfERiTLhbOxtCkJTdtHTXhXVrgakFVdWBWUlxMxDP_Yyk12v-Dozi2pwBSbMdRIEJ1xvD4akO3=w2000-h1596',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'UWB RTLS, 미래 산업의 시작',
    date: '2023-11-03',
    tag1: '#uwb',
    tag2: '#rtls',
    tag3: '#디지털트윈',
    tag4: '#디지털전환',

    // <br/>
    text: [
        {
            head: '',
            subHead: '',
            desc: '최근 몇 년 동안 UWB (Ultra-Wideband) RTLS (Real-Time Location System) 기술은 다양한 산업 분야에서 빠르게 주목받고 있습니다. 이 기술은 위치 추적 및 위치 기반 서비스 분야에서 혁신적인 변화를 가져오고 있으며, 미래의 솔루션으로 자리잡을 것으로 기대됩니다. 이 글에서는 UWB RTLS 기술이 무엇인지, 그 활용 분야와 잠재적인 장점, 그리고 미래에 어떻게 더 발전할 수 있는지에 대해 알아보겠습니다.',
            dot: ''
        },
        {
            head: 'UWB RTLS란?',
            subHead: '',
            desc: 'UWB RTLS는 실시간 위치 추적 시스템으로, UWB 기술을 활용하여 정밀한 위치 데이터를 제공합니다. UWB는 초고주파 대역폭을 사용하여 데이터를 전송하며, 이를 통해 매우 정확한 위치 정보를 얻을 수 있습니다. 이 기술은 GPS나 Wi-Fi와 같은 기존 위치 추적 시스템과 비교했을 때 훨씬 높은 정확도와 실시간성을 제공합니다.',
            dot: ''
        },
        {
            video : video,
            link : 'https://orbro.io/enterprise/manufacturing/process',
            head: '활용 분야',
            subHead: '',
            desc: '<strong>건설 및 건축업 : </strong>건설 현장에서 건물, 장비 및 재료의 위치를 추적하여 작업 일정을 최적화하고 안전을 강화할 수 있습니다. 또한 건물 내의 실내 위치 정확도를 통해 스마트 빌딩 시스템을 구현할 수 있습니다.<br/><br/><strong>유통 및 소매업 : </strong>유통 및 소매업체는 제품의 위치와 재고를 실시간으로 파악하여 고객 서비스와 재고 관리를 향상시킬 수 있습니다. 또한 고객의 구매 행동을 분석하여 상품 배치를 최적화할 수 있습니다.<br/><br/><strong>교통 및 국토 관리 : </strong>도로 및 철도 네트워크에서 차량, 열차, 및 기타 교통 수단의 위치를 추적하여 교통 혼잡을 관리하고 사고를 예방할 수 있습니다. 국토 관리 부서는 지형 및 자원의 위치 데이터를 관리하여 효율적인 자원 할당을 실현할 수 있습니다.<br/><br/><strong>보안 및 안전 관리 : </strong>중요한 시설, 이벤트, 또는 대규모 모임에서 UWB RTLS를 활용하여 사람과 물체의 위치를 추적하여 보안 및 안전을 강화할 수 있습니다. 이것은 비상 상황에서 빠른 대응을 가능하게 합니다.<br/><br/><strong>스포츠 및 엔터테인먼트 : </strong>스포츠 경기장이나 공연장에서 관중의 위치를 추적하여 행사 조직을 최적화하고 안내 서비스를 제공할 수 있습니다. 또한 미디어 제작을 위한 카메라 및 조명 장비의 위치를 정확히 파악할 수 있습니다.',
            dot: ''
        },
        {
            head: '장점',
            subHead: '',
            desc: '<strong>높은 정확성 : </strong>UWB 기술은 다른 무선 기술보다 훨씬 높은 정확성을 제공합니다. 센서 간의 거리 측정을 기반으로 하기 때문에 실내 및 야외 환경에서도 정밀한 위치 데이터를 얻을 수 있습니다.<br/><br/><strong>낮은 레이턴시 : </strong>UWB는 빠른 응답 시간을 제공하여 실시간 위치 정보를 신속하게 얻을 수 있습니다. 이는 시스템의 신속한 대응과 응급 상황에서의 유용성을 높입니다.<br/><br/><strong>다중 사용자 지원 : </strong>UWB는 다수의 사용자 또는 물체를 동시에 추적할 수 있으며, 이는 대규모 이벤트나 산업 환경에서 매우 중요합니다.<br/><br/><strong>안전 및 보안 : </strong>UWB는 다른 무선 통신 기술과의 간섭을 최소화하고 높은 보안 수준을 제공합니다. 이는 민감한 데이터 및 시스템의 안전을 보장합니다.<br/><br/><strong>실내 및 야외 활용 : </strong>UWB RTLS는 실내와 야외 환경에서 모두 사용할 수 있으며, 이는 다양한 산업 분야에서의 범용성을 의미합니다.<br/><br/><strong>저전력 운영 : </strong>UWB 기술은 저전력 무선 통신을 지원하므로 배터리 수명을 연장하고 에너지 효율성을 높일 수 있습니다.',
            dot: ''
        },
        {
            head: '미래 전망',
            subHead: '',
            desc: 'UWB RTLS 기술은 미래에 더욱 중요한 역할을 할 것으로 전망됩니다. 미래에는 더 많은 기기와 시스템이 UWB를 활용하여 상호 연결되고, 스마트 도시, 스마트 공장, 자율 주행 차량, 의료 분야, 및 다른 다양한 분야에서 UWB RTLS 기술이 적용될 것입니다. 이를 통해 생산성 향상, 안전성 강화, 환경 개선, 및 편의성 증진이 이루어질 것으로 기대됩니다.<br/><br/>총론적으로, UWB RTLS는 미래의 솔루션으로 자리잡을 기술 중 하나로 주목받고 있습니다. 그 안정성, 정확성, 그리고 다양한 산업 분야에서의 활용 가능성을 고려할 때, 이 기술은 우리의 일상 생활과 다양한 산업에 긍정적인 영향을 미칠 것으로 기대됩니다. 이 기술의 발전과 확산을 통해 미래는 더욱 스마트하고 효율적인 세상이 될 것입니다.',
            dot: ''
        },

    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/enterprise/construction/safety'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/enterprise/manufacturing/asset'
            }
        ]

    }


}