import { StyledLabel } from "@components/Text";
import React, { useCallback, useEffect, useState } from "react";
import {
  StyledItemBox, StyledNoteDetail, StyledItemDetail,
  StyledItem, StyledItemIcon,
  StyledSpecBox, StyledItemTextBox,
  StyledItemTextPriceBox,
  StyledItemSoldOut, StyledNoteBox, StyledNoteIcon, StyledNoteText, StyledRequestBtnWrapper,
  StyledAddImg, StyledAddBox, StyledPriceText, StyledAddPrice, StyledAddTextBox,
  StyledNoteFullText, StyledCalcBox, StyledPrice, StyledCountBtnBox,
  StyledCountDisplay, StyledCount, StyledNewItem,
  StyledRequestBtn, StyledMainProductBox, StyledMainProductIcon,
  PurchaseBtn, PurchaseIcon, PurchaseBox, StyledPurchseCount,
  StyledBtnBoxLeftSide, StyledOptionsTextBox,
  StyledOptionsBox, StyledCheckBox, CountBtn, AccessoryTitle,
} from "./PurchaseDetail.style";

import { Trans } from "react-i18next"
import product from "@src/lang/ko/main/product/productImg";
import { useLocation } from "react-router-dom";
import Modal from "./Modal/Modal";
import { IconCheckBoxOff, IconCheckBoxOn, IconPlus } from "@assets/images/icon";
import { IconMinus } from "@assets/images/icon";
import i18n from "@pages/i18n";
import { HeadingColor, ImgWH, LabelColor, Spacing, SpacingL, SpacingR } from "@pages/CommonStyle/common.style";
import { DivBorderRaiuds } from "@pages/CommonStyle/commonDiv.style";



interface FaqItemProps {
  productName: string,
  title: string,
  serial: string,
  price: string,

  note: { text: string, icon: string }[];
  parts: { title: string, price: string, img: string }[];
  options: { select: string, imgIndex: number, price: string }[];
  path: string,

  mainProduct: {
    img: string, title: string, price: string, min: number, max: number
  }[];
  mark: string,
  isOpen: boolean,
  handleClick: () => void;
  handleIndex: (index: number) => void;
}

const PurchaseDetail = (props: FaqItemProps): React.ReactElement => {

  const location = useLocation();
  const isEnglishPage = location.pathname.startsWith('/en');
  const isJapanPage = location.pathname.startsWith('/jp');
  const isChinaPage = location.pathname.startsWith('/cn');


  useEffect(() => {

    if (isEnglishPage) {
      i18n.changeLanguage('en').then(() => {
      });
    } else if (isJapanPage) {
      i18n.changeLanguage('jp').then(() => {

      });
    } else if (isChinaPage) {
      i18n.changeLanguage('cn').then(() => {

      });
    } else {
      i18n.changeLanguage('ko').then(() => {
      });
    }
  }, [location.pathname]);


  const { productName, title, serial, note, parts, path,
    options, mainProduct, mark, isOpen, handleClick, handleIndex } = props;
  const requestLineUp = [
    "productPurchase.purchase.asset.assetTracking.title", //자산 추적
    "productPurchase.purchase.worker.workerTracking.title", //노동자 추적
    "productPurchase.purchase.auto.apart.title", //아파트 패키지
  ]
  //가격 뒤에 월이 붙는 경우
  const subscribeLineUp = [
    "productPurchase.purchase.auto.cloud.title",

  ]
  const [price, setPrice] = useState<string>(props.price); // 초기값을 options[index].price로 설정
  const [indexPrice, setIndexPrice] = useState<boolean>(false);

  const getItemPrice = (itemPrice: string) => {
    if (indexPrice) return price;
    else return itemPrice;
  }

  const lang = i18n.language;
  let currency;
  let exchange = 1;
  if (lang === 'en') {
    exchange = 1250;
    currency = '$';
  } else if (lang === 'jp') {
    exchange = 900;
    currency = '￥';
  } else if (lang === 'cn') {
    exchange = 175;
    currency = '￥';
  } else {
    exchange = 1;
    currency = '₩';
  }

  // 컴마를 제거하고 문자열을 숫자로 변환
  const parsePrice = (price) => {
    return parseFloat(price.replace(/,/g, ''));
  };

  // 숫자를 문자열로 변환하고 컴마를 추가
  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };


  const initialMainProductCounts = mainProduct.map((item) => item.min);
  const initialPartsCounts = parts.map(() => 1);

  // 상태값 설정
  const [mainProductCounts, setMainProductCounts] = useState<number[]>(initialMainProductCounts);
  const [partsCounts, setPartsCounts] = useState(initialPartsCounts);
  const [isChecked, setIsChecked] = useState(Array(parts.length).fill(false));
  const [totalPrice, setTotalPrice] = useState(parseFloat(price));

  const [isMin, setMin] = useState<boolean>(false);

  const [maxValues, setMaxValues] = useState<boolean[]>(Array(mainProduct.length).fill(false));
  const [minValues, setMinValues] = useState<boolean[]>(Array(mainProduct.length).fill(false));


  // 인덱스에 따라 숫자 증가 함수 (메인 제품)
  const increaseMainProductCount = (index) => {
    setMainProductCounts((prevCounts) => {
      const newCounts = [...prevCounts];
      let max = mainProduct[index].max;

      //번들 제품의 경우 : 최소 수량이 1개가 아닌경우,
      //위쪽 제품(index-1)의 갯수 x 최소값
      if (max < 999) {
        const prevProductCount = newCounts[index - 1];
        max = prevProductCount * max;
      }
      if (newCounts[index] < max) {
        newCounts[index] = newCounts[index] + 1;
      }

      // index 번째부터 mainProduct의 length까지 반복
      for (let i = index + 1; i < mainProduct.length; i++) {
        const min = mainProduct[i].min;
        const currentIndex = newCounts[index];
        if (mainProduct[i].min > 1 && newCounts[i] < currentIndex * min) {
          newCounts[i] = currentIndex * min;
        }
      }
      const updatedCounts = calcMinMax(newCounts); // calcMinMax 함수 호출

      return updatedCounts;
    });
  };

  // 인덱스에 따라 숫자 감소 함수 (메인 제품)
  const decreaseMainProductCount = (index) => {
    setMainProductCounts((prevCounts) => {
      const newCounts = [...prevCounts];
      let min = mainProduct[index].min;

      //번들 제품의 경우 : 최소 수량이 1개가 아닌경우,
      //위쪽 제품(index-1)의 갯수 x 최소값
      if (min > 1) {
        const prevProductCount = newCounts[index - 1];
        min = prevProductCount * min;
      }
      if (newCounts[index] > min) {
        newCounts[index] = newCounts[index] - 1;
        setMin(false);
      } else {
        setMin(false);

      }

      // index 번째부터 mainProduct의 length까지 반복
      for (let i = index + 1; i < mainProduct.length; i++) {
        const max = mainProduct[i].max;
        const currentIndex = newCounts[index];
        if (mainProduct[i].max < 999 && newCounts[i] > currentIndex * max) {
          newCounts[i] = currentIndex * max;
        }
      }


      const updatedCounts = calcMinMax(newCounts); // calcMinMax 함수 호출

      return updatedCounts;
    });
  };


  const calcMinMax = (counts) => {
    const updatedMaxValues = [...maxValues];
    const updatedMinValues = [...minValues];

    const newCount = [...counts];
    const data = newCount[0];

    for (let i = 1; i < mainProduct.length; i++) {
      const max = mainProduct[i].max;
      const min = mainProduct[i].min;

      if (newCount[i] === max * data) {
        updatedMaxValues[i] = true;
      } else {
        updatedMaxValues[i] = false;
      }
      if (newCount[i] === min * data) {
        updatedMinValues[i] = true;
      } else {
        updatedMinValues[i] = false;
      }

      // 메인 핵심 제품 수량
      if (newCount[0] === 1) {
        updatedMinValues[0] = true;
      } else {
        updatedMinValues[0] = false;
      }
    }

    setMaxValues(updatedMaxValues);
    setMinValues(updatedMinValues);

    return newCount;
  };

  // 메인 제품 최소값 최대값 갱신
  useEffect(() => {
    calcMinMax(mainProductCounts); // mainProductCounts가 변경되면 calcMinMax 함수 호출
  }, [mainProductCounts]);


  // 인덱스에 따라 숫자 증가 함수 (추가 구매 제품)
  const increasePartsCount = (index) => {
    setPartsCounts((prevCounts) => {
      const newCounts = [...prevCounts];
      newCounts[index] = newCounts[index] + 1;
      return newCounts;
    });
  };

  // 인덱스에 따라 숫자 감소 함수 (추가 구매 제품)
  const decreasePartsCount = (index) => {
    setPartsCounts((prevCounts) => {
      const newCounts = [...prevCounts];
      if (newCounts[index] > 1) {
        newCounts[index] = newCounts[index] - 1;
      }
      return newCounts;
    });
  };



  const [selectedOption, setSelectedOption] = useState(0);
  const handleOptionClick = (index, imgIndex) => {
    setSelectedOption(index);
    handleIndex(imgIndex);
    if (imgIndex === 10) {
      setPrice(options[index].price);
      setIndexPrice(true);
    }
  };

  const [partPrice, setPartPrice] = useState<string[]>([]);

  // useEffect를 이용해 체크박스 상태가 변경될 때마다 가격 계산
  useEffect(() => {
    let total = 0;
    parts.forEach((part, i) => {
      if (isChecked[i]) {
        total += parsePrice(partPrice[i]) * partsCounts[i];
      }
    });

    const mainProductTotal = mainProduct.reduce((acc, item, index) => {
      return acc + parsePrice(getItemPrice(item.price)) * mainProductCounts[index];
    }, 0);
    setTotalPrice(total + mainProductTotal);
  }, [isChecked, price, partPrice, partsCounts, mainProduct, mainProductCounts, selectedOption]);

  // 체크박스 상태 변경 함수
  const handleCheck = (index: number) => {
    setIsChecked(prevChecked => {
      const newChecked = [...prevChecked];
      newChecked[index] = !newChecked[index];

      return newChecked;
    });

  };

  const imgPath = product.product[productName];

  let thumbnail;

  if (imgPath.length > 0) {
    thumbnail = imgPath[selectedOption].thumbnail;
  } else {
    thumbnail = imgPath.thumbnail;
  }

  // 모달 팝업창을 제어
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<{ title: string; partsCount: number; partPrice: string; image: string; }[]>([]);

  const openModal = () => {
    setIsModalOpen(true);

    //악세서리 제품들
    const selected: {
      title: string;
      partsCount: number;
      partPrice: string;
      image: string;
    }[] = parts
      .map((item, index) => ({ item, index })) // parts 배열의 각 요소와 인덱스를 쌍으로 묶음
      .filter(({ index }) => isChecked[index]) // isChecked가 true인 제품들의 인덱스를 필터링
      .map(({ item, index }) => ({
        title: item.title,
        partsCount: partsCounts[index],
        partPrice: lang === 'ko'
          ? `₩${formatPrice(parsePrice(partPrice[index]) * partsCounts[index])}`
          : `$${formatPrice(Math.floor(parsePrice(partPrice[index]) * partsCounts[index] / exchange))}`,
        image: item.img
      }));
    setSelectedProducts(selected);
    setIsModalOpen(true);

  };


  const onClickToggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset"; // 기본 스크롤 상태로 되돌리기
    }

    return () => {
      document.body.style.overflow = "unset"; // 컴포넌트 언마운트 시 기본 스크롤 상태로 되돌리기
    };
  }, [isModalOpen]);


  const SpaceBar = () => <>&nbsp;</>;

  return (
    <StyledItemBox isSelected={isOpen}>

      <StyledItem onClick={handleClick} isOpen={isOpen}>

        <StyledItemIcon src={thumbnail} alt="RTLS product" />

        <StyledItemTextBox>
          <HeadingColor color="#000" size="xxsmall"> <Trans i18nKey={title} /> </HeadingColor>
          <LabelColor color="#818181" size="small"> <Trans i18nKey={serial} /> </LabelColor>
          <StyledItemTextPriceBox>
            <LabelColor color="#818181" size="large">
              {price === 'request' ?
                <>
                  <DivBorderRaiuds pixel='8'>
                    <Trans i18nKey={`productPurchase.modal.title`} />
                  </DivBorderRaiuds>
                  <SpacingR pixel="8" />
                </>
                :
                <>
                  {price !== '별도 문의' &&
                    <>
                      {currency}{formatPrice(Math.floor(parsePrice(price) / exchange))} <SpaceBar />
                    </>
                  }</>
              }
            </LabelColor>

            {subscribeLineUp.includes(title) ? (
              <LabelColor color="#818181" size="large">
                <Trans i18nKey={`${path}.priceSub`} />
              </LabelColor>
            ) : null}


            {mark === "1" &&
              <>
                <StyledItemSoldOut>
                  <LabelColor color="#9F9F9F" size="small"> <Trans i18nKey={`productPurchase.soldOut`} /> </LabelColor>
                </StyledItemSoldOut></>}

            {mark === "2" &&
              <>
                <StyledNewItem>
                  <LabelColor color="#00C37D" size="small"> <Trans i18nKey={`productPurchase.new`} /> </LabelColor>
                </StyledNewItem></>}
          </StyledItemTextPriceBox>
        </StyledItemTextBox>

      </StyledItem>



      <StyledSpecBox>

        {/* 스펙 */}
        <StyledNoteDetail isOpen={isOpen}>
          {note.map((item, index) => {
            if (item.text) {
              return (
                <StyledNoteBox key={`purchase-item-note-${index}`}>
                  {item.icon ? (<>
                    <StyledNoteIcon><ImgWH src={item.icon} w="16" h="16" alt="Product function Icon" /></StyledNoteIcon>
                    <StyledNoteText size="small"> <Trans i18nKey={`${path}.note.${index}.text`} /> </StyledNoteText>
                  </>) : (
                    <StyledNoteFullText size="small"> <Trans i18nKey={`${path}.note.${index}.text`} /> </StyledNoteFullText>
                  )}

                </StyledNoteBox>
              );
            } else {
              return null;
            }
          })}



          {/* 선택 옵션이 있는 경우 */}
          {/* 선택 옵션이 있는 경우 */}
          {options &&
            <StyledOptionsBox gap={options.length > 3 ? '8' : '16'}>
              {options.map((option, index) => (
                <StyledOptionsTextBox
                  key={index}
                  isSelected={selectedOption === index}
                  onClick={() => handleOptionClick(index, option.imgIndex)}
                >
                  <Trans i18nKey={`${path}.options.${index}.select`} />
                </StyledOptionsTextBox>
              ))}

            </StyledOptionsBox>
          }




          {/* 메인 제품 구매*/}

          {mainProduct.map((item, index) => (
            <StyledMainProductBox key={`purchase-item-note-${index}`}>

              <StyledMainProductIcon src={item.img} alt="RTLS product" />
              <StyledItemTextBox>
                <StyledLabel size="medium"> <Trans i18nKey={`${path}.mainProduct.${index}.title`} /> </StyledLabel>
                {/* <Spacing pixel="4" /> */}
                <StyledItemTextPriceBox >
                  <LabelColor color="#818181" size="small">
                    {item.price === '별도 문의' ?
                      <>
                        <Trans i18nKey={`simple.priceUnknown`} />
                      </>
                      :
                      <>
                        {currency}
                        {formatPrice(Math.floor(parsePrice(getItemPrice(item.price)) * mainProductCounts[index] / exchange))}
                      </>}
                  </LabelColor>
                  {subscribeLineUp.includes(title) ? (
                    <LabelColor color="#818181" size="small">
                      <Trans i18nKey={`${path}.priceSub`} />
                    </LabelColor>
                  ) : null}
                </StyledItemTextPriceBox>
              </StyledItemTextBox>

              <PurchaseBtn>
                <PurchaseBox>
                  <PurchaseIcon onClick={() => decreaseMainProductCount(index)}>
                    <CountBtn src={IconMinus} alt="Minus Button" opacity={minValues[index] ? '0.5' : '1'} w={"16"} aRatio={"1/1"} />
                  </PurchaseIcon>
                  <StyledCountDisplay>
                    <StyledCount size="medium">{mainProductCounts[index]}</StyledCount>
                  </StyledCountDisplay>
                  <PurchaseIcon onClick={() => increaseMainProductCount(index)}>
                    <CountBtn src={IconPlus} alt="Plus Button" opacity={maxValues[index] ? '0.5' : '1'} w={"16"} aRatio={"1/1"} />
                  </PurchaseIcon>
                </PurchaseBox>
              </PurchaseBtn>

            </StyledMainProductBox>
          ))}




        </StyledNoteDetail>


        {/* 액세서리 */}
        {/* 추가 구매 제품 */}
        {parts.length > 0 && (
          <StyledItemDetail isOpen={isOpen}>
            <AccessoryTitle size="xxxxsmall" >
              <Trans i18nKey={`productPurchase.accessory`} />
            </AccessoryTitle>

            {/* 추가 구매 제품 */}
            {parts.map((item, index) => {
              if (item.title && getItemPrice(item.price)) {
                return (
                  <StyledAddBox key={`purchase-item-parts-${index}`}>

                    <StyledCheckBox src={isChecked[index] ? IconCheckBoxOn : IconCheckBoxOff} onClick={() => handleCheck(index)} alt="checkbox" />

                    {/* 이미지 */}
                    <StyledAddImg src={item.img} alt="RTLS product" />

                    {/* 제품이름 */}
                    <StyledAddTextBox>
                      <StyledLabel size="medium">  <Trans i18nKey={`${path}.parts.${index}.title`} /> </StyledLabel>

                      <Spacing pixel="4" />
                      {/* 가격 */}
                      <StyledAddPrice color="#818181" size="small">
                        {partPrice[index] === undefined ? (
                          <>
                            {partPrice[index] = getItemPrice(item.price)}
                          </>)
                          :
                          (
                            <Trans i18nKey={`${currency}${formatPrice(parsePrice(partPrice[index]) * partsCounts[index])}`} />

                          )}
                      </StyledAddPrice>

                    </StyledAddTextBox>


                    <PurchaseBtn>
                      <PurchaseBox>
                        <StyledCountBtnBox>
                          <StyledPurchseCount onClick={() => decreasePartsCount(index)}>
                            <CountBtn src={IconMinus} alt="Minus Button" opacity={partsCounts[index] === 1 ? '0.5' : '1'} w={"16"} aRatio={"1 / 1"} />
                          </StyledPurchseCount>
                          <StyledCountDisplay>
                            <StyledCount size="medium"> {partsCounts[index]} </StyledCount>
                          </StyledCountDisplay>
                          <StyledPurchseCount onClick={() => increasePartsCount(index)}>
                            <CountBtn src={IconPlus} alt="Plus Button" opacity={"1"} w={"16"} aRatio={"1/1"} />
                          </StyledPurchseCount>
                        </StyledCountBtnBox>
                      </PurchaseBox>
                    </PurchaseBtn>


                  </StyledAddBox>
                );
              } else {
                return null; // title이나 text가 없는 경우 null 반환
              }
            })}
          </StyledItemDetail>
        )}



        {/* 계산 */}
        <StyledCalcBox isOpen={isOpen}>

          <StyledBtnBoxLeftSide>

            {/* 총 가격 텍스트 */}
            <StyledPriceText size="xsmall" pixel={"14px 0"}>

              <Trans i18nKey={`simple.price`} />{"\u00A0"}
            </StyledPriceText>

            {/* 총 가격 숫자 */}
            <StyledPrice size="xsmall" pixel={"14px 0"}>
              {price === '별도 문의' ?
                <>
                  <Trans i18nKey={`simple.priceUnknown`} />
                </>
                :
                <>
                  {(` ${currency}` + formatPrice(Math.floor(totalPrice / exchange)))}
                </>}
            </StyledPrice>

          </StyledBtnBoxLeftSide>



          {/* 문의하기 or 개수 */}

          {/* 문의하기 */}
          {requestLineUp.includes(title) ?
            <>
              <StyledRequestBtnWrapper>

                <StyledRequestBtn onClick={openModal}>
                  <Trans i18nKey={"productPurchase.request"} />
                </StyledRequestBtn>

              </StyledRequestBtnWrapper>
            </>
            :
            <>

              <StyledRequestBtnWrapper>

                <StyledRequestBtn onClick={openModal}>
                  <Trans i18nKey={"productPurchase.request"} />
                </StyledRequestBtn>
              </StyledRequestBtnWrapper>
            </>
          }


        </StyledCalcBox>



      </StyledSpecBox>



      {isModalOpen && (
        <Modal onClickToggleModal={onClickToggleModal}
          mainProduct={mainProduct}
          mainProductCounts={mainProductCounts}
          mainTotalPrices={mainProduct.map((item, index) => lang === 'ko'
            ? `₩${formatPrice(parsePrice(getItemPrice(item.price)) * mainProductCounts[index])}`
            : `${currency}${formatPrice(Math.floor(parsePrice(getItemPrice(item.price)) * mainProductCounts[index] / exchange))}`)}
          selectedProducts={selectedProducts}
          totalPrice={
            price === '별도 문의' ?
              '별도 문의' :
              lang === 'ko'
                ? `₩${formatPrice(totalPrice)}`
                : `${currency}${formatPrice(Math.floor(totalPrice / exchange))}`
          }
          path={path}
        />
      )}


    </StyledItemBox>
  )
}

export default PurchaseDetail;

