import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: '通过实时监控城市交通状况来增强交通管理和响应能力。通过交通信息闭路电视（CCTV）联动，将视频信息包含在物体中，实现大规模视频信息整合，进行事故分析和对策制定，监督交通法规遵守，制定高效的交通管理策略等手段，有效管理交通系统。',
  keywords: 'Digital Twin, Digital Twins, 数字孪生, gis, 数字城市, RTLS, 位置追踪系统, 位置追踪技术, 体育, 制造, 物流, 医疗, 医院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - 交通 CCTV 连接 :: ORBRO 博客',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogUrl: 'https://orbro.io/cn/blog/digitaltwin-cctv',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twin - 交通 CCTV 连接',
  date: '2023-12-26',
  tag1: '#数字孪生',
  tag2: '#CCTV',
  tag3: '#数字城市',
  tag4: '#虚拟现实',

  text: [
    {
      head: 'Digital Twin - 交通 CCTV 连接',
      subHead: '',
      desc:'ORBRO提供了实时监测交通状况、改善交通管理和应对的服务。通过与交通信息的CCTV集成、在对象中包含视觉信息、大规模视觉信息的综合集成、事故分析和对策的制定、监测交通法规合规性以及制定高效的交通管理战略，它有效地管理交通系统。<br/><br/>为实现这一目标，CCTV集成实时观察交通流，对象中包含视觉信息强化了交通管理和安全性。大规模视觉信息的综合集成准确评估广泛区域的交通状况，而事故分析和对策的制定增强了道路安全性。监测交通法规合规性确保交通安全，而高效的交通管理战略减少了交通拥堵。通过这些措施，ORBRO有效管理道路和交通环境，保持安全高效的交通系统。',
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/iOZVM1YT_pE?si=MkzatuD-2E4xbzkQ',
      head: '',
      subHead: '交通信息 CCTV 联动',
      desc: 'ORBRO实时集成CCTV影像以监控道路和交通状况。这使交通管理者能够实时观察交通流，并迅速应对诸如事故或拥堵等情况。',
      dot: ''
    },
    {
      head: '',
      subHead: '将视频信息包含在物体中',
      desc: '在CCTV影像中识别车辆、行人等对象，并提供附加信息，有助于增强交通管理和安全性。这在防止和调查交通事故方面发挥着关键作用。',
    },
    {
      head: '',
      subHead: '大规模视频信息整合',
      desc: '通过整合来自多个CCTV来源的视频信息，全面了解广泛区域的交通状况。这为城市交通管理和规划提供了重要数据。',
    },
    {
      head: '',
      subHead: '事故分析和对策制定',
      desc: '利用CCTV数据分析交通事故的原因，并制定减少事故发生的对策。这提高了道路安全性并降低了事故发生率。',
    },
    {
      head: '',
      subHead: '监督交通法规遵守',
      desc: '监控交通法规遵守，识别违规者并促进法规遵守。这确保了交通安全，并鼓励遵守规则的文化。',
    },
    {
      head: '',
      subHead: '制定高效的交通管理策略',
      desc: '通过分析收集的数据来改善交通流，并制定高效的交通管理策略。这减轻了交通拥堵，并提高了市民的出行便利性。',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '将UWB终端安装在工人或设备上，以实时追踪其位置的产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }
    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]
  }
}
