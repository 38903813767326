import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";
import digitaltwin from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

export default {
    helmet: '| 数字化转型是什么？',
    desc: '数字化转型是指通过数字技术革新传统的业务和社会过程。换句话说，它将数字技术引入传统方法，以有效且创新地改善业务、服务和沟通等方面的过程。',
    keywords: '智慧城市、数字孪生、数字化转型、实时定位跟踪、RTLS、orbro、Kongtech',

    ogTitle: '',
    ogDesc: '',
    ogSite: '数字化转型是什么？ | 数字孪生专业平台，Orbro',
    ogUrl: 'https://orbro.io/cn/blog/what-is-digital-transformation',
    ogKey: '',
    ogImg: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '数字化转型是什么？',
    date: '2024-01-10',
    tag1: '#数字化转型',
    tag2: '#数字孪生',
    tag3: '#DX',
    tag4: '#智慧城市',

    text: [
        {
            head: '数字化转型是什么？',
            subHead: '',
            desc: '数字化转型是通过数字技术革新传统的业务和社会过程的过程。它将数字技术引入传统方法，通过利用大数据、云计算、人工智能、自动化等技术，重新构建业务模型和流程，提高生产力，增强竞争力。因此，数字化转型不仅仅是技术变革，还影响组织文化和业务方法，可以理解为未来持续变革的过程。',
            dot: ''
        },
        {
            head: '数字化转型的主要特征',
            subHead: '数据驱动管理',
            desc: '企业收集并分析大量数据，进行准确的业务决策。通过利用大数据技术和分析，预测客户行为，制定战略。',
            dot: ''
        },
        {
            head: '',
            subHead: '自动化与人工智能',
            desc: '自动化业务流程促使企业提高生产力，引入人工智能技术可以高效执行复杂的任务，如预测分析、自然语言处理和图像识别，从而减轻人类的工作负担。',
            dot: ''
        },
        {
            head: '',
            subHead: '云计算',
            desc: '云技术允许灵活使用IT资源，企业可以降低基础设施成本，并根据需要灵活调整资源。这使得业务流程更加流畅。',
            dot: ''
        },
        {
            head: '',
            subHead: '物联网',
            desc: '物联网概念涉及将传感器和通信功能嵌入物体中，通过互联网使它们相互连接。各种设备收集数据，相互通信，智能适应生活和工作环境。',
            dot: ''
        },
        {
            video: digitaltwin,
            link: 'https://orbro.io/cn/digital-twin',
            videoDesc: '(点击视频将跳转到Orbro数字孪生页面)',
            head: '数字化转型案例',
            subHead: '',
            desc: '数字化转型正在各行各业和企业中进行，每个案例中采用的特定数字技术以改革业务或优化过程的方式不同。以下是一些典型的数字化转型案例。',
            dot: {
                a: '<strong> • 金融服务行业 - 金融科技：</strong> 金融服务行业的数字化转型挑战传统银行体系。金融科技公司通过引入技术，如移动银行、电子支付系统、投资机器人等，提供更便利和实惠的服务。移动应用进行银行业务，在线投资平台，个人财务管理应用等是金融科技的主要案例。',
                b: '<strong> • 制造业 - 智能工厂：</strong> 制造业的数字化转型以智能工厂的概念呈现。通过利用物联网、大数据和人工智能技术，优化生产线并分析实时生产数据以提高效率。使用传感器和物联网技术进行生产线监控，利用大数据分析进行预测性维护，使用机器人和自动化进行生产流程优化等都是智能工厂的案例。',
                c: '<strong> • 零售业 - 在线购物和个性化营销：</strong> 零售业的数字化转型侧重于在线购物平台和个性化营销。基于数据分析的个性化推荐系统、虚拟试衣间和实时库存管理等是零售业数字化转型的案例。通过分析顾客的过往购买记录进行定制产品推荐，提供在线购物体验的虚拟试衣间，店内数字支付系统等都属于此类。',
                d: '<strong> • 医疗领域 - 电子健康记录：</strong> 医疗领域的数字化转型侧重于引入电子健康记录（EHR）系统和通过远程诊疗和监控实现的远程医疗技术。通过数字方式管理患者数据，通过在线提供医疗服务，提高效率。引入电子健康记录系统，通过使用视频通话平台进行远程诊疗，使用传感器实时监测患者健康状况等都是医疗领域数字化转型的案例。',
            }
        }, {
            head: '数字化转型的主要技术',
            subHead: '人工智能（AI）',
            desc: 'AI涵盖了计算机系统像人一样学习、推理和解决问题的能力。使用机器学习、深度学习、自然语言处理等子领域执行各种任务，如数据分析、预测分析、自动化等。',
            dot: ''
        },
        {
            head: '',
            subHead: '大数据',
            desc: '大数据技术涉及收集、存储、分析大规模且多样化的数据。使用大量数据发现统计模式、趋势和洞察，支持业务决策。与云计算协同工作，处理和存储大规模数据集。',
            dot: ''
        },
        {
            head: '',
            subHead: '云计算',
            desc: '云计算是通过互联网提供计算资源（服务器、存储、网络等）的技术，可以灵活调整资源。通过云介质节省基础设施成本，迅速提供服务。',
            dot: ''
        },
        {
            head: '',
            subHead: '物联网（IoT）',
            desc: '物联网是一种技术，通过在物体中嵌入传感器和通信功能，并通过互联网连接它们。各种设备收集数据，相互通信，智能适应生活和工作环境。',
            dot: ''
        },
        {
            head: '',
            subHead: '机器人技术和自动化',
            desc: '机器人技术和自动化技术指的是使用机械替代或协助人类任务。这项技术不仅在制造业中使用，还在服务业中使用，提高生产力和效率。',
            dot: ''
        },
        {
            head: '数字化转型的优势',
            subHead: '确保竞争优势',
            desc: '数字化转型通过引入新的商业模式或优化现有流程，确保竞争优势。高效的数据使用和服务提供的改善将导致在市场上更高的可见性和积极评价。',
            dot: ''
        },
        {
            head: '',
            subHead: '提升客户体验',
            desc: '以客户为中心的数字化转型将提升客户体验。个性化的服务、便捷的数字界面、快速的响应将为客户提供更好的体验，增强忠诚度。',
            dot: ''
        },
        {
            head: '',
            subHead: '降低成本和提高效率',
            desc: '通过引入数字技术，如自动化、云计算、数据分析等，业务流程变得更加高效，实现成本削减和生产效率提升。快速而准确的任务执行使人力和资源得到有效利用。',
            dot: ''
        },
        {
            head: '',
            subHead: '提高市场应变能力',
            desc: '数字化转型有助于迅速适应快速变化的市场环境。灵活的商业模式和数据驱动的决策有助于迅速应对变化。引入新技术或改变现有业务模型将通过创新表现为进入新市场、产品创新和形成伙伴关系等多种形式。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: '该产品通过将UWB设备安装在工人和设备上，支持实时位置追踪。',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/cn/enterprise/logistics/asset'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/cn/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/cn/enterprise/manufacturing/asset'
            }
        ]

    }
}
