

import React, { PropsWithChildren } from "react";
import { Trans } from "react-i18next";


import {
  StyledDownloadDesc,
} from "./ModalDownload.style";

import { DialogBorder, DialogBox, DialogBtn, DialogBtnBox, ModalContainer } from "./Modal.style";
import { IconXMark } from "@assets/images/icon";
import solutionCommon from "@src/lang/ko/enterprise/solutionCommon";
import solutionCommonEng from "@src/lang/en/enterprise/solutionCommon";
import solutionCommonJP from "@src/lang/jp/enterprise/solutionCommon";
import solutionCommonCN from "@src/lang/cn/enterprise/solutionCommon";
import { useLocation } from "react-router-dom";
import axios from "axios";
import emailjs from 'emailjs-com';
import { StyledDownloadBtnlText } from "@pages/Enterprise/Download/ModalDone/ModalDoneText.style";
import { DivFlexRow, DivWidth100 } from "@pages/CommonStyle/commonDiv.style";
import { CloseBtn } from "@pages/CommonStyle/commonBtn";
import { Backdrop } from "@pages/Enterprise/Download/Modal/Modal.style";
import { StyledHeading } from "@components/Text";
import { Spacing } from "@pages/CommonStyle/common.style";


//엔터프라이즈 솔루션 구독

interface ModalDefaultType {
  onClickToggleModal: () => void;
  onDone: () => void;
  email: string;
}


function ModalSubscribe({
  onClickToggleModal,
  onDone,
  email

}: PropsWithChildren<ModalDefaultType>) {

  const handleModalClose = () => {
    onClickToggleModal();
  }

  const onDoneBtn = () => {
    subscribe();
    onDone();
    onClickToggleModal();
    if(!email.includes('tech42')){
      onClickEmail();
    } else {
      onClickEmailSpam();
    }
  }

  const onClickEmail = () => {
    emailjs.init('SoQtE3CThBY_wpeDn');
    const mailForm ={
      email : email,
    } 
    
    emailjs.send('service_7yv5b7d', 'template_8o3j6bm', mailForm)
    .then((response) => {
      console.log('이메일 보내기 성공:', response);
    })
    .catch((error) => {
      console.error('이메일 보내기 실패:', error);
    });
    
  }

  const onClickEmailSpam = () => {
    emailjs.init('SoQtE3CThBY_wpeDn');
    const mailForm ={
      email : email,
    } 
    
    emailjs.send('service_7yv5b7d', 'template_zt2sf0o', mailForm)
    .then((response) => {
      console.log('이메일 보내기 성공:', response);
    })
    .catch((error) => {
      console.error('이메일 보내기 실패:', error);
    });
    
  }


  const subscribe = () => {
    
    const stibee = 'https://api.stibee.com/v1/lists/253278/subscribers'
    const apiKey = '98bfb7a271ec803ad3d00fb3e5f5402d66b9ea53af2df75fcf5b0995b937696ea2c89f06d924bd28b75edb6bf58d10c364de29acde10a0a00946b9d48a13215f'
    const subData = {
      "eventOccuredBy": "SUBSCRIBER",
      "confirmEmailYN": "N",
      "subscribers": [
          {
              "email": `${email}`
          }
      ]
    }

    axios.post(stibee, subData, {
      headers:{
        'AccessToken' : apiKey,
        'Content-Type' : 'application/json',
      }
    })
    .then(function (res){
      console.log(res);
    })
    .catch(function (err){
      console.log(err);
    })
    ;

  };

  const location = useLocation();
  let text;
  let textTail;
  const getText = () => {
    if(location.pathname.includes('/en/')){
      textTail = solutionCommonEng.modal.subscribe.desc;
    } else if(location.pathname.includes('/jp/')){
      textTail = solutionCommonJP.modal.subscribe.desc;
    } else if(location.pathname.includes('/cn/')){
      textTail = solutionCommonCN.modal.subscribe.desc;
    } else {
      textTail = solutionCommon.modal.subscribe.desc;
    }
    text = email + textTail;
    return text;
  };

  return (

    <ModalContainer>
      <DialogBox>
        <DivWidth100>

          
          <DivFlexRow>
            <StyledHeading size="small"> <Trans i18nKey={"solutionCommon.modal.subscribe.title"} /> </StyledHeading>
            <Spacing pixel="8"/>
            <CloseBtn src={IconXMark}
            onClick={onClickToggleModal} size={"20"} alt="x mark icon"/>
          </DivFlexRow>

          <DialogBorder/>
          
          
          <StyledDownloadDesc size="medium"> <Trans i18nKey={getText()} /> </StyledDownloadDesc>
          
          <DialogBtnBox>

            <DialogBtn onClick={handleModalClose} bgcolor={"#FFFFFF"}>
                <StyledDownloadBtnlText size="medium" color='#000000'><Trans i18nKey={"solutionCommon.modal.subscribe.skip"} /> </StyledDownloadBtnlText>
            </DialogBtn>

            <DialogBtn onClick={onDoneBtn} bgcolor={"#000000"}>
                <StyledDownloadBtnlText size="medium" color='#FFFFFF' ><Trans i18nKey={"solutionCommon.modal.subscribe.confirm"} /> </StyledDownloadBtnlText>
            </DialogBtn>

          </DialogBtnBox>



        </DivWidth100>
      </DialogBox>


      <Backdrop
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();

          if (onClickToggleModal) {
            onClickToggleModal();
          }
        }}
      />
      
    </ModalContainer>

    
  )


}


export default ModalSubscribe;

