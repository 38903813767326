import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: 'ORBROは飛行機のリアルタイムトラッキングと運用情報を提供し、航空機の機動性を向上させ、旅行と運用の安全性を確保します。航空会社情報、飛行経路の確認、空港別スケジュールの確認、緊急事態の管理、顧客サービスの向上、環境への影響のモニタリングなどを通じて、航空旅行の体験を向上させます。',
  keywords: 'Digital Twin, Digital Twins, デジタルツイン, gis, デジタルシティ, RTLS, 位置追跡システム, 位置追跡技術, スポーツ, 製造, ロジスティクス, 医療, 病院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'デジタルツイン - 航空機情報の連携 :: ORBRO ブログ',
  ogUrl: 'https://orbro.io/jp/blog/digitaltwin-airplane',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'デジタルツイン - 航空機情報の連携',
  date: '2023-12-26',
  tag1: '#DigitalTwin',
  tag2: '#Aircraft',
  tag3: '#DigitalCity',
  tag4: '#VirtualReality',

  text: [
    {
      head: 'デジタルツイン - 航空機情報の連携',
      subHead: '',
      desc: 'ORBROは航空機のリアルタイムトラッキングと運用情報を通じて航空機の移動性を向上させ、旅行および運用の安全性を確保しています。航空会社情報の提供、航空路のモニタリング、各空港のスケジュール確認、緊急事態管理、顧客サービス向上、環境への影響のモニタリングなどを通じて、航空旅行の総合的な体験を向上させています。<br/><br/>航空会社情報の提供は、旅行者が旅行計画を立てる際に不可欠な情報を提供し、便利な旅行を支援します。航空路のモニタリングは、航空交通管理と安全性を確保し、スムーズな運航をサポートします。各空港のスケジュール提供は、旅行者が正確で信頼性のある旅行情報を得て、旅行の予定を効果的に管理できるよう支援します。<br/><br/>緊急事態管理は予測できない状況に備えて、乗客とスタッフの安全を最優先に考えています。顧客サービス向上は、乗客対応とサービス品質の向上により満足度を高めます。環境への影響のモニタリングは、持続可能な航空運用戦略を確立し、環境的責任を果たし、持続可能性を追求します。ORBROはこれら多岐にわたる機能を通じて安全で便利な航空旅行をサポートし、持続可能な運用に貢献しています。',
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/w1pKhaleOH4?si=BNCBDwA5fepB03Y3',
      head: '',
      subHead: '航空会社情報の提供',
      desc: 'ORBROは各航空会社の航空機タイプ、航路、スケジュールなどの情報をリアルタイムで提供します。この情報は航空旅行を計画する乗客にとって不可欠であり、航空会社および空港オペレーターにとっては航空機の運用と管理に必要な重要なデータを提供します。',
      dot: ''
    },
    {
      head: '',
      subHead: '飛行経路のモニタリング',
      desc: '航空機のリアルタイムな飛行経路のモニタリングと表示は航空交通管理と航空安全を確保する上で重要な役割を果たします。',
    },
    {
      head: '',
      subHead: '空港別スケジュール情報の提供',
      desc: '到着および出発のスケジュール、ゲート情報などを含む各空港の航空スケジュール情報を提供することで、旅行者に正確な旅行情報を提供し、空港の運用効率を向上させます。',
    },
    {
      head: '',
      subHead: '緊急事態の管理',
      desc: 'リアルタイムの航空データを活用して緊急事態への迅速な対応と管理を可能にし、乗客とスタッフの安全を確保し、潜在的なリスクを最小限に抑えます。',
    },
    {
      head: '',
      subHead: '顧客サービスの向上',
      desc: 'リアルタイムの航空情報を活用して乗客対応とサービスの向上を促進し、乗客満足度を高め、航空旅行の体験を向上させます。',
    },
    {
      head: '',
      subHead: '環境への影響のモニタリング',
      desc: '航空機の運用に関連する環境への影響をモニタリングし、二酸化炭素排出の削減および環境保護の取り組みに重要な役割を果たします。',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を作業者や機器に取り付け、リアルタイムでの位置追跡をサポートする製品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/jp/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/jp/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      }
    ]
  }
}
