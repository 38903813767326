import { FlexStyle } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay } from "@components/Text";
import styled from "@emotion/styled";

export const StyledFaq = styled(FlexStyle)`
  justify-content: center;

  & * {
    font-family : 'Pretendard' ! important;
    font-display: swap;
  }
  
`

export const StyledFaqTitle = styled(StyledDisplay)`

  & > br {
    display: none;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    & > br {
      display: block;
    }
  }
`

export const StyledFaqGridRow = styled(StyledGridRow)`
  margin-top: 32px;
  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    row-gap: 0px;
  }
`