import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const PatentLayout = styled.div`
    width : 60%;
    height : fit-content;

    display : flex;
    justify-content : space-between;
    align-items : center;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        width : 90%;
    }

    @media (max-width : 490px){
        flex-direction : column;
        gap : 32px;
        width : 100%;
    }
`;


export const PatentBox = styled.div`
    display : flex;
    flex-direction : column;
    text-align : center;
    justify-content : center;
    align-items : center;
    gap : 8px;
`;

