import { StyledTechnologyGroup, StyledTechnologyText, StyledTechnologyTitle } from "@pages/Enterprise/Technology/Technology.style";
import React from "react"; 
import { Trans } from "react-i18next";

import AdvantageWhite from "@pages/Enterprise/Technology/Common/Advantage/AdvantageWhite";
import { Icon3DLine, IconWall } from "@assets/images/icon";
import { AdvantagePropsItem } from "@pages/Enterprise/Technology/Common/Advantage/Advantage";
import { StyledTechnologyGroupNoBar } from "@pages/Enterprise/Technology/StereoVision/components/SVContent/StereoVision.style";

const howWorks:AdvantagePropsItem[] = [
  { 
    icon: Icon3DLine, 
    title: "stereo.howItWorks.card.detection.title",
    description: "stereo.howItWorks.card.detection.description",
  },
  { 
    icon: IconWall, 
    title: "stereo.howItWorks.card.estimation.title",
    description: "stereo.howItWorks.card.estimation.description",
  },
  
]


const MonocularContent = () => {
  

  return (
    <>
      <StyledTechnologyGroup>
        <StyledTechnologyTitle size="small"><Trans i18nKey={ "monocular.tech.title" }/></StyledTechnologyTitle>
        <StyledTechnologyText size="medium">
          <Trans i18nKey={"monocular.tech.text"}/>
        </StyledTechnologyText>
      </StyledTechnologyGroup>
      
    
      <StyledTechnologyGroup>
        <StyledTechnologyTitle size="small"><Trans i18nKey={ "monocular.feature.title" }/></StyledTechnologyTitle>
        <StyledTechnologyText size="medium">
          <Trans i18nKey={"monocular.feature.text"} />
        </StyledTechnologyText>
      </StyledTechnologyGroup>

      <StyledTechnologyGroup>
        <StyledTechnologyTitle size="small"><Trans i18nKey={ "monocular.howItWorks.title" }/></StyledTechnologyTitle>
        <AdvantageWhite title={"monocular.advantage.title"}items={howWorks} />

      </StyledTechnologyGroup>




      <StyledTechnologyGroupNoBar>
        <StyledTechnologyTitle size="small"><Trans i18nKey={ "monocular.difference.title" }/></StyledTechnologyTitle>
        <StyledTechnologyText size="medium">
          <Trans i18nKey={"monocular.difference.description"} />
        </StyledTechnologyText>
      </StyledTechnologyGroupNoBar>



    </>
  );
};

export default MonocularContent;