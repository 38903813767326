import { IconBell, IconBinocularsGray, IconChainLink, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import mainVideo from "@src/assets/images/solution/constructionSafety/video/rtlsVideo.mp4";
import subVideo1 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo4.mp4";
import subVideo5 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo5.mp4";


export default {
    banner: {
      title: '作業者の安全管理',
      desc: '作業者の安全は何よりも重要です。高危険な環境で作業者の安全を確保しないことは、生産性の問題だけでなく、法的責任も発生する可能性があります。また、安全な建設現場は企業のイメージを形成し、利害関係者の信頼を築く上で重要な役割を果たします。',
    },
    video: {
      title: '建設現場の作業者をより安全に',
      desc: 'リアルタイム位置測位（RTLS）技術により、建設現場の作業者の位置情報をリアルタイムで把握することができます。管理者は事前に危険な状況を検知し、問題が発生した場合に迅速に対応できます。<br/>さらに、<strong>安全規制の遵守</strong>をサポートし、作業者と管理者の信頼関係を構築します。',
      play: mainVideo,
    },
    func: [
      {
        title: '安全装備の確認',
        desc: '作業者がヘルメットなどの安全装備を着用しているかをリアルタイムで検知し、作業中の安全事故を予防し、安全な作業環境を維持するためにサポートします。現場の安全管理を強化し、組織の安全性と信頼性を高めます。',
        sub1: '高い精度の提供',
        icon1: IconRulerGray,
        sub2: 'リアルタイムな状況把握',
        icon2: IconClockGray,
        video: subVideo1,
      },
      {
        title: 'リアルタイム作業者位置測位',
        desc: '作業者の位置をリアルタイムで測位し、安全状況をモニタリングし、危険なエリアに近づいたり緊急事態が発生した場合に迅速に対応することを支援します。これにより、潜在的な危険を事前に防止します。',
        sub1: 'リアルタイムな位置測位',
        icon1: IconBinocularsGray,
        sub2: '高速データ統合',
        icon2: IconTvocGray,
        video: subVideo2,
      },
      {
        title: 'リアルタイム重機位置測位',
        desc: '安全に留意するべきは人間だけではありません。潜在的な事故リスクのある重機の位置をリアルタイムで把握し、作業者が接近したり誤った経路に入ったりする場合を検知し、事故を予防できます。',
        sub1: 'リアルタイムな位置測位',
        icon1: IconBinocularsGray,
        sub2: '高い精度の提供',
        icon2: IconRulerGray,
        video: subVideo3,
      },
      {
        title: '作業者の経路測位',
        desc: '作業者が進む経路に危険な機器や物質がある場合、管理者に警告を出すことができます。これにより、迅速な対応や救出が可能となります。',
        sub1: '統計データの提供',
        icon1: IconChartBarGray,
        sub2: 'データのクラウド保存',
        icon2: IconCloudCheckGray,
        video: subVideo4,
      },
      {
        title: '安全ゾーンの設定',
        desc: 'アクセスしてはならない場所や注意が必要な場所を事前に設定し、近くを通過する作業者に警告したり、管理者に通知することができます。これにより、事故を予防することができます。',
        sub1: '自由な範囲の設定',
        icon1: IconZoneGray,
        sub2: '複数のゾーンの設定',
        icon2: IconTagGray,
        video: subVideo5,
      },
    ],
    subfunc: {
      title: 'さまざまな機能',
      desc: '作業者の安全を確保することは効率を高め、事故による損失を減らします。ORBROは、建設現場の作業者の安全を保護するために最適な機能を提供しています。作業者が危険なエリアに接近したり、事故が発生したり、重機が潜在的なリスク状況にさらされる可能性のある安全事故に適切に対応できるシステムを構築しています。',
      data: [
        {
          icon: IconChartRadius,
          title: 'レポートと分析',
          desc: 'フィールドデータを基にレポートや分析資料を生成できます。',
        },
        {
          icon: IconBell,
          title: 'リアルタイムアラーム機能',
          desc: '異常な状況が発生した場合にアラームを送信し、即座に対応できます。',
        },
        {
          icon: IconRoute,
          title: '経路履歴の照会',
          desc: '作業者がフィールドで移動した経路を照会し、データ化することができます。',
        },
        {
          icon: IconDashboard,
          title: 'ステータスのモニタリング',
          desc: '作業者の現在の移動状況やフローを把握します。',
        },
        {
          icon: IconWarning,
          title: '逸脱の検出',
          desc: '作業者がフィールドから離れたり、制限エリアに近づいたりする状況をリアルタイムで測位します。',
        },
        {
          icon: IconSearch,
          title: '経路パターンの分析',
          desc: '作業者の動作方法を理解し、作業効率を向上させます。',
        },
        {
          icon: IconChainLink,
          title: 'データ統合',
          desc: 'RFID、IoT、セキュリティ、ビデオなどのシステムを統合して効率的に活用できます。',
        },
        {
          icon: IconSecurity,
          title: 'データのセキュリティ',
          desc: '高度なデータセキュリティを維持し、信頼性の高いソリューションを提供します。',
        },
      ],
    },
    system: {
      title: 'システム構成',
      img: rtlsSystem,
    },
    productTitle: '製品構成',
    product: [
      {
        tag: 'workerPackage',
      },
      {
        tag: 'assetPackage',
      },
    ],
    contents: [
      {
        data: 'constructionAsset',
      },
      {
        data: 'constructionEquipment',
      },
      {
        data: 'blogRTLS',
      },
      {
        data: 'blogMedical',
      },
    ],
  }
  