import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const BeaconPage = styled.div`
    padding : 128px 0 96px 0;
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        padding : 96px 0;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 64px 0 96px 0;
    }

`;

export const Layout = styled.div`
    display : grid;
    gap : 32px;
    max-width : 100%;
    grid-template-columns : repeat(2, 1fr);

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        display : flex;
        flex-direction : column;
        gap : 16px;
    }
`;



export const NormalCard = styled.a<{w : number, img : string}>`
    cursor : pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;
    border-radius: 12px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
    display : flex;
    ${({w})=> w && `max-width : calc((1 / 2) * (${w}px - 64px));`}
    ${({img})=> img && `background-image : url(${img});`}
    padding : 32px;

    aspect-ratio : 600 / 400 ;
    
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        padding : 24px;
        ${({w})=> w && `max-width : calc((1 / 2) * (${w}px - 48px));`}
        aspect-ratio : 440 / 320 ;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        width : 100%;
        max-width : 100%;
        aspect-ratio : 361 / 240 ;
        padding : 24px;
    }
`;


export const CardTextBox = styled.div`
    display : flex;
    flex-direction : column;
    width : 100%;
    
`;




export const DescBox = styled.div`
    margin-top : auto;
    width : 64%;
    
    word-break : keep-all;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        width : 80%;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        width: 100%;
    }
`;

