export default {


    solution : '오브로 솔루션 소개',
    product : '관련 제품',

    helmet : ' ',
    desc : ' ',
    keywords : ' ',
    
    ogTitle : ' ',
    ogSite : ' ',
    ogDesc :' ',
    ogUrl : 'https://orbro.io/blog',
    ogType : ' ',

    twitterCard : ' ',
    twitterTitle : ' ',
    twitterDesc : ' ',

    
    title: '<strong>ORBRO</strong> Blog',
    description: '오브로의 최신 기술과 소식을 읽어보세요.',
    blogList: [

      {
        name : 'blogSmartShipyard',
        date : '2024-05-09',
        label: '스마트 조선소 솔루션',
        path : '/blog/smart-shipyard'
      },
      {
        name : 'blogAIoT',
        date : '2024-05-09',
        label: 'AIoT란? | 정의, 기술, 사례, 장점',
        path : '/blog/what-is-aiot'
      },
      {
        name : 'blogAiCamera',
        date : '2024-02-07',
        label: 'AI 카메라 위치추적 솔루션',
        path : '/blog/ai-camera'
      },
      {
        name : 'blogIndoorGPS',
        date : '2024-01-31',
        label: '실내 GPS 솔루션',
        path : '/blog/indoor-gps'
      },
      {
        name : 'blogSmartBuildings',
        date : '2024-01-30',
        label: '스마트 빌딩이란?',
        path : '/blog/smart-buildings'
      },
      {
        name : 'blogSmartPort',
        date : '2024-01-25',
        label: '스마트 항만 솔루션',
        path : '/blog/smart-port'
      },
      {
        name : 'blogSmartParking',
        date : '2024-01-24',
        label: '스마트 주차장 시스템',
        path : '/blog/smart-parking'
      },
      {
        name : 'blogIPS',
        date : '2024-01-23',
        label: 'IPS(Indoor Positioning System)란?',
        path : '/blog/indoor-positioning-system'
      },
      {
        name : 'blogSmartAirport',
        date : '2024-01-16',
        label: '스마트 공항 솔루션',
        path : '/blog/smart-airport-solution'
      },
      {
        name : 'blogSmartHospital',
        date : '2024-01-16',
        label: '스마트 병원이란?',
        path : '/blog/스마트병원이란'
      },

      {
        name : 'blogLoRa',
        date : '2024-01-15',
        label: 'LoRa란?',
        path : '/blog/what-is-LoRa'
      },
      {
        name : 'blogSmartFactory2',
        date : '2024-01-12',
        label: '스마트 공장이란?',
        path : '/blog/what-is-a-smart-factory'
      },
      {
        name : 'blogNVR',
        date : '2024-01-12',
        label: 'NVR이란?',
        path : '/blog/nvr'
      },
      {
        name : 'blogNFC',
        date : '2024-01-11',
        label: 'NFC란?',
        path : '/blog/nfc'
      },
      {
        name : 'blogRFID',
        date : '2024-01-11',
        label: 'RFID란?',
        path : '/blog/rfid'
      },
      {
        name : 'blogWhatIsDX',
        date : '2024-01-10',
        label: '디지털 전환(Digital Transformation)이란?',
        path : '/blog/what-is-digital-transformation'
      },
      {
        name : 'blogContainerTracking',
        date : '2024-01-09',
        label: '컨테이너 추적 (Container Tracking)',
        path : '/blog/container-tracking'
      },

      {
        name : 'blogExampleOfSmartCity',
        date : '2024-01-09',
        label: '스마트 시티(Smart City) 구축 사례',
        path : '/blog/example-of-smart-city'
      },
      {
        name : 'blogWhatIsSmartCity',
        date : '2024-01-08',
        label: '스마트 시티(Smart City)란?',
        path : '/blog/what-is-smart-city'
      },
      {
        name : 'blogBLE',
        date : '2024-01-04',
        label: 'BLE 란? | Bluetooth Low Energy',
        path : '/blog/ble'
      },
      {
        name : 'blogDigitalTwin3',
        date : '2023-12-28',
        label: '디지털 트윈(Digital Twin)이란? | 정의, 적용 사례',
        path : '/blog/digital-twin'
      },
      {
        name : 'blogDigitalTwinDigitalCity',
        date : '2023-12-26',
        label: '디지털 트윈(8) - 생생한 그래픽 도시 구현',
        path : '/blog/digitaltwin-digitalcity'
      },
      {
        name : 'blogDigitalTwinCCTV',
        date : '2023-12-26',
        label: '디지털 트윈(7) - 교통 CCTV 연결',
        path : '/blog/digitaltwin-cctv'
      },
      {
        name : 'blogDigitalTwinAirplane',
        date : '2023-12-26',
        label: '디지털 트윈(6) - 항공기 정보 연결',
        path : '/blog/digitaltwin-airplane'
      },
      {
        name : 'blogDigitalTwinTraffic',
        date : '2023-12-26',
        label: '디지털 트윈(5) - 버스 및 지하철 정보 연결',
        path : '/blog/digitaltwin-traffic'
      },
      {
        name : 'blogDigitalTwinTracking',
        date : '2023-12-26',
        label: '디지털 트윈(4) - 사람과 차량의 실시간 움직임 연결',
        path : '/blog/digitaltwin-tracking'
      },
      {
        name : 'blogDigitalTwinBuildings',
        date : '2023-12-26',
        label: '디지털 트윈(3) - 건물 및 실내정보 구현',
        path : '/blog/digitaltwin-buildings'
      },
      {
        name : 'blogDigitalTwinWeather',
        date : '2023-12-26',
        label: '디지털 트윈(2) - 대기와 기상정보 구현',
        path : '/blog/digitaltwin-weather'
      },
      {
        name : 'blogDigitalTwinGis',
        date : '2023-12-26',
        label: '디지털 트윈(1) - GIS 지리정보 구현',
        path : '/blog/digitaltwin-gis'
      },

      {
        name : 'blogQaUwb',
        date : '2023-12-18',
        label: '품질관리와 재고관리 솔루션 : UWB',
        path : '/blog/qa-uwb'
      },
      {
        name : 'blogConstructionSolution',
        date : '2023-12-11',
        label: '중대재해처벌법 예방 솔루션 : 건설현장 안전관리',
        path : '/blog/construction-solution'
      },
      {
        name : 'blogRfidRtls',
        date : '2023-11-28',
        label: 'RFID 대신, RTLS.',
        path : '/blog/rfid-rtls'
      },
      {
        name : 'blogNfcUwbBle',
        date : '2023-11-20',
        label: 'NFC 태그를 대체할 UWB와 BLE를 활용한 혁신적인 재고관리 시스템',
        path : '/blog/nfc-uwb-ble'
      },
      {
        name : 'blogRtlsCompany',
        date : '2023-11-15',
        label: 'RTLS 업체를 선택하는 데 고려해야 할 주요 기준',
        path : '/blog/rtlsCompany'
      },
      {
        name : 'blogRfidNfcUwb',
        date : '2023-11-10',
        label: 'RFID, NFC, UWB: 어떤 기술을 선택해야 할까요?',
        path : '/blog/RfidNfcUwb'
      },
      {
        name : 'blogBeacon',
        date : '2023-11-08',
        label: '비콘(Beacon)이란?',
        path : '/blog/beacon'
      },
      {
        name : 'blogProductionAutomation',
        date : '2023-11-06',
        label: '스마트공장 구축 및 생산자동화 솔루션',
        path : '/blog/productionAutomation'
      },
      {
        name : 'blogUwbRtls',
        date : '2023-11-03',
        label: 'UWB RTLS, 미래 산업의 시작',
        path : '/blog/uwbRtls'
      },
      {
        name : 'blogRtlsHealthcare',
        date : '2023-11-02',
        label: 'RTLS 헬스케어, 바로 사용 가능합니다.',
        path : '/blog/rtlsHealthcare'
      },
      {
        name : 'blogDX',
        date : '2023-11-01',
        label: '디지털 전환(DX)이란?',
        path : '/blog/dx'
      },
      {
        name : 'blogOfficeSolution',
        date : '2023-10-31',
        label: '스마트 오피스 구축 솔루션',
        path : '/blog/officeSolution'
      },
      {
        name : 'blogDigitalTwin2',
        date : '2023-10-26',
        label: '디지털 트윈의 실체와 발전 가능성',
        path : '/blog/digitalTwin2'
      },
      /*
      {
        name : 'blogOfficeAsset',
        date : '2023-10-25',
        label: '현대 오피스의 물건 및 자산 관리 혁신',
        path : '/blog/officeAsset'
      },
      {
        name : 'blogOfficeRTLS',
        date : '2023-10-24',
        label: '디지털 오피스의 시대',
        path : '/blog/officeRTLS'
      },
      */
      {
        name : 'blogNextManufacturing',
        date : '2023-10-16',
        label: '제조공장 재고 관리 시스템의 미래',
        path : '/blog/nextManufacturing'
      },
      {
        name : 'blogSmartFactory',
        date : '2023-10-12',
        label: '스마트 공장이 제조 산업을 진화시키는 방법',
        path : '/blog/smartFactory'
      },
      /*
      {
        name : 'blogLogisticsSolution',
        date : '2023-10-10',
        label: '생산 공정 관리 솔루션의 핵심기술',
        path : '/blog/logisticsSolution'
      },
      */
      {
        name : 'blogSmartSafety',
        date : '2023-10-04',
        label: '단 하나로 끝내는 스마트 안전관리 솔루션',
        path : '/blog/smartSafety'
      },
      {
        name : 'blogHeavyEquipment',
        date : '2023-09-25',
        label: '현장을 뒤흔드는 기술, 중장비 스마트 관리의 혁신',
        path : '/blog/heavyEquipment'
      },
      /*
      {
        name : 'blogConstructionAsset',
        date : '2023-09-21',
        label: '건설현장 재고 관리 : RTLS가 가져올 새로운 변화',
        path : '/blog/constructionAsset'
      },
      */
      {
        name : 'blogConstructionSafety',
        date : '2023-09-19',
        label: '건설현장을 안전하고 스마트하게 만드는 RTLS 솔루션',
        path : '/blog/constructionSafety'
      },
      /*
      {
        name : 'blogConstructionRTLS',
        date : '2023-09-18',
        label: '스마트 건설현장 작업자 안전관리의 핵심',
        path : '/blog/constructionRTLS'
      },
      */

      {
        name : 'blogLogisticsTech',
        date : '2023-09-13',
        label: '물류 업계 종사자가 알아야 할 최신 기술 트렌드',
        path : '/blog/logisticsTech'
      },
      /*
      {
        name : 'blogSCM',
        date : '2023-09-11',
        label: '글로벌 물류 공급망 관리(SCM)를 위한 스마트 창고 관리',
        path : '/blog/scm'
      },
      */
      {
        name : 'blogLogisticsAsset',
        date : '2023-09-08',
        label: '물류센터 재고 관리의 선진화: RTLS 기반의 솔루션',
        path : '/blog/logisticsAsset'
      },
      {
        name : 'blogLogisticsWorker',
        date : '2023-09-06',
        label: '스마트 물류 시설에서 작업자 안전을 관리하는 방법',
        path : '/blog/logisticsWorker'
      },
      {
        name : 'blogUwbLogistics',
        date : '2023-09-05',
        label: 'UWB 기술을 활용한 스마트 물류 센터',
        path : '/blog/uwbLogistics'
      },
      {
        name : 'blogLogistics',
        date : '2023-09-04',
        label: '스마트 물류 센터 : 게임 체인저가 될 RTLS',
        path : '/blog/logistics'
      },
      {
        name : 'blogUwbGeofence',
        date : '2023-09-01',
        label: '초정밀 UWB 기술을 활용한 지오펜스 시스템의 혁신',
        path : '/blog/uwbGeofence'
      },
      {
        name : 'blogMedicalWaste',
        date : '2023-08-30',
        label: '의료 폐기물과 자산을 관리하는 스마트 기술',
        path : '/blog/medicalWaste'
      },
/*
      {
        name : 'blogSmartMedical',
        date : '2023-08-28',
        label: '스마트 병원 시스템을 구축하는 차세대 기술',
        path : '/blog/smartMedical'
      },
      {
        name : 'blogSeniorCare',
        date : '2023-08-24',
        label: '노인 의료 시설의 운영비를 절감하는 스마트 기술',
        path : '/blog/seniorCare'
      },
*/
      {
        name : 'blogHospital',
        date : '2023-08-21',
        label: '최첨단 병원이 환자를 관리하는 방법',
        path : '/blog/hospital'
      },

      {
        name : 'blogVisitor',
        date : '2023-08-14',
        label: '당신의 전시관 방문객이 줄어드는 이유',
        path : '/blog/visitor'
      },

    /*
      {
        name : 'blogManufacturing',
        date : '2023-08-10',
        label: '차세대 제조 공정 관리의 시작',
        path : '/blog/manufacturing'
      },
*/
      {
        name : 'blogPatientTracking',
        date : '2023-08-07',
        label: '환자 관리를 위한 실시간 위치 추적',
        path : '/blog/patientTracking'
      },
      {
        name : 'blogSmartLogistics',
        date : '2023-08-03',
        label: '스마트 물류창고가 산업을 리드하는 이유',
        path : '/blog/smartLogistics'
      },

      
      {
        name : 'blogSmartConstruction',
        date : '2023-07-31',
        label: '스마트 건설 현장 안전 관리 시스템',
        path : '/blog/smartConstruction'
      },
      /*

      {
        name : 'blogAccess',
        date : '2023-07-05',
        label: '완전히 새로워진 출입의 개념',
        path : '/blog/access'
      },
      
      // 26 - 30
      {
        name : 'blogConstFuture',
        date : '2023-06-06',
        label: 'RTLS가 가져올 건설 업계의 미래',
        path : '/blog/constfuture'
      },
      */

      {
        name : 'blogOffice',
        date : '2023-06-02',
        label: '2023년, 스마트 오피스를 구축하는 방법',
        path : '/blog/office'
      },
      /*
      {
        name : 'blogMall',
        date : '2023-05-28',
        label: '대형 쇼핑몰을 더욱 진화시키는 RTLS',
        path : '/blog/mall'
      },
      
      {
        name : 'blogConstruction',
        date : '2023-05-23',
        label: '건설 현장에서의 RTLS',
        path : '/blog/construction'
      },
      {
        name : 'blogWarehouse',
        date : '2023-05-21',
        label: '창고를 더 스마트하게 운영하는 방법',
        path : '/blog/warehouse'
      },
      */
      



      
      // 21 - 25
      /*
      {
        name : 'blogHealthcare',
        date : '2023-05-19',
        label: '헬스케어 시설에서 RTLS의 이점',
        path : '/blog/healthcare'
      },
      */
      {
        name : 'blogConsider',
        date : '2023-05-19',
        label: 'RTLS 솔루션을 선택할 때 고려해야 할 사항',
        path : '/blog/consider'
      },
      /*
      {
        name : 'blogPatient',
        date : '2023-05-18',
        label: 'RTLS가 의료 시설의 환자 흐름을 개선하는 방법',
        path : '/blog/patient'
      },
      {
        name : 'blogBi',
        date : '2023-05-18',
        label: 'RTLS 를 활용한 비즈니스 인텔리전스(BI) 성장',
        path : '/blog/bi'
      },
      */
      {
        name : 'blogForklift',
        date : '2023-05-17',
        label: '지게차 운전자의 안전을 개선하는 RTLS 솔루션',
        path : '/blog/forklift'
      },
      

      // 16 - 20
      /*
      {
        name : 'blogGse',
        date : '2023-05-17',
        label: 'GSE(지상 지원 장비) 추적을 위한 항공 RTLS 가이드',
        path : '/blog/gse'
      },
      */

      {
        name : 'blogActiveRfid',
        date : '2023-05-16',
        label: 'Active RFID 기술 기반 RTLS',
        path : '/blog/activerfid'
      },
      /*
      {
        name : 'blogElder',
        date : '2023-05-16',
        label: '노인 의료 시설에 RTLS를 사용해야 하는 이유',
        path : '/blog/elder'
      },
      {
        name : 'blogAdvantage',
        date : '2023-05-16',
        label: 'RTLS의 4가지 주요 이점',
        path : '/blog/advantage'
      },
      {
        name : 'blogAnswer',
        date : '2023-05-15',
        label: '답변 : Bluetooth 위치 서비스에 대한 5가지 주요 질문',
        path : '/blog/answer'
      },


      // 11 - 15
      {
        name : 'blogFactory',
        date : '2023-05-15',
        label: '미래의 공장에 오신 것을 환영합니다 : Industry 4.0에서 실시간 추적의 역할',
        path : '/blog/factory'
      },
      {
        name : 'blog4th',
        date : '2023-05-14',
        label: '4차 산업혁명에서 실시간 위치시스템의 역할',
        path : '/blog/4th'
      },
      */
      {
        name : 'blogManufacture',
        date : '2023-05-14',
        label: 'RTLS와 IoT가 제조 운영을 혁신하는 방법',
        path : '/blog/manufacture'
      },
      /*
      {
        name : 'blogCorona',
        date : '2023-05-13',
        label: 'RTLS(실시간 위치 서비스)가 포스트 코로나19 공급망에 다리를 구축하는 방법',
        path : '/blog/corona'
      },
      {
        name : 'blogIot',
        date : '2023-05-12',
        label: 'IoT 프로세스와 RTLS를 결합하여 수익 기회 창출',
        path : '/blog/iot'
      },


      // 6 - 10
      {
        name : 'blogAlone',
        date : '2023-05-12',
        label: '고독한 근로자를 위험에 빠뜨리지 않도록 보호',
        path : '/blog/alone'
      },
      */
      {
        name : 'blogAirport',
        date : '2023-05-10',
        label: '공항에서 사용되는 RTLS',
        path : '/blog/airport'
      },
      /*
      {
        name : 'blogUwbble',
        date : '2023-05-08',
        label: 'UWB vs BLE, 무엇이 다를까',
        path : '/blog/uwbble'
      },
      */
      {
        name : 'blogMedical',
        date : '2023-05-04',
        label: '의료 산업에서의 RTLS',
        path : '/blog/medical'
      },
      {
        name : 'blogGeofence',
        date : '2023-05-01',
        label: '지오팬스(Geofence)란 무엇이며 어떤 일을 할까요??',
        path : '/blog/geofence'
      },


      // 1 - 5
      /*
      {
        name : 'blogAsset',
        date : '2023-04-27',
        label: '실내/실외에서의 원활한 자산 및 프로세스 추적',
        path : '/blog/asset'
      },
      */
      {
        name : 'blogUwb',
        date : '2023-04-25',
        label: 'UWB란 무엇이며, 비지니스에 어떻게 도움이 될까요 ?',
        path : '/blog/uwb'
      },
      {
        name : 'blogDigitalTwin',
        date : '2023-04-22',
        label: '디지털 트윈과 실시간 위치추적 시스템',
        path : '/blog/digitalTwin'
      },
      /*
      {
        name : 'blogSafety',
        date : '2023-04-20',
        label: '산업 현장에서 위치 추적 기술의 역할이 노동자 안전에 미치는 영향',
        path : '/blog/safety'
      },
      */
      {
        name : 'blogRTLS',
        date : '2023-04-17',
        label: '실시간 위치추적시스템(RTLS)란?',
        path : '/blog/rtls'
      },
    ],

    back : 'ORBRO 블로그로 돌아가기',
    newletter : '뉴스레터 구독하기',
    info : '다양한 소식을 받아보세요!',
    placeholder : '이메일을 입력해주세요',
    subscribe : '구독하기',
    terms1: "정보를 제공함으로써",
    terms2: "Orbro의",
    terms3 : '개인정보처리방침',
    terms4 : '에 동의합니다.',

    more : '더보기',
    less : '접기',

    recent : '최신 블로그 글',
    share : '글이 마음에 드셨나요? <br/> 공유하기',

  }