import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4"

export default {


    helmet: '| 스마트 공장이란?',
    desc: '스마트 공장은 IoT 기술과 인공지능을 활용하여 생산 라인을 자동화하고 최적화하는 공장을 말합니다. 이를 통해 생산 프로세스의 효율성을 향상시키고 유지보수 시간을 줄이며, 생산량과 품질을 향상시키는데 기여합니다.',
    keywords: 'digital twin, 디지털 트윈, 스마트 공장, 스마트 팩토리, nvr, ip, ptz, rfid, nfc, uwb, 무선 통신, 위치 추적, 물체 위치 추적, 사람 위치 추적, 태그, 비콘, rtls, orbro, 오브로, 콩테크',

    ogTitle: '',
    ogDesc: '',
    ogSite: '스마트 공장이란? | 위치추적과 디지털 트윈 전문 플랫폼, 오브로',
    ogUrl: 'https://orbro.io/blog/what-is-a-smart-factory',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDlrNJZcN0y8rqN6rn4lIBsYzyCBFCLngoMNzRGAY6aBK05F5A7p7wMg7ZuILGtqR0GyG3qa5nWoqrTkhzMc8RRj0tMC2erUg8B95SfXjNpxqhWAlZI2ZNQ20zf6Bqvfx3ztoqtHXcz2hhYcN11wxSut__qBoWsflYyVOeQHJ35WsxS_wN8WaNJKzx-dhLVFusRshtRF7Zx4doNUOlm_KUYkgX0IUQ1IeB6yFe3WYvOyZC8sqY1FMnRAfO22PRRK4uVETtZYRhz1rrYA_xT7mbQP94D71TrIFaoebXtygq-yfvQOvJ4UfR6wr4I2M4V0oN6suppqQ8YAOYRD1WYR_cbQeuw_KsCJ2wLvHZYc2LaJwaDpP3CO-Ygqw0gsMrOMEtf5U1WEMf903qg20rF0D8hqpBPnjJRCQ6KY2leZG3Xv2k7q5gSOcKTnqSEQTe7DpWKAbnscWRoK7j1HGIopA0MWEyVIu5Ux9VhBZUSMEgfQkKoAX2UL71HTDwV7xf_AMP2cVdj7u49LCN1Pcdq-eKXMygl_EcCyGvoZ_wp4Bq7Uh1dbm7IJe5A2TaDKbuzgY9JL-ZFhxhwVES7tvZlWP-B_TeatTd2RKPQ6FMoeblJYuiAjHopqXL9JO8-y5wEcVgWwSo6_2W1AosdLSjlS2dqAE827GZP9HjudpoEd7lQXyP3bdXE6n3DzSuUOtextrsHpP6vN6tZ7q6krYTztpHpdIN8naMIOpdy4O2pdvv1uz-fhax_TyYVUEXg6ld7JVk-hPSvGPIAVUDpUx00G4uQgiBq8U0EZlVtIPynqiSn0VJjVTkArpnM_WX3E05A4Qwt-1AS3SagPFHUABELaSJyL1L34cKVxK_BLvKBZlGnfpC1LqOfmjS5iBamKqpm4ctvMkBIwLKcxad4eYpEH1H_yQ9AxantgCTB-GHWAdLrVk0cA3jMjJfx-OpQRCUEE2BSLWbuTCDEtJpMFwRzLdHzcRjg8qbJgjJTV7KeVZCHqn9DoghaNW2r_ON-IXwyfgNGPNPqeGx81epFjGIdFEjaCGAgIGdsth-CUeTGc5Q69DrT_PAuAYnbN3F7Bj1XLyz0Ie97ogOtcAHLzQwCjNd0Ll1vl2A35yNX905gQEctPSAY9e91nlTtH6KmIgLYgtpBt-w4xOQIXh4_z5hZulvwYKL2lh7Xt-qXqpV-PGYYbnGgz20mwGck4DxmCnXztmOUOMWkwvZ6BFpIoA7ZA2EntRJ8Z6hI17yexqq-384ja6SLh_3QqtEALCOtwyxIYCqwJsSFPyGPp17Pz1RyvB-j2RZ23GCX1YKYo6STGlKOmlYxmV0spVQQIQVgamCYHkKTDfSr3KFe2-SyaJEaPRoIansSf_NTtNvwC8RD0AMr2HYs7iQ35mFLUS5KF2dTLlah-AmnppP4WbuZ-4T0McWZKlfAFjR_nLifHSgTF8XTBEa9XMQsjhzP3c_SWzbqMOkYR82Q9zVg6wacsuo537pPMZn5oxZcSFNdLKsmywtFpS9Pg5dWlyWI7fgd7sn-lu7-vhp8j6iz36SG2OsnjAuOrlL92W_P9=w1984-h1208',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '스마트 공장이란?',
    date: '2024-01-12',
    tag1: '#스마트공장',
    tag2: '#스마트팩토리',
    tag3: '#디지털트윈',
    tag4: '#위치추적',

    // <br/>
    text: [
        {
            head: '스마트 공장이란?',
            subHead: '',
            desc: '스마트 공장은 IoT 기술과 인공지능을 활용하여 생산 라인을 자동화하고 최적화하는 공장을 말합니다. 이를 통해 생산 프로세스의 효율성을 향상시키고 유지보수 시간을 줄이며, 생산량과 품질을 향상시키는데 기여합니다. 이는 전통적인 제조업의 개념을 혁신하고, 생산 과정에서 발생하는 비용을 절감하며 생산품의 품질을 향상시킵니다.<br/><br/>스마트 공장은 현대 제조업에서 빠르게 확산되고 있는 혁신적인 생산 방식으로, 디지털 트윈과 위치추적 기술을 효과적으로 활용하여 생산성을 높이고 생산 프로세스를 최적화하는데 주목받고 있습니다.',
            dot: ''
        },
        {
            head: '스마트 공장의 기능',
            subHead: '',
            desc: '스마트 공장은 다양한 기술과 시스템을 통합하여 생산 과정을 지능적으로 관리하고 최적화하는데 중점을 두고 있습니다. 이를 통해 생산성을 향상시키고 비용을 절감하며 품질을 향상시킬 수 있습니다. 주요한 스마트 공장의 기능은 다음과 같습니다:',
            dot: {
                a : '<strong><br/> • 자동화 및 로봇 기술 : </strong>스마트 공장은 첨단 로봇과 자동화 시스템을 도입하여 생산 라인에서의 작업을 자동화합니다. 이는 노동 비용을 절감하고 생산성을 높이는데 기여합니다.',
                b : '<strong><br/> • 디지털 트윈 기술 : </strong>제품 또는 생산 프로세스의 디지털 트윈을 생성하여 현실 세계의 물리적인 객체를 디지털 환경으로 모델링합니다. 이를 통해 생산 프로세스를 실시간으로 모니터링하고 최적화할 수 있습니다.',
                c : '<strong><br/> • 사물인터넷 (IoT) 기술 : </strong>센서와 연결된 IoT 디바이스를 활용하여 생산 라인의 모든 기기와 장비를 네트워크로 연결합니다. 이로써 데이터를 실시간으로 수집하고 생산 프로세스를 모니터링하여 효율적으로 관리할 수 있습니다.',
                d : '<strong><br/> • 위치추적 기술 : </strong>RFID나 GPS를 통한 위치추적 기술을 활용하여 원자재, 제품, 작업자 등의 위치를 실시간으로 추적합니다. 이는 생산라인에서의 자원 관리와 효율적인 작업 배치에 도움을 줍니다.',
                e : '<strong><br/> • 환경 모니터링 및 에너지 효율화 : </strong>스마트 공장은 생산 과정에서 발생하는 에너지 소비 및 환경 영향을 모니터링하고 최소화하는데 주력합니다. 환경에 대한 지속 가능한 관리를 통해 친환경적인 생산 방식을 추구합니다.',
                f : '<strong><br/> • 클라우드 컴퓨팅 : </strong>생산 데이터를 클라우드에 저장하고 관리하여 지리적으로 떨어진 위치에서도 실시간으로 데이터에 접근하고 공유할 수 있습니다. 다양한 지점 간의 협업을 원활하게 지원합니다.',
            }
        },
        {
            video : video,
            link : '/enterprise/manufacturing/process',
            videoDesc : '(스마트 팩토리 솔루션, 클릭하면 이동합니다.)',
            head: '스마트 공장 장점',
            subHead: '생산성 향상',
            desc: '자동화 및 로봇화를 도입하여 반복적이고 노동 집약적인 작업을 자동화함으로써 생산성을 향상시킵니다. 또한, 디지털 트윈을 활용하여 생산 프로세스를 실시간으로 모니터링하고 최적화함으로써 생산 효율성을 높입니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '품질 향상',
            desc: '센서 및 IoT 기술을 사용하여 생산 라인에서 발생하는 데이터를 수집하고 분석함으로써 제품 생산 프로세스를 모니터링합니다. 이를 통해 불량률을 감소시키고 제품 품질을 향상시킵니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '비용 절감',
            desc: '자동화된 시스템을 도입하여 노동력 비용을 절감하고, 빅데이터 분석을 통해 예측 정비를 실시하여 유지보수 비용을 최소화합니다. 또한, 생산 프로세스의 최적화를 통해 자원 사용 효율을 높여 비용을 절감할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '유연성과 빠른 변화 대응',
            desc: '디지털 트윈과 스마트 제조 시스템을 통해 생산 라인을 더욱 유연하게 조절하고 변경할 수 있습니다. 이는 다양한 제품의 생산 라인을 신속하게 구축하거나 수정할 수 있는 능력을 제공합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '실시간 모니터링과 의사 결정',
            desc: '센서 데이터와 IoT 기술을 통해 실시간으로 생산 환경의 데이터를 수집하고, 빅데이터 분석 및 AI 기술을 활용하여 이를 신속하게 분석합니다. 이로써 생산 라인의 상태를 실시간으로 파악하고 의사 결정을 신속하게 내릴 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '자원 효율화',
            desc: '스마트 공장은 에너지 효율적인 생산 방식을 도입하여 에너지 소비를 최소화하고, 빅데이터와 AI를 활용하여 자원의 효율적인 사용을 모니터링하고 최적화합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '친환경 생산',
            desc: '생산 프로세스의 친환경적인 설계와 에너지 효율적인 생산 방식을 통해 친환경적인 생산 환경을 조성합니다. 이는 기업의 지속 가능성을 높이고 환경 영향을 최소화하는 데 도움이 됩니다.',
            dot: ''
        },
        {
            head: '스마트 공장 사례',
            subHead: '',
            desc: '',
            dot: {
                a : '<strong>• 토요타 (Toyota) : </strong>토요타는 스마트 제조의 선두주자로 꼽힙니다. 그들은 디지털 트윈, 로봇 공학, 인공지능 등의 첨단 기술을 적극적으로 도입하여 제조 프로세스를 혁신하고 있습니다. 예를 들어, 고급 로봇이 작업자와 공존하며 작업을 수행하고, 디지털 트윈을 사용하여 생산 프로세스를 실시간으로 모니터링하고 최적화하고 있습니다.',
                b : '<strong>• Bosch 스마트 공장 : </strong>Bosch는 자동차 및 산업 기술 분야에서 스마트 공장을 구축하고 있습니다. 디지털 트윈과 IoT를 통합하여 제조 프로세스를 최적화하고, 위치추적 기술을 활용하여 원자재 및 제품의 이동을 실시간으로 추적하고 효율적으로 관리하고 있습니다.',
                c : '<strong>• GE 스마트 제조 시설 : </strong>일렉트릭 파워, 리뉴얼블 에너지, 건강 관리 등 다양한 분야에서 활동하는 GE는 스마트 제조 기술을 적극적으로 도입하고 있습니다. 디지털 트윈과 IoT를 활용하여 생산 설비의 상태를 모니터링하고, 빅데이터 분석을 통해 예측 정비를 수행하여 생산 효율성을 극대화하고 있습니다.',
            }
        },
        {
            head: '스마트 공장 구축 방법',
            subHead: '1. 비전 수립과 전략 계획:',
            desc: '스마트 공장을 구축하기 전에 기업은 스마트 공장의 비전을 수립하고 전략을 계획해야 합니다. 어떤 기술을 도입하고 어떤 목표를 달성하고자 하는지를 명확히 정의합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '2. 현황 분석과 요구사항 정의:',
            desc: '현재의 생산 시스템 및 기술 수준을 분석하고 스마트 공장 도입에 필요한 요구사항을 정의합니다. 생산 프로세스의 강점과 약점을 파악하고 개선이 필요한 부분을 식별합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '3. 기술 선택 및 통합:',
            desc: '디지털 트윈, IoT, 빅데이터 분석, 인공지능, 자동화 등 스마트 공장을 구성하는 다양한 기술 중 어떤 것을 도입할지 선택합니다. 이후 이러한 기술들을 효율적으로 통합하여 사용할 수 있는 시스템을 구축합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '4. 자동화 및 로봇화 도입:',
            desc: '생산 라인의 일부 또는 전체를 자동화하고 로봇을 도입하여 노동 집약적인 작업을 자동으로 수행하도록 합니다. 이는 생산성을 향상시키고',
            dot: ''
        },
        {
            head: '',
            subHead: '5. 디지털 트윈 구축:',
            desc: '제품이나 생산 프로세스의 디지털 트윈을 생성합니다. 이는 현실 세계의 물리적인 오브젝트나 프로세스를 디지털로 복제하여 모니터링하고 최적화하는 데 사용됩니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '6. 사물인터넷 (IoT) 기술 도입:',
            desc: '센서 및 IoT 기술을 도입하여 생산 라인의 모든 기기와 장비를 연결하고 데이터를 실시간으로 수집합니다. 이를 통해 생산 프로세스를 모니터링하고 최적화할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '7. 위치추적 기술 도입:',
            desc: 'RFID, GPS, UWB RTLS 등의 위치추적 기술을 도입하여 생산물, 자원, 작업자의 위치를 실시간으로 추적하고 최적의 자원 배치를 수행합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '8. 빅데이터 및 인공지능 (AI) 적용:',
            desc: '생산 데이터를 수집하고 분석하기 위해 빅데이터 기술을 활용하며, AI를 사용하여 데이터에서 패턴을 찾고 예측 분석을 수행합니다. 이를 통해 생산 프로세스를 최적화하고 예측 정비를 진행할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '9. 클라우드 컴퓨팅 도입:',
            desc: '생산 데이터를 클라우드에 저장하고 처리하여 실시간으로 데이터에 접근하고 중앙에서 모니터링할 수 있도록 합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '10. 보안 강화:',
            desc: '스마트 공장에서는 많은 데이터가 교환되기 때문에 보안이 매우 중요합니다. 적절한 보안 시스템을 도입하여 데이터의 무결성과 기밀성을 보장합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '11. 교육 및 인프라 개선:',
            desc: '직원들에 대한 교육을 실시하여 새로운 기술 및 시스템을 효과적으로 사용할 수 있도록 합니다. 또한, 필요한 경우 인프라를 개선하여 스마트 공장 구축을 지원합니다.',
            dot: ''
        },
        
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/enterprise/medical/patient'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/enterprise/retail/exhibition'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/enterprise/office/asset'
            }
        ]

    }


}