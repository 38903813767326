import {  medicalPatient,  officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {

  helmet: '| 什么是 RFID？',
  desc: 'RFID 是“Radio-Frequency Identification”的缩写，指的是无线射频识别技术，通过无线通信传输和接收数据，以唯一标识和管理物体。',
  keywords: 'rfid、nfc、uwb、无线通信、位置追踪、物体位置追踪、人物位置追踪、标签、信标、rtls、orbro、contech',

  ogTitle: '',
  ogDesc: '',
  ogSite: '什么是 RFID？ | 专业的位置追踪和数字孪生平台，Orbro',
  ogUrl: 'https://orbro.io/cn/blog/rfid',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDnHsX6sdpDkHidg6Qw1BNPI-8KHNunrbQX70t-RRiZFc_m0TXoYRKa1E9Qo01-Au848tK9S5Jzx1vOsreK1FoM7_WUn-aUPsNq2SoW1p0bgotbqEic-B-shh4JqyX1vRGz1BKJuuNybUGEV0Sntx7H_5LpXctuKvQDlllya8Qrt86WPh2Z8-5f8fKO1zJH2O8i96n9h1SvE8MHibKQqZzHRsPx4JboT3-j7Dq6nMb3Gsk-bMZkqaQVCKMuKmSG9s28DFXjSr2dDlwKXTgw6HPGwcGijF3sdo52c8MtyGSFhBtgtmGbjidDLlTEoYy5sTjCUl9KoRk5SbaOqCb-WHfrMzvlUE-KzSkFspk4Qe_oDLl4U2mcOBOC6tpykKzDDNY3P-si0yNBW_3kHTZd8ITgkwKE1AwK0hHOdS0FB7_AvrfgQW1VAIpkjYlJFHXsbP3Nac0v-XjWu9W-fozT8vIJC3P5c5imd_plMzSJorI4po9xbiqNGReeiJpbzilK2ym2B2i8MZT6w2SRjdf2nm2SU4Ru4aZR60ahb8bagWe_xc_ZzKNmdlZu5Avvkyc3_fUGnnqw3d3xcwaPdtRhYQul_0LDKxsgKrBjv2Co6CztB1mX2Et8-F5j68r2F9qy1yj6abH08yQokpafvTmxUsBYZ_i4OAw30nPlQkXflKfWFTob2y_o-X_FvVTGl4qerYTVBQLafVq7aSdlwkNpTvzZbXYkJMJasDN8JRO6mBJ8WhoJm9GOF5z75doXv-ds7jiNur5KLdA_UC2SR6-GpDrQPew6gUqn_1NvNGCXxkH9skkPX6gIuzc-9EAaXkSNajXWEc9_cRppxNODr4m1x_rl00fnHmVra7BhMXTjEgsB7rUJJw9OdKbTvB565TUXJXGMIfiFUAJfYBgm3FRZfJobff73T4LEa7d6e-znqcf64EINs_qnz2n-6yqf6_8Ww7A3P2K_iKCJymzKYy-dmjnxIW7c_msNP3sQZ0Ev0CpMW2neef16CpS2qPJjSc1Hv977oz4JSkrCL2GJ3RIUILnczDL8Ww96raGuIs058uNZu-pJwbIEbr9995dIPiHY-a6wstUW8xKthzZmdCFJ2JInX_Mos6jxXNorixlMOiseVcCXzuEcH_QEXGn9Mj7GzKkhAwgYeEq3D7EMlwjQ56-8mACmRW1ein59VVTW8JoXlBZ0enRBVmONwC4Y0aUUc_Hn70Zbktx4y1pKsehMCWy3GnGikZej6coDNKuKPnv8VE2ZBfCy8QdoI0i4P9rH2zFPqJWJEEgYmy3MpmnS5Asf8f1aScgjVS1S06wmBN_9Lpa-3cUJ-epljLU28BaHQWk9f2z4XojkWBWXVnGCidPhbxH-Cx4l-mNjXL8bh9PixgnFknFkWbwaqqGfjgS-GvOPqF0uGlIlEavfeovi1d_1opq7fxuM6wc-y17eImZEycGDKQuMRds6xvylNau1IgJTWCxWfnHArzBoJMxU91IlULuYkMFPAmoA-U2JjLAeiz35GX1eHmMQ3k_2JZmlfIvGQ73K2zf9ErNr-QzQPqixYph1iRaJi=w1984-h1214',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '什么是 RFID？',
  date: '2024-01-11',
  tag1: '#rfid',
  tag2: '#rtls',
  tag3: '#无线通信',
  tag4: '#位置追踪',

  // <br/>
  text: [
    {
      head: '什么是 RFID？',
      subHead: '',
      desc: 'RFID 是“Radio-Frequency Identification”的缩写，指的是无线射频识别技术，通过无线通信传输和接收数据，以唯一标识和管理物体。<br/>RFID 系统通常由标签、阅读器（或询问器）和数据库组成。 RFID 系统的关键组件标签由小芯片和天线组成。该芯片附在物体上或内嵌在物体中，而天线负责无线信号的传输和接收。阅读器通过天线与标签通信，读取或写入数据，并将此信息连接到数据库，实时跟踪或管理信息。',
      dot: ''
    },
    {
      head: 'RFID 的应用案例',
      subHead: '',
      desc: 'RFID 技术在各行业广泛应用。以下是 RFID 的一些主要应用案例。',
      dot: ''
    },
    {
      head: '',
      subHead: '分销和物流管理',
      desc: '',
      dot: {
        a : '<strong> • 库存管理： </strong>使用 RFID 为产品附加标签，并通过阅读器实时跟踪库存，提供准确的库存信息。',
        b : '<strong> • 自动物流处理： </strong>通过自动化在物流中心运输和分类产品的过程，提高物流操作效率。',
      }
    },
    {
      head: '',
      subHead: '制造业',
      desc: '',
      dot: {
        a : '<strong> • 产品追踪和生产管理： </strong>制造商通过为产品附加 RFID 标签，追踪生产线并在生产和发货阶段进行实时监控。',
      }
    },
    {
      head: '',
      subHead: '零售',
      desc: '',
      dot: {
        a : '<strong> • 智能商店： </strong>使用 RFID 技术实现智能商店，当客户直接拿走产品时自动生成发票和支付。',
        b : '<strong> • 提升客户体验： </strong>利用 RFID 使客户更容易找到产品，或者自动应用折扣和促销以提升客户体验。',
      }
    },
    {
      head: '',
      subHead: '医疗和医院管理',
      desc: '',
      dot: {
        a : '<strong> • 患者识别和医疗设备管理： </strong>使用 RFID 精确识别患者，并监控医疗设备的位置和状态，以提高医疗机构的效率。',
        b : '<strong> • 药品追踪： </strong>加强从制造到患者交付的药品的追踪，以确保安全性。',
      }
    },
    {
      head: '',
      subHead: '车辆和交通系统',
      desc: '',
      dot: {
        a : '<strong> • 自动收费： </strong>使用 RFID 自动收费并跟踪车辆以减少交通拥堵。',
        b : '<strong> • 车辆管理和维护： </strong>通过为车辆附加 RFID 标签，追踪维护历史并监控车辆位置，以支持高效运营。',
      }
    },
    {
      head: 'RFID 的优势',
      subHead: '自动化和实时追踪',
      desc: 'RFID 可以迅速无线识别和收集物体数据，比手动过程更快。此外，RFID 还可以实时追踪物体的位置和状态，从而在物流、生产和库存管理方面实现高效运作。',
      dot: ''
    },
    {
      head: '',
      subHead: '准确的数据收集',
      desc: '与条形码扫描相比，RFID 可以更快速、更准确地收集数据，从而减少数据输入错误。即使在没有视觉可见性的情况下，RFID 也能够自动识别物体。',
      dot: ''
    },
    {
      head: '',
      subHead: '视觉和可见性的提升',
      desc: '利用 RFID，可以透明地跟踪整个供应链中物体的移动，从而提高可见性。通过在生产线或仓库中使用 RFID，可以实时监控工作状态。',
      dot: ''
    },
    {
      head: '',
      subHead: '加强安全性',
      desc: 'RFID 可以检测物体的非法移动，并应用于需要安全的区域，以防止未经授权的访问。RFID 还可以为标签分配个别权限，以确保只有特定用户或群体可以访问。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '通过安装 UWB 终端在工人或设备上，实时支持位置追踪的产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/cn/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/cn/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/cn/enterprise/office/asset'
      }
    ]

  }

}
