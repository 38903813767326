import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| スマート物流倉庫が産業をリードする理由',
  desc : 'スマート倉庫は、リアルタイムの在庫追跡によって物流プロセスを最適化し、効率を最大化することで、産業をリードしています。これは正確な在庫追跡と効率的な在庫移動によって、コスト削減と顧客サービスレベルの向上を実現することができるためです。',
  keywords : '大型物流倉庫管理·物流センター管理·物流倉庫管理·スマート物流·在庫管理·スマート在庫管理·物流倉庫在庫管理·大型物流倉庫在庫管理',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'スマート物流倉庫が産業をリードする理由 :: ORBRO Blog',
  ogUrl : 'https://orbro.io/jp/blog/smartLogistics',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4NDUS2r1YTByIJmtp6T8gd_h-Y0FvrgnSd4VlXMvLXHrcsoDQuYCCpyNiEnagSHA56J_cXX6ma4jVfg2btnQwzHH1T8dty-PlOi09CLRr0QCFPG5Y4vVqfn7C5PnTTsR0x0nbT2ON5OhY0dk5d-xXzCLE6vUBjcUa8fBILF1gIuf_N84MNO3N-VeWiksJFzVJc-xgHqholB4vdavvEz7lQXy-2H0Bd4mRgkpdEB_TKat6ecFPI1Iicj74Quxd7X80Jsf4K-0CLRxPmsRzqHK-9OTe65ZtvBh6uCdL9EkTWgEqIhYkBZurnVB2Xu1w5ehFm0YWCO4mUMXNlZ-Dl5zLrTMwASpHVsXgOer9HduJZ_tpy7yNSd1_eqR0YtzmRoll-I0lVS6kZFRtI8DFYaLVzENwJgYYzd7g6722BEKL9hnLql3Q5hQpwBGPeiSfZBLuwjIxTUqbuTlemEXD9BykaU6SchggZ0JNi9oy4MVSmKjvnHMAInxjwv2dhy0MyVb7f8gdr8lK41OhMhRx4OiMYRfCoJjXc6Ee9DOX-Z7g2HReiWKhMK4gGpS9wKp_fsj9PATynJFs2AJY8G1c4G84Vqpv7jFwYbpgyp11npXaIaG12FHyF7hBdvDtwmWQ2Ebb1M7Fl2CEQFB7GZREh7wslvCX6Tst9-y6HysrikAuUYviPj_IFrmp4eDDGbQIznKzXVpeegI-fbzkPhiPv5MREXUt_gCYR_CKVy916MV0gpoSXeKYuSjDEih9ebiLmq-kpGGAb6Cl3G9s5zsiWOZLaZlZKjoDCI9Gujoy7FTRDGFM3r6HaIFY7mehUppvhCS_9nsSGgk32eoWdThQu3oUDCJMbf4vDIWM7bBa6LW4_OqliCAqREvMwSx8EezvOQf01DLRZ1UMbWyA_QQl4qIa_g7P8_NAUSWbFSZf2rjA5WRMJ12rKhQrUsxiSTUOnFjee6CSe693z1ccTi0XCbjaPdu7LEg9Mbcb2wmJ-0Jcxjmggf_jEWTjwwGIGUrzcIPJ0PqDXNiCW5esg5ksxHkcunw8ra44iq0DjQx8vE2BZ62e4i5meo_tR91eEqPecKgYFSVnNfBmE7mPfBo5W-Y_rrffkTnIQxlgR8zFUselSqB-KFuai7uIoAniJzBPc8ReLT7OJzprQQyMgdDtCW3epD-bbknMF4stChNDuYgGa85BsShBc3_pEuvsoxINtMIUXtpn5s7IS18mmnVLyK8C-jqpisEXwk8A4CZjv3tU4GWJyb_ZZoL9ZpCb6h6w4Brla59Ia3dRU-ZUKtVXg7CNzMdllUkdXV64VHxjSmpvNtAJ92aE_qUOj8Q1BOwQuAh71_yBTW0zKAxhme_coBD55dETFQakhVS484NQqw2sj2Ypm7qUDu6UWHB8TZVpogTjCpoKhjT84dNSVIMMqHk6UT-4BiaXv1NMyFb6LtFhGmbUO8iEX1n9RsO2Sn5bNAwjs8aFmh_P5eX9Qfj3HJ5YX_ChNlBlkwTDgdj_V3_uJm_DErrCc4YCi_ODmOv_0Gj-2o3wiULGzOPSZQdtnhCSOCNOEokcoQSRMcy1l4IBEW5Hs=w1470-h1233',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'スマート物流倉庫が産業をリードする理由',
  date : '2023-08-03',
  tag1 : '#大型物流センター',
  tag2 : '#スマート物流',
  tag3 : '#在庫管理',
  tag4 : '#スマート倉庫',
    
  // <br/>
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'在庫管理は物流センター供給チェーンの核心です。したがって、ビジネスを成長させたい物流責任者たちは、より効率的な在庫管理を模索しています。もちろん、在庫管理だけでなく、全体的な物流システムの効率と生産性を高めるために、最もスマートな方法を見つけようとしています。<br/>最近、リアルタイム位置追跡システムであるRTLS（Real-time Location System）の進化により、物流倉庫も以前とは異なる形態のシステムを構築できるようになりました。<br/>これで物流倉庫内のすべてを追跡できます。物流倉庫と同じような地図上に在庫、作業者、フォークリフトなどの位置がリアルタイムで表示されます。ほぼ誤差のない精巧な技術により、現場のすべてを一目で把握できるようになりました。これは単なる位置追跡を超え、さまざまな機能として活用される可能性を持っています。<br/><br/>以下では、これによって得られるさまざまな機能について説明します。',
      dot : ''
      
    },
    {
      head : '在庫位置把握',
      subHead :'',
      desc : '物流倉庫内にある在庫の位置を把握できます。エリアを選択すると、該当エリアに存在する在庫の位置と数量を把握できます。もし探したい在庫がある場合は、検索機能を利用して倉庫内のどこに位置しているかを把握できます。移動中の在庫なら、リアルタイムで動線を把握できます。品目の人気度に応じて在庫を配置し、在庫回転率を最適化できるため、コスト削減や在庫不足や過剰を防止するのに役立ちます。そして正確な在庫位置情報を持っていれば、物流作業者は商品を迅速に見つけ出し、必要な位置へ移動させることができます。これにより作業時間を短縮し、生産性を向上させることができます。',
      dot : ''
      
    },
    {
      head : '入出庫自動化',
      subHead :'',
      desc : '事前に指定された地点を通過する在庫はリアルタイムで入出庫時間が記録されます。 このデータは正確であるため、人のミスを防止し、人員を節約することができます。また、このデータはクラウドに保存されるため、手間のかかる管理や紛失のリスクがありません。',
      dot : ''
      
    },
    {
      head : 'リードタイム短縮',
      subHead :'',
      desc : 'リアルタイム在庫追跡を通じてより速いリードタイムを実現できます。在庫位置を正確に把握するため、必要な在庫を迅速に見つけ出し、生産または配送に必要な時間を短縮することができます。',
      dot : ''
      
    },
    {
      head : '安全管理',
      subHead :'1. 作業者追跡',
      desc : '作業者の位置をリアルタイムで追跡して安全状況をモニタリングし、危険な領域に接近したり、緊急事態が発生した場合に迅速に対応できるようサポートします。これにより潜在的なリスクを事前に防止します。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'2. 危険区域設定',
      desc : '現場で接近してはいけない場所や注意すべき場所を事前に設定し、近くを通過する作業者に警告したり、管理者に通知を送ることができます。これにより事故を予防できます。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'3. フォークリフト管理',
      desc : '安全に注意すべきは人だけではありません。潜在的な事故リスクがある重機の位置をリアルタイムで把握して、作業者が接近したり、誤った経路で入る場合を把握して事故を予防できます。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'4. 資産管理',
      desc : '在庫損失と盗難を防止するのに役立ちます。在庫が各位置に割り当てられ、リアルタイムで追跡されると在庫の損失や盗難を迅速に検知できます。これにより在庫損失を最小限に抑え、回収措置を行うことができます。',
      dot : ''
      
    },

  ],


  link : {
    page : 'rtls',
    numb : '1',
    product : [
      {
        text : productPurchase.purchase.asset.assetTracking.title,
        title : productPurchase.purchase.asset.assetTracking.title,
        serial : productPurchase.purchase.asset.assetTracking.serial,
        desc: 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
        img : productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution : [
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/jp/enterprise/logistics/asset'
      },
      {
        img : logisticsSafety.thumb,
        data : 'logisticsSafety',
        link : '/jp/enterprise/logistics/safety'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/jp/enterprise/manufacturing/asset'
      }
    ]

  }


}