import styled from "@emotion/styled";



export const ExmaImg = styled.img`
    width: 100%;
    height: 300px;
    object-fit : cover;
    border-radius : 8px;

    cursor : pointer;
    transition: transform 0.4s ease;

    &:hover {
        transform : scale(105%);
    }
`;