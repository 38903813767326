import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

   
  helmet : '',
  desc : 'RTLS solutions are an excellent choice for driving business growth in the era of the Fourth Industrial Revolution. Therefore, it is important to choose the right RTLS solution. Here are some considerations to keep in mind when selecting an RTLS solution.',
  keywords : 'RTLS, Fourth Industrial Revolution, business growth, location tracking, indoor positioning',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'Considerations When Choosing RTLS Solutions :: Orbro Blog',
  ogUrl : 'https://orbro.io/en/blog/consider',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZcwlDQZU5H5qnmKjPCdFPeFV8_CDJMRUQZgpa1f1gRZkrk-n6YpwPszumPmKOPJnJFIlJShKqh_Q5wFcv3gmUpnEWmtobQ0b3vFCWKOqFl_3v4hkpS_t9J8Xii17KJEpKeSLt8T_ieoIYrWTgkSw35wq4vyGsWME_m4RNxlrtv45F_VObAxOwJ2GUEWq1wC4Bs4iSvdnNVnw7beJQ6VDOIpKyialic3nfvYBuzj7Cg7a1ZYNEz91Gw4ifCkDL5XMf6yIg9It_aR4VGT_qnUW5E1tAjOeYZvjX16A8i_6CapXmgXxT18PWHgjLwU5B7aLKeBiLI4OfYkeru88hyuTajrjyWXAMAexmkeM5hK9gE6163DFPgr7dn3EpQnHhwvl537Gw-aeQAgEoMXoKNNfjmhnoGbjBur1yDSA81JEh2fujJMtlIwoVQQYgeHQhvqh5ZbNAc1kJWx3u8Vu85TMLDudxXtCmkAAsJpEnKsK8H0f8FOKZnAnWvHCjZOfyaQVDCFqL5G-NBcjQl5f_XbGdizmytaouj5Qh7_bD-oWnmEzXhhIzW47zMmDei8tGOR6Mf3tj2Yc7rgoIV5u6sM-u7rkrwtIUq3ZDcLi4bjM3CI0nPMqFQKAnnSK3WuDHZi5gI7lgg4Z2fpGM446qKEFp7RlMo40wdQ3dDGM4onXDAOn8nsbvAD8RpJY6Oh88j_CclPLbtwl8MgE2mMC0lq3t9fPP6FKz1rc894KGlbpYh-CxCrSHow-eGoM5WzKfGCDI45s0Pd304BDuBsizABdkcHVyUuLIefrfFdm1xB4Dx42ostYrLcccYZbim5zxXfiDXFHxeydWnEaR_3JlR0yLvJ1YxiuPWEQJ39vr6QFMQh6vOM9-hIG5V2W9tZl1BldMwlHpqfXqfHX8QxTHXYKNVJ6okDuOqQBSoXQNnZyi45gSvhXcAFGXDnHVj07MF4L6OTTd9iGg8xBCKBnlB4glo7twHHpVLj2LTKNkQQoNsDTtWsTCqPeXsAGgwshNgiKy-SEHSPR4kzyBhzgs8mhVNGvnAKUDr_p9iOH56Ng_3rWMKm9eF2wubbi5ffi4M6O5SnQYfTqYo9ZN0fBibaVoDzfSxXS6sBZGsfWbmgHxVi5Nsmq6D-HCo6yfecXsHiCemyaUS2VxqGt6Sxq_uN4FeFNCeOUUXfUro_qyrrs8cnSxmC3jetAkdPXpA_KIukOkttU1TDuvHOKiRRd48YccqjhRT1LRh5iIRBYiowrDQi7ewnWTYajwzWPUqm7ygPvZR9qYdK2l76JMFxA8Ucznef6UP-ApuWqRaYpANDZYzkoussTQhGn2mtDmbH41EMoGLY7a8G9r9ghA4YWVFM9hb3U2YLujjDmKFRnntRhskbgxpOK01aYvoDSGH5iKKj3K6ggNfC9QqvCX31JrrJadskNS_1lsqfgZYTVRlQ4-Vhx48TXtAo-rpwhyFWECUbGgjVzThWzmNQheWVWbRH1WoNx5nnnL6skFUK2y-QqdTJPm_XtkUqwkblpJvRGIxrV53uYlfug204LpUQfGgcIRn5zCsmN7KyyQ9ANKRMygJmQ=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
  title: 'Considerations When Choosing RTLS Solutions',
  date : '2023-05-19',
  tag1 : '#RTLS',
  tag2 : '#FourthIndustrialRevolution',
  tag3 : '#businessgrowth',
  tag4 : '#locationtracking',

  // <br/>
  text : [
    {
      head : '',
      subHead :'',
      desc : 'The advent of the Fourth Industrial Revolution, Industry 4.0, has brought about radical changes in product manufacturing, improvement, and distribution methods.<br/>Among the forefront, many companies are adopting RTLS as a new solution. This technology has begun to play a central role in many services, along with rapid growth.',
      dot : ''
      
    },
    {
      head : 'What is RTLS?',
      subHead :'',
      desc : 'RTLS stands for Real-Time Location System. The data collected through RTLS technology can be used to create a virtual map of the real world. This system processes the data in an easy-to-understand format, providing meaningful data to administrators.',
      dot : ''
      
    },
    {
      head : 'Benefits of RTLS',
      subHead :'',
      desc : 'Companies that implement RTLS solutions can gain significant advantages over those that do not. They can develop workforce allocation, asset management and tracking, and safety protocols. Additionally, RTLS can communicate with other IoT devices, enabling the effective implementation of various services. RTLS can also be utilized in diverse industries such as retail, hospitality, and healthcare. For example, hospitals can use RTLS to track staff availability, areas that are understaffed or overcrowded, and the location of medical equipment or medications.<br/><br/>These wide-ranging applications have turned RTLS into a rapidly growing market. According to "futuremarketinsights," the RTLS market, which was valued at $4.6 billion in 2022, is projected to reach $37.3 billion by 2032.',
      dot : ''
      
    },
    {
      head : 'Considerations for RTLS Implementation',
      subHead :'',
      desc : 'When implementing RTLS, it is crucial to carefully differentiate and plan your business requirements. The following are considerations to ensure the efficient implementation of RTLS.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'Personal Needs',
      desc : 'While traditional GPS can provide real-time location information for assets anywhere in the world, it is inaccurate within a range of 5 to 20 meters, making it impractical for indoor environments. RFID offers high accuracy but has limited range, requiring the use of thousands of receiver tags in large facilities. Among other RTLS technologies, including Wi-Fi, each has its own advantages and disadvantages.<br/><br/>Therefore, when selecting an RTLS for your facility, consider the following:',
      dot : {
        a :' • <strong>Targets</strong>: Are you tracking people, assets, or both? Will you track them through wearable tags or mobile devices? How many devices do you intend to track?',
        b :' • <strong>Accuracy</strong>: How accurate does your RTLS need to be? Is it sufficient to know if an asset is in a specific area, or do you need the precise location of the asset within 1 meter or less?',
        c :' • <strong>Latency</strong>: How real-time does your tracking need to be? Can you set a latency requirement of 10-20 seconds to align with your needs, or do you require greater accuracy?',
      }
    },
    {
      head : '',
      subHead :'Finding a Suitable Vendor',
      desc : 'The success of your technology implementation depends on selecting the right vendor. An RTLS company should have a proven track record and an understanding of your requirements. A good vendor should also provide extensive training on how to use their support when issues arise. Additionally, it is important to choose an RTLS solution that is future-proof and scalable, keeping up with the pace of RTLS technology evolution and growth, and its ability to be applied to your business.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'Budget and ROI',
      desc : 'Determining the budget for an RTLS system can be a complex process, especially when it requires approval from other stakeholders in the business. Start by itemizing the costs for each component of the project, such as procurement of tracking tags and locators, installation costs, IT integration costs, cloud storage, and ongoing operational costs such as general maintenance.<br/>After establishing a budget for the RTLS installation and operation, determine the expected return on investment (ROI) over the next 10 years. Total cost of ownership (TCO) calculations should consider not only additional revenue that the company can generate through increased productivity but also operational cost savings due to efficiency improvements.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'',
      desc : '',
      dot : ''
      
    },
  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/en/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/en/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/en/enterprise/logistics/asset'
      }
    ]


  }
  
  
  
}