import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: 'RTLS and IoT bring innovative changes to the manufacturing industry. They enable businesses to grow with more efficient and accurate asset management and tracking.',
  keywords: 'RTLS, IoT, manufacturing industry, manufacturing, asset management, asset tracking, location tracking, worker management, labor management, work management, indoor location tracking, GPS',
  
  ogTitle: '',
  ogSite: 'How RTLS and IoT Revolutionize Manufacturing Operations :: Orbro Blog',
  ogUrl: 'https://orbro.io/en/blog/manufacture',
  ogDesc: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EOa6daqBDghCNor8iv-YzX1WxKH5TOKtIuZ9Iom5tWnX1rsigsNIOwNLoDBlg70uCuj1H-sg75MSHvBgEva42vfGoepNWA-Iw509j1WfkjEYFSyInBvchHQrT16H7m2qJIKHJMkYZIS2k9KhzJE-hoQ86Y9voT53nZQA7VyJOfQWTxxaUy1bjjnVKrn8H0GEP9W_85velE96d8kXtIjOWmjV6SDkKVxEnl_N1NejohAfWmQhvVCJVnlf7cPcn-5DB4LMdtZGSAGRVWLSXzygG8F8nP4b3YJTMGE4WZBoePBa0hM4SBgT7SpykyGlPzUay6TifllWLBvuvWKh9LCb0JZtYebb8isWpkOIERN0tmDCM_tBjsvibo27ZyP1yRfpmLKfsoNtpZOHY9NP9eHQnUAhLg1clyaBjR-hxMKhMXk2AQUhS1OWIcsN_tLo36LQyNQAeChEpFUJLr0hc0aXxrscDRHI4S80-5hqCmjPcfUERM-fKyyK6SfFJrEBtyCDbqhWgGuU8BOUvkcnPc68hRsSBXjkiZILl0tjKmrWzJe2UUBBVyduU__R3dktNC0FGRPLrkx9XavkeA3B5aZUCOggV9AjAUklOCEml0dfPOyCg-eKwU196LAIC6HdS-ja1H_q7aBURgW9RAK_jGYJmWGzfUPt9ZndbM4G7khUvJU4eWKvYkXFsP0QlkOszc2q3LD9zWxMFrjzbuAs7Gnus4QHrCk0VoS5WYhGBgDLuYfph53E3g6vKm3Z8VH1oGnD_MoZdX3NjdUmLbm2aN8qGe0uvq57HPT2AOUVTC-ntXkT2560j9Y1TnPzHczlkmaRCzxxMHdufPs1Xv147yCml7lz-IY3_3tYh88JhPpWKYMwsZ0gmDMD-k58uVl6M2nu2U2ZnDBIhQR5N2XG5W6-45lXcdsSMQ249MAkTUAoO1V3qu3l66NcOZ4f2K4eL8pHl1OgPuLlgp5xvHaaWG4DJP9-uOps1wYhRqnDZWGfsQaoNH27x3JwRvkKIoSojHEQ28-GqOxo7DHwZDbwGtSzAnh-oHGzqpbUgbtcUr9Urk8Kr4YZh5GbMrmwZLXAl5CyIzCxfCxgKHvxkRgKL81zT3bcX4n-L9seXjGliNOO9sUax87u662hezKTLkVnx4a0olXqQm4PuCUpdF_AO187KE0-8fqEHktIFUy2WujCmXpbyKXwj63VakYRGmnTF2DcVJ1F7lkdio4CGmBLhtQoQeXPabn1aRJ-QeuvDLFBO27AGJbhszDTGjIjQaha7Hp1Btx_AKN0NEdYl2PiFBF1y9QB1JCiexGRPeVBWBSnvf_FE69IkFXqkSkIi1kPKlA-ycurt5-uYVPe3ejmtTq54EHjy8uEBAyQ74EY0dCuSVc1zMRZPYot0mmlcxDw7oRtWRpzHRl2tudqm9bwTriTBMAjd101CAjfkwupjGycmcBCeEQ1KHkjkG6noU85cBX2uzWJgLxlQZKcw_RCypz5egZfAzkP4uiZIBauX2UAcOcLo_f8XBjlq6_-I9AGmNgzrS9hd_NNGBqcxS65JFZaT5gWU2Ousre_BtisMG4z84fsFw=w2059-h1216',
  ogType: 'website',
  
  twitterCard: 'summary',
  twitterTitle: '',
  twitterDesc: '',
  
  title: 'How RTLS and IoT Revolutionize Manufacturing Operations',
  date: '2023-05-14',
  tag1: '#RTLS',
  tag2: '#IoT',
  tag3: '#manufacturing',
  tag4: '#assetmanagement',
  
  // <br/>
  text: [
  {
  head: 'What is RTLS?',
  subHead: '',
  desc: `Real Time Location System (RTLS) is a natural complement that provides real-time location information of people or objects for location-based services. It supports businesses in gaining insights into improving overall performance, efficiency, and profitability using data. <br/>The RTLS technology market is rapidly growing with the emergence of various new applications in healthcare, manufacturing, sports, and other industries. <br/>In today's business environment, increasing profitability and customer satisfaction are essential to secure a competitive advantage. The combination of IoT devices with this technology has the potential to revolutionize the manufacturing industry and unlock new dynamics of revenue through improvements in production process speed, development cycle monitoring, predictive maintenance, and warehouse and inventory management. <br/><br/>Let's explore some examples below to understand how it works.`,
  dot: ''
  },
  {
    head: '',
    subHead: 'Increased Production Line Efficiency',
    desc: 'By integrating RTLS technology into production lines, companies can track specific products and collect data on how equipment is being used, allowing them to evaluate the efficiency of their supply chain. Access to such data means that operational issues or bottlenecks can be identified and mitigated immediately. This ultimately helps streamline operations and reduce costs. Similarly, knowing when process deviations occur can help identify errors before products reach customers.',
    dot: ''
  },
  {
    head: '',
    subHead: 'Asset Tracking and Management',
    desc: 'A persistent challenge in the manufacturing and logistics industries is knowing where equipment is located. This challenge has become even more complex in environments where only a limited number of workers are allowed, such as during the COVID-19 pandemic. Furthermore, the time spent searching for equipment in any industry becomes a cause of revenue loss. <br/>Attaching RTLS tags to all assets means that workers can always know the exact location of assets, down to the nearest submeter. Additionally, managers can expedite maintenance tasks by attaching tags to all equipment and machines, enabling them to pinpoint their exact location. Moreover, having knowledge of equipment usage data allows for better estimation of when regular maintenance should be scheduled, preventing machine breakdowns from occurring in the first place.',
  },
  {
    head: '',
    subHead: 'Safety Management',
    desc: 'Worker and equipment safety is another critical concern, especially in large-scale facilities where people navigate daily. RTLS, LBS, and IoT can benefit the industry through controlling access to assets. RTLS can be implemented within entry points, visitor ID badges, mobile phones, or key cards to prevent unauthorized access to specific facility areas. <br/>Similarly, tags can alert workers when they are entering high-risk areas and ensure they are wearing the appropriate personal protective equipment (PPE) upon entry, ensuring companies comply with regulations and security protocols.',
    dot: ''
  },
  {
    head: 'Considerations for Successful Solutions <br/>',
    subHead: 'Future Requirements',
    desc: 'The value of RTLS stems from considering both short-term and long-term requirements of the business and taking a holistic approach. However, many companies tend to view location-based services as a single application, resulting in budgeting for minimal viable solutions that may not meet medium to long-term requirements. It is important to ensure that the chosen RTLS can fulfill multiple use cases simultaneously.',
  },
  {
    head: '',
    subHead: 'Realistic Expectations',
    desc: 'End-users should receive education to avoid unrealistic expectations and be equipped to troubleshoot problems when they arise. Allocating time to fully understand how the product operates helps align desired outcomes with the necessary implementation budget, preventing future issues.',
  },
  {
    head: '',
    subHead: 'Software Types',
    desc: `Understanding the most suitable software types for a company's application is crucial, as location software comes with varying layers and complexities. Consider the goals you aim to achieve and find the optimal solution that aligns with your business strategy. In this regard, adopting an open and versatile RTLS platform from the outset is key to ensuring flexibility among various LBS and SW application solutions.`,
  },
  {
    head: '',
    subHead: 'Impatience',
    desc: 'There is a tendency to skip testing in real environments after the initial setup, but this often leads to failures. Companies should understand how the solution scales across multiple scenarios before rolling out the technology at scale. Additionally, it is important to align budget and project requirements to ensure clear estimates for sustainable scalability plans and total cost of ownership (TCO).',
  },
  {
    head: '',
    subHead: '',
    desc: 'It is an exciting time to be interested in RTLS and IoT. As more and more companies across various industries reap the benefits, performance and profitability are improving. For those who have not yet adopted this technology, now is the opportunity.',
  },

  ],
  

  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/en/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/en/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/en/enterprise/logistics/asset'
      }
    ]


  }
  


  }