export default {

  helmet: ' ',
  desc: ' ',
  keywords: ' ',

  ogTitle: ' ',
  ogSite: ' ',
  ogDesc: ' ',
  ogUrl: 'https://orbro.io/jp/blog',
  ogType: ' ',

  twitterCard: ' ',
  twitterTitle: ' ',
  twitterDesc: ' ',

  solution: 'オブロ ソリューション紹介',
  product: '関連製品',

  title: '<strong>ORBRO</strong> Blog',
  description: 'Orbroの最新技術とニュースをお読みください。',
  blogList: [

    {
      name : 'blogSmartShipyard',
      date : '2024-05-09',
      label: 'スマート造船所ソリューション',
      path : '/jp/blog/smart-shipyard'
    },
    {
      name : 'blogAIoT',
      date : '2024-05-09',
      label: 'AIoTとは？',
      path : '/jp/blog/what-is-aiot'
    },
    {
      name : 'blogAiCamera',
      date : '2024-02-07',
      label: 'AIカメラ位置追跡ソリューション',
      path : '/jp/blog/ai-camera'
    },
    {
      name : 'blogIndoorGPS',
      date : '2024-01-31',
      label: '屋内GPSソリューション',
      path : '/jp/blog/indoor-gps'
    },
    {
      name : 'blogSmartBuildings',
      date : '2024-01-30',
      label: 'スマートビルとは？',
      path : '/jp/blog/smart-buildings'
    },
    {
      name : 'blogSmartPort',
      date : '2024-01-25',
      label: 'スマートポートソリューション',
      path : '/jp/blog/smart-port'
    },
    {
      name : 'blogSmartParking',
      date : '2024-01-24',
      label: 'スマートパーキングシステム',
      path : '/jp/blog/smart-parking'
    },
    {
      name : 'blogIPS',
      date : '2024-01-23',
      label: 'IPS（屋内位置情報システム）とは？',
      path : '/jp/blog/indoor-positioning-system'
    },
    {
      name : 'blogSmartAirport',
      date : '2024-01-16',
      label: 'スマート空港ソリューション',
      path : '/jp/blog/smart-airport-solution'
    },
    {
      name : 'blogSmartHospital',
      date : '2024-01-16',
      label: 'スマート病院とは？',
      path : '/jp/blog/스마트병원이란'
    },
    {
      name: 'blogLoRa',
      date: '2024-01-15',
      label: 'LoRaとは？',
      path: '/jp/blog/what-is-LoRa'
    },
    {
      name: 'blogSmartFactory2',
      date: '2024-01-12',
      label: 'スマート工場とは？',
      path: '/jp/blog/what-is-a-smart-factory'
    },
    {
      name: 'blogNVR',
      date: '2024-01-12',
      label: 'NVR とは？',
      path: '/jp/blog/nvr'
    },
    {
      name: 'blogNFC',
      date: '2024-01-11',
      label: 'NFC とは？',
      path: '/jp/blog/nfc'
    },
    {
      name: 'blogRFID',
      date: '2024-01-11',
      label: 'RFID とは？',
      path: '/jp/blog/rfid'
    },
    {
      name: 'blogWhatIsDX',
      date: '2024-01-10',
      label: 'デジタルトランスフォーメーションとは？',
      path: '/jp/blog/what-is-digital-transformation'
    },
    {
      name: 'blogContainerTracking',
      date: '2024-01-09',
      label: 'コンテナトラッキング',
      path: '/jp/blog/container-tracking'
    },
    {
      name: 'blogExampleOfSmartCity',
      date: '2024-01-09',
      label: 'スマートシティ (Smart City) 構築事例',
      path: '/jp/blog/example-of-smart-city'
    },
    {
      name: 'blogWhatIsSmartCity',
      date: '2024-01-08',
      label: 'スマートシティとは？ | Smart City',
      path: '/jp/blog/what-is-smart-city'
    },
    {
      name: 'blogBLE',
      date: '2024-01-04',
      label: 'BLE とは？ | Bluetooth Low Energy',
      path: '/jp/blog/ble'
    },
    {
      name: 'blogDigitalTwin3',
      date: '2023-12-28',
      label: 'デジタルツイン（Digital Twin）とは？ | 定義、適用事例',
      path: '/jp/blog/digital-twin'
    },
    {
      name: 'blogDigitalTwinDigitalCity',
      date: '2023-12-26',
      label: 'デジタルツイン(8) - リアルなグラフィック都市の実装',
      path: '/jp/blog/digitaltwin-digitalcity'
    },
    {
      name: 'blogDigitalTwinCCTV',
      date: '2023-12-26',
      label: 'デジタルツイン(7) - 交通CCTVの接続',
      path: '/jp/blog/digitaltwin-cctv'
    },
    {
      name: 'blogDigitalTwinAirplane',
      date: '2023-12-26',
      label: 'デジタルツイン(6) - 飛行機情報の接続',
      path: '/jp/blog/digitaltwin-airplane'
    },
    {
      name: 'blogDigitalTwinTraffic',
      date: '2023-12-26',
      label: 'デジタルツイン(5) - バスおよび地下鉄情報の接続',
      path: '/jp/blog/digitaltwin-traffic'
    },
    {
      name: 'blogDigitalTwinTracking',
      date: '2023-12-26',
      label: 'デジタルツイン(4) - 人と車両のリアルタイムな移動の接続',
      path: '/jp/blog/digitaltwin-tracking'
    },
    {
      name: 'blogDigitalTwinBuildings',
      date: '2023-12-26',
      label: 'デジタルツイン(3) - 建物および室内情報の実装',
      path: '/jp/blog/digitaltwin-buildings'
    },
    {
      name: 'blogDigitalTwinWeather',
      date: '2023-12-26',
      label: 'デジタルツイン(2) - 大気および気象情報の実装',
      path: '/jp/blog/digitaltwin-weather'
    },
    {
      name: 'blogDigitalTwinGis',
      date: '2023-12-26',
      label: 'デジタルツイン(1) - GIS地理情報の実装',
      path: '/jp/blog/digitaltwin-gis'
    },

    {
      name: 'blogQaUwb',
      date: '2023-12-18',
      label: '品質管理と在庫管理ソリューション：UWB',
      path: '/jp/blog/qa-uwb'
    },
    {
      name: 'blogConstructionSolution',
      date: '2023-12-11',
      label: '重大災害罰則法防止ソリューション：建設現場の安全管理',
      path: '/jp/blog/construction-solution'
    },
    {
      name: 'blogRfidRtls',
      date: '2023-11-28',
      label: 'RFIDの代わりにRTLS',
      path: '/jp/blog/nfc-uwb-ble'
    },
    {
      name: 'blogNfcUwbBle',
      date: '2023-11-20',
      label: 'NFCタグを置き換えるUWBとBLEを活用した革新的な在庫管理システム',
      path: '/jp/blog/nfc-uwb-ble'
    },

    {
      name: 'blogRtlsCompany',
      date: '2023-11-15',
      label: 'RTLSプロバイダを選ぶ際の主要な考慮事項',
      path: '/jp/blog/rtlsCompany'
    },
    {
      name: 'blogRfidNfcUwb',
      date: '2023-11-10',
      label: 'RFID、NFC、UWB：どの技術を選択するか？',
      path: '/jp/blog/RfidNfcUwb'
    },
    {
      name: 'blogBeacon',
      date: '2023-11-08',
      label: 'ビーコンとは？',
      path: '/jp/blog/beacon'
    },
    {
      name: 'blogProductionAutomation',
      date: '2023-11-06',
      label: 'スマート工場の構築と生産自動化ソリューション',
      path: '/jp/blog/productionAutomation'
    },
    {
      name: 'blogUwbRtls',
      date: '2023-11-03',
      label: 'UWB RTLS、未来の産業の始まり',
      path: '/jp/blog/uwbRtls'
    },
    {
      name: 'blogRtlsHealthcare',
      date: '2023-11-02',
      label: 'RTLSヘルスケア、即座に使用可能',
      path: '/jp/blog/rtlsHealthcare'
    },
    {
      name: 'blogDX',
      date: '2023-11-01',
      label: 'デジタルトランスフォーメーション（DX）とは？',
      path: '/jp/blog/dx'
    },
    {
      name: 'blogOfficeSolution',
      date: '2023-10-31',
      label: 'スマートオフィス構築ソリューション',
      path: '/jp/blog/officeSolution'
    },
    {
      name: 'blogDigitalTwin2',
      date: '2023-10-26',
      label: 'デジタルツインの本質と潜在能力',
      path: '/jp/blog/digitalTwin2'
    },
    {
      name: 'blogNextManufacturing',
      date: '2023-10-16',
      label: '製造施設の在庫管理システムの未来',
      path: '/jp/blog/nextManufacturing'
    },
    {
      name: 'blogSmartFactory',
      date: '2023-10-12',
      label: 'スマートファクトリーが製造業を革命づける方法',
      path: '/jp/blog/smartFactory'
    },
    {
      name: 'blogSmartSafety',
      date: '2023-10-04',
      label: 'スマートセーフティマネジメントの包括的なソリューション',
      path: '/jp/blog/smartSafety'
    },
    {
      name: 'blogHeavyEquipment',
      date: '2023-09-25',
      label: '建設現場を揺るがす重機のスマート管理技術の革新',
      path: '/jp/blog/heavyEquipment'
    },

    /*
      {
          name: 'blogConstructionAsset',
          date: '2023-09-21',
          label: '建設現場の在庫管理：RTLSによる新しい変化',
          path: '/jp/blog/constructionAsset'
      },
      */
    {
      name: 'blogConstructionSafety',
      date: '2023-09-19',
      label: '安全でスマートな建設現場のためのRTLSソリューション',
      path: '/jp/blog/constructionSafety'
    },

    /*
      {
          name: 'blogConstructionRTLS',
          date: '2023-09-18',
          label: 'スマート建設現場の労働者安全管理の中心',
          path: '/jp/blog/constructionRTLS'
      },
      */
    {
      name: 'blogLogisticsTech',
      date: '2023-09-13',
      label: '最新のテクノロジートレンド：物流業界従業員が知るべきこと',
      path: '/jp/blog/logisticsTech'
    },
    /*
    {
        name: 'blogSCM',
        date: '2023-09-11',
        label: 'グローバルサプライチェーンマネジメント（SCM）のためのスマートウェアハウス管理',
        path: '/jp/blog/scm'
    },
    */
    {
      name: 'blogLogisticsAsset',
      date: '2023-09-08',
      label: '物流センター在庫管理の進化：RTLSベースのソリューション',
      path: '/jp/blog/logisticsAsset'
    },
    {
      name: 'blogLogisticsWorker',
      date: '2023-09-06',
      label: 'スマート物流施設での労働者の安全管理方法',
      path: '/jp/blog/logisticsWorker'
    },
    {
      name: 'blogUwbLogistics',
      date: '2023-09-05',
      label: 'UWB技術を活用したスマートロジスティクスセンター',
      path: '/jp/blog/uwbLogistics'
    },
    {
      name: 'blogLogistics',
      date: '2023-09-04',
      label: 'スマートロジスティクスセンター：RTLSを活用したゲームチェンジャー',
      path: '/jp/blog/logistics'
    },
    {
      name: 'blogUwbGeofence',
      date: '2023-09-01',
      label: 'ウルトラワイドバンド（UWB）技術を活用したジオフェンスシステムの革新',
      path: '/jp/blog/uwbGeofence'
    },
    {
      name: 'blogMedicalWaste',
      date: '2023-08-30',
      label: '医療廃棄物と資産の管理のためのスマートテクノロジー',
      path: '/jp/blog/medicalWaste'
    },
    {
      name: 'blogHospital',
      date: '2023-08-21',
      label: '最先端の病院が患者を管理する方法',
      path: '/jp/blog/hospital'
    },
    {
      name: 'blogVisitor',
      date: '2023-08-14',
      label: 'あなたの展示館の来場者が減少する理由',
      path: '/jp/blog/visitor'
    },
    {
      name: 'blogPatientTracking',
      date: '2023-08-07',
      label: '患者管理のためのリアルタイム位置追跡',
      path: '/jp/blog/patientTracking'
    },

    {
      name: 'blogSmartLogistics',
      date: '2023-08-03',
      label: 'スマート物流倉庫が産業をリードする理由',
      path: '/jp/blog/smartLogistics'
    },


    {
      name: 'blogSmartConstruction',
      date: '2023-07-31',
      label: 'スマート建設現場安全管理システム',
      path: '/jp/blog/smartConstruction'
    },
    /*
 
 
    {
        name: 'blogAccess',
        date: '2023年07月05日',
        label: '完全に刷新された出入りの概念',
        path: '/jp/blog/access'
    }, 
   {
        name: 'blogConstFuture',
        date: '2023年06月06日',
        label: 'RTLSがもたらす建設業界の未来',
        path: '/jp/blog/constfuture'
    }, 
    */
    {
      name: 'blogOffice',
      date: '2023-06-02',
      label: '2023年、スマートオフィスの構築方法',
      path: '/jp/blog/office'
    },
    /*
    {
        name: 'blogMall',
        date: '2023年05月28日',
        label: '大型ショッピングモールをより進化させるRTLS',
        path: '/jp/blog/mall'
    }, 
    
    {
        name: 'blogConstruction',
        date: '2023年05月23日',
        label: '建設現場でのRTLS',
        path: '/jp/blog/construction'
    },
    {
        name: 'blogWarehouse',
        date: '2023年05月21日',
        label: '倉庫をよりスマートに運営する方法',
        path: '/jp/blog/warehouse'
    }, 
    {
        name: 'blogHealthcare',
        date: '2023年05月19日',
        label: 'ヘルスケア施設でのRTLSの利点',
        path: '/jp/blog/healthcare'
    }, 
    */
    {
      name: 'blogConsider',
      date: '2023-05-19',
      label: 'RTLSソリューションを選ぶ際に考慮すべき事項',
      path: '/jp/blog/consider'
    },
        /*{
            name: 'blogPatient',
            date: '2023年05月18日',
            label: 'RTLSが医療施設の患者フローを改善する方法',
            path: '/jp/blog/patient'
        }, {
            name: 'blogBi',
            date: '2023年05月18日',
            label: 'RTLSを活用したビジネスインテリジェンス（BI）の成長',
            path: '/jp/blog/bi'
        }, 
        */{
      name: 'blogForklift',
      date: '2023-05-17',
      label: 'フォークリフト運転手の安全を改善するRTLSソリューション',
      path: '/jp/blog/forklift'
    },
    /* {
        name: 'blogGse',
        date: '2023年05月17日',
        label: 'GSE（地上支援装置）測位のための航空RTLSガイド',
        path: '/jp/blog/gse'
    }, 
    */
    {
      name: 'blogActiveRfid',
      date: '2023-05-16',
      label: 'Active RFID技術ベースのRTLS',
      path: '/jp/blog/activerfid'
    },
    /*
    {
        name: 'blogElder',
        date: '2023年05月16日',
        label: '老人医療施設にRTLSを使用する理由',
        path: '/jp/blog/elder'
    }, 
    {
        name: 'blogAdvantage',
        date: '2023年05月16日',
        label: 'RTLSの4つの主要な利点',
        path: '/jp/blog/advantage'
    },
    
    {
        name: 'blogAnswer',
        date: '2023年05月15日',
        label: '回答：Bluetooth位置サービスに関する5つの主要な質問',
        path: '/jp/blog/answer'
    }, 
    {
        name: 'blogFactory',
        date: '2023年05月15日',
        label: '未来の工場へようこそ：Industry 4.0でのリアルタイムトラッキングの役割',
        path: '/jp/blog/factory'
    }, 
   {
        name: 'blog4th',
        date: '2023年05月14日',
        label: '第4次産業革命でのリアルタイム位置システムの役割',
        path: '/jp/blog/4th'
    },
    */
    {
      name: 'blogManufacture',
      date: '2023-05-14',
      label: 'RTLSとIoTが製造業のオペレーションを革新する方法',
      path: '/jp/blog/manufacture'
    },
        /*{
            name: 'blogCorona',
            date: '2023年05月13日',
            label: 'RTLS（リアルタイム位置サービス）がポストコロナ19サプライチェーンの構築に役立つ方法',
            path: '/jp/blog/corona'
        }, {
            name: 'blogIot',
            date: '2023年05月12日',
            label: 'IoTプロセスとRTLSを組み合わせて収益機会を創出する',
            path: '/jp/blog/iot'
        },
        {
            name: 'blogAlone',
            date: '2023年05月12日',
            label: '孤独な労働者を危険にさらさないように保護',
            path: '/jp/blog/alone'
        }, 
        */{
      name: 'blogAirport',
      date: '2023-05-10',
      label: '空港で使用されるRTLS',
      path: '/jp/blog/airport'
    }, {
      name: 'blogMedical',
      date: '2023-05-04',
      label: '医療産業でのRTLS',
      path: '/jp/blog/medical'
    }, {
      name: 'blogGeofence',
      date: '2023-05-01',
      label: 'ジオフェンスとは何であり、何をするのか？',
      path: '/jp/blog/geofence'
    },
    /*{
        name: 'blogAsset',
        date: '2023年04月27日',
        label: '室内外でのスムーズな資産とプロセスのトラッキング',
        path: '/jp/blog/asset'
    }, 
    */
    {
      name: 'blogUwb',
      date: '2023-04-25',
      label: 'UWBとは何であり、ビジネスにどのように役立つのか？',
      path: '/jp/blog/uwb'
    }, {
      name: 'blogDigitalTwin',
      date: '2023-04-22',
      label: 'デジタルツインとリアルタイム位置測位システム',
      path: '/jp/blog/digitalTwin'
    },
    /*{
        name: 'blogSafety',
        date: '2023年04月20日',
        label: '産業現場での位置測位技術の役割が労働者の安全に与える影響',
        path: '/jp/blog/safety'
    }, 
    */
    {
      name: 'blogRTLS',
      date: '2023-04-17',
      label: 'リアルタイム位置測位システム（RTLS）とは？',
      path: '/jp/blog/rtls'
    }
  ],
  back: 'ORBROブログに戻る',
  newletter: 'ニュースレターの購読',
  info: 'さまざまなニュースを受け取ってください！',
  placeholder: 'メールアドレスを入力してください',
  subscribe: '登録する',
  terms1: "情報を提供することにより、",
  terms2: "Orbroの",
  terms3: '個人情報処理方針',
  terms4: 'に同意します。',
  more: 'もっと見る',
  less: '閉じる',
  recent: '最新のブログ記事',
  share: '記事が気に入りましたか？ <br/> 共有する'
}