import { ptzThumb } from "@assets/images/main/camera";

export default {


    default: {
        title: 'ORBRO | 位置追踪与数字孪生专业解决方案',
        key: '定位, 资产管理, 资产追踪, 仓库管理, 仓库资产管理, 室内GPS, 室内定位, RTLS, 定位解决方案, 重大灾难处罚法, 建筑工地安全, 访客路径分析, 出入系统, 第四次工业革命, 智能工厂, 数字孪生, Beacon, 蓝牙, BLE, UWB, 物联网',
        desc: 'ORBRO基于UWB（超宽带）、RTLS（实时定位系统）、信标、门禁系统、位置跟踪等尖端技术提供智能解决方案。利用数字孪生技术，探索智能城市的未来。',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: 'https://orbro.io/cn/',
    },


    orbroOs: {
        title: 'Orbro OS | 数字孪生空间管理平台',
        key: '室内追踪, RTLS解决方案, RTLS, 数字孪生, 室内定位, 物联网RTLS, 室内定位, 定位, 资产管理, 资产追踪, 数字孪生, 重大灾难处罚法',
        desc: '数字孪生平台Orbro OS允许您在虚拟中管理现实世界的空间。通过实时位置追踪，管理资产并确定人员位置，轻松管理空间或获取信息。',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYkZbw7aOzICkoQi8HNk9s2d6VpUL4SVgujeUxCxLThYcyYLFnsiLZyVx8NwEWTiJIKKeTqFcuFZF4-0GJiF5k8YyZKXtU1iL8Qas-_DGIZPtogRYL6cnYimywaHxSkEe97rBb410rPXE2LymoexrdTIuXxKNli61FgKo4m_7JzUZXofk9HBe1CmLCLjcaJ4N8CusSji3C-D-W9XEtpZ_pdbROqqHehQbzJf3Lu7a9r04vRKR5XY6SlOsaHQXgGvyvf2EwBUoRIK1DKgjGg5Zzpi5ql-8O8MWh8pyV_NMWerNhx8Km30j95bwAyArdMfGv2x8l5jdUBxychGtfT5SECI01jw3yjOvdPPWgw9EfhlRlUPENdWxGAM8tN8fPTKID1cOaskpvwlCYjMeExZpPCYAmw8IWLq_KmhgyxcGK287oaey7vTTQ_AFc816pvys48-p5vA5qpp1-3VCFZEEVoF90jtIKxMU5dWbpeElJFg99AybHdS6Ka8QD1-oq7K-N2rat8r8uPyJCc-UGK2DfU301JI-BlolwsoqI9JH2eomiXOFFsCyPfbMImXi-ZM0r5R7DAY5j3VqdTVq3x2AUK_VMlLkFSCcOzlpo6_hUaTgAXdIGIdtArstnx9UYRPj1qdvT2zhTSXu43gxG5dnRsO8WVC4H6qMoiotvH-f-8UkXMZOSjV3C9TmUS3zLAzMIeP5_wTbaf7Pk8kT9Cf_h6NFml9VUIPZ-0AVWUfsGfBgqD2U_ToCKiOtKLRwy1d50veOBzfrvRlhAUVUyQj3B6Jf4cXxAgJyp7PnJUXUPmpYr3UVmjjyx3-7N1f1TAf5TpHSChyMrenXXD_BlVEYe2DX2o_oaZVmkZ4K3cPSUoaQsgcalbV-QMpW-cys5PIo1H5DRYxfH5WzurFBEEqXF0h_RJZD8uBz7u-nF3a_sGEXbSV1sIj-5oqlm0WHswVhSyFLy1AsEPsWKNGoN4vAOdFN3bvKfDXcj22FbtQv7oqpj35v0fiK0uFjxzUli6LA0lOcxxBTDX8K3TGhhmgZSGVIVN-RtMSnnYrzqGJ2Gdpfhs6Z_lrTrIL4IE9EiVsbWkZRlk9Ung0oFwlX9E0bpnv_oBa8cEsinqQms8LDrCXle81kS5jg_WHHUVoTBYawhdKqeBHbGj67EcTW5OfDNT5b-0ivlXNSO2rj2MfdTIXcPXHrFF0O1x3x4e_aZhiXwhxOdQpRn38jnYIu8XKjS4Ot07PLLE0sdyPidowRf1mGv3Fe3VZbO1cPCMUGmwJwDb1ow5AYNQsCpv9wLFrfkTfb9z8_lyUPmzo4t7o8N4difD44FvoqkfHmwLBBPkYXimpJ9jrIz25oS_B70pubRYpO-ZMwo1aQ2McWHBUd9oBRTr27FuBGJS8RIlZ5nxyeOeZ-Tp_kOeKWr8bcB5yrd96BSV8Cgb5P43XLS9SMCZrZ7oM6e3JipkAMJDP7kLBtvCET1199BhDG6TF44RHfOKXYImbDVIdgmSdsIw6nhcXUIDGaQCifrmOru2TFa0v2Wwz4K-=w2006-h1238',
        url: 'https://orbro.io/cn/os',
    },

    os: {
        beacon: {
            title: '信标位置追踪操作系统 | 位置追踪和数字孪生专业解决方案',
            desc: '',
        },
        rtls: {
            title: '实时位置追踪操作系统 | 位置追踪和数字孪生专业解决方案',
            desc: '',
        },
        camera: {
            title: '视频位置追踪操作系统 | 位置追踪和数字孪生专业解决方案',
            desc: '',
        },
        access: {
            title: '门禁系统操作系统 | 位置追踪和数字孪生专业解决方案',
            desc: '',
        },
        sensor: {
            title: '工业传感器操作系统 | 位置追踪和数字孪生专业解决方案',
            desc: '',
        },
    },

    accessProduct: {
        title: '全新的进出系统 | ORBRO，数字化转型的开始',
        key: '智能进出系统, 进出控制系统, 室内GPS, 进出管理系统, 智能门锁, 生物特征进出系统, RFID进出系统, 二维码进出系统, 远程进出系统, 进出卡系统, 进出日志记录',
        desc: '如果您需要更便捷、更安全的进出系统，立即了解ORBRO的未来型进出系统。',
        img: 'https://lh3.googleusercontent.com/fife/APg5EObhIhVSc5l_YgEkV8ENDMUXJdxYXa-tWu2ob2CGj1T8JguKcr3Oh6Ywro_h3lU1aAEDTsm7Y33KDfdFlaO6IaSSuDYM6jnVKpOcIiAe0eCrjFHlDoXvptrgNbRQPpSce46Ge5-c77SpWj2XbOegeOH6WEQ54JhkhINdVTje6Mjr5QHnHYihwyoLoEY5brdu6vr7ku4EHibMaDRdaqhHwEnn_WCeTCmFTPbHm4KUoUp5saol3DT9810Q8H_Nmhgg9VvFYIn3JToTijZ5R2Vr2G7EHzbQmFSDSmkt60piBLbORnc52qceoWBGmSzhkMGsLF3sT3JdXCd2QPn-tC0MfwpaXWl5qm9-D4FMltwFgePmpiMZFDjy7O7pEwY-ScQ--sd2h6ptZnjjdO05ROX-m3AmdzXEEXvulJFRmx8ZyoryCIODzmathG0yJ0_vke5v6mE94-lyK8IFHisgptmGPzJG6m6-kkaK_EjlPxneaY8NDdT1cCxlyeAaHSCNgOps9RqcyrjM5BWr02C_P7cEswPoR9lT73V7zsWcTODPyUteFFSqvLjKrMZbQ269etOJjln97cW54V5y_3OplUpQ1Zt2-lppHEz_BnEgH_fsIfnyni9lbY1qP_-fdeXV6n3tlA3rNTSQZV5HLm5kzafsNPKWMzx3CKW-z7S6MxUpBGVCgA_fjiBt5YUoFQSwxmei1SlyV8zK-A3I5Yi-8DaPA5ty075aBejJ8r4fu0G_PvNx7dPUCjNgwfZOTgYXIXgKGZVv75WGo3qvXjP_BezP6LrsqC_Soid5hd1osgf6JmGsCSp7u0oPBw8KwjxUNHCWCIta4i0XFmK_6FkII6LV1KHbqmGiOoI75JSqjnneaPjueqkzBdZtOtDep3ynq0T_iTz_KsVWEHN9-zBjFAvintaIShl7gJRF__etrafFFB5wcImqgSJm7E5mO3CgRTRkaWfnI__wb3kx-6lby0oT2nII_K7cCJcE4fmn7xjaKW4Q6ql9_e9dHF7tri8sOpwP2AoBo7YgKD8QrNUPoPaGrUKpRLQwdnXChhEguPYTCZccG_agb8XmHBsrvRTXtMoGppbQM2j9KvMJ7VzAYAMpao7dxwFdgJwJMV_d08_UbF0k7eErXfDYFfODs6wHnTnNEVevxgq0sBog7O0-7M6CGTo-CnhP4I_gnMzeu-zHRbbjsK9cV9K7vlLnf8zhSGW9HkcZ659Gws5h13eSMKZjqqMQxZv5uRVqBCWOV3dhRbLHget2G3DfdjrJx-uFPWubEUAYfsp_vW6ZSJW6nKkY3r9WWDF-mea0DR1p72JASwZpyxzftA3-l-Lkz6Ruqk4uzF3MXpAIgsWMIfgBywUDTdxpkXEUmEWqa8CPqrfEDlNClOAOqypO2tLTeK3Fqn5ACl3jx6d3ScqvIMpqN3zW-J6HrLTpfK3cAJ1B9kxpqVzVuc0PwPYeQqOi-zFekLqzL08yNZgHmr_p7Ncq0dDn7IlkMIwfycNyiq6l9mlcQ9Q9Szk-Wze_Tcx-_rwXVmlocKD6lGRi=w2006-h1238',
        url: 'https://orbro.io/cn/access',
    },


    rtlsProduct: {
        title: '定位的未来 | ORBRO，数字化转型的开始',
        key: '定位, 资产管理, 资产追踪, 仓库管理, 仓库资产管理, 室内GPS, 室内定位, RTLS, 定位解决方案, 重大灾难处罚法, 建筑工地安全, 访客路径分析, 出入系统, 第四次工业革命, 智能工厂, 数字孪生, Beacon, 蓝牙, BLE, UWB, 物联网',
        desc: '更快捷、更准确地管理室内定位、资产管理和工作场所管理。而且ORBRO系统操作简便。',
        img: 'https://lh3.googleusercontent.com/fife/APg5EObHma5EkyX2MCK6e2mdlbtvvAySBNi1Q6Sy0yCt3XGL-YOgvTEWmMgseI1eiYCBWZ6bCS-EHvV75JiwpvxCDhTKatr_wU7jMtlOwtex9EaelwxpicCYi7PgQNQIIe89wX_xVEPYAvj4dTBXP6aWIDMAio8cHy356CjoDnInZfZXqS-YvRCFK8gZBRMzv4fg1I1jHk0VTFtNo3GJEw7uBwbX5vckmGbYqiBPreTVy8b9t3iHACk5GKEEvGOH3EIN8L8g1V82KcZhn-iaqiRSpudUMASnFDyxdw-tEg7p8-qOAhypBhKaWsOFkTS_1Y3vi5hjRDHEKrNzeg7w9mDBoZdjbd3HaSR49-DFMFiaIjUjcdHeMGYNly4CBBoR0mOaNxvpFu2KLpVt683_8jSHK8YvRAZJCZFZlrUm5t1GRtOXjYbDgJ4scMmX9Agnjr24xOMup95HEUTukM9SFuxajBB8CTqfkX2g8seJ1H66X6zTjBqzzLdwQSRCvB82ItDZM1S4TCGMIQcO9gOGZM_QDFamVpM_RDBBrpMq2g5ErIYWBl3o7pZcOiAtlZOnfWCYagSLeUq4dvo963RZlRvA1XUMTAVVdTfmP8j3A0XcABOijSHaWSql7FtHiQuNE5kWlg91EZYLdnR5TmqD2ua88to5MCCiuJugKsEYTIA09FoIbPozNw3lJEvmKur3O2IRJmDjz5TA3UTHEHlLpBFACSyXXd70flICfMLlZIbkQqv9Ikzbell2A9-_cGDPw18kjU1i8doOYmKhg-fpQZhe0FRRBGtrZm2pEJruhGIjTYHfUrH5Q55qq1vMAFkb2OiCHNmhZ4IHhOL7iqZMyjuvXwMusC306K_yZFw5Md40a5K99WVQUhcXszyVefYM-tYlczn22kyfdM6hyCLQynNvs1bBNio2EdhXpe7tvwlpqQa9YJpnRfS8xS-UNd_3Tzlt5OlbXKZQFUL8NcGeeNyfYU9suciV7N2qFVw1go2Lf4HG9JlCNMgdIJG9gnM2-0bWM-E87vsxH4TvvgT8Y-8APLJlgZ5g0Y_jzTLaXWIEnn-9Z5Vw50MUNv2RTuKKzVlefLGlc24Y7bqebotC1PqdTAN5T4gWmJC33EsO7LmrZ1UpsoFhm3WzqTDTqZE_2MYpXGpNdm_FW3z44CvSgqwewSdmYW-O9kq9mTtjPGpKskUmQ7PlZJY1oKFy7f-vDHJE1PMGKunf6CtrA3pXnXNUls11PKhavf4RpmEnHzII85kSdHFKM_EJrVjVw6vb1qyW_QYLEQkUhM3PsLdc2WGbvGPiKRvIkxmeQ9cNzDPzyD8J-1tsibIhMrVrjzncEBP7r5iwtsqg5q8cZfwFJLFIF_EBh4V3JSqVHVj-pidYwaipmm0rxkhJGoppq5C3WrLM7R1uAiQBA3n1p1U2NAO2bNP3AcAsZUkuWuQbyKIm1kslrgY92ltA27GAc1crDXFbjE2M0AxBSYkKVYOcWpVRCC4FSv5itShGPqYGCd4rbtenPBRiMgtFRM32kdWGP1sWHx_g72cD=w2006-h1238',
        url: 'https://orbro.io/cn/rtls',
    },

    cameraProduct: {
        title: '摄像头定位 | ORBRO，数字化转型的开始',
        key: '定位, 资产管理, 资产追踪, 仓库管理, 仓库资产管理, 室内GPS, 室内定位, RTLS, 定位解决方案, 重大灾难处罚法, 建筑工地安全, 访客路径分析, 出入系统, 第四次工业革命, 智能工厂, 数字孪生, Beacon, 蓝牙, BLE, UWB, 物联网',
        desc: '通过摄像头轻松快捷地追踪室内和室外的人员和资产。而且ORBRO系统操作简便。',
        img: ptzThumb,
        url: 'https://orbro.io/cn/camera',
    },

    iotProduct: {
        title: '物联网 | ORBRO，数字化转型的开始',
        key: '物联网, 智能家居, 物联网设备, 连接设备, 物联网解决方案, 物联网安全, 物联网分析, 工业物联网, 物联网平台, 物联网传感器',
        desc: '为了在紧急情况下能够快速报警，请了解ORBRO的安全警报系统。',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYCH16slHwmaQG331-kV_CkBXnlfH1G9KrQOsfFjNqI7fC1rCmzgUg01Dizxv1tp9RuOIHrNhWUHRmxn0KTRaH1025_7yPSqXctHcfoKasdRVOxqYUom3UzM6ontDOvbiJwx021PbutfqbQZR8gLoT2Btt487SFAu-oBqfMkEaBftr6vSecMBx11J_KvKfDuVp79oWFJjP4HYEc9wJoel4oqj_B2CpPolo0ykC5oJ4MFipkMmGoCuDzxt-mKnQHqiGptEGd0eWtoriaBDaGGeDj18v3SSCdfRJQ9bBXN5M3YJRg2yBJVtFdlboqFrKw4CIvK0LqHyr_eZbrBFj56A_u9uLpgMoKT2azM0XOCFY4eKvjOUG26lUuFO-6TuATsfMAiHJs0pcKOEQfkj1O8ewSyT2Wn14K032rljAuc9rnAElCgFf0plndW4RmVaQAAEZeK3LFNctWNVdUvCz54tPSKulB5Om_n8r_pn-IgsU94nbquRGFkjfew_XBXhkNv5Moq90hwIyXy3WxBg0rAB8u3gkXB0EHJDBBqTOcr_-nE4F_wZ-BxsyMUURlg0m6vk23XftGlrZ1c-57Wdtgwk8YfIvK9GHsk9CwgEZ7e9JUTgb23if0YuTzlAob7bGBJGNrWbEOt35VKMT8BUzKT_lvQCALsHexYk-YO9LVzbHoAdwbYYqIyCg41xvkWltQu_w9Racs4ORCFBkfke1gsOvy51IFkmE2hqqrivGO8YLzNQ2zJwCaOw9Wsb0Cqj8PN3imRwa6VJ2IMENOkqjipW9hvnbn-U-Nm7IMjKTmCE5z-_I84D0-yJJLiKtfo3a7PJl0gCgEEBQTFseDbtyujfFv8BnW0Xvbhn6WsHIYiRmrx8HpB_8lTDuFFwFPan_SfMzoAicjyLHUQLeOSdYPhRNKbZ9Ah1i9ovTjy9rRqpF8XuHkiQPdcnOEoqmzlCdnXc0hFzORQZ6koWMG_nH8Car64KYjSp8uaPyXgKwPGRXc2F41TWuE1-kU0gjPssZAj2qNxkMVHom-lYdZ7dIabaFsmKGpQbTnafugm4e2lNmULTpFJQkzQnN25Y180bmsvRzCG-NroyymzpZGaicJCGjRKcKDMZ754a10zT3o2jAP11AwZVB3Em3mk8IRU8tMKja2TQItdDa0PXI-naiwGAbBgyo55z4gGfov-Xo-NWXbtBVMu3kZd2UYLglsjT_adJWLbPAw1Eja758K9PhE1l_9qQgBE7FNH5bYnxUD_ZvfZm_1-sP8uVnKbrgf2qcWQEnl9yaSRRWlCXwBN6HUDvMo1sM6L-t8oH-IJS40kNUQBEwfbjNXkWAFjf-QPzyvXaHrS_7qIS4-uFvl_f_lF08pp_INZIcfVz9dl2Ghi2bZ0lgUdwmxS8VeZYqIy-yFV1aImHAtYpQSV0TUw9Gvrqeh7XHSYQgEmXB5t6ky0FWmrZxsDfJ-KTbrFsZrl7Hsffa-zGtfUb4WfSmwfyfp2MamCB6S9lc9UThvhkKorxsD98tB1uDyy4Sak8o3jDNZw54krj5u=w2006-h1238',
        url: 'https://orbro.io/cn/iot',
    },


    home: {
        title: 'ORBRO | ORBRO，数字化转型的开始',
        key: '定位, 资产管理, 资产追踪, 仓库管理, 仓库资产管理, 室内GPS, 室内定位, RTLS, 定位解决方案, 重大灾难处罚法, 建筑工地安全, 访客路径分析, 出入系统, 第四次工业革命, 智能工厂, 数字孪生, Beacon, 蓝牙, BLE, UWB, 物联网',
        desc: 'ORBRO | ORBRO，将现实生动地重新塑造成数字世界的数据平台。',
    },

    tech: {
        uwb: {
            title: 'UWB定位与定位技术 :: ORBRO，数字化转型的开始',
            key: '定位解决方案, 室内定位, GIS, UWB, RTLS, RTLS解决方案, UWB技术, 定位技术, 技术解决方案, UWB标签, TDOA, TWR, AI技术',
            desc: 'UWB是一种无线通信技术，利用非常宽广的频带在极短的时间内发送非常强大的信号。',
        },
        ble: {
            title: 'BLE定位与定位技术 :: ORBRO，数字化转型的开始',
            key: '定位解决方案, 室内定位, GIS, UWB, RTLS, RTLS解决方案, UWB技术, 定位技术, 技术解决方案, UWB标签, TDOA, TWR, AI技术',
            desc: 'BLE是一种无线通信技术，具有低功耗特性，但数据传输速度较慢。主要用于小型设备或低功耗传感器，并可用于小型发射器（如Beacon）。',
        },
        twr: {
            title: 'TWR定位技术 :: ORBRO，数字化转型的开始',
            key: '定位解决方案, 室内定位, GIS, UWB, RTLS, RTLS解决方案, UWB技术, 定位技术, 技术解决方案, UWB标签, TDOA, TWR, AI技术',
            desc: 'TWR（双程测距）技术是一种定位技术，通过测量发射器和接收器之间往返信号的时间来计算距离。',
        },
        tdoa: {
            title: 'TDoA定位技术 :: ORBRO，数字化转型的开始',
            key: '定位解决方案, 室内定位, GIS, UWB, RTLS, RTLS解决方案, UWB技术, 定位技术, 技术解决方案, UWB标签, TDOA, TWR, AI技术',
            desc: 'TDoA（到达时间差）技术是一种定位技术，通过测量信号从发射器到接收器到达的时间差来计算接收器的位置。',
        },
        stereo: {
            title: '利用立体视觉进行定位与定位技术 :: ORBRO，数字化转型的开始',
            key: '视觉, 深度学习, 定位, DepthEstimation, 定位推断, 立体视觉, RTLS解决方案, RTLS技术',
            desc: '立体视觉是一种利用两个相机联合识别对象的三维感知技术。',
        },
        mono: {
            title: '利用单目视觉进行定位与定位技术 :: ORBRO，数字化转型的开始',
            key: '视觉, 深度学习, 定位, DepthEstimation, 定位推断, 单目视觉, RTLS解决方案, RTLS技术',
            desc: '单目视觉是一种利用单个镜头采集和分析图像的计算机视觉技术。',
        }
    },
    integration: {
        title: '集成系统 | Orbro，定位与数字孪生专业平台',
        key: '物联网, 智能家居, 物联网设备, 连接设备, 物联网解决方案, 物联网安全, 物联网分析, 工业物联网, 物联网平台, 物联网传感器, 物联网, 智能家居技术, 物联网设备, 连接设备, 物联网解决方案, 物联网安全, 物联网分析, 工业物联网, 物联网平台, 物联网传感器, Kong-tech, Kong-tech',
        desc: '通过各种集成解决方案，如配送机器人、无人装卸系统和消毒系统，支持高效且安全的运营。Orbro的集成系统通过定位技术和实时监控提供最佳性能。',
        img: '',
        url: 'https://orbro.io/integration'
    },



    blog: {
        title: 'ORBRO博客 | ORBRO，数字化转型的开始',
        key: 'ORBRO, ORBRO博客, RTLS',
        desc: '浏览与RTLS相关的博客文章。',
        url: 'https://orbro.io/cn/blog'
    },


    company: {
        title: '生动地虚拟世界实现 :: ORBRO，数字转换的开始',
        key: '数字孪生、DT、Metabus、公共信息、3D世界、ORBRO、产业用Metabus平台、ORBRO、出入系统、空气管理、UWB、RTLS，技术解决方案，定位解决方案，技术营业，技术解决方案，People&Technology',
        desc: "Orbro是将200兆字节以上的数据进行3D形象化,是创新的数据连接平台。",
        url: 'https://orbro.io/cn/company'
    },

    request: {
        title: 'ORBRO咨询 :: ORBRO，数字转换的开始',
        key: '越野，ORBRO，数字孪生，RTLS, "IPS、GPS、GIS、MES、重大灾害处罚法，信标、蓝牙、超宽带，BLE、UWB、IOT，物联网，定位，室内位置定位，Access、Asset、数字转换，DX，4次产业革命，智能工厂，智能城市，RTLS解决方案',
        desc: "如果对室内定位、资产追踪技术有疑问，请随时咨询。",
        url: 'https://orbro.io/cn/request'
    },

    pricing: {
        title: '报价请求 :: ORBRO，数字转型的开始',
        key: '位置跟踪、摄像头位置跟踪、AI摄像头、出入门控制终端、出入门控制系统、自动门、BLE、UWB、工业传感器、LoRa网关、空气质量监测仪、一氧化碳监测仪、紧急铃、信标、工业信标、实时位置跟踪、建筑安全管理、物流库存管理、资产管理、患者位置跟踪、制造流程管理、智能解决方案、访客分析、ORBRO、Kongtech、orbro、RTLS',
        desc: '我们是一家可信赖的合作伙伴，提供各种产品和服务的免费报价。我们根据客户需求提供定制报价，将价格透明度和客户满意度置于首位。通过报价请求，发现高效和经济实惠的解决方案，并获取推进项目所需的信息。',
        url: 'https://orbro.io/cn/pricing/1'
    },
    schedule: {
        title: '预约咨询 :: ORBRO，数字转型的开始',
        key: '位置跟踪、摄像头位置跟踪、AI摄像头、出入门控制终端、出入门控制系统、自动门、BLE、UWB、工业传感器、LoRa网关、空气质量监测仪、一氧化碳监测仪、紧急铃、信标、工业信标、实时位置跟踪、建筑安全管理、物流库存管理、资产管理、患者位置跟踪、制造流程管理、智能解决方案、访客分析、ORBRO、Kongtech、orbro、RTLS',
        desc: '我们是一家可信赖的合作伙伴，提供各种产品和服务的免费报价。我们根据客户需求提供定制报价，将价格透明度和客户满意度置于首位。通过报价请求，发现高效和经济实惠的解决方案，并获取推进项目所需的信息。',
        url: 'https://orbro.io/cn/schedule'
    },





}