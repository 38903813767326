import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


    helmet: '',
    desc: 'RTLS technology is a real-time location tracking system that utilizes various technologies such as wireless communication, RFID (Radio Frequency Identification), GPS (Global Positioning System), BLE, UWB, etc., to track the location of objects or individuals.',
    keywords: 'RTLS, location tracking, indoor positioning, UWB, BLE, GPS, RFID, SmartDevice, construction site, safety management, medical equipment tracking, logistics tracking, asset management, visitor tracking, visitor management',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'What is Real-Time Location System (RTLS)? :: ORBRO Blog',
    ogUrl: 'https://orbro.io/en/blog/rtls',
    ogKey: '',
    ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOa-nRYXA8PZImoRPlC90eAt55C_Yy5HojqGpcHjostLqtAF14MCSQ278cDyuN65UTXHiZHKvWeZoTRbQjbqQfGXnKhLe0Jzybk5GEEeDIBPuaQ2yJMVTE7cYE4ybvDVweuPcGID5jW-Km3DtdqhciBpUOpej1uGnUdDTETQFhnYEqhziXT9YHAFI273KfzuCNJ_uUqla7WVrXuO-L-JB9VOb02pjgn9OBX7ABY94YhcQuq6a-Ab-nBcHbSmBNGXO1A8mMU-tX8hUz0u8674fbuG1dG0s39XddJNEeATt4PPsUeHudjCnqM1mN5HYXmvKTbQDEwzHBUET0GnnPpP4qVOG863atiB02qL8oopfM9EARdQjyJvxwLzBI1I9gkPm8wG4--IG7ViMVK4wRq79qmHgONSyFMJoqVcMChZeB701Ly6tordATCjgSlwZmG4O0I9W-G0ngTMbYP3AFihY2RPoQic3cTW_4jhftk7gMEPACbeZIh6jokwFH2KqMxpAVFKgew8f6Xr5lEiMx1C32laNCJfgRS6Xcnp4Oz68hKxcpR_Fn2nHamfrquJVPM7rKuEcnfndeSOs3wrBpMk-FMkx3hgt1QqB4LW0OLmWNWl5ps3dhN2NItej8os9Zeh_2wcCMnFaM_hbDj-UrbDNl7QDg_oGk9Etqxc2xrfS1yM5uMP78VIHwgPqrsHx6RqMEtgLZFjVCRBhr_n61ELA0YFi3gN-ybLRz3qBXMpkAq9iGl79fwmZuKwVEMnOvMPMsGpxAwM-c2O_WDEF4Ux-6lUxX7d9BGAPDPSwRvcwfF8rfLhEnz_wpeT-Os5mApgdVujb0fYri1b7J9ghi54_nZCyDPci8vn-GpyhH0k6Y4b_g_m723hFbaw1OfNPXx1uc-riT8pR82xvGhJ_EtOPsWIXtaEtiljYneEBpDa24VRkJt5afbOwE99dwRbNKrrlPJc-uZBNlNy9NsH8_2tYFjt1fi2WO6DmDukQqiW3eiYKAY8rINaMN4i38u5wJT1AVlykMVMcqtcugjpg3MBh066wvi5rSKgh-P-2H7qVk58IrARg3z24QXHaA4KPkicsJEFI5I11-x0daCC5h3lo0BV-grpGI7fGDWd_eOyj76t509E6BIPpx6DQ3B_TawRKRHGCRXdagn5iJy66zrQfuaHi78tKYqs_N8Noa8_Un3XstK2nD_Y86r-SmsLonEhHAZFoCJ-yv3YbRA_fVz1QIDIxBNdfifkvLjy6SlgtRb84_XzUPKjcMBalkMWrjzOSWYvShQ8OHTopeZK1HJdDLBzbdi2a7sTKpULhRBcWWDzLkgDrdgQHPsc_gkq9ZnisEl3GNOElWsbItVNyDdeXriH5YdSibObkZStczC8FKOdKf1urhaY95eEUrEgFKtTDL44ZXtLgM7KbxUrLqXmp8_ZZT-IPBJss-j3ydlHfcbCQ2Vr0eXK_Alu4os65IyR3hgGYmYcmtci14dWMSKJjZmyQ6LqBV9bwNmfQxcm2CqnImGEdAVbfpHuy1tcwaJ4aZq8X6K4q-7Np--NMO-2u-ULgFP8OfGDSLgEvAfn5JOyig=w2059-h1216',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'What is Real-Time Location System (RTLS)?',
    date: '2023-04-17',
    tag1: '#locationTracking',
    tag2: '#RTLS',
    tag3: '#UWB',
    tag4: '#BLE',

    rtls: {
      title: 'What is RTLS Technology?',
      desc: `RTLS (Real-time Location Systems) is a real-time location tracking system that utilizes various technologies such as wireless communication, RFID (Radio Frequency Identification), GPS (Global Positioning System), BLE, UWB, etc., to track the location of objects or individuals. <br/><br/>RTLS technology tracks and collects real-time location information, enabling its utilization in various industries. It can provide accurate and efficient production management and logistics services in industries such as logistics, manufacturing, construction, and retail. RTLS technology is applied in different ways depending on the industry. For example, in the manufacturing industry, it can be used to track the location of equipment or products within the production process, while in the logistics industry, it can be used for inventory management and distribution tracking. In the construction industry, it can be used for safety management, equipment and material tracking, and so on. RTLS systems are deployed within a company's unique space and aim to monitor and improve processes rather than tracking assets over long distances.<br/><br/>There are three key elements to RTLS technology.`,
    },

    realtime: {
      title: '1. Real-Time Tracking',
      desc: 'RTLS technology allows real-time tracking of assets, providing accurate and up-to-date information on their location throughout their lifecycle. This enables companies to systematically monitor and manage asset locations, leading to improved production line management, logistics services, and safety management.',
    },

    predict: {
      title: '2. Trend and Prediction',
      desc: 'By measuring the location and movement of assets, it becomes easier to identify avoidable errors, unnecessary steps, resource shortages, and abnormal employee behavior in processes. This enables early detection of potential issues in various fields such as production lines, logistics, and safety management, leading to more effective management.',
    },

    geo: {
      title: '3. Geofencing',
      desc: 'Defining virtual boundaries in a 3D space is useful in various fields such as ensuring employee safety, preventing contamination, and enhancing security. It helps protect employees from hazardous areas, prevent contamination, and strengthen security.',
    },

    howItWorks: 'How does RTLS Work?',

    core: {
      title: 'Core Components',
      desc: 'An RTLS system consists of three main components: transmitters, receivers, and software that integrates and analyzes location data. Each RTLS provider may use slightly different terms for their technology and emphasize their unique selling points. The following is a generalization of these core system components.',
    },

    send: {
      title: 'Transmitters',
      desc: 'In RTLS, transmitters primarily refer to the devices connected to tags or receivers. These devices identify and transmit location information of objects or individuals to receivers. Transmitters typically operate using wireless technology, and their size and performance may vary depending on the technology used. Transmitters can be attached to objects or worn by individuals or animals. Some models use "passive" transmitters that do not require power, which utilize short-range RFID technology. Other models use "active" transmitters that require power, often using wireless technologies such as Bluetooth Low Energy (BLE) or Ultra-Wideband (UWB). GPS tags can also be used as transmitters.',
    },

    rfid: {
      title: '1. RFID Tags',
      desc: 'One of the most common tags, which operate passively (i.e., without requiring battery power) and are used to identify assets by attaching them to almost any object.',
    },

    ble: {
      title: '2. BLE Tags',
      desc: 'Transmitters that emit BLE signals, which are one of the most widely used wireless communications. They have broad compatibility with most mobile devices but offer lower accuracy compared to UWB.',
    },

    uwb: {
      title: '3. UWB Tags',
      desc: 'Transmitters that typically emit battery-powered UWB signals and are received by UWB receivers. These signals are very short but provide highly accurate location data with low power consumption using ultra-wideband frequency bands.',
    },

    smart: {
      title: '4. Smart Devices',
      desc: 'Various smart devices can operate as transmitters, with mobile phones being the most common example. They can use both BLE and GPS methods, and some models support tracking using UWB, which has become more prevalent.',
    },

    gps: {
      title: '5. GPS Tags',
      desc: 'GPS tags determine the location using satellites. However, they only operate in clear outdoor environments and do not require additional infrastructure. GPS tags have lower accuracy and relatively higher power consumption compared to UWB tags. However, by adding real-time kinematic GPS, the main GPS signal can be augmented to improve accuracy to the centimeter level.',
    },

    receive: {
      title: 'Receivers',
      desc: 'Depending on the type of tags used, receivers used to track tags can vary. In more complex indoor environments where simple passive RFID tags are insufficient, active RFID, Bluetooth Low Energy (BLE), or Ultra-Wideband (UWB) technologies can be used. <br/><br/>By attaching tags to important assets and placing receivers around physical spaces such as factories, aircraft hangars, offices, etc., receivers can determine the location of each asset at any time and transmit this information to relevant software/visualization systems. These receivers are typically directly connected to a power source and network (wired or wireless).',
    },

    software: {
      title: 'Software',
      desc: `RTLS technology utilizes software systems consisting of firmware running on hardware such as sensors and tags, external applications that interpret location data, and middleware that connects to external applications. These software components work together to enable the functioning of an RTLS system. <br/><br/>Among these software components, the most important to companies looking to utilize RTLS technology is the software of external applications. This software interacts with and determines how to benefit from RTLS. Depending on the industry, the application software can vary significantly. Many RTLS companies offer customized software applications designed to optimize the overall operations of a company and provide easy insights. <br/><br/>Visualization typically plays a crucial role in RTLS systems, and factors to consider when selecting an RTLS software solution include: <br/><br/>- Floorplan and area mapping: Can the software accurately and to scale reflect actual floorplans? <br/>- Practicality of the software: Does it include essential functions tailored to the user's objectives, along with impressive visualizations? <br/>- Quality reporting: Does it offer analysis and reporting features for instant insights? <br/>- On-premises or cloud: Does it provide options to host the software in the most suitable way? <br/>- API integration: Can the software connect to existing business systems and databases via APIs? <br/>- Scalability: Is the software scalable as the business grows, and has it been proven to operate without bottlenecks during the collection of location data streams? <br/>- Support and education: How user-friendly is the solution and does it offer strong support for the critical application it serves?`,
    },

    conclusion: {
      title: 'What are the Use Cases of RTLS Technology?',
      desc: 'As awareness of RTLS technology increases, the industries where it can be applied become increasingly evident. Some representative examples include:',
    },

    advantage: {
      safety: {
        title: 'Construction Site Safety Management',
        desc: 'Real-time tracking and monitoring of workers, construction equipment, and material locations and movement paths to detect and alert potential risks, thereby preventing accidents.',
      },
      assets: {
        title: 'Logistics Warehouse Asset Tracking',
        desc: 'Location tracking technology allows real-time monitoring of asset locations, reducing the time and effort required for workers to locate assets. This improves operational efficiency and optimizes inventory movement paths, enhancing the efficiency of logistics processes.',
      },
      medical: {
        title: 'Medical Equipment and Patient Tracking',
        desc: 'Location tracking technology allows real-time monitoring of asset locations, reducing the time and effort required for workers to locate assets. This improves operational efficiency and optimizes inventory movement paths, enhancing the efficiency of logistics processes.',
      },
      monitor: {
        title: 'Real-Time Production Process Monitoring',
        desc: 'Real-time tracking of product locations, movement on production lines, and worker locations within manufacturing plants enables the optimization of production line movement, quick response to process issues, and improvement of production quality.',
      },
      visitor: {
        title: 'Exhibition Visitor Analysis',
        desc: 'Allows analysis of visitor occupancy rates by time slot in venues such as museums, exhibitions, and retail stores. This provides insights into visitor movement paths, visit frequency, dwell time, popular exhibits or products, which can be used to adjust the location or arrangement of exhibits or products to enhance visitor satisfaction.',
      },

      label: `RTLS technology is being utilized in various industries such as automotive, aerospace, defense, maintenance/repair/operations, public transportation, warehousing and logistics, and healthcare to pursue higher efficiency and safety. RTLS technology is crucial for enhancing a company's competitiveness. By using RTLS, companies can save time, reduce labor, and manage inventory and parts tracking more efficiently. Additionally, RTLS helps improve worker safety and reduce risks. <br/><br/>RTLS technology is becoming an essential technology for companies in many industries that strive for high levels of profitability and efficiency.`,
    },

    share: 'Did you like the article? <br/> Share it.',
  


    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/en/enterprise/construction/asset'
        }, 
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/en/enterprise/construction/safety'
        },
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/en/enterprise/logistics/asset'
        }
      ]
  
  
    }


  
}