import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


interface LayoutInfo {
  breakPoint: number;
  padding: number;
  gap: number;
  columns: number;
}

const DESKTOP: LayoutInfo = {
  breakPoint: 1280,
  gap: 32,
  padding: 24,
  columns: 12,
};

const TABLET: LayoutInfo = {
  breakPoint: 960,
  gap: 32,
  padding: 24,
  columns: 8,
};

const MOBILE: LayoutInfo = {
  breakPoint: 768,
  gap: 32,
  padding: 16,
  columns: 4,
};


export const StyledFaq = styled.div`
  justify-content: center;
  margin-bottom : 64px;

`


export const StyledProductContainer = styled.div`
  width: 100%;
  position: relative;
  justify-content: center;


  max-width: ${DESKTOP.breakPoint}px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
  }
`;
