export default {
  titleBanner: {
    subtitle: 'UWB | 超宽带',
    title: 'UWB 定位与定位技术',
    description: '*UWB 是一种无线通信技术，利用非常宽的频带来传输极短时间内的非常强大的信号。UWB 可以提供高精度和高数据传输速率，被广泛应用于定位和雷达应用等领域。'
  },
  tech: {
    title: 'UWB 技术是什么？',
    text: '超宽带 (UWB) 是一种用于无线通信的短距离 RF 技术，理论上能够以非常高的精确度检测人员、设备和资产的位置。与蓝牙和Wi-Fi等其他通信协议一样，UWB 可以通过无线波长进行设备之间的数据传输。为此，它使用了短纳秒脉冲在“超宽”的频率范围内进行通信。 <br/><br/> UWB 技术通过在跨越广泛的频谱（至少 500 MHz 或中心频率的 20%）上传输数十亿个无线脉冲并形成模式来工作。这些信号在发射器之间或发射器与接收器之间传输。接收设备会分析并转换传入的模式数据。通过这种方式，设备能够快速传输数据并准确地检测 UWB 信号用于定位设备的位置。采用 UWB 功能的设备（如智能手机或带有传感器和锚点的设备）能够准确地定位其他智能手机或资产追踪标签等发送设备，并在特定应用程序中实现位置感知通信和服务。 <br/><br/> 虽然 UWB 技术在最近才开始在最新的智能手机和其他技术中采用，但它已经应用了数十年。长期以来，它通常以“脉冲无线电”之名在军事雷达和通信应用领域使用。自从 2002 年 FCC 批准了无许可使用后，UWB 技术一直在创新和发展，成为释放创新的室内位置技术的起点。'
  },
  selection: {
    title: 'UWB 采用率增加',
    text: '许多大型企业已经意识到 UWB 的各种潜力，开始开发或推出 UWB 解决方案。其中，值得关注的公司之一是 Apple，它在 iPhone 11 上推出了新的 U1 超宽带芯片，开始实现 UWB 技术。其他公司如三星等也随之在部分最新的智能手机和其他设备中实现了 UWB 技术。谷歌也在为 Android 设备开发使用 UWB 的应用程序接口（API），使其成为可能。 <br/><br/> 现在已经有足够小的硬件组件可内置在各种类型的设备中，成为成本效益的选择。NFL 甚至在运动员的实时位置和动作追踪中使用超宽带技术。他们在运动员的肩垫上安装 UWB 芯片，以分析联盟在比赛中运动员如何移动。NXP 和大众汽车正在探索在汽车中使用 UWB，以提供更安全和便捷的车辆体验。此外，UWB 技术的领军开发者包括 UWB 联盟和 FIRA，以及各种 UWB 硬件公司。他们根据特定的使用案例提供各种 UWB 芯片、锚点和标签。一些专家预测，UWB 技术将像蓝牙和 Wi-Fi 一样被广泛使用，并取代 BLE 成为主要的短距离通信和定位标准。'
  },
  feature: {
    title: 'UWB 的特点',
    text: 'UWB 技术在短距离无线通信标准中有许多独特的优势。该技术可以在短距离内提供非常高的数据传输速率，并能准确地确定实时位置。UWB 技术在 3.1GHz 到 10.6GHz 的非常宽的频谱范围内以高带宽运行。此外，它的功耗非常低，可以实现低成本和高效的硬件选项，如资产追踪标签，这些标签可以通过硬币电池供电，无需充电或更换多年。 <br/><br/> UWB 能够非常准确地检测位置的原因在于它通过时间-到达时间 (ToF) 检测距离。当无线波从一个设备传输到另一个设备时，它计算了所需时间，从而得出发射器和接收器之间的距离。这种方法通常仅在短距离内起作用，但在最佳条件和配置下，UWB 可以将设备的位置精确到小于 50 厘米，并显示非常低的延迟时间。相比之下，其他技术通常使用接收信号强度指标 (RSSI) 来确定位置，其准确性可能仅限于几米范围。 <br/><br/> UWB 信号的低功率传输和与周围窄带技术几乎没有干扰的能力，使其成为与窄带 RF 技术共存的优选选择。这些独特的特性使得 UWB 技术在室内位置和短距离通信领域迅速崛起。'
  },
  howItWorks: {
    title: 'UWB 定位的工作原理',
    text: 'UWB 可以通过 ToF 来确定位置。这意味着它可以准确测量信号在设备之间移动所需的时间，并精确测量发送器和接收器之间的距离。在某些场景下，它甚至可以检测设备位置的 X、Y 和 Z 坐标，从而为 UWB 提供额外的定位维度。根据使用案例或应用程序的不同，ToF 的计算方法可能有所不同。 <br/><br/> 在 UWB 位置测量中，可以使用两种基本技术：Time Difference of Arrival (TDoA) 和 Two-Way Ranging (TWR)。',
    items: [
      {
        title: 'Time Difference of Arrival (TDoA)',
        description: 'TDoA 使用在整个室内空间固定位置的 UWB 锚点或传感器。这些传感器检测 UWB 设备（如追踪标签）并确定其位置。固定锚点必须完全同步并在相同的时钟下运行。UWB 标签或其他设备定期发送信号。这些信号由通信范围内的所有锚点接收并标记时间戳。然后，所有时间戳数据被传送到中央 IPS 或 RTLS。 <br/><br/> 位置引擎通过分析每个锚点的数据和到达时间差来绘制双曲线，并通过双曲线的交点计算标签的坐标。这些坐标可以在室内地图上可视化设备的位置，也可以用于不同的应用程序中的其他用途。'
      },
      {
        title: 'Two-Way Ranging (TWR)',
        description: 'TWR 使用双向通信来测量两个设备之间的距离。例如，当智能手机等设备彼此靠近时，两个设备开始测量彼此之间的距离。在两个设备之间传递信号所需的时间乘以光速，可以确定它们的相对位置。这种技术通常用于实现位置感知通信。 <br/><br/> TWR 使用一对设备之间的双向通信来测量距离，而这些设备之间确定的位置根据特定的应用程序用途而使用。TWR 可以用于固定锚点和 UWB 设备，但 TWR 过程通常仅使用一个测量伙伴一次来确定设备的位置。'
      }
    ]
  },
  accuracy: {
    title: 'UWB 定位有多精确？',
    text: 'UWB 可以通过 ToF 测量快速纳秒脉冲和与其他 RF 传输的有限干扰，使其在最佳条件和配置下，能够以50厘米以下的精确度准确地追踪室内位置，实时结果可以快速追踪设备的位置、移动和动作。 <br/><br/> 与其他室内定位技术相比，UWB 提供了出色的位置精度。其他标准通常使用接收信号强度指标 (RSSI) 来确定位置，BLE (< 5 米) 和 Wi-Fi (< 10 米) 等技术的精确度可能有限。有些 UWB 技术通过方向检测实现更精确的位置测量，这需要使用能够测量接收信号的角度的多天线设备。'
  },
  range: {
    title: 'UWB 通信范围是多少？',
    text: 'UWB 可以在 200 米以下的范围内检测设备位置。然而，通常在 1-50 米的短距离内效果最好，在这个范围内的应用程序可以实现最高的精确度。超过 50 米的范围可能会降低 UWB 的精确度，因为信号可能会受到多径传播和干扰的影响。 <br/><br/> 对于大多数室内定位和追踪应用，短距离的覆盖范围通常是最有用的。这涵盖了许多应用，例如室内导航、智能家居设备控制、设备追踪和位置感知通信等。然而，对于某些应用，如室外定位或需要广泛覆盖范围的特定应用，可能需要多个 UWB 锚点或信标来实现所需的范围。'
  },

  difference: {
    title: 'UWB与其他定位技术有何区别？',
    text: '每种RF标准都有其独特的特性和优势，并且根据个别需求、预算、设施以及特定位置相关用例，可能会有适合的选择。 UWB与其他技术之间最重要的区别是其宽广的频谱范围和提供的精度级别。 UWB的精度在需要绝对位置和实时移动的重要资产跟踪场景等高级室内定位用例中特别有效。',
    items: [
      {
        title: 'UWB vs 蓝牙',
        description: 'UWB和蓝牙（BLE）具有许多共同点，例如低功耗、低成本、优势资产跟踪技术等。然而，UWB可以提供比蓝牙更高精度的定位。这是因为UWB使用ToF（Time of Flight）来实现基于准确距离的定位方法。 BLE定位技术通常通过RSSI（接收信号强度指示）来定位设备，这样会提供较低水平的精度，因为设备是否发送强信号或弱信号对其产生影响。 BLE的范围和数据速率比UWB要小，并且对信号干扰更敏感。蓝牙拥有庞大的生态系统，并且是非常流行的定位技术之一。它被广泛应用于许多无线设备，并且可以轻松实现像BLE信标等灵活的硬件选项。'
      },
      {
        title: 'UWB vs Wi-Fi',
        description: "由于Wi-Fi在设备和室内空间中的广泛应用，它成为室内定位技术的核心RF技术。在高级定位场景中，Wi-Fi的精度和灵活性可能受到限制。在这些高级应用中，UWB技术非常出色。 Wi-Fi使用的是信号强度而不是距离来进行测量，因此其精度较UWB较低，并且更容易受到干扰的影响。而UWB具有强大的抗干扰能力，因此在干扰问题方面较少。此外，UWB需要较少的功耗，因此可以制造出像硬币电池供电的有用且经济的工具，例如资产跟踪标签等。然而，Wi-Fi可以利用支持Wi-Fi的各种设备和接入点等现有基础设施，因此在不需要高精度定位时仍然是非常重要的室内定位技术。"
      }
    ]
  },
  advantage: {
    title: 'UWB的主要优势',
    items: {
      accuracy: {
        title: '精确度',
        description: 'UWB技术提供亚厘米级的精度，在高级室内定位应用中是优秀的选择，为以前不可能实现的各种功能提供无限可能。'
      },
      position: {
        title: '实时定位',
        description: 'UWB技术提供非常快速的位置确定结果。通过纳秒级的快速通信，它可以实现真正的实时室内位置确定和追踪，延迟极低，每秒更新超过100次，可以检测到人员、资产或车辆的位置、移动和动作。'
      },
      security: {
        title: '可靠性与安全性',
        description: 'UWB与其他无线电波几乎没有干扰或重叠，因此可以用于小范围的非常安全的通信。利用接收设备的准确位置，UWB还可以用于小距离内的安全通信。'
      },
    }
  },
  useCase: {
    title: 'UWB定位的使用案例',
    description: 'UWB的精确度在需要高度精确度的高级室内位置确定用例中非常有效。 UWB使未来的RTLS技术成为可能，并在各种资产跟踪应用中表现出色。以下是当前使用UWB的使用案例和应用领域，以及未来可能的领域。',
    card: {
      physical: {
        title: '物体跟踪',
        description: 'UWB的高精度是资产跟踪的重要RF标准。许多行业的组织可以利用UWB的准确性、快速性和可靠性，以优秀的精度追踪重要资产和设备的实时位置和状态。',
        items: [
          {
            name: '企业',
            description: '大型企业校园和设施内的资源、资产和设备可以清晰地掌握，从而提高生产力和资源分配。'
          },
          {
            name: '医疗',
            description: '利用资产跟踪功能，快速找到并跟踪重要设备如呼吸机或轮椅的准确位置。'
          },
          {
            name: '仓库管理',
            description: '有助于在大型设施中找到设备、工具和库存物品的位置，特别是需要精确确定库存位置的情况下非常有用。'
          },
        ]
      },
      employee: {
        title: '员工和人员跟踪',
        description: '组织可以利用UWB的强大准确性，确定员工和人员的准确位置，从而驱动位置感知应用。',
        items: [
          {
            name: '员工安全',
            description: '在紧急情况和撤离时，准确迅速地确定员工或人员的准确位置至关重要，这时UWB的高精度尤为重要。'
          },
          {
            name: '工作场所准备',
            description: '利用UWB员工标签和徽章，实现精确的接触追踪，以支持室内空间的疾病传播预防和缓解，满足卫生规定和要求，提供物理距离感知和有效的卫生指南等。'
          },
        ]
      },
      location: {
        title: '位置服务',
        description: '通过利用人员和设备的准确位置，组织可以建立智能建筑，便于各种互动和消息传递等。',
        items: [
          {
            name: '近场消息',
            description: '引入UWB室内位置跟踪技术，识别客户和资产附近的目标位置，并利用此数据实现直接的客户互动，例如优惠券、营销活动、附近景点等超本地内容。'
          },
          {
            name: '地理围栏',
            description: '在室内空间的各种区域周围创建虚拟地理边界，当用户进入、离开或停留在指定区域时，可以执行特定操作。'
          }
        ]
      },
      navigation: {
        title: '室内导航',
        description: '利用UWB定位技术的高精度，通过室内导航和路径规划功能，使空间立即变得熟悉和可探索。',
        items: [
          {
            name: '增强现实（AR）',
            description: 'UWB室内全球定位能力为组织创造出高度本地化且身临其境的增强现实体验提供了新的机遇。'
          },
          {
            name: '蓝点导航',
            description: '通过蓝点体验，用户可以精确定位自己在室内空间的位置，并实现逐步导航和路径规划。在企业、医疗、零售、酒店、交通等多个行业中，能够为室内提供特别准确的体验。'
          }
        ]
      },
      business: {
        title: '商业智能',
        description: 'UWB可以收集令人惊讶的准确位置数据，并将其转化为强大的洞察力。',
        items: [
          {
            name: '分析和商业智能',
            description: '通过了解资产、人员和设备的位置和移动，随着时间的推移对这些位置数据进行分析，了解访问者与室内空间的互动方式，从而提供更智能、信息化的商业决策视野。'
          }
        ]
      },
      b2b: {
        title: '消费者基础和B2B使用案例',
        description: 'UWB正在开拓许多创新的消费者基础使用案例，以实现短距离通信和位置信息的变革。',
        items: [
          { name: '智能建筑和出入控制' },
          { name: '点对点通信' },
          { name: '智能医疗设备' },
          { name: '车辆进出控制' },
          { name: '安全的位置基础移动支付' },
          { name: '智能家居' }
        ]
      }
    }
  }
}