import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4';

export default {

    desc: '스마트 항만은 디지털 트윈, RTLS, 인공 지능을 이용하여 항만 내부의 모든 활동을 실시간으로 모니터링하고 관리하는 종합적인 솔루션입니다. 이를 통해 실시간으로 항만 내의 모든 사물 및 프로세스를 모니터링하고 분석할 수 있습니다.',
    keywords: '스마트 항만, 디지털 트윈, 디지털 전환, digital twin, orbro, beacon, rtls, uwb, ble, ai camera, 오브로, 콩테크',

    ogSite: '스마트 항만 솔루션 | 위치추적과 디지털 트윈, 오브로',
    ogUrl: 'https://orbro.io/blog/smart-port',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmtMlMpuGaSkdrwhUm9cmk57NhnjuSqUyvAfLNAvWgGu0MM4L2R6eFvjBYvTVkkIk2f8QbkrMlttEBDfzi4pLKN9N-dhDu_JiYkV4BvDu2CLF5JFWrrMJgKJAPWObqYGbQYtBBMoquCCAYx_k8njELRIPt4_PKkk6KdhaLKf78OatyVEbgXKhqBqdCGxbhGw3jTDiqdbaImqLAMR3Wdos-IWpYXZYClx-DKC1P9dB3GLBP0ixLsNn9rpaH91rcH8RLcRUDTYzamMx1BMmNl89ZY8vTfts7eomL8NXjR9nEZZ5K-MkBjZT4KJ4o4iUQRE8CZDBT8xcueZCDOkMPkM1K3XgnM7okDR8U8xf_zJc0w6Ng2bEtDNjtB1h-vn2NNuBWuiIXgDTJG-ZlSbIFXuT1ySTt8d8L9s9vZffcfMLrKFRnY8Obtfn-WVUDAaXA68xwLtGJYP-NMEMyCqtdlswxY1nzpPdBpNTELVjP4NGkFiHk3Vb0xHr22sMgIzsoWUBuuZRiiz_Oadq3DWG64cWpzTeUzRxxnVc7lh1jqQCre0y1-PNTz8FmoHgjoNYQIGR8teD7k3s2g9v6_39LkKVnEp3LZg4K7fK_st9aGnXxXHgT38G1P_CyZ2NCfDR2h7757hijJWyc01v1e5DKey7CKzS5XKyz6VQitW_E20TWpbzbIO-rYeEzjZwRCbjcBhUVfQiSz2QcMbLTb3acfrCop4bSDap1c2tjNFn6BURfcFhtqYymgRGU1UCQ4ZuD_RO552LTgZgcyVENTq2v3A1n1qzhsNoSFHD5hJ2RA2ww4so8MDKBF2R5J6mer8Su6fEutD5F1ZUgGwSU4glTVu0jYCkS97SgUsjSJ-hWu10P40b1PbPIYjir7h7BTyDphRhnPDkigpYw4x2NeLO1ilNqDXxOLoRxOz3XM0ubgAHxvtFSl6urWE-MXOjXwCuu_8dCODgOBpzlHm_HA1FIREfuLH-sVZ9VKFvyFZZyxx4x12i7Gf8wfTFh20JL2uxFSplfv-8nvkqRaVPYrF5RGZ3jSwtPJP9OYAMhxVpgB1unGLMYL2-a0_s-q8lfbijvufnfa1O6P0UiqgkFwZ0HKLgLXHOD3DSaHMMSJkB9oSwFNFJgoIAciFzzPWPCw4Hs1wMO5q4ixJ1Jbvyu53pWL_tznPM5m5mIj1D_i2RMKAoNFDl4EMFRzXjMKHUWjTc2bt9knvzg3CUTzs7rDarMVqv2ELwEc2BJE5joJfnY7APOre8u84naPSATFCFeKvGxt0v6mruuozNIF4IZMC2XhhGDHbQasJe1Vfab9-e18wVVD6fyEM0jLw50EPpOh5rTUJE02ppq_cHwPe2ZUQ10K7oC0LZoy05ga5pL6tfHnt65GWSUg6ehpUe7RkFH0vOMvEPWln4Zl3XiUjnmVQ_SAN-gWxtOswmhrO4QavIJmTUrR9UmDxFLOAjeUDHPsYc0XYFpmyLYyiAuDr095MknMbE1K0WjCf9kfZy_O14qr-6UpIl7J8vTNKxXQX1RKB9t7N_rO0S0Q1UgEj02peJ30KvmNifvU3gDY1huNYAcxzOUSC0kfxpI=w2110-h1171',
    ogType: 'website',

    title: '스마트 항만 솔루션',
    date: '2024-01-25',
    tag1: '#스마트항만',
    tag2: '#RTLS',
    tag3: '#위치추적',
    tag4: '#디지털트윈',

    // <br/>
    text: [
        {
            head: '스마트 항만이란?',
            subHead: '',
            desc: '스마트 항만은 디지털 트윈, RTLS, 인공 지능을 이용하여 항만 내부의 모든 활동을 실시간으로 모니터링하고 관리하는 종합적인 솔루션입니다. 이를 통해 실시간으로 항만 내의 모든 사물 및 프로세스를 모니터링하고 분석할 수 있습니다. 컨테이너의 위치, 상태, 및 운송 정보와 같은 중요한 데이터를 수집하고 업데이트하여 항만 관리자들이 빠르게 의사 결정을 내릴 수 있도록 도와줍니다.<br/>이 블로그에서는 스마트 항만 솔루션에 대한 개념과 이점, 그리고 그 중심에 있는 디지털 트윈과 RTLS 기술에 대해 알아보겠습니다.',
            dot: ''
        },
        {
            head: '스마트 항만 기술',
            subHead: '디지털 트윈',
            desc: '디지털 트윈은 물리적인 시스템, 구조물 또는 프로세스를 정확하게 복제하는 가상 모델을 생성하는 기술입니다. 주로 항만 시설물과 화물 운송 프로세스의 디지털 복제를 생성하여 실시간으로 모니터링하고 최적화합니다. 그리고 컨테이너 위치, 크레인 동작, 차량 이동 경로 등을 디지털 트윈 모델로 표현하여 문제를 사전에 예측하고 효율적인 운영을 가능하게 합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: 'RTLS (Real-Time Location System)',
            desc: 'RTLS는 실시간 위치추적이라는 뜻으로, 물체 또는 이동 물체의 실시간 위치를 정확하게 추적하는 기술입니다. 주로 UWB, RFID 태그, 카메라, 비콘 기술 등을 활용하여 정확한 위치 정보를 수집하고 관리합니다. 이로써 컨테이너, 트럭, 선박의 위치를 추적하며, 화물 운송 경로 최적화, 화물 상태 모니터링 및 안전성 향상에 기여합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '인공 지능 (AI)',
            desc: 'AI 기술은 CCTV 카메라를 사용하여 항만 내의 이상 징후나 위험한 상황을 식별합니다. 이는 항만에서 발생하는 다양한 데이터를 수집하고 분석하여 예측 모델을 개발함으로써 물류 및 운송 효율성을 향상시키는 것이 가능합니다. 또한, 인공지능을 사용하여 항만 운영 중의 문제를 빠르게 감지하고 해결할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: 'IoT',
            desc: '센서와 통신 기술을 활용하여 항만 내의 다양한 시스템과 장비를 연결합니다. 뿐만 아니라, 온도와 습도를 비롯한 전반적인 현장 환경을 파악하고 관리하는 데에 필요한 데이터를 수집합니다. 이를 통해 실시간으로 항만 운영을 최적화할 수 있습니다.',
            dot: ''
        },
        {
            video: video,
            link: '/enterprise/manufacturing/process',
            head: "스마트 항만 기능",
            subHead: "실시간 재고 관리",
            desc: "스마트 항만은 센서 기술과 무선 통신 기술을 활용하여 화물의 실시간 위치를 추적할 수 있습니다. 이를 통해 항만 관리자는 언제든지 특정 화물의 위치를 파악하고, 물류 프로세스를 효율적으로 관리할 수 있습니다. 화물 추적은 정확한 ETA(Estimated Time of Arrival) 계획을 가능케 하며, 이는 물류 계획의 효율성과 정확성을 향상시킵니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "디지털 트윈을 활용한 운영 최적화",
            desc: "디지털 트윈은 항만 시설물이나 운송 수단을 디지털적으로 모델링하여 실시간으로 관리하는 기술입니다. 항만 내의 시설물 상태를 실시간으로 감시하고 예측 분석을 통해 유지보수 일정을 최적화하며, 물류 및 운송 프로세스를 효율적으로 운영할 수 있습니다. 디지털 트윈을 활용한 운영 최적화는 항만 시설물의 수명 주기를 연장하고 시설물 관리의 효율성을 향상시킵니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "자율 주행 차량 및 로봇의 활용",
            desc: "스마트 항만은 자율 주행 차량과 로봇을 통해 화물의 운송이나 특정 작업을 자동화합니다. 자율 주행 차량은 화물을 효율적으로 이동시키며, 로봇은 필요한 작업을 자동으로 수행하여 노동력을 절감하고 생산성을 향상시킵니다. 이로써 항만 내의 화물 이동 및 작업 프로세스가 자동화되어 더욱 높은 효율성을 실현할 수 있습니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "비용 절감과 효율적 자원 관리",
            desc: "스마트 항만은 물류 및 운송 프로세스의 자동화와 효율화를 통해 비용을 절감하고 효율적인 자원 관리를 실현합니다. 자율 주행 차량과 로봇의 도입은 인력 비용을 줄이면서 생산성을 높일 수 있습니다. 또한, 디지털 트윈을 통한 시설물 관리의 최적화는 유지보수 비용을 감소시키고 에너지 효율성을 향상시킵니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "실시간 안전 및 보안 감시",
            desc: "RTLS 및 CCTV 등의 기술을 활용하여 항만 내의 운송 수단 및 인력의 위치를 실시간으로 모니터링하고, 이상 징후를 탐지합니다. 이를 통해 비인가된 접근이나 안전 문제를 빠르게 대응하여 항만의 안전성과 보안성을 강화합니다. 실시간 감시는 사고 발생 시 빠른 대처를 가능케 하여 항만 시설물과 인력에 대한 안전을 증진시킵니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "데이터 기반 의사 결정",
            desc: "빅데이터 및 인공지능 기술을 활용하여 항만에서 생성되는 다양한 데이터를 수집, 분석하고 예측 모델을 구축합니다. 이를 통해 항만 관리자는 실시간으로 변화하는 환경에 대응하며, 데이터 기반의 의사 결정을 통해 운영 효율성을 극대화할 수 있습니다. 또한, 데이터 분석을 통해 물류 프로세스의 병목 현상을 식별하고 개선함으로써 전반적인 생산성을 향상시킵니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "환경 친화적 운영과 에너지 효율성",
            desc: "스마트 항만은 화물 운송 및 항만 시설물 관리에서 환경 친화적인 운영을 추구합니다. 물류 및 운송 프로세스의 최적화로 인해 연료 소비를 최소화하고 친환경적인 운송 수단을 활용함으로써 탄소 배출을 감소시킵니다. 또한, 디지털 트윈과 센서를 통한 시설물 관리는 에너지 소비를 최적화하여 항만의 에너지 효율성을 높입니다.",
            dot: ""
        },
        {
            head: '스마트 항만 전망',
            subHead: '',
            desc: '스마트 항만의 전망은 미래에 더욱 혁신적인 발전을 이룰 것으로 예상됩니다. 자율 주행 기술의 확대는 물류 및 운송 프로세스를 자동화하고 효율화하여 생산성을 향상시킬 것으로 예측됩니다. 디지털 트윈과 빅데이터 분석을 통한 운영 최적화는 항만 시설물의 유지보수 및 운영 일정을 더욱 효율적으로 관리할 수 있게 할 것입니다. 환경 친화적 운영과 에너지 효율성은 지속 가능한 항만 운영을 강조하며, 친환경 기술의 도입으로 에너지 소비를 최소화할 것으로 기대됩니다. 또한, 실시간 안전 및 보안 감시 시스템의 발전은 항만의 안전성을 향상시키고 비인가 접근에 대한 대응 능력을 강화할 것입니다. 스마트 항만은 물류 산업과 국제 무역에 더 많은 편의성과 효율성을 제공하며, 디지털화와 혁신의 중심지로 자리매김할 것으로 전망됩니다.',
            dot: ''
        },

    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/enterprise/construction/equipment'
            }
        ]


    }


}