import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    helmet: '| LoRaとは？',
    desc: 'LoRa（Low Range Radio）は、低電力かつ長距離無線通信のために設計された無線通信技術の一つです。この技術はセンサーネットワーク、産業オートメーション、特にIoTデバイス間の通信に適しています。',
    keywords: 'LoRa、無線通信、IoT、通信技術、RTLS、リアルタイム位置追跡、ORBRO、ConTech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'LoRaとは？ | 位置追跡とデジタルツインに特化したORBRO',
    ogUrl: 'https://orbro.io/jp/blog/what-is-LoRa',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkGw848hiaZwUj1NzOs30j6aqQAJyNCTeY0oQIeVJFOkeYuNI3kvZhnbc5uEfN7j4eLrviv8mEssXZn4lxH-dLhqXgRoLEtW86etie39T8Z4ZrsMxp-5A2G7W4VIP6BP_qFtyqDEJ_krIVKkxluM5vTPOKBMEyrGtQ_3ydm3DGKWHp7F-ShLBeXFtG1oET_EreJ_iYWOkT3CzAatE2_V77uE1qOcaLPuWCttx2d70Jt6u0a7tKI8nKCOheeIgrZqmaGNHLXtWSyJnPPkS083MmuPZv_AuP5JXZOe4h-xyzZcYCyo1E8vbbzRQZKjDcRj4CXwdfV6isxP_GMeoZXMvMMQR6ZL-CjdMHskmTwCTVRtYSQd-QRWtlRXn5bdxpcE5n2kgYENQIRBn73zqANbo6d7dbmHSgh3vqEzNP7yLk6mMQ-YNVO_04A1nNDlqhOaA9HQ7kkUtvDq1WsmkMAw2i169IEUISWz8pliXgKuRnPM_YOP8EWj0ewhPm6YtPrYizD5n9yo8sGI_B1E63PiHKz9hRNeRPSWKrkFPIlT-Y-adJ1giugNEpETW7dZOnr3u_FsbtzrPm6eOw5gQecQrBtDq14AN5olMMyI3_uGibjXoS3rutDYRvls1YlPMHv2iUBWhe9CmRSI9BsT-_tyscLg6cwWAhwfb9OdbWga3TI7dLo9b7s-8SFXm6s6Yqop9WgsB23-rOl_h4UAZHsjRu93uENXiNTD7YD-5VbvdSubD6Ffj3xkL3f9HdhilG3turAnuWLTcQPQ6f9yePOwuvoIrcmXntkKHwzoChR5WH4VoWk83o-UippkYQ3gzYCJJ8-4yd9JZg-TUA3-n9KHJvWyoaCjlaOTCkcBygN_c4WcWJgu3zui6gzBVZTv3oyuKzJzYYslQ89doq9Lh_T7yohz1vbGyDjOJ7gr-onVsc-yfw0_f3apHYvqfZKsSE_9DLZ6G9uzBBotKH-KPTnvEp3OA-2poPSP5Z-DsbLOymfPnvpMPs5ZsiCGh7Jvomyr5pxJcJL19u6HKf3979fiLresgN9QVAef0DmZTEYu76a10CjiW20Ne3C15KDO6Q2ICYOLpVyObPTZc6gGrvgED_Xr2Ul4We1Nc38VxTaLYoPMhzbg8SIe6AZ_UzExO4tZtDfX1hU-5kzIRBq2lXR1cKGXLvP8B0VUshx8iDhwWXOkUPuCyymV8Ng2j6JQyXZr0H2lyhO-b4WgS3aP5efeg_Ny64v_sUBQVG9wKL2FSxalXpQy5LHTrrbPV3akp2a7BeoMondBr7DVQnCTG8sc1oOJb-ZkAtOR05xmrGM2l4A0PlhelYg6RIyPfBvkGI5PV8lh3yPOUQVQCXBzqm_G6LYCRbqWdX5-3BlFEh4-fQpy6luoA1LossKR8VX0DXX8e9lcSJT17c2nXpV9w1mjyWuW8rYGBCeiiyulrgkobyIG_fSCd3TWmdr10IZU-_4msP81pWVBQ0iqdbEp4g-5cuufyYZ-hJo1dOOQGOsizdKKRg_-ndSZc_0nSCjjoEIoxoIl_wZN08371fZ_3FzYFTjFYkbcO9F=w1834-h1208',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'LoRaとは？',
    date: '2024-01-15',
    tag1: '#LoRa',
    tag2: '#IoT',
    tag3: '#通信技術',
    tag4: '#LowRangeRadio',

    // <br/>
    text: [
        {
            head: 'LoRaとは？',
            subHead: '',
            desc: 'LoRa（Low Range Radio）は、低電力かつ長距離無線通信のために設計された無線通信技術です。この技術はセンサーネットワーク、スマートシティ、農業オートメーション、産業オートメーションなど、様々な応用分野で使用され、特にIoT（Internet of Things）デバイス間の通信に適しています。この技術は周波数帯域を効果的に活用し、数十キロメートルに及ぶ通信距離を提供し、低電力消費でバッテリー寿命を延ばします。また、コスト効果があり、セキュリティを備えており、さまざまな応用分野で活用されています。',
            dot: ''
        },
        {
            head: 'LoRaの特徴',
            subHead: '長距離通信',
            desc: 'LoRaは数十キロメートルに及ぶ通信距離をサポートし、主に農業地域や離れた場所でのセンサーデータの収集やモニタリングに有用です。',
            dot: ''
        },
        {
            head: '',
            subHead: '低電力消費',
            desc: 'LoRaは低電力通信を特徴とし、バッテリー駆動のセンサーデバイスなどの低電力デバイスで長時間動作できます。これにより、センサーネットワークのノードがバッテリーの交換や充電が少なくて済むようになります。',
            dot: ''
        },
        {
            head: '',
            subHead: '周波数帯域の活用',
            desc: 'LoRaは主にISM（Industrial, Scientific, Medical）バンドを使用しており、868MHz（ヨーロッパ）や915MHz（アメリカ）などが該当します。これらの周波数帯域は無料で利用でき、低電力デバイスに適しています。',
            dot: ''
        },
        {
            head: '',
            subHead: 'コスト効果',
            desc: 'LoRaは比較的低コストで実装できます。これにより、大量のIoTデバイスを構築する際にもコスト効果的に活用できます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'スタートポロジ',
            desc: 'LoRaネットワークは主にスタートポロジを使用し、各デバイスがゲートウェイと直接通信します。これによりネットワークの拡張が容易であり、各デバイス間の通信が迅速に行われます。',
            dot: ''
        },
        {
            head: '',
            subHead: '拡張性と柔軟性',
            desc: 'LoRaは様々な応用分野に柔軟に適用できます。また、ネットワークに新しいデバイスを簡単に追加して拡張できる特性を持っています。',
            dot: ''
        },
        {
            head: '',
            subHead: '暗号化とセキュリティ',
            desc: 'LoRaはデータ転送中に暗号化を提供し、センサーデータの安全な転送を確保します。また、LoRa技術はオープンスタンダードとして開発されているため、様々なメーカーが互換性のあるデバイスを提供できます。',
            dot: ''
        },
        {
            head: 'LoRaの利用事例',
            subHead: '農業オートメーション',
            desc: 'LoRaは農業でセンサーネットワークを構築し、作物の状態や土壌条件などのデータをリアルタイムで収集し分析することで、農業生産性を向上させるために活用されています。',
            dot: ''
        },
        {
            head: '',
            subHead: 'スマートシティ',
            desc: 'LoRaはスマートシティで様々なセンサーやデバイス間の効率的な通信を提供し、都市のインフラ管理、環境モニタリング、公共安全システムなどに適用され、スマートシティの発展を促進しています。',
            dot: ''
        },
        {
            head: '',
            subHead: '産業オートメーション',
            desc: '産業オートメーションでは、LoRaはセンサーと機器間の通信をサポートし、生産プロセスを最適化しモニタリングします。これにより生産効率が向上し、コストが削減されます。',
            dot: ''
        },
        {
            head: '',
            subHead: '環境モニタリング',
            desc: 'LoRaは自然環境モニタリングに適用され、大気の品質、水質、気候などのデータをリアルタイムで収集し、環境の変化をモニタリングし対応するために使用されます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'インテリジェントビル管理',
            desc: 'LoRaはビル内のさまざまなシステムとの通信を通じてエネルギー使用、セキュリティシステム、空調制御などを効率的に管理し、インテリジェントビルの構築に貢献しています。',
            dot: ''
        },
        {
            head: 'LoRa技術の将来展望',
            subHead: 'データ標準化と規模拡大',
            desc: 'LoRaのデータ標準化と規模拡大の取り組みにより、様々なメーカーやサービスプロバイダー間の相互運用性が向上すると予測されています。これによりLoRaを利用したエコシステムが拡大し、グローバルなIoT市場での地位が強化されるでしょう。',
            dot: ''
        },
        {
            head: '',
            subHead: 'エッジコンピューティングとの統合',
            desc: 'LoRaはエッジコンピューティングと組み合わせることで、リアルタイムデータ処理とアプリケーションの実行をサポートし、スマートデバイスやシステムに対するより高度な自動化と知能化を提供すると予測されています。',
            dot: ''
        },
        {
            head: '',
            subHead: 'セキュリティの強化',
            desc: 'LoRaはより強力なセキュリティ機能を開発し、デバイス間の通信やデータ転送中のセキュリティを強化することが期待されています。これにより機密情報の安全な転送と保護に寄与します。',
            dot: ''
        },
        {
            head: '',
            subHead: '人工知能との統合',
            desc: 'LoRaは人工知能と統合され、データ分析と意思決定プロセスを強化することで、予測分析や自動運転技術など様々な分野で革新的なソリューションを提供すると見込まれています。',
            dot: ''
        }
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB端末を作業者や機器に取り付け、位置追跡をリアルタイムでサポートする製品です。',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/jp/enterprise/logistics/asset'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/jp/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/jp/enterprise/manufacturing/asset'
            }
        ]

    }

}
