import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4";

export default {
  helmet: '',
  desc: "利用UWB（Ultra-Wideband）的RTLS（Real-Time Location System）技术利用极宽的频带实现了高度精准的实时位置跟踪。该技术通过精确测量传感器和标签之间的距离，在室内和室外环境中提供位置信息。",
  keywords: 'UWB RTLS,掌握室内位置,资产追踪,非接触结算系统,Simart Home,健康管理,汽车安全,UWB,RTLS,GPS,BLE,数字孪生',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'UWB是什么，如何对商业有帮助呢？ :: Orbro Blog',

  ogUrl: 'https://orbro.io/cn/blog/uwb',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EObeDoLPWDY0m4J6RG5ivUvly0sFp1k2N8pJ6vwHvfEotnTR-c97QjquFgftQfhSf8tMXrczrcxhZelBBXIc0FoSa6ZZgE8na0u8s66yPDrdq0UrGeG-BtDEHMU8P-DPTgUQcwgdX_770Njyx6SNDF4Bchooan8k2Ghgf5DDf0A-rOW2q8-J9DNpNr9pjZRVCg6601CXYmQ-4C_DWZQrQPOpbyvi0Utlc-Az8WhS41m0SPy_AVoqe-fxEuq53t-bNal_-D9ko7jhgr3HHIaTqLbFKWfu5W_yVuf3-clrf0CtlwYyWeQTD4-blr1Vt6UMEpe6g0xh8vMdEG9KwIOY0We-an0hHPhqHco-cxMhZqnXuKuhdRngKsVJnmNzhoAGDcEovW0xEdbwNGGnaXf9HgDuwEyz0O0hBJ1VdMLVrZeF3yG2w0uOYvT1w1CGsNnOvF1HQ2O1tBB0OUXGi7kOuhASd34xinm8ZUoaopcdehwOHmFkiWhblS3O86GDhcSHi64o5o9yPXeKJKcg3aX4M1WKjSD4u-v_h7Cz7j2Ew_QRGSJUkV0ZqKIYM-LnidAHxkTwv5b0vujpTQTybOQctctEpk07QqA7jx3zz1AbtSgtB4SMS3BVYQ-LDQPwmRnkVEi15VK5I33OFLSw8UAoibRcC2y68nu5w0ZmwZ3yZPgRbGUhwVnNrbzmmWjnhyW6agO6gnb3iAAYmxGgUZ0Bxe6sU3F_hRAOk3K5adG-GTihhhRZ6Ny-1YfLjwBN2XWS9CQmHOiNL2XKyUmDIRFnYf6My62MYUpTGkqD3QGMb5QWsmjsVvMDeYmPK2pRmdXC2VuzOpXv1I32Uij5MECw_kBbt9J59P_jtayKMJb7MPa9mZTsvEtK68fS4kpKPiTqgmY6Fpu81ySeLX6iMqgw2mW8BqPuEyOz5VlDQR8kXkleyutpFfgwMLd0gDe-6V-1w-4lb3u6ccuCTShoIl4rzEil0_RJbe2Oe3td0WW3KYtfT_1Oeihe7jPGmD0JZrOSemw8nMa-oWOjkMSMFpCBJOfSPxpx5I42Jupgd2vOsaIezrcSGCGzHpoXM2-ryc14nPGOUDOc-hA1eFFGqVgCGUQN2dUMHISspskEVJBPI7647sxsyCnd0IUJLN5NSHR_sHivHXsX3SAq9g2Tk3WwbdpT8PW2l9hw6FfuxvjBv8DfsEILwnTGOQQMQ8iiQIZLz9QyCq1OEEDethUDWNsTZipSUMrcWUwpyNxE5586r4DxKO0R_AIGUIxL7kAAwP5cRdFP7CxE1UBRhidxzIVJeIaoHrk-OtutFpM4iFhXD6VY_zZRNDnT74KvBOONaMhB3Lhc801EddUkWf0-35rZksJbpbnWR-H_yNKiCBv8UP9iHJoUWB84wFs2dUNq8sK7fy316q-Coz-Yc58zY_S07R8mK_SjMcdJPDpUc-XzfMyXvXGhYWScpZjP766CDtHOd_ArlWxMsLBupquNSUuHJJ6FWmIoUzq31LSeNqPvc7MH_ywbqQd_efx2p8c73E_NvkVCJP8OYYT8IP8iUh9v7tRMD0-JBjW6yzXknRIpicPYTQ=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'UWB是什么，<br/>对业务有何帮助？',
  date: '2023-04-25',
  tag1: '#UWB',
  tag2: '#工业自动化',
  tag3: '#资产追踪',
  tag4: '#室内定位',

  text: [
    {
      head: 'UWB是什么？',
      subHead: '',
      desc: 'Ultra-wideband（UWB）是一种在非常高频下以低功耗运行的无线通信协议。与其他无线通信协议（如蓝牙或Wi-Fi）不同，UWB使用电磁脉冲传输数据。这些脉冲通常只持续几纳秒，但具有数百兆赫兹至数吉赫兹的非常宽带宽。 <br/><br/>UWB的宽带带宽使其非常适用于需要快速和准确传输大量数据的应用领域，如工业自动化、机器人和自动驾驶车辆。此外，UWB还能够非常精确地测量两个物体之间的距离，因此在定位应用程序中非常有用。',
      dot: ''
    },
    {
      video: video,
      link: 'https://orbro.io/cn/enterprise/medical/patient',
      head: 'UWB对业务有何帮助？',
      subHead: '',
      desc: 'UWB在许多潜在的应用领域中都有用途，可以以多种方式帮助业务。以下是UWB最有潜力的一些应用领域之一。',
      dot: ''
    },
    {
      head: '',
      subHead: '室内定位和资产追踪',
      desc: 'UWB技术可用于精确追踪建筑物内或其他室内空间中资产的位置。这在库存管理、工具追踪、工具控制、流程监控、车辆追踪和需要精确位置数据的其他应用领域中非常有用。例如，如果您经营仓库，使用UWB可以实时追踪各个物品的位置，更容易找到和管理库存。',
      dot: {}
    },
    {
      head: '',
      subHead: '非接触式支付系统',
      desc: 'UWB技术可用于实现比传统解决方案更安全可靠的非接触式支付系统。通过使用UWB在移动设备和支付终端之间传输支付数据，可以确保支付安全进行，以及避免欺诈或非法访问。',
    },
    {
      head: '',
      subHead: '智能家居自动化',
      desc: 'UWB技术可用于构建比传统解决方案更准确、可靠的智能家居自动化系统。通过使用UWB来确定设备在家中的位置，可以创建更精细的自动化规则，根据特定位置或动作做出响应。例如，当有人进入房间时，灯可以自动打开，当有人离开时，灯可以自动关闭。',
    },
    {
      head: '',
      subHead: '健康管理监控',
      desc: 'UWB技术可用于监控医疗设施内患者的位置和动向，以确认他们是否接受到所需的治疗。例如，UWB可以追踪医院内患者的移动，以帮助快速找到需要帮助的患者。',
    },
    {
      head: '',
      subHead: '汽车安全',
      desc: 'UWB技术可提供更准确的车辆位置和动向数据，可用于汽车安全系统中。这在碰撞回避等应用领域非常有用，通过使用UWB准确判断两辆车之间的距离，并向驾驶员发出潜在危险的警告。',
    },
    {
      head: '',
      subHead: '工业自动化',
      desc: 'UWB技术可实现设备之间更准确、更稳定的通信，在工业自动化应用领域非常有用。例如，UWB可以传输机器人和其他工业设备之间的数据，使它们能够更有效地协作。',
    },
    {
      head: '结论',
      subHead: '',
      desc: 'UWB技术是从室内定位追踪和资产管理到智能家居自动化和医疗监控等多个领域都可应用的强大工具。由于其高精度和可靠性，UWB在需要准确位置数据或高速数据传输的应用领域中是理想的选择。',
    },
  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: "将UWB终端机安装在工作者或设备上，帮助实时追踪位置的产品。",
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]


  }


}