import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { logisticsAsset, manufacturingAsset, manufacturingProcess, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {


    helmet: '',
    desc: 'UWB를 적용한 품질관리와 재고관리 솔루션은 다양한 장점들을 통해 기업의 경쟁력을 강화하고 효율성을 극대화할 수 있는 혁신적인 기술입니다.',
    keywords : 'UWB RTLS, 실내위치파악, 자산추적, 비접촉결제시스템, 시마트홈, 건강관리, 자동차안전, UWB, RTLS, GPS, BLE, 디지털트윈',

    ogTitle: '',
    ogDesc: '',
    ogSite: '품질관리와 재고관리 솔루션 : UWB :: 오브로 블로그',
    ogUrl: 'https://orbro.io/blog/qa-uwb',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDlVCtmGkr2mCVSS69T6u1T4F6EZiqevSZ-7cN786FOvXV8x22WHoof7IcLlSW7d3d4DnUPZT2QfBIuUWr_weryhpgDPEa45E1edX4NBzbCnjImjTiKPVNkJrnd-hMccXsYDNleXYSmD_YNZSLPjAwSMKKN90Mx3tpkR9a7UTKQ-cImQeFt0AOvs96-ik71EqDzH3cdqAWsBQ7aU_aLz_wlgsJwZMUUAAPPY7lxS8lWkog24zYM8BWi2P4-bg6lZ0IL5Be72Kuu1Ga2-E828VnyLDcaIkXnXbFqBqFoW57RzNMYcpG-bH3VEhchLwDniUo-jBNqflu56GYJZFz_CMBt-PdbwZEXzyNVxVyomHJgFQ-_tuofm4uNMx6DdvQ3EAbi99lfqXxlwE05tg7IFIIn88NzPmt6B_D-g0Ujw73JyDNlyzOefzda1AisrhVvXXHE12gEZAQEAnDVsf3mDImb9t9WffWNd047vV9q6GWhzE9BNt5ibIfCXZxVJD3Q6xWSICvlyRJdbFKPLMbsjwe07izGimOadYEIgqo7IBq0sH9kw8I0azDR2iJKcAEFexI4TBxg0uvQJRTqbePuIdd8hvDRm7HcFvd3-X8PctgzhJPrN4y7nSMWlWSH77CEDaYptKymzQvANITzUqYwh8_MWoPcMYKdM-3z8fuRhtut9qckVa8HbX0lFmfPE1hci-P_pz7Wx-jCb2pXgdSoVENciMiiHeng7uHM3BtBM0-2GKsCINKIFKGJs8iD-kgEiH8VWr3SkIVWJzkR-Bi63wUR-khcKdv2u2jwsQV0ClNREfUWWQpiEZxfFyw2zK8KMPxRSk4hhKh_hNeIagh-ygtgeLYzblJicZL7Hbl5w7USiFatqiLkaNKzBimapAPyMde_FZuPRAQie5tC95ZtYPRyXGGMGA1qXV7oZ2GpzqwBkG9wU_MTYQDznkHb3cJvSYUWVGT6J704WQWlETRLd2Dl7N60sZ50lDknqhXG1ZfGGF8qcBgIAXqfuUF-nRIYqZqwHoObsg_F_5iBMZzejZ82s1gbIGHR3MKaOqJy2hZOFgZfKdIST5uJHCKvc22R3enzPv502iA-rkaOY5n5fbWlFGYCB7vwvoWYspA8zGdUhQAee4hAWRQrn4L65-tc4n0rKFKBUqDi_tE5q7d9CLdGirQixGEhZc1pP8SOT9wRECaNgtzQjZf5lrjBdtjBR6oi8m6_cDiZsyHY2kZZVIQBodyLG9VToaONzqqNbcfLdQJ9PnDwxV0zS2UV1hpZIJSoCjk3fLgUn_rHE4yBlkubsupv3o4b1uUuWOSmCl2z8Bbowg6pub3WJAQ92GZa9l06BsP8H7wNS5c0B-pMOockKojLd5B-uMVbslrDK1KUS-0_00CRW9N9KzUg0-jtCM62SDPsilmcnPFwxO5bcNMVXCaUwDa5GVDhKgXXsi4aZ5tbkxZhH3RHDPSLjNtVr6KNeUCBt4Nezhhyp3ETy9B9L0m28nYfY5KUDdzpzwo68QW5GQMDP4bn4vq9pl7_oKviMByvEuvJPzi4pa1LZ9HlqTCFEf3RSRvMv_UhyZQ=w1851-h1197',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',


    title: '품질관리와 재고관리 솔루션 : UWB',
    date: '2023-12-18',
    tag1: '#품질관리',
    tag2: '#재고관리',
    tag3: '#uwb',
    tag4: '#rtls',

    // <br/>
    text: [
        {
            head: '',
            subHead: '',
            desc: '현대 비즈니스 환경에서는 효율적인 품질관리와 재고관리가 기업의 성공에 중요한 역할을 합니다. 최신 기술의 도입으로 이러한 관리 프로세스를 향상시킬 수 있는 다양한 솔루션이 등장하고 있는데, 그 중 하나가 UWB(Ultra-Wideband) 기술을 활용한 솔루션입니다.<br/>UWB는 고정밀 위치추적 기술로, 대역폭이 매우 넓어 정밀한 위치 정보를 제공할 수 있습니다. 이 기술을 품질관리와 재고관리에 적용함으로써 기업은 다양한 이점을 얻을 수 있습니다.',
            dot: ''

        },
        {
            video: video,
            link : '/enterprise/manufacturing/asset',
            head: '',
            subHead: '고정밀한 위치정보 제공',
            desc: 'UWB 기술은 고정밀한 위치정보를 제공하므로 기업은 높은 정확성으로 제품 및 자산의 위치를 파악할 수 있습니다. 이는 제품의 이동 경로를 실시간으로 추적하고 물체의 위치를 정확하게 파악하여 효율적인 운영을 가능케 합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '데이터 실시간 감지 및 분석',
            desc: 'UWB 솔루션은 실시간으로 다양한 데이터를 수집합니다. 이를 통해 생산 과정의 각 단계에서 발생하는 문제나 변화를 빠르게 감지하고, 데이터 분석을 통해 문제의 근본 원인을 파악할 수 있습니다. 이는 신속한 대응과 개선을 가능케 하여 생산 품질을 향상시킵니다.',
            dot: ''
        },
        
        {
            head: '',
            subHead: '에너지 효율 향상',
            desc: 'UWB 기술은 저전력으로 작동하면서도 높은 정밀도를 제공합니다. 이는 전체 시스템의 에너지 효율을 향상시키고, 장비의 배터리 수명을 연장할 수 있습니다. 에너지 효율 향상은 기업의 지속가능성에도 긍정적인 영향을 미칩니다.',
            dot: ''
        },
        
        {
            head: '',
            subHead: '실시간 적응형 제어',
            desc: 'UWB 솔루션은 실시간으로 데이터를 감지하고 분석함으로써 자동화된 적응형 제어를 가능케 합니다. 이는 생산 라인이나 창고의 작업 프로세스를 최적화하고, 과거의 데이터를 기반으로 예측 분석을 통해 미래의 운영을 개선하는 데 도움이 됩니다.',
            dot: ''
        },
        
        {
            head: '',
            subHead: '보안 강화',
            desc: 'UWB는 무선 통신을 사용하며, 다른 무선 기술에 비해 높은 보안 수준을 제공합니다. 물리적으로 매우 낮은 간섭을 가지며, 안전한 통신을 보장함으로써 기업 자산의 보안을 강화합니다.',
            dot: ''
        },
        
        {
            head: '',
            subHead: '사용자 정의 가능성',
            desc: 'UWB 솔루션은 다양한 산업 분야에 적용 가능하며, 기업의 특정 요구에 따라 맞춤형으로 구성할 수 있습니다. 이는 기업이 자체적으로 시스템을 개선하거나 특정한 운영 환경에 최적화된 솔루션을 도입할 수 있는 유연성을 제공합니다.<br/><br/>UWB를 적용한 품질관리와 재고관리 솔루션은 다양한 장점들을 통해 기업의 경쟁력을 강화하고 효율성을 극대화할 수 있는 혁신적인 기술입니다.',
            dot: ''
        },
        
        {
            head: '사용 사례',
            subHead: '제조업',
            desc: 'UWB를 활용한 실시간 위치 추적 시스템은 제조업에서 효율적인 생산관리에 사용됩니다. 원자재, 부품, 제품 등의 위치를 정확히 파악하여 생산 프로세스를 최적화하고 불량률을 줄일 수 있습니다.',
            dot: ''
        },
        
        {
            head: '',
            subHead: '물류 및 창고 관리',
            desc: '물류 업체나 대규모 창고에서 UWB를 도입하여 물품의 이동 경로를 실시간으로 추적함으로써 재고 관리를 최적화할 수 있습니다. 특히, 대형 창고에서 UWB를 통한 자동화된 가이드 시스템은 물품의 효율적인 재고 배치를 가능케 합니다.',
            dot: ''
        },
        
        {
            head: '',
            subHead: '의료 분야',
            desc: '병원이나 의료 기관에서는 UWB를 사용하여 의료 기기나 환자의 위치를 정확하게 파악함으로써 응급 상황 대응이나 의료 서비스 품질을 향상시킬 수 있습니다.',
            dot: ''
        },
        
        {
            head: '',
            subHead: '쇼핑 및 소매업',
            desc: '소매업체에서는 UWB를 이용하여 매장 내 고객의 이동 패턴을 분석하고, 제품의 재고 상태를 실시간으로 파악하여 재고 관리 및 고객 경험을 개선할 수 있습니다.',
            dot: ''
        },
        
        {
            head: '',
            subHead: '건설 산업',
            desc: '대형 건설 현장에서 UWB를 활용하여 장비 및 작업자의 위치를 정확히 파악함으로써 안전성을 강화하고 작업 일정을 최적화할 수 있습니다.<br/><br/>이러한 사례들은 UWB를 통한 위치 추적 기술이 다양한 산업 분야에서 성과를 내고 있음을 보여줍니다. 다양한 기업들이 UWB 솔루션을 도입함으로써 생산성과 효율성을 향상시키며 경쟁력을 확보하고 있습니다.',
            dot: ''
        },
        
    ],


    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.assetTracking.thumbnail
            },
            {

            }

        ],
        solution: [

            {
                img: manufacturingProcess.thumb,
                data: 'manufacturingProcess',
                link: '/enterprise/manufacturing/process'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/enterprise/manufacturing/asset'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/enterprise/logistics/asset'
            },
        ]


    }

}