import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const RTLSPage = styled.div`
    padding : 128px 0 64px 0;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 96px 0 64px 0;
    }
`;

export const LeftBox = styled.div<{img : string}>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;
    border-radius: 16px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));

    width : 100%;
    max-height : 820px;
    aspect-ratio : 600 / 820 ;
    padding : 24px;
    ${({img})=> img && `background-image : url(${img});`}
   
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        aspect-ratio : 440 / 740 ;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        max-height : 400px;
        width : 100%;
        aspect-ratio : 361 / 400 ;
    }
`;


export const RightSection = styled.div`
    display : flex;
    flex-direction : column;
    gap : 32px;
    width : 100%;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        gap : 16px;
    }
    
`;

export const RightBox = styled.div<{img : string}>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;
    border-radius: 16px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
    
    max-height : 394px;
    aspect-ratio : 600 / 394;
    padding : 24px;
    ${({img})=> img && `background-image : url(${img});`}
    width : 100%;


    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        aspect-ratio : 440 / 354 ;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        aspect-ratio : 361 / 400 ;
    }
`;