import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

export default {
    helmet: '| スマート病院とは？',
    desc: 'スマート病院は、IoT、人工知能、データ分析などを活用して患者の健康状態をリアルタイムでモニタリングし、診断と治療プロセスを改善し、医療サービスの効率性と品質を向上させることに重点を置いています。',
    keywords: 'デジタルツイン、スマート工場、NVR、IP、PTZ、RFID、NFC、UWB、無線通信、位置追跡、物体追跡、人物追跡、タグ、ビーコン、RTLS、Orbro、コンテック',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'スマート病院とは？ | 位置追跡とデジタルツインの専門プラットフォーム、Orbro',
    ogUrl: 'https://orbro.io/jp/blog/스마트병원이란',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmPfoc5xcFsetUID5pyg3CYD7YMozk7v8agtjsAU3FWSwMrSh5jezcJw00E2PsYdRwBciFQJKL4IguNdWivv4bRwDkwLuH_X_qAV982dYuBJho1obfwpVkXOoGGsFzUHcZ6I-tLD3CCDnlOljA-ZIojFjbigYAYMSdbWykA0SplErndN38ZvDJCDdtAJKIn703TEZAaikiyqmO_dZMOzge0_bIm3eS8eIPGsweG-xjhoDelT8CCOdyeN0LY68PSWfiinGf0eEuOoS3pti-1ZwLu-JjJELZsO1tsY30mxgenUNvpYfjBhCHq2IFN2vX5T-39NwhhLOOxM_5WE1ec46dO8qlMqV2Z1m6FkaFlsNr4fCccF3HM0uahwB1AlQY9T47hoqeH3C6kQC3JbanZWKV_CWqzXDVXLdEbwzA7taG3ny_gWknaCAdR_v0oqgaUnAkuOldeRH5wVEsZC6SaHP8T-DZXTyl_XTvlPl3WRE5POEcdCSelPI6gRWOzIuFOfyImIPGqtxPuc2yuwk9lv372FquiGhHqBAkHQ8FoPn5lNd7nVuV0COyXuu5vdL3sdii7rC-xZQ9s55QCRPlGbX51dJb6u-ku9H_Cv-Dxr-zr4PV0TnSKTWQxdM0kJbKL2kEgQCahORl-3ojZaHOXWE9Hqu5GjfKdar2oI2wNIGP82lEb7oRlXwEtqokSSFGY2SW6Sz-GeEUyJWL17f4XqGaBnv-bC_6XYXuyWQpnAU7VPOSjZOh4BPpQTAS1qEXKVISNMt9c4urPNVRAtG5I0Pn2v-fcBE4wcQgixYinbHP2OdtdyBKdjGOo31Zlp78Hs7PzDyWCQ4x8a4vV1z2EbrciEe2pcWWkll9qrFFYRRF62ecCzT9vRLko6dD4j9RjTWUigzS-Y0Un4NBy4gSwfIKmYvjf5q1muT96LYHPdE1qu-d9sUUW5Cg05eUAfUwwEFDQkms0x5jM5ds68-FYCiI2aeTY3nlo9E84to4h4o9Z2LjufbbwC3Y_zkPzkbREeF-FKbkRGiE1gw7WuUfRQ-K16n3rdiCytnsClNeTgIw_xYkkGYGS9TCh-BgPnvBvnvrfviZdwMpI22X-Jp_F4dVL_OBwVKMqKAUATJNPCcNiqqoGAHJdOv4Zwt4odCav4i7sM7_ZdJaN08S5egxFZMpLL9Lu1TR9SGWSpT-opyrkMUo2yWTKLtxY6E4mjO1EV5a2qRD-OSbGxlT6-HBn2HvJpvlbmHyChUDA1LR5aTDsReQq8lP5lIHjbkA1lD0h9x-MuYp0QUXJWiAoMdRxNVQBsZUHjkJP41fTk1bBuizb8U9tp3kv_NReX8fCs7a8UNYbGnWllcN3mRBSET80gDqirtQm2Nw5uX7nXHQvgKOqFufopQIHmjdVZBBRgLs9ofbVSgaD3bryP8l7mMotKmEyOPA_cD-WbTB8aSMfUbgqc_WSyW_3VJi_4Y0B2QfdwRGnr2d_lPXG_V62CHnP1vjPlkC7xGDXxVzG6hmC6EWsrfSEUvAywKLYdmBc0bEGQzj33GVTHADzLtZ01SU_juNo-ASh-i-A=w1834-h1208',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'スマート病院とは？',
    date: '2024-01-16',
    tag1: '#スマート病院',
    tag2: '#スマートヘルスケア',
    tag3: '#デジタルツイン',
    tag4: '#位置追跡',

    text: [
        {
            head: 'スマート病院とは？',
            subHead: '',
            desc: 'スマート病院は、IoT、人工知能、データ分析などを活用して患者の健康状態をリアルタイムでモニタリングし、診断と治療プロセスを改善し、医療サービスの効率性と品質を向上させることに重点を置いています。スマート病院は、医療情報の効率的な共有と意思決定を支援し、迅速かつ効果的な医療サービスを提供する革新的な医療システムを目指します。これにより、患者はより便利で個別化された医療サービスを受けることができます。',
            dot: '',
        },
        {
            head: 'スマート病院の適用技術',
            subHead: '',
            desc: 'スマート病院は、現代的で効率的な医療サービスを提供するためにさまざまな技術を活用しています。主要な技術には次のものが含まれます。',
            dot: {
                a: '<strong> • リアルタイムモニタリング技術： </strong>患者の状態をリアルタイムでモニタリングし、医療スタッフが即座に対応し治療を調整できます。これにより患者の安全性と監視が強化されます。',
                b: '<strong> • 電子医療記録（EMR）： </strong>患者の医療記録を電子的に記録および管理し、医療スタッフが情報に効率的にアクセスし、意思決定を行えるようにします。',
                c: '<strong> • ロボティクス技術： </strong>ロボットは手術補助、患者ケア、薬物提供など、さまざまな医療業務に使用され、スマート病院での人材サポートおよび医療サービスの向上を支えています。',
                d: '<strong> • IoT（モノのインターネット）技術： </strong>医療機器およびセンサーを介して患者の健康状態をリアルタイムでモニタリングし、データを収集します。これにより個別の医療サービスを提供できます。',
                e: '<strong> • 人工知能（AI）およびビッグデータ分析： </strong>AIを使用して医療データを分析し、予測モデルを開発することで、疾病の早期診断および予防を強化し、医療コストを削減できます。',
            },
        },
        {
            head: 'スマート病院の利点',
            subHead: '',
            desc: 'スマート病院の利点には、電子医療記録を介した効率的な医療記録管理、リアルタイムモニタリングを通じた迅速な対応と治療提供、人工知能およびビッグデータ分析を活用した疾病予防および早期診断の強化があります。また、患者の体験向上のための便利な予約および治療プロセス、個別化された医療サービスを提供するIoT技術の導入、仮想現実および拡張現実を活用した効果的な患者教育とリハビリセラピー、ロボティクス技術を活用した医療人材のサポートなどがあります。これらの技術の統合により、スマート病院は患者治療および医療サービスを革新的に向上させています。',
            dot: '',
        },
        {
            video: video,
            link: '/jp/enterprise/medical/patient',
            videoDesc: 'スマート病院ソリューション、クリックで移動します。',
            head: 'スマート病院の事例',
            subHead: '',
            desc: '',
            dot: {
                a: '<strong> • メイヨークリニック： </strong>メイヨークリニックは世界的に有名なスマート病院の1つで、電子医療記録およびリアルタイムモニタリングシステムを統合し、患者治療の効率性を向上させています。また、患者には携帯電話アプリケーションを介した予約および医療情報アクセス機能を提供しています。',
                b: '<strong> • シンガポールジェネラルホスピタル： </strong>シンガポール総合病院は積極的にスマートテクノロジーを活用し、リアルタイムモニタリング、IoTデバイス、ロボティクス手術などを導入して患者治療の効率性を高め、患者に個別化されたサービスを提供しています。',
                c: '<strong> • シェバ医療センター： </strong>シェバ医療センターは仮想現実（VR）を使用して患者教育および治療に革新的なアプローチをしています。VRを活用した治療プログラムを通じて、患者のリハビリに効果的に貢献しています。',
            },
        },
        {
            head: 'スマート病院の展望',
            subHead: '',
            desc: 'スマート病院は急速に発展している医療産業の重要な部分として確立されています。最新の技術と人工知能を活用して患者の診断と治療を効率的に進め、医療データのリアルタイム共有により医師間の協力を強化しています。患者はスマートフォンアプリを使用して健康状態をモニタリングし、簡単に医療サービスにアクセスでき、利便性を体験しています。また、スマート病院は患者個人の医療記録を厳格に管理し、医療情報の安全性を向上させています。将来には、より発展した技術と革新的なサービスにより、スマート病院が患者中心の効率的で安全な医療環境を提供することが期待されています。',
            dot: '',
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWBデバイスを労働者または機器に取り付け、リアルタイムで位置を追跡する製品です。',
                img: productImg.product.workerTracking.thumbnail,
            },
            {},
        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/jp/enterprise/medical/patient',
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/jp/enterprise/retail/exhibition',
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/jp/enterprise/office/asset',
            },
        ],
    },
};
