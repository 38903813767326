import gis from "@src/assets/images/digitaltwin/video/gis.mp4";
import weather from "@src/assets/images/digitaltwin/video/weather.mp4";
import buildings from "@src/assets/images/digitaltwin/video/buildings.mp4";
import tracking from "@src/assets/images/digitaltwin/video/tracking.mp4";
import traffic from "@src/assets/images/digitaltwin/video/traffic.mp4";
import airplane from "@src/assets/images/digitaltwin/video/airplane.mp4";
import cctv from "@src/assets/images/digitaltwin/video/cctv.mp4";

import video from "@src/assets/images/digitaltwin/video/traffic.mp4";

import {
    Icon3d3d, Icon3dCycle, Icon3dLink, Icon3dLocation, Icon3dMap,
    Icon3dMap2, Icon3dMeter, Icon3dPlus, Icon3dServer,
} from "@assets/images/icon";
import { DTexam1, DTexam2, DTexam3, headerImg, layerEngImg, usaPatent, verifyPatent } from "@assets/images/digitaltwin";


export default {

    banner: {
        title: '全球首个第三代数字转型<br/>数字孪生专业平台',
        desc : `One step advanced Orbro's digital twin. Experience the innovation.`,
        btn: {
            left: '体验',
            right: '介绍视频'
        }
    },

    header: {

        box: [
            {
                title: '世界首创',
                desc: '基于Web的虚拟世界平台'
            },
            {
                title: '第一名',
                desc: '工业用Metaverse成绩（韩国标准）'
            },
            {
                title: '500亿+',
                desc: '亚洲最大规模的位置信息数据'
            },
            {
                title: '20,000+',
                desc: '实时连接的对象数量'
            },
        ],

        title: '数字转型，数字孪生',
        desc: '将现实世界的人、物、建筑和城市数据接收，实时在数字屏幕中监控和管理。通过这样做，可以提高生产力并预防问题，主要应用于工业和制造领域。',
        img: headerImg
    },

    connection: {
        title: 'ORBRO World，更高级的数字孪生',
        desc: '提供比基本地理、基础设施信息更高级的服务，实时了解现实世界中发生的事情。ORBRO通过最先进的技术，如AI摄像机、信标、高精度位置跟踪、智能出入系统等，构建了高水平的数字孪生技术。',
        img: layerEngImg
    },

    func: {
        title: '数字转型的时代<br/>可应用于任何地方的数字孪生',
        desc: '世界上存在着大量的数据。ORBRO收集我们发送的数据，使世界更易于理解。',
        card: [
            {
                video: gis,
                title: '基于3D的视觉设备',
                desc: '使用与现实世界相同的3D形状的视觉数据在现实世界中提供信息。<br/>建筑物和建筑物的内部，所有信息都在3D地图上实时反映。这使体验更加栩栩如生的虚拟世界成为可能。',
            },
            {
                video: weather,
                title: '大气和气象信息协同',
                desc: '将大气和气象信息与ORBRO服务器同步，实现实际下雨或下雪的天气也同样实现。这增加了沉浸感，可以确认与实际相同的世界。对于某些行业，这样的信息可能是必不可少的。',
            },
            {
                video: buildings,
                title: '建筑信息实现',
                desc: '实时监测设施状态，优化维护计划，利用机器传感器数据预测故障，并有效地计划。此外，监测建筑内的能源使用并进行优化，提高能源效率，减小对环境的影响。',
            },
            {
                video: tracking,
                title: '室内建筑地图实现',
                desc: '不仅显示外部数据，还实时显示室内物品和行走的人。特别是对于流动人口多、访客信息重要的设施，这将是更有意义的数据。',
            },
            {
                video: traffic,
                title: '巴士、地铁交通信息协同',
                desc: '实现各种交通工具之间的合作和智能移动。通过实时位置跟踪提供准确的运行信息，乘客可以提前了解到达时间。相关交通信息提供最佳路径，从而避免交通拥堵，实现高效移动。',
            },
            {
                video: airplane,
                title: '飞机信息协同',
                desc: '关联的信息提供快速通知有关航班延误或更改，优化行程。与智能门和运输系统集成的飞机信息调整旅客的移动，提高运输网络的效率。此外，利用预测分析优化航空运营，减少燃料消耗，最大化环境效益。',
            },
            {
                video: cctv,
                title: 'CCTV图像信息协同',
                desc: '将CCTV图像与其关联，检测异常迹象，并发出警告以加强区域安全。交通流监测可通过实时CCTV图像协同来优化道路交通，预防交通拥堵。此外，将CCTV图像信息与各种系统整合，全面利用数据以增强智能城市功能。',
            },
        ]
    },

    video: {
        title: '栩栩如生的高清图形协同',
        desc: '实现对现实世界的准确建模和模拟，从而更详细地理解实时情况并有效管理。',
        video: video
    },
    patent: {
        card: [
            {
                title: '个人位置信息业务提供商认证<br/>',
                desc : '317C-0052-69BC-05A3',
                img: verifyPatent,
            },
            {
                title: '射频位置追踪美国专利<br/>',
                desc : 'DFS ID: 37686035',
                img: usaPatent,
            }
        ]
    },
    
    summary: {
        title: '主要功能摘要',
        desc: '',
        card: [
            {
                img: Icon3d3d,
                title: '基于3D的视觉设备',
                desc: '城市平台以3D形式实现，提供更栩栩如生的虚拟世界体验。',
            },
            {
                img: Icon3dServer,
                title: '数据收集',
                desc: '从各种来源收集数据，安全地存储在中心化的环境中。',
            },
            {
                img: Icon3dMap,
                title: '基于位置的服务',
                desc: '利用用户的位置信息提供定制的服务。',
            },
            {
                img: Icon3dMap2,
                title: '经度纬度基础平台',
                desc: '所有位置信息都转换为地理坐标信息，可在地图上确认。',
            },
            {
                img: Icon3dLocation,
                title: '空间友好型服务',
                desc: '使用智能亭、媒体墙等硬件设备提供基于位置的服务。',
            },
            {
                img: Icon3dLink,
                title: '数据协同',
                desc: '协同公共信息和物联网/工业物联网传感器数据等，形成全面的数据环境。',
            },
            {
                img: Icon3dCycle,
                title: '各种公共信息的协同',
                desc: 'ORBRO World整合、管理并提供在国家和地区发生的各种数据。',
            },
            {
                img: Icon3dMeter,
                title: '报告和分析',
                desc: '以报告形式提供数据分析结果，支持有效分析。',
            },
            {
                img: Icon3dPlus,
                title: '提供各种应用程序',
                desc: '分析收集的数据，提供交通信息、环境监测工具、智能城市管理等多种应用程序。',
            },
        ]
    },
    exam: {
        title: 'ORBRO World的多种应用',
        desc: '利用数字孪生技术的各种领域解决方案',
        card: [
            {
                img: DTexam1,
                tag: '智能城市',
                title: '城市信息协同',
                desc: '将城市的物理方面数字化建模，并实时监控城市的运营和发展，为城市提供各种积极影响。通过这种方式，城市能够实现高效的资源管理，推动智能城市的发展，并为市民提供提高生活质量的服务。',
            },
            {
                img: DTexam2,
                tag: '智能建筑',
                title: '室内位置追踪',
                desc: '在购物商场或复杂的建筑物内提供准确的导航，支持方便的移动，并在紧急情况下支持快速撤离。此外，室内位置追踪可以实现个性化的广告和服务提供，提高用户体验。在商业环境中，有助于资产管理和提高生产力，在智能家居中，与自动化控制系统配合，提高便利性。',
            },
            {
                img: DTexam3,
                tag: '智能仓库',
                title: '物流状况确认',
                desc: '通过准确了解产品的位置、移动路径和库存状态等，可以提高物流网络的效率并优化库存管理。基于实时信息的决策可以快速调整物流流程，以及在出现问题时进行快速响应。',
            },
        ]
    },
}
