
import { IconOsOff, IconOsOn, IconRobotOff, IconRobotOn } from '@assets/images/main/icon';
import { IntegrationLabel, IntegrationLabel1, IntegrationLabel2, IntegrationLabel3, integrationOverviewDesktop11, integrationOverviewDesktop12, integrationOverviewDesktop13, integrationOverviewDesktop14, integrationOverviewDesktop15, integrationOverviewDesktop21, integrationOverviewDesktop22, integrationOverviewDesktop23, integrationOverviewDesktop24, integrationOverviewDesktop31, integrationOverviewDesktop32, integrationOverviewDesktop33, integrationOverviewDesktop34, integrationOverviewMobile11, integrationOverviewMobile12, integrationOverviewMobile13, integrationOverviewMobile14, integrationOverviewMobile15, integrationOverviewMobile21, integrationOverviewMobile22, integrationOverviewMobile23, integrationOverviewMobile24, integrationOverviewMobile31, integrationOverviewMobile32, integrationOverviewMobile33, integrationOverviewMobile34, integrationOverviewTablet11, integrationOverviewTablet12, integrationOverviewTablet13, integrationOverviewTablet14, integrationOverviewTablet15, integrationOverviewTablet21, integrationOverviewTablet22, integrationOverviewTablet23, integrationOverviewTablet24, integrationOverviewTablet31, integrationOverviewTablet32, integrationOverviewTablet33, integrationOverviewTablet34 } from '@assets/images/main/integration';
import { IntegrationDetailVideoDesktop1, IntegrationDetailVideoDesktop2, IntegrationDetailVideoDesktop3, IntegrationDetailVideoMobile1, IntegrationDetailVideoMobile2, IntegrationDetailVideoMobile3, IntegrationDetailVideoTablet1, IntegrationDetailVideoTablet2, IntegrationDetailVideoTablet3 } from '@assets/images/main/video/integration';


export default {

    mainPage: {
        product: {
            title: 'Usage Environment Package Configuration',
            desc: 'Check out the product package configurations that can be implemented in medical environments.',
            robotBasic: {
                title: 'Basic Delivery Robot Package',
                desc: 'Harmony of indoor location tracking system and robot system, quick setup with simple configuration',
            },
            delivery: {
                title: 'Unmanned Loading/Unloading Package',
                desc: 'A package configured to allow unmanned loading and unloading of delivery robots',
            },
            disinfection: {
                title: 'High-Risk Management Package',
                desc: 'A package configured to manage the delivery of medical supplies with high infection risk',
            }
        }
    },

    banner: {
        label: IntegrationLabel,
        title: 'Integration System for Greater Expansion',
        desc: '',

        schemeTitle: 'Camera System Proposed by ORBRO.',
        schemeDesc: 'Need help deciding?',
        schemeDesc2: 'Contact us and a security expert will assist you.',
        phone: '1522-9928',
    },

    bannerData: [
        {
            icon: IconRobotOn,
            iconInv: IconRobotOff,
            image: '',
            iconText: 'Robot System',
            sub: 'New',
            link: '/en/integration/robot'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/en/os'
        },
    ],

    video: {
        title: 'Combination of Tracking Technology and Indoor Delivery Robots',
        desc: [
            { text: `Monitor robot locations and process progress in real-time with ORBRO's tracking system.` },
        ]
    },

    delivery: {
        label: IntegrationLabel1,
        title: 'Integration with Delivery Robot System',
        desc: 'Provides real-time monitoring of the location and operational status of robots using the ORBRO location tracking system.',

        card: [
            {
                videoTag: true,
                video: {
                    desktop: IntegrationDetailVideoDesktop1,
                    tablet: IntegrationDetailVideoTablet1,
                    mobile: IntegrationDetailVideoMobile1
                },
                title: 'Start of Robot Location Tracking and Management',
                desc: `Track and manage robot locations and status in real-time with ORBRO's system.`,
                img: {
                    desktop: integrationOverviewDesktop11,
                    tablet: integrationOverviewTablet11,
                    mobile: integrationOverviewMobile11
                },
            },
            {
                title: 'Combination with ORBRO Location Tracking System',
                desc: `Easily integrate robots with ORBRO's tracking system.`,
                img: {
                    desktop: integrationOverviewDesktop12,
                    tablet: integrationOverviewTablet12,
                    mobile: integrationOverviewMobile12
                },
            },
            {
                title: 'Monitoring Work Status',
                desc: 'Monitor robot status in real-time and respond immediately.',
                img: {
                    desktop: integrationOverviewDesktop13,
                    tablet: integrationOverviewTablet13,
                    mobile: integrationOverviewMobile13
                },
            },
            {
                videoTag: true,
                video: {
                    desktop: IntegrationDetailVideoDesktop2,
                    tablet: IntegrationDetailVideoTablet2,
                    mobile: IntegrationDetailVideoMobile2
                },
                title: 'Monitoring Locations',
                desc: 'Provide real-time current location of each robot.',
                img: {
                    desktop: integrationOverviewDesktop14,
                    tablet: integrationOverviewTablet14,
                    mobile: integrationOverviewMobile14
                },
            },
            {
                videoTag: true,
                video: {
                    desktop: IntegrationDetailVideoDesktop3,
                    tablet: IntegrationDetailVideoTablet3,
                    mobile: IntegrationDetailVideoMobile3
                },
                title: 'Managing Multiple Robots',
                desc: 'Effectively manage multiple robots.',
                img: {
                    desktop: integrationOverviewDesktop15,
                    tablet: integrationOverviewTablet15,
                    mobile: integrationOverviewMobile15
                },
            },
        ]
    },

    loading: {
        label: IntegrationLabel2,
        title: 'Integration with Unmanned Loading/Unloading System',
        desc: 'Enable unmanned loading and unloading of delivery robots by integrating equipment into a single system.',
        card: [
            {
                title: 'Integration with Loading Equipment',
                desc: 'Enable robot calling and automatic operation by integrating with loading equipment.',
                img: {
                    desktop: integrationOverviewDesktop21,
                    tablet: integrationOverviewTablet21,
                    mobile: integrationOverviewMobile21
                },
            },
            {
                title: 'Integration with Collaborative Robots',
                desc: 'Automatically unload by confirming the status of delivery robots.',
                img: {
                    desktop: integrationOverviewDesktop22,
                    tablet: integrationOverviewTablet22,
                    mobile: integrationOverviewMobile22
                },
            },
            {
                title: 'Integration with Smart Lockers',
                desc: 'Monitor important data and make effective decisions through customized dashboards.',
                img: {
                    desktop: integrationOverviewDesktop23,
                    tablet: integrationOverviewTablet23,
                    mobile: integrationOverviewMobile23
                },
            },
            {
                title: 'Real-time Status Check',
                desc: 'View real-time stock and operational status of all equipment on one screen.',
                img: {
                    desktop: integrationOverviewDesktop24,
                    tablet: integrationOverviewTablet24,
                    mobile: integrationOverviewMobile24
                },
            },
        ]
    },

    service: {
        label: IntegrationLabel3,
        title: 'Integration with Disinfection System',
        desc: 'ORBRO OS is powerful, convenient, and easy to set up and use.',
        card: [
            {
                title: 'Plasma Disinfection Device',
                desc: 'Quickly sterilize medical waste and tools with vaporized sterilant.',
                img: {
                    desktop: integrationOverviewDesktop31,
                    tablet: integrationOverviewTablet31,
                    mobile: integrationOverviewMobile31
                },
            },
            {
                title: 'UVC Disinfection Device',
                desc: 'Safe, high sterilization using UVC and LED lamps.',
                img: {
                    desktop: integrationOverviewDesktop32,
                    tablet: integrationOverviewTablet32,
                    mobile: integrationOverviewMobile32
                },
            },
            {
                title: 'Integration with HPV Disinfection Device',
                desc: 'Enable contactless self-disinfection of robots in contaminated areas.',
                img: {
                    desktop: integrationOverviewDesktop33,
                    tablet: integrationOverviewTablet33,
                    mobile: integrationOverviewMobile33
                },
            },
            {
                title: 'Real-time Monitoring of Device Status',
                desc: 'Monitor connection, device, and operational status in real-time.',
                img: {
                    desktop: integrationOverviewDesktop34,
                    tablet: integrationOverviewTablet34,
                    mobile: integrationOverviewMobile34
                },
            },
        ]
    },
}
