import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4"

export default {
    helmet: '| 什么是智能工厂？',
    desc: '智能工厂是指利用物联网技术和人工智能自动化和优化生产线的工厂。这提高了生产过程的效率，减少了维护时间，并有助于提高生产产量和质量。',
    keywords: '数字孪生、智能工厂、智能制造、NVR、IP、PTZ、RFID、NFC、UWB、无线通信、位置追踪、物体追踪、人员追踪、标签、信标、RTLS、Orbro、Contec',

    ogTitle: '',
    ogDesc: '',
    ogSite: '什么是智能工厂？ | 专业平台，位置追踪和数字孪生，Orbro',
    ogUrl: 'https://orbro.io/cn/blog/what-is-a-smart-factory',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDlrNJZcN0y8rqN6rn4lIBsYzyCBFCLngoMNzRGAY6aBK05F5A7p7wMg7ZuILGtqR0GyG3qa5nWoqrTkhzMc8RRj0tMC2erUg8B95SfXjNpxqhWAlZI2ZNQ20zf6Bqvfx3ztoqtHXcz2hhYcN11wxSut__qBoWsflYyVOeQHJ35WsxS_wN8WaNJKzx-dhLVFusRshtRF7Zx4doNUOlm_KUYkgX0IUQ1IeB6yFe3WYvOyZC8sqY1FMnRAfO22PRRK4uVETtZYRhz1rrYA_xT7mbQP94D71TrIFaoebXtygq-yfvQOvJ4UfR6wr4I2M4V0oN6suppqQ8YAOYRD1WYR_cbQeuw_KsCJ2wLvHZYc2LaJwaDpP3CO-Ygqw0gsMrOMEtf5U1WEMf903qg20rF0D8hqpBPnjJRCQ6KY2leZG3Xv2k7q5gSOcKTnqSEQTe7DpWKAbnscWRoK7j1HGIopA0MWEyVIu5Ux9VhBZUSMEgfQkKoAX2UL71HTDwV7xf_AMP2cVdj7u49LCN1Pcdq-eKXMygl_EcCyGvoZ_wp4Bq7Uh1dbm7IJe5A2TaDKbuzgY9JL-ZFhxhwVES7tvZlWP-B_TeatTd2RKPQ6FMoeblJYuiAjHopqXL9JO8-y5wEcVgWwSo6_2W1AosdLSjlS2dqAE827GZP9HjudpoEd7lQXyP3bdXE6n3DzSuUOtextrsHpP6vN6tZ7q6krYTztpHpdIN8naMIOpdy4O2pdvv1uz-fhax_TyYVUEXg6ld7JVk-hPSvGPIAVUDpUx00G4uQgiBq8U0EZlVtIPynqiSn0VJjVTkArpnM_WX3E05A4Qwt-1AS3SagPFHUABELaSJyL1L34cKVxK_BLvKBZlGnfpC1LqOfmjS5iBamKqpm4ctvMkBIwLKcxad4eYpEH1H_yQ9AxantgCTB-GHWAdLrVk0cA3jMjJfx-OpQRCUEE2BSLWbuTCDEtJpMFwRzLdHzcRjg8qbJgjJTV7KeVZCHqn9DoghaNW2r_ON-IXwyfgNGPNPqeGx81epFjGIdFEjaCGAgIGdsth-CUeTGc5Q69DrT_PAuAYnbN3F7Bj1XLyz0Ie97ogOtcAHLzQwCjNd0Ll1vl2A35yNX905gQEctPSAY9e91nlTtH6KmIgLYgtpBt-w4xOQIXh4_z5hZulvwYKL2lh7Xt-qXqpV-PGYYbnGgz20mwGck4DxmCnXztmOUOMWkwvZ6BFpIoA7ZA2EntRJ8Z6hI17yexqq-384ja6SLh_3QqtEALCOtwyxIYCqwJsSFPyGPp17Pz1RyvB-j2RZ23GCX1YKYo6STGlKOmlYxmV0spVQQIQVgamCYHkKTDfSr3KFe2-SyaJEaPRoIansSf_NTtNvwC8RD0AMr2HYs7iQ35mFLUS5KF2dTLlah-AmnppP4WbuZ-4T0McWZKlfAFjR_nLifHSgTF8XTBEa9XMQsjhzP3c_SWzbqMOkYR82Q9zVg6wacsuo537pPMZn5oxZcSFNdLKsmywtFpS9Pg5dWlyWI7fgd7sn-lu7-vhp8j6iz36SG2OsnjAuOrlL92W_P9=w1984-h1208',
    ogKey: '',
    ogType: '网站',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '什么是智能工厂？',
    date: '2024-01-12',
    tag1: '#智能工厂',
    tag2: '#智能制造',
    tag3: '#数字孪生',
    tag4: '#位置追踪',

    text: [
        {
            head: '什么是智能工厂？',
            subHead: '',
            desc: '智能工厂是指利用物联网技术和人工智能自动化和优化生产线的工厂。这提高了生产过程的效率，减少了维护时间，并有助于提高生产产量和质量。它革新了传统的制造概念，降低了生产过程中的成本，同时提高了产品质量。<br/><br/>智能工厂作为一种在现代制造业迅速普及的创新生产方法，正因有效利用数字孪生和位置追踪技术而备受关注。<br/><br/>智能工厂正在迅速传播于现代制造业，通过有效利用数字孪生和位置追踪技术，提高生产效率，引起了对生产流程优化的关注。',
            dot: ''
        },
        {
            head: '智能工厂的功能',
            subHead: '',
            desc: '智能工厂通过整合各种技术和系统，重点是智能地管理和优化生产过程。这有助于提高生产效率，降低成本，提高质量。主要智能工厂的功能包括：',
            dot: {
                a: '<strong><br/> • 自动化和机器人技术： </strong>智能工厂通过引入先进的机器人和自动化系统自动化生产线的工作。这有助于降低劳动成本，提高生产率。',
                b: '<strong><br/> • 数字孪生技术： </strong>创建产品或生产过程的数字孪生，将实际的物理对象建模到数字环境中。这有助于实时监测和优化生产过程。',
                c: '<strong><br/> • 物联网（IoT）技术： </strong>通过利用与传感器连接的IoT设备，将生产线上的所有设备和装置连接到网络。这有助于实时收集数据，监测和有效管理生产过程。',
                d: '<strong><br/> • 位置追踪技术： </strong>利用使用RFID或GPS的位置追踪技术，实时跟踪原材料、产品、工作人员等的位置。这有助于资源管理和有效的工作安排。',
                e: '<strong><br/> • 环境监测和能源效率： </strong>智能工厂重点监测和最小化能源消耗和对环境的影响。通过可持续的环境管理，追求环保的生产方式。',
                f: '<strong><br/> • 云计算： </strong>将生产数据存储和处理在云中，实现对数据的实时访问和集中监控。支持不同地点之间的协作。',
            }
        },
        {
            video: video,
            link: '/cn/enterprise/manufacturing/process',
            videoDesc: '(智能工厂解决方案，请点击以移动。)',
            head: '智能工厂的优势',
            subHead: '提高生产力',
            desc: '通过引入自动化和机器人化，自动化重复性高且劳动密集的工作，提高生产效率。此外，通过利用数字孪生实时监测生产过程并进行优化，提高生产效率。',
            dot: ''
        },
        {
            head: '',
            subHead: '提高产品质量',
            desc: '使用传感器和物联网技术收集并分析在生产线上产生的数据，监测产品的生产过程。这有助于减少次品率，提高产品质量。',
            dot: ''
        },
        {
            head: '',
            subHead: '降低成本',
            desc: '通过引入自动化系统，降低劳动力成本，并通过大数据分析实施预测性维护，将维护成本最小化。此外，通过优化生产过程提高资源使用效率，降低成本。',
            dot: ''
        },
        {
            head: '',
            subHead: '灵活性和快速变化的响应',
            desc: '通过利用数字孪生和智能制造系统，更灵活地调整和更改生产线。这提供了迅速构建或更改各种产品生产线的能力。',
            dot: ''
        },
        {
            head: '',
            subHead: '实时监控和决策',
            desc: '使用传感器数据和物联网技术实时收集生产环境的数据，并利用大数据分析和人工智能技术迅速进行分析。这有助于实时了解生产线的状态，并进行迅速决策。',
            dot: ''
        },
        {
            head: '',
            subHead: '资源效率',
            desc: '智能工厂通过采用高能效的生产方法，将能源消耗降至最低，并利用大数据和人工智能监控和优化资源的有效使用。',
            dot: ''
        },
        {
            head: '',
            subHead: '环保生产',
            desc: '通过采用对生产过程环境友好的设计和高能效的生产方法，构建环保的生产环境。这有助于提高企业的可持续性，最小化对环境的影响。',
            dot: ''
        },
        {
            head: '智能工厂的案例',
            subHead: '',
            desc: '',
            dot: {
                a: '<strong>• 丰田： </strong> 丰田被认为是智能制造业的领导者。他们积极采用先进技术，如数字孪生、机器人工程和人工智能，革新制造流程。例如，高级机器人与员工共存，并使用数字孪生实时监视和优化制造流程。',
                b: '<strong>• 博世智能工厂： </strong> 博世在汽车和工业技术领域建立了智能工厂。他们整合了数字孪生和物联网，使用位置追踪技术实时追踪原材料和产品的移动，以优化制造过程。',
                c: '<strong>• GE智能制造设施： </strong> 通用电气在多个领域活跃，包括电力、可再生能源、医疗保健等，积极采用智能制造技术。他们使用数字孪生和物联网监视生产设备状态，并通过大数据分析实施预测性维护，最大化生产效率。',
            }
        },
        {
            head: '构建智能工厂',
            subHead: '1. 确立愿景和战略计划：',
            desc: '在建立智能工厂之前，企业需要确立智能工厂的愿景，并制定战略计划。明确要采用的技术和实现的目标。',
            dot: ''
        },
        {
            head: '',
            subHead: '2. 现状分析和需求定义：',
            desc: '分析当前的生产系统和技术水平，并定义引入智能工厂所需的要求。确定生产流程的优势和劣势，并确定需要改进的领域。',
            dot: ''
        },
        {
            head: '',
            subHead: '3. 技术选择和集成：',
            desc: '从数字孪生、物联网、大数据分析、人工智能、自动化等多种技术中选择，构建高效集成的系统。',
            dot: ''
        },
        {
            head: '',
            subHead: '4. 自动化和机器人技术的引入：',
            desc: '自动化部分或全部生产线，并引入机器人以自动执行劳动密集型任务。这有助于提高生产力。',
            dot: ''
        },
        {
            head: '',
            subHead: '5. 构建数字孪生：',
            desc: '创建产品或生产过程的数字孪生。这使得可以在数字环境中复制现实世界的物理对象和过程，并进行监测和优化。',
            dot: ''
        },
        {
            head: '',
            subHead: '6. 引入物联网技术：',
            desc: '连接生产线上的所有设备和机器，引入传感器和物联网技术以实时收集数据。这有助于监控和优化生产过程。',
            dot: ''
        },
        {
            head: '',
            subHead: '7. 引入位置追踪技术：',
            desc: '引入使用RFID、GPS、UWB RTLS等位置追踪技术，实时追踪产品、资源、员工等的位置。这有助于轻松实现最佳资源分配。',
            dot: ''
        },
        {
            head: '',
            subHead: '8. 应用大数据和人工智能（AI）：',
            desc: '利用大数据技术收集和分析生产数据。使用AI找出数据模式，进行预测性分析。这有助于优化生产过程，实现预测性维护。',
            dot: ''
        },
        {
            head: '',
            subHead: '9. 引入云计算：',
            desc: '将生产数据存储和处理在云中，实现对数据的实时访问和集中监控。支持不同地点之间的协作。',
            dot: ''
        },
        {
            head: '',
            subHead: '10. 加强安全性：',
            desc: '由于智能工厂涉及大量数据交换，因此安全性非常重要。引入适当的安全系统以确保数据的一致性和机密性。',
            dot: ''
        },
        {
            head: '',
            subHead: '11. 教育与基础设施改善:',
            desc: '为了有效地使用新技术和系统，我们将对员工进行培训。在需要的情况下，我们将改善基础设施以支持智能工厂的建立。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: '这是一款可以附着在工人或设备上，实时定位的UWB设备。',
                img: productImg.product.workerTracking.thumbnail
            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/cn/enterprise/medical/patient'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/cn/enterprise/retail/exhibition'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/cn/enterprise/office/asset'
            }
        ]

    }

}
