export default {
  titleBanner: {
  subtitle: 'UWB | Ultra - Wideband',
  title: 'UWB Location Tracking and Positioning Technology',
  description: '*UWB is a wireless communication technology that uses a very wide frequency bandwidth to transmit powerful signals in a very short period of time. UWB can provide high precision and high data transfer speed, which are useful in applications such as location tracking and radar.'
  },
  tech: {
  title: 'What is UWB Technology?',
  text: `Ultra-Wideband (UWB) is a short-range RF technology for wireless communication that can theoretically detect the location of people, devices, and assets with exceptional accuracy. Similar to other communication protocols like Bluetooth and Wi-Fi, UWB enables the transmission of data between devices through radio waves. It operates by using short nanosecond pulses to communicate in the "ultra-wide" range of frequencies. <br/><br/> UWB technology transmits billions of radio pulses crossing a wide frequency spectrum (at least 500 MHz or 20% of the center frequency) in a pattern every nanosecond. These signals are transmitted between transmitters and receivers, and the receiving devices analyze the incoming patterns and convert them into data. This allows devices to transmit data rapidly over short distances and also enables precise detection of the device's location using the UWB signal. Devices equipped with UWB functionality, such as smartphones or sensors and anchors, can accurately position other transmitting devices like smartphones or asset tracking tags, enabling location-aware communication and services in specific applications. <br/><br/> While it may seem like a new cutting-edge technology in recent smartphones and other devices, UWB technology has been in use for many years. It was commonly seen as "pulse radio" in military radar and communication applications for many decades. After the FCC approved its unlicensed use in 2002, UWB technology has undergone innovation and development, becoming a starting point for unlocking innovative location-based features.`
  },
  selection: {
  title: 'Increasing Adoption of UWB',
  text: 'Many major companies have recognized the various possibilities of UWB and have started developing or implementing UWB-enabled solutions. One notable company among them is Apple, which began implementing UWB technology with the introduction of the iPhone 11 and its new U1 ultra-wideband chip. Other companies like Samsung have also started implementing UWB technology in some of their latest smartphones and other devices. Google is also working on developing APIs to enable UWB applications on Android devices. <br/><br/> UWB is now a cost-effective option with small enough hardware components that can be embedded in various types of devices. The NFL even uses ultra-wideband technology for real-time player tracking, analyzing how players move during games by attaching UWB chips to their shoulder pads. NXP and Volkswagen are exploring the use of UWB in automobiles to provide safer and more convenient vehicle experiences. Additionally, there are leading innovators in UWB technology development, including consortia like the UWB Alliance and FIRA, as well as various UWB hardware companies that offer different UWB chips, anchors, and tags for specific use cases. Some experts predict that UWB technology will soon be as ubiquitous as Bluetooth and Wi-Fi and may even become a key standard for short-range communication and location confirmation, potentially replacing BLE.'
  },
  feature: {
  title: 'Advantages of UWB',
  text: 'UWB technology has many unique advantages that quickly establish it as a new standard in RF technology. It provides high data transfer speeds over short distances and allows for precise real-time positioning. UWB technology operates in an extremely wide frequency spectrum ranging from 3.1 to 10.6 GHz with a large bandwidth. Furthermore, it has very low power consumption, enabling low-cost and efficient hardware options like tracking tags. These tags can be powered by coin cell batteries, functioning without the need for charging or replacement over extended periods of time. <br/><br/> The reason UWB can detect locations with high precision is its ability to measure distances based on time-of-flight (ToF) between devices. It calculates how long it takes for a wireless waveform to travel from one device to another. While this method typically works over short distances, under optimal conditions and placements, UWB signals can pinpoint the location with accuracy of less than 50cm and show very low latency. In contrast, other technologies like BLE and Wi-Fi generally determine location based on received signal strength indicators (RSSI), which limits their accuracy to meters. <br/><br/> The low-power transmission and wide frequency spectrum of UWB allow it to coexist well with narrowband RF technologies without significant interference. Additionally, UWB appears "invisible in the noise floor" and is a good choice for coexistence with narrowband RF technologies. These unique characteristics contribute to the rapid rise of UWB as a premium indoor positioning technology in location-based and short-range communication applications.'
  },
  howItWorks: {
  title: 'How UWB Positioning Works',
  text: 'UWB enables location determination through Time-of-Flight (ToF). It calculates the time it takes for a signal to move between devices, allowing for precise measurement of the distance between transceivers. In some scenarios, the X, Y, and Z coordinates of device positions can also be detected, adding additional dimensions to the location capabilities provided by UWB. The method of ToF calculation can vary depending on the use case or application. <br/><br/> In UWB location measurements, two fundamental technologies can be used: Time Difference of Arrival (TDoA) and Two-Way Ranging (TWR).',
  items: [
  {
  title: 'Time Difference of Arrival (TDoA)',
  description: 'TDoA uses UWB anchors or sensors placed at fixed positions throughout the indoor space. These sensors detect UWB devices such as tracking tags and determine their positions. The fixed anchors must be accurately synchronized to operate at the same clock. UWB tags or other devices transmit signals periodically, which are received by all anchors within the communication range and timestamped by the anchors. Then, all the timestamp data is transmitted to a central IPS or RTLS. <br/><br/> The location engine analyzes the data and time differences between the anchors to draw hyperbolas and calculate the coordinates of the tag. These coordinates can be visualized on a map of the indoor space or utilized for different purposes in specific applications.'
  },
  {
  title: 'Two-Way Ranging (TWR)',
  description: 'TWR is a technique for measuring distances between two devices using bidirectional communication. For example, when two devices such as smartphones are in close proximity, they start measuring the distance between each other. The time it takes for the signal to travel between the two devices is multiplied by the speed of light to determine their relative positions. This technology is often used to enable location-aware communication. <br/><br/> While TWR can be used between fixed anchors and UWB devices, the determined positions between these devices are utilized for specific application purposes. TWR can measure the distance between one device and another using bidirectional communication, but the position of the devices determined by TWR can only be obtained by using one measurement partner at a time.'
  }
  ]
  },
  accuracy: {
  title: 'How Accurate is UWB Positioning?',
  text: `Thanks to the fast nanosecond pulses detectable by ToF and limited interference with other RF transmissions, UWB can achieve accurate indoor location tracking with a precision of less than 50cm under optimal conditions and placements. It can also provide real-time tracking of device movements and actions. <br/><br/> Compared to other indoor positioning technologies, UWB offers exceptional accuracy. Other standards typically determine location using received signal strength indicators (RSSI), providing accuracy only in the range of meters, such as with BLE (in 5m) and Wi-Fi (in 10m). Some UWB technologies also enable more precise location measurements through direction finding, which requires multi-antenna devices capable of measuring the angle of received signals.`
  },
  range: {
  title: 'What is the Communication Range of UWB?',
  text: 'UWB can detect the location of a device within a range of up to 200 meters. However, it is most effective within short distances ranging from 1 to 50 meters, especially when there is line-of-sight between the devices or anchors. In short distances, UWB provides highly accurate, fast, and secure communication while maintaining minimal interference.'
  },
  difference: {
  title: 'How Does UWB Differ from Other Positioning Technologies?',
  text: `Each RF standard has its own characteristics and advantages, and it can be a suitable option depending on individual requirements, budget, facility, and specific location-based use cases. The most significant differences between UWB and other technologies lie in the wide frequency spectrum and the level of accuracy it can provide. UWB's accuracy makes it particularly effective in advanced indoor location-based use cases where absolute positioning and real-time movements are crucial.`,
  items: [
  {
  title: 'UWB vs Bluetooth',
  description: `UWB and Bluetooth share many similarities, such as low power consumption, cost-effectiveness, and strengths in asset tracking technology. However, UWB can provide much higher accuracy compared to Bluetooth. This is due to UWB's precise distance-based positioning using ToF. BLE-based positioning technology typically relies on RSSI to locate devices, resulting in lower accuracy levels that depend on whether a device transmits a strong or weak signal to beacons or sensors. Bluetooth has a vast ecosystem and is a highly popular positioning technology used in many wireless devices, making it easy to implement flexible hardware options like BLE beacons.`
  },
  {
  title: 'UWB vs Wi-Fi',
  description: `Wi-Fi has become a core RF technology in indoor spaces due to its widespread usage in our devices and indoor environments. However, Wi-Fi's accuracy and flexibility can be limited in advanced location-based scenarios. In such advanced applications, higher accuracy is required, and that's where UWB excels. Wi-Fi typically measures signal strength instead of distance, resulting in lower accuracy compared to UWB, which is more resilient to interference. Additionally, UWB requires very low power, enabling the creation of useful and affordable tools like asset tracking tags powered by coin cell batteries. Wi-Fi, on the other hand, remains a significant indoor positioning technology due to its ability to leverage existing infrastructure, such as a variety of devices and access points supporting Wi-Fi. It becomes highly relevant when high accuracy is not a requirement.`
  }
  ]
  },
  advantage: {
    title: 'Key Advantages of UWB',
    items: {
    accuracy: {
    title: 'Precision Accuracy',
    description: 'UWB technology provides centimeter-level accuracy, making it an excellent choice for advanced location-based applications and enabling a wide range of functionalities that were previously impossible.'
    },
    position: {
    title: 'Real-time Positioning',
    description: 'UWB technology offers extremely fast location determination. With nanosecond-level communication, it enables true real-time indoor positioning and tracking, allowing immediate detection of the location, movement, and actions of personnel, assets, or vehicles with low latency and update rates of over 100 times per second.'
    },
    security: {
    title: 'Reliability and Security',
    description: 'One of the advantages of UWB is its ability to provide highly reliable communication with minimal interference or overlap with other wireless signals. By utilizing the precise location of the receiving device, UWB can also be used for secure communication over short distances.'
    },
    }
    },
    useCase: {
    title: 'Use Cases of UWB Positioning',
    description: 'The accuracy of UWB is particularly effective in advanced indoor positioning use cases that require high precision. UWB enables future Real-Time Location System (RTLS) technologies and demonstrates outstanding performance in various asset tracking applications. The following are current and future use cases and application areas where UWB can be utilized.',
    card: {
    physical: {
    title: 'Physical Asset Tracking',
    description: 'The precise accuracy of UWB serves as a crucial RF benchmark for asset tracking. Organizations in various industries can utilize the accuracy, speed, and reliability of UWB to track the real-time location and status of key assets and equipment with exceptional precision.',
    items: [
    {
    name: 'Enterprise',
    description: 'It allows large-scale enterprises to clearly understand the resources, assets, and equipment across their campuses and facilities, thereby enhancing productivity and resource allocation.'
    },
    {
    name: 'Healthcare',
    description: 'By leveraging asset tracking capabilities, it enables the quick and accurate identification and tracking of vital equipment, such as ventilators or wheelchairs.'
    },
    {
    name: 'Warehouse Management',
    description: 'It helps locate equipment, tools, and inventory within large facilities, particularly when precise inventory location is essential.'
    },
    ]
    },
    employee: {
    title: 'Employee and Personnel Tracking',
    description: 'Organizations can utilize the strong accuracy of UWB to determine the precise location of employees and personnel, enabling location-aware applications.',
    items: [
    {
    name: 'Employee Safety',
    description: 'In emergency situations or evacuations, the high precision accuracy of UWB is crucial for quickly determining the exact location of employees or personnel.'
    },
    {
    name: 'Workplace Preparedness',
    description: 'By utilizing UWB employee tags and badges, it enables precise contact tracing protocols and regulatory requirements that help prevent and mitigate disease transmission in indoor spaces. It also provides awareness of physical distancing compliance and efficient hygiene instructions.'
    },
    ]
    },
    location: {
    title: 'Location Services',
    description: 'Organizations can build smart buildings by utilizing the precise location of people and devices to facilitate various interactions and messaging.',
    items: [
    {
    name: 'Proximity Messaging',
    description: 'By incorporating UWB indoor location tracking technology, it can identify destinations near customers and assets, allowing for direct customer interactions, including coupons, marketing campaigns, and hyper-local content.'
    },
    {
    name: 'Geofencing',
    description: 'By creating virtual geographic boundaries around various areas within indoor spaces, it enables specific actions to be triggered when users enter, exit, or stay in designated areas.'
    }
    ]
    },
    navigation: {
    title: 'Indoor Navigation',
    description: 'By leveraging the high precision of UWB positioning technology, it immediately familiarizes and enables exploration of spaces through indoor navigation and wayfinding features.',
    items: [
    {
    name: 'Augmented Reality (AR)',
    description: 'The ability of UWB to provide precise location across indoor spaces opens up new opportunities for organizations to offer hyper-local and immersive augmented reality experiences.'
    },
    {
    name: 'Blue-dot Wayfinding',
    description: 'By providing users with an accurate understanding of their location within indoor spaces through the blue dot experience, it enables step-by-step guidance and wayfinding. It can provide highly accurate indoor experiences, particularly in industries such as enterprise, healthcare, retail, hospitality, and transportation.'
    }
    ]
    },
    business: {
    title: 'Business Intelligence',
    description: 'UWB can collect remarkably precise location data and convert it into powerful insights.',
    items: [
    {
    name: 'Analysis and Business Intelligence',
    description: 'By understanding the movement and location of assets, personnel, and devices and analyzing this location data over time, it provides organizations with a vision to make more intelligent and data-driven business decisions regarding how visitors interact with indoor spaces.'
    }
    ]
    },
    b2b: {
    title: 'Consumer-based and B2B Use Cases',
    description: 'UWB is pioneering numerous innovative consumer-based use cases to enable short-range communication and game-changing location-based information.',
    items: [
    { name: 'Smart Buildings and Access Control' },
    { name: 'Peer-to-Peer Communication' },
    { name: 'Smart Medical Devices' },
    { name: 'Vehicle Access Control' },
    { name: 'Secure Location-Based Mobile Payments' },
    { name: 'Smart Homes' }
    ]
    }
    }
    }
    };