import { accessAuto1, accessAutoMain, accessPro1, accessProMain, accesscontroller1, accesscontrollerMain } from "@assets/images/main/access/product";
import { assetTracking1, workerTracking1 } from "@assets/images/main/rtls/product";

export default {

    specTitle : {

    },

    specData : [
        {
          data: [
            {title:"产品名称"},
            {text1:"手机出入"},
            {text2 : "自动门出入"},
            {text3 : "人脸识别出入"},
          ],

        },
        {
          data: [
            {text:"说明"},
            {text:"用手机软件控制出入门的非常简便的机器"},
            {text:"无需运行应用程序即可方便出入的机器"},
            {text : "人脸识别、NFC卡等多种出入一次性使用的机器"},
          ],

        },
        {
          data: [
            {text : "电源供应"},
            {text : "电池使用（最多3年）"},
            {text : "DC 12V, 24V "},
            {text : "DC 12V, 3A"},
          ],

        }


    ],


    a: [
      {text:"产品名称"},
      {text:"说明"},
      {text:"支架"},
      {text:"精度"},
      {text:"通信方式"},
      {text:"定位器电源"},
      {text:"定位器机使用时间"},
      {text:"支持安装"},
      {text:"热图"},
      {text:"使用支架"},
      {text:"定位器搜索"},
      {text:"跟踪路线"}

    ],


    b: [
      {text : "蓝牙BLE定位追踪"},
      {text:"将BLE标签终端附在您希望监视或追踪的资产目标上，实现实时位置追踪。"},
      {text : "天棚、铁轨灯"},
      {text : "最大10m"},
      {text : "BLE"},
      {text : "CR 2430"},
      {text : "最长1年"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : ""},

    ],


    c: [
      {text : "高精度UWB定位追踪"},
      {text : "将UWB标签终端附在您希望监视或追踪的资产目标上，实现实时位置追踪。"},
      {text : "天棚、铁轨灯"},
      {text : "最大10cm"},

      {text : "UWB"},
      {text : "USB - C"},
      {text : "最多2周"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},

    ],
    
    
    spec: [
      
      {

      },

      {
        image : assetTracking1,

      },


      {
        image : workerTracking1,

      },
      
        
    ],
    
    
}

