import { accessAuto1, accessAutoThumb, accessKeyM1, accessPro1, accesscontroller1, accesscontrollerThumb, apartThumb, deadbolt1, deadboltM1, deadboltThumb, emlock1, emlockM1, emlockThumb } from '@assets/images/main/access/product';
import {
    access1off, access2off, access3off, access4off,
    access1on, access2on, access3on, access4on,
    accessKey1, accessKey2, accessKey3, accessKey4,
    accessOutlook,
    IconAccess, IconAccessInv, IconBattery, IconBluetooth,
    IconBox, IconChevRight, IconComputer, IconConsole,
    IconConsoleInv, IconCard, IconFacetime, IconGuarantee,
    IconQR, IconRTLS, IconRTLSInv, IconTemp, IconUsbc,
    IconWarn, IconWaterproof, IconCamera, IconZoom, IconTopology
} from '@assets/images/main/index';
import { bell1, esl1, idCard1, mCradle1, mCradleThumb, mTagThumb, switch1 } from '@assets/images/main/iot/product';
import { assetTracking2, tagUwb1, twinTracker1, twinTracker2, twinTracker3, twinTrackerBracket } from '@assets/images/main/rtls/product';
import { IconAtomGray, IconBinocularsInv, IconCalc, IconChainLinkGray, IconEyeline, IconInfo, IconMail, IconMonitorLineGray, IconPhone, IconPower, IconRoom, IconRulerGray, IconSignal, IconThumbUp, IconToolsGray, IconTvocGray, IconWifi } from '@assets/images/icon';
import { accessKeyThumbnail, accessproThumbnail, deadboltThumbnail, emlockThumbnail, eslThumbnail, orbroServerThumbnail, safetyBellThumbnail, switchThumbnail, twinTrackerThumbnail } from '@assets/images/main/thumbnail';
import { ipThumb, ptzThumb } from '@assets/images/main/camera';
import { loraGateway1, loraSensor1 } from '@assets/images/main/sensor';
import { assetTag1 } from "@assets/images/main/beacon/product";





export default {



    purchaseDesc: {
        mobile: `バッテリーが内蔵された製品で、電源工事の必要なく出入り口の横に取り付け、簡単かつ迅速にモバイルで出入り口制御が可能です。`,
        auto: `自動ドアへの出入りに特化した製品で、既存の自動ドアセンサー交換でモバイルアプリのインストールを通じて非接触出入りができるようにします。`,
        face: `Access Proは建物の共同玄関の出入り口を制御し、呼び出す機能を持つ製品です。その製品でユーザーを呼び出すと、モバイルアプリでリアルタイムのビデオ通話が可能になり、遠隔で出入り口を制御することができます。またNFCカードおよびパスワードロック解除が可能で、既存の共同玄関制御器に代わることができる最高の製品です`,
        accessory: "",

        asset: `BLEタグデバイスを対象物に取り付けて、資産として監視または追跡し、リアルタイムの位置追跡が可能です。`,
        worker: 'UWBタグデバイスを対象物に取り付けて、資産として監視または追跡し、リアルタイムの位置追跡が可能です。',

        outdoor: 'PTZ屋外カメラを使用して、駐車場の車両の位置を追跡します。',
        indoor: 'カメラを使用して室内の人の移動経路を分析できます。',
        monitoring: "Safety BellはWi-Fiがあればどこでも簡単に取り付けてすぐに使用できる製品です。",

        energy: "Smart Switchはアナログスイッチを簡単にデジタルスイッチに交換できる製品です。",
        display: `ESLはビーコンシステム基盤のディスプレイ装置です。 E-ink Displayを使用しているため、非常に低い電力でディスプレイを使用することができます`,
        cradle: `ケーブルロックの不便なくスマートフォンを無線で管理することができます`,
        idCard: "社員証はネックレス型で製造され、産業およびサービス領域の両方で高い互換性を持って使用することができます。オブロ RTLS システムとブルートゥース システムとの連携が可能です。",

        loraGateway: 'ALESS_GW装置は、工業環境に最適化されたスマートワイヤレス通信コンセントレーターで、センサーデータをネットワークサーバーに送信するスマートワイヤレスゲートウェイとして機能します。',
        loraSensor: 'この製品は、フィールドで必要なさまざまなセンサー（温度センサー、圧力センサー、漏れセンサー、湿度センサーなど）とインターフェースします。センサーデータを測定し、ワイヤレス通信を使用して送信します。',
        airQuality: '高精度な空気品質モニターで、微細粉塵、二酸化炭素、揮発性有機化合物の内蔵センサーを備えています。各センサー値のリアルタイム測定を表示し、データモニタリングのためにサーバーシステムと統合できる製品です。',
        coMeasure: '一酸化炭素だけでなく、微細粉塵（PM1.0/PM2.5/PM4.0/PM10）、温度、湿度をリアルタイムで測定するための内蔵センサーが備わっています。',

        beaconIndustry: '防水および防塵をサポートし、最大3年間使用可能で、産業で検証されたビーコンターミナルです。',
        beaconTag: 'どこにでも取り付け可能な小型のタグビーコンターミナルです。',
        assetTag: '資産管理用の超小型ブルートゥースタグ端末',


    },



    purchase: {

        mobile: {
            accesscontroller: {
                icon: accessKey1,
                title: 'Access Controller',
                serial: 'KB-BK02',
                price: '50,000',

                note: [
                    { text: `モバイルを利用した非接触出入り機能支援`, icon: IconInfo },
                    { text: `最新バージョンのBluetooth`, icon: IconBluetooth },
                    { text: '10m以内の出入り制御', icon: IconWarn },
                    { text: `IP65等級の防水防塵(-10~40℃)`, icon: IconWaterproof },
                    { text: `最大3年バッテリー使用期間`, icon: IconBattery },
                    { text: `1年間保証期間`, icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'EM Lock',
                        price: '150,000',
                        img: emlockThumb,
                    },
                    {
                        title: 'デッドボルト',
                        price: '150,000',
                        img: deadboltThumb,
                    },

                ],
                options: [],
                mark: "2",


                mainProduct: [
                    {
                        img: accesscontrollerThumb,
                        title: 'Access Controller',
                        price: '50,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },



        //자동문 출입
        auto: {
            accessauto: {
                icon: accessKey1,
                title: 'Access Auto',
                serial: 'OAC-AUBT5091',
                price: '150,000',

                note: [
                    { text: `モバイルを利用した非接触出入り機能支援`, icon: IconInfo },
                    { text: `最新バージョンのBluetooth`, icon: IconBluetooth },
                    { text: `2m以内の精密な出入り管理`, icon: IconWarn },
                    { text: '0~50℃作動温度', icon: IconTemp },
                    { text: `1年間保証期間`, icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'EM Lock',
                        price: '150,000',
                        img: emlockThumb,
                    },
                    {
                        title: 'デッドボルト',
                        price: '150,000',
                        img: deadboltThumb,
                    },
                    {
                        title: '室内自動ドアセンサー',
                        price: '99,000',
                        img: accessAutoThumb,
                    }

                ],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: accessAutoThumb,
                        title: 'Access Auto',
                        price: '150,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
            apart: {
                icon: accessOutlook,
                title: 'マンションパッケージ',
                serial: 'Access Auto 20 + Server 1',
                price: '5,000,000',

                note: [
                    { text: 'モバイルを利用した非接触出入り機能をサポート', icon: IconInfo },
                    { text: 'ORBRO Serverに ORBRO OSを含む', icon: IconComputer },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'EM Lock',
                        price: '150,000',
                        img: emlockThumb,
                    },
                    {
                        title: 'デッドボルト',
                        price: '150,000',
                        img: deadboltThumb,
                    },
                    {
                        title: '室内自動ドアセンサー',
                        price: '99,000',
                        img: accessAutoThumb,
                    }

                ],

                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '2,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: accessAutoThumb,
                        title: 'Access Auto',
                        price: '150,000',
                        min: 20,
                        max: 999,
                    }
                ]

            },
            cloud: {
                icon: accessOutlook,
                title: 'Access Auto (Cloud)',
                serial: 'OAC-AUBT5091',
                price: '29,000',
                priceSub: '/月',

                note: [
                    { text: 'モバイルを利用した非接触の出入り機能サポート', icon: IconInfo },
                    { text: '最新バージョンのBluetooth', icon: IconBluetooth },
                    { text: '2m以内の精密な出入り管理', icon: IconWarn },
                    { text: '0 ~ 50°Cの動作温度', icon: IconTemp },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],
                parts: [],
                options: [],
                mark: "1",

                mainProduct: [
                    {
                        img: accessAutoThumb,
                        title: 'Access Auto (Cloud)',
                        price: '29,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },

        //얼굴 인식 출입
        face: {
            accesspro: {
                title: 'Access Pro',
                serial: 'KSB-SL02',
                price: '1,200,000',

                note: [
                    { text: 'モバイルを利用した非接触の出入りとビデオ通話機能サポート', icon: IconInfo },
                    { text: '最新バージョンのBluetooth', icon: IconBluetooth },
                    { text: 'NFCカード出入り', icon: IconCard },
                    { text: '来訪者ビデオ通話', icon: IconFacetime },
                    { text: 'QRコード出入り', icon: IconQR },
                    { text: '0 ~ 40°Cの動作温度', icon: IconTemp },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'EM Lock',
                        price: '150,000',
                        img: emlockThumb,
                    },
                    {
                        title: 'デッドボルト',
                        price: '150,000',
                        img: deadboltThumb,
                    },
                    {
                        title: '室内自動ドアセンサー',
                        price: '99,000',
                        img: accessAutoThumb,
                    },
                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: accessproThumbnail,
                        title: 'Access Pro',
                        price: '1,200,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },


        },


        //출입 액세서리
        accessory: {
            accesskey: {
                title: 'Access Key',
                serial: 'KSB-AKC01',
                price: '30,000',

                note: [
                    { text: 'ビーコンキーを利用した非接触の出入り機能サポート', icon: IconInfo },
                    { text: '最新バージョンのBluetooth', icon: IconBluetooth },
                    { text: 'USB-C充電', icon: IconUsbc },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: " ",

                mainProduct: [
                    {
                        img: accessKeyThumbnail,
                        title: 'Access Key',
                        price: '30,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

            emlock: {
                title: 'EM Lock',
                serial: 'EM-Lock',
                price: '150,000',

                note: [
                    { text: '自動ドアではなく、防火戸や木製ドアなどの片方向の出入りに主に使用されるElectric Lockで、磁力を利用して出入り口を固定する装置です。', icon: '' },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: " ",

                mainProduct: [
                    {
                        img: emlockThumbnail,
                        title: 'EM Lock',
                        price: '150,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

            deadbolt: {
                タイトル: 'デッドボルト',
                serial: 'Deadbolt Lock',
                price: '150,000',
                note: [
                    { text: 'ドアを閉めると自動的にドアロックが施される自動施錠デッドボルトです。アパート、オフィスなどの出入り口、玄関などに使用できる自動施錠が可能なデッドボルトロックです。', icon: '' },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: " ",

                mainProduct: [
                    {
                        img: deadboltThumbnail,
                        タイトル: 'デッドボルト',
                        price: '150,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },


            autoDoorSensor: {
                title: '室内自動ドアセンサー',
                serial: 'Automatic Door Sensor',
                price: '99,000',
                note: [
                    { text: '人体または物体の動きを検知して自動ドアの開閉を制御するための高感度、高性能のデバイスです。', icon: '' },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: " ",

                mainProduct: [
                    {
                        img: accessAuto1,
                        title: '室内自動ドアセンサー',
                        price: '99,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },


        },


        //자산 추적
        asset: {
            assetTracking: {
                icon: accessKey1,
                title: '資産位置測位パッケージ',
                serial: 'TwinTracker 10 + Server 1',
                price: '9,500,000',

                note: [
                    { text: 'Bluetoothタグ端末を使用した資産の位置測位', icon: IconInfo },
                    { text: 'パッケージ当たり,最大1,000平方メートルの設置可能', icon: IconBox },
                    { text: 'ORBBRO ServerにORBRO OSを含む', icon: IconComputer },
                    { text: '1年間の保証期間', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'Asset Tag(BLE)',
                        price: '30,000',
                        img: assetTracking2,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },

                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTracker',
                        price: '450,000',
                        min: 10,
                        max: 30,
                    }
                ]

            },
            twinTracker: {
                icon: twinTrackerThumbnail,
                title: "TwinTracker",
                serial: 'OTR-TTBT0001',
                price: '450,000',
                note: [

                    { text: 'Bluetooth タグ端末を使用した位置追跡', icon: IconInfo },
                    { text: 'Ethernet および Wi-Fi ネットワーク接続', icon: IconTopology },
                    { text: 'PoE および USB Type-C 電源供給', icon: IconPower },
                    { text: '最大10mの精度', icon: IconRulerGray },
                    { text: '1年間の保証期間', icon: IconGuarantee },

                ],
                parts: [
                    {
                        title: "Asset Tag (BLE)",
                        price: "30,000",
                        img: assetTracking2,
                    },
                    {
                        title: "TwinTracker Bracket",
                        price: "150,000",
                        img: twinTrackerBracket,
                    },
                ],
                mark: "2",

                mainProduct: [
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTracker',
                        price: '450,000',
                        min: 10,
                        max: 999,
                    }
                ]
            },

        },


        //작업자 위치 추적
        worker: {
            workerTracking: {
                icon: accessKey1,
                title: '作業者位置測位パッケージ',
                serial: 'TwinTrackerPro 10 + Server 1',
                price: '11,000,000',

                note: [
                    { text: 'UWBタグ端末を使用した高精度位置測位', icon: IconInfo },
                    { text: '10cm以内の精度', icon: IconWarn },
                    { text: 'パッケージ当たり,室内最大600平方メートルの設置可能', icon: IconRoom },
                    { text: 'パッケージ当たり,屋外最大1,000平方メートルの設置可能', icon: IconRoom },
                    { text: 'ORBBRO ServerにORBRO OSを含む', icon: IconComputer },
                    { text: '1年間の保証期間', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'Tag(UWB)',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    }

                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 10,
                        max: 30,

                    }
                ]

            },
            twinTrackerPro: {
                icon: twinTrackerThumbnail,
                title: 'TwinTracker Pro',
                serial: 'OTR-TTUW0001',
                price: '600,000',

                note: [
                    { text: 'UWBタグ端末を使用した位置追跡', icon: IconInfo },
                    { text: 'Ethernet および Wi-Fi ネットワーク接続', icon: IconTopology },
                    { text: 'PoE および USB Type-C 電源供給', icon: IconPower },
                    { text: '最大10cmの精度', icon: IconRulerGray },
                    { text: '1年間の保証期間', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'Tag(UWB)',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },

                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTracker Pro',
                        price: '600,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
        },


        //차량 위치 추적
        outdoor: {
            carTracking: {
                icon: accessKey1,
                title: '車両位置測位パッケージ',
                serial: 'Server 1 + NVR 1 + PTZ Camera 2',
                price: '11,000,000',

                note: [
                    { text: 'PTZカメラを通じた駐車場の車両位置測位', icon: IconInfo },
                    { text: 'IP67等級の防水防塵', icon: IconWaterproof },
                    { text: '4MP解像度の高品質な映像', icon: IconCamera },
                    { text: '最大400mのIR距離での撮影', icon: IconWarn },
                    { text: '42倍の光学、16倍のデジタルズーム', icon: IconZoom },
                    { text: '1年間の保証期間', icon: IconGuarantee },


                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server + NVR',
                        price: '7,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: ptzThumb,
                        title: 'PTZ Camera',
                        price: '4,000,000',
                        min: 2,
                        max: 4,

                    }
                ]

            },
            ptz: {
                icon: ptzThumb,
                title: 'PTZ Camera',
                serial: 'OTR-CAPT0001',
                price: '2,000,000',

                note: [
                    { text: 'PTZカメラを使用して駐車場の車両の位置を追跡する。', icon: IconInfo },
                    { text: 'IP67等級の防水防塵', icon: IconWaterproof },
                    { text: '4MP解像度の高品質な映像', icon: IconCamera },
                    { text: '最大400mのIR距離での撮影', icon: IconWarn },
                    { text: '42倍の光学、16倍のデジタルズーム', icon: IconZoom },
                    { text: '1年間の保証期間', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: ptzThumb,
                        title: 'PTZ Camera',
                        price: '2,000,000',
                        min: 1,
                        max: 999,

                    }
                ]

            },
        },


        //유동 인구 위치 추적
        indoor: {
            peopleTracking: {
                icon: accessKey1,
                title: '人流分析パッケージ',
                serial: 'Server 1 + NVR 1 + IP Camera 4',
                price: '8,600,000',

                note: [
                    { text: '室内IPカメラを通じた人の位置測位', icon: IconInfo },
                    { text: 'IP67等級の防水防塵', icon: IconWaterproof },
                    { text: '2MP解像度', icon: IconCamera },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: "0",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server + NVR',
                        price: '7,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: ipThumb,
                        title: 'IP Camera',
                        price: '1,600,000',
                        min: 4,
                        max: 8,

                    }
                ]

            },
            ip: {
                icon: ipThumb,
                title: 'IP Camera',
                serial: 'OTR-CAIP0001',
                price: '1,600,000',

                note: [
                    { text: '屋内IPカメラを使用して位置を追跡し、異常な行動を検知します。', icon: IconInfo },
                    { text: 'IP67等級の防水防塵', icon: IconWaterproof },
                    { text: '2MP解像度', icon: IconCamera },
                    { text: '1年間の保証期間', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: ipThumb,
                        title: 'IP Camera',
                        price: '1,600,000',
                        min: 1,
                        max: 999,

                    }
                ]

            },
        },



        monitoring: {
            safetyBell: {
                icon: bell1,
                title: 'Safety Bell',
                serial: 'KB-EW02',
                price: '100,000',

                note: [
                    { text: '緊急時にボタンを押すと管理者にメッセージが送信されます', icon: IconInfo },
                    { text: '最新バージョンのBluetooth', icon: IconBluetooth },
                    { text: 'Wi-Fi接続による報告', icon: IconWifi },
                    { text: '100件の報告メッセージ送信', icon: IconMail },
                    { text: 'AAAバッテリー使用', icon: IconBattery },
                    { text: '1年間の保証期間', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: safetyBellThumbnail,
                        title: 'Safety Bell',
                        price: '100,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },




        energy: {
            smartSwitch: {
                icon: switch1,
                title: 'Smart Switch',
                serial: 'KSB-SS01',
                price: '80,000',

                note: [
                    { text: 'リモートで制御可能なスマートスイッチ', icon: IconInfo },
                    { text: 'Bluetooth 5.0', icon: IconBluetooth },
                    { text: 'モバイルアプリによる照明制御', icon: IconPhone },
                    { text: '非接触タッチによるスイッチ制御', icon: IconThumbUp },
                    { text: '1年間の保証期間', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: switchThumbnail,
                        title: 'Smart Switch',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
        },


        display: {
            esl: {
                icon: esl1,
                title: 'ESL',
                serial: 'KI-MDE1',
                price: '80,000',

                note: [
                    { text: 'インクディスプレイを使用した情報表現', icon: IconInfo },
                    { text: 'Bluetooth 5.0', icon: IconBluetooth },
                    { text: 'USB-C電源供給', icon: IconPower },
                    { text: '230dpiの解像度', icon: IconEyeline },
                    { text: 'NFCタグ', icon: IconSignal },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: eslThumbnail,
                        title: 'ESL',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
        },


        cradle: {
            mTag: {
                icon: mTagThumb,
                title: 'Mobile Tag',
                serial: 'KI-MT02',
                price: '80,000',

                note: [
                    { text: 'スマートフォンワイヤレスセキュリティデバイス', icon: IconInfo },
                    { text: 'Bluetooth 5.2', icon: IconBluetooth },
                    { text: 'iPhone、Android対応', icon: IconPhone },
                    { text: '1年間の保証期間', icon: IconGuarantee },

                ],

                options: [
                    { select: 'Lightning', imgIndex: 0 },
                    { select: 'USB-C', imgIndex: 1 },
                ],


                parts: [],
                mark: "",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: 'Mobile Tag',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

            mCradle: {
                icon: mCradle1,
                title: 'Mobile Cradle',
                serial: 'KI-MC02',
                price: '80,000',

                note: [
                    { text: 'スマートフォンワイヤレスセキュリティデバイス', icon: IconInfo },
                    { text: 'Bluetooth 5.2', icon: IconBluetooth },
                    { text: 'USB-C充電', icon: IconPhone },
                    { text: '1年間の保証期間', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: mCradleThumb,
                        title: 'Mobile Cradle',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },


        idCard: {
            idCard: {
                icon: idCard1,
                title: 'ID02',
                serial: 'KB-ID02',
                price: '100,000',

                note: [
                    { text: 'ブルートゥースベースの電子社員証', icon: IconInfo },
                    { text: 'ブルートゥース 5.0', icon: IconBluetooth },
                    { text: 'USB-C 電源供給', icon: IconPower },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                options: [],
                parts: [],
                mark: "",

                mainProduct: [
                    {
                        img: idCard1,
                        title: 'ID02',
                        price: '100,000',
                        min: 1,
                        max: 999,
                    }
                ]
            }
        },

        loraGateway: {
            loraGateway: {
                icon: loraGateway1,
                title: 'LoRa ゲートウェイ',
                serial: 'ALESS-GW',
                price: '1,500,000',

                note: [
                    { text: 'LoRaセンサー端末のデータ収集', icon: IconInfo },
                    { text: 'IP67等級の防水防塵', icon: IconWaterproof },
                    { text: '-20 ~ 60°Cの産業仕様の環境で動作可能', icon: IconTemp },
                    { text: '24VDC電源供給', icon: IconPower },
                    { text: '最大6kmの通信距離', icon: IconWarn },
                    { text: '最大150個のセンサーを収容可能', icon: IconSignal },
                    { text: 'LTE/Wi-Fi通信の選択が可能', icon: IconWifi },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: loraGateway1,
                        title: 'LoRa ゲートウェイ',
                        price: '1,500,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        loraSensor: {
            loraSensor: {
                icon: loraSensor1,
                title: 'LoRa センサー',
                serial: 'ALESS-BA',
                price: '500,000',

                note: [
                    { text: 'ユニバーサル入力センサーと連動可能な製品で、複数のセンサーインターフェイスをサポート', icon: IconInfo },
                    { text: 'IP67等級の防水防塵', icon: IconWaterproof },
                    { text: '-20 ~ 60°Cの産業仕様の環境で動作可能', icon: IconTemp },
                    { text: 'バッテリーおよび外部DC24V電源供給可能', icon: IconPower },
                    { text: '最大6kmの通信距離', icon: IconWarn },
                    { text: '複数の入力インターフェイスをサポート', icon: IconChainLinkGray },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [],
                options: [
                    { select: '非常ベル', imgIndex: 0 },
                    { select: '火災検知', imgIndex: 1 },
                    { select: '温度/湿度', imgIndex: 0 },
                    { select: '漏洩検知', imgIndex: 1 },
                    { select: '圧力', imgIndex: 0 },
                    { select: '侵入検知', imgIndex: 1 },
                ],

                mark: "2",

                mainProduct: [
                    {
                        img: loraSensor1,
                        title: 'LoRa センサー - 非常ベル',
                        price: '500,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },

        airQuality: {
            airQuality: {
                icon: mTagThumb,
                title: '空気品質計測器',
                serial: 'VENTAX',
                price: '800,000',

                note: [
                    { text: '産業用空気品質計測器', icon: IconInfo },
                    { text: 'カラーLCDディスプレイ内蔵', icon: IconMonitorLineGray },
                    { text: 'RS-485およびLoRa通信対応', icon: IconSignal },
                    { text: '自動クリーニング機能', icon: IconToolsGray },
                    { text: '韓国環境公園の微粒子等級1認定', icon: IconAtomGray },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [],
                options: [],

                mark: "2",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: '空気品質計測器',
                        price: '800,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        coMeasure: {

            coMeasure: {
                icon: mTagThumb,
                title: '一酸化炭素計測器',
                serial: 'VENTAX co+',
                price: '1,500,000',

                note: [
                    { text: '一酸化炭素および空気品質計測器', icon: IconInfo },
                    { text: 'RS-485およびWi-Fi通信対応', icon: IconSignal },
                    { text: '微粒子、一酸化炭素、温度/湿度計測', icon: IconTvocGray },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [],
                options: [],

                mark: "0",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: '一酸化炭素計測器',
                        price: '1,500,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        beaconIndustry: {

            plutoconPro: {
                icon: mTagThumb,
                title: 'プルートコン・プロ',
                serial: 'KB-BK02',
                price: '50,000',

                note: [
                    { text: '産業用ビーコン端末', icon: IconInfo },
                    { text: '最新のBluetoothバージョン', icon: IconBluetooth },
                    { text: 'IP65等級の防水防塵(-10 ~ 60°C)', icon: IconWaterproof },
                    { text: '最大3年のバッテリー寿命', icon: IconBattery },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [],
                options: [],

                mark: "0",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: 'プルートコン・プロ',
                        price: '50,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        beaconTag: {

            orbroTag: {
                icon: mTagThumb,
                title: 'ORBROタグ',
                serial: 'OAS-OTBT1991',
                price: '30,000',

                note: [
                    { text: '小型ビーコン端末', icon: IconInfo },
                    { text: '最新のBluetoothバージョン', icon: IconBluetooth },
                    { text: 'IP65等級の防水防塵(-10 ~ 60°C)', icon: IconWaterproof },
                    { text: '最大2年のバッテリー寿命', icon: IconBattery },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [],
                options: [],

                mark: "0",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: 'ORBROタグ',
                        price: '30,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },

        assetTag: {
            assetTag: {
                icon: assetTag1,
                title: 'Asset Tag (1,000EA)',
                serial: 'OAS-ABBT1991',
                price: '20,000,000',

                note: [
                    { text: '資産管理用の超小型ビーコン端末', icon: IconInfo },
                    { text: '最新バージョンのブルートゥース', icon: IconBluetooth },
                    { text: '最大1年のバッテリー使用期間', icon: IconBattery },
                    { text: '1年間の保証期間', icon: IconGuarantee },
                ],

                parts: [],
                options: [],

                mark: "2",

                mainProduct: [
                    {
                        img: assetTag1,
                        title: 'Asset Tag (1,000EA)',
                        price: '20,000,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        robot: {
            robotBasic: {
                icon: accessKey1,
                title: '配送ロボット基本パッケージ',
                serial: 'Delivery Robot + TwinTrackerPro 10 + Server 1',
                price: '要問い合わせ',

                note: [
                    { text: '屋内位置追跡システムとロボットシステムの調和、簡単な構成で迅速な導入が可能', icon: IconInfo },
                    { text: '配送ロボットの積載容量 20L', icon: IconWaterproof },
                    { text: '精度 10cm以内', icon: IconCamera },
                    { text: '屋内最大600平方メートルの設置が可能', icon: IconGuarantee },
                    { text: '屋外最大1,000平方メートルの設置が可能', icon: IconGuarantee },
                    { text: 'ORBROサーバーにORBRO OSが含まれています', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'タグ（UWB）',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'ツイントラッカーレールブラケット',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },
                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBROサーバー',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 100,
                        max: 300,
                    },
                    {
                        img: ipThumb,
                        title: '配送ロボット',
                        price: '要問い合わせ',
                        min: 1,
                        max: 10,
                    }
                ]
            },

            delivery: {
                icon: accessKey1,
                title: '無人上/下車パッケージ',
                serial: 'Delivery Robot + Loading Kiosk + Cobot + TwinTrackerPro 10 + Server 1',
                price: '要問い合わせ',

                note: [
                    { text: '無人で配送ロボットが上/下車できるように構成されたパッケージ', icon: IconInfo },
                    { text: '配送ロボットの積載容量 20L', icon: IconWaterproof },
                    { text: '協働ロボットの最大荷重 12kg', icon: IconCamera },
                    { text: '精度 10cm以内', icon: IconGuarantee },
                    { text: '屋内最大600平方メートルの設置が可能', icon: IconGuarantee },
                    { text: '屋外最大1,000平方メートルの設置が可能', icon: IconGuarantee },
                    { text: 'ORBROサーバーにORBRO OSが含まれています', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'タグ（UWB）',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'ツイントラッカーレールブラケット',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },
                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBROサーバー',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 100,
                        max: 300,
                    },
                    {
                        img: ipThumb,
                        title: '配送ロボット',
                        price: '要問い合わせ',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: 'ローディングキオスク',
                        price: '要問い合わせ',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: '協働ロボット',
                        price: '要問い合わせ',
                        min: 1,
                        max: 10,
                    }
                ]
            },

            disinfection: {
                icon: accessKey1,
                title: '高リスク管理パッケージ',
                serial: 'Delivery Robot + Loading Kiosk + Cobot + Disinfection Device + etc',
                price: '要問い合わせ',

                note: [
                    { text: '感染リスクの高い医療品を配送管理できるように構成されたパッケージ', icon: IconInfo },
                    { text: '配送ロボットの積載容量 20L', icon: IconWaterproof },
                    { text: '協働ロボットの最大荷重 12kg', icon: IconCamera },
                    { text: '99.9%の消毒および滅菌', icon: IconGuarantee },
                    { text: '精度 10cm以内', icon: IconGuarantee },
                    { text: '屋内最大600平方メートルの設置が可能', icon: IconGuarantee },
                    { text: '屋外最大1,000平方メートルの設置が可能', icon: IconGuarantee },
                    { text: 'ORBROサーバーにORBRO OSが含まれています', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'タグ（UWB）',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'ツイントラッカーレールブラケット',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },
                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBROサーバー',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 100,
                        max: 300,
                    },
                    {
                        img: ipThumb,
                        title: '配送ロボット',
                        price: '要問い合わせ',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: 'ローディングキオスク',
                        price: '要問い合わせ',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: '協働ロボット',
                        price: '要問い合わせ',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: '消毒デバイスキット',
                        price: '要問い合わせ',
                        min: 1,
                        max: 10,
                    }
                ]
            },
        }




    },


    modal: {

        title: '製品のお問い合わせ',
        descHead: '以下の項目に情報を入力していただければ、担当者からお見積りをお送りいたします。',
        descMid: '迅速な回答が必要な場合は、',
        phone: '1522-9928',
        descTail: 'までお問い合わせください。',
        time: '平日 (10:00 - 19:00)',

        theme: {
            name: 'お名前 *',
            company: '会社名 *',
            email: '業務用メールアドレス *',
            phone: '電話番号',
        },
        placeholder: {
            name: '山田太郎',
            company: 'オブロ株式会社',
            email: 'example@orbro.io',
            phone: '090-1234-5678',
            ask: 'その他のお問い合わせ内容を入力してください。 (任意)'
        }



    },

    accessory: 'アクセサリー',
    soldOut: '品切れ',
    new: '新製品',
    request: '製品のお問い合わせ',
    select: '選択する',

    submit: '提出する',
    cancel: 'キャンセル'
}