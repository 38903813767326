import { StyledGridContainer } from "@components/Grid/Grid.style";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";
import { ImgWidth100, ParagraphColor, Spacing, SpacingDTM } from "@pages/CommonStyle/common.style";
import { ShowMoreButton } from "@pages/CommonStyle/commonBtn";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import { StyledOsImg } from "@pages/Main/Access/Access.style";
import i18n from "@pages/i18n";
import { CommonState } from "@redux/reducers/commonReducer";
import os from "@src/lang/ko/main/os/os";
import simple from "@src/lang/ko/simple";
import React, { useEffect } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";


const OS = () => {

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const SpaceBar = () => <>&nbsp;</>;

  useEffect(() => {
    localStorage.setItem('selectedOs', `rtls`);
  },[]);

  return (
    <>
      <StyledGridContainer>
        <>
          <DivAllCenter>
            <SpacingDTM d={"128"} t={"128"} m={"96"}/>
            <ImgWidth100 src={os.osLogo[layoutType]} alt="ORBRO OS Logo" />
            <DisplayH2 size="small">
              <Trans i18nKey={`home.os.title`} />
            </DisplayH2>
            <Spacing pixel="16" />
            <ParagraphColor color="#000" size="medium">
              <Trans i18nKey={`home.os.desc`} components={{ br: layoutType === 'mobile' ? <SpaceBar/> : <br/> }} />
            </ParagraphColor>
            <Spacing pixel="16" />

            <ShowMoreButton href={i18n.language === 'ko' ? '/os' : `/${i18n.language}/os`} rel="noopener noreferrer">
              <img src={simple.showmoreImg[i18n.language]} alt="learn more button" />
            </ShowMoreButton>

            <StyledOsImg src={os.bannerImg} alt="RTLS System" />

          </DivAllCenter>
        </>
      </StyledGridContainer>
    </>
  )
}

export default OS;