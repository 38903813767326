import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";
import digitaltwin from "@src/assets/images/digitaltwin/video/traffic-short.mp4";
import img from "@src/assets/images/blog/main/sub/m_p.png";

export default {

    
  helmet : '| 디지털 트윈(Digital Twin)이란? | 정의, 적용 사례',
  desc : '디지털 트윈(Digital Twin)이란 현실 세계의 물리적인 대상이나 프로세스를 디지털로 모델링하여 실시간으로 정보를 수집하고 분석하는 것을 의미합니다. 즉, 현실 데이터를 디지털 환경에서 시뮬레이션하고 최적화할 수 있는 효과적인 방법을 제공합니다.',
  keywords : '디지털 트윈, digital twin, smart city, 스마트 시티, 디지털 트윈 기술, 디지털 트윈의 가치, 디지털 트윈 활용 사례, 미래의 기술, 디지털 혁신, 미래 비전, orbro, 오브로, 콩테크',

  ogTitle : '',
  ogDesc : '',
  ogSite : '디지털 트윈(Digital Twin)이란? | 정의, 적용 사례',
  ogUrl : 'https://orbro.io/blog/digital-twin',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDn5W_M0e1npdG9n9MzOVOPXstcQFN609JOUoXoNFXx5-5yloUkGMm1ufjGdF0R9JPeeoW9ykX72SA54O2wG8Vc8sW09dZk2e0IDK9TeXeQEa--HWeYTcyW4jGYAJNHeFvfBs5a4BRrjqNwwxwwrqLUiPrfxt1Kh2LXvniTuSqgVgIjcSz_RxNn3xVuLJqTOqR4rqFbVHkYHahb2a4j49UHtTzHmHnfVmpdEQYWJVViBOrZ3tPK0EWAkyAmPCBugIKyPXfG0iiMHGMupqT-0FPP0g20RLM4zBag-s05hL1Ujp7TBBUm4Ib_MBExPF2QSW0-Ko50dZ7a9hFGGpBrZalaWJMlebmqnC01xrRhTTAcFFNKYYDtFKQNXpb6IQ43Ef9F4-Y4sfGroiz6XZChSKlwLGbp72vSY1vYD7KX3GNlBNvYgCchgmuUmC9myCzEJbjRkKRcKOFGCNqOwXq8Ql04pFpN3XNTkmcedlgJ8xLQIH6FvQ0eIrHRjI7BxmwNtFj2PCeXKhfii-bTYi3ZB9ao0wlQkWQ10gL_ZXLOaWbKY8h_pWysjm2m7iZ2zdJyJac7ZlaVuSwFAHC__vc17P51pGrnB957kwOqxbJPEUrfDsAwD0ucwfa9X5wm2OECrV5cGVJVGBUAz55SEPInk4aouVwJ5eGNvbUoi5mFQOqeqQd-SdcSyc9hMrhBbIK8zigjExJpaRWEchpc5nL5PiJ1Ap2b73ReZfichg5q3S7QYZbk1pQVzRkvF5aDagxH3bEDhyA-eoQBmUwXNZ-O1Rlw2yLhARV_3xTok0wVPRN1PRECRlPCwgkFBEOBCTJCHxr4evnVKMdan_h85axDlA4QL5fLDuhScyp8Yiy3f_L-av_QBdqjVIkovYRjt3Ofnds2NKJbIIEDkgjJ5fnvUgNxJHKpuO3_rrqHmZagGcPtWEq1ZiUTSnmPMFUvd0ip-6GeWiZCwnkJNzazZZ3y_sejVcyeAGQpURR2dl9a3CytaKdgrP0E5kIRrKZqFFzMRvEFEsCPQI1yC6GDAlnMfvv72q5nLzjm-gU_soTcmaBcciDyAbOhEPnFgayRxu0QEDHw-A-SJn8Ub_cYlh-adqEbax34AGDlnxQLW5cgoFo0SsnMA2xD14GMSns8hj8HFgyTd1D9LpDViC5e5qZimjm3K6z56isQsFByGjVb6CYaqdICSjtdtWBEsBFjqqxf3SwLXUKU-sdMuNMmi2rJeO5KsRUv2MjSRY6MIWgv0_nv629SFKM93QxSWT3de2SAWHmDtCI0l6zGKm3ts0heoIfsioTAKjyRw0DabC833amSZ4gIWF5IIwIdc9w1mj7lsTSi5JrZKoyo5ul2TTchUf_9QqFj3u7AYcfhreY7UvlPyJD7gzlp0GY8wyKXBvaFFq9_r84ZA8vOdTNArreD3OAi8owfQOAJ2_j4H3e6nsxTjTpWDLIoz0VPfiiT-1YXwlQ3yD0DMRkGckEnpWrCRwF-JTiI-1Cn2OTi1VgH2L1-Mw0wSen_gD_tT9lcRT4vlYC0eyRhDZlfNZAI2E8RRVwxB50SEeBgj=w1906-h1213',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '디지털 트윈(Digital Twin)이란? | 정의, 적용 사례',
  date : '2023-12-28',
  tag1 : '#디지털트윈',
  tag2 : '#디지털전환',
  tag3 : '#스마트시티',
  tag4 : '#RTLS',
    
  // <br/>
  text : [
    { 
      head : '디지털 트윈(Digital Twin)이란?',
      subHead : '',
      desc :'만약 여러분들이 자주 가는 식당, 회사, 학교, 도서관, 버스와 동일한 모습들을 디지털 화면에서 볼 수 있다면 어떨 것 같나요? 게다가 사진처럼 멈춰있는 것이 아니라 우리가 살아가는 모습 그대로를 실시간으로 보여준다면 말이죠.<br/>당신이 식당을 운영하고 있다면, 집에 가는 길에 매장에 가스를 끄고 나왔는지 기억이 나질 않을 때, 직접 돌아갈 필요가 없습니다. 버스 정류장에 버스가 언제 도착할 지 어림잡지 않아도 됩니다. 현재 위치를 직접 볼 수 있으니까요!<br/>이처럼 디지털 트윈(Digital Twin)이란 사람, 차량, 빌딩, 도시와 같은 현실 세계를, 디지털 화면 속 가상 세계로 구현하는 것을 의미합니다.<br/><br/>디지털 트윈(Digital Twin)이란 현실 세계의 물리적인 대상이나 프로세스를 디지털로 모델링하여 실시간으로 정보를 수집하고 분석하는 것을 의미합니다. 즉, 현실 데이터를 디지털 환경에서 시뮬레이션하고 최적화할 수 있는 효과적인 방법을 제공합니다. 이는 산업, 건설, 의료, 자동차 등 다양한 분야에서 활용됩니다.',
      dot : ''
    },
    { 
      head : '디지털 트윈 기술은 어떻게 작동하나요?',
      subHead : '',
      desc :'디지털 트윈은 현실의 사람과 물체, 건물을 디지털 형태로 변환하는 기술을 말합니다. 물체의 위치, 상태, 성능과 관련된 데이터를 실시간으로 수집하고 이를 디지털 화면에서 모니터링하는 것을 목표로 합니다. <br/><br/>디지털 트윈 기술의 작동은 다음과 같은 주요 단계로 구성됩니다:',
      dot : ''
    },
    { 
      head : '',
      subHead : '데이터 수집 및 연결',
      desc :'디지털 트윈의 핵심은 현실 세계에서 발생하는 데이터를 수집하고 연결하는 것입니다. 센서, IoT 디바이스 등을 통해 다양한 환경에서 발생하는 데이터가 실시간으로 수집됩니다. 이 수집된 데이터는 클라우드 기반의 플랫폼으로 전송되어 거기에서 가공되고 저장됩니다. 이로써 현실 세계의 상황이 디지털 환경으로 정확하게 반영됩니다',
      dot : ''
    },
    { 
      head : '',
      subHead : '모델링 및 시뮬레이션',
      desc :'수집된 데이터를 기반으로 디지털 트윈은 현실의 물리적인 대상을 정교하게 모델링합니다. 이 모델은 다양한 변수와 환경 요소를 고려하여 현실 세계에서의 행동을 예측하고 시뮬레이션합니다. 이를 통해 디지털 트윈은 여러 시나리오에서의 작동 방식을 시험하고 최적의 결과를 찾을 수 있습니다.',
      dot : ''
    },
    { 
      head : '',
      subHead : '실시간 반영 및 제어',
      desc :'디지털 트윈은 현실 세계와 실시간으로 연동되어 변화에 즉각적으로 대응합니다. 현실 세계에서 발생하는 사건이나 변경 사항에 대해 디지털 트윈도 자동으로 업데이트되며, 필요에 따라 실제 시스템에 제어 신호를 보냅니다. 이것은 디지털 트윈이 항상 현실과 일치하며 최신 정보를 반영하며 실제 시스템에 영향을 주는 데 필수적인 단계입니다.<br/>이러한 단계를 통해 디지털 트윈은 현실 세계의 물리적인 대상을 디지털로 반영하여 실시간 최적화와 향상된 의사 결정을 가능케 합니다.',
      dot : ''
    },
    { 
      video : digitaltwin,
      link : '/digital-twin',
      head : '스마트 시티와는 무슨 관계인가요?',
      subHead : '',
      desc :'디지털 트윈은 스마트 시티를 구현하는 데 핵심적인 역할을 합니다. 도시의 물리적 요소를 정밀하게 모델링하고 디지털로 표현함으로써, 스마트 시티는 실시간으로 도시의 상태를 파악하고 최적화할 수 있습니다. 예를 들어, 교통 시스템의 디지털 트윈을 통해 교통 흐름을 예측하고 조절함으로써 교통 체계를 향상시킬 수 있습니다. 또한, 환경 모니터링을 통한 디지털 트윈은 대기질, 물의 사용, 에너지 소비 등을 효율적으로 관리하는 데 도움이 됩니다.<br/><br/>디지털 트윈과 스마트 시티는 상호작용을 통해 서로를 보완하고 발전시키고 있습니다. 스마트 시티의 다양한 시스템은 디지털 트윈을 통해 현실 세계와 연결되어 실시간 정보를 공유하고 상황에 따라 조치를 취할 수 있습니다. 이러한 상호작용은 도시의 안전성, 효율성, 생활 편의성을 총체적으로 향상시키는 데 기여합니다.',
      dot : ''
    },
    { 
      head : '디지털 트윈의 장점',
      subHead : '실시간 의사 결정',
      desc :'디지털 트윈은 현실 세계의 데이터를 실시간으로 수집하고 분석하여 의사 결정에 필요한 정보를 신속하게 제공합니다. 이는 업무 환경에서 빠른 대응과 최신 정보에 기반한 의사 결정을 가능케 하며, 경쟁력을 유지하고 강화하는 데 기여합니다.',
      dot : ''
    },
    { 
      head : '',
      subHead : '자동화 및 효율성',
      desc :'디지털 트윈은 자동화된 시스템과 효율적으로 통합됨으로써 작업 프로세스를 최적화하고 생산성을 향상시킵니다. 실제 환경에서 발생하는 데이터를 기반으로 한 자동화된 의사 결정은 일상적인 작업을 단순화하고 인간 에러를 최소화하여 조직의 작업 효율성을 향상시킵니다.',
      dot : ''
    },
    { 
      head : '',
      subHead : '비용 절감 및 예측 가능한 유지보수',
      desc :'디지털 트윈을 활용하면 물리적인 시스템의 상태를 실시간으로 모니터링하고 예측할 수 있습니다. 이를 통해 예방적인 유지보수가 가능해지며, 시스템의 고장이나 문제를 미리 감지하여 비용을 절감할 수 있습니다. 또한, 정확한 데이터 기반의 예측은 유지보수 일정을 미리 계획할 수 있게 하여 예측 가능성을 높여줍니다. 이는 비즈니스에 안정성과 효율성을 도입함으로써 비용을 효과적으로 관리하는 데 기여합니다.<br/>',
      dot : ''
    },
    { 
      head : '산업에 디지털 트윈 적용 사례가 있나요?',
      subHead : '',
      desc :'',
      dot : ''
    },
    { 
      head : '',
      subHead : '제조업에서의 활용',
      desc :'제조 공정에서 디지털 트윈을 활용하면 생산 라인의 효율성을 높일 수 있습니다. 공정의 각 단계에서 발생하는 데이터를 기반으로 디지털 트윈을 구축하여 실시간으로 생산 환경을 모니터링하고 최적화할 수 있습니다. 이를 통해 생산 라인의 문제점을 빠르게 식별하고 개선할 수 있으며, 예측 가능한 유지보수로 생산 중단 시간을 최소화할 수 있습니다. 또한, 제품의 수명 주기 동안 디지털 트윈을 통해 제품의 성능을 모니터링하고 최적화하여 품질을 향상시키는 데에도 활용됩니다.',
      dot : ''
    },
    { 
      img : img,
      link : '/enterprise/manufacturing/process',
      videoDesc : '제조 디지털 트윈, 클릭하면 이동합니다.',
      head : '',
      subHead : '에너지 산업에서의 적용',
      desc :'에너지 산업에서는 발전소나 에너지 인프라의 디지털 트윈을 활용하여 에너지 생산 및 소비를 최적화하고 효율성을 향상시킵니다. 발전소의 장비나 인프라의 상태를 실시간으로 모니터링하여 정비 및 수리 작업을 예측하고 계획할 수 있습니다. 이는 에너지 생산의 안정성과 효율성을 높이는 데 도움이 되며, 예측 가능한 유지보수로 비용을 효과적으로 관리할 수 있습니다.',
      dot : ''
    },
    { 
      head : '',
      subHead : '의료 분야의 디지털 트윈',
      desc :'의료 분야에서는 환자의 건강 상태를 디지털 트윈으로 모델링하여 의료진이 개인 맞춤형 치료를 제공할 수 있습니다. 또한, 의료 기기 및 시스템의 성능을 실시간으로 모니터링하여 효율적인 운영과 신속한 대응을 가능케 하여 환자의 안전과 편의성을 향상시킵니다.',
      dot : ''
    },
    { 
      head : '',
      subHead : '자동차 산업에서의 활용',
      desc :'자동차 산업에서는 자동차의 디지털 트윈을 활용하여 운전 성능을 최적화하고 정비 및 수리 작업을 예측할 수 있습니다. 특히, 자율 주행 기술의 발전과 함께 디지털 트윈은 자동차의 환경 모니터링 및 상호 작용을 개선하는 데 큰 역할을 합니다. 이를 통해 운전자의 안전성과 편의성을 향상시키며, 차량의 수명 주기 동안 최적의 성능을 유지할 수 있습니다.',
      dot : ''
    },
    { 
      head : '디지털 트윈 전망은 어떤가요?',
      subHead : '',
      desc :'디지털 트윈 기술은 현재 산업 현장에서 빠르게 적용되고 있으며, 미래에는 더욱 높은 수준의 혁신을 이끌어낼 것으로 전망됩니다. 몇 가지 주요 전망은 다음과 같습니다.',
      dot : ''
    },
    { 
      head : '',
      subHead : '산업 혁신의 주도 역할',
      desc :'디지털 트윈은 제조, 건설, 에너지, 의료 등 다양한 산업에서 혁신의 중심에 서 있을 것으로 예상됩니다. 특히 인공 지능과의 융합, 5G 기술의 보급, 빅데이터 분석 등과 결합되면서 더욱 효과적으로 활용될 것으로 예측됩니다.',
      dot : ''
    },
    { 
      head : '',
      subHead : '스마트 도시의 핵심 요소',
      desc :'디지털 트윈은 스마트 도시 개발의 핵심 기술로 자리매김할 것으로 예상됩니다. 도시 인프라의 효율적인 관리, 교통 체계의 최적화, 환경 모니터링 등 다양한 영역에서 스마트 도시의 핵심 요소로 작용할 것입니다.',
      dot : ''
    },
    { 
      head : '',
      subHead : '사물인터넷과의 접목',
      desc :'사물인터넷(IoT)과의 결합을 통해 디지털 트윈은 더욱 강력한 기능을 발휘할 것으로 예상됩니다. 실시간 데이터 수집 및 분석 능력이 향상되면서 물리적인 대상의 디지털 모델링이 더욱 정밀해질 것입니다.',
      dot : ''
    },
    { 
      head : '결론',
      subHead : '',
      desc :'디지털 트윈은 물리적인 세계와 디지털 환경 간의 경계를 허물며 혁신적인 솔루션을 제공합니다. 이 기술은 다양한 산업 분야에서 효율성 향상과 혁신을 이끌어내고 있으며, 앞으로 더 많은 분야에서의 응용이 기대됩니다.<br/><br/>오브로의 디지털 트윈 기술을 확인하고 싶으시다면 아래 링크를 클릭해주세요.',
      dot : ''
    },
    { 
      linkText : '더 알아보기',
      link : '/digital-twin',
      head : '',
      subHead : '',
      desc :'',
      dot : ''
    },

  ],

  link : {
    page : 'rtls',
    numb : '1',
    product : [
        {
          text : productPurchase.purchase.asset.assetTracking.title,
          title : productPurchase.purchase.asset.assetTracking.title,
          serial : productPurchase.purchase.asset.assetTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.assetTracking.key1
        },
      {

      }

    ],
    solution : [
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/enterprise/manufacturing/asset'
      }
    ]

  }


}