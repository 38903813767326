
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const AdvantagePage = styled.div`

    background-color : #000;
    padding : 64px 0;


    overflow-x : hidden;
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        padding : 96px 0 64px 0;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 96px 0;
    }

`

export const AdvantageInnerSection = styled.div`
    display : flex;
    flex-direction : row;
    gap : 32px;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        flex-direction : column;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        flex-direction : column;
    }
`;


export const AdvantageTextBox = styled.div`
    width : 50%;
    display : flex;
    flex-direction : column;
    padding : 128px 40px 128px 0;
    z-index : 3;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        width : 100%;
        padding : 0;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        width : 100%;
        padding : 0;
    }
`;

export const IconSection = styled.div`
    display : grid;
    gap : 32px;
    grid-template-columns: repeat(2, 1fr);

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        grid-template-columns: repeat(3, 1fr);
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        display : flex;
        flex-direction : column;
    }
    
`;

export const IconBox = styled.div`
    display : flex;
    flex-direction : row;
    gap : 12px;
`;

export const Icon = styled.img`
    width : 32px;
    height : 32px;
    object-fit : contain;
`;

export const IconTextBox = styled.div`
    display : flex;
    flex-direction : column;

`;


export const Rsection = styled.div`
    width: 720px;
    position : absolute;
    height : 100%;
    right : -70px;

    display : flex;
    justify-content : center;
    align-items : center;

    overflow-x : hidden;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        width : 100%;
        position : relative;
        right : 0;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width : 200%;
        position : relative;
        right : 50%;
    }
`;

export const Rimg = styled.img`
    width : 100%;
    height : 100%;
    object-fit : cover;
`;