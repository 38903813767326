import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const Card23Page = styled.div`

    padding : 128px 0 96px 0;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        padding : 128px 0 96px 0;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 96px 0 64px 0;
    }

`;


export const CardLayout = styled.div<{ w: number, img: string, index: string }>`
    ${({ img }) => img && `background-image : url(${img});`}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;
    border-radius: 16px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
    padding : 24px;
    display : flex;

    ${({ w, index }) => index === '3' && `width : calc((2 / 3) * (${w}px - 64px) + 32px); aspect-ratio : 812 / 480 ;`}
  
    ${({ w, index }) => index === '1' && `width : calc((1 / 3) * (${w}px - 64px)); aspect-ratio : 388 / 480 ; max-width : 388px;`}
    
    ${({ w, index }) => index === '2' && `width : calc((1 / 3) * (${w}px - 64px)); aspect-ratio : 389 / 480 ;`}
    
    ${({ w, index }) => index === '4' && `width : calc((1 / 2) * (${w}px - 32px)); aspect-ratio : 600 / 480 ;`}
   


    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{

        ${({ w, index }) => index === '3' && `width : calc((1 / 2) * (${w}px - 32px)); aspect-ratio : 440 / 440 ;`}
  
        ${({ w, index }) => index === '1' && `width : calc((1 / 2) * (${w}px - 32px)); aspect-ratio : 440 / 440 ; max-width : 1920px;`}
        
        ${({ w, index }) => index === '2' && `width : calc((1 / 3) * (${w}px - 48px)); aspect-ratio : 288 / 440 ;`}
        
        ${({ w, index }) => index === '4' && `width : calc((1 / 2) * (${w}px - 32px)); aspect-ratio : 440 / 440 ;`}

    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 20px;
        width : 100%;
        aspect-ratio : 361 / 440 ;

        ${({ w, index }) => index === '1' && `max-width : 100%;`}
   
    }

`;



export const CardTextBox = styled.div`
    display : flex;
    flex-direction : column;
    height : 100%;
`;

export const DescBox = styled.div`
    margin-top : auto;
`;

export const Icon = styled.img`
    width : 48px;
    height: 48px;
    display : flex;
    object-fit : contain;
`;

export const InnerLabelBox = styled.div<{ color: string }>`
    display: flex;
    padding: 4px 8px;
    align-items: flex-start;
    border-radius: 4px;
    width : fit-content;

    ${({ color }) => color && `background: var(--primitive-blue-100, ${color});`}
`;


export const CardBox = styled.div`
    display : flex;
    gap : 32px;
    
    
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        gap : 24px;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        flex-direction : column;
        gap : 16px;
    }
`;

export const CardGap = styled.div`
    margin-top : 32px;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        margin-top : 24px;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        margin-top : 16px;
    }
`;
