export default {
  titleBanner: {
    subtitle: 'Monocular Vision',
    title: 'Monocular Visionを活用した<br/>位置測位およびポジショニング技術',
    description: '* Monocular Visionは、単眼カメラを利用して画像を取得および解析するコンピュータビジョン技術です。<br/>これに対して、ステレオビジョン技術は2つのカメラを使用して深度を測定し、それをポジショニングに反映します。'
  },
  
  tech: {
    title: 'Monocular Visionとは何ですか？',
    text: 'Monocular Visionは、単眼カメラを使用して2次元画像情報を取得する視覚技術です。この技術は非常にシンプルであり、一般的な単眼カメラがあれば簡単に実装できるため、さまざまな分野で使用されています。一般的な単眼カメラを簡単に導入できるため、さまざまな産業分野で広く使用されています。<br/><br/>Monocular Visionは、オブジェクトの検出および測位、自動運転、ロボット工学など、さまざまな分野で使用されています。たとえば、オブジェクト検出の分野では、ディープラーニングアルゴリズムを活用してMonocularイメージシーケンス内のオブジェクトを検出および測位する技術が普及しています。また、自動運転の分野では、カメラを使用して道路上の車線、信号、標識、歩行者などを検出し、周囲の車両の位置と速度を推定して安全に運転するのに使用されます。'
  },
  feature: {
    title: 'Monocular Visionを活用したRTLSの利点',
    text: 'Monocular Vision技術は単眼カメラを使用するため、システムを構築するためのコストが比較的低くなります。単眼カメラはステレオカメラと比較してコストが低く、大規模なインストールや広範な領域でのRTLSの構築において他の技術よりも低コストで構築することができます。また、機械学習技術と組み合わせて、事前に学習されたデータを基に正確な位置推定を可能にします。これにより、高い精度を保証し、最新のディープラーニング技術を活用すれば少量のデータでも学習が可能であり、構築および運用コストが削減されます。<br/><br/>Monocular Visionを活用したRTLSはTagが必要ないため、既存のRTLSと比較してコストの面で大きな節約効果をもたらします。Tag端末が必要ないことは、インストールと運用が容易であり、既存の施設との互換性が高く、導入が容易であることを意味します。また、Tagの信号を受信するための機器（Anchor）を設置する必要がないため、スペースの利用効率が高まり、これに基づいて施設の効率を向上させることができます。'
  },
  
  howItWorks: {
    title: 'Monocular Visionを活用したポジショニングの動作原理は？',
    card: {
      detection: {
        title: 'オブジェクト検出',
        description: 'VisionベースのRTLSでは、Deep Learningオブジェクト検出技術を使用して、イメージ内の対象を測位します。この技術は、イメージ内のさまざまなオブジェクトを識別および分類できるように学習されたAIアルゴリズムです。現在は約80種類のオブジェクトを認識できますし、顧客の要求に応じてさらに多くの種類のオブジェクトを認識するための追加の学習が可能です。'
        
      },
      estimation: {
        title: 'パースペクティブ変換',
        description: 'パースペクティブ変換を使用して、2Dイメージ上の特定のポイントを実世界の座標に変換できます。これには、カメラの内部および外部パラメータを使用してカメラ行列を構築し、この行列を使用して2Dイメージ上のポイントを実際の空間上のポイントに変換します。'
       
      }
    }
  },
  
  difference: {
      title: 'Monocular Visionを活用したRTLSは、Stereo Visionを活用したRTLSとどのように異なりますか？',
      description : `Monocular VisionとStereo Visionは、基本的に異なる動作をします。Monocular Visionでは、1つのカメラのみを使用してオブジェクトの位置を測位しますが、Stereo Visionでは2つのカメラが使用され、オブジェクトの位置は2つのカメラ間の時間差情報を使用して測位します。Monocular Visionは比較的低コストで実装でき、インストールおよびメンテナンスが簡単です。しかし、カメラから提供される2Dイメージのみを使用してオブジェクトの位置を測位するため、精度と信頼性が相対的に低くなる可能性があります。<br/><br/>一方、Stereo Visionはハードウェアの複雑さにより、Monocular Visionよりも導入、インストールを含む総合コストが高くなり、ソフトウェアの処理もより複雑です。同じイメージを処理しても時間差を計算する必要があるため、Stereo Vision RTLSはMonocular Vision RTLSよりもより多くの計算リソースを要求します。したがって、Monocular VisionとStereo Visionのどちらの技術でRTLSを実装するかは、使用目的や予算などを考慮して決定できます。`
  },
  
  
  
      
  advantage: {
      title: 'Monocular Visionの主な利点',
      items: {
      accuracy: {
          title: '比較的低コスト',
          description: 'Monocular Visionでは、1つのカメラのみを使用するため、ハードウェアを構成するためのコストが低価格です。また、単一のイメージセンサを使用するため、ソフトウェア的な処理も比較的簡単であり、低スペックのハードウェアでも実装が可能です。'
      },
      position: {
          title: '設置の制約が少ない',
          description: 'Monocular Visionは、カメラをどこに設置しても、オブジェクトの位置と動きを測位することができます。これは、適用可能な分野が多いことを意味します。たとえば、屋内の位置測位、自動運転車の歩行者検出と測位、スポーツ競技での選手測位など、さまざまな分野で活用されることができます。'
      },
      security: {
          title: 'さまざまな応用分野',
          description: 'この技術は、1つのカメラだけでもオブジェクトを測位できるため、導入およびメンテナンスが簡単で比較的低コストです。これにより、スマートホーム、ロボット、自動車などの日常生活でよく使用される分野に適用することができます。'
      },
      }
  },
  
      

    useCase: {
        title: 'Monocular Vision を用いたポジショニング使用事例',
        description: 'Monocular Vision」の拡張性は、様々な室内位置把握使用事例に効果的なオプションとして作用します。 <br/>以下はMonocular Vision室内位置把握技術が使用されている一部のユースケースおよび応用分野です。',
        card: {
          home: {
            title: 'スマートホーム',
            description: 'スマートホーム技術」は、家庭内の様々な環境をモニタリング·制御できる機能を提供します。 Monocular Vision技術を使えば、人と物の位置と動きを感知してより効率的なエネルギー使用、セキュリティおよび利便性を提供できます。',
            items : ` `
          },
          space: {
            title: '空間シェア分析',
            description: '建物の内部や駐車場などの空間からOccupancy Dataを収集し、空間の活用度を最適化することができます。 Monocular Visionを利用したRTLS技術は、空間内部に設置されたカメラを通じて空間内の物の位置および動きをリアルタイムで把握することができます。 これにより、空間内部の使用状況を把握し、空間の使用効率を高めることができます。',
            items : ` `
          },

          safety: {
            title: '産業安全',
            description: 'Monocular Vision を利用したRTLS技術は、産業現場での安全維持にも使用されます。 例えば、作業場内で人や物の位置をリアルタイムで把握でき、作業中に発生しうる事故予防に大きく役立ちます。 特に、産業現場では倒れた人や作業者が重大な傷害を負った時、これを素早く感知してゴールデンタイムを遵守するのに役立ちます。 RTLSシステムは、産業現場で発生する様々な危険状況を事前に感知し、早期に対応できるよう支援します。 これにより、作業者の安全と健康を守り、生産性と効率性を高めることができます。',
            items : ` `
          },
          analyse: {
            title: '展示品目選好度分析',
            description: 'Monocular Vision 技術は、展示品目に対する顧客の好みを把握するのに役立ちます。 カメラを通じて展示された品目に対する訪問者の動きと関心度を把握し、特定商品に対する関心度が高い顧客を識別したり、展示品目の位置と移動経路設計を最適化することができます。 これはマーケティング分野で重要な役割を果たします。',
            items : ` `
          },
          sports: {
            title: 'スポーツ分析',
            description: 'Monocular Vision 技術を利用して、スポーツ競技における選手の位置と動きを正確に測位·分析することができます。 これにより選手たちの動きを改善したり、競技戦略を開発するのに活用することができます。',
            items : ` `
          },
          security: {
            title: 'セキュリティ',
            description: ' Monocular Vision を利用したRTLS技術は、セキュリティ面でも様々に活用できます。 例えば、事物または人物の移動経路を測位して非認可人員の接近制限システム、CCTVモニタリングなどに使用できます。 このような技術は建物の出入口や主要施設の保安管理、軍事産業などにも活用できます。',
            items : ` `
          },

          
        }
      }



  }