import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { logisticsAsset, manufacturingAsset, manufacturingProcess, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4"

export default {
    helmet: '',
    desc: 'リアルタイムで場所情報と製造プロセスを地図上でモニタリングし、デジタル自動化によりスマート工場を構築し、生産性と品質を向上させることができます。',
    keywords: 'スマート工場、在庫管理、資産管理、プロセスモニタリング、生産自動化、工場のロボット、自動工場、自動プロセス、スマート工場、コンテック、オルブロ、rtls、オルブロ',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'スマート工場の構築と生産自動化ソリューション :: オルブロブログ',
    ogUrl: 'https://orbro.io/blog/jp/productionAutomation',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxXE6MzArHBPAtgIpznMDdCH4J8pK06Pt6RC09sNHW7PTLIF0PmPta2ymrlAdxzXB1xM2c15BXwtMiUlMiXHV5-rX_y-MCj5rxn28ZcepfxYkwYGbTtkD2tiwtidfaA7UEiWPc_QZU5UvVQ_07DIs56mvdkD5LITBtqiZP7tZHrG0NN30d5aoj0cErsx5-AXHIQuv5zO6bwkV_42KLIq2YCCSe_xQfkSXyh6zwheUsNCyZV5Cs9oUd6ImOHrCWsB1JXDQljN2q2G8-li2gz1NQV2v7__j_4t3KgJDoCmqWuuFG495xjseOwBNsdbOkL9jBB79LwHj2EeEfqTUCmPYuKjA1HxHrHCe1kQDTXbjBxYc-GoornZ3F1PG1fmwyz5EQt9n7BVSD2smPwW9zHqSVYdcwpwdzjgnVBZ3tKehLBz7gRUtfjRxxmYWMbP6-WvApgEY0_TXrwuOnT_3R44fYEsAQeQSJU1xGt-FT7Kbs_cEtrKJ09pqHvl4tOeWBKTBgqNPQi6jC3AU8eKzNgSPVI641s3fQ9Bgfpq8FFw6Rt4ry87oCoCXJErzzb8G6TI-DWbBnvd0s49QfnIWK2e6fLVdGmnzgy4vMgGa8kwu4YUXRDNn4lju7F-Uwglr1KkkLrfsUgBJe-eLigJnmV32eHXFTRod_bwAstvo2SUiqPG-jwDDjG2mEyQlODDkYR9ghqohTDwpQWEen7XYXcAzlcuo-W2XT-KLvL_3K0LaSWaV9RfuH4lEvJZg_5O4AZVvbaoonwP8c3O2kPDidXj-aGYpEkZAiERMu_6E3LuqbK6LYx3XKDOKkv_BB5569dm6PjLKrCNvyKpsQHYuRzAD6KjmMC2M55q_b4QrIldAJ3OIGc3it9CKfkRPBchL0fmymgKDrP4bYSRskJ7ezbG3mrJlmftUW4zRbdV51Be_JnMCpDP7ZHni4RsYAeKni05hHJmQlYGqb3kj64d5eiJwctbRLV1PRD_TN5cT_1IILP4xyTKxXanxncYJeyv_1ju9rI5-ROwlCt_yt6IDKC6svINvZQGxFd27XpmvlkiFq2KNNTbKekQ7YI9QP9Wssadz30lALiW08lksJZ35fwt2xP_-Wsi6PcMQz-P3uXI_70uAZdl2X5Qe1NEjfoVSUv3r9e8L7Kq22Xo1x7MClBLI1lb88GAe4GaKiYNmhYL1A3YP-hn22TIwRvcJuFHOWWQa1GuowDDCu5xve-5jEasGKyBgkoliyCv4Whu5WYvSKb5uBwYRzJWXr4AuyUGPGTZwcO_067lWFWNoJz9s6XviIJ8aHA7_zJn5ZQrokTVhOC-Cv8m6SM8YfA0bKWlE4hUZjbYdzQDsCl95UzT9WaGLONhe7h7EUo3Nt_oIXYtUso9Lqo6fu8nvLxskCelBaZ5xLRDKYnrYpxehQPCO-iTxz9WGYxKbrTFeHIXJU4jwKn-Envs1kbg34tE5lnMSGW9mGEJLIsO8h7yJ2VrKeks-V2StiHHhLCtQvCZEKJIwLfeQ8wnOuTHDHNAnkuQDSTk6mGn7bOFAOHw7L0Aloc1_6w_HfzWqtEyxlm_9bWDuQyLv9LXHa1=w2000-h1548',
    ogType: 'ウェブサイト',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'スマート工場の構築と生産自動化ソリューション',
    date: '2023-11-06',
    tag1: '#スマート工場',
    tag2: '#生産自動化',
    tag3: '#プロセスモニタリング',
    tag4: '#スマート工場',

    text: [
        {
            head: '',
            subHead: '',
            desc: 'モダンな製造業者は競争力を確保し、生産性を向上させるためにスマート工場の構築と生産自動化ソリューションに大きな関心を寄せています。この傾向は、現代技術の進化とスマートシステムの普及に伴い、スマート工場の構築のための主要な技術の1つであるリアルタイム位置追跡システム（RTLS）技術がますます重要になっています。',
            dot: ''
        },
        {
            video: video,
            link: '/jp/enterprise/manufacturing/process',
            head: 'スマート工場とは何ですか？',
            subHead: '',
            desc: 'スマート工場は、最新の技術と自動化システムを利用して既存の製造システムを革新し、生産プロセスを最適化することを目的とする製造環境を指します。スマート工場は製造業者にさまざまな利点をもたらし、品質向上、生産性向上、エネルギー効率向上などが可能になります。これらの利点を実現するために、RTLS技術が重要な役割を果たしています。',
            dot: ''
        },
        {
            head: 'スマート工場の外観',
            subHead: '',
            desc: 'RTLS技術はリアルタイム位置追跡システムを意味し、さまざまな技術とセンサーを使用してオブジェクト、機器、製品、労働者などの位置情報を正確に追跡する技術です。この技術をスマート工場に適用すると、次の利点が得られます：<br/><br/><strong>リソース管理と最適化：</strong> RTLSを使用すると、製造施設内のすべてのリソースのリアルタイムの位置情報を把握できます。これにより、機器、資材、労働者などを効率的に管理および最適化できます。<br/><br/><strong>生産プロセスのモニタリング：</strong> RTLSは製造プロセスをリアルタイムでモニタリングできるようにします。生産ラインで発生する異常を迅速に検出し対応できます。<br/><br/><strong>自動化とロボットの活用：</strong> RTLSを使用すると、ロボットや自動化装置の制御に使用できます。これにより、生産プロセスを自動化し、人的労働を最小限に抑えることができます。<br/><br/><strong>在庫管理：</strong>リアルタイムの位置情報を基に在庫を管理し、在庫の移動を追跡できます。これにより、コスト削減と効率的な在庫管理が可能になります。<br/><br/><strong>安全とセキュリティの強化：</strong> RTLSは労働者の位置を追跡し、作業場内の安全とセキュリティを向上させます。不正アクセスを検出し、事故の予防に役立ちます。',
            dot: ''
        },
        {
            head: 'スマート工場の構築方法',
            subHead: '',
            desc: 'スマート工場にRTLSを適用するために、以下のステップを進めることができます：<br/><br/><strong>要件分析：</strong>まず、製造業者の要件を分析し、どのRTLSシステムが必要かを決定します。<br/><br/><strong>センサーおよびハードウェアの設置：</strong> RTLSシステムを構築するために必要なセンサーおよびハードウェアを設置します。これらはオブジェクトと労働者の位置を追跡するのに使用されます。<br/><br/><strong>データ収集と処理：</strong> センサーが収集したデータを処理し、リアルタイムの位置情報を生成します。<br/><br/><strong>システムの統合：</strong> RTLSシステムを他の製造システムと統合して自動化とモニタリングに活用します。<br/><br/><strong>最適化と改善：</strong> システムが稼働したら、生産プロセスをモニタリングし、改善作業を継続して行います。',
            dot: ''
        },
        {
            head: '生産自動化とスマート工場の未来',
            subHead: '',
            desc: 'RTLSを中心としたスマート工場は、製造業の未来をリードする役割を果たすと期待されています。生産自動化とスマートシステムは品質と生産性の向上に重要な役割を果たします。さらに、人工知能（AI）とビッグデータ分析との統合により、工場運用がより知的で効率的になります。<br/><br/>スマート工場の構築と生産自動化ソリューションは、現代の製造業者が競争力を高め、持続可能な成長を実現するための不可欠なステップです。 RTLS技術は、これらの未来志向の取り組みをサポートし、革新的な製造環境の構築に貢献しています。製造業者は、スマート工場の構築に向けた成功を続けるために積極的にこのような技術を採用すべきです。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB端末を労働者や機器に取り付けてリアルタイムの位置追跡を支援する製品。',
                img: productImg.product.assetTracking.thumbnail
            },
        ],
        solution: [
            {
                img: manufacturingProcess.thumb,
                data: 'manufacturingProcess',
                link: '/jp/enterprise/manufacturing/process'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/jp/enterprise/manufacturing/asset'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/jp/enterprise/logistics/asset'
            },
        ]
    }
}
