import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet: '',
  desc: 'RTLS(Real-time Location Systems)是实时定位系统,是利用无线通信、RFID(Radio Frequency Identification)、GPS(Global Positioning System)、BLE、UWB等多种技术开发的定位技术。',
  keywords: 'RTLS，定位，室内定位，UWB，BLE，GPS，RFID，Smart Device，建筑工地、安全管理、医疗设备追踪、物流追踪、资产管理、访客管理',

  ogTitle: '',
  ogDesc: '',
  ogSite: `实时定位系统（RTLS）是？ :: Orbro Blog`,

  ogUrl: 'https://orbro.io/cn/blog/rtls',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EOa-nRYXA8PZImoRPlC90eAt55C_Yy5HojqGpcHjostLqtAF14MCSQ278cDyuN65UTXHiZHKvWeZoTRbQjbqQfGXnKhLe0Jzybk5GEEeDIBPuaQ2yJMVTE7cYE4ybvDVweuPcGID5jW-Km3DtdqhciBpUOpej1uGnUdDTETQFhnYEqhziXT9YHAFI273KfzuCNJ_uUqla7WVrXuO-L-JB9VOb02pjgn9OBX7ABY94YhcQuq6a-Ab-nBcHbSmBNGXO1A8mMU-tX8hUz0u8674fbuG1dG0s39XddJNEeATt4PPsUeHudjCnqM1mN5HYXmvKTbQDEwzHBUET0GnnPpP4qVOG863atiB02qL8oopfM9EARdQjyJvxwLzBI1I9gkPm8wG4--IG7ViMVK4wRq79qmHgONSyFMJoqVcMChZeB701Ly6tordATCjgSlwZmG4O0I9W-G0ngTMbYP3AFihY2RPoQic3cTW_4jhftk7gMEPACbeZIh6jokwFH2KqMxpAVFKgew8f6Xr5lEiMx1C32laNCJfgRS6Xcnp4Oz68hKxcpR_Fn2nHamfrquJVPM7rKuEcnfndeSOs3wrBpMk-FMkx3hgt1QqB4LW0OLmWNWl5ps3dhN2NItej8os9Zeh_2wcCMnFaM_hbDj-UrbDNl7QDg_oGk9Etqxc2xrfS1yM5uMP78VIHwgPqrsHx6RqMEtgLZFjVCRBhr_n61ELA0YFi3gN-ybLRz3qBXMpkAq9iGl79fwmZuKwVEMnOvMPMsGpxAwM-c2O_WDEF4Ux-6lUxX7d9BGAPDPSwRvcwfF8rfLhEnz_wpeT-Os5mApgdVujb0fYri1b7J9ghi54_nZCyDPci8vn-GpyhH0k6Y4b_g_m723hFbaw1OfNPXx1uc-riT8pR82xvGhJ_EtOPsWIXtaEtiljYneEBpDa24VRkJt5afbOwE99dwRbNKrrlPJc-uZBNlNy9NsH8_2tYFjt1fi2WO6DmDukQqiW3eiYKAY8rINaMN4i38u5wJT1AVlykMVMcqtcugjpg3MBh066wvi5rSKgh-P-2H7qVk58IrARg3z24QXHaA4KPkicsJEFI5I11-x0daCC5h3lo0BV-grpGI7fGDWd_eOyj76t509E6BIPpx6DQ3B_TawRKRHGCRXdagn5iJy66zrQfuaHi78tKYqs_N8Noa8_Un3XstK2nD_Y86r-SmsLonEhHAZFoCJ-yv3YbRA_fVz1QIDIxBNdfifkvLjy6SlgtRb84_XzUPKjcMBalkMWrjzOSWYvShQ8OHTopeZK1HJdDLBzbdi2a7sTKpULhRBcWWDzLkgDrdgQHPsc_gkq9ZnisEl3GNOElWsbItVNyDdeXriH5YdSibObkZStczC8FKOdKf1urhaY95eEUrEgFKtTDL44ZXtLgM7KbxUrLqXmp8_ZZT-IPBJss-j3ydlHfcbCQ2Vr0eXK_Alu4os65IyR3hgGYmYcmtci14dWMSKJjZmyQ6LqBV9bwNmfQxcm2CqnImGEdAVbfpHuy1tcwaJ4aZq8X6K4q-7Np--NMO-2u-ULgFP8OfGDSLgEvAfn5JOyig=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '实时定位系统(RTLS)是什么？',
  date: '2023-04-17',
  tag1: '#定位',
  tag2: '#RTLS',
  tag3: '#UWB',
  tag4: '#BLE',



  rtls: {
    title: 'RTLS技术是什么？',
    desc: 'RTLS（Real-time Location Systems）是一种实时定位系统，利用无线通信、射频识别（RFID）、全球定位系统（GPS）、BLE和UWB等多种技术开发而成的定位技术。<br/><br/>RTLS技术可以实时追踪物体、人员等，并收集和处理位置信息以供使用。通过RTLS技术，各行各业可以在物流、制造、建筑、零售等领域提供更准确、更高效的生产管理和物流服务。<br/><br/>RTLS技术在各个行业都有不同的用途。在制造业，可以用于追踪生产过程中设备或产品的位置；在物流业，可以用于库存管理和分销追踪；在建筑业，可以用于施工现场的安全管理、设备和物资追踪等。RTLS系统部署在公司独有的空间内，其目标不是从远程追踪资产，而是监测和改进过程。<br/><br/>RTLS技术由三个主要要素组成。',
  },

  realtime: {
    title: '1. 实时追踪',
    desc: 'RTLS技术能够实时获取资产的位置信息，准确了解其在整个生命周期内的位置。这包括生产线上移动的产品、工厂内的工具、到达仓库的原材料、车辆位置、员工位置等几乎所有物体。企业可以更有序地监视和管理资产位置，从而在生产线管理、物流服务、安全管理等多个领域实现有效利用。',
  },

  predict: {
    title: '2. 趋势与预测',
    desc: '当可以测量资产的位置和移动时，可以更轻松地识别出可能发生的错误、不必要的步骤、资源短缺、员工异常行为等问题。通过这些信息，可以及早发现可能发生的问题，并在制造、物流、安全管理等多个领域更有效地进行管理。',
  },

  geo: {
    title: '3. 地理围栏',
    desc: '在三维空间内定义虚拟边界，在员工安全、污染防控、安全加固等多个领域得到应用。通过这种方式可以保护员工免受危险区域的影响，防止污染，加强安全等。',
  },

  howItWorks: 'RTLS是如何工作的？',

  core: {
    title: '核心组成要素',
    desc: 'RTLS系统由三个主要要素组成，分别是发送器、接收器和用于整合数据和位置分析的软件。每个RTLS供应商可能会对其技术使用略有不同的术语，并侧重于其独特的销售点。以下是概括这些核心系统组成要素的描述。',
  },

  send: {
    title: '1. 发送器',
    desc: '在RTLS中，发送器主要指与标签或接收器相连接的设备。该设备用于识别物体或个人并将位置信息传输给接收器。发送器通常使用无线技术运行，其大小和性能根据技术的不同而异。发送器可以附加到物体上，也可以佩戴在个人或动物身上。一些型号使用不需要电源的“被动”发送器，这些发送器使用近距离射频识别（RFID）技术。其他型号使用需要电源的“主动”发送器，这些发送器通常使用蓝牙低功耗（BLE）或超宽带（UWB）等无线技术。此外，GPS标签也可以用作发送器。',
  },

  rfid: {
    title: '1. 射频识别(RFID)标签',
    desc: '射频识别(RFID)标签是最常见的标签之一，它以被动方式（即无需电池电源）运行，用于标识几乎所有物体。',
  },

  ble: {
    title: '2. 蓝牙低功耗(BLE)标签',
    desc: '蓝牙低功耗（BLE）标签发射最普遍的无线通信信号。由于大多数移动设备都支持BLE，因此具有广泛适用性，但精度低于UWB。',
  },

  uwb: {
    title: '3. 超宽带(UWB)标签',
    desc: '超宽带（UWB）标签通常需要电池供电，并在UWB接收器上接收信号。信号持续时间非常短，但使用宽带频率范围提供非常精确的位置数据，同时保持低功耗。',
  },

  smart: {
    title: '4. 智能设备',
    desc: '各种智能设备都可以作为发送器运行，最典型的例子是智能手机。它们可以同时使用BLE和GPS技术，并随着UWB技术的普及，一些型号也支持基于UWB的追踪。',
  },

  gps: {
    title: '5. GPS标签',
    desc: 'GPS标签使用卫星确定位置。然而，它仅在室外环境下可见，并且不需要额外的基础设施。GPS标签的精度较UWB标签低，而且相对较耗电。但是，通过添加实时运动学（RTK）GPS进行校准，可以将GPS标签的精度提高到厘米级。',
  },

  receive: {
    title: '接收器',
    desc: '根据所使用的标签类型，用于追踪标签的接收器也会有所不同。在复杂的室内环境中，简单的被动射频识别（RFID）标签是不够的，可以使用主动RFID、蓝牙低功耗（BLE）或超宽带（UWB）技术。 <br/><br/> 将标签附加到重要的资产上，并在物理空间（例如工厂、飞机机库、办公室等）周围布置接收器，接收器可以随时确定每个资产的位置并将此信息传递给相关的软件/可视化系统。这些接收器通常直接连接到电源和网络（有线或无线）。',
  },

  software: {
    title: '软件',
    desc: 'RTLS技术涉及运行在硬件上（如传感器和标签）的固件，以及解释位置数据的外部应用程序和连接到外部应用程序的中间件等多种软件系统。换句话说，这些软件共同运作，使RTLS系统正常运行。<br/><br/> 在这些软件中，最受企业关注的是外部应用程序的软件。这些软件与RTLS进行交互，并决定从中获益的方式。根据所属行业的不同，应用软件可能会有很大的差异。许多RTLS公司提供专为优化企业总体概述并提供简便洞察的定制软件应用程序。<br/><br/> 在RTLS系统中，可视化通常起着核心作用，选择RTLS软件解决方案时应考虑以下因素：<br/><br/> 平面图和区域映射-软件能否精确地反映实际平面图并按比例缩放？<br/> 软件的实用性-除了漂亮的可视化外，是否还包含适合用户目标的基本功能？<br/> 质量报告-是否存在用于即时获得洞察的分析和报告功能？<br/> On-Premises还是Cloud-是否提供最适合托管软件的选项？<br/> API连接-软件是否可以通过API与现有业务系统和数据库连接？<br/> 可扩展性-该软件是否能够随着业务增长而扩展，并经过验证在收集位置数据流时不会出现瓶颈？<br/> 支持与培训-这个解决方案是否容易启动，并且提供对承担重要任务的应用程序的支持？',
  },

  conclusion: {
    title: 'RTLS技术的应用案例是什么？',
    desc: '随着对RTLS技术的认知增加，该技术可以应用的行业越来越明确。以下是一些典型的例子。',
  },

  advantage: {
    safety: {
      title: '建筑工地安全管理',
      desc: '实时追踪工人、建筑设备、材料的位置和移动路径，实时监测风险区域或行为，向工人发送警报，以预防事故。',
    },
    assets: {
      title: '物流仓库资产追踪',
      desc: '定位技术可实时掌握资产的位置，减少员工寻找资产位置所需的时间和努力。这有助于提高工作效率，并通过优化库存移动路径提高物流过程的效率。',
    },
    medical: {
      title: '医院医疗设备和患者追踪',
      desc: '定位技术可实时掌握医疗设备和患者的位置，提高医院设备使用效率和患者服务质量。',
    },
    monitor: {
      title: '实时生产过程监控',
      desc: '在制造业或工厂中，可以实时追踪产品的位置、生产线的运动和员工的位置等。通过实时了解位置信息，可以帮助优化工厂的生产线，提高生产过程的效率，并改进产品质量。',
    },
    visitor: {
      title: '展览场所访客分析',
      desc: '在博物馆、展览馆、零售店等场所，可以分析访客的时间分布情况。通过了解访客的移动路径、访问频率、停留时间和受欢迎的展品等信息，可以调整展品或商品的位置和布局，提高访客的满意度。',
    },


    label: 'RTLS技术在汽车、航空航天、国防、维修/维护/运营、公共交通、仓储和物流、医疗等多个行业中都应用了RTLS技术，以追求更高的效率和安全性。RTLS技术对于增强企业竞争力非常重要。通过使用RT希望这篇实时定位系统（RTLS）的解释对您有所帮助。如果您对其他话题有兴趣或有任何其他问题，请随时向我询问！',
  },

  share: '喜欢你的文章吗? <br/> 共享',


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: "将UWB终端机安装在工作者或设备上，帮助实时追踪位置的产品。",
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]


  }

}