import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {


  helmet: '| RFID 대신, RTLS',
  desc: 'rfid 기술이 가진 단점을 보완하기 위해서, rtls 기술을 사용하면 더 현대적이고 효과적인 효과를 경험할 수 있습니다. 지금 오브로의 기술을 확인해보세요.',
  keywords: 'rfid, rtls, uwb, 무선 통신, 위치 추적, 물체 위치 추적, 사람 위치 추적, 태그, 비콘, rtls, orbro, 오브로, 콩테크',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RFID 대신, RTLS :: 오브로 블로그',
  ogUrl: 'https://orbro.io/blog/rfid-rtls',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDydFqsSpxfUGJRfgotjd5r5s27p9Jf2p08DcmZQY7K9VFxeg9rqDdYY9Gty2zcbPdkTtDG8IT8VHVI8Cu9Xay4Y9sNkWKyhNf5MZZfEJmSwx5RxDXIBhNLiVFBCl6asQi7XkVXIwVpqgVKugt94uXMpRmAUIUCYNCbhGMRV0zD7wJHpv6g4sEO3b3FkYBmPo0wrorAZ2UZIwYz4wRbFewYqqUgH0P6BidU5KE0kQsn2HGiIRRQlmCsPEY3rI0XKSGLatvI5vRo7-7c8DOoNlcOIpsMLXxaBYF3suQGFhdxrqEhA8t5mY3vsnWX2JOTgDdhEae70uPkPmf6IqEUhYr4i-kDTR-rCIjoikpAUHb2w5BUtdC3vMMJ3K8zZ2WbXTUDfkeCyjCN8C95-hMsUQX_dK52Yb2k6kTPrFuJWsiL7OSFsXX7TyjBajZX3yx1DfjD2OitZ7pZZSD6HV37MrXk0sykUNnUTbjSdW3NNVWoIBs0F4ur3TY23NSm8UKCWdXDvmlGsQrur-inPNF8C0iYeb2fEkwmH-WPv92-_V2JM5Q7t--49WhtDqsZHE5jlndPD7lFWwQa2qfEKhOExytxc3Vxt4LvM9BWSz3eR4p0jQt8OX3pXvRFcoc6wZEGANIoVWjvXj8HyvYZaYE56ckP2tHBQoruYpNVs4AfP8_ECNMPz4tfxLmvLQF9XYGOu_QZAFxMshOsDsJmAFOiRvKRKtNYQzCaJFgZCWibWTWvkbo52R_irSOgQZqIahJbvebkjVJmY8s9HJ0ouwoM7hApeuHgaFpMQ8gN1ov_4CqlBoa_pGCFaZvMOiJzqLIImEuRDrGWGOb9XuHS6dI22h-k3C5498w8ybVmF45VfA76In3QlmWtMx7ua2zT_bsj1AWfMJWaglRKkImhInZNRwMZFSSifk4ept8JyDKQox_XnxyJkVdojTLFKruj3z_uJ6M8nVcbIspvyz1QggC63Fp3acz35N2EXmQ0J8JZeWUnyLrhuwfJ-Wc6Ovja6LEF9gbjgAoesYWEghHYi3ByOzsEcFhHsQM9cQ1UgZUrRg_nf3Ic1V49R3ewe6J5HmaIqXADdHXQ4fWuZjZ4RpRe9ffQThkcH9PwHGFH5eRvgMLO2Ey8q_PdaHnN-1B2tUJ7IWkt2GMllFbOnfC2F7Z7yhNLOWss-MAXrtBW702-RreLR8GFAlMB7UnW3xrKC7GJCnJqJQfL913t9SZJyXm_52svc-p4d0UlQvuYUN7N0AA34STZtooGf9cSNITDeb62jWztMNJFGTw5YVTHqdsVttLICWXOO1_Q8_ygXfLtKu_YvfGDfhZqIqyMgk8OcDk1rYzMYok0P0j6grBuhSs8IyoPctYZddBCbfjze8PiLcDu22_gIz6biHHDr1-R4WtPNoyWavEKXnWNLMRAnSxy67bG83C5wbqYWv4pDA8mTGd1QfYqxLh4e2a4TXm6vxN0SOt_CJZSEa6cO64dcsuX74_Y12xT2AtRXErifMIoUyB0JFZCUbLPWq1jd20L_J22kFD6e-uaGmBSUQH_gZsMmYuS9Sf82buto=w2087-h1196',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RFID 대신, RTLS',
  date: '2023-11-28',
  tag1: '#rfid',
  tag2: '#rtls',
  tag3: '#uwb',
  tag4: '#무선통신',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: 'RFID 기술은 수년 동안 기업의 물류 및 자산 추적 분야에서 효과적으로 활용되어 왔습니다. 그러나 최근에는 RFID 대신 RTLS(Real-Time Location System) 기술이 물류 및 자산 관리 분야에서 주목을 받고 있습니다. 이 글에서는 RFID 대신 RTLS가 어떻게 기존 방식을 넘어선 혁신을 가져오고 있는지에 대해 살펴보겠습니다.',
      dot: ''
    },
    {
      head: 'RTLS의 개념과 동작 원리',
      subHead: '',
      desc: 'RFID는 물리적인 라벨이나 태그를 통해 물체를 식별하는 기술로, 특정 범위 내에서만 정보를 수집할 수 있습니다. 반면 RTLS는 무선 통신 및 위치 추적 기술을 결합하여 실시간으로 물체의 위치를 파악할 수 있는 시스템입니다. RTLS는 GPS, 센서, 비콘 등을 활용하여 정확한 위치 정보를 제공하므로 물류 및 자산 관리에 있어 탁월한 성능을 발휘합니다.',
      dot: ''
    },
    {
      video : video,
      link : '/enterprise/manufacturing/asset',
      head: 'RTLS의 장점',
      subHead: '실시간 위치 추적',
      desc: 'RTLS는 물체의 실시간 위치를 정확하게 파악할 수 있어 물류 관리에서의 실시간 트래킹이 가능합니다. 이는 제품의 이동 경로를 최적화하고 물류 작업을 효율적으로 수행할 수 있게 합니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '정확한 데이터 제공',
      desc: 'RFID는 특정 범위 내에서만 정보를 수집할 수 있어 정확성에 제한이 있습니다. 그러나 RTLS는 센서와 결합되어 높은 정확도로 위치 정보를 제공하므로, 물류 및 자산 관리에서 정확한 데이터를 얻을 수 있습니다.',
      dot: ''
    },
    {
      head: '어떤 기술을 선택해야 할까요?',
      subHead: '다양한 활용 분야',
      desc: 'RTLS는 물류뿐만 아니라 의료, 제조, 운송 등 다양한 산업 분야에서 활용 가능합니다. 이는 기업이 한 가지 기술을 다양한 영역에 적용하여 효과를 극대화할 수 있음을 의미합니다.',
      dot: ''
    },
    {
      head: '성공 사례 및 사업적 효과',
      subHead: '',
      desc: '세계적으로 RTLS를 도입한 기업들은 높은 생산성과 효율성을 달성하고 있습니다. 예를 들어, 의료 분야에서는 RTLS를 활용하여 의료기기 및 환자 위치를 실시간으로 추적함으로써 응급 상황에서의 신속한 대응이 가능해지고, 물류 분야에서는 제품의 이동 경로 최적화를 통해 비용을 절감하고 있습니다.',
      dot: ''
    },
    {
      head: '미래 전망',
      subHead: '',
      desc: 'RFID가 기존에는 어려웠던 환경에서도 효과적으로 사용되어 왔지만, RTLS의 등장으로 더욱 정교하고 효율적인 물류 및 자산 관리가 가능해졌습니다. 향후에는 인공지능과의 결합, 블록체인 기술의 도입 등으로 더욱 진화된 RTLS 시스템이 나올 것으로 예상되며, 기업들은 이러한 기술을 적극적으로 도입하여 경쟁력을 확보해야 할 것입니다.',
      dot: ''
    },
    {
      head: '마무리',
      subHead: '',
      desc: 'RFID 대신 RTLS가 주목받는 이유는 실시간 위치 추적과 정확한 데이터 제공으로 더욱 효율적인 물류 및 자산 관리를 가능케 한다는 점에 있습니다. 기업들은 기존의 방식을 뛰어넘는 혁신적인 기술을 적극적으로 도입하여 미래 시장에서의 경쟁에 대비해야 합니다. RFID에서 RTLS로의 전환은 현대 기업이 새로운 기술을 통해 더 나은 미래를 준비하는 한 걸음일 것입니다.',
      dot: ''
    },

  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/enterprise/office/asset'
      }
    ]

  }


}