import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/constructionSafety/video/rtlsVideo.mp4";

export default {

    
  helmet: '| 重大災害罰則法防止ソリューション：建設現場の安全管理',
  desc: '重大災害罰則法の予防のために、スマートな安全な建設現場を構築し、作業者のリアルタイムの位置を利用して安全を管理できます。UWB RTLS技術を活用して建設現場を安全にしましょう。',
  keywords: '作業者の安全管理、建設現場の安全管理、建設現場、現場の安全、建設安全、スマート建設、重機管理、建設現場安全管理総合情報網、リアルタイム位置追跡、安全管理、rtls、uwb、orbro、kongtech',

  ogTitle: '',
  ogDesc: '',
  ogSite: '重大災害罰則法防止ソリューション：建設現場の安全管理 :: オーブロ ブログ',
  ogUrl: 'https://orbro.io/jp/blog/construction-solution',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxcFbqyCgsLqX77SjZSbmhPyHShDbN57Y0uVQssyTRIySb5QaO86GwRQU2XDIM6wENVOKG_sn4SuK6BIHu6D3_n9v4DTFhHsRRVUg7UmWsG9qrjE9rGDPe6P0_Ox2um950sgEHtj-KLjzqld-ZdzNkaE9QjgTMEVmj2tkQ1pPVRozYUD-xIl94PQaGNp7tQuO1Hob1FvMuSLLEcdXdUxZmR9CoCl4ArjWfuuwr1d6lvN2umbSY0_y7mc8jTFyCu9Dk5Wi_v3aDRSH29LhUBlpZyeEyM5oCecT8fbW04YC3xCmuCmG41FwPtKf2akaJlFuBsSXINWwYzKejipOUhx5BayplcpMLZKsJoGI_6BI4RTrNIGGFpS4LZqAmgN3JWX43wftmGOlcwJ2np2WFszY2X5QqQdeAdnTXKLzgiYRCcjrM0cHzvzsLebg0KbpyAgCiLawwttYXLmqYEUsw-7it4OWqarLHXZxq4K2hggeMrpWJVFc6R84ruA7y4WPP4lNxVTbcGz3h0tcwGbYv8mGEc5UjMsDalMc8fOmsS5_Dy9qrOewKS-A4oZsW0bBJ1IYnhhHgCvKL4VP19n_PzOnu51vLF_B2xWmnR6pT5F3wa7GapNjG0QEN3185QO4AI9hysQXwb4wLEZqT5dGeHzwRvfIfrp_NCJ6xUbTHt3W070iXbqCG-7liIoaYzwbWYUA_U21h4elbMhPdZqvaIJBQRWBRRKxo8RdjyguhL-3b3LSOd4ffx3PJuvI6T7d5Q89IwZccvi2VLTR2Z5XiTUAveb8J_U3zPTKmIliM2rNcFCQ2zUW61y3Je0voWlaxLRy-_eHoH-tLuixemebIJo770TZZk69mhoNcuzZf0HepRpMoZ_gK7WX5gZ9ybogR9JvNsEQ6do078xyLvTKuZQpri86vobe9PvXeTkVM-_h6agSAcUfUihK0GLiehcw4MjrNaXaq-eqEEEf_aWtfPJAjuJ7JXtFXOiuO6hBMA1-qPq6e_VOOcRJl3PigwW66TzDYgjowTnaD7M1dWDXFYFz_OJS0u8UQbmeKd1TT5h30l70YXeabd4BvtI33YLCL_fJ2ReOIzwUmzZZiEBplwIAxzDcNPvmVwfd5YzvxVGdtm3WEBbzZR0q-MdVvbABRxn90yZ7U2vcwgvNbSTJbC5aWQyI7Jd6LiooIa8nWVkVwaMK_vOmDwotOk2vnamSORh837qPfvlDmUuBX7RRN19heNUGbFF20032J3_JRYcT3AA8eYmTV8LKhQuF0eNQAUe40lPKPGrdrnDHBsjBdHgoOxOHd-ChckYyCFOL3a-FpQv4OO_OpXL6lWWv4SwJsdzXxWT0h2uqoGu0ONpkDv7O3WiZF4E3cQ46IlBsU_K4JWsh1R9pjjLBSRy61vgbvfD4a7yzwU-qvZ6sd5wm6HyVVOU4pmgYoH1SG86ZISmHsUXYxdrWo4vuJDA1If2gUg6P7Y7uMooHmA2MlFPgVsT3xqakmYR-xx3Z3gZnIbcFzO7LldLuSvs8Ha8nMoI9HM2dh6uxK5_9OTfmQRIGHTZngWgCBZrRIz4ARaIxBZiw=w2249-h1298',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '重大災害罰則法防止ソリューション：建設現場の安全管理',
  date: '2023-12-11',
  tag1: '#重大災害罰則法',
  tag2: '#労働安全',
  tag3: '#建設現場',
  tag4: '#RTLS',
    
  // <br/>
  text: [
    { 
      head: '',
      subHead: '',
      desc: '安全な建設現場を確立するためには、作業者の安全をリアルタイムで把握し、事故を予防し、発生時に迅速に対応することが不可欠です。特に、重大災害罰則法を考慮して、「リアルタイム位置追跡技術」を効果的に活用することが重要です。',
      dot: ''
    },
    {
      video: video,
      link: '/jp/enterprise/construction/safety',
      head: '1. リアルタイム位置追跡技術の主な機能',
      subHead: 'a. 正確な位置の特定',
      desc: 'RTLS技術はGPS、RFID、UWBなどを利用して建設現場の作業者の正確な位置をリアルタイムで追跡します。これにより安全な作業環境を構築し、危険なエリアを事前に把握します。',
      dot: ''
    },
    {
      head: '',
      subHead: 'b. 作業者の安全監視',
      desc: '位置データを基に作業者の動きをモニタリングし、作業中の安全状態をリアルタイムで確認します。危険な状況やエリアを迅速に検知し、適切な対策を取ることができます。',
      dot: ''
    },
    {
      head: '',
      subHead: 'c. 重機の監視',
      desc: '作業者だけでなく、重機や在庫の位置をリアルタイムで把握し、現場の状況を理解できます。これにより、作業者との衝突事故や危険なエリアへのアクセスなどを検知し、より安全に現場の状況を制御および管理できます。',
      dot: ''
    },
    {
      head: '',
      subHead: 'd. 発生時の迅速な対応',
      desc: 'RTLSシステムは事故発生時に迅速に該当位置を特定し、事故の内容を理解して迅速な対応を可能にします。緊急の状況で効果的な対応が可能です。',
      dot: ''
    },
    {
      head: '',
      subHead: 'e. データ収集と分析',
      desc: '位置データを収集し、分析して建設現場の作業パターンと危険なエリアを把握できます。これにより予防的な対応と安全ポリシーの改善に貢献します。',
      dot: ''
    },
    {
      head: '2. 利点',
      subHead: 'a. 安全性の強化',
      desc: 'RTLSは作業者の正確な位置特定を通じて安全性を強化します。事故発生時の迅速な対応により、重大災害罰則法の遵守と作業者の安全を保証します。',
      dot: ''
    },
    {
      head: '',
      subHead: 'b. 生産性の向上',
      desc: '正確な位置情報を活用して作業者の効率的な配置と業務計画を立て、建設プロセスの最適化と生産性の向上を図ります。',
      dot: ''
    },
    {
      head: '',
      subHead: 'c. コスト削減',
      desc: '事故予防と迅速な対応によって生じる損失を最小限に抑え、生産性向上によるコスト削減効果をもたらします。',
      dot: ''
    },
    {
      head: '',
      subHead: 'd. 効果的な資源管理',
      desc: 'RTLSを通じて建設現場内の資源を効果的に管理し、不要な資源の浪費を最小限に抑え、効率的な運用を可能にします。',
      dot: ''
    },
    {
      head: '',
      subHead: '',
      desc: '事故の最小化と作業環境の改善を目指すRTLS技術は、建設業界で非常に重要な役割を果たしています。これにより建設現場での安全性と効率性が大幅に向上するでしょう。<br/><br/>（上のビデオをクリックすると、対応するソリューションに移動します。）',
      dot: ''
    },

  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
        {
          text: productPurchase.purchase.worker.workerTracking.title,
          title: productPurchase.purchase.worker.workerTracking.title,
          serial: productPurchase.purchase.worker.workerTracking.serial,
          desc: 'この製品はUWB端末を作業者や機器に取り付け、リアルタイムの位置追跡を支援します。',
          img: productImg.product.workerTracking.key1
        },
      {

      }

    ],
    solution: [
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/jp/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      }, 
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/jp/enterprise/manufacturing/asset'
      }
    ]

  }


}
