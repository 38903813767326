
import {
  accesscontroller1, accesscontroller2, accesscontroller3, accesscontroller4, accesscontrollerOutlook, accesscontrollerThumb,
  accessAuto1, accessAuto2, accessAuto3, accessAuto4, accessAutoOutlook, accessAutoThumb,
  accessKey1, accessKey2, accessKey3, accessKey4, accessKeyOutlook, accessKeyThumb, accessKeyM1,
  accessPro1, accessPro2, accessPro3, accessPro4, accessProOutlook, accessProThumb,
  apart1, apart2, apart3, apart4, apartThumb,
  deadbolt1, deadbolt2, deadbolt3, deadbolt4, deadboltThumb, deadboltM1,
  emlock1, emlock2, emlock3, emlock4, emlockThumb, emlockM1

} from "@assets/images/main/access/product";
import { carTracking1, carTracking2, carTracking3, carTracking4, carTrackingKey1, carTrackingKey2, carTrackingKey3, carTrackingKey4, carTrackingKey5, ipCam1, ipCam2, ipCam3, ipCam4, ipCamKey1, ipCamKey2, ipCamKey3, ipThumb, peopleTracking1, peopleTracking2, peopleTracking3, peopleTracking4, peopleTrackingKey1, peopleTrackingKey2, peopleTrackingKey3, peopleTrackingKey4, ptzCam1, ptzCam2, ptzCam3, ptzCam4, ptzCamKey1, ptzCamKey2, ptzCamKey3, ptzThumb } from "@assets/images/main/camera";
import { bell1, bell2, bell3, bell4, bellOutlook, esl1, esl2, esl3, esl4, eslOutlook, mCradle1, mCradle2, mCradle3, mCradle4, mCradle5, mCradle6, mCradle7, mCradleOutlook, mCradleThumb, mTag1, mTag2, mTag2_1, mTag2_2, mTag2_3, mTag2_4, mTag2_Outlook, mTag2_Thumb, mTag2_key1, mTag2_key2, mTag2_key3, mTag3, mTag4, mTagOutlook, mTagThumb, switch1, switch2, switch3, switch4, switchOutlook } from "@assets/images/main/iot/product";
import { assetTag1, assetTag2, assetTag3, assetTagOutlook, assetTracking1, assetTracking2, assetTracking3, assetTracking4, serverPc, tagUwb1, tagUwb2, tagUwbOutlook, twinTracker1, twinTracker2, twinTracker3, twinTracker4, twinTracker5, twinTrackerOutlook, workerTracking1 } from "@assets/images/main/rtls/product";
import { eslThumbnail, safetyBellThumbnail, switchThumbnail, twinTrackerThumbnail } from "@assets/images/main/thumbnail";


export default {

  product: {


    accesscontroller: {
      thumbnail: accesscontrollerThumb,

      mini1: accesscontroller1,
      mini2: accesscontroller2,
      mini3: accesscontroller3,
      mini4: accesscontroller4,

      key1: accesscontroller1,
      key2: accesscontroller2,
      key3: accesscontroller3,
      outlook: accesscontrollerOutlook,

    },


    accessauto: {
      thumbnail: accessAutoThumb,

      mini1: accessAuto1,
      mini2: accessAuto2,
      mini3: accessAuto3,
      mini4: accessAuto4,

      key1: accessAuto1,
      key2: accessAuto3,
      key3: accessAuto2,

      outlook: accessAutoOutlook,


    },

    apart: {
      thumbnail: apartThumb,

      mini1: apart1,
      mini2: apart2,
      mini3: apart3,
      mini4: apart4,

      key1: apart2,
      key2: apart4,
      key3: apart3,
      key4: accessAuto1,

      outlook: accessAutoOutlook,

    },


    cloud: {
      thumbnail: accessAutoThumb,

      mini1: accessAuto1,
      mini2: accessAuto2,
      mini3: accessAuto3,
      mini4: accessAuto4,

      key1: accessAuto1,
      key2: accessAuto3,
      key3: accessAuto2,

      outlook: accessAutoOutlook,

    },



    accesspro: {
      thumbnail: accessProThumb,

      mini1: accessPro1,
      mini2: accessPro2,
      mini3: accessPro3,
      mini4: accessPro4,

      key1: accessPro1,
      key2: accessPro2,
      key3: accessPro4,

      outlook: accessProOutlook,


    },



    accesskey: {
      thumbnail: accessKeyThumb,

      mini1: accessKeyM1,
      mini2: accessKey2,
      mini3: accessKey3,
      mini4: accessKey4,

      key1: accessKeyM1,
      key2: accessKey2,
      key3: accessKey3,

      outlook: accessKeyOutlook,


    },



    emlock: {
      thumbnail: emlockThumb,

      mini1: emlockM1,
      mini2: emlock2,
      mini3: emlock3,
      mini4: emlock4,

      key1: emlockM1,
      key2: emlock2,
      key3: emlock3,

      outlook: "",


    },



    deadbolt: {
      thumbnail: deadboltThumb,

      mini1: deadboltM1,
      mini2: deadbolt2,
      mini3: deadbolt3,
      mini4: deadbolt4,

      key1: deadboltM1,
      key2: deadbolt2,
      key3: deadbolt3,

      outlook: "",


    },


    autoDoorSensor: {
      thumbnail: accessAutoThumb,

      mini1: accessAuto1,
      mini2: accessAuto2,
      mini3: accessAuto3,
      mini4: accessAuto4,

      key1: accessAuto1,
      key2: accessAuto3,
      key3: accessAuto2,

      outlook: accessAutoOutlook,


    },


    assetTracking: {
      design: [
        {
          title: "Twin Tracker",
          desc:
            "Track the wireless signals of asset tag devices with a simple PoE (Power over Ethernet) connection without complicated installation.",
          img1: twinTracker1,
          img2: twinTracker2,
          img3: twinTracker3,
        },
        {
          title: "Asset Tag(BLE)",
          desc: "Easily track the location of assets with a small and easy attachment.",
          img1: assetTag1,
          img2: assetTag2,
          img3: assetTag3,
        },
        {
          title: "Server PC",
          desc: "Stores and calculates the location of assets.",
          img1: serverPc,
          img2: "",
          img3: "",
        },
      ],
      out: [
        {
          title: "Twin Tracker",
          desc:
            "Track the wireless signals of asset tag devices with a simple PoE (Power over Ethernet) connection without complicated installation.",
          img: twinTrackerOutlook,
        },
        {
          title: "Asset Tag(BLE)",
          desc: "Easily track the location of workers with a simple attachment.",
          img: assetTagOutlook,
        },
      ],
      thumbnail: assetTracking1,
      mini1: assetTracking1,
      mini2: assetTracking2,
      mini3: assetTracking3,
      mini4: assetTracking4,
      key1: assetTracking1,
      key2: assetTracking2,
      key3: assetTracking3,
      outlook: accessAutoOutlook,
      title: "Twin Tracker",
      desc: "Track the wireless signals of asset tag devices with a simple PoE (Power over Ethernet) connection.",
    },


    workerTracking: {
      design: [
        {
          title: "Twin Tracker Pro",
          desc:
            "Track the wireless signals of asset tag devices with a simple PoE (Power over Ethernet) connection without complicated installation.",
          img1: twinTracker1,
          img2: twinTracker2,
          img3: twinTracker3,
        },
        {
          title: "Tag(UWB)",
          desc: "Easily track the location of workers with a simple attachment.",
          img1: "",
          img2: tagUwb1,
          img3: tagUwb2,
        },
        {
          title: "Server PC",
          desc: "Stores and calculates the location of assets.",
          img1: serverPc,
          img2: "",
          img3: "",
        },
      ],
      out: [
        {
          title: "Twin Tracker Pro",
          desc:
            "Track the wireless signals of asset tag devices with a simple PoE (Power over Ethernet) connection without complicated installation.",
          img: twinTrackerOutlook,
        },
        {
          title: "Tag(UWB)",
          desc: "Easily track the location of workers with a simple attachment.",
          img: tagUwbOutlook,
        },
      ],
      thumbnail: workerTracking1,
      mini1: workerTracking1,
      mini2: tagUwb1,
      mini3: twinTracker1,
      mini4: assetTracking4,
      key1: workerTracking1,
      key2: tagUwb1,
      key3: twinTracker1,
      outlook: "",
      title: "Twin Tracker Pro",
      desc:
        "Track the wireless signals of asset tag devices with a simple PoE (Power over Ethernet) connection.",
    },



    carTracking: {


      design: [
        {
          title: 'PTZ Camera',
          desc: 'Track the location of vehicles in the parking lot using PTZ outdoor cameras.',

          img1: carTrackingKey1,
          img2: carTrackingKey2,
          img3: carTrackingKey3,

        },

        {
          title: 'NVR',
          desc: 'Store and transmit camera image information.',

          img1: carTrackingKey4,
          img2: '',
          img3: '',

        },

        {
          title: 'Server PC',
          desc: 'Analyze images and calculate positions.',

          img1: carTrackingKey5,
          img2: '',
          img3: '',

        },

      ],

      out: [],


      thumbnail: carTracking1,

      //제품 아래 4가지 이미지
      mini1: carTracking1,
      mini2: carTracking2,
      mini3: carTracking3,
      mini4: carTracking4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },

    peopleTracking: {


      design: [
        {
          title: 'IP Camera',
          desc: 'Track indoor pedestrian traffic using IP cameras.',

          img1: peopleTrackingKey1,
          img2: peopleTrackingKey2,
          img3: '',

        },

        {
          title: 'NVR',
          desc: 'Store and transmit camera image information.',

          img1: peopleTrackingKey3,
          img2: '',
          img3: '',

        },

        {
          title: 'Server PC',
          desc: 'Analyze images and calculate positions.',

          img1: peopleTrackingKey4,
          img2: '',
          img3: '',

        },

      ],

      out: [],


      thumbnail: peopleTracking1,

      //제품 아래 4가지 이미지
      mini1: peopleTracking1,
      mini2: peopleTracking2,
      mini3: peopleTracking3,
      mini4: peopleTracking4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },

    ptz: {


      design: [
        {
          title: 'PTZ Camera',
          desc: 'The PTZ outdoor camera tracks the location of a person or vehicle.',

          img1: ptzCamKey1,
          img2: ptzCamKey2,
          img3: ptzCamKey3,

        },

      ],

      out: [],


      thumbnail: ptzThumb,

      //제품 아래 4가지 이미지
      mini1: ptzCam1,
      mini2: ptzCam2,
      mini3: ptzCam3,
      mini4: ptzCam4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },

    ip: {


      design: [
        {
          title: 'IP Camera',
          desc: 'Track indoor floating population with IP camera.',

          img1: ipCamKey1,
          img2: ipCamKey2,
          img3: ipCamKey3,

        },

      ],

      out: [],


      thumbnail: ipThumb,

      //제품 아래 4가지 이미지
      mini1: ipCam1,
      mini2: ipCam2,
      mini3: ipCam3,
      mini4: ipCam4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },




    safetyBell: {
      thumbnail: safetyBellThumbnail,

      mini1: bell1,
      mini2: bell2,
      mini3: bell3,
      mini4: bell4,

      key1: bell1,
      key2: bell2,
      key3: bell3,

      outlook: bellOutlook,


    },



    smartSwitch: {
      thumbnail: switchThumbnail,

      mini1: switch1,
      mini2: switch2,
      mini3: switch3,
      mini4: switch4,

      key1: switch1,
      key2: switch2,
      key3: switch3,

      outlook: switchOutlook,


    },


    esl: {
      thumbnail: eslThumbnail,

      mini1: esl1,
      mini2: esl2,
      mini3: esl3,
      mini4: esl4,

      key1: esl1,
      key2: esl2,
      key3: esl3,

      outlook: eslOutlook,


    },

    mTag: {
      thumbnail: mTagThumb,

      mini1: mTag1,
      mini2: mTag2,
      mini3: mTag3,
      mini4: mTag4,

      key1: mTag1,
      key2: mTag2,
      key3: mTag3,

      outlook: mTagOutlook,


    },

    mCradle: {
      thumbnail: mCradleThumb,

      mini1: mCradle1,
      mini2: mCradle2,
      mini3: mCradle3,
      mini4: mCradle4,

      key1: mCradle5,
      key2: mCradle6,
      key3: mCradle7,

      outlook: mCradleOutlook,


    },

    robotBasic: {

      design: [
        {
          title: 'Delivery Robot',
          desc: 'It plays the role of delivering goods through integration with the OBRO system.',

          img1: carTrackingKey1,
          img2: carTrackingKey2,
          img3: carTrackingKey3,

        },

        {
          title: 'Twin Tracker Pro',
          desc: 'It tracks the wireless signals of tag devices with just a PoE (Power over Ethernet) connection, without a complicated installation process.',

          img1: carTrackingKey4,
          img2: '',
          img3: '',

        },

        {
          title: 'Tag (UWB)',
          desc: 'It can track the location of workers with easy attachment.',

          img1: carTrackingKey5,
          img2: '',
          img3: '',

        },

        {
          title: 'Server PC',
          desc: 'It plays the role of storing and calculating the location of assets.',
          img1: serverPc,
          img2: '',
          img3: '',

        },

      ],

      out: [],

      thumbnail: carTracking1,

      // Four images below the product
      mini1: carTracking1,
      mini2: carTracking2,
      mini3: carTracking3,
      mini4: carTracking4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },

    delivery: {

      design: [
        {
          title: 'Delivery Robot',
          desc: 'It plays the role of delivering goods through integration with the OBRO system.',

          img1: carTrackingKey1,
          img2: carTrackingKey2,

        },

        {
          title: 'Loading Kiosk',
          desc: 'It automatically loads the goods when the robot is called after placing the goods to be delivered on the device.',

          img1: carTrackingKey4,
          img2: '',
          img3: '',

        },

        {
          title: 'Cobot',
          desc: 'It plays the role of unloading goods from the delivery robot upon its arrival.',

          img1: carTrackingKey5,
          img2: '',
          img3: '',

        },

        {
          title: 'Twin Tracker Pro',
          desc: 'It tracks the wireless signals of tag devices with just a PoE (Power over Ethernet) connection, without a complicated installation process.',

          img1: carTrackingKey4,
          img2: '',
          img3: '',

        },

        {
          title: 'Tag (UWB)',
          desc: 'It can track the location of workers with easy attachment.',

          img1: carTrackingKey5,
          img2: '',
          img3: '',

        },

        {
          title: 'Server PC',
          desc: 'It plays the role of storing and calculating the location of assets.',
          img1: serverPc,
          img2: '',
          img3: '',

        },

      ],

      out: [],

      thumbnail: carTracking1,

      // Four images below the product
      mini1: carTracking1,
      mini2: carTracking2,
      mini3: carTracking3,
      mini4: carTracking4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },

    disinfection: {

      design: [
        {
          title: 'Delivery Robot',
          desc: 'It plays the role of delivering goods through integration with the OBRO system.',

          img1: carTrackingKey1,
          img2: carTrackingKey2,

        },

        {
          title: 'Loading Kiosk',
          desc: 'It automatically loads the goods when the robot is called after placing the goods to be delivered on the device.',

          img1: carTrackingKey4,
          img2: '',
          img3: '',

        },

        {
          title: 'Cobot',
          desc: 'It plays the role of unloading goods from the delivery robot upon its arrival.',

          img1: carTrackingKey5,
          img2: '',
          img3: '',

        },

        {
          title: 'Disinfection Device Kit',
          desc: 'It is a product that safely performs the disinfection role in the loading and unloading process of the delivery robot.',
          img1: serverPc,
          img2: '',
          img3: '',

        },
        {
          title: 'Twin Tracker Pro',
          desc: 'It tracks the wireless signals of tag devices with just a PoE (Power over Ethernet) connection, without a complicated installation process.',

          img1: carTrackingKey4,
          img2: '',
          img3: '',

        },

        {
          title: 'Tag (UWB)',
          desc: 'It can track the location of workers with easy attachment.',

          img1: carTrackingKey5,
          img2: '',
          img3: '',

        },

        {
          title: 'Server PC',
          desc: 'It plays the role of storing and calculating the location of assets.',
          img1: serverPc,
          img2: '',
          img3: '',

        },

      ],

      out: [],

      thumbnail: carTracking1,

      // Four images below the product
      mini1: carTracking1,
      mini2: carTracking2,
      mini3: carTracking3,
      mini4: carTracking4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },







  },



}


