import { TechUseCaseIcon3D, TechUseCaseIconBuilding } from "@assets/images/technology/common";
import { IconBattery, IconCar, IconChartInv, IconHeart, IconHome, IconRulerInv, IconTagInv } from "@assets/images/icon";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

export default {

    seo: {
        title: '数字孪生是什么？',
        desc: '数字孪生是实时收集物理对象数据并将其反映在虚拟模型中，以实现现实世界状态相同。收集到的这些数据将在基于云的平台上存储、分析，并生成各种信息。',
        keywords: '数字孪生，数字转型，智能建筑，虚拟现实，实时定位系统，位置追踪，信标，orbro，奥布罗，康泰克',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: 'https://orbro.io/cn/enterprise/technology/what-is-digital-twin',
        type: 'website',
    },

    tag: '什么是数字孪生？',
    title: '数字孪生是什么？',
    desc: '',


    btnText : 'ORBRO数字孪生是什么',
    btnLink : '/digital-twin',

    text: [
        {
            head: '什么是数字孪生（Digital Twin）？',
            desc: '数字孪生是实时收集物理对象数据并将其反映在虚拟模型中，以实现现实世界状态相同。收集到的这些数据将在基于云的平台上存储、分析，并生成各种信息。这些信息在物理对象的状态、性能、维护预测等各个方面都创造价值，并支持决策。<br/><br/>例如，在制造业中，数字孪生可以准确监测生产过程的状态，并通过预测分析优化设备的维护计划。这有助于缩短生产时间并提高产品质量。<br/>换句话说，数字孪生实时监控实际系统的运行状态，快速检测物理对象的状态变化或异常迹象，从而提高系统的稳定性并预防问题。此外，数字孪生通过模拟为实验提供了各种场景，寻找最佳运行方式的机会。通过这种方式，决策制定，如引入新技术或改进流程等，变得更加准确和有效。<br/><br/>数字孪生还应用于生命周期管理、维护预测、性能优化、产品开发等多个领域，企业通过这些应用实现成本节约和生产效率提升。这些优势是数字孪生崛起为现代工业核心技术的重要原因之一。',
            subHead: '',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/en/enterprise/manufacturing/process',
            head: '数字孪生的优势',
            desc: '',
            subHead: [
                {
                    title: '实时监控和分析',
                    desc: '数字孪生可以实时收集和分析物理对象的数据，准确监控运行状态。通过快速检测问题并采取措施，提高生产效率并提高系统安全性。'
                },
                {
                    title: '模拟和优化',
                    desc: '使用数字孪生，可以利用虚拟模型模拟各种场景并找到最佳运行方式。这为节约成本和最大化效率提供了机会。'
                },
                {
                    title: '维护和生命周期管理',
                    desc: '准确了解物理对象的状态有助于优化维护计划并有效管理设备的寿命。这有助于降低成本并提高系统的可靠性。'
                },
            ],
            dot: ''
        },
        {
            head: '数字孪生类型',
            desc: '数字孪生可以根据行业领域、应用领域、物理对象特性等进行多样化分类。在众多类型中，以下是一些关键数字孪生类型：',
            subHead: [
                {
                    title: '产品孪生(Product Twin)',
                    desc: '产品孪生利用产品生命周期中产生的数据对产品的状态和性能进行数字建模。在制造业中，可以使用产品孪生跟踪和优化产品的设计、制造、运营、维护等所有阶段的信息。'
                },
                {
                    title: '过程孪生(Process Twin)',
                    desc: '过程孪生是对复杂的制造过程或设施运营等进行数字化。它用于监测和改进生产过程的优化、提高能源效率、确保过程安全性等。'
                },
                {
                    title: '设施孪生(Facility Twin)',
                    desc: '设施孪生通过数字化表示建筑或设施的物理特性。实时监控能源消耗、维护计划、环境因素等，以优化建筑的效率。'
                },
                {
                    title: '城市孪生(City Twin)',
                    desc: '城市孪生将城市的基础设施、交通、环境等数字建模。在智能城市概念中，城市孪生可用于优化交通流、提高能源效率、增强公共安全等多个城市管理方面。'
                },
                {
                    title: '生物力学孪生(Biomechanical Twin)',
                    desc: '在医疗领域，通过收集患者的生理信息创建生物力学孪生，数字化患者的健康状况。这使医疗人员能够实时监测患者状态并用于诊断。'
                },
                {
                    title: '服务孪生(Twin of a Service)',
                    desc: '数字孪生不仅应用于产品和过程，还出现了服务的数字孪生。通过数字化服务的运营和性能，实现有效管理和改进。'
                },
            ],
            dot: ''
        },
        {
            videoDesc: '(制造设施数字孪生，点击导航)',
            tag: 'noBottomLine',
            head: '数字孪生技术运作方式',
            desc: '数字孪生通过数字化物理对象精确反映现实世界的虚拟模型。数字孪生的运作方式可以总结为以下几个步骤：',
            subHead: [
                {
                    title: '1. 传感器和数据收集',
                    desc: '数字孪生的运作从收集物理对象产生的各种数据开始。为此，使用传感器、物联网（IoT）设备、摄像机等。这些传感器收集有关物理对象状态、位置、性能等的信息。'
                },
                {
                    title: '2. 数据传输和存储',
                    desc: '收集到的数据通过云平台或中央数据库传输并存储。这些数据可能是大量的且具有多种格式，可以实时传输或定期更新。'
                },
                {
                    title: '3. 处理和建模',
                    desc: '存储的数据经过处理，反映在虚拟模型中。在这个过程中，可以利用机器学习算法或各种分析技术。虚拟模型准确模拟物理对象的特性，并以数字形式表示其状态。'
                },
                {
                    title: '4. 实时更新',
                    desc: '传感器数据的实时更新使数字孪生能够准确反映物理对象的当前状态，迅速应对现实世界的变化。'
                },
                {
                    title: '5. 模拟和优化',
                    desc: '数字孪生可以通过模拟实验各种场景并找到最佳运行方式。例如，通过预测特定条件下的性能或在变化的环境中进行模拟，以帮助决策。'
                },
                {
                    title: '6. 接口和可视化',
                    desc: '数字孪生的结果通过用户界面可视化，使用户能够轻松理解和监控。这直观地提供了有关实际物理对象状态、性能、预测等信息。通过这种方式，数字孪生将物理对象准确地转移到数字屏幕上，以实现对现实世界的准确反映，并通过提高生产力促进问题的解决。',
                },
            ],
            dot: ''
        },
        {
            tag: 'card',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
        {
            head: '构建数字孪生的方法',
            desc: '构建数字孪生的过程可能因行业领域和应用领域而异，但通常包括以下步骤：',
            subHead: '',
            dot: [
                {
                    title: '1. 确定目标和范围：',
                    desc: [
                        {
                            text: ' • 决定将哪些物理对象建模为数字孪生。',
                        },
                        {
                            text: ' • 目标可以侧重于性能改进、简化维护、模拟和优化等。',
                        },
                    ]
                },
                {
                    title: '2. 制定传感器和数据收集计划：',
                    desc: [
                        {
                            text: ' • 确定所需的传感器和数据收集方法。',
                        },
                        {
                            text: ' • 考虑数据收集周期、格式、安全性和隐私等因素，制定计划。',
                        },
                    ]
                },
                {
                    title: '3. 构建数据基础设施：',
                    desc: [
                        {
                            text: ' • 建立用于存储和处理收集到的数据的数据基础设施。',
                        },
                        {
                            text: ' • 选择并实施基于云的平台或本地解决方案。',
                        }
                    ]
                },
                {
                    title: '4. 虚拟建模和模拟：',
                    desc: [
                        {
                            text: ' • 使用收集到的数据创建物理对象的虚拟模型。',
                        },
                        {
                            text: ' • 这个模型准确地反映了物理对象的特性，并通过模拟实验各种场景。',
                        }
                    ]
                },
                {
                    title: '5. 数据分析和模型学习：',
                    desc: [
                        {
                            text: ' • 分析收集到的数据，利用机器学习技术改进虚拟模型。',
                        },
                        {
                            text: ' • 实施预测模型或解决问题的算法进行学习。',
                        },
                    ]
                },
                {
                    title: '6. 实时监控和更新：',
                    desc: [
                        {
                            text: ' • 收集传感器实时数据，实时更新数字孪生以准确反映现实世界的变化。'
                        },
                        {
                            text: ' '
                        },
                    ]
                },
                {
                    title: '7. 加强安全性和隐私保护：',
                    desc: [
                        {
                            text: ' • 为保护敏感数据实施适当的安全机制。',
                        },
                        {
                            text: ' • 遵守与个人信息保护相关的法规和规定。',
                        }
                    ]
                },
                {
                    title: '8. 用户界面和可视化：',
                    desc: [
                        {
                            text: ' • 开发用户可以轻松理解和监控的数字孪生结果的用户界面。',
                        },
                        {
                            text: ' • 通过可视化工具直观查看虚拟模型的状态和性能。',
                        }
                    ]
                },
                {
                    title: '9. 持续改进和优化：',
                    desc: [
                        {
                            text: ' • 持续监控性能和效果，根据需要改进和优化数字孪生。',
                        },
                        {
                            text: ' • 通过引入新的数据源或技术升级系统。',
                        }
                    ]
                }
            ]
        },
        {
            tag: 'advantage',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
    ],

    card: {
        title: '数字孪生应用案例',
        desc: '数字孪生在各种行业领域中得到广泛应用。以下是一些代表性的数字孪生应用案例：',
        cards: [
            {
                icon: TechUseCaseIcon3D,
                title: '制造业什么是数字孪生',
                desc: [
                    {
                        title: '设备监控和优化',
                        desc: '通过将生产线和设备建模成数字孪生，实时监控其状态，并通过预测分析优化维护计划。',
                    },
                    {
                        title: '产品开发和模拟',
                        desc: '创建产品的数字孪生，从设计阶段到生产阶段进行模拟，并找到最佳设计。',
                    }
                ]
            },
            {
                icon: IconHome,
                title: '建筑与房地产什么是数字孪生',
                desc: [
                    {
                        title: '建筑物监控',
                        desc: '实时监控建筑物或基础设施结构的健康状况，优化结构的维护计划并提高安全性。',
                    },
                    {
                        title: '城市规划和模拟',
                        desc: '通过数字孪生对城市的交通、能源使用、环境影响等进行模拟，制定高效的城市规划。',
                    }
                ]
            },
            {
                icon: IconBattery,
                title: '能源管理什么是数字孪生',
                desc: [
                    {
                        title: '智能电网运营',
                        desc: '将电力供应网络建模为数字孪生，优化能源流动，并通过故障检测和预防来高效运营电力系统。',
                    },
                    {
                        title: '',
                        desc: '',
                    }
                ]
            },
            {
                icon: IconHeart,
                title: '医疗保健什么是数字孪生',
                desc: [
                    {
                        title: '患者监测和诊断',
                        desc: '通过数字孪生表现患者的健康状况，使医疗人员能够实时监测并在诊断中提供帮助。',
                    },
                    {
                        title: '医疗设备开发',
                        desc: '在医疗设备开发阶段使用数字孪生进行模拟，提高设备性能。',
                    }
                ]
            },
            {
                icon: IconCar,
                title: '汽车与交通什么是数字孪生',
                desc: [
                    {
                        title: '汽车设计和测试',
                        desc: '在汽车开发阶段使用数字孪生，提升汽车性能，并通过模拟验证安全性。',
                    },
                    {
                        title: '物流与交通优化',
                        desc: '将物流网络建模为数字孪生，优化物流和交通过程，降低成本。',
                    }
                ]
            },
            {
                icon: TechUseCaseIconBuilding,
                title: '智能建筑什么是数字孪生',
                desc: [
                    {
                        title: '智能计量系统',
                        desc: '将电力、气体、水等公用事业网络建模为数字孪生，实时监控并向消费者提供能源使用信息。',
                    },
                    {
                        title: '环境监控',
                        desc: '通过数字孪生表现环境的状态，持续监测大气质量、水质、气候等环境因素，用于环境管理决策。',
                    }
                ]
            },
        ]
    },

    advantage: {
        title: '数字孪生主要特点',
        cards: [
            {
                icon: IconRulerInv,
                title: '实时监控和响应能力',
                desc: '数字孪生能够实时监控物理对象的状态，使在问题发生或可预测时能够迅速做出响应。这在生产线、设备、建筑等各种对象中发挥着重要作用。通过快速响应，可以提高系统的安全性并最小化潜在问题。',
            },
            {
                icon: IconTagInv,
                title: '模拟和优化功能',
                desc: '数字孪生利用虚拟模型进行各种场景的模拟，并找到最佳运行方式。通过这种方式，企业可以在生产流程、产品设计、能源使用等多个方面最大化效益。此外，通过在变化的环境中进行模拟，可以在实际决策之前降低风险。',
            },
            {
                icon: IconChartInv,
                title: '降低维护成本和生命周期管理',
                desc: '利用数字孪生，可以精确监测物理对象的状态，并通过预测分析优化维护计划。避免不必要的维护工作，预测机器或设备的故障，并有效地进行修理或更换。这有助于降低总体维护成本并延长资产的使用寿命。',
            },
        ]
    }


}