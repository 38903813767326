

export default {
  constructionSafety: {
    tag: 'Solution',
    title: 'Construction Worker Safety Management',
    desc: 'Worker safety is paramount above all else. Failure to ensure worker safety in high-risk environments can lead to not only productivity issues but also legal liabilities.',
    link: '/en/enterprise/construction/safety',
  },
  constructionAsset: {
    tag: 'Solution',
    title: 'Construction Asset Tracking and Management',
    desc: 'Accurate material management ultimately enhances productivity. Effective management of resources, waste reduction, and maintaining quality are crucial aspects of construction site material management.',
    link: '/en/enterprise/construction/asset',
  },
  constructionEquipment: {
    tag: 'Solution',
    title: 'Heavy Equipment Management',
    desc: 'Heavy equipment is a key factor in construction sites. Proper maintenance and accident prevention are essential. With proper equipment management, safety can be maintained, costs can be reduced, and site productivity can significantly increase.',
    link: '/en/enterprise/construction/equipment',
  },
  logisticsAsset: {
    tag: 'Solution',
    title: 'Asset Tracking and Management',
    desc: 'Asset management is a critical part of supporting supply chain efficiency and smooth operations. Real-time tracking of asset locations enables effective monitoring of asset levels and optimization of asset movement.',
    link: '/en/enterprise/logistics/asset',
  },
  logisticsSafety: {
    tag: 'Solution',
    title: 'Warehouse Worker Safety Management',
    desc: `Worker safety is paramount above all else. Failure to ensure worker safety can lead to productivity issues and legal liabilities. Additionally, ensuring a safe logistics site plays a significant role in forming a company's image and gaining stakeholders' trust.`,
    link: '/en/enterprise/logistics/safety',
  },
  officeAsset: {
    tag: 'Solution',
    title: 'Asset Management',
    desc: 'Effective asset management is crucial to avoid time and financial losses. Clear and fast asset management enables smooth business operations without interruptions.',
    link: '/en/enterprise/office/asset',
  },
  manufacturingProcess: {
    tag: 'Solution',
    title: 'Real-time Process Management',
    desc: 'Knowing how manufacturing facilities are progressing in real-time enables effective management. Improving production lines and increasing efficiency can be achieved through accurate and objective data. This is a key aspect of the manufacturing industry.',
    link: '/en/enterprise/manufacturing/process',
  },
  manufacturingAsset: {
    tag: 'Solution',
    title: 'Real-time Asset Management',
    desc: 'Manufacturing facilities hold numerous inventories, such as raw materials and semi-finished products. Properly managing these inventories is crucial in terms of time and economic factors. It significantly influences the efficiency of the manufacturing industry.',
    link: '/en/enterprise/manufacturing/asset',
  },
  medicalPatient: {
    tag: 'Solution',
    title: 'Patient Tracking',
    desc: 'Medical facilities house patients who require special management. Real-time tracking of patient locations ensures accurate and fast tracking without the need for constant surveillance.',
    link: '/en/enterprise/medical/patient',
  },
  medicalWaste: {
    tag: 'Solution',
    title: 'Medical Waste Management',
    desc: 'Tracking the real-time status and location of medical waste enables accident prevention and ensures safe medical services. It is an essential task that contributes to human health and resource conservation by complying with legal regulations.',
    link: '/en/enterprise/medical/waste',
  },
  retailExhibition: {
    tag: 'Solution',
    title: 'Exhibition Hall Visitor Management and Analysis',
    desc: 'By analyzing the movement of visitors to the exhibition hall and easily managing assets, efficient facility operation becomes possible. Accurate and objective data can drive new business growth.',
    link: '/en/enterprise/retail/exhibition'
  },


  blogRTLS: {
    tag: 'Blog',
    title: 'RTLS in Construction Sites',
    desc: 'Let\'s explore how to locate and navigate within building interiors using RTLS systems. Many construction companies have begun implementing real-time asset tracking systems to locate assets and move objects. This optimization of construction activities enhances the efficiency of construction workers.',
    link: '/blog/rtls',
  },
  blogActiveRfid: {
    tag: 'Blog',
    title: 'Real-Time Location Systems Based on Active RFID Technology',
    desc: 'Active RFID is a wireless communication method that transmits information between devices. Active RFID tags have their power source, allowing them to transmit signals over long distances. They are ideal for real-time tracking of assets.',
    link: '/blog/activerfid',
  },
  blogForklift: {
    tag: 'Blog',
    title: 'RTLS Solutions for Improving Forklift Operator Safety',
    desc: 'Forklifts should be inspected daily before use. The checklist includes checking parking brakes, lights, hydraulic oil levels, and more. Any issues discovered should be promptly repaired. It is recommended to have a qualified technician perform a more thorough inspection at least once a month.',
    link: '/blog/forklift',
  },
  
  blogOffice: {
    tag: 'Blog',
    title: 'Building a Smart Office in 2023: How to Do It',
    desc: 'Indoor location tracking in office buildings can pose many challenges. As the number of offices, halls, and floors increases, visitors can easily get lost and waste time finding their way around the building. In such situations, companies need to use smarter maps. The latest technology ensures convenient navigation within the building, improving customer and visitor satisfaction.',
    link: '/blog/office',
  },
  
  blogFactory: {
    tag: 'Blog',
    title: 'Welcome to the Future of Factories: The Role of Real-Time Tracking in Industry 4.0',
    desc: 'Factories will undergo innovative changes in the near future. This is an imminent reality. Advanced technologies such as the Internet of Things (IoT), artificial intelligence, and big data serve as the foundation. Real-time location systems (RTLS) are also a key element driving innovation.',
    link: '/blog/factory',
  },
  blogMedical: {
    tag: 'Blog',
    title: 'RTLS in the Medical Industry',
    desc: 'The advancement of IT technology in the healthcare industry has become even more crucial, especially in the wake of the COVID-19 pandemic. Tracking and managing patients is at the core of this technological evolution. Real-Time Location Systems (RTLS), as it is called, will play a diverse role across various aspects of the healthcare industry in the future, providing significantly improved levels of service compared to the present.',
    link: '/blog/access'
  },

};
