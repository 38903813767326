import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { StyledHeading, StyledLabel, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";



export const StyledLineupPage = styled.div`
  width : 100%;
  
  display : flex; 
  gap : 32px;

  margin-bottom : 96px;

  & * {
    font-family: Pretendard !important;
    font-display: swap;
  } 


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    flex-direction : column;
    gap : 16px;
  
  }


`




export const StyledProductBox = styled.div`
  padding : 32px 24px;
   

  
  position: relative;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content : center; 
  text-align : center;
  
  flex : 1;
  

  object-fit :fit;


  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;


`


export const StyledProductImg = styled.img` 
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  height : 280px;

`

export const StyledTextHead = styled(StyledHeading)`
  text-align : center;

`

export const StyledTextPara = styled(StyledParagraph)`
  color : #646464;
  margin-top : 8px;
  text-align : center;
`

export const StyledTextLabel = styled(StyledLabel)`
  text-align : center;
`



export const StyledSpacing32 = styled.div`
  height : 32px;
  background-color : red;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height : 16px;
  
  }
`


export const StyledBtn = styled.a`

  margin : auto;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 4px;

  width: auto;

  cursor : pointer;

  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;


`



export const StyledProducImgBox = styled.div`
  position: relative;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content : center;
  height : 240px;
  
  width : 100px;
  flex : 1;

`

export const StyledProductImage = styled.img`

max-height: 100%;
max-width: 100%;
object-fit: contain;
`;

export const StyledProductButton = styled.button`

  border : none;
  cursor : pointer;


  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin : auto;
  gap: 4px;
  align-self: flex-start;

  background: #2A70F0;
  border-radius: 8px;
  height : 40px;
  max-width : 300px;

  color : #FFFFFF;
  font-family: 'Pretendard';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  
  text-align: center;

`
