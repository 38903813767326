import React from "react";
import { useTranslation } from "react-i18next";
import TitleBanner from "../Common/TitleBanner/TitleBanner";
import { StyledTechnologyContent } from "../Technology.style";
import TechRequest from "../Common/TechRequest/TechRequest";
import StereoVisionContent from "./components/SVContent/StereoVisionContent";
import UseCase from "./UseCase/UseCase";
import i18n from "@pages/i18n";
import SEO from "@pages/SEO";
import CommonLang from "@pages/CommonStyle/commonLang";
import { FontPage } from "@pages/CommonStyle/commonDiv.style";
import { SpacingDTM } from "@pages/CommonStyle/common.style";



const StereoVision = () => {
  // Translation
  const { t } = useTranslation();
  CommonLang();

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "headline": "Stereo Vision을 이용한 위치 추적 및 포지셔닝 기술",
    "image": [

    ],
    "datePublished": "2023-04-01T08:00:00+08:00",
    "dateModified": "2023-04-01T08:00:00+08:00",
    "author": [{
      "@type": "Organization",
      "name": "ORBRO",
      "url": "https://orbro.io"
    }]
  }

  const helmetTitle = t(`seo.tech.stereo.title`);
  const key = t(`seo.tech.stereo.key`);
  const desc = t(`seo.tech.stereo.desc`);
  const currentLang = i18n.language;
  let isKorean;
  if (currentLang === 'ko') {
    isKorean = true;
  } else {
    isKorean = false;
  }


  return (
    <>

      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={key}
        ogDesc={desc}
        ogImg="https://orbro.io/images/53a25e5cd7109e62a40567b90f4b7cc6.png"
        ogUrl={isKorean ? "https://orbro.io/enterprise/technology/stereo-vision" : `https://orbro.io/${currentLang}/enterprise/technology/stereo-vision`}
        jsonLd={jsonLd}
      />

      <SpacingDTM d={"64"} t={"48"} m={"32"} />
      <FontPage>
        <TitleBanner
          subtitle={"stereo.titleBanner.subtitle"}
          title={"stereo.titleBanner.title"}
          description={"stereo.titleBanner.description"}
        />

        <StyledTechnologyContent>
          <StereoVisionContent />
        </StyledTechnologyContent>

        <UseCase type="stereo" />
        <TechRequest />
      </FontPage>
    </>

  );
};

export default StereoVision;