import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet: '| スマートロジスティクスセンター：RTLSを活用したゲームチェンジャーになる',
  desc: `スマートロジスティクスセンターを構築するには、RTLS技術が必要です。 RTLS技術が物流業界でゲームチェンジャーとしてもたらす機能を探ってみましょう。`,
  keywords: '大規模物流倉庫管理、ロジスティクスセンター管理、ロジスティクス倉庫管理、スマートロジスティクス、在庫管理、スマート在庫管理、ロジスティクス倉庫在庫管理、大規模物流倉庫在庫管理、ORBRO、リアルタイム位置追跡、rtls、uwb、KongTech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'スマートロジスティクスセンター：RTLSを活用したゲームチェンジャー :: ORBRO ブログ',
  ogUrl: 'https://orbro.io/jp/blog/logistics',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4Nn92GBAhAnqBNHPYNQDpB-QpINtYBr8NP1whgOrOeiJ231ayd-ir1pFXYQyPNn6ORiGuT9r9NH8WMwpUVVIJuDIDCcxjED8rHcdEkrjVEabQroYsK8zKZBFb5y7MqX52j_Hsy7L5ePRwrLj1l5AgzzhwwwRDy0SSWbba5oN-o3v4VK9ulJDIn-AlRzUvK-2_ExWeqxWtqjdDVGF780Q1Fyw9IIdObKFPnjkNtFBBQtMXHmKC4K3NY-PU75is1fvZGtz95FAVwQW8NW-iuI9-V6GgxYKoSSaE0VpltJTXpVeUwTA-55-8pGVLMV1jPeO1vGqDiJJsSQfFQE7xxXm6-UZvVDalr763kudRcjaH6dWNitPT2w4_qxEV3EivvyxvKRAKilLymJIzk-KgoGkU4hhuVrqpelXsjpiyAVrTRehdny-SFdJeuDk7TnnLXSFTf8rG1zgj4OOJcXrXqDmjNcKnSmD3gyrnY_7gEkAde2Vn2Wnm9hQewDJ1YDCx926J3unTLJApvy7uGKDbgGwZY0jfHbj3_EaB-5t5TBucsd2CukDZvZ27jfvLUUe1Wrr0-U21oag_JP6AeJNO-Thzp8fvPd1rmpOiKsPwlCOEdTn-aVu9hoAWByLe-OAQ_weFNJB2jqIOrRDEiEdsF2Ux_D4H4UY0gjcEi6EuIl2SaJ7GQEfGt0uQ1BSYXOm6_Ljse4BNu1hSdj9Iqtg7MstD1cwgxIVmXk20C1GudLlOL14w-EOHYMhOg-CdEf_6i7jOS4F-HZVe98fBNM8s4m5pjIUFngbQ9Ig158BQ2ZwJB2t05BgqvZDYMA33z6z0iOiRAnmT6J9sYM2yx4itS08KP1CgjFduRqis5fJcg9eFZuZJf5Hfd-vWgWD6fl5ssh6du1AEaEBLfbbmiNhDihCB-M0jHlLuDCL-wWrFKTCo3ylcA89Xivl7Hca0rY3s6JW_VfSwboGpYKJnMKpZqynEuHGMXoYXQ3jFpIQPj2xOElK8OLo1o3oQdjWPzTb4vUKBHiBzM6qkfsUZAL4b-zqcL2KxE1jgAyweOBCYpp3CdQ7LCgDO6I5VfS8lzcNBPnPq8_5OkN_r6xGcemqmtbzIIFQonHkEIYSOl0km6axb9eXkmCacj_0lr5oyGA8xGjLFN85rAsznmLomsfvDmvk8qx1fT8OQGW0Fsqgeeoujl6HOsXsGEVr-4dP1EupFbf4WR-v8GPCBN-c9FSQ7472yBB8ES-QlDo-xEP0xB8bn0ogShH5PCnzG3aQ1Y8fpAK_nzqUPpkZDW0HEUKQw6shyWu9MqPLKD1IbYuzWo_9Vba83qhFFvye5IxqyGiZr2SG2wZpmu1kWNjSG89Br3ZMTKQ2XSVpURse0EKs4abuIUAjtf7O1t3PVWF1Hm8R2Pllgb36whs0ZsXEgvM3gAghdJ_T8ydCqOD76BitUXjI_oV_2cn7KlbYyMsT90CHLr6NrT0ZW3B0s-hDXnGSiwvDM7ZCywF8XguZFj2X3konPXb1o1blj-A6LDR-ROdDTdsc9jBotrseWFE7qTdeVNs92ZxvaiWGvziDNdVARHQAwQIY5krEfq8B6DpkghJNg=w2194-h1199',
  ogType: 'ウェブサイト',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'スマートロジスティクスセンター：RTLSを活用したゲームチェンジャー',
  date: '2023-09-04',
  tag1: '#大規模物流センター',
  tag2: '#スマートロジスティクス',
  tag3: '#在庫管理',
  tag4: '#ロジスティクス管理',
    
  text: [
    {
      head: '',
      subHead: '',
      desc: `スマートロジスティクスセンターは、効率と生産性の点で従来の物流業界から大きく進化しています。資産、在庫、労働力を効率的に管理することにより、スマートロジスティクスセンターの建設はビジネスの成長と競争力を維持するために不可欠となりました。 これを実現するためには、リアルタイムロケーションシステム（RTLS）技術の採用が必要です。 <br/>リアルタイム位置追跡ソリューションがスマートロジスティクスセンターに導入されると、何が可能になるのかを探ってみましょう。`,
      dot: ''
      
    },
    {
      head: '',
      subHead: '1. 資産管理とトラッキングの最適化',
      desc: 'RTLS技術を使用して、物流センター内のすべての資産と設備をリアルタイムで追跡できます。これにより、資産の位置と状態を正確に把握できます。例えば、貨物トレーラーやフォークリフトの位置をリアルタイムで追跡することにより、資産の利用効率を最適化し、必要に応じて効率的な保守計画を立てることができ、資産管理コストを削減し、資産の寿命を延ばすことができます。',
      dot: ''
      
    },
    {
      head: '',
      subHead: '2. 在庫管理の精度向上',
      desc: 'RTLSを介して、製品と在庫の位置をリアルタイムで追跡できます。これにより、在庫管理が革命的に変わります。物流センター内の製品が正確にどこにあるかを知ることは、在庫ロスを最小限に抑え、在庫回転を最適化します。これにより、コスト削減と効率の向上が実現します。',
      dot: ''
      
    },
    {
      head: '',
      subHead: '3. リアルタイムデータ分析',
      desc: 'RTLSはリアルタイムデータを生成し、物流センターの運用をリアルタイムでモニタリングおよび最適化できるようにします。物流センター内のどのプロセスでボトルネックが発生した場合、管理者は直ちに問題を解決するための行動を取ることができます。これは顧客サービスにもポジティブな影響を与えます。',
      dot: ''
      
    },
    {
      head: '',
      subHead: '4. 労働力の最適化',
      desc: 'RTLSは労働力の管理にも役立ちます。労働者の動きと労働時間を追跡することにより、労働者の効率を向上させ、労働スケジュールを最適化できます。RTLSを使用して物流センター内で労働者を効率的に配置し、タスクを調整することは、労働コストの削減と生産性の向上につながります。',
      dot: ''
      
    },
    {
      head: '',
      subHead: '5. 顧客サービスの向上',
      desc: 'RTLSは注文処理と配送プロセスに重要な役割を果たします。製品のリアルタイム位置を追跡することにより、正確な到着予定時刻を顧客に提供でき、顧客サービスの品質を向上させます。製品がいつ到着するかの情報を顧客に提供することで、顧客満足度が向上します。',
      dot: ''
      
    },
    {
      head: '',
      subHead: '6. 安全性とセキュリティの強化',
      desc: 'RTLSは物流センターの安全性とセキュリティを向上させます。設備と資産の位置を正確に追跡することにより、不正侵入や在庫の盗難を防ぐことができます。設備の定期的な点検と保守も安全性の確保に貢献します。',
      dot: ''
      
    },
    {
      head: '',
      subHead: '7. 環境への配慮の強化',
      desc: 'スマートロジスティクスセンターはエネルギーと資源の効率を向上させることができます。RTLSは資源の使用を最適化し、エネルギー消費を削減するのに役立ちます。例えば、物流センター内の照明と機械の自動調整は、エネルギー消費を最適化できます。',
      dot: ''
      
    },
    {
      head: '',
      subHead: '',
      desc: 'RTLS技術は物流およびサプライチェーン管理分野で革新をリードしています。スマートロジスティクスセンターにRTLS技術を導入することで、コスト削減、効率向上、顧客サービス品質の向上、環境への配慮の強化など、さまざまな利点を享受できます。これらの利点は競争力を高め、より効果的な物流およびサプライチェーン管理の機会を提供します。したがって、スマートロジスティクスセンターを設立する企業は、積極的にRTLS技術を検討すべきです。',
      dot: ''
      
    },

  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: '労働者や設備にUWB端末を取り付けてリアルタイム位置追跡を支援する製品。',
        img: productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution: [
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      },
      {
        img: logisticsSafety.thumb,
        data: 'logisticsSafety',
        link: '/jp/enterprise/logistics/safety'
      }, 
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/jp/enterprise/manufacturing/asset'
      }
    ]

  }


}
