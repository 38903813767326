export default {
  
  titleBanner: {
    subtitle: 'Stereo Vision',
    title: '位置測位およびポジショニングに使用される<br/>Stereo Vision技術',
    description: '*Stereo Visionは、2つの結合されたカメラを使用してオブジェクトを3次元で認識する技術です。<br/>この技術は、人間の両眼と似た原理で動作します。つまり、2つのカメラは異なる位置から同時に同じ対象を撮影し、それによってオブジェクトの奥行き情報を取得します。'
  },
  tech: {
    title: 'Stereo Visionとは何ですか？',
    text: 'Stereo Visionは、2つのカメラを使用して画像を撮影し、それを使用して3D空間の奥行き情報を推定するコンピュータビジョン技術です。このために、2つのカメラは同じ対象を異なる位置から撮影し、取得した画像ペアを使用して奥行き情報を計算します。それには、2つの画像間の視差、つまりdisparityを計算し、それを使用して奥行きを推定します。<br/><br/>この技術は、人間の両眼を使用して奥行き情報を推定する原理と類似しています。これにより、3D環境でのオブジェクトの位置と距離を把握することができ、ロボティクス、自動運転車、ビデオゲーム、ビジョン処理など、さまざまな分野で活用されています。これは従来のコンピュータビジョン技術と比べて、より正確で実用的な結果を提供できるため、さまざまな応用分野で重要な役割を果たしています。'
  },
  feature: {
    title: 'Stereo Visionを利用したRTLSの特長',
    text: 'Stereo Visionを利用したリアルタイム位置システム（RTLS）は、2つのカメラで収集した視覚情報を分析し、オブジェクトや人物の位置情報をリアルタイムで測位および監視するシステムです。<br/>このStereo Visionは非常に正確な位置推定が可能であり、高い精度と信頼性を保証します。また、リアルタイムに位置情報を更新できるため、物流、製造、建設などで重要なリアルタイム位置情報を提供することができます。<br/><br/>さらに、Stereo VisionベースのRTLSでは、タグを使用しないため、対象に対して追加のタグや信号発生機器を購入および保守する費用が不要です。これにより、システム構成コストを削減し、システムの保守コストを削減するため、コスト面で効率的です。また、対象の位置測位の正確性と信頼性が高いため、コスト効率性とともにシステムのパフォーマンスも優れています。したがって、Stereo VisionベースのRTLSは、コスト削減とパフォーマンス向上を同時に実現できる非常に有用な技術です。'
  },
  
  howItWorks: {
    title: 'Stereo Visionを利用したポジショニングの動作原理',
    card: {
      detection: {
        title: 'オブジェクト検出',
        description: 'ビジョンベースのRTLSでは、Deep Learningオブジェクト検出技術を使用して画像内の対象を測位します。この技術は、画像内のさまざまなオブジェクトを識別および分類するためにトレーニングされたAIアルゴリズムです。現在は約80種類のオブジェクトを認識できますが、顧客の要求に応じて追加のトレーニングによりさらに多くの種類のオブジェクトを認識できます。'
      },
      estimation: {
        title: '深度推定',
        description: '物体の位置を測位するために、異なる角度から撮影された2枚の画像を使用して深度情報を推定します。これは、人間が両眼を使用して空間の深さを認識する原理と似ています。私たちはこれを実現するために、より正確に深度を認識できるDeep Learning技術を使用しています。'
      },
      calculation: {
        title: '座標計算',
        description: 'コンピュータビジョン技術を活用して、オブジェクトの検出や距離推定、角度計算などを行い、オブジェクトの位置情報を計算することができます。これにより、オブジェクトのリアルタイム測位や位置の把握など、さまざまな応用が可能です。'
      }
    }
  },

  core: {
    items:{
      title: `KongTechのStereo Visionを利用したRTLSの核心技術`,
      streaming: {
        title: `オリジナル画像ストリーミング`,
        description: `位置測位の精度を向上させるためには、高度なデプスマップ推定技術が必要です。人間は経験と直感によっておおまかな輪郭だけで距離を迅速かつ正確に認識することができますが、デプスマップ技術は画像の詳細に焦点を当て、精密な推定が位置測位に直接関連します。そのため、私たちは一般的なCCTVで使用されるロスレス圧縮ビデオストリーミング方法を使用せず、ロスレスな画像を活用して低レベルの特徴の損失を最小限に抑えます。この方法により、Stereo Visionを利用したRTLSは位置情報の損失を最小限に抑えます。`,
        subDescription: `ロスレスなオリジナル画像を使用したデプスマップでは、物体の表面がより連続的に表現されます。`,
        imgLabel1: `推定背景`,
        imgLabel2: `オリジナル`,
        imgLabel3: `エンコーディング`
      },
      estimation: {
        title: `高度なデプス推定`,
        description: `デプス推定にはステレオカメラの画像を活用することがコンピュータビジョンの研究分野で大きな関心を集めています。特に、過去10年間、ディープラーニングの手法を適用することで従来の古典的なアルゴリズムよりも優れた結果を示しています。ただし、高い精度とリアルタイム性を確保するために、多くの分野で古典的なアルゴリズムが使用され続けている理由もあります。これは通常、15 FPS以上のフレームレートで処理できることを意味します。`,
        imgLabel: `他社のDepth Map（Computer Vision使用）`,
        description2: `従来のコンピュータビジョンアルゴリズムを使用したDepth推定の結果は、詳細な部分や物体の境界で歪みが発生する問題がありました。たとえば、マウスを持つ手の上部や、パーティションに隠れた人の頭などは推定されませんでした。しかし、私たちが開発したディープラーニングベースのモデルは、これらの問題を克服しました。このモデルは、"Stereo Visionを利用したRTLS"で使用できるように最適化されており、軽量化と微調整によりリアルタイム性を確保します（>15 FPS）。ただし、複数のカメラの入力を処理する場合、処理速度は約4 FPS遅くなる場合がありますが、画面上での人の移動速度を考慮すると、満足のいく位置情報を提供することができます。`,
        imgLabel2: `KongTechのDepth Map（Deep Learning使用）`
      },
      fineTuning: {
        title: `Depth Estimationの微調整技術`,
        description: `自然光と人工照明で撮影された画像は異なる特性を持ち、ステレオカメラの片方のレンズにのみ照明が当たる場合でも発生することがあります。この場合、左右の画像センサーの露出値の差により、左右の画像の特性が一貫していなくなります。`,
        imgTag: `照明の直接的な照射による左右のステレオ画像の特性の差`,
        description2: `これらの外部環境の要因だけでなく、ステレオカメラの製造プロセスで発生する内部要因も考慮する必要があります。たとえば、画像センサーを基板に実装する際に発生する上下の整列エラーや回転エラー、レンズと画像センサーの間の距離変化による倍率の違いなどが該当します。`,
        imgLabel: `(a): 明るさの違い, (b): 画像センサーの回転エラー, (c): 画像センサーの上下整列エラー, (d): 焦点距離エラー`,
        imgLabela: `(a)`,
        imgLabelb: `(b)`,
        imgLabelc: `(c)`,
        imgLabeld: `(d)`,
        description3: `これらの現実的な問題を克服することで、Stereo Visionを利用したRTLSを高い信頼性で提供することができます。KongTechのDepth Estimationの微調整技術とノウハウはこれを可能にし、結果としてDepth Estimation技術は特定のハードウェアに依存せず、環境の変化にも頑健に機能します。`
      },
      distance: {
        title: `カメラのオブジェクトバリエーションを考慮した距離計算`,
        description: `ステレオカメラから取得した画像をディープラーニングベースのDepth推定技術に変換する際、広角レンズによるDepthの歪み問題が発生します。このような歪みは、距離が近づくほど増加する傾向があり、光軸から遠ざかるほど非線形な挙動を示します。`,
        imgLabel: `広角レンズによる歪みのパターンおよびカメラA、B間のオブジェクトバリエーション <br/> (緑色: 2.7m, 紫色: 6.3m, 赤色: 8.8m)`,
        imgLabela: `カメラA`,
        imgLabelb: 'カメラB',
        description2: `この問題を解決するためには、歪みのパターンを正確にモデリングし、実際のDepthと一致するように補正するプロセスがDepth推定技術の重要な部分です。この補正プロセスには、カメラレンズの製造プロセスで生じるバリエーションやアセンブリプロセスで生じるバリエーションなど、カメラの個別の不確実性を考慮する必要があります。歪み補正モデルを適用しない場合、一般的には広角ステレオカメラから推定されたDepth値で30％以上のバリエーションが観察されます。しかし、歪み補正モデルを適用すると、これらの誤差を除去するだけでなく、Depth推定の平均誤差率を約50％減少させる効果を得ることができます。したがって、私たちはすべてのステレオカメラが出荷される前に、独自に開発した高度な歪み補正モデルを使用して補正を行っています。これにより、優れたDepth推定技術を提供し、顧客に最高のパフォーマンスと信頼性を提供します。`
      }
    }
  },
  
  advantage: {
    title: 'Stereo Visionの主な利点',
    items: {
      accuracy: {
        title: '正確な位置推定',
        description: 'ステレオカメラを使用することで、2つの画像を同時に取得し、オブジェクトの位置を3次元空間で正確に把握することができます。これにより、オブジェクトの位置と動きを正確に推定することができます。'
      },
      position: {
        title: '拡張性',
        description: 'ステレオビジョン技術は、空間のサイズに制約されず、さまざまなスケールやタイプの屋内および屋外環境に適用できます。また、ステレオカメラを基にしたRTLSシステムは、必要に応じて追加のセンサを使用して機能を拡張することができます。'
      },
      security: {
        title: 'コスト効率',
        description: 'ステレオビジョン技術は、他の位置推定技術と比較して比較的低コストです。また、既存のステレオカメラを活用してRTLSシステムを構築することができるため、設置コストを削減することができます。'
      },
    }
  },
  
  useCase: {
    title: 'Stereo Visionを活用したポジショニングの使用例',
    description: 'Stereo Visionの拡張性は、さまざまな屋内位置推定の使用例に効果的なオプションとして機能します。次に、Stereo Visionの屋内位置推定技術が使用される一部の使用例と応用分野を紹介します。',
    card: {
      auto: {
        title: 'ロボット自動化',
        description: 'ロボット自動化は、製造業界でよく使用されるステレオビジョンを活用した技術です。ロボットはステレオカメラを使用して環境を認識し、位置推定技術を使用して製品の自動移動、組み立て、検査、または梱包を行うことができます。',
        items: ` `
      },
      car: {
        title: '自動運転車',
        description: 'ステレオビジョンは、自動運転車の位置推定、障害物検知と回避、車線維持などに使用されます。ステレオカメラの3D情報を活用して周囲の車両の位置と方向を推定し、それを利用して車両の移動経路を計算します。',
        items: ` `
      },
  
      counting: {
        title: '人数カウント',
        description: '商業施設や公共施設で顧客の出入りと人数を把握するために人数カウント機能が利用されます。ステレオビジョンを活用したRTLS技術は、出入口に設置されたカメラを使用して通行する人々の出入りを区別し、人数をリアルタイムで把握することができます。',
        items: ` `
      },
      space: {
        title: 'スペース利用率分析',
        description: '建物内や駐車場などのスペースでOccupancy Dataを収集し、スペースの利用効率を最適化することができます。ステレオビジョンを活用したRTLS技術は、スペース内に設置されたカメラを使用して、スペース内のオブジェクトの位置と動きをリアルタイムで把握することができます。これにより、スペース内の利用状況を把握し、スペースの利用効率を向上させることができます。',
        items: ` `
      },
      safe: {
        title: '産業安全',
        description: 'ステレオビジョンを活用したRTLS技術は、産業現場で安全を確保するためにも使用されます。例えば、作業場内で人や物の位置をリアルタイムで把握することができ、作業中に発生する事故の予防に大きく貢献します。<br/>特に、産業現場では倒れた人や作業者が重傷を負った場合にこれを迅速に検知し、ゴールデンタイムを守るのに役立つことがあります。RTLSシステムは、産業現場で発生するさまざまな危険な状況を事前に検知し、早期に対応することができます。これにより、作業者の安全と健康を保ち、生産性と効率を向上させることができます。',
        items: ` `
      },
      security: {
        title: 'セキュリティ',
        description: 'ステレオビジョンを活用したRTLS技術は、セキュリティ面でもさまざまな用途に活用することができます。例えば、オブジェクトや人物の移動経路を測位して、不正な人員のアクセス制限システム、CCTVモニタリングなどに使用することができます。このような技術は、建物の出入り口や重要施設のセキュリティ管理、軍事産業などにも活用されることがあります。',
        items: ` `
      },
  
  
    }
  }
  



  }