import { BACKGROUND_SECONDARY } from "@assets/styled/color.style";
import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";

export const StyledTechnologyContent = styled(StyledGridContainer)`
  padding-top: 96px;
  padding-bottom: 128px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding-top: 64px;
    padding-bottom: 116px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-top: 64px;
    padding-bottom: 96px;
  }
`;

export const StyledTechnologyContentBottom0 = styled(StyledGridContainer)`
  padding-top: 96px;
  padding-bottom: 0px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding-top: 64px;
    padding-bottom: 0px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-top: 64px;
    padding-bottom: 0px;
  }
`;

export const StyledTechnologyGroup = styled.div`
  padding: 64px 0;
  border-bottom: 1px solid ${({ theme }) => theme.BORDER_TRANSLUCENT};

  &:first-of-type {
    padding-top: 0;
  }
`; 

export const StyledTechnologyTitle = styled(StyledDisplay)`
  margin-bottom: 16px;
`;

export const StyledTechnologyText = styled(StyledParagraph)`

`;  

export const StyledTechnologyImg = styled.img`
  width: 100%;
  border-radius: 16px;
  background-color: ${BACKGROUND_SECONDARY};
`;

// Items - Start
export const StyledTechnologyItems = styled(StyledGridRow)`
  width: 100%;
  margin-top: 64px;
`;

export const StyledTechnologyItem = styled(StyledGridColumn)`
  
`

export const StyledTechnologyItemImg = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
  background-color: ${BACKGROUND_SECONDARY};
  margin-bottom: 24px;
`;

export const StyledTechnologyItemTitle = styled(StyledHeading)`
  margin-bottom: 16px;
  height: fit-content;
`;

export const StyledTechnologyItemDescription = styled(StyledParagraph)`

`;
// Items - End



export const DivPaddingRight = styled.div<{pixel : string}>`
  ${({pixel})=> pixel&& `margin-right : ${pixel}px;`}
`;

export const IndentText = styled(StyledParagraph)`
  text-indent : -12px;
  padding-left : 12px;
`;