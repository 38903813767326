import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

   
  helmet: '',
  desc: '在建筑行业，确保安全和保护工人至关重要。为实现这一目标，最新的实时位置跟踪系统（RTLS）技术正在被使用，以使建筑工地上的工人安全管理更加容易。',
  keywords: '建筑安全、建筑工地工人安全、智能建筑、劳工安全、劳动安全与卫生法、建筑安全管理综合信息网络、建筑工会、安全劳动',

  ogTitle: '',
  ogDesc: '',
  ogSite: '智能安全管理的综合解决方案:: Orbro博客',
  ogUrl: 'https://orbro.io/cn/blog/smartSafety',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDyroUx1vmR8ckUGMDATiid3Dh8um-T9HPZwlPIYFAVQZ6gAqtjkC9yRmKCzc1E4y9OSYTPb_LCkF_SEylO00Rky0eS7weV0HFegyoQFnuWtZS4dJcgZhXg7NgKbB-lJHqoWX9iWFXOLGYRCck7Pzm9SecNQohk3gkJMABfK0W7SOahk5A_CpImCa6ZeYjnPrnJxJzATWPOAu_GUAv9tunvWcaHzwHNFGzd4wmKkzNRlpgiqTGmxGDiF1tnkU43_8zJ8Af3YPglR8V_Y8nr6hIKD0r_deC5eKuX9NhdkGi3_MwlH_6ID7fHAUY_4D8ay4UBjdHdIY39ZO5qmVVydqF4YA7_e2S6LGjkfGuhX06LkrzYLkNj0kAaBXMND1BhVSI-Zb-GpDot9xVAkg6mS0dhnTHGd4Dvh5P1F6CkjB2DJ8ZytwXnQJyQtOzhgYFcstok9388wIUDLs51CPoeUsytegmLUUG2NVsxXLfpB4yqeUz4gTQjH3WvGUQP3GKJkEsZ-fuzdaF3pTx9LLKB_xDvIeFhzmb4fONSHT2U9U_QEJjLCzt-NCZxHK3YK_xeHCQhB9h_8KLnCTSz8LgKKy80N4IfOpROiCh_oyYTiWXzhcWHWWh-OEvPtgY2xoCN2CtcClws0U3t-xRH5NQ9bsFsLU_41jxHLmULeU4UjUBfE50lAbgEhTIIi45ibRyNlruRzOzOsd3M129XYQM4F49mvW0c-MCe1D8fhLFbSg3TLCoO6lNHzOuGk5AhawFY6LXRmsNWIAf_orzFCaByHj8FIYhInRqA3JGuwUkGOVkqTrb2pAeqRb2Y0mO0a7Q5yuK7IEEVD6nNCJ9QrWtOBE8yYzrXVf54N7SL9aQz5ZCP4SCwp27j3V85cmk5dx-ZyDGiapVFg1rNA7Mwnx4L3F0eKNcRZzT9WWMWBXLD61fLbIfCcvoENjVSYEPO8VN5ipvNWSNwV5iHjace_sdYEzNMByEmrEjVt4slthi5itRPgSKrrgq1CQDcdZsSh1ToMCeUzPAcoPW9rnjGh8tngE7-VrPH5QLHldQu9mQy9skU-nCfY8A2DF9RRzB6So10votLNIb3bGaL-HSF5s0TOU8HlSAg2EKU6gM4LGwPjg5HztDXDoJyuRiBQcw6myNwpypRu3MPsD4EhS-uhn49VM4TVxsZMw6DJc5CXmZHhghyxX9GJyf2uHKWVOOzLkeJm2641BA8ZOpxpeg1bfNczrS9qVJDgRpzye_uh7uqihmtRlKAWH9tA2nouewAzocUyrzii7EX46YkP2GHg7DY_L_42aHc9EyTXPpGw3LDlJXHQ3sQ44RUyNV1EFE99w27XRmnxr_8YlZD9pegnY5GWNBiU35BwROQDKDsEzgKunAYgEhg3EspT4nJl4E_j19DHxsNer97Yudk2jhRWZ1QTcFWH67Ew8lCLdVw_yY0CqKWuMZK0o_379BLJt_dJIO5GVE-vhMEqdEN1zCUUp8yqBaruMbTm-_IfSHGOgQa4uue05eTTj-RcxLbAz-mRT8gzDo4yeOWNDBw4NsMtbQr-YOf89LvIvhhch-ND74zLC0KeIz5gM7VfV0VD4MkKBw=w2049-h1228',
  ogType: '网站',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',
    
    title: '智能安全管理的综合解决方案',
    date: '2023-10-04',
    tag1: '#工人安全管理',
    tag2: '#智能建筑',
    tag3: '#建筑工地安全',
    tag4: '#RTLS',
    
    // <br/>
    text: [
        {
          head: '',
          subHead: '',
          desc: '现代建筑工地复杂多样，充满各种潜在风险。确保安全和保护工人至关重要。为实现这一目标，最新的实时位置跟踪系统（RTLS）技术正在被使用，以使建筑工地上的工人安全管理更加容易。在本文中，我们将探讨RTLS技术如何有助于提高工人安全。',
          dot: ''
        },
        {
          head: 'RTLS技术介绍',
          subHead: '',
          desc: 'RTLS是Real-Time Location System的缩写，意味着实时位置跟踪系统。该技术能够精确追踪和监控移动元素，如工人和设备。RTLS系统使用各种技术，如GPS、RFID（射频识别）、UWB（超宽带）、蓝牙、超声波和其他传感器来跟踪工人的位置。',
          dot: ''
        },
        {
          head: 'RTLS对工人安全的优势',
          subHead: '事故预防',
          desc: 'RTLS系统可以通过跟踪工人的实时位置来检测潜在的碰撞或危险。例如，在起重机操作期间，它可以检测到与其他工人或设备的接近，并发送警告以防止事故。',
          dot: ''
        },
        {
          head: '',
          subHead: '监测工人状态',
          desc: 'RTLS可以监测工人的活动并跟踪其健康状况和疲劳程度。如果工人疲劳，它可以提供指导以采取适当的休息或远离危险任务。',
          dot: ''
        },
        {
          head: '',
          subHead: '紧急响应',
          desc: '在紧急情况下，RTLS系统提供精确的位置信息，缩短了救援或紧急医疗援助所需的时间。这增加了挽救工人生命的可能性。',
          dot: ''
        },
        {
          head: '',
          subHead: '工作日志和报告',
          desc: 'RTLS系统记录工人的活动并生成工作日志。这有助于优化工作计划并提高生产力。此外，在事故发生时，它通过记录相关信息便于事故调查和报告。',
          dot: ''
        },
        {
          head: 'RTLS的应用案例',
          subHead: '建筑工地',
          desc: '建筑工地充满各种风险，许多工人合作完成任务。RTLS系统实时跟踪设备和工人，创造更安全的工作环境。',
          dot: ''
        },
        {
          head: '',
          subHead: '工厂和制造业',
          desc: '工厂和制造业领域也使用RTLS技术来跟踪工人的位置并管理库存。这种优化提高了生产流程并提高了效率。',
          dot: ''
        },
        {
          head: '',
          subHead: '物流和仓库',
          desc: '在物流和仓储领域，RTLS系统用于库存跟踪、监控仓库内工人的位置以及优化发货和配送流程。',
          dot: ''
        },
        {
          head: '结论',
          subHead: '',
          desc: 'RTLS技术是提高建筑工地和各种其他行业工人安全性和生产力的强大工具。具有事故预防、工人状态监测、紧急响应、工作日志和报告等功能，为现场管理人员和工人提供了重大好处。展望未来，我们可以期待RTLS技术在建筑行业和其他行业的进一步发展。在这些环境中，继续努力提高工人安全至关重要。',
          dot: ''
        },
        {
          head: '',
          subHead: '',
          desc: '',
          dot: ''
        },
    ],
  
    link: {
      page: 'rtls',
      numb: '2',
      product: [
        {
          text: productPurchase.purchase.worker.workerTracking.title,
          title: productPurchase.purchase.worker.workerTracking.title,
          serial: productPurchase.purchase.worker.workerTracking.serial,
          desc: '通过安装UWB设备来支持实时位置跟踪，可附在工人或设备上的产品。',
          img: productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution: [
        {
          img: constructionSafety.thumb,
          data: 'constructionSafety',
          link: '/cn/enterprise/construction/safety'
        },
        {
          img: constructionAsset.thumb,
          data: 'constructionAsset',
          link: '/cn/enterprise/construction/asset'
        }, 
        {
          img: constructionEquipment.thumb,
          data: 'constructionEquipment',
          link: '/cn/enterprise/construction/equipment'
        }
      ]
  
  
    }
  
  
  }
