import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset, medicalPatient, medicalWaste } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";
import digitaltwin from "@src/assets/images/digitaltwin/video/traffic.mp4";

export default {


    helmet: '| スマートシティ (Smart City) 構築事例',
    desc: 'スマートシティは、現代の都市で急速に発展している概念で、最新の技術とインフラを活用して効率的な都市運営を追求します。センサー、IoT技術、ビッグデータなどを活用して、交通、エネルギー、環境などさまざまな分野でデータを収集し、分析して市民の生活を向上させ、持続可能な発展を目指しています。',
    keywords: 'スマートシティ、デジタルツイン、デジタル変革、smart city、digital twin、digital transformation、リアルタイム位置追跡、rtls、orbro、オブロ、コンテク',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'スマートシティ (Smart City) 構築事例 :: オブロ ブログ',
    ogUrl: 'https://orbro.io/jp/blog/example-of-smart-city',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkFVkNhGYhAjBJLQh-TO_MrDW5dZ9e675klRxgK1f4x67wU6ADQnAzVhSAqDCJ5a6mjIlcP3clmtu92HV05SxhzPTDf1NScGbhZaAOxxAakyFJm0bwewGqWjuE1NJoM86xI5RkwtjQZ4WFYc6d0Hrid-pszPZbQGf1gYPMDqDwORsg5wyGW5bNgU7KKeOTMzrkt78Yr5lYcchyCNmpn2NmzJazzotGsmI9T33V6K3T6xqraKPVNfNkg6odqKHbGpbE7knODw-Ps7LNMLRhHIAZIM9lduTSX896wr7cZscafS5TDVi8cSC0ERUhdb3YwCRgKm0YqSsnVcCJcAbu4evdtoUzdMcjZAIfKR_kw7HgXI42LaA4C-PhxW2jQQKyD9UIxoOFPmJFIh6q7vIHbg3k3cAUc2dxW0Z6hfKLB2k10q0aubzcnWFJnEPSibJNaZJA0fslRcEQTOOSq7qH3K-9HiRproHejfmIxqYsRayvAnKXQPSJyG6cmWTKw2ctEcRwcsQV7cMU2H5VMEc-s5BYkjOqQe6yylbCUXzQ8qYVwls2h-mstfcyt2phYijwZXPHjEY0Xd1KSUErYHTSCWx_A1JKCL30IryNxWX6ejXVr4rzx9VOXpS53IYlHEz2WJTk6LjBccSU2u9lAv_gwAiZTmTSoRX658UQv-7kdx_Et7OVcWSg4GdQpFTDS9mF8huuN39CfL2Tm9qVy2j5jAl-gTQ4o4bnPhaHz9NMoaM-qoTdDt4Q5C1ysaxNReONUY8phy-5RgTgHszfiRXjSpTP_fjzv_dGumhP0V_Rf6ndfluYR6Mg1BHCVhSUG62aDV8bGqdS2oYAmGPYgbN99KcLyQgySVflbWa6wC_fOGkLNeso6n_M6C7otFKqjX9XmB3s6GUBTaS3JiqqQJ-N9qGtjKI86za8HY7i9lxAJEh4iY2FSXw3kIW-m6OTvQbenmldQlgE6hB_Fl8rm6ATKKLAhsxwx5fMKD16IV2hBFuQ9wpnIjLhw1XLzIy0R5Pka1Mygfo6no04iZU4LAApDVkphY4k958mwN4tg_wyl3L63jjST8taRHacU0M-4ADiu0tC2vJzWH1XQwiSedBQQTTUMuydsHQCMYlanG9WhyhybHTxRIA7pUX7-wpNqkFiWCrT9TPNbHg-ON5nQrBGyDUZUb5fC-U4EkUN0a-Pe-CHJJ6bZd1m_oCpzdulGMPnK-4_VjWBJAiQRteQ8QTUKNhmQj2yEnZwCJQZZSTpk1IlGzgHiJ_BsIYyD_a3z-ysShhTuE9hG_57y4aIz8Rf6ZsrXTp1OeEHkNkCvjH7ZnbdABz0u_QyshaMMfU7EKVUbH6tIXiym1yOX6upY9M_6JtOTgtlulM87kVzPjPoy47O2YEq7mcAOwMBRmDb6gfW2J0t7PuaFMgTQAi4XiKpM5Pf_NhIK-wNtRCAhcSD9WCAd1CIxJ-CrcG3xn77PYV4NQRe0Oi2KQCyqG3frUstyWuJuSAeYrdYhBRBkDG6OoVG48EshJwneIwytpeURilKCKLLPt64ls45oiR-ZMwMxmdpfsCMct--L=w2010-h1214',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'スマートシティ (Smart City) 構築事例',
    date: '2024-01-09',
    tag1: '#スマートシティ',
    tag2: '#デジタルツイン',
    tag3: '#デジタル変革',
    tag4: '#未来の街',

    // <br/>
    text: [
        {
            head: 'スマートシティ (Smart City) 構築事例',
            subHead: '',
            desc: 'スマートシティは、現代の都市で急速に発展している概念で、最新の技術とインフラを活用して効率的な都市運営を追求します。センサー、IoT技術、ビッグデータなどを活用して、交通、エネルギー、環境などさまざまな分野でデータを収集し、分析して市民の生活を向上させ、持続可能な発展を目指しています。スマートシティはグローバルな都市が革新と協力を通じて未来の都市のモデルとして発展しています。<br/><br/>それに伴い、世界中のスマートシティ構築事例を以下で見ていきます。',
            dot: ''
        },
        {
            head: 'シンガポール (Singapore) スマートシティ',
            subHead: '',
            desc: 'シンガポールは、システマティックにスマートシティの概念を実現する先駆者です。市内に設置されたさまざまなセンサーやIoTデバイスは、交通渋滞をモニタリングし、効率的な交通管理を実現しています。また、スマートグリッド技術を活用してエネルギー効率を向上させ、公共施設の自動化やインテリジェントなCCTVネットワークを通じて安全性を向上させています。',
            dot: ''
        },
        {
            head: '東京 (Tokyo) スマートシティ',
            subHead: '',
            desc: '東京は都市のスマート化を促進するさまざまなプロジェクトを進めています。スマートライティングや建物の自動化を含む技術の導入により、微粒子の計測センサーや環境のモニタリングを行い、インテリジェントな交通システムは交通渋滞を減少させ、都市の効率を向上させています。',
            dot: ''
        },
        {
            head: 'バルセロナ (Barcelona) スマートシティ',
            subHead: '',
            desc: 'バルセロナは、スマートグリッド、ワイヤレスセンサーネットワーク、公共交通のデジタル化を通じて、都市の持続可能な発展を追求しています。ビッグデータ分析を活用してゴミ収集やエネルギー使用量を最適化し、スマートシティプラットフォームを構築して都市のさまざまな機能を効率的に管理しようとしています。',
            dot: ''
        },
        {
            head: 'ソウル (Seoul) スマートシティ',
            subHead: '',
            desc: 'ソウルはスマートシティの構築を通じて都市のインフラを革新しています。スマートグリッドの構築と公共交通のデジタル化は、エネルギー効率と交通の便利さを向上させています。また、スマートシティプラットフォームを通じてさまざまなデータを統合し、分析して市民の利便性と安全を向上させることに力を注いでいます。',
            dot: ''
        },
        {
            video: digitaltwin,
            link: 'https://orbro.io/jp/digital-twin',
            videoDesc: '(動画をクリックすると、オブロ デジタルツイン ページに移動します。)',
            head: 'スマートシティ構築方法',
            subHead: '',
            desc: 'スマートシティを構築するには、さまざまな技術と戦略が必要です。以下は、スマートシティを構築する主要な方法についての簡単な説明です。',
            dot: {
                a: '<strong> • インフラの改善 : </strong>スマートシティを構築するには、強化されたデジタルインフラが必要です。高速ブロードバンドと5Gネットワークの構築により、リアルタイムデータ転送が可能になり、インターネットに接続可能なデバイスが効率的に動作できるようになります。',
                b: '<strong> • ビッグデータおよび人工知能の活用 : </strong>大量のデータを収集し、分析して都市の運営を最適化することが重要です。ビッグデータと人工知能技術を活用して、交通、環境、エネルギーなどさまざまな分野で発生するデータをリアルタイムで分析し、予測します。',
                c: '<strong> • スマートセンサーおよびIoT技術の導入 : </strong>都市内にさまざまなセンサーやIoT技術を導入して、交通、環境、エネルギーなどに関するデータをリアルタイムで収集します。これにより市民の利便性が向上し、都市運営が最適化されます。',
                d: '<strong> • スマートエネルギーおよびグリッドシステム : </strong>再生可能エネルギーの導入とスマートグリッドシステムの構築により、エネルギー効率が向上し、エネルギー消費を最適化します。スマートエネルギーマネジメントシステムを使用してエネルギー使用量を最適化し、持続可能なエネルギー消費を促進します。',
                e: '<strong> • 公共交通のデジタル化 : </strong>公共交通システムをデジタル化してリアルタイム情報提供、予約システム、自動運転車両などを導入し、交通渋滞を緩和し、環境への影響を最小限に抑えます。',
                f: '<strong> • スマートシティプラットフォームの構築 : </strong>さまざまなデータを効率的に収集、管理し、市民や企業に開かれたデータを提供するスマートシティプラットフォームを構築します。これは都市の運営を最適化し、市民の参加を促進するのに重要な役割を果たします。',
                g: '<strong> • サイバーセキュリティの強化 : </strong>スマートシティではさまざまなデータがリアルタイムで交換されるため、サイバーセキュリティは中核的な課題です。強力なセキュリティシステムを導入してシステムを保護し、市民の個人情報を安全に管理します。',
            }
        },
        {
            head: 'スマートシティの利点',
            subHead: '効率的な都市運営',
            desc: 'スマートシティはリアルタイムのデータ収集と分析を通じて、交通、エネルギー、水資源、ゴミ処理など都市の運営を効率的に管理できます。これにより都市の機能が最適化され、市民の日常がスムーズに進行します。',
            dot: ''
        },
        {
            head: '',
            subHead: '交通フローの最適化',
            desc: '公共交通のデジタル化、自動運転技術の導入などにより、交通渋滞が減少し、交通フローが最適化されます。これにより市民の移動が円滑になり、環境への悪影響が軽減されます。',
            dot: ''
        }, {
            head: '',
            subHead: 'エネルギー効率と持続可能性',
            desc: 'スマートシティは、スマートグリッド、再生可能エネルギー、エネルギー効率化技術などを活用してエネルギー消費を最適化し、持続可能なエネルギー利用を促進します。これにより、環境への負荷를減少させ、エネルギーコストを削減できます。',
            dot: ''
        },
        {
            head: '',
            subHead: '市民参加促進',
            desc: 'スマートシティは市民が都市の運営に参加できるさまざまな手段を提供します。オープンデータ、モバイルアプリケーションなどを通じて市民は都市の意思決定に参加し、より良い環境を構築できます。',
            dot: ''
        },
        {
            head: '',
            subHead: '安全性向上',
            desc: 'スマートシティは、インテリジェントなCCTV、緊急事態対応システム、予防的な安全対策などを導入して都市の安全性を向上させます。リアルタイムモニタリングとビッグデータ分析を通じて犯罪予防と緊急事態への対応能力が向上します。',
            dot: ''
        },
        {
            head: '',
            subHead: '経済的発展',
            desc: 'スマートシティの構築は新しい技術と産業を引き寄せ、現地経済を活性化させることができます。スマートシティ技術を開発し適用する企業は雇用を創出し、経済的な発展を牽引する役割を果たします。',
            dot: ''
        },
        {
            head: 'スマートシティの展望',
            subHead: '',
            desc: 'スマートシティの未来はより革新的で連携した都市環境に発展すると予想されます。<br/>ビッグデータ、人工知能、インターネット・オブ・シングスなどの先端技術が統合され、都市の運営を効率的に管理することにより、交通渋滞の緩和、エネルギー効率の向上、環境保護など、様々な面で都市の持続可能性が強調されるでしょう。市民は個別化されたサービスと高い参加度を体験し、都市生活の利便性が一層向上すると予想されます。また、グローバルなレベルでスマートシティの構築の重要性が高まり、世界中でこのような都市モデルを参考にしようとする動きが増えるでしょう。スマートビルディングや都市デザインの進化も期待され、市民はより安全で知的な都市での生活を楽しむことが見込まれます。',
            dot: ''
        },
        {
            linkText: 'Orbro Worldを体験する',
            link: 'https://home.orbro.io',
            head: '',
            subHead: '',
            desc: '',
            dot: '',
        }
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB端末を労働者や設備に取り付けて、位置追跡をリアルタイムで支援する製品です。',
                img: productImg.product.workerTracking.thumbnail
            },
            {
                // 追加の製品が必要な場合
            },
        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/jp/enterprise/medical/patient'
            },
            {
                img: medicalWaste.thumb,
                data: 'medicalWaste',
                link: '/jp/enterprise/medical/waste'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/jp/enterprise/logistics/asset'
            }
        ]
    }


}