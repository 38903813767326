import { ptzThumb } from "@assets/images/main/camera";

export default {

    default: {
        title: 'ORBRO, Location Tracking and Digital Twin Platform',
        key: 'Real-Time Location System, Indoor Positioning System, Asset Tracking System, Location Tracking, Wireless Tracking, RTLS Solutions, Bluetooth Tracking, RFID Tracking, RTLS Tags, Digital Twin, Asset Management, Asset Tracking, Digital Twin, Real-Time Location Tracking System, Indoor Location Determination System, Asset Tracking System, Location Tracking Technology, Wireless Tracking System, RTLS Solution, Location-Based Tracking, Bluetooth Tracking, RFID Tracking, RTLS Tags',
        desc: 'ORBRO offers smart solutions based on cutting-edge technologies such as UWB (Ultra-Wideband), RTLS (Real-Time Location System), beacons, access systems, and location system. Explore the future of Smart Cities using Digital Twins.',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: 'https://orbro.io/en/',
    },


    orbroOs: {
        title: 'Orbro OS | Digital Twin Space Management Platform',
        key: 'indoor tracking,rtls solutions,rtls,digital twin,indoor location tracking,iot rtls,indoor location tracking,location tracking,asset management,asset tracking,digital twin,serious disaster punishment act',
        desc: `The Digital Twin Platform ORBRO OS allows you to manage real-world spaces in virtuality. Through real-time location tracking, it manages assets and identifies people's locations, making it easy to manage spaces or obtain information.`,
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYkZbw7aOzICkoQi8HNk9s2d6VpUL4SVgujeUxCxLThYcyYLFnsiLZyVx8NwEWTiJIKKeTqFcuFZF4-0GJiF5k8YyZKXtU1iL8Qas-_DGIZPtogRYL6cnYimywaHxSkEe97rBb410rPXE2LymoexrdTIuXxKNli61FgKo4m_7JzUZXofk9HBe1CmLCLjcaJ4N8CusSji3C-D-W9XEtpZ_pdbROqqHehQbzJf3Lu7a9r04vRKR5XY6SlOsaHQXgGvyvf2EwBUoRIK1DKgjGg5Zzpi5ql-8O8MWh8pyV_NMWerNhx8Km30j95bwAyArdMfGv2x8l5jdUBxychGtfT5SECI01jw3yjOvdPPWgw9EfhlRlUPENdWxGAM8tN8fPTKID1cOaskpvwlCYjMeExZpPCYAmw8IWLq_KmhgyxcGK287oaey7vTTQ_AFc816pvys48-p5vA5qpp1-3VCFZEEVoF90jtIKxMU5dWbpeElJFg99AybHdS6Ka8QD1-oq7K-N2rat8r8uPyJCc-UGK2DfU301JI-BlolwsoqI9JH2eomiXOFFsCyPfbMImXi-ZM0r5R7DAY5j3VqdTVq3x2AUK_VMlLkFSCcOzlpo6_hUaTgAXdIGIdtArstnx9UYRPj1qdvT2zhTSXu43gxG5dnRsO8WVC4H6qMoiotvH-f-8UkXMZOSjV3C9TmUS3zLAzMIeP5_wTbaf7Pk8kT9Cf_h6NFml9VUIPZ-0AVWUfsGfBgqD2U_ToCKiOtKLRwy1d50veOBzfrvRlhAUVUyQj3B6Jf4cXxAgJyp7PnJUXUPmpYr3UVmjjyx3-7N1f1TAf5TpHSChyMrenXXD_BlVEYe2DX2o_oaZVmkZ4K3cPSUoaQsgcalbV-QMpW-cys5PIo1H5DRYxfH5WzurFBEEqXF0h_RJZD8uBz7u-nF3a_sGEXbSV1sIj-5oqlm0WHswVhSyFLy1AsEPsWKNGoN4vAOdFN3bvKfDXcj22FbtQv7oqpj35v0fiK0uFjxzUli6LA0lOcxxBTDX8K3TGhhmgZSGVIVN-RtMSnnYrzqGJ2Gdpfhs6Z_lrTrIL4IE9EiVsbWkZRlk9Ung0oFwlX9E0bpnv_oBa8cEsinqQms8LDrCXle81kS5jg_WHHUVoTBYawhdKqeBHbGj67EcTW5OfDNT5b-0ivlXNSO2rj2MfdTIXcPXHrFF0O1x3x4e_aZhiXwhxOdQpRn38jnYIu8XKjS4Ot07PLLE0sdyPidowRf1mGv3Fe3VZbO1cPCMUGmwJwDb1ow5AYNQsCpv9wLFrfkTfb9z8_lyUPmzo4t7o8N4difD44FvoqkfHmwLBBPkYXimpJ9jrIz25oS_B70pubRYpO-ZMwo1aQ2McWHBUd9oBRTr27FuBGJS8RIlZ5nxyeOeZ-Tp_kOeKWr8bcB5yrd96BSV8Cgb5P43XLS9SMCZrZ7oM6e3JipkAMJDP7kLBtvCET1199BhDG6TF44RHfOKXYImbDVIdgmSdsIw6nhcXUIDGaQCifrmOru2TFa0v2Wwz4K-=w2006-h1238',
        url: 'https://orbro.io/en/os',
    },

    os: {
        beacon: {
            title: 'Beacon RTLS OS | Digital Twin Platform, ORBRO',
            desc: '',
        },
        rtls: {
            title: 'RTLS OS | Digital Twin Platform, ORBRO',
            desc: '',
        },
        camera: {
            title: 'Camera RTLS OS | Digital Twin Platform, ORBRO',
            desc: '',
        },
        access: {
            title: 'Access System OS | Digital Twin Platform, ORBRO',
            desc: '',
        },
        sensor: {
            title: 'Industrial Sensor OS | Digital Twin Platform, ORBRO',
            desc: '',
        },
    },


    accessProduct: {
        title: 'Access System | ORBRO, Digital Twin PlatformDigital Twin Platform',
        key: 'Smart Access System, Access Control System, Access Management System, Smart Door Lock, Biometric Access System, RFID Access System, QR Code Access System, Remote Access System, Access Card System, Access Log Recording, Smart Access System, Access Control System, Access Management System, Smart Door Lock, Biometric Access System, RFID Access System, QR Code Access System, Remote Access System, Access Card System, Access Log Recording',
        desc: 'If you need a more convenient and secure access system, check out ORBRO\'s futuristic access system now.',
        img: 'https://lh3.googleusercontent.com/fife/APg5EObhIhVSc5l_YgEkV8ENDMUXJdxYXa-tWu2ob2CGj1T8JguKcr3Oh6Ywro_h3lU1aAEDTsm7Y33KDfdFlaO6IaSSuDYM6jnVKpOcIiAe0eCrjFHlDoXvptrgNbRQPpSce46Ge5-c77SpWj2XbOegeOH6WEQ54JhkhINdVTje6Mjr5QHnHYihwyoLoEY5brdu6vr7ku4EHibMaDRdaqhHwEnn_WCeTCmFTPbHm4KUoUp5saol3DT9810Q8H_Nmhgg9VvFYIn3JToTijZ5R2Vr2G7EHzbQmFSDSmkt60piBLbORnc52qceoWBGmSzhkMGsLF3sT3JdXCd2QPn-tC0MfwpaXWl5qm9-D4FMltwFgePmpiMZFDjy7O7pEwY-ScQ--sd2h6ptZnjjdO05ROX-m3AmdzXEEXvulJFRmx8ZyoryCIODzmathG0yJ0_vke5v6mE94-lyK8IFHisgptmGPzJG6m6-kkaK_EjlPxneaY8NDdT1cCxlyeAaHSCNgOps9RqcyrjM5BWr02C_P7cEswPoR9lT73V7zsWcTODPyUteFFSqvLjKrMZbQ269etOJjln97cW54V5y_3OplUpQ1Zt2-lppHEz_BnEgH_fsIfnyni9lbY1qP_-fdeXV6n3tlA3rNTSQZV5HLm5kzafsNPKWMzx3CKW-z7S6MxUpBGVCgA_fjiBt5YUoFQSwxmei1SlyV8zK-A3I5Yi-8DaPA5ty075aBejJ8r4fu0G_PvNx7dPUCjNgwfZOTgYXIXgKGZVv75WGo3qvXjP_BezP6LrsqC_Soid5hd1osgf6JmGsCSp7u0oPBw8KwjxUNHCWCIta4i0XFmK_6FkII6LV1KHbqmGiOoI75JSqjnneaPjueqkzBdZtOtDep3ynq0T_iTz_KsVWEHN9-zBjFAvintaIShl7gJRF__etrafFFB5wcImqgSJm7E5mO3CgRTRkaWfnI__wb3kx-6lby0oT2nII_K7cCJcE4fmn7xjaKW4Q6ql9_e9dHF7tri8sOpwP2AoBo7YgKD8QrNUPoPaGrUKpRLQwdnXChhEguPYTCZccG_agb8XmHBsrvRTXtMoGppbQM2j9KvMJ7VzAYAMpao7dxwFdgJwJMV_d08_UbF0k7eErXfDYFfODs6wHnTnNEVevxgq0sBog7O0-7M6CGTo-CnhP4I_gnMzeu-zHRbbjsK9cV9K7vlLnf8zhSGW9HkcZ659Gws5h13eSMKZjqqMQxZv5uRVqBCWOV3dhRbLHget2G3DfdjrJx-uFPWubEUAYfsp_vW6ZSJW6nKkY3r9WWDF-mea0DR1p72JASwZpyxzftA3-l-Lkz6Ruqk4uzF3MXpAIgsWMIfgBywUDTdxpkXEUmEWqa8CPqrfEDlNClOAOqypO2tLTeK3Fqn5ACl3jx6d3ScqvIMpqN3zW-J6HrLTpfK3cAJ1B9kxpqVzVuc0PwPYeQqOi-zFekLqzL08yNZgHmr_p7Ncq0dDn7IlkMIwfycNyiq6l9mlcQ9Q9Szk-Wze_Tcx-_rwXVmlocKD6lGRi=w2006-h1238',
        url: 'https://orbro.io/en/access',
    },


    rtlsProduct: {
        title: 'RTLS | ORBRO, Digital Twin Platform',
        key: 'Real-Time Location System, Indoor Positioning System, Asset Tracking System, Location Tracking, Wireless Tracking, RTLS Solutions, Bluetooth Tracking, RFID Tracking, RTLS Tags, Digital Twin, Asset Management, Asset Tracking, Digital Twin, Real-Time Location Tracking System, Indoor Location Determination System, Asset Tracking System, Location Tracking Technology, Wireless Tracking System, RTLS Solution, Location-Based Tracking, Bluetooth Tracking, RFID Tracking, RTLS Tags',
        desc: 'Manage indoor location tracking, asset management, and workforce management faster and more accurately. Plus, ORBRO system is easy to use.',
        img: 'https://lh3.googleusercontent.com/fife/APg5EObHma5EkyX2MCK6e2mdlbtvvAySBNi1Q6Sy0yCt3XGL-YOgvTEWmMgseI1eiYCBWZ6bCS-EHvV75JiwpvxCDhTKatr_wU7jMtlOwtex9EaelwxpicCYi7PgQNQIIe89wX_xVEPYAvj4dTBXP6aWIDMAio8cHy356CjoDnInZfZXqS-YvRCFK8gZBRMzv4fg1I1jHk0VTFtNo3GJEw7uBwbX5vckmGbYqiBPreTVy8b9t3iHACk5GKEEvGOH3EIN8L8g1V82KcZhn-iaqiRSpudUMASnFDyxdw-tEg7p8-qOAhypBhKaWsOFkTS_1Y3vi5hjRDHEKrNzeg7w9mDBoZdjbd3HaSR49-DFMFiaIjUjcdHeMGYNly4CBBoR0mOaNxvpFu2KLpVt683_8jSHK8YvRAZJCZFZlrUm5t1GRtOXjYbDgJ4scMmX9Agnjr24xOMup95HEUTukM9SFuxajBB8CTqfkX2g8seJ1H66X6zTjBqzzLdwQSRCvB82ItDZM1S4TCGMIQcO9gOGZM_QDFamVpM_RDBBrpMq2g5ErIYWBl3o7pZcOiAtlZOnfWCYagSLeUq4dvo963RZlRvA1XUMTAVVdTfmP8j3A0XcABOijSHaWSql7FtHiQuNE5kWlg91EZYLdnR5TmqD2ua88to5MCCiuJugKsEYTIA09FoIbPozNw3lJEvmKur3O2IRJmDjz5TA3UTHEHlLpBFACSyXXd70flICfMLlZIbkQqv9Ikzbell2A9-_cGDPw18kjU1i8doOYmKhg-fpQZhe0FRRBGtrZm2pEJruhGIjTYHfUrH5Q55qq1vMAFkb2OiCHNmhZ4IHhOL7iqZMyjuvXwMusC306K_yZFw5Md40a5K99WVQUhcXszyVefYM-tYlczn22kyfdM6hyCLQynNvs1bBNio2EdhXpe7tvwlpqQa9YJpnRfS8xS-UNd_3Tzlt5OlbXKZQFUL8NcGeeNyfYU9suciV7N2qFVw1go2Lf4HG9JlCNMgdIJG9gnM2-0bWM-E87vsxH4TvvgT8Y-8APLJlgZ5g0Y_jzTLaXWIEnn-9Z5Vw50MUNv2RTuKKzVlefLGlc24Y7bqebotC1PqdTAN5T4gWmJC33EsO7LmrZ1UpsoFhm3WzqTDTqZE_2MYpXGpNdm_FW3z44CvSgqwewSdmYW-O9kq9mTtjPGpKskUmQ7PlZJY1oKFy7f-vDHJE1PMGKunf6CtrA3pXnXNUls11PKhavf4RpmEnHzII85kSdHFKM_EJrVjVw6vb1qyW_QYLEQkUhM3PsLdc2WGbvGPiKRvIkxmeQ9cNzDPzyD8J-1tsibIhMrVrjzncEBP7r5iwtsqg5q8cZfwFJLFIF_EBh4V3JSqVHVj-pidYwaipmm0rxkhJGoppq5C3WrLM7R1uAiQBA3n1p1U2NAO2bNP3AcAsZUkuWuQbyKIm1kslrgY92ltA27GAc1crDXFbjE2M0AxBSYkKVYOcWpVRCC4FSv5itShGPqYGCd4rbtenPBRiMgtFRM32kdWGP1sWHx_g72cD=w2006-h1238',
        url: 'https://orbro.io/en/rtls',
    },
    cameraProduct: {
        title: "AI Tracking | Orbro, Digital Twin Platform",
        key: "Location tracking, asset management, asset tracking, warehouse management, indoor location tracking, RTLS, location tracking solutions, major disaster law enforcement, construction site safety, visitor path analysis, access system, 4th industrial revolution, smart factory, digital twin, beacon, Bluetooth, Real-Time Location System, Indoor Location Tracking, Asset Tracking, asset management, asset tracking, warehouse management, warehouse asset management, indoor location tracking, RTLS, location tracking solutions, construction site safety, visitor path analysis, access system, 4th industrial revolution, smart factory, digital twin, beacon, Bluetooth, BLE, UWB, IoT",
        desc: "Easily and quickly track indoor and outdoor pedestrian traffic and assets using cameras. Moreover, the Orbro system is simple.",
        img: ptzThumb,
        url: 'https://orbro.io/en/camera',
    },


    integration: {
        title: 'Integration System | Orbro, Specialist Platform in Positioning and Digital Twin',
        key: 'Internet of Things, Smart Home, IoT Devices, Connected Devices, IoT Solutions, IoT Security, IoT Analytics, Industrial IoT, IoT Platform, IoT Sensors, Internet of Things, Smart Home Technology, IoT Devices, Connected Devices, IoT Solutions, IoT Security, IoT Analytics, Industrial Internet of Things, IoT Platform, IoT Sensors, Kong-tech, Kong-tech',
        desc: 'We support efficient and safe operations through various integrated solutions such as delivery robots, unmanned loading/unloading systems, and disinfection systems. Orbro’s integrated system offers optimal performance through positioning technology and real-time monitoring.',
        img: '',
        url: 'https://orbro.io/en/integration'
    },

    iotProduct: {
        title: 'Internet of Things | ORBRO, Digital Twin Platform',
        key: 'Internet of Things, Smart Home, IoT Devices, Connected Devices, IoT Solutions, IoT Security, IoT Analytics, Industrial IoT, IoT Platform, IoT Sensors, Internet of Things, Smart Home Technology, IoT Devices, Connected Devices, IoT Solutions, IoT Security, IoT Analytics, Industrial IoT, IoT Platform, IoT Sensors',
        desc: 'Safety bell for quick reporting in emergency situations. If you have any concerns about your safety, check out ORBRO\'s safety bell.',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYCH16slHwmaQG331-kV_CkBXnlfH1G9KrQOsfFjNqI7fC1rCmzgUg01Dizxv1tp9RuOIHrNhWUHRmxn0KTRaH1025_7yPSqXctHcfoKasdRVOxqYUom3UzM6ontDOvbiJwx021PbutfqbQZR8gLoT2Btt487SFAu-oBqfMkEaBftr6vSecMBx11J_KvKfDuVp79oWFJjP4HYEc9wJoel4oqj_B2CpPolo0ykC5oJ4MFipkMmGoCuDzxt-mKnQHqiGptEGd0eWtoriaBDaGGeDj18v3SSCdfRJQ9bBXN5M3YJRg2yBJVtFdlboqFrKw4CIvK0LqHyr_eZbrBFj56A_u9uLpgMoKT2azM0XOCFY4eKvjOUG26lUuFO-6TuATsfMAiHJs0pcKOEQfkj1O8ewSyT2Wn14K032rljAuc9rnAElCgFf0plndW4RmVaQAAEZeK3LFNctWNVdUvCz54tPSKulB5Om_n8r_pn-IgsU94nbquRGFkjfew_XBXhkNv5Moq90hwIyXy3WxBg0rAB8u3gkXB0EHJDBBqTOcr_-nE4F_wZ-BxsyMUURlg0m6vk23XftGlrZ1c-57Wdtgwk8YfIvK9GHsk9CwgEZ7e9JUTgb23if0YuTzlAob7bGBJGNrWbEOt35VKMT8BUzKT_lvQCALsHexYk-YO9LVzbHoAdwbYYqIyCg41xvkWltQu_w9Racs4ORCFBkfke1gsOvy51IFkmE2hqqrivGO8YLzNQ2zJwCaOw9Wsb0Cqj8PN3imRwa6VJ2IMENOkqjipW9hvnbn-U-Nm7IMjKTmCE5z-_I84D0-yJJLiKtfo3a7PJl0gCgEEBQTFseDbtyujfFv8BnW0Xvbhn6WsHIYiRmrx8HpB_8lTDuFFwFPan_SfMzoAicjyLHUQLeOSdYPhRNKbZ9Ah1i9ovTjy9rRqpF8XuHkiQPdcnOEoqmzlCdnXc0hFzORQZ6koWMG_nH8Car64KYjSp8uaPyXgKwPGRXc2F41TWuE1-kU0gjPssZAj2qNxkMVHom-lYdZ7dIabaFsmKGpQbTnafugm4e2lNmULTpFJQkzQnN25Y180bmsvRzCG-NroyymzpZGaicJCGjRKcKDMZ754a10zT3o2jAP11AwZVB3Em3mk8IRU8tMKja2TQItdDa0PXI-naiwGAbBgyo55z4gGfov-Xo-NWXbtBVMu3kZd2UYLglsjT_adJWLbPAw1Eja758K9PhE1l_9qQgBE7FNH5bYnxUD_ZvfZm_1-sP8uVnKbrgf2qcWQEnl9yaSRRWlCXwBN6HUDvMo1sM6L-t8oH-IJS40kNUQBEwfbjNXkWAFjf-QPzyvXaHrS_7qIS4-uFvl_f_lF08pp_INZIcfVz9dl2Ghi2bZ0lgUdwmxS8VeZYqIy-yFV1aImHAtYpQSV0TUw9Gvrqeh7XHSYQgEmXB5t6ky0FWmrZxsDfJ-KTbrFsZrl7Hsffa-zGtfUb4WfSmwfyfp2MamCB6S9lc9UThvhkKorxsD98tB1uDyy4Sak8o3jDNZw54krj5u=w2006-h1238',
        url: 'https://orbro.io/en/iot',
    },

    home: {
        title: "ORBRO, Location Tracking and Digital Twin Platform",
        key: "Orbro, ORBRO, Location Tracking, Asset Management, Asset Tracking, Digital Twin, RTLS, IPS, GPS, GIS, MES, Major Disaster Act, Beacon, Bluetooth, Ultra-Wideband, BLE, UWB, IoT, Internet of Things, Indoor Positioning, Access, Asset, Digital Transformation, DX, 4th Industrial Revolution, Smart Factory, Smart City, RTLS Solutions",
        desc: "ORBRO, a data platform that vividly recreates reality into the digital world."
    },

    tech: {
        uwb: {
            title: "UWB Positioning and Tracking Technology | ORBRO",
            key: "Location Tracking Solution, Indoor Positioning, GIS, UWB, RTLS, RTLS Solutions, UWB Technology, Positioning Technology, Technology Solutions, UWB Tags, TDoA, TWR, AI Technology",
            desc: "UWB (Ultra-Wideband) is a wireless communication technology that uses a very wide frequency bandwidth to transmit very powerful signals in a very short period of time."
        },
        ble: {
            title: "BLE Positioning and Tracking Technology | ORBRO",
            key: "Location Tracking Solution, Indoor Positioning, GIS, UWB, RTLS, RTLS Solutions, UWB Technology, Positioning Technology, Technology Solutions, UWB Tags, TDoA, TWR, AI Technology",
            desc: "BLE (Bluetooth Low Energy) is a wireless communication technology that consumes less power and has lower data transfer rates. It is commonly used in small devices and low-power sensors and can also be used with small transmitters like beacons."
        },
        twr: {
            title: "TWR(Two-Way Ranging) Positioning Technology | ORBRO",
            key: "Location Tracking Solution, Indoor Positioning, GIS, UWB, RTLS, RTLS Solutions, UWB Technology, Positioning Technology, Technology Solutions, UWB Tags, TDoA, TWR, AI Technology",
            desc: "TWR (Two-Way Ranging) technology is a positioning technology that measures the round-trip time of signals between transmitters and receivers to calculate distance."
        },
        tdoa: {
            title: "TDoA(Time Difference of Arrival) Positioning Technology | ORBRO",
            key: "Location Tracking Solution, Indoor Positioning, GIS, UWB, RTLS, RTLS Solutions, UWB Technology, Positioning Technology, Technology Solutions, UWB Tags, TDoA, TWR, AI Technology",
            desc: "TDoA (Time Difference of Arrival) technology is a positioning technology that calculates the position of a receiver by measuring the time difference of signal arrival from multiple transmitters."
        },
        stereo: {
            title: "Tracking Technology Using Stereo Vision | ORBRO",
            key: "Vision, Deep Learning, Location, Depth Estimation, Position Inference, Stereo Vision, RTLS Solutions, RTLS Technology",
            desc: "Stereo Vision is a technology that uses paired cameras to perceive objects in 3D."
        },
        mono: {
            title: "Tracking Technology Using Monocular Vision | ORBRO",
            key: "Vision, Deep Learning, Location, Depth Estimation, Position Inference, Monocular Vision, RTLS Solutions, RTLS Technology",
            desc: "Monocular Vision is a computer vision technology that uses a single-camera to acquire and analyze images."
        }
    },

    product: {
        title: "Product Introduction | ORBRO, Digital Twin Platform",
        key: "Beacon, Indoor Location Tracking Device, UWB, BLE, RTLS, Terminal, Orbro, Orbro Products, GIS",
        desc: "Discover a variety of Orbro products."
    },

    blog: {
        title: "Orbro Blog | ORBRO, Location Tracking and Digital Twin Platform",
        key: "Orbro, Orbro Blog, RTLS",
        desc: "Please check out the blog posts related to RTLS.",
        url: 'https://orbro.io/en/blog'
    },

    company: {
        title: "Creating a Dynamic Virtual World | ORBRO, Digital Twin Platform",
        key: "Digital Twin, DT, Metaverse, Public Information, 3D World, Orbro, Industrial Platform, Industrial Metaverse Platform, ORBRO, Access System, Air Management, UWB, RTLS, Technology Solutions, Location Tracking Solutions, Technical Sales, Technology Solutions, People & Technology",
        desc: "Orbro is a data platform that transforms over 200 zettabytes of data into 3D visualization, connecting innovative data sources.",
        url: 'https://orbro.io/en/company'
    },

    request: {
        title: "Contact ORBRO | ORBRO, Digital Twin Platform",
        key: "Orbro, ORBRO, Digital Twin, RTLS, IPS, GPS, GIS, MES, Major Disaster Act, Beacon, Bluetooth, Ultra-Wideband, BLE, UWB, IoT, Internet of Things, Location Tracking, Indoor Positioning, Access, Asset, Digital Transformation, DX, 4th Industrial Revolution, Smart Factory, Smart City, RTLS Solutions",
        desc: "If you have any questions about indoor positioning, asset tracking technology, or related topics, feel free to contact us.",
        url: 'https://orbro.io/en/request'
    }
    ,

    platform: {
        title: "Creating an Immersive Virtual World | ORBRO, the Start of Digital Transformation",
        key: "ORBRO, digital twin, RTLS, IPS, GPS, GIS, MES, major disaster punishment law, beacon, Bluetooth, ultra-wideband, UWB, IoT, Internet of Things, location tracking, indoor positioning, access, asset, digital transformation, DX, 4th industrial revolution, smart factory, smart city, RTLS solution",
        desc: "Experience faster indoor location tracking and easily manage more assets. The ORBRO system is powerful, yet simple and convenient.",
    },
    pricing: {
        title: 'Quote Request | ORBRO, Digital Twin Platform',
        key: 'Location Tracking, Camera Location Tracking, AI Camera, Access Control Terminals, Access Control Systems, Automatic Doors, BLE, UWB, Industrial Sensors, LoRa Gateways, Air Quality Monitors, Carbon Monoxide Monitors, Emergency Bells, Beacons, Industrial Beacons, Real-time Location Tracking, Construction Safety Management, Logistics Inventory Management, Asset Management, Patient Location Tracking, Manufacturing Process Management, Smart Solutions, Visitor Analysis, ORBRO, Kongtech, orbro, RTLS',
        desc: 'We are a reliable partner offering free quotes for a variety of products and services. We provide customized quotes based on customer requirements, prioritizing price transparency and customer satisfaction. Through a quote request, discover efficient and cost-effective solutions and obtain the information you need to proceed with your project.',
        url: 'https://orbro.io/en/pricing/1'
    },
    schedule: {
        title: 'Book a Consultation | ORBRO, Digital Twin Platform',
        key: 'Location Tracking, Camera Location Tracking, AI Camera, Access Control Terminals, Access Control Systems, Automatic Doors, BLE, UWB, Industrial Sensors, LoRa Gateways, Air Quality Monitors, Carbon Monoxide Monitors, Emergency Bells, Beacons, Industrial Beacons, Real-time Location Tracking, Construction Safety Management, Logistics Inventory Management, Asset Management, Patient Location Tracking, Manufacturing Process Management, Smart Solutions, Visitor Analysis, ORBRO, Kongtech, orbro, RTLS',
        desc: 'We are a reliable partner offering free quotes for a variety of products and services. We provide customized quotes based on customer requirements, prioritizing price transparency and customer satisfaction. Through a quote request, discover efficient and cost-effective solutions and obtain the information you need to proceed with your project.',
        url: 'https://orbro.io/en/schedule'
    }



}