import { StyledLabel } from "@components/Text";
import { useTheme } from "@emotion/react";
import React from "react";
import { Trans } from "react-i18next";
import { StyledTechCard, StyledTechCardLogo, StyledTechCardParagraph, StyledTechCardPolicy, StyledTechCardPolicyItem, StyledTechCardTitle } from "./TechCard.style";

interface TechCardProps {
  title: string,
  description: string,
  image: string,
  count : number,
  policy: string[]
}

const TechCard = (props: TechCardProps) => {
  const { title, description, image, policy, count } = props;

  // Emotion
  const theme = useTheme();

  return (
    <StyledTechCard desktop={6} tablet={4} mobile={4}>
      
      <div>
        <StyledTechCardLogo src={image} alt="TechCardLogo" />
        <StyledTechCardTitle size="xsmall"><Trans i18nKey={ title }/></StyledTechCardTitle>  
        <StyledTechCardParagraph size="medium">
        <Trans i18nKey={ description }/>
        </StyledTechCardParagraph>
      </div>
      
      
      
      <StyledTechCardPolicy>
        <StyledLabel size="xsmall">
          <Trans i18nKey={"company.ourTechnology.policy"} />
        </StyledLabel>

        { policy.map((item, index) => (
          <StyledTechCardPolicyItem size="xsmall" color={theme.CONTENT_TERTIARY} key={`${title}-policy-${index}`}>
            <span>·</span> <Trans i18nKey={ `company.ourTechnology.items.${count}.policy.${index}` }/>
          </StyledTechCardPolicyItem>
        ))}
      </StyledTechCardPolicy>
      
    </StyledTechCard>
  );
};

export default TechCard;