export default {
  titleBanner: {
    subtitle: 'UWB | Ultra - Wideband',
    title: 'UWB(Ultra - Wideband)란?',
    description: '*UWB는 무선 통신 기술 중 하나로, 매우 넓은 주파수 대역폭을 사용하여 매우 짧은 시간 동안 매우 강력한 신호를 전송하는 기술입니다. <br/> UWB는 위치 추적 및 레이더 애플리케이션 등에서 사용되는 높은 정밀도와 데이터 전송 속도를 제공할 수 있습니다.'
  },
  tech: {
    title: 'UWB(Ultra - Wideband)란?',
    text: 'UWB(Ultra Wideband)는 무선 통신용 단거리 RF 기술로, 사람, 기기, 자산의 위치를 이론적으로도 뛰어난 정확도로 감지할 수 있습니다. 블루투스와 와이파이 등 다른 통신 프로토콜과 마찬가지로, UWB는 라디오 파장을 통해 장치 간 데이터를 전송할 수 있습니다. 이를 위해 짧은 나노초 펄스를 사용하여 "울트라 와이드" 범위의 주파수에서 통신합니다. <br/><br/> UWB 기술은 넓은 주파수 스펙트럼(최소 500 MHz 또는 중심 주파수의 20%)을 가로지르는 십억 개의 라디오 펄스를 나노초마다 패턴으로 전송합니다. 이 신호는 송신기에서 수신기 또는 수신기 간에 전송됩니다. 수신 장치는 들어오는 패턴을 분석하고 데이터로 변환합니다. 이를 통해 장치들은 짧은 거리 내에서 빠르게 데이터를 전송할 수 있을 뿐만 아니라, 이 UWB 신호는 장치의 위치를 정확하게 감지하는 데에도 사용될 수 있습니다. 이를 통해 UWB 기능을 탑재한 장치(스마트폰 또는 센서 및 앵커와 같은)는 다른 스마트폰이나 자산 추적 태그와 같은 송신 장치를 정확하게 위치시키고, 특정 응용 프로그램에서 위치 인식 통신과 서비스를 가능하게 합니다. <br/><br/> 최신 스마트폰 및 다른 기술에서 최근 채택되어 새로운 첨단 기술처럼 보일 수 있지만, UWB 기술은 수십 년간 적용되어 왔습니다. 많은 해 동안 그 사용은 제한되거나 군사 레이더 및 통신 응용 분야에서 "펄스 라디오"라는 이름으로 대개 볼 수 있었습니다. 2002년 FCC에서 라이선스 없는 사용을 승인한 이후, UWB 기술은 혁신과 발전을 거듭하며, 혁신적인 위치 기반 기능을 해제하는 시작점이 되었습니다.'
  },
  selection: {
    title: 'UWB의 채택 증가',
    text: '다수의 대형 업체들이 UWB의 다양한 가능성을 깨달았으며, UWB 탑재 솔루션을 개발하거나 출시하기 시작했습니다. 그 중에서 가장 주목할 만한 업체 중 하나인 Apple은 iPhone 11 출시와 함께 새로운 U1 초광대역 칩을 탑재하여 UWB 기술을 구현하기 시작했습니다. 삼성과 같은 다른 기업들도 이에 따라 일부 최신 스마트폰 및 기타 장치에 UWB 기술을 구현하기 시작했습니다. 구글도 안드로이드 기기에서 UWB를 사용하는 애플리케이션을 가능하게하는 API 개발에 착수하고 있습니다. <br/><br/> 이제는 다양한 종류의 장치에 내장할 수 있는 충분히 작은 하드웨어 구성 요소를 갖춘 비용 효율적인 선택지입니다. NFL은 심지어 선수들의 실시간 위치와 움직임을 추적하기 위해 초광대역 기술을 사용합니다. 선수들의 어깨 패드에 UWB 칩을 장착하여 리그가 경기 도중 선수들이 어떻게 움직이는지 분석할 수 있습니다. NXP와 폭스바겐은 UWB를 자동차에 사용하여 보다 안전하고 편리한 차량 경험을 제공하는 가능성을 탐구하고 있습니다. 이와 함께, UWB 기술 개발의 선두주자들 중에는 UWB 얼라이언스와 FIRA를 비롯한 컨소시엄 및 다양한 UWB 하드웨어 업체들이 있습니다. 이들은 특정 사용 사례에 따라 다양한 UWB 칩, 앵커 및 태그를 제공합니다. 어떤 전문가들은 UWB 기술이 곧 블루투스와 와이파이만큼 보편적으로 사용될 것이며, 일부는 BLE를 대체하여 단거리 통신 및 위치 확인의 주요 표준으로 자리 잡을 것으로 예측합니다.'
  },
  feature: {
    title: 'UWB의 특장점',
    text: 'UWB 기술은 RF 기술의 새로운 표준을 빠르게 설정하게 된 많은 독특한 이점을 갖고 있습니다. 이 기술은 짧은 거리에서 매우 높은 데이터 전송 속도를 제공하며 실시간으로 정확한 위치를 파악할 수 있습니다. UWB 기술은 3.1에서 10.6GHz의 매우 넓은 주파수 스펙트럼에서 고대역폭으로 작동합니다. 또한 전력 소비가 매우 적어 추적 태그와 같은 저비용 및 효율적인 하드웨어 옵션을 가능하게 합니다. 이러한 태그는 코인 셀 배터리로 구동되어 여러 해간 충전이나 교체 없이 작동할 수 있습니다. <br/><br/> UWB가 위치를 매우 정확하게 감지할 수 있는 이유는, 디바이스 간의 시간-거리 측정(Time-of-Flight, ToF)을 통해 거리를 기반으로 측정하기 때문입니다. 이를 위해 무선 파형이 한 디바이스에서 다른 디바이스로 전송될 때 얼마나 걸리는지 계산합니다. 이 방식은 보통 짧은 거리에서만 작동하지만, 최적의 조건과 배치에서는 UWB 신호의 위치를 50cm 이하의 정확도로 판별할 수 있으며 매우 낮은 대기 시간을 보여줍니다. 반면, BLE 및 Wi-Fi와 같은 다른 기술은 일반적으로 받은 신호 세기 지표 (RSSI)를 사용하여 위치를 결정하며 이 경우 정확성은 미터 단위로 제한될 수밖에 없습니다. <br/><br/> UWB 신호의 저전력 송신과 넓은 주파수 스펙트럼은 주변의 좁은 대역 기술과 거의 간섭하지 않도록 합니다. 또한, UWB는 "노이즈 바닥에서도 보이지 않는" 것으로 나타나며, 좁은 대역 RF 기술과 공존하기에 좋은 선택입니다. 이러한 독특한 특성들은 UWB가 위치 기반 및 단거리 통신 응용 분야에서 프리미엄 실내 위치 기술로 급속히 부상하는 이유입니다.'
  },
  howItWorks: {
    title: 'UWB 포지셔닝의 동작 방식',
    text: 'UWB는 ToF를 통해 위치를 결정할 수 있게 합니다. 이는 신호가 장치 간에 이동하는 데 걸리는 시간을 계산하여 송수신기 간의 거리를 정확하게 측정할 수 있게 합니다. 일부 시나리오에서는 장치 위치의 X, Y 및 Z 좌표도 감지할 수 있으며, 이를 통해 UWB가 제공하는 위치 지정에 추가적인 차원을 더할 수 있습니다. 사용 사례나 애플리케이션에 따라 ToF 계산 방법이 달라질 수 있습니다. <br/><br/> UWB 위치 측정에서는 Time Difference of Arrival (TDoA)와 Two-Way Ranging (TWR)라는 두 가지 기본 기술을 사용할 수 있습니다.',
    items: [
      {
        title: 'Time Difference of Arrival (TDoA)',
        description: 'TDoA는 실내 공간 전체에 고정된 위치에 배치된 UWB 앵커 또는 센서를 사용합니다. 이 센서들은 추적 태그와 같은 UWB 디바이스를 감지하고 위치를 파악합니다. 고정된 앵커는 정확하게 동기화되어 동일한 클럭에서 작동해야합니다. UWB 태그 또는 기타 디바이스는 정기적으로 신호를 전송합니다. 이러한 신호는 통신 범위 내의 모든 앵커에 의해 수신되고 앵커에서 타임 스탬프가 찍힙니다. 그런 다음 모든 타임 스탬프 데이터가 중앙 IPS 또는 RTLS로 전송됩니다. <br/><br/> 위치 엔진은 각 앵커의 데이터 및 도착 시간 차이를 분석하여 쌍곡선을 그리고 쌍곡선이 만나는 교점을 이용해 태그의 좌표를 계산합니다. 이 좌표는 공간의 실내 지도상에 기기의 위치를 시각화하거나 특정 응용 프로그램에 따라 다른 용도로 활용될 수 있습니다.'
      },
      {
        title: 'Two-Way Ranging (TWR)',
        description: 'TWR는 두 기기 간의 양방향 통신을 이용하여 거리를 측정하는 기술입니다. 예를 들어, 스마트폰과 같은 기기가 서로 가까이에 있을 때, 두 기기는 서로 거리를 측정하기 시작합니다. 두 기기 사이에서 신호가 전달되는 데 걸리는 시간은 빛의 속도와 곱해져 상대적인 위치를 결정합니다. 이 기술은 종종 위치 인식 통신을 가능하게 하기 위해 사용됩니다. <br/><br/> TWR는 하나의 기기와 다른 기기 사이의 거리를 감지하기 위해 양방향 통신을 사용하는 반면, 이 기기들 간의 결정된 위치는 특정한 응용 분야에 따라 활용됩니다. TWR는 고정 앵커와 UWB 기기에서도 사용할 수 있지만, TWR 프로세스는 한 번에 하나의 측정 파트너만 사용하여 기기의 위치를 파악할 수 있습니다.'
      }
    ]
  },
  accuracy: {
    title: 'UWB 포지셔닝은 얼마나 정확합니까?',
    text: 'UWB는 ToF를 통해 감지할 수 있는 빠른 나노초 펄스와 다른 RF 전송과의 제한된 간섭 덕분에, 최적의 조건과 배치에서 50cm 미만의 정확도로 정확한 실내 위치 추적이 가능하며, 실시간 결과로 디바이스의 움직임과 동작도 빠르게 추적할 수 있습니다. <br/><br/> 다른 실내 위치 기술과 비교했을 때, UWB는 월등한 위치 정확도를 제공합니다. 다른 표준은 일반적으로 위치를 결정하기 위해 수신 신호 강도 지표 (RSSI)를 활용하며 BLE (< 5m) 및 Wi-Fi (< 10m)와 같은 정확도로만 동작할 수 있습니다. 일부 UWB 기술은 방향 탐지를 통해 보다 정확한 위치 측정도 가능하며, 이는 수신되는 신호의 각도를 측정할 수 있는 다중 안테나 장치를 필요로 합니다.'
  },
  range: {
    title: 'UWB의 통신 범위는 얼마입니까?',
    text: 'UWB는 장치의 위치를 200미터 이하의 범위에서 감지할 수 있습니다. 그러나, 대개 1-50미터의 짧은 거리에서 가장 효과적으로 작동하며, 장치나 앵커 간에 시야가 확보된 경우 가장 좋은 결과를 얻을 수 있습니다. 짧은 거리에서 UWB는 매우 정확하고 빠르며 안전한 통신을 제공하며, 최소한의 간섭을 유지할 수 있습니다.'
  },
  difference: {
    title: 'UWB는 다른 포지셔닝 기술과 어떻게 다릅니까?',
    text: '각 RF 표준마다 고유한 특성과 이점이 있으며, 개별 요구 사항, 예산, 시설 및 특정 위치 기반 사용 사례에 따라 적합한 옵션이 될 수 있습니다. UWB와 다른 기술 사이의 가장 중요한 차이점은 넓은 주파수 스펙트럼과 제공할 수 있는 정확도 수준입니다. UWB의 정확도는 절대 위치와 실시간 이동이 중요한 자산 추적 시나리오와 같은 고급 실내 위치 기반 사용 사례에서 특히 효과적인 옵션입니다.',
    items: [
      {
        title: 'UWB vs Bluetooth',
        description: 'UWB와 BLE는 많은 공통점을 가지고 있습니다 - 저전력, 저비용, 자산 추적 기술의 강점 등. 그러나 UWB는 Bluetooth보다 훨씬 우수한 정확도를 제공할 수 있습니다. 이는 UWB의 ToF를 통한 정확한 거리 기반 위치 파악 방법 때문입니다. BLE 위치 기술은 일반적으로 RSSI를 통해 장치를 위치시키며, 장치가 비컨이나 센서에 대해 강한 신호 또는 약한 신호를 전송하는지에 따라 상당히 낮은 수준의 정확도를 제공합니다. BLE는 UWB보다 훨씬 짧은 범위와 데이터 속도를 가지며, 신호 간섭에 더 취약합니다. Bluetooth는 거대한 에코시스템을 갖추고 있으며, 매우 인기 있는 위치 기술 중 하나입니다. 많은 무선 장치에서 사용되며 BLE 비컨과 같은 유연한 하드웨어 옵션을 쉽게 구현할 수 있습니다.'
      },
      {
        title: 'UWB vs Wi-Fi',
        description: "Wi-Fi는 우리 기기와 실내 공간에서의 광범위한 사용으로 인해 실내 위치 기술에서 핵심 RF 기술이 되었습니다. 고급 위치 기반 시나리오에서는 Wi-Fi의 정확도와 유연성이 제한될 수 있습니다. 이러한 고급 응용 분야에서는 높은 정확도가 필요하며, 이를 위해서는 UWB 기술이 뛰어납니다. Wi-Fi는 거리가 아닌 신호 강도를 측정하기 때문에 UWB에 비해 정확도가 떨어지며, 더욱 간섭에 영향을 받기 쉽습니다. 반면 UWB는 강력한 면역성을 가지므로 간섭에 대한 문제가 적습니다. 또한 UWB는 적은 전력을 필요로 하므로, 코인셀 배터리로 구동되는 자산 추적 태그와 같은 유용하고 저렴한 도구를 만들 수 있습니다. 그러나 Wi-Fi를 지원하는 다양한 기기와 액세스 포인트와 같은 기존 인프라를 활용할 수 있는 이점이 있기 때문에, 높은 정확도가 필요하지 않을 때에는 매우 중요한 실내 위치 기술이 됩니다."
      }
    ]
  },
  advantage: {
    title: 'UWB의 주요 장점',
    items: {
      accuracy: {
        title: '정밀 정확도',
        description: 'UWB 기술은 센티미터 수준의 정확도를 제공하여 고급 위치 기반 응용 분야에서 우수한 선택지가 되며, 이전에는 불가능했던 다양한 기능을 무한히 제공합니다.'
      },
      position: {
        title: '실시간 포지셔닝',
        description: 'UWB 기술은 매우 빠른 위치 결정 결과를 제공합니다. 나노초 단위의 빠른 통신으로 진정한 실시간 실내 위치 결정 및 추적이 가능하며, 지연 시간이 매우 낮고 초당 100회 이상의 업데이트 속도로 즉각적인 인력, 자산 또는 차량의 위치, 움직임 및 동작을 감지할 수 있습니다.'
      },
      security: {
        title: '신뢰성 및 보안',
        description: '다른 무선 전파와 거의 간섭 또는 중첩되지 않아 매우 신뢰성 높은 통신이 가능한 것이 UWB의 장점입니다. 수신 장치의 정확한 위치를 사용하여 UWB는 작은 거리에서 기기 간 매우 안전한 통신에도 사용될 수 있습니다.'
      },
    }
  },
  useCase: {
    title: 'UWB 포지셔닝 사용 사례',
    description: 'UWB의 정확도는 특히 고도의 정밀도가 필요한 고급 실내 위치 결정 사용 사례에서 효과적인 옵션입니다. <br/> UWB는 미래의 RTLS 기술을 가능케하며, 다양한 자산 추적 응용 분야에서도 뛰어난 성능을 발휘합니다. <br/> 다음은 현재 UWB가 사용되고 있는 사용 사례 및 응용 분야와 미래에 사용될 수 있는 분야입니다.',
    card: {
      physical: {
        title: '물리적 자산 추적',
        description: 'UWB의 정밀한 정확도는 자산 추적을 위한 중요한 RF 기준입니다. 많은 산업 분야의 조직은 UWB의 정확성, 빠른 속도, 신뢰성을 활용하여 키 자산 및 장비의 실시간 위치 및 상태를 뛰어난 정밀도로 추적할 수 있습니다.',
        items: [
          { 
            name: '기업', 
            description: '대규모 기업 캠퍼스 및 시설 전체의 자원, 자산 및 장비를 명확하게 파악하여 생산성과 자원 할당을 향상시킬 수 있습니다.'
          },
          { 
            name: '의료', 
            description: '자산 추적 기능을 활용하여, 인공호흡기나 휠체어와 같은 중요한 장비의 정확한 위치를 빠르게 찾고 추적할 수 있도록 합니다.'
          },
          { 
            name: '창고관리', 
            description: '큰 시설물 내에서 장비, 도구 및 재고물의 위치를 찾아내는 데 도움이 되며, 특히 재고물의 정확한 위치 파악이 필요한 경우에 유용합니다.'
          },
        ]
      },
      employee: {
        title: '직원 및 인사 추적',
        description: '조직은 UWB의 강력한 정확성을 활용하여 직원 및 인원의 정확한 위치를 파악하여 위치 인식 애플리케이션을 구동할 수 있습니다.',
        items: [
          { 
            name: '직원 안전', 
            description: '비상 상황 및 대피 시 직원 또는 인원의 정확한 위치를 빠르게 파악해야 하는 경우 UWB의 고정밀 정확성이 매우 중요합니다.'
          },
          { 
            name: '작업장 준비', 
            description: 'UWB 직원 태그 및 배지를 사용하여 정밀한 접촉 추적을 가능하게 하는 도구를 활용하여, 실내 공간에서 질병 전파를 예방하고 완화하는 데 도움이 되는 프로토콜 및 규제 요구 사항을 지원하며, 물리적 거리두기 준수 인식, 효율적인 위생 지시 등을 제공합니다.'
          },
        ]
      },
      location: {
        title: '위치 서비스',
        description: 'UWB를 활용하여 조직은 사람과 장치의 정확한 위치를 활용하여 다양한 상호작용 및 메시징 등을 용이하게 할 수 있는 스마트 빌딩을 구축할 수 있습니다.',
        items: [
          {
            name: '근접 메세지',
            description: 'UWB 실내 위치 추적 기술을 도입하여 고객 및 자산 근처의 목적지를 식별하고 이 데이터를 활용하여 쿠폰, 마케팅 캠페인, 인근 관광지 등 하이퍼로컬 컨텐츠를 포함한 직접적인 고객 상호작용을 유도하는 참여형 고객 경험을 제공할 수 있습니다.'
          },
          {
            name: '지오펜싱',
            description: '실내 공간의 다양한 영역 주변에 가상 지리적 경계를 생성하여 사용자가 지정된 영역에 진입, 이탈 또는 머무를 때 특정 작업을 실행할 수 있도록 합니다.'
          }
        ]
      },
      navigation: {
        title: '실내 내비게이션',
        description: 'UWB 위치 측정 기술의 고정밀성을 활용하여, 실내 내비게이션 및 길 찾기 기능을 통해 공간을 즉시 익숙하게 하고 탐험 가능하게 만듭니다.',
        items: [
          {
            name: '증강 현실(AR)',
            description: 'UWB의 실내 전역에서 정확한 위치 제공 능력은 조직이 하이퍼로컬하며 몰입감 있는 증강 현실 경험을 제공하는 새로운 기회를 창출할 수 있게 합니다.'
          },
          {
            name: 'Blue-dot Wayfinding',
            description: '블루 닷(Blue dot) 경험을 통해 사용자가 실내 공간에서 자신의 위치를 정확하게 파악하며, 차례대로 안내 및 길 찾기를 가능하게 합니다. 기업, 의료, 소매, 호텔, 교통 등 여러 산업에서 건물 내에서 특히 정확한 실내 체험을 제공할 수 있습니다블루 닷(Blue dot) 경험을 통해 사용자가 실내 공간에서 자신의 위치를 정확하게 파악하며, 차례대로 안내 및 길 찾기를 가능하게 합니다. 기업, 의료, 소매, 호텔, 교통 등 여러 산업에서 건물 내에서 특히 정확한 실내 체험을 제공할 수 있습니다'
          }
        ]
      },
      business: {
        title: '비즈니스 인텔리전스',
        description: 'UWB는 놀랄 만큼 정확한 위치 데이터를 수집할 수 있으며, 이를 강력한 인사이트로 변환할 수 있습니다.',
        items: [
          {
            name: '분석 및 비즈니스 인텔리전스',
            description: '자산, 인원 및 장치의 위치와 이동을 파악하고 시간이 지남에 따라 이 위치 데이터를 분석하여 방문자가 실내 공간과 상호작용하는 모습을 파악함으로써, 보다 지능적이고 정보화된 비즈니스 결정을 내릴 수 있는 시야를 제공합니다.'
          }
        ]
      },
      b2b: {
        title: '소비자 기반 및 B2B 사용 사례',
        description: 'UWB는 단거리 통신과 위치 정보에 대한 게임 체인지를 가능하게 하기 위해 많은 혁신적인 소비자 기반 사용 사례를 개척하고 있습니다',
        items: [
          { name: '스마트 빌딩 및 출입 통제' },
          { name: '피어 투 피어 통신' },
          { name: '스마트 의료기기' },
          { name: '차량 출입 통제' },
          { name: '안전한 위치 기반 모바일 결제' },
          { name: '스마트홈' }
        ]
      }
    }
  }
}