import React from "react";
import { Trans } from "react-i18next";
import { StyledAdvantage, StyledAdvantageItemIcon, StyledAdvantageItems } from "./AdvantageWhite.style";
import { Spacing } from "@pages/CommonStyle/common.style";
import { StyledHeading, StyledParagraph } from "@components/Text";
import { StyledAdvantageItem } from "./Advantage.style";

interface AdvantageProps {
  title: string,
  items: AdvantagePropsItem[],
}

export interface AdvantagePropsItem {
  icon: string,
  title: string,
  description: string
}


const StereoAdvantage = (props: AdvantageProps) => {
  // Props
  const { items } = props;

  // Translation

  return (
    <StyledAdvantage>

      <StyledAdvantageItems>
        { items.map((item, index) => (
          <StyledAdvantageItem key={`advantage-item-${index}`}>
            <StyledAdvantageItemIcon src={item.icon} alt="orbro techonology icon"/>
            <Spacing pixel="8"/>
            <StyledHeading size="xsmall"><Trans i18nKey={ item.title}/></StyledHeading>
            <Spacing pixel="8"/>
            <StyledParagraph size="medium"><Trans i18nKey={ item.description }/></StyledParagraph>
          </StyledAdvantageItem>
        )) }
      </StyledAdvantageItems>
    </StyledAdvantage> 
  )
}

export default StereoAdvantage;