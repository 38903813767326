import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../../en/main/product/productImg";
import productPurchase from "../../en/main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {

  helmet: '| Key Considerations When Choosing an RTLS Provider',
  desc: 'Recently, companies have been actively adopting Real-Time Location Tracking Systems (RTLS) to maximize productivity and resource efficiency. However, when choosing an RTLS system, various criteria need to be considered. In this article, we will take a closer look at the key considerations when selecting an RTLS provider.',
  keywords: 'rfid, nfc, uwb, wireless communication, location tracking, object location tracking, person location tracking, tags, beacons, rtls, orbro, contech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Key Considerations When Choosing an RTLS Provider :: Orbro Blog',
  ogUrl: 'https://orbro.io/en/blog/rtlsCompany',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDwtIISjDSVCDSnp4Nx3cGb1Ozp2BSmcdnEapCFup7uDaNWXxV2V1-fB8A3HklTGwTcrHkdZ_cA7VDTUd4UkTfLspAq0d4uZPq6ZeulzJqvcifS9Bw-xfTVTFgfZXklSlXh1rq8NsXP6Ah2aN-bYrwpNREltJcCR5HW7d-IJmK-9gIpde_688lL9UU2BeQ5E0P1A2ESvEXcd8-LuzUEVRs3e3jfbDPxSjg9ydKLvn705yJSBD6rpEUC5iU8jQGXfKbB2Hr7A88JMwRLphNHv6zieJqhSM1dv8ESi8X3l8jH3DBJB7E8xvlicUOt1-QvsMD65qm87niZDWettcJyS6XnpOwmmmhHTqiLe-0DzPjVRtGmz1mkzs3JHNrhxNp40cCE6NK3Mp5RQBKUy190oEUBDb1bb2MLa-cs_xu8wcwQLNf8K_d9HDtyMlsLD6XHKShUDydJm2H9iNfI2TsHCpj6qOmc5IfB42F4WCFQuGHtpbKj7mKOvCe1BqtRhoXzmAtiHmghM8jzrLZQkWCMDJE79cfRTqO-JSBpPwJi0g-Zov4puGycni3TFAD7lHlCAKw8wLWn6xZuaCkMGG79wJ32p6xa_Gp2JLiSbVJNsqYu1BWb54Wwb5tVdGsx8W8tWYwv3BzJqrafHi9FtyYaKunD2m3qcDZNbkcTyIcXeQsOcj8C2BEp9WiOJELl6A_etzBinI5fHqf6UwTzJ_LTJ-FtBHkB-5bH-qIitO-2Gf2FxzgXE65QclQQhjI_nD25uwKI6Gos0YU0s4EwlQoIlcptoFn8dWovqaERvfkgME5C8EgN_W8HKqq5J8j6kUqUwnT_dJR9QTJDp2MDFFUeF6vV_R8-eq_qquZuYhfQDKqVcx6SSkIgQB8p_pJ-Q_DJZCg-4iLTx3gP7b24S32g5jEMhTnx0zpHHsdzc8MIxzySrRt5bT5AfNasqvrWHqPWa_t9MM-0iHWjR9lR8hWEL17y8XdEKjF5igOYGdR0UEvs1ZcFGvvNTV3vdiNCk3rNaC89ZJ9m6olPqAFIcdEbc2qAgG78-MbI33yCmidAD9hPBPR2dyPg_pXmHHqIlXT14EoAoDu18wbBu_wQP41ZPemWH-X-U_BUK2IdUQJP4CvLlFNs6ghZbwn13En9tXaPpl0SFBQB5v6riEQxDujwiMEvoi65V8m170vbu8L-NRuQ5KJoWd2GOmxBhOtupoe3t4FTv-DjmFjImqiCeGOq4-iyWcmU55jv4gk1_ugniXiZVGSGpvb2WJJABeN6xgNiT_9z_L6VwCfK6aMHs9cE8S_sv2yc2qBi3_SeEM5xTIr8pmU_qG4r1P_C1GVdiinD8I8c56mIUcX4Sz8chmHYiLwlTtIlj1nweMwToAiXHsvztud9HFsIdPNvBaVIkvl-6I7a0UHtkO699f9aK2qT2MiYWrueCLYE9V3_ibpgYgbxxyS5crADSB-7YJ--6S4n6eD-r4bUkMHVz97z6X6HV_uHS5B4jce8CeOOdEUpUMMvTgWZ6Hs7FP4fGhrAwhGUTRFRsZ_93Z7YmOWXx4WJLxdyoABRmg3TOA1ghvuCqDjiUA14wOTD3=w1775-h1190',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Key Considerations When Choosing an RTLS Provider',
  date: '2023-11-15',
  tag1: '#rfid',
  tag2: '#rtls',
  tag3: '#uwb',
  tag4: '#solution',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: 'Recently, companies have been actively adopting Real-Time Location Tracking Systems (RTLS) to maximize productivity and resource efficiency. However, when choosing an RTLS system, various criteria need to be considered. In this article, we will take a closer look at the key considerations when selecting an RTLS provider.',
      dot: ''
    },
    {
      video : video,
      link : '/en/enterprise/manufacturing/asset',
      head: 'Maturity and Accuracy of Technology',
      subHead: '',
      desc: `The core of RTLS systems lies in the accuracy of location information. You should verify how mature the provider's technology is and how accurately it provides location information. Evaluate the technical capabilities of the provider reliably through performance assessments and real-world use cases, especially.`,
      dot: ''
    },
    {
      head: 'Applicability in Various Environments',
      subHead: '',
      desc: `Companies perform various tasks in different environments. Therefore, it is necessary to ensure that the RTLS system can operate effectively in various environments. Consider the provider's ability to guarantee testing and application in different environments and its ability to provide solutions suitable for various industries.`,
      dot: ''
    },
    {
      head: 'Scalability and Flexibility',
      subHead: '',
      desc: 'The scale and business requirements of a company may change over time. It is important for an RTLS provider to have the ability to easily expand and adjust the system. Also, gather information on how easy it is to apply new technologies or updates.',
      dot: ''
    },
    {
      head: 'Cost and ROI Analysis',
      subHead: '',
      desc: `Since adopting RTLS incurs investment costs, careful consideration is needed from a cost perspective. Understand the provider's pricing policies and analyze the expected return on investment (ROI) after adoption to consider long-term investment returns. Calculate the cost-effectiveness accurately to make optimized decisions for the business.`,
      dot: ''
    },
    {
      head: 'Security and Data Privacy',
      subHead: '',
      desc: 'Location-based information can be a crucial asset for a company. Verify whether the RTLS provider has strong policies for data security and privacy protection. Compliance with regulations and regulations is also an important consideration. Selecting a system that can securely protect customer data is crucial.',
      dot: ''
    },
    {
      head: 'Customer Support and Services',
      subHead: '',
      desc: `In case of issues with RTLS systems, quick response is necessary. Evaluate the provider's technical support and service quality and find out what education and training programs are offered. Also, check if the provider's customer response and problem-solving abilities are excellent to build a reliable partnership.`,
      dot: ''
    },
    {
      head: '',
      subHead: '',
      desc: `Since RTLS systems can play a crucial role in a company's operations, selecting an RTLS provider considering these various criteria can help build an effective location tracking system. Choosing the right provider will contribute to the future growth and efficiency enhancement of the company.`,
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that assists in real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/en/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/en/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/en/enterprise/office/asset'
      }
    ]

  }

}
