
import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { StyledGridColumnBlank } from "@components/Grid/Grid.style";
import { StyledBlogHeading, StyledBlogPara, StyledGridRowGap0 } from "../Detail/BlogDetail.style";

import { CommonState } from "@redux/reducers/commonReducer";
import { useSelector } from "react-redux";
import { StyledAdvantageImgBox, StyledAdvantageImg, StyledAdvantageImgLeft } from "./BlogAdvantage.style";

import {
  BlogRTLSimg1, BlogRTLSimg1Tablet, BlogRTLSimg1Mobile,
  BlogRTLSimg2, BlogRTLSimg2Tablet, BlogRTLSimg2Mobile,
  BlogRTLSimg3, BlogRTLSimg3Tablet, BlogRTLSimg3Mobile,
  BlogRTLSimg4, BlogRTLSimg4Tablet, BlogRTLSimg4Mobile,
  BlogRTLSimg5, BlogRTLSimg5Tablet, BlogRTLSimg5Mobile,
  BlogRTLSimg6, BlogRTLSimg6Tablet, BlogRTLSimg6Mobile,
  BlogRTLSimg7, BlogRTLSimg7Tablet, BlogRTLSimg7Mobile,
  BlogRTLSimg8, BlogRTLSimg8Tablet, BlogRTLSimg8Mobile,
  BlogRTLSimg9, BlogRTLSimg9Tablet, BlogRTLSimg9Mobile,
  BlogRTLSimg10, BlogRTLSimg10Tablet, BlogRTLSimg10Mobile,
} from "@assets/images/blog/index";
import { StyledGridColumnBlog } from "../Main/BlogModule.style";
import { Spacing } from "@pages/CommonStyle/common.style";

const sections = [
  'safety',
  'assets',
  'medical',
  'monitor',
  'visitor',
];

const RTLSImg = () => {

  const images = useMemo(() => [
    {
      desktop: BlogRTLSimg1,
      tablet: BlogRTLSimg1Tablet,
      mobile: BlogRTLSimg1Mobile,
    },
    {
      desktop: BlogRTLSimg2,
      tablet: BlogRTLSimg2Tablet,
      mobile: BlogRTLSimg2Mobile,
      redot: true
    },
    {
      desktop: BlogRTLSimg3,
      tablet: BlogRTLSimg3Tablet,
      mobile: BlogRTLSimg3Mobile,
    },
    {
      desktop: BlogRTLSimg4,
      tablet: BlogRTLSimg4Tablet,
      mobile: BlogRTLSimg4Mobile,
    },
    {
      desktop: BlogRTLSimg5,
      tablet: BlogRTLSimg5Tablet,
      mobile: BlogRTLSimg5Mobile,
    },
    {
      desktop: BlogRTLSimg6,
      tablet: BlogRTLSimg6Tablet,
      mobile: BlogRTLSimg6Mobile,
    },
    {
      desktop: BlogRTLSimg7,
      tablet: BlogRTLSimg7Tablet,
      mobile: BlogRTLSimg7Mobile,
      redot: true
    },
    {
      desktop: BlogRTLSimg8,
      tablet: BlogRTLSimg8Tablet,
      mobile: BlogRTLSimg8Mobile,
      redot: true
    },
    {
      desktop: BlogRTLSimg9,
      tablet: BlogRTLSimg9Tablet,
      mobile: BlogRTLSimg9Mobile,
    },
    {
      desktop: BlogRTLSimg10,
      tablet: BlogRTLSimg10Tablet,
      mobile: BlogRTLSimg10Mobile,
    },
  ], []);



  // Redux
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  return (

    <StyledGridRowGap0>

      <StyledGridColumnBlank desktop={1} tablet={1} mobile={1} />
      <StyledGridColumnBlog desktop={10} tablet={8} mobile={4}>

        {sections.map((section, index) => (
          <div key={section}>

            <Spacing pixel="64" />
            <StyledBlogHeading size="xxsmall">
              <Trans i18nKey={`blogRTLS.advantage.${section}.title`} />
            </StyledBlogHeading>
            <StyledBlogPara size="medium">
              <Trans i18nKey={`blogRTLS.advantage.${section}.desc`} />
            </StyledBlogPara>

            {index >= 0 &&
              <StyledAdvantageImgBox>
                <StyledAdvantageImgLeft>
                  <StyledAdvantageImg src={images[index * 2][layoutType]} alt="RTLS Techonology's Advantage Image" />
                </StyledAdvantageImgLeft>
                <StyledAdvantageImgLeft>
                  <StyledAdvantageImg src={images[index * 2 + 1][layoutType]} alt="RTLS Techonology's Advantage Image" />
                </StyledAdvantageImgLeft>

              </StyledAdvantageImgBox>
            }
          </div>
        ))}


      </StyledGridColumnBlog>

    </StyledGridRowGap0>

  )
};

export default RTLSImg; 