import { StyledGridContainer } from '@components/Grid/Grid.style';
import React, { PropsWithChildren } from 'react';
import { SystemImg, SystemPage } from './System.style';
import { StyledHeading } from '@components/Text';
import { Trans } from 'react-i18next';
import { Spacing } from '@pages/CommonStyle/common.style';
import { HeadingH2 } from '@components/Text/Heading/Heading.style';

interface ModalDefaultType {
  pageName : string
  path : any
}


const System: React.FC<PropsWithChildren<ModalDefaultType>> = ({ pageName, path }) => {

  return (
    <>
      <StyledGridContainer>
        <SystemPage>

          <HeadingH2 size={'small'}>
            <Trans i18nKey={ `${pageName}.system.title` }/>
          </HeadingH2>

          <Spacing pixel='32'/>

          <SystemImg src={path.system.img} alt='orbroRtlsSystem'/>
          
        </SystemPage>
        
      </StyledGridContainer>
    </>  
  )
};

export default System;