export default {
  titleBanner: {
  subtitle: 'TWR | Two-Way Ranging',
  title: 'TWR Positioning Technology',
  description: '*TWR (Two-Way Ranging) is one of the positioning technologies that calculates distance by measuring the round-trip time of signals between a transmitter and a receiver.'
  },
  definition: {
  title: 'What is TWR Technology?',
  text: 'While TDoA works with multiple fixed anchors to determine the position of a mobile object, TWR primarily relies on bidirectional communication between two devices. For example, real-time distance monitoring of location tracking tags placed on people, objects, or vehicles is possible. This allows for bidirectional communication-based applications such as autonomous collision warning systems and proximity detection without the need for separate infrastructure. <br/> In TWR, bidirectional communication between two devices, such as location tracking tags, is used to monitor distances in real-time. As a result, bidirectional communication-based applications such as autonomous collision detection systems and proximity detection can be implemented without additional infrastructure. <br/> In TWR, when two devices are in close proximity, they initiate ranging to determine the distance between them. Then, the time taken for the signal to travel is multiplied by the speed of light to continuously determine the relative positions. This often enables location-aware communication and services. <br/><br/> To measure distances, three messages need to be exchanged: <br/><br/>',
  list: [
  `· Distance measurement devices like tags send a "Poll" message to nearby tags or other RTLS nodes to initiate TWR.`,
  `· The nearby device records the reception time of the "Poll" and responds with a "Response" message.`,
  `· The tag receives the response message and records the response reception time`,
  ],
  text2: '<br/> Based on the received time of the final message, the tag can determine the Time of Flight (ToF) and the distance between them.'
  },
  advantage: {
  title: 'Key Advantages of TWR',
  items: {
  realTime: {
  title: 'Real-Time',
  description: 'TWR technology enables accurate real-time distance measurement applications with very low latency (2-5 ms) and high update rates (up to 100Hz).'
  },
  twoWay: {
  title: 'Implementation of Various Two-Way Functions',
  description: 'It provides functionalities for distance measurement such as collision avoidance systems and proximity detection.'
  },
  flexible: {
  title: 'Flexibility',
  description: 'It offers peer-to-peer range applications between two devices without the need for additional infrastructure.'
  }
  }
  },
  }