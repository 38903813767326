import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/logisticsSafety/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/logisticsSafety/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/logisticsSafety/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/logisticsSafety/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/logisticsSafety/video/sub/rtlsVideo4.mp4";

export default {

    banner: {
        title: '仓库工作员安全管理',
        desc: '工作员的安全是最重要的。在高风险环境中不保证工作员的安全性，不仅会影响生产力，而且可能导致法律责任。此外，安全的物流现场对于塑造企业形象和获得利益相关者的信任至关重要。',

    },

    video: {
        title: '使仓库工作员更安全。',
        desc: '实时定位(RTLS)技术可以实时告知物流现场工作员的位置。管理员可以提前检测到危险情况，并在问题发生时做出更快的响应。此外，它支持遵守<strong>安全规定</strong>，并建立工作员与管理人员之间的信任。',
        play: video
    },

    func: [
        {
            title: '实时工作员定位',
            desc: '实时追踪工作员的位置，监控安全状况，一旦接近危险区域或发生紧急情况，可以快速做出响应。这样可以预防潜在的风险。',
            sub1: '高精度提供',
            icon1: IconRulerGray,
            sub2: '快速数据联动',
            icon2: IconShareGray,
            video: subVideo1

        },
        {
            title: '实时重型设备定位',
            desc: '安全不仅仅关乎人员。实时了解潜在事故风险的重型设备位置，可以防止工作员靠近或错误进入危险区域。',
            sub1: '标记危险资产',
            icon1: IconWarningGray,
            sub2: '实时定位',
            icon2: IconBinocularsGray,
            video: subVideo2
        },
        {
            title: '工作员轨迹追踪',
            desc: '如果工作员的行进路径有危险设备或物质，管理员将收到警报。这样可以快速做出响应或进行救援。',
            sub1: '数据云端存储',
            icon1: IconCloudCheckGray,
            sub2: '统计数据提供',
            icon2: IconChartBarGray,
            video: subVideo3
        },
        {
            title: '设置安全区域',
            desc: '预先设置禁止接近或需要注意的区域，可以向附近的工作员发出警告或向管理员发送通知。这样可以防止事故发生。',
            sub1: '自由范围设置',
            icon1: IconZoneGray,
            sub2: '多个区域设置',
            icon2: IconTagGray,
            video: subVideo4
        },


    ],



    subfunc: {

        title: '多种功能',
        desc: '确保工作员的安全性可以提高效率并减少由事故造成的损失。为了保障物流行业工作员的安全，Orbro提供了最优化的功能。<br/>系统旨在合理应对可能发生的安全事故，包括工作员进入危险区域或发生事故的情况，以及潜在危险的重型设备所带来的风险。',

        data: [
            {
                icon: IconChartRadius,
                title: '报告和分析',
                desc: '基于现场数据生成报告和分析资料。',
            },
            {
                icon: IconBell,
                title: '实时警报功能',
                desc: '工作员遇到异常情况时发送警报，可以及时应对。',
            },
            {
                icon: IconRoute,
                title: '轨迹历史查询',
                desc: '查询工作员的移动路径并数据化。',
            },
            {
                icon: IconDashboard,
                title: '现状了解',
                desc: '了解工作员当前的移动情况。',
            },
            {
                icon: IconWarning,
                title: '越界检测',
                desc: '检测工作员是否离开现场或进入了错误的区域。',
            },
            {
                icon: IconSearch,
                title: '轨迹模式分析',
                desc: '根据工作员的位置指示下一步行动。',
            },
            {
                icon: IconChainLink,
                title: '数据联动',
                desc: '与RFID、物联网、安全、视频等系统连接，实现高效利用。',
            },
            {
                icon: IconSecurity,
                title: '数据安全',
                desc: '保持高数据安全水平，提供可信赖的解决方案。',
            },


        ],
    },

    system: {
        title: '系统组成',
        img: rtlsSystem
    },

    productTitle: '产品配置',
    product: [

        {
            tag: 'assetPackage'
        },
        {
            tag: 'workerPackage'
        },
    ],


    contents: [
        {
            data: 'logisticsAsset'
        },
        {
            data: 'constructionSafety'
        },
        {
            data: 'blogForklift'
        },
        {
            data: 'blogRTLS'
        },

    ]

}
