import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import React, { useState, useEffect } from 'react';
import { DivFlexRow, DivFlexRowGap, DivFlexWrap, FontPage } from "@pages/CommonStyle/commonDiv.style";
import { BorderBottomLine, DisplayColor, LabelColor, ParagraphColor, Spacing, SpacingDTM, SpacingR } from "@pages/CommonStyle/common.style";
import release_note from "@src/lang/ko/release_note/release_note";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { StyledGridContainer } from "@components/Grid/Grid.style";
import { DivRight, PageButton, ReleaseNotePage, ReleaseNoteTagButton, ReleaseNoteTagButtonSmall, ReleaseNoteText, ReleaseNoteTextBG, ReleaseNoteTextBox } from "./ReleaseNote.style";
import SEO from "@pages/SEO";
import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";


const ReleaseNote = () => {

  const img = 'https://lh3.googleusercontent.com/fife/APg5EOYkZbw7aOzICkoQi8HNk9s2d6VpUL4SVgujeUxCxLThYcyYLFnsiLZyVx8NwEWTiJIKKeTqFcuFZF4-0GJiF5k8YyZKXtU1iL8Qas-_DGIZPtogRYL6cnYimywaHxSkEe97rBb410rPXE2LymoexrdTIuXxKNli61FgKo4m_7JzUZXofk9HBe1CmLCLjcaJ4N8CusSji3C-D-W9XEtpZ_pdbROqqHehQbzJf3Lu7a9r04vRKR5XY6SlOsaHQXgGvyvf2EwBUoRIK1DKgjGg5Zzpi5ql-8O8MWh8pyV_NMWerNhx8Km30j95bwAyArdMfGv2x8l5jdUBxychGtfT5SECI01jw3yjOvdPPWgw9EfhlRlUPENdWxGAM8tN8fPTKID1cOaskpvwlCYjMeExZpPCYAmw8IWLq_KmhgyxcGK287oaey7vTTQ_AFc816pvys48-p5vA5qpp1-3VCFZEEVoF90jtIKxMU5dWbpeElJFg99AybHdS6Ka8QD1-oq7K-N2rat8r8uPyJCc-UGK2DfU301JI-BlolwsoqI9JH2eomiXOFFsCyPfbMImXi-ZM0r5R7DAY5j3VqdTVq3x2AUK_VMlLkFSCcOzlpo6_hUaTgAXdIGIdtArstnx9UYRPj1qdvT2zhTSXu43gxG5dnRsO8WVC4H6qMoiotvH-f-8UkXMZOSjV3C9TmUS3zLAzMIeP5_wTbaf7Pk8kT9Cf_h6NFml9VUIPZ-0AVWUfsGfBgqD2U_ToCKiOtKLRwy1d50veOBzfrvRlhAUVUyQj3B6Jf4cXxAgJyp7PnJUXUPmpYr3UVmjjyx3-7N1f1TAf5TpHSChyMrenXXD_BlVEYe2DX2o_oaZVmkZ4K3cPSUoaQsgcalbV-QMpW-cys5PIo1H5DRYxfH5WzurFBEEqXF0h_RJZD8uBz7u-nF3a_sGEXbSV1sIj-5oqlm0WHswVhSyFLy1AsEPsWKNGoN4vAOdFN3bvKfDXcj22FbtQv7oqpj35v0fiK0uFjxzUli6LA0lOcxxBTDX8K3TGhhmgZSGVIVN-RtMSnnYrzqGJ2Gdpfhs6Z_lrTrIL4IE9EiVsbWkZRlk9Ung0oFwlX9E0bpnv_oBa8cEsinqQms8LDrCXle81kS5jg_WHHUVoTBYawhdKqeBHbGj67EcTW5OfDNT5b-0ivlXNSO2rj2MfdTIXcPXHrFF0O1x3x4e_aZhiXwhxOdQpRn38jnYIu8XKjS4Ot07PLLE0sdyPidowRf1mGv3Fe3VZbO1cPCMUGmwJwDb1ow5AYNQsCpv9wLFrfkTfb9z8_lyUPmzo4t7o8N4difD44FvoqkfHmwLBBPkYXimpJ9jrIz25oS_B70pubRYpO-ZMwo1aQ2McWHBUd9oBRTr27FuBGJS8RIlZ5nxyeOeZ-Tp_kOeKWr8bcB5yrd96BSV8Cgb5P43XLS9SMCZrZ7oM6e3JipkAMJDP7kLBtvCET1199BhDG6TF44RHfOKXYImbDVIdgmSdsIw6nhcXUIDGaQCifrmOru2TFa0v2Wwz4K-=w2006-h1238';

  const [activeTags, setActiveTags] = useState(['전체']);
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  const handleTagClick = (tag) => {
    if (tag === '전체') {
      // 전체 버튼을 클릭한 경우, 모든 버튼을 선택 해제
      setActiveTags(['전체']);
    } else if (activeTags.includes(tag) && activeTags.length === 1) {
      return;
    } else if (activeTags.includes('전체')) {
      // 전체 버튼을 선택한 후 다른 버튼을 클릭한 경우, 전체 버튼 선택 해제 후 해당 버튼 활성화
      setActiveTags([tag]);
    } else {
      // 전체 버튼을 선택하지 않은 경우, 해당 버튼을 토글
      setActiveTags(activeTags.includes(tag) ? activeTags.filter((t) => t !== tag) : [...activeTags, tag]);
    }
    setCurrentPage(1);
  };


  function calculateDaysAgo(dateString) {
    // 오늘 날짜를 가져옵니다.
    const today = new Date();

    // dateString에서 괄호 안의 내용을 제거합니다.
    const cleanedDateString = dateString.replace(/\([^()]*\)/g, '');

    // 공백을 기준으로 날짜를 분할합니다.
    const dateParts = cleanedDateString.split('-');

    // 날짜 요소를 가져와서 순서를 바꿉니다. (년도, 월, 일)
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // 월은 0부터 시작하므로 1을 빼줍니다.
    const day = parseInt(dateParts[2]);

    // dateString을 Date 객체로 파싱합니다.
    const date = new Date(year, month, day);

    // 두 날짜 사이의 차이를 계산합니다.
    const timeDiff = Math.abs(today.getTime() - date.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    // 결과를 반환합니다.
    return `Release : ${diffDays} Days ago`;
  }


  const getColor = (text) => {
    let btnColor, textColor;
    switch (text) {
      case '전체':
        btnColor = '#000';
        textColor = '#FFF';
        break;
      case 'ORBRO OS':
        btnColor = '#CCF3E5';
        textColor = '#00C37D';
        break;
      case '모바일':
        btnColor = '#D4E2FC';
        textColor = '#2A70F0';
        break;
      case '위치추적':
        btnColor = '#EBD5F0';
        textColor = '#9C2FB6';
        break;
      case 'AI':
        btnColor = '#FFE2DF';
        textColor = '#BF5149';
        break;
      default:
        btnColor = '#FFF';
        textColor = '#000';
        break;
    }
    return { btnColor, textColor };
  };

  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 번호
  const itemsPerPage = layoutType === 'mobile' ? 8 : 10; // 페이지당 보여줄 아이템 수


  const getFilteredData = () => {
    return release_note.notion_id.filter(data =>
      data.tag.some(check => activeTags.includes(check) || activeTags.includes('전체'))
    );
  };

  let filteredData;
  // 현재 페이지의 데이터를 계산하는 함수
  const getCurrentPageData = () => {
    filteredData = getFilteredData(); // 필터링된 데이터를 가져옵니다.
    const startIndex = (currentPage - 1) * itemsPerPage; // 현재 페이지의 시작 인덱스
    const endIndex = startIndex + itemsPerPage; // 현재 페이지의 끝 인덱스
    return filteredData.slice(startIndex, endIndex); // 현재 페이지의 데이터 반환
  };

  // 특정 페이지로 이동하는 함수
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber); // 현재 페이지 번호를 해당 페이지 번호로 업데이트
  };


  // 현재 페이지의 데이터를 가져옵니다.
  const currentPageData = getCurrentPageData();


  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "ORBRO",
    "description": `오브로 OS의 릴리즈 노트에서 새롭게 업데이트 된 내용을 확인할 수 있습니다.`,
    "alternateName": `ORBRO OS 릴리즈 노트 | 오브로`,
    "url": `https://orbro.io/release-note`,
    "keywords": `오브로, 릴리즈 노트, 디지털 트윈, 실시간 위치추적, 비콘, rtls, orbro, 콩테크`,
    "image": `${img}`,
    "address": "#B-1203, 660, Daewangpangyo-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea",
    "email": "orbro@orbro.io",
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+8215229928",
      "contactType": "a sales contact point",
      "areaServed": "KR",
      "availableLanguage": "Korean"
    }],
    "sameAs": "https://kr.linkedin.com/company/kongtech"
  }


  return (
    <>
      <SEO
        lang={'ko'}
        helmetTitle={'ORBRO OS 릴리즈 노트 | 오브로'}
        ogKey={`오브로, 릴리즈 노트, 디지털 트윈, 실시간 위치추적, 비콘, rtls, orbro, 콩테크`}
        ogDesc={`오브로 OS의 릴리즈 노트에서 새롭게 업데이트 된 내용을 확인할 수 있습니다.`}
        ogImg={img}
        ogUrl={`https://orbro.io/release-note`}
        jsonLd={jsonLd}
      />
      <Spacing pixel="52" />
      <SpacingDTM d={"128"} t={"96"} m={"56"} />
      <FontPage>
        <StyledGridContainer>
          <ReleaseNotePage>
            <DisplayColor color={"#000"} size={"small"}>
              <Trans i18nKey={release_note.title} />
            </DisplayColor>
            <Spacing pixel="8" />
            <ParagraphColor color={"#000"} size={"medium"}>
              <Trans i18nKey={release_note.desc} />
            </ParagraphColor>
            <SpacingDTM d={"64"} t={"48"} m={"28"} />
            <DivFlexWrap gap={"8"}>
              {release_note.type.map((data, i) => (
                <>
                  <ReleaseNoteTagButton
                    color={activeTags.includes(data.tag) ? getColor(data.tag).btnColor : getColor('default').btnColor} // 활성화된 버튼일 때와 비활성화된 버튼일 때의 색상을 설정
                    onClick={() => handleTagClick(data.tag)} // 버튼 클릭 이벤트 처리
                  >
                    <LabelColor
                      color={activeTags.includes(data.tag) ? getColor(data.tag).textColor : getColor('default').textColor} // 활성화된 버튼일 때와 비활성화된 버튼일 때의 텍스트 색상을 설정
                      size={"small"}
                    >
                      <Trans i18nKey={data.tag} />
                    </LabelColor>
                  </ReleaseNoteTagButton>
                </>
              ))}
            </DivFlexWrap>
            <SpacingDTM d={"32"} t={"32"} m={"24"} />
            {currentPageData.map((data, i) => (
              <>
                {data.tag.some(check => activeTags.includes(check) || activeTags.includes('전체')) &&
                  <>
                    <BorderBottomLine />
                    <Link to={data.link}>
                      <ReleaseNoteTextBox>
                        <ReleaseNoteText dir='start'>
                          <LabelColor color={"#000"} size={"medium"}>
                            <Trans i18nKey={data.title} />
                          </LabelColor>
                          <SpacingDTM d={"16"} t={"16"} m={"8"} />
                          <LabelColor color={"#646464"} size={"small"}>
                            <Trans i18nKey={calculateDaysAgo(data.date)} />
                          </LabelColor>
                        </ReleaseNoteText>
                        <ReleaseNoteText dir='end'>
                          <DivRight>
                            {data.tag.map((tag_data, k) => (
                              <>
                                <ReleaseNoteTagButtonSmall btnColor={getColor(tag_data).btnColor}>
                                  <LabelColor color={getColor(tag_data).textColor} size={"xsmall"}>
                                    <Trans i18nKey={tag_data} />
                                  </LabelColor>
                                </ReleaseNoteTagButtonSmall>
                              </>
                            ))}
                          </DivRight>
                          <SpacingDTM d={"12"} t={"12"} m={"4"} />
                          <LabelColor color={"#646464"} size={"small"}>
                            <Trans i18nKey={data.date} />
                          </LabelColor>
                        </ReleaseNoteText>
                        <ReleaseNoteTextBG />
                      </ReleaseNoteTextBox>
                    </Link>
                  </>
                }
              </>
            ))}
            <BorderBottomLine />
            <Spacing pixel="32" />
            <DivFlexRowGap gap={8}>
              {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }).map((_, index) => (
                <PageButton key={index} onClick={() => goToPage(index + 1)} color={currentPage - 1 === index ? '#000' : '#fff'}>
                  <LabelColor color={currentPage - 1 === index ? '#fff' : '#646464'} size={"small"}>
                    {index + 1}
                  </LabelColor>
                </PageButton>
              ))}
            </DivFlexRowGap>

            <SpacingDTM d={"128"} t={"96"} m={"64"} />
          </ReleaseNotePage>


        </StyledGridContainer>
      </FontPage>
    </>
  );
}

export default ReleaseNote;