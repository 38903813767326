import React from "react";
import TechRequest from "../Common/TechRequest/TechRequest";
import TitleBanner from "../Common/TitleBanner/TitleBanner";
import { StyledTechnologyContentBottom0 } from "../Technology.style";
import TWRContent from "./components/TWRContent/TWRContent";
import { useTranslation } from "react-i18next";
import i18n from "@pages/i18n";
import SEO from "@pages/SEO";
import CommonLang from "@pages/CommonStyle/commonLang";
import { FontPage } from "@pages/CommonStyle/commonDiv.style";
import { SpacingDTM } from "@pages/CommonStyle/common.style";


const TWRPage = () => {

  const { t } = useTranslation();
  CommonLang();

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "headline": "TWR 위치추적 기술",

    "datePublished": "2023-04-01T08:00:00+08:00",
    "dateModified": "2023-04-01T08:00:00+08:00",
    "author": [{
      "@type": "Organization",
      "name": "ORBRO",
      "url": "https://orbro.io"
    }]
  }
  const helmetTitle = t(`seo.tech.twr.title`);
  const key = t(`seo.tech.twr.key`);
  const desc = t(`seo.tech.twr.desc`);
  const currentLang = i18n.language;
  let isKorean;
  if (currentLang === 'ko') {
    isKorean = true;
  } else {
    isKorean = false;
  }

  return (
    <>
      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={key}
        ogDesc={desc}
        ogImg="https://orbro.io/images/0a58db6e4cee110ea36685b611d3b55b.png"
        ogUrl={isKorean ? "https://orbro.io/enterprise/technology/twr" : `https://orbro.io/${currentLang}/enterprise/technology/twr`}
        jsonLd={jsonLd}
      />

      <SpacingDTM d={"64"} t={"48"} m={"32"} />
      <FontPage>

        <TitleBanner
          subtitle={"twr.titleBanner.subtitle"}
          title={"twr.titleBanner.title"}
          description={"twr.titleBanner.description"}
        />

        <StyledTechnologyContentBottom0>
          <TWRContent />
        </StyledTechnologyContentBottom0>

        <TechRequest />
      </FontPage>
    </>

  );
};

export default TWRPage;