import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconPenSquare, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/officeAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/officeAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/officeAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/officeAsset/video/sub/rtlsVideo3.mp4";


export default {

    banner: {
        title: '资产管理',
        desc: '糟糕的资产管理会导致时间和金钱损失。通过清晰快捷地管理资产，可以确保业务的顺利运营，避免中断。',

    },

    video: {
        title: '更准确、更安全的资产管理。',
        desc: '实时定位(RTLS)技术可以实时追踪一切。<br/>将办公室内的所有资产管理到最佳状态，实现<strong>最高效的运营</strong>。',
        play: video
    },

    func: [
        {
            title: '资产定位',
            desc: '实时追踪包括办公用品在内的资产的当前位置。减少浪费的路线、时间和成本，提高办公室运营效率。',
            sub1: '实时定位',
            icon1: IconBinocularsGray,
            sub2: '高精度提供',
            icon2: IconRulerGray,
            video: subVideo1

        },
        {
            title: '库存管理',
            desc: '准确了解库存数量是基本要求。同时，实时了解库存位置和数量。',
            sub1: '实时状态了解',
            icon1: IconClockGray,
            sub2: '快速数据交互',
            icon2: IconShareGray,
            video: subVideo2
        },
        {
            title: '资产出库提醒',
            desc: '准确了解办公室内物品的出库情况是必要的。但是，过多地投入人力和成本是低效的。通过自动化实现高效的办公环境。',
            sub1: '提供统计数据',
            icon1: IconChartBarGray,
            sub2: '数据云端存储',
            icon2: IconCloudCheckGray,
            video: subVideo3
        },


    ],



    subfunc: {

        title: '多种功能',
        desc: '高效的库存管理、节约时间和成本、透明度和追踪性等多种优点。<br/>为打造最佳工作环境并实现最高效率，Orbro还提供以下功能。',

        data: [
            {
                icon: IconChartRadius,
                title: '报告与分析',
                desc: '基于资产使用记录生成报告和分析资料。',
            },
            {
                icon: IconBell,
                title: '实时警报功能',
                desc: '当特定资产发生异常情况时，发送警报，可以立即应对。',
            },
            {
                icon: IconRoute,
                title: '资产动线查询',
                desc: '查询特定资产在办公室内的移动轨迹，进行数据化。',
            },
            {
                icon: IconDashboard,
                title: '状态了解',
                desc: '实时了解特定资产的当前位置。',
            },
            {
                icon: IconWarning,
                title: '离开监测',
                desc: '当资产离开预设区域时，实时发送警报。',
            },
            {
                icon: IconPenSquare,
                title: '数量管理',
                desc: '了解资产的剩余数量，一旦超过或不足一定数量即时通知。',
            },
            {
                icon: IconChainLink,
                title: '数据联动',
                desc: '与RFID、物联网、安全、视频等系统连接，实现高效利用。',
            },
            {
                icon: IconSecurity,
                title: '数据安全',
                desc: '保持高数据安全水平，提供可信赖的解决方案。',
            },


        ],
    },

    system: {
        title: '系统构成',
        img: rtlsSystem
    },

    productTitle: '产品配置',
    product: [

        {
            tag: 'assetPackage'
        },
        {
            tag: 'workerPackage'
        },
    ],


    contents: [
        {
            data: 'logisticsAsset'
        },
        {
            data: 'manufacturingAsset'
        },
        {
            data: 'blogOffice'
        },
        {
            data: 'blogRTLS'
        },

    ]

}

