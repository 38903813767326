import { IconBell, IconBinocularsGray, IconChainLink, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { IconAccess, IconAlarm, IconWarn, IconZoom } from "@assets/images/main";
import { rtlsSystem2 } from "@assets/images/solution";
import video from "@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4";
import subVideo1 from "@src/assets/images/solution/constructionEquipment/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/constructionEquipment/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/constructionEquipment/video/sub/rtlsVideo3.mp4";


export default {
    
    banner : {
        title : '중장비 관리',
        desc : '건설 현장에서 중장비는 핵심 요소입니다. 따라서 적절한 유지 보수, 사고 예방은 필수입니다. 중장비를 제대로 관리 한다면, 안전을 유지하면서 비용은 절감될 것이고 현장의 생산성은 눈에 띄게 상승할 것입니다.',

    },

    video : {
        title : '중장비를 가장 스마트하게 관리하는 방법.',
        desc : '건설 현장의 중장비 위치를 실시간으로 파악합니다.<br/>충돌 사고를 예방하고 최적의 배치를 통해 <strong>작업 프로세스의 효율과 생산성을 한 단계 높입니다.</strong>',
        play : video
    },

    func : [
        {
            title : '실시간 위치 추적',
            desc : '중장비의 현재 위치를 실시간으로 파악합니다. 따라서 현장에서 효율적인 배치가 가능하며, 시간 관리가 더욱 쉬워집니다.',
            sub1 : '높은 정확도 제공',
            icon1 : IconRulerGray,
            sub2 : '빠른 데이터 연동',
            icon2 : IconShareGray,
            video : subVideo1
        },
        {
            title : '근접 경고 시스템',
            desc : '중장비 운행 중, 사람의 실수나 시스템의 결함으로 사고가 발생한다면 이는 치명적인 결과를 초래합니다. 특히나 사람과의 접촉사고는 아주 위험합니다. 이를 실시간으로  모니터링하며 사고 발생 시, 즉각적인 대응이 가능합니다.',
            sub1 : '위험 자산 지정',
            icon1 : IconWarningGray,
            sub2 : '근접 기준 거리 설정',
            icon2 : IconRulerGray,
            video : subVideo2
        },
        {
            title : '사각지대 위험 감지',
            desc : '중장비 운전자의 시야에서는 사각 지대가 발생할 수 있습니다. 피로도가 높은 작업은 사람이 방심하기 쉬운 환경을 만듭니다. 따라서 사각지대를 파악하여 사람과 충돌하면서 생길 수 있는 위험을 방지하고 안전한 작업 현장을 유지합니다.',
            sub1 : '자유로운 범위 지정',
            icon1 : IconZoneGray,
            sub2 : '여러 구역 설정',
            icon2 : IconTagGray,
            video : subVideo3
        },


    ],



    subfunc : {
        
        title : '다양한 기능',
        desc : '중장비는 안전과 비용 측면 모두에서 중요한 존재입니다. 안전 규정을 어기는 중장비 사용은 사고를 유발하고, 비효율적인 작업방식은 비용과 인력을 낭비하게 됩니다. 안전하고 경제적인 현장을 만들고자 오브로는 아래와 같은 기능들을 구성하였습니다.',
        
        data : [
            {
                icon : IconChartRadius,
                title : '리포트 및 분석',
                desc : '현장 데이터를 기반으로 보고서 및 분석 자료를 생성할 수 있습니다.',
            },
            {
                icon : IconBell,
                title : '실시간 알람 기능',
                desc : '중장비에 이상 상황이 발생하는 경우 알람을 보내 즉각적으로 대처할 수 있습니다.',
            },
            {
                icon : IconRoute    ,
                title : '동선 이력 조회',
                desc : '중장비가 현장에서 움직였던 동선을 조회하여 데이터화 할 수 있습니다.',
            },
            {
                icon : IconDashboard,
                title : '현황 파악',
                desc : '현재 특정 중장비가 어디로 이동하고 있는지 파악합니다.',
            },
            {
                icon : IconWarning,
                title : '이탈 감지',
                desc : '중장비가 현장에서 이탈하거나 잘못된 곳으로 이동하는 것을 감지합니다.',
            },
            {
                icon : IconSearch,
                title : '동선 패턴 분석',
                desc : '중장비가 현장에서 얼마만큼 사용되고 언제 쓰였는지 분석할 수 있습니다.',
            },
            {
                icon : IconChainLink,
                title : '데이터 연동',
                desc : 'RFID, IOT, 보안, 영상 등의 시스템과 연결하여 효율적으로 활용할 수 있습니다.',
            },
            {
                icon : IconSecurity,
                title : '데이터 보안',
                desc : '높은 데이터 보안 수준을 유지하여 신뢰성이 높은 솔루션을 제공합니다.',
            },


        ],
    },

    system : {
        title : '시스템 구성',
        img : rtlsSystem2
    },

    productTitle : '제품 구성',
    product : [

        {
            tag : 'carPackage'
        },
        {
            tag : 'workerPackage'
        },

    ],


    contents : [
      {
        data : 'constructionAsset'
      },
      {
        data : 'constructionSafety'
      },
      {
        data : 'blogRTLS'
      },
      {
        data : 'blogMedical'
      },

    ]



}