import { accessAuto1, accessAutoMain, accessPro1, accessProMain, accesscontroller1, accesscontrollerMain } from "@assets/images/main/access/product";
import { assetTracking1, workerTracking1 } from "@assets/images/main/rtls/product";

export default {

    specTitle : {

    },

    specData : [
        {
          data: [
            {title : "제품명"},
            {text1 : "모바일 출입"},
            {text2 : "자동문 출입"},
            {text3 : "얼굴 인식 출입"},
          ],

        },
        {
          data: [
            {text : "설명"},
            {text : "모바일 앱으로 출입문을 제어하는 매우 간편한 기기"},
            {text : "앱 실행없이 편리한 출입이 가능한 기기"},
            {text : "얼굴인식, NFC카드 등 다양한 출입을 한번에 사용가능한 기기"},
          ],

        },
        {
          data: [
            {text : "전원 공급"},
            {text : "배터리 사용(최대 3년사용)"},
            {text : "DC 12V, 24V "},
            {text : "DC 12V, 3A"},
          ],

        }


    ],


    a: [
      {text : "제품명"},
      {text : "설명"},
      {text : "브라켓"},
      {text : "정밀도"},
      {text : "통신 방식"},
      {text : "태그 단말기 전원"},
      {text : "태그 단말기 사용기간"},
      {text : "설치 지원"},
      {text : "히트맵"},
      {text : "브라켓 사용"},
      {text : "태그 단말기 검색"},
      {text : "동선 추적"}

    ],


    b: [
      {text : "블루투스(BLE) 위치추적"},
      {text : "BLE 태그 단말기를 자산 또는 추적하고자하는 대상에 부착하여 실시간 위치 추적이 가능합니다."},
      {text : "천장, 레일등"},
      {text : "최대 10m"},
      {text : "BLE"},
      {text : "CR 2430"},
      {text : "최대 1년"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : ""},

    ],


    c: [
      {text : "고정밀(UWB) 위치추적"},
      {text : "UWB 태그 단말기를 자산 또는 추적하고자하는 대상에 부착하여 실시간 위치 및 동선 추적이 가능합니다."},
      {text : "천장, 레일등"},
      {text : "최대 10cm"},
      {text : "UWB"},
      {text : "USB - C"},
      {text : "최대 2주"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},

    ],
    
    
    spec: [
      
      {

      },

      {
        image : assetTracking1,
        link : '/rtls/ble'
      },


      {
        image : workerTracking1,
        link : '/rtls/uwb'

      },
      
        
    ],
    
    
}

