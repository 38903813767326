import {
  constructionAsset,
  constructionSafety,
  logisticsAsset,
  medicalPatient,
  medicalWaste,
  officeAsset,
  retailExhibition
} from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../../cn/main/product/productImg";
import productPurchase from "../../cn/main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4";

export default {
  helmet: '| RFID、NFC、UWB：选择哪种技术？',
  desc: 'RFID、NFC和UWB技术在物体跟踪、人物跟踪、无线通信等方面具有各自的特点和优势。选择适当的技术取决于具体情况。',
  keywords: 'rfid, nfc, uwb, 无线通信, 位置追踪, 物体追踪, 人物追踪, 标签, 信标, rtls, orbro, contech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RFID、NFC、UWB：选择哪种技术？:: Orbro博客',
  ogUrl: 'https://orbro.io/cn/blog/RfidNfcUwb',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDyDzwljrefhMhdX7kf1Mm1ywH2nnOki0RIgSDRIh7C5tg1pR6m3kbEx54PkUJcq-hciL8YYYvumKJ-63gC6WJ4qVWUBwpX2L27Az-lRi9JVPUAPPrN4w5UrS1sMh0YLSWqhm1JpL4pi_Z08Ejh_gka2T1aIruLLocuLbyP_ZAhm5q8Ak1AOxoZ30Sa750ffkaTQoyxFwgi_lbDVwXorafRdzRGuy6GFGf2k6pLcN8gyQ_bmLhYnizUxtLSo4rpyQalNm-qZKsosAwvg0xPCKx3yIpdkycgeB8XisSD6JoFWDLjK5DE2XFvKt5taMzrDEUI4yq-kRgj54cRHA0AkUYviBZEHbun6CP-ddQfgL0_vxj-afsWWE11vR-J7aic2bRHVF46WEIHc_Q1lom0Ro3XRQy9WuJ3_x23uVUQemus3_BtxD76BMNNfwUJTA8ohBZ74GkjJAozZjHWVNZcN-bk9cTnLcBCuYdeXKB67hosHLnXyRWTxbKHnAspOTZuDEjoq0c6efewIt3TF-krS18xuDI06UQOOINUt3T177VvqEHZescieirG3kU3V1fklYqDXIo5VJhA8IxB1G7tllhssELDGTJmG7K40AbmDmY9wSwM_FRqgaHsIA3Ol9rBz2civyC8T_JpAV0c0gB0UdkO6mYn9PTaWSVEyOHMFhrLh9kJT9Ec3N26DFoUe0wJGHafWgc3EOY5SyD9TbyysdCbMFtmzbri63MLFS-CVlfuurccxzTp4fA7Y6x5m2w7-zVqBw4l17kYVEXAEnX1lIg2pSOuZAaWmSiOE3O6_4zbKpMHgySf3gCmA8moDXM_SB0npT9zE1NftELISmGwhEA0EWAC_OPBpOHijsZC38Ww9_MjSbVAn65T6j96hn0MVkq0drXqf9kVdR5VzHla36eqjBCnvxuv7p3OJpxMhG7JWgENwqauKMiKBc2sGmAUAtvjVrAUaxDD1f18pVT-97AjFdXJtrfEOrbFQXJhqp8F9GiFJTyQGbepdWdEjhzpL06WDXrPTZ9TMIEJrftdzxDH3RvDYScwSGlEPMALRbh448aSi05ozs5TG4KXVyOEN90b_BLn_LR5XkaXWFAyzj9nbaR5pQL75WGz857-0w5fOcpXAJlzdGLPK-qzwvQypZufKmeGjhUZN324auzcqLBxDTdpiX6mQfeqR9YekYBydEHDzesVBgPzdFxNIc8e-D6uW0CNYJbWG-cs9YLY0brSsnh8Ki32Gb_PwmdKDxyt-zejkein4rKNvH_L4fe-bCYOwKwuS3Do2tIDr_HRgFlmIuIzQ-LTzwnQHFwrivngUeb-urFqXm-OHCMLLVsTdhMl6F8tAmWehfylTymzJT9wSric2u2oyOWofynt5w2DHrO5btzJrk4fqVRfO7VXnIJ71aTGeb2wLS6fYLUIFWraHoG6b0QKEqzmoDVtIVpinqNK3eRd4bTQ5lxE6FJxMyX3Dnt2FOOzosarYUFrndKzLb8P1AnsUez-1xlCwpA2KUqFeolbevRLxAPtPZcEuFHLZKcBVKi48FPzR4jypbwpMCM7j9e9Eh9KNzoRlArES6rb_kHgh=w1995-h1157',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RFID、NFC、UWB：选择哪种技术？',
  date: '2023-11-10',
  tag1: '#rfid',
  tag2: '#nfc',
  tag3: '#uwb',
  tag4: '#无线通信',

  text: [
    {
      head: '',
      subHead: '',
      desc: '在现代社会中，存在着对物体跟踪、数据交换、位置测量等多样化需求，因此出现了多种无线通信技术。在其中，RFID、NFC和UWB都有各自的特点，并根据具体情况进行选择。本文探讨了每种技术的特点和使用案例，并讨论了在不同情况下选择哪种技术。',
      dot: ''
    },
    {
      head: '1. RFID：适用于高效的物体跟踪和管理',
      subHead: '',
      desc: 'RFID主要用于物流、制造业和库存管理等领域，用于物体识别和跟踪。通过将小型RFID标签附在物体上，读取器可以检测并读取/写入信息。被动RFID利用外部能源运作，而主动RFID具有自己的电源。由于这些特性，RFID在高效跟踪和管理大量物体方面表现出色。此外，由于相对较低的成本，它也是许多企业选择的技术之一。',
      dot: ''
    },
    {
      head: '2. NFC：用于简便的数据交换和无线支付',
      subHead: '',
      desc: 'NFC主要用于智能手机支付、智能家居应用和便捷的数据交换。它在非常短的距离内工作，允许快速配对和连接。通过智能手机或NFC卡进行数据交换或无线支付，为用户提供便利的体验。它主要用于安全性重要的支付系统或与智能家居设备集成。',
      dot: ''
    },
    {
      head: '3. UWB：在需要精确位置测量的环境中的最佳选择',
      subHead: '',
      desc: 'UWB是一种专门用于使用非常宽带进行精确位置测量和距离测量的技术。它支持双向通信，主要用于室内位置服务、汽车导航以及需要精确距离测量的工业环境。UWB与传感器协同工作，提供物体的实时位置信息，因此在需要比RFID更精确的位置测量的地方表现出色。',
      dot: ''
    },
    {
      video: video,
      link: '/cn/enterprise/manufacturing/asset',
      head: '选择哪种技术？',
      subHead: '',
      desc: '在决定企业或个人选择哪种无线通信技术时，必须考虑用途和环境。如果希望高效跟踪大量物体，则适合使用RFID。如果智能手机支付或便捷的数据交换是主要目的，NFC是一个不错的选择。在需要精确位置测量的工业领域，UWB表现出色。<br/><br/>此外，还必须考虑每种技术的成本、功耗和数据容量等因素。总体而言，将用户需求与技术特性结合起来，找到最佳的无线通信解决方案至关重要。考虑物理环境和业务目标，有助于建立创新且有效的无线通信系统。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '通过将UWB终端附在工人或设备上，实时进行位置追踪的产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      // 可根据需要添加其他产品条目
    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/cn/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/cn/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/cn/enterprise/office/asset'
      }
    ]
  }
};
