import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

export default {
    helmet: '| UWB RTLS，未来工业的开端',
    desc: 'UWB RTLS（Ultra-Wideband Real-Time Location System）技术实现了工业现场的数字化，以跟踪和管理人员和物体。具有高精度和实时数据更新的特点，有助于推动业务增长。',
    keywords: 'uwb，rtls，uwb rtls，智能物流，智能建筑，智能制造，智能流程，智能工厂，数字化转型，数字孪生，实时位置跟踪，rtls，orbro，contech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'UWB RTLS，未来工业的开端 :: 奥博博客',
    ogUrl: 'https://orbro.io/cn/blog/uwbRtls',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDyMvH9u0-21RYnyyBVVc9guqVzCzv-ggtVgPCRQTLkEU33FS2rG9s1BImAzHdqx3slOcbgGEGcFnTwDAmmi0nBR-jQfAdavlymzwvoyWDbTWljNw2DPoaW_qaO4ciYcfvrO7YnaeT_zAYoHLLULjh7MSKToInBek_3WIXpnTfHBz83o4ryfNamREq7rU9e4ET1GsYzLjQ-33tSLxqcb1f1RUYR4qlgVm7ntqiwd4jyEZlj-33EJPgyvj3R-QwTvkFl1Ha2Z1oL3YBwdk33ugYt5wzdW-3IJa9T1gM6FWZbPZdnGWL0xDRYYzh0OTD7sckAN2dXqBwWWAqEjdyTKN6AfoQibnezcJUsWa12l8mton2PeSWa45J0NarK5Vrkbc_qrQzoC9iJ209QlewQrjmeCzUN2E9gIK8_O_eQ_seXYgBf-nQWy5BD5fX2goEJBEYQECwdmfhmRPWwkbQC8rXsKBhnXCZ9t3t65Dpn7Voj_tUI2w0wMMlJcsEpEpUdsd4hx6FrX5t4pHmpaJzEB5DxjLofbzSQXqEQcEQvkocvpBQ6HI9XVC5MFlwkonW-DUk_EwFlNGoCZYnZFFQjU-pK07aYDuZA4uvClTrr2OrxvC6iX2rVhJ01oGwN071DybCKXVsScEP4pQyzVkgMsbrOwoLXWRnDA5bl7_-nz4w1_PYbHs7kCJu14Zw1jaBhCb3vgIyFJPMHGaFGLUdtv00ej-qe1NVeGs5SGo8qkMOrkmAVxHx7cqR1ZqqeTvVy6xGAFPhJGvdZRlOV6T1M9HJ3a25z1couxxGM4TnjUSFrKXfhe--PNrqZxiX-4Yia8psajdHdqgD4I4KT6ryESC2umjrIiEI7oQyok08ry7UKuswV2OlxgJXmJYFOr6hBvriet1T1K8N7sko5tDVIDEyoVyGkGasOAifm6XgUZO3cbNLmvYib5LoKMKDFyldZpUxLWQ2yiI0ux0k-c303GbZ8h5nw-OP8JQekoR5do0QHpENpLqonOuBwnQZiBGmH2BGHHTYXGDNIl5s3QYqmWKNRQUMMvAau7nPXgQvmvJs3xRpcmPKFy1x149cg4cTWVvIL99qqF8lfTu8Dr0r2ycGSIm0NDz6WsrMA6cUDNEWZLdCFxEZMl6T7U2IFXBW0CLDmJ9UONldp_cQHYA-aIojbGabali2Q3YgwAKBlxyIytx5azfiIrUy3w7YzUE8BGiWS9faNVbZqk7TfUxA0mevS00orIB7fBajjFpHnkRPXLsXlm0d6pZTBHMzyu1s77gT-82xJSJVvvkHA3SwtYpap0571i6g8VCzO_OkwDlxeQkY9P_BbcVYzEUxoeIrASHDZuuhT2rkJ1qOY0UbdZlGS5KOOO6R10Wi0z8p-SXJQRT9kOR1N2mhdGk_Z_rag_ptC5lHxc7dYZaT2NCqxEVI-GZa3fHcvyge6Gu0OzWP0o2N8x_8XpdTaJuMTcUd-vUlQdg-o1YKkmcoqzVjfZVLzqDF7tsS3KS7hO8cTL23IfERiTLhbOxtCkJTdtHTXhXVrgakFVdWBWUlxMxDP_Yyk12v-Dozi2pwBSbMdRIEJ1xvD4akO3=w2000-h1596',
    ogType: '网站',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'UWB RTLS，未来工业的开端',
    date: '2023-11-03',
    tag1: '#uwb',
    tag2: '#rtls',
    tag3: '#数字孪生',
    tag4: '#数字化转型',

    text: [
        {
            head: '',
            subHead: '',
            desc: '近年来，超宽带（UWB）实时定位系统（RTLS）技术在各种行业中迅速引起了关注。这项技术为位置跟踪和基于位置的服务领域带来了创新性的变革，预计将成为未来的解决方案。在本文中，我们将探讨UWB RTLS技术是什么，其应用领域，潜在优势以及未来如何进一步发展。',
            dot: ''
        },
        {
            head: '什么是UWB RTLS？',
            subHead: '',
            desc: 'UWB RTLS是一种实时位置跟踪系统，利用UWB技术提供精确的位置数据。UWB使用超宽带频宽传输数据，可以获取非常精确的位置信息。与传统的位置跟踪系统（如GPS或Wi-Fi）相比，该技术提供了更高的准确性和实时性。',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/cn/enterprise/manufacturing/process',
            head: '应用领域',
            subHead: '',
            desc: '<strong>建筑与建筑行业： </strong>在建筑工地，可以跟踪建筑物、设备和材料的位置，以优化工作计划并增强安全性。此外，可以借助高室内位置精度实施智能建筑系统。<br/><br/><strong>零售与分销： </strong>零售和分销企业可以实时跟踪产品的位置和库存，从而提高客户服务和库存管理。还可以通过分析客户购买行为来优化产品放置。<br/><br/><strong>交通与土地管理： </strong>跟踪道路和铁路网络中车辆、火车和其他交通工具的位置可以帮助管理交通拥堵并防止事故。土地管理部门可以通过管理地形和资源位置数据来有效分配资源。<br/><br/><strong>安全与安全管理： </strong>在关键设施、活动或大规模聚会中利用UWB RTLS来跟踪人员和物体的位置，以增强安全性和安全性。这使得在紧急情况下能够迅速响应。<br/><br/><strong>体育和娱乐： </strong>在体育场馆和活动场馆中，跟踪观众的位置可以优化活动组织并提供指导服务。还可以准确确定媒体制作所需的摄像机和照明设备的位置。',
            dot: ''
        },
        {
            head: '优势',
            subHead: '',
            desc: '<strong>高准确性： </strong>UWB技术相对于其他无线技术提供了更高的准确性。它基于传感器之间的距离测量，因此可以在室内和室外环境中提供精确的位置数据。<br/><br/><strong>低延迟： </strong>UWB提供快速的响应时间，因此可以快速获取实时位置信息。这提高了系统的响应速度和应急情况下的实用性。<br/><br/><strong>支持多用户： </strong>UWB可以同时跟踪多个用户或物体，这在大型活动或工业环境中非常重要。<br/><br/><strong>安全性和安全性： </strong>UWB最小化了与其他无线通信技术的干扰，并提供了高度的安全性。这确保了敏感数据和系统的安全性。<br/><br/><strong>室内和室外应用： </strong>UWB RTLS可以在室内和室外环境中都可以使用，这意味着它在各种行业中都具有通用性。<br/><br/><strong>低功耗运行： </strong>UWB技术支持低功耗的无线通信，可以延长电池寿命并提高能源效率。',
            dot: ''
        },
        {
            head: '未来展望',
            subHead: '',
            desc: 'UWB RTLS技术预计将在未来发挥更加重要的作用。未来将有更多设备和系统利用UWB相互连接，包括智能城市、智能工厂、自动驾驶汽车、医疗等各种领域都将应用UWB RTLS技术。这将带来提高生产力、增强安全性、改善环境和提高便利性的期望。<br/><br/>总的来说，UWB RTLS技术作为未来解决方案之一备受关注。考虑到其稳定性、准确性以及在各种行业中的应用潜力，这项技术有望对我们的日常生活和各种行业产生积极影响。通过这项技术的发展和普及，未来将变得更加智能和高效。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: '该产品通过将UWB终端设备安装在工人或设备上，支持实时位置跟踪。',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/cn/enterprise/construction/safety'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/cn/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/cn/enterprise/manufacturing/asset'
            }
        ]

    }
}
