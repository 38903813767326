export default {

  design: "产品设计",
  outlook: "产品外观",
  spec: "产品规格",


  product: {

    acces: [
      { text: "accesscontroller" },
      { text: "accessauto" },
      { text: "cloud" },
    ],

    accesscontroller: {
      title: '门禁控制器',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色, ABS/灰色, ABS/黑色, PC/半透明',
            },
            {
              title: '尺寸',
              description: '85.4mm x 85.4mm x 24.5mm (宽 x 高 x 厚)',
            },
          ]
        },

        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: 'Cortex M4 64MHz',
            },
            {
              title: '内存',
              description: '512KB, 64KB',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '连接性',
              description: 'BLE 5.2',
            }
          ]
        },

        {
          name: '界面',
          detail: [
            {
              title: '提示',
              description: 'RGB LED x 4',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '工作条件',
              description: '-10°C ~ +60°C, 0% ~ 90%',
            },
            {
              title: '电源',
              description: 'CR2477 1S 4P',
            }
          ]
        },

      ],

    },

    accessauto: {
      title: '自动门进出',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色, PC/半透明黑色',
            },
            {
              title: '尺寸',
              description: '210.1mm x 60.1mm x 42.2mm (宽 x 高 x 厚)',
            },
          ]
        },

        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: '双核 32位 LX6, 240MHz',
            },
            {
              title: '内存',
              description: 'FLASH 8MB, PSRAM 2MB',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '连接性',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n, 2.4GHz 微波, SUB 1GHz',
            }
          ]
        },

        {
          name: '界面',
          detail: [
            {
              title: '提示',
              description: '门控制, 震动检测, 蜂鸣器',
            },
            {
              title: '串口',
              description: 'RS232(USB 控制台)',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '工作条件',
              description: '0°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: '电源',
              description: 'DC 12V, 24V (无极性)',
            }
          ]
        },

      ],

    },

    cloud: {
      title: '云门禁控制器',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色, PC/半透明黑色',
            },
            {
              title: '尺寸',
              description: '210.1mm x 60.1mm x 42.2mm (宽 x 高 x 厚)',
            },
          ]
        },

        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: '双核 32位 LX6, 240MHz',
            },
            {
              title: '内存',
              description: 'FLASH 8MB, PSRAM 2MB',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '连接性',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n, 2.4GHz 微波, SUB 1GHz',
            }
          ]
        },

        {
          name: '界面',
          detail: [
            {
              title: '提示',
              description: '门控制, 震动检测, 蜂鸣器',
            },
            {
              title: '串口',
              description: 'RS232(USB 控制台)',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '工作条件',
              description: '0°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: '电源',
              description: 'DC 12V, 24V (无极性)',
            }
          ]
        },

      ],

    },
    serverpcRTLS: {
      title: '服务器电脑',
      spec: [
        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: 'Intel Xeon E-2334（3.4GHz 4核心 8M L3 65W）',
            },
            {
              title: '内存',
              description: '16GB 3200MT/s DDR4 ECC UDIMM，最大支持64GB，4个插槽',
            },
            {
              title: '存储',
              description: '1TB 7,200RPM SATA 3.5',
            },
          ],
        },
        {
          name: '网络',
          detail: [
            {
              title: '网络控制器',
              description: '内置Broadcom 5720双端口1Gb LOM',
            },
          ],
        },
        {
          name: '使用环境和电源',
          detail: [
            {
              title: '重量',
              description: '12.2kg',
            },
            {
              title: '扩展插槽',
              description: '2个PCIe插槽',
            },
            {
              title: '电源',
              description: '双电源、热插拔、冗余电源供应（1+1）、600W',
            },
          ],
        },
      ],
    },


    serverpc: {
      title: '服务器电脑',
      spec: [
        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: 'Intel Xeon silver 4210R, 1CPU, 10 Core',
            },
            {
              title: '内存',
              description: '32GB',
            },
            {
              title: '存储空间',
              description: '1TB + 4TB',
            },
          ]
        },


        {
          name: '使用环境和电源',
          detail: [
            {
              title: '重量',
              description: '17.6kg',
            },
            {
              title: '扩展插槽',
              description: '16 PCI Gen3 插槽 x 2',
            },
            {
              title: '电源',
              description: '450W',
            }
          ]
        },

      ],

    },



    accesspro: {
      title: '高级门禁控制器',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: '铝合金/灰色, PC/半透明',
            },
            {
              title: '尺寸',
              description: '256mm x 273mm x 24mm (宽 x 高 x 厚)',
            },
            {
              title: '重量',
              description: '1.2Kg',
            },
          ]
        },
        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: '四核-A17 1.8Ghz',
            },
            {
              title: '内存',
              description: '2GB / 16GB',
            },
          ]
        },

        {
          name: '传感器和摄像头',
          detail: [
            {
              title: '图像传感器',
              description: '1/3.06" CMOS, MN34229',
            },
            {
              title: '镜头类型',
              description: '定焦',
            },
            {
              title: '摄像头视角',
              description: '180º',
            },
            {
              title: '分辨率',
              description: '1920 x 1080',
            }
          ]
        },

        {
          name: '显示屏',
          detail: [
            {
              title: '分辨率',
              description: '10.1" TFT LCD 1280x800',
            },
          ]
        },

        {
          name: '界面',
          detail: [
            {
              title: '音频',
              description: '麦克风, 扬声器',
            },
            {
              title: '警报',
              description: '门控制, 紧急呼叫',
            },
            {
              title: 'USB',
              description: 'USB2.0 Host x2, USB2.0 OTG x 1',
            },
            {
              title: '串口',
              description: 'RS232(USB 控制台)',
            }
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '工作条件',
              description: '0°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: '电源',
              description: '适配器 12V / 3A',
            },
          ]
        },

      ],

    },


    accesskey: {
      title: '门禁钥匙',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: '铝合金/灰色, PC/半透明, ABS/白色',
            },
            {
              title: '尺寸',
              description: '49mm x 26mm x 9mm (宽 x 高 x 厚)',
            },
          ]
        },
        {
          name: '网络',
          detail: [
            {
              title: '连接性',
              description: '2.4GHz / 蓝牙 5.0',
            },
          ]
        },

        {
          name: '界面',
          detail: [
            {
              title: '警报',
              description: 'LED(状态)',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '工作条件',
              description: '0°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: '电源',
              description: '3.7V - 75mAh / 锂聚合物电池',
            },
            {
              title: '额定',
              description: '5V / 40mA',
            },
          ]
        },

      ],

    },

    emlock: {
      title: 'EM锁',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: '铝合金/灰色',
            },
            {
              title: '尺寸',
              description: '178mm x 38mm x 25mm (宽 x 高 x 厚)',
            },
          ]
        },
        {
          name: '功能和特点',
          detail: [
            {
              title: '吸合力',
              description: '280KG/F',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '工作条件',
              description: '-10°C ~ +55°C',
            },
            {
              title: '工作湿度',
              description: '0 - 90%',
            },
            {
              title: '电源',
              description: '12V/40mA',
            },
          ]
        },

      ],

    },

    deadbolt: {
      title: 'Deadbolt 锁',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: '铝合金/灰色',
            },
            {
              title: '尺寸',
              description: '203mm x 37mm x 41mm (宽 x 高 x 厚)',
            },
            {
              title: '重量',
              description: '529g',
            },
          ]
        },
        {
          name: '功能和特点',
          detail: [
            {
              title: '特点',
              description: '内置自动开关触点 / 锁状态监测 / 门状态监测可选',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '工作条件',
              description: '-10°C ~ +55°C',
            },
            {
              title: '工作湿度',
              description: '0 - 90%',
            },
            {
              title: '功耗电流',
              description: '工作时 900mA / 待机时 350mA',
            },
            {
              title: '电源',
              description: 'DC 12V',
            },
          ]
        },

      ],

    },

    autoDoorSensor: {
      title: '自动门传感器',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色, PC/半透明',
            },
            {
              title: '尺寸',
              description: '210.1mm x 60.1mm x 42.2mm (宽 x 高 x 厚)',
            }
          ]
        },
        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: '双核32位LX6，240MHz',
            },
            {
              title: '内存',
              description: 'FLASH 8MB，PSRAM 2MB',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '连接性',
              description: '24GHz 微波, SUB 1GHz',
            },
          ]
        },
        {
          name: '界面',
          detail: [
            {
              title: '警报',
              description: '门控制, 震动检测',
            },
          ]
        },
        {
          name: '使用环境和电源',
          detail: [
            {
              title: '工作条件',
              description: '0°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: '电源',
              description: 'DC 12V, 24V (非极性)',
            },
          ]
        },

      ],

    },



    twinTracker: {
      link: 'assetTagBle',
      title: 'TwinTracker',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色, PC/半透明白色, 铝合金/黑色',
            },
            {
              title: '尺寸',
              description: '95mm x 95mm x 32.5mm (宽 x 高 x 厚)',
            },
          ]
        },

        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: '双核32位LX6, 240MHz',
            },
            {
              title: '内存',
              description: '448KB ROM, 520KB SRAM',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '以太网',
              description: '100Base-TX',
            },
            {
              title: '无线',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n',
            }
          ]
        },

        {
          name: '界面',
          detail: [
            {
              title: '串行',
              description: 'RS232(USB控制台)',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '工作条件',
              description: '0°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: '电源',
              description: '3.2W, PoE 802.3af, USB 5V',
            }
          ]
        },

      ],

    },

    twinTrackerPro: {
      link: 'assetTagBle',
      title: 'TwinTracker Pro',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色, PC/半透明白色, 铝合金/黑色',
            },
            {
              title: '尺寸',
              description: '95mm x 95mm x 32.5mm (宽 x 高 x 厚)',
            },
          ]
        },

        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: '双核32位LX6, 240MHz',
            },
            {
              title: '内存',
              description: '448KB ROM, 520KB SRAM',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '以太网',
              description: '100Base-TX',
            },
            {
              title: '无线',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n',
            }
          ]
        },

        {
          name: '界面',
          detail: [
            {
              title: '串行',
              description: 'RS232(USB控制台)',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '工作条件',
              description: '-20°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: '电源',
              description: '3.2W, PoE 802.3af, USB 5V',
            }
          ]
        },

      ],

    },


    assetTagBle: {
      title: '资产标签(BLE)',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色, ABS/黑色, PC/半透明',
            },
            {
              title: '尺寸',
              description: '48.4mm x 48.4mm x 10.7mm (宽 x 高 x 厚)',
            },
            {
              title: '重量',
              description: '22g',
            },
          ]
        },

        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: 'Cortex M4 64MHz',
            },
            {
              title: '内存',
              description: '512KB',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '无线',
              description: 'BLE 5.2',
            }
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '工作条件',
              description: '-20°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: '电源',
              description: 'CR2450',
            }
          ]
        },

      ],

    },
    assetTag: {
      title: '资产标签',
      spec: [
        {
          name: '外观及材料',
          detail: [
            {
              title: '材料',
              description: 'ABS/白色, ABS/灰色',
            },
            {
              title: '尺寸',
              description: '31mm x 31mm x 3.8mm (宽 x 高 x 厚)',
            },
          ]
        },

        {
          name: '处理器及内存',
          detail: [
            {
              title: '处理器',
              description: 'Cortex M4 64MHz',
            },
            {
              title: '内存',
              description: '64KB',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '无线',
              description: 'BLE 5.2',
            }
          ]
        },

        {
          name: '使用环境及电源',
          detail: [
            {
              title: '工作条件',
              description: '-20°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: '电源',
              description: 'CR2012',
            }
          ]
        },

      ],

    },


    tagUwb: {
      title: 'Tag(UWB)',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/黑色',
            },
          ]
        },
        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: 'Cortex M4 64MHz',
            },
            {
              title: '内存',
              description: '512KB',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '无线',
              description: 'UWB 802.15.4',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '工作条件',
              description: '-20°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: '电源',
              description: 'USB-C',
            },
          ]
        },

      ],

    },




    safetyBell: {
      title: 'Safety Bell',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色, ABS/黑色, PC/半透明白色',
            },
            {
              title: '尺寸',
              description: '64.2mm x 64.2mm x 16.2mm (宽 x 高 x 厚)',
            },
          ]
        },
        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: 'ARM Cortex M4, 64Mhz',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '无线',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n',
            },
          ]
        },
        {
          name: '界面',
          detail: [
            {
              title: '音频',
              description: '蜂鸣器',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '工作条件',
              description: '-20°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: '电源',
              description: 'AAA x 2',
            },
          ]
        },

      ],

    },




    smartSwitch: {
      title: 'Smart Switch',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色, ABS/黑色, 铝合金/银色',
            },
            {
              title: '尺寸',
              description: '76mm x 120mm x 44.3mm (宽 x 高 x 厚)',
            },
          ]
        },
        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: 'ARM® Cortex®-M4 32位带FPU的处理器，64MHz',
            },
            {
              title: '内存',
              description: '从闪存中运行的215 EEMBC CoreMark®分数',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '无线',
              description: 'BLE 5.0，Wi-Fi 802.11 b/g/n',
            },
          ]
        },

        {
          name: '界面',
          detail: [
            {
              title: '触摸',
              description: '',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '工作条件',
              description: '0°C ~ +60°C',
            },
          ]
        },

      ],

    },




    esl: {
      title: 'ESL',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色, PC/半透明',
            },
            {
              title: '尺寸',
              description: '76mm x 142mm x 10mm (宽 x 高 x 厚)',
            },
          ]
        },
        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: 'ARM® Cortex®-M4 32位带FPU的处理器，64MHz',
            },
            {
              title: '内存',
              description: '从闪存中运行的215 EEMBC CoreMark®分数',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '无线',
              description: 'BLE 5.0',
            },
          ]
        },

        {
          name: '显示',
          detail: [
            {
              title: '分辨率',
              description: '800 x 600(230dpi)',
            },
            {
              title: '颜色',
              description: '黑色, 白色',
            },
            {
              title: '最大灰度',
              description: '16',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '连接器',
              description: 'USB-C',
            },
            {
              title: '电源',
              description: '5V, 500mA',
            },
          ]
        },


      ],

    },

    mTag: {
      title: 'Mobile Tag',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色, PC/半透明',
            },
            {
              title: '尺寸',
              description: '38mm x 50.1mm x 18mm (宽 x 高 x 厚)',
            },
          ]
        },
        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: 'ARM® Cortex®-M4 64MHz',
            },
            {
              title: '内存',
              description: '512KB',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '无线',
              description: 'BLE 5.2',
            },
          ]
        },

        {
          name: '界面',
          detail: [
            {
              title: '通知',
              description: 'LED(白色) x 1',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '连接器',
              description: 'Lightning, USB-C',
            },
            {
              title: '电源',
              description: '锂聚合物电池(4.2V, 35mAh)',
            },
          ]
        },


      ],

    },

    mCradle: {
      title: 'Mobile Cradle',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色, PC/半透明',
            },
            {
              title: '尺寸',
              description: '76mm x 40mm x 25mm (宽 x 高 x 厚)',
            },
          ]
        },
        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: 'ARM® Cortex®-M4 64MHz',
            },
            {
              title: '内存',
              description: '512KB',
            },
            {
              title: '传感器',
              description: '三轴传感器',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '无线',
              description: 'BLE 5.2',
            },
          ]
        },

        {
          name: '界面',
          detail: [
            {
              title: '通知',
              description: 'LED(白色) x 1',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '电源',
              description: 'DC 5V(USB-C类型)',
            },
          ]
        },


      ],

    },


    ptz: {
      title: 'PTZ Camera',
      spec: [
        {
          name: '外观',
          detail: [
            {
              title: '尺寸',
              description: '266mm x 266mm x 410mm (宽 x 高 x 厚)',
            },
          ]
        },

        {
          name: '摄像头',
          detail: [
            {
              title: '图像传感器',
              description: '1/1.8英寸渐进扫描CMOS',
            },
            {
              title: '变焦',
              description: '42倍光学, 16倍数字',
            },
            {
              title: '分辨率',
              description: '2560 x 1440',
            }
          ]
        },

        {
          name: '镜头',
          detail: [
            {
              title: '焦距',
              description: '6.0mm - 252mm',
            },
            {
              title: '变焦速度',
              description: '4.5秒',
            },
            {
              title: '光圈',
              description: 'F 1.2',
            },
          ]
        },

        {
          name: '云台',
          detail: [
            {
              title: '移动范围(水平)',
              description: '360°',
            },
            {
              title: '移动范围(垂直)',
              description: '-20°至90°',
            },
          ]
        },

        {
          name: '界面',
          detail: [
            {
              title: '以太网',
              description: '1个RJ45 10M/100M自适应以太网端口，Hi-PoE',
            },
            {
              title: '警报',
              description: '7个输入，2个输出',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '电源',
              description: '24VAC, PoE',
            },
            {
              title: '工作条件',
              description: '-40°C ~ +70°C, 0% ~ 95%',
            },
            {
              title: '防水防尘',
              description: 'IP67',
            },
          ]
        },

      ],

    },



    ip: {
      title: 'IP 摄像头',
      spec: [
        {
          name: '外观',
          detail: [
            {
              title: '尺寸',
              description: '135mm x 135mm x 110mm (宽 x 高 x 厚)',
            },
            {
              title: '重量',
              description: '约800g',
            },
          ]
        },

        {
          name: '摄像头',
          detail: [
            {
              title: '图像传感器',
              description: '1/2.8英寸渐进扫描CMOS',
            },
            {
              title: '分辨率',
              description: '1920 × 1080',
            }
          ]
        },

        {
          name: '镜头',
          detail: [
            {
              title: '镜头类型',
              description: '固定焦距镜头，2.8mm和4mm选项',
            },
            {
              title: '光圈',
              description: 'F 1.4',
            },
          ]
        },

        {
          name: '界面',
          detail: [
            {
              title: '以太网',
              description: 'RJ45 10 M/100 M自适应以太网端口1个',
            },
            {
              title: '警报',
              description: '-S: 警报输入1个，警报输出1个（最大12 VDC，30 mA）',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '电源',
              description: '12 VDC',
            },
            {
              title: '工作条件',
              description: '-30°C ~ +60°C, 0% ~ 95%',
            },
            {
              title: '防水防尘',
              description: 'IP67',
            },
          ]
        },

      ],

    },

    nvr: {
      title: 'NVR',
      spec: [
        {
          name: '视频和音频',
          detail: [
            {
              title: 'IP视频输入',
              description: '8通道',
            },
            {
              title: '视频输出模式',
              description: 'HDMI/VGA独立输出',
            },
            {
              title: 'HDMI输出',
              description: '4K (3840 × 2160)/30Hz, 2K (2560 × 1440)/60Hz, 1920 × 1080/60Hz, 1600 × 1200/60Hz, 1280 × 1024/60Hz, 1280 × 720/60Hz ',
            },
            {
              title: 'VGA输出',
              description: '1920 × 1080/60 Hz, 1280 × 1024/60 Hz, 1280 × 720/60 Hz',
            },
            {
              title: '音频输入',
              description: '1通道，RCA (2.0 Vp-p, 1 KΩ) ',
            },
            {
              title: '音频输出',
              description: '1通道，RCA (线性, 1 KΩ)',
            }
          ]
        },

        {
          name: '解码',
          detail: [
            {
              title: '解码格式',
              description: 'H.265/H.265+/H.264/H.264+',
            },
            {
              title: '录像分辨率',
              description: '12 MP/8 MP/6 MP/5 MP/4 MP/3 MP/1080p/UXGA/720p/VGA/4CIF/DCIF/2CIF/CIF/QCIF',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '网络协议',
              description: 'TCP/IP, DHCP, IPv4, IPv6, DNS, DDNS, NTP, RTSP, SADP, SMTP, SNMP, NFS, iSCSI, ISUP, UPnP™, HTTP, HTTPS',
            },
            {
              title: '网络界面',
              description: '1个RJ-45 10/100/1000 Mbps自适应以太网界面',
            },
          ]
        },

        {
          name: '使用环境和电源',
          detail: [
            {
              title: '电源',
              description: '12VDV',
            },
            {
              title: '运行条件',
              description: '-10°C ~ +55°C, 10% ~ 90%',
            },
          ]
        },


      ],

    },


    loraGateway: {
      title: 'LoRa 网关',
      spec: [
        {
          name: '通用规格',
          detail: [
            {
              title: '材质',
              description: '铝/灰色',
            },
            {
              title: '尺寸',
              description: '145mm x 145mm x 42mm（宽 x 高 x 深）',
            },
            {
              title: '重量',
              description: '750克',
            },
            {
              title: '射频范围',
              description: '920.9MHz～923.1MHz 带宽125KHz',
            },
            {
              title: '通信距离',
              description: '视线距离（LOS）最多6公里',
            },
            {
              title: '天线接收灵敏度',
              description: '-135 dBm',
            },
            {
              title: '天线发射输出',
              description: '20dBm',
            },
            {
              title: '安全性',
              description: 'AES-128',
            },
          ],
        },
        {
          name: '以太网网关',
          detail: [
            {
              title: '以太网规格',
              description: '10/100 以太网控制器',
            },
            {
              title: 'IEEE标准兼容性',
              description: '802.3',
            },
            {
              title: '驱动方式',
              description: '全双工和半双工',
            },
            {
              title: '支持协议',
              description: 'DHCP、DNS、NTP、UDP、TCP、SNMP',
            },
            {
              title: '电缆连接',
              description: '以太网',
            },
          ],
        },
        {
          name: 'Wi-Fi',
          detail: [
            {
              title: 'IEEE标准兼容性',
              description: '802.11b/g/n 2.4GHz',
            },
          ],
        },
        {
          name: '蜂窝LTE',
          detail: [
            {
              title: 'LTE规格',
              description: 'LTE CatM1（Telit Me910 C1）',
            },
            {
              title: 'SIM卡规格',
              description: 'Micro-SIM（3FF）15mm x 12mm x 0.76mm',
            },
          ],
        },
        {
          name: '使用环境和电源',
          detail: [
            {
              title: '操作条件',
              description: '-20°C ～ 60°C',
            },
            {
              title: '电源',
              description: 'DC 8V ～ 24.0V',
            },
            {
              title: '认证',
              description: 'KC、IP67',
            },
          ],
        },
      ],
    },
    loraSensor: {
      title: 'LoRa 传感器',
      spec: [
        {
          name: '通用规格',
          detail: [
            {
              title: '材质',
              description: '铝/灰色',
            },
            {
              title: '尺寸',
              description: '84mm x 72mm x 31mm（宽 x 高 x 深）',
            },
            {
              title: '重量',
              description: '300克',
            },
            {
              title: '射频范围',
              description: '920.9MHz～923.1MHz 带宽125KHz',
            },
            {
              title: '通信方式',
              description: 'LoRa',
            },
            {
              title: '通信距离',
              description: '视线距离（LOS）最多6公里',
            },
            {
              title: '安全性',
              description: 'AES-128',
            },
          ],
        },
        {
          name: '使用环境和电源',
          detail: [
            {
              title: '操作条件',
              description: '-20°C ～ 60°C',
            },
            {
              title: '电源',
              description: 'DC 9V ～ 33V<br/>AA电池 DC 3.6V / 2,500mAh x 2节（并联连接总共5,000mAh）<br/>AA电池 DC 1.5V / 2,850mAh x 2节（串联连接总共2,850mAh）',
            },
            {
              title: '认证',
              description: 'KC、IP67',
            },
          ],
        },
      ],
    },
    airQuality: {
      title: '空气质量检测仪',
      spec: [
        {
          name: '通用规格',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色',
            },
            {
              title: '尺寸',
              description: '115mm X 115mm X 30mm（宽 x 高 x 深）',
            },
            {
              title: '重量',
              description: '215克',
            },
            {
              title: '显示',
              description: '2.0英寸TFT显示屏',
            },
            {
              title: '通信方式',
              description: 'LoRa（可选）、RS-485',
            },
          ],
        },
        {
          name: '测量范围',
          detail: [
            {
              title: '微粒子',
              description: 'PM1.0/PM2.5/PM4.0/PM10 : 0 ~ 1,000 µg/m³',
            },
            {
              title: '二氧化碳（CO2）',
              description: '400 ~ 10,000 ppm',
            },
            {
              title: '总挥发性有机化合物（TVOC）',
              description: '0 ~ 6,000 ppb',
            },
            {
              title: '温室气体',
              description: '0 ~ 60,000 ppm',
            },
            {
              title: '温度',
              description: '-40°C ～ 125°C',
            },
            {
              title: '湿度',
              description: '0 ~ 100 %RH',
            },
          ],
        },
        {
          name: '使用环境和电源',
          detail: [
            {
              title: '操作条件',
              description: '-20°C ～ 60°C',
            },
            {
              title: '电源',
              description: 'DC 24V / 0.1A<br/>锂聚合物电池 1500mAh/3.7V',
            },
            {
              title: '认证',
              description: 'KC',
            },
          ],
        },
      ],
    },
    coMeasure: {
      title: '一氧化碳（CO）检测仪',
      spec: [
        {
          name: '通用规格',
          detail: [
            {
              title: '材质',
              description: '铝/灰色',
            },
            {
              title: '尺寸',
              description: '93mm X 125mm X 44mm（宽 x 高 x 深）',
            },
            {
              title: '重量',
              description: '245克',
            },
            {
              title: '通信方式',
              description: 'WiFi（2.4GHz 802.11 b/g/n）和RS-485（Modbus RTU）',
            },
          ],
        },
        {
          name: '测量范围',
          detail: [
            {
              title: '微粒子',
              description: 'PM1.0/PM2.5/PM4.0/PM10 : 0 ~ 1,000 µg/m³',
            },
            {
              title: '一氧化碳（CO）',
              description: '0 ~ 100 ppm（室温标准）',
            },
            {
              title: '温度',
              description: '-40°C ～ 125°C',
            },
            {
              title: '湿度',
              description: '0 ~ 100 %RH',
            },
          ],
        },
        {
          name: '使用环境和电源',
          detail: [
            {
              title: '操作条件',
              description: '-10°C ～ 40°C',
            },
            {
              title: '电源',
              description: 'DC 24V / 0.1A',
            },
            {
              title: '认证',
              description: 'KC/CE/FCC',
            },
          ],
        },
      ],
    },
    plutoconPro: {
      title: 'Plutocon Pro',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色、ABS/灰色、ABS/黑色、PC/半透明',
            },
            {
              title: '尺寸',
              description: '85.4mm x 85.4mm x 24.5mm（宽 x 高 x 深）',
            },
          ],
        },
        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: 'Cortex M4 64MHz',
            },
            {
              title: '内存',
              description: '512KB、64KB',
            },
          ],
        },
        {
          name: '网络',
          detail: [
            {
              title: '连接性',
              description: 'BLE 5.2',
            },
          ],
        },
        {
          name: '接口',
          detail: [
            {
              title: '警示',
              description: 'RGB LED x 4',
            },
          ],
        },
        {
          name: '使用环境和电源',
          detail: [
            {
              title: '操作条件',
              description: '-10°C ~ +60°C，0% ~ 90%',
            },
            {
              title: '电源',
              description: 'CR2477 1S 4P',
            },
          ],
        },
      ],
    },
    orbroTag: {
      title: 'ORBRO 标签',
      spec: [
        {
          name: '外观和材质',
          detail: [
            {
              title: '材质',
              description: 'ABS/白色、ABS/黑色、PC/半透明',
            },
            {
              title: '尺寸',
              description: '48.4mm x 48.4mm x 10.7mm（宽 x 高 x 深）',
            },
            {
              title: '重量',
              description: '22克',
            },
          ],
        },
        {
          name: '处理器和内存',
          detail: [
            {
              title: '处理器',
              description: 'Cortex M4 64MHz',
            },
            {
              title: '内存',
              description: '512KB',
            },
          ],
        },
        {
          name: '网络',
          detail: [
            {
              title: '无线',
              description: 'BLE 5.2',
            },
          ],
        },
        {
          name: '使用环境和电源',
          detail: [
            {
              title: '操作条件',
              description: '-20°C ~ +40°C，0% ~ 90%',
            },
            {
              title: '电源',
              description: 'CR2450',
            },
          ],
        },
      ],
    },

    idCard: {
      title: 'ID02',
      spec: [
        {
          name: '外观及材料',
          detail: [
            {
              title: '材料',
              description: 'ABS/白色, 铝',
            },
            {
              title: '尺寸',
              description: '51mm x 95.9mm x 7mm(宽 x 高 x 厚)',
            },
          ]
        },
        {
          name: '处理器及内存',
          detail: [
            {
              title: '处理器',
              description: 'ARM® Cortex®-M4 32位处理器带 FPU, 64MHz',
            },
            {
              title: '内存',
              description: '215 EEMBC CoreMark® 从闪存运行得分<br/>58μA/MHz 从闪存运行<br/>51.6μA/MHz 从RAM运行。',
            },
          ]
        },

        {
          name: '网络',
          detail: [
            {
              title: '无线',
              description: 'BLE 5.2',
            },
          ]
        },

        {
          name: '接口',
          detail: [
            {
              title: '通知',
              description: 'LED(RGB) x 1',
            },
            {
              title: 'USB',
              description: 'USB 2.0 设备 x 1',
            },
            {
              title: '按钮',
              description: '滑动开关按钮',
            },
          ]
        },

        {
          name: '使用环境及电源',
          detail: [
            {
              title: '工作条件',
              description: '0°C ~ 50°C',
            },
            {
              title: '电源',
              description: 'Li-polymer (3.7V / 300mA)',
            },
          ]
        },


      ],

    },
    deliveryRobot: {
      title: '配送机器人',
      spec: [
        {
          name: ' ',
          detail: [
            {
              title: '材料',
              description: 'ABS/白色, PC/半透明白色, 铝/黑色',
            },
            {
              title: '尺寸',
              description: '510 x 510 x 1300 (宽 x 深 x 高)',
            },
            {
              title: '载重容量',
              description: '20L',
            },
            {
              title: '运行环境',
              description: '室内',
            },
            {
              title: '行驶倾角',
              description: '+5度/-5度',
            },
            {
              title: '行驶台阶高度',
              description: '1cm/-1cm',
            },
            {
              title: '照度',
              description: '60 ~ 2,000lx (最小 ~ 最大)',
            },
          ]
        },
      ],
    },

    loadingKiosk: {
      title: '装载终端',
      spec: [
        {
          name: ' ',
          detail: [
            {
              title: '尺寸',
              description: '720 x 800 x 611 (宽 x 深 x 高)',
            },
            {
              title: '处理容量',
              description: '20L/30L',
            },
            {
              title: '显示屏',
              description: '10.1英寸 多点触控显示屏',
            },
            {
              title: '网络',
              description: '以太网 (100Base-TX)/Wi-Fi',
            },
            {
              title: '电源',
              description: '100~240VAC/120W',
            },
          ]
        },
      ],
    },

    cobot: {
      title: '协作机器人',
      spec: [
        {
          name: ' ',
          detail: [
            {
              title: '尺寸',
              description: '220 x 227 x 1300 (宽 x 深 x 高)',
            },
            {
              title: '最大距离',
              description: '1300mm',
            },
            {
              title: '最大负载',
              description: '12kg',
            },
            {
              title: '自由度',
              description: '60DoF',
            },
            {
              title: '网络',
              description: '以太网 (100Base-TX)',
            },
            {
              title: '电源',
              description: '100~240VAC/1kW',
            },
          ]
        },
      ],
    },


    apart: {
      title: 'multi',
      list: [
        { text: "accesscontroller" },
        { text: "serverpc" },
      ]

    },


    assetTracking: {
      title: 'multi',
      list: [
        { text: "twinTracker" },
        { text: "assetTagBle" },
        { text: "serverpcRTLS" },
      ]

    },

    workerTracking: {
      title: 'multi',
      list: [
        { text: "twinTrackerPro" },
        { text: "tagUwb" },
        { text: "serverpcRTLS" },
      ]

    },

    carTracking: {
      title: 'multi',
      list: [
        { text: "ptz" },
        { text: "nvr" },
        { text: "serverpc" },
      ]

    },

    peopleTracking: {
      title: 'multi',
      list: [
        { text: "ip" },
        { text: "nvr" },
        { text: "serverpc" },
      ]

    },


    robotBasic: {
      title: 'multi',
      list: [
        { text: 'deliveryRobot' },
        { text: 'twinTrackerPro' },
        { text: 'tagUwb' },
        { text: 'serverpc' },
      ]
    },

    delivery: {
      title: 'multi',
      list: [
        { text: 'deliveryRobot' },
        { text: 'loadingKiosk' },
        { text: 'cobot' },
        { text: 'twinTrackerPro' },
        { text: 'tagUwb' },
        { text: 'serverpc' },
      ]
    },

    disinfection: {
      title: 'multi',
      list: [
        { text: 'deliveryRobot' },
        { text: 'loadingKiosk' },
        { text: 'cobot' },
        { text: 'twinTrackerPro' },
        { text: 'tagUwb' },
        { text: 'serverpc' },
      ]
    }




  }

}