import { StyledGridContainer } from "@components/Grid/Grid.style";
import { ParagraphColor, Spacing, SpacingDTM } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import digitaltwin from "@src/lang/ko/digitaltwin/digitaltwin";
import React from "react";
import { Trans } from "react-i18next";
import { FuncBox, FuncTextBox, FuncVideoBox } from "./Func.style";
import { VideoPlayCover } from "@pages/CommonStyle/commonVideo";
import { DisplayH2color } from "@components/Text/HeadingTagDisplay.style";
import { HeadingH3white } from "@components/Text/Heading/Heading.style";
const Func = () => {
  return (
    <>
      <StyledGridContainer>
        <DivAllCenter>
          <DisplayH2color color="#FFF" size="small">
            <Trans i18nKey={`digitaltwin.func.title`} />
          </DisplayH2color>
          <Spacing pixel="8"/>
          <ParagraphColor color="#E0E0E0" size="large">
            <p>
              <Trans i18nKey={`digitaltwin.func.desc`} />
            </p>
          </ParagraphColor>
          <SpacingDTM d={"64"} t={"64"} m={"0"} />
        </DivAllCenter>

        {digitaltwin.func.card.map((data, i) => (
          <>
            <FuncBox>
              <FuncTextBox>
                <HeadingH3white size="small" color="#FFF">
                  <Trans i18nKey={`digitaltwin.func.card.${i}.title`} />
                </HeadingH3white>
                <ParagraphColor size="medium" color="#A0A0A0">
                  <p>
                    <Trans i18nKey={`digitaltwin.func.card.${i}.desc`} />
                  </p>
                </ParagraphColor>
              </FuncTextBox>
              <FuncVideoBox index={i}>
                <VideoPlayCover
                  autoPlay
                  loop muted
                  playsInline>
                  <source src={data.video} type="video/mp4" />
                </VideoPlayCover>
              </FuncVideoBox>
            </FuncBox>
          </>
        ))}

      </StyledGridContainer>
      <SpacingDTM d={"256"} t={"128"} m={"96"} />
    </>
  )
}


export default Func;