import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { IconAccess, IconAlarm, IconWarn, IconZoom } from "@assets/images/main";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/constructionAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo4.mp4";
import subVideo5 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo5.mp4";

export default {
    
    banner : {
        title : '건설 자재 관리',
        desc : '정확한 건설 자재 관리는 결국 생산성을 향상시킵니다. 자재 위치 추적을 통해 자원 낭비를 줄이고 품질을 유지하며 더 나아가 안전에 영향을 끼칠 정도로 자재 관리는 건설 현장에서 중요한 부분입니다.',

    },

    video : {
        title : '자재 관리를 정확하고 빠르게.',
        desc : '자재의 위치를 실시간으로 알려줍니다. 자재의 위치를 찾는 데 걸리는 시간이 거의 들지 않습니다.<br/>또한, 수량 관리까지 가능하므로 이는 RTLS 를 사용하기 <strong>이전과는 다른 수준의 생산성</strong>을 갖게 됩니다.',
        play : video
    },

    func : [
        {
            title : '자재 수량 관리 및 위치 추적',
            desc : '정확한 자재 수량을 파악하는 것은 기본입니다. 또한 위치를 실시간으로 파악하여 자재가 현재 어느 위치에 몇개가 있는지 파악 합니다.',
            sub1 : '높은 정확도 제공',
            icon1 : IconRulerGray,
            sub2 : '통계 데이터 제공',
            icon2 : IconChartBarGray,
            video : subVideo1
            
        },
        {
            title : '자동 입출고',
            desc : '자재가 특정 지점을 통과할때, 자동으로 입출고 기록을 생성합니다. 이 데이터는 정확하므로, 사람의 실수를 방지하고 인력을 절약할 수 있습니다.',
            sub1 : '빠른 데이터 연동',
            icon1 : IconTvocGray,
            sub2 : '실시간 위치 추적',
            icon2 : IconBinocularsGray,
            video : subVideo2
        },
        {
            title : '안전관리',
            desc : '잠재적으로 안전사고를 일으킬 가능성이 있는 자재는 각별하게 관리되어야 합니다. 실시간으로 위치를 추적할 뿐만 아니라, 근처에 지나가는 작업자도 파악하여 사고를 예방할 수 있습니다.',
            sub1 : '근접 기준 거리 설정',
            icon1 : IconRulerGray,
            sub2 : '대응 방식 설정',
            icon2 : IconBellGray,
            video : subVideo3
        },
        {
            title : '시간 절감',
            desc : '작업자들이 자재를 찾는 데 걸리는 시간을 대폭 줄여줍니다. 현장에서 작업 시간은 곧 생산성을 의미합니다. 낭비되는 리소스 없이 최적의 작업 현장을 이룹니다.',
            sub1 : '실시간 현황 파악',
            icon1 : IconClockGray,
            sub2 : '실시간 위치 추적',
            icon2 : IconBinocularsGray,
            video : subVideo4
        },
        {
            title : '도난 방지',
            desc : '자재를 분실하였다면 단순한 실수인지, 아니면 자재를 도난당한 것인지 구분하기 어렵습니다. 하지만 실시간으로 자재 위치를 파악하게 된다면, 이로써 낭비되는 에너지와 비용을 미연에 방지합니다.',
            sub1 : '대응 방식 설정',
            icon1 : IconBellGray,
            sub2 : '외부 시스템 연동',
            icon2 : IconChainLinkGray,
            video : subVideo5
        },


    ],



    subfunc : {
        
        title : '다양한 기능',
        desc : '현장에서 미숙한 자재관리는 생산성에 직접적인 영향을 끼칩니다. 최적화된 프로세스를 통해서 효율성을 높이고 낭비되는 인적 자원을 비롯한 리소스들을 제거할 수 있습니다. 이를 위해 오브로는 최적화된 다양한 기능들을 제공합니다.',
        
        data : [
            {
                icon : IconChartRadius,
                title : '리포트 및 분석',
                desc : '현장 데이터를 기반으로 보고서 및 분석 자료를 생성할 수 있습니다.',
            },
            {
                icon : IconBell,
                title : '실시간 알람 기능',
                desc : '자재에 이상 상황이 발생하는 경우 알람을 보내 즉각적으로 대처할 수 있습니다.',
            },
            {
                icon : IconRoute    ,
                title : '동선 이력 조회',
                desc : '자재가 현장에서 움직였던 동선을 조회하여 데이터화 할 수 있습니다.',
            },
            {
                icon : IconDashboard,
                title : '현황 파악',
                desc : '현재 얼마나 많은 자재가 어디로 이동하고 있는지 파악합니다.',
            },
            {
                icon : IconWarning,
                title : '이탈 감지',
                desc : '자재가 현장에서 이탈하거나 잘못된 곳으로 이동하는 것을 감지합니다.',
            },
            {
                icon : IconSearch,
                title : '동선 패턴 분석',
                desc : '자재가 현장에서 얼마만큼 사용되고 언제 쓰였는지 분석할 수 있습니다.',
            },
            {
                icon : IconChainLink,
                title : '데이터 연동',
                desc : 'RFID, IOT, 보안, 영상 등의 시스템과 연결하여 효율적으로 활용할 수 있습니다.',
            },
            {
                icon : IconSecurity,
                title : '데이터 보안',
                desc : '높은 데이터 보안 수준을 유지하여 신뢰성이 높은 솔루션을 제공합니다.',
            },


        ],
    },

    system : {
        title : '시스템 구성',
        img : rtlsSystem
    },

    productTitle : '제품 구성',
    product : [

        {
            tag : 'assetPackage'
        },
        {
            tag : 'workerPackage'
        },
    ],


    contents : [
        {
        data : 'constructionEquipment'
        },
        {
        data : 'constructionSafety'
        },
        {
        data : 'blogMedical'
        },
        {
        data : 'blogRTLS'
        },

    ]



}