import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";

export const SubFuncPage = styled.div`
  padding-top : 128px;
  padding-bottom : 128px;
  border-bottom : 1px solid rgba(0,0,0,0.08);
  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    padding-top : 96px;
    padding-bottom : 96px;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding-top : 96px;
    padding-bottom : 64px;
  }

`;

export const SubFuncSection = styled.div`
  margin-top : 64px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 32px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    margin-top : 48px;
    display : flex;
    flex-direction : column;
    grid-gap: 16px;

  }

`


export const SubFuncBox = styled.div`
  display : flex;
  align-items : center;
  text-align : center;
  flex-direction : column;
  border-radius: 12px;
  padding: 0px 24px;
  flex: 1 0 0;

  height : 224px;
  background-color : #F8F8F8;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    height : 200px;
  }
`;


export const SubFuncIcon = styled.img`
  width : 28px;
  height : 28px;
  object-fit : contain;
`;


