import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


    helmet: '',
    desc: '实时定位追踪系统（RTLS）是安全管理医疗废物的完美解决方案。通过监控直至风险消除，可以预防安全事故。',
    keywords: '医疗废物管理，医疗废物处理，废物管理，废物处理，医疗废物，医疗事故管理，医疗设备管理，安全事故管理，医疗设施，医院废物管理，医院废物处理，医院废物，污染设备管理，污染设备处理，实时定位追踪，RTLS，奥布罗，康技，奥博罗',

    ogTitle: '',
    ogDesc: '',
    ogSite: '智能技术管理医疗废物与资产 :: ORBRO Blog',
    ogUrl: 'https://orbro.io/cn/blog/medicalWaste',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4MONQZidMWIaTrmLqrkg911u867GiKnQvRp2J_6pXN-o_ulq8j5B2yFxT7ir2txi5c7bIVIt8hiyAS_8bBcYHCvfHVgU19Cv-9Zti_3iLiSQjzwRtscyVXg8ra_zcueLOOTV98WMwQPJvqq6lLm9WCS1uuU0kQTDiObKEJgcPUH1VWuCsr2icfJp2XeQo37pAjWJRRYRO0V94Rfg7K5kCNY2i6xyCb9v7ScCCWn1TDV770PCeZCTaqxvMsBaKLqMGHrg7rel0zDkwCNNCmA3Ztbw-uSIi1yvmAFmD-UwvSJ_CDlAoODePdXtGUuvvbo7nwKL38Ku_KgOEwMjm_fu5OmSTGuXLj-6GyiDXO_BGO4kRGAVRu1XLrfWPPX4GUQvDghijtlavji5s42a6EmSNBQKQ5PNV6Xi1LKTyhsOFRT6H69kayRpXz0PGQr9o9PwF9V8wif_gyo5Yp3WupUyxj2Bjc7jiHRBLBbeT8jDbZzdNEcYtdGJm6AiYBu1d3eNxk3awkxVXesGtS7MS_zXLKg03XqO_AyofzksiJ4jqyJBJu-atlMmflMnpcow2pp_JmyyTwQqPnikDNlTZ3iywdxdsseVVU_LfDL_koySbuLXVfcbrwXThmZI0aBrW-7R3HsTN_axhMhnxoIf16Le7Oe_9dD2o29Udu271POE6W1-FFfn7mj9Qh3td3uOl5KqXAZKoy3pB9Zw5bn3wgCANBWgV5BuOaQS5tPDxrFloyRt8VHGipdKgP5Cvz8M0ZiHdq_S3Ykh3eXE2z8Mu5e1ruL-FRtmbxQTl-PaOT6LrZS3WPhkOZrgEPel4AFv93GXcBg1YXw4MHsW-w6k_pJWGv43aL2AOsJ0G-xSso8gxdCh8Z6A3j75V0HVSHZ77OVUrJsYgEIXN-XGaCdMmDEaIJEGaJkC6ryDiPWnbogd0em8sf7YiKictJq9jl-aD_OGuOALvvAAmKdAsVzzZGMUp19SWDeI4R1UT_uYZ1_vC8ocRAurJg-kwM_gZu2TlLRPMkLUdDFDNVDoOYGyq47vgWJNg6KX-VtP5NwZ1K0afRlbOl1ho7d69Hl15XsQiqOQla9c1j7YFVQiUFWpx6GR3QhPt6XHSOufQfw6OOqtMz5s3Ibt69c0hgIzV1I2dVXetfGUDWGSoYXmpbxpF2M0KrnAjCojSgEXMU6X4DEcdo_yJrru5Tl8J1_cBMr0nm09QJSNg-qXDoYtS6wq-L1L4uy-MlHr_N4G6minr4oerAf1zu8o9jL3e9XpOkJdIo0sYln8FRjzLZ4J8AdpQF4pDo768VucYPzCgSrOJ_4-ARZoCuJKqB_lQJ2bIAIST0_STyL4tpQPSFWwnrMgAdgMgAams6EhbAQCgMV1xTZjRvd5DptWwTMknoV2YJ4sVcLtlZ3sdE4wOhJH7Oucjab0uDCuYhl3AjrdtZB3TgemIMOWwuji09pERKa7q1rMo6mwI5TiHI6vt4OzEshaO1UQRMcB0mxtkJsU7xT7bTkz1c2i1SRNWplYz9TzJnWVFqcQQeQ5XcTMaHkjM1_774K3ZNMFShH_yqajpvElOIl_J4JSnz0n1gneniKhbI3DQ=w2000-h1794',
    ogType: '网站',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',


    title: '智能技术管理医疗废物与资产',
    date: '2023-08-30',
    tag1: '#医疗废物',
    tag2: '#预防医疗事故',
    tag3: '#医疗设备管理',
    tag4: '#废物处理',

    // <br/>
    text: [
        {
            head: '',
            subHead: '',
            desc: '医疗设施的管理者需要关注的不仅仅是患者。医疗废物和设备是直接影响患者的重要资产，需要特别关心和注意。例如，由于未能正确处理污染的注射器或废物而导致二次感染事件的案例并不少见。此外，尽管每年需要处理的医疗废物数量都在增加，但设施实施这些目的的系统的案例却难以找到。加强安全管理协议并向员工提供教育可能是极限。但是，由于医疗事故涉及生命问题，需要更安全的解决方案。近年来实时定位追踪技术（RTLS）的发展为解决医疗行业的这些问题提供了途径。RTLS技术允许实时追踪对象的当前位置。那么，这种技术如何有益于医疗设施呢？',
            dot: ''

        },
        {
            head: '',
            subHead: '1. 管理医疗废物和设备数量',
            desc: '如果您负责废物处理，了解需要处理的废物存储在设施内的频率以及废物的数量是很重要的。尽管实际搜寻整个设施是一个选择，但采用RTLS解决方案可以快速简便地检索信息。同样，在手术、治疗、故障或丢失的情况下，RTLS可以快速准确地追踪设施内医疗设备的位置。',
            dot: ''
        },
        {
            head: '',
            subHead: '2. 实时位置追踪',
            desc: '在废物处理过程中，存在将物品混淆或与其他物品交换的可能性。为防止这种情况并监控过程，建立废物移动路线的实时追踪。如果未经适当处理的废物移动到病房或限制区域，将自动发出警报以进行即时响应。此外，RTLS还可以监控医疗设备进出设施的情况，提高了资产管理的效率。',
            dot: ''
        },
        {
            head: '',
            subHead: '3. 自动登记和签出',
            desc: '记录并保存有关设备或废物签入或签出的信息，无需手动输入，无论是手写还是计算机输入。这个过程存储在云端以供保存。它既可以作为设施运营的重要信息，也可以在事故需要法律证据的情况下有用。',
            dot: ''
        },
        {
            head: '',
            subHead: '4. 其他功能',
            desc: '实时位置追踪技术不仅仅局限于物体。也可以用于实时追踪患者的位置，包括需要特别护理的患者。当患者进入限制区域，包括废物区域时，会自动触发警报，从而实现快速位置识别。这有助于存储患者的移动数据，以便在像COVID-19这样的传染病爆发时进行准确快速的流行病学调查。这为基于实时位置追踪的更先进的患者管理系统铺平了道路。',
            dot: ''
        },
        {
            head: '',
            subHead: '结论',
            desc: '许多行业在第四次工业革命之后都发生了变革。上述过程改变了传统的容易出错的方式。在减少人力和成本的同时，提高效率和生产力的同时，还可以建立更安全的设施。',
            dot: ''
        },
    ],


    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: '通过安装UWB终端在工作人员或设备上，提供实时位置追踪的产品。',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/cn/enterprise/medical/patient'
            },
            {
                img: medicalWaste.thumb,
                data: 'medicalWaste',
                link: '/cn/enterprise/medical/waste'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/cn/enterprise/office/asset'
            }
        ]


    }

}
