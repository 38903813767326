
import { StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import UseCaseCard from "../UseCaseCard/UseCaseCard";

import React, { useMemo } from "react";
import { Trans } from "react-i18next";

import {IconHome, IconRoom, IconAnalytics, IconTools, IconSecurity} from "@assets/images/icon";
import monocular from "@src/lang/ko/technology/monocular";
import { StyledUseCase } from "../../StereoVision/UseCase/UseCase.style";
import { StyledDisplay, StyledParagraph } from "@components/Text";
import { Spacing } from "@pages/CommonStyle/common.style";
type TechnologyType = 'monocular'

interface UseCaseProps {
  type: TechnologyType
}
const useCaseIcons = [
  IconHome,
  IconRoom,
  IconTools,
  IconAnalytics,
  IconAnalytics,
  IconSecurity
];

const UseCase = (props: UseCaseProps) => {
  // Props
  const { type } = props;

  
  // Memo
  const cards = useMemo(() => monocular.useCase.card, []);

  return (
    <StyledUseCase>
      <StyledGridContainer>
        <StyledDisplay size="small"> <Trans i18nKey={ "monocular.useCase.title" }/></StyledDisplay>
        <Spacing pixel="16"/>
        <StyledParagraph size="medium">
          <Trans i18nKey={"monocular.useCase.description"} />
        </StyledParagraph>

        <Spacing pixel="64"/>
        <StyledGridRow>
          { Object.keys(cards).map((key, index) => (
            <UseCaseCard
              key={`${type}-use-case-${key}`}
              icon={useCaseIcons[index]}
              title={cards[key].title}
              description={cards[key].description}
              items={key}
            />
          )) }
        </StyledGridRow>
      </StyledGridContainer>
    </StyledUseCase>
  )
};

export default UseCase;