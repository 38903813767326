export default {

  helmet: ' ',
  desc: ' ',
  keywords: ' ',

  ogTitle: ' ',
  ogSite: ' ',
  ogDesc: ' ',
  ogUrl: 'https://orbro.io/en/blog',
  ogType: ' ',

  twitterCard: ' ',
  twitterTitle: ' ',
  twitterDesc: ' ',
  
  solution : 'Introduction to ORBRO Solutions',
  product : 'Related Products',

  title: '<strong>ORBRO</strong> Blog',
  description: 'Discover the latest technology and news from ORBRO.',
  blogList: [

    {
      name : 'blogSmartShipyard',
      date : '2024-05-09',
      label: 'Smart Shipyard Solution',
      path : '/en/blog/smart-shipyard'
    },
    {
      name : 'blogAIoT',
      date : '2024-05-09',
      label: 'What is AIoT?',
      path : '/en/blog/what-is-aiot'
    },
    {
      name : 'blogAiCamera',
      date : '2024-02-07',
      label: 'AI Camera Location Tracking Solution',
      path : '/en/blog/ai-camera'
    },
    {
      name : 'blogIndoorGPS',
      date : '2024-01-31',
      label: 'Indoor GPS Solution',
      path : '/en/blog/indoor-gps'
    },
    {
      name : 'blogSmartBuildings',
      date : '2024-01-30',
      label: 'What is a Smart Building?',
      path : '/en/blog/smart-buildings'
    },
    {
      name : 'blogSmartPort',
      date : '2024-01-25',
      label: 'Smart Port Solution',
      path : '/en/blog/smart-port'
    },
    {
      name : 'blogSmartParking',
      date : '2024-01-24',
      label: 'Smart Parking System',
      path : '/en/blog/smart-parking'
    },
    {
      name : 'blogIPS',
      date : '2024-01-23',
      label: 'What is IPS(Indoor Positioning System)?',
      path : '/en/blog/indoor-positioning-system'
    },
    {
      name : 'blogSmartAirport',
      date : '2024-01-16',
      label: 'Smart Airport Solution',
      path : '/en/blog/smart-airport-solution'
    },
    {
      name : 'blogSmartHospital',
      date : '2024-01-16',
      label: 'What is a Smart Hospital?',
      path : '/en/blog/스마트병원이란'
    },
    {
      name: 'blogLoRa',
      date: '2024-01-15',
      label: 'What is LoRa?',
      path: '/en/blog/what-is-LoRa'
    },
    {
      name: 'blogSmartFactory2',
      date: '2024-01-12',
      label: 'What is a Smart Factory?',
      path: '/en/blog/what-is-a-smart-factory'
    },
    {
      name: 'blogNVR',
      date: '2024-01-12',
      label: 'What is NVR?',
      path: '/en/blog/nvr'
    },
    {
      name: 'blogNFC',
      date: '2024-01-11',
      label: 'What is NFC?',
      path: '/en/blog/nfc'
    },
    {
      name: 'blogRFID',
      date: '2024-01-11',
      label: 'What is RFID?',
      path: '/en/blog/rfid'
    },
    {
      name: 'blogWhatIsDX',
      date: '2024-01-10',
      label: 'What is Digital Transformation?',
      path: '/en/blog/what-is-digital-transformation'
    },
    {
      name: 'blogContainerTracking',
      date: '2024-01-09',
      label: 'Container Tracking',
      path: '/en/blog/container-tracking'
    },
    {
      name: 'blogWhatIsSmartCity',
      date: '2024-01-09',
      label: 'Example Of Smart City',
      path: '/en/blog/example-of-smart-city'
    },
    {
      name: 'blogWhatIsSmartCity',
      date: '2024-01-08',
      label: 'What is a Smart City?',
      path: '/en/blog/what-is-smart-city'
    },
    {
      name: 'blogDigitalTwin3',
      date: '2024-01-04',
      label: 'What is BLE? | Definition of Bluetooth Low Energy',
      path: '/en/blog/ble'
    },
    {
      name: 'blogDigitalTwin3',
      date: '2023-12-28',
      label: 'What is Digital Twin? | Definition, Use Case',
      path: '/en/blog/digital-twin'
    },
    {
      name: 'blogDigitalTwinDigitalCity',
      date: '2023-12-26',
      label: 'Digital Twin(8) - Realistic Graphic City Implementation',
      path: '/en/blog/digitaltwin-digitalcity'
    },
    {
      name: 'blogDigitalTwinCCTV',
      date: '2023-12-26',
      label: 'Digital Twin(7) - Connecting Traffic CCTV',
      path: '/en/blog/digitaltwin-cctv'
    },
    {
      name: 'blogDigitalTwinAirplane',
      date: '2023-12-26',
      label: 'Digital Twin(6) - Connecting Airplane Information',
      path: '/en/blog/digitaltwin-airplane'
    },
    {
      name: 'blogDigitalTwinTraffic',
      date: '2023-12-26',
      label: 'Digital Twin(5) - Connecting Bus and Subway Information',
      path: '/en/blog/digitaltwin-traffic'
    },
    {
      name: 'blogDigitalTwinTracking',
      date: '2023-12-26',
      label: 'Digital Twin(4) - Connecting Real-time Movement of People and Vehicles',
      path: '/en/blog/digitaltwin-tracking'
    },
    {
      name: 'blogDigitalTwinBuildings',
      date: '2023-12-26',
      label: 'Digital Twin(3) - Implementing Building and Indoor Information',
      path: '/en/blog/digitaltwin-buildings'
    },
    {
      name: 'blogDigitalTwinWeather',
      date: '2023-12-26',
      label: 'Digital Twin(2) - Implementing Atmospheric and Weather Information',
      path: '/en/blog/digitaltwin-weather'
    },
    {
      name: 'blogDigitalTwinGis',
      date: '2023-12-26',
      label: 'Digital Twin(1) - Implementing GIS Geographic Information',
      path: '/en/blog/digitaltwin-gis'
    },
    
    {
      name: 'blogQaUwb',
      date: '2023-12-18',
      label: 'Quality and Inventory Management Solution: UWB',
      path: '/en/blog/qa-uwb'
    },
    {
      name: 'blogConstructionSolution',
      date: '2023-12-11',
      label: 'Prevention Solution for Serious Accident Penalty Law: Construction Site Safety Management',
      path: '/en/blog/construction-solution'
    },
    {
      name: 'blogRfidRtls',
      date: '2023-11-28',
      label: 'RTLS instead of RFID',
      path: '/en/blog/rfid-rtls'
    },
    {
      name: 'blogNfcUwbBle',
      date: '2023-11-20',
      label: 'Innovative Inventory Management System Utilizing UWB and BLE as Alternatives to NFC Tags',
      path: '/en/blog/nfc-uwb-ble'
    },
    {
      name: 'blogRtlsCompany',
      date: '2023-11-15',
      label: 'Key Considerations When Choosing an RTLS Provider',
      path: '/en/blog/rtlsCompany'
    },
    {
      name: 'blogRfidNfcUwb',
      date: '2023-11-10',
      label: 'RFID, NFC, UWB: Which Technology to Choose?',
      path: '/en/blog/RfidNfcUwb'
    },
    {
      name: 'blogBeacon',
      date: '2023-11-08',
      label: 'What is a Beacon?',
      path: '/en/blog/beacon'
    },
    {
      name: 'blogProductionAutomation',
      date: '2023-11-06',
      label: 'Smart Factory Construction and Production Automation Solution',
      path: '/en/blog/productionAutomation'
    },
    {
      name: 'blogUwbRtls',
      date: '2023-11-03',
      label: 'UWB RTLS, The Beginning of the Future Industry',
      path: '/en/blog/uwbRtls'
    },
    {
      name: 'blogRtlsHealthcare',
      date: '2023-11-02',
      label: 'RTLS Healthcare, Ready for Immediate Use',
      path: '/en/blog/rtlsHealthcare'
    },
    {
      name: 'blogDX',
      date: '2023-11-01',
      label: 'What is Digital Transformation (DX)?',
      path: '/en/blog/dx'
    },
    {
      name: 'blogOfficeSolution',
      date: '2023-10-31',
      label: 'Smart Office Construction Solution',
      path: '/en/blog/officeSolution'
    },
    {
      name: 'blogDigitalTwin2',
      date: '2023-10-26',
      label: 'The Essence and Potential of Digital Twins',
      path: '/en/blog/digitalTwin2'
    },
    {
      name: 'blogNextManufacturing',
      date: '2023-10-16',
      label: 'The Future of Manufacturing Facility Inventory Management Systems',
      path: '/en/blog/nextManufacturing'
    },
    {
      name: 'blogSmartFactory',
      date: '2023-10-12',
      label: 'How Smart Factories are Revolutionizing the Manufacturing Industry',
      path: '/en/blog/smartFactory'
    },
    {
      name: 'blogSmartSafety',
      date: '2023-10-04',
      label: 'A Comprehensive Solution for Smart Safety Management',
      path: '/en/blog/smartSafety'
    },
    {
      name: 'blogHeavyEquipment',
      date: '2023-09-25',
      label: 'Innovation in Smart Management of Heavy Equipment Technology Shaking Up Construction Sites',
      path: '/en/blog/heavyEquipment'
    },

    /*
  {
    name : 'blogConstructionAsset',
    date : '2023-09-21',
    label: 'Construction Site Inventory Management: New Changes Brought by RTLS',
    path : '/en/blog/constructionAsset'
  },
  */
    {
      name: 'blogConstructionSafety',
      date: '2023-09-19',
      label: 'RTLS Solution for Safe and Smart Construction Sites',
      path: '/en/blog/constructionSafety'
    },

    /*
  {
    name : 'blogConstructionRTLS',
    date : '2023-09-18',
    label: 'The Core of Smart Construction Site Worker Safety Management',
    path : '/en/blog/constructionRTLS'
  },
  */
    {
      name: 'blogLogisticsTech',
      date: '2023-09-13',
      label: 'Latest Technology Trends Every Logistics Industry Worker Should Know',
      path: '/en/blog/logisticsTech'
    },
    /*
    {
      name : 'blogSCM',
      date : '2023-09-11',
      label: 'Smart Warehouse Management for Global Supply Chain Management (SCM)',
      path : '/en/blog/scm'
    },
    */
    {
      name: 'blogLogisticsAsset',
      date: '2023-09-08',
      label: 'Advancement of Inventory Management in Logistics Centers: RTLS-Based Solution',
      path: '/en/blog/logisticsAsset'
    },
    {
      name: 'blogLogisticsWorker',
      date: '2023-09-06',
      label: 'How to Manage Worker Safety in Smart Logistics Facilities',
      path: '/en/blog/logisticsWorker'
    },
    {
      name: 'blogUwbLogistics',
      date: '2023-09-05',
      label: 'Smart Logistics Center Utilizing UWB Technology',
      path: '/en/blog/uwbLogistics'
    },
    {
      name: 'blogLogistics',
      date: '2023-09-04',
      label: 'Smart Logistics Center: Becoming a Game Changer with RTLS',
      path: '/en/blog/logistics'
    },
    {
      name: 'blogUwbGeofence',
      date: '2023-09-01',
      label: 'Innovation of Geofence System Using Ultra-Wideband (UWB) Technology',
      path: '/en/blog/uwbGeofence'
    },
    {
      name: 'blogMedicalWaste',
      date: '2023-08-30',
      label: 'Smart Technology for Managing Medical Waste and Assets',
      path: '/en/blog/medicalWaste'
    },
    {
      name: 'blogHospital',
      date: '2023-08-21',
      label: 'How Cutting-Edge Hospitals Manage Patients',
      path: '/en/blog/hospital'
    },

    {
      name: 'blogVisitor',
      date: '2023-08-14',
      label: 'Reasons Your Exhibition Hall Visitors Are Decreasing',
      path: '/en/blog/visitor'
    },
    {
      name: 'blogPatientTracking',
      date: '2023-08-07',
      label: 'Real-time Patient Tracking for Patient Management',
      path: '/en/blog/patientTracking'
    },

    {
      name: 'blogSmartLogistics',
      date: '2023-08-03',
      label: 'Why Smart Logistics Warehouses Lead the Industry',
      path: '/en/blog/smartLogistics'
    },

    {
      name: 'blogSmartConstruction',
      date: '2023-07-31',
      label: 'Smart Construction Site Safety Management System',
      path: '/en/blog/smartConstruction'
    },
    /*
    {
      name: "blogAccess",
      date: "2023-07-05",
      label: "The Completely Revamped Concept of Access",
      path: "/blog/access"
    },


    // 26 - 30
    {
      name: 'blogConstFuture',
      date: '2023-06-06',
      label: 'The Future of the Construction Industry with RTLS',
      path: '/blog/constfuture'
    },
    */

    {
      name: 'blogOffice',
      date: '2023-06-02',
      label: 'Building Smart Offices in 2023',
      path: '/blog/office'
    },

    /*
  {
    name : 'blogMall',
    date : '2023-05-28',
    label: 'Enhancing Large Shopping Malls with RTLS',
    path : '/blog/mall'
  },
  
  {
    name : 'blogConstruction',
    date : '2023-05-23',
    label: 'RTLS in Construction Sites',
    path : '/blog/construction'
  },
  
  {
    name : 'blogWarehouse',
    date : '2023-05-21',
    label: 'Smart Strategies for Warehouse Management',
    path : '/blog/warehouse'
  },
  
 
 
 
  
  // 21 - 25

    
  {
    name : 'blogHealthcare',
    date : '2023-05-19',
    label: 'Benefits of RTLS in Healthcare Facilities',
    path : '/blog/healthcare'
  },
  */

    {
      name: 'blogConsider',
      date: '2023-05-19',
      label: 'Key Considerations for Choosing an RTLS Solution',
      path: '/blog/consider'
    },
    /*
    {
      name : 'blogPatient',
      date : '2023-05-18',
      label: 'Improving Patient Flow in Medical Facilities with RTLS',
      path : '/blog/patient'
    },
    
    {
      name : 'blogBi',
      date : '2023-05-18',
      label: 'Business Intelligence (BI) Growth with RTLS',
      path : '/blog/bi'
    },
    */
    {
      name: 'blogForklift',
      date: '2023-05-17',
      label: 'Enhancing Forklift Driver Safety with RTLS Solutions',
      path: '/blog/forklift'
    },


    // 16 - 20
    /*
  {
    name : 'blogGse',
    date : '2023-05-17',
    label: 'A Guide to Aviation RTLS for GSE Tracking',
    path : '/blog/gse'
  },
  */

    {
      name: 'blogActiveRfid',
      date: '2023-05-16',
      label: 'RTLS Based on Active RFID Technology',
      path: '/blog/activerfid'
    },
    /*
    {
      name : 'blogElder',
      date : '2023-05-16',
      label: 'Why RTLS is Essential in Elderly Care Facilities',
      path : '/blog/elder'
    },
    
    {
      name : 'blogAdvantage',
      date : '2023-05-16',
      label: 'The 4 Key Advantages of RTLS',
      path : '/blog/advantage'
    },
    
    {
      name : 'blogAnswer',
      date : '2023-05-15',
      label: 'Answering the Top 5 Questions about Bluetooth Location Services',
      path : '/blog/answer'
    },
  
  
    // 11 - 15
      
    {
      name : 'blogFactory',
      date : '2023-05-15',
      label: 'Welcome to the Future of Factories: The Role of Real-Time Tracking in Industry 4.0',
      path : '/blog/factory'
    },
    
    {
      name : 'blog4th',
      date : '2023-05-14',
      label: 'The Role of Real-Time Location Systems in the Fourth Industrial Revolution',
      path : '/blog/4th'
    },
    */
    {
      name: 'blogManufacture',
      date: '2023-05-14',
      label: 'Revolutionizing Manufacturing Operations with RTLS and IoT',
      path: '/blog/manufacture'
    },
    /*
    {
      name : 'blogCorona',
      date : '2023-05-13',
      label: 'Building Bridges in the Post-COVID-19 Supply Chain with Real-Time Location Services (RTLS)',
      path : '/blog/corona'
    },
    
    {
      name : 'blogIot',
      date : '2023-05-12',
      label: 'Generating Revenue Opportunities by Combining IoT Processes and RTLS',
      path : '/blog/iot'
    },
  
  
    // 6 - 10
      
    {
      name : 'blogAlone',
      date : '2023-05-12',
      label: 'Protecting Lone Workers from Hazards',
      path : '/blog/alone'
    },
    */
    {
      name: 'blogAirport',
      date: '2023-05-10',
      label: 'RTLS Applications in Airports',
      path: '/blog/airport'
    },
    /*
    {
      name : 'blogUwbble',
      date : '2023-05-08',
      label: `UWB vs BLE: What's the Difference?`,
      path : '/blog/uwbble'
    },
    */
    {
      name: 'blogMedical',
      date: '2023-05-04',
      label: 'RTLS in the Medical Industry',
      path: '/blog/medical'
    },
    {
      name: 'blogGeofence',
      date: '2023-05-01',
      label: 'What is Geofencing and How Does It Work?',
      path: '/blog/geofence'
    },


    // 1 - 5
    /*
  {
    name : 'blogAsset',
    date : '2023-04-27',
    label: 'Efficient Asset and Process Tracking Indoors and Outdoors',
    path : '/blog/asset'
  },
  */
    {
      name: 'blogUwb',
      date: '2023-04-25',
      label: 'What is UWB and How Can It Benefit Your Business?',
      path: '/blog/uwb'
    },
    {
      name: 'blogDigitalTwin',
      date: '2023-04-22',
      label: 'Digital Twin and Real-Time Location Tracking Systems',
      path: '/blog/digitalTwin'
    },
    /*
    {
      name : 'blogSafety',
      date : '2023-04-20',
      label: 'The Impact of Location Tracking Technology on Worker Safety in Industrial Settings',
      path : '/blog/safety'
    },
    */
    {
      name: 'blogRTLS',
      date: '2023-04-17',
      label: 'What is Real-Time Location Tracking System (RTLS)?',
      path: '/blog/rtls'
    },
  ],
  back: 'Go Back to ORBRO Blog',
  newletter: 'Subscribe to Newsletter',
  info: 'Stay updated with the latest technologies<br/>and news from ORBRO!',
  placeholder: 'Enter your email',
  subscribe: 'Subscribe',
  terms1: "By providing information, agree to",
  terms2: "Orbro's",
  terms3: 'Privacy Policy',
  terms4: '.',

  more: 'Read More',
  less: 'Collapse',

  recent: 'Recent Blog Posts',
  share: 'Did you like the article? <br/> Share it',

}