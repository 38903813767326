import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { IconAccess, IconAlarm, IconWarn, IconZoom } from "@assets/images/main";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/constructionAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo4.mp4";
import subVideo5 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo5.mp4";

export default {
    
    
    banner : {
        title : '建筑材料定位追踪与管理',
        desc : '精确的材料管理最终将提高生产力。材料管理在建筑现场中是至关重要的一部分，它可以减少资源浪费，保持质量，甚至影响安全。',

    },

    video : {
        title : '准确且快速地管理材料的方法。',
        desc : '实时定位(RTLS)技术能够即时提供材料的位置信息。几乎不需要花费时间查找材料的位置。<br/>此外，由于可以进行数量管理，使用RTLS将实现比以前更高水平的生产力。',
        play : video
    },

    func : [
        {
            title : '材料数量管理与定位',
            desc : '准确地了解材料的数量是基本要求。同时，实时定位材料的位置和数量也是必要的。',
            sub1 : '提供高精度数据',
            icon1 : IconRulerGray,
            sub2 : '提供统计数据',
            icon2 : IconChartBarGray,
            video : subVideo1
            
        },
        {
            title : '自动进出库',
            desc : '当材料通过特定的地点时，会自动创建进出库记录。由于数据准确，可以防止人为错误并节省人力资源。',
            sub1 : '快速数据链接',
            icon1 : IconTvocGray,
            sub2 : '实时定位',
            icon2 : IconBinocularsGray,
            video : subVideo2
        },
        {
            title : '安全管理',
            desc : '对于潜在可能导致安全事故的材料，应该受到特别关注。实时定位材料位置的同时，还可以掌握附近的工人情况，以预防事故。',
            sub1 : '设置临近标准距离',
            icon1 : IconRulerGray,
            sub2 : '设置应对方式',
            icon2 : IconBellGray,
            video : subVideo3
        },
        {
            title : '节省时间',
            desc : '将工人寻找材料的时间大大缩短。现场工作时间意味着生产力。优化现场工作，无浪费的资源。',
            sub1 : '实时情况掌握',
            icon1 : IconClockGray,
            sub2 : '实时定位',
            icon2 : IconBinocularsGray,
            video : subVideo4
        },
        {
            title : '防盗',
            desc : '如果材料丢失，很难区分是简单的错误还是被盗。但是，如果能实时定位材料位置，就可以预防能源和成本的浪费。',
            sub1 : '设置应对方式',
            icon1 : IconBellGray,
            sub2 : '与外部系统连接',
            icon2 : IconChainLinkGray,
            video : subVideo5
        },
    ],



    subfunc : {
        
        title : '多种功能',
        desc : '现场不完善的材料管理直接影响生产力。通过优化的流程，提高效率并消除浪费的人力资源等资源。为此，Orbro提供了优化的多种功能。',
        
        data : [
            {
                icon : IconChartRadius,
                title : '报告和分析',
                desc : '基于现场数据生成报告和分析数据。',
            },
            {
                icon : IconBell,
                title : '实时警报功能',
                desc : '在发生材料异常情况时发送警报，以便立即采取措施。',
            },
            {
                icon : IconRoute    ,
                title : '轨迹历史查询',
                desc : '查询材料在现场移动的轨迹并将其数据化。',
            },
            {
                icon : IconDashboard,
                title : '现场情况掌握',
                desc : '了解当前有多少材料在哪里移动。',
            },
            {
                icon : IconWarning,
                title : '遗留监测',
                desc : '监测材料是否离开现场或移动到错误的地方。',
            },
            {
                icon : IconSearch,
                title : '轨迹模式分析',
                desc : '分析材料在现场使用的数量和时间。',
            },
            {
                icon : IconChainLink,
                title : '数据连接',
                desc : '与RFID、物联网、安防、视频等系统连接，实现高效利用。',
            },
            {
                icon : IconSecurity,
                title : '数据安全',
                desc : '保持高水平的数据安全性，提供可信赖的解决方案。',
            },
        ],
    },


    system : {
        title : '系统构成',
        img : rtlsSystem
    },

    productTitle : '产品构成',
    product : [

        {
            tag : 'assetPackage'
        },
        {
            tag : 'workerPackage'
        },
    ],


    contents : [
        {
        data : 'constructionEquipment'
        },
        {
        data : 'constructionSafety'
        },
        {
        data : 'blogOffice'
        },
        {
        data : 'blogRTLS'
        },

    ]



}