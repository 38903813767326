import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: 'ORBRO enhances aviation mobility and ensures safety in travel and operations by providing real-time tracking and operational information for aircraft. It improves the aviation travel experience through services such as airline information, flight route confirmation, airport schedule verification, emergency situation management, customer service enhancement, and environmental impact monitoring.',
  keywords: 'Digital Twin, Digital Twins, 디지털 트윈, gis, 디지털 시티, RTLS, location tracking system, location tracking technology, sports, manufacturing, logistics, healthcare, hospital',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - Connecting Aircraft Information :: ORBRO Blog',
  ogUrl: 'https://orbro.io/en/blog/digitaltwin-airplane',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twin - Connecting Aircraft Information',
  date: '2023-12-26',
  tag1: '#DigitalTwin',
  tag2: '#Aircraft',
  tag3: '#DigitalCity',
  tag4: '#VirtualReality',

  text: [
    {
      head: 'Digital Twin - Connecting Aircraft Information',
      subHead: '',
      desc: 'ORBRO enhances aviation mobility and ensures the safety of travel and operations through real-time tracking and operational information of aircraft. It improves the overall experience of air travel by providing airline information, monitoring flight routes, checking schedules for each airport, emergency situation management, customer service enhancement, and environmental impact monitoring.<br/><br/>Providing airline information offers essential information for passengers to plan their air travel conveniently. Flight route monitoring supports smooth operations by ensuring aviation traffic management and safety. Providing schedules for each airport helps travelers manage their travel itineraries effectively with accurate and reliable travel information.<br/><br/>Emergency situation management prioritizes the safety of passengers and staff by preparing for unforeseen circumstances. Customer service enhancement increases satisfaction by improving passenger response and service quality. Environmental impact monitoring establishes sustainable aviation operational strategies, fulfilling environmental responsibilities and pursuing sustainability. ORBRO supports safe and convenient air travel and contributes to sustainable operations through these diverse functionalities.',
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/w1pKhaleOH4?si=BNCBDwA5fepB03Y3',
      head: '',
      subHead: 'Providing Airline Information',
      desc: 'ORBRO provides real-time information on each airline, including aircraft types, routes, and schedules. This information is crucial for passengers planning air travel and provides important data for airline and airport operators for aircraft operation and management.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Flight Route Monitoring',
      desc: 'Real-time monitoring and display of aircraft routes play a vital role in ensuring air traffic management and aviation safety.',
    },
    {
      head: '',
      subHead: 'Airport Schedule Information',
      desc: 'Providing airport-specific flight schedules, including arrival and departure schedules, gate information, etc., improves travel information accuracy for passengers and enhances airport operational efficiency.',
    },
    {
      head: '',
      subHead: 'Emergency Situation Management',
      desc: 'Utilizing real-time aviation data enables swift response and management of emergency situations, ensuring the safety of passengers and staff and minimizing potential risks.',
    },
    {
      head: '',
      subHead: 'Customer Service Enhancement',
      desc: 'Utilizing real-time aviation information promotes passenger response and service improvement, increasing passenger satisfaction and enhancing the air travel experience.',
    },
    {
      head: '',
      subHead: 'Environmental Impact Monitoring',
      desc: 'Monitoring environmental impacts related to aircraft operations and establishing sustainable aviation operation strategies play a crucial role in carbon emission reduction and environmental protection efforts.',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that assists real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/en/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      }
    ]
  }
}
