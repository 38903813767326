import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


    helmet: '',
    desc: 'uwb 기술을 활용하여 지오펜스(geofence) 시스템을 구축할 수 있습니다. 실시간으로 안전을 모니터링하여 시설을 효율적으로 관리할 수 있습니다.',
    keywords: 'uwb, geofence, 지오펜스, 지오펜싱, 안전구역, 보안시설, 군사보안, 군사보안시설, 접근금지구역, 출입금지 구역, 스마트 보안, 보안 시스템',

    ogTitle: '',
    ogDesc: '',
    ogSite: '초정밀 UWB 기술을 활용한 지오펜스 시스템의 혁신 :: 오브로 블로그',
    ogUrl: 'https://orbro.io/blog/uwbGeofence',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4MxDPsSDiu2Tyl6nzt6wk9OfJpf_lWTcfXeVRyvIzqQCB1pSV0Spq8jW3WvcY7Nw02jSYqstCQAImKsHtFRK-1bPx1X2mx0YaBIiayQ1ohB3l7WHUYrrwPX0KVYOmKZg-lo7OXiR53dsL16RqpSTSGdQaV46-p40ThUaFoYxY-hUVgD4l18QurSDnaKVaSL_qZ6ApBj7LSbjfbjVaUNEOXWI3bT5GNmDdlLfWBv8id417JIjgIeDW3iI_kkzevdCiKHN2ORcK4RYGPGFVeAOL8wlco80gszm93LArYd2nOvTK38arZmeYvJ0Ex0nqkjaxulQ7gJ5-a5bSBSfAaKAkqxv24246EQdlkBh9N5COrSoTqrAlmVNNf9PxF2fbxMevrZt-G9NMRIaxYkWT42AfcpxuTL1DikOcn2_q8jFMWhrvSwNYC2No2mZOgbqYoDKytKXp2xOcRM7RKZBhIZhDvfS59FVqMUMiO5aD2j2QizWcy5BI53-fnun3DZdVQTijx4yq1Gg_tQvY36Lu803m17BZ6Fia8uhoZVOdcuNpFsiAttmTR2X_wkCdeti3lj3ovDVjGE7kvZr1CdpkFft3MtDSoL2fhdU6YubXi_uuNfS_zCAwv6er3VjTfUTvPgtciMHUbAv5Ci63iew9qiOj3Fkr0ueSGZk_iqSYP-He2PUYp7TplTlsPd3CmzeGIf03yFNX1FK4RUVfIo55fXbW0n7cuga9vSRYuRF5dsHCTLILV1qr_nJGd-U4u-vO2jRh53ZHXTDgA8dZ_c4XUneNWJ0_JDYNOTmzrgJJsCDvH8-J28NyJVw-UBpgUGYZRMnhoPclDHRXAMcy5YvwsLVCLajck2ewTifU3gOr8SHNlFP_wOpK7_L5WgP7GHPYE04bZbHZ-bjCxcDM8E3wQYn32oUrEammRi0mbSb7xPPr9azb_14nmPjd1d-Ppay-29QzX8A1B5GRo4emyzqI0NMMPmDryb4QTIsKI9fYTbZsQ3fUoPH3HnNRZtpmVc-Ht1Gld7epvFpcrD199Ery-c1XIEEvw9PSDjteUY1-RMoyDutgDc06huKQxZv_wx-vfxmu-GnVz6kDHYQtEKULMtqxhF-8f65nC-p8nnbYD59CkksLRWPzzKmq7c51Sq1UDLuOqDPlnpema8t7VSg2iWUNi7pqgxfwnt1BSPeRt8XrPv8dmTmudreTbSR1g-7x4fAzf1PxU2QyGOmbVEq_5L4HNxOmJQzGnEYOxtPcTWL1G-vYS58iH6octXtcqiXoFymXxnt5sHnpCaYK1cpWTWUCjEMjJi8r2YOEPxBfzHv4VUeME1bB91xu2jsZhQC09SylsHJZZcLxW_jG4v2EzHsKI87CaHQCimSgcaP_8Iy3GPUVQdbOnRXT8XSow67w2sItu6ujbKZCr1aT7l54ree-kMoWfM3urhWArs2mQE2_oo0_eSh__SdYlxYh56m64zFkguxTpaecjUFam-ty20MdiuW5LkVjoNPR4A_9HsbmFYn_lQ8Pqc6jtL3FDml1vyeTYAYYv2XMQxoZvyGZBYbTU=w2000-h1736',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',


    title: '초정밀 UWB 기술을 활용한 지오펜스 시스템의 혁신',
    date: '2023-09-01',
    tag1: '#uwb',
    tag2: '#geonfece',
    tag3: '#지오펜스',
    tag4: '#보안구역',

    // <br/>
    text: [
        {
            head: '',
            subHead: '초정밀 광대역 기술, UWB란?',
            desc: '초정밀 광대역 (Ultra-Wideband, UWB) 기술은 매우 넓은 주파수 대역폭을 사용하여 데이터를 전송하거나 물체의 위치를 정확하게 측정하는 데 사용되는 무선 통신 기술입니다. UWB는 짧은 시간 동안 매우 짧은 펄스를 사용하며, 이를 통해 고해상도의 신호를 생성합니다. 이러한 고해상도의 신호를 활용하여 실내와 실외 환경에서 정밀한 위치 추적과 거리 측정을 가능하게 합니다. UWB 기술은 낮은 전력으로도 높은 데이터 전송률과 위치 측정 정확도를 제공하는 특징을 가지고 있어 다양한 응용 분야에서 활용됩니다.',
            dot: ''

        },
        {
            head: '',
            subHead: '지오펜스(Geofencing)란?',
            desc: '지오펜스는 위치 기반 서비스와 무선 기술을 결합하여 특정 지리적 영역 내에서 사건이 발생할 때 경고를 보내거나 특정 동작을 수행하는 기술입니다. 이를 단순화하면 가상의 울타리를 설정하여 그 범위를 벗어나거나 들어갈 때 특정한 조치를 취하는 것을 의미합니다. 지오펜스는 GPS, Wi-Fi, 블루투스 및 UWB와 같은 위치 추적 기술과 결합하여 사용될 수 있습니다. 이를 통해 실시간 위치 정보를 기반으로 정확하게 지오펜스 영역을 설정하고, 사용자나 장치가 특정 영역 내부 또는 외부에 들어갔을 때 알림을 보내거나 특정 동작을 수행할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '초정밀 UWB 기술을 활용한 지오펜스 시스템의 혁신',
            desc: '최근 몇 년간 기술 혁신은 우리의 삶을 급격하게 변화시키고 있습니다. 그 중에서도 초정밀 UWB(Ultra-Wideband) 기술은 혁신적인 지오펜스(Geofence) 시스템을 구축하는 데 큰 관심을 받고 있는 주제입니다.<br/>이 기술은 넓은 대역폭을 활용하여 이전에는 어려웠던 정확하고 신속한 위치 추적 및 거리 측정을 가능하게 합니다. 이를 토대로 UWB 기술이 지오펜스 분야에서 어떻게 활용되며 어떤 혁신을 가져오고 있는지 알아보겠습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '1. 초정밀 위치 추적',
            desc: 'UWB 기술은 매우 정교한 위치 추적 기능을 제공합니다. 이를 활용하여 지오펜스 시스템은 물체나 개인의 위치를 실시간으로 파악할 수 있습니다.<br/>예를 들어 시설 내에 접근 금지 구역이 있는 경우, 실시간으로 접근하는 사람들의 위치를 파악하여 자동으로 알람을 보낼 수 있습니다. 또, 군사 분야의 경우에는 적의 이동을 정확하게 감시하고 통제할 수 있는 장점을 가져올 뿐만 아니라, 민간 분야에서는 시설물이나 물품의 위치를 보다 효과적으로 관리할 수 있는 장점을 제공합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '2. 가시화 및 모니터링',
            desc: 'UWB 기술을 활용한 지오펜스 시스템은 높은 정확도로 지리적 정보를 가시화하고 모니터링할 수 있습니다. 이를 통해 관리자는 실시간으로 지역 내의 움직임과 활동을 확인할 수 있으며, 이상 행동이나 무단침입으로부터 경고를 즉시 받아 대응할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '3. 안전과 보안 강화',
            desc: 'UWB 기술은 고도의 보안성을 제공합니다. 레이더 기반의 위치 추적은 GPS 신호에 비해 더욱 어려운 탐지가 힘들기 때문에, 지오펜스 시스템은 외부로부터의 해킹이나 교란을 피하면서 안전을 유지할 수 있습니다. 이는 군사시설을 비롯한 기밀 정보 보호와 같은 분야에서 큰 장점을 가집니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '4. 다중 활용성',
            desc: 'UWB 기술은 다양한 분야에서 활용할 수 있는 다중 기능성을 지니고 있습니다. 위치 추적 뿐만 아니라 다양한 센싱 기능과 연계하여 활용할 수 있어, 환경 모니터링, 재난 대응, 자동화 시스템 등에서도 활용 가능한 잠재력을 가지고 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '5. 미래 전망',
            desc: 'UWB 기술은 계속해서 발전하고 있으며, 더욱 정교한 센싱과 위치 추적 능력을 제공할 것으로 예상됩니다. 이로 인해 지오펜스 시스템은 보다 강력하고 효과적인 안전 관리 능력을 갖추게 될 것으로 기대됩니다. 또한 UWB 기술은 기존의 방식보다 더욱 효율적이고 정확한 데이터를 제공하므로, 의사 결정에 있어서도 높은 신뢰도를 제공할 것으로 예측됩니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '결론',
            desc: '초정밀 UWB 기술을 활용한 지오펜스 시스템은 정교한 위치 추적과 모니터링 능력을 통해 보다 효과적인 방어와 관리를 가능하게 합니다. 이러한 기술의 혁신은 민간, 군사, 보안 분야에서 다양한 영역에 긍정적인 영향을 미치며, 미래에는 더욱 발전된 UWB 기술을 통한 더 강력한 지오펜스 시스템의 등장을 기대할 수 있습니다.',
            dot: ''
        },
    ],


    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/enterprise/medical/patient'
            },
            {
                img: medicalWaste.thumb,
                data: 'medicalWaste',
                link: '/enterprise/medical/waste'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/enterprise/office/asset'
            }
        ]


    }

}