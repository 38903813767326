import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconChartRadiusGray, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconTagGray, IconTvocGray, IconUserCheck, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { IconTemp } from "@assets/images/main";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo4.mp4";

export default {
    
    banner : {
        title : '실시간 공정 관리',
        desc : '제조 시설이 어떤 흐름으로 진행되고 있는지 한눈에 파악하여 관리할 수 있습니다. 정확하고 객관화된 데이터를 통해서 공정 라인을 개선하고, 효율성을 높일 수 있습니다. 이는 제조 산업의 핵심입니다.',

    },

    video : {
        title : '제조 공정의 모든 것을 추적하다.',
        desc : '실시간 위치 추적(RTLS) 기술은 제조 공정을 실시간으로 파악할 수 있습니다.<br/>생산 라인의 현 상황을 실시간으로 파악하여, <strong>최고의 효율</strong>을 이끌어 낼 수 있습니다.',
        play : video
    },

    func : [
        {
            title : '생산 라인 모니터링',
            desc : '제조공정 중 발생하는 이상 현상을 빠르게 파악하여 조치를 취할 수 있으며, 제조 과정에서 발생하는 장애를 최소화하여 생산 효율성을 높일 수 있습니다.',
            sub1 : '실시간 현황 파악',
            icon1 : IconClockGray,
            sub2 : '통계 데이터 제공',
            icon2 : IconChartBarGray,
            video : subVideo1
            
        },
        {
            title : '병목 현상 완화',
            desc : '원자재, 반제품, 완제품 등의 이동을 실시간으로 파악하여 어떤 단계에서 병목 현상이 발생하는지 식별합니다. 또한 이를 예측하고 빠른 조치를 취할 수 있습니다.',
            sub1 : '실시간 위치 추적',
            icon1 : IconBinocularsGray,
            sub2 : '높은 정확도 제공',
            icon2 : IconRulerGray,
            video : subVideo2
        },
        {
            title : '안전관리',
            desc : '공정 생산라인에서 발생하는 사고를 실시간으로 감지합니다. 이로써 사고 발생 초기에 발 빠른 대처가 가능하고 더 큰 사고를 방지합니다.',
            sub1 : '위험 자산 지정',
            icon1 : IconWarningGray,
            sub2 : '대응 방식 설정',
            icon2 : IconChartRadiusGray,
            video : subVideo3
        },
        {
            title : '공정 분석',
            desc : '데이터들을 수집하여 좀 더 효율적인 생성 공정으로 개선할 수 있습니다.  문제점들을 쉽게 파악하여 비즈니스를 성장시킬 수 있습니다.',
            sub1 : '데이터 클라우드 저장',
            icon1 : IconClockGray,
            sub2 : '통계 데이터 제공',
            icon2 : IconChartBarGray,
            video : subVideo4
        },


    ],



    subfunc : {
        
        title : '다양한 기능',
        desc : '공정 라인의 생산성은 제조 산업의 핵심이라 할 수 있습니다. 오브로가 제공하는 기능들은 공정 라인의 효율성과 생산성을 모두 한층 더 끌어 올릴 수 있습니다.',
        
        data : [
            {
                icon : IconChartRadius,
                title : '리포트 및 분석',
                desc : '현장 데이터를 기반으로 보고서 및 분석 자료를 생성할 수 있습니다.',
            },
            {
                icon : IconBell,
                title : '실시간 알람 기능',
                desc : '공정라인에 이상 상황이 발생하는 경우 알람을 보내 즉각적으로 대처할 수 있습니다.',
            },
            {
                icon : IconRoute,
                title : '동선 이력 조회',
                desc : '내부의 이동 물체가 이동하는 경로와 이동 시간을 추적하여 확인할 수 있습니다.',
            },
            {
                icon : IconDashboard,
                title : '현황 파악',
                desc : '현재 공정 상황을 실시간으로 파악할 수 있습니다.',
            },
            {
                icon : IconTemp,
                title : '온도, 습도 등 환경분석',
                desc : '물류창고 내부 환경 요인을 모니터링하여 이상이 있는 경우 실시간으로 감지할 수 있습니다.',
            },
            {
                icon : IconUserCheck,
                title : '작업자 지시',
                desc : '현 상황을 바탕으로 작업자에게 다음 작업을 지시할 수 있습니다.',
            },
            {
                icon : IconChainLink,
                title : '데이터 연동',
                desc : 'RFID, IOT, 보안, 영상 등의 시스템과 연결하여 효율적으로 활용할 수 있습니다.',
            },
            {
                icon : IconSecurity,
                title : '데이터 보안',
                desc : '높은 데이터 보안 수준을 유지하여 신뢰성이 높은 솔루션을 제공합니다.',
            },


        ],
    },

    system : {
        title : '시스템 구성',
        img : rtlsSystem
    },

    productTitle : '제품 구성',
    product : [

        {
            tag : 'assetPackage'
        },
        {
            tag : 'workerPackage'
        },
    ],


    contents : [
        {
        data : 'manufacturingAsset'
        },
        {
        data : 'logisticsAsset'
        },
        {
        data : 'blogMedical'
        },
        {
        data : 'blogRTLS'
        },

    ]



}