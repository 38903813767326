import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import {  logisticsAsset, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/officeAsset/video/rtlsVideo.mp4";

export default {
    helmet: '',
    desc: '智能办公室带来了资产管理、考勤管理、库存管理和员工管理方面的创新变革。它允许您以不同的方式运营和管理办公室，提高生产力。',
    keywords: '智能办公室，资产管理，库存管理，办公解决方案，位置跟踪，考勤管理，办公用品管理，办公用品，contech，orbro，rtls，orbro',

    ogTitle: '',
    ogDesc: '',
    ogSite: '智能办公室建设解决方案:: Orbro博客',
    ogUrl: 'https://orbro.io/cn/blog/officeSolution',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDz0dYjvT_dovrQIm8StET0JPtXyRSg67istWgj_G4Tk7ygKMfT0sSkYCFctUToVxdJt0GLQovf6SlDVOv8wRusG-hAYmfYmKYwY2UC2zapN2wPY8xmOdsmqfCmtKJE_ZgS5d3zp8jhdEh3kzrTI6wCtwXZsh2l8FoST49zwMy3hMKotCDruVcBK8e-90_kWC-DRfGXSqBUlqYqEvpb7suLGc-sG9uVyVzgpahbCluCQLjjYQp0FfaIMqi3qWmmpzn4UeKKFlFEKxo5npOoMkUfIPOIzQwrqMhKMvCPw19QwjLii04Vk779E1xu1B3yvdQA0SsTGt8xskOW8TEzvlXITVtsKdpUOAbHj6rKF-3gchc57T4eE_-3ycDx8gttnPLOy-milalJmjbIZrntwCeb8Qpocf-Ug-YSBcy46fdXlAD73CrLAkMZD_EibWYj6rcA1MkP7YlYgUDMFZsUHXCRAJn-FXeC1tFSMvFp9XkMXa0hQjhG7WFKQ9OY__bS3VMM3m653EIFAooJVI2JW5Lehmr8i7AoYvVwyVya-ECTAkyCOHE36ApWm2GXcuMaUEKVpFWCa6Uk5VT3y44pXzUjv7UyfKoAILkaIxCBJ90rb-hQj0SOmBbu6J5gp--cXveh_j_I1BpDL4yXkaY5roagR4KZV4HnPqPzoAuXRMkg9D1c2IIuxsH3XW2UbComnYB3ZP36_elzk3qsnT125p6OPuquaowy30SFBy65N7gknz-dVooAi8Bz_bREsVMZUQ1wevfQYy8CoL2buJo3oL-tQbJTKrbPBiZQYm3H1SZ7S9JXjEpyEJgoPnmvNE1Qk8ErzBGgEACyWUFED9vQmLonSf0sPZK2ipPzAfbSStrz0RdsuLXHASIrTRRXO2miidoWuq4JqeuHAzVXqVj8iiG6VnQSMN8hW29RelzkAFwfOg9QGOSQgroVEuHNbCZebcAa-WfMSPdOvklsFWIQIc7tEZ0Qc4oKkpyced7b9OF6nQAwaZH0Risyrl_jcCKd0dYWRazieZBi2P6EV3VqCdtI8xhpYvCer4uocgKohjK07UgUVaEC2ug-oLsCYkNC1MeYrEvVkM6lJCnqihOdgUQ7-28FDGc1mmL-l0En06_GFIkCWjuUL84NZSglIuAjAgx-XkQSRl6j9XiV_O-vcqE8qmjky4mXV-Wn9Mj4AUexdJBDWX9R1CgPvFsXQlYaAKg7sPSYXzv9cfTPCRBBZZ7HExJ0g18NzVBpn3JlbO76m7WOsMm20NMcBW_rFgPVVbcx_RkjFJZGYalie3GY6mewgx_VeKktH5GAlyxmgXqT2JeF_1GcqN6AFrQoTW_QgkDDyUkqJW-_olrQiVqerertlIZ8GOosa2chps5Yw1iCaH01YmTgG8u9YCrqbwtWdL2wE1StruepZYCM4DRqCS22OLJySm6TTeql6iQAxR3n6gNFmWutp83Z87o_7FuetzaZmM6Z31Pk7JOVigfT3FgDClioeRwOy_k3WkXA_FWF4xSORqO21iqrYzkjTzZKHUAiEqUt7VNXIcfn_bWJE2wDSqElWPnqG6MDtJwqdm6CK-l1uGp3c=w2110-h1166',
    ogType: '网站',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '智能办公室建设解决方案',
    date: '2023-10-31',
    tag1: '#智能办公室',
    tag2: '#库存管理',
    tag3: '#办公用品',
    tag4: '#考勤管理',

    text: [
        {
            video: video,
            link : '/cn/enterprise/office/asset',
            head: '',
            subHead: '',
            desc: '将实时位置系统（RTLS）技术集成到智能办公室建设解决方案中可以使工作环境更智能化。RTLS是一种追踪资产和工作人员实时位置的技术，为智能办公室环境提供各种创新机会。',
            dot: ''
        },
        {
            head: '1. RTLS技术和智能办公室',
            subHead: '',
            desc: 'RTLS技术是实时位置系统（Real-Time Location System）的缩写，利用无线通信和传感器技术收集和分析实时位置数据。将这项技术应用到智能办公室环境中，您可以获得以下优势：',
            dot: ''
        },
        {
            head: '2. 优势',
            subHead: '资产管理',
            desc: '通过RTLS，可以准确跟踪智能办公室内的所有资产。例如，会议室预订系统利用RTLS数据来实时检查会议室内的设备，并允许预订。这可以防止重复预订，最大程度地利用会议室资源。此外，通过分析资产的移动和位置历史数据，您可以优化资产生命周期并计划预防性维护。',
            dot: ''
        },
        {
            head: '',
            subHead: '工人生产力',
            desc: '需要找到特定资产的工人可以使用RTLS应用程序来查看资产的当前位置并通过最短路径移动到它。它还有助于优化工人的工作时间表。通过分析RTLS数据，您可以优化工人的工作模式并提高生产力。更重要的是，当工人可以从任何地方高效执行任务时，工人的满意度会提高。',
            dot: ''
        },
        {
            head: '',
            subHead: '增强安全性',
            desc: '管理员可以检测未经授权访问受限区域并立即采取措施，使用警报系统。监控工人位置数据可以检测危险区域的工作并向工人发送警告或立即采取措施。此外，监控资产的移动和位置变化有助于防止盗窃或损坏，显着提高安全性。',
            dot: ''
        },
        {
            head: '3. 利用RTLS的智能办公室解决方案',
            subHead: '资产管理系统',
            desc: '该系统实时跟踪和管理办公室内的所有资产。每个资产都配备有RTLS标签，以跟踪资产的位置、移动路径和使用历史。管理员可以通过基于Web的仪表板实时查看所有资产的位置，快速找到所需的资产。它还有助于基于位置信息优化资产利用率，防止不必要的设备购买。',
            dot: ''
        },
        {
            head: '',
            subHead: '工人状态仪表板',
            desc: '在智能办公室环境中引入实时监控工人位置的仪表板可优化工作时间表和合作。例如，它允许实时跟踪谁正在使用哪个会议室，可以与会议室预订系统集成。共享工人位置信息并促进工人之间的合作可提高生产力。',
            dot: ''
        },
        {
            head: '',
            subHead: '环境控制和能源管理',
            desc: '智能照明系统可以检测工人位置并自动调整照明以节省能源并提供舒适的照明环境。此外，通过监控工人位置来调整温度并监测空气质量以维持舒适的工作环境。这有助于优化办公室内的能源消耗并智能管理环境。',
            dot: ''
        },
        {
            head: '结论',
            subHead: '',
            desc: '通过RTLS的智能办公室解决方案在资产管理、工人生产力和安全性增强等多个方面改善工作环境，提高企业竞争力。通过使用这些解决方案，组织可以构建智能办公室环境并为未来做好准备。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: '通过将UWB设备安装在工人或设备上，支持实时位置跟踪的产品。',
                img: productImg.product.assetTracking.thumbnail
            },
            {

            }
        ],
        solution: [
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/cn/enterprise/office/asset'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/cn/enterprise/logistics/asset'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/cn/enterprise/retail/exhibition'
            },
        ]
    }
}
