import { SCREEN_BREAKPOINT } from '@components/Grid/Grid.style';
import { StyledParagraph } from '@components/Text';
import { TypeMap } from '@core/util';
import styled from '@emotion/styled';
import { LayoutType } from '@redux/actions/common';

export const newsItemWidthMap: TypeMap<LayoutType, number> = { 
  desktop: 389,
  tablet: 320,
  mobile: 300,
};

export const newsItemGapMap: TypeMap<LayoutType, number> = { 
  desktop: 32,
  tablet: 32,
  mobile: 16,
};



export const StyledFunContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top : 48px;  

  column-gap : 32px;
  row-gap : 48px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    flex-direction : column;
    row-gap : 32px;
  
  }

`

export const StyledFunBox = styled.div`
  flex: 0 0 calc(50% - 32px);
  
`

export const StyledFun = styled.div`
  display: flex;
  flex-direction: row;
`


export const StyledImg = styled.img`
  width : 100px;
  height : 100px;
  object-fit : fit;


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 80px;
    height : 80px;
  }

`

export const StyledTextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left : 32px;


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-left : 16px;
  }

`
