import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconLocation, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconUserCheck, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/medicalPatient/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/medicalPatient/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/medicalPatient/video/sub/rtlsVideo3.mp4";

export default {

    banner: {
        title: '患者定位',
        desc: '医疗设施中存在需要特别管理的患者。无需直接进行24小时监控。可以实时追踪患者的位置，更加精准和迅速。',

    },

    video: {
        title: '更安全地管理患者的方法。',
        desc: '实时定位(RTLS)技术可以实时追踪患者的位置。<br/>可以大幅减少患者管理所需的人力和成本，并同时建立<strong>更安全的系统</strong>。',
        play: video
    },

    func: [
        {
            title: '实时定位',
            desc: '实时追踪患者在医疗设施内的位置。如果患者靠近医院内的禁止区域，系统将实时通知管理员。',
            sub1: '高精度提供',
            icon1: IconRulerGray,
            sub2: '快速数据交互',
            icon2: IconShareGray,
            video: subVideo1

        },
        {
            title: '病毒传播学调查',
            desc: '在新冠病毒之后，对传染病的早期应对变得更加重要。通过追踪感染者的移动路径，可以及早掌握接触者或可疑患者，从而预防设施内的传播。',
            sub1: '风险资产指定',
            icon1: IconWarningGray,
            sub2: '数据云存储',
            icon2: IconCloudCheckGray,
            video: subVideo2
        },
        {
            title: '跌倒检测',
            desc: '包括老年患者在内，活动能力受限的患者始终面临跌倒风险。因此，建立跌倒检测系统以进行即时响应。',
            sub1: '高精度提供',
            icon1: IconRulerGray,
            sub2: '快速数据交互',
            icon2: IconShareGray,
            video: subVideo3
        },


    ],



    subfunc: {

        title: '多种功能',
        desc: '医疗设施中最重要的是患者的健康。患者的状况应该随时监测，发生情况时快速响应是必不可少的。Orbro提供以下功能，始终保持安全的医疗设施。',

        data: [
            {
                icon: IconChartRadius,
                title: '报告与分析',
                desc: '基于患者的行动数据生成统计报告和分析资料。',
            },
            {
                icon: IconBell,
                title: '实时警报功能',
                desc: '如果患者在行动中出现异常情况，将发送警报，可以立即应对。',
            },
            {
                icon: IconRoute,
                title: '资产行动查询',
                desc: '跟踪特定患者的路径，以防止安全事故。',
            },
            {
                icon: IconDashboard,
                title: '状态了解',
                desc: '实时了解特定患者的当前位置。',
            },
            {
                icon: IconLocation,
                title: '室内导航',
                desc: '找到迷路或来访者，医务人员可以前去帮助。',
            },
            {
                icon: IconUserCheck,
                title: '体验改进',
                desc: '给予来医院就诊的人更大的信任。',
            },
            {
                icon: IconChainLink,
                title: '数据联动',
                desc: '与RFID、物联网、安全、视频等系统连接，实现高效利用。',
            },
            {
                icon: IconSecurity,
                title: '数据安全',
                desc: '保持高数据安全水平，提供可信赖的解决方案。',
            },


        ],
    },

    system: {
        title: '系统构成',
        img: rtlsSystem
    },

    productTitle: '产品配置',
    product: [

        {
            tag: 'peoplePackage',
        },
        {
            tag: 'assetPackage'
        },
        {
            tag: 'workerPackage'
        },
    ],


    contents: [
        {
            data: 'medicalWaste'
        },
        {
            data: 'logisticsSafety'
        },
        {
            data: 'blogMedical'
        },
        {
            data: 'blogOffice'
        },

    ]

}
