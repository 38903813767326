
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const CardBox = styled.div`
    display : flex;
    gap : 32px;
    
    
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        gap : 24px;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        flex-direction : column;
        gap : 16px;
    }
`;

export const CardGap = styled.div`
    margin-top : 32px;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        margin-top : 24px;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        margin-top : 16px;
    }
`;


export const BigCard = styled.div<{w : number, img : string}>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    width : 100%;
    ${({img})=> img && `background-image : url(${img});`}
    
    padding : 24px;
    aspect-ratio : 705 / 480;
    display : flex;
    
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        ${({w})=> w && `width : calc((1 / 2) * (${w}px - 32px));`}
        aspect-ratio : 440 / 440;
        
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 20px;
        width : 100%;
        aspect-ratio : 361 / 440;
    }
`;

export const SmallCard = styled.div<{w : number, img : string}>`
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    
    ${({img})=> img && `background-image : url(${img});`}
    padding : 24px;
    width : 100%;
    max-width : 495px;
    
    aspect-ratio : 495 / 480;
    display : flex;
    
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        ${({w})=> w && `width : calc((1 / 2) * (${w}px - 32px));`}
        aspect-ratio : 440 / 440;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        max-width : 1920px;
        background-size: cover;
        padding : 20px;
        width : 100%;
        aspect-ratio : 361 / 440;
    }
`;


export const NormalCard = styled.div<{w : number, img : string}>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    
    ${({w})=> w && `width : calc((1 / 3) * (${w}px - 64px));`}
    ${({img})=> img && `background-image : url(${img});`}
    height : auto;
    padding : 24px;

    aspect-ratio : 389 / 480 ;
    display : flex;
    
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        ${({w})=> w && `width : calc((1 / 3) * (${w}px - 48px));`}
        aspect-ratio : 288 / 440 ;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 20px;
        width : 100%;
        aspect-ratio : 361 / 440 ;
    }
`;



export const CardTextBox = styled.div`
    display : flex;
    flex-direction : column;
    height : 100%;
`;

export const DescBox = styled.div`
    margin-top : auto;
`;