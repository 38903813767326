

import { VideoBox, VideoHeaderText, VideoPage, VideoPlay } from './Video.style';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { StyledGridContainer } from '@components/Grid/Grid.style';
import { Trans } from 'react-i18next';
import { StyledParagraph } from '@components/Text';
import { DisplayH2 } from '@components/Text/HeadingTagDisplay.style';
import { BorderBottomLine, Spacing } from '@pages/CommonStyle/common.style';




interface ModalDefaultType {
  pageName: string
  path: any
}



const Video: React.FC<PropsWithChildren<ModalDefaultType>> = ({ pageName, path }) => {

  const video = path.video;

  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleScroll = () => {
    if (videoRef.current) {
      const videoTop = videoRef.current.getBoundingClientRect().top;
      const videoBottom = videoRef.current.getBoundingClientRect().bottom;

      const windowHeight = window.innerHeight;
      const isCurrentVideoPlaying = videoBottom >= windowHeight / 2 && videoTop <= windowHeight / 2 && !isVideoPlaying;

      if (isCurrentVideoPlaying) {
        if (videoRef.current.readyState >= 4) {
          videoRef.current.play();
          setIsVideoPlaying(true);
        }
      } else {
        setIsVideoPlaying(false);
      }
    }
  };



  return (
    <VideoPage>
      <StyledGridContainer>
        <VideoHeaderText>
          <DisplayH2 size='small'>
            <Trans i18nKey={path.video.title} />
          </DisplayH2>
          <Spacing pixel="8" />
          <StyledParagraph size='medium'>
            <p>
              <Trans i18nKey={path.video.desc} />
            </p>
          </StyledParagraph>
          <Spacing pixel='64' />
        </VideoHeaderText>

        <VideoBox>
          <VideoPlay ref={videoRef}
            autoPlay
            loop muted
            playsInline>
            <source src={video.play} type="video/mp4" />
          </VideoPlay>
        </VideoBox>

        <Spacing pixel='64' />
        <BorderBottomLine />

      </StyledGridContainer>
    </VideoPage>

  )
};

export default Video;