import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionEquipment, logisticsAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4';



export default {

    desc: '실내 GPS는 실내에서 이동하는 객체들의 위치를 측정하는 시스템입니다. 실시간으로 사람이나 사물들의 위치를 파악하는 것이 가능합니다.',
    keywords: '실내 GPS, GPS, RTLS, uwb, ble, beacon, ai camera, 위치추적, 실내 위치추적, 실시간 위치추적, 비콘, 오브로, 콩테크, orbro',

    ogSite: '실내 GPS 솔루션 | 위치추적 전문 플랫폼, 오브로',
    ogUrl: 'https://orbro.io/blog/indoor-gps',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDldPhj_PKmAYMbJqfxzx90kvwqvmaEJvuGa-Tilhn_LDEQtT2ogbSz96n2WA7ZAkr8WCRqOH4FmFoBufrCikZrXDwtb5It8GdPL_VxgMjGS1EnQAjJ4qrWYj4KcPq2uUwiEvls5sC1jYuNZWL9bM_ehwpvaclUZmuxqNlqIHYdw-FwmLbIsnVL148GQBP4aPXNwhvDLGmbonOGByrjok58YvYwxO339qVTHGs3T_pNB3bpk3KtiI87LFAQTozPEkOlHvJdDGXaK_LwvsTpFUd_Y38nRFI6ff6khHkuBibyrmO3mFIAaSxXYgd--mNSgro0BX8zdHQ8oeun8sW6yug8ZCW_V0fedhTrafZhPhB6nDSrdpkoQs10G4ftI0mB_kPLYFSeyTOOORJzy5jXFMNQmlE9TwHl3RNxv32lu3iDvi1odQiYyAywd92rwhHhlULlmvPWd111HclIULU9SqZ4agxxncGZtw6e2TnyrWoNGJueJfvaH5fNe1AEUTSlxwvK6z_lSaG4AO6elSZZcwVN5_z_GK5f9GbYZWX98FSOF8GuENEjrNIi-rqewypjL2c50sP3_C6v3rzWQnZeXsW31fgBWLAeWZWuJTLN0s0dJ5qPgh_FJliOSsh8w5i9RXV-6kBIjI4EOxtOvLP47aYE1GhfOvxS4ckLRSt_qxWTxU4D8gTX55bKtdogVsY85YNRVjOWb1XPLXo1-28zu_1LePcA2zEaUuqqntLjWTlSpr6Z-wLGNgRAg_T9hl3DPo2Rt04piFBRqjrdIpuwE-YH4n9Z7mlUXuAcPiq6n8RyQzWRG_c-6K1wE9RTRoEtR4qOPvVOCp58VLuf9ZyZ3fc12wznVHAaMFUA5k6_hWeGX-Lo_Fa5qY0yAgq6xhHE3qmu9iNue_6L3rSRz2JDy8FJjGddpCtZAZESd-tbSTG4-IlytOnvM1CViy2ygKKcbWecEfllglOtxIWzclw3EZ8wXQFBFSmmhnnoMVXv5ksBL6JpLrVF3rOyGKZWISqxoMn_KdCKyBVHDjkCKsEDSyHsUfvdlJIdsYzown--og4tGE0XLrE4pNXLyIVp45JOS0yUaykHqkRPQDs9g-iioTlotza8AE5gBtWK_SF_-ULnujQu1fKBH8CyAYkUVSHJ0wWxk8kIYcpT6AagBiYU9-jqARQkc5x9PghJFx6rTa61P615QnzFKlWCDYKYxkNmwMFutN-ucVMK2MwUCjp_gt5h6xBiTT3eOg1S0F7KupXENgRSYm7E0s4GYje4Eizvlcfe-0eLS8yENjj4sYl2DORd_mAlF-5DX37Dssx_oov83DQQe6W_8GjOw5ieJpA-klmSxjRGZ5vrMc4U3EY8D-cejqv61EFJo8BPbbZMzgj6no9VNG1TSQ2vuaj0VA5vDl03Hx_MKtsHsGR47umJQDLT2Zk6jOmcSN65erXXx4SqDnrqJn9xL8iky-Z9ZWHL_Jdl9eTPERAcH0OmmHzq8ltKodC4bSz63jP2XGVBtdrPJBvwYVJVrbXoLsVsNNRQx1TSSPO_HA8XpPrbumKkuRJiUhBaCrX7j-6PK_b3e_nP2PMpzIXBD=w1595-h1272',
    ogType: 'website',

    title: '실내 GPS 솔루션',
    date: '2024-01-31',
    tag1: '#실내GPS',
    tag2: '#RTLS',
    tag3: '#UWB',
    tag4: '#위치추적',

    // <br/>
    text: [
        {
            head: '실내 GPS란?',
            subHead: '',
            desc: '실내 GPS는 실내에서 이동하는 객체들의 위치를 측정하는 시스템입니다. 실시간으로 사람이나 사물들의 위치를 파악하는 것이 가능합니다. GPS는 주로 실외에서 사용되며 위성 신호를 활용하여 위치를 정확하게 파악하는데, 그러나 건물 내부에서는 GPS 신호가 약해지거나 차단되는 경우가 많아 정확한 위치 파악이 어려워집니다.',
            dot: ''
        },
        {
            head: '실내 GPS 기술',
            subHead: '',
            desc: '실내 GPS는 이러한 한계를 극복하기 위해 다양한 기술을 활용합니다. 주요한 기술 중 하나는 UWB(Ultra-Wideband) 기술입니다. UWB는 매우 넓은 대역폭을 사용하여 정밀한 위치 측정을 가능하게 하는 무선 통신 기술로, 건물 내부에서 정확한 위치 데이터를 제공할 수 있습니다. UWB 태그와 수신기를 사용하여 실내에서 물체 또는 사람의 위치를 추적할 수 있습니다. 또한 AI(인공 지능) 카메라를 사용하여 실내 환경에서 사물을 인식하고, 이를 토대로 위치 추적을 수행할 수도 있습니다. AI 카메라는 이미지 또는 비디오를 분석하여 물체를 식별하고 그 위치를 파악하는 데 도움을 줄 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: 'UWB',
            desc: 'UWB (Ultra-Wideband) 기술은 실내 GPS 구현을 위한 주요 기술 중 하나로 사용됩니다. UWB는 매우 넓은 대역폭의 무선 통신 기술로, 정밀한 위치 측정 및 추적에 사용됩니다. 다음은 UWB 기술의 주요 특징과 실내 GPS에 대한 구현 방법에 대한 설명입니다:',
            dot: {
                a : '<strong> • 매우 넓은 대역폭 (Ultra-Wideband): </strong>UWB 기술은 매우 넓은 대역폭을 사용하여 매우 짧은 시간 동안 많은 신호를 발생합니다. 이러한 대역폭은 정확한 시간 및 거리 측정을 가능하게 합니다.',
                b : '<strong> • 태그와 수신기: </strong>UWB 기술을 사용한 실내 GPS 시스템은 주로 "태그"와 "수신기"로 구성됩니다. 태그는 물체, 사람 또는 동물에 부착되며 UWB 신호를 발생합니다. 수신기는 이 신호를 수신하고 처리하여 위치를 계산합니다.',
                c : '<strong> • 시간-차이-신호 (Time-of-Flight, ToF) 측정: </strong>UWB 시스템은 태그에서 발생한 신호가 수신기에 도달하는 데 걸린 시간을 정밀하게 측정합니다. 이를 통해 실내 공간에서 두 장치 사이의 거리를 계산합니다. 다수의 수신기가 사용되면, 실내에서 객체의 위치를 3차원으로 계산할 수 있습니다.',
                d : '<strong> • 실시간 위치 추적: </strong>UWB를 사용한 시스템은 빠르고 정확한 위치 추적을 지원하므로 실시간 위치 데이터를 제공할 수 있습니다. 이것은 실내 환경에서 실시간으로 물체 또는 사람의 위치를 추적하는 데 매우 유용합니다.',
            }
        },
        {
            head: '',
            subHead: 'AI 카메라',
            desc: 'AI 카메라는 인공 지능 기술을 활용하여 화상 데이터를 분석하고 이를 기반으로 다양한 작업을 수행하는 카메라 시스템입니다. AI 카메라의 작동 방식은 다음과 같습니다:',
            dot: {
                a : '<strong> • 영상 수집: </strong>AI 카메라는 환경에서 비디오 또는 이미지를 캡처합니다. 이를 위해 렌즈, 이미지 센서 및 기타 하드웨어 구성 요소를 사용합니다. 환경에서 수집한 영상은 디지털 이미지로 변환됩니다.',
                b : '<strong> • 데이터 전처리: </strong>수집한 영상 데이터는 전처리 과정을 거쳐 최적화됩니다. 이 단계에서는 영상의 해상도 조정, 노이즈 제거, 색상 보정 등의 처리가 수행됩니다.',
                c : '<strong> • 특징 추출: </strong>AI 카메라는 영상 데이터에서 중요한 특징을 추출합니다. 이 특징들은 물체, 얼굴, 움직임 등과 관련된 정보를 포함합니다. 특징 추출은 주로 컴퓨터 비전 알고리즘을 사용하여 이루어집니다.',
                d : '<strong> • 딥 러닝 모델 적용: </strong>AI 카메라는 딥 러닝 기술을 사용하여 추출한 특징을 심층 신경망 모델에 입력합니다. 이 모델은 훈련된 상태에서 입력된 데이터를 분석하고 판단하는 역할을 합니다. 딥 러닝 모델은 실시간으로 식별, 분류, 추적 및 예측을 수행할 수 있습니다.',
                e : '<strong> • 분석 및 판단: </strong>AI 카메라는 딥 러닝 모델을 사용하여 분석 및 판단을 수행합니다. 예를 들어, 얼굴 인식 시스템은 얼굴을 식별하고 얼굴 특징을 추출하여 해당 얼굴의 신원을 확인할 수 있습니다. 또한 물체 추적 시스템은 물체의 위치를 실시간으로 감지하고 추적할 수 있습니다.',
                f : '<strong> • 결과 출력: </strong>AI 카메라는 분석 및 판단 결과를 출력 형식으로 제공합니다. 이 결과는 다양한 형태로 표시될 수 있으며, 주로 사용자 또는 연결된 시스템에 정보를 전달합니다.',
            }
        },
        {
            video :video,
            link : '/enterprise/logistics/asset',
            head: '일반 GPS와의 차이점',
            subHead: '',
            desc: '실내 GPS와 일반 GPS(실외 GPS) 사이에는 몇 가지 중요한 차이점이 있습니다. 이러한 차이점은 GPS 시스템이 주로 실외 환경에서 작동하는 데 최적화되어 있으며, 실내에서의 정확한 위치 추적을 어렵게 만듭니다. 다음은 실내 GPS와 일반 GPS 간의 주요 차이점입니다:',
            dot: ''
        },
        {
            head: '',
            subHead: '신호 강도 및 차단',
            desc: '',
            dot: {
                a : '<strong> • 실외 GPS: </strong>일반 GPS는 위성 신호를 사용하여 위치를 결정합니다. 신호는 대기 중에서 자유롭게 전파될 수 있으므로 일반적으로 강도가 높고 건물과 장애물에 의한 차단이 적습니다.',
                b : '<strong> • 실내 GPS: </strong>건물 내부에서는 GPS 신호가 약화되거나 차단될 가능성이 큽니다. 벽, 천장, 바닥, 금속 구조물 등이 GPS 신호를 흐리게 하거나 차단할 수 있습니다.',
            }
        },
        {
            head: '',
            subHead: '위성 신호 수신',
            desc: '',
            dot: {
                a : '<strong> • 실외 GPS: </strong>일반 GPS는 최소한 3개 이상의 위성 신호를 수신하여 위치를 정확하게 파악합니다. 일반적으로 4개 이상의 위성을 사용하는 경우 더 정확한 위치 정보를 제공합니다.',
                b : '<strong> • 실내 GPS: </strong>건물 내부에서는 위성 신호를 적게 받거나 아예 받지 못하는 경우가 많습니다. 이로 인해 정확한 위치 추적이 어려워집니다.',
            }
        },
        {
            head: '',
            subHead: '보정 및 보완 기술',
            desc: '',
            dot: {
                a : '<strong> • 실내 GPS: </strong>실내에서의 위치 추적을 위해 UWB(Ultra-Wideband), RFID(Radio-Frequency Identification), Wi-Fi, 블루투스, 지상 기반의 위치 추적 시스템 등과 같은 다른 기술이 사용됩니다. 이러한 기술을 결합하여 실내 GPS 시스템을 구현하고 정확도를 향상시킵니다.',
                b : '',
            }
        },
        {
            head: '',
            subHead: '정확성 및 정밀도',
            desc: '',
            dot: {
                a : '<strong> • 실외 GPS: </strong>일반 GPS는 좋은 조건에서 정확도가 1미터 내외로 높을 수 있습니다.',
                b : '<strong> • 실내 GPS: </strong>실내 GPS 시스템은 정밀한 위치 측정을 위해 더 많은 노력이 필요하며, 정확도는 환경, 사용되는 기술 및 구현에 따라 다를 수 있습니다. 일반적으로 더 낮은 정밀도를 가집니다.',
            }
        },
        {
            head: '실내 GPS 사용사례',
            subHead: '',
            desc: '실내 GPS 기술은 실내 네비게이션 등 다양한 응용 분야에서 활용됩니다. 이를 통해 사용자는 건물 내에서도 정확한 위치 정보를 얻을 수 있으며, 관련 서비스 및 응용 프로그램의 편의성과 효율성을 향상시킬 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '쇼핑몰 내 상품 위치 추적',
            desc: '소비자가 쇼핑몰을 방문할 때, 스마트폰 앱을 통해 실내 GPS를 사용하여 원하는 상품의 위치를 실시간으로 찾을 수 있습니다. 이로써 소비자는 빠르게 원하는 상품을 찾을 수 있으며, 상점은 고객의 구매 경험을 향상시킬 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '병원 내 환자 위치 추적',
            desc: '병원은 환자의 위치를 실시간으로 파악하여 의료진과 환자의 안전을 보장하는 데 활용할 수 있습니다. 예를 들어, 의료진은 환자가 어디에 있는지 파악하고 응급 상황에 빠르게 대응할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '공항 내 승객 안내',
            desc: '큰 규모의 공항에서는 승객들이 게이트, 레스토랑, 상점 등을 쉽게 찾을 수 있도록 실내 GPS를 활용합니다. 스마트폰 앱을 통해 승객은 공항 내 위치를 파악하고 필요한 정보를 얻을 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '공장 내 자동화 및 로봇 제어',
            desc: '실내 GPS를 사용하여 로봇 및 자동화 장비의 위치를 정확하게 추적할 수 있습니다. 이를 통해 생산 공정을 최적화하고 작업 효율성을 향상시킬 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '실내 네비게이션',
            desc: '건물 내부에서의 실내 네비게이션은 박물관, 대학교, 복합 시설 등에서 유용하게 활용됩니다. 방문객이 건물 내에서 목적지를 쉽게 찾을 수 있으며 관광 명소나 교육 시설을 탐색할 때 도움을 줍니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '자동차 주차 관리',
            desc: '실내 GPS는 주차장 내에서 빈 주차 공간을 식별하고, 드라이버가 주차할 곳을 안내하는 데 사용됩니다. 이를 통해 주차 시간을 단축하고 주차장의 효율성을 높일 수 있습니다.',
            dot: ''
        },
        {
            head: '종합',
            subHead: '',
            desc: '실내 GPS는 건물 내부에서 위치를 정확하게 추적하는 기술로, UWB와 AI 카메라 등의 기술을 활용하여 다양한 응용 분야에서 활용됩니다. 이 기술은 쇼핑몰 내에서 상품 위치 추적, 병원에서 환자 위치 파악, 공항 승객 안내, 공장 자동화 및 로봇 제어, 실내 네비게이션, 자동차 주차 관리와 같은 다양한 사용 사례에서 효율성과 편의성을 제공합니다. 그러나 실내 GPS와 일반 GPS 간에는 신호 강도 및 차단, 위성 신호 수신, 정확성 및 보정 기술에서 차이가 있으며, 실내 GPS는 주로 실내 환경에서의 위치 추적에 특화되어 있습니다.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/enterprise/retail/exhibition'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/enterprise/logistics/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/enterprise/construction/equipment'
            }
        ]


    }


}