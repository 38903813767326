import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/constructionSafety/video/rtlsVideo.mp4";

export default {

    
  helmet: '| Prevention Solution for Serious Accident Penalty Law: Construction Site Safety Management',
  desc: 'To prevent serious accidents and penalties, build a smart and safe construction site to manage safety in real-time based on the real-time location of workers. Utilize UWB RTLS technology to enhance safety on construction sites.',
  keywords: 'worker safety management, construction site safety management, construction site, site safety, construction safety, smart construction, heavy equipment management, comprehensive information network for construction site safety management, real-time location tracking, safety management, rtls, uwb, orbro, kongtech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Prevention Solution for Serious Accident Penalty Law: Construction Site Safety Management :: Orbro Blog',
  ogUrl: 'https://orbro.io/en/blog/construction-solution',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxcFbqyCgsLqX77SjZSbmhPyHShDbN57Y0uVQssyTRIySb5QaO86GwRQU2XDIM6wENVOKG_sn4SuK6BIHu6D3_n9v4DTFhHsRRVUg7UmWsG9qrjE9rGDPe6P0_Ox2um950sgEHtj-KLjzqld-ZdzNkaE9QjgTMEVmj2tkQ1pPVRozYUD-xIl94PQaGNp7tQuO1Hob1FvMuSLLEcdXdUxZmR9CoCl4ArjWfuuwr1d6lvN2umbSY0_y7mc8jTFyCu9Dk5Wi_v3aDRSH29LhUBlpZyeEyM5oCecT8fbW04YC3xCmuCmG41FwPtKf2akaJlFuBsSXINWwYzKejipOUhx5BayplcpMLZKsJoGI_6BI4RTrNIGGFpS4LZqAmgN3JWX43wftmGOlcwJ2np2WFszY2X5QqQdeAdnTXKLzgiYRCcjrM0cHzvzsLebg0KbpyAgCiLawwttYXLmqYEUsw-7it4OWqarLHXZxq4K2hggeMrpWJVFc6R84ruA7y4WPP4lNxVTbcGz3h0tcwGbYv8mGEc5UjMsDalMc8fOmsS5_Dy9qrOewKS-A4oZsW0bBJ1IYnhhHgCvKL4VP19n_PzOnu51vLF_B2xWmnR6pT5F3wa7GapNjG0QEN3185QO4AI9hysQXwb4wLEZqT5dGeHzwRvfIfrp_NCJ6xUbTHt3W070iXbqCG-7liIoaYzwbWYUA_U21h4elbMhPdZqvaIJBQRWBRRKxo8RdjyguhL-3b3LSOd4ffx3PJuvI6T7d5Q89IwZccvi2VLTR2Z5XiTUAveb8J_U3zPTKmIliM2rNcFCQ2zUW61y3Je0voWlaxLRy-_eHoH-tLuixemebIJo770TZZk69mhoNcuzZf0HepRpMoZ_gK7WX5gZ9ybogR9JvNsEQ6do078xyLvTKuZQpri86vobe9PvXeTkVM-_h6agSAcUfUihK0GLiehcw4MjrNaXaq-eqEEEf_aWtfPJAjuJ7JXtFXOiuO6hBMA1-qPq6e_VOOcRJl3PigwW66TzDYgjowTnaD7M1dWDXFYFz_OJS0u8UQbmeKd1TT5h30l70YXeabd4BvtI33YLCL_fJ2ReOIzwUmzZZiEBplwIAxzDcNPvmVwfd5YzvxVGdtm3WEBbzZR0q-MdVvbABRxn90yZ7U2vcwgvNbSTJbC5aWQyI7Jd6LiooIa8nWVkVwaMK_vOmDwotOk2vnamSORh837qPfvlDmUuBX7RRN19heNUGbFF20032J3_JRYcT3AA8eYmTV8LKhQuF0eNQAUe40lPKPGrdrnDHBsjBdHgoOxOHd-ChckYyCFOL3a-FpQv4OO_OpXL6lWWv4SwJsdzXxWT0h2uqoGu0ONpkDv7O3WiZF4E3cQ46IlBsU_K4JWsh1R9pjjLBSRy61vgbvfD4a7yzwU-qvZ6sd5wm6HyVVOU4pmgYoH1SG86ZISmHsUXYxdrWo4vuJDA1If2gUg6P7Y7uMooHmA2MlFPgVsT3xqakmYR-xx3Z3gZnIbcFzO7LldLuSvs8Ha8nMoI9HM2dh6uxK5_9OTfmQRIGHTZngWgCBZrRIz4ARaIxBZiw=w2249-h1298',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Prevention Solution for Serious Accident Penalty Law: Construction Site Safety Management',
  date: '2023-12-11',
  tag1: '#SeriousAccidentPenaltyLaw',
  tag2: '#LaborSafety',
  tag3: '#ConstructionSite',
  tag4: '#RTLS',
    
  // <br/>
  text: [
    { 
      head: '',
      subHead: '',
      desc: 'To establish a safe construction site, it is essential to monitor the safety of workers in real-time, prevent accidents, and respond quickly in the event of an occurrence. Particularly, considering the Serious Accident Penalty Law, effectively utilizing the location tracking technology called \'Real-Time Location Tracking Technology\' is crucial.',
      dot: ''
    },
    {
      video: video,
      link: '/en/enterprise/construction/safety',
      head: '1. Key Features of Real-Time Location Tracking Technology',
      subHead: 'a. Accurate Location Identification',
      desc: 'RTLS technology tracks the precise location of workers in real-time using GPS, RFID, UWB, etc., creating a safe working environment and identifying danger zones in advance.',
      dot: ''
    },
    {
      head: '',
      subHead: 'b. Monitoring Worker Safety',
      desc: 'Monitor the movements of workers based on location data to real-time confirm their safety status during work. Quickly detect risky situations or areas and take appropriate measures.',
      dot: ''
    },
    {
      head: '',
      subHead: 'c. Monitoring Heavy Equipment',
      desc: 'Real-time tracking of the location of not only workers but also heavy equipment and inventory allows understanding the site situation. This helps in identifying collisions with workers or approaching hazardous areas, ensuring better control and management of the site.',
      dot: ''
    },
    {
      head: '',
      subHead: 'd. Swift Response to Incidents',
      desc: 'The RTLS system quickly identifies the location in the event of an incident, understands the incident details, and enables a rapid response. It facilitates effective handling in emergency situations.',
      dot: ''
    },
    {
      head: '',
      subHead: 'e. Data Collection and Analysis',
      desc: 'Collect and analyze location data to understand the work patterns and danger zones of the construction site. Contribute to proactive responses and improvement of safety policies.',
      dot: ''
    },
    {
      head: '2. Advantages',
      subHead: 'a. Strengthened Safety',
      desc: 'RTLS enhances safety by accurately identifying the location of workers. Swift response to incidents ensures compliance with the Serious Accident Penalty Law and guarantees worker safety.',
      dot: ''
    },
    {
      head: '',
      subHead: 'b. Improved Productivity',
      desc: 'Utilize accurate location information to establish efficient worker placement and work planning, optimizing the construction process and enhancing productivity.',
      dot: ''
    },
    {
      head: '',
      subHead: 'c. Cost Savings',
      desc: 'Minimize losses resulting from accident prevention and rapid response, and achieve cost savings due to increased productivity.',
      dot: ''
    },
    {
      head: '',
      subHead: 'd. Effective Resource Management',
      desc: 'Effectively manage resources within the construction site through RTLS, minimizing waste of unnecessary resources and enabling efficient operation.',
      dot: ''
    },
    {
      head: '',
      subHead: '',
      desc: 'RTLS technology, aimed at minimizing accidents and improving the working environment, plays a crucial role in the construction industry. It will greatly contribute to enhancing safety and efficiency on construction sites.<br/><br/>(Click on the video above to go to the corresponding solution.)',
      dot: ''
    },

  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
        {
          text: productPurchase.purchase.worker.workerTracking.title,
          title: productPurchase.purchase.worker.workerTracking.title,
          serial: productPurchase.purchase.worker.workerTracking.serial,
          desc: 'This product assists in real-time location tracking by attaching UWB terminals to workers or equipment.',
          img: productImg.product.workerTracking.key1
        },
      {

      }

    ],
    solution: [
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      }, 
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/en/enterprise/manufacturing/asset'
      }
    ]

  }


}
