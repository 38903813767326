import {
  constructionAsset,
  constructionSafety,
  logisticsAsset,
  logisticsSafety,
  manufacturingAsset
} from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { BlogOrbroWorld } from "@assets/images/blog";

export default {
  helmet: "| 数字双胞胎的本质和潜力",
  desc: "数字孪生是一种将现实世界的物理实体和过程数字化复制的方式，从而可以在数字空间中对现实世界的系统进行建模和模拟。",
  keywords: "数字双胞胎，数字双胞胎技术，数字双胞胎的价值，数字双胞胎使用案例，未来技术，数字创新，未来愿景，orbro，오브로，ConTech",

  ogTitle: "",
  ogDesc: "",
  ogSite: "数字双胞胎的本质和潜力 :: Orbro博客",
  ogUrl: "https://orbro.io/cn/blog/digitalTwin2",
  ogKey: "",
  ogImg: "https://lh3.googleusercontent.com/fife/AK0iWDyMknjvJ99ToTqI13n6sJ8BSaf3JZGOENkN_2JU7vwTVdJ1mg0nffpOx5r6tq6lv4F0bOXSJdbY8A6oX1AFY03LIJf2JtFF2wRcEJOJaquWjtZsd0mWpQBq_pEUGTJFnlWDkhyXb4I74BgzY1m4mqHU6tEW28PtAaGDrwMAQtY2jBxkHkkc2CElFc6iCNlhM0CBo5Q5nJ5smX5h-I7jl-DfT5xZkB0go6qpIOi66-rEsvQSbT33lnaaD5QvGwFOO0Qv2cvnKoewfm0pA5WcKdE2-m7UMQJBBnPNaQSttqkpy3a1ROL6WpobJ4hXVx6u7sgSCjox5fUSiLYwOz7Ogj48pxEWoqBOPMi8xU6SPj1GkXJlNbKSLFiPh1Gxx4gAj6yna064glGTGfkFz4utstmCQDRyUfHIBNWgoRIA6w7sZTibsxQw0kVLOs1zvoSpcVnuqfTeCeMc2zVn-RPDhOYs47NdtJw15gD-7g2SEnQoPQ0jPuPsUlQK3cZiwGJPXqaUpk_bJEHoKljrUPvZ4RskxzXd_Wd_iUR_fAsXgtfYJA2y69CplR2X8zI2xNr6o3QMeEnodvetxx_wijsIzX0VB-NY9ARHuYoDLWeuluJd3L5Jwbz7RUt9Daau7aK0xPzczhs0wCZukWXKxEHTa4vsViogKLwCwQCGRH0i-etPSDQoL6QDgCZYMpi19QP2c4EpWmal3bbzPuDTXE9c5KGhCoKeadtLOyfRIQSXO4j3qfTLZDhlfYui2qpfof_b8iQDXbrYnKdQFKCF1BsOgdB-x6BFgyxy4yiJFodhm11ZsU2hE-FHcFe57GTIa-9BoCesvhAASwxcG3Jb87SMGY20XeoYQRdKybgwKJVtyHYIyr-96-_YGoT3K0R1g8Rh_-5g8ieKsx9xYxgBP_SRy15quSjqbY_sb-Br-ABcrOvK-f_8B3Fcs4MUyawnsk1F3Iq757aU6O9vr5eRVr0p_e617DypRhC-EsoUOnfuneCpYiwISUNEh7dzIWRwMoAg0nsWPpsaZBAFDzkfkWsNzdkHNsR51jz0otfpLr1G_F5MN4-TRI9fLTeBG72qfhqwexJIJ3hty3NLh45kAfx6b4jQVDuVOEloTq3hBRamRttcG_zUXLYPChDX4_px_PqdCbBFebzcfjbJUSwJddmFGYkV8Oc0z7dze6CyvuHcWRubrB7_QHLFSrKfkJAQWWwuL6pILkMJathIpb7hFq7aW9OEeRdf7IWj4VFsGIRvc-B_Pi7Rkvw4yTyX18g6trPF-jBAa7kbSdyNvEqsfT7SJLR2-N3Gt8Y4u2rF8_YwJ8xywkibJnFJwrmqFbsLwJJbquz__yB38C0nECD5y5NUoN1ZJVQ4g2ZhUEJAKWxvXGu7SqhfmR-BssX_5pHjrMO5KNdT4GzxR6UMQmqq0P81ai916GVxC9u-klTiMQxnWqlOAVF8oxfYqPkwAQ9gjipWXh2CpHh12DVNM22CcBvVmV3r35ystH3OAURGJoOQ0RSPUw6ULmaboU1q4tLaJa08YT-WN9q9XNnbjgHFb654mIrS4AI-5Sm_OQxMxxO7bymwwgJH5VMy=w2000-h1916",
  ogType: "网站",

  twitterCard: "",
  twitterTitle: "",
  twitterDesc: "",

  title: "数字双胞胎的本质和潜力",
  date: "2023-10-26",
  tag1: "#数字双胞胎",
  tag2: "#数字转型",
  tag3: "#智能城市",
  tag4: "#RTLS",

  text: [
    {
      img: BlogOrbroWorld,
      link : "https://home.orbro.io",
      head: "",
      subHead: "",
      desc: "现代社会不断经历进化和变革，主要是由于数字技术的快速发展所推动的。这一创新是以数字双胞胎的概念为基础的，数字双胞胎以数字形式复制了现实世界的物理实体和过程，使我们能够在数字空间中对真实世界的系统进行建模和模拟。这正改变了我们的工作、生活和技术使用方式。",
      dot: "",
    },
    {
      head: "1. 数字双胞胎的概念",
      subHead: "",
      desc: "数字双胞胎是物理实体的数字复制品，可以在数字空间中复制和监控真实系统或过程。这一数字复制使用传感器数据、物联网设备、模拟和大数据分析等来捕捉现实世界的所有方面。它允许我们预测和优化系统的运作。",
      dot: "",
    },
    {
      head: "2. 数字双胞胎的价值",
      subHead: "",
      desc: "数字双胞胎为各种行业领域提供了价值。在制造业中，数字双胞胎用于管理产品生命周期并优化生产过程。通过监控产品性能并使用预测性分析，有助于提前防止故障。在建筑行业，数字双胞胎支持建筑和设施的建设和运营。通过项目规划、模拟和监控，项目可以按计划进行并有效管理预算。在医疗领域，数字双胞胎跟踪患者的健康状况并支持疾病的早期诊断和预防。它还用于优化医疗设备和制药的开发和生产过程。在能源领域，数字双胞胎优化发电厂、电力网格和能源系统的运作，提高能源效率。",
      dot: "",
    },
    {
      head: "3. 数字双胞胎的应用案例",
      subHead: "",
      desc: "Orbro已经在各种行业中执行了许多应用数字双胞胎的项目，提高了工作效率和生产力，涵盖了从医院到制造设施等各种情境。此外，数字双胞胎的应用不断扩大。在航空航天行业，它用于监测飞机的状态并改进维护。在城市规划中，它优化城市的基础设施和交通系统。在农业中，数字双胞胎跟踪作物的生长和健康，提高了农业生产率。智能城市项目将城市的每个方面建模为数字双胞胎，推动城市的智能化和可持续性。数字双胞胎是融合物理世界和数字世界的创新方式，在各种行业中提供价值，促进创新和效率提高。这项技术预计将在未来变得更加重要，企业和组织应积极利用数字双胞胎来增强竞争力并实现可持续增长。",
      dot: "",
    },
    {
      head: "结论：数字双胞胎的未来和潜力",
      subHead: "",
      desc: "数字双胞胎无疑将成为未来的关键技术之一。它的价值无限，推动各种行业的创新和生产力。当然，还存在重要的考虑因素，如安全和个人数据保护，但随着技术的发展，这些问题将持续得到解决。数字双胞胎将改变我们的日常生活和业务，打破现实和数字世界之间的界限，创造更智能和互联的世界。我们应积极拥抱这项技术，探索数字双胞胎为塑造未来带来的新机会。",
      dot: "",
    },
  ],

  link: {
    page: "rtls",
    numb: "1",
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: "通过安装UWB终端设备在工人或设备上，支持实时位置跟踪的产品。",
        img: productImg.product.assetTracking.key1,
      },
      {},
    ],
    solution: [
      {
        img: constructionSafety.thumb,
        data: "constructionSafety",
        link: "/cn/enterprise/construction/safety",
      },
      {
        img: logisticsAsset.thumb,
        data: "logisticsAsset",
        link: "/cn/enterprise/logistics/asset",
      },
      {
        img: manufacturingAsset.thumb,
        data: "manufacturingAsset",
        link: "/cn/enterprise/manufacturing/asset",
      },
    ],
  },
};
