import { IconBell, IconBinocularsGray, IconChainLink, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import mainVideo from "@src/assets/images/solution/constructionSafety/video/rtlsVideo.mp4";
import subVideo1 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo4.mp4";
import subVideo5 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo5.mp4";

export default {
    
    banner : {
        title : '작업자 안전 관리',
        desc : '건설 현장 작업자 안전 관리는 필수입니다. 중대재해처벌법과 더불어 작업자의 안전을 보장하지 않는 것은, 생산성에도 문제가 생길뿐더러 법적인 책임까지도 발생할 수 있습니다. 또한, 안전한 건설 현장은 기업의 이미지와 이해관계자들의 신뢰를 형성하는 데 중요한 역할을 합니다.',

    },

    video : {
        title : '건설 현장 작업자를 더 안전하게.',
        desc : '건설 현장 작업자의 위치를 실시간으로 알려줍니다. 관리자는 위험 상황을 미리 감지할 수 있고, 문제 발생 시 더 빠른 대응이 가능해집니다. <br/>또한, <strong>안전 규정을 준수</strong> 하도록 지원하고, 작업자와 관리자 사이의 신뢰를 구축합니다.',
        play : mainVideo
    },

    func : [
        {
            title : '안전 장비 착용 확인',
            desc : '작업자가 안전모 등의 안전장비를 착용하고 있는지 실시간으로 감지하여, 작업 중 안전사고를 예방하고 안전한 작업 환경을 유지할 수 있도록 지원합니다. 현장의 안전관리를 강화하여 조직의 안전성과 신뢰도를 높일 수 있습니다.',
            sub1 : '높은 정확도 제공',
            icon1 : IconRulerGray,
            sub2 : '실시간 현황 파악',
            icon2 : IconClockGray,
            video : subVideo1
        },
        {
            title : '실시간 작업자 위치 추적',
            desc : '작업자의 위치를 실시간으로 추적하여 안전 상황을 모니터링하고, 위험한 지역에 접근하거나 긴급 상황이 발생할 경우 신속하게 대응할 수 있도록 도와줍니다. 이로써 잠재적인 위험을 사전에 예방합니다.',
            sub1 : '실시간 위치 추적',
            icon1 : IconBinocularsGray,
            sub2 : '빠른 데이터 연동',
            icon2 : IconTvocGray,
            video : subVideo2
        },
        {
            title : '실시간 중장비 위치 추적',
            desc : '안전에 유의해야 하는 것은 사람뿐만이 아닙니다. 잠재적 사고 위험이 있는 중장비의 위치를 실시간으로 파악하여, 작업자가 근접하거나 잘못된 경로로 들어서는 경우를 파악하여 사고를 예방할 수 있습니다.',
            sub1 : '실시간 위치 추적',
            icon1 : IconBinocularsGray,
            sub2 : '높은 정확도 제공',
            icon2 : IconRulerGray,
            video : subVideo3
        },
        {
            title : '작업자 동선 파악',
            desc : '작업자가 향하는 경로에 위험한 장비나 물질이 있는 경우, 관리자에게 경고를 할 수 있습니다. 이로써 신속한 대응 또는 구조가 가능해집니다.',
            sub1 : '통계 데이터 제공',
            icon1 : IconChartBarGray,
            sub2 : '데이터 클라우드 저장',
            icon2 : IconCloudCheckGray,
            video : subVideo4
        },
        {
            title : '안전구역 설정',
            desc : '현장에서 접근해서는 안 되는 곳이나 주의해야 하는 곳을 미리 설정하여, 근처를 지나가는 작업자에게 경고하거나 관리자에게 알림을 보낼 수 있습니다. 이로써 사고를 예방할 수 있습니다.',
            sub1 : '자유로운 범위 설정',
            icon1 : IconZoneGray,
            sub2 : '여러 구역 설정',
            icon2 : IconTagGray,
            video : subVideo5
        },


    ],



    subfunc : {
        
        title : '다양한 기능',
        desc : '작업자의 안전을 보장하는 것은 효율성을 높이고 사고로 인한 손실을 줄입니다. 오브로는 건설 현장 작업자의 안전을 지키기 위해서 최적의 기능들을 구성하였습니다. 작업자가 위험 지역에 다가거나 사고가 발생했을 때, 또는 중장비가 가진 잠재적 위험상황을 포함하여 발생할 수 있는 안전사고에 적절히 대응하도록 시스템을 구축하였습니다.',
        
        data : [
            {
                icon : IconChartRadius,
                title : '리포트 및 분석',
                desc : '현장 데이터를 기반으로 보고서 및 분석 자료를 생성할 수 있습니다.',
            },
            {
                icon : IconBell,
                title : '실시간 알람 기능',
                desc : '이상 상황이 발생하는 경우 알람을 보내어 즉각적으로 대처할 수 있습니다.',
            },
            {
                icon : IconRoute    ,
                title : '동선 이력 조회',
                desc : '작업자가 현장에서 움직였던 동선을 조회하여 데이터화 할 수 있습니다.',
            },
            {
                icon : IconDashboard,
                title : '현황 파악',
                desc : '현재 얼마나 많은 작업자들이 어떤 흐름으로 이동하고 있는지 파악합니다.',
            },
            {
                icon : IconWarning,
                title : '이탈 감지',
                desc : '작업자가 현장에서 이탈하거나 접근 금지 구역에 다가가는 상황을 실시간으로 추적합니다.',
            },
            {
                icon : IconSearch,
                title : '동선 패턴 분석',
                desc : '작업자들이 어떤 방식으로 작업하는지 파악하여 작업 효율성을 높일 수 있습니다.',
            },
            {
                icon : IconChainLink,
                title : '데이터 연동',
                desc : 'RFID, IOT, 보안, 영상 등의 시스템과 연결하여 효율적으로 활용할 수 있습니다.',
            },
            {
                icon : IconSecurity,
                title : '데이터 보안',
                desc : '높은 데이터 보안 수준을 유지하여 신뢰성이 높은 솔루션을 제공합니다.',
            },


        ],
    },

    system : {
        title : '시스템 구성',
        img : rtlsSystem
    },

    productTitle : '제품 구성',
    product : [
        {
            tag : 'workerPackage'
        },
        {
            tag : 'assetPackage'
        }

    ],


    contents : [
      {
        data : 'constructionAsset'
      },
      {
        data : 'constructionEquipment'
      },
      {
        data : 'blogRTLS'
      },
      {
        data : 'blogMedical'
      },

    ]



}