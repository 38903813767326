import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconChartRadiusGray, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconTagGray, IconTvocGray, IconUserCheck, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { IconTemp } from "@assets/images/main";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo4.mp4";

export default {
    banner: {
      title: 'Real-time Process Management',
      desc: 'You can understand and manage the flow of manufacturing processes at a glance. By using accurate and objective data, you can improve the process line and increase efficiency. This is the key to the manufacturing industry.',
    },
    video: {
      title: 'Tracking Everything in Manufacturing Processes',
      desc: 'Real-time Location Tracking (RTLS) technology allows you to monitor manufacturing processes in real time. By understanding the current status of the production line in real time, you can achieve the highest efficiency.',
      play: video,
    },
    func: [
      {
        title: 'Production Line Monitoring',
        desc: 'Quickly identify abnormalities that occur during the manufacturing process and take appropriate measures to minimize disruptions and increase production efficiency.',
        sub1: 'Real-time Status Monitoring',
        icon1: IconClockGray,
        sub2: 'Providing Statistical Data',
        icon2: IconChartBarGray,
        video: subVideo1,
      },
      {
        title: 'Alleviating Bottlenecks',
        desc: 'Track the movement of raw materials, semi-finished products, and finished products in real time to identify where bottlenecks occur. This allows you to predict and take prompt action.',
        sub1: 'Real-time Location Tracking',
        icon1: IconBinocularsGray,
        sub2: 'Providing High Accuracy',
        icon2: IconRulerGray,
        video: subVideo2,
      },
      {
        title: 'Safety Management',
        desc: 'Detect accidents occurring in the production line in real time. This enables quick response to incidents and prevents major accidents.',
        sub1: 'Designating Hazardous Assets',
        icon1: IconWarningGray,
        sub2: 'Setting Response Methods',
        icon2: IconChartRadiusGray,
        video: subVideo3,
      },
      {
        title: 'Process Analysis',
        desc: 'Collect data to improve the efficiency of the production process. Identify problems easily to grow your business.',
        sub1: 'Data Cloud Storage',
        icon1: IconClockGray,
        sub2: 'Providing Statistical Data',
        icon2: IconChartBarGray,
        video: subVideo4,
      },
    ],
    subfunc: {
      title: 'Various Features',
      desc: 'The productivity of the process line is the key to the manufacturing industry. The features provided by ORBRO can further enhance the efficiency and productivity of the process line.',
      data: [
        {
          icon: IconChartRadius,
          title: 'Reports and Analysis',
          desc: 'Generate reports and analysis based on on-site data.',
        },
        {
          icon: IconBell,
          title: 'Real-time Alarm Function',
          desc: 'Send alarms when abnormalities occur in the process line, enabling immediate response.',
        },
        {
          icon: IconRoute,
          title: 'Route History Tracking',
          desc: 'Track the paths and travel times of internal moving objects.',
        },
        {
          icon: IconDashboard,
          title: 'Status Overview',
          desc: 'Monitor the current status of the process in real time.',
        },
        {
          icon: IconTemp,
          title: 'Environmental Analysis (Temperature, Humidity, etc.)',
          desc: 'Monitor internal environmental factors in logistics warehouses and detect anomalies in real time.',
        },
        {
          icon: IconUserCheck,
          title: 'Worker Instruction',
          desc: 'Provide instructions to workers based on the current situation.',
        },
        {
          icon: IconChainLink,
          title: 'Data Integration',
          desc: 'Integrate with systems such as RFID, IoT, security, and video to utilize them efficiently.',
        },
        {
          icon: IconSecurity,
          title: 'Data Security',
          desc: 'Maintain a high level of data security and provide a reliable solution.',
        },
      ],
    },
    system: {
      title: 'System Configuration',
      img: rtlsSystem,
    },
    productTitle: 'Product Configuration',
    product: [
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'manufacturingAsset',
      },
      {
        data: 'logisticsAsset',
      },
      {
        data: 'blogMedical',
      },
      {
        data: 'blogRTLS',
      },
    ],
  }
  