export default {
  titleBanner: {
    subtitle: 'TWR | 双向测距',
    title: 'TWR 定位技术',
    description: '*TWR（双向测距）技术是一种定位技术，<br/> 通过测量发射器和接收器之间往返信号的时间来计算距离。'
  },
  definition: {
    title: 'TWR 技术是什么？',
    text: `与 TDoA 不同，TWR 主要使用两个设备之间的双向通信。例如，可以实时监测放置在人员、物体或车辆上的位置跟踪标签的距离。这使得可以实现自主碰撞警报系统、近距离检测等双向通信应用而无需额外的基础设施。通过 TWR，可以使用两个设备之间的双向通信来实时监测它们之间的距离。因此，可以实现自主碰撞警报系统、近距离检测等双向通信应用而无需额外的基础设施。在 TWR 中，当两个设备彼此靠近时，它们开始进行测距，以确定它们之间的距离。然后将信号传播所需的时间乘以光速，从而持续地确定它们的相对位置。这通常使位置感知通信和服务成为可能。<br/><br/>为了测量距离，需要交换三种消息：<br/><br/>`,
    list: [
      `· 像标签一样的测距设备发送“Poll”消息来初始化TWR，以查询附近的标签或其他RTLS节点。`,
      `· 附近的设备记录“Poll”的接收时间并回复“Response”消息。`,
      `· 标签在收到响应消息后记录响应的接收时间。`
    ],
    text2: '<br/> 基于最终消息的接收时间，标签可以确定ToF（飞行时间）和它们之间的距离。'
  },
  advantage: {
    title: 'TWR的主要优点',
    items: {
      realTime: {
        title: '实时性',
        description: 'TWR技术具有非常低的延迟（2-5毫秒）和高更新速率（最高100Hz），可以实现准确的实时距离测量应用。'
      },
      twoWay: {
        title: '实现多种双向功能',
        description: '提供碰撞避免系统、近距离检测等距离测量功能。'
      },
      flexible: {
        title: '灵活性',
        description: '提供两个设备之间的点对点（P2P）范围应用，无需额外基础设施。'
      }
    }
  },
}
