export default {
  visual: {
  subtitle: 'Our Vision',
  title: 'Dynamic <br/> Virtual World Implementation.',
  description: `ORBRO was created to visually represent the approximately 200 zettabytes of data that exist worldwide. In order to create a sustainable data ecosystem, we are copying Earth's information to ORBRO and generating innovative data connections through 3D-shaped objects. <br/><br/> ORBRO connects location information collected from dedicated products and scattered online information, enabling more than 20,000 objects to move in real-time. To accelerate this speed, we are building an ecosystem that allows people worldwide to participate through an open policy. <br/><br/> Build your own world in ORBRO and join the digital transformation.`,
  items: [
    {
    title: `World's First`,
    description: 'Web-based virtual world platform'
    },
    {
    title: `World's Largest`,
    description: 'Platform connecting real and virtual information'
    },
    {
    title: '500+ Billion',
    description: 'Largest collection of analyzed location data in Asia'
    },
    {
    title: '1st Place',
    description: 'Industrial metaverse performance (based on Korea)'
    },
    {
    title: '150,000+',
    description: 'Units of ORBRO products sold'
    },
    {
    title: '500,000+',
    description: 'Cumulative number of service users'
    },
    {
    title: '20,000+',
    description: 'Real-time connected objects in ORBRO'
    },
    {
    title: '97.5%',
    description: 'Upload of most buildings worldwide'
    },
    {
    title: '1,000+',
    description: 'Cumulative number of client companies'
    },
    ]
  },
  ourPartners: {
  subtitle: 'Our Partners',
  title: 'Many companies are experiencing digital transformation through ORBRO'
  },
  ourTechnology: {
  subtitle: 'Our Technology',
  title: 'ORBRO Innovates <br/> Technology.',
  description: 'ORBRO possesses core technology in location analysis through years of research.',
  policy: 'Related Patents',
  items: [
    {
    title: 'Wireless Signal Stabilization',
    description: `The 2.4GHz signal faces difficulties in utilizing the signal due to the influence of the surrounding medium. Therefore, stabilizing the wireless signal is essential in beacon-based systems. ORBRO's wireless signal processing technology has been validated in over 300 locations for about 10 years and is designed to be quickly tuned to match the customer's references.`,
    policy: [
    'System, method, and computer-readable medium for determining the location of a wireless signal-based mobile tag',
    `Mobile beacon system for alerting a user's emergency situation to a control server`
    ]
    },
    {
    title: 'Directional Antenna Design',
    description: 'Designing antennas according to the environment is fundamental in wireless communication technology since Bluetooth signals are greatly influenced by the surroundings. It is possible to design both omnidirectional and directional antennas that can distinguish the direction of the signal. As a result, it is possible to supply antennas, products, and signal processing systems as a package, enabling more stable and efficient service implementation.',
    policy: [
    'Method and system for door control based on beacon signals',
    ]
    },
    {
    title: 'Signal Division / Multiple Signal Processing',
    description: 'By using multiple antennas, signals can be transmitted/received faster or the proximity of a mobile terminal (or mobile device) to a specific signal can be determined. By applying multiple signal processing technology, it is possible to distinguish between indoor and outdoor, entering and exiting a building, creating new service scenarios.',
    policy: [
    'System, method, and computer-readable medium for determining the location of a wireless signal-based mobile tag',
    'Information providing system for electronic devices within a store with security features'
    ]
    },
    {
    title: 'Low Power Wireless Trigger',
    description: 'Low power wireless trigger technology refers to the technology of temporarily enabling bi-directional communication by giving a trigger signal to a terminal that needs to transmit for a long time with low power. It operates more than 10 times faster and consumes more than 20 times less power than pairing and exchanging data. ',
    policy: [
    'Method and system for detecting beacon device signals'
    ]
    },
    {
    title: 'Mesh Network',
    description: `ORBRO's mesh network produces more effective results in environments where low-power communication is required. It operates based on the Flooding Mesh algorithm and low-power wireless trigger technology, consuming less than 50 times the power of conventional beacons while operating more than 3 times faster and allowing multiple information connections. It is effective when receiving information from multiple devices in an environment where power supply is not convenient.`,
    policy: [
    'Method for implementing a mesh network in a beacon system',
    'Safety bell system utilizing mesh network communication technology'
    ]
    },
    {
    title: 'Location Tracking of Omnidirectional Signals',
    description: `ORBRO's Insight analyzes the correlation between installed Twintrackers and user terminals to infer the direction vector of each Twintracker's Tag. The direction of the Tag can be known by utilizing the intersection of theoretically inferred vectors. However, in real environments, the intersection does not converge to one point due to signal instability and physical obstacles. To derive a two-dimensional position in such environments, the position is inferred based on probability density on a two-dimensional floor plan.`,
    policy: [
    'Method and system for deriving the two-dimensional position of a user terminal'
    ]
    },
    {
    title: 'Extraction of Object Coordinates from Images',
    description: 'By applying technologies such as Object Detection and Depth Estimation, people in images can be recognized, and the distance between the person and the camera can be estimated. Based on this, the direction of the person can be recognized in a two-dimensional floor plan, and coordinates can be calculated accordingly.',
    policy: [
    'Method and system for deriving the location of an object using camera images'
    ]
    },
    {
    title: 'Post-processing Algorithms for Location Analysis',
    description: 'By applying technologies such as Object Detection and Depth Estimation, people in images can be recognized, and their coordinates can be inferred using the distance and angle between them. To correct the results, the ratio of the distance between the camera when there is no person in the same space and the distance when a person is present is used to predict the coordinates of the recognized person and supplement the measured coordinate results.',
    policy: [
    'Method and system for improving the output of object position information using camera images'
    ]
    },
    {
    title: 'Control Using Machine Learning',
    description: 'By using 3C Reinforcement Learning models, the system automatically controls the temperature by penalizing users for adjusting the temperature and rewarding them for not adjusting it for a certain period of time.',
    policy: [
    'Method, system, and computer-readable medium for automatic and comfortable control of an air conditioner using a machine learning model',
    'System, method, and computer-readable medium for controlling multiple air conditioners in a space'
    ]
    },
    {
    title: 'Digital Twin Interface',
    description: 'ORBRO has a specialized interface structure for the digital twin. By clicking on objects in a 3D space, users can easily access associated apps.',
    policy: [
    'Method, service server, and computer-readable medium for providing an object-centered app execution interface',
    'Method, service server, and computer-readable medium for providing an object-related app list interface',
    'Method, service server, and computer-readable medium for providing an app list interface based on the association between objects and apps'
    ]
  },
  ]
  },
  ourCulture: {
  subtitle: 'Our Culture',
  title: 'This is How We Work at ORBRO.',
  description: 'Our journey to turn imagination into valuable reality',
  discovery: {
  title: 'Discovery',
  description: 'We discover opportunities in a world full of infinite possibilities.'
  },
  extension: {
  title: 'Extension',
  description: 'We expand our thinking through intense immersion.'
  },
  collaboration: {
  title: 'Collaboration',
  description: 'We bring imagination to life through close collaboration.'
  },
  refine: {
  title: 'Refine',
  description: 'We create and refine valuable outcomes.'
  },
  },
  ourHistory: {
  subtitle: 'Our History',
  title: 'The Story of ORBRO',
  histories: {
    '2022': [
    "Achieved 10 billion KRW in revenue within 7 years of establishment",
    "Won Red Dot, one of the world's top three design awards",
    "Launched the digital twin-based metaverse platform 'ORBRO'",
    "Introduced ORBRO system in Pangyo, Gyeonggi Province"
    ],
    '2021': [
    "Achieved three-fold increase in revenue compared to the previous year, expanded office in Pangyo",
    "Started the project 'Non-face-to-face collection and disposal robot for medical waste' in collaboration with the Ministry of Environment, Woojung Bio, LG Electronics, and Ajou University",
    "Won the project 'AI technology validation testbed establishment' from the Ministry of Science and ICT, operating the project team",
    "Won the project 'AI-based construction site risk prediction system' from the Korea Expressway Corporation"
    ],
    '2020': [
    "Applied AI location analysis technology at the world-class level, expanding the supply of real-time location tracking systems (RTLS) through Kongservice",
    "Increased the number of Kongcheck users by over 10 times compared to the previous year",
    "Won the project 'Development of region-specific disaster safety problem-solving technology' from the Ministry of Public Administration and Security, supplying Gyeonggi Ansim Bell"
    ],
    '2019': [
    "Launched Kongcheck service (became a top 3 company in the industry in 3 months), surpassed 10,000 paid users",
    "Gartner Vendor Briefing"
    ],
    '2018': [
    "Started global sales of Smart Factory Calibration Tool",
    "Started supplying IoT access control systems for large domestic apartment construction companies"
    ],
    '2017': [
    "Launched Kong Monitoring Service, installed it on all dangerous sections of domestic highways (approximately 2,000 locations)",
    "Established a subsidiary (Kong Monitoring)"
    ],
    }
  },

    news: {
      subtitle: 'News',
      title: 'ORBRO in the News',
      news: [
      {
      "agency": "Maeil Business Newspaper",
      "createdAt": "June 27, 2022",
      "title": "Kongtech Wins Red Dot, One of the World's Top Three Design Awards",
      "link": "https://www.mk.co.kr/news/business/view/2022/06/562542/"
      },
      {
      "agency": "Maeil Business Newspaper",
      "createdAt": "April 19, 2022",
      "title": "Kongtech Achieves 10 Billion KRW in Revenue within 7 Years of Establishment",
      "link": "https://www.mk.co.kr/news/business/view/2022/04/349210/"
      },
      {
      "agency": "Etoday",
      "createdAt": "April 18, 2022",
      "title": "Kongtech Surpasses 10 Billion KRW in Revenue, Establishes a 17 Billion KRW Base in Pangyo",
      "link": "https://www.etoday.co.kr/news/view/2125046"
      },
      {
      "agency": "DataNet",
      "createdAt": "October 29, 2021",
      "title": "Kongtech Provides Latest IoT Technology to SK Shelters and Jeonglim Architecture",
      "link": "http://www.datanet.co.kr/news/articleView.html?idxno=165926"
      },
      {
      "agency": "Financial News",
      "createdAt": "October 14, 2021",
      "title": "Kongtech CEO Lee Hak-kyung: 'There Are No Miracles in Management, Only Intense Processes'",
      "link": "http://www.jjan.kr/2118721"
      },
      {
      "agency": "ZDNet Korea",
      "createdAt": "June 24, 2021",
      "title": "Kongtech: 'Enter Apartments Smoothly with Just a Smartphone'",
      "link": "https://zdnet.co.kr/view/?no=20210624211558"
      },
      {
      "agency": "CCTV News",
      "createdAt": "June 24, 2021",
      "title": "Kongtech Releases 'Kongpass,' a Smartphone-based Apartment Access Security Solution",
      "link": "http://www.cctvnews.co.kr/news/articleView.html?idxno=228019"
      },
      {
      "agency": "Recruit Times",
      "createdAt": "May 27, 2021",
      "title": "Kongtech Collaborates on the Development of 'Non-face-to-face Collection and Disposal Robot for Medical Waste'",
      "link": "https://www.medipana.com/article/view.php?news_idx=276992&sch_menu=1&sch_gubun=5"
      },
      {
      "agency": "DataNet",
      "createdAt": "November 16, 2020",
      "title": "Kongtech Officially Supplies Real-time Location Tracking System 'Kongservice'",
      "link": "http://www.datanet.co.kr/news/articleView.html?idxno=152892"
      },
      {
      "agency": "Global Economic",
      "createdAt": "February 31, 2020",
      "title": "Kongtech, Provider of Attendance Management Solution, Updates 'Kongcheck 3.0' Reflecting Field Demand",
      "link": "https://news.g-enews.com/ko-kr/news/article/news_all/202002211426069349aca1a8c21a_1/article.html?md=20200221142829_U"
      },
      {
      "agency": "Simin Ilbo",
      "createdAt": "February 5, 2020",
      "title": "Kongtech Delivers 250,000 Beacons for Electronic Attendance in Kindergartens",
      "link": "http://www.siminilbo.co.kr/news/newsview.php?ncode=1065603668995186"
      },
      {
      "agency": "Sisa Magazine",
      "createdAt": "December 19, 2019",
      "title": "Kongtech Selected as a 'Family-friendly Certification' Company by the Ministry of Gender Equality and Family",
      "link": "https://www.sisamagazine.co.kr/news/articleView.html?idxno=307188"
      },
      {
      "agency": "Sisa News",
      "createdAt": "December 3, 2019",
      "title": "Kongcheck Wins '1st Place in Brand Satisfaction in the IT Sector' at the 2019 Korea Brand Satisfaction Awards",
      "link": "http://www.sisa-news.com/news/article.html?no=122374"
      },
      {
      "agency": "Magazine Hankyung",
      "createdAt": "November 26, 2019",
      "title": "Kongcheck, a Attendance Management System with Smart Technology",
      "link": "https://magazine.hankyung.com/business/article/201911262429b"
      },
      {
      "agency": "Sisa Magazine",
      "createdAt": "July 30, 2019",
      "title": "Kongtech Leads Beacon Solution Technology",
      "link": "https://www.sisamagazine.co.kr/news/articleView.html?idxno=215613"
      },
      {
      "agency": "Gonggam Shinmun",
      "createdAt": "December 19, 2018",
      "title": "Kongcheck, a Smart Attendance Management Program without Card Tags or Fingerprint Recognition",
      "link": "https://www.gokorea.kr/news/articleView.html?idxno=62896"
      },
      {
      "agency": "Dong-a.com",
      "createdAt": "July 27, 2018",
      "title": "Kongtech to Launch 'School Bus Safety Management System' Based on Beacon Technology in September",
      "link": "https://www.donga.com/news/article/all/20180727/91250851/2"
      },
      {
      "agency": "Energy Economy Newspaper",
      "createdAt": "April 21, 2017",
      "title": "Kongtech, a Samsung ARTIK Partner, Releases ARTIK5 Standard EVAL Development Board",
      "link": "https://www.ekn.kr/web/view.php?key=282853"
      },
      {
      "agency": "DataNet",
      "createdAt": "April 18, 2017",
      "title": "IT Company 'Kongtech' Relocates to Pangyo... Sign of Growth",
      "link": "http://www.datanet.co.kr/news/articleView.html?idxno=110048"
      }
      ]
    }
  }