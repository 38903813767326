import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

    
  helmet : '',
  desc : 'フォークリフトと作業場内のタグを使用して、ドライバーの位置をリアルタイムで追跡し、危険なゾーンに入ると警告を発生させ、事前に事故を防止します。さらに、このソリューションはドライバーの活動をモニタリングし、作業効率を向上させ、安全規則の遵守を強化します。',
  keywords : 'RTLS、フォークリフト、労働者の安全、労働安全、室内位置測位、位置測位、資産測位、労働安全、物流測位',
  
  ogTitle : '',
  ogDesc : '',
  ogSite : 'フォークリフト運転者の安全を改善するRTLSソリューション :: オブローブログ',
  ogUrl : 'https://orbro.io/jp/blog/forklift',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOYIuJxCZK1FJlYG0LyjYKrQCmcid1qnH-Lohcv1uYTGKb_GPVqY_SN8WE9jRoShLP0EX6b_VBXeTRzsO7mTk-z8QCbptyDW5Xe2QJeYxoTzMEDRgwOv8DvtKIbf4NcZZT_Zlplcg3Trlui87z3ElC0nCnI7pf1dZarBcSrb0LNt-ppIe2rFLvzS2ENcB9eWuw4z305W5GaBK0I-WjR0cY8MqBSBwIHoTY9di2xcTpbNtP65TVZDYzTDl7CQF157USeD7Tv9QhJl0LkphloREDPEYsqCkBmLCcGYAQju-OGqng3VUbIdyWaVOBofa0mNlwvO6D_amlwyTTiePAAysdHcsybGY8vPLjCQiidDTnfLYbE1UiI5Jl66uSVD0I6N3f9v7vospII2i5_YUcBaEEs4Uzb_F7XB5DIE3c7JrxIA_e6-nrn_YtHrMgid-5asfdU0L-Mu9fphtnVDL8fqDR7OZjb6-BISal_s9i8ORnx_iHMIcTCc9vXcP1Rq7C98YnMeMns2CqYZDaRVt-Pfmd_9ewR9v1hu0_YJG2TX-Je9BsZ0SXOx6fKHVjmWAppLp1kksJJxNxiIBWnSksv1hKhrn30d_QQmvYLDj-Jo88sbktqoX6Z-2TLIv3p2MSpCcGP7EwCnHLVPgc5ERRnv7zJkVHqh-07x-w5dq1R8qbdi7uRnG6yU3wAYTgilNH-USBwM2tL4FnMgAcZmQbSvFN61xcK3W1hGqOwfChKQ9aszr3gQdhYuf0zA-rBWbbuzB3mevQ5-MZPjI9p2tsS17nuFTROO8LR-WcMHO0bUmUfPbstaC4lgbyvZNrT2ZAMp9-4vQb8UzGs1SZTBzJFW8aqKfoKAvRIs9jMfZ0W7RDdjqTy6y4CYyQMyJXerwdeo8ODGTqvNux6rZeWraDDHjEhIJwHa68ntfE17VTeU9IhCi5Ax1xG5JKfDi89fGmyo0UGkByOqKXAUzQ0kb855-2_iUgo8bPO1rR4bdseYSRmP0A7VvhsrkW_cAe7shFm1dq5npgpxdlyqp7YSlii5O5nkSKPUTfBojpAN6nQQ31UtAIPu509dljo1GS1WzSIPWyw2LReT9-Cw6wR53NlceY4lcuqoVYfBHWxo7pz2gLr-0VfRlnJsTOa7u5skuZ6gxqycQuGkh1eWRDVwU9kPjERqK5HvRXQg2fmZgnb3ZLK8SAOpADOFjbSFGBizZAyNK5dP-73XpmaMWv60i64qqNS9zEEUtOzGyEhaAsBddF7caUjqcMq48beLtFcWzVl6nKMtZWS9v4GbJ79FqktHsqD2VNVKl5psGiqmamA97oR8Foggtisgpo_tXzUChfzvEPo4iLwNIS4e7cArRNt5MlE2L9My_ch8hwqmwWOjlLat1WgDZefk1f8TEzObNLoqW_fl0vZ2224rKOy8uRgK-smLBAJRvqxvrLdc8jqrYtsBmQbRW_5F9O38XaXXU86yJ6u4un2v2bZQs9lbD1U-Nj8419GHFhfQEyczZxSV2__aPX540BJW1T-CKQYO2-3UWpAuGLZnQuABSy6v5TAT3Zo6qgQgjs1CCnkpXLUnQZ_FCA=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
  
    
    title: 'RTLSソリューションによるフォークリフト運転者の安全性の向上',
    date : '2023-05-17',
    tag1 : '#RTLS',
    tag2 : '#労働者の安全',
    tag3 : '#資産管理',
    tag4 : '#位置測位',
    
    // <br/>
    text : [
      {
        head: 'フォークリフトの定期点検',
        subHead: '',
        desc: 'フォークリフトは、毎日の使用前に点検して正常に動作しているかを確認する必要があります。点検リストには、駐車ブレーキ、照明、油圧オイルレベルなどが含まれます。もし問題が見つかった場合、すべての欠陥を即座に修理する必要があります。少なくとも1か月に1度、資格を持った技術者によるより詳細な検査を行うことが望ましいです。<br/>ただし、人間が行う作業にはミスが発生することがあり、検査すべき機器が増えると時間とミスも増える傾向があります。<br/>RTLS技術を使用すると、フォークリフトや貨物機器に取り付けられたセンサーを使用して、機器の状態をリアルタイムで監視することができます。<br/>効率的な資産管理は安全性とコスト削減に直接貢献します。',
        dot: ''
        },
        {
        head: '接近警告システム',
        subHead: '',
        desc: 'フォークリフトは大きく、誰かか何かに衝突すると重大な損害を引き起こす可能性があります。<br/>そのため、運転者は常に周囲に注意を払い、人や障害物に注意することが重要です。<br/>もし彼らが道路上で誰かか何かを発見した場合、スピードを落とし、慎重に進む必要があります。<br/>特に活気のある倉庫では、これはより重要な問題です。<br/>このような場合、危険なエリアを視覚的に表示するためにサインを立てることはまだ良い方法です。<br/>ただし、作業員だけでなく一般の人々も、時折サインを見落としたり無視したりすることがあります。<br/>このような場合、接近警告システムを取り付けることも非常に良い方法です。フォークリフトの近くにいる人や物体を検知し、リアルタイムで警告措置を取ることができます。<br/>これにより、フォークリフトの運転者だけでなく管理者にもリアルタイムで監視され、管理者側の安全基準の遵守にも役立ちます。',
        dot: ''
        },
        {
        head: '適切な速度で操作する',
        subHead: '',
        desc: '速度が速すぎると事故のリスクが高まりますが、逆に遅すぎると生産性に直結するため、これも良い方法ではありません。<br/>安全かつ効率的にフォークリフトを操作する必要があります。ただし、作業に没頭していると運転者が速度制限を守れない場合もあります。そのような場合、RTLSシステムは自動的に速度遵守の判断を下し、警告することができます。',
        dot: ''
        },
        {
        head: '積載リスク',
        subHead: '',
        desc: '運転者は常に自分がいくつの重さを積んでいるかを把握することが重要です。負荷が重すぎるとフォークリフトが転倒する可能性があります。運転者は自分や他の人を危険にさらさない範囲で運搬する必要があります。同様に、フォークリフトは危険物を運搬するために絶対に使用してはいけません。このような物質は漏れや流出、火災が発生し、近くの作業員に重大な安全リスクを引き起こす可能性があります。<br/>RTLSシステムはこのような状況をモニタリングし、データを提供することで予測や対策が容易になります。',
        dot: ''
        },
        {
        head: '盲点の危険',
        subHead: '',
        desc: 'フォークリフトの盲点は通常、機械の直後の領域です。フォークリフトの経路に人や物体がある場合、予期しない怪我が発生する可能性があるため、これは重大な安全リスクです。<br/>盲点の危険にはいくつかの要因があります。1つは、フォークリフトが多量の貨物を頻繁に運搬するため、運転者の視界を妨げる可能性があるということです。もう1つは、明かりの入りにくいエリアでフォークリフトが運行される場合が多く、後方を見ることがはるかに困難であるということです。最後に、フォークリフトは頻繁に停止して出発することがあるが、これは盲点の事故の原因になる可能性があります。<br/><br/>人の能力では発生する可能性のある安全事故を、RTLS技術はスマートに予測できます。',
        dot: ''
        },
        {
        head: '資産管理',
        subHead: '',
        desc: 'RTLSソリューションは装置の管理にも効果的です！<br/>必要な装置がどこにあるかを一括でモニタリングでき、適切な装置配置に繋がるため、生産性向上にも大いに役立ちます。<br/>また、盗まれた装置や紛失した装置も位置測位が可能なので、作業中断などの状況を減らすことができます。<br/>RTLS技術はフォークリフト運転者と管理者の両方に対して、資産管理の効率性と安全性を保証することができます。',
        dot: ''
        }
    ],
  
    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc: 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/jp/enterprise/construction/asset'
        }, 
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/jp/enterprise/construction/safety'
        },
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/jp/enterprise/logistics/asset'
        }
      ]
  
    }
  
  
  }