import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

  helmet: '| What is RFID?',
  desc: 'RFID stands for "Radio-Frequency Identification," referring to wireless radio frequency identification technology used to uniquely identify and manage objects through wireless communication of data.',
  keywords: 'rfid, nfc, uwb, wireless communication, location tracking, object location tracking, people location tracking, tags, beacons, rtls, orbro, contech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'What is RFID? | Location Tracking and Digital Twin, Orbro',
  ogUrl: 'https://orbro.io/en/blog/rfid',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDnHsX6sdpDkHidg6Qw1BNPI-8KHNunrbQX70t-RRiZFc_m0TXoYRKa1E9Qo01-Au848tK9S5Jzx1vOsreK1FoM7_WUn-aUPsNq2SoW1p0bgotbqEic-B-shh4JqyX1vRGz1BKJuuNybUGEV0Sntx7H_5LpXctuKvQDlllya8Qrt86WPh2Z8-5f8fKO1zJH2O8i96n9h1SvE8MHibKQqZzHRsPx4JboT3-j7Dq6nMb3Gsk-bMZkqaQVCKMuKmSG9s28DFXjSr2dDlwKXTgw6HPGwcGijF3sdo52c8MtyGSFhBtgtmGbjidDLlTEoYy5sTjCUl9KoRk5SbaOqCb-WHfrMzvlUE-KzSkFspk4Qe_oDLl4U2mcOBOC6tpykKzDDNY3P-si0yNBW_3kHTZd8ITgkwKE1AwK0hHOdS0FB7_AvrfgQW1VAIpkjYlJFHXsbP3Nac0v-XjWu9W-fozT8vIJC3P5c5imd_plMzSJorI4po9xbiqNGReeiJpbzilK2ym2B2i8MZT6w2SRjdf2nm2SU4Ru4aZR60ahb8bagWe_xc_ZzKNmdlZu5Avvkyc3_fUGnnqw3d3xcwaPdtRhYQul_0LDKxsgKrBjv2Co6CztB1mX2Et8-F5j68r2F9qy1yj6abH08yQokpafvTmxUsBYZ_i4OAw30nPlQkXflKfWFTob2y_o-X_FvVTGl4qerYTVBQLafVq7aSdlwkNpTvzZbXYkJMJasDN8JRO6mBJ8WhoJm9GOF5z75doXv-ds7jiNur5KLdA_UC2SR6-GpDrQPew6gUqn_1NvNGCXxkH9skkPX6gIuzc-9EAaXkSNajXWEc9_cRppxNODr4m1x_rl00fnHmVra7BhMXTjEgsB7rUJJw9OdKbTvB565TUXJXGMIfiFUAJfYBgm3FRZfJobff73T4LEa7d6e-znqcf64EINs_qnz2n-6yqf6_8Ww7A3P2K_iKCJymzKYy-dmjnxIW7c_msNP3sQZ0Ev0CpMW2neef16CpS2qPJjSc1Hv977oz4JSkrCL2GJ3RIUILnczDL8Ww96raGuIs058uNZu-pJwbIEbr9995dIPiHY-a6wstUW8xKthzZmdCFJ2JInX_Mos6jxXNorixlMOiseVcCXzuEcH_QEXGn9Mj7GzKkhAwgYeEq3D7EMlwjQ56-8mACmRW1ein59VVTW8JoXlBZ0enRBVmONwC4Y0aUUc_Hn70Zbktx4y1pKsehMCWy3GnGikZej6coDNKuKPnv8VE2ZBfCy8QdoI0i4P9rH2zFPqJWJEEgYmy3MpmnS5Asf8f1aScgjVS1S06wmBN_9Lpa-3cUJ-epljLU28BaHQWk9f2z4XojkWBWXVnGCidPhbxH-Cx4l-mNjXL8bh9PixgnFknFkWbwaqqGfjgS-GvOPqF0uGlIlEavfeovi1d_1opq7fxuM6wc-y17eImZEycGDKQuMRds6xvylNau1IgJTWCxWfnHArzBoJMxU91IlULuYkMFPAmoA-U2JjLAeiz35GX1eHmMQ3k_2JZmlfIvGQ73K2zf9ErNr-QzQPqixYph1iRaJi=w1984-h1214',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'What is RFID?',
  date: '2024-01-11',
  tag1: '#rfid',
  tag2: '#rtls',
  tag3: '#wirelesscommunication',
  tag4: '#locationtracking',

  // <br/>
  text: [
    {
      head: 'What is RFID?',
      subHead: '',
      desc: 'RFID stands for "Radio-Frequency Identification," referring to wireless radio frequency identification technology used to uniquely identify and manage objects through wireless communication of data.<br/>RFID systems typically consist of tags, readers (or interrogators), and a database. The key component of RFID systems, the tag, is composed of a small chip and an antenna. This chip is attached or embedded in objects, and the antenna is responsible for the transmission and reception of wireless signals. The reader communicates with the tag through the antenna, reads or writes data, and connects this information to the database for real-time tracking or management.',
      dot: ''
    },
    {
      head: 'Use Cases of RFID',
      subHead: '',
      desc: 'RFID technology is widely utilized in various industries. Below are some key use cases of RFID.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Distribution and Logistics Management',
      desc: '',
      dot: {
        a : '<strong> • Inventory Management: </strong>Using RFID to attach tags to products and track inventory in real-time through readers, providing accurate inventory information.',
        b : '<strong> • Automated Logistics Processing: </strong>Automating the transportation and sorting of products in logistics centers to increase the efficiency of logistics operations.',
      }
    },
    {
      head: '',
      subHead: 'Manufacturing',
      desc: '',
      dot: {
        a : '<strong> • Product Tracking and Production Management: </strong>Manufacturers attach RFID tags to products to track production lines and perform real-time monitoring during the manufacturing and shipping stages.',
      }
    },
    {
      head: '',
      subHead: 'Retail',
      desc: '',
      dot: {
        a : '<strong> • Intelligent Stores: </strong>Using RFID technology to implement intelligent stores that automatically generate invoices and payments when customers take products directly.',
        b : '<strong> • Enhancing Customer Experience: </strong>Utilizing RFID to make it easy for customers to find products or automatically applying discounts and promotions to enhance the customer experience.',
      }
    },
    {
      head: '',
      subHead: 'Medical and Hospital Management',
      desc: '',
      dot: {
        a : '<strong> • Patient Identification and Medical Device Management: </strong>Using RFID to accurately identify patients and monitor the location and status of medical devices to improve the efficiency of medical institutions.',
        b : '<strong> • Medication Tracking: </strong>Enhancing the traceability of medications from manufacturing to patient delivery to ensure safety.',
      }
    },
    {
      head: '',
      subHead: 'Vehicles and Transportation Systems',
      desc: '',
      dot: {
        a : '<strong> • Automatic Toll Collection: </strong>Using RFID to automatically impose tolls and track vehicles to reduce traffic congestion.',
        b : '<strong> • Vehicle Management and Maintenance: </strong>Attaching RFID tags to vehicles to track maintenance history and monitor the location of vehicles for efficient operation.',
      }
    },
    {
      head: 'Advantages of RFID',
      subHead: 'Automation and Real-time Tracking',
      desc: 'RFID quickly identifies and collects data from objects wirelessly, operating at a faster speed compared to manual processes. Additionally, RFID enables real-time tracking of the location and status of objects, allowing for efficient performance in logistics, production, and inventory management.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Accurate Data Collection',
      desc: 'RFID enables faster and more accurate data collection compared to barcode scanning, reducing data input errors. RFID can identify objects automatically even in situations without visual visibility.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Vision and Visibility Enhancement',
      desc: 'Utilizing RFID allows transparent tracking of object movement throughout the entire supply chain, enhancing visibility. Using RFID in production lines or warehouses enables real-time monitoring of work status.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Security Enhancement',
      desc: 'RFID detects unauthorized movement of objects and can be applied to secure areas to prevent unauthorized access. RFID can grant individual permissions to tags, allowing only specific users or groups to access.',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that attaches UWB terminals to workers or equipment, helping with real-time location tracking.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/en/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/en/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/en/enterprise/office/asset'
      }
    ]

  }

}
