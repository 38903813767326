import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4';

export default {

    desc: 'スマートポートはデジタルツイン、RTLS、人工知能を活用して、港内のすべての活動をリアルタイムで監視および管理する包括的なソリューションです。これにより、港内のすべての物体やプロセスをリアルタイムでモニタリングおよび分析することが可能です。',
    keywords: 'スマートポート、デジタルツイン、デジタル変革、digital twin、ビーコン、rtls、uwb、ble、aiカメラ、Orbro、Kongtek',

    ogSite: 'スマートポートソリューション | 位置追跡とデジタルツイン、Orbro',
    ogUrl: 'https://orbro.io/jp/blog/smart-port',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmtMlMpuGaSkdrwhUm9cmk57NhnjuSqUyvAfLNAvWgGu0MM4L2R6eFvjBYvTVkkIk2f8QbkrMlttEBDfzi4pLKN9N-dhDu_JiYkV4BvDu2CLF5JFWrrMJgKJAPWObqYGbQYtBBMoquCCAYx_k8njELRIPt4_PKkk6KdhaLKf78OatyVEbgXKhqBqdCGxbhGw3jTDiqdbaImqLAMR3Wdos-IWpYXZYClx-DKC1P9dB3GLBP0ixLsNn9rpaH91rcH8RLcRUDTYzamMx1BMmNl89ZY8vTfts7eomL8NXjR9nEZZ5K-MkBjZT4KJ4o4iUQRE8CZDBT8xcueZCDOkMPkM1K3XgnM7okDR8U8xf_zJc0w6Ng2bEtDNjtB1h-vn2NNuBWuiIXgDTJG-ZlSbIFXuT1ySTt8d8L9s9vZffcfMLrKFRnY8Obtfn-WVUDAaXA68xwLtGJYP-NMEMyCqtdlswxY1nzpPdBpNTELVjP4NGkFiHk3Vb0xHr22sMgIzsoWUBuuZRiiz_Oadq3DWG64cWpzTeUzRxxnVc7lh1jqQCre0y1-PNTz8FmoHgjoNYQIGR8teD7k3s2g9v6_39LkKVnEp3LZg4K7fK_st9aGnXxXHgT38G1P_CyZ2NCfDR2h7757hijJWyc01v1e5DKey7CKzS5XKyz6VQitW_E20TWpbzbIO-rYeEzjZwRCbjcBhUVfQiSz2QcMbLTb3acfrCop4bSDap1c2tjNFn6BURfcFhtqYymgRGU1UCQ4ZuD_RO552LTgZgcyVENTq2v3A1n1qzhsNoSFHD5hJ2RA2ww4so8MDKBF2R5J6mer8Su6fEutD5F1ZUgGwSU4glTVu0jYCkS97SgUsjSJ-hWu10P40b1PbPIYjir7h7BTyDphRhnPDkigpYw4x2NeLO1ilNqDXxOLoRxOz3XM0ubgAHxvtFSl6urWE-MXOjXwCuu_8dCODgOBpzlHm_HA1FIREfuLH-sVZ9VKFvyFZZyxx4x12i7Gf8wfTFh20JL2uxFSplfv-8nvkqRaVPYrF5RGZ3jSwtPJP9OYAMhxVpgB1unGLMYL2-a0_s-q8lfbijvufnfa1O6P0UiqgkFwZ0HKLgLXHOD3DSaHMMSJkB9oSwFNFJgoIAciFzzPWPCw4Hs1wMO5q4ixJ1Jbvyu53pWL_tznPM5m5mIj1D_i2RMKAoNFDl4EMFRzXjMKHUWjTc2bt9knvzg3CUTzs7rDarMVqv2ELwEc2BJE5joJfnY7APOre8u84naPSATFCFeKvGxt0v6mruuozNIF4IZMC2XhhGDHbQasJe1Vfab9-e18wVVD6fyEM0jLw50EPpOh5rTUJE02ppq_cHwPe2ZUQ10K7oC0LZoy05ga5pL6tfHnt65GWSUg6ehpUe7RkFH0vOMvEPWln4Zl3XiUjnmVQ_SAN-gWxtOswmhrO4QavIJmTUrR9UmDxFLOAjeUDHPsYc0XYFpmyLYyiAuDr095MknMbE1K0WjCf9kfZy_O14qr-6UpIl7J8vTNKxXQX1RKB9t7N_rO0S0Q1UgEj02peJ30KvmNifvU3gDY1huNYAcxzOUSC0kfxpI=w2110-h1171',
    ogType: 'website',

    title: 'スマートポートソリューション',
    date: '2024-01-25',
    tag1: '#スマートポート',
    tag2: '#RTLS',
    tag3: '#位置追跡',
    tag4: '#デジタルツイン',

    // <br/>
    text: [
        {
            head: 'スマートポートとは？',
            subHead: '',
            desc: 'スマートポートはデジタルツイン、RTLS、人工知能を活用して、港内のすべての活動をリアルタイムで監視および管理する包括的なソリューションです。これにより、港内のすべての物体やプロセスをリアルタイムでモニタリングおよび分析することが可能です。コンテナの位置、状態、および輸送情報などの重要なデータを収集および更新し、港管理者が迅速な意思決定を行えるよう支援します。<br/>このブログでは、スマートポートソリューションの概念、利点、およびデジタルツインとRTLS技術の中心に焦点を当てて探ります。',
            dot: ''
        },
        {
            head: 'スマートポート技術',
            subHead: 'デジタルツイン',
            desc: 'デジタルツインは、物理的なシステム、構造物、またはプロセスを正確に複製する仮想モデルを生成する技術です。主に港の施設と貨物輸送プロセスのデジタル複製を生成し、リアルタイムでモニタリングおよび最適化します。そして、コンテナの位置、クレーンの動作、車両の移動経路などをデジタルツインモデルで表現し、問題を事前に予測し、効率的な運用を可能にします。',
            dot: ''
        },
        {
            head: '',
            subHead: 'RTLS（リアルタイムロケーションシステム）',
            desc: 'RTLSはリアルタイムロケーションシステムの略で、物体や移動物体のリアルタイムの位置を正確に追跡する技術です。主にUWB、RFIDタグ、カメラ、ビーコン技術などを活用して正確な位置情報を収集および管理します。これにより、コンテナ、トラック、船舶の位置を追跡し、貨物輸送経路の最適化、貨物の状態モニタリング、および安全性向上に貢献します。',
            dot: ''
        },
        {
            head: '',
            subHead: '人工知能（AI）',
            desc: 'AI技術はCCTVカメラを使用して港内の異常なしるしや危険な状況を識別します。これは、港で発生するさまざまなデータを収集し、分析して予測モデルを開発することで、物流および輸送の効率を向上させる可能性があります。また、人工知能を使用して港の運用中の問題を迅速に検出および解決できます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'IoT',
            desc: 'センサーおよび通信技術を活用して港内のさまざまなシステムと機器を接続します。また、温度や湿度などの現場の環境全般を把握し、管理に必要なデータを収集します。これにより、港の運用をリアルタイムで最適化できます。',
            dot: ''
        },
        {
            video: video,
            link: '/jp/enterprise/manufacturing/process',
            head: "スマートポートの機能",
            subHead: "リアルタイム在庫管理",
            desc: "スマートポートはセンサー技術と無線通信技術を活用して貨物のリアルタイムの位置を追跡できます。これにより、港の管理者はいつでも特定の貨物の位置を把握し、物流プロセスを効率的に管理できます。貨物の追跡は正確な到着予定時刻（ETA）の計画を可能にし、物流計画の効率性と正確性を向上させます。",
            dot: ""
        },
        {
            head: "",
            subHead: "デジタルツインを活用した運用の最適化",
            desc: "デジタルツインは、港の施設や輸送手段をデジタル的にモデリングし、リアルタイムで管理する技術です。港内の施設の状態をリアルタイムで監視し、予測分析を通じて保守スケジュールを最適化し、物流および輸送プロセスを効率的に運用できます。デジタルツインを活用した運用の最適化は、港の施設の寿命サイクルを延ばし、施設管理の効率性を向上させます。",
            dot: ""
        },
        {
            head: "",
            subHead: "自律走行車両およびロボットの活用",
            desc: "スマートポートは自律走行車両とロボットを通じて貨物の輸送や特定の作業を自動化します。自律走行車両は貨物を効率的に移動させ、ロボットは必要な作業を自動で実行して労働力を削減し、生産性を向上させます。これにより、港内の貨物移動および作業プロセスが自動化され、より高い効率性が実現されます。",
            dot: ""
        },
        {
            head: "",
            subHead: "コスト削減と効率的なリソース管理",
            desc: "スマートポートは物流および輸送プロセスの自動化と効率化を通じてコスト削減と効率的なリソース管理を実現します。自律走行車両とロボットの導入は人件費を削減し、同時に生産性を向上させることができます。また、デジタルツインを通じた施設管理の最適化は保守コストを削減し、エネルギー効率を向上させます。",
            dot: ""
        },
        {
            head: "",
            subHead: "リアルタイムの安全およびセキュリティモニタリング",
            desc: "RTLSやCCTVなどの技術を活用して、スマートポートは港内の輸送手段や人員のリアルタイムの位置をモニタリングし、異常の兆候を検知します。これにより、不正なアクセスや安全上の問題に迅速に対応して、港の安全性とセキュリティを強化します。リアルタイムの監視は事故発生時に素早い対応を可能にし、港の施設および人員の安全性を向上させます。",
            dot: ""
        },
        {
            head: "",
            subHead: "データ駆動型意思決定",
            desc: "ビッグデータおよび人工知能技術を使用して港で生成されるさまざまなデータを収集し、分析し、予測モデルを構築します。これにより、港の管理者は環境の変化にリアルタイムで対応し、データ駆動型の意思決定を通じて運用効率を最大化できます。また、データ分析により物流プロセスのボトルネックを特定し、全体的な生産性を向上させます。",
            dot: ""
        },
        {
            head: "",
            subHead: "環境にやさしい運用とエネルギー効率",
            desc: "スマートポートは貨物輸送および港の施設管理において環境にやさしい運用を目指しています。物流および輸送プロセスの最適化により、エコフレンドリーな輸送手段を利用して燃料消費を最小化し、炭素排出を削減します。さらに、デジタルツインとセンサーを通じた施設管理はエネルギー消費を最適化し、港のエネルギー効率を向上させます。",
            dot: ""
        },
        {
            head: 'スマートポート展望',
            subHead: '',
            desc: 'スマートポートの展望は将来さらなる革新的な発展を達成することが期待されています。自動運転技術の拡大は、物流および輸送プロセスを自動化し効率化することで生産性を向上させると予測されています。デジタルツインとビッグデータ分析を通じた運用の最適化は、港の施設の保守および運用スケジュールを効果的に管理します。環境にやさしい運用とエネルギー効率への強調は、エコフレンドリーな技術の導入によりエネルギー消費を最小限に抑える持続可能な港運用を強調しています。また、リアルタイムの安全およびセキュリティモニタリングシステムの進化により、港の安全性が向上し、不正アクセスへの対応能力が強化されます。スマートポートは物流業界と国際貿易にさらなる利便性と効率性を提供し、デジタル化と革新のハブとしての地位を確立することが予測されています。',
            dot: ''
        }
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB端末を労働者や設備に取り付けてリアルタイムの位置追跡を支援する製品。',
                img: productImg.product.workerTracking.thumbnail
            },
            {
    
            }
        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/jp/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/jp/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/jp/nterprise/construction/equipment'
            }
        ]
    }
    
}
