import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  StyledIconWrapper, SliderButtonLayout
} from './BannerScroll.style';

import { IconChevronRightBlack } from '@assets/images/icon';
import access from '@src/lang/ko/main/access/access';
import rtls from '@src/lang/ko/main/rtls/rtls';
import camera from '@src/lang/ko/main/camera/camera';
import iot from '@src/lang/ko/main/iot/iot';
import sensor from '@src/lang/ko/main/sensor/sensor';
import beacon from '@src/lang/ko/main/beacon/beacon';
import integration from '@src/lang/ko/main/integration/integration';

import accessEng from '@src/lang/en/main/access/access';
import rtlsEng from '@src/lang/en/main/rtls/rtls';
import cameraEng from '@src/lang/en/main/camera/camera';
import iotEng from '@src/lang/en/main/iot/iot';
import sensorEng from '@src/lang/en/main/sensor/sensor'
import beaconEng from '@src/lang/en/main/beacon/beacon'
import integrationEng from '@src/lang/en/main/integration/integration';

import accessJP from '@src/lang/jp/main/access/access';
import rtlsJP from '@src/lang/jp/main/rtls/rtls';
import cameraJP from '@src/lang/jp/main/camera/camera';
import iotJP from '@src/lang/jp/main/iot/iot';
import sensorJP from '@src/lang/jp/main/sensor/sensor';
import beaconJP from '@src/lang/jp/main/beacon/beacon';
import integrationJP from '@src/lang/jp/main/integration/integration';

import accessCN from '@src/lang/cn/main/access/access';
import rtlsCN from '@src/lang/cn/main/rtls/rtls';
import cameraCN from '@src/lang/cn/main/camera/camera';
import iotCN from '@src/lang/cn/main/iot/iot';
import sensorCN from '@src/lang/cn/main/sensor/sensor';
import beaconCN from '@src/lang/cn/main/beacon/beacon';
import integrationCN from '@src/lang/cn/main/integration/integration';


import { Link, useLocation } from 'react-router-dom';
import i18n from '@pages/i18n';
import { ImgScale, SliderButton, SliderLayout, StyledAppPage, StyledIcon, StyledIconContainer, StyledIconText, StyledIconTextSub } from './Banner.style';
import { useDispatch } from 'react-redux';
import { setOsType } from '@redux/action-creators/common';

const Banner = ({ selectedGroup, product }): React.ReactElement => {


  // 스크롤 컨테이너의 ref를 생성합니다.
  const containerRef = useRef<HTMLDivElement>(null);
  const currentPage = useRef<HTMLDivElement>(null);

  const [currentImage, setCurrentImage] = useState(0);

  let currentLanguage;


  const location = useLocation();

  const isEnglishPage = location.pathname.startsWith('/en');
  const isJapanPage = location.pathname.startsWith('/jp');
  const isChinaPage = location.pathname.startsWith('/cn');
  const currentLang = i18n.language;

  useEffect(() => {

    if (isEnglishPage && currentLang !== 'en') {
      i18n.changeLanguage('en').then(() => {
      });
    } else if (isJapanPage && currentLang !== 'jp') {
      i18n.changeLanguage('jp').then(() => {

      });
    } else if (isChinaPage && currentLang !== 'cn') {
      i18n.changeLanguage('cn').then(() => {

      });
    } else {
      i18n.changeLanguage('ko').then(() => {
      });
    }

  }, [location.pathname]);


  let dataPath;
  let productPath;

  if (location.pathname.includes('/en/')) {
    currentLanguage = 'en';

    if (product === 'access') {
      productPath = accessEng;
      dataPath = product + 'Main';
    } else if (product === 'rtls') {
      productPath = rtlsEng;
      dataPath = product;
    } else if (product === 'camera') {
      productPath = cameraEng;
      dataPath = product;
    } else if (product === 'iot') {
      productPath = iotEng;
      dataPath = product;
    } else if (product === 'sensor') {
      productPath = sensorEng;
      dataPath = product;
    } else if (product === 'beacon') {
      productPath = beaconEng;
      dataPath = product;
    } else if (product === 'integration') {
      productPath = integrationEng;
      dataPath = product;
    }

  } else if (location.pathname.includes('/jp/')) {
    currentLanguage = 'jp';

    if (product === 'access') {
      productPath = accessJP;
      dataPath = product + 'Main';
    } else if (product === 'rtls') {
      productPath = rtlsJP;
      dataPath = product;
    } else if (product === 'camera') {
      productPath = cameraJP;
      dataPath = product;
    } else if (product === 'iot') {
      productPath = iotJP;
      dataPath = product;
    } else if (product === 'sensor') {
      productPath = sensorJP;
      dataPath = product;
    } else if (product === 'beacon') {
      productPath = beaconJP;
      dataPath = product;
    } else if (product === 'integration') {
      productPath = integrationJP;
      dataPath = product;
    }

  } else if (location.pathname.includes('/cn/')) {
    currentLanguage = 'cn';

    if (product === 'access') {
      productPath = accessCN;
      dataPath = product + 'Main';
    } else if (product === 'rtls') {
      productPath = rtlsCN;
      dataPath = product;
    } else if (product === 'camera') {
      productPath = cameraCN;
      dataPath = product;
    } else if (product === 'iot') {
      productPath = iotCN;
      dataPath = product;
    } else if (product === 'sensor') {
      productPath = sensorCN;
      dataPath = product;
    } else if (product === 'beacon') {
      productPath = beaconCN;
      dataPath = product;
    } else if (product === 'integration') {
      productPath = integrationCN;
      dataPath = product;
    }

  } else {
    currentLanguage = 'ko';

    if (product === 'access') {
      productPath = access;
      dataPath = product + 'Main';
    } else if (product === 'rtls') {
      productPath = rtls;
      dataPath = product;
    } else if (product === 'camera') {
      productPath = camera;
      dataPath = product;
    } else if (product === 'iot') {
      productPath = iot;
      dataPath = product;
    } else if (product === 'sensor') {
      productPath = sensor;
      dataPath = product;
    } else if (product === 'beacon') {
      productPath = beacon;
      dataPath = product;
    } else if (product === 'integration') {
      productPath = integration;
      dataPath = product;
    }

  }



  const imageIndex = productPath.bannerData.length;


  const goLeft = () => {
    const container = containerRef.current;
    if (container) {
      const newScrollLeft = container.scrollLeft - moveLeft;
      smoothScroll(container, container.scrollLeft, newScrollLeft);
    }
  };

  const goRight = () => {
    const container = containerRef.current;
    if (container) {
      const newScrollLeft = container.scrollLeft + moveRight;
      smoothScroll(container, container.scrollLeft, newScrollLeft);
    }
  };
  const smoothScroll = (element, start, end, duration = 300) => {
    const range = end - start;
    let current = start;
    const increment = 20;
    const step = range / (duration / increment);
    const animateScroll = () => {
      current += step;
      if ((step > 0 && current >= end) || (step < 0 && current <= end)) {
        element.scrollTo({
          left: end,
          behavior: 'smooth',
        });
        return;
      }
      element.scrollTo({
        left: current,
        behavior: 'auto',
      });
      requestAnimationFrame(animateScroll);
    };
    animateScroll();
  };


  function isLastIconVisible() {
    const containerWidth = layoutMaxWidth - 80; // 컨테이너의 실제 너비 계산 (80px은 버튼의 너비)
    const visibleIcons = Math.floor(containerWidth / 96); // 아이콘 하나의 너비는 80px + 16px 마진

    const itemCount = visibleWidth > 96 * visibleIcons + 80 ? visibleIcons + 1 : visibleIcons;

    return imageIndex === itemCount + currentImage;
  }


  useEffect(() => {
    const handleResize = () => {
      setLayoutMaxWidth(window.innerWidth);
    }

    setLayoutMaxWidth(document.documentElement.clientWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [layoutMaxWidth, setLayoutMaxWidth] = useState<any>(null);


  const itemWidth = 80; // 아이템의 너비 계산
  const layoutWidth = itemWidth * imageIndex + 16 * (imageIndex - 1); // 레이아웃의 너비 계산
  const isContainerFullWidth = layoutMaxWidth < layoutWidth + 40;

  ////현재 보여지는 넓이
  const visibleWidth = layoutMaxWidth - 80;

  const [scrollRight, setScrollRight] = useState(true);
  const [scrollLeft, setScrollLeft] = useState(false);

  const [moveLeft, setMoveLeft] = useState(0);
  const [moveRight, setMoveRight] = useState(96);

  // 스크롤 컨테이너의 scroll 이벤트를 처리합니다.
  const handleScroll = () => {
    const container = containerRef.current;
    const pageSize = currentPage.current;

    if (container && pageSize) {
      const scrollLeft = container.scrollLeft;
      const scrollRight = container.scrollWidth - (pageSize.scrollWidth - 92 + scrollLeft);
      setMoveLeft(scrollLeft < 80 ? scrollLeft : 96);
      setMoveRight(scrollRight < 80 ? scrollRight : 96);

      if (container.clientWidth >= container.scrollWidth - container.scrollLeft) {
        setScrollRight(false);
      } else {
        setScrollRight(true);
      }

      if (container.scrollLeft === 0) {
        setScrollLeft(false);
      } else {
        setScrollLeft(true);
      }
    }
  };

  // 스크롤 컨테이너에 scroll 이벤트 핸들러를 등록합니다.
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);


  const dispatch = useDispatch();
  const handleOsType = () => {
    if (location.pathname.includes('beacon')) {
      localStorage.setItem('selectedOs', `beacon`);
    } else if (location.pathname.includes('rtls')) {
      localStorage.setItem('selectedOs', `rtls`);
    } else if (location.pathname.includes('camera')) {
      localStorage.setItem('selectedOs', `camera`);
    } else if (location.pathname.includes('access')) {
      localStorage.setItem('selectedOs', `access`);
    } else if (location.pathname.includes('sensor')) {
      localStorage.setItem('selectedOs', `sensor`);
    } else if (location.pathname.includes('integration')) {
      localStorage.setItem('selectedOs', 'integration');
    } else {
    }
  }


  return (
    <StyledAppPage ref={currentPage}>
      <StyledIconContainer ref={containerRef} w={isContainerFullWidth ? layoutMaxWidth - 80 : layoutWidth} mw={layoutMaxWidth}>
        <SliderLayout>
          {productPath.bannerData.map((data, index) => (
            <Link to={selectedGroup === index ? '' : `${data.link}`} key={index}>
              <StyledIconWrapper selected={selectedGroup === index}
                onClick={() => { handleOsType(); }}>
                <StyledIcon
                  src={selectedGroup === index ? data.icon : data.iconInv} alt='ORBRO RTLS Product Icon'
                />
                {selectedGroup === index ?

                  <StyledIconText size='xsmall' color='#2A70F0'>
                    <Trans i18nKey={productPath.bannerData[index].iconText} />
                  </StyledIconText>
                  :
                  <StyledIconText size='xsmall' color='#646464'>
                    <Trans i18nKey={productPath.bannerData[index].iconText} />
                  </StyledIconText>
                }


                {data.sub === "New" &&

                  <StyledIconTextSub size='xsmall'>
                    <Trans i18nKey={data.sub} />
                  </StyledIconTextSub>
                }
              </StyledIconWrapper>
            </Link>
          ))}
        </SliderLayout>
      </StyledIconContainer>


      {isContainerFullWidth &&
        <SliderButtonLayout>

          {(currentImage !== 0 || scrollLeft) &&
            <SliderButton onClick={goLeft} direction={true} bgcolor={location.pathname.includes('/os') ? '#F8F8F8' : '#FFF'}>
              <ImgScale src={IconChevronRightBlack} w={'16'} h={'16'} trans={true} alt='button icon' />
            </SliderButton>
          }

          {(!isLastIconVisible() && scrollRight) &&
            <SliderButton onClick={goRight} direction={false} bgcolor={location.pathname.includes('/os') ? '#F8F8F8' : '#FFF'}>
              <ImgScale src={IconChevronRightBlack} w={'16'} h={'16'} trans={false} alt='button icon' />
            </SliderButton>
          }

        </SliderButtonLayout>
      }

    </StyledAppPage>
  );
};

export default Banner;
