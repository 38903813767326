import {
    functionImg1D, functionImg1M, functionImg1T,
    functionImg2D, functionImg2M, functionImg2T,
    functionImg3D, functionImg3M, functionImg3T,
    functionImg4D, functionImg4M, functionImg4T,
    functionImg5D, functionImg5M, functionImg5T,
    functionImg6D, functionImg6M, functionImg6T,
    functionImg7D, functionImg7M, functionImg7T,
} from "@assets/images/main/access/components/index";

export default {

    functions: {

        title: '使いやすいソフトウェア<br/>誰でもアクセス可能',
        desc: 'Orbroは8年間のアクセス管理の経験に基づいてソフトウェアを開発しました。ユーザーフィードバックと要件を考慮して設計され、フィールドでの利便性を最大限に引き出します。これは実際のユーザーのニーズに応えて開発された製品で、アクセス管理において実際の利点を提供します。',
        line: 3,
        card: [
            {
                size: '4',
                tag: 'ユーザー登録',
                title: '電話番号またはメールでユーザーを招待',
                label: '管理者',
                img: {
                    desktop: functionImg1D,
                    tablet: functionImg1T,
                    mobile: functionImg1M
                },
            },
            {
                size: '4',
                tag: 'アクセス権の管理',
                title: '簡単なアクセス権の管理',
                label: '管理者',
                desc: 'アクセス可能なデバイスを登録するだけで、アクセス権を作成できます。',
                img: {
                    desktop: functionImg2D,
                    tablet: functionImg2T,
                    mobile: functionImg2M
                },
            },
            {
                size: '2',
                tag: 'Excelダウンロード',
                title: 'アクセス履歴とユーザー情報をダウンロード',
                label: '管理者',
                img: {
                    desktop: functionImg3D,
                    tablet: functionImg3T,
                    mobile: functionImg3M
                },
            },
            {
                size: '2',
                tag: 'リモートコントロール',
                title: 'リモートでアクセスドアを制御',
                label: '管理者',
                img: {
                    desktop: functionImg4D,
                    tablet: functionImg4T,
                    mobile: functionImg4M
                },
            },
            {
                size: '2',
                tag: '自動エントリー',
                title: 'アプリを開かなくてもアクセス可能',
                label: 'ユーザー',
                img: {
                    desktop: functionImg5D,
                    tablet: functionImg5T,
                    mobile: functionImg5M
                },
            },
            {
                size: '4',
                tag: 'モバイルウィジェット',
                title: 'クイックアプリ起動用のウィジェット提供',
                label: 'ユーザー',
                img: {
                    desktop: functionImg6D,
                    tablet: functionImg6T,
                    mobile: functionImg6M
                },
            },
            {
                size: '4',
                tag: '自動エントリー',
                title: 'ドアの開閉距離を簡単に設定',
                label: 'ユーザー',
                img: {
                    desktop: functionImg7D,
                    tablet: functionImg7T,
                    mobile: functionImg7M
                },
            },
        ]
    },

}
