import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { StyledTechTableColumn, StyledTechTableHeader, StyledTechTableHeaderText, StyledTechTableRow } from "./TechTable.style";
import technology from "@src/lang/ko/technology/technology";
import { LabelColor } from "@pages/CommonStyle/common.style";
type ColumnType = 'uwb' | 'ble' | 'wifi';

interface TechTableProps {
  columns: ColumnType[]
}

const TechTable = (props: TechTableProps) => {
  const { columns } = props;
  
  const tableData = useMemo(() => technology.table,[]);
  const types = useMemo(() => ['name', ...columns], [columns]);

  console.log(types);
  return (
    <div>
      <StyledTechTableHeader>
        { types.map(type => (
          <StyledTechTableColumn key={`tech-table-header-item-${type}`}>
            <StyledTechTableHeaderText size="xxxsmall"><Trans i18nKey={ technology.table.header[type] }/></StyledTechTableHeaderText>
          </StyledTechTableColumn>
        )) }
      </StyledTechTableHeader>
      
      
      { Object.keys(tableData).filter(key => key != 'header').map(key => (
        <StyledTechTableRow key={`tech-table-row-${key}`}>
          { Object.keys(tableData[key]).map((data, index) => (
            <StyledTechTableColumn key={`tech-table-row-${key}-${index}`}>
              <LabelColor size="medium" color="#646464">
                <Trans i18nKey={`technology.table.${key}.${types[index]}`} />
              </LabelColor>
            </StyledTechTableColumn>
          )) }
        </StyledTechTableRow>
      )) }
      
    </div>
  );
};

export default TechTable;