import { TypeMap } from '@core/util';
import styled from '@emotion/styled';
import { CommonText, CommonTextProps } from '../CommonText.style';

type SizeTypes = 'large' | 'medium' | 'small' | 'xsmall';
interface StyledLabelProps extends CommonTextProps {
  size: SizeTypes;
}
interface StyledLabelColorProps extends CommonTextProps {
  size: SizeTypes;
  color : string;
}

type LabelData = {
  size: string,
  height: string,
}

const sizeMap: TypeMap<SizeTypes, LabelData> = {
  large: { size: '18px', height: '1.33' },
  medium: { size: '16px', height: '1.25' },
  small: { size: '14px', height: '1.14' },
  xsmall: { size: '12px', height: '1.33' },
};

export const StyledLabel = styled(CommonText)<StyledLabelProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  line-height: ${({ size }) => sizeMap[size].height};
`;


export const LabelH2 = styled.h2<StyledLabelProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  line-height: ${({ size }) => sizeMap[size].height};
  font-weight : 500;
`

export const LabelH2color = styled.h2<StyledLabelColorProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  line-height: ${({ size }) => sizeMap[size].height};
  font-weight : 500;
  color: ${({ color }) => color};
`

export const LabelH3 = styled.h3<StyledLabelProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  line-height: ${({ size }) => sizeMap[size].height};
  font-weight : 500;
`

export const LabelH3color = styled.h3<StyledLabelColorProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  line-height: ${({ size }) => sizeMap[size].height};
  font-weight : 500;
  color: ${({ color }) => color};
`

export const LabelH3_818181 = styled.h3<StyledLabelProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  line-height: ${({ size }) => sizeMap[size].height};
  color : #818181;
  font-weight : 500;
`