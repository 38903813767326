export default {
  table: {
    header: {
      name: '',
      uwb: 'UWB',
      ble: 'BLE',
      wifi: 'WI-FI'
    },
    accuracy: {
      name: '正確度',
      uwb: '10-50cm',
      ble: '5m未満',
      wifi: '10m未満'
    },
    optimumRange: {
      name: '最適範囲',
      uwb: '10-50cm',
      ble: '0-25m',
      wifi: '0-50m'
    },
    maximumRange: {
      name: '最大範囲',
      uwb: '200m',
      ble: '100m',
      wifi: '500m'
    },
    latency: {
      name: '遅延時間',
      uwb: '1ms',
      ble: '3-5秒',
      wifi: '3-5秒'
    },
    power: {
      name: '電力消費',
      uwb: '低',
      ble: '非常に低い',
      wifi: '通常'
    },
    value: {
      name: 'コスト',
      uwb: '₩₩',
      ble: '₩₩',
      wifi: '₩₩₩'
    },
    frequency: {
      name: '周波数',
      uwb: '3.1〜10.6GHz',
      ble: '2.4GHz',
      wifi: '2.4GHz、5GHz'
    },
    dataRate: {
      name: '速度',
      uwb: '最大27Mbps',
      ble: '最大2Mbps',
      wifi: '最大1Gbps'
    },
  },
  request: {
    title: 'ソリューションの導入が必要ですか？',
    description: 'ソリューションの導入やデモのリクエストが必要ですか？<br/>いつでもお気軽にご連絡ください。迅速に対応いたします。',
    button: '営業チームにお問い合わせ'
  }
}