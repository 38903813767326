import { StyledGridContainer } from '@components/Grid/Grid.style';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Trans } from 'react-i18next';
import { StyledParagraph } from '@components/Text';
import { FuncBottomBorder, FuncImgSide, FuncLabelBox, FuncLabelIcon, FuncSection, FuncTextBox, FuncTextGridColumn, FuncTextSide, FuncVideo, FuncVideoBox } from './Func.style';
import { BorderBottomLine, LabelColor, Spacing } from '@pages/CommonStyle/common.style';
import { DisplayH2 } from '@components/Text/HeadingTagDisplay.style';


interface ModalDefaultType {
  pageName: string
  path: any
}

const Func: React.FC<PropsWithChildren<ModalDefaultType>> = ({ pageName, path }) => {

  const pageData = path;
  const [isVideoPlaying, setIsVideoPlaying] = useState<Array<boolean>>([]);

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setMobile(true);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const handleScroll = () => {
    pageData.func.forEach((data: any, index: number) => {
      const videoRef = videoRefs.current[index];
      if (videoRef) {
        const videoTop = videoRef.getBoundingClientRect().top;
        const videoBottom = videoRef.getBoundingClientRect().bottom;

        const windowHeight = window.innerHeight;
        const isCurrentVideoPlaying = videoBottom >= windowHeight / 2 && videoTop <= windowHeight / 2 && !isVideoPlaying[index];

        if (isCurrentVideoPlaying) {
          if (videoRef.readyState >= 4) {
            playVideo(videoRef, index);
          }
        } else {
          pauseVideo(videoRef, index);
        }
      }
    });
  };

  const playVideo = (videoRef: HTMLVideoElement | null, index: number) => {
    if (videoRef && videoRef.paused) {
      (videoRef as HTMLVideoElement).play().then(() => {
        setIsVideoPlaying((prevIsVideoPlaying) => {
          const updatedArray = [...prevIsVideoPlaying];
          updatedArray[index] = true;
          return updatedArray;
        });
      });
    }
  };

  const pauseVideo = (videoRef: HTMLVideoElement | null, index: number) => {
    if (videoRef && !(videoRef as HTMLVideoElement).paused) {
      (videoRef as HTMLVideoElement).pause();
      setIsVideoPlaying((prevIsVideoPlaying) => {
        const updatedArray = [...prevIsVideoPlaying];
        updatedArray[index] = false;
        return updatedArray;
      });
    }
  };

  return (
    <>
      <StyledGridContainer >

        {pageData.func.map((data, index) => (
          <div key={index}>
            <FuncSection>

              <FuncTextSide index={index}>
                <FuncTextBox index={index}>
                  <DisplayH2 size='small'>
                    <Trans i18nKey={pageData.func[index].title} />
                  </DisplayH2>
                  <Spacing pixel='16'/>
                  <StyledParagraph size='medium'>
                    <p>
                      <Trans i18nKey={pageData.func[index].desc} />
                    </p>
                  </StyledParagraph>
                  <Spacing pixel='32' />
                  <FuncLabelBox>
                    <FuncLabelIcon src={data.icon1} alt='rtlsIcon' />
                    <LabelColor size={'medium'} color='#646464'>
                      <p>
                        <Trans i18nKey={pageData.func[index].sub1} />
                      </p>
                    </LabelColor>
                  </FuncLabelBox>
                  <Spacing pixel='16'/>
                  <FuncLabelBox>
                    <FuncLabelIcon src={data.icon2} alt='rtlsIcon' />
                    <LabelColor size={'medium'} color='#646464'>
                      <p>
                        <Trans i18nKey={pageData.func[index].sub2} />
                      </p>
                    </LabelColor>
                  </FuncLabelBox>
                  <Spacing pixel='32' />
                </FuncTextBox>
              </FuncTextSide>

              <FuncImgSide i={index}>
                <FuncVideoBox >
                  <FuncVideo ref={(ref) => (videoRefs.current[index] = ref)}
                    autoPlay
                    loop muted
                    playsInline
                  >
                    <source src={data.video} type="video/mp4" />
                  </FuncVideo>
                </FuncVideoBox>

              </FuncImgSide>




            </FuncSection>

            {index === pageData.func.length - 1 && <FuncBottomBorder />}
            <BorderBottomLine />
          </div>
        ))}


      </StyledGridContainer>
    </>
  )
};

export default Func;