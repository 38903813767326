import { accessAutoThumb, deadboltThumb, emlockThumb } from '@assets/images/main/access/product';
import { monitoringOn, monitoringOff, controlOn, controlOff, displayOn, displayOff, cradleOn, cradleOff, idCardOn, idCardOff } from '@assets/images/main/index';


export default {

 
    banner : {
        title : '入退室システムの革新',
        desc : 'Orbroの入退室システムを導入して、あなたの入退室システムを革新してください。',

        schemeTitle :'あなたに最適な入退室システムを選択してください。',
        schemeDesc : '決定にお困りですか？',
        schemeDesc2 : 'にご連絡いただければ<br/>入退室の専門家がサポートいたします。',
        phone : '1522-9928',
    },

    mainPage : {
        product : {
            title : '製品構成を確認する',
            desc :'革新的な入退室システムの製品構成を確認してください。',
            asset :{
                title : 'モバイル入退室',
                desc :'モバイルで入退室する入退室システムの始まり',
            },
            worker :{
                title : '自動ドア入退室',
                desc :'自動ドアに特化した入退室システムの始まり<br/>すべての出入り口に対応',

            }
        }

    },
    

    bannerData : [
    {
        icon:monitoringOn,
        iconInv:monitoringOff,
        image:'',
        iconText: '緊急ベル',
        sub: '',
        link : '/jp/iot/bell'
    },
    {
        icon:controlOn,
        iconInv:controlOff,
        image:'',
        iconText: '電力制御',
        sub: '',
        link : '/jp/iot/energy'
    },
    {
        icon:displayOn,
        iconInv:displayOff,
        image:'',
        iconText: 'E-Ink<br/>ディスプレイ',
        sub: '',
        link : '/jp/iot/display'
    },
    {
        icon:cradleOn,
        iconInv:cradleOff,
        image:'',
        iconText: 'モバイルセキュリティデバイス',
        sub: '',
        link : '/jp/iot/cradle'
    },
    {
        icon: idCardOn,
        iconInv: idCardOff,
        image: '',
        iconText: '社員証',
        sub: '',
        link: '/jp/iot/id'
    },
    
    
    
    ],
}