export default {

  banner: 'Turning Reality into the Digital World',
  banner2: 'A Vividly Reconstructed Data Platform.',
  desc: 'There is an enormous amount of information, approximately 10 zettabytes, existing worldwide. <br/>ORBRO is a platform that visualizes and represents the information from the real world in the virtual world. <br/>Experience a new world where virtual and reality are seamlessly connected.',
  over: 'ORBRO is a project that replicates and functionally integrates meaningful information existing on Earth in real-time. <br/>From land, sky, sea, city, to the movement of people and vehicles, you can experience a more realistic digital world than reality itself.',
  overSerial: [
    { text: 'The Same World' },
    { text: 'The Same Ocean' },
    { text: 'The Same City' },
    { text: 'The Same Movement' },
  ],
  alive: [
    {
      title: 'Scan City Information Rapidly',
      titleColor: '#151515',
      text: 'Explore building details on ORBRO, including height, name, address, and number of floors.',
      textColor: '#151515',
      size: 'type2',
    },
    {
      title: 'More Detailed Information about All Buildings in the World',
      titleColor: '#151515',
      text: 'Discover realistic global building visualizations on ORBRO. Access detailed models and information on apartments, parks, landmarks, and more from anywhere.',
      textColor: '#151515',
      size: 'type4',
    },
    {
      title: 'Fastest Access to Living Information',
      titleColor: '#151515',
      text: 'ORBRO shows bus stops, terminals, airports, and public CCTV, and it can even represent various objects such as trees on the map. It creates infinite scalability by connecting apps to virtual objects.',
      textColor: '#151515',
      size: 'type4',
    },
    {
      title: 'Beyond 3D, to Video Representation',
      titleColor: '#151515',
      text: `View real-time CCTV and video feeds from cameras installed on roads and other devices on ORBRO's 3D map`,
      textColor: '#151515',
      size: 'type2',
    },
    {
      title: 'Realistic Traffic Information with Enhanced Realism',
      titleColor: '#151515',
      text: 'Display a massive amount of public big data, including subway timetables by line, terminal transportation information (high-speed, intercity, air, passenger), public transportation route information, and location information, in real-time by visualizing it in the virtual space.',
      textColor: '#151515',
      size: 'type5',
    },
    {
      title: 'People Walking on the Map',
      titleColor: '#151515',
      text: 'In places where ORBRO infrastructure is installed, all moving individuals, indoors and outdoors, appear on the map.',
      textColor: '#151515',
      size: 'type2',
    },
    {
      title: 'Vehicles Driving on the Map',
      titleColor: '#151515',
      text: 'ORBRO displays real-time vehicle information, including parked vehicles and their movements, providing insights into traffic volume, congestion, and traffic dispersal prediction',
      textColor: '#151515',
      size: 'type4',
    },
    {
      title: 'Airplanes Flying on the Map',
      titleColor: '#151515',
      text: 'Display flight schedules by subway line, terminal transportation information (high-speed, intercity, air, passenger), public transportation route information, and location information in real-time on the virtual space by visualizing a vast amount of public big data.',
      textColor: '#151515',
      size: 'type4',
    },
    {
      title: 'Real-time Airport Information Display',
      titleColor: '#fff',
      text: 'ORBRO provides real-time connectivity with aircraft information, allowing you to track arrival and departure times through terminal information.',
      textColor: '#fff',
      size: 'type2',
    },
    {
      title: 'Weather Seen with Your Own Eyes',
      titleColor: '#151515',
      text: 'ORBRO visualizes and displays detailed environmental information, including air quality, dust levels, and more, for any location on the map',
      textColor: '#151515',
      size: 'type2',
    },
    {
      title: 'Curious to Know More?',
      titleColor: '#fff',
      text: 'In ORBRO World, all weather information from every region around the world is connected. You can see real-time weather information that changes every moment at every location you encounter in ORBRO.',
      textColor: '#fff',
      size: 'type4',
      shadow: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(96, 96, 96, 0.2) 45%, rgba(255, 255, 255, 0) 95%)',
    },
  ],
  infoSub: 'The Real World Operated in the Virtual Space',
  info: 'ORBRO, Containing Real World <br/>Information.',
  twinTitle: 'This is the True Digital Twin',
  twinSub: 'How We See the World.',
  twin: [
    {
      title: 'Twin Tracker',
      text: 'Twin Tracker is a device that tracks the entire 2.4GHz Bluetooth radio wave band. It is the world\'s first network device for position analysis that has been highly advanced for industrial distribution over a 10-year period of R&D.',
      deviceInfo: {
        width: '95mm',
        height: '95mm',
        thickness: '32.5mm',
        specifications: ['USB C, PoE LAN Port', 'Directional Antenna'],
      },
      buttonType: 'black',
    },
    {
      title: 'Twin Vision',
      text: 'Twin Vision is a location tracking device that utilizes stereo camera and AI technology. It utilizes advanced deep learning technology in a comprehensive manner to track the movements of objects in real-time and virtualizes target objects in the ORBRO world through 3D modeling.',
      deviceInfo: {
        width: '185mm',
        height: '40mm',
        thickness: '39mm',
        specifications: ['Dual 4 MP Sensor, 2 Micron Pixel', 'RTLS Optimized Image Streaming', 'High-Quality Image Calibration'],
      },
      buttonType: 'black',
    },
    {
      title: 'Precise Indoor Position Analysis',
      text: 'Through Twin devices, you can detect and analyze visitors\' entry, movement routes, and even precise locations in real-time, providing the most accurate method.',
    },
    {
      title: 'Accumulation of Detailed Movement Data',
      text: 'Record and store the real-time movements of visitors entering the water, and utilize the data as valuable information for improving the spatial experience. Analyze in detail, such as where they visited, how fast they moved, and how long they stayed.',
    },
    {
      title: 'Individual Visitor Tracking',
      text: 'ORBRO tracks visitor movements, records detailed data such as routes, entry/exit times, duration, and contact information, enabling analysis of visitor patterns and behavior.',
    },
    {
      title: 'User Analysis by Time Zone',
      text: 'You can check how many people have entered at each time zone. You can instantly see when the most people are moving in a building. By analyzing the flow of population, it can be applied in various forms such as marketing, promotion, and facility rearrangement.',
    },
    {
      title: 'Heatmap Representation of Space',
      text: 'When ORBRO is combined with personal information, its utility becomes even higher. It can be used in various management methods, such as visitor access systems that require authorization settings, and checking commuting times.',
    },
    {
      title: 'Expansion of Personal Information Integration',
      text: 'When ORBRO is combined with user information, it transforms into excellent information. It can be used in various ways, such as efficient user management by being connected to access systems and existing user database information.',
    },
    {
      title: 'World\'s First Integrated Position Analysis',
      text: 'ORBRO Twin enables industrial innovation by integrating video analysis and radio wave technology as a precision position tracking technology for the first time in the world.',
    },
    {
      title: 'Mobile Integration',
      text: 'Using radio wave tracking technology, ORBRO can track mobile devices, tags, and electronic devices. Analyzing user location through mobile devices can generate new insights.',
    },
    {
      title: 'Protection of Personal Information',
      text: 'In order to protect users\' valuable personal information and ensure security, ORBRO does not store any personal sensitive information on the server.',
    },
  ],
  accessTitle: 'Contactless Access System',
  accessSub: 'A Futuristic<br/>New Entry Method',
  access: [
    {
      title: 'Access Pro',
      text: 'Access Pro manages and controls access permissions based on user information, making it useful for buildings that require precise access control.',
      deviceInfo: {
        width: '160mm',
        height: '160mm',
        thickness: '38mm',
        weight: '258g',
        specifications: ['4 Channel T&C Engine', '2 Channel Bluetooth 5.0', 'Round TFT-LCD'],
      },
      buttonType: 'white',
    },
    {
      title: 'Access Lite',
      text: 'Access Lite grants access permissions to all users with specific conditions. It is useful for public places and area management locations that permit entry with only movement or simple authentication procedures.',
      deviceInfo: {
        width: '210mm',
        height: '60mm',
        thickness: '43mm',
        weight: '156g',
        specifications: ['Radar Sensor', 'Noise Filter', 'Bluetooth 5.0'],
      },
      buttonType: 'white',
    },
    {
      title: 'Visitor Statistics Providing Insights',
      text: 'You can instantly check various visitor information by statistics in real-time.',
    },
    {
      title: 'Movement Routes for Each Space',
      text: 'Curious about how visitors move? You can identify the movement routes of individual visitors on each floor of the building.',
    },
  ],
  smartTitle: 'Remote Control of Devices',
  smartSub: 'Optimal<br/>Space Experience',
  smart: [
    {
    title: 'Comfortable Air Conditioning Control Anytime',
    text :'',
    },
    {
    title: 'Easy Lighting Control at Once',
    text :'',
    },
    {
    title: 'Not Only Air Quality, but also Temperature Information',
    text :'',
    },
    {
      title: 'All Products for Everything',
      text: 'Various products that interact with ORBRO help you achieve your perfect experience, transcending online and offline.',
    },
  ],


  size: 'Size and Weight',
  width: 'Width',
  thick: 'Thickness',
  height: 'Height',
  weight: 'Weight',
  product: 'Specifications',

  dream: 'All the Features of the World You Dreamed of',
  app: 'ORBRO App for Infinite Expansion',
  download: 'Download the App',
  solution: 'Various solutions are available.',
  more: 'Learn More',
  platform: 'Start the Platform',
  exp: 'Experience a New World Connected to Reality.',
  start: 'Get Started',
}
