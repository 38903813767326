import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import digitaltwin from "@src/assets/images/digitaltwin/video/traffic.mp4";

export default {

    helmet: '| 什么是智能城市？',
    desc: '智慧城市是一个概念，利用数字孪生技术对城市的物理要素进行数字建模，从而实现对城市基础设施和服务的智能管理和优化。',
    keywords: '智能城市、数字孪生、数字转型、smart city、digital twin、digital transformation、实时位置追踪、rtls、orbro、奥布罗、孔泰科',

    ogTitle: '',
    ogDesc: '',
    ogSite: '什么是智能城市？ :: 奥布罗博客',
    ogUrl: 'https://orbro.io/cn/blog/what-is-smart-city',
    ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDlPQWtEdPqpk5QG-xfimTOJPt6-WAQc9XeRZnvdeRnqh_rxt6qJtKZOqz_FUESiTUuXhz_YtQo1FyKz_g8EERCOUtHYJW3xiXXD2sNkav4DXtc1AZ-gu3i93GEY_jis-cy4O6GH1tGpeZ4nKJVSR86PDIoBBZF_H0P4oJxW8cQnvXQuhCLFts7lyVvPB7kmVKa1OMU-JySPR1iSsikgxErasD7CSbVUFcgQ1ta5zV8ucSdKb-wJcos_kHcj1LwrC_ZcOPRVd1aLU-qqBrA0MQpdzUPwdSap8VNPoTP5KEvrZFDmYEqXdDHW70S-815Duo6uAP5itzyDvT7LMIj6PJsucX39uXQuIgna2GZVvnFvV5Mzn0Ahzj6YGbEc4_-R8dKbmd7l-xQQn97-pBq93VtBRpWnsE8CZtKKzUe5hgvhKVKYxiG-xYBSdR5ofDnF7aW1M1I93_h77nSfLcx1Tt19ZtokM9H-Rd655Ovi9zrvx9HHZbvCCDwce6It0IKoyJahzNaatP9RxcCbIHXKs64i1a78WRZIGcAF54tvf702cITAZp-Z3Jie9aUOtwKlb0_egIbCBZGu5dQ9h7I-nlDcj8nk-6y7h-JOYwYNUsUUW5B-3Vl6Sfu6KR25Oq7YrQo7nAbbRk8cdqfGGyXkcugF1RVkYybD-qq4oTRAbsSaOtltD9IuMouwBm4zeQ9JaL_4i-bpnX2Ag-rFmjOb9ylnE5cwozwljUHSITuoJOTbj9KtFqzMIoHoG9BkgSaLSmTnEhUeScVp3hhhJXkJTM4xvgRTDSboBXdC7C2aFpLaZZ-YqsxRUa5DuIKjdfOFccVaL0YhoSD1GrckiDXmEwZNa8il2qaWLbSFfLYPtF6gjK79kIta-EJplhWiFs4ONxbwmyZIER1m1dLRj5ALQgPqrHSTit4coflnZ--wlKrOhTRcrgiw7IXdbPboS_-3zlQi2dK5Mrxy-cJHOIRWDfvAG0Cjf77xz86tvDC7feQY_926zrWMX8xOGH2mMmzXVjcLzTD45Sw6_cUUsdLakivhUrXMSbtL_Vgg9pCsTx1oLfwtYUF0ugK5dO7BhRojQZoxBIopnkdCm8uq2vmhq94jKesQediuYNGhMGoLE3_a2Bxd48cIhzQgHb2QGvE1eDvT2P4GldVE8p17vZhFvEmv3lXu-7RAKx_PaUzPXRbJ8UUESSX7ifbfg54onDWKtJ7qJ6dkZ2mSlhuKdEe9G9rF0CG8BE1JPI2MeZC4yR6-iQNGwzWrcicyyBTqkotflfSmhEV_Rrqpg1avycEeajNafwvwEUC1yZzShi15JDuVdDGvgVH2hZVKM3pOZ1JxEuwtUbqP-BFkg5aBtbALWMImy4XQYiSkd3EEdQPa02OfyER8CLl0Qy7g6czv2zTAukCrY7PE4v6VAhtPwV6cI7At2KAheOe7VZNPThDj5qCKG3duCLJl4Rbkqyrt0s7WFzlAke5TDxhEE_9ta0JtMzjrPZkQWcACu_7KOu3wXz7cpHrv906MtZzK83YCoVHtfCjmXVjrU0ArV66WqRuUbz9OCBOsngRV=w2152-h1214',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '什么是智能城市？',
    date: '2024-01-08',
    tag1: '#智能城市',
    tag2: '#数字孪生',
    tag3: '#数字转型',
    tag4: '#未来城市',

    // <br/>
    text: [
        {
            head: '什么是智能城市？',
            subHead: '',
            desc: '智慧城市是一个利用数字孪生技术对城市的物理要素进行数字建模，从而实现对城市基础设施和服务的智能管理和优化的概念。这是为了解决传统城市中出现的各种问题，并作为建设可持续和创新型城市的努力的一部分而出现的。<br/><br/>智慧城市旨在利用各种技术和信息通信技术（ICT）提升城市生活的各个方面。它被视为改善现有城市问题、为未来城市的可持续性和创新提供新范式。主要技术包括物联网（IoT）、大数据、人工智能（AI）和自动驾驶汽车。',
            dot: ''
        },
        {
            head: '智能城市的核心技术',
            subHead: '',
            desc: '实现智能城市需要利用各种先进技术，并将这些技术集成，实现城市智能化。受到关注的主要技术包括以下内容。',
            dot: ''
        },
        {
            head: '',
            subHead: '物联网（IoT）',
            desc: '智能城市的核心要素之一是物联网（IoT）技术。通过将各种物体应用传感器，并通过互联网连接，可以实现实时数据收集和共享。在城市内的建筑、交通系统、环境等各个领域收集的数据被集成到中央系统中，有助于实时了解城市状况。',
            dot: ''
        },
        {
            head: '',
            subHead: '大数据',
            desc: '数据是智能城市的核心资源之一，大数据技术是有效利用它的必备条件。通过快速收集、存储、分析大量数据，可以把握城市的趋势和模式，并进行预测。通过大数据分析，可以优化城市功能并及早发现问题，为决策和规划提供巨大帮助。',
            dot: ''
        },
        {
            head: '',
            subHead: '人工智能（AI）',
            desc: '与大数据一起使用的人工智能技术发挥着使智能城市变得智能的不可或缺的作用。利用机器学习和深度学习分析数据，优化城市系统。AI技术提高了智能城市在交通流量优化、能源消耗预测、自动化系统运营等多个领域的效率。',
            dot: ''
        },
        {
            head: '',
            subHead: '5G网络',
            desc: '在智能城市中，需要迅速传输和处理大量数据。作为第五代移动通信技术的5G提供超高速的数据传输和低延迟，支持智能城市的各种技术有效运行。5G为自动驾驶汽车、智能基础设施、工业机器人等技术提供了核心的通信基础设施。',
            dot: ''
        },
        {
            head: '',
            subHead: '区块链',
            desc: '在智能城市中，安全保护和共享数据是一个重要问题。区块链技术通过分布式数据库确保数据的安全性和一致性，同时实现透明且安全的交易。这使得在智能城市的各个领域安全共享和利用数据成为可能。',
            dot: ''
        },
        {
            video: digitaltwin,
            link: 'https://orbro.io/cn/digital-twin',
            videoDesc: '(点击视频，将跳转至Orbro数字孪生页面。)',
            head: '智能城市的优势',
            subHead: '高效的资源管理',
            desc: '智能城市通过利用大数据、物联网（IoT）和人工智能（AI）技术，有效地管理资源。通过收集和分析有关能源、水资源、交通等各种资源的数据，找到最佳的运营方式，降低能源消耗，最小化资源浪费。',
            dot: ''
        },
        {
            head: "",
            subHead: "优化交通流",
            desc: "通过实时数据的收集和分析，智能城市优化交通流，减少道路拥堵。通过智能信号灯、自动驾驶汽车、改进的公共交通系统等方式，提高交通效率，使市民出行更加便利。",
            dot: ""
        },
        {
            head: "",
            subHead: "提高环境可持续性",
            desc: "智能城市提高能源效率，积极利用可再生能源，减轻对环境的负担。同时，实时监测空气和水质，及时应对环境问题。",
            dot: ""
        },
        {
            head: "",
            subHead: "经济激活",
            desc: "智能城市的实施推动新技术和服务产业的发展，创造就业机会，引领经济激活。各个产业领域的企业和专业人士参与智能城市项目，通过开发创新技术和商业模式，扩大产业生态系统。",
            dot: ""
        },
        {
            head: "",
            subHead: "提高应对紧急情况的能力",
            desc: "智能城市建立有效的紧急响应系统，以应对自然灾害和社会紧急情况。实时数据的收集和分析有助于预测和管理灾害，并迅速向市民提供安全信息，提高应对能力。",
            dot: ""
        },
        {
            head: "",
            subHead: "提升城市竞争力",
            desc: "智能城市建立现代化和创新的形象，提升城市的国际竞争力。先进技术和先进基础设施吸引全球企业和人才，促使国际投资，引领城市经济发展。",
            dot: ""
        },
        {
            head: "",
            subHead: "可持续城市发展",
            desc: "智能城市的优势有助于可持续城市发展。通过从环境、经济、社会的角度规划和运营城市，实现未来世代的可持续发展。",
            dot: ""
        },
        {
            head: "智能城市的运作原理",
            subHead: "传感器和物联网的应用",
            desc: "智能城市的核心在于传感器和物联网技术的应用。安装在城市各地的各种位置的传感器实时收集与环境、交通、能源使用等相关的数据。这些数据用于监视城市的各个方面，并用于早期发现问题。",
            dot: ""
        },
        {
            head: "",
            subHead: "可持续城市发展",
            desc: "智能城市的优势有助于可持续城市发展。通过从环境、经济、社会的角度规划和运营城市，实现未来世代的可持续发展。",
            dot: ""
        },
        {
            head: "",
            subHead: "人工智能和机器学习的应用",
            desc: "与大数据结合使用的人工智能（AI）和机器学习技术分析数据，优化城市系统。通过预测分析优化交通流，预测能源消耗以有效管理。 AI在解决城市中发生的复杂问题方面发挥创造性和智能的解决方案。",
            dot: ""
        },
        {
            head: "",
            subHead: "构建智能基础设施",
            desc: "智能城市建设智能基础设施，连接城市的各个部分。智能电网有效管理电力，智能交通系统优化交通流，智能建筑最小化能源使用。这些智能基础设施综合管理城市的各个方面，推动可持续发展。",
            dot: ""
        },
        {
            head: "",
            subHead: "与物联网相互连接的设备",
            desc: "通过物联网连接的相互连接的设备构成智能城市。智能家居产品、车辆、建筑等通过信息交换和调整运作，有效利用城市资源，提高便利性。",
            dot: ""
        },
        {
            head: "",
            subHead: "市民参与和数字服务提供",
            desc: "市民可以通过数字服务积极参与智能城市。通过移动应用程序访问城市信息，报告问题，参与活动等，市民可以直接参与城市的运营。",
            dot: ""
        },
        {
            head: "",
            subHead: "智能城市运营系统",
            desc: "集成所有数据和设备的智能城市运营系统有效运营城市的各个部分，并提供最佳服务。交通流的优化、能源效率的管理、环境监测等都会自动进行，使城市智能运营。",
            dot: ""
        },
        {
            head: "",
            subHead: "持续改善与评估",
            desc: "智慧城市将通过持续改善和评估不断发展。接受市民的反馈，引入技术创新以满足城市的需求，维持并发展可持续的智慧城市。",
            dot: ""
        },
        {
            head: "智慧城市展望",
            subHead: "",
            desc: "智慧城市通过可持续发展和创新技术的引入，正在塑造未来城市的框架。预计将重点关注能源效率和环境保护，进一步加强可再生能源和环保交通系统的利用。此外，创新技术如大数据、人工智能、物联网等将更积极地应用，智能管理城市的各个方面，提高市民的便利性。智慧城市被预测为未来城市的核心特征，通过持续发展和技术创新加强城市竞争力。",
            dot: ""
        },
        {
            linkText: "体验Orbro World",
            link: "https://home.orbro.io",
            head: "",
            subHead: "",
            desc: "",
            dot: ""
        }


    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
          {
            text: productPurchase.purchase.asset.assetTracking.title,
            title: productPurchase.purchase.asset.assetTracking.title,
            serial: productPurchase.purchase.asset.assetTracking.serial,
            desc: '此产品可将UWB设备附加到工作人员或设备上，以实时跟踪其位置。',
            img: productImg.product.assetTracking.key1
          },
          {
    
          }
    
        ],
        solution: [
          {
            img: logisticsAsset.thumb,
            data: 'logisticsAsset',
            link: '/cn/enterprise/logistics/asset'
          },
          {
            img: logisticsSafety.thumb,
            data: 'logisticsSafety',
            link: '/cn/enterprise/logistics/safety'
          },
          {
            img: manufacturingAsset.thumb,
            data: 'manufacturingAsset',
            link: '/cn/enterprise/manufacturing/asset'
          }
        ]
    
      }
}
