import React, { useMemo, useRef } from 'react';
import { Trans } from 'react-i18next';
import access from '@src/lang/ko/main/access/access';
import { StyledGridContainer } from '@components/Grid/Grid.style';
import productScheme from '@src/lang/ko/main/access/productScheme';
import { IconCheckBlack } from '@assets/images/icon';
import { StyledCareerSeciton } from '../Main/Slider.style';
import { accessMainKey1Mobile, accessMainKey2Mobile, accessMainKey3Mobile } from '@assets/images/main';

import {
  StyledBox, StyledImgBox, StyledTextBox, StyledImg,
  StyledSubTextBox, StyledCheckBox, StyledGapBorder, StyledBtn
} from './SchemeMobile.style';
import { StyledHeading, StyledLabel } from '@components/Text';
import { LabelColor, Spacing } from '@pages/CommonStyle/common.style';
import { DivFlexRow } from '@pages/CommonStyle/commonDiv.style';
import { StyledDesc, StyledLink, StyledTitle } from '@pages/Main/Common/Scheme/Scheme.style';

const SchemeMobile = ({ handleIconClickLocal }): React.ReactElement => {


  // Datas
  const list = useMemo(() => productScheme.a, []);

  const handleIconClick = (group) => {
    handleIconClickLocal(group);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const outputData = ["a", "b", "c", "d"]
  const outputList = ["설명", "전원 공급", "통신 방식", "네트워크", "규격"]

  const borderSwitchRef = useRef(true);

  const images = useMemo(() => [
    {
      mobile: accessMainKey1Mobile
    },
    {
      mobile: accessMainKey2Mobile
    },
    {
      mobile: accessMainKey3Mobile
    },
    // Add more image objects as needed
  ], []);


  return (
    <StyledCareerSeciton>
      <StyledGridContainer>

        <Spacing pixel='96' />

        <StyledTitle size={'small'}>
          <Trans i18nKey={`accessMain.banner.schemeTitle`} />
        </StyledTitle>

        <StyledDesc size={'medium'}>
          <Trans i18nKey={`accessMain.banner.schemeDesc`} />
          <StyledLink size={'medium'} inline>
            <Trans i18nKey={access.banner.phone} />
          </StyledLink>
          <Trans i18nKey={`accessMain.banner.schemeDesc2`} />
        </StyledDesc>


        {Array(3).fill(null).map((_, index) => (
          <StyledBox key={index}>
            <StyledHeading size="xxxsmall">  <Trans i18nKey={`productSchemeAccess.${outputData[index + 1]}.0.text`} /></StyledHeading>
            <StyledImgBox>
              <StyledImg src={images[index].mobile} alt='Door Access Product'>

              </StyledImg>
              <DivFlexRow>
                <StyledBtn onClick={() => handleIconClick(index + 1)}>
                  <Trans i18nKey={`home.showMore`} />
                </StyledBtn>
              </DivFlexRow>

            </StyledImgBox>
            <Spacing pixel='16' />

            {borderSwitchRef.current = true}
            {list.map((data, i) => (
              <>
                {outputList.includes(data.text) && (
                  <>
                    <StyledTextBox key={i}>
                      <StyledLabel size="small">
                        <Trans i18nKey={`productSchemeAccess.a.${i}.text`} />
                      </StyledLabel>
                      <Spacing pixel='4' />
                      <LabelColor size="medium" color="#646464">
                        <Trans i18nKey={`productSchemeAccess.${outputData[index + 1]}.${i}.text`} />
                      </LabelColor>
                    </StyledTextBox>

                  </>

                )}

                {productScheme[outputData[index + 1]][i]?.text === `1` && (
                  <>
                    {borderSwitchRef.current && (
                      <>
                        <Spacing pixel='16' />
                        <StyledGapBorder />
                        {borderSwitchRef.current = false}
                      </>
                    )}
                    <StyledSubTextBox>
                      <StyledCheckBox src={IconCheckBlack} alt='Black Check Icon' />
                      <StyledLabel size="medium">
                        <Trans i18nKey={`productSchemeAccess.a.${i}.text`} />
                      </StyledLabel>
                    </StyledSubTextBox>
                  </>
                )}


              </>
            ))}

          </StyledBox>

        ))}

        <Spacing pixel='108' />

      </StyledGridContainer>
    </StyledCareerSeciton>
  );
};

export default SchemeMobile;
