import { StyledGridContainer } from "@components/Grid/Grid.style";
import React, { useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { StyledOurHistory, StyledOurHistoryContentItem, StyledOurHistoryContents, StyledOurHistoryControl, StyledOUrHistoryControlIcon, StyledOurHistoryNav, StyledOurHistorySubtitle, StyledOurHistoryTitle, StyledOurHistoryYear } from "./OurHistory.style";
import ArrowLeftDisabled from "@assets/icons/ic-arrow-left-disabled.svg";
import ArrowRightDisabled from "@assets/icons/ic-arrow-right-disabled.svg";
import ArrowLeft from "@assets/icons/ic-arrow-left-light.svg"
import ArrowRight from "@assets/icons/ic-arrow-right-active.svg";
import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";
import company from "@src/lang/ko/company";
const OurHistory = () => {
  
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  // States
  const [year, setYear] = useState<string>('2022');
  const [activeIndex, setActiveIndex] = useState<number>(0);

  // Memo
  const histories = useMemo(() => 
    company.ourHistory.histories, 
  []);

  // Active Arrows
  const activeBefore = activeIndex != 0;
  const activeAfter = activeIndex < Object.keys(histories).length - 1;

  // hooks
  useEffect(() => {
    const keys = Object.keys(histories).reverse();
    setYear(keys[activeIndex]);
  }, [activeIndex]);

  // Click Arrows
  const handleClickArrow = (type: 'before' | 'after') => {
    if (type === 'before' && activeBefore) {
      setActiveIndex(activeIndex - 1);
    } else if (type === 'after' && activeAfter){
      setActiveIndex(activeIndex + 1);
    }
  };

  return (
    <StyledOurHistory>
      <StyledGridContainer>

        <StyledOurHistorySubtitle size="xxsmall">
          <Trans i18nKey={"company.ourHistory.subtitle"} />
        </StyledOurHistorySubtitle>
        <StyledOurHistoryTitle size="small">
          <Trans i18nKey={"company.ourHistory.title"} />
        </StyledOurHistoryTitle>
        

        <StyledOurHistoryNav>
          <StyledOurHistoryYear size="large">{ year }</StyledOurHistoryYear>
          
          <StyledOurHistoryControl>
            <StyledOUrHistoryControlIcon 
              src={activeBefore ? ArrowLeft : ArrowLeftDisabled} 
              width={layoutType === 'mobile' ? 24 : 36}
              onClick={() => handleClickArrow('before')}
              alt="control icon"
            />
            <StyledOUrHistoryControlIcon 
              src={activeAfter ? ArrowRight :ArrowRightDisabled} 
              width={layoutType === 'mobile' ? 24 : 36}
              onClick={() => handleClickArrow('after')}
              alt="control icon"
            />
          </StyledOurHistoryControl>
          
        </StyledOurHistoryNav>
        


        <StyledOurHistoryContents>
          { histories[year].map((history, index) => (
            <StyledOurHistoryContentItem size="xxsmall" key={`our-history-${year}-${index}`}>
               <Trans i18nKey={`company.ourHistory.histories.${year}.${index}`}/>
            </StyledOurHistoryContentItem>
          )) }
        </StyledOurHistoryContents>
        
      </StyledGridContainer>
    </StyledOurHistory>
  );
};

export default OurHistory;