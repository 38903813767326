import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4';

export default {

    desc: 'A smart shipyard refers to building a more efficient and intelligent production system utilizing advanced technologies such as AI (Artificial Intelligence), IoT (Internet of Things), RTLS (Real-Time Location Tracking), and digital twin.',
    keywords: 'Smart shipyard, AI, IoT, RTLS, Digital twin, beacon, UWB, BLE, Orbro, KONTECH',

    ogSite: 'Smart Shipyard Solution',
    ogUrl: 'https://orbro.io/en/blog/smart-shipyard',
    ogImg: 'https://lh3.google.com/u/0/d/1WdXSNBhEft64iXMMY189UfAb4GJnSnFp=w2000-h7622-iv1',
    ogType: 'website',

    title: 'Smart Shipyard Solution',
    date: '2024-05-09',
    tag1: '#smartshipyard',
    tag2: '#shipyard',
    tag3: '#portindustry',
    tag4: '#digitaltwin',

    // <br/>
    text: [
        {
            head: 'What is a Smart Shipyard?',
            subHead: '',
            desc: 'A smart shipyard refers to building a more efficient and intelligent production system utilizing advanced technologies such as AI (Artificial Intelligence), IoT (Internet of Things), RTLS (Real-Time Location Tracking), and digital twin. The goal of a smart shipyard is to enhance worker safety, increase productivity, and improve energy efficiency, thereby establishing a sustainable maritime industry through the digitization and automation of production processes.',
            dot: ''
        },
        {
            head: 'Key Technologies',
            subHead: 'Real-Time Location Tracking (RTLS)',
            desc: 'Tracks the real-time location of workers and assets to increase transparency and maximize efficiency in the production process. This technology provides precise location information updated per second to optimize work schedules and enhance safety.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Digital Twin',
            desc: 'Connects all elements of the shipyard to the digital world, creating a virtual model. This allows simulation and optimization of actual processes, enhancing productivity and efficiency at every stage from design to production and maintenance.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Utilization of Big Data and Artificial Intelligence (AI)',
            desc: 'Collects and analyzes large amounts of data generated in the shipyard to optimize production processes and achieve predictive maintenance. AI discovers patterns in data and supports prediction and decision-making based on them, increasing productivity and reducing costs.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Introduction of Internet of Things (IoT) Technology',
            desc: 'Connects various equipment and sensors within ships and shipyards to collect and monitor real-time data, optimizing production processes. This allows real-time monitoring of equipment status and preventive maintenance, enhancing productivity.',
            dot: ''
        },
        {
            videoDesc : '(e.g., Orbro Smart Manufacturing Facility)',
            video : video,
            link : '/en/enterprise/manufacturing/process',
            head: 'Key Features',
            subHead: 'Real-Time Worker Location Tracking',
            desc: 'Tracks the real-time location of workers to optimize work schedules and maintain work environment safety. Monitoring worker locations in real-time prevents potential hazards and maximizes work efficiency.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Real-Time Asset Management',
            desc: 'Monitors the real-time location and status of assets within the shipyard to enhance inventory management and work efficiency. This prevents issues such as inventory shortages or over-purchasing and optimizes asset utilization.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Environmental Management',
            desc: 'Monitors environmental factors to prevent environmental pollution within the shipyard and maintain the sustainability of production processes. This involves real-time monitoring of environmental factors such as air quality, water quality, and noise to take actions and improve the production environment.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Hazard Zone Management',
            desc: 'Identifies and monitors hazardous zones within the shipyard to ensure worker safety and minimize accidents during work. Identifying hazardous areas and providing warnings to workers maintain a safe working environment.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Energy Efficiency Management',
            desc: 'Introduces smart energy systems to optimize energy usage in ports and achieve eco-friendly operations.',
            dot: ''
        },
        {
            head: 'Future Outlook',
            subHead: 'Expansion of Artificial Intelligence (AI) and Autonomous Navigation Technology',
            desc: 'AI technology is expected to further advance, and autonomous navigation technology for ships is expected to become more widespread in the future. This will improve operational efficiency and reduce labor costs in shipyards.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Application of Internet of Things (IoT) Technology',
            desc: 'IoT technology is expected to play an increasingly important role in port operations and management. By collecting and analyzing data through sensors installed on ships and other facilities, IoT technology can enhance operational efficiency in ports.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Emphasis on Environmentally Friendly Technologies',
            desc: 'Technologies focused on improving energy efficiency and environmental protection are expected to be emphasized for sustainable maritime industries. These efforts will increase the sustainability of port operations alongside preserving marine ecosystems.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Growth in the Global Market',
            desc: 'The global port and shipbuilding industry is projected to continue growing. Growth, particularly in the Asia-Pacific region with increasing new port construction and expansion projects, is expected to have a positive impact on Korea\'s smart shipyard industry.',
            dot: ''
        },
        {
            head: 'Conclusion',
            subHead: '',
            desc: 'Smart shipyards are becoming essential for the future sustainable maritime industry. Through the adoption of advanced technologies and innovative approaches, productivity, efficiency, and safety can be increased while achieving environmentally friendly production methods. This will lead to rapid advancements in the shipbuilding industry and enable sustainable growth in the future.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'A product that assists real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/en/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/en/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/en/enterprise/construction/equipment'
            }
        ]


    }


}
