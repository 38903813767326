import { logisticsAsset, manufacturingAsset, manufacturingProcess } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";


export default {
    helmet: '| How Smart Factories are Revolutionizing the Manufacturing Industry',
    desc: 'It can perform various functions such as asset management, production line optimization, automation, data analysis, environmentally friendly production, inventory management, and order processing. This enables securing competitiveness and optimizing the manufacturing process.',
    keywords: 'Smart factories, RTLS technology, smart manufacturing, manufacturing innovation, production efficiency, asset management, production line optimization, safety and security, automation, data analysis, resource allocation, preventive maintenance, customer service, quality improvement, eco-friendly manufacturing, inventory management, visualization, order processing, quality inspection, quality management, production transparency, orbro, 오브로, 콩테크',
    ogSite: 'How Smart Factories are Revolutionizing the Manufacturing Industry :: Orbro Blog',
    ogUrl: 'https://orbro.io/en/blog/smartFactory',
    ogType: 'website',
    title: 'How Smart Factories are Revolutionizing the Manufacturing Industry',
    date: '2023-10-12',
    tag1: '#SmartFactories',
    tag2: '#SmartManufacturing',
    tag3: '#ProductionLine',
    tag4: '#InventoryManagement',
    text: [
        {
            head: '',
            subHead: '',
            desc: 'The manufacturing industry has been significantly impacted by technological innovation, and in recent years, the concept of smart factories has played a crucial role in revolutionizing manufacturing, enhancing production efficiency. One of the key elements of these smart factories is Real-Time Location System (RTLS) technology. RTLS technology allows real-time tracking of the location and movement of assets and workers within manufacturing facilities, helping manufacturing companies optimize production processes and maximize efficiency. In this article, we will discuss how RTLS technology is applied in smart factories and how its efficiency is reshaping the manufacturing industry.',
            dot: '',
        },
        {
            head: 'What is RTLS?',
            subHead: '',
            desc: 'RTLS stands for Real-Time Location System, a technology that enables precise tracking and monitoring of the location of mobile assets, such as workers and equipment. RTLS systems use technologies like GPS, RFID (Radio-Frequency Identification), UWB (Ultra-Wideband), Bluetooth, ultrasound, and other sensors to track the location of workers.',
            dot: '',
        },
        {
            head: 'Optimizing Resource Allocation',
            subHead: '',
            desc: 'RTLS enables real-time tracking of the location of raw materials, components, workers, and machines, allowing for optimized resource allocation. This enhances the efficiency of production lines and reduces resource waste.',
            dot: '',
        },
        {
            head: 'Preventive Maintenance and Maintenance Optimization',
            subHead: '',
            desc: 'RTLS technology monitors the condition of machinery and equipment, allowing for preventive maintenance and optimized maintenance schedules. Early detection of changes in equipment condition or malfunctions prevents production downtime and cost savings.',
            dot: '',
        },
        {
            head: 'Real-Time Monitoring of Production Data',
            subHead: '',
            desc: 'RTLS systems collect and monitor data generated within manufacturing facilities in real time. This enables real-time monitoring of the production process, allowing for quick responses to any issues that may arise during production.',
            dot: '',
        },
        {
            head: 'Enhancing Customer Service and Quality Improvement',
            subHead: '',
            desc: 'RTLS technology is used to track the production process and record the history of products. This allows for improved product quality management and enhanced customer service.',
            dot: '',
        },
        {
            head: 'Eco-Friendly Production',
            subHead: '',
            desc: 'RTLS technology can be used to optimize energy and resource usage, promoting eco-friendly manufacturing. Compliance with environmental regulations and an emphasis on eco-friendly manufacturing practices is supported.',
            dot: '',
        },
        {
            head: 'Automated Inventory Management',
            subHead: '',
            desc: 'RTLS automates inventory management and optimizes inventory levels, reducing inventory management costs and efficiently utilizing storage space.',
            dot: '',
        },
        {
            head: 'Real-Time Visualization of the Production Floor',
            subHead: '',
            desc: `RTLS is used to visualize the manufacturing process in real time. This allows for clear insights into the production floor's status and real- time monitoring of the production process.`,
            dot: '',
        },
        {
            head: 'Reducing Order Processing and Lead Time',
            subHead: '',
            desc: 'RTLS technology can shorten order processing and production cycle times, enabling rapid responses to customer demands.',
            dot: '',
        },
        {
            head: 'Quality Inspection and Management',
            subHead: '',
            desc: 'RTLS enhances product quality inspection and management. It identifies defective products and enables rapid corrective actions for quality issues.',
            dot: '',
        },
        {
            head: 'Enhancing Production Transparency',
            subHead: '',
            desc: 'RTLS technology increases production process transparency, enabling real-time tracking of errors and improvements. This leads to greater production efficiency.',
            dot: '',
        },
        {
            head: '',
            subHead: '',
            desc: 'Successful implementation of RTLS technology in manufacturing facilities requires the right hardware and software infrastructure. Data security and privacy are important considerations. However, despite these challenges, the potential of RTLS technology offers significant advantages to modern manufacturing companies.',
            dot: '',
        },
        {
            head: '',
            subHead: '',
            desc: 'The combination of smart factories and RTLS technology is one of the key ways to revolutionize the manufacturing industry. This enables manufacturing companies to increase efficiency and optimize production processes, maintaining and improving their competitiveness. Therefore, RTLS technology plays a crucial role in shaping the future of the manufacturing industry.',
            dot: '',
        },
    ],
    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'A product that aids real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.assetTracking.key1,
            },
            // Add more product details if needed.
        ],
        solution: [
            {
                img: manufacturingProcess.thumb,
                data: 'manufacturingProcess',
                link: '/en/enterprise/manufacturing/process',
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/en/enterprise/manufacturing/asset',
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/en/enterprise/logistics/asset',
            },
        ],
    },
};
