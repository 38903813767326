import { buildingWhite, groupWhite, hospitalWhite, toolsWhite, trafficWhite, truckWhite } from "@assets/images/pricing";

export default {

    title: '見積もりを取得',
    link: '/jp/pricing/1',

    step1: {
        title: 'どの業界に興味がありますか？',
        percent: '0%',
        common: 'ユースケース',
        industry: [
            {
                title: '建設',
                example: '作業者の安全管理、資材管理、アクセス制御、環境管理など',
                icon: trafficWhite
            },
            {
                title: '物流',
                example: '在庫管理、安全管理、環境管理、アクセス制御など',
                icon: truckWhite
            },
            {
                title: '病院',
                example: 'アクセス制御、患者の追跡、医療廃棄物管理、空気品質管理など',
                icon: hospitalWhite
            },
            {
                title: 'オフィス',
                example: 'アクセス制御、資産管理、空気品質管理など',
                icon: buildingWhite
            },
            {
                title: '製造',
                example: 'アクセス制御、在庫管理、空気品質管理、プロセス管理など',
                icon: toolsWhite
            },
            {
                title: "その他",
                example: "小売り、航空、港、歩行者フロー分析、駐車場制御、空気品質管理など",
                icon: groupWhite
            }
              
        ],
        next: '次へ'
    },

    step2: {
        title: 'どの製品に興味がありますか？',
        percent: '25%',
        common: '詳細を知る',
        product: [
            {
                title: 'ビーコンデバイス',
                example: '工業用途で検証済みの防水・防塵のビーコンデバイスです。'
            },
            {
                title: '無線位置追跡',
                example: '資産と作業者にタグを取り付けて正確でリアルタイムな位置追跡が可能です。'
            },
            {
                title: 'カメラベースの位置追跡',
                example: 'AI搭載のカメラを使用して人、車両などを追跡し、分析および管理します。'
            },
            {
                title: 'アクセス制御システム',
                example: '建物のアクセスをスマートに、迅速かつ安全に管理します。'
            },
            {
                title: '産業用センサー',
                example: '温度、湿度、圧力、漏れ、粒子状物質、一酸化炭素などの現場の環境状態をモニタリングします。'
            },
            {
                title: 'デジタルツイン',
                example: '空間のすべてのデータを収集して仮想現実を構築します。'
            },
        ],
        prev: '前へ',
        next: '次へ'
    },

    step3: {
        title: '以下の項目を確認してください。',
        percent: '50%',
        question: [
            {
                title: 'ビーコンデバイスの使用目的と数量はどのようになりますか？',
                parts: [
                    {
                        title: '使用目的',
                        type: 'choice2',
                        option: [
                            { text: '単なる購入' },
                            { text: '研究または試験プロジェクト' },
                            { text: 'ソリューション導入（サービス活用）' },
                            { text: 'その他' },
                        ]
                    },
                    {
                        title: '予想数量',
                        type: 'choice1',
                        option: [
                            { text: '1〜1,000個' },
                            { text: '1,000〜2,000個' },
                            { text: '2,000〜5,000個' },
                            { text: '5,000個以上' },
                        ]
                    },
                ]
            },
            {
                title: '位置追跡の正確性とエリアのサイズはどの程度ですか？',
                parts: [
                    {
                        title: '正確性',
                        type: 'choice1',
                        option: [
                            { text: '資産追跡（5m未満の範囲で資産追跡）' },
                            { text: '高精度追跡（50cm未満の範囲で資産追跡）' },
                            { text: 'その他' },
                        ]
                    },
                    {
                        title: 'エリアのサイズ',
                        type: 'choice1',
                        option: [
                            { text: '0〜100平方メートル' },
                            { text: '100〜500平方メートル' },
                            { text: '500平方メートル以上' },
                            { text: '分からない' },
                        ]
                    },
                ]
            },
            {
                title: 'どこでカメラ追跡を使用し、追跡する対象は何ですか？',
                parts: [
                    {
                        title: '追跡対象',
                        type: 'choice3',
                        option: [
                            { text: '人' },
                            { text: '車両' },
                            { text: 'その他' },
                        ]
                    },
                    {
                        title: '設置場所',
                        type: 'choice3',
                        option: [
                            { text: '室内' },
                            { text: '屋外' },
                            { text: '分からない' },
                        ]
                    },
                ]
            },
            {
                title: 'アクセス制御システムをどの出入口で使用し、出入口の数は何ですか？',
                parts: [
                    {
                        title: '出入口',
                        type: 'choice3',
                        option: [
                            { text: 'アパート' },
                            { text: 'オフィスと店舗' },
                            { text: 'その他' },
                        ]
                    },
                    {
                        title: '出入口の数',
                        type: 'choice1',
                        option: [
                            { text: '1〜10の出入口' },
                            { text: '10〜20の出入口' },
                            { text: '20〜50の出入口' },
                            { text: '50以上の出入口' },
                        ]
                    },
                ]
            },
            {
                title: 'どのタイプのセンサーが必要ですか？',
                parts: [
                    {
                        title: '',
                        type: 'multichoice2',
                        option: [
                            { text: '非常ベル' },
                            { text: '火災検知' },
                            { text: '温度/湿度' },
                            { text: '漏水検知' },
                            { text: '圧力' },
                            { text: '侵入検知' },
                            { text: '空気品質' },
                            { text: '一酸化炭素検知' },
                        ]
                    },
                ]
            },
            {
                title: ' ',
                parts: [
                    {
                        title: ' ',
                        type: ' ',
                        option: [
                            { text: ' ' },
                        ]
                    },
                ]
            },
        ],
        prev: '前へ',
        next: '次へ'
    },

    step4: {
        title: '連絡可能なメールアドレスを入力してください。',
        percent: '75%',
        email: 'メールアドレス',
        emailHolder: 'example@orbro.io',
        prev: '前へ',
        next: '次へ'
    },

    step5: {
        title: '担当者からのご連絡をさせていただきます。',
        desc: '相談に必要な情報を入力していただければ幸いです。',
        name: 'お名前',
        nameHolder: '山田太郎',
        phone: '電話番号',
        phoneHolder: '090-1234-5678',
        memo: 'メモ',
        memoHolder: '情報を入力してください。',
        prev: '情報をメールで受け取る',
        next: '追加情報を提出する'
    },

    step6: {
        title: '完了',
        desc: '情報を確認した後、お早めにご連絡いたします。プロのエンジニアとの相談が必要な場合は、相談予約を進めてください。',
        btn: '電話相談を予約する'
    },

    schedule: {
        title: '通話可能な時間を入力してください。',
        company: '会社名',
        companyHolder: 'オルブロ',
        name: 'お名前',
        nameHolder: '山田太郎',
        date: '日付',
        time: '時間',
        submit: '送信',
        morning: '午前',
        noon: '午後',
    }
}
