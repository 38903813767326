import React, { ReactElement } from "react";
import { Trans } from "react-i18next";
import { StyledOurCultureCard, StyledOurCultureCardDescription, StyledOurCultureCardTitle } from "./OurCultureCard.style";

interface OurCultureCardProps {
  title: ReactElement | string, 
  description: ReactElement | string,
  img: string
}

const OurCultureCard = (props: OurCultureCardProps) => {
  const { title, description, img } = props;

  return (
    <StyledOurCultureCard desktop={6} tablet={4} mobile={4} img={img}>
      <div>
        <StyledOurCultureCardTitle size="small">{ title }</StyledOurCultureCardTitle>
        <StyledOurCultureCardDescription size="xxsmall">
          { description }
        </StyledOurCultureCardDescription>
      </div>
    </StyledOurCultureCard>  
  );
};

export default OurCultureCard;