import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet: '',
  desc: 'ORBROのGIS実装は、精密な地図学と3D地形シミュレーションを組み合わせたリアルタイムの地理情報システムを提供します。このシステムは専門家が地形分析、インフラ計画、災害管理の意思決定をサポートし、ORBROの革新的な技術力を反映しています。',
  keywords: 'Digital Twin, Digital Twins, デジタルツイン, gis, デジタルシティ, RTLS, 位置追跡システム, 位置追跡技術, スポーツ, 製造, ロジスティクス, 医療, 病院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'デジタルツイン - GIS実装 :: ORBROブログ',
  ogUrl: 'https://orbro.io/jp/blog/digitaltwin-gis',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'デジタルツイン - GIS実装',
  date: '2023-12-26',
  tag1: '#デジタルツイン',
  tag2: '#GIS',
  tag3: '#デジタルシティ',
  tag4: '#バーチャルリアリティ',

  // <br/>
  text: [
    {
      head: 'デジタルツイン - GIS実装',
      subHead: '',
      desc: 'ORBROのGIS（地理情報システム）実装は、デジタルツイン技術を活用して、精密な地図学と3D地形シミュレーションを統합した革新的なリアルタイム地理情報システムを提供します。このシステムは専門家が地形分析、インフラ計画、災害管理の意思決定をサポートし、ORBROの最先端技術力を反映しています。<br/><br/>3D地形モデルは地形の正確な形状、勾配、高さを視覚的に表現し、環境分析、都市計画、災害管理に活用されます。インフラデータは3DモデリングとGIS技術を使用してデジタル化され、都市開発と災害対応に不可欠です。<br/><br/>建物情報は高解像度の写真、建築設計データ、3Dスキャンを使用してモデリングされ、建築設計と都市計画に重要な役割を果たします。GIS技術は都市計画、交通流最適化、公共サービス展開に必要な情報を提供し、同時に災害対応、環境変化、保護においても中心的な役割を果たします。',
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/uUm8my-ogGs?si=UNRDMDxCeI3ZWxyt',
      head: '',
      subHead: '地球形状の地理表現',
      desc: 'ORBROは、衛星画像、地形データ、地理情報システム（GIS）を統合して高解像度の3D地形モデルを構築します。このモデルは地形の正確な形状、傾斜、高さなどをリアルタイムで視覚化し、環境分析、都市計画、災害管理、構造計画など、さまざまな分野で重要な意思決定に貢献します。',
      dot: ''
    },
    {
      head: '',
      subHead: '地形およびインフラの表現',
      desc: 'さまざまなソースからインフラデータを収集し、これを3DモデリングとGIS技術でデジタル化します。この情報は都市および地域の開発、災害対応、安全計画に不可欠です。',
      dot: ''
    },
    {
      head: '',
      subHead: '建物情報の3D表現',
      desc: 'ORBROは、高解像度の写真、建築設計データ、3Dスキャン技術を使用して建物の外観と内部構造を精密にモデリングします。このモデリングは建築設計、都市計画、不動産開発、評価に重要です。',
    },
    {
      head: '',
      subHead: '都市計画と管理の改善',
      desc: 'ORBROのGIS技術は複雑な都市環境での計画と管理の改善に役立ちます。これにより、交通フローの最適化、公共サービスの配置、都市の拡張管理などに必要な情報が提供されます。',
    },
    {
      head: '',
      subHead: '災害対応と準備のサポート',
      desc: 'GIS技術を活用して自然災害や緊急事態への対応と準備をサポートします。地形とインフラデータを利用して危険地域を特定し、避難経路の計画、救援資源の配置などに重要な役割を果たします。',
    },
    {
      head: '',
      subHead: '環境変化と保護',
      desc: 'ORBROのGISは環境変化のモニタリングと分析に重要なツールです。この技術は生態系の変化、自然保護区の管理、環境保護政策の策定に寄与します。',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'この製品はUWB端末を作業者または機器に取り付け、リアルタイムで位置追跡を支援します。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }
    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/jp/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/jp/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      }
    ]
  }
}
