import { logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import digitaltwin from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

export default {
    helmet: '| Container Tracking',
    desc: 'Container tracking solutions use technologies such as RFID, GPS, or RTLS to track and monitor the real-time location and status of containers. This helps optimize transportation and storage processes in logistics and maritime industries, enhancing efficiency.',
    keywords: '',
    ogSite: 'Container Tracking | Digital Twin Specialist Platform, Orbro',
    ogUrl: 'https://orbro.io/en/blog/container-tracking',
    ogKey: 'Port, Harbor, Container, Container Tracking, Cargo Tracking, Port Management, Digital Twin, Location Tracking, Real-time Location Tracking, RTLS, Orbro',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkZ9sm3FiFSvojymITtsaWizbn8oKQcMwa4MM-G4G-nMKWBXq2F_q-sH0ACGnraTS707A9X3ogEPQhu0wxDNF0RP9I7pkAp7v0fdzFU2A7zFZtXef3IJ8c3TnYnagQc060ySKipQrcBuqB5tFq82ljVxI4ocqiownoure2nWdl7EzNUSFEQTe4NwflqjUms4AQTYBfwiwLp_4T-VPmoBsby9wKS0Lg20NkhGMM5-AKMQmD08MhEjPcjrWnYRjuhhHDJGB7yjVjJJFaC_8Hhzk-ZRhfMFoN0Werqf5YPXyy9n3DKLQuJv4nD4zYfYswWZj57am6eEuIWYdT6NU5VcdfGPuikrcaFNLVqonZVSVokqkKDjLIfFOLZ_jEHpWaiUpzJ4N2AwMlHjbypjZYkkcWPFuZRiOBOSnzAHvamvwRM9-ag0utSPD9dB9kqXM0gG9iIDVJJfeHxii387jBr7qtgEhEzpInbsnTxby1QQ4qGR91AptBAs-efDpG5dpw5aLNanaEtUnjmHLb2Kdx2BptiikLaz60I7qbKj7yt-eOYq030IZRzypf44xRyI-iAv0WxNE_El-NzX1Xl3T7aRWL_uiBNv-RML1K9gcbC5oEs2G9jGWd7uCAO4XzOtfu7tMNk8ENa9Tv6VxD9AqTdSu2HCUXxnqni5WajMTCT0r-C9pXr0DY7boiz-aBXmITz77v3-VtVXpMpHAiJnjaaQQWtsxCsp3JO643BiMaXQ69jdqcWU_tSVlUMBjVx3MwtdBkfpkH1UlOqrYL8lSfAryg4rOuYRlq0Wgd_HYQaWjg6ASY6GsYPQ_3jsk1c16AxmcmFKaYOvB95Una7UyNMZ2E1L0J2E9UDw5_NT0chrXIjUSCfCNoO3_z3t7PNeoNWyGhS5fkcxZ4iZEMJoSHPjO7_Ycj8fGf_TH8iAFx5kzutepCAa0OU4wVEZMJ7w26XLzJjNJY6Sv4e8ZtuB94rz3bII-npzvEPbPLe3-5_ZvinvmuEyUx8MBkei6-GyebkWDRO9A8GSt5cXN5ysTiKCLCgDz-2g_ZnlRafFzaYJM8zCrHpbL2doyBDLbLTr0XLnk_c4aKGCAMlWxt3na5UYPiexaCSHUgbRzHuZQDUNe6QzEVzuZ-ISeaiBOcDzj25ejL36EoeLeVduxzuvIs8XFa8rIO6sQOK0zSwNcAyeohI0waUzbyHJgEhNXe-vz0Ilc_AChIthH4taUvqT5x62hnV41jq-07G--rjyOeS2pG6PdQMkRqfteroupWK_rO_COOQCb_vscco04P8Jla0Lm7NfxsHyo6chs1rki0f_bjkv1NpW2QgqoX0-igIPd6ByzZOXoWVLlNXWkPkTOQKC3gdh8NRAep9vGbGAvQfRfyKFO5adEA0J-MyTdJPuq8gCH3oPCC3CLntoNARciwS31xLFRD-bF3x5iAb8rcK1VMSj4FKB6aTbMmF4J8ENEDRZ2axEdqIuW7mxt1PQl5aRzqvwPeG0rphIczuLwknwPZ-acGokkMnL7bY9ak1NZdEq4H0LeggE-5ryhwpPzWBQofP7HvD5zQx=w2010-h1214',
    ogType: 'website',
    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',
    title: 'Container Tracking',
    date: '2024-01-09',
    tag1: '#ContainerTracking',
    tag2: '#CargoTracking',
    tag3: '#LogisticsTracking',
    tag4: '#SmartLogistics',
    text: [
        {
            head: 'Container Tracking',
            subHead: '',
            desc: 'Containers play a vital role in safely transporting goods across the world. However, accurately tracking the location and status of containers remains a challenging task. The current global uncertainties add instability and unpredictability to related industries. Real-time tracking of container destinations and origins, and early detection of issues during transit are crucial in the logistics industry.',
            dot: ''
        },
        {
            head: 'Methods of Container Tracking',
            subHead: 'Application of RTLS Technology',
            desc: 'RTLS, short for Real-Time Location System, excels in accurately tracking the precise location of containers in various environments. While GPS is excellent for outdoor location tracking, it lacks accuracy in indoor environments or complex urban areas. RTLS overcomes the limitations of GPS by combining sensor networks, RFID, ultrasound, and wireless communication technology to accurately track container locations even within buildings.<br/><br/>Furthermore, RTLS technology seamlessly integrates with existing logistics infrastructure. By connecting with existing warehouse automation systems, transportation management software, and order processing systems, it allows real-time sharing of container location information and quick adaptation to automated processes, enhancing efficiency in logistics and transportation management, saving both manpower and time.',
            dot: ''
        },
        {
            video: digitaltwin,
            link: 'https://orbro.io/en/enterprise/logistics/asset',
            videoDesc: '(Orbro\'s Logistics Center Digital Twin)',
            head: 'Enhancing Container Security',
            subHead: 'Preventing Theft and Damage',
            desc: 'Security enhancement using RTLS involves real-time tracking of container locations to prevent theft and damage. Accurate tracking of containers in transit minimizes the risk of theft. Additionally, rapid response is possible in case unexpected movement is detected in specific areas, thereby enhancing security levels.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Security During Idle Times',
            desc: 'Monitoring the location of containers during idle times enables preparedness against security threats. Reinforcing security during idle times ensures safety even when the logistics system is inactive, enhancing container security and overall safety across the logistics network.',
            dot: ''
        },
        {
            head: 'Advantages of Real-Time Container Tracking',
            subHead: 'Optimizing Logistics Processes',
            desc: 'Real-time container tracking through RTLS plays a crucial role in optimizing logistics processes. By tracking container locations in real-time, logistics managers can increase efficiency at each stage. Real-time adjustment of logistics routes at departure, intermediate stops, and arrival helps shorten transportation times and minimize costs.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Predictive Analysis and Exception Handling',
            desc: 'Real-time information provides assistance in strategic planning based on predictive analysis and exception handling. Analyzing data allows the prediction of potential issues in the movement path and identifies areas for improvement to maximize efficiency in the logistics network. Additionally, real-time detection and quick response to exceptional situations minimize problem occurrence time.',
            dot: ''
        },
        {
            head: 'Efficient Resource Management',
            subHead: 'Optimizing Transportation Routes',
            desc: 'Efficient resource management using RTLS is achieved through optimizing transportation routes. Utilizing real-time location information of containers helps identify frequently used containers in specific areas, allowing for the planning of optimal transportation routes. This reduces fuel consumption and cuts transportation costs.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Optimal Placement of Logistics Assets',
            desc: 'RTLS is also utilized to optimize logistics asset management within warehouses. Using sensor technology to track logistics assets in real-time, efficient placement of specific goods or equipment in areas where they are needed can be achieved. This maximizes logistics space utilization and increases operational efficiency.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Cost Savings and Resource Optimization',
            desc: 'Real-time container tracking through RTLS helps effectively cut costs and optimize resources in transportation and logistics management. Optimizing transportation routes based on accurate location information and efficiently placing logistics assets reduce fuel costs and minimize idle resources.',
            dot: ''
        },
        {
            head: 'Future Outlook of Container Tracking',
            subHead: 'Integration of AI and Machine Learning',
            desc: 'Integration with artificial intelligence (AI) and machine learning is expected to strengthen further in the future. RTLS technology generates a vast amount of data, and AI can analyze this data to build predictive models. This allows the anticipation of unforeseen issues and finer optimization of logistics processes.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Ecosystem Expansion',
            desc: 'Container tracking is anticipated to be widely used not only in the current logistics industry but also in various industries such as manufacturing, healthcare, retail, and energy. This expansion is expected to integrate the logistics ecosystem further and expand opportunities for collaboration.<br/><br/>Container tracking technology is expected to positively impact the overall industry through continuous innovation, enhancing efficiency and stability in logistics and supply chain management. As RTLS application extends to various industries, more innovative and sustainable logistics and supply chain systems are expected to develop.',
            dot: ''
        },
        {
            head: 'Conclusion',
            subHead: '',
            desc: 'Predictive analysis and exception handling based on real-time information provide logistics managers with opportunities to respond quickly and continuously improve logistics processes. Additionally, optimizing transportation routes and logistics asset placement contribute to cost savings and increased efficiency in the logistics network.<br/><br/>Continuous technological innovation and the expanding use of RTLS technology are expected to lead the future logistics industry, improving efficiency and enhancing competitiveness. Furthermore, as the application of RTLS expands across various industries, more innovative and sustainable logistics and supply chain systems are anticipated to develop.',
            dot: ''
        }
    ],
    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'A product that assists real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.assetTracking.key1
            },
            {

            }
        ],
        solution: [
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/en/enterprise/logistics/asset'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/en/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/en/enterprise/manufacturing/asset'
            }
        ]
    }
}
