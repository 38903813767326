import { css } from '@emotion/react';

// Font
/*
import {
  PretendardBlackWOFF2,
  PretendardBlackWOFF,
  PretendardExtraBoldWOFF2,
  PretendardExtraBoldWOFF,
  PretendardBoldWOFF2,
  PretendardBoldWOFF,
  PretendardSemiBoldWOFF2,
  PretendardSemiBoldWOFF,
  PretendardMediumWOFF2,
  PretendardMediumWOFF,
  PretendardRegularWOFF2,
  PretendardRegularWOFF,
  PretendardLightWOFF2,
  PretendardLightWOFF,
  PretendardExtraLightWOFF2,
  PretendardExtraLightWOFF,
  PretendardThinWOFF2,
  PretendardThinWOFF
} from '@css/fonts/Pretendard/index';
*/

import { SCREEN_BREAKPOINT } from '@components/Grid/Grid.style';
export default css`
  
  @import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-dynamic-subset.min.css");

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
  }

  html,
  body,
  #root {
    position: relative;
    min-height: 100vh;
  }

  img {
    user-select: none;
    -webkit-user-drag: none;
  }

  br.desktop-hide {
    display: none;
  }

  br.tablet-hide {
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
      display: none;
    }
  }

  br.mobile-hide {
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
      display: none;
    }
  }
`;

/*

  @font-face {
    font-family: Pretendard;
    font-weight: 900;
    src: url(${PretendardBlackWOFF2}) format('woff2'), url(${PretendardBlackWOFF}) format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: Pretendard;
    font-weight: 800;
    src: url(${PretendardExtraBoldWOFF2}) format('woff2'), url(${PretendardExtraBoldWOFF}) format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: Pretendard;
    font-weight: 700;
    src: url(${PretendardBoldWOFF2}) format('woff2'), url(${PretendardBoldWOFF}) format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: Pretendard;
    font-weight: 600;
    src: url(${PretendardSemiBoldWOFF2}) format('woff2'), url(${PretendardSemiBoldWOFF}) format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: Pretendard;
    font-weight: 500;
    src: url(${PretendardMediumWOFF2}) format('woff2'), url(${PretendardMediumWOFF}) format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: Pretendard;
    font-weight: 400;
    src: url(${PretendardRegularWOFF2}) format('woff2'), url(${PretendardRegularWOFF}) format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: Pretendard;
    font-weight: 300;
    src: url(${PretendardLightWOFF2}) format('woff2'), url(${PretendardLightWOFF}) format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: Pretendard;
    font-weight: 200;
    src: url(${PretendardExtraLightWOFF2}) format('woff2'), url(${PretendardExtraLightWOFF}) format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: Pretendard;
    font-weight: 100;
    src: url(${PretendardThinWOFF2}) format('woff2'), url(${PretendardThinWOFF}) format('woff');
    font-display: swap;
  }

*/