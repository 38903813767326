export default {
  titleBanner: {
    subtitle: 'TWR | Two-Way Ranging',
    title: 'TWR 위치 추적 기술',
    description: '*TWR (Two-Way Ranging) 기술은 위치 추적 기술 중 하나로, <br/> 발신기와 수신기 간에 오가는 신호의 왕복 시간을 측정하여 거리를 계산하는 방식입니다.'
  },
  definition: {
    title: 'TWR 기술이란?',
    text: `TDoA는 여러 고정된 앵커가 모바일 객체의 위치를 결정하는 데 함께 작동하는 반면, TWR은 두 장치 간의 양방향 통신을 주로 사용합니다. 예를 들어 사람, 물건 또는 차량에 배치된 위치 추적 태그에 대한 실시간 거리 모니터링이 가능합니다. 이를 통해 자율적인 충돌 경고 시스템, 근접 감지 등의 양방향 통신 기반 응용 프로그램을 별도의 인프라 없이 구현할 수 있습니다. <br/> TWR에서는 두 기기 간에 거리를 실시간으로 모니터링하기 위해 위치 추적 태그와 같은 두 기기 간의 양방향 통신을 사용합니다. 따라서 부가적인 인프라 없이도 자율 충돌 감지 시스템, 근접 감지 등의 양방향 통신 기반 응용 프로그램을 구현할 수 있습니다. <br/> TWR에서는 두 기기가 서로 가까이 있으면, 두 기기는 서로 레인지링을 시작하여 거리를 결정합니다. 그런 다음 신호가 이동하는 데 걸리는 시간이 빛의 속도로 곱해져 상대적인 위치를 지속적으로 결정합니다. 이것은 종종 위치 인식 통신 및 서비스를 가능하게 합니다. <br/><br/> 거리를 측정하기 위해서는 세 가지 메시지를 교환해야 합니다: <br/><br/> `,
    list: [
      `· 태그와 같은 거리 측정 장치는 'Poll' 메시지를 근처의 태그나 다른 RTLS 노드에 보내어 TWR을 초기화합니다.`,
      `· 근처 장치는 "Poll"의 수신 시간을 기록하고 "Response" 메시지로 응답합니다.`,
      `· 태그는 응답 메시지를 받으면 응답 수신 시간을 기록합니다.`
    ],
    text2: '<br/> 최종 메시지의 수신 시간을 기반으로 태그는 ToF(비행 시간)와 서로의 거리를 결정할 수 있습니다.'
  },
  advantage: {
    title: 'TWR의 주요 장점',
    items: {
      realTime: {
        title: 'Real-Time',
        description: 'TWR 기술은 매우 낮은 latency(2-5 ms)와 높은 업데이트 속도(최대 100Hz)로 정확한 실시간 거리 측정정 어플리케이션을 가능하게 합니다.'
      },
      twoWay: {
        title: '다양한 Two-Way 기능 구현',
        description: '충돌 회피 시스템, 근접 감지 등 거리측정에 대한 기능을 제공할 수 있습니다.'
      },
      flexible: {
        title: '유연함',
        description: '추가 인프라 필요없이, 두 개의 장비 간의 P2P 범위 Application을 제공합니다.'
      }
    }
  },
}