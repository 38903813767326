import React from "react";
import { Trans } from "react-i18next";
import { StyledAdvantage, StyledAdvantageItemIcon } from "./Advantage.style";
import { HeadingH3white } from "@components/Text/Heading/Heading.style";
import { DisplayH2color } from "@components/Text/HeadingTagDisplay.style";
import { ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import { StyledAdvantageItem, StyledAdvantageItems } from "@pages/Enterprise/Technology/Common/Advantage/Advantage.style";

interface AdvantageProps {
  page: string,
  items: AdvantagePropsItem[],
}

export interface AdvantagePropsItem {
  icon: string,
  title: string,
  description: string
}

const Advantage = (props: AdvantageProps) => {
  // Props
  const { page, items} = props;

  // Translation

  return (
    <StyledAdvantage>
      <DisplayH2color size="small" color="#fff">
        <Trans i18nKey={ `${page}.advantage.title` }/>
      </DisplayH2color>

      <StyledAdvantageItems>
        { items.map((item, index) => (
          <StyledAdvantageItem key={`advantage-item-${index}`}>
            <StyledAdvantageItemIcon src={item.icon} alt="ORBRO Technologoy description icon"/>
            <Spacing pixel="16"/>
            <HeadingH3white size="xsmall"><Trans i18nKey={ `${page}.advantage.cards.${index}.title` }/></HeadingH3white>
            <Spacing pixel="16"/>
            <ParagraphColor size="medium" color={"#fff"}>
              <p>
                <Trans i18nKey={ `${page}.advantage.cards.${index}.desc` }/>
              </p>
            </ParagraphColor>
          </StyledAdvantageItem>
        )) }
      </StyledAdvantageItems>
    </StyledAdvantage> 
  )
}

export default Advantage;