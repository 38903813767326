import { ParagraphColor, Spacing, SpacingDTM } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { ConnectionImg } from "./Connection.style";
import { StyledGridContainer } from "@components/Grid/Grid.style";
import { DisplayH2color } from "@components/Text/HeadingTagDisplay.style";
import i18n from "@pages/i18n";
import { layerImg, layerEngImg } from "@assets/images/digitaltwin";



const Connection = () => {

  const [img, setImg] = useState<any>();
  useEffect(() => {
    if(i18n.language === 'ko'){
      setImg(layerImg);
    } else {
      setImg(layerEngImg)
    }
  }, [i18n.language])

  return (
    <>
      <StyledGridContainer>
        <DivAllCenter>
          <DisplayH2color color="#FFF" size="small">
            <Trans i18nKey={`digitaltwin.connection.title`} />
          </DisplayH2color>
          <Spacing pixel="8"/>
          <ParagraphColor color="#E0E0E0" size="large">
            <p>
              <Trans i18nKey={`digitaltwin.connection.desc`} />
            </p>
          </ParagraphColor>
          <Spacing pixel="32" />
          <StyledGridContainer>
            <ConnectionImg src={img} alt="ORBRO's Digital Twin Technology Image"/>
          </StyledGridContainer>
          <SpacingDTM d={"256"} t={"128"} m={"128"} />
        </DivAllCenter>
      </StyledGridContainer>
    </>
  )
}

export default Connection;