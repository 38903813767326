import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

   
  helmet : '',
  desc:'RTLS（Real-Time Location System）技術は、オフィス業界において場所や資産の管理を最適化し、従業員の活動をモニタリングすることで業務効率を向上させます。さらに、リアルタイムの位置追跡を通じて会議室の予約や業務状況の追跡など、ビジネスプロセスをスマートに管理することが可能です。',
  keywords:'RTLS、勤務管理、勤怠管理、労働管理、スマートオフィス、4次産業革命、位置測位、時間管理、事業の成長',

  ogTitle:'',
  ogDesc:'',
  ogSite:'2023年、スマートオフィスを構築する方法 :: ・オブ・でブログ',

  ogUrl : 'https://orbro.io/jp/blog/office',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZq-6QxpQF15gjoXPsuEITf_QOrCQngx-0mGH811amVcoMdPYM3ilqBy7Xoz0830SCxjlpjUuueuzKWtZiEjWuVteCxytua-WdJxpnvVidHfU4TRtkkXlw0xHxxUhNQxvbb5bAwoYWtF7PCka-Od-il6dCHS5WmHz1gttbrgzmv7WLmU9SQYkEs6xvUmw1kB84m4rT91EAavf9ENZ1GzxhJtFsM23wREpJzxhm0kBUbz6YMna9plYIlxFD6SfUcvqI3yduebDgFVrNqNzYuSg4MXNYrgd5besqzBTlYJ9q2D_uLNLNVin8CekS0u6HEWRqm2LjOdw5tZCbsATqf_KZTaVe7bB5zLAJv5WyYdCvS-WJtkrZis6Lr7ks9hAcVUZ6OAnu-oOnotcsNqBM6Slmh6SgL637kMZVCCL2ZRIV4M2zMmtkSgoIpl2EhAmsO_MbEAVPS6hqULxTA2MUhF7VknsXzpP4-gqDtg93jTb6M1jJSVcZMeg7b8eWXjGDAPvZ3DhZ52xs5CsgxNFiHcORnJ3r4L2BhkrUEHxdaIxtNcpYnVaJEaugtx1yYoSJhypYwIMxeuw56xgtDuhwSeXp_M47ijuLYAQH3D_e2y1-ze3pGxLp-ZUNSwEpsIQE75duXj6pGnykP_X86pC_9RvRqtGCauBh03Q5lbXcLi8uZU3feFRPIEBpcuqWSzOvc9OF-gGQQNAJvg_C-XlG7DU5noByHvqhzA_NWYyevnv_GdUqXP86pPL_HXVVFBq9tdJewYtLAEu9moGil4If2TKpgbLU2DqEFZbk53pVq6nNEEuvkIxnmmqT5FRECRIjoycCaKpClyWU6KflQXTh_ajli8XstUOEb9464PjQbEk7hur5AQveRex4wVq8OI5z2_fhrjlcMHuCN8Mi5xDJO1YX2bYT6HuGrN8A2M4qzLvoLqv9qpwf8LN-eddAFPRRxMvxKMEcV4vpwmPsrDe05R40Iwju-ALvmfJgcWEU9W4wFW3uCzbpa7bsF6sM2OYa8xC1DXZpwBcMgXHr1cy68Fgc3-Bz3bmlRBq_DoIxX_B-R-SCQRPmfELhiX_1bX6T21K1tX29iMtlobYwfW0Rm-I9toicT-RbtqAIkwLb9PKEt9Tnbxku321EMkp-qP3ZSVtC1rn5aBH9KG8v5s-FQrNpXvcFgBGJQ11ubG2TJVGND98TOtFmJODYe6lRFuvgA6v8vPcEASZfEZ9Ud5d26C6v-c9piIwRf8jrciHUSEW8heb3vtIngX_afHpGYI_yb63svGjNo9f12aCQxY8HTUu9YwnDCRFXbEiEuvHdqibatUmRWd1Ov15pW7Bfn7ERM721O-P4lkjWurLZ-7IQkd_kod2tNUENcQjT5UCMVuCNgvSmsjHm9jUuSmqjhz_N_E4gfYUImTrGgMJaW1hsQdmlIE3rNGnoSDj4L58a8B7S6f9hFQqRwyqvbeFfXP4Or8aFnxIzM-cJjgL3xcE7IIyza9Q-JN1MuqyK9eC_2nFrT7mx-hMjBW11zCF93GQ4zKO5ILybEfpIEFnZSMRUkkevmWEqohIRPhuI6uS1H2tm_Fg=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
    
  title:'2023年、スマートオフィスを構築する方法',
  date:'2023-05-21',
  tag1:'#スマートオフィス',
  tag2:'#RTLS',
  tag3:'#4次産業革命',
  tag4:'#勤務管理',

    
    // <br/>
    text : [
      {
        head : '',
        subHead :'',
        desc:'オフィスビルでの室内位置測位は多くの困難を引き起こす可能性があります。 <br/>オフィス、ホール、階数が多くなるほど訪問者は道に迷いやすくなり、建物を探すのに多くの時間を費やすことになります。  このような状況で、企業はよりスマートな地図を使用する必要があります。<br/>最新技術は建物に関する便利な探索を保障し、顧客及び訪問者の満足度を向上させます。',
        dot : ''
        
      },
      {
        head:"オフィス用室内ナビ",
        subHead :'',
        desc:'室内地図ツールを使用すると、オフィスナビゲーションの問題を効果的に解決できます。 <br/>デジタル地図でオフィスを素早く検索したり、イベントの準備のために会議室を予約することができます。<br/>もし、当該会議室が既に使用中であれば、これもリアルタイムで確認することにより、従来発生していた重複予約のようなことを防ぎます。 これは、誤って発生する可能性のある時間の無駄を防ぎます。<br/>このシステムは、建物内に設置された複数の特殊タグまたはビーコンを使用することに基づいています。 これらのタグは、あらかじめ設定された間隔で読み取り機が検出してサーバーに送信する無線信号を送信します。 <br/>アプリケーションが事前にインストールされたスマートフォンを持って建物に入る間、ユーザーはパスを構築し、他のタスクを実行できる対話型マップを取得します。<br/><br/>BLE、Wi-Fi及びUWBといった技術を使ってオフィス内部を探索することができます。<br/>位置を定義し、必要なスペースを見つけることから従業員の安全を確保し、詳細な分析を得ることまで、さまざまな機能を実行するのに役立ちます。<br/>ある統計によると、建物内部で道を探すのに困難がある場合、そうでない場合より作業時間効率が平均10~20%減少します。 訪問者と従業員は場所を見つけるのに多くの時間を費やす必要がありますが、これはイベントの遅延や遅く始まる原因になります。 <br/>室内位置マッピングは、これらの問題を最小限に抑えます。 ビジネスセンターインフラに実装される利点は次のとおりです。',
        dot : ''
        
      },
      {
        head:'',
        subHead:'リアルタイムの段階別探索',
        desc:'関心の場所(POI)に行く一番短くて便利な経路を作成することができます。また、ユーザーの位置を基準にお知らせを送ります。<br/>事務室マッピングソフトウェアは、建物やビジネスセンター内部の探索を単純化します。対話型指導を導入すれば、勤務時間を最適化し、職員らが日常的な業務を解決するうえで、安らかさを高めることができます。',
        dot:''
        
      },
      {
        head : '',
        subHead:"地図でオブジェクト検索",
        desc:'ほとんどの場合、一般的な平面図は静的イメージのように見えますが、対話型地図は平面拡張性を通じてユーザーの可能性を拡張できます。<br/>つまり、全階を見ることもでき、別の部屋を見ることもできます。 必要に応じて規模を変更することができ、家具の配置を含むいくつかの確実なオフィスを詳細に検討できます。<br/>拡張性は地図をより柔軟にし、地図上でオブジェクトを見つけやすくします。 ユーザーまたは従業員は、必要な部屋、会議場、トイレ、または技術室を見つけるためにモバイルアプリを使用するだけです。 <br/>経路を構築できる可能性と組み合わせて、どんなオフィスでも真の革新的な空間にします。',
        dot : ''
        
      },
      {
        head : '',
        subHead:"訪問者との社会的相互作用",
        desc:"多くのオフィスで最も一般的な問題の 1 つは、人々を見つけることに関連する困難です。特に、彼らが彼らの席にいない場合にそうです。 <br/>位置共有機能は希望するスタッフを探すのに十分です。 マッピングのおかげで、ユーザーはオフィスやフロアを探して走り回る必要がありません。 このシステムは社会的相互作用をかなり単純化し、時間を節約します。",
        dot : ''
        
      },
      {
        head : '',
        subHead:"制限区域モニタリング",
        desc: `多くの企業では、室内マッピングは制限区域を監視する効果的なツールになります。 これらの場所は、ドキュメント、実験室、およびその他の類似の前提がある保管所になる可能性があります。 <br/>管理者は室内地図技術を使用して誰がこのような区域に出入りするのかを簡単に測位し、無断出入りを防止することができます。<br/>このシステムは、人々に制限区域へのアクセスについて知らせることができます。 これ以上標識を立てなくても大丈夫です。 <br/>制限区域はアプリケーションで'アクセスできない'と表示され、システムは従業員にその区域を入力しないよう警告します。`,
        dot : ''
        
      },
      {
        head : '',
        subHead:"会議室シェア分析",
        desc:'このプラットフォームを使用して、オフィスおよび会議場のシェアに関するデータを取得できます。 システムは継続的な情報収集を行い、リアルタイムで実際のデータを提供します。 <br/>ビーコンまたはタグは、作業場にいるユーザーを知らせたり、部屋が予約されているかを表示することができます。',
        dot : ''
        
      },
      {
        head : '',
        subHead:"安全管理",
        desc:"人々の適時避難を保障するための非常事態に関する情報を提供します。 一部の客室は防音がしっかりしていて、スタッフがアラームを聞けない場合があります。 しかし、火災のような事故が発生した場合、彼らはモバイル アプリケーションを使用して情報を取得します。<br/>以降、システムはその場所から近くのスタッフを見つけて現場に案内したり、問題を解決するのに役立ちます。<br/>ソーシャルディスタンス。 新型コロナウイルス感染症のパンデミックの期間中、従業員と訪問者は感染を避けることができます。 人々の間の距離を注意深くモニタリングできるからです。<br/>オフィス機器が建物の外に出るのを測位することもできます。 装備が建物を離れると、直ちにこの事実を知らせてくれます。",
        dot : ''
        
      },
      {
        head : '',
        subHead :'',
        desc :'',
        dot : ''
        
      },
    ],
  
    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc: 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/jp/enterprise/construction/asset'
        }, 
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/jp/enterprise/construction/safety'
        },
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/jp/enterprise/logistics/asset'
        }
      ]
  
    }
  
  
  }