import { ptzThumb } from "@assets/images/main/camera";

import * as banner from "@src/pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

    home : {
        title : 'ORBRO | ORBRO, 数字转型的开始',
        key :'室内定位追踪, 室内GPS, 实时定位系统, 资产管理, 资产追踪, 人流分析, 实时位置系统, ',
        desc : '了解ORBRO的各种产品',
        img : '',
        url : 'https://orbro.io/cn/',
    
    },
    
    solution : {
    
        constructionAsset : {
            title : '建筑材料定位与管理 | ORBRO, 数字转型的开始',
            key :'建筑材料管理, 建筑工地管理, 材料管理, 材料追踪, 物品追踪, 工地追踪, 室内定位追踪, 资产管理, 工人安全管理, 劳动安全与赔偿法, ORBRO',
            desc : '准确的材料管理最终将提高生产力。材料管理是建筑工地上的重要组成部分，可以减少资源浪费、保持质量，并对安全产生影响。',
            img : banner.constructionAsset.banner,
            url : 'https://orbro.io/cn/enterprise/construction/asset',
        },
    
        constructionSafety : {
            title : '工人安全管理 | ORBRO, 数字转型的开始',
            key :'劳动者安全, 劳动者安全管理, 安全管理, 工人安全, 建筑安全, 工地安全, 室内定位追踪, 资产管理, 工人安全管理, 劳动安全与赔偿法, ORBRO, 实时定位系统, 实时定位, 劳动者追踪, 定位, 室外定位',
            desc : '工人的安全是最重要的。在高风险环境中，不保证工人的安全不仅会影响生产力，还可能导致法律责任。而安全的建筑工地对于公司形象和利益相关者的信任也起着重要作用。',
            img : banner.constructionSafety.banner,
            url : 'https://orbro.io/cn/enterprise/construction/safety',
        },
        constructionEquipment : {
            title : '重型设备管理 | ORBRO, 数字转型的开始',
            key :'建筑重型设备管理, 建筑设备管理, 重型设备管理, 重型设备维护, 吊车管理, 建筑重型设备, 推土机管理, 挖掘机管理, 实时定位, 室外定位, 实时定位系统',
            desc : '在建筑工地上，重型设备是核心要素。因此，适当的维护和事故预防是必要的。如果正确管理重型设备，可以在保持安全的同时降低成本并显著提高现场生产力。',
            img : banner.constructionEquipment.banner,
            url : 'https://orbro.io/cn/enterprise/construction/equipment',
        },
    
    
        logisticsAsset : {
            title : '库存定位与管理 | ORBRO, 数字转型的开始',
            key :'库存管理, 仓库资产管理, 仓库库存管理, 仓库库存追踪, 库存定位, 室内GPS, 室内定位追踪, 实时定位系统, 仓库管理, 资产管理, 仓库物品管理, 库存追踪, 实时定位',
            desc : '库存管理是支持供应链的效率和顺畅运营的重要部分。通过实时了解库存的位置，可以有效监控库存水平并优化库存移动。例如，根据物品的受欢迎程度安排库存并优化库存周转率，从而帮助节省成本并防止库存不足或过剩。',
            img : banner.logisticsAsset.banner,
            url : 'https://orbro.io/cn/enterprise/logistics/asset',
        },
    
        logisticsSafety : {
            title : '仓库工人安全管理 | ORBRO, 数字转型的开始',
            key :'仓库安全, 仓库安全管理, 仓库工人安全管理, 仓库工人管理, 室内GPS, 工人安全, 仓库管理, 安全管理, 仓库劳动者安全, 实时定位系统, 定位, 实时定位, 室内定位追踪, 工人追踪',
            desc : '工人的安全是最重要的。在高风险环境中，不保证工人的安全不仅会影响生产力，还可能导致法律责任。而安全的物流场所对于公司形象和利益相关者的信任也起着重要作用。',
            img : banner.logisticsSafety.banner,
            url : 'https://orbro.io/cn/enterprise/logistics/safety',
        },
    
    
        officeAsset : {
            title : '资产管理 | ORBRO, 数字转型的开始',
            key :'办公用品管理, 资产管理, 办公室资产管理, 办公室库存管理, 室内GPS, 办公室管理, 库存管理, 办公室管理, 智能办公室, 室内定位追踪, 库存追踪, 资产追踪, 实时定位系统',
            desc : '良好的资产管理将减少时间和金钱损失，确保业务的顺利运营，提供明确且快速的资产管理。',
            img : banner.officeAsset.banner,
            url : 'https://orbro.io/cn/enterprise/office/asset',
        },
    
    
        medicalPatient : {
            title : '患者定位 | ORBRO, 数字转型的开始',
            key :'患者定位, 患者管理, 感染者追踪, 医院管理, 智能医院, 室内GPS, 室内定位追踪, 实时定位系统, 患者追踪, 病房管理, 患者安全, 患者监测, 实时监测, 室内定位',
            desc : '医疗设施中存在需要特别管理的患者。您无需直接进行24小时监控。我们实时跟踪患者的位置。',
            img : banner.medicalPatient.banner,
            url : 'https://orbro.io/cn/enterprise/medical/patient',
        },
        medicalWaste : {
            title : '医疗废物管理 | ORBRO, 数字转型的开始',
            key :'医疗废物管理, 医疗事故管理, 防止医疗事故, 医疗事故, 室内GPS, 废物管理, 废物追踪, 室内定位追踪, 实时定位, 定位, 资产管理, 医院资产管理, 医院库存管理, 实时监测, 废物监测, 医院安全, 实时定位系统',
            desc : '医疗废物管理是在医疗设施中处理各种类型废物的必要工作，它安全且适当地处理废物，以预防环境污染、最小化感染风险并遵守法律法规，为人类健康和资源保护做出贡献。通过跟踪医疗废物的实时状态和位置，可以预防医疗事故，提供安全的医疗服务。',
            img : banner.medicalWaste.banner,
            url : 'https://orbro.io/cn/enterprise/medical/waste',
        },
    
    
        manufacturingProcess : {
            title : '实时生产管理 | ORBRO, 数字转型的开始',
            key :'制造过程管理, 制造过程, 制造设施管理, 库存定位, 室内GPS, 实时定位, 过程管理, 过程监控, 实时监控, 定位, 智能制造, 生产线监控, 室内定位追踪, 过程分析, 实时定位系统, 制造设施资产管理',
            desc : '您可以一目了然地了解制造设施的运行情况并进行管理。通过准确和客观的数据，可以改进工艺线并提高效率。这是制造业的核心。',
            img : banner.manufacturingProcess.banner,
            url : 'https://orbro.io/cn/enterprise/manufacturing/process',
        },
        manufacturingAsset : {
            title : '实时库存管理 | ORBRO, 数字转型的开始',
            key :'制造库存管理, 库存管理, 智能制造, 过程监控, 室内GPS, 过程管理, 原材料管理, 监控, 实时监控, 实时定位, 定位, 实时定位系统, 生产线监控, 过程分析, 实时定位系统',
            desc : '制造设施中有许多库存，如原材料和半成品。妥善管理它们对于时间和经济来说都非常重要。因此，这是影响制造业效率的重要因素。',
            img : banner.manufacturingAsset.banner,
            url : 'https://orbro.io/cn/enterprise/manufacturing/asset',
        },
    
        retailExhibition : {
            title : '展览观众管理和分析 | ORBRO, 数字转型的开始',
            key :'访客路径分析, 访客分析, 观众路径分析, 观众分析, 路径分析, 室内定位, 室内GPS, 实时定位, 访客分析, 访客识别, 定位, 实时定位系统',
            desc : '准确而客观的数据可以推动业务的增长。最大限度地减少浪费的人力和资金，提供智能化的展览形象，并提供差异化的运营。',
            img : banner.retailExhibition.banner,
            url : 'https://orbro.io/cn/enterprise/retail/exhibition',
        },
    },
    

    

}