import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet: '',
  desc: 'Enhance passenger experience and improve operational efficiency through real-time tracking of public transportation. ORBRO provides real-time location, stop information representation for buses and subways, traffic flow analysis and optimization, safety management and monitoring, app and service integration.',
  keywords: 'Digital Twin, Digital Twins, 디지털 트윈, gis, 디지털 시티, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - Connecting Bus and Subway Information :: ORBRO Blog',
  ogUrl: 'https://orbro.io/en/blog/digitaltwin-traffic',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twin - Connecting Bus and Subway Information',
  date: '2023-12-26',
  tag1: '#DigitalTwin',
  tag2: '#TrafficSituation',
  tag3: '#DigitalCity',
  tag4: '#VirtualReality',

  // <br/>
  text: [
    {
      head: 'Digital Twin - Connecting Bus and Subway Information',
      subHead: '',
      desc: `ORBRO provides a service that enhances passenger experience and improves operational efficiency through real-time tracking of public transportation. For this purpose, it offers real-time location updates for buses and subways, representation of stop information, traffic flow analysis and optimization, safety management and monitoring, as well as integration with apps and services.<br/><br/>ORBRO's GPS and tracking technology precisely delivers real-time locations of buses, enabling passengers to access up-to-date information on public transportation operations. Real-time monitoring of subway locations provides passengers with accurate operation information, enhancing the reliability of public transportation use.<br/><br/>Providing stop information enhances the convenience of public transportation use, and traffic flow analysis and optimization contribute to reducing traffic congestion and shortening passenger waiting times. Additionally, safety management and monitoring prioritize passenger safety and support the smooth operation of public transportation systems.<br/><br/>Ultimately, ORBRO enhances the experience for public transportation users and simultaneously improves the efficiency of public transportation systems, contributing to the enhancement of the urban transportation environment.`,
      dot: ''
      
    },
    {
      youtube: 'https://www.youtube.com/embed/tK8f-aMRh-g?si=Q6CVGXUz5ZTjLv-4',
      head: '',
      subHead: 'Real-time Tracking of Bus Location',
      desc: 'ORBRO provides real-time location for buses using GPS and tracking technologies. This enables passengers to predict accurate arrival times and helps transportation managers enhance operational efficiency.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Real-time Location Monitoring of Subways',
      desc: 'Real-time monitoring and display of vehicle locations within the subway system provide passengers with accurate travel information. This enhances operational efficiency and enables swift response in emergency situations.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Stop Information Representation',
      desc: 'Detailed information for each stop and station is displayed on a digital map, enhancing the convenience of public transportation use by providing passengers with stop locations, schedules, and service information.',
    },
    {
      head: '',
      subHead: 'Traffic Flow Analysis and Optimization',
      desc: 'Utilizing real-time location data to analyze and optimize traffic flow contributes to reducing traffic congestion and shortening passenger waiting times.',
    },
    {
      head: '',
      subHead: 'Safety Management and Monitoring',
      desc: 'Enhancing safety management and monitoring systems within public transportation ensures passenger safety and enables swift responses to risks during operation.',
    },
    {
      head: '',
      subHead: 'App and Service Integration',
      desc: 'Integrating public transportation information with mobile apps and various digital services provides passengers with better accessibility and convenience for information.',
    },

  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that assists real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/en/enterprise/construction/asset'
      }, 
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      }
    ]


  }

}
