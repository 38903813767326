import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4";

export default {
  helmet : '',
  desc : 'UWB(Ultra-Wideband)를 활용한 RTLS 기술은 매우 넓은 주파수 대역폭을 이용하여 높은 정확도로 실시간 위치 추적을 가능하게 합니다. 이 기술은 센서와 태그 간의 거리를 정밀하게 측정하여 실내 및 실외 환경에서 위치 정보를 제공합니다.',
  keywords : 'UWB RTLS, 실내위치파악, 자산추적, 비접촉결제시스템, 시마트홈, 건강관리, 자동차안전, UWB, RTLS, GPS, BLE, 디지털트윈',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'UWB란 무엇이며, 비지니스에 어떻게 도움이 될까요 ? | 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/uwb',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EObeDoLPWDY0m4J6RG5ivUvly0sFp1k2N8pJ6vwHvfEotnTR-c97QjquFgftQfhSf8tMXrczrcxhZelBBXIc0FoSa6ZZgE8na0u8s66yPDrdq0UrGeG-BtDEHMU8P-DPTgUQcwgdX_770Njyx6SNDF4Bchooan8k2Ghgf5DDf0A-rOW2q8-J9DNpNr9pjZRVCg6601CXYmQ-4C_DWZQrQPOpbyvi0Utlc-Az8WhS41m0SPy_AVoqe-fxEuq53t-bNal_-D9ko7jhgr3HHIaTqLbFKWfu5W_yVuf3-clrf0CtlwYyWeQTD4-blr1Vt6UMEpe6g0xh8vMdEG9KwIOY0We-an0hHPhqHco-cxMhZqnXuKuhdRngKsVJnmNzhoAGDcEovW0xEdbwNGGnaXf9HgDuwEyz0O0hBJ1VdMLVrZeF3yG2w0uOYvT1w1CGsNnOvF1HQ2O1tBB0OUXGi7kOuhASd34xinm8ZUoaopcdehwOHmFkiWhblS3O86GDhcSHi64o5o9yPXeKJKcg3aX4M1WKjSD4u-v_h7Cz7j2Ew_QRGSJUkV0ZqKIYM-LnidAHxkTwv5b0vujpTQTybOQctctEpk07QqA7jx3zz1AbtSgtB4SMS3BVYQ-LDQPwmRnkVEi15VK5I33OFLSw8UAoibRcC2y68nu5w0ZmwZ3yZPgRbGUhwVnNrbzmmWjnhyW6agO6gnb3iAAYmxGgUZ0Bxe6sU3F_hRAOk3K5adG-GTihhhRZ6Ny-1YfLjwBN2XWS9CQmHOiNL2XKyUmDIRFnYf6My62MYUpTGkqD3QGMb5QWsmjsVvMDeYmPK2pRmdXC2VuzOpXv1I32Uij5MECw_kBbt9J59P_jtayKMJb7MPa9mZTsvEtK68fS4kpKPiTqgmY6Fpu81ySeLX6iMqgw2mW8BqPuEyOz5VlDQR8kXkleyutpFfgwMLd0gDe-6V-1w-4lb3u6ccuCTShoIl4rzEil0_RJbe2Oe3td0WW3KYtfT_1Oeihe7jPGmD0JZrOSemw8nMa-oWOjkMSMFpCBJOfSPxpx5I42Jupgd2vOsaIezrcSGCGzHpoXM2-ryc14nPGOUDOc-hA1eFFGqVgCGUQN2dUMHISspskEVJBPI7647sxsyCnd0IUJLN5NSHR_sHivHXsX3SAq9g2Tk3WwbdpT8PW2l9hw6FfuxvjBv8DfsEILwnTGOQQMQ8iiQIZLz9QyCq1OEEDethUDWNsTZipSUMrcWUwpyNxE5586r4DxKO0R_AIGUIxL7kAAwP5cRdFP7CxE1UBRhidxzIVJeIaoHrk-OtutFpM4iFhXD6VY_zZRNDnT74KvBOONaMhB3Lhc801EddUkWf0-35rZksJbpbnWR-H_yNKiCBv8UP9iHJoUWB84wFs2dUNq8sK7fy316q-Coz-Yc58zY_S07R8mK_SjMcdJPDpUc-XzfMyXvXGhYWScpZjP766CDtHOd_ArlWxMsLBupquNSUuHJJ6FWmIoUzq31LSeNqPvc7MH_ywbqQd_efx2p8c73E_NvkVCJP8OYYT8IP8iUh9v7tRMD0-JBjW6yzXknRIpicPYTQ=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
  
  title: 'UWB란 무엇이며,<br/>비지니스에 어떻게 도움이 될까요 ?',
  date : '2023-04-25',
  tag1 : '#UWB',
  tag2 : '#산업자동화',
  tag3 : '#자산추적',
  tag4 : '#실내위치파악',
  
  // <br/>
  text : [
    {
      head : 'UWB란 ?',
      subHead :'',
      desc :'UWB (Ultra-wideband)란 매우 높은 주파수에서 낮은 전력 소비로 작동하는 무선 통신 프로토콜입니다. Bluetooth 또는 Wi-Fi와 같은 다른 무선 통신 프로토콜과는 달리, UWB는 전자기 에너지의 짧은 펄스를 사용하여 데이터를 전송합니다. 이러한 펄스는 보통 몇 나노초밖에 지속되지 않지만, 수백 메가헤르츠에서 여러 기가헤르츠의 매우 넓은 대역폭을 갖습니다. <br/><br/>UWB의 넓은 대역폭은 빠르고 정확한 대용량 데이터 전송이 필요한 산업 자동화, 로봇 및 자율주행차량과 같은 응용 분야에 이상적입니다. 또한 UWB는 두 물체 간 거리를 매우 정확하게 측정할 수 있어 위치 기반 응용 프로그램에서 유용합니다.',
      dot : ''
      
    },
    {
      video : video,
      link : 'https://orbro.io/enterprise/medical/patient',
      head : '비즈니스에 UWB가 어떻게 도움이 될까요 ?',
      subHead :'',
      desc :'UWB는 여러 가지 잠재적인 응용 분야가 있어서 다양한 방식으로 사업을 도울 수 있습니다. 다음은 UWB의 가장 유망한 응용 분야 중 일부입니다.',
      dot :''
    },
    {
      head : '',
      subHead :'실내 위치파악 및 자산 추적',
      desc : 'UWB 기술은 건물 내나 다른 실내 공간에서 자산의 위치를 정확하게 추적하는 데 사용될 수 있습니다. 이는 재고 관리, 도구 추적, 도구 제어, 공정 모니터링, 차량 추적 및 정확한 위치 데이터가 필요한 기타 응용 분야에 유용합니다. 예를 들어, 창고를 운영한다면 UWB를 사용하여 개별 항목의 위치를 실시간으로 추적하여 재고를 찾고 관리하기가 더 쉬울 수 있습니다.',
      dot : {}
    },
    {
      head : '',
      subHead :'비접촉 결제 시스템',
      desc : 'UWB 기술은 기존 솔루션보다 더 안전하고 신뢰성 있는 비접촉 결제 시스템을 구현하는 데 사용될 수 있습니다. 모바일 기기와 결제 단말기 간에 결제 데이터를 UWB를 이용해 전송함으로써 결제가 안전하게 이루어지고 사기나 불법적인 접근이 없도록 보장할 수 있습니다.',  
    },
    {
      head : '',
      subHead :'스마트 홈 자동화',
      desc :'UWB 기술은 기존 솔루션보다 정확하고 신뢰성 높은 스마트 홈 자동화 시스템을 만드는 데 사용될 수 있습니다. 집 안에서 장치의 위치를 결정하기 위해 UWB를 사용하면, 특정 위치나 움직임에 반응하는 더 정교한 자동화 규칙을 만들 수 있습니다. 예를 들어, 누군가 방에 들어오면 불이 켜지고, 나갈 때는 불이 꺼지도록 규칙을 만들 수 있습니다.',  
    },
    {
      head : '',
      subHead :'건강 관리 모니터링',
      desc :'UWB 기술은 의료 시설 내 환자의 위치와 움직임을 모니터링하여 필요한 치료를 받고 있는지 확인하는 데 사용될 수 있습니다. 예를 들어, UWB는 병원 내에서 환자의 움직임을 추적하여 필요한 도움이 필요한 환자를 빠르게 찾을 수 있도록 도와줄 수 있습니다.',  
    },
    {
      head : '',
      subHead :'자동차 안전',
      desc :'UWB 기술은 차량의 위치와 움직임에 대한 더 정확한 데이터를 제공하여 자동차 안전 시스템에서 사용될 수 있습니다. 이는 충돌 회피와 같은 응용 분야에서 유용하며, UWB를 사용하여 두 차량 간 거리를 정확하게 판단하고 운전자에게 잠재적인 위험에 대해 경고할 수 있습니다.',  
    },
    {
      head : '',
      subHead :'산업 자동화',
      desc :'UWB 기술은 산업 자동화 응용 분야에서 장치 간 더 정확하고 안정적인 통신을 가능하게 할 수 있습니다. 예를 들어, UWB는 로봇과 다른 산업용 장비 간 데이터를 전송하여 그들이 더 효율적으로 협력할 수 있도록 할 수 있습니다.',  
    },
    {
      head : '결론',
      subHead :'',
      desc :'UWB 기술은 실내 위치 추적 및 자산 관리부터 스마트 홈 자동화 및 의료 모니터링까지 다양한 분야에서 사용할 수 있는 강력한 도구입니다. 높은 정확도와 신뢰성으로 인해 정확한 위치 데이터 또는 고속 데이터 전송이 필요한 응용 분야에서 이상적인 선택지입니다.',  
    },

  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      }
    ]


  }


}