import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";

export const RequestPage = styled.div`
  padding-top : 96px;
  padding-bottom : 96px;

  text-align : center;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding-top : 64px;
    padding-bottom : 64px;
  }

`;



export const RequestBox = styled.div`
  width : 100%;
  display : flex;
  flex-direction : row;
  gap : 32px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    flex-direction : column;
    gap : 16px;
  }


`;


export const StyledDownloadPolicyBox = styled.div`
    width : 100%;
    display : flex;
    align-items : center;
    gap : 2px;
    text-align : center;
    justify-content :center;
`;



export const RequestInputBox = styled.div<{inputValue : boolean}>`
  width: 100%;
  height: 56px;
  border-radius : 8px;

  padding : 8px 12px;

  background-color : #FFFFFF;
  align-items : center;

  border: ${({ inputValue }) => (inputValue ? '1px solid black' : '1px solid rgba(0, 0, 0, 0.08)')};

 

`;

