import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledParagraph } from "@components/Text";
import React, { FC, useEffect, useState } from 'react';
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { Card23Page, CardBox, CardGap, CardLayout, CardTextBox, DescBox, InnerLabelBox } from "./Functions.style";
import { CommonState } from "@redux/reducers/commonReducer";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";
import { BorderBottomLine, HeadingColor, LabelColor, Spacing, SpanColor } from "@pages/CommonStyle/common.style";
import { LabelH3color } from "@components/Text/Label/Label.style";

interface Item {
  data: any;
  page: string;
}

const Functions: FC<Item> = ({ data, page }): React.ReactElement => {


  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const [layoutMaxWidth, setLayoutMaxWidth] = useState<any>(null);

  const min = [0, 2, 5];
  const max = [1, 4, 6];

  useEffect(() => {
    const handleResize = () => {
      setLayoutMaxWidth(window.innerWidth);
    }

    setLayoutMaxWidth(document.documentElement.clientWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <StyledGridContainer>
        <Card23Page>

          <DisplayH2 size="small">
            <Trans i18nKey={`${page}.functions.title`} />
          </DisplayH2>
          <Spacing pixel="16" />
          <StyledParagraph size="medium">
            <p>
              <Trans i18nKey={`${page}.functions.desc`} />
            </p>
          </StyledParagraph>
          <Spacing pixel="64" />


          {Array.from({ length: data.functions.line }).map((_, index) => (
            <>
              <CardBox key={index}>
                {data.functions.card.map((data, i) => (
                  <>
                    {i >= min[index] && i <= max[index] &&
                      <CardLayout w={layoutMaxWidth} img={data.img[layoutType]} index={data.size} >
                        <CardTextBox>
                          <LabelH3color size={"xsmall"} color={data.color ? '#BDBDBD' : '#818181'}>
                            <Trans i18nKey={`${page}.functions.card.${i}.tag`} />
                          </LabelH3color>
                          <Spacing pixel="4" />
                          {data.darkTitle ?
                            <>
                              <HeadingColor size="small" color={'#646464'}>
                                <p>
                                  <SpanColor color="#646464">
                                    <Trans i18nKey={`${page}.functions.card.${i}.darkTitle`} />
                                  </SpanColor>
                                  <SpanColor color="#FFF">
                                    <Trans i18nKey={`${page}.functions.card.${i}.lightTitle`} />
                                  </SpanColor>
                                  <SpanColor color="#646464">
                                    <Trans i18nKey={`${page}.functions.card.${i}.darkTitle2`} />
                                  </SpanColor>
                                  </p>
                              </HeadingColor>
                            </>
                            :
                            <HeadingColor size="small" color={data.color ? data.color : '#000'}>
                              <p>
                                <Trans i18nKey={`${page}.functions.card.${i}.title`} />
                              </p>
                            </HeadingColor>
                          }
                          <Spacing pixel="16" />
                          {data.label &&
                            <InnerLabelBox color={i===5 ? "#D4E2FC" : '#EAF1FD'}>
                              <LabelColor color={"#2A70F0"} size={"small"}>
                                <Trans i18nKey={`${page}.functions.card.${i}.label`} />
                              </LabelColor>
                            </InnerLabelBox>
                          }
                          {data.desc &&
                            <DescBox>
                              <LabelH3color size={"xsmall"} color={data.color ? '#BDBDBD' : '#818181'}>
                                <Trans i18nKey={`${page}.functions.card.${i}.desc`} />
                              </LabelH3color>
                            </DescBox>
                          }
                        </CardTextBox>
                      </CardLayout>
                    }
                  </>
                ))}
              </CardBox>
              <CardGap />
            </>
          ))}





        </Card23Page>

        <BorderBottomLine />
      </StyledGridContainer>

    </>
  )
}

export default Functions;