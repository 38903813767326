import { IconBell, IconBinocularsGray, IconChainLink, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { IconAccess, IconAlarm, IconWarn, IconZoom } from "@assets/images/main";
import { rtlsSystem2 } from "@assets/images/solution";
import video from "@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4";
import subVideo1 from "@src/assets/images/solution/constructionEquipment/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/constructionEquipment/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/constructionEquipment/video/sub/rtlsVideo3.mp4";

export default {
    banner: {
      title: '重機管理',
      desc: '建設現場では重機が重要な要素です。適切なメンテナンスと事故予防は必須です。重機を適切に管理することで、安全性を確保し、コストを削減し、現場の生産性を著しく向上させることができます。',
    },
    video: {
      title: '最もスマートな重機管理方法',
      desc: 'リアルタイム位置測位（RTLS）技術は、作業者が必要とする重機の位置をリアルタイムで把握できます。<br/>衝突事故を防ぎ、最適な配置により作業プロセスの効率と生産性を一段と向上させます。',
      play: video,
    },
    func: [
      {
        title: 'リアルタイム位置測位',
        desc: '重機の現在の位置をリアルタイムで把握します。そのため、効率的な配置が可能になり、時間管理が容易になります。',
        sub1: '高い精度の提供',
        icon1: IconRulerGray,
        sub2: '迅速なデータ連携',
        icon2: IconShareGray,
        video: subVideo1,
      },
      {
        title: '近接警告システム',
        desc: '重機の操作中に人為的なミスやシステムの障害による事故は致命的な結果を引き起こすことがあります。特に人との接触事故は非常に危険です。リアルタイムでモニタリングし、事故が発生した場合には即座に対応できます。',
        sub1: '危険な資産の指定',
        icon1: IconWarningGray,
        sub2: '近接距離基準の設定',
        icon2: IconRulerGray,
        video: subVideo2,
      },
      {
        title: '盲点ハザード検知',
        desc: '重機オペレーターの視界には盲点が生じることがあります。疲労の高い作業は人の油断を招きやすい環境を作ります。そのため、盲点を把握し、人との衝突や危険が生じる可能性を防ぎ、安全な作業現場を維持します。',
        sub1: '自由な範囲の指定',
        icon1: IconZoneGray,
        sub2: '複数のゾーン設定',
        icon2: IconTagGray,
        video: subVideo3,
      },
    ],
    subfunc: {
      title: 'さまざまな機能',
      desc: '重機は安全性とコストの両面で重要な存在です。安全基準を破る重機の使用は事故を引き起こし、非効率な作業方法はコストと人的リソースの浪費につながります。安全で経済的な現場を実現するために、ORBROは以下の機能を提供しています。',
      data: [
        {
          icon: IconChartRadius,
          title: 'レポートと分析',
          desc: 'フィールドデータを基にレポートと分析資料を作成できます。',
        },
        {
          icon: IconBell,
          title: 'リアルタイムアラーム機能',
          desc: '重機に異常状況が発生した場合、アラームを送信し、即座に対処できます。',
        },
        {
          icon: IconRoute,
          title: '経路履歴の照会',
          desc: 'フィールドで重機が移動した経路を照会し、データ化することができます。',
        },
        {
          icon: IconDashboard,
          title: '状況の把握',
          desc: '特定の重機の現在の移動状況を測位します。',
        },
        {
          icon: IconWarning,
          title: '逸脱の検知',
          desc: '重機が所定の範囲から逸脱したり、誤った場所に移動したりすることを検知します。',
        },
        {
          icon: IconSearch,
          title: '経路パターンの分析',
          desc: 'フィールドでの重機の使用状況とタイミングを分析できます。',
        },
        {
          icon: IconChainLink,
          title: 'データ統合',
          desc: 'RFID、IoT、セキュリティ、ビデオなどのシステムと統合して効率的に活用できます。',
        },
        {
          icon: IconSecurity,
          title: 'データセキュリティ',
          desc: '高いデータセキュリティレベルを維持し、信頼性の高いソリューションを提供します。',
        },
      ],
    },
    system: {
      title: 'システム構成',
      img: rtlsSystem2,
    },
    productTitle: '製品構成',
    product: [
      {
        tag: 'carPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'constructionAsset',
      },
      {
        data: 'constructionSafety',
      },
      {
        data: 'blogRTLS',
      },
      {
        data: 'blogMedical',
      },
    ],
  }
  