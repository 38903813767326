import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconUserCheck, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { IconTemp } from "@assets/images/main";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/medicalWaste/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/medicalWaste/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/medicalWaste/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/medicalWaste/video/sub/rtlsVideo3.mp4";


export default {

    banner: {
        title: '医疗废弃物管理',
        desc: '医疗废弃物管理是医疗设施中处理各种类型废弃物的重要工作，旨在安全、适当地处理废弃物，预防环境污染，最小化感染风险，遵守法规，为保护人体健康和资源做出贡献。通过实时追踪医疗废弃物的状态和位置，可以预防医疗事故，提供安全的医疗服务。',

    },

    video: {
        title: '最可靠的医疗废弃物管理方法。',
        desc: '实时定位(RTLS)技术可以追踪医疗废弃物的状态和位置。<br/>遵守安全规定，实现<strong>准确且可靠的医疗废弃物管理</strong>。',
        play: video
    },

    func: [
        {
            title: '实时废弃物状态确认',
            desc: '实时追踪医疗废弃物的类型和数量，预测和管理医疗废弃物的产生量和处理时间。',
            sub1: '高精度提供',
            icon1: IconRulerGray,
            sub2: '快速数据交互',
            icon2: IconShareGray,
            video: subVideo1

        },
        {
            title: '实时移动状态确认',
            desc: '医疗废弃物需要特别管理，通过实时追踪与医务人员或患者的接触，提前预防医疗事故，增加患者信任感。',
            sub1: '风险资产指定',
            icon1: IconWarningGray,
            sub2: '应对方式设置',
            icon2: IconBellGray,
            video: subVideo2
        },
        {
            title: '自动进出库',
            desc: '自动进出库系统通过提高工作效率，减少人为错误，增强实时应对能力，确保安全处理，遵守规定，实现医疗机构中有效且安全的废弃物管理。',
            sub1: '高精度提供',
            icon1: IconRulerGray,
            sub2: '快速数据交互',
            icon2: IconChainLinkGray,
            video: subVideo3
        },


    ],



    subfunc: {

        title: '多种功能',
        desc: '医疗废弃物管理工作不容有失。即使小的疏忽和错误也可能导致严重的医疗事故。因此，需要非常精细的管理，确保安全性。<br/>Orbro提供多种功能，以建立安全高效的医疗设施。',

        data: [
            {
                icon: IconChartRadius,
                title: '报告与分析',
                desc: '基于废弃物的进出库数据生成统计报告和分析资料。',
            },
            {
                icon: IconBell,
                title: '实时警报功能',
                desc: '发生高风险医疗废弃物与患者接触的情况时，发送警报，可以立即应对。',
            },
            {
                icon: IconRoute,
                title: '废弃物动线查询',
                desc: '追踪医疗废弃物的路径，预防安全事故。',
            },
            {
                icon: IconDashboard,
                title: '状态了解',
                desc: '实时了解医疗废弃物存放仓库的废弃物状态。',
            },
            {
                icon: IconTemp,
                title: '温湿度等环境分析',
                desc: '监测医疗废弃物仓库的内部环境因素，及时发现异常。',
            },
            {
                icon: IconUserCheck,
                title: '工作效率提升',
                desc: '通过自动进出库系统，提高工作效率和减少人为错误。',
            },
            {
                icon: IconChainLink,
                title: '数据联动',
                desc: '与RFID、物联网、安全、视频等系统连接，实现高效利用。',
            },
            {
                icon: IconSecurity,
                title: '数据安全',
                desc: '保持高数据安全水平，提供可信赖的解决方案。',
            },


        ],
    },

    system: {
        title: '系统构成',
        img: rtlsSystem
    },

    productTitle: '产品配置',
    product: [

        {
            tag: 'assetPackage'
        },
        {
            tag: 'workerPackage'
        },
    ],


    contents: [
        {
            data: 'medicalPatient'
        },
        {
            data: 'officeAsset'
        },
        {
            data: 'blogOffice'
        },
        {
            data: 'blogMedical'
        },

    ]

}

