import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { IconAccess, IconAlarm, IconWarn, IconZoom } from "@assets/images/main";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/constructionAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo4.mp4";
import subVideo5 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo5.mp4";
export default {
    banner: {
      title: 'Construction Asset Tracking and Management',
      desc: 'Accurate material management ultimately enhances productivity. Effective material management is a crucial part of construction sites, reducing resource waste, maintaining quality, and even impacting safety.',
    },
    video: {
      title: 'How to Manage Materials Accurately and Efficiently',
      desc: 'Real-Time Location System (RTLS) technology provides real-time tracking of material location, significantly reducing the time spent searching for materials. With the ability to manage quantities, RTLS brings a new level of productivity.',
      play: video,
    },
    func: [
      {
        title: 'Material Quantity Management and Tracking',
        desc: 'Accurate tracking of material quantity is essential. Real-time tracking of material location helps identify where materials are and how many are currently available.',
        sub1: 'High Accuracy',
        icon1: IconRulerGray,
        sub2: 'Statistical Data',
        icon2: IconChartBarGray,
        video: subVideo1,
      },
      {
        title: 'Automated Incoming and Outgoing Processes',
        desc: 'Automatically record material transactions as they pass through specific points, preventing human errors and saving labor resources.',
        sub1: 'Fast Data Integration',
        icon1: IconTvocGray,
        sub2: 'Real-Time Location Tracking',
        icon2: IconBinocularsGray,
        video: subVideo2,
      },
      {
        title: 'Safety Management',
        desc: 'Materials that could potentially cause safety accidents require special attention. Real-time tracking helps prevent accidents by identifying nearby workers.',
        sub1: 'Proximity Distance Settings',
        icon1: IconRulerGray,
        sub2: 'Response Method Settings',
        icon2: IconBellGray,
        video: subVideo3,
      },
      {
        title: 'Time Savings',
        desc: 'Significantly reduces the time workers spend searching for materials. In a construction site, work time directly affects productivity, and optimizing the work site leads to efficient operations without wasting resources.',
        sub1: 'Real-Time Status Overview',
        icon1: IconClockGray,
        sub2: 'Real-Time Location Tracking',
        icon2: IconBinocularsGray,
        video: subVideo4,
      },
      {
        title: 'Theft Prevention',
        desc: `When materials are lost, it can be difficult to determine if it's a simple mistake or theft. However, real-time tracking of material location prevents energy and cost waste.`,
        sub1: 'Response Method Settings',
        icon1: IconBellGray,
        sub2: 'External System Integration',
        icon2: IconChainLinkGray,
        video: subVideo5,
      },
    ],
    subfunc: {
      title: 'Various Functions',
      desc: 'Inefficient material management at construction sites directly affects productivity. By optimizing processes and eliminating wasted resources, including human resources, ORBRO provides a range of optimized functions.',
      data: [
        {
          icon: IconChartRadius,
          title: 'Reports and Analysis',
          desc: 'Generate reports and analysis based on on-site data.',
        },
        {
          icon: IconBell,
          title: 'Real-Time Alarm Function',
          desc: 'Receive immediate alarms in case of abnormal situations related to materials.',
        },
        {
          icon: IconRoute,
          title: 'Route History Inquiry',
          desc: 'Retrieve and digitize the movement history of materials at the construction site.',
        },
        {
          icon: IconDashboard,
          title: 'Status Overview',
          desc: 'Understand the current movement and location of materials.',
        },
        {
          icon: IconWarning,
          title: 'Deviation Detection',
          desc: 'Detect materials that deviate from their designated areas or move to incorrect locations.',
        },
        {
          icon: IconSearch,
          title: 'Route Pattern Analysis',
          desc: 'Analyze the usage and timing of materials at the construction site.',
        },
        {
          icon: IconChainLink,
          title: 'Data Integration',
          desc: 'Efficiently connect with systems such as RFID, IoT, security, and video to maximize utility.',
        },
        {
          icon: IconSecurity,
          title: 'Data Security',
          desc: 'Maintain a high level of data security to provide a reliable solution.',
        },
      ],
    },
    system: {
      title: 'System Configuration',
      img: rtlsSystem,
    },
    productTitle: 'Product Configuration',
    product: [
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'constructionEquipment',
      },
      {
        data: 'constructionSafety',
      },
      {
        data: 'blogRTLS',
      },
      {
        data: 'blogMedical',
      },
    ],
  }
  