import { medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4";

export default {


  helmet: '| RTLS 헬스케어, 바로 사용 가능합니다.',
  desc: '환자의 위치를 실시간으로 추적하고, 의료 기기 및 인력을 효율적으로 관리함으로써 의료 현장에서의 작업 효율성을 향상시키고 환자 안전을 높이는 데 큰 역할을 합니다.',
  keywords: '환자 위치추적, 환자 관리, 병원 위치 추적, 병원 관리, 스마트 병원, 환자 추적, 의료 시설 관리, 치매 환자 관리, 알츠하이머 환자 관리, 노인 환자 관리, 병원 운영, 메디컬 센터, 요양원 관리, 오브로, 콩테크, rtls, orbro',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RTLS 헬스케어, 바로 사용 가능합니다. :: 오브로 블로그',
  ogUrl: 'https://orbro.io/blog/rtlsHealthcare',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxCulBiV7gg1-xmVx62eta4ZCVo7bPai3vj9SN8ajL7XdyxO5_DwTc9AopMRDeHnIP1JRQ2aZs8YXRVegMIQ3XJAzQpGZPe7LsKZRYfG2TTKDwuITV4QU1esZgJZjvVrWFNk7tdYgfPUmag4WVUhkZm9kN2q9I3i-i7g39hykefddJoB-GGyLstWZXHZPGouEpHRHO-QSDUlk-5bfgHEXekgkw8khERLg03ek2jr9aZVu73vARqYrCfRjmXxpjdzbezfvYPPNqyHHEqz7ryYxrceLzeUag7SeXqCikIehVE0j-teEEB0twcIKzxDhQ926mrdivrYUsLL7Y73cfXmCIoQrwGuj_PWfjkx8---GZuGMDu2T0xCegVf_ixBlv-_DP0s4PYYug15P9K4RRQcy8wzkEIe8DXRrNFJxK06VwMVNt7IvsyGuGpztnYoPe9DTJVmh6DgvpkLVfrWOQROJ1mY3wPnWoRGDONddVgsZOg9IFlZ8XDLxhGIwf9_NUadZ72Sx1uku7HzFPuRiPUi_jEHwpZssTqer0dHqRrmEiUm7WIfEvksaY5tlVEdKCkt6rieJQk6XkQ7HLkH9Sft-gtWyMIbXXc-ezr8CI1QW-BSqTqW1u1RHOH_U8qsyeAMRsLziuReFdGt94MU0XLtJQjzm470ZW0Lyliq6-D-ACBxago-nL7bW3hByy9u4qrPcseGt9nDBtxez21nzH4j-kk-ikAGdM2lqgNjENR3W3iBBYZxCLEgg5FaWno-JnWFODzJ2EPDkL9YgV3eJXaSav9q-K9OGrHtGcdLoOoclYQdW6HGei2jvEzIvkJ7fOrE_BkaMDvwfP_mG1Wyfmazl4ZT-E3REcF7-H8fvG1Maj9lGE47sI9NaaLiuKxMUKkoOq7ATE-CsA56qjq0om1fHUHtL3k5dJaPw5dqFFc_I1pmQF2l6v32K36AQbL6URjYW2TRB5R_7PdZbxPltDvqVhv26deM1LrtslNSaQ1y8-v60TTOuJzwdCsBF2-a1Cgad2aG4c0gxmrniSlWna_ih_LT7LTZ78ekaoZK-QZbGYBhztrbJH8pG6OtYsABLu_EJbLwCZ8gemIFAL2str5ljuZjxGa8baorSqsutD4ZWG98EcUImwgTwfsXrg_fNe0Uyl2TmD1Jnr-cpdFvRHT9CtlmxJL0XV3CuWIB36kk7Sj451LQT1d3CUGDUYSob_cs6XtFvl8zo1L1iJO3CbaIL-X7qva6sYYjidWIQqBIwseA6TVk4ZZ4AB1lm8OMbmP-VwUqs4EahKtumqMiQXZoiUFyXyTKLz-soVKV_uVW4R9xcXy-pUYHMsYmRM2uDVc0eCkPkdr5NoidDFKrf03A4kPR2s3URy-bZdCORxE0KgtlQLx1eKtVi-Ku88pwYGe1IECBztZxTUfhisovrg-CZu2B3OOTHLZEZ3DTwH9ORlbfLRdJtLl21EkzHYHX485uwK40pglWChiD4G58da5pMDp2-tZtgrkAaEUw-gf3FoUya3HD4ywea6Tzxbua0wg9oJfPQYB4jUYPE8kQiPVTpz4TgFYI8iwU9sF8yUIRbW5c7kWjwIp=w2000-h1596',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RTLS 헬스케어, 바로 사용 가능합니다.',
  date: '2023-11-02',
  tag1: '#헬스케어',
  tag2: '#환자위치추적',
  tag3: '#스마트의료',
  tag4: '#환자관리',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: 'RTLS(Real-Time Location System) 기술은 현대 헬스케어 분야에서 혁명을 일으키고 있습니다. 이 기술은 환자의 위치를 실시간으로 추적하고, 의료 기기 및 인력을 효율적으로 관리함으로써 의료 현장에서의 작업 효율성을 향상시키고 환자 안전을 높이는 데 큰 역할을 합니다. 이 글에서는 RTLS 헬스케어 기술의 중요성과 이를 즉시 사용할 수 있는 이점에 대해 알아보겠습니다.',
      dot: ''

    },
    {
      video : video,
      link : 'https://orbro.io/enterprise/medical/patient',
      head: '지금 솔루션을 구축한다면?',
      subHead: '환자 안전 강화',
      desc: 'RTLS는 환자 위치 추적을 통해 환자 안전을 강화하는 주요 수단입니다. 환자의 위치를 실시간으로 파악하면 의료진은 환자의 상태를 신속하게 파악할 수 있으며, 긴급한 상황이 발생할 때 즉각 대응할 수 있습니다. 이로써 응급 상황에서의 치명적인 오류를 줄일 수 있습니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '자원 관리 향상',
      desc: 'RTLS는 의료 기기와 의료 직원의 효율적인 관리를 가능하게 합니다. 의료 기기의 실시간 위치 정보를 얻으면 필요한 장비를 빠르게 찾을 수 있고, 유지보수 및 교체 작업을 효율적으로 수행할 수 있습니다. 또한, 의료진의 일정을 최적화하여 스케줄 충돌을 피하고 환자 치료에 집중할 수 있습니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '감염 예방',
      desc: 'RTLS를 통해 병원 내에서 환자 이동을 효과적으로 관리할 수 있습니다. 감염병의 확산을 예방하고, 병원 내에서의 환자 간 접촉을 최소화하는데 도움이 됩니다. 이로써 감염병의 전파를 제한하고 환자 및 의료진 모두의 안전을 보호합니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '재무 관리',
      desc: 'RTLS를 도입함으로써 자원을 효율적으로 활용하고, 비용을 절감할 수 있습니다. 예를 들어, 불필요한 장비 구매나 재고 낭비를 방지할 수 있으며, 인력 비용을 최적화할 수 있습니다. 이는 병원의 재무 상황을 개선하고 예산을 효율적으로 활용할 수 있도록 도와줍니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '실시간 정보',
      desc: 'RTLS는 실시간 위치 정보를 제공하여 의료진이 더 신속하고 효과적으로 의사결정을 내릴 수 있도록 도와줍니다. 환자의 상태가 변할 때 즉각적으로 대응할 수 있으며, 이로써 진료 과정을 개선하고 환자 치료에 대한 최상의 결과를 얻을 수 있습니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '비용 효율성',
      desc: 'RTLS는 비용 효율적인 솔루션을 제공하며, 시간과 자원을 절약하여 기관의 재무 상황을 개선시킵니다. 투자 대비 빠른 수익을 얻을 수 있으며, 장기적으로는 비용 효율성을 높일 수 있습니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '환자 만족도 향상',
      desc: '환자 위치 추적을 통해 환자들은 보다 개인화된 관리를 받을 수 있으며, 환자들은 자신의 안전과 편의성에 대한 높은 만족도를 경험할 수 있습니다. 이는 환자와 가족에게 신뢰와 안심감을 제공합니다.',
      dot: ''
    },
    {
      head: '정리하자면,',
      subHead: '',
      desc: 'RTLS 헬스케어 기술은 현대 의료 분야에서 큰 혁신을 가져오고 있으며, 이를 바로 적용하여 환자 안전과 효율성을 향상시킬 수 있습니다. RTLS는 쉽게 구현 가능하며, 빠르게 결과를 얻을 수 있기 때문에 의료 기관과 환자 모두에게 혜택이 되는 중요한 기술 중 하나입니다. 이를 통해 미래의 헬스케어는 더 안전하고 효율적으로 제공될 것으로 기대됩니다.',
      dot: ''
    },

  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/enterprise/medical/patient'
      },
      {
        img: medicalWaste.thumb,
        data: 'medicalWaste',
        link: '/enterprise/medical/waste'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/enterprise/office/asset'
      }
    ]

  }


}