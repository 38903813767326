import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledHeading } from "@components/Text";
import { ImgWH, ImgWidth100, ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import React, { useEffect, useRef, useState } from 'react';
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { BigCard, Card23Page, CardBox, CardTextBox, LableImage, SmallCard, VideoBoxBig, VideoBoxSmall, VideoPlay, VideoTextBox } from "./Detail.style";
import { CommonState } from "@redux/reducers/commonReducer";
import { CardGap } from "@pages/Main/Common/Functions/Functions.style";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";
import i18n from "@pages/i18n";
import { DivAllCenter, DivWidth100 } from "@pages/CommonStyle/commonDiv.style";
import integration from "@src/lang/ko/main/integration/integration";
import integrationEn from "@src/lang/en/main/integration/integration";
import integrationJp from "@src/lang/jp/main/integration/integration";
import integrationCn from "@src/lang/cn/main/integration/integration";



const Detail = ({ type }) => {


  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  const [layoutMaxWidth, setLayoutMaxWidth] = useState<any>(null);
  const [dataPath, setDataPath] = useState<any>(integration.delivery);
  const language = i18n.language;
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  const getDataPath = (type, language) => {
    const datas = {
      ko: {
        delivery: integration.delivery,
        loading: integration.loading,
        service: integration.service,
      },
      en: {
        delivery: integrationEn.delivery,
        loading: integrationEn.loading,
        service: integrationEn.service,
      },
      jp: {
        delivery: integrationJp.delivery,
        loading: integrationJp.loading,
        service: integrationJp.service,
      },
      cn: {
        delivery: integrationCn.delivery,
        loading: integrationCn.loading,
        service: integrationCn.service,
      },
    };

    return datas[language] ? datas[language][type] : null;
  };

  useEffect(() => {
    setDataPath(getDataPath(type, language));

    const handleResize = () => {
      setLayoutMaxWidth(window.innerWidth);
    };

    setLayoutMaxWidth(document.documentElement.clientWidth);

    const handleScroll = () => {
      videoRefs.current.forEach((videoRef, index) => {
        if (videoRef) {
          const videoTop = videoRef.getBoundingClientRect().top;
          const videoBottom = videoRef.getBoundingClientRect().bottom;
          const windowHeight = window.innerHeight;
          const isCurrentVideoPlaying = videoBottom >= windowHeight / 2 && videoTop <= windowHeight / 2;

          if (isCurrentVideoPlaying) {
            if (videoRef.readyState >= 4 && videoRef.paused) {
              videoRef.play();
            }
          } else {
            if (!videoRef.paused) {
              videoRef.pause();
            }
          }
        }
      });
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);



  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleScroll = () => {
    if (videoRef.current) {
      const videoTop = videoRef.current.getBoundingClientRect().top;
      const videoBottom = videoRef.current.getBoundingClientRect().bottom;

      const windowHeight = window.innerHeight;
      const isCurrentVideoPlaying = videoBottom >= windowHeight / 2 && videoTop <= windowHeight / 2 && !isVideoPlaying;

      if (isCurrentVideoPlaying) {
        if (videoRef.current.readyState >= 4) {
          videoRef.current.play();
          setIsVideoPlaying(true);
        }
      } else {
        setIsVideoPlaying(false);
      }
    }
  };




  return (
    <>
      <StyledGridContainer>
        <Card23Page>
          <DivAllCenter>

            <LableImage h={"23"} src={integration[type].label} />
            <Spacing pixel="8" />
            <DisplayH2 size="small">
              <Trans i18nKey={`integration.${type}.title`} />
            </DisplayH2>

            <Spacing pixel="16" />
            <ParagraphColor color="#000" size="medium">
              <Trans i18nKey={`integration.${type}.desc`} />
            </ParagraphColor>
          </DivAllCenter>
          <Spacing pixel="64" />

          <CardBox>
            {dataPath.card.map((data, i) => (
              <>
                {data.videoTag ?
                  <>
                    {i === 0 ?

                      <VideoBoxBig>
                        <VideoTextBox>
                          <StyledHeading size="small">
                            <Trans i18nKey={`integration.${type}.card.${i}.title`} />
                          </StyledHeading>
                          <Spacing pixel="4" />
                          <ParagraphColor color="#818181" size="small">
                            <Trans i18nKey={`integration.${type}.card.${i}.desc`} />
                          </ParagraphColor>
                        </VideoTextBox>
                        <VideoPlay autoPlay loop muted playsInline
                          ref={(el) => (videoRefs.current[i] = el)}>
                          <source src={data.video[layoutType]} type="video/mp4" />
                        </VideoPlay>
                      </VideoBoxBig>
                      :
                      <VideoBoxSmall>
                        <VideoTextBox>
                          <StyledHeading size="small">
                            <Trans i18nKey={`integration.${type}.card.${i}.title`} />
                          </StyledHeading>
                          <Spacing pixel="4" />
                          <ParagraphColor color="#818181" size="small">
                            <Trans i18nKey={`integration.${type}.card.${i}.desc`} />
                          </ParagraphColor>
                        </VideoTextBox>
                        <VideoPlay autoPlay loop muted playsInline
                          ref={(el) => (videoRefs.current[i] = el)}>
                          <source src={data.video[layoutType]} type="video/mp4" />
                        </VideoPlay>
                      </VideoBoxSmall>
                    }
                  </>
                  :
                  <>

                    {type === 'delivery' && i === 0 ?
                      <BigCard w={layoutMaxWidth} img={data.img[layoutType]}>
                        <CardTextBox>
                          <StyledHeading size="small">
                            <Trans i18nKey={`integration.${type}.card.${i}.title`} />
                          </StyledHeading>
                          <Spacing pixel="4" />
                          <ParagraphColor color="#818181" size="small">
                            <Trans i18nKey={`integration.${type}.card.${i}.desc`} />
                          </ParagraphColor>
                        </CardTextBox>
                      </BigCard>
                      :
                      <SmallCard w={layoutMaxWidth} img={data.img[layoutType]}>
                        <CardTextBox>
                          <StyledHeading size="small">
                            <Trans i18nKey={`integration.${type}.card.${i}.title`} />
                          </StyledHeading>
                          <Spacing pixel="4" />
                          <ParagraphColor color="#818181" size="small">
                            <Trans i18nKey={`integration.${type}.card.${i}.desc`} />
                          </ParagraphColor>
                        </CardTextBox>
                      </SmallCard>
                    }
                  </>}
              </>
            ))}
          </CardBox>



          <CardGap />

        </Card23Page>
      </StyledGridContainer>

    </>
  )
}

export default Detail;