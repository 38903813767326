export default {
  table: {
  header: {
  name: '',
  uwb: 'UWB',
  ble: 'BLE',
  wifi: 'Wi-Fi'
  },
  accuracy: {
  name: 'Accuracy',
  uwb: '10 - 50cm',
  ble: 'Less than 5m',
  wifi: 'Less than 10m'
  },
  optimumRange: {
  name: 'Optimum Range',
  uwb: '10 - 50cm',
  ble: '0-25m',
  wifi: '0-50m'
  },
  maximumRange: {
  name: 'Maximum Range',
  uwb: '200m',
  ble: '100m',
  wifi: '500m'
  },
  latency: {
  name: 'Latency',
  uwb: '1ms',
  ble: '3-5 seconds',
  wifi: '3-5 seconds'
  },
  power: {
  name: 'Power Consumption',
  uwb: 'Low',
  ble: 'Very low',
  wifi: 'Medium'
  },
  value: {
  name: 'Cost',
  uwb: '₩₩',
  ble: '₩₩',
  wifi: '₩₩₩'
  },
  frequency: {
  name: 'Frequency',
  uwb: '3.1-10.6GHz',
  ble: '2.4GHz',
  wifi: '2.4GHz, 5GHz'
  },
  dataRate: {
  name: 'Data Rate',
  uwb: 'Up to 27Mbps',
  ble: 'Up to 2Mbps',
  wifi: 'Up to 1Gbps'
  },
  },
  request: {
  title: 'Need a Solution?',
  description: 'Do you need a solution implementation or a demo request? <br/> Feel free to contact us, and we will get back to you promptly.',
  button: 'Contact'
  }
  }