import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const AccessPage = styled.div`
    padding : 128px 0 56px 0;
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        padding : 96px 0 56px 0;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 64px 0 56px 0;
    }
`;


export const BigCard = styled.div<{w : number, img : string}>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;
    border-radius: 16px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));

    ${({w})=> w && `width : calc((2 / 3) * (${w}px - 64px) + 32px);`}
    ${({img})=> img && `background-image : url(${img});`}
    padding : 24px;

    aspect-ratio : 812 / 480 ;
    display : flex;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        max-width : 558px;
        aspect-ratio : 558 / 400 ;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 24px;
        width : 100%;
        aspect-ratio : 361 / 440 ;
    }
`;


export const SmallCard = styled.div<{w : number, img : string}>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;
    border-radius: 16px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
    ${({w})=> w && `width : calc((1 / 3) * (${w}px - 64px));`}
    ${({img})=> img && `background-image : url(${img});`}
    padding : 24px;

    max-width : 388px;
    aspect-ratio : 388 / 480 ;
    display : flex;
    
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        max-width : 322px;
        aspect-ratio : 322 / 400 ;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 24px;
        width : 100%;
        max-width : 100%;   
        aspect-ratio : 361 / 440 ;
    }
`;



export const NormalCard = styled.div<{w : number, img : string}>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;
    border-radius: 16px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));

    ${({w})=> w && `width : calc((1 / 3) * (${w}px - 64px));`}
    ${({img})=> img && `background-image : url(${img});`}
    padding : 24px;

    aspect-ratio : 389 / 480 ;
    display : flex;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        ${({w})=> w && `width : calc((1 / 3) * (${w}px - 48px));`}
        aspect-ratio : 288 / 440 ;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 24px;
        width : 100%;
        aspect-ratio : 361 / 440 ;
    }
`;
