export default {

    banner : '현실을 디지털 세상으로',
    banner2 : '생생히 재창조한 데이터 플랫폼.',
    desc : '전세계에는 약 10제타바이트에 달하는 어마어마한 정보가 있습니다.<br/>오브로는 전세계에 존재하는 정보를 가상세계에 시각화하여 표현하는 플랫폼 입니다.<br/>가상과 현실, 모든 것이 하나로 연결되는 새로운 세계를 경험하세요.',
    over : '오브로는 지구에 존재하는 유의미한 정보들을 전부 실시간으로 복제하고 기능적으로 연동하는 프로젝트입니다. <br/>땅, 하늘, 바다, 도시, 사람들과 차량의 움직임까지 현실 보다 더 현실 같은 디지털 세상을 느껴볼 수 있습니다.',

    overSerial : [

        {text : '동일한 세상',},
        {text : '동일한 바다',},
        {text :'동일한 도시',},
        {text : '동일한 움직임',},
        
    ],
    

    alive : [
        {
            title: '빠르게 스캔하는 도시 정보',
            titleColor : '#151515',
            text: '건물의 특징을 나타내는 높이, 이름, 주소, 층수 등 건물에 종속된 다양한 정보를 오브로에서 확인할 수 있습니다. ',
            textColor : '#151515',
            size: 'type2',
          },
          {
            title: '세상의 모든 건물을 더 자세하게',
            titleColor : '#151515',
            text: '오브로에는 전 세계의 모든 건물과 빌딩 정보가 그대로 시각화되어 표현됩니다. 건물의 디테일한 모습들까지 표현된 모델링 정보들이 도시 전체를 구성하는 것을 볼 수 있습니다. 아파트에서부터 공원, 호수, 빌딩, 랜드마크까지 어디서든 더 자세한 정보를 한눈에 직접 볼 수 있습니다.',
            textColor : '#151515',
            size: 'type4',
          },
          {
            title: '가장 빠르게 만나는 생활정보',
            titleColor : '#151515',
            text: '버스정류장, 터미널, 공항, 공공 CCTV를 보여주며 심지어, 나무와 같은 다양한 객체 모두 지도 위에 표현이 가능합니다. 가상 객체에 앱을 연결하여 무한한 확장성을 만들어냅니다.',
            textColor : '#151515',
            size: 'type4',
          },
          {
            title: '3D를 넘어 영상화까지',
            titleColor : '#151515',
            text: '내부 IP카메라를 통해 도로에 설치된 CCTV와 같은 영상 저장장치의 송출정보들이 오브로와 연결되어 3D 지도상에서 실시간으로 보여집니다.',
            textColor : '#151515',
            size: 'type2',
          },
          {
            title: '현실감을 더한 생생한 교통정보',
            titleColor : '#151515',
            text: `지하철 노선별 시간표, 고속.시외.항공.여객 등 터미널 교통정보, 대중교통 노선정보 그리고 위치정보까지 방대한 양의 공공 빅데이터를 데이터 시각화하여 실시간으로 가상 공간에 표현합니다.`,
            textColor : '#151515',
            size: 'type5',
          },
          {
            title: '지도 위를 걷는 사람',
            titleColor : '#151515',
            text: '오브로 인프라가 설치된 장소에서는 실내외 움직이는 모든 사람이 지도 위에 나타납니다.',
            textColor : '#151515',
            size: 'type2',
          },
          {
            title: '지도 위를 달리는 차량',
            titleColor : '#151515',
            text: '오브로는 차량의 모든 정보도 지도 위에 나타납니다. 주차된 차량 모습뿐만 아니라 실시간 이동하는 모습까지 나타나 한눈에 교통량과 혼잡도가 파악되어 교통 분산 예측도 가능하게 합니다.',
            textColor : '#151515',
            size: 'type4',
          },
          {
            title: '지도 위를 나는 비행기',
            titleColor : '#151515',
            text: '지하철 노선별 시간표, 고속.시외.항공.여객 등 터미널 교통정보, 대중교통 노선정보 그리고 위치정보까지 방대한 양의 공공 빅데이터를 데이터 시각화하여 실시간으로 가상 공간에 표현합니다.',
            textColor : '#151515',
            size: 'type4',
          },
          {
            title: '실시간 공항 정보 표시',
            titleColor: '#fff',
            text: '비행기가 언제 도착하고 공항의 터미널 정보를 통해 언제 이륙하는지 한눈에 볼 수 있습니다. 오브로는 항공기 정보를 실시간으로 연결합니다.',
            textColor: '#fff',
            size: 'type2',
          },
          {
            title: '눈으로 직접 보는 날씨',
            titleColor : '#151515',
            text: '오브로는 생활에 가장 밀접한 날씨 정보도 새로운 경험으로 제공됩니다. 궁금한 위치의 미세먼지, 초미세먼지 등과 같은 자세한 환경 정보까지 지도 위에서 시각적으로 표현됩니다.',
            textColor : '#151515',
            size: 'type2',
          },
          {
            title: '더 멀리까지 궁금하세요?',
            titleColor: '#fff',
            text: '오브로 세상에서는 전 세계 각 지역의 모든 날씨 정보들이 연결되어 있습니다. 시시각각 변화하는 전 세계의 날씨 정보를 오브로에서 만나는 모든 장소마다 눈으로 볼 수 있습니다',
            textColor: '#fff',
            size: 'type4',
            shadow: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(96, 96, 96, 0.2) 45%, rgba(255, 255, 255, 0) 95%)',
          },


        
    ],

    

    infoSub :'가상공간에서 동작하는 현실세상',
    info :'오브로, 현실 세상<br/>정보를 담다.',

    twinTitle : '이것이 진정한 디지털 트윈',
    twinSub : '우리가 세상을<br/>보는 방법.',

    
    twin : [


      {
        title: 'Twin Tracker',
        text: '트윈트래커는 2.4GHz의 블루투스 전파 대역을 모두 추적하는 장비입니다. 10년간의 R&D 끝에 산업용 보급이 가능하도록 고도화된 전 세계 최초의 위치분석용 네트워크 장비입니다.',
        deviceInfo: {
          width: '95mm',
          height: '95mm',
          thickness: '32.5mm',
          specifications: ['USB C, PoE LAN Port', 'Directional Antenna'],
        },
        buttonType: 'black',
      },
      {
        title: 'Twin Vision',
        text: '트윈 비전은 스테레오 카메라 기술과 AI 기술을 이용한 위치추적 장비입니다. 복합적으로 고도화된 딥러닝 기술을 이용하여 사물의 움직임을 실시간으로 추적하고 오브로 세상에 타겟한 물체를 3D 모델링으로 가상화시킵니다.',
        deviceInfo: {
          width: '185mm',
          height: '40mm',
          thickness: '39mm',
          specifications: ['Dual 4 MP Sensor, 2 Micron Pixel', 'RTLS Optimized Image Streaming', 'High Quality Image Callibration'],
        },
        buttonType: 'black',
      },
      {
        title: '실내 위치를 정확하게 분석',
        text: '트윈 장비를 통해 방문자의 입장 및 동선을 감지하고 체류시간을 포함한 정밀한 위치까지 실시간으로 확인하고 분석하는 가장 정확한 방법을 제공합니다.',
        
      },
      {
        title: '섬세한 동선 데이터 누적',
        text: '물에 진입한 방문자들의 다양한 움직임을 실시간으로 기록하고 저장하여 공간 경험 개선에 유용한 데이터로 활용할 수 있습니다. 어디에 방문하였고 어떤 속도로 이동하며 얼마나 체류했는지 등을 섬세하게 분석합니다.',
        
      },
      {
        title: '방문자별 동선 추적',
        text: '방문자는 개별 ID 단위로 구분되어 움직임 추적 및 동선 분석이 가능합니다. 여러 명의 방문자가 발생하여도 방문자별로 동선 및 출입 시간, 체류 시간, 접촉자 등 모든 상세 데이터가 기록되고 타임라인 데이터로 저장됩니다.',
        
      },
      {
        title: '시간대별 사용자 분석',
        text: '시간대별로 사람들이 얼마나 유입되었는지 확인이 가능합니다. 건물에 가장 많은 사람들이 움직이는 시간을 한 번에 알 수 있습니다. 유동 인구 분석을 통하여 마케팅, 홍보, 시설물 재배치 등 다양한 형태로 응용할 수 있습니다.',
        
      },
      {
        title: '공간의 히트맵 표현',
      text: '오브로와 개인정보가 결합하면 활용성은 더욱 높아집니다. 권한 설정이 필요한 방문자 출입 시스템, 출퇴근 시간 체크 등 다양한 관리 방법으로 활용할 수 있습니다.',
        
      },
      {
        title: '개인정보 연동의 확장성',
        text: '오브로와 사용자 정보가 결합되면 훌륭한 정보로 변합니다. 효율적인 사용자 관리를 위해 출입 시스템, 기존 사용자 DB 정보와 연동되어 다양하게 활용할 수 있습니다.',
        
      },
      {
        title: '세계 최초 통합 위치 분석',
      text: '오브로 트윈은 전 세계 최초로 영상 분석과 전파 기술을 통합한 초정밀 위치추적 기술로 산업의 혁신을 가능하게 합니다.',
        
      },
      {
        title: '모바일 연동',
        text: '오브로는 전파추적 기술을 이용하여 모바일, Tag, 전자장비 모두 추적이 가능합니다. 모바일을 통한 사용자 위치 분석을 통해 새로운 인사이트를 만들어낼 수 있습니다.',
        
      },
      {
        title: '개인정보 보호',
        text: '사용자의 소중한 개인정보 보호와 보안을 위하여 오브로는 개인 민감정보를 서버에 일체 저장하지 않습니다.',
        
      },


    ],


    accessTitle : '비접촉 출입 시스템',
    accessSub :'미래다운<br/>새로운 출입 방법',

    access : [
      {
        title: 'Access Pro',
        text: 'Access Pro는 사용자 정보에 따라 출입 권한을 관리하며 제어할 수 있어, 정밀한 출입 관리가 필요한 건물에 유용합니다.',
        
        deviceInfo: {
          width: '160mm',
          height: '160mm',
          thickness: '38mm',
          weight: '258g',
          specifications: ['4 Channel T&C Engine', '2 Channel Bluetooth 5.0', 'Round TFT-LCD'],
        },
        buttonType: 'white',
      },
      {
        title: 'Access lite',
        text: 'Access Lite는 특정 조건을 가진 모든 사용자에게 출입 권한을 부여합니다. 움직임 또는 간단한 인증 절차만으로 출입을 허가하는 공공장소, 지역관리 장소 등에 유용합니다.',
       deviceInfo: {
          width: '210mm',
          height: '60mm',
          thickness: '43mm',
          weight: '156g',
          specifications: ['Radar Sensor', 'Noise Filter', 'Bluetooth 5.0'],
        },
        buttonType: 'white',
      },
      {
        title: '인사이트를 주는 방문자 통계',
        text: '실시간으로 다양한 항목별 방문자 정보를 통계로 확인할 수 있습니다.',
      },
      {
        title: '공간별 이동 동선',
        text: '방문자들이 어떤 동선으로 움직이는지 궁금하지 않으세요? 개별 방문자의 이동 동선을 건물의 층마다 알아볼 수 있습니다.',
        
      },

    ],


    smartTitle : '기기 원격 제어',
    smartSub : '멀리서도 만들 수 있는<br/>최적의 공간 경험',
    smart :[

      {
        title: '언제든 쾌적하게<br/>에어컨 제어',
        text :'',
      },
      {
        title: '한 번에 손쉽게<br/>조명 제어',
        text :'',
      },
      {
        title:'공기 품질뿐 아니라<br/>온도 정보까지',
        text :'',
      },
      {
        title: '모든 것을 위한 모든 제품',
        text: '오브로와 상호작용하는 다양한 제품들은 온라인과 오프라인을 넘나들며 당신의 완벽한 경험을 돕습니다.',
        
      },
      
    ],


    size : '크기 및 무게',
    width : '가로',
    thick : '두께',
    height : '세로',
    weight : '무게',
    product : '제원',
    
    dream : '꿈꿔왔던<br/>세상의 모든 기능',
    app : '무한한 확장을 위한 오브로 앱',
    download : '앱 다운 받기',
    solution : '다양한 솔루션이 준비되어 있습니다.',
    more : '더 알아보기',


    platform : 'Platform 시작하기',
    exp : '현실과 연결된 새로운 세상을 경험하세요.',
    start : '시작하기'







}