import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet: '',
  desc: 'RTLS technology optimizes location and asset management in the office industry and enhances work efficiency by monitoring employee activities. Additionally, it enables smart management of business processes such as meeting room reservations and task status tracking through real-time location tracking.',
  keywords: 'RTLS, workforce management, attendance management, labor management, smart office, 4th industrial revolution, location tracking, time management, business growth',
  
  ogTitle: '',
  ogDesc: '',
  ogSite: 'Building Smart Offices in 2023 :: Orbro Blog',
  ogUrl: 'https://orbro.io/en/blog/office',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EOZq-6QxpQF15gjoXPsuEITf_QOrCQngx-0mGH811amVcoMdPYM3ilqBy7Xoz0830SCxjlpjUuueuzKWtZiEjWuVteCxytua-WdJxpnvVidHfU4TRtkkXlw0xHxxUhNQxvbb5bAwoYWtF7PCka-Od-il6dCHS5WmHz1gttbrgzmv7WLmU9SQYkEs6xvUmw1kB84m4rT91EAavf9ENZ1GzxhJtFsM23wREpJzxhm0kBUbz6YMna9plYIlxFD6SfUcvqI3yduebDgFVrNqNzYuSg4MXNYrgd5besqzBTlYJ9q2D_uLNLNVin8CekS0u6HEWRqm2LjOdw5tZCbsATqf_KZTaVe7bB5zLAJv5WyYdCvS-WJtkrZis6Lr7ks9hAcVUZ6OAnu-oOnotcsNqBM6Slmh6SgL637kMZVCCL2ZRIV4M2zMmtkSgoIpl2EhAmsO_MbEAVPS6hqULxTA2MUhF7VknsXzpP4-gqDtg93jTb6M1jJSVcZMeg7b8eWXjGDAPvZ3DhZ52xs5CsgxNFiHcORnJ3r4L2BhkrUEHxdaIxtNcpYnVaJEaugtx1yYoSJhypYwIMxeuw56xgtDuhwSeXp_M47ijuLYAQH3D_e2y1-ze3pGxLp-ZUNSwEpsIQE75duXj6pGnykP_X86pC_9RvRqtGCauBh03Q5lbXcLi8uZU3feFRPIEBpcuqWSzOvc9OF-gGQQNAJvg_C-XlG7DU5noByHvqhzA_NWYyevnv_GdUqXP86pPL_HXVVFBq9tdJewYtLAEu9moGil4If2TKpgbLU2DqEFZbk53pVq6nNEEuvkIxnmmqT5FRECRIjoycCaKpClyWU6KflQXTh_ajli8XstUOEb9464PjQbEk7hur5AQveRex4wVq8OI5z2_fhrjlcMHuCN8Mi5xDJO1YX2bYT6HuGrN8A2M4qzLvoLqv9qpwf8LN-eddAFPRRxMvxKMEcV4vpwmPsrDe05R40Iwju-ALvmfJgcWEU9W4wFW3uCzbpa7bsF6sM2OYa8xC1DXZpwBcMgXHr1cy68Fgc3-Bz3bmlRBq_DoIxX_B-R-SCQRPmfELhiX_1bX6T21K1tX29iMtlobYwfW0Rm-I9toicT-RbtqAIkwLb9PKEt9Tnbxku321EMkp-qP3ZSVtC1rn5aBH9KG8v5s-FQrNpXvcFgBGJQ11ubG2TJVGND98TOtFmJODYe6lRFuvgA6v8vPcEASZfEZ9Ud5d26C6v-c9piIwRf8jrciHUSEW8heb3vtIngX_afHpGYI_yb63svGjNo9f12aCQxY8HTUu9YwnDCRFXbEiEuvHdqibatUmRWd1Ov15pW7Bfn7ERM721O-P4lkjWurLZ-7IQkd_kod2tNUENcQjT5UCMVuCNgvSmsjHm9jUuSmqjhz_N_E4gfYUImTrGgMJaW1hsQdmlIE3rNGnoSDj4L58a8B7S6f9hFQqRwyqvbeFfXP4Or8aFnxIzM-cJjgL3xcE7IIyza9Q-JN1MuqyK9eC_2nFrT7mx-hMjBW11zCF93GQ4zKO5ILybEfpIEFnZSMRUkkevmWEqohIRPhuI6uS1H2tm_Fg=w2059-h1216',
  ogType: 'website',
  
  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',
  
  title: 'Building Smart Offices in 2023',
  date: '2023-05-21',
  tag1: '#SmartOffice',
  tag2: '#RTLS',
  tag3: '#4thIndustrialRevolution',
  tag4: '#WorkforceManagement',
  
  text: [
  {
  head: '',
  subHead: '',
  desc: 'Indoor location tracking in office buildings can present many challenges. As the number of offices, halls, and floors increases, visitors can easily get lost and waste a lot of time finding their way around the building. In such situations, companies need to use smarter maps. The latest technology ensures convenient navigation related to the building and enhances customer and visitor satisfaction.',
  dot: ''
  },
  {
  head: 'Indoor Navigation for Offices',
  subHead: '',
  desc: `By using indoor mapping tools, you can effectively address office navigation issues. With digital maps, you can quickly search for offices or successfully book meeting rooms for event preparations. If a particular meeting room is already in use, real-time confirmation prevents double bookings, avoiding the time wasted due to human error. The system is based on multiple special tags or beacons installed throughout the building. These tags emit wireless signals detected by readers at predefined intervals and transmitted to servers. While users enter the building with their smartphones, the application provides an interactive map that allows them to navigate and perform other tasks. BLE, Wi-Fi, and UWB technologies can be used to navigate the office interior. It helps define locations, find necessary spaces, ensure employee safety, and obtain detailed analytics. According to statistics, work efficiency decreases by an average of 10-20% when there are difficulties in navigating the building compared to when there aren't any. Visitors and employees waste a significant amount of time finding places, resulting in event delays or late starts. Indoor location mapping minimizes these issues. The benefits of implementing this in a business center infrastructure include the following:`,
  dot: ''
  },
  {
  head: '',
  subHead: 'Real-time Step-by-Step Navigation',
  desc: `You can create the shortest and most convenient paths to Points of Interest (POIs) and provide notifications based on the user's location. Office mapping software simplifies navigation within buildings or business centers. With the introduction of interactive maps, you can optimize working hours and increase comfort for employees in handling routine tasks.`,
  dot: ''
  },
  {
  head: '',
  subHead: 'Object Search on Maps',
  desc: 'While most floor plans appear as static images, interactive maps expand possibilities for users. They can view entire floors or zoom in on specific rooms. You can scale and examine individual offices in detail, including furniture arrangements. The scalability helps make maps more flexible and facilitates easy object search. Users or employees simply need to use a mobile app to find the required room, meeting room, restroom, or technical room. Combined with the ability to build routes, it transforms any office into a truly innovative space.',
  dot: ''
  },
  {
  head: '',
  subHead: 'Social Interaction with Visitors',
  desc: 'One common problem in many offices is difficulty in finding people, especially when they are not at their desks. Location sharing features are sufficient to locate desired employees. With mapping, users no longer need to navigate through the office and floors. The system significantly simplifies social interactions and saves time.',
  dot: ''
  },
  {
  head: '',
  subHead: 'Restricted Area Monitoring',
  desc: 'Indoor mapping becomes an effective tool for monitoring restricted areas in many businesses. These places can include storage areas with documents, labs, and similar premises. Administrators can easily track who enters these areas and prevent unauthorized access using indoor mapping technology. The system can alert users not to enter these areas. No more need for signage. Restricted areas are marked as "Access Denied" on the application, warning employees not to enter.',
  dot: ''
  },
  {
  head: '',
  subHead: 'Meeting Room Occupancy Analysis',
  desc: 'This platform allows you to obtain data on office and meeting room occupancy. The system performs continuous data collection and provides real-time actual data. Beacons or tags can indicate users present in workplaces or whether rooms are reserved.',
  dot: ''
  },
  {
  head: '',
  subHead: 'Safety Management',
  desc: 'It provides information on emergency situations to ensure timely evacuation. Some rooms may have good sound insulation, making it difficult for employees to hear alarms. However, in case of accidents such as fires, they will use the mobile application to obtain information. The system can guide the nearest employee to the location for assistance or problem resolution. It can also monitor social distancing during the COVID-19 pandemic, helping employees and visitors avoid infection by closely monitoring the distance between people. It can also track office equipment leaving the building, immediately notifying if any equipment goes out of the building.',
  dot: ''
  },
  {
  head: '',
  subHead: '',
  desc: '',
  dot: ''
  },
  ],
  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/en/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/en/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/en/enterprise/logistics/asset'
      }
    ]


  }
  
}