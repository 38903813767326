import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";
import digitaltwin from "@src/assets/images/digitaltwin/video/traffic.mp4";

export default {

  helmet: '| デジタルツインとは？ | 定義、適用事例',
  desc: 'デジタルツインとは、現実世界の物理的な対象やプロセスをデジタルでモデリングし、リアルタイムで情報を収集および分析することを指します。つまり、現実のデータをデジタル環境でシミュレートおよび最適化する効果的な手段を提供します。',
  keywords: 'デジタルツイン、digital twin、スマートシティ、スマートシティ、デジタルツイン技術、デジタルツインの価値、デジタルツインの活用事例、将来の技術、デジタル革新、将来のビジョン、orbro、kongtech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'デジタルツインとは？ | 定義、適用事例',
  ogUrl: 'https://orbro.io/jp/blog/digital-twin',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDn5W_M0e1npdG9n9MzOVOPXstcQFN609JOUoXoNFXx5-5yloUkGMm1ufjGdF0R9JPeeoW9ykX72SA54O2wG8Vc8sW09dZk2e0IDK9TeXeQEa--HWeYTcyW4jGYAJNHeFvfBs5a4BRrjqNwwxwwrqLUiPrfxt1Kh2LXvniTuSqgVgIjcSz_RxNn3xVuLJqTOqR4rqFbVHkYHahb2a4j49UHtTzHmHnfVmpdEQYWJVViBOrZ3tPK0EWAkyAmPCBugIKyPXfG0iiMHGMupqT-0FPP0g20RLM4zBag-s05hL1Ujp7TBBUm4Ib_MBExPF2QSW0-Ko50dZ7a9hFGGpBrZalaWJMlebmqnC01xrRhTTAcFFNKYYDtFKQNXpb6IQ43Ef9F4-Y4sfGroiz6XZChSKlwLGbp72vSY1vYD7KX3GNlBNvYgCchgmuUmC9myCzEJbjRkKRcKOFGCNqOwXq8Ql04pFpN3XNTkmcedlgJ8xLQIH6FvQ0eIrHRjI7BxmwNtFj2PCeXKhfii-bTYi3ZB9ao0wlQkWQ10gL_ZXLOaWbKY8h_pWysjm2m7iZ2zdJyJac7ZlaVuSwFAHC__vc17P51pGrnB957kwOqxbJPEUrfDsAwD0ucwfa9X5wm2OECrV5cGVJVGBUAz55SEPInk4aouVwJ5eGNvbUoi5mFQOqeqQd-SdcSyc9hMrhBbIK8zigjExJpaRWEchpc5nL5PiJ1Ap2b73ReZfichg5q3S7QYZbk1pQVzRkvF5aDagxH3bEDhyA-eoQBmUwXNZ-O1Rlw2yLhARV_3xTok0wVPRN1PRECRlPCwgkFBEOBCTJCHxr4evnVKMdan_h85axDlA4QL5fLDuhScyp8Yiy3f_L-av_QBdqjVIkovYRjt3Ofnds2NKJbIIEDkgjJ5fnvUgNxJHKpuO3_rrqHmZagGcPtWEq1ZiUTSnmPMFUvd0ip-6GeWiZCwnkJNzazZZ3y_sejVcyeAGQpURR2dl9a3CytaKdgrP0E5kIRrKZqFFzMRvEFEsCPQI1yC6GDAlnMfvv72q5nLzjm-gU_soTcmaBcciDyAbOhEPnFgayRxu0QEDHw-A-SJn8Ub_cYlh-adqEbax34AGDlnxQLW5cgoFo0SsnMA2xD14GMSns8hj8HFgyTd1D9LpDViC5e5qZimjm3K6z56isQsFByGjVb6CYaqdICSjtdtWBEsBFjqqxf3SwLXUKU-sdMuNMmi2rJeO5KsRUv2MjSRY6MIWgv0_nv629SFKM93QxSWT3de2SAWHmDtCI0l6zGKm3ts0heoIfsioTAKjyRw0DabC833amSZ4gIWF5IIwIdc9w1mj7lsTSi5JrZKoyo5ul2TTchUf_9QqFj3u7AYcfhreY7UvlPyJD7gzlp0GY8wyKXBvaFFq9_r84ZA8vOdTNArreD3OAi8owfQOAJ2_j4H3e6nsxTjTpWDLIoz0VPfiiT-1YXwlQ3yD0DMRkGckEnpWrCRwF-JTiI-1Cn2OTi1VgH2L1-Mw0wSen_gD_tT9lcRT4vlYC0eyRhDZlfNZAI2E8RRVwxB50SEeBgj=w1906-h1213',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'デジタルツインとは？ | 定義、適用事例',
  date: '2023-12-28',
  tag1: '#デジタルツイン',
  tag2: '#デジタルトランスフォーメーション',
  tag3: '#スマートシティ',
  tag4: '#RTLS',

  text: [
    {
      head: 'デジタルツインとは？',
      subHead: '',
      desc: 'もしも、レストランやオフィス、学校、図書館、バス停など、おなじみの場所をデジタル画面で見ることができたらどうでしょうか？さらに、写真のような静止画ではなく、私たちの日常の様子をリアルタイムで見ることができたらどうでしょうか？<br/> もしレストランを経営していて、帰宅時にガスを消し忘れたか覚えていない場合でも、戻る必要はありません。現在の場所を直接確認できます。<br/> バス停でバスがいつ到着するか見当もつかなくても、現在の位置がわかるので心配はありません！<br/> このデジタルツインの概念は、人々や車両、建物、都市などの現実世界のものをデジタル画面上の仮想環境に再現することを指します。<br/><br/> デジタルツインは、現実世界の物体やプロセスをデジタルでモデリングし、リアルタイムで情報を収集および分析することを意味します。基本的には、デジタル環境で現実世界のデータをシミュレーションし、最適化する効果的な方法を提供します。これは産業、建設、医療、自動車など、さまざまな分野で利用されています。',
      dot: ''
    },
    {
      head: 'デジタルツイン技術の動作原理は？',
      subHead: '',
      desc: 'デジタルツインは、現実の人々、物体、建物をデジタル形式に変換する技術を指します。対象物の位置、状態、性能に関連するデータをリアルタイムで収集し、それをデジタル画面でモニタリングすることが目標です。<br/><br/>デジタルツイン技術の動作は次の主要なステップで構成されています',
      dot: ''
    },
    {
      head: '',
      subHead: 'データ収集と接続',
      desc: 'デジタルツインの核心は、現実の世界で発生するデータを収集し、それを接続することです。センサーやIoTデバイスなどを使用してさまざまな環境で発生するデータがリアルタイムで収集されます。収集されたデータはクラウドベースのプラットフォームに送信され、そこで処理および保存されます。これにより、現実の状況が正確にデジタル環境に反映されます',
      dot: ''
    },
    {
      head: '',
      subHead: 'モデリングとシミュレーション',
      desc: '収集されたデータを基に、デジタルツインは現実の物理的な対象を精密にモデリングします。このモデルはさまざまな変数や環境要因を考慮して現実の世界での動作を予測し、シミュレーションします。これにより、デジタルツインはさまざまなシナリオでの動作をテストし、最適な結果を見つけることができます。',
      dot: ''
    },
    {
      head: '',
      subHead: 'リアルタイムな反映と制御',
      desc: 'デジタルツインは現実の世界とリアルタイムで連動し、変化に即座に対応します。現実の世界で発生するイベントや変更に対して、デジタルツインも自動的に更新され、必要に応じて実際のシステムに制御信号を送ります。これにより、デジタルツインは常に現実と一致し、最新の情報を反映し、実際のシステムに影響を与えるために不可欠なステップとなります。<br/>これらの段階を通じて、デジタルツインは現実の世界の物理的な対象をデジタルで反映し、リアルタイムの最適化と向上した意思決定を可能にします。',
      dot: ''
    },
    {
      video: digitaltwin,
      link: '/jp/digital-twin',
      head: 'スマートシティとの関係は？',
      subHead: '',
      desc: 'デジタルツインはスマートシティの実装において重要な役割を果たしています。都市の物理的な要素を正確にモデリングし、デジタルで表現することで、スマートシティは都市の状態をリアルタイムで把握し、最適化することができます。例えば、交通システムのデジタルツインを通じて、交通の流れを予測し調整することで、交通システムを向上させることができます。また、デジタルツインを通じた環境モニタリングは、大気の質、水の使用、エネルギー消費などを効果的に管理するのに役立ちます。<br/><br/>デジタルツインとスマートシティは相互作用し、お互いを補完し、発展させています。スマートシティのさまざまなシステムはデジタルツインを通じて現実の世界と連携し、リアルタイムの情報を共有し、状況に応じて対策を取ることができます。この相互作用は、都市の安全性、効率性、生活の利便性を包括的に向上させるのに寄与します。',
      dot: ''
    },
    {
      head: 'デジタルツインの利点',
      subHead: 'リアルタイムの意思決定',
      desc: 'デジタルツインは現実の世界のデータをリアルタイムで収集し、分析して必要な情報を迅速に提供します。これにより、ビジネス環境での迅速な対応と最新情報に基づく意思決定が可能になり、競争力を維持し強化するのに寄与します。',
      dot: ''
    },
    {
      head: '',
      subHead: '自動化と効率性',
      desc: 'デジタルツインは自動化されたシステムと効果的に統合されることで、作業プロセスを最適化し生産性を向上させます。実際の環境で発生するデータに基づく自動化された意思決定は、日常の作業を簡素化し、人間のエラーを最小限に抑え、組織の作業効率を向上させます。',
      dot: ''
    },
    {
      head: '',
      subHead: 'コスト削減と予測可能なメンテナンス',
      desc: 'デジタルツインを活用すると、物理的なシステムの状態をリアルタイムでモニタリングし予測できます。これにより予防的なメンテナンスが可能となり、システムの障害や問題を事前に検知してコストを削減できます。また、正確なデータベースの予測に基づくメンテナンススケジュールを事前に計画でき、予測可能性を高めます。これはビジネスに安定性と効率性をもたらし、コストを効果的に管理するのに寄与します。<br/>',
      dot: ''
    },
    {
      head: '産業にデジタルツインを適用した事例はありますか？',
      subHead: '',
      desc: '',
      dot: ''
    },
    {
      head: '',
      subHead: '製造業での活用',
      desc: '製造プロセスではデジタルツインを活用することで、生産ラインの効率を向上させることができます。各段階で発生するデータを基にデジタルツインを構築し、リアルタイムで生産環境をモニタリングし最適化できます。これにより、生産ラインの問題点を迅速に識別し改善でき、予測可能なメンテナンスにより生産中断時間を最小限に抑えることができます。また、製品の寿命サイクル中にデジタルツインを活用して製品のパフォーマンスをモニタリングし最適化し、品質を向上させるのにも利用されます。',
      dot: ''
    },
    {
      video: video,
      link: '/jp/enterprise/manufacturing/process',
      head: '',
      subHead: 'エネルギー産業での適用',
      desc: 'エネルギー産業では発電所やエネルギーインフラのデジタルツインを活用して、エネルギー生産と消費を最適化し効率を向上させます。発電所の機器やインフラの状態をリアルタイムでモニタリングし、メンテナンスおよび修理作業を予測および計画できます。これはエネルギー生産の安定性と効率性を向上させ、予測可能なメンテナンスでコストを効果的に管理できます。',
      dot: ''
    },
    {
      head: '',
      subHead: '医療分野のデジタルツイン',
      desc: '医療分野では、患者の健康状態をデジタルツインでモデリングし、医療専門家が個別の治療を提供できるようにします。また、医療機器やシステムのパフォーマンスをリアルタイムでモニタリングして効率的な運用と迅速な対応を可能にし、患者の安全性と利便性を向上させます。',
      dot: ''
    },
    {
      head: '',
      subHead: '自動車産業での活用',
      desc: '自動車産業では、デジタルツインを活用して運転性能を最適化し、保守および修理作業を予測することができます。特に自動運転技術の進化とともに、デジタルツインは車両の環境モニタリングや相互作用を向上させる重要な役割を果たします。これによりドライバーの安全性と利便性を向上させ、車両の寿命全体で最適なパフォーマンスを維持できます。',
      dot: ''
    },
    {
      head: 'デジタルツインの展望はどのようなものですか？',
      subHead: '',
      desc: 'デジタルツイン技術は現在さまざまな産業分野で急速に適用されており、将来的にはさらなるイノベーションを牽引することが期待されています。いくつかの主な展望には以下が含まれます。',
      dot: ''
    },
    {
      head: '',
      subHead: '産業イノベーションの中心的な役割',
      desc: 'デジタルツインは製造、建設、エネルギー、医療などさまざまな産業分野でイノベーションの中心的な役割を果たすと期待されています。特に人工知能、広範な5Gの採用、ビッグデータ分析などの技術と組み合わせると、より効果的に活用されると予測されています。',
      dot: ''
    },
    {
      head: '',
      subHead: 'スマートシティの重要な要素',
      desc: 'デジタルツインはスマートシティの開発において重要な技術となることが期待されています。都市インフラの効率的な管理、交通システムの最適化、環境モニタリングなど、さまざまな分野で鍵となります。',
      dot: ''
    },
    {
      head: '',
      subHead: 'インターネット・オブ・シングス（IoT）との統合',
      desc: 'インターネット・オブ・シングス（IoT）との統合により、デジタルツインはより強力な機能を発揮すると期待されています。向上したリアルタイムデータ収集と分析能力により、物理オブジェクトのより正確なモデリングが可能となります。',
      dot: ''
    },
    {
      head: '結論',
      subHead: '',
      desc: 'デジタルツインは物理世界とデジタル環境との境界をなくし、革新的な解決策を提供します。この技術はさまざまな産業セクターで効率の向上とイノベーションを推進し、将来的にはさらに多様な分野での応用が期待されています。'
        + ' ORBROのデジタルツイン技術について詳しく知りたい場合は、以下のリンクをクリックしてください。',
      dot: ''
    },
    {
      linkText: 'もっと詳しく',
      link: '/jp/digital-twin',
      head: '',
      subHead: '',
      desc: '',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: 'UWB端末を作業者や機器に取り付け、リアルタイムの位置追跡をサポートする製品です。',
        img: productImg.product.assetTracking.key1
      },
    ],

    solution: [
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/jp/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      },
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/jp/enterprise/manufacturing/asset'
      }
    ]
  }

}
