import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { logisticsAsset, manufacturingAsset, manufacturingProcess, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4"

export default {
    helmet: '',
    desc: 'Monitor inventory location and production processes in real-time on a map identical to the field. By establishing a smart factory through digital automation, you can improve productivity and quality.',
    keywords: 'Smart factory, inventory management, asset management, process monitoring, production automation, factory robots, automatic factory, automatic process, smart factory, Contec, Orbro, rtls, orbro',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'Smart Factory Construction and Production Automation Solution :: Orbro Blog',
    ogUrl: 'https://orbro.io/en/blog/productionAutomation',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxXE6MzArHBPAtgIpznMDdCH4J8pK06Pt6RC09sNHW7PTLIF0PmPta2ymrlAdxzXB1xM2c15BXwtMiUlMiXHV5-rX_y-MCj5rxn28ZcepfxYkwYGbTtkD2tiwtidfaA7UEiWPc_QZU5UvVQ_07DIs56mvdkD5LITBtqiZP7tZHrG0NN30d5aoj0cErsx5-AXHIQuv5zO6bwkV_42KLIq2YCCSe_xQfkSXyh6zwheUsNCyZV5Cs9oUd6ImOHrCWsB1JXDQljN2q2G8-li2gz1NQV2v7__j_4t3KgJDoCmqWuuFG495xjseOwBNsdbOkL9jBB79LwHj2EeEfqTUCmPYuKjA1HxHrHCe1kQDTXbjBxYc-GoornZ3F1PG1fmwyz5EQt9n7BVSD2smPwW9zHqSVYdcwpwdzjgnVBZ3tKehLBz7gRUtfjRxxmYWMbP6-WvApgEY0_TXrwuOnT_3R44fYEsAQeQSJU1xGt-FT7Kbs_cEtrKJ09pqHvl4tOeWBKTBgqNPQi6jC3AU8eKzNgSPVI641s3fQ9Bgfpq8FFw6Rt4ry87oCoCXJErzzb8G6TI-DWbBnvd0s49QfnIWK2e6fLVdGmnzgy4vMgGa8kwu4YUXRDNn4lju7F-Uwglr1KkkLrfsUgBJe-eLigJnmV32eHXFTRod_bwAstvo2SUiqPG-jwDDjG2mEyQlODDkYR9ghqohTDwpQWEen7XYXcAzlcuo-W2XT-KLvL_3K0LaSWaV9RfuH4lEvJZg_5O4AZVvbaoonwP8c3O2kPDidXj-aGYpEkZAiERMu_6E3LuqbK6LYx3XKDOKkv_BB5569dm6PjLKrCNvyKpsQHYuRzAD6KjmMC2M55q_b4QrIldAJ3OIGc3it9CKfkRPBchL0fmymgKDrP4bYSRskJ7ezbG3mrJlmftUW4zRbdV51Be_JnMCpDP7ZHni4RsYAeKni05hHJmQlYGqb3kj64d5eiJwctbRLV1PRD_TN5cT_1IILP4xyTKxXanxncYJeyv_1ju9rI5-ROwlCt_yt6IDKC6svINvZQGxFd27XpmvlkiFq2KNNTbKekQ7YI9QP9Wssadz30lALiW08lksJZ35fwt2xP_-Wsi6PcMQz-P3uXI_70uAZdl2X5Qe1NEjfoVSUv3r9e8L7Kq22Xo1x7MClBLI1lb88GAe4GaKiYNmhYL1A3YP-hn22TIwRvcJuFHOWWQa1GuowDDCu5xve-5jEasGKyBgkoliyCv4Whu5WYvSKb5uBwYRzJWXr4AuyUGPGTZwcO_067lWFWNoJz9s6XviIJ8aHA7_zJn5ZQrokTVhOC-Cv8m6SM8YfA0bKWlE4hUZjbYdzQDsCl95UzT9WaGLONhe7h7EUo3Nt_oIXYtUso9Lqo6fu8nvLxskCelBaZ5xLRDKYnrYpxehQPCO-iTxz9WGYxKbrTFeHIXJU4jwKn-Envs1kbg34tE5lnMSGW9mGEJLIsO8h7yJ2VrKeks-V2StiHHhLCtQvCZEKJIwLfeQ8wnOuTHDHNAnkuQDSTk6mGn7bOFAOHw7L0Aloc1_6w_HfzWqtEyxlm_9bWDuQyLv9LXHa1=w2000-h1548',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'Smart Factory Construction and Production Automation Solution',
    date: '2023-11-06',
    tag1: '#SmartFactory',
    tag2: '#ProductionAutomation',
    tag3: '#ProcessMonitoring',
    tag4: '#SmartFactory',

    text: [
        {
            head: '',
            subHead: '',
            desc: 'Modern manufacturers are highly interested in smart factory construction and production automation solutions to secure competitiveness and enhance productivity. This trend has become even more critical with the advancement of modern technology and the proliferation of smart systems, and Real-Time Location System (RTLS) technology for real-time location tracking has become one of the key technologies for smart factory construction.',
            dot: ''
        },
        {
            video: video,
            link: '/en/enterprise/manufacturing/process',
            head: 'What is a Smart Factory?',
            subHead: '',
            desc: 'A smart factory refers to a manufacturing environment that utilizes state-of-the-art technology and automation systems to innovate existing manufacturing systems and optimize production processes. Smart factories offer various benefits to manufacturers, enabling quality improvement, increased productivity, and enhanced energy efficiency. To realize these benefits, RTLS technology plays a crucial role.',
            dot: ''
        },
        {
            head: 'The Appearance of Smart Factories',
            subHead: '',
            desc: 'RTLS technology stands for Real-Time Location Tracking System, which accurately tracks the location information of objects, equipment, products, and workers using various technologies and sensors. When applied to smart factories, this technology offers the following advantages:<br/><br/><strong>Resource Management and Optimization:</strong> RTLS allows real-time tracking of the location information of all resources within the manufacturing facility, enabling efficient management and optimization of equipment, materials, and workers.<br/><br/><strong>Production Process Monitoring:</strong> RTLS enables real-time monitoring of the manufacturing process, allowing quick detection and response to any abnormalities occurring on the production line.<br/><br/><strong>Automation and Robot Usage:</strong> RTLS can be used to control robots and automation equipment, automating the production process and minimizing human labor.<br/><br/><strong>Inventory Management:</strong> It enables the management of inventory based on real-time location information, leading to cost savings and efficient inventory management.<br/><br/><strong>Enhanced Safety and Security:</strong> RTLS tracks worker locations, enhancing safety and security within the workspace by detecting unauthorized access and aiding accident prevention.',
            dot: ''
        },
        {
            head: 'How to Build a Smart Factory',
            subHead: '',
            desc: 'To apply RTLS to a smart factory, you can go through the following steps:<br/><br/><strong>Requirement Analysis:</strong> First, analyze the requirements of the manufacturer and determine what RTLS system is needed.<br/><br/><strong>Sensor and Hardware Installation:</strong> Install the sensors and hardware required to build the RTLS system, which are used to track the location of objects and workers.<br/><br/><strong>Data Collection and Processing:</strong> Process the data collected by the sensors and generate real-time location information.<br/><br/><strong>System Integration:</strong> Integrate the RTLS system with other production systems to utilize it for automation and monitoring.<br/><br/><strong>Optimization and Improvement:</strong> Once the system is operational, continually work on improvements to monitor and optimize the production process.',
            dot: ''
        },
        {
            head: 'The Future of Production Automation and Smart Factories',
            subHead: '',
            desc: 'Smart factories built around RTLS are expected to play a leading role in the future of manufacturing. Production automation and smart systems are crucial for enhancing quality and productivity. Furthermore, integration with artificial intelligence (AI) and big data analysis will make factory operations even more intelligent and efficient.<br/><br/>The construction of smart factories and production automation solutions is an essential step for modern manufacturers to enhance their competitiveness and achieve sustainable growth. RTLS technology supports these forward-looking efforts and contributes to the creation of innovative manufacturing environments. Manufacturers should actively adopt such technology to continue their successful journey toward smart factory construction.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'A product that assists real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.assetTracking.thumbnail
            },
        ],
        solution: [
            {
                img: manufacturingProcess.thumb,
                data: 'manufacturingProcess',
                link: '/en/enterprise/manufacturing/process'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/en/enterprise/manufacturing/asset'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/en/enterprise/logistics/asset'
            },
        ]
    }
}
