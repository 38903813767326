import { logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@assets/images/solution/logisticsSafety/video/rtlsVideo.mp4"

export default {

    helmet: '| 室内定位系统（IPS）是什么？',
    desc: 'IPS是室内定位系统（Indoor Positioning System）的缩写，用于跟踪建筑物内用户或物体的精确位置。IPS在各种应用中被使用，在室内导航、自动化、安全、环境监测和资源管理等多个领域都是必不可少的。',
    keywords: '',

    ogTitle: '',
    ogDesc: '',
    ogSite: '室内定位系统（IPS）是什么？ | 专业的定位追踪专家，Orbro',
    ogUrl: 'https://orbro.io/cn/blog/indoor-positioning-system',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDk6TQ-h2KkQiYudK2spJrwM9-DVyXGqjpnyMQI2sZIQz1hxm14GXjbIy0_vPqBdcWNqBfevlZpTaHCDaKLTSInodrYeccUP3gpQNgAtrMwqEHlrjdh4WhikpPJ_iDLCi3PldIyG97g8uPiEGv2KbKurpE7JoCN5mRkfiH448dzhhpubMZxkB2ZKIJC7k8kNPYcSX1A-QOydJBw_yipl-pCZS_3oxhv2uYggjB0clzoEqTdYWRLydtMHV79DjszkbWJW3LTgrYEKIQP1Kbdw7ZItA-BwStqSuGehlMzlqxoFgQRChchtMnWac9VkUzOWnga-7_XUfmX90CtTqWRrFZg9Umjy8FX9288OQT_dXLNDDdowk_5t23b2mX-L7yW25zgxFHd3VtxgRongH9p94aLi4mMDO6fmT_8ajfcngVp9gOjPzHsmkOESsojVUQc706JPrK__OnMo7qr_aEVb15EIjZlqlJaJ4IhcIuXokNh-sp1nkFHuZ8qW_HXAB3FZu7r91T6rPAmKirnvFAtZdTghCXsX9nzmLybftlK1YYBWTAV0qcWkL5Ckpp2njt9pFjoXmTQqbdpz3duHY-AljIdMbCDzS444jPCLaKfXTkCmbV6WnZRaPfGR81_Q82hX3jxtuLKQmjMmCgLgCl3gp5L8CZi1hvvtQKMdcDSJ90ZUhsu2CZkmvwQ3Q_03VoJSlwwFZ3EUN3W_GB8e5cWXpNGvQccrSjeCnmIF9aVrHwtaIdWTvpskUJKjNzlqAafVUfTouYOSrV8OCfLPwSQTV8iSm2qnbRuoBigg9xouxN0bRwPkIiz67dGt4JKQcqivJnCoQxzvVomW1fVr4UbxMq6akImY3IxQ7ovGV81wA46n8tx5WPSPLMCEKQBK9FkzHiHqb8Pxxw9rzzuIa6TtoY6KRfti9zMv4fsmGqX6Q1_R5B-ABtExcOjVt-x_mS5Y3ZQR0jJIdKh4GEj98Pa9oTTF3saa28UWqTTjqRErmeVY04QTsy_SqFx9CyzU_2V1_zbdvez8hyZLuj5L9K-cvJyCKZTyBCrw0YzWwOX2MZ6fCyqvFzPfzzKJZRUncB0UrOVKw7dGDdDlaX5CqXBk-kR06RFpJIEf1fS0zEk2cBhS3NX2BU_PuueEkq1bQyLe9sG9kxqLXZ9jOc8ImIMr4X6JZgbKdO0-x0t_LkclmAjG3g4QuaNl63oDUgU1QUvQoQhDiCEHrK0xqXRaU7qSPbYXesuwkNkmOgVwPoJnNa-jYCoJ__RR4WZomXaiPckDuGTkSKx2dOfaSs7TbvIb7fqqcv5kVXnVeEtCheiIU220AnBQ6P4vLOzcQM3JP63j9eLnrIAgh2NMqXpyNVhSY5elNnry6i9vqriqjEwypIBg-eRxHD_S7iWd7gaTLIA_cu_QyeVrW7fYEMoc830qGVhoMH7VNFbffpm5PtC_9B2e2P4xe4fQIisHlT1tnhnFJynZshU2YAiNTWNWMEnpEsitAZMUPJ6oRGSQe_DK9xz-bmpANM-9WSuCR8vlKsLHuWX8A516ShbC0Y9wnZQrN3RK1Rt3xRlX=w2045-h1221',
    ogType: '网站',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '室内定位系统（IPS）是什么？',
    date: '2024-01-23',
    tag1: '#IPS',
    tag2: '#室内定位',
    tag3: '#RTLS',
    tag4: '#UWB',

    // <br/>
    text: [
        {
            head: '室内定位系统（IPS）是什么？',
            subHead: '',
            desc: 'IPS是一种用于追踪用户或对象在室内建筑中准确位置的技术。 IPS在各种应用中使用，包括室内导航、自动化、安全、环境监测和资源管理等多个领域。 像GPS这样的室外定位系统已被广泛采用，但在室内实现准确的位置追踪是一个更复杂的挑战。 IPS是为解决这个问题而开发的一种技术。',
            dot: ''
        },
        {
            head: 'IPS的重要性',
            subHead: '',
            desc: '随着智能手机和其他移动设备在现代社会的普及，位置定位技术的重要性迅速增加。 这项技术在我们使用地图应用程序寻找路线或在室内购物时提供高效信息方面发挥着重要作用。 这种位置定位技术之一是“室内定位系统”或IPS。 IPS是一种用于准确追踪设备或用户位置的技术和系统，主要在室内环境中使用。 与全球定位系统（GPS）不同，IPS在建筑物内也能够有效运行。',
            dot: ''
        },
        {
            head: 'IPS的工作原理',
            subHead: '',
            desc: '',
            dot: {
                a: '<strong> • 利用UWB技术： </strong> UWB技术可以非常精确地测量与附近设备或标签的距离。 这可用于计算用户与UWB标签（例如智能手机或信用卡）之间的距离。',
                b: '<strong> • BLE（低功耗蓝牙）： </strong> 这种方法使用蓝牙低功耗信标来确定室内位置。 信标发送唯一的标识符，然后根据此标识符来估计位置。 它提供高精度，但在部署信标时可能会产生成本。',
                c: '<strong> • 利用AI摄像头： </strong> AI摄像头可以分析从摄像头获得的图像，了解周围环境，并检测对象或人员。 这样，摄像头可以实时跟踪用户的位置和动态。',
                d: '<strong> • 基于Wi-Fi的位置定位： </strong> 它根据Wi-Fi接入点的信号强度来确定用户的位置。 每个Wi-Fi接入点都有唯一的MAC地址，可用于确定用户的位置。 但是，精度可能较低，可能会受到信号干扰和范围限制的影响。',
            }
        },
        {
            video: video,
            link: '/cn/enterprise/logistics/safety',
            videoDesc: 'Orbro物流数字孪生，点击以移动。',
            head: 'IPS技术的优势',
            subHead: '',
            desc: '由于GPS在室内无法正常工作，IPS可以提高室内位置信息的准确性。 根据准确的位置数据，可以准确确定用户或资产的位置。 另外，IPS可以实时跟踪用户或资产，因此可以在室内环境中进行实时监控和管理。 这对于应急响应和在关键时刻高效管理资产至关重要。<br/><br/>使用IPS，可以自动化和提高工作流程的效率。 例如，将IPS用于自动化机器人的位置决策可以提高生产率。 在物流行业，IPS可以优化物流和资产管理，降低成本。 此外，IPS还可以在医院、学校、机场等各种场所提高应急响应、患者跟踪、学生和员工安全性以及大型活动的导航等方面发挥作用。',
            dot: ''
        },
        {
            head: 'IPS的应用案例',
            subHead: '室内导航和营销',
            desc: '',
            dot: {
                a: ' • 帮助客户在店内轻松找到所需商品。',
                b: ' • 简化订单和付款流程，提高客户体验。',
                c: ' • 跟踪客户位置，提供产品推荐和折扣信息以改善营销效果。'
            }
        },
        {
            head: '',
            subHead: '医院和医疗设施内的患者追踪',
            desc: '',
            dot: {
                a: ' • 在医疗设施内追踪患者并实时监控其位置。',
                b: ' • 医疗人员可以迅速找到患者以应对紧急情况。',
                c: ' • 作为有效的感染控制和医疗设备管理系统使用。',
            }
        },
        {
            head: '',
            subHead: '机场内的旅客导航和安全',
            desc: '',
            dot: {
                a: ' • 旅客可以在机场内轻松找到登机口、行李领取处和服务设施。',
                b: ' • 支持安全管理和排队管理。',
                c: ' • 及时引导旅客到达登机口以遵守飞行计划。',
            }
        },
        {
            head: '',
            subHead: '体育设施和娱乐场所内的比赛和活动管理',
            desc: '',
            dot: {
                a: ' • 观众可以在体育馆内找到座位并订购食物和饮料。',
                b: ' • 提供实时比赛结果和团队信息以提高娱乐体验。',
                c: ' • 有效管理比赛和活动。',
            }
        },
        {
            head: '',
            subHead: '工厂和仓库内的物流和资产管理',
            desc: '',
            dot: {
                a: ' • 追踪工厂和仓库内的设备、产品和资产，并确定其位置。',
                b: ' • 优化物流和供应链管理以提高生产力。',
                c: ' • 支持库存管理和移动路线优化。',
            }
        },
        {
            head: '',
            subHead: '学校和大学校园内的学生和教职员工引导',
            desc: '',
            dot: {
                a: ' • 学生和教职员工可以轻松找到校园内的教室、图书馆、食堂和停车场。',
                b: ' • 跟踪学生的出勤情况，支持教育资源管理。',
            }
        },
        {
            head: '',
            subHead: '室内自动驾驶和机器人协作',
            desc: '',
            dot: {
                a: ' • 室内定位技术可以帮助自动驾驶车辆和机器人跟踪准确的路径。',
                b: ' • 提高机器人在各种任务中的操作效率。',
            }
        },
        {
            head: 'IPS的未来展望',
            subHead: '',
            desc: 'IPS技术在不断发展，有望提高室内定位的准确性和效率。与5G网络的整合、人工智能和机器学习的引入、传感器技术的发展以及其他进展对IPS的未来充满希望。更精确和快速的位置定位预计将为各种行业带来创新和效率。',
            dot: ''
        },

        {
            head: '',
            subHead: '',
            desc: '',
            dot: ''
        },
    ],
    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: '通过将UWB终端连接到工人或设备来实现实时位置跟踪的产品。',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/cn/enterprise/logistics/asset'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/cn/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/cn/enterprise/manufacturing/asset'
            }
        ]
    }

}
