import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { logisticsAsset, manufacturingAsset, manufacturingProcess, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {

    helmet: '',
    desc: '利用UWB的质量和库存管理解决方案通过各种优势增强企业竞争力，最大化效率，是一项创新的技术。',
    keywords: 'UWB RTLS、室内定位、资产跟踪、非接触支付系统、智能家居、医疗保健、汽车安全、UWB、RTLS、GPS、BLE、数字孪生',

    ogTitle: '',
    ogDesc: '',
    ogSite: '质量和库存管理解决方案：UWB :: Orbro博客',
    ogUrl: 'https://orbro.io/cn/blog/qa-uwb',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDlVCtmGkr2mCVSS69T6u1T4F6EZiqevSZ-7cN786FOvXV8x22WHoof7IcLlSW7d3d4DnUPZT2QfBIuUWr_weryhpgDPEa45E1edX4NBzbCnjImjTiKPVNkJrnd-hMccXsYDNleXYSmD_YNZSLPjAwSMKKN90Mx3tpkR9a7UTKQ-cImQeFt0AOvs96-ik71EqDzH3cdqAWsBQ7aU_aLz_wlgsJwZMUUAAPPY7lxS8lWkog24zYM8BWi2P4-bg6lZ0IL5Be72Kuu1Ga2-E828VnyLDcaIkXnXbFqBqFoW57RzNMYcpG-bH3VEhchLwDniUo-jBNqflu56GYJZFz_CMBt-PdbwZEXzyNVxVyomHJgFQ-_tuofm4uNMx6DdvQ3EAbi99lfqXxlwE05tg7IFIIn88NzPmt6B_D-g0Ujw73JyDNlyzOefzda1AisrhVvXXHE12gEZAQEAnDVsf3mDImb9t9WffWNd047vV9q6GWhzE9BNt5ibIfCXZxVJD3Q6xWSICvlyRJdbFKPLMbsjwe07izGimOadYEIgqo7IBq0sH9kw8I0azDR2iJKcAEFexI4TBxg0uvQJRTqbePuIdd8hvDRm7HcFvd3-X8PctgzhJPrN4y7nSMWlWSH77CEDaYptKymzQvANITzUqYwh8_MWoPcMYKdM-3z8fuRhtut9qckVa8HbX0lFmfPE1hci-P_pz7Wx-jCb2pXgdSoVENciMiiHeng7uHM3BtBM0-2GKsCINKIFKGJs8iD-kgEiH8VWr3SkIVWJzkR-Bi63wUR-khcKdv2u2jwsQV0ClNREfUWWQpiEZxfFyw2zK8KMPxRSk4hhKh_hNeIagh-ygtgeLYzblJicZL7Hbl5w7USiFatqiLkaNKzBimapAPyMde_FZuPRAQie5tC95ZtYPRyXGGMGA1qXV7oZ2GpzqwBkG9wU_MTYQDznkHb3cJvSYUWVGT6J704WQWlETRLd2Dl7N60sZ50lDknqhXG1ZfGGF8qcBgIAXqfuUF-nRIYqZqwHoObsg_F_5iBMZzejZ82s1gbIGHR3MKaOqJy2hZOFgZfKdIST5uJHCKvc22R3enzPv502iA-rkaOY5n5fbWlFGYCB7vwvoWYspA8zGdUhQAee4hAWRQrn4L65-tc4n0rKFKBUqDi_tE5q7d9CLdGirQixGEhZc1pP8SOT9wRECaNgtzQjZf5lrjBdtjBR6oi8m6_cDiZsyHY2kZZVIQBodyLG9VToaONzqqNbcfLdQJ9PnDwxV0zS2UV1hpZIJSoCjk3fLgUn_rHE4yBlkubsupv3o4b1uUuWOSmCl2z8Bbowg6pub3WJAQ92GZa9l06BsP8H7wNS5c0B-pMOockKojLd5B-uMVbslrDK1KUS-0_00CRW9N9KzUg0-jtCM62SDPsilmcnPFwxO5bcNMVXCaUwDa5GVDhKgXXsi4aZ5tbkxZhH3RHDPSLjNtVr6KNeUCBt4Nezhhyp3ETy9B9L0m28nYfY5KUDdzpzwo68QW5GQMDP4bn4vq9pl7_oKviMByvEuvJPzi4pa1LZ9HlqTCFEf3RSRvMv_UhyZQ=w1851-h1197',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '质量和库存管理解决方案：UWB',
    date: '2023-12-18',
    tag1: '#质量管理',
    tag2: '#库存管理',
    tag3: '#uwb',
    tag4: '#rtls',

    // <br/>
    text: [
        {
            head: '',
            subHead: '',
            desc: '在现代商业环境中，高效的质量和库存管理在企业的成功中起着重要作用。借助最新技术的引入，正在出现改进这些管理流程的各种解决方案之一是利用超宽带（UWB）技术的解决方案。<br/>UWB具有高精度的定位跟踪技术，由于其宽带，可以提供精准的位置信息。将此技术应用于质量和库存管理可以为企业提供各种优势。',
            dot: ''
        },
        {
            video: video,
            link: '/cn/enterprise/manufacturing/asset',
            head: '',
            subHead: '提供高精度的位置信息',
            desc: 'UWB技术提供高精度的位置信息，使企业能够准确追踪产品和资产的位置。这使得实时跟踪产品移动路径并精确识别物体位置，从而实现高效运营。',
            dot: ''
        },
        {
            head: '',
            subHead: '实时数据检测和分析',
            desc: 'UWB解决方案实时收集各种数据。通过这些数据，企业可以迅速检测到生产过程中的问题或变化，并通过数据分析迅速了解问题的根本原因。这有助于迅速响应和改进，提高生产质量。',
            dot: ''
        },
        {
            head: '',
            subHead: '提高能效',
            desc: 'UWB技术低功耗运行，同时提供高精度。这提高了系统整体的能效，并延长了设备的电池寿命。提高的能效对企业的可持续性也产生积极影响。',
            dot: ''
        },
        {
            head: '',
            subHead: '实时自适应控制',
            desc: 'UWB解决方案通过实时检测和分析数据，实现自动自适应控制。这优化了生产线或仓库的工作流程，并通过基于过去数据的预测分析帮助改善未来运营。',
            dot: ''
        },
        {
            head: '',
            subHead: '加强安全性',
            desc: 'UWB使用无线通信，与其他无线技术相比提供更高的安全级别。它具有极低的物理干扰，通过确保安全通信增强了企业资产的安全性。',
            dot: ''
        },
        {
            head: '',
            subHead: '可定制性',
            desc: 'UWB解决方案可应用于各种行业，并可以根据企业的特定要求进行定制。这种灵活性使企业能够独立改进其系统或引入适应特定运营环境的解决方案。<br/><br/>利用UWB的质量和库存管理解决方案通过各种优势增强企业竞争力，最大化效率，是一项创新的技术。',
            dot: ''
        },
        {
            head: '使用案例',
            subHead: '制造业',
            desc: '利用UWB的实时位置追踪系统在制造业中用于高效的生产管理。精确追踪原材料、零部件和产品的位置，优化生产过程，减少缺陷率。',
            dot: ''
        },
        {
            head: '',
            subHead: '物流和仓库管理',
            desc: '在物流公司或大型仓库中引入UWB可以实时追踪货物的移动路径，优化库存管理。特别是在大型仓库中，通过UWB实现的自动化引导系统可以实现货物的高效库存布置。',
            dot: ''
        },
        {
            head: '',
            subHead: '医疗领域',
            desc: '医院或医疗机构可以利用UWB追踪医疗设备或患者的位置，从而提高应对紧急情况的能力并提升医疗服务质量。',
            dot: ''
        },
        {
            head: '',
            subHead: '购物和零售业',
            desc: '零售商可以利用UWB分析商店内顾客的移动模式，并实时了解产品的库存状况，从而改善库存管理和顾客体验。',
            dot: ''
        },
        {
            head: '',
            subHead: '建筑业',
            desc: '在大型建筑工地上，通过利用UWB准确追踪设备和工人的位置，可以增强安全性并优化工作日程。<br/><br/>这些案例显示了UWB位置追踪技术在各种行业中取得的成果。通过引入UWB解决方案，各种企业提高了生产力和效率，确保了竞争力。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: '将UWB终端设备安装在工作者或设备上，以实时追踪位置的产品。',
                img: productImg.product.assetTracking.thumbnail
            },
            {

            }
        ],
        solution: [
            {
                img: manufacturingProcess.thumb,
                data: 'manufacturingProcess',
                link: '/cn/enterprise/manufacturing/process'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/cn/enterprise/manufacturing/asset'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/cn/enterprise/logistics/asset'
            },
        ]
    }
}
