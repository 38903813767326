import { accessAutoThumb, deadboltThumb, emlockThumb } from '@assets/images/main/access/product';
import { IconOsOff, IconOsOn } from '@assets/images/main/icon';
import {
    accessKey1, accessKey2, accessKey3, accessKey4,
    accessOutlook,
    IconBox, IconComputer, IconGuarantee,
    assetOn, workerOn, workerOff, IconPath, IconLocation, IconAnalyze, IconAlarm, IconExcel, IconSDK, assetOff
} from '@assets/images/main/index';
import { RtlsFunctions1D, RtlsFunctions1M, RtlsFunctions1T, RtlsFunctions2D, RtlsFunctions2M, RtlsFunctions2T, RtlsFunctions3D, RtlsFunctions3M, RtlsFunctions3T, RtlsFunctions4D, RtlsFunctions4M, RtlsFunctions4T, RtlsFunctions5D, RtlsFunctions5M, RtlsFunctions5T } from '@assets/images/main/rtls/main/functions';
import { RtlsHardware1D, RtlsHardware1M, RtlsHardware1T, RtlsHardware2D, RtlsHardware2M, RtlsHardware2T, RtlsHardware3D, RtlsHardware3M, RtlsHardware3T, RtlsHardware4D, RtlsHardware4M, RtlsHardware4T } from '@assets/images/main/rtls/main/hardware';
import { assetTag, twinTracker1, twinTrackerBracket } from '@assets/images/main/rtls/product';


export default {


    banner: {
        title: '位置測位の始まり',
        desc: '位置測位システムを導入して生産性と安全管理を開始しましょう。',
        schemeTitle: 'あなたに合った位置測位を選択してください。',
        schemeDesc: '決定するのにお手伝いが必要ですか？ ',
        schemeDesc2: 'までお問い合わせください。<br/>専門の専門家がお手伝いいたします。',
        phone: '(+82)1522-9928',
        card: [
            {
                title: '国内唯一',
                desc: '位置追跡に適用<br/>ネットワークデバイスの製造',
            },
            {
                title: '50億以上の記録',
                desc: 'アジア最大のスケール<br/>リアルタイムの位置情報データ',
            },
            {
                title: '世界クラスのパフォーマンス',
                desc: 'AIアルゴリズムを使用した<br/>最高水準の位置精度',
            },
        ]


    },

    mainPage: {
        product: {
            title: '製品構成を確認する',
            desc: '革新的な位置測位製品構成を確認してください。',
            asset: {
                title: 'Bluetooth(BLE)位置測位',
                desc: '説明：「BLEタグデバイスを対象物に取り付けて、資産として監視または追跡し、リアルタイムの位置追跡が可能です。',
            },
            worker: {
                title: '高精度UWB位置追跡',
                desc: '説明：「UWBタグデバイスを対象物に取り付けて、資産として監視または追跡し、リアルタイムの位置追跡が可能です。',

            }
        }

    },


    video: {
        title: 'ORBRO位置測位システム',
        desc: [
            { text: 'ダッシュボード機能を通じて機器の状態、タグ端末の変動推移カテゴリ別分類現況、区域別現況を<br/>一目で素早く確認することができます。' },
            { text: 'デバイスを基準に、対象の位置と現状をすばやく把握できます。<br />正確なデータと簡単なインターフェースを提供します。' },
            { text: '作業空間から危険区域まで素早く設定でき、<br/>区域別現況を素早く把握できます。' },
            { text: '危険区域に進入する場合をはじめ、<br/>様々な状況に適したアラームを素早く送信できます。' },
        ]

    },

    functions: {
        title: '革新的な位置追跡システムの体験',
        desc: 'Orbroの位置追跡システムは、ウェブベースの管理およびモバイルアプリを提供することで、既存の位置追跡システムの中で最高のユーザーコンビニエンスを提供します。',

        line: 2,
        card: [
            {
                size: '3',
                tag: '統合管理ソフトウェア',
                title: '屋内マップに基づいたソフトウェア',
                label: '管理者',
                img: {
                    desktop: RtlsFunctions1D,
                    tablet: RtlsFunctions1T,
                    mobile: RtlsFunctions1M
                },
            },
            {
                size: '1',
                tag: 'モバイルアプリ提供',
                title: 'モバイルアプリを介した位置追跡',
                label: '共通提供',
                img: {
                    desktop: RtlsFunctions2D,
                    tablet: RtlsFunctions2T,
                    mobile: RtlsFunctions2M
                },
            },
            {
                size: '2',
                tag: 'デバイス履歴管理',
                title: 'ゾーンごとのデバイスの位置のリアルタイム記録',
                label: '管理者',
                img: {
                    desktop: RtlsFunctions3D,
                    tablet: RtlsFunctions3T,
                    mobile: RtlsFunctions3M
                },
            },
            {
                size: '2',
                tag: 'ゾーン設定',
                title: 'ゾーンごとのデバイスのリアルタイム追跡',
                label: '管理者',
                img: {
                    desktop: RtlsFunctions4D,
                    tablet: RtlsFunctions4T,
                    mobile: RtlsFunctions4M
                },
            },
            {
                size: '2',
                tag: 'デバイス管理',
                title: '便利なデバイス管理',
                label: '管理者',
                img: {
                    desktop: RtlsFunctions5D,
                    tablet: RtlsFunctions5T,
                    mobile: RtlsFunctions5M
                },
            },
        ]
    },

    hardware: {
        title: 'コアはハードウェア、すべてが軽やかに革新',
        desc: '当社の位置追跡システムのデバイスは、指向性アンテナと現場の環境を考慮したデザインが施された特別製のデバイスです。最適な性能と安定性を実現するために、指向性アンテナを適用し、さまざまな状況で効果的に動作します。',
        card: [
            {
                img: {
                    desktop: RtlsHardware1D,
                    tablet: RtlsHardware1T,
                    mobile: RtlsHardware1M
                },
                tag: '指向性アンテナ',
                title: '指向性アンテナによる正確な位置追跡',
                desc: 'TwinTracker（位置追跡デバイス）の指向性アンテナは、無線信号の方向を推測できます。'
            },
            {
                img: {
                    desktop: RtlsHardware2D,
                    tablet: RtlsHardware2T,
                    mobile: RtlsHardware2M
                },
                tag: 'さまざまなブラケット',
                title: '天井埋め込み型、レイ設置可能',
                falldown: [
                    {
                        title: '天井埋め込みブラケット',
                        desc: '石膏、テクス、SMC、マイトンなど<br/>さまざまな天井に取り付け可能。'
                    },
                    {
                        title: 'レール型ブラケット',
                        desc: '照明に使用されるレールと同じ規格で<br/>天井にレールが取り付けられていればいつでも取り付け可能です。'
                    },
                ]

            },
            {
                img: {
                    desktop: RtlsHardware3D,
                    tablet: RtlsHardware3T,
                    mobile: RtlsHardware3M
                },
                tag: '革新的な技術',
                title: 'TDoA技術を活用した最小の設置、最大のパフォーマンス',
                desc: 'TDoA技術は、高性能な通信を維持しながらバッテリーの消費を革新的に低減させた通信技術です。リアルタイムで動作しながらも、位置の精度は従来よりもはるかに高い数値を記録します。'
            },
            {
                img: {
                    desktop: RtlsHardware4D,
                    tablet: RtlsHardware4T,
                    mobile: RtlsHardware4M
                },
                tag: 'さまざまな通信方式',
                title: '有線通信、無線通信、両方が接続可能',
            },
        ]
    },


    faqRequest: 'いつでもお問い合わせ',
    faqRequestTail: 'ください。',
    FAQ: [
        {
            title: '技術的な専門知識はありません。 まだ使用できますか?',
            description: 'ORBROシステムは、技術についての知識がないユーザーにシンプルでありながら強力なインターフェイスを提供します。 <br/>何よりも視覚化されたデータを通じて直観的な分析と使用が可能なシステムを作りました。<br/><br/>'
        },
        {

            title: 'ロケーション トラッキングはどれくらい洗練されていますか？',
            description: 'ORBRO RTLSシステムの精度は非常に洗練されています。<br/>リアルタイムデータを反映することが重要なので、誤差なく正確かつ迅速にデータ連携が可能です。<br/><br/>',
        },
        {

            title: `これらの製品で場所を測位することは可能ですか？ 他の機能を追加します。`,
            description: `ORBROのテクノロジーには、拡張性があります。<br/>ロケーション トラッキングだけでなく、ルートの特定、ルートの逸脱、アクセス不能ゾーンの設定など、さまざまなアプリケーション技術を適用できます。<br/><br/>`,
        }
    ],


    bannerData: [
        {
            icon: workerOn,
            iconInv: workerOff,
            image: '',
            iconText: '高精度UWB<br/>位置追跡',
            sub: 'New',
            link: '/jp/rtls/uwb'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/jp/os'
        },

    ],



    product: {
        asset: {
            func: 'バッテリー内蔵の製品で、電源工事は必要ありません。出入り口の近くに取り付けて、モバイルで簡単かつ迅速に出入り制御ができます。',
            key1: accessKey1,
            key2: accessKey2,
            key3: accessKey3,
            key4: accessKey4,
            outlook: accessOutlook,

        },


    },


    purchaseDesc: {
        asset: "説明：「BLEタグデバイスを対象物に取り付けて、資産として監視または追跡し、リアルタイムの位置追跡が可能です。",
        worker: "説明：「UWBタグデバイスを対象物に取り付けて、資産として監視または追跡し、リアルタイムの位置追跡が可能です。",

    },


    purchase: {
        //모바일
        asset: {
            assetTracking: {
                icon: accessKey1,
                title: '資産位置測位',
                serial: 'Asset-Tracking',
                price: '5,900,000',

                note: [
                    { text: 'TwinTracker (BLE) 10台', icon: IconBox },
                    { text: '資産測位タグ 30台', icon: IconBox },
                    { text: 'Server PC 1台', icon: IconComputer },
                    { text: '3年間の保証期間', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'Asset Tag(BLE)',
                        price: '30,000',
                        img: assetTag,
                    },
                    {
                        title: 'TwinTracker(BLE)',
                        price: '300,000',
                        img: twinTracker1,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '100,000',
                        img: twinTrackerBracket,
                    },

                ],
                mark: ""

            },

        },



        //자동문 출입
        worker: {
            workerTracking: {
                icon: accessKey1,
                title: '作業者位置測位',
                serial: 'Worker-Tracking',
                price: '5,000,000',

                note: [
                    { text: 'TwinTracker (UWB) 10台', icon: IconBox },
                    { text: '作業者測位タグ 10台', icon: IconBox },
                    { text: 'Server PC 1台', icon: IconComputer },
                    { text: '3年間の保証期間', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'Tag(UWB)',
                        price: '60,000',
                        img: emlockThumb,
                    },
                    {
                        title: 'TwinTracker(UWB)',
                        price: '300,000',
                        img: deadboltThumb,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '100,000',
                        img: accessAutoThumb,
                    }

                ],
                mark: ""

            },
        },


    },





    func: {
        title: '位置測位機能を確認する',
        desc: '位置測位のさまざまな機能をご確認ください。',
        data: [
            {
                img: IconPath,
                title: '経路分析',
                desc: 'リアルタイムでデータを蓄積し、時間帯別の経路を分析できます。'

            },
            {
                img: IconLocation,
                title: 'リアルタイム位置測位',
                desc: 'Orbroの位置測位システムはリアルタイムで位置を測位します。分析された機能で現場を生き生きとモニタリングできます。'

            },
            {
                img: IconAnalyze,
                title: '分析ツール',
                desc: '数多くの位置データを収集し、データ統計とインサイトを得ることができます。'

            },
            {
                img: IconAlarm,
                title: 'アラーム設定',
                desc: '人が出入りしてはならないエリアに危険エリアを設定し、アラームを受け取ることができます。'

            },
            {
                img: IconExcel,
                title: 'Excelダウンロード',
                desc: '必要なデータを整理してExcelデータをダウンロードできます。'

            },
            {
                img: IconSDK,
                title: 'SDK提供',
                desc: 'Orbroの位置測位システムはSDKを提供し、お客様のニーズに合わせた開発をサポートします。'

            },
        ]


    }


}