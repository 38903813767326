import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../../cn/main/product/productImg";
import productPurchase from "../../cn/main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {

  helmet: '| 选择RTLS提供商时的主要考虑因素',
  desc: '最近，公司积极采用实时定位跟踪系统（RTLS）以最大化生产力和资源效率。然而，在选择RTLS系统时，需要考虑各种标准。本文将更详细地讨论选择RTLS提供商时需要考虑的主要因素。',
  keywords: 'rfid、nfc、uwb、无线通信、位置跟踪、物体位置跟踪、人物位置跟踪、标签、信标、rtls、orbro、contech',

  ogTitle: '',
  ogDesc: '',
  ogSite: '选择RTLS提供商时的主要考虑因素 :: Orbro博客',
  ogUrl: 'https://orbro.io/cn/blog/rtlsCompany',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDwtIISjDSVCDSnp4Nx3cGb1Ozp2BSmcdnEapCFup7uDaNWXxV2V1-fB8A3HklTGwTcrHkdZ_cA7VDTUd4UkTfLspAq0d4uZPq6ZeulzJqvcifS9Bw-xfTVTFgfZXklSlXh1rq8NsXP6Ah2aN-bYrwpNREltJcCR5HW7d-IJmK-9gIpde_688lL9UU2BeQ5E0P1A2ESvEXcd8-LuzUEVRs3e3jfbDPxSjg9ydKLvn705yJSBD6rpEUC5iU8jQGXfKbB2Hr7A88JMwRLphNHv6zieJqhSM1dv8ESi8X3l8jH3DBJB7E8xvlicUOt1-QvsMD65qm87niZDWettcJyS6XnpOwmmmhHTqiLe-0DzPjVRtGmz1mkzs3JHNrhxNp40cCE6NK3Mp5RQBKUy190oEUBDb1bb2MLa-cs_xu8wcwQLNf8K_d9HDtyMlsLD6XHKShUDydJm2H9iNfI2TsHCpj6qOmc5IfB42F4WCFQuGHtpbKj7mKOvCe1BqtRhoXzmAtiHmghM8jzrLZQkWCMDJE79cfRTqO-JSBpPwJi0g-Zov4puGycni3TFAD7lHlCAKw8wLWn6xZuaCkMGG79wJ32p6xa_Gp2JLiSbVJNsqYu1BWb54Wwb5tVdGsx8W8tWYwv3BzJqrafHi9FtyYaKunD2m3qcDZNbkcTyIcXeQsOcj8C2BEp9WiOJELl6A_etzBinI5fHqf6UwTzJ_LTJ-FtBHkB-5bH-qIitO-2Gf2FxzgXE65QclQQhjI_nD25uwKI6Gos0YU0s4EwlQoIlcptoFn8dWovqaERvfkgME5C8EgN_W8HKqq5J8j6kUqUwnT_dJR9QTJDp2MDFFUeF6vV_R8-eq_qquZuYhfQDKqVcx6SSkIgQB8p_pJ-Q_DJZCg-4iLTx3gP7b24S32g5jEMhTnx0zpHHsdzc8MIxzySrRt5bT5AfNasqvrWHqPWa_t9MM-0iHWjR9lR8hWEL17y8XdEKjF5igOYGdR0UEvs1ZcFGvvNTV3vdiNCk3rNaC89ZJ9m6olPqAFIcdEbc2qAgG78-MbI33yCmidAD9hPBPR2dyPg_pXmHHqIlXT14EoAoDu18wbBu_wQP41ZPemWH-X-U_BUK2IdUQJP4CvLlFNs6ghZbwn13En9tXaPpl0SFBQB5v6riEQxDujwiMEvoi65V8m170vbu8L-NRuQ5KJoWd2GOmxBhOtupoe3t4FTv-DjmFjImqiCeGOq4-iyWcmU55jv4gk1_ugniXiZVGSGpvb2WJJABeN6xgNiT_9z_L6VwCfK6aMHs9cE8S_sv2yc2qBi3_SeEM5xTIr8pmU_qG4r1P_C1GVdiinD8I8c56mIUcX4Sz8chmHYiLwlTtIlj1nweMwToAiXHsvztud9HFsIdPNvBaVIkvl-6I7a0UHtkO699f9aK2qT2MiYWrueCLYE9V3_ibpgYgbxxyS5crADSB-7YJ--6S4n6eD-r4bUkMHVz97z6X6HV_uHS5B4jce8CeOOdEUpUMMvTgWZ6Hs7FP4fGhrAwhGUTRFRsZ_93Z7YmOWXx4WJLxdyoABRmg3TOA1ghvuCqDjiUA14wOTD3=w1775-h1190',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '选择RTLS提供商时的主要考虑因素',
  date: '2023-11-15',
  tag1: '#rfid',
  tag2: '#rtls',
  tag3: '#uwb',
  tag4: '#解决方案',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: '最近，公司积极采用实时定位跟踪系统（RTLS）以最大化生产力和资源效率。然而，在选择RTLS系统时，需要考虑各种标准。本文将更详细地讨论选择RTLS提供商时需要考虑的主要因素。',
      dot: ''
    },
    {
      video : video,
      link : '/cn/enterprise/manufacturing/asset',
      head: '技术的成熟度和准确性',
      subHead: '',
      desc: 'RTLS系统的核心在于位置信息的准确性。您应该验证提供商的技术成熟度以及其提供位置信息的准确程度。通过性能评估和实际使用案例，特别是通过技术的性能评估和实际使用案例，可以可靠地评估提供商的技术能力。',
      dot: ''
    },
    {
      head: '在各种环境中的适用性',
      subHead: '',
      desc: '公司在不同环境中执行各种任务。因此，需要确保RTLS系统能够在各种环境中有效运行。考虑提供商如何保证在不同环境中进行测试和应用，并考虑其提供适用于各种行业的解决方案的能力。',
      dot: ''
    },
    {
      head: '可扩展性和灵活性',
      subHead: '',
      desc: '公司的规模和业务需求可能会随时间变化。RTLS提供商具有轻松扩展系统并灵活调整的能力非常重要。此外，收集有关应用新技术或更新的信息也很重要。',
      dot: ''
    },
    {
      head: '成本和投资回报分析',
      subHead: '',
      desc: '由于采用RTLS会产生投资成本，因此需要从成本的角度进行仔细考虑。了解提供商的定价政策并分析采用后的预期投资回报（ROI），以考虑长期的投资回报。准确计算成本效益，以做出业务的最优化决策。',
      dot: ''
    },
    {
      head: '安全性和数据隐私',
      subHead: '',
      desc: '基于位置的信息可能是公司的重要资产。验证RTLS提供商是否具有强有力的数据安全和隐私保护政策。合规性也是一个重要的考虑因素。选择能够安全保护客户数据的系统至关重要。',
      dot: ''
    },
    {
      head: '客户支持和服务',
      subHead: '',
      desc: '在RTLS系统出现问题时，需要快速响应。评估提供商的技术支持和服务质量，并了解提供了哪些教育和培训计划。此外，检查提供商的客户响应和问题解决能力是否出色，以建立可靠的合作关系。',
      dot: ''
    },
    {
      head: '',
      subHead: '',
      desc: '由于RTLS系统可能在公司的运营中发挥关键作用，因此考虑这些各种标准来选择RTLS提供商，可以帮助构建有效的位置跟踪系统。选择正确的提供商将有助于公司未来的增长和效率提升。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '通过将UWB终端附着在工人或设备上，协助实时位置跟踪的产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/cn/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/cn/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/cn/enterprise/office/asset'
      }
    ]

  }

}
