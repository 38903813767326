import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/constructionSafety/video/rtlsVideo.mp4";

export default {

    
  helmet: '| 严重事故罚则法预防解决方案：建筑工地安全管理',
  desc: '为了预防严重事故和罚则法，建立一个智能且安全的建筑工地，通过实时追踪工人的位置来进行安全管理。利用 UWB RTLS 技术，提升建筑工地的安全性。',
  keywords: '工人安全管理，建筑工地安全管理，建筑工地，现场安全，建筑安全，智能建筑，重型设备管理，建筑工地安全管理综合信息网络，实时位置追踪，安全管理，rtls，uwb，orbro，kongtech',

  ogTitle: '',
  ogDesc: '',
  ogSite: '严重事故罚则法预防解决方案：建筑工地安全管理 :: Orbro 博客',
  ogUrl: 'https://orbro.io/cn/blog/construction-solution',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxcFbqyCgsLqX77SjZSbmhPyHShDbN57Y0uVQssyTRIySb5QaO86GwRQU2XDIM6wENVOKG_sn4SuK6BIHu6D3_n9v4DTFhHsRRVUg7UmWsG9qrjE9rGDPe6P0_Ox2um950sgEHtj-KLjzqld-ZdzNkaE9QjgTMEVmj2tkQ1pPVRozYUD-xIl94PQaGNp7tQuO1Hob1FvMuSLLEcdXdUxZmR9CoCl4ArjWfuuwr1d6lvN2umbSY0_y7mc8jTFyCu9Dk5Wi_v3aDRSH29LhUBlpZyeEyM5oCecT8fbW04YC3xCmuCmG41FwPtKf2akaJlFuBsSXINWwYzKejipOUhx5BayplcpMLZKsJoGI_6BI4RTrNIGGFpS4LZqAmgN3JWX43wftmGOlcwJ2np2WFszY2X5QqQdeAdnTXKLzgiYRCcjrM0cHzvzsLebg0KbpyAgCiLawwttYXLmqYEUsw-7it4OWqarLHXZxq4K2hggeMrpWJVFc6R84ruA7y4WPP4lNxVTbcGz3h0tcwGbYv8mGEc5UjMsDalMc8fOmsS5_Dy9qrOewKS-A4oZsW0bBJ1IYnhhHgCvKL4VP19n_PzOnu51vLF_B2xWmnR6pT5F3wa7GapNjG0QEN3185QO4AI9hysQXwb4wLEZqT5dGeHzwRvfIfrp_NCJ6xUbTHt3W070iXbqCG-7liIoaYzwbWYUA_U21h4elbMhPdZqvaIJBQRWBRRKxo8RdjyguhL-3b3LSOd4ffx3PJuvI6T7d5Q89IwZccvi2VLTR2Z5XiTUAveb8J_U3zPTKmIliM2rNcFCQ2zUW61y3Je0voWlaxLRy-_eHoH-tLuixemebIJo770TZZk69mhoNcuzZf0HepRpMoZ_gK7WX5gZ9ybogR9JvNsEQ6do078xyLvTKuZQpri86vobe9PvXeTkVM-_h6agSAcUfUihK0GLiehcw4MjrNaXaq-eqEEEf_aWtfPJAjuJ7JXtFXOiuO6hBMA1-qPq6e_VOOcRJl3PigwW66TzDYgjowTnaD7M1dWDXFYFz_OJS0u8UQbmeKd1TT5h30l70YXeabd4BvtI33YLCL_fJ2ReOIzwUmzZZiEBplwIAxzDcNPvmVwfd5YzvxVGdtm3WEBbzZR0q-MdVvbABRxn90yZ7U2vcwgvNbSTJbC5aWQyI7Jd6LiooIa8nWVkVwaMK_vOmDwotOk2vnamSORh837qPfvlDmUuBX7RRN19heNUGbFF20032J3_JRYcT3AA8eYmTV8LKhQuF0eNQAUe40lPKPGrdrnDHBsjBdHgoOxOHd-ChckYyCFOL3a-FpQv4OO_OpXL6lWWv4SwJsdzXxWT0h2uqoGu0ONpkDv7O3WiZF4E3cQ46IlBsU_K4JWsh1R9pjjLBSRy61vgbvfD4a7yzwU-qvZ6sd5wm6HyVVOU4pmgYoH1SG86ZISmHsUXYxdrWo4vuJDA1If2gUg6P7Y7uMooHmA2MlFPgVsT3xqakmYR-xx3Z3gZnIbcFzO7LldLuSvs8Ha8nMoI9HM2dh6uxK5_9OTfmQRIGHTZngWgCBZrRIz4ARaIxBZiw=w2249-h1298',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '严重事故罚则法预防解决方案：建筑工地安全管理',
  date: '2023-12-11',
  tag1: '#严重事故罚则法',
  tag2: '#劳动安全',
  tag3: '#建筑工地',
  tag4: '#RTLS',
    
  // <br/>
  text: [
    { 
      head: '',
      subHead: '',
      desc: '为了建立一个安全的建筑工地，实时监测工人的安全情况，预防事故并在发生时迅速应对是必不可少的。特别是考虑到严重事故罚则法，有效地利用“实时位置追踪技术”是至关重要的。',
      dot: ''
    },
    {
      video: video,
      link: '/cn/enterprise/construction/safety',
      head: '1. 实时位置追踪技术的关键功能',
      subHead: 'a. 精准的位置识别',
      desc: 'RTLS 技术利用 GPS、RFID、UWB 等实时追踪建筑工地工人的精准位置。通过这种方式，创造一个安全的工作环境并提前识别危险区域。',
      dot: ''
    },
    {
      head: '',
      subHead: 'b. 监控工人安全',
      desc: '基于位置数据监控工人的移动，实时确认其工作中的安全状态。快速检测到危险情况或区域，并采取适当的措施。',
      dot: ''
    },
    {
      head: '',
      subHead: 'c. 监控重型设备',
      desc: '不仅追踪工人的位置，还实时了解重型设备和库存的位置，以了解工地情况。这有助于识别与工人的碰撞事故或接近危险区域，确保更好地控制和管理工地。',
      dot: ''
    },
    {
      head: '',
      subHead: 'd. 事故发生时的迅速应对',
      desc: 'RTLS 系统可以快速识别事故发生的位置，并了解事故详情，从而实现迅速应对。在紧急情况下，能够有效地处理。',
      dot: ''
    },
    {
      head: '',
      subHead: 'e. 数据收集与分析',
      desc: '收集并分析位置数据，了解建筑工地的工作模式和危险区域。通过这种方式，为主动应对和改善安全政策做出贡献。',
      dot: ''
    },
    {
      head: '2. 优势',
      subHead: 'a. 强化安全性',
      desc: 'RTLS 通过准确识别工人的位置来增强安全性。在事故发生时迅速作出反应，确保严重事故罚则法的遵守并保障工人的安全。',
      dot: ''
    },
    {
      head: '',
      subHead: 'b. 提高生产力',
      desc: '利用准确的位置信息进行工人的有效安排和工作计划，优化建筑过程，提高生产力。',
      dot: ''
    },
    {
      head: '',
      subHead: 'c. 成本节约',
      desc: '通过事故预防和迅速应对，最小化损失，并通过增加生产力实现成本节约。',
      dot: ''
    },
    {
      head: '',
      subHead: 'd. 有效的资源管理',
      desc: '通过 RTLS 有效地管理建筑工地内的资源，最小化不必要的资源浪费，并实现高效的运作。',
      dot: ''
    },
    {
      head: '',
      subHead: '',
      desc: '旨在最小化事故并改善工作环境的 RTLS 技术，在建筑行业中发挥着至关重要的作用。这将极大地有助于提高建筑工地的安全性和效率。<br/><br/>（点击上面的视频，转到相应的解决方案。）',
      dot: ''
    },

  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
        {
          text: productPurchase.purchase.worker.workerTracking.title,
          title: productPurchase.purchase.worker.workerTracking.title,
          serial: productPurchase.purchase.worker.workerTracking.serial,
          desc: '通过将 UWB 终端连接到工人或设备上，实现实时位置追踪的产品。',
          img: productImg.product.workerTracking.key1
        },
      {

      }

    ],
    solution: [
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }, 
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/cn/enterprise/manufacturing/asset'
      }
    ]

  }


}
