

//export type MainMenuType = 'PLATFORM' | 'APPS' | 'SUPPORT' | 'COMPANY' | 'SERVICE' | 'PRODUCT' | 'TECHNOLOGY';
export type MainMenuType = 'OS' | 'ACCESS' | 'APPS' | 'RTLS' | 'IOT' | 'CAMERA' | 'SERVICE' | 'PRODUCT' | 'BLOG' | 'TECHNOLOGY';

export interface Menu {
  id: MainMenuType;
  name: string;
  path: string;

  submenus?: SubMenuGroup[];
}

export interface SubMenuGroup {
  title: string;
  items: SubMenu[];
}

export interface SubMenu {
  id: string;
  name: string;
  description: string;
  path: string;
  imgDark?: string;
  imgLight?: string;
}

export interface MenuProps {
  active: boolean;
  isLast?: boolean;
  click : boolean;
}

export interface MenuPopupProps {
  menus: Menu[];
  isOpen: boolean;

  handleToggleMenuPopup: () => void;
}

export const MainMenus: Menu[] = [
  {
    id: 'OS',
    name: `layout.menus.OS.name`,
    path: '/os',
  },
  {
    
    id: 'ACCESS',
    name: `layout.menus.ACCESS.name`,
    path: '/access',
  },
  {
    id: 'RTLS',
    name: `layout.menus.RTLS.name`,
    path: '/rtls',
  },
  {
    id: 'CAMERA',
    name: `layout.menus.CAMERA.name`,
    path: '/camera',
  },
  {
    id: 'IOT',
    name: `layout.menus.IOT.name`,
    path: '/iot',
  }

];
