import { assetTag1, assetTag2, assetTag3, twinTracker2, twinTracker3 } from "@assets/images/main/rtls/product";
import productImg from "./productImg";
import { tagUwb1Pop, tagUwb2Pop, tagUwb3Pop, twinTrackerBle1Pop, twinTrackerBle2Pop, twinTrackerBle3Pop, twinTrackerBracket1Pop, twinTrackerBracket2Pop, twinTrackerBracket3Pop, twinTrackerUwb1, twinTrackerUwb2, twinTrackerUwb3 } from "@assets/images/main";

export default {

    products : [
        {   title : "accesscontroller",
            components : [
                {part : 0},
                {part : 1},
        ],},

        {   
            title : 'accessauto',
            components : [
            {part : 0},
            {part : 1},
            {part : 2},
        ],},

       { 
            title : 'accesspro',
            components : [
                {part : 0},
                {part : 1},
                {part : 2},
        ],},
        {
            title : 'assetTracking',
            components : [
                {part : 3},
                {part : 4},
                {part : 5},
        ],},
        {
            title : 'workerTracking',
            components : [
                {part : 6},
                {part : 7},
                {part : 5},
        ],}
    ],


    parts :[
        {
            // 0
            title : "EM Lock",
            serial : "EM-Lock",
            desc : "자동문이 아닌 방화문이나 목재문등 단방향 출입에 주로 사용되는 Electric Lock으로 자력의 힘을 이용하여 출입문을 고정시키는 장치 입니다.",
            price : "150,000",
            img : [
                {data : productImg.product.emlock.mini1},
                {data : productImg.product.emlock.mini2},
                {data : productImg.product.emlock.mini3},
                {data : productImg.product.emlock.mini4}
            ]
        },
        {
            // 1
            title : "데드볼트",
            serial : "Deadbolt Lock",
            desc : "문을 닫으면 자동으로 도어락이 잠기는 자동잠금 데드볼트록입니다. 아파트, 사무실 등의 출입구, 대문 등에 사용할 수 있는 자동잠금이 가능한 데드볼트락입니다.",
            price : "150,000",
            img : [
                {data : productImg.product.deadbolt.mini1},
                {data : productImg.product.deadbolt.mini2},
                {data : productImg.product.deadbolt.mini3},
                {data : productImg.product.deadbolt.mini4}
                
            ]
        },
        {
            // 2
            title : "실내 자동문 센서",
            serial : "Automatic Door Sensor",
            desc : "인체 또는 사물의 움직임을 감지하여 자동문의 개.폐를 위한 유사한 장치 제어하기 위한 고감도, 고성능의 제품입니다.",
            price : "99,000",
            img : [
                {data : productImg.product.accessauto.mini1},
                {data : productImg.product.accessauto.mini2},
                {data : productImg.product.accessauto.mini3},
                {data : productImg.product.accessauto.mini4}
                
            ]
        },
        ///
        {
            // 3
            title : "Asset Tag(BLE)",
            serial : "OAS-OTBT1991",
            desc : "작고 손쉬운 부착으로 자산의 위치를 추적 할 수 있습니다.",
            price : "30,000",
            img : [
                {data : productImg.product.assetTracking.mini2},
                {data : assetTag1},
                {data : assetTag2},
                {data : assetTag3}
            ]
        },
        {
            // 4
            title : "TwinTracker(BLE)",
            serial : "OTR-TTBT0001",
            desc : "블루투스 신호를 광범위하게 인식하여, 인식한 위치 추적정보를 오브로 시스템에 전달하는 역할을 합니다.다양한 설치 환경을 고려하여 USB-C 타입 전원 방식과, PoE방식의 전원을 지원하고유선뿐만 아니라 무선 통신(Wi-Fi)방식도 사용할 수 있도록 설계되어있습니다.",
            price : "300,000",
            img : [
                {data : twinTrackerBle1Pop},
                {data : twinTrackerBle2Pop},
                {data : twinTrackerBle3Pop},
            ]
        },
        {
            // 5
            title : "TwinTracker Bracket",
            serial : "TwinTracker - Bracket",
            desc : "TwinTracker를 조명 Rail에 설치 하여 쉽게 설치가 가능하도록 하는 제품 입니다.",
            price : "100,000",
            img : [
                {data : twinTrackerBracket1Pop},
                {data : twinTrackerBracket2Pop},
                {data : twinTrackerBracket3Pop}

            ]
        },
        {
            // 6
            title : "Tag(UWB)",
            serial : "Tag-UWB",
            desc : "손쉬운 부착으로 작업자의 위치를 추적 할 수 있습니다.",
            price : "60,000",
            img : [
                {data : tagUwb1Pop},
                {data : tagUwb2Pop},
                {data : tagUwb3Pop}

            ]
        },
        {
            // 7
            title : "TwinTracker(UWB)",
            serial : "ORT-TTUW0001",
            desc : "UWB 신호를 광범위하게 인식하여, 인식한 위치 정보를 오버로 플랫폼에 전달하는 역할을 합니다.10cm 이내의 정확도를 측정하며 다양한 설치 환경을 고려하여 USB-C형 전원 방식과, POE 방식의 전원을 지원하고유선뿐만 아니라 무선(Wi-Fi)방식도 사용할 수 있도록 설계되어 있습니다.",
            price : "300,000",
            img : [
                {data : twinTrackerUwb1},
                {data : twinTrackerUwb2},
                {data : twinTrackerUwb3}

            ]
        },

        {
            // 7
            title : "TwinTracker(UWB)",
            serial : "ORT-TTUW0001",
            desc : "UWB 신호를 광범위하게 인식하여, 인식한 위치 정보를 오버로 플랫폼에 전달하는 역할을 합니다.10cm 이내의 정확도를 측정하며 다양한 설치 환경을 고려하여 USB-C형 전원 방식과, POE 방식의 전원을 지원하고유선뿐만 아니라 무선(Wi-Fi)방식도 사용할 수 있도록 설계되어 있습니다.",
            price : "300,000",
            img : [
                {data : twinTrackerUwb1},
                {data : twinTrackerUwb2},
                {data : twinTrackerUwb3}

            ]
        },

    ],









}