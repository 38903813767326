import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet: '',
  desc: 'Loss, theft, and equipment location issues at airports are common problems leading to resource waste. To address these issues, RTLS (Real-Time Location Systems) solutions can be implemented.',
  keywords: 'RTLS, asset management, asset tracking, terrorism, counterterrorism, Incheon Airport, international airport, airport, airport safety, aviation, aviation safety, security',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RTLS Used in Airports :: ORBRO Blog',
  ogUrl: 'https://orbro.io/en/blog/airport',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EOZesBoVKMwIFFry24uoX1306-E2-KIndjXjngk3HQJAqvdeMd-lpFrtUa5bQkacaOFn73rL9aZpISCh6BfSvBY8Rj6pgLzP098EmKNW5WO0k0RSNeqTxeIIE_LiFLF3sUcJc7VhuL6arfAuAQ4APzhaT4aTqEMd8WiAzJwEEVEK3UEevHgYg7ZmwsN-ImqYMFbr01G9VkLQVPdClEaxND6eKem2wvryX-MyUhMvVWmXvao5e8JdFoNh7pmf0ED7MFgYJTg8fxDAxUsbI2jFKwsQCXpbmLsVjR7VcwSNln_gq6BqHDdpB_gte6scXAcPWT5vxnkbmDs22PEDiPDoNcyewtDBBFdtglgRga94O9jfNboErHhvfBncigoMjDxtW7TYVYAps24D2VMURGxOoAx8EquJAIaYIcLlBk2zaSrqdtCeCJGD8Il7sLifXjYw4s7qUr9XFF35LROheqZAC_0kOQ5mI85j5IkNvcvUuT0Wpg9K9Tg1uovCttIBSCD5hfEs1i5gQOxwB7fK0t9kkppR_tDz4HPjIo3i-QfSV3VtiERhnb0gIWUVAFX5jrN8rwX4iTKGpNR55yhogdwRDaSLkHePzB-Xnsn0SwSjXlonVCX02p4Zn02NM8FsMa8fNMfP7Rp4-NIciOdAehNLDjZzwFH6hXgArjIg7_CtUHBeiV5gQ9GX7PqRU4TtZXbljhqePIobwwEd_XHrXRNxAyMxnCU45iqtZUwO_dZyKmhfAZ7XDrUUK0gMaNhguKiZEIm3ZeBZN_082FE9ifEja5-LCI4-QC60gzDs8bYmOrqsotPyR47V6zTrFzRiKDRfwmiLCdbRdQsy3iLV5ph0yhVab492zrx1LaPY39eCtfQzTyDRXrgrwxrAnp54XYMJYZ23TEVgan4Sg7MwiQiYw_Y9z2c1WwAt-TE7rpWOR5Gn2um6jOBrrfo_09UF9nfV4o02ZGF4NWQ99ZIRwBmOg6KODX6mQNtxOjU60pRIpQj0BUCj5JCE5P99E2FRUfxKE89tWwftzf0WpV4XuY3XCnSVpT1DW8OAdsfQyv5GyiSm2ikSlRAq8s0nJAoPv5gcqTR5rwl-QI7YOwucwvhQgM0N3jhPjqcfCsZ0tEP4V-IVLpT1k5_LGmhBueHvi8jZinIgZ0YWZov9jEWK4ysorc637zHhZpy_infWTuHbzXNlLpxoWRsQCbsvGYNKsAVJIi91R85rK5tRbEiMFXA6l67UP2CxWsZHXN_IofYYJmQrT7t6IreFaZrouew2I-5bE-grBY53BCNZ_uk2rE8BPkifZU4ldeMi-0qDyy93SH-XeiDqOWadwsB8yHsrx-6_2UNxAeQzzFEwJtNv0tB4WWtyXgGyX94icliihgFcgXBYPX-t4tGwNJd776YIxod1tvww4WR2a-m5idyeoKDjuWUQrMWHd6JuMKFGGxnIjzUKIQMTx6q2vzwdG62nR80hG1B0_osyET4FeVfNTRdVzDRNcEtrlSSbgcQfMg0Q9LKROQcN6smgad6gObnA6mQB3Ghvo_9zXSkfAufJdY9Kg4g7Px31v1Z2fV3IhA9rzOE5Qg=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RTLS Used in Airports',
  date: '2023-05-10',
  tag1: '#RTLS',
  tag2: '#Airport',
  tag3: '#AssetManagement',
  tag4: '#Safety',

  text: [
    {
      head: '',
      subHead: '',
      desc: 'Loss, theft, and equipment retrieval issues, as well as resource waste, are common problems in airports. To address these issues, Real-time Location Systems (RTLS) can be implemented as a solution.',
      dot: ''
    },
    {
      head: 'What is RTLS?',
      subHead: '',
      desc: `RTLS (Real-time Location Systems) is a real-time location tracking system that allows for real-time tracking, collection, and processing of location information for objects, people, etc. This enables the monitoring, control, and management of the movement of people, goods, and aircraft in airports. Over the past few years, many airports have adopted asset tracking systems using RTLS.<br/>According to market research, the value of the airport asset tracking market was $350 million in 2019, and it is projected to reach $900 million by 2027.<br/>Next, let's see how RTLS systems can help solve problems.<br/>`,
      dot: ''
    },
    {
      head: '',
      subHead: 'Asset Maintenance and Management',
      desc: `Airport staff used to manually visually confirm and record assets. Even if recorded on tablets, errors are prone to occur with human involvement. With RTLS, assets can be accurately tracked, managed, and easily maintained. This also applies to controlling passengers' luggage. RTLS allows for quick identification of lost or stolen items, reducing the waste of human and material resources that used to occur.`,
    },
    {
      head: '',
      subHead: 'Enhanced Safety',
      desc: 'Airports are facilities that must be very strict about safety. Monitoring and controlling the movements of thousands or tens of thousands of people, cars, and luggage passing through the airport every day is not an easy task, especially for humans. RTLS can monitor and control designated areas that should not be accessed by civilians, helping to prevent accidents and terrorism. If unauthorized baggage is placed in restricted areas, administrators can respond immediately. By integrating emergency equipment into the previously mentioned asset management, safety equipment such as fire extinguishers and defibrillators can be tracked, contributing to better airport safety in emergency situations.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Improved Customer Experience',
      desc: 'Administrators can understand the flow of people inside the airport to maintain a smoother airport environment. By identifying peak hours, specific entrances, and gates with high traffic, people can be guided and controlled, allowing individuals visiting the airport to reduce wasted time and have a smoother boarding experience.',
    },
    {
      head: '',
      subHead: 'Cost Reduction',
      desc: 'Controlling and managing large airports all at once is not easy. However, RTLS technology allows for the monitoring of airport information that used to be collected by multiple people (sometimes with errors) in a single dashboard. This reduces costs compared to before while delivering higher service quality.',
    },
  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/en/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      }
    ]


  }


}