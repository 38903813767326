import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/retailExhibition/video/rtlsVideo.mp4"

export default {
    helmet: '| NVR とは？',
    desc: 'NVR、またはネットワークビデオレコーダーは、IPカメラまたはネットワークカメラから送信されるデジタルビデオストリームを受信し、保存するデバイスです。',
    keywords: 'nvr、ip、ptz、rfid、nfc、uwb、無線通信、位置追跡、物体位置追跡、人物位置追跡、タグ、ビーコン、rtls、orbro、Kongtech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'NVR とは？ | 位置追跡とデジタルツインに特化したプラットフォーム、Orbro',
    ogUrl: 'https://orbro.io/jp/blog/nvr',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmL4Stf6lklybaH3OesTetv_ifx8JMPoVd04awuiZ4-02dNTkyV_aHj1fMf2KnR4WC3V8BIU0tp4nnqyMrJ1H3wq2E2MR1v4SC2aV7X0uQuYIPhuSRRExRshekWS5QDY7QauCkENm0TmU7Nd5ybqI8YS9VYjU47WZpMCiPjMURQQed_nvkn0AhE2Yun2VlI0rqIh4wdfyfrV8KCYwxBiuTJu0JyormxuEVYxQX_zzHdRwQHQRmGDKitVvEFslYvVFcRU7zmk1H5su0Nbh0OH-2sr7PH92g1JPUqxCgUlBTxhpLdrL_E6UqQka9fnmGxwy-M42lPeCHvlJ3pjxezqOwNVArbkw4Gtzl8eeAr8IbZ4rZ9wTIRi05-bGw29_Cz-zxphiYy_phH52YKkFQP5NEH11pxn9SDr8Xgj6EZqd95NgevWxTMMi4O1A6iOydRW_EOn4lix025cQB5RehRr0gZlPIVwj_YEku0bG-va9JpG6rAPHUbj_AELcIbLToKJnyJ-DYE-gTASAaIamUNLzKNoXmIGa9phep5L_nIkwEHdA-qzT5dyrmhQDpc4d1ruaYFLHWV-bb-ETaORk4Sdr3Y-aqznGhdsafAvy0lGxYU_DMVM_emH2uyyVFq71xraelVNooi5Qoe-PsPuj8BnSOTSZeLGWmuglDlrgmTuEpFNqsVou185a6VQJO6s71py93y5OpnwMtj3FmATd_eeN9ZY43KUQ2iLDXw125g3abOP4HE4YnRCCsMjz3Vray4TP2_m_9QoRm8OuJBGF4A2CbGJ2M8oLmEQ1Mc3VpNZ9WwdYkGXpBY8yCLEuDwxqeKNMUTkJwbr4YNdpja17MLWg9XHFoVNlG64qk-VkBi4IFSFMDx4LaZD9XsGttmU9bBIIT2jJldP5PYCfTjffwwstwZ4VNG-3bxzqfsCZ3CGeihhAYm1knV-ullCOomsYIaweJwLK2MMRFkxlpRPuT0bbJnFWuCdMoydkNYf3JMh0Bi2kok0mrqpGDEqywp9sccVcp8wZkG1XRVZJPJjwSd2kbiJNR2DfwoneK2ivKwpqhT5kRrl9cpuclYrRgIqnlOuce-kuOj0dJMTozXERXeWUzhXWX4EFywOc-1IoTXFtxiPQL76CyCsHtYImgIoegeus-2_6ZQZtpmxvYL33d95jUw3J_yUPkWETDzHro5RGKOD5RDM41CMUEokOzoWbxmbO0MufhfRGY_PnM5W16XGqSZGRTwenay9yrEB3IPluMmrdpervFylEfCPYJyutGFQigW5iY-tvjM8JVOyzk3OqLzqq9LjnJAyhkCJZ2ZGtPKZPvMvOSlovrdc93ztDGD6dorfc1SWfaxDOi4kQduozkGE8GSEkHRte9hFjdoWMGblqD1Lu4ADPGwr2546UPK93UJbiCtj2SUaSVV8E5R1XD3cy4mKcZ27rsDh_s_os0RaZ8lEQfPHZMmphlOnC2S5AnuXnZqqQWPQJD9b2v8kYV9CS3vW8_Gr3zLg1jcDqGfeBbzTg3g3rtTUPi885LTBUpT_NK2a5Ux-HCwPu1da_Cen1_2-oto=w1984-h1208',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'NVR とは？',
    date: '2024-01-12',
    tag1: '#nvr',
    tag2: '#カメラ',
    tag3: '#セキュリティカメラ',
    tag4: '#位置追跡',

    text: [
        {
            head: 'NVR とは？',
            subHead: '',
            desc: 'NVR、またはネットワークビデオレコーダーは、IPカメラまたはネットワークカメラから送信されるデジタルビデオストリームを受信し、保存するデバイスです。これは一般的に監視およびセキュリティシステムで使用され、従来のデジタルビデオレコーダー（DVR）とは異なり、主にデジタルカメラと共に動作します。NVRは主にネットワークを介してさまざまな場所からビデオを管理し、アクセスできるように設計されています。これは特に大規模な施設や複数のエリアにわたる監視が必要な場合に役立ち、より高い解像度および高度な機能をサポートして優れたパフォーマンスを提供します。機能的には、NVRは複数のカメラから同時にビデオストリームを受信し、これを安全に保存します。保存されたビデオは後で検索、再生、または分析するために使用できます。さらに、多くのNVRシステムは電子メールの通知、リモートモニタリング、またはモーション検知などの高度な機能を提供し、効果的な監視を可能にします。これらの特性により、NVRは高いセキュリティ要件のある環境で広く使用され、ネットワークベースの現代の監視システムの中核的な要素の1つとして確立しています。',
            dot: ''
        },
        {
            video: video,
            link: '/jp/enterprise/retail/exhibition',
            head: 'NVR の機能',
            subHead: 'カメラの管理',
            desc: '',
            dot: {
                a: ' • 複数のデジタルカメラをサポートし、効果的に管理できます。',
                b: ' • カメラのグループ化および個別の設定により、柔軟な構成が可能です。',
            }
        },
        {
            head: '',
            subHead: '高解像度と圧縮',
            desc: '',
            dot: {
                a: ' • 高解像度のビデオをサポートし、圧縮アルゴリズムを使用してストレージの効率性を維持します。',
                b: ' • H.264、H.265などの圧縮標準をサポートし、帯域幅とストレージの使用を最適化します。',
            }
        },
        {
            head: '',
            subHead: 'ネットワーク接続',
            desc: '',
            dot: {
                a: ' • ネットワークを介してさまざまな場所からリアルタイムまたは記録されたビデオにアクセスできます。',
                b: ' • リモート管理とモニタリングが可能で、複数のユーザーサポートにより効果的な協力が可能です。',
            }
        },
        {
            head: '',
            subHead: '通知およびイベント管理',
            desc: '',
            dot: {
                a: ' • モーション検知、ビデオロス、カメラカバー、一時停止などのイベントを検出し、管理できます。',
                b: ' • 重要なイベントに対する迅速な対応のために通知や警告を設定できます。',
            }
        },
        {
            head: '',
            subHead: 'ビデオの検索と再生',
            desc: '',
            dot: {
                a: ' • 特定の時間帯またはイベントに基づいてビデオを検索および再生できます。',
                b: ' • ビデオの分割、ズームイン/アウト、フレーム単位での精密な調整が可能です。',
            }
        },
        {
            head: '',
            subHead: '保存管理',
            desc: '',
            dot: {
                a: ' • ストレージを効率的に管理し、さまざまな保存オプションを提供します。',
                b: ' • 古いビデオを自動的に削除したり、別の保存デバイスに移動したりすることができます。',
            }
        },
        {
            head: '',
            subHead: 'さまざまなセキュリティ機能',
            desc: '',
            dot: {
                a: ' • ユーザー権限管理を通じてさまざまなレベルのアクセス権限を設定できます。',
                b: ' • 安全なリモートアクセスのための暗号化技術をサポートします。',
            }
        },

        {
            head: 'NVR の利点',
            subHead: '高解像度と画質',
            desc: 'NVRは主にデジタルカメラと共に使用され、高解像度および高品質のビデオをサポートします。これにより、細部がはっきりと表示され、必要に応じてより正確な監視が可能になります。',
            dot: ''
        },
        {
            head: '',
            subHead: 'ネットワークベースの構造',
            desc: 'NVRはネットワークを介してカメラと通信するため、ビデオをリモートエリアから簡単にアクセスできます。これにより、リアルタイムモニタリングとビデオ検索のための便利な手段が提供されます。',
            dot: ''
        },
        {
            head: '',
            subHead: '柔軟性と拡張性',
            desc: 'NVRシステムは複数のカメラをサポートし、必要に応じてシステムを簡単に拡張できます。新しいカメラを追加したり、システムをアップグレードしたりすることが容易であり、セキュリティ要件の変化に柔軟に対応できます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'リモートモニタリングおよび通知機能',
            desc: 'NVRはリモートからビデオモニタリングをサポートし、モーション検知やメール通知などの高度な機能を介してイベントが検出された場合、すぐに通知を受けることができます。これにより、迅速な対応と効果的な管理が支援されます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'ビデオの検索と分析機能',
            desc: '一部のNVRシステムはビデオ検索および分析機能を提供し、特定のイベントを検出し、必要な情報を迅速に見つけることができます。これは調査や監視データの効率的な活用をサポートします。',
            dot: ''
        },
        {
            head: '',
            subHead: '効率的な保存と管理',
            desc: 'NVRは効率的なビデオの保存および管理機能を提供します。ストレージを効果的に活用し、必要な期間のビデオレコードを安定して保存します。',
            dot: ''
        },


        {
            head: 'NVR と DVR の違い',
            subHead: 'カメラの接続および信号処理',
            desc: 'NVRは主にデジタルカメラと共に使用され、高解像度および高品質のビデオをサポートします。これにより、細部がはっきりと表示され、必要に応じてより正確な監視が可能になります。',
            dot: {
                a: '<strong> • DVR： </strong>DVRは主にアナログカメラと共に使用され、アナログ信号をデジタルに変換して処理します。',
                b: '<strong> • NVR： </strong>NVRはデジタルカメラまたはIPカメラと共に使用され、既にデジタル形式で送信されるデータを処理します。',
            }
        },
        {
            head: '',
            subHead: 'ビデオ品質と機能',
            desc: '',
            dot: {
                a: '<strong> • DVR： </strong>一般的にはより低いビデオ品質を提供し、高度な機能が制限されることがあります。',
                b: '<strong> • NVR： </strong>高解像度と高度な機能を備え、向上したビデオ品質と多様な機能をサポートします。',
            }
        },
        {
            head: '',
            subHead: 'カメラのサポートと解像度',
            desc: '',
            dot: {
                a: '<strong> • DVR: </strong>主に低解像度のアナログカメラをサポートし、高解像度のビデオには適していない場合があります。',
                b: '<strong> • NVR: </strong>デジタルカメラをサポートし、高解像度およびマルチメガピクセルカメラとの互換性に優れています。',
            }
        },
        {
            head: '',
            subHead: 'ネットワーク接続',
            desc: '',
            dot: {
                a: '<strong> • DVR: </strong>通常、ローカルネットワークに接続され、リモートアクセスがより制限される場合があります。',
                b: '<strong> • NVR: </strong>ネットワークを介してデータを送受信し、リモートから簡単にアクセスできます。',
            }
        },
        {
            head: '',
            subHead: '拡張性',
            desc: '',
            dot: {
                a: '<strong> • DVR: </strong>通常、特定のカメラ数をサポートし、システムの拡張がより難しい場合があります。',
                b: '<strong> • NVR: </strong>複数のカメラをサポートし、必要に応じて簡単にシステムを拡張できます。',
            }
        },
        {
            head: '',
            subHead: 'インストールとメンテナンス',
            desc: '',
            dot: {
                a: '<strong> • DVR: </strong>アナログシステムは簡単なインストールとメンテナンスが可能ですが、全体的なシステムのパフォーマンスは低いかもしれません。',
                b: '<strong> • NVR: </strong>デジタルシステムは複雑かもしれませんが、便利なメンテナンスとともに高度な機能とパフォーマンスを提供します。',
            }
        },
    ],

    link: {
        page: 'camera',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.indoor.peopleTracking.title,
                title: productPurchase.purchase.indoor.peopleTracking.title,
                serial: productPurchase.purchase.indoor.peopleTracking.serial,
                desc: 'カメラを使用して室内の人の移動経路を分析できます。',
                img: productImg.product.peopleTracking.thumbnail
            },
        ],
        
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/jp/enterprise/medical/patient'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/jp/enterprise/retail/exhibition'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/jp/enterprise/office/asset'
            }
        ]
    }
}
