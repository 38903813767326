import { SCREEN_BREAKPOINT, } from "@components/Grid/Grid.style";
import { StyledHeading, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export const PricingPage = styled.div`
  width : 100%;
  height : auto;

  display : flex;
  flex-direction : column;

  padding-top : 128px;

  align-items : center;

  & * {
    font-family : 'Pretendard' ! important;
    font-display: swap;
  }


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding-top : 96px;
  }

`;


export const ProgressBarBox = styled.div`
  width : 100%;
  display : flex;
  flex-direction : row;
  gap : 16px;

  height : 24px;
`;

export const ProgressNumber = styled.div`
  width : 73px;
`;

export const ProgressBar = styled.div`
  display : flex;
  width : 100%;
  margin : 6px 0 6px 16px;
  border-radius : 4px;
  background-color : rgba(0,0,0,0.08);
`;

export const ProgressedBar = styled.div`
  position : absolute;
  z-index : 2;
  height : 12px;
  border-radius : 4px;
  background-color : #2A70F0;
  
`;

export const SelectLayout = styled.div`
width : 100%;
display : grid;

  grid-template-columns: repeat(3, 1fr);
  gap : 24px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    grid-template-columns: repeat(1, 1fr);
  }

`

export const SelectBox = styled.div<{ isSelected: boolean }>`

padding : 24px;
display : flex;
flex-direction : column;
align-items : center;
border-radius: 12px;
border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));

text-align : center;
cursor : pointer;

${({ isSelected }) =>
    isSelected &&
    `
border: 1px solid var(--primitive-blue-600, #2A70F0);
`}

`

export const IndustryIcon = styled.div<{ isSelected: boolean }>`
padding : 8px;
margin : auto;

display : flex;
align-items : center;
justify-content : center;
object-fit : contain;
border-radius: 60px;
background: var(--content-tertiary-light, #818181);

${({ isSelected }) =>
    isSelected &&
    `
background: var(--primitive-blue-600, #2A70F0);
`}
`;


export const BtnBox = styled.div<{ isOk: boolean }>`

padding : 16px 24px;

background-color : #AAC6F9;
display: flex;
justify-content: center;
border-radius : 12px;

text-align:center;

${({ isOk }) =>
    isOk &&
    `
background-color : #2A70F0;
cursor : pointer;
`}

`;


export const BtnCancel = styled.div`
cursor : pointer;
padding : 16px 24px;
background-color : #FFF;
display: flex;
text-align:center;
justify-content: center;
border-radius : 12px;
border-radius: 12px;
border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
background: var(--primitive-transparent, rgba(255, 255, 255, 0.00));
`;

export const ShowMoreBtn = styled.div` 
  padding : 12px;
  display : flex;

justify-content: center;
align-items: center;
border-radius: 8px;
border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
background: var(--primitive-transparent, rgba(255, 255, 255, 0.00));


`;


export const CheckBox = styled.div<{ margin: boolean }>`
  width : 16px;
  height : 16px;=
  display : flex;
  object-fit : contain;

  ${({ margin }) => margin ? `margin-top : auto;` : ``}
`;

export const ChoiceLayout = styled.div<{ i: number }>`

  width : 100%;
  display : grid;
  flex-direction : column;
  gap : 8px;


  ${({ i }) =>
    i &&
    `
    grid-template-columns: repeat(${i}, 1fr);

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
      grid-template-columns: repeat(1, 1fr);
    }
  `}


`

export const ChoiceBox = styled.div<{ isSelected: boolean }>`

  width : 100%;
  
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;

  flex-direction : row;

  border-radius: 12px;
  border: 1px solid rgba(0,0,0,0.08);

  cursor : pointer;

  ${({ isSelected }) =>
    isSelected &&
    `
  border: 1px solid var(--primitive-blue-600, #2A70F0);
  `}
  

`;

export const ChoiceCircle = styled.div<{ radius: string }>`
  width : 16px;
  height : 16px;
  aspect-ratio : 1/1;

  ${({ radius }) => radius && `border-radius : ${radius}px;`}
  border : 1px solid rgba(0,0,0,0.08);
  display : flex;
  justify-content : center;
  align-itmes : center;
`;

export const ChoiceSquare = styled.img`
  width : 16px;
  height : 16px;
  aspect-ratio : 1/1;

  display : flex;
  justify-content : center;
  align-itmes : center;

  object-fit : contain;

`;




export const ChoiceBlueDot = styled.div`
margin : auto;
width : 8px;
height : 8px;
display ; flex;
align-items : center;
justify-content : center;
border-radius : 8px;
background-color : #2A70F0;
`;


export const AnswerBox = styled.div`

padding : 18px 16px;
width : 100%;
display : flex;
flex-direction : row;
align-items : center;

gap : 10px;

border-radius: 12px;
border: 1px solid rgba(0,0,0,0.08);


`;


export const Placeholder = styled.input<{ i: number }>`
  border: none;
  outline : none;
  height : 20px;
  width : 100%;
  font-size : 16px;

  ${({ i }) => i > 0 ? `color : #000;` : 'color : #BDBDBD;'}

  & : placeholder {
    color : #BDBDBD
  }

`

export const PlaceholderTextArea = styled.textarea<{ i: number }>`
  border: none;
  outline : none;
  height : 20px;
  width : 100%;
  font-size : 16px;
  resize : none;
  flex : 1;

  ${({ i }) => i > 0 ? `color : #000;` : 'color : #BDBDBD;'}

  & : placeholder {
    color : #BDBDBD
  }

`


export const PickerBox = styled.label`
z-index : 3;
cursor : pointer;
  margin-top : 8px;
  padding : 18px 16px;
  border : 1px solid rgba(0,0,0,0.08);
  border-radius : 12px;

  width : 100%;

  display : flex;
  flex-direction : row;
  align-items: center; 

`


export const Picker = styled(DatePicker)`

cursor : pointer;
  border : none;
  width : 100%;
  align-items: center; 
  outline : none;

  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  & * {
    font-family : 'Pretendard' ! important;
    font-display: swap;
  }
`

export const ScheduleBox = styled.div`
  width : 100%;
  display : flex;
  margin-top : 48px;
  flex-direction : row;
  gap : 16px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    flex-direction : column;
    gap : 32px;

  }

`


export const ScheduleSideBox = styled.div`
width : 50%;
display : flex;
flex-direction : column;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    width : 100%;

  }

`



export const SelectTime = styled.div`
  width : fit-content;
  height : 100%;
  display : flex;
  flex-direction : row;
  gap : 6px;
  padding : 4px;
  border-radius: 12px;
  background: var(--border-translucent-light, rgba(0, 0, 0, 0.08));

  cursor : pointer;

${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
  width : 100%;

}

`;

export const Dashbox = styled.div`
  padding : 0 8px;
  height : 100%;
  display : flex;
  justify-content : center;
  align-items : center;
`;

export const NoonBox = styled.div`
  margin-left : 8px;
`;

export const Noon = styled.div<{ time: boolean }>`
  padding : 16px;
  text-align: center;
  width : 100%;
  justify-content : center;
  align-items : center;
  border-radius: 8px;
  flex : 1;

  white-space : nowrap;

  ${({ time }) => time ? 'background-color : #FFF;' : 'background-color : transparent;'}
  
`


export const PricingParagraph = styled(StyledParagraph)`
  margin-top : 4px;
  color : #9F9F9F;
  text-align : center;
  min-height : 40px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    min-height : 20px;
  }
`;


export const PricingParagraph2 = styled(StyledParagraph)`
  margin-top : 12px;
  color : #9F9F9F;
  text-align : center;
  min-height : 60px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    min-height : 20px;
  }
`;



export const PricingSpacingTop = styled.div`
  margin-top : 64px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    margin-top : 32px;
  }

`;

export const PricingSpacingBottom = styled.div`
  margin-top : 96px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    margin-top : 64px;
  }

`;


export const NextButton = styled.div`
  width : 100%;
  display : flex;
  justify-content : center;
`;

export const BoxLayout = styled.div`
  width: 100%;
  display : flex;
  flex-direction : row;
  gap : 16px;
  justify-content : center;
`;


export const OptionBox = styled.div`
  display : flex;
  flex-direction : column;
  align-itmes : center;
  margin : auto 0 16px 0;
`;


export const Options = styled.div`
  width : 90px;
  height : 60px;
  display : flex;
  object-fit : contain;
  justify-content : center;
`;

export const OptionExam = styled.div`
  width : 100%;
  height : 100%;
  min-height : 60px;
`;

export const Width100HeightAuto = styled.div`
  width : 100%;
  height  : auto;
`;


export const HeadingCenter = styled(StyledHeading)`
  margin : auto;
  display : flex;
  text-align : center;
`;

export const OptionTitleDiv = styled.div`
  margin-left : 16px;
  width : 100%;
  display : flex;
  align-items : center;
  object-fit : contain;
`;

export const OptionHeading = styled(StyledHeading) <{ i: number }>`
  margin-top : auto;
  ${({ i }) => 1 === i ? `color : #000;` : 'color : #646464;'}
`;

export const InputBox = styled.div`
  padding : 18px 16px;
  border : 1px solid rgba(0,0,0,0.08);
  border-radius : 12px;
`;

export const InputLayout50 = styled.div`
  width : 50%;
  display : flex;
  flex-direction : column;
`;

export const MemoInputDiv = styled.div`
  height : 200px;
  margin-top : 8px;
  padding : 18px 16px; 
  border : 1px solid rgba(0,0,0,0.08);
  border-radius : 12px;
  display : flex;
  flex-direction : column;

`;


export const CallButton = styled.div`
  padding : 16px 24px; 
  dispaly : flex;
  text-align : center;
  justify-content : center;
  align-items : center;
  cursor : pointer;
  background-color : #2A70F0;
  border-radius : 12px;
`;

export const CalenderIcon = styled.img`
  width: 16px;
  height : 16px;
  margin-left : auto;
`;