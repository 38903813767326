import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4";

export default {
  helmet: '',
  desc: 'RTLS (Real-Time Location System) technology utilizing UWB (Ultra-Wideband) enables highly accurate real-time location tracking by utilizing an extremely wide frequency bandwidth. This technology precisely measures the distance between sensors and tags to provide location information in indoor and outdoor environments.',
  keywords: 'UWB RTLS, indoor positioning, asset tracking, contactless payment system, smart home, healthcare management, car safety, UWB, RTLS, GPS, BLE, digital twin',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'What is UWB and How Can It Help Businesses? :: ORBRO Blog',
  ogUrl: 'https://orbro.io/en/blog/uwb',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EObeDoLPWDY0m4J6RG5ivUvly0sFp1k2N8pJ6vwHvfEotnTR-c97QjquFgftQfhSf8tMXrczrcxhZelBBXIc0FoSa6ZZgE8na0u8s66yPDrdq0UrGeG-BtDEHMU8P-DPTgUQcwgdX_770Njyx6SNDF4Bchooan8k2Ghgf5DDf0A-rOW2q8-J9DNpNr9pjZRVCg6601CXYmQ-4C_DWZQrQPOpbyvi0Utlc-Az8WhS41m0SPy_AVoqe-fxEuq53t-bNal_-D9ko7jhgr3HHIaTqLbFKWfu5W_yVuf3-clrf0CtlwYyWeQTD4-blr1Vt6UMEpe6g0xh8vMdEG9KwIOY0We-an0hHPhqHco-cxMhZqnXuKuhdRngKsVJnmNzhoAGDcEovW0xEdbwNGGnaXf9HgDuwEyz0O0hBJ1VdMLVrZeF3yG2w0uOYvT1w1CGsNnOvF1HQ2O1tBB0OUXGi7kOuhASd34xinm8ZUoaopcdehwOHmFkiWhblS3O86GDhcSHi64o5o9yPXeKJKcg3aX4M1WKjSD4u-v_h7Cz7j2Ew_QRGSJUkV0ZqKIYM-LnidAHxkTwv5b0vujpTQTybOQctctEpk07QqA7jx3zz1AbtSgtB4SMS3BVYQ-LDQPwmRnkVEi15VK5I33OFLSw8UAoibRcC2y68nu5w0ZmwZ3yZPgRbGUhwVnNrbzmmWjnhyW6agO6gnb3iAAYmxGgUZ0Bxe6sU3F_hRAOk3K5adG-GTihhhRZ6Ny-1YfLjwBN2XWS9CQmHOiNL2XKyUmDIRFnYf6My62MYUpTGkqD3QGMb5QWsmjsVvMDeYmPK2pRmdXC2VuzOpXv1I32Uij5MECw_kBbt9J59P_jtayKMJb7MPa9mZTsvEtK68fS4kpKPiTqgmY6Fpu81ySeLX6iMqgw2mW8BqPuEyOz5VlDQR8kXkleyutpFfgwMLd0gDe-6V-1w-4lb3u6ccuCTShoIl4rzEil0_RJbe2Oe3td0WW3KYtfT_1Oeihe7jPGmD0JZrOSemw8nMa-oWOjkMSMFpCBJOfSPxpx5I42Jupgd2vOsaIezrcSGCGzHpoXM2-ryc14nPGOUDOc-hA1eFFGqVgCGUQN2dUMHISspskEVJBPI7647sxsyCnd0IUJLN5NSHR_sHivHXsX3SAq9g2Tk3WwbdpT8PW2l9hw6FfuxvjBv8DfsEILwnTGOQQMQ8iiQIZLz9QyCq1OEEDethUDWNsTZipSUMrcWUwpyNxE5586r4DxKO0R_AIGUIxL7kAAwP5cRdFP7CxE1UBRhidxzIVJeIaoHrk-OtutFpM4iFhXD6VY_zZRNDnT74KvBOONaMhB3Lhc801EddUkWf0-35rZksJbpbnWR-H_yNKiCBv8UP9iHJoUWB84wFs2dUNq8sK7fy316q-Coz-Yc58zY_S07R8mK_SjMcdJPDpUc-XzfMyXvXGhYWScpZjP766CDtHOd_ArlWxMsLBupquNSUuHJJ6FWmIoUzq31LSeNqPvc7MH_ywbqQd_efx2p8c73E_NvkVCJP8OYYT8IP8iUh9v7tRMD0-JBjW6yzXknRIpicPYTQ=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'What is UWB and How Can It Help Businesses?',
  date: '2023-04-25',
  tag1: '#UWB',
  tag2: '#industrialautomation',
  tag3: '#assettracking',
  tag4: '#indoorpositioning',

  // <br/>
  text: [
    {
      head: 'What is UWB?',
      subHead: '',
      desc: `Ultra-wideband (UWB) is a wireless communication protocol that operates at very high frequencies with low power consumption. Unlike other wireless communication protocols like Bluetooth or Wi-Fi, UWB uses short pulses of electromagnetic energy to transmit data. These pulses typically last for only a few nanoseconds but have an extremely wide bandwidth ranging from hundreds of megahertz to several gigahertz. UWB's wide bandwidth makes it ideal for applications that require fast and accurate transmission of large amounts of data, such as industrial automation, robotics, and autonomous vehicles. Additionally, UWB is useful in location-based applications as it can measure the distance between two objects with high precision.`,
      dot: ''
    },
    {
      video: video,
      link: 'https://orbro.io/en/enterprise/medical/patient',
      head: 'How Can UWB Help Businesses?',
      subHead: '',
      desc: 'UWB has various potential applications that can assist businesses in multiple ways. Here are some of the most promising applications of UWB.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Indoor Positioning and Asset Tracking',
      desc: 'UWB technology can be used to accurately track the location of assets within a building or other indoor spaces. This is useful for inventory management, tool tracking, tool control, process monitoring, vehicle tracking, and other applications that require precise location data. For example, if you operate a warehouse, using UWB to track the real-time location of individual items can make inventory retrieval and management easier.',
      dot: {}
    },
    {
      head: '',
      subHead: 'Contactless Payment Systems',
      desc: 'UWB technology can be utilized to implement more secure and reliable contactless payment systems than traditional solutions. By transmitting payment data using UWB between mobile devices and payment terminals, payments can be conducted securely, ensuring there are no fraud or unauthorized access.',
    },
    {
      head: '',
      subHead: 'Smart Home Automation',
      desc: 'UWB technology can be used to create more accurate and reliable smart home automation systems compared to existing solutions. By using UWB to determine the location of devices within a home, more sophisticated automation rules that respond to specific locations or movements can be created. For example, rules can be set up to turn on the lights when someone enters a room and turn them off when they leave.',
    },
    {
      head: '',
      subHead: 'Healthcare Management Monitoring',
      desc: 'UWB technology can be utilized to monitor the location and movement of patients within medical facilities to ensure they are receiving the necessary care. For instance, UWB can track the movement of patients within a hospital, aiding in quickly locating patients who may require immediate assistance.',
    },
    {
      head: '',
      subHead: 'Car Safety',
      desc: 'UWB technology can provide more precise data on the location and movement of vehicles, making it useful in car safety systems. It can be used in applications like collision avoidance, accurately determining the distance between two vehicles, and providing warnings to the driver about potential risks.',
    },
    {
      head: '',
      subHead: 'Industrial Automation',
      desc: 'UWB technology enables more accurate and reliable communication between devices in industrial automation applications. For example, UWB can transmit data between robots and other industrial equipment, allowing them to collaborate more efficiently.',
    },
    {
      head: 'Conclusion',
      subHead: '',
      desc: 'UWB technology is a powerful tool that can be used in various fields, from indoor positioning and asset management to smart home automation and healthcare monitoring. Its high accuracy and reliability make it an ideal choice for applications that require precise location data or high-speed data transmission.',
    },
  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/en/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      }
    ]


  }


}