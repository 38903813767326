import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset, medicalPatient, medicalWaste } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";
import digitaltwin from "@src/assets/images/digitaltwin/video/traffic.mp4";

export default {

    helmet: '| 智能城市 (Smart City) 建设案例',
    desc: '智能城市是一个在当代城市迅速发展的概念，通过利用最新技术和基础设施追求高效的城市管理。利用传感器、物联网技术、大数据等，在交通、能源、环境等各个领域收集和分析数据，提升市民生活，追求可持续发展。',
    keywords: '智能城市、数字孪生、数字化转型、smart city、digital twin、digital transformation、实时定位跟踪、rtls、orbro、奥博罗、科技',

    ogTitle: '',
    ogDesc: '',
    ogSite: '智能城市 (Smart City) 建设案例 :: 奥博罗 博客',
    ogUrl: 'https://orbro.io/cn/blog/example-of-smart-city',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkFVkNhGYhAjBJLQh-TO_MrDW5dZ9e675klRxgK1f4x67wU6ADQnAzVhSAqDCJ5a6mjIlcP3clmtu92HV05SxhzPTDf1NScGbhZaAOxxAakyFJm0bwewGqWjuE1NJoM86xI5RkwtjQZ4WFYc6d0Hrid-pszPZbQGf1gYPMDqDwORsg5wyGW5bNgU7KKeOTMzrkt78Yr5lYcchyCNmpn2NmzJazzotGsmI9T33V6K3T6xqraKPVNfNkg6odqKHbGpbE7knODw-Ps7LNMLRhHIAZIM9lduTSX896wr7cZscafS5TDVi8cSC0ERUhdb3YwCRgKm0YqSsnVcCJcAbu4evdtoUzdMcjZAIfKR_kw7HgXI42LaA4C-PhxW2jQQKyD9UIxoOFPmJFIh6q7vIHbg3k3cAUc2dxW0Z6hfKLB2k10q0aubzcnWFJnEPSibJNaZJA0fslRcEQTOOSq7qH3K-9HiRproHejfmIxqYsRayvAnKXQPSJyG6cmWTKw2ctEcRwcsQV7cMU2H5VMEc-s5BYkjOqQe6yylbCUXzQ8qYVwls2h-mstfcyt2phYijwZXPHjEY0Xd1KSUErYHTSCWx_A1JKCL30IryNxWX6ejXVr4rzx9VOXpS53IYlHEz2WJTk6LjBccSU2u9lAv_gwAiZTmTSoRX658UQv-7kdx_Et7OVcWSg4GdQpFTDS9mF8huuN39CfL2Tm9qVy2j5jAl-gTQ4o4bnPhaHz9NMoaM-qoTdDt4Q5C1ysaxNReONUY8phy-5RgTgHszfiRXjSpTP_fjzv_dGumhP0V_Rf6ndfluYR6Mg1BHCVhSUG62aDV8bGqdS2oYAmGPYgbN99KcLyQgySVflbWa6wC_fOGkLNeso6n_M6C7otFKqjX9XmB3s6GUBTaS3JiqqQJ-N9qGtjKI86za8HY7i9lxAJEh4iY2FSXw3kIW-m6OTvQbenmldQlgE6hB_Fl8rm6ATKKLAhsxwx5fMKD16IV2hBFuQ9wpnIjLhw1XLzIy0R5Pka1Mygfo6no04iZU4LAApDVkphY4k958mwN4tg_wyl3L63jjST8taRHacU0M-4ADiu0tC2vJzWH1XQwiSedBQQTTUMuydsHQCMYlanG9WhyhybHTxRIA7pUX7-wpNqkFiWCrT9TPNbHg-ON5nQrBGyDUZUb5fC-U4EkUN0a-Pe-CHJJ6bZd1m_oCpzdulGMPnK-4_VjWBJAiQRteQ8QTUKNhmQj2yEnZwCJQZZSTpk1IlGzgHiJ_BsIYyD_a3z-ysShhTuE9hG_57y4aIz8Rf6ZsrXTp1OeEHkNkCvjH7ZnbdABz0u_QyshaMMfU7EKVUbH6tIXiym1yOX6upY9M_6JtOTgtlulM87kVzPjPoy47O2YEq7mcAOwMBRmDb6gfW2J0t7PuaFMgTQAi4XiKpM5Pf_NhIK-wNtRCAhcSD9WCAd1CIxJ-CrcG3xn77PYV4NQRe0Oi2KQCyqG3frUstyWuJuSAeYrdYhBRBkDG6OoVG48EshJwneIwytpeURilKCKLLPt64ls45oiR-ZMwMxmdpfsCMct--L=w2010-h1214',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '智能城市 (Smart City) 建设案例',
    date: '2024-01-09',
    tag1: '#智能城市',
    tag2: '#数字孪生',
    tag3: '#数字化转型',
    tag4: '#未来之城',

    // <br/>
    text: [
        {
            head: '智能城市 (Smart City) 建设案例',
            subHead: '',
            desc: '智能城市是一个在当代城市迅速发展的概念，通过利用最新技术和基础设施追求高效的城市管理。利用传感器、物联网技术、大数据等，在交通、能源、环境等各个领域收集和分析数据，提升市民生活，追求可持续发展。智能城市作为全球城市创新与合作的模型正在不断发展。<br/><br/>随之而来，我们将在以下看到世界各地的智能城市建设案例。',
            dot: ''
        },
        {
            head: '新加坡 (Singapore) 智能城市',
            subHead: '',
            desc: '新加坡是系统性实现智能城市概念的先驱。城市内部安装的各种传感器和物联网设备监测交通拥堵，实现了有效的交通管理。此外，通过利用智能电网技术提高能源效率，自动化公共设施以及通过智能CCTV网络提高安全性。',
            dot: ''
        },
        {
            head: '东京 (Tokyo) 智能城市',
            subHead: '',
            desc: '东京正在推进多个项目促进城市的智能化。通过引入技术，如智能照明和建筑自动化，测量微粒子的传感器和环境监测，智能交通系统减少交通拥堵，提高城市效率。',
            dot: ''
        },
        {
            head: '巴塞罗那 (Barcelona) 智能城市',
            subHead: '',
            desc: '巴塞罗那通过智能电网、无线传感器网络、数字化公共交通等手段，追求城市的可持续发展。通过利用大数据分析优化垃圾收集和能源使用，构建智能城市平台以有效管理城市的各种功能。',
            dot: ''
        },
        {
            head: '首尔 (Seoul) 智能城市',
            subHead: '',
            desc: '首尔通过智能城市的建设革新城市基础设施。智能电网的建设和公共交通的数字化提高了能源效率和交通便利性。此外，通过智能城市平台整合和分析各种数据，提高市民便利性和安全性。',
            dot: ''
        },
        {
            video: digitaltwin,
            link: 'https://orbro.io/cn/digital-twin',
            videoDesc: '(点击视频，将跳转到奥博罗数字孪生页面。)',
            head: '智能城市建设方法',
            subHead: '',
            desc: '要建设智能城市，需要各种技术和战略。以下是对建设智能城市的主要方法的简要说明。',
            dot: {
                a: '<strong> • 基础设施改善 : </strong>建设智能城市需要强化的数字基础设施。通过建设高速宽带和5G网络，实现实时数据传输，使连接到互联网的设备能够高效运行。',
                b: '<strong> • 大数据和人工智能的应用 : </strong>收集、分析大量数据，优化城市运营至关重要。利用大数据和人工智能技术实时分析和预测在交通、环境、能源等各领域发生的数据。',
                c: '<strong> • 智能传感器和物联网技术的引入 : </strong>引入各种传感器和物联网技术到城市中，实时收集与交通、环境、能源等相关的数据。通过这种方式提高市民的便利性，优化城市运营。',
                d: '<strong> • 智能能源和电网系统 : </strong>通过引入可再生能源和建设智能电网系统，提高能源效率，优化能源消耗。利用智能能源管理系统优化能源使用，促进可持续能源消耗。',
                e: '<strong> • 公共交通的数字化 : </strong>将公共交通系统数字化，引入实时信息提供、预约系统、自动驾驶车辆等，缓解交通拥堵，最小化对环境的影响。',
                f: '<strong> • 构建智能城市平台 : </strong>构建智能城市平台，高效收集、管理数据，并向市民和企业提供开放数据。这对优化城市运营，促进市民参与起到重要作用。',
                g: '<strong> • 增强网络安全 : </strong>由于智能城市中各种数据实时交换，网络安全是一个核心问题。通过引入强大的安全系统保护系统，安全管理市民个人信息。',
            }
        },
        {
            head: '智能城市的优势',
            subHead: '高效城市运营',
            desc: '通过实时数据收集和分析，智能城市可以高效管理交通、能源、水资源、垃圾处理等城市运营。这优化了城市的功能，使市民的日常生活更加顺畅。',
            dot: ''
        },
        {
            head: '',
            subHead: '优化交通流',
            desc: '通过数字化公共交通、引入自动驾驶技术等手段，减少交通拥堵，优化交通流。这使市民的出行更加顺畅，减轻对环境的不良影响。',
            dot: ''
        }, {
            head: '',
            subHead: '能源效率和可持续性',
            desc: '智能城市通过利用智能电网、可再生能源、能源效率化技术等手段，优化能源消耗，推动可持续能源利用。这减轻了对环境的负担，降低了能源成本。',
            dot: ''
        },
        {
            head: '',
            subHead: '促进市民参与',
            desc: '智能城市提供多种手段让市民参与城市运营。通过开放数据、移动应用等方式，市民可以参与城市决策，共同打造更好的环境。',
            dot: ''
        },
        {
            head: '',
            subHead: '提高安全性',
            desc: '智能城市通过引入智能CCTV、应急响应系统、预防性安全措施等手段提高城市的安全性。实时监控和大数据分析提升了犯罪预防和应对紧急情况的能力。',
            dot: ''
        },
        {
            head: '',
            subHead: '经济发展',
            desc: '智能城市的建设可以吸引新技术和产业，激活当地经济。开发和应用智能城市技术的企业将创造就业机会，引领经济发展。',
            dot: ''
        },
        {
            head: '智能城市的展望',
            subHead: '',
            desc: '智能城市的未来预计将发展成为更具创新性和协同性的城市环境。<br/>大数据、人工智能、物联网等先进技术的整合，通过高效管理城市运营，将强调在减缓交通拥堵、提高能源效率、环境保护等各方面的城市可持续性。市民将体验到个性化服务和更高的参与度，城市生活的便利性将进一步提升。此外，在全球范围内，智能城市建设的重要性将增加，世界各地将借鉴这样的城市模型。对于智能建筑和城市设计的进化也是值得期待的，市民有望在更安全、智能的城市生活中享受生活。',
            dot: ''
        },
        {
            linkText: '体验奥博罗世界',
            link: 'https://home.orbro.io',
            head: '',
            subHead: '',
            desc: '',
            dot: '',
        }
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: '通过安装UWB设备对工人和设备进行实时位置跟踪的产品。',
                img: productImg.product.workerTracking.thumbnail
            },
            {
                // Additional products if needed
            },
        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/cn/enterprise/medical/patient'
            },
            {
                img: medicalWaste.thumb,
                data: 'medicalWaste',
                link: '/cn/enterprise/medical/waste'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/cn/enterprise/logistics/asset'
            }
        ]
    }
}
