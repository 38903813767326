import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const StyledTitleBanner = styled.div`
  display: flex;
  
  width: 100%; 
  max-height: 1080px;

  background-color: #F8F8F8;
  left: 0px;
  margin-top : -200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  
  padding-top : 24px;

  & * {
    font-family: Pretendard !important;
    font-display: swap;
  }
  
  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding-top : 64px;
    max-height: 964px;
    margin-top : -200px;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-top : 96px;
    max-height: 1280px;
    margin-top : -200px;
  
  }
`;




export const CardLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap : 32px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    gap : 16px;
  }
`;


export const Card = styled.div<{w : number, img : string, index : number}>`
    ${({img})=> img && `background-image : url(${img});`}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;
    border-radius: 16px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
    padding : 32px;
    display : flex;

    ${({w, index})=> index === 1 && `width : 100%; aspect-ratio : 1232 / 600 ; max-width : 1232px;`}
    
    ${({w, index})=> index === 2 && `width : calc(50% - 16px); aspect-ratio : 600 / 600 ;`}



    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{

      ${({w, index})=> index === 1 && `width : 100%; aspect-ratio : 912 / 500 ; max-width : 912px;`}
      
      ${({w, index})=> index === 2 && `width : calc(50% - 16px); aspect-ratio : 440 / 500 ;`}
  
  
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 24px;
        width : 100%;
        aspect-ratio : 361 / 360 ;

        ${({w, index})=> index === 1 && `max-width : 100%;`}
   
    }

`;

