import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {


  helmet: '| RFID, NFC, UWB: 어떤 기술을 선택해야 할까요?',
  desc: '물체 위치 추적, 사람 추적, 무선 통신 등, RFID, NFC, UWB 기술들은 각각의 특징과 장점들을 가지고 있습니다. 상황에 적절한 기술들을 적용해야 합니다.',
  keywords: 'rfid, nfc, uwb, 무선 통신, 위치 추적, 물체 위치 추적, 사람 위치 추적, 태그, 비콘, rtls, orbro, 오브로, 콩테크',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RFID, NFC, UWB: 어떤 기술을 선택해야 할까요? :: 오브로 블로그',
  ogUrl: 'https://orbro.io/blog/RfidNfcUwb',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDyDzwljrefhMhdX7kf1Mm1ywH2nnOki0RIgSDRIh7C5tg1pR6m3kbEx54PkUJcq-hciL8YYYvumKJ-63gC6WJ4qVWUBwpX2L27Az-lRi9JVPUAPPrN4w5UrS1sMh0YLSWqhm1JpL4pi_Z08Ejh_gka2T1aIruLLocuLbyP_ZAhm5q8Ak1AOxoZ30Sa750ffkaTQoyxFwgi_lbDVwXorafRdzRGuy6GFGf2k6pLcN8gyQ_bmLhYnizUxtLSo4rpyQalNm-qZKsosAwvg0xPCKx3yIpdkycgeB8XisSD6JoFWDLjK5DE2XFvKt5taMzrDEUI4yq-kRgj54cRHA0AkUYviBZEHbun6CP-ddQfgL0_vxj-afsWWE11vR-J7aic2bRHVF46WEIHc_Q1lom0Ro3XRQy9WuJ3_x23uVUQemus3_BtxD76BMNNfwUJTA8ohBZ74GkjJAozZjHWVNZcN-bk9cTnLcBCuYdeXKB67hosHLnXyRWTxbKHnAspOTZuDEjoq0c6efewIt3TF-krS18xuDI06UQOOINUt3T177VvqEHZescieirG3kU3V1fklYqDXIo5VJhA8IxB1G7tllhssELDGTJmG7K40AbmDmY9wSwM_FRqgaHsIA3Ol9rBz2civyC8T_JpAV0c0gB0UdkO6mYn9PTaWSVEyOHMFhrLh9kJT9Ec3N26DFoUe0wJGHafWgc3EOY5SyD9TbyysdCbMFtmzbri63MLFS-CVlfuurccxzTp4fA7Y6x5m2w7-zVqBw4l17kYVEXAEnX1lIg2pSOuZAaWmSiOE3O6_4zbKpMHgySf3gCmA8moDXM_SB0npT9zE1NftELISmGwhEA0EWAC_OPBpOHijsZC38Ww9_MjSbVAn65T6j96hn0MVkq0drXqf9kVdR5VzHla36eqjBCnvxuv7p3OJpxMhG7JWgENwqauKMiKBc2sGmAUAtvjVrAUaxDD1f18pVT-97AjFdXJtrfEOrbFQXJhqp8F9GiFJTyQGbepdWdEjhzpL06WDXrPTZ9TMIEJrftdzxDH3RvDYScwSGlEPMALRbh448aSi05ozs5TG4KXVyOEN90b_BLn_LR5XkaXWFAyzj9nbaR5pQL75WGz857-0w5fOcpXAJlzdGLPK-qzwvQypZufKmeGjhUZN324auzcqLBxDTdpiX6mQfeqR9YekYBydEHDzesVBgPzdFxNIc8e-D6uW0CNYJbWG-cs9YLY0brSsnh8Ki32Gb_PwmdKDxyt-zejkein4rKNvH_L4fe-bCYOwKwuS3Do2tIDr_HRgFlmIuIzQ-LTzwnQHFwrivngUeb-urFqXm-OHCMLLVsTdhMl6F8tAmWehfylTymzJT9wSric2u2oyOWofynt5w2DHrO5btzJrk4fqVRfO7VXnIJ71aTGeb2wLS6fYLUIFWraHoG6b0QKEqzmoDVtIVpinqNK3eRd4bTQ5lxE6FJxMyX3Dnt2FOOzosarYUFrndKzLb8P1AnsUez-1xlCwpA2KUqFeolbevRLxAPtPZcEuFHLZKcBVKi48FPzR4jypbwpMCM7j9e9Eh9KNzoRlArES6rb_kHgh=w1995-h1157',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RFID, NFC, UWB: 어떤 기술을 선택해야 할까요?',
  date: '2023-11-10',
  tag1: '#rfid',
  tag2: '#nfc',
  tag3: '#uwb',
  tag4: '#무선통신',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: '현대 사회에서는 물체의 추적, 데이터 교환, 위치 측정 등 다양한 요구사항이 있어 여러 무선 통신 기술이 등장했습니다. 그 중에서도 RFID, NFC, 그리고 UWB는 각자의 특성을 가지고 있어 선택 시 고려해야 할 점이 많습니다. 이 글에서는 각 기술의 특징과 사용 사례를 살펴보고, 어떤 상황에서 어떤 기술을 선택해야 하는지에 대해 논의해보겠습니다.',
      dot: ''
    },
    {
      head: '1. RFID: 효율적인 물체 추적과 관리에 적합한 기술',
      subHead: '',
      desc: 'RFID는 주로 물류, 제조업, 그리고 재고 관리와 같은 분야에서 물체의 식별과 추적에 사용됩니다. 작은 RFID 태그를 물체에 부착하면 리더가 이를 감지하여 정보를 읽거나 쓸 수 있습니다. 패시브 RFID는 외부 에너지를 활용해 동작하며, 액티브 RFID는 자체 전원을 가지고 있습니다. 이러한 특성 덕분에 대량의 물체를 효율적으로 추적하고 관리하는 데 뛰어난 성과를 보입니다. 또한 비교적 낮은 비용으로 도입이 가능하다는 점에서 많은 기업에서 선택하는 기술 중 하나입니다.',
      dot: ''
    },
    {
      head: '2. NFC: 간편한 데이터 교환과 무선 결제를 위한 선택',
      subHead: '',
      desc: 'NFC는 주로 스마트폰 결제, 스마트홈 응용, 그리고 간편한 데이터 교환에 사용되는 기술입니다. 매우 짧은 거리에서 작동하며, 페어링 및 빠른 연결이 가능합니다. 스마트폰이나 NFC 카드를 통해 데이터를 교환하거나 무선 결제를 할 수 있어 사용자에게 편리한 경험을 제공합니다. 주로 보안이 중요한 결제 시스템이나 스마트홈 기기와의 통합에 활용됩니다.',
      dot: ''
    },
    {
      head: '3. UWB: 정밀한 위치 측정이 필요한 환경에서의 최적 선택',
      subHead: '',
      desc: 'UWB는 매우 넓은 대역폭을 활용하여 정밀한 위치 측정과 거리 측정에 특화된 기술입니다. 양방향 통신을 지원하며, 주로 실내 위치 기반 서비스, 자동차 내비게이션, 그리고 정밀한 거리 측정이 필요한 산업 환경에서 사용됩니다. UWB는 센서와 연계하여 물체의 실시간 위치 정보를 제공하므로, RFID 보다 정확한 위치 측정이 필요한 곳에서 최적의 선택으로 부각되고 있습니다.',
      dot: ''
    },
    {
      video : video,
      link : '/enterprise/manufacturing/asset',
      head: '어떤 기술을 선택해야 할까요?',
      subHead: '',
      desc: '기업이나 개인이 어떤 무선 통신 기술을 선택해야 할지 결정할 때, 사용 목적과 환경에 대한 고려가 필요합니다. 만약 대량의 물체를 효율적으로 추적하고 싶다면 RFID가 적합할 것입니다. 스마트폰 결제나 간편한 데이터 교환이 주 목적이라면 NFC가 좋은 선택이 될 것입니다. 정밀한 위치 측정이 필요한 산업 분야에서는 UWB가 뛰어난 성능을 발휘할 것입니다.<br/><br/>또한 비용, 전력 소비, 데이터 용량 등의 측면에서도 각 기술의 특성을 고려해야 합니다. 종합적으로, 사용자의 요구 사항과 기술의 특성을 잘 조합하여 최적의 무선 통신 솔루션을 찾는 것이 중요합니다. 물리적 환경과 비즈니스 목표를 고려하여 적절한 기술을 선택함으로써 혁신적이고 효과적인 무선 통신을 구축할 수 있을 것입니다.',
      dot: ''
    },

  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/enterprise/office/asset'
      }
    ]

  }


}