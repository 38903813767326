import {
  HomeAccess1D, HomeAccess1M, HomeAccess1T,
  HomeAccess2D, HomeAccess2M, HomeAccess2T,
  HomeAccess3D, HomeAccess3M, HomeAccess3T,
  HomeAccess4D, HomeAccess4M, HomeAccess4T,
  HomeAccess5D, HomeAccess5M, HomeAccess5T,
  HomeBeacon1D, HomeBeacon1M, HomeBeacon1T,
  HomeBeacon2D, HomeBeacon2M, HomeBeacon2T,
  HomeBeacon3D, HomeBeacon3M, HomeBeacon3T,
  HomeBeacon4D, HomeBeacon4M, HomeBeacon4T,
  HomeBeacon5D, HomeBeacon5M, HomeBeacon5T,
  HomeBeacon6D, HomeBeacon6M, HomeBeacon6T,
  HomeRTLS1D, HomeRTLS1M, HomeRTLS1T,
  HomeRTLS2D, HomeRTLS2M, HomeRTLS2T,
  HomeRTLS3D, HomeRTLS3M, HomeRTLS3T,
  HomeSolution1D, HomeSolution1M, HomeSolution1T,
  HomeSolution2D, HomeSolution2M, HomeSolution2T,
  HomeSolution3D, HomeSolution3M, HomeSolution3T,
  HomeSolution4D, HomeSolution4M, HomeSolution4T
} from "@assets/images/main/home";
import { HomeDTBanner } from "@assets/images/main/home/dt";

export default {
  titleBanner: {
    title: `Specialized Platform for Location Tracking and Digital Twin`,
    description: 'Real-time location tracking, access systems, digital twins, and the innovation in spaces brought by industrial IoT'
  },

  productText: {
    title: `Digital Twin, Location Tracking Products`,
    description: `Newness that Orbro proposes to those seeking change`
  },

  os: {
    title: 'The Software That Understands Space Best',
    desc: 'Orbro OS uses the latest communication technologies to accurately track and manage every element of the space in real-time.',
  },

  rtls: {
    title: 'Location Tracking Lineup Applicable to All Sites',
    desc: 'We offer a lineup of radio wave location tracking using Bluetooth and UWB communication, as well as location tracking using video and AI technology.',

    card: [
      {
        tag: 'Real-time Location Tracking',
        title: 'Reality in Virtual Space',
        link: 'https://orbro.io/en/rtls',
        img: {
          desktop: HomeRTLS1D,
          tablet: HomeRTLS1T,
          mobile: HomeRTLS1M
        },
      },
      {
        title: 'Location Tracking via Beacons',
        desc: 'Location tracking system using BLE',
        link: 'https://orbro.io/en/beacon/ble',
        img: {
          desktop: HomeRTLS2D,
          tablet: HomeRTLS2T,
          mobile: HomeRTLS2M
        },
      },
      {
        title: 'Location Tracking via AI Camera',
        desc: 'Location tracking and anomaly detection using AI technology',
        link: 'https://orbro.io/en/camera',
        img: {
          desktop: HomeRTLS3D,
          tablet: HomeRTLS3T,
          mobile: HomeRTLS3M
        },
      },
    ]
  },

  access: {
    title: 'Seamless Contactless Access via Mobile',
    desc: 'We offer next-generation access methods and information using mobile and facial recognition technology.',

    card: [
      {
        tag: 'Various Access Methods Provided',
        title: 'NFC/Bluetooth/Smartphone<br/>All Types of Access',
        sub: 'Not only NFC card access,<br/>but also mobile access and smart watch access are available.',
        img: {
          desktop: HomeAccess1D,
          tablet: HomeAccess1T,
          mobile: HomeAccess1M
        },
      },
      {
        title: 'Access Pro',
        link: 'https://orbro.io/en/access/face',
        desc: 'Supports all access methods including facial recognition, NFC cards, and mobile',
        img: {
          desktop: HomeAccess2D,
          tablet: HomeAccess2T,
          mobile: HomeAccess2M
        },
      },
      {
        title: 'Access Auto',
        link: 'https://orbro.io/en/access/auto',
        desc: 'Optimized for mobile access',
        img: {
          desktop: HomeAccess3D,
          tablet: HomeAccess3T,
          mobile: HomeAccess3M
        },
      },
      {
        title: 'Accessories',
        desc: 'Provides various access accessories',
        link: 'https://orbro.io/en/access/accessory',
        img: {
          desktop: HomeAccess4D,
          tablet: HomeAccess4T,
          mobile: HomeAccess4M
        },
      },
      {
        title: 'Access Lite',
        desc: 'A basic mobile access system supporting NFC and Apple Watch',
        tag: "Coming Soon",
        img: {
          desktop: HomeAccess5D,
          tablet: HomeAccess5T,
          mobile: HomeAccess5M
        },
      },
    ]
  },

  dt: {
    title: 'World’s First 3rd Generation Digital Transformation<br/>Digital Twin Specialized Platform',
    desc: 'Experience the advanced innovation by virtually implementing cities with Orbro’s digital twin solution.',
    img: HomeDTBanner
  },

  beacon: {
    title: 'Industrial IoT and Beacon System',
    desc: 'We create an efficient working environment with various sensors and beacon systems required in industrial sites.',

    card: [
      {
        title: 'ALESS-GW',
        desc: 'LoRa Gateway',
        link: 'https://orbro.io/en/sensor/LoRaGateway',
        img: {
          desktop: HomeBeacon1D,
          tablet: HomeBeacon1T,
          mobile: HomeBeacon1M
        },
      },
      {
        title: 'ALESS-BA',
        desc: 'Industrial sensor using LoRa communication',
        link: 'https://orbro.io/en/sensor/LoRaSensor',
        img: {
          desktop: HomeBeacon2D,
          tablet: HomeBeacon2T,
          mobile: HomeBeacon2M
        },
      },
      {
        title: 'VENTAX CO+',
        desc: 'Carbon Monoxide Detector',
        link: 'https://orbro.io/en/sensor/co',
        img: {
          desktop: HomeBeacon3D,
          tablet: HomeBeacon3T,
          mobile: HomeBeacon3M
        },
      },
      {
        title: 'VENTAX',
        desc: 'Air Quality Monitor',
        link: 'https://orbro.io/en/sensor/air',
        img: {
          desktop: HomeBeacon4D,
          tablet: HomeBeacon4T,
          mobile: HomeBeacon4M
        },
      },
      {
        title: 'Plutocon Pro',
        desc: 'Industrial Beacon Device',
        link: 'https://orbro.io/en/beacon/industry',
        img: {
          desktop: HomeBeacon5D,
          tablet: HomeBeacon5T,
          mobile: HomeBeacon5M
        },
      },
      {
        title: 'ORBRO Tag',
        desc: 'Small Beacon Device',
        link: 'https://orbro.io/en/beacon/tag',
        img: {
          desktop: HomeBeacon6D,
          tablet: HomeBeacon6T,
          mobile: HomeBeacon6M
        },
      },
    ]
  },

  solution: {
    title: 'Integration of Location Tracking and Digital Twin',
    desc: 'Experience Orbro’s advanced solutions in various industries such as construction, logistics, manufacturing, and healthcare.',
    button: 'Contact Us',
    buttonLink: 'https://orbro.io/en/request',
    card: [
      {
        title: 'Construction',
        desc: 'Smart transformation of construction sites with safety management and inventory management.',
        link: 'https://orbro.io/en/enterprise/construction/safety',
        img: {
          desktop: HomeSolution1D,
          tablet: HomeSolution1T,
          mobile: HomeSolution1M
        },
      },
      {
        title: 'Logistics',
        desc: 'Real-time inventory location tracking and worker safety management in large logistics centers and warehouses.',
        link: 'https://orbro.io/en/enterprise/logistics/asset',
        img: {
          desktop: HomeSolution2D,
          tablet: HomeSolution2T,
          mobile: HomeSolution2M
        },
      },
      {
        title: 'Manufacturing',
        desc: 'Enables real-time manufacturing process management and inventory control to improve productivity.',
        link: 'https://orbro.io/en/enterprise/manufacturing/process',
        img: {
          desktop: HomeSolution3D,
          tablet: HomeSolution3T,
          mobile: HomeSolution3M
        },
      },
      {
        title: 'Healthcare',
        desc: 'Ensures the safety of patients through tracking and management in hospitals and medical facilities.',
        link: 'https://orbro.io/en/enterprise/medical/patient',
        img: {
          desktop: HomeSolution4D,
          tablet: HomeSolution4T,
          mobile: HomeSolution4M
        },
      },
    ]
  },

  //product.ts 
  product: {
    items: [
      {
        title: 'Wireless Location Tracking',
        description: 'Perfect asset management and accurate location tracking<br/>through wireless location tracking',
        link: '/en/rtls'
      },

      {
        title: 'Camera Location Tracking',
        description: `Camera location tracking system using AI technology`,
        link: '/en/camera'
      },

      {
        title: 'Access System',
        description: 'Faster and smarter.<br/>A new access system',
        link: '/en/access'
      },
      {
        title: 'Industrial Sensors',
        description: 'Sensors for immediate site application<br/>from fire detection to temperature and humidity',
        link: '/en/sensor/LoRaGateway'
      },
      {
        title: 'Internet of Things',
        description: `Special changes from simple things`,
        link: '/en/iot/bell'
      },
      {
        title: 'Beacon Device',
        description: 'Complete beacon system',
        link: '/en/beacon/industry'
      },
    ]
  },

  control: {
    title: `Integrated Management through ORBRO`,
    description: `A single software<br/>Everything you need, from mobile to desktop, simplified`
  },
  showMore: 'Learn More'
}
