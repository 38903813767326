import React, { PropsWithChildren } from "react";
import {DialogBox} from "./ModalDone.style";

import ModalDoneText from "@pages/Enterprise/Download/ModalDone/ModalDoneText";
import { ModalContainer } from "@pages/Enterprise/Solution/components/Banner/Modal/Modal.style";
import { Backdrop } from "../Modal/Modal.style";

interface ModalDefaultType {
  onClickToggleModal: () => void;
}




function ModalDone({
  onClickToggleModal,
  
}: PropsWithChildren<ModalDefaultType>) {

  return (
    
    <ModalContainer>
      <DialogBox>
        <ModalDoneText onClickToggleModal={onClickToggleModal}/>
      </DialogBox>


      <Backdrop
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();

          if (onClickToggleModal) {
            onClickToggleModal();
          }
        }}
      />

    </ModalContainer>


  );
}

export default ModalDone;