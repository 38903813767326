import styled from '@emotion/styled';


export const StyledIconWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width : 80px;
  min-height : 74px;
  z-index : 2;
`;



export const SliderButtonLayout = styled.div`
  width : calc(100% + 24px);
  height : 106px;
  position : absolute;
  
  display : flex;
  justify-content : space-between;
  align-items : center;


`
