import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconLocation, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconUserCheck, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/medicalPatient/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/medicalPatient/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/medicalPatient/video/sub/rtlsVideo3.mp4";

export default {
    
    banner : {
        title : '환자 위치 추적',
        desc : '의료 시설에는 각별히 관리해야만 하는 환자들이 존재합니다. 직접 24시간 감시하지 않아도 괜찮습니다. 더 정확하고 빠르기 환자의 위치를, 실시간으로 추적합니다.',

    },

    video : {
        title : '환자를 실시간으로 안전하게 관리하는 방법.',
        desc : '실시간 위치 추적(RTLS) 기술은 환자의 위치를 실시간으로 추적합니다.<br/>환자 관리에 소요되는 인력과 경비를 대폭 줄이고, 동시에 <strong>더욱 안전한 시스템을 구축</strong>할 수 있습니다.',
        play : video
    },

    func : [
        {
            title : '실시간 위치 추적',
            desc : '환자가 현재 의료 시설 내에 어디에 있는지 실시간으로 추적합니다. 만약 병원 내 접근 금지 구역에 다가가는 경우, 실시간으로 관리자에게 알림을 보냅니다.',
            sub1 : '높은 정확도 제공',
            icon1 : IconRulerGray,
            sub2 : '빠른 데이터 연동',
            icon2 : IconShareGray,
            video : subVideo1
            
        },
        {
            title : '바이러스 역학 조사',
            desc : '코로나 이후, 감염병의 초기 대응은 더욱 중요해졌습니다. 감염자의 이동 경로를 추적하여, 접촉자나 의심환자를 파악하여 시설 내 확산을 초기에 예방할 수 있습니다.',
            sub1 : '위험 자산 지정',
            icon1 : IconWarningGray,
            sub2 : '데이터 클라우드 저장',
            icon2 : IconCloudCheckGray,
            video : subVideo2
        },
        {
            title : '낙상 감지',
            desc : '노인 환자를 포함하여, 거동이 불평한 환자들은 항상 낙상 위험이 존재합니다. 이에 즉각 대응할 수 있도록 낙상 감지 시스템을 구축랍니다',
            sub1 : '높은 정확도 제공',
            icon1 : IconRulerGray,
            sub2 : '빠른 데이터 연동',
            icon2 : IconShareGray,
            video : subVideo3
        },


    ],



    subfunc : {
        
        title : '다양한 기능',
        desc : '의료 시설에서 가장 중요한 것은 환자의 건강입니다. 언제 어디서나 환자의 상태는 모니터링되어야 하며, 상황 발생 시 재빠른 초기 대응은 필수적입니다. 오브로는 아래와 같은 기능들을 제공하여, 언제나 안전한 의료 시설을 유지하도록 합니다.',
        
        data : [
            {
                icon : IconChartRadius,
                title : '리포트 및 분석',
                desc : '환자의 동선 데이터를 기반으로 통계 자료를 만들고 분석할 수 있습니다.',
            },
            {
                icon : IconBell,
                title : '실시간 알람 기능',
                desc : '환자가 동선에 이상 상황이 발생하는 경우 알람을 보내 즉각적으로 대처할 수 있습니다.',
            },
            {
                icon : IconRoute,
                title : '자산 동선 조회',
                desc : '특정 환자의 경로를 추적하여, 안전사고를 예방합니다.',
            },
            {
                icon : IconDashboard,
                title : '현황 파악',
                desc : '현재 특정 환자가 어디로 이동하고 있는지 파악합니다.',
            },
            {
                icon : IconLocation,
                title : '실내 네비게이션',
                desc : '길을 잃은 환자 또는 방문자를 파악하여, 의료 관계자가 찾아가 도움을 줄 수 있습니다.',
            },
            {
                icon : IconUserCheck,
                title : '경험 개선',
                desc : '병원을 방문하는 사람들에게 더 큰 신뢰감을 줄 수 있습니다.',
            },
            {
                icon : IconChainLink,
                title : '데이터 연동',
                desc : 'RFID, IOT, 보안, 영상 등의 시스템과 연결하여 효율적으로 활용할 수 있습니다.',
            },
            {
                icon : IconSecurity,
                title : '데이터 보안',
                desc : '높은 데이터 보안 수준을 유지하여 신뢰성이 높은 솔루션을 제공합니다.',
            },


        ],
    },

    system : {
        title : '시스템 구성',
        img : rtlsSystem
    },

    productTitle : '제품 구성',
    product : [

        {
            tag: 'peoplePackage',
        },
        {
            tag : 'assetPackage'
        },
        {
            tag : 'workerPackage'
        },
    ],


    contents : [
        {
        data : 'medicalWaste'
        },
        {
        data : 'logisticsSafety'
        },
        {
        data : 'blogRTLS'
        },
        {
        data : 'blogMedical'
        },

    ]



}