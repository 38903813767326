export default {
    
    download : "자료 다운로드",
    safety: "건설현장 안전관리 솔루션 <br/> 자료 다운로드",
    assets: "물류창고 재고 위치 분석 <br/> 자료 다운로드",
    medical: "병원 의료장비 관리 추적 <br/> 자료 다운로드",
    process: "실시간 생산 공정 모니터링 <br/> 자료 다운로드",
    visitor: "방문객 동선 분석 <br/> 자료 다운로드",


    description: "이메일로 자료를 보내드리겠습니다.",
  
    form: {
      emailPlaceholder: "name@company.com",
      policy: {
        info: "정보를 제공함으로써 Orbro의 ",
        link : "개인정보 처리방침 ",
        agree: "에 동의합니다. ",
        check : "[필수]"
      },
    },

    cancel: "취소",
    submit: "다운로드",


    send: "로<br/> 자료를 발송해 드렸습니다.",
    newsletter : "Orbro의 뉴스레터를 구독하시려면 아래 폼을 작성해주세요! 구독자님들께는 매주 최신 소식과 정보를 이메일로 보내드립니다.",
    name : "이름 (필수)",
    namePlaceholder : "홍길동",
    team : "소속명(필수)",
    teamPlaceholder : "소속 이름",
    phone : "전화번호 (선택)",
    phonePlaceholder : "010-1234-5678",
    skip : "건너뛰기",
    subscribe : "구독하기",

    ask : "Orbro의 뉴스레터를 구독하여 기술 및 다양한 정보를 받아 보시겠습니까?",
    thanks : "구독해 주셔서 감사합니다.",
    thanksMore : "오브로의 뉴스레터를 구독해주셔서 감사합니다! 구독자님들께는 최신 소식과 정보를 이메일로 보내드립니다. 오브로와 함께 더 많은 정보와 지식을 얻어보세요.",
    done : "확인"
  }