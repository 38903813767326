import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";


export const SerivcePage = styled.div`
    width : 100%;
    height : fit-content;
    display : flex;

    flex-direction : row;

    padding : 128px 0 64px 0;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        padding : 96px 0 64px 0;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        flex-direction : column;
        padding : 96px 0 64px 0;
    }
`;


export const ServiceLeftSide = styled.div`
    flex : 1;
    display : flex;
    flex-direction : column;
     
    justify-content : center;

    padding-right : 105px;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        padding-right : 40px;
    }

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding-right : 0px;
    }

`;


export const Appbox = styled.div`

display : flex;
flex-direction : row;
gap : 8px;
margin-top : 32px;
width : 100%;
`;

export const AppBtn = styled.img<{width : string}>`
display : flex;
height : 40px;
cursor : pointer;
${({width})=> width&& `width:${width}px;`}

`;



export const Falldown = styled.div`
    display : flex;
    flex-direction : column;
    gap : 24px;


    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        margin-bottom : 48px;
    }

`;

export const FalldownBox = styled.div`
    display : flex;
    flex-direction : row;
    gap : 16px;
    height : fit-content;

    
`;

export const FallTextBox = styled.div`
    display : flex;
    flex-direction : column;
    cursor : pointer;
    gap : 4px;

`



export const ProgressBar = styled.div<{h : string}>`

  display : flex;
  width : 2px;
  background-color : rgba(0,0,0,0.08);

  ${({h})=> h && `height : ${h}px;`}
 
`;


export const ProgressBar2 = styled.div`

    position : absolute;
    z-index : 3;
    width: 2px;
    height : 56px;

    animation: fillProgress 4s linear forwards; 

        @keyframes fillProgress {
        from {
            background-color : #000;
            height: 0 
        }
        to {
            background-color: #000;
            height: 56px; 
        }
    }
`;
export const ServiceRightSide = styled.div`
    width : 100%;
    display : flex;
    object-fit : contain;
    
    flex : 1;

    aspect-ratio : 600 / 600;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        aspect-ratio : 440 / 560;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        aspect-ratio : 361 / 440;
    }

`

export const RightImg = styled.img`

    width : 100%;
    height : 100%;
    display : flex;
    object-fit : cover;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;

    border-radius: 20px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
    background: var(--background-secondary-light, #F8F8F8);


`



export const SelectBtn = styled.div<{ direction: number }>`
    cursor : pointer;
    width: 56px;
    height : 56px;
    display : flex;
    object-fit : contain;
    justify-content : center;
    align-items : center;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        ${({ direction }) => direction === 0 ? 'margin-left:32px' : 'margin-right : 32px'};
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        margin : auto;
    }
    
    & img { 
        width : 56px;
        height : 56px;
        object-fit : contain;
    }
`;



