import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/retailExhibition/video/rtlsVideo.mp4"

export default {
    helmet: '| What is NVR?',
    desc: 'NVR, or Network Video Recorder, is a device that receives and stores digital video streams transmitted from IP cameras or network cameras.',
    keywords: 'nvr, ip, ptz, rfid, nfc, uwb, wireless communication, location tracking, object location tracking, person location tracking, tag, beacon, rtls, orbro, Kongtech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'What is NVR? | location tracking and digital twin platform',
    ogUrl: 'https://orbro.io/en/blog/nvr',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmL4Stf6lklybaH3OesTetv_ifx8JMPoVd04awuiZ4-02dNTkyV_aHj1fMf2KnR4WC3V8BIU0tp4nnqyMrJ1H3wq2E2MR1v4SC2aV7X0uQuYIPhuSRRExRshekWS5QDY7QauCkENm0TmU7Nd5ybqI8YS9VYjU47WZpMCiPjMURQQed_nvkn0AhE2Yun2VlI0rqIh4wdfyfrV8KCYwxBiuTJu0JyormxuEVYxQX_zzHdRwQHQRmGDKitVvEFslYvVFcRU7zmk1H5su0Nbh0OH-2sr7PH92g1JPUqxCgUlBTxhpLdrL_E6UqQka9fnmGxwy-M42lPeCHvlJ3pjxezqOwNVArbkw4Gtzl8eeAr8IbZ4rZ9wTIRi05-bGw29_Cz-zxphiYy_phH52YKkFQP5NEH11pxn9SDr8Xgj6EZqd95NgevWxTMMi4O1A6iOydRW_EOn4lix025cQB5RehRr0gZlPIVwj_YEku0bG-va9JpG6rAPHUbj_AELcIbLToKJnyJ-DYE-gTASAaIamUNLzKNoXmIGa9phep5L_nIkwEHdA-qzT5dyrmhQDpc4d1ruaYFLHWV-bb-ETaORk4Sdr3Y-aqznGhdsafAvy0lGxYU_DMVM_emH2uyyVFq71xraelVNooi5Qoe-PsPuj8BnSOTSZeLGWmuglDlrgmTuEpFNqsVou185a6VQJO6s71py93y5OpnwMtj3FmATd_eeN9ZY43KUQ2iLDXw125g3abOP4HE4YnRCCsMjz3Vray4TP2_m_9QoRm8OuJBGF4A2CbGJ2M8oLmEQ1Mc3VpNZ9WwdYkGXpBY8yCLEuDwxqeKNMUTkJwbr4YNdpja17MLWg9XHFoVNlG64qk-VkBi4IFSFMDx4LaZD9XsGttmU9bBIIT2jJldP5PYCfTjffwwstwZ4VNG-3bxzqfsCZ3CGeihhAYm1knV-ullCOomsYIaweJwLK2MMRFkxlpRPuT0bbJnFWuCdMoydkNYf3JMh0Bi2kok0mrqpGDEqywp9sccVcp8wZkG1XRVZJPJjwSd2kbiJNR2DfwoneK2ivKwpqhT5kRrl9cpuclYrRgIqnlOuce-kuOj0dJMTozXERXeWUzhXWX4EFywOc-1IoTXFtxiPQL76CyCsHtYImgIoegeus-2_6ZQZtpmxvYL33d95jUw3J_yUPkWETDzHro5RGKOD5RDM41CMUEokOzoWbxmbO0MufhfRGY_PnM5W16XGqSZGRTwenay9yrEB3IPluMmrdpervFylEfCPYJyutGFQigW5iY-tvjM8JVOyzk3OqLzqq9LjnJAyhkCJZ2ZGtPKZPvMvOSlovrdc93ztDGD6dorfc1SWfaxDOi4kQduozkGE8GSEkHRte9hFjdoWMGblqD1Lu4ADPGwr2546UPK93UJbiCtj2SUaSVV8E5R1XD3cy4mKcZ27rsDh_s_os0RaZ8lEQfPHZMmphlOnC2S5AnuXnZqqQWPQJD9b2v8kYV9CS3vW8_Gr3zLg1jcDqGfeBbzTg3g3rtTUPi885LTBUpT_NK2a5Ux-HCwPu1da_Cen1_2-oto=w1984-h1208',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'What is NVR?',
    date: '2024-01-12',
    tag1: '#nvr',
    tag2: '#camera',
    tag3: '#securitycamera',
    tag4: '#locationtracking',

    text: [
        {
            head: 'What is NVR?',
            subHead: '',
            desc: 'NVR, or Network Video Recorder, is a device that receives and stores digital video streams transmitted from IP cameras or network cameras. It is commonly used in surveillance and security systems, distinguishing itself from the traditional Digital Video Recorder (DVR) by primarily operating with digital cameras. NVR is designed to manage and access videos from various locations through a network, making it especially useful for large facilities or surveillance spanning multiple areas. It provides superior performance with higher resolution and advanced features. Functionally, NVR receives video streams from multiple cameras simultaneously and securely stores them. Stored videos can later be searched, played, or analyzed. Many NVR systems offer advanced features such as email notifications, remote monitoring, and motion detection for efficient surveillance. With these features, NVR has become a key component of modern network-based surveillance systems in environments with high security requirements.',
            dot: ''
        },
        {
            video: video,
            link: '/en/enterprise/retail/exhibition',
            head: 'NVR Features',
            subHead: 'Camera Management',
            desc: '',
            dot: {
                a: ' • Supports and efficiently manages multiple digital cameras.',
                b: ' • Allows flexible configuration through camera grouping and individual settings.',
            }
        },
        {
            head: '',
            subHead: 'High Resolution and Compression',
            desc: '',
            dot: {
                a: ' • Supports high-resolution video and maintains storage efficiency through compression algorithms.',
                b: ' • Supports compression standards such as H.264, H.265 to optimize bandwidth and storage usage.',
            }
        },
        {
            head: '',
            subHead: 'Network Connectivity',
            desc: '',
            dot: {
                a: ' • Provides real-time or recorded video access from various locations through the network.',
                b: ' • Enables remote management and monitoring, supporting efficient collaboration with multi-user support.',
            }
        },
        {
            head: '',
            subHead: 'Notification and Event Management',
            desc: '',
            dot: {
                a: ' • Detects and manages events such as motion detection, video loss, camera cover, and interruptions.',
                b: ' • Allows setting up notifications and alerts for a quick response to important events.',
            }
        },
        {
            head: '',
            subHead: 'Video Search and Playback',
            desc: '',
            dot: {
                a: ' • Can search and playback videos based on specific time frames or events.',
                b: ' • Provides precise control with video splitting, zooming, and frame-level adjustments.',
            }
        },
        {
            head: '',
            subHead: 'Storage Management',
            desc: '',
            dot: {
                a: ' • Efficiently manages storage and offers various storage options.',
                b: ' • Automatically deletes old videos according to a schedule or transfers them to other storage devices.',
            }
        },
        {
            head: '',
            subHead: 'Various Security Features',
            desc: '',
            dot: {
                a: ' • Allows setting access permissions at various levels through user permission management.',
                b: ' • Supports encryption technology for secure remote access.',
            }
        },

        {
            head: 'Advantages of NVR',
            subHead: 'High Resolution and Quality',
            desc: 'NVR is mainly used in conjunction with digital cameras to support high resolution and quality video. This provides clear details, allowing more accurate surveillance as needed.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Network-Based Architecture',
            desc: 'NVR communicates with cameras through the network, allowing easy access to videos from remote locations. This provides a convenient method for real-time monitoring and video retrieval.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Flexibility and Scalability',
            desc: 'NVR systems support multiple cameras and can easily expand the system as needed. Adding new cameras or upgrading the system is simple, allowing flexibility to adapt to changes in security requirements.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Remote Monitoring and Notification Features',
            desc: 'NVR supports remote video monitoring, and with advanced features such as motion detection or email notifications, immediate alerts can be received when events are detected. This aids in quick response and effective management.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Video Search and Analysis Features',
            desc: 'Some NVR systems provide video search and analysis features to detect specific events and quickly find the required information. This supports efficient utilization of investigation or surveillance data.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Efficient Storage and Management',
            desc: 'NVR provides efficient video storage and management features, utilizing storage effectively and securely retaining video records for the required duration.',
            dot: ''
        },

        {
            head: 'Difference between NVR and DVR',
            subHead: 'Camera Connection and Signal Processing',
            desc: 'NVR is mainly used with digital cameras, supporting high resolution and quality video. This provides clear details, allowing more accurate surveillance as needed.',
            dot: {
                a: '<strong> • DVR: </strong>DVR is primarily used with analog cameras, converting analog signals to digital for processing.',
                b: '<strong> • NVR: </strong>NVR is used with digital cameras or IP cameras, processing data already transmitted in digital form.',
            }
        },
        {
            head: '',
            subHead: 'Video Quality and Features',
            desc: '',
            dot: {
                a: '<strong> • DVR: </strong>Typically provides lower video quality, and advanced features may be limited.',
                b: '<strong> • NVR: </strong>Offers high resolution and advanced features, supporting improved video quality and various functionalities.',
            }
        },
        {
            head: '',
            subHead: 'Camera Support and Resolution',
            desc: '',
            dot: {
                a: '<strong> • DVR: </strong>Mainly supports low-resolution analog cameras and may not be suitable for high-resolution videos.',
                b: '<strong> • NVR: </strong>Supports digital cameras, excelling in compatibility with high-resolution and multi-megapixel cameras.',
            }
        },
        {
            head: '',
            subHead: 'Network Connection',
            desc: '',
            dot: {
                a: '<strong> • DVR: </strong>Usually connected to the local network, and remote access may be more limited.',
                b: '<strong> • NVR: </strong>Transmits and receives data through the network, allowing easy access from remote locations.',
            }
        },
        {
            head: '',
            subHead: 'Scalability',
            desc: '',
            dot: {
                a: '<strong> • DVR: </strong>Generally supports a specific number of cameras, and system expansion may be more challenging.',
                b: '<strong> • NVR: </strong>Supports multiple cameras and allows easy system expansion as needed.',
            }
        },
        {
            head: '',
            subHead: 'Installation and Maintenance',
            desc: '',
            dot: {
                a: '<strong> • DVR: </strong>Analog systems may have simple installation and maintenance, but overall system performance may be lower.',
                b: '<strong> • NVR: </strong>Digital systems may be complex but offer advanced features and performance with convenient maintenance.',
            }
        },
    ],

    link: {
        page: 'camera',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.indoor.peopleTracking.title,
                title: productPurchase.purchase.indoor.peopleTracking.title,
                serial: productPurchase.purchase.indoor.peopleTracking.serial,
                desc: 'Analyzing the movement paths of people indoors through cameras.',
                img: productImg.product.peopleTracking.thumbnail
            },
        ],
        
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/en/enterprise/medical/patient'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/en/enterprise/retail/exhibition'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/en/enterprise/office/asset'
            }
        ]

    }
}
