import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconPenSquare, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem3 } from "@assets/images/solution";
import video from "@src/assets/images/solution/retailExhibition/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/retailExhibition/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/retailExhibition/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/retailExhibition/video/sub/rtlsVideo3.mp4";
import g from "@src/assets/images/solution/retailExhibition/video/.mp4";



export default {
    
    banner : {
        title : '방문객 관리 및 분석',
        desc : '정확하고 객관화된 데이터는 새롭게 비즈니스를 성장시킬 수 있습니다.<br/>낭비되는 인력과 경비를 최소화하고 스마트한 리테일 이미지를 제공하며, 차별화된 운영이 가능합니다.',

    },

    video : {
        title : '방문객을 실시간으로 관리하고 분석하는 법.',
        desc : '오브로는 관람객의 위치를 실시간으로 알려줍니다.<br/>정확하고 객관화된 방문객의 데이터를 바탕으로, <strong>사람들이 더 선호하는 전시관</strong>으로 만들 수 있습니다.',
        play : video
    },

    func : [
        {
            title : '실시간 유동인구 파악',
            desc : '실시간 유동 인구를 파악하면 전시관의 레이아웃과 전시품 배치 등, 전시관 운영 계획을 효과적으로 세울 수 있습니다.',
            sub1 : '실시간 현황 파악',
            icon1 : IconClockGray,
            sub2 : '빠른 데이터 연동',
            icon2 : IconShareGray,
            video : subVideo1
            
        },
        {
            title : '관람객 분석',
            desc : '관람객이 전시관에 들어와서 어떤 방식으로 이동하는지 동선을 분석할 수 있습니다. 또한 선호 작품을 파악하여, 전시관 운영에 의미 있는 지표를 제공합니다.',
            sub1 : '통계 데이터 제공',
            icon1 : IconChartBarGray,
            sub2 : '높은 정확도 제공',
            icon2 : IconRulerGray,
            video : subVideo2
        },
        {
            title : '전시품 안전 관리',
            desc : '전시품의 훼손과 도난을 실시간으로 감시합니다. 이로써 기존보다 빠른 선제 조치가 가능합니다.',
            sub1 : '높은 정확도 제공',
            icon1 : IconRulerGray,
            sub2 : '빠른 데이터 연동',
            icon2 : IconChainLinkGray,
            video : subVideo3
        },


    ],



    subfunc : {
        
        title : '다양한 기능',
        desc : '기존 방식으로는 관람객들로부터 의미 있는 데이터를 얻기가 쉽지 않습니다. 오브로의 새로운 기능들을 통해 전시관 운영에 효율성을 높이고 비즈니스를 성장시킬 수 있습니다.',
        
        data : [
            {
                icon : IconChartRadius,
                title : '리포트 및 분석',
                desc : '자산 사용 기록을 기반으로 보고서 및 분석 자료를 생성할 수 있습니다.',
            },
            {
                icon : IconBell,
                title : '실시간 알람 기능',
                desc : '전시관에 이상 상황이 발생하는 경우 알람을 보내 즉각적으로 대처할 수 있습니다.',
            },
            {
                icon : IconRoute    ,
                title : '날짜별 유동 인구 동선 조회',
                desc : '날짜별로 유동 인구가 어떤 방식으로 이동했는지 파악할 수 있습니다.',
            },
            {
                icon : IconDashboard,
                title : '현황 파악',
                desc : '현재 관람객들이 어디로 이동하고 있는지 파악합니다.',
            },
            {
                icon : IconWarning,
                title : '이탈 감지',
                desc : '관람객들이 접근 금지 구역으로 다가가는 것을 감지합니다.',
            },
            {
                icon : IconPenSquare,
                title : '작업자 지시',
                desc : '전시관 상황을 기반으로, 작업자에게 다음 지시를 내릴 수 있습니다.',
            },
            {
                icon : IconChainLink,
                title : '데이터 연동',
                desc : 'RFID, IOT, 보안, 영상 등의 시스템과 연결하여 효율적으로 활용할 수 있습니다.',
            },
            {
                icon : IconSecurity,
                title : '데이터 보안',
                desc : '높은 데이터 보안 수준을 유지하여 신뢰성이 높은 솔루션을 제공합니다.',
            },


        ],
    },

    system : {
        title : '시스템 구성',
        img : rtlsSystem3
    },

    productTitle : '제품 구성',
    product : [

        {
            tag: 'peoplePackage',
        },
        {
            tag : 'assetPackage'
        },
        {
            tag : 'workerPackage'
        },
    ],


    contents : [
        {
        data : 'manufacturingAsset'
        },
        {
        data : 'logisticsSafety'
        },
        {
        data : 'blogMedical'
        },
        {
        data : 'blogRTLS'
        },

    ]



}