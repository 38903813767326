import { buildingWhite, groupWhite, hospitalWhite, toolsWhite, trafficWhite, truckWhite } from "@assets/images/pricing";

export default {

    title: '获取报价',
    link: '/cn/pricing/1',

    step1: {
        title: '您对哪个行业感兴趣？',
        percent: '0%',
        common: '常见应用',
        industry: [
            {
                title: '建筑',
                example: '涉及工人安全管理、材料管理、出入管理、环境管理等。',
                icon: trafficWhite
            },
            {
                title: '物流',
                example: '涉及库存管理、安全管理、环境管理、出入管理等。',
                icon: truckWhite
            },
            {
                title: '医院',
                example: '涉及出入管理、患者位置跟踪、医疗废物管理、空气质量管理等。',
                icon: hospitalWhite
            },
            {
                title: '办公室',
                example: '涉及出入管理、资产管理、空气质量管理等。',
                icon: buildingWhite
            },
            {
                title: '制造',
                example: '涉及出入管理、库存管理、空气质量管理、工序管理等。',
                icon: toolsWhite
            },
            {
                title: "其他",
                example: "零售、航空、港口、行人流分析、停车控制、空气质量管理等",
                icon: groupWhite
            }
              
        ],
        next: '下一步'
    },

    step2: {
        title: '您对哪种产品感兴趣？',
        percent: '25%',
        common: '了解更多',
        product: [
            {
                title: '蓝牙信标设备',
                example: '防水防尘的工业场景验证蓝牙信标设备。'
            },
            {
                title: '无线位置追踪',
                example: '通过给资产和工人添加标签，实现精确且实时的位置追踪。'
            },
            {
                title: '基于摄像头的位置追踪',
                example: '使用AI摄像头跟踪和分析人员、车辆等。'
            },
            {
                title: '出入管理系统',
                example: '智能管理建筑出入，快速且安全。'
            },
            {
                title: '工业传感器',
                example: '监测温度、湿度、压力、泄漏、微粒子、一氧化碳等现场环境状态。'
            },
            {
                title: '数字孪生',
                example: '收集空间中的所有数据以构建虚拟现实。'
            },
        ],
        prev: '上一步',
        next: '下一步'
    },

    step3: {
        title: '请检查以下项目。',
        percent: '50%',
        question: [
            {
                title: '蓝牙信标设备的用途和数量是多少？',
                parts: [
                    {
                        title: '用途',
                        type: 'choice2',
                        option: [
                            { text: '仅购买' },
                            { text: '研究或试验项目' },
                            { text: '引入解决方案（服务利用）' },
                            { text: '其他' },
                        ]
                    },
                    {
                        title: '预期数量',
                        type: 'choice1',
                        option: [
                            { text: '1~1,000个' },
                            { text: '1,000~2,000个' },
                            { text: '2,000~5,000个' },
                            { text: '5,000个以上' },
                        ]
                    },
                ]
            },
            {
                title: '位置追踪的准确性和区域大小是多少？',
                parts: [
                    {
                        title: '准确性',
                        type: 'choice1',
                        option: [
                            { text: '资产追踪（在5米范围内进行资产追踪）' },
                            { text: '高精度追踪（在50厘米范围内进行资产追踪）' },
                            { text: '其他' },
                        ]
                    },
                    {
                        title: '区域大小',
                        type: 'choice1',
                        option: [
                            { text: '0~100平方米' },
                            { text: '100~500平方米' },
                            { text: '500平方米以上' },
                            { text: '不清楚' },
                        ]
                    },
                ]
            },
            {
                title: '在哪里使用摄像头追踪，需要追踪的对象是什么？',
                parts: [
                    {
                        title: '追踪对象',
                        type: 'choice3',
                        option: [
                            { text: '人员' },
                            { text: '车辆' },
                            { text: '其他' },
                        ]
                    },
                    {
                        title: '安装位置',
                        type: 'choice3',
                        option: [
                            { text: '室内' },
                            { text: '室外' },
                            { text: '不清楚' },
                        ]
                    },
                ]
            },
            {
                title: '出入管理系统在哪些入口处使用，入口数量是多少？',
                parts: [
                    {
                        title: '入口位置',
                        type: 'choice3',
                        option: [
                            { text: '公寓' },
                            { text: '办公室和商店' },
                            { text: '其他' },
                        ]
                    },
                    {
                        title: '入口数量',
                        type: 'choice1',
                        option: [
                            { text: '1~10个入口' },
                            { text: '10~20个入口' },
                            { text: '20~50个入口' },
                            { text: '50个以上的入口' },
                        ]
                    },
                ]
            },
            {
                title: '需要哪种类型的传感器？',
                parts: [
                    {
                        title: '',
                        type: 'multichoice2',
                        option: [
                            { text: '紧急报警器' },
                            { text: '火警检测' },
                            { text: '温湿度' },
                            { text: '泄漏检测' },
                            { text: '压力' },
                            { text: '入侵检测' },
                            { text: '空气质量' },
                            { text: '一氧化碳检测' },
                        ]
                    },
                ]
            },
            {
                title: ' ',
                parts: [
                    {
                        title: ' ',
                        type: ' ',
                        option: [
                            { text: ' ' },
                        ]
                    },
                ]
            },
        ],
        prev: '上一步',
        next: '下一步'
    },

    step4: {
        title: '请提供您的电子邮件地址以便联系。',
        percent: '75%',
        email: '电子邮件',
        emailHolder: 'example@orbro.io',
        prev: '上一步',
        next: '下一步'
    },

    step5: {
        title: '我们将通过负责人与您联系。',
        desc: '输入所需的咨询信息将有助于我们提供更好的服务。',
        name: '姓名',
        nameHolder: '张三',
        phone: '电话号码',
        phoneHolder: '138-1234-5678',
        memo: '备注',
        memoHolder: '请输入信息。',
        prev: '通过电子邮件获取信息',
        next: '提交附加信息'
    },

    step6: {
        title: '已完成',
        desc: '我们会尽快核实您的信息并与您联系。如果需要专业工程师的电话咨询，请继续预约。',
        btn: '预约电话咨询'
    },

    schedule: {
        title: '请输入可通话的时间。',
        company: '公司',
        companyHolder: '奥博罗',
        name: '姓名',
        nameHolder: '张三',
        date: '日期',
        time: '时间',
        submit: '提交',
        morning: '上午',
        noon: '下午'
    }
}
