import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from '@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4';

export default {


  helmet: '| 비콘(Beacon)이란? | 오브로, 국내 1위 비콘 회사',
  desc: '비콘은 작은 전파 송신 장치로, 무선 통신을 이용하여 특정 위치에 있는 스마트 디바이스에 정보를 전달하는 역할을 합니다. 주로 Bluetooth Low Energy(BLE) 기술을 활용하며, 작은 크기와 저전력 소비를 특징으로 합니다.',
  keywords: '비콘, IoT, 무선 통신, 실내 위치추적, 위치 추적, 블루투스, bluetooth, ble, 플루토콘, plutocon, ibeacon, orbro, 오브로, 콩테크',

  ogTitle: '',
  ogDesc: '',
  ogSite: '비콘(Beacon)이란? | 오브로, 국내 1위 비콘 회사 :: 오브로 블로그',
  ogUrl: 'https://orbro.io/blog/beacon',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxa-ucISW1k_6fC9t-ZpGPIV1qRXWXcZO1OhYuIe-j3MLl1BdXVIIv40aG69pNCT6AD0WANYJ1p4OjzDQF9yM8_m5CaFglWOtunF7ZF0uySt_dYAB4pE7shLBOrdeIkyxz_3esBC_Qd-x3FmnZ4jLNcZazyIYAPR_ZuRbLW2E_sui_6vQWc2_iABmwSImfVtoyWAhaCjoHOk7UonwVJTlXVq-NJmIqcpT0-KgFYxoTYR_i_qaS1PR8hzLVcOzzk1Z7ng11UP9IaQiWcX6pFAZnxvGsUJDNz9eiIxIRifGlSF2rGl3O6KQKRRdOU1LATUHY1Rkym6F5nLo87mxlA9iqybXGIED_3BG2ywgaf3uYgBloZH3CGqi4IMUUD-dN7cbbwxrQyiEppY9GA0xNk9uatZggX5TaYItKRL0fGmJXsHn18bQHWue-OCSVs_IjMmQ6omlyiJiQAVMSGu65C3hd8ZIQGZDq0EzutRLIXyb7f6OVVcogIw8fmAHnyhzYh-ffT1Rl0kp7BSYB0y-ooqoM_z3ut5Znu5TWC4EndXrRa4BB7KOnh--fa8H0-rbNiorUZmhoedmMZHCUuHKEjiyshQaX9UiAn2Rr-Uvtuvqs-TYIRAHroGgOBrzaGBEycwr3rifKxJx-fBbAO8fe7xhZD8RNeniCCIXkf4o_crQdpMZuDeKh5_f7URFr8NU_5vs74I1lhdV_Bgq5bYEI0-b7N7JV4-Wr_zSbOLTMb5EZ-vyj43hGUKLdgOUHTYYNP8plRV5_UMElexKEid5ZmX2aj1QrTT9zBm5c_SunfkZOEK6kY6GuV7NagmSgQDhcVmX_Qgf9tJoEw07dJxwXPxOzZPO5nZDkX_xA1oDKNNsgNpPXuVjmEI2K_O-syucjYFlVnVagL7aQ-V4givU9qKYBasb6s2hfajR4L4BAvyBp-EiItjTy3dK4H8fk1sdf6OlqMfNS3DQqBu5gpyMD28HyW0gk7wC-hs0C3EsglleW016V9ELEcMiX9OTvM1dozJQFzpMEXve9fw44h-D0lW5MuJIfDb_HvD4FB0ZVrab-8kKD-99NVk2mfZigqE3tPXhEb559joMsf5UYlRvjLz7DqnBmNwPBMQavjtKTmwq6gPHsLn4hR2ENgscKrdmGFYbt-pSwwinouRHIWBOAeLbIU4R9AOWId11R5oG0IFEuiAib3dTKuzObWCeWjN4aP-24uX46l_DnyL1K9zDS3MOZbqbQOaTjkTIsx7FlLrk1iw5P3RxjboS7VC048bElpy68OsGGr5c30D4owv38GeXx3eQm4P8RDq7Ubxe9W3Z1a-CNQhDMGEBpns6BLrZ2d8AO3TaS7HhGcvmbbGMsdgmoPRZgElmoN6l1a7ktrDjuTD2NWNvLUJ6d7WxCpEG9S5RClt8JSoR884w_PQAONR_WMbvHpL-xm_fuWqWDDpYT1_7ScbWFIwBwUTop8jrIiPrrgrHHNAVloR9YwiH3J_U5jMMK9rNENYv9rHahEOLrwcJzGzbnbbkocERqVusvbsC1pl-fPUAVDjtgKf89d9X6FPsVJ95xED_hnGthC4JfXqLnPbKG_=w3356-h2242',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '비콘(Beacon)이란?',
  date: '2023-11-08',
  tag1: '#비콘',
  tag2: '#블루투스',
  tag3: '#BLE',
  tag4: '#오브로',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: '비콘(beacon)은 현대 디지털 시대의 혁신적인 기술 중 하나로, 우리 일상 생활과 비즈니스 분야에 적용되어 있습니다. 비콘은 무엇이고, 어떻게 작동하는지, 그리고 그 활용 분야에 대해 알아보겠습니다.',
      dot: ''
    },
    {
      head: '비콘(beacon)이란?',
      subHead: '',
      desc: '비콘은 작은 전파 송신 장치로, 무선 통신을 이용하여 특정 위치에 있는 스마트 디바이스에 정보를 전달하는 역할을 합니다. 주로 Bluetooth Low Energy(BLE) 기술을 활용하며, 작은 크기와 저전력 소비를 특징으로 합니다. 이러한 비콘은 주변 환경에 자신의 존재와 위치를 알릴 수 있습니다.',
      dot: ''
    },
    {
      head: '비콘의 작동 원리',
      subHead: '',
      desc: '비콘은 매우 단순한 작동 원리를 가지고 있습니다. 비콘은 주기적으로 신호를 방출하며, 주변에 있는 스마트 디바이스(예: 스마트폰)는 이 신호를 감지하고 해당 비콘의 위치를 파악합니다. 이를 통해 스마트 디바이스는 특정 위치에서 어떤 일을 수행할 수 있게 됩니다.',
      dot: ''
    },
    {
      head: '활용 분야',
      subHead: '',
      desc: '비콘은 다양한 분야에서 활용되고 있습니다. 그 중에서도 다음과 같은 분야에서 큰 주목을 받고 있습니다.',
      dot: {
        a: '<strong>마케팅 및 광고 : </strong>상점이나 박물관에서 비콘을 활용하여 고객에게 특별한 할인 혹은 정보를 제공하거나, 광고를 보다 효과적으로 전달할 수 있습니다.',
        b: '<strong>위치 기반 서비스 : </strong>비콘을 이용하여 실내에서 정확한 위치 정보를 제공하고, 내비게이션 기능을 향상시킬 수 있습니다.',
        c: '<strong>스마트 홈 및 IoT : </strong>비콘을 활용하여 가정 내의 다양한 스마트 기기들을 연결하고, 자동화 및 편의성을 높일 수 있습니다.',
        d: '<strong>헬스케어 : </strong>환자 모니터링 및 의료기기에서 활용하여 의료 서비스의 질을 향상시킵니다.'
      }
    },
    {
      video: video,
      link: "https://orbro.io/enterprise/medical/patient",
      head: '장점',
      subHead: '',
      desc: '비콘 기술은 다른 위치 기반 기술과 비교하여 몇 가지 장점을 가지고 있습니다.',
      dot: {
        a: '<strong>정확성 : </strong>비콘은 정확한 위치 정보를 제공하며, 건물 내부와 같이 GPS 신호가 도달하지 않는 환경에서도 작동합니다.',
        b: '<strong>저전력 : </strong>비콘은 저전력 소비로 오랜 배터리 수명을 가지고 있습니다.',
        c: '<strong>간편성 : </strong>비콘을 설정하고 관리하기 쉽습니다.'
      }
    },
    {
      head: '마무리',
      subHead: '',
      desc: '비콘은 현대 기술의 발전으로 우리의 일상 생활과 비즈니스에 큰 영향을 미치는 중요한 기술 중 하나입니다. 그 활용 분야는 계속해서 확장되고 있으며, 앞으로 더 다양한 혁신적인 서비스와 기회를 제공할 것으로 기대됩니다. 비콘의 미래에 대한 기대감을 갖고, 이 기술을 활용하여 우리의 생활을 더욱 스마트하고 편리하게 만들어보는 것은 어떨까요?',
      dot: ''
    },

  ],

  link: {
    page: 'access',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.auto.accessauto.title,
        title: productPurchase.purchase.auto.accessauto.title,
        serial: productPurchase.purchase.auto.accessauto.serial,
        desc: '자동문 출입에 특화된 제품으로 기존 자동문 센서 교환으로 모바일 앱 설치를 통해 비접촉 출입이 가능하도록 합니다.',
        img: productImg.product.accessauto.key1
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/enterprise/logistics/asset'
      }
    ]

  }


}