import { StyledGridContainer } from "@components/Grid/Grid.style";
import { DisplayH1 } from "@components/Text/HeadingTagDisplay.style";
import { LabelColor, ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import { DivAllCenter, DivPadding } from "@pages/CommonStyle/commonDiv.style";
import personal from "@src/lang/ko/signupconditions/personal";
import React from "react";
import { Trans } from "react-i18next";

const SignUpConditionsUsing = () => {


    return (
        <>
            <Spacing pixel="128" />
            <StyledGridContainer>

                <DisplayH1 size={"small"}>
                    <Trans i18nKey={personal.title} />
                </DisplayH1>
                <Spacing pixel="64" />
                {personal.text.map((data, i) => (
                    <>
                        <LabelColor color="#000000" size="large">
                            <Trans i18nKey={data.title} />
                        </LabelColor>
                        <Spacing pixel="16" />
                        {data.desc &&
                            <>
                                <ParagraphColor color={"#000000"} size={"small"}>
                                    <Trans i18nKey={data.desc} />
                                </ParagraphColor>
                                <Spacing pixel="64" />
                            </>
                        }
                    </>
                ))}
            </StyledGridContainer>
        </>
    )
}


export default SignUpConditionsUsing;