import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet: '',
  desc: 'ORBRO的GIS实施提供了一个将精密制图与3D地形模拟相结合的实时地理信息系统。该系统支持专家进行地形分析、基础设施规划和灾害管理决策，反映了ORBRO的创新技术能力。',
  keywords: 'Digital Twin, Digital Twins, 数字孪生, gis, 数字城市, RTLS, 位置追踪系统, 位置追踪技术, 体育, 制造业, 物流, 医疗, 医院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - GIS实施 :: ORBRO博客',
  ogUrl: 'https://orbro.io/cn/blog/digitaltwin-gis',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twin - GIS实施',
  date: '2023-12-26',
  tag1: '#数字孪生',
  tag2: '#GIS',
  tag3: '#数字城市',
  tag4: '#虚拟现实',

  // <br/>
  text: [
    {
      head: 'Digital Twin - GIS实施',
      subHead: '',
      desc:'ORBRO的GIS（地理信息系统）实现利用数字孪生技术提供了创新的实时地理信息系统，整合了精确的制图和3D地形模拟。该系统支持专家进行地形分析、基础设施规划和灾害管理决策，反映了ORBRO尖端技术的能力。<br/><br/>3D地形模型以可视方式表示地形的准确形状、坡度和高度，用于环境分析、城市规划和灾害管理。基础设施数据通过3D建模和GIS技术数字化，对于城市发展和灾害响应至关重要。<br/><br/>建筑信息利用高分辨率照片、建筑设计数据和3D扫描进行建模，在建筑设计和城市规划中发挥着关键作用。GIS技术为城市规划、交通流优化和公共服务部署提供了必要的信息，同时在灾害响应、环境变化和保护中也发挥着关键作用。',
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/uUm8my-ogGs?si=UNRDMDxCeI3ZWxyt',
      head: '',
      subHead: '地球形状的地理表达',
      desc: 'ORBRO整合卫星图像、地形数据和地理信息系统（GIS）以构建高分辨率的3D地形模型。该模型实时可视化地形的形状、坡度和高度等细节，在环境分析、城市规划、灾害管理和救援规划等各个领域中做出重要决策。',
      dot: ''
    },
    {
      head: '',
      subHead: '地形和基础设施的表达',
      desc: '从各种来源收集基础设施数据，并通过3D建模和GIS技术进行数字化。这些信息对城市和区域发展、灾害响应和安全规划至关重要。',
      dot: ''
    },
    {
      head: '',
      subHead: '建筑信息的3D表达',
      desc: 'ORBRO使用高分辨率照片、建筑设计数据和3D扫描技术精确建模建筑的外观和内部结构。这种建模对于建筑设计、城市规划、房地产开发和评估至关重要。',
    },
    {
      head: '',
      subHead: '城市规划和管理的改善',
      desc: 'ORBRO的GIS技术有助于改善复杂城市环境中的规划和管理。它提供了优化交通流、布置公共服务和管理城市扩展所需的关键信息。',
    },
    {
      head: '',
      subHead: '灾害响应和准备支持',
      desc: '利用GIS技术支持自然灾害和紧急情况的响应和准备。利用地形和基础设施数据识别高风险区域，规划疏散路线，分配救援资源等具有重要作用。',
    },
    {
      head: '',
      subHead: '环境变化和保护',
      desc: 'ORBRO的GIS是监测和分析环境变化的重要工具。该技术有助于了解生态系统变化、管理自然保护区和制定环境保护政策。',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '通过将UWB终端安装在工作者或设备上，实现实时位置追踪的产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }
    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]
  }
}
