
import { outdoorOff, outdoorOn, indoorOn, indoorOff, IconAlarm, IconAnalyze, IconSDK, IconExcel, IconImage, IconTracking, IconLocation, IconHeatmap } from '@assets/images/main/index';
import { CameraFunctions1D, CameraFunctions1M, CameraFunctions1T,
    CameraFunctions2D, CameraFunctions2M, CameraFunctions2T, 
    CameraFunctions3D, CameraFunctions3M, CameraFunctions3T, 
    CameraFunctions4D, CameraFunctions4M, CameraFunctions4T, 
    CameraFunctions5D, CameraFunctions5M, CameraFunctions5T, 
    CameraFunctions6D, CameraFunctions6M, CameraFunctions6T, 
    CameraFunctions7D, CameraFunctions7M, CameraFunctions7T } from '@assets/images/main/camera/main/functions';
import { IconOsOff, IconOsOn } from '@assets/images/main/icon';


export default {

    mainPage: {
        product: {
            title: 'Discover Product Configurations',
            desc: 'Check out the innovative location tracking product configurations.',
            outdoor: {
                title: 'Outdoor Cameras',
                desc: 'Detect and manage the location and abnormal behavior of various objects such as people, vehicles, and more using cameras.',
            },
            indoor: {
                title: 'Indoor Cameras',
                desc: 'Analyze the movement paths of indoor individuals using cameras.',
    
            }
        }
    
    },
    
    banner: {
        title: 'Camera Location Tracking using ORBRO AI.',
        desc: 'ORBRO Camera System manages the location and abnormal behavior of various objects such as people and vehicles.',
    
        schemeTitle: 'Camera System Proposed by ORBRO.',
        schemeDesc: 'Need help making a decision?',
        schemeDesc2: 'Contact us and our entry experts will assist you.',
        phone: '1522-9928',
    },
    
    bannerData: [
        {
            icon: indoorOn,
            iconInv: indoorOff,
            image: '',
            iconText: 'Indoor Cameras',
            sub: '',
            link : '/en/camera/indoor'
        },
        {
            icon: outdoorOn,
            iconInv: outdoorOff,
            image: '',
            iconText: 'Outdoor Cameras',
            sub: 'New',
            link : '/en/camera/outdoor'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/en/os'
        },

    
    ],
    
    video: {
        title: 'ORBRO Artificial Intelligence Camera System',
        desc: [
            {text: 'Collects and analyzes the location information of vehicles and people in outdoor spaces.'},
            {text: 'Identifies the location information of people moving indoors.'},
            {text: 'Can be integrated with existing camera infrastructure such as public CCTV.'},
            {text: 'Allows easy addition of necessary camera equipment for use.'},
        ]
    },
    
    
    
    func: {
        title: 'Explore Camera Functions',
        desc: 'Check out the various functions of the camera.',
    
        data: [
            {
                img: IconHeatmap,
                title: 'Heatmap',
                desc: 'Provides real-time accumulated data to offer time-based heatmaps.'
    
            },
            {
                img: IconLocation,
                title: 'Real-time Location Tracking',
                desc: 'ORBRO\'s location tracking system tracks positions in real-time. You can monitor the site with a lively and analytical feature.'
    
            },
            {
                img: IconAnalyze,
                title: 'Analysis Tools',
                desc: 'Collects numerous location data to obtain statistical data and insights.'
    
            },
            {
                img: IconAlarm,
                title: 'Alarm Settings',
                desc: 'Set up danger zones where people should not enter and receive notifications.'
    
            },
            {
                img: IconImage,
                title: 'Object Recognition',
                desc: 'Recognizes various types of objects by analyzing captured images.'
    
            },
            {
                img: IconTracking,
                title: 'SDK Provision',
                desc: 'Analyzes spaces and detects anomalies without requiring tag terminals.'
    
            },
            {
                img: IconExcel,
                title: 'Excel Download',
                desc: 'Refine and download data into Excel files for your needs.'
    
            },
            {
                img: IconSDK,
                title: 'SDK Provision',
                desc: 'ORBRO\'s location tracking system provides SDK to support custom development for clients\' needs.'
    
            },
        ]
    
    },
    
    
    functions: {
        title: 'Digital Twin Location Tracking Technology Using AI',
        desc: `ORBRO's AI camera system boasts high accuracy and stability. It tracks and analyzes the location of people and vehicles indoors and outdoors, providing users with the necessary data.`,
    
        line: 3,
        card: [
            {
                size: '3',
                tag: 'Key Features',
                title: 'Objects detected in the video<br/>are represented in real-time on the map',
                label: 'Administrator',
                img: {
                    desktop: CameraFunctions1D,
                    tablet: CameraFunctions1T,
                    mobile: CameraFunctions1M
                },
            },
            {
                color: '#fff',
                size: '1',
                tag: 'Mobile Service',
                title: 'Mobile Usability<br/>in All Spaces',
                label: 'Common Offer',
                img: {
                    desktop: CameraFunctions2D,
                    tablet: CameraFunctions2T,
                    mobile: CameraFunctions2M
                },
            },
            {
                size: '2',
                tag: 'Compatibility with Existing CCTV',
                title: 'Compatibility with All Video Data',
                label: 'Administrator',
                img: {
                    desktop: CameraFunctions3D,
                    tablet: CameraFunctions3T,
                    mobile: CameraFunctions3M
                },
            },
            {
                size: '2',
                tag: 'Person and Vehicle Object Recognition',
                title: 'Detection<br/>with High Recognition Rate',
                label: 'Administrator',
                img: {
                    desktop: CameraFunctions4D,
                    tablet: CameraFunctions4T,
                    mobile: CameraFunctions4M
                },
            },
            {
                size: '2',
                tag: 'On-Site Server Setup',
                title: 'The Most Reliable<br/>and Secure Security System',
                label: 'On-Premise',
                img: {
                    desktop: CameraFunctions5D,
                    tablet: CameraFunctions5T,
                    mobile: CameraFunctions5M
                },
            },
            {
                size: '1',
                tag: 'Enterprise-Grade Installation for Outdoor Parking Lots, etc.',
                title: 'Applicable for Large-Scale Parking Facilities',
                label: 'Enterprise',
                img: {
                    desktop: CameraFunctions6D,
                    tablet: CameraFunctions6T,
                    mobile: CameraFunctions6M
                },
            },
            {
                size: '3',
                tag: 'Dashboard',
                title: 'Data Insights for Data-Driven Decision-Making',
                label: 'Administrator',
                img: {
                    desktop: CameraFunctions7D,
                    tablet: CameraFunctions7T,
                    mobile: CameraFunctions7M
                },
            },
        ]
    },
    
    FAQ: [
        {
            title: 'How many cameras can be installed on one server?',
            description: 'Using cameras for location tracking mainly involves utilizing computer vision technology to recognize objects and track their positions. To achieve this, the images captured by the cameras are analyzed in real-time to detect objects and subsequently track their movements.<br/><br/>Image analysis is a computationally demanding task. To maintain adequate performance for processing video streams from each camera and performing object tracking calculations, it is recommended to have a maximum of 8 cameras per server.<br/><br/>'
        },
        {
            title: 'Is NVR necessary to purchase?',
            description: 'NVR is not essential for using cameras for location tracking.<br/><br/>However, ORBRO OS is software optimized for analyzing images and tracking object positions, and it does not provide the unique features of NVRs. If you want to monitor CCTV videos in real-time and store and utilize them, we recommend using NVRs.<br/><br/>',
        },
        {
            title: 'Can the system operate with existing CCTV?',
            description: 'Yes, it is possible to operate the system using existing CCTV.<br/><br/>However, the performance of the existing CCTV must be similar to the specifications proposed by ORBRO OS. Typically, the CCTV systems that are already in place are intended for security purposes rather than location tracking. If you want to establish a more comprehensive location tracking infrastructure, it is recommended to install separate cameras.<br/><br/>',
        },
        {
            title: 'Is it possible to integrate with our own service data?',
            description: 'Yes, the data from location tracking using cameras is managed and stored using the ORBRO OS software. If desired, it is possible to call and integrate the data managed by ORBRO OS into your own system.<br/><br/>',
        }
    ],
    




}