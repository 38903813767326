import { StyledGridContainer } from "@components/Grid/Grid.style"
import React, { useEffect, useState } from "react"
import { FallTextBox, Falldown, FalldownBox, ProgressBar, ProgressBar2, RightImg, SerivcePage, ServiceLeftSide, ServiceRightSide } from "./Falldown.style"
import { StyledHeading, StyledParagraph } from "@components/Text"
import { Trans } from "react-i18next"
import { useSelector } from "react-redux"
import { CommonState } from "@redux/reducers/commonReducer"

import { BorderBottomLine, HeadingColor, Spacing } from "@pages/CommonStyle/common.style";
import access from "@src/lang/ko/main/access/access"
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style"




const FalldownPage = () => {

  const [index, setIndex] = useState<number>(0);
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  const handleText = (i: number) => {
    if (i === 3) {
      setIndex(0);
    } else if (i < 0) {
      setIndex(2)
    } else {
      setIndex(i);
    }
  }

  useEffect(() => {

    const intervalId = setInterval(() => {
      if (index === 3) {
        handleText(0);
      } else {
        handleText(index + 1);
      }
    }, 4000);

    // 컴포넌트가 언마운트되면 인터벌 클리어
    return () => clearInterval(intervalId);


  }, [index, handleText]);


  return (
    <>
      <StyledGridContainer>
        <SerivcePage>
          <ServiceLeftSide>
            <div>
              <DisplayH2 size="small">
                <Trans i18nKey={`accessMain.falldown.left.title`} />
              </DisplayH2>

              <Spacing pixel="16" />
              <StyledParagraph size="medium">
                <Trans i18nKey={`accessMain.falldown.left.desc`} />
              </StyledParagraph>

              <Spacing pixel="48" />

              <Falldown>
                {access.falldown.left.fall.map((data, i) => (
                  <>
                    {i === index ?
                      <FalldownBox>
                        <ProgressBar h={"56"}>
                          <ProgressBar2 />
                        </ProgressBar>
                        <FallTextBox onClick={() => handleText(i)}>
                          <StyledHeading size="xsmall"> <Trans i18nKey={`accessMain.falldown.left.fall.${i}.title`} /> </StyledHeading>
                          <StyledParagraph size="medium"> <Trans i18nKey={`accessMain.falldown.left.fall.${i}.desc`} /> </StyledParagraph>
                        </FallTextBox>
                      </FalldownBox>
                      :
                      <FalldownBox>
                        <ProgressBar h={"28"} />
                        <FallTextBox onClick={() => handleText(i)}>
                          <HeadingColor size="xsmall" color={"#BDBDBD"}> <Trans i18nKey={`accessMain.falldown.left.fall.${i}.title`} /> </HeadingColor>
                        </FallTextBox>
                      </FalldownBox>
                    }
                  </>
                ))}
              </Falldown>

            </div>
          </ServiceLeftSide>

          <ServiceRightSide>
            <RightImg src={access.falldown.left.fall[index].img[layoutType]} alt="orbrohome app display" />
          </ServiceRightSide>


        </SerivcePage>

        <BorderBottomLine />

      </StyledGridContainer>

    </>


  )

}


export default FalldownPage;