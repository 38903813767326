import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledHeading } from "@components/Text";
import { HeadingColor, LabelColor, ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import React, { useEffect, useState } from 'react';
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { BigCard, Card23Page, CardTextBox, DescBox, Icon, NormalCard, SmallCard } from "./Installation.style";
import { CommonState } from "@redux/reducers/commonReducer";
import { IconPhoneWhite } from "@assets/images/icon";
import { CardBox, CardGap } from "@pages/Main/Common/Functions/Functions.style";
import access from "@src/lang/ko/main/access/access";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";



const Installation = () => {

  
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  const [layoutMaxWidth, setLayoutMaxWidth] = useState<any>(null);

  useEffect(() => {
    const handleResize = () => {
      setLayoutMaxWidth(window.innerWidth);
    }

    setLayoutMaxWidth(document.documentElement.clientWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    <StyledGridContainer>
      <Card23Page>
        <DisplayH2 size="small">
          <Trans i18nKey={`accessMain.installation.title`} />
        </DisplayH2>

        <Spacing pixel="64"/>

        <CardBox>
          {access.installation.card.map((data, i) => (
            <>
              {i<2 && <>
                {i===0 ? 
                  <BigCard w={layoutMaxWidth} img={data.img[layoutType]}>
                    <CardTextBox>
                      <Spacing pixel="4"/>
                      <LabelColor color={"#818181"} size={"xsmall"}>
                        <Trans i18nKey={`accessMain.installation.card.${i}.tag`} />
                      </LabelColor>
                      <Spacing pixel="4"/>
                      <StyledHeading size="small">
                        <Trans i18nKey={`accessMain.installation.card.${i}.title`} />
                      </StyledHeading>
                      {data.desc &&
                      <DescBox>
                        <ParagraphColor color="#818181" size="small">
                          <Trans i18nKey={`accessMain.installation.card.${i}.desc`} />
                        </ParagraphColor>
                      </DescBox>}
                    </CardTextBox>
                  </BigCard>
                  :
                  <SmallCard w={layoutMaxWidth} img={data.img[layoutType]}>
                    <CardTextBox>
                      <Spacing pixel="4"/>
                      <LabelColor color={"#FFF"} size={"xsmall"}>
                        <Trans i18nKey={`accessMain.installation.card.${i}.tag`} />
                      </LabelColor>
                      <Spacing pixel="64"/>
                        <Icon src={IconPhoneWhite} alt="Phone Icon"/>
                      <Spacing pixel="16"/>
                      <HeadingColor color="#FFF" size="small">
                        <Trans i18nKey={`accessMain.installation.card.${i}.title`} />
                      </HeadingColor>
                      <Spacing pixel="16"/>
                      {data.desc &&
                      <div>
                        <ParagraphColor color="rgba(250,250,250,0.8)" size="small">
                          <Trans i18nKey={`accessMain.installation.card.${i}.desc`} />
                        </ParagraphColor>
                      </div>}
                    </CardTextBox>
                  </SmallCard>
                }
              </>}
            </>
          ))}
        </CardBox>

        <CardGap/>

        <CardBox>
          {access.installation.card.map((data, i) => (
            <>
              {i>1 && 
                <NormalCard w={layoutMaxWidth} img={data.img[layoutType]}>
                  <CardTextBox>
                      <LabelColor color={"#818181"} size={"xsmall"}>
                        <Trans i18nKey={`accessMain.installation.card.${i}.tag`} />
                      </LabelColor>
                      <Spacing pixel="4"/>
                      <StyledHeading size="small">
                        <Trans i18nKey={`accessMain.installation.card.${i}.title`} />
                      </StyledHeading>
                    </CardTextBox>
                </NormalCard>
              }
            </>
          ))}
        </CardBox>





      </Card23Page>
    </StyledGridContainer>

    </>
  )
}

export default Installation;