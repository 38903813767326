import { useLocation, useNavigate } from "react-router-dom";
import i18n from "@pages/i18n";
import SEO from "@pages/SEO";
import React, { useEffect, useMemo } from "react";
import TechnologyModule from "../TechnologyModule";

import whatisdigitaltwin from "@src/lang/ko/technology/whatisdigitaltwin";
import whatisgeofence from "@src/lang/ko/technology/whatisgeofence";

const pageData = {
  whatisdigitaltwin,
  whatisgeofence
}

const ModuleKo = ({ pageName }) => {

  const location = useLocation();
  const isEnglishPage = location.pathname.startsWith('/en/');
  const isJapanPage = location.pathname.includes('/jp/');
  const isChinaPage = location.pathname.includes('/cn/');
  const navigate = useNavigate();
  useEffect(() => {
    if (isEnglishPage) {
      i18n.changeLanguage("en").then(() => {
        navigate("", { replace: true });
      });
    } else if (isJapanPage) {
      i18n.changeLanguage("jp").then(() => {
        navigate("", { replace: true });

      });
    } else if (isChinaPage) {
      i18n.changeLanguage("cn").then(() => {
        navigate("", { replace: true });

      });
    } else {
      i18n.changeLanguage("ko").then(() => {
        navigate("", { replace: true });
      });
    }
  }, [location.pathname]);


  const page = pageName;
  const textList: {
    advantage: any;
    tag?: string; img?: string; videoDesc?: string; video?: string; link?: string; youtube?: string;
    head: string; subHead: string[]; desc: string; dot: string; linkText?: string;
  }[] = useMemo(
    () => pageData[page]?.text || [],
    [page]
  );


  const seo_title = useMemo(() => pageData[page]?.seo.title || '', [page]);
  const seo_desc = useMemo(() => pageData[page]?.seo.desc || '', [page]);
  const seo_url = useMemo(() => pageData[page]?.seo.url || '', [page]);
  const seo_key = useMemo(() => pageData[page]?.seo.key || '', [page]);
  const seo_img = useMemo(() => pageData[page]?.seo.img || '', [page]);

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Article",
    "publisher": {
      "@type": "Organization",
      "name": seo_title,
      "url": seo_url,
      "logo": {
        "@type": "ImageObject",
        "url": "https://ci6.googleusercontent.com/proxy/XBuoTCAgZz2qRpP9W6wKTcoui4eSr7Ewe3D3m-Yhc10c9_5yTOXJsX9UV79hvagikUmz8cQhPcKX5cMyZy_hvYC8Fao1TGOIRjKFEZNCpgt02YAg2S8GvNqadpL37XBn80qOJwAQvCELqYtUTBjrVOVHDGw4c0vvD4wB5Ul3aDzFYGwS4eRWBXaG5YSvTWIjtPbGI02j3v3OMHrAEXfcgfHFqMAAX4S5hWokE0NHvBlmTCjlGL-rYuo1wufdfVNpRq8b5-rfDfTtN8t3oMlwXu1S_8SFtrd1HbuDF_FJwE-WqwgjkiorNvcUbJCvV5-xeBH0EW0WaoCGQPO-g0VoGYaDQN-eAiDoYmx4a0F5fRR00IkXZCf9IvNjQ1harIXfZ7hPZU9TC1RUnVCDsuKPZlxPzAoHPQ=s0-d-e1-ft#https://kongtech.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fcf58ac78-4bac-4b20-852f-a21d311bf753%2FOrbro_Logo(RGB)_B.png?id=a0c2cd44-56a9-449f-8c52-4d0c69e3aaec&table=block&spaceId=2ff4ed52-1658-44a4-a5d8-af1695935a1d&width=2000&userId=&cache=v2"
      }
    },
    "author": {
      "@type": "Organization",
      "name": "오브로(ORBRO)",
      "image": {
        "@type": "ImageObject",
        "url": "https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238",
        "width": 167,
        "height": 167
      },
      "url": "https://orbro.io",
      "sameAs": [
        "https://kong-tech.com"
      ]
    },

    "headline": seo_title,
    "description": seo_desc,
    "keywords": seo_key,
    "url": seo_url,
    "image": {
      "@type": "ImageObject",
      "url": seo_img,
      "width": 1200,
      "height": 854
    },
    "mainEntityOfPage": seo_url
  }


  return (
    <>
      <SEO
        lang={"ko"}
        helmetTitle={seo_title}
        ogKey={seo_key}
        ogDesc={seo_desc}
        ogImg={seo_img}
        ogUrl={seo_url}
        jsonLd={jsonLd}
      />

      <TechnologyModule pageName={pageName} textList={textList} />

    </>
  )
}

export default ModuleKo; 