import { StyledParagraph } from "@components/Text";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";
import { ImgWH, ImgWidth100, LabelColor, ParagraphColor, Spacing, SpacingDTM } from "@pages/CommonStyle/common.style";
import os from "@src/lang/ko/main/os/os";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Card, CardLayout } from "../../Os.style";
import { DivAllCenter, DivFlexColumn } from "@pages/CommonStyle/commonDiv.style";
import { HeadingH3 } from "@components/Text/Heading/Heading.style";
import { Link } from "react-router-dom";
import { ShowMoreButton } from "@pages/CommonStyle/commonBtn";
import simple from "@src/lang/ko/simple";
import i18n from "@pages/i18n";


export const SpaceBar = () => <>&nbsp;</>;

const Section = ({ section, layoutType }) => {

  const [layoutMaxWidth, setLayoutMaxWidth] = useState<any>(null);

  useEffect(() => {

    const handleResize = () => {
      setLayoutMaxWidth(window.innerWidth);
    }
    setLayoutMaxWidth(document.documentElement.clientWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);


  return (
    <>
      <SpacingDTM d={"128"} t={"128"} m={"96"} />

      <DivAllCenter>

        <ImgWH src={os[section].img} w={""} h={layoutType === 'mobile' ? "14" : "18"} />
        <Spacing pixel="8" />
        <DisplayH2 size={"small"}>
          <Trans i18nKey={`os.${section}.title`} />
        </DisplayH2>
        <Spacing pixel="16" />

        <StyledParagraph size={"medium"}>
          <p>
            <Trans i18nKey={`os.${section}.desc`} components={{ br: layoutType === 'mobile' ? <SpaceBar /> : <br /> }} />
          </p>
        </StyledParagraph>

      </DivAllCenter>

      <SpacingDTM d={"64"} t={"64"} m={"48"} />

      <CardLayout>
        {os[section].box.map((data, i) => (
          <>
            <Card w={layoutMaxWidth} img={data.img[layoutType]} index={data.size}>
              <DivFlexColumn>
                <HeadingH3 size={layoutType === 'mobile' ? "xsmall" : "small"}>
                  <Trans i18nKey={`os.${section}.box.${i}.title`} />
                </HeadingH3>
                <Spacing pixel="4" />
                <ParagraphColor size={"small"} color={"#646464"}>
                  <p>
                    <Trans i18nKey={`os.${section}.box.${i}.desc`} />
                  </p>
                </ParagraphColor>

                {data.link &&
                  <>
                    <Spacing pixel="16" />
                    <ShowMoreButton href={i18n.language === 'ko' ? data.link : `/${i18n.language}${data.link}`} rel="noopener noreferrer">
                      <img src={simple.showmoreImg[i18n.language]} alt="learn more button" />
                    </ShowMoreButton>
                  </>
                }
              </DivFlexColumn>
            </Card>
          </>
        ))}
      </CardLayout>
    </>
  )
}


export default Section;