export default {
  subtitle: "도입 문의",
  title: "ORBRO 문의하기",
  description: "아래와 같이 오브로 서비스 또는 솔루션에 대한 궁금한 사항이 있으시면 언제든지 문의해 주세요.",
  checkList: [
    "서비스 이용요금이 어떻게 되나요?",
    "커스텀 서비스가 가능한가요?",
    "다른 서비스와 연동이 가능한가요?",
    "솔루션 도입을 원합니다.",
    "기술 지원을 받고 싶습니다.",
    "제품을 구매 하고 싶습니다."
  ],
  call: "빠른 답변이 필요하시다면 <strong>1522-9928</strong>로 연락 주세요.",
  callTime: "평일 (10:00 ~ 19:00)",
  form: {
    name: "이름",
    namePlaceholder: "홍길동",
    title: "직함",
    titlePlaceholder: "선택사항입니다.",
    companyName: "회사명",
    companyNamePlaceholder: "회사 이름을 입력해 주세요.",
    email: "업무 이메일",
    emailPlaceholder: "name@company.com",
    phoneNumber: "전화 번호",
    phoneNumberPlaceholder: "010-1234-5678",
    comment: "어떻게 도와 드릴까요?",
    commentPlaceholder: "견적 문의, 솔루션 문의, 비지니스 제안등 원하시는 내용을 입력해 주세요.",
    policy: {
      require: "[필수]",
      agree: "동의",
      policy1: "개인정보 처리방침"
    },
    submit: "도입 문의"
  }
}