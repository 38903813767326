import { TechUseCaseIcon3D, TechUseCaseIconBuilding } from "@assets/images/technology/common";
import { IconAnalytics, IconBattery, IconCar, IconChartInv, IconHeart, IconHome, IconLocation, IconRulerInv, IconSecurity, IconTagInv } from "@assets/images/icon";
import video from "@src/assets/images/solution/constructionSafety/video/rtlsVideo.mp4";

export default {

    seo: {
        title: 'ジオフェンス（Geofence）とは？ | オーブロ',
        desc: 'ジオフェンスは地理的な領域を仮想的に設定し、その領域内で発生するイベントを検知する技術を指します。これは、位置情報サービスとセンサー技術を組み合わせ、特定の地域でのイベントをリアルタイムで検知し、対応することを可能にします。',
        keywords: 'geofence, rtls, リアルタイムロケーションシステム, デジタルツイン, デジタルトランスフォーメーション, スマートビルディング, バーチャルリアリティ, ロケーショントラッキング, ビーコン, オーブロ',
        img: '',
        url: 'https://orbro.io/jp/enterprise/technology/what-is-geofence',
        type: 'website',
    },

    tag: 'What is Geofence?',
    title: 'ジオフェンス（Geofence）とは？',
    desc: '',

    text: [
        {
            head: 'ジオフェンス（Geofence）とは？',
            desc: 'ジオフェンスは地理的な領域を仮想的に設定し、その領域内で発生するイベントを検知する技術を指します。これは、位置情報サービスとセンサー技術を組み合わせ、特定の地域でのイベントをリアルタイムで検知し、対応することを可能にします。',
            subHead: '',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/jp/enterprise/construction/safety',
            head: 'ジオフェンス技術',
            desc: 'ジオフェンスは一般的にはGlobal Positioning System（GPS）をベースにしていますが、最近ではReal-Time Location System（RTLS）技術の活用が増えています。RTLSはGPSよりも正確な位置情報を提供し、室内でも優れた性能を発揮します。ビーコン、RFID、センサーネットワークなどを使用して、リアルタイムで位置を追跡することで、ジオフェンスを効果的に実装できます。',
            subHead: [
                {
                    title: 'RTLSとは？',
                    desc: 'リアルタイムロケーションシステム（RTLS）は、対象の位置をリアルタイムで追跡する技術であり、GPSとは異なり、室内環境でも正確な位置情報を提供します。ビーコン、RFID、センサーネットワークなどを使用して対象の位置をリアルタイムで検知し、記録するシステムです。RTLSは主に車両追跡、自動化、セキュリティなど、さまざまな産業分野で活用されています。'
                },
                {
                    title: 'RTLSの利点',
                    desc: 'RTLSの主な利点は高い精度であり、GPSとは異なり、室内や複雑な環境でも正確な位置追跡が可能です。高いリアルタイム検知能力により、対象の動きをリアルタイムでモニタリングでき、これにより迅速な対応と効率的な自動化が可能です。さらに、さまざまな技術を組み合わせて適用でき、さまざまな産業で利用できます。RTLSは特に産業現場での生産性向上やセキュリティの強化に寄与する効果的なソリューションとして評価されています。'
                },
                {
                    title: 'RTLSの適用',
                    desc: 'RTLS技術の導入プロセスは、さまざまなコンポーネントを統合して進行します。まず、対象を識別するために使用されるビーコン、RFIDセンサー、または他のロケーショントラッキング技術を選択し、配置します。その後、インストールされた技術はネットワークに接続され、リアルタイムの位置データを収集します。中央制御システムを設定してデータを処理し、効率的に活用できるようにします。最後に、ユーザーインターフェースを構成して位置情報をモニタリングし、必要な対策を講じることができるようにします。'
                },
            ],
            dot: ''
        },
        {
            tag: 'noBottomLine',
            head: 'ジオフェンスの適用分野',
            desc: 'ジオフェンスはさまざまな分野で活用され、主に以下のような適用分野があります:',
            subHead: [
                {
                    title: 'セキュリティシステム',
                    desc: '企業ビル、住居地、公共の場などさまざまな地域で、ジオフェンスは不正侵入を検知し、警告を提供することでセキュリティを強化します。危険な地域での出入り制御や事前の警告システムとして利用されることがあります。'
                },
                {
                    title: '車両追跡',
                    desc: '自動車や貨物運送業者では、ジオフェンスを使用して車両の動きをリアルタイムでモニタリングし、特定の地域から外れると警報を鳴らすように設定できます。'
                },
                {
                    title: 'スマートシティとIoT',
                    desc: 'スマートシティプロジェクトでは、公共の場での人の移動をモニタリングして都市インフラを最適化するために利用されます。または、インテリジェントな交通システムでの交通フロー分析と渋滞の検出に利用されます。'
                },
                {
                    title: 'レジャーとエンターテインメント',
                    desc: '観光地やテーマパークでは、訪問者の移動を追跡して向上したサービスを提供します。また、移動可能な境界を設定して特定のエリアでのアクティビティをサポートまたは制限することができます。'
                },
            ],
            dot: ''
        },
        {
            videoDesc: '(建設現場の安全管理、クリックすると移動します)',
            tag: 'noBottomLine',
            head: 'ジオフェンス技術展望',
            desc: 'ジオフェンス技術は継続的に進化し、将来的にはさらに拡大すると予測されます。技術の進歩により、精密な位置追跡とリアルタイム検知能力が向上し、セキュリティおよび安全分野での適用が強化されることが期待されます。<br/>また、屋内位置追跡技術の進化により、建物内部でも対象の位置を正確に把握できるようになり、車両追跡、産業自動化、スマートシティなど様々な分野での応用がさらに拡大すると予測されます。さらに、協力システムとの統合を通じて、様々なセンサーや技術と連携して効果的なジオフェンスソリューションを開発する取り組みが進むと予想されます。この技術の進歩は、セキュリティ、物流、スマートシティなど、様々な産業分野で革新的な変化をもたらすと予想されます。',
            subHead: [
            ],
            dot: ''
        },
        {
            tag: 'card',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
        {
            tag: 'advantage',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
    ],


    card: {
        title: 'ジオフェンスの利用事例',
        desc: 'ジオフェンス技術はさまざまな分野で効果的に活用されています。以下はいくつかの代表的なジオフェンスの利用事例です。',
        cards: [
            {
                icon: IconSecurity,
                title: 'セキュリティとアラートシステム',
                desc: [
                    {
                        title: '建物および施設保護',
                        desc: 'ジオフェンスは建物や施設の周りに仮想境界を設定し、不正侵入があった場合にリアルタイムでアラートをトリガーし、セキュリティを向上させます。'
                    },
                    {
                        title: '車両および輸送保護',
                        desc: 'ジオフェンスを車両や輸送車両に適用することで、特定のエリアから逸脱した場合にアラートを設定して盗難防止と安全な運用をサポートします。'
                    }
                ]
            },
            {
                icon: IconHome,
                title: 'ロジスティクスおよび倉庫管理',
                desc: [
                    {
                        title: 'リアルタイム貨物トラッキング',
                        desc: 'ジオフェンスを物流ネットワークに導入することで、貨物のリアルタイムな位置を監視し、効率的な倉庫管理に貢献します。'
                    },
                    {
                        title: '配送および輸送の最適化',
                        desc: 'ジオフェンスを使用して配送および輸送ルートを最適化することで、コスト削減を促進し、迅速なサービスを提供します。'
                    }
                ]
            },
            {
                icon: IconHeart,
                title: '医療機関のセキュリティ',
                desc: [
                    {
                        title: '患者の位置追跡',
                        desc: 'ジオフェンスを病院や医療機関に導入して患者の位置をリアルタイムで追跡することで、緊急事態に対する迅速な対応をサポートします。'
                    },
                    {
                        title: '医療機器の管理',
                        desc: '医療機器にジオフェンスを導入して機器の位置と状態をモニタリングすることで、正確かつ効率的な医療サービスを提供します。'
                    }
                ]
            },
            {
                icon: TechUseCaseIconBuilding,
                title: 'スマートシティの安全',
                desc: [
                    {
                        title: '公共スペースの監視',
                        desc: 'ジオフェンスを都市の重要な地点に適用することで、公共スペースのセキュリティを強化し、犯罪防止をサポートします。'
                    },
                    {
                        title: '交通フローの管理',
                        desc: '道路や交通ネットワークにジオフェンスを導入して交通フローを監視し、効率的な交通計画を立てます。'
                    }
                ]
            },
            {
                icon: IconLocation,
                title: '教育機関のセキュリティ',
                desc: [
                    {
                        title: '学校および大学キャンパスの保護',
                        desc: '学校や大学内にジオフェンスを構築することで、不正アクセスを防いで学生やスタッフの安全を確保します。'
                    },
                    {
                        title: '教育リソースの追跡',
                        desc: '教育機器やリソースにジオフェンスを適用することで、効率的なリソース管理と損失防止をサポートします。'
                    }
                ]
            },
            {
                icon: IconAnalytics,
                title: '環境モニタリング',
                desc: [
                    {
                        title: '自然環境のモニタリング',
                        desc: 'ジオフェンスを自然環境に活用して大気品質、水質、気候などをリアルタイムでモニタリングすることで、環境保全に寄与します。'
                    },
                    {
                        title: '産業環境管理',
                        desc: '産業施設内で環境パラメータをモニタリングし、正確なデータでエコフレンドリーな運用に貢献します。'
                    }
                ]
            }
        ]
    },
    advantage: {
        title: 'ジオフェンスの主な利点',
        cards: [
            {
                icon: IconRulerInv,
                title: 'リアルタイムの位置検出とアラート',
                desc: 'ジオフェンスはオブジェクトのリアルタイムな位置を検出し、特定のエリアへの進入や退出時に即座のアラートをトリガーし、迅速な対応と正確なアラートでセキュリティを向上させます。'
            },
            {
                icon: IconTagInv,
                title: '多様なアプリケーションのためのシミュレーション',
                desc: 'ジオフェンスはさまざまなシナリオをシミュレートし、事前にこれらのエリア内の状況を理解するための仮想境界の設定を可能にします。これにより、物流の最適化、交通フローの管理などで効果的に活用できます。'
            },
            {
                icon: IconChartInv,
                title: 'コスト削減と資産寿命の延長',
                desc: 'ジオフェンスを活用することで物理的なオブジェクトの位置と状態をリアルタイムでモニタリングし、予測分析を通じてメンテナンススケジュールを最適化します。これにより不必要なメンテナンスを防ぎ、故障を予測し、効率的な修理や交換を行い、総合的なメンテナンスコストを削減し、資産寿命を延ばします。'
            },
        ]
    }

}
