import { logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import digitaltwin from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

export default {


    helmet: '| 集装箱追踪',
    desc: '集装箱追踪解决方案利用RFID、GPS或RTLS等技术实时跟踪和监控集装箱的位置和状态。这有助于优化物流和海运行业中的集装箱运输和存储过程，提高效率。',
    keywords: '港口，港口，集装箱，集装箱追踪，货物追踪，港口管理，数字孪生，位置追踪，实时位置追踪，RTLS，Orbro，康泰克',

    ogSite: '集装箱追踪 | 数字孪生专业平台，Orbro',
    ogUrl: 'https://orbro.io/cn/blog/container-tracking',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkZ9sm3FiFSvojymITtsaWizbn8oKQcMwa4MM-G4G-nMKWBXq2F_q-sH0ACGnraTS707A9X3ogEPQhu0wxDNF0RP9I7pkAp7v0fdzFU2A7zFZtXef3IJ8c3TnYnagQc060ySKipQrcBuqB5tFq82ljVxI4ocqiownoure2nWdl7EzNUSFEQTe4NwflqjUms4AQTYBfwiwLp_4T-VPmoBsby9wKS0Lg20NkhGMM5-AKMQmD08MhEjPcjrWnYRjuhhHDJGB7yjVjJJFaC_8Hhzk-ZRhfMFoN0Werqf5YPXyy9n3DKLQuJv4nD4zYfYswWZj57am6eEuIWYdT6NU5VcdfGPuikrcaFNLVqonZVSVokqkKDjLIfFOLZ_jEHpWaiUpzJ4N2AwMlHjbypjZYkkcWPFuZRiOBOSnzAHvamvwRM9-ag0utSPD9dB9kqXM0gG9iIDVJJfeHxii387jBr7qtgEhEzpInbsnTxby1QQ4qGR91AptBAs-efDpG5dpw5aLNanaEtUnjmHLb2Kdx2BptiikLaz60I7qbKj7yt-eOYq030IZRzypf44xRyI-iAv0WxNE_El-NzX1Xl3T7aRWL_uiBNv-RML1K9gcbC5oEs2G9jGWd7uCAO4XzOtfu7tMNk8ENa9Tv6VxD9AqTdSu2HCUXxnqni5WajMTCT0r-C9pXr0DY7boiz-aBXmITz77v3-VtVXpMpHAiJnjaaQQWtsxCsp3JO643BiMaXQ69jdqcWU_tSVlUMBjVx3MwtdBkfpkH1UlOqrYL8lSfAryg4rOuYRlq0Wgd_HYQaWjg6ASY6GsYPQ_3jsk1c16AxmcmFKaYOvB95Una7UyNMZ2E1L0J2E9UDw5_NT0chrXIjUSCfCNoO3_z3t7PNeoNWyGhS5fkcxZ4iZEMJoSHPjO7_Ycj8fGf_TH8iAFx5kzutepCAa0OU4wVEZMJ7w26XLzJjNJY6Sv4e8ZtuB94rz3bII-npzvEPbPLe3-5_ZvinvmuEyUx8MBkei6-GyebkWDRO9A8GSt5cXN5ysTiKCLCgDz-2g_ZnlRafFzaYJM8zCrHpbL2doyBDLbLTr0XLnk_c4aKGCAMlWxt3na5UYPiexaCSHUgbRzHuZQDUNe6QzEVzuZ-ISeaiBOcDzj25ejL36EoeLeVduxzuvIs8XFa8rIO6sQOK0zSwNcAyeohI0waUzbyHJgEhNXe-vz0Ilc_AChIthH4taUvqT5x62hnV41jq-07G--rjyOeS2pG6PdQMkRqfteroupWK_rO_COOQCb_vscco04P8Jla0Lm7NfxsHyo6chs1rki0f_bjkv1NpW2QgqoX0-igIPd6ByzZOXoWVLlNXWkPkTOQKC3gdh8NRAep9vGbGAvQfRfyKFO5adEA0J-MyTdJPuq8gCH3oPCC3CLntoNARciwS31xLFRD-bF3x5iAb8rcK1VMSj4FKB6aTbMmF4J8ENEDRZ2axEdqIuW7mxt1PQl5aRzqvwPeG0rphIczuLwknwPZ-acGokkMnL7bY9ak1NZdEq4H0LeggE-5ryhwpPzWBQofP7HvD5zQx=w2010-h1214',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '集装箱追踪',
    date: '2024-01-09',
    tag1: '#集装箱追踪',
    tag2: '#货物追踪',
    tag3: '#物流追踪',
    tag4: '#智能物流',

    // <br/>
    text: [
        {
            head: '集装箱追踪',
            subHead: '',
            desc: '集装箱是安全运输世界各地商品的关键要素。然而，准确追踪集装箱的位置和状态仍然是一项具有挑战性的任务。特别是在当前不稳定的全球局势下，相关行业面临着不稳定性和不确定性的加剧。实时追踪集装箱的目的地和起点，提前检测移动过程中的问题，在物流行业中具有极其重要的意义。',
            dot: ''
        },
        {
            head: '集装箱追踪方法',
            subHead: '应用RTLS技术',
            desc: 'RTLS是Real-Time Location System的缩写，意味着实时位置追踪。RTLS技术在各种环境中准确追踪集装箱的位置表现出色。GPS在室外定位方面表现出色，但在室内环境或复杂城市区域中的准确度存在问题。RTLS克服了GPS的限制，通过适当结合传感器网络、RFID、超声波和无线通信技术，即使在建筑物内部也能准确追踪集装箱的位置。<br/><br/>此外，RTLS技术可以与现有的物流基础设施无缝集成。通过与现有的仓储自动化系统、运输管理软件和订单处理系统的连接，可以实时共享集装箱位置信息，并快速反映到自动化流程中。这有助于提高物流和运输管理的效率，节省人力和时间。',
            dot: ''
        },
        {
            video: digitaltwin,
            link: 'https://orbro.io/cn/enterprise/logistics/asset',
            videoDesc: '(Orbro物流中心数字孪生)',
            head: '加强集装箱安全',
            subHead: '预防盗窃和损坏',
            desc: '利用RTLS加强安全有助于实时了解集装箱的位置，从而预防盗窃和损坏。特别是通过准确了解运输中的集装箱位置，可以将盗窃风险降至最低。此外，如果在特定区域检测到意外移动，可以迅速响应，提高安全水平。',
            dot: ''
        },
        {
            head: '',
            subHead: '空闲时段安全',
            desc: '通过持续监测集装箱在空闲状态下的位置，可以对抗安全威胁。在空闲时期加强安全性可以在物流系统停用时仍然保持安全。这有助于加强集装箱的安全性，并提高整个物流网络的安全性。',
            dot: ''
        },
        {
            head: '实时集装箱追踪的优势',
            subHead: '优化物流流程',
            desc: '通过RTLS进行实时集装箱追踪在优化物流流程中发挥着重要作用。通过实时了解集装箱的位置，物流管理者可以提高每个阶段的效率。通过实时调整出发地、中途经停地和目的地的物流路线，可以缩短运输时间并最小化成本。',
            dot: ''
        },
        {
            head: '',
            subHead: '预测分析和异常处理',
            desc: '实时信息提供有助于基于预测分析和异常处理制定战略。通过分析数据，可以提前预测移动路径可能出现的问题，并找到优化物流网络的改进点。此外，实时检测并快速响应异常情况有助于最小化问题发生的时间。',
            dot: ''
        },
        {
            head: '有效的资源管理',
            subHead: '优化运输路线',
            desc: '通过RTLS实现有效的资源管理通过优化运输路线实现。利用集装箱的实时位置信息，可以识别在特定地区频繁使用的集装箱，并使用它们规划最佳的运输路线。这有助于减少燃料消耗并降低运输成本。',
            dot: ''
        },
        {
            head: '',
            subHead: '优化物流资产布局',
            desc: 'RTLS也用于优化仓库内的物流资产管理。利用传感器技术实时跟踪仓库内的物流资产，并将其有效地布置在需要特定商品或设备的区域。这有助于最大程度地利用物流空间并提高工作效率。',
            dot: ''
        },
        {
            head: '',
            subHead: '成本节约和资源优化',
            desc: '通过RTLS进行实时集装箱追踪有助于在运输和物流管理中有效节约成本并优化资源。通过基于准确的位置信息优化运输路线，有效布置物流资产，可以降低燃料成本并最小化闲置资源。',
            dot: ''
        },
        {
            head: '集装箱追踪的未来展望',
            subHead: '与人工智能和机器学习的整合',
            desc: '未来预计将更加强化与人工智能（AI）和机器学习的整合。RTLS技术产生大量数据，利用AI分析这些数据并建立预测模型。通过这样做，可以提前预测出问题并更精细地优化物流流程。',
            dot: ''
        },
        {
            head: '',
            subHead: '生态系统扩展',
            desc: '集装箱追踪预计将在当前物流行业以外的各种行业中得到应用，如制造、医疗、零售和能源行业等。这种扩展将更紧密地整合物流生态系统，并扩大协作的机会。<br/><br/>集装箱追踪技术通过持续创新预计将在整个行业产生积极影响，并在物流和供应链管理中提高效率和稳定性。',
            dot: ''
        },
        {
            head: '结论',
            subHead: '',
            desc: '基于实时信息的预测分析和异常处理为物流管理者提供了快速响应并持续改进物流流程的机会。此外，优化运输路线和物流资产布置有助于节省成本并提高物流网络的效率。<br/><br/>持续的技术创新和RTLS技术的广泛应用预计将引领未来物流行业，提高效率并增强竞争力。此外，随着RTLS在各个行业中的应用扩大，预计将出现更多创新和可持续的物流和供应链系统。',
            dot: ''
        },

    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: '通过附加UWB终端到工作人员或设备，以帮助实时追踪位置的产品。',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/cn/enterprise/logistics/asset'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/cn/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/cn/enterprise/manufacturing/asset'
            }
        ]

    }


}