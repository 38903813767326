export default {
  subtitle: "导入咨询",
  title: "关于ORBRO的咨询",
  description: "如果您对ORBRO服务或解决方案有任何疑问，请随时与我们联系。",
  checkList: [
  "服务使用费用是多少？",
  "是否支持定制服务？",
  "是否可以与其他服务进行集成？",
  "我想引入解决方案。",
  "我想获得技术支持。",
  "我想购买产品。"
  ],
  call: "如果您需要快速答复，请致电<strong>1522-9928</strong>。",
  callTime: "工作日（10:00 ~ 19:00）",
  form: {
  name: "姓名",
  namePlaceholder: "请输入您的姓名",
  title: "职位",
  titlePlaceholder: "选填",
  companyName: "公司名称",
  companyNamePlaceholder: "请输入公司名称",
  email: "工作邮箱",
  emailPlaceholder: "name@company.com",
  phoneNumber: "联系电话",
  phoneNumberPlaceholder: "010-1234-5678",
  comment: "我们如何帮助您？",
  commentPlaceholder: "请输入您想要咨询的内容，如报价咨询、解决方案咨询、业务提议等。",
  policy: {
  require: "[必填]",
  agree: "同意",
  policy1: "个人信息处理政策"
  },
  submit: "导入咨询"
  }
}