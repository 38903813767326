import { accessAutoThumb, deadboltThumb, emlockThumb } from '@assets/images/main/access/product';
import { monitoringOn, monitoringOff, controlOn, controlOff, displayOn, displayOff, cradleOn, cradleOff, idCardOff, idCardOn } from '@assets/images/main/index';

export default {


    banner : {
        title : '出入系统的创新',
        desc : '通过引入Orbro的出入系统，革新您的出入系统。',

        schemeTitle :'为您选择出入系统。',
        schemeDesc : '需要帮助做决定吗？ ',
        schemeDesc2 : '致电 ',
        phone : '1522-9928',
    },

    mainPage : {
        product : {
            title : '了解产品组成',
            desc :'了解创新出入系统的产品组成。',
            asset :{
                title : '移动出入',
                desc :'移动出入系统的起点',
            },
            worker :{
                title : '自动门出入',
                desc :'专为自动门定制的出入系统<br/>适用于所有出入口',

            }
        }

    },
    

    bannerData : [
    {
        icon:monitoringOn,
        iconInv:monitoringOff,
        image:'',
        iconText: '紧急铃',
        sub: '',
        link : '/cn/iot/bell'
    },
    {
        icon:controlOn,
        iconInv:controlOff,
        image:'',
        iconText: '电力控制',
        sub: '',
        link : '/cn/iot/energy'
    },
    {
        icon:displayOn,
        iconInv:displayOff,
        image:'',
        iconText: 'E-Ink<br/>显示屏',
        sub: '',
        link : '/cn/iot/display'
    },
    {
        icon:cradleOn,
        iconInv:cradleOff,
        image:'',
        iconText: '移动安全设备',
        sub: '',
        link : '/cn/iot/cradle'
    },
    {
        icon: idCardOn,
        iconInv: idCardOff,
        image: '',
        iconText: '员工证',
        sub: '',
        link: '/cn/iot/id'
    },
    
    
    ],





}
