import { IconBell, IconBinocularsGray, IconChainLink, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { IconAccess, IconAlarm, IconWarn, IconZoom } from "@assets/images/main";
import { rtlsSystem2 } from "@assets/images/solution";
import video from "@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4";
import subVideo1 from "@src/assets/images/solution/constructionEquipment/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/constructionEquipment/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/constructionEquipment/video/sub/rtlsVideo3.mp4";

export default {

    banner: {
        title: '重型设备管理',
        desc: '在建筑工地中，重型设备是核心要素。因此，适当的维护和事故预防是必不可少的。如果正确管理重型设备，将保持安全的同时节约成本，工地的生产力将显著提高。',

    },

    video: {
        title: '最智能的重型设备管理方法。',
        desc: '实时位置跟踪(RTLS)技术能够实时提供工作人员所需重型设备的位置信息。<br/>通过预防碰撞事故并通过最佳安排提高工作流程的效率和生产力。',
        play: video
    },

    func: [
        {
            title: '实时位置跟踪',
            desc: '实时跟踪重型设备的当前位置。因此，现场能够实现高效的布局，时间管理更加容易。',
            sub1: '高精度提供',
            icon1: IconRulerGray,
            sub2: '快速数据共享',
            icon2: IconShareGray,
            video: subVideo1
        },
        {
            title: '接近警告系统',
            desc: '在重型设备行驶中，由于人为失误或系统缺陷可能会发生事故，这将造成严重后果。特别是与人的接触事故非常危险。通过实时监控并在发生事故时立即采取措施。',
            sub1: '指定危险资产',
            icon1: IconWarningGray,
            sub2: '设定接近距离标准',
            icon2: IconRulerGray,
            video: subVideo2
        },
        {
            title: '盲区危险检测',
            desc: '重型设备操作员的视线可能会产生盲区。高疲劳操作会产生人们容易忽视的环境。因此，掌握盲区，避免与人碰撞并维持现场的安全。',
            sub1: '指定自由范围',
            icon1: IconZoneGray,
            sub2: '设置多个区域',
            icon2: IconTagGray,
            video: subVideo3
        },
    ],

    subfunc: {

        title: '多种功能',
        desc: '重型设备在安全和成本方面都非常重要。违反安全规定的重型设备使用会引发事故，而效率低下的工作方式会浪费成本和人力资源。为了创建安全和经济效益的现场，Orbro提供以下功能：',

        data: [
            {
                icon: IconChartRadius,
                title: '报告和分析',
                desc: '基于现场数据生成报告和分析资料。',
            },
            {
                icon: IconBell,
                title: '实时警报功能',
                desc: '当发生异常情况时，向重型设备发送警报，以便立即采取措施。',
            },
            {
                icon: IconRoute,
                title: '历史轨迹查询',
                desc: '查询重型设备在现场的移动轨迹并对数据进行化。',
            },
            {
                icon: IconDashboard,
                title: '状态监控',
                desc: '实时查看特定重型设备的移动情况。',
            },
            {
                icon: IconWarning,
                title: '逃逸检测',
                desc: '监测重型设备在现场逃逸或移动到错误位置的情况。',
            },
            {
                icon: IconSearch,
                title: '轨迹模式分析',
                desc: '分析重型设备在现场的使用情况及时间。',
            },
            {
                icon: IconChainLink,
                title: '数据链接',
                desc: '与RFID，IOT，安全，视频等系统连接，以有效利用。',
            },
            {
                icon: IconSecurity,
                title: '数据安全',
                desc: '保持高水平的数据安全，提供可靠的解决方案。',
            },
        ],
    },

    system: {
        title: '系统配置',
        img: rtlsSystem2
    },

    productTitle: '产品配置',
    product: [
        {
            tag: 'carPackage'
        },
        {
            tag: 'workerPackage'
        },
    ],

    contents: [
        {
            data: 'constructionAsset'
        },
        {
            data: 'constructionSafety'
        },
        {
            data: 'blogOffice'
        },
        {
            data: 'blogMedical'
        },
    ]
}
