import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";
import { GRAY_500, GRAY_50} from "@assets/styled/color.style";


export const StyledSVLabelTop = styled(StyledParagraph)`
    margin-bottom : 24px;
    color : ${GRAY_500};
    display: flex;
    justify-content: center;

    grid-column: 1 / -1; /* spans across both columns */
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: center;
    

`;  


export const StyledSVLabel = styled(StyledParagraph)`
    margin-top: 16px;
    color : ${GRAY_500};
    display: flex;
    justify-content: center;
    margin-bottom : 16px;

`;  


export const StyledSVLabelMiddle     = styled(StyledParagraph)`
    grid-column: 1 / -1; /* spans across both columns */
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: center;
    margin-top: 16px;
`;  



export const StyledSVImgBox = styled.div`
    margin-bottom : 24px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    grid-template-columns: none;
  }  

`;


export const StyledSVImgBoxThree = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 32px;
    width: 100%;
    

    margin-bottom : 24px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    grid-template-columns: none;
    grid-row-gap: 24px;
  }  
`;



export const StyledSVImgBoxTwo = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 32px;
    width: 100%;

    margin-bottom : 24px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    grid-template-columns: none;
    grid-row-gap: 32px;
  }  

`;



export const StyledSVImg = styled.img`
  width: 100%;

  border-radius : 20px;


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    width: 100%;
  }  

`;



export const StyledSVImgGray = styled.img`
  width: 100%;

  border-radius : 20px;

  background-color : ${GRAY_50};

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    width: 100%;
  }  

`;




export const StyledTechnologyGroupNoBar = styled.div`
  padding: 64px 0;

  &:first-of-type {
    padding-top: 0;
  }
`; 




export const StyledSpacing24 = styled.div`
    height: 24px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    opacity: 0.2;


`;

export const StyledSpacing16 = styled.div`
    height: 16px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    opacity: 0.2;


`;



