import { ptzThumb } from "@assets/images/main/camera";

export default {

    mobile: {
        title: 'モバイルアクセスシステム | オーブロ製品',
        key: '自動アクセス、玄関ドア、アパートのアクセス、アクセスシステム、スマートアクセス、モバイルアクセス、RTLS、非接触アクセス、ビーコン、Bluetooth、ConTech、Orbro',
        desc: 'Orbroのモバイルアクセスシステムは、電力の設置が必要なく、モバイルデバイスを使用して玄関ドアを制御できます。高速で便利で費用対効果の高い次世代のアクセスシステムです。',
        img: '',
        url: 'https://orbro..io/jp/access/mobile',
    },
    auto: {
        title: '自動ドアアクセスシステム | オーブロ製品',
        key: '自動ドア、自動アクセス、玄関ドア、アパートのアクセス、アクセスシステム、スマートアクセス、モバイルアクセス、RTLS、ビーコン、Bluetooth、非接触アクセス、ConTech、Orbro',
        desc: 'オーブロの自動ドアアクセスシステムは、自動ドアアクセス向けに設計され、モバイルアプリのインストールを通じて非接触の入場を可能にします。スリムでスマートなデザインの製品で、利便性を提供します。',
        img: '',
        url: 'https://orbro..io/jp/access/auto',
    },
    face: {
        title: '顔認識システム | オーブロ製品',
        key: '顔認識、顔認証、顔認識、自動アクセス、玄関ドア、アパートのアクセス、アクセスシステム、スマートアクセス、ビーコン、Bluetooth、スマートアクセスシステム、モバイルアクセス、RTLS、非接触アクセス、ConTech、Orbro',
        desc: 'Access Proは、建物の共同玄関の出入りを制御し、ユーザーを呼び出す機能を備えた製品です。この製品を使用すると、モバイルアプリを介したリアルタイムのビデオ通話が可能で、玄関ドアを遠隔操作できます。NFCカードとパスワードのロック解除が可能で、従来の共同玄関制御システムを代替できる高品質なソリューションを提供します。',
        img: '',
        url: 'https://orbro..io/jp/access/face',
    },
    accessory: {
        title: 'アクセサリー | オーブロ製品',
        key: 'ビーコン、Bluetooth、顔認識、顔認証、顔認識、自動アクセス、玄関ドア、アパートのアクセス、アクセスシステム、スマートアクセス、スマートアクセスシステム、モバイルアクセス、RTLS、非接触アクセス、ConTech、Orbro',
        desc: 'オーブロは効率的で経済的なスマートアクセス製品を提供します。さまざまなアクセサリーを使用してカスタマイズされたアクセスシステムを構築できます。',
        img: '',
        url: 'https://orbro..io/jp/access/accessory',
    },
    ble: {
        title: 'Bluetooth（BLE）位置追跡 | オーブロ製品',
        key: '位置追跡、リアルタイム位置追跡、RTLS、Bluetooth、ビーコン、BLE、UWB、資産管理、資産追跡、在庫管理、在庫追跡、人の追跡、屋内位置追跡、屋内GPS、GPS、Orbro、ConTech',
        desc: 'オーブロのBLEベースの位置追跡製品は、BLEタグデバイスを取り付けて資産や対象物の位置を追跡できます。システムは安定して効率的な技術に基づいており、正確なデータを提供します。',
        img: '',
        url: 'https://orbro..io/jp/rtls/ble',
    },
    uwb: {
        title: '高精度（UWB）位置追跡 | オーブロ製品',
        key: '位置追跡、リアルタイム位置追跡、RTLS、Bluetooth、ビーコン、BLE、UWB、資産管理、資産追跡、在庫管理、在庫追跡、人の追跡、屋内位置追跡、屋内GPS、GPS、Orbro、ConTech',
        desc: 'オーブロのUWBベースの高精度位置追跡技術により、資産や人の位置を高い精度と安定性でリアルタイムに追跡できます。安定性、効率性、経済性の面で優れた性能を提供します。',
        img: '',
        url: 'https://orbro..io/jp/rtls/uwb',
    },
    outdoor: {
        title: '屋外カメラ | オーブロCamera製品',
        key: 'カメラ位置追跡、PTZ、リアルタイム位置追跡、車両位置追跡、スマート駐車、RTLS、Bluetooth、ビーコン、BLE、UWB、資産管理、資産追跡、在庫管理、在庫追跡、人の追跡、屋内位置追跡、屋内GPS、GPS、Orbro、ConTech',
        desc: 'カメラを使用して、人や車両などさまざまなオブジェクトの位置と行動を検出および管理できます。車両の位置や人の動きに関するリアルタイムかつ正確なデータを提供します。',
        img: '',
        url: 'https://orbro..io/jp/camera/outdoor',
    },
    indoor: {
        title: '室内カメラ | オーブロCamera製品',
        key: 'カメラ位置追跡、IP、リアルタイム位置追跡、車両位置追跡、スマート駐車、RTLS、Bluetooth、ビーコン、BLE、UWB、資産管理、資産追跡、在庫管理、在庫追跡、人の追跡、屋内位置追跡、屋内GPS、GPS、Orbro、ConTech',
        desc: '高精度な位置追跡技術に基づいて、室内での人の移動や在庫の位置を追跡し、正確なデータを提供します。',
        img: '',
        url: 'https://orbro..io/jp/camera/indoor',
    },
    bell: {
        title: '緊急ベル | オーブロIoT製品',
        key: 'IoT、モノのインターネット、セーフティベル、緊急通報ボタン、緊急通報、通報ボタン、警察通報、緊急事態、セーフティベル、119、112、女性の安全、ビーコン、Bluetooth、ストーカー対策、ストーカー通報、ConTech、Orbro',
        desc: '緊急の状況での緊急通報を可能にするセーフティベルです。どこでも取り付け可能で、迅速な緊急通報のための最も効率的なソリューションと言えます。',
        img: '',
        url: 'https://orbro..io/jp/iot/bell',
    },
    energy: {
        title: '電力制御 | オーブロIoT製品',
        key: '電力制御、スマート電力、スマートスイッチ、スマートボタン、デジタルスイッチ、スイッチ、Bluetooth、リモートコントロールスイッチ、スマート電球、ConTech、Orbro',
        desc: 'スマートスイッチはアナログスイッチをデジタルスイッチに簡単に交換できる製品で、無駄な電力の削減と不要な時間の浪費を最小限に抑えます。',
        img: '',
        url: 'https://orbro..io/jp/iot/energy',
    },
    display: {
        title: 'ディスプレイ | オーブロ製品',
        key: '電力制御、スマート電力、スマートスイッチ、スマートボタン、デジタルスイッチ、スイッチ、Bluetooth、リモートコントロールスイッチ、スマート電球、ConTech、Orbro',
        desc: 'ESLはビーコン技術をベースにしたディスプレイデバイスです。E-inkディスプレイを使用しているため、低電力でディスプレイを利用できます。',
        img: '',
        url: 'https://orbro..io/jp/iot/display',
    },
    cradle: {
        title: 'ワイヤレスクレードル | オーブロ製品',
        key: 'IoT、モノのインターネット、女性の安全、ConTech、Orbro',
        desc: 'ケーブルロックの不便さなしにスマートフォンをワイヤレスで管理できます。',
        img: '',
        url: 'https://orbro..io/jp/iot/cradle',
    },
    idCard: {
        title: '社員証 | オブロ製品',
        key: '社員証, スマート社員証, IoT, インターネットオブシングス, 女性の安全, コンタクトレス技術, オブロ, orbro',
        desc: 'スマート社員証はネックレス形式で製作され、産業およびサービス領域の両方で高い互換性を持って使用することができます。オブロの他のサービスとの連携も可能です。',
        img: '',
        url: 'https://orbro.io/jp/iot/id',
    },
    loraGateway: {
        title: 'LoRa ゲートウェイ | ORBRO 製品',
        key: 'LoRa ゲートウェイ、LPWAN ゲートウェイ デバイス、長距離無線機器、IoT 通信ソリューション、ワイヤレスセンサーネットワークデバイス、LoRaWAN ゲートウェイデバイス、省電力 IoT 通信、スマートシティ IoT ソリューション、ワイヤレス IoT デバイス販売、ワイヤレスセンサーネットワークソリューション、IoT デバイス購入、ワイヤレス通信システム、ワイヤレス LoRa ゲートウェイ、ワイヤレス IoT デバイスメーカー、LoRa ゲートウェイ価格、LPWAN IoT デバイス、IoT 通信ソリューションプロバイダー、センサーネットワークシステム、スマートシティ通信装置、省電力ワイヤレスネットワーク、ORBRO、Kongtech、orbro、rtls、リアルタイム位置追跡',
        desc: 'LoRa ゲートウェイ製品は長距離通信技術を基にし、IoT（インターネット・オブ・シングス）の分野でのワイヤレス通信において重要な役割を果たします。これらのゲートウェイは省電力なソリューションを提供し、スマートシティ、農業、環境モニタリング、産業自動化などさまざまな分野で使用されています。私たちの LoRa ゲートウェイは安定した効率的なデータ転送を通じて顧客の要求を満たし、IoT プロジェクトの成功をサポートしています。',
        img: '',
        url: 'https://orbro.io/jp/sensor/LoRaGateway'
    },
    loraSensor: {
        title: 'LoRa センサー | ORBRO 製品',
        key: 'LoRa センサー、LPWAN IoT センサー、長距離ワイヤレスセンサー、IoT センサーネットワーク、ワイヤレス環境検出センサー、省電力 IoT デバイス、スマートシティセンサーソリューション、ワイヤレス環境モニタリング、IoT センサーデバイス、ワイヤレス環境モニタリング機器、環境センサーネットワーク、ワイヤレス LoRa センサーテクノロジー、環境モニタリングシステム、IoT 環境センサー販売、LoRa センサーネットワーク、LPWAN IoT テクノロジー、スマートシティ環境モニタリング、ワイヤレス環境センサーメーカー、LoRa センサー価格、LPWAN IoT 環境センサー、IoT センサーソリューションプロバイダー、ORBRO、Kongtech、orbro、rtls、リアルタイム位置追跡',
        desc: 'LoRa センサーは省電力ワイヤレス技術を使用して環境モニタリングとリアルタイムデータ収集に最適なソリューションです。私たちの LoRa センサー製品は、空気品質、温度、湿度、ガス濃度などさまざまなパラメータを素早くモニタリングし、スマートシティ、農業、産業自動化、環境モニタリングなどの分野で必要不可欠です。これらのセンサーは信頼性と精度を備え、データ収集の効率を向上させます。',
        img: '',
        url: 'https://orbro.io/jp/sensor/LoRaSensor'
    },
    airQuality: {
        title: '空気品質モニター | ORBRO 製品',
        key: '産業用空気品質モニター、大気品質測定装置、工場用大気汚染モニター、空気品質モニタリング装置、環境モニタリングソリューション、空気品質分析装置、産業用大気汚染センサー、空気品質モニタリング機器、産業用大気品質測定ソリューション、大気品質モニタリングシステム、産業環境モニタリング装置、大気品質センサーネットワーク、環境モニタリングソリューションプロバイダー、産業用空気品質センサー販売、空気品質モニタリングデバイス、産業環境センサーテクノロジー、空気品質測定機器価格、工場用環境モニタリングシステム、産業用空気品質センサーメーカー、大気品質モニタリングソリューションプロバイダー、ORBRO、Kongtech、orbro、rtls、リアルタイム位置追跡',
        desc: '空気品質モニターは屋内および屋外の環境での空気品質を正確にモニタリングし改善するために重要なツールです。これらのモニターは微細粒子、CO2、温度、湿度など、さまざまな大気品質指標をリアルタイムで測定し、スマートシティ、建物管理、農業、産業などで使用されています。安全で健康的な環境を維持するために必要なツールとして、大気中の一酸化炭素濃度をモニタリングおよび制御します。',
        img: '',
        url: 'https://orbro.io/jp/sensor/air'
    },
    coMeasure: {
        title: '一酸化炭素検出器 | ORBRO 製品',
        key: '一酸化炭素検出器、CO ガスセンサー、一酸化炭素濃度測定装置、CO モニター販売、環境 CO モニタリング装置、一酸化炭素検出デバイス、CO センサー価格、ガス濃度測定装置、一酸化炭素センサー、CO 濃度モニタリングシステム、一酸化炭素測定ソリューション、CO モニタリング装置プロバイダー、環境ガスセンサーテクノロジー、CO ガスセンサーメーカー、産業用 CO モニタリング、一酸化炭素濃度測定、CO 測定デバイスインストール、一酸化炭素検出デバイスソリューション、環境 CO モニタリングシステム価格、CO モニターレベル調整、ORBRO、Kongtech、orbro、rtls、リアルタイム位置追跡',
        desc: '一酸化炭素検出器は環境と安全のための重要なツールとして、室内および屋外の環境で CO（一酸化炭素）濃度を迅速に検出およびモニタリングします。これらの検出器は生活環境、工場、ガレージなどさまざまな場所で使用され、有毒ガスの漏れを検出し、安全な環境を提供します。安全性と健康のために必要な CO 検出器を使用して、大気中の一酸化炭素濃度をモニタリングおよび制御してください。',
        img: '',
        url: 'https://orbro.io/jp/sensor/co'
    },
    beaconIndustry: {
        title: '産業用ビーコン | ORBRO beacon 製品',
        key: '産業用ビーコン、ビーコントラッキングシステム、ビーコン位置追跡、産業用ビーコンソリューション、ワイヤレスビーコンテクノロジー、ビーコン位置サービス、ビーコン通信機器、ビーコントラッカー販売、スマートファクトリービーコン、産業用ビーコン価格、ビーコン屋内位置サービス、ワイヤレス位置追跡システム、ビーコン位置ベースサービス、ビーコンリアルタイム位置追跡、産業用ビーコンメーカー、ビーコン屋内ナビゲーション、ビーコン IoT デバイス、ワイヤレスビーコン位置システム、ビーコンネットワークソリューション、産業用ビーコンテクノロジープラットフォーム',
        desc: '当社の産業用ビーコンは、スマートファクトリーおよび産業自動化分野で革新的な位置追跡およびワイヤレス IoT ソリューションを提供します。これらのビーコンは高度な Bluetooth テクノロジーを活用して、物流、資産管理、生産プロセスの最適化など、さまざまなアプリケーションで使用されています。信頼性と正確性を基にした産業用ビーコンを使用して、生産効率を向上させ、スムーズな工場運営をサポートします。革新的な IoT ソリューションを構築するための必須の機器として、リアルタイム位置追跡およびデータ収集を実現してください。',
        img: '',
        url: 'https://orbro.io/jp/beacon/industry'
    },
    beaconTag: {
        title: '小型ビーコン | ORBRO beacon 製品',
        key: 'ビーコン, 小型ビーコン、ビーコンタグトラッキング、ビーコンタグシステム、室内位置追跡テクノロジー、Bluetooth タグビーコン、リアルタイム位置ベースサービス、ビーコンタグデバイス、位置タグトラッカー販売、Bluetooth ビーコンタグ、タグビーコン価格、Bluetooth 室内位置サービス、位置ベースタグシステム、ビーコンタグリアルタイムトラッキング、タグビーコン IoT ソリューション、室内位置追跡メーカー、Bluetooth タグ IoT デバイス、タグビーコンワイヤレス位置システム、位置ベースタグネットワークソリューション、タグビーコンテクノロジープラットフォーム、ビーコンタグアプリ開発',
        desc: '小型ビーコンは Bluetooth をベースにした位置追跡テクノロジーで、屋内および屋外で物体やオブジェクトの位置を正確に識別およびモニタリングするために使用されます。私たちのタグビーコン製品は物流、資産管理、屋内ナビゲーション、スマートスペースおよびスマートシティプロジェクトで中心的な役割を果たしています。信頼性と精度を強調したタグビーコンを使用して、リアルタイム位置ベースサービスを実現し、データ収集の効率性を向上させてください。',
        img: '',
        url: 'https://orbro.io/jp/beacon/tag'
    },
    assetTag: {
        title: '資産用タグ | オブロビーコン製品',
        key: '資産管理, ビーコン, 小型ビーコン, ビーコントラッキングタグ, ビーコンタグシステム, 屋内位置情報技術, ブルートゥースタグビーコン, リアルタイム位置情報サービス, ビーコンタグデバイス, 位置情報タグトラッカー販売, ブルートゥースビーコンタグ, ブルートゥース屋内位置情報サービス, 位置情報ベースタグシステム, ビーコンタグリアルタイム追跡, タグビーコンIoTソリューション, 屋内位置追跡メーカー, ブルートゥースタグIoTデバイス, タグビーコンワイヤレス位置情報システム, 位置情報ベースタグネットワークソリューション, タグビーコン技術プラットフォーム, ビーコンタグアプリ開発',
        desc: '資産用タグは資産管理に最適化された超小型ブルートゥースタグ端末です。最新バージョンのブルートゥースと最大1年のバッテリー寿命を搭載しています。',
        img: '',
        url: 'https://orbro.io/jp/beacon/asset-tag'
    },
    basicRobot: {
        title: 'ロボットシステム | オブロビーコン製品',
        key: 'ロボットシステム, アセット管理, ビーコン, 小型ビーコン, ビーコンタグトラッキング, ビーコンタグシステム, 屋内位置追跡技術, Bluetoothタグビーコン, リアルタイム位置ベースサービス, ビーコンタグデバイス, 位置タグトラッカー販売, Bluetoothビーコンタグ, タグビーコン価格, Bluetooth屋内位置サービス, 位置ベースタグシステム, ビーコンタグリアルタイム追跡, タグビーコンIoTソリューション, 屋内位置追跡製造者, BluetoothタグIoTデバイス, タグビーコン無線位置システム, 位置ベースタグネットワークソリューション, タグビーコン技術プラットフォーム, ビーコンタグアプリ開発.',
        desc: 'リアルタイム位置追跡システムとロボット配送システムを組み合わせて、物品を配送したり作業を行うのに最適なロボットサービスを提供します。',
        img: '',
        url: 'https://orbro.io/jp/integration/robot'
    },

}
