export default {

    title : '오브로와 함께 세계 No.1이 될 대리점을 모집합니다.',
    form : {
        title : '제출 서류를 확인해 주세요',
        docu : [
            {
                title : '제출 서류',
                item : [
                    {
                        text : '[필수] 회사 소개서 (사업영역, 인력현황 등)',
                    },
                    {
                        text : '[필수] 신용평가서',
                    },
                    {
                        text : '[필수] 판매계획 (자유 양식)',
                    },
                ]
            },
            {
                title : '모집 기한',
                item : [
                    {
                        text : '상시 모집',
                    },
                ]
            },
            {
                title : '제출 시, 유의사항',
                item : [
                    {
                        text : '제출 파일명은 아래와 같이 기입하여 메일로 송부 바랍니다.',
                    },
                    {
                        text : 'E-mail : pr@orbro.io',
                        type : '-'
                    },
                    {
                        text : '파일 형식 : [지원분야]회사이름_수신자성함',
                        type : '-'
                    },
                ]
            },
            {
                title : '혜택',
                item : [
                    {
                        text : '엔지니어 기술지원 및 교육',
                    },
                    {
                        text : '영업툴 제공 (홍보 자료 등)',
                    },
                ]
            },
        ]
    },


    requestForm : {
        title : '신청자 정보',
        input : [
            {
                label : '이름 *',
                place_holder : '홍길동',
                tag : 'name',
                type : '0'
            },
            {
                label : '연락처 *',
                place_holder : '-없이 숫자만',
                tag : 'phone',
                type : '0'
            },
            {
                label : '회사명 *',
                place_holder : '회사명 또는 업체명',
                tag : 'company',
                type : '0'
            },
            {
                label : '사업자등록번호 *',
                place_holder : '-없이 숫자만',
                tag : 'registNumber',
                type : '0'
            },
            {
                label : '영업 희망 지역 *',
                place_holder : '시/도 선택',
                tag : 'region',
                type : '1'
            },
            {
                label : '지원 분야 *',
                place_holder : '필수 분야',
                tag : 'category',
                type : '1'
            },
        ],

        region : [
            {text : '서울'},
            {text : '인천'},
            {text : '경기'},
            {text : '부산'},
            {text : '전라'},
            {text : '대구'},
            {text : '대전'},
            {text : '광주'},
            {text : '충청'},
            {text : '경상'},
            {text : '제주'},
        ],
        category : [
            {text : '출입 시스템'},
            {text : '비콘 위치추적'},
            {text : '실시간 위치추적'},
            {text : '영상 위치추적'},
            {text : '산업용 센서'},
            {text : '그 외'},
        ],
    },


    file : {
        title : '회사 소개',
        btn : '파일 변경'
    },
    submit : '대리점 문의하기'

}