import { accessAutoThumb, deadboltThumb, emlockThumb } from '@assets/images/main/access/product';
import { IconOsOff, IconOsOn } from '@assets/images/main/icon';
import {
    accessKey1, accessKey2, accessKey3, accessKey4,
    accessOutlook,
    IconBox, IconComputer, IconGuarantee,
    assetOn, workerOn, workerOff, IconPath, IconLocation, IconAnalyze, IconAlarm, IconExcel, IconSDK, assetOff
} from '@assets/images/main/index';
import { RtlsFunctions1D, RtlsFunctions1M, RtlsFunctions1T, RtlsFunctions2D, RtlsFunctions2M, RtlsFunctions2T, RtlsFunctions3D, RtlsFunctions3M, RtlsFunctions3T, RtlsFunctions4D, RtlsFunctions4M, RtlsFunctions4T, RtlsFunctions5D, RtlsFunctions5M, RtlsFunctions5T } from '@assets/images/main/rtls/main/functions';
import { RtlsHardware1D, RtlsHardware1M, RtlsHardware1T, RtlsHardware2D, RtlsHardware2M, RtlsHardware2T, RtlsHardware3D, RtlsHardware3M, RtlsHardware3T, RtlsHardware4D, RtlsHardware4M, RtlsHardware4T } from '@assets/images/main/rtls/main/hardware';
import { assetTag, twinTracker1, twinTrackerBracket } from '@assets/images/main/rtls/product';


export default {


    banner: {
        title: '실시간으로 정확한 위치 정보 수집',
        desc: '위치 추적 시스템 도입을 통해 생산성, 안전관리를 시작하세요.',

        schemeTitle: '당신에게 맞는 위치추적을 선택하세요.',
        schemeDesc: '결정하는데 도움이 필요하신가요? ',
        schemeDesc2: '로 연락 주시면<br/>출입 전문가가 도와드리도록 하겠습니다.',
        phone: '1522-9928',

        card: [
            {
                title: '국내 유일',
                desc: '위치 추적 적용<br/>네트워크 단말기 제조',
            },
            {
                title: '500억 건 +',
                desc: '아시아 최대 규모의<br/>실시간 위치 정보 데이터',
            },
            {
                title: '세계 최고 성능',
                desc: 'AI 알고리즘을 이용한<br/>세게 최고 수준의 위치 정확도',
            },
        ]
    },

    mainPage: {
        product: {
            title: '제품 구성 알아보기',
            desc: '혁신적인 위치추적 제품 구성을 확인 해 보세요.',
            asset: {
                title: '블루투스(BLE) 위치추적',
                desc: 'BLE 태그 단말기를 자산 또는 추적하고자 하는 대상에 부착하여<br/>실시간 위치 추적 가능',
            },
            worker: {
                title: '고정밀(UWB) 위치추적',
                desc: 'UWB 태그 단말기를 자산 또는 추적하고자 하는 대상에 부착하여<br/>실시간 위치 및 동선 추적 가능',

            }
        }

    },

    video: {
        title: 'ORBRO 위치추적 시스템',
        desc: [
            { text: '대시보드 기능을 통하여 기기의 상태, 태그 단말기의 변동 추이 카테고리별 분류 현황, 구역별 현황들을<br/>한눈에 빠르게 확인할 수 있습니다.' },
            { text: '디바이스를 기준으로 대상의 위치와 현황을 빠르게 파악할 수 있습니다.<br/>정확한 데이터와 쉬운 인터페이스를 제공합니다.' },
            { text: '작업 공간부터 위험 구역까지 빠르게 설정할 수 있고<br/> 구역별 현황을 빠르게 파악할 수 있습니다.' },
            { text: '위험 구역에 진입하는 경우를 비롯하여,<br/>다양한 상황에 알맞은 알람을 빠르게 보낼 수 있습니다.' },
        ]

    },


    functions: {

        title: '위치추적의 시스템의<br/>혁신적인 경험',
        desc: '오브로의 위치 추적 시스템은 관리자 웹뿐만 아니라 모바일 앱까지 제공하여 사용자에게 최상의 경험을 제공하는 서비스입니다.<br/>이를 통해 현존하는 위치 추적 시스템 중 최고 수준의 사용자 편의성을 경험할 수 있습니다.',

        line: 2,
        card: [
            {
                size: '3',
                tag: '통합 관리 소프트웨어',
                title: '실내 지도 기반의<br/>소프트웨어 제공',
                label: '관리자',
                img: {
                    desktop: RtlsFunctions1D,
                    tablet: RtlsFunctions1T,
                    mobile: RtlsFunctions1M
                },
            },
            {
                size: '1',
                tag: '모바일 앱 제공',
                title: '모바일 앱을통한<br/>위치 추적 제공',
                label: '공통 제공',
                img: {
                    desktop: RtlsFunctions2D,
                    tablet: RtlsFunctions2T,
                    mobile: RtlsFunctions2M
                },
            },
            {
                size: '2',
                tag: '단말기 이력 관리',
                title: '구역별 위치 실시간 기록<br/>다운로드 제공',
                label: '관리자',
                img: {
                    desktop: RtlsFunctions3D,
                    tablet: RtlsFunctions3T,
                    mobile: RtlsFunctions3M
                },
            },
            {
                size: '2',
                tag: '구역 설정',
                title: '구역별 실시간<br/>기기 추적',
                label: '관리자',
                img: {
                    desktop: RtlsFunctions4D,
                    tablet: RtlsFunctions4T,
                    mobile: RtlsFunctions4M
                },
            },
            {
                size: '2',
                tag: '기기 관리',
                title: '편리한<br/>단말기 관리',
                label: '관리자',
                img: {
                    desktop: RtlsFunctions5D,
                    tablet: RtlsFunctions5T,
                    mobile: RtlsFunctions5M
                },
            },
        ]
    },


    hardware: {
        title: '핵심은 하드웨어,<br/>모든 것을 가볍게 혁신.',
        desc: '우리 위치 추적 시스템의 단말기는 특별 제작된 장치로, 지향성 안테나와 현장 환경을 고려한 디자인입니다.<br/>최적의 성능과 안정성을 위해 지향성 안테나를 적용하였으며, 다양한 상황에서도 효과적으로 동작합니다.',
        card: [
            {
                img: {
                    desktop: RtlsHardware1D,
                    tablet: RtlsHardware1T,
                    mobile: RtlsHardware1M
                },
                tag: '지향성 안테나',
                title: '지향성 안테나로<br/>정확한 위치 추적',
                desc: 'TwinTracker(위치추적단말기)의 지향성 안테나가<br/>무선신호의 방향을 유추 할 수 있도록 합니다.'
            },
            {
                img: {
                    desktop: RtlsHardware2D,
                    tablet: RtlsHardware2T,
                    mobile: RtlsHardware2M
                },
                tag: '다양한 브라켓',
                title: '천장 매립형,<br/>레일 등 설치 가능',
                falldown: [
                    {
                        title: '천장 매립형 브라켓',
                        desc: '석고, 텍스, SMC, 마이톤 등<br/>다양한 천장 설치 가능.'
                    },
                    {
                        title: '레일형 브라켓',
                        desc: '조명에 사용하는 레일과 동일한 규격으로<br/>천장에 레일만 설치 되어있으면 언제든지 설치가 가능합니다.'
                    },
                ]
            },
            {
                img: {
                    desktop: RtlsHardware3D,
                    tablet: RtlsHardware3T,
                    mobile: RtlsHardware3M
                },
                tag: '혁신적인 기술',
                title: 'TDoA기술을 이용한<br/>최소 설치, 최대 퍼포먼스',
                desc: 'TDoA기술은 고성능의 통신성능을 유지하면서도 배터리를 혁신적으로 감소시킨 통신기술입니다. <br/>실시간으로 동작 하면서도 위치 정밀도는 오히려 기존대비 훨씬 더 높은 수치를 기록합니다.',
            },
            {
                img: {
                    desktop: RtlsHardware4D,
                    tablet: RtlsHardware4T,
                    mobile: RtlsHardware4M
                },
                tag: '다양한 통신 방식',
                title: '유선통신, 무선 통신<br/>모두 연결 가능',
            },
        ]

    },

    faqRequest: '언제든지 문의',
    faqRequestTail: '주세요.',
    FAQ: [
        {
            title: '기술에 대한 전문 지식이 없습니다. 그래도 사용 가능할까요?',
            description: 'ORBRO 시스템은 기술에 대한 지식이 전혀 없는 사람들도 사용할 수 있도록 간단하지만 강력한 인터페이스를 제공합니다. <br/>무엇보다 시각화된 데이터를 통해서 직관적인 분석과 사용이 가능하도록 시스템을 만들었습니다.<br/><br/>'
        },
        {

            title: '위치 추적이 얼마나 정교한가요?',
            description: 'ORBRO RTLS 시스템의 정확도는 굉장히 정교합니다.<br/>실시간 데이터를 반영하는 것이 중요한 만큼, 거의 오차가 없을 만큼 정확하고 빠른 데이터 연동이 가능합니다.<br/><br/>',
        },
        {

            title: '이 제품들로 위치 추적만 가능한가요? 다른 기능도 추가하고 싶습니다.',
            description: 'ORBRO의 기술은 많은 확장성을 가지고 있습니다.<br/>위치 추적뿐 아니라, 동선 파악, 경로 이탈, 접근 금지 구역 설정 등 다양한 활용기술을 적용할 수 있습니다.<br/><br/>',
        }
    ],


    bannerData: [

        {
            icon: workerOn,
            iconInv: workerOff,
            image: '',
            iconText: 'UWB 위치추적',
            sub: 'New',
            link: '/rtls/uwb'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/os'
        },

    ],



    product: {
        asset: {
            func: '배터리가 내장된 제품으로 전원 공사 필요없이 출입문 옆에 부착하여 쉽고 빠르게 모바일로 출입문 제어가 가능합니다.',
            key1: accessKey1,
            key2: accessKey2,
            key3: accessKey3,
            key4: accessKey4,
            outlook: accessOutlook,

        },


    },


    purchaseDesc: {
        asset: "자산 또는 추적하고자 하는 대상에 BLE 태그 단말기를 부착하여 위치를 추적할 수 있습니다.",
        worker: "자산 또는 추적하고자 하는 대상에 UWB 태그 단말기를 부착하여 위치를 추적할 수 있습니다.",


    },


    purchase: {
        //모바일
        asset: {
            assetTracking: {
                icon: accessKey1,
                title: '자산 위치추적',
                serial: 'Asset-Tracking',
                price: '5,900,000',

                note: [
                    { text: 'TwinTracker (BLE) 10대', icon: IconBox },
                    { text: '자산 추적 태그 30대', icon: IconBox },
                    { text: 'Server PC 1대', icon: IconComputer },
                    { text: '3년 간 보증기간', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'Asset Tag(BLE)',
                        price: '30,000',
                        img: assetTag,
                    },
                    {
                        title: 'TwinTracker(BLE)',
                        price: '300,000',
                        img: twinTracker1,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '100,000',
                        img: twinTrackerBracket,
                    },

                ],
                mark: ""

            },

        },



        //자동문 출입
        worker: {
            workerTracking: {
                icon: accessKey1,
                title: '작업자 위치추적',
                serial: 'Worker-Tracking',
                price: '5,000,000',

                note: [
                    { text: 'TwinTracker (UWB) 10대', icon: IconBox },
                    { text: '작업자 추적 태그 10대', icon: IconBox },
                    { text: 'Server PC 1대', icon: IconComputer },
                    { text: '3년 간 보증기간', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'Tag(UWB)',
                        price: '60,000',
                        img: emlockThumb,
                    },
                    {
                        title: 'TwinTracker(UWB)',
                        price: '300,000',
                        img: deadboltThumb,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '100,000',
                        img: accessAutoThumb,
                    }

                ],
                mark: ""

            },
        },


    },





    func: {
        title: '위치 추적 기능 알아보기',
        desc: '위치 추적의 다양한 기능을 확인해 보세요.',

        data: [
            {
                img: IconPath,
                title: '경로 분석',
                desc: '실시간으로 데이터를 누적하여 시간대별 경로를 분석 할 수 있습니다.'

            },
            {
                img: IconLocation,
                title: '실시간 위치 추적',
                desc: 'Orbro의 위치추적 시스템은 실시간으로 위치를 추적합니다. 정말 분석된 기능으로 생동감있게 현장을 모니터링 할 수 있습니다.'

            },
            {
                img: IconAnalyze,
                title: '분석 도구',
                desc: '수많은 위치 데이터를 모아 데이터 통계와 인사이트를 얻을 수 있습니다.'

            },
            {
                img: IconAlarm,
                title: '알림 설정',
                desc: '사람이 출입 하면 안되는 구역에 위험 구역을 설정하여 알림을 받을 수 있습니다.'

            },
            {
                img: IconExcel,
                title: '엑셀 다운로드',
                desc: '필요한 데이터를 정제하여 엑셀 데이터를 다운 받을 수 있습니다.'

            },
            {
                img: IconSDK,
                title: 'SDK 제공',
                desc: 'Orbro의 위치 추적시스템은 SDK를 제공하여 고객사의 니즈에 맞는 개발을 지원합니다.'

            },
        ]

    }


}