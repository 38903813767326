import { LogoReseller } from "@assets/images/icon";
import { StyledGridContainer } from "@components/Grid/Grid.style";
import { BorderBottomLine, ImgWH, ParagraphColor, Spacing, SpacingDTM } from "@pages/CommonStyle/common.style";
import { DivAllCenter, FontPage } from "@pages/CommonStyle/commonDiv.style";
import React from "react";
import { Trans } from "react-i18next";
import Documents from "./components/Documents";
import RequestForm from "./components/RequestForm";
import { FormCenterDiv } from "./Franchise.style";



const Franchise = () => {
  return (
    <>
    <FontPage>
      <Spacing pixel="52"/>
        <StyledGridContainer>
          <DivAllCenter>
            <Spacing pixel="64"/>
            <ImgWH src={LogoReseller} w={""} h={"56"}/>
            <Spacing pixel="24"/>
            <ParagraphColor color={"#000"} size={"small"}>
              <Trans i18nKey={`franchise.title`}/>
            </ParagraphColor>
            <SpacingDTM d={"64"} t={"64"} m={"48"}/>
          </DivAllCenter>
          <BorderBottomLine/>
          <Spacing pixel="64"/>
          
          <FormCenterDiv>
            <Documents/>
            <RequestForm/>
          </FormCenterDiv>

        </StyledGridContainer>
      </FontPage>
    </>
  )
}

export default Franchise;