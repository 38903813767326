import { DisplayColor, LabelColor, ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import { DivAllCenter, DivFlexRowGap } from "@pages/CommonStyle/commonDiv.style";
import React, { useCallback, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { BannerSection, BannerVideoSection, ImageInner, VideoBox } from "./Banner.style";
import { DisplayH1fff } from "@components/Text/HeadingTagDisplay.style";
import video from '@assets/images/digitaltwin/video/digitaltwin-banner.mp4';
import { VideoPlayCover } from "@pages/CommonStyle/commonVideo";
import i18n from "@pages/i18n";
import { useLocation, useNavigate } from "react-router-dom";
import BannerPop from "./BannerPop";
import { BtnTransparent } from "@pages/CommonStyle/commonBtn";
import { BannerBtnAtag } from "@pages/Main/Home/components/Banner/Banner.style";


const Banner = () => {


  const location = useLocation();
  const isEnglishPage = location.pathname.includes('/en/');
  const isJapanPage = location.pathname.includes('/jp/');
  const isChinaPage = location.pathname.startsWith('/cn/');

  const navigate = useNavigate();
  useEffect(() => {
    if (isEnglishPage) {
      i18n.changeLanguage("en").then(() => {
        navigate("/en/digital-twin", { replace: true });

      });
    } else if (isJapanPage) {
      i18n.changeLanguage("jp").then(() => {
        navigate("/jp/digital-twin", { replace: true });

      });
    } else if (isChinaPage) {
      i18n.changeLanguage("cn").then(() => {
        navigate("/cn/digital-twin", { replace: true });

      });
    } else {
      i18n.changeLanguage("ko").then(() => {
        navigate("/digital-twin", { replace: true });
      });
    }
  }, [location.pathname]);


  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const [isPop, setPop] = useState<boolean>(false);
  const handlePopup = () => {
    setPop(true);
  }

  const onSuccess = useCallback(() => {
    setPop(false);
  }, []);

  function closePopupOnEsc(event) {
    if (event.key === 'Escape') {
      setPop(false);
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', closePopupOnEsc);
  })

  return (
    <>
      {isPop && <BannerPop onClickToggleModal={onSuccess} />}

      <BannerSection>
        <ImageInner scrollPosition={scrollPosition}>
          <DivAllCenter>
            <DisplayColor size={"small"} color="#F8F8F8">
              <Trans i18nKey={`ORBRO World`} />
            </DisplayColor>
            <DisplayH1fff size={"medium"}>
              <Trans i18nKey={`digitaltwin.banner.title`} />
            </DisplayH1fff>
            <Spacing pixel="8" />
            <ParagraphColor color="#FFF" size="medium">
              <p>
                <Trans i18nKey={`digitaltwin.banner.desc`} />
              </p>
            </ParagraphColor>
            <Spacing pixel="16" />
            <DivFlexRowGap gap={16}>
              <BannerBtnAtag href={'https://home.orbro.io'}>
                <LabelColor color={"#FFF"} size={"large"}>
                  <Trans i18nKey={`digitaltwin.banner.btn.left`} />
                </LabelColor>
              </BannerBtnAtag>
              <BtnTransparent onClick={() => handlePopup()} border={"1px solid #fff"}>
                <LabelColor color={"#FFF"} size={"large"}>
                  <Trans i18nKey={`digitaltwin.banner.btn.right`} />
                </LabelColor>
              </BtnTransparent>
            </DivFlexRowGap>
          </DivAllCenter>
        </ImageInner>
        <BannerVideoSection>
          <VideoBox>
            <VideoPlayCover
              autoPlay
              loop muted
              playsInline>
              <source src={video} type="video/mp4" />
            </VideoPlayCover>
          </VideoBox>
        </BannerVideoSection>
      </BannerSection>

    </>
  )
}

export default Banner;
