import { SCREEN_BREAKPOINT, StyledGridRowBtn } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledLabel } from "@components/Text";
import styled from "@emotion/styled";



export const StyledNewsLetterBox = styled.div`
  margin-top : 64px;
  background: #EAF1FD;
  border-radius: 16px;

  min-height: 400px;
  

  padding : 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align : center;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-left : 24px;
    margin-right : 24px;
    overflow : hidden;
  }
  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-left : 16px;
    margin-right : 16px;
    overflow : hidden;
  }
  

`

export const StyledSubscribeBox = styled.div`
  margin-top : 32px;
  display : flex;
  flex-direction : row;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    flex-direction : column;
    padding-left : 24px;
    padding-right : 24px;
    width : 100%;
  }

`


export const StyledDownloadMailBox = styled.div`
  width: 320px;
  height: 52px;
  border : 1px solid rgba(0, 0, 0, 0.08);
  border-radius : 8px;

  color : #BDBDBD;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
  }
    
`;


export const StyledDownloadMail = styled.input`
    border : none;
    border-radius : 8px;
    height : 100%;
    width : 100%;
    padding-left : 16px;
`;



export const StyledDownloadBtnBox = styled(StyledGridRowBtn)`
  margin-left : 8px;
  width : 120px;
  height : 52px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-left : 0px;
    margin-top : 16px;
    width : 100%;
    }
`;


export const StyledDownloadPolicyBox = styled(StyledGridRowBtn)`
    width : auto;
    display : flex;
    margin-top : 32px;

    @media (max-width: 430px) {
      display: flex;
      flex-direction : column;
      gap : 4px;
  }
`;
