import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";
import digitaltwin from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

export default {
    helmet: '| デジタルトランスフォーメーションとは？',
    desc: 'デジタルトランスフォーメーションは、従来のビジネスや社会をデジタル技術で革新するプロセスを指します。つまり、伝統的な手法にデジタル技術を導入し、業務、サービス、コミュニケーションなどを効率的かつ革新的に改善するプロセスと言えます。',
    keywords: 'スマートシティ、デジタルツイン、デジタルトランスフォーメーション、real-time location tracking、RTLS、orbro、Kongtech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'デジタルトランスフォーメーションとは？ | デジタルツイン専門プラットフォーム、Orbro',
    ogUrl: 'https://orbro.io/jp/blog/what-is-digital-transformation',
    ogKey: '',
    ogImg: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'デジタルトランスフォーメーションとは？',
    date: '2024-01-10',
    tag1: '#デジタルトランスフォーメーション',
    tag2: '#デジタルツイン',
    tag3: '#DX',
    tag4: '#スマートシティ',

    text: [
        {
            head: 'デジタルトランスフォーメーションとは？',
            subHead: '',
            desc: 'デジタルトランスフォーメーションは、従来のビジネスや社会をデジタル技術で革新するプロセスを指します。伝統的な手法にデジタル技術を導入し、業務、サービス、コミュニケーションなどを効率的かつ革新的に改善するプロセスです。主にビッグデータ、クラウドコンピューティング、人工知能、自動化などの技術を活用して、ビジネスモデルとプロセスを再構築し、生産性を向上させ、競争力を強化することを目指しています。そのため、デジタルトランスフォーメーションは技術的な変化だけでなく、組織文化や業務方法にも影響を与え、将来にわたる持続的な変化のプロセスとして理解できます。',
            dot: ''
        },
        {
            head: 'デジタルトランスフォーメーションの主な特徴',
            subHead: 'データ中心の経営 (Data-Driven Management)',
            desc: '企業は大量のデータを収集し、分析して正確なビジネスの意思決定を行います。これにより、ビッグデータ技術と分析を活用して顧客の行動を予測し、戦略を立てるのに役立ちます。',
            dot: ''
        },
        {
            head: '',
            subHead: '自動化と人工知能 (Automation and Artificial Intelligence)',
            desc: '業務プロセスの自動化は企業の生産性向上を促し、人工知能技術の導入により予測分析、自然言語処理、画像認識などの複雑な作業を効率的に実行します。これにより人間の業務負担が減少します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'クラウドコンピューティング (Cloud Computing)',
            desc: 'クラウド技術は柔軟なITリソースの利用を可能にし、企業はインフラコストを削減し、必要に応じてリソースを拡張または縮小できるようになります。これにより業務プロセスがより流動的に運営されることができます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'Internet of Things (IoT)',
            desc: 'IoTは物理的なデバイスが接続されてデータを交換し、相互に対話する概念です。これは製造業の生産ラインの最適化からスマートホームの便利な制御、ヘルスケア領域のモニタリングまでさまざまな分野で適用され、効率性を向上させ、革新を促進します。',
            dot: ''
        },
        {
            video: digitaltwin,
            link: 'https://orbro.io/jp/digital-twin',
            videoDesc: '(ビデオをクリックすると、Orbro Digital Twinのページに移動します。)',
            head: 'デジタルトランスフォーメーションの事例',
            subHead: '',
            desc: 'デジタルトランスフォーメーションはさまざまな産業や企業で進行中であり、各事例では特定のデジタル技術を導入してビジネスを革新したりプロセスを最適化したりする方法が異なります。以下はいくつかの代表的なデジタルトランスフォーメーションの事例です。',
            dot: {
                a: '<strong> • 金融サービス業界 - FinTech：</strong>金融サービス業界でのデジタルトランスフォーメーションは、FinTech企業が従来の銀行制度に挑戦しています。モバイルバンキング、電子支払いシステム、投資ロボットなどの技術を導入して、顧客により便利で手頃なサービスを提供しています。モバイルアプリを使用した銀行業務、オンライン投資プラットフォーム、個人金融管理アプリなどがFinTechの主要な事例とされています。',
                b: '<strong> • 製造業 - スマートファクトリー：</strong>製造業でのデジタルトランスフォーメーションは、スマートファクトリーの概念として表れています。 IoT、ビッグデータ、人工知能技術を活用して、生産ラインを最適化し、リアルタイムの生産データを分析して効率性を向上させます。センサーとIoT技術を使用した生産ラインのモニタリング、ビッグデータ分析を使用したメンテナンスの予測、ロボットと自動化を使用した生産プロセスの最適化などがスマートファクトリーの事例です。',
                c: '<strong> • 小売業 - オンラインショッピングおよび個人化マーケティング：</strong>小売業でのデジタルトランスフォーメーションは、オンラインショッピングプラットフォームと個人化マーケティングを強調しています。データ分析を基にした個人化された推薦システム、仮想試着室、およびリアルタイムの在庫管理などが小売業のデジタルトランスフォーメーションの事例です。顧客の過去の購買履歴を分析してカスタム製品のお勧め、オンラインショッピング体験を提供するための仮想フィッティングルーム、店内のデジタル決済システムなどがこれに該当します。',
                d: '<strong> • 医療分野 - 電子健康記録：</strong>医療分野でのデジタルトランスフォーメーションは、電子健康記録（EHR）システムの導入およびテレメディスン技術を通じた遠隔診療とモニタリングに焦点を当てています。患者データをデジタルで管理し、オンラインで医療サービスを提供することで効率性を向上させます。電子健康記録システムの導入、ビデオ通話プラットフォームを使用した遠隔診療、患者の健康状態をリアルタイムでモニタリングするセンサーなどが医療分野のデジタルトランスフォーメーションの事例と見なすことができます。',
            }
        }, 
        {
            head: 'デジタルトランスフォーメーションの主要技術',
            subHead: '人工知能（AI）',
            desc: 'AIは、コンピュータシステムが人間のように学習し、推論し、問題を解決する能力を持つ技術を含みます。機械学習、ディープラーニング、自然言語処理などのサブフィールドを使用して、データ分析、予測分析、自動化などのさまざまなタスクを実行します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'ビッグデータ',
            desc: 'ビッグデータは、大規模で多様なデータを収集、保存、分析する技術です。大量のデータを使用して統計的なパターン、トレンド、洞察を発見し、ビジネスの意思決定をサポートします。クラウドコンピューティングと連携して大規模なデータセットを処理および保存します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'クラウドコンピューティング',
            desc: 'クラウドコンピューティングは、インターネットを介してコンピュータリソース（サーバー、ストレージ、ネットワークなど）を提供する技術であり、必要に応じてリソースを柔軟に調整できます。クラウドを介してインフラコストを節約し、迅速にサービスを提供できます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'Internet of Things（IoT）',
            desc: 'IoTは、オブジェクトにセンサーと通信機能を埋め込み、これらをインターネットを通じて相互に接続する技術です。さまざまなデバイスがデータを収集し、互いに通信し、生活環境や労働環境にインテリジェントに適応します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'ロボティクスとオートメーション',
            desc: 'ロボティクスとオートメーション技術は、機械を使用して人間のタスクを置き換えたり協力したりすることを指します。この技術は製造業だけでなく、サービス業でも利用され、生産性と効率を向上させます。',
            dot: ''
        },
        {
            head: 'デジタルトランスフォーメーションの利点',
            subHead: '競争上の優位性の確保',
            desc: 'デジタルトランスフォーメーションは、新しいビジネスモデルの導入や既存のプロセスの最適化を通じて競争上の優位性を確保できます。効率的なデータ利用とサービス提供の向上は市場での高い可視性と肯定的な評価につながります。',
            dot: ''
        },
        {
            head: '',
            subHead: '顧客体験の向上',
            desc: '顧客中心のデジタルトランスフォーメーションは、顧客体験を向上させます。個別化されたサービス、便利なデジタルインターフェース、迅速な対応は顧客により良い体験を提供し、忠誠心を高めます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'コスト削減と効率向上',
            desc: '自動化、クラウドコンピューティング、データ分析などのデジタル技術の導入により、ビジネスプロセスが効率化され、コスト削減と生産性向上がもたらされます。迅速で正確なタスク実行は人員とリソースの効果的な利用を可能にします。',
            dot: ''
        },
        {
            head: '',
            subHead: '市場対応力の向上',
            desc: 'デジタルトランスフォーメーションは、急速に変化する市場環境に適応するのに役立ちます。柔軟なビジネスモデルとデータ駆動の意思決定は変化に迅速に対応するのに役立ちます。新しい技術の導入や既存のビジネスモデルの変更はイノベーションを促進し、新しい市場への参入、製品革新、パートナーシップの形成など様々な形で現れます。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'この製品は、作業者や設備にUWB端末を取り付け、リアルタイムの位置追跡をサポートします。',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/jp/enterprise/logistics/asset'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/jp/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/jp/enterprise/manufacturing/asset'
            }
        ]

    }
}
