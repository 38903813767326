import {
    constSafetyBanner,
    constSafetyThumb,

    constAssetBanner,
    constAssetThumb,

    constEquipmentBanner,
    constEquipmentThumb,

    logisticsAssetBanner,
    logisticsAssetThumb,

    logisticsSafetyBanner,
    logisticsSafetyThumb,

    officeAssetBanner,
    officeAssetThumb,

    manufacturingProcessBanner,
    manufacturingProcessThumb,

    manufacturingAssetBanner,
    manufacturingAssetThumb,
    
    medicalPatientBanner,
    medicalPatientThumb,

    medicalWasteBanner,
    medicalWasteThumb,

    retailExhibitionBanner,
    retailExhibitionThumb,
    
  } from "@assets/images/solution";
  
  
  export const constructionSafety = {
    banner : constSafetyBanner,
    thumb : constSafetyThumb
  }  

  export const constructionAsset = {
    banner : constAssetBanner,
    thumb : constAssetThumb
  }  

  export const constructionEquipment = {
    banner : constEquipmentBanner,
    thumb : constEquipmentThumb
  }



  export const logisticsAsset = {
    banner : logisticsAssetBanner,
    thumb : logisticsAssetThumb
  }  

  export const logisticsSafety = {
    banner : logisticsSafetyBanner,
    thumb : logisticsSafetyThumb
  }

  

  export const officeAsset = {
    banner : officeAssetBanner,
    thumb : officeAssetThumb
  }




  export const manufacturingProcess = {
    banner : manufacturingProcessBanner,
    thumb : manufacturingProcessThumb
  }

  
  export const manufacturingAsset = {
    banner : manufacturingAssetBanner,
    thumb : manufacturingAssetThumb
  }  

  


  export const medicalPatient = {
    banner : medicalPatientBanner,
    thumb : medicalPatientThumb
  }

  
  export const medicalWaste = {
    banner : medicalWasteBanner,
    thumb : medicalWasteThumb
  }  

  
  
  export const retailExhibition = {
    banner : retailExhibitionBanner,
    thumb : retailExhibitionThumb
  }  

  