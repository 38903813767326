import React, { useMemo, useState, useCallback, PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import i18n from "@pages/i18n";
import SEO from "@pages/SEO";
import BlogText from "../BlogText";

import blog from "@src/lang/jp/blog/blog";
import blogActiveRfid from "@src/lang/jp/blog/blogActiveRfid";
import blogAirport from "@src/lang/jp/blog/blogAirport";
import blogConsider from "@src/lang/jp/blog/blogConsider";

import blogDigitalTwin from "@src/lang/jp/blog/blogDigitalTwin";
import blogForklift from "@src/lang/jp/blog/blogForklift";
import blogGeofence from "@src/lang/jp/blog/blogGeofence";
import blogManufacture from "@src/lang/jp/blog/blogManufacture";
import blogMedical from "@src/lang/jp/blog/blogMedical";
import blogOffice from "@src/lang/jp/blog/blogOffice";
import blogUwb from "@src/lang/jp/blog/blogUwb";
import blogSmartConstruction from "@src/lang/jp/blog/blogSmartConstruction";
import blogSmartLogistics from "@src/lang/jp/blog/blogSmartLogistics";
import blogPatientTracking from "@src/lang/jp/blog/blogPatientTracking";
import blogVisitor from "@src/lang/jp/blog/blogVisitor";
import blogHospital from "@src/lang/jp/blog/blogHospital";
import blogMedicalWaste from "@src/lang/jp/blog/blogMedicalWaste";
import blogLogistics from "@src/lang/jp/blog/blogLogistics";
import blogUwbGeofence from "@src/lang/jp/blog/blogUwbGeofence";
import blogUwbLogistics from "@src/lang/jp/blog/blogUwbLogistics";
import blogLogisticsWorker from "@src/lang/jp/blog/blogLogisticsWorker";
import blogLogisticsAsset from "@src/lang/jp/blog/blogLogisticsAsset";
import blogLogisticsTech from "@src/lang/jp/blog/blogLogisticsTech";
import blogConstructionSafety from "@src/lang/jp/blog/blogConstructionSafety";
import blogHeavyEquipment from "@src/lang/jp/blog/blogHeavyEquipment";
import blogSmartSafety from "@src/lang/jp/blog/blogSmartSafety";
import blogSmartFactory from "@src/lang/jp/blog/blogSmartFactory";
import blogNextManufacturing from "@src/lang/jp/blog/blogNextManufacturing";
import blogDigitalTwin2 from "@src/lang/jp/blog/blogDigitalTwin2";
import blogOfficeSolution from "@src/lang/jp/blog/blogOfficeSolution";
import blogDX from "@src/lang/jp/blog/blogDX";
import blogRtlsHealthcare from "@src/lang/jp/blog/blogRtlsHealthcare";
import blogUwbRtls from "@src/lang/jp/blog/blogUwbRtls";
import blogProductionAutomation from "@src/lang/jp/blog/blogProductionAutomation";
import blogBeacon from "@src/lang/jp/blog/blogBeacon";
import blogRfidNfcUwb from "@src/lang/jp/blog/blogRfidNfcUwb";
import blogRtlsCompany from "@src/lang/jp/blog/blogRtlsCompany";
import blogNfcUwbBle from "@src/lang/jp/blog/blogNfcUwbBle";
import blogUwbble from "@src/lang/jp/blog/blogUwbble";
import blogRfidRtls from "@src/lang/jp/blog/blogRfidRtls";
import blogConstructionSolution from "@src/lang/jp/blog/blogConstructionSolution";
import blogQaUwb from "@src/lang/jp/blog/blogQaUwb";
import blogDigitalTwinGis from "@src/lang/jp/blog/blogDigitalTwinGis";
import blogDigitalTwinWeather from "@src/lang/jp/blog/blogDigitalTwinWeather";
import blogDigitalTwinBuildings from "@src/lang/jp/blog/blogDigitalTwinBuildings";
import blogDigitalTwinTracking from "@src/lang/jp/blog/blogDigitalTwinTracking";
import blogDigitalTwinTraffic from "@src/lang/jp/blog/blogDigitalTwinTraffic";
import blogDigitalTwinAirplane from "@src/lang/jp/blog/blogDigitalTwinAirplane";
import blogDigitalTwinCCTV from "@src/lang/jp/blog/blogDigitalTwinCCTV";
import blogDigitalTwinDigitalCity from "@src/lang/jp/blog/blogDigitalTwinDigitalCity";
import blogDigitalTwin3 from "@src/lang/jp/blog/blogDigitalTwin3";
import blogBLE from "@src/lang/jp/blog/blogBLE";
import blogWhatIsSmartCity from "@src/lang/jp/blog/blogWhatIsSmartCity";
import blogExampleOfSmartCity from "@src/lang/jp/blog/blogExampleOfSmartCity";
import blogContainerTracking from "@src/lang/jp/blog/blogContainerTracking";
import blogWhatIsDX from "@src/lang/jp/blog/blogWhatIsDX";
import blogRFID from "@src/lang/jp/blog/blogRFID";
import blogNFC from "@src/lang/jp/blog/blogNFC";
import blogNVR from "@src/lang/jp/blog/blogNVR";
import blogSmartFactory2 from "@src/lang/jp/blog/blogSmartFactory2";
import blogLoRa from "@src/lang/jp/blog/blogLoRa";
import blogSmartHospital from "@src/lang/jp/blog/blogSmartHospital";
import blogSmartAirport from "@src/lang/jp/blog/blogSmartAirport";
import blogIPS from "@src/lang/jp/blog/blogIPS";
import blogSmartParking from "@src/lang/jp/blog/blogSmartParking";
import blogSmartPort from "@src/lang/jp/blog/blogSmartPort";
import blogSmartBuildings from "@src/lang/jp/blog/blogSmartBuildings";
import blogIndoorGPS from "@src/lang/jp/blog/blogIndoorGPS";
import blogAiCamera from "@src/lang/jp/blog/blogAiCamera";
import blogAIoT from "@src/lang/jp/blog/blogAIoT";
import blogSmartShipyard from "@src/lang/jp/blog/blogSmartShipyard";



const pageData = {

  blog,
  blogUwb,
  blogDigitalTwin,
  blogGeofence,
  blogMedical,
  blogUwbble,
  blogAirport,
  blogManufacture,
  blogActiveRfid,
  blogForklift,
  blogConsider,
  blogOffice,
  blogSmartConstruction,
  blogSmartLogistics,
  blogPatientTracking,
  blogVisitor,
  blogHospital,
  blogMedicalWaste,
  blogUwbGeofence,
  blogLogistics,
  blogUwbLogistics,
  blogLogisticsWorker,
  blogLogisticsAsset,
  blogLogisticsTech,
  blogConstructionSafety,
  blogHeavyEquipment,
  blogSmartSafety,
  blogSmartFactory,
  blogNextManufacturing,
  blogDigitalTwin2,
  blogOfficeSolution,
  blogDX,
  blogRtlsHealthcare,
  blogUwbRtls,
  blogProductionAutomation,
  blogBeacon,
  blogRfidNfcUwb,
  blogRtlsCompany,
  blogNfcUwbBle,
  blogRfidRtls,
  blogConstructionSolution,
  blogQaUwb,
  blogDigitalTwinGis,
  blogDigitalTwinWeather,
  blogDigitalTwinBuildings,
  blogDigitalTwinTracking,
  blogDigitalTwinTraffic,
  blogDigitalTwinAirplane,
  blogDigitalTwinCCTV,
  blogDigitalTwinDigitalCity,
  blogDigitalTwin3,
  blogBLE,
  blogWhatIsSmartCity,
  blogExampleOfSmartCity,
  blogContainerTracking,
  blogWhatIsDX,
  blogRFID,
  blogNFC,
  blogNVR,
  blogSmartFactory2,
  blogLoRa,
  blogSmartHospital,
  blogSmartAirport,
  blogIPS,
  blogSmartParking,
  blogSmartPort,
  blogSmartBuildings,
  blogIndoorGPS,
  blogAiCamera,
  blogAIoT,
  blogSmartShipyard

}

interface ModalDefaultType {
  pageName: string
}

const BlogDetailPage = ({
  pageName
}: PropsWithChildren<ModalDefaultType>) => {


  const location = useLocation();
  const page = 'blog' + pageName;


  const helmetTitle = pageData[page].ogSite;
  const ogDesc = useMemo(() => pageData[page]?.desc || '', [page]);
  const ogUrl = useMemo(() => pageData[page]?.ogUrl || '', [page]);
  const ogKey = useMemo(() => pageData[page]?.keywords || '', [page]);
  const ogImg = useMemo(() => pageData[page]?.ogImg || '', [page]);

  const date = useMemo(() => pageData[page]?.date || '', [page]);


  const textList: {
    img?: string; videoDesc?: string; video?: string; link?: string; youtube?: string;
    head: string; subHead: string; desc: string; dot: string; linkText?: string;
  }[] = useMemo(
    () => pageData[page]?.text || [],
    [page]
  );
  useEffect(() => {
    if (typeof window !== 'undefined') {
    }
  }, []);


  const [windowWidth, setInnerWidth] = useState<number>(1920);
  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;
      setInnerWidth(innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const isJapanPage = location.pathname.includes('/jp/');
  const navigate = useNavigate();
  useEffect(() => {
    if (isJapanPage && (i18n.language !== 'jp')) {
      i18n.changeLanguage("jp").then(() => {
        navigate("", { replace: true });
      });
    }
  }, [isJapanPage]);


  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Article",
    "publisher": {
      "@type": "Organization",
      "name": helmetTitle,
      "url": ogUrl,
      "logo": {
        "@type": "ImageObject",
        "url": "https://ci6.googleusercontent.com/proxy/XBuoTCAgZz2qRpP9W6wKTcoui4eSr7Ewe3D3m-Yhc10c9_5yTOXJsX9UV79hvagikUmz8cQhPcKX5cMyZy_hvYC8Fao1TGOIRjKFEZNCpgt02YAg2S8GvNqadpL37XBn80qOJwAQvCELqYtUTBjrVOVHDGw4c0vvD4wB5Ul3aDzFYGwS4eRWBXaG5YSvTWIjtPbGI02j3v3OMHrAEXfcgfHFqMAAX4S5hWokE0NHvBlmTCjlGL-rYuo1wufdfVNpRq8b5-rfDfTtN8t3oMlwXu1S_8SFtrd1HbuDF_FJwE-WqwgjkiorNvcUbJCvV5-xeBH0EW0WaoCGQPO-g0VoGYaDQN-eAiDoYmx4a0F5fRR00IkXZCf9IvNjQ1harIXfZ7hPZU9TC1RUnVCDsuKPZlxPzAoHPQ=s0-d-e1-ft#https://kongtech.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fcf58ac78-4bac-4b20-852f-a21d311bf753%2FOrbro_Logo(RGB)_B.png?id=a0c2cd44-56a9-449f-8c52-4d0c69e3aaec&table=block&spaceId=2ff4ed52-1658-44a4-a5d8-af1695935a1d&width=2000&userId=&cache=v2"
      }
    },
    "author": {
      "@type": "Organization",
      "name": "ORBRO",
      "image": {
        "@type": "ImageObject",
        "url": "https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238",
        "width": 167,
        "height": 167
      },
      "url": "https://orbro.io",
      "sameAs": [
        "https://kong-tech.com"
      ]
    },
    "headline": helmetTitle,
    "url": ogUrl,
    "image": {
      "@type": "ImageObject",
      "url": ogImg,
      "width": 1200,
      "height": 854
    },

    "datePublished": date,
    "keywords": ogKey,
    "description": ogDesc,
    "mainEntityOfPage": ogUrl
  }



  return (
    <>
      <SEO
        lang={"ja"}
        helmetTitle={helmetTitle}
        ogKey={ogKey}
        ogDesc={ogDesc}
        ogImg={ogImg}
        ogUrl={ogUrl}
        jsonLd={jsonLd}
      />
      <BlogText pageName={pageName} path={pageData[page]} textList={textList}/>
    </>
  )
}

export default BlogDetailPage; 