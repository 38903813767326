import { IconBell, IconBinocularsGray, IconChainLink, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { IconAccess, IconAlarm, IconWarn, IconZoom } from "@assets/images/main";
import { rtlsSystem2 } from "@assets/images/solution";
import video from "@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4";
import subVideo1 from "@src/assets/images/solution/constructionEquipment/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/constructionEquipment/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/constructionEquipment/video/sub/rtlsVideo3.mp4";

export default {
    banner: {
      title: 'Heavy Equipment Management',
      desc: 'Heavy equipment is a crucial element on construction sites. Proper maintenance and accident prevention are essential. By effectively managing heavy equipment, safety can be maintained, costs can be reduced, and productivity on the site can significantly increase.',
    },
    video: {
      title: 'The smartest way to manage heavy equipment.',
      desc: 'Real-Time Location System (RTLS) technology provides real-time tracking of the heavy equipment workers need.<br/>By preventing collision accidents and optimizing deployment, it takes work processes to the next level of efficiency and productivity.',
      play: video,
    },
    func: [
      {
        title: 'Real-Time Location Tracking',
        desc: 'Real-time tracking of the current location of heavy equipment enables efficient deployment and easier time management on-site.',
        sub1: 'High accuracy',
        icon1: IconRulerGray,
        sub2: 'Fast data integration',
        icon2: IconShareGray,
        video: subVideo1,
      },
      {
        title: 'Proximity Warning System',
        desc: 'Accidents caused by human error or system failure during the operation of heavy equipment can have severe consequences. Contact accidents, in particular, are highly dangerous. Real-time monitoring allows immediate response to accidents.',
        sub1: 'Designation of hazardous assets',
        icon1: IconWarningGray,
        sub2: 'Setting proximity distance criteria',
        icon2: IconRulerGray,
        video: subVideo2,
      },
      {
        title: 'Blind Spot Hazard Detection',
        desc: 'Blind spots can occur in the field of vision of heavy equipment operators. Fatigue-inducing work creates an environment where people are prone to carelessness. By identifying blind spots, the risk of collisions and maintaining a safe working environment can be prevented.',
        sub1: 'Defining free zones',
        icon1: IconZoneGray,
        sub2: 'Setting multiple zones',
        icon2: IconTagGray,
        video: subVideo3,
      },
    ],
    subfunc: {
      title: 'Various Functions',
      desc: 'Heavy equipment is vital in terms of both safety and cost. Using heavy equipment that violates safety regulations can cause accidents, and inefficient work methods waste costs and resources. To create a safe and cost-effective environment, ORBRO offers the following features.',
      data: [
        {
          icon: IconChartRadius,
          title: 'Reports and Analysis',
          desc: 'Generate reports and analysis based on field data.',
        },
        {
          icon: IconBell,
          title: 'Real-Time Alarm Function',
          desc: 'Receive alarms for abnormal situations with heavy equipment and respond immediately.',
        },
        {
          icon: IconRoute,
          title: 'Route History Inquiry',
          desc: 'Retrieve and digitize the routes heavy equipment has taken in the field.',
        },
        {
          icon: IconDashboard,
          title: 'Status Monitoring',
          desc: 'Track the current movement of specific heavy equipment.',
        },
        {
          icon: IconWarning,
          title: 'Deviation Detection',
          desc: 'Detect deviations where heavy equipment deviates from the intended area or moves incorrectly.',
        },
        {
          icon: IconSearch,
          title: 'Route Pattern Analysis',
          desc: 'Analyze the usage and timing of heavy equipment in the field.',
        },
        {
          icon: IconChainLink,
          title: 'Data Integration',
          desc: 'Efficiently utilize systems such as RFID, IoT, security, and video by integrating them.',
        },
        {
          icon: IconSecurity,
          title: 'Data Security',
          desc: 'Maintain a high level of data security to provide a reliable solution.',
        },
      ],
    },
    system: {
      title: 'System Configuration',
      img: rtlsSystem2,
    },
    productTitle: 'Product Configuration',
    product: [
      {
        tag: 'carPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'constructionAsset',
      },
      {
        data: 'constructionSafety',
      },
      {
        data: 'blogRTLS',
      },
      {
        data: 'blogMedical',
      },
    ],
  }
  