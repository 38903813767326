import { assetTag1, assetTag2, assetTag3, twinTracker2, twinTracker3 } from "@assets/images/main/rtls/product";
import productImg from "./productImg";
import { tagUwb1Pop, tagUwb2Pop, tagUwb3Pop, twinTrackerBle1Pop, twinTrackerBle2Pop, twinTrackerBle3Pop, twinTrackerBracket1Pop, twinTrackerBracket2Pop, twinTrackerBracket3Pop, twinTrackerUwb1, twinTrackerUwb2, twinTrackerUwb3 } from "@assets/images/main";

export default {

    products : [
        {   title : "accesscontroller",
            components : [
                {part : 0},
                {part : 1},
        ],},

        {   
            title : 'accessauto',
            components : [
            {part : 0},
            {part : 1},
            {part : 2},
        ],},

       { 
            title : 'accesspro',
            components : [
                {part : 0},
                {part : 1},
                {part : 2},
        ],},
        {
            title : 'assetTracking',
            components : [
                {part : 3},
                {part : 4},
                {part : 5},
        ],},
        {
            title : 'workerTracking',
            components : [
                {part : 6},
                {part : 7},
                {part : 5},
        ],}
    ],


    parts :[
        {
            // 0
            title : "EM Lock",
            serial : "EM-Lock",
            desc : "EM Lock是用于非自动门（如防火门或木门）的单向进出的电子锁，利用磁力固定进出门。适用于公寓、办公室等出入口和大门等位置的自动锁。",
            price : "150,000",
            img : [
                {data : productImg.product.emlock.mini1},
                {data : productImg.product.emlock.mini2},
                {data : productImg.product.emlock.mini3},
                {data : productImg.product.emlock.mini4}
            ]
        },
        {
            // 1
            title : "磁性门锁",
            serial : "Deadbolt Lock",
            desc : "当关闭门时，磁性门锁会自动锁定门锁，适用于公寓、办公室等出入口和大门等位置的自动锁。",
            price : "150,000",
            img : [
                {data : productImg.product.deadbolt.mini1},
                {data : productImg.product.deadbolt.mini2},
                {data : productImg.product.deadbolt.mini3},
                {data : productImg.product.deadbolt.mini4}
                
            ]
        },
        {
            // 2
            title : "室内自动门传感器",
            serial : "Automatic Door Sensor",
            desc : "室内自动门传感器可以检测人体或物体的运动，用于控制自动门的开闭，是高灵敏度、高性能的产品。",
            price : "99,000",
            img : [
                {data : productImg.product.accessauto.mini1},
                {data : productImg.product.accessauto.mini2},
                {data : productImg.product.accessauto.mini3},
                {data : productImg.product.accessauto.mini4}
                
            ]
        },
        ///
        {
            // 3
            title : "Asset Tag(BLE)",
            serial : "OAS-OTBT1991",
            desc : "小巧便捷的附件，可用于跟踪资产的位置。",
            price : "30,000",
            img : [
                {data : productImg.product.assetTracking.mini2},
                {data : assetTag1},
                {data : assetTag2},
                {data : assetTag3}
            ]
        },
        {
            // 4
            title : "Twin Tracker(BLE)",
            serial : "OTR-TTBT0001",
            desc : "Twin Tracker可以广泛识别蓝牙信号，并将识别的位置跟踪信息传递给ORBRO系统。可根据不同的安装环境支持USB-C型电源和PoE型电源，并且还支持有线和无线通信（Wi-Fi）。",
            price : "300,000",
            img : [
                {data : twinTrackerBle1Pop},
                {data : twinTrackerBle2Pop},
                {data : twinTrackerBle3Pop},
            ]
        },
        {
            // 5
            title : "Twin Tracker Bracket",
            serial : "Twin Tracker - Bracket",
            desc : "Twin Tracker支架可将Twin Tracker安装在照明导轨上，使安装更加便捷。",
            price : "100,000",
            img : [
                {data : twinTrackerBracket1Pop},
                {data : twinTrackerBracket2Pop},
                {data : twinTrackerBracket3Pop}

            ]
        },
        {
            // 6
            title : "Tag(UWB)",
            serial : "Tag-UWB",
            desc : "简单便捷的附件，可用于跟踪工人的位置。",
            price : "60,000",
            img : [
                {data : tagUwb1Pop},
                {data : tagUwb2Pop},
                {data : tagUwb3Pop}

            ]
        },
        {
            // 7
            title : "Twin Tracker(UWB)",
            serial : "ORT-TTUW0001",
            desc : "Twin Tracker可以广泛识别UWB信号，并将识别的位置信息传递给ORBRO平台。测量精度可达10cm，可根据不同的安装环境支持USB-C型电源和PoE型电源，并且还支持有线和无线（Wi-Fi）通信。",
            price : "300,000",
            img : [
                {data : twinTrackerUwb1},
                {data : twinTrackerUwb2},
                {data : twinTrackerUwb3}

            ]
        },

        {
            // 7
            title : "Twin Tracker(UWB)",
            serial : "ORT-TTUW0001",
            desc : "Twin Tracker可以广泛识别UWB信号，并将识别的位置信息传递给ORBRO平台。测量精度可达10cm，可根据不同的安装环境支持USB-C型电源和PoE型电源，并且还支持有线和无线（Wi-Fi）通信。",
            price : "300,000",
            img : [
                {data : twinTrackerUwb1},
                {data : twinTrackerUwb2},
                {data : twinTrackerUwb3}

            ]
        },

    ],









}