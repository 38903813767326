import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/logisticsSafety/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/logisticsSafety/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/logisticsSafety/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/logisticsSafety/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/logisticsSafety/video/sub/rtlsVideo4.mp4";

export default {
    banner: {
      title: 'Warehouse Worker Safety Management',
      desc: `Worker safety is paramount above all else. Failing to ensure worker safety in high-risk environments can not only pose productivity issues but also result in legal liabilities. Furthermore, a safe logistics site plays a crucial role in shaping the company's image and building trust with stakeholders.`,
    },
    video: {
      title: 'Ensuring Warehouse Worker Safety',
      desc: 'Real-Time Location Tracking (RTLS) technology provides real-time information on the locations of logistics site workers. Managers can detect potential risks in advance and respond more quickly to issues. It also supports compliance with <strong>safety regulations</strong> and builds trust between workers and managers.',
      play: video,
    },
    func: [
      {
        title: 'Real-Time Worker Location Tracking',
        desc: 'By tracking the locations of workers in real time, it helps monitor safety conditions and enables quick responses in case of approaching hazardous areas or emergencies. This helps prevent potential risks in advance.',
        sub1: 'High Accuracy',
        icon1: IconRulerGray,
        sub2: 'Fast Data Integration',
        icon2: IconShareGray,
        video: subVideo1,
      },
      {
        title: 'Real-Time Heavy Equipment Location Tracking',
        desc: 'Safety considerations extend beyond just humans. By tracking the locations of potentially hazardous heavy equipment in real time, it enables the identification of situations where workers are in close proximity or entering the wrong path, thus preventing accidents.',
        sub1: 'Designating Hazardous Assets',
        icon1: IconWarningGray,
        sub2: 'Real-Time Location Tracking',
        icon2: IconBinocularsGray,
        video: subVideo2,
      },
      {
        title: 'Tracking Worker Paths',
        desc: 'When there are hazardous equipment or materials along the path where workers are heading, warnings can be sent to administrators. This enables quick responses or rescues in case of accidents.',
        sub1: 'Cloud Storage for Data',
        icon1: IconCloudCheckGray,
        sub2: 'Providing Statistical Data',
        icon2: IconChartBarGray,
        video: subVideo3,
      },
      {
        title: 'Setting Safety Zones',
        desc: 'By predefining areas that workers should not approach or areas that require caution, warnings can be issued to workers passing nearby or notifications can be sent to managers. This helps prevent accidents.',
        sub1: 'Configurable Range',
        icon1: IconZoneGray,
        sub2: 'Multiple Zone Settings',
        icon2: IconTagGray,
        video: subVideo4,
      },
    ],
    subfunc: {
      title: 'Various Features',
      desc: 'Ensuring worker safety enhances efficiency and reduces losses due to accidents. ORBRO has designed optimal features to ensure the safety of workers in the logistics industry.<br/>The system is built to respond appropriately to safety accidents that may occur, including workers approaching hazardous areas or accidents involving potentially hazardous heavy equipment.',
      data: [
        {
          icon: IconChartRadius,
          title: 'Reports and Analysis',
          desc: 'Reports and analysis can be generated based on field data.',
        },
        {
          icon: IconBell,
          title: 'Real-Time Alert Function',
          desc: 'Alerts can be sent to workers in case of abnormal situations, allowing for immediate responses.',
        },
        {
          icon: IconRoute,
          title: 'Path History Tracking',
          desc: 'The movement paths of workers can be tracked and recorded as data.',
        },
        {
          icon: IconDashboard,
          title: 'Status Monitoring',
          desc: 'Monitor the current locations of workers.',
        },
        {
          icon: IconWarning,
          title: 'Deviation Detection',
          desc: 'Detect when workers deviate from the designated areas or move to the wrong locations.',
        },
        {
          icon: IconSearch,
          title: 'Path Pattern Analysis',
          desc: 'Based on the location of workers, instructions for the next tasks can be given.',
        },
        {
          icon: IconChainLink,
          title: 'Data Integration',
          desc: 'Efficiently integrate with systems such as RFID, IoT, security, and video for optimal utilization.',
        },
        {
          icon: IconSecurity,
          title: 'Data Security',
          desc: 'Maintain a high level of data security to provide a reliable solution.',
        },
      ],
    },
    system: {
      title: 'System Configuration',
      img: rtlsSystem,
    },
    productTitle: 'Product Configuration',
    product: [
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'logisticsAsset',
      },
      {
        data: 'constructionSafety',
      },
      {
        data: 'blogForklift',
      },
      {
        data: 'blogRTLS',
      },
    ],
  }
  