import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";
 


export const FormGrid = styled.div`
    display : grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap : 32px;
    row-gap : 16px;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        display : flex;
        flex-direction : column;
        gap : 16px;
    }

`;

export const FormCenterDiv =styled.div`
    max-width: 810px;
    margin : 0 auto;
`;



export const FranchiseOptionBtn = styled.div<{inputValue : boolean}>`
  width: 100%;
  height: 56px;
  border-radius : 8px;

  padding : 8px 12px;

  background-color : #FFFFFF;
  align-items : center;

  cursor : pointer;

  border: ${({ inputValue }) => (inputValue ? '1px solid black' : '1px solid rgba(0, 0, 0, 0.08)')};

 

`;

export const OptionSelect = styled.div`
    border : none;
    height : 20px;
    width : 100%;
    font-size : 16px; 
    outline: none;

    &::placeholder {
      color: #BDBDBD;
    }
    
`;


export const FranchiseMemo = styled.textarea`
  width: 100%;
  height: 240px;
  padding: 12px;
  border-radius: 8px;
  resize: none;
  border: solid 1px rgba(0, 0, 0, 0.08);
`

export const FileSection = styled.div`
    display : flex;

`;


export const FileBox = styled.div`
    display: flex;
    padding: 18px 12px;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
    background: var(--background-primary-light, #FFF);
    width : 100%;
    
`;

export const FileBtn = styled.label<{color : string}>`
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    right : 0;
    width : fit-content;
    cursor : pointer;

    ${({color})=> color && `
        background: var(--primitive-blue-600, ${color});
    `}
`;


export const InvisibleInput = styled.input`
    display : none;
`;

export const SubmitBtn = styled.div<{color : string}>`
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    right : 0;
    width : fit-content;
    cursor : pointer;

    ${({color})=> color && `
        background: var(--primitive-blue-600, ${color});
    `}
`;