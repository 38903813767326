import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";

export const UseCaseLayout = styled.div`
width: 100%;
  padding: 128px 0;
  background-color: ${({ theme }) => theme.BACKGROUND_SECONDARY};

`;


export const UseCaseAbsolute = styled.div`
width : 100vw;
left : 0;
  padding: 128px 0;
  position : absolute;
  background-color : blue;
  height : fit-content;
`;


export const UseCaseCardSection = styled.div`
  display : grid;
  grid-template-columns: repeat(3, 1fr);
  gap : 32px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    grid-template-columns: repeat(2, 1fr);
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    display : flex;
    flex-direction : column;
  }
`;

export const UseCaseCard = styled.div`
  padding : 32px;
  height : 380px;
  display : flex;
  flex-direction : column;
  background-color : #fff;
  border-radius : 24px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    height : fit-content;}

`;

export const UseCaseIcon = styled.img`
  width: 32px;
  height : 32px;
  display : flex;
  object-fit : contain;
`;