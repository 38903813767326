import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  StyledDesc, StyledLink, StyledProducImgBox,
  StyledSchemeDesc, StyledProductButton, StyledSchemeBox,
  StyledSchemeLine, StyledProductImage, StyledProductLayer
} from './Scheme.style';

import access from '@src/lang/ko/main/access/access';
import { StyledGridColumn, StyledGridContainer, StyledGridRow } from '@components/Grid/Grid.style';
import { IconCheckBlack } from '@assets/images/icon';
import { ImgWH, Spacing } from '@pages/CommonStyle/common.style';
import { DivAllCenter, DivJustify, FontPage } from '@pages/CommonStyle/commonDiv.style';

import accessScheme from '@src/lang/ko/main/access/productScheme';
import cameraScheme from '@src/lang/ko/main/camera/productScheme';
import rtlsScheme from '@src/lang/ko/main/rtls/productScheme';
import { DisplayH2 } from '@components/Text/HeadingTagDisplay.style';
import { LabelH3 } from '@components/Text/Label/Label.style';
import { Link } from 'react-router-dom';

const Scheme = ({ handleIconClickLocal, product }): React.ReactElement => {


  const handleIconClickScheme = (group) => {
    handleIconClickLocal(group);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [productName, setProductName] = useState<string>('');
  const [path, setPath] = useState<string>('');
  const [dataSheet, setDataSheet] = useState<any>({}); // Provide an initial value, an empty object in this case
  const [products, setProducts] = useState<any>([]);
  const [a, setA] = useState<any>([]);
  const [b, setB] = useState<any>([]);
  const [c, setC] = useState<any>([]);
  const [d, setD] = useState<any>([]);



  useEffect(() => {
    if (product === 'rtls') {
      setProductName('rtls');
      setPath('productSchemeRTLS');
      setDataSheet(rtlsScheme);

    } else if (product === 'camera') {
      setProductName('camera');
      setPath('productSchemeCamera');
      setDataSheet(cameraScheme);
    } else if (product === 'access') {
      setProductName('accessMain');
      setPath('productSchemeAccess');
      setDataSheet(accessScheme);
    }

    // dataSheet가 유효한 경우에만 setProducts 등을 호출
    if (dataSheet && dataSheet.spec) {
      setProducts(dataSheet.spec);
      setA(dataSheet.a || []); // 초기값을 빈 배열로 설정
      setB(dataSheet.b || []); // 초기값을 빈 배열로 설정
      setC(dataSheet.c || []); // 초기값을 빈 배열로 설정
      setD(dataSheet.d || []); // 초기값을 빈 배열로 설정
    }
  }, [product, dataSheet])



  return (
    <>
      <Spacing pixel='96' />
      <StyledGridContainer>
        <StyledGridRow>

          <StyledGridColumn desktop={12} tablet={8} mobile={4}>
            <DivAllCenter>
              <DisplayH2 size={'small'}>
                <Trans i18nKey={`${productName}.banner.schemeTitle`} />
              </DisplayH2>
            </DivAllCenter>
            <StyledDesc size={'medium'}>
              <p>
                <Trans i18nKey={`${productName}.banner.schemeDesc`} />
                <StyledLink size={'medium'} inline>
                  <Trans i18nKey={access.banner.phone} />
                </StyledLink>
                <Trans i18nKey={`${productName}.banner.schemeDesc2`} />
              </p>
            </StyledDesc>
          </StyledGridColumn>
        </StyledGridRow>



        {/* 이미지, 버튼 출력 */}
        <StyledProductLayer>
          {products.map((product, index) => (
            <>
              <StyledProducImgBox>
                {product.image && (
                  <>
                    <StyledProductImage src={product.image} alt="RTLS Product" />
                    <Link to={product.link}>
                      <StyledProductButton><Trans i18nKey={"home.showMore"} /></StyledProductButton>
                    </Link>
                  </>
                )}
              </StyledProducImgBox>
            </>
          ))}
        </StyledProductLayer>


        <Spacing pixel='32' />
        {Array.from({ length: 12 }).map((_, idx) => (

          <div key={`product-${idx}`}>
            <>
              <StyledSchemeLine>
                <StyledSchemeBox>
                  <LabelH3 size={'medium'}>
                    <Trans i18nKey={`${path}.a.${idx}.text`} />
                  </LabelH3>
                </StyledSchemeBox>

                <StyledSchemeBox>
                  {b[idx]?.text === '1' ? (
                    <DivJustify>
                      <ImgWH w='20' h='20' src={IconCheckBlack} alt='Check Icon' />
                    </DivJustify>
                  ) : (
                    <StyledSchemeDesc size={'medium'}>
                      <p>
                        <Trans i18nKey={`${path}.b.${idx}.text`} />
                      </p>
                    </StyledSchemeDesc>
                  )}
                </StyledSchemeBox>

                <StyledSchemeBox>
                  {c[idx]?.text === '1' ? (
                    <DivJustify>
                      <ImgWH w='20' h='20' src={IconCheckBlack} alt='Check Icon' />
                    </DivJustify>
                  ) : (
                    <StyledSchemeDesc size={'medium'}>
                      <p>
                        <Trans i18nKey={`${path}.c.${idx}.text`} />
                      </p>
                    </StyledSchemeDesc>
                  )}
                </StyledSchemeBox>

                {d.length > 0 &&
                  <StyledSchemeBox>
                    {d[idx]?.text === '1' ? (
                      <DivJustify>
                        <ImgWH w='20' h='20' src={IconCheckBlack} alt='Check Icon' />
                      </DivJustify>
                    ) : (
                      <StyledSchemeDesc size={'medium'}>
                        <p>
                          <Trans i18nKey={`${path}.d.${idx}.text`} />
                        </p>
                      </StyledSchemeDesc>
                    )}
                  </StyledSchemeBox>
                }
              </StyledSchemeLine>
            </>
          </div>
        ))}

      </StyledGridContainer>
    </>
  );
};

export default Scheme;
