import { StyledGridContainer } from "@components/Grid/Grid.style";

import { VideoBox, VideoPlay } from "@pages/Enterprise/Solution/components/Video/Video.style";
import { BorderBottomLine, ParagraphColor, Spacing, SpacingDTM, } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";

import { HeadingH2,StyledHeading } from "@components/Text/Heading/Heading.style";
import { FitAtag } from "@layouts/Default/components/NavBox/components/MenuTree/Tree3.style";
import TitleBanner from "../Common/TitleBanner/TitleBanner";
import React  from "react";
import { StyledBlog, StyledBlogPara, StyledBlogParaSub } from "@pages/Enterprise/Blog/Detail/BlogDetail.style";
import { Trans } from "react-i18next";
import { BlogImg, BlogImgBox, BlogTextLink } from "@pages/Enterprise/Blog/Main/BlogModule.style";
import UseCase from "./components/UseCase/UseCase";

import { StyledParagraph } from "@components/Text";
import { TechPage } from "./TechnologyModule.style";
import Advantage from "./components/Advantage/Advantage";
import TechRequest from "../Common/TechRequest/TechRequest";



import whatisdigitaltwin from "@src/lang/ko/technology/whatisdigitaltwin";
import whatisgeofence from "@src/lang/ko/technology/whatisgeofence";

const pageData = {
  whatisdigitaltwin,
  whatisgeofence,

}

const TechnologyModule = ({ pageName, textList }) => {


  const page = pageName;


  return (
    <>

      <SpacingDTM d={"64"} t={"48"} m={"32"} />
      <StyledBlog>
        <TitleBanner
          subtitle={`${pageName}.tag`}
          title={`${pageName}.title`}
          description={`${pageName}.desc`}
          btnText = {pageData[page].btnText ? `${page}.btnText` : ''}
          btnLink = {pageData[page].btnLink ? pageData[page].btnLink : ''}
        />

        {textList.map((textNumb, index) => {

          return (
            <>
              {textNumb.tag === 'card' ?
                <>
                  <UseCase pageName={pageName} path={pageData[page]} />
                  <Spacing pixel="64" />
                </>

                :

                <StyledGridContainer>
                  <TechPage >
                    {textNumb?.img && (textNumb?.link ?
                      <a href={`${textNumb.link}`} target="_blank" rel="noopener noreferrer">
                        <BlogImgBox>
                          <BlogImg src={textNumb?.img} alt="RTLS Indoor Tracking" />
                        </BlogImgBox>
                      </a>
                      :
                      <DivAllCenter >
                        <BlogImg src={textNumb?.img} alt="RTLS Indoor Tracking" />
                      </DivAllCenter>)
                    }
                    {textNumb?.video && textNumb?.link &&
                      <a href={`${textNumb.link}`} target="_blank" rel="noopener noreferrer">
                        <Spacing pixel="24" />
                        <VideoBox>
                          <VideoPlay autoPlay loop muted playsInline>
                            <source src={textNumb?.video} type="video/mp4" />
                          </VideoPlay>
                        </VideoBox>
                      </a>
                    }
                  </TechPage>
                  {
                    textNumb?.videoDesc &&
                    <>
                      <Spacing pixel="4" />
                      <DivAllCenter>
                        <ParagraphColor size="medium" color={"#BDBDBD"}>
                          <p>
                            <Trans i18nKey={`${page}.text.${index}.videoDesc`} />
                          </p>
                        </ParagraphColor>
                      </DivAllCenter>
                    </>
                  }
                  <Spacing pixel="64" />
                  {
                    textNumb.head &&
                    <>
                      <HeadingH2 size={"small"}>
                        <Trans i18nKey={`${page}.text.${index}.head`} />
                      </HeadingH2>
                      <Spacing pixel="8" />
                    </>
                  }

                  <StyledBlogPara size="medium" >
                    <p>
                      <Trans i18nKey={`${page}.text.${index}.desc`} />
                    </p>
                  </StyledBlogPara>

                  {
                    textNumb.subHead &&
                    <>
                      {textNumb.subHead.map((data, i) => (
                        <>
                          <Spacing pixel="32" />
                          <StyledHeading size="xxsmall">
                            <p>
                              <Trans i18nKey={`${page}.text.${index}.subHead.${i}.title`} />
                            </p>
                          </StyledHeading>
                          <StyledParagraph size="medium" >
                            <p>
                              <Trans i18nKey={`${page}.text.${index}.subHead.${i}.desc`} />
                            </p>
                          </StyledParagraph>
                        </>
                      ))}
                    </>
                  }

                  {
                    textNumb.linkText &&
                    <>
                      <FitAtag href={textNumb.link} mt={"0"}>
                        <BlogTextLink size="xxsmall" >
                          <Trans i18nKey={`${page}.text.${index}.linkText`} />
                        </BlogTextLink>
                        <Spacing pixel="8" />
                      </FitAtag>
                    </>
                  }
                  {
                    textNumb?.tag === 'advantage' &&
                    <>
                      <Advantage
                        page={`${page}`}
                        items={pageData[page].advantage.cards}
                      />
                    </>
                  }


                  {
                    Object.keys(textNumb.dot || {}).map((key) => (
                      <>
                        <Spacing pixel="32" />
                        <StyledBlogParaSub size="medium" key={key} >
                          <strong>
                            <Trans i18nKey={`${page}.text.${index}.dot.${key}.title`} />
                          </strong>
                        </StyledBlogParaSub>
                        <StyledBlogParaSub size="medium" key={key} >
                          <p>
                            <Trans i18nKey={`${page}.text.${index}.dot.${key}.desc.${0}.text`} />
                          </p>
                        </StyledBlogParaSub>
                        <StyledBlogParaSub size="medium" key={key} >
                          <p>
                            <Trans i18nKey={`${page}.text.${index}.dot.${key}.desc.${1}.text`} />
                          </p>
                        </StyledBlogParaSub>
                      </>
                    ))
                  }

                  {textNumb.tag === 'noBottomLine' ?
                    <>
                      <Spacing pixel="128" />
                    </>
                    :
                    <>
                      <Spacing pixel="64" />
                      <BorderBottomLine />
                    </>}
                </StyledGridContainer>

              }
            </>
          )
        })}

      </StyledBlog>
      <TechRequest/>
    </>
  )
}

export default TechnologyModule; 