import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import './Default.styl';
import Nav from './components/NavBox/NavBox';
import Footer from './components/Footer/Footer';
import i18n from '@pages/i18n';

const Default = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/en/')) {
      i18n.changeLanguage('en');
    } else if (location.pathname.includes('/jp/')) {
      i18n.changeLanguage('jp');
    } else if (location.pathname.includes('/cn/')) {
      i18n.changeLanguage('cn');
    } else {
      i18n.changeLanguage('ko');
    }
  }, [location.pathname]);

  const checkRootPage = () => {
    if (location.pathname.includes('/apart')) {
      return true;
    } else {
      return false;
    }
  }


  const renderNavBox = () => {
    if (checkRootPage()) {
      return <Nav/>
    } else {
      return <Nav />
    }
  }


  const renderFooter = () => {
    if (checkRootPage()) {
      return <Footer/>
    } else {
      return <Footer />
    }
  }




  return (
    <div className="default-layout">
      {renderNavBox()}
      <div className="contents">
        <Outlet />
      </div>

      {renderFooter()}
    </div>
  );
};

export default Default;
