import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import React, { useEffect, useState } from "react";
import { BoxLayout, BtnBox, BtnCancel, CheckBox, OptionBox, OptionExam, Options, PricingPage, PricingParagraph2, 
  PricingSpacingBottom, ProgressBar, ProgressBarBox, ProgressNumber, SelectBox, SelectLayout, ShowMoreBtn, Width100HeightAuto } from "./Pricing.style";
import { StyledDisplay, StyledHeading, StyledLabel } from "@components/Text";
import { Trans } from "react-i18next";
import { StyledSpacing24 } from "@pages/Enterprise/Technology/StereoVision/components/SVContent/StereoVision.style";
import pricing from "@src/lang/ko/pricing/pricing";
import { IconCheckBoxEmpty, IconCheckBoxOn } from "@assets/images/icon";
import { useLocation, useNavigate } from "react-router-dom";
import navMenu from "@src/lang/ko/navMenu";
import seo from '@src/lang/ko/seo';
import seoEN from '@src/lang/en/seo';
import seoJP from '@src/lang/jp/seo';
import seoCN from '@src/lang/cn/seo';
import SEO from '@pages/SEO';
import axios from "axios";
import { ProgressedBarAnimation2_1, ProgressedBarAnimation2_2 } from "./BarAnimation";
import { HeadingColor, ImgWH, ImgWidth100, LabelColor, Spacing } from "@pages/CommonStyle/common.style";


const Pricing2 = (): React.ReactElement => {

  const [ipAddress, setIp] = useState();

  useEffect(() => {
    axios.get('https://geolocation-db.com/json/')
      .then((res) => {
        setIp(res.data.state)
      })
  }, [])


  const location = useLocation();
  const currentDateTime = new Date();

  const [currentLang, setCurrentLang] = useState<string>('ko');
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const selectedIndexes = location.state && location.state.selectedIndexes ? location.state.selectedIndexes : [];
  const selectedOptions = location.state && location.state.selectedOptions ? location.state.selectedOptions : [];
  const email = location.state && location.state.email ? location.state.email : '';
  const personalID = location.state && location.state.personalID ? location.state.personalID : '-';
  
  
  const name = location.state && location.state.name ? location.state.name : '';
  const phone = location.state && location.state.phone ? location.state.phone : '-';
  const memo = location.state && location.state.memo ? location.state.memo : '-';
  const schedule = location.state && location.state.schedule ? location.state.schedule : '-';
  const forward = location.state && location.state.forward ? location.state.forward : false;

  for (let i = 0; i < selectedIndexes.length; i++) {
    //alert(pricing.step2.product[selectedIndexes[i]].title);
  }

  const handleSelectBoxClick = (index) => {
    if (selectedProducts.includes(index)) {
      // 이미 선택된 경우, 해당 인덱스 제거
      setSelectedProducts(selectedProducts.filter((i) => i !== index));
    } else {
      // 선택되지 않은 경우, 해당 인덱스 추가
      setSelectedProducts([...selectedProducts, index]);
    }
  };
  useEffect(() => {
    if (location.pathname.includes('/en/')) {
      setCurrentLang('en');
    } else if (location.pathname.includes('jp/')) {
      setCurrentLang('jp');
    } else if (location.pathname.includes('/cn/')) {
      setCurrentLang('cn');
    } else {
      setCurrentLang('ko');
    }
  }, []);


  const navigate = useNavigate();
  const handleNextStep = () => {
    if (selectedProducts.length === 1 && selectedProducts.includes(5)) {
      notionSubmit(personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule);
      if (currentLang === 'ko') { navigate('/pricing/4', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule , forward:true } }); }
      else { navigate(`/${currentLang}/pricing/4`, { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward:true  } }); }
    }
    else if (selectedProducts.length > 0) {
      notionSubmit(personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule);
      if (currentLang === 'ko') { navigate('/pricing/3', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward:true  } }); }
      else { navigate(`/${currentLang}/pricing/3`, { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward:true  } }); }
    }
  }
  const handlePrevStep = () => {
    if (currentLang === 'ko') { navigate('/pricing/1', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward:false  } }); }
    else { navigate(`/${currentLang}/pricing/1`, { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward:false  } }); }
  }


  useEffect(() => {
    const updateIndex = location.state && location.state.selectedProducts ? location.state.selectedProducts : [];
    if (updateIndex.length > 0) {
      setSelectedProducts(updateIndex);
    }
  }, [location.pathname])

  // 데이터를 Notion에 전송하는 함수
  const notionSubmit = async (personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule) => {
    try {
      const response = await fetch("https://orbro.io/pricingNotion", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          personalID: personalID,
          industries: convertString(selectedIndexes),
          products: convertString2(selectedProducts),
          demand: convertString3(selectedProducts, selectedOptions),
          email: email,
          name: name,
          phone: phone,
          memo: memo,
          schedule: schedule,
          ipAddress : ipAddress,
          type : '-',
          currentTime: currentDateTime.toLocaleString('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // 24시간 형식
            timeZone: 'Asia/Seoul', // 한국 시간대
          })
        })
      });

    } catch (error) {
      console.log('error', error);
    }
  }

  const convertString = (selectedIndexes) => {
    const selectedIndustries = selectedIndexes.map(index => {
      return pricing.step1.industry[index].title;
    });
    return selectedIndustries.join(', ');
  }

  const convertString2 = (selectedProducts) => {
    const selectedProduct = selectedProducts.map(index => {
      return pricing.step2.product[index].title;
    });
    return selectedProduct.join(', ');
  }


  const convertString3 = (selectedProducts, selectedOptions) => {
    let combinedText = ''; // 문자열을 저장할 변수 초기화

    selectedProducts.forEach((i) => {
      selectedOptions[i].forEach((jData, j) => {
        jData.forEach((value, k) => {
          if (
            value === 1 &&
            j < pricing.step3.question[i].parts.length &&
            k < pricing.step3.question[i].parts[j].option.length
          ) {
            // 문자열을 추가
            combinedText += pricing.step3.question[i].parts[j].option[k].text + ' ';
            if (i !== selectedProducts.length - 1) {
              // i 값이 마지막이 아니면 쉼표를 추가
              combinedText += ', ';
            }
          }
        });
      });
    });

    return combinedText; // 결합된 문자열 반환
  };

  
  const [count, setCount] = useState(forward ? 0 : 62);
  useEffect(() => {
    const interval = setInterval(() => {
      if (count !== 38) {
        // forward 값에 따라 증가 또는 감소
        setCount(count + (forward ? 1 : -1));
      }
    }, forward ? 22 : 37); // 100ms 간격으로 증가
  
    return () => clearInterval(interval); // 컴포넌트 언마운트 시 타이머 정리
  }, [count, forward]);
  

  let seoPath;

  if (location.pathname.includes('/en/')) {
    seoPath = seoEN;
  } else if (location.pathname.includes('/jp/')) {
    seoPath = seoJP;
  } else if (location.pathname.includes('/cn/')) {
    seoPath = seoCN;
  } else {
    seoPath = seo;
  }
  const helmetTitle = seoPath.pricing.title;
  const ogKey = seoPath.pricing.key;
  const ogDesc = seoPath.pricing.desc;
  const ogImg = seoPath.pricing.img;
  const ogUrl = seoPath.pricing.url;
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "ORBRO",
    "description": `${ogDesc}`,
    "alternateName": `${helmetTitle}`,
    "url": `${ogUrl}`,
    "keywords": `${ogKey}`,
    "image": `${ogImg}`,
    "address": "#B-1203, 660, Daewangpangyo-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea",
    "email": "orbro@orbro.io",
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+8215229928",
      "contactType": "a sales contact point",
      "areaServed": "KR",
      "availableLanguage": "Korean"
    }],
    "sameAs": "https://kr.linkedin.com/company/kongtech"
  }

  return (
    <>
      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={ogKey}
        ogDesc={ogDesc}
        ogImg={ogImg}
        ogUrl={ogUrl}
        jsonLd={jsonLd}
      />
      <Spacing pixel="64"/>
      <StyledGridContainer>

        <StyledGridRow>
          <StyledGridColumn desktop={2} tablet={1} mobile={0}>

          </StyledGridColumn>
          <StyledGridColumn desktop={8} tablet={6} mobile={4}>

            <PricingPage>
              {/** 타이틀 **/}
              <StyledDisplay size={"small"}>
                <Trans i18nKey={`pricing.step2.title`} />
              </StyledDisplay>
              <StyledSpacing24 />
              <StyledSpacing24 />

              {/** 진행 바 **/}
              <ProgressBarBox>
                <ProgressNumber>
                  <HeadingColor size="xxsmall" color='#2A70F0'>
                    {count + "%"}
                  </HeadingColor>
                </ProgressNumber>
                <ProgressBar>
                  {forward ? <ProgressedBarAnimation2_1/> : <ProgressedBarAnimation2_2/>}
                </ProgressBar>

              </ProgressBarBox>

              {/** 옵션 선택 **/}
              <PricingSpacingBottom />
              <SelectLayout>
                {pricing.step2.product.map((data, i) => (
                  <>
                    <SelectBox key={i} onClick={() => handleSelectBoxClick(i)} isSelected={selectedProducts.includes(i)}>
                      <Options>
                        <ImgWH src={navMenu.product[i].icon} alt="ORBRO Products" w={""} h={"60"}/>
                      </Options>
                      <Width100HeightAuto>
                        <Spacing pixel="8"/>
                        <StyledHeading size="xxxsmall">
                          <Trans i18nKey={`pricing.step2.product.${i}.title`} />
                        </StyledHeading>
                      </Width100HeightAuto>
                      <OptionExam>
                        <PricingParagraph2 size={"small"}>
                          <Trans i18nKey={`pricing.step2.product.${i}.example`} />
                        </PricingParagraph2>
                      </OptionExam>
                      <Spacing pixel="16"/>
                      <OptionBox>
                        <a href={currentLang === 'ko' ?
                          navMenu.product[i].link !== 'none' ? `${navMenu.product[i].link}` : `${navMenu.product[i].path[0].link}` :
                          navMenu.product[i].link !== 'none' ? `/${currentLang}${navMenu.product[i].link}` : `/${currentLang}${navMenu.product[i].path[0].link}`}
                          target="_blank" rel="noopener noreferrer">
                          <ShowMoreBtn>
                            <StyledLabel size="small">
                              <Trans i18nKey={`pricing.step2.common`} />
                            </StyledLabel>
                          </ShowMoreBtn>
                        </a>
                      </OptionBox>
                      <CheckBox margin={true}>
                        <ImgWidth100 src={selectedProducts.includes(i) ? IconCheckBoxOn : IconCheckBoxEmpty} alt="checkbox"/>
                      </CheckBox>

                    </SelectBox>

                  </>
                ))}
              </SelectLayout>

              {/** 다음 버튼 **/}
              <Spacing pixel="64"/>
              <BoxLayout>
                <BtnCancel onClick={() => handlePrevStep()}>
                  <StyledLabel size="large">
                    <Trans i18nKey={`pricing.step2.prev`} />
                  </StyledLabel>
                </BtnCancel>
                <BtnBox isOk={selectedProducts.length > 0} onClick={() => handleNextStep()}>
                  <LabelColor size="large" color='#FFF'>
                    <Trans i18nKey={`pricing.step2.next`} />
                  </LabelColor>
                </BtnBox>
              </BoxLayout>
              <Spacing pixel="64"/>
            </PricingPage>
          </StyledGridColumn>

        </StyledGridRow>
      </StyledGridContainer>

    </>
  )
};

export default Pricing2;


//
//<Product/>