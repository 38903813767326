import { IconBlueCircle, IconBlueCycle, IconBlueDevelop, IconBlueFigure, IconBluePlus, IconBluePower, IconBlueScan, IconBlueShield } from "@assets/images/main/icon/blue";
import { osBanner, osLogoDesktop, osLogoMobile, osLogoTablet } from "@assets/images/main/os/bannerImg";
import {
  OsSectionDesktop1, OsSectionTablet1, OsSectionMobile1,
  OsSectionDesktop2, OsSectionTablet2, OsSectionMobile2,
  OsSectionDesktop3, OsSectionTablet3, OsSectionMobile3,
  OsSectionDesktop4, OsSectionTablet4, OsSectionMobile4,
  OsSectionDesktop5, OsSectionTablet5, OsSectionMobile5,
  OsSectionDesktop6, OsSectionTablet6, OsSectionMobile6,
  OsSectionDesktop7, OsSectionTablet7, OsSectionMobile7,
  OsSectionDesktop8, OsSectionTablet8, OsSectionMobile8,
  OsSectionDesktop9, OsSectionTablet9, OsSectionMobile9,
  OsSectionDesktop10, OsSectionTablet10, OsSectionMobile10,
  OsSectionDesktop11, OsSectionTablet11, OsSectionMobile11,
  OsSectionDesktop12, OsSectionTablet12, OsSectionMobile12,
  OsSectionDesktop13, OsSectionTablet13, OsSectionMobile13,
  OsSectionDesktop14, OsSectionTablet14, OsSectionMobile14,
  OsSectionDesktop15, OsSectionTablet15, OsSectionMobile15,
  OsSectionDesktop16, OsSectionTablet16, OsSectionMobile16,
  OsSectionDesktop17, OsSectionTablet17, OsSectionMobile17,
  OsTextImg1, OsTextImg2, OsTextImg3, OsTextImg4,
} from "@assets/images/main/os/section";



export default {

  title: 'Digital Twin Space Management Platform',
  osLogo: {
    desktop: osLogoDesktop,
    tablet: osLogoTablet,
    mobile: osLogoMobile
  },

  bannerImg: osBanner,
  section1: {
    img: OsTextImg1,
    title: 'Managing Spaces like Reality, Space Management',
    desc: 'You can set specific areas in a virtual space identical to real space.<br/>Manage people counting, danger zones, and reverse driving detection zones.',
    box: [
      {
        size: 1,
        title: 'Simple Zone Setup',
        desc: 'Easily set up areas you want to manage and monitor<br/>to obtain the data you want to track.',
        img: {
          desktop: OsSectionDesktop1,
          tablet: OsSectionTablet1,
          mobile: OsSectionMobile1,
        },
      },
      {
        size: 2,
        title: 'Danger Zone Setup and Alert',
        desc: 'Set danger zones and send alerts when unauthorized individuals access them.',
        img: {
          desktop: OsSectionDesktop2,
          tablet: OsSectionTablet2,
          mobile: OsSectionMobile2,
        },
      },
      {
        size: 2,
        title: 'People Counting',
        desc: 'Extract entry and exit data of people and assets in specific areas.',
        img: {
          desktop: OsSectionDesktop3,
          tablet: OsSectionTablet3,
          mobile: OsSectionMobile3,
        },
      },
      {
        size: 2,
        title: 'Reverse Driving Detection',
        desc: 'Specify one direction in a specific area and detect objects and items moving in the opposite direction, sending alerts.',
        img: {
          desktop: OsSectionDesktop4,
          tablet: OsSectionTablet4,
          mobile: OsSectionMobile4,
        },
      },
      {
        size: 2,
        title: 'Inflow Tracking by Time Zone',
        desc: 'Store data of objects and people entering the set area by time zone and provide data.',
        img: {
          desktop: OsSectionDesktop5,
          tablet: OsSectionTablet5,
          mobile: OsSectionMobile5,
        },
      },
    ]
  },

  section2: {
    img: OsTextImg2,
    title: 'Real-Time Data Management',
    desc: 'Monitor real-life events in virtual space in real-time.<br/>Essential data can be searched for or received for use.',
    box: [
      {
        size: 2,
        title: 'Real-Time Location Tracking',
        desc: 'Collect and analyze real-time data from connected Orbro devices to support quick situation awareness and decision-making.',
        img: {
          desktop: OsSectionDesktop6,
          tablet: OsSectionTablet6,
          mobile: OsSectionMobile6,
        },
      },
      {
        size: 2,
        title: 'Data Collection',
        desc: 'You can monitor data collected by installed devices, including IoT devices, in real-time.',
        img: {
          desktop: OsSectionDesktop7,
          tablet: OsSectionTablet7,
          mobile: OsSectionMobile7,
        },
      },
      {
        size: 2,
        title: 'Device Search',
        desc: 'Search for where objects, such as assets or people, are located in real-time.',
        img: {
          desktop: OsSectionDesktop8,
          tablet: OsSectionTablet8,
          mobile: OsSectionMobile8,
        },
      },
      {
        size: 2,
        title: 'Data Download',
        desc: 'Detect anomalies early through real-time data analysis and send alerts for early response.',
        img: {
          desktop: OsSectionDesktop9,
          tablet: OsSectionTablet9,
          mobile: OsSectionMobile9,
        },
      },
    ]
  },

  section3: {
    img: OsTextImg3,
    title: 'Convenient Management Features',
    desc: 'Monitor real-life events in virtual space in real-time.<br/>Essential data can be searched for or received for use.',
    box: [
      {
        size: 2,
        title: 'Situation-Specific Algorithm Application',
        desc: 'Change Orbro algorithms developed for various spaces to obtain optimal data.',
        img: {
          desktop: OsSectionDesktop10,
          tablet: OsSectionTablet10,
          mobile: OsSectionMobile10,
        },
      },
      {
        size: 2,
        title: 'Device Management',
        desc: 'It has a flexible structure to effectively manage IoT devices, making it easy to add new devices or upgrade existing systems.',
        img: {
          desktop: OsSectionDesktop11,
          tablet: OsSectionTablet11,
          mobile: OsSectionMobile11,
        },
      },
      {
        size: 2,
        title: 'Custom Dashboard',
        desc: 'Custom dashboards provide users with insights to easily monitor important data and make effective decisions.',
        img: {
          desktop: OsSectionDesktop12,
          tablet: OsSectionTablet12,
          mobile: OsSectionMobile12,
        },
      },
      {
        size: 2,
        title: 'Mobile Widget Provision',
        desc: 'Through widget provision, users can conveniently check various data without launching the app and have quick access to the app.',
        img: {
          desktop: OsSectionDesktop13,
          tablet: OsSectionTablet13,
          mobile: OsSectionMobile13,
        },
      },
    ]
  },

  section4: {
    img: OsTextImg4,
    title: 'Integrated OS in One System',
    desc: 'Orbro OS is powerful and convenient without being complex.<br/>You can easily set up and use any product.',
    box: [
      {
        link: '/rtls',
        size: 2,
        title: 'Location Tracking System',
        desc: 'Register products installed with UWB, BLE, beacons, etc., in Orbro OS.',
        img: {
          desktop: OsSectionDesktop14,
          tablet: OsSectionTablet14,
          mobile: OsSectionMobile14,
        },
      },
      {
        link: '/sensor/LoRaGateway',
        size: 2,
        title: 'Industrial IoT',
        desc: 'Detect anomalies early through real-time data analysis and send alerts for early response.',
        img: {
          desktop: OsSectionDesktop15,
          tablet: OsSectionTablet15,
          mobile: OsSectionMobile15,
        },
      },
      {
        link: '/access',
        size: 2,
        title: 'Access System',
        desc: 'Register and collect access data with the Orbro access system.',
        img: {
          desktop: OsSectionDesktop16,
          tablet: OsSectionTablet16,
          mobile: OsSectionMobile16,
        },
      },
      {
        link: '/camera',
        size: 2,
        title: 'AI Camera Location Tracking',
        desc: 'Easily map installed cameras in real space to a virtual map.',
        img: {
          desktop: OsSectionDesktop17,
          tablet: OsSectionTablet17,
          mobile: OsSectionMobile17,
        },
      },
    ]
  },

  function: {
    title: 'Various Other Features',
    box: [
      {
        icon: IconBlueScan,
        title: 'Real-Time Data Analysis and Visualization',
        desc: 'OS can analyze and visualize collected data, allowing users to understand and utilize collected information in real-time through digital twins.',
      },
      {
        icon: IconBlueShield,
        title: 'Security and Personal Data Protection',
        desc: 'It will provide strong security features to protect digital twins. Limit access to sensitive information and prevent unauthorized modification of data to minimize security issues.',
      },
      {
        icon: IconBlueDevelop,
        title: 'Developer Friendly',
        desc: 'It will be developed to allow developers to easily utilize and extend digital twin functionality. Provide powerful APIs and development tools to develop new applications and services.',
      },
      {
        icon: IconBlueFigure,
        title: 'Integration of Artificial Intelligence',
        desc: 'Integrate artificial intelligence (AI) technology to manage and optimize digital twins more intelligently. Use AI algorithms to analyze data and build predictive models to enhance system performance.',
      },
      {
        icon: IconBluePower,
        title: 'Environmental Monitoring and Intelligent Energy Management',
        desc: 'Integrate environmental monitoring sensors to optimize energy consumption and resource usage. Monitor environmental conditions in real-time and automatically adjust systems as needed to maximize energy efficiency.',
      },
      {
        icon: IconBlueCycle,
        title: 'Automation and Smart Control',
        desc: 'Based on the information collected through digital twins, automated decisions can be made. For example, control devices or execute processes automatically when certain conditions are met.',
      },
      {
        icon: IconBluePlus,
        title: 'Customizable Interface',
        desc: 'It will provide features to customize interfaces according to user needs. Users can freely configure dashboards or applications for monitoring and controlling digital twins.',
      },
      {
        icon: IconBlueCircle,
        title: 'Ecosystem Scalability',
        desc: 'Build an ecosystem where developers and companies can develop their own add-ons or plugins to extend the system. This allows providing customized solutions for various industries and application areas.',
      },
    ]
  },


  beacon: {
    index: 4
  },
  rtls: {
    index: 1
  },
  camera: {
    index: 2
  },
  access: {
    index: 3
  },
  sensor: {
    index: 4
  },
  integration: {
    index: 1
  },
}
