export default {
  table: {
    header: {
      name: '',
      uwb: 'UWB',
      ble: 'BLE',
      wifi: 'WI-FI'
    },
    accuracy: {
      name: '정확도',
      uwb: '10 - 50cm',
      ble: '5m 미만',
      wifi: '10m 미만'
    },
    optimumRange: {
      name: '최적 범위',
      uwb: '10 - 50cm',
      ble: '0-25m',
      wifi: '0-50m'
    },
    maximumRange: {
      name: '최대 범위',
      uwb: '200m',
      ble: '100m',
      wifi: '500m'
    },
    latency: {
      name: '지연 시간',
      uwb: '1ms',
      ble: '3~5초',
      wifi: '3~5초'
    },
    power: {
      name: '전력소비',
      uwb: '낮음',
      ble: '매우 낮음',
      wifi: '보통'
    },
    value: {
      name: '비용',
      uwb: '₩₩',
      ble: '₩₩',
      wifi: '₩₩₩'
    },
    frequency: {
      name: '주파수',
      uwb: '3.1~10.6GHz',
      ble: '2.4GHz',
      wifi: '2.4GHz, 5GHz'
    },
    dataRate: {
      name: '속도',
      uwb: '최대 27Mbps',
      ble: '최대 2Mbps',
      wifi: '최대 1GBps'
    },
  },
  request: {
    title: '솔루션 도입이 필요하세요?',
    description: '솔루션 도입 및 데모 요청이 필요하신가요? <br/> 언제든지 연락해주시면 빨리 연락드리겠습니다.',
    button: '영업팀 문의하기'
  }
}