import { CultureImg1Desktop, CultureImg1Mobile, CultureImg1Tablet, CultureImg2Desktop, CultureImg2Mobile, CultureImg2Tablet, CultureImg3Desktop, CultureImg3Mobile, CultureImg3Tablet, CultureImg4Desktop, CultureImg4Mobile, CultureImg4Tablet, CultureMainDesktop, CultureMainMobile, CultureMainTablet } from "@assets/images/company";
import { CONTENT_DARK_PRIMARY } from "@assets/styled/color.style";
import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledHeading } from "@components/Text";
import { CommonState } from "@redux/reducers/commonReducer";
import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import OurCultureCard from "./components/OurCultureCard";
import { StyledOurCulture, StyledOurCultureDescription, StyledOurCultureDisplay, StyledOurCultureMainImg, StyledOurCultureRow } from "./OurCulture.style";

interface ImgLayout {
  desktop: string,
  tablet: string,
  mobile: string
}

const OurCulture = () => {
  // layoutType
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  // MainImg
  const MainImg:ImgLayout = useMemo(() => ({
    desktop: CultureMainDesktop,
    tablet: CultureMainTablet, 
    mobile: CultureMainMobile
  }), []);
  
  // Culture Img
  const discovery:ImgLayout = useMemo(() => ({
    desktop: CultureImg1Desktop,
    tablet: CultureImg1Tablet,
    mobile: CultureImg1Mobile,
  }), []);

  const extension:ImgLayout = useMemo(() => ({
    desktop: CultureImg2Desktop,
    tablet: CultureImg2Tablet,
    mobile: CultureImg2Mobile,
  }), []);

  const collaboration:ImgLayout = useMemo(() => ({
    desktop: CultureImg3Desktop,
    tablet: CultureImg3Tablet,
    mobile: CultureImg3Mobile,
  }), []);

  const refine:ImgLayout = useMemo(() => ({
    desktop: CultureImg4Desktop,
    tablet: CultureImg4Tablet,
    mobile: CultureImg4Mobile,
  }), []);

  return (
    <StyledOurCulture>
      <StyledGridContainer>
        <StyledHeading size="xxsmall" color={CONTENT_DARK_PRIMARY}>
          <Trans i18nKey={"company.ourCulture.subtitle"} />
        </StyledHeading>

        <StyledOurCultureDisplay size="medium" color={CONTENT_DARK_PRIMARY}>
          <Trans i18nKey={"company.ourCulture.title"} />
        </StyledOurCultureDisplay>

        <StyledOurCultureDescription size="xxsmall" color={CONTENT_DARK_PRIMARY}>
          <Trans i18nKey={"company.ourCulture.description"} />
        </StyledOurCultureDescription>

        <StyledOurCultureMainImg src={MainImg[layoutType]} alt="ORBRO Company Image"/>

        <StyledOurCultureRow>
          <OurCultureCard 
            title={<Trans i18nKey={"company.ourCulture.discovery.title"} />}
            description={<Trans i18nKey={"company.ourCulture.discovery.description"} />}
            img={discovery[layoutType]}
          />

          <OurCultureCard 
            title={<Trans i18nKey={"company.ourCulture.extension.title"} />}
            description={<Trans i18nKey={"company.ourCulture.extension.description"} />}
            img={extension[layoutType]}
          />

          <OurCultureCard 
            title={<Trans i18nKey={"company.ourCulture.collaboration.title"} />}
            description={<Trans i18nKey={"company.ourCulture.collaboration.description"} />}
            img={collaboration[layoutType]}
          />

          <OurCultureCard 
            title={<Trans i18nKey={"company.ourCulture.refine.title"} />}
            description={<Trans i18nKey={"company.ourCulture.refine.description"} />}
            img={refine[layoutType]}
          />
        </StyledOurCultureRow>
      </StyledGridContainer>
    </StyledOurCulture>
  );
};

export default OurCulture;