import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

export default {
    helmet: '| UWB RTLS, The Beginning of the Future Industry',
    desc: 'UWB RTLS (Ultra-Wideband Real-Time Location System) technology enables the digitalization of industrial sites to track and manage people and objects. With high accuracy and real-time data updates, it facilitates business growth.',
    keywords: 'uwb, rtls, uwb rtls, smart logistics, smart construction, smart manufacturing, smart processes, smart factory, digital transformation, digital twin, real-time location tracking, rtls, orbro, contech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'UWB RTLS, The Beginning of the Future Industry :: Orbro Blog',
    ogUrl: 'https://orbro.io/en/blog/uwbRtls',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDyMvH9u0-21RYnyyBVVc9guqVzCzv-ggtVgPCRQTLkEU33FS2rG9s1BImAzHdqx3slOcbgGEGcFnTwDAmmi0nBR-jQfAdavlymzwvoyWDbTWljNw2DPoaW_qaO4ciYcfvrO7YnaeT_zAYoHLLULjh7MSKToInBek_3WIXpnTfHBz83o4ryfNamREq7rU9e4ET1GsYzLjQ-33tSLxqcb1f1RUYR4qlgVm7ntqiwd4jyEZlj-33EJPgyvj3R-QwTvkFl1Ha2Z1oL3YBwdk33ugYt5wzdW-3IJa9T1gM6FWZbPZdnGWL0xDRYYzh0OTD7sckAN2dXqBwWWAqEjdyTKN6AfoQibnezcJUsWa12l8mton2PeSWa45J0NarK5Vrkbc_qrQzoC9iJ209QlewQrjmeCzUN2E9gIK8_O_eQ_seXYgBf-nQWy5BD5fX2goEJBEYQECwdmfhmRPWwkbQC8rXsKBhnXCZ9t3t65Dpn7Voj_tUI2w0wMMlJcsEpEpUdsd4hx6FrX5t4pHmpaJzEB5DxjLofbzSQXqEQcEQvkocvpBQ6HI9XVC5MFlwkonW-DUk_EwFlNGoCZYnZFFQjU-pK07aYDuZA4uvClTrr2OrxvC6iX2rVhJ01oGwN071DybCKXVsScEP4pQyzVkgMsbrOwoLXWRnDA5bl7_-nz4w1_PYbHs7kCJu14Zw1jaBhCb3vgIyFJPMHGaFGLUdtv00ej-qe1NVeGs5SGo8qkMOrkmAVxHx7cqR1ZqqeTvVy6xGAFPhJGvdZRlOV6T1M9HJ3a25z1couxxGM4TnjUSFrKXfhe--PNrqZxiX-4Yia8psajdHdqgD4I4KT6ryESC2umjrIiEI7oQyok08ry7UKuswV2OlxgJXmJYFOr6hBvriet1T1K8N7sko5tDVIDEyoVyGkGasOAifm6XgUZO3cbNLmvYib5LoKMKDFyldZpUxLWQ2yiI0ux0k-c303GbZ8h5nw-OP8JQekoR5do0QHpENpLqonOuBwnQZiBGmH2BGHHTYXGDNIl5s3QYqmWKNRQUMMvAau7nPXgQvmvJs3xRpcmPKFy1x149cg4cTWVvIL99qqF8lfTu8Dr0r2ycGSIm0NDz6WsrMA6cUDNEWZLdCFxEZMl6T7U2IFXBW0CLDmJ9UONldp_cQHYA-aIojbGabali2Q3YgwAKBlxyIytx5azfiIrUy3w7YzUE8BGiWS9faNVbZqk7TfUxA0mevS00orIB7fBajjFpHnkRPXLsXlm0d6pZTBHMzyu1s77gT-82xJSJVvvkHA3SwtYpap0571i6g8VCzO_OkwDlxeQkY9P_BbcVYzEUxoeIrASHDZuuhT2rkJ1qOY0UbdZlGS5KOOO6R10Wi0z8p-SXJQRT9kOR1N2mhdGk_Z_rag_ptC5lHxc7dYZaT2NCqxEVI-GZa3fHcvyge6Gu0OzWP0o2N8x_8XpdTaJuMTcUd-vUlQdg-o1YKkmcoqzVjfZVLzqDF7tsS3KS7hO8cTL23IfERiTLhbOxtCkJTdtHTXhXVrgakFVdWBWUlxMxDP_Yyk12v-Dozi2pwBSbMdRIEJ1xvD4akO3=w2000-h1596',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'UWB RTLS, The Beginning of the Future Industry',
    date: '2023-11-03',
    tag1: '#uwb',
    tag2: '#rtls',
    tag3: '#digitaltwin',
    tag4: '#digitaltransformation',

    text: [
        {
            head: '',
            subHead: '',
            desc: 'In recent years, Ultra-Wideband (UWB) Real-Time Location System (RTLS) technology has gained rapid attention in various industries. This technology brings innovative changes to the field of location tracking and location-based services and is expected to become a future solution. In this article, we will explore what UWB RTLS technology is, its applications, potential advantages, and how it can further develop in the future.',
            dot: ''
        },
        {
            head: 'What Is UWB RTLS?',
            subHead: '',
            desc: 'UWB RTLS is a real-time location tracking system that provides precise location data using UWB technology. UWB uses ultra-wide bandwidth to transmit data, allowing for extremely accurate location information. This technology offers much higher accuracy and real-time capabilities compared to traditional location tracking systems like GPS or Wi-Fi.',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/en/enterprise/manufacturing/process',
            head: 'Applications',
            subHead: '',
            desc: '<strong>Construction and Building Industry: </strong>In construction sites, it can track the location of buildings, equipment, and materials to optimize work schedules and enhance safety. Additionally, it can implement smart building systems with high indoor location accuracy.<br/><br/><strong>Retail and Distribution: </strong>Retail and distribution businesses can track the location and inventory of products in real-time, improving customer service and inventory management. It can also optimize product placement by analyzing customer purchasing behavior.<br/><br/><strong>Transportation and Land Management: </strong>Tracking the location of vehicles, trains, and other transportation means in road and railway networks can help manage traffic congestion and prevent accidents. Land management departments can efficiently allocate resources by managing location data for terrain and resources.<br/><br/><strong>Security and Safety Management: </strong>Utilizing UWB RTLS in critical facilities, events, or large gatherings can track the location of people and objects to enhance security and safety. This enables a quick response in emergencies.<br/><br/><strong>Sports and Entertainment: </strong>In sports stadiums and event venues, tracking the location of the audience can optimize event organization and provide guidance services. It can also accurately determine the location of cameras and lighting equipment for media production.',
            dot: ''
        },
        {
            head: 'Advantages',
            subHead: '',
            desc: `<strong>High Accuracy: </strong>UWB technology provides much higher accuracy compared to other wireless technologies. It is based on distance measurements between sensors, allowing precise location data in both indoor and outdoor environments.<br/><br/><strong>Low Latency: </strong>UWB offers fast response times, allowing for rapid acquisition of real-time location information. This enhances the system's responsiveness and usefulness in emergency situations.<br/><br/><strong>Support for Multiple Users: </strong>UWB can track multiple users or objects simultaneously, which is crucial in large-scale events or industrial environments.<br/><br/><strong>Safety and Security: </strong>UWB minimizes interference with other wireless communication technologies and provides a high level of security. This ensures the safety of sensitive data and systems.<br/><br/><strong>Indoor and Outdoor Use: </strong>UWB RTLS is suitable for both indoor and outdoor environments, making it versatile for various industries.<br/><br/><strong>Low Power Operation: </strong>UWB technology supports low-power wireless communication, extending battery life and increasing energy efficiency.`,
            dot: ''
        },
        {
            head: 'Future Outlook',
            subHead: '',
            desc: 'UWB RTLS technology is expected to play an even more significant role in the future. It is anticipated that more devices and systems will utilize UWB for interconnectivity in smart cities, smart factories, autonomous vehicles, healthcare, and various other fields. This is expected to lead to improved productivity, enhanced safety, environmental improvements, and increased convenience.<br/><br/>In summary, UWB RTLS is gaining attention as one of the technologies that will shape the future of solutions. Considering its stability, accuracy, and its applicability in various industries, this technology is expected to have a positive impact on our daily lives and various industries. The development and widespread use of this technology will make the future smarter and more efficient.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'This product helps with real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/en/enterprise/construction/safety'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/en/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/en/enterprise/manufacturing/asset'
            }
        ]

    }
}
