import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {


  helmet: '| 利用UWB技术打造智能物流中心',
  desc: '智能物流中心在现代物流和供应链管理中发挥着关键作用。速度、准确性和效率是所有物流操作的关键要素之一，优化这些因素对于企业的成功和竞争力至关重要。',
  keywords: '大型物流仓库管理、物流中心管理、物流仓库管理、智能物流、库存管理、智能库存管理、物流仓库库存管理、大型物流仓库库存管理、实时位置跟踪、rtls、orbro、康泰克、超宽带、uwb',

  ogTitle: '',
  ogDesc: '',
  ogSite: '利用UWB技术打造智能物流中心 :: Orbro博客',
  ogUrl: 'https://orbro.io/cn/blog/uwbLogistics',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4PMiWc5DIFUhcXlBfDRp2o8d3EJ_Z4ffawsJSoDWT70JslXvsXOw5KgNd3q5wgkx3TUzMx42JtuNcCYfLVsl4W8GEIevw-Q84jKo4Nm-Bu0CdH4qgYaA6OwgyC4paHI32-VbALd50n6lLWH0CNWUBFGOgkoXzFpchLLz2INpLMFbzPNsPlnWPK1V9vPzvJlVDr6GdB4SKKQM0XHaIbQFHd9xwu4quaqFpAPoJ6HMEZb_-PMBnKc0D5CSBQ0cBlJqHFHacrj-TPxhfLG1QeDfnIkE25ooGaiwz76DMvLqIqnCBcYPYsStKF8weqz_g5Sh3LyHpvveZL2GPOiRD0SvaNatRUPDeyK5_11bB9t42SpjZZuEYUHIb-ivBBOXxdCGJt4MDAl3pcM65PWS1DDBqUcncZ1uHo8_1b5wHawqsOZ6F6H5hFzzLKZHEnfibNYE0UgKoBvRHlCqz5y6SPTqcRTsaUzu0aj7LRETSi4adhGjvfgOQ0mILavMKp9ei41wAcC7zWl67f5uYGBTU9z-Sq8C0ncIG_tUmKIf3-bXeS0dBjJyYZQCPSytqKSFAPWzcxUHqp7GKdX6cFyMRkop-S-SzwB0spx8E_3wdecdTkX3H6TX_uSGKaFOmSZbMrm6YZaEJFUrDH7Zl6RAx-sIJoDA1qoEx4ULktfnVpEt-DaFyVJDuiY-qC54aoH8YJ-FHWixIFG6BpVGCp6pME79yITG3fDMVFfRfSnz32WTrpb_8htFe2iu3M4d8NRRm48c5ovfiTkWMRNLFRdSkRPDZ0qGVEs6FSU-UrGu95_QwTHwqWWceSPTdxRiPAMtR2x1zyg7au0pLgIDeVoChJuZ9nE6t6659ZBQsZnWHA_DOSrdWzMPvdrr7qTj5_byxnTgW0vC8flxGkf0I8g8DR3cWtJ7tWVMQpUwTh5dOl_7fQ62ZRN9fgnXvx-GKnP1ZkEz3Vzuz4Z4PPVAmS7gOHS8L-PMTJ9WF7-mwLVknLpgxrlSGhYuW4ognx12nU0yiuSDxPKLq-jS5DFHHeIN6O4SQtVgRnga99vTOi9QmhV_pWMXbuvhzEhbGMgxkHTLZV_iwH0PTl6MWgJAG2HOaUSAT9uZ71Kvv6RWyt_VLkET4AuJ480_wC7DHvDneJqb7pgtUZpbVa_aYXNB5AouMSyFVoHrsGRLZTJ1vhiKdQgfy5exsXJY2e6qCVrSpsVYQufijgQ3dc4PjAGJ8IY8krq2igby7D2AGnRDmJO6q7zwf7hNktGXBM4jR6YcUh1GbRmzm4Ios1p7z3EaKsY59m7zLrNjbZHA6o2Dcgf2cxFNBfwMiShDmtQSMQZHzFEY4x6PCByTF_Pb0tKe1Pu5iuiUm-HYkboa8BEWXYjFVvEc05axWugdX6zrW4ijr0xd_xMSZRCmZ0lXPBOekY03zX5ajinw6TRDfQo3l5HqEmCXgAZKlWZAgfBp67huSzzosSWfm2dY9b4bTGfgrfKZTeu9IJx8El-7m7PcTU4Bv-g584Bp0wcyv2tjG2aDCIz1BgGuXW7oNyImmGFxwWArxN0_Xk=w2000-h1946',
  ogType: '网站',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '利用UWB技术打造智能物流中心',
  date: '2023-09-05',
  tag1: '#大型物流中心',
  tag2: '#智能物流',
  tag3: '#库存管理',
  tag4: '#智能库存',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: '智能物流中心在现代物流和供应链管理中发挥着关键作用。速度、准确性和效率是所有物流操作的关键要素之一，优化这些因素对于企业的成功和竞争力至关重要。超宽带（UWB）技术正逐渐成为应对这些挑战的现代解决方案。在本文中，我们将探讨如何利用UWB技术创建智能物流中心以及其优势。',
      dot: ''

    },
    {
      head: '',
      subHead: '什么是UWB技术？',
      desc: 'UWB技术支持使用非常宽的带宽进行无线通信。这种宽带对于快速传输数据和精确跟踪位置非常有用。UWB技术以毫米级精度精确跟踪位置而闻名，使其成为在物流中心具有无限应用潜力的多功能技术。以下，我们将更详细地探讨智能物流中心的优势。',
      dot: ''

    },
    {
      head: '利用UWB技术的智能物流中心的优势',
      subHead: '',
      desc: '',
      dot: {
        a: '• <strong>精确的位置跟踪</strong>：使用UWB技术，您可以实时跟踪物流中心内所有物体的精确位置。这提高了库存管理、物品移动和操作流程的准确性和效率。',
        b: '• <strong>自动化和机器人支持</strong>：在智能物流中心中有效运行机器人和自动化设备至关重要。UWB技术可以实现与机器人和自动化设备的实时通信，增强了流程自动化。',
        c: '• <strong>增强的安全性和安全性</strong>：UWB可用于监视物流中心内的所有活动，并与各种安全和安全系统集成，包括物品移动、访问控制和火警检测。',
        d: '• <strong>实时数据分析</strong>：UWB技术允许实时数据采集和分析，支持物流操作的实时决策，有助于流程优化。',
        e: '• <strong>能源效率</strong>：UWB技术精确跟踪物体的位置，最小化能源消耗。它允许更高效的资源利用和构建环保的物流中心。',
      }
    },
    {
      head: 'UWB技术在物流中心的应用',
      subHead: '',
      desc: '',
      dot: {
        a: '• <strong>库存管理</strong>：准确跟踪物流中心内的库存有助于防止库存错误，降低库存成本。',
        b: '• <strong>重型设备管理</strong>：跟踪和管理物流操作中使用的重型设备变得更加容易，还可以预防事故。',
        c: '• <strong>员工管理</strong>：实时跟踪员工的位置，可以更有效地安排人员，并迅速应对安全事故。',
        d: '• <strong>自动进出库</strong>：自动记录特定资产通过特定区域的时间，从而减少不必要的人员。',
        e: '• <strong>汽车装配线</strong>：在汽车制造工厂中使用UWB技术，可以跟踪汽车零件的准确位置，提高生产效率。',
        f: '• <strong>无人机</strong>：在物流中心内使用无人机可以实现高效的产品运输和监控。',
      }
    },

    {
      head: '',
      subHead: '结论',
      desc: 'UWB技术推动着智能物流中心的创新，并为物流和供应链管理带来重大变革。精确的位置跟踪、自动化支持、安全性提升、能源效率和实时数据分析等优势使企业能够增强竞争力，并实现高效的物流操作。积极采用UWB技术构建智能物流中心的企业将在未来取得更大的成功。',
      dot: ''
    },

  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: '此产品可将UWB设备附加到工作人员或设备上，以实时跟踪其位置。',
        img: productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution: [
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      },
      {
        img: logisticsSafety.thumb,
        data: 'logisticsSafety',
        link: '/cn/enterprise/logistics/safety'
      },
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/cn/enterprise/manufacturing/asset'
      }
    ]

  }


}
