import { CompanyVisualDesktop, CompanyVisualMobile, CompanyVisualTablet } from "@assets/images/company";
import { StyledGridColumn, StyledGridContainer } from "@components/Grid/Grid.style";
import { CommonState } from "@redux/reducers/commonReducer";
import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { StyledVisual, StyledVisualItem, StyledVisualItemLabel, StyledVisualItems, StyledVisualItemTitle, StyledVisualMain, StyledVisualMainDescription, StyledVisualMainDisplay, StyledVisualMainHeading, StyledVisualMainInfo } from "./Visual.style";
import company from "@src/lang/ko/company";
import { DisplayH1 } from "@components/Text/HeadingTagDisplay.style";
import { Spacing } from "@pages/CommonStyle/common.style";
const Visual = (): React.ReactElement => {
  // Selector
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);


  // Items 
  const items = useMemo(() => company.visual.items, []);
  const images = useMemo(() => ({
    desktop: CompanyVisualDesktop,
    tablet: CompanyVisualTablet,
    mobile: CompanyVisualMobile
  }), []);

  return (
    <StyledVisual img={images[layoutType]}>
      <StyledGridContainer>

        <StyledVisualMain>
          <StyledGridColumn desktop={6} tablet={6} mobile={4}>

            <StyledVisualMainInfo>
              <StyledVisualMainHeading size="xxsmall">
                <Trans i18nKey={"company.visual.subtitle"} />
              </StyledVisualMainHeading>
              
              <Spacing pixel="16"/>
              <DisplayH1 size="large">
                <Trans i18nKey={"company.visual.title"} />
              </DisplayH1>

              <StyledVisualMainDescription size="large">
                <Trans i18nKey={"company.visual.description"} />
              </StyledVisualMainDescription>
            </StyledVisualMainInfo>

          </StyledGridColumn>
        </StyledVisualMain>


        <StyledVisualItems>
          {items.map((item, index) => (
            <StyledVisualItem key={`company-visual-item-${index}`} desktop={4} tablet={4} mobile={4}>
              <div>
                <StyledVisualItemTitle size="small"><Trans i18nKey={`company.visual.items.${index}.title`} /></StyledVisualItemTitle>
                <StyledVisualItemLabel size="medium"><Trans i18nKey={`company.visual.items.${index}.description`} /></StyledVisualItemLabel>
              </div>
            </StyledVisualItem>
          ))}
        </StyledVisualItems>

      </StyledGridContainer>
    </StyledVisual>
  );
};

export default Visual;