export default {
  visual: {
    subtitle: 'Our Vision',
    title: '생동감 있는 <br/> 가상세계 구현.',
    description: '오브로는 전 세계에 존재하는 약 200제타바이트의 데이터를 눈으로 보기 위하여 만들어 졌습니다. 지속 가능한 데이터 생태계를 위하여 우리는 지구의 정보를 오브로에 복사하고 있으며, 3D로 형상화된 오브젝트를 통해 혁신적인 데이터 연결을 만들어 내고 있습니다. <br/><br/> 오브로에는 전용 제품에서 수집된 위치정보와 온라인에 흩어져 있는 정보가 연결되어 실시간으로 2만개 이상의 오브젝트가 움직이고 있습니다. 우리는 이 속도를 가속화 하기 위하여 오픈된 정책을 통해 전 세계 사람들이 참여 가능한 생태계를 구축하고 있습니다. <br/><br/> 오브로에서 나만의 세계를 구축하고 디지털 전환에 동참하세요.',
    items: [
      {
        title: '세계 최초',
        description: 'Web 기반의 가상세계 플랫폼'
      },
      {
        title: '세계 최대',
        description: '현실과 가상의 정보가 연결된 플랫폼'
      },
      {
        title: '500억+',
        description: '아시아 최대 규모의 분석된 위치 정보 데이터'
      },
      {
        title: '1위',
        description: '산업용 메타버스 실적 (한국 기준)'
      },
      {
        title: '150,000+',
        description: '오브로 제품 판매량'
      },
      {
        title: '500,000+',
        description: '누적 서비스 이용자 수 보유'
      },
      {
        title: '20,000+',
        description: '오브로에 연결된 실시간 오브젝트 갯수'
      },
      {
        title: '97.5%',
        description: '전 세계 건물 대부분을 업로드'
      },
      {
        title: '1,000+',
        description: '누적 고객사 수를 보유'
      },
    ]
  },
  ourPartners: {
    subtitle: 'Our Partners',
    title: '많은 기업들이 오브로를 통한 <br/> 디지털 전환을 경험하고 있습니다.'
  },
  ourTechnology: {
    subtitle: 'Our Technology',
    title: '오브로는 <br/> 기술을 혁신 합니다.',
    description: '오브로는 오랜기간 연구를 통해 위치 분석의 핵심 기술을 보유 하게 되었습니다.',
    policy: '관련특허',
    items: [
      {
        title: '무선 신호 안정화',
        description: '2.4GHz 신호는 주변 매질의 영향에 따라 신호를 활용하는 것에 어려움이 있습니다. 이러한 이유로 무선 신호를 안정화 하는 기술은 비콘을 이용한 시스템에서 필수적 입니다. Orbro의 무선신호 처리 기술은 약 10년간 300여개의 현장에서 검증 되었으며, 고객의 레퍼런스에 맞게 빠른속도로 튜닝될 수 있도록 만들어 졌습니다.',
        policy: [
          '무선 신호 기반 이동식 태그의 위치를 판단하는 시스템, 방법 및 컴퓨터 판독 가능 매체', 
          '사용자의 위험상황을 관제 서버로 알리는 이동형 비콘 시스템'
        ]
      },
      {
        title: '지향성 안테나 설계',
        description: '블루투스 신호는 주변의 영향을 많이 받기 때문에 환경에 맞추어 안테나를 설계하는 것은 무선통신 기술에서 가장 기본이 됩니다. 무지향성 안테나 뿐만 아니라 신호의 방향성을 구분할 수 있는 지향성 설계가 가능합니다. 결과적으로, 안테나, 제품, 신호처리 시스템을 한개로 패키지 화 하여 공급이 가능하기 때문에 더욱 더 안정적이고 효과적인 서비스 구현이 가능합니다.',
        policy: [
          '비콘신호에 기반한 도어제어방법 및 시스템', 
        ]
      },
      {
        title: '신호 분할 / 다중 신호처리',
        description: '여러개의 안테나를 이용하여 신호를 더 빠르게 송/수신하거나 이동 단말기(또는 모바일)가 어느 신호에 가까이 있는지 등을 구분할 수 있습니다. 다중 신호처리 기술을 응용하면 문의 안과 밖, 건물의 진입과 진출 등을 구분해 낼 수 있기 때문에 새로운 서비스 시나리오를 만들 수 있습니다.',
        policy: [
          '무선신호기반 이동식태그의 위치를 판단하는 시스템, 방법 및 컴퓨터-판독가능 매체',
          '보안기능을 수반한 매장 내 전자기기의 정보제공 시스템'
        ]
      },
      {
        title: '저전력 무선 트리거',
        description: '저전력 무선 트리거 기술은 저전력으로 오랜시간 송신을 해야하는 단말기에게 수신기가 트리거 신호를 주어 일시적으로 양방향 통신을 하는 기술을 의미 합니다. 페어링(pairing)을 하여 데이터를 주고 받는것 보다 약 10배 이상 빠르며 20배 이상 저전력으로 구동됩니다.',
        policy: [
          '비콘장치의 신호를 검출하는 방법 및 시스템'
        ]
      },
      {
        title: '메시 네트워크',
        description: 'Orbro의 메시 네트워크는 저전력 통신을 이용하여야 하는 환경에서 더 효과적인 결과를 만들어 냅니다. Flooding Mesh 알고리즘과 저전력 무선 트리거 기술이 기반이 되어 동작 되며 기존 비콘 대비 최대 50배 이하의 전력을 소비하고 3배이상 빠르게 동작 하면서 다중 정보 연결이 가능합니다. 전력 공급이 원할 하지 않은 환경에서 여러개의 기기의 정보를 정기적으로 수신할 때 효과적 입니다.',
        policy: [
          '비콘시스템에서 메쉬네트워크를 구현하기 위한 방법',
          '메쉬네트워크 통신기술을 활용한 안심벨 시스템'
        ]
      },
      {
        title: '무지향성 신호의 위치 추적기술',
        description: 'Orbro의 Insight는 설치된 각 Twintracker들과 사용자단말기간의 상관관게를 분석해 각 Twintracker별 Tag의 방향 벡터를 추론합니다. 이론적으로 추론된 벡터들의 교차점을 이용하면 Tag의 방향을 알 수 있습니다. 그러나 실제 환경에서는 신호의 불안정성과 물리적인 장애물로 인해 교차점이 한곳으로 모이지 않습니다. 이러한 환경에서 2차원적 위치를 도출하기 위해 2차원 도면상에서 확률밀도를 기반으로 위치를 추론합니다.',
        policy: [
          '사용자 단말기의 2차원적 위치를 도출하는 방법 및 시스템'
        ]
      },
      {
        title: '영상 객체의 좌표 추출',
        description: 'Object Detection과 Depth Estimation이라는 기술을 적용해 영상 내에 존재하는 사람을 인식하고 그 사람과의 거리를 추론할 수 있습니다. 이를 기반으로 2차원 도면 상에 사람의 위치를 알아내기 위해, 카메라의 화각과 Pixel을 인식된 사람이 어느방향에 있는지 인식할 수 있고 이를 기반으로 좌표를 계산합니다.',
        policy: [
          '카메라이미지를 이용하여 객체의 위치를 도출하는 방법 및 시스템'
        ]
      },
      {
        title: '위치분석 후처리 알고리즘',
        description: 'Object Detection과 Depth Estimation이라는 기술을 적용해 영상 내에 존재하는 사람을 인식하고 그 사람과의 거리와 각도를 이용해 좌표를 추론할 수 있습니다.  이 결과를 보정하기 위해 같은 공간에 사람이 없었을때의 카메라가 인식하는 바닥면과의 거리와 사람이 존재 했을때의 거리의 비를 이용해 인식된 사람의 좌표를 예측하고 측정한 좌표 결과를 보완합니다.',
        policy: [
          '카메라이미지를 이용하여 객체의 위치정보 출력을 보완하는 방법 및 시스템'
        ]
      },
      {
        title: '기계학습을 이용한 제어',
        description: '3C 강화학습 모델을 이용해서 사용자가 온도 조절을 하면 패널티를 부여하고 온도조절을 일정시간동안 하지 않으면 보상을 주어 적절한 온도를 자동으로 제어하게 합니다.',
        policy: [
          '기계학습모델을 이용한 에어컨 자동 쾌적제어방법, 시스템, 및 컴퓨터-판독가능 매체',
          '공간 내의 복수의 에어컨을 제어하는 시스템, 방법, 및 컴퓨터-판독가능 매체'
        ]
      },
      {
        title: '디지털 트윈 인터페이스',
        description: 'Orbro는 디지털 트윈에 특화된 인터페이스 구조를 가지고있습니다. 3D 공간상의 오브젝트를 클릭하며, 연관되는 앱을 쉽게 접근할수 있습니다.',
        policy: [
          '오브젝트 중심의 앱실행 인터페이스를 제공하는 방법, 이를 수행하는 서비스서버 및 컴퓨터-판독가능 매체',
          '오브젝트 관련 앱목록 인터페이스를 제공하는 방법, 이를 수행하는 서비스서버 및 컴퓨터-판독가능 매체',
          '오브젝트와 앱 간의 연관도를 이용한 앱목록 인터페이스를 제공하는 방법, 이를 수행하는 서비스서버 및 컴퓨터-판독가능 매체'
        ]
      },
    ]
  },
  ourCulture: {
    subtitle: 'Our Culture',
    title: '오브로는 <br/> 이렇게 일합니다.',
    description: '상상을 가치 있는 현실로 만드는 우리의 여정',
    discovery: {
      title: 'Discovery',
      description: '무한한 가능성을 가진 세상 속에서 기회를 발견합니다.'
    },
    extension: {
      title: 'Extension',
      description: '치열한 몰입을 통해 생각을 확장 합니다.'
    },
    collaboration: {
      title: 'Collaboration',
      description: '긴밀한 협업을 통해 상상을 구현 합니다.'
    },
    refine: {
      title: 'Refine',
      description: '가치있는 결과물을 창조하고 가공합니다.'
    },
  },
  ourHistory: {
    subtitle: 'Our History',
    title: '오브로 이야기',
    histories: {
      '2022': [
        "설립 7년만에 매출 100억원 달성",
        "콩테크, 세계 3대 디자인 어워드 '레드닷' 수상",
        "디지털 트윈 기반 메타버스 플랫폼 ‘ 오브로’ 출시",
        "경기도 판교 오브로 시스템 도입"
      ],
      '2021': [
        "전년대비 매출액 3배 성장, 판교 사무실 확장",
        "환경부, 우정바이오, LG전자, 아주대학교와 공동 주관 '의료폐기물 비대면 수거 처리 로봇' 사업 착수",
        "과학기술정보통신부 'AI기술실증 테스트베드 조성' 사업 수주, 사업단 운영",
        "한국도로공사 'AI 기반 건설현장 위험예측 시스템 도입' 사업 사주"
      ],
      '2020': [
        "세계 최고 수준 AI 위치분석 기술 접목, 실시간 위치추적 시스템(RTLS) 콩서비스 공급 본격화",
        "콩체크 이용 누적 사업장 수 전년 대비 10배 이상 증가",
        "행정안전부 '지역맞춤형 재난안전 문제해결 기술개발 지원' 사업 수주, 경기안심벨 공급"
      ],
      '2019': [
        "콩체크 서비스 런칭(3개월 만에 업계 Top3), 유료 이용자 1만명 돌파",
        "가트너(Gatner) 벤더 브리핑"
      ],
      '2018': [
        "스마트 팩토리 캘리브레이션 툴 전 세계 판매 시작",
        "국내 대형 아파트 건설사 IoT 출입관리 시스템 공급시작"
      ],
      '2017': [
        "콩 모니터링 서비스 출시, 국내 고속도로 위험사면 전체 설치(약 2천개소)",
        "자회사(콩모니터링) 설립"
      ],
    }
  },
  news: {
    subtitle: 'News',
    title: '세상이 보는 오브로',
    news: [
      {
        "agency": "매일경제",
        "createdAt": "2022.6.27",
        "title": "콩테크, 세계 3대 디자인 어워드 '레드닷' 수상",
        "link": "https://www.mk.co.kr/news/business/view/2022/06/562542/"
      },
      {
        "agency": "매일경제",
        "createdAt": "2022.4.19",
        "title": "\"현실세계 데이터 메타버스에 구현\"…콩테크, 설립 7년만에 매출 100억원",
        "link": "https://www.mk.co.kr/news/business/view/2022/04/349210/"
      },
      {
        "agency": "이투데이",
        "createdAt": "2022.4.18",
        "title": "콩테크, 작년 매출 100억 돌파...판교에 170억 규모 보금자리 마련",
        "link": "https://www.etoday.co.kr/news/view/2125046"
      },
      {
        "agency": "데이터넷",
        "createdAt": "2021.10.29",
        "title": "콩테크, SK쉴더스·정림건축에 최신 IoT 기술 지원",
        "link": "http://www.datanet.co.kr/news/articleView.html?idxno=165926"
      },
      {
        "agency": "파이넨셜뉴스",
        "createdAt": "2021.10.14",
        "title": "콩테크 이학경 대표 “경영에 기적은 없다, 매순간 치열한 과정이 있을 뿐”",
        "link": "http://www.jjan.kr/2118721"
      },
      {
        "agency": "지디넷코리아",
        "createdAt": "2021.6.24",
        "title": "콩테크 \"스마트폰만 있으면 아파트 스르르~ 출입\"",
        "link": "https://zdnet.co.kr/view/?no=20210624211558"
      },
      {
        "agency": "CCTV뉴스",
        "createdAt": "2021.6.24",
        "title": "콩테크, 스마트폰 기반 아파트 출입 보안 솔루션 '콩패스' 출시",
        "link": "http://www.cctvnews.co.kr/news/articleView.html?idxno=228019"
      },
      {
        "agency": "리크루트타임스",
        "createdAt": "2021.5.27",
        "title": "콩테크, '병원 의료폐기물 비대면 수거 처리 로봇' 공동개발",
        "link": "https://www.medipana.com/article/view.php?news_idx=276992&sch_menu=1&sch_gubun=5"
      },
      {
        "agency": "데이터넷",
        "createdAt": "2020.11.16",
        "title": "콩테크, 실시간 위치추적 시스템 ‘콩서비스’ 본격 공급",
        "link": "http://www.datanet.co.kr/news/articleView.html?idxno=152892"
      },
      {
        "agency": "글로벌이코노믹",
        "createdAt": "2020.2.31",
        "title": "근태관리 솔루션을 제공하는 ‘콩테크㈜’…현장 수요 반영한 ‘콩체크 3.0’ 업데이트",
        "link": "https://news.g-enews.com/ko-kr/news/article/news_all/202002211426069349aca1a8c21a_1/article.html?md=20200221142829_U"
      },
      {
        "agency": "시민일보",
        "createdAt": "2020.2.5",
        "title": "콩테크, 어린이집 전자출결 사업에 비콘 25만대 납품",
        "link": "http://www.siminilbo.co.kr/news/newsview.php?ncode=1065603668995186"
      },
      {
        "agency": "시사매거진",
        "createdAt": "2019.12.19",
        "title": "콩테크, 여가부 ‘2019 가족친화인증’ 기업 선정",
        "link": "https://www.sisamagazine.co.kr/news/articleView.html?idxno=307188"
      },
      {
        "agency": "시사뉴스",
        "createdAt": "2019.12.3",
        "title": "콩체크, ‘2019 대한민국 브랜드 만족도 IT부문 1위’ 수상",
        "link": "http://www.sisa-news.com/news/article.html?no=122374"
      },
      {
        "agency": "매거진한경",
        "createdAt": "2019.11.26",
        "title": "[2019 대한민국브랜드만족도1위] 콩체크, 스마트한 기술력의 근태관리 시스템",
        "link": "https://magazine.hankyung.com/business/article/201911262429b"
      },
      {
        "agency": "시사매거진",
        "createdAt": "2019.7.30",
        "title": "비콘 솔루션 기술을 선도하는 콩테크 이학경 대표",
        "link": "https://www.sisamagazine.co.kr/news/articleView.html?idxno=215613"
      },
      {
        "agency": "공감신문",
        "createdAt": "2018.12.19",
        "title": "카드 태그·지문인식 없이 가능한 스마트 근태관리 프로그램 ‘콩체크’ 출시",
        "link": "https://www.gokorea.kr/news/articleView.html?idxno=62896"
      },
      {
        "agency": "동아닷컴",
        "createdAt": "2018.7.27",
        "title": "콩테크(KongTech), 비콘 기반 기술 ‘어린이집 통학차량 안전관리 시스템’ 9월 출시예정",
        "link": "https://www.donga.com/news/article/all/20180727/91250851/2"
      },
      {
        "agency": "에너지경제신문",
        "createdAt": "2017.4.21",
        "title": "삼성 ARTIK 협력사 ‘콩테크’, ARTIK5 Standard EVAL 개발보드 출시",
        "link": "https://www.ekn.kr/web/view.php?key=282853"
      },
      {
        "agency": "데이터넷",
        "createdAt": "2017.4.18",
        "title": "IT기업 ‘콩테크’ 판교로 이전…성장의 신호탄",
        "link": "http://www.datanet.co.kr/news/articleView.html?idxno=110048"
      }
    ]
  }
}