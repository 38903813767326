
import {
  accesscontroller1, accesscontroller2, accesscontroller3, accesscontroller4, accesscontrollerOutlook, accesscontrollerThumb,
  accessAuto1, accessAuto2, accessAuto3, accessAuto4, accessAutoOutlook, accessAutoThumb,
  accessKey1, accessKey2, accessKey3, accessKey4, accessKeyOutlook, accessKeyThumb, accessKeyM1,
  accessPro1, accessPro2, accessPro3, accessPro4, accessProOutlook, accessProThumb,
  apart1, apart2, apart3, apart4, apartThumb,
  deadbolt1, deadbolt2, deadbolt3, deadbolt4, deadboltThumb, deadboltM1,
  emlock1, emlock2, emlock3, emlock4, emlockThumb, emlockM1

} from "@assets/images/main/access/product";
import { carTracking1, carTracking2, carTracking3, carTracking4, carTrackingKey1, carTrackingKey2, carTrackingKey3, carTrackingKey4, carTrackingKey5, ipCam1, ipCam2, ipCam3, ipCam4, ipCamKey1, ipCamKey2, ipCamKey3, ipThumb, peopleTracking1, peopleTracking2, peopleTracking3, peopleTracking4, peopleTrackingKey1, peopleTrackingKey2, peopleTrackingKey3, peopleTrackingKey4, ptzCam1, ptzCam2, ptzCam3, ptzCam4, ptzCamKey1, ptzCamKey2, ptzCamKey3, ptzThumb } from "@assets/images/main/camera";
import { bell1, bell2, bell3, bell4, bellOutlook, esl1, esl2, esl3, esl4, eslOutlook, mCradle1, mCradle2, mCradle3, mCradle4, mCradle5, mCradle6, mCradle7, mCradleOutlook, mCradleThumb, mTag1, mTag2, mTag2_1, mTag2_2, mTag2_3, mTag2_4, mTag2_Outlook, mTag2_Thumb, mTag2_key1, mTag2_key2, mTag2_key3, mTag3, mTag4, mTagOutlook, mTagThumb, switch1, switch2, switch3, switch4, switchOutlook } from "@assets/images/main/iot/product";
import { assetTag1, assetTag2, assetTag3, assetTagOutlook, assetTracking1, assetTracking2, assetTracking3, assetTracking4, serverPc, tagUwb1, tagUwb2, tagUwbOutlook, twinTracker1, twinTracker2, twinTracker3, twinTracker4, twinTracker5, twinTrackerOutlook, workerTracking1 } from "@assets/images/main/rtls/product";
import { eslThumbnail, safetyBellThumbnail, switchThumbnail, twinTrackerThumbnail } from "@assets/images/main/thumbnail";


export default {

  product: {


    accesscontroller: {
      thumbnail: accesscontrollerThumb,

      mini1: accesscontroller1,
      mini2: accesscontroller2,
      mini3: accesscontroller3,
      mini4: accesscontroller4,

      key1: accesscontroller1,
      key2: accesscontroller2,
      key3: accesscontroller3,
      outlook: accesscontrollerOutlook,

      redDot: 0,
    },


    accessauto: {
      thumbnail: accessAutoThumb,

      mini1: accessAuto1,
      mini2: accessAuto2,
      mini3: accessAuto3,
      mini4: accessAuto4,

      key1: accessAuto1,
      key2: accessAuto3,
      key3: accessAuto2,

      outlook: accessAutoOutlook,

      redDot: 1,

    },

    apart: {
      thumbnail: apartThumb,

      mini1: apart1,
      mini2: apart2,
      mini3: apart3,
      mini4: apart4,

      key1: apart2,
      key2: apart4,
      key3: apart3,
      key4: accessAuto1,

      outlook: accessAutoOutlook,

      redDot: 0,
    },


    cloud: {
      thumbnail: accessAutoThumb,

      mini1: accessAuto1,
      mini2: accessAuto2,
      mini3: accessAuto3,
      mini4: accessAuto4,

      key1: accessAuto1,
      key2: accessAuto3,
      key3: accessAuto2,

      outlook: accessAutoOutlook,

      redDot: 1,
    },



    accesspro: {
      thumbnail: accessProThumb,

      mini1: accessPro1,
      mini2: accessPro2,
      mini3: accessPro3,
      mini4: accessPro4,

      key1: accessPro1,
      key2: accessPro2,
      key3: accessPro4,

      outlook: accessProOutlook,


      redDot: 0,
    },



    accesskey: {
      thumbnail: accessKeyThumb,

      mini1: accessKeyM1,
      mini2: accessKey2,
      mini3: accessKey3,
      mini4: accessKey4,

      key1: accessKeyM1,
      key2: accessKey2,
      key3: accessKey3,

      outlook: accessKeyOutlook,


      redDot: 0,
    },



    emlock: {
      thumbnail: emlockThumb,

      mini1: emlockM1,
      mini2: emlock2,
      mini3: emlock3,
      mini4: emlock4,

      key1: emlockM1,
      key2: emlock2,
      key3: emlock3,

      outlook: "",


      redDot: 0,
    },



    deadbolt: {
      thumbnail: deadboltThumb,

      mini1: deadboltM1,
      mini2: deadbolt2,
      mini3: deadbolt3,
      mini4: deadbolt4,

      key1: deadboltM1,
      key2: deadbolt2,
      key3: deadbolt3,

      outlook: "",


      redDot: 0,
    },


    autoDoorSensor: {
      thumbnail: accessAutoThumb,

      mini1: accessAuto1,
      mini2: accessAuto2,
      mini3: accessAuto3,
      mini4: accessAuto4,

      key1: accessAuto1,
      key2: accessAuto3,
      key3: accessAuto2,

      outlook: accessAutoOutlook,


      redDot: 0,
    },


    assetTracking: {

      design: [
        {
          title: 'TwinTracker',
          desc: "无需复杂的安装过程,仅通过PoE(Power over Ethernet)连接就可以追踪资产定位器机的无线信号。",
          img1: twinTracker1,
          img2: twinTracker2,
          img3: twinTracker3,

        },

        {
          title: 'Asset Tag(BLE)',
          desc: "小巧且易于粘贴，可追踪资产的位置。",
          img1: assetTag1,
          img2: assetTag2,
          img3: assetTag3,

        },

        {
          title: 'Server PC',
          desc: '起到存储和计算资产位置的作用。',
          img1: serverPc,
          img2: '',
          img3: '',

        },

      ],

      out: [
        {
          title: 'TwinTracker',
          desc: "无需复杂的安装过程,仅通过PoE(Power over Ethernet)连接就可以追踪资产定位器机的无线信号。",
          img: twinTrackerOutlook,
        },
        {
          title: 'Asset Tag(BLE)',
          desc: "小巧且易于粘贴，可追踪资产的位置。",
          img: assetTagOutlook,
        },

      ],


      thumbnail: assetTracking1,

      mini1: assetTracking1,
      mini2: assetTracking2,
      mini3: assetTracking3,
      mini4: assetTracking4,

      key1: assetTracking1,
      key2: assetTracking2,
      key3: assetTracking3,

      outlook: accessAutoOutlook,

      title: 'TwinTracker',
      desc: "无需复杂的安装过程,仅通过PoE(Power over Ethernet)连接就可以追踪资产定位器机的无线信号。",

      redDot: 0,
    },



    workerTracking: {


      design: [
        {
          title: 'TwinTracker Pro',
          desc: "无需复杂的安装过程，仅通过PoE(Power over Ethernet)连接即可追踪资产定位器的无线信号。",

          img1: twinTracker1,
          img2: twinTracker2,
          img3: twinTracker3,

        },

        {
          title: 'Tag(UWB)',
          desc: "轻松粘贴可追踪工作者的位置。",

          img1: '',
          img2: tagUwb1,
          img3: tagUwb2,

        },

        {
          title: 'Server PC',
          desc: '起到存储和计算资产位置的作用。',

          img1: serverPc,
          img2: '',
          img3: '',

        },

      ],

      out: [
        {
          title: 'TwinTracker(BLE)',
          desc: "无需复杂的安装过程，仅通过PoE(Power over Ethernet)连接即可追踪资产定位器的无线信号。",
          img: twinTrackerOutlook,
        },
        {
          title: 'Tag(UWB)',
          desc: "轻松粘贴可追踪工作者的位置。",
          img: tagUwbOutlook,
        },

      ],


      thumbnail: workerTracking1,

      //제품 아래 4가지 이미지
      mini1: workerTracking1,
      mini2: tagUwb1,
      mini3: twinTracker1,
      mini4: assetTracking4,

      key1: workerTracking1,
      key2: tagUwb1,
      key3: twinTracker1,

      outlook: "",

      title: 'TwinTracker Pro',
      desc: "无需复杂的安装过程，仅通过PoE(Power over Ethernet)连接即可追踪资产定位器的无线信号。",

      redDot: 0,
    },




    carTracking: {


      design: [
        {
          title: 'PTZ Camera',
          desc: "通过PTZ室外摄像机追踪停车场的车辆位置。",

          img1: carTrackingKey1,
          img2: carTrackingKey2,
          img3: carTrackingKey3,

        },

        {
          title: 'NVR',
          desc: "保存并发送相机的图像信息。",

          img1: carTrackingKey4,
          img2: '',
          img3: '',

        },

        {
          title: 'Server PC',
          desc: " 它负责分析图像并计算位置。",

          img1: carTrackingKey5,
          img2: '',
          img3: '',

        },

      ],

      out: [],


      thumbnail: carTracking1,

      //제품 아래 4가지 이미지
      mini1: carTracking1,
      mini2: carTracking2,
      mini3: carTracking3,
      mini4: carTracking4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },

    peopleTracking: {


      design: [
        {
          title: 'IP Camera',
          desc: "通过IP相机追踪室内流动人口。",

          img1: '',
          img2: peopleTrackingKey1,
          img3: peopleTrackingKey2,

        },

        {
          title: 'NVR',
          desc: "保存并发送相机的图像信息。",

          img1: peopleTrackingKey3,
          img2: '',
          img3: '',

        },

        {
          title: 'Server PC',
          desc: " 它负责分析图像并计算位置。",

          img1: peopleTrackingKey4,
          img2: '',
          img3: '',

        },

      ],

      out: [],


      thumbnail: peopleTracking1,

      //제품 아래 4가지 이미지
      mini1: peopleTracking1,
      mini2: peopleTracking2,
      mini3: peopleTracking3,
      mini4: peopleTracking4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },


    ptz: {


      design: [
        {
          title: 'PTZ Camera',
          desc: 'PTZ户外摄像机可用于跟踪人员或车辆的位置。',

          img1: ptzCamKey1,
          img2: ptzCamKey2,
          img3: ptzCamKey3,

        },

      ],

      out: [],


      thumbnail: ptzThumb,

      //제품 아래 4가지 이미지
      mini1: ptzCam1,
      mini2: ptzCam2,
      mini3: ptzCam3,
      mini4: ptzCam4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },

    ip: {


      design: [
        {
          title: 'IP Camera',
          desc: '通过IP摄像机可以跟踪室内的人流动态。',

          img1: ipCamKey1,
          img2: ipCamKey2,
          img3: ipCamKey3,

        },

      ],

      out: [],


      thumbnail: ipThumb,

      //제품 아래 4가지 이미지
      mini1: ipCam1,
      mini2: ipCam2,
      mini3: ipCam3,
      mini4: ipCam4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },




    safetyBell: {
      thumbnail: safetyBellThumbnail,

      mini1: bell1,
      mini2: bell2,
      mini3: bell3,
      mini4: bell4,

      key1: bell1,
      key2: bell2,
      key3: bell3,

      outlook: bellOutlook,


      redDot: 0,
    },



    smartSwitch: {
      thumbnail: switchThumbnail,

      mini1: switch1,
      mini2: switch2,
      mini3: switch3,
      mini4: switch4,

      key1: switch1,
      key2: switch2,
      key3: switch3,

      outlook: switchOutlook,


      redDot: 0,
    },


    esl: {
      thumbnail: eslThumbnail,

      mini1: esl1,
      mini2: esl2,
      mini3: esl3,
      mini4: esl4,

      key1: esl1,
      key2: esl2,
      key3: esl3,

      outlook: eslOutlook,


      redDot: 0,
    },


    mTag: [
      {
        thumbnail: mTagThumb,

        mini1: mTag1,
        mini2: mTag2,
        mini3: mTag3,
        mini4: mTag4,

        key1: mTag1,
        key2: mTag2,
        key3: mTag3,

        outlook: mTagOutlook,
        redDot: 1,
      },

      {
        thumbnail: mTag2_Thumb,

        mini1: mTag2_1,
        mini2: mTag2_2,
        mini3: mTag2_3,
        mini4: mTag2_4,

        key1: mTag2_key1,
        key2: mTag2_key2,
        key3: mTag2_key3,

        outlook: mTag2_Outlook,
        redDot: 1,
      }


    ],

    mCradle: {
      thumbnail: mCradleThumb,

      mini1: mCradle1,
      mini2: mCradle2,
      mini3: mCradle3,
      mini4: mCradle4,

      key1: mCradle5,
      key2: mCradle6,
      key3: mCradle7,

      outlook: mCradleOutlook,

      redDot: 0,

    },
    robotBasic: {

      design: [
        {
          title: '配送机器人',
          desc: '通过与OBRO系统的集成，实现物品的配送。',

          img1: carTrackingKey1,
          img2: carTrackingKey2,
          img3: carTrackingKey3,

        },

        {
          title: '双跟踪器Pro',
          desc: '无需复杂的安装过程，只需PoE（以太网供电）连接即可追踪标签设备的无线信号。',

          img1: carTrackingKey4,
          img2: '',
          img3: '',

        },

        {
          title: '标签（UWB）',
          desc: '通过简单的附着可以追踪作业人员的位置。',

          img1: carTrackingKey5,
          img2: '',
          img3: '',

        },

        {
          title: '服务器PC',
          desc: '负责存储和计算资产的位置。',
          img1: serverPc,
          img2: '',
          img3: '',

        },

      ],

      out: [],

      thumbnail: carTracking1,

      // 产品下的四个图像
      mini1: carTracking1,
      mini2: carTracking2,
      mini3: carTracking3,
      mini4: carTracking4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },

    delivery: {

      design: [
        {
          title: '配送机器人',
          desc: '通过与OBRO系统的集成，实现物品的配送。',

          img1: carTrackingKey1,
          img2: carTrackingKey2,

        },

        {
          title: '装载终端',
          desc: '将待配送的物品放在设备上并呼叫机器人后，会自动装载物品。',

          img1: carTrackingKey4,
          img2: '',
          img3: '',

        },

        {
          title: '协作机器人',
          desc: '配送机器人到达时，负责卸载配送机器人上的物品。',

          img1: carTrackingKey5,
          img2: '',
          img3: '',

        },

        {
          title: '双跟踪器Pro',
          desc: '无需复杂的安装过程，只需PoE（以太网供电）连接即可追踪标签设备的无线信号。',

          img1: carTrackingKey4,
          img2: '',
          img3: '',

        },

        {
          title: '标签（UWB）',
          desc: '通过简单的附着可以追踪作业人员的位置。',

          img1: carTrackingKey5,
          img2: '',
          img3: '',

        },

        {
          title: '服务器PC',
          desc: '负责存储和计算资产的位置。',
          img1: serverPc,
          img2: '',
          img3: '',

        },

      ],

      out: [],

      thumbnail: carTracking1,

      // 产品下的四个图像
      mini1: carTracking1,
      mini2: carTracking2,
      mini3: carTracking3,
      mini4: carTracking4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },

    disinfection: {

      design: [
        {
          title: '配送机器人',
          desc: '通过与OBRO系统的集成，实现物品的配送。',

          img1: carTrackingKey1,
          img2: carTrackingKey2,

        },

        {
          title: '装载终端',
          desc: '将待配送的物品放在设备上并呼叫机器人后，会自动装载物品。',

          img1: carTrackingKey4,
          img2: '',
          img3: '',

        },

        {
          title: '协作机器人',
          desc: '配送机器人到达时，负责卸载配送机器人上的物品。',

          img1: carTrackingKey5,
          img2: '',
          img3: '',

        },

        {
          title: '消毒设备套件',
          desc: '在配送机器人装卸过程中安全地进行消毒。',
          img1: serverPc,
          img2: '',
          img3: '',

        },
        {
          title: '双跟踪器Pro',
          desc: '无需复杂的安装过程，只需PoE（以太网供电）连接即可追踪标签设备的无线信号。',

          img1: carTrackingKey4,
          img2: '',
          img3: '',

        },

        {
          title: '标签（UWB）',
          desc: '通过简单的附着可以追踪作业人员的位置。',

          img1: carTrackingKey5,
          img2: '',
          img3: '',

        },

        {
          title: '服务器PC',
          desc: '负责存储和计算资产的位置。',
          img1: serverPc,
          img2: '',
          img3: '',

        },

      ],

      out: [],

      thumbnail: carTracking1,

      // 产品下的四个图像
      mini1: carTracking1,
      mini2: carTracking2,
      mini3: carTracking3,
      mini4: carTracking4,

      key1: '',
      key2: '',
      key3: '',

      outlook: "",

      title: '',
      desc: '',

      redDot: 0,
    },




  },



}