import { SCREEN_BREAKPOINT, StyledGridColumn } from "@components/Grid/Grid.style";
import { StyledHeading, StyledLabel, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";

export const StyledTechCard = styled(StyledGridColumn)`
  min-height: 400px;
  padding: 40px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme['BACKGROUND_SECONDARY']};

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 20px 24px 30px;
  }
`;

export const StyledTechCardLogo = styled.img`
  width: 80px;
  height: 80px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width: 60px;
    height: 60px;
  }
`;

export const StyledTechCardTitle = styled(StyledHeading)`
  margin-top: 12px;
  color: ${({ theme }) => theme['CONTENT_PRIMARY']};
`;

export const StyledTechCardParagraph = styled(StyledParagraph)`
  margin: 4px 0 16px;
  color: ${({ theme }) => theme['CONTENT_SECONDARY']};
`;

export const StyledTechCardPolicy = styled.div`
  margin-top: auto;
`;

export const StyledTechCardPolicyItem = styled(StyledLabel)`
  align-items: center;
  padding-left: 8px;
  text-indent: -8px;
  & span {
    font-size: 18px;
  }
`;