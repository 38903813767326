import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| 스마트 물류 센터 : 게임 체인저가 될 RTLS',
  desc : '스마트 물류 센터를 구축하기 위해서는 RTLS 기술은 핵심입니다. 물류 산업의 게임 체인저로써 RTLS 기술은 어떠한 기능들을 가지게 될 것인지 알아보겠습니다.',
  keywords : '대형 물류 창고 관리, 물류센터 관리, 물류창고 관리, 스마트 물류, 재고 관리, 스마트 재고 관리, 물류창고 재고 관리, 대형 물류 창고 재고 관리, ORBRO, 오브로, 실시간 위치 추적, rtls, uwb, 콩테크',

  ogTitle : '',
  ogDesc : '',
  ogSite : '스마트 물류 센터 : 게임 체인저가 될 RTLS :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/logistics',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4Nn92GBAhAnqBNHPYNQDpB-QpINtYBr8NP1whgOrOeiJ231ayd-ir1pFXYQyPNn6ORiGuT9r9NH8WMwpUVVIJuDIDCcxjED8rHcdEkrjVEabQroYsK8zKZBFb5y7MqX52j_Hsy7L5ePRwrLj1l5AgzzhwwwRDy0SSWbba5oN-o3v4VK9ulJDIn-AlRzUvK-2_ExWeqxWtqjdDVGF780Q1Fyw9IIdObKFPnjkNtFBBQtMXHmKC4K3NY-PU75is1fvZGtz95FAVwQW8NW-iuI9-V6GgxYKoSSaE0VpltJTXpVeUwTA-55-8pGVLMV1jPeO1vGqDiJJsSQfFQE7xxXm6-UZvVDalr763kudRcjaH6dWNitPT2w4_qxEV3EivvyxvKRAKilLymJIzk-KgoGkU4hhuVrqpelXsjpiyAVrTRehdny-SFdJeuDk7TnnLXSFTf8rG1zgj4OOJcXrXqDmjNcKnSmD3gyrnY_7gEkAde2Vn2Wnm9hQewDJ1YDCx926J3unTLJApvy7uGKDbgGwZY0jfHbj3_EaB-5t5TBucsd2CukDZvZ27jfvLUUe1Wrr0-U21oag_JP6AeJNO-Thzp8fvPd1rmpOiKsPwlCOEdTn-aVu9hoAWByLe-OAQ_weFNJB2jqIOrRDEiEdsF2Ux_D4H4UY0gjcEi6EuIl2SaJ7GQEfGt0uQ1BSYXOm6_Ljse4BNu1hSdj9Iqtg7MstD1cwgxIVmXk20C1GudLlOL14w-EOHYMhOg-CdEf_6i7jOS4F-HZVe98fBNM8s4m5pjIUFngbQ9Ig158BQ2ZwJB2t05BgqvZDYMA33z6z0iOiRAnmT6J9sYM2yx4itS08KP1CgjFduRqis5fJcg9eFZuZJf5Hfd-vWgWD6fl5ssh6du1AEaEBLfbbmiNhDihCB-M0jHlLuDCL-wWrFKTCo3ylcA89Xivl7Hca0rY3s6JW_VfSwboGpYKJnMKpZqynEuHGMXoYXQ3jFpIQPj2xOElK8OLo1o3oQdjWPzTb4vUKBHiBzM6qkfsUZAL4b-zqcL2KxE1jgAyweOBCYpp3CdQ7LCgDO6I5VfS8lzcNBPnPq8_5OkN_r6xGcemqmtbzIIFQonHkEIYSOl0km6axb9eXkmCacj_0lr5oyGA8xGjLFN85rAsznmLomsfvDmvk8qx1fT8OQGW0Fsqgeeoujl6HOsXsGEVr-4dP1EupFbf4WR-v8GPCBN-c9FSQ7472yBB8ES-QlDo-xEP0xB8bn0ogShH5PCnzG3aQ1Y8fpAK_nzqUPpkZDW0HEUKQw6shyWu9MqPLKD1IbYuzWo_9Vba83qhFFvye5IxqyGiZr2SG2wZpmu1kWNjSG89Br3ZMTKQ2XSVpURse0EKs4abuIUAjtf7O1t3PVWF1Hm8R2Pllgb36whs0ZsXEgvM3gAghdJ_T8ydCqOD76BitUXjI_oV_2cn7KlbYyMsT90CHLr6NrT0ZW3B0s-hDXnGSiwvDM7ZCywF8XguZFj2X3konPXb1o1blj-A6LDR-ROdDTdsc9jBotrseWFE7qTdeVNs92ZxvaiWGvziDNdVARHQAwQIY5krEfq8B6DpkghJNg=w2194-h1199',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '스마트 물류 센터 : 게임 체인저가 될 RTLS',
  date : '2023-09-04',
  tag1 : '#대형물류센터',
  tag2 : '#스마트물류',
  tag3 : '#재고관리',
  tag4 : '#물류관리',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'스마트 물류 센터는 기존의 물류 산업과 효율성과 생산성 면에서 큰 차이가 있습니다. 자산, 재고, 노동력을 효율적으로 관리하여 생산성을 극대화하고 오류를 최소화하기 때문에 스마트 물류 센터 구축은 기업의 성장과 경쟁력 강화에 필수 요소가 되었습니다. 그리고 이를 구축하기 위해서는 실시간 위치 추적 기술(RTLS)의 도입이 필요합니다. <br/>과연 스마트 물류 센터에 실시간 위치 추적 솔루션을 도입하게 된다면, 어떤 일들이 가능해질지 알아보겠습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'1. 자산 관리 및 추적의 최적화',
      desc : 'RTLS 기술은 물류 센터 내의 모든 자산과 장비를 실시간으로 추적하는 데 사용됩니다. 이를 통해 물류 센터 관리자는 자산의 위치와 상태를 정확하게 파악할 수 있습니다. 예를 들어, 화물 트레일러나 포크리프트의 위치를 실시간으로 파악하여 자산의 이용률을 최적화하고 필요한 경우 유지 보수를 효율적으로 계획할 수 있습니다. 이로 인해 자산 관리 비용이 감소하고 자산의 수명이 연장됩니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'2. 재고 관리의 정확성 향상',
      desc : 'RTLS를 통해 제품과 재고의 위치를 실시간으로 파악할 수 있습니다. 이것은 재고 관리에 혁신을 가져옵니다. 물류 센터에서 어떤 제품이 어느 위치에 있는지 정확하게 파악하면 재고 손실을 최소화하고 재고 회전율을 최적화할 수 있습니다. 이로 인해 비용 절감과 효율성 향상이 이루어집니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'3. 실시간 데이터 분석',
      desc : 'RTLS는 실시간 데이터를 생성하며, 이 데이터를 분석하여 물류 센터의 운영을 실시간으로 모니터링하고 최적화할 수 있습니다. 물류 센터 내에서 어떤 과정에서 병목 현상이 발생하면 관리자들은 즉각적으로 조치를 취하여 문제를 해결할 수 있습니다. 이것은 고객 서비스에도 긍정적인 영향을 미칩니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'4. 노동력 최적화',
      desc : 'RTLS는 노동력 관리에도 도움이 됩니다. 노동자의 움직임과 작업 시간을 추적함으로써 작업자들의 작업 효율성을 향상시키고 작업 일정을 최적화할 수 있습니다. 물류 센터에서 작업자들을 효율적으로 배치하고 작업을 조정하는 데 RTLS를 활용하면 노동 비용을 절감하고 생산성을 향상시킬 수 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'5. 고객 서비스 향상',
      desc : 'RTLS는 주문 처리 및 배송 과정에서도 중요한 역할을 합니다. 제품의 실시간 위치를 파악하여 고객에게 정확한 예상 도착 시간을 제공할 수 있으므로 고객 서비스 품질을 향상시킬 수 있습니다. 고객은 제품이 언제 도착할지에 대한 정보를 받을 수 있기 때문에 더욱 만족할 것입니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'6. 안전 및 보안 강화',
      desc : 'RTLS는 물류 센터의 안전 및 보안을 강화하는 데 도움이 됩니다. 장비와 자산의 위치를 정확하게 파악하여 불법 침입이나 재고 도난을 방지할 수 있으며, 장비의 정기적인 점검 및 유지 보수를 통해 안전을 보장할 수 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'7. 환경 친화성 강화',
      desc : '스마트 물류 센터는 에너지 및 자원 효율성을 향상시킬 수 있습니다. RTLS는 자원 사용을 최적화하고 에너지 소비를 줄일 수 있는 방법을 찾는 데 도움을 줄 수 있습니다. 예를 들어, 물류 센터 내의 조명 및 기계를 자동으로 조절하여 에너지 소비를 최적화할 수 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'',
      desc : 'RTLS 기술은 물류 및 공급망 관리 분야에서 혁신을 이끌고 있으며, 스마트 물류 센터에서 이를 적용함으로써 비용 절감, 효율성 향상, 고객 서비스 품질 향상, 환경 친화성 강화 등 다양한 이점을 얻을 수 있습니다. 이러한 이점은 기업의 경쟁력을 향상시키고 물류 및 공급망 관리를 더욱 효과적으로 수행할 수 있는 기회를 제공합니다. 따라서 스마트 물류 센터를 구축하려는 기업은 RTLS 기술을 적극적으로 고려해야 합니다.',
      dot : ''
      
    },

  ],

  link : {
    page : 'rtls',
    numb : '1',
    product : [
      {
        text : productPurchase.purchase.asset.assetTracking.title,
        title : productPurchase.purchase.asset.assetTracking.title,
        serial : productPurchase.purchase.asset.assetTracking.serial,
        desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img : productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution : [
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      },
      {
        img : logisticsSafety.thumb,
        data : 'logisticsSafety',
        link : '/enterprise/logistics/safety'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/enterprise/manufacturing/asset'
      }
    ]

  }


}