import { ptzThumb } from "@assets/images/main/camera";

export default {


    mobile: {
        title: '모바일 출입 시스템 | 오브로 제품',
        key: '자동 출입, 출입문, 아파트 출입, 출입 시스템, 스마트 출입, 스마트 출입 시스템, 모바일 출입, RTLS, 비접촉 출입, 비콘, 블루투스, 콩테크, 오브로, orbro',
        desc: '모바일 출입 시스템은 배터리가 내장된 제품으로 전원 공사 필요없이 출입문 옆에 부착하여 쉽고 빠르게 모바일로 출입문 제어가 가능합니다. 빠르고 간편하며 합리적인 가격까지 겸비한 차세대 출입 시스템입니다.',
        img: '',
        url: 'https://orbro.io/access/mobile',
    },
    auto: {
        title: '자동문 출입 시스템 | 오브로 제품',
        key: '자동문, 자동 출입, 출입문, 아파트 출입, 출입 시스템, 스마트 출입, 스마트 출입 시스템, 모바일 출입, RTLS, 비콘, 블루투스, 비접촉 출입, 콩테크, 오브로, orbro',
        desc: '자동문 출입에 특화된 제품으로 기존 자동문 센서 교환으로 모바일 앱 설치를 통해 비접촉 출입이 가능하도록 합니다. 디자인 대상을 받은 세련되고 스마트한 제품입니다.',
        img: '',
        url: 'https://orbro.io/access/auto',
    },
    face: {
        title: '얼굴 인식 시스템 | 오브로 제품',
        key: '얼굴 인식, 얼굴 인증, 안면 인증, 자동 출입, 출입문, 아파트 출입, 출입 시스템, 스마트 출입, 비콘, 블루투스, 스마트 출입 시스템, 모바일 출입, RTLS, 비접촉 출입, 콩테크, 오브로, orbro',
        desc: 'Access Pro는 건물의 공동 현관의 출입문을 제어하고, 호출하는 기능을 가진 제품입니다. 실시간 영상 통화가 가능하고 원격으로 출입문을 제어할 수 있습니다.',
        img: '',
        url: 'https://orbro.io/access/face',
    },
    accessory: {
        title: '출입 액세서리 | 오브로 제품',
        key: '비콘, 블루투스, 얼굴 인식, 얼굴 인증, 안면 인증, 자동 출입, 출입문, 아파트 출입, 출입 시스템, 스마트 출입, 스마트 출입 시스템, 모바일 출입, RTLS, 비접촉 출입, 콩테크, 오브로, orbro',
        desc: '효율적이고 경제적인 스마트 출입 제품들을 제공합니다. 다양한 액세서리로 차별화된 출입 시스템을 구축할 수 있습니다.',
        img: '',
        url: 'https://orbro.io/access/accessory',
    },

    ble: {
        title: '블루투스(BLE) 위치추적 | 오브로 제품',
        key: '위치추적, 실시간 위치 추적, RTLS, 블루투스, 비콘, BLE, UWB, 자산 관리, 자산 추적, 재고 관리, 재고 추적, 사람 추적, 실내 위치추적, 실내 GPS, GPS, ORBRO, 오브로, 공테크',
        desc: 'BLE 기반 위치추적 제품은 자산 또는 추적하고자하는 대상에 BLE 태그 단말기를 부착하여 위치를 추적할 수 있습니다. 안정적으로 효율적인 시스템을 기반으로 정확한 데이터를 제공합니다.',
        img: '',
        url: 'https://orbro.io/rtls/ble',
    },
    uwb: {
        title: '고정밀(UWB) 위치추적 | 오브로 제품',
        key: '위치추적, 실시간 위치 추적, RTLS, 블루투스, 비콘, BLE, UWB, 자산 관리, 자산 추적, 재고 관리, 재고 추적, 사람 추적, 실내 위치추적, 실내 GPS, GPS, ORBRO, 오브로, 공테크',
        desc: 'UWB 기반 고정밀 위치추적은 자산이나 사람의 위치를 매우 정확하게 추적합니다. 안정성, 효율성, 경제적인 부분 모두 뛰어난 장점을 지닌 제품입니다.',
        img: '',
        url: 'https://orbro.io/rtls/uwb',
    },

    outdoor: {
        title: '실외 카메라 | 오브로 제품',
        key: '카메라 위치추적, PTZ, 실시간 위치 추적, 차량 위치추적, 스마트 주차장, RTLS, 블루투스, 비콘, BLE, UWB, 자산 관리, 자산 추적, 재고 관리, 재고 추적, 사람 추적, 실내 위치추적, 실내 GPS, GPS, ORBRO, 오브로, 공테크',
        desc: '카메라를 통하여 사람, 차량 등 다양한 객체의 위치 및 이상 행동을 감지하고 관리 할 수 있습니다. 차량의 위치를 파악하거나 사람들의 동선을 파악하여 정확하고 안정적인 데이터를 실시간으로 제공합니다.',
        img: '',
        url: 'https://orbro.io/camera/outdoor',
    },
    indoor: {
        title: '실내 카메라 | 오브로 제품',
        key: '카메라 위치추적, IP, 실시간 위치 추적, 차량 위치추적, 스마트 주차장, RTLS, 블루투스, 비콘, BLE, UWB, 자산 관리, 자산 추적, 재고 관리, 재고 추적, 사람 추적, 실내 위치추적, 실내 GPS, GPS, ORBRO, 오브로, 공테크',
        desc: '매우 정밀한 위치추적 기술을 기반으로, 실내에서 이동하는 사람이나 재고의 위치를 실시간으로 추적하여 정확한 데이터를 제공해 줍니다.',
        img: '',
        url: 'https://orbro.io/camera/indoor',
    },


    bell: {
        title: '비상벨 | 오브로 IoT 제품',
        key: 'iot, 사물 인터넷, 안심벨, 긴급 신고 버튼, 긴급 신고, 신고 버튼, 경찰 호출, 위급상황, 안전벨, 119, 112, 여성안전, 비콘, 블루투스, 스토킹방지, 스토킹신고, 콩테크, 오브로, orbro',
        desc: '위급 상황시에 긴급 신고를 할 수 있는 안심벨입니다. 언제 어디서나 부착이 가능하며, 빠른 신고를 위해 가장 효율적인 제품이라고 할 수 있습니다.',
        img: '',
        url: 'https://orbro.io/iot/bell',
    },
    energy: {
        title: '전력제어 | 오브로 IoT 제품',
        key: '전력제어, 스마트 전력, 스마트 스위치, 스마트 버튼, 디지털 스위치, 스위치, 블루투스, 원격 제어 스위치, 스마트 전구, 콩테크, 오브로, orbro',
        desc: 'Smart Switch는 아날로그 스위치를 손쉽게 디지털 스위치로 교체할 수 있는 제품입니다. 낭비되는 전력을 줄이고 불필요한 시간 낭비를 줄일 수 있습니다.',
        img: '',
        url: 'https://orbro.io/iot/energy',
    },
    display: {
        title: 'E-Ink 디스플레이 | 오브로 제품',
        key: '전력제어, 스마트 전력, 스마트 스위치, 스마트 버튼, 디지털 스위치, 스위치, 블루투스, 원격 제어 스위치, 스마트 전구, 콩테크, 오브로, orbro',
        desc: 'ESL은 비콘 시스템 기반의 디스플레이 장치입니다. E-ink Display를 사용하기 때문에 매우 낮은 전력으로 디스플레이 사용이 가능합니다.',
        img: '',
        url: 'https://orbro.io/iot/display',
    },
    cradle: {
        title: '모바일 보안장치 | 오브로 제품',
        key: 'iot, 사물 인터넷, 여성안전, 콩테크, 오브로, orbro',
        desc: '케이블 잠금장치의 불편함 없이 스마트폰을 무선으로 관리 할수 있습니다.',
        img: '',
        url: 'https://orbro.io/iot/cradle',
    },
    idCard: {
        title: '사원증 | 오브로 제품',
        key: '사원증, 스마트 사원증, iot, 사물 인터넷, 여성안전, 콩테크, 오브로, orbro',
        desc: '스마트 사원증은 목걸이 형태로 제작되어 산업과 서비스 영역 모두에서 호환성 높게 사용이 가능합니다. 오브로의 다른 서비스와 연동도 가능합니다.',
        img: '',
        url: 'https://orbro.io/iot/id',
    },

    loraGateway: {
        title: 'LoRa 중계기 | 오브로 제품',
        key: 'LoRa 중계기, LPWAN 중계 장치, Long Range 무선 장비, IoT 통신 솔루션, 무선 센서 네트워크 장치, LoRaWAN 중계장치, 저전력 IoT 통신, 스마트 시티 IoT 솔루션, 무선 IoT 기기 판매, 무선 센서 네트워크 솔루션, IoT 장비 구매, 무선 통신 시스템, 무선 LoRa 중계기, 무선 IoT 장비 제조사, LoRa 중계기 가격, LPWAN IoT 장비, IoT 통신 솔루션 업체, 센서 네트워크 시스템, 스마트 시티 통신 장비, 저전력 무선 네트워크, 오브로, 콩테크, orbro, rtls, 실시간 위치추적',
        desc: 'LoRa 중계기 제품은 긴 거리 통신 기술을 기반으로 하며, IoT (사물인터넷) 응용 분야에서 무선 통신의 핵심 역할을 합니다. 이들 중계기는 저전력 솔루션을 제공하며, 스마트 시티, 농업, 환경 모니터링, 산업 자동화 등 다양한 분야에서 사용됩니다. 우리의 LoRa 중계기는 안정적이고 효율적인 데이터 전송을 통해 고객의 요구를 충족하며, IoT 프로젝트의 성공을 지원합니다.',
        img: '',
        url: 'https://orbro.io/sensor/LoRaGateway'
    },
    loraSensor: {
        title: 'LoRa 센서 | 오브로 제품',
        key: 'LoRa 센서, LPWAN IoT 센서, Long Range 무선 센서, IoT 센서 네트워크, 무선 환경 감지 센서, 저전력 IoT 디바이스, 스마트 시티 센서 솔루션, 무선 환경 모니터링, IoT 센서 기기, 무선 환경 감시 장비, 환경 센서 네트워크, 무선 LoRa 센서 기술, 환경 모니터링 시스템, IoT 환경 센서 판매, LoRa 센서 네트워크, LPWAN IoT 기술, 스마트 시티 환경 모니터링, 무선 환경 센서 제조사, LoRa 센서 가격, LPWAN IoT 환경 센서, IoT 센서 솔루션 업체, 오브로, 콩테크, orbro, rtls, 실시간 위치추적',
        desc: 'LoRa 센서는 저전력 무선 기술을 활용하여 환경 모니터링 및 실시간 데이터 수집을 위한 이상적인 솔루션입니다. 저희의 LoRa 센서 제품은 IoT (사물인터넷) 프로젝트에 필수적이며, 공기 품질, 온도, 습도, 가스 농도 등 다양한 파라미터를 신속하게 모니터링합니다. 이러한 센서는 스마트 시티, 농업, 산업 자동화 및 환경 모니터링에 필수적이며, 안정성과 정확성을 통해 데이터 수집의 효율성을 높입니다.',
        img: '',
        url: 'https://orbro.io/sensor/LoRaSensor'
    },
    airQuality: {
        title: '공기품질 측정기 | 오브로 제품',
        key: '산업용 공기품질 측정기, 대기질 측정장치, 공장용 대기 오염 측정기, 공기질 모니터링 장비, 환경 모니터링 솔루션, 공기질 분석 장치, 산업용 대기 오염 센서, 공기 품질 모니터링 장비, 산업용 대기질 측정 솔루션, 대기질 모니터링 시스템, 산업 환경 모니터링 장치, 대기질 센서 네트워크, 환경 모니터링 솔루션 업체, 산업용 대기질 센서 판매, 대기질 모니터링 기기, 산업 환경 센서 기술, 대기질 측정 장비 가격, 공장용 환경 모니터링 시스템, 산업용 대기질 센서 제조사, 대기질 모니터링 솔루션 업체, 오브로, 콩테크, orbro, rtls, 실시간 위치추적',
        desc: '공기품질 측정기는 실내 및 외부 환경에서 대기질을 정확하게 모니터링하고 개선하는 데 필수적입니다. 이 측정기는 미세먼지, CO2, 온도, 습도 등 다양한 대기질 지표를 실시간으로 측정하며, 스마트 시티, 건물 관리, 농업, 산업 및 환경 보호 분야에서 사용됩니다. 안전하고 건강한 환경을 유지하기 위한 필수 도구로, 고객의 대기질 모니터링 요구를 충족합니다.',
        img: '',
        url: 'https://orbro.io/sensor/air'
    },
    coMeasure: {
        title: '일산화탄소 측정기 | 오브로 제품',
        key: '일산화탄소 측정기, CO 가스 감지기, 일산화탄소 농도 측정 장치, CO 측정기 판매, 환경 CO 모니터링 장비, 일산화탄소 감지 장치, CO 감지기 가격, 가스 농도 측정 장치, 일산화탄소 센서, CO 농도 모니터링 시스템, 일산화탄소 측정 솔루션, CO 모니터링 장비 업체, 환경 가스 센서 기술, CO 가스 감지기 제조사, 산업용 CO 모니터링, 일산화탄소 레벨 측정, CO 측정장치 설치, 일산화탄소 감지 장치 솔루션, 환경 CO 모니터링 시스템 가격, CO 측정기 레벨 조정, 오브로, 콩테크, orbro, rtls, 실시간 위치추적',
        desc: '일산화탄소 측정기는 환경과 안전을 위한 중요한 도구로서, 실내 및 실외 환경에서 CO (일산화탄소) 농도를 신속하게 감지하고 모니터링합니다. 이 측정기는 생활환경, 공장, 차고 등 다양한 장소에서 사용되며, 독성 가스 누출 감지 및 안전한 환경을 제공하는 데 기여합니다. 안전과 건강을 위해 필수적인 일산화탄소 측정기를 통해 대기 중 일산화탄소 농도를 모니터링하고 제어하세요.',
        img: '',
        url: 'https://orbro.io/sensor/co'
    },
    beaconIndustry: {
        title: '산업용 비콘 | 오브로 비콘 제품',
        key: '산업용 비콘, 비콘 트래킹 시스템, 비콘 위치 추적, 산업용 비콘 솔루션, 무선 비콘 기술, 비콘 위치 서비스, 비콘 통신 장비, 비콘 트래커 판매, 스마트 공장 비콘, 산업용 비콘 가격, 비콘 실내 위치 서비스, 무선 위치 추적 시스템, 비콘 위치 기반 서비스, 비콘 실시간 위치 추적, 산업용 비콘 제조사, 비콘 실내 네비게이션, 비콘 IoT 기기, 무선 비콘 위치 시스템, 비콘 네트워크 솔루션, 산업용 비콘 기술 플랫폼,',
        desc: '고급 블루투스 기술을 활용하여 물류, 자산 관리, 생산 프로세스 최적화 등 다양한 응용 분야에서 사용됩니다. 스마트 공장 및 산업 자동화 분야에서 혁신적인 위치 추적 및 무선 IoT 솔루션을 제공합니다.',
        img: '',
        url: 'https://orbro.io/beacon/industry'
    },
    beaconTag: {
        title: '소형 비콘 | 오브로 비콘 제품',
        key: '비콘, 소형 비콘, 비콘 태그 트래킹, 비콘 태그 시스템, 실내 위치 추적 기술, 블루투스 태그 비콘, 실시간 위치 기반 서비스, 비콘 태그 기기, 위치 태그 트래커 판매, 블루투스 비콘 태그, 태그 비콘 가격, 블루투스 실내 위치 서비스, 위치 기반 태그 시스템, 비콘 태그 실시간 추적, 태그 비콘 IoT 솔루션, 실내 위치 추적 제조사, 블루투스 태그 IoT 기기, 태그 비콘 무선 위치 시스템, 위치 기반 태그 네트워크 솔루션, 태그 비콘 기술 플랫폼, 비콘 태그 앱 개발.',
        desc: '실내 및 실외에서 물체 또는 개체의 위치를 정확하게 식별하고 모니터링하는 데 사용됩니다. 물류, 자산 관리, 실내 네비게이션, 스마트 공간 및 스마트 도시 프로젝트에서 핵심 역할을 합니다.',
        img: '',
        url: 'https://orbro.io/beacon/tag'
    },
    assetTag: {
        title: '자산용 태그 | 오브로 비콘 제품',
        key: '자산관리, 비콘, 소형 비콘, 비콘 태그 트래킹, 비콘 태그 시스템, 실내 위치 추적 기술, 블루투스 태그 비콘, 실시간 위치 기반 서비스, 비콘 태그 기기, 위치 태그 트래커 판매, 블루투스 비콘 태그, 태그 비콘 가격, 블루투스 실내 위치 서비스, 위치 기반 태그 시스템, 비콘 태그 실시간 추적, 태그 비콘 IoT 솔루션, 실내 위치 추적 제조사, 블루투스 태그 IoT 기기, 태그 비콘 무선 위치 시스템, 위치 기반 태그 네트워크 솔루션, 태그 비콘 기술 플랫폼, 비콘 태그 앱 개발.',
        desc: '자산 관리에 최적화된 초소형 블루투스 태그 단말기 입니다. 최신 버전의 블루투스와 최대 1년의 배터리가 탑재되어 있습니다.',
        img: '',
        url: 'https://orbro.io/beacon/asset-tag'
    },

    basicRobot: {
        title: '로봇 시스템 | 오브로 비콘 제품',
        key: '로봇 시스템, 자산 관리, 비콘, 소형 비콘, 비콘 태그 트래킹, 비콘 태그 시스템, 실내 위치 추적 기술, 블루투스 태그 비콘, 실시간 위치 기반 서비스, 비콘 태그 기기, 위치 태그 트래커 판매, 블루투스 비콘 태그, 태그 비콘 가격, 블루투스 실내 위치 서비스, 위치 기반 태그 시스템, 비콘 태그 실시간 추적, 태그 비콘 IoT 솔루션, 실내 위치 추적 제조사, 블루투스 태그 IoT 기기, 태그 비콘 무선 위치 시스템, 위치 기반 태그 네트워크 솔루션, 태그 비콘 기술 플랫폼, 비콘 태그 앱 개발.',
        desc: '실시간 위치추적 시스템을 로봇 배송 시스템과 결합하여, 물건을 배송하거나 작업을 수행하는 데에 최적화된 로봇 서비스를 제공합니다.',
        img: '',
        url: 'https://orbro.io/integration/robot'
    },


}