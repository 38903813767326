import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: 'RTLS based on Active RFID technology utilizes RFID tags with power, enabling tracking of locations over longer distances and accurate real-time monitoring of objects or personnel, finding applications across various industries.',
  keywords: 'Active RFID, Passive RFID, RFID, RTLS, location-based, asset tracking, indoor tracking',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Active RFID-based RTLS :: OrbRo Blog',
  ogUrl: 'https://orbro.io/en/blog/activerfid',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EOaF0taI55etnk-7VQVh_JecDxbhJX529VYv21nToxYe_qXD-w9k29bChDiW0Dj2JxEgZAtoeKjP5UhTJ0zB6tPZT6tGj5rygnex9J0bJt-k9_HhdDZPSetk87I3E-uDLFHG4xQF9ZgyEozwxxPAL_R0RPL-WNwKtkmGk1-d2GauxlTr2d4hGLWvllQl3_usiu46eWZqw76MEhqjCOs1HfYNn6nZM1_tRdsFDF2YeHeVM_nxLUKocvLFuA1xOhxMMkn2O2EW51rmnrEX66iYcxSgcpQix_5nMEcjBMJRqsrg2ULZuUeQ4R_-ji2u-RIAmKIt45RH7RVpxVW5jORDikiW-LbfzIQeDxdAPu8fq3k5o1IQ15lDVU7_nAae5wo1RxF_2JoYHgTqK1D5f6Zpt9eKA3OZReeim-MmTDklv6WC4CRnzWF1YS6efSa5G_vOTnieHKYY_QQ_w6mQlR-Lzzik_xNgPachPN-w_ls-iM2a-AtLnitE0S5O2xXPUlzsm_NyBM3NaA7BueOWETvdm64Es_4LjIsbnlQ-A_MIMbKZjiL7lIfWXUtNbYkpFryMZzUFeHXV7j72VtEmvkbmnMeGI120Co5kuvlpsFxOfmcuQTgwQPbRgsgFIWBkAnt9vla-Y4F3szREBBYSt44cgGIqknvJEv_7llpLaRXYYL0v1mKTnDRjXVKmGHugaxJNamcxLIHjGAgw_PfhHxMTYYeHs216wRGREOhMRsYnaIoi9httnwkNN911s1nK48m8YaTploLUIbMNoGasxH9yXlqXAcZUvDzbzAw4-bEzD4WBeX0pNQe9TuF3QnV2GlnklwVCLrWL8O4jE6jpOvW7KYMKIdAGPTkpHGBsViFHHX6nWFYHtsfaFKj8hjAUJmLrR_To65RSXQRHqfPye2voD_IDE6XhqnFK5Zi9gcsBgwZo7KQuihZcE_ctUyQOfFYvDJA8NILsJsvoGZQXQjPJvIaYmV9LfETM1MMCSL4AC4Vp4ZqQi7q6Fr1cvv1zrmYChkRYtxC00M5Eo2qg2-Qmb8SiANdUYeHxsSOLl5ybYsXBTbEUI9wY0jvT646VkLtHUf-AR9QdUnMqwj2YROz8lgVDZgZroHzXpzDUJaVt1BieJuuk5OLawO2eOrDN7VRU9i35NYRxx_eMOjeVvXJNJ7QxDISJgoCj_iLnilmShn8QmQfU5zINgqzKac6Ru0cJvScz5k_IQxEx_L9LXfqjaljUjhkI65UDijCW-u328M_DEEGeW9sYavREHwtpdY97oScs7VaBcxyOF2fi6XvP_5Gwig4GyydfN67d_49MmeMMSXVrJajT9i93QKvePas21Tiu8VaLtdSYFnuj_Vy53U1EJihuJXEQG4DoTm_I6UsnaCxf4Ymi0Asp-m2oZlcoywdlRKdj9W8x5uVqd2baDzd4mjNO_CBNMFTMmvGAMOS3XOi3jQYZ0a7tkbrcy1DSNhwv_Ds19DpQrDM5faV6GSaRtRfu-8hgESZSlBC_P3fJ9QPREO5n0TspEozjIrZbHoyhmP7yQB58K-7V3bpk-fKuaw3ruD64ZeIeFyOiJwJDig=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',


  title: 'Active RFID-based RTLS',
  date: '2023-05-15',
  tag1: '#RFID',
  tag2: '#RTLS',
  tag3: '#AssetTracking',
  tag4: '#IndoorTracking',

  // <br/>
  text: [
    {
      head: 'What is Active RFID?',
      subHead: '',
      desc: 'Active RFID is a wireless communication format that transmits information between devices. Active RFID tags have a power source, allowing them to transmit signals over long distances. They are ideal for real-time tracking of assets. Active RFID tags can be read up to several hundred meters, making them ideal for large-scale asset tracking solutions.',
      dot: ''
    },
    {
      head: 'Active RFID vs. Passive RFID',
      subHead: '',
      desc: 'Active RFID tags have an embedded transmitter and power source, enabling them to emit signals without requiring external readings. They are more expensive than passive RFID tags but have a wider range. Active tags are typically used for tracking wide-ranging items such as warehouse management. <br/><br/>Passive RFID tags do not have a power source. They rely on the power emitted by the RFID reader to activate and transmit signals. Therefore, passive RFID tags are much cheaper than active RFID tags but often have a reading range of only a few meters. Passive RFID tags are typically used for applications where a short reading range is sufficient, such as retail asset management.',
      dot: ''
    },
    {
      head: 'Which type of RFID is used nowadays?',
      subHead: '',
      desc: 'There are two main types of active RFID tags: beacon tags and transponder tags. Beacon tags periodically broadcast information, which can be received by active RFID readers within range. Transponder tags only communicate when receiving specific queries from active RFID readers. This allows each tag to transmit information only when queried by the reader, enabling more targeted asset tracking.',
      dot: ''
    },
    {
      head: 'Can Active RFID technology be combined with RTLS solutions?',
      subHead: '',
      desc: 'Real-Time Location Systems (RTLS) are essentially a form of active RFID. RTLS is made possible through active RFID tags that emit signals, which can be received by receivers deployed throughout a facility. This allows for near-instantaneous tracking of designated assets, providing valuable and comprehensive information about their whereabouts.',
      dot: ''
    },
    {
      head: 'Advantages of RTLS solutions',
      subHead: '<br/>Accuracy',
      desc: 'Active RFID tags can achieve a high level of tracking accuracy, often down to a few centimeters. This makes Active RTLS suitable for applications where precise location information is crucial, such as healthcare and manufacturing.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Real-Time Data',
      desc: 'Active RTLS systems provide real-time location data of tagged objects, allowing the constant monitoring of asset locations. This is useful for time-sensitive applications such as retail and logistics.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Flexible Scalability',
      desc: 'An active RTLS system can be configured to track all types of assets, including people, pallets, and containers. It can track assets even in indoor environments with weak or non-existent GPS signals. Since line of sight is not required between tags and receivers, it is ideal for tracking assets in high-density environments. These capabilities make active RTLS suitable for a wide range of applications.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Extended Range',
      desc: 'With a range of up to 100 meters, active RFID tags are ideal for tracking dispersed assets across large areas. They can transmit signals over long distances, making them suitable for tracking large assets like vehicles. This information can be used to improve asset management, security, and automate tasks such as asset management.',
      dot: ''
    },
    {
      head: '<br/>Practical Applications of Active RTLS-Based Asset Tracking Systems',
      subHead: '',
      desc: 'Active RTLS systems can be used in various applications, including:',
      dot: ''
    },
    {
      head: '',
      subHead: 'Healthcare',
      desc: 'Active RTLS systems for healthcare are used to track the location of patients, staff, and equipment within a hospital. This information can improve patient care and safety and help manage hospital resources more effectively.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Manufacturing',
      desc: 'Active RTLS systems for manufacturing are used to track the location of raw materials, finished products, and production equipment within a factory. This information can help improve manufacturing efficiency and quality control.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Retail',
      desc: 'Active RTLS systems are used to track asset locations in retail stores. This information can improve customer service.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Wholesale',
      desc: 'Active RTLS systems are used to track the location of pallets, containers, and other assets in warehouses and logistics centers. This information can be used to enhance delivery accuracy and efficiency.',
      dot: ''
    },
    {
      head: '',
      subHead: '',
      desc: '<br/>Considering the practicality and flexibility of RTLS-based asset tracking systems, they can be classified as cost-effective systems. It is also worth noting that the cost of these RTLS systems has been consistently declining over the past few years.',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/en/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      }
    ]


  }


}