import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";
import digitaltwin from "@src/assets/images/digitaltwin/video/traffic.mp4";

export default {

  helmet: '| 数字孪生是什么？| 定义、应用案例',
  desc: '数字孪生是指将现实世界中的物理对象或过程数字化建模，以实时方式收集和分析信息。换句话说，它提供了在数字环境中模拟和优化现实数据的有效方法。',
  keywords: '数字孪生、digital twin、智慧城市、数字孪生技术、数字孪生的价值、数字孪生应用案例、未来技术、数字创新、未来愿景、orbro、kongtech',

  ogTitle: '',
  ogDesc: '',
  ogSite: '什么是数字孪生？| 定义、应用案例',
  ogUrl: 'https://orbro.io/cn/blog/digital-twin',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDn5W_M0e1npdG9n9MzOVOPXstcQFN609JOUoXoNFXx5-5yloUkGMm1ufjGdF0R9JPeeoW9ykX72SA54O2wG8Vc8sW09dZk2e0IDK9TeXeQEa--HWeYTcyW4jGYAJNHeFvfBs5a4BRrjqNwwxwwrqLUiPrfxt1Kh2LXvniTuSqgVgIjcSz_RxNn3xVuLJqTOqR4rqFbVHkYHahb2a4j49UHtTzHmHnfVmpdEQYWJVViBOrZ3tPK0EWAkyAmPCBugIKyPXfG0iiMHGMupqT-0FPP0g20RLM4zBag-s05hL1Ujp7TBBUm4Ib_MBExPF2QSW0-Ko50dZ7a9hFGGpBrZalaWJMlebmqnC01xrRhTTAcFFNKYYDtFKQNXpb6IQ43Ef9F4-Y4sfGroiz6XZChSKlwLGbp72vSY1vYD7KX3GNlBNvYgCchgmuUmC9myCzEJbjRkKRcKOFGCNqOwXq8Ql04pFpN3XNTkmcedlgJ8xLQIH6FvQ0eIrHRjI7BxmwNtFj2PCeXKhfii-bTYi3ZB9ao0wlQkWQ10gL_ZXLOaWbKY8h_pWysjm2m7iZ2zdJyJac7ZlaVuSwFAHC__vc17P51pGrnB957kwOqxbJPEUrfDsAwD0ucwfa9X5wm2OECrV5cGVJVGBUAz55SEPInk4aouVwJ5eGNvbUoi5mFQOqeqQd-SdcSyc9hMrhBbIK8zigjExJpaRWEchpc5nL5PiJ1Ap2b73ReZfichg5q3S7QYZbk1pQVzRkvF5aDagxH3bEDhyA-eoQBmUwXNZ-O1Rlw2yLhARV_3xTok0wVPRN1PRECRlPCwgkFBEOBCTJCHxr4evnVKMdan_h85axDlA4QL5fLDuhScyp8Yiy3f_L-av_QBdqjVIkovYRjt3Ofnds2NKJbIIEDkgjJ5fnvUgNxJHKpuO3_rrqHmZagGcPtWEq1ZiUTSnmPMFUvd0ip-6GeWiZCwnkJNzazZZ3y_sejVcyeAGQpURR2dl9a3CytaKdgrP0E5kIRrKZqFFzMRvEFEsCPQI1yC6GDAlnMfvv72q5nLzjm-gU_soTcmaBcciDyAbOhEPnFgayRxu0QEDHw-A-SJn8Ub_cYlh-adqEbax34AGDlnxQLW5cgoFo0SsnMA2xD14GMSns8hj8HFgyTd1D9LpDViC5e5qZimjm3K6z56isQsFByGjVb6CYaqdICSjtdtWBEsBFjqqxf3SwLXUKU-sdMuNMmi2rJeO5KsRUv2MjSRY6MIWgv0_nv629SFKM93QxSWT3de2SAWHmDtCI0l6zGKm3ts0heoIfsioTAKjyRw0DabC833amSZ4gIWF5IIwIdc9w1mj7lsTSi5JrZKoyo5ul2TTchUf_9QqFj3u7AYcfhreY7UvlPyJD7gzlp0GY8wyKXBvaFFq9_r84ZA8vOdTNArreD3OAi8owfQOAJ2_j4H3e6nsxTjTpWDLIoz0VPfiiT-1YXwlQ3yD0DMRkGckEnpWrCRwF-JTiI-1Cn2OTi1VgH2L1-Mw0wSen_gD_tT9lcRT4vlYC0eyRhDZlfNZAI2E8RRVwxB50SEeBgj=w1906-h1213',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '什么是数字孪生？| 定义、应用案例',
  date: '2023-12-28',
  tag1: '#数字孪生',
  tag2: '#数字转型',
  tag3: '#智慧城市',
  tag4: '#RTLS',


  text: [
    {
      head: '数字孪生是什么？',
      subHead: '',
      desc: '如果您能在数字屏幕上看到熟悉的地方，如餐厅、办公室、学校、图书馆和公交车站，那该多好呀？而且，如果不是像照片那样的静态图像，而是实时地看到它们，就像我们日常生活中的样子一样，那该多好呀？<br/> 如果您经营着一家餐厅，不记得离开时是否关闭了煤气，您就不需要回去；您可以直接查看。您不需要估计公交车何时到达公交车站，因为您可以看到当前位置！<br/> 数字孪生的概念指的是将现实世界的实体，如人、车辆、建筑物和城市，复制到数字屏幕上的虚拟环境中。<br/><br/> 数字孪生涉及将现实世界的物体或过程数字化建模，以实时收集和分析信息。基本上，它提供了在数字环境中模拟和优化现实世界数据的有效方法。这在工业、建筑、医疗保健和汽车等各个领域都有应用。',
      dot: ''
    },
    {
      head: '数字孪生技术如何工作？',
      subHead: '',
      desc: '数字孪生是将现实世界中的人、物体和建筑物转换为数字形式的技术。其目标是实时收集与对象的位置、状态和性能相关的数据，并在数字屏幕上监视。 <br/><br/>数字孪生技术的运作包括以下主要步骤：',
      dot: ''
    },
    {
      head: '',
      subHead: '数据收集与连接',
      desc: '数字孪生的核心是收集和连接在现实世界中发生的数据。通过传感器、物联网设备等，从各种环境中实时收集的数据被传输到基于云的平台，在那里进行处理和存储。通过这一过程，现实世界的情况被准确地反映到数字环境中',
      dot: ''
    },
    {
      head: '',
      subHead: '建模与模拟',
      desc: '基于收集到的数据，数字孪生精细地对现实世界的物理对象进行建模。该模型考虑了各种变量和环境因素，以预测和模拟现实世界中的行为。通过这一过程，数字孪生可以测试在多种情景下的运作方式，找到最优结果。',
      dot: ''
    },
    {
      head: '',
      subHead: '实时反馈与控制',
      desc: '数字孪生与现实世界实时互动，立即响应变化。数字孪生会自动更新以反映现实世界发生的事件或更改，并在需要时向实际系统发送控制信号。这是数字孪生始终与现实相一致并反映最新信息的必要步骤。<br/>通过这些步骤，数字孪生将现实世界的物理对象数字化，实现实时优化和改进决策的可能性。',
      dot: ''
    },
    {
      video: digitaltwin,
      link: '/cn/digital-twin',
      head: '与智能城市有什么关系？',
      subHead: '',
      desc: '数字孪生在实现智能城市方面发挥着关键作用。通过精确建模和数字化表示城市的物理要素，智能城市可以实时了解和优化城市的状态。例如，通过数字孪生技术预测和调控交通流，可以改善交通系统。此外，通过数字孪生进行环境监测，有助于有效管理空气质量、水资源使用和能源消耗等方面。<br/><br/>数字孪生和智能城市相互作用，互相补充并推动彼此发展。智能城市的各个系统通过数字孪生与现实世界连接，共享实时信息，并根据情况采取行动。这种相互作用有助于综合提高城市的安全性、效率性和生活便利性。',
      dot: ''
    },
    {
      head: '数字孪生的优势',
      subHead: '实时决策',
      desc: '数字孪生通过实时收集和分析现实世界的数据，迅速提供决策所需的信息。这有助于在工作环境中做出迅速响应和基于最新信息的决策，从而增强竞争力。',
      dot: ''
    },
    {
      head: '',
      subHead: '自动化与效率',
      desc: '数字孪生通过与自动化系统有效集成，优化工作流程并提高生产力。基于实际环境数据的自动化决策简化了日常任务，最小化了人为错误，提高了组织的工作效率。',
      dot: ''
    },
    {
      head: '',
      subHead: '成本降低和可预测的维护',
      desc: '利用数字孪生，可以实时监控和预测物理系统的状态。这使得可以进行预防性维护，提前检测系统故障或问题，从而节省成本。此外，基于准确数据的预测使得可以提前计划维护计划，增加可预测性。这有助于通过引入稳定性和效率来有效管理成本。<br/>',
      dot: ''
    },
    {
      head: '数字孪生在工业中的应用案例有吗？',
      subHead: '',
      desc: '',
      dot: ''
    },
    {
      head: '',
      subHead: '制造业中的应用',
      desc: '在制造过程中，利用数字孪生可以提高生产线的效率。通过基于各个阶段产生的数据构建数字孪生，可以实时监控和优化生产环境。这使得可以迅速识别和改进生产线的问题，同时通过预测性维护最小化生产中断时间。此外，数字孪生还可以通过整个产品生命周期监控和优化产品性能，提高质量。',
      dot: ''
    },
    {
      video: video,
      link: '/cn/enterprise/manufacturing/process',
      head: '',
      subHead: '能源行业中的应用',
      desc: '在能源行业，通过利用数字孪生来优化能源生产和消费，提高效率。通过实时监控发电厂设备或基础设施的状态，可以预测和计划维护和修理工作。这有助于提高能源生产的稳定性和效率，通过可预测性维护来有效管理成本。',
      dot: ''
    },
    {
      head: '',
      subHead: '医疗领域的数字孪生',
      desc: '在医疗领域，可以通过数字孪生对患者的健康状况进行建模，以便医疗团队提供个性化治疗。此外，实时监控医疗设备和系统的性能，通过数字孪生可以实现高效运营和迅速响应，提高患者的安全性和便利性。',
      dot: ''
    },
    {
      head: '',
      subHead: '汽车行业的应用',
      desc: '在汽车行业，利用汽车的数字孪生可以优化驾驶性能，并预测维护和修理工作。特别是随着自动驾驶技术的发展，数字孪生在提高汽车环境监测和交互方面发挥着重要作用。通过这种方式，数字孪生可以提高驾驶员的安全性和便利性，同时在汽车的整个生命周期内保持最佳性能。',
      dot: ''
    },
    {
      head: '数字孪生的前景如何？',
      subHead: '',
      desc: '数字孪生技术目前在工业领域迅速应用，并有望在未来带来更高水平的创新。一些主要的前景包括以下几点：',
      dot: ''
    },
    {
      head: '',
      subHead: '引领工业创新',
      desc: '数字孪生有望在制造、建筑、能源、医疗等各个行业中成为创新的中心。特别是当与人工智能的融合、5G技术的普及、大数据分析等相结合时，数字孪生预计将更有效地发挥作用。',
      dot: ''
    },
    {
      head: '',
      subHead: '智能城市的核心要素',
      desc: '数字孪生有望成为智能城市发展的核心技术。通过有效管理城市基础设施、优化交通系统、进行环境监测等多个领域，数字孪生将在智能城市中发挥核心作用。',
      dot: ''
    },
    {
      head: '',
      subHead: '与物联网的整合',
      desc: '通过与物联网的结合，数字孪生有望发挥更强大的功能。随着实时数据收集和分析能力的提高，物理对象的数字建模将变得更加精确。',
      dot: ''
    },
    {
      head: '结论',
      subHead: '',
      desc: '数字孪生打破了物理世界与数字环境之间的边界，提供了创新的解决方案。这项技术在各个行业中实现了效率提升和创新，并有望在更多领域得到应用。<br/><br/>如果您想了解更多关于我们数字孪生技术的信息，请点击下面的链接。',
      dot: ''
    },
    {
      linkText: '了解更多',
      link: '/cn/digital-twin',
      head: '',
      subHead: '',
      desc: '',
      dot: ''
    },

  ],


  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: '将UWB终端安装到工人或设备上，支持实时位置跟踪的产品。',
        img: productImg.product.assetTracking.key1
      },
    ],

    solution: [
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      },
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/cn/enterprise/manufacturing/asset'
      }
    ]
  }

}
