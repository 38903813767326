import { FlexStyle, Vertical } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading } from "@components/Text";
import styled from "@emotion/styled";

export const StyledOurHistory = styled.div`
  width: 100%;
  height: 744px;
  padding: 128px 0;
  background-color: ${({ theme }) => theme['BACKGROUND_PRIMARY']};

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    height: 740px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height: 736px;
  }
`;

export const StyledOurHistorySubtitle = styled(StyledHeading)`
  color: ${({ theme }) => theme['CONTENT_PRIMARY']};
`;

export const StyledOurHistoryTitle = styled(StyledDisplay)`
  margin-top: 16px;
  color: ${({ theme }) => theme['CONTENT_PRIMARY']};
`;

export const StyledOurHistoryNav = styled(FlexStyle)`
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme['BORDER_TRANSLUCENT']};
`;

export const StyledOurHistoryYear = styled(StyledDisplay)`

`;

export const StyledOurHistoryControl = styled(FlexStyle)`

`;

export const StyledOUrHistoryControlIcon = styled.img`
  margin-left: 8px;
  cursor: pointer;
`;

export const StyledOurHistoryContents = styled(Vertical)`
  margin-top: 24px;
`;

export const StyledOurHistoryContentItem = styled(StyledHeading)`
  color: ${({ theme }) => theme['CONTENT_PRIMARY']};
  margin-bottom: 16px;
`;