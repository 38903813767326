import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {


  helmet: '| 利用代替NFC标签的UWB和BLE的库存管理',
  desc: '通过使用UWB和BLE而不是NFC标签，可以更智能、更便捷地利用库存管理系统。立即查看可立即采用的解决方案。',
  keywords: 'nfc、uwb、无线通信、位置跟踪、物体位置跟踪、人员位置跟踪、标签、信标、rtls、orbro、kongtech',

  ogTitle: '',
  ogDesc: '',
  ogSite: '利用代替NFC标签的UWB和BLE的库存管理 :: Orbro博客',
  ogUrl: 'https://orbro.io/cn/blog/nfc-uwb-ble',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDwxW_WSF_F7yNjIa07OkmVhKArBLWz8-_J1moJsQci7Ije4YbYRD5Oa0A3rsNOuVWMRMVKmnI6q7dJm894FnMM4irt8-NIgSx08LF7IIza4ONna0iwlNNedic3hlVYbDPl7746HMAAhJMopboCbqV9PlElgpHpCuSsWKAWobQKY1JrFwdltqWl5FsJqgC4DiIaxM9NUQNJHd4Tt2bOO1lPabNGc27n5QShIyfFcNuGdpozghgJAoszlfuiyD5CVSXKJOXYsfrj1Dhk0cbYrU_RHZfxcle6uATv2tw81Rq5eyY-OCCnNI9WQYLcp5PeiRUydm3S0Ba1eR0DQF-dHz8AvSGqnC2liVRiO-p1TSfr_dtXdAJcEDMSC1_zz2O2dksO3KqdbnvRkpDU-xKCqeel5YaHVAxFFOt19k4SHGVc493DTVSksZhLeWJPTBllKX8oc5zt5qErJMjPZWMUZ2iOr-geb_9fVyyuZogAAj-tFvkAF-mqBapjOwe_z2_8caQhLzg6vnNK3OSmDHQMOLUyZT22pB-OBf0QWVA-9wzMUyIo6BZbUXSj8n0bd6Hq72hFlNnNKwDe2aHoam2KguWNLfBx3PFqYkeO3fbdBRoc8vGkW_FcogVpvOusbERHosgwDP6T-foORllJuVUPjKZyx3CuilLxfRO-fo-4JF0yB8zqC-WZUHzpzyYTN5LZk0nln7wCJNPlMuRtxzJNq_4KrjorS6SdzMmpuYJog9QlrLImyeiR3wOLLvc2bklRyoEQ5T-DcDttw6dzFt1jFu8BjtXKIJ9KCc5PfIgl4XB2T5LshMXFDw7HU2qoFfYHvyQciti_HH59bUaP67x0KwbzAFSnMyP8edq6y4Wsvvhrl5cvYepph4sK7Dn5zkeP1Pv9qa5wNbYn--ZEAND3870LLFBqN5ttPfpxijnKszom0HGZG9G1EGUcU3GeMyAKg5Un-dXh7mWayLTFjU2UIBCRzpU5w509ZaRDokT94aFEyudm035LQQWLoRIV6TInKZ-LaBi6LNpXrykfPgmZb97nPCdULyDlhpp7oYwDLN7wgNy6b0-Wn5Tj9sl8qVTs1o_LrAT-8tl2UZ-uM9MPzHVKPmpPtnDymJklgsbMOp3TK45pIQ9voLx_ESS7YM1S2gw2ihX-Kvs3PqdKmxJ8y6LU8Op4OdaAeuWY1evq4apVEly7tAVqx7Gh5EiLl7PdZnpX1PiwLiz4WnDK0WLV_YOPYQlouBLWy7ojtfLu_C5UjgrjyKBg71KnsCv680b2sBcB-p2Wt6aD6IFtfAlT5J5nzg_cW01H3nwcRUKJdwKsVN-6_vVbyyBX8WGa4yR00BKZ0_scjPlWgID-43QPnz5X6P0IHyJUKtyUTiZM9a5gV7_Raq8rjHTOwYAR4r8je2xkZBJ_jSbV2Dk5yn6wEfK7XhzhLDCOrIoZdOCMhy-Qh610uh34OOtAoLyPBLfWcF_RXsMAGfz73LPe3WJx9YjYMPmRu-dvPHRiBlNrBU2OrvZN1lW3XYjKgGHTt7QlAKY2D5XpDjGA8NsfGVcpLghIXt-HCy32YCFiUdpQr5rq6mf7hfmJr=w1871-h1190',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '利用代替NFC标签的UWB和BLE的库存管理',
  date: '2023-11-20',
  tag1: '#nfc',
  tag2: '#uwb',
  tag3: '#ble',
  tag4: '#库存管理',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: '高效的库存管理在企业的生产和分销核心活动中扮演着至关重要的角色。因此，RFID和NFC标签一直以来都被广泛使用。然而，随着UWB（超宽带）和BLE（低功耗蓝牙）技术的出现，更先进的库存管理系统成为可能。特别是，UWB和BLE作为NFC标签的替代方案备受关注。',
      dot: ''
    },
    {
      video : video,
      link : '/cn/enterprise/manufacturing/asset',
      head: 'UWB和BLE的特点',
      subHead: '1. UWB（超宽带）',
      desc: 'UWB是一种使用极宽带的无线通信技术，可以进行精准的位置跟踪。通过提供精准的位置数据，它在实时库存跟踪和位置识别方面表现出色。此外，它通过避免多径干扰来提供稳定的通信，从而实现准确的数据收集。',
      dot: ''
    },
    {
      head: '',
      subHead: '2. BLE（低功耗蓝牙）',
      desc: 'BLE是一种低能耗的蓝牙技术，适用于低功耗设备之间的高效数据通信。BLE标签体积小，电池寿命长，可构建能效高的库存跟踪系统。此外，BLE与智能手机的高兼容性使其易于使用。',
      dot: ''
    },
    {
      head: '替代NFC标签的优势',
      subHead: '1. 精准的位置跟踪',
      desc: 'UWB技术提供非常精准的位置信息，可以实时跟踪仓库或生产线上物品的精确位置。这提高了库存管理的准确性，并将库存损失和错误降至最低。',
      dot: ''
    },
    {
      head: '',
      subHead: '2. 低功耗运行',
      desc: 'BLE的低功耗特性使标签能够长时间运行。这种优势导致了增加电池更换周期和节省维护成本。',
      dot: ''
    },
    {
      head: '',
      subHead: '3. 高兼容性',
      desc: '由于BLE在许多设备上默认支持，因此它可以轻松集成到现有基础设施中。特别是与智能手机的兼容性为无线库存跟踪提供了便利。',
      dot: ''
    },
    {
      head: '实施策略',
      subHead: '1. 引入UWB和BLE标签',
      desc: '第一步是用UWB和BLE标签替换现有的NFC标签。这确保了精准的位置跟踪和低功耗操作的优势。',
      dot: ''
    },
    {
      head: '',
      subHead: '2. 建立数据平台',
      desc: '为了有效管理从UWB和BLE收集的数据，需要一个高效的数据平台。引入基于云的数据存储和分析平台，实现实时数据处理和分析。',
      dot: ''
    },
    {
      head: '',
      subHead: '3. 系统集成',
      desc: '将使用UWB和BLE的库存管理系统与现有系统集成。这将所有数据集中管理，提高生产力和效率。',
      dot: ''
    },
    {
      head: '结论',
      subHead: '',
      desc: '利用UWB和BLE替代NFC标签的库存管理系统提供了诸多优势，包括精度、低功耗运行和兼容性。采用这些技术的企业预计将建立更为高效和智能的库存管理系统，确保竞争力。预计更多企业将采用这些创新技术以提升竞争力。',
      dot: ''
    },

  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '通过将UWB终端安装到工人或设备上，帮助实时位置跟踪的产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/cn/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/cn/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/cn/enterprise/office/asset'
      }
    ]

  }


}
