import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: 'ORBRO innovatively enhances building management and planning through the digitization of indoor and outdoor construction structures and infrastructure. This provides crucial information for architectural design, facility management, and safety monitoring.',
  keywords: 'Digital Twin, Digital Twins, 디지털 트윈, gis, 디지털 시티, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - Implementation of Building and Indoor Information',
  ogUrl: 'https://orbro.io/en/blog/digitaltwin-buildings',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twin - Implementation of Building and Indoor Information',
  date: '2023-12-26',
  tag1: '#digitaltwin',
  tag2: '#digitalcity',
  tag3: '#digitalbuildings',
  tag4: '#virtualreality',

  text: [
    {
      head: 'Digital Twin - Implementation of Building and Indoor Information',
      subHead: '',
      desc: `ORBRO innovatively implements building and indoor information to enhance building management and planning. To achieve this, it digitizes architectural structures and infrastructure, providing essential information for architectural design, facility management, and safety monitoring through diverse data.<br/><br/>Detailed representations of the building's appearance and structural features are conveyed through high-resolution images and 3D modeling, while indoor maps precisely visualize the layout inside the building. Such information aids in a clear understanding of the visual representation and structure of the building.<br/><br/>Additionally, the representation of infrastructure information within the building, real-time equipment status checks, and external equipment integration play a crucial role in building management, maintenance, and automation. This allows real-time confirmation of the data required for building operation and management, enabling efficient control.`,
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/KkvO62eClcU?si=AQmNMfH6nmQ5Aa3Z',
      head: '',
      subHead: 'Detailed Graphic Representation of Buildings',
      desc: 'ORBRO utilizes high-resolution images and advanced 3D modeling technology to vividly depict the exterior and structural features of buildings. This plays a crucial role in evaluating the design and functionality of buildings by architects, engineers, and urban planners.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Indoor Map Representation',
      desc: 'Precisely visualizing the layout of the building\'s interior maximizes the utilization of indoor spaces and provides essential information for safety and evacuation planning.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Representation of Infrastructure Information within Buildings',
      desc: 'Digitizing critical infrastructure within the building, such as HVAC systems, electrical and plumbing systems, provides essential information for building management and maintenance.',
    },
    {
      head: '',
      subHead: 'Real-time Equipment Status Checks',
      desc: 'Monitoring equipment and systems within the building in real-time allows for quickly detecting performance degradation or malfunctions, facilitating immediate maintenance.',
    },
    {
      head: '',
      subHead: 'External Equipment Integration',
      desc: 'Integration with smart technology and IoT devices enhances the intelligence and automation of building management systems.',
    },
    {
      head: '',
      subHead: 'Developer Center Support',
      desc: 'Supporting developers in accessing building-related data and developing custom applications introduces innovative solutions for building management and operations.',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that assists real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      // Additional product objects can be added here
    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/en/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      }
    ]
  }
}
