export default {

    overview : {
        title : 'Digital Twin Specialist Platform | Orbro World',
        key :'Smart City, Digital Transformation, Location Tracking, Asset Management, Asset Tracking, Warehouse Management, Warehouse Asset Management, Indoor GPS, Indoor Location Tracking, RTLS, Location Tracking Solution, Major Accident Punishment Act, Construction Site Safety, Visitor Path Analysis, Access System, 4th Industrial Revolution, Smart Factory, Digital Twin, Beacon, Bluetooth, Kong-tech, Real-Time Location System, Indoor Location Tracking, Asset Tracking, Asset Management, Asset Tracking, Warehouse Management, Warehouse Asset Management, Indoor Location Tracking, RTLS, Location Tracking Solutions, Construction Site Safety, Visitor Path Analysis, Access System, 4th Industrial Revolution, Smart Factory, UWB RTLS, Digital Twin, Beacon, Bluetooth, BLE, UWB, IoT',
        img : 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        desc : 'A digital twin receives and monitors real-world data of people, objects, buildings, and city in real-time on a virtual screen, allowing for monitoring and management. This enables productivity improvement and problem prevention, primarily used in industrial and manufacturing sectors.',
        url : 'https://orbro.io/en/igital-twin',
    },

}
