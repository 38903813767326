import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading } from "@components/Text";
import styled from "@emotion/styled";

export const StyledOurPartners = styled.div`
  width: 100%;
  padding: 128px 0 132px;
  background-color: ${({ theme }) => theme['BACKGROUND_SECONDARY']};

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding: 96px 0;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 96px 0;
  }
`

export const StyledOurPartnersHeading = styled(StyledHeading)`
  text-align: center;
`;

export const StyledOurPartnersDisplay = styled(StyledDisplay)`
  text-align: center;
  margin-top: 16px;
`;

export const StyledOurPartnersLogos = styled(StyledGridRow)`
  margin-top: 96px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-top: 64px;
  }
`;

export const StyledOurPartnersLogo = styled(StyledGridColumn)`
  & img { 
    max-width: 100%;
    max-height: 90px;
    margin: 0 auto;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
      max-height: 65px;
    }
  }
`;