import { HeadingH3 } from "@components/Text/Heading/Heading.style";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";
import { ImgWH, ParagraphColor, Spacing, SpacingDTM } from "@pages/CommonStyle/common.style";
import { DivFlexColumn, DivGrid, DivGridGap } from "@pages/CommonStyle/commonDiv.style";
import { DivPaddingRight } from "@pages/Enterprise/Technology/Technology.style";
import { CommonState } from "@redux/reducers/commonReducer";
import os from "@src/lang/ko/main/os/os";
import React from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";

const Functions = ({ layoutType }) => {

  const padding = {
    desktop: '105',
    tablet: '118',
    mobile: '0'
  }

  return (
    <>
      <SpacingDTM d={"128"} t={"96"} m={"64"} />
      <DisplayH2 size={"small"}>
        <Trans i18nKey={`os.function.title`} />
      </DisplayH2>

      <SpacingDTM d={"96"} t={"96"} m={"48"} />

      <DivGridGap cnt={2} c_gap={32} r_gap={64}>
        {os.function.box.map((data, i) => (
          <>
            <DivPaddingRight pixel={padding[layoutType]} key={i}>
              <DivFlexColumn>
                <ImgWH src={data.icon}
                  w={layoutType === 'mobile' ? "80" : "100"} h={layoutType === 'mobile' ? "80" : "100"}
                  alt="ORBRO Os Functions Icon" />
                <SpacingDTM d={"32"} t={"32"} m={"24"} />
                <HeadingH3 size={"xsmall"}>
                  <Trans i18nKey={`os.function.box.${i}.title`} />
                </HeadingH3>
                <Spacing pixel="8" />
                <ParagraphColor color={"#000"} size={"medium"}>
                  <p>
                    <Trans i18nKey={`os.function.box.${i}.desc`} />
                  </p>
                </ParagraphColor>
              </DivFlexColumn>
            </DivPaddingRight>
          </>
        ))}
      </DivGridGap>

      <SpacingDTM d={"128"} t={"96"} m={"96"} />
    </>
  )
}


export default Functions;