import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet: '| Smart Logistics Center: Becoming a Game Changer with RTLS',
  desc: `To build a smart logistics center, RTLS technology is essential. Let's explore the functionalities that RTLS technology will bring as a game-changer in the logistics industry.`,
  keywords: 'Large logistics warehouse management, Logistics center management, Logistics warehouse management, Smart logistics, Inventory management, Smart inventory management, Logistics warehouse inventory management, Large logistics warehouse inventory management, ORBRO, Real-time location tracking, rtls, uwb, KongTech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Smart Logistics Center: Becoming a Game Changer with RTLS',
  ogUrl: 'https://orbro.io/en/blog/logistics',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4Nn92GBAhAnqBNHPYNQDpB-QpINtYBr8NP1whgOrOeiJ231ayd-ir1pFXYQyPNn6ORiGuT9r9NH8WMwpUVVIJuDIDCcxjED8rHcdEkrjVEabQroYsK8zKZBFb5y7MqX52j_Hsy7L5ePRwrLj1l5AgzzhwwwRDy0SSWbba5oN-o3v4VK9ulJDIn-AlRzUvK-2_ExWeqxWtqjdDVGF780Q1Fyw9IIdObKFPnjkNtFBBQtMXHmKC4K3NY-PU75is1fvZGtz95FAVwQW8NW-iuI9-V6GgxYKoSSaE0VpltJTXpVeUwTA-55-8pGVLMV1jPeO1vGqDiJJsSQfFQE7xxXm6-UZvVDalr763kudRcjaH6dWNitPT2w4_qxEV3EivvyxvKRAKilLymJIzk-KgoGkU4hhuVrqpelXsjpiyAVrTRehdny-SFdJeuDk7TnnLXSFTf8rG1zgj4OOJcXrXqDmjNcKnSmD3gyrnY_7gEkAde2Vn2Wnm9hQewDJ1YDCx926J3unTLJApvy7uGKDbgGwZY0jfHbj3_EaB-5t5TBucsd2CukDZvZ27jfvLUUe1Wrr0-U21oag_JP6AeJNO-Thzp8fvPd1rmpOiKsPwlCOEdTn-aVu9hoAWByLe-OAQ_weFNJB2jqIOrRDEiEdsF2Ux_D4H4UY0gjcEi6EuIl2SaJ7GQEfGt0uQ1BSYXOm6_Ljse4BNu1hSdj9Iqtg7MstD1cwgxIVmXk20C1GudLlOL14w-EOHYMhOg-CdEf_6i7jOS4F-HZVe98fBNM8s4m5pjIUFngbQ9Ig158BQ2ZwJB2t05BgqvZDYMA33z6z0iOiRAnmT6J9sYM2yx4itS08KP1CgjFduRqis5fJcg9eFZuZJf5Hfd-vWgWD6fl5ssh6du1AEaEBLfbbmiNhDihCB-M0jHlLuDCL-wWrFKTCo3ylcA89Xivl7Hca0rY3s6JW_VfSwboGpYKJnMKpZqynEuHGMXoYXQ3jFpIQPj2xOElK8OLo1o3oQdjWPzTb4vUKBHiBzM6qkfsUZAL4b-zqcL2KxE1jgAyweOBCYpp3CdQ7LCgDO6I5VfS8lzcNBPnPq8_5OkN_r6xGcemqmtbzIIFQonHkEIYSOl0km6axb9eXkmCacj_0lr5oyGA8xGjLFN85rAsznmLomsfvDmvk8qx1fT8OQGW0Fsqgeeoujl6HOsXsGEVr-4dP1EupFbf4WR-v8GPCBN-c9FSQ7472yBB8ES-QlDo-xEP0xB8bn0ogShH5PCnzG3aQ1Y8fpAK_nzqUPpkZDW0HEUKQw6shyWu9MqPLKD1IbYuzWo_9Vba83qhFFvye5IxqyGiZr2SG2wZpmu1kWNjSG89Br3ZMTKQ2XSVpURse0EKs4abuIUAjtf7O1t3PVWF1Hm8R2Pllgb36whs0ZsXEgvM3gAghdJ_T8ydCqOD76BitUXjI_oV_2cn7KlbYyMsT90CHLr6NrT0ZW3B0s-hDXnGSiwvDM7ZCywF8XguZFj2X3konPXb1o1blj-A6LDR-ROdDTdsc9jBotrseWFE7qTdeVNs92ZxvaiWGvziDNdVARHQAwQIY5krEfq8B6DpkghJNg=w2194-h1199',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Smart Logistics Center: Becoming a Game Changer with RTLS',
  date: '2023-09-04',
  tag1: '#LargeLogisticsCenter',
  tag2: '#SmartLogistics',
  tag3: '#InventoryManagement',
  tag4: '#LogisticsManagement',
    
  text: [
    {
      head: '',
      subHead: '',
      desc: `A smart logistics center presents a significant departure from the traditional logistics industry in terms of efficiency and productivity. By efficiently managing assets, inventory, and labor, smart logistics center construction has become essential for business growth and competitiveness. To achieve this, the adoption of Real-Time Location System (RTLS) technology is necessary. <br/>Let's explore what becomes possible when real-time location tracking solutions are introduced to smart logistics centers.`,
      dot: ''
      
    },
    {
      head: '',
      subHead: '1. Optimization of Asset Management and Tracking',
      desc: 'RTLS technology is used to track all assets and equipment within a logistics center in real-time. This allows logistics center managers to accurately determine the location and status of assets. For example, by tracking the location of cargo trailers or forklifts in real-time, asset utilization can be optimized, and maintenance can be efficiently planned when needed, resulting in reduced asset management costs and extended asset lifespans.',
      dot: ''
      
    },
    {
      head: '',
      subHead: '2. Enhanced Inventory Management Accuracy',
      desc: 'Through RTLS, the location of products and inventory can be tracked in real-time. This revolutionizes inventory management. Knowing precisely where products are located within a logistics center minimizes inventory losses and optimizes inventory turnover. This leads to cost savings and improved efficiency.',
      dot: ''
      
    },
    {
      head: '',
      subHead: '3. Real-Time Data Analysis',
      desc: 'RTLS generates real-time data, enabling the monitoring and optimization of logistics center operations in real-time. If bottlenecks occur in any process within the logistics center, administrators can take immediate action to resolve the issues. This also has a positive impact on customer service.',
      dot: ''
      
    },
    {
      head: '',
      subHead: '4. Labor Optimization',
      desc: 'RTLS also aids in labor management. By tracking worker movements and work hours, the efficiency of workers can be improved, and work schedules can be optimized. Efficiently deploying workers in logistics centers and adjusting tasks using RTLS can lead to labor cost savings and increased productivity.',
      dot: ''
      
    },
    {
      head: '',
      subHead: '5. Improved Customer Service',
      desc: 'RTLS plays a crucial role in order processing and delivery processes. By tracking the real-time location of products, accurate estimated arrival times can be provided to customers, enhancing the quality of customer service. Customers receive information about when products will arrive, leading to higher satisfaction levels.',
      dot: ''
      
    },
    {
      head: '',
      subHead: '6. Enhanced Safety and Security',
      desc: 'RTLS enhances safety and security in logistics centers. By accurately tracking the location of equipment and assets, unauthorized intrusions and inventory theft can be prevented. Regular inspections and maintenance of equipment also contribute to safety assurance.',
      dot: ''
      
    },
    {
      head: '',
      subHead: '7. Strengthened Environmental Friendliness',
      desc: 'Smart logistics centers can improve energy and resource efficiency. RTLS helps optimize resource usage and reduce energy consumption. For example, automatically adjusting lighting and machinery within logistics centers can optimize energy consumption.',
      dot: ''
      
    },
    {
      head: '',
      subHead: '',
      desc: 'RTLS technology is leading innovation in the logistics and supply chain management fields. By implementing RTLS technology in smart logistics centers, various advantages such as cost savings, improved efficiency, enhanced customer service quality, and strengthened environmental friendliness can be achieved. These advantages enhance competitiveness and provide opportunities for more effective logistics and supply chain management. Therefore, companies aiming to establish smart logistics centers should actively consider RTLS technology.',
      dot: ''
      
    },

  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: 'A product that aids real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution: [
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      },
      {
        img: logisticsSafety.thumb,
        data: 'logisticsSafety',
        link: '/en/enterprise/logistics/safety'
      }, 
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/en/enterprise/manufacturing/asset'
      }
    ]

  }


}
