import React, { useMemo, useRef } from 'react';

import access from '@src/lang/ko/main/access/access';
import { StyledGridContainer } from '@components/Grid/Grid.style';
import productScheme from '@src/lang/ko/main/camera/productScheme';
import { IconCheckBlack } from '@assets/images/icon';
import { StyledCareerSeciton } from '@src/pages/Main/Access/components/Main/Slider.style';

import {
  StyledBox, StyledImgBox, StyledTextBox, StyledImg,
  StyledSubTextBox, StyledCheckBox, StyledGapBorder, StyledBtn
} from '@pages/Main/Access/components/Scheme/SchemeMobile.style';
import { StyledHeading, StyledLabel } from '@components/Text';
import { indoor280, outdoor280 } from '@assets/images/main/camera';
import { LabelColor, Spacing } from '@pages/CommonStyle/common.style';
import { DivAllCenter, DivFlexRow } from '@pages/CommonStyle/commonDiv.style';
import { StyledDesc, StyledLink } from '@pages/Main/Common/Scheme/Scheme.style';
import { Trans } from 'react-i18next';
import { DisplayH2 } from '@components/Text/HeadingTagDisplay.style';
import { LabelH3 } from '@components/Text/Label/Label.style';

const SchemeMobile = ({ handleIconClickLocal }): React.ReactElement => {


  // Datas
  const list = useMemo(() => productScheme.a, []);

  const handleIconClick = (group) => {
    handleIconClickLocal(group);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const outputData = ["a", "b", "c"]
  const outputList = ["설명", "통신 방식", "해상도", "조리개", "방수 방진", "작동 조건", "전원"]

  const borderSwitchRef = useRef(true);

  const images = useMemo(() => [
    {
      mobile: outdoor280
    },
    {
      mobile: indoor280
    },
    // Add more image objects as needed
  ], []);


  return (
    <StyledCareerSeciton>
      <StyledGridContainer>

        <Spacing pixel='96' />

        <DivAllCenter>
          <DisplayH2 size={'small'}>
            <Trans i18nKey={`camera.banner.schemeTitle`} />
          </DisplayH2>
        </DivAllCenter>

        <StyledDesc size={'medium'}>
          <p>
          <Trans i18nKey={`camera.banner.schemeDesc`} />
          <StyledLink size={'medium'} inline>
            <Trans i18nKey={access.banner.phone} />
          </StyledLink>
          <Trans i18nKey={`camera.banner.schemeDesc2`} />
          </p>
        </StyledDesc>


        {Array(2).fill(null).map((_, index) => (
          <StyledBox key={index}>
            <StyledHeading size="xxxsmall">  <Trans i18nKey={`productSchemeCamera.${outputData[index + 1]}.0.text`} /></StyledHeading>
            <StyledImgBox>
              <StyledImg src={images[index].mobile} alt="RTLS solution">

              </StyledImg>
              <DivFlexRow>
                <StyledBtn onClick={() => handleIconClick(index + 1)}>
                  <Trans i18nKey={`home.showMore`} />
                </StyledBtn>
              </DivFlexRow>

            </StyledImgBox>
                        <Spacing pixel='16' />

            {borderSwitchRef.current = true}
            {list.map((data, i) => (
              <>
                {outputList.includes(data.text) && (
                  <>
                    <StyledTextBox key={i}>
                      <LabelH3 size="small">
                        <Trans i18nKey={`productSchemeCamera.a.${i}.text`} />
                      </LabelH3>
                      <Spacing pixel='4' />
                      <LabelColor size="medium" color="#646464">
                        <p>
                          <Trans i18nKey={`productSchemeCamera.${outputData[index + 1]}.${i}.text`} />
                        </p>
                      </LabelColor>
                    </StyledTextBox>

                  </>
                )}

                {productScheme[outputData[index + 1]][i]?.text === `1` && (
                  <>
                    {borderSwitchRef.current && (
                      <>
                        <Spacing pixel='16' />
                        <StyledGapBorder />
                        {borderSwitchRef.current = false}
                      </>
                    )}
                    <StyledSubTextBox>
                      <StyledCheckBox src={IconCheckBlack} alt='checkbox'/>
                      <StyledLabel size="medium">
                        <p>
                          <Trans i18nKey={`productSchemeCamera.a.${i}.text`} />
                        </p>
                      </StyledLabel>
                    </StyledSubTextBox>
                  </>
                )}


              </>
            ))}

          </StyledBox>

        ))}

      </StyledGridContainer>
    </StyledCareerSeciton>
  );
};

export default SchemeMobile;
