import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconUserCheck, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { IconTemp } from "@assets/images/main";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/medicalWaste/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/medicalWaste/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/medicalWaste/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/medicalWaste/video/sub/rtlsVideo3.mp4";


export default {
    banner: {
      title: 'Medical Waste Management',
      desc: 'Medical waste management is an essential task that involves the safe and proper handling of various types of waste generated in healthcare facilities. It aims to prevent environmental pollution, minimize the risk of infection, and comply with legal regulations to contribute to human health and resource conservation. Real-time tracking of the status and location of medical waste enables proactive incident response and ensures the provision of safe medical services.',
    },
    video: {
      title: 'The Most Reliable Medical Waste Management Method',
      desc: 'Real-time Location Tracking System (RTLS) technology tracks the status and location of medical waste. By complying with safety regulations, it enables accurate and effective management of medical waste.',
      play: video,
    },
    func: [
      {
        title: 'Real-time Waste Status Monitoring',
        desc: 'Real-time monitoring of the types and quantities of medical waste allows for predicting and managing waste volumes and disposal periods, ensuring efficient waste management.',
        sub1: 'High Accuracy',
        icon1: IconRulerGray,
        sub2: 'Fast Data Integration',
        icon2: IconShareGray,
        video: subVideo1,
      },
      {
        title: 'Real-time Movement Tracking',
        desc: 'Medical waste requires special handling, and real-time tracking during contact with healthcare professionals or patients enables proactive incident prevention and instills confidence in patients.',
        sub1: 'Designating Hazardous Assets',
        icon1: IconWarningGray,
        sub2: 'Setting Response Methods',
        icon2: IconBellGray,
        video: subVideo2,
      },
      {
        title: 'Automated Asset Management',
        desc: 'Automated asset management of medical waste ensures efficient operations, reduces human errors, enhances real-time response capabilities, ensures safe disposal, and enables compliance with regulations in healthcare facilities.',
        sub1: 'High Accuracy',
        icon1: IconRulerGray,
        sub2: 'Fast Data Integration',
        icon2: IconChainLinkGray,
        video: subVideo3,
      },
    ],
    subfunc: {
      title: 'Various Features',
      desc: 'Medical waste management requires continuous attention, as even minor negligence or mistakes can lead to significant medical incidents. Therefore, precise management and guaranteed safety are crucial. ORBRO provides various features to establish a safe and efficient healthcare facility.',
      data: [
        {
          icon: IconChartRadius,
          title: 'Reports and Analysis',
          desc: 'Generate statistical reports and perform data analysis based on the inflow and outflow data of medical waste.',
        },
        {
          icon: IconBell,
          title: 'Real-time Alerting',
          desc: 'Send immediate alerts when patients come into contact with high-risk medical waste.',
        },
        {
          icon: IconRoute,
          title: 'Waste Route Tracking',
          desc: 'Track the route of medical waste to prevent safety incidents.',
        },
        {
          icon: IconDashboard,
          title: 'Status Overview',
          desc: 'Real-time monitoring of the waste status in medical waste storage warehouses.',
        },
        {
          icon: IconTemp,
          title: 'Environmental Analysis',
          desc: 'Monitor environmental factors such as temperature and humidity inside medical waste storage facilities to detect abnormalities in real-time.',
        },
        {
          icon: IconUserCheck,
          title: 'Improved Workflow Efficiency',
          desc: 'Automated asset management systems enhance workflow efficiency and reduce human errors.',
        },
        {
          icon: IconChainLink,
          title: 'Data Integration',
          desc: 'Integrate systems such as RFID, IoT, security, and video to leverage data efficiently.',
        },
        {
          icon: IconSecurity,
          title: 'Data Security',
          desc: 'Maintain a high level of data security to provide a reliable solution.',
        },
      ],
    },
    system: {
      title: 'System Configuration',
      img: rtlsSystem,
    },
    productTitle: 'Product Configuration',
    product: [
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'medicalPatient',
      },
      {
        data: 'officeAsset',
      },
      {
        data: 'blogMedical',
      },
      {
        data: 'blogOffice',
      },
    ],
  }
  