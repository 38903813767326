import { ptzThumb } from "@assets/images/main/camera";

export default {

    mobile: {
        title: '移动通行系统 | 奥布罗产品',
        key: '自动通行，门禁系统，智能通行，移动通行，RTLS，非接触通行，蓝牙，奥布罗，orbro',
        desc: '奥布罗的移动通行系统是一款内置电池的产品，可以轻松快速地通过移动设备控制门禁，无需进行电力施工。它是一种高速、便捷且具有合理价格的下一代通行系统。',
        img: '',
        url: 'https://orbro.io/cn/access/mobile',
    },
    auto: {
        title: '自动门通行系统 | 奥布罗产品',
        key: '自动门，自动通行，门禁系统，智能通行，移动通行，RTLS，非接触通行，蓝牙，奥布罗，orbro',
        desc: '奥布罗的自动门通行系统专为自动门通行而设计，通过安装移动应用程序实现非接触通行。它具有时尚智能的设计，提供便利性。',
        img: '',
        url: 'https://orbro.io/cn/access/auto',
    },
    face: {
        title: '人脸识别系统 | 奥布罗Access产品',
        key: '人脸识别，自动通行，门禁系统，智能通行，非接触通行，奥布罗，orbro',
        desc: 'Access Pro用于控制和呼叫建筑物共用入口的门禁产品。使用此产品，您可以通过移动应用实时视频通话用户，远程控制入口。此外，它还支持NFC卡和密码解锁，可替代传统的共用入口控制系统。',
        img: '',
        url: 'https://orbro.io/cn/access/face',
    },
    accessory: {
        title: '门禁配件 | 奥布罗产品',
        key: '蓝牙，奥布罗，orbro',
        desc: '奥布罗提供高效且经济实惠的智能通行产品。您可以使用各种配件构建定制的通行系统。',
        img: '',
        url: 'https://orbro.io/cn/access/accessory',
    },
    ble: {
        title: '蓝牙（BLE）位置跟踪 | 奥布罗产品',
        key: '位置跟踪，实时位置跟踪，RTLS，蓝牙，Beacon，BLE，资产管理，资产跟踪，库存管理，人员跟踪，室内位置跟踪，GPS，奥布罗，orbro',
        desc: '奥布罗的基于BLE的位置跟踪产品允许您通过附加BLE标签设备来跟踪资产或目标的位置。它基于稳定而高效的系统，提供准确的数据。',
        img: '',
        url: 'https://orbro.io/cn/rtls/ble',
    },
    uwb: {
        title: '超宽带（UWB）位置跟踪 | 奥布罗产品',
        key: '位置跟踪，实时位置跟踪，RTLS，蓝牙，Beacon，BLE，UWB，资产管理，资产跟踪，库存管理，人员跟踪，室内位置跟踪，GPS，奥布罗，orbro',
        desc: '奥布罗的UWB基础的高精度位置跟踪技术可实时追踪资产或人员的位置，具有卓越的稳定性、效率和经济性。',
        img: '',
        url: 'https://orbro.io/cn/rtls/uwb',
    },
    outdoor: {
        title: '室外摄像机 | 奥布罗Camera产品',
        key: '摄像机位置跟踪，PTZ，实时位置跟踪，车辆位置跟踪，智能停车，RTLS，蓝牙，Beacon，BLE，UWB，资产管理，资产跟踪，库存管理，人员跟踪，室内位置跟踪，GPS，奥布罗，orbro',
        desc: '通过摄像机可以检测和管理人、车辆等各种对象的位置和行为。它提供有关车辆位置和人员活动的实时精确数据。',
        img: '',
        url: 'https://orbro.io/cn/camera/outdoor',
    },
    indoor: {
        title: '室内摄像机 | 奥布罗Camera产品',
        key: '摄像机位置跟踪，IP，实时位置跟踪，车辆位置跟踪，智能停车，RTLS，蓝牙，Beacon，BLE，UWB，资产管理，资产跟踪，库存管理，人员跟踪，室内位置跟踪，GPS，奥布罗，orbro',
        desc: '基于高精度的位置跟踪技术，可以追踪室内人员的移动和库存位置，提供准确的数据。',
        img: '',
        url: 'https://orbro.io/cn/camera/indoor',
    },
    bell: {
        title: '紧急铃 | 奥布罗IoT产品',
        key: 'IoT，物联网，安全铃，紧急报警按钮，紧急报警，报警按钮，警察呼叫，紧急情况，安全铃，119，112，女性安全，Beacon，蓝牙，防骚扰，骚扰举报，奥布罗，orbro',
        desc: '安全铃可用于紧急情况下进行紧急报警。它可以随时随地安装，并为快速报警提供了最有效的解决方案。',
        img: '',
        url: 'https://orbro.io/cn/iot/bell',
    },
    energy: {
        title: '电力控制 | 奥布罗产品',
        key: '电力控制，智能电力，智能开关，智能按钮，数字开关，开关，蓝牙，远程控制开关，智能灯泡，奥布罗，orbro',
        desc: '智能开关是一种可将模拟开关轻松替换为数字开关的产品，可减少不必要的电力浪费和时间浪费。',
        img: '',
        url: 'https://orbro.io/cn/iot/energy',
    },
    display: {
        title: '显示屏 | 奥布罗IoT产品',
        key: '电力控制，智能电力，智能开关，智能按钮，数字开关，开关，蓝牙，远程控制开关，智能灯泡，奥布罗，orbro',
        desc: 'ESL是基于Beacon技术的显示设备。由于采用E-ink显示屏，因此可以在低功耗下使用显示功能。',
        img: '',
        url: 'https://orbro.io/cn/iot/display',
    },
    cradle: {
        title: '无线充电座 | 奥布罗IoT产品',
        key: 'IoT，物联网，女性安全，奥布罗，orbro',
        desc: '无需电缆锁定装置，即可通过无线方式管理智能手机。',
        img: '',
        url: 'https://orbro.io/cn/iot/cradle',
    },
    idCard: {
        title: '员工证 | Orbro 产品',
        key: '员工证, 智能员工证, IoT, 物联网, 女性安全, 无接触技术, Orbro, orbro',
        desc: '智能员工证是以项链形式设计，可以在工业和服务领域都高度兼容使用。也可以与 Orbro 的其他服务集成。',
        img: '',
        url: 'https://orbro.io/cn/iot/id',
    },
    loraGateway: {
        title: 'LoRa 网关 | ORBRO 产品',
        key: 'LoRa 网关、LPWAN 网关设备、远程无线设备、物联网通信解决方案、无线传感器网络设备、LoRaWAN 网关设备、省电物联网通信、智能城市 IoT 解决方案、无线 IoT 设备销售、无线传感器网络解决方案、IoT 设备采购、无线通信系统、无线 LoRa 网关、无线 IoT 设备制造商、LoRa 网关价格、LPWAN IoT 设备、IoT 通信解决方案提供商、传感器网络系统、智能城市通信设备、省电力无线网络、ORBRO、Kongtech、orbro、实时位置追踪',
        desc: 'LoRa 网关产品基于远程通信技术，在物联网通信领域发挥重要作用。这些网关提供节能解决方案，并在智能城市、农业、环境监测、工业自动化等多个领域得到广泛应用。我们的 LoRa 网关通过稳定高效的数据传输满足客户需求，支持物联网项目的成功实施。',
        img: '',
        url: 'https://orbro.io/cn/sensor/LoRaGateway'
    },
    loraSensor: {
        title: 'LoRa 传感器 | ORBRO 产品',
        key: 'LoRa 传感器、LPWAN IoT 传感器、远程无线传感器、物联网传感器网络、无线环境检测传感器、省电 IoT 设备、智能城市传感器解决方案、无线环境监测、IoT 传感器设备、无线环境监测设备、环境传感器网络、无线 LoRa 传感器技术、环境监测系统、IoT 环境传感器销售、LoRa 传感器网络、LPWAN IoT 技术、智能城市环境监测、无线环境传感器制造商、LoRa 传感器价格、LPWAN IoT 传感器、IoT 传感器解决方案提供商、ORBRO、Kongtech、orbro、rtls、实时位置追踪',
        desc: 'LoRa 传感器是一种使用省电力无线技术进行环境监测和实时数据采集的理想解决方案。我们的 LoRa 传感器产品能够快速监测各种参数，如空气质量、温度、湿度、气体浓度等，广泛应用于智能城市、农业、工业自动化、环境监测等领域。这些传感器具有可靠性和精度，提高了数据采集效率。',
        img: '',
        url: 'https://orbro.io/cn/sensor/LoRaSensor'
    },
    airQuality: {
        title: '空气质量监测仪 | ORBRO 产品',
        key: '工业用空气质量监测仪、大气质量测量设备、工厂用大气污染监测仪、空气质量监测设备、环境监测解决方案、空气质量分析设备、工业用大气污染传感器、空气质量监测设备、工业用大气质量测量解决方案、大气质量监测系统、工业环境监测设备、大气质量传感器网络、环境监测解决方案提供商、工业用空气质量传感器销售、空气质量监测设备、工业环境传感器技术、空气质量测量设备价格、工厂用环境监测系统、工业用空气质量传感器制造商、大气质量监测解决方案提供商、ORBRO、Kongtech、orbro、rtls、实时位置追踪',
        desc: '空气质量监测仪是在室内和室外监测空气质量以准确监测和改善环境的重要工具。这些监测仪能够实时测量微粒子、CO2、温度、湿度等各种大气质量指标，广泛用于智能城市、建筑物管理、农业、工业等领域。使用这些CO检测器来监测和控制大气中的CO浓度，以确保安全和健康的环境。',
        img: '',
        url: 'https://orbro.io/cn/sensor/air'
    },
    coMeasure: {
        title: '一氧化碳检测器 | ORBRO 产品',
        key: '一氧化碳检测器、CO 气体传感器、一氧化碳浓度测量设备、CO 监测销售、环境 CO 监测设备、一氧化碳检测设备、CO 传感器价格、气体浓度测量设备、一氧化碳传感器、CO 浓度监测系统、一氧化碳测量解决方案、CO 监测设备提供商、环境气体传感器技术、CO 气体传感器制造商、工业用 CO 监测、一氧化碳浓度测量、CO 测量设备安装、一氧化碳检测设备解决方案、环境 CO 监测系统价格、CO 监测器调校、ORBRO、Kongtech、orbro、rtls、实时位置追踪',
        desc: '一氧化碳检测器是在室内和室外环境中快速检测和监测CO（一氧化碳）浓度的重要工具，以确保环境安全。这些检测器广泛用于生活环境、工厂、车库等各种场所，用于检测有毒气体泄漏并提供安全环境。使用CO检测器监测和控制大气中的一氧化碳浓度，以确保安全和健康的环境。',
        img: '',
        url: 'https://orbro.io/cn/sensor/co'
    },
    beaconIndustry: {
        title: '工业用信标 | ORBRO 产品',
        key: '工业用信标、信标跟踪系统、信标位置追踪、工业用信标解决方案、无线信标技术、信标位置服务、信标通信设备、信标跟踪销售、智能工厂信标、工业用信标价格、室内位置服务、无线位置追踪系统、信标位置基础服务、信标实时位置追踪、工业用信标制造商、室内导航信标、信标 IoT 设备、无线信标位置系统、信标网络解决方案、工业用信标技术平台',
        desc: '我们的工业用信标提供创新的位置追踪和无线物联网解决方案，广泛应用于智能工厂和工业自动化领域。这些信标利用先进的蓝牙技术，用于物流、资产管理、生产过程优化等多个应用。使用可靠且准确的工业用信标，提高生产效率，支持流畅的工厂运营。这些信标是构建创新物联网解决方案的必备设备，实现实时位置追踪和数据收集。',
        img: '',
        url: 'https://orbro.io/cn/beacon/industry'
    },
    beaconTag: {
        title: '小型信标 | ORBRO 产品',
        key: '信标, 小型信标、信标标签跟踪、信标标签系统、室内位置追踪技术、蓝牙标签信标、实时位置基础服务、信标标签设备、位置标签跟踪销售、蓝牙信标标签、标签信标价格、蓝牙室内位置服务、位置标签系统、信标标签实时追踪、标签信标 IoT 解决方案、室内位置追踪制造商、蓝牙标签 IoT 设备、标签信标无线位置系统、位置标签网络解决方案、标签信标技术平台、信标标签应用开发',
        desc: '小型信标采用基于蓝牙的位置追踪技术，能够准确识别和监测室内和室外物体和对象的位置。我们的标签信标产品在物流、资产管理、室内导航、智能空间和智能城市项目中发挥核心作用。使用可靠性和准确性强调的标签信标，实现实时位置基础服务，并提高数据收集的效率。',
        img: '',
        url: 'https://orbro.io/cn/beacon/tag'
    },
    assetTag: {
        title: '资产标签 | Orbro 信标产品',
        key: '资产管理, 信标, 小型信标, 信标标签跟踪, 信标标签系统, 室内定位技术, 蓝牙标签信标, 实时位置基服务, 信标标签设备, 位置标签跟踪器销售, 蓝牙信标标签, 蓝牙室内位置服务, 基于位置的标签系统, 信标标签实时跟踪, 标签信标 IoT 解决方案, 室内位置跟踪制造商, 蓝牙标签 IoT 设备, 标签信标无线位置系统, 基于位置的标签网络解决方案, 标签信标技术平台, 信标标签应用开发',
        desc: '资产标签是为资产管理优化的超小型蓝牙标签终端。配备了最新版本的蓝牙和最长一年的电池寿命。',
        img: '',
        url: 'https://orbro.io/cn/beacon/asset-tag'
    },
    basicRobot: {
        title: '机器人系统 | Orbro Beacon 产品',
        key: '机器人系统, 资产管理, 信标, 小型信标, 信标标签跟踪, 信标标签系统, 室内定位技术, 蓝牙标签信标, 实时位置服务, 信标标签设备, 位置标签跟踪器销售, 蓝牙信标标签, 标签信标价格, 蓝牙室内位置服务, 基于位置的标签系统, 信标标签实时跟踪, 标签信标物联网解决方案, 室内定位制造商, 蓝牙标签物联网设备, 标签信标无线定位系统, 基于位置的标签网络解决方案, 标签信标技术平台, 信标标签应用开发。',
        desc: '将实时定位系统与机器人配送系统相结合，提供优化的机器人服务，用于物品配送或执行任务。',
        img: '',
        url: 'https://orbro.io/cn/integration/robot'
    },


}
