import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

    helmet : '',
    desc:'RTLSとIoTは、製造産業において革新的な変化をもたらします。さらに効率的かつ正確な資産管理と資産の測位でビジネスを成長させることができます。',
    keywords:'RTLS、IoT、製造産業、製造業、資産管理、資産の測位、位置測位、労働者の管理、労働管理、勤務管理、室内の位置測位、GPS',
        
    ogTitle:'',
    ogSite:'RTLSとIoTが製造の運営を革新する方法 :: ・オブ・でブログ',

    ogUrl : 'https://orbro.io/jp/blog/manufacture',
    ogDesc :'',
    ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOa6daqBDghCNor8iv-YzX1WxKH5TOKtIuZ9Iom5tWnX1rsigsNIOwNLoDBlg70uCuj1H-sg75MSHvBgEva42vfGoepNWA-Iw509j1WfkjEYFSyInBvchHQrT16H7m2qJIKHJMkYZIS2k9KhzJE-hoQ86Y9voT53nZQA7VyJOfQWTxxaUy1bjjnVKrn8H0GEP9W_85velE96d8kXtIjOWmjV6SDkKVxEnl_N1NejohAfWmQhvVCJVnlf7cPcn-5DB4LMdtZGSAGRVWLSXzygG8F8nP4b3YJTMGE4WZBoePBa0hM4SBgT7SpykyGlPzUay6TifllWLBvuvWKh9LCb0JZtYebb8isWpkOIERN0tmDCM_tBjsvibo27ZyP1yRfpmLKfsoNtpZOHY9NP9eHQnUAhLg1clyaBjR-hxMKhMXk2AQUhS1OWIcsN_tLo36LQyNQAeChEpFUJLr0hc0aXxrscDRHI4S80-5hqCmjPcfUERM-fKyyK6SfFJrEBtyCDbqhWgGuU8BOUvkcnPc68hRsSBXjkiZILl0tjKmrWzJe2UUBBVyduU__R3dktNC0FGRPLrkx9XavkeA3B5aZUCOggV9AjAUklOCEml0dfPOyCg-eKwU196LAIC6HdS-ja1H_q7aBURgW9RAK_jGYJmWGzfUPt9ZndbM4G7khUvJU4eWKvYkXFsP0QlkOszc2q3LD9zWxMFrjzbuAs7Gnus4QHrCk0VoS5WYhGBgDLuYfph53E3g6vKm3Z8VH1oGnD_MoZdX3NjdUmLbm2aN8qGe0uvq57HPT2AOUVTC-ntXkT2560j9Y1TnPzHczlkmaRCzxxMHdufPs1Xv147yCml7lz-IY3_3tYh88JhPpWKYMwsZ0gmDMD-k58uVl6M2nu2U2ZnDBIhQR5N2XG5W6-45lXcdsSMQ249MAkTUAoO1V3qu3l66NcOZ4f2K4eL8pHl1OgPuLlgp5xvHaaWG4DJP9-uOps1wYhRqnDZWGfsQaoNH27x3JwRvkKIoSojHEQ28-GqOxo7DHwZDbwGtSzAnh-oHGzqpbUgbtcUr9Urk8Kr4YZh5GbMrmwZLXAl5CyIzCxfCxgKHvxkRgKL81zT3bcX4n-L9seXjGliNOO9sUax87u662hezKTLkVnx4a0olXqQm4PuCUpdF_AO187KE0-8fqEHktIFUy2WujCmXpbyKXwj63VakYRGmnTF2DcVJ1F7lkdio4CGmBLhtQoQeXPabn1aRJ-QeuvDLFBO27AGJbhszDTGjIjQaha7Hp1Btx_AKN0NEdYl2PiFBF1y9QB1JCiexGRPeVBWBSnvf_FE69IkFXqkSkIi1kPKlA-ycurt5-uYVPe3ejmtTq54EHjy8uEBAyQ74EY0dCuSVc1zMRZPYot0mmlcxDw7oRtWRpzHRl2tudqm9bwTriTBMAjd101CAjfkwupjGycmcBCeEQ1KHkjkG6noU85cBX2uzWJgLxlQZKcw_RCypz5egZfAzkP4uiZIBauX2UAcOcLo_f8XBjlq6_-I9AGmNgzrS9hd_NNGBqcxS65JFZaT5gWU2Ousre_BtisMG4z84fsFw=w2059-h1216',
    ogType : 'website',
    
    twitterCard : 'summary',
    twitterTitle : '',
    twitterDesc : '',


    title:'RTLSとIoTが製造の運営を革新する方法',
    date:'2023-05-14',
    tag1:'#RTLS',
    tag2:'#IoT',
    tag3:'#製造業',
    tag4:'#資産管理',

    
    // // <br/>
    text : [
      {
        head : 'RTLS 란?',
        subHead :'',
        desc:"RTLS(Real Time Location System)は、位置基盤サービスのために人や物体に対するリアルタイム位置情報を提供する自然な補完装置です。 これにより、企業はデータを使用して全体的なパフォーマンス、効率、および収益性を改善する方法について洞察を得ることができます。<br/>RTLS技術市場は医療、製造、スポーツなどの様々な分野で多様な新しいアプリケーションの登場と共に急速に成長しています。 <br/>今日のビジネス環境では、利益率を高め、顧客満足度を高めることが競争優位を確保するために不可欠です。 IoTデバイスと結合されたこの技術は、生産プロセスの速度向上、開発サイクルのモニタリング、予測メンテナンス、倉庫および在庫管理を通じて製造産業を革新し、新しい収益ダイナミクスを開く可能性があります。 <br/><br/>どのように動作するのか、以下からいくつかの例を見てみましょう。",
        dot : ''
        
      },
      {
        head : '',
        subHead:"生産ライン効率の増加",
        desc:"生産ラインにRTLS技術を統合すると、企業は特定の生産物を測位し、装備がどのように使用されているかに関するデータを収集し、サプライチェーンの効率性を評価できます。  このような方法でデータにアクセスできるということは、日常的な運用またはボトルネックをすぐに把握し、緩和できることを意味します。 これは、最終的に運用を簡素化し、コストを削減するのに役立ちます。 同様に、プロセス偏差が発生する時期を知ると、製品が顧客に届く前にエラーを特定するのに役立ちます。",
        dot :''
      },
      {
        head : '',
        subHead:"資産測位および管理",
        desc: "製造および物流産業内で発生する継続的な問題は、機器をどこで探すべきかを知ることです。 新型コロナウイルス感染症によって特定地域で制限された数の労働者だけが許される環境で、このような問題はさらに難しくなりました。 さらに、すべての産業で機器を探索するのにかかる時間は、売上損失の原因となります。<br/>すべての機器にRTLSタグを付けることは、作業者が資産がどこにあるかを常に知ることができることを意味します。つまり、最も近いサブメーターまでです。 また、管理者はすべての装備と機械にタグを付けて正確な位置を把握することで、メンテナンス作業をより迅速に行うことができます。 さらに、マシンの使用データを知っているということは、作業者が定期的なメンテナンスをいつ予約すべきかをよりよく推定できることを意味します。 最初から機械が故障しないように",
      },
      {
        head : '',
        subHead:"安全管理",
        desc:"作業者と装備の安全は、特に人々が毎日出入りする大規模施設でもう一つの重要な関心事です。 RTLS、LBS、IoTは資産を統制することで業界に利益を与えることができます。 RTLSは出入口、訪問者IDバッジ内、携帯電話またはキーカードで具現され、特定施設領域への無断アクセスを防止できます。 <br/>同様にタグは作業者にリスクの高い領域に進入していることを警告し、作業者が現場に進入する際に正しい個人保護装備(PPE)を着用しているか確認し、企業が規定遵守及び保安規定を遵守するよう保障できます。",
        dot : ''
      },
      {
        head:"ソリューションを成功させるための考慮事項<br/>",
        subHead:"未来要求事項",
        desc:"RTLSの価値は、ビジネスの短期的および長期的なニーズをすべて考慮し、全体的なアプローチをとることに起因するという点を覚えておくことが重要です。 しかし、多くの企業がロケーションベースのサービスを単一のアプリケーションと考える傾向があり、結果として中長期的なニーズを満たすソリューションではなく、最小限の実行可能なソリューションに対する予算を策定することになります。  選択されたRTLSが複数のユースケースを同時に満たすことができるようにしなければなりません。",
      },
      {
        head : '',
        subHead:"現実的な期待",
        desc:"エンドユーザーは非現実的な期待をせずに問題が発生した場合、問題を解決できるように教育を受けなければなりません。 製品の働き方を完全に理解するのに時間をかけ、必要な結果と必要な実行予算を一致させることで、今後の問題予防に役立ちます。",
      },
      {
        head : '',
        subHead:"ソフトウェアタイプ",
        desc:"位置ソフトウェアは階層と複雑さが異なるため、会社のアプリケーションに最適なソフトウェアタイプを理解することが重要です。 達成したい目標を考慮し、ビジネス戦略に合った最適なソリューションを見つけてください。 これと関連して、多様なLBSおよびSWアプリケーションソリューション間の柔軟性を保障するためには、最初から開放的で多才なRTLSプラットフォームを採用することが核心です。",
      },
      {
        head : '',
        subHead:"せっかち",
        desc:"初期設定後は実際の環境でテストをスキップする傾向がありますが、その場合は失敗に終わることが多いです。 企業は、テクノロジーを規模に合わせてロールアウトする前に、複数のシナリオでソリューションがどのように拡張されるかを理解する必要があります。 また、持続可能な拡張性計画と総所有コスト（TCO）の明確な推定値を保証するために、予算およびプロジェクト要件を一致させることが重要です。",
      },
      {
        head : '',
        subHead :'',
        desc:"RTLS、IoT に関心を持つのに興味深い時期です。 さまざまな業種にわたってますます多くの企業が恩恵を享受し、パフォーマンスと収益が改善されています。 まだこの技術を採用していない人にとっては、今がチャンスです。",
      },

    ],



    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc: 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/jp/enterprise/construction/asset'
        }, 
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/jp/enterprise/construction/safety'
        },
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/jp/enterprise/logistics/asset'
        }
      ]
    }
  

  }