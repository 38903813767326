import { FlexStyle } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";

export const StyledAdvantage = styled.div`
  width: 100%;
  

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
  }
`;

export const StyledAdvantageItems = styled(FlexStyle)`
  width: 100%;
  gap: 32px;
  padding-top: 24px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    flex-wrap: wrap;
    row-gap: 64px;
  }
`;


export const StyledAdvantageItemIcon = styled.img`
  width: 32px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width: 36px;
  } 
`;

 