import {
  navImgAccess,
  navImgBeacon,
  navImgCamera,
  navImgIOT,
  navImgOS,
  navImgRTLS,
  navImgSensor,
  navImgWorld
} from "@assets/images/nav";

export default {
  product: [
    {
      icon: navImgRTLS,
      title: 'RTLS',
      link: '/en/rtls',
      path: [
        {
          text: 'High Precision (UWB) Location Tracking',
          link: '/en/rtls/uwb',
        },
      ]
    },
    {
      icon: navImgBeacon,
      title: 'Beacon',
      link: 'none',
      path: [
        {
          text: 'Bluetooth (BLE) Location Tracking',
          link: '/en/beacon/ble',
        },
        {
          text: 'Industrial Beacons',
          link: '/en/beacon/industry',
        },
        {
          text: 'Small Beacons',
          link: '/en/beacon/tag',
        },
        {
          text: 'Asset Tag',
          link: '/en/beacon/asset-tag',
        },
      ]
    },
    {
      icon: navImgAccess,
      title: 'Access Control',
      link: '/en/access',
      path: [
        {
          text: 'Automatic Door Access',
          link: '/en/access/auto',
        },
        {
          text: 'Face Recognition Access',
          link: '/en/access/face',
        },
        {
          text: 'Access Control Accessories',
          link: '/en/access/accessory',
        }
      ]
    },
    {
      icon: navImgCamera,
      title: 'AI RTLS',
      link: '/en/camera',
      path: [
        {
          text: 'Indoor Camera',
          link: '/en/camera/indoor',
        },
        {
          text: 'Outdoor Camera',
          link: '/en/camera/outdoor',
        },
      ]
    },
    {
      icon: navImgSensor,
      title: 'Industrial IoT',
      link: 'none',
      path: [
        {
          text: 'LoRa Gateway',
          link: '/en/sensor/LoRaGateway',
        },
        {
          text: 'LoRa Sensors',
          link: '/en/sensor/LoRaSensor',
        },
        {
          text: 'Air Quality Monitor',
          link: '/en/sensor/air',
        },
        {
          text: 'Carbon Monoxide Monitor',
          link: '/en/sensor/co',
        }
      ]
    },
    {
      icon: navImgWorld,
      title: 'Integration',
      link: '/en/integration',
      path: [
        {
          text: 'Robot System',
          link: '/en/integration/robot',
        },
      ]
    },
  ],

  OS: {
    icon: navImgOS,
    title: "ORBRO OS",
    desc: 'From access control systems to real-time location tracking and various IoT controls, ORBRO OS is a unified service that makes spaces smarter in every way. Experience a smart future right now.',
    more: 'Learn More',
    link: '/en/os',
  },

  solution: [
    {
      title: 'Construction',
      path: [
        {
          text: 'Worker Safety Management',
          link: '/en/enterprise/construction/safety'
        },
        {
          text: 'Construction Asset Management',
          link: '/en/enterprise/construction/asset'
        },
        {
          text: 'Heavy Equipment Management',
          link: '/en/enterprise/construction/equipment'
        },
      ]
    },
    {
      title: 'Logistics',
      path: [
        {
          text: 'Logistics Inventory Management',
          link: '/en/enterprise/logistics/asset'
        },
        {
          text: 'Warehouse Worker Safety Management',
          link: '/en/enterprise/logistics/safety'
        },
      ]
    },
    {
      title: 'Hospitals',
      path: [
        {
          text: 'Patient Location Tracking',
          link: '/en/enterprise/medical/patient'
        },
        {
          text: 'Medical Waste Management',
          link: '/en/enterprise/medical/waste'
        },
      ]
    },
    {
      title: 'Manufacturing',
      path: [
        {
          text: 'Manufacturing Process Management',
          link: '/en/enterprise/manufacturing/process'
        },
        {
          text: 'Manufacturing Asset Management',
          link: '/en/enterprise/manufacturing/asset'
        },
      ]
    },
    {
      title: 'Office',
      path: [
        {
          text: 'Office Asset Management',
          link: '/en/enterprise/office/asset'
        },
      ]
    },
    {
      title: 'Retail',
      path: [
        {
          text: 'Exhibition Visitor Analysis',
          link: '/en/enterprise/retail/exhibition'
        },
      ]
    }
  ],

  world: {
    icon: navImgWorld,
    title: "ORBRO Digital Twin",
    desc: `Receive data from real-world people, objects, buildings, and cities to monitor and manage in real-time within a digital screen's virtual reality.This enhances productivity and prevents issues, primarily utilized in industrial and manufacturing sectors.`,
    more: 'Learn More',
    link: '/en/digital-twin',
  },


  support: [
    {
      title: 'Customer Support',
      path: [
        {
          text: 'Company Introduction',
          link: '/en/company'
        },
        {
          text: 'Contact',
          link: '/en/request'
        }
      ]
    },
    {
      title: 'Technology Introduction',
      path: [
        {
          text: 'UWB',
          link: '/en/enterprise/technology/uwb'
        },
        {
          text: 'BLE',
          link: '/en/enterprise/technology/ble'
        },
        {
          text: 'Stereo Vision',
          link: '/en/enterprise/technology/stereo-vision'
        },
        {
          text: 'Monocular Vision',
          link: '/en/enterprise/technology/monocular-vision'
        },
        {
          text: 'TDoA',
          link: '/en/enterprise/technology/tdoa'
        },
        {
          text: 'TWR',
          link: '/en/enterprise/technology/twr'
        },
        {
          text: 'Geofence',
          link: '/en/enterprise/technology/what-is-geofence'
        },
        {
          text: 'Digital Twin',
          link: '/en/enterprise/technology/what-is-digital-twin'
        },
      ]
    },
    {
      title: 'Software',
      path: [
        {
          text: 'iOS',
          link: 'https://apps.apple.com/us/app/orbro-service/id1673356996'
        },
        {
          text: 'Android',
          link: 'https://play.google.com/store/apps/details?id=com.orbro.mobile'
        },
        {
          text: 'ORBRO World',
          link: 'https://home.orbro.io'
        },
        {
          text: 'Release Note (only KOR)',
          link: '/release-note'
        },
      ]
    },
    {
      title: 'Social',
      path: [
        {
          text: 'LinkedIn',
          link: 'https://www.linkedin.com/company/kongtech/'
        },
        {
          text: 'Naver',
          link: 'https://blog.naver.com/orbro_platform'
        },
        {
          text: 'Medium',
          link: 'https://medium.com/@orbro'
        },
      ]
    },
  ],
  footerSupport: [

    {
      title: 'Support',
      path: [
        { text: "Blog", link: "/en/blog" },
        { text: "Contact", link: "/en/request" },
        { text: 'Release Note (only KOR)', link: '/release-note' },
      ]
    },
    {
      title: 'Services',
      path: [
        { text: "Orbro World", link: "https://home.orbro.io" },
      ]
    },
    {
      title: 'Downloads',
      path: [
        { text: "Download iOS App", link: "https://apps.apple.com/us/app/orbro-service/id1673356996" },
        { text: "Download Android App", link: "https://play.google.com/store/apps/details?id=com.orbro.mobile" },
      ]
    },
  ]

}
