import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";



export const StyledTitleBanner = styled.div`
  display: flex;
  
  width: 100%; 
  height: auto;

  background: #F8F8F8;
  left: 0px;
  top: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    height: auto;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height: auto;
  
  }
`;
