import { TechUseCaseIcon3D, TechUseCaseIconBuilding } from "@assets/images/technology/common";
import { IconAnalytics, IconBattery, IconCar, IconChartInv, IconHeart, IconHome, IconLocation, IconRulerInv, IconSecurity, IconTagInv } from "@assets/images/icon";
import video from "@src/assets/images/solution/constructionSafety/video/rtlsVideo.mp4";

export default {

    seo: {
        title: 'What is Geofence? | Orbro',
        desc: 'Geofence refers to the technology that virtually sets up a geographical area and detects events occurring within that area. Combining location-based services and sensor technology, Geofence enables real-time detection and response to events in specific areas.',
        keywords: 'geofence, rtls, real-time location system, digital twin, digital transformation, smart building, virtual reality, location tracking, beacon, orbro',
        img: '',
        url: 'https://orbro.io/en/enterprise/technology/what-is-geofence',
        type: 'website',
    },

    tag: 'Geofence',
    title: 'What is Geofence?',
    desc: '',

    text: [
        {
            head: 'What is Geofence?',
            desc: 'Geofence refers to the technology that virtually sets up a geographical area and detects events occurring within that area. Combining location-based services and sensor technology, Geofence enables real-time detection and response to events in specific areas.',
            subHead: '',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/en/enterprise/construction/safety',
            head: 'Geofence Technology',
            desc: 'While Geofence typically relies on the Global Positioning System (GPS), there is a growing trend to utilize Real-Time Location System (RTLS) technology. RTLS provides more accurate location information than GPS and excels in indoor environments. By tracking real-time locations through technologies like beacons, RFID, and sensor networks, Geofence can be effectively implemented.',
            subHead: [
                {
                    title: 'What is RTLS?',
                    desc: 'Real-Time Location System (RTLS) is a technology that tracks the real-time location of objects, providing more accurate location information than GPS, even in indoor environments. Using beacons, RFID, sensor networks, RTLS detects and records the real-time location of objects. RTLS is commonly used in various industries for vehicle tracking, automation, security, and more.'
                },
                {
                    title: 'Advantages of RTLS',
                    desc: 'RTLS offers high precision, enabling accurate location tracking even in indoor and complex environments. Its real-time detection capability allows monitoring the movement of objects in real-time, facilitating quick response and efficient automation. Moreover, RTLS can be applied across various industries by combining different technologies. It is recognized as an effective solution contributing to enhanced productivity and security in industrial settings.'
                },
                {
                    title: 'Installation of RTLS Technology',
                    desc: 'The installation process of RTLS technology involves integrating various components. Select and deploy technologies such as beacons, RFID sensors, or other location tracking technologies for object identification. These deployed technologies are then connected to a network to collect real-time location data. Setting up a central control system processes the data and allows efficient utilization. Finally, configuring a user interface enables monitoring of location information and taking necessary actions.'
                },
            ],
            dot: ''
        },
        {
            tag: 'noBottomLine',
            head: 'Applications of Geofence',
            desc: 'Geofence is applied in various fields, primarily in the following areas:',
            subHead: [
                {
                    title: 'Security Systems',
                    desc: 'Geofence enhances security by detecting unauthorized intrusions around buildings, residential areas, and public spaces, triggering real-time alerts. It can be utilized for access control and early warning systems in high-risk areas.'
                },
                {
                    title: 'Vehicle Tracking',
                    desc: 'Geofence is used in the automotive and freight transport industries to monitor vehicle movements in real-time. Alerts can be set to notify if a vehicle deviates from a specific area.'
                },
                {
                    title: 'Smart Cities and IoT',
                    desc: 'In smart city projects, Geofence monitors the movement of people in public spaces, optimizing city infrastructure. It is also utilized in intelligent transportation systems for traffic flow analysis and congestion detection.'
                },
                {
                    title: 'Leisure and Entertainment',
                    desc: 'Tracking the movement of visitors in tourist attractions or theme parks using Geofence provides enhanced services. Additionally, movable boundaries can be set to support or restrict activities in specific areas.'
                },
            ],
            dot: ''
        },
        {
            videoDesc: '(Construction Site Safety Management, Click to visit)',
            tag: 'noBottomLine',
            head: 'Prospects of Geofence Technology',
            desc: 'Geofence technology continues to evolve, and it is expected to expand further in the future. With advancements in technology improving precision location tracking and real-time detection capabilities, the application of Geofence is anticipated to strengthen in security and safety domains. Furthermore, advancements in indoor location tracking technologies enable accurate tracking within buildings, leading to expanded applications in areas such as vehicle tracking, industrial automation, and smart cities. Efforts are also underway to develop more effective Geofence solutions through integration with collaborative systems, connecting various sensors and technologies. The progress in this technology is expected to bring innovative changes in various industries, including security, logistics, and smart cities.',
            subHead: [],
            dot: ''
        },
        {
            tag: 'card',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
        {
            tag: 'advantage',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
    ],



    card: {
        title: 'Geofence Use Cases',
        desc: 'Geofence technology is effectively utilized in various fields. Here are some representative Geofence use cases:',
        cards: [
            {
                icon: IconSecurity,
                title: 'Security and Alert Systems',
                desc: [
                    {
                        title: 'Building and Facility Protection',
                        desc: 'Geofence is used to set virtual boundaries around buildings or facilities, triggering real-time alerts in case of unauthorized intrusion, thereby enhancing security.'
                    },
                    {
                        title: 'Vehicle and Transportation Protection',
                        desc: 'Applying Geofence to vehicles or transport vehicles supports theft prevention and safe operation by setting up alerts if they deviate from specific areas.'
                    }
                ]
            },
            {
                icon: IconHome,
                title: 'Logistics and Warehouse Management',
                desc: [
                    {
                        title: 'Real-time Cargo Tracking',
                        desc: 'Introducing Geofence into logistics networks allows monitoring the real-time location of cargo, contributing to efficient warehouse management.'
                    },
                    {
                        title: 'Delivery and Transportation Optimization',
                        desc: 'Utilizing Geofence for optimizing delivery and transportation routes helps reduce costs and provides quick services.'
                    }
                ]
            },
            {
                icon: IconHeart,
                title: 'Medical Facility Security',
                desc: [
                    {
                        title: 'Patient Location Tracking',
                        desc: 'Using Geofence in hospitals or medical facilities to track patients\' locations in real-time supports quick responses to emergency situations.'
                    },
                    {
                        title: 'Medical Equipment Management',
                        desc: 'Introducing Geofence to medical devices monitors the location and status of equipment, providing accurate and efficient medical services.'
                    }
                ]
            },
            {
                icon: TechUseCaseIconBuilding,
                title: 'Smart City Safety',
                desc: [
                    {
                        title: 'Surveillance of Public Spaces',
                        desc: 'Applying Geofence to key points in the city strengthens public space security and supports crime prevention.'
                    },
                    {
                        title: 'Traffic Flow Management',
                        desc: 'Introducing Geofence to roads and traffic networks for monitoring traffic flow and establishing efficient traffic plans.'
                    }
                ]
            },
            {
                icon: IconLocation,
                title: 'Education Facility Security',
                desc: [
                    {
                        title: 'Protection of Schools and University Campuses',
                        desc: 'Constructing Geofence within schools or universities ensures the safety of students and staff by preventing unauthorized access.'
                    },
                    {
                        title: 'Tracking Education Resources',
                        desc: 'Applying Geofence to educational equipment and resources supports efficient resource management and loss prevention.'
                    }
                ]
            },
            {
                icon: IconAnalytics,
                title: 'Environmental Monitoring',
                desc: [
                    {
                        title: 'Monitoring Natural Environment',
                        desc: 'Utilizing Geofence in natural environments for real-time monitoring of air quality, water quality, climate, etc., contributes to environmental conservation.'
                    },
                    {
                        title: 'Industrial Environmental Management',
                        desc: 'Monitoring environmental parameters within industrial facilities and contributing to eco-friendly operations with accurate data.'
                    }
                ]
            }
        ]
    },
    

    advantage: {
        title: 'Key Advantages of Geofence',
        cards: [
            {
                icon: IconRulerInv,
                title: 'Real-time Location Detection and Alerts',
                desc: 'Geofence detects the real-time location of objects and triggers immediate alerts upon entry or exit from specific areas, enhancing security with rapid response and accurate alerts.'
            },
            {
                icon: IconTagInv,
                title: 'Simulation for Diverse Applications',
                desc: 'Geofence allows setting virtual boundaries for simulating various scenarios and understanding situations within those areas beforehand. This enables effective applications in logistics optimization, traffic flow management, and more.'
            },
            {
                icon: IconChartInv,
                title: 'Cost Savings and Asset Lifespan Extension',
                desc: 'Utilizing Geofence enables real-time monitoring of the location and status of physical objects, optimizing maintenance schedules through predictive analysis. This prevents unnecessary maintenance, predicts failures, and efficiently performs repairs or replacements, reducing overall maintenance costs and extending asset lifespan.'
            },
        ]
    }
}
