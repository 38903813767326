import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| 智能物流仓库引领产业的原因',
  desc : '智能仓库通过实时库存跟踪优化物流流程，最大化效率，引领行业。这是通过准确的库存跟踪和高效的库存移动实现成本节约和客户服务水平提高的。',
  keywords : '大型物流仓库管理,物流中心管理,物流仓库管理,智能物流,库存管理,智能库存管理,物流仓库库存管理,大型物流仓库库存管理',

  ogTitle : '',
  ogDesc : '',
  ogSite : '智能物流仓库引领产业的原因 :: ORBRO Blog',
  ogUrl : 'https://orbro.io/cn/blog/smartLogistics',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4NDUS2r1YTByIJmtp6T8gd_h-Y0FvrgnSd4VlXMvLXHrcsoDQuYCCpyNiEnagSHA56J_cXX6ma4jVfg2btnQwzHH1T8dty-PlOi09CLRr0QCFPG5Y4vVqfn7C5PnTTsR0x0nbT2ON5OhY0dk5d-xXzCLE6vUBjcUa8fBILF1gIuf_N84MNO3N-VeWiksJFzVJc-xgHqholB4vdavvEz7lQXy-2H0Bd4mRgkpdEB_TKat6ecFPI1Iicj74Quxd7X80Jsf4K-0CLRxPmsRzqHK-9OTe65ZtvBh6uCdL9EkTWgEqIhYkBZurnVB2Xu1w5ehFm0YWCO4mUMXNlZ-Dl5zLrTMwASpHVsXgOer9HduJZ_tpy7yNSd1_eqR0YtzmRoll-I0lVS6kZFRtI8DFYaLVzENwJgYYzd7g6722BEKL9hnLql3Q5hQpwBGPeiSfZBLuwjIxTUqbuTlemEXD9BykaU6SchggZ0JNi9oy4MVSmKjvnHMAInxjwv2dhy0MyVb7f8gdr8lK41OhMhRx4OiMYRfCoJjXc6Ee9DOX-Z7g2HReiWKhMK4gGpS9wKp_fsj9PATynJFs2AJY8G1c4G84Vqpv7jFwYbpgyp11npXaIaG12FHyF7hBdvDtwmWQ2Ebb1M7Fl2CEQFB7GZREh7wslvCX6Tst9-y6HysrikAuUYviPj_IFrmp4eDDGbQIznKzXVpeegI-fbzkPhiPv5MREXUt_gCYR_CKVy916MV0gpoSXeKYuSjDEih9ebiLmq-kpGGAb6Cl3G9s5zsiWOZLaZlZKjoDCI9Gujoy7FTRDGFM3r6HaIFY7mehUppvhCS_9nsSGgk32eoWdThQu3oUDCJMbf4vDIWM7bBa6LW4_OqliCAqREvMwSx8EezvOQf01DLRZ1UMbWyA_QQl4qIa_g7P8_NAUSWbFSZf2rjA5WRMJ12rKhQrUsxiSTUOnFjee6CSe693z1ccTi0XCbjaPdu7LEg9Mbcb2wmJ-0Jcxjmggf_jEWTjwwGIGUrzcIPJ0PqDXNiCW5esg5ksxHkcunw8ra44iq0DjQx8vE2BZ62e4i5meo_tR91eEqPecKgYFSVnNfBmE7mPfBo5W-Y_rrffkTnIQxlgR8zFUselSqB-KFuai7uIoAniJzBPc8ReLT7OJzprQQyMgdDtCW3epD-bbknMF4stChNDuYgGa85BsShBc3_pEuvsoxINtMIUXtpn5s7IS18mmnVLyK8C-jqpisEXwk8A4CZjv3tU4GWJyb_ZZoL9ZpCb6h6w4Brla59Ia3dRU-ZUKtVXg7CNzMdllUkdXV64VHxjSmpvNtAJ92aE_qUOj8Q1BOwQuAh71_yBTW0zKAxhme_coBD55dETFQakhVS484NQqw2sj2Ypm7qUDu6UWHB8TZVpogTjCpoKhjT84dNSVIMMqHk6UT-4BiaXv1NMyFb6LtFhGmbUO8iEX1n9RsO2Sn5bNAwjs8aFmh_P5eX9Qfj3HJ5YX_ChNlBlkwTDgdj_V3_uJm_DErrCc4YCi_ODmOv_0Gj-2o3wiULGzOPSZQdtnhCSOCNOEokcoQSRMcy1l4IBEW5Hs=w1470-h1233',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '智能物流仓库引领产业的原因',
  date : '2023-08-03',
  tag1 : '#大型物流中心',
  tag2 : '#智能物流',
  tag3 : '#库存管理',
  tag4 : '#智能存货',
    
  // <br/>
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'库存管理是物流中心供应链的核心。因此，渴望业务增长的物流经理们不断寻求更高效的库存管理。除了库存管理之外，他们还希望找到最智能的方法来提高整体物流系统的效率和生产力。<br/>随着实时位置追踪系统（RTLS）的最新进展，仓库已经从过去的形态进化成了一种不同的系统。<br/>现在，您可以跟踪物流仓库内的所有内容。库存、工人、叉车等的位置会实时显示在类似仓库布局的地图上。这种几乎没有误差的先进技术，使您能够全面了解整个现场，拥有多种应用可能性，超越了简单的位置跟踪。<br/><br/>以下，我们将解释通过这项技术可以实现的各种功能。',
      dot : ''
      
    },
    {
      head : '库存位置追踪',
      subHead :'',
      desc : '您可以跟踪物流仓库内库存的位置。选择特定区域后，您可以准确定位该区域内的库存位置和数量。如果您正在寻找特定的物品，您可以使用搜索功能来确定其在仓库内的位置。如果库存正在运输中，您可以实时追踪其移动路线。根据物品的受欢迎程度，您可以优化库存布置和周转率，从而有助于降低成本并防止库存不足或过量。凭借准确的库存位置信息，物流工作人员可以快速找到物品并将其移至所需位置，缩短工作时间，提高生产力。',
      dot : ''
      
    },
    {
      head : '自动化进出货过程',
      subHead :'',
      desc : '通过预先设定的点通过的库存会记录其进出货时间。这些准确的数据可以避免人为错误，并节省人力。此外，这些数据存储在云端，消除了手动管理的麻烦和丢失的风险。',
      dot : ''
      
    },
    {
      head : '缩短交货时间',
      subHead :'',
      desc : '实时库存追踪可实现更快的交货时间。准确了解库存位置，可以快速找到并取回所需物品，从而缩短生产或运输时间。',
      dot : ''
      
    },
    {
      head : '安全管理',
      subHead :'1. 工人追踪',
      desc : '实时工人追踪可用于监控安全情况，并在工人接近危险区域或发生紧急情况时快速采取措施，有助于主动预防潜在风险。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'2. 危险区域设定',
      desc : '可以预先设定危险或受限区域，并在工人附近经过时发出警告或向管理员发出实时提醒，有助于预防事故。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'3. 叉车管理',
      desc : '安全关注点不仅限于工人，还包括重型设备。实时追踪可能发生事故的重型设备，可以检测到工人的接近或未经授权进入，从而防止事故。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'4. 资产管理',
      desc : '这项技术有助于防止库存损失和盗窃。每个位置上都分配和实时追踪库存，可以快速发现库存损失或盗窃，并最小化损失，并采取恢复措施。',
      dot : ''
      
    },

  ],


  link : {
    page : 'rtls',
    numb : '1',
    product : [
      {
        text : productPurchase.purchase.asset.assetTracking.title,
        title : productPurchase.purchase.asset.assetTracking.title,
        serial : productPurchase.purchase.asset.assetTracking.serial,
        desc: "将UWB终端机安装在工作者或设备上，帮助实时追踪位置的产品。",
        img : productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution : [
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/cn/enterprise/logistics/asset'
      },
      {
        img : logisticsSafety.thumb,
        data : 'logisticsSafety',
        link : '/cn/enterprise/logistics/safety'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/cn/enterprise/manufacturing/asset'
      }
    ]

  }


}