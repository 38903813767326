
import { IconOsOff, IconOsOn, IconRobotOff, IconRobotOn } from '@assets/images/main/icon';
import { IntegrationLabel, IntegrationLabel1, IntegrationLabel2, IntegrationLabel3, integrationOverviewDesktop11, integrationOverviewDesktop12, integrationOverviewDesktop13, integrationOverviewDesktop14, integrationOverviewDesktop15, integrationOverviewDesktop21, integrationOverviewDesktop22, integrationOverviewDesktop23, integrationOverviewDesktop24, integrationOverviewDesktop31, integrationOverviewDesktop32, integrationOverviewDesktop33, integrationOverviewDesktop34, integrationOverviewMobile11, integrationOverviewMobile12, integrationOverviewMobile13, integrationOverviewMobile14, integrationOverviewMobile15, integrationOverviewMobile21, integrationOverviewMobile22, integrationOverviewMobile23, integrationOverviewMobile24, integrationOverviewMobile31, integrationOverviewMobile32, integrationOverviewMobile33, integrationOverviewMobile34, integrationOverviewTablet11, integrationOverviewTablet12, integrationOverviewTablet13, integrationOverviewTablet14, integrationOverviewTablet15, integrationOverviewTablet21, integrationOverviewTablet22, integrationOverviewTablet23, integrationOverviewTablet24, integrationOverviewTablet31, integrationOverviewTablet32, integrationOverviewTablet33, integrationOverviewTablet34 } from '@assets/images/main/integration';
import { IntegrationDetailVideoDesktop1, IntegrationDetailVideoDesktop2, IntegrationDetailVideoDesktop3, IntegrationDetailVideoMobile1, IntegrationDetailVideoMobile2, IntegrationDetailVideoMobile3, IntegrationDetailVideoTablet1, IntegrationDetailVideoTablet2, IntegrationDetailVideoTablet3 } from '@assets/images/main/video/integration';


export default {

    mainPage: {
        product: {
            title: '使用環境に適したパッケージ構成',
            desc: '医療環境で構築可能な製品パッケージ構成をご確認ください。',
            robotBasic: {
                title: '配送ロボット基本パッケージ',
                desc: '屋内位置追跡システムとロボットシステムの調和、簡単な構成で迅速な構築が可能',
            },
            delivery: {
                title: '無人積載/降載パッケージ',
                desc: '無人で配送ロボットが積載/降載できるように構成されたパッケージ',
            },
            disinfection: {
                title: '高リスク管理パッケージ',
                desc: '感染リスクが高い医療物品を配送管理できるように構成されたパッケージ',
            }
        }
    },

    banner: {
        label: IntegrationLabel,
        title: 'より広い拡張のための統合システム',
        desc: '',

        schemeTitle: 'ORBROが提案するカメラシステム。',
        schemeDesc: '決定するのに助けが必要ですか？',
        schemeDesc2: 'お問い合わせいただければ、出入口の専門家がサポートいたします。',
        phone: '1522-9928',
    },

    bannerData: [
        {
            icon: IconRobotOn,
            iconInv: IconRobotOff,
            image: '',
            iconText: 'ロボットシステム',
            sub: '新',
            link: '/jp/integration/robot'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: '新',
            link: '/jp/os'
        },
    ],

    video: {
        title: '位置追跡技術と室内配送ロボットの組み合わせ',
        desc: [
            { text: 'ORBROの位置追跡システムに基づくロボットシステムの統合は、ロボットのリアルタイム位置監視とプロセスの進行状況を一目で確認できるようにします。' },
        ]
    },

    delivery: {
        label: IntegrationLabel1,
        title: '配送ロボットシステムとの統合',
        desc: 'ORBRO位置追跡システムを使用して、ロボットのリアルタイム位置と作業状態のモニタリングを提供します。',

        card: [
            {
                videoTag: true,
                video: {
                    desktop: IntegrationDetailVideoDesktop1,
                    tablet: IntegrationDetailVideoTablet1,
                    mobile: IntegrationDetailVideoMobile1
                },
                title: 'ロボットの位置追跡および管理の開始',
                desc: 'ORBRO位置追跡システムに基づいてリアルタイムでロボットの位置追跡が可能で、さまざまな機器を統合してロボットとのリアルタイム運営状況を監視し、運営を支援します。',
                img: {
                    desktop: integrationOverviewDesktop11,
                    tablet: integrationOverviewTablet11,
                    mobile: integrationOverviewMobile11
                },
            },
            {
                title: 'ORBRO位置追跡システムとの結合',
                desc: 'ORBROの統合システムを通じて、ロボットと位置追跡システムを簡単に統合できます。',
                img: {
                    desktop: integrationOverviewDesktop12,
                    tablet: integrationOverviewTablet12,
                    mobile: integrationOverviewMobile12
                },
            },
            {
                title: '作業状態のモニタリング',
                desc: 'ロボットの作業状態をリアルタイムで検知し、即時対応が可能です。',
                img: {
                    desktop: integrationOverviewDesktop13,
                    tablet: integrationOverviewTablet13,
                    mobile: integrationOverviewMobile13
                },
            },
            {
                videoTag: true,
                video: {
                    desktop: IntegrationDetailVideoDesktop2,
                    tablet: IntegrationDetailVideoTablet2,
                    mobile: IntegrationDetailVideoMobile2
                },
                title: 'ロボット位置のモニタリング',
                desc: '各ロボットの現在位置をリアルタイムで提供します。',
                img: {
                    desktop: integrationOverviewDesktop14,
                    tablet: integrationOverviewTablet14,
                    mobile: integrationOverviewMobile14
                },
            },
            {
                videoTag: true,
                video: {
                    desktop: IntegrationDetailVideoDesktop3,
                    tablet: IntegrationDetailVideoTablet3,
                    mobile: IntegrationDetailVideoMobile3
                },
                title: '複数のロボット管理',
                desc: '複数のロボットを同時に効果的に管理できます。',
                img: {
                    desktop: integrationOverviewDesktop15,
                    tablet: integrationOverviewTablet15,
                    mobile: integrationOverviewMobile15
                },
            },
        ]
    },

    loading: {
        label: IntegrationLabel2,
        title: '無人積載/降載システムとの統合',
        desc: '配送ロボットの積載から降載まで無人で運営できるように、積載機器、協働ロボット、ロッカーを統合し、1つのシステムとして提供します。',
        card: [
            {
                title: '積載装置の統合',
                desc: '積載装置の統合によりロボットの呼び出しが可能で、ロボットの到着および積載準備状態をORBROを通じて受け取り、自動で運営できるようにします。',
                img: {
                    desktop: integrationOverviewDesktop21,
                    tablet: integrationOverviewTablet21,
                    mobile: integrationOverviewMobile21
                },
            },
            {
                title: '協働ロボットの統合',
                desc: '協働ロボットの統合を通じて配送ロボットが到着時の状態を確認し、自動で降載を行います。',
                img: {
                    desktop: integrationOverviewDesktop22,
                    tablet: integrationOverviewTablet22,
                    mobile: integrationOverviewMobile22
                },
            },
            {
                title: 'スマートロッカーの統合',
                desc: 'カスタマイズされたダッシュボードを通じて、ユーザーが重要なデータを簡単にモニタリングし、データを迅速に理解し効果的な決定を下すためのインサイトを提供します。',
                img: {
                    desktop: integrationOverviewDesktop23,
                    tablet: integrationOverviewTablet23,
                    mobile: integrationOverviewMobile23
                },
            },
            {
                title: 'リアルタイム状況確認',
                desc: 'リアルタイム在庫状況および運営状態などすべての機器の運営状態を1つの画面で提供します。',
                img: {
                    desktop: integrationOverviewDesktop24,
                    tablet: integrationOverviewTablet24,
                    mobile: integrationOverviewMobile24
                },
            },
        ]
    },
    service: {

        label: IntegrationLabel3,
        title: '消毒システム連携',
        desc: 'ORBRO OSは強力で便利ですが、そのプロセスは複雑ではありません。<br/>どの製品でも簡単に設定してすぐに使用できます。',

        card: [
            {
                title: 'プラズマ消毒装置',
                desc: '医療廃棄物および医療器具の滅菌に必要な温度まで迅速に上昇し、滅菌サイクルの時間を短縮し、気化された滅菌剤の均一な供給を通じて完璧な滅菌を提供します。',
                img: {
                    desktop: integrationOverviewDesktop31,
                    tablet: integrationOverviewTablet31,
                    mobile: integrationOverviewMobile31
                },
            },
            {
                title: 'UVC消毒装置',
                desc: 'UVCの高い殺菌力とLEDランプの安全性を活用し、安全でありながら高い殺菌を提供します。',
                img: {
                    desktop: integrationOverviewDesktop32,
                    tablet: integrationOverviewTablet32,
                    mobile: integrationOverviewMobile32
                },
            },
            {
                title: 'HPV消毒装置連携',
                desc: '汚染区域を通過した配送ロボットを非接触で自動消毒/滅菌を行います。',
                img: {
                    desktop: integrationOverviewDesktop33,
                    tablet: integrationOverviewTablet33,
                    mobile: integrationOverviewMobile33
                },
            },
            {
                title: '機器別作業状態モニタリングの提供',
                desc: '連携された消毒装置の接続状態および機器状態だけでなく<br/>作業状態までリアルタイムでモニタリング機能を提供します。',
                img: {
                    desktop: integrationOverviewDesktop34,
                    tablet: integrationOverviewTablet34,
                    mobile: integrationOverviewMobile34
                },
            },
        ]
    }




}