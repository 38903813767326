import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

export default {
    helmet: '| UWB RTLS、未来の産業の始まり',
    desc: 'UWB RTLS（Ultra-Wideband Real-Time Location System）技術は、産業現場のデジタル化を可能にし、人や物体を追跡および管理します。高い精度とリアルタイムのデータ更新により、ビジネスの成長を促進します。',
    keywords: 'uwb、rtls、uwb rtls、スマートロジスティクス、スマート建設、スマート製造、スマートプロセス、スマートファクトリー、デジタルトランスフォーメーション、デジタルツイン、リアルタイム位置追跡、rtls、orbro、contech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'UWB RTLS、未来の産業の始まり :: オーブロブログ',
    ogUrl: 'https://orbro.io/jp/log/uwbRtls',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDyMvH9u0-21RYnyyBVVc9guqVzCzv-ggtVgPCRQTLkEU33FS2rG9s1BImAzHdqx3slOcbgGEGcFnTwDAmmi0nBR-jQfAdavlymzwvoyWDbTWljNw2DPoaW_qaO4ciYcfvrO7YnaeT_zAYoHLLULjh7MSKToInBek_3WIXpnTfHBz83o4ryfNamREq7rU9e4ET1GsYzLjQ-33tSLxqcb1f1RUYR4qlgVm7ntqiwd4jyEZlj-33EJPgyvj3R-QwTvkFl1Ha2Z1oL3YBwdk33ugYt5wzdW-3IJa9T1gM6FWZbPZdnGWL0xDRYYzh0OTD7sckAN2dXqBwWWAqEjdyTKN6AfoQibnezcJUsWa12l8mton2PeSWa45J0NarK5Vrkbc_qrQzoC9iJ209QlewQrjmeCzUN2E9gIK8_O_eQ_seXYgBf-nQWy5BD5fX2goEJBEYQECwdmfhmRPWwkbQC8rXsKBhnXCZ9t3t65Dpn7Voj_tUI2w0wMMlJcsEpEpUdsd4hx6FrX5t4pHmpaJzEB5DxjLofbzSQXqEQcEQvkocvpBQ6HI9XVC5MFlwkonW-DUk_EwFlNGoCZYnZFFQjU-pK07aYDuZA4uvClTrr2OrxvC6iX2rVhJ01oGwN071DybCKXVsScEP4pQyzVkgMsbrOwoLXWRnDA5bl7_-nz4w1_PYbHs7kCJu14Zw1jaBhCb3vgIyFJPMHGaFGLUdtv00ej-qe1NVeGs5SGo8qkMOrkmAVxHx7cqR1ZqqeTvVy6xGAFPhJGvdZRlOV6T1M9HJ3a25z1couxxGM4TnjUSFrKXfhe--PNrqZxiX-4Yia8psajdHdqgD4I4KT6ryESC2umjrIiEI7oQyok08ry7UKuswV2OlxgJXmJYFOr6hBvriet1T1K8N7sko5tDVIDEyoVyGkGasOAifm6XgUZO3cbNLmvYib5LoKMKDFyldZpUxLWQ2yiI0ux0k-c303GbZ8h5nw-OP8JQekoR5do0QHpENpLqonOuBwnQZiBGmH2BGHHTYXGDNIl5s3QYqmWKNRQUMMvAau7nPXgQvmvJs3xRpcmPKFy1x149cg4cTWVvIL99qqF8lfTu8Dr0r2ycGSIm0NDz6WsrMA6cUDNEWZLdCFxEZMl6T7U2IFXBW0CLDmJ9UONldp_cQHYA-aIojbGabali2Q3YgwAKBlxyIytx5azfiIrUy3w7YzUE8BGiWS9faNVbZqk7TfUxA0mevS00orIB7fBajjFpHnkRPXLsXlm0d6pZTBHMzyu1s77gT-82xJSJVvvkHA3SwtYpap0571i6g8VCzO_OkwDlxeQkY9P_BbcVYzEUxoeIrASHDZuuhT2rkJ1qOY0UbdZlGS5KOOO6R10Wi0z8p-SXJQRT9kOR1N2mhdGk_Z_rag_ptC5lHxc7dYZaT2NCqxEVI-GZa3fHcvyge6Gu0OzWP0o2N8x_8XpdTaJuMTcUd-vUlQdg-o1YKkmcoqzVjfZVLzqDF7tsS3KS7hO8cTL23IfERiTLhbOxtCkJTdtHTXhXVrgakFVdWBWUlxMxDP_Yyk12v-Dozi2pwBSbMdRIEJ1xvD4akO3=w2000-h1596',
    ogType: 'ウェブサイト',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'UWB RTLS、未来の産業の始まり',
    date: '2023-11-03',
    tag1: '#uwb',
    tag2: '#rtls',
    tag3: '#digitaltwin',
    tag4: '#digitaltransformation',

    text: [
        {
            head: '',
            subHead: '',
            desc: '近年、超広帯域（UWB）リアルタイムロケーションシステム（RTLS）技術はさまざまな産業で急速に注目されています。この技術は位置追跡と位置ベースのサービスの分野に革新的な変化をもたらし、将来のソリューションとなることが期待されています。本記事では、UWB RTLS技術とは何か、そのアプリケーション、潜在的な利点、そして将来どのように発展する可能性があるかについて探ります。',
            dot: ''
        },
        {
            head: 'UWB RTLSとは？',
            subHead: '',
            desc: 'UWB RTLSは、UWBテクノロジーを使用して高精度の位置データを提供するリアルタイムロケーショントラッキングシステムです。UWBは超広帯域を使用してデータを送信し、非常に正確な位置情報を取得できます。この技術はGPSやWi-Fiなどの従来の位置追跡システムと比較してはるかに高い精度とリアルタイム性を提供します。',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/enterprise/manufacturing/process',
            head: 'アプリケーション',
            subHead: '',
            desc: '<strong>建設および建築業界： </strong>建設現場では、建物、機器、および材料の位置を追跡し、作業スケジュールを最適化し、安全性を向上させることができます。また、屋内の位置精度を高めることでスマートビルディングシステムを実装できます。<br/><br/><strong>小売および流通： </strong>小売および流通事業は製品の位置と在庫をリアルタイムで追跡し、顧客サービスと在庫管理を向上させることができます。また、顧客の購買行動を分析して製品配置を最適化できます。<br/><br/><strong>輸送および土地管理： </strong>道路や鉄道ネットワークでの車両、列車、および他の交通手段の位置を追跡することで、交通渋滞を管理し、事故を予防できます。土地管理部門は地形とリソースの位置データを管理して、リソースの効率的な割り当てを実現できます。<br/><br/><strong>セキュリティおよび安全管理： </strong>重要な施設、イベント、または大規模な集会でUWB RTLSを活用して人や物の位置を追跡し、セキュリティと安全性を向上させることができます。これにより緊急時の迅速な対応が可能となります。<br/><br/><strong>スポーツとエンターテインメント： </strong>スポーツ競技場やイベント会場で観客の位置を追跡し、イベントの組織を最適化し案内サービスを提供できます。また、メディア制作用のカメラや照明機器の位置を正確に特定できます。',
            dot: ''
        },
        {
            head: '利点',
            subHead: '',
            desc: '<strong>高い精度： </strong>UWBテクノロジーは他のワイヤレステクノロジーと比べてはるかに高い精度を提供します。センサー間の距離測定に基づいているため、室内および屋外の両環境で正確な位置データを提供します。<br/><br/><strong>低遅延： </strong>UWBは高速な応答時間を提供し、リアルタイム位置情報を迅速に取得できます。これはシステムの対応性と緊急事態での有用性を高めます。<br/><br/><strong>複数ユーザーのサポート： </strong>UWBは複数のユーザーやオブジェクトを同時に追跡でき、大規模なイベントや産業環境で非常に重要です。<br/><br/><strong>安全性とセキュリティ： </strong>UWBは他のワイヤレス通信技術との干渉を最小限に抑え、高度なセキュリティレベルを提供します。これは機密データとシステムの安全性を保証します。<br/><br/><strong>室内および屋外の利用： </strong>UWB RTLSは室内と屋外の両環境で使用でき、さまざまな産業分野で多目的に使用できます。<br/><br/><strong>低消費電力運用： </strong>UWBテクノロジーは低消費電力のワイヤレス通信をサポートし、バッテリー寿命を延ばしエネルギー効率を向上させます。',
            dot: ''
        },
        {
            head: '将来の展望',
            subHead: '',
            desc: 'UWB RTLSテクノロジーは将来さらに重要な役割を果たすと予想されています。将来、UWBを活用したデバイスやシステムがさらに増加し、スマートシティ、スマートファクトリー、自動車、医療など、さまざまな分野でUWB RTLSテクノロジーが適用されると予想されています。これにより生産性向上、安全性向上、環境改善、利便性向上が期待されています。<br/><br/>要するに、UWB RTLSはソリューションの未来を形作る技術の一つとして注目されています。その安定性、精度、およびさまざまな産業への適用性を考慮すると、この技術は私たちの日常生活とさまざまな産業に肯定的な影響を与えると期待されています。この技術の開発と普及を通じて、未来はよりスマートで効率的な世界になるでしょう。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'この製品はUWB端末を労働者や機器に取り付けることでリアルタイムの位置追跡をサポートします。',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/jp/enterprise/construction/safety'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/jp/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/jp/enterprise/manufacturing/asset'
            }
        ]

    }
}
