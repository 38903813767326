export default {
  titleBanner: {
  subtitle: 'Monocular Vision',
  title: 'Position Tracking and Positioning Technology Using Monocular Vision',
  description: '* Monocular Vision is a computer vision technology that utilizes a single camera to acquire and analyze images. In contrast, stereo vision technology, which uses two cameras, measures depth and incorporates it into positioning.'
  },
  
  tech: {
    title: 'What is Monocular Vision?',
    text: 'Monocular vision is a visual technology that uses a single camera to obtain 2D image information. This technology is straightforward and can be easily implemented with common single cameras, making it widely used in various fields. Due to its ease of adoption with a regular single camera, it is extensively employed in various industrial sectors. <br/><br/> Monocular vision is used in object recognition and tracking, autonomous driving, robotics, and other fields. For example, in object recognition, deep learning algorithms are used to identify and track objects within monocular image sequences. Furthermore, in the field of autonomous driving, cameras are used to recognize lane markings, traffic lights, signs, pedestrians, estimate the positions and speeds of surrounding vehicles, and ensure safe operation.'
},
feature: {
    title: 'Key Advantages of RTLS Using Monocular Vision',
    text: 'Monocular Vision technology is cost-effective in building the system since it uses a single camera. The hardware cost is relatively low compared to stereo cameras, and the software processing is relatively simple as only a single image sensor is used. This allows for implementation on low-spec hardware. When combined with machine learning techniques, precise position estimation can be achieved based on previously trained data. This ensures high accuracy and, with the utilization of advanced deep learning techniques, training can be done with a small amount of data, reducing implementation and operation costs. <br/><br/> RTLS using Monocular Vision does not require tags, resulting in significant cost savings compared to traditional RTLS. The absence of separate tag terminals simplifies installation and maintenance, and high compatibility with existing facilities makes it easy to adopt. Moreover, as there is no need to install equipment (anchors) to receive tag signals, the space utilization is improved, enhancing overall facility efficiency.'
},

howItWorks: {
    title: 'How does Positioning with Monocular Vision Work?',
    card: {
        detection: {
            title: 'Object Detection',
            description: 'In vision-based RTLS, deep learning object recognition technology is utilized to track objects in images. This technology employs artificial intelligence algorithms trained to identify and classify various objects within the images. Currently, it can recognize around 80 different types of objects, and additional training can be performed to recognize more objects based on customer requirements.'
        },
        estimation: {
            title: 'Perspective Transform',
            description: `Perspective transform can convert specific points in 2D images to real-world coordinates. Camera matrices are constructed using the camera's internal and external parameters, and these matrices are used to transform 2D image points into points in real space.`
        }
    }
},

difference: {
    title: 'How is RTLS Using Monocular Vision Different from RTLS Using Stereo Vision?',
    description: 'Monocular Vision and Stereo Vision work fundamentally differently. Monocular Vision tracks the position of objects using a single camera, while Stereo Vision uses two cameras and estimates object positions based on the time difference between the cameras. Monocular Vision can be implemented at a relatively low cost as it only requires a single camera and has simplified hardware and software requirements. However, since it relies on 2D images from the camera, the accuracy and reliability may be comparatively lower. <br/><br/> On the other hand, due to the hardware complexity, implementation, including installation and overall cost, is higher for RTLS using Stereo Vision compared to Monocular Vision. The software processing is also more complex. Even when processing the same images, Stereo Vision RTLS requires more computing resources as it involves calculating time differences. Therefore, the choice between Monocular Vision and Stereo Vision for implementing RTLS depends on factors such as the intended use and budget.'
},

advantage: {
    title: 'Key Advantages of Monocular Vision',
    items: {
        accuracy: {
            title: 'Cost-Effective',
            description: 'Monocular Vision is cost-effective as it uses only one camera, resulting in lower hardware costs. Additionally, software processing is relatively simple as it utilizes a single image sensor, allowing implementation on lower-spec hardware.'
        },
        position: {
            title: 'Few Installation Constraints',
            description: `With Monocular Vision, objects can be tracked regardless of the camera's installation location. This versatility enables its application in various fields. For example, it can be used for indoor positioning, pedestrian detection and tracking in autonomous vehicles, and player tracking in sports.`
        },
        security: {
            title: 'Various Applications',
            description: 'This technology is cost-effective and easy to install and maintain as it requires only a single camera. As a result, it can be applied to everyday applications such as smart homes, robotics, and automobiles.'
        }
    }
},

useCase: {
    title: 'Use Cases of Positioning Using Monocular Vision',
    description: 'The scalability of Monocular Vision offers effective options for various indoor positioning use cases. The following are some use cases and application areas where Monocular Vision indoor positioning technology is utilized.',
    card: {
        home: {
            title: 'Smart Homes',
            description: 'Smart home technology provides functionality to monitor and control various aspects within a home. By using Monocular Vision technology, the positions and movements of people and objects can be detected, enabling more efficient energy usage, security, and convenience.'
        },
        space: {
            title: 'Occupancy Analysis',
            description: 'Occupancy data collected from spaces such as buildings or parking lots can be utilized to optimize space utilization. RTLS technology using Monocular Vision enables real-time tracking of the positions and movements of objects within a space. This helps understand space usage and improve efficiency.'
        },
        safety: {
            title: 'Industrial Safety',
            description: 'RTLS technology using Monocular Vision is also employed for maintaining safety in industrial settings. For example, real-time tracking of the positions of people or objects within a workplace helps prevent accidents that may occur during operations. In particular, in industrial environments, quickly detecting a fallen person or a worker who has suffered a serious injury can assist in adhering to the golden time for treatment. RTLS systems help detect various potential hazardous situations in industrial settings in advance, enabling early response. This ensures the safety and health of workers while enhancing productivity and efficiency.'
        },
        analyse: {
            title: 'Preference Analysis of Exhibit Items',
            description: 'Monocular Vision technology can be useful in determining customer preferences for exhibit items. By tracking the movements and interest levels of visitors toward exhibited items through cameras, customers with high interest in specific products can be identified, and the positioning and movement path design of exhibit items can be optimized. This plays an important role in the field of marketing.'
        },
        sports: {
            title: 'Sports Analysis',
            description: 'Using Monocular Vision technology, the positions and movements of players in sports games can be accurately tracked and analyzed. This can be utilized to improve players\' movements or develop game strategies.'
        },
        security: {
            title: 'Security',
            description: 'RTLS technology using Monocular Vision can be applied in various security aspects. For example, tracking the movement paths of objects or individuals can be used for access restriction systems for unauthorized personnel, CCTV monitoring, and more. Such technology can be utilized for managing the security of building entrances, key facilities, and military industries.'
        }
    }
}

}