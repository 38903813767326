import { SCREEN_BREAKPOINT, StyledGridColumn } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";

export const StyledUseCaseCard = styled(StyledGridColumn)`
  min-height: 480px;
  padding: 32px;
  border-radius: 24px;
  background-color: #fff;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    min-height: 560px;
    padding: 32px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    min-height: 256px;
    padding : 24px;
    margin-bottom : 16px;
  }
`;