export default {

  design: '제품 디자인',
  outlook: '제품 외형',
  spec: '제품 사양',

  product: {


    acces: [
      { text: "accesscontroller" },
      { text: "accessauto" },
      { text: "cloud" },
    ],

    accesscontroller: {
      title: 'Access Controller',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, ABS/Gray, ABS/Black, PC/Translucent',
            },
            {
              title: '치수',
              description: '85.4mm x 85.4mm x 24.5mm (가로 x 세로 x 높이)',
            },
          ]
        },

        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'Cortex M4 64MHz',
            },
            {
              title: '메모리',
              description: '512KB, 64KB',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '연결성',
              description: 'BLE 5.2',
            }
          ]
        },

        {
          name: '인터페이스',
          detail: [
            {
              title: '알람',
              description: 'RGB LED x 4',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '-10°C ~ +60°C, 0% ~ 90%',
            },
            {
              title: '전원',
              description: 'CR2477 1S 4P',
            }
          ]
        },

      ],

    },

    accessauto: {
      title: 'Access Auto',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, PC/Translucent Black',
            },
            {
              title: '치수',
              description: '210.1mm x 60.1mm x 42.2mm (가로 x 세로 x 높이)',
            },
          ]
        },

        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'Dual-core 32bit LX6, 240MHz',
            },
            {
              title: '메모리',
              description: 'FLASH 8MB, PSRAM 2MB',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '연결성',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n, 24GHz Microwave, SUB 1GHz',
            }
          ]
        },

        {
          name: '인터페이스',
          detail: [
            {
              title: '알람',
              description: 'Door control, Shock detection, BEEP',
            },
            {
              title: '시리얼',
              description: 'RS232(USB Console)',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '0°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: '전원',
              description: 'DC 12V, 24V (Non Polarity)',
            }
          ]
        },

      ],

    },
    cloud: {
      title: 'Access Controller',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, PC/Translucent Black',
            },
            {
              title: '치수',
              description: '210.1mm x 60.1mm x 42.2mm (가로 x 세로 x 높이)',
            },
          ]
        },

        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'Dual-core 32bit LX6, 240MHz',
            },
            {
              title: '메모리',
              description: 'FLASH 8MB, PSRAM 2MB',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '연결성',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n, 24GHz Microwave, SUB 1GHz',
            }
          ]
        },

        {
          name: '인터페이스',
          detail: [
            {
              title: '알람',
              description: 'Door control, Shock detection, BEEP',
            },
            {
              title: '시리얼',
              description: 'RS232(USB Console)',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '0°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: '전원',
              description: 'DC 12V, 24V (Non Polarity)',
            }
          ]
        },

      ],

    },




    serverpcRTLS: {
      title: 'Server PC',
      spec: [
        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'Intel Xeon E-2334 (3.4Ghz 4Core 8M L3 65W)',
            },
            {
              title: '메모리',
              description: '16GB 3200MT/s DDR4 ECC UDIMM, 최대 64GB, 4 slots',
            },
            {
              title: '저장 공간',
              description: '1TB 7.2K RPM SATA 3.5',
            },
          ]
        },
        {

          name: '네트워크',
          detail: [
            {
              title: '네트워크 컨트롤러',
              description: '온보드 Broadcom 5720 듀얼 포트 1Gb LOM'
            }
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '중량',
              description: '12.2kg',
            },
            {
              title: '확장 슬롯',
              description: '2 PCIe slots',
            },
            {
              title: '전원',
              description: '듀얼, 핫플러그, 예비 전원 공급 장치 (1+1), 600W',
            }
          ]
        },

      ],

    },

    serverpc: {
      title: 'Server PC',
      spec: [
        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'Intel Xeon silver 4210R, 1CPU, 10 Core',
            },
            {
              title: '메모리',
              description: '32GB',
            },
            {
              title: '저장 공간',
              description: '1TB + 4TB',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '중량',
              description: '17.6kg',
            },
            {
              title: '확장 슬롯',
              description: '16 PCI Gen3 슬롯 * 2',
            },
            {
              title: '전원',
              description: '450w',
            }
          ]
        },

      ],

    },


    accesspro: {
      title: 'Access Pro',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'Aluminum/Gray, PC/Translucent',
            },
            {
              title: '치수',
              description: '256mm x 273mm x 24mm (가로 x 세로 x 높이)',
            },
            {
              title: '무게',
              description: '1.2Kg',
            },
          ]
        },
        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'Quad core-A17 1.8Ghz',
            },
            {
              title: '메모리',
              description: '2GB / 16GB',
            },
          ]
        },

        {
          name: '센서 및 카메라',
          detail: [
            {
              title: '이미지 센서',
              description: '1/3.06" CMO, MN34229',
            },
            {
              title: '렌즈 타입',
              description: 'Fixed-focal',
            },
            {
              title: '카메라 화각',
              description: '180º',
            },
            {
              title: '해상도',
              description: '1920 x 1080',
            }
          ]
        },

        {
          name: '디스플레이',
          detail: [
            {
              title: '해상도',
              description: '10.1" TFT LCD 1280x800',
            },
          ]
        },

        {
          name: '인터페이스',
          detail: [
            {
              title: '오디오',
              description: 'MIC, Speaker',
            },
            {
              title: '알림',
              description: 'Door Control, Emergency Call',
            },
            {
              title: 'USB',
              description: 'USB2.0 Host x2, USB2.0 OTG x 1',
            },
            {
              title: '시리얼',
              description: 'RS232(USB Console)',
            }
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '0°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: '전원',
              description: 'ADAPTOR 12V / 3A',
            },
          ]
        },

      ],

    },


    accesskey: {
      title: 'Access Key',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'Aluminum/Gray, PC/Translucent, ABS/White',
            },
            {
              title: '치수',
              description: '49mm x 26mm x 9mm (가로 x 세로 x 높이)',
            },
          ]
        },
        {
          name: '네트워크',
          detail: [
            {
              title: '연결성',
              description: '24.GHz / Bluetooth 5.0',
            },
          ]
        },

        {
          name: '인터페이스',
          detail: [
            {
              title: '알림',
              description: 'LED(Status)',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '0°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: '전원',
              description: '3.7V - 75mAh / Lithium Polymer',
            },
            {
              title: '정격',
              description: '5V / 40mA',
            },
          ]
        },

      ],

    },


    emlock: {
      title: 'EM Lock',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'Aluminum/Gray',
            },
            {
              title: '치수',
              description: '178mm x 38mm x 25mm (가로 x 세로 x 높이)',
            },
          ]
        },
        {
          name: '기능 및 특징',
          detail: [
            {
              title: '인장력',
              description: '280KG/F',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '-10°C ~ +55°C',
            },
            {
              title: '작동 습도',
              description: '0 - 90%',
            },
            {
              title: '전원',
              description: '12V/40mA',
            },
          ]
        },

      ],

    },

    deadbolt: {
      title: 'Deadbolt Lock',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'Aluminum/Gray',
            },
            {
              title: '치수',
              description: '203mm x 37mm x 41mm (가로 x 세로 x 높이)',
            },
            {
              title: '무게',
              description: '529g',
            },
          ]
        },
        {
          name: '기능 및 특징',
          detail: [
            {
              title: '특장점',
              description: '자체 오픈 접점 내장 / 락 모니터링 / 도어 모니터링 가능',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '-10°C ~ +55°C',
            },
            {
              title: '작동 습도',
              description: '0 - 90%',
            },
            {
              title: '소비 전류',
              description: '작동시 900mA / 대기시 350mA',
            },
            {
              title: '전원',
              description: 'DC 12V',
            },
          ]
        },

      ],

    },

    autoDoorSensor: {
      title: 'Automatic Door Sensor',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, PC/Translucent Black',
            },
            {
              title: '치수',
              description: '210.1mm x 60.1mm x 42.2mm (가로 x 세로 x 높이)',
            }
          ]
        },
        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'Dual-core 32bit LX6, 240MHz',
            },
            {
              title: '메모리',
              description: 'FLASH 8MB, PSRAM 2MB',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '연결성',
              description: '24GHz Microwave, SUB 1GHz',
            },
          ]
        },
        {
          name: '인터페이스',
          detail: [
            {
              title: '알람',
              description: 'Door control, Shock detection',
            },
          ]
        },
        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '0°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: '전원',
              description: 'DC 12V, 24V (Non Polarity)',
            },
          ]
        },

      ],

    },



    twinTracker: {
      link: 'assetTagBle',
      title: 'TwinTracker',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, PC/Translucent White, Aluminum/Black',
            },
            {
              title: '치수',
              description: '95mm x 95mm x 32.5mm (가로 x 세로 x 높이)',
            },
          ]
        },

        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'Dual-core 32bit LX6,240MHz',
            },
            {
              title: '메모리',
              description: '448KB ROM, 520 KB SRAM',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '이더넷',
              description: '100Base-TX',
            },
            {
              title: '무선',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n',
            }
          ]
        },

        {
          name: '인터페이스',
          detail: [
            {
              title: '시리얼',
              description: 'RS232(USB Console)',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '0°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: '전원',
              description: '3.2W, PoE 802.3af, USB 5V',
            }
          ]
        },

      ],

    },

    twinTrackerPro: {
      link: 'assetTagBle',
      title: 'TwinTracker Pro',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, PC/Translucent White, Aluminum/Black',
            },
            {
              title: '치수',
              description: '95mm x 95mm x 32.5mm (가로 x 세로 x 높이)',
            },
          ]
        },

        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'Dual-core 32bit LX6,240MHz',
            },
            {
              title: '메모리',
              description: '448KB ROM, 520 KB SRAM',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '이더넷',
              description: '100Base-TX',
            },
            {
              title: '무선',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n',
            }
          ]
        },

        {
          name: '인터페이스',
          detail: [
            {
              title: '시리얼',
              description: 'RS232(USB Console)',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '-20°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: '전원',
              description: '3.2W, PoE 802.3af, USB 5V',
            }
          ]
        },

      ],

    },


    assetTagBle: {
      title: 'Asset Tag(BLE)',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, ABS/Black, PC/Translucent',
            },
            {
              title: '치수',
              description: '48.4mm x 48.4mm x 10.7mm (가로 x 세로 x 높이)',
            },
            {
              title: '무게',
              description: '22g',
            },
          ]
        },

        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'Cortex M4 64MHz',
            },
            {
              title: '메모리',
              description: '512KB',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '무선',
              description: 'BLE 5.2',
            }
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '-20°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: '전원',
              description: 'CR2450',
            }
          ]
        },

      ],

    },

    assetTag: {
      title: 'Asset Tag',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, ABS/Gray',
            },
            {
              title: '치수',
              description: '31mm x 31mm x 3.8mm (가로 x 세로 x 높이)',
            },
          ]
        },

        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'Cortex M4 64MHz',
            },
            {
              title: '메모리',
              description: '64KB',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '무선',
              description: 'BLE 5.2',
            }
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '-20°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: '전원',
              description: 'CR2012',
            }
          ]
        },

      ],

    },

    tagUwb: {
      title: 'Tag(UWB)',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/Black',
            },
          ]
        },
        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'Cortex M4 64MHz',
            },
            {
              title: '메모리',
              description: '512KB',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '무선',
              description: 'UWB 802.15.4',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '-20°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: '전원',
              description: 'USB-C',
            },
          ]
        },

      ],

    },





    safetyBell: {
      title: 'Safety Bell',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, ABS/Black, PC/Translucent White',
            },
            {
              title: '치수',
              description: '64.2mm x 64.2mm x 16.2mm (가로 x 세로 x 높이)',
            },
          ]
        },
        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'ARM Cortex M4, 64Mhz',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '무선',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n',
            },
          ]
        },
        {
          name: '인터페이스',
          detail: [
            {
              title: '오디오',
              description: 'Buzzer',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '-20°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: '전원',
              description: 'AAA x 2',
            },
          ]
        },

      ],

    },




    smartSwitch: {
      title: 'Smart Switch',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, ABS/Black, Aluminum/Silver',
            },
            {
              title: '치수',
              description: '76mm x 120mm x 44.3mm (가로 x 세로 x 높이)',
            },
          ]
        },
        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'ARM® Cortex®-M4 32-Bit Processor with FPU, 64MHz',
            },
            {
              title: '메모리',
              description: '215 EEMBC CoreMark® Score Running from Flash Memory',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '무선',
              description: 'BLE 5.0, Wi-Fi 802.11 b/g/n',
            },
          ]
        },

        {
          name: '인터페이스',
          detail: [
            {
              title: '터치',
              description: '',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '0°C ~ +60°C',
            },
          ]
        },

      ],

    },




    esl: {
      title: 'ESL',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, PC/Transparent',
            },
            {
              title: '치수',
              description: '76mm x 142mm x 10mm (가로 x 세로 x 높이)',
            },
          ]
        },
        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'ARM® Cortex®-M4 32-Bit Processor with FPU, 64MHz',
            },
            {
              title: '메모리',
              description: '215 EEMBC CoreMark® Score Running from Flash Memory',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '무선',
              description: 'BLE 5.0',
            },
          ]
        },

        {
          name: '디스플레이',
          detail: [
            {
              title: '해상도',
              description: '800 x 600(230dpi)',
            },
            {
              title: '색상',
              description: 'Black, White',
            },
            {
              title: 'Max Grayscale',
              description: '16',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '커넥터',
              description: 'USB-C',
            },
            {
              title: '전원',
              description: '5V, 500mA',
            },
          ]
        },


      ],

    },

    mTag: {
      title: 'Mobile Tag',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, PC/Transparent',
            },
            {
              title: '치수',
              description: '38mm x 50.1mm x 18mm (가로 x 세로 x 높이)',
            },
          ]
        },
        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'ARM® Cortex®-M4 64MHz',
            },
            {
              title: '메모리',
              description: '512KB',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '무선',
              description: 'BLE 5.2',
            },
          ]
        },

        {
          name: '인터페이스',
          detail: [
            {
              title: '알림',
              description: 'LED(White) x 1',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '커넥터',
              description: 'Lightning, USB-C',
            },
            {
              title: '전원',
              description: 'Lithium Polymer Battery(4.2V, 35mAh)',
            },
          ]
        },


      ],

    },

    mCradle: {
      title: 'Mobile Cradle',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, PC/Transparent',
            },
            {
              title: '치수',
              description: '76mm x 40mm x 25mm (가로 x 세로 x 높이)',
            },
          ]
        },
        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'ARM® Cortex®-M4 64MHz',
            },
            {
              title: '메모리',
              description: '512KB',
            },
            {
              title: '센서',
              description: '3Axis Sensor',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '무선',
              description: 'BLE 5.2',
            },
          ]
        },

        {
          name: '인터페이스',
          detail: [
            {
              title: '알림',
              description: 'LED(White) x 1',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '전원',
              description: 'DC 5V(USB-C type)',
            },
          ]
        },


      ],

    },

    idCard: {
      title: 'ID02',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, Aluminum',
            },
            {
              title: '치수',
              description: '51mm x 95.9mm x 7mm(가로 x 세로 x 높이)',
            },
          ]
        },
        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'ARM® Cortex®-M4 32-Bit Processor with FPU, 64MHz',
            },
            {
              title: '메모리',
              description: '215 EEMBC CoreMark® Score Running from Flash Memory<br/>58μA/MHz Running from Flash Memory<br/>51.6μA/MHz Running from RAM.',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '무선',
              description: 'BLE 5.2',
            },
          ]
        },

        {
          name: '인터페이스',
          detail: [
            {
              title: '알림',
              description: 'LED(RGB) x 1',
            },
            {
              title: 'USB',
              description: 'USB 2.0 Device x 1',
            },
            {
              title: '버튼',
              description: 'Slide Switch Button',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '0°C ~ 50°C',
            },
            {
              title: '전원',
              description: 'Li-polymer (3.7V / 300mA)',
            },
          ]
        },


      ],

    },


    ptz: {
      title: 'PTZ Camera',
      spec: [
        {
          name: '외형',
          detail: [
            {
              title: '치수',
              description: '266mm x 266mm x 410mm (가로 x 세로 x 높이)',
            },
          ]
        },

        {
          name: '카메라',
          detail: [
            {
              title: '이미지 센서',
              description: '1/1.8” 프로그레시브 스캔 CMOS',
            },
            {
              title: '줌',
              description: '42배 광학, 16배 디지털',
            },
            {
              title: '해상도',
              description: '2560 x 1440',
            }
          ]
        },

        {
          name: '렌즈',
          detail: [
            {
              title: '초점 거리',
              description: '6.0mm - 252mm',
            },
            {
              title: '줌 속도',
              description: '4.5초',
            },
            {
              title: '조리개',
              description: 'F 1.2',
            },
          ]
        },

        {
          name: 'PTZ',
          detail: [
            {
              title: '이동 범위(Pan)',
              description: '360°',
            },
            {
              title: '이동 범위(Tilt)',
              description: '-20°에서 90°',
            },
          ]
        },

        {
          name: '인터페이스',
          detail: [
            {
              title: '이더넷',
              description: '1 RJ45 10M/100M 자체 적응형 이더넷 포트 1개, Hi-PoE',
            },
            {
              title: '알람',
              description: '7 inputs, 2 outputs',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '전원',
              description: '24VAC, PoE',
            },
            {
              title: '작동 조건',
              description: '-40°C ~ +70°C, 0% ~ 95%',
            },
            {
              title: '방수 방진',
              description: 'IP67',
            },
          ]
        },

      ],

    },



    ip: {
      title: 'IP Camera',
      spec: [
        {
          name: '외형',
          detail: [
            {
              title: '치수',
              description: '135mm x 135mm x 110mm (가로 x 세로 x 높이)',
            },
            {
              title: '무게',
              description: '약 800g',
            },
          ]
        },

        {
          name: '카메라',
          detail: [
            {
              title: '이미지 센서',
              description: '1/2.8" 프로그레시브 스캔 CMOS',
            },
            {
              title: '해상도',
              description: '1920 × 1080 ',
            }
          ]
        },

        {
          name: '렌즈',
          detail: [
            {
              title: '렌즈 유형',
              description: '고정 초점 렌즈, 2.8 및 4mm 옵션 ',
            },
            {
              title: '조리개',
              description: 'F 1.4',
            },
          ]
        },

        {
          name: '인터페이스',
          detail: [
            {
              title: '이더넷',
              description: 'RJ45 10 M/100 M 자체 적응형 이더넷 포트 1개 ',
            },
            {
              title: '알람',
              description: '-S: 알람 입력 1개, 알람 출력 1개 (최대. 12 VDC, 30 mA) ',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '전원',
              description: '12 VDC',
            },
            {
              title: '작동 조건',
              description: '-30°C ~ +60°C, 0% ~ 95%',
            },
            {
              title: '방수 방진',
              description: 'IP67',
            },
          ]
        },

      ],

    },

    nvr: {
      title: 'NVR',
      spec: [
        {
          name: '비디오 및 오디오',
          detail: [
            {
              title: 'IP 비디오 입력',
              description: '8채널',
            },
            {
              title: '비디오 출력 모드',
              description: 'HDMI/VGA 독립 출력',
            },
            {
              title: 'HDIM 출력',
              description: '4K (3840 × 2160)/30Hz, 2K (2560 × 1440)/60Hz, 1920 × 1080/60Hz, 1600 × 1200/60Hz, 1280 × 1024/60Hz, 1280 × 720/60Hz ',
            },
            {
              title: 'VGA 출력',
              description: '1920 × 1080/60 Hz, 1280 × 1024/60 Hz, 1280 × 720/60 Hz',
            },
            {
              title: '오디오 입력',
              description: '1-채널, RCA (2.0 Vp-p, 1 KΩ) ',
            },
            {
              title: '오디오 출력',
              description: '1-ch, RCA (선형, 1 KΩ)',
            }
          ]
        },

        {
          name: '디코딩',
          detail: [
            {
              title: '디코딩 형식',
              description: 'H.265/H.265+/H.264/H.264+',
            },
            {
              title: '녹음 해상도',
              description: '12 MP/8 MP/6 MP/5 MP/4 MP/3 MP/1080p/UXGA/720p/VGA/4CIF/DCIF/2CIF/CIF/QCIF',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '네트워크 프로토콜',
              description: 'TCP/IP, DHCP, IPv4, IPv6, DNS, DDNS, NTP, RTSP, SADP, SMTP, SNMP, NFS, iSCSI, ISUP, UPnP™, HTTP, HTTPS',
            },
            {
              title: '네트워크 인터페이스',
              description: '1, RJ-45 10/100/1000 Mbps 자체 적응형 이더넷 인터페이스 ',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '전원',
              description: '12VDV',
            },
            {
              title: '동작 조건',
              description: '-10°C ~ +55°C, 10% ~ 90%',
            },
          ]
        },


      ],

    },


    loraGateway: {
      title: 'LoRa 중계기',
      spec: [
        {
          name: '공통 사양',
          detail: [
            {
              title: '소재',
              description: 'Aluminum/Gray',
            },
            {
              title: '치수',
              description: '145mm x 145mm x 42mm (가로 x 세로 x 높이)',
            },
            {
              title: '중량',
              description: '750g',
            },
            {
              title: 'RF 범위',
              description: '920.9MHz~923.1MHz BW 125KHz',
            },
            {
              title: '통신 거리',
              description: 'LOS(Line of Sight) 6Km 이내',
            },
            {
              title: '안테나 수신 감도',
              description: '-135 dBm',
            },
            {
              title: '안테나 송신 출력',
              description: '20dBm',
            },
            {
              title: '보안',
              description: 'AES-128',
            }
          ]
        },

        {
          name: 'Ethernet Gateway',
          detail: [
            {
              title: 'Ethernet 스펙',
              description: '10/100 Ethernet Controller',
            },
            {
              title: 'IEEE Standard Compliance',
              description: '802.3',
            },
            {
              title: '구동 방식',
              description: 'Full–Duplex and Half-Duplex',
            },
            {
              title: '지원 프로토콜',
              description: 'DHCP, DNS, NTP, UDP, TCP, SNMP',
            },
            {
              title: '케이블 연결',
              description: 'Ethernet',
            },
          ]
        },

        {
          name: 'Wi-Fi',
          detail: [
            {
              title: 'IEEE Standard Compliance',
              description: '802.11b/g/n 2.4GHz',
            },
          ]
        },

        {
          name: 'Cellular LTE',
          detail: [
            {
              title: 'LTE Spec',
              description: 'LTE CatM1(Telit Me910 C1)',
            },
            {
              title: 'SIM Card 규격',
              description: 'Micro-SIM(3FF) 15mm x 12mm x 0.76mm',
            },
          ]
        },
        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '-20 ~ 60 °C',
            },
            {
              title: '전원',
              description: 'DC 8 ~ 24.0 V',
            },
            {
              title: '인증',
              description: 'KC, IP67',
            },
          ]
        },


      ],

    },

    loraSensor: {
      title: 'LoRa 센서',
      spec: [
        {
          name: '공통 사양',
          detail: [
            {
              title: '소재',
              description: 'Aluminum/Gray',
            },
            {
              title: '치수',
              description: '84mm x 72mm x 31mm (가로 x 세로 x 높이)',
            },
            {
              title: '중량',
              description: '300g',
            },
            {
              title: 'RF 범위',
              description: '920.9MHz~923.1MHz BW 125KHz',
            },
            {
              title: '통신방식',
              description: 'LoRa',
            },
            {
              title: '통신 거리',
              description: 'LOS(Line of Sight) 6Km 이내',
            },
            {
              title: '보안',
              description: 'AES-128',
            }
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '-20 ~ 60 °C',
            },
            {
              title: '전원',
              description: 'DC 9V ~ 33V<br/>AA Battery DC 3.6V / 2,500mAh x 2EA (병렬 연결 총 5,000mAh)<br/>AA Battery DC 1.5V / 2,850mAh x 2EA (직렬 연결 총 2,850mAh)',
            },
            {
              title: '인증',
              description: 'KC, IP67',
            },
          ]
        },


      ],

    },

    airQuality: {
      title: '공기품질 측정기',
      spec: [
        {
          name: '공통 사양',
          detail: [
            {
              title: '소재',
              description: 'ABS/White',
            },
            {
              title: '치수',
              description: '115mm X 115mm X 30mm (가로 x 세로 x 높이)',
            },
            {
              title: '중량',
              description: '215g',
            },
            {
              title: '디스플레이',
              description: '2.0" TFT DISPLAY',
            },
            {
              title: '통신방식',
              description: 'LoRa(옵션), RS-485',
            },
          ]
        },

        {
          name: '측정 범위',
          detail: [
            {
              title: '미세먼지',
              description: 'PM1.0/PM2.5/PM4.0/PM10 : 0 ~ 1,000 ㎍/㎥',
            },
            {
              title: '이산화탄소',
              description: '400 ~ 10,000 ppm',
            },
            {
              title: 'TVOC',
              description: '0 ~ 6,000 ppb',
            },
            {
              title: '온실가스',
              description: '0 ~ 60,000 ppm',
            },
            {
              title: '온도',
              description: '-40 ~ 125 ℃',
            },
            {
              title: '습도',
              description: '0 ~ 100 %RH',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '-20 ~ 60 ℃',
            },
            {
              title: '전원',
              description: 'DC 24V / 0.1A<br/>Li-polymer  1500mAh/3.7V',
            },
            {
              title: '인증',
              description: 'KC',
            },
          ]
        },


      ],

    },


    coMeasure: {
      title: '일산화탄소 측정기',
      spec: [
        {
          name: '공통 사양',
          detail: [
            {
              title: '소재',
              description: 'Aluminum/Gray',
            },
            {
              title: '치수',
              description: '93mm X 125mm X 44mm (가로 x 세로 x 높이)',
            },
            {
              title: '중량',
              description: '245g',
            },
            {
              title: '통신방식',
              description: 'WiFi(2.4GHz only 802.11 b/g/n) 및 RS-485(Modbus RTU)',
            },
          ]
        },

        {
          name: '측정 범위',
          detail: [
            {
              title: '미세먼지',
              description: 'PM1.0/PM2.5/PM4.0/PM10 : 0 ~ 1,000 ㎍/㎥',
            },
            {
              title: '일산화탄소',
              description: '0 ~ 100 ppm (상온 기준)',
            },
            {
              title: '온도',
              description: '-40 ~ 125℃',
            },
            {
              title: '습도',
              description: '0 ~ 100 %RH',
            },
          ]
        },

        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '-10 ~ 40 ℃',
            },
            {
              title: '전원',
              description: 'DC 24V / 0.1A',
            },
            {
              title: '인증',
              description: 'KC/CE/FCC',
            },
          ]
        },


      ],

    },

    plutoconPro: {
      title: 'Plutocon Pro',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, ABS/Gray, ABS/Black, PC/Translucent',
            },
            {
              title: '치수',
              description: '85.4mm x 85.4mm x 24.5mm (가로 x 세로 x 높이)',
            },
          ]
        },

        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'Cortex M4 64MHz',
            },
            {
              title: '메모리',
              description: '512KB, 64KB',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '연결성',
              description: 'BLE 5.2',
            },
          ]
        },
        {
          name: '인터페이스',
          detail: [
            {
              title: '알람',
              description: 'RGB LED x 4',
            },
          ]
        },
        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '-10°C ~ +60°C, 0% ~ 90%',
            },
            {
              title: '전원',
              description: 'CR2477 1S 4P',
            },
          ]
        },


      ],

    },
    orbroTag: {
      title: 'ORBRO Tag',
      spec: [
        {
          name: '외형 및 소재',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, ABS/Black, PC/Translucent',
            },
            {
              title: '치수',
              description: '48.4mm x 48.4mm x 10.7mm (가로 x 세로 x 높이)',
            },
            {
              title: '중량',
              description: '22g',
            },
          ]
        },

        {
          name: '프로세서 및 메모리',
          detail: [
            {
              title: '프로세서',
              description: 'Cortex M4 64MHz',
            },
            {
              title: '메모리',
              description: '512KB',
            },
          ]
        },

        {
          name: '네트워크',
          detail: [
            {
              title: '무선',
              description: 'BLE 5.2',
            },
          ]
        },
        {
          name: '사용 환경 및 전원',
          detail: [
            {
              title: '작동 조건',
              description: '-20°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: '전원',
              description: 'CR2450',
            },
          ]
        },


      ],

    },

    deliveryRobot: {
      title: 'Delivery Robot',
      spec: [
        {
          name: ' ',
          detail: [
            {
              title: '소재',
              description: 'ABS/White, PC/Translucent White, Aluminum/Black',
            },
            {
              title: '치수',
              description: '510 x 510 x 1300 (가로 x 세로 x 높이)',
            },
            {
              title: '적재 용량',
              description: '20L',
            },
            {
              title: '주행 환경',
              description: '실내',
            },
            {
              title: '주행 경사각',
              description: '+5도/-5도',
            },
            {
              title: '주행턱 높이',
              description: '1cm/-1cm',
            },
            {
              title: '조도',
              description: '60 ~ 2,000lx(Min ~ Max)',
            },
          ]
        },

      ],

    },


    loadingKiosk: {
      title: 'Loading Kiosk',
      spec: [
        {
          name: ' ',
          detail: [
            {
              title: '치수',
              description: '720 x 800 x 611 (가로 x 세로 x 높이)',
            },
            {
              title: '처리 용량',
              description: '20L/30L',
            },
            {
              title: '디스플레이',
              description: '10.1 Inch Multi-touch Display',
            },
            {
              title: '네트워크',
              description: 'Ethernet(100Base-TX)/Wi-Fi',
            },
            {
              title: '전원',
              description: '100~240VAC/120W',
            },
          ]
        },

      ],

    },

    cobot: {
      title: 'Cobot',
      spec: [
        {
          name: ' ',
          detail: [
            {
              title: '치수',
              description: '220 x 227 x 1300 (가로 x 세로 x 높이)',
            },
            {
              title: '최대 거리',
              description: '1300mm',
            },
            {
              title: '최대 하중',
              description: '12kg',
            },
            {
              title: '자유도',
              description: '60DoF',
            },
            {
              title: '네트워크',
              description: 'Ethernet(100Base-TX)',
            },
            {
              title: '전원',
              description: '100~240VAC/1kW',
            },
          ]
        },

      ],

    },



    apart: {
      title: 'multi',
      list: [
        { text: "accesscontroller" },
        { text: "serverpc" },
      ]

    },


    assetTracking: {
      title: 'multi',
      list: [
        { text: "twinTracker" },
        { text: "assetTagBle" },
        { text: "serverpcRTLS" },
      ]

    },

    workerTracking: {
      title: 'multi',
      list: [
        { text: "twinTrackerPro" },
        { text: "tagUwb" },
        { text: "serverpcRTLS" },
      ]

    },

    carTracking: {
      title: 'multi',
      list: [
        { text: "ptz" },
        { text: "nvr" },
        { text: "serverpc" },
      ]

    },

    peopleTracking: {
      title: 'multi',
      list: [
        { text: "ip" },
        { text: "nvr" },
        { text: "serverpc" },
      ]

    },

    robotBasic: {
      title: 'multi',
      list: [
        { text: 'deliveryRobot' },
        { text: 'twinTrackerPro' },
        { text: 'tagUwb' },
        { text: 'serverpc' },
      ]
    },

    delivery: {
      title: 'multi',
      list: [
        { text: 'deliveryRobot' },
        { text: 'loadingKiosk' },
        { text: 'cobot' },
        { text: 'twinTrackerPro' },
        { text: 'tagUwb' },
        { text: 'serverpc' },
      ]
    },

    disinfection: {
      title: 'multi',
      list: [
        { text: 'deliveryRobot' },
        { text: 'loadingKiosk' },
        { text: 'cobot' },
        { text: 'twinTrackerPro' },
        { text: 'tagUwb' },
        { text: 'serverpc' },
      ]
    }




  }

}