import { StyledGridColumnProps } from "@components/Grid/Grid.model";
import { SCREEN_BREAKPOINT, StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledHeading, StyledLabel } from "@components/Text";
import styled from "@emotion/styled";


interface LayoutInfo {
  breakPoint: number;
  padding: number;
  gap: number;
  columns: number;
}

const DESKTOP: LayoutInfo = {
  breakPoint: 1280,
  gap: 32,
  padding: 24,
  columns: 12,
};

const TABLET: LayoutInfo = {
  breakPoint: 960,
  gap: 32,
  padding: 24,
  columns: 8,
};

const MOBILE: LayoutInfo = {
  breakPoint: 768,
  gap: 32,
  padding: 16,
  columns: 4,
};

interface StyledGridRowProps {
  desktop?: number
  tablet?: number
  mobile?: number
}



export const StyledGridColumnMenu = styled.div<StyledGridColumnProps>`
  position: relative;
  display: grid;
  gap : 32px;

  -ms-grid-column-span: ${({ desktop }) => desktop};
  grid-column-end: span ${({ desktop }) => desktop};

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    -ms-grid-column-span: ${({ tablet }) => tablet};
    grid-column-end: span ${({ tablet }) => tablet};
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    -ms-grid-column-span: ${({ mobile }) => mobile};
    grid-column-end: span ${({ mobile }) => mobile};
  }
`;



export const MenuTreeLayout = styled.div`

  position : absolute;
  top : 100%;
  left : 0;
  width : 100%;
  height : auto;
  
  border-bottom : 1px solid rgba(0,0,0,0.08);
  background-color : #FFFFFF;
  z-index : 6;
  
`;

export const MenuContainer = styled(StyledGridContainer)`

  padding : 32px 0;
  height : 100%;
  
  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    padding : 32px 0;
  }
`;

export const MenuLayout = styled.div`
  padding : 0 24px 64px 24px;
  width : 100%;
  height : 100%;
  
  display : flex;
  flex-direction : row;
  row-gap : 32px;

`;

export const MenuColumn = styled.div`
  display : flex;
  flex-direction : row;
  width : 100%;
  gap : 32px;
`;

export const MenuBigBox = styled.div`

  width : 100%;
  height : 100%;
  display : flex;
  flex-direction : row;
  background-color : yellow;
`;


export const MenuLine = styled.div`

  width : 100%;
  height : 100%;
  display : flex;
  flex-direction : row;
  background-color : yellow;
`;



export const MenuBox = styled.div`

  width : 100%;
  height : 100%;
  display : flex;
  flex-direction : column;
  backgrond-color : yellow;
  
`;


export const MenuIcon = styled.img`
  width : 90px;
  height : 60px;
  display : flex;
  object-fit : contain;

`;


export const MenuOS = styled.div`

  width : 100%;
  display : flex;
  flex-direction : column;
  padding-left : 32px;
  border-left : 1px solid rgba(0,0,0,0.08);

`;

export const MenuOSIcon = styled.img`
  width : 100%;
  height : 140px;
  display : flex;
  object-fit : contain;

`;

export const MenuHoverHeading = styled(StyledHeading)`
  width : fit-content;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }
`;
export const MenuHoverLabel = styled(StyledLabel)`
  width : fit-content;
  color : #646464;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }
`;




export const DivGridRowPadding = styled.div<StyledGridRowProps>`
    width: 100%;
    position: relative;
    gap: 32px;
    flex-wrap: wrap;
    display: grid;
    display: -ms-grid;
    padding : 0 24px 64px 24px;

    -ms-grid-columns: repeat(${({ desktop = DESKTOP.columns }) => desktop}, 1fr);
    grid-template-columns: repeat(${({ desktop = DESKTOP.columns }) => desktop}, 1fr);

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    -ms-grid-columns: repeat(${({ tablet = TABLET.columns }) => tablet}, 1fr);
    grid-template-columns: repeat(${({ tablet = TABLET.columns }) => tablet}, 1fr);
    }

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    -ms-grid-columns: repeat(${({ mobile = MOBILE.columns }) => mobile}, 1fr);
    grid-template-columns: repeat(${({ mobile = MOBILE.columns }) => mobile}, 1fr);
    row-gap: 16px;
    }
`;


export const ArrowImg = styled.img`
    width : 20px;
    height : 20px;
    margin-left : 4px;
    display : flex;
    object-fit : contain;
`;