export default {
    titleBanner: {
      subtitle: 'Monocular Vision',
      title: 'Monocular Vision을 이용한 <br/> 위치 추적 및 포지셔닝 기술',
      description: '* Monocular Vision은 단안 카메라를 활용하여 이미지를 획득하고 분석하는 컴퓨터 비전 기술입니다.  <br/> 이에 대비되는 기술로 스테레오 비전 기술이 있으며, 스테레오 비전에서는 두 개의 카메라를 사용하여 깊이를 측정하고 이를 포지셔닝에 반영합니다.'
    },

    tech: {
      title: 'Monocular Vision이란 무엇인가요 ?',
      text: 'Monocular vision은 단안카메라를 이용하여 2차원 영상 정보를 얻어내는 시각 기술입니다. 이 기술은 매우 간단하며, 흔한 단안 카메라만 있으면 쉽게 구현할 수 있어서 많은 분야에서 사용됩니다. 일반적인 단안 카메라로 쉽게 도입할 수 있기 때문에 다양한 산업 분야에서 널리 사용되고 있습니다.  <br/>  <br/> Monocular vision은 객체 인식 및 추적, 자율주행, 로봇 공학 등 다양한 분야에서 사용됩니다. 예를 들어, 객체 인식 분야에서는 딥러닝 알고리즘을 활용하여 Monocular 이미지 시퀀스 내의 객체를 인식하고 추적하는 기술이 보편화 되었습니다.  또한 자율주행 분야에서는 카메라를 이용하여 도로 위에 있는 차선, 신호등, 표지판, 보행자 등을 인식하고, 주변 차량의 위치와 속도를 추정하여 안전하게 운행하는데 사용됩니다.'
    },
    feature: {
      title: 'Monocular Vision을 이용한 RTLS의 특장점',
      text: 'Monocular Vision 기술은 단안 카메라를 사용하기 때문에 시스템을 구축하는 데 필요한 비용이 상대적으로 적습니다. 단안카메라는 스테레오 카메라와 비교해 상대적으로 단가가 낮아 대규모 설치나 광범위한 영역에서 RTLS를 구축하는 경우, 다른 기술보다 더 저렴하게 구축할 수 있습니다. 또한 머신러닝 기술과 결합하여, 이전에 학습된 데이터를 기반으로 정확한 위치 추정을 가능케 합니다. 이는 높은 정확도를 보장하며, 최신의 딥러닝 기술을 활용하면 적은 양의 데이터로도 학습이 가능하기 때문에 구축 및 운영 비용이 줄어듭니다. <br/>  <br/> Monocular Vision을 이용한 RTLS는 Tag가 필요하지 않기 때문에 기존의 RTLS와 비교하여 비용적인 측면에서 큰 절감 효과를 가져옵니다. 별도의 Tag 단말이 필요하지 않다는 것은 설치 및 운영이 간편하고, 기존 시설물과의 호환성이 높아 도입이 용이하다는 것을 의미합니다. 또한 Tag의 신호를 수신하기 위한 장비(Anchor)를 설치할 필요가 없기 때문에 공간 활용도가 높아지고, 이를 바탕으로 시설물의 효율성을 높일 수 있습니다.'
    },
    
    howItWorks: {
      title: 'Monocular Vision을 이용한 포지셔닝은 어떻게 동작하나요 ?',
      card: {
        detection: {
          title: 'Object Detection',
          description: ' Vision 기반의 RTLS에서는 이미지에서 대상을 추적하기 위해 Deep Learning 객체 인식 기술을 사용합니다. 이 기술은 이미지 내에 있는 다양한 물체를 식별하고 분류할 수 있도록 학습된 인공지능 알고리즘입니다. 현재는 약 80 종류의 물체를 인식할 수 있으며, 고객의 요청에 따라 추가적인 학습을 통해 더 많은 종류의 물체를 인식할 수 있습니다.'
          
        },
        estimation: {
          title: 'Perspective transform',
          description: 'Perspective transform을 이용하여 2D 이미지상의 특정 점을실제세계의 좌표로 변환할 수 있습니다. 이를 위해 카메라의 내부 및 외부 파라미터를 이용하여 카메라 매트릭스를 구성하고, 이 매트릭스를 사용하여 2D 이미지상의 점을 실제 공간상의 점으로 변환합니다'
         
        }
      }
    },

    difference: {
        title: 'Monocular Vision을 이용한 RTLS는 Stereo Vision을 이용한 RTLS와 어떻게 다릅니까 ?',
        description : `Monocular Vision과 Stereo Vision은 기본적으로 다르게 작동합니다. Monocular Vision은 하나의 카메라만 사용하여 객체의 위치를 추적하는 반면, Stereo Vision은 두 개의 카메라가 사용되며, 객체의 위치는 두 카메라에서의 시차 정보를 이용하여 추적합니다.Monocular Vision은 비교적 저렴한 비용으로 구현할 수 있고, 설치 및 유지보수가 간편합니다. 그러나 카메라에서 제공되는 2D 이미지만으로 객체의 위치를 추적하기 때문에 정확도와 신뢰성이 상대적으로 떨어질 수 있습니다.<br/><br/> 반면에 Stereo Vision은 구현의 하드웨어적인 복잡도로 인해 Monocular Vision보다 도입, 설치를 포함한 전체 비용이 높으며, 소프트웨어적인 처리도 더 복잡합니다. 같은 이미지를 처리하더라도 시차를 계산해야 하기 때문에 Stereo Vision RTLS는 Monocular Vision RTLS 보다 더 큰 Computing Resource가 요구됩니다. 따라서, Monocular Vision과 Stereo Vision 중 어떤 기술로 RTLS를 구현할지는 사용하려는 용도와 예산 등을 고려하여 결정할 수 있습니다.`
    },



        
    advantage: {
        title: 'Monocular Vision의 주요 장점',
        items: {
        accuracy: {
            title: '비교적 저렴',
            description: 'Monocular Vision에서는 하나의 카메라만 사용하기 때문에 하드웨어를 구성하는데 드는 비용이 저렴합니다. 또한, 단일 이미지 센서를 사용하기 때문에 소프트웨어적인 처리도 상대적으로 간단하며, 컴퓨팅 파워도 낮아서 저사양의 하드웨어로도 구현이 가능합니다.'
        },
        position: {
            title: '설치의 제약이 적음',
            description: 'Monocular Vision은 카메라를 어디에 설치하든지, 물체의 위치와 움직임을 추적할 수 있습니다. 이는 적용 가능한 분야가 다양하다는 것을 의미합니다. 예를 들어, 실내 위치 추적, 자율 주행 차량의 보행자 감지 및 추적, 스포츠 경기에서의 선수 추적 등 다양한 분야에서 활용될 수 있습니다.'
        },
        security: {
            title: '다양한 응용분야',
            description: ' 이 기술은 하나의 카메라만으로도 객체를 추적할 수 있기 때문에, 설치 및 유지보수가 간단하며 비용이 비교적 저렴합니다. 이로 인해, 일상 생활에서 많이 사용되는 스마트 홈, 로봇, 자동차 등에 적용할 수 있습니다.'
        },
        }
    },
      

    useCase: {
        title: 'Monocular Vision을 이용한 포지셔닝 사용 사례',
        description: 'Monocular Vision의 확장성은 다양한 실내 위치 파악 사용 사례에 효과적인 옵션으로 작용합니다. <br/> 다음은 Monocular Vision 실내 위치 파악 기술이 사용되는 일부 사용 사례 및 응용 분야입니다.',
        card: {
          home: {
            title: '스마트홈',
            description: '스마트홈 기술은 가정 내부의 다양한 환경을 모니터링하고 제어할 수 있는 기능을 제공합니다. Monocular Vision 기술을 사용하면, 사람과 물체의 위치와 움직임을 감지하여 더 효율적인 에너지 사용, 보안 및 편의성을 제공할 수 있습니다.',
            items : ` `
          },
          space: {
            title: '공간 점유율 분석',
            description: '건물 내부나 주차장 등의 공간에서 Occupancy Data를 수집하여 공간의 활용도를 최적화할 수 있습니다. Monocular Vision을 이용한 RTLS 기술은 공간 내부에 설치된 카메라를 통해 공간 내의 사물의 위치 및 움직임을 실시간으로 파악할 수 있습니다. 이를 통해 공간 내부의 사용 상황을 파악하고 공간의 사용 효율을 높일 수 있습니다.',
            items : ` `
          },

          safety: {
            title: '산업 안전',
            description: 'Monocular Vision를 이용한 RTLS 기술은 산업 현장에서 안전을 유지하는 데에도 사용됩니다. 예를들어, 작업장 내에서 사람이나 물건의 위치를 실시간으로 파악할 수 있어, 작업 중 발생할 수 있는 사고 예방에 큰 도움이 됩니다. 특히, 산업 현장에서는 쓰러진 사람이나 작업자가 중대한 상해를 입었을 때 이를 빠르게 감지하여 골든타임을 준수하는 데에 도움이 될 수 있습니다. RTLS 시스템은 산업 현장에서 발생하는 다양한 위험 상황을 미리 감지하여 조기에 대응할 수 있도록 돕습니다. 이를 통해 작업자들의 안전과 건강을 지키고, 생산성과 효율성을 높일 수 있습니다.',
            items : ` `
          },
          analyse: {
            title: '전시 품목 선호도 분석',
            description: 'Monocular Vision 기술은 전시 품목에 대한 고객들의 선호도를 파악하는데 유용하게 사용될 수 있습니다. 카메라를 통해 전시된 품목에 대한 방문자들의 움직임과 관심도를 파악하여, 특정 상품에 대한 관심도가 높은 고객들을 식별하거나, 전시 품목의 위치와이동경로 설계를 최적화할 수 있습니다. 이는 마케팅 분야에서 중요한 역할을 합니다.',
            items : ` `
          },
          sports: {
            title: '스포츠 분석',
            description: 'Monocular Vision 기술을 이용하여, 스포츠 경기에서 선수들의 위치와 움직임을 정확히 추적하고 분석할 수 있습니다. 이를 통해 선수들의 움직임을 개선하거나, 경기 전략을 개발하는 데 활용할 수 있습니다.',
            items : ` `
          },
          security: {
            title: '보안',
            description: ' Monocular Vision을 이용한 RTLS 기술은 보안 측면에서도 다양하게 활용될 수 있습니다. 예를 들어, 사물 또는 인물의 이동 경로를 추적하여 비인가 인원의 접근 제한 시스템, CCTV 모니터링 등에 사용할 수 있습니다. 이러한 기술은 건물 출입구나 주요 시설물의 보안 관리, 군사 산업 등에도 활용될 수 있습니다.',
            items : ` `
          },

          
        }
      }



  }