import { FlexStyle } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT, StyledGridRowBtn } from "@components/Grid/Grid.style";
import { StyledLabel } from "@components/Text";
import styled from "@emotion/styled";


export const StyledNewsLetterBox = styled.div`
  background: #EAF1FD;
  border-radius: 16px;

  min-height: 400px;
  

  padding : 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom : 64px;


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    overflow : hidden;
  }
  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    overflow : hidden;
  }
  

`


export const StyledSubscribeBox = styled.div`
  margin-top : 32px;
  display : flex;
  flex-direction : row;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    flex-direction : column;
    width : 100%;
  }

`


export const StyledDownloadSubmit = styled(FlexStyle)<{bgcolor : string}>`
    margin-left : 8px;
    
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align : center;
    margin-left : 8px;
    padding-top : 16px;
    
    width: 120px;

    border-radius: 8px;
    border : none;
    cursor: pointer;
    ${({bgcolor})=> bgcolor && `background-color : ${bgcolor};`}

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
      margin-top : 16px;
      margin-left : 0px;
       ${({bgcolor})=> bgcolor && `background-color : ${bgcolor};`}
      width : 100%;
      height : 52px;
    }

`;

export const StyledDownloadSubmitText = styled(StyledLabel)`
    width : 100%;
    color: #FFFFFF;
    blackground-color : yellow;

`;



export const StyledDownloadPolicyBox = styled(StyledGridRowBtn)`
    width : auto;
    display : flex;
    margin-top : 32px;

    @media (max-width: 430px) {
      display: flex;
      flex-direction : column;
      gap : 4px;
  }
`;

export const StyledDownloadPolicy = styled(StyledLabel)`
    color : #818181;
    white-space: pre-wrap;
`;

export const StyledDownloadPolicyLink = styled.a`
    white-space: pre-wrap;
`;



export const StyledDownloadPolicyLinkText = styled(StyledLabel)`
    color : #2A70F0;
    white-space: pre-wrap;
`;

