import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledHeading, StyledLabel } from "@components/Text";
import styled from "@emotion/styled";






export const MenuTreeLayout = styled.div`

  position : absolute;
  top : 100%;
  left : 0;
  width : 100%;
  height : auto;
  

  border-bottom : 1px solid rgba(0,0,0,0.08);
  background-color : #FFFFFF;
  z-index : 6;
  
`;

export const MenuContainer = styled(StyledGridContainer)`
  padding : 32px 0px;
  height : 100%;
`;

  export const MenuLayout = styled.div`
  padding : 0 24px;
  display: grid;
  
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
  padding-bottom : 48px;
`;


export const MenuBox = styled.div`

  width : 100%;
  height : 100%;
  display : flex;
  flex-direction : column;
  
`;


export const MenuIcon = styled.img`
  width : 80px;
  height : 60px;
  display : flex;
  object-fit : contain;

`;


export const MenuOS = styled.div`

  width : 100%;
  display : flex;
  flex-direction : column;
  padding-left : 32px;
  border-left : 1px solid rgba(0,0,0,0.08);

`;

export const MenuOSIcon = styled.img`
  width : auto;
  height : 120px;
  display : flex;
  object-fit : contain;

`;

export const MenuHoverHeading = styled(StyledHeading)`
  width : fit-content;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }
`;
export const MenuHoverLabel = styled(StyledLabel)`
  width : fit-content;
  color : #646464;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }
`;