import React from "react";
import { Trans } from "react-i18next";
import { StyledSubListItem, StyledSubListItems, StyledUSbListItemText } from "./SubList.style";
import { StyledGlobalSub } from "./SubListGlobal.style";
import { useLocation, useNavigate } from "react-router-dom";

const SubListGlobal = () => {


  const location = useLocation();
  const navigate = useNavigate();


  const changeLanguage = async (language) => {
    const currentPath = location.pathname;
    let newPath = currentPath;

    if (newPath.startsWith("/en/")) {
      if (language === "ko") {
        newPath = newPath.replace("/en/", "/");
      } else if (language === "jp") {
        newPath = newPath.replace("/en/", "/jp/");
      } else if (language === 'cn') {
        newPath = newPath.replace("/en/", "/cn/");
      } else {
        return;
      }
    } else if (newPath.startsWith("/jp/")) {
      if (language === "ko") {
        newPath = newPath.replace("/jp/", "/");
      } else if (language === "en") {
        newPath = newPath.replace("/jp/", "/en/");
      } else if (language === 'cn') {
        newPath = newPath.replace("/jp/", "/cn/");
      } else {
        return;
      }
    } else if (newPath.startsWith("/cn/")) {
      if (language === "ko") {
        newPath = newPath.replace("/cn/", "/");
      } else if (language === "en") {
        newPath = newPath.replace("/cn/", "/en/");
      } else if (language === 'jp') {
        newPath = newPath.replace("/cn/", "/jp/");
      } else {
        return;
      }
    } else {
      if (language === "en") {
        newPath = newPath.replace("/", "/en/");
      } else if (language === "jp") {
        newPath = newPath.replace("/", "/jp/");
      } else if (language === 'cn') {
        newPath = newPath.replace("/", "/cn/");
      } else {
        return;
      }
    }

    await navigate(newPath);
  };



  return (
    <StyledGlobalSub>
      {/*<StyledSubListTitle size="xsmall"><Trans i18nKey={"title"}/></StyledSubListTitle>*/}

      <StyledSubListItems>

        <StyledSubListItem onClick={() => changeLanguage("ko")}>
          <StyledUSbListItemText size="small" >
            <Trans i18nKey={"navSupport.lang.0.submenus.0.text"} />
          </StyledUSbListItemText>
        </StyledSubListItem>


        <StyledSubListItem onClick={() => changeLanguage("en")}>
          <StyledUSbListItemText size="small" >
            <Trans i18nKey={"navSupport.lang.0.submenus.1.text"} />
          </StyledUSbListItemText>
        </StyledSubListItem>

        <StyledSubListItem onClick={() => changeLanguage("jp")}>
          <StyledUSbListItemText size="small" >
            <Trans i18nKey={"navSupport.lang.0.submenus.2.text"} />
          </StyledUSbListItemText>
        </StyledSubListItem>

        <StyledSubListItem onClick={() => changeLanguage("cn")}>
          <StyledUSbListItemText size="small" >
            <Trans i18nKey={"navSupport.lang.0.submenus.3.text"} />
          </StyledUSbListItemText>
        </StyledSubListItem>

      </StyledSubListItems>
    </StyledGlobalSub>
  );


};

export default SubListGlobal;