import { useTheme } from '@emotion/react';
import React, { FC, useState } from 'react';
import Product from '@pages/Main/Common/Product/Product';
import Spec from '@pages/Main/Common/Spec/Spec';
import Purchase from '@pages/Main/Common/Purchase/Purchase';
import BannerScroll from '@pages/Main/Common/Banner/BannerScroll';
import { useLocation } from 'react-router-dom';
import i18n from '@pages/i18n';
import SEO from '@pages/SEO';
import seoProduct from '@src/lang/ko/seoProduct';
import seoProductEng from '@src/lang/en/seoProduct';
import seoProductJP from '@src/lang/jp/seoProduct';
import seoProductCN from '@src/lang/cn/seoProduct';
import { StyledControlPage } from '@pages/Main/Access/Products/ProductPage.style';


interface ProductProps {
  category: string;

}

const LoRaSensor: FC<ProductProps> = ({ category }) => {

  const initialProduct = "loraSensor";

  
  const [productName, setProduct] = useState<string>(initialProduct);
  const [imgGroupIndex, setImgGroupIndex] = useState<number>(0);


  const location = useLocation();
  const currentLang = i18n.language;

  
  let seoPath;
  
  if(location.pathname.includes('/en/')){
    seoPath = seoProductEng;
  } else if (location.pathname.includes('/jp/')){
    seoPath = seoProductJP;
  } else if (location.pathname.includes('/cn/')){
    seoPath = seoProductCN;
  } else{
    seoPath = seoProduct;
  }



  const helmetTitle = seoPath.loraSensor.title;
  const ogKey = seoPath.loraSensor.key;
  const ogDesc = seoPath.loraSensor.desc;
  const ogImg = seoPath.loraSensor.img;
  const ogUrl = seoPath.loraSensor.url;

  
  const jsonLd = {
    "@context" : "https://schema.org",
    "@type": "Product",
    "name": helmetTitle,
    "description": ogDesc,
    "url":  ogUrl,
    "keywords": ogKey,
    "image": ogImg,
    "brand": {
      "@type": "Brand",
      "name": "ORBRO"
    },
   
    "review": {
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": 4.9,
        "bestRating": 5
      },
      "author": {
        "@type": "Person",
        "name": "ORBRO"
      }
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": 4.9,
      "reviewCount": 99
    },
    "offers": {
      "@type": "AggregateOffer",
      "offerCount": 5,
      "lowPrice": 400000,
      "highPrice": 900000,
      "priceCurrency": "KRW"
    },

  }



  return (
    <>
      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={ogKey}
        ogDesc={ogDesc}
        ogImg={ogImg}
        ogUrl={ogUrl}
        jsonLd={jsonLd}
      />

      <BannerScroll product={'sensor'} selectedGroup={1} />
      <StyledControlPage>

        <Purchase category={category} productName={productName} setProduct={setProduct} setImgGroupIndex={setImgGroupIndex}></Purchase>
        <Product productName={productName} imgGroupIndex={imgGroupIndex} ></Product>
        <Spec productName={productName}></Spec>

      </StyledControlPage>
    </>
  );
};

export default LoRaSensor;
