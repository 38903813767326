export default {
  table: {
    header: {
      name: '',
      uwb: 'UWB',
      ble: 'BLE',
      wifi: 'WI-FI'
    },
    accuracy: {
      name: '精确度',
      uwb: '10 - 50cm',
      ble:`5米以下`,
      WiFi : "不到10米"
    },
    optimumRange: {
      name: "最佳范围",
      uwb: '10 - 50cm',
      ble: '0-25m',
      wifi: '0-50m'
    },
    maximumRange: {
      name : "最大范围",
      uwb: '200m',
      ble: '100m',
      wifi: '500m'
    },
    latency: {
      name : " 延迟时间",
      uwb: '1ms',
      ble:"3~5秒",
      wifi : " 3~5秒"
    },
    power: {
      name: "耗电",
      uwb : "低",
      ble: "非常低",
      wifi : " 一般"
    },
    value: {
      name: '费用',
      uwb: '₩₩',
      ble: '₩₩',
      wifi: '₩₩₩'
    },
    frequency: {
      name: '频率',
      uwb: '3.1~10.6GHz',
      ble: '2.4GHz',
      wifi: '2.4GHz, 5GHz'
    },
    dataRate: {
      name: "速度",
      uwb:"最大27Mbps",
      ble : "最大2Mbps",
      wifi : "最多1GBps"
    },
  },
  request: {
    title: "需要引进解决方案吗？",
    description : "需要引进解决方案和演示吗？<br/>随时联系我，我会尽快联系您。",
    button:"咨询销售组"
  }
}