import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

    helmet : '',
    desc : 'Active RFID 기술 기반 RTLS는 전원이 있는 RFID 태그를 사용하여 더 긴 거리에서 위치를 추적할 수 있으며, 실시간으로 물체나 인원의 위치를 정확하게 파악하여 다양한 산업 분야에서 활용됩니다.',
    keywords : 'Active RFID, Passive RFID, RFID, RTLS, 위치기반, 위치 추적, 실내 위치 추적',

    ogTitle : '',
    ogDesc : '',
    ogSite : 'Active RFID 기술 기반 RTLS :: 오브로 블로그',
    ogUrl : 'https://orbro.io/blog/activerfid',
    ogKey : '',
    ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOaF0taI55etnk-7VQVh_JecDxbhJX529VYv21nToxYe_qXD-w9k29bChDiW0Dj2JxEgZAtoeKjP5UhTJ0zB6tPZT6tGj5rygnex9J0bJt-k9_HhdDZPSetk87I3E-uDLFHG4xQF9ZgyEozwxxPAL_R0RPL-WNwKtkmGk1-d2GauxlTr2d4hGLWvllQl3_usiu46eWZqw76MEhqjCOs1HfYNn6nZM1_tRdsFDF2YeHeVM_nxLUKocvLFuA1xOhxMMkn2O2EW51rmnrEX66iYcxSgcpQix_5nMEcjBMJRqsrg2ULZuUeQ4R_-ji2u-RIAmKIt45RH7RVpxVW5jORDikiW-LbfzIQeDxdAPu8fq3k5o1IQ15lDVU7_nAae5wo1RxF_2JoYHgTqK1D5f6Zpt9eKA3OZReeim-MmTDklv6WC4CRnzWF1YS6efSa5G_vOTnieHKYY_QQ_w6mQlR-Lzzik_xNgPachPN-w_ls-iM2a-AtLnitE0S5O2xXPUlzsm_NyBM3NaA7BueOWETvdm64Es_4LjIsbnlQ-A_MIMbKZjiL7lIfWXUtNbYkpFryMZzUFeHXV7j72VtEmvkbmnMeGI120Co5kuvlpsFxOfmcuQTgwQPbRgsgFIWBkAnt9vla-Y4F3szREBBYSt44cgGIqknvJEv_7llpLaRXYYL0v1mKTnDRjXVKmGHugaxJNamcxLIHjGAgw_PfhHxMTYYeHs216wRGREOhMRsYnaIoi9httnwkNN911s1nK48m8YaTploLUIbMNoGasxH9yXlqXAcZUvDzbzAw4-bEzD4WBeX0pNQe9TuF3QnV2GlnklwVCLrWL8O4jE6jpOvW7KYMKIdAGPTkpHGBsViFHHX6nWFYHtsfaFKj8hjAUJmLrR_To65RSXQRHqfPye2voD_IDE6XhqnFK5Zi9gcsBgwZo7KQuihZcE_ctUyQOfFYvDJA8NILsJsvoGZQXQjPJvIaYmV9LfETM1MMCSL4AC4Vp4ZqQi7q6Fr1cvv1zrmYChkRYtxC00M5Eo2qg2-Qmb8SiANdUYeHxsSOLl5ybYsXBTbEUI9wY0jvT646VkLtHUf-AR9QdUnMqwj2YROz8lgVDZgZroHzXpzDUJaVt1BieJuuk5OLawO2eOrDN7VRU9i35NYRxx_eMOjeVvXJNJ7QxDISJgoCj_iLnilmShn8QmQfU5zINgqzKac6Ru0cJvScz5k_IQxEx_L9LXfqjaljUjhkI65UDijCW-u328M_DEEGeW9sYavREHwtpdY97oScs7VaBcxyOF2fi6XvP_5Gwig4GyydfN67d_49MmeMMSXVrJajT9i93QKvePas21Tiu8VaLtdSYFnuj_Vy53U1EJihuJXEQG4DoTm_I6UsnaCxf4Ymi0Asp-m2oZlcoywdlRKdj9W8x5uVqd2baDzd4mjNO_CBNMFTMmvGAMOS3XOi3jQYZ0a7tkbrcy1DSNhwv_Ds19DpQrDM5faV6GSaRtRfu-8hgESZSlBC_P3fJ9QPREO5n0TspEozjIrZbHoyhmP7yQB58K-7V3bpk-fKuaw3ruD64ZeIeFyOiJwJDig=w2059-h1216',
    ogType : 'website',

    twitterCard : '',
    twitterTitle : '',
    twitterDesc : '',
  
    
    title: 'Active RFID 기술 기반 RTLS',
    date : '2023-05-15',
    tag1 : '#RFID',
    tag2 : '#RTLS',
    tag3 : '#위치추적',
    tag4 : '#실내추적',
    
    // <br/>
    text : [
      {
        head : 'Active RFID는 무엇입니까?',
        subHead :'',
        desc : 'Active RFID는 장치 사이의 정보를 송신하는 무선 통신 형태입니다. Active RFID 태그는 전원 소스를 가지고 있으며, 이는 긴 거리를 통해 신호를 전송할 수 있습니다. 특히 실시간 추적 자산을 추적하기 위해 이상적인 제품입니다. <br/> Active RFID 태그는 몇백 ｍ 까지 읽을 수 있기 때문에, 대규모 자산 추적 솔루션에 이상적일 수 있습니다.',
        dot : ''
      },
      {
        head : 'Active RFID vs Passive RFID',
        subHead :'',
        desc : 'Active RFID 태그는 내장 송신기와 전원 소스를 가지고 있으므로 외부 판독이 필요 없는 신호를 내보낼 수 있습니다. Passive RFID 태그보다 훨씬 비싸지만, 더 넓은 범위를 갖습니다. Active 태그는 일반적으로 창고 관리와 같은 넓은 범위를 추적할 때 사용됩니다. <br/><br/>Passive RFID 태그는 전원 소스를 가지고 있지 않습니다. 그들은 신호를 활성화하고 전송하기 위해 RFID 판독기에서 방출되는 전력에 의존합니다. 따라서 Passive RFID 태그는 Active RFID 태그보다 훨씬 저렴하지만, 읽기 범위가 종종 몇 미터에 불과하다는 것을 의미하기도 합니다. 패시브 RFID 태그는 일반적으로 소매 재고 관리와 같이 짧은 읽기 범위로 충분한 애플리케이션에 사용됩니다.',
        dot : ''
      },
      {
        head : '요즘은 어떤 RFID가 사용되나요?',
        subHead :'',
        desc : 'Active RFID 태그에는 비콘 태그와 트랜스폰더 태그의 두 가지 주요 유형이 있습니다. 비콘 태그는 정보를 주기적으로 Broadcast하며, 범위 내의 Active RFID 리더가 이 정보를 수신할 수 있습니다. 트랜스폰더 태그는 Active RFID 판독기로부터 특정 쿼리를 수신할 때만 통신합니다. 이를 통해 각 태그는 판독기에서 정보를 쿼리할 때만 정보를 전송하기 때문에 더욱 표적화된 자산을 추적할 수 있습니다.',
        dot : ''
      },
      {
        head : 'Active RFID 기술을 RTLS 솔루션과 결합할 수 있습니까?',
        subHead :'',
        desc : 'RTLS(실시간 위치 시스템)는 기본적으로 일종의 Active RFID입니다. RTLS는 시설 전체에 배치된 수신기가 수신할 수 있는 신호를 방출하는 Active RFID 태그를 통해 가능합니다. 이를 통해 태그가 지정된 자산을 거의 즉각적으로 추적할 수 있으므로 자산의 행방에 대한 가치 있고 포괄적인 정보를 제공할 수 있습니다. ',
        dot : ''
      },
      {
        head : 'RTLS 솔루션의 장점',
        subHead :'<br/>정확도',
        desc : 'Active RFID 태그는 종종 몇 cm까지의 높은 수준의 추적 정확도로 위치할 수 있습니다. 따라서 의료 및 제조와 같이 정확한 위치 정보가 중요한 애플리케이션에 적합한 Active RTLS를 제공합니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'실시간 데이터',
        desc : 'Active RTLS 시스템은 태그가 지정된 개체의 실시간 위치 데이터를 제공하므로 자산의 위치를 항상 확인할 수 있습니다. 이는 소매 및 물류와 같이 시간이 중요한 애플리케이션에 유용합니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'유연한 확장성',
        desc : '사람, 팔레트 및 컨테이너를 포함한 모든 유형의 자산을 추적하도록 활성 RTLS 시스템을 구성할 수 있습니다. GPS 신호가 약하거나 존재하지 않는 실내에서도 자산을 추적할 수 있습니다. 태그와 수신기 사이에 시선이 필요하지 않으므로 고밀도 환경에서 자산을 추적하는 데 이상적입니다. 이러한 기능은 활성 RTLS를 광범위한 애플리케이션에 적합하게 만듭니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'유효범위',
        desc : 'Active RFID 태그의 범위는 최대 100m이므로 넓은 지역에 걸쳐 분산된 자산을 추적하는 데 이상적입니다. 신호를 장거리로 전송할 수 있으므로 차량과 같은 대규모 자산을 추적하는 데 이상적입니다. 이 정보는 자산 관리 및 보안을 개선하고 인벤토리 관리와 같은 작업을 자동화하는 데 사용될 수 있습니다.',
        dot : ''
      },
      {
        head : '<br/>Active RTLS 기반 자산 추적 시스템의 실용적 활용',
        subHead :'',
        desc : 'Active RTLS 시스템은 다음과 같은 다양한 애플리케이션에 사용될 수 있습니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'의료',
        desc : '의료를 위한 Active RTLS 시스템은 병원 내 환자, 직원 및 장비의 위치를 추적하는 데 사용됩니다. 이 정보는 환자 관리 및 안전을 개선하고 병원 자원을 보다 효과적으로 관리하는 데 사용될 수 있습니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'제조업',
        desc : '제조를 위한 Active RTLS 시스템은 공장에서 원료, 완제품 및 생산 장비의 위치를 추적하는 데 사용됩니다. 이 정보는 제조 효율성과 품질 관리를 개선하는 데 도움이 될 수 있습니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'소매',
        desc : 'Active RTLS 시스템은 소매점에서 재고 위치를 추적하는 데 사용됩니다. 이 정보는 고객 서비스를 개선할 수 있습니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'도매',
        desc : 'Active RTLS 시스템은 창고 및 물류 센터에서 팔레트, 컨테이너 및 기타 자산의 위치를 추적하는 데 사용됩니다. 이 정보는 배송 정확도와 효율성을 향상시키는 데 사용될 수 있습니다.',
        dot : ''
      },
      {
        head : '',
        subHead :'',
        desc : '<br/>RTLS 기반 자산 추적 시스템의 실용성과 유연성을 고려하면 비용 효율적인 시스템으로 분류할 수 있습니다. 이러한 RTLS 시스템의 비용이 지난 몇 년 동안 꾸준히 하락하고 있다는 점도 눈여겨 볼 만한 점입니다.',
        dot : ''
      },
    ],
  
  
    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      
      solution : [
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/enterprise/construction/asset'
        }, 
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/enterprise/construction/safety'
        },
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/enterprise/logistics/asset'
        }
      ]
  
    }
  
  }