import { Vertical } from '@assets/styled/flex';
import styled from '@emotion/styled';


export const StyledGlobalSub = styled(Vertical)`

  width: 154px;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 0 12px 0 ${({ theme }) => theme.BORDER_TRANSLUCENT};
  border: solid 1px ${({ theme }) => theme.BORDER_TRANSLUCENT};
  background-color: #fff;
  position: absolute;
  top: calc(100% + 4px);
  left: 50%;
  transform: translate(-50%);

  & * {
    font-family: Pretendard !important;
    font-display: swap;
  }
`;