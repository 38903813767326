
import { CommonState } from "@redux/reducers/commonReducer";
import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  StyledBlog, StyledBlogCard, StyledBlogTextBox,
  StyledBlogImg, StyledBlogRow
} from "./BlogRecent.style";

import { IconCalender } from "@assets/images/icon";
import blog from "@src/lang/ko/blog/blog";
import * as banner from "../Common/BlogBannerImg";
import i18n from "@pages/i18n";
import { DisplayH3 } from "@components/Text/HeadingTagDisplay.style";
import { DivAllCenter, DivFlexRow, DivPadding } from "@pages/CommonStyle/commonDiv.style";
import { ImgWH, LabelColor, Spacing } from "@pages/CommonStyle/common.style";
import { StyledHeading } from "@components/Text";
import { StyledGridColumnBlog } from "../Main/BlogModule.style";

interface ProductCard {
  name: string;
  date: string;
  label: string;
  path: string;
}

const canlender = IconCalender


const BlogRecent = () => {

  // Redux
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  // Memos
  const productList: ProductCard[] = useMemo(() => blog.blogList, []);
  const currentLang = i18n.language;

  return (
    <StyledBlog>
      <StyledGridColumnBlog desktop={12} tablet={8} mobile={4}>

        <DivAllCenter>
          <DisplayH3 size="small">
            <Trans i18nKey={"blog.recent"} />
          </DisplayH3>
        </DivAllCenter>

        <StyledBlogRow>
          {productList.map((blog, index) => {
            if (index <= 2) {
              return (
                <StyledBlogCard
                  key={`blog-list-item-${index}`}
                >
                  <Link to={currentLang !== 'ko' ? `/${currentLang}${blog.path}` : `${blog.path}`}>
                    <StyledBlogImg src={(banner[blog.name][layoutType])} alt="ORBRO Technology Blog" />

                    <StyledBlogTextBox>

                      <Spacing pixel="24"/> 
                      <DivFlexRow>
                        <ImgWH src={canlender} alt="Calender Icon" w={"16"} h={"16"} />
                        <DivPadding a={"0"} b={"0"} c={"0"} d={"6"}/>
                        <LabelColor size="small" dangerouslySetInnerHTML={{ __html: blog.date }} color={"#818181"} />
                      </DivFlexRow>

                      <Spacing pixel="8"/> 
                      <StyledHeading size="xsmall">
                        <p>
                          <Trans i18nKey={`blog.blogList.${index}.label`} />
                        </p>
                      </StyledHeading>

                    </StyledBlogTextBox>
                  </Link>

                </StyledBlogCard>
              )
            }
          })}
        </StyledBlogRow>


      </StyledGridColumnBlog>

    </StyledBlog>
  )
};

export default BlogRecent; 