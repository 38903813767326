import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import digitaltwin from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

export default {


    helmet: '| コンテナトラッキング',
    desc: 'コンテナ追跡ソリューションはRFID、GPS、またはRTLSなどの技術を使用してコンテナの位置と状態をリアルタイムで追跡し、監視します。これにより、物流および海運業界でコンテナの輸送および保管プロセスを最適化し、効率を向上させるのに役立ちます。',
    keywords: '港、港、コンテナ、コンテナトレーシング、貨物トレーシング、港湾管理、デジタルツイン、位置追跡、リアルタイム位置追跡、RTLS、Orbro、オブロ、コンテック',
    ogSite: 'コンテナトレーシング | デジタルツイン専門プラットフォーム、Orbro',
    ogUrl: 'https://orbro.io/jp/blog/container-tracking',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkZ9sm3FiFSvojymITtsaWizbn8oKQcMwa4MM-G4G-nMKWBXq2F_q-sH0ACGnraTS707A9X3ogEPQhu0wxDNF0RP9I7pkAp7v0fdzFU2A7zFZtXef3IJ8c3TnYnagQc060ySKipQrcBuqB5tFq82ljVxI4ocqiownoure2nWdl7EzNUSFEQTe4NwflqjUms4AQTYBfwiwLp_4T-VPmoBsby9wKS0Lg20NkhGMM5-AKMQmD08MhEjPcjrWnYRjuhhHDJGB7yjVjJJFaC_8Hhzk-ZRhfMFoN0Werqf5YPXyy9n3DKLQuJv4nD4zYfYswWZj57am6eEuIWYdT6NU5VcdfGPuikrcaFNLVqonZVSVokqkKDjLIfFOLZ_jEHpWaiUpzJ4N2AwMlHjbypjZYkkcWPFuZRiOBOSnzAHvamvwRM9-ag0utSPD9dB9kqXM0gG9iIDVJJfeHxii387jBr7qtgEhEzpInbsnTxby1QQ4qGR91AptBAs-efDpG5dpw5aLNanaEtUnjmHLb2Kdx2BptiikLaz60I7qbKj7yt-eOYq030IZRzypf44xRyI-iAv0WxNE_El-NzX1Xl3T7aRWL_uiBNv-RML1K9gcbC5oEs2G9jGWd7uCAO4XzOtfu7tMNk8ENa9Tv6VxD9AqTdSu2HCUXxnqni5WajMTCT0r-C9pXr0DY7boiz-aBXmITz77v3-VtVXpMpHAiJnjaaQQWtsxCsp3JO643BiMaXQ69jdqcWU_tSVlUMBjVx3MwtdBkfpkH1UlOqrYL8lSfAryg4rOuYRlq0Wgd_HYQaWjg6ASY6GsYPQ_3jsk1c16AxmcmFKaYOvB95Una7UyNMZ2E1L0J2E9UDw5_NT0chrXIjUSCfCNoO3_z3t7PNeoNWyGhS5fkcxZ4iZEMJoSHPjO7_Ycj8fGf_TH8iAFx5kzutepCAa0OU4wVEZMJ7w26XLzJjNJY6Sv4e8ZtuB94rz3bII-npzvEPbPLe3-5_ZvinvmuEyUx8MBkei6-GyebkWDRO9A8GSt5cXN5ysTiKCLCgDz-2g_ZnlRafFzaYJM8zCrHpbL2doyBDLbLTr0XLnk_c4aKGCAMlWxt3na5UYPiexaCSHUgbRzHuZQDUNe6QzEVzuZ-ISeaiBOcDzj25ejL36EoeLeVduxzuvIs8XFa8rIO6sQOK0zSwNcAyeohI0waUzbyHJgEhNXe-vz0Ilc_AChIthH4taUvqT5x62hnV41jq-07G--rjyOeS2pG6PdQMkRqfteroupWK_rO_COOQCb_vscco04P8Jla0Lm7NfxsHyo6chs1rki0f_bjkv1NpW2QgqoX0-igIPd6ByzZOXoWVLlNXWkPkTOQKC3gdh8NRAep9vGbGAvQfRfyKFO5adEA0J-MyTdJPuq8gCH3oPCC3CLntoNARciwS31xLFRD-bF3x5iAb8rcK1VMSj4FKB6aTbMmF4J8ENEDRZ2axEdqIuW7mxt1PQl5aRzqvwPeG0rphIczuLwknwPZ-acGokkMnL7bY9ak1NZdEq4H0LeggE-5ryhwpPzWBQofP7HvD5zQx=w2010-h1214',
    ogType: 'website',
    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',
    title: 'コンテナトラッキング',
    date: '2024-01-09',
    tag1: '#コンテナトラッキング',
    tag2: '#貨物トラッキング',
    tag3: '#物流トラッキング',
    tag4: '#スマートロジスティクス',


    text: [
        {
            head: 'コンテナトラッキング',
            subHead: '',
            desc: 'コンテナは世界中で商品を安全に輸送するための重要な要素です。しかし、コンテナの位置と状態を正確に追跡することはまだ高度な課題です。特に今のような不安定な国際状況は、関連する産業に不安定性と不確実性をもたらしています。コンテナの到着地と出発地をリアルタイムで把握し、移動中の問題を事前に検知することは物流業界において非常に重要な課題です。',
            dot: ''
        },
        {
            head: 'コンテナトラッキングの方法',
            subHead: 'RTLSテクノロジーの適用',
            desc: 'RTLS（Real-Time Location System）は、コンテナの正確な位置を追跡するために優れた性能を発揮します。GPSは屋外での位置追跡に優れていますが、室内環境や複雑な都市エリアでは精度が低下する問題があります。RTLSはGPSの限界を克服し、センサーネットワーク、RFID、超音波、無線通信技術を適切に組み合わせて、建物内でもコンテナの位置を正確に追跡できます。<br/><br/>さらに、RTLSテクノロジーは既存の物流インフラとスムーズに統合できます。既存の倉庫自動化システム、輸送管理ソフトウェア、注文処理システムと連携して、コンテナの位置情報をリアルタイムで共有し、自動化されたプロセスに迅速に適応できます。これにより、物流および輸送管理の効率が向上し、人力と時間を節約できます。',
            dot: ''
        },
        {
            video: digitaltwin,
            link: 'https://orbro.io/jp/enterprise/logistics/asset',
            videoDesc: '(Orbroの物流センターデジタルツイン)',
            head: 'コンテナのセキュリティ強化',
            subHead: '盗難および損傷の防止',
            desc: 'RTLSを活用したセキュリティの強化は、コンテナの位置をリアルタイムで把握し、盗難および損傷を予防するのに役立ちます。特に移動中のコンテナの位置を正確に把握することで、盗難のリスクを最小限に抑えることができます。また、特定の地域で予期せぬ動きが検出された場合、迅速に対応できるため、セキュリティレベルを向上させます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'アイドル時のセキュリティ',
            desc: 'コンテナがアイドル状態の間に位置を継続的にモニタリングすることで、セキュリティに対する備えが可能になります。アイドル時のセキュリティ強化は、物流システムが非アクティブな間でも安全性を維持できるようにします。これにより、コンテナのセキュリティが向上し、物流ネットワーク全体の安定性が向上します。',
            dot: ''
        },
        {
            head: 'リアルタイムコンテナトラッキングの利点',
            subHead: '物流プロセスの最適化',
            desc: 'RTLSを通じたリアルタイムコンテナトラッキングは、物流プロセスの最適化に重要な役割を果たします。コンテナの位置をリアルタイムで追跡することにより、物流マネージャーは各段階での効率を向上させることができます。出発地、中間経由地、到着地での物流経路をリアルタイムで調整し、輸送時間を短縮し、コストを最小限に抑えることができます。',
            dot: ''
        },
        {
            head: '',
            subHead: '予測分析と例外処理',
            desc: 'リアルタイム情報提供は、予測分析に基づく戦略の立案と例外処理にも役立ちます。データを分析して移動経路で発生する可能性がある問題を事前に予測し、物流ネットワークの効率を最大化するための改善点を見つけることができます。また、リアルタイムで状況を検知し、例外的な状況に迅速に対応することで、問題発生時間を最小限に抑えます。',
            dot: ''
        },
        {
            head: '効率的なリソース管理',
            subHead: '輸送経路の最適化',
            desc: 'RTLSを活用した効率的なリソース管理は、輸送経路の最適化を通じて実現されます。コンテナのリアルタイム位置情報を利用して、特定の地域でよく使用されるコンテナを識別し、これを利用して最適な輸送経路を計画します。これにより燃料消費を減少させ、輸送コストを削減することができます。',
            dot: ''
        },
        {
            head: '',
            subHead: '物流資産の最適配置',
            desc: 'RTLSは倉庫内での物流資産管理を最適化するためにも活用されます。センサーテクノロジーを使用して倉庫内の物流資産をリアルタイムで追跡し、特定の商品や機器が必要なエリアに効率的に配置できます。これにより物流スペースの最大活用と作業効率の向上が図られます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'コスト削減とリソースの最適化',
            desc: 'RTLSを通じたリアルタイムコンテナトラッキングは、輸送および物流管理でコストを効果的に削減し、リソースを最適化するのに役立ちます。正確な位置情報をもとに輸送経路を最適化し、物流資産を効率的に配置することで、燃料費用を減少させ、アイドルリソースを最小限に抑えることができます。',
            dot: ''
        },
        {
            head: 'コンテナトラッキングの将来展望',
            subHead: 'AIおよび機械学習の統合',
            desc: '将来的には人工知能（AI）および機械学習との統合がさらに強化されることが予想されます。RTLSテクノロジーは多くのデータを生成し、AIを活用してこのデータを分析することで予測モデルを構築できます。これにより予測できない問題を事前に予測し、物流プロセスをさらに微調整することが可能になります。',
            dot: ''
        },
        {
            head: '',
            subHead: 'エコシステムの拡大',
            desc: 'コンテナトラッキングは現在の物流産業だけでなく、製造、医療、小売り、エネルギーなどのさまざまな産業でも利用されることが予想されます。この拡大は物流エコシステムをさらに統合し、協力の機会を拡大することが期待されます。<br/><br/>コンテナトラッキング技術は持続的な革新を通じて産業全体に肯定的な影響を与え、物流およびサプライチェーン管理で効率性と安定性を向上させる重要な役割を果たすことでしょう。',
            dot: ''
        },
        {
            head: '結論',
            subHead: '',
            desc: 'リアルタイム情報に基づく予測分析と例外処理は、物流マネージャーに迅速な対応の機会を提供し、物流プロセスの継続的な改善を促進します。さらに、輸送経路と物流資産の最適化はコスト削減と物流ネットワークの効率向上に寄与します。<br/><br/>持続的な技術革新とRTLS（Real-Time Locating System）技術の拡大利用が将来の物流業界を牽引し、効率向上と競争力の向上に寄与することが期待されます。さらに、RTLSの適用範囲がさまざまな産業に広がるにつれ、より革新的で持続可能な物流およびサプライチェーンシステムが発展する見込みです。',
            dot: ''
        }
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'この製品はUWB端末を作業者や機器に取り付け、リアルタイムの位置追跡を支援します。',
                img: productImg.product.workerTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/jp/enterprise/construction/safety'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/jp/enterprise/logistics/asset'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/jp/enterprise/manufacturing/asset'
            }
        ]

    }
}
