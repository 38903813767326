import { StyledGridContainer } from "@components/Grid/Grid.style";
import { ImgWH, LabelColor, SpacingDTM, SpanColor } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import React from "react";
import { PatentBox, PatentLayout } from "./Patent.style";
import digitaltwin from "@src/lang/ko/digitaltwin/digitaltwin";
import { Trans } from "react-i18next";


const Patent = () => {
  return (
    <>
      <StyledGridContainer>
        <DivAllCenter>
          <PatentLayout>
            <PatentBox>
              <ImgWH src={digitaltwin.patent.card[0].img} w={"120"} h={"120"} alt="Personal Location Information Provider Certification Icon"/>
              <LabelColor size="medium" color="#fff">
                <span>
                  <Trans i18nKey={`digitaltwin.patent.card.${0}.title`} />
                </span>
                <SpanColor color="#BDBDBD">
                  <Trans i18nKey={`digitaltwin.patent.card.${0}.desc`} />
                </SpanColor>
              </LabelColor>
            </PatentBox>
            <PatentBox>
              <ImgWH src={digitaltwin.patent.card[1].img} w={"120"} h={"120"} alt="Radio Frequency Location Tracking U.S. Patent Icon"/>
              <LabelColor size="medium" color="#fff">
                <p>
                  <span>
                    <Trans i18nKey={`digitaltwin.patent.card.${1}.title`} />
                  </span>
                  {`\n`}
                  <SpanColor color="#BDBDBD">
                    <Trans i18nKey={`digitaltwin.patent.card.${1}.desc`} />
                  </SpanColor>
                </p>
              </LabelColor>
            </PatentBox>
          </PatentLayout>
        </DivAllCenter>
      </StyledGridContainer>

      <SpacingDTM d={"96"} t={"64"} m={"48"} />
    </>
  )
}

export default Patent;