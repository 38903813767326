import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconPenSquare, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem3 } from "@assets/images/solution";
import video from "@src/assets/images/solution/retailExhibition/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/retailExhibition/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/retailExhibition/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/retailExhibition/video/sub/rtlsVideo3.mp4";



export default {

    banner: {
        title: '展览馆观众管理与分析',
        desc: '准确客观的数据可以帮助新业务增长。<br/>通过最小化浪费的人力和成本，提供智能展览馆形象，实现差异化运营。',

    },

    video: {
        title: '增加观众的智能方法。',
        desc: '实时定位(RTLS)技术可以实时通知观众位置。<br/>根据准确客观的访客数据，可以将展览馆打造成<strong>人们更喜欢的展览馆</strong>。',
        play: video
    },

    func: [
        {
            title: '实时客流把握',
            desc: '实时把握客流可以有效制定展览馆的布局和展品陈列等运营计划。',
            sub1: '实时状态把握',
            icon1: IconClockGray,
            sub2: '快速数据交互',
            icon2: IconShareGray,
            video: subVideo1

        },
        {
            title: '观众分析',
            desc: '可以分析观众进入展览馆后的行动轨迹。同时了解受欢迎的作品，为展览馆运营提供有意义的指标。',
            sub1: '提供统计数据',
            icon1: IconChartBarGray,
            sub2: '高精度提供',
            icon2: IconRulerGray,
            video: subVideo2
        },
        {
            title: '展品安全管理',
            desc: '实时监控展品的破损和失窃。从而能够更快地进行预防性措施。',
            sub1: '高精度提供',
            icon1: IconRulerGray,
            sub2: '快速数据交互',
            icon2: IconChainLinkGray,
            video: subVideo3
        },


    ],



    subfunc: {

        title: '多种功能',
        desc: '通过传统方法很难从观众中获得有意义的数据。通过Orbro的新功能，可以提高展览馆的运营效率并实现业务增长。',

        data: [
            {
                icon: IconChartRadius,
                title: '报告与分析',
                desc: '基于展品使用记录生成报告和分析资料。',
            },
            {
                icon: IconBell,
                title: '实时警报功能',
                desc: '当展览馆发生异常情况时，发送警报，可以立即应对。',
            },
            {
                icon: IconRoute,
                title: '按日期查询客流动线',
                desc: '可以按日期查询客流的行动轨迹。',
            },
            {
                icon: IconDashboard,
                title: '状态了解',
                desc: '实时了解观众的当前位置。',
            },
            {
                icon: IconWarning,
                title: '离开监测',
                desc: '监测观众是否接近禁止区域。',
            },
            {
                icon: IconPenSquare,
                title: '工作人员指示',
                desc: '基于展览馆情况，可以向工作人员下达下一步指示。',
            },
            {
                icon: IconChainLink,
                title: '数据联动',
                desc: '与RFID、物联网、安全、视频等系统连接，实现高效利用。',
            },
            {
                icon: IconSecurity,
                title: '数据安全',
                desc: '保持高数据安全水平，提供可信赖的解决方案。',
            },


        ],
    },

    system: {
        title: '系统构成',
        img: rtlsSystem3
    },

    productTitle: '产品配置',
    product: [

        {
            tag: 'peoplePackage',
        },
        {
            tag: 'assetPackage'
        },
        {
            tag: 'workerPackage'
        },
    ],


    contents: [
        {
            data: 'manufacturingAsset'
        },
        {
            data: 'logisticsSafety'
        },
        {
            data: 'blogMedical'
        },
        {
            data: 'blogRTLS'
        },

    ]

}

