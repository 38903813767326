import { TechUseCaseIcon3D, TechUseCaseIconBriefcase, TechUseCaseIconBuilding, TechUseCaseIconLocation, TechUseCaseIconMap, TechUseCaseIconUsers } from "@assets/images/technology/common";
import { StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import UseCaseCard from "@pages/Enterprise/Technology/Common/UseCaseCard/UseCaseCard";
import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import ble from "@src/lang/ko/technology/ble";
import uwb from "@src/lang/ko/technology/uwb";
import stereo from "@src/lang/ko/technology/stereo";
import monocular from "@src/lang/ko/technology/monocular";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";
import { StyledParagraph } from "@components/Text";
import { Spacing } from "@pages/CommonStyle/common.style";
import { StyledUseCase } from "../../StereoVision/UseCase/UseCase.style";

type TechnologyType = 'ble' | 'uwb' | 'stereovision' | 'monocularvision'

interface UseCaseProps {
  type: TechnologyType
}

const useCaseIcons = [
  TechUseCaseIcon3D,
  TechUseCaseIconUsers,
  TechUseCaseIconLocation,
  TechUseCaseIconMap,
  TechUseCaseIconBriefcase,
  TechUseCaseIconBuilding
];

const UseCase = (props: UseCaseProps) => {
  // Props
  const { type } = props;


  const tag = useMemo(() => {
    switch (type) {
      case 'ble':
        return ble.useCase;
      case 'uwb':
        return uwb.useCase;
      case 'stereovision':
        return stereo.useCase;
      case 'monocularvision':
        return monocular.useCase;

    }
  }, [type]);


  const cards = useMemo(() => tag.card, []);



  return (
    <StyledUseCase>
      <StyledGridContainer>
        <DisplayH2 size="small"><Trans i18nKey={`${type}.useCase.title`} /></DisplayH2>
        <Spacing pixel="16"/>
        <StyledParagraph size="medium">
          <p>
            <Trans i18nKey={`${type}.useCase.description`} />
          </p>
        </StyledParagraph>

        <Spacing pixel="64"/>
        <StyledGridRow>
          {Object.keys(cards).map((key, index) => (
            <UseCaseCard
              key={`${type}-use-case-${key}`}
              icon={useCaseIcons[index]}
              title={`${type}.useCase.card.${key}.title`}
              description={`${type}.useCase.card.${key}.description`}
              items={type}
              data={key}
            />
          ))}
        </StyledGridRow>
      </StyledGridContainer>
    </StyledUseCase>
  )
};

export default UseCase;