import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: 'ORBRO通过提供飞机的实时追踪和运营信息，提高了航空机动性，并确保旅行和运营的安全。通过提供航空公司信息、确认飞行路线、验证机场时间表、管理紧急情况、提升客户服务以及监测环境影响等服务，改善了航空旅行体验。',
  keywords: 'Digital Twin, Digital Twins, 数字双胞胎, gis, 数字城市, RTLS, 位置跟踪系统, 位置跟踪技术, 体育, 制造, 物流, 医疗, 医院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - 连接飞机信息 :: ORBRO博客',
  ogUrl: 'https://orbro.io/cn/blog/digitaltwin-airplane',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twin - 连接飞机信息',
  date: '2023-12-26',
  tag1: '#DigitalTwin',
  tag2: '#Aircraft',
  tag3: '#DigitalCity',
  tag4: '#VirtualReality',

  text: [
    {
      head: 'Digital Twin - 连接飞机信息',
      subHead: '',
      desc : `ORBRO通过飞机的实时跟踪和运营信息提高了航空机动性，并通过提供航空公司信息、监控飞行路线、检查每个机场的时刻表、紧急情况管理、客户服务改进和环境影响监测来确保旅行和运营的安全性。提供航空公司信息为乘客提供了计划航空旅行所需的基本信息，以便帮助便利的旅行。飞行路线监控通过确保航空交通管理和安全性来支持顺畅的运营。为每个机场提供时刻表可以通过提供准确可靠的旅行信息，有效地管理旅行行程。<br/><br/>紧急情况管理优先考虑乘客和员工的安全，以应对意外情况。客户服务的改进通过提高乘客的响应和服务质量来提高满意度。环境影响监测通过制定可持续的航空运营战略，履行环境责任并追求可持续性。ORBRO通过这些多样化的功能支持安全便利的航空旅行，并为可持续运营做出贡献。`,
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/w1pKhaleOH4?si=BNCBDwA5fepB03Y3',
      head: '',
      subHead: '提供航空公司信息',
      desc: 'ORBRO实时提供每个航空公司的信息，包括飞机类型、航线和时间表等。这些信息对计划空中旅行的乘客至关重要，同时为航空公司和机场运营商提供了运营和管理飞机所需的重要数据。',
      dot: ''
    },
    {
      head: '',
      subHead: '飞行路线监控',
      desc: '实时监控和显示飞机路线在确保空中交通管理和航空安全方面起着至关重要的作用。',
    },
    {
      head: '',
      subHead: '提供机场时间表信息',
      desc: '提供包括到达和出发时间表、登机口信息等在内的每个机场的航空时间表信息，提高了旅行者的旅行信息准确性，并增强了机场运营效率。',
    },
    {
      head: '',
      subHead: '紧急情况管理',
      desc: '利用实时航空数据，可以迅速响应和管理紧急情况，确保乘客和工作人员的安全，最小化潜在风险。',
    },
    {
      head: '',
      subHead: '提升客户服务',
      desc: '利用实时航空信息促进乘客响应和服务改进，提高乘客满意度，增强航空旅行体验。',
    },
    {
      head: '',
      subHead: '环境影响监测',
      desc: '监测与飞机运营相关的环境影响，并制定可持续的航空运营战略，对于减少碳排放和环境保护工作至关重要。',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '通过将UWB终端附着到工作者或设备上，实现实时位置追踪的产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]
  }
}
