import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: '都市の交通状況をリアルタイムでモニタリングし、交通管理と対応を強化します。交通情報のCCTV連動、オブジェクトへの映像情報の組み込み、大規模映像情報の統合、事故分析と対策の開発、交通法令の遵守監視、効果的な交通管理戦略の策定などを通じて、交通システムを効果的に管理します。',
  keywords: 'Digital Twin, Digital Twins, デジタルツイン, gis, デジタルシティ, RTLS, 位置追跡システム, 位置追跡技術, スポーツ, 製造, ロジスティクス, 医療, 病院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'デジタルツイン - 交通CCTV接続 :: ORBRO ブログ',
  ogUrl: 'https://orbro.io/jp/blog/digitaltwin-cctv',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'デジタルツイン - 交通CCTV接続',
  date: '2023-12-26',
  tag1: '#デジタルツイン',
  tag2: '#CCTV',
  tag3: '#デジタルシティ',
  tag4: '#バーチャルリアリティ',

  text: [
    {
      head: 'デジタルツイン - 交通CCTV接続',
      subHead: '',
      desc: 'ORBROは交通状況をリアルタイムでモニタリングし、交通管理と対応を向上させるサービスを提供しています。交通情報のCCTVとの連動、オブジェクトに映像情報を含め、大規模な映像情報の統合、事故分析と対策の開発、交通法規の遵守監視、効果的な交通管理戦略の策定を通じて、交通システムを効果的に管理しています。<br/><br/>これを実現するために、CCTVとの連動では交通フローをリアルタイムで観察し、オブジェクトに映像情報を含めることで交通管理と安全性を強化します。大規模な映像情報の統合は広範な地域の交通状況を正確に把握し、事故分析と対策の開発は道路安全性を向上させます。交通法規の遵守監視は交通安全を確保し、効果的な交通管理戦略は交通渋滞を減少させます。ORBROはこれらの手段を通じて道路および交通環境を効果的に管理し、安全かつ効率的な交通システムを維持しています。',
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/iOZVM1YT_pE?si=MkzatuD-2E4xbzkQ',
      head: '',
      subHead: '交通情報CCTV連動',
      desc: 'ORBROは道路や交通状況をリアルタイムでモニタリングするために、CCTV映像をリアルタイムで連動させます。これにより、交通管理者は交通流をリアルタイムで観察し、交通事故や渋滞などの状況に迅速に対応できます。',
      dot: ''
    },
    {
      head: '',
      subHead: 'オブジェクトへの映像情報の組み込み',
      desc: 'CCTV映像内の車両や歩行者などのオブジェクトを識別し、追加の情報を提供します。これは交通管理と安全性の強化に寄与し、交通事故の予防や調査に重要な役割を果たします。',
    },
    {
      head: '',
      subHead: '大規模映像情報の統合',
      desc: '複数のCCTVソースからの映像情報を統合することで、広範な地域の交通状況を包括的に把握できます。これは都市の交通管理と計画に重要なデータを提供します。',
    },
    {
      head: '',
      subHead: '事故分析と対策の開発',
      desc: 'CCTVデータを活用して交通事故の原因を分析し、事故を減少させるための対策を開発します。これにより道路安全性が向上し、事故発生率が低下します。',
    },
    {
      head: '',
      subHead: '交通法令の遵守監視',
      desc: '交通法令の遵守を監視し、違反者を特定して法令の遵守を促進します。これにより交通安全が確保され、法令を遵守する文化が奨励されます。',
    },
    {
      head: '',
      subHead: '効果的な交通管理戦略の策定',
      desc: '収集されたデータを分析して交通流を改善し、効果的な交通管理戦略を策定します。これにより交通渋滞が軽減され、市民の移動の便利さが向上します。',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を作業者や機器に取り付け、リアルタイムで位置を追跡する製品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }
    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/jp/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/jp/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      }
    ]
  }
}
