import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const FontPage = styled.div`

    & * {
    font-family: Pretendard !important;
    font-display: swap;
  } 
`;

export const DivWH = styled.div<{ w: string, h: string }>`
    ${({ w, h }) => w && h && `width : ${w}px; height : ${h}px;`}
`;

export const DivFlexColumn = styled.div`
    width : 100%;
    display : flex;
    flex-direction:column; 
`;

export const DivFlexRow = styled.div`
    width : 100%;
    display : flex;
    flex-direction : row;
`;

export const DivFlexWrap = styled.div<{gap : string}>`
  width : 100%;
  display : flex;
  flex-wrap : wrap;
  ${({ gap }) => gap && `gap : ${gap}px;`}
`;


export const DivFlexRowGap = styled.div<{ gap: number }>`
    width : 100%;
    display : flex;
    flex-direction : row;
    justify-content : center;
    align-items : center;
    ${({ gap }) => gap && `gap : ${gap}px;`}
`;


export const DivJustify = styled.div`
    display : flex;
    justify-content : center;
    width : 100%;
`;


export const DivAllCenter = styled.div`
    width: 100%;
    display : flex;
    flex-direction : column;
    text-align : center;
    align-items : center;
`;

export const DivWidth100 = styled.div`
    width : 100%;
`;

export const DivHeight = styled.div<{ h: string }>`
    ${({ h }) => h && `height : ${h}px;`}
`;

export const Div100flex = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
`;

export const DivBorderRaiuds = styled.div<{ pixel: string }>`
    ${({ pixel }) => pixel && `border-radius : ${pixel}px;`}
`;

export const DivPadding = styled.div<{ a: string, b: string, c: string, d: string }>`
    ${({ a, b, c, d }) => a && b && c && d && `padding : ${a}px ${b}px ${c}px ${d}px;`}
`;

export const DivF8F8F8 = styled.div`
    width : 100%;
    background-color : #F8F8F8;
`;

export const DivGrid = styled.div<{ cnt: number, gap: number }>`
  display : grid;
  ${({ cnt, gap }) => cnt && gap && `grid-template-columns : repeat(${cnt} , 1fr); gap : ${gap}px;`}

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    display : flex;
    flex-direction : column;
  }
`;


export const DivGridGap = styled.div<{ cnt: number, c_gap: number, r_gap: number }>`
  display : grid;
  ${({ cnt, c_gap, r_gap }) => cnt && c_gap && r_gap &&
        `grid-template-columns : repeat(${cnt} , 1fr); 
  column-gap : ${c_gap}px; 
  row-gap : ${r_gap}px;`}

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    display : flex;
    flex-direction : column;
  }
`;


export const DivStickyTop = styled.div<{ top: number }>`
  position : sticky;
  ${({ top }) => top && `top : ${top}px;`}
`;