import React from "react";
import TechRequest from "../Common/TechRequest/TechRequest";
import TitleBanner from "../Common/TitleBanner/TitleBanner";
import { StyledTechnologyContentBottom0 } from "../Technology.style";
import TDoAContent from "./components/TDoAContent/TDoAContent";
import { useTranslation } from "react-i18next";
import i18n from "@pages/i18n";
import SEO from "@pages/SEO";
import CommonLang from "@pages/CommonStyle/commonLang";
import { FontPage } from "@pages/CommonStyle/commonDiv.style";
import { SpacingDTM } from "@pages/CommonStyle/common.style";
const TDoAPage = () => {

  const { t } = useTranslation();

  CommonLang();

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "headline": "TDoA 위치 추적 기술",
    "datePublished": "2023-04-01T08:00:00+08:00",
    "dateModified": "2023-04-01T08:00:00+08:00",
    "author": [{
      "@type": "Organization",
      "name": "ORBRO",
      "url": "https://orbro.io"
    }]
  }

  const helmetTitle = t(`seo.tech.tdoa.title`);
  const key = t(`seo.tech.tdoa.key`);
  const desc = t(`seo.tech.tdoa.desc`);
  const currentLang = i18n.language;
  let isKorean;
  if (currentLang === 'ko') {
    isKorean = true;
  } else {
    isKorean = false;
  }

  return (
    <>

      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={key}
        ogDesc={desc}
        ogImg="https://orbro.io/images/07c59e6e2321dfd9cf2a60c7442e50de.png"
        ogUrl={isKorean ? "https://orbro.io/enterprise/technology/tdoa" : `https://orbro.io/${currentLang}/enterprise/technology/tdoa`}
        jsonLd={jsonLd}
      />

      <SpacingDTM d={"64"} t={"48"} m={"32"} />
      <FontPage>
        <TitleBanner
          subtitle={"tdoa.titleBanner.subtitle"}
          title={"tdoa.titleBanner.title"}
          description={"tdoa.titleBanner.description"}
        />

        <StyledTechnologyContentBottom0>
          <TDoAContent />
        </StyledTechnologyContentBottom0>

        <TechRequest />
      </FontPage>
    </>

  );
};

export default TDoAPage;