import { IconBlueCircle, IconBlueCycle, IconBlueDevelop, IconBlueFigure, IconBluePlus, IconBluePower, IconBlueScan, IconBlueShield } from "@assets/images/main/icon/blue";
import { osBanner, osLogoDesktop, osLogoMobile, osLogoTablet } from "@assets/images/main/os/bannerImg";
import { OsSectionDesktop1, OsSectionTablet1, OsSectionMobile1,
  OsSectionDesktop2, OsSectionTablet2, OsSectionMobile2,
  OsSectionDesktop3, OsSectionTablet3, OsSectionMobile3,
  OsSectionDesktop4, OsSectionTablet4, OsSectionMobile4,
  OsSectionDesktop5, OsSectionTablet5, OsSectionMobile5,
  OsSectionDesktop6, OsSectionTablet6, OsSectionMobile6,
  OsSectionDesktop7, OsSectionTablet7, OsSectionMobile7,
  OsSectionDesktop8, OsSectionTablet8, OsSectionMobile8, 
  OsSectionDesktop9, OsSectionTablet9, OsSectionMobile9, 
  OsSectionDesktop10, OsSectionTablet10, OsSectionMobile10, 
  OsSectionDesktop11, OsSectionTablet11, OsSectionMobile11, 
  OsSectionDesktop12, OsSectionTablet12, OsSectionMobile12, 
  OsSectionDesktop13, OsSectionTablet13, OsSectionMobile13, 
  OsSectionDesktop14, OsSectionTablet14, OsSectionMobile14, 
  OsSectionDesktop15, OsSectionTablet15, OsSectionMobile15, 
  OsSectionDesktop16, OsSectionTablet16, OsSectionMobile16, 
  OsSectionDesktop17, OsSectionTablet17, OsSectionMobile17,
  OsTextImg1, OsTextImg2, OsTextImg3, OsTextImg4, 
} from "@assets/images/main/os/section";


export default {

  title: 'デジタルツインスペース管理プラットフォーム',
  osLogo: {
    desktop: osLogoDesktop,
    tablet: osLogoTablet,
    mobile: osLogoMobile
  },

  bannerImg: osBanner,

  
  section1: {
    img: OsTextImg1,
    title: '現実のようにスペースを管理する、スペース管理',
    desc: '実際のスペースと同じ仮想のスペースに特定のエリアを設定できます。<br/>ピープルカウント、危険区域、逆走検知エリアを設定して管理します。',
    box: [
      {
        size: 1,
        title: 'シンプルなゾーン設定',
        desc: '管理したいエリアを簡単に設定し、<br/>追跡したいデータを取得できます。',
        img: {
          desktop: OsSectionDesktop1,
          tablet: OsSectionTablet1,
          mobile: OsSectionMobile1,
        },
      },
      {
        size: 2,
        title: '危険区域設定と警告',
        desc: '危険区域を設定し、不正な者がアクセスした場合にアラートを送信します。',
        img: {
          desktop: OsSectionDesktop2,
          tablet: OsSectionTablet2,
          mobile: OsSectionMobile2,
        },
      },
      {
        size: 2,
        title: 'ピープルカウンティング',
        desc: '特定のエリアに人や資産の出入りデータを抽出できます。',
        img: {
          desktop: OsSectionDesktop3,
          tablet: OsSectionTablet3,
          mobile: OsSectionMobile3,
        },
      },
      {
        size: 2,
        title: '逆走検知',
        desc: '特定のエリアに一方向を指定し、その方向と逆方向に移動するオブジェクトや物体を検出し、アラートを送信します。',
        img: {
          desktop: OsSectionDesktop4,
          tablet: OsSectionTablet4,
          mobile: OsSectionMobile4,
        },
      },
      {
        size: 2,
        title: '時間帯別流入追跡',
        desc: '設定したエリアに入るオブジェクトや人などのデータを時間帯別に保存し、データを提供します。',
        img: {
          desktop: OsSectionDesktop5,
          tablet: OsSectionTablet5,
          mobile: OsSectionMobile5,
        },
      },
    ]
  },
  
  section2: {
    img: OsTextImg2,
    title: 'リアルタイムデータ管理',
    desc: '実際のスペースで起こることを仮想のスペースでリアルタイムに把握します。<br/>必要な特定のデータは検索して確認するか、受け取って使用できます。',
    box: [
      {
        size: 2,
        title: 'リアルタイム位置追跡',
        desc: '接続されたオブロデバイスからリアルタイムデータを収集し、分析して、迅速な状況把握と意思決定を支援します。',
        img: {
          desktop: OsSectionDesktop6,
          tablet: OsSectionTablet6,
          mobile: OsSectionMobile6,
        },
      },
      {
        size: 2,
        title: 'データ収集',
        desc: 'インストールされたデバイス、IoTデバイスを含むデバイスによって収集されたデータをリアルタイムでモニターできます。',
        img: {
          desktop: OsSectionDesktop7,
          tablet: OsSectionTablet7,
          mobile: OsSectionMobile7,
        },
      },
      {
        size: 2,
        title: 'デバイス検索',
        desc: '資産や人などのオブジェクトの位置をリアルタイムで検索して把握します。',
        img: {
          desktop: OsSectionDesktop8,
          tablet: OsSectionTablet8,
          mobile: OsSectionMobile8,
        },
      },
      {
        size: 2,
        title: 'データダウンロード',
        desc: 'リアルタイムデータ分析により早期に異常を検知し、早期対応のためにアラートを送信します。',
        img: {
          desktop: OsSectionDesktop9,
          tablet: OsSectionTablet9,
          mobile: OsSectionMobile9,
        },
      },
    ]
  },
  
  section3: {
    img: OsTextImg3,
    title: '便利な管理機能',
    desc: '実際のスペースで起こることを仮想のスペースでリアルタイムに把握します。<br/>必要な特定のデータは検索して確認するか、受け取って使用できます。',
    box: [
      {
        size: 2,
        title: '状況に応じたアルゴリズム適用',
        desc: 'さまざまなスペースに適したオブロアルゴリズムを変更して、最適なデータを取得できます。',
        img: {
          desktop: OsSectionDesktop10,
          tablet: OsSectionTablet10,
          mobile: OsSectionMobile10,
        },
      },
      {
        size: 2,
        title: 'デバイス管理',
        desc: '効率的にIoTデバイスを管理できる柔軟な構造を持ち、新しいデバイスを追加したり、既存のシステムをアップグレードしたりするのが簡単です。',
        img: {
          desktop: OsSectionDesktop11,
          tablet: OsSectionTablet11,
          mobile: OsSectionMobile11,
        },
      },
      {
        size: 2,
        title: 'カスタムダッシュボード',
        desc: 'カスタムダッシュボードは、ユーザーに重要なデータを簡単にモニターし、効果的な決定を行うための洞察を提供します。',
        img: {
          desktop: OsSectionDesktop12,
          tablet: OsSectionTablet12,
          mobile: OsSectionMobile12,
        },
      },
      {
        size: 2,
        title: 'モバイルウィジェット提供',
        desc: 'ウィジェットの提供を通じて、ユーザーはアプリを起動せずにさまざまなデータを便利に確認し、アプリに迅速にアクセスできます。',
        img: {
          desktop: OsSectionDesktop13,
          tablet: OsSectionTablet13,
          mobile: OsSectionMobile13,
        },
      },
    ]
  },
  
  section4: {
    img: OsTextImg4,
    title: '統合されたOSの1つのシステム',
    desc: 'Orbro OSは強力で便利ですが、複雑ではありません。<br/>どの製品でも簡単に設定して使用できます。',
    box: [
      {
        link: '/rtls',
        size: 2,
        title: '位置追跡システム',
        desc: 'UWB、BLE、ビーコンなどの製品をOrbro OSに登録できます。',
        img: {
          desktop: OsSectionDesktop14,
          tablet: OsSectionTablet14,
          mobile: OsSectionMobile14,
        },
      },
      {
        link: '/sensor/LoRaGateway',
        size: 2,
        title: '産業用IoT',
        desc: 'リアルタイムデータ分析により早期に異常を検知し、早期対応のためにアラートを送信します。',
        img: {
          desktop: OsSectionDesktop15,
          tablet: OsSectionTablet15,
          mobile: OsSectionMobile15,
        },
      },
      {
        link: '/access',
        size: 2,
        title: '出入りシステム',
        desc: 'Orbroアクセスシステムを登録し、出入りデータを収集します。',
        img: {
          desktop: OsSectionDesktop16,
          tablet: OsSectionTablet16,
          mobile: OsSectionMobile16,
        },
      },
      {
        link: '/camera',
        size: 2,
        title: 'AIカメラ位置追跡',
        desc: '実際のスペースに設置されたカメラと仮想の地図を簡単にマッピングして設定できます。',
        img: {
          desktop: OsSectionDesktop17,
          tablet: OsSectionTablet17,
          mobile: OsSectionMobile17,
        },
      },
    ]
  },

  

  function: {
    title: 'その他の機能',
    box: [
      {
        icon: IconBlueScan,
        title: 'リアルタイムデータ分析と可視化',
        desc: 'OSは収集されたデータを分析し、可視化して、ユーザーが収集された情報をリアルタイムに理解し、活用できるようにします。',
      },
      {
        icon: IconBlueShield,
        title: 'セキュリティと個人データ保護',
        desc: '強力なセキュリティ機能を提供して、デジタルツインを保護します。機密情報へのアクセスを制限し、未承認のデータ変更を防止してセキュリティ問題を最小限に抑えます。',
      },
      {
        icon: IconBlueDevelop,
        title: '開発者向け',
        desc: '開発者がデジタルツイン機能を簡単に活用および拡張できるように設計されています。強力なAPIおよび開発ツールを提供し、新しいアプリケーションやサービスを開発できます。',
      },
      {
        icon: IconBlueFigure,
        title: 'AIの統合',
        desc: '人工知能（AI）技術を統合して、デジタルツインをよりスマートに管理および最適化します。AIアルゴリズムを使用してデータを分析し、予測モデルを構築してシステムのパフォーマンスを向上させます。',
      },
      {
        icon: IconBluePower,
        title: '環境モニタリングとスマートエネルギー管理',
        desc: '環境モニタリングセンサーを統合して、エネルギー消費量とリソース利用を最適化します。環境状況をリアルタイムで監視し、必要に応じてシステムを自動調整してエネルギー効率を最大化します。',
      },
      {
        icon: IconBlueCycle,
        title: '自動化とスマート制御',
        desc: '収集された情報に基づいて、デジタルツインを使用して自動化された決定を行います。例えば、特定の条件が満たされると、デバイスを自動制御したり、プロセスを実行したりできます。',
      },
      {
        icon: IconBluePlus,
        title: 'カスタマイズ可能なユーザーインターフェース',
        desc: 'ユーザーが必要に応じてインターフェースをカスタマイズできる機能を提供します。ユーザーは、デジタルツインを監視および制御するためのダッシュボードやアプリケーションを自由に構成できます。',
      },
      {
        icon: IconBlueCircle,
        title: 'エコシステムの拡張性',
        desc: '開発者や企業がエコシステムを拡張するために、アドオンやプラグインを開発できるようにします。これにより、さまざまな産業やアプリケーション領域にカスタマイズされたソリューションを提供できます。',
      },
    ],
  }
}