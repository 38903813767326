import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4';

export default {

    desc: '智能造船厂是指利用人工智能（AI）、物联网（IoT）、实时定位追踪（RTLS）、数字孪生等先进技术，构建更高效、更智能的生产系统。',
    keywords: '智能造船厂、人工智能、物联网、RTLS、数字孪生、信标、UWB、BLE、Orbro、KONTECH',

    ogSite: '智能造船厂解决方案 | 数字孪生平台、Orbro',
    ogUrl: 'https://orbro.io/cn/blog/smart-shipyard',
    ogImg: 'https://lh3.google.com/u/0/d/1WdXSNBhEft64iXMMY189UfAb4GJnSnFp=w2000-h7622-iv1',
    ogType: 'website',

    title: '智能造船厂解决方案 | 数字孪生平台、Orbro',
    date: '2024-05-09',
    tag1: '#智能造船厂',
    tag2: '#造船厂',
    tag3: '#港口工业',
    tag4: '#数字孪生',

    // <br/>
    text: [
        {
            head: '智能造船厂是什么？',
            subHead: '',
            desc: '智能造船厂是指利用人工智能（AI）、物联网（IoT）、实时定位追踪（RTLS）、数字孪生等先进技术，构建更高效、更智能的生产系统。智能造船厂通过数字化和自动化生产过程，强化工人安全，提高生产效率，同时改善能源效率，建立可持续的海洋产业。',
            dot: ''
        },
        {
            head: '主要技术',
            subHead: '实时定位追踪（RTLS）',
            desc: '跟踪工人和资产的实时位置，提高生产过程的透明度和效率。该技术提供每秒更新的准确位置信息，优化工作计划，增强安全性。',
            dot: ''
        },
        {
            head: '',
            subHead: '数字孪生',
            desc: '将造船厂的所有要素与现实世界和数字世界相连接，创建虚拟模型。通过这种方式，可以模拟和优化实际过程，并提高从设计阶段到生产、维护的所有阶段的生产效率和效率。',
            dot: ''
        },
        {
            head: '',
            subHead: '大数据和人工智能（AI）的应用',
            desc: '收集和分析造船厂产生的大量数据，实现生产过程的优化和预测性维护。通过人工智能发现数据模式，并以此支持预测和决策，提高生产率，降低成本。',
            dot: ''
        },
        {
            head: '',
            subHead: '物联网（IoT）技术的引入',
            desc: '连接船舶和造船厂内部各种设备和传感器，收集和监控实时数据，优化生产过程。通过实时了解设备状态并进行预防性维护，提高生产率。',
            dot: ''
        },
        {
            videoDesc : '(例如：Orbro智能制造设施)',
            video : video,
            link : '/cn/enterprise/manufacturing/process',
            head: '主要功能',
            subHead: '实时工人位置追踪',
            desc: '跟踪工人的实时位置，优化工作计划，保持工作环境的安全性。通过实时监控工人位置，预防危险情况，最大化工作效率。',
            dot: ''
        },
        {
            head: '',
            subHead: '实时资产管理',
            desc: '监控造船厂内资产的实时位置和状态，提高库存管理和工作效率。防止库存不足或过度采购等问题，优化资产利用率。',
            dot: ''
        },
        {
            head: '',
            subHead: '环境管理',
            desc: '监测环境因素，预防造船厂内的环境污染，保持生产过程的可持续性。实时监测大气、水质、噪音等环境因素，并采取措施改善生产环境。',
            dot: ''
        },
        {
            head: '',
            subHead: '危险区域管理',
            desc: '识别并监控造船厂内的危险区域，确保工人安全，并将可能发生的事故最小化。识别危险区域，向工人发出警告，确保工作环境安全。',
            dot: ''
        },
        {
            head: '',
            subHead: '能源效率管理',
            desc: '引入智能能源系统，优化港口的能源使用，实现环保运营。',
            dot: ''
        },
        {
            head: '未来展望',
            subHead: '人工智能（AI）和自动驾驶技术的扩展',
            desc: '预计未来，人工智能技术将进一步发展，船舶自动驾驶技术将普及。这将提高造船厂的运营效率，并降低生产过程中的人力成本。',
            dot: ''
        },
        {
            head: '',
            subHead: '物联网（IoT）技术的应用',
            desc: '预计物联网技术将在港口运营和管理中发挥更加重要的作用。通过船舶和其他设施上安装的传感器收集和分析数据，提高港口运营效率。',
            dot: ''
        },
        {
            head: '',
            subHead: '环境友好技术的强调',
            desc: '为了实现可持续的海洋产业，将强调提高能源效率和环境保护的技术。这些努力将提高海洋生态系统的保护水平，并增加港口运营的可持续性。',
            dot: ''
        },
        {
            head: '',
            subHead: '全球市场的增长',
            desc: '预计全球港口和造船业将继续增长。特别是随着新港口建设和扩建项目的增加，亚太地区的增长预计将增加，这将对韩国的智能造船厂产业产生积极影响。',
            dot: ''
        },
        {
            head: '结论',
            subHead: '',
            desc: '智能造船厂是未来可持续海洋产业的必要组成部分。通过引入先进技术和创新方法，可以提高生产率、效率和安全性，同时实现环境友好型生产方式。这将推动造船业迅速发展，并实现未来可持续增长的目标。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: '将UWB终端安装在工人或设备上，支持实时位置跟踪的产品。',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/cn/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/cn/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/cn/enterprise/construction/equipment'
            }
        ]


    }


}
