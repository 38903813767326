import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet: '| How to Manage Worker Safety in Smart Logistics Facilities',
  desc: 'By using RTLS technology to track the real-time location of workers, it is possible to prevent safety accidents and improve the productivity and efficiency of logistics facilities.',
  keywords: 'Logistics worker safety, Logistics workers, Worker safety management, Logistics center management, Smart logistics center, Logistics facility safety, Real-time location tracking, RTLS, UWB, Orbro, ConTech, Large logistics center',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'How to Manage Worker Safety in Smart Logistics Facilities :: Orbro Blog',
  ogUrl: 'https://orbro.io/en/blog/logisticsWorker',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4NdDe6oJsqBton9AjaPbL4Q5NxAVY66XFiGJrNW6Ib8-vKVwi61wEFWFuSoe0IzzjXlQbOtwFN55XZKQq8-merMyuu_Kc-aCU9rXsAxqlu4jJu1W48RqVrOHIVfqqqsE6lyVOnqs0-udnyCNaiR_P-55-JDEg1q7Xen3jEtC_J7Vb-agtwtcKFwspoLjunKXVBr9DX9XtbURNHamoE8axj_QTsyRGYEjihiSkLTZsKjMAFQ7XEjKC5gypKGi-6h70LVzMWxFYES1IEFrj_g1Xm7X_87ZICuq9UkXYfF5_kSsePap3XCgzaGjXTxtewamebrNTcvtyyOGiDJzCop_9ps9SiDxBVMH5GHlXgzqGmO97RWoXrAzVM73ecqHfZvofOY6_mcID1tD3eGIZBqVBgd7U8JQKxRv5VzLnj2nylAh5JYmN5uKSjOqTtNmcH5ryzlmyojOD2ErsaCkaHV5ExP87eOjZKQy4uKs_zWCdrgvAmWTPvzw69EDOlKv2RoG16hSWS_Ak_ejmhNKHqiapfSKTGHaC-OyuAl909u40AIfMQLdhaDAfq0dLJve-7i1wj3R4CLlyviq_bE_NXXUK5qw3DbmY5cmW6vI1h_bt2hkpZNfi3JDz5QhJ9DAcFdAWrfv2leATnS45LJW4WOW1oXUJDbzkHUZQHW0ZW6wIGYVQ-xlZWJJqhrD_Njlp8FUHTGIx0P4nbQ9gwzfH5XHMncEv6nMznbvF1pSn3RUd018qa27lJlV03sBikmB34XLF4CDCNEO1bQw9jWtbwk_vYV4M8X13pC0IyanCVU2du9J_GkiVXzFXfbzpyqQ9WOMjzjr1fz7PFZ9K4m9oA5JTQT4J3Zdqjx740QlfdBPLL-2yRp1LOhhELK6pOVdTjGXtjX3wv47zRDndoJU1_JRmZ4jZVB7-7HzJYviS1og154IRi0RIUkjfgjfUAA8LCVjWDHS32tJzSqsZXqKTXYRk9Rhi_YcjuhZLNetMYWCuiyoFBsqFmv6ai_Ux9jN66QNYaXnxRFnLddvNA6yApVZwoKPn-IzhYO8Vqv-0kVcG5eU-71tpilnn85vP3jJ6t79hkLY3n-fxyWgmRvnYI29nFIXJVmMfS6WNeeoqxKWJXUKMhbTVHH2pLy3fBnuVsWrrFjp1exyh-ahC1VHmDZbVVT2OhrELUuXeed4tfK3fgFsq066oX6o3XaihsVsvxd_7ohiRB6yCLuR0wSKZfvVqT9HQbGXWjOB6TZtXQpmsDzt_Hm4jCIyzgGJhQL-iMQZzqxalaYhQYVocsX1i6DSrWQD54jIik7h_PJfgK-eulk6EBtGZS3Tzf0Iaureqspuc_I8f4hcXRLWO2U5NnbnPZUmwxp-__Br0RSKSdBAv39o-tNXhLUk0-WF5nNiPAK4XlxF4hKVQl6nVCf5_wzw5t95Acv8QlXvmGuwe9etTZWlibVBH-aqe5_XOETlbldEKqJj_JN2mk1bGd6U2D5jYxlz5mexjaGdd6aWxF48u6hG_15sIUgXGqEHPhXvDJk6bTv_k7wgvoN1ESF_WKxkZuAnBZWa3G7jWW4VT6NK9Q3RxYq3VXTACR2nFRVJQ=w2052-h1199',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'How to Manage Worker Safety in Smart Logistics Facilities',
  date: '2023-09-06',
  tag1: '#LogisticsSafety',
  tag2: '#LargeLogisticsCenter',
  tag3: '#LogisticsCenterWorkers',
  tag4: '#WorkerSafetyManagement',
    
  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: 'Worker safety in logistics facilities is one of the crucial factors in the logistics industry. Even during various operations such as the movement, storage, and shipping of goods, there are risks of accidents, and even minor accidents can immediately impact safety, productivity, and efficiency. However, by using Real-Time Location System (RTLS) technology for real-time location tracking, you can ensure worker safety. Below, we will explore how to manage worker safety and operate a smart logistics facility.',
      dot: ''
    },
    {
      head: 'What is RTLS Technology?',
      subHead: '',
      desc: 'Firstly, RTLS, Real-Time Location System, is a technology that tracks location information in real-time, utilizing various technologies such as GPS, RFID (Radio-Frequency Identification), UWB (Ultra-Wideband), BLE (Bluetooth Low Energy), and more. This allows for precise tracking of the locations of workers within logistics facilities, and this location information can be used to create a safe working environment.',
      dot: ''
    },
    {
      head: 'Key Benefits',
      subHead: '1. Worker Management',
      desc: 'With RTLS technology, you can track the location of workers in real-time. By accurately knowing where workers are in the logistics facility, you can prevent collisions between workers and collisions with heavy equipment, ensuring swift response in case of emergencies. This not only enhances safety but also efficiently allocates workers at the worksite, increasing efficiency.',
      dot: ''
    },
    {
      head: '',
      subHead: '2. Safe Zone Setup',
      desc: 'There may be hazardous areas in logistics facilities that workers should not access. By utilizing RTLS, you can establish safe zones, and if outsiders or unauthorized workers enter these areas, immediate danger alerts can be issued. Conversely, measures can be taken, such as triggering warnings when specific workers leave these areas, helping to prevent accidents in advance.',
      dot: ''
    },
    {
      head: '',
      subHead: '3. Emergency Response',
      desc: 'When a worker faces an emergency situation, RTLS technology allows for rapid location information retrieval. This enables swift rescue and emergency medical assistance, ensuring worker safety.',
      dot: ''
    },
    {
      head: '',
      subHead: '4. Efficiency and Productivity',
      desc: 'Tracking the movement of workers through RTLS not only enhances safety but also optimizes work processes. If combined with asset tracking solutions, you can operate highly advanced logistics facilities.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Conclusion',
      desc: `Worker safety in logistics facilities is of paramount importance in the logistics industry. Utilizing RTLS technology to manage and enhance safety is highly effective. By tracking workers' locations in real-time and creating a safe working environment, you can increase worker safety and contribute to accident prevention. The use of RTLS technology will open up a brighter future for logistics facility operations.`,
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }
    ],
    solution: [
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      },
      {
        img: logisticsSafety.thumb,
        data: 'logisticsSafety',
        link: '/en/enterprise/logistics/safety'
      }, 
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/en/enterprise/manufacturing/asset'
      }
    ]
  }
}
