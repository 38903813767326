export default {


    
    enterprise : 'エンタープライズ',
    supportTitle : 'サポート',
    start : 'はじめる',



        support :{
            technology:`技術`,
            blog : 'ブログ',
            request : '営業問い合わせ',
            company : '会社紹介'

        }


}