import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import digitaltwin from "@src/assets/images/digitaltwin/video/traffic.mp4";

export default {

    helmet: '| スマートシティとは？',
    desc: "スマートシティは、デジタルツイン技術を活用して都市の物理的要素をデジタルでモデリングし、都市のインフラストラクチャとサービスを知的に管理および最適化する概念です。",
    keywords: "スマートシティ、デジタルツイン、デジタルトランスフォーメーション、smart city、digital twin、digital transformation、リアルタイム位置追跡、rtls、orbro、オブロ、コンテック",
      

    ogTitle: '',
    ogDesc: '',
    ogSite: 'スマートシティとは？ :: オブロ ブログ',
    ogUrl: 'https://orbro.io/jp/blog/what-is-smart-city',
    ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDlPQWtEdPqpk5QG-xfimTOJPt6-WAQc9XeRZnvdeRnqh_rxt6qJtKZOqz_FUESiTUuXhz_YtQo1FyKz_g8EERCOUtHYJW3xiXXD2sNkav4DXtc1AZ-gu3i93GEY_jis-cy4O6GH1tGpeZ4nKJVSR86PDIoBBZF_H0P4oJxW8cQnvXQuhCLFts7lyVvPB7kmVKa1OMU-JySPR1iSsikgxErasD7CSbVUFcgQ1ta5zV8ucSdKb-wJcos_kHcj1LwrC_ZcOPRVd1aLU-qqBrA0MQpdzUPwdSap8VNPoTP5KEvrZFDmYEqXdDHW70S-815Duo6uAP5itzyDvT7LMIj6PJsucX39uXQuIgna2GZVvnFvV5Mzn0Ahzj6YGbEc4_-R8dKbmd7l-xQQn97-pBq93VtBRpWnsE8CZtKKzUe5hgvhKVKYxiG-xYBSdR5ofDnF7aW1M1I93_h77nSfLcx1Tt19ZtokM9H-Rd655Ovi9zrvx9HHZbvCCDwce6It0IKoyJahzNaatP9RxcCbIHXKs64i1a78WRZIGcAF54tvf702cITAZp-Z3Jie9aUOtwKlb0_egIbCBZGu5dQ9h7I-nlDcj8nk-6y7h-JOYwYNUsUUW5B-3Vl6Sfu6KR25Oq7YrQo7nAbbRk8cdqfGGyXkcugF1RVkYybD-qq4oTRAbsSaOtltD9IuMouwBm4zeQ9JaL_4i-bpnX2Ag-rFmjOb9ylnE5cwozwljUHSITuoJOTbj9KtFqzMIoHoG9BkgSaLSmTnEhUeScVp3hhhJXkJTM4xvgRTDSboBXdC7C2aFpLaZZ-YqsxRUa5DuIKjdfOFccVaL0YhoSD1GrckiDXmEwZNa8il2qaWLbSFfLYPtF6gjK79kIta-EJplhWiFs4ONxbwmyZIER1m1dLRj5ALQgPqrHSTit4coflnZ--wlKrOhTRcrgiw7IXdbPboS_-3zlQi2dK5Mrxy-cJHOIRWDfvAG0Cjf77xz86tvDC7feQY_926zrWMX8xOGH2mMmzXVjcLzTD45Sw6_cUUsdLakivhUrXMSbtL_Vgg9pCsTx1oLfwtYUF0ugK5dO7BhRojQZoxBIopnkdCm8uq2vmhq94jKesQediuYNGhMGoLE3_a2Bxd48cIhzQgHb2QGvE1eDvT2P4GldVE8p17vZhFvEmv3lXu-7RAKx_PaUzPXRbJ8UUESSX7ifbfg54onDWKtJ7qJ6dkZ2mSlhuKdEe9G9rF0CG8BE1JPI2MeZC4yR6-iQNGwzWrcicyyBTqkotflfSmhEV_Rrqpg1avycEeajNafwvwEUC1yZzShi15JDuVdDGvgVH2hZVKM3pOZ1JxEuwtUbqP-BFkg5aBtbALWMImy4XQYiSkd3EEdQPa02OfyER8CLl0Qy7g6czv2zTAukCrY7PE4v6VAhtPwV6cI7At2KAheOe7VZNPThDj5qCKG3duCLJl4Rbkqyrt0s7WFzlAke5TDxhEE_9ta0JtMzjrPZkQWcACu_7KOu3wXz7cpHrv906MtZzK83YCoVHtfCjmXVjrU0ArV66WqRuUbz9OCBOsngRV=w2152-h1214',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'スマートシティとは？',
    date: '2024-01-08',
    tag1: '#スマートシティ',
    tag2: '#デジタルツイン',
    tag3: '#デジタル変革',
    tag4: '#未来都市',

    // <br/>
    text: [
        {
            head: 'スマートシティとは？',
            subHead: '',
            desc: 'スマートシティは、デジタルツイン技術を活用して都市の物理的要素をデジタルでモデリングし、都市のインフラストラクチャとサービスを知的に管理および最適化する概念です。これは、従来の都市から発生するさまざまな問題に対処し、持続可能で革新的な都市を構築する取り組みの一環として浮上しています。<br/><br/>スマートシティは、さまざまな技術と情報通信技術（ICT）を活用して都市生活のさまざまな側面を向上させることを目指しています。これは、既存の都市の問題を改善し、将来の都市の持続可能性とイノベーションのための新しいパラダイムと見なされています。主要な技術には、Internet of Things（IoT）、ビッグデータ、人工知能（AI）、自律型車両などが含まれます。',
            dot: ''
        },
        {
            head: 'スマートシティの核心技術',
            subHead: '',
            desc: 'スマートシティの実現には、さまざまな先進技術が活用され、これらの技術が統合されて都市の知能化が実現されます。注目される主要な技術は次のとおりです。',
            dot: ''
        },
        {
            head: '',
            subHead: 'Internet of Things（IoT）',
            desc: 'スマートシティの中核要素の一つは、Internet of Things（IoT）技術です。これは、さまざまな物にセンサーを適用し、インターネットを介して接続することで、リアルタイムのデータ収集と共有を可能にします。都市内の建物、交通システム、環境など、さまざまな分野で収集されたデータは中央システムで統合され、都市の状況をリアルタイムで理解するのに役立ちます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'ビッグデータ',
            desc: 'データはスマートシティの中核資源の一つであり、ビッグデータ技術はそれを効果的に活用するために必須です。大量のデータを迅速に収集、保存、分析することで、都市のトレンドやパターンを把握し、予測する能力が得られます。ビッグデータ分析を通じて、都市機能の最適化と問題の早期発見が可能になり、意思決定と計画に大きな助けとなります。',
            dot: ''
        },
        {
            head: '',
            subHead: '人工知能（AI）',
            desc: 'ビッグデータと共に使用される人工知能技術は、スマートシティを知的にするために不可欠な役割を果たします。機械学習やディープラーニングを活用してデータを分析し、都市のシステムを最適化します。AI技術は、交通フローの最適化、エネルギー消費の予測、自動化されたシステムの運営など、さまざまな分野でスマートシティの効率を向上させます。',
            dot: ''
        },
        {
            head: '',
            subHead: '5Gネットワーク',
            desc: 'スマートシティでは、大量のデータを迅速に転送および処理する必要があります。第5世代移動通信技術である5Gは、超高速のデータ転送と低遅延を提供し、スマートシティのさまざまな技術が実際に効果的に動作できるようにサポートします。5Gは、自律走行車、スマートインフラ、産業用ロボットなどの技術を可能にする中核的な通信基盤を提供します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'ブロックチェーン',
            desc: 'スマートシティではデータの安全な保護と共有が重要な問題です。ブロックチェーン技術は分散型データベースを通じてデータの安全性と整合性を確保し、同時に透明かつ安全な取引を可能にします。これにより、スマートシティのさまざまな分野で安全にデータを共有し、活用することができます。',
            dot: ''
        },
        {
            video : digitaltwin,
            link : 'https://orbro.io/jp/digital-twin',
            videoDesc : '(動画をクリックすると、オブロ デジタルツインページに移動します。)',
            head: 'スマートシティの利点',
            subHead: '効率的なリソース管理',
            desc: 'スマートシティは、ビッグデータ、Internet of Things（IoT）、および人工知能（AI）技術を活用してリソースを効率的に管理します。エネルギー、水資源、交通などさまざまなリソースに関するデータ収集と分析を通じて、最適な運用方法を見つけ、エネルギー消費を削減し、リソースの浪費を最小限に抑えます。',
            dot: ''
        },
        {
            head: "",
            subHead: "交通フローの最適化",
            desc: "スマートシティはリアルタイムデータの収集と分析を通じて、交通フローを最適化し、道路の混雑を減少させます。スマートな信号機、自動運転車、公共交通システムの向上などにより、交通効率を向上させ、市民の移動を便利にします。",
            dot: ""
        },
        {
            head: "",
            subHead: "環境の持続可能性の向上",
            desc: "スマートシティはエネルギー効率を向上させ、再生エネルギーを積極的に活用して環境への負担を軽減します。また、空気や水の品質をリアルタイムでモニタリングし、環境問題に早期に対応できるようにします。",
            dot: ""
        },
        {
            head: "",
            subHead: "経済的活性化",
            desc: "スマートシティの実施は新しい技術やサービス産業を促進し、雇用を創出し、経済的な活性化を牽引します。スマートシティプロジェクトにはさまざまな産業分野の企業や専門家が参加し、革新的な技術とビジネスモデルの開発を通じて産業生態系が拡大します。",
            dot: ""
        },
        {
            head: "",
            subHead: "緊急事態への対応能力の向上",
            desc: "スマートシティは自然災害や社会的緊急事態に備えて効果的な緊急対応システムを構築します。リアルタイムデータの収集と分析は災害の予測と管理に役立ち、市民に迅速に安全情報を提供して対応能力を向上させます。",
            dot: ""
        },
        {
            head: "",
            subHead: "都市の競争力強化",
            desc: "スマートシティは現代的で革新的なイメージを構築し、都市の国際的な競争力を向上させます。先進技術と先端インフラはグローバルな企業や才能を引き寄せ、国際的な投資を誘致して都市の経済的な発展を牽引します。",
            dot: ""
        },
        {
            head: "",
            subHead: "持続可能な都市の発展",
            desc: "スマートシティの利点は持続可能な都市の発展に貢献します。環境、経済、社会的な観点から都市を計画し、運営することで、将来の世代のための持続可能な発展が可能になります。",
            dot: ""
        },
        {
            head: "スマートシティの動作原理",
            subHead: "センサーとIoTの活用",
            desc: "スマートシティの中心はセンサーとInternet of Things（IoT）技術の活用です。都市内に設置されたさまざまな場所のセンサーは環境、交通、エネルギーの使用などに関連するデータをリアルタイムで収集します。このデータは都市のさまざまな側面を監視し、問題を早期に検出するために使用されます。",
            dot: ""
        },
        {
            head: "",
            subHead: "持続可能な都市の発展",
            desc: "スマートシティの利点は持続可能な都市の発展に貢献します。環境、経済、社会的な観点から都市を計画し、運営することで、将来の世代のための持続可能な発展が可能になります。",
            dot: ""
        },
        {
            head: "",
            subHead: "人工知能と機械学習の活用",
            desc: "ビッグデータと組み合わせて使用される人工知能（AI）および機械学習技術はデータを分析し、都市のシステムを最適化します。予測分析を通じて交通フローを最適化したり、エネルギー消費を予測して効率的に管理したりできます。 AIは都市で発生する複雑な問題に対する創造的で知的な解決策を見つけ出します。",
            dot: ""
        },
        {
            head: "",
            subHead: "スマートインフラの構築",
            desc: "スマートシティはスマートインフラを構築し、都市のさまざまな部分を結びつけます。スマートグリッドは電力を効率的に管理し、スマートトランスポーテーションシステムは交通フローを最適化し、スマートビルディングはエネルギー使用を最小限に抑えます。これらのスマートインフラは都市のさまざまな側面を統合的に管理し、持続可能な発展を牽引します。",
            dot: ""
        },
        {
            head: "",
            subHead: "IoTと相互接続されたデバイス",
            desc: "IoTを通じて相互接続されたデバイスがスマートシティを構成します。スマートな家電製品、車両、建物などが情報をやり取りし、動作を調整することで、都市のリソースを効率的に活用し、利便性を向上させます。",
            dot: ""
        },
        {
            head: "",
            subHead: "市民参加とデジタルサービスの提供",
            desc: "市民はスマートシティにデジタルサービスを通じて積極的に参加できます。モバイルアプリケーションを介して都市情報にアクセスし、問題を報告し、参加するなどの活動を通じて市民は都市の運営に直接参加できます。",
            dot: ""
        },
        {
            head: "",
            subHead: "知能型都市運営システム",
            desc: "すべてのデータとデバイスが統合された知能型都市運営システムは都市のさまざまな部分を効率的に運営し、最適なサービスを提供します。交通フローの最適化、エネルギー効率の管理、環境モニタリングなどが自動的に行われ、都市を知的に運営します。",
            dot: ""
        },
        {
            head: "",
            subHead: "持続的な改善と評価",
            desc: "スマートシティは持続的な改善と評価を通じて進化します。市民のフィードバックを受け入れ、技術的な革新を導入して都市の要求に応え、持続可能なスマートシティを維持し、発展させます。",
            dot: ""
        },
        {
            head: "スマートシティの展望",
            subHead: "",
            desc: "スマートシティは持続可能な発展と革新的な技術の導入によって、未来の都市の枠組みを形成しています。エネルギー効率と環境保護に焦点を当て、再生可能エネルギーと環境に優しい交通システムの利用がさらに強化されると予想されます。また、ビッグデータ、人工知能、IoTなどの革新的な技術がより積極的に導入され、都市のさまざまな側面を知的に管理し、市民の利便性を向上させることが予測されます。スマートシティは未来の都市の中核的な特徴として、持続的な発展と技術革新を通じて都市の競争力を強化すると予想されます。",
            dot: ""
        },
        {
            linkText: "Orbro Worldを体験する",
            link: "https://home.orbro.io",
            head: "",
            subHead: "",
            desc: "",
            dot: ""
        }
        

    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
          {
            text: productPurchase.purchase.asset.assetTracking.title,
            title: productPurchase.purchase.asset.assetTracking.title,
            serial: productPurchase.purchase.asset.assetTracking.serial,
            desc: 'この製品は、作業者や設備にUWB端末を取り付け、リアルタイムの位置追跡をサポートします。',
            img: productImg.product.assetTracking.key1
          },
          {
    
          }
    
        ],
        solution: [
          {
            img: logisticsAsset.thumb,
            data: 'logisticsAsset',
            link: '/jp/enterprise/logistics/asset'
          },
          {
            img: logisticsSafety.thumb,
            data: 'logisticsSafety',
            link: '/jp/enterprise/logistics/safety'
          },
          {
            img: manufacturingAsset.thumb,
            data: 'manufacturingAsset',
            link: '/jp/enterprise/manufacturing/asset'
          }
        ]
    
      }
}
