import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet: '',
  desc: 'ORBROは、気象モニタリングと高度なシミュレーションを通じて気候変動の影響を理解し、適応するために必要な気象分析を提供します。夜と昼の気象環境、気候および降水量、海洋気象データ、世界の気象情報、大気汚染モニタリング、災害予測、警告システムを介して広範な情報を提供します。これは気候研究、農業計画、水資源管理に不可欠な情報です。気象衛星データ、日照時間、太陽位置情報は昼と夜の気象環境をリアルタイムでシミュレーションし、高解像度気象モデルと降水データは気象条件をシミュレーションします。海洋の降雨、世界の気象情報統合、大気汚染モニタリング、災害予測、警告システムは海洋学、気候研究、公衆衛生、環境保護にとって重要です。',
  keywords: 'Digital Twin, Digital Twins, デジタルツイン, gis, デジタルシティ, RTLS, 位置追跡システム, 位置追跡技術, スポーツ, 製造, ロジスティクス, 医療, 病院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'デジタルツイン - 大気と気象情報の実装 :: ORBROブログ',
  ogUrl: 'https://orbro.io/jp/blog/digitaltwin-weather',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',
  
  title: 'デジタルツイン - 大気と気象情報の実装',
  date: '2023-12-26',
  tag1: '#デジタルツイン',
  tag2: '#気象情報',
  tag3: '#デジタルシティ',
  tag4: '#バーチャルリアリティ',
  
  // <br/>
  text: [
    {
      head: 'デジタルツイン - 大気と気象情報の実装',
      subHead: '',
      desc: 'ORBROはデジタルツインを活用して大気および気象情報を視覚化し、気候変動に対する理解と適応をサポートします。気象モニタリングと先進的なシミュレーションにより気候分析を提供し、さまざまな分野で幅広く活用されています。<br/><br/>昼と夜の気象環境、気候環境、降水量、海洋気象データ、世界の気象情報、大気汚染モニタリング、災害予測および警告システムは、気候研究、農業計画、水資源管理などに必要な情報を提供します。<br/><br/>天候衛星データ、日照時間、太陽位置情報を活用して昼と夜の気象環境をリアルタイムでシミュレーションし、高解像度の気象モデルと降水データを使用して気象条件をシミュレーションします。海洋での降水量、世界の気象情報統合、大気汚染モニタリング、災害予測および警告システムは、海洋学、気候研究、公共衛生、環境保護において重要な役割を果たしています。',
      dot: ''
      
    },
    {
      youtube: 'https://www.youtube.com/embed/QB2sM1OdrXA?si=X3po5Cxz2Hu3QlGA',
      head: '',
      subHead: '夜と昼の気象環境の表現',
      desc: 'ORBROは気象衛星データ、日照時間、太陽位置情報を使用して夜と昼の気象環境をリアルタイムでシミュレーションします。これは気候研究、災害管理、農業計画、エネルギー管理に重要なデータを提供します。',
      dot: ''
    },
    {
      head: '',
      subHead: '気候および降水量の表現',
      desc: '高解像度気象モデルと降水データを使用してさまざまな気候条件をリアルタイムでシミュレーションします。これは気候研究、農業計画、水資源管理に必要な重要なデータを提供します。',
      dot: ''
    },
    {
      head: '',
      subHead: '海洋での降雨の表現',
      desc: '衛星ベースの海洋気象データと海洋モデルを使用して海洋域での降雨現象を視覚化します。この情報は海洋学、気候研究、海洋輸送、安全管理に必要な重要な情報を提供します。',
    },
    {
      head: '',
      subHead: '世界の気象情報の統合',
      desc: '世界の気象観測所と衛星データを統合して広範な気象情報を収集し、分析します。これはグローバルな気候変動のモニタリングと国際的な災害対応に不可欠な役割を果たします。',
    },
    {
      head: '',
      subHead: '大気汚染モニタリング',
      desc: 'ORBROは大気の質と汚染レベルを追跡し、予測して公共衛生と環境保護政策をサポートします。これは大気の質の改善と汚染の防止に重要な情報を提供します。',
    },
    {
      head: '',
      subHead: '災害予測および警告システム',
      desc: '気象データを利用して自然災害を予測し、関連する警告システムを開発します。これらのシステムは災害への備えと対応を向上させ、自然災害への迅速な対応を可能にします。',
    },

  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を労働者や機器に取り付け、リアルタイムで位置を追跡する製品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/jp/enterprise/construction/asset'
      }, 
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/jp/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      }
    ]


  }

}
