import { SCREEN_BREAKPOINT, StyledGridContainer } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const DropdownLayout = styled.div`
    position : absolute;
    max-width : 810px;
    margin : 20px auto 0 auto;
    height : 300px;
    width : 100%;
`;




export const DropdownMenu = styled.div<{side : string}>`
    
    bacgkround-color : blue;
    cursor : pointer;
    height : 300px;
    width : calc((100% - 32px)/2);


    border-radius: 8px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
    background: var(--background-primary-light, #FFF);

    z-index : 3;
    overflow-y : auto;
    ::-webkit-scrollbar {
        display: none; /*Chrome, Safari, Opera*/
        width: 0px;
    } 

    ${({side}) => side === 'category'&&
     `margin-left : auto;`}


    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        width : 100%;
        right : 0;
        width : calc(100% - 32px);
        margin-right : 16px;

    }
`;


export const DropdownMenuItem = styled.div`
    height : 52px;
    padding : 16px;

    &:hover {
        background: var(--blur-blue, rgba(42, 112, 240, 0.08));
    }
`;

