import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/retailExhibition/video/rtlsVideo.mp4';

export default {

    desc: 'AIoT（人工智能物联网）是将人工智能（AI）与物联网（IoT）相结合的概念，利用机器学习、数据分析、认知技术等人工智能技术，使物联网设备能够智能地相互作用、学习和具备智能。',
    keywords: 'AIoT (物联网人工智能), IoT (物联网), 人工智能摄像头, 数字孪生, 数字化转型, 信标, 实时定位系统 (RTLS), 超宽带 (UWB), 蓝牙低功耗 (BLE), Orbro, KongTech.',

    ogSite: '什么是AIoT？ | 定义、技术、案例、优势',
    ogUrl: 'https://orbro.io/cn/blog/what-is-aiot',
    ogImg: 'https://lh3.google.com/u/0/d/1beXwJR6Bc3ap2Kb_H7OaW4vPVlq3ovWM=w2000-h7622-iv1',
    ogType: 'website',

    title: '什么是AIoT？ | 定义、技术、案例、优势',
    date: '2024-05-09',
    tag1: '#AIoT',
    tag2: '#IoT',
    tag3: '#AI',
    tag4: '#人工智能融合技术',

    text: [
        {
            head: 'AIoT是什么？',
            subHead: '',
            desc: 'AIoT（人工智能物联网）是将人工智能（AI）与物联网（IoT）相结合的概念。通过利用机器学习、数据分析、认知技术等AI技术，使物联网设备能够智能地相互作用、学习和具备智能。AIoT将AI技术整合到传统的IoT技术中，实现更高效的自动化和基于数据的决策，从而提高生产力和提供便捷的服务。',
            dot: ''
        },
        {
            head: 'AIoT代表技术',
            subHead: '人工智能（AI）',
            desc: 'AI技术包括机器学习、深度学习、自然语言处理等。利用这些技术进行数据分析和模式识别，使物联网设备能够智能地相互作用。',
            dot: ''
        },
        {
            head: '',
            subHead: '物联网（IoT）',
            desc: 'IoT是指各种物体连接到网络，可以交换数据的技术。传感器、设备、家用电器等物体连接到互联网，相互交互并收集、传输、处理数据。',
            dot: ''
        },
        {
            head: '',
            subHead: '传感器和执行器',
            desc: '传感器从周围环境中收集数据，而执行器是影响外部环境的设备，它们是物联网设备的重要组成部分。',
            dot: ''
        },
        {
            head: '',
            subHead: '云计算',
            desc: 'AIoT利用云服务进行大规模数据处理和分析。基于云的服务器存储和处理数据，并为用户提供决策支持。',
            dot: ''
        },
        {
            video: video,
            link: '/cn/enterprise/retail/exhibition',
            head: 'AIoT应用案例',
            subHead: '智能家居',
            desc: '利用AI技术将家庭内的各种设备连接起来，提供便捷的生活环境。例如，智能家居中心可以控制家庭内的照明、供暖、安全系统等，并学习用户习惯，提供最佳的能源消耗方案。',
            dot: ''
        },
        {
            head: '',
            subHead: '智能健康管理',
            desc: '利用可穿戴设备和传感器监测个人的健康状况，并进行分析，提供个性化的健康管理服务。这些数据包括心率、睡眠模式、活动量等，AI算法可用于预测健康状况或及早发现疾病。',
            dot: ''
        },
        {
            head: '',
            subHead: '智能农业',
            desc: '利用IoT传感器收集土壤湿度、温度、PH值等信息，并进行分析，监测作物状态并优化种植过程。农民可以通过智能手机应用实时监测作物状态并采取必要措施。',
            dot: ''
        },
        {
            head: '',
            subHead: '智能城市',
            desc: '连接城市内各种设施和基础设施，提高城市效率并改善市民生活。例如，智能照明可以根据环境条件调节，智能交通系统可以实时监测交通状况并预防堵车或提供最佳路线。',
            dot: ''
        },
        {
            head: '',
            subHead: '智能制造',
            desc: '在制造业中，利用IoT传感器和机器人自动化生产线并监控生产过程。AI算法分析生产数据，优化产量并预测次品，提高生产效率。',
            dot: ''
        },
        {
            head: 'AIoT的优势',
            subHead: '智能自动化',
            desc: 'AIoT利用人工智能技术智能地控制和管理物联网设备。这可以实现任务的自动化和优化，减少人为干预，提高生产力。',
            dot: ''
        },
        {
            head: '',
            subHead: '基于数据的决策',
            desc: 'AIoT收集并分析大量数据以进行决策。这使得能够实时了解情况并采取最佳行动。',
            dot: ''
        },
        {
            head: '',
            subHead: '预测分析和维护',
            desc: 'AIoT使用传感器数据和AI算法提前检测系统异常并进行预测分析。这可以预防设备故障并优化维护计划。',
            dot: ''
        },
        {
            head: '',
            subHead: '个性化服务',
            desc: 'AIoT通过分析用户数据提供个性化服务。例如，智能家居系统可以根据用户习惯和偏好调节照明、供暖等，提供便捷安全的环境。',
            dot: ''
        },
        {
            head: '',
            subHead: '能源效率',
            desc: 'AIoT有助于优化能源消耗。通过分析传感器数据和AI算法，提出最佳的节能方案，提高能源效率。',
            dot: ''
        },
        {
            head: '',
            subHead: '节省时间和成本',
            desc: 'AIoT通过自动化和优化任务节省人力和时间。此外，通过预测分析提前预防设备故障，可以节省成本。',
            dot: ''
        },
        {
            head: '',
            subHead: '',
            desc: 'AIoT技术将人工智能与物联网结合，提供智能自动化、基于数据的决策、预测分析、个性化服务等多种优势。这可以实现生产力提高、成本降低、能源效率提高等，并在各种工业和生活场景中实现创新和效率。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: '将UWB终端设备安装在工人或设备上，以支持实时位置跟踪的产品。',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/cn/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/cn/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/cn/enterprise/construction/equipment'
            }
        ]


    }


}
