import { TechUseCaseIcon3D, TechUseCaseIconBuilding } from "@assets/images/technology/common";
import { IconBattery, IconCar, IconChartInv, IconHeart, IconHome, IconRulerInv, IconTagInv } from "@assets/images/icon";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

export default {

    seo: {
        title: 'What is Digital Twin? | ORBRO',
        desc: 'Digital Twin collects real-time data of physical objects and reflects it in a virtual model, replicating the real-world state. The collected data is stored and analyzed on a cloud-based platform, generating various insights.',
        keywords: 'digital twin, digital transformation, smart building, virtual reality, rtls, location tracking, beacon, orbro, contech',
        img : 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: 'https://orbro.io/en/enterprise/technology/what-is-digital-twin',
        type: 'website',
    },
    
    tag: 'Digital Twin',
    title: 'What is Digital Twin?',
    desc: '',
    
    btnText : `ORBRO's Digital Twin`,
    btnLink : '/en/digital-twin',

    text: [
        {
            head: 'What is Digital Twin?',
            desc: `Digital Twin collects real-time data of physical objects and reflects it in a virtual model, replicating the real-world state. The collected data is stored and analyzed on a cloud-based platform, generating various information. This information contributes value in aspects such as the physical object's condition, performance, maintenance predictions, supporting decision-making. For example, in manufacturing, Digital Twin monitors production processes accurately, optimizing equipment maintenance schedules through predictive analysis, reducing production time, and enhancing product quality. In essence, Digital Twin monitors the real-time operational state of actual systems, quickly detecting changes or anomalies in the physical object, thereby increasing system stability and preventing issues in advance. Additionally, Digital Twin offers the opportunity to experiment with various scenarios through simulation, finding optimal operating methods for introducing new technologies or improving processes, resulting in more accurate and effective decision-making. Digital Twin is applied in various areas such as lifecycle management, maintenance predictions, performance optimization, product development, allowing businesses to achieve cost savings and productivity improvements. These advantages contribute to Digital Twin emerging as a core technology in modern industries.`,
            subHead: '',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/en/enterprise/manufacturing/process',
            head: 'Advantages of Digital Twin',
            desc: '',
            subHead: [
                {
                    title: 'Real-time Monitoring and Analysis',
                    desc: 'Digital Twin collects and analyzes real-time data of physical objects, accurately monitoring their operational state. This enables rapid detection of issues and implementation of measures to improve productivity and increase system safety.'
                },
                {
                    title: 'Simulation and Optimization',
                    desc: 'Using Digital Twin allows simulation of various scenarios and finding optimal operating methods through virtual models. This provides opportunities to cut costs and maximize efficiency.'
                },
                {
                    title: 'Maintenance and Lifecycle Management',
                    desc: `Accurate understanding of the physical object's condition optimizes maintenance schedules and effectively manages equipment lifecycle, leading to cost savings and increased system reliability.`
                },
            ],
            dot: ''
        },
        {
            head: 'Types of Digital Twins',
            desc: 'Digital Twins can be categorized into various types based on industrial sectors, application areas, and physical object characteristics. Among several types, here are some key types of Digital Twins:',
            subHead: [
                {
                    title: 'Product Twin',
                    desc: 'Utilizes data generated throughout the product lifecycle to digitally model and track the product\'s condition and performance. In manufacturing, Product Twin is used to trace and optimize information at every stage, from design and manufacturing to operation and maintenance.'
                },
                {
                    title: 'Process Twin',
                    desc: 'Digitizes complex processes such as manufacturing operations or facility management. It is employed to monitor and enhance aspects like process optimization, energy efficiency, and operational safety.'
                },
                {
                    title: 'Facility Twin',
                    desc: 'Represents the physical characteristics of buildings or facilities digitally. Real-time monitoring of energy consumption, maintenance schedules, and environmental factors optimizes the efficiency of the facility.'
                },
                {
                    title: 'City Twin',
                    desc: 'Digitally models the infrastructure, transportation, and environment of a city. In the concept of smart cities, City Twin is used for optimizing traffic flow, improving energy efficiency, and enhancing public safety.'
                },
                {
                    title: 'Biomechanical Twin',
                    desc: 'In the medical field, collects patient biometric information to create a Biomechanical Twin, digitally representing the patient\'s health. This allows healthcare professionals to monitor the patient\'s condition in real-time and use it for diagnosis.'
                },
                {
                    title: 'Twin of a Service',
                    desc: 'Digital Twins are not limited to products or processes; they are also emerging for services. This involves digitizing and efficiently managing the operation and performance of services.'
                },
            ],
            dot: ''
        },
        {
            videoDesc: '(Digital Twin for Manufacturing Facility, click to navigate)',            
            tag : 'noBottomLine',
            head: 'How Digital Twin Technology Works',
            desc: 'Digital Twin is a virtual model that accurately reflects the real world through the digitalization of physical objects. The operation of Digital Twin can be summarized in the following steps:',
            subHead: [
                {
                    title: '1. Sensor and Data Collection',
                    desc: 'The operation of Digital Twin starts with collecting various data generated from the physical object. Sensors, IoT (Internet of Things) devices, cameras, etc., are used for this purpose to gather information about the physical object\'s condition, location, and performance.'
                },
                {
                    title: '2. Data Transmission and Storage',
                    desc: 'The collected data is transmitted to and stored in a cloud-based platform or central database. This data can be extensive and in various formats, transmitted in real-time or updated at regular intervals.'
                },
                {
                    title: '3. Processing and Modeling',
                    desc: 'Stored data undergoes processing and is reflected in the virtual model. Machine learning algorithms and various analytical technologies can be used during this process. The virtual model accurately simulates the characteristics of the physical object and represents its state digitally.'
                },
                {
                    title: '4. Real-time Updates',
                    desc: 'Real-time updates of sensor data enable Digital Twin to accurately reflect the current state of the physical object. This allows Digital Twin to respond quickly to changes in the real world.'
                },
                {
                    title: '5. Simulation and Optimization',
                    desc: 'Digital Twin can experiment with various scenarios through simulation and find optimal operating methods. For example, predicting performance under specific conditions or experimenting with simulations in a changed environment to aid decision-making.'
                },
                {
                    title: '6. Interface and Visualization',
                    desc: 'The results of Digital Twin are visualized through a user interface, allowing users to easily understand and monitor information about the physical object\'s state, performance, predictions, etc. This way, Digital Twin brings the physical object onto a digital screen, accurately reflecting the real world and contributing to improved productivity and problem-solving.'
                },
            ],
            dot: ''
        },
        {
            tag: 'card',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
        {
            head: 'Building a Digital Twin',
            desc: 'The process of building a Digital Twin may vary based on industrial sectors and application domains, but generally includes the following steps:',
            subHead: '',
            dot: [
                {
                    title: '1. Define Goals and Scope:',
                    desc: [
                        {
                            text: ' • Decide which physical object to model as a Digital Twin.'
                        },
                        {
                            text: ' • Goals can focus on performance improvement, simplified maintenance, simulation, and optimization, among other aspects.'
                        },
                    ]
                },
                {
                    title: '2. Plan Sensor and Data Collection:',
                    desc: [
                        {
                            text: ' • Determine the necessary sensors and data collection methods.'
                        },
                        {
                            text: ' • Plan considering data collection frequency, format, security, privacy considerations, etc.'
                        },
                    ]
                },
                {
                    title: '3. Build Data Infrastructure:',
                    desc: [
                        {
                            text: ' • Establish data infrastructure to store and process collected data.'
                        },
                        {
                            text: ' • Choose and implement cloud-based platforms or on-premises solutions.'
                        }
                    ]
                },
                {
                    title: '4. Virtual Modeling and Simulation:',
                    desc: [
                        {
                            text: ' • Create a virtual model of the physical object using collected data.'
                        },
                        {
                            text: ' • This model accurately reflects the characteristics of the physical object and experiments with various scenarios through simulation.'
                        }
                    ]
                },
                {
                    title: '5. Data Analysis and Model Learning:',
                    desc: [
                        {
                            text: ' • Analyze collected data and improve the virtual model using machine learning technologies.'
                        },
                        {
                            text: ' • Implement predictive models or algorithms for problem-solving and learning.'
                        },
                    ]
                },
                {
                    title: '6. Real-time Monitoring and Updates:',
                    desc: [
                        {
                            text: ' • Collect real-time data from sensors, update the Digital Twin in real-time, allowing it to quickly respond to changes in the real world.'
                        },
                        {
                            text: ' '
                        },
                    ]
                },
                {
                    title: '7. Strengthen Security and Privacy:',
                    desc: [
                        {
                            text: ' • Implement security mechanisms to appropriately protect sensitive data.'
                        },
                        {
                            text: ' • Comply with regulations related to privacy protection.'
                        }
                    ]
                },
                {
                    title: '8. Interface and Visualization:',
                    desc: [
                        {
                            text: ' • Develop a user interface for users to easily understand and monitor Digital Twin results.'
                        },
                        {
                            text: ' • Use visualization tools to provide a visual representation of the virtual model\'s state and performance.'
                        }
                    ]
                },
                {
                    title: '9. Continuous Improvement and Optimization:',
                    desc: [
                        {
                            text: ' • Continuously monitor performance and effectiveness during operation.'
                        },
                        {
                            text: ' • Improve and optimize Digital Twin as needed.'
                        },
                        {
                            text: ' • Upgrade the system through the introduction of new data sources or technologies.'
                        }
                    ]
                }
            ]
        },
        {
            tag: 'advantage',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
    ],
    

    card: {
        title: 'Use Cases of Digital Twin',
        desc: 'Digital Twin is utilized in various industries for diverse applications. Here are some representative use cases of Digital Twin:',
        cards: [
            {
                icon: TechUseCaseIcon3D,
                title: 'Manufacturing Digital Twin',
                desc: [
                    {
                        title: 'Facility Monitoring and Optimization',
                        desc: 'Modeling production lines and facilities as Digital Twins to monitor real-time status and optimize maintenance schedules through predictive analysis.'
                    },
                    {
                        title: 'Product Development and Simulation',
                        desc: 'Creating Digital Twins of products to simulate and optimize the entire process from design to production, finding the optimal design.'
                    }
                ]
            },
            {
                icon: IconHome,
                title: 'Construction, Real Estate',
                desc: [
                    {
                        title: 'Building Monitoring',
                        desc: 'Real-time monitoring of the health status of buildings or infrastructure to optimize maintenance schedules and enhance safety.'
                    },
                    {
                        title: 'City Planning and Simulation',
                        desc: 'Simulating city aspects like traffic, energy usage, and environmental impact as Digital Twins to establish efficient urban planning.'
                    }
                ]
            },
            {
                icon: IconBattery,
                title: 'Energy Management Solution',
                desc: [
                    {
                        title: 'Smart Grid Operation',
                        desc: 'Modeling power supply networks as Digital Twins to optimize energy flow, efficiently operate power systems, and detect/prevent faults.'
                    },
                    {
                        title: '',
                        desc: '',
                    }
                ]
            },
            {
                icon: IconHeart,
                title: 'Healthcare Digital Twin',
                desc: [
                    {
                        title: 'Patient Monitoring and Diagnosis',
                        desc: 'Representing the health status of patients as Digital Twins for real-time monitoring by healthcare professionals, aiding in diagnosis.'
                    },
                    {
                        title: 'Medical Device Development',
                        desc: 'Using Digital Twins in the development stage of medical devices to perform simulations and enhance device performance.'
                    }
                ]
            },
            {
                icon: IconCar,
                title: 'Transportation Digital Twin',
                desc: [
                    {
                        title: 'Car Design and Testing',
                        desc: 'Using Digital Twins in the development stage of cars to improve performance and validate safety through simulations.'
                    },
                    {
                        title: 'Logistics and Transportation Optimization',
                        desc: 'Modeling logistics networks as Digital Twins to optimize logistics and transportation processes, reducing costs.'
                    }
                ]
            },
            {
                icon: TechUseCaseIconBuilding,
                title: 'Smart Buildings',
                desc: [
                    {
                        title: 'Smart Metering System',
                        desc: 'Modeling utility networks (electricity, gas, water) as Digital Twins for real-time monitoring and providing consumers with energy usage information.'
                    },
                    {
                        title: 'Environmental Monitoring',
                        desc: 'Digital Twins continuously monitor environmental factors such as air and water quality, climate, etc., facilitating informed environmental management decisions.'
                    }
                ]
            },
        ]
    },
    
    advantage: {
        title: 'Key Features of Digital Twin',
        cards: [
            {
                icon: IconRulerInv,
                title: 'Real-time Monitoring and Responsiveness',
                desc: 'Digital Twin can monitor the real-time status of physical objects, enabling quick responses when issues occur or are predicted. This enhances system safety and minimizes potential problems across various targets such as production lines, facilities, and buildings.'
            },
            {
                icon: IconTagInv,
                title: 'Simulation and Optimization Capability',
                desc: 'Digital Twin utilizes virtual models to simulate various scenarios and find optimal operating methods. This maximizes efficiency in aspects like production processes, product design, and energy usage. Additionally, simulating in changed environments helps in making informed decisions and reducing risks.'
            },
            {
                icon: IconChartInv,
                title: 'Cost Reduction in Maintenance and Lifecycle Management',
                desc: 'Utilizing Digital Twin allows accurate monitoring of the physical object\'s status and optimization of maintenance schedules through predictive analysis. This prevents unnecessary maintenance, predicts faults in machinery or facilities in advance, and efficiently performs repairs or replacements. This overall reduces maintenance costs and extends asset lifespans.'
            },
        ]
    }
    


}