import { IconBlueCircle, IconBlueCycle, IconBlueDevelop, IconBlueFigure, IconBluePlus, IconBluePower, IconBlueScan, IconBlueShield } from "@assets/images/main/icon/blue";
import { osBanner, osLogoDesktop, osLogoMobile, osLogoTablet } from "@assets/images/main/os/bannerImg";
import {
  OsSectionDesktop1, OsSectionTablet1, OsSectionMobile1,
  OsSectionDesktop2, OsSectionTablet2, OsSectionMobile2,
  OsSectionDesktop3, OsSectionTablet3, OsSectionMobile3,
  OsSectionDesktop4, OsSectionTablet4, OsSectionMobile4,
  OsSectionDesktop5, OsSectionTablet5, OsSectionMobile5,
  OsSectionDesktop6, OsSectionTablet6, OsSectionMobile6,
  OsSectionDesktop7, OsSectionTablet7, OsSectionMobile7,
  OsSectionDesktop8, OsSectionTablet8, OsSectionMobile8,
  OsSectionDesktop9, OsSectionTablet9, OsSectionMobile9,
  OsSectionDesktop10, OsSectionTablet10, OsSectionMobile10,
  OsSectionDesktop11, OsSectionTablet11, OsSectionMobile11,
  OsSectionDesktop12, OsSectionTablet12, OsSectionMobile12,
  OsSectionDesktop13, OsSectionTablet13, OsSectionMobile13,
  OsSectionDesktop14, OsSectionTablet14, OsSectionMobile14,
  OsSectionDesktop15, OsSectionTablet15, OsSectionMobile15,
  OsSectionDesktop16, OsSectionTablet16, OsSectionMobile16,
  OsSectionDesktop17, OsSectionTablet17, OsSectionMobile17,
  OsTextImg1, OsTextImg2, OsTextImg3, OsTextImg4,
} from "@assets/images/main/os/section";


export default {

  title: '数字孪生空间管理平台',
  osLogo: {
    desktop: osLogoDesktop,
    tablet: osLogoTablet,
    mobile: osLogoMobile
  },

  bannerImg: osBanner,


  section1: {
    img: OsTextImg1,
    title: '像现实一样管理空间，空间管理',
    desc: '您可以在虚拟空间中设置与实际空间相同的特定区域。<br/>管理人数统计、危险区域和逆行检测区域。',
    box: [
      {
        size: 1,
        title: '简单区域设置',
        desc: '轻松设置要管理和监视的区域<br/>以获取要跟踪的数据。',
        img: {
          desktop: OsSectionDesktop1,
          tablet: OsSectionTablet1,
          mobile: OsSectionMobile1,
        },
      },
      {
        size: 2,
        title: '危险区域设置和警报',
        desc: '设置危险区域并在未经授权的个体访问时发送警报。',
        img: {
          desktop: OsSectionDesktop2,
          tablet: OsSectionTablet2,
          mobile: OsSectionMobile2,
        },
      },
      {
        size: 2,
        title: '人数统计',
        desc: '提取特定区域的人员和资产等出入数据。',
        img: {
          desktop: OsSectionDesktop3,
          tablet: OsSectionTablet3,
          mobile: OsSectionMobile3,
        },
      },
      {
        size: 2,
        title: '逆行检测',
        desc: '指定特定区域的一个方向，并检测沿着相反方向移动的对象和物体，发送警报。',
        img: {
          desktop: OsSectionDesktop4,
          tablet: OsSectionTablet4,
          mobile: OsSectionMobile4,
        },
      },
      {
        size: 2,
        title: '按时间段跟踪流入',
        desc: '按时间段存储进入设置区域的对象和人员等数据，并提供数据。',
        img: {
          desktop: OsSectionDesktop5,
          tablet: OsSectionTablet5,
          mobile: OsSectionMobile5,
        },
      },
    ]
  },

  section2: {
    img: OsTextImg2,
    title: '实时数据管理',
    desc: '实时监控虚拟空间中发生的真实事件。<br/>您可以搜索或接收所需的特定数据以供使用。',
    box: [
      {
        size: 2,
        title: '实时位置追踪',
        desc: '收集并分析来自连接的Orbro设备的实时数据，以支持快速的情况感知和决策。',
        img: {
          desktop: OsSectionDesktop6,
          tablet: OsSectionTablet6,
          mobile: OsSectionMobile6,
        },
      },
      {
        size: 2,
        title: '数据收集',
        desc: '您可以实时监视由安装的设备（包括物联网设备）收集的数据。',
        img: {
          desktop: OsSectionDesktop7,
          tablet: OsSectionTablet7,
          mobile: OsSectionMobile7,
        },
      },
      {
        size: 2,
        title: '设备搜索',
        desc: '实时搜索对象（如资产或人员）的位置。',
        img: {
          desktop: OsSectionDesktop8,
          tablet: OsSectionTablet8,
          mobile: OsSectionMobile8,
        },
      },
      {
        size: 2,
        title: '数据下载',
        desc: '通过实时数据分析及时检测异常并发送警报以进行早期响应。',
        img: {
          desktop: OsSectionDesktop9,
          tablet: OsSectionTablet9,
          mobile: OsSectionMobile9,
        },
      },
    ]
  },

  section3: {
    img: OsTextImg3,
    title: '便捷管理功能',
    desc: '实时监控虚拟空间中发生的真实事件。<br/>您可以搜索或接收所需的特定数据以供使用。',
    box: [
      {
        size: 2,
        title: '情况特定算法应用',
        desc: '通过更改为各种空间开发的Orbro算法，获取最佳数据。',
        img: {
          desktop: OsSectionDesktop10,
          tablet: OsSectionTablet10,
          mobile: OsSectionMobile10,
        },
      },
      {
        size: 2,
        title: '设备管理',
        desc: '具有灵活结构，可以有效管理物联网设备，轻松添加新设备或升级现有系统。',
        img: {
          desktop: OsSectionDesktop11,
          tablet: OsSectionTablet11,
          mobile: OsSectionMobile11,
        },
      },
      {
        size: 2,
        title: '自定义仪表板',
        desc: '自定义仪表板为用户提供洞察力，便于监控重要数据并做出有效决策。',
        img: {
          desktop: OsSectionDesktop12,
          tablet: OsSectionTablet12,
          mobile: OsSectionMobile12,
        },
      },
      {
        size: 2,
        title: '移动小部件提供',
        desc: '通过小部件提供，用户可以方便地查看各种数据，而无需启动应用程序，并快速访问应用程序。',
        img: {
          desktop: OsSectionDesktop13,
          tablet: OsSectionTablet13,
          mobile: OsSectionMobile13,
        },
      },
    ]
  },

  section4: {
    img: OsTextImg4,
    title: '一个系统中的集成OS',
    desc: 'Orbro OS功能强大，方便易用，而且不复杂。<br/>您可以轻松设置和使用任何产品。',
    box: [
      {
        link: '/rtls',
        size: 2,
        title: '位置跟踪系统',
        desc: '注册安装了UWB、BLE、信标等产品的产品到Orbro OS。',
        img: {
          desktop: OsSectionDesktop14,
          tablet: OsSectionTablet14,
          mobile: OsSectionMobile14,
        },
      },
      {
        link: '/sensor/LoRaGateway',
        size: 2,
        title: '工业物联网',
        desc: '通过实时数据分析及时检测异常并发送警报以进行早期响应。',
        img: {
          desktop: OsSectionDesktop15,
          tablet: OsSectionTablet15,
          mobile: OsSectionMobile15,
        },
      },
      {
        link: '/access',
        size: 2,
        title: '进出系统',
        desc: '注册Orbro进出系统并收集进出数据。',
        img: {
          desktop: OsSectionDesktop16,
          tablet: OsSectionTablet16,
          mobile: OsSectionMobile16,
        },
      },
      {
        link: '/camera',
        size: 2,
        title: 'AI摄像头位置跟踪',
        desc: '轻松映射安装在实际空间中的摄像头和虚拟地图。',
        img: {
          desktop: OsSectionDesktop17,
          tablet: OsSectionTablet17,
          mobile: OsSectionMobile17,
        },
      },
    ]
  },




  function: {
    title: '其他各种功能',
    box: [
      {
        icon: IconBlueScan,
        title: '实时数据分析和可视化',
        desc: 'OS可以分析和可视化收集的数据，使用户能够实时理解和利用收集到的信息。',
      },
      {
        icon: IconBlueShield,
        title: '安全和个人数据保护',
        desc: '提供强大的安全功能来保护数字孪生。限制对敏感信息的访问，并防止未经授权的数据修改，以最小化安全问题。',
      },
      {
        icon: IconBlueDevelop,
        title: '开发者友好',
        desc: '它将被开发为允许开发者轻松利用和扩展数字孪生功能。提供强大的API和开发工具，以开发新的应用程序和服务。',
      },
      {
        icon: IconBlueFigure,
        title: '人工智能整合',
        desc: '整合人工智能（AI）技术，以更智能地管理和优化数字孪生。使用AI算法分析数据并构建预测模型，以提高系统性能。',
      },
      {
        icon: IconBluePower,
        title: '环境监测和智能能源管理',
        desc: '整合环境监测传感器，以优化能源消耗和资源利用。实时监测环境状况，并根据需要自动调整系统，以最大程度地提高能源效率。',
      },
      {
        icon: IconBlueCycle,
        title: '自动化和智能控制',
        desc: '基于收集到的信息，通过数字孪生做出自动化决策。例如，在满足特定条件时自动控制设备或执行流程。',
      },
      {
        icon: IconBluePlus,
        title: '可定制的用户界面',
        desc: '提供用户根据需要定制界面的功能。用户可以自由配置用于监视和控制数字孪生的仪表板或应用程序。',
      },
      {
        icon: IconBlueCircle,
        title: '生态系统扩展性',
        desc: '建立允许开发人员和企业开发插件或插件以扩展系统的生态系统。这样，可以提供定制的解决方案，适用于各种行业和应用领域。',
      },
    ]
  },


  beacon: {
    index: 4
  },
  rtls: {
    index: 1
  },
  camera: {
    index: 2
  },
  access: {
    index: 3
  },
  sensor: {
    index: 4
  },
  integration: {
    index: 1
  },
}