import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    helmet: '| What is LoRa?',
    desc: 'LoRa (Low Range Radio) is one of the wireless communication technologies designed for low-power and long-range wireless communication. It is suitable for communication between sensor networks, industrial automation, and especially IoT devices.',
    keywords: 'LoRa, wireless communication, IoT, communication technology, RTLS, real-time location tracking, ORBRO, ConTech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'What is LoRa? | Location Tracking and Digital Twin, ORBRO',
    ogUrl: 'https://orbro.io/en/blog/what-is-LoRa',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkGw848hiaZwUj1NzOs30j6aqQAJyNCTeY0oQIeVJFOkeYuNI3kvZhnbc5uEfN7j4eLrviv8mEssXZn4lxH-dLhqXgRoLEtW86etie39T8Z4ZrsMxp-5A2G7W4VIP6BP_qFtyqDEJ_krIVKkxluM5vTPOKBMEyrGtQ_3ydm3DGKWHp7F-ShLBeXFtG1oET_EreJ_iYWOkT3CzAatE2_V77uE1qOcaLPuWCttx2d70Jt6u0a7tKI8nKCOheeIgrZqmaGNHLXtWSyJnPPkS083MmuPZv_AuP5JXZOe4h-xyzZcYCyo1E8vbbzRQZKjDcRj4CXwdfV6isxP_GMeoZXMvMMQR6ZL-CjdMHskmTwCTVRtYSQd-QRWtlRXn5bdxpcE5n2kgYENQIRBn73zqANbo6d7dbmHSgh3vqEzNP7yLk6mMQ-YNVO_04A1nNDlqhOaA9HQ7kkUtvDq1WsmkMAw2i169IEUISWz8pliXgKuRnPM_YOP8EWj0ewhPm6YtPrYizD5n9yo8sGI_B1E63PiHKz9hRNeRPSWKrkFPIlT-Y-adJ1giugNEpETW7dZOnr3u_FsbtzrPm6eOw5gQecQrBtDq14AN5olMMyI3_uGibjXoS3rutDYRvls1YlPMHv2iUBWhe9CmRSI9BsT-_tyscLg6cwWAhwfb9OdbWga3TI7dLo9b7s-8SFXm6s6Yqop9WgsB23-rOl_h4UAZHsjRu93uENXiNTD7YD-5VbvdSubD6Ffj3xkL3f9HdhilG3turAnuWLTcQPQ6f9yePOwuvoIrcmXntkKHwzoChR5WH4VoWk83o-UippkYQ3gzYCJJ8-4yd9JZg-TUA3-n9KHJvWyoaCjlaOTCkcBygN_c4WcWJgu3zui6gzBVZTv3oyuKzJzYYslQ89doq9Lh_T7yohz1vbGyDjOJ7gr-onVsc-yfw0_f3apHYvqfZKsSE_9DLZ6G9uzBBotKH-KPTnvEp3OA-2poPSP5Z-DsbLOymfPnvpMPs5ZsiCGh7Jvomyr5pxJcJL19u6HKf3979fiLresgN9QVAef0DmZTEYu76a10CjiW20Ne3C15KDO6Q2ICYOLpVyObPTZc6gGrvgED_Xr2Ul4We1Nc38VxTaLYoPMhzbg8SIe6AZ_UzExO4tZtDfX1hU-5kzIRBq2lXR1cKGXLvP8B0VUshx8iDhwWXOkUPuCyymV8Ng2j6JQyXZr0H2lyhO-b4WgS3aP5efeg_Ny64v_sUBQVG9wKL2FSxalXpQy5LHTrrbPV3akp2a7BeoMondBr7DVQnCTG8sc1oOJb-ZkAtOR05xmrGM2l4A0PlhelYg6RIyPfBvkGI5PV8lh3yPOUQVQCXBzqm_G6LYCRbqWdX5-3BlFEh4-fQpy6luoA1LossKR8VX0DXX8e9lcSJT17c2nXpV9w1mjyWuW8rYGBCeiiyulrgkobyIG_fSCd3TWmdr10IZU-_4msP81pWVBQ0iqdbEp4g-5cuufyYZ-hJo1dOOQGOsizdKKRg_-ndSZc_0nSCjjoEIoxoIl_wZN08371fZ_3FzYFTjFYkbcO9F=w1834-h1208',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'What is LoRa?',
    date: '2024-01-15',
    tag1: '#LoRa',
    tag2: '#IoT',
    tag3: '#CommunicationTechnology',
    tag4: '#LowRangeRadio',

    // <br/>
    text: [
        {
            head: 'What is LoRa?',
            subHead: '',
            desc: 'LoRa (Low Range Radio) is a wireless communication technology designed for low-power and long-range wireless communication. This technology is used in various applications such as sensor networks, smart cities, agricultural automation, industrial automation, and is particularly suitable for communication between IoT (Internet of Things) devices. The technology effectively utilizes frequency bands to provide communication distances of up to tens of kilometers while extending battery life through low power consumption. Additionally, it is cost-effective and features security, making it versatile in various application areas.',
            dot: ''
        },
        {
            head: 'LoRa Features',
            subHead: 'Long-Range Communication',
            desc: 'LoRa supports long-range communication, providing communication distances of up to tens of kilometers. This is especially useful when collecting or monitoring sensor data in agricultural or remote areas.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Low Power Consumption',
            desc: 'LoRa is characterized by low-power communication, allowing devices such as battery-operated sensors to operate for extended periods. This reduces the need for frequent battery replacement or charging in sensor network nodes.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Effective Use of Frequency Bands',
            desc: 'LoRa mainly uses the ISM (Industrial, Scientific, Medical) bands, such as 868MHz (Europe) and 915MHz (United States). These frequency bands are available for free use and are suitable for low-power devices.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Cost-Effective',
            desc: 'LoRa can be implemented at a relatively low cost, making it efficient for building large-scale IoT devices. This cost-effectiveness extends to various applications.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Star Topology',
            desc: 'LoRa networks primarily use star topology, where each device communicates directly with the gateway. This allows easy network expansion and fast communication between devices.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Scalability and Flexibility',
            desc: 'LoRa can be flexibly applied in various application areas. Additionally, it has the ability to easily add new devices to the network, facilitating scalability.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Encryption and Security',
            desc: 'LoRa provides encryption during data transmission to enhance security. This ensures the secure transmission of important sensor data. Moreover, as LoRa technology is developed as an open standard, various manufacturers can provide compatible devices.',
            dot: ''
        },
        {
            head: 'Use Cases of LoRa',
            subHead: 'Agricultural Automation',
            desc: 'LoRa is utilized in agriculture to build sensor networks for real-time collection and analysis of data such as crop conditions and soil conditions, enhancing agricultural productivity.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Smart City',
            desc: 'LoRa provides efficient communication between various sensors and devices in smart cities, contributing to infrastructure management, environmental monitoring, and public safety systems, promoting the development of smart cities.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Industrial Automation',
            desc: 'In industrial automation, LoRa supports communication between sensors and equipment, optimizing production processes and monitoring. This enhances production efficiency and reduces costs.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Environmental Monitoring',
            desc: 'LoRa is applied in natural environmental monitoring to collect real-time data on air quality, water quality, climate, etc. This data is used to monitor environmental changes and take appropriate actions.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Intelligent Building Management',
            desc: 'LoRa facilitates efficient management of various systems within buildings, such as energy usage, security systems, and HVAC control, contributing to the construction of intelligent buildings.',
            dot: ''
        },
        {
            head: 'Future Outlook of LoRa Technology',
            subHead: 'Data Standardization and Scale Expansion',
            desc: 'It is predicted that efforts in data standardization and scale expansion in LoRa will enhance interoperability between various manufacturers and service providers. This will further expand the ecosystem utilizing LoRa and strengthen its position in the global IoT market.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Integration with Edge Computing',
            desc: 'LoRa, when combined with edge computing, is expected to support real-time data processing and application execution, providing a higher level of automation and intelligence for smart devices and systems.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Enhanced Security',
            desc: 'LoRa is expected to enhance security by developing stronger features, strengthening communication and data transmission security between devices. This will contribute to safely transmitting and protecting sensitive information.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Integration with Artificial Intelligence',
            desc: 'LoRa, integrated with artificial intelligence, is expected to provide innovative solutions in various fields such as predictive analysis and autonomous driving technology by enhancing data analysis and decision-making processes.',
            dot: ''
        }
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'A product that helps real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/en/enterprise/logistics/asset'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/en/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/en/enterprise/manufacturing/asset'
            }
        ]

    }

}
