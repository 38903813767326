import styled from '@emotion/styled';


export const StyledBox = styled.div`

    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    background-color: ${({ theme }) => theme.BACKGROUND_PRIMARY};
    padding: 24px 16px;
    justify-content: space-between;
    align-items: flex-start;

    margin-bottom : 20px;

`;


export const StyledImgBox = styled.div`
  text-align : center;
  justify-content : center;
`

export const StyledImg = styled.img`
  margin : auto;
  object-fit : contain;
  
  max-width : 100%;

`

export const StyledBtn = styled.div`
  align-items: center;
  padding: 12px;
  gap: 4px;
  margin : auto;
  color : #FFFFFF;
  background: #2A70F0;
  border-radius: 8px;
  cursor : pointer;
  
`




export const StyledTextBox = styled.div`
  padding : 16px 0;

`


export const StyledSubTextBox = styled.div`
display : flex;
flex-direction : row;

  padding : 16px 0;

`

export const StyledGapBorder = styled.div`
  height : 16px;
  border-top : 1px solid rgba(0,0,0,0.08);
`


export const StyledCheckBox = styled.img`
  width : 20px;
  height : 20px;
  display : flex;
  align-items : center;
  justify-content : center;
  margin-right : 8px;

`