import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4"

export default {
    helmet: '| スマート工場とは？',
    desc: 'スマート工場とは、IoT技術と人工知能を活用して生産ラインを自動化し、最適化する工場のことです。これにより、生産プロセスの効率が向上し、メンテナンス時間が短縮され、生産量と品質が向上します。',
    keywords: 'デジタルツイン、スマート工場、スマート製造、nvr、ip、ptz、rfid、nfc、uwb、無線通信、位置追跡、オブジェクト追跡、人員追跡、タグ、ビーコン、rtls、orbro、コンテック',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'スマート工場とは？ | 位置追跡とデジタルツインの専門プラットフォーム、オブロ',
    ogUrl: 'https://orbro.io/jp/blog/what-is-a-smart-factory',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDlrNJZcN0y8rqN6rn4lIBsYzyCBFCLngoMNzRGAY6aBK05F5A7p7wMg7ZuILGtqR0GyG3qa5nWoqrTkhzMc8RRj0tMC2erUg8B95SfXjNpxqhWAlZI2ZNQ20zf6Bqvfx3ztoqtHXcz2hhYcN11wxSut__qBoWsflYyVOeQHJ35WsxS_wN8WaNJKzx-dhLVFusRshtRF7Zx4doNUOlm_KUYkgX0IUQ1IeB6yFe3WYvOyZC8sqY1FMnRAfO22PRRK4uVETtZYRhz1rrYA_xT7mbQP94D71TrIFaoebXtygq-yfvQOvJ4UfR6wr4I2M4V0oN6suppqQ8YAOYRD1WYR_cbQeuw_KsCJ2wLvHZYc2LaJwaDpP3CO-Ygqw0gsMrOMEtf5U1WEMf903qg20rF0D8hqpBPnjJRCQ6KY2leZG3Xv2k7q5gSOcKTnqSEQTe7DpWKAbnscWRoK7j1HGIopA0MWEyVIu5Ux9VhBZUSMEgfQkKoAX2UL71HTDwV7xf_AMP2cVdj7u49LCN1Pcdq-eKXMygl_EcCyGvoZ_wp4Bq7Uh1dbm7IJe5A2TaDKbuzgY9JL-ZFhxhwVES7tvZlWP-B_TeatTd2RKPQ6FMoeblJYuiAjHopqXL9JO8-y5wEcVgWwSo6_2W1AosdLSjlS2dqAE827GZP9HjudpoEd7lQXyP3bdXE6n3DzSuUOtextrsHpP6vN6tZ7q6krYTztpHpdIN8naMIOpdy4O2pdvv1uz-fhax_TyYVUEXg6ld7JVk-hPSvGPIAVUDpUx00G4uQgiBq8U0EZlVtIPynqiSn0VJjVTkArpnM_WX3E05A4Qwt-1AS3SagPFHUABELaSJyL1L34cKVxK_BLvKBZlGnfpC1LqOfmjS5iBamKqpm4ctvMkBIwLKcxad4eYpEH1H_yQ9AxantgCTB-GHWAdLrVk0cA3jMjJfx-OpQRCUEE2BSLWbuTCDEtJpMFwRzLdHzcRjg8qbJgjJTV7KeVZCHqn9DoghaNW2r_ON-IXwyfgNGPNPqeGx81epFjGIdFEjaCGAgIGdsth-CUeTGc5Q69DrT_PAuAYnbN3F7Bj1XLyz0Ie97ogOtcAHLzQwCjNd0Ll1vl2A35yNX905gQEctPSAY9e91nlTtH6KmIgLYgtpBt-w4xOQIXh4_z5hZulvwYKL2lh7Xt-qXqpV-PGYYbnGgz20mwGck4DxmCnXztmOUOMWkwvZ6BFpIoA7ZA2EntRJ8Z6hI17yexqq-384ja6SLh_3QqtEALCOtwyxIYCqwJsSFPyGPp17Pz1RyvB-j2RZ23GCX1YKYo6STGlKOmlYxmV0spVQQIQVgamCYHkKTDfSr3KFe2-SyaJEaPRoIansSf_NTtNvwC8RD0AMr2HYs7iQ35mFLUS5KF2dTLlah-AmnppP4WbuZ-4T0McWZKlfAFjR_nLifHSgTF8XTBEa9XMQsjhzP3c_SWzbqMOkYR82Q9zVg6wacsuo537pPMZn5oxZcSFNdLKsmywtFpS9Pg5dWlyWI7fgd7sn-lu7-vhp8j6iz36SG2OsnjAuOrlL92W_P9=w1984-h1208',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'スマート工場とは？',
    date: '2024-01-12',
    tag1: '#スマート工場',
    tag2: '#スマート製造',
    tag3: '#デジタルツイン',
    tag4: '#位置追跡',

    text: [
        {
            head: 'スマート工場とは？',
            subHead: '',
            desc: 'スマート工場は、IoT技術と人工知能を活用して生産ラインを自動化し、最適化する工場のことを指します。これにより、生産プロセスの効率が向上し、メンテナンス時間が短縮され、生産量と品質が向上します。これは従来の製造業の概念を革新し、生産プロセスで発生するコストを削減し、製品の品質を向上させます。<br/><br/>スマート工場は、現代の製造業で急速に普及している革新的な生産方法として、デジタルツインと位置追跡技術を効果的に活用し、生産性を向上させ、生産プロセスを最適化することに注目されています。',
            dot: ''
        },
        {
            head: 'スマート工場の機能',
            subHead: '',
            desc: 'スマート工場はさまざまな技術とシステムを統合して生産プロセスを知的に管理し、最適化することに重点を置いています。これにより生産性が向上し、コストが削減され、品質が向上します。主なスマート工場の機能は次のとおりです：',
            dot: {
                a: '<strong><br/> • 自動化およびロボット技術： </strong>スマート工場は先進のロボットと自動化システムを導入して生産ラインの作業を自動化します。これにより労働コストを削減し、生産性を向上させます。',
                b: '<strong><br/> • デジタルツイン技術： </strong>製品または生産プロセスのデジタルツインを生成し、現実の物理的なオブジェクトをデジタル環境にモデリングします。これにより生産プロセスをリアルタイムでモニタリングし、最適化することができます。',
                c: '<strong><br/> • IoT（モノのインターネット）技術： </strong>センサーと接続されたIoTデバイスを活用して生産ラインのすべての機器と装置をネットワークで接続します。これによりデータをリアルタイムで収集し、生産プロセスをモニタリングして効率的に管理できます。',
                d: '<strong><br/> • 位置追跡技術： </strong>RFIDやGPSを使用した位置追跡技術を活用して原材料、製品、作業者などの位置をリアルタイムで追跡します。これは生産ラインでのリソース管理と効率的な作業配置に役立ちます。',
                e: '<strong><br/> • 環境モニタリングおよびエネルギー効率化： </strong>スマート工場は発生するエネルギー消費や環境への影響をモニタリングし、最小化することに重点を置いています。持続可能な環境管理を通じて環境に優しい生産方法を追求します。',
                f: '<strong><br/> • クラウドコンピューティング： </strong>生産データをクラウドに保存し、管理して地理的に離れた場所からもデータにリアルタイムでアクセスし共有できます。異なる拠点間の円滑な協力をサポートします。',
            }
        },
        {
            video: video,
            link: '/jp/enterprise/manufacturing/process',
            videoDesc: '(スマートファクトリーソリューション、クリックで移動します。)',
            head: 'スマート工場の利点',
            subHead: '生産性向上',
            desc: '自動化およびロボット化を導入して反復的で労働集約的な作業を自動化することで、生産性を向上させます。また、デジタルツインを活用して生産プロセスをリアルタイムでモニタリングし、最適化することで生産効率を向上させます。',
            dot: ''
        },
        {
            head: '',
            subHead: '品質向上',
            desc: 'センサーやIoT技術を使用して生産ラインで発生するデータを収集し分析することで、製品の生産プロセスをモニタリングします。これにより不良率を減少させ、製品の品質を向上させます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'コスト削減',
            desc: '自動化されたシステムを導入して労働力コストを削減し、ビッグデータ分析を通じて予測メンテナンスを実施して保守コストを最小限に抑えます。また、生産プロセスの最適化を通じてリソースの使用効率を向上させ、コストを削減できます。',
            dot: ''
        },
        {
            head: '',
            subHead: '柔軟性と迅速な変化への対応',
            desc: 'デジタルツインとスマート製造システムを活用して生産ラインをより柔軟に調整および変更できます。これによりさまざまな製品の生産ラインを迅速に構築または変更できる能力を提供します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'リアルタイムモニタリングと意思決定',
            desc: 'センサーデータとIoT技術を使用して生産環境のデータをリアルタイムで収集し、ビッグデータ分析およびAI技術を活用して迅速に分析します。これにより生産ラインの状態をリアルタイムで把握し、迅速な意思決定が可能です。',
            dot: ''
        },
        {
            head: '',
            subHead: 'リソース効率化',
            desc: 'スマート工場はエネルギー効率の高い生産方法を導入してエネルギー消費を最小限に抑え、ビッグデータとAIを活用してリソースの効率的な使用をモニタリングおよび最適化します。',
            dot: ''
        },
        {
            head: '',
            subHead: '環境にやさしい生産',
            desc: '生産プロセスの環境にやさしい設計とエネルギー効率の高い生産方法を導入することで、環境にやさしい生産環境を構築します。これは企業の持続可能性を向上させ、環境への影響を最小限に抑えます。',
            dot: ''
        },
        {
            head: 'スマートファクトリーの事例',
            subHead: '',
            desc: '',
            dot: {
                a: '<strong>• トヨタ: </strong>トヨタはスマート製造業のリーダーと見なされています。彼らはデジタルツイン、ロボット工学、人工知能などの先進技術を積極的に導入して、製造プロセスを革新しています。例えば、高度なロボットが従業員と共存し、デジタルツインを使用して製造プロセスをリアルタイムで監視および最適化しています。',
                b: '<strong>• ボッシュ スマートファクトリー: </strong>ボッシュは自動車および産業技術分野にスマートファクトリーを確立しています。彼らはデジタルツインとIoTを統合し、位置追跡技術を使用して原材料および製品の移動をリアルタイムで追跡して製造プロセスを最適化しています。',
                c: '<strong>• GE スマート製造施設: </strong>GEは電力、再生可能エネルギー、ヘルスケアなど様々な分野で活動しており、スマート製造技術を積極的に採用しています。デジタルツインとIoTを使用して生産設備の状態を監視し、ビッグデータ分析を通じて予測保守を実施し、生産効率を最大化しています。',
            }
        },
        {
            head: 'スマートファクトリーの構築',
            subHead: '1. ビジョンの確立と戦略的計画:',
            desc: 'スマートファクトリーを構築する前に、企業はスマートファクトリーのビジョンを確立し、戦略を計画する必要があります。採用する技術や達成する目標を明確に定義します。',
            dot: ''
        },
        {
            head: '',
            subHead: '2. 現状分析と要件定義:',
            desc: '現在の生産システムと技術レベルを分析し、スマートファクトリー導入に必要な要件を定義します。生産プロセスの強みと弱点を特定し、改善が必要な領域を特定します。',
            dot: ''
        },
        {
            head: '',
            subHead: '3. 技術の選択と統合:',
            desc: 'デジタルツイン、IoT、ビッグデータ分析、人工知能、自動化など、スマートファクトリーを構成するさまざまな技術から選択します。これらの技術を効率的に統合したシステムを構築します。',
            dot: ''
        },
        {
            head: '',
            subHead: '4. オートメーションおよびロボティクスの導入:',
            desc: '生産ラインの一部またはすべてを自動化し、ロボットを導入して労働集約的なタスクを自動的に実行します。これにより生産性が向上します。',
            dot: ''
        },
        {
            head: '',
            subHead: '5. デジタルツインの構築:',
            desc: '製品または製造プロセスのデジタルツインを作成します。これにより物理的なオブジェクトやプロセスをデジタルで現実世界に複製し、監視および最適化が可能になります。',
            dot: ''
        },
        {
            head: '',
            subHead: '6. インターネット・オブ・シングス（IoT）技術の導入:',
            desc: '生産ライン上のすべてのデバイスや機器を接続し、リアルタイムでデータを収集するためにセンサーやIoT技術を導入します。これにより生産プロセスの監視と最適化が可能になります。',
            dot: ''
        },
        {
            head: '',
            subHead: '7. 位置追跡技術の導入:',
            desc: 'RFID、GPS、UWB RTLSなどの位置追跡技術を導入して製品、資源、従業員のリアルタイムの位置を追跡します。これにより最適なリソース配分が容易になります。',
            dot: ''
        },
        {
            head: '',
            subHead: '8. ビッグデータおよび人工知能（AI）の適用:',
            desc: '生産データを収集し分析するためにビッグデータ技術を活用します。AIを使用してデータのパターンを見つけ、予測分析を実行します。これにより生産プロセスが最適化され、予測保守が可能になります。',
            dot: ''
        },
        {
            head: '',
            subHead: '9. クラウドコンピューティングの導入:',
            desc: '生産データをクラウドに保存および処理し、データへのリアルタイムアクセスとデータの集中モニタリングを可能にします。',
            dot: ''
        },
        {
            head: '',
            subHead: '10. セキュリティの強化:',
            desc: 'スマートファクトリーでは多くのデータがやり取りされるため、セキュリティは重要です。適切なセキュリティシステムを導入してデータの整合性と機密性を確保します。',
            dot: ''
        },
        {
            head: '',
            subHead: '11. 教育とインフラの改善:',
            desc: '新しい技術やシステムを効果的に使用するために従業員にトレーニングを実施します。必要に応じてスマートファクトリーの設立をサポートするためにインフラを改善します。',
            dot: ''
        }
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/jp/enterprise/medical/patient'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/jp/enterprise/retail/exhibition'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/jp/enterprise/office/asset'
            }
        ]

    }

}
