

import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4';

export default {

    desc: '스마트 조선소는 AI(인공지능), IoT(사물인터넷), RTLS(실시간 위치추적), 디지털 트윈과 같은 첨단 기술을 활용하여 더욱 효율적이고 지능화된 생산 시스템을 구축하는 것을 말합니다.',
    keywords: '스마트 조선소, AI, IoT, RTLS, Digital twin, beacon, uwb, ble, 오브로, 콩테크',

    ogSite: '스마트 조선소 솔루션 | 디지털 트윈 플랫폼, 오브로',
    ogUrl: 'https://orbro.io/blog/smart-shipyard',
    ogImg: 'https://lh3.google.com/u/0/d/1WdXSNBhEft64iXMMY189UfAb4GJnSnFp=w2000-h7622-iv1',
    ogType: 'website',

    title: '스마트 조선소 솔루션',
    date: '2024-05-09',
    tag1: '#스마트조선소',
    tag2: '#조선소',
    tag3: '#항만산업',
    tag4: '#디지털트윈',

    // <br/>
    text: [
        {
            head: '스마트 조선소란?',
            subHead: '',
            desc: '스마트 조선소는 AI(인공지능), IoT(사물인터넷), RTLS(실시간 위치추적), 디지털 트윈과 같은 첨단 기술을 활용하여 더욱 효율적이고 지능화된 생산 시스템을 구축하는 것을 말합니다. 스마트 조선소는 생산 과정의 디지털화와 자동화를 통해 작업자의 안전을 강화하고 생산성을 높이는 동시에 에너지 효율성을 개선하여 지속 가능한 해양 산업을 구축하는 것을 목표로 합니다.',
            dot: ''
        },
        {
            head: '주요 기술',
            subHead: '실시간 위치 추적(RTLS)',
            desc: '작업자와 자산의 실시간 위치를 추적하여 생산 프로세스의 투명성을 높이고 효율성을 극대화합니다. 이 기술은 초당 업데이트되는 정확한 위치 정보를 제공하여 작업 일정을 최적화하고 안전을 강화합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '디지털 트윈(Digital Twin)',
            desc: '조선소의 모든 요소를 현실 세계와 디지털로 연결시켜 가상의 모델을 생성합니다. 이를 통해 실제 공정을 시뮬레이션하고 최적화할 수 있으며, 설계 단계에서부터 생산, 유지보수에 이르기까지 모든 단계에서 생산성과 효율성을 향상시킵니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '빅데이터 및 인공지능(AI) 활용',
            desc: '조선소에서 생성되는 대량의 데이터를 수집하고 분석하여 생산 과정의 최적화와 예측 정비를 실현합니다. 인공지능을 통해 데이터의 규칙성을 발견하고 이를 기반으로 예측 및 의사결정을 지원하여 생산성을 높이고 비용을 절감합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '사물인터넷(IoT) 기술의 도입',
            desc: '선박 및 조선소 내부의 다양한 장비와 센서를 연결하여 실시간 데이터를 수집하고 모니터링하여 생산 과정을 최적화합니다. 이를 통해 장비의 상태를 실시간으로 파악하고 예방 정비를 수행함으로써 생산성을 향상시킵니다.',
            dot: ''
        },
        {
            videoDesc : '(ex. 오브로 스마트 제조 시설)',
            video : video,
            link : '/enterprise/manufacturing/process',
            head: '주요 기능',
            subHead: '실시간 작업자 위치 추적',
            desc: '작업자의 실시간 위치를 추적하여 작업 일정을 최적화하고 작업 환경의 안전성을 유지합니다. 이는 작업자의 위치를 실시간으로 모니터링하여 위험 상황을 사전에 예방하고 작업 효율을 극대화합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '실시간 자산 관리',
            desc: '조선소 내 자산의 실시간 위치와 상태를 모니터링하여 재고 관리 및 작업 효율성을 향상시킵니다. 이를 통해 재고 부족이나 과잉 구매와 같은 문제를 방지하고 자산의 이용률을 최적화합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '환경 관리',
            desc: '환경 요소를 모니터링하여 조선소 내 환경 오염을 예방하고 생산 프로세스의 지속 가능성을 유지합니다. 이는 대기, 수질, 소음 등의 환경 요인을 실시간으로 모니터링하여 조치를 취하고 생산 환경을 개선합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '위험구역 관리',
            desc: '조선소 내의 위험 구역을 식별하고 모니터링하여 작업자의 안전을 보장하고 작업 중 발생할 수 있는 사고를 최소화합니다. 이는 위험한 지역을 식별하고 작업자에게 경고를 제공하여 작업 환경을 안전하게 유지합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '에너지 효율성 관리',
            desc: '스마트 에너지 시스템을 도입하여 항만의 에너지 사용량을 최적화하고 친환경적인 운영을 실현합니다.',
            dot: ''
        },
        {
            head: '미래 전망',
            subHead: '인공지능(AI)과 자율 주행 기술의 확대',
            desc: '미래에는 AI 기술이 보다 더 발전하고 자율 주행 선박 기술이 보급될 것으로 예상됩니다. 이를 통해 조선소는 운영 효율성을 향상시키고 생산 과정에서의 인력 비용을 절감할 수 있을 것입니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '사물인터넷(IoT) 기술의 적용',
            desc: 'IoT 기술은 더욱 더 항만 운영 및 관리에서 중요한 역할을 할 것으로 예상됩니다. 선박 및 기타 설비에 장착된 센서를 통해 데이터를 수집하고 분석함으로써 항만의 운영 효율성을 높일 수 있을 것입니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '환경 친화적 기술의 강조',
            desc: '지속 가능한 해양 산업을 위해 에너지 효율성을 개선하고 환경 보호에 힘쓰는 기술이 강조될 것으로 예상됩니다. 이러한 노력은 해양 생태계 보존과 함께 항만 운영의 지속 가능성을 높일 것입니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '글로벌 시장의 성장',
            desc: '글로벌 항만 및 조선 산업은 계속해서 성장할 것으로 전망됩니다. 특히, 신규 항만 건설 및 확장 프로젝트의 증가와 함께 아시아 태평양 지역에서의 성장이 예상되며, 이는 한국의 스마트 조선소 산업에도 긍정적인 영향을 미칠 것입니다.',
            dot: ''
        },
        {
            head: '결론',
            subHead: '',
            desc: '스마트 조선소는 미래 지속 가능한 해양 산업을 위한 필수 요소로 자리매김하고 있습니다. 첨단 기술의 도입과 혁신적인 접근을 통해 생산성, 효율성, 안전성을 높이고 동시에 환경 친화적인 생산 방식을 실현할 수 있습니다. 이를 통해 조선 산업은 더욱 빠르게 발전하고 미래에 걸친 지속 가능한 성장을 이룰 것으로 기대됩니다.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/enterprise/construction/equipment'
            }
        ]


    }


}