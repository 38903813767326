import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

export default {
    helmet: '| What is a Smart Hospital?',
    desc: `A smart hospital focuses on improving the efficiency and quality of medical services by utilizing IoT, artificial intelligence, and data analysis to monitor patients' health status in real-time and enhance the diagnosis and treatment process.`,
    keywords: 'digital twin, digital twin, smart factory, smart factory, NVR, IP, PTZ, RFID, NFC, UWB, wireless communication, location tracking, object tracking, person tracking, tag, beacon, RTLS, Orbro, Orbro, Kongtech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'What is a Smart Hospital? | Location Tracking and Digital Twin Platform, Orbro',
    ogUrl: 'https://orbro.io/en/blog/스마트병원이란',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmPfoc5xcFsetUID5pyg3CYD7YMozk7v8agtjsAU3FWSwMrSh5jezcJw00E2PsYdRwBciFQJKL4IguNdWivv4bRwDkwLuH_X_qAV982dYuBJho1obfwpVkXOoGGsFzUHcZ6I-tLD3CCDnlOljA-ZIojFjbigYAYMSdbWykA0SplErndN38ZvDJCDdtAJKIn703TEZAaikiyqmO_dZMOzge0_bIm3eS8eIPGsweG-xjhoDelT8CCOdyeN0LY68PSWfiinGf0eEuOoS3pti-1ZwLu-JjJELZsO1tsY30mxgenUNvpYfjBhCHq2IFN2vX5T-39NwhhLOOxM_5WE1ec46dO8qlMqV2Z1m6FkaFlsNr4fCccF3HM0uahwB1AlQY9T47hoqeH3C6kQC3JbanZWKV_CWqzXDVXLdEbwzA7taG3ny_gWknaCAdR_v0oqgaUnAkuOldeRH5wVEsZC6SaHP8T-DZXTyl_XTvlPl3WRE5POEcdCSelPI6gRWOzIuFOfyImIPGqtxPuc2yuwk9lv372FquiGhHqBAkHQ8FoPn5lNd7nVuV0COyXuu5vdL3sdii7rC-xZQ9s55QCRPlGbX51dJb6u-ku9H_Cv-Dxr-zr4PV0TnSKTWQxdM0kJbKL2kEgQCahORl-3ojZaHOXWE9Hqu5GjfKdar2oI2wNIGP82lEb7oRlXwEtqokSSFGY2SW6Sz-GeEUyJWL17f4XqGaBnv-bC_6XYXuyWQpnAU7VPOSjZOh4BPpQTAS1qEXKVISNMt9c4urPNVRAtG5I0Pn2v-fcBE4wcQgixYinbHP2OdtdyBKdjGOo31Zlp78Hs7PzDyWCQ4x8a4vV1z2EbrciEe2pcWWkll9qrFFYRRF62ecCzT9vRLko6dD4j9RjTWUigzS-Y0Un4NBy4gSwfIKmYvjf5q1muT96LYHPdE1qu-d9sUUW5Cg05eUAfUwwEFDQkms0x5jM5ds68-FYCiI2aeTY3nlo9E84to4h4o9Z2LjufbbwC3Y_zkPzkbREeF-FKbkRGiE1gw7WuUfRQ-K16n3rdiCytnsClNeTgIw_xYkkGYGS9TCh-BgPnvBvnvrfviZdwMpI22X-Jp_F4dVL_OBwVKMqKAUATJNPCcNiqqoGAHJdOv4Zwt4odCav4i7sM7_ZdJaN08S5egxFZMpLL9Lu1TR9SGWSpT-opyrkMUo2yWTKLtxY6E4mjO1EV5a2qRD-OSbGxlT6-HBn2HvJpvlbmHyChUDA1LR5aTDsReQq8lP5lIHjbkA1lD0h9x-MuYp0QUXJWiAoMdRxNVQBsZUHjkJP41fTk1bBuizb8U9tp3kv_NReX8fCs7a8UNYbGnWllcN3mRBSET80gDqirtQm2Nw5uX7nXHQvgKOqFufopQIHmjdVZBBRgLs9ofbVSgaD3bryP8l7mMotKmEyOPA_cD-WbTB8aSMfUbgqc_WSyW_3VJi_4Y0B2QfdwRGnr2d_lPXG_V62CHnP1vjPlkC7xGDXxVzG6hmC6EWsrfSEUvAywKLYdmBc0bEGQzj33GVTHADzLtZ01SU_juNo-ASh-i-A=w1834-h1208',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'What is a Smart Hospital?',
    date: '2024-01-16',
    tag1: '#SmartHospital',
    tag2: '#SmartHealthcare',
    tag3: '#DigitalTwin',
    tag4: '#LocationTracking',

    text: [
        {
            head: 'What is a Smart Hospital?',
            subHead: '',
            desc: `A smart hospital focuses on improving the efficiency and quality of medical services by utilizing IoT, artificial intelligence, and data analysis to monitor patients' health status in real-time and enhance the diagnosis and treatment process. It aims to create an innovative medical system that provides rapid and effective medical services by supporting efficient sharing of medical information and decision-making. This enables patients to receive more convenient and personalized medical services.`,
            dot: '',
        },
        {
            head: 'Technologies Applied in Smart Hospitals',
            subHead: '',
            desc: 'Smart hospitals employ various technologies to provide modern and efficient healthcare services. Key technologies include:',
            dot: {
                a: '<strong> • Real-time Monitoring Technology: </strong> Monitors patient conditions in real-time, enabling medical professionals to respond immediately and adjust treatment. This enhances patient safety and surveillance.',
                b: '<strong> • Electronic Medical Records (EMR): </strong> Records and manages patient medical records electronically, allowing medical professionals efficient access to information for decision-making.',
                c: '<strong> • Robotics Technology: </strong> Robots are used for various medical tasks, such as surgical assistance, patient care, and medication delivery, supporting smart hospitals in workforce augmentation and improving healthcare services.',
                d: '<strong> • IoT (Internet of Things) Technology: </strong> Monitors patient health conditions in real-time and collects data through medical devices and sensors, enabling the provision of personalized healthcare services.',
                e: '<strong> • Artificial Intelligence (AI) and Big Data Analysis: </strong> Analyzes medical data using AI, developing predictive models to enhance early disease diagnosis and prevention, thereby reducing healthcare costs.',
            },
        },
        {
            head: 'Advantages of Smart Hospitals',
            subHead: '',
            desc: 'The advantages of smart hospitals include efficient healthcare record management through electronic medical records, prompt response and treatment provision through real-time monitoring, and strengthening disease prevention and early diagnosis through the use of artificial intelligence and big data analysis. Additionally, smart hospitals introduce convenient reservation and treatment processes for improved patient experiences, incorporate IoT technology for personalized healthcare services, and utilize virtual and augmented reality for effective patient education and rehabilitation therapy. Robot technology also supports healthcare professionals, contributing to enhanced healthcare services. The convergence of these technologies innovatively enhances patient treatment and healthcare services.',
            dot: '',
        },
        {
            video: video,
            link: '/en/enterprise/medical/patient',
            head: 'Case Studies of Smart Hospitals',
            subHead: '',
            desc: '',
            dot: {
                a: '<strong> • Mayo Clinic: </strong> Mayo Clinic is globally renowned as one of the smart hospitals, integrating electronic medical records and real-time monitoring systems to increase the efficiency of patient treatment. They also provide features like reservation and medical information access through a mobile application for patients.',
                b: '<strong> • Singapore General Hospital: </strong> Singapore General Hospital actively utilizes smart technology to innovate healthcare services. They introduce real-time monitoring, IoT devices, and robot surgery to enhance the efficiency of patient treatment and provide personalized services to patients.',
                c: '<strong> • Sheba Medical Center: </strong> Sheba Medical Center adopts virtual reality (VR) for innovative approaches to patient education and treatment. They effectively contribute to patient rehabilitation through VR-based treatment programs.',
            },
        },
        {
            head: 'Outlook for Smart Hospitals',
            subHead: '',
            desc: 'Smart hospitals have firmly established themselves as a crucial part of the rapidly advancing healthcare industry. Leveraging the latest technologies and artificial intelligence, smart hospitals efficiently conduct patient diagnosis and treatment, reinforcing collaboration among healthcare professionals through real-time data sharing. Patients experience convenience by monitoring their health status through a mobile app and easily accessing healthcare services. Smart hospitals rigorously manage patients\' personal medical records, enhancing the safety of healthcare information. The future holds the promise of even more advanced technologies and innovative services, providing a patient-centric, efficient, and safe healthcare environment.',
            dot: '',
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'A product that assists real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.workerTracking.thumbnail,
            },
            {},
        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/en/enterprise/medical/patient',
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/en/enterprise/retail/exhibition',
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/en/enterprise/office/asset',
            },
        ],
    },
};
