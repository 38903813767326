import {
  constructionAsset,
  constructionSafety,
  logisticsAsset,
  medicalPatient,
  medicalWaste,
  officeAsset,
  retailExhibition
} from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../../en/main/product/productImg";
import productPurchase from "../../en/main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4";

export default {
  helmet: '| RFID, NFC, UWB: Which Technology to Choose?',
  desc: 'RFID, NFC, and UWB technologies have their own characteristics and advantages in object tracking, people tracking, wireless communication, etc. The choice of appropriate technologies depends on the specific situation.',
  keywords: 'rfid, nfc, uwb, wireless communication, location tracking, object tracking, person tracking, tags, beacons, rtls, orbro, contech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RFID, NFC, UWB: Which Technology to Choose? :: Orbro Blog',
  ogUrl: 'https://orbro.io/en/blog/RfidNfcUwb',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDyDzwljrefhMhdX7kf1Mm1ywH2nnOki0RIgSDRIh7C5tg1pR6m3kbEx54PkUJcq-hciL8YYYvumKJ-63gC6WJ4qVWUBwpX2L27Az-lRi9JVPUAPPrN4w5UrS1sMh0YLSWqhm1JpL4pi_Z08Ejh_gka2T1aIruLLocuLbyP_ZAhm5q8Ak1AOxoZ30Sa750ffkaTQoyxFwgi_lbDVwXorafRdzRGuy6GFGf2k6pLcN8gyQ_bmLhYnizUxtLSo4rpyQalNm-qZKsosAwvg0xPCKx3yIpdkycgeB8XisSD6JoFWDLjK5DE2XFvKt5taMzrDEUI4yq-kRgj54cRHA0AkUYviBZEHbun6CP-ddQfgL0_vxj-afsWWE11vR-J7aic2bRHVF46WEIHc_Q1lom0Ro3XRQy9WuJ3_x23uVUQemus3_BtxD76BMNNfwUJTA8ohBZ74GkjJAozZjHWVNZcN-bk9cTnLcBCuYdeXKB67hosHLnXyRWTxbKHnAspOTZuDEjoq0c6efewIt3TF-krS18xuDI06UQOOINUt3T177VvqEHZescieirG3kU3V1fklYqDXIo5VJhA8IxB1G7tllhssELDGTJmG7K40AbmDmY9wSwM_FRqgaHsIA3Ol9rBz2civyC8T_JpAV0c0gB0UdkO6mYn9PTaWSVEyOHMFhrLh9kJT9Ec3N26DFoUe0wJGHafWgc3EOY5SyD9TbyysdCbMFtmzbri63MLFS-CVlfuurccxzTp4fA7Y6x5m2w7-zVqBw4l17kYVEXAEnX1lIg2pSOuZAaWmSiOE3O6_4zbKpMHgySf3gCmA8moDXM_SB0npT9zE1NftELISmGwhEA0EWAC_OPBpOHijsZC38Ww9_MjSbVAn65T6j96hn0MVkq0drXqf9kVdR5VzHla36eqjBCnvxuv7p3OJpxMhG7JWgENwqauKMiKBc2sGmAUAtvjVrAUaxDD1f18pVT-97AjFdXJtrfEOrbFQXJhqp8F9GiFJTyQGbepdWdEjhzpL06WDXrPTZ9TMIEJrftdzxDH3RvDYScwSGlEPMALRbh448aSi05ozs5TG4KXVyOEN90b_BLn_LR5XkaXWFAyzj9nbaR5pQL75WGz857-0w5fOcpXAJlzdGLPK-qzwvQypZufKmeGjhUZN324auzcqLBxDTdpiX6mQfeqR9YekYBydEHDzesVBgPzdFxNIc8e-D6uW0CNYJbWG-cs9YLY0brSsnh8Ki32Gb_PwmdKDxyt-zejkein4rKNvH_L4fe-bCYOwKwuS3Do2tIDr_HRgFlmIuIzQ-LTzwnQHFwrivngUeb-urFqXm-OHCMLLVsTdhMl6F8tAmWehfylTymzJT9wSric2u2oyOWofynt5w2DHrO5btzJrk4fqVRfO7VXnIJ71aTGeb2wLS6fYLUIFWraHoG6b0QKEqzmoDVtIVpinqNK3eRd4bTQ5lxE6FJxMyX3Dnt2FOOzosarYUFrndKzLb8P1AnsUez-1xlCwpA2KUqFeolbevRLxAPtPZcEuFHLZKcBVKi48FPzR4jypbwpMCM7j9e9Eh9KNzoRlArES6rb_kHgh=w1995-h1157',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RFID, NFC, UWB: Which Technology to Choose?',
  date: '2023-11-10',
  tag1: '#rfid',
  tag2: '#nfc',
  tag3: '#uwb',
  tag4: '#wirelesscommunication',

  text: [
    {
      head: '',
      subHead: '',
      desc: 'In modern society, various wireless communication technologies have emerged to meet the demands of object tracking, data exchange, location measurement, etc. Among them, RFID, NFC, and UWB each have their characteristics and are chosen based on specific considerations. This article explores the features and use cases of each technology and discusses when to choose which technology in different situations.',
      dot: ''
    },
    {
      head: '1. RFID: Suitable for efficient object tracking and management',
      subHead: '',
      desc: 'RFID is primarily used in areas such as logistics, manufacturing, and inventory management for object identification and tracking. By attaching small RFID tags to objects, a reader can detect and read/write information. Passive RFID operates using external energy, while active RFID has its own power source. Due to these characteristics, RFID excels in efficiently tracking and managing large quantities of objects. It is also a popular choice for its relatively low cost.',
      dot: ''
    },
    {
      head: '2. NFC: Chosen for easy data exchange and wireless payments',
      subHead: '',
      desc: 'NFC is a technology mainly used for smartphone payments, smart home applications, and convenient data exchange. It operates at a very short distance, allowing for quick pairing and connection. NFC provides a convenient experience for users by enabling data exchange or wireless payments through smartphones or NFC cards. It is commonly utilized in secure payment systems and integration with smart home devices where security is crucial.',
      dot: ''
    },
    {
      head: '3. UWB: Optimal choice in environments requiring precise location measurement',
      subHead: '',
      desc: 'UWB is a technology specialized in precise location measurement and distance measurement using a very wide bandwidth. It supports bidirectional communication and is mainly used in indoor location-based services, car navigation, and industrial environments where precise distance measurement is needed. UWB, in conjunction with sensors, provides real-time location information for objects, making it the preferred choice in situations where more accurate location measurement is essential compared to RFID.',
      dot: ''
    },
    {
      video: video,
      link: '/en/enterprise/manufacturing/asset',
      head: 'Which technology to choose?',
      subHead: '',
      desc: 'When deciding which wireless communication technology to choose for businesses or individuals, consideration of the purpose of use and the environment is essential. If efficient tracking of large quantities of objects is the goal, RFID would be suitable. If smartphone payments or convenient data exchange is the main purpose, NFC is a good choice. In industrial fields requiring precise location measurement, UWB demonstrates excellent performance.<br/><br/>Additionally, factors such as cost, power consumption, and data capacity must be considered for each technology. Overall, combining user requirements with the characteristics of the technology is crucial in finding the optimal wireless communication solution. Considering the physical environment and business objectives will help establish an innovative and effective wireless communication system.',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that helps real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      // Additional product entries can be added here
    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/en/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/en/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/en/enterprise/office/asset'
      }
    ]
  }
};
