import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconInfo, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo4.mp4";

export default {
    
    banner : {
        title : '실시간 재고 관리',
        desc : '제조 시설은 원자재, 반제품 등 수많은 재고를 보유하고 있습니다. 이들을 제대로 관리하는 것은, 시간과 경제적인 측면에서 매우 중요합니다. 즉, 제조 산업의 효율성에 영향을 끼치는 중요한 요소입니다.',

    },

    video : {
        title : '더 정확하고 빠르게 재고를 관리하는 방법.',
        desc : '실시간 위치 추적(RTLS) 기술은 자산의 위치를 실시간으로 알려줍니다. 자산의 위치를 찾는 데 걸리는 시간이 거의 들지 않습니다.<br/>또한, 수량 관리까지 가능하므로 이는 RTLS 를 사용하기 <strong>이전과는 다른 수준의 생산성</strong>을 갖게 됩니다.',
        play : video
    },

    func : [
        {
            title : '재고 위치 추적',
            desc : '실시간으로 재고 위치를 추적하여, 재고 이동이나 회전을 매우 정확하고 효율적으로 관리합니다. 또한 생산 계획과 예측에도 의미 있는 데이터를 제공합니다.',
            sub1 : '높은 정확도 제공',
            icon1 : IconRulerGray,
            sub2 : '빠른 데이터 연동',
            icon2 : IconShareGray,
            video : subVideo1
            
        },
        {
            title : '손실 방지',
            desc : '유실이나 도난을 신속하게 감지하고 조치를 취할 수 있습니다. 또한, 재고가 정확한 위치에 보관되고 정확한 시기에 사용되므로, 재고 손실을 최소화할 수 있습니다.',
            sub1 : '실시간 현황 파악',
            icon1 : IconClockGray,
            sub2 : '높은 정확도 제공',
            icon2 : IconRulerGray,
            video : subVideo2
        },
        {
            title : '회전율 최적화',
            desc : '재고를 효과적으로 배치하고, 재고의 유통 기간을 줄이고, 오래된 재고를 우선 사용하여 작업 회전율을 향상시킬 수 있습니다. 이는 재고 보유 비용을 줄이고 자금 효율성을 높일 수 있습니다.',
            sub1 : '실시간 위치 추적',
            icon1 : IconBinocularsGray,
            sub2 : '통계 데이터 제공',
            icon2 : IconChartBarGray,
            video : subVideo3
        },
        {
            title : '정확한 출하',
            desc : '재고 위치를 신속하게 확인하여 주문에 필요한 제품을 정확한 위치에서 적시에 추출할 수 있으며, 출하 시 제품의 정확성을 보장할 수 있습니다. 이는 주문 처리 시간을 단축하고 오류를 최소화하여 고객 서비스 품질을 향상시킵니다.',
            sub1 : '실시간 현황 파악',
            icon1 : IconClockGray,
            sub2 : '높은 정확도 제공',
            icon2 : IconRulerGray,
            video : subVideo4
        },

    ],



    subfunc : {
        
        title : '다양한 기능',
        desc : '정확한 자산 관리는 낭비되는 시간과 경비를 줄이고 생산성을 높입니다. 오브로는 다양한 기능을 통해 최적화된 제조 환경을 구축하도록 수 있도록 합니다.',
        
        data : [
            {
                icon : IconBinocularsGray,
                title : '재고 예측',
                desc : '유지 비용을 최소화하고 재고 공급의 신속성과 효율성을 향상시킵니다.',
            },
            {
                icon : IconBell,
                title : '실시간 알람 기능',
                desc : '재고에 이상 상황이 발생하는 경우 알람을 보내 즉각적으로 대처할 수 있습니다.',
            },
            {
                icon : IconRoute ,
                title : '운행 경로 조회',
                desc : '재고의 운행 경로를 조회하여 데이터화 할 수 있습니다.',
            },
            {
                icon : IconDashboard,
                title : '현황 파악',
                desc : '현재 특정 재고가 어디로 이동하고 있는지 파악합니다.',
            },
            {
                icon : IconWarning,
                title : '이탈 감지',
                desc : '재고가 현장에서 이탈하거나 잘못된 곳으로 이동하는 것을 감지합니다.',
            },
            {
                icon : IconInfo,
                title : '리스크 관리',
                desc : '재고가 적절한 장소에 안전하게 보관되고 관리됨을 보장할 수 있습니다.',
            },
            {
                icon : IconChainLink,
                title : '데이터 연동',
                desc : 'RFID, IOT, 보안, 영상 등의 시스템과 연결하여 효율적으로 활용할 수 있습니다.',
            },
            {
                icon : IconSecurity,
                title : '데이터 보안',
                desc : '높은 데이터 보안 수준을 유지하여 신뢰성이 높은 솔루션을 제공합니다.',
            },


        ],
    },

    system : {
        title : '시스템 구성',
        img : rtlsSystem
    },

    productTitle : '제품 구성',
    product : [

        {
            tag : 'assetPackage'
        },
        {
            tag : 'workerPackage'
        },
    ],


    contents : [
        {
        data : 'manufacturingProcess'
        },
        {
        data : 'logisticsAsset'
        },
        {
        data : 'blogMedical'
        },
        {
        data : 'blogRTLS'
        },

    ]



}