import React, { useEffect } from "react";
import { StyledCompany } from "./Company.style";
import News from "./components/News/News";
import OurCulture from "./components/OurCulture/OurCulture";
import OurHistory from "./components/OurHistory/OurHistory";
import OurPartners from "./components/OurPartners/OurPartners";
import OurTechnology from "./components/OurTechnology/OurTechnology";
import Visual from "./components/Visual/Visual";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "@pages/i18n";
import SEO from "@pages/SEO";
import seo from "@src/lang/ko/seo";
import seoEng from "@src/lang/en/seo";
import seoJP from "@src/lang/jp/seo";
import seoCN from "@src/lang/cn/seo";
import Youtube from "./components/Youtube/Youtube";


const Company = (): React.ReactElement => {

    const location = useLocation();
    const isEnglishPage = location.pathname.includes('/en/');
    const isJapanPage = location.pathname.includes('/jp/');
    const navigate = useNavigate();
    useEffect(() => {
      if (isEnglishPage) {
        i18n.changeLanguage("en").then(() => {
          navigate("", { replace: true });
        });
      } else if(isJapanPage) {
        i18n.changeLanguage("jp").then(() => {
          navigate("", { replace: true });
          
        });
      } else {
        i18n.changeLanguage("ko").then(() => {
          navigate("", { replace: true });
        });
      }
    }, [location.pathname]);
    

  let seoPath;
  let koreanPage = false;
  if (location.pathname.includes('/en/')) {
    seoPath = seoEng;
  } else if (location.pathname.includes('/jp/')) {
    seoPath = seoJP;
  } else if (location.pathname.includes('/cn/')) {
    seoPath = seoCN;
  } else {
    seoPath = seo;
    koreanPage = true;
  }

  const currentLang = i18n.language;
  const helmetTitle = seoPath.company.title;
  const key = seoPath.company.key;
  const desc = seoPath.company.desc;

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "ORBRO",
    "alternateName": "위치추적과 디지털 트윈 전문 플랫폼, ORBRO",
    "url": "https://orbro.io",

    "address": "#B-1203, 660, Daewangpangyo-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea",
    "email": "orbro@orbro.io",
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+8215229928",
      "contactType": "a sales contact point",
      "areaServed": "KR",
      "availableLanguage": "Korean"
    }],
    "sameAs": "https://kr.linkedin.com/company/kongtech"
  }

  return (
    <StyledCompany>

      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={key}
        ogDesc={desc}
        ogImg="https://orbro.io/images/0105d9c7a8a46d66bf8140bb565f1dbc.png"
        ogUrl={koreanPage ? "https://orbro.io/company" : `https://orbro.io/${currentLang}/company`}
        jsonLd={jsonLd}
      />
      <Visual />
      <Youtube link={koreanPage ? 'https://www.youtube.com/embed/Q1-FaEGAaHM?si=eWj0_UaQQ8qz_c1U' : 'https://www.youtube.com/embed/PL9YbiaYFUk?si=oT97AI_zbL0VrL_j'}/>
      <OurPartners />
      <OurTechnology />
      <OurCulture />
      <OurHistory />
      <News />
    </StyledCompany>
  );
};


export default Company;