import seo from "@src/lang/cn/seo";
import navMenu from "@src/lang/cn/navMenu";
import pricing from "@src/lang/cn/pricing/pricing";

import simple from "@src/lang/cn/simple";
import nav from "@src/lang/cn/nav";
import home from "../lang/cn/main/home/home";
import digitaltwin from "@src/lang/cn/digitaltwin/digitaltwin";

import productSchemeAccess from "@src/lang/cn/main/access/productScheme";

import accessF1 from "../lang/cn/main/access/accessF1";
import accessMain from "../lang/cn/main/access/access";
import iot from "@src/lang/cn/main/iot/iot";
import os from "@src/lang/cn/main/os/os";
import rtls from "@src/lang/cn/main/rtls/rtls";
import camera from "@src/lang/cn/main/camera/camera";
import sensor from "@src/lang/cn/main/sensor/sensor";
import beacon from "@src/lang/cn/main/sensor/sensor";
import integration from "@src/lang/cn/main/integration/integration";

import video from "@src/lang/cn/main/video/video";

import productSchemeRTLS from "@src/lang/cn/main/rtls/productScheme";
import productSchemeCamera from "@src/lang/cn/main/camera/productScheme";

import productImg from "@src/lang/cn/main/product/productImg";
import productPurchase from "@src/lang/cn/main/product/productPurchase";
import productSpec from "@src/lang/cn/main/product/productSpec";
import popupData from "@src/lang/cn/main/product/popupData";

import layout from "../lang/cn/layout.json";
import layoutEnterprise from '../lang/cn/layoutEnterprise.json';


import company from "../lang/cn/company";


//technology
import technology from "../lang/cn/technology/technology";
import uwb from '../lang/cn/technology/uwb';
import ble from "../lang/cn/technology/ble";
import tdoa from "../lang/cn/technology/tdoa";
import twr from "../lang/cn/technology/twr";
import stereo from "../lang/cn/technology/stereo";
import monocular from '../lang/cn/technology/monocular';
import whatisdigitaltwin from '../lang/cn/technology/whatisdigitaltwin';
import whatisgeofence from '../lang/cn/technology/whatisgeofence';

//request
import request from "../lang/cn/request";

import download from '../lang/cn/download';
import serviceRequest from "../lang/cn/serviceRequest.json";


import { MainMenus } from '../layouts/Default/core/model';

import navEntSupport from "@src/lang/cn/navEntSupport";
import navSupport from "@src/lang/cn/navSupport";
import footer from "@src/lang/cn/footer";

import solutionProduct from "@src/lang/cn/enterprise/solutionProduct";
import solutionCommon from "@src/lang/cn/enterprise/solutionCommon";
import solutionContents from "@src/lang/cn/enterprise/solutionContents";

import constructionSafety from "@src/lang/cn/enterprise/construction/constructionSafety";
import constructionAsset from "@src/lang/cn/enterprise/construction/constructionAsset";
import constructionEquipment from "@src/lang/cn/enterprise/construction/constructionEquipment";

import logisticsAsset from "@src/lang/cn/enterprise/logistics/logisticsAsset";
import logisticsSafety from "@src/lang/cn/enterprise/logistics/logisticsSafety";

import officeAsset from "@src/lang/cn/enterprise/office/officeAsset";

import manufacturingProcess from "@src/lang/cn/enterprise/manufacturing/manufacturingProcess";
import manufacturingAsset from "@src/lang/cn/enterprise/manufacturing/manufacturingAsset";

import medicalPatient from "@src/lang/cn/enterprise/medical/medicalPatient";
import medicalWaste from "@src/lang/cn/enterprise/medical/medicalWaste";

import retailExhibition from "@src/lang/cn/enterprise/retail/retailExhibition";


//blog
import blog from '../lang/cn/blog/blog';
import blogUwb from "../lang/cn/blog/blogUwb";
import blogDigitalTwin from "../lang/cn/blog/blogDigitalTwin";
import blogRTLS from "../lang/cn/blog/blogRTLS";

import blogGeofence from "../lang/cn/blog/blogGeofence";
import blogMedical from "../lang/cn/blog/blogMedical";
import blogUwbble from "../lang/cn/blog/blogUwbble";
import blogAirport from "../lang/cn/blog/blogAirport";
import blogManufacture from "../lang/cn/blog/blogManufacture";
import blogActiveRfid from "../lang/cn/blog/blogActiveRfid";

import blogForklift from "../lang/cn/blog/blogForklift";
import blogConsider from "../lang/cn/blog/blogConsider";

import blogOffice from "../lang/cn/blog/blogOffice";
import blogSmartConstruction from "@src/lang/cn/blog/blogSmartConstruction";
import blogSmartLogistics from "@src/lang/cn/blog/blogSmartLogistics";
import blogPatientTracking from "@src/lang/cn/blog/blogPatientTracking";
import blogVisitor from "@src/lang/cn/blog/blogVisitor";
import blogHospital from "@src/lang/cn/blog/blogHospital";
import blogMedicalWaste from "@src/lang/cn/blog/blogMedicalWaste";
import blogLogistics from "@src/lang/cn/blog/blogLogistics";
import blogUwbGeofence from "@src/lang/cn/blog/blogUwbGeofence";
import blogUwbLogistics from "@src/lang/cn/blog/blogUwbLogistics";
import blogLogisticsWorker from "@src/lang/cn/blog/blogLogisticsWorker";
import blogLogisticsAsset from "@src/lang/cn/blog/blogLogisticsAsset";
import blogLogisticsTech from "@src/lang/cn/blog/blogLogisticsTech";
import blogConstructionSafety from "@src/lang/cn/blog/blogConstructionSafety";
import blogHeavyEquipment from "@src/lang/cn/blog/blogHeavyEquipment";
import blogSmartSafety from "@src/lang/cn/blog/blogSmartSafety";
import blogSmartFactory from "@src/lang/cn/blog/blogSmartFactory";
import blogNextManufacturing from "@src/lang/cn/blog/blogNextManufacturing";
import blogDigitalTwin2 from "@src/lang/cn/blog/blogDigitalTwin2";
import blogOfficeSolution from "@src/lang/cn/blog/blogOfficeSolution";
import blogDX from "@src/lang/cn/blog/blogDX";
import blogRtlsHealthcare from "@src/lang/cn/blog/blogRtlsHealthcare";
import blogUwbRtls from "@src/lang/cn/blog/blogUwbRtls";
import blogProductionAutomation from "@src/lang/cn/blog/blogProductionAutomation";
import blogBeacon from "@src/lang/cn/blog/blogBeacon";
import blogRfidNfcUwb from "@src/lang/cn/blog/blogRfidNfcUwb";
import blogRtlsCompany from "@src/lang/cn/blog/blogRtlsCompany";
import blogNfcUwbBle from "@src/lang/cn/blog/blogNfcUwbBle";
import blogRfidRtls from "@src/lang/cn/blog/blogRfidRtls";
import blogConstructionSolution from "@src/lang/cn/blog/blogConstructionSolution";
import blogQaUwb from "@src/lang/cn/blog/blogQaUwb";
import blogDigitalTwinGis from "@src/lang/cn/blog/blogDigitalTwinGis";
import blogDigitalTwinWeather from "@src/lang/cn/blog/blogDigitalTwinWeather";
import blogDigitalTwinBuildings from "@src/lang/cn/blog/blogDigitalTwinBuildings";
import blogDigitalTwinTracking from "@src/lang/cn/blog/blogDigitalTwinTracking";
import blogDigitalTwinTraffic from "@src/lang/cn/blog/blogDigitalTwinTraffic";
import blogDigitalTwinAirplane from "@src/lang/cn/blog/blogDigitalTwinAirplane";
import blogDigitalTwinCCTV from "@src/lang/cn/blog/blogDigitalTwinCCTV";
import blogDigitalTwinDigitalCity from "@src/lang/cn/blog/blogDigitalTwinDigitalCity";
import blogDigitalTwin3 from "@src/lang/cn/blog/blogDigitalTwin3";
import blogBLE from "@src/lang/cn/blog/blogBLE";
import blogWhatIsSmartCity from "@src/lang/cn/blog/blogWhatIsSmartCity";
import blogExampleOfSmartCity from "@src/lang/cn/blog/blogExampleOfSmartCity";
import blogContainerTracking from "@src/lang/cn/blog/blogContainerTracking";
import blogWhatIsDX from "@src/lang/cn/blog/blogWhatIsDX";
import blogRFID from "@src/lang/cn/blog/blogRFID";
import blogNFC from "@src/lang/cn/blog/blogNFC";
import blogNVR from "@src/lang/cn/blog/blogNVR";
import blogSmartFactory2 from "@src/lang/cn/blog/blogSmartFactory2";
import blogLoRa from "@src/lang/cn/blog/blogLoRa";
import blogSmartHospital from "@src/lang/cn/blog/blogSmartHospital";
import blogSmartAirport from "@src/lang/cn/blog/blogSmartAirport";
import blogIPS from "@src/lang/cn/blog/blogIPS";
import blogSmartParking from "@src/lang/cn/blog/blogSmartParking";
import blogSmartPort from "@src/lang/cn/blog/blogSmartPort";
import blogSmartBuildings from "@src/lang/cn/blog/blogSmartBuildings";
import blogIndoorGPS from "@src/lang/cn/blog/blogIndoorGPS";
import blogAiCamera from "@src/lang/cn/blog/blogAiCamera";
import blogAIoT from "@src/lang/cn/blog/blogAIoT";
import blogSmartShipyard from "@src/lang/cn/blog/blogSmartShipyard";



const cn = {
  pricing,
  digitaltwin,
  navMenu,
  constructionSafety,
  constructionAsset,
  constructionEquipment,

  logisticsAsset,
  logisticsSafety,

  manufacturingProcess,
  manufacturingAsset,

  medicalPatient,
  medicalWaste,

  officeAsset,

  retailExhibition,

  solutionProduct,
  solutionCommon,
  solutionContents,

  ///
  home,
  nav,
  simple,
  accessF1,
  accessMain,
  productSchemeAccess,
  iot,
  os,
  rtls,
  camera,
  sensor,
  beacon,
  integration,

  video,

  productSchemeRTLS,
  productSchemeCamera,
  productImg,
  productPurchase,
  productSpec,
  layout,
  navEntSupport,
  navSupport,
  popupData,

  MainMenus,
  footer,
  seo,
  layoutEnterprise,
  layout,


  company,

  technology,
  uwb,
  ble,
  tdoa,
  twr,
  stereo,
  monocular,
  whatisdigitaltwin,
  whatisgeofence,

  request,
  serviceRequest,

  download,

  blog,
  blogUwb,
  blogDigitalTwin,
  blogRTLS,
  blogGeofence,
  blogMedical,
  blogUwbble,
  blogAirport,
  blogManufacture,
  blogActiveRfid,
  blogForklift,
  blogConsider,
  blogOffice,
  blogSmartConstruction,
  blogSmartLogistics,
  blogPatientTracking,
  blogVisitor,
  blogHospital,
  blogMedicalWaste,
  blogUwbGeofence,
  blogLogistics,
  blogUwbLogistics,
  blogLogisticsWorker,
  blogLogisticsAsset,
  blogLogisticsTech,
  blogConstructionSafety,
  blogHeavyEquipment,
  blogSmartSafety,
  blogSmartFactory,
  blogNextManufacturing,
  blogDigitalTwin2,
  blogOfficeSolution,
  blogDX,
  blogRtlsHealthcare,
  blogUwbRtls,
  blogProductionAutomation,
  blogBeacon,
  blogRfidNfcUwb,
  blogRtlsCompany,
  blogNfcUwbBle,
  blogRfidRtls,
  blogConstructionSolution,
  blogQaUwb,
  blogDigitalTwinGis,
  blogDigitalTwinWeather,
  blogDigitalTwinBuildings,
  blogDigitalTwinTracking,
  blogDigitalTwinTraffic,
  blogDigitalTwinAirplane,
  blogDigitalTwinCCTV,
  blogDigitalTwinDigitalCity,
  blogDigitalTwin3,
  blogBLE,
  blogWhatIsSmartCity,
  blogExampleOfSmartCity,
  blogContainerTracking,
  blogWhatIsDX,
  blogRFID,
  blogNFC,
  blogNVR,
  blogSmartFactory2,
  blogLoRa,
  blogSmartHospital,
  blogSmartAirport,
  blogIPS,
  blogSmartParking,
  blogSmartPort,
  blogSmartBuildings,
  blogIndoorGPS,
  blogAiCamera,
  blogAIoT,
  blogSmartShipyard


};

export default cn;

