import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../../jp/main/product/productImg";
import productPurchase from "../../jp/main/product/productPurchase";

export default {

    
  helmet : '| 患者管理のためのリアルタイム位置追跡',
  desc : '医療スタッフが患者の現在の位置をリアルタイムで把握し、緊急事態に迅速に対応し、治療や監視に必要なリソースを効率的に割り当てることを支援します。これにより、患者の安全性を高め、医療サービスの品質を向上させることができます。',
  keywords : '患者位置追跡、患者管理、病院位置追跡、病院運営、スマート病院、患者追跡、医療施設管理、認知症患者管理、アルツハイマー患者管理、高齢者患者管理、病院運用、医療センター、介護施設管理',

  ogTitle : '',
  ogDesc : '',
  ogSite : '患者管理のためのリアルタイム位置追跡 :: ORBRO ブログ',
  ogUrl : 'https://orbro.io/jp/blog/patientTracking',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4NuWirMGmbqpgIlWdCqK-ppWpzcgz_tlLlZI_o-5cHhj2j9qDRwsnEZiV8vOrMOlgGbh_KwwuV__dIaSBb4XYZB1HeJm3biM2fWsJG8fIJeS5V5kdQb1dPsENNDJZ_zA8YJEqLbd9oYhe7xJup9Cj0li6ASKbfdFzgjVSOv5nw9S1bq5HSdb3F-X1xE3Y3aAkqV1dkKqwPbFh5k9umUyucZ_TuNYaj2QcdjjdzsQfPpPJXnLHTYvP2jf2R80k52qNHygEEDSAP6if-9V8fj-RaWQ22wE13LmgV9GSECNTS9vVpS30mTAIwxI-GeD6m0J4Mu2OMwiArtjZQnqvHYKGzFumN9bqIchR5DBOue8f47fUqqH35eeZjPT6KFT0ykHX1UPgLuqhlouyD2V96TnHrcJjuoV6gj6n3qsfMYqj_-FLcJuy8biYIsFkBSzapyu4nuPfGB4aLMes_jDVehCX4VDdMVe5MlkWAXUqGZXvn_gsL8xrwcCn1wzGqA6pabPD-4W8aub76Rgsw4-Pch-5O3UQHuUkBEWDGzlYA3bmGZtL0It0WuJ2YrRICpcphRc-dkcsUpSMFFkRAs9wKE0iY_n-XdLq2SBW-p1sVPddxJuHi6s8sBXGYlI018XAOQfYkZ9iKUglZYhiwvfWTrp_MD_-xKSG5hRwPBRclRrIuqtQhCdmyLiG9PFc-k8Zzc0nrx9oLYcMOfOoCpKa3uNflVWy8JQF1jg6SWc_6IAzN3iex6DH7MiS1gjTRkd06IoN1ak0BjLij5u6JZa_2NoOCImCsjTG-saSx89XwTjolpf-0NeYZ7yC_7sHsUcLpFKtSoMNA2IMi1mfRtuPDpiuxiJISZ8WKPBm3HBfZqWs5D0MFWFd6HxrPotsmJoWiLBbJEbzcj6Q2r9zqygWKXnw12Kdv9WXPrOqSt3HJlmvd9u76idLGomybRZRJ_8z4q6IkYJwFotHED3XrmnxcD7076DgXVuhiRpSPI0w19qp6wccBXjOE2ySKWzLJO3NVFRXyi2YWpdZSKtZ34ZkHDDkhR29p6v4DI7wAxSn9oBNOXRdS83jsK8rE5DUHxS6GQjjgMhF08vjMlay-RB94xolSLw9nmiBfsmmCqHGEIReH-PBxHd6yEYhqR0V5YHFcAT6qUla5YiV4-CDftqlCmwRMuckmyx0d34NbvAUxWomI_-_8A8GwUdrcmAE5me-0RJYcrPd-McYwnI9Jr7_k8vQ-cUChbrrreLjukSuF5n93kEUvM6c9dUYGIZMnXLjjAMAYtg2Xkvf0_Iko5BlcvHIH4XZxUXOhxNZA3pvpgHI7OOt9GtIiSv6JkP1nr1VtFiy8MvV2LM19_SAcsitsu3Kh92FS8lUnTyo7PiQxxKmZTngLuMJToXuzVsXIqr6GuWEPzDBIyKZ7ti7MT57s-koDdp147Lx1bHkrwSIZ9PwC0MhHfJSS7hOUhyhSO-imUKDGcKUGAulSr_jiKtjiKlAbldoULjuMFSJeEM9OZ6Th9AbTNrgF0Br4Cj7MKqqqQJSJj2PE2hlGPBzFLo-qBP4x0ed_IHQB_8_czj6rpvIBt0YE=w2276-h1147',
  ogKey : '',
  ogType : 'ウェブサイト',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '患者管理のためのリアルタイム位置追跡',
  date : '2023-08-07',
  tag1 : '#患者管理',
  tag2 : '#患者位置追跡',
  tag3 : '#スマート病院',
  tag4 : '#患者安全',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'病院などの医療施設は、（当然ながら）患者が最も集まる場所でしょう。そのため、他の産業よりも高度な安全保障システムが必要です。これを違反することは、単なるビジネスの損失を超えて個々の生命や死を決定する重要な問題です。同様に、患者や介護者としても、より安全な医療施設で医療サービスを受けたいと思います。言い換えれば、お互いのニーズを満たす方法は、より安全な医療施設を作ることだと言えるでしょう。<br/><br/>キーは患者です。患者の状態をリアルタイムでモニタリングし、事故を検出し、予防できれば、より迅速な対応が可能になります。<br/>リアルタイム位置追跡システム（RTLS）を基に、安定したシステムを構築することができます。以下で可能なことを見てみましょう。',
      dot : ''
      
    },
    {
      head : 'リアルタイム位置追跡',
      subHead :'',
      desc : '医療施設内での患者の位置をリアルタイムで追跡できます。特定の患者の位置を迅速に特定する必要がある場合、検索を通じて非常に迅速に行うことができます。特にアルツハイマー患者など、より注意が必要な患者に対して効果的です。患者だけでなく、施設内で迷子になる可能性のある来訪者の位置を特定したり、病室で診察が予約されている患者と医療スタッフの位置をリアルタイムで追跡して無駄なルートを回避したりすることもできます。',
      dot : ''
      
    },
    {
      head : '転倒検出',
      subHead :'',
      desc : '移動が不便な患者や関節の問題を抱える患者にとって、転倒事故は致命的な場合があります。これをリアルタイムで検出して、直ちに医療スタッフを呼び出せれば、より大きな事故を防ぐことができます。',
      dot : ''
      
    },
    {
      head : '感染症調査',
      subHead :'',
      desc : 'COVID-19などの致命的な感染症に対して迅速な予防対応が不可欠です。これを実現するためには、感染の疑いがある患者を特定し、そのためには接触した人々を追跡する必要があります。リアルタイム位置追跡システムは、患者のリアルタイムの位置だけでなく、施設内での移動履歴も記録します。これにより、迅速かつ正確な感染症調査が可能であり、迅速な接触者の特定が可能となります。',
      dot : ''
      
    },
    {
      head : '混雑制御',
      subHead :'',
      desc : 'ビジネスの成長は効率に基づいています。そして、消費者（患者）もこれを非常によく理解しています。医療予約の遅延や施設の混雑は、ネガティブな経験につながり、信頼を損なう可能性があります。<br/>リアルタイム位置追跡システムは、施設の混雑状況を評価し、ボトルネックを制御し、スムーズな施設運用を維持することができます。',
      dot : ''
      
    },
    {
      head : '安全区域',
      subHead :'',
      desc : '薬剤、医療機器、廃棄物など、医療施設は特別な管理が必要な資産が多数存在します。窃盗、事故、紛失を防ぐために、これらのエリアを安全区域として指定することができます。無許可の外部者がこれらのエリアに入ると、即座に管理者にアラートが送信されるようになっています。',
      dot : ''
      
    },
    {
      head : 'その他の機能',
      subHead :'',
      desc : 'リアルタイム位置追跡システムは人だけに限定されません。あらゆる種類の資産を追跡できます。医療廃棄物や医療機器など、継続的な管理と監視が必要な資産もリアルタイムで追跡することができます。',
      dot : ''
      
    },
    {
      head : '結論',
      subHead :'',
      desc : '多くの産業がそうであるように、医療施設は患者が医療サービスを選ぶ際にさまざまな要因を考慮します。これらの要因の中でも、「信頼」は最も重要と言えるでしょう。<br/>そのため、リアルタイム位置追跡システム（RTLS）は、現在の時点で最も進化した医療施設サービスを構築するための解決策と言えるでしょう。',
      dot : ''
      
    },

  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : medicalPatient.thumb,
        data : 'medicalPatient',
        link : '/jp/enterprise/medical/patient'
      }, 
      {
        img : medicalWaste.thumb,
        data : 'medicalWaste',
        link : '/jp/enterprise/medical/waste'
      },
      {
        img : officeAsset.thumb,
        data : 'officeAsset',
        link : '/jp/enterprise/office/asset'
      }
    ]

  }


}
