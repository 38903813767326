import {  medicalPatient, medicalWaste, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {


  helmet: '| NFC 태그를 대체할 UWB와 BLE를 활용한 재고관리',
  desc: 'NFC 태그가 아닌 UWB, BLE를 활용하여 재고 관리 시스템을 훨씬 스마트하고 편리하게 이용할 수 있습니다. 바로 도입 가능한 솔루션을 확인해보세요.',
  keywords: 'nfc, uwb, 무선 통신, 위치 추적, 물체 위치 추적, 사람 위치 추적, 태그, 비콘, rtls, orbro, 오브로, 콩테크',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'NFC 태그를 대체할 UWB와 BLE를 활용한 재고관리 :: 오브로 블로그',
  ogUrl: 'https://orbro.io/blog/nfc-uwb-ble',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDwxW_WSF_F7yNjIa07OkmVhKArBLWz8-_J1moJsQci7Ije4YbYRD5Oa0A3rsNOuVWMRMVKmnI6q7dJm894FnMM4irt8-NIgSx08LF7IIza4ONna0iwlNNedic3hlVYbDPl7746HMAAhJMopboCbqV9PlElgpHpCuSsWKAWobQKY1JrFwdltqWl5FsJqgC4DiIaxM9NUQNJHd4Tt2bOO1lPabNGc27n5QShIyfFcNuGdpozghgJAoszlfuiyD5CVSXKJOXYsfrj1Dhk0cbYrU_RHZfxcle6uATv2tw81Rq5eyY-OCCnNI9WQYLcp5PeiRUydm3S0Ba1eR0DQF-dHz8AvSGqnC2liVRiO-p1TSfr_dtXdAJcEDMSC1_zz2O2dksO3KqdbnvRkpDU-xKCqeel5YaHVAxFFOt19k4SHGVc493DTVSksZhLeWJPTBllKX8oc5zt5qErJMjPZWMUZ2iOr-geb_9fVyyuZogAAj-tFvkAF-mqBapjOwe_z2_8caQhLzg6vnNK3OSmDHQMOLUyZT22pB-OBf0QWVA-9wzMUyIo6BZbUXSj8n0bd6Hq72hFlNnNKwDe2aHoam2KguWNLfBx3PFqYkeO3fbdBRoc8vGkW_FcogVpvOusbERHosgwDP6T-foORllJuVUPjKZyx3CuilLxfRO-fo-4JF0yB8zqC-WZUHzpzyYTN5LZk0nln7wCJNPlMuRtxzJNq_4KrjorS6SdzMmpuYJog9QlrLImyeiR3wOLLvc2bklRyoEQ5T-DcDttw6dzFt1jFu8BjtXKIJ9KCc5PfIgl4XB2T5LshMXFDw7HU2qoFfYHvyQciti_HH59bUaP67x0KwbzAFSnMyP8edq6y4Wsvvhrl5cvYepph4sK7Dn5zkeP1Pv9qa5wNbYn--ZEAND3870LLFBqN5ttPfpxijnKszom0HGZG9G1EGUcU3GeMyAKg5Un-dXh7mWayLTFjU2UIBCRzpU5w509ZaRDokT94aFEyudm035LQQWLoRIV6TInKZ-LaBi6LNpXrykfPgmZb97nPCdULyDlhpp7oYwDLN7wgNy6b0-Wn5Tj9sl8qVTs1o_LrAT-8tl2UZ-uM9MPzHVKPmpPtnDymJklgsbMOp3TK45pIQ9voLx_ESS7YM1S2gw2ihX-Kvs3PqdKmxJ8y6LU8Op4OdaAeuWY1evq4apVEly7tAVqx7Gh5EiLl7PdZnpX1PiwLiz4WnDK0WLV_YOPYQlouBLWy7ojtfLu_C5UjgrjyKBg71KnsCv680b2sBcB-p2Wt6aD6IFtfAlT5J5nzg_cW01H3nwcRUKJdwKsVN-6_vVbyyBX8WGa4yR00BKZ0_scjPlWgID-43QPnz5X6P0IHyJUKtyUTiZM9a5gV7_Raq8rjHTOwYAR4r8je2xkZBJ_jSbV2Dk5yn6wEfK7XhzhLDCOrIoZdOCMhy-Qh610uh34OOtAoLyPBLfWcF_RXsMAGfz73LPe3WJx9YjYMPmRu-dvPHRiBlNrBU2OrvZN1lW3XYjKgGHTt7QlAKY2D5XpDjGA8NsfGVcpLghIXt-HCy32YCFiUdpQr5rq6mf7hfmJr=w1871-h1190',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'NFC 태그를 대체할 UWB와 BLE를 활용한 재고관리',
  date: '2023-11-20',
  tag1: '#nfc',
  tag2: '#uwb',
  tag3: '#ble',
  tag4: '#재고관리',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: '기업의 생산 및 유통 활동에서 핵심적인 역할을 하는 재고관리는 정확하고 효율적인 시스템이 요구되고 있습니다. 이에 따라 RFID와 NFC 태그가 활발히 사용되어 왔습니다. 그러나 최근에는 UWB(Ultra-Wideband)와 BLE(Bluetooth Low Energy) 기술이 등장함으로써 더 나은 재고관리 시스템이 가능해졌습니다. 특히, NFC 태그를 대체하는 UWB와 BLE의 활용이 주목받고 있습니다.',
      dot: ''
    },
    {
      video : video,
      link : '/enterprise/manufacturing/asset',
      head: 'UWB와 BLE의 특징',
      subHead: '1. UWB (Ultra-Wideband)',
      desc: 'UWB는 매우 넓은 대역폭을 사용하여 정밀한 위치 추적이 가능한 무선 통신 기술입니다. 고정밀의 위치 데이터를 제공하므로 실시간 재고 추적 및 위치 파악에 우수한 성능을 발휘합니다. 또한, 다중 경로 간섭을 피하면서 안정적인 통신을 제공하여 정확한 데이터 수집이 가능합니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '2. BLE (Bluetooth Low Energy)',
      desc: 'BLE는 에너지 소모가 낮은 블루투스 기술로, 저전력 장치 간의 효율적인 데이터 통신에 적합합니다. BLE 태그는 소형이며 배터리 수명이 길어 에너지 효율적인 재고 추적 시스템을 구축할 수 있습니다. 또한, BLE는 스마트폰과의 호환성이 높아 사용이 편리합니다.',
      dot: ''
    },
    {
      head: 'NFC 태그 대체의 이점',
      subHead: '1. 정확한 위치 추적',
      desc: 'UWB 기술은 매우 정확한 위치 정보를 제공하므로 물류창고나 생산라인에서 물품의 실시간 위치를 정확하게 파악할 수 있습니다. 이는 재고 관리의 정확성을 향상시키며, 재고 손실 및 오류를 최소화합니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '2. 저전력 운영',
      desc: 'BLE의 저전력 특성은 장기간 동안 태그의 운영을 가능케 합니다. 이는 배터리 교체 주기를 늘리고 유지보수 비용을 절감할 수 있는 장점으로 이어집니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '3. 높은 호환성',
      desc: 'BLE는 많은 디바이스에서 기본적으로 지원되므로, 기존의 인프라와 쉽게 통합될 수 있습니다. 특히, 스마트폰과의 호환성은 무선 재고 추적에 있어서 편리함을 제공합니다.',
      dot: ''
    },
    {
      head: '구현 방안',
      subHead: '1. UWB 및 BLE 태그 도입',
      desc: '기존의 NFC 태그를 UWB 및 BLE 태그로 교체하는 것이 첫 번째 단계입니다. 이를 통해 정밀한 위치 추적과 저전력 운영의 이점을 확보할 수 있습니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '2. 데이터 플랫폼 구축',
      desc: 'UWB와 BLE에서 수집된 데이터를 효과적으로 관리하기 위해서는 효율적인 데이터 플랫폼이 필요합니다. 클라우드 기반의 데이터 저장 및 분석 플랫폼을 도입하여 데이터의 실시간 처리 및 분석이 가능하도록 합니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '3. 시스템 통합',
      desc: 'UWB와 BLE를 사용하는 재고관리 시스템을 기존 시스템에 통합합니다. 이를 통해 모든 데이터가 중앙에서 통합되어 관리됨으로써 생산성과 효율성을 높일 수 있습니다.',
      dot: ''
    },
    {
      head: '마무리',
      subHead: '',
      desc: 'NFC 태그를 대체하는 UWB와 BLE를 활용한 재고관리 시스템은 정확성, 저전력 운영, 호환성 등 다양한 이점을 제공합니다. 기업은 이러한 기술의 도입을 통해 더욱 효율적이고 스마트한 재고관리 체계를 구축할 수 있을 것입니다. 앞으로 더 많은 기업들이 이러한 혁신적인 기술을 도입하여 경쟁력을 확보할 것으로 기대됩니다.',
      dot: ''
    },

  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/enterprise/office/asset'
      }
    ]

  }


}