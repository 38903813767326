import { accessAuto1, accessAutoMain, accessPro1, accessProMain, accesscontroller1, accesscontrollerMain } from "@assets/images/main/access/product";
import { assetTracking1, workerTracking1 } from "@assets/images/main/rtls/product";

export default {

    specTitle : {

    },

    specData : [{
      data: [
        {title : "製品名"},
        {text1: "モバイルアクセス"},
        {text2: "自動ドア出入り"},
        {text3: "顔認識出入り"},
      ],

    },
    {
      data: [
        {text: "説明"},
        {text: "モバイルアプリで出入り口を制御する非常に簡単な機器"},
        {text: "アプリ実行なしで便利なアクセスが可能な機器"},
        {text: "顔認識,NFCカードなど様々な出入りを一度に使用できる機器"},
      ],

    },
    {
      data: [
        {text: "電源供給"},
        {text: "バッテリー使用(最大3年使用)"},
        {text : "DC 12V, 24V "},
        {text : "DC 12V, 3A"},
      ],

    }

    ],

    a: [
      {text: "製品名"},
      {text: "説明"},
      {text: "ブラケット"},
      {text: "精密度"},
      {text: "通信方式"},
      {text: "タグ端末電源"},
      {text: "タグ端末使用期間"},
      {text: "インストールサポート"},
      {text: "ヒットマップ"},
      {text: "ブラケットの使用"},
      {text: "タグ端末検索"},
      {text: "動線測位"}

    ],


    b: [
      {text: "Bluetooth(BLE)位置測位"},
      {text: "BLEタグ端末を資産として監視または追跡したい対象に取り付けて、リアルタイムの位置追跡が可能です。"},
      {text: "天井,レールなど"},
      {text: "最大10m"},
      {text : "BLE"},
      {text : "CR 2430"},
      {text: "最大1年"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : ""},

    ],


    c: [
      {text: "高精度UWB位置追跡"},
      {text: "UWBタグ端末を資産として監視または追跡したい対象に取り付けて、リアルタイムの位置追跡が可能です。"},
      {text: "天井,レールなど"},
      {text: "最大10cm"},
      {text : "UWB"},
      {text : "USB - C"},
      {text: "最大2週間"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},

    ],
    
    
    spec: [
      
      {

      },

      {
        image : assetTracking1,

      },


      {
        image : workerTracking1,

      },
      
        
    ],
    
    
}

