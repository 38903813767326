import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const BeaconPage = styled.div`
    padding : 128px 0 64px 0;
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        padding : 96px 0 64px 0;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 64px 0 64px 0;
    }

`;

export const Layout = styled.div`
    display : grid;
    gap : 32px;
    max-width : 100%;
    grid-template-columns : repeat(3, 1fr);

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        display : flex;
        flex-direction : column;
        gap : 16px;
    }
`;



export const NormalCard = styled.div<{w : number, img : string}>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;
    border-radius: 16px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));

    ${({w})=> w && `max-width : calc((1 / 3) * (${w}px - 64px));`}
    ${({img})=> img && `background-image : url(${img});`}
    padding : 24px;

    aspect-ratio : 389 / 440 ;
    display : flex;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        ${({w})=> w && `max-width : calc((1 / 3) * (${w}px - 48px));`}
        aspect-ratio : 288 / 400 ;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 24px;
        width : 100%;
        max-width : 100%;
        aspect-ratio : 361 / 440 ;
    }
`;

