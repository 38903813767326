import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

 
  helmet : '',
  desc:'医療業界では、RTLS（Real-Time Location System）により、患者と医療機器の位置をリアルタイムで追跡することができ、医療スタッフが効率的に患者を監視し、医療施設内の機器や資産を効率的に管理することが可能です。',
  keywords:'RTLS、室内の位置測位、室内のナビゲーション、位置測位ソリューション、リアルタイム位置測位、資産の測位、スマートオフィス、GPS、室内の位置測位ソリューション',

  ogTitle:'',
  ogDesc:'',
  ogSite:'医療産業におけるRTLS :: ・オブ・でブログ',

  ogUrl : 'https://orbro.io/jp/blog/medical',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZJaHlD_-JbEbsseg3VyR_yeMr1aCExLMWJX8T8GLTArDRbIStI6J1-0hax0bG6__oOWgnHyGPyvarcGsI-N_BiKN4cYDhs_2bM3hfycDf5l2N2df2hsBc0jlAcY_dysEr70kglQvb_fo8sUaG0ru2JfDJeZrPF1XghQ_RRBkDJ875-CCv9sf1V7SL2EOv-Zr3zfuUc1K9zWPyudhy3aV8sFP-CDO_Qqs7eKhqpYGZkwen7a5j5SWZ2uDUyfeILxjUiinPVpzoKRFiaCam-AsJ7r0faFjUPz2abau-VuB1gD0U6O4cMGZaiu341Tl8PyFCJZzL-sycERRlTXmvO3wqGjoUICFGSMkpkPJSlrbS4iCx6f7-_DAOOHNpbz-RXyE0tUVk1WR3rWbn4iR_bZJGICZ7--JH4t4OcYVCbvdUVgJnPN9j_9UuKRrCK0D5cz0QERE9Er6fTw0uTL7GdSOJozzq9i47R3TYtPO2hncXh4cYmM8UmIXwWgC4FGSP5kiyKNDziO3A_aZEg2sRvVSjzigCb73mkjswlIaJfO4LWJ258zbFwf7ft9F58M5Kfq2KHjAIV8o1svKZ_1llrr1owK8S7Fm-XHJMfU5Cuo2_n5agb6t-HF1N8DvZKyT0ylqZwBz2y4zmnt0my0cXuy_NrsnxEKPg_CVIITQoNOROTq4rEfnPyXFHsDF6Gh7-wmgOBRgbUR9TtVI7P9lP0LBU0puSoStWWW4DTJkuXmVDz4sM5j6yOGUzpq03SDL-QhpyZpq1U4wI1UfmhzOYlzYLcwJpl0u6jf44FDboZF9Iz4V9tVW86AwrI5ov_Rm_iImhjZgpIVtofL67UnvaJsSxCT_2PIZVD6X-3X2YcQ___BetQ_lTyYWhywPoI5WLoBJjEPyUwq_rFOTbCKJcFhgV-i49op1zkeCdPZtah3rOgCSo95wbPoAwaPv3d5VhDlDAfSdCLNzr6VBos3Ym9La7xU9GNLYIHwb--0ALdXL4GcV-nEhqrhBs46_fI6e3QMKwYPZHHUyniul-H7E7-Rbio-WbQC-J8SHO61CD-qiZWTZS_6jcbLTeKC20s6APzZmv91lV5viIWzkRQy_MfPgob9ft0G8Rinq1ywzZ5FGWlXeqbqqLDImJHcv5sbMbOSpdAftb4VMO7y8WHbQmKip38OP8tzNobikPBk1nibKhAgHp_ppJHlCxiW7EqBkXRY4D4dDcj-hwLusPNlh4LNdrxge7Z36lSWGIwzkpAaKiMAcssNMhsS9z4iS98Mg87bdJQT4x1tdmIocIxu6voXK2foTf-jVxbfCzhbDoD8brxsCj7nNytwO1wfQXwq2NC8FFG8Fma_SYpSTAfBOEFzt_SbKRO8usqLbqfCHIu5hs3tyUAaGOkFUUbhf8h2VGlgJKyEEwN9MKct5KqEVU9tPm0i5COlqkbDRj5PQy3pBKG9Ryuxe0C8lWW7cNyf7ARG6xGAvhoyXYjwV-gy-hSJpzs2ZxtJWB5KuYwjiHBbTNGpGFkqI89Z2AS379KYEV19-8MdLEab5Pgk2ml3sc0EFAq4pU-9Hbf9E6n2Ku3wAYJiw=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title:'医療産業におけるRTLS',
  date:'2023-05-04',
  tag1:'#RTLS',
  tag2:'#医療',
  tag3:'#資産管理',
  tag4:'#資産測位',

  
  // <br/>
  text : [
    {
      head:"医療産業におけるRTLS",
      subHead :'',
      desc:"コロナパンデミックを経験し、医療産業において発展したIT技術はさらに重要視されています。<br/>特に患者を測位·管理することが技術の核心といえます。<br/>RTLS、リアルタイムロケーションシステムと呼ばれるこの技術は、将来多くの医療産業全般にわたって多様に活用され、今よりはるかに良いレベルのサービスを提供できるよう支援します。",
      dot : ''
      
    },
    {
      head : '',
      subHead:"RTLSとは何ですか？",
      desc:`医療分野におけるリアルタイムロケーションシステム(RTLS)は、あらゆるタイプの患者治療環境内で医療機器、従業員および患者をリアルタイムで測位および管理するために使用されるシステムです。 一種の病院用の"室内GPS"と考えられます。 しかし、単に資産を探すだけでなく、他の医療ITソリューションと簡単に統合される正確な位置把握技術により、施設は作業の流れを改善し、コストを削減し、臨床品質を高めることができます。 <br/>RTLSソリューションには、一般的に患者、従業員、または機器など、さまざまな資産に取り付けられる位置センサが含まれます。 システムは固有IDを使用してタグを見つけ、施設内の位置に関するリアルタイム情報を提供できます。`,
      dot :''
    },
    {
      head : '',
      subHead:'何ができますか？',
      desc:"病院内の機器エラー、感染または物理的攻撃を含むリスクは、医療施設内で絶え間ない脅威です。 リアルタイムロケーション システムは、そのようなリスクの余地を減らし、患者の安全を促進します。 <br/>たとえば、しばしば病院では感染した医療機器を洗浄せずに再使用して医療事故が発生することもあります。 <br/>このような人的ミスを減らすためにRTLSソリューションは解答を提供します。",
      dot : {
        a:'• 感染した患者または人と接触した職員と患者を把握し、これに基づいて接触測位報告書を作成することができます。 コロナのような感染性が高い疾患の場合、先制措置が可能です。',
        b:'• 汚染された機器は洗浄しなければならない時期を直ちに把握し、可能な感染の危険性について直ちに通知します。 また、該当装備をはじめとする病院内の医療機器の位置をモニタリングし、それらを統制·管理できるよう管理者に情報を提供します。',
        c:"• 故障が発生した機器またはメンテナンスが必要な時点になった機器に限り、これを管理することができます。",
        d:"• 医師、看護師をはじめとする労働者と患者に応急状況や身体的脅威が発生した場合、速やかに助けを求めることができます。",
        e:'• 患者が行ってはならない区域で彷徨う場合、これをリアルタイムで把握して対処することができます。 または、ジオフェンシング技術を使用して自動的に通知を鳴らすこともできます。 これは、特に認知症やアルツハイマー病の患者の世話をするときに重要です。 ',
        f:"• 警告及びセキュリティシステム、ジオフェンシング技術をはじめとするRTLS技術を使用して応急患者や新生児など特に脆弱な対象を保護·管理することができます。",
      }
    },
    {
      head : '',
      subHead:"何がいいですか？",
      desc:"RTLS技術は安全において特に高い効用性を発揮しますが、その他の経済的側面とサービス運営面でも大いに役立ちます。 <br/>効率的な装備管理により漏水コストを削減でき、機器事故による費用も防ぐことができます。<br/><br/>運営者の立場ではモニタリングされる情報を収集して分析することができます。病院で活用される装備の使用状況と活用度を把握し、患者が病院に滞在したり、建物内で歩き回る特定地域を把握することができます。<br/>また病院に患者と待機者が増え、建物内にボトルネック現象が生じれば、これを解消することができます。 特定の時間帯に待ち時間が長くなりすぎる場合、円滑な運営のために措置を取ることができ、これにより多くの人々により良い経験とより高い満足度を保障します。",
      dot : ''

    },
  ],


  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/jp/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/jp/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/jp/enterprise/logistics/asset'
      }
    ]

  }

}