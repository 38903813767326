import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet: '',
  desc: `RTLS解决方案是促进第四产业时代商业成长的优秀选择。 因此，选择正确的RTLS解决方案非常重要。 以下是在选择RTLS解决方案时，需要考虑的事项。`,
  keywords: 'RTLS，第四次产业革命，事业成长，定位，室内定位',

  ogTitle: '',
  ogDesc: '',
  ogSite: '选择RTLS解决方案时需要考虑的事项 :: Orbro Blog',

  ogUrl: 'https://orbro.io/cn/blog/consider',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EOZcwlDQZU5H5qnmKjPCdFPeFV8_CDJMRUQZgpa1f1gRZkrk-n6YpwPszumPmKOPJnJFIlJShKqh_Q5wFcv3gmUpnEWmtobQ0b3vFCWKOqFl_3v4hkpS_t9J8Xii17KJEpKeSLt8T_ieoIYrWTgkSw35wq4vyGsWME_m4RNxlrtv45F_VObAxOwJ2GUEWq1wC4Bs4iSvdnNVnw7beJQ6VDOIpKyialic3nfvYBuzj7Cg7a1ZYNEz91Gw4ifCkDL5XMf6yIg9It_aR4VGT_qnUW5E1tAjOeYZvjX16A8i_6CapXmgXxT18PWHgjLwU5B7aLKeBiLI4OfYkeru88hyuTajrjyWXAMAexmkeM5hK9gE6163DFPgr7dn3EpQnHhwvl537Gw-aeQAgEoMXoKNNfjmhnoGbjBur1yDSA81JEh2fujJMtlIwoVQQYgeHQhvqh5ZbNAc1kJWx3u8Vu85TMLDudxXtCmkAAsJpEnKsK8H0f8FOKZnAnWvHCjZOfyaQVDCFqL5G-NBcjQl5f_XbGdizmytaouj5Qh7_bD-oWnmEzXhhIzW47zMmDei8tGOR6Mf3tj2Yc7rgoIV5u6sM-u7rkrwtIUq3ZDcLi4bjM3CI0nPMqFQKAnnSK3WuDHZi5gI7lgg4Z2fpGM446qKEFp7RlMo40wdQ3dDGM4onXDAOn8nsbvAD8RpJY6Oh88j_CclPLbtwl8MgE2mMC0lq3t9fPP6FKz1rc894KGlbpYh-CxCrSHow-eGoM5WzKfGCDI45s0Pd304BDuBsizABdkcHVyUuLIefrfFdm1xB4Dx42ostYrLcccYZbim5zxXfiDXFHxeydWnEaR_3JlR0yLvJ1YxiuPWEQJ39vr6QFMQh6vOM9-hIG5V2W9tZl1BldMwlHpqfXqfHX8QxTHXYKNVJ6okDuOqQBSoXQNnZyi45gSvhXcAFGXDnHVj07MF4L6OTTd9iGg8xBCKBnlB4glo7twHHpVLj2LTKNkQQoNsDTtWsTCqPeXsAGgwshNgiKy-SEHSPR4kzyBhzgs8mhVNGvnAKUDr_p9iOH56Ng_3rWMKm9eF2wubbi5ffi4M6O5SnQYfTqYo9ZN0fBibaVoDzfSxXS6sBZGsfWbmgHxVi5Nsmq6D-HCo6yfecXsHiCemyaUS2VxqGt6Sxq_uN4FeFNCeOUUXfUro_qyrrs8cnSxmC3jetAkdPXpA_KIukOkttU1TDuvHOKiRRd48YccqjhRT1LRh5iIRBYiowrDQi7ewnWTYajwzWPUqm7ygPvZR9qYdK2l76JMFxA8Ucznef6UP-ApuWqRaYpANDZYzkoussTQhGn2mtDmbH41EMoGLY7a8G9r9ghA4YWVFM9hb3U2YLujjDmKFRnntRhskbgxpOK01aYvoDSGH5iKKj3K6ggNfC9QqvCX31JrrJadskNS_1lsqfgZYTVRlQ4-Vhx48TXtAo-rpwhyFWECUbGgjVzThWzmNQheWVWbRH1WoNx5nnnL6skFUK2y-QqdTJPm_XtkUqwkblpJvRGIxrV53uYlfug204LpUQfGgcIRn5zCsmN7KyyQ9ANKRMygJmQ=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: `选择RTLS解决方案时需要考虑的事项`,
  date: '2023-05-19',
  tag1: '#RTLS',
  tag2: `#第四次工业革命`,
  tag3: "#事业成长",
  tag4: "#室内定位",

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: '第四次工业革命（Industry 4.0）的到来，带来了产品制造、改进和分销方式的巨大变革。<br/>在其中处于领先地位的许多公司正在采用RTLS作为新的解决方案。 近年来，这项技术以非常快的速度迅速增长，并在许多服务中发挥着关键作用。',
      dot: ''
    },
    {
      head: 'RTLS是什么？',
      subHead: '',
      desc: 'RTLS是实时定位系统（Real-Time Location System）的缩写。利用RTLS技术收集的真实世界数据可以用于创建虚拟地图。然后，这些系统会将数据处理为易于理解的形式，并向管理者提供有意义的数据。',
      dot: ''
    },
    {
      head: 'RTLS的优势',
      subHead: '',
      desc: '建立RTLS解决方案的企业可以获得比其他企业更多的优势。<br/>他们可以开发员工分配、库存管理和追踪、安全协议等功能。此外，它们还可以与其他物联网设备通信，从而有效实施各种服务。<br/>RTLS还可以在零售、酒店和医疗等各种行业中得到应用。例如，医院可以利用RTLS追踪员工的位置、床位的可用性以及医疗设备或药物的位置。<br/><br/>这些广泛的应用将RTLS变成了一个快速增长的新市场。<br/>根据＂futuremarketinsights＂的预测，RTLS市场从2022年的46亿美元增长到2032年的373亿美元。',
      dot: ''
    },
    {
      head: '考虑实施RTLS时的注意事项',
      subHead: '',
      desc: '实施RTLS与区分业务需求一样重要。 <br/>以下是确保RTLS有效实施的一些考虑事项。',
      dot: ''
    },
    {
      head: '',
      subHead: '个人需求',
      desc: '传统GPS可以在全球任何地方提供资产的实时位置，但仅在5-20米范围内提供位置数据，在室内环境中使用时不够准确。 RFID的准确性较高，但工作范围有限，因此在大规模设施中需要使用数千个接收器标签。 <br/>因此，在选择RTLS时，需要考虑以下问题。<br/><br/>',
      dot: {
        a: ' • <strong>目标</strong>：是否要追踪人员或资产？还是两者都要追踪？将通过可穿戴标签或移动设备进行追踪？您打算追踪多少设备？',
        b: ' • <strong>准确性</strong>：RTLS的精度要求是多少？只需知道资产是否在特定区域内就足够，还是需要精确到1米以下的资产位置？',
        c: ' • <strong>响应时间</strong>：您的跟踪需要多快的实时性？是否可以根据要求设置10-20秒的响应时间？还是需要更高的精度？',
      },
    },
    {
      head: '',
      subHead: '寻找合适的供应商',
      desc: '成功的技术实施取决于选择合适的供应商。 RTLS公司应具有经过验证的业绩，并且必须了解您的需求。良好的供应商还应提供广泛的培训，以说明在出现问题时如何使用支持服务。<br/>选择具有未来发展和可扩展性的RTLS也很重要。此外，要找到适应RTLS技术变化和增长速度的供应商，以适应您的业务需求。',
      dot: ''
    },
    {
      head: '',
      subHead: '预算和投资回报率（ROI）',
      desc: '决定RTLS系统的预算可能会是一个复杂的过程，特别是在业务中需要其他利益相关者的批准时。 首先，将成本细分为项目的各个部分，例如跟踪标签和定位器采购成本、安装成本、IT集成成本、云存储和常规维护等持续运营成本。<br/>确定了RTLS的安装和运行预算后，可以确定未来10年的预期ROI（总拥有成本）。 TCO计算应该考虑生产率改善带来的额外收入，以及由于效率提高而产生的运营成本节省。',
      dot: ''
    },
    {
      head: '',
      subHead: '',
      desc: '',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: "将UWB终端机安装在工作者或设备上，帮助实时追踪位置的产品。",
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]


  }

}