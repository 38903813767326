import { BorderBottomLine, HeadingColor, ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import franchise from "@src/lang/ko/support/franchise";
import React from "react";
import { Trans } from "react-i18next";

const SpaceBar = () => <>&nbsp;</>;
const Documents = () => {
  return (
    <>

      <HeadingColor color={"#000"} size={"medium"}>
        <Trans i18nKey={`franchise.form.title`}/>
      </HeadingColor>
      <Spacing pixel="24"/>
      {franchise.form.docu.map((data, i) => (
        <>
          <Spacing pixel={i === 0 ? '24' : '32'}/>
          <HeadingColor color={"#000"} size={"xxxsmall"}>
            <Trans i18nKey={`franchise.form.docu.${i}.title`}/>
          </HeadingColor>
          <Spacing pixel="8"/>
          {data.item.map((text, j)=> (
            <>

              <ParagraphColor color={""} size={"medium"}>
                {text?.type === '-' ?
                <> <SpaceBar/><SpaceBar/><SpaceBar/><SpaceBar/><SpaceBar/>{'-'}<SpaceBar/></>
                :<><SpaceBar/>{'•'}<SpaceBar/></>}
                <Trans i18nKey={`franchise.form.docu.${i}.item.${j}.text`}/>
              </ParagraphColor>
            </>
          ))}
        
        </>
      ))}
      <Spacing pixel="64"/>
      <BorderBottomLine/>
    </>
  )
}


export default Documents;