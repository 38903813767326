import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { IconAccess, IconAlarm, IconWarn, IconZoom } from "@assets/images/main";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/constructionAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo4.mp4";
import subVideo5 from "@src/assets/images/solution/constructionAsset/video/sub/rtlsVideo5.mp4";

export default {
    banner: {
      title: '建設資材の測位と管理',
      desc: '正確な資材管理は最終的に生産性を向上させます。資源の無駄を減らし品質を維持し、さらには安全にも影響を与えるため、資材管理は建設現場で重要な要素です。',
    },
    video: {
      title: '資材の正確で迅速な管理方法',
      desc: 'リアルタイムロケーションシステム（RTLS）技術により、資材の位置をリアルタイムで把握することができます。資材の位置を特定するのにかかる時間はほとんどありません。<br/>さらに、数量管理も可能であり、これによりRTLSを使用することで生産性における新たなレベルを実現します。',
      play: video,
    },
    func: [
      {
        title: '資材数量管理と測位',
        desc: '正確な資材数量の把握は基本です。また、リアルタイムで資材の位置を把握し、現在の位置にどれだけの資材があるかを把握します。',
        sub1: '高い精度を提供',
        icon1: IconRulerGray,
        sub2: '統計データを提供',
        icon2: IconChartBarGray,
        video: subVideo1,
      },
      {
        title: '自動入出庫',
        desc: '資材が特定のポイントを通過する際には、自動的に入出庫記録が作成されます。このデータは正確であるため、人為的なミスを防ぎ、労力を節約することができます。',
        sub1: '迅速なデータ連携',
        icon1: IconTvocGray,
        sub2: 'リアルタイムの位置測位',
        icon2: IconBinocularsGray,
        video: subVideo2,
      },
      {
        title: '安全管理',
        desc: '潜在的な安全事故の原因となる可能性のある資材は特に管理が必要です。リアルタイムで位置を測位するだけでなく、近くを通る作業員も把握し、事故を予防することができます。',
        sub1: '近接基準距離の設定',
        icon1: IconRulerGray,
        sub2: '対応方法の設定',
        icon2: IconBellGray,
        video: subVideo3,
      },
      {
        title: '時間の節約',
        desc: '作業者が資材を探すのにかかる時間を大幅に削減します。現場での作業時間は生産性を意味します。最適な作業現場を確保し、リソースの無駄を省きます。',
        sub1: 'リアルタイムの状況把握',
        icon1: IconClockGray,
        sub2: 'リアルタイムの位置測位',
        icon2: IconBinocularsGray,
        video: subVideo4,
      },
      {
        title: '盗難防止',
        desc: '資材が紛失した場合、単なるミスなのか盗難なのかを区別することは難しいです。しかし、資材の位置をリアルタイムで把握すれば、エネルギーやコストの無駄を未然に防ぐことができます。',
        sub1: '対応方法の設定',
        icon1: IconBellGray,
        sub2: '外部システムとの連携',
        icon2: IconChainLinkGray,
        video: subVideo5,
      },
    ],
    subfunc: {
      title: 'さまざまな機能',
      desc: '未熟な資材管理は生産性に直接影響を与えます。最適化されたプロセスにより効率を向上させ、人的リソースを含むリソースの浪費を削除できます。ORBROでは、最適化されたさまざまな機能を提供しています。',
      data: [
        {
          icon: IconChartRadius,
          title: 'レポートと分析',
          desc: '現場データを基にレポートと分析資料を作成できます。',
        },
        {
          icon: IconBell,
          title: 'リアルタイムアラーム機能',
          desc: '資材に異常事態が発生した場合、アラームを受け取り即座に対応できます。',
        },
        {
          icon: IconRoute,
          title: 'ルート履歴の照会',
          desc: '資材の移動履歴を照会し、データ化できます。',
        },
        {
          icon: IconDashboard,
          title: '状況の把握',
          desc: '現在の資材の移動状況と位置を把握します。',
        },
        {
          icon: IconWarning,
          title: '逸脱検出',
          desc: '指定されたエリアから逸脱した資材や誤った位置に移動する資材を検出します。',
        },
        {
          icon: IconSearch,
          title: 'ルートパターンの分析',
          desc: '資材の使用量やタイミングを分析できます。',
        },
        {
          icon: IconChainLink,
          title: 'データ連携',
          desc: 'RFID、IoT、セキュリティ、ビデオなどのシステムと連携し、効率的に活用できます。',
        },
        {
          icon: IconSecurity,
          title: 'データセキュリティ',
          desc: '高いデータセキュリティレベルを維持し、信頼性の高いソリューションを提供します。',
        },
      ],
    },
    system: {
      title: 'システム構成',
      img: rtlsSystem,
    },
    productTitle: '製品構成',
    product: [
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'constructionEquipment',
      },
      {
        data: 'constructionSafety',
      },
      {
        data: 'blogMedical',
      },
      {
        data: 'blogRTLS',
      },
    ],
  }
  