import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {


  helmet: '| RTLS 업체를 선택하는 데 고려해야 할 주요 기준',
  desc: '최근 기업들은 생산성 향상과 자원 효율성을 극대화하기 위해 실시간 위치추적 시스템(RTLS)을 적극적으로 도입하고 있습니다. 하지만 RTLS 시스템을 선택할 때는 다양한 기준을 고려하여야 합니다. 이 글에서는 RTLS 업체를 고를 때 고려해야 할 주요 기준에 대해 더 자세히 살펴보겠습니다.',
  keywords: 'rfid, nfc, uwb, 무선 통신, 위치 추적, 물체 위치 추적, 사람 위치 추적, 태그, 비콘, rtls, orbro, 오브로, 콩테크',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RTLS 업체를 선택하는 데 고려해야 할 주요 기준 :: 오브로 블로그',
  ogUrl: 'https://orbro.io/blog/rtlsCompany',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDwtIISjDSVCDSnp4Nx3cGb1Ozp2BSmcdnEapCFup7uDaNWXxV2V1-fB8A3HklTGwTcrHkdZ_cA7VDTUd4UkTfLspAq0d4uZPq6ZeulzJqvcifS9Bw-xfTVTFgfZXklSlXh1rq8NsXP6Ah2aN-bYrwpNREltJcCR5HW7d-IJmK-9gIpde_688lL9UU2BeQ5E0P1A2ESvEXcd8-LuzUEVRs3e3jfbDPxSjg9ydKLvn705yJSBD6rpEUC5iU8jQGXfKbB2Hr7A88JMwRLphNHv6zieJqhSM1dv8ESi8X3l8jH3DBJB7E8xvlicUOt1-QvsMD65qm87niZDWettcJyS6XnpOwmmmhHTqiLe-0DzPjVRtGmz1mkzs3JHNrhxNp40cCE6NK3Mp5RQBKUy190oEUBDb1bb2MLa-cs_xu8wcwQLNf8K_d9HDtyMlsLD6XHKShUDydJm2H9iNfI2TsHCpj6qOmc5IfB42F4WCFQuGHtpbKj7mKOvCe1BqtRhoXzmAtiHmghM8jzrLZQkWCMDJE79cfRTqO-JSBpPwJi0g-Zov4puGycni3TFAD7lHlCAKw8wLWn6xZuaCkMGG79wJ32p6xa_Gp2JLiSbVJNsqYu1BWb54Wwb5tVdGsx8W8tWYwv3BzJqrafHi9FtyYaKunD2m3qcDZNbkcTyIcXeQsOcj8C2BEp9WiOJELl6A_etzBinI5fHqf6UwTzJ_LTJ-FtBHkB-5bH-qIitO-2Gf2FxzgXE65QclQQhjI_nD25uwKI6Gos0YU0s4EwlQoIlcptoFn8dWovqaERvfkgME5C8EgN_W8HKqq5J8j6kUqUwnT_dJR9QTJDp2MDFFUeF6vV_R8-eq_qquZuYhfQDKqVcx6SSkIgQB8p_pJ-Q_DJZCg-4iLTx3gP7b24S32g5jEMhTnx0zpHHsdzc8MIxzySrRt5bT5AfNasqvrWHqPWa_t9MM-0iHWjR9lR8hWEL17y8XdEKjF5igOYGdR0UEvs1ZcFGvvNTV3vdiNCk3rNaC89ZJ9m6olPqAFIcdEbc2qAgG78-MbI33yCmidAD9hPBPR2dyPg_pXmHHqIlXT14EoAoDu18wbBu_wQP41ZPemWH-X-U_BUK2IdUQJP4CvLlFNs6ghZbwn13En9tXaPpl0SFBQB5v6riEQxDujwiMEvoi65V8m170vbu8L-NRuQ5KJoWd2GOmxBhOtupoe3t4FTv-DjmFjImqiCeGOq4-iyWcmU55jv4gk1_ugniXiZVGSGpvb2WJJABeN6xgNiT_9z_L6VwCfK6aMHs9cE8S_sv2yc2qBi3_SeEM5xTIr8pmU_qG4r1P_C1GVdiinD8I8c56mIUcX4Sz8chmHYiLwlTtIlj1nweMwToAiXHsvztud9HFsIdPNvBaVIkvl-6I7a0UHtkO699f9aK2qT2MiYWrueCLYE9V3_ibpgYgbxxyS5crADSB-7YJ--6S4n6eD-r4bUkMHVz97z6X6HV_uHS5B4jce8CeOOdEUpUMMvTgWZ6Hs7FP4fGhrAwhGUTRFRsZ_93Z7YmOWXx4WJLxdyoABRmg3TOA1ghvuCqDjiUA14wOTD3=w1775-h1190',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RTLS 업체를 선택하는 데 고려해야 할 주요 기준',
  date: '2023-11-15',
  tag1: '#rfid',
  tag2: '#rtls',
  tag3: '#uwb',
  tag4: '#솔루션',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: '최근 기업들은 생산성 향상과 자원 효율성을 극대화하기 위해 실시간 위치추적 시스템(RTLS)을 적극적으로 도입하고 있습니다. 하지만 RTLS 시스템을 선택할 때는 다양한 기준을 고려하여야 합니다. 이 글에서는 RTLS 업체를 고를 때 고려해야 할 주요 기준에 대해 더 자세히 살펴보겠습니다.',
      dot: ''
    },
    {
      video : video,
      link : '/enterprise/manufacturing/asset',
      head: '기술의 성숙성과 정확도',
      subHead: '',
      desc: 'RTLS 시스템의 핵심은 위치정보의 정확성에 있습니다. 업체의 기술이 얼마나 성숙하며 정확한 위치정보를 제공하는지 확인해야 합니다. 특히, 기술의 성능 평가와 실제 사용 사례를 통해 업체의 기술적 능력을 신뢰성 있게 평가할 수 있습니다.',
      dot: ''
    },
    {
      head: '다양한 환경에서의 적용성',
      subHead: '',
      desc: '기업은 다양한 환경에서 다양한 작업을 수행하고 있습니다. 따라서 RTLS 시스템이 다양한 환경에서 효과적으로 동작할 수 있는지 확인이 필요합니다. 업체가 어떻게 다양한 환경에서의 테스트와 적용을 보장하는지 확인하고, 여러 산업 분야에 적합한 솔루션을 제공하는 능력을 고려하세요.',
      dot: ''
    },
    {
      head: '확장성과 유연성',
      subHead: '',
      desc: '기업의 규모나 비즈니스 요구사항은 시간이 지남에 따라 변할 수 있습니다. RTLS 업체가 시스템을 쉽게 확장하고 유연하게 조정할 수 있는 능력이 중요합니다. 또한 새로운 기술이나 업데이트를 적용하기 쉬운지에 대한 정보도 수집해보세요.',
      dot: ''
    },
    {
      head: '비용 및 ROI 분석',
      subHead: '',
      desc: 'RTLS 도입은 투자 비용이 발생하므로 비용 측면에서도 신중한 검토가 필요합니다. 업체의 가격 정책을 이해하고 도입 후의 예상 수익 (ROI)을 분석하여 장기적인 투자 수익을 고려하세요. 비용 대비 효과를 정확하게 계산하여 비즈니스에 최적화된 결정을 내리세요.',
      dot: ''
    },
    {
      head: '보안 및 개인정보 보호',
      subHead: '',
      desc: '위치 기반 정보는 기업의 중요한 자산이 될 수 있습니다. RTLS 업체는 데이터 보안 및 개인정보 보호에 대한 강력한 정책을 갖추고 있는지 확인하세요. 규정 및 규제를 준수하는지 여부도 중요한 고려 사항입니다. 고객의 데이터를 안전하게 보호할 수 있는 시스템을 선택하는 것이 중요합니다.',
      dot: ''
    },
    {
      head: '고객 지원 및 서비스',
      subHead: '',
      desc: 'RTLS 시스템은 문제가 발생할 경우 빠른 대응이 필요합니다. 업체의 기술 지원 및 서비스 품질을 평가하고, 어떠한 교육 및 훈련 프로그램을 제공하는지 알아보세요. 또한 업체의 고객 응대 및 문제 해결 능력이 탁월한지 확인하여 신뢰성 있는 협력 관계를 구축하세요.',
      dot: ''
    },
    {
      head: '',
      subHead: '',
      desc: 'RTLS 시스템은 기업의 운영에 중요한 역할을 할 수 있으므로, 이러한 다양한 기준들을 고려하여 RTLS 업체를 선택하면 효과적인 위치추적 시스템을 구축할 수 있을 것입니다. 적절한 업체 선택은 기업의 미래 성장과 효율성 증대에 기여할 것입니다.',
      dot: ''
    },

  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/enterprise/office/asset'
      }
    ]

  }


}