import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet: '',
  desc: 'RTLS和物联网在制造业中带来创新变化。 通过更有效率、更准确的资产管理和资产追踪,可以发展业务。',
  keywords: 'RTLS,IoT,制造产业,制造业,资产管理,资产追踪,定位,工人管理,劳动管理,工作管理,室内定位,GPS',

  ogTitle: '',
  ogSite: 'RTLS和IoT革新制造运营的方法 :: Orbro Blog',

  ogUrl: 'https://orbro.io/cn/blog/manufacture',
  ogDesc: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EOa6daqBDghCNor8iv-YzX1WxKH5TOKtIuZ9Iom5tWnX1rsigsNIOwNLoDBlg70uCuj1H-sg75MSHvBgEva42vfGoepNWA-Iw509j1WfkjEYFSyInBvchHQrT16H7m2qJIKHJMkYZIS2k9KhzJE-hoQ86Y9voT53nZQA7VyJOfQWTxxaUy1bjjnVKrn8H0GEP9W_85velE96d8kXtIjOWmjV6SDkKVxEnl_N1NejohAfWmQhvVCJVnlf7cPcn-5DB4LMdtZGSAGRVWLSXzygG8F8nP4b3YJTMGE4WZBoePBa0hM4SBgT7SpykyGlPzUay6TifllWLBvuvWKh9LCb0JZtYebb8isWpkOIERN0tmDCM_tBjsvibo27ZyP1yRfpmLKfsoNtpZOHY9NP9eHQnUAhLg1clyaBjR-hxMKhMXk2AQUhS1OWIcsN_tLo36LQyNQAeChEpFUJLr0hc0aXxrscDRHI4S80-5hqCmjPcfUERM-fKyyK6SfFJrEBtyCDbqhWgGuU8BOUvkcnPc68hRsSBXjkiZILl0tjKmrWzJe2UUBBVyduU__R3dktNC0FGRPLrkx9XavkeA3B5aZUCOggV9AjAUklOCEml0dfPOyCg-eKwU196LAIC6HdS-ja1H_q7aBURgW9RAK_jGYJmWGzfUPt9ZndbM4G7khUvJU4eWKvYkXFsP0QlkOszc2q3LD9zWxMFrjzbuAs7Gnus4QHrCk0VoS5WYhGBgDLuYfph53E3g6vKm3Z8VH1oGnD_MoZdX3NjdUmLbm2aN8qGe0uvq57HPT2AOUVTC-ntXkT2560j9Y1TnPzHczlkmaRCzxxMHdufPs1Xv147yCml7lz-IY3_3tYh88JhPpWKYMwsZ0gmDMD-k58uVl6M2nu2U2ZnDBIhQR5N2XG5W6-45lXcdsSMQ249MAkTUAoO1V3qu3l66NcOZ4f2K4eL8pHl1OgPuLlgp5xvHaaWG4DJP9-uOps1wYhRqnDZWGfsQaoNH27x3JwRvkKIoSojHEQ28-GqOxo7DHwZDbwGtSzAnh-oHGzqpbUgbtcUr9Urk8Kr4YZh5GbMrmwZLXAl5CyIzCxfCxgKHvxkRgKL81zT3bcX4n-L9seXjGliNOO9sUax87u662hezKTLkVnx4a0olXqQm4PuCUpdF_AO187KE0-8fqEHktIFUy2WujCmXpbyKXwj63VakYRGmnTF2DcVJ1F7lkdio4CGmBLhtQoQeXPabn1aRJ-QeuvDLFBO27AGJbhszDTGjIjQaha7Hp1Btx_AKN0NEdYl2PiFBF1y9QB1JCiexGRPeVBWBSnvf_FE69IkFXqkSkIi1kPKlA-ycurt5-uYVPe3ejmtTq54EHjy8uEBAyQ74EY0dCuSVc1zMRZPYot0mmlcxDw7oRtWRpzHRl2tudqm9bwTriTBMAjd101CAjfkwupjGycmcBCeEQ1KHkjkG6noU85cBX2uzWJgLxlQZKcw_RCypz5egZfAzkP4uiZIBauX2UAcOcLo_f8XBjlq6_-I9AGmNgzrS9hd_NNGBqcxS65JFZaT5gWU2Ousre_BtisMG4z84fsFw=w2059-h1216',
  ogType: 'website',

  twitterCard: 'summary',
  twitterTitle: '',
  twitterDesc: '',


  title: 'RTLS和物联网改革制造运营的方法',
  date: '2023-05-14',
  tag1: '#RTLS',
  tag2: '#物联网',
  tag3: '#制造业',
  tag4: '#资产管理',

  // <br/>
  text: [
    {
      head: 'RTLS是什么？',
      subHead: '',
      desc: 'RTLS（实时定位系统）是一种用于提供人或物体实时位置信息的自然补充装置，用于位置服务。它支持企业利用数据来获得关于如何改进整体绩效、效率和盈利能力的洞察力。<br/>RTLS技术市场正在快速增长，涌现出多种新的应用，涵盖医疗、制造、体育等各个领域。<br/>在当今的商业环境中，提高利润率并提高客户满意度是确保竞争优势的必要条件。与物联网设备结合的RTLS技术能够通过提高生产流程速度、监控开发周期、预测维护以及仓库和库存管理来创新制造业，并带来新的收入动力。<br/><br/>让我们来看一些它是如何运作的例子。',
      dot: ''
    },
    {
      head: '',
      subHead: '提高生产线效率',
      desc: '将RTLS技术整合到生产线中，企业可以追踪特定产品并收集有关设备使用情况和供应链效率的数据。通过这种方式，能够即时了解常规运营或瓶颈，有助于简化运营并降低成本。此外，如果知道出现过程偏差的时间，有助于在产品到达客户之前发现错误。',
      dot: ''
    },
    {
      head: '',
      subHead: '资产追踪与管理',
      desc: '制造和物流行业中持续出现的问题之一就是在哪里找到设备。由于COVID-19的影响，这个问题在某些地区仅允许有限数量的工人的环境中变得更加棘手。而且，在所有行业中，寻找设备所需的时间成为销售损失的原因之一。<br/>给所有设备配备RTLS标签意味着始终知道工具在哪里，甚至到最近的亚米级。此外，管理人员可以通过为所有设备和机器添加标签来快速了解确切位置，从而更快地进行维护工作。此外，了解设备的使用数据可以更好地估计何时预订定期维护，从而避免设备的故障。',
      dot: ''
    },
    {
      head: '',
      subHead: '安全管理',
      desc: '在每天有大量人员出入的大型设施中，员工和设备的安全尤为重要。通过控制资产，RTLS、LBS和物联网可以给行业带来利益。RTLS可以在入口处、访客ID徽章、手机或钥匙卡上实现，以防止对特定设施区域的未经授权访问。<br/>同样，标签可以警告员工进入高风险区域，并确保员工进入现场时佩戴适当的个人防护装备（PPE），从而确保企业遵守法规和安全规定。',
      dot: ''
    },
    {
      head: '成功的解决方案考虑因素 <br/>',
      subHead: '未来需求',
      desc: 'RTLS的价值在于综合考虑业务的短期和长期需求，并采取整体方法。然而，许多公司倾向于将位置服务视为单一应用程序，并因此为最小可行解决方案设定预算，而不是满足中长期需求的解决方案。所选的RTLS应该能够同时满足多个用例。',
    },
    {
      head: '',
      subHead: '现实期望',
      desc: '最终用户应该接受教育，不要产生不现实的期望，并在问题发生时能够解决问题。花时间全面了解产品的工作方式，以协调所期望的结果和所需的执行预算，有助于预防未来问题。',
    },
    {
      head: '',
      subHead: '软件类型',
      desc: '由于位置软件的层次和复杂性不同，因此了解公司应用程序的最适合软件类型是很重要的。考虑实现的目标并找到适合业务策略的最佳解决方案。与此相关，采用从一开始就开放和多功能的RTLS平台是关键，以确保在各种LBS和SW应用解决方案之间具有灵活性。',
    },
    {
      head: '',
      subHead: '匆忙',
      desc: '在初步设置后，有时候会倾向于跳过在实际环境中进行测试，但这样做往往导致失败。企业应在将技术按比例推广之前了解解决方案在多种场景下如何扩展。此外，确保预算和项目要求相匹配以保证可持续的扩展计划和总拥有成本（TCO）的明确估计。',
    },
    {
      head: '',
      subHead: '',
      desc: 'RTLS和物联网引起了人们的兴趣，这是一个令人兴奋的时刻。随着越来越多的企业从各行各业中受益，绩效和收益也在改善。现在是那些尚未采用这项技术的人们的机会。',
    },
  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: "将UWB终端机安装在工作者或设备上，帮助实时追踪位置的产品。",
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]


  }


}