import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| UWB 기술을 활용한 스마트 물류 센터',
  desc : '스마트 물류 센터는 현대 물류 및 공급망 관리에서 중요한 역할을 합니다. 속도, 정확성, 효율성은 모든 물류 작업의 핵심 요소 중 하나이며 이러한 요소들을 최적화하는 것은 기업의 성공과 경쟁력을 결정짓는 중요한 과제 중 하나입니다.',
  keywords : '대형 물류 창고 관리, 물류센터 관리, 물류창고 관리, 스마트 물류, 재고 관리, 스마트 재고 관리, 물류창고 재고 관리, 대형 물류 창고 재고 관리, rtls, orbro, 오브로, 콩테크, 실시간 위치 추적, uwb',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'UWB 기술을 활용한 스마트 물류 센터 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/uwbLogistics',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4PMiWc5DIFUhcXlBfDRp2o8d3EJ_Z4ffawsJSoDWT70JslXvsXOw5KgNd3q5wgkx3TUzMx42JtuNcCYfLVsl4W8GEIevw-Q84jKo4Nm-Bu0CdH4qgYaA6OwgyC4paHI32-VbALd50n6lLWH0CNWUBFGOgkoXzFpchLLz2INpLMFbzPNsPlnWPK1V9vPzvJlVDr6GdB4SKKQM0XHaIbQFHd9xwu4quaqFpAPoJ6HMEZb_-PMBnKc0D5CSBQ0cBlJqHFHacrj-TPxhfLG1QeDfnIkE25ooGaiwz76DMvLqIqnCBcYPYsStKF8weqz_g5Sh3LyHpvveZL2GPOiRD0SvaNatRUPDeyK5_11bB9t42SpjZZuEYUHIb-ivBBOXxdCGJt4MDAl3pcM65PWS1DDBqUcncZ1uHo8_1b5wHawqsOZ6F6H5hFzzLKZHEnfibNYE0UgKoBvRHlCqz5y6SPTqcRTsaUzu0aj7LRETSi4adhGjvfgOQ0mILavMKp9ei41wAcC7zWl67f5uYGBTU9z-Sq8C0ncIG_tUmKIf3-bXeS0dBjJyYZQCPSytqKSFAPWzcxUHqp7GKdX6cFyMRkop-S-SzwB0spx8E_3wdecdTkX3H6TX_uSGKaFOmSZbMrm6YZaEJFUrDH7Zl6RAx-sIJoDA1qoEx4ULktfnVpEt-DaFyVJDuiY-qC54aoH8YJ-FHWixIFG6BpVGCp6pME79yITG3fDMVFfRfSnz32WTrpb_8htFe2iu3M4d8NRRm48c5ovfiTkWMRNLFRdSkRPDZ0qGVEs6FSU-UrGu95_QwTHwqWWceSPTdxRiPAMtR2x1zyg7au0pLgIDeVoChJuZ9nE6t6659ZBQsZnWHA_DOSrdWzMPvdrr7qTj5_byxnTgW0vC8flxGkf0I8g8DR3cWtJ7tWVMQpUwTh5dOl_7fQ62ZRN9fgnXvx-GKnP1ZkEz3Vzuz4Z4PPVAmS7gOHS8L-PMTJ9WF7-mwLVknLpgxrlSGhYuW4ognx12nU0yiuSDxPKLq-jS5DFHHeIN6O4SQtVgRnga99vTOi9QmhV_pWMXbuvhzEhbGMgxkHTLZV_iwH0PTl6MWgJAG2HOaUSAT9uZ71Kvv6RWyt_VLkET4AuJ480_wC7DHvDneJqb7pgtUZpbVa_aYXNB5AouMSyFVoHrsGRLZTJ1vhiKdQgfy5exsXJY2e6qCVrSpsVYQufijgQ3dc4PjAGJ8IY8krq2igby7D2AGnRDmJO6q7zwf7hNktGXBM4jR6YcUh1GbRmzm4Ios1p7z3EaKsY59m7zLrNjbZHA6o2Dcgf2cxFNBfwMiShDmtQSMQZHzFEY4x6PCByTF_Pb0tKe1Pu5iuiUm-HYkboa8BEWXYjFVvEc05axWugdX6zrW4ijr0xd_xMSZRCmZ0lXPBOekY03zX5ajinw6TRDfQo3l5HqEmCXgAZKlWZAgfBp67huSzzosSWfm2dY9b4bTGfgrfKZTeu9IJx8El-7m7PcTU4Bv-g584Bp0wcyv2tjG2aDCIz1BgGuXW7oNyImmGFxwWArxN0_Xk=w2000-h1946',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'UWB 기술을 활용한 스마트 물류 센터',
  date : '2023-09-05',
  tag1 : '#대형물류센터',
  tag2 : '#스마트물류',
  tag3 : '#재고관리',
  tag4 : '#스마트재고',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'스마트 물류 센터는 현대 물류 및 공급망 관리에서 중요한 역할을 합니다. 속도, 정확성, 효율성은 모든 물류 작업의 핵심 요소 중 하나이며 이러한 요소들을 최적화하는 것은 기업의 성공과 경쟁력을 결정짓는 중요한 과제 중 하나입니다. UWB(Ultra-Wideband) 기술은 이러한 도전에 대한 현대적인 해결책으로 떠오르고 있습니다. 이 글에서는 UWB 기술을 활용하여 스마트 물류 센터를 만들 수 있는 방법과 장점에 대해 살펴보겠습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'UWB 기술이란?',
      desc : 'UWB 기술은 매우 넓은 대역폭을 사용하여 무선 통신을 지원하는 기술입니다. 이러한 넓은 대역폭은 데이터를 빠르게 전송하고 정확하게 위치를 추적하는 데 매우 유용합니다. UWB 기술은 물체의 위치를 밀리미터 단위로 정확하게 추적할 수 있는 고정밀 위치 추적 기술로 알려져 있으며 이러한 특징은 물류 센터에서의 응용 가능성을 무한히 확장시킵니다. <br/>아래에서는 과연 스마트 물류 센터가 어떤 장점들을 가지고 있는지 알아보겠습니다.',
      dot : ''
      
    },
    {
      head : 'UWB 기술을 활용한 스마트 물류 센터의 장점',
      subHead :'',
      desc : '',
      dot : {
        a :' • <strong>정확한 위치 추적</strong> : UWB 기술을 사용하면 물류 센터 내 모든 물체의 정확한 위치를 실시간으로 추적할 수 있습니다. 이로써 재고 관리, 물품 이동 및 작업 과정의 정확성과 효율성을 향상시킬 수 있습니다.',
        b :' • <strong>자동화 및 로봇화 지원</strong> : 스마트 물류 센터에서는 로봇과 자동화 장비를 효과적으로 운영해야 합니다. UWB 기술은 로봇 및 자동화 장비와의 실시간 통신을 가능하게 하여 작업 프로세스의 자동화를 강화합니다.',
        c :' • <strong>안전 및 보안 강화</strong> : UWB를 사용하면 물류 센터 내의 모든 활동을 모니터링하고 보안 감시에 활용할 수 있습니다. 물품의 이동, 출입 통제, 화재 감지 등 다양한 보안 및 안전 시스템에 통합할 수 있습니다.',
        d :' • <strong>실시간 데이터 분석</strong> : UWB 기술은 실시간 데이터를 수집하고 분석할 수 있으므로 물류 작업에 대한 실시간 의사 결정을 가능하게 합니다. 이로써 물류 프로세스의 최적화가 가능해집니다.',
        e :' • <strong>에너지 효율성</strong> : UWB 기술은 물체의 위치를 정확하게 파악하므로 에너지 소모를 최소화할 수 있습니다. 자원을 더 효율적으로 활용하고 환경에도 더 친화적인 물류 센터를 구축할 수 있습니다.',
        }
      
    },
    {
      head : 'UWB 기술의 물류 센터 응용 사례',
      subHead :'',
      desc : '',
      dot : {
        a :' • <strong>재고 관리</strong> : 물류 센터 내에서 재고를 정확하게 추적하여 재고 오류를 방지하고 재고 비용을 줄일 수 있습니다.',
        b :' • <strong>중장비 관리</strong> : 물류 현장에서 이용되는 중장비들을 추적하여 쉽게 관리할 수 있습니다. 또한 접촉 사고도 예방할 수 있습니다.',
        c :' • <strong>작업자 관리</strong> : 작업자들의 위치를 실시간으로 파악하여 더 효율적으로 인력을 배치하거나, 안전사고를 실시간으로 파악하여 빠르게 대처할 수 있습니다.',
        d :' • <strong>자동 입출고</strong> : 특정 자산이 특정 구역을 지나는 시간을 자동으로 기록하여, 불필요한 인력을 절감할 수 있습니다.',
        e :' • <strong>자동차 조립 라인</strong> : 자동차 생산 공장에서 UWB를 사용하여 자동차 부품의 정확한 위치를 추적하여 생산 효율성을 향상시킬 수 있습니다.',
        f :' • <strong>무인 드론</strong> : 물류 센터 내에서 무인 드론을 사용하여 제품을 효과적으로 운송하고 모니터링할 수 있습니다.',
        }
      
    },
    {
      head : '',
      subHead :'결론',
      desc : 'UWB 기술은 스마트 물류 센터의 혁신을 이끌고 있으며 물류 및 공급망 관리에 있어서 큰 변화를 가져오고 있습니다. 정확한 위치 추적, 자동화 지원, 보안 강화, 에너지 효율성, 실시간 데이터 분석 등의 장점을 통해 기업은 경쟁력을 강화하고 더 효율적인 물류 작업을 실현할 수 있습니다. UWB 기술을 적극적으로 채택하여 스마트 물류 센터를 구축하는 기업은 미래에 더 큰 성공을 이룰 것입니다.',
      dot : ''
    },

  ],

  link : {
    page : 'rtls',
    numb : '1',
    product : [
      {
        text : productPurchase.purchase.asset.assetTracking.title,
        title : productPurchase.purchase.asset.assetTracking.title,
        serial : productPurchase.purchase.asset.assetTracking.serial,
        desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img : productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution : [
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      },
      {
        img : logisticsSafety.thumb,
        data : 'logisticsSafety',
        link : '/enterprise/logistics/safety'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/enterprise/manufacturing/asset'
      }
    ]

  }


}