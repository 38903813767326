import { setDarkMode } from '@redux/action-creators/common';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

interface PageProps {
  darkMode: boolean
  component: FunctionComponent
}

const Page = (props: PageProps): React.ReactElement => {
  // React
  const location = useLocation();

  // Props
  const { darkMode, component: Element } = props;

  // Redux
  const dispatch = useDispatch();

  // Hooks
  useEffect(() => {
    const setDarkModeAction = setDarkMode(darkMode);
    dispatch(setDarkModeAction);
  }, [location]);

  return <Element />;
};

export default Page;
