
import { SCREEN_BREAKPOINT } from '@components/Grid/Grid.style';
import { StyledLabel, StyledParagraph } from '@components/Text';
import styled from '@emotion/styled';




export const StyledProductBtnOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  gap: 8px;

  width: 100%;
  height: 44px;

`



export const StyledProductBtn = styled.div<{ index: number, selectedIndex: number }>`
  padding: 16px 24px;
  gap: 4px;
  
  height : 100%;
  display : flex;
  align-items : center;
  
  border-radius: 12px;


  flex: none;
  order: 0;
  flex-grow: 0;

  ${({ index, selectedIndex }) => index === selectedIndex ? `background-color : #2A70F0;` : 'background-color : #FFFFFF;'}

  cursor : pointer;
`



export const BtnLabel = styled(StyledLabel) <{ index: number, selectedIndex: number }>`
  ${({ index, selectedIndex }) => index === selectedIndex ? `color : #FFF;` : 'color : #000;'}

`;



export const StyledProductImgBig = styled.img`
  width: 100%;
  max-height: 520px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background: #F8F8F8;

  padding : auto;
  margin-top : 48px;
  
  object-fit: contain;

  aspect-ratio: auto 1232 / 520;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    width: 100%;
    max-height: 440px;
    aspect-ratio: auto 1287 / 912;
    
  }


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    display: block;
    margin-top : 32px;    
    margin-bottom : 0px;
    aspect-ratio: auto 360 / 360;
  }


`


export const StyledProductImgBig2 = styled.img`
  max-width : 100%;
  max-height: auto;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background: #F8F8F8;

  padding : auto;
  margin-top : 32px;

  object-fit: fit;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    width: 100%;
    max-height: 440px;
    
  }


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height: 380px;
    object-fit: cover;
  }


`




export const StyledProductImgBox = styled.div`
  margin-top: 32px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;


  object-fit: contain;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    flex-direction: column;
    width : 100%;
    margin-top : 16px;
    gap: 16px;
  
  }

`;

export const StyledProductImgSide = styled.img`
  flex: 1;
  max-width: calc((100% - 32px) / 2);
  object-fit: contain;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  display: block;
  background: #F8F8F8;
  aspect-ratio: auto 1232 / 520;


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    aspect-ratio: auto 1287 / 912;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    max-width : 100%;
    max-height: 380px;
    aspect-ratio: auto 360 / 360;
  }

`;


export const StyledProductImgOutlook = styled.img`

  margin-top : 48px;
  max-width: 100%;
  
  border-radius: 16px;

  object-fit: contain;

`




export const StyledSpacing64 = styled.div`
  height : 64px;



  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-bottom : 48px;
    
  }

`


export const DownloadLayout = styled.div`
  width : 100%;
  height : 100%;
`;


export const SpacingBtn = styled.div`
  margin-top : 48px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    margin-top : 64px;
  }
`;

export const DownloadRow = styled.div`
  width : 100%;
  height : auto;
  display : flex;
  padding : 16px 0;
  gap : 33px;
`;

export const PDFimg = styled.img`
  display : flex;
  height : 24px;

  cursor : pointer;
`;

export const DownloadPara = styled(StyledParagraph)`
  width: 44.8%;
`;

export const DownloadDate = styled(StyledParagraph)`
  width : 44.8%;
  color ; #646464;
`;


export const YoutubeIframe = styled.iframe`
  width : 100%;
  height : 100%;
  aspect-ratio : 16/9;
`;