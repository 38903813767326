import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";


export const ReleaseNotePage = styled.div`
    padding : 0 16px;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
      padding : 0;
    }
`;

export const ReleaseNoteTagButton = styled.div<{ color: string }>`
    padding : 8px 16px;
    border-radius : 38px;
    cursor : pointer;
    background-color: ${(props) => props.color};
    ${(props) => props.color === '#FFF' && 'border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));'}

`;


const fadeIn = keyframes`
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
`;


export const DivRight = styled.div`
  width : 100%;
  display : flex;
  flex-direction : row;
  justify-content : end;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    justify-content : start;
    margin-top : 8px;
    order : 1;
  }
`;

export const ReleaseNoteTextBox = styled.div`
  width : 100%;
  position: relative;
  display : flex;
  flex-direction : row;
  gap : 48x;
  padding : 24px 0px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    padding : 24px 0px;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding : 18px 0px;
    flex-direction : column;
  }
`;

export const ReleaseNoteText = styled.div<{ dir: string }>`
  width : 50%;
  display : flex;
  flex-direction:column; 
  align-items: ${(props) => (props.dir === 'end' ? 'flex-end' : 'start')};

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    width : 100%;
    align-items : start;
  }
`;

export const ReleaseNoteTextGap = styled.div`
    position: relative;
    width : 100%;
    height : fit-content;
    text-align : start;
    margin-bottom : 8px;
    padding : 4px;
`;

export const ReleaseNoteTagButtonSmall = styled.div<{ btnColor: string}>`
  padding : 6px 12px;
  display : flex;
  border-radius: 28.5px;
  justify-content: center;
  align-items: center;
  margin-left : 8px;
  background-color: ${(props) => props.btnColor};
  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    margin-left : 0px;
    margin-right : 8px;
  }
`;


export const ReleaseNoteTextBG = styled.div`
    position: absolute; // 부모 컨테이너에 대해 절대 위치 설정
    top: 0;
    left: 0;
    width: 100%; // 부모 요소에 대해 전체 너비 설정
    height: 100%; // 부모 요소에 대해 전체 높이 설정
    position : absolute;
    border-radius: 4px;
    &:hover {
        background-color : rgba(0,0,0,0.04);
        animation: ${fadeIn} 0.1s ease forwards;
    }

`;

export const NotionIframe = styled.iframe`
    width : 100%;
    height : calc(-296px + 100vh);
    border : none;
`;

export const PageButton = styled.div<{color:string}>`
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding : 8px 16px;
    border-radius: 38px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
    cursor : pointer;
    background-color: ${(props) => props.color};
`;



export const NotionBackBtnLayout = styled.div`
    max-width : 708px;
    width : 708px;
    text-align : start;
    display : flex;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
      width : 100%;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
      width : 100%;
    }
`;



export const NotionBack = styled.div`
  margin-top : 48px;
  height : 20px;

`;

export const NotionBackBtn = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

`;
