import { ptzThumb } from "@assets/images/main/camera";

import * as banner from "@src/pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  home: {
    title: 'Orbro | ORBRO',
    key: 'Indoor Positioning, RTLS, Real-time Location Tracking, Asset Management, Indoor Tracking, Asset Tracking, People Counting, Real-time Location System',
    desc: 'Discover the wide range of Orbro products',
    img : '',
    url : 'https://orbro.io/en/'
  },
  
  solution: {
    constructionAsset: {
      title: 'Construction Asset Tracking and Management | ORBRO',
      key: 'Construction Asset Management, Construction Site Management, Material Management, Material Tracking, Goods Tracking, Construction Site Tracking, Indoor Positioning, Asset Management, Worker Safety Management, Labor Standards Act, Orbro',
      desc: 'Accurate material management ultimately improves productivity. Efficiently manage resources, maintain quality, and ensure safety in construction sites, which is a crucial aspect of the construction industry.',
      img: banner.constructionAsset.banner,
      url: 'https://orbro.io/en/enterprise/construction/asset',
    },
    constructionSafety: {
      title: 'Worker Safety Management | ORBRO',
      key: 'Worker Safety, Worker Safety Management, Safety Management, Worker Safety, Construction Safety, Construction Site Safety, Indoor Positioning, Asset Management, Worker Safety Management, Labor Standards Act, Orbro, RTLS, Real-time Location Tracking, Worker Tracking, Location Tracking, Outdoor Location Tracking',
      desc: `Worker safety is of paramount importance. Failing to ensure worker safety in high-risk environments not only impacts productivity but also leads to legal liabilities. Furthermore, a safe construction site plays a crucial role in shaping the company's image and building trust among stakeholders.`,
      img: banner.constructionSafety.banner,
      url: 'https://orbro.io/en/enterprise/construction/safety',
    },
    constructionEquipment: {
      title: 'Heavy Equipment Management | ORBRO',
      key: 'Construction Heavy Equipment Management, Construction Equipment Management, Heavy Equipment Management, Heavy Equipment Maintenance, Crane Management, Construction Heavy Equipment, Bulldozer Management, Excavator Management, Real-time Location Tracking, Outdoor Location Tracking, RTLS',
      desc: 'Heavy equipment is a critical component in construction sites. Proper maintenance and accident prevention are essential. By effectively managing heavy equipment, you can maintain safety while reducing costs and significantly increasing productivity on the site.',
      img: banner.constructionEquipment.banner,
      url: 'https://orbro.io/en/enterprise/construction/equipment',
    },
    logisticsAsset: {
      title: 'Asset Tracking and Management | ORBRO',
      key: 'Asset Management, Warehouse Asset Management, Warehouse Asset Management, Indoor Tracking, Warehouse Asset Tracking, Asset Location Tracking, Warehouse Indoor Positioning, Indoor Positioning, RTLS, Warehouse Management, Asset Management, Warehouse Goods Management, Asset Tracking, Real-time Location Tracking',
      desc: 'Asset management is a crucial part of supporting supply chain efficiency and smooth operations. By accurately tracking the location of Asset in real-time, you can effectively monitor Asset levels and optimize Asset movement. For example, you can allocate Asset based on item popularity, optimize Asset turnover, and prevent shortages or overstocking, resulting in cost savings and prevention of Asset-related issues.',
      img: banner.logisticsAsset.banner,
      url: 'https://orbro.io/en/enterprise/logistics/asset',
    },
    logisticsSafety: {
      title: 'Warehouse Worker Safety Management | ORBRO',
      key: 'Warehouse Safety, Warehouse Safety Management, Warehouse Worker Safety Management, Indoor Tracking, Warehouse Worker Management, Worker Safety, Warehouse Management, Safety Management, Warehouse Worker Safety, RTLS, Location Tracking, Real-time Location Tracking, Indoor Location Tracking, Worker Tracking',
      desc: `Worker safety is of paramount importance. Failing to ensure worker safety in high-risk environments not only impacts productivity but also leads to legal liabilities. Furthermore, a safe logistics site plays a crucial role in shaping the company's image and building trust among stakeholders.`,
      img: banner.logisticsSafety.banner,
      url: 'https://orbro.io/en/enterprise/logistics/safety',
    },
    officeAsset: {
      title: 'Asset Management | ORBRO',
      key: 'Office Supplies Management, Asset Management, Office Asset Management, Indoor Tracking, Office Asset Management, Office Management, Asset Management, Office Management, Smart Office, Indoor Positioning, Asset Tracking, Asset Tracking, RTLS',
      desc: 'Effective asset management prevents time and financial losses. By managing assets clearly and quickly, you can operate a smooth business without disruptions.',
      img: banner.officeAsset.banner,
      url: 'https://orbro.io/en/enterprise/office/asset',
    },
    medicalPatient: {
      title: 'Patient Tracking | ORBRO',
      key: 'Patient Tracking, Patient Management, Infection Control, Hospital Management, Indoor Tracking, Smart Hospital, Indoor Positioning, Real-time Location Tracking, Patient Tracking, Ward Management, Patient Safety, Patient Monitoring, Real-time Monitoring, Indoor Positioning, RTLS',
      desc: 'Medical facilities have patients that require special management. You don\'t need to monitor them directly 24/7. You can track their location accurately and in real-time.',
      img: banner.medicalPatient.banner,
      url: 'https://orbro.io/en/enterprise/medical/patient',
    },
    medicalWaste: {
      title: 'Medical Waste Management | ORBRO',
      key: 'Medical Waste Management, Medical Incident Management, Medical Incident Prevention, Indoor Tracking, Medical Incident, Waste Management, Waste Tracking, Indoor Positioning, Real-time Location Tracking, Location Tracking, Asset Management, Hospital Asset Management, Hospital Asset Management, Real-time Monitoring, Waste Monitoring, Hospital Safety, RTLS',
      desc: 'Medical waste management is essential for the safe and proper disposal of various types of waste generated in medical facilities. It helps prevent environmental pollution, minimize the risk of infections, and comply with legal regulations, contributing to both human health and resource conservation. By tracking the real-time status and location of medical waste, you can anticipate and prevent medical incidents, providing safe medical services.',
      img: banner.medicalWaste.banner,
      url: 'https://orbro.io/en/enterprise/medical/waste',
    },
    manufacturingProcess: {
      title: 'Real-time Process Management | ORBRO',
      key: 'Manufacturing Process Management, Manufacturing Process, Manufacturing Facility Management, Indoor Tracking, Asset Location Tracking, Real-time Location Tracking, Process Management, Process Monitoring, Real-time Monitoring, Location Tracking, Smart Manufacturing, Production Line Monitoring, Indoor Positioning, Process Analysis, RTLS, Manufacturing Facility Asset Management',
      desc: 'You can easily grasp the flow of manufacturing processes and manage them. By improving process lines based on accurate and objective data, you can increase efficiency. This is crucial in the manufacturing industry.',
      img: banner.manufacturingProcess.banner,
      url: 'https://orbro.io/en/enterprise/manufacturing/process',
    },
    manufacturingAsset: {
      title: 'Real-time Asset Management | ORBRO',
      key: 'Manufacturing Asset Management, Asset Management, Smart Manufacturing, Indoor Tracking, Process Monitoring, Process Management, Raw Material Management, Monitoring, Real-time Monitoring, Real-time Location Tracking, Location Tracking, RTLS, Indoor Positioning, Production Line Monitoring, Process Analysis, RTLS',
      desc: 'Manufacturing facilities hold numerous inventories, such as raw materials and semi-finished products. Properly managing these inventories is crucial from a time and economic perspective, as it significantly impacts the efficiency of the manufacturing industry.',
      img: banner.manufacturingAsset.banner,
      url: 'https://orbro.io/en/enterprise/manufacturing/asset',
    },
    retailExhibition: {
      title: 'Exhibition Visitor Management and Analysis | ORBRO',
      key: 'Visitor Path Analysis, Visitor Analysis, Exhibition Visitor Path Analysis, Indoor Tracking, Exhibition Visitor Analysis, Path Analysis, Indoor Positioning, Real-time Location Tracking, Visitor Analysis, Visitor Tracking, Location Tracking, RTLS',
      desc: 'Accurate and objective data can drive business growth. By minimizing wasted resources and expenses, you can provide a smart exhibition experience and differentiate your operations.',
      img: banner.retailExhibition.banner,
      url: 'https://orbro.io/en/enterprise/retail/exhibition',
    },
  },
}
