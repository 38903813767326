import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { logisticsAsset, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/officeAsset/video/rtlsVideo.mp4";

export default {
    helmet: '',
    desc: 'スマートオフィスは資産管理、出勤管理、在庫管理、従業員管理に革命的な変化をもたらします。これにより、オフィスの運営と管理が異なる方法で行え、生産性を向上させることができます。',
    keywords: 'スマートオフィス、資産管理、在庫管理、オフィスソリューション、位置追跡、出勤管理、オフィス用品管理、オフィス用品、コンテック、オブロ、RTLS、オブロ',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'スマートオフィス構築ソリューション :: オブロブログ',
    ogUrl: 'https://orbro.io/jp/blog/officeSolution',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDz0dYjvT_dovrQIm8StET0JPtXyRSg67istWgj_G4Tk7ygKMfT0sSkYCFctUToVxdJt0GLQovf6SlDVOv8wRusG-hAYmfYmKYwY2UC2zapN2wPY8xmOdsmqfCmtKJE_ZgS5d3zp8jhdEh3kzrTI6wCtwXZsh2l8FoST49zwMy3hMKotCDruVcBK8e-90_kWC-DRfGXSqBUlqYqEvpb7suLGc-sG9uVyVzgpahbCluCQLjjYQp0FfaIMqi3qWmmpzn4UeKKFlFEKxo5npOoMkUfIPOIzQwrqMhKMvCPw19QwjLii04Vk779E1xu1B3yvdQA0SsTGt8xskOW8TEzvlXITVtsKdpUOAbHj6rKF-3gchc57T4eE_-3ycDx8gttnPLOy-milalJmjbIZrntwCeb8Qpocf-Ug-YSBcy46fdXlAD73CrLAkMZD_EibWYj6rcA1MkP7YlYgUDMFZsUHXCRAJn-FXeC1tFSMvFp9XkMXa0hQjhG7WFKQ9OY__bS3VMM3m653EIFAooJVI2JW5Lehmr8i7AoYvVwyVya-ECTAkyCOHE36ApWm2GXcuMaUEKVpFWCa6Uk5VT3y44pXzUjv7UyfKoAILkaIxCBJ90rb-hQj0SOmBbu6J5gp--cXveh_j_I1BpDL4yXkaY5roagR4KZV4HnPqPzoAuXRMkg9D1c2IIuxsH3XW2UbComnYB3ZP36_elzk3qsnT125p6OPuquaowy30SFBy65N7gknz-dVooAi8Bz_bREsVMZUQ1wevfQYy8CoL2buJo3oL-tQbJTKrbPBiZQYm3H1SZ7S9JXjEpyEJgoPnmvNE1Qk8ErzBGgEACyWUFED9vQmLonSf0sPZK2ipPzAfbSStrz0RdsuLXHASIrTRRXO2miidoWuq4JqeuHAzVXqVj8iiG6VnQSMN8hW29RelzkAFwfOg9QGOSQgroVEuHNbCZebcAa-WfMSPdOvklsFWIQIc7tEZ0Qc4oKkpyced7b9OF6nQAwaZH0Risyrl_jcCKd0dYWRazieZBi2P6EV3VqCdtI8xhpYvCer4uocgKohjK07UgUVaEC2ug-oLsCYkNC1MeYrEvVkM6lJCnqihOdgUQ7-28FDGc1mmL-l0En06_GFIkCWjuUL84NZSglIuAjAgx-XkQSRl6j9XiV_O-vcqE8qmjky4mXV-Wn9Mj4AUexdJBDWX9R1CgPvFsXQlYaAKg7sPSYXzv9cfTPCRBBZZ7HExJ0g18NzVBpn3JlbO76m7WOsMm20NMcBW_rFgPVVbcx_RkjFJZGYalie3GY6mewgx_VeKktH5GAlyxmgXqT2JeF_1GcqN6AFrQoTW_QgkDDyUkqJW-_olrQiVqerertlIZ8GOosa2chps5Yw1iCaH01YmTgG8u9YCrqbwtWdL2wE1StruepZYCM4DRqCS22OLJySm6TTeql6iQAxR3n6gNFmWutp83Z87o_7FuetzaZmM6Z31Pk7JOVigfT3FgDClioeRwOy_k3WkXA_FWF4xSORqO21iqrYzkjTzZKHUAiEqUt7VNXIcfn_bWJE2wDSqElWPnqG6MDtJwqdm6CK-l1uGp3c=w2110-h1166',
    ogType: 'ウェブサイト',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'スマートオフィス構築ソリューション',
    date: '2023-10-31',
    tag1: '#スマートオフィス',
    tag2: '#在庫管理',
    tag3: '#オフィス用品',
    tag4: '#出勤管理',

    text: [
        {
            video: video,
            link : '/jp/enterprise/office/asset',
            head: '',
            subHead: '',
            desc: 'スマートオフィス構築ソリューションにRTLS（リアルタイムロケーションシステム）技術を統合すると、効果的な環境を実現できます。 RTLSは資産と作業者のリアルタイムの位置を追跡する技術で、スマートオフィスでさまざまな革新的な機会を提供します。',
            dot: ''
        },
        {
            head: '1. RTLS技術とスマートオフィス',
            subHead: '',
            desc: 'RTLS技術は、Real-Time Location System（リアルタイム位置追跡）の略で、無線通信とセンサー技術を使用してリアルタイムの位置データを収集し、分析するシステムです。この技術をスマートオフィス環境に適用すると、次の利点を得ることができます。',
            dot: ''
        },
        {
            head: '2.利点',
            subHead: '資産管理',
            desc: 'RTLSを使用すると、スマートオフィス内のすべての資産を正確に追跡できます。例えば、会議室の予約システムは、RTLSデータを使用して会議室内の設備をリアルタイムで確認し、予約できるようにします。これにより、重複した予約を防ぎ、会議室のリソースを最大限に活用できます。さらに、資産の移動と位置の履歴データを分析して、資産の寿命サイクルを最適化し、予防的な定期点検を計画できます。',
            dot: ''
        },
        {
            head: '',
            subHead: '労働者の生産性',
            desc: '特定の資産を見つける必要がある労働者は、RTLSアプリケーションを使用して資産の現在の位置を確認し、最短経路で移動できます。また、労働者のスケジュールを最適化するのにも役立ちます。 RTLSデータを活用して労働者の作業パターンを分析し、作業スケジュールを最適化することで、生産性を向上させることができます。さらに重要なことは、労働者がどこからでも効率的に作業できるようになると、労働者の満足度が向上します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'セキュリティの強化',
            desc: '管理者は、制限されたエリアへの不正アクセスを検出し、アラートシステムを使用して即座に対策を取ることができます。労働者の位置データをモニタリングして、危険な場所での作業を検出し、労働者に警告を送ったり即座に対策を取ったりできます。さらに、資産の移動と位置の変更を監視して、盗難や損傷を防ぐのに大きく貢献します。',
            dot: ''
        },
        {
            head: '3. RTLSを活用したスマートオフィスソリューション',
            subHead: '資産管理システム',
            desc: 'このシステムはオフィス内のすべての資産をリアルタイムで追跡および管理します。各資産にはRTLSタグが装備され、資産の位置、移動経路、使用履歴を追跡します。管理者はウェブベースのダッシュボードを使用してすべての資産のリアルタイムの位置を確認でき、必要な資産を素早く見つけることができます。また、位置情報に基づいて資産の活用を最適化し、不要な機器の購入を防ぐのに役立ちます。',
            dot: ''
        },
        {
            head: '',
            subHead: '労働者のステータスダッシュボード',
            desc: 'スマートオフィス環境で労働者の位置をリアルタイムでモニタリングするダッシュボードを導入することで、作業スケジュールと協力を最適化できます。例えば、どの会議室を誰が使用しているかをリアルタイムで確認でき、会議室予約システムと連携できます。労働者の位置情報を共有し、労働者間の協力を促進して生産性を向上させることができます。',
            dot: ''
        },
        {
            head: '',
            subHead: '環境制御およびエネルギー管理',
            desc: 'スマート照明システムは労働者の位置を検出し、自動的に照明を調整してエネルギーを節約し、快適な照明環境を提供します。また、労働者の位置を監視して温度を調整し、空気の品質をモニタリングして快適な作業環境を維持します。これにより、オフィス内のエネルギー消費を最適化し、環境をスマートに管理できます。',
            dot: ''
        },
        {
            head: '結論',
            subHead: '',
            desc: 'RTLSを活用したスマートオフィスソリューションは、資産管理、労働者の生産性、セキュリティの強化など、さまざまな側面で作業環境を改善し、企業の競争力を向上させる重要な役割を果たします。これらのソリューションを使用して、組織はスマートオフィス環境を構築し、将来に備えることができます。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB端末を労働者や設備に取り付けて、リアルタイムの位置追跡をサポートする製品。',
                img: productImg.product.assetTracking.thumbnail
            },
            {

            }
        ],
        solution: [
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/jp/enterprise/office/asset'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/jp/enterprise/logistics/asset'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/jp/enterprise/retail/exhibition'
            },
        ]
    }
}
