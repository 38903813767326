import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconPenSquare, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem3 } from "@assets/images/solution";
import video from "@src/assets/images/solution/retailExhibition/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/retailExhibition/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/retailExhibition/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/retailExhibition/video/sub/rtlsVideo3.mp4";



export default {
    banner: {
      title: 'Exhibition Hall Visitor Management and Analysis',
      desc: 'Accurate and objective data can drive business growth. By minimizing wasteful resources and expenses, we can provide a smart exhibition hall image and enable differentiated operations.',
    },
    video: {
      title: 'Smart Ways to Increase Visitors.',
      desc: 'Real-time Location Tracking System (RTLS) technology provides real-time information on the location of visitors. Based on accurate and objective visitor data, we can create an exhibition hall that people prefer.',
      play: video,
    },
    func: [
      {
        title: 'Real-time Crowd Tracking',
        desc: 'By tracking real-time crowd movement, we can effectively plan exhibition hall operations, including layout and exhibit placement.',
        sub1: 'Real-time Status Monitoring',
        icon1: IconClockGray,
        sub2: 'Fast Data Integration',
        icon2: IconShareGray,
        video: subVideo1,
      },
      {
        title: 'Visitor Analysis',
        desc: 'We can analyze the movement patterns of visitors within the exhibition hall. Additionally, by understanding their preferences, we can provide meaningful indicators for exhibition hall operations.',
        sub1: 'Statistical Data Provision',
        icon1: IconChartBarGray,
        sub2: 'High Accuracy',
        icon2: IconRulerGray,
        video: subVideo2,
      },
      {
        title: 'Exhibit Safety Management',
        desc: 'We monitor exhibits in real-time to prevent damage and theft. This allows for faster proactive measures.',
        sub1: 'High Accuracy',
        icon1: IconRulerGray,
        sub2: 'Fast Data Integration',
        icon2: IconChainLinkGray,
        video: subVideo3,
      },
    ],
    subfunc: {
      title: 'Various Features',
      desc: "It's not easy to obtain meaningful data from exhibition hall visitors using traditional methods. With ORBRO's new features, we can increase operational efficiency and drive business growth in exhibition hall management.",
      data: [
        {
          icon: IconChartRadius,
          title: 'Reports and Analysis',
          desc: 'We can generate reports and analytical data based on asset usage records.',
        },
        {
          icon: IconBell,
          title: 'Real-time Alert Function',
          desc: 'In the event of abnormal situations in the exhibition hall, alerts can be sent for immediate response.',
        },
        {
          icon: IconRoute,
          title: 'Daily Crowd Movement Analysis',
          desc: 'We can track the movement patterns of the crowd on a daily basis.',
        },
        {
          icon: IconDashboard,
          title: 'Status Monitoring',
          desc: 'We can monitor the current movement of visitors.',
        },
        {
          icon: IconWarning,
          title: 'Exit Detection',
          desc: 'We detect when visitors approach restricted areas.',
        },
        {
          icon: IconPenSquare,
          title: 'Worker Instructions',
          desc: 'Based on the exhibition hall situation, we can provide instructions to workers.',
        },
        {
          icon: IconChainLink,
          title: 'Data Integration',
          desc: 'We can integrate RFID, IoT, security, video, and other systems for efficient utilization.',
        },
        {
          icon: IconSecurity,
          title: 'Data Security',
          desc: 'We maintain a high level of data security to provide a reliable solution.',
        },
      ],
    },
    system: {
      title: 'System Configuration',
      img: rtlsSystem3,
    },
    productTitle: 'Product Configuration',
    product: [

      {
        tag: 'peoplePackage',
      },
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'manufacturingAsset',
      },
      {
        data: 'logisticsSafety',
      },
      {
        data: 'blogOffice',
      },
      {
        data: 'blogRTLS',
      },
    ],
  }
  