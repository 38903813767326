import { FlexStyle } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT, StyledGridRowBtn } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledLabel } from "@components/Text";
import styled from "@emotion/styled";


export const StyledNewsLetterBox = styled.div`
  background: #EAF1FD;
  border-radius: 16px;

  min-height: 400px;
  

  padding : 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & * {
    font-family: Pretendard !important;
    font-display: swap;
  }

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin : 0 12px;
    overflow : hidden;
  }
  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin : 0 12px;
    overflow : hidden;
  }
  

`


export const StyledNewletterHead = styled(StyledHeading)`
  text-align: center;
  color: #2A70F0;
`


export const StyledNewletterText = styled(StyledDisplay)`
  margin-top : 8px;
  text-align : center;
  font-weight: 700;

`



export const StyledDownloadSubmit = styled(FlexStyle)<{bgcolor : string}>`
    margin-left : 8px;
    
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align : center;
    margin-left : 8px;
    padding-top : 16px;


    width: 120px;

    border-radius: 8px;
    border : none;
    cursor: pointer;
    ${({bgcolor})=> bgcolor && `background-color : ${bgcolor};`}

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
      margin-top : 16px;
      margin-left : 0px;
      ${({bgcolor})=> bgcolor && `background-color : ${bgcolor};`}
      width : 100%;
      height : 52px;
    }

`;



export const StyledDownloadPolicyBox = styled(StyledGridRowBtn)`
    width : auto;
    display : flex;
    margin-top : 32px;
    text-align : center;
    
    @media (max-width: 430px) {
      display: flex;
      flex-direction : column;
      gap : 4px;
  }
`;
