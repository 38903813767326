import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";
import digitaltwin from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

export default {
    helmet: '| What is Digital Transformation?',
    desc: 'Digital transformation is the process of innovating traditional business and society using digital technology. In other words, it involves introducing digital technology into conventional methods to efficiently and innovatively improve tasks, services, communication, etc.',
    keywords: 'Smart city, Digital twin, Digital transformation, real-time location tracking, RTLS, orbro, Kongtech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'What is Digital Transformation? | Orbro',
    ogUrl: 'https://orbro.io/en/blog/what-is-digital-transformation',
    ogKey: '',
    ogImg: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'What is Digital Transformation?',
    date: '2024-01-10',
    tag1: '#DigitalTransformation',
    tag2: '#DigitalTwin',
    tag3: '#DX',
    tag4: '#SmartCity',

    text: [
        {
            head: 'What is Digital Transformation?',
            subHead: '',
            desc: 'Digital transformation is the process of innovating traditional business and society using digital technology. It involves introducing digital technology into conventional methods to efficiently and innovatively improve tasks, services, communication, etc. This process primarily utilizes technologies such as big data, cloud computing, artificial intelligence, and automation to restructure business models and processes, aiming to increase productivity and enhance competitiveness. Therefore, digital transformation not only brings technological changes but also influences organizational culture and work methods, understanding it as an ongoing process of continuous change into the future.',
            dot: ''
        },
        {
            head: 'Key Features of Digital Transformation',
            subHead: 'Data-Driven Management',
            desc: 'Companies collect and analyze large amounts of data to make accurate business decisions. Big data technologies and analytics help predict customer behavior and formulate strategies.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Automation and Artificial Intelligence',
            desc: 'Automation of business processes enhances productivity, and the adoption of artificial intelligence efficiently performs complex tasks such as predictive analysis, natural language processing, and image recognition, reducing human workload.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Cloud Computing',
            desc: 'Cloud technology enables flexible utilization of IT resources, allowing companies to save infrastructure costs and operate business processes more dynamically by expanding or reducing resources as needed.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Internet of Things (IoT)',
            desc: 'The Internet of Things involves connecting physical devices to exchange data and interact. It is applied in various fields, from optimizing production lines in manufacturing to convenient control in smart homes, increasing efficiency and driving innovation.',
            dot: ''
        },
        {
            video: digitaltwin,
            link: 'https://orbro.io/en/digital-twin',
            videoDesc: '(Click on the video to go to the Orbro Digital Twin page.)',
            head: 'Examples of Digital Transformation',
            subHead: '',
            desc: 'Digital transformation occurs in various industries and companies, with specific digital technologies being introduced to innovate business or optimize processes in different ways. Below are some representative examples of digital transformation:',
            dot: {
                a: '<strong> • Financial Services Industry - FinTech: </strong> Digital transformation in the financial services industry challenges traditional banking systems. FinTech companies introduce technologies such as mobile banking, electronic payment systems, and investment robots to provide customers with more convenient and affordable services. Mobile app-based banking, online investment platforms, and personal finance management apps are prominent examples of FinTech.',
                b: '<strong> • Manufacturing - Smart Factory: </strong> Digital transformation in manufacturing is manifested in the concept of smart factories. Utilizing technologies such as the Internet of Things (IoT), big data, and artificial intelligence, it optimizes production lines and analyzes real-time production data to enhance efficiency. Examples include monitoring production lines through sensors and IoT technology, predictive maintenance through big data analysis, and optimization of production processes through robots and automation in smart factories.',
                c: '<strong> • Retail - Online Shopping and Personalized Marketing: </strong> Digital transformation in retail emphasizes online shopping platforms and personalized marketing. Examples include data analysis-based personalized recommendation systems, virtual fitting rooms, and real-time inventory management. Analyzing customers\' previous purchase records for personalized product recommendations, virtual fitting rooms for online shopping experiences, and digitized payment systems within stores are part of this transformation.',
                d: '<strong> • Healthcare - Electronic Health Records: </strong> Digital transformation in the healthcare sector focuses on the adoption of electronic health record (EHR) systems and telemedicine technologies for remote diagnosis and monitoring. Managing patient data digitally and providing medical services online enhance efficiency. Examples include the adoption of electronic health record systems, video call platforms for remote consultations, and sensors for real-time monitoring of patients\' health status.',
            }
        },
        {
            head: 'Key Technologies of Digital Transformation',
            subHead: 'Artificial Intelligence (AI)',
            desc: 'AI includes technologies that enable computer systems to have human-like learning, reasoning, and problem-solving abilities. Subfields such as machine learning, deep learning, and natural language processing are used to perform various tasks such as data analysis, predictive analysis, and automation.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Big Data',
            desc: 'Big data is the technology of collecting, storing, and analyzing large-scale and diverse data. It uses large amounts of data to discover statistical patterns, trends, and insights, supporting business decision-making. It processes and stores large data sets in conjunction with cloud computing.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Cloud Computing',
            desc: 'Cloud computing is a technology that provides computer resources (servers, storage, networks, etc.) over the Internet, allowing flexible adjustment of resources as needed. Companies can save infrastructure costs and provide services rapidly through the cloud.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Internet of Things (IoT)',
            desc: 'IoT is a technology that embeds sensors and communication capabilities in objects, making them interconnected through the Internet. Various devices collect data, communicate with each other, and intelligently adapt to living and working environments.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Robotics and Automation',
            desc: 'Robotics and automation technology refer to the use of machines to replace or collaborate with human tasks. This technology is utilized not only in manufacturing but also in the service industry, enhancing productivity and efficiency.',
            dot: ''
        },
        {
            head: 'Advantages of Digital Transformation',
            subHead: 'Securing Competitive Advantage',
            desc: 'Digital transformation can secure a competitive advantage by introducing new business models and optimizing existing processes. Efficient data utilization and improved service provision can lead to greater visibility and positive evaluations in the market.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Improving Customer Experience',
            desc: 'Customer-centric digital transformation enhances the customer experience. Personalized services, convenient digital interfaces, and quick responses provide customers with a better experience, contributing to increased loyalty.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Cost Reduction and Efficiency Improvement',
            desc: 'The adoption of digital technologies such as automation, cloud computing, and data analysis makes business processes more efficient, leading to cost savings and increased productivity. Faster and more accurate task execution allows effective utilization of personnel and resources.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Enhancing Market Responsiveness',
            desc: 'Digital transformation helps businesses adapt to rapidly changing market environments. Flexible business models and data-driven decision-making assist in responding quickly to changes. The introduction of new technologies and changes to existing business models promote innovation and create new opportunities, manifesting in various ways such as entering new markets, product innovation, and forming partnerships.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'A product that aids real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.assetTracking.key1
            },
            {
                // Additional product entries can be added here if needed.
            }
        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/en/enterprise/construction/safety'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/en/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/en/enterprise/manufacturing/asset'
            }
        ]
    }
}
