import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconUserCheck, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { IconTemp } from "@assets/images/main";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/medicalWaste/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/medicalWaste/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/medicalWaste/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/medicalWaste/video/sub/rtlsVideo3.mp4";



export default {
    
    banner : {
        title : '의료 폐기물 및 자산 관리',
        desc : '의료 폐기물 관리는 의료 시설에서 발생하는 다양한 종류의 폐기물을 안전하고 적절하게 처리하여 환경 오염을 예방하고 감염 위험을 최소화하며, 법적 규제를 준수하여 인체 건강과 자원 보전에 기여하는 필수적인 작업입니다. 의료폐기물의 실시간 현황과 위치를 추적하여 의료사고 예방하고 안전한 의료 서비스를 제공할 수 있습니다.',

    },

    video : {
        title : '가장 신뢰할 수 있는 의료 폐기물 관리 방법.',
        desc : '의료폐기물 현황과 위치를 추적합니다.<br/>안전 규정을 준수하며, <strong>정확하고 확실한 의료 폐기물 관리</strong>가 가능해집니다.',
        play : video
    },

    func : [
        {
            title : '실시간 폐기물 현황 확인',
            desc : '실시간으로 의료폐기물의 종류 및 수량 현황을 파악하여, 기간별 의료폐기물 양과 의료 폐기물 처리 기간을 예측하고 관리 할 수 있습니다.',
            sub1 : '높은 정확도 제공',
            icon1 : IconRulerGray,
            sub2 : '빠른 데이터 연동',
            icon2 : IconShareGray,
            video : subVideo1
            
        },
        {
            title : '실시간 이동 현황 확인',
            desc : '의료폐기물은 특별한 관리가 필요하며, 의료진이나 환자와의 접촉 시 실시간으로 추적하여 알림을 보내는 방식을 통해 의료사고를 사전에 예방하고 환자들에게 신뢰감을 불어넣을 수 있습니다.',
            sub1 : '위험 자산 지정',
            icon1 : IconWarningGray,
            sub2 : '대응 방식 설정',
            icon2 : IconBellGray,
            video : subVideo2
        },
        {
            title : '자동 입출고',
            desc : '의료폐기물 자동 입출고는 작업 효율성 향상, 인적 오류 감소, 실시간 대응 능력 강화, 안전한 처리 확보, 규정 준수를 통해 의료 기관에서 효과적이고 안전한 폐기물 관리를 실현합니다.',
            sub1 : '높은 정확도 제공',
            icon1 : IconRulerGray,
            sub2 : '빠른 데이터 연동',
            icon2 : IconChainLinkGray,
            video : subVideo3
        },


    ],



    subfunc : {
        
        title : '다양한 기능',
        desc : '의료 폐기물은 끝까지 긴장을 놓쳐서는 안됩니다. 작은 방심과 실수로도 큰 의료 사고로 이어질 수 있습니다. 따라서 매우 정밀한 관리가 필요하며 안전이 보장되어야 합니다.<br/>오브로는 안전하고 효율적인 의료 시설을 구축하기 위해 다양한 기능을 제공합니다.',
        
        data : [
            {
                icon : IconChartRadius,
                title : '리포트 및 분석',
                desc : '폐기물의 입출고 데이터를 기반으로 통계 자료를 만들고 분석할 수 있습니다.',
            },
            {
                icon : IconBell,
                title : '실시간 알람 기능',
                desc : '고위험 의료폐기물에 환자가 접촉하는 상황이 발생하는 경우 알람을 보내 즉각적으로 대처할 수 있습니다.',
            },
            {
                icon : IconRoute,
                title : '폐기물 동선 조회',
                desc : '의료 폐기물의 경로를 추적하여, 안전사고를 예방합니다.',
            },
            {
                icon : IconDashboard,
                title : '현황 파악',
                desc : '현재 의료폐기물 보관창고에 폐기물 현황을 실시간으로 파악 할 수 있습니다.',
            },
            {
                icon : IconTemp,
                title : '온도, 습도 등 환경 분석',
                desc : '의료폐기물 창고 내부 환경 요인을 모니터링하여 이상이 있는 경우 실시간으로 감지할 수 있습니다.',
            },
            {
                icon : IconUserCheck,
                title : '작업 효율 향상',
                desc : '자동 입출고 시스템을 통해 작업 효율성 및 인적 오류 감소가 가능합니다.',
            },
            {
                icon : IconChainLink,
                title : '데이터 연동',
                desc : 'RFID, IOT, 보안, 영상 등의 시스템과 연결하여 효율적으로 활용할 수 있습니다.',
            },
            {
                icon : IconSecurity,
                title : '데이터 보안',
                desc : '높은 데이터 보안 수준을 유지하여 신뢰성이 높은 솔루션을 제공합니다.',
            },


        ],
    },

    system : {
        title : '시스템 구성',
        img : rtlsSystem
    },

    productTitle : '제품 구성',
    product : [

        {
            tag : 'assetPackage'
        },
        {
            tag : 'workerPackage'
        },
    ],


    contents : [
        {
        data : 'medicalPatient'
        },
        {
        data : 'officeAsset'
        },
        {
        data : 'blogMedical'
        },
        {
        data : 'blogRTLS'
        },

    ]



}