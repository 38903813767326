export default {


    support : [

        {
            name : '엔터프라이즈',
            path : '/enterprise'

        },
        {
            name : '지원',
            submenus : [
                {text:'기술', path:'/enterprise/technology/uwb'},
                {text:'블로그', path:'/blog'},
                {text:'영업문의', path:'/request'},
                {text:'회사소개', path:'/company'},
            ]
        }

    ],


    lang : [
        {
            name : '언어',
            submenus :[
                {text:'한국어'},
                {text:'English'},
                {text:'日本語'},
                {text:"中文"}
            ]
        }


    ]


}