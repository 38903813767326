
import styled from "@emotion/styled";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";



export const ModalContainer = styled.div`
 
  width : 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 999;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
    height : 100%;
    text-align : center;
  }

`;



export const DialogBox = styled.dialog`
  width: 400px;
  height : fit-content;
  padding : 24px;
  display: flex;
  
  border: none;
  border-radius: 16px;
  box-shadow: 0 0 30px rgba(30, 30, 30, 0.185);
  box-sizing: border-box;
  background-color: white;
  z-index: 10000;

  margin: 0 auto;
  

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
    width : 100%;
    height : 100%;
    display : flex;
    flex-direction : column;
    text-align : start;
    top: 64px;
  }


`;



export const DialogEmailBox = styled.div`


  width : 100%;
  height: 56px;
  border : 1px solid rgba(0, 0, 0, 0.08);
  border-radius : 8px;

  color : #BDBDBD;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
  }
  

`;


export const DialogEmail = styled.input`
    border : none;
    border-radius : 8px;
    height : 100%;
    width : 100%;
    padding-left : 12px;

    ::placeholder {
      color: #BDBDBD;
    }
    
`;


export const DialogPolicyBox = styled.div`

    display : flex;
    width : 100%;
    gap : 2px;
    text-align : center;
`;

export const DialogBtnBox = styled.div`
    display : flex;
    flex-direction : row;
    gap : 8px;
    margin-left : auto;
    width : fit-content;
    height : 44px;
    

`;

export const DialogBtnCancel = styled.div`
  width: fit-content;
  height: 44px;
  border-radius: 8px;
  border : none;
  padding : 12px 24px;
  cursor: pointer;

  background-color : #FFFFFF;
  display : flex;

  justify-content : center;
  align-items : center;

`;
export const DialogBtn = styled.div<{bgcolor : string}>`
  width: fit-content;
  height: 44px;
  border-radius: 8px;
  border : none;
  padding : 12px 24px;
  cursor: pointer;

  background-color : black;
  display : flex;

  justify-content : center;
  align-items : center;

  ${({bgcolor})=> bgcolor && `background-color : ${bgcolor};`}

`;


export const DialogBorder = styled.div`

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : calc(100% + 48px);
    margin : 0 -24px;
    border-bottom : 1px solid rgba(0,0,0,0.08);
    padding-bottom : 16px;
    margin-bottom : 24px;
  }
`



export const DialogSpanText = styled.span<{deco : boolean, color : string}>`

  color : #646464;
  font-family: Pretendard;
  font-display: swap;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  ${({deco})=> deco? 'text-decoration : underline;' : ''}
  ${({color})=> color && `color : ${color};`}
  


`;

export const PolicySpanText = styled.span<{color : string}>`
  font-family: Pretendard;
  font-display: swap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  ${({color})=> color&& `color : ${color};`}

`;



export const ModalEmailInput = styled.div<{focus : boolean}>`
  display : flex;
  width : 100%;
  height : 100%;
  ${({focus})=> focus? `margin-top : 4px; align-items : start` : 'margin-top : 0px; align-items : center;'}

`;