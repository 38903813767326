import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| Smart Logistics Center Utilizing UWB Technology',
  desc : 'Smart logistics centers play a crucial role in modern logistics and supply chain management. Speed, accuracy, and efficiency are key elements in all logistics operations, and optimizing these factors is a critical task for business success and competitiveness.',
  keywords : 'Large-scale logistics warehouse management, logistics center management, logistics warehouse management, smart logistics, inventory management, smart inventory management, logistics warehouse inventory management, large-scale logistics warehouse inventory management, rtls, orbro, contech, real-time location tracking, uwb',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'Smart Logistics Center Utilizing UWB Technology :: Orbro Blog',
  ogUrl : 'https://orbro.io/en/blog/uwbLogistics',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4PMiWc5DIFUhcXlBfDRp2o8d3EJ_Z4ffawsJSoDWT70JslXvsXOw5KgNd3q5wgkx3TUzMx42JtuNcCYfLVsl4W8GEIevw-Q84jKo4Nm-Bu0CdH4qgYaA6OwgyC4paHI32-VbALd50n6lLWH0CNWUBFGOgkoXzFpchLLz2INpLMFbzPNsPlnWPK1V9vPzvJlVDr6GdB4SKKQM0XHaIbQFHd9xwu4quaqFpAPoJ6HMEZb_-PMBnKc0D5CSBQ0cBlJqHFHacrj-TPxhfLG1QeDfnIkE25ooGaiwz76DMvLqIqnCBcYPYsStKF8weqz_g5Sh3LyHpvveZL2GPOiRD0SvaNatRUPDeyK5_11bB9t42SpjZZuEYUHIb-ivBBOXxdCGJt4MDAl3pcM65PWS1DDBqUcncZ1uHo8_1b5wHawqsOZ6F6H5hFzzLKZHEnfibNYE0UgKoBvRHlCqz5y6SPTqcRTsaUzu0aj7LRETSi4adhGjvfgOQ0mILavMKp9ei41wAcC7zWl67f5uYGBTU9z-Sq8C0ncIG_tUmKIf3-bXeS0dBjJyYZQCPSytqKSFAPWzcxUHqp7GKdX6cFyMRkop-S-SzwB0spx8E_3wdecdTkX3H6TX_uSGKaFOmSZbMrm6YZaEJFUrDH7Zl6RAx-sIJoDA1qoEx4ULktfnVpEt-DaFyVJDuiY-qC54aoH8YJ-FHWixIFG6BpVGCp6pME79yITG3fDMVFfRfSnz32WTrpb_8htFe2iu3M4d8NRRm48c5ovfiTkWMRNLFRdSkRPDZ0qGVEs6FSU-UrGu95_QwTHwqWWceSPTdxRiPAMtR2x1zyg7au0pLgIDeVoChJuZ9nE6t6659ZBQsZnWHA_DOSrdWzMPvdrr7qTj5_byxnTgW0vC8flxGkf0I8g8DR3cWtJ7tWVMQpUwTh5dOl_7fQ62ZRN9fgnXvx-GKnP1ZkEz3Vzuz4Z4PPVAmS7gOHS8L-PMTJ9WF7-mwLVknLpgxrlSGhYuW4ognx12nU0yiuSDxPKLq-jS5DFHHeIN6O4SQtVgRnga99vTOi9QmhV_pWMXbuvhzEhbGMgxkHTLZV_iwH0PTl6MWgJAG2HOaUSAT9uZ71Kvv6RWyt_VLkET4AuJ480_wC7DHvDneJqb7pgtUZpbVa_aYXNB5AouMSyFVoHrsGRLZTJ1vhiKdQgfy5exsXJY2e6qCVrSpsVYQufijgQ3dc4PjAGJ8IY8krq2igby7D2AGnRDmJO6q7zwf7hNktGXBM4jR6YcUh1GbRmzm4Ios1p7z3EaKsY59m7zLrNjbZHA6o2Dcgf2cxFNBfwMiShDmtQSMQZHzFEY4x6PCByTF_Pb0tKe1Pu5iuiUm-HYkboa8BEWXYjFVvEc05axWugdX6zrW4ijr0xd_xMSZRCmZ0lXPBOekY03zX5ajinw6TRDfQo3l5HqEmCXgAZKlWZAgfBp67huSzzosSWfm2dY9b4bTGfgrfKZTeu9IJx8El-7m7PcTU4Bv-g584Bp0wcyv2tjG2aDCIz1BgGuXW7oNyImmGFxwWArxN0_Xk=w2000-h1946',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'Smart Logistics Center Utilizing UWB Technology',
  date : '2023-09-05',
  tag1 : '#LargeLogisticsCenter',
  tag2 : '#SmartLogistics',
  tag3 : '#InventoryManagement',
  tag4 : '#SmartInventory',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'Smart logistics centers play a crucial role in modern logistics and supply chain management. Speed, accuracy, and efficiency are key elements in all logistics operations, and optimizing these factors is a critical task for business success and competitiveness. Ultra-Wideband (UWB) technology is emerging as a modern solution to these challenges. In this article, we will explore how UWB technology can be used to create smart logistics centers and its advantages.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'What is UWB Technology?',
      desc : 'UWB technology supports wireless communication using a very wide bandwidth. This wide bandwidth is highly beneficial for rapidly transmitting data and accurately tracking locations. UWB technology is known for its precision location tracking capabilities down to millimeter accuracy, making it a versatile technology with limitless applications in logistics centers. Below, we will discover the advantages of smart logistics centers in more detail.',
      dot : ''
      
    },{
      head: 'Advantages of Smart Logistics Center Using UWB Technology',
      subHead: '',
      desc: '',
      dot: {
        a: '• <strong>Precision Location Tracking</strong>: With UWB technology, you can track the precise location of all objects within the logistics center in real-time. This enhances accuracy and efficiency in inventory management, item movement, and operational processes.',
        b: '• <strong>Automation and Robotics Support</strong>: Effective operation of robots and automation equipment is crucial in smart logistics centers. UWB technology enables real-time communication with robots and automation equipment, enhancing process automation.',
        c: '• <strong>Enhanced Safety and Security</strong>: UWB can be used to monitor all activities within the logistics center and integrate with various security and safety systems, including item movement, access control, and fire detection.',
        d: '• <strong>Real-time Data Analysis</strong>: UWB technology allows real-time data collection and analysis, enabling real-time decision-making for logistics operations. This facilitates process optimization.',
        e: '• <strong>Energy Efficiency</strong>: UWB technology accurately tracks the location of objects, minimizing energy consumption. It allows more efficient resource utilization and the construction of an environmentally friendly logistics center.',
      }
    },
    {
      head: 'Applications of UWB Technology in Logistics Centers',
      subHead: '',
      desc: '',
      dot: {
        a: '• <strong>Inventory Management</strong>: Accurately tracking inventory within the logistics center helps prevent inventory errors and reduces inventory costs.',
        b: '• <strong>Heavy Equipment Management</strong>: Tracking and managing heavy equipment used in logistics operations becomes easier and also helps prevent accidents.',
        c: `• <strong>Worker Management</strong>: Real-time tracking of workers' locations allows more efficient staff allocation and rapid response to safety incidents.`,
        d: '• <strong>Automated Inbound and Outbound</strong>: Automatically recording the time when specific assets pass through specific areas helps reduce unnecessary manpower.',
        e: '• <strong>Automotive Assembly Lines</strong>: Using UWB in automotive manufacturing plants allows tracking the precise location of automotive components, improving production efficiency.',
        f: '• <strong>Unmanned Drones</strong>: Using unmanned drones within logistics centers enables efficient product transport and monitoring.',
      }
    },
    
    {
      head : '',
      subHead :'Conclusion',
      desc : 'UWB technology is leading the innovation of smart logistics centers, bringing significant changes to logistics and supply chain management. With advantages such as precise location tracking, automation support, enhanced security, energy efficiency, and real-time data analysis, businesses can enhance their competitiveness and achieve more efficient logistics operations. Companies that actively adopt UWB technology to build smart logistics centers are poised for greater success in the future.',
      dot : ''
    },

  ],

  link : {
    page : 'rtls',
    numb : '1',
    product : [
      {
        text : productPurchase.purchase.asset.assetTracking.title,
        title : productPurchase.purchase.asset.assetTracking.title,
        serial : productPurchase.purchase.asset.assetTracking.serial,
        desc : 'This product involves attaching UWB terminals to workers or equipment to assist in real-time location tracking.',
        img : productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution : [
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/en/enterprise/logistics/asset'
      },
      {
        img : logisticsSafety.thumb,
        data : 'logisticsSafety',
        link : '/en/enterprise/logistics/safety'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/en/enterprise/manufacturing/asset'
      }
    ]

  }


}
