import { FlexStyle, Vertical } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";


export const StyledRequest = styled(Vertical)`
  width: 100%;
  height: 100%;
  
  & * {
    font-family: Pretendard !important;
    font-display: swap;
  }
`

export const StyledRequestMain = styled(Vertical)`
  padding: 128px 0;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding: 96px 0px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 96px 0px;
  }
`;

export const StyledRequestInfo = styled(Vertical)`
  width: 100%;
`

export const StyledRequestMainRow = styled(StyledGridRow)`
  row-gap: 128px;
`;


export const StyledRequestDisplay = styled(StyledDisplay)`
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;

  & strong {
    white-space: pre-wrap;
  }

  & strong:nth-of-type(2) {
    font-weight: normal;
  }

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    display: block;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    display: block;
  }

  @media screen and (max-width: 400px){
    display: flex;
  }
`

export const StyledRequestCheckList = styled(Vertical)`
  margin: 48px 0 128px;
  gap: 16px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin: 36px 0;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin: 36px 0;
  }
`;  

export const StyledRequestCheckItem = styled(FlexStyle)`
  align-items: center;
`;

export const StyledRequestCheckItemIcon = styled.img`
  margin-right: 16px;
`;

export const StyledRequestCheckItemName = styled(StyledParagraph)`
  color: ${({ theme }) => theme.CONTENT_SECONDARY};
`;

export const StyledRequestCall = styled(StyledParagraph)`
  margin-bottom: 4px;

  & strong {
    font-weight: normal;
    text-decoration: underline;
  }
`;

export const StyledRequestCallTime = styled(StyledParagraph)`
  color: ${({ theme }) => theme.CONTENT_TERTIARY};
`;