import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| RTLS Solution for Safe and Smart Construction Sites',
  desc : 'Smart construction sites enhance productivity, efficiency, and worker safety. RTLS technology can be the most suitable solution to enable the creation of these smart construction site systems.',
  keywords : 'Worker Safety Management, Construction Site Safety Management, Construction Sites, Site Safety, Construction Safety, Smart Construction, Heavy Equipment Management, Real-Time Location Tracking, Safety Management, RTLS, UWB, Orbro, KonTech',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'RTLS Solution for Safe and Smart Construction Sites :: Orbro Blog',
  ogUrl : 'https://orbro.io/blog/constructionSafety',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4ODZOuIwgi2GmgeN38XOohY2I6ZhTq1OX26xq5S1gJqAGQsMoshxsOsqKhEqbIAKQ4m2i0wnTaha9gJzWrOTQJjh-kG35v_PKDbGn6oQKyjdPrG83wKwq9aDBoWxLoDMDp5REkLIfvg86TqENuhdkKlvaCE8JtLLjZtoMWuiSQCYZYRXIk1QRwMiLlKrLdUR3Ae7WNDbQamCAEzOhA4iB7NibqGzN1cFrlfeKXbBN95eOv_6gdNvv8egQ9WRKK1ABThUboxhbiTLeGy74mZO217xUHPe9qAWl_BitXQj3yMJXo_3BQUooKtjvw8Lm1OAObOJX5ZUIyV_DF_WDnsYCP6ypezD9GmdGsasbiagdvp9zxbH2CvrIBSLYhNFv34TTAiztM6BukR-0OJInkIZHvaJQyilhLrE44lrqa8M3IzDhzWEOK-bb3Tz5mXAe370UpbaXxkMHaEJS72MZgBbyUj-J3f7J889zkMt7U7UffPNi4BGFhgb5prG4kOatNmsgh8pmVHsAk4xHZ8Fewny7ASDPV2Q4z9YuGgCynJaNDxYwb3FurwZ8G-Nfwgdt2KfslDc1yQ5I-IX-4gd8DTlSCr596Caq2a1UsBSEyl4J9lioE1CFUj22KymcIVvSBhkp285DCyho_1lztbytT6HMUyMOyaMMvEyNnug6qmzJeQEVITT9AqDTwv4crACqyBUCZXmdgcBNa45zkwL_Jsx1akoJohEvzXJ7qMFfZHXmPapV4uS5jSmJGSkvVPCjjhTqGFvy0NDStFwvdrwqGHnI34q1-p2wTpJG2kvUhUH7grFZhik9E7V--QY6HF4fkAjtjgmSiY3mrf4qGOxawVFTvH0n5R4fppcdngOJRnpXyeNUIm-1L8-Qe_A7xZl04bFY7AMwjt9u-DeXWOdd6buaBYjig4EHIovTR2IeuTI2BtFyttqCDwpea3cJuouIq4VZ_m8QEFTUGrq8hkviXmE8iVc80a_vpEfJebHjzWM5f48cjhEk-LVjsrECpYd1lU9TYRV3a6mLY0VxcVpNdN8rxI7cQe5YgRBJGSHR4ECjku3U9jvPJeMJ7hJ4V9D8donENtGVDWuiVPXUk6RH9XU9yeVxMTApzrsB58tA03IPp8f2Aejpzr8ksllmvuubwwbr3JXX6nqREowvgldTiKTGrvT0ca1ol6reTjqX5Xr06c0n5F2v-aYGnPNajdoQNBxtUcxpQRVd-yKyJryJXz56r_J6c2QiiVIADQMjYUH9ULyFT0d0UvnwJS4cTdqEGBzUoJNTLZZi2JtD3cJvG8vrrz5xHUsR2SIkyNjCGLZyOouOh7etxxjVK_MNFYqDGli4SQhp48hMgCc56c4vMuWmZXZlSgUueLUUtEBsLpUFaTERHwqNvJaRHFJOpIyNIOsibD94KowEbCGWg9pydqRNrAdY7TQ7HbAUqUTWFMdCD4Kzkdx4cKPIzL9YTVCvBtk23t0a9E343R1x38JavpVlYeMQVxXxDG5jpP5O9lXmBitXgEfDHBmTnT_BUAvgjYjkUG5djwJfZ6E8K_FRHXtgkB-M62IYcrVsaZAd_9c1Mq8YKMP-fJ=w2052-h1199',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'RTLS Solution for Safe and Smart Construction Sites',
  date : '2023-09-19',
  tag1 : '#WorkerSafetyManagement',
  tag2 : '#ConstructionSiteSafetyManagement',
  tag3 : '#SmartConstruction',
  tag4 : '#HeavyEquipmentManagement',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'The construction industry plays a significant role in modern society, and as a result, the introduction of technology to increase the efficiency and safety of construction sites is a crucial challenge. Recently, Real-Time Location Tracking System (RTLS) technology has brought innovative changes to construction sites, and its adoption plays a vital role in building smart construction sites. In this blog, we will explore the concept of RTLS technology and how it can help in creating smart construction sites.',
      dot : ''
      
    },
    
    {
        head : 'Concept of RTLS Technology',
        subHead : '',
        desc :'Real-Time Location Tracking System (RTLS) is a technology that utilizes wireless communication to track the precise location of objects or entities in real-time. RTLS employs various technologies such as GPS, RFID (Radio-Frequency Identification), UWB (Ultra-Wideband), BLE (Bluetooth Low Energy) to collect and manage location information. In construction sites, these technologies can be used to efficiently manage equipment, materials, and labor.',
        dot : ''
        
      },
      {
        head : 'Advantages of RTLS Technology',
        subHead : '',
        desc :'Introducing RTLS technology to construction sites offers several advantages.',
        dot : {
            a :' • <strong>Asset and Equipment Management</strong>: RTLS allows real-time tracking of the location and status of construction equipment and materials, enabling efficient inventory management and maintenance.',
            b :' • <strong>Enhanced Safety</strong>: Real-time monitoring of worker locations helps prevent accidents and enables rapid responses in emergency situations.',
            c :' • <strong>Increased Productivity</strong>: Managing construction schedules and workloads using RTLS enhances productivity while preventing excessive time and resource waste.',
            d :' • <strong>Data Analysis and Prediction</strong>: RTLS collects and analyzes data, helping understand construction work patterns and supporting decision-making and efficient resource allocation.',
          }
        
      },
      {
        head : 'Applications of RTLS for Building Smart Construction Sites',
        subHead : 'Automated Equipment Operations',
        desc :'Utilizing RTLS for automated equipment operations is one of the key applications that enhance productivity and cost savings on construction sites. Attaching RTLS tags to construction equipment enables real-time tracking of their location and status. This allows construction managers to optimize equipment movement paths and minimize fuel consumption. For example, in situations where cranes are inefficiently placed, time and energy can be wasted in transporting materials. With RTLS, crane positions can be optimized to reduce material transport time and save energy. Such automated systems achieve faster work progress, increased productivity, and improved energy efficiency.',
        dot : ''
        
      },
      {
        head : '',
        subHead : 'Enhanced Worker Safety',
        desc :'Worker safety is paramount on construction sites. RTLS technology significantly contributes to enhancing worker safety. Workers are required to wear RTLS tags, which enable real-time tracking of their locations. Alerts can be sent if workers enter hazardous areas or if there is a potential for accidents. Additionally, worker health can be monitored, and appropriate actions can be taken if they are working in adverse conditions. This system helps prevent accidents and strengthens worker safety.',
        dot : ''
        
      },
      {
        head : '',
        subHead : 'Materials and Equipment Management',
        desc :'Managing materials and equipment is a critical factor that can influence the success of construction projects. RTLS allows real-time tracking of the location and status of materials and equipment used on construction sites. This leads to more efficient inventory management and minimizes losses. For example, it can prevent situations where essential materials are lacking at a particular point or where there is duplicate storage. Additionally, it helps identify idle equipment and optimize maintenance schedules.',
        dot : ''
        
      },
      {
        head : '',
        subHead : 'Process Optimization',
        desc :'RTLS is also used to optimize construction processes. Construction projects consist of various work stages that require coordination and adjustment. By using RTLS to monitor and analyze the workload and progress of each stage in real-time, construction managers can ensure compliance with schedules, maintain quality, prevent bottlenecks between tasks, and optimize processes.',
        dot : ''
        
      },
      {
        head : '',
        subHead : 'Environmental Management',
        desc :'Environmental management is becoming increasingly important on modern construction sites. RTLS can be used to monitor and manage energy consumption, waste management, and the replacement of aging equipment on construction sites. This contributes to eco-friendly construction and improved energy efficiency, helping create sustainable construction sites.',
        dot : ''
      },
      {
        head : '',
        subHead : 'Improved Customer Service',
        desc :'RTLS can also enhance customer service. Customers expect real-time monitoring of project progress and prompt responses to issues. By using RTLS to provide transparent project progress and rapid responses to problems, customer satisfaction can be increased.',
        dot : ''
      },
      {
        head : 'Conclusion',
        subHead : '',
        desc :'The adoption of RTLS technology is essential for the construction industry to build smart and safer sites with greater efficiency. This technology enables construction site managers to more efficiently manage and monitor assets, workers, materials, equipment, and create smart systems supported by data analysis. Therefore, the future of the construction industry looks bright with RTLS technology. Actively adopting RTLS technology is a crucial task in creating smarter and safer construction sites.',
        dot : ''
      },
  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
        {
          text: productPurchase.purchase.worker.workerTracking.title,
          title: productPurchase.purchase.worker.workerTracking.title,
          serial: productPurchase.purchase.worker.workerTracking.serial,
          desc: 'A product that helps real-time location tracking by attaching UWB terminals to workers or equipment.',
          img: productImg.product.workerTracking.thumbnail
        },
      {

      }

    ],
    solution : [
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/en/enterprise/construction/safety'
        },
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/en/enterprise/construction/asset'
        }, 
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/en/enterprise/logistics/asset'
        }
    ]

  }


}
