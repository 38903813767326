import {  medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {


  helmet: '| NFC란?',
  desc: 'NFC는 근거리 통신 기술로, 근거리에서 무선 통신을 가능케 하는 기술입니다. 이 기술은 소형 전파를 이용하여 매우 가까운 거리에서 데이터를 교환할 수 있도록 합니다.',
  keywords: 'nfc, uwb, 무선 통신, 위치 추적, 물체 위치 추적, 사람 위치 추적, 태그, 비콘, rtls, orbro, 오브로, 콩테크',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'NFC란? | 위치추적과 디지털 트윈 전문 플랫폼, 오브로',
  ogUrl: 'https://orbro.io/blog/nfc-uwb-ble',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDlx0aose_UUwB8yAMLpMzvTdJK7dGM_ga67nWJqGp-Ad-0qXiKbUEUjAYHHCIw3c5fBJeOurZ9GbBlOLASQQ-fxRGXYfqb0RRl8SAXz05tRfZVuRggCXCN4Lhgi5bmQUDXYI9RvZTvlt-aRguSSi_Stihe1t7AUtepg-f_OTkuBEX5XDNmq1mSEBJVgqC26dYfGuJh3zgT12nYtPK60Bz8xB5ydx2cmvaAbP-No0hvZIWMciZv6XarrpKcR-Eu6YSdcsFOZ3J9wjVTeyyc6S-gVqoxW4abVCEfQaItklbow0uqPjlPT_KHGCGQa0SKOW97pUKx0PO8uSBpJMD6AzeJ4Yh-01yRQqpC6vcBsmNvEBxMvLvTm8BX5mRmBV48v6kHK_P7WycSemW5wnW58cEHX5Wjo2tV2tTJHX9y_qWwOZZWX7eFx73V0WmX-vG4ABxNwFr7u-p3TKA6nWMxJzwGyLw_9ouTexsqnfiBXf8MhruRLbrNobB19ErL_DHgZi4kPuI8PDDdT4kvxwE9lt4LpJuArzzdpvwhT4XnDrFl73C616KHcorzOcrOJxw-jzKhFvc-q17_3S--zh2PcMpG5cvL7DJ1Cgy62atJyXB5IB67bDeOUAnB_Zr3RdAUcVBg3_yADYB7htLduaNVCYlC8F9ucLtTOKYqDU_Kc0G53AHrpmiqbbWxLOyGamXVkCO9EzJaWC8EVDcclcnwMUWf_5vC_tIg8wnNp4nSycN0vVAfm-iukMGeHV3onRhtyGa--Q_31wtjzULC5n1ffWD2DgX0LCxksQOCkOmzWPFRhGC-UpLo1hUE6ar64oJbH06kISvVcqIqPYMLHIqWcMchD9FMPlBI2P0XtEJaZtNQ8OQ7XdAJzRXi6TK_jo16h0jAW9Bnh5sv9BA0eJoEQz4d0jD1wMwPB9StiMAHG_6pTNleKUOO92VU0weNP0U6p8gqceFhBSgierz4ukU8ud1rti_cnmtvPUHKNnPGoKMb-np6KexGgI3D9XWzzAsaUrAwziO87m2zHYt2SezQ3urRH9gG1yhokna4WTjfrxED9WfE2yI9uC3xMuALyZLompGIZk-mz9k1N8O4kZRHSLtIt_TQSUDILXDQsIwVMeMs1q_U9i6L10APLVQAjMZQL5dxa557zkCPB59Zytf72ASMHtp9TL6LGnpV5bPGGl3GSNPkH8sjR7UkuMmEFrUbbohLMbhHtxGneSDUtRJO7bdQsysoOK_-ZxsXqnkQVa1KX-J153QU3yI4IlaViIpjxY9w5kptwJPL_WFlXcjUnR-JnSu5c3FPe8y8gae7yt91cT6KECSmJelkYxaEan1q9_hAgXCvb24BKiRGWrNMAacUoPz8hljTI0cf8fB9GuDiPoqYgxJKcZFdicKosSD3SZMXnLbF4hZE96gFYFWuOIetfv7pzlsg08ZZ0EiklpgY1dshXnDmrLrKcnB4v_mWgvqKA1tyQnCZrAYyX-Z7zW_cj00TQX28EqAsV3cAkEVZTs_ClA1g-xHvUBIm-0logZKUhbQUT9TBz5eip_Aw_rexmWpOV4XcV=w1984-h1214',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'NFC란?',
  date: '2024-01-11',
  tag1: '#nfc',
  tag2: '#위치추적',
  tag3: '#무선통신',
  tag4: '#자산관리',

  // <br/>
  text: [
    {
      head: 'NFC란?',
      subHead: '',
      desc: `NFC(Near Field Communication)는 근거리 통신 기술로, 근거리에서 무선 통신을 가능케 하는 기술입니다. 이 기술은 소형 전파를 이용하여 매우 가까운 거리에서 데이터를 교환할 수 있도록 합니다. 주로 스마트폰이나 기타 전자기기 간의 간편한 정보 전송, 모바일 결제, 출입 카드 시스템 등에 활용되며, 10cm 이내의 짧은 거리에서 작동합니다. NFC는 빠른 속도와 간단한 사용성으로 주로 결제 및 간편한 데이터 전송에 활용되고 있습니다.`,
      dot: ''
    },
    {
      head: 'NFC의 작동 원리',
      subHead: '',
      desc: 'NFC(근거리 통신 기술)의 작동 원리는 매우 효과적이고 간단합니다. 먼저, NFC는 디바이스 간에 작은 전자기 필드를 생성합니다. 이 필드는 마치 레이더에서 사용되는 것과 유사한 개념으로, 데이터를 교환하는데 필요한 통로 역할을 합니다. 이 작은 전자기 필드는 각 디바이스 간에 매우 가까운 거리에서만 효과적으로 작동하며, 보통 10cm 이내의 거리에서 이루어집니다.<br/>NFC의 작동 원리는 RFID(Radio-Frequency Identification) 기술에 기반합니다. 따라서, 전송하는 디바이스(리더 또는 초기화된 기기)와 수신하는 디바이스(태그 또는 카드) 간에는 무선으로 데이터가 교환됩니다. 이 때, 전자기 필드를 통해 양쪽 디바이스는 서로 인식하고 통신을 시작합니다.<br/>주요한 특징 중 하나는 NFC의 작동 거리가 상대적으로 짧다는 것입니다. 이는 보안적인 측면에서 이점을 제공하며, 사용자가 의도하지 않은 데이터 교환을 방지합니다. 또한, 이 작동 원리는 전송 속도가 빠르고 데이터를 실시간으로 교환할 수 있게 합니다.',
      dot: ''
    },
    {
      head: 'NFC의 주요 용도',
      subHead: 'NFC 모바일 결제',
      desc: 'NFC는 주로 스마트폰을 이용한 간편 결제에 활용됩니다. 사용자는 NFC가 지원되는 결제 터미널에 스마트폰을 가까이 대면하여 미리 등록된 결제 정보를 전송함으로써 신속하고 편리하게 결제를 완료할 수 있습니다. 대표적인 예로는 Apple Pay, Google Pay, Samsung Pay 등이 있습니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '데이터 전송',
      desc: 'NFC를 사용하여 두 디바이스 간에 간단한 데이터 전송이 가능합니다. 예를 들어, 스마트폰 간에 연락처 정보, 사진, 비즈니스 카드 등을 손쉽게 교환할 수 있습니다. 또한, NFC 태그를 활용하여 정보 전송이 자동으로 이루어지도록 프로그래밍할 수 있습니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '스마트폰 기능 확장',
      desc: 'NFC는 스마트폰의 기능을 확장하는 데 사용됩니다. 예를 들어, 홈 오토메이션 시스템과 연동하여 스마트폰을 특정 장치에 태그함으로써 조명, 난방, 보안 시스템 등을 컨트롤할 수 있습니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '전자 출입 카드',
      desc: 'NFC는 전자 출입 카드 시스템에서 흔히 사용됩니다. 스마트폰이나 NFC 태그를 통해 건물이나 시설에 출입할 수 있으며, 더 높은 보안성과 편의성을 제공합니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '의료 분야',
      desc: '환자의 식별과 접근 제어, 의료 기록 및 약물 관리, 응급 상황 대응, 그리고 진료실 자동화와 같은 다양한 응용 분야에서 효과적으로 활용됩니다. NFC는 의료 시스템을 효율적으로 자동화하고 환자의 안전성을 강화하는 데 기여하며, 응급 상황에서 신속하고 정확한 의료 서비스를 제공하는 데 도움이 됩니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '스마트 포스 기술',
      desc: 'NFC는 매장이나 카페 등에서 스마트 포스(Point of Sale) 시스템과 연동하여 결제 및 할인 정보 전송에 사용됩니다. 고객은 스마트폰을 결제 터미널에 가까이 대면하여 손쉽게 결제할 수 있습니다.',
      dot: ''
    },
    {
      head: 'NFC와 블루투스 차이점',
      subHead: '통신거리',
      desc: '',
      dot: {
        a : '<strong> • NFC : </strong>매우 가까운 거리에서만 통신이 가능하며, 일반적으로 10cm 이내입니다. 이는 주로 접촉 또는 매우 가까운 거리에서의 데이터 전송에 사용됩니다.',
        b : '<strong> • 블루투스 : </strong>훨씬 더 넓은 범위에서 작동하며, 일반적으로 최대 100 미터까지의 통신이 가능합니다. 이는 보다 원격에서의 데이터 전송 및 장치 간 연결에 사용됩니다.',
      }
    },
    {
      head: '',
      subHead: '전송 속도',
      desc: '',
      dot: {
        a : '<strong> • NFC : </strong>일반적으로 블루투스보다 전송 속도가 느립니다. 주로 소량의 데이터를 전송하는 데 사용됩니다.',
        b : '<strong> • 블루투스 : </strong>높은 전송 속도를 제공하며, 대용량의 데이터를 빠르게 전송하는 데 효과적입니다.',
      }
    },
    {
      head: '',
      subHead: '용도',
      desc: '',
      dot: {
        a : '<strong> • NFC : </strong>간단한 데이터 교환 및 결제 시스템, 태그 리딩(태그에 저장된 정보 읽기)과 같은 단거리 응용에 주로 사용됩니다.',
        b : '<strong> • 블루투스 : </strong>주로 이어폰, 스피커, 키보드 등과 같은 장거리 및 대용량 데이터 전송이 필요한 응용 분야에서 사용됩니다.',
      }
    },
    {
      head: '',
      subHead: '전력 소모',
      desc: '',
      dot: {
        a : '<strong> • NFC : </strong>낮은 전력 소모로 알려져 있습니다. 주로 충전이 필요 없는 작은 디바이스에서 사용됩니다.',
        b : '<strong> • 블루투스 : </strong>상대적으로 높은 전력 소모가 있어, 일부 장치에서는 배터리 수명에 영향을 줄 수 있습니다.',
      }
    },
    {
      head: '',
      subHead: '보안',
      desc: '',
      dot: {
        a : '<strong> • NFC : </strong>매우 짧은 거리에서만 통신이 가능하기 때문에, 일반적으로 상대적으로 안전한 환경에서 사용됩니다.',
        b : '<strong> • 블루투스 : </strong>장거리 통신이 가능하므로 보안이 좀 더 중요하며, 보다 강력한 보안 기술이 요구됩니다.',
      }
    },
    {
      head: 'NFC의 미래 전망',
      subHead: '',
      desc: 'NFC 기술은 계속해서 발전하고 있으며, 모바일 결제, 스마트 홈, 의료 분야 등 다양한 분야에서의 응용이 기대되고 있습니다. 더 나은 보안성과 높은 전송 속도를 제공하기 위한 기술적인 혁신이 계속되고 있어, NFC는 더 다양한 산업 분야에서 중요한 역할을 할 것으로 전망됩니다.<br/>이처럼 NFC는 무선 통신 기술 중 하나로 다양한 분야에서 혁신을 이끌고 있는 중요한 기술 중 하나입니다. 그 발전은 미래의 디지털 환경에서 더 많은 혁신을 가져올 것으로 기대됩니다.',
      dot: ''
    },

  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/enterprise/office/asset'
      }
    ]

  }


}