import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/retailExhibition/video/rtlsVideo.mp4"

export default {
    helmet: '| NVR 是什么？',
    desc: 'NVR，或网络视频录像机，是一种接收和存储来自IP摄像机或网络摄像机传输的数字视频流的设备。',
    keywords: 'nvr、ip、ptz、rfid、nfc、uwb、无线通信、位置追踪、物体位置追踪、人物位置追踪、标签、信标、rtls、orbro、Kongtech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'NVR 是什么？ | 专注于位置追踪和数字孪生的平台，Orbro',
    ogUrl: 'https://orbro.io/cn/blog/nvr',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmL4Stf6lklybaH3OesTetv_ifx8JMPoVd04awuiZ4-02dNTkyV_aHj1fMf2KnR4WC3V8BIU0tp4nnqyMrJ1H3wq2E2MR1v4SC2aV7X0uQuYIPhuSRRExRshekWS5QDY7QauCkENm0TmU7Nd5ybqI8YS9VYjU47WZpMCiPjMURQQed_nvkn0AhE2Yun2VlI0rqIh4wdfyfrV8KCYwxBiuTJu0JyormxuEVYxQX_zzHdRwQHQRmGDKitVvEFslYvVFcRU7zmk1H5su0Nbh0OH-2sr7PH92g1JPUqxCgUlBTxhpLdrL_E6UqQka9fnmGxwy-M42lPeCHvlJ3pjxezqOwNVArbkw4Gtzl8eeAr8IbZ4rZ9wTIRi05-bGw29_Cz-zxphiYy_phH52YKkFQP5NEH11pxn9SDr8Xgj6EZqd95NgevWxTMMi4O1A6iOydRW_EOn4lix025cQB5RehRr0gZlPIVwj_YEku0bG-va9JpG6rAPHUbj_AELcIbLToKJnyJ-DYE-gTASAaIamUNLzKNoXmIGa9phep5L_nIkwEHdA-qzT5dyrmhQDpc4d1ruaYFLHWV-bb-ETaORk4Sdr3Y-aqznGhdsafAvy0lGxYU_DMVM_emH2uyyVFq71xraelVNooi5Qoe-PsPuj8BnSOTSZeLGWmuglDlrgmTuEpFNqsVou185a6VQJO6s71py93y5OpnwMtj3FmATd_eeN9ZY43KUQ2iLDXw125g3abOP4HE4YnRCCsMjz3Vray4TP2_m_9QoRm8OuJBGF4A2CbGJ2M8oLmEQ1Mc3VpNZ9WwdYkGXpBY8yCLEuDwxqeKNMUTkJwbr4YNdpja17MLWg9XHFoVNlG64qk-VkBi4IFSFMDx4LaZD9XsGttmU9bBIIT2jJldP5PYCfTjffwwstwZ4VNG-3bxzqfsCZ3CGeihhAYm1knV-ullCOomsYIaweJwLK2MMRFkxlpRPuT0bbJnFWuCdMoydkNYf3JMh0Bi2kok0mrqpGDEqywp9sccVcp8wZkG1XRVZJPJjwSd2kbiJNR2DfwoneK2ivKwpqhT5kRrl9cpuclYrRgIqnlOuce-kuOj0dJMTozXERXeWUzhXWX4EFywOc-1IoTXFtxiPQL76CyCsHtYImgIoegeus-2_6ZQZtpmxvYL33d95jUw3J_yUPkWETDzHro5RGKOD5RDM41CMUEokOzoWbxmbO0MufhfRGY_PnM5W16XGqSZGRTwenay9yrEB3IPluMmrdpervFylEfCPYJyutGFQigW5iY-tvjM8JVOyzk3OqLzqq9LjnJAyhkCJZ2ZGtPKZPvMvOSlovrdc93ztDGD6dorfc1SWfaxDOi4kQduozkGE8GSEkHRte9hFjdoWMGblqD1Lu4ADPGwr2546UPK93UJbiCtj2SUaSVV8E5R1XD3cy4mKcZ27rsDh_s_os0RaZ8lEQfPHZMmphlOnC2S5AnuXnZqqQWPQJD9b2v8kYV9CS3vW8_Gr3zLg1jcDqGfeBbzTg3g3rtTUPi885LTBUpT_NK2a5Ux-HCwPu1da_Cen1_2-oto=w1984-h1208',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'NVR 是什么？',
    date: '2024-01-12',
    tag1: '#nvr',
    tag2: '#摄像机',
    tag3: '#安全摄像机',
    tag4: '#位置追踪',

    text: [
        {
            head: 'NVR 是什么？',
            subHead: '',
            desc: 'NVR，或网络视频录像机，是一种接收和存储来自IP摄像机或网络摄像机传输的数字视频流的设备。通常用于监控和安全系统，与传统的数字视频录像机（DVR）不同，它主要与数字摄像机一起运行。 NVR主要通过网络管理和访问视频，设计用于从各种地点轻松访问。特别是在需要监视大型设施或跨多个区域的情况下，它非常有用，支持更高的分辨率和先进的功能，提供出色的性能。从功能上讲，NVR可以同时接收来自多个摄像机的视频流，并安全地保存它们。保存的视频可以用于稍后检索、播放或分析。此外，许多NVR系统提供高级功能，如电子邮件通知、远程监视或运动检测等，支持有效的监控。这些特性使得NVR在需要高度安全性的环境中广泛使用，并成为现代基于网络的监控系统的核心组件之一。',
            dot: ''
        },
        {
            video: video,
            link: '/cn/enterprise/retail/exhibition',
            head: 'NVR 的功能',
            subHead: '摄像机管理',
            desc: '',
            dot: {
                a: ' • 支持多个数字摄像机，可以有效管理。',
                b: ' • 通过摄像机分组和个别设置，实现灵活配置。',
            }
        },
        {
            head: '',
            subHead: '高分辨率和压缩',
            desc: '',
            dot: {
                a: ' • 支持高分辨率的视频，并使用压缩算法保持存储的效率。',
                b: ' • 支持压缩标准，如H.264、H.265，优化带宽和存储使用。',
            }
        },
        {
            head: '',
            subHead: '网络连接',
            desc: '',
            dot: {
                a: ' • 通过网络可以从各种位置实时或记录的视频。',
                b: ' • 支持远程管理和监控，通过多用户支持实现有效的协作。',
            }
        },
        {
            head: '',
            subHead: '通知和事件管理',
            desc: '',
            dot: {
                a: ' • 可以检测和管理运动检测、视频丢失、摄像机遮盖、暂停等事件。',
                b: ' • 可以设置通知和警告，以快速响应重要事件。',
            }
        },
        {
            head: '',
            subHead: '视频检索和播放',
            desc: '',
            dot: {
                a: ' • 可以根据特定的时间段或事件检索和播放视频。',
                b: ' • 可以进行视频分割、缩放和精密调整。',
            }
        },
        {
            head: '',
            subHead: '存储管理',
            desc: '',
            dot: {
                a: ' • 高效管理存储，并提供各种存储选项。',
                b: ' • 可以自动删除旧视频或将其移动到另一个存储设备。',
            }
        },
        {
            head: '',
            subHead: '各种安全功能',
            desc: '',
            dot: {
                a: ' • 可以通过用户权限管理设置不同级别的访问权限。',
                b: ' • 支持加密技术，以实现安全的远程访问。',
            }
        },

        {
            head: 'NVR 的优势',
            subHead: '高分辨率和画质',
            desc: 'NVR主要与数字摄像机一起使用，支持高分辨率和高质量的视频。这使得细节清晰可见，并且可以实现更精准的监视。',
            dot: ''
        },
        {
            head: '',
            subHead: '基于网络的结构',
            desc: 'NVR通过网络与摄像机通信，因此可以轻松地从远程地区访问视频。这为实时监视和视频检索提供了方便的手段。',
            dot: ''
        },
        {
            head: '',
            subHead: '灵活性和可扩展性',
            desc: 'NVR系统支持多个摄像机，并且可以轻松地扩展系统。添加新摄像机或升级系统都很容易，可以灵活应对安全要求的变化。',
            dot: ''
        },
        {
            head: '',
            subHead: '远程监控和通知功能',
            desc: 'NVR支持从远程地方进行视频监控，通过高级功能，如运动检测或电子邮件通知，可以立即接收事件通知。这有助于快速响应和有效管理。',
            dot: ''
        },
        {
            head: '',
            subHead: '视频检索和分析功能',
            desc: '一些NVR系统提供视频检索和分析功能，可以检测特定事件并快速找到所需的信息。这有助于支持调查和高效利用监控数据。',
            dot: ''
        },
        {
            head: '',
            subHead: '有效的存储和管理',
            desc: 'NVR提供了有效的视频存储和管理功能。它有效利用存储空间，并稳定地保存所需时间段的视频记录。',
            dot: ''
        },


        {
            head: 'NVR 与 DVR 的区别',
            subHead: '摄像机连接和信号处理',
            desc: 'NVR主要与数字摄像机一起使用，支持高分辨率和高质量的视频。这使得细节清晰可见，并且可以实现更精准的监视。',
            dot: {
                a: '<strong> • DVR： </strong>DVR主要与模拟摄像机一起使用，将模拟信号转换为数字信号进行处理。',
                b: '<strong> • NVR： </strong>NVR与数字摄像机或IP摄像机一起使用，处理已经以数字格式传输的数据。',
            }
        },
        {
            head: '',
            subHead: '视频质量和功能',
            desc: '',
            dot: {
                a: '<strong> • DVR： </strong>通常提供较低的视频质量，并且高级功能可能受到限制。',
                b: '<strong> • NVR： </strong>支持高分辨率和高级功能，支持改进的视频质量和多样的功能。',
            }
        },
        {
            head: '',
            subHead: '摄像机支持和分辨率',
            desc: '',
            dot: {
                a: '<strong> • DVR: </strong>主要支持低分辨率的模拟摄像机，并且可能不适合高分辨率的视频。',
                b: '<strong> • NVR: </strong>支持数字摄像机，并且与高分辨率和多兆像素摄像机兼容。',
            }
        },
        {
            head: '',
            subHead: '网络连接',
            desc: '',
            dot: {
                a: '<strong> • DVR: </strong>通常连接到本地网络，远程访问可能更有限。',
                b: '<strong> • NVR: </strong>通过网络发送和接收数据，可以轻松从远程位置访问。',
            }
        },
        {
            head: '',
            subHead: '可扩展性',
            desc: '',
            dot: {
                a: '<strong> • DVR: </strong>通常支持特定数量的摄像机，系统扩展可能更具挑战性。',
                b: '<strong> • NVR: </strong>支持多个摄像机，可以根据需要轻松扩展系统。',
            }
        },
        {
            head: '',
            subHead: '安装和维护',
            desc: '',
            dot: {
                a: '<strong> • DVR: </strong>模拟系统可能具有简单的安装和维护，但整体系统性能可能较低。',
                b: '<strong> • NVR: </strong>数字系统可能较复杂，但提供先进的功能和方便的维护。',
            }
        },
    ],

    link: {
        page: 'camera',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.indoor.peopleTracking.title,
                title: productPurchase.purchase.indoor.peopleTracking.title,
                serial: productPurchase.purchase.indoor.peopleTracking.serial,
                desc: '通过摄像机分析室内人员的移动路径。',
                img: productImg.product.peopleTracking.thumbnail
            },
        ],
        
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/cn/enterprise/medical/patient'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/cn/enterprise/retail/exhibition'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/cn/enterprise/office/asset'
            }
        ]
    }
}
