import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { DefaultLayout } from '@layouts/index';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import {

  Home,
  Os,
  Access,

  Auto,
  Face,
  Accessory,
  Rtls,

  Company,

  RequestPage,

  UwbPage,
  BlePage,
  TDoaPage,
  TWRPage,
  StereoVisionPage,
  MonocularVisionPage,

  BlogPage,
  BlogPageBasic,
  BlogPageBasicEng,
  BlogPageBasicJP,
  BlogRTLSPage,


  Camera,
  SolutionPage,

  SolutionEngPage,
  SolutionJPPage,
  SolutionCNPage,
  BlogPageBasicCN,
  Outdoor,
  Indoor,
  Bell,
  Energy,
  Display,
  Cradle,
  BLE,
  UWB,
  LoRaGateway,
  LoRaSensor,
  CO,
  Air,
  Industry,
  Tag,
  AssetTag,

  Pricing1,
  Pricing2,
  Pricing3,
  Pricing4,
  Pricing5,
  Pricing6,
  CallSchedule,
  IdCard,
  ReleaseNote,
  ReleaseNoteDetailPage,
  ReleaseNoteText,
  Franchise,
  Integration,
  Robot,
  SignUpConditionsPersonal,
  SignUpConditionsUsing,

} from './index';

import '@css/app.styl';

import { useDispatch, useSelector } from 'react-redux';
import { setLayoutType, setGridLayoutType } from '@redux/action-creators/common';
import { SCREEN_BREAKPOINT } from '@components/Grid/Grid.style';
import { Global, ThemeProvider } from '@emotion/react';
import global from '@assets/styled/global';
import Page from '@components/Page/Page';
import { darkTheme, lightTheme } from '@assets/styled/color.style';
import { CommonState } from '@redux/reducers/commonReducer';
import TagManager from 'react-gtm-module';
import DigitalTwin from './DigitalTwin/DigitalTwin';
import ModuleEn from './Enterprise/Technology/Module/global/ModuleEn';
import ModuleJp from './Enterprise/Technology/Module/global/ModuleJp';
import ModuleCn from './Enterprise/Technology/Module/global/ModuleCn';
import ModuleKo from './Enterprise/Technology/Module/global/ModuleKo';
import release_note from '@src/lang/ko/release_note/release_note';

const tagManagerArgs = {
  gtmId: 'GTM-5ZNLD9PB'
}

const GOOGLE_ANALYTICS_ID = 'G-RKWS9073N3';


const App = () => {
  // React
  const location = useLocation();
  const dispatch = useDispatch();

  // Redux
  const darkMode = useSelector((state: CommonState) => state.darkMode);


  const [response, setResponse] = useState({});
  useEffect(() => {
    fetch(`https://notion-api.splitbee.io/v1/page/18c170f3400249e1a51895f6ac79f478`)
      .then(res => res.json())
      .then((resJson) => {
        setResponse(resJson);
      });
  }, [])

  // Hooks
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send('pageview');
    }

    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize(GOOGLE_ANALYTICS_ID, { gtagOptions: { debug_mode: true } });
      TagManager.initialize(tagManagerArgs);
    }

    const handleResize = () => {
      const { innerWidth } = window;

      if (innerWidth >= 1221) {
        dispatch(setLayoutType('desktop'));
      } else if (innerWidth > 721) {
        dispatch(setLayoutType('tablet'));
      } else {
        dispatch(setLayoutType('mobile'));
      }

      if (innerWidth > SCREEN_BREAKPOINT.TABLET) {
        dispatch(setGridLayoutType('desktop'));
      } else if (innerWidth > SCREEN_BREAKPOINT.MOBILE) {
        dispatch(setGridLayoutType('tablet'));
      } else {
        dispatch(setGridLayoutType('mobile'));
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Theme
  const theme = useMemo(() => (darkMode ? darkTheme : lightTheme), [darkMode]);

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <Global styles={global} />
          <Routes>

            <Route path="/" element={<DefaultLayout />}>

              <Route path="/release-note" element={<ReleaseNote />} />
              <Route path="/partnership" element={<Franchise />} />

              {release_note.notion_id.map((menu) => {
                return <Route key={menu.link} path={menu.link} element={<ReleaseNoteDetailPage Page_ID={menu.id} />} />;
              })}

              <Route path="/digital-twin" element={<DigitalTwin />} />
              <Route path="/en/digital-twin" element={<DigitalTwin />} />
              <Route path="/jp/digital-twin" element={<DigitalTwin />} />
              <Route path="/cn/digital-twin" element={<DigitalTwin />} />


              {/* 한국어 페이지 */}
              {/* 한국어 페이지 */}
              {/* 한국어 페이지 */}
              <Route path="/" element={<Page darkMode={false} component={Home} />} />
              <Route path="/pricing/1" element={<Pricing1 />} />
              <Route path="/pricing/2" element={<Pricing2 />} />
              <Route path="/pricing/3" element={<Pricing3 />} />
              <Route path="/pricing/4" element={<Pricing4 />} />
              <Route path="/pricing/5" element={<Pricing5 />} />
              <Route path="/pricing/6" element={<Pricing6 />} />
              <Route path="/schedule" element={<CallSchedule />} />

              <Route path="/os" element={<Os category={'os'} />} />

              <Route path="/access" element={<Page darkMode={false} component={Access} />} />
              <Route path="/access/auto" element={<Auto category={'auto'} />} />
              <Route path="/access/face" element={<Face category={'face'} />} />
              <Route path="/access/accessory" element={<Accessory category={'accessory'} />} />

              <Route path="/rtls" element={<Page darkMode={false} component={Rtls} />} />
              <Route path="/beacon/ble" element={<BLE category={'asset'} />} />
              <Route path="/rtls/uwb" element={<UWB category={'worker'} />} />

              <Route path="/camera" element={<Page darkMode={false} component={Camera} />} />
              <Route path="/camera/outdoor" element={<Outdoor category={'outdoor'} />} />
              <Route path="/camera/indoor" element={<Indoor category={'indoor'} />} />

              <Route path="/iot/bell" element={<Bell category={'monitoring'} />} />
              <Route path="/iot/energy" element={<Energy category={'energy'} />} />
              <Route path="/iot/display" element={<Display category={'display'} />} />
              <Route path="/iot/cradle" element={<Cradle category={'cradle'} />} />
              <Route path="/iot/id" element={<IdCard category={'idCard'} />} />

              <Route path="/sensor/LoRaGateway" element={<LoRaGateway category={'loraGateway'} />} />
              <Route path="/sensor/LoRaSensor" element={<LoRaSensor category={'loraSensor'} />} />
              <Route path="/sensor/air" element={<Air category={'airQuality'} />} />
              <Route path="/sensor/co" element={<CO category={'coMeasure'} />} />

              <Route path="/beacon/industry" element={<Industry category={'beaconIndustry'} />} />
              <Route path="/beacon/tag" element={<Tag category={'beaconTag'} />} />
              <Route path="/beacon/asset-tag" element={<AssetTag category={'assetTag'} />} />

              <Route path="/integration" element={<Page darkMode={false} component={Integration} />} />
              <Route path="/integration/robot" element={<Robot category={'robot'} />} />



              {/* sign-up-conditions */}

              <Route path="/terms/1" element={<SignUpConditionsUsing />} />
              <Route path="/terms/2" element={<SignUpConditionsPersonal />} />

              <Route path="/company" element={<Page darkMode={false} component={Company} />} />
              <Route path="/request" element={<Page darkMode={false} component={RequestPage} />} />





              <Route path="/enterprise/technology/uwb" element={<Page darkMode={false} component={UwbPage} />} />
              <Route path="/enterprise/technology/ble" element={<Page darkMode={false} component={BlePage} />} />
              <Route path="/enterprise/technology/tdoa" element={<Page darkMode={false} component={TDoaPage} />} />
              <Route path="/enterprise/technology/twr" element={<Page darkMode={false} component={TWRPage} />} />
              <Route path="/enterprise/technology/stereo-vision" element={<Page darkMode={false} component={StereoVisionPage} />} />
              <Route path="/enterprise/technology/monocular-vision" element={<Page darkMode={false} component={MonocularVisionPage} />} />
              <Route path="/enterprise/technology/what-is-digital-twin" element={<ModuleKo pageName="whatisdigitaltwin" />} />
              <Route path="/enterprise/technology/what-is-geofence" element={<ModuleKo pageName="whatisgeofence" />} />


              <Route path="/enterprise/construction/safety" element={<SolutionPage pageName="constructionSafety" />} />
              <Route path="/enterprise/construction/asset" element={<SolutionPage pageName="constructionAsset" />} />
              <Route path="/enterprise/construction/equipment" element={<SolutionPage pageName="constructionEquipment" />} />
              <Route path="/enterprise/logistics/asset" element={<SolutionPage pageName="logisticsAsset" />} />
              <Route path="/enterprise/logistics/safety" element={<SolutionPage pageName="logisticsSafety" />} />
              <Route path="/enterprise/office/asset" element={<SolutionPage pageName="officeAsset" />} />
              <Route path="/enterprise/manufacturing/process" element={<SolutionPage pageName="manufacturingProcess" />} />
              <Route path="/enterprise/manufacturing/asset" element={<SolutionPage pageName="manufacturingAsset" />} />
              <Route path="/enterprise/medical/patient" element={<SolutionPage pageName="medicalPatient" />} />
              <Route path="/enterprise/medical/waste" element={<SolutionPage pageName="medicalWaste" />} />
              <Route path="/enterprise/retail/exhibition" element={<SolutionPage pageName="retailExhibition" />} />


              <Route path="/blog" element={<Page darkMode={false} component={BlogPage} />} />

              <Route path="/blog/rtls" element={<Page darkMode={false} component={() => <BlogRTLSPage pageName="RTLS" />} />} />
              <Route path="/blog/digitalTwin" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="DigitalTwin" />} />} />
              <Route path="/blog/uwb" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="Uwb" />} />} />

              <Route path="/blog/geofence" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="Geofence" />} />} />
              <Route path="/blog/medical" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="Medical" />} />} />
              <Route path="/blog/airport" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="Airport" />} />} />

              <Route path="/blog/manufacture" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="Manufacture" />} />} />

              <Route path="/blog/activerfid" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="ActiveRfid" />} />} />

              <Route path="/blog/forklift" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="Forklift" />} />} />
              <Route path="/blog/consider" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="Consider" />} />} />

              <Route path="/blog/office" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="Office" />} />} />
              <Route path="/blog/smartConstruction" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="SmartConstruction" />} />} />
              <Route path="/blog/smartLogistics" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="SmartLogistics" />} />} />
              <Route path="/blog/patientTracking" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="PatientTracking" />} />} />
              <Route path="/blog/visitor" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="Visitor" />} />} />
              <Route path="/blog/hospital" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="Hospital" />} />} />
              <Route path="/blog/medicalWaste" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="MedicalWaste" />} />} />
              <Route path="/blog/uwbGeofence" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="UwbGeofence" />} />} />
              <Route path="/blog/logistics" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="Logistics" />} />} />
              <Route path="/blog/uwbLogistics" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="UwbLogistics" />} />} />
              <Route path="/blog/logisticsWorker" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="LogisticsWorker" />} />} />
              <Route path="/blog/logisticsAsset" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="LogisticsAsset" />} />} />
              <Route path="/blog/logisticsTech" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="LogisticsTech" />} />} />
              <Route path="/blog/constructionSafety" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="ConstructionSafety" />} />} />
              <Route path="/blog/heavyEquipment" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="HeavyEquipment" />} />} />
              <Route path="/blog/smartSafety" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="SmartSafety" />} />} />
              <Route path="/blog/smartFactory" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="SmartFactory" />} />} />
              <Route path="/blog/nextManufacturing" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="NextManufacturing" />} />} />
              <Route path="/blog/digitalTwin2" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="DigitalTwin2" />} />} />
              <Route path="/blog/officeSolution" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="OfficeSolution" />} />} />
              <Route path="/blog/dx" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="DX" />} />} />
              <Route path="/blog/rtlsHealthcare" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="RtlsHealthcare" />} />} />
              <Route path="/blog/uwbRtls" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="UwbRtls" />} />} />
              <Route path="/blog/productionAutomation" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="ProductionAutomation" />} />} />
              <Route path="/blog/beacon" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="Beacon" />} />} />
              <Route path="/blog/RfidNfcUwb" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="RfidNfcUwb" />} />} />
              <Route path="/blog/rtlsCompany" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="RtlsCompany" />} />} />
              <Route path="/blog/nfc-uwb-ble" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="NfcUwbBle" />} />} />
              <Route path="/blog/rfid-rtls" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="RfidRtls" />} />} />
              <Route path="/blog/construction-solution" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="ConstructionSolution" />} />} />
              <Route path="/blog/qa-uwb" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="QaUwb" />} />} />
              <Route path="/blog/digitaltwin-gis" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="DigitalTwinGis" />} />} />
              <Route path="/blog/digitaltwin-weather" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="DigitalTwinWeather" />} />} />
              <Route path="/blog/digitaltwin-buildings" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="DigitalTwinBuildings" />} />} />
              <Route path="/blog/digitaltwin-tracking" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="DigitalTwinTracking" />} />} />
              <Route path="/blog/digitaltwin-traffic" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="DigitalTwinTraffic" />} />} />
              <Route path="/blog/digitaltwin-airplane" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="DigitalTwinAirplane" />} />} />
              <Route path="/blog/digitaltwin-cctv" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="DigitalTwinCCTV" />} />} />
              <Route path="/blog/digitaltwin-digitalcity" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="DigitalTwinDigitalCity" />} />} />
              <Route path="/blog/digital-twin" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="DigitalTwin3" />} />} />
              <Route path="/blog/ble" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="BLE" />} />} />
              <Route path="/blog/what-is-smart-city" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="WhatIsSmartCity" />} />} />
              <Route path="/blog/example-of-smart-city" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="ExampleOfSmartCity" />} />} />
              <Route path="/blog/container-tracking" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="ContainerTracking" />} />} />
              <Route path="/blog/what-is-digital-transformation" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="WhatIsDX" />} />} />
              <Route path="/blog/rfid" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="RFID" />} />} />
              <Route path="/blog/nfc" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="NFC" />} />} />
              <Route path="/blog/nvr" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="NVR" />} />} />
              <Route path="/blog/what-is-a-smart-factory" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="SmartFactory2" />} />} />
              <Route path="/blog/what-is-LoRa" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="LoRa" />} />} />
              <Route path="/blog/스마트병원이란" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="SmartHospital" />} />} />
              <Route path="/blog/smart-airport-solution" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="SmartAirport" />} />} />
              <Route path="/blog/indoor-positioning-system" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="IPS" />} />} />
              <Route path="/blog/smart-parking" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="SmartParking" />} />} />
              <Route path="/blog/smart-port" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="SmartPort" />} />} />
              <Route path="/blog/smart-buildings" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="SmartBuildings" />} />} />
              <Route path="/blog/indoor-gps" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="IndoorGPS" />} />} />
              <Route path="/blog/ai-camera" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="AiCamera" />} />} />
              <Route path="/blog/what-is-AIoT" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="AIoT" />} />} />
              <Route path="/blog/smart-shipyard" element={<Page darkMode={false} component={() => <BlogPageBasic pageName="SmartShipyard" />} />} />





              {/* 영어 페이지 */}
              {/* 영어 페이지 */}
              {/* 영어 페이지 */}

              <Route path="/en/" element={<Page darkMode={false} component={Home} />} />
              <Route path="/en/pricing/1" element={<Pricing1 />} />
              <Route path="/en/pricing/2" element={<Pricing2 />} />
              <Route path="/en/pricing/3" element={<Pricing3 />} />
              <Route path="/en/pricing/4" element={<Pricing4 />} />
              <Route path="/en/pricing/5" element={<Pricing5 />} />
              <Route path="/en/pricing/6" element={<Pricing6 />} />
              <Route path="/en/schedule" element={<CallSchedule />} />

              <Route path="/en/os" element={<Os category={'os'} />} />

              <Route path="/en/access" element={<Page darkMode={false} component={Access} />} />
              <Route path="/en/access/auto" element={<Auto category={'auto'} />} />
              <Route path="/en/access/face" element={<Face category={'face'} />} />
              <Route path="/en/access/accessory" element={<Accessory category={'accessory'} />} />

              <Route path="/en/rtls" element={<Page darkMode={false} component={Rtls} />} />
              <Route path="/en/beacon/ble" element={<BLE category={'asset'} />} />
              <Route path="/en/rtls/uwb" element={<UWB category={'worker'} />} />

              <Route path="/en/camera" element={<Page darkMode={false} component={Camera} />} />
              <Route path="/en/camera/outdoor" element={<Outdoor category={'outdoor'} />} />
              <Route path="/en/camera/indoor" element={<Indoor category={'indoor'} />} />

              <Route path="/en/iot/bell" element={<Bell category={'monitoring'} />} />
              <Route path="/en/iot/energy" element={<Energy category={'energy'} />} />
              <Route path="/en/iot/display" element={<Display category={'display'} />} />
              <Route path="/en/iot/cradle" element={<Cradle category={'cradle'} />} />
              <Route path="/en/iot/id" element={<IdCard category={'idCard'} />} />

              <Route path="/en/sensor/LoRaGateway" element={<LoRaGateway category={'loraGateway'} />} />
              <Route path="/en/sensor/LoRaSensor" element={<LoRaSensor category={'loraSensor'} />} />
              <Route path="/en/sensor/air" element={<Air category={'airQuality'} />} />
              <Route path="/en/sensor/co" element={<CO category={'coMeasure'} />} />

              <Route path="/en/beacon/industry" element={<Industry category={'beaconIndustry'} />} />
              <Route path="/en/beacon/tag" element={<Tag category={'beaconTag'} />} />
              <Route path="/en/beacon/asset-tag" element={<AssetTag category={'assetTag'} />} />


              <Route path="/en/integration" element={<Page darkMode={false} component={Integration} />} />
              <Route path="/en/integration/robot" element={<Robot category={'robot'} />} />

              <Route path="/en/company" element={<Page darkMode={false} component={Company} />} />
              <Route path="/en/request" element={<Page darkMode={false} component={RequestPage} />} />


              <Route path="/en/enterprise/construction/safety" element={<SolutionEngPage pageName="constructionSafety" />} />
              <Route path="/en/enterprise/construction/asset" element={<SolutionEngPage pageName="constructionAsset" />} />
              <Route path="/en/enterprise/construction/equipment" element={<SolutionEngPage pageName="constructionEquipment" />} />
              <Route path="/en/enterprise/logistics/asset" element={<SolutionEngPage pageName="logisticsAsset" />} />
              <Route path="/en/enterprise/logistics/safety" element={<SolutionEngPage pageName="logisticsSafety" />} />
              <Route path="/en/enterprise/office/asset" element={<SolutionEngPage pageName="officeAsset" />} />
              <Route path="/en/enterprise/manufacturing/process" element={<SolutionEngPage pageName="manufacturingProcess" />} />
              <Route path="/en/enterprise/manufacturing/asset" element={<SolutionEngPage pageName="manufacturingAsset" />} />
              <Route path="/en/enterprise/medical/patient" element={<SolutionEngPage pageName="medicalPatient" />} />
              <Route path="/en/enterprise/medical/waste" element={<SolutionEngPage pageName="medicalWaste" />} />
              <Route path="/en/enterprise/retail/exhibition" element={<SolutionEngPage pageName="retailExhibition" />} />


              <Route path="/en/enterprise/technology/uwb" element={<Page darkMode={false} component={UwbPage} />} />
              <Route path="/en/enterprise/technology/ble" element={<Page darkMode={false} component={BlePage} />} />
              <Route path="/en/enterprise/technology/tdoa" element={<Page darkMode={false} component={TDoaPage} />} />
              <Route path="/en/enterprise/technology/twr" element={<Page darkMode={false} component={TWRPage} />} />
              <Route path="/en/enterprise/technology/stereo-vision" element={<Page darkMode={false} component={StereoVisionPage} />} />
              <Route path="/en/enterprise/technology/monocular-vision" element={<Page darkMode={false} component={MonocularVisionPage} />} />
              <Route path="/en/enterprise/technology/what-is-digital-twin" element={<ModuleEn pageName="whatisdigitaltwin" />} />
              <Route path="/en/enterprise/technology/what-is-geofence" element={<ModuleEn pageName="whatisgeofence" />} />


              <Route path="/en/blog" element={<Page darkMode={false} component={BlogPage} />} />

              <Route path="/en/blog/rtls" element={<Page darkMode={false} component={() => <BlogRTLSPage pageName="RTLS" />} />} />
              <Route path="/en/blog/digitalTwin" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="DigitalTwin" />} />} />
              <Route path="/en/blog/uwb" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="Uwb" />} />} />

              <Route path="/en/blog/geofence" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="Geofence" />} />} />
              <Route path="/en/blog/medical" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="Medical" />} />} />
              <Route path="/en/blog/airport" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="Airport" />} />} />

              <Route path="/en/blog/manufacture" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="Manufacture" />} />} />

              <Route path="/en/blog/activerfid" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="ActiveRfid" />} />} />

              <Route path="/en/blog/forklift" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="Forklift" />} />} />
              <Route path="/en/blog/consider" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="Consider" />} />} />


              <Route path="/en/blog/office" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="Office" />} />} />
              <Route path="/en/blog/smartConstruction" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="SmartConstruction" />} />} />
              <Route path="/en/blog/smartLogistics" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="SmartLogistics" />} />} />
              <Route path="/en/blog/patientTracking" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="PatientTracking" />} />} />
              <Route path="/en/blog/visitor" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="Visitor" />} />} />
              <Route path="/en/blog/hospital" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="Hospital" />} />} />
              <Route path="/en/blog/medicalWaste" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="MedicalWaste" />} />} />
              <Route path="/en/blog/uwbGeofence" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="UwbGeofence" />} />} />
              <Route path="/en/blog/logistics" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="Logistics" />} />} />
              <Route path="/en/blog/uwbLogistics" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="UwbLogistics" />} />} />
              <Route path="/en/blog/logisticsWorker" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="LogisticsWorker" />} />} />
              <Route path="/en/blog/logisticsAsset" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="LogisticsAsset" />} />} />
              <Route path="/en/blog/logisticsTech" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="LogisticsTech" />} />} />
              <Route path="/en/blog/constructionSafety" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="ConstructionSafety" />} />} />
              <Route path="/en/blog/heavyEquipment" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="HeavyEquipment" />} />} />
              <Route path="/en/blog/smartSafety" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="SmartSafety" />} />} />
              <Route path="/en/blog/smartFactory" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="SmartFactory" />} />} />
              <Route path="/en/blog/nextManufacturing" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="NextManufacturing" />} />} />
              <Route path="/en/blog/digitalTwin2" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="DigitalTwin2" />} />} />
              <Route path="/en/blog/officeSolution" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="OfficeSolution" />} />} />
              <Route path="/en/blog/dx" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="DX" />} />} />
              <Route path="/en/blog/rtlsHealthcare" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="RtlsHealthcare" />} />} />
              <Route path="/en/blog/uwbRtls" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="UwbRtls" />} />} />
              <Route path="/en/blog/productionAutomation" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="ProductionAutomation" />} />} />
              <Route path="/en/blog/beacon" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="Beacon" />} />} />
              <Route path="/en/blog/RfidNfcUwb" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="RfidNfcUwb" />} />} />
              <Route path="/en/blog/rtlsCompany" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="RtlsCompany" />} />} />
              <Route path="/en/blog/nfc-uwb-ble" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="NfcUwbBle" />} />} />
              <Route path="/en/blog/rfid-rtls" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="RfidRtls" />} />} />
              <Route path="/en/blog/construction-solution" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="ConstructionSolution" />} />} />
              <Route path="/en/blog/qa-uwb" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="QaUwb" />} />} />
              <Route path="/en/blog/digitaltwin-gis" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="DigitalTwinGis" />} />} />
              <Route path="/en/blog/digitaltwin-weather" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="DigitalTwinWeather" />} />} />
              <Route path="/en/blog/digitaltwin-buildings" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="DigitalTwinBuildings" />} />} />
              <Route path="/en/blog/digitaltwin-tracking" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="DigitalTwinTracking" />} />} />
              <Route path="/en/blog/digitaltwin-traffic" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="DigitalTwinTraffic" />} />} />
              <Route path="/en/blog/digitaltwin-airplane" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="DigitalTwinAirplane" />} />} />
              <Route path="/en/blog/digitaltwin-cctv" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="DigitalTwinCCTV" />} />} />
              <Route path="/en/blog/digitaltwin-digitalcity" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="DigitalTwinDigitalCity" />} />} />
              <Route path="/en/blog/digital-twin" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="DigitalTwin3" />} />} />
              <Route path="/en/blog/ble" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="BLE" />} />} />
              <Route path="/en/blog/what-is-smart-city" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="WhatIsSmartCity" />} />} />
              <Route path="/en/blog/example-of-smart-city" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="ExampleOfSmartCity" />} />} />
              <Route path="/en/blog/container-tracking" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="ContainerTracking" />} />} />
              <Route path="/en/blog/what-is-digital-transformation" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="WhatIsDX" />} />} />
              <Route path="/en/blog/rfid" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="RFID" />} />} />
              <Route path="/en/blog/nfc" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="NFC" />} />} />
              <Route path="/en/blog/nvr" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="NVR" />} />} />
              <Route path="/en/blog/what-is-a-smart-factory" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="SmartFactory2" />} />} />
              <Route path="/en/blog/what-is-LoRa" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="LoRa" />} />} />
              <Route path="/en/blog/스마트병원이란" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="SmartHospital" />} />} />
              <Route path="/en/blog/smart-airport-solution" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="SmartAirport" />} />} />
              <Route path="/en/blog/indoor-positioning-system" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="IPS" />} />} />
              <Route path="/en/blog/smart-parking" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="SmartParking" />} />} />
              <Route path="/en/blog/smart-port" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="SmartPort" />} />} />
              <Route path="/en/blog/smart-buildings" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="SmartBuildings" />} />} />
              <Route path="/en/blog/indoor-gps" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="IndoorGPS" />} />} />
              <Route path="/en/blog/ai-camera" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="AiCamera" />} />} />
              <Route path="/en/blog/what-is-AIoT" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="AIoT" />} />} />
              <Route path="/en/blog/smart-shipyard" element={<Page darkMode={false} component={() => <BlogPageBasicEng pageName="SmartShipyard" />} />} />






              {/* 일어 페이지 */}
              {/* 일어 페이지 */}
              {/* 일어 페이지 */}

              <Route path="/jp/" element={<Page darkMode={false} component={Home} />} />
              <Route path="/jp/pricing/1" element={<Pricing1 />} />
              <Route path="/jp/pricing/2" element={<Pricing2 />} />
              <Route path="/jp/pricing/3" element={<Pricing3 />} />
              <Route path="/jp/pricing/4" element={<Pricing4 />} />
              <Route path="/jp/pricing/5" element={<Pricing5 />} />
              <Route path="/jp/pricing/6" element={<Pricing6 />} />
              <Route path="/jp/schedule" element={<CallSchedule />} />
              <Route path="/jp/os" element={<Os category={'os'} />} />

              <Route path="/jp/access" element={<Page darkMode={false} component={Access} />} />
              <Route path="/jp/access/auto" element={<Auto category={'auto'} />} />
              <Route path="/jp/access/face" element={<Face category={'face'} />} />
              <Route path="/jp/access/accessory" element={<Accessory category={'accessory'} />} />

              <Route path="/jp/rtls" element={<Page darkMode={false} component={Rtls} />} />
              <Route path="/jp/beacon/ble" element={<BLE category={'asset'} />} />
              <Route path="/jp/rtls/uwb" element={<UWB category={'worker'} />} />

              <Route path="/jp/camera" element={<Page darkMode={false} component={Camera} />} />
              <Route path="/jp/camera/outdoor" element={<Outdoor category={'outdoor'} />} />
              <Route path="/jp/camera/indoor" element={<Indoor category={'indoor'} />} />

              <Route path="/jp/iot/bell" element={<Bell category={'monitoring'} />} />
              <Route path="/jp/iot/energy" element={<Energy category={'energy'} />} />
              <Route path="/jp/iot/display" element={<Display category={'display'} />} />
              <Route path="/jp/iot/cradle" element={<Cradle category={'cradle'} />} />
              <Route path="/jp/iot/id" element={<IdCard category={'idCard'} />} />

              <Route path="/jp/sensor/LoRaGateway" element={<LoRaGateway category={'loraGateway'} />} />
              <Route path="/jp/sensor/LoRaSensor" element={<LoRaSensor category={'loraSensor'} />} />
              <Route path="/jp/sensor/air" element={<Air category={'airQuality'} />} />
              <Route path="/jp/sensor/co" element={<CO category={'coMeasure'} />} />

              <Route path="/jp/beacon/industry" element={<Industry category={'beaconIndustry'} />} />
              <Route path="/jp/beacon/tag" element={<Tag category={'beaconTag'} />} />
              <Route path="/jp/beacon/asset-tag" element={<AssetTag category={'assetTag'} />} />


              <Route path="/jp/integration" element={<Page darkMode={false} component={Integration} />} />
              <Route path="/jp/integration/robot" element={<Robot category={'robot'} />} />

              <Route path="/jp/company" element={<Page darkMode={false} component={Company} />} />
              <Route path="/jp/request" element={<Page darkMode={false} component={RequestPage} />} />


              <Route path="/jp/enterprise/construction/safety" element={<SolutionJPPage pageName="constructionSafety" />} />
              <Route path="/jp/enterprise/construction/asset" element={<SolutionJPPage pageName="constructionAsset" />} />
              <Route path="/jp/enterprise/construction/equipment" element={<SolutionJPPage pageName="constructionEquipment" />} />
              <Route path="/jp/enterprise/logistics/asset" element={<SolutionJPPage pageName="logisticsAsset" />} />
              <Route path="/jp/enterprise/logistics/safety" element={<SolutionJPPage pageName="logisticsSafety" />} />
              <Route path="/jp/enterprise/office/asset" element={<SolutionJPPage pageName="officeAsset" />} />
              <Route path="/jp/enterprise/manufacturing/process" element={<SolutionJPPage pageName="manufacturingProcess" />} />
              <Route path="/jp/enterprise/manufacturing/asset" element={<SolutionJPPage pageName="manufacturingAsset" />} />
              <Route path="/jp/enterprise/medical/patient" element={<SolutionJPPage pageName="medicalPatient" />} />
              <Route path="/jp/enterprise/medical/waste" element={<SolutionJPPage pageName="medicalWaste" />} />
              <Route path="/jp/enterprise/retail/exhibition" element={<SolutionJPPage pageName="retailExhibition" />} />


              <Route path="/jp/enterprise/technology/uwb" element={<Page darkMode={false} component={UwbPage} />} />
              <Route path="/jp/enterprise/technology/ble" element={<Page darkMode={false} component={BlePage} />} />
              <Route path="/jp/enterprise/technology/tdoa" element={<Page darkMode={false} component={TDoaPage} />} />
              <Route path="/jp/enterprise/technology/twr" element={<Page darkMode={false} component={TWRPage} />} />
              <Route path="/jp/enterprise/technology/stereo-vision" element={<Page darkMode={false} component={StereoVisionPage} />} />
              <Route path="/jp/enterprise/technology/monocular-vision" element={<Page darkMode={false} component={MonocularVisionPage} />} />
              <Route path="/jp/enterprise/technology/what-is-digital-twin" element={<ModuleJp pageName="whatisdigitaltwin" />} />
              <Route path="/jp/enterprise/technology/what-is-geofence" element={<ModuleJp pageName="whatisgeofence" />} />


              <Route path="/jp/blog" element={<Page darkMode={false} component={BlogPage} />} />

              <Route path="/jp/blog/rtls" element={<Page darkMode={false} component={() => <BlogRTLSPage pageName="RTLS" />} />} />
              <Route path="/jp/blog/digitalTwin" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="DigitalTwin" />} />} />
              <Route path="/jp/blog/uwb" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="Uwb" />} />} />

              <Route path="/jp/blog/geofence" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="Geofence" />} />} />
              <Route path="/jp/blog/medical" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="Medical" />} />} />
              <Route path="/jp/blog/airport" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="Airport" />} />} />

              <Route path="/jp/blog/manufacture" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="Manufacture" />} />} />

              <Route path="/jp/blog/activerfid" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="ActiveRfid" />} />} />
              <Route path="/jp/blog/forklift" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="Forklift" />} />} />
              <Route path="/jp/blog/consider" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="Consider" />} />} />


              <Route path="/jp/blog/office" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="Office" />} />} />
              <Route path="/jp/blog/smartConstruction" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="SmartConstruction" />} />} />
              <Route path="/jp/blog/smartLogistics" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="SmartLogistics" />} />} />
              <Route path="/jp/blog/patientTracking" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="PatientTracking" />} />} />
              <Route path="/jp/blog/visitor" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="Visitor" />} />} />
              <Route path="/jp/blog/hospital" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="Hospital" />} />} />
              <Route path="/jp/blog/medicalWaste" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="MedicalWaste" />} />} />
              <Route path="/jp/blog/uwbGeofence" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="UwbGeofence" />} />} />
              <Route path="/jp/blog/logistics" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="Logistics" />} />} />
              <Route path="/jp/blog/uwbLogistics" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="UwbLogistics" />} />} />
              <Route path="/jp/blog/logisticsWorker" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="LogisticsWorker" />} />} />
              <Route path="/jp/blog/logisticsAsset" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="LogisticsAsset" />} />} />
              <Route path="/jp/blog/logisticsTech" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="LogisticsTech" />} />} />
              <Route path="/jp/blog/constructionSafety" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="ConstructionSafety" />} />} />
              <Route path="/jp/blog/heavyEquipment" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="HeavyEquipment" />} />} />
              <Route path="/jp/blog/smartSafety" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="SmartSafety" />} />} />
              <Route path="/jp/blog/smartFactory" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="SmartFactory" />} />} />
              <Route path="/jp/blog/nextManufacturing" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="NextManufacturing" />} />} />
              <Route path="/jp/blog/digitalTwin2" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="DigitalTwin2" />} />} />
              <Route path="/jp/blog/officeSolution" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="OfficeSolution" />} />} />
              <Route path="/jp/blog/dx" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="DX" />} />} />
              <Route path="/jp/blog/rtlsHealthcare" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="RtlsHealthcare" />} />} />
              <Route path="/jp/blog/uwbRtls" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="UwbRtls" />} />} />
              <Route path="/jp/blog/productionAutomation" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="ProductionAutomation" />} />} />
              <Route path="/jp/blog/beacon" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="Beacon" />} />} />
              <Route path="/jp/blog/RfidNfcUwb" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="RfidNfcUwb" />} />} />
              <Route path="/jp/blog/rtlsCompany" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="RtlsCompany" />} />} />
              <Route path="/jp/blog/nfc-uwb-ble" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="NfcUwbBle" />} />} />
              <Route path="/jp/blog/rfid-rtls" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="RfidRtls" />} />} />
              <Route path="/jp/blog/construction-solution" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="ConstructionSolution" />} />} />
              <Route path="/jp/blog/qa-uwb" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="QaUwb" />} />} />
              <Route path="/jp/blog/digitaltwin-gis" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="DigitalTwinGis" />} />} />
              <Route path="/jp/blog/digitaltwin-weather" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="DigitalTwinWeather" />} />} />
              <Route path="/jp/blog/digitaltwin-buildings" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="DigitalTwinBuildings" />} />} />
              <Route path="/jp/blog/digitaltwin-tracking" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="DigitalTwinTracking" />} />} />
              <Route path="/jp/blog/digitaltwin-traffic" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="DigitalTwinTraffic" />} />} />
              <Route path="/jp/blog/digitaltwin-airplane" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="DigitalTwinAirplane" />} />} />
              <Route path="/jp/blog/digitaltwin-cctv" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="DigitalTwinCCTV" />} />} />
              <Route path="/jp/blog/digitaltwin-digitalcity" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="DigitalTwinDigitalCity" />} />} />
              <Route path="/jp/blog/digital-twin" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="DigitalTwin3" />} />} />
              <Route path="/jp/blog/ble" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="BLE" />} />} />
              <Route path="/jp/blog/what-is-smart-city" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="WhatIsSmartCity" />} />} />
              <Route path="/jp/blog/example-of-smart-city" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="ExampleOfSmartCity" />} />} />
              <Route path="/jp/blog/container-tracking" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="ContainerTracking" />} />} />
              <Route path="/jp/blog/what-is-digital-transformation" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="WhatIsDX" />} />} />
              <Route path="/jp/blog/rfid" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="RFID" />} />} />
              <Route path="/jp/blog/nfc" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="NFC" />} />} />
              <Route path="/jp/blog/nvr" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="NVR" />} />} />
              <Route path="/jp/blog/what-is-a-smart-factory" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="SmartFactory2" />} />} />
              <Route path="/jp/blog/what-is-LoRa" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="LoRa" />} />} />
              <Route path="/jp/blog/스마트병원이란" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="SmartHospital" />} />} />
              <Route path="/jp/blog/smart-airport-solution" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="SmartAirport" />} />} />
              <Route path="/jp/blog/indoor-positioning-system" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="IPS" />} />} />
              <Route path="/jp/blog/smart-parking" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="SmartParking" />} />} />
              <Route path="/jp/blog/smart-port" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="SmartPort" />} />} />
              <Route path="/jp/blog/smart-buildings" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="SmartBuildings" />} />} />
              <Route path="/jp/blog/indoor-gps" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="IndoorGPS" />} />} />
              <Route path="/jp/blog/ai-camera" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="AiCamera" />} />} />
              <Route path="/jp/blog/what-is-AIoT" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="AIoT" />} />} />
              <Route path="/jp/blog/smart-shipyard" element={<Page darkMode={false} component={() => <BlogPageBasicJP pageName="SmartShipyard" />} />} />





              {/* 중국어 페이지 */}
              {/* 중국어 페이지 */}
              {/* 중국어 페이지 */}

              <Route path="/cn/" element={<Page darkMode={false} component={Home} />} />
              <Route path="/cn/pricing/1" element={<Pricing1 />} />
              <Route path="/cn/pricing/2" element={<Pricing2 />} />
              <Route path="/cn/pricing/3" element={<Pricing3 />} />
              <Route path="/cn/pricing/4" element={<Pricing4 />} />
              <Route path="/cn/pricing/5" element={<Pricing5 />} />
              <Route path="/cn/pricing/6" element={<Pricing6 />} />
              <Route path="/cn/schedule" element={<CallSchedule />} />
              <Route path="/cn/os" element={<Os category={'os'} />} />

              <Route path="/cn/access" element={<Page darkMode={false} component={Access} />} />
              <Route path="/cn/access/auto" element={<Auto category={'auto'} />} />
              <Route path="/cn/access/face" element={<Face category={'face'} />} />
              <Route path="/cn/access/accessory" element={<Accessory category={'accessory'} />} />

              <Route path="/cn/rtls" element={<Page darkMode={false} component={Rtls} />} />
              <Route path="/cn/beacon/ble" element={<BLE category={'asset'} />} />
              <Route path="/cn/rtls/uwb" element={<UWB category={'worker'} />} />

              <Route path="/cn/camera" element={<Page darkMode={false} component={Camera} />} />
              <Route path="/cn/camera/outdoor" element={<Outdoor category={'outdoor'} />} />
              <Route path="/cn/camera/indoor" element={<Indoor category={'indoor'} />} />

              <Route path="/cn/iot/bell" element={<Bell category={'monitoring'} />} />
              <Route path="/cn/iot/energy" element={<Energy category={'energy'} />} />
              <Route path="/cn/iot/display" element={<Display category={'display'} />} />
              <Route path="/cn/iot/cradle" element={<Cradle category={'cradle'} />} />
              <Route path="/cn/iot/id" element={<IdCard category={'idCard'} />} />

              <Route path="/cn/sensor/LoRaGateway" element={<LoRaGateway category={'loraGateway'} />} />
              <Route path="/cn/sensor/LoRaSensor" element={<LoRaSensor category={'loraSensor'} />} />
              <Route path="/cn/sensor/air" element={<Air category={'airQuality'} />} />
              <Route path="/cn/sensor/co" element={<CO category={'coMeasure'} />} />

              <Route path="/cn/beacon/industry" element={<Industry category={'beaconIndustry'} />} />
              <Route path="/cn/beacon/tag" element={<Tag category={'beaconTag'} />} />
              <Route path="/cn/beacon/asset-tag" element={<AssetTag category={'assetTag'} />} />


              <Route path="/cn/integration" element={<Page darkMode={false} component={Integration} />} />
              <Route path="/cn/integration/robot" element={<Robot category={'robot'} />} />

              <Route path="/cn/company" element={<Page darkMode={false} component={Company} />} />
              <Route path="/cn/request" element={<Page darkMode={false} component={RequestPage} />} />

              <Route path="/cn/enterprise/construction/safety" element={<SolutionCNPage pageName="constructionSafety" />} />
              <Route path="/cn/enterprise/construction/asset" element={<SolutionCNPage pageName="constructionAsset" />} />
              <Route path="/cn/enterprise/construction/equipment" element={<SolutionCNPage pageName="constructionEquipment" />} />
              <Route path="/cn/enterprise/logistics/asset" element={<SolutionCNPage pageName="logisticsAsset" />} />
              <Route path="/cn/enterprise/logistics/safety" element={<SolutionCNPage pageName="logisticsSafety" />} />
              <Route path="/cn/enterprise/office/asset" element={<SolutionCNPage pageName="officeAsset" />} />
              <Route path="/cn/enterprise/manufacturing/process" element={<SolutionCNPage pageName="manufacturingProcess" />} />
              <Route path="/cn/enterprise/manufacturing/asset" element={<SolutionCNPage pageName="manufacturingAsset" />} />
              <Route path="/cn/enterprise/medical/patient" element={<SolutionCNPage pageName="medicalPatient" />} />
              <Route path="/cn/enterprise/medical/waste" element={<SolutionCNPage pageName="medicalWaste" />} />
              <Route path="/cn/enterprise/retail/exhibition" element={<SolutionCNPage pageName="retailExhibition" />} />


              <Route path="/cn/enterprise/technology/uwb" element={<Page darkMode={false} component={UwbPage} />} />
              <Route path="/cn/enterprise/technology/ble" element={<Page darkMode={false} component={BlePage} />} />
              <Route path="/cn/enterprise/technology/tdoa" element={<Page darkMode={false} component={TDoaPage} />} />
              <Route path="/cn/enterprise/technology/twr" element={<Page darkMode={false} component={TWRPage} />} />
              <Route path="/cn/enterprise/technology/stereo-vision" element={<Page darkMode={false} component={StereoVisionPage} />} />
              <Route path="/cn/enterprise/technology/monocular-vision" element={<Page darkMode={false} component={MonocularVisionPage} />} />
              <Route path="/cn/enterprise/technology/what-is-digital-twin" element={<ModuleCn pageName="whatisdigitaltwin" />} />
              <Route path="/cn/enterprise/technology/what-is-geofence" element={<ModuleCn pageName="whatisgeofence" />} />


              <Route path="/cn/blog" element={<Page darkMode={false} component={BlogPage} />} />

              <Route path="/cn/blog/rtls" element={<Page darkMode={false} component={() => <BlogRTLSPage pageName="RTLS" />} />} />
              <Route path="/cn/blog/digitalTwin" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="DigitalTwin" />} />} />
              <Route path="/cn/blog/uwb" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="Uwb" />} />} />

              <Route path="/cn/blog/geofence" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="Geofence" />} />} />
              <Route path="/cn/blog/medical" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="Medical" />} />} />
              <Route path="/cn/blog/airport" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="Airport" />} />} />

              <Route path="/cn/blog/manufacture" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="Manufacture" />} />} />
              <Route path="/cn/blog/activerfid" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="ActiveRfid" />} />} />

              <Route path="/cn/blog/forklift" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="Forklift" />} />} />
              <Route path="/cn/blog/consider" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="Consider" />} />} />


              <Route path="/cn/blog/office" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="Office" />} />} />
              <Route path="/cn/blog/smartConstruction" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="SmartConstruction" />} />} />
              <Route path="/cn/blog/smartLogistics" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="SmartLogistics" />} />} />
              <Route path="/cn/blog/patientTracking" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="PatientTracking" />} />} />
              <Route path="/cn/blog/visitor" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="Visitor" />} />} />
              <Route path="/cn/blog/hospital" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="Hospital" />} />} />
              <Route path="/cn/blog/medicalWaste" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="MedicalWaste" />} />} />
              <Route path="/cn/blog/uwbGeofence" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="UwbGeofence" />} />} />
              <Route path="/cn/blog/logistics" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="Logistics" />} />} />
              <Route path="/cn/blog/uwbLogistics" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="UwbLogistics" />} />} />
              <Route path="/cn/blog/logisticsWorker" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="LogisticsWorker" />} />} />
              <Route path="/cn/blog/logisticsAsset" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="LogisticsAsset" />} />} />
              <Route path="/cn/blog/logisticsTech" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="LogisticsTech" />} />} />
              <Route path="/cn/blog/constructionSafety" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="ConstructionSafety" />} />} />
              <Route path="/cn/blog/heavyEquipment" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="HeavyEquipment" />} />} />
              <Route path="/cn/blog/smartSafety" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="SmartSafety" />} />} />
              <Route path="/cn/blog/smartFactory" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="SmartFactory" />} />} />
              <Route path="/cn/blog/nextManufacturing" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="NextManufacturing" />} />} />
              <Route path="/cn/blog/digitalTwin2" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="DigitalTwin2" />} />} />
              <Route path="/cn/blog/officeSolution" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="OfficeSolution" />} />} />
              <Route path="/cn/blog/dx" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="DX" />} />} />
              <Route path="/cn/blog/rtlsHealthcare" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="RtlsHealthcare" />} />} />
              <Route path="/cn/blog/uwbRtls" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="UwbRtls" />} />} />
              <Route path="/cn/blog/productionAutomation" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="ProductionAutomation" />} />} />
              <Route path="/cn/blog/beacon" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="Beacon" />} />} />
              <Route path="/cn/blog/RfidNfcUwb" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="RfidNfcUwb" />} />} />
              <Route path="/cn/blog/rtlsCompany" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="RtlsCompany" />} />} />
              <Route path="/cn/blog/nfc-uwb-ble" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="NfcUwbBle" />} />} />
              <Route path="/cn/blog/rfid-rtls" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="RfidRtls" />} />} />
              <Route path="/cn/blog/construction-solution" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="ConstructionSolution" />} />} />
              <Route path="/cn/blog/qa-uwb" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="QaUwb" />} />} />
              <Route path="/cn/blog/digitaltwin-gis" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="DigitalTwinGis" />} />} />
              <Route path="/cn/blog/digitaltwin-weather" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="DigitalTwinWeather" />} />} />
              <Route path="/cn/blog/digitaltwin-buildings" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="DigitalTwinBuildings" />} />} />
              <Route path="/cn/blog/digitaltwin-tracking" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="DigitalTwinTracking" />} />} />
              <Route path="/cn/blog/digitaltwin-traffic" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="DigitalTwinTraffic" />} />} />
              <Route path="/cn/blog/digitaltwin-airplane" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="DigitalTwinAirplane" />} />} />
              <Route path="/cn/blog/digitaltwin-cctv" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="DigitalTwinCCTV" />} />} />
              <Route path="/cn/blog/digitaltwin-digitalcity" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="DigitalTwinDigitalCity" />} />} />
              <Route path="/cn/blog/digital-twin" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="DigitalTwin3" />} />} />
              <Route path="/cn/blog/ble" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="BLE" />} />} />
              <Route path="/cn/blog/what-is-smart-city" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="WhatIsSmartCity" />} />} />
              <Route path="/cn/blog/example-of-smart-city" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="ExampleOfSmartCity" />} />} />
              <Route path="/cn/blog/container-tracking" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="ContainerTracking" />} />} />
              <Route path="/cn/blog/what-is-digital-transformation" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="WhatIsDX" />} />} />
              <Route path="/cn/blog/rfid" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="RFID" />} />} />
              <Route path="/cn/blog/nfc" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="NFC" />} />} />
              <Route path="/cn/blog/nvr" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="NVR" />} />} />
              <Route path="/cn/blog/what-is-a-smart-factory" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="SmartFactory2" />} />} />
              <Route path="/cn/blog/what-is-LoRa" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="LoRa" />} />} />
              <Route path="/cn/blog/스마트병원이란" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="SmartHospital" />} />} />
              <Route path="/cn/blog/smart-airport-solution" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="SmartAirport" />} />} />
              <Route path="/cn/blog/indoor-positioning-system" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="IPS" />} />} />
              <Route path="/cn/blog/smart-parking" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="SmartParking" />} />} />
              <Route path="/cn/blog/smart-port" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="SmartPort" />} />} />
              <Route path="/cn/blog/smart-buildings" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="SmartBuildings" />} />} />
              <Route path="/cn/blog/indoor-gps" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="IndoorGPS" />} />} />
              <Route path="/cn/blog/ai-camera" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="AiCamera" />} />} />
              <Route path="/cn/blog/what-is-AIoT" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="AIoT" />} />} />
              <Route path="/cn/blog/smart-shipyard" element={<Page darkMode={false} component={() => <BlogPageBasicCN pageName="SmartShipyard" />} />} />


            </Route>

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </ThemeProvider>
      </I18nextProvider>
    </>
  );
};

export default App;


/*
<Route path="/enterprise/apps" element={<Page darkMode={true} component={Apps} />} />
<Route path="/enterprise/apps/:id" element={<Page darkMode={true} component={AppDetail} />} />
<Route path="/enterprise/support" element={<Page darkMode={true} component={Support} />} />
*/