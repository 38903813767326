import { accessAuto1, accessAutoMain, accessPro1, accessProMain, accesscontroller1, accesscontrollerMain } from "@assets/images/main/access/product";
import { indoor280, outdoor280 } from "@assets/images/main/camera";
import { assetTracking1, workerTracking1 } from "@assets/images/main/rtls/product";

export default {



  a: [
    { text: "Product Name" },
    { text: "Description" },
    { text: "Communication Method" },
    { text: "Resolution" },
    { text: "Aperture" },
    { text: "Waterproof Rating" },
    { text: "Operating Conditions" },
    { text: "Power" },
    { text: "Installation Support" },
    { text: "Video Storage" },
    { text: "Person Tracking" },
    { text: "Vehicle Tracking" },
    { text: "Heatmap" },
    { text: "Abnormal Behavior Detection" }

  ],

  b: [
    { text: "Outdoor Camera" },
    { text: "Detect and manage the location and abnormal behavior of various objects such as people, vehicles, and more using cameras." },
    { text: "Ethernet" },
    { text: "2560 x 1440" },
    { text: "F 1.2" },
    { text: "IP67" },
    { text: "-40°C ~ +70°C, 0% ~ 95%" },
    { text: "12VDV" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },

  ],

  c: [
    { text: "Indoor Camera" },
    { text: "Analyze the movement paths of indoor individuals using cameras." },
    { text: "Ethernet" },
    { text: "1920 x 1080" },
    { text: "F 1.4" },
    { text: "IP67" },
    { text: "-30°C ~ +60°C, 0% ~ 95%" },
    { text: "12VDC" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },

  ],


  spec: [

    {

    },

    {
      image: outdoor280,

    },


    {
      image: indoor280,

    },


  ],


}

