import {
  HomeAccess1D, HomeAccess1M, HomeAccess1T,
  HomeAccess2D, HomeAccess2M, HomeAccess2T,
  HomeAccess3D, HomeAccess3M, HomeAccess3T,
  HomeAccess4D, HomeAccess4M, HomeAccess4T,
  HomeAccess5D, HomeAccess5M, HomeAccess5T,
  HomeBeacon1D, HomeBeacon1M, HomeBeacon1T,
  HomeBeacon2D, HomeBeacon2M, HomeBeacon2T,
  HomeBeacon3D, HomeBeacon3M, HomeBeacon3T,
  HomeBeacon4D, HomeBeacon4M, HomeBeacon4T,
  HomeBeacon5D, HomeBeacon5M, HomeBeacon5T,
  HomeBeacon6D, HomeBeacon6M, HomeBeacon6T,
  HomeRTLS1D, HomeRTLS1M, HomeRTLS1T,
  HomeRTLS2D, HomeRTLS2M, HomeRTLS2T,
  HomeRTLS3D, HomeRTLS3M, HomeRTLS3T,
  HomeSolution1D, HomeSolution1M, HomeSolution1T,
  HomeSolution2D, HomeSolution2M, HomeSolution2T,
  HomeSolution3D, HomeSolution3M, HomeSolution3T,
  HomeSolution4D, HomeSolution4M, HomeSolution4T
} from "@assets/images/main/home";
import { HomeDTBanner } from "@assets/images/main/home/dt";

export default {
  ttitleBanner: {
    title: `位置追跡とデジタルツイン専門プラットフォーム`,
    description: 'リアルタイム位置追跡、入退システム、デジタルツイン、産業用IoTがもたらす空間の革新'
  },

  productText: {
    title: `デジタルツイン、位置追跡製品`,
    description: `変化を求めるあなたにオブロが提案する新しさ`
  },

  os: {
    title: '空間を最もよく理解するソフトウェア',
    desc: 'オブロOSは最新の通信技術を利用して、<br/>空間のすべての要素をリアルタイムで正確に追跡および管理します。',
  },

  rtls: {
    title: 'すべての現場に適用可能な位置追跡ラインアップ',
    desc: 'Bluetooth、UWB通信を利用した電波位置追跡と、映像とAI技術を利用した位置追跡ラインアップを提供します。',

    card: [
      {
        tag: 'リアルタイム位置追跡',
        title: '現実を仮想空間で',
        link: 'https://orbro.io/jp/rtls',
        img: {
          desktop: HomeRTLS1D,
          tablet: HomeRTLS1T,
          mobile: HomeRTLS1M
        },
      },
      {
        title: 'ビーコンを通じた位置追跡',
        desc: 'BLEを通じた位置追跡システム',
        link: 'https://orbro.io/jp/beacon/ble',
        img: {
          desktop: HomeRTLS2D,
          tablet: HomeRTLS2T,
          mobile: HomeRTLS2M
        },
      },
      {
        title: 'AIカメラを通じた位置追跡',
        desc: 'AI技術を活用した位置追跡および異常行動検知',
        link: 'https://orbro.io/jp/camera',
        img: {
          desktop: HomeRTLS3D,
          tablet: HomeRTLS3T,
          mobile: HomeRTLS3M
        },
      },
    ]
  },

  access: {
    title: 'モバイルを利用した自然な非接触入退',
    desc: 'モバイル、顔認識技術を利用した次世代の入退方式と情報を提供します。',

    card: [
      {
        tag: '多様な入退方式提供',
        title: 'NFC/Bluetooth/スマートフォン<br/>すべての方式の入退',
        sub: 'NFCカード入退はもちろん、<br/>モバイル入退、スマートウォッチ入退も可能です。',
        img: {
          desktop: HomeAccess1D,
          tablet: HomeAccess1T,
          mobile: HomeAccess1M
        },
      },
      {
        title: 'Access Pro',
        link: 'https://orbro.io/jp/access/face',
        desc: '顔認識、NFCカード、モバイルすべての入退方式をサポート',
        img: {
          desktop: HomeAccess2D,
          tablet: HomeAccess2T,
          mobile: HomeAccess2M
        },
      },
      {
        title: 'Access Auto',
        link: 'https://orbro.io/jp/access/auto',
        desc: 'モバイル入退の最適化',
        img: {
          desktop: HomeAccess3D,
          tablet: HomeAccess3T,
          mobile: HomeAccess3M
        },
      },
      {
        title: 'アクセサリー',
        desc: '多様な入退アクセサリーを提供',
        link: 'https://orbro.io/jp/access/accessory',
        img: {
          desktop: HomeAccess4D,
          tablet: HomeAccess4T,
          mobile: HomeAccess4M
        },
      },
      {
        title: 'Access Lite',
        desc: '基本に忠実なモバイル入退システムとNFC、Apple Watchサポート',
        tag: "近日発売",
        img: {
          desktop: HomeAccess5D,
          tablet: HomeAccess5T,
          mobile: HomeAccess5M
        },
      },
    ]
  },

  dt: {
    title: '世界初の第3世代デジタルトランスフォーメーション<br/>デジタルツイン専門プラットフォーム',
    desc: 'オブロのデジタルツインソリューションで都市を仮想的に実現し、さらに進化した革新を体験してください。',
    img: HomeDTBanner
  },

  beacon: {
    title: '産業用IoTおよびビーコンシステム',
    desc: '産業現場で求められる多様なセンサーおよびビーコンシステムで効率的な作業環境を構築します。',

    card: [
      {
        title: 'ALESS-GW',
        desc: 'LoRa中継機',
        link: 'https://orbro.io/jp/sensor/LoRaGateway',
        img: {
          desktop: HomeBeacon1D,
          tablet: HomeBeacon1T,
          mobile: HomeBeacon1M
        },
      },
      {
        title: 'ALESS-BA',
        desc: 'LoRa通信を利用した産業用センサー',
        link: 'https://orbro.io/jp/sensor/LoRaSensor',
        img: {
          desktop: HomeBeacon2D,
          tablet: HomeBeacon2T,
          mobile: HomeBeacon2M
        },
      },
      {
        title: 'VENTAX CO+',
        desc: '一酸化炭素測定器',
        link: 'https://orbro.io/jp/sensor/co',
        img: {
          desktop: HomeBeacon3D,
          tablet: HomeBeacon3T,
          mobile: HomeBeacon3M
        },
      },
      {
        title: 'VENTAX',
        desc: '空気質測定器',
        link: 'https://orbro.io/jp/sensor/air',
        img: {
          desktop: HomeBeacon4D,
          tablet: HomeBeacon4T,
          mobile: HomeBeacon4M
        },
      },
      {
        title: 'Plutocon Pro',
        desc: '産業用ビーコン端末',
        link: 'https://orbro.io/jp/beacon/industry',
        img: {
          desktop: HomeBeacon5D,
          tablet: HomeBeacon5T,
          mobile: HomeBeacon5M
        },
      },
      {
        title: 'ORBRO Tag',
        desc: '小型ビーコン端末',
        link: 'https://orbro.io/jp/beacon/tag',
        img: {
          desktop: HomeBeacon6D,
          tablet: HomeBeacon6T,
          mobile: HomeBeacon6M
        },
      },
    ]
  },

  solution: {
    title: '位置追跡とデジタルツインの融合',
    desc: '建設、物流、製造、医療など様々な産業分野でオブロの先端ソリューションをご覧ください。',
    button: 'お問い合わせ',
    buttonLink: 'https://orbro.io/jp/request',
    card: [
      {
        title: '建設',
        desc: '建設現場の安全管理、在庫管理など、建設現場がスマートに変わります。',
        link: 'https://orbro.io/jp/enterprise/construction/safety',
        img: {
          desktop: HomeSolution1D,
          tablet: HomeSolution1T,
          mobile: HomeSolution1M
        },
      },
      {
        title: '物流',
        desc: '大規模物流センターや倉庫でリアルタイムの在庫位置把握と作業者安全管理が可能です。',
        link: 'https://orbro.io/jp/enterprise/logistics/asset',
        img: {
          desktop: HomeSolution2D,
          tablet: HomeSolution2T,
          mobile: HomeSolution2M
        },
      },
      {
        title: '製造',
        desc: 'リアルタイムの製造プロセス管理が可能で、在庫を管理して生産性を向上させます。',
        link: 'https://orbro.io/jp/enterprise/manufacturing/process',
        img: {
          desktop: HomeSolution3D,
          tablet: HomeSolution3T,
          mobile: HomeSolution3M
        },
      },
      {
        title: '医療',
        desc: '病院、医療施設で患者を追跡・管理して安全を守ります。',
        link: 'https://orbro.io/jp/enterprise/medical/patient',
        img: {
          desktop: HomeSolution4D,
          tablet: HomeSolution4T,
          mobile: HomeSolution4M
        },
      },
    ]
  },

  //product.ts 
  product: {
    items: [
      {
        title: '無線位置追跡',
        description: '無線位置追跡による<br/>完全な資産管理、正確な位置追跡',
        link: '/jp/rtls'
      },

      {
        title: 'カメラ位置追跡',
        description: `AI技術を活用したカメラ位置追跡システム`,
        link: '/jp/camera'
      },

      {
        title: '入退システム',
        description: 'より速く、よりスマートに。<br/>新しい入退システム',
        link: '/jp/access'
      },
      {
        title: '産業用センサー',
        description: '火災検知から温湿度まで<br/>すぐに適用可能なセンサー',
        link: '/jp/sensor/LoRaGateway'
      },
      {
        title: 'モノのインターネット（IoT）',
        description: `シンプルなものから特別な変化`,
        link: '/jp/iot/bell'
      },
      {
        title: 'ビーコン端末',
        description: '完全なビーコンシステム',
        link: '/jp/beacon/industry'
      },
    ]
  },

  control: {
    title: `ORBROを通じた統合管理`,
    description: `1つのソフトウェア<br/>モバイルからデスクトップまで必要なものをシンプルに`
  },
  showMore: '詳細を見る'
}
