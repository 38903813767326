import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledParagraph } from "@components/Text";
import { css } from "@emotion/react";
import styled from "@emotion/styled";


export const ContentPage = styled.div<{notHome : boolean}>`
  display : flex;
  padding-top : 128px;
  padding-bottom : 64px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    padding-top : 96px;
  }


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding-top : 96px;

    ${({notHome}) => 
      !notHome &&
      css`
        padding-top : 64px;
        padding-bottom : 0px;
      `
    }
  }

`

export const ContentSection = styled.div`
  margin-top : 64px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;

  ${SCREEN_BREAKPOINT.TABLET} {
    margin-top : 48px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-top : 48px;
    display : flex;
    flex-direction : column;
  }
`


export const ContentBox = styled.div`
  width : 100%;
  height : 420px;
  display : flex;
  flex-direction : column;

`;


export const ContentImg = styled.img`
  height : 260px;
  width : 100%;
  
  aspect-ratio : auto 600 / 260;
  
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.08));

  object-fit : cover;

  cursor : pointer;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    height : 240px;
    width : 100%;
    aspect-ratio : auto 440 / 240;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    height : 220px;
    width : 100%;
    aspect-ratio : auto 361 / 220;
  }

`


export const ContentTag = styled.div<{bgcolor : string}>`
  padding: 4px 8px;
  border-radius: 4px;
  width: fit-content;

  ${({bgcolor})=> bgcolor && `background-color : ${bgcolor};`}
`;

export const ContentPara = styled(StyledParagraph)`
  overflow : hidden;
  text-overflow : ellipsis;
  display : -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ContentDisplay = styled(StyledDisplay)`
  width : 100%;
  text-align : center;
`;
