import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledParagraph } from "@components/Text";
import React, { useEffect, useState } from 'react';
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";
import { BorderBottomLine, ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import i18n from "@pages/i18n";
import simple from "@src/lang/ko/simple";
import { BeaconPage, Layout, NormalCard } from "./Beacon.style";
import { useLocation } from "react-router-dom";

import home from "@src/lang/ko/main/home/home";
import homeEn from "@src/lang/en/main/home/home";
import homeJp from "@src/lang/jp/main/home/home";
import homeCn from "@src/lang/cn/main/home/home";
import { HeadingH3 } from "@components/Text/Heading/Heading.style";
import { ShowMoreButton } from "@pages/CommonStyle/commonBtn";
import { CardTextBox } from "@pages/Main/Common/Functions/Functions.style";


const Beacon = () => {


  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const location = useLocation();

  const getPath = (i: number) => {
    if (location.pathname.includes('/en/')) {
      return homeEn.beacon.card[i].link;
    } else if (location.pathname.includes('/jp/')) {
      return homeJp.beacon.card[i].link;
    } else if (location.pathname.includes('/cn/')) {
      return homeCn.beacon.card[i].link;
    } else {
      return home.beacon.card[i].link;
    }
  }
  const [layoutMaxWidth, setLayoutMaxWidth] = useState<any>(null);

  useEffect(() => {
    const handleResize = () => {
      setLayoutMaxWidth(window.innerWidth);
    }

    setLayoutMaxWidth(document.documentElement.clientWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <StyledGridContainer>
        <BeaconPage>
          <DivAllCenter>
            <DisplayH2 size="small">
              <Trans i18nKey={`home.beacon.title`} />
            </DisplayH2>
            <Spacing pixel="16" />
            <StyledParagraph size="medium">
              <p>
                <Trans i18nKey={`home.beacon.desc`} />
              </p>
            </StyledParagraph>
            <Spacing pixel="64" />
          </DivAllCenter>

          <Layout>
            {home.beacon.card.map((data, i) => (
              <>
                <NormalCard w={layoutMaxWidth} img={data.img[layoutType]}>
                  <CardTextBox>
                    <HeadingH3 size="small" color={'#000'}>
                      <Trans i18nKey={`home.beacon.card.${i}.title`} />
                    </HeadingH3>
                    <Spacing pixel="4" />
                    <ParagraphColor color={'#646464'} size={"small"}>
                      <p>
                        <Trans i18nKey={`home.beacon.card.${i}.desc`} />
                      </p>
                    </ParagraphColor>
                    <Spacing pixel="16" />
                    <ShowMoreButton href={getPath(i)} target="_blank" rel="noopener noreferrer">
                      <img src={simple.showmoreImg[i18n.language]} alt="learn more button" />
                    </ShowMoreButton>
                  </CardTextBox>
                </NormalCard>
              </>
            ))}
          </Layout>

        </BeaconPage>

      </StyledGridContainer>

    </>
  )
}

export default Beacon;