import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";
import digitaltwin from "@src/assets/images/digitaltwin/video/traffic.mp4";

export default {

  helmet: '| What is Digital Twin? | Definition, Use Case',
  desc: 'Digital Twin refers to digitally modeling physical objects or processes from the real world to collect and analyze information in real-time. In other words, it provides an effective way to simulate and optimize real-world data in a digital environment.',
  keywords: 'Digital Twin, digital twin, smart city, smart city, digital twin technology, value of digital twin, digital twin use cases, future technology, digital innovation, future vision, orbro, kongtech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'What is Digital Twin? | Definition, Use Case',
  ogUrl: 'https://orbro.io/en/blog/digital-twin',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDn5W_M0e1npdG9n9MzOVOPXstcQFN609JOUoXoNFXx5-5yloUkGMm1ufjGdF0R9JPeeoW9ykX72SA54O2wG8Vc8sW09dZk2e0IDK9TeXeQEa--HWeYTcyW4jGYAJNHeFvfBs5a4BRrjqNwwxwwrqLUiPrfxt1Kh2LXvniTuSqgVgIjcSz_RxNn3xVuLJqTOqR4rqFbVHkYHahb2a4j49UHtTzHmHnfVmpdEQYWJVViBOrZ3tPK0EWAkyAmPCBugIKyPXfG0iiMHGMupqT-0FPP0g20RLM4zBag-s05hL1Ujp7TBBUm4Ib_MBExPF2QSW0-Ko50dZ7a9hFGGpBrZalaWJMlebmqnC01xrRhTTAcFFNKYYDtFKQNXpb6IQ43Ef9F4-Y4sfGroiz6XZChSKlwLGbp72vSY1vYD7KX3GNlBNvYgCchgmuUmC9myCzEJbjRkKRcKOFGCNqOwXq8Ql04pFpN3XNTkmcedlgJ8xLQIH6FvQ0eIrHRjI7BxmwNtFj2PCeXKhfii-bTYi3ZB9ao0wlQkWQ10gL_ZXLOaWbKY8h_pWysjm2m7iZ2zdJyJac7ZlaVuSwFAHC__vc17P51pGrnB957kwOqxbJPEUrfDsAwD0ucwfa9X5wm2OECrV5cGVJVGBUAz55SEPInk4aouVwJ5eGNvbUoi5mFQOqeqQd-SdcSyc9hMrhBbIK8zigjExJpaRWEchpc5nL5PiJ1Ap2b73ReZfichg5q3S7QYZbk1pQVzRkvF5aDagxH3bEDhyA-eoQBmUwXNZ-O1Rlw2yLhARV_3xTok0wVPRN1PRECRlPCwgkFBEOBCTJCHxr4evnVKMdan_h85axDlA4QL5fLDuhScyp8Yiy3f_L-av_QBdqjVIkovYRjt3Ofnds2NKJbIIEDkgjJ5fnvUgNxJHKpuO3_rrqHmZagGcPtWEq1ZiUTSnmPMFUvd0ip-6GeWiZCwnkJNzazZZ3y_sejVcyeAGQpURR2dl9a3CytaKdgrP0E5kIRrKZqFFzMRvEFEsCPQI1yC6GDAlnMfvv72q5nLzjm-gU_soTcmaBcciDyAbOhEPnFgayRxu0QEDHw-A-SJn8Ub_cYlh-adqEbax34AGDlnxQLW5cgoFo0SsnMA2xD14GMSns8hj8HFgyTd1D9LpDViC5e5qZimjm3K6z56isQsFByGjVb6CYaqdICSjtdtWBEsBFjqqxf3SwLXUKU-sdMuNMmi2rJeO5KsRUv2MjSRY6MIWgv0_nv629SFKM93QxSWT3de2SAWHmDtCI0l6zGKm3ts0heoIfsioTAKjyRw0DabC833amSZ4gIWF5IIwIdc9w1mj7lsTSi5JrZKoyo5ul2TTchUf_9QqFj3u7AYcfhreY7UvlPyJD7gzlp0GY8wyKXBvaFFq9_r84ZA8vOdTNArreD3OAi8owfQOAJ2_j4H3e6nsxTjTpWDLIoz0VPfiiT-1YXwlQ3yD0DMRkGckEnpWrCRwF-JTiI-1Cn2OTi1VgH2L1-Mw0wSen_gD_tT9lcRT4vlYC0eyRhDZlfNZAI2E8RRVwxB50SEeBgj=w1906-h1213',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'What is Digital Twin? | Definition, Use Case',
  date: '2023-12-28',
  tag1: '#DigitalTwin',
  tag2: '#DigitalTransformation',
  tag3: '#SmartCity',
  tag4: '#RTLS',

    // <br/>
    text: [
      {
        head: 'What is Digital Twin?',
        subHead: '',
        desc: `What if you could see familiar places like restaurants, offices, schools, libraries, and bus stops on a digital screen? Moreover, what if instead of static images like photos, you could see them in real-time, just as they are in our daily lives? <br/> If you own a restaurant and can't remember if you turned off the gas when leaving for home, you wouldn't need to go back; you could check directly. You wouldn't need to estimate when the bus will arrive at the bus stop because you could see the current location!<br/> This concept of Digital Twin refers to replicating real-world entities like people, vehicles, buildings, and cities into virtual environments on digital screens. <br/><br/> Digital Twin involves modeling physical objects or processes from the real world digitally to collect and analyze information in real-time. Essentially, it provides an effective way to simulate and optimize real-world data in a digital environment. This finds applications in various fields such as industry, construction, healthcare, and automotive sectors.`,
        dot: ''
      },
      {
        head: 'How Does Digital Twin Technology Work?',
        subHead: '',
        desc: 'Digital twin refers to the technology that converts real-world people, objects, and buildings into digital forms. The goal is to collect real-time data related to the location, status, and performance of objects and monitor them on a digital screen. <br/><br/> The operation of digital twin technology is composed of the following key steps:',
        dot: ''
      },
      {
        head: '',
        subHead: 'Data Collection and Connectivity',
        desc: 'The core of Digital Twin is to collect and connect data generated in the real world. Data from various environments is collected in real-time through sensors, IoT devices, etc. The collected data is then transmitted to a cloud-based platform, where it is processed and stored. This accurately reflects the real-world situation in the digital environment.',
        dot: ''
      },
      {
        head: '',
        subHead: 'Modeling and Simulation',
        desc: 'Based on the collected data, Digital Twin intricately models the physical object in the real world. Considering various variables and environmental factors, this model predicts and simulates the behavior in the real world. Through this process, Digital Twin can test operations in various scenarios and find optimal results.',
        dot: ''
      },
      {
        head: '',
        subHead: 'Real-time Reflection and Control',
        desc: 'Digital Twin is continuously linked to the real world, responding immediately to changes. Events or changes in the real world automatically update the Digital Twin, and it sends control signals to the actual system as needed. This ensures that Digital Twin always aligns with reality, reflecting the latest information and playing an essential role in influencing the actual system. Through these steps, Digital Twin reflects physical objects in the real world digitally, enabling real-time optimization and improved decision-making.',
        dot: ''
      },
      {
        video: digitaltwin,
        link: '/en/digital-twin',
        head: 'What is the Relationship with Smart Cities?',
        subHead: '',
        desc: 'Digital Twin plays a crucial role in implementing Smart Cities. By precisely modeling and digitally representing the physical elements of a city, Smart Cities can assess and optimize the city\'s status in real-time. For example, using the Digital Twin of a transportation system to predict and regulate traffic flow can enhance the efficiency of the transportation system. Additionally, Digital Twin facilitates efficient management of air quality, water usage, and energy consumption through environmental monitoring. Digital Twin and Smart Cities complement and enhance each other through interaction, contributing to the overall improvement of urban safety, efficiency, and convenience.',
        dot: ''
      },
      {
        head: 'Advantages of Digital Twin',
        subHead: 'Real-time Decision Making',
        desc: 'Digital Twin collects and analyzes real-world data in real-time, providing prompt information for decision-making. This capability enables quick responses and decision-making based on up-to-date information in work environments, contributing to maintaining and enhancing competitiveness.',
        dot: ''
      },
      {
        head: '',
        subHead: 'Automation and Efficiency',
        desc: 'Digital Twin, integrated with automated systems, optimizes work processes and enhances productivity. Automation based on real-world data simplifies routine tasks, minimizes human errors, and improves organizational operational efficiency.',
        dot: ''
      },
      {
        head: '',
        subHead: 'Cost Savings and Predictable Maintenance',
        desc: 'Utilizing Digital Twin allows real-time monitoring and prediction of the physical system\'s condition. This enables preventive maintenance, detecting system failures or issues in advance to save costs. Accurate data-based predictions facilitate planning maintenance schedules in advance, increasing predictability. This contributes to effective cost management by introducing stability and efficiency to businesses.',
        dot: ''
      },
      {
        head: 'Are There Applications of Digital Twin in Industries?',
        subHead: '',
        desc: '',
        dot: ''
      },
      {
        head: '',
        subHead: 'Utilization in Manufacturing',
        desc: 'Digital Twin enhances the efficiency of production lines in manufacturing processes. By building Digital Twin based on data generated at each stage of the process, real-time monitoring and optimization of the production environment are possible. This allows quick identification and improvement of issues in the production line, minimizing downtime through predictable maintenance. Additionally, Digital Twin is used to monitor and optimize product performance throughout its lifecycle, enhancing product quality.',
        dot: ''
      },
      {
        video: video,
        link: '/en/enterprise/manufacturing/process',
        head: '',
        subHead: 'Application in the Energy Industry',
        desc: 'In the energy industry, Digital Twin is used to optimize energy production and consumption by leveraging Digital Twins of power plants or energy infrastructure. Real-time monitoring of equipment or infrastructure in power plants allows predicting and planning maintenance and repair operations. This improves the stability and efficiency of energy production, and predictable maintenance helps manage costs effectively.',
        dot: ''
      },
      {
        head: '',
        subHead: 'Digital Twin in the Medical Field',
        desc: 'In the medical field, Digital Twin models the health status of patients, enabling healthcare professionals to provide personalized treatment. Real-time monitoring of the performance of medical devices and systems allows efficient operation and rapid response, enhancing patient safety and convenience.',
        dot: ''
      },
      {
        head: '',
        subHead: 'Utilization in the Automotive Industry',
        desc: 'In the automotive industry, Digital Twin optimizes driving performance and predicts maintenance and repair operations. Particularly with the advancement of autonomous driving technology, Digital Twin plays a significant role in improving environmental monitoring and interaction of vehicles. This enhances driver safety and convenience while maintaining optimal performance throughout the vehicle\'s lifecycle.',
        dot: ''
      },
      {
        head: 'What is the Outlook for Digital Twin?',
        subHead: '',
        desc: 'Digital Twin technology is rapidly being applied in various industrial fields, and it is expected to lead to even higher levels of innovation in the future. Some key outlooks include:',
        dot: ''
      },
      {
        head: '',
        subHead: 'Leading Role in Industrial Innovation',
        desc: 'Digital Twin is expected to play a central role in innovation in various industries such as manufacturing, construction, energy, and healthcare. Especially when combined with technologies like artificial intelligence, widespread adoption of 5G, and big data analysis, it is predicted to be more effectively utilized.',
        dot: ''
      },
      {
        head: '',
        subHead: 'Essential Element of Smart Cities',
        desc: 'Digital Twin is expected to establish itself as a core technology in the development of Smart Cities. It will act as a key element in the efficient management of city infrastructure, optimization of transportation systems, and environmental monitoring, among various areas.',
        dot: ''
      },
      {
        head: '',
        subHead: 'Integration with the Internet of Things (IoT)',
        desc: 'Through integration with the Internet of Things (IoT), Digital Twin is expected to exhibit even more powerful functionalities. Improved real-time data collection and analysis capabilities will lead to more precise modeling of physical objects.',
        dot: ''
      },
      {
        head: 'Conclusion',
        subHead: '',
        desc: 'Digital Twin breaks down the boundaries between the physical world and the digital environment, providing innovative solutions. This technology is driving efficiency improvements and innovation in various industrial sectors, and its future applications are anticipated in more diverse fields.'
          + ' If you want to explore more about ORBRO\'s Digital Twin technology, click the link below.',
        dot: ''
      },
      {
        linkText: 'Learn More',
        link: '/en/digital-twin',
        head: '',
        subHead: '',
        desc: '',
        dot: ''
      },
    ],
  

  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: 'A product that assists real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.assetTracking.key1
      },
    ],

    solution: [
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      },
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/en/enterprise/manufacturing/asset'
      }
    ]
  }
}
