import React from "react";
import { Trans } from "react-i18next";
import { StyledAdvantageItem, StyledAdvantageItemIcon, StyledAdvantageItems } from "./Advantage.style";
import { DisplayH2color } from "@components/Text/HeadingTagDisplay.style";
import { StyledAdvantage } from "../../Module/components/Advantage/Advantage.style";
import { HeadingColor, ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";

interface AdvantageProps {
  title: string,
  items: AdvantagePropsItem[],
}

export interface AdvantagePropsItem {
  icon: string,
  title: string,
  description: string
}

const Advantage = (props: AdvantageProps) => {
  // Props
  const { title, items } = props;

  // Translation

  return (
    <>
      <Spacing pixel="128" />
      <StyledAdvantage>
        <DisplayH2color size="small" color={"#fff"}><Trans i18nKey={title} /></DisplayH2color>

        <StyledAdvantageItems>
          {items.map((item, index) => (
            <StyledAdvantageItem key={`advantage-item-${index}`}>
              <StyledAdvantageItemIcon src={item.icon} alt="ORBRO Technologoy icon" />
              <Spacing pixel="16"/>
              <HeadingColor color="#FFF" size="xsmall"><p><Trans i18nKey={item.title} /></p></HeadingColor>
              <Spacing pixel="16"/>
              <ParagraphColor color="#FFF" size="medium"> <p><Trans i18nKey={item.description} /></p></ParagraphColor>
            </StyledAdvantageItem>
          ))}
        </StyledAdvantageItems>
      </StyledAdvantage>
    </>
  )
}

export default Advantage;