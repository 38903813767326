import { StyledGridContainer } from "@components/Grid/Grid.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import digitaltwin from "@src/lang/ko/digitaltwin/digitaltwin";
import React from "react";
import { HeaderGrid, HeaderImg, TextBox } from "./Header.style";
import { DisplayColor, ParagraphColor, Spacing, SpacingDTM } from "@pages/CommonStyle/common.style";
import { Trans } from "react-i18next";
import { DisplayH2color } from "@components/Text/HeadingTagDisplay.style";

const Header = () => {


  return (
    <>
      <SpacingDTM d={"128"} t={"96"} m={"64"} />
      <StyledGridContainer>
        <HeaderGrid>
          {digitaltwin.header.box.map((data, i) => (
            <>
              <TextBox>
                <DisplayColor color="#FFF" size="small">
                  <p>
                    <Trans i18nKey={`digitaltwin.header.box.${i}.title`} />
                  </p>
                </DisplayColor>
                <SpacingDTM d={"8"} t={"8"} m={"0"}/>
                <ParagraphColor color={"#DADADA"} size={"medium"}>
                  <p>
                    <Trans i18nKey={`digitaltwin.header.box.${i}.desc`} />
                  </p>
                </ParagraphColor>
              </TextBox>
            </>
          ))}
        </HeaderGrid>
        <SpacingDTM d={"128"} t={"96"} m={"96"} />
        <DivAllCenter>
          <DisplayH2color color="#FFF" size="small">
            <Trans i18nKey={`digitaltwin.header.title`} />
          </DisplayH2color>
          <Spacing pixel="8" />
          <ParagraphColor color="#E0E0E0" size="large">
            <p>
              <Trans i18nKey={`digitaltwin.header.desc`} />
            </p>
          </ParagraphColor>
          <Spacing pixel="32" />

          <HeaderImg src={digitaltwin.header.img} alt="Image Explaning Digital Twin Techonology"/>
          <SpacingDTM d={"256"} t={"128"} m={"128"} />
        </DivAllCenter>
      </StyledGridContainer>
    </>
  )
}

export default Header;