import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4"

export default {


    helmet: '| 스마트 병원이란?',
    desc: '스마트 병원은 IoT, 인공지능, 및 데이터 분석 등을 활용하여 환자의 건강 상태를 실시간으로 모니터링하고 진단 및 치료 과정을 개선하여 의료 서비스의 효율성과 품질을 향상시키는데 중점을 둡니다.',
    keywords: 'digital twin, 디지털 트윈, 스마트 공장, 스마트 팩토리, nvr, ip, ptz, rfid, nfc, uwb, 무선 통신, 위치 추적, 물체 위치 추적, 사람 위치 추적, 태그, 비콘, rtls, orbro, 오브로, 콩테크',

    ogTitle: '',
    ogDesc: '',
    ogSite: '스마트 병원이란? | 위치추적과 디지털 트윈 전문 플랫폼, 오브로',
    ogUrl: 'https://orbro.io/blog/스마트병원이란',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmPfoc5xcFsetUID5pyg3CYD7YMozk7v8agtjsAU3FWSwMrSh5jezcJw00E2PsYdRwBciFQJKL4IguNdWivv4bRwDkwLuH_X_qAV982dYuBJho1obfwpVkXOoGGsFzUHcZ6I-tLD3CCDnlOljA-ZIojFjbigYAYMSdbWykA0SplErndN38ZvDJCDdtAJKIn703TEZAaikiyqmO_dZMOzge0_bIm3eS8eIPGsweG-xjhoDelT8CCOdyeN0LY68PSWfiinGf0eEuOoS3pti-1ZwLu-JjJELZsO1tsY30mxgenUNvpYfjBhCHq2IFN2vX5T-39NwhhLOOxM_5WE1ec46dO8qlMqV2Z1m6FkaFlsNr4fCccF3HM0uahwB1AlQY9T47hoqeH3C6kQC3JbanZWKV_CWqzXDVXLdEbwzA7taG3ny_gWknaCAdR_v0oqgaUnAkuOldeRH5wVEsZC6SaHP8T-DZXTyl_XTvlPl3WRE5POEcdCSelPI6gRWOzIuFOfyImIPGqtxPuc2yuwk9lv372FquiGhHqBAkHQ8FoPn5lNd7nVuV0COyXuu5vdL3sdii7rC-xZQ9s55QCRPlGbX51dJb6u-ku9H_Cv-Dxr-zr4PV0TnSKTWQxdM0kJbKL2kEgQCahORl-3ojZaHOXWE9Hqu5GjfKdar2oI2wNIGP82lEb7oRlXwEtqokSSFGY2SW6Sz-GeEUyJWL17f4XqGaBnv-bC_6XYXuyWQpnAU7VPOSjZOh4BPpQTAS1qEXKVISNMt9c4urPNVRAtG5I0Pn2v-fcBE4wcQgixYinbHP2OdtdyBKdjGOo31Zlp78Hs7PzDyWCQ4x8a4vV1z2EbrciEe2pcWWkll9qrFFYRRF62ecCzT9vRLko6dD4j9RjTWUigzS-Y0Un4NBy4gSwfIKmYvjf5q1muT96LYHPdE1qu-d9sUUW5Cg05eUAfUwwEFDQkms0x5jM5ds68-FYCiI2aeTY3nlo9E84to4h4o9Z2LjufbbwC3Y_zkPzkbREeF-FKbkRGiE1gw7WuUfRQ-K16n3rdiCytnsClNeTgIw_xYkkGYGS9TCh-BgPnvBvnvrfviZdwMpI22X-Jp_F4dVL_OBwVKMqKAUATJNPCcNiqqoGAHJdOv4Zwt4odCav4i7sM7_ZdJaN08S5egxFZMpLL9Lu1TR9SGWSpT-opyrkMUo2yWTKLtxY6E4mjO1EV5a2qRD-OSbGxlT6-HBn2HvJpvlbmHyChUDA1LR5aTDsReQq8lP5lIHjbkA1lD0h9x-MuYp0QUXJWiAoMdRxNVQBsZUHjkJP41fTk1bBuizb8U9tp3kv_NReX8fCs7a8UNYbGnWllcN3mRBSET80gDqirtQm2Nw5uX7nXHQvgKOqFufopQIHmjdVZBBRgLs9ofbVSgaD3bryP8l7mMotKmEyOPA_cD-WbTB8aSMfUbgqc_WSyW_3VJi_4Y0B2QfdwRGnr2d_lPXG_V62CHnP1vjPlkC7xGDXxVzG6hmC6EWsrfSEUvAywKLYdmBc0bEGQzj33GVTHADzLtZ01SU_juNo-ASh-i-A=w1834-h1208',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '스마트 병원이란?',
    date: '2024-01-16',
    tag1: '#스마트병원',
    tag2: '#스마트의료',
    tag3: '#디지털트윈',
    tag4: '#위치추적',

    // <br/>
    text: [
        {
            head: '스마트 병원이란?',
            subHead: '',
            desc: '스마트 병원은 IoT, 인공지능, 및 데이터 분석 등을 활용하여 환자의 건강 상태를 실시간으로 모니터링하고 진단 및 치료 과정을 개선하여 의료 서비스의 효율성과 품질을 향상시키는데 중점을 둡니다. 스마트 병원은 의료 정보의 효율적인 공유와 의사 결정을 지원하여 빠르고 효과적인 의료 서비스를 제공하는 혁신적인 의료 시스템을 지향합니다. 이를 통해 환자들은 보다 편리하고 개인화된 의료 서비스를 받을 수 있게 됩니다.',
            dot: ''
        },
        {
            head: '스마트 병원 적용 기술',
            subHead: '',
            desc: '스마트 병원은 다양한 기술을 활용하여 현대적이고 효율적인 의료 서비스를 제공합니다. 주요 기술에는 다음과 같은 것들이 포함됩니다.',
            dot: {
                a : '<strong> • 실시간 모니터링 기술 : </strong>환자의 상태를 실시간으로 모니터링하여 의료진이 즉각적으로 대응하고 치료를 조절할 수 있습니다. 이는 환자 안전과 감시를 강화합니다.',
                b : '<strong> • 전자 의료 기록 (EMR) : </strong>환자의 의료 기록을 전자적으로 기록하고 관리하여 의료진이 효율적으로 정보에 접근하고 의사 결정을 내릴 수 있습니다.',
                c : '<strong> • 로봇 기술 : </strong>로봇은 수술 보조, 환자 돌보기, 의약품 제공 등 다양한 의료 작업에 사용되어 스마트 병원에서 인력을 지원하고 의료 서비스를 향상시킵니다.',
                d : '<strong> • IoT (사물인터넷) 기술 : </strong>의료 기기 및 센서를 통해 환자의 건강 상태를 실시간으로 모니터링하고 데이터를 수집합니다. 이를 통해 개인 맞춤형 의료 서비스를 제공할 수 있습니다.',
                e : '<strong> • 인공 지능 (AI) 및 빅데이터 분석 : </strong>AI를 사용하여 의료 데이터를 분석하고 예측 모델을 개발함으로써 질병의 조기 진단 및 예방을 강화하고 의료 비용을 절감할 수 있습니다.',
            }
        },
        {
            head: '스마트 병원의 장점',
            subHead: '',
            desc: '스마트 병원의 장점은 전자 의료 기록을 통한 효율적인 의료 기록 관리, 실시간 모니터링을 통한 빠른 대응과 치료 제공, 인공 지능과 빅데이터 분석을 활용한 질병 예방 및 조기 진단 강화입니다. <br/>또한, 환자 경험의 개선을 위한 간편한 예약 및 진료 과정, 개인 맞춤형 의료 서비스를 제공하는 IoT 기술의 도입, 가상 현실과 증강 현실을 활용한 효과적인 환자 교육과 재활치료, 로봇 기술을 통한 의료 인력의 지원 등이 있습니다. 이러한 기술의 융합으로 스마트 병원은 환자 치료 및 의료 서비스를 혁신적으로 향상시키고 있습니다.',
            dot: ''
        },
        {
            video : video,
            link : '/enterprise/medical/patient',
            videoDesc : '스마트 병원 솔루션, 클릭하면 이동합니다.',
            head: '스마트 병원의 사례',
            subHead: '',
            desc: '',
            dot: {
                a : '<strong> • Mayo Clinic : </strong>Mayo Clinic은 전 세계적으로 유명한 스마트 병원 중 하나로, 전자 의료 기록 및 실시간 모니터링 시스템을 통합하여 환자 치료의 효율성을 증가시키고 있습니다. 또한, 환자들에게는 휴대폰 애플리케이션을 통한 예약 및 의료 정보 접근 기능을 제공하고 있습니다.',
                b : '<strong> • Singapore General Hospital : </strong>싱가포르 일반 병원은 스마트 기술을 적극적으로 활용하여 의료 서비스를 혁신하고 있습니다. 실시간 모니터링, IoT 디바이스, 로봇 수술 등을 도입하여 환자 치료의 효율성을 높이고 환자들에게 개인 맞춤형 서비스를 제공하고 있습니다.',
                c : '<strong> • Sheba Medical Center : </strong>Sheba Medical Center는 가상 현실 (VR)을 사용하여 환자 교육 및 치료에 혁신적으로 접근하고 있습니다. VR을 활용한 치료 프로그램을 통해 환자들의 재활에 효과적으로 기여하고 있습니다.',
            }
        },
        {
            head: '스마트 병원의 전망',
            subHead: '',
            desc: '스마트 병원은 빠르게 발전하고 있는 의료 산업의 중요한 부분으로 자리매김하고 있습니다. 최신 기술과 인공지능을 활용하여 환자 진단과 치료를 효율적으로 진행하며, 의료 데이터의 실시간 공유로 의사들 간의 협업을 강화하고 있습니다. 환자들은 휴대폰 앱을 통해 건강 상태를 모니터링하고 의료 서비스에 손쉽게 접근할 수 있어 편리함을 경험하고 있습니다. 또한, 스마트 병원은 환자 개인의 의료 기록을 철저히 관리하여 의료정보의 안전성을 높이고 있습니다. 미래에는 더욱 더 발전된 기술과 혁신적인 서비스로 스마트 병원이 환자 중심의 효율적이고 안전한 의료환경을 제공할 것으로 전망됩니다.',
            dot: ''
        },
        
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/enterprise/medical/patient'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/enterprise/retail/exhibition'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/enterprise/office/asset'
            }
        ]

    }


}