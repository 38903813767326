import {  medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {


  helmet: '| 환자 관리를 위한 실시간 위치 추적',
  desc: '의료진이 환자의 현재 위치를 실시간으로 파악하여 응급 상황에 신속하게 대응하고, 치료 및 감시에 필요한 자원을 효율적으로 할당할 수 있도록 지원합니다. 이를 통해 환자 안전성을 높이고 의료 서비스의 품질을 향상시킬 수 있습니다.',
  keywords: '환자 위치추적, 환자 관리, 병원 위치 추적, 병원 관리, 스마트 병원, 환자 추적, 의료 시설 관리, 치매 환자 관리, 알츠하이머 환자 관리, 노인 환자 관리, 병원 운영, 메디컬 센터, 요양원 관리',

  ogTitle: '',
  ogDesc: '',
  ogSite: '환자 관리를 위한 실시간 위치 추적 :: 오브로 블로그',
  ogUrl: 'https://orbro.io/blog/patientTracking',
  ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4NuWirMGmbqpgIlWdCqK-ppWpzcgz_tlLlZI_o-5cHhj2j9qDRwsnEZiV8vOrMOlgGbh_KwwuV__dIaSBb4XYZB1HeJm3biM2fWsJG8fIJeS5V5kdQb1dPsENNDJZ_zA8YJEqLbd9oYhe7xJup9Cj0li6ASKbfdFzgjVSOv5nw9S1bq5HSdb3F-X1xE3Y3aAkqV1dkKqwPbFh5k9umUyucZ_TuNYaj2QcdjjdzsQfPpPJXnLHTYvP2jf2R80k52qNHygEEDSAP6if-9V8fj-RaWQ22wE13LmgV9GSECNTS9vVpS30mTAIwxI-GeD6m0J4Mu2OMwiArtjZQnqvHYKGzFumN9bqIchR5DBOue8f47fUqqH35eeZjPT6KFT0ykHX1UPgLuqhlouyD2V96TnHrcJjuoV6gj6n3qsfMYqj_-FLcJuy8biYIsFkBSzapyu4nuPfGB4aLMes_jDVehCX4VDdMVe5MlkWAXUqGZXvn_gsL8xrwcCn1wzGqA6pabPD-4W8aub76Rgsw4-Pch-5O3UQHuUkBEWDGzlYA3bmGZtL0It0WuJ2YrRICpcphRc-dkcsUpSMFFkRAs9wKE0iY_n-XdLq2SBW-p1sVPddxJuHi6s8sBXGYlI018XAOQfYkZ9iKUglZYhiwvfWTrp_MD_-xKSG5hRwPBRclRrIuqtQhCdmyLiG9PFc-k8Zzc0nrx9oLYcMOfOoCpKa3uNflVWy8JQF1jg6SWc_6IAzN3iex6DH7MiS1gjTRkd06IoN1ak0BjLij5u6JZa_2NoOCImCsjTG-saSx89XwTjolpf-0NeYZ7yC_7sHsUcLpFKtSoMNA2IMi1mfRtuPDpiuxiJISZ8WKPBm3HBfZqWs5D0MFWFd6HxrPotsmJoWiLBbJEbzcj6Q2r9zqygWKXnw12Kdv9WXPrOqSt3HJlmvd9u76idLGomybRZRJ_8z4q6IkYJwFotHED3XrmnxcD7076DgXVuhiRpSPI0w19qp6wccBXjOE2ySKWzLJO3NVFRXyi2YWpdZSKtZ34ZkHDDkhR29p6v4DI7wAxSn9oBNOXRdS83jsK8rE5DUHxS6GQjjgMhF08vjMlay-RB94xolSLw9nmiBfsmmCqHGEIReH-PBxHd6yEYhqR0V5YHFcAT6qUla5YiV4-CDftqlCmwRMuckmyx0d34NbvAUxWomI_-_8A8GwUdrcmAE5me-0RJYcrPd-McYwnI9Jr7_k8vQ-cUChbrrreLjukSuF5n93kEUvM6c9dUYGIZMnXLjjAMAYtg2Xkvf0_Iko5BlcvHIH4XZxUXOhxNZA3pvpgHI7OOt9GtIiSv6JkP1nr1VtFiy8MvV2LM19_SAcsitsu3Kh92FS8lUnTyo7PiQxxKmZTngLuMJToXuzVsXIqr6GuWEPzDBIyKZ7ti7MT57s-koDdp147Lx1bHkrwSIZ9PwC0MhHfJSS7hOUhyhSO-imUKDGcKUGAulSr_jiKtjiKlAbldoULjuMFSJeEM9OZ6Th9AbTNrgF0Br4Cj7MKqqqQJSJj2PE2hlGPBzFLo-qBP4x0ed_IHQB_8_czj6rpvIBt0YE=w2276-h1147',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '환자 관리를 위한 실시간 위치 추적',
  date: '2023-08-07',
  tag1: '#환자관리',
  tag2: '#환자위치추적',
  tag3: '#스마트병원',
  tag4: '#환자안전',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: '병원과 같은 의료시설들은 (당연하지만)환자들이 가장 모이는 장소일 것입니다. 그러므로 다른 산업보다 더 높은 수준의 안전 보장 시스템이 요구되며, 이를 위반할 시, 비즈니스의 손해 차원을 넘어 개개인의 생과 사를 결정짓는 중요한 문제라고 할 수 있습니다. 마찬가지로 환자와 보호자로서는, 안전이 더욱 보장된 의료시설이라면 가능한 한 더 좋은 시설에서 의료 서비스를 받고자 합니다. 즉, 서로의 요구를 충족시키는 방법은 더 안전한 의료시설을 만드는 것이라고 할 수도 있습니다.<br/><br/>핵심은 환자입니다. 환자의 상태를 실시간으로 모니터링하고 사고를 감지하고 예방할 수 있다면, 훨씬 더 빠른 대응이 가능할 것입니다. <br/>바로 실시간 위치추적 시스템, RTLS를 기반으로 이런 시스템을 안정적으로 구축할 수 있습니다.<br/>어떤 일들이 가능해지는지 아래에서 확인해 볼까요?',
      dot: ''

    },
    {
      head: '실시간 위치 추적',
      subHead: '',
      desc: '의료 시설 내에서 돌아다니는 환자들의 위치를 실시간으로 파악할 수 있습니다. 만약 특정 환자의 위치를 빠르게 파악해야 한다면, 검색을 통해 아주 신속한 파악이 가능합니다. 특히, 알츠하이머 환자와 같이 더 신경 써야 하는 환자가 있다면 효과적일 것입니다.<br/>꼭 환자가 아니더라도, 시설 내에서 길을 잃은 방문객의 위치를 파악할 수도 있고, 병실에서 진료가 예약되어있는 환자와 의료진의 위치를 실시간으로 파악하여 동선을 낭비하지 않도록 할 수 있습니다. ',
      dot: ''

    },
    {
      head: '낙상감지',
      subHead: '',
      desc: '거동이 불편하거나 관절에 무리가 있는 환자들에게 낙상 사고는 치명적일 수 있습니다. 이를 실시간으로 감지하여 즉시 의료진을 호출할 수 있다면, 만일에 발생할 수 있는 더 큰 사고를 방지할 수 있습니다.',
      dot: ''

    },
    {
      head: '역학조사',
      subHead: '',
      desc: '코로나와 같은 치명적인 감염병은 빠른 선제대응이 필수입니다. 그러기 위해서는 감염의심 환자를 파악해야 하고, 또 그러기 위해서는 감염환자가 접촉한 사람들을 파악해야 합니다. 실시간 위치추적 시스템은 해당 환자의 실시간 위치뿐만 아니라, 시설 내의 이동 동선도 기록합니다. 이로써 빠르고 정확한 역학조사가 가능하고 접촉자들을 빠르게 선별해낼 수 있습니다.',
      dot: ''

    },
    {
      head: '혼잡도 제어',
      subHead: '',
      desc: '비즈니스의 성장은 효율성을 기반으로 발생합니다. 그리고 소비자들(환자들)도 이를 매우 잘 알고 있습니다. 진료 시간이 너무 밀리거나 시설이 혼잡한 경우, 이는 좋지 못한 경험으로 이어지고 신뢰에 문제가 생기기도 합니다.<br/>실시간 위치 추적 시스템은, 시설의 혼잡도를 파악할 수 있습니다. 병목현상을 제어하고, 시설 운영을 원활하게 유지할 수 있습니다.',
      dot: ''

    },
    {
      head: '안전구역',
      subHead: '',
      desc: '약, 진료장비, 폐기물 등 의료시설은 특별히 관리되어야 하는 자산들이 많습니다. 도난, 사고. 분실을 예방하기 위해서 해당 구역을 안전구역으로 설정할 수 있습니다. 만약 허가되지 않은 외부인이 이 구역에 들어서는 경우, 즉시 관리자에게 알람을 보내어 대응할 수 있도록 합니다.',
      dot: ''

    },
    {
      head: '더 많은 기능',
      subHead: '',
      desc: '실시간 위치 추적 시스템은 사람에게만 적용 가능한 것이 아닙니다. 모든 자산을 추적할 수 있습니다.<br/>의료 폐기물이나 의료장비와 같이 지속적인 관리와 감시가 필요한 자산도 실시간으로 추적할 수 있습니다.',
      dot: ''

    },
    {
      head: '결론',
      subHead: '',
      desc: '많은 산업이 그러하지만, 의료 시설은 그 특성상 환자들이 의료 서비스를 선택하는 데에 더 많은 요소를 고려합니다. 특히나 그중에서도 ‘신뢰’는 거의 제일 중요하다고 할 수 있습니다.<br/>그러므로 실시간 위치 추적 시스템, RTLS는 현시점에서 가장 발전된 의료 시설 서비스를 구축하기 위한 해답이라고 할 수 있습니다.',
      dot: ''

    },

  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/enterprise/medical/patient'
      },
      {
        img: medicalWaste.thumb,
        data: 'medicalWaste',
        link: '/enterprise/medical/waste'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/enterprise/office/asset'
      }
    ]

  }


}