export default {
    back: '返回ORBRO博客',
    newletter: '订阅新闻通讯',
    info: '获取各种新消息！',
    placeholder: '请输入您的电子邮箱',
    subscribe: '订阅',
    terms1: "通过提供信息，我同意Orbro的",
    terms2: "",
    terms3: '隐私政策',
    terms4: '。',

    more: '查看更多',
    less: '折叠',

    recent: '最新博客文章',
    share: '喜欢这篇文章吗？<br/>分享一下',

    priceUnknown: '价格另询',

    price: '总价：',
    product: {
        title: '了解产品组成',
        access: '了解创新的门禁系统产品组成。',
        rtls: '了解创新的定位系统产品组成。',
        camera: '请确认相机系统的产品构成。'

    },

    uwbVideoDesc: '<br/><strong>(利用UWB技术的ORBRO解决方案，医院)</strong>',
    bleVideoDesc: '<br/><strong>(利用UWB技术的ORBRO解决方案，制造设施)</strong>',
    svVideoDesc: '<br/><strong>(利用摄像头技术的ORBRO解决方案，展览馆)</strong>',
    requestHead: '如果您需要更多详细信息',
    request: '随时',
    requestTail: '联系我们。'

}