export default {
    titleBanner: {
      subtitle: 'Stereo Vision',
      title: 'Stereo Vision을 이용한 <br/> 위치 추적 및 포지셔닝 기술',
      description: '* Stereo Vision은 결합된 두 개의 카메라를 사용하여 객체를 3차원적으로 인식하는 기술입니다. <br/> 이 기술은 인간의 두 눈과 유사한 원리로 작동합니다. 즉, 두 개의 카메라는 서로 다른 위치에서 동시에 같은 대상을 촬영하며, 이를 통해 객체의 깊이 정보를 얻어냅니다.'
    },
    tech: {
      title: 'Stereo Vision이란 무엇인가요 ?',
      text: 'Stereo Vision은 두 개의 카메라를 이용하여 이미지를 촬영하고, 이를 이용하여 3D 공간의 깊이 정보를 추정하는 컴퓨터 비전 기술입니다. 이를 위해 두 개의 카메라는 같은 객체를 서로 다른 위치에서 촬영하며, 획득한 이미지 쌍을 이용하여 깊이 정보를 계산합니다. 이를 위해 두 이미지 간의 시차, 즉 disparity를 계산하고, 이를 이용하여 깊이를 추정합니다. <br/><br/> 이 기술은 인간의 두 눈을 이용하여 깊이 정보를 추정하는 원리와 유사합니다. 이를 통해 3D 환경에서 객체의 위치와 거리를 파악할 수 있으며, 로봇 공학, 자율주행차, 비디오 게임, 영상 처리 등 다양한 분야에서 활용됩니다. 이는 기존의 Computer Vision 기술에 비해 보다 정확하고 실용적인 결과를 제공할 수 있어, 다양한 응용 분야에서 중요한 역할을 하고 있습니다.'
    },
    feature: {
      title: 'Stereo Vision을 이용한 RTLS의 특장점',
      text: 'Stereo Vision을 이용한 RTLS(Real-Time Location System)은 두 개의 카메라를 이용하여 수집한 시각적 정보를 분석해, 물체나 사람의 위치 정보를 실시간으로 추적하고 감시하는 시스템입니다. <br/> 이러한 Stereo Vision는 매우 정확한 위치 추론이 가능하며, 높은 정확도와 신뢰성을 보장합니다. 또한 실시간으로 위치 정보를 갱신할 수 있기 때문에 물류, 제조, 건설 등에서 중요한 실시간 위치 정보를 제공할 수 있습니다. <br/><br/> 또한, Stereo Vision 기반의 RTLS는 Tag를 사용하지 않기 때문에, 대상에 대한 추가적인 태그나 신호 발생 장비를 구매하고 유지보수하는 비용이 필요하지 않습니다. 이는 시스템 구성 비용을 절감하고, 시스템 유지보수 비용을 줄여줌으로써 비용면에서 효율적입니다. 또한, 대상의 위치 추적에 대한 정확성과 신뢰성이 높기 때문에 비용 효율성과 함께 시스템의 성능 면에서도 우수합니다. 따라서, Stereo Vision 기반의 RTLS는 비용 절감과 성능 향상을 동시에 이룰 수 있는 매우 유용한 기술입니다.'
    },
    
    howItWorks: {
      title: 'Stereo Vision을 이용한 포지셔닝은 어떻게 동작하나요 ?',
      card: {
        detection: {
          title: 'Object Detection',
          description: 'Vision 기반의 RTLS에서는 이미지에서 대상을 추적하기 위해 Deep Learning 객체 인식 기술을 사용합니다. 이 기술은 이미지 내에 있는 다양한 물체를 식별하고 분류할 수 있도록 학습된 인공지능 알고리즘입니다. 현재는 약 80 종류의 물체를 인식할 수 있으며, 고객의 요청에 따라 추가적인 학습을 통해 더 많은 종류의 물체를 인식할 수 있습니다.'
          
        },
        estimation: {
          title: 'Depth Estimation',
          description: '우리는 사물의 위치를 추적하기 위해 다른 구도에서 찍은 2장의 이미지를 이용해 깊이 정보를 유추합니다. 이는 인간이 두 눈을 사용하여 공간의 깊이를 인식하는 원리와 비슷합니다. 우리는 이를 위해 Deep Learning 기술을 사용하고 있어 고전적인 컴퓨터 비전 기술보다 더 정확하게 깊이를 인식할 수 있습니다.'
         
        },
        calculation: {
          title: 'Coordinate Calculation',
          description: '컴퓨터 비전 기술을 활용하여 객체 인식과 거리 추정, 각도 계산 등을 수행하여 객체의 위치 정보를 계산할 수 있습니다. 이를 통해 객체를 실시간으로 추적하거나, 위치를 파악하는 등의 다양한 응용이 가능합니다.'
          
        }
      }
    },


    core: {
        items:{
             title : `KongTech의 Stereo Vision을 이용한 RTLS의 핵심기술`,
            streaming:{
                title : `Original Image Streaming`,
                description: `RTLS에서 위치 추적 정확도를 높이기 위해서는 높은 수준의 Depth Map 추론 기술이 필수적입니다. 사람은 경험과 직관으로 대략적인 윤곽만으로도 거리를 빠르고 정확하게 인식할 수 있지만, Depth Map 기술은 이미지의 디테일에 집중하여 정밀한 추론이 위치 추적에 직접적으로 연결됩니다. 따라서 우리는 일반 CCTV에서 사용되는 손실압축 영상 스트리밍 방법을 사용하지 않고 무손실 영상을 활용하여 Low Level Feature의 손실을 최소화합니다. 이러한 방식으로 Stereo Vision을 이용한 RTLS는 위치정보의 손실을 최소화합니다.`,
                subDescription : `무손실 원본 영상을 활용한 Depth Map에서 사물의 표면이 좀더 연속적으로 표현됩니다.`,
                imgLabel1 : `추론배경`,
                imgLabel2 : `원본`,
                imgLabel3 : `인코딩`
            },
            estimation:{
                title : `Advanced Depth Estimation`,
                
                description: ` Depth 추론을 위해 스테레오 카메라 이미지를 활용하는 것은 컴퓨터 비전 연구 분야에서 큰 관심을 받고 있습니다. 특히, 최근 10년 동안 딥러닝 방법론을 적용하여 이전의 고전적인 알고리즘보다 더욱 탁월한 결과를 보여줬습니다. 그러나 여전히 많은 분야에서 고전적인 알고리즘을 사용하는 이유는 적절한 정확도와 높은 실시간성을 보장하기 때문입니다. 이는 일반적으로 15 FPS 이상의 프레임 속도로 처리가 가능하다는 의미입니다.`,
                imgLabel : `Computer Vision을 사용한 타사 Depth Map`,
                
                description2: ` 고전적인 컴퓨터 비전 알고리즘을 활용한 Depth 추론 결과물은 디테일한 부분과 물체의 외각에서 왜곡이 발생하는 문제가 있었습니다. 예를 들어, 마우스를 쥔 손의 윗부분이나 파티션에 가려진 사람의 머리와 같은 부분은 추론되지 않았습니다. 그러나 우리가 개발한 딥러닝 기반 모델은 이러한 문제점을 극복하였습니다. 이 모델은 "Stereo Vision을 이용한 RTLS"에서 사용할 수 있도록 최적화되었으며, 경량화 및 미세조정을 통해 실시간성을 보장합니다(>15 FPS). 다만, 여러 카메라의 입력을 처리하는 경우 처리속도는 약 4 FPS까지 느려질 수 있지만, 화면에서 사람의 이동 속도를 고려할 때에는 만족스러운 위치정보를 제공할 수 있습니다.`,
                imgLabel2 : `Deep Learning을 사용한 콩테크 Depth Map`
               
               
            },
            fineTuning:{
                title : `Depth Estimation Finetuning 기술`,
                description : ` 자연광과 인공조명에서 찍힌 이미지는 서로 다른 특성을 가지며, 이는 스테레오 카메라의 한 렌즈에만 조명이 닿은 경우에도 나타날 수 있습니다. 이 경우, 좌/우 이미지 센서의 노출값 차이로 인해 좌/우 이미지의 특성이 일관되지 않게 됩니다.`,
                imgTag : `조명의 직접적인 조사로 인해 나타난 좌/우 스테레오 이미지의 특성 차이`,

                description2 : `이러한 외부 환경 요인 뿐만 아니라, 스테레오 카메라 제조 과정에서 발생할 수 있는 내부 요인도 고려해야 합니다. 예를 들어, 이미지 센서를 기판에 실장할 때 발생할 수 있는 상/하 정렬 오차나 회전 오차, 렌즈와 이미지 센서 사이의 거리 변화에 따른 배율 차이 등이 해당됩니다.`,
                imgLabel : `(a): 밝기 차이, (b): 이미지센서 회전 오차, (c): 이미지 센서 상/하 정렬 오차, (d): 초점거리 오차`,
                imgLabela : `(a)`,
                imgLabelb : `(b)`,
                imgLabelc : `(c)`,
                imgLabeld : `(d)`,
                
                description3 : `이러한 현실적인 문제를 극복함으로써, Stereo Vision을 이용한 RTLS를 신뢰성 높게 제공할 수 있습니다. 콩테크의 Depth Estimation Finetuning 기술과 노하우는 이를 가능하게 하며, 결과적으로 Depth Estimation 기술은 특정 하드웨어에 의존하지 않고 환경 변화에도 강건하게 작동합니다.`

            },
            distance:{
                title : `카메라의 개체편차를 고려한 거리 계산`,
                description : `스테레오 카메라로부터 획득된 이미지를 딥러닝 기반의 Depth 추론 기술로 변환할 때, 광각 렌즈로 인한 Depth 왜곡 문제가 발생합니다. 이러한 왜곡은 거리가 가까워질수록 커지는 양상을 보이며, 광축에서 멀어질수록 더욱 비선형적인 거동을 보입니다.`,
                imgLabel : `광각렌즈에 의한 왜곡 양상 및 카메라 A, B 간 개체 편차 <br/> (초록색: 2.7m, 보라색: 6.3m, 빨간색: 8.8m)`,
                imgLabela : `카메라 A`,
                imgLabelb : '카메라 B',

                description2 : `이러한 문제를 해결하기 위해, 왜곡 양상을 정확히 모델링하여 실제 Depth와 일치하도록 보정하는 과정이 Depth 추론 기술의 중요한 부분입니다. 이 보정 과정은 카메라 렌즈 생산 과정에서 발생하는 편차나 조립 공정에서 발생하는 편차 등의 카메라의 개체적 불확실성을 고려해야 합니다. 왜곡 보정 모델을 적용하지 않으면, 광각 스테레오 카메라에서 추론된 Depth 값에서 일반적으로 30% 이상의 편차를 관찰할 수 있습니다. 그러나 왜곡 보정 모델을 적용하면 이러한 오차를 제거할 수 있을 뿐 아니라, Depth 추론의 평균 오차율을 약 50% 감소시키는 효과를 얻을 수 있습니다. 따라서, 저희는 모든 스테레오 카메라가 출고되기 전에 우리가 자체 개발한 고도화된 왜곡 보정 모델을 활용하여 보정을 진행합니다. 이를 통해 우수한 Depth 추론 기술을 제공하며, 고객에게 최상의 성능과 신뢰성을 제공합니다.`
            }




        }
    
    },
        
    advantage: {
        title: 'Stereo Vision의 주요장점',
        items: {
        accuracy: {
            title: '정확한 위치 추정',
            description: ' 스테레오 카메라를 사용하면 두 개의 이미지를 동시에 취득하여 3차원 공간에서 물체의 위치를 정확하게 파악할 수 있습니다. 이를 통해 물체의 위치와 움직임을 정확하게 추정할 수 있습니다.'
        },
        position: {
            title: '확장성',
            description: '스테레오 비전 기술은 공간의 크기에 구애받지 않으며, 다양한 규모와 유형의 실내 및 실외 환경에서 적용할 수 있습니다. 또한, 스테레오 카메라를 기반으로 하는 RTLS 시스템은 필요에 따라 추가 센서를 통해 기능을 확장할 수 있습니다.'
        },
        security: {
            title: '비용 효율성',
            description: ' 스테레오 비전 기술은 다른 위치 추정 기술에 비해 비교적 저렴합니다. 또한, 기존에 설치되어 있는 스테레오 카메라를 활용하여 RTLS 시스템을 구축할 수 있기 때문에 설치 비용을 줄일 수 있습니다.'
        },
        }
    },
      

    useCase: {
        title: 'Stereo Vision을 이용한 포지셔닝 사용 사례',
        description: 'Stereo Vision의 확장성은 다양한 실내 위치 파악 사용 사례에 효과적인 옵션으로 작용합니다. <br/> 다음은 Stereo Vision 실내 위치 파악 기술이 사용되는 일부 사용 사례 및 응용 분야입니다.',
        card: {
          auto: {
            title: '로봇 자동화',
            description: 'Stereo Vision을 이용한 로봇 자동화는 제조업 분야에서 많이 사용됩니다. 로봇은 스테레오 카메라로 환경을 인식하고 위치 추정 기술을 사용하여 제품을 자동으로 이동, 조립, 검사 또는 포장할 수 있습니다.',
            items : ` `
          },
          car: {
            title: '자율 주행 차량',
            description: 'Stereo Vision은 자율 주행 차량의 위치 추정, 장애물 감지 및 회피, 차선 유지 등에 사용됩니다. 스테레오 카메라의 3D 정보를 이용하여 주변 차량의 위치 및 방향을 추정하고, 이를 이용하여 차량의 이동 경로를 계산합니다',
            items : ` `
          },

          counting: {
            title: 'People Counting',
            description: '상업 시설이나 공공 시설에서 고객의 출입 및 인원 파악을 위해 People counting 기능을 이용합니다. Stereo Vision을 이용한 RTLS 기술은 입구와 출구에 설치된 카메라를 통해 통행하는 사람들의 입출입 여부를 구분하며 인원수를 실시간으로 파악할 수 있습니다.',
            items : ` `
          },
          space: {
            title: '공간 점유율 분석',
            description: '건물 내부나 주차장 등의 공간에서 Occupancy Data를 수집하여 공간의 활용도를 최적화할 수 있습니다. Stereo Vision을 이용한 RTLS 기술은 공간 내부에 설치된 카메라를 통해 공간 내의 사물의 위치 및 움직임을 실시간으로 파악할 수 있습니다. 이를 통해 공간 내부의 사용 상황을 파악하고 공간의 사용 효율을 높일 수 있습니다.',
            items : ` `
          },
          safe: {
            title: '산업 안전',
            description: 'Stereo Vision을 이용한 RTLS 기술은 산업 현장에서 안전을 유지하는 데에도 사용됩니다. 예를들어, 작업장 내에서 사람이나 물건의 위치를 실시간으로 파악할 수 있어, 작업 중 발생할 수 있는 사고 예방에 큰 도움이 됩니다. <br/> 특히, 산업 현장에서는 쓰러진 사람이나 작업자가 중대한 상해를 입었을 때 이를 빠르게 감지하여 골든타임을 준수하는 데에 도움이 될 수 있습니다. RTLS 시스템은 산업 현장에서 발생하는 다양한 위험 상황을 미리 감지하여 조기에 대응할 수 있도록 돕습니다. 이를 통해 작업자들의 안전과 건강을 지키고, 생산성과 효율성을 높일 수 있습니다. ',
            items : ` `
          },
          security: {
            title: '보안',
            description: 'Stereo Vision을 이용한 RTLS 기술은 보안 측면에서도 다양하게 활용될 수 있습니다. 예를 들어, 사물 또는 인물의 이동 경로를 추적하여 비인가 인원의 접근 제한 시스템, CCTV 모니터링 등에 사용할 수 있습니다. 이러한 기술은 건물 출입구나 주요 시설물의 보안 관리, 군사 산업 등에도 활용될 수 있습니다.',
            items : ` `
          },

          
        }
      }



  }