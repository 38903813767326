import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from "@pages/i18n";
import SEO from "@pages/SEO";
import Video from "../components/Video/Video";
import Banner from "../components/Banner/Banner";
import Func from "../components/Func/Func";
import SubFunc from "../components/SubFunc/SubFunc";
import System from "../components/System/System";
import Product from "../components/Product/Product";
import Contents from "../components/Contents/Contents";
import Request from "../components/Request/Request";
import NewsLetter from '../components/Newletter/NewsLetter';

import constructionSafety from "@src/lang/jp/enterprise/construction/constructionSafety";
import constructionAsset from "@src/lang/jp/enterprise/construction/constructionAsset";
import constructionEquipment from "@src/lang/jp/enterprise/construction/constructionEquipment";
import logisticsAsset from "@src/lang/jp/enterprise/logistics/logisticsAsset";
import logisticsSafety from "@src/lang/jp/enterprise/logistics/logisticsSafety";
import officeAsset from "@src/lang/jp/enterprise/office/officeAsset";
import manufacturingProcess from "@src/lang/jp/enterprise/manufacturing/manufacturingProcess";
import manufacturingAsset from "@src/lang/jp/enterprise/manufacturing/manufacturingAsset";
import medicalPatient from "@src/lang/jp/enterprise/medical/medicalPatient";
import medicalWaste from "@src/lang/jp/enterprise/medical/medicalWaste";
import retailExhibition from "@src/lang/jp/enterprise/retail/retailExhibition";

import seoEnterprise from "@src/lang/jp/seoEnterprise";
import { FontPage } from "@pages/CommonStyle/commonDiv.style";


interface ModalDefaultType {
  pageName : string
}

const Solution = ({
  pageName
}: PropsWithChildren<ModalDefaultType>) => {


    const paths = {
      constructionSafety: {
        path: constructionSafety,
      },
      constructionAsset: {
        path: constructionAsset,
      },
      constructionEquipment: {
        path: constructionEquipment,
      },

      logisticsAsset: {
        path: logisticsAsset,
      },
      logisticsSafety: {
        path: logisticsSafety,
      },

      officeAsset: {
        path: officeAsset,
      },


      manufacturingProcess: {
        path: manufacturingProcess,
      },
      manufacturingAsset: {
        path: manufacturingAsset,
      },


      medicalPatient: {
        path: medicalPatient,
      },
      medicalWaste: {
        path: medicalWaste,
      },

      retailExhibition : {
        path : retailExhibition,
      },


    };
    
    const location = useLocation();
    const isEnglishPage = location.pathname.includes('/en/');
    const isJapanPage = location.pathname.includes('/jp/');
    const navigate = useNavigate();
    useEffect(() => {
      if (isEnglishPage) {
        i18n.changeLanguage("en").then(() => {
          navigate("", { replace: true });
        });
      } else if(isJapanPage) {
        i18n.changeLanguage("jp").then(() => {
          navigate("", { replace: true });
          
        });
      } else {
        i18n.changeLanguage("ko").then(() => {
          navigate("", { replace: true });
        });
      }
    }, [location.pathname]);
    

    const helmetTitle = seoEnterprise.solution[pageName].title;
    const ogKey = seoEnterprise.solution[pageName].key;
    const ogDesc = seoEnterprise.solution[pageName].desc;
    const ogImg = seoEnterprise.solution[pageName].img;
    const ogUrl =seoEnterprise.solution[pageName].url;
    

    const jsonLd = {
      "@context" : "https://schema.org",
      "@type": "Product",
      "name": `${helmetTitle}`,
      "description": `${ogDesc}`,
      "alternateName": `${helmetTitle}`,
      "url":  `${ogUrl}`,
      "keywords": `${ogKey}`,
      "image": `${ogImg}`,
      "address": "#B-1203, 660, Daewangpangyo-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea",
      "email" : "orbro@orbro.io",
      "brand": {
        "@type": "Brand",
        "name": "ORBRO"
      },
     
    }

    const requestRef = useRef<HTMLDivElement>(null);
    const onRequestSection = () => {
      if(requestRef.current){
        requestRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };

    const [path, setPath] = useState(paths[pageName].path);
    
    useEffect(() => {
      setPath(paths[pageName].path);
      
    }, [location.pathname]);
    

    const currentLang = i18n.language;
    const key = location.pathname; 

    return (
        <FontPage key={key}>
          <SEO
            lang={currentLang}
            helmetTitle={helmetTitle}
            ogKey={ogKey}
            ogDesc={ogDesc}
            ogImg={ogImg}
            ogUrl={ogUrl}
            jsonLd={jsonLd}
          />
          

          <Banner pageName = {pageName} path={path} onRequestSection={onRequestSection}/>
          <Video pageName = {pageName}  path={path} />
          <Func pageName = {pageName} path={path} />
          <SubFunc pageName = {pageName} path={path} />
          <System pageName = {pageName} path={path} />
          <Product pageName = {pageName} path={path} />
          <div ref={requestRef}> 
            <Request pageName = {pageName} />
          </div>
          <Contents pageName = {pageName} path={path} />

          <NewsLetter/>
          


      
        </FontPage>
      )
};
    
export default Solution;