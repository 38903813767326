import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

   
  helmet: '',
  desc: '建設業界では、安全確保と労働者保護は非常に重要です。そのために、最新のリアルタイム位置情報システム（RTLS）技術が使用され、建設現場での労働者の安全管理を容易にする役割を果たしています。',
  keywords: '建設安全、建設現場の労働者安全、スマート建設、労働者の安全、労働安全衛生法、建設安全管理総合情報ネットワーク、建設労働組合、安全労働',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'スマートセーフティマネジメントの包括的なソリューション::オーブロブログ',
  ogUrl: 'https://orbro.io/jp/blog/smartSafety',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDyroUx1vmR8ckUGMDATiid3Dh8um-T9HPZwlPIYFAVQZ6gAqtjkC9yRmKCzc1E4y9OSYTPb_LCkF_SEylO00Rky0eS7weV0HFegyoQFnuWtZS4dJcgZhXg7NgKbB-lJHqoWX9iWFXOLGYRCck7Pzm9SecNQohk3gkJMABfK0W7SOahk5A_CpImCa6ZeYjnPrnJxJzATWPOAu_GUAv9tunvWcaHzwHNFGzd4wmKkzNRlpgiqTGmxGDiF1tnkU43_8zJ8Af3YPglR8V_Y8nr6hIKD0r_deC5eKuX9NhdkGi3_MwlH_6ID7fHAUY_4D8ay4UBjdHdIY39ZO5qmVVydqF4YA7_e2S6LGjkfGuhX06LkrzYLkNj0kAaBXMND1BhVSI-Zb-GpDot9xVAkg6mS0dhnTHGd4Dvh5P1F6CkjB2DJ8ZytwXnQJyQtOzhgYFcstok9388wIUDLs51CPoeUsytegmLUUG2NVsxXLfpB4yqeUz4gTQjH3WvGUQP3GKJkEsZ-fuzdaF3pTx9LLKB_xDvIeFhzmb4fONSHT2U9U_QEJjLCzt-NCZxHK3YK_xeHCQhB9h_8KLnCTSz8LgKKy80N4IfOpROiCh_oyYTiWXzhcWHWWh-OEvPtgY2xoCN2CtcClws0U3t-xRH5NQ9bsFsLU_41jxHLmULeU4UjUBfE50lAbgEhTIIi45ibRyNlruRzOzOsd3M129XYQM4F49mvW0c-MCe1D8fhLFbSg3TLCoO6lNHzOuGk5AhawFY6LXRmsNWIAf_orzFCaByHj8FIYhInRqA3JGuwUkGOVkqTrb2pAeqRb2Y0mO0a7Q5yuK7IEEVD6nNCJ9QrWtOBE8yYzrXVf54N7SL9aQz5ZCP4SCwp27j3V85cmk5dx-ZyDGiapVFg1rNA7Mwnx4L3F0eKNcRZzT9WWMWBXLD61fLbIfCcvoENjVSYEPO8VN5ipvNWSNwV5iHjace_sdYEzNMByEmrEjVt4slthi5itRPgSKrrgq1CQDcdZsSh1ToMCeUzPAcoPW9rnjGh8tngE7-VrPH5QLHldQu9mQy9skU-nCfY8A2DF9RRzB6So10votLNIb3bGaL-HSF5s0TOU8HlSAg2EKU6gM4LGwPjg5HztDXDoJyuRiBQcw6myNwpypRu3MPsD4EhS-uhn49VM4TVxsZMw6DJc5CXmZHhghyxX9GJyf2uHKWVOOzLkeJm2641BA8ZOpxpeg1bfNczrS9qVJDgRpzye_uh7uqihmtRlKAWH9tA2nouewAzocUyrzii7EX46YkP2GHg7DY_L_42aHc9EyTXPpGw3LDlJXHQ3sQ44RUyNV1EFE99w27XRmnxr_8YlZD9pegnY5GWNBiU35BwROQDKDsEzgKunAYgEhg3EspT4nJl4E_j19DHxsNer97Yudk2jhRWZ1QTcFWH67Ew8lCLdVw_yY0CqKWuMZK0o_379BLJt_dJIO5GVE-vhMEqdEN1zCUUp8yqBaruMbTm-_IfSHGOgQa4uue05eTTj-RcxLbAz-mRT8gzDo4yeOWNDBw4NsMtbQr-YOf89LvIvhhch-ND74zLC0KeIz5gM7VfV0VD4MkKBw=w2049-h1228',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',
    
    title: 'スマートセーフティマネジメントの包括的なソリューション',
    date: '2023-10-04',
    tag1: '#労働者安全管理',
    tag2: '#スマート建設',
    tag3: '#建設現場安全',
    tag4: '#RTLS',
    
    // <br/>
    text: [
        {
          head: '',
          subHead: '',
          desc: '現代の建設現場は複雑でさまざまなリスクを伴っています。安全を確保し、労働者を保護することは極めて重要です。そのために、最新のリアルタイム位置情報システム（RTLS）技術が使用され、建設現場での労働者の安全管理を容易にする役割を果たしています。本記事では、RTLS技術が労働者の安全性向上にどのように貢献するかについて探求します。',
          dot: ''
        },
        {
          head: 'RTLS技術の紹介',
          subHead: '',
          desc: 'RTLSはReal-Time Location Systemの略で、モバイル要素（労働者や設備など）の位置を正確に追跡し、監視する技術を意味します。RTLSシステムは、GPS、RFID（無線周波数識別）、UWB（超広帯域）、Bluetooth、超音波など、労働者の位置を追跡するためにさまざまな技術を使用しています。',
          dot: ''
        },
        {
          head: '労働者安全のためのRTLSの利点',
          subHead: '事故予防',
          desc: 'RTLSシステムは労働者のリアルタイムの位置を追跡することで、潜在的な衝突や危険を検出できます。たとえば、クレーンの操作中に他の労働者や設備との接近を検出し、事故を防ぐための警告を送信できます。',
          dot: ''
        },
        {
          head: '',
          subHead: '労働者の状態モニタリング',
          desc: 'RTLSは労働者の活動を監視し、健康状態や疲労度を追跡できます。労働者が疲れてきた場合、適切な休憩を取るようにアドバイスしたり、危険な作業から遠ざかるように警告したりできます。',
          dot: ''
        },
        {
          head: '',
          subHead: '緊急対応',
          desc: '緊急時には、RTLSシステムが正確な位置情報を提供し、救助や緊急医療支援に必要な時間を短縮します。これにより、労働者の命を救う可能性が高まります。',
          dot: ''
        },
        {
          head: '',
          subHead: '作業ログとレポート',
          desc: 'RTLSシステムは労働者の活動を記録し、作業ログを生成します。これにより作業スケジュールの最適化や生産性の向上が可能になります。また、事故が発生した場合、関連情報を記録して事故調査と報告を容易にします。',
          dot: ''
        },
        {
          head: 'RTLSの適用事例',
          subHead: '建設現場',
          desc: '建設現場はさまざまなリスクが存在し、多くの労働者がタスクに協力しています。RTLSシステムは設備と労働者をリアルタイムで追跡し、より安全な作業環境を作り出します。',
          dot: ''
        },
        {
          head: '',
          subHead: '工場と製造業',
          desc: '工場と製造業でも、労働者の位置を追跡し在庫を管理するためにRTLS技術が使用されています。これにより生産プロセスが最適化され、効率が向上します。',
          dot: ''
        },
        {
          head: '',
          subHead: '物流と倉庫',
          desc: '物流と倉庫では、在庫の追跡、倉庫内での労働者の位置の監視、出荷と配送プロセスの最適化にRTLSシステムが使用されています。',
          dot: ''
        },
        {
          head: 'まとめ',
          subHead: '',
          desc: 'RTLS技術は、建設現場および他のさまざまな産業で労働者の安全性と生産性を向上させるための強力なツールとして機能します。事故予防、労働者の状態モニタリング、緊急対応、作業ログ、レポートなどの機能を備えており、現場マネージャーや労働者に大きな利益をもたらします。今後、建設業界および他のセクターでRTLS技術のさらなる発展が期待されており、これらの環境で労働者の安全性向上のための取り組みを続けることが重要です。',
          dot: ''
        },
        {
          head: '',
          subHead: '',
          desc: '',
          dot: ''
        },
    ],
  
    link: {
      page: 'rtls',
      numb: '2',
      product: [
        {
          text: productPurchase.purchase.worker.workerTracking.title,
          title: productPurchase.purchase.worker.workerTracking.title,
          serial: productPurchase.purchase.worker.workerTracking.serial,
          desc: 'UWBデバイスを労働者や設備に取り付けてリアルタイムの位置追跡を支援する製品。',
          img: productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution: [
        {
          img: constructionSafety.thumb,
          data: 'constructionSafety',
          link: '/jp/enterprise/construction/safety'
        },
        {
          img: constructionAsset.thumb,
          data: 'constructionAsset',
          link: '/jp/enterprise/construction/asset'
        }, 
        {
          img: constructionEquipment.thumb,
          data: 'constructionEquipment',
          link: '/jp/enterprise/construction/equipment'
        }
      ]
  
  
    }
  
  
  }
