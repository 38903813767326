import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4';

export default {

  desc: '智能停车是在现代城市环境中解决停车问题并实现高效停车管理的先进技术和基础设施的利用，为车辆用户提供更便捷的体验。这种停车空间集成了各种技术元素，优化停车过程，并为车辆用户提供便捷的体验。',
  keywords: '智能停车、停车管理、休息设施、数字双胞胎、数字转型、数字双胞胎、orbro、信标、rtls、uwb、ble、ai摄像头、orbro、建筑技术',

  ogSite: '智能停车系统 | 位置追踪和数字双胞胎、orbro',
  ogUrl: 'https://orbro.io/cn/blog/smart-parking',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmD7VPkEf-FilCFjgoS9bLoX8jzIaaMktsjySXAPNjEeeFagK2_315Sl39yXx7L8iamkUCasZgYN_nmqjEzlcJ7b471GwtTQQuGwwOAfQQA6S8NE3asVOx8onF2HoacQcQFEPQ8CAhcl0rNpbh8bBS8FFHY4_D2fvH08mJkm9qWDYYAV69cnY6EoN7AbjCxcmjDp9MRJWdjSZLXFzgpaIIYwkOai0qq4fv00oAK7nW5aHmjQ7yW3Fs_dnUPcWjO02-SJvPDSXNRfqALw7R8fQ1xzAouT41STdr9FL0nvwawirXEQrJHdFYmtKxTMeYwrzrbvapEVr_9gbwWf7Xz6op2cCCQ9rlTypNRAUcoImxb8moTg_z4h0lB4He17_bFIKP8htIGOonJQ30cdXaym4saPjQhyRCio1wMUVwgbmNVC3b4zyJ5L9HnxyCsxDiVEDthb0FhoiTe75C-fAaLDxkhVfyjQTSX7lCYYG_JjPfTEo2kBb6fU79VYeUO4Kb_eWYiPR2Iwfzuu7StZDg_fEldXXzjVx6NJPi6R4a0r8VwuTtEwURqugwI57tg0kMwc1CN3P6SfBrzGPldfU6D5kntEPrH4rZvGkFLUOhGrKJWQrBodIb2rV5aACulW8o5W5D8wDEJkfyNS-F0oQHBEqzp9nZxKDuGMZ2gb_1rBuvwbphoql1q_W42SNGRTzjK3yZLzxxh9utXyY4bL4KVGrSVYX5BrshF1Uxy9qzyfmgNeI_sy5Tbah5GI3QhJG_MWU8nN5WicgsNpOWRVNSKyEhrt5hUOE5AVc60roL6Ar04TIJvjwBc85d0W_DonnbugsHZd-ZE2a1qACGExoN1GoO7gY8I0rGyaeXPXmz-eKFBYlni7VNvL_-WZlVfON20nmmnJGjJENADAWpBqdcJsKErZNryomDdplCibc53ZnYZ0-tE14kXFvQSMVx9rpq7xNi459vviazTPO_ZP6OKfTXX6tIrYW-vNA6r-vVM2nvber10dIqepSAUGPhm85RXyKGyHzU2sMQZA_0KKZR9ghdnDbzKyk6Ig0gcSclnaKoUAv0tV8ouS-IML_FSjTylgxNCHCeh5DRwimnmWs-M-Qj8E_pvYxpE5gDx5NFgO3KQtnEk7ZocAEpOSVFN5PHfWUhpTh-ZsM4joGNhdAiBAbc-VZzDs0jIIC2xpKDPdpUtVvIaVM9X_lf5FKcKd7VLx27m6hSL2bmbgdvx4t33C6fN69eGruOV7uvS1-K7vO6dqs-g4FWmrLDnOFmCGDyaa8gwvxozpfxaSUqklsYGSVuw6zCnAgAM-z6ApVjVb6P_aRbWW4ETJnzuMg3O4RVMoQZ1XGTM2kXC_0Um42ueBxfKmL7cAdcHi77Mxu7jLWBKoR1MI0uR-Flk-ouBsLn0joMzdQK6d3noiwmXVnNt26nLyiTY0OuyNhP5oDp6lXYyWjUsUwSZs2GpTcNDljddjYs1m1T7ckdu_JHlFsHpJ3RYQxuOcSAHdB4sv4hQohcrrbkctCpfLF8KUHAI4LbX1RqQ8eHJfD1wKMUSd7CydiQFcKD7FLnz=w1501-h1172',
  ogType: '网站',

  title: '智能停车系统',
  date: '2024-01-24',
  tag1: '#智能停车',
  tag2: '#停车管理',
  tag3: '#休息设施',
  tag4: '#数字双胞胎',

  text: [
    {
      head: '什么是智能停车？',
      subHead: '',
      desc: '智能停车是在现代城市环境中解决停车问题并实现高效停车管理的先进技术和基础设施的利用，为车辆用户提供更便捷的体验。这种停车空间集成了各种技术元素，优化停车过程，并为车辆用户提供便捷的体验。<br/>要实现这样的系统，必须应用数字孪生技术来创建与现实停车场完全相同的数字表示，并传输数据。其主要特点和功能如下：',
      dot: ''
    },
    {
      head: '智能停车的功能',
      subHead: '',
      desc: '智能停车利用各种先进技术提供多种功能，以提高停车管理和用户体验。这些功能对停车场管理员和车辆用户都有益处，并有助于解决城市的停车问题。以下是通常在智能停车中提供的主要功能列表：',
      dot: {
        a: '<strong>• 实时位置追踪：</strong> 使用AI摄像头、传感器和物联网技术实时追踪和管理停车场内的车辆位置，快速确定空闲的停车位。',
        b: '<strong>• 停车指南和导航：</strong> 通过移动应用程序或显示屏引导用户找到空闲停车位，使停车更加容易。',
        c: '<strong>• 预订停车位：</strong> 用户可以远程预订停车位，并在到达前进行确认。',
        d: '<strong>• 交通流量分析：</strong> 使用停车场内的传感器和摄像头分析停车场的高峰时段，将这些信息提供给用户。',
        e: '<strong>• 车牌识别：</strong> 自动识别车辆的车牌，自动检测和管理违规停车、超时停车等违规行为。',
        f: '<strong>• 安全性：</strong> 在停车场内安装闭路电视摄像头和传感器，提高车辆和停车设施的安全性。',
        g: '<strong>• 数据分析和优化：</strong> 分析收集的数据，优化停车场运营，发现改进点。这有助于停车场管理员更有效地利用资源。'
      }
    },
    {
      video: video,
      link: '/cn/enterprise/manufacturing/process',
      head: '智能停车技术',
      subHead: 'AI摄像头和视觉技术',
      desc: 'AI摄像头是一种基于高级计算机视觉技术的摄像系统，用于监视和分析停车场内的所有活动。它可以实时检测和识别车辆的车牌、车型、颜色、位置和方向等信息。这些信息用于优化停车场运营，向车辆用户提供停车信息，并用于识别和管理违规停车和违规行为。',
      dot: ''
    },
    {
      head: '',
      subHead: '物联网（IoT）',
      desc: '物联网通过在停车场内安装各种传感器和设备来收集和传输数据。传感器实时收集各种信息，如车辆移动、停车位可用性、停车场内温度、湿度等，这些信息用于停车场运营和管理，为车辆用户提供便捷的停车环境。',
      dot: ''
    },
    {
      head: '',
      subHead: '移动应用程序和网络服务',
      desc: '移动应用程序和网络服务用于向车辆用户提供停车信息和管理停车过程。用户可以使用移动应用程序查找和预订空闲停车位，还可以支付停车费用并监控停车状态。此外，通过网络服务，用户可以从计算机浏览器中查看和管理停车信息，为车辆用户提供各种便利。',
      dot: ''
    },
    {
      head: '',
      subHead: '大数据分析',
      desc: '大数据分析是指深入分析智能停车收集的大量数据的过程。收集的数据包括车辆用户的行为模式、停车场拥挤程度、停车费用政策的效果等各个方面的分析。',
      dot: {
        a: '<strong>• 优化停车场运营：</strong> 优化停车场的运营时间、停车位的布局、停车费用政策等，提高效率。',
        b: '<strong>• 改善用户体验：</strong> 了解用户行为，基于此改进停车过程，提高用户体验。',
        c: '<strong>• 增加收入：</strong> 通过大数据分析优化停车费用政策，找到增加收入的方法。',
        d: '<strong>• 开发预测模型：</strong> 通过数据分析开发预测未来停车位可用性、停车需求等模型，制定运营计划。',
        e: '<strong>• 问题识别和处理：</strong> 通过数据分析识别停车场内的问题，并采取迅速的解决措施，例如分配额外的停车位以减少拥挤。'
      }
    },
    {
      head: '智能停车案例',
      subHead: '韩国休息站 - 利用信标进行停车管理',
      desc: '韩国的一些停车场利用信标技术实施智能停车。信标与移动应用程序配合使用，提供实时停车位可用性信息，识别并管理停车场内的车辆出入。此外，通过移动应用程序，用户可以轻松支付停车费用。',
      dot: ''
    },
    {
      head: '',
      subHead: '美国洛杉矶 - 实时停车信息',
      desc: '洛杉矶引入了智能停车场，以解决市中心地区的停车问题，并提供实时停车信息服务。通过此服务，驾驶员可以使用移动应用程序查看可用的停车位位置和停车费用信息，从而更加便捷地停车。',
      dot: ''
    },
    {
      head: '',
      subHead: '中国上海 - 自动停车机器人',
      desc: '上海的一些停车场使用自动停车机器人来进行自动停车和车辆取回。驾驶员将车辆放置在停车场入口，然后使用机器人系统，缩短停车时间，提高便利性。',
      dot: ''
    },
    {
      head: '',
      subHead: '日本东京 - 自动停车费用支付',
      desc: '东京的一些停车场通过车牌识别技术和移动应用程序自动计算和处理停车费用。这使驾驶员能够快速离开停车场，省去了现金或信用卡支付的麻烦。',
      dot: ''
    },
    {
      head: '智能停车的未来展望',
      subHead: '',
      desc: '未来，智能停车预计将与自动驾驶汽车整合，优化停车过程，并更加重要地发挥自动停车功能。此外，高速数据通信技术的发展将促进实时数据共享，机器学习和人工智能技术将提高停车预测和用户行为分析。智能停车将成为智慧城市项目中交通管理和控制的重要组成部分，停车数据将用于城市规划和交通管理。此外，安全技术也将进一步加强，以确保车辆和停车设施的安全性。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '该产品支持在工作人员和设备上安装UWB设备以进行实时位置追踪。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionEquipment.thumb,
        data: 'constructionEquipment',
        link: '/cn/enterprise/construction/equipment'
      }
    ]


  }


}
