import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionEquipment, logisticsAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4';

export default {
   
    desc: '室内GPS是一种用于测量室内移动物体位置的系统。它可以实时跟踪人员和物体的位置。',
    keywords: '室内GPS、GPS、RTLS、UWB、BLE、信标、AI摄像头、位置跟踪、室内位置跟踪、实时位置跟踪、信标、orbro、康泰克',

    ogSite: '室内GPS解决方案 | 位置追踪专业平台，Orbro',
    ogUrl: 'https://orbro.io/cn/blog/indoor-gps',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDldPhj_PKmAYMbJqfxzx90kvwqvmaEJvuGa-Tilhn_LDEQtT2ogbSz96n2WA7ZAkr8WCRqOH4FmFoBufrCikZrXDwtb5It8GdPL_VxgMjGS1EnQAjJ4qrWYj4KcPq2uUwiEvls5sC1jYuNZWL9bM_ehwpvaclUZmuxqNlqIHYdw-FwmLbIsnVL148GQBP4aPXNwhvDLGmbonOGByrjok58YvYwxO339qVTHGs3T_pNB3bpk3KtiI87LFAQTozPEkOlHvJdDGXaK_LwvsTpFUd_Y38nRFI6ff6khHkuBibyrmO3mFIAaSxXYgd--mNSgro0BX8zdHQ8oeun8sW6yug8ZCW_V0fedhTrafZhPhB6nDSrdpkoQs10G4ftI0mB_kPLYFSeyTOOORJzy5jXFMNQmlE9TwHl3RNxv32lu3iDvi1odQiYyAywd92rwhHhlULlmvPWd111HclIULU9SqZ4agxxncGZtw6e2TnyrWoNGJueJfvaH5fNe1AEUTSlxwvK6z_lSaG4AO6elSZZcwVN5_z_GK5f9GbYZWX98FSOF8GuENEjrNIi-rqewypjL2c50sP3_C6v3rzWQnZeXsW31fgBWLAeWZWuJTLN0s0dJ5qPgh_FJliOSsh8w5i9RXV-6kBIjI4EOxtOvLP47aYE1GhfOvxS4ckLRSt_qxWTxU4D8gTX55bKtdogVsY85YNRVjOWb1XPLXo1-28zu_1LePcA2zEaUuqqntLjWTlSpr6Z-wLGNgRAg_T9hl3DPo2Rt04piFBRqjrdIpuwE-YH4n9Z7mlUXuAcPiq6n8RyQzWRG_c-6K1wE9RTRoEtR4qOPvVOCp58VLuf9ZyZ3fc12wznVHAaMFUA5k6_hWeGX-Lo_Fa5qY0yAgq6xhHE3qmu9iNue_6L3rSRz2JDy8FJjGddpCtZAZESd-tbSTG4-IlytOnvM1CViy2ygKKcbWecEfllglOtxIWzclw3EZ8wXQFBFSmmhnnoMVXv5ksBL6JpLrVF3rOyGKZWISqxoMn_KdCKyBVHDjkCKsEDSyHsUfvdlJIdsYzown--og4tGE0XLrE4pNXLyIVp45JOS0yUaykHqkRPQDs9g-iioTlotza8AE5gBtWK_SF_-ULnujQu1fKBH8CyAYkUVSHJ0wWxk8kIYcpT6AagBiYU9-jqARQkc5x9PghJFx6rTa61P615QnzFKlWCDYKYxkNmwMFutN-ucVMK2MwUCjp_gt5h6xBiTT3eOg1S0F7KupXENgRSYm7E0s4GYje4Eizvlcfe-0eLS8yENjj4sYl2DORd_mAlF-5DX37Dssx_oov83DQQe6W_8GjOw5ieJpA-klmSxjRGZ5vrMc4U3EY8D-cejqv61EFJo8BPbbZMzgj6no9VNG1TSQ2vuaj0VA5vDl03Hx_MKtsHsGR47umJQDLT2Zk6jOmcSN65erXXx4SqDnrqJn9xL8iky-Z9ZWHL_Jdl9eTPERAcH0OmmHzq8ltKodC4bSz63jP2XGVBtdrPJBvwYVJVrbXoLsVsNNRQx1TSSPO_HA8XpPrbumKkuRJiUhBaCrX7j-6PK_b3e_nP2PMpzIXBD=w1595-h1272',
    ogType: '网站',

    title: '室内GPS解决方案',
    date: '2024-01-31',
    tag1: '#室内GPS',
    tag2: '#RTLS',
    tag3: '#UWB',
    tag4: '#位置追踪',

    text: [
        {
            head: '什么是室内GPS？',
            subHead: '',
            desc: '室内GPS是一种用于测量室内移动物体位置的系统。它可以实时跟踪人员和物体的位置。GPS主要用于室外，利用卫星信号来获取准确的位置信息。但是，在室内，GPS信号通常会受到削弱或屏蔽，因此在室内进行准确的跟踪较为困难。',
            dot: ''
        },
        {
            head: '室内GPS技术',
            subHead: '',
            desc: '为了克服这些限制，室内GPS利用各种技术。其中一项关键技术是Ultra-Wideband（UWB）技术。UWB是一种利用极宽带宽的无线通信技术，可实现室内精确的位置测量。使用UWB标签和接收器，可以在建筑物内提供准确的位置数据。此外，还可以使用AI（人工智能）摄像头在室内环境中识别物体并进行位置跟踪。AI摄像头可以分析图像和视频以识别物体并确定其位置。',
            dot: ''
        },
        {
            head: '',
            subHead: 'UWB',
            desc: 'Ultra-Wideband（UWB）技术是实现室内GPS的主要技术之一。UWB使用极宽带宽生成大量信号，非常短的时间内。这种宽带宽允许准确测量时间和距离，对于室内空间的准确位置跟踪至关重要。以下是有关UWB技术的主要特点以及其在室内GPS中的实施的说明：',
            dot: {
                a: '<strong> • Ultra-Wideband（UWB）: </strong>UWB技术使用极宽带宽生成大量信号，非常短的时间内。这种宽带宽允许准确测量时间和距离。',
                b: '<strong> • 标签和接收器: </strong>使用UWB技术的室内GPS系统主要由“标签”和“接收器”组成。标签附在物体、人员或动物上，生成UWB信号。接收器接收这些信号并计算位置。',
                c: '<strong> • Time-of-Flight（ToF）测量: </strong>UWB系统可以非常准确地测量从标签生成的信号到达接收器的时间。这使得可以计算出两个设备之间的距离，即使在室内空间中也可以如此。如果使用多个接收器，可以在三维中计算物体的位置。',
                d: '<strong> • 实时位置跟踪: </strong>使用UWB的系统支持快速和准确的位置跟踪，可以提供实时位置数据。这在实时跟踪室内物体和人员的位置方面非常有用。',
            }
        },
        {
            head: '',
            subHead: 'AI摄像头',
            desc: 'AI摄像头是一种分析图像数据并执行各种任务的摄像头系统。AI摄像头的工作原理如下：',
            dot: {
                a: '<strong> • 视频采集: </strong>AI摄像头从环境中捕获视频或图像。这涉及使用镜头、图像传感器和其他硬件组件。从环境中收集的图像会转换为数字图像。',
                b: '<strong> • 数据预处理: </strong>收集的图像数据经过预处理过程以进行优化。在此阶段，执行分辨率调整、噪声去除、颜色校正等处理。',
                c: '<strong> • 特征提取: </strong>AI摄像头从图像数据中提取重要特征。这些特征包括与物体、人脸、动作等相关的信息。特征提取主要通过计算机视觉算法进行。',
                d: '<strong> • 应用深度学习模型: </strong>AI摄像头将提取的特征输入到深度学习技术使用的深度神经网络模型中。该模型以经过训练的状态对输入数据进行分析和判断。深度学习模型可以实时执行识别、分类、跟踪和预测。',
                e: '<strong> • 分析和判断: </strong>AI摄像头使用深度学习模型进行分析和判断。例如，人脸识别系统可以识别人脸并提取其特征以确认其身份。而物体跟踪系统可以实时检测和跟踪物体的位置。',
                f: '<strong> • 结果输出: </strong>AI摄像头以输出格式提供分析和判断结果。这些结果可以以各种格式显示，并通常提供给用户或相关系统。',
            }
        },
        {
            video: video,
            link: '/cn/enterprise/logistics/asset',
            head: '与常规GPS的区别',
            subHead: '',
            desc: '室内GPS与常规GPS（室外GPS）之间存在一些重要区别。这些区别与GPS系统主要在室外环境下运行，因此在室内进行准确的位置跟踪较为困难有关。以下是室内GPS与常规GPS的主要区别：',
            dot: ''
        },
        {
            head: '',
            subHead: '信号强度和障碍',
            desc: '',
            dot: {
                a: '<strong> • 室外GPS: </strong>常规GPS使用卫星信号来确定位置。信号可以自由传播在空中，通常是强大的，几乎不受建筑物或障碍物的干扰。',
                b: '<strong> • 室内GPS: </strong>在建筑物内部，GPS信号往往会减弱或受阻，可能性较高。墙壁、天花板、地板、金属结构等可能会损害或阻碍GPS信号。',
            }
        },{
            head: '',
            subHead: '卫星信号接收',
            desc: '',
            dot: {
                a: '<strong> • 室外GPS: </strong>通常，传统GPS需要接收来自至少3颗卫星的信号以获得准确的位置信息。通常，使用4颗以上的卫星可以提供更准确的位置信息。',
                b: '<strong> • 室内GPS: </strong>在室内，通常很难接收到卫星信号，或根本无法接收。这使得在室内进行准确的位置跟踪较为困难。',
            }
        },
        {
            head: '',
            subHead: '校正和增强技术',
            desc: '',
            dot: {
                a: '<strong> • 室内GPS: </strong>为了进行室内位置跟踪，使用了多种其他技术，包括Ultra-Wideband（UWB）、RFID（射频识别）、Wi-Fi、蓝牙和地面基础的位置跟踪系统等。这些技术的组合可以增强室内GPS系统并提高精度。',
                b: '',
            }
        },
        {
            head: '',
            subHead: '精度和准确性',
            desc: '',
            dot: {
                a: '<strong> • 室外GPS: </strong>传统GPS在最佳条件下可以达到约1米以下的高精度。',
                b: '<strong> • 室内GPS: </strong>室内GPS系统需要更多的努力才能实现准确的位置测量，精度因环境、使用的技术和实施而异。通常情况下，室内GPS的精度较低。',
            }
        },
        {
            head: '室内GPS的用例',
            subHead: '',
            desc: '室内GPS技术在各种应用领域中提供了便利性和效率的提升。用户可以在建筑物内获取准确的位置信息，从而提高相关服务和应用的便利性和效率。',
            dot: ''
        },
        {
            head: '',
            subHead: '在购物中心内跟踪产品位置',
            desc: '当消费者访问购物中心时，他们可以使用带有室内GPS的智能手机应用程序来查找所需产品的实时位置。这使消费者可以快速确定他们想要的产品的位置，从而提高购物体验并提高商店的效率。',
            dot: ''
        },
        {
            head: '',
            subHead: '医院内患者位置跟踪',
            desc: '医院使用室内GPS来跟踪患者的实时位置，以确保医护人员和患者的安全。例如，医护人员可以快速确定患者的位置，以应对紧急情况。',
            dot: ''
        },
        {
            head: '',
            subHead: '在机场为旅客提供导航',
            desc: '大型机场使用室内GPS帮助旅客轻松找到登机口、餐厅、商店等地方。旅客可以使用智能手机应用程序在机场内确定自己的位置，并访问所需的信息。',
            dot: ''
        },
        {
            head: '',
            subHead: '工厂自动化和机器人控制',
            desc: '室内GPS可以精确跟踪机器人和自动化设备的位置。这可以实现生产过程的优化，提高运营效率。',
            dot: ''
        },
        {
            head: '',
            subHead: '室内导航',
            desc: '在建筑物内进行室内导航对博物馆、大学、综合设施等地非常有用。访客可以轻松找到建筑物内的目的地，更容易探索旅游景点或教育设施。',
            dot: ''
        },
        {
            head: '',
            subHead: '停车场管理',
            desc: '室内GPS用于确定停车场内的空闲停车位，并引导驾驶员前往可用的停车位。这可以缩短停车时间，提高停车场的效率。',
            dot: ''
        },
        {
            head: '结论',
            subHead: '',
            desc: '室内GPS是一种精确跟踪建筑物内位置的技术，利用了Ultra-Wideband（UWB）和AI摄像头等技术。它在跟踪购物中心内产品位置、医院内患者位置、机场内旅客导航、工厂自动化和机器人控制、室内导航、停车场管理等各种应用中提供了便利性和效率的提升。然而，从信号强度和障碍、卫星信号接收、精度以及校正技术的角度看，室内GPS与传统GPS存在差异。室内GPS主要专注于室内位置跟踪。',
            dot: ''
        }
        
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: '使用UWB设备附在工作人员或设备上，可以进行实时位置定位的产品。',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }
        ],
        solution: [
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/cn/enterprise/retail/exhibition'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/cn/enterprise/logistics/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/cn/enterprise/construction/equipment'
            }
        ]
    }
}
