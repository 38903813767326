import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

   
  helmet : '',
  desc : 'RTLS 솔루션은 4차 산업 시대에 있어서 비즈니스 성장을 촉구하는 훌륭한 선택입니다. 따라서 올바른 RTLS 솔루션을 선택하는 것이 중요합니다. 다음은 RTLS 솔루션 선택 시, 고려해야 할 사항입니다.',
  keywords : 'RTLS, 4차산업혁명, 사업성장, 위치추적, 실내위치추적',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'RTLS 솔루션을 선택할 때 고려해야 할 사항 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/consider',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZcwlDQZU5H5qnmKjPCdFPeFV8_CDJMRUQZgpa1f1gRZkrk-n6YpwPszumPmKOPJnJFIlJShKqh_Q5wFcv3gmUpnEWmtobQ0b3vFCWKOqFl_3v4hkpS_t9J8Xii17KJEpKeSLt8T_ieoIYrWTgkSw35wq4vyGsWME_m4RNxlrtv45F_VObAxOwJ2GUEWq1wC4Bs4iSvdnNVnw7beJQ6VDOIpKyialic3nfvYBuzj7Cg7a1ZYNEz91Gw4ifCkDL5XMf6yIg9It_aR4VGT_qnUW5E1tAjOeYZvjX16A8i_6CapXmgXxT18PWHgjLwU5B7aLKeBiLI4OfYkeru88hyuTajrjyWXAMAexmkeM5hK9gE6163DFPgr7dn3EpQnHhwvl537Gw-aeQAgEoMXoKNNfjmhnoGbjBur1yDSA81JEh2fujJMtlIwoVQQYgeHQhvqh5ZbNAc1kJWx3u8Vu85TMLDudxXtCmkAAsJpEnKsK8H0f8FOKZnAnWvHCjZOfyaQVDCFqL5G-NBcjQl5f_XbGdizmytaouj5Qh7_bD-oWnmEzXhhIzW47zMmDei8tGOR6Mf3tj2Yc7rgoIV5u6sM-u7rkrwtIUq3ZDcLi4bjM3CI0nPMqFQKAnnSK3WuDHZi5gI7lgg4Z2fpGM446qKEFp7RlMo40wdQ3dDGM4onXDAOn8nsbvAD8RpJY6Oh88j_CclPLbtwl8MgE2mMC0lq3t9fPP6FKz1rc894KGlbpYh-CxCrSHow-eGoM5WzKfGCDI45s0Pd304BDuBsizABdkcHVyUuLIefrfFdm1xB4Dx42ostYrLcccYZbim5zxXfiDXFHxeydWnEaR_3JlR0yLvJ1YxiuPWEQJ39vr6QFMQh6vOM9-hIG5V2W9tZl1BldMwlHpqfXqfHX8QxTHXYKNVJ6okDuOqQBSoXQNnZyi45gSvhXcAFGXDnHVj07MF4L6OTTd9iGg8xBCKBnlB4glo7twHHpVLj2LTKNkQQoNsDTtWsTCqPeXsAGgwshNgiKy-SEHSPR4kzyBhzgs8mhVNGvnAKUDr_p9iOH56Ng_3rWMKm9eF2wubbi5ffi4M6O5SnQYfTqYo9ZN0fBibaVoDzfSxXS6sBZGsfWbmgHxVi5Nsmq6D-HCo6yfecXsHiCemyaUS2VxqGt6Sxq_uN4FeFNCeOUUXfUro_qyrrs8cnSxmC3jetAkdPXpA_KIukOkttU1TDuvHOKiRRd48YccqjhRT1LRh5iIRBYiowrDQi7ewnWTYajwzWPUqm7ygPvZR9qYdK2l76JMFxA8Ucznef6UP-ApuWqRaYpANDZYzkoussTQhGn2mtDmbH41EMoGLY7a8G9r9ghA4YWVFM9hb3U2YLujjDmKFRnntRhskbgxpOK01aYvoDSGH5iKKj3K6ggNfC9QqvCX31JrrJadskNS_1lsqfgZYTVRlQ4-Vhx48TXtAo-rpwhyFWECUbGgjVzThWzmNQheWVWbRH1WoNx5nnnL6skFUK2y-QqdTJPm_XtkUqwkblpJvRGIxrV53uYlfug204LpUQfGgcIRn5zCsmN7KyyQ9ANKRMygJmQ=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
  
    
    title: 'RTLS 솔루션을 선택할 때 고려해야 할 사항',
    date : '2023-05-19',
    tag1 : '#RTLS',
    tag2 : '#4차산업혁명',
    tag3 : '#사업성장',
    tag4 : '#실내위치추적',
    
    // <br/>
    text : [
      {
        head : '',
        subHead :'',
        desc : '4차 산업 혁명, Industry 4.0의 도래는 제품 제조, 개선 및 유통 방식에 있어서 급진적인 변화를 가져왔습니다.<br/>그중에서 선두를 앞서고 있는 많은 기업들이 RTLS를 새로운 솔루션으로 채택하고 있습니다. 이 기술은 최근 굉장히 빠른 성장과 함께 많은 서비스에서 중추 역할을 하기 시작했습니다.',
        dot : ''
        
      },
      {
        head : 'RTLS 란?',
        subHead :'',
        desc : 'RTLS는 실시간 위치 확인 시스템(Real-Time Location System)의 약자입니다. RTLS 기술로 수집한 실제 세계의 데이터들을 활용해 가상 세계의 지도를 만드는 데 사용될 수 있습니다. <br/>그런 다음 이러한 시스템은 알아보기 쉬운 형태로 처리하여 관리자에게 의미 있는 데이터를 제공합니다.',
        dot : ''
        
      },
      {
        head : 'RTLS의 이점',
        subHead :'',
        desc : 'RTLS 솔루션을 구축한 기업은 그렇지 않은 기업보다 방대한 이점을 제공받을 수 있습니다.<br/>직원 할당, 재고 관리 및 추적, 안전 프로토콜 등을 개발할 수 있습니다. 또한 다른 IoT 기기와도 통신할 수 있어 다양한 서비스를 효과적으로 구현할 수 있습니다.<br/>RTLS는 소매, 환대 및 의료와 같은 다양한 산업에서도 활용될 수 있습니다. 예를 들어, 병원은 RTLS를 사용하여 직원이 부족하거나 초과된 영역, 병원 침대의 가용성, 의료 장비나 의약품의 위치를 확인할 수 있습니다.<br/><br/>이러한 광범위한 애플리케이션은 RTLS를 엄청나게 빠르게 성장하는 새로운 시장으로 만들었습니다.<br/>＂futuremarketinsights＂에 따르면 2022년 46억 달러에 이르던 RTLS 시장은 2032년 373억 달러까지 성장할 것으로 전망했습니다.',
        dot : ''
        
      },
      {
        head : 'RTLS 구현 시 고려 사항',
        subHead :'',
        desc : 'RTLS는 구현하는 것만큼이나 비즈니스의 요구사항들을 잘 구별하여 계획하여야 합니다. <br/>다음의 RTLS가 효율적으로 구현되도록 하기 위한 고려사항입니다.',
        dot : ''
        
      },
      {
        head : '',
        subHead :'개인적인 필요성',
        desc : '기존의 GPS는 전 세계 어디에서나 자산의 실시간 위치를 제공할 수 있지만 5~20m 이내에서만 제공할 수 있으며, 이는 실내 환경에서 사용하기에는 너무 부정확합니다. RFID의 정확도는 높지만 작동 범위가 좋지 않아 제한적이어서 대규모 시설에서 수천 개의 수신기 태그를 사용해야 합니다. <br/>다른 RTLS 기술에는 그중에서, Wi-Fi 및 그중에서 포함되어 있으며, 이 모든 기술에는 장단점이 있습니다.<br/><br/>따라서 시설에 대한 RTLS를 선택할 때는 다음 사항을 고려해야 합니다.<br/><br/>',
        dot : {
          a :' • <strong>대상</strong> : 사람이나 자산을 추적하고 있습니까? 아니면 둘 다 추적하고 있습니까? 웨어러블 태그 또는 모바일 장치를 통해 추적할 것입니까? 얼마나 많은 장치를 추적하시겠습니까?',
          b :' • <strong>정확도</strong> : RTLS는 얼마나 정확해야 합니까? 자산이 특정 지역에 있는지 여부를 간단히 알 수 있는 것으로 충분합니까, 아니면 자산의 정확한 위치를 1m 미만으로 알아야 합니까?',
          c :' • <strong>대기 시간</strong> : 당신의 추적은 실시간으로 얼마나 되어야 합니까? 10~20초의 대기 시간을 요구사항에 맞게 설정할 수 있습니까? 아니면 좀 더 정확한 것이 필요하십니까?',
        }
      },
      {
        head : '',
        subHead :'적합한 공급업체 찾기',
        desc : '성공적인 기술 구현은 적절한 공급업체를 선택하는 데 달려 있습니다. RTLS 회사는 입증된 실적을 가지고 있어야 하며 당신의 요구사항을 이해해야 합니다. 좋은 공급업체는 또한 문제가 발생했을 때 그중에서 지원을 사용하는 방법에 대한 광범위한 교육을 제공해야 합니다. <br/> 미래에 대비하고 확장성이 뛰어난 RTLS를 선택하는 것도 중요합니다. 또한, RTLS 기술이 변화하고 성장하는 속도에 맞춰서 당신의 비즈니스에 적용할 수 있는 능력을 지닌 업체를 찾는 것이 중요합니다.',
        dot : ''
        
      },
      {
        head : '',
        subHead :'예산 및 ROI',
        desc : 'RTLS 시스템에 대한 예산을 결정하는 것은 특히 비즈니스에서 다른 이해 관계자가 승인해야 할 때 복잡한 프로세스가 될 수 있습니다. 먼저 추적 태그 및 로케이터 조달 비용, 설치 비용, IT 통합 비용, 클라우드 스토리지 및 일반 유지보수와 같은 지속적인 운영 비용 등 프로젝트의 각 부분에 대한 비용을 세분화합니다.<br/>RTLS 설치 및 운영을 위한 예산을 책정한 후 향후 10년간 예상 ROI(총 소유 비용)를 결정합니다. TCO 계산은 생산성 향상으로 인해 회사에서 발생할 수 있는 추가 매출뿐만 아니라 효율성 향상으로 인한 운영 비용 절감도 고려해야 합니다.',
        dot : ''
        
      },
      {
        head : '',
        subHead :'',
        desc : '',
        dot : ''
        
      },
    ],
  
  
    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/enterprise/construction/asset'
        }, 
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/enterprise/construction/safety'
        },
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/enterprise/logistics/asset'
        }
      ]
  
  
    }
  
  }