import gis from "@src/assets/images/digitaltwin/video/gis.mp4";
import weather from "@src/assets/images/digitaltwin/video/weather.mp4";
import buildings from "@src/assets/images/digitaltwin/video/buildings.mp4";
import tracking from "@src/assets/images/digitaltwin/video/tracking.mp4";
import traffic from "@src/assets/images/digitaltwin/video/traffic.mp4";
import airplane from "@src/assets/images/digitaltwin/video/airplane.mp4";
import cctv from "@src/assets/images/digitaltwin/video/cctv.mp4";

import video from "@src/assets/images/digitaltwin/video/traffic.mp4";

import { Icon3d3d, Icon3dCycle, Icon3dLink, Icon3dLocation, Icon3dMap, 
    Icon3dMap2, Icon3dMeter, Icon3dPlus, Icon3dServer, } from "@assets/images/icon";
import { DTexam1, DTexam2, DTexam3, headerImg, layerImg, usaPatent, verifyPatent } from "@assets/images/digitaltwin";


export default {

    banner : {
        title : '세계최초 3세대 디지털 전환<br/>디지털 트윈 전문 플랫폼',
        desc : `One step advanced Orbro's digital twin. Experience the innovation.`,
        btn : {
            left : '체험하기',
            right : '오브로 월드 소개 영상'
        }
    },

    header : {
        
        box : [
            {
                title : '세계 최초',
                desc : 'Web 기반의 가상세계 플랫폼'
            },
            {
                title : '1위',
                desc : '산업용 메타버스 실적 (한국 기준)'
            },
            {
                title : '500억+',
                desc : '아시아 최대 규모의 위치 정보 데이터'
            },
            {
                title : '20,000+',
                desc : '오브로에 연결된 실시간 오브젝트 갯수'
            },
        ],

        title : '디지털 전환, 디지털 트윈',
        desc : '현실 세계의 사람, 물건, 빌딩 그리고 도시의 데이터를 받아서, 디지털 화면 속 가상 현실에서 실시간으로 모니터링하고 관리할 수 있습니다.<br/>이를 통해 생산성 향상과 문제 예방이 가능하며, 주로 산업과 제조 분야에서 활용됩니다.',
        img : headerImg
    },

    connection : {
        title : '오브로 월드, 더 높은 수준의 디지털 트윈',
        desc : '아주 기본적인 지리, 인프라 정보들을 제공하는 수준을 뛰어넘어, 현실 세계에서 일어나는 일들을 실시간으로 파악할 수 있습니다.<br/>AI 카메라, 비콘, 고정밀 위치추적, 스마트 출입 시스템 등 오브로는 가장 앞서나가는 기술력으로 수준 높은 디지털 트윈 기술을 구축하였습니다.',
        img : layerImg
    },

    func : {
        title : '디지털 전환의 시대<br/>어디에나 적용 가능한 디지털 트윈',
        desc : '세상에는 수많은 데이터가 존재합니다. 오브로는 우리가 흘려 보내는 데이터들을 수집하여 세상을 더 이해하기 쉽게 만듭니다.',
        card : [
            {
                video : gis,
                title : '3D 기반한 시각 장치',
                desc : '현실 세계에 동일한 3D 형태의 시각 데이터로 정보를 제공합니다.<br/>건물과 건물의 내부까지도 모두 3D 지도 위에서 실시간으로 반영된 정보들을 보여줍니다. 이로써 더욱 생동감 있는 가상세계를 경험할 수 있습니다.',
            },
            {
                video : weather,
                title : '대기 및 기상정보 연결',
                desc : '대기와 기상정보를 오브로 서버와 연동하여, 실제 비가 오거나 눈이 오는 날씨도 똑같이 구현되도록 하였습니다. 좀 더 몰입감있고 사실과 같은 세계를 확인할 수 있습니다. 산업에 따라서는 이와 같은 정보들이 필수적일 수 있습니다.',
            },
            {
                video : buildings,
                title : '건물 정보 구현',
                desc : '시설물 상태를 실시간으로 모니터링하여 유지보수 일정을 최적화하고, 기계의 센서 데이터를 활용하여 고장을 예측하고 정비 작업을 효율적으로 계획할 수 있습니다. 또한, 건물 내 에너지 사용을 모니터링하여 최적화하여 에너지 효율성을 향상시키며, 환경에 미치는 영향을 최소화할 수 있습니다',
            },
            {
                video : tracking,
                title : '실내 건물 맵 구현',
                desc : '외부 데이터 뿐만 아니라, 실내에 있는 물건들과 그 옆을 지나다는 사람들 까지도 실시간으로 지도에 표시됩니다. 특히 유동인구, 방문자들의 정보나 보안이 충요한 시설의 경우라면 더더욱 의미있는 데이터가 될 것입니다.',
            },
            {
                video : traffic,
                title : '버스, 지하철 교통정보 연결',
                desc : '여러 교통 수단 간의 협력과 스마트한 이동을 가능케 합니다. 실시간 위치 추적을 통해 정확한 운행 정보를 제공하여 승객은 도착 예정 시간을 미리 파악할 수 있습니다. 연계된 교통정보는 최적 경로를 제시하고 이로 인해 교통 혼잡을 피하며 효율적인 이동이 가능합니다.',
            },
            {
                video : airplane,
                title : '항공기 정보 연결',
                desc : '연결된 정보는 항공편 지연이나 변경사항에 대한 신속한 알림을 제공하여 여행 일정을 최적화합니다. 스마트 게이트 및 수송 시스템과 통합된 항공기 정보는 여행자의 이동을 원활하게 조정하고 수송 네트워크의 효율성을 높입니다. 또한, 예측 분석을 활용하여 항공 운영을 최적화하고 연료 소비를 줄여 환경적 효과를 극대화합니다.',
            },
            {
                video : cctv,
                title : 'CCTV 영상 정보 연결',
                desc : '범죄 예방을 위해 CCTV 영상을 연결하면 이상 징후를 감지하고 이에 대한 경고를 발생시켜 지역의 안전을 강화할 수 있습니다. 교통 흐름 모니터링은 도로 교통을 최적화하고 교통 혼잡을 예방하기 위해 실시간으로 CCTV 영상을 연결하여 교통 상황을 모니터링하고 관리합니다. 또한, CCTV 영상 정보를 다양한 시스템과 통합함으로써 데이터를 종합적으로 활용하여 스마트 시티 기능을 강화합니다.',
            },
        ]
    },
    
    video : {
        title : '생생한 고화질 그래픽 연동',
        desc : '현실 세계의 정확한 모델링과 시뮬레이션을 가능케 하여 실시간 상황을 더 자세하게 이해하고 효과적으로 관리할 수 있습니다. ',
        video : video
    },

    patent : {
        card : [
            {
                title : '개인위치정보사업자 인증<br/>',
                desc : '317C-0052-69BC-05A3',
                img : verifyPatent,
            },
            {
                title : '전파위치추적 미국 특허<br/>',
                desc : 'DFS ID: 37686035',
                img : usaPatent,
            }
        ]
    },
    
    summary : {
        title : '주요기능 요약',
        desc : '',
        card : [
            {
                img : Icon3d3d,
                title : '3D에 기반한 시각 장치',
                desc : '도시 플랫폼은 3D로 구현되어 있어 보다 생동감있는 가상 세계를 체험할 수 있습니다.',
            },
            {
                img : Icon3dServer,
                title : '데이터 수집',
                desc : '다양한 소스로부터 데이터를 수직하여 중앙화 된 환경에 안전하게 저장합니다.',
            },
            {
                img : Icon3dMap,
                title : '위치 기반 서비스',
                desc : '사용자의 위치 정보를 활용하여 맞춤형 서비스를 제공합니다.',
            },
            {
                img : Icon3dMap2,
                title : '위경도 기반의 플랫폼',
                desc : '위치 정보는 모두 지리좌표계 정보로 변환되어 지도상에서 확인이 가능합니다.',
            },
            {
                img : Icon3dLocation,
                title : '공간 친화적 서비스',
                desc : '지능형 키오스크, 미디어월 등의 하드웨어 장치로 위치기반의 서비스를 제공합니다.',
            },
            {
                img : Icon3dLink,
                title : '데이터 연결',
                desc : '공공정보와 IoT/IIoT 센서 데이터 등을 연결하여 종합적인 데이터 환경을 형성합니다.',
            },
            {
                img : Icon3dCycle,
                title : '다양한 공공 정보 연동',
                desc : '오브로 월드는 국가나 지역에서 발생하는 다양한 데이터를 통합하여 관리하고 제공합니다.',
            },
            {
                img : Icon3dMeter,
                title : '리포트 및 분석',
                desc : '데이터 분석 결과를 보고서 형태로 제공하고, 효과적인 분석을 지원합니다.',
            },
            {
                img : Icon3dPlus,
                title : '다양한 어플리케이션 제공',
                desc : '수집된 데이터를 분석해 교통 정보, 환경 모니터링 도구, 스마트 시티 관리 등 다양한 어플리케이션을 제공합니다.',
            },
        ]
    },
    exam : {
        title : '오브로 월드의 다양한 활용',
        desc : '디지털 트윈 기술을 활용한 다양한 분야의 솔루션',
        card : [
            {
                img : DTexam1,
                link : 'https://home.orbro.io/',
                tag : '스마트 시티',
                title : '도시정보 연결',
                desc : '도시의 물리적인 측면을 디지털로 모델링하고, 실시간으로 모니터링하여 도시 운영과 발전에 다양한 긍정적인 효과를 제공합니다. 이를 통해 도시는 효율적인 자원 관리를 실현하고, 스마트 도시 개발을 추진하여 시민들에게 향상된 생활 품질을 제공할 수 있습니다.'
            },
            {
                img : DTexam2,
                link : '/enterprise/retail/exhibition',
                tag : '스마트 빌딩',
                title : '실내 위치추적',
                desc : ' 쇼핑몰이나 복잡한 건물 내에서 정확한 내비게이션을 제공하여 편리한 이동을 도와주며, 긴급 상황 시 신속한 대피를 지원합니다. 또한, 실내 위치추적은 개인화된 광고 및 서비스 제공을 가능케 하여 사용자 경험을 향상시킵니다. 업무 환경에서는 자산 관리 및 생산성 향상에 도움을 주며, 스마트 홈에서는 자동화된 제어 시스템과 연계하여 편의성을 증대시킵니다.'
            },
            {
                img : DTexam3,
                link : '/enterprise/logistics/asset',
                tag : '스마트 창고',
                title : '물류현황 확인',
                desc : '제품의 위치, 이동 경로, 및 재고 상태 등을 정확하게 파악함으로써 물류 네트워크의 효율성을 증대시키고 재고 관리를 최적화할 수 있습니다. 실시간 정보에 기반한 의사결정은 물류 프로세스를 민첩하게 조절할 수 있게 하며, 문제 발생 시 빠른 대응을 가능케 합니다.'
            },
        ]
    },



}