import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4"

export default {

    helmet: '| スマート空港ソリューション',
    desc: '旅行者の移動をリアルタイムで追跡し、空港内のさまざまなシステムを統合して運用効率を最大限に高めます。スマートチェックイン、自動化されたセキュリティスクリーニング、デジタル搭乗券、ゲート通知などのサービスが可能です。',
    keywords: 'スマート空港、デジタルツイン、NVR、IP、PTZ、RFID、NFC、UWB、無線通信、位置追跡、物体位置追跡、人物位置追跡、タグ、ビーコン、RTLS、orbro、contech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'スマート空港ソリューション | 位置追跡とデジタルツインプラットフォーム、Orbro',
    ogUrl: 'https://orbro.io/jp/blog/smart-airport-solution',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDnB84Bj2f8mz7hiRJi4WKgwiYq0yzs4GDe7VfFWOdEiQPlqEtyMMpSU-r7CcKy3BQ9FE63-XkxXVh-VAf440HOH1GA2iUOdHrknQN9GTI5y3ciXRGd5tlYsj4DhnmSkYTQpZas8pKO2QTEY2Yr1aKg7FEDLt0kRSvFKTZggTvuJepKze-kTLdpe_706Vc5UevQyGXNClLc9oJpNJDRuQC6tUHKnn_FiW52O6uSCoGFs28ONxKiUPRFE5Ju29eGYw_NfFWK5qCYQkem15ivnuMMmQSfx2L1JMQGPPP__JQ8WwQTWf0fqJZrIOChm-Ytl3cTDljPsL84S4SK3ww7wvpdBVVnfeI_Hg9DIMDCgf2m4_GsjXhNuJb8f9tyjQix7woDC35BeyLXjFP9o2ERnbG7fyvuPfPIRBgK5nDmmQ-wLv8t6GiBIp0c9271KpuXrz_4fq6Pq0V18HWuznis8j9ij1ifWmZi0BufNpYrlKZ2RdY4lq_6Gr9vc4ZPYRfJ-NFKGBI03EKiA9vlIrzcQTS3aEzIVovappIABtvO8PxqCkqhF0amIzISUH2Y5efT1YtPEGCevRdIC2I9_V7SvrQTvm0Qnp6vF-xmdybPAnJr0BD0vT05fpxoeHcN_WbUEtUQcbFsq6v1TanDDnB-XLp6Hj1LBhEApO0KLMdKr6hqANaGkrweuLO-ppA1rq_sNPCwSoX3tInq-mGVn9-HfzIH4u1P52EJjPxNZh60gOpVuZqkyH0P7dKgHso5PfMXAHJJwBIhCrzzILpZN8rrqZZvgP7AKPfAS06cevU6M0PaN0pZiHywpvCcQVdty1VzWLryj5hS4cQmTfrC3GcAHFJBDMgSUdZAhbiB7MSwtMhcD4ye-9kOD85_PonAQG9QMZMyrDPuna6Rnfr3NNhtq5VLL4M2wMxmiUDvxHg1WMRsQIoR-KUoTQ7imcUxIkCvzVkMe0UgWhSU3oamalc9rvOKud9cDgvAzuC1Pi4TUFJEZoHIvWiSKf6fqf7_yqlGHA5CggXGmBUVlSUJKMz4AF25WfOC3t3lBYczZ7sdmtLr4oU5MSzzsuoU1ZRAsXu21sJkggnYNxqgP12eulaDnKgNC2v2tUoVFG3FM0dx4L9exFCMlSC4o25cBb7aCwOiAArJL-pUeJfoo0RWP79r8X-Fai40OnK_c9Mu3JLlhPbxgOsjIIIqErT8CygE-qJyrNdf7TY6j9Onn6HFhBE3IeCPrkrAbSVNLPT43ZP_PQeII9yOMYE1U9feIAsW6V2sC75YSru67__inv-Qj90ltj2FbqExBTfA2Yr-kLz4oeF05ygNcG4IyVyMb40jXPlED8eAhW-C3QHc4YQUCMvuu6Di-Lbgtce_nkq_428rQwjdjJDzejxpTy6tyIKxtYbtWMLS2siVXz5PQ07dAbC6fOPlh5x1vyFEsMroUS20vNOqxpVKPYNog4lmhOQ5_KeNNrSPhRy2_zHR0c5l3sOApfG7vMjyOHlwSXmthwYqL1_Hgz_WbJ_B1jLdHt8fpAOw6KWq_yUsDx0yS9mIzNjDitluARF04p2PJ=w2038-h1285',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'スマート空港ソリューション',
    date: '2024-01-16',
    tag1: '#スマート空港',
    tag2: '#RTLS',
    tag3: '#デジタルツイン',
    tag4: '#位置追跡',

    // <br/>
    text: [
        {
            head: 'スマート空港ソリューション',
            subHead: '',
            desc: 'スマート空港は、現代の技術と先進的なシステムを活用して、旅行者に最適なサービスを提供する空港を指します。目標は、さまざまなスマートテクノロジーとデジタル革新を通じて効率を最大化し、旅行体験を革新することです。<br/>これには、室内環境制御、AIベースのセキュリティシステム、RTLS技術など、さまざまなスマートテクノロジーを活用して旅行体験を最適化し、効率を最大化することが含まれます。スマート空港は、旅行のすべての段階で最適化されたサービスを提供し、顧客満足度を向上させることに焦点を当てています。',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/jp/enterprise/construction/equipment',
            head: 'スマート空港技術',
            subHead: '',
            desc: 'スマート空港は、さまざまな先進技術を活用して旅行者に革新的で便利なサービスを提供します。スマート空港に導入できる技術には、次のようなものがあります:',
            dot: ''
        },
        {
            head: '',
            subHead: '室内環境制御システム',
            desc: 'スマート空港は、旅行者に最適な環境を提供するために室内環境制御システムを導入しています。これにより、空港内の温度、湿度、空気の品質をリアルタイムでモニタリングし、調整することで、旅行者は快適に待つことができます。また、スマート照明システムは自然光の模倣とエネルギー効率の向上を促進し、旅行者に生活の利便性を提供します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'AIベースのセキュリティシステム',
            desc: '安全は旅行の中核的な価値の一つです。スマート空港は、AIカメラを使用して高度なセキュリティシステムを構築しています。顔認識技術、行動分析、そして不正行為の検出を組み合わせてセキュリティレベルを向上させ、旅行者に安心感を提供します。また、リアルタイムで異常な兆候を検出し、即座に対応できます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'RTLS（リアルタイムロケーションシステム）技術',
            desc: 'RTLS（リアルタイムロケーションシステム）技術を活用して、スマート空港は旅行者のリアルタイムの位置を追跡します。これにより、空港はゲート、ショップ、荷物輸送システムと効率的に連携し、旅行者の移動を最適化します。また、経路分析と安全管理にも有益なデータを提供します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'データ分析と人工知能',
            desc: 'ビッグデータと人工知能を使用したデータ分析は、スマート空港運営の最適化に重要な役割を果たしています。旅行者の移動パターンやサービス利用トレンドを分析することで、効率的な運用と改善が可能となります。これにより、空港マネージャーはリソースを効果的に配分し、旅行者に対してより良いサービスを提供することができます。',
            dot: ''
        },
        {
            head: 'スマート空港の利点',
            subHead: '',
            desc: '',
            dot: {
                a: '<strong> • 便利な旅行体験：</strong>スマート空港は、自動チェックインプロセスやリアルタイム情報提供を通じて旅行者に高い利便性を提供します。顔認識を使用した自動チェックインやセキュリティ検証により、旅行者はスムーズに移動し、サービスにアクセスしやすくなります。',
                b: '<strong> • 向上した安全性とセキュリティ：</strong>AIカメラや顔認識技術を活用したスマートセキュリティシステムは、旅行者の安全性を向上させ、空港敷地内での潜在的な事故や脅威を事前に検知します。これにより、より安全で信頼性の高い旅行環境が確保されます。',
                c: '<strong> • 効率的な運用とリソース管理：</strong>データ分析とビッグデータの活用により、スマート空港の運用を最適化し、リソースを効率的に管理することが可能となります。人工知能技術は旅行者の移動パターンを理解し、運用効率の向上に寄与します。',
                d: '<strong> • 最適化された室内環境：</strong>室内環境制御システムにより、旅行者は快適な環境で待つことができます。温度、湿度、空気品質のリアルタイムモニタリングと調整により、旅行者の利便性が向上し、質の高い待機環境が提供されます。',
                e: '<strong> • 迅速な処理と入場手続き：</strong>自動処理により、旅行者に不便を最小限に抑え、迅速なチェックインと入場プロセスが提供されます。これにより、旅行者は時間を節約し、快適に移動することができます。'
            }
        },
        {
            head: "スマート空港の事例",
            subHead: "ハマド国際空港（DOH）、カタール",
            desc: "ドーハ国際空港は、スマートシティの一環として先進的なサービスを提供するためにスマートテクノロジーを積極的に取り入れています。自動案内サービス、スマートセキュリティシステム、モバイルアプリケーションを通じた旅客向けの便利なサービスが導入されています。",
            dot: ""
        },
        {
            head: "",
            subHead: "チャンギ空港（SIN）、シンガポール",
            desc: "チャンギ空港は革新的なテクノロジーの積極的な導入により、世界最高の空港の一つとして認識されています。自動チェックインシステム、インテリジェントな手荷物取り扱い、空港内のスマートステーションは旅行者に先進的な利便性を提供しています。",
            dot: ""
        },
        {
            head: "",
            subHead: "ドバイ国際空港（DXB）、アラブ首長国連邦",
            desc: "ドバイ国際空港はスマートテクノロジーと革新的なサービスにより、国際的な旅行ハブへと進化しています。AIベースのスマートセキュリティシステム、自動車、モバイルアプリケーションを通じた旅客体験の向上が導入されています。",
            dot: ""
        },
        {
            head: "",
            subHead: "サンフランシスコ国際空港（SFO）、アメリカ",
            desc: "サンフランシスコ国際空港は効率的な運用とスマートサービスで注目を集めています。IoTを使用した室内環境モニタリング、スマートな手荷物取り扱いシステム、モバイルアプリを通じた旅客向けの利便性の向上が特徴です。",
            dot: ""
        }

    ],
    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB端末を労働者や設備に取り付けてリアルタイムの位置追跡をサポートする製品。',
                img: productImg.product.workerTracking.thumbnail
            },
            {
    
            }
    
        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/jp/enterprise/medical/patient'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/jp/enterprise/retail/exhibition'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/jp/enterprise/office/asset'
            }
        ]
    
    }
    
}
