export default {

  subtitle: "導入に関するお問い合わせ",
  title: "ORBROへのお問い合わせ",
  description: "以下のようなORBROサービスやソリューションに関するお問い合わせがございましたら、いつでもお気軽にお問い合わせください。",
  checkList: [
    "サービス利用料金はどのようになっていますか？",
    "カスタムサービスは可能ですか？",
    "他のサービスとの連携は可能ですか？",
    "ソリューション導入を希望します。",
    "技術サポートを受けたいです。",
    "製品を購入したいです。"
  ],
  call: "迅速な回答が必要な場合は、お電話でお問い合わせください：<strong>1522-9928</strong>",
  callTime: "平日（10:00〜19:00）",
  form: {
  name: "お名前",
  namePlaceholder: "山田太郎",
  title: "役職",
  titlePlaceholder: "任意です。",
  companyName: "会社名",
  companyNamePlaceholder: "会社名を入力してください。",
  email: "業務用メールアドレス",
  emailPlaceholder: "name@company.com",
  phoneNumber: "電話番号",
  phoneNumberPlaceholder: "010-1234-5678",
  comment: "どのようにお手伝いできますか？",
  commentPlaceholder: "見積りのお問い合わせ、ソリューションのお問い合わせ、ビジネス提案など、ご希望内容をご入力ください。",
  policy: {
    require: "[必須]",
    agree: "同意する",
    policy1: "個人情報の取り扱いについて"
  },
  submit: "導入に関するお問い合わせ"
  }
  
}