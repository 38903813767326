import React from "react";
import { BannerPopFrame, BannerPopPage, BannerPopTopSection, CloseBtn } from "./BannerPop.style";
import { IconXcircle } from "@assets/images/icon";
import i18n from "@pages/i18n";


const BannerPop = ({ onClickToggleModal }) => {
  return (
    <>
      <BannerPopTopSection onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        if (onClickToggleModal) {
          onClickToggleModal();
        }
      }}>
        <CloseBtn src={IconXcircle} alt="Close Button Icon"/>
      </BannerPopTopSection>

      <BannerPopPage>
        <BannerPopFrame
          title="ORBRO's Digital Twin Video"
          src={i18n.language ==='ko' ? 'https://www.youtube.com/embed/Q1-FaEGAaHM?si=eWj0_UaQQ8qz_c1U' : 'https://www.youtube.com/embed/PL9YbiaYFUk?si=oT97AI_zbL0VrL_j'}
          frameBorder="0"
          allowFullScreen
        />
      </BannerPopPage>
      


    </>
  )
}

export default BannerPop;