import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    helmet: '| LoRa是什么？',
    desc: 'LoRa（Low Range Radio）是一种为低功耗和远程无线通信而设计的无线通信技术之一。该技术适用于传感器网络、工业自动化，尤其适用于物联网设备之间的通信。',
    keywords: 'LoRa、无线通信、物联网、通信技术、RTLS、实时位置跟踪、ORBRO、ConTech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'LoRa是什么？ | 位置追踪和数字孪生专业，ORBRO',
    ogUrl: 'https://orbro.io/cn/blog/what-is-LoRa',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkGw848hiaZwUj1NzOs30j6aqQAJyNCTeY0oQIeVJFOkeYuNI3kvZhnbc5uEfN7j4eLrviv8mEssXZn4lxH-dLhqXgRoLEtW86etie39T8Z4ZrsMxp-5A2G7W4VIP6BP_qFtyqDEJ_krIVKkxluM5vTPOKBMEyrGtQ_3ydm3DGKWHp7F-ShLBeXFtG1oET_EreJ_iYWOkT3CzAatE2_V77uE1qOcaLPuWCttx2d70Jt6u0a7tKI8nKCOheeIgrZqmaGNHLXtWSyJnPPkS083MmuPZv_AuP5JXZOe4h-xyzZcYCyo1E8vbbzRQZKjDcRj4CXwdfV6isxP_GMeoZXMvMMQR6ZL-CjdMHskmTwCTVRtYSQd-QRWtlRXn5bdxpcE5n2kgYENQIRBn73zqANbo6d7dbmHSgh3vqEzNP7yLk6mMQ-YNVO_04A1nNDlqhOaA9HQ7kkUtvDq1WsmkMAw2i169IEUISWz8pliXgKuRnPM_YOP8EWj0ewhPm6YtPrYizD5n9yo8sGI_B1E63PiHKz9hRNeRPSWKrkFPIlT-Y-adJ1giugNEpETW7dZOnr3u_FsbtzrPm6eOw5gQecQrBtDq14AN5olMMyI3_uGibjXoS3rutDYRvls1YlPMHv2iUBWhe9CmRSI9BsT-_tyscLg6cwWAhwfb9OdbWga3TI7dLo9b7s-8SFXm6s6Yqop9WgsB23-rOl_h4UAZHsjRu93uENXiNTD7YD-5VbvdSubD6Ffj3xkL3f9HdhilG3turAnuWLTcQPQ6f9yePOwuvoIrcmXntkKHwzoChR5WH4VoWk83o-UippkYQ3gzYCJJ8-4yd9JZg-TUA3-n9KHJvWyoaCjlaOTCkcBygN_c4WcWJgu3zui6gzBVZTv3oyuKzJzYYslQ89doq9Lh_T7yohz1vbGyDjOJ7gr-onVsc-yfw0_f3apHYvqfZKsSE_9DLZ6G9uzBBotKH-KPTnvEp3OA-2poPSP5Z-DsbLOymfPnvpMPs5ZsiCGh7Jvomyr5pxJcJL19u6HKf3979fiLresgN9QVAef0DmZTEYu76a10CjiW20Ne3C15KDO6Q2ICYOLpVyObPTZc6gGrvgED_Xr2Ul4We1Nc38VxTaLYoPMhzbg8SIe6AZ_UzExO4tZtDfX1hU-5kzIRBq2lXR1cKGXLvP8B0VUshx8iDhwWXOkUPuCyymV8Ng2j6JQyXZr0H2lyhO-b4WgS3aP5efeg_Ny64v_sUBQVG9wKL2FSxalXpQy5LHTrrbPV3akp2a7BeoMondBr7DVQnCTG8sc1oOJb-ZkAtOR05xmrGM2l4A0PlhelYg6RIyPfBvkGI5PV8lh3yPOUQVQCXBzqm_G6LYCRbqWdX5-3BlFEh4-fQpy6luoA1LossKR8VX0DXX8e9lcSJT17c2nXpV9w1mjyWuW8rYGBCeiiyulrgkobyIG_fSCd3TWmdr10IZU-_4msP81pWVBQ0iqdbEp4g-5cuufyYZ-hJo1dOOQGOsizdKKRg_-ndSZc_0nSCjjoEIoxoIl_wZN08371fZ_3FzYFTjFYkbcO9F=w1834-h1208',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'LoRa是什么？',
    date: '2024-01-15',
    tag1: '#LoRa',
    tag2: '#物联网',
    tag3: '#通信技术',
    tag4: '#LowRangeRadio',

    // <br/>
    text: [
        {
            head: 'LoRa是什么？',
            subHead: '',
            desc: 'LoRa（Low Range Radio）是一种为低功耗和远程无线通信而设计的无线通信技术。该技术适用于传感器网络、智能城市、农业自动化、工业自动化等各种应用领域，尤其适用于物联网（Internet of Things）设备之间的通信。该技术有效利用频段，提供长达数十公里的通信距离，并通过低功耗延长电池寿命。此外，LoRa还具有成本效益高、安全性强的特点，广泛应用于各种领域。',
            dot: ''
        },
        {
            head: 'LoRa的特点',
            subHead: '远程通信',
            desc: 'LoRa支持远程通信，提供数十公里的通信距离，主要用于农业地区或偏远地区的传感器数据收集或监控。',
            dot: ''
        },
        {
            head: '',
            subHead: '低功耗',
            desc: 'LoRa以低功耗通信为特点，可以在电池驱动的传感器设备等低功耗设备上长时间运行。这使得传感器网络的节点无需频繁更换或充电电池。',
            dot: ''
        },
        {
            head: '',
            subHead: '频段利用',
            desc: 'LoRa主要使用ISM（工业、科学、医学）频段，如868MHz（欧洲）和915MHz（美国）。这些频段是免费使用的，适用于低功耗设备。',
            dot: ''
        },
        {
            head: '',
            subHead: '低成本',
            desc: 'LoRa的实施相对成本较低。这使得在大规模建设IoT设备时也能以成本效益的方式利用。',
            dot: ''
        },
        {
            head: '',
            subHead: '星型拓扑',
            desc: 'LoRa网络主要采用星型拓扑，每个设备直接与网关通信。这使得网络扩展容易，设备之间的通信快速。',
            dot: ''
        },
        {
            head: '',
            subHead: '可扩展性和灵活性',
            desc: 'LoRa可以灵活应用于各种应用领域。此外，它还具有轻松添加新设备以扩展网络的特性。',
            dot: ''
        },
        {
            head: '',
            subHead: '加密和安全',
            desc: 'LoRa在数据传输过程中提供加密，增强安全性。此外，LoRa技术是开放标准开发的，因此多个制造商可以提供兼容的设备。',
            dot: ''
        },
        {
            head: 'LoRa的应用案例',
            subHead: '农业自动化',
            desc: 'LoRa用于构建农业领域的传感器网络，实时收集和分析作物状态、土壤条件等数据，以提高农业生产力。',
            dot: ''
        },
        {
            head: '',
            subHead: '智能城市',
            desc: 'LoRa为智能城市提供了各种传感器和设备之间高效通信，用于城市基础设施管理、环境监测、公共安全系统等，推动智能城市的发展。',
            dot: ''
        },
        {
            head: '',
            subHead: '工业自动化',
            desc: 'LoRa在工业自动化领域支持传感器和设备之间的通信，优化生产过程并进行监测。这提高了生产效率并降低了成本。',
            dot: ''
        },
        {
            head: '',
            subHead: '环境监测',
            desc: 'LoRa应用于自然环境监测，实时收集大气质量、水质、气候等数据，监测环境变化并采取相应措施。',
            dot: ''
        },
        {
            head: '',
            subHead: '智能建筑管理',
            desc: 'LoRa通过与建筑内各系统的通信，如能源使用、安全系统、空调控制等，有效管理并促进智能建筑的建设。',
            dot: ''
        },
        {
            head: 'LoRa技术的未来展望',
            subHead: '数据标准化和规模扩展',
            desc: 'LoRa通过数据标准化和规模扩展，预计将提高各制造商和服务提供商之间的互操作性。这将扩展LoRa生态系统并在全球IoT市场中加强其地位。',
            dot: ''
        },
        {
            head: '',
            subHead: '与边缘计算的结合',
            desc: 'LoRa通过与边缘计算结合，预计将支持实时数据处理和应用程序执行，为智能设备和系统提供更高级别的自动化和智能化。',
            dot: ''
        },
        {
            head: '',
            subHead: '加强安全性',
            desc: '预计LoRa将开发更强大的安全功能，增强设备之间通信和数据传输中的安全性。这将有助于安全传输和保护敏感信息。',
            dot: ''
        },
        {
            head: '',
            subHead: '与人工智能的整合',
            desc: 'LoRa与人工智能整合，通过增强数据分析和决策流程，预计将在预测分析、自动驾驶技术等多个领域提供创新性解决方案。',
            dot: ''
        }
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: '将UWB终端设备固定在工人或设备上，实时支持位置跟踪的产品。',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/cn/enterprise/logistics/asset'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/cn/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/cn/enterprise/manufacturing/asset'
            }
        ]

    }

}
