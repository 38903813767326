import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4';

export default {

    desc: '智能港口利用数字孪生、RTLS 和人工智能，全面监控和管理港口内所有活动的综合解决方案。通过这种方式，可以实时监测和分析港口内所有对象和过程。',
    keywords: '智能港口、数字孪生、数字化转型、digital twin、信标、rtls、uwb、ble、ai 摄像头、Orbro、Kongtek',

    ogSite: '智能港口解决方案 | 位置追踪和数字孪生、Orbro',
    ogUrl: 'https://orbro.io/cn/blog/smart-port',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmtMlMpuGaSkdrwhUm9cmk57NhnjuSqUyvAfLNAvWgGu0MM4L2R6eFvjBYvTVkkIk2f8QbkrMlttEBDfzi4pLKN9N-dhDu_JiYkV4BvDu2CLF5JFWrrMJgKJAPWObqYGbQYtBBMoquCCAYx_k8njELRIPt4_PKkk6KdhaLKf78OatyVEbgXKhqBqdCGxbhGw3jTDiqdbaImqLAMR3Wdos-IWpYXZYClx-DKC1P9dB3GLBP0ixLsNn9rpaH91rcH8RLcRUDTYzamMx1BMmNl89ZY8vTfts7eomL8NXjR9nEZZ5K-MkBjZT4KJ4o4iUQRE8CZDBT8xcueZCDOkMPkM1K3XgnM7okDR8U8xf_zJc0w6Ng2bEtDNjtB1h-vn2NNuBWuiIXgDTJG-ZlSbIFXuT1ySTt8d8L9s9vZffcfMLrKFRnY8Obtfn-WVUDAaXA68xwLtGJYP-NMEMyCqtdlswxY1nzpPdBpNTELVjP4NGkFiHk3Vb0xHr22sMgIzsoWUBuuZRiiz_Oadq3DWG64cWpzTeUzRxxnVc7lh1jqQCre0y1-PNTz8FmoHgjoNYQIGR8teD7k3s2g9v6_39LkKVnEp3LZg4K7fK_st9aGnXxXHgT38G1P_CyZ2NCfDR2h7757hijJWyc01v1e5DKey7CKzS5XKyz6VQitW_E20TWpbzbIO-rYeEzjZwRCbjcBhUVfQiSz2QcMbLTb3acfrCop4bSDap1c2tjNFn6BURfcFhtqYymgRGU1UCQ4ZuD_RO552LTgZgcyVENTq2v3A1n1qzhsNoSFHD5hJ2RA2ww4so8MDKBF2R5J6mer8Su6fEutD5F1ZUgGwSU4glTVu0jYCkS97SgUsjSJ-hWu10P40b1PbPIYjir7h7BTyDphRhnPDkigpYw4x2NeLO1ilNqDXxOLoRxOz3XM0ubgAHxvtFSl6urWE-MXOjXwCuu_8dCODgOBpzlHm_HA1FIREfuLH-sVZ9VKFvyFZZyxx4x12i7Gf8wfTFh20JL2uxFSplfv-8nvkqRaVPYrF5RGZ3jSwtPJP9OYAMhxVpgB1unGLMYL2-a0_s-q8lfbijvufnfa1O6P0UiqgkFwZ0HKLgLXHOD3DSaHMMSJkB9oSwFNFJgoIAciFzzPWPCw4Hs1wMO5q4ixJ1Jbvyu53pWL_tznPM5m5mIj1D_i2RMKAoNFDl4EMFRzXjMKHUWjTc2bt9knvzg3CUTzs7rDarMVqv2ELwEc2BJE5joJfnY7APOre8u84naPSATFCFeKvGxt0v6mruuozNIF4IZMC2XhhGDHbQasJe1Vfab9-e18wVVD6fyEM0jLw50EPpOh5rTUJE02ppq_cHwPe2ZUQ10K7oC0LZoy05ga5pL6tfHnt65GWSUg6ehpUe7RkFH0vOMvEPWln4Zl3XiUjnmVQ_SAN-gWxtOswmhrO4QavIJmTUrR9UmDxFLOAjeUDHPsYc0XYFpmyLYyiAuDr095MknMbE1K0WjCf9kfZy_O14qr-6UpIl7J8vTNKxXQX1RKB9t7N_rO0S0Q1UgEj02peJ30KvmNifvU3gDY1huNYAcxzOUSC0kfxpI=w2110-h1171',
    ogType: '网站',

    title: '智能港口解决方案',
    date: '2024-01-25',
    tag1: '#智能港口',
    tag2: '#RTLS',
    tag3: '#位置追踪',
    tag4: '#数字孪生',

    // <br/>
    text: [
        {
            head: '什么是智能港口？',
            subHead: '',
            desc: '智能港口利用数字孪生、RTLS 和人工智能，全面监控和管理港口内所有活动的综合解决方案。通过这种方式，可以实时监测和分析港口内所有对象和过程。收集和更新关键数据，如集装箱位置、状态和运输信息，支持港口管理者迅速做出决策。<br/>本博客将重点探讨智能港口解决方案的概念、优势以及数字孪生和RTLS技术。',
            dot: ''
        },
        {
            head: '智能港口技术',
            subHead: '数字孪生',
            desc: '数字孪生是生成精确复制物理系统、结构或流程的虚拟模型的技术。主要用于生成港口设施和货物运输过程的数字复制品，并进行实时监控和优化。通过数字孪生模型表示集装箱位置、起重机操作、车辆移动路径等，预测问题并实现高效运营。',
            dot: ''
        },
        {
            head: '',
            subHead: 'RTLS（实时定位系统）',
            desc: 'RTLS 是实时定位系统的缩写，是一种追踪物体或移动物体实时位置的技术。主要利用 UWB、RFID 标签、摄像头、信标技术等，精确收集和管理位置信息。通过这种方式，可以追踪集装箱、卡车、船舶的位置，为货物运输路径的优化、货物状态监控和提高安全性做出贡献。',
            dot: ''
        },
        {
            head: '',
            subHead: '人工智能（AI）',
            desc: 'AI 技术使用 CCTV 摄像头识别港口内的异常标志或危险情况。通过收集、分析各种港口数据并开发预测模型，可以提高物流和运输效率。此外，使用人工智能可以迅速检测和解决港口运营中的问题。',
            dot: ''
        },
        {
            head: '',
            subHead: '物联网（IoT）',
            desc: '利用传感器和通信技术连接港口内各种系统和设备。还可以了解现场环境的各个方面，如温度和湿度，并收集管理所需的数据。通过这种方式，可以实时优化港口运营。',
            dot: ''
        },
        {
            video: video,
            link: '/cn/enterprise/manufacturing/process',
            head: "智能港口的功能",
            subHead: "实时库存管理",
            desc: "智能港口利用传感器技术和无线通信技术可以实时追踪货物位置。通过这种方式，港口管理者可以随时了解特定货物的位置，有效管理物流过程。货物跟踪可以实现准确的预计到达时间（ETA）计划，提高物流计划的效率和准确性。",
            dot: ""
        },
        {
            head: "",
            subHead: "优化运营利用数字孪生",
            desc: "数字孪生是将港口设施和运输工具数字化建模并实时管理的技术。通过实时监视港口设施的状态，并通过预测分析优化维护计划，可以实现对物流和运输过程的高效运营。利用数字孪生进行运营优化可以延长港口设施的寿命，并提高设施管理的效率。",
            dot: ""
        },
        {
            head: "",
            subHead: "利用自动驾驶车辆和机器人",
            desc: "智能港口通过自动驾驶车辆和机器人自动化货物运输和特定工作。自动驾驶车辆可以高效地移动货物，而机器人可以自动执行必要的工作，减少劳动力并提高生产力。通过这种方式，港口内的货物移动和工作流程实现自动化，实现更高的效率。",
            dot: ""
        },
        {
            head: "",
            subHead: "降低成本和有效的资源管理",
            desc: "智能港口通过自动化和优化物流和运输过程实现成本削减和有效的资源管理。引入自动驾驶车辆和机器人可以减少人力成本，同时提高生产力。此外，通过数字孪生进行设施管理的优化可以降低维护成本，提高能源效率。",
            dot: ""
        },
        {
            head: "",
            subHead: "实时安全和安全监控",
            desc: "利用RTLS和CCTV等技术，智能港口可以实时监控港口内运输工具和人员的位置，并检测异常迹象。通过这种方式，可以迅速应对非法访问或安全问题，增强港口的安全性和安全性。实时监视使在事故发生时能够迅速作出反应，提高港口设施和人员的安全性。",
            dot: ""
        },
        {
            head: "",
            subHead: "数据驱动决策",
            desc: "利用大数据和人工智能技术收集、分析港口产生的各种数据，构建预测模型。通过这种方式，港口管理者可以实时应对环境变化，并通过数据驱动的决策最大化运营效率。此外，数据分析可以确定物流过程的瓶颈，提高整体生产力。",
            dot: ""
        },
        {
            head: "",
            subHead: "环境友好运营和能源效率",
            desc: "智能港口在货物运输和设施管理方面追求环境友好运营。通过优化物流和运输过程，利用环保的运输手段最小化燃料消耗，减少碳排放。此外，通过数字孪生和传感器进行设施管理，优化能源消耗，提高港口的能源效率。",
            dot: ""
        },
        {
            head: '智能港口展望',
            subHead: '',
            desc: '预计智能港口的前景将在未来取得更多创新性的发展。自动驾驶技术的扩展预计将通过自动化和效率化物流和运输过程来提高生产力。通过数字孪生和大数据分析优化运营将有效管理港口设施的维护和运营计划。对环境友好运营和能源效率的强调强调了通过引入环保技术最小化能源消耗，实现可持续港口运营。此外，实时安全和安全监控系统的进化将提高港口的安全性，并增强应对非法访问的能力。智能港口将为物流行业和国际贸易提供更多便利性和效率性，并预计将确立其作为数字化和创新中心的地位。',
            dot: ''
        }
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: '通过安装UWB终端对工人和设备进行实时位置跟踪的产品。',
                img: productImg.product.workerTracking.thumbnail
            },
            {
    
            }
        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/cn/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/cn/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/cn/nterprise/construction/equipment'
            }
        ]
    }
    
}
