import { UwbImg1, UwbImg1Mobile, UwbImg1Tablet, UwbImg2, UwbImg2Mobile, UwbImg2Tablet, UwbImg3, UwbImg3Mobile, UwbImg3Tablet } from "@assets/images/technology/uwb";
import {
  StyledTechnologyGroup, StyledTechnologyImg,
  StyledTechnologyItem, StyledTechnologyItemDescription, StyledTechnologyItemImg, StyledTechnologyItems,
  StyledTechnologyItemTitle, StyledTechnologyText, StyledTechnologyTitle
} from "@pages/Enterprise/Technology/Technology.style";
import { CommonState } from "@redux/reducers/commonReducer";
import React from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";

import uwb from "@src/lang/ko/technology/uwb";
import { VideoBox, VideoPlay } from "@pages/Enterprise/Solution/components/Video/Video.style";
import video from "@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4";
import { useLocation } from "react-router-dom";
import { StyledParagraph } from "@components/Text";
import { Spacing } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";


interface layoutImg {
  desktop: string,
  tablet: string,
  mobile: string
}

const img1: layoutImg = {
  desktop: UwbImg1,
  tablet: UwbImg1Tablet,
  mobile: UwbImg1Mobile
}

const img2: layoutImg = {
  desktop: UwbImg2,
  tablet: UwbImg2Tablet,
  mobile: UwbImg2Mobile
}

const img3: layoutImg = {
  desktop: UwbImg3,
  tablet: UwbImg3Tablet,
  mobile: UwbImg3Mobile
}



const UwbContent = () => {


  // Redux
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const location = useLocation();

  const getLang = () => {
    if (location.pathname.includes('jp')) {
      return '/jp';
    } else if (location.pathname.includes('cn/')) {
      return '/cn';
    } else if (location.pathname.includes('en/')) {
      return '/en';
    } else {
      return '';
    }
  }


  return (
    <>
      <StyledTechnologyGroup>
        <DisplayH2 size="small"> <Trans i18nKey={"uwb.tech.title"} /></DisplayH2>
        <Spacing pixel="16" />
        <StyledTechnologyText size="medium">
          <p>
            <Trans i18nKey={"uwb.tech.text"} />
          </p>
        </StyledTechnologyText>
        <DivAllCenter>
          <StyledParagraph size="medium">
            <Trans i18nKey={"simple.uwbVideoDesc"} />
          </StyledParagraph>
          <a href={`${getLang()}/enterprise/medical/patient`} target="_blank" rel="noopener noreferrer">
            <Spacing pixel="24" />
            <VideoBox>
              <VideoPlay autoPlay loop muted playsInline>
                <source src={video} type="video/mp4" />
              </VideoPlay>
            </VideoBox>
          </a>
        </DivAllCenter>
      </StyledTechnologyGroup>

      <StyledTechnologyGroup>
        <DisplayH2 size="small"><Trans i18nKey={"uwb.selection.title"} /></DisplayH2>
        <Spacing pixel="16" />
        <StyledTechnologyText size="medium">
          <p>
            <Trans i18nKey={"uwb.selection.text"} />
          </p>
        </StyledTechnologyText>
      </StyledTechnologyGroup>

      <StyledTechnologyGroup>
        <DisplayH2 size="small"><Trans i18nKey={"uwb.feature.title"} /></DisplayH2>
        <Spacing pixel="16" />
        <StyledTechnologyText size="medium">
          <p>
            <Trans i18nKey={"uwb.selection.text"} />
          </p>
        </StyledTechnologyText>
      </StyledTechnologyGroup>

      <StyledTechnologyGroup>
        <DisplayH2 size="small"><Trans i18nKey={"uwb.howItWorks.title"} /></DisplayH2>
        <Spacing pixel="16" />
        <StyledTechnologyText size="medium">
          <p>
            <Trans i18nKey={"uwb.howItWorks.text"} />
          </p>
        </StyledTechnologyText>

        <StyledTechnologyItems>
          <StyledTechnologyItem desktop={6} tablet={4} mobile={4}>

            <StyledTechnologyItemImg src={img1[layoutType]} alt="UWB" />
            <StyledTechnologyItemTitle size="xsmall">{uwb.howItWorks.items[0].title}</StyledTechnologyItemTitle>
            <StyledTechnologyItemDescription size="medium">
              <p>
                <Trans i18nKey={`uwb.howItWorks.items.${0}.description`} />
              </p>
            </StyledTechnologyItemDescription>

          </StyledTechnologyItem>

          <StyledTechnologyItem desktop={6} tablet={4} mobile={4}>
            <div>
              <StyledTechnologyItemImg src={img2[layoutType]} alt="UWB" />
              <StyledTechnologyItemTitle size="xsmall">{uwb.howItWorks.items[1].title}</StyledTechnologyItemTitle>
              <StyledTechnologyItemDescription size="medium">
                <p>
                  <Trans i18nKey={`uwb.howItWorks.items.${1}.description`} />
                </p>
              </StyledTechnologyItemDescription>
            </div>
          </StyledTechnologyItem>
        </StyledTechnologyItems>
      </StyledTechnologyGroup>

      <StyledTechnologyGroup>
        <DisplayH2 size="small"><Trans i18nKey={"uwb.accuracy.title"} /></DisplayH2>
        <Spacing pixel="16" />
        <StyledTechnologyText size="medium">
          <p>
            <Trans i18nKey={"uwb.accuracy.text"} />
          </p>
        </StyledTechnologyText>
      </StyledTechnologyGroup>

      <StyledTechnologyGroup>
        <DisplayH2 size="small"><Trans i18nKey={"uwb.range.title"} /></DisplayH2>
        <Spacing pixel="16" />
        <StyledTechnologyText size="medium">
          <p>
            <Trans i18nKey={"uwb.range.text"} />
          </p>
        </StyledTechnologyText>
      </StyledTechnologyGroup>

      <StyledTechnologyGroup>
        <DisplayH2 size="small"> <Trans i18nKey={"uwb.difference.title"} /></DisplayH2>
        <Spacing pixel="16" />
        <StyledTechnologyText size="medium">
          <p>
            <Trans i18nKey={"uwb.difference.text"} />
          </p>
        </StyledTechnologyText>

        <Spacing pixel="32" />
        <StyledTechnologyImg
          src={img3[layoutType]}
          alt="UWB"
        />

        <StyledTechnologyItems>
          <StyledTechnologyItem desktop={6} tablet={4} mobile={4}>
            <div>
              <StyledTechnologyItemTitle size="xsmall">
                <Trans i18nKey={`uwb.difference.items.${0}.title`} />
              </StyledTechnologyItemTitle>
              <StyledTechnologyItemDescription size="medium">
                <Trans i18nKey={`uwb.difference.items.${0}.description`} />
              </StyledTechnologyItemDescription>
            </div>
          </StyledTechnologyItem>

          <StyledTechnologyItem desktop={6} tablet={4} mobile={4}>
            <div>
              <StyledTechnologyItemTitle size="xsmall">
                <Trans i18nKey={`uwb.difference.items.${1}.title`} />
              </StyledTechnologyItemTitle>
              <StyledTechnologyItemDescription size="medium">
                <Trans i18nKey={`uwb.difference.items.${1}.description`} />
              </StyledTechnologyItemDescription>
            </div>
          </StyledTechnologyItem>
        </StyledTechnologyItems>
      </StyledTechnologyGroup>
    </>
  );
};

export default UwbContent;