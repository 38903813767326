export default {
  titleBanner: {
    subtitle: 'TDoA | Time Difference of Arrival',
    title: 'TDoA 위치 추적 기술',
    description: '*TDoA (Time Difference of Arrival) 기술은 위치 추적 기술 중 하나로, 발신기로부터 전파가 도착하는 시간 차이를 측정하여 수신기의 위치를 계산하는 방식입니다. <br/> 여러 개의 수신기가 하나의 발신기에서 전파를 수신하면, 수신기들 사이의 전파 도착 시간 차이를 측정하여 발신기의 위치를 계산할 수 있습니다. <br/> 이 때, 수신기들은 정확한 시간을 동기화하여 사용하며, 시간 동기화가 정확할수록 위치 측정 정확도가 높아집니다.'
  },
  definition: {
    title: 'TDoA 기술이란?',
    text: `TDoA는 무선 신호의 도착 시간 차이를 결정하는 위치 결정 방법론입니다. TDoA는 실시간 위치 시스템(RTLS)에서 사용되며, 인적 자원이나 중요 자산에 부착된 추적 태그와 같은 추적 대상의 위치를 실시간으로 정확하게 계산합니다. <br/><br/> 예를 들어, Orbro의 실시간 위치 추적 시스템에서 TDoA는 UWB 또는 칩(RTLS) RTLS 앵커에서 추적 태그로부터 수집한 위치 데이터를 활용하여 추적 대상의 실시간 위치를 정확하게 계산합니다. 첨부된 도표에서 볼 수 있듯이, RTLS 태그(T)는 일정한 간격으로 RF 신호, 즉 위치 블링크를 지속적으로 발신합니다. 송신 태그의 통신 범위 내에 있는 여러 RTLS 앵커는 이 위치 블링크를 수신하고 정확한 도착 시간(ToA)을 타임스탬프로 기록합니다. 그런 다음 앵커는 이 타임스탬프가 포함된 위치 데이터를 Orbro Edge 위치 엔진 소프트웨어에 전달합니다. Orbro Edge는 이러한 방식으로 수신 위치 블링크의 도착 시간 차이(예: ToA1-ToA2)를 분석하여 TDoA를 계산합니다.`
  },
  sync: {
    title: '가상 앵커 동기화',
    text: `올바르게 작동하려면 고정된 앵커들은 정확하게 동기화 된 Clock에서 실행될 수 있도록 정확하게 동기화되어야 합니다. Orbro Edge의 실시간 위치 추적 시스템은 무선통신 기반의 시간 동기화를 통해 이를 달성합니다. 계산된 TDoA는 초점선(hyperbolas)으로 표시되며 가능한 태그 위치를 나타냅니다. 한 지점에서 교차하는 세 개의 관련 초점선은 태그의 위치를 설명합니다. 신뢰할 수 있는 위치 추정을 위해 최소 4개의 앵커를 사용하는 것이 권장됩니다. <br/><br/> 그 결과로 나오는 위치 좌표는 지도상에서 태그의 위치를 시각화하거나, 구체적인 어플리케이션에 따라 다른 용도로 활용될 수 있습니다.`
  },
  advantage: {
    title: 'TDoA의 주요 장점',
    items: {
      accuracy: {
        title: '높은 정확도',
        description: 'TDoA는 RSSI 기반 위치 측정과 같은 기존의 전통적인 방법보다 높은 정확도의 위치 결과를 보장합니다.'
      },
      wait: {
        title: '낮은 대기 시간',
        description: 'TDoA를 사용하면, RTLS는 수천 개의 동시 수신 위치 블링크를 처리할 수 있어 낮은 지연 시간과 높은 성능 신뢰성을 보장합니다.'
      },
      expansion: {
        title: '엔터프라이즈 확장성',
        description: 'TDoA 기반 계산은 기업 요구 사항을 충족할 수 있는 확장 가능한 RTLS 배포를 가능하게 합니다.'
      }
    }
  },
}