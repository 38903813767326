import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4';

export default {

    desc: 'スマート造船所とは、AI（人工知能）、IoT（モノのインターネット）、RTLS（リアルタイム位置追跡）、デジタルツインなどの先端技術を活用して、より効率的で知能化された生産システムを構築することを指します。',
    keywords: 'スマート造船所、AI、IoT、RTLS、デジタルツイン、ビーコン、UWB、BLE、Orbro、KONTECH',

    ogSite: 'スマート造船所ソリューション',
    ogUrl: 'https://orbro.io/jp/blog/smart-shipyard',
    ogImg: 'https://lh3.google.com/u/0/d/1WdXSNBhEft64iXMMY189UfAb4GJnSnFp=w2000-h7622-iv1',
    ogType: 'website',

    title: 'スマート造船所ソリューション',
    date: '2024-05-09',
    tag1: '#スマート造船所',
    tag2: '#造船所',
    tag3: '#港産業',
    tag4: '#デジタルツイン',

    // <br/>
    text: [
        {
            head: 'スマート造船所とは？',
            subHead: '',
            desc: 'スマート造船所とは、AI（人工知能）、IoT（モノのインターネット）、RTLS（リアルタイム位置追跡）、デジタルツインなどの先端技術を活用して、より効率的で知能化された生産システムを構築することを指します。スマート造船所は、生産工程のデジタル化と自動化を通じて作業者の安全を強化し、生産性を向上させると同時に、エネルギー効率を改善して持続可能な海洋産業を構築することを目指しています。',
            dot: ''
        },
        {
            head: '主要技術',
            subHead: 'リアルタイム位置追跡（RTLS）',
            desc: '作業者と資産のリアルタイムの位置を追跡し、生産プロセスの透明性を高め、効率を最大化します。この技術は、秒間更新される正確な位置情報を提供し、作業スケジュールを最適化し、安全性を向上させます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'デジタルツイン',
            desc: '造船所のすべての要素を現実世界とデジタルでつなぎ、仮想モデルを作成します。これにより、実際のプロセスをシミュレートし、最適化することができ、設計段階から生産、保守までのすべての段階で生産性と効率性を向上させます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'ビッグデータと人工知能（AI）の活用',
            desc: '造船所で生成される大量のデータを収集し、分析して生産プロセスの最適化と予測メンテナンスを実現します。AIによりデータのパターンを発見し、それを基に予測と意思決定をサポートし、生産性を向上させ、コストを削減します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'モノのインターネット（IoT）技術の導入',
            desc: '船舶および造船所内のさまざまな機器とセンサーを接続し、リアルタイムのデータを収集し、モニタリングして生産プロセスを最適化します。これにより、機器の状態をリアルタイムで把握し、予防保守を行い、生産性を向上させます。',
            dot: ''
        },
        {
            videoDesc : '(例: Orbroスマート製造施設)',
            video : video,
            link : '/jp/enterprise/manufacturing/process',
            head: '主な機能',
            subHead: 'リアルタイム作業者位置追跡',
            desc: '作業者のリアルタイムの位置を追跡し、作業スケジュールを最適化し、作業環境の安全性を維持します。作業者の位置をリアルタイムでモニタリングすることで、事前に危険な状況を防止し、作業効率を最大化します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'リアルタイム資産管理',
            desc: '造船所内の資産のリアルタイムの位置と状態をモニタリングして、在庫管理と作業効率を向上させます。これにより、在庫不足や過剰購入などの問題を防止し、資産の利用率を最適化します。',
            dot: ''
        },
        {
            head: '',
            subHead: '環境管理',
            desc: '環境要素をモニタリングして造船所内の環境汚染を防止し、生産プロセスの持続可能性を維持します。大気、水質、騒音などの環境要因をリアルタイムでモニタリングし、対策を講じて生産環境を改善します。',
            dot: ''
        },
        {
            head: '',
            subHead: '危険区域管理',
            desc: '造船所内の危険な地域を識別し、モニタリングして、作業者の安全を確保し、作業中に発生する可能性のある事故を最小限に抑えます。危険な地域を特定し、作業者に警告を与えて作業環境を安全に維持します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'エネルギー効率管理',
            desc: 'スマートエネルギーシステムを導入して港のエネルギー使用量を最適化し、環境にやさしい運用を実現します。',
            dot: ''
        },
        {
            head: '将来の展望',
            subHead: '人工知能（AI）と自動航行技術の拡大',
            desc: '将来、AI技術がさらに進化し、船舶の自動航行技術が普及すると予想されます。これにより、造船所は運用効率を向上し、生産プロセスでの労働コストを削減することができるでしょう。',
            dot: ''
        },
        {
            head: '',
            subHead: 'モノのインターネット（IoT）技術の適用',
            desc: 'IoT技術は、港の運用と管理にますます重要な役割を果たすと予想されます。船舶および他の施設に取り付けられたセンサーを介してデータを収集し、分析することで、港の運用効率を高めることができます。',
            dot: ''
        },
        {
            head: '',
            subHead: '環境にやさしい技術の強調',
            desc: '持続可能な海洋産業を実現するために、エネルギー効率を向上させ、環境保護に取り組む技術が重視されると予想されます。これらの取り組みは、海洋生態系の保全と並行して、港の運用の持続可能性を高めるでしょう。',
            dot: ''
        },
        {
            head: '',
            subHead: 'グローバル市場の成長',
            desc: '世界の港湾および造船業は、引き続き成長すると予測されています。特に、新しい港湾建設や拡張プロジェクトの増加とともに、アジア太平洋地域での成長が期待され、これは韓国のスマート造船所産業にもプラスの影響を与えるでしょう。',
            dot: ''
        },
        {
            head: '結論',
            subHead: '',
            desc: 'スマート造船所は、将来の持続可能な海洋産業にとって不可欠な要素となっています。先端技術と革新的なアプローチの採用により、生産性、効率性、安全性を向上させ、同時に環境にやさしい生産方法を実現することができます。これにより、造船業は急速に発展し、将来にわたる持続可能な成長を実現するでしょう。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB端末を作業者や機器に取り付けて、リアルタイムの位置追跡を支援する製品です。',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/jp/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/jp/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/jp/enterprise/construction/equipment'
            }
        ]


    }


}
