import { IconBell, IconBinocularsGray, IconChainLink, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import mainVideo from "@src/assets/images/solution/constructionSafety/video/rtlsVideo.mp4";
import subVideo1 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo4.mp4";
import subVideo5 from "@src/assets/images/solution/constructionSafety/video/sub/rtlsVideo5.mp4";

export default {
    banner: {
      title: 'Worker Safety Management',
      desc: `Worker safety is paramount above all else. Failing to ensure worker safety in high-risk environments can lead to productivity issues and even legal liabilities. Moreover, maintaining a safe construction site plays a crucial role in establishing a company's image and gaining the trust of stakeholders.`,
    },
    video: {
      title: 'Ensuring Construction Site Worker Safety',
      desc: 'Real-Time Location System (RTLS) technology provides real-time information on the locations of construction site workers. Administrators can detect potential hazards in advance and respond quickly to issues. It also supports compliance with<strong> safety regulations</strong> and builds trust between workers and management.',
      play: mainVideo,
    },
    func: [
      {
        title: 'Safety Equipment Verification',
        desc: 'Real-time detection of whether workers are wearing safety equipment, such as helmets, helps prevent accidents during work and maintain a safe working environment. Strengthening site safety management enhances organizational safety and reliability.',
        sub1: 'High Accuracy',
        icon1: IconRulerGray,
        sub2: 'Real-time Status Monitoring',
        icon2: IconClockGray,
        video: subVideo1,
      },
      {
        title: 'Real-time Worker Location Tracking',
        desc: `Real-time tracking of workers' locations allows for monitoring of safety conditions and enables quick response in case of approaching hazardous areas or emergencies. This helps prevent potential risks in advance.`,
        sub1: 'Real-time Location Tracking',
        icon1: IconBinocularsGray,
        sub2: 'Fast Data Integration',
        icon2: IconTvocGray,
        video: subVideo2,
      },
      {
        title: 'Real-time Heavy Equipment Location Tracking',
        desc: 'Safety is not only important for humans but also for heavy equipment with potential accident risks. Real-time tracking of heavy equipment locations enables identification of cases where workers are in close proximity or taking incorrect routes, preventing accidents.',
        sub1: 'Real-time Location Tracking',
        icon1: IconBinocularsGray,
        sub2: 'High Accuracy',
        icon2: IconRulerGray,
        video: subVideo3,
      },
      {
        title: 'Worker Path Tracking',
        desc: 'Warnings can be sent to managers if there are hazardous equipment or materials along the paths workers are heading towards. This enables quick response or rescue operations.',
        sub1: 'Provision of Statistical Data',
        icon1: IconChartBarGray,
        sub2: 'Data Cloud Storage',
        icon2: IconCloudCheckGray,
        video: subVideo4,
      },
      {
        title: 'Safety Zone Setup',
        desc: 'Pre-setting areas that should not be accessed or areas that require caution allows for warning workers passing by or notifying managers. This helps prevent accidents.',
        sub1: 'Free-range Setup',
        icon1: IconZoneGray,
        sub2: 'Multiple Zone Setup',
        icon2: IconTagGray,
        video: subVideo5,
      },
    ],
    subfunc: {
      title: 'Various Features',
      desc: 'Ensuring worker safety enhances efficiency and reduces losses from accidents. ORBRO has designed optimal features to protect the safety of construction site workers. The system is built to appropriately respond to safety accidents that may occur, including worker proximity to hazardous areas, accidents, and potential risks associated with heavy equipment.',
      data: [
        {
          icon: IconChartRadius,
          title: 'Reports and Analysis',
          desc: 'Generate reports and analytical materials based on field data.',
        },
        {
          icon: IconBell,
          title: 'Real-time Alarm Function',
          desc: 'Send alarms in case of abnormal situations for immediate response.',
        },
        {
          icon: IconRoute,
          title: 'Path History Inquiry',
          desc: 'Inquire about the paths taken by workers on the field and convert them into data.',
        },
        {
          icon: IconDashboard,
          title: 'Status Monitoring',
          desc: 'Monitor the current movements of workers and their flow.',
        },
        {
          icon: IconWarning,
          title: 'Deviation Detection',
          desc: `Real-time tracking of workers' movements to detect deviations or approaching restricted areas.`,
        },
        {
          icon: IconSearch,
          title: 'Path Pattern Analysis',
          desc: 'Understand how workers operate to improve work efficiency.',
        },
        {
          icon: IconChainLink,
          title: 'Data Integration',
          desc: 'Efficiently utilize systems such as RFID, IoT, security, and video through integration.',
        },
        {
          icon: IconSecurity,
          title: 'Data Security',
          desc: 'Maintain high-level data security to provide a reliable solution.',
        },
      ],
    },
    system: {
      title: 'System Configuration',
      img: rtlsSystem,
    },
    productTitle: 'Product Configuration',
    product: [
      {
        tag: 'workerPackage',
      },
      {
        tag: 'assetPackage',
      },
    ],
    contents: [
      {
        data: 'constructionAsset',
      },
      {
        data: 'constructionEquipment',
      },
      {
        data: 'blogRTLS',
      },
      {
        data: 'blogMedical',
      },
    ],
  }
  