import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconInfo, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo4.mp4";

export default {
    banner: {
      title: 'リアルタイム在庫管理',
      desc: '製造施設では、原材料や半製品など、多くの在庫が保管されています。これらの在庫を適切に管理することは、時間的および経済的な面で非常に重要です。つまり、製造業の効率性に影響を与える重要な要素です。',
    },
    video: {
      title: 'より正確かつ迅速な在庫管理方法',
      desc: 'リアルタイム位置測位（RTLS）技術は、資産の位置情報をリアルタイムで提供します。資産の位置を特定するのにほとんど時間がかかりません。<br/>さらに、数量管理も可能であり、RTLSを使用することで以前とは異なるレベルの生産性を実現できます。',
      play: video,
    },
    func: [
      {
        title: '在庫測位',
        desc: '在庫の位置をリアルタイムで測位し、在庫の移動や回転を非常に正確かつ効率的に管理します。また、生産計画や予測にも有益なデータを提供します。',
        sub1: '高い精度の提供',
        icon1: IconRulerGray,
        sub2: '迅速なデータ統合',
        icon2: IconShareGray,
        video: subVideo1,
      },
      {
        title: '損失防止',
        desc: '損失や盗難を素早く検出し、対策を講じることができます。また、在庫が正確な位置に保管され、正確なタイミングで使用されるため、在庫の損失を最小限に抑えることができます。',
        sub1: 'リアルタイムの状況把握',
        icon1: IconClockGray,
        sub2: '高い精度の提供',
        icon2: IconRulerGray,
        video: subVideo2,
      },
      {
        title: '回転率の最適化',
        desc: '在庫を効果的に配置し、在庫の回転期間を短縮し、古い在庫を優先的に使用することで、回転率を向上させることができます。これにより、在庫保有コストを削減し、資金効率を向上させることができます。',
        sub1: 'リアルタイム位置測位',
        icon1: IconBinocularsGray,
        sub2: '統計データの提供',
        icon2: IconChartBarGray,
        video: subVideo3,
      },
      {
        title: '正確な出荷',
        desc: '在庫の位置を素早く確認し、注文に必要な製品を正確な位置から適切なタイミングで抽出し、出荷時の製品の正確性を保証できます。これにより、注文処理時間を短縮し、エラーを最小限に抑えて顧客サービスの品質を向上させます。',
        sub1: 'リアルタイムの状況把握',
        icon1: IconClockGray,
        sub2: '高い精度の提供',
        icon2: IconRulerGray,
        video: subVideo4,
      },
    ],
    subfunc: {
      title: 'さまざまな機能',
      desc: '正確な資産管理は、無駄な時間と費用を削減し、生産性を向上させます。ORBROは、最適な製造環境を構築するためにさまざまな機能を提供します。',
      data: [
        {
          icon: IconBinocularsGray,
          title: '在庫予測',
          desc: '保有コストを最小限に抑え、在庫供給の迅速性と効率性を向上させます。',
        },
        {
          icon: IconBell,
          title: 'リアルタイムアラート機能',
          desc: '在庫に異常な状況が発生した場合、アラートを送信して即座に対応できます。',
        },
        {
          icon: IconRoute,
          title: 'ルートトラッキング',
          desc: '在庫の移動経路を測位し、データ化することができます。',
        },
        {
          icon: IconDashboard,
          title: '状況把握',
          desc: '特定の在庫の現在の位置を把握します。',
        },
        {
          icon: IconWarning,
          title: '逸脱検知',
          desc: '在庫が現場から逸脱したり、間違った場所に移動したりすることを検知します。',
        },
        {
          icon: IconInfo,
          title: 'リスク管理',
          desc: '在庫が適切な場所に安全に保管および管理されることを保証します。',
        },
        {
          icon: IconChainLink,
          title: 'データ連携',
          desc: 'RFID、IoT、セキュリティ、ビデオなどのシステムと連携し、効率的に活用できます。',
        },
        {
          icon: IconSecurity,
          title: 'データセキュリティ',
          desc: '高いレベルのデータセキュリティを維持し、信頼性の高いソリューションを提供します。',
        },
      ],
    },
    system: {
      title: 'システム構成',
      img: rtlsSystem,
    },
    productTitle: '製品構成',
    product: [
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'manufacturingProcess',
      },
      {
        data: 'logisticsAsset',
      },
      {
        data: 'blogOffice',
      },
      {
        data: 'blogRTLS',
      },
    ],
  }
  