import gis from "@src/assets/images/digitaltwin/video/gis.mp4";
import weather from "@src/assets/images/digitaltwin/video/weather.mp4";
import buildings from "@src/assets/images/digitaltwin/video/buildings.mp4";
import tracking from "@src/assets/images/digitaltwin/video/tracking.mp4";
import traffic from "@src/assets/images/digitaltwin/video/traffic.mp4";
import airplane from "@src/assets/images/digitaltwin/video/airplane.mp4";
import cctv from "@src/assets/images/digitaltwin/video/cctv.mp4";

import video from "@src/assets/images/digitaltwin/video/traffic.mp4";

import {
    Icon3d3d, Icon3dCycle, Icon3dLink, Icon3dLocation, Icon3dMap,
    Icon3dMap2, Icon3dMeter, Icon3dPlus, Icon3dServer,
} from "@assets/images/icon";
import { DTexam1, DTexam2, DTexam3, headerImg, layerEngImg, usaPatent, verifyPatent } from "@assets/images/digitaltwin";


export default {

    banner: {
        title: `World's First 3rd Generation Digital Transformation<br/>Digital Twin Specialist Platform`,
        desc : `One step advanced Orbro's digital twin. Experience the innovation.`,
        btn: {
            left: 'Start Now',
            right: 'Introduction video'
        }
    },

    header: {

        box: [
            {
                title: 'World\'s First',
                desc: 'Web-based Virtual World Platform'
            },
            {
                title: 'No.1',
                desc: 'Industrial Metaverse Performance (Korea Standard)'
            },
            {
                title: '500 Billion+',
                desc: 'Asia\'s Largest Scale of Location Information Data'
            },
            {
                title: '20,000+',
                desc: 'Real-time Objects Connected to ORBRO'
            },
        ],

        title: 'Digital Transformation, Digital Twin',
        desc: 'Receive and monitor real-world data of people, objects, buildings, and city in real-time on a digital screen. This allows for increased productivity, problem prevention, and is mainly used in the industrial and manufacturing sectors.',
        img: headerImg
    },

    connection: {
        title: 'ORBRO World, Advanced Digital Twin',
        desc: 'Goes beyond providing basic geographical and infrastructural information to understand real-world events in real-time. ORBRO, with its cutting-edge technology, has built a high-level Digital Twin technology.',
        img: layerEngImg
    },

    func: {
        title: 'Era of Digital Transformation<br/>Applicable Digital Twin Anywhere',
        desc: 'The world is full of data. ORBRO collects and organizes the data we generate to make the world easier to understand.',
        card: [
            {
                video: gis,
                title: '3D-Based Visual Device',
                desc: 'Provides information with the same 3D visual data in the real world. Shows real-time reflected information on a 3D map, including buildings and their interiors. This provides a more vivid virtual world experience.'
            },
            {
                video: weather,
                title: 'Connect Atmospheric and Weather Information',
                desc: 'Connects atmospheric and weather information to ORBRO servers, ensuring that actual rain or snow is implemented similarly. This allows for a more immersive and realistic world. Such information is essential in certain industries.'
            },
            {
                video: buildings,
                title: 'Building Information Implementation',
                desc: 'Monitors the status of facilities in real-time to optimize maintenance schedules. Uses sensor data from machines to predict and efficiently plan for malfunctions. Also monitors energy usage within buildings, optimizing energy efficiency and minimizing environmental impact.'
            },
            {
                video: tracking,
                title: 'Implementation of Indoor Building Maps',
                desc: 'Displays real-time maps not only of external data but also of items inside buildings and people passing by them. In facilities where information about foot traffic and visitors is crucial, this data becomes even more meaningful.'
            },
            {
                video: traffic,
                title: 'Connect Bus and Subway Traffic Information',
                desc: 'Enables collaboration between various modes of transportation for smart mobility. Provides accurate operational information through real-time tracking, allowing passengers to anticipate arrival times. Integrated transportation information offers optimal routes, helping to avoid traffic congestion and enabling efficient mobility.'
            },
            {
                video: airplane,
                title: 'Connect Airplane Information',
                desc: 'Connected information provides swift notifications for flight delays or changes, optimizing travel schedules. Airplane information integrated with smart gates and transportation systems facilitates smooth adjustments to travelers\' movements and enhances the efficiency of transportation networks. Additionally, leveraging predictive analytics optimizes aviation operations, reduces fuel consumption, and maximizes environmental benefits.'
            },
            {
                video: cctv,
                title: 'Connect CCTV Video Information',
                desc: 'Connecting CCTV videos for crime prevention detects suspicious signs and triggers alerts to enhance regional safety. Traffic flow monitoring optimizes road traffic by connecting CCTV videos in real-time to monitor and manage traffic situations. Integrating CCTV video information with various systems enhances the use of data for strengthening smart city functions.'
            },
        ]
    },

    video: {
        title: 'Vivid High-Quality Graphics Integration',
        desc: 'Enables precise modeling and simulation of the real world, allowing for a more detailed understanding of real-time situations and effective management.',
        video: video
    },
    patent: {
        card: [
            {
                title: 'Personal Location Information Provider Certification<br/>',
                desc : '317C-0052-69BC-05A3',
                img: verifyPatent,
            },
            {
                title: 'Radio Frequency Location Tracking U.S. Patent<br/>',
                desc : 'DFS ID: 37686035',
                img: usaPatent,
            }
        ]
    },
    
    summary: {
        title: 'Key Features Summary',
        desc: '',
        card: [
            {
                img: Icon3d3d,
                title: '3D-Based Visual Device',
                desc: 'The city platform is implemented in 3D, allowing for a more lively experience of the virtual world.'
            },
            {
                img: Icon3dServer,
                title: 'Data Collection',
                desc: 'Collects data from various sources, safely storing it in a centralized environment.'
            },
            {
                img: Icon3dMap,
                title: 'Location-Based Services',
                desc: 'Utilizes user location information to provide personalized services.'
            },
            {
                img: Icon3dMap2,
                title: 'Latitude and Longitude-Based Platform',
                desc: 'All location information is transformed into geographic coordinate information, visible on maps.'
            },
            {
                img: Icon3dLocation,
                title: 'Space-Friendly Services',
                desc: 'Provides location-based services through hardware devices such as intelligent kiosks and media walls.'
            },
            {
                img: Icon3dLink,
                title: 'Data Connection',
                desc: 'Connects public information and IoT/IIoT sensor data to create a comprehensive data environment.'
            },
            {
                img: Icon3dCycle,
                title: 'Integration of Various Public Information',
                desc: 'ORBRO World integrates various data generated at the national or regional level, managing and providing it.'
            },
            {
                img: Icon3dMeter,
                title: 'Reports and Analysis',
                desc: 'Provides analysis results in the form of reports, supporting effective analysis.'
            },
            {
                img: Icon3dPlus,
                title: 'Diverse Application Offerings',
                desc: 'Offers various applications, such as traffic information, environmental monitoring tools, smart city management, etc., by analyzing collected data.'
            },
        ]
    },
    exam: {
        title: 'Various Uses of ORBRO World',
        desc: 'Solutions in various fields using Digital Twin technology',
        card: [
            {
                img: DTexam1,
                tag: 'Smart City',
                title: 'City Information Connection',
                desc: 'Digitally models the physical aspects of the city, monitoring it in real-time for various positive effects on city operations and development. This allows the city to achieve efficient resource management, promote smart city development, and provide an enhanced quality of life for citizens.'
            },
            {
                img: DTexam2,
                tag: 'Smart Building',
                title: 'Indoor Location Tracking',
                desc: 'Provides accurate navigation within shopping malls or complex buildings, assisting in convenient movement and supporting rapid evacuation in emergency situations. Additionally, indoor location tracking enables personalized advertising and service delivery, enhancing user experience. In business environments, it aids in asset management and productivity improvement, while in smart homes, it increases convenience through integrated control systems.'
            },
            {
                img: DTexam3,
                tag: "Smart Warehouse",
                title: 'Logistics Status Checking',
                desc: 'Accurately understanding the location, movement path, and inventory status of products optimizes the efficiency of the logistics network and streamlines inventory management. Decision-making based on real-time information allows for agile adjustment of logistics processes, enabling quick responses to issues.'
            },
        ]
    },
}
