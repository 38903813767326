import { IconOsOff, IconOsOn } from '@assets/images/main/icon';
import { industryOn, industryOff, tagOn, tagOff, assetTagOn, assetTagOff, workerOn, workerOff } from '@assets/images/main/index';


export default {



    bannerData: [
        {
            icon: workerOn,
            iconInv: workerOff,
            image: '',
            iconText: 'BLE 위치추적',
            sub: 'New',
            link: '/beacon/ble'
        },
        {
            icon: industryOn,
            iconInv: industryOff,
            image: '',
            iconText: '산업용 비콘',
            sub: '',
            link: '/beacon/industry'
        },
        {
            icon: tagOn,
            iconInv: tagOff,
            image: '',
            iconText: '소형 비콘',
            sub: '',
            link: '/beacon/tag'
        },
        {
            icon: assetTagOn,
            iconInv: assetTagOff,
            image: '',
            iconText: '자산용 태그',
            sub: 'New',
            link: '/beacon/asset-tag'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/os'
        },


    ],





}