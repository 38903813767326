
import { IconOsOff, IconOsOn, IconRobotOff, IconRobotOn } from '@assets/images/main/icon';
import { IntegrationLabel, IntegrationLabel1, IntegrationLabel2, IntegrationLabel3, integrationOverviewDesktop11, integrationOverviewDesktop12, integrationOverviewDesktop13, integrationOverviewDesktop14, integrationOverviewDesktop15, integrationOverviewDesktop21, integrationOverviewDesktop22, integrationOverviewDesktop23, integrationOverviewDesktop24, integrationOverviewDesktop31, integrationOverviewDesktop32, integrationOverviewDesktop33, integrationOverviewDesktop34, integrationOverviewMobile11, integrationOverviewMobile12, integrationOverviewMobile13, integrationOverviewMobile14, integrationOverviewMobile15, integrationOverviewMobile21, integrationOverviewMobile22, integrationOverviewMobile23, integrationOverviewMobile24, integrationOverviewMobile31, integrationOverviewMobile32, integrationOverviewMobile33, integrationOverviewMobile34, integrationOverviewTablet11, integrationOverviewTablet12, integrationOverviewTablet13, integrationOverviewTablet14, integrationOverviewTablet15, integrationOverviewTablet21, integrationOverviewTablet22, integrationOverviewTablet23, integrationOverviewTablet24, integrationOverviewTablet31, integrationOverviewTablet32, integrationOverviewTablet33, integrationOverviewTablet34 } from '@assets/images/main/integration';
import { IntegrationDetailVideoDesktop1, IntegrationDetailVideoDesktop2, IntegrationDetailVideoDesktop3, IntegrationDetailVideoMobile1, IntegrationDetailVideoMobile2, IntegrationDetailVideoMobile3, IntegrationDetailVideoTablet1, IntegrationDetailVideoTablet2, IntegrationDetailVideoTablet3 } from '@assets/images/main/video/integration';


export default {

    mainPage: {
        product: {
            title: '사용환경에 맞는 패키지 구성',
            desc: '의료 환경에서 구축가능한 제품 패키지 구성을 확인해 보세요.',
            robotBasic: {
                title: '배송로봇 기본 패키지',
                desc: '실내 위치추적 시스템과 로봇 시스템의 조화, 간단한 구성으로 빠른 구축 가능',
            },
            delivery: {
                title: '무인 상/하차 패키지',
                desc: '무인으로 배송로봇이<br/>상/하차 가능하도록 구성된 패키지',

            },
            disinfection: {
                title: '고위험 관리 패키지',
                desc: '감염우려가 높은 의료 물품을 배송 관리가<br/>가능하도록 구성된 패키지',

            }
        },

    },

    banner: {
        label: IntegrationLabel,
        title: '더 넓은 확장을 위한 인티그레이션 시스템',
        desc: '',

        schemeTitle: '오브로가 제안하는 카메라 시스템.',
        schemeDesc: '결정하는데 도움이 필요하신가요? ',
        schemeDesc2: '로 연락 주시면<br/>출입 전문가가 도와드리도록 하겠습니다.',
        phone: '1522-9928',
    },

    bannerData: [
        {
            icon: IconRobotOn,
            iconInv: IconRobotOff,
            image: '',
            iconText: '로봇 시스템',
            sub: 'New',
            link: '/integration/robot'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/os'
        },

    ],


    video: {
        title: '위치추적 기술과 실내 배송 로봇의 결합',
        desc: [
            { text: '오브로의 위치추적시스템을 기반으로한 로봇시스템 연동은<br/>로봇의 실시간 위치 관제 부터 프로세스의 진행상황을 한눈에 확인 할 수 있도록 도와줍니다.' },
        ]

    },


    delivery: {
        label: IntegrationLabel1,
        title: '배송 로봇 시스템 연동',
        desc: '오브로 위치추적 시스템을 이용하여 로봇의 실시간 위치와 작업 상태 모니터링을 제공합니다.',

        card: [
            {
                videoTag: true,
                video: {
                    desktop: IntegrationDetailVideoDesktop1,
                    tablet: IntegrationDetailVideoTablet1,
                    mobile: IntegrationDetailVideoMobile1
                },
                title: '로봇의 위치추적 및 관리의 시작',
                desc: '오브로 위치 추적 시스템 기반으로 실시간 로봇의 위치추적이 가능하며<br/>다양한 장비들을 연동하여 로봇과의 실시간 운영 현황을 모니터링 하고 운영을 돕습니다.',
                img: {
                    desktop: integrationOverviewDesktop11,
                    tablet: integrationOverviewTablet11,
                    mobile: integrationOverviewMobile11
                },
            },
            {
                title: '오브로 위치 추적 시스템과 결합',
                desc: '오브로의 인티그레이션 시스템을 통하여<br/>로봇과 위치 추적시스템을 연동이 손쉽게 가능합니다.',
                img: {
                    desktop: integrationOverviewDesktop12,
                    tablet: integrationOverviewTablet12,
                    mobile: integrationOverviewMobile12
                },
            },
            {
                title: '작업 상태 모니터링',
                desc: '로봇의 작업 상태를 실시간으로 감지하고 즉각적인 대응이 가능합니다.',
                img: {
                    desktop: integrationOverviewDesktop13,
                    tablet: integrationOverviewTablet13,
                    mobile: integrationOverviewMobile13
                },
            },
            {
                videoTag: true,
                video: {
                    desktop: IntegrationDetailVideoDesktop2,
                    tablet: IntegrationDetailVideoTablet2,
                    mobile: IntegrationDetailVideoMobile2
                },
                title: '로봇 위치 모니터링',
                desc: '각 로봇의 현재 위치를 실시간으로 제공합니다.',
                img: {
                    desktop: integrationOverviewDesktop14,
                    tablet: integrationOverviewTablet14,
                    mobile: integrationOverviewMobile14
                },
            },
            {
                videoTag: true,
                video: {
                    desktop: IntegrationDetailVideoDesktop3,
                    tablet: IntegrationDetailVideoTablet3,
                    mobile: IntegrationDetailVideoMobile3
                },
                title: '여러 대의 로봇 관리',
                desc: '여러대의 로봇을 동시에 효과적으로 관리 할 수 있습니다.',
                img: {
                    desktop: integrationOverviewDesktop15,
                    tablet: integrationOverviewTablet15,
                    mobile: integrationOverviewMobile15
                },
            },
        ]
    },



    loading: {

        label: IntegrationLabel2,
        title: '무인 상하차 시스템 연동',
        desc: '배송 로봇의 상차부터 하차까지 무인으로 운영 가능하도록<br/>상차장비, 협동로봇, 보관함까지 모두 연동하여 하나의 시스템으로 제공합니다.',

        card: [
            {
                title: '상차장치 연동',
                desc: '상차장치 연동으로 로봇 호출이 가능하며 로봇의 도착 및 상차 준비 상태를<br/>오브로를 통해 전달받아 이를 자동으로 운영 가능하도록 합니다.',
                img: {
                    desktop: integrationOverviewDesktop21,
                    tablet: integrationOverviewTablet21,
                    mobile: integrationOverviewMobile21
                },
            },
            {
                title: '협동 로봇 연동',
                desc: '협동로봇 연동을 통해 배송로봇이 도착시 상태를 확인하여<br/>자동으로 하차를 진행 합니다.',
                img: {
                    desktop: integrationOverviewDesktop22,
                    tablet: integrationOverviewTablet22,
                    mobile: integrationOverviewMobile22
                },
            },
            {
                title: '스마트 보관함 연동',
                desc: '맞춤형 대시보드를 통해 사용자는 중요한 데이터를 쉽게 모니터링하고, 데이터를 빠르게 이해하고 효과적인 결정을 내릴 수 있는 인사이트를 제공합니다.',
                img: {
                    desktop: integrationOverviewDesktop23,
                    tablet: integrationOverviewTablet23,
                    mobile: integrationOverviewMobile23
                },
            },
            {
                title: '실시간 현황 확인',
                desc: '실시간 재고 현황 및 운영 상태 등<br/>모든장비들의 운영 상태를 하나의 화면에서 제공합니다.',
                img: {
                    desktop: integrationOverviewDesktop24,
                    tablet: integrationOverviewTablet24,
                    mobile: integrationOverviewMobile24
                },
            },
        ]
    },



    service: {

        label: IntegrationLabel3,
        title: '소독 시스템 연동',
        desc: '오브로 OS는 강력하고 편리하지만 그 과정이 복잡하지 않습니다.<br/>어떤 제품이라도 손쉽게 설정하고 바로 사용할 수 있습니다.',

        card: [
            {
                title: '플라즈마 소독장치',
                desc: '의료 폐기물 및 의료도구의 멸균에 필요한 온도까지 빠르게 상승하여 멸균 사이클의 시간을 줄이고 기화된 멸균 제의 균일한 공급을 통해 완벽한 멸균을 제공합니다. ',
                img: {
                    desktop: integrationOverviewDesktop31,
                    tablet: integrationOverviewTablet31,
                    mobile: integrationOverviewMobile31
                },
            },
            {
                title: 'UVC 소독 장치',
                desc: 'UVC의 높은 살균력과 LED 램프의 안전성을 활용하여 안전하면서 높은 살균을 제공합니다.',
                img: {
                    desktop: integrationOverviewDesktop32,
                    tablet: integrationOverviewTablet32,
                    mobile: integrationOverviewMobile32
                },
            },
            {
                title: 'HPV 소독장치 연동',
                desc: '오염구역을 다녀온 배송로봇을 비대면으로 자가/소독/멸균을 진행합니다.',
                img: {
                    desktop: integrationOverviewDesktop33,
                    tablet: integrationOverviewTablet33,
                    mobile: integrationOverviewMobile33
                },
            },
            {
                title: '기기별 작업 상태 모니터링 제공',
                desc: '연동된 소독 장치들의 연결 상태 및 기기 상태 뿐만 아니라<br/>작업 상태까지 실시간으로 모니터링 기능을 제공합니다.',
                img: {
                    desktop: integrationOverviewDesktop34,
                    tablet: integrationOverviewTablet34,
                    mobile: integrationOverviewMobile34
                },
            },
        ]
    },



}