

export default {


  design: 'Product',
  outlook: 'Appearance',
  spec: 'specifications',

  product: {

    acces: [
      { text: "accesscontroller" },
      { text: "accessauto" },
      { text: "cloud" },
    ],

    accesscontroller: {
      title: 'Access Controller',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, ABS/Gray, ABS/Black, PC/Translucent',
            },
            {
              title: 'Dimensions',
              description: '85.4mm x 85.4mm x 24.5mm (Width x Height x Depth)',
            },
          ]
        },

        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'Cortex M4 64MHz',
            },
            {
              title: 'Memory',
              description: '512KB, 64KB',
            },
          ]
        },

        {
          name: 'Network',
          detail: [
            {
              title: 'Connectivity',
              description: 'BLE 5.2',
            }
          ]
        },

        {
          name: 'Interface',
          detail: [
            {
              title: 'Alarm',
              description: 'RGB LED x 4',
            },
          ]
        },

        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '-10°C ~ +60°C, 0% ~ 90%',
            },
            {
              title: 'Power',
              description: 'CR2477 1S 4P',
            }
          ]
        },

      ],

    },

    accessauto: {
      title: 'Access Auto',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, PC/Translucent Black',
            },
            {
              title: 'Dimensions',
              description: '210.1mm x 60.1mm x 42.2mm (Width x Height x Depth)',
            },
          ]
        },

        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'Dual-core 32bit LX6, 240MHz',
            },
            {
              title: 'Memory',
              description: 'FLASH 8MB, PSRAM 2MB',
            },
          ]
        },

        {
          name: 'Network',
          detail: [
            {
              title: 'Connectivity',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n, 24GHz Microwave, SUB 1GHz',
            }
          ]
        },

        {
          name: 'Interface',
          detail: [
            {
              title: 'Alarm',
              description: 'Door control, Shock detection, BEEP',
            },
            {
              title: 'Serial',
              description: 'RS232(USB Console)',
            },
          ]
        },

        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '0°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: 'Power',
              description: 'DC 12V, 24V (Non Polarity)',
            }
          ]
        },

      ],

    },

    cloud: {
      title: 'Access Controller',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, PC/Translucent Black',
            },
            {
              title: 'Dimensions',
              description: '210.1mm x 60.1mm x 42.2mm (Width x Height x Depth)',
            },
          ]
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'Dual-core 32-bit LX6, 240MHz',
            },
            {
              title: 'Memory',
              description: 'FLASH 8MB, PSRAM 2MB',
            },
          ]
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Connectivity',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n, 24GHz Microwave, SUB 1GHz',
            }
          ]
        },
        {
          name: 'Interfaces',
          detail: [
            {
              title: 'Alarms',
              description: 'Door control, Shock detection, BEEP',
            },
            {
              title: 'Serial',
              description: 'RS232 (USB Console)',
            },
          ]
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '0°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: 'Power',
              description: 'DC 12V, 24V (Non-Polarity)',
            }
          ]
        },
      ],
    },
    serverpcRTLS: {
      title: 'Server PC',
      spec: [
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'Intel Xeon E-2334 (3.4Ghz 4Core 8M L3 65W)',
            },
            {
              title: 'Memory',
              description: '16GB 3200MT/s DDR4 ECC UDIMM, Max 64GB, 4 slots',
            },
            {
              title: 'Storage',
              description: '1TB 7.2K RPM SATA 3.5',
            },
          ],
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Network Controller',
              description: 'Onboard Broadcom 5720 Dual Port 1Gb LOM',
            },
          ],
        },
        {
          name: 'Usage Environment and Power',
          detail: [
            {
              title: 'Weight',
              description: '12.2kg',
            },
            {
              title: 'Expansion Slots',
              description: '2 PCIe slots',
            },
            {
              title: 'Power',
              description: 'Dual, Hot Plug, Redundant Power Supply (1+1), 600W',
            },
          ],
        },
      ],
    },

    serverpc: {
      title: 'Server PC',
      spec: [
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'Intel Xeon silver 4210R, 1CPU, 10 Core',
            },
            {
              title: 'Memory',
              description: '32GB',
            },
            {
              title: 'Storage',
              description: '1TB + 4TB',
            },
          ]
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Weight',
              description: '17.6kg',
            },
            {
              title: 'Expansion Slots',
              description: '16 PCI Gen3 slots x 2',
            },
            {
              title: 'Power',
              description: '450W',
            }
          ]
        },
      ],
    },

    accesspro: {
      title: 'Access Pro',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'Aluminum/Gray, PC/Translucent',
            },
            {
              title: 'Dimensions',
              description: '256mm x 273mm x 24mm (Width x Height x Depth)',
            },
            {
              title: 'Weight',
              description: '1.2Kg',
            },
          ]
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'Quad-core A17 1.8GHz',
            },
            {
              title: 'Memory',
              description: '2GB / 16GB',
            },
          ]
        },
        {
          name: 'Sensors and Camera',
          detail: [
            {
              title: 'Image Sensor',
              description: '1/3.06" CMO, MN34229',
            },
            {
              title: 'Lens Type',
              description: 'Fixed-focal',
            },
            {
              title: 'Camera Angle',
              description: '180º',
            },
            {
              title: 'Resolution',
              description: '1920 x 1080',
            }
          ]
        },
        {
          name: 'Display',
          detail: [
            {
              title: 'Resolution',
              description: '10.1" TFT LCD 1280x800',
            },
          ]
        },
        {
          name: 'Interfaces',
          detail: [
            {
              title: 'Audio',
              description: 'MIC, Speaker',
            },
            {
              title: 'Notifications',
              description: 'Door Control, Emergency Call',
            },
            {
              title: 'USB',
              description: 'USB2.0 Host x 2, USB2.0 OTG x 1',
            },
            {
              title: 'Serial',
              description: 'RS232 (USB Console)',
            }
          ]
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '0°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: 'Power',
              description: 'ADAPTER 12V / 3A',
            },
          ]
        },
      ],
    },

    accesskey: {
      title: 'Access Key',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'Aluminum/Gray, PC/Translucent, ABS/White',
            },
            {
              title: 'Dimensions',
              description: '49mm x 26mm x 9mm (Width x Height x Depth)',
            },
          ]
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Connectivity',
              description: '24.GHz / Bluetooth 5.0',
            },
          ]
        },
        {
          name: 'Interfaces',
          detail: [
            {
              title: 'Notifications',
              description: 'LED (Status)',
            },
          ]
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '0°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: 'Power',
              description: '3.7V - 75mAh / Lithium Polymer',
            },
            {
              title: 'Rating',
              description: '5V / 40mA',
            },
          ]
        },
      ],
    },

    emlock: {
      title: 'EM Lock',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'Aluminum/Gray',
            },
            {
              title: 'Dimensions',
              description: '178mm x 38mm x 25mm (Width x Height x Depth)',
            },
          ]
        },
        {
          name: 'Function and Features',
          detail: [
            {
              title: 'Holding Force',
              description: '280KG/F',
            },
          ]
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '-10°C to +55°C',
            },
            {
              title: 'Operating Humidity',
              description: '0 - 90%',
            },
            {
              title: 'Power',
              description: '12V/40mA',
            },
          ]
        },
      ],
    },

    deadbolt: {
      title: 'Deadbolt Lock',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'Aluminum/Gray',
            },
            {
              title: 'Dimensions',
              description: '203mm x 37mm x 41mm (Width x Height x Depth)',
            },
            {
              title: 'Weight',
              description: '529g',
            },
          ]
        },
        {
          name: 'Function and Features',
          detail: [
            {
              title: 'Advantages',
              description: 'Built-in self-open contact / Lock monitoring / Door monitoring capability',
            },
          ]
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '-10°C to +55°C',
            },
            {
              title: 'Operating Humidity',
              description: '0 - 90%',
            },
            {
              title: 'Current Consumption',
              description: '900mA during operation / 350mA during standby',
            },
            {
              title: 'Power',
              description: 'DC 12V',
            },
          ]
        },
      ],
    },

    autoDoorSensor: {
      title: 'Automatic Door Sensor',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, PC/Translucent Black',
            },
            {
              title: 'Dimensions',
              description: '210.1mm x 60.1mm x 42.2mm (Width x Height x Depth)',
            },
          ],
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'Dual-core 32bit LX6, 240MHz',
            },
            {
              title: 'Memory',
              description: 'FLASH 8MB, PSRAM 2MB',
            },
          ],
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Connectivity',
              description: '2.4GHz Microwave, SUB 1GHz',
            },
          ],
        },
        {
          name: 'Interface',
          detail: [
            {
              title: 'Alarms',
              description: 'Door control, Shock detection',
            },
          ],
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '0°C to +50°C, 0% to 90% humidity',
            },
            {
              title: 'Power',
              description: 'DC 12V, 24V (Non Polarity)',
            },
          ],
        },
      ],
    },





    twinTracker: {
      link: 'assetTagBle',
      title: 'TwinTracker',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, PC/Translucent White, Aluminum/Black',
            },
            {
              title: 'Dimensions',
              description: '95mm x 95mm x 32.5mm (Width x Height x Depth)',
            },
          ]
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'Dual-core 32-bit LX6, 240MHz',
            },
            {
              title: 'Memory',
              description: '448KB ROM, 520 KB SRAM',
            },
          ]
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Ethernet',
              description: '100Base-TX',
            },
            {
              title: 'Wireless',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n',
            }
          ]
        },
        {
          name: 'Interfaces',
          detail: [
            {
              title: 'Serial',
              description: 'RS232 (USB Console)',
            },
          ]
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '0°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: 'Power',
              description: '3.2W, PoE 802.3af, USB 5V',
            }
          ]
        },
      ],
    },


    twinTrackerPro: {
      link: 'assetTagBle',
      title: 'TwinTracker Pro',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, PC/Translucent White, Aluminum/Black',
            },
            {
              title: 'Dimensions',
              description: '95mm x 95mm x 32.5mm (Width x Height x Depth)',
            },
          ]
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'Dual-core 32-bit LX6, 240MHz',
            },
            {
              title: 'Memory',
              description: '448KB ROM, 520 KB SRAM',
            },
          ]
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Ethernet',
              description: '100Base-TX',
            },
            {
              title: 'Wireless',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n',
            }
          ]
        },
        {
          name: 'Interfaces',
          detail: [
            {
              title: 'Serial',
              description: 'RS232 (USB Console)',
            },
          ]
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '-20°C ~ +50°C, 0% ~ 90%',
            },
            {
              title: 'Power',
              description: '3.2W, PoE 802.3af, USB 5V',
            }
          ]
        },
      ],
    },



    assetTagBle: {
      title: 'Asset Tag (BLE)',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, ABS/Black, PC/Translucent',
            },
            {
              title: 'Dimensions',
              description: '48.4mm x 48.4mm x 10.7mm (Width x Height x Depth)',
            },
            {
              title: 'Weight',
              description: '22g',
            },
          ]
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'Cortex M4 64MHz',
            },
            {
              title: 'Memory',
              description: '512KB',
            },
          ]
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Wireless',
              description: 'BLE 5.2',
            }
          ]
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '-20°C to +40°C, 0% to 90% humidity',
            },
            {
              title: 'Power',
              description: 'CR2450',
            }
          ]
        },
      ],
    },

    assetTag: {
      title: 'Asset Tag',
      spec: [
        {
          name: 'Appearance and Materials',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, ABS/Gray',
            },
            {
              title: 'Dimensions',
              description: '31mm x 31mm x 3.8mm (Width x Height x Depth)',
            },
          ]
        },

        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'Cortex M4 64MHz',
            },
            {
              title: 'Memory',
              description: '64KB',
            },
          ]
        },

        {
          name: 'Network',
          detail: [
            {
              title: 'Wireless',
              description: 'BLE 5.2',
            }
          ]
        },

        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '-20°C ~ +40°C, 0% ~ 90% Humidity',
            },
            {
              title: 'Power',
              description: 'CR2012',
            }
          ]
        },

      ],

    },


    tagUwb: {
      title: 'Tag (UWB)',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/Black',
            },
          ]
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'Cortex M4 64MHz',
            },
            {
              title: 'Memory',
              description: '512KB',
            },
          ]
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Wireless',
              description: 'UWB 802.15.4',
            },
          ]
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '-20°C to +50°C, 0% to 90% humidity',
            },
            {
              title: 'Power',
              description: 'USB-C',
            },
          ]
        },
      ],
    },

    safetyBell: {
      title: 'Safety Bell',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, ABS/Black, PC/Translucent White',
            },
            {
              title: 'Dimensions',
              description: '64.2mm x 64.2mm x 16.2mm (Width x Height x Depth)',
            },
          ]
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'ARM Cortex M4, 64MHz',
            },
          ]
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Wireless',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n',
            },
          ]
        },
        {
          name: 'Interfaces',
          detail: [
            {
              title: 'Audio',
              description: 'Buzzer',
            },
          ]
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '-20°C to +40°C, 0% to 90% humidity',
            },
            {
              title: 'Power',
              description: 'AAA x 2',
            },
          ]
        },
      ],
    },

    smartSwitch: {
      title: 'Smart Switch',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, ABS/Black, Aluminum/Silver',
            },
            {
              title: 'Dimensions',
              description: '76mm x 120mm x 44.3mm (Width x Height x Depth)',
            },
          ]
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'ARM® Cortex®-M4 32-Bit Processor with FPU, 64MHz',
            },
            {
              title: 'Memory',
              description: '215 EEMBC CoreMark® Score Running from Flash Memory',
            },
          ]
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Wireless',
              description: 'BLE 5.0, Wi-Fi 802.11 b/g/n',
            },
          ]
        },
        {
          name: 'Interfaces',
          detail: [
            {
              title: 'Touch',
              description: '',
            },
          ]
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '0°C to +60°C',
            },
          ]
        },
      ],
    },

    esl: {
      title: 'ESL',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, PC/Transparent',
            },
            {
              title: 'Dimensions',
              description: '76mm x 142mm x 10mm (Width x Height x Depth)',
            },
          ]
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'ARM® Cortex®-M4 32-Bit Processor with FPU, 64MHz',
            },
            {
              title: 'Memory',
              description: '215 EEMBC CoreMark® Score Running from Flash Memory',
            },
          ]
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Wireless',
              description: 'BLE 5.0',
            },
          ]
        },
        {
          name: 'Display',
          detail: [
            {
              title: 'Resolution',
              description: '800 x 600 (230dpi)',
            },
            {
              title: 'Color',
              description: 'Black, White',
            },
            {
              title: 'Max Grayscale',
              description: '16',
            },
          ]
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Connector',
              description: 'USB-C',
            },
            {
              title: 'Power',
              description: '5V, 500mA',
            },
          ]
        },
      ],
    },

    mTag: {
      title: 'Mobile Tag',
      spec: [
        {
          name: 'Design and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, PC/Transparent',
            },
            {
              title: 'Dimensions',
              description: '38mm x 50.1mm x 18mm (Width x Height x Depth)',
            },
          ]
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'ARM® Cortex®-M4 64MHz',
            },
            {
              title: 'Memory',
              description: '512KB',
            },
          ]
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Wireless',
              description: 'BLE 5.2',
            },
          ]
        },
        {
          name: 'Interface',
          detail: [
            {
              title: 'Indication',
              description: 'LED (White) x 1',
            },
          ]
        },
        {
          name: 'Usage Environment and Power',
          detail: [
            {
              title: 'Connector',
              description: 'Lightning, USB-C',
            },
            {
              title: 'Power',
              description: 'Lithium Polymer Battery (4.2V, 35mAh)',
            },
          ]
        },
      ],
    },

    mCradle: {
      title: 'Mobile Cradle',
      spec: [
        {
          name: 'Appearance and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, PC/Transparent',
            },
            {
              title: 'Dimensions',
              description: '76mm x 40mm x 25mm (Width x Height x Depth)',
            },
          ],
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'ARM® Cortex®-M4 64MHz',
            },
            {
              title: 'Memory',
              description: '512KB',
            },
            {
              title: 'Sensor',
              description: '3Axis Sensor',
            },
          ],
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Wireless',
              description: 'BLE 5.2',
            },
          ],
        },
        {
          name: 'Interface',
          detail: [
            {
              title: 'Notification',
              description: 'LED (White) x 1',
            },
          ],
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Power',
              description: 'DC 5V (USB-C type)',
            },
          ],
        },
      ],
    },



    ptz: {
      title: "PTZ Camera",
      spec: [
        {
          name: "Appearance",
          detail: [
            {
              title: "Dimensions",
              description: "266mm x 266mm x 410mm (Width x Height x Depth)"
            }
          ]
        },
        {
          name: "Camera",
          detail: [
            {
              title: "Image Sensor",
              description: "1/1.8” Progressive Scan CMOS"
            },
            {
              title: "Zoom",
              description: "42x Optical, 16x Digital"
            },
            {
              title: "Resolution",
              description: "2560 x 1440"
            }
          ]
        },
        {
          name: "Lens",
          detail: [
            {
              title: "Focal Length",
              description: "6.0mm - 252mm"
            },
            {
              title: "Zoom Speed",
              description: "4.5 seconds"
            },
            {
              title: "Aperture",
              description: "F 1.2"
            }
          ]
        },
        {
          name: "PTZ",
          detail: [
            {
              title: "Pan Range",
              description: "360°"
            },
            {
              title: "Tilt Range",
              description: "-20° to 90°"
            }
          ]
        },
        {
          name: "Interface",
          detail: [
            {
              title: "Ethernet",
              description: "1 RJ45 10M/100M self-adaptive Ethernet port, Hi-PoE"
            },
            {
              title: "Alarm",
              description: "7 inputs, 2 outputs"
            }
          ]
        },
        {
          name: "Operating Environment and Power",
          detail: [
            {
              title: "Power",
              description: "24VAC, PoE"
            },
            {
              title: "Operating Conditions",
              description: "-40°C to +70°C, 0% to 95%"
            },
            {
              title: "Ingress Protection",
              description: "IP67"
            }
          ]
        }
      ]
    },

    idCard: {
      title: 'ID02',
      spec: [
        {
          name: 'Appearance and Materials',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, Aluminum',
            },
            {
              title: 'Dimensions',
              description: '51mm x 95.9mm x 7mm (Width x Height x Depth)',
            },
          ]
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'ARM® Cortex®-M4 32-Bit Processor with FPU, 64MHz',
            },
            {
              title: 'Memory',
              description: '215 EEMBC CoreMark® Score Running from Flash Memory<br/>58μA/MHz Running from Flash Memory<br/>51.6μA/MHz Running from RAM.',
            },
          ]
        },

        {
          name: 'Network',
          detail: [
            {
              title: 'Wireless',
              description: 'BLE 5.2',
            },
          ]
        },

        {
          name: 'Interface',
          detail: [
            {
              title: 'Notification',
              description: 'LED(RGB) x 1',
            },
            {
              title: 'USB',
              description: 'USB 2.0 Device x 1',
            },
            {
              title: 'Button',
              description: 'Slide Switch Button',
            },
          ]
        },

        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '0°C ~ 50°C',
            },
            {
              title: 'Power',
              description: 'Li-polymer (3.7V / 300mA)',
            },
          ]
        },


      ],

    },

    ip: {
      title: "IP Camera",
      spec: [
        {
          name: "Appearance",
          detail: [
            {
              title: "Dimensions",
              description: "135mm x 135mm x 110mm (Width x Height x Depth)"
            },
            {
              title: "Weight",
              description: "Approximately 800g"
            }
          ]
        },
        {
          name: "Camera",
          detail: [
            {
              title: "Image Sensor",
              description: "1/2.8\" Progressive Scan CMOS"
            },
            {
              title: "Resolution",
              description: "1920 × 1080"
            }
          ]
        },
        {
          name: "Lens",
          detail: [
            {
              title: "Lens Type",
              description: "Fixed Focus Lens, options of 2.8mm and 4mm"
            },
            {
              title: "Aperture",
              description: "F 1.4"
            }
          ]
        },
        {
          name: "Interface",
          detail: [
            {
              title: "Ethernet",
              description: "RJ45 10M/100M self-adaptive Ethernet port"
            },
            {
              title: "Alarm",
              description: "-S: 1 alarm input, 1 alarm output (max. 12 VDC, 30 mA)"
            }
          ]
        },
        {
          name: "Operating Environment and Power",
          detail: [
            {
              title: "Power",
              description: "12 VDC"
            },
            {
              title: "Operating Conditions",
              description: "-30°C to +60°C, 0% to 95%"
            },
            {
              title: "Ingress Protection",
              description: "IP67"
            }
          ]
        }
      ]
    },
    nvr: {
      title: "NVR",
      spec: [
        {
          name: "Video and Audio",
          detail: [
            {
              title: "IP Video Inputs",
              description: "8 channels"
            },
            {
              title: "Video Output Modes",
              description: "HDMI/VGA independent outputs"
            },
            {
              title: "HDMI Output",
              description: "4K (3840 × 2160)/30Hz, 2K (2560 × 1440)/60Hz, 1920 × 1080/60Hz, 1600 × 1200/60Hz, 1280 × 1024/60Hz, 1280 × 720/60Hz"
            },
            {
              title: "VGA Output",
              description: "1920 × 1080/60 Hz, 1280 × 1024/60 Hz, 1280 × 720/60 Hz"
            },
            {
              title: "Audio Input",
              description: "1 channel, RCA (2.0 Vp-p, 1 KΩ)"
            },
            {
              title: "Audio Output",
              description: "1 channel, RCA (linear, 1 KΩ)"
            }
          ]
        },
        {
          name: "Decoding",
          detail: [
            {
              title: "Decoding Format",
              description: "H.265/H.265+/H.264/H.264+"
            },
            {
              title: "Recording Resolution",
              description: "12 MP/8 MP/6 MP/5 MP/4 MP/3 MP/1080p/UXGA/720p/VGA/4CIF/DCIF/2CIF/CIF/QCIF"
            }
          ]
        },
        {
          name: "Network",
          detail: [
            {
              title: "Network Protocols",
              description: "TCP/IP, DHCP, IPv4, IPv6, DNS, DDNS, NTP, RTSP, SADP, SMTP, SNMP, NFS, iSCSI, ISUP, UPnP™, HTTP, HTTPS"
            },
            {
              title: "Network Interface",
              description: "1, RJ-45 10/100/1000 Mbps self-adaptive Ethernet interface"
            }
          ]
        },
        {
          name: "Operating Environment and Power",
          detail: [
            {
              title: "Power",
              description: "12 VDC"
            },
            {
              title: "Operating Conditions",
              description: "-10°C to +55°C, 10% to 90%"
            }
          ]
        }
      ]

    },

    loraGateway: {
      title: 'LoRa Gateway',
      spec: [
        {
          name: 'Common Specifications',
          detail: [
            {
              title: 'Material',
              description: 'Aluminum/Gray',
            },
            {
              title: 'Dimensions',
              description: '145mm x 145mm x 42mm (Width x Height x Depth)',
            },
            {
              title: 'Weight',
              description: '750g',
            },
            {
              title: 'RF Range',
              description: '920.9MHz~923.1MHz BW 125KHz',
            },
            {
              title: 'Communication Range',
              description: 'LOS (Line of Sight) up to 6km',
            },
            {
              title: 'Antenna Reception Sensitivity',
              description: '-135 dBm',
            },
            {
              title: 'Antenna Transmission Power',
              description: '20dBm',
            },
            {
              title: 'Security',
              description: 'AES-128',
            },
          ],
        },
        {
          name: 'Ethernet Gateway',
          detail: [
            {
              title: 'Ethernet Specs',
              description: '10/100 Ethernet Controller',
            },
            {
              title: 'IEEE Standard Compliance',
              description: '802.3',
            },
            {
              title: 'Drive Method',
              description: 'Full–Duplex and Half-Duplex',
            },
            {
              title: 'Supported Protocols',
              description: 'DHCP, DNS, NTP, UDP, TCP, SNMP',
            },
            {
              title: 'Cable Connection',
              description: 'Ethernet',
            },
          ],
        },
        {
          name: 'Wi-Fi',
          detail: [
            {
              title: 'IEEE Standard Compliance',
              description: '802.11b/g/n 2.4GHz',
            },
          ],
        },
        {
          name: 'Cellular LTE',
          detail: [
            {
              title: 'LTE Spec',
              description: 'LTE CatM1 (Telit Me910 C1)',
            },
            {
              title: 'SIM Card Specifications',
              description: 'Micro-SIM (3FF) 15mm x 12mm x 0.76mm',
            },
          ],
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '-20°C ~ 60°C',
            },
            {
              title: 'Power',
              description: 'DC 8V ~ 24.0V',
            },
            {
              title: 'Certifications',
              description: 'KC, IP67',
            },
          ],
        },
      ],
    },
    loraSensor: {
      title: 'LoRa Sensor',
      spec: [
        {
          name: 'Common Specifications',
          detail: [
            {
              title: 'Material',
              description: 'Aluminum/Gray',
            },
            {
              title: 'Dimensions',
              description: '84mm x 72mm x 31mm (Width x Height x Depth)',
            },
            {
              title: 'Weight',
              description: '300g',
            },
            {
              title: 'RF Range',
              description: '920.9MHz~923.1MHz BW 125KHz',
            },
            {
              title: 'Communication Method',
              description: 'LoRa',
            },
            {
              title: 'Communication Range',
              description: 'LOS (Line of Sight) up to 6km',
            },
            {
              title: 'Security',
              description: 'AES-128',
            },
          ],
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '-20°C ~ 60°C',
            },
            {
              title: 'Power',
              description: 'DC 9V ~ 33V<br/>AA Battery DC 3.6V / 2,500mAh x 2EA (parallel connection total 5,000mAh)<br/>AA Battery DC 1.5V / 2,850mAh x 2EA (series connection total 2,850mAh)',
            },
            {
              title: 'Certifications',
              description: 'KC, IP67',
            },
          ],
        },
      ],
    },
    airQuality: {
      title: 'Air Quality Monitor',
      spec: [
        {
          name: 'Common Specifications',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White',
            },
            {
              title: 'Dimensions',
              description: '115mm x 115mm x 30mm (Width x Height x Depth)',
            },
            {
              title: 'Weight',
              description: '215g',
            },
            {
              title: 'Display',
              description: '2.0" TFT DISPLAY',
            },
            {
              title: 'Communication Method',
              description: 'LoRa (Optional), RS-485',
            },
          ],
        },
        {
          name: 'Measurement Ranges',
          detail: [
            {
              title: 'Fine Dust',
              description: 'PM1.0/PM2.5/PM4.0/PM10: 0 ~ 1,000 µg/m³',
            },
            {
              title: 'Carbon Dioxide (CO2)',
              description: '400 ~ 10,000 ppm',
            },
            {
              title: 'Total Volatile Organic Compounds (TVOC)',
              description: '0 ~ 6,000 ppb',
            },
            {
              title: 'Greenhouse Gases',
              description: '0 ~ 60,000 ppm',
            },
            {
              title: 'Temperature',
              description: '-40°C ~ 125°C',
            },
            {
              title: 'Humidity',
              description: '0 ~ 100 %RH',
            },
          ],
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '-20°C ~ 60°C',
            },
            {
              title: 'Power',
              description: 'DC 24V / 0.1A<br/>Li-polymer 1500mAh/3.7V',
            },
            {
              title: 'Certifications',
              description: 'KC',
            },
          ],
        },
      ],
    },
    coMeasure: {
      title: 'Carbon Monoxide (CO) Monitor',
      spec: [
        {
          name: 'Common Specifications',
          detail: [
            {
              title: 'Material',
              description: 'Aluminum/Gray',
            },
            {
              title: 'Dimensions',
              description: '93mm x 125mm x 44mm (Width x Height x Depth)',
            },
            {
              title: 'Weight',
              description: '245g',
            },
            {
              title: 'Communication Method',
              description: 'WiFi (2.4GHz only 802.11 b/g/n) and RS-485 (Modbus RTU)',
            },
          ],
        },
        {
          name: 'Measurement Ranges',
          detail: [
            {
              title: 'Fine Dust',
              description: 'PM1.0/PM2.5/PM4.0/PM10: 0 ~ 1,000 µg/m³',
            },
            {
              title: 'Carbon Monoxide (CO)',
              description: '0 ~ 100 ppm (at room temperature)',
            },
            {
              title: 'Temperature',
              description: '-40°C ~ 125°C',
            },
            {
              title: 'Humidity',
              description: '0 ~ 100 %RH',
            },
          ],
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '-10°C ~ 40°C',
            },
            {
              title: 'Power',
              description: 'DC 24V / 0.1A',
            },
            {
              title: 'Certifications',
              description: 'KC/CE/FCC',
            },
          ],
        },
      ],
    },
    plutoconPro: {
      title: 'Plutocon Pro',
      spec: [
        {
          name: 'Exterior and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, ABS/Gray, ABS/Black, PC/Translucent',
            },
            {
              title: 'Dimensions',
              description: '85.4mm x 85.4mm x 24.5mm (Width x Height x Depth)',
            },
          ],
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'Cortex M4 64MHz',
            },
            {
              title: 'Memory',
              description: '512KB, 64KB',
            },
          ],
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Connectivity',
              description: 'BLE 5.2',
            },
          ],
        },
        {
          name: 'Interface',
          detail: [
            {
              title: 'Alarms',
              description: 'RGB LED x 4',
            },
          ],
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '-10°C ~ +60°C, 0% ~ 90%',
            },
            {
              title: 'Power',
              description: 'CR2477 1S 4P',
            },
          ],
        },
      ],
    },
    orbroTag: {
      title: 'ORBRO Tag',
      spec: [
        {
          name: 'Exterior and Material',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, ABS/Black, PC/Translucent',
            },
            {
              title: 'Dimensions',
              description: '48.4mm x 48.4mm x 10.7mm (Width x Height x Depth)',
            },
            {
              title: 'Weight',
              description: '22g',
            },
          ],
        },
        {
          name: 'Processor and Memory',
          detail: [
            {
              title: 'Processor',
              description: 'Cortex M4 64MHz',
            },
            {
              title: 'Memory',
              description: '512KB',
            },
          ],
        },
        {
          name: 'Network',
          detail: [
            {
              title: 'Wireless',
              description: 'BLE 5.2',
            },
          ],
        },
        {
          name: 'Operating Environment and Power',
          detail: [
            {
              title: 'Operating Conditions',
              description: '-20°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: 'Power',
              description: 'CR2450',
            },
          ],
        },
      ],
    },


    deliveryRobot: {
      title: 'Delivery Robot',
      spec: [
        {
          name: ' ',
          detail: [
            {
              title: 'Material',
              description: 'ABS/White, PC/Translucent White, Aluminum/Black',
            },
            {
              title: 'Dimensions',
              description: '510 x 510 x 1300 (W x D x H)',
            },
            {
              title: 'Load Capacity',
              description: '20L',
            },
            {
              title: 'Operating Environment',
              description: 'Indoor',
            },
            {
              title: 'Inclination Angle',
              description: '+5 degrees/-5 degrees',
            },
            {
              title: 'Step Height',
              description: '1cm/-1cm',
            },
            {
              title: 'Illumination',
              description: '60 ~ 2,000lx (Min ~ Max)',
            },
          ]
        },
      ],
    },

    loadingKiosk: {
      title: 'Loading Kiosk',
      spec: [
        {
          name: ' ',
          detail: [
            {
              title: 'Dimensions',
              description: '720 x 800 x 611 (W x D x H)',
            },
            {
              title: 'Processing Capacity',
              description: '20L/30L',
            },
            {
              title: 'Display',
              description: '10.1 Inch Multi-touch Display',
            },
            {
              title: 'Network',
              description: 'Ethernet (100Base-TX)/Wi-Fi',
            },
            {
              title: 'Power',
              description: '100~240VAC/120W',
            },
          ]
        },
      ],
    },

    cobot: {
      title: 'Cobot',
      spec: [
        {
          name: ' ',
          detail: [
            {
              title: 'Dimensions',
              description: '220 x 227 x 1300 (W x D x H)',
            },
            {
              title: 'Max Reach',
              description: '1300mm',
            },
            {
              title: 'Max Load',
              description: '12kg',
            },
            {
              title: 'Degrees of Freedom',
              description: '60DoF',
            },
            {
              title: 'Network',
              description: 'Ethernet (100Base-TX)',
            },
            {
              title: 'Power',
              description: '100~240VAC/1kW',
            },
          ]
        },
      ],
    },



    apart: {
      title: 'Multi',
      list: [
        { text: "accesscontroller" },
        { text: "serverpc" },
      ]
    },

    assetTracking: {
      title: 'Multi',
      list: [
        { text: "twinTracker" },
        { text: "assetTagBle" },
        { text: "serverpcRTLS" },
      ]

    },

    workerTracking: {
      title: 'Multi',
      list: [
        { text: "twinTrackerPro" },
        { text: "tagUwb" },
        { text: "serverpcRTLS" },
      ]

    },

    robotBasic: {
      title: 'multi',
      list: [
        { text: 'deliveryRobot' },
        { text: 'twinTrackerPro' },
        { text: 'tagUwb' },
        { text: 'serverpc' },
      ]
    },

    delivery: {
      title: 'multi',
      list: [
        { text: 'deliveryRobot' },
        { text: 'loadingKiosk' },
        { text: 'cobot' },
        { text: 'twinTrackerPro' },
        { text: 'tagUwb' },
        { text: 'serverpc' },
      ]
    },

    disinfection: {
      title: 'multi',
      list: [
        { text: 'deliveryRobot' },
        { text: 'loadingKiosk' },
        { text: 'cobot' },
        { text: 'twinTrackerPro' },
        { text: 'tagUwb' },
        { text: 'serverpc' },
      ]
    }



  }


}