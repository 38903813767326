

import React, { PropsWithChildren } from "react";
import { Trans } from "react-i18next";


import {
  StyledDownloadDesc,
} from "./ModalDownload.style";

import { DialogBorder, DialogBox, DialogBtn, DialogBtnBox, ModalContainer } from "./Modal.style";
import { IconXMark } from "@assets/images/icon";
import { StyledDownloadBtnlText } from "@pages/Enterprise/Download/ModalDone/ModalDoneText.style";
import { DivFlexRow, DivWidth100 } from "@pages/CommonStyle/commonDiv.style";
import { CloseBtn } from "@pages/CommonStyle/commonBtn";
import { Backdrop } from "@pages/Enterprise/Download/Modal/Modal.style";
import { Spacing } from "@pages/CommonStyle/common.style";
import { StyledHeading } from "@components/Text";


interface ModalDefaultType {
  onClickToggleModal: () => void;
}


function ModalDone({
  onClickToggleModal,
}: PropsWithChildren<ModalDefaultType>) {

  const handleModalClose = () => {
    onClickToggleModal();
  }
  

  
  return (

    <ModalContainer>
      <DialogBox>
        <DivWidth100>

          <DivFlexRow >
            <StyledHeading size="small"> <Trans i18nKey={`solutionCommon.modal.done.title`} /> </StyledHeading>
            <Spacing pixel="8"/>
            <CloseBtn src={IconXMark}
            onClick={onClickToggleModal} size={"20"} alt="x mark icon"/>
          </DivFlexRow>
          
          <DialogBorder/>
          
          <Spacing pixel="4"/>
          <StyledDownloadDesc size="medium"> <Trans i18nKey={`solutionCommon.modal.done.desc`} /> </StyledDownloadDesc>
          
          <DialogBtnBox>

            <DialogBtn onClick={handleModalClose} bgcolor={"#000"}>
                <StyledDownloadBtnlText size="medium" color={"#fff"} ><Trans i18nKey={`solutionCommon.modal.done.confirm`} /> </StyledDownloadBtnlText>
            </DialogBtn>


          </DialogBtnBox>


        </DivWidth100>
      </DialogBox>


      <Backdrop
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();

          if (onClickToggleModal) {
            onClickToggleModal();
          }
        }}
      />
      
    </ModalContainer>

    
  )


}


export default ModalDone;

