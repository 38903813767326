import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";
import digitaltwin from "@src/assets/images/digitaltwin/video/traffic.mp4";

export default {


    helmet: '| 스마트 시티(Smart City)란?',
    desc: '스마트 시티는 디지털 트윈 기술을 활용하여 도시의 물리적인 요소들을 디지털적으로 모델링하고, 이를 통해 도시의 인프라와 서비스를 지능적으로 관리하고 최적화하는 개념입니다. ',
    keywords: '스마트시티, 디지털 트윈, 디지털 전환, smart city, digital twin, digital transformation, 실시간 위치 추적, rtls, orbro, 오브로, 콩테크',

    ogTitle: '',
    ogDesc: '',
    ogSite: '스마트 시티(Smart City)란? | 디지털 트윈 플랫폼, 오브로',
    ogUrl: 'https://orbro.io/blog/what-is-smart-city',
    ogKey: '',
    ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDlPQWtEdPqpk5QG-xfimTOJPt6-WAQc9XeRZnvdeRnqh_rxt6qJtKZOqz_FUESiTUuXhz_YtQo1FyKz_g8EERCOUtHYJW3xiXXD2sNkav4DXtc1AZ-gu3i93GEY_jis-cy4O6GH1tGpeZ4nKJVSR86PDIoBBZF_H0P4oJxW8cQnvXQuhCLFts7lyVvPB7kmVKa1OMU-JySPR1iSsikgxErasD7CSbVUFcgQ1ta5zV8ucSdKb-wJcos_kHcj1LwrC_ZcOPRVd1aLU-qqBrA0MQpdzUPwdSap8VNPoTP5KEvrZFDmYEqXdDHW70S-815Duo6uAP5itzyDvT7LMIj6PJsucX39uXQuIgna2GZVvnFvV5Mzn0Ahzj6YGbEc4_-R8dKbmd7l-xQQn97-pBq93VtBRpWnsE8CZtKKzUe5hgvhKVKYxiG-xYBSdR5ofDnF7aW1M1I93_h77nSfLcx1Tt19ZtokM9H-Rd655Ovi9zrvx9HHZbvCCDwce6It0IKoyJahzNaatP9RxcCbIHXKs64i1a78WRZIGcAF54tvf702cITAZp-Z3Jie9aUOtwKlb0_egIbCBZGu5dQ9h7I-nlDcj8nk-6y7h-JOYwYNUsUUW5B-3Vl6Sfu6KR25Oq7YrQo7nAbbRk8cdqfGGyXkcugF1RVkYybD-qq4oTRAbsSaOtltD9IuMouwBm4zeQ9JaL_4i-bpnX2Ag-rFmjOb9ylnE5cwozwljUHSITuoJOTbj9KtFqzMIoHoG9BkgSaLSmTnEhUeScVp3hhhJXkJTM4xvgRTDSboBXdC7C2aFpLaZZ-YqsxRUa5DuIKjdfOFccVaL0YhoSD1GrckiDXmEwZNa8il2qaWLbSFfLYPtF6gjK79kIta-EJplhWiFs4ONxbwmyZIER1m1dLRj5ALQgPqrHSTit4coflnZ--wlKrOhTRcrgiw7IXdbPboS_-3zlQi2dK5Mrxy-cJHOIRWDfvAG0Cjf77xz86tvDC7feQY_926zrWMX8xOGH2mMmzXVjcLzTD45Sw6_cUUsdLakivhUrXMSbtL_Vgg9pCsTx1oLfwtYUF0ugK5dO7BhRojQZoxBIopnkdCm8uq2vmhq94jKesQediuYNGhMGoLE3_a2Bxd48cIhzQgHb2QGvE1eDvT2P4GldVE8p17vZhFvEmv3lXu-7RAKx_PaUzPXRbJ8UUESSX7ifbfg54onDWKtJ7qJ6dkZ2mSlhuKdEe9G9rF0CG8BE1JPI2MeZC4yR6-iQNGwzWrcicyyBTqkotflfSmhEV_Rrqpg1avycEeajNafwvwEUC1yZzShi15JDuVdDGvgVH2hZVKM3pOZ1JxEuwtUbqP-BFkg5aBtbALWMImy4XQYiSkd3EEdQPa02OfyER8CLl0Qy7g6czv2zTAukCrY7PE4v6VAhtPwV6cI7At2KAheOe7VZNPThDj5qCKG3duCLJl4Rbkqyrt0s7WFzlAke5TDxhEE_9ta0JtMzjrPZkQWcACu_7KOu3wXz7cpHrv906MtZzK83YCoVHtfCjmXVjrU0ArV66WqRuUbz9OCBOsngRV=w2152-h1214',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '스마트 시티(Smart City)란?',
    date: '2024-01-08',
    tag1: '#스마트시티',
    tag2: '#디지털트윈',
    tag3: '#디지털전환',
    tag4: '#미래도시',

    // <br/>
    text: [
        {
            head: '스마트 시티(Smart City)란?',
            subHead: '',
            desc: '스마트 시티는 디지털 트윈 기술을 활용하여 도시의 물리적인 요소들을 디지털적으로 모델링하고, 이를 통해 도시의 인프라와 서비스를 지능적으로 관리하고 최적화하는 개념입니다. 이는 전통적인 도시에서 발생하는 다양한 문제를 해결하고, 지속 가능하고 혁신적인 도시를 구축하기 위한 노력의 일환으로 떠오르게 되었습니다.<br/><br/>스마트시티는 다양한 기술과 정보통신기술을 활용하여 도시의 다양한 측면을 향상시키는 것을 목표로 합니다. 이는 기존 도시의 문제점을 개선하고, 미래 도시의 지속 가능성과 혁신을 위한 새로운 패러다임으로 인식되고 있습니다. 주요 기술로는 인터넷 of Things(IoT), 빅데이터, 인공지능(AI), 자율주행차 등이 있습니다.',
            dot: ''
        },
        {
            head: '스마트시티 핵심 기술',
            subHead: '',
            desc: '스마트시티의 구현에는 다양한 고급 기술이 활용되며, 이러한 기술들이 통합되어 도시의 지능화를 실현합니다. 주목받는 핵심 기술들은 다음과 같습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '사물인터넷 (IoT)',
            desc: '스마트시티의 핵심 요소 중 하나는 사물인터넷 (IoT) 기술입니다. 이는 다양한 사물에 센서를 적용하고 인터넷을 통해 연결함으로써 실시간 데이터 수집 및 공유를 가능케 합니다. 도시 내의 빌딩, 교통체계, 환경 등 여러 부문에서 수집된 데이터는 중앙 시스템에서 통합되어 분석되어 도시의 상황을 실시간으로 이해할 수 있게 합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '빅데이터',
            desc: '데이터는 스마트시티의 핵심 자원 중 하나로, 빅데이터 기술은 이를 효과적으로 활용하는 데 필수적입니다. 대량의 데이터를 신속하게 수집, 저장, 분석함으로써 도시의 트렌드와 패턴을 파악하고 예측하는 능력을 갖춥니다. 빅데이터 분석을 통해 도시 기능의 최적화와 문제의 조기 발견이 가능해지며, 이는 의사결정과 계획 수립에 큰 도움을 줍니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '인공지능 (AI)',
            desc: '빅데이터와 함께 사용되는 인공지능 기술은 스마트시티를 지능적으로 만드는 데 핵심적인 역할을 합니다. 기계 학습 및 딥러닝을 활용하여 데이터를 분석하고 도시의 시스템을 최적화합니다. AI 기술은 교통 흐름 최적화, 에너지 소비 예측, 자동화된 시스템 운영 등 다양한 영역에서 스마트시티의 효율성을 높입니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '5G 네트워크',
            desc: '스마트시티에서는 대량의 데이터를 빠르게 전송하고 처리해야 합니다. 5세대 이동통신 기술인 5G는 초고속의 데이터 전송과 낮은 지연 시간을 제공하여 스마트시티의 다양한 기술들이 실제로 효과적으로 동작할 수 있도록 지원합니다. 5G는 자율 주행 차량, 스마트 인프라, 산업용 로봇 등과 같은 기술들을 가능케 하는 핵심적인 통신 기반을 제공합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '블록체인',
            desc: '스마트시티에서 데이터의 안전한 보호와 공유는 중요한 문제입니다. 블록체인 기술은 분산형 데이터베이스를 통해 데이터의 안전성과 무결성을 확보하면서 동시에 투명하고 안전한 거래를 가능케 합니다. 이를 통해 스마트시티의 다양한 부문에서 안전하게 데이터를 공유하고 활용할 수 있습니다.',
            dot: ''
        },
        {
            video : digitaltwin,
            link : 'https://orbro.io/digital-twin',
            videoDesc : '(영상을 누르면, 오브로 디지털 트윈 페이지로 이동합니다.)',
            head: '스마트시티의 장점',
            subHead: '효율적인 자원 관리',
            desc: '스마트시티는 빅데이터, 사물인터넷 (IoT), 그리고 인공지능 (AI) 기술을 활용하여 자원을 효율적으로 관리합니다. 에너지, 수자원, 교통 등 다양한 자원에 대한 데이터 수집과 분석을 통해 최적의 운영 방식을 찾아내어 에너지 소비를 줄이고 자원의 낭비를 최소화합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '교통 흐름 최적화',
            desc: '스마트시티는 실시간 데이터 수집과 분석을 통해 교통 흐름을 최적화하여 도로 혼잡을 감소시킵니다. 스마트 신호등, 자율 주행 차량, 대중교통 시스템의 향상 등을 통해 교통 효율성을 높여 시민들의 이동을 편리하게 만듭니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '환경 지속가능성 강화',
            desc: '스마트시티는 에너지 효율성을 높이고 재생에너지를 적극적으로 활용하여 환경에 대한 부담을 줄입니다. 또한, 공기 및 물의 품질을 실시간으로 모니터링하여 환경 문제에 대한 조기 대응이 가능하게 합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '경제적 활성화',
            desc: '스마트시티 구현은 새로운 기술 및 서비스 산업을 촉진하여 일자리를 창출하고 경제적 활성화를 이끌어냅니다. 스마트시티 프로젝트에는 다양한 산업 분야의 기업 및 전문가들이 참여하며 혁신적인 기술과 비즈니스 모델을 개발하는 과정에서 산업 생태계가 확장됩니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '비상 상황 대응 능력 강화',
            desc: '스마트시티는 자연 재해, 사회적 비상사태 등에 대비하여 효과적인 비상 대응 시스템을 구축합니다. 실시간 데이터 수집과 분석은 재해 예측 및 관리에 도움을 주며, 시민들에게 신속하게 안전 정보를 전달하여 대처 능력을 강화합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '도시 경쟁력 강화',
            desc: '스마트시티는 현대적이고 혁신적인 이미지를 조성하여 도시의 국제적인 경쟁력을 향상시킵니다. 선진 기술과 첨단 인프라는 글로벌 기업과 인재를 유치하며, 국제적인 투자를 유치하여 도시의 경제적 발전을 이끌어냅니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '지속가능한 도시 발전',
            desc: '스마트시티의 장점은 지속가능한 도시 발전을 실현하는 데에 기여합니다. 환경, 경제, 사회적 측면에서의 지속가능성을 고려하여 도시를 계획하고 운영함으로써 미래 세대를 위한 지속가능한 발전이 가능해집니다.',
            dot: ''
        },
        {
            head: '스마트 시티 작동 원리',
            subHead: '센서와 IoT의 활용',
            desc: '스마트시티의 핵심은 센서와 사물인터넷 (IoT) 기술의 활용입니다. 도시 내 곳곳에 설치된 센서들은 환경, 교통, 에너지 사용 등과 관련된 데이터를 실시간으로 수집합니다. 이 데이터는 도시의 다양한 측면을 모니터링하고 문제를 조기에 감지하는 데 사용됩니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '지속가능한 도시 발전',
            desc: '스마트시티의 장점은 지속가능한 도시 발전을 실현하는 데에 기여합니다. 환경, 경제, 사회적 측면에서의 지속가능성을 고려하여 도시를 계획하고 운영함으로써 미래 세대를 위한 지속가능한 발전이 가능해집니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '인공지능과 기계 학습의 활용',
            desc: '빅데이터와 함께 사용되는 인공지능 (AI) 및 기계 학습 기술은 데이터를 분석하고 도시의 시스템을 최적화합니다. 예측 분석을 통해 교통 흐름을 최적화하거나 에너지 소비를 예측하여 효율적으로 관리할 수 있습니다. AI는 도시에서 발생하는 복잡한 문제에 대한 창의적이고 지능적인 해결책을 찾아냅니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '스마트 인프라 구축',
            desc: '스마트시티는 스마트 인프라를 구축하여 도시의 여러 부분을 연결합니다. 스마트 그리드는 전력을 효율적으로 관리하고, 스마트 교통체계는 교통 흐름을 최적화하며, 스마트 빌딩은 에너지 사용을 최소화합니다. 이러한 스마트 인프라는 도시의 여러 측면을 통합적으로 관리하여 지속 가능한 발전을 이끌어냅니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '사물인터넷과 상호 연결된 기기들',
            desc: 'IoT를 통해 상호 연결된 기기들이 스마트시티를 구성합니다. 스마트한 가전제품, 차량, 건물 등이 상호 연결되어 정보를 주고받고, 동작을 조절함으로써 도시의 자원을 효율적으로 활용하고 편의성을 높입니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '시민 참여와 디지털 서비스 제공',
            desc: '시민들은 스마트시티에 디지털 서비스를 통해 적극적으로 참여할 수 있습니다. 모바일 애플리케이션을 통해 도시 정보에 접근하고, 문제를 신고하며, 참여하는 등의 활동을 통해 시민들은 도시의 운영에 직접 참여할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '지능형 도시 운영 시스템',
            desc: '모든 데이터와 기기들이 통합된 지능형 도시 운영 시스템은 도시의 다양한 부분을 효율적으로 운영하고 최적의 서비스를 제공합니다. 교통 흐름 최적화, 에너지 효율 관리, 환경 모니터링 등이 자동으로 이루어지며, 도시를 지능적으로 운영합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '지속적인 개선과 평가',
            desc: '스마트시티는 지속적인 개선과 평가를 통해 발전합니다. 시민들의 피드백을 수용하고 기술적인 혁신을 도입하여 도시의 요구에 부응하며, 지속 가능한 스마트시티를 유지하고 발전시킵니다.',
            dot: ''
        },
        {
            head: '스마트 시티 전망',
            subHead: '',
            desc: '스마트시티는 지속 가능한 발전과 혁신적인 기술 도입을 통해 미래 도시의 틀을 형성하고 있습니다. 에너지 효율성과 환경 보호에 중점을 두며, 신재생 에너지와 친환경 교통 시스템의 활용이 더욱 강화될 것으로 전망됩니다. 또한, 빅데이터, 인공지능, 사물인터넷 등의 혁신적인 기술이 더욱 적극적으로 도입되어 도시의 다양한 부분을 지능적으로 관리하며 시민들의 편의성을 높일 것으로 예측됩니다. 스마트시티는 미래 도시의 핵심 특징으로서, 지속적인 발전과 기술 혁신을 통해 도시의 경쟁력을 강화할 것으로 전망됩니다.',
            dot: ''
        },
        {
            linkText : '오브로 월드 체험하기',
            link : 'https://home.orbro.io',
            head : '',
            subHead : '',
            desc: '',
            dot : '',
        },

    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/enterprise/construction/safety'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/enterprise/manufacturing/asset'
            }
        ]

    }


}