import { FlexStyle, Vertical } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledLabel, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";
import { StyledGridColumnProps } from '@components/Grid/Grid.model';

interface LayoutInfo {
  breakPoint: number;
  padding: number;
  gap: number;
  columns: number;
}

const DESKTOP: LayoutInfo = {
  breakPoint: 1280,
  gap: 32,
  padding: 24,
  columns: 12,
};

const TABLET: LayoutInfo = {
  breakPoint: 960,
  gap: 32,
  padding: 24,
  columns: 8,
};

const MOBILE: LayoutInfo = {
  breakPoint: 768,
  gap: 32,
  padding: 16,
  columns: 4,
};





export const StyledGridColumnBlog = styled.div<StyledGridColumnProps>`
  position: relative;
  display: grid;

  -ms-grid-column-span: ${({ desktop }) => desktop};
  grid-column-end: span ${({ desktop }) => desktop};

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    -ms-grid-column-span: ${({ tablet }) => tablet};
    grid-column-end: span ${({ tablet }) => tablet};
    margin-left : 24px;
    margin-right : 24px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    -ms-grid-column-span: ${({ mobile }) => mobile};
    grid-column-end: span ${({ mobile }) => mobile};
    margin-left : 16px;
    margin-right : 16px;
  }
`;




export const StyledBack = styled.div`
  margin-top : 48px;
  margin-bottom : 48px;
  height : 20px;


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding : 24px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    display : none;
    margin-top : 0px;
    height : 0px;
  }

`;




export const StyledBackArrow = styled.img`
  cursor : pointer;
  color : black;
  width : 20px;
  height : 20px;


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-left : 24px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 0px;
  }
`;



export const StyledBackBtn = styled(StyledHeading)`
  cursor : pointer;
  color : black;

  margin-left : 16px;

  line-height: 20px;


`;




export const StyledTitleBanner = styled.div`
  width: 100%;
  height: 500px;
  border-radius: 24px;
  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: darken;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    border-radius : 0px;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    border-radius : 0px;
  }
`;

export const StyledBannerImg = styled.img`
  z-index : 0;
  width : 100%;
  height : 500px;
  object-fit : cover;
  border-radius : 24px;
  
  filter: brightness(50%);

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    border-radius : 0px;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    border-radius : 0px;
  }

`

export const StyledBannerData = styled.div`

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-left : 24px;
  }
`

export const StyledIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top : 16px;
  margin-bottom : 24px;
  
  height : 32px;
  gap : 8px;


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    justify-content: flex-start;
  }


`


export const StyledIconBoxBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top : 16px;
  margin-bottom : 24px;
  height : 32px;
  gap : 8px;
`




export const StyledBackup = styled.a`
  width : 32px;
  height : 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius : 16px;
  background-color : #3676B0;
  padding: 9.2px;
  &:hover {
    cursor: pointer;
  }
`


export const StyledLinkedIn = styled.div`
  width : 32px;
  height : 32px;
  border-radius : 16px;
  &:hover {
    cursor: pointer;
  }
`


export const StyledTwitter = styled.div`
  width : 32px;
  height : 32px;
  border-radius : 16px;

  &:hover {
    cursor: pointer;
  }
`


export const StyledFacebook = styled.div`
  width : 32px;
  height : 32px;
  border-radius : 16px;
  

  &:hover {
    cursor: pointer;
  }
`


export const StyledIcon = styled.img`
  max-width: 100%;
  max-height: 100%;

`


export const StyledTagBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap : wrap;

  gap: 16px;


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    justify-content: flex-start;
    align-items: flex-start;
  }

`


export const StyledTagBoxBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap : wrap;

  gap: 16px;


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
  }


`


export const StyledTag = styled(StyledLabel)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 16px;

  color : white;
  background: rgba(255, 255, 255, 0.03);

  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;


`


export const StyledTagBottom = styled(StyledLabel)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 16px;

  color : black;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;


`



export const StyledBlogShareBox = styled(StyledHeading)`
  width : 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align : center;
  font-weight: 600;
  color : #818181;

`



export const StyledBlogParaCenter = styled(StyledParagraph)`
text-align : center; 
font-weight : 500;

`;

export const StyledBlogParaSub = styled(StyledParagraph)`
  margin-top : 8px;
  font-weight : 400;

  text-indent: -1em;
  margin-left: 1em;
`;



export const StyledBlogDateBox = styled.div`
  display : flex;
  margin-top: 16px;
  justify-content : center;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    justify-content : flex-start;
    
  }

`;

export const StyledBlogDate = styled(StyledLabel)`

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #BDBDBD;
  margin-left : 4px;
`;



export const StyledSpacing64Border = styled.div`
  padding-top : 64px;
  border-bottom: 1px solid ${({ theme }) => theme.BORDER_TRANSLUCENT};

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-right : 24px;
    margin-left : 24px  
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-right : 16px;
    margin-left : 16px;
  }
`


export const StyledProductBox = styled.div`
  width : 100%;
  display : flex;
  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    display : flex;
    flex-direction : column;
    width : auto;
  }
`


export const StyledProductTextSide = styled.div`
  width : 50%;
  padding : 64px 0;
  
  margin-right : 32px;
  word-wrap: break-word;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
    padding-top : 32px;
    padding-bottom : 0px;
    
    margin-right : 0px;
    margin-bottom : 32px;
  }
`

export const StyledProductImgBox = styled.div`

  width : 50%;
  padding : 34px 38px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
    padding : 0px;
    height: 280px;
  }

`

export const StyledProductImg = styled.img`

  width: 100%;
  height: 100%;
  object-fit : contain;
  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height: 280px;
  }

`

export const StyledSolutionLayout = styled.div`
  display : flex;
  margin-bottom : 64px;
  height : auto;

  
  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-left : 24px;
    margin-right : 24px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    display : flex;
    flex-direction : column;
    margin : 0;
    padding-bottom : 64px;
    padding-left: 16px;
    padding-right : 16px;
  }

`

export const StyledSolutionBox = styled.div`
  flex: 1;
  margin-right: 32px;
  min-height : 380px;
  padding-bottom : 40px;  

  &:last-child {
    margin-right: 0;
  }


  border-radius : 16px;
  &:hover {
    cursor : pointer;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-right : 0;
    margin-top : 16px;
    margin-bottom : 32px;
    
    height : auto;

    &:first-child {
      margin-top: 0;
    }
  }

`

export const StyledSolutionTextBox = styled.div`
  padding : 8px;
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding : 0px;
  }
`

export const StyledShowMore = styled.div`
  display : flex;
  flex-direction : row;

  margin-top: 32px;
  align-items : center;
  height : auto;
  cursor : pointer;
  max-width : 120px;
  
`


export const StyledSolutionImg = styled.img`
  width : 100%;
  height : 220px;
  object-fit : cover;
  border-radius: 12px;
  border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    min-height : 220px;
  }

`

export const BlogVisual = styled.div`
  margin-top : 24px;
  width : 100%;
  display : flex;
  align-items : center;
  justify-content : center;
  object-fit : contain;
`;

export const BlogImgBox = styled.div`
  width : 100%;
  display : flex;
  align-items  : center;
  cursor : pointer
`;

export const BlogImg = styled.img`
  margin : auto;
  width : 100%;
  border-radius : 12px;
  border : 1px solid rgba(0,0,0,0.08);
`;




export const BlogTextLink = styled(StyledHeading)`
  text-decoration : underline;
  width : fit-content;
  cursor : pointer
`;

export const BlogBannerText = styled.div`
  width: 100%;
  display : flex;
  flex-direction : column;
  text-align : center;
  align-items : center;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    align-items : start;
  }
`;