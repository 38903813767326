export default {
  
  titleBanner: {
    subtitle: 'TWR | Two-Way Ranging',
    title: 'TWR位置測位技術',
    description: '*TWR（Two-Way Ranging）技術は、送信機と受信機間を行き来する信号の往復時間を測定して距離を計算する方法です。'
  },
  definition: {
    title: 'TWR技術とは何ですか？',
    text: 'TDoAは、複数の固定アンカーがモバイルオブジェクトの位置を決定するために協力するのに対し、TWRは主に2つのデバイス間の双方向通信を使用します。たとえば、人や物品、車に配置された位置測位タグのリアルタイム距離モニタリングが可能です。これにより、インフラストラクチャを必要とせずに自律的な衝突警告システム、近接検知などの双方向通信ベースのアプリケーションを実装できます。<br/>TWRでは、2つのデバイス間の距離をリアルタイムに監視するために、位置測位タグなどの2つのデバイス間の双方向通信を使用します。そのため、追加のインフラストラクチャなしで自律的な衝突検出システム、近接検出などの双方向通信ベースのアプリケーションを実装できます。<br/>TWRでは、2つのデバイスが近づくと、2つのデバイスは互いにレンジングを開始して距離を決定します。そして、信号が移動するのにかかる時間を光速で掛けることで、相対的な位置を持続的に決定します。これにより、位置認識通信やサービスを実現できることがあります。<br/><br/>距離を測定するためには、3つのメッセージを交換する必要があります：<br/><br/>',
    list: [
      '· タグなどの距離測定デバイスは、近くのタグや他のRTLSノードに「Poll」メッセージを送信してTWRを初期化します。',
      '· 近くのデバイスは、「Poll」の受信時間を記録し、「Response」メッセージで応答します。',
      '· タグは応答メッセージを受け取ると、応答の受信時間を記録します。'
    ],
    text2: '<br/> 最終的なメッセージの受信時間を基に、タグはToF（Time of Flight）と相互の距離を決定できます。'
  },
  advantage: {
    title: 'TWRの主な利点',
    items: {
      realTime: {
        title: 'リアルタイム',
        description: 'TWR技術は、非常に低いレイテンシ（2-5 ms）と高い更新速度（最大100Hz）により、正確なリアルタイムの距離測定アプリケーションを可能にします。'
      },
      twoWay: {
        title: 'さまざまなTwo-Way機能の実現',
        description: '衝突回避システム、近接検知など、距離測定に関連する機能を提供できます。'
      },
      flexible: {
        title: '柔軟性',
        description: '追加のインフラストラクチャなしで、2つのデバイス間のP2P（ピアツーピア）レンジアプリケーションを提供します。'
      }
    }
  },
}