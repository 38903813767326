import {
  Blog01, Blog01T, Blog01M,
  
  Blog03, Blog03T, Blog03M,
  Blog04, Blog04T, Blog04M,
  
  Blog06, Blog06T, Blog06M,
  Blog07, Blog07T, Blog07M,
  Blog08, Blog08T, Blog08M,
  Blog09, Blog09T, Blog09M,
  
  Blog13, Blog13T, Blog13M,
  
  Blog17, Blog17T, Blog17M,
  
  Blog19, Blog19T, Blog19M,
  
  Blog21, Blog21T, Blog21M,
  
  Blog24, Blog24T, Blog24M,
  
  Blog27, Blog27T, Blog27M,
  
  Blog29, Blog29T, Blog29M,
  
  Blog32, Blog32T, Blog32M, 
  Blog33, Blog33T, Blog33M, 
  Blog34, Blog34T, Blog34M,
  Blog35, Blog35T, Blog35M,
  Blog36, Blog36T, Blog36M,
  Blog37, Blog37T, Blog37M,
  Blog38, Blog38T, Blog38M,
  Blog39, Blog39T, Blog39M,
  Blog40, Blog40T, Blog40M,
  Blog41, Blog41T, Blog41M,
  Blog42, Blog42T, Blog42M,
  Blog43, Blog43T, Blog43M,
  Blog44, Blog44T, Blog44M, 
  Blog45, Blog45T, Blog45M, 
  
  Blog47, Blog47T, Blog47M, 
  
  Blog49, Blog49T, Blog49M, 
  
  Blog51, Blog51T, Blog51M,
  Blog52, Blog52T, Blog52M,
  Blog53, Blog53T, Blog53M,
  Blog54, Blog54T, Blog54M,   
  Blog55, Blog55T, Blog55M,
  Blog56, Blog56T, Blog56M, 
  Blog57, Blog57T, Blog57M, 
  Blog58, Blog58T, Blog58M, 
  Blog59, Blog59T, Blog59M, 
  Blog60, Blog60T, Blog60M, 
  Blog61, Blog61T, Blog61M, 
  Blog62, Blog62T, Blog62M, 
  Blog63, Blog63T, Blog63M, 
  Blog64, Blog64T, Blog64M, 
  Blog65, Blog65T, Blog65M, 
  Blog66, Blog66T, Blog66M,
  Blog67, Blog67T, Blog67M, 
  Blog68, Blog68T, Blog68M, 
  Blog69, Blog69T, Blog69M, 
  Blog70, Blog70T, Blog70M, 
  Blog71, Blog71T, Blog71M, 
  Blog79, Blog79T, Blog79M, 
  Blog80, Blog80T, Blog80M, 
  Blog81, Blog81T, Blog81M, 
  Blog82, Blog82T, Blog82M,
  Blog83, Blog83T, Blog83M, 
  Blog84, Blog84T, Blog84M, 
  Blog85, Blog85T, Blog85M, 
  Blog86, Blog86T, Blog86M, 
  Blog87, Blog87T, Blog87M,
  Blog88, Blog88T, Blog88M, 
  Blog89, Blog89T, Blog89M, 
  Blog90, Blog90T, Blog90M,
  Blog91, Blog91T, Blog91M, 
  Blog92, Blog92T, Blog92M, 
  Blog93, Blog93T, Blog93M, 
  Blog94, Blog94T, Blog94M, 
  Blog95, Blog95T, Blog95M, 
  Blog96, Blog96T, Blog96M,
  Blog97, Blog97T, Blog97M, 
  Blog98, Blog98T, Blog98M, 
  Blog99, Blog99T, Blog99M,
  
  
} from "@assets/images/blog";

// 1 - 5
export const blogRTLS = {
  desktop: Blog01,
  tablet: Blog01T,
  mobile: Blog01M,
}

/*
export const blogSafety = {
    desktop: Blog02,
    tablet: Blog02T,
    mobile: Blog02M,
}
*/

export const blogDigitalTwin = {
    desktop: Blog03,
    tablet: Blog03T,
    mobile: Blog03M,
}
export const blogUwb = {
    desktop: Blog04,
    tablet: Blog04T,
    mobile: Blog04M,
}
/*
export const blogAsset = {
    desktop: Blog05,
    tablet: Blog05T,
    mobile: Blog05M,
}*/

// 6 - 10
export const blogGeofence = {
    desktop: Blog06,
    tablet: Blog06T,
    mobile: Blog06M,
}

export const blogMedical = {
  desktop: Blog07,
  tablet: Blog07T,
  mobile: Blog07M,
}

export const blogUwbble = {
  desktop: Blog08,
  tablet: Blog08T,
  mobile: Blog08M,
}

export const blogAirport = {
  desktop: Blog09,
  tablet: Blog09T,
  mobile: Blog09M,
}

/*
export const blogAlone = {
  desktop: Blog10,
  tablet: Blog10T,
  mobile: Blog10M,
}


// 11 - 15
export const blogIot = {
  desktop: Blog11,
  tablet: Blog11T,
  mobile: Blog11M,
}

export const blogCorona = {
desktop: Blog12,
tablet: Blog12T,
mobile: Blog12M,
}
*/

export const blogManufacture = {
desktop: Blog13,
tablet: Blog13T,
mobile: Blog13M,
}

/*
export const blog4th = {
desktop: Blog14,
tablet: Blog14T,
mobile: Blog14M,
}

export const blogFactory = {
desktop: Blog15,
tablet: Blog15T,
mobile: Blog15M,
}



// 16 - 20
export const blogAnswer = {
  desktop: Blog16,
  tablet: Blog16T,
  mobile: Blog16M,
}*/

export const blogAdvantage = {
desktop: Blog17,
tablet: Blog17T,
mobile: Blog17M,
}

/*
export const blogElder = {
desktop: Blog18,
tablet: Blog18T,
mobile: Blog18M,
}
*/

export const blogActiveRfid = {
  desktop: Blog19,
  tablet: Blog19T,
  mobile: Blog19M,
}
  

/*
export const blogGse = {
  desktop: Blog20,
  tablet: Blog20T,
  mobile: Blog20M,
}
*/
    
      
// 21 - 25
export const blogForklift = {
  desktop: Blog21,
  tablet: Blog21T,
  mobile: Blog21M,
}

/*
export const blogBi = {
  desktop: Blog22,
  tablet: Blog22T,
  mobile: Blog22M,
}

export const blogPatient = {
  desktop: Blog23,
  tablet: Blog23T,
  mobile: Blog23M,
}
*/

export const blogConsider = {
  desktop: Blog24,
  tablet: Blog24T,
  mobile: Blog24M,
}
  
/*
export const blogHealthcare = {
  desktop: Blog25,
  tablet: Blog25T,
  mobile: Blog25M,
}
    
  
// 26 - 30
export const blogWarehouse = {
  desktop: Blog26,
  tablet: Blog26T,
  mobile: Blog26M,
}
*/

export const blogConstruction = {
  desktop: Blog27,
  tablet: Blog27T,
  mobile: Blog27M,
}

/*
export const blogMall = {
  desktop: Blog28,
  tablet: Blog28T,
  mobile: Blog28M,
}
*/

export const blogOffice = {
  desktop: Blog29,
  tablet: Blog29T,
  mobile: Blog29M,
}
  
/*
export const blogConstFuture = {
  desktop: Blog30,
  tablet: Blog30T,
  mobile: Blog30M,
}
    
// 31 - 35

export const blogAccess = {
  desktop: Blog31,
  tablet: Blog31T,
  mobile: Blog31M,
}
    
      
*/

export const blogSmartConstruction = {
  desktop: Blog32,
  tablet: Blog32T,
  mobile: Blog32M,
}

export const blogSmartLogistics = {
  desktop: Blog33,
  tablet: Blog33T,
  mobile: Blog33M,
}

      
export const blogPatientTracking = {
  desktop: Blog34,
  tablet: Blog34T,
  mobile: Blog34M,
}

      
export const blogManufacturing = {
  desktop: Blog35,
  tablet: Blog35T,
  mobile: Blog35M,
}

      
// 36 - 40

export const blogVisitor = {
  desktop: Blog36,
  tablet: Blog36T,
  mobile: Blog36M,
}


export const blogHospital = {
  desktop: Blog37,
  tablet: Blog37T,
  mobile: Blog37M,
}

export const blogSeniorCare = {
  desktop: Blog38,
  tablet: Blog38T,
  mobile: Blog38M,
}

export const blogSmartMedical = {
  desktop: Blog39,
  tablet: Blog39T,
  mobile: Blog39M,
}

export const blogMedicalWaste = {
  desktop: Blog40,
  tablet: Blog40T,
  mobile: Blog40M,
}

      
// 41 - 45
export const blogUwbGeofence = {
  desktop: Blog41,
  tablet: Blog41T,
  mobile: Blog41M,
}

export const blogLogistics = {
  desktop: Blog42,
  tablet: Blog42T,
  mobile: Blog42M,
}
export const blogUwbLogistics = {
  desktop: Blog43,
  tablet: Blog43T,
  mobile: Blog43M,
}

export const blogLogisticsWorker = {
  desktop: Blog44,
  tablet: Blog44T,
  mobile: Blog44M,
}

export const blogLogisticsAsset = {
  desktop: Blog45,
  tablet: Blog45T,
  mobile: Blog45M,
}

/*
// 46 - 50
export const blogSCM = {
  desktop: Blog46,
  tablet: Blog46T,
  mobile: Blog46M,
}
*/

export const blogLogisticsTech = {
  desktop: Blog47,
  tablet: Blog47T,
  mobile: Blog47M,
}

/*
export const blogConstructionRTLS = {
  desktop: Blog48,
  tablet: Blog48T,
  mobile: Blog48M,
}
*/

export const blogConstructionSafety = {
  desktop: Blog49,
  tablet: Blog49T,
  mobile: Blog49M,
}

/*
export const blogConstructionAsset = {
  desktop: Blog50,
  tablet: Blog50T,
  mobile: Blog50M,
}
*/

// 51 - 55
export const blogHeavyEquipment = {
  desktop: Blog51,
  tablet: Blog51T,
  mobile: Blog51M,
}
export const blogSmartSafety = {
  desktop: Blog52,
  tablet: Blog52T,
  mobile: Blog52M,
}
export const blogLogisticsSolution = {
  desktop: Blog53,
  tablet: Blog53T,
  mobile: Blog53M,
}
export const blogSmartFactory = {
  desktop: Blog54,
  tablet: Blog54T,
  mobile: Blog54M,
}
export const blogNextManufacturing = {
  desktop: Blog55,
  tablet: Blog55T,
  mobile: Blog55M,
}



// 56 - 60

export const blogOfficeRTLS = {
  desktop: Blog56,
  tablet: Blog56T,
  mobile: Blog56M,
}
export const blogOfficeAsset = {
  desktop: Blog57,
  tablet: Blog57T,
  mobile: Blog57M,
}

export const blogDigitalTwin2 = {
  desktop: Blog58,
  tablet: Blog58T,
  mobile: Blog58M,
}

export const blogOfficeSolution = {
  desktop: Blog59,
  tablet: Blog59T,
  mobile: Blog59M,
}
export const blogDX = {
  desktop: Blog60,
  tablet: Blog60T,
  mobile: Blog60M,
}


// 61 - 65

export const blogRtlsHealthcare = {
  desktop: Blog61,
  tablet: Blog61T,
  mobile: Blog61M,
}
export const blogUwbRtls = {
  desktop: Blog62,
  tablet: Blog62T,
  mobile: Blog62M,
}
export const blogProductionAutomation = {
  desktop: Blog63,
  tablet: Blog63T,
  mobile: Blog63M,
}
export const blogBeacon = {
  desktop: Blog64,
  tablet: Blog64T,
  mobile: Blog64M,
}
export const blogRfidNfcUwb = {
  desktop: Blog65,
  tablet: Blog65T,
  mobile: Blog65M,
}


// 66 - 70
export const blogRtlsCompany = {
  desktop: Blog66,
  tablet: Blog66T,
  mobile: Blog66M,
}
export const blogNfcUwbBle = {
  desktop: Blog67,
  tablet: Blog67T,
  mobile: Blog67M,
}
export const blogRfidRtls = { 
  desktop: Blog68,
  tablet: Blog68T,
  mobile: Blog68M,
}
export const blogConstructionSolution = { 
  desktop: Blog69,
  tablet: Blog69T,
  mobile: Blog69M,
}
export const blogQaUwb = { 
  desktop: Blog70,
  tablet: Blog70T,
  mobile: Blog70M,
}


// 71 - 80
export const blogDigitalTwinGis = { 
  desktop: Blog71,
  tablet: Blog71T,
  mobile: Blog71M,
}
export const blogDigitalTwinWeather = { 
  desktop: Blog71,
  tablet: Blog71T,
  mobile: Blog71M,
}
export const blogDigitalTwinBuildings = { 
  desktop: Blog71,
  tablet: Blog71T,
  mobile: Blog71M,
}
export const blogDigitalTwinTracking = { 
  desktop: Blog71,
  tablet: Blog71T,
  mobile: Blog71M,
}
export const blogDigitalTwinTraffic = { 
  desktop: Blog71,
  tablet: Blog71T,
  mobile: Blog71M,
}
export const blogDigitalTwinAirplane = { 
  desktop: Blog71,
  tablet: Blog71T,
  mobile: Blog71M,
}
export const blogDigitalTwinCCTV = { 
  desktop: Blog71,
  tablet: Blog71T,
  mobile: Blog71M,
}
export const blogDigitalTwinDigitalCity = { 
  desktop: Blog71,
  tablet: Blog71T,
  mobile: Blog71M,
}


export const blogDigitalTwin3 = { 
  desktop: Blog79,
  tablet: Blog79T,
  mobile: Blog79M,
}
export const blogBLE = { 
  desktop: Blog80,
  tablet: Blog80T,
  mobile: Blog80M,
}

// 81 - 85
export const blogWhatIsSmartCity = { 
  desktop: Blog81,
  tablet: Blog81T,
  mobile: Blog81M,
}
export const blogExampleOfSmartCity = { 
  desktop: Blog82,
  tablet: Blog82T,
  mobile: Blog82M,
}
export const blogContainerTracking = { 
  desktop: Blog83,
  tablet: Blog83T,
  mobile: Blog83M,
}
export const blogWhatIsDX = { 
  desktop: Blog84,
  tablet: Blog84T,
  mobile: Blog84M,
}
export const blogRFID = { 
  desktop: Blog85,
  tablet: Blog85T,
  mobile: Blog85M,
}


// 86 - 90
export const blogNFC = { 
  desktop: Blog86,
  tablet: Blog86T,
  mobile: Blog86M,
}
export const blogNVR = { 
  desktop: Blog87,
  tablet: Blog87T,
  mobile: Blog87M,
}
export const blogSmartFactory2 = { 
  desktop: Blog88,
  tablet: Blog88T,
  mobile: Blog88M,
}
export const blogLoRa = { 
  desktop: Blog89,
  tablet: Blog89T,
  mobile: Blog89M,
}
export const blogSmartHospital = { 
  desktop: Blog90,
  tablet: Blog90T,
  mobile: Blog90M,
}


// 91 - 95
export const blogSmartAirport = { 
  desktop: Blog91,
  tablet: Blog91T,
  mobile: Blog91M,
}
export const blogIPS = { 
  desktop: Blog92,
  tablet: Blog92T,
  mobile: Blog92M,
}
export const blogSmartParking = { 
  desktop: Blog93,
  tablet: Blog93T,
  mobile: Blog93M,
}
export const blogSmartPort = { 
  desktop: Blog94,
  tablet: Blog94T,
  mobile: Blog94M,
}
export const blogSmartBuildings = { 
  desktop: Blog95,
  tablet: Blog95T,
  mobile: Blog95M,
}



// 96 - 100
export const blogIndoorGPS = { 
  desktop: Blog96,
  tablet: Blog96T,
  mobile: Blog96M,
}
export const blogAiCamera = { 
  desktop: Blog97,
  tablet: Blog97T,
  mobile: Blog97M,
}
export const blogAIoT = { 
  desktop: Blog98,
  tablet: Blog98T,
  mobile: Blog98M,
}
export const blogSmartShipyard = { 
  desktop: Blog99,
  tablet: Blog99T,
  mobile: Blog99M,
}



