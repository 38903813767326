import { ParagraphColor, Spacing, SpacingDTM } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import digitaltwin from "@src/lang/ko/digitaltwin/digitaltwin";
import React from "react";
import { Trans } from "react-i18next";
import { StyledGridContainer } from "@components/Grid/Grid.style";
import { VideoRadius } from "./Video.style";
import { DisplayH2color } from "@components/Text/HeadingTagDisplay.style";



const Video = () => {

  return (
    <>
      <StyledGridContainer>
        <DivAllCenter>
          <DisplayH2color color="#FFF" size="small">
            <Trans i18nKey={`digitaltwin.video.title`} />
          </DisplayH2color>
          <Spacing pixel="8" />
          <ParagraphColor color="#E0E0E0" size="large">
            <p>
              <Trans i18nKey={`digitaltwin.video.desc`} />
            </p>
          </ParagraphColor>
          <Spacing pixel="32" />
          <VideoRadius
            autoPlay
            loop muted
            playsInline>
            <source src={digitaltwin.video.video} type="video/mp4" />
          </VideoRadius>

          <SpacingDTM d={"96"} t={"64"} m={"48"} />

        </DivAllCenter>
      </StyledGridContainer>
    </>
  )
}

export default Video;