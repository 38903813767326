import { Vertical } from "@assets/styled/flex";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyledUseCaseCard, StyledUseCaseCardIcon, StyledUseCaseCardItems } from "./UseCaseCard.style";
import { HeadingH3, StyledHeading } from "@components/Text/Heading/Heading.style";
import { Spacing } from "@pages/CommonStyle/common.style";
import { StyledParagraph } from "@components/Text";

interface UseCaseCardProps {
  icon: string,
  title: string,
  description: string,
  items: string,
  data: string
}

const UseCaseCard = (props: UseCaseCardProps) => {
  // Props
  const { icon, title, description, items, data } = props;

  // Translation
  const { t } = useTranslation();

  return (
    <StyledUseCaseCard desktop={4} tablet={4} mobile={4}>
      <Vertical>
        <StyledUseCaseCardIcon src={icon} alt="example icon" />
        <Spacing pixel="16" />
        <HeadingH3 size="small"> <Trans i18nKey={title} /></HeadingH3>
        <Spacing pixel="8" />
        <StyledParagraph size="medium"><p><Trans i18nKey={description} /></p></StyledParagraph>
        <Spacing pixel="4" />

        <StyledUseCaseCardItems>
          {(t(`${items}.useCase.card.${data}.items`, { returnObjects: true }) as any[]).map((item, index) => (
            <Vertical key={`usecase-card-${index}`}>
              <StyledHeading size="xxxsmall">
                <p>
                  <Trans i18nKey={item.name} />
                </p>
              </StyledHeading>
              {item.description && (
                <>
                  <Spacing pixel="4" />
                  <StyledParagraph size="small">
                    <p>
                      <Trans i18nKey={item.description} />
                    </p>
                  </StyledParagraph>
                  <Spacing pixel="8" />
                </>
              )}
            </Vertical>
          ))}

        </StyledUseCaseCardItems>
      </Vertical>
    </StyledUseCaseCard>
  );
};

export default UseCaseCard; 