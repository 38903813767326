import { accessAuto1, accessAutoMain, accessPro1, accessProMain, accesscontroller1, accesscontrollerMain } from "@assets/images/main/access/product";

export default {

  specTitle: {

  },

  specData: [
    {
      data: [
        { title: "产品名" },
        { text1: "移动出入" },
        { text2: "自动门出入" },
        { text3: "人脸识别出入" },
      ],
    },
    {
      data: [
        { text: "说明" },
        { text: "使用移动应用程序控制出入门非常方便的设备" },
        { text: "无需启动应用程序即可方便出入的设备" },
        { text: "人脸识别、NFC卡等多种出入方式的设备" },
      ],
    },
    {
      data: [
        { text: "电源供应" },
        { text: "使用电池（最长3年使用）" },
        { text: "DC 12V, 24V" },
        { text: "DC 12V, 3A" },
      ],
    }
  ],

  a: [
    { text: "产品名" },
    { text: "说明" },
    { text: "电源供应" },
    { text: "通信方式" },
    { text: "网络" },
    { text: "规格" },
    { text: "安装支持" },
    { text: "人员追踪" },
    { text: "一次通行功能" },
    { text: "实时设备监控" },
    { text: "远程控制" },
    { text: "NFC出入" },
    { text: "人脸识别出入" },
    { text: "QR码出入" },
  ],

  b: [
    { text: "移动出入" },
    { text: "使用移动应用程序控制出入门非常方便的设备" },
    { text: "使用电池（最长3年使用）" },
    { text: "Bluetooth, BLE" },
    { text: "不需要" },
    { text: "85.4 x 85.4 x 24.5(mm)" },
    { text: "" },
    { text: "" },
    { text: "" },
    { text: "" },
    { text: "" },
    { text: "" },
    { text: "" },
    { text: "" },
  ],

  c: [
    { text: "自动门出入" },
    { text: "无需启动应用程序即可方便出入的设备" },
    { text: "DC 12V, 24V" },
    { text: "Bluetooth, BLE" },
    { text: "不需要" },
    { text: "210.1 x 60.1 x 42.2(mm)" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "" },
    { text: "" },
    { text: "" },
    { text: "" },
    { text: "" },
  ],

  d: [
    { text: "人脸识别出入" },
    { text: "人脸识别、NFC卡等多种出入方式的设备" },
    { text: "DC 12V, 3A" },
    { text: "Bluetooth, BLE, Wi-Fi, NFC" },
    { text: "需要" },
    { text: "256 x 273 x 24(mm)" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
    { text: "1" },
  ],


  spec: [

    {

    },

    {
      image: accesscontrollerMain,

    },


    {
      image: accessAutoMain,

    },

    {
      image: accessProMain,


    },

  ],


}

