import { Vertical } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT, StyledGridColumn } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";

export const StyledUseCaseCard = styled(StyledGridColumn)`
  min-height: 600px;
  padding: 32px;
  border-radius: 24px;
  background-color: #fff;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    min-height: 560px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    min-height: 560px;
    padding: 24px;
  }
`;

export const StyledUseCaseCardIcon = styled.img`
  width: 32px;
`;


export const StyledUseCaseCardItems = styled(Vertical)`
  width: 100%;
  gap: 16px;
  margin-top: auto;
`;
