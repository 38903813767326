import { constructionSafety, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import digitaltwin from "@src/assets/images/digitaltwin/video/traffic.mp4";

export default {


    helmet: '| Example of Smart City',
    desc: 'Smart cities are a rapidly evolving concept in modern urban development, aiming for efficient city operations using the latest technology and infrastructure. By collecting and analyzing data in various fields such as transportation, energy, and environment through sensors, IoT technology, and big data, smart cities strive to enhance citizens\' lives and achieve sustainable development.',
    keywords: 'Smart City, Digital Twin, Digital Transformation, real-time location tracking, RTLS, Orbro, ConTech',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'Example of Smart City Construction :: Orbro Blog',
    ogUrl: 'https://orbro.io/en/blog/example-of-smart-city',
    ogKey: '',
    ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDkFVkNhGYhAjBJLQh-TO_MrDW5dZ9e675klRxgK1f4x67wU6ADQnAzVhSAqDCJ5a6mjIlcP3clmtu92HV05SxhzPTDf1NScGbhZaAOxxAakyFJm0bwewGqWjuE1NJoM86xI5RkwtjQZ4WFYc6d0Hrid-pszPZbQGf1gYPMDqDwORsg5wyGW5bNgU7KKeOTMzrkt78Yr5lYcchyCNmpn2NmzJazzotGsmI9T33V6K3T6xqraKPVNfNkg6odqKHbGpbE7knODw-Ps7LNMLRhHIAZIM9lduTSX896wr7cZscafS5TDVi8cSC0ERUhdb3YwCRgKm0YqSsnVcCJcAbu4evdtoUzdMcjZAIfKR_kw7HgXI42LaA4C-PhxW2jQQKyD9UIxoOFPmJFIh6q7vIHbg3k3cAUc2dxW0Z6hfKLB2k10q0aubzcnWFJnEPSibJNaZJA0fslRcEQTOOSq7qH3K-9HiRproHejfmIxqYsRayvAnKXQPSJyG6cmWTKw2ctEcRwcsQV7cMU2H5VMEc-s5BYkjOqQe6yylbCUXzQ8qYVwls2h-mstfcyt2phYijwZXPHjEY0Xd1KSUErYHTSCWx_A1JKCL30IryNxWX6ejXVr4rzx9VOXpS53IYlHEz2WJTk6LjBccSU2u9lAv_gwAiZTmTSoRX658UQv-7kdx_Et7OVcWSg4GdQpFTDS9mF8huuN39CfL2Tm9qVy2j5jAl-gTQ4o4bnPhaHz9NMoaM-qoTdDt4Q5C1ysaxNReONUY8phy-5RgTgHszfiRXjSpTP_fjzv_dGumhP0V_Rf6ndfluYR6Mg1BHCVhSUG62aDV8bGqdS2oYAmGPYgbN99KcLyQgySVflbWa6wC_fOGkLNeso6n_M6C7otFKqjX9XmB3s6GUBTaS3JiqqQJ-N9qGtjKI86za8HY7i9lxAJEh4iY2FSXw3kIW-m6OTvQbenmldQlgE6hB_Fl8rm6ATKKLAhsxwx5fMKD16IV2hBFuQ9wpnIjLhw1XLzIy0R5Pka1Mygfo6no04iZU4LAApDVkphY4k958mwN4tg_wyl3L63jjST8taRHacU0M-4ADiu0tC2vJzWH1XQwiSedBQQTTUMuydsHQCMYlanG9WhyhybHTxRIA7pUX7-wpNqkFiWCrT9TPNbHg-ON5nQrBGyDUZUb5fC-U4EkUN0a-Pe-CHJJ6bZd1m_oCpzdulGMPnK-4_VjWBJAiQRteQ8QTUKNhmQj2yEnZwCJQZZSTpk1IlGzgHiJ_BsIYyD_a3z-ysShhTuE9hG_57y4aIz8Rf6ZsrXTp1OeEHkNkCvjH7ZnbdABz0u_QyshaMMfU7EKVUbH6tIXiym1yOX6upY9M_6JtOTgtlulM87kVzPjPoy47O2YEq7mcAOwMBRmDb6gfW2J0t7PuaFMgTQAi4XiKpM5Pf_NhIK-wNtRCAhcSD9WCAd1CIxJ-CrcG3xn77PYV4NQRe0Oi2KQCyqG3frUstyWuJuSAeYrdYhBRBkDG6OoVG48EshJwneIwytpeURilKCKLLPt64ls45oiR-ZMwMxmdpfsCMct--L=w2010-h1214',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'Example of Smart City',
    date: '2024-01-09',
    tag1: '#SmartCity',
    tag2: '#DigitalTwin',
    tag3: '#DigitalTransformation',
    tag4: '#FutureCity',

    // <br/>
    text: [
        {
            head: 'Example of Smart City',
            subHead: '',
            desc: 'Smart cities are a rapidly evolving concept in modern urban development, aiming for efficient city operations using the latest technology and infrastructure. By collecting and analyzing data in various fields such as transportation, energy, and environment through sensors, IoT technology, and big data, smart cities strive to enhance citizens\' lives and achieve sustainable development. Smart cities globally are evolving into models of the future city through innovation and collaboration.<br/><br/>In this context, let\'s explore smart city construction cases from around the world below.',
            dot: ''
        },
        {
            head: 'Smart City in Singapore',
            subHead: '',
            desc: 'Singapore is a pioneer in systematically implementing the concept of smart cities. Various sensors and IoT devices installed in the city monitor traffic congestion and achieve efficient traffic management. Additionally, through smart grid technology, it enhances energy efficiency, and automation of public facilities and an intelligent CCTV network strengthen safety.',
            dot: ''
        },
        {
            head: 'Smart City in Tokyo',
            subHead: '',
            desc: 'Tokyo is pursuing various projects for the smartification of the city. By introducing technologies such as fine dust measurement sensors, smart lighting, and building automation, it monitors the environment. The intelligent transportation system reduces traffic congestion and enhances the city\'s efficiency.',
            dot: ''
        },
        {
            head: 'Smart City in Barcelona',
            subHead: '',
            desc: 'Barcelona aims for the city\'s sustainable development through smart grids, wireless sensor networks, and the digitization of public transportation. By optimizing waste collection and energy consumption through big data analysis, it strives to manage the city\'s various functions efficiently.',
            dot: ''
        },
        {
            head: 'Smart City in Seoul',
            subHead: '',
            desc: 'Seoul is innovating city infrastructure through smart city construction. The implementation of smart grids and the digitization of public transportation contribute to increased energy efficiency and transportation convenience. Additionally, by integrating and analyzing various data through the smart city platform, it focuses on enhancing citizens\' convenience and safety.',
            dot: ''
        },
        {
            video : digitaltwin,
            link : 'https://orbro.io/en/digital-twin',
            videoDesc : '(Click on the video to go to the Orbro Digital Twin page.)',
            head: 'Methods of Smart City Construction',
            subHead: '',
            desc: 'Various technologies and strategies are required to build a smart city. Below is a brief description of the key methods for constructing a smart city.',
            dot : {
                a :'<strong> • Infrastructure Improvement: </strong>Strong digital infrastructure is needed for a smart city. Building enhanced digital infrastructure through high-speed broadband and 5G networks enables real-time data transmission, allowing internet-connected devices to operate efficiently.',
                b :'<strong> • Utilizing Big Data and Artificial Intelligence: </strong>Collecting and analyzing large amounts of data to optimize city operations is crucial. Using big data and artificial intelligence technologies to analyze and predict data generated in various fields such as transportation, environment, and energy in real-time.',
                c :'<strong> • Introduction of Smart Sensors and IoT Technology: </strong>Introducing various sensors and IoT technology within the city to collect real-time data on transportation, environment, energy, etc. Enhancing citizens\' convenience and optimizing city operations.',
                d :'<strong> • Smart Energy and Grid System: </strong>Introducing renewable energy and building a smart grid system to increase energy efficiency and optimize energy consumption. Using smart energy management systems to optimize energy usage and promote sustainable energy consumption.',
                e :'<strong> • Digitization of Public Transportation: </strong>Digitizing public transportation systems to provide real-time information, reservation systems, and introducing autonomous vehicles to alleviate traffic congestion and minimize environmental impact.',
                f :'<strong> • Establishment of Smart City Platform: </strong>Building a smart city platform to efficiently collect, manage, and provide open data to citizens and businesses. This plays a crucial role in optimizing city operations and encouraging citizen participation.',
                g :'<strong> • Strengthening Cybersecurity: </strong>Since various data is exchanged in real-time in a smart city, cybersecurity is a key challenge. Implementing robust security systems to protect the system and securely manage citizens\' personal information.',
            }
        },
        {
            head: 'Advantages of Smart Cities',
            subHead: 'Efficient City Operations',
            desc: 'Smart cities can efficiently manage city operations, including transportation, energy, water resources, waste disposal, etc., through real-time data collection and analysis. This optimizes city functions, ensuring smooth daily life for citizens.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Optimization of Traffic Flow',
            desc: 'Digitization of public transportation, introduction of autonomous driving technology, etc., reduce traffic congestion and optimize traffic flow. This facilitates smooth movement for citizens and reduces negative impacts on the environment.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Energy Efficiency and Sustainability',
            desc: 'Smart cities promote optimal energy consumption and sustainable energy use through smart grids, renewable energy, and energy-efficient technologies. This reduces the environmental burden and cuts energy costs.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Promotion of Citizen Participation',
            desc: 'Smart cities provide various means for citizens to participate in city operations. Open data, mobile applications, etc., enable citizens to participate in decision-making for the city and create a better environment.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Enhanced Safety',
            desc: 'Smart cities enhance safety through the introduction of intelligent CCTV, emergency response systems, preventive safety measures, etc. Real-time monitoring and big data analysis improve crime prevention and emergency response capabilities.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Economic Development',
            desc: 'Smart city construction attracts new technologies and industries, stimulating local economies. Companies developing and applying smart city technologies create jobs and drive economic development.',
            dot: ''
        },
        {
            head: 'Outlook for Smart Cities',
            subHead: '',
            desc: 'The future of smart cities is expected to evolve into a more innovative and connected urban environment. Advanced technologies such as big data, artificial intelligence, and the Internet of Things will be integrated to efficiently manage city operations, emphasizing sustainability in various aspects such as traffic congestion relief, energy efficiency, and environmental protection. Citizens will experience personalized services and higher participation, enhancing the convenience of urban life. Additionally, the importance of smart city construction will be highlighted globally, leading to increased interest in referencing such city models worldwide. The evolution of smart architecture and city design is also anticipated, providing citizens with a safer and more intelligent city life.',
            dot: ''
        },
        {
            linkText : 'Experience Orbro World',
            link : 'https://home.orbro.io',
            head : '',
            subHead : '',
            desc: '',
            dot : '',
        },
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'A product that helps real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/en/enterprise/construction/safety'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/en/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/en/enterprise/manufacturing/asset'
            }
        ]

    }


}
