import React from "react";
import { useTranslation } from "react-i18next";
import TitleBanner from "../Common/TitleBanner/TitleBanner";
import { StyledTechnologyContent } from "../Technology.style";
import Advantage, { AdvantagePropsItem } from "../Common/Advantage/Advantage";
import TechRequest from "../Common/TechRequest/TechRequest";
import MonocularContent from "./components/UwbContent/MonocularVisionContent";
import UseCase from "./UseCase/UseCase";

import { IconTagInv, IconToolsInv, IconGridPlusInv } from "@assets/images/icon";
import i18n from "@pages/i18n";
import SEO from "@pages/SEO";
import CommonLang from "@pages/CommonStyle/commonLang";
import { FontPage } from "@pages/CommonStyle/commonDiv.style";
import { SpacingDTM } from "@pages/CommonStyle/common.style";

const advantageItems: AdvantagePropsItem[] = [
  {
    icon: IconTagInv,
    title: "monocular.advantage.items.accuracy.title",
    description: "monocular.advantage.items.accuracy.description",
  },
  {
    icon: IconToolsInv,
    title: "monocular.advantage.items.position.title",
    description: "monocular.advantage.items.position.description",
  },
  {
    icon: IconGridPlusInv,
    title: "monocular.advantage.items.security.title",
    description: "monocular.advantage.items.security.description",
  },
]


const MonocularVision = () => {
  // Translation
  const { t } = useTranslation();

  CommonLang();

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "headline": "Monocular Vision을 이용한 위치 추적 및 표지셔닝 기술",
    "datePublished": "2023-04-01T08:00:00+08:00",
    "dateModified": "2023-04-01T08:00:00+08:00",
    "author": [{
      "@type": "Organization",
      "name": "ORBRO",
      "url": "https://orbro.io"
    }]
  }


  const helmetTitle = t(`seo.tech.mono.title`);
  const key = t(`seo.tech.mono.key`);
  const desc = t(`seo.tech.mono.desc`);
  const currentLang = i18n.language;
  let isKorean;
  if (currentLang === 'ko') {
    isKorean = true;
  } else {
    isKorean = false;
  }


  return (
    <>

      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={key}
        ogDesc={desc}
        ogImg="https://lh3.googleusercontent.com/fife/APg5EOaj2i-YxhmYpUMZaR8IpPdTvYenqXPHi9BZF5a6F_E_Ci0NtEGWUE3VLhN4G-nzdw3l_fSd1KHsncITWH82EXL89v2lSqRij6fMmj27hY6TUS-fr2-mxttuYbCY7u4bSAA8mj7VdelB-Q3eMS9G3251WhCeaYR0oi07d7rEPrVM5Sz7aD1fqqfwEeoBPb_6AMINv6xlH5j7wkN_rE7pZ1-npHASFgcTtybg_vV52qqbEHvCxYYnS--aPNTYxJwopaqTAscFB85Sw7PKOaKaayA2mC9bMHIQfmRooYMGrIYYyImxFP_D-HdbQGRVQu-nRMZ4QGcZoRtVA-ncdrAdgz17JbQRgavB709KoTFnVCdcFsUwICHXVjX3yALY8hN-2Jnj1RjedxO4jXOLuSSfOQUoz-hWPIiVc0pXTPSo9wHpJDs9rgKD4mZGyvka_6QyhBZBH2zcq64u3VtmM4TyKHRLckVo7G2WMBa9uaBMY5_jNvaSkCT6_FPE_at6PzxmTQgNuReaRnPKPjRw4NjDMk7GyJe5HtAvHmON3hCTIel5u-qSlMVmsxqSKHYAwAxB1RIHFi8721nHkc6xBl68ztb6uy6ZnUSsM0KvSdFPKfTKKgGVo_7Z4lAwaEokQ7mZAvgD_UK00rEnIJrBdTsm3zJKlYZ_Z4F9rtRI6a7uUM5NVKEWc2QkkjbuNdW5Ndw2k0Nj4c-2VdWyUxoR2oZD9FHnHYJ6NM1yuQ_VvoC6kTOQEPqPa3otU-Qfdt_jHic8af-2PvQUHhxR3JTGTPnDtga60OSBNKUZM-DSCZofj6KAJ9zSGjM2tRhKdtfFoLHe5XaZsIrtd-ynfcthLbSC3GzoiYP25ujJJNpFSAuzuMxGjWhdggF9ADM1-Xer5NwVMcgcsqvEkJC4faamP88ytd95adxdCOylxyMYuegkwqWqdlp6LLS751ocLnL58JGrgVPsh80Fdxh2AvMdTJFUpMX6PfaFhaPiKZKbzTUCNvezTP7Gg8mvIoA4n1P6nVmmd6XmtkYqa6Z6ImNP2WeGEG7LI81Vy1dH-zeiQT0V0c2mmkE5MdO5_Ak4H6zd1QHCuLNVit8bvopFoX5mNVeQxv5yuXIAfY2EYCvqyWfa-S461H0KHbyQ3AQO8Fk9WiLUe9fPJzXWtUAdk0hTd15N5-oYQjlC_Hw1bFsVFgh49EZSRzkDDEKQTgQaHvr1w6fjAIUB8EoFmKXyIN19k8YU9hzsXcryjJiHkqam2zE7_wTr7iGaHa0dt8clZj6iXNRuFH11Mw38HQg6ng9lI0jarxiVuNA-KBbQblTdPYTJ8mYGChnTFvjF3E5c3-CbsKf-XUfbkWpmFnpaz03AJvSjBHhDDaFSh5ETjHo51y7ISZAisTjcC-sSh8lJrX7wMABmMFEuOOavoeBc7z88ZfesTBtX9HSDjGisVn90jg_Opgh7UDO47gl0703GY-JrgAA9fdPoaDXzmU2JkAfT6yFybH0WaQ=w1515-h1220"
        ogUrl={isKorean ? "https://orbro.io/enterprise/technology/monocular-vision" : `https://orbro.io/${currentLang}/enterprise/technology/monocular-vision`}
        jsonLd={jsonLd}
      />
      <SpacingDTM d={"64"} t={"48"} m={"32"} />
      <FontPage>

        <TitleBanner
          subtitle={"monocular.titleBanner.subtitle"}
          title={"monocular.titleBanner.title"}
          description={"monocular.titleBanner.description"}
        />

        <StyledTechnologyContent>
          <MonocularContent />

          <Advantage title={"monocular.advantage.title"} items={advantageItems} />
        </StyledTechnologyContent>


        <UseCase type="monocular" />

        <TechRequest />
      </FontPage>
    </>
  );
};

export default MonocularVision;