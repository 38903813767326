import styled from "@emotion/styled";
import { SCREEN_BREAKPOINT, StyledGridRow } from "@components/Grid/Grid.style";

export const StyledPurchasePage = styled(StyledGridRow)`
  margin-top: 30px;
  margin-bottom : 96px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-top: 32px;
    margin-bottom : 64px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-top: 24px;
    margin-bottom : 64px;
  }

  & * {
    font-family: Pretendard !important;
    font-display: swap;
  }
`



export const StyledPurchaseLayout = styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  
  gap: 32px;

  height : auto;
  width : 100%;

  margin-top : 32px;
  background-color : gray;
`


export const StyledMainBox = styled.div`


`

export const StyledImgMain = styled.div`
  max-width: 100%;
  height: 500px;
  margin-top : 32px;

  position: relative; 

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    display : none;
  }

`

export const OverlayImage = styled.img`
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 100px;
  height: auto;
`;



export const StyledImgSubBox = styled.div`

  height: 112px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    display : none;
  }

`

export const ImageWrapper = styled.div`
  display: inline-block;
  width: calc(25% - 12px);
  margin-right: 16px; 

  height : 100%;
  cursor : pointer;
  &:last-child {
    margin-right: 0; 
  }
`;

export const Image = styled.img<{ isSelected: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  
  ${({ isSelected }) => isSelected && `
    border-bottom : 2px solid black;
  `}


  ${({ isSelected }) => !isSelected && `
    border-bottom : 2px solid transparent;
  `}
`;


export const StyledImgSub = styled.img`
  background-color : white;

`


export const StyledTextSection = styled.div`
  width: 600px;
  height: 991px;

`

export const StyledPurchaseBox = styled.div`
  width : 600px;
  height : auto;


`

export const StyledDescBox = styled.div`

  padding-top : 24px;


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-top : 0px;
  }

`