import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";



export const BannerPopTopSection = styled.div`
    position : fixed;
    width : 100%;
    height : 64px;

    z-index : 6;

    box-shadow: 0 0 30px rgba(30, 30, 30, 0.185);
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.5);

    display : flex;
    justify-content : center;
    align-items : center;
`;

export const CloseBtn = styled.img`
    margin-left : auto;
    width : 48px;
    height : 48px;
    z-index : 8px;
    cursor : pointer;
`;



export const BannerPopPage = styled.div`
    margin-top : 64px;
    position : fixed;
    width : 100%;
    height : 100%;
    max-height : 890px;
    z-index : 6;
    box-shadow: 0 0 30px rgba(30, 30, 30, 0.185);
    box-sizing: border-box;
    background-color: white;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        max-height : 660px;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        max-height : 540px;
    }
`;


export const BannerPopFrame = styled.iframe`
    width : 100%;
    height : 100%;
    object-fit : contain;
`;

