import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {


  helmet: '| Inventory Management Utilizing UWB and BLE as Alternatives to NFC Tags',
  desc: 'Experience a smarter and more convenient inventory management system using UWB and BLE instead of NFC tags. Check out the ready-to-implement solution right away.',
  keywords: 'nfc, uwb, wireless communication, location tracking, object location tracking, people location tracking, tags, beacons, rtls, orbro, kongtech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Inventory Management Utilizing UWB and BLE as Alternatives to NFC Tags',
  ogUrl: 'https://orbro.io/en/blog/nfc-uwb-ble',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDwxW_WSF_F7yNjIa07OkmVhKArBLWz8-_J1moJsQci7Ije4YbYRD5Oa0A3rsNOuVWMRMVKmnI6q7dJm894FnMM4irt8-NIgSx08LF7IIza4ONna0iwlNNedic3hlVYbDPl7746HMAAhJMopboCbqV9PlElgpHpCuSsWKAWobQKY1JrFwdltqWl5FsJqgC4DiIaxM9NUQNJHd4Tt2bOO1lPabNGc27n5QShIyfFcNuGdpozghgJAoszlfuiyD5CVSXKJOXYsfrj1Dhk0cbYrU_RHZfxcle6uATv2tw81Rq5eyY-OCCnNI9WQYLcp5PeiRUydm3S0Ba1eR0DQF-dHz8AvSGqnC2liVRiO-p1TSfr_dtXdAJcEDMSC1_zz2O2dksO3KqdbnvRkpDU-xKCqeel5YaHVAxFFOt19k4SHGVc493DTVSksZhLeWJPTBllKX8oc5zt5qErJMjPZWMUZ2iOr-geb_9fVyyuZogAAj-tFvkAF-mqBapjOwe_z2_8caQhLzg6vnNK3OSmDHQMOLUyZT22pB-OBf0QWVA-9wzMUyIo6BZbUXSj8n0bd6Hq72hFlNnNKwDe2aHoam2KguWNLfBx3PFqYkeO3fbdBRoc8vGkW_FcogVpvOusbERHosgwDP6T-foORllJuVUPjKZyx3CuilLxfRO-fo-4JF0yB8zqC-WZUHzpzyYTN5LZk0nln7wCJNPlMuRtxzJNq_4KrjorS6SdzMmpuYJog9QlrLImyeiR3wOLLvc2bklRyoEQ5T-DcDttw6dzFt1jFu8BjtXKIJ9KCc5PfIgl4XB2T5LshMXFDw7HU2qoFfYHvyQciti_HH59bUaP67x0KwbzAFSnMyP8edq6y4Wsvvhrl5cvYepph4sK7Dn5zkeP1Pv9qa5wNbYn--ZEAND3870LLFBqN5ttPfpxijnKszom0HGZG9G1EGUcU3GeMyAKg5Un-dXh7mWayLTFjU2UIBCRzpU5w509ZaRDokT94aFEyudm035LQQWLoRIV6TInKZ-LaBi6LNpXrykfPgmZb97nPCdULyDlhpp7oYwDLN7wgNy6b0-Wn5Tj9sl8qVTs1o_LrAT-8tl2UZ-uM9MPzHVKPmpPtnDymJklgsbMOp3TK45pIQ9voLx_ESS7YM1S2gw2ihX-Kvs3PqdKmxJ8y6LU8Op4OdaAeuWY1evq4apVEly7tAVqx7Gh5EiLl7PdZnpX1PiwLiz4WnDK0WLV_YOPYQlouBLWy7ojtfLu_C5UjgrjyKBg71KnsCv680b2sBcB-p2Wt6aD6IFtfAlT5J5nzg_cW01H3nwcRUKJdwKsVN-6_vVbyyBX8WGa4yR00BKZ0_scjPlWgID-43QPnz5X6P0IHyJUKtyUTiZM9a5gV7_Raq8rjHTOwYAR4r8je2xkZBJ_jSbV2Dk5yn6wEfK7XhzhLDCOrIoZdOCMhy-Qh610uh34OOtAoLyPBLfWcF_RXsMAGfz73LPe3WJx9YjYMPmRu-dvPHRiBlNrBU2OrvZN1lW3XYjKgGHTt7QlAKY2D5XpDjGA8NsfGVcpLghIXt-HCy32YCFiUdpQr5rq6mf7hfmJr=w1871-h1190',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Inventory Management Utilizing UWB and BLE as Alternatives to NFC Tags',
  date: '2023-11-20',
  tag1: '#nfc',
  tag2: '#uwb',
  tag3: '#ble',
  tag4: '#inventorymanagement',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: 'Efficient inventory management plays a crucial role in the core activities of businesses in production and distribution. RFID and NFC tags have been actively used for this purpose. However, with the emergence of UWB (Ultra-Wideband) and BLE (Bluetooth Low Energy) technologies, more advanced inventory management systems are now possible. In particular, the use of UWB and BLE as alternatives to NFC tags is gaining attention.',
      dot: ''
    },
    {
      video : video,
      link : '/en/enterprise/manufacturing/asset',
      head: 'Features of UWB and BLE',
      subHead: '1. UWB (Ultra-Wideband)',
      desc: 'UWB is a wireless communication technology that uses an extremely wide bandwidth for precise location tracking. It provides high-performance real-time inventory tracking and location identification by offering precise location data. Moreover, it ensures stable communication by avoiding multi-path interference, allowing accurate data collection.',
      dot: ''
    },
    {
      head: '',
      subHead: '2. BLE (Bluetooth Low Energy)',
      desc: 'BLE is a low-energy Bluetooth technology suitable for efficient data communication between low-power devices. BLE tags are compact, and their long battery life enables the construction of an energy-efficient inventory tracking system. Additionally, BLE has high compatibility with smartphones, making it convenient to use.',
      dot: ''
    },
    {
      head: 'Advantages of Replacing NFC Tags',
      subHead: '1. Accurate Location Tracking',
      desc: 'UWB technology provides extremely accurate location information, allowing real-time tracking of the precise location of items in warehouses or production lines. This enhances the accuracy of inventory management and minimizes inventory loss and errors.',
      dot: ''
    },
    {
      head: '',
      subHead: '2. Low Power Operation',
      desc: 'The low power consumption feature of BLE enables the operation of tags for an extended period. This advantage leads to an increased battery replacement cycle and cost savings in maintenance.',
      dot: ''
    },
    {
      head: '',
      subHead: '3. High Compatibility',
      desc: 'Since BLE is supported by many devices by default, it can be easily integrated with existing infrastructures. Particularly, its compatibility with smartphones provides convenience in wireless inventory tracking.',
      dot: ''
    },
    {
      head: 'Implementation Strategies',
      subHead: '1. Introduction of UWB and BLE Tags',
      desc: 'The first step is to replace existing NFC tags with UWB and BLE tags. This ensures the benefits of precise location tracking and low-power operation.',
      dot: ''
    },
    {
      head: '',
      subHead: '2. Establishment of Data Platform',
      desc: 'To effectively manage data collected from UWB and BLE, an efficient data platform is necessary. Introduce a cloud-based data storage and analysis platform for real-time data processing and analysis.',
      dot: ''
    },
    {
      head: '',
      subHead: '3. System Integration',
      desc: 'Integrate the inventory management system using UWB and BLE with existing systems. This centralizes all data for efficient management, increasing productivity and efficiency.',
      dot: ''
    },
    {
      head: 'Conclusion',
      subHead: '',
      desc: 'Utilizing UWB and BLE to replace NFC tags in the inventory management system provides various advantages, including accuracy, low-power operation, and compatibility. Businesses adopting these technologies are expected to build more efficient and smarter inventory management systems, securing a competitive edge. It is anticipated that more companies will embrace these innovative technologies to enhance their competitiveness.',
      dot: ''
    },

  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that helps real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/en/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/en/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/en/enterprise/office/asset'
      }
    ]

  }


}
