

//export type MainMenuType = 'PLATFORM' | 'APPS' | 'SUPPORT' | 'COMPANY' | 'SERVICE' | 'PRODUCT' | 'TECHNOLOGY';
export type MainMenuType = 'PRODUCT' | 'SOLUTION' | 'SUPPORT' | 'BLOG';

export interface Menu {
  id: MainMenuType;
  name: string;
  path: string;
  sub: string;

  submenus?: SubMenuGroup[];
}

export interface SubMenuGroup {
  title: string;
  items: SubMenu[];
}

export interface SubMenu {
  id: string;
  name: string;
  description: string;
  path: string;
  imgDark?: string;
  imgLight?: string;
}

export interface MenuProps {
  active: boolean;
}

export interface MenuPopupProps {
  menus: Menu[];
  isOpen: boolean;

  handleToggleMenuPopup: () => void;
}

export const MainMenus: Menu[] = [
  {

    id: 'SOLUTION',
    name: `layout.menus.RTLS.name`,
    path: '/rtls',
    sub: ''
  },
  {
    id: 'PRODUCT',
    name: `layout.menus.BEACON.name`,
    path: '/beacon/ble',
    sub: ''
  },
  {
    id: 'BLOG',
    name: `layout.menus.CAMERA.name`,
    path: '/camera',
    sub: ''
  },
  {
    id: 'BLOG',
    name: `layout.menus.ACCESS.name`,
    path: '/access',
    sub: ''
  },
  {
    id: 'BLOG',
    name: `layout.menus.SENSOR.name`,
    path: '/sensor/LoRaGateway',
    sub: ''
  },
  {
    id: 'BLOG',
    name: `layout.menus.INTEGRATION.name`,
    path: '/integration',
    sub: ''
  },
  {
    id: 'BLOG',
    name: `layout.menus.SOLUTION.name`,
    path: 'none',
    sub: '1'
  },
  {
    id: 'BLOG',
    name: `layout.menus.SUPPORT.name`,
    path: 'none',
    sub: '1'
  },

];
