import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo4.mp4";

export default {
    banner: {
      title: 'Asset Tracking and Management',
      desc: 'Asset management is a crucial part of supporting supply chain efficiency and smooth operations. By tracking the location of asset in real time, you can effectively monitor asset levels and optimize asset movements. For example, you can strategically position asset based on item popularity and optimize asset turnover, which helps reduce costs and prevent asset shortages or excess.',
    },
    video: {
      title: 'Smart Logistics Asset Management',
      desc: 'Real-time location tracking (RTLS) technology enables real-time tracking of asset.<br/>Manage your asset accurately, quickly, and easily for <strong>optimal efficiency</strong>.',
      play: video,
    },
    func: [
      {
        title: 'Automated Inbound and Outbound Processes',
        desc: 'Automatically generate records of inbound and outbound transactions when asset passes through specific points. This data is accurate and helps prevent human errors while saving manpower.',
        sub1: 'High Accuracy',
        icon1: IconRulerGray,
        sub2: 'Fast Data Integration',
        icon2: IconShareGray,
        video: subVideo1,
      },
      {
        title: 'Accurate Asset Quantity Management',
        desc: 'You can strategically position asset based on item popularity and optimize asset turnover, which helps reduce costs and prevent asset shortages or excess.',
        sub1: 'Real-time Status Monitoring',
        icon1: IconClockGray,
        sub2: 'Statistical Data Provision',
        icon2: IconChartBarGray,
        video: subVideo2,
      },
      {
        title: 'Time Savings',
        desc: 'With accurate information on asset locations, logistics workers can quickly locate items and move them to the required locations. This reduces work time and increases productivity.',
        sub1: 'Real-time Location Tracking',
        icon1: IconBinocularsGray,
        sub2: 'High Accuracy',
        icon2: IconRulerGray,
        video: subVideo3,
      },
      {
        title: 'Real-time Location Tracking',
        desc: 'Helps prevent asset loss and theft. By assigning and tracking asset to specific locations in real time, you can quickly detect asset loss or theft and take recovery measures.',
        sub1: 'Fast Data Integration',
        icon1: IconShareGray,
        sub2: 'Real-time Status Monitoring',
        icon2: IconClockGray,
        video: subVideo4,
      },
    ],
    subfunc: {
      title: 'Various Features',
      desc: 'Asset management is a vital part of supply chain management in the logistics industry. To build the most optimized system, ORBRO provides various features.',
      data: [
        {
          icon: IconChartRadius,
          title: 'Reports and Analysis',
          desc: 'Generate reports and analytical data based on field data.',
        },
        {
          icon: IconBell,
          title: 'Real-time Alert Function',
          desc: 'Receive alerts when asset quantity exceeds certain limits and take immediate actions.',
        },
        {
          icon: IconRoute,
          title: 'Route History Inquiry',
          desc: 'Track and record the movement routes of asset for data analysis.',
        },
        {
          icon: IconDashboard,
          title: 'Status Monitoring',
          desc: 'Monitor the current movement of specific asset items.',
        },
        {
          icon: IconWarning,
          title: 'Deviation Detection',
          desc: 'Detect when asset items deviate from their designated locations.',
        },
        {
          icon: IconSearch,
          title: 'Route Pattern Analysis',
          desc: 'Analyze the usage and timing of equipment at the field.',
        },
        {
          icon: IconChainLink,
          title: 'Data Integration',
          desc: 'Connect with systems such as RFID, IoT, security, and video for efficient utilization.',
        },
        {
          icon: IconSecurity,
          title: 'Data Security',
          desc: 'Maintain a high level of data security to provide a reliable solution.',
        },
      ],
    },
    system: {
      title: 'System Configuration',
      img: rtlsSystem,
    },
    productTitle: 'Product Configuration',
    product: [
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'logisticsSafety',
      },
      {
        data: 'constructionAsset',
      },
      {
        data: 'blogRTLS',
      },
      {
        data: 'blogMedical',
      },
    ],
  }
  