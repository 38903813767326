import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


    helmet: '',
    desc: 'リアルタイム位置追跡システム（RTLS）は、医療廃棄物を安全に処理するための完璧な方法です。リスクがなくなるまでモニタリングし、安全事故を予防できます。',
    keywords: '医療廃棄物の管理、医療廃棄物の処理、廃棄物の管理、廃棄物の処理、医療廃棄物、医療事故の管理、医療機器の管理、安全事故の管理、医療施設、病院廃棄物の管理、病院廃棄物の処理、病院廃棄物、汚染された機器の管理、汚染された機器の処理、リアルタイム位置追跡、RTLS、オルブロ、コングテック、オルブロ',

    ogTitle: '',
    ogDesc: '',
    ogSite: '医療廃棄物と資産の管理のためのスマートテクノロジー :: オルブロ ブログ',
    ogUrl: 'https://orbro.io/jp/blog/medicalWaste',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4MONQZidMWIaTrmLqrkg911u867GiKnQvRp2J_6pXN-o_ulq8j5B2yFxT7ir2txi5c7bIVIt8hiyAS_8bBcYHCvfHVgU19Cv-9Zti_3iLiSQjzwRtscyVXg8ra_zcueLOOTV98WMwQPJvqq6lLm9WCS1uuU0kQTDiObKEJgcPUH1VWuCsr2icfJp2XeQo37pAjWJRRYRO0V94Rfg7K5kCNY2i6xyCb9v7ScCCWn1TDV770PCeZCTaqxvMsBaKLqMGHrg7rel0zDkwCNNCmA3Ztbw-uSIi1yvmAFmD-UwvSJ_CDlAoODePdXtGUuvvbo7nwKL38Ku_KgOEwMjm_fu5OmSTGuXLj-6GyiDXO_BGO4kRGAVRu1XLrfWPPX4GUQvDghijtlavji5s42a6EmSNBQKQ5PNV6Xi1LKTyhsOFRT6H69kayRpXz0PGQr9o9PwF9V8wif_gyo5Yp3WupUyxj2Bjc7jiHRBLBbeT8jDbZzdNEcYtdGJm6AiYBu1d3eNxk3awkxVXesGtS7MS_zXLKg03XqO_AyofzksiJ4jqyJBJu-atlMmflMnpcow2pp_JmyyTwQqPnikDNlTZ3iywdxdsseVVU_LfDL_koySbuLXVfcbrwXThmZI0aBrW-7R3HsTN_axhMhnxoIf16Le7Oe_9dD2o29Udu271POE6W1-FFfn7mj9Qh3td3uOl5KqXAZKoy3pB9Zw5bn3wgCANBWgV5BuOaQS5tPDxrFloyRt8VHGipdKgP5Cvz8M0ZiHdq_S3Ykh3eXE2z8Mu5e1ruL-FRtmbxQTl-PaOT6LrZS3WPhkOZrgEPel4AFv93GXcBg1YXw4MHsW-w6k_pJWGv43aL2AOsJ0G-xSso8gxdCh8Z6A3j75V0HVSHZ77OVUrJsYgEIXN-XGaCdMmDEaIJEGaJkC6ryDiPWnbogd0em8sf7YiKictJq9jl-aD_OGuOALvvAAmKdAsVzzZGMUp19SWDeI4R1UT_uYZ1_vC8ocRAurJg-kwM_gZu2TlLRPMkLUdDFDNVDoOYGyq47vgWJNg6KX-VtP5NwZ1K0afRlbOl1ho7d69Hl15XsQiqOQla9c1j7YFVQiUFWpx6GR3QhPt6XHSOufQfw6OOqtMz5s3Ibt69c0hgIzV1I2dVXetfGUDWGSoYXmpbxpF2M0KrnAjCojSgEXMU6X4DEcdo_yJrru5Tl8J1_cBMr0nm09QJSNg-qXDoYtS6wq-L1L4uy-MlHr_N4G6minr4oerAf1zu8o9jL3e9XpOkJdIo0sYln8FRjzLZ4J8AdpQF4pDo768VucYPzCgSrOJ_4-ARZoCuJKqB_lQJ2bIAIST0_STyL4tpQPSFWwnrMgAdgMgAams6EhbAQCgMV1xTZjRvd5DptWwTMknoV2YJ4sVcLtlZ3sdE4wOhJH7Oucjab0uDCuYhl3AjrdtZB3TgemIMOWwuji09pERKa7q1rMo6mwI5TiHI6vt4OzEshaO1UQRMcB0mxtkJsU7xT7bTkz1c2i1SRNWplYz9TzJnWVFqcQQeQ5XcTMaHkjM1_774K3ZNMFShH_yqajpvElOIl_J4JSnz0n1gneniKhbI3DQ=w2000-h1794',
    ogType: 'ウェブサイト',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',


    title: '医療廃棄物と資産の管理のためのスマートテクノロジー',
    date: '2023-08-30',
    tag1: '#医療廃棄物',
    tag2: '#医療事故の予防',
    tag3: '#医療機器の管理',
    tag4: '#廃棄物の処理',

    // <br/>
    text: [
        {
            head: '',
            subHead: '',
            desc: '医療施設で管理者が注意を払うべき点は患者だけではありません。医療廃棄物と医療機器は患者に直接影響を与える重要な資産であり、患者と同じくらいの注意と管理が必要です。例えば、汚染された注射器や廃棄物を適切に処理しなかったために二次感染事故が発生した例は容易に見つかります。さらに、毎年処理する必要のある医療廃棄物の量は増加傾向にありますが、これに対するシステムを設置した施設の例は見当たりません。安全管理のプロトコルを強化し、スタッフに教育を行うことが限界かもしれません。<br/>ただし、医療事故は生命にかかわる重大な問題であるため、より安全なソリューションが必要です。リアルタイム位置追跡技術（RTLS）の最近の成長は、医療業界のこれらの問題に対処する方法を提供しています。RTLS技術は、対象の現在の位置をリアルタイムで追跡することができる技術です。それでは、このような技術が医療施設にどのような恩恵をもたらすか見てみましょう。',
            dot: ''

        },
        {
            head: '',
            subHead: '1. 医療廃棄物および医療機器の数量管理',
            desc: '廃棄物の処理を担当している場合、施設内のどのくらいの頻度で廃棄物が保管されているか、廃棄物の量はどれくらいかを知ることは重要です。施設全体を実際に探すことも方法ですが、RTLSソリューションを導入すると、迅速かつ簡単に情報を取得できます。同様に、手術や治療、故障、紛失の場合、RTLSは施設内の医療機器の位置を迅速かつ正確に追跡することができます。',
            dot: ''
        },
        {
            head: '',
            subHead: '2. リアルタイム位置追跡',
            desc: '廃棄物処理中に他の物と混ざったり交換される可能性があります。これを防止し、プロセスを監視するために廃棄物の移動経路をリアルタイムで追跡します。適切に処理されていない廃棄物が患者の部屋や制限されたエリアに移動する場合、即座に自動警報が発せられ、迅速な対応が可能です。さらに、施設から持ち出されたり持ち込まれたりする医療機器の状況も監視できるため、資産管理の効率が大幅に向上します。',
            dot: ''
        },
        {
            head: '',
            subHead: '3. 自動チェックインおよびチェックアウト',
            desc: '手作業で記入するかコンピュータで入力する必要がないように、機器や廃棄物の出入りに関する情報を記録し保存します。このプロセスはクラウドに保存され、施設の運用に必要な情報となるだけでなく、事故の場合に法的な証拠として役立つ可能性もあります。',
            dot: ''
        },
        {
            head: '',
            subHead: '4. その他の機能',
            desc: 'リアルタイム位置追跡技術は、物体に限定されるものではありません。一般患者や特別なケアが必要な患者のリアルタイムの位置追跡も可能です。患者が廃棄物などの制限エリアにアクセスすると自動的に警告が発せられることもあり、特定の患者の位置を素早く特定できます。これに基づいて患者の移動経路を記録できるため、COVID-19などの感染症発生時には迅速かつ正確な疫学調査が可能になります。こうしたリアルタイム位置追跡を基に、より進化した患者管理システムを構築することができます。',
            dot: ''
        },
        {
            head: '',
            subHead: '結論',
            desc: '多くの産業が第四次産業革命の後に変化しています。上記のプロセスは、従来の誤りの可能性のある方法を変革します。人員とコストを削減しつつ、効率と生産性を向上させる一方で、より安全な施設を確立できます。',
            dot: ''
        },
    ],


    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWBターミナルを作業者や機器に取り付けて、リアルタイムの位置追跡を支援する製品です。',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/jp/enterprise/medical/patient'
            },
            {
                img: medicalWaste.thumb,
                data: 'medicalWaste',
                link: '/jp/enterprise/medical/waste'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/jp/enterprise/office/asset'
            }
        ]


    }

}
