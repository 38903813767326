import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4';

export default {

    desc: 'AI摄像头定位追踪解决方案利用人工智能技术分析和处理摄像头捕获的视频数据，实时跟踪人和车辆的位置。',
    keywords: '智能停车场，AI摄像头，人工智能摄像头，数字孪生，数字转型，digital twin，orbro，信标，rtls，uwb，ble，ai camera，奥博罗，康泰克',

    ogSite: 'AI摄像头位置追踪解决方案 | 数字孪生平台，奥博罗',
    ogUrl: 'https://orbro.io/cn/blog/ai-camera',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkc4tGW9Sjxkfqer33MMng2ket3NNLJzV7Iz2LLirs_1Vbk4Lhm63aO_z8WWd2dIov4g4YuW4_LF8fiQn1v8CAQMxvfMbY58snCZaz5-tmYX7MljahebCQULlqUN3wwe4XqTP-9XdR2jE5QpHWOWD2ddnpV3jfnK3qIltSnKR3JEpPdmrECN49rXQbSa7jNtNAFPC71gTTRBtV-koIsqQYpX0un1kLVJJZxwlqQNH5_tGeA9HHAmQRhtGxmF0IkPCicaA2wexfmrwboGdPR9_tkKklNF54F6EulnMKypq_qaskVAEZvDlYArvBFhnatgUVy0Uh4yo-mw9wek4fNrsUOfIb2QjUoGzYVul27F9GFN5eXULvD3zPSTOeZHF_Fy7_VBfp_A0ugMGL_EuF2olRd-eBnVJt3xGOvMOIfgcA6AfXHOmhu7sbHo1yBoJ6cyTvJQzLMafMxmStxH5CKMzD_3FKEw0pJ25EuuAy7I5e8Pp_LxdZg_GjN8cj5qXbhIpnxCfSmd5KOol3tgfS1jCnXsAbDJ_XSVp4EBn_zgXhTswmP02m9R2XxafKdWMa-ZhZE5l4o_T_ZBOoWKwRfJXJtMcTJspT2tOfode7mVpQFhU5pa6KtAqcJtVuS1i2r4rjQDEI92DQCh0CDmnkM041jtKKL9oFXlO0GQLOX0j_Z7O1R3qZ0awsO_O7gBkBGK0CJ0PlT2_JW3eGFtPu5zROSwOKF-7rcJMH1f6R29Nf1bhejjB2zRRyISB5J6Psjf7wk3LffQaSUsmJy1Wl_J11fQUBTzMAl-mUnwqquWh2eEijlvT-y4Ixu8zeRJSM7Fk61_nWCu6F48eu4Ge_4geMR2DOjfQOnLqKKfSWY_XdzxDDeACmf6PTAgeWFDzx7MZJ6vN7yp75G8Ez9xTKMQGpcfn4qwI8oA2W3qx_NtVJ6AFT0dD7vLS87ML4D7eHI8uB2pjBlqb6v4rbxp41pQGAQ_VuldNTZNUZH8q2ddGnDJ1PVh_eKBB7uoGEPUO2Lbvws6VRw93FJDgIzqXUkPwDHMVOhhjsFBWBcBQtOh2fbVWyT1k9_jRFfeo2zJ3hPWat4kTns5Ew671LTXXGJwXGHGQB0bqGCqwhwBJGjwlPS7NFxhhDYocq8P-a1r6dyKzlEmh18hbWIc-Rz7z8h0_Fv-a4EeOmoNwTQMQl1QaWugyzqkqs1wUDKtJb7dHQBcMSkzJe-fylN3_peFQAyizDmhXmlZmjYqOOCHEXHuEyxbL8ILOK_8KxHyahhYwMSk13KrKVMR0ThHJkCcsfyA-hS9jzo4Ww8q3ARcr0R7Yb-361rI8aluKtbzPbGZHWwOQCMnDv8itfAOTiVU8l3vEfE6TPQUytUs03-_CGTmMrgpoxFxRDPkJFNwEa0671QbXV5AwJNTviSSe9q27H-I59Id0McwUZGQBn0z8XOjoJyCBZ27zRD9tSbBg-B1s1crfHk5RU8bIvgfwCsov_1oVufbxVy223DtdDzlernhw9DYzq5IUYKPoL-lZXZiS4er28lhBzPfXz4feDNHNSzB0cP_-4p-UlNtXaQtKzLGIu3WX-VqHW-=w2059-h1270',
    ogType: 'website',

    title: 'AI摄像头位置追踪解决方案',
    date: '2024-02-07',
    tag1: '#ai摄像头',
    tag2: '#位置追踪',
    tag3: '#车辆追踪',
    tag4: '#数字孪生',

    text: [
        {
            head: 'AI摄像头是什么？',
            subHead: '',
            desc: 'AI摄像头位置追踪解决方案利用内置人工智能技术，分析和处理摄像头接收到的视频数据，实时追踪人员和车辆的位置。这样可以在安全、交通管理、视频监控等多个领域发挥作用。与传统摄像头不同，它可以分析和处理视频数据，更加智能地运行。',
            dot: ''
        },
        {
            head: "AI摄像头的主要功能",
            subHead: "实时位置追踪",
            desc: "AI摄像头可以快速准确地实时追踪人员和车辆的动态，并识别并记录其当前位置。这样安全系统可以快速识别潜在威胁，交通管理系统可以实时监控交通流，并根据情况采取措施。",
        },
        {
            head: "",
            subHead: "视频分析和识别",
            desc: "AI摄像头可以通过视频分析和物体识别自动识别和分类视频中的各种物体。这样安全系统可以快速检测入侵者或异常行为，交通管理系统可以精确分析交通情况，预防问题并进行有效管理。",
        },
        {
            head: "",
            subHead: "支持多摄像头",
            desc: "AI摄像头可以整合多个摄像头，同时监视广泛的区域。这样可以覆盖更广泛的区域，并有效地收集多个角度的信息。此外，利用多摄像头支持功能，可以在交叉路口或大型建筑等各种环境中提供更强大的安全和管理解决方案。",
        },
        {
            head: "",
            subHead: "可定制功能",
            desc: "AI摄像头可以根据用户需求添加和设置所需功能。可定制功能可以灵活调整以适应各种环境，并根据特定要求提供准确和有效的监视。此外，通过定制功能，可以优化和升级AI摄像头的性能。",
        },
        {
            video : video,
            link : '/cn/enterprise/construction/equipment',
            head: "AI摄像头技术",
            subHead: "视频处理和分析",
            desc: "AI摄像头可以快速准确地实时分析摄像头拍摄的视频数据。最新的人工智能算法用于对象检测、跟踪、分类等主要元素。通过这些技术，AI摄像头可以快速处理视频数据，并准确识别和提取人员和车辆等对象。",
        },
        {
            head: "",
            subHead: "对象检测",
            desc: "AI摄像头可以快速检测视频中的人员和车辆等对象。利用最新的深度学习技术，可以准确识别和提取对象。这样，AI摄像头可以在各种环境中快速检测对象，并基于此进行进一步分析或采取行动。",
        },
        {
            head: "",
            subHead: "追踪和位置确认",
            desc: "实时追踪检测到的对象的移动，并确认其位置。这样，AI摄像头可以持续监视人员和车辆的移动，并在必要时迅速做出反应。此外，通过分析追踪对象的移动路径，还可以提供额外的见解。",
        },
        {
            head: "",
            subHead: "数据分析和存储",
            desc: "追踪的数据进行分析和存储。这样，AI摄像头可以分析追踪对象的移动模式或行为，并在必要时存储在数据库中以供稍后审查或编写报告使用。通过这种方式，安全系统可以分析事件的原因和进展，以更有效地应对。",
        },
        {
            head: "应用领域",
            subHead: "安全系统",
            desc: "AI摄像头可以实时追踪建筑内部或停车场等需要安全性的地方的人员和车辆移动，从而增强安全性。此外，通过分析这些数据，可以提供更精确的风险预测和应对措施。",
        },
        {
            head: "",
            subHead: "交通管理",
            desc: "利用AI摄像头在道路或交叉口等交通相关设施中实时监视和管理交通情况。这样可以预防交通堵塞和事故，并优化交通流。",
        },
        {
            head: "",
            subHead: "商业用途",
            desc: "商业设施如商店或购物中心可以利用AI摄像头追踪客户的移动路径和行为，优化店铺布局或制定营销策略。此外，还可以分析和改进客户偏好。",
        },
        {
            head: "",
            subHead: "智慧城市",
            desc: "通过在城市各处安装AI摄像头，城市规划者可以了解市民的移动模式，并有效管理城市基础设施。这样可以提高城市的安全性和便利性，并为智慧城市建设做出贡献。",
        },
        {
            head: '总结',
            subHead: '',
            desc: '利用AI的摄像头位置追踪解决方案可以实时追踪人员和车辆的位置，适用于多个领域的高效技术。安全、交通管理、商业用途等各种行业领域都可以确认其应用潜力，并提供更安全、高效的服务。',
            dot: ''
        },
        {
            head: '',
            subHead: '',
            desc: '',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: '将UWB终端安装在工人或设备上，以支持实时位置追踪的产品。',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/cn/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/cn/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/cn/enterprise/construction/equipment'
            }
        ]


    }


}
