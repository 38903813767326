import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4"

export default {

    helmet: '| 智能机场解决方案',
    desc: '实时跟踪旅行者的移动，并整合机场内的各种系统，以最大化运营效率。提供智能办理登机手续、自动安检、数字登机牌和登机门通知等服务。',
    keywords: '智能机场、数字孪生、NVR、IP、PTZ、RFID、NFC、UWB、无线通信、位置追踪、物体位置追踪、人物位置追踪、标签、信标、RTLS、orbro、contech',

    ogTitle: '',
    ogDesc: '',
    ogSite: '智能机场解决方案 | 位置追踪与数字孪生平台，Orbro',
    ogUrl: 'https://orbro.io/cn/blog/smart-airport-solution',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDnB84Bj2f8mz7hiRJi4WKgwiYq0yzs4GDe7VfFWOdEiQPlqEtyMMpSU-r7CcKy3BQ9FE63-XkxXVh-VAf440HOH1GA2iUOdHrknQN9GTI5y3ciXRGd5tlYsj4DhnmSkYTQpZas8pKO2QTEY2Yr1aKg7FEDLt0kRSvFKTZggTvuJepKze-kTLdpe_706Vc5UevQyGXNClLc9oJpNJDRuQC6tUHKnn_FiW52O6uSCoGFs28ONxKiUPRFE5Ju29eGYw_NfFWK5qCYQkem15ivnuMMmQSfx2L1JMQGPPP__JQ8WwQTWf0fqJZrIOChm-Ytl3cTDljPsL84S4SK3ww7wvpdBVVnfeI_Hg9DIMDCgf2m4_GsjXhNuJb8f9tyjQix7woDC35BeyLXjFP9o2ERnbG7fyvuPfPIRBgK5nDmmQ-wLv8t6GiBIp0c9271KpuXrz_4fq6Pq0V18HWuznis8j9ij1ifWmZi0BufNpYrlKZ2RdY4lq_6Gr9vc4ZPYRfJ-NFKGBI03EKiA9vlIrzcQTS3aEzIVovappIABtvO8PxqCkqhF0amIzISUH2Y5efT1YtPEGCevRdIC2I9_V7SvrQTvm0Qnp6vF-xmdybPAnJr0BD0vT05fpxoeHcN_WbUEtUQcbFsq6v1TanDDnB-XLp6Hj1LBhEApO0KLMdKr6hqANaGkrweuLO-ppA1rq_sNPCwSoX3tInq-mGVn9-HfzIH4u1P52EJjPxNZh60gOpVuZqkyH0P7dKgHso5PfMXAHJJwBIhCrzzILpZN8rrqZZvgP7AKPfAS06cevU6M0PaN0pZiHywpvCcQVdty1VzWLryj5hS4cQmTfrC3GcAHFJBDMgSUdZAhbiB7MSwtMhcD4ye-9kOD85_PonAQG9QMZMyrDPuna6Rnfr3NNhtq5VLL4M2wMxmiUDvxHg1WMRsQIoR-KUoTQ7imcUxIkCvzVkMe0UgWhSU3oamalc9rvOKud9cDgvAzuC1Pi4TUFJEZoHIvWiSKf6fqf7_yqlGHA5CggXGmBUVlSUJKMz4AF25WfOC3t3lBYczZ7sdmtLr4oU5MSzzsuoU1ZRAsXu21sJkggnYNxqgP12eulaDnKgNC2v2tUoVFG3FM0dx4L9exFCMlSC4o25cBb7aCwOiAArJL-pUeJfoo0RWP79r8X-Fai40OnK_c9Mu3JLlhPbxgOsjIIIqErT8CygE-qJyrNdf7TY6j9Onn6HFhBE3IeCPrkrAbSVNLPT43ZP_PQeII9yOMYE1U9feIAsW6V2sC75YSru67__inv-Qj90ltj2FbqExBTfA2Yr-kLz4oeF05ygNcG4IyVyMb40jXPlED8eAhW-C3QHc4YQUCMvuu6Di-Lbgtce_nkq_428rQwjdjJDzejxpTy6tyIKxtYbtWMLS2siVXz5PQ07dAbC6fOPlh5x1vyFEsMroUS20vNOqxpVKPYNog4lmhOQ5_KeNNrSPhRy2_zHR0c5l3sOApfG7vMjyOHlwSXmthwYqL1_Hgz_WbJ_B1jLdHt8fpAOw6KWq_yUsDx0yS9mIzNjDitluARF04p2PJ=w2038-h1285',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '智能机场解决方案',
    date: '2024-01-16',
    tag1: '#智能机场',
    tag2: '#RTLS',
    tag3: '#数字孪生',
    tag4: '#位置追踪',

    // <br/>
    text: [
        {
            head: '智能机场解决方案',
            subHead: '',
            desc: '智能机场利用现代技术和先进系统为旅行者提供最佳服务的机场。目标是通过各种智能技术和数字创新最大化效率，革新旅行体验。<br/>包括室内环境控制、基于人工智能的安全系统、RTLS技术等，通过利用各种智能技术来优化旅行体验并最大化效率。智能机场致力于在旅行的每个阶段提供优化的服务，关注提高客户满意度。',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/cn/enterprise/construction/equipment',
            head: '智能机场技术',
            subHead: '',
            desc: '智能机场利用各种先进技术为旅行者提供创新和便利的服务。可以在智能机场引入的技术包括以下几种：',
            dot: ''
        },
        {
            head: '',
            subHead: '室内环境控制系统',
            desc: '智能机场引入室内环境控制系统，以为旅行者提供最佳环境。通过实时监控和调整机场内的温度、湿度和空气质量，旅行者可以舒适地等待。此外，智能照明系统模仿自然光，促进能源效率提高，为旅行者提供生活便利性。',
            dot: ''
        },
        {
            head: '',
            subHead: '基于人工智能的安全系统',
            desc: '安全是旅行的核心价值之一。智能机场通过使用AI摄像头建立高级安全系统。结合人脸识别技术、行为分析以及检测不当行为，提高安全水平，为旅行者提供安全感。同时，实时检测异常迹象并立即做出响应。',
            dot: ''
        },
        {
            head: '',
            subHead: 'RTLS（实时定位系统）技术',
            desc: '智能机场利用RTLS技术追踪旅行者的实时位置。通过这种方式，机场可以与门、商店、行李运输系统有效协同工作，优化旅行者的移动。此外，它还提供有益于路径分析和安全管理的数据。',
            dot: ''
        },
        {
            head: '',
            subHead: '数据分析与人工智能',
            desc: '使用大数据和人工智能进行数据分析在优化智能机场运营中发挥着重要作用。通过分析旅行者的移动模式和服务使用趋势，可以实现效率化运营和改进。这使得机场管理者能够有效分配资源，为旅行者提供更好的服务。',
            dot: ''
        },
        {
            head: '智能机场的优势',
            subHead: '',
            desc: '',
            dot: {
                a: '<strong> • 便利的旅行体验：</strong>智能机场通过自动办理入住流程和实时信息提供，为旅行者提供高度便利。使用人脸识别的自动入住和安全验证，旅行者可以轻松移动，并更容易访问服务。',
                b: '<strong> • 提高的安全性与保障：</strong>利用AI摄像头和人脸识别技术的智能安全系统提高了旅行者的安全性，提前发现机场内潜在的事故或威胁。从而确保更安全可靠的旅行环境。',
                c: '<strong> • 高效的运营和资源管理：</strong>通过数据分析和大数据的利用，可以优化智能机场的运营，并有效管理资源。人工智能技术了解旅行者的移动模式，有助于提高运营效率。',
                d: '<strong> • 优化的室内环境：</strong>通过室内环境控制系统，旅行者可以在舒适的环境中等待。通过实时监测和调整温度、湿度、空气品质，提高旅行者的便利性，提供高品质的等待环境。',
                e: '<strong> • 快速的处理和入场手续：</strong>自动处理减少了旅行者的不便，提供了快速的入住和入场流程。这使得旅行者可以节省时间，舒适地移动。'
            }
        },
        {
            head: "智能机场案例",
            subHead: "哈马德国际机场（DOH），卡塔尔",
            desc: "多哈国际机场积极引入智能技术，作为智能城市的一部分提供先进的服务。自动导航服务、智能安全系统和通过移动应用程序为旅客提供方便的服务已经引入。",
            dot: ""
        },
        {
            head: "",
            subHead: "樟宜机场（SIN），新加坡",
            desc: "樟宜机场通过引入创新技术积极提升，被认为是全球最佳机场之一。自动办理入住系统、智能行李处理以及机场内的智能站点为旅行者提供了先进的便利性。",
            dot: ""
        },
        {
            head: "",
            subHead: "迪拜国际机场（DXB），阿联酋",
            desc: "迪拜国际机场通过智能技术和创新服务演变为国际旅行枢纽。引入了基于人工智能的智能安全系统、汽车和通过移动应用程序提升旅客体验。",
            dot: ""
        },
        {
            head: "",
            subHead: "旧金山国际机场（SFO），美国",
            desc: "旧金山国际机场以高效的运营和智能服务而备受关注。使用物联网技术的室内环境监测、智能行李处理系统以及通过移动应用程序提供给旅客的便利性改善是其特点。",
            dot: ""
        }

    ],
    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: '该产品通过安装UWB设备在工人和设备上，支持实时位置追踪。',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/cn/enterprise/medical/patient'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/cn/enterprise/retail/exhibition'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/cn/enterprise/office/asset'
            }
        ]

    }

}
