import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/logisticsAsset/video/sub/rtlsVideo4.mp4";

export default {
    
    banner : {
        title : '물류 재고 관리',
        desc : '재고 관리는 공급망의 효율성과 원활한 운영을 지원하는 중요한 부분입니다. 실시간으로 재고의 위치를 파악하면 재고 수준을 효과적으로 모니터링하고 재고 이동을 최적화할 수 있습니다. 예를 들어, 품목의 인기도에 따라 재고를 배치하고 재고 회전율을 최적화할 수 있으며, 이는 비용 절감과 재고 부족이나 과잉을 방지하는 데 도움이 됩니다.',

    },

    video : {
        title : '스마트 물류 재고 관리 방법.',
        desc : '실시간 위치 추적(RTLS) 기술은 재고의 위치를 실시간으로 추적합니다.<br/>정확하고 빠르게, 그리고 간단하게 재고를 관리하여 <strong>최고의 효율</strong>을 이끌어 냅니다.',
        play : video
    },

    func : [
        {
            title : '입출고 자동화',
            desc : '재고가 특정 지점을 통과할 때, 자동으로 입출고 기록을 생성합니다. 이 데이터는 정확하므로, 사람의 실수를 방지하고 인력을 절약할 수 있습니다.',
            sub1 : '높은 정확도 제공',
            icon1 : IconRulerGray,
            sub2 : '빠른 데이터 연동',
            icon2 : IconShareGray,
            video : subVideo1
            
        },
        {
            title : '정확한 재고 수량 관리',
            desc : '품목의 인기도에 따라 재고를 배치하고 재고 회전율을 최적화할 수 있으며, 이는 비용 절감과 재고 부족이나 과잉을 방지하는 데 도움이 됩니다.',
            sub1 : '실시간 현황 파악',
            icon1 : IconClockGray,
            sub2 : '통계 데이터 제공',
            icon2 : IconChartBarGray,
            video : subVideo2
        },
        {
            title : '시간 절감',
            desc : '정확한 재고 위치 정보를 가지고 있다면, 물류 작업자들은 물품을 신속하게 찾아내고 필요한 위치로 이동시킬 수 있습니다. 이를 통해 작업 시간을 단축시키고 생산성을 높일 수 있습니다.',
            sub1 : '실시간 위치 추적',
            icon1 : IconBinocularsGray,
            sub2 : '높은 정확도 제공',
            icon2 : IconRulerGray,
            video : subVideo3
        },
        {
            title : '실시간 위치 추적',
            desc : '재고 손실과 도난을 방지하는 데 도움이 됩니다. 재고가 각 위치에 할당되고 실시간으로 추적되면 재고의 유실이나 도난을 신속하게 감지할 수 있습니다. 이를 통해 재고 손실을 최소화하고 회수 조치를 취할 수 있습니다.',
            sub1 : '빠른 데이터 연동',
            icon1 : IconShareGray,
            sub2 : '실시간 현황 파악',
            icon2 : IconClockGray,
            video : subVideo4
        },


    ],



    subfunc : {
        
        title : '다양한 기능',
        desc : '물류 산업의 핵심은 공급망 관리입니다. 그리고 재고 관리는 공급망의 효율성과 원활한 운영을 지원하는 중요한 부분입니다.<br/>가장 최적화된 시스템을 구축하기 위해, 오브로는 다양한 기능들을 제공합니다.',
        
        data : [
            {
                icon : IconChartRadius,
                title : '리포트 및 분석',
                desc : '현장 데이터를 기반으로 보고서 및 분석 자료를 생성할 수 있습니다.',
            },
            {
                icon : IconBell,
                title : '실시간 알람 기능',
                desc : '재고 수량 이상 상황이 발생하는 경우 알람을 보내 즉각적으로 대처할 수 있습니다.',
            },
            {
                icon : IconRoute    ,
                title : '동선 이력 조회',
                desc : '재고의 이동 경로를 조회하여 데이터화 할 수 있습니다.',
            },
            {
                icon : IconDashboard,
                title : '현황 파악',
                desc : '현재 특정 재고가 어디로 이동하고 있는지 파악합니다.',
            },
            {
                icon : IconWarning,
                title : '이탈 감지',
                desc : '재고가 지정된 위치를 벗어나는 경우를 감지합니다.',
            },
            {
                icon : IconSearch,
                title : '동선 패턴 분석',
                desc : '중장비가 현장에서 얼마만큼 사용되고 언제 쓰였는지 분석할 수 있습니다.',
            },
            {
                icon : IconChainLink,
                title : '데이터 연동',
                desc : 'RFID, IOT, 보안, 영상 등의 시스템과 연결하여 효율적으로 활용할 수 있습니다.',
            },
            {
                icon : IconSecurity,
                title : '데이터 보안',
                desc : '높은 데이터 보안 수준을 유지하여 신뢰성이 높은 솔루션을 제공합니다.',
            },


        ],
    },

    system : {
        title : '시스템 구성',
        img : rtlsSystem
    },

    productTitle : '제품 구성',
    product : [

        {
            tag : 'assetPackage'
        },
        {
            tag : 'workerPackage'
        },
    ],


    contents : [
        {
        data : 'logisticsSafety'
        },
        {
        data : 'constructionAsset'
        },
        {
        data : 'blogRTLS'
        },
        {
        data : 'blogForklift'
        },

    ]



}