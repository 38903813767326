import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet: '',
  desc: `在机场发生的丢失、盗窃、设备定位等问题是常见问题，导致资源浪费。为了解决这些问题，可以引入RTLS（实时定位系统）解决方案。`,
  keywords: 'RTLS，资产管理，资产追踪，反恐，防止恐怖，仁川机场，国际机场，机场，机场安全,航空,航空安全,保安',

  ogTitle: '',
  ogDesc: '',
  ogSite: '在机场使用的RTLS :: Orbro Blog',
  ogUrl: 'https://orbro.io/cn/blog/airport',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EOZesBoVKMwIFFry24uoX1306-E2-KIndjXjngk3HQJAqvdeMd-lpFrtUa5bQkacaOFn73rL9aZpISCh6BfSvBY8Rj6pgLzP098EmKNW5WO0k0RSNeqTxeIIE_LiFLF3sUcJc7VhuL6arfAuAQ4APzhaT4aTqEMd8WiAzJwEEVEK3UEevHgYg7ZmwsN-ImqYMFbr01G9VkLQVPdClEaxND6eKem2wvryX-MyUhMvVWmXvao5e8JdFoNh7pmf0ED7MFgYJTg8fxDAxUsbI2jFKwsQCXpbmLsVjR7VcwSNln_gq6BqHDdpB_gte6scXAcPWT5vxnkbmDs22PEDiPDoNcyewtDBBFdtglgRga94O9jfNboErHhvfBncigoMjDxtW7TYVYAps24D2VMURGxOoAx8EquJAIaYIcLlBk2zaSrqdtCeCJGD8Il7sLifXjYw4s7qUr9XFF35LROheqZAC_0kOQ5mI85j5IkNvcvUuT0Wpg9K9Tg1uovCttIBSCD5hfEs1i5gQOxwB7fK0t9kkppR_tDz4HPjIo3i-QfSV3VtiERhnb0gIWUVAFX5jrN8rwX4iTKGpNR55yhogdwRDaSLkHePzB-Xnsn0SwSjXlonVCX02p4Zn02NM8FsMa8fNMfP7Rp4-NIciOdAehNLDjZzwFH6hXgArjIg7_CtUHBeiV5gQ9GX7PqRU4TtZXbljhqePIobwwEd_XHrXRNxAyMxnCU45iqtZUwO_dZyKmhfAZ7XDrUUK0gMaNhguKiZEIm3ZeBZN_082FE9ifEja5-LCI4-QC60gzDs8bYmOrqsotPyR47V6zTrFzRiKDRfwmiLCdbRdQsy3iLV5ph0yhVab492zrx1LaPY39eCtfQzTyDRXrgrwxrAnp54XYMJYZ23TEVgan4Sg7MwiQiYw_Y9z2c1WwAt-TE7rpWOR5Gn2um6jOBrrfo_09UF9nfV4o02ZGF4NWQ99ZIRwBmOg6KODX6mQNtxOjU60pRIpQj0BUCj5JCE5P99E2FRUfxKE89tWwftzf0WpV4XuY3XCnSVpT1DW8OAdsfQyv5GyiSm2ikSlRAq8s0nJAoPv5gcqTR5rwl-QI7YOwucwvhQgM0N3jhPjqcfCsZ0tEP4V-IVLpT1k5_LGmhBueHvi8jZinIgZ0YWZov9jEWK4ysorc637zHhZpy_infWTuHbzXNlLpxoWRsQCbsvGYNKsAVJIi91R85rK5tRbEiMFXA6l67UP2CxWsZHXN_IofYYJmQrT7t6IreFaZrouew2I-5bE-grBY53BCNZ_uk2rE8BPkifZU4ldeMi-0qDyy93SH-XeiDqOWadwsB8yHsrx-6_2UNxAeQzzFEwJtNv0tB4WWtyXgGyX94icliihgFcgXBYPX-t4tGwNJd776YIxod1tvww4WR2a-m5idyeoKDjuWUQrMWHd6JuMKFGGxnIjzUKIQMTx6q2vzwdG62nR80hG1B0_osyET4FeVfNTRdVzDRNcEtrlSSbgcQfMg0Q9LKROQcN6smgad6gObnA6mQB3Ghvo_9zXSkfAufJdY9Kg4g7Px31v1Z2fV3IhA9rzOE5Qg=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RTLS的4个主要优势',
  date: '2023-05-16',
  tag1: '#RTLS',
  tag2: '#资产管理',
  tag3: '#资产追踪',
  tag4: '#定位',

  text: [
    {
      head: 'RTLS是什么？',
      subHead: '',
      desc: 'RTLS是实时定位系统的缩写。它是一种利用Wi-Fi、蓝牙和RFID标签信号实时追踪物体和人员位置的技术。当有标签的物体或用户经过其中一个传感器时，标签的信号会被接收和处理。然后传感器计算物体或用户的位置，并将这些信息发送到计算机以进行追踪和分析。',
      dot: ''
    },
    {
      head: 'RTLS的4个主要优势',
      subHead: '',
      desc: 'RTLS的优势在于对成功的业务至关重要。为了在竞争激烈的市场中保持领先，高效和有序的资产管理是必要的。通过RTLS，可以实现这一目标。 RTLS可以提高工人的生产力和安全性，支持企业更加高效和有效地运营。',
      dot: ''
    },
    {
      head: '',
      subHead: '提高效率',
      desc: 'RTLS通过更准确地追踪和管理资产来提高企业的效率。这是通过在物体或人员上安装小型标签，并由一个或多个传感器接收和处理信号来实现的。然后传感器计算信号传输的距离和方向，以确定物体或人员的准确位置。',
      dot: ''
    },
    {
      head: '',
      subHead: '降低成本',
      desc: 'RTLS的一个基本优势是帮助降低成本。通过改进流程效率并减少浪费。通过RTLS实现成本节约的方式包括：',
      dot: {
        a: '• <strong>院务管理</strong> - 设施管理员可以跟踪资产和员工，以确保适当的人员和设备在适当的时间和位置。因此，可以减少加班的需要，改进工作流程，并防止资产的丢失或损坏，从而实现显著的成本节约效果。这在医疗设施等存在许多运动的场所尤为重要。',
        b: '• <strong>供应链管理</strong> - 供应链管理只是优化通过供应链的商品和材料流动。这涉及到需求预测、材料采购、产品生产、向客户交付产品、库存管理等等。为了确保一切顺利进行，需要有关每个步骤发生情况的准确信息。RTLS可以提供这些信息。',
        c: '• <strong>制造业</strong> - 制造商可以使用RTLS来优化生产计划，减少废物，并改进质量管理。制造业需要快速准确地应对客户订单。他们需要跟踪库存并确保正确的产品得到生产。',
        d: '• <strong>医疗服务</strong> - RTLS可以改善医疗行业的患者安全，缩短等待时间，降低成本。例如，医院可以跟踪员工、医疗设备和患者的位置，以确保每个人都在适当的时间处于适当的位置。这有助于减少错误风险，改善患者管理，并最终缩短住院时间。',
      }
    },
    {
      head: '',
      subHead: '提高生产力',
      desc: '员工生产力是组织效率和成功的重要指标。 RTLS通过提供有关员工和资产位置的准确实时信息来提高生产力。这样可以改进协调并消除浪费的时间。<br/>对于生产力可能会受到影响的许多因素，比如员工找到和检索物品所花费的时间、由于不恰当的处理或产品损坏导致的废料、需要停工休息时间等。',
      dot: ''
    },
    {
      head: '',
      subHead: '保障安全',
      desc: '无论是什么类型的组织，员工的安全都是首要的。当员工对周围环境不了解时，可能会发生受伤或死亡事故。在某些情况下，如制造业或建筑业，员工需要穿戴可能干扰视力和听力的安全设备。<br/>RTLS通过提供员工周围环境的信息和安全警示，以及有关危险区域的信息，可以防止安全事故的发生。',
      dot: ''
    },
  ],



  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc:"将UWB终端机安装在工作者或设备上，帮助实时追踪位置的产品。",
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]


  }

}