export default {
  titleBanner: {
    subtitle: 'Monocular Vision',
    title: '利用Monocular Vision的<br/>定位技术',
    description: '* Monocular Vision是利用单眼相机获取和分析图像的计算机视觉技术。  <br/> 立体视觉技术作为对比技术，立体视觉技术采用两个摄像头测量深度，并反映在定位上。'
  },

  tech: {
    title: "Monocular Vision是什么？",
    text: `Monocular vision是利用单眼相机获取二维影像信息的视觉技术。 该技术非常简单，只要有常见的单眼相机就可以轻松实现，在很多领域都有应用。 因其易于引入普通单镜头相机，被广泛应用于各个行业。  <br/><br/>Monocularvision用于客体识别与跟踪、自动驾驶、机器人工程等各个领域。 例如,在客体识别领域,利用深度学习算法识别和追踪Monocular图像序列内的客体的技术已经普及。  另外，在无人驾驶领域，利用相机识别道路上的车道、信号灯、指示牌、行人等，并推测周边车辆的位置和速度，用于安全行驶。`
  },
  feature: {
    title: `利用Monocular Vision的RTLS的优点`,
    text: 'Monocular Vision技术由于使用了单眼相机，所以建立系统所需的费用相对较少。 单镜头相机与立体相机相比,单价相对较低,因此大规模安装或在广泛领域构建RTLS时,可以比其他技术更便宜。 另外,与机器学习技术相结合,以之前学习的数据为基础,实现准确的位置推测。 这保障了较高的准确度,如果利用最新的深度学习技术,即使用少量的数据也可以学习,因此构建及运营费用也会减少。 与传统的RTLS相比，使用<br/>MonocularVision的RTLS在成本方面具有很大的节省效果，因为它不需要Tag。 不需要单独的Tag终端意味着安装和操作方便，与现有设施兼容性高，易于引入。 另外，无需安装接收Tag信号的设备（Anchor），因此可以提高空间利用率，并以此为基础提高设施效率。'
  },


  howItWorks: {
    title: '单目视觉（Monocular Vision）用于定位的工作原理是什么？',
    card: {
      detection: {
        title: '目标检测',
        description: '在基于视觉的实时定位系统（RTLS）中，使用深度学习目标检测技术来跟踪图像中的目标。这项技术是经过训练的人工智能算法，可以识别和分类图像中的各种物体。目前，它可以识别约80种不同类型的物体，并可以通过根据客户要求进行额外训练来识别更多种类的物体。'
      },
      estimation: {
        title: '透视变换',
        description: '我们可以使用透视变换将图像上的特定点转换为实际世界的坐标。为此，我们利用相机的内部和外部参数构建相机矩阵，然后使用该矩阵将图像上的点转换为实际空间中的点。'
      }
    }
  },
  difference: {
    title: '单目视觉（Monocular Vision）与立体视觉（Stereo Vision）用于RTLS的区别是什么？',
    description: '单目视觉（Monocular Vision）和立体视觉（Stereo Vision）在基本工作原理上有所不同。单目视觉只使用一个相机来追踪对象的位置，而立体视觉则使用两个相机，通过计算两个相机之间的时间差（视差）来追踪对象的位置。单目视觉相对较便宜且易于实现，并且安装和维护成本较低。但由于仅使用相机提供的二维图像来追踪对象的位置，其准确性和可靠性可能相对较低。<br/><br/>另一方面，立体视觉的硬件复杂度较高，因此引入、安装和维护的总成本较高，软件处理也更为复杂。即使处理相同的图像，立体视觉RTLS需要更大的计算资源。因此，在选择Monocular Vision和Stereo Vision之间的RTLS实现方式时，应考虑用途和预算等因素来做出决定。'
  },




  advantage: {
    title: '单目视觉（Monocular Vision）的主要优势',
    items: {
      accuracy: {
        title: '相对较便宜',
        description: '单目视觉（Monocular Vision）只使用一个相机，因此构建硬件的成本相对较低。此外，由于仅使用单个图像传感器，软件处理也相对简单，因此可用较低配置的硬件来实现。'
      },
      position: {
        title: '安装约束较少',
        description: '单目视觉（Monocular Vision）可以追踪和监测物体的位置和移动，无论相机安装在何处。这意味着适用于各种不同的应用领域。例如，它可以用于室内定位，自动驾驶车辆的行人检测和追踪，运动比赛中运动员的追踪等多个领域。'
      },
      security: {
        title: '适用于多个应用领域',
        description: '单目视觉（Monocular Vision）技术在仅使用一个相机的情况下即可实现物体的追踪，因此安装和维护较为简单，成本相对较低。因此，它适用于智能家居，机器人，汽车等日常生活中广泛使用的领域。'
      },
    }
  },


  useCase: {
    title: '单目视觉（Monocular Vision）用于定位的使用案例',
    description: '单目视觉（Monocular Vision）的可扩展性在各种室内定位使用案例中发挥着重要作用。以下是一些应用场景和领域中使用的一些单目视觉定位技术。',
    card: {
      home: {
        title: '智能家居',
        description: '智能家居技术提供了监测和控制家庭内各种设施的功能。利用单目视觉技术，可以检测人和物体的位置和移动，并提供更高效的能源使用、安全性和便利性。',
        items: ` `
      },
      space: {
        title: '空间占用率分析',
        description: '通过收集建筑内部或停车场等空间的占用数据，可以优化空间的利用率。利用基于单目视觉的实时定位系统（RTLS）技术，可以通过在空间内部安装相机来实时掌握其中物体的位置和移动。这样，可以了解空间的使用情况，并提高空间的利用效率。',
        items: ` `
      },

      safety: {
        title: '工业安全',
        description: '单目视觉的实时定位系统（RTLS）技术在工业现场中也用于保持安全。例如，在工作区域内实时追踪人员或物体的位置，有助于预防潜在的事故。特别是在工业现场中，如果有人员倒地或工人受伤，可以快速检测并采取行动。RTLS系统能够及时检测到工业现场中发生的各种潜在危险情况，帮助及时应对。这样可以确保工人的安全和健康，并提高生产力和效率。',
        items: ` `
      },
      analyse: {
        title: '展品偏好分析',
        description: '单目视觉技术可以用于分析顾客对展品的偏好。通过跟踪展品附近的访客移动和兴趣，可以识别对特定产品感兴趣的顾客，优化展品的位置和布局设计。这对于市场营销领域至关重要。',
        items: ` `
      },
      sports: {
        title: '体育分析',
        description: '利用单目视觉技术，可以准确跟踪和分析体育比赛中选手的位置和移动。这有助于改进选手的运动，制定比赛策略等。',
        items: ` `
      },
      security: {
        title: '安全',
        description: '基于单目视觉的实时定位系统（RTLS）技术在安全方面也有多种用途。例如，可以跟踪物体或人员的移动路径，用于未经授权的人员进入限制区域的访问控制系统，闭路电视监控等。这些技术可以应用于建筑出入口或重要设施的安全管理，军事工业等领域。',
        items: ` `
      },


    }
  }



}