import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet : '',
  desc:"RTLS（リアルタイム位置システム）は位置情報をリアルタイムで追跡および監視するための技術であり、この技術を活用したデジタルツインは現実世界の物理的なシステムや空間をデジタル的に模倣し、リアルタイムで管理および分析するシステムです。",
  keywords:"Digital Twins, Digital Twins, デジタルツイン, RTLS, 位置測位システム, 位置測位技術、 スポーツ、 製造、 物流、 医療、病院",

  ogTitle : '',
  ogDesc : '',
  ogSite:"デジタルツインとリアルタイム位置測位システム :: オブローブログ",

  ogUrl : 'https://orbro.io/jp/blog/digitalTwin',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EObM89yl-Fckym9cymsbb7S7KBZ-fORj-iI1ch019MZXKtGLBD8Q6iWqftjthjE-64HwCxCX7XRPR10bcSBzxfbbHdVe_fmhy-hRrTTbEqa1kmSlG5WJwe_aFIG3PMwmlcPU90rQL2e9sjumg_2pSrR0sgMw6IN_LEwA-To3jUhSTh42IZmzhH0_1e5TJi90KWyaQEDki6hm93269tWDF2Xfm3oZpVefWKgJ7Xnv0NVZ3NMHBGkLEZz236wZHYh8GuRwDBtgHG8EKrm3c0X_9wkhJsdZATZNeCHPBUqyEDFMGmqaOnj5TYLfoJ25Il3v1m91oZtuoSchfnhmgHThYM6qbzj5ID4OSkyrRre3W3EpjouXL3zLHCJSOvOoHA6Kc3r_K58o7PuCQtVLs0b-lmcGHhXfC4peLT-9_8cWEisLN9cPEA_4hg5HT3tZ3zW9xG0WaU809hPnE9sBfCUriBFXpQaHHI6SIZ6lDLYSYJ2AzxYXxTqNWcCd3cvdbm4Vv9zzDaYP8phmcP1-TVhwKjMS3djddsWAPjxzbbqx4X6P4S3O5qCgKsCPMzOSiYDGSneF9xLkcFpLm13KT7xUcDV4wWlR-PrM01GTS66BvLHflJa_pg0ZtJte4DAIBu8TPsiDoiLjxT1WB69G-OYqtGnDrXVpUJkk8hhnK2n02PqcAwDA6s60Zvo_qKRnDXqbwkYe1qc_pwctFBShNNA-2NZATqu3r4Fl2gLy07d1dG9Rz39jB7tKKIWSeTBl-bpslCsuyWQaK6IxF19oGBhANRlIwpMHQfSJOWVSeE0QtKA4hlzcONIb28Iz8FfnYJUlNL_mEEKfC2QsgCP9QhKJ9xHYPPn_GBZ8TFuQSZvsDQvJ64s1teGa1Z8KdsteqgEG7iP_QxD_hfYHTEZc4c-EiDHiwwlrKrHPp5hLPgjMr9mWLUinWGKPTnPS39qBnM9Qq4cscUTNNGRDPumUYh7NIDmCR5PZ60qw2qCTSOcmlOQGvIaiZSEj9VpXBoLgEmJQHBylfdfduwNMLkIbbBdu9HKVJhcKkcIM0pgxhUjIX1yLgaNBg2GPWwEW9ep8pBep3OFKcdtehYRxHKLcCC6-69tQcLLslYLGTFyGoVokZ4dtBpy1lLWIG_YraeJ7yalMNg2J4yOYvwoLTInt3G6hSnU37a6twsvEp9TJR6z9jo2A9chkGqWyeg7bXB4Sc8-lIwwhlEXGQgW1MWX-h9kBUvZNEs0-_Jx6Drdfi0vYIfaK5-6GhfEZTVGuS6zzHCCpUNMFfu3Cu06IA7BWvunUR10RDVViw1gKdqrBdd5tgyrTqBoPEUaaBJIK9Dw0UcMRSP97gbKb6Slr49dZCyN2toItkq1fWOxtM6Of7AUzY3aGinq1R675xtbTceH2_QpwYO9HwgJbLHYdDtsgeum0buiGPEhInEHCe44zxXvOYzNzs1fRs9aPAM-H_ukO3HPnSAJ8yHWnnKTZ90M-KQQ_PMMuVgY7EKiGZMDGPNxxN-yVxPjSbFoQPtWLvLWA2XkWK8ExgJN4YuLR29U-f8ZiwcIQQwNA3gWp3qNF7QwyMjjZCg=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
  
  title: 'デジタルツインとリアルタイム位置測位システム',
  date: '2023-04-22',
  tag1: '#デジタルツイン',
  tag2: '#RTLS',
  tag3: '#位置測位',
  tag4: '#UWB',


  
  // <br/>
  text: [
    {
    head: '',
    subHead: '',
    desc: 'コロナとの戦いによる長期間の封鎖とグローバルなサプライチェーンの崩壊により、多くの産業技術は停滞していましたが、産業界では多くの技術が復活し、これらの技術の利点がより明確になっています。多くの製造業、物流業、および交通機関会社が人材不足と厳しい締め切りに悩んでいる中、熟練したマネージャーは負担を軽減できる技術的なソリューションを探しています。そのため、RTLSとデジタルツインの市場成長の展望は明確になっています。 <br/>RTLSとデジタルツインは製造業で発展してきましたが、現在ではさまざまな産業で活用され、新たな応用分野に進んでいます。これらの技術は生産性向上だけでなく、人材不足や厳しいスケジュールに対処するための企業の取り組みとして積極的に導入されています。',
    dot: ''
    },
    {
    head: 'RTLSの需要の増加',
    subHead: '',
    desc: '調査によると、RTLS市場は現在25.1％のCAGR成長率で成長すると予想されています。2030年までに、さらに多くのデバイスがインターネットに接続されることにより、企業はモバイルおよびウェアラブル技術を活用して生産性を向上させ、より良いインサイトを得ることが期待されています。位置測位技術を利用した事故予防やエラー回避アプリケーションの適用が増えることで、新たなRTLSの販売が促進されています。また、パンデミック後の健康関連の応用分野の急増もこの成長を牽引しています。<br/>アジア太平洋地域では、産業用IoTの採用の増加が交通、製造、医療、政府および国防産業におけるRTLSの採用の成長を促しています。インドの製造業部門が1兆ドルの基準線に向けて進んでいる中、倉庫、輸送、物流などでも大規模なRTLS契約が形成されています。',
    dot: ''
    },
    {
    head: 'スポーツ分野でのRTLS',
    subHead: '',
    desc: 'トップのスポーツ選手やチームは常に自分たちのパフォーマンスを向上させる方法を探し、テレビネットワークは常にスポーツファンの視聴体験を向上させる方法を探しています。過去1年間、RTLSはこれらの2つの側面でさまざまな方法で役立っています。まず、バスケットボールのトレーニングや試合で、選手のボールコントロール技術やスピード、加速度などの重要な指標を分析するために位置測位システムが導入されています。 <br/> カタールワールドカップでは、サッカーボールがUWB技術を活用してボールの各接触ごとに記録されました。このデータはオフサイド判定の迅速かつ正確な行われるのに役立ち、ライブTV放送やスタジアムモニターに投影され、ファンが審判の判定を理解できるイメージを提供しました。これはカタールワールドカップが記録上最も平和な大会になるのに貢献したと考えられています。',
    dot: {}
    },
    {
    head: '医療分野でのRTLSの成長',
    subHead: '',
    desc: '病院や介護施設の複雑さと高いリスクのため、医療分野はRTLSの展開が難しい分野の一つでした。しかし、最近ではさまざまな目的でRTLSソリューションの需要が高まっています。 <br/><br/>多くの病院や介護施設では、COVID-19パンデミックを予防するために患者を測位するためにRTLSを使用しています。これは大流行病のリスクを防ぐための病院の運営上の目的です。また、RTLSを使用して病院内の人員とリソースの流れを最適化し、機器の使用や患者予約に対する自動スケジュールを生成することで追加のコスト削減を追求しています。訴訟までRTLSをコスト削減策の一環として取り入れる動きも見られます。病院はRTLSを使用して運営を監視し、過失を防止するのに役立つと期待しています。 <br/><br/>バーチャルワードは新しい概念ではありませんが、RTLSは中央集中型プラットフォームで患者管理をより円滑にすることができます。オーストラリア政府保健省は「自動レポート作成は、老人介護スタッフがレポート作成に費やす時間を短縮し、関連するコストを削減します」と主張しています。ウェアラブルセンサーを使用して、患者の健康状態を自宅でモニタリングすると、従来の方法よりも迅速に医師に問題を報告することができます。これにより、治療が必要な患者のベッドを確保することができ、待機患者を減らすのに役立っています。シンガポールでは、成功したパイロット運用の後、保健省がバーチャルワードのパイロットプロジェクトを拡大しています。',
    },
    {
    head: 'デジタルツインの登場',
    subHead: '',
    desc: 'あるレポートによると、デジタルツイン市場は現在95億ドルの価値を持ち、2032年までに726.5億ドルになると予想されています。現在、トランスポートおよび自動車部門がデジタルツイン技術の最大の市場シェアを持っていますが、医療、通信、不動産、小売業などへの拡散がすでに進行しています。RTLSとデジタルツイン技術は密接に関連しているため、アジア太平洋地域でも類似した成長パターンが現れると予想され、次の数年間で40％のCAGRが可能と予測されています。',
    },
    {
    head: '都市のデジタルツイン',
    subHead: '',
    desc: '以前はデジタルツインは一般的に製造ラインや自動車工場、物流センターなどに限定されていましたが、最近では全体の都市に適用される概念が現実のものとなり始めています。 ITC、BS、ETの研究者らは、デジタルツインが都市計画と調整に役立つことを証明した研究報告書を発表しました。この研究の主著者であるVille Lehtola博士は、「デジタルツインは都市のインフラとサービスに関する最新情報にアクセスできるようにすることで、都市に重要な利点をもたらすことができる」と述べています。 <br/><br/>都市全体でデジタルツインを使用する広範な目的は、時間とコストを節約し、都市の環境をより持続可能にすることです。これには交通騒音、日射量、道路安全、公共交通システム、ごみ処理、および警察および消防の対応時間のモニタリングが必要です。これらの領域でデータを初めて集め、都市の生活の質を劇的に向上させることができます。<br/><br/>ほとんどの都市ではこれが仮説的なものとして残っていますが、イギリスのバーミンガムでは既にデジタルツインが実装され、都市の東部地域に恩恵をもたらすための「テストベッド」として使用されています。スマートホーム、クリーンエアゾーン、地域の成長戦略の影響を確認することが目標です。RTLSとデジタルツインの領域は非常に興味深い進展を見せており、市場の成長予測は製造業全般だけでなく、航空宇宙、ヘルスケア、および公共交通分野でも大きな利益をもたらすことが予想されています。',
    },
  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/jp/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/jp/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/jp/enterprise/logistics/asset'
      }
    ]

  }

}