import { StyledGridColumnProps } from "@components/Grid/Grid.model";
import { SCREEN_BREAKPOINT, StyledGridContainer } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";

export const BannerPage = styled.div`
  width : 100%;
  margin-top : 52px;
  height : 640px;

  display : flex;
  justify-content : center;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    height : 520px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    height : 480px;
  }
  
  & * {
    font-family: Pretendard !important; 
    font-display: swap;
  }

`;


export const BannerLayout = styled.div`
  width: 100%;
  height : 640px;
  position : relative;
`;




export const BannerSection = styled.div`
  height : 100%;
  width: 100%;
  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: darken;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
  }
`;




export const BannerImg = styled.div<{img : string}>`
  width : 100%;
  height : 640px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: darken;
  
  position : absolute;

  ${({img})=> img && `background-image : url(${img});`}

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    height : 520px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    height : 480px;
  }

`


export const ImageInner = styled(StyledGridContainer)`
height : 640px;

  display : flex;
  align-items : center;
  justify-content : flex-start;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    height : 520px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height : 480px;
    
  }
`;



export const ImageInnerBox = styled.div<StyledGridColumnProps>`
  width : 640px;
  display: grid;

  -ms-grid-column-span: ${({ desktop }) => desktop};
  grid-column-end: span ${({ desktop }) => desktop};

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    width : 100%;
    -ms-grid-column-span: ${({ tablet }) => tablet};
    grid-column-end: span ${({ tablet }) => tablet};
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
    -ms-grid-column-span: ${({ mobile }) => mobile};
    grid-column-end: span ${({ mobile }) => mobile};
  }


`;



export const BannerButton = styled.div<{ok : boolean}>`
  padding : 16px 24px;

  justify-content: center;
  align-items: center;
  border-radius : 12px;

  cursor : pointer;

  ${({ok})=> ok ? `cursor:pointer; background-color : #000;` : 'curosr:text; background-color:#BDBDBD;'}
`;


export const BannerButtonRequestA = styled.a`
  padding : 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius : 12px;

  cursor : pointer;

  background-color : #FFFFFF;
  margin-left : 8px;
`;
