import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
    helmet: '',
    desc: 'UWB（Ultra-Wideband）技術を活用してジオフェンス（geofence）システムを構築できます。リアルタイムで安全を監視し、施設を効率的に管理できます。',
    keywords: 'uwb, ジオフェンス, 地理的範囲, 安全区域, セキュア施設, 軍事セキュリティ, 軍事施設セキュリティ, 制限エリア, アクセス禁止エリア, スマートセキュリティ, セキュリティシステム',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'ウルトラワイドバンド（UWB）技術を活用したジオフェンスシステムの革新 :: オルブロ ブログ',
    ogUrl: 'https://orbro.io/jp/blog/uwbGeofence',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4MxDPsSDiu2Tyl6nzt6wk9OfJpf_lWTcfXeVRyvIzqQCB1pSV0Spq8jW3WvcY7Nw02jSYqstCQAImKsHtFRK-1bPx1X2mx0YaBIiayQ1ohB3l7WHUYrrwPX0KVYOmKZg-lo7OXiR53dsL16RqpSTSGdQaV46-p40ThUaFoYxY-hUVgD4l18QurSDnaKVaSL_qZ6ApBj7LSbjfbjVaUNEOXWI3bT5GNmDdlLfWBv8id417JIjgIeDW3iI_kkzevdCiKHN2ORcK4RYGPGFVeAOL8wlco80gszm93LArYd2nOvTK38arZmeYvJ0Ex0nqkjaxulQ7gJ5-a5bSBSfAaKAkqxv24246EQdlkBh9N5COrSoTqrAlmVNNf9PxF2fbxMevrZt-G9NMRIaxYkWT42AfcpxuTL1DikOcn2_q8jFMWhrvSwNYC2No2mZOgbqYoDKytKXp2xOcRM7RKZBhIZhDvfS59FVqMUMiO5aD2j2QizWcy5BI53-fnun3DZdVQTijx4yq1Gg_tQvY36Lu803m17BZ6Fia8uhoZVOdcuNpFsiAttmTR2X_wkCdeti3lj3ovDVjGE7kvZr1CdpkFft3MtDSoL2fhdU6YubXi_uuNfS_zCAwv6er3VjTfUTvPgtciMHUbAv5Ci63iew9qiOj3Fkr0ueSGZk_iqSYP-He2PUYp7TplTlsPd3CmzeGIf03yFNX1FK4RUVfIo55fXbW0n7cuga9vSRYuRF5dsHCTLILV1qr_nJGd-U4u-vO2jRh53ZHXTDgA8dZ_c4XUneNWJ0_JDYNOTmzrgJJsCDvH8-J28NyJVw-UBpgUGYZRMnhoPclDHRXAMcy5YvwsLVCLajck2ewTifU3gOr8SHNlFP_wOpK7_L5WgP7GHPYE04bZbHZ-bjCxcDM8E3wQYn32oUrEammRi0mbSb7xPPr9azb_14nmPjd1d-Ppay-29QzX8A1B5GRo4emyzqI0NMMPmDryb4QTIsKI9fYTbZsQ3fUoPH3HnNRZtpmVc-Ht1Gld7epvFpcrD199Ery-c1XIEEvw9PSDjteUY1-RMoyDutgDc06huKQxZv_wx-vfxmu-GnVz6kDHYQtEKULMtqxhF-8f65nC-p8nnbYD59CkksLRWPzzKmq7c51Sq1UDLuOqDPlnpema8t7VSg2iWUNi7pqgxfwnt1BSPeRt8XrPv8dmTmudreTbSR1g-7x4fAzf1PxU2QyGOmbVEq_5L4HNxOmJQzGnEYOxtPcTWL1G-vYS58iH6octXtcqiXoFymXxnt5sHnpCaYK1cpWTWUCjEMjJi8r2YOEPxBfzHv4VUeME1bB91xu2jsZhQC09SylsHJZZcLxW_jG4v2EzHsKI87CaHQCimSgcaP_8Iy3GPUVQdbOnRXT8XSow67w2sItu6ujbKZCr1aT7l54ree-kMoWfM3urhWArs2mQE2_oo0_eSh__SdYlxYh56m64zFkguxTpaecjUFam-ty20MdiuW5LkVjoNPR4A_9HsbmFYn_lQ8Pqc6jtL3FDml1vyeTYAYYv2XMQxoZvyGZBYbTU=w2000-h1736',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'ウルトラワイドバンド（UWB）技術を活用したジオフェンスシステムの革新',
    date: '2023-09-01',
    tag1: '#uwb',
    tag2: '#geofence',
    tag3: '#ジオフェンス',
    tag4: '#セキュアゾーン',

    text: [
        {
            head: '',
            subHead: 'ウルトラワイドバンド（UWB）技術とは？',
            desc: 'ウルトラワイドバンド（UWB）技術は、非常に広い周波数帯域を使用してデータを送信したり、物体の位置を正確に測定したりするためのワイヤレス通信技術です。 UWBは非常に短いパルスを短時間使用し、これにより高解像度の信号が生成されます。この高解像度の信号を活用して、室内外の環境で精密な位置追跡と距離測定が可能となります。低電力でも高いデータ転送率と位置測定の精度を提供するUWB技術は、さまざまな分野で活用されています。',
            dot: ''
        },
        {
            head: '',
            subHead: 'ジオフェンシングとは？',
            desc: 'ジオフェンシングは、位置情報サービスとワイヤレス技術を組み合わせて、特定の地理的領域でイベントが発生した場合に警告を送ったり特定のアクションを実行したりする技術です。これを単純に言うと、仮想のフェンスを設定し、その領域から入るか出る際に特定のアクションを行うという意味です。ジオフェンシングは、GPS、Wi-Fi、Bluetooth、UWBなどの位置追跡技術と組み合わせて使用できます。これにより、リアルタイムの位置情報を基に正確なジオフェンス領域を設定し、ユーザーやデバイスが特定の領域に入るか出る際に通知を送ったりアクションを実行したりできるようになります。',
            dot: ''
        },
        {
            head: '',
            subHead: 'ウルトラワイドバンド（UWB）技術を活用したジオフェンスシステムの革新',
            desc: '近年、技術革新は私たちの生活を急速に変えています。その中でもウルトラワイドバンド（UWB）技術を使用して革新的なジオフェンスシステムを作成することは、注目を集めています。この技術は、以前は難しかった正確で迅速な位置追跡と距離測定を可能にします。UWB技術がジオフェンシング分野でどのように活用され、どのような革新をもたらしているかを探ってみましょう。',
            dot: ''
        },
        {
            head: '',
            subHead: '1. 高精度の位置追跡',
            desc: 'UWB技術は非常に高精度な位置追跡機能を提供します。これにより、ジオフェンスシステムは物体や個人のリアルタイムの位置を追跡できます。たとえば、アクセス制限エリアがある施設の場合、そのエリアに入る人々の位置をリアルタイムで追跡して自動的に警告を送ることができます。また、軍事アプリケーションでは、敵の移動を正確に監視および制御するだけでなく、市民セクターでは施設やアイテムの位置を効果的に管理する利点が得られます。',
            dot: ''
        },
        {
            head: '',
            subHead: '2. 可視化とモニタリング',
            desc: 'UWB技術を使用したジオフェンスシステムは、高い精度で地理情報を可視化し、モニタリングできます。管理者はリアルタイムで領域内の動きや活動を監視でき、異常な行動や不正な侵入に対して即座に対応できます。',
            dot: ''
        },
        {
            head: '',
            subHead: '3. 安全性とセキュリティの強化',
            desc: 'UWB技術は高いセキュリティを提供します。レーダーベースの位置追跡はGPS信号に比べて検出が難しいため、ジオフェンスシステムは外部からのハッキングや干渉を避けつつ安全性を維持できます。これは軍事施設や機密情報保護などの分野で特に有利です。',
            dot: ''
        },
        {
            head: '',
            subHead: '4. 多機能性',
            desc: 'UWB技術はさまざまな分野で活用可能な多機能性を提供します。位置追跡だけでなく、さまざまなセンシング機能と統合して環境モニタリング、災害対応、自動化システムなどで活用できる潜在能力を持っています。',
            dot: ''
        },
        {
            head: '',
            subHead: '5. 将来の展望',
            desc: 'UWB技術は引き続き進化し、より洗練されたセンシングおよび位置追跡能力を提供すると予想されています。この進化により、安全管理能力が向上したより強力なジオフェンスシステムが登場すると期待されています。また、UWB技術は従来の方法よりも効率的かつ正確なデータを提供するため、意思決定においても高い信頼性を提供すると予測されています。',
            dot: ''
        },
        {
            head: '',
            subHead: 'まとめ',
            desc: 'ウルトラワイドバンド（UWB）技術を活用したジオフェンスシステムは、精密な位置追跡およびモニタリング能力を通じてより効果的な防御と管理を可能にします。この技術革新は市民、軍事、セキュリティの各分野にポジティブな影響をもたらし、将来的には進化したUWB技術を使用したより強力なジオフェンスシステムの登場に期待が持てます。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'この製品は、UWBデバイスを労働者や装置に取り付けてリアルタイムの位置追跡を支援します。',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/jp/enterprise/medical/patient'
            },
            {
                img: medicalWaste.thumb,
                data: 'medicalWaste',
                link: '/jp/enterprise/medical/waste'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/jp/enterprise/office/asset'
            }
        ]
    }
}
