import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet : '',
  desc: '空港での紛失、盗難、機器の位置特定の問題は一般的な問題です。これらの問題を解決するために、RTLS（リアルタイム位置システム）ソリューションが導入されることがあります。',
  keywords:"RTLS、資産管理、資産測位、テロ、テロ防止、仁川国際空港、空港、空港安全、航空安全、セキュリティ",

  ogTitle : '',
  ogDesc : '',
  ogSite : '空港で使用されるRTLS :: オブローブログ',
  ogUrl : 'https://orbro.io/jp/blog/airport',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZesBoVKMwIFFry24uoX1306-E2-KIndjXjngk3HQJAqvdeMd-lpFrtUa5bQkacaOFn73rL9aZpISCh6BfSvBY8Rj6pgLzP098EmKNW5WO0k0RSNeqTxeIIE_LiFLF3sUcJc7VhuL6arfAuAQ4APzhaT4aTqEMd8WiAzJwEEVEK3UEevHgYg7ZmwsN-ImqYMFbr01G9VkLQVPdClEaxND6eKem2wvryX-MyUhMvVWmXvao5e8JdFoNh7pmf0ED7MFgYJTg8fxDAxUsbI2jFKwsQCXpbmLsVjR7VcwSNln_gq6BqHDdpB_gte6scXAcPWT5vxnkbmDs22PEDiPDoNcyewtDBBFdtglgRga94O9jfNboErHhvfBncigoMjDxtW7TYVYAps24D2VMURGxOoAx8EquJAIaYIcLlBk2zaSrqdtCeCJGD8Il7sLifXjYw4s7qUr9XFF35LROheqZAC_0kOQ5mI85j5IkNvcvUuT0Wpg9K9Tg1uovCttIBSCD5hfEs1i5gQOxwB7fK0t9kkppR_tDz4HPjIo3i-QfSV3VtiERhnb0gIWUVAFX5jrN8rwX4iTKGpNR55yhogdwRDaSLkHePzB-Xnsn0SwSjXlonVCX02p4Zn02NM8FsMa8fNMfP7Rp4-NIciOdAehNLDjZzwFH6hXgArjIg7_CtUHBeiV5gQ9GX7PqRU4TtZXbljhqePIobwwEd_XHrXRNxAyMxnCU45iqtZUwO_dZyKmhfAZ7XDrUUK0gMaNhguKiZEIm3ZeBZN_082FE9ifEja5-LCI4-QC60gzDs8bYmOrqsotPyR47V6zTrFzRiKDRfwmiLCdbRdQsy3iLV5ph0yhVab492zrx1LaPY39eCtfQzTyDRXrgrwxrAnp54XYMJYZ23TEVgan4Sg7MwiQiYw_Y9z2c1WwAt-TE7rpWOR5Gn2um6jOBrrfo_09UF9nfV4o02ZGF4NWQ99ZIRwBmOg6KODX6mQNtxOjU60pRIpQj0BUCj5JCE5P99E2FRUfxKE89tWwftzf0WpV4XuY3XCnSVpT1DW8OAdsfQyv5GyiSm2ikSlRAq8s0nJAoPv5gcqTR5rwl-QI7YOwucwvhQgM0N3jhPjqcfCsZ0tEP4V-IVLpT1k5_LGmhBueHvi8jZinIgZ0YWZov9jEWK4ysorc637zHhZpy_infWTuHbzXNlLpxoWRsQCbsvGYNKsAVJIi91R85rK5tRbEiMFXA6l67UP2CxWsZHXN_IofYYJmQrT7t6IreFaZrouew2I-5bE-grBY53BCNZ_uk2rE8BPkifZU4ldeMi-0qDyy93SH-XeiDqOWadwsB8yHsrx-6_2UNxAeQzzFEwJtNv0tB4WWtyXgGyX94icliihgFcgXBYPX-t4tGwNJd776YIxod1tvww4WR2a-m5idyeoKDjuWUQrMWHd6JuMKFGGxnIjzUKIQMTx6q2vzwdG62nR80hG1B0_osyET4FeVfNTRdVzDRNcEtrlSSbgcQfMg0Q9LKROQcN6smgad6gObnA6mQB3Ghvo_9zXSkfAufJdY9Kg4g7Px31v1Z2fV3IhA9rzOE5Qg=w2059-h1216',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

    
  title: "空港で使用されるRTLS",
  date: "2023-05-10",
  tag1: "RTLS",
  tag2: "空港",
  tag3: "資産管理",
  tag4: "安全",
  
  
  // <br/>
  text : [
    {
      head: "",
      subHead: "",
      desc: "紛失や盗難、機器の測位など、空港で発生する問題やリソースの浪費は一般的な課題です。これらの問題を解決するために、RTLS（リアルタイムロケーションシステム）を導入することができます。",
      dot: ""
      },
      {
      head: "RTLSとは？",
      subHead: "",
      desc: "RTLS（Real-time Location Systems）は、物体や人物などをリアルタイムで測位し、位置情報を収集し処理して活用するシステムです。これにより、空港で移動する人々や荷物、航空機などの動きを把握し、制御や管理が可能となります。実際に、市場調査によれば、2019年の空港資産測位市場の価値は3億5,000万ドルであり、2027年までには9億ドルに達すると予測されています。",
      dot: ""
      },
      {
      head: "",
      subHead: "資産の維持と管理",
      desc: "空港の従業員は、資産を測位するために直接目で確認し、紙に記録する必要がありました。これにより、資産の紛失や破損を把握し、維持や保守も容易に管理できます。空港の資産だけでなく、乗客の手荷物も制御が容易になります。RTLSは、紛失や盗難された物品を迅速に把握することで、過去に浪費されていた人的および物的資源を削減することができます。"
      },
      {
      head: "",
      subHead: "安全強化",
      desc: "空港は安全に関して非常に厳格である施設です。毎日、数千人から数万人の人々や車両、手荷物を監視することは簡単な仕事ではありません。特に人が直接行う場合はなおさらです。RTLSは、民間人が立ち入ってはいけない区域を指定し、監視および制御することができるため、事故やテロを未然に防ぐことができます。もし、立ち入り禁止区域に手荷物が置かれている場合、管理者は即座に対処することができます。また、先述の資産管理に緊急時の装備を組み合わせると、消火器や除細動器などの安全装備を測位し、緊急事態時により良い空港安全に貢献することができます。",
      dot: ""
      },
      {
      head: "",
      subHead: "より良い顧客体験",
      desc: "管理者は空港内の人口動員を把握し、よりスムーズな空港の状態を維持することができます。人々が集中する時間帯や特定の出入り口、ゲートを把握し、人々を案内・制御できるようになります。これにより、空港を訪れる個々人は時間の浪費を減らし、スムーズな搭乗をすることができます。",
      dot: ""
      },
      {
      head: "",
      subHead: "コスト削減",
      desc: "大規模な空港を一度に制御・管理することは容易ではありません。しかし、RTLS技術を使用することで、従来複数人が収集していた（時折ミスが混ざった）空港情報を一度に監視することができます。これにより、従来よりもコストを削減し、サービスの品質を向上させる結果を生み出すことができます。",
      dot: ""
      }
  ],


  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc : 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/jp/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/jp/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/jp/enterprise/logistics/asset'
      }
    ]

  }

}