import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconInfo, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/manufacturingAsset/video/sub/rtlsVideo4.mp4";

export default {

    banner: {
        title: '实时库存管理',
        desc: '制造设施持有大量原材料、半成品等库存。正确管理这些库存在时间和经济方面非常重要。这是影响制造业效率的关键因素。',

    },

    video: {
        title: '更准确、更快速地管理库存。',
        desc: '实时定位(RTLS)技术可以实时告知资产的位置。几乎不需要花费时间来找到资产的位置。<br/>此外，由于还可以进行数量管理，因此使用RTLS将拥有与之前完全不同的生产效率。',
        play: video
    },

    func: [
        {
            title: '库存定位',
            desc: '实时追踪库存位置，非常准确和高效地管理库存移动和转移。同时为生产计划和预测提供有意义的数据。',
            sub1: '高精度提供',
            icon1: IconRulerGray,
            sub2: '快速数据联动',
            icon2: IconShareGray,
            video: subVideo1

        },
        {
            title: '损失预防',
            desc: '可以迅速发现并采取措施以防止遗失或盗窃。此外，由于库存储存在准确的位置并且在正确的时间使用，可以将库存损失最小化。',
            sub1: '实时状态了解',
            icon1: IconClockGray,
            sub2: '高精度提供',
            icon2: IconRulerGray,
            video: subVideo2
        },
        {
            title: '优化周转率',
            desc: '可以有效地配置库存，缩短库存周转周期，优先使用较旧的库存，从而提高周转率。这将降低库存持有成本并提高资金效率。',
            sub1: '实时定位',
            icon1: IconBinocularsGray,
            sub2: '统计数据提供',
            icon2: IconChartBarGray,
            video: subVideo3
        },
        {
            title: '准确出货',
            desc: '快速确认库存位置，可以及时从正确的位置提取所需的产品，并保证产品在出货时的准确性。这将缩短订单处理时间，最小化错误，并提高客户服务质量。',
            sub1: '实时状态了解',
            icon1: IconClockGray,
            sub2: '高精度提供',
            icon2: IconRulerGray,
            video: subVideo4
        },

    ],



    subfunc: {

        title: '多种功能',
        desc: '准确的资产管理可以减少浪费的时间和费用，提高生产率。Orbro通过提供多种功能来构建优化的制造环境。',

        data: [
            {
                icon: IconBinocularsGray,
                title: '库存预测',
                desc: '最小化维持成本，提高库存供应的速度和效率。',
            },
            {
                icon: IconBell,
                title: '实时警报功能',
                desc: '当库存出现异常情况时发送警报，可以及时应对。',
            },
            {
                icon: IconRoute,
                title: '运行路径查询',
                desc: '查询库存的运行路径并数据化。',
            },
            {
                icon: IconDashboard,
                title: '现状了解',
                desc: '了解特定库存当前的移动情况。',
            },
            {
                icon: IconWarning,
                title: '越界检测',
                desc: '检测库存是否离开现场或进入了错误的区域。',
            },
            {
                icon: IconInfo,
                title: '风险管理',
                desc: '确保库存安全存放和管理在适当的地方。',
            },
            {
                icon: IconChainLink,
                title: '数据联动',
                desc: '与RFID、物联网、安全、视频等系统连接，实现高效利用。',
            },
            {
                icon: IconSecurity,
                title: '数据安全',
                desc: '保持高数据安全水平，提供可信赖的解决方案。',
            },


        ],
    },

    system: {
        title: '系统组成',
        img: rtlsSystem
    },

    productTitle: '产品配置',
    product: [

        {
            tag: 'assetPackage'
        },
        {
            tag: 'workerPackage'
        },
    ],


    contents: [
        {
            data: 'manufacturingProcess'
        },
        {
            data: 'logisticsAsset'
        },
        {
            data: 'blogRTLS'
        },
        {
            data: 'blogMedical'
        },

    ]

}
