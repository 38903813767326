// i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageDetector from "i18next-browser-languagedetector";



import ko from './ko';
import en from './en';
import jp from './jp';
import cn from "./cn";

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "ko",
    debug: true,
    resources: {
      ko: { translation: ko },
      en: { translation: en },
      jp: { translation: jp },
      cn: { translation: cn },
    },
  });

export default i18n;
