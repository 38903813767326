import {
    IconCctvOff, IconCctvOn, IconIndoorOff, IconIndoorOn, IconOutdoorOff, IconOutdoorOn, IconSelectAlarmOff, IconSelectAlarmOn, IconSelectDashOff, IconSelectDashOn,
    IconSelectDeviceOff, IconSelectDeviceOn, IconSelectZoneOff, IconSelectZoneOn, IconSettingOff, IconSettingOn
} from "@assets/images/main/icon";

import rtlsVideo1 from "@src/assets/images/main/video/rtls/rtlsVideo1.mp4";
import rtlsVideo2 from "@src/assets/images/main/video/rtls/rtlsVideo2.mp4";
import rtlsVideo3 from "@src/assets/images/main/video/rtls/rtlsVideo3.mp4";
import rtlsVideo4 from "@src/assets/images/main/video/rtls/rtlsVideo4.mp4";

import cameraVideo1 from "@src/assets/images/main/video/camera/1.mp4";
import cameraVideo2 from "@src/assets/images/main/video/camera/2.mp4";
import cameraVideo3 from "@src/assets/images/main/video/camera/3.mp4";
import cameraVideo4 from "@src/assets/images/main/video/camera/4.mp4";


export default {


    rtls: [
        {
            iconOn: IconSelectDashOn,
            iconOff: IconSelectDashOff,
            text: 'Dashboard',
            video: rtlsVideo1
        },
        {
            iconOn: IconSelectDeviceOn,
            iconOff: IconSelectDeviceOff,
            text: 'Device',
            video: rtlsVideo2
        },
        {
            iconOn: IconSelectZoneOn,
            iconOff: IconSelectZoneOff,
            text: 'Zone',
            video: rtlsVideo3
        },
        {
            iconOn: IconSelectAlarmOn,
            iconOff: IconSelectAlarmOff,
            text: 'Alarm',
            video: rtlsVideo4
        }

    ],

    camera: [
        {
            iconOn: IconOutdoorOn,
            iconOff: IconOutdoorOff,
            text: '室外摄像头',
            video: cameraVideo1
        },
        {
            iconOn: IconIndoorOn,
            iconOff: IconIndoorOff,
            text: '室内摄像头',
            video: cameraVideo2
        },
        {
            iconOn: IconCctvOn,
            iconOff: IconCctvOff,
            text: '公共摄像头',
            video: cameraVideo3
        },
        {
            iconOn: IconSettingOn,
            iconOff: IconSettingOff,
            text: '添加设备',
            video: cameraVideo4
        }
    ],

    integration: {
        singleVideo: true,
        video: cameraVideo1
    }


}