import { TechUseCaseIcon3D, TechUseCaseIconBuilding } from "@assets/images/technology/common";
import { IconBattery, IconCar, IconChartInv, IconHeart, IconHome, IconRulerInv, IconTagInv } from "@assets/images/icon";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

export default {

    seo: {
        title: 'デジタルツイン（Digital Twin）とは何ですか？',
        desc: 'デジタルツインは物理的な対象からリアルタイムでデータを収集し、それを仮想モデルに反映して現実の状態を同様に再現します。収集されたデータはクラウドベースのプラットフォームで保存され、分析されてさまざまな情報が生成されます。',
        keywords: 'digital twin, デジタルトランスフォーメーション, スマートビルディング, 仮想現実, rtls, 位置追跡, ビーコン, orbro, コンテック',
        img : 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: 'https://orbro.io/jp/enterprise/technology/what-is-digital-twin',
        type: 'website',
    },

    tag: 'デジタルツインとは？',
    title: 'デジタルツイン（Digital Twin）とは何ですか？',
    desc: '',

    btnText : 'ORBRO デジタルツイン',
    btnLink : '/jp/digital-twin',

    text: [
        {
            head: 'デジタルツインとは何ですか？',
            desc: `デジタルツインは物理的な対象のリアルタイムデータを収集し、それを仮想モデルに反映して現実の状態を同様に再現します。収集されたデータはクラウドベースのプラットフォームで保存され、分析されてさまざまな情報が生成されます。この情報は、物理的な対象の状態、パフォーマンス、メンテナンスの予測などの側面で価値を提供し、意思決定をサポートします。たとえば、製造業では、デジタルツインが製造プロセスを正確にモニタリングし、予測分析を通じて機器のメンテナンススケジュールを最適化し、生産時間を短縮し、製品の品質を向上させます。基本的にデジタルツインは実際のシステムのリアルタイムな運用状態をモニタリングし、物理的な対象の変更や異常を迅速に検出してシステムの安定性を向上させ、問題を事前に防ぐのに役立ちます。さらに、デジタルツインはシミュレーションを通じてさまざまなシナリオを試す機会を提供し、新しい技術の導入やプロセスの改善に対する最適な運用方法を見つけることで、より正確で効果的な意思決定を可能にします。デジタルツインはライフサイクル管理、メンテナンスの予測、パフォーマンスの最適化、製品開発など、さまざまな分野で適用され、企業はコスト削減と生産性の向上を実現しています。これらの利点は、デジタルツインが現代の産業における中核技術として台頭している要因です。`,
            subHead: '',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/jp/enterprise/manufacturing/process',
            head: 'デジタルツインの利点',
            desc: '',
            subHead: [
                {
                    title: 'リアルタイムモニタリングと分析',
                    desc: 'デジタルツインは物理的な対象のリアルタイムデータを収集し、その運用状態を正確にモニタリングしています。これにより、問題を迅速に検出し、生産性の向上とシステムの安全性向上を実現できます。'
                },
                {
                    title: 'シミュレーションと最適化',
                    desc: 'デジタルツインを使用すると、仮想モデルを使用してさまざまなシナリオをシミュレーションし、最適な運用方法を見つけることができます。これによりコスト削減の機会が提供され、効率を最大限に引き出すことができます。'
                },
                {
                    title: 'メンテナンスとライフサイクル管理',
                    desc: `物理的な対象の状態を正確に理解することで、メンテナンススケジュールを最適化し、機器のライフサイクルを効果的に管理できます。これによりコスト削減とシステムの信頼性向上が実現します。`
                },
            ],
            dot: ''
        },
        {
            head: 'デジタルツインの種類',
            desc: 'デジタルツインは産業セクターや応用領域、物理的な対象の特性に基づいてさまざまなタイプに分類できます。いくつかのタイプの中から、以下はいくつかの主要なデジタルツインのタイプです:',
            subHead: [
                {
                    title: 'Product Twin',
                    desc: '製品ライフサイクル全体で生成されたデータを利用して、製品の状態とパフォーマンスをデジタルモデルで追跡します。製造業では、Product Twinは設計、製造、運用、メンテナンスまでの各段階で情報を追跡し、最適化します。'
                },
                {
                    title: 'Process Twin',
                    desc: '製造操作や施設管理などの複雑なプロセスをデジタル化します。プロセスの最適化、エネルギー効率、運用安全性などの側面をモニタリングおよび向上させるために使用されます。'
                },
                {
                    title: 'Facility Twin',
                    desc: '建物や施設の物理的な特性をデジタルで表現します。エネルギー消費、メンテナンススケジュール、環境要因のリアルタイムモニタリングにより、施設の効率が最適化されます。'
                },
                {
                    title: 'City Twin',
                    desc: '都市のインフラ、交通、環境をデジタルモデルで表現します。スマートシティの概念では、City Twinは交通フローの最適化、エネルギー効率の向上、公共の安全性の向上に使用されます。'
                },
                {
                    title: 'Biomechanical Twin',
                    desc: '医療分野では、患者の生体情報を収集してBiomechanical Twinを作成し、患者の健康状態をデジタルで表現します。これにより医療専門家は患者の状態をリアルタイムでモニタリングし、診断に利用できます。'
                },
                {
                    title: 'Twin of a Service',
                    desc: 'デジタルツインは製品やプロセスに限定されるものではなく、サービスにも進出しています。これにはサービスの操作とパフォーマンスをデジタル化し、効率的に管理することが含まれます。'
                },
            ],
            dot: ''
        },
        {
            videoDesc: '(製造施設のデジタルツイン、クリックして移動します)',
            tag: 'noBottomLine',
            head: 'デジタルツインテクノロジーの動作原理',
            desc: 'デジタルツインは物理的な対象をデジタル化することで実世界を正確に反映する仮想モデルです。デジタルツインの動作は以下のステップで要約できます:',
            subHead: [
                {
                    title: '1. センサーとデータ収集',
                    desc: 'デジタルツインの動作は、物理的な対象から生成されたさまざまなデータを収集することから始まります。センサーやIoT（モノのインターネット）デバイス、カメラなどが使用され、物理的な対象の状態、位置、パフォーマンスに関する情報を収集します。'
                },
                {
                    title: '2. データの伝送と保存',
                    desc: '収集されたデータはクラウドベースのプラットフォームや中央データベースに伝送され、保存されます。このデータは広範でさまざまな形式であり、リアルタイムで伝送されるか、定期的なインターバルで更新されることがあります。'
                },
                {
                    title: '3. 処理とモデリング',
                    desc: '保存されたデータは処理され、仮想モデルに反映されます。このプロセス中には機械学習アルゴリズムやさまざまな分析技術が使用されることがあります。仮想モデルは物理的な対象の特性を正確にシミュレートし、その状態をデジタルで表現します。'
                },
                {
                    title: '4. リアルタイムアップデート',
                    desc: 'センサーデータのリアルタイムアップデートにより、デジタルツインは物理的な対象の現在の状態を正確に反映できます。これによりデジタルツインは実世界の変化に迅速に対応できます。'
                },
                {
                    title: '5. シミュレーションと最適化',
                    desc: 'デジタルツインはシミュレーションを通じてさまざまなシナリオを試すことができ、最適な運用方法を見つけることができます。たとえば、特定の条件下でのパフォーマンスの予測や、変更された環境でのシミュレーションを実験することで、意思決定をサポートします。'
                },
                {
                    title: '6. インターフェースと可視化',
                    desc: 'デジタルツインの結果はユーザーインターフェースを介して視覚化され、物理的な対象の状態、パフォーマンス、予測などに関する情報をユーザーが簡単に理解し、モニタリングできます。これによりデジタルツインは物理的な対象をデジタル画面にもたらし、実世界を正確に反映して生産性向上と問題解決に貢献します。'
                },
            ],
            dot: ''
        },
        {
            tag: 'card',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
        {
            head: "デジタルツインの構築",
            desc: "デジタルツインの構築プロセスは、産業セクターやアプリケーションドメインによって異なる場合がありますが、一般的には以下のステップが含まれます。",
            subHead: "",
            dot: [
                {
                    title: "1. 目標と範囲の定義:",
                    desc: [
                        {
                            text: " • デジタルツインとしてモデリングする物理オブジェクトを決定します。"
                        },
                        {
                            text: " • 目標は、パフォーマンスの向上、簡素化されたメンテナンス、シミュレーション、最適化などの側面に焦点を当てることができます。"
                        }
                    ]
                },
                {
                    title: "2. センサーとデータ収集の計画:",
                    desc: [
                        {
                            text: " • 必要なセンサーとデータ収集方法を決定します。"
                        },
                        {
                            text: " • データ収集の頻度、形式、セキュリティ、プライバシーの考慮事項などを考慮して計画します。"
                        }
                    ]
                },
                {
                    title: "3. データインフラの構築:",
                    desc: [
                        {
                            text: " • 収集されたデータを保存および処理するためのデータインフラを確立します。"
                        },
                        {
                            text: " • クラウドベースのプラットフォームまたはオンプレミスソリューションを選択および実装します。"
                        }
                    ]
                },
                {
                    title: "4. 仮想モデリングとシミュレーション:",
                    desc: [
                        {
                            text: " • 収集されたデータを使用して物理オブジェクトの仮想モデルを作成します。"
                        },
                        {
                            text: " • このモデルは物理オブジェクトの特性を正確に反映し、シミュレーションを通じてさまざまなシナリオを実験します。"
                        }
                    ]
                },
                {
                    title: "5. データ分析とモデル学習:",
                    desc: [
                        {
                            text: " • 収集されたデータを分析し、機械学習技術を使用して仮想モデルを改善します。"
                        },
                        {
                            text: " • 問題解決と学習のための予測モデルやアルゴリズムを実装します。"
                        }
                    ]
                },
                {
                    title: "6. リアルタイムモニタリングとアップデート:",
                    desc: [
                        {
                            text: " • センサーからリアルタイムデータを収集し、デジタルツインをリアルタイムで更新して、現実世界の変化に迅速に対応させます。"
                        },
                        {
                            text: " "
                        }
                    ]
                },
                {
                    title: "7. セキュリティとプライバシーの強化:",
                    desc: [
                        {
                            text: " • 機密データを適切に保護するためのセキュリティメカニズムを実装します。"
                        },
                        {
                            text: " • プライバシー保護に関連する規制を遵守します。"
                        }
                    ]
                },
                {
                    title: "8. インターフェースと視覚化:",
                    desc: [
                        {
                            text: " • ユーザーがデジタルツインの結果を簡単に理解しモニタリングできるようにするためのユーザーインターフェースを開発します。"
                        },
                        {
                            text: " • 視覚化ツールを使用して仮想モデルの状態とパフォーマンスの視覚的な表現を提供します。"
                        }
                    ]
                },
                {
                    title: "9. 継続的な改善と最適化:",
                    desc: [
                        {
                            text: " • 運用中にパフォーマンスと効果を継続的にモニタリングします。"
                        },
                        {
                            text: " • 必要に応じてデジタルツインを改善および最適化します。"
                        },
                        {
                            text: " • 新しいデータソースやテクノロジーの導入を通じてシステムをアップグレードします。"
                        }
                    ]
                }
            ]
        },
        {
            tag: "advantage",
            head: "",
            desc: "",
            subHead: "",
            dot: ""
        }
    ],

    card: {
        title: 'デジタルツインの使用事例',
        desc: 'デジタルツインはさまざまな産業で様々な用途に活用されています。以下はデジタルツインの代表的な使用事例です。',
        cards: [
            {
                icon: TechUseCaseIcon3D,
                title: '製造業デジタルツイン',
                desc: [
                    {
                        title: '施設モニタリングと最適化',
                        desc: '製造ラインや施設をデジタルツインとしてモデリングし、リアルタイムの状態を監視し、予測分析を通じてメンテナンススケジュールを最適化します。'
                    },
                    {
                        title: '製品開発とシミュレーション',
                        desc: '製品のデジタルツインを作成し、デザインから生産までのプロセスをシミュレートして最適なデザインを見つけます。'
                    }
                ]
            },
            {
                icon: IconHome,
                title: '建設と不動産デジタルツイン',
                desc: [
                    {
                        title: '建物モニタリング',
                        desc: '建物やインフラの健康状態をリアルタイムで監視し、メンテナンススケジュールを最適化し、安全性を向上させます。'
                    },
                    {
                        title: '都市計画とシミュレーション',
                        desc: '交通、エネルギー使用、環境への影響などの都市の側面をデジタルツインとしてシミュレートし、効率的な都市計画を確立します。'
                    }
                ]
            },
            {
                icon: IconBattery,
                title: 'エネルギー管理デジタルツイン',
                desc: [
                    {
                        title: 'スマートグリッドの運用',
                        desc: '電力供給ネットワークをデジタルツインとしてモデリングし、エネルギーフローを最適化し、効率的に電力システムを運用し、故障を検出/予防します。'
                    },
                    {
                        title: '',
                        desc: '',
                    }
                ]
            },
            {
                icon: IconHeart,
                title: 'ヘルスケアデジタルツイン',
                desc: [
                    {
                        title: '患者モニタリングと診断',
                        desc: '患者の健康状態をデジタルツインとして表現し、医療専門家によるリアルタイムのモニタリングを支援し、診断を助けます。'
                    },
                    {
                        title: '医療機器開発',
                        desc: '医療機器の開発段階でデジタルツインを使用し、シミュレーションを実行してデバイスのパフォーマンスを向上させます。'
                    }
                ]
            },
            {
                icon: IconCar,
                title: '自動車と交通デジタルツイン',
                desc: [
                    {
                        title: '車両設計とテスト',
                        desc: '自動車の開発段階でデジタルツインを使用して性能を向上させ、シミュレーションを通じて安全性を検証します。'
                    },
                    {
                        title: '物流と交通の最適化',
                        desc: '物流ネットワークをデジタルツインとしてモデリングして物流と交通プロセスを最適化し、コストを削減します。'
                    }
                ]
            },
            {
                icon: TechUseCaseIconBuilding,
                title: 'スマートビルディング',
                desc: [
                    {
                        title: 'スマートメータリングシステム',
                        desc: 'ユーティリティネットワーク（電気、ガス、水など）をデジタルツインとしてモデリングし、リアルタイムで監視し、消費者にエネルギー使用情報を提供します。'
                    },
                    {
                        title: '環境モニタリング',
                        desc: '環境の状態をデジタルツインとして表現し、空気品質、水質、気候などを持続的にモニタリングし、環境管理の意思決定を支援します。'
                    }
                ]
            },
        ]
    },

    advantage: {
        title: 'デジタルツインの主な特長',
        cards: [
            {
                icon: IconRulerInv,
                title: 'リアルタイムモニタリングと対応能力',
                desc: 'デジタルツインは物理的なオブジェクトのリアルタイムの状態をモニタリングし、問題が発生したり予測されたりした際に迅速な対応が可能です。これにより、生産ライン、施設、建物などのさまざまな対象においてシステムの安全性が向上し、潜在的な問題が最小限に抑えられます。'
            },
            {
                icon: IconTagInv,
                title: 'シミュレーションと最適化能力',
                desc: 'デジタルツインは仮想モデルを利用してさまざまなシナリオをシミュレートし、最適な運用方法を見つけることができます。これにより、生産プロセス、製品設計、エネルギー使用などの側面で効率が最大化されます。また、変化した環境でのシミュレーションは、情報を基にした意思決定をサポートし、リスクを軽減します。'
            },
            {
                icon: IconChartInv,
                title: 'メンテナンスとライフサイクル管理のコスト削減',
                desc: 'デジタルツインを利用することで物理的なオブジェクトの状態を正確にモニタリングし、予測分析を通じてメンテナンススケジュールを最適化できます。これにより不要なメンテナンスを防ぎ、機械や施設の故障を事前に予測し、効率的な修理や交換を実施できます。これによりメンテナンスコストが削減され、資産の寿命が延長されます。'
            },
        ]
    }

}