import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4';

export default {
    desc: 'スマートビルは、IoTセンサー、人工知能、自動化システムなどの技術を活用して、建物内のさまざまなシステムを接続し、統合し、建物の運用を最適化します。これにより、エネルギー効率が向上し、建物の安全性、利便性、効率性が向上します。',
    keywords: 'スマートビル、RTLS、IoT、デジタルツイン、デジタルトランスフォーメーション',

    ogSite: 'スマートビルとは？ | デジタルツインプラットフォーム、Orbro',
    ogUrl: 'https://orbro.io/jp/blog/smart-buildings',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmNclLyXvFXAppGJYrqUeCnj1ItMcBFHWPvVUo5mAAclWqqXT2c7O3kn8SC3hs6LiHgOw7HC2-KUOHZpCjEK7FnzMSWAna_xAxwzmLzRZvzyR4y1i-8TbhmLBCUAAEfPsa5GgyVj98AunnYrTGFbIebivjQFTuGcC2LCdg_GLJkED01NGjfucz8TKJjCj8Gxa3QnH2ZcipuWUhWVUHPvgzHZe-w_TxeDOeywhDMKI9JWA2QiUM3pBtOpFLqmbd_dl7WMBDD_lw7xutd_UJTam_iuLegrr12cuEQJittPjU2a4YQ5SAqRcKbatpWFDrDsthws-65VTYSGgffeaODxqhQccffxpsoZpNQ9z5y6dydLbm02KFtQyjGQVkVztr00IBlvROvpALKGf8X4W1ZGeg7zEvsV8kyP084k_hUI6MWh6wqkVnF7SSV17RYtyumC1lq5VI-JdGn_YuyA7BZl4_Sgz8_fgeNXiC8z0qifLgrN2sczIe0CffI8piaJcUPxk8ADXBieKFniP5f3dG-AMDN8STr-B2nwFB_CNY7UDz76_s3FmVEnQTTU7MFqF5px-0ZgJhAJhWrOpAY93WGxfVRThfuMY1iGn-6_uuf6IrNAv5Cy7aGvagtrsi7F_UfGjbVbbB4bxQbcV-UqIHZP5wcgNU1JIW4Bztfjw5QUTidafK0ARwixIms3w1Dry4-O0KV-eksKbVrMtPAcL4GiiBaiuU-mpMDiT_c0yf2rCBeMOK_bAmkp7i8lrRMUQC4xYvav6-BDs8r3iuHEbvyBhO7i9Dlq1d5yT40AfmLvRs0lfU9S1d6_ltRRv3sNgUcuUiNxAMwHkco_LDoBxGg21-QCK6CahMJws-V4L60V7M-dRsIuhkIvCs0D7tSFmhBLMd33XEbOE0Jy79l0AgNMG0ChJPg8dvdSjBQ3ep_vAjQTgxJ_vWTy05yCwl6mFTl1zJWxcyjydP40t8D9SdAIQFXOXsxOaOGkauQGkO8t9igDMGIFCQWRJBW4JDC77EmIG3xHmhUnOZTUOmk2Y13SsSePHqLvXpEImCh-lG_Ehp5TgbK_mBfUUDOpZqSnEc3lHGC4z-9XMeOIGWYX689D07wskrtyNdEzShvTXWgrEPopU8c-D2XFw8X89i4f9wlVJ-Egn29eAhBCw4XaLwAA7_LUOXEVwlhYb7DmYxADRH_zXMl9gZE9_UJ_LkymE5ZoEMDT2mTv7cEUK9LPYkEBRmViKwxiFHwx2coDptiwVoR4-mLvPA7hzxRe7NjcreMJ6BEtpvhi4DwXeosAyHVQxd8R_Am7rw5Dtt5eap7Oca07-DDGWzSUzvpzt2Hde-_3NmrBxpOditdoDCuddXrXvyKByMIQmWnRtvIVhGgnGMjvYAkwNJBrd-1qxORsRMbhiBGX_l8HzgAYb6vuHjiaBhbo4a5_F-whRFJW1rO919s5ItElRg8Y6R3xI3xtKl3tza9Al_rKSvZ8lkiv44cUZ6a_rO8Zmi97pNd_aEgj1JhawhSHN3WGHkf7v5TdRC0CAZaxfDUrA4XupbrYuT1lZN7rnd_iIWvTEG2cFR08BcGyw0R9Yg=w1701-h1166',
    ogType: 'ウェブサイト',

    title: 'スマートビルとは？',
    date: '2024-01-30',
    tag1: '#スマートビル',
    tag2: '#IoT',
    tag3: '#位置追跡',
    tag4: '#デジタルツイン',

    text: [
        {
            head: 'スマートビルとは？',
            subHead: '',
            desc: 'スマートビルは、IoTセンサー、人工知能、自動化システムなどの技術を活用して、建物内のさまざまなシステムを接続し、統合し、建物の運用を最適化します。これにより、エネルギー効率が向上し、建物の安全性、利便性、効率性が向上します。これらの技術により、建物はリアルタイムでデータを収集し、照明、暖房、冷房、セキュリティシステムなどのさまざまな設備を自動的に調整することができます。スマートビルはエネルギー効率を向上し、エネルギー消費を最適化し、環境に配慮し、経済的に運用されます。ユーザーの利便性を高め、建物の運用と管理を効率化し、より良い生活と作業環境を提供します。',
            dot: ''
        },
        {
            head: 'スマートビルの主要なテクノロジー',
            subHead: 'センサーテクノロジー',
            desc: 'スマートビルは、建物の内外の環境をリアルタイムでモニタリングするためにさまざまなセンサーを使用します。これらのセンサーは温度、湿度、照明、二酸化炭素濃度、微粒子レベル、動きなどを検出し、データを収集します。また、センサーデータと他のデバイスとの接続を通じて、スマートビルはリアルタイムの情報交換と制御を可能にします。IoTテクノロジーを使用することで、スマートビルは効率的な運用のためにさまざまなシステムとデバイスを統合できます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'RTLS（リアルタイムロケーションシステム）',
            desc: 'RTLSテクノロジーを使用すると、スマートビル内の機器、在庫、資材などをリアルタイムで追跡できます。これにより、機器の損傷、紛失、保守が必要な場合などを迅速に検出し、管理できる機会が提供されます。スマートビル内で緊急事態が発生した場合、RTLSテクノロジーを使用して避難経路を案内し、建物内のすべての対象物と個人の位置を追跡できます。これにより、救助作業員や緊急対応チームが効率的に対応できるよう支援します。',
            dot: ''
        },
        {
            head: '',
            subHead: '人工知能（AI）',
            desc: '人工知能技術は、スマートビルの自動化システムとビッグデータ分析を改善するために使用されます。AIはリアルタイムのデータ変化に応じてシステムを調整し、ユーザーの要件に合わせて最適化するのに役立ちます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'ビッグデータ分析',
            desc: '収集されたデータは、ビッグデータ分析技術を使用してパターンを識別し、予測モデルを開発するために使用されます。これにより、建物の運用を最適化し、問題を早期に検出できます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'デジタルツイン',
            desc: 'デジタルツインは、建物の物理モデルをデジタルで複製したものです。建物と同じ外観をデジタル画面に実装し、理解しやすく管理できるようにします。このデジタルモデルは建物の運用と保守をサポートし、建物のリアルタイムの状態を反映して問題を診断し、予測します。',
            dot: ''
        },
        {
            head: 'スマートビルの利点',
            subHead: '',
            desc: 'スマートビルは、近代建築技術と情報通信技術の統合を通じてさまざまな利点を提供します。これらの利点は建物の運用、効率、利便性、環境保護など、さまざまな側面で現れます。',
            dot: {
                a: '<strong> • エネルギー効率の向上：</strong> スマートビルはセンサーと自動化システムを使用してエネルギー消費を最適化します。たとえば、照明、暖房、冷房システムはリアルタイムデータを基に調整され、エネルギー消費を削減し、エネルギー効率を向上させます。さらに、ビッグデータ分析と人工知能を使用して、エネルギーの費用節約に寄与します。',
                b: '<strong> • 環境にやさしい：</strong> エネルギー効率の向上により、温室効果ガス排出量が減少します。スマートビルは微粒子および大気汚染を監視し、空気の品質を改善し、健康な環境を作り出します。',
                c: '<strong> • 費用削減：</strong> エネルギー効率の向上により、エネルギー費用が削減され、自動化システムにより人件費を節約し、保守および運用費用を最適化できます。',
                d: '<strong> • 便益と安全性：</strong> スマートビルは自動的に照明、暖房、冷房、セキュリティシステムなどを調整することで、ユーザーは快適な建物を利用できます。セキュリティシステムはスマートビルの内外の環境を監視し、不正侵入を防ぎ、緊急事態に備えます。',
                e: '<strong> • リアルタイムモニタリングと管理：</strong> スマートビルは建物の運用者にリアルタイムのデータとアラートを提供し、問題の迅速な解決を支援します。したがって、建物の健康状態を監視し、保守スケジュールを最適化できます。',
            }
        },
        {
            video: video,
            link: '/jp/enterprise/logistics/asset',
            head: 'スマートビルの事例',
            subHead: 'The Edge、アムステルダム、オランダ',
            desc: 'The Edgeはオランダのアムステルダムに位置する革新的なスマートビルで、最もエネルギー効率の高い建物の1つとされています。建物内には数百のセンサーが設置され、ユーザーの位置と活動に基づいて照明、暖房、冷房を最適化します。リアルタイムデータとビッグデータ分析を使用してエネルギー消費を管理し、従業員にさまざまなサービスを提供して利便性を向上させます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'Microsoft Campus、Redmond、アメリカ',
            desc: 'Microsoft Campusは、スマートビルおよびスマート施設管理技術を使用して建物と施設を管理しています。この建物は、IoTセンサーとビッグデータ分析を使用して、暖房、冷房、照明、水管理を最適化し、エネルギーコストを削減しています。これにより、従業員に建物サービスを提供し、セキュリティシステムを強化することで、ユーザーの利便性と安全性を向上させています。',
            dot: ''
        },
        {
            head: '',
            subHead: 'One Angel Square、マンチェスター、イギリス',
            desc: 'One Angel Squareは、イギリスのCo-operative Groupの本部であり、積極的にスマートビル技術を活用しています。この建物は、地熱、太陽エネルギー、環境に優しいエネルギーシステムを統合し、高いエネルギー効率を実現し、建物の温度と環境を最適化しています。建物内外のセンサーを使用してリアルタイムのデータを収集し、ビッグデータ分析を通じて建物の運用を管理しています。',
            dot: ''
        },
        {
            head: 'スマートビルの展望',
            subHead: '',
            desc: '将来のスマートビルは、エネルギー効率を重視し、ビッグデータと人工知能を活用して最適な運用を実現します。IoT技術とセンサーネットワークの普及により、リアルタイムのモニタリングと制御が可能になり、ユーザーの利便性が最大限に高められます。また、堅牢なセキュリティシステムを備えることで、より安全な環境を提供します。スマートビルは将来の都市と建物で中心的な役割を果たし、エネルギー効率と環境への配慮を向上させます。これらの機能は建物の運用とユーザー体験を革新します。',
            dot: ''
        },
        
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWBデバイスを労働者または機器に取り付け、リアルタイムで位置を追跡する製品です。',
                img: productImg.product.workerTracking.thumbnail,
            },
            {},
        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/jp/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/jp/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/jp/enterprise/construction/equipment'
            }
        ]
    },
}
