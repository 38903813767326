import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

export default {
  helmet: '| 什么是智能医院？',
  desc: '智能医院专注于利用物联网、人工智能和数据分析等技术实时监测患者的健康状况，并改善诊断和治疗过程，以提高医疗服务的效率和质量。',
  keywords: '数字孪生、智能工厂、NVR、IP、PTZ、RFID、NFC、UWB、无线通信、位置追踪、物体追踪、人员追踪、标签、信标、RTLS、Orbro、康泰克',

  ogTitle: '',
  ogDesc: '',
  ogSite: '什么是智能医院？ | 位置追踪和数字孪生专业平台，Orbro',
  ogUrl: 'https://orbro.io/cn/blog/스마트병원이란',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmPfoc5xcFsetUID5pyg3CYD7YMozk7v8agtjsAU3FWSwMrSh5jezcJw00E2PsYdRwBciFQJKL4IguNdWivv4bRwDkwLuH_X_qAV982dYuBJho1obfwpVkXOoGGsFzUHcZ6I-tLD3CCDnlOljA-ZIojFjbigYAYMSdbWykA0SplErndN38ZvDJCDdtAJKIn703TEZAaikiyqmO_dZMOzge0_bIm3eS8eIPGsweG-xjhoDelT8CCOdyeN0LY68PSWfiinGf0eEuOoS3pti-1ZwLu-JjJELZsO1tsY30mxgenUNvpYfjBhCHq2IFN2vX5T-39NwhhLOOxM_5WE1ec46dO8qlMqV2Z1m6FkaFlsNr4fCccF3HM0uahwB1AlQY9T47hoqeH3C6kQC3JbanZWKV_CWqzXDVXLdEbwzA7taG3ny_gWknaCAdR_v0oqgaUnAkuOldeRH5wVEsZC6SaHP8T-DZXTyl_XTvlPl3WRE5POEcdCSelPI6gRWOzIuFOfyImIPGqtxPuc2yuwk9lv372FquiGhHqBAkHQ8FoPn5lNd7nVuV0COyXuu5vdL3sdii7rC-xZQ9s55QCRPlGbX51dJb6u-ku9H_Cv-Dxr-zr4PV0TnSKTWQxdM0kJbKL2kEgQCahORl-3ojZaHOXWE9Hqu5GjfKdar2oI2wNIGP82lEb7oRlXwEtqokSSFGY2SW6Sz-GeEUyJWL17f4XqGaBnv-bC_6XYXuyWQpnAU7VPOSjZOh4BPpQTAS1qEXKVISNMt9c4urPNVRAtG5I0Pn2v-fcBE4wcQgixYinbHP2OdtdyBKdjGOo31Zlp78Hs7PzDyWCQ4x8a4vV1z2EbrciEe2pcWWkll9qrFFYRRF62ecCzT9vRLko6dD4j9RjTWUigzS-Y0Un4NBy4gSwfIKmYvjf5q1muT96LYHPdE1qu-d9sUUW5Cg05eUAfUwwEFDQkms0x5jM5ds68-FYCiI2aeTY3nlo9E84to4h4o9Z2LjufbbwC3Y_zkPzkbREeF-FKbkRGiE1gw7WuUfRQ-K16n3rdiCytnsClNeTgIw_xYkkGYGS9TCh-BgPnvBvnvrfviZdwMpI22X-Jp_F4dVL_OBwVKMqKAUATJNPCcNiqqoGAHJdOv4Zwt4odCav4i7sM7_ZdJaN08S5egxFZMpLL9Lu1TR9SGWSpT-opyrkMUo2yWTKLtxY6E4mjO1EV5a2qRD-OSbGxlT6-HBn2HvJpvlbmHyChUDA1LR5aTDsReQq8lP5lIHjbkA1lD0h9x-MuYp0QUXJWiAoMdRxNVQBsZUHjkJP41fTk1bBuizb8U9tp3kv_NReX8fCs7a8UNYbGnWllcN3mRBSET80gDqirtQm2Nw5uX7nXHQvgKOqFufopQIHmjdVZBBRgLs9ofbVSgaD3bryP8l7mMotKmEyOPA_cD-WbTB8aSMfUbgqc_WSyW_3VJi_4Y0B2QfdwRGnr2d_lPXG_V62CHnP1vjPlkC7xGDXxVzG6hmC6EWsrfSEUvAywKLYdmBc0bEGQzj33GVTHADzLtZ01SU_juNo-ASh-i-A=w1834-h1208',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '什么是智能医院？',
  date: '2024-01-16',
  tag1: '#智能医院',
  tag2: '#智能医疗',
  tag3: '#数字孪生',
  tag4: '#位置追踪',

  text: [
    {
      head: '什么是智能医院？',
      subHead: '',
      desc: '智能医院专注于利用物联网、人工智能和数据分析等技术实时监测患者的健康状况，并改善诊断和治疗过程，以提高医疗服务的效率和质量。智能医院致力于支持医疗信息的有效共享和决策，构建创新的医疗系统，提供快速有效的医疗服务。这使得患者可以获得更加便捷和个性化的医疗服务。',
      dot: '',
    },
    {
      head: '智能医院应用技术',
      subHead: '',
      desc: '智能医院利用各种技术提供现代高效的医疗服务。主要技术包括以下内容。',
      dot: {
        a: '<strong> • 实时监测技术： </strong>实时监测患者状况，使医护人员能够立即作出反应并调整治疗。这有助于增强患者安全性和监视。',
        b: '<strong> • 电子医疗记录（EMR）： </strong>电子记录和管理患者的医疗记录，使医护人员能够高效地访问信息并做出决策。',
        c: '<strong> • 机器人技术： </strong>机器人用于手术辅助、患者护理、药物提供等各种医疗任务，从而在智能医院提供人力支持并提高医疗服务质量。',
        d: '<strong> • 物联网（IoT）技术： </strong>通过医疗设备和传感器实时监测患者健康状况并收集数据。这有助于提供个性化医疗服务。',
        e: '<strong> • 人工智能（AI）和大数据分析： </strong>利用人工智能分析医疗数据，开发预测模型，增强疾病的早期诊断和预防，降低医疗成本。',
      },
    },
    {
      head: '智能医院的优势',
      subHead: '',
      desc: '智能医院的优势包括通过电子医疗记录实现高效的医疗记录管理、通过实时监测实现快速响应和治疗提供、通过人工智能和大数据分析实现疾病预防和早期诊断的增强。此外，为了改善患者体验，还有便捷的预约和治疗流程、引入IoT技术提供个性化医疗服务、利用虚拟现实和增强现实进行有效的患者教育和康复治疗、通过机器人技术支持医疗人员等。这些技术的整合使智能医院革新性地提高了患者治疗和医疗服务的水平。',
      dot: '',
    },
    {
      video: video,
      link: '/cn/enterprise/medical/patient',
      videoDesc: '点击以查看智能医院解决方案。',
      head: '智能医院案例',
      subHead: '',
      desc: '',
      dot: {
        a: '<strong> • 梅奥诊所： </strong>梅奥诊所是全球著名的智能医院之一，整合了电子医疗记录和实时监测系统，提高了患者治疗的效率。此外，患者可以通过手机应用程序预约和访问医疗信息。',
        b: '<strong> • 新加坡总医院： </strong>新加坡总医院积极利用智能技术，引入实时监测、物联网设备、机器人手术等，提高患者治疗的效率，并为患者提供个性化服务。',
        c: '<strong> • 谢巴医疗中心： </strong>谢巴医疗中心使用虚拟现实（VR）进行创新的患者教育和治疗。通过利用VR进行治疗计划，有效地促进患者康复。',
      },
    },
    {
      head: '智能医院的展望',
      subHead: '',
      desc: '智能医院作为迅速发展的医疗产业的重要组成部分，正在确立其地位。它通过利用最新技术和人工智能，高效进行患者诊断和治疗，通过实时共享医疗数据加强医生之间的协作。患者可以通过手机应用程序监测健康状况，轻松访问医疗服务，体验便利。此外，智能医院严格管理患者个人医疗记录，提高医疗信息的安全性。未来，通过更先进的技术和创新服务，智能医院有望提供更加患者中心、高效和安全的医疗环境。',
      dot: '',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '通过安装UWB设备在工作者或设备上，实时跟踪位置的产品。',
        img: productImg.product.workerTracking.thumbnail,
      },
      {},
    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/cn/enterprise/medical/patient',
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/cn/enterprise/retail/exhibition',
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/cn/enterprise/office/asset',
      },
    ],
  },
};
