import { BleImg1, BleImg1Mobile, BleImg1Tablet, BleImg2, BleImg2Mobile, BleImg2Tablet, BleImg3, BleImg3Mobile, BleImg3Tablet } from "@assets/images/technology/ble";
import { StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import {
  StyledTechnologyGroup, StyledTechnologyImg, StyledTechnologyItem, 
  StyledTechnologyItemDescription, StyledTechnologyItemImg,
  StyledTechnologyItems, StyledTechnologyText,
} from "@pages/Enterprise/Technology/Technology.style";
import { CommonState } from "@redux/reducers/commonReducer";
import React from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { VideoBox, VideoPlay } from "@pages/Enterprise/Solution/components/Video/Video.style";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";
import { StyledParagraph } from "@components/Text";
import { useLocation } from "react-router-dom";
import { Spacing } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";
import { HeadingH3 } from "@components/Text/Heading/Heading.style";
interface layoutImg {
  desktop: string,
  tablet: string,
  mobile: string
}

const img1: layoutImg = {
  desktop: BleImg1,
  tablet: BleImg1Tablet,
  mobile: BleImg1Mobile
}

const img2: layoutImg = {
  desktop: BleImg2,
  tablet: BleImg2Tablet,
  mobile: BleImg2Mobile
}

const img3: layoutImg = {
  desktop: BleImg3,
  tablet: BleImg3Tablet,
  mobile: BleImg3Mobile
}

const BleContent = () => {


  // Redux
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const location = useLocation();

  const getLang = () => {
    if (location.pathname.includes('jp')) {
      return '/jp';
    } else if (location.pathname.includes('cn/')) {
      return '/cn';
    } else if (location.pathname.includes('en/')) {
      return '/en';
    } else {
      return '';
    }
  }

  return (
    <>
      <StyledTechnologyGroup>
        <DisplayH2 size="small"><Trans i18nKey={"ble.definition.title"} /></DisplayH2>
        <Spacing pixel="16" />
        <StyledTechnologyText size="medium">
          <p>
            <Trans i18nKey={"ble.definition.text"} />
          </p>
        </StyledTechnologyText>
        <DivAllCenter>
          <StyledParagraph size="medium">
            <Trans i18nKey={"simple.bleVideoDesc"} />
          </StyledParagraph>
          <a href={`${getLang()}/enterprise/manufacturing/process`} target="_blank" rel="noopener noreferrer">
            <Spacing pixel="24" />
            <VideoBox>
              <VideoPlay autoPlay loop muted playsInline>
                <source src={video} type="video/mp4" />
              </VideoPlay>
            </VideoBox>
          </a>
        </DivAllCenter>
      </StyledTechnologyGroup>

      <StyledTechnologyGroup>
        <DisplayH2 size="small"><Trans i18nKey={"ble.tech.title"} /></DisplayH2>
        <Spacing pixel="16" />
        <StyledTechnologyText size="medium">
          <p>
            <Trans i18nKey={"ble.tech.text"} />
          </p>
        </StyledTechnologyText>
      </StyledTechnologyGroup>

      <StyledTechnologyGroup>
        <DisplayH2 size="small"><Trans i18nKey={"ble.beacon.title"} /></DisplayH2>
        <Spacing pixel="16" />
        <StyledTechnologyText size="medium">
          <p>
            <Trans i18nKey={"ble.beacon.text"} />
          </p>
        </StyledTechnologyText>
      </StyledTechnologyGroup>

      <StyledTechnologyGroup>
        <DisplayH2 size="small"><Trans i18nKey={"ble.howItWorks.title"} /></DisplayH2>
        <Spacing pixel="16" />
        <StyledTechnologyText size="medium">
          <p>
            <Trans i18nKey={"ble.howItWorks.text"} />
          </p>
        </StyledTechnologyText>

        <StyledTechnologyItems>
          <StyledTechnologyItem desktop={6} tablet={4} mobile={4}>
            <div>
              <StyledTechnologyItemImg src={img1[layoutType]} alt="BLE" />
              <HeadingH3 size="xsmall"><Trans i18nKey={`ble.howItWorks.items.${0}.title`} /></HeadingH3>
              <Spacing pixel="16" />
              <StyledTechnologyItemDescription size="medium">
                <p>
                  <Trans i18nKey={`ble.howItWorks.items.${0}.description`} />
                </p>
              </StyledTechnologyItemDescription>
            </div>
          </StyledTechnologyItem>

          <StyledTechnologyItem desktop={6} tablet={4} mobile={4}>
            <div>
              <StyledTechnologyItemImg src={img2[layoutType]} alt="BLE" />
              <HeadingH3 size="xsmall"><Trans i18nKey={`ble.howItWorks.items.${1}.title`} /></HeadingH3>
              <Spacing pixel="16" />
              <StyledTechnologyItemDescription size="medium">
                <p>
                  <Trans i18nKey={`ble.howItWorks.items.${1}.description`} />
                </p>
              </StyledTechnologyItemDescription>
            </div>
          </StyledTechnologyItem>
        </StyledTechnologyItems>
      </StyledTechnologyGroup>

      <StyledTechnologyGroup>
        <DisplayH2 size="small"><Trans i18nKey={"ble.accuracy.title"} /></DisplayH2>
        <Spacing pixel="16" />
        <StyledTechnologyText size="medium">
          <p>
            <Trans i18nKey={"ble.accuracy.text"} />
          </p>
        </StyledTechnologyText>
        <Spacing pixel="32" />
        <StyledGridRow>
          <StyledGridColumn desktop={6} tablet={4} mobile={4}>
            <StyledTechnologyText size="medium">
              <p>
                <Trans i18nKey={"ble.accuracy.text2"} />
              </p>
            </StyledTechnologyText>
          </StyledGridColumn>

          <StyledGridColumn desktop={6} tablet={4} mobile={4}>
            <StyledTechnologyImg src={img3[layoutType]} alt="BLE" />
          </StyledGridColumn>
        </StyledGridRow>
      </StyledTechnologyGroup>

      <StyledTechnologyGroup>
        <DisplayH2 size="small"><Trans i18nKey={"ble.range.title"} /></DisplayH2>
        <Spacing pixel="16" />
        <StyledTechnologyText size="medium">
          <p>
            <Trans i18nKey={"ble.range.text"} />
          </p>
        </StyledTechnologyText>
      </StyledTechnologyGroup>

      <StyledTechnologyGroup>
        <DisplayH2 size="small"><Trans i18nKey={"ble.difference.title"} /></DisplayH2>
        <Spacing pixel="16" />
        <StyledTechnologyText size="medium">
          <p>
            <Trans i18nKey={"ble.difference.text"} />
          </p>
        </StyledTechnologyText>

        <StyledTechnologyItems>
          <StyledTechnologyItem desktop={6} tablet={4} mobile={4}>
            <div>
              <HeadingH3 size="xsmall"><Trans i18nKey={`ble.difference.items.${0}.title`} /></HeadingH3>
              <Spacing pixel="16" />
              <StyledTechnologyItemDescription size="medium">
                <p>
                  <Trans i18nKey={`ble.difference.items.${0}.description`} />
                </p>
              </StyledTechnologyItemDescription>
            </div>
          </StyledTechnologyItem>

          <StyledTechnologyItem desktop={6} tablet={4} mobile={4}>
            <div>
              <HeadingH3 size="xsmall"><Trans i18nKey={`ble.difference.items.${1}.title`} /></HeadingH3>
              <Spacing pixel="16" />
              <StyledTechnologyItemDescription size="medium">
                <p>
                  <Trans i18nKey={`ble.difference.items.${1}.description`} />
                </p>
              </StyledTechnologyItemDescription>
            </div>
          </StyledTechnologyItem>
        </StyledTechnologyItems>
      </StyledTechnologyGroup>
    </>
  );
};

export default BleContent;