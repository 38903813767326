import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledParagraph } from "@components/Text";
import React, { useEffect, useState } from 'react';
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";
import { DisplayH2 } from "@components/Text/HeadingTagDisplay.style";
import { LabelColor, ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import { CardBox, CardGap, CardTextBox, DescBox, InnerLabelBox } from "@pages/Main/Common/Functions/Functions.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import i18n from "@pages/i18n";
import simple from "@src/lang/ko/simple";
import { AccessPage, BigCard, NormalCard, SmallCard } from "./Access.style";
import { useLocation } from "react-router-dom";
import home from "@src/lang/ko/main/home/home";
import homeEn from "@src/lang/en/main/home/home";
import homeJp from "@src/lang/jp/main/home/home";
import homeCn from "@src/lang/cn/main/home/home";
import { HeadingH3, HeadingH3white } from "@components/Text/Heading/Heading.style";
import { ShowMoreButton } from "@pages/CommonStyle/commonBtn";



const Access = () => {


  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const location = useLocation();

  const getPath = (i : number) => {
    if(location.pathname.includes('/en/')){
      return homeEn.access.card[i].link;
    } else if(location.pathname.includes('/jp/')){
      return homeJp.access.card[i].link;
    } else if(location.pathname.includes('/cn/')){
      return homeCn.access.card[i].link;
    } else {
      return home.access.card[i].link;
    }
  }
  const [layoutMaxWidth, setLayoutMaxWidth] = useState<any>(null);

  useEffect(() => {
    const handleResize = () => {
      setLayoutMaxWidth(window.innerWidth);
    }

    setLayoutMaxWidth(document.documentElement.clientWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <StyledGridContainer>
        <AccessPage>
          <DivAllCenter>
            <DisplayH2 size="small">
              <Trans i18nKey={`home.access.title`} />
            </DisplayH2>
            <Spacing pixel="16" />
            <StyledParagraph size="medium">
              <p>
                <Trans i18nKey={`home.access.desc`} />
              </p>
            </StyledParagraph>
            <Spacing pixel="64" />
          </DivAllCenter>

          <CardBox>
            {home.access.card.map((data, i) => (
              <>
                {i < 2 && <>
                  {i === 0 ?
                    <BigCard w={layoutMaxWidth} img={data.img[layoutType]}>
                      <CardTextBox>
                        <ParagraphColor color={"#646464"} size={"small"}>
                          <Trans i18nKey={`home.access.card.${i}.tag`} />
                        </ParagraphColor>
                        <Spacing pixel="4" />
                        <HeadingH3 size="small">
                          <Trans i18nKey={`home.access.card.${i}.title`} />
                        </HeadingH3>
                        <DescBox>
                          <ParagraphColor color={"#818181"} size={"small"}>
                            <p>
                              <Trans i18nKey={`home.access.card.${i}.sub`} />
                            </p>
                          </ParagraphColor>
                        </DescBox>
                      </CardTextBox>
                    </BigCard>
                    :
                    <SmallCard w={layoutMaxWidth} img={data.img[layoutType]}>
                      <CardTextBox>
                        <HeadingH3 size="small">
                          <Trans i18nKey={`home.access.card.${i}.title`} />
                        </HeadingH3>
                        <Spacing pixel="4" />
                        <ParagraphColor color={"#646464"} size={"small"}>
                          <p>
                            <Trans i18nKey={`home.access.card.${i}.desc`} />
                          </p>
                        </ParagraphColor>
                        <Spacing pixel="16" />
                        <ShowMoreButton href={getPath(i)} target="_blank" rel="noopener noreferrer">
                          <img src={simple.showmoreImg[i18n.language]} alt="learn more button" />
                        </ShowMoreButton>
                      </CardTextBox>
                    </SmallCard>
                  }
                </>}
              </>
            ))}
          </CardBox>

          <CardGap />

          <CardBox>
            {home.access.card.map((data, i) => (
              <>
                {i > 1 &&
                  <NormalCard w={layoutMaxWidth} img={data.img[layoutType]}>
                    <CardTextBox>
                      {data.tag ? 
                      <HeadingH3white size="small">
                        <Trans i18nKey={`home.access.card.${i}.title`} />
                      </HeadingH3white>
                      :
                      <HeadingH3 size="small">
                        <Trans i18nKey={`home.access.card.${i}.title`} />
                      </HeadingH3>
                      }
                      <Spacing pixel="4" />
                      <ParagraphColor color={data.tag ? "#BDBDBD" : '#646464'} size={"small"}>
                        <p>
                          <Trans i18nKey={`home.access.card.${i}.desc`} />
                        </p>
                      </ParagraphColor>
                      {data.link &&
                        <>
                          <Spacing pixel="16" />
                          <ShowMoreButton href={getPath(i)} target="_blank" rel="noopener noreferrer">
                            <img src={simple.showmoreImg[i18n.language]} alt="learn more button" />
                          </ShowMoreButton>
                        </>
                      }
                      {data.tag &&
                        <>
                          <Spacing pixel="16" />
                          <InnerLabelBox color={'#EAF1FD'}>
                            <LabelColor color={"#2A70F0"} size={"small"}>
                              <Trans i18nKey={`home.access.card.${i}.tag`} />
                            </LabelColor>
                          </InnerLabelBox>
                        </>}
                    </CardTextBox>
                  </NormalCard>
                }
              </>
            ))}
          </CardBox>

        </AccessPage>
      </StyledGridContainer>

    </>
  )
}

export default Access;