import { logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@assets/images/solution/logisticsSafety/video/rtlsVideo.mp4"

export default {


    helmet: '| IPS(Indoor Positioning System)란?',
    desc: 'IPS란 Indoor Positioning System의 줄임말로, 사용자 또는 물체의 정확한 위치를 건물 내부에서 추적하는 기술입니다. IPS는 다양한 응용 분야에서 활용되고 있으며, 실내 내비게이션, 자동화, 보안, 환경 모니터링 및 리소스 관리 등 다양한 영역에서 필요합니다.',
    keywords: '',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'IPS(Indoor Positioning System)란? | 위치추적 전문, 오브로',
    ogUrl: 'https://orbro.io/blog/indoor-positioning-system',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDk6TQ-h2KkQiYudK2spJrwM9-DVyXGqjpnyMQI2sZIQz1hxm14GXjbIy0_vPqBdcWNqBfevlZpTaHCDaKLTSInodrYeccUP3gpQNgAtrMwqEHlrjdh4WhikpPJ_iDLCi3PldIyG97g8uPiEGv2KbKurpE7JoCN5mRkfiH448dzhhpubMZxkB2ZKIJC7k8kNPYcSX1A-QOydJBw_yipl-pCZS_3oxhv2uYggjB0clzoEqTdYWRLydtMHV79DjszkbWJW3LTgrYEKIQP1Kbdw7ZItA-BwStqSuGehlMzlqxoFgQRChchtMnWac9VkUzOWnga-7_XUfmX90CtTqWRrFZg9Umjy8FX9288OQT_dXLNDDdowk_5t23b2mX-L7yW25zgxFHd3VtxgRongH9p94aLi4mMDO6fmT_8ajfcngVp9gOjPzHsmkOESsojVUQc706JPrK__OnMo7qr_aEVb15EIjZlqlJaJ4IhcIuXokNh-sp1nkFHuZ8qW_HXAB3FZu7r91T6rPAmKirnvFAtZdTghCXsX9nzmLybftlK1YYBWTAV0qcWkL5Ckpp2njt9pFjoXmTQqbdpz3duHY-AljIdMbCDzS444jPCLaKfXTkCmbV6WnZRaPfGR81_Q82hX3jxtuLKQmjMmCgLgCl3gp5L8CZi1hvvtQKMdcDSJ90ZUhsu2CZkmvwQ3Q_03VoJSlwwFZ3EUN3W_GB8e5cWXpNGvQccrSjeCnmIF9aVrHwtaIdWTvpskUJKjNzlqAafVUfTouYOSrV8OCfLPwSQTV8iSm2qnbRuoBigg9xouxN0bRwPkIiz67dGt4JKQcqivJnCoQxzvVomW1fVr4UbxMq6akImY3IxQ7ovGV81wA46n8tx5WPSPLMCEKQBK9FkzHiHqb8Pxxw9rzzuIa6TtoY6KRfti9zMv4fsmGqX6Q1_R5B-ABtExcOjVt-x_mS5Y3ZQR0jJIdKh4GEj98Pa9oTTF3saa28UWqTTjqRErmeVY04QTsy_SqFx9CyzU_2V1_zbdvez8hyZLuj5L9K-cvJyCKZTyBCrw0YzWwOX2MZ6fCyqvFzPfzzKJZRUncB0UrOVKw7dGDdDlaX5CqXBk-kR06RFpJIEf1fS0zEk2cBhS3NX2BU_PuueEkq1bQyLe9sG9kxqLXZ9jOc8ImIMr4X6JZgbKdO0-x0t_LkclmAjG3g4QuaNl63oDUgU1QUvQoQhDiCEHrK0xqXRaU7qSPbYXesuwkNkmOgVwPoJnNa-jYCoJ__RR4WZomXaiPckDuGTkSKx2dOfaSs7TbvIb7fqqcv5kVXnVeEtCheiIU220AnBQ6P4vLOzcQM3JP63j9eLnrIAgh2NMqXpyNVhSY5elNnry6i9vqriqjEwypIBg-eRxHD_S7iWd7gaTLIA_cu_QyeVrW7fYEMoc830qGVhoMH7VNFbffpm5PtC_9B2e2P4xe4fQIisHlT1tnhnFJynZshU2YAiNTWNWMEnpEsitAZMUPJ6oRGSQe_DK9xz-bmpANM-9WSuCR8vlKsLHuWX8A516ShbC0Y9wnZQrN3RK1Rt3xRlX=w2045-h1221',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'IPS(Indoor Positioning System)란?',
    date: '2024-01-23',
    tag1: '#IPS',
    tag2: '#실내위치추적',
    tag3: '#RTLS',
    tag4: '#UWB',

    // <br/>
    text: [
        {
            head: 'IPS(Indoor Positioning System)란?',
            subHead: '',
            desc: 'IPS란 Indoor Positioning System의 줄임말로, 사용자 또는 물체의 정확한 위치를 건물 내부에서 추적하는 기술입니다. IPS는 다양한 응용 분야에서 활용되고 있으며, 실내 내비게이션, 자동화, 보안, 환경 모니터링 및 리소스 관리 등 다양한 영역에서 필요합니다.<br/>물론  GPS와 같은 실외 위치 추적 시스템이 널리 사용되고 있지만, 실내에서의 정확한 위치 추적은 더 복잡한 문제입니다. 이 문제를 해결하기 위해 개발된 기술 중 하나가 IPS 입니다. 이 글에서는 IPS에 대해서 자세히 살펴보겠습니다.',
            dot: ''
        },
        {
            head: 'IPS의 중요성',
            subHead: '',
            desc: '현대 사회에서 스마트폰 및 기타 모바일 디바이스의 보급으로 인해 위치 결정 기술의 중요성은 급격하게 증가했습니다. 이 기술은 우리가 지도 어플리케이션을 통해 길을 찾거나, 실내에서 쇼핑을 할 때 효율적인 정보를 제공하기 위해 핵심적인 역할을 합니다. 이러한 위치 결정 기술 중 하나가 "실내 위치 결정 시스템" 또는 IPS입니다.<br/><br/>IPS는 기기 또는 사용자의 위치를 정확하게 파악하고 추적하기 위한 기술 및 시스템을 말합니다. 주로 실내 환경에서 사용되며, GPS (Global Positioning System)와는 달리 건물 내부에서도 효과적으로 동작합니다.',
            dot: ''
        },
        {
            head: 'IPS 작동 원리',
            subHead: '',
            desc: '',
            dot: {
                a : '<strong> • UWB 기술 활용 : </strong>UWB 기술은 주변 장치 또는 태그와의 거리를 매우 정확하게 측정합니다. 이를 이용하여 사용자와 UWB 태그(예: 스마트폰 또는 뱅크 카드) 사이의 거리를 계산합니다.',
                b : '<strong> • BLE (Bluetooth Low Energy) :</strong> Bluetooth LE 비콘을 사용하여 실내 위치를 결정하는 방법입니다. 비콘은 고유한 식별자를 발송하며, 이를 기반으로 위치를 추정합니다. 정확도가 높지만 비콘을 배치해야 하므로 비용이 들 수 있습니다.',
                c : '<strong> • AI 카메라 활용 : </strong>AI 카메라는 카메라로부터 얻은 영상을 분석하여 주변 환경을 이해하고, 물체 또는 사람을 감지할 수 있습니다. 이를 통해 카메라는 사용자의 위치와 움직임을 실시간으로 파악합니다.',
                d : '<strong> • Wi-Fi 기반 위치 결정 : </strong> Wi-Fi 접속 지점의 신호 강도를 기반으로 사용자의 위치를 결정합니다. 각 Wi-Fi 액세스 포인트는 고유한 MAC 주소를 가지며, 이를 사용하여 사용자의 위치를 파악합니다. 하지만 정확도는 약하며, 신호 간섭 및 범위 한정이 있을 수 있습니다.',
            }
        },
        {
            video : video,
            link : '/enterprise/logistics/safety',
            videoDesc : '오브로 물류 디지털 트윈, 클릭하면 이동합니다.',
            head: 'IPS 기술의 장점',
            subHead: '',
            desc: 'GPS는 실내 환경에서 작동하지 않기 때문에 IPS는 실내 위치 결정의 정확도를 높입니다. 정확한 위치 데이터를 기반으로 사용자나 자산의 정확한 위치를 파악할 수 있습니다. 또한 IPS는 실시간으로 사용자나 자산의 위치를 추적할 수 있어, 실내 환경에서 실시간 모니터링 및 관리가 가능합니다. 이는 비상 상황에서 긴급 조치를 취하거나, 자산을 효율적으로 관리할 때 중요합니다.<br/><br/>IPS를 통해 작업 및 프로세스를 자동화하고 효율화할 수 있습니다. 예를 들어, 자동차 공장에서 자율 주행 로봇을 위치 결정하기 위해 IPS를 사용하면 생산성을 향상시킬 수 있습니다. 예를 들어, 물류 산업에서는 물류 및 자산 관리를 최적화하여 비용을 절감할 수 있습니다. 또는 병원, 학교, 공항 등 다양한 장소에서 IPS는 비상 상황 대응을 개선하는 데 도움이 되기도 하며, 환자 추적, 학생 및 교직원 안전, 대규모 이벤트의 경로 안내 등에서 사용될 수 있습니다.',
            dot: ''
        },
        {
            head: 'IPS 적용 사례',
            subHead: '상점 내 실내 탐색 및 마케팅',
            desc: '',
            dot: {
                a : ' • 상점 내에서 고객이 원하는 상품을 쉽게 찾을 수 있도록 돕습니다.',
                b : ' • 주문과 결제 프로세스를 단순화하여 고객 경험을 향상시킵니다.',
                c : ' • 고객의 위치를 파악하여 상품 추천 및 할인 정보를 제공하여 마케팅을 개선합니다.'
            }
        },
        {
            head: '',
            subHead: '병원 및 의료 시설 내 환자 추적',
            desc: '',
            dot: {
                a : ' • 의료 시설에서 환자를 추적하고 환자의 위치를 실시간으로 파악합니다.',
                b : ' • 의료진은 환자를 빠르게 찾고 응급 상황에 대비할 수 있습니다.',
                c : ' • 감염 예방 및 의료 장비 관리를 위한 효과적인 시스템으로 활용됩니다.',
            }
        },
        {
            head: '',
            subHead: '공항 내 승객 안내 및 보안',
            desc: '',
            dot: {
                a : ' • 승객은 공항 내에서 탑승 게이트, 수하물 수령 지점 및 서비스 시설을 찾기 쉽게 할 수 있습니다.',
                b : ' • 보안 관리 및 대기열 관리에 도움을 줍니다.',
                c : ' • 적절한 시간에 승객을 게이트로 안내하여 비행 스케줄을 준수할 수 있습니다.',
            }
        },
        {
            head: '',
            subHead: '실내 스포츠 및 엔터테인먼트 시설 내 경기 및 이벤트 관리',
            desc: '',
            dot: {
                a : ' • 관객들은 경기장 내에서 좌석을 찾고 음식 및 음료를 주문할 수 있습니다.',
                b : ' • 경기 결과와 팀 정보를 실시간으로 제공하여 엔터테인먼트 경험을 향상시킵니다.',
                c : ' • 경기 및 이벤트 관리를 효율적으로 수행합니다.',
            }
        },
        {
            head: '',
            subHead: '공장 및 창고 내 물류 및 자산 관리',
            desc: '',
            dot: {
                a : ' • 공장이나 창고 내에서 장비, 제품 및 자산을 추적하고 위치를 파악합니다.',
                b : ' • 물류 및 공급망 관리를 최적화하여 생산성을 높입니다.',
                c : ' • 재고 관리 및 이동 경로 최적화에 도움을 줍니다.'
            }
        },
        {
            head: '',
            subHead: '학교 및 대학 캠퍼스 내 학생 및 교직원 안내',
            desc: '',
            dot: {
                a : ' • 학생들과 교직원은 캠퍼스 내에서 강의실, 도서관, 식당 및 주차장을 찾기 쉽게 할 수 있습니다.',
                b : ' • 학생 출석 추적 및 교육 자원 관리를 지원합니다.'
            }
        },
        {
            head: '',
            subHead: '실내 자율 주행 및 로봇 협업',
            desc: '',
            dot: {
                a : ' • 실내 위치 결정 기술은 자율 주행 차량 및 로봇이 정확한 경로를 따를 수 있게 도와줍니다.',
                b : ' • 로봇의 작업 효율성을 향상시켜 다양한 업무를 수행합니다.',
            }
        },
        {
            head: 'IPS의 미래 전망',
            subHead: '',
            desc: 'IPS 기술은 계속 발전하고 있으며, 실내 위치 결정의 정확성과 효율성을 향상시킬 것으로 기대됩니다. 5G 네트워크와의 통합, 인공 지능 및 기계 학습의 도입, 센서 기술의 진보 등이 IPS의 미래를 밝게 보이고 있습니다. 더 정확하고 신속한 위치 결정은 다양한 업종에서 혁신과 효율성을 가져올 것으로 예상됩니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '',
            desc: '',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/enterprise/logistics/asset'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/enterprise/manufacturing/asset'
            }
        ]

    }


}