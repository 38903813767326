export default {
  download : "ダウンロード",
  safety: "建設現場の安全管理ソリューション <br/> 資料のダウンロード",
  assets: "物流倉庫の在庫位置分析 <br/> 資料のダウンロード",
  medical: "病院の医療機器管理測位 <br/> 資料のダウンロード",
  process: "リアルタイム生産プロセスモニタリング <br/> 資料のダウンロード",
  visitor: "展示会の来場者経路分析 <br/> 資料のダウンロード",


  description: "資料をメールでお送りいたします。",

  form: {
    emailPlaceholder: "name@company.com",
    policy: {
      info: "情報を提供することにより、Orbroの ",
      link : "個人情報処理方針 ",
      agree: "に同意します。 ",
      check : "[必須]"
    },
  },

  cancel: "キャンセル",
  submit: "ダウンロード",


  send: "に<br/>資料を送信しました。",
  newsletter : "Orbroのニュースレターを購読するには、以下のフォームに入力してください！購読者の方には、毎週最新のニュースと情報をメールでお送りします。",
  name : "名前（必須）",
  namePlaceholder : "山田太郎",
  team : "所属名（必須）",
  teamPlaceholder : "所属名",
  phone : "電話番号（任意）",
  phonePlaceholder : "010-1234-5678",
  skip : "スキップ",
  subscribe : "購読する",

  ask : "Orbroのニュースレターを購読して、技術やさまざまな情報を受け取りますか？",
  thanks : "購読していただき、ありがとうございます。",
  thanksMore : "Orbroのニュースレターを購読いただき、ありがとうございます！購読者の方には、最新のニュースと情報をメールでお送りします。Orbroと共に、より多くの情報と知識を得てください。",
  done : "確認"
  
}