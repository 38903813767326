export default {
  visual: {
    subtitle: '我们的愿景',
    title: '栩栩如生的<br/>虚拟世界实现。',
    description: 'Orbro的使命是为了能够通过眼睛看到全球约200 zettabytes的数据而诞生的。为了可持续的数据生态系统，我们正在将地球的信息复制到Orbro，并通过以3D形式呈现的对象创建创新性的数据连接。<br/><br/>在Orbro中，通过将从专用设备收集的位置信息与散布在网络上的信息相连接，超过20,000个对象实时运动。为了加快这一速度，我们正在通过开放政策建立一个全球人们可以参与的生态系统。<br/><br/>快来创建自己的世界，参与数字化转型吧！',
    items: [
      {
        title: '世界第一',
        description: '基于Web的虚拟世界平台'
      },
      {
        title: '全球最大',
        description: '连接现实与虚拟信息的平台'
      },
      {
        title: '500亿+',
        description: '亚洲规模最大的分析位置信息数据'
      },
      {
        title: '第一名',
        description: '工业用Metaverse绩效（韩国基准）'
      },
      {
        title: '150,000+',
        description: 'Orbro产品销量'
      },
      {
        title: '500,000+',
        description: '累计服务用户数'
      },
      {
        title: '20,000+',
        description: '实时连接的对象数量'
      },
      {
        title: '97.5%',
        description: '全球大部分建筑物已上传'
      },
      {
        title: '1,000+',
        description: '累计客户数'
      },
    ]
  },
  ourPartners: {
    subtitle: '我们的合作伙伴',
    title: '许多企业正在通过Orbro体验数字化转型。'
  },
  ourTechnology: {
    subtitle: '我们的技术',
    title: 'Orbro正在革新技术。',
    description: '经过长时间的研究，Orbro拥有位置分析的核心技术。',
    policy: '相关专利',
    items: [
      {
        title: '无线信号稳定化',
        description: '2.4GHz信号受周围介质影响较大，因此在基于Beacon系统的应用中，无线信号稳定化技术是必不可少的。Orbro的无线信号处理技术经过了约10年在300多个现场的验证，并且可以根据客户的参考快速调整。',
        policy: [
          '用于判断移动式标签位置的系统、方法和计算机可读媒介',
          '用于将用户的危险情况通知监控服务器的移动式Beacon系统'
        ]
      },
      {
        title: '定向天线设计',
        description: '由于蓝牙信号容易受到周围环境的影响，在无线通信技术中，根据环境设计天线是最基本的。不仅限于无定向性天线，还可以设计能够区分信号方向的定向性天线。结果，可以将天线、产品和信号处理系统打包在一起供应，从而实现更稳定和有效的服务。',
        policy: [
          '基于Beacon信号的门控方法和系统',
        ]
      },
      {
        title: '信号分割/多信号处理',
        description: '使用多个天线可以更快地发送/接收信号，或者可以区分移动终端（或移动设备）靠近哪个信号。应用多信号处理技术，可以区分室内和室外、建筑物进入和离开等，从而可以创建新的服务场景。',
        policy: [
          '用于判断移动式标签位置的系统、方法和计算机-可读媒介',
          '具有安全功能的商店内电子设备信息提供系统'
        ]
      },
      {
        title: '低功耗无线触发器',
        description: '低功耗无线触发器技术是指对于需要长时间传输的终端设备，接收器会发出触发信号，使其进行临时双向通信的技术。与配对相比，它的速度要快10倍以上，并且功耗要低20倍以上。',
        policy: [
          '检测Beacon设备信号的方法和系统'
        ]
      },
      {
        title: 'Mesh网络',
        description: 'Orbro的Mesh网络通过使用低功耗通信来在需要低功耗的环境中产生更好的结果。它以Flood Mesh算法和低功耗无线触发器技术为基础，并且相对于传统Beacon最多消耗50倍以下的功耗，并且速度比传统方法快3倍以上，并且可以进行多个信息连接。在供电不稳定的环境中，当需要定期接收多个设备的信息时，它非常有效。',
        policy: [
          '实施Mesh网络的Beacon系统的方法',
          '利用Mesh网络通信技术的安心铃系统'
        ]
      },
      {
        title: '无定向信号的定位技术',
        description: 'Orbro的Insight通过分析安装的每个Twintracker与用户终端之间的相关关系来推断每个Twintracker标签的方向向量。在理论上，可以通过交叉点推断标签的方向。然而，在实际环境中，由于信号的不稳定性和物理障碍物的存在，交叉点不会汇聚到一个位置。在这样的环境中，为了推导出2D位置，我们使用基于二维图纸的概率密度来推断位置。',
        policy: [
          '推导出用户终端的2D位置的方法和系统'
        ]
      },
      {
        title: '提取视频对象的坐标',
        description: '通过应用Object Detection和Depth Estimation等技术，可以识别图像中的人并推断与其的距离。基于此，为了在2D图纸上确定人的位置，可以识别摄像机的视角和识别人所在的方向，然后计算坐标。',
        policy: [
          '利用相机图像推导对象位置的方法和系统'
        ]
      },
      {
        title: '位置分析后处理算法',
        description: '通过应用Object Detection和Depth Estimation等技术，可以识别图像中的人并推断与其的距离和角度，然后使用这些结果推断坐标。为了校正这些结果，使用当相同空间内没有人时摄像机识别到的与地板之间的距离和存在人时的距离的比例，预测识别到的人的坐标，并补充测量到的坐标结果。',
        policy: [
          '利用相机图像改善对象位置信息输出的方法和系统'
        ]
      },
      {
        title: '基于机器学习的控制',
        description: '通过使用3C强化学习模型，如果用户调节温度，则会给予惩罚，并在一定时间内不调节温度，则给予奖励，以自动控制适当的温度。',
        policy: [
          '利用机器学习模型的空调自动舒适控制方法、系统和计算机可读媒介',
          '控制空间内多个空调的系统、方法和计算机可读媒介'
        ]
      },
      {
        title: '数字孪生界面',
        description: 'Orbro拥有专门针对数字孪生的界面结构。通过点击3D空间中的对象，可以轻松访问相关应用程序。',
        policy: [
          '提供以对象为中心的应用程序执行界面的方法、执行该方法的服务服务器和计算机可读媒介',
          '提供与对象相关的应用程序列表界面的方法、执行该方法的服务服务器和计算机可读媒介',
          '提供基于对象和应用程序关联度的应用程序列表界面的方法、执行该方法的服务服务器和计算机可读媒介'
        ]
      },
    ]
  },
  ourCulture: {
    subtitle: '我们的文化',
    title: '这就是我们的工作方式。',
    description: '将想象变成有价值的现实的旅程',
    discovery: {
      title: '探索',
      description: '在充满无限可能的世界中发现机会。'
    },
    extension: {
      title: '扩展',
      description: '通过紧密的投入扩展思维。'
    },
    collaboration: {
      title: '协作',
      description: '通过紧密的合作实现想象。'
    },
    refine: {
      title: '完善',
      description: '创造有价值的成果并加以改进。'
    },
  },
  ourHistory: {
    subtitle: '我们的历史',
    title: 'Orbro的故事',
    histories: {
      '2022': [
        "成立7年实现销售额达100亿韩元",
        "K-TECH, 获得全球三大设计奖'Red Dot'奖项",
        "推出基于数字孪生的Metaverse平台'Orbro'",
        "引入Orbro系统于京畿道坡州"
      ],
      '2021': [
        "年销售额增长3倍，扩大坡州办公室",
        "与环境部、友情生物、LG电子、阿朱大学共同举办'非接触式医疗废弃物回收处理机器人'项目",
        "获得科学技术信息通信部'AI技术实证测试床建设'项目，运营项目团队",
        "韩国道路公社'基于AI的建筑工地危险预测系统'项目投资"
      ],
      '2020': [
        "结合世界一流的AI位置分析技术，正式供应实时位置跟踪系统(RTLS)服务",
        "BeanCheck累计服务企业数量比去年增长10倍以上",
        "获得行政安全部'定制地方化灾难安全问题解决技术开发支援'项目，供应京畿安心钟"
      ],
      '2019': [
        "推出BeanCheck服务（3个月内成为行业前3），付费用户突破1万人",
        "Gartner供应商简报"
      ],
      '2018': [
        "全球销售智能工厂校准工具",
        "开始为国内大型公寓建筑公司提供IoT门禁管理系统"
      ],
      '2017': [
        "推出Bean监控服务，安装于韩国高速公路危险区（约2000个地点）",
        "设立子公司（Bean Monitoring）"
      ],
    }
  },
  news: {
    "subtitle": "新闻",
    "title": "Orbro引起世界的关注",
    "news": [
      {
        "agency": "每日经济新闻",
        "createdAt": "2022.6.27",
        "title": "K-Tech获得全球三大设计奖'Red Dot'奖项",
        "link": "https://www.mk.co.kr/news/business/view/2022/06/562542/"
      },
      {
        "agency": "每日经济新闻",
        "createdAt": "2022.4.19",
        "title": "K-Tech成立7年实现销售额达100亿韩元",
        "link": "https://www.mk.co.kr/news/business/view/2022/04/349210/"
      },
      {
        "agency": "Etoday",
        "createdAt": "2022.4.18",
        "title": "K-Tech去年销售额突破100亿韩元，位于坡州的办公室规模扩大至170亿韩元",
        "link": "https://www.etoday.co.kr/news/view/2125046"
      },
      {
        "agency": "数据网",
        "createdAt": "2021.10.29",
        "title": "K-Tech向SK Shield、正林建筑提供最新IoT技术支持",
        "link": "http://www.datanet.co.kr/news/articleView.html?idxno=165926"
      },
      {
        "agency": "金融新闻",
        "createdAt": "2021.10.14",
        "title": "K-Tech创始人李学京：“经营中没有奇迹，只有紧张的过程”",
        "link": "http://www.jjan.kr/2118721"
      },
      {
        "agency": "ZDNet Korea",
        "createdAt": "2021.6.24",
        "title": "K-Tech：仅凭智能手机即可进出公寓",
        "link": "https://zdnet.co.kr/view/?no=20210624211558"
      },
      {
        "agency": "CCTV News",
        "createdAt": "2021.6.24",
        "title": "K-Tech推出基于智能手机的公寓出入安全解决方案'K-Pass'",
        "link": "http://www.cctvnews.co.kr/news/articleView.html?idxno=228019"
      },
      {
        "agency": "Recruit Times",
        "createdAt": "2021.5.27",
        "title": "K-Tech与环保生物、LG Electronics、阿朱大学共同开发“非接触式医疗废弃物回收处理机器人”项目",
        "link": "https://www.medipana.com/article/view.php?news_idx=276992&sch_menu=1&sch_gubun=5"
      },
      {
        "agency": "数据网",
        "createdAt": "2020.11.16",
        "title": "K-Tech正式提供实时定位系统'K-Service'",
        "link": "http://www.datanet.co.kr/news/articleView.html?idxno=152892"
      },
      {
        "agency": "Global Economic",
        "createdAt": "2020.2.31",
        "title": "提供考勤管理解决方案的K-Tech更新了适应现场需求的'K-Check 3.0'",
        "link": "https://news.g-enews.com/ko-kr/news/article/news_all/202002211426069349aca1a8c21a_1/article.html?md=20200221142829_U"
      },
      {
        "agency": "Citizen Ilbo",
        "createdAt": "2020.2.5",
        "title": "K-Tech向幼儿园电子签到项目交付了25万个Beacon",
        "link": "http://www.siminilbo.co.kr/news/newsview.php?ncode=1065603668995186"
      },
      {
        "agency": "Sisamagazine",
        "createdAt": "2019.12.19",
        "title": "K-Tech获得家庭友好认证企业",
        "link": "https://www.sisamagazine.co.kr/news/articleView.html?idxno=307188"
      },
      {
        "agency": "Sisa News",
        "createdAt": "2019.12.3",
        "title": "K-Tech获得'2019韩国品牌满意度IT部门第一名'奖项",
        "link": "http://www.sisa-news.com/news/article.html?no=122374"
      },
      {
        "agency": "Magazine Hankyung",
        "createdAt": "2019.11.26",
        "title": "[2019韩国品牌满意度第一名] K-Tech的智能考勤管理系统技术",
        "link": "https://magazine.hankyung.com/business/article/201911262429b"
      },
      {
        "agency": "Sisamagazine",
        "createdAt": "2019.7.30",
        "title": "以Beacon解决方案技术引领的K-Tech创始人李学京",
        "link": "https://www.sisamagazine.co.kr/news/articleView.html?idxno=215613"
      },
      {
        "agency": "Gonggam News",
        "createdAt": "2018.12.19",
        "title": "无需刷卡或指纹识别即可使用的智能考勤程序'K-Check'上市",
        "link": "https://www.gokorea.kr/news/articleView.html?idxno=62896"
      },
      {
        "agency": "Dong-A.com",
        "createdAt": "2018.7.27",
        "title": "K-Tech将于9月发布基于Beacon技术的'幼儿园通勤车安全管理系统'",
        "link": "https://www.donga.com/news/article/all/20180727/91250851/2"
      },
      {
        "agency": "Energy Economy Newspaper",
        "createdAt": "2017.4.21",
        "title": "三星ARTIK合作伙伴'K-Tech'推出ARTIK5 Standard EVAL开发板",
        "link": "https://www.ekn.kr/web/view.php?key=282853"
      },
      {
        "agency": "数据网",
        "createdAt": "2017.4.18",
        "title": "IT公司'K-Tech'搬迁至坡州...成长的信号弹",
        "link": "http://www.datanet.co.kr/news/articleView.html?idxno=110048"
      }
    ]
  }
}