import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { logisticsAsset, manufacturingAsset, manufacturingProcess, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4"

export default {
    helmet: '',
    desc: '在地图上实时监控库存位置和生产过程，通过数字自动化建立智能工厂，以提高生产效率和质量。',
    keywords: '智能工厂，库存管理，资产管理，流程监控，生产自动化，工厂机器人，自动工厂，自动化流程，智能工厂，Contec，Orbro，rtls，orbro',

    ogTitle: '',
    ogDesc: '',
    ogSite: '智能工厂建设与生产自动化解决方案 :: Orbro博客',
    ogUrl: 'https://orbro.io/cn/blog/productionAutomation',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxXE6MzArHBPAtgIpznMDdCH4J8pK06Pt6RC09sNHW7PTLIF0PmPta2ymrlAdxzXB1xM2c15BXwtMiUlMiXHV5-rX_y-MCj5rxn28ZcepfxYkwYGbTtkD2tiwtidfaA7UEiWPc_QZU5UvVQ_07DIs56mvdkD5LITBtqiZP7tZHrG0NN30d5aoj0cErsx5-AXHIQuv5zO6bwkV_42KLIq2YCCSe_xQfkSXyh6zwheUsNCyZV5Cs9oUd6ImOHrCWsB1JXDQljN2q2G8-li2gz1NQV2v7__j_4t3KgJDoCmqWuuFG495xjseOwBNsdbOkL9jBB79LwHj2EeEfqTUCmPYuKjA1HxHrHCe1kQDTXbjBxYc-GoornZ3F1PG1fmwyz5EQt9n7BVSD2smPwW9zHqSVYdcwpwdzjgnVBZ3tKehLBz7gRUtfjRxxmYWMbP6-WvApgEY0_TXrwuOnT_3R44fYEsAQeQSJU1xGt-FT7Kbs_cEtrKJ09pqHvl4tOeWBKTBgqNPQi6jC3AU8eKzNgSPVI641s3fQ9Bgfpq8FFw6Rt4ry87oCoCXJErzzb8G6TI-DWbBnvd0s49QfnIWK2e6fLVdGmnzgy4vMgGa8kwu4YUXRDNn4lju7F-Uwglr1KkkLrfsUgBJe-eLigJnmV32eHXFTRod_bwAstvo2SUiqPG-jwDDjG2mEyQlODDkYR9ghqohTDwpQWEen7XYXcAzlcuo-W2XT-KLvL_3K0LaSWaV9RfuH4lEvJZg_5O4AZVvbaoonwP8c3O2kPDidXj-aGYpEkZAiERMu_6E3LuqbK6LYx3XKDOKkv_BB5569dm6PjLKrCNvyKpsQHYuRzAD6KjmMC2M55q_b4QrIldAJ3OIGc3it9CKfkRPBchL0fmymgKDrP4bYSRskJ7ezbG3mrJlmftUW4zRbdV51Be_JnMCpDP7ZHni4RsYAeKni05hHJmQlYGqb3kj64d5eiJwctbRLV1PRD_TN5cT_1IILP4xyTKxXanxncYJeyv_1ju9rI5-ROwlCt_yt6IDKC6svINvZQGxFd27XpmvlkiFq2KNNTbKekQ7YI9QP9Wssadz30lALiW08lksJZ35fwt2xP_-Wsi6PcMQz-P3uXI_70uAZdl2X5Qe1NEjfoVSUv3r9e8L7Kq22Xo1x7MClBLI1lb88GAe4GaKiYNmhYL1A3YP-hn22TIwRvcJuFHOWWQa1GuowDDCu5xve-5jEasGKyBgkoliyCv4Whu5WYvSKb5uBwYRzJWXr4AuyUGPGTZwcO_067lWFWNoJz9s6XviIJ8aHA7_zJn5ZQrokTVhOC-Cv8m6SM8YfA0bKWlE4hUZjbYdzQDsCl95UzT9WaGLONhe7h7EUo3Nt_oIXYtUso9Lqo6fu8nvLxskCelBaZ5xLRDKYnrYpxehQPCO-iTxz9WGYxKbrTFeHIXJU4jwKn-Envs1kbg34tE5lnMSGW9mGEJLIsO8h7yJ2VrKeks-V2StiHHhLCtQvCZEKJIwLfeQ8wnOuTHDHNAnkuQDSTk6mGn7bOFAOHw7L0Aloc1_6w_HfzWqtEyxlm_9bWDuQyLv9LXHa1=w2000-h1548',
    ogType: '网站',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '智能工厂建设与生产自动化解决方案',
    date: '2023-11-06',
    tag1: '#智能工厂',
    tag2: '#生产自动化',
    tag3: '#流程监控',
    tag4: '#智能工厂',

    text: [
        {
            head: '',
            subHead: '',
            desc: '现代制造商非常关注智能工厂建设和生产自动化解决方案，以确保竞争力和提高生产率。随着现代技术的进步和智能系统的普及，这一趋势变得更为重要，实时定位系统（RTLS）技术成为智能工厂建设的关键技术之一。',
            dot: ''
        },
        {
            video: video,
            link: '/cn/enterprise/manufacturing/process',
            head: '什么是智能工厂？',
            subHead: '',
            desc: '智能工厂是指利用最新技术和自动化系统对现有制造系统进行创新并优化生产过程的制造环境。智能工厂为制造商提供各种优势，包括质量改进、生产效率提高和能源效率提高等。要实现这些优势，RTLS技术发挥了关键作用。',
            dot: ''
        },
        {
            head: '智能工厂的外观',
            subHead: '',
            desc: 'RTLS技术代表实时定位跟踪系统，利用各种技术和传感器精确跟踪物体、设备、产品和工人的位置信息。将该技术应用于智能工厂后，可以获得以下优势：<br/><br/><strong>资源管理和优化：</strong> RTLS允许实时跟踪制造设施内所有资源的位置信息，从而有效地管理和优化设备、材料和工人。<br/><br/><strong>生产过程监控：</strong> RTLS可以实时监控制造过程，快速检测和应对生产线上发生的任何异常。<br/><br/><strong>自动化和机器人应用：</strong> RTLS可用于控制机器人和自动化设备，自动化生产过程并最小化人力投入。<br/><br/><strong>库存管理：</strong>基于实时位置信息，可以管理库存并跟踪库存移动，从而节省成本并实现高效的库存管理。<br/><br/><strong>安全和安全增强：</strong> RTLS跟踪工人的位置，增强了工作场所的安全性和安全性，可以检测未经授权的访问并有助于预防事故。',
            dot: ''
        },
        {
            head: '如何建立智能工厂',
            subHead: '',
            desc: '要将RTLS应用于智能工厂，可以按照以下步骤进行：<br/><br/><strong>需求分析：</strong>首先，分析制造商的需求，并确定需要哪种RTLS系统。<br/><br/><strong>传感器和硬件安装：</strong>安装用于构建RTLS系统所需的传感器和硬件，用于跟踪物体和工人的位置。<br/><br/><strong>数据收集和处理：</strong>处理传感器收集的数据并生成实时位置信息。<br/><br/><strong>系统集成：</strong>将RTLS系统与其他生产系统集成，以实现自动化和监控。<br/><br/><strong>优化和改进：</strong>一旦系统运行，不断改进以监控和优化生产过程。',
            dot: ''
        },
        {
            head: '生产自动化和智能工厂的未来',
            subHead: '',
            desc: '建立在RTLS基础上的智能工厂有望在制造业的未来发挥主导作用。生产自动化和智能系统对于提高质量和生产效率至关重要。此外，与人工智能（AI）和大数据分析的整合将使工厂运营更加智能和高效。<br/><br/>スマート工場の構築と生産自動化ソリューションは、現代の製造業者が競争力を高め、持続可能な成長を実現するための不可欠なステップです。 RTLS技術は、これらの未来志向の取り組みをサポートし、革新的な製造環境の構築に貢献しています。製造業者は、スマート工場の構築に向けた成功を続けるために積極的にこのような技術を採用すべきです。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: '通过安装UWB设备来支持实时位置跟踪的产品，可安装在工人或设备上。',
                img: productImg.product.assetTracking.thumbnail
            },
        ],
        solution: [
            {
                img: manufacturingProcess.thumb,
                data: 'manufacturingProcess',
                link: '/cn/enterprise/manufacturing/process'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/cn/enterprise/manufacturing/asset'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/cn/enterprise/logistics/asset'
            },
        ]
    }
}
