import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  StyledTextContainer, StyledIconContainer, StyledIcon
  , StyledImageContainer, StyledImage, StyledIconWrapper, StyledIconText,
  StyledIconTextSub, StyledCameraBannerImage, ImgScale
} from './Banner.style';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import access from '@src/lang/ko/main/access/access';
import { IconChevronRightBlack, accessBanner, cameraBanner, rtlsBanner } from '@assets/images/main/index';
import { StyledAppPage } from './BannerMain.style';
import { SliderButton, SliderLayout } from './Banner.style';
import rtls from '@src/lang/ko/main/rtls/rtls';
import camera from '@src/lang/ko/main/camera/camera';
import iot from '@src/lang/ko/main/iot/iot';
import sensor from '@src/lang/ko/main/sensor/sensor';
import { DisplayH1 } from '@components/Text/HeadingTagDisplay.style';
import { SliderButtonLayout } from './BannerScroll.style';
import { ImgWH, ParagraphColor, Spacing } from '@pages/CommonStyle/common.style';
import { DivJustify } from '@pages/CommonStyle/commonDiv.style';
import { StyledTitleBanner } from '@pages/Main/Access/Access.style';
import { useDispatch } from 'react-redux';
import { setOsType } from '@redux/action-creators/common';
import integration from '@src/lang/ko/main/integration/integration';
import { IntegrationBanner } from '@assets/images/main/integration';
import { LableImage } from '@pages/Main/Integration/components/Detail/Detail.style';


const Banner = ({ handleIconClick, product }): React.ReactElement => {

  const bannerExistProduct = ['access', 'rtls', 'camera'];
  let clickIndex;
  const isBannerExist = () => {
    if (bannerExistProduct.includes(product)) {
      clickIndex = 1;
    } else {
      clickIndex = 0;
    }
    return clickIndex;
  }

  let dataPath;
  let productPath;
  let bannerImg;
  if (product === 'access') {
    productPath = access;
    dataPath = product + 'Main';
    bannerImg = accessBanner;
  } else if (product === 'rtls') {
    productPath = rtls;
    dataPath = product;
    bannerImg = rtlsBanner;
  } else if (product === 'camera') {
    productPath = camera;
    dataPath = product;
    bannerImg = cameraBanner;
  } else if (product === 'iot') {
    productPath = iot;
    dataPath = product;
    bannerImg = '';
  } else if (product === 'sensor') {
    productPath = sensor;
    dataPath = product;
    bannerImg = '';
  } else if (product === 'beacon') {
    productPath = iot;
    dataPath = product;
    bannerImg = '';
  } else if (product === 'integration') {
    productPath = integration;
    dataPath = product;
    bannerImg = IntegrationBanner;
  }



  const location = useLocation();


  let currentLanguage;
  if (location.pathname.includes('/en/')) {
    currentLanguage = 'en';
  } else if (location.pathname.includes('/jp/')) {
    currentLanguage = 'jp';
  } else if (location.pathname.includes('/cn/')) {
    currentLanguage = 'cn';
  } else {
    currentLanguage = 'ko';
  }



  const imageIndex = productPath.bannerData.length;
  const [selectedGroup, setSelectedGroup] = useState(1);


  useEffect(() => {
    setSelectedGroup(1);
  }, []); // 컴포넌트가 처음 마운트될 때 1번 이미지로 설정


  const [currentImage, setCurrentImage] = useState(0);


  function isLastIconVisible() {
    const containerWidth = layoutMaxWidth - 80; // 컨테이너의 실제 너비 계산 (80px은 버튼의 너비)
    const visibleIcons = Math.floor(containerWidth / 96); // 아이콘 하나의 너비는 80px + 16px 마진

    const itemCount = visibleWidth > 96 * visibleIcons + 80 ? visibleIcons + 1 : visibleIcons;
    return imageIndex === itemCount + currentImage;
  }


  useEffect(() => {
    const handleResize = () => {
      setLayoutMaxWidth(window.innerWidth);
    }

    setLayoutMaxWidth(document.documentElement.clientWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [layoutMaxWidth, setLayoutMaxWidth] = useState<any>(null);


  const itemWidth = 80; // 아이템의 너비 계산
  const layoutWidth = itemWidth * imageIndex + 16 * (imageIndex - 1); // 레이아웃의 너비 계산
  //const transformX = isLastIconVisible() ? (Math.floor((layoutMaxWidth - 80) / 96) * itemWidth - layoutMaxWidth) : -currentImage * (itemWidth + 16);

  const isContainerFullWidth = layoutMaxWidth < layoutWidth + 40;

  ////현재 보여지는 넓이
  const visibleWidth = layoutMaxWidth - 80;

  const [scrollRight, setScrollRight] = useState(true);
  const [scrollLeft, setScrollLeft] = useState(false);

  const [moveLeft, setMoveLeft] = useState(0);
  const [moveRight, setMoveRight] = useState(96);

  const containerRef = useRef<HTMLDivElement>(null);
  const currentPage = useRef<HTMLDivElement>(null);
  // 스크롤 컨테이너의 scroll 이벤트를 처리합니다.
  const handleScroll = () => {
    const container = containerRef.current;
    const pageSize = currentPage.current;

    if (container && pageSize) {
      const scrollLeft = container.scrollLeft;
      const scrollRight = container.scrollWidth - (pageSize.scrollWidth - 92 + scrollLeft);
      setMoveLeft(scrollLeft < 80 ? scrollLeft : 96);
      setMoveRight(scrollRight < 80 ? scrollRight : 96);

      if (container.clientWidth >= container.scrollWidth - container.scrollLeft) {
        setScrollRight(false);
      } else {
        setScrollRight(true);
      }

      if (container.scrollLeft === 0) {
        setScrollLeft(false);
      } else {
        setScrollLeft(true);
      }
    }
  };

  const goLeft = () => {
    const container = containerRef.current;
    if (container) {
      const newScrollLeft = container.scrollLeft - moveLeft;
      smoothScroll(container, container.scrollLeft, newScrollLeft);
    }
  };

  const goRight = () => {
    const container = containerRef.current;
    if (container) {
      const newScrollLeft = container.scrollLeft + moveRight;
      smoothScroll(container, container.scrollLeft, newScrollLeft);
    }
  };

  const smoothScroll = (element, start, end, duration = 300) => {
    const range = end - start;
    let current = start;
    const increment = 20;
    const step = range / (duration / increment);
    const animateScroll = () => {
      current += step;
      if ((step > 0 && current >= end) || (step < 0 && current <= end)) {
        element.scrollTo({
          left: end,
          behavior: 'smooth',
        });
        return;
      }
      element.scrollTo({
        left: current,
        behavior: 'auto',
      });
      requestAnimationFrame(animateScroll);
    };
    animateScroll();
  };

  // 스크롤 컨테이너에 scroll 이벤트 핸들러를 등록합니다.
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);


  const dispatch = useDispatch();
  const handleOsType = () => {
    if (location.pathname.includes('beacon')) {
      localStorage.setItem('selectedOs', `beacon`);
    } else if (location.pathname.includes('rtls')) {
      localStorage.setItem('selectedOs', `rtls`);
    } else if (location.pathname.includes('camera')) {
      localStorage.setItem('selectedOs', `camera`);
    } else if (location.pathname.includes('access')) {
      localStorage.setItem('selectedOs', `access`);
    } else if (location.pathname.includes('sensor')) {
      localStorage.setItem('selectedOs', `sensor`);
    } else if (location.pathname.includes('integration')) {
      localStorage.setItem('selectedOs', `integration`);
    } else {
    }
  }



  return (

    <StyledTitleBanner ref={currentPage}>
      <StyledAppPage>
        <DivJustify>
          <StyledIconContainer ref={containerRef} w={isContainerFullWidth ? layoutMaxWidth - 80 : layoutWidth} mw={layoutMaxWidth}>
            <SliderLayout >
              {productPath.bannerData.map((data, index) => (
                <Link to={currentLanguage !== 'ko' ? `/${currentLanguage}${data.link}` : `${data.link}`}
                  key={index}>
                  <StyledIconWrapper
                    key={index}
                    onClick={() => { handleIconClick(index + 1); handleOsType(); }}
                    selected={selectedGroup === index + 1}
                  >
                    <StyledIcon
                      src={data.iconInv}
                      alt='Door Access'
                    />
                    <StyledIconText size='xsmall' color={index === 0 ? '#646464' : '#646464'}>
                      <Trans i18nKey={`${dataPath}.bannerData.${index}.iconText`} />
                    </StyledIconText>

                    {data.sub === "New" &&
                      <StyledIconTextSub size='xsmall'>
                        <Trans i18nKey={data.sub} />
                      </StyledIconTextSub>}
                  </StyledIconWrapper>
                </Link>
              ))}
            </SliderLayout>
          </StyledIconContainer>

          {isContainerFullWidth &&
            <SliderButtonLayout>

              {(currentImage !== 0 || scrollLeft) &&
                <SliderButton onClick={goLeft} direction={true} bgcolor={'#F8F8F8'}>
                  <ImgScale src={IconChevronRightBlack} w={'16'} h={'16'} trans={true} alt='button icon' />
                </SliderButton>
              }

              {(!isLastIconVisible() && scrollRight) &&
                <SliderButton onClick={goRight} direction={false} bgcolor={'#F8F8F8'}>
                  <ImgScale src={IconChevronRightBlack} w={'16'} h={'16'} trans={false} alt='button icon' />
                </SliderButton>
              }

            </SliderButtonLayout>
          }
        </DivJustify>

        <StyledTextContainer>

          {
            productPath.banner?.label &&
            <>
              <LableImage h={'31'} src={productPath.banner.label} />
            </>
          }
          <DisplayH1 size="medium">
            <Trans i18nKey={`${dataPath}.banner.title`} />
          </DisplayH1>

          <Spacing pixel='16' />
          <ParagraphColor color='#646464' size='medium'>
            <p>
              <Trans i18nKey={`${dataPath}.banner.desc`} />
            </p>
          </ParagraphColor>
        </StyledTextContainer>

        {bannerImg &&
          <StyledImageContainer>
            <StyledImage src={bannerImg} alt='RTLS System' />
          </StyledImageContainer>
        }
      </StyledAppPage>
    </StyledTitleBanner>
  );
};
export default Banner;
