import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";



export const BannerSection = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;

  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content : center;

  margin-top : 52px;
  
  width: 100%;
  max-height : 980px;

  aspect-ratio : 1920 / 980 ;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    max-height: 800px;
    aspect-ratio : 960 / 800 ;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    aspect-ratio : 393 / 480 ;
  }
`


export const ImageInner = styled.div<{scrollPosition:number}>`
  display : flex; 
  z-index : 3;

  padding : 0 24px;

  ${({ scrollPosition }) =>
  scrollPosition &&
  `transform: translateY(-${scrollPosition/4}px) scale(${100 - scrollPosition / 20}%); opacity : ${1-scrollPosition/300};`}


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-bottom : 0px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-bottom : 16px;
`;



export const BannerVideoSection = styled.div`
  display : flex;
  position : absolute;
  
  width: 100%;
  max-height : 980px;

  display : flex;

  aspect-ratio : 1920 / 980 ;
  

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    max-height: 800px;
    aspect-ratio : 960 / 800 ;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    aspect-ratio : 393 / 480 ;
  }

`;



export const VideoBox = styled.div`
  width : 100%;
  display : flex;
  opacity : 0.6;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
  }
`

