import { TechRequestBgDesktop, TechRequestBgMobile, TechRequestBgTablet } from "@assets/images/technology/common";
import { StyledGridContainer } from "@components/Grid/Grid.style";
import { CommonState } from "@redux/reducers/commonReducer";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { StyledTechRequest, StyledTechRequestBanner, StyledTechRequestBannerButton, StyledTechRequestBannerButtonText, StyledTechRequestBannerDescription, StyledTechRequestBannerTitle } from "./TechRequest.style";
import technology from "@src/lang/ko/technology/technology";

const requestImg = {
  desktop: TechRequestBgDesktop,
  tablet: TechRequestBgTablet,
  mobile: TechRequestBgMobile
}

const TechRequest = () => {
  
  const location = useLocation();
  
  // Redux
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  return (
    <StyledTechRequest>
      <StyledGridContainer>
        <StyledTechRequestBanner img={requestImg[layoutType]}>
          <StyledTechRequestBannerTitle size="small">
          <Trans i18nKey={ "technology.request.title" }/>
          </StyledTechRequestBannerTitle>

          <StyledTechRequestBannerDescription size="large">
            <Trans i18nKey={"technology.request.description"} />
          </StyledTechRequestBannerDescription>

          <Link to={location.pathname.includes('/en/') ? '/en/request' : '/request' }>
            <StyledTechRequestBannerButton>
              <StyledTechRequestBannerButtonText size="large">
              <Trans i18nKey={ "technology.request.button" }/>
              </StyledTechRequestBannerButtonText>
            </StyledTechRequestBannerButton>
          </Link>

        </StyledTechRequestBanner>
      </StyledGridContainer>
    </StyledTechRequest>  
  );
};

export default TechRequest;