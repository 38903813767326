import { StyledHeading } from "@components/Text";
import React from "react";
import { StyledFaqItemDetail, StyledFaqItemTitleIcon,
  StyledSpecTitleBox, StyledSpecDescBox } from "./Item.style";

import ArrowUpIcon from "@assets/images/services/common/arrow-chevron-up.svg";
import ArrowDownIcon from "@assets/images/services/common/arrow-chevron-down.svg";

import { Trans } from "react-i18next"
import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";
import { StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledFaqItem, StyledFaqItemDescription, StyledFaqItemTitle } from "../Faq/Item.style";

interface FaqItemProps {
  title: string,
  description: { title: string; description: string; }[];
  isOpen: boolean,
  path : string
  handleClick: () => void;
}

const SpecItem = (props: FaqItemProps):React.ReactElement => {
  const { title, description, isOpen, path, handleClick } = props;
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  
  
  return (
    <StyledFaqItem >
      <StyledFaqItemTitle onClick={handleClick}>
        <StyledHeading size={layoutType === 'mobile' ? 'xxxsmall' : 'xxxsmall'}><Trans i18nKey={title}/></StyledHeading>

        <StyledFaqItemTitleIcon>
          <img src={isOpen ? ArrowUpIcon : ArrowDownIcon} alt="faq-item-title-img" />
        </StyledFaqItemTitleIcon> 
      </StyledFaqItemTitle>

      <StyledFaqItemDetail isOpen={isOpen}>
        {description.map((item, index) => {
          if (item.title) {
            return (
              <StyledGridRow key={`faq-item-detail-${index}`}>

                <StyledGridColumn desktop={3} tablet={2} mobile={1}>
                  <StyledSpecTitleBox>
                    <StyledFaqItemDescription size="medium"><Trans i18nKey={`${path}.detail.${index}.title`}/></StyledFaqItemDescription>
                  </StyledSpecTitleBox>
                </StyledGridColumn>

                <StyledGridColumn desktop={9} tablet={6} mobile={3}>
                  <StyledSpecDescBox>
                    <StyledFaqItemDescription size="medium"><Trans i18nKey={`${path}.detail.${index}.description`}/></StyledFaqItemDescription>
                  </StyledSpecDescBox>
                </StyledGridColumn>

              </StyledGridRow>
            );
          } else {
            return null;
          }
        })}
      </StyledFaqItemDetail>

    </StyledFaqItem>
  )
}

export default SpecItem;