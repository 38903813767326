import { FlexStyle } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT, StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledLabel } from "@components/Text";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MenuProps } from "@layouts/Default/core/model";
import { CSSProperties } from "react";


interface StyledNavBoxProps {
  position?: CSSProperties['position'];
  isGNBFixed: boolean;
  bgColor : string;
}
export const StyledNavBox = styled(FlexStyle)<StyledNavBoxProps>`
  z-index: 6;
  width: 100%;
  height: 52px;
 
  ${({ position }) => position && `position: ${position};`}
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}
  
  
  justify-content: center;
  align-items: center;
  
  left: 0;

  top: ${({ isGNBFixed }) => (isGNBFixed ? '-100px' : '0')}; /* 기본적으로 숨겨져 있도록 위치 설정 */
  ${({ isGNBFixed, bgColor }) =>
    isGNBFixed &&
    css`
      position: fixed;
      background-color: ${bgColor};
      
      backdrop-filter: blur(50px);
      transform: translateY(100px);
      transition: transform .4s;
      border-bottom : 1px solid rgba(0,0,0,0.08);
  `}


  & * {
    font-family: Pretendard !important;
    font-display: swap;
  }
`;

export const NavLogoBox = styled.div`
  height : 100%;
  display : flex;
  justify-content : center;
  align-items : center;
`;

export const StyledHeaderContainer = styled(StyledGridContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: static;
  
`;

export const StyledHeaderLogo = styled.img`
  cursor: pointer;
  max-height: 52px;
  widht : 100%;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {

  }
`;

export const StyledMenus = styled(FlexStyle)`
  flex: 1;
  
  display : flex;
  
  margin-left : 8px;
  padding-left : 16px;
  
  @media (max-width: 768) {
    visibility: hidden;
  }

`;

export const StyledMenu = styled(FlexStyle)<MenuProps>`
  padding : 0 12px;
  
  font-family: 'Pretendard';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  border-radius: 8px;
  height : 40px;

  border-radius: 8px;
  align-items: center;
  user-select: none;
  position: relative;

  ${({ isLast }) => isLast ? `margin : 0px;` : `margin-right : 8px;`}
  ${({ click }) => click && 'background-color : rgba(0,0,0,0.03);'}

  & > img {
    margin-left: 4px;
    padding-bottom: 3px;
  }

  &:hover {
    background: rgba(0,0,0,0.03);
    cursor: pointer;
  }


  color: ${({ active, theme }) => active ? theme.CONTENT_TERTIARY : theme.CONTENT_PRIMARY};
  & > a {
    color: inherit;
  }
`;

// NavBox 클릭 범위 넓히기.


export const PricingBtn = styled.div`
  padding: 10px 15px;
  border-radius: 8px;
  color: ${({ theme }) => (theme.BACKGROUND_PRIMARY)};
  background-color: #2A70F0;
`;


export const StyledHeaderButton = styled.a`
  font-size: 14px;
  font-weight: 500;
  padding: 12px 16px;
  line-height: 1.14;
  border-radius: 8px;
  color: ${({ theme }) => (theme.BACKGROUND_PRIMARY)};
  background-color: #2A70F0;
`;

export const StyledNavTopBox = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${({ theme }) => (theme.BACKGROUND_PRIMARY)};
`;


export const StyledNavText = styled(StyledLabel)<{color : string}>`
  width: 100%;
  display : flex;
  align-items : center;
  padding: 12px;

  border-radius: 8px;
  height : 40px;

  ${({color})=> color&& `color : ${color};`}
  
  &:hover {
    background: rgba(255, 255, 255, 0.25);
    cursor: pointer;
  }

`;




export const StyledImage = styled.img`
  width: 16px;
  height : 16px;
  object-fit: contain;
  
`;




export const StyledGlobal = styled(FlexStyle)`
  width: auto;
  height: 52px;
  cursor : pointer;
  position : relative;
  
  align-items: center; 
  display : flex;
  flex-direction : row; 

`


export const StyledNavArrow = styled.div`
  width : 12px;
  height : 12px;
  display : flex;
  align-itmes : center;
  justify-content : center;

  margin-left : 4px;
  cursor: pointer;
`;


export const LabelColorNav = styled(StyledLabel)<{color:string}>`
    font-size : 13px;
    ${({color})=> color&& `color : ${color};`}
`;


export const StyledNavTextBlog = styled(StyledLabel)<{color : string, click : boolean}>`
  width: 100%;
  display : flex;
  align-items : center;
  padding: 12px;

  border-radius: 8px;
  height : 40px;
  font-size : 13px;

  ${({color})=> color&& `color : ${color};`}
  ${({ click }) => click && 'background-color : rgba(0,0,0,0.03);'}

  &:hover {
    background: rgba(255, 255, 255, 0.25);
    cursor: pointer;
  }

`;