import { IconBox, IconGroup, VideoBox, VideoHeaderText, VideoIconBox, VideoPage, VideoPageBottom, VideoPlay } from './Video.style';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { StyledGridContainer } from '@components/Grid/Grid.style';
import { Trans } from 'react-i18next';
import video from '@src/lang/ko/main/video/video';
import { HeadingColor, ImgWH, ParagraphColor, Spacing } from '@pages/CommonStyle/common.style';
import { DisplayH2 } from '@components/Text/HeadingTagDisplay.style';



interface ModalDefaultType {
  pageName: string
}



const Video: React.FC<PropsWithChildren<ModalDefaultType>> = ({ pageName }) => {

  let path;
  if (pageName === 'rtls') {
    path = video.rtls;
  } else if (pageName === 'camera') {
    path = video.camera;
  } else if (pageName === 'integration') {
    path = video.integration;
  }


  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleScroll = () => {
    if (videoRef.current) {
      const videoTop = videoRef.current.getBoundingClientRect().top;
      const videoBottom = videoRef.current.getBoundingClientRect().bottom;

      const windowHeight = window.innerHeight;
      const isCurrentVideoPlaying = videoBottom >= windowHeight / 2 && videoTop <= windowHeight / 2 && !isVideoPlaying;

      if (isCurrentVideoPlaying) {
        if (videoRef.current.readyState >= 4) {
          videoRef.current.play();
          setIsVideoPlaying(true);
        }
      } else {
        setIsVideoPlaying(false);
      }
    }
  };


  const [selectedVideo, setSelectedVideo] = useState(0); // 선택된 비디오의 인덱스를 저장


  const handleVideoClick = (index) => {
    setSelectedVideo(index);
    setIsVideoPlaying(false);
  };



  return (
    <VideoPage>
      <StyledGridContainer>
        <VideoHeaderText>
          <DisplayH2 size='small'>
            <Trans i18nKey={`${pageName}.video.title`} />
          </DisplayH2>
          {!path?.singleVideo &&
            <>
              <Spacing pixel='32' />
              <VideoIconBox>
                <>
                  {path.map((data, i) => (
                    <>
                      <IconGroup key={i} onClick={() => handleVideoClick(i)}>
                        <IconBox isSelected={selectedVideo === i}>
                          <ImgWH src={selectedVideo === i ? data.iconOn : data.iconOff} w='24' h='24' alt='RTLS function Icon' />
                        </IconBox>
                        <HeadingColor size='xxxxsmall' color={selectedVideo === i ? '#2A70F0' : '#646464'}>
                          <Trans i18nKey={`video.${pageName}.${i}.text`} />
                        </HeadingColor>
                      </IconGroup>
                    </>
                  ))}
                </>
              </VideoIconBox>
              <Spacing pixel='20' />
            </>
          }

          {path?.singleVideo && <Spacing pixel='16' />}
          <ParagraphColor size='medium' color='#646464'>
            <p>
              <Trans i18nKey={`${pageName}.video.desc.${selectedVideo}.text`} />
            </p>
          </ParagraphColor>
          <Spacing pixel='32' />
          {path?.singleVideo && <Spacing pixel='32' />}
        </VideoHeaderText>

        <VideoBox>
          <VideoPlay ref={videoRef} autoPlay loop muted playsInline key={selectedVideo}>
            <source src={path?.singleVideo ? path.video : path[selectedVideo].video} type="video/mp4" />
          </VideoPlay>
        </VideoBox>

        <VideoPageBottom />

      </StyledGridContainer>
    </VideoPage>

  )
};

export default Video;