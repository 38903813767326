import { DisplayH1 } from "@components/Text/HeadingTagDisplay.style";
import { HeadingColor, ImgWidth100, LabelColor, Spacing } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import { StyledOsBannerIn, StyledOsDesc, StyledOsImg, StyledOsTextBox } from "@pages/Main/Access/Access.style";
import React from "react";
import { Trans } from "react-i18next";
import { StyledTitleBanner } from "../../Os.style";
import os from "@src/lang/ko/main/os/os";


const Banner = ({layoutType}) => {

  return (
    <>

      <StyledTitleBanner>
        <StyledOsBannerIn>
          <Spacing pixel="0" />
          <StyledOsTextBox>
            <DivAllCenter>
              <ImgWidth100 src={os.osLogo[layoutType]} alt="ORBRO OS Logo"/>
              <DisplayH1 size="medium">
                <Trans i18nKey={`os.title`} />
              </DisplayH1>
            </DivAllCenter>
          </StyledOsTextBox>
          <StyledOsImg src={os.bannerImg} alt="RTLS System" />

        </StyledOsBannerIn>

      </StyledTitleBanner>
    </>
  )
}



export default Banner;