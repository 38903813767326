import {
  medicalPatient,
  officeAsset,
  retailExhibition
} from "@pages/Enterprise/Solution/components/Banner/BannerImages";

import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {
  helmet: '| 什么是 NFC？',
  desc: 'NFC（近场通信）是一种使近距离无线通信成为可能的技术。该技术利用小型无线波，使数据在非常短的距离内进行交换。',
  keywords: 'nfc、uwb、无线通信、位置跟踪、对象位置跟踪、人员位置跟踪、标签、信标、rtls、orbro、contech',

  ogTitle: '',
  ogDesc: '',
  ogSite: '什么是 NFC？ | 位置追踪和数字孪生专业平台 Orbro',
  ogUrl: 'https://orbro.io/cn/blog/nfc-uwb-ble',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDlx0aose_UUwB8yAMLpMzvTdJK7dGM_ga67nWJqGp-Ad-0qXiKbUEUjAYHHCIw3c5fBJeOurZ9GbBlOLASQQ-fxRGXYfqb0RRl8SAXz05tRfZVuRggCXCN4Lhgi5bmQUDXYI9RvZTvlt-aRguSSi_Stihe1t7AUtepg-f_OTkuBEX5XDNmq1mSEBJVgqC26dYfGuJh3zgT12nYtPK60Bz8xB5ydx2cmvaAbP-No0hvZIWMciZv6XarrpKcR-Eu6YSdcsFOZ3J9wjVTeyyc6S-gVqoxW4abVCEfQaItklbow0uqPjlPT_KHGCGQa0SKOW97pUKx0PO8uSBpJMD6AzeJ4Yh-01yRQqpC6vcBsmNvEBxMvLvTm8BX5mRmBV48v6kHK_P7WycSemW5wnW58cEHX5Wjo2tV2tTJHX9y_qWwOZZWX7eFx73V0WmX-vG4ABxNwFr7u-p3TKA6nWMxJzwGyLw_9ouTexsqnfiBXf8MhruRLbrNobB19ErL_DHgZi4kPuI8PDDdT4kvxwE9lt4LpJuArzzdpvwhT4XnDrFl73C616KHcorzOcrOJxw-jzKhFvc-q17_3S--zh2PcMpG5cvL7DJ1Cgy62atJyXB5IB67bDeOUAnB_Zr3RdAUcVBg3_yADYB7htLduaNVCYlC8F9ucLtTOKYqDU_Kc0G53AHrpmiqbbWxLOyGamXVkCO9EzJaWC8EVDcclcnwMUWf_5vC_tIg8wnNp4nSycN0vVAfm-iukMGeHV3onRhtyGa--Q_31wtjzULC5n1ffWD2DgX0LCxksQOCkOmzWPFRhGC-UpLo1hUE6ar64oJbH06kISvVcqIqPYMLHIqWcMchD9FMPlBI2P0XtEJaZtNQ8OQ7XdAJzRXi6TK_jo16h0jAW9Bnh5sv9BA0eJoEQz4d0jD1wMwPB9StiMAHG_6pTNleKUOO92VU0weNP0U6p8gqceFhBSgierz4ukU8ud1rti_cnmtvPUHKNnPGoKMb-np6KexGgI3D9XWzzAsaUrAwziO87m2zHYt2SezQ3urRH9gG1yhokna4WTjfrxED9WfE2yI9uC3xMuALyZLompGIZk-mz9k1N8O4kZRHSLtIt_TQSUDILXDQsIwVMeMs1q_U9i6L10APLVQAjMZQL5dxa557zkCPB59Zytf72ASMHtp9TL6LGnpV5bPGGl3GSNPkH8sjR7UkuMmEFrUbbohLMbhHtxGneSDUtRJO7bdQsysoOK_-ZxsXqnkQVa1KX-J153QU3yI4IlaViIpjxY9w5kptwJPL_WFlXcjUnR-JnSu5c3FPe8y8gae7yt91cT6KECSmJelkYxaEan1q9_hAgXCvb24BKiRGWrNMAacUoPz8hljTI0cf8fB9GuDiPoqYgxJKcZFdicKosSD3SZMXnLbF4hZE96gFYFWuOIetfv7pzlsg08ZZ0EiklpgY1dshXnDmrLrKcnB4v_mWgvqKA1tyQnCZrAYyX-Z7zW_cj00TQX28EqAsV3cAkEVZTs_ClA1g-xHvUBIm-0logZKUhbQUT9TBz5eip_Aw_rexmWpOV4XcV=w1984-h1214',
  ogKey: '',
  ogType: '网站',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '什么是 NFC？',
  date: '2024-01-11',
  tag1: '#nfc',
  tag2: '#位置追踪',
  tag3: '#无线通信',
  tag4: '#资产管理',

  // <br/>
  text: [
    {
      head: '什么是 NFC？',
      subHead: '',
      desc: `NFC（近场通信）是一种近距离无线通信技术，允许在非常短的距离内进行数据交换。该技术使用小型无线波，以便在非常短的距离内进行数据交换。主要用于智能手机和其他电子设备之间的简单信息传递、移动支付、入退室卡系统等，操作距离通常在10厘米以内。由于 NFC 高速且易于使用，主要用于支付和简单的数据传输。`,
      dot: ''
    },
    {
      head: 'NFC 的工作原理',
      subHead: '',
      desc: 'NFC（近场通信技术）的工作原理非常有效且简单。首先，NFC 在设备之间生成小型电磁场。这个电场充当数据交换的通道，类似于雷达的概念。这个小电磁场只在设备之间非常近的距离内有效，通常在10厘米以内。<br/>NFC 的工作原理基于射频识别（RFID）技术。因此，通过两个设备（读取器或初始化设备和标签或卡片）之间的无线方式交换数据。在这个过程中，通过电磁场，两个设备相互识别并开始通信。<br/>其主要特点之一是 NFC 的工作距离相对较短。这提供了安全性的优势，防止用户进行意外的数据交换。此外，这种工作原理使得高速数据传输和实时数据交换成为可能。',
      dot: ''
    },
    {
      head: 'NFC 的主要应用',
      subHead: 'NFC 移动支付',
      desc: 'NFC 主要用于通过智能手机进行简单的支付。用户可以将 NFC 兼容的智能手机靠近 NFC 支付终端，通过发送预先注册的支付信息，快速而便捷地完成支付。代表性的例子包括 Apple Pay、Google Pay、Samsung Pay 等。',
      dot: ''
    },
    {
      head: '',
      subHead: '数据传输',
      desc: '使用 NFC，两个设备之间可以进行简单的数据传输。例如，在智能手机之间轻松交换联系人信息、照片、名片等。此外，可以利用 NFC 标签自动化信息传输。',
      dot: ''
    },
    {
      head: '',
      subHead: '扩展智能手机功能',
      desc: 'NFC 用于扩展智能手机功能。例如，通过与家庭自动化系统集成，将智能手机贴标记到特定设备，可以控制照明、加热、安全系统等。',
      dot: ''
    },
    {
      head: '',
      subHead: '电子访问卡',
      desc: 'NFC 在电子访问卡系统中广泛使用。通过使用智能手机或 NFC 标签访问建筑物或设施，提供更高的安全性和便利性。',
      dot: ''
    },
    {
      head: '',
      subHead: '医疗领域',
      desc: 'NFC 在各种应用领域中被有效地应用，如患者身份识别和访问控制、医疗记录和药物管理、紧急响应以及诊所自动化。NFC 有效地自动化医疗系统，提高患者安全性，并在紧急情况下提供快速而准确的医疗服务。',
      dot: ''
    },
    {
      head: '',
      subHead: '智能 POS 技术',
      desc: 'NFC 与商店和咖啡馆等地的智能 POS（销售点）系统配合使用，用于支付和传输折扣信息。顾客只需将智能手机靠近支付终端，即可轻松进行支付。',
      dot: ''
    },
    {
      head: 'NFC 与蓝牙的区别',
      subHead: '通信距离',
      desc: '',
      dot: {
        a: '<strong> • NFC：</strong>只能在非常近的距离内通信，通常在10厘米以内。主要用于接触或非常短距离的数据传输。',
        b: '<strong> • 蓝牙：</strong>作用范围更广，通常最大可达100米。主要用于远距离数据传输和设备连接。',
      }
    },
    {
      head: '',
      subHead: '传输速度',
      desc: '',
      dot: {
        a: '<strong> • NFC：</strong>通常传输速度较慢，比蓝牙慢。主要用于小量数据传输。',
        b: '<strong> • 蓝牙：</strong>提供高传输速度，有效地快速传输大容量数据。',
      }
    },
    {
      head: '',
      subHead: '用途',
      desc: '',
      dot: {
        a: '<strong> • NFC：</strong>主要用于简单的数据交换和支付系统、读取标签（读取存储在标签上的信息）等，主要用于短距离应用。',
        b: '<strong> • 蓝牙：</strong>主要用于需要远距离和大容量数据传输的应用领域，如耳机、扬声器、键盘等。',
      }
    },
    {
      head: '',
      subHead: '电源消耗',
      desc: '',
      dot: {
        a: '<strong> • NFC：</strong>电源消耗较低。主要用于不需要充电的小型设备。',
        b: '<strong> • 蓝牙：</strong>相对较高的电源消耗，可能会影响某些设备的电池寿命。',
      }
    },
    {
      head: '',
      subHead: '安全性',
      desc: '',
      dot: {
        a: '<strong> • NFC：</strong>只能在非常短的距离内通信，因此通常在相对安全的环境中使用。',
        b: '<strong> • 蓝牙：</strong>由于能够进行长距离通信，因此安全性更为重要，需要更强大的安全技术。',
      }
    },
    {
      head: 'NFC 的未来展望',
      subHead: '',
      desc: "NFC 技术正在不断发展，其应用领域涵盖移动支付、智能家居、医疗行业等各个领域。正在进行的技术创新旨在提供更高的安全性和传输速度，预计 NFC 将在各个行业中发挥重要作用。<br/>作为无线通信技术之一，NFC 预计将在未来的数字环境中引领创新。随着其发展，预计将为数字景观带来更多创新。",
      dot: ''
    },
  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '将 UWB 终端安装在工人或设备上，支持实时位置跟踪的产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/cn/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/cn/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/cn/enterprise/office/asset'
      }
    ]

  }

};
