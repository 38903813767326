import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset, manufacturingProcess } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {


    helmet: '| 스마트 공장이 제조 산업을 진화시키는 방법',
    desc: '자산 관리, 생산 라인 최적화, 자동화, 데이터 분석, 환경 친화적 생산, 재고 관리, 주문 처리 등 다양한 기능을 수행할 수 있습니다. 이로써 경쟁력을 확보하고 제조 과정을 최적화할 수 있습니다.',
    keywords: '스마트 공장, RTLS 기술, 스마트 제조, 제조업 혁신, 생산 효율성, 자산 관리, 생산 라인 최적화, 안전 및 보안, 자동화, 데이터 분석, 자원 할당, 예방 정비, 유지보수, 생산 데이터 모니터링, 고객 서비스, 품질 향상, 환경 친화적 생산, 재고 관리, 시각화, 주문 처리, 품질 검사, 품질 관리, 생산 투명성, orbro, 오브로, 콩테크',

    ogTitle: '',
    ogDesc: '',
    ogSite: '스마트 공장이 제조 산업을 진화시키는 방법 :: 오브로 블로그',
    ogUrl: 'https://orbro.io/blog/smartFactory',
    ogKey: '',
    ogImg: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '스마트 공장이 제조 산업을 진화시키는 방법',
    date: '2023-10-12',
    tag1: '#스마트공장',
    tag2: '#스마트제조',
    tag3: '#생산라인',
    tag4: '#재고관리',

    // <br/>
    text: [
        {
            head: '',
            subHead: '',
            desc: '제조 산업은 기술 혁신의 영향을 크게 받아왔으며, 최근 몇년 동안 스마트 공장이라고 불리는 혁신적인 개념은 제조업을 혁신하고 생산 효율성을 향상시키는데 큰 역할을 하고 있습니다. 이러한 스마트 공장의 핵심 요소 중 하나가 Real-Time Location System(RTLS) 기술입니다. RTLS 기술은 제조 시설 내에서 자산 및 작업자의 위치와 움직임을 실시간으로 추적하는 기술로, 제조업체가 생산 프로세스를 최적화하고 효율성을 극대화할 수 있도록 돕는 역할을 합니다. 이 글에서는 RTLS 기술이 스마트 공장에서 어떻게 적용되고, 그 효율성은 어떻게 제조 산업을 진화시키는지에 대해 논의하겠습니다.',
            dot: ''
        },
        {
            head: 'RTLS란?',
            subHead: '',
            desc: `RTLS는 'Real-Time Location System'의 약자로, 실시간 위치 추적 시스템을 의미합니다. 이 기술은 작업자나 장비와 같은 이동성이 있는 요소의 위치를 정확하게 파악하고 모니터링할 수 있게 해줍니다. RTLS 시스템은 GPS, RFID(라디오 주파수 식별), UWB(초광대역), 블루투스, 초음파 및 다른 센서를 사용하여 작업자의 위치를 추적합니다.`,
            dot: ''
        },
        {
            head: '자원 할당 최적화',
            subHead: '',
            desc: 'RTLS를 통해 원자재, 부품, 작업자, 및 기계의 위치를 실시간으로 파악할 수 있으므로, 자원 할당을 최적화할 수 있습니다. 이를 통해 생산 라인과 장비의 효율성을 향상시키며 자원 낭비를 줄일 수 있습니다.',
            dot: ''
        },
        {
            head: '예방 정비 및 유지보수',
            subHead: '',
            desc: 'RTLS는 기계 및 장비의 상태를 모니터링하고 예방 정비 및 유지보수 일정을 최적화하는 데 사용됩니다. 기계의 상태 변화나 고장을 조기 감지하여 생산 중단을 예방하고 비용을 절감할 수 있습니다.',
            dot: ''
        },
        {
            head: '생산 데이터의 실시간 모니터링',
            subHead: '',
            desc: 'RTLS는 제조 시설 내에서 발생하는 데이터를 실시간으로 수집하고 모니터링할 수 있습니다. 이를 통해 생산 과정의 실시간 상태를 파악하고, 생산 중 문제가 발생할 경우 빠르게 대응할 수 있습니다.',
            dot: ''
        },
        {
            head: '고객 서비스 및 품질 향상',
            subHead: '',
            desc: 'RTLS는 생산 과정을 추적하고 제품의 이력을 기록하는 데 사용될 수 있습니다. 이를 통해 제품의 품질을 관리하고, 고객 서비스를 개선할 수 있습니다.',
            dot: ''
        },
        {
            head: '환경 친화적 생산',
            subHead: '',
            desc: 'RTLS 기술을 활용하여 에너지 및 자원 사용을 최적화하고 환경 친화적 생산을 실현할 수 있습니다. 또한, 환경 규제를 준수하고 친환경 제조를 강화할 수 있습니다.',
            dot: ''
        },
        {
            head: '자동화된 재고 관리',
            subHead: '',
            desc: 'RTLS는 재고 관리를 자동화하고 재고 수준을 최적화하는 데 사용됩니다. 이를 통해 재고 관리 비용을 줄이고, 공간을 효율적으로 활용할 수 있습니다.',
            dot: ''
        },
        {
            head: '생산 현장의 실시간 시각화',
            subHead: '',
            desc: 'RTLS는 제조 공정을 실시간으로 시각화하는 데 활용됩니다. 이를 통해 생산 현장의 상황을 명확하게 파악하고 생산 과정을 모니터링할 수 있습니다.',
            dot: ''
        },
        {
            head: '주문 처리 및 순환 시간 단축',
            subHead: '',
            desc: 'RTLS를 통해 주문 처리 및 생산 순환 시간을 단축할 수 있으며, 고객 요구를 신속히 충족할 수 있습니다.',
            dot: ''
        },
        {
            head: '품질 검사 및 품질 관리',
            subHead: '',
            desc: 'RTLS를 활용하여 제품 품질 검사 및 품질 관리를 강화할 수 있습니다. 불량품을 식별하고 빠르게 조치를 취하여 품질 문제를 해결할 수 있습니다.',
            dot: ''
        },
        {
            head: '생산 과정의 투명성',
            subHead: '',
            desc: 'RTLS는 제조 과정의 투명성을 높이고 오류를 실시간으로 추적하는 데 사용됩니다. 이를 통해 생산 과정의 개선 사항을 파악하고 효율성을 높일 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '',
            desc: 'RTLS 기술을 제조 시설에 성공적으로 적용하려면, 적절한 하드웨어 및 소프트웨어 인프라가 필요합니다. 또한, 데이터 보안과 개인 정보 보호에 대한 고려도 중요합니다. 그러나 이러한 도전에 직면하더라도, RTLS 기술의 잠재력은 현대 제조업체에게 큰 이점을 제공합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '',
            desc: '스마트 공장과 RTLS 기술의 결합은 제조 산업을 진화시키는 중요한 방법 중 하나입니다. 이를 통해 제조업체는 효율성을 높이고 생산 과정을 최적화하며, 경쟁력을 유지하고 향상시킬 수 있습니다. 따라서 RTLS 기술은 제조 산업의 미래를 밝게 그리는 데 중요한 역할을 합니다.',
            dot: ''
        },

    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: manufacturingProcess.thumb,
                data: 'manufacturingProcess',
                link: '/enterprise/manufacturing/process'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/enterprise/manufacturing/asset'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/enterprise/logistics/asset'
            }
        ]

    }


}