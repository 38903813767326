import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const StyledAdvantageImgBox = styled.div`

  max-width: 100%;
  margin-top: 24px;

  display: flex;
  flex-direction : row;
  gap : 32px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    flex-direction : column;
    gap : 24px;
    height : auto;
  }
`;



export const StyledAdvantageImgLeft = styled.div`
  height : auto;
  max-width: 50%;
  
  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    max-width: 100%;
  }
`;


export const StyledAdvantageImg = styled.img`

  max-height : 100%;
  max-width: 100%;
  border-radius : 16px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
  }
 
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
  }

`;