import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconChartRadiusGray, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconTagGray, IconTvocGray, IconUserCheck, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { IconTemp } from "@assets/images/main";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/manufacturingProcess/video/sub/rtlsVideo4.mp4";

export default {

    banner: {
        title: '实时生产过程管理',
        desc: '可以一目了然地了解制造设施的运作流程，并通过准确而客观的数据来改进生产线，提高效率。这是制造业的核心。',

    },

    video: {
        title: '追踪制造过程的一切。',
        desc: '实时定位(RTLS)技术可以实时了解制造过程。<br/>通过实时了解生产线的当前状态，可以实现<strong>最高效率</strong>。',
        play: video
    },

    func: [
        {
            title: '生产线监控',
            desc: '能够迅速发现制造过程中出现的异常现象，并采取措施，从而最大程度地减少制造过程中出现的故障，提高生产效率。',
            sub1: '实时状态了解',
            icon1: IconClockGray,
            sub2: '统计数据提供',
            icon2: IconChartBarGray,
            video: subVideo1

        },
        {
            title: '瓶颈现象缓解',
            desc: '通过实时追踪原材料、半成品、成品等的移动，可以识别出哪个阶段出现了瓶颈现象。同时可以预测并采取快速措施。',
            sub1: '实时定位',
            icon1: IconBinocularsGray,
            sub2: '高精度提供',
            icon2: IconRulerGray,
            video: subVideo2
        },
        {
            title: '安全管理',
            desc: '可以实时检测在生产线上发生的事故。这样，在事故发生初期就可以采取迅速的应对措施，防止更大的事故发生。',
            sub1: '风险资产指定',
            icon1: IconWarningGray,
            sub2: '应对方式设置',
            icon2: IconChartRadiusGray,
            video: subVideo3
        },
        {
            title: '生产过程分析',
            desc: '通过收集数据，可以改进更加高效的生产过程。能够轻松识别问题，并促进业务增长。',
            sub1: '数据云存储',
            icon1: IconClockGray,
            sub2: '统计数据提供',
            icon2: IconChartBarGray,
            video: subVideo4
        },


    ],



    subfunc: {

        title: '多种功能',
        desc: '生产线的生产效率是制造业的核心。Orbro提供的功能可以进一步提高生产线的效率和生产率。',

        data: [
            {
                icon: IconChartRadius,
                title: '报告与分析',
                desc: '可以基于现场数据生成报告和分析资料。',
            },
            {
                icon: IconBell,
                title: '实时警报功能',
                desc: '当生产线出现异常情况时发送警报，可以及时应对。',
            },
            {
                icon: IconRoute,
                title: '路径历史查询',
                desc: '跟踪内部移动物体的路径和移动时间。',
            },
            {
                icon: IconDashboard,
                title: '状态了解',
                desc: '可以实时了解当前生产过程的情况。',
            },
            {
                icon: IconTemp,
                title: '温度、湿度等环境分析',
                desc: '监控物流仓库内部的环境因素，可以实时检测异常。',
            },
            {
                icon: IconUserCheck,
                title: '工人指导',
                desc: '基于当前状态指导工人进行下一步操作。',
            },
            {
                icon: IconChainLink,
                title: '数据联动',
                desc: '与RFID、物联网、安全、视频等系统连接，实现高效利用。',
            },
            {
                icon: IconSecurity,
                title: '数据安全',
                desc: '保持高数据安全水平，提供可信赖的解决方案。',
            },


        ],
    },

    system: {
        title: '系统构成',
        img: rtlsSystem
    },

    productTitle: '产品配置',
    product: [

        {
            tag: 'assetPackage'
        },
        {
            tag: 'workerPackage'
        },
    ],


    contents: [
        {
            data: 'manufacturingAsset'
        },
        {
            data: 'logisticsAsset'
        },
        {
            data: 'blogOffice'
        },
        {
            data: 'blogRTLS'
        },

    ]

}
