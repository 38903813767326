import React, { useEffect, useState } from 'react';
import { MenuBox, MenuContainer, MenuHoverLabel,
   MenuLayout, MenuTreeLayout } from './Tree2.style';
import { StyledHeading } from '@components/Text';
import { useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import navMenu from '@src/lang/ko/navMenu';
import navMenuEN from '@src/lang/en/navMenu';
import navMenuCN from '@src/lang/cn/navMenu';
import navMenuJP from '@src/lang/jp/navMenu';
import { FitAtag } from './Tree3.style';


const Tree3 = ({mouseLeave}) => {

  const [path, setPath] = useState<any>(navMenu);
  const location = useLocation();

  useEffect(()=> {
    if(location.pathname.includes('en/')){
      setPath(navMenuEN);
    } else if(location.pathname.includes('cn/')){
      setPath(navMenuCN);
    } else if(location.pathname.includes('jp/')){
      setPath(navMenuJP);
    } else {
      setPath(navMenu);
    }
    

  },[])


  return (
    <MenuTreeLayout onMouseLeave={() => {mouseLeave();}}>
      <MenuContainer>
        <MenuLayout>
          {path.support.map((data, index) => (
            <MenuBox key={index}>
              <StyledHeading size='xxxsmall'> <Trans i18nKey={`navMenu.support.${index}.title`}/> </StyledHeading>
              
              {data.path.map((product, i) => (
                <FitAtag href={`${product.link}`} key={i} mt={'16'} >
                  <MenuHoverLabel size='medium'> <Trans i18nKey={`navMenu.support.${index}.path.${i}.text`}/> </MenuHoverLabel>
                </FitAtag>
              ))}
            </MenuBox>
          ))}
        </MenuLayout>
      </MenuContainer>
    </MenuTreeLayout>
  );
};

export default Tree3;
