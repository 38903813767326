import { FlexStyle, Vertical } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledLabel } from "@components/Text";
import styled from "@emotion/styled";

export const StyledBlog = styled.div`
  width: 100%;
  padding: 128px 0;

  & * {
    font-family: Pretendard !important;
    font-display: swap;
  } 

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding: 96px 0;
    
  }


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 64px 0;
    
  }
`;


export const FontWeightNormal = styled.span`
  font-weight : normal;
`


export const StyledBlogRow = styled(StyledGridRow)`
  margin-top: 64px;
  margin-bottom : 64px;
  row-gap : 32px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-top: 48px;
    
  }

`;

export const StyledBlogCard = styled(StyledGridColumn)`
  height: 380px;
  border-radius: 16px;

  display : flex;
  flex-direction : column;
  
  &:hover {
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  }


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height: auto;
    
  }
`;


export const StyledBlogImg = styled.img`
  height : 220px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-size: cover;
  background-position: center;

  object-fit : cover;
  width : 100%;

  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height: auto;
    object-fit : contain;
    
  }

`

export const StyledBlogTextBox = styled(Vertical)`
  height: 160px;
  padding: 0 16px;
  position: relative;
  padding-top : 0px;

`;

export const StyledBlogMoreBtn = styled.button`

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  padding: 16px 32px;
  gap: 4px;
  
  border-radius: 12px;
  background: #000000;
  border : none;
  
  margin : 0 auto;
  cursor : pointer;
`;
