import { carTracking1, peopleTracking1 } from "@assets/images/main/camera";
import { assetTracking1, workerTracking1 } from "@assets/images/main/rtls/product";

export default {

    assetPackage: {
        label: 'Assets Tracking Package',
        title: 'Asset Location Tracking Package',
        product: 'TwinTracker 10 + Server 1',
        desc: 'A product that helps track the real-time location by attaching BLE devices to workers or equipment.',
        img: assetTracking1,
        link: '/en/rtls',
        index: '1'
    },
    
    workerPackage: {
        label: 'Workers Tracking Package',
        title: 'Worker Location Tracking Package',
        product: 'TwinTrackerPro 10 + Server 1',
        desc: 'A product that helps track the real-time location by attaching UWB devices to workers or equipment.',
        img: workerTracking1,
        link: '/en/rtls',
        index: '2'
    },
    
    
    carPackage: {
        label: 'Vehicle Location Tracking Package',
        title: 'Vehicle Location Tracking Package',
        product: 'Server1 + NVR1 + PTZ Camera2',
        desc: 'Detect and manage the location and abnormal behavior of various objects such as people and vehicles through cameras.',
        img: carTracking1,
        link: '/en/camera',
        index: '1'
    },
    
    peoplePackage : {
        label : 'People Tracking and Analysis Package',
        title: 'Flowing Population Analysis Package',
        product : 'Server1 + NVR1 + IP Camera4',
        desc: 'The camera can analyze the movement path of indoor people and detect unexpected situations.',
        img : peopleTracking1,
        link : '/camera',
        index : '2'
    },

}