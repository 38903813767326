import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { logisticsAsset, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/officeAsset/video/rtlsVideo.mp4";

export default {
    helmet: '',
    desc: 'Smart Office brings innovative changes in asset management, attendance management, inventory management, and employee management. It allows you to operate and manage your office in a different way, increasing productivity.',
    keywords: 'Smart Office, asset management, inventory management, office solution, location tracking, attendance management, office supplies management, office supplies, contech, orbro, rtls, orbro',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'Smart Office Construction Solution :: Orbro Blog',
    ogUrl: 'https://orbro.io/en/blog/officeSolution',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDz0dYjvT_dovrQIm8StET0JPtXyRSg67istWgj_G4Tk7ygKMfT0sSkYCFctUToVxdJt0GLQovf6SlDVOv8wRusG-hAYmfYmKYwY2UC2zapN2wPY8xmOdsmqfCmtKJE_ZgS5d3zp8jhdEh3kzrTI6wCtwXZsh2l8FoST49zwMy3hMKotCDruVcBK8e-90_kWC-DRfGXSqBUlqYqEvpb7suLGc-sG9uVyVzgpahbCluCQLjjYQp0FfaIMqi3qWmmpzn4UeKKFlFEKxo5npOoMkUfIPOIzQwrqMhKMvCPw19QwjLii04Vk779E1xu1B3yvdQA0SsTGt8xskOW8TEzvlXITVtsKdpUOAbHj6rKF-3gchc57T4eE_-3ycDx8gttnPLOy-milalJmjbIZrntwCeb8Qpocf-Ug-YSBcy46fdXlAD73CrLAkMZD_EibWYj6rcA1MkP7YlYgUDMFZsUHXCRAJn-FXeC1tFSMvFp9XkMXa0hQjhG7WFKQ9OY__bS3VMM3m653EIFAooJVI2JW5Lehmr8i7AoYvVwyVya-ECTAkyCOHE36ApWm2GXcuMaUEKVpFWCa6Uk5VT3y44pXzUjv7UyfKoAILkaIxCBJ90rb-hQj0SOmBbu6J5gp--cXveh_j_I1BpDL4yXkaY5roagR4KZV4HnPqPzoAuXRMkg9D1c2IIuxsH3XW2UbComnYB3ZP36_elzk3qsnT125p6OPuquaowy30SFBy65N7gknz-dVooAi8Bz_bREsVMZUQ1wevfQYy8CoL2buJo3oL-tQbJTKrbPBiZQYm3H1SZ7S9JXjEpyEJgoPnmvNE1Qk8ErzBGgEACyWUFED9vQmLonSf0sPZK2ipPzAfbSStrz0RdsuLXHASIrTRRXO2miidoWuq4JqeuHAzVXqVj8iiG6VnQSMN8hW29RelzkAFwfOg9QGOSQgroVEuHNbCZebcAa-WfMSPdOvklsFWIQIc7tEZ0Qc4oKkpyced7b9OF6nQAwaZH0Risyrl_jcCKd0dYWRazieZBi2P6EV3VqCdtI8xhpYvCer4uocgKohjK07UgUVaEC2ug-oLsCYkNC1MeYrEvVkM6lJCnqihOdgUQ7-28FDGc1mmL-l0En06_GFIkCWjuUL84NZSglIuAjAgx-XkQSRl6j9XiV_O-vcqE8qmjky4mXV-Wn9Mj4AUexdJBDWX9R1CgPvFsXQlYaAKg7sPSYXzv9cfTPCRBBZZ7HExJ0g18NzVBpn3JlbO76m7WOsMm20NMcBW_rFgPVVbcx_RkjFJZGYalie3GY6mewgx_VeKktH5GAlyxmgXqT2JeF_1GcqN6AFrQoTW_QgkDDyUkqJW-_olrQiVqerertlIZ8GOosa2chps5Yw1iCaH01YmTgG8u9YCrqbwtWdL2wE1StruepZYCM4DRqCS22OLJySm6TTeql6iQAxR3n6gNFmWutp83Z87o_7FuetzaZmM6Z31Pk7JOVigfT3FgDClioeRwOy_k3WkXA_FWF4xSORqO21iqrYzkjTzZKHUAiEqUt7VNXIcfn_bWJE2wDSqElWPnqG6MDtJwqdm6CK-l1uGp3c=w2110-h1166',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'Smart Office Construction Solution',
    date: '2023-10-31',
    tag1: '#SmartOffice',
    tag2: '#InventoryManagement',
    tag3: '#OfficeSupplies',
    tag4: '#AttendanceManagement',

    text: [
        {
            video: video,
            link : '/en/enterprise/office/asset',
            head: '',
            subHead: '',
            desc: 'Integrating RTLS (Real-Time Location System) technology into Smart Office construction solution can make the work environment smarter. RTLS is a technology that tracks the real-time location of assets and workers, offering various innovative opportunities in the Smart Office environment.',
            dot: ''
        },
        {
            head: '1. RTLS Technology and Smart Office',
            subHead: '',
            desc: 'RTLS technology, short for Real-Time Location System, uses wireless communication and sensor technology to collect and analyze real-time location data. When you apply this technology to the Smart Office environment, you can gain the following advantages:',
            dot: ''
        },
        {
            head: '2. Advantages',
            subHead: 'Asset Management',
            desc: 'RTLS allows precise tracking of all assets within the Smart Office. For example, the meeting room reservation system utilizes RTLS data to check equipment within the meeting room in real-time and enables reservations. This prevents duplicate reservations and maximizes the utilization of meeting room resources. Additionally, by analyzing asset movement and location history data, you can optimize asset lifecycle and plan preventive maintenance.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Worker Productivity',
            desc: 'Workers needing to locate specific assets can use the RTLS application to check the current location of the asset and move to it via the shortest route. It also aids in optimizing worker schedules. Analyzing RTLS data allows you to optimize worker work patterns and enhance productivity. Importantly, when workers can perform tasks efficiently from anywhere, worker satisfaction increases.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Enhanced Security',
            desc: 'Administrators can detect unauthorized access to restricted areas and take immediate action using alert systems. Monitoring worker location data can detect work in hazardous areas and send warnings to workers or take immediate action. Additionally, monitoring asset movement and location changes helps prevent theft or damage, significantly enhancing security.',
            dot: ''
        },
        {
            head: '3. Smart Office Solution Utilizing RTLS',
            subHead: 'Asset Management System',
            desc: `This system tracks and manages all assets within the office in real-time. Each asset is equipped with an RTLS tag, which tracks the asset's location, movement path, and usage history. Administrators can view the real-time location of all assets through a web-based dashboard, quickly finding the necessary assets. It also helps optimize asset utilization based on location information, preventing unnecessary equipment purchases.`,
            dot: ''
        },
        {
            head: '',
            subHead: 'Worker Status Dashboard',
            desc: 'Introducing a dashboard that monitors worker location in real-time in the Smart Office environment optimizes work schedules and collaboration. For example, it allows real-time tracking of who is using which meeting room, which can be integrated with the meeting room reservation system. Sharing worker location information and promoting collaboration among workers increases productivity.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Environmental Control and Energy Management',
            desc: 'Smart lighting systems detect worker location and automatically adjust lighting to save energy and provide a comfortable lighting environment. It also monitors worker location to adjust temperature and monitor air quality for a comfortable work environment. This optimizes energy consumption within the office and smartly manages the environment.',
            dot: ''
        },
        {
            head: 'Conclusion',
            subHead: '',
            desc: 'The Smart Office solution through RTLS plays a crucial role in improving the work environment in various aspects such as asset management, worker productivity, and enhanced security, enhancing the competitiveness of businesses. Using these solutions, organizations can build a Smart Office environment and prepare for the future.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'A product that helps real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.assetTracking.thumbnail
            },
            {

            }
        ],
        solution: [
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/en/enterprise/office/asset'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/en/enterprise/logistics/asset'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/en/enterprise/retail/exhibition'
            },
        ]
    }
}
