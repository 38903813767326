import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet: '| 在智能物流设施中管理工人安全的方法',
  desc: '通过使用RTLS（实时定位系统）技术跟踪工人的实时位置，可以预防安全事故，并提高物流设施的生产力和效率。',
  keywords: '物流工人安全，物流工人，工人安全管理，物流中心管理，智能物流中心，物流设施安全，实时位置跟踪，RTLS，UWB，Orbro，ConTech，大型物流中心',

  ogTitle: '',
  ogDesc: '',
  ogSite: '在智能物流设施中管理工人安全的方法 :: Orbro博客',
  ogUrl: 'https://orbro.io/cn/blog/logisticsWorker',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4NdDe6oJsqBton9AjaPbL4Q5NxAVY66XFiGJrNW6Ib8-vKVwi61wEFWFuSoe0IzzjXlQbOtwFN55XZKQq8-merMyuu_Kc-aCU9rXsAxqlu4jJu1W48RqVrOHIVfqqqsE6lyVOnqs0-udnyCNaiR_P-55-JDEg1q7Xen3jEtC_J7Vb-agtwtcKFwspoLjunKXVBr9DX9XtbURNHamoE8axj_QTsyRGYEjihiSkLTZsKjMAFQ7XEjKC5gypKGi-6h70LVzMWxFYES1IEFrj_g1Xm7X_87ZICuq9UkXYfF5_kSsePap3XCgzaGjXTxtewamebrNTcvtyyOGiDJzCop_9ps9SiDxBVMH5GHlXgzqGmO97RWoXrAzVM73ecqHfZvofOY6_mcID1tD3eGIZBqVBgd7U8JQKxRv5VzLnj2nylAh5JYmN5uKSjOqTtNmcH5ryzlmyojOD2ErsaCkaHV5ExP87eOjZKQy4uKs_zWCdrgvAmWTPvzw69EDOlKv2RoG16hSWS_Ak_ejmhNKHqiapfSKTGHaC-OyuAl909u40AIfMQLdhaDAfq0dLJve-7i1wj3R4CLlyviq_bE_NXXUK5qw3DbmY5cmW6vI1h_bt2hkpZNfi3JDz5QhJ9DAcFdAWrfv2leATnS45LJW4WOW1oXUJDbzkHUZQHW0ZW6wIGYVQ-xlZWJJqhrD_Njlp8FUHTGIx0P4nbQ9gwzfH5XHMncEv6nMznbvF1pSn3RUd018qa27lJlV03sBikmB34XLF4CDCNEO1bQw9jWtbwk_vYV4M8X13pC0IyanCVU2du9J_GkiVXzFXfbzpyqQ9WOMjzjr1fz7PFZ9K4m9oA5JTQT4J3Zdqjx740QlfdBPLL-2yRp1LOhhELK6pOVdTjGXtjX3wv47zRDndoJU1_JRmZ4jZVB7-7HzJYviS1og154IRi0RIUkjfgjfUAA8LCVjWDHS32tJzSqsZXqKTXYRk9Rhi_YcjuhZLNetMYWCuiyoFBsqFmv6ai_Ux9jN66QNYaXnxRFnLddvNA6yApVZwoKPn-IzhYO8Vqv-0kVcG5eU-71tpilnn85vP3jJ6t79hkLY3n-fxyWgmRvnYI29nFIXJVmMfS6WNeeoqxKWJXUKMhbTVHH2pLy3fBnuVsWrrFjp1exyh-ahC1VHmDZbVVT2OhrELUuXeed4tfK3fgFsq066oX6o3XaihsVsvxd_7ohiRB6yCLuR0wSKZfvVqT9HQbGXWjOB6TZtXQpmsDzt_Hm4jCIyzgGJhQL-iMQZzqxalaYhQYVocsX1i6DSrWQD54jIik7h_PJfgK-eulk6EBtGZS3Tzf0Iaureqspuc_I8f4hcXRLWO2U5NnbnPZUmwxp-__Br0RSKSdBAv39o-tNXhLUk0-WF5nNiPAK4XlxF4hKVQl6nVCf5_wzw5t95Acv8QlXvmGuwe9etTZWlibVBH-aqe5_XOETlbldEKqJj_JN2mk1bGd6U2D5jYxlz5mexjaGdd6aWxF48u6hG_15sIUgXGqEHPhXvDJk6bTv_k7wgvoN1ESF_WKxkZuAnBZWa3G7jWW4VT6NK9Q3RxYq3VXTACR2nFRVJQ=w2052-h1199',
  ogType: '网站',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '在智能物流设施中管理工人安全的方法',
  date: '2023-09-06',
  tag1: '#物流中心安全',
  tag2: '#大型物流中心',
  tag3: '#物流中心工人',
  tag4: '#工人安全管理',
    
  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: '物流设施中的工人安全是物流业的重要因素之一。即使在进行货物移动、存储、装运等各种操作时，也存在事故的风险，即使是小事故也会立即影响安全、生产力和效率。然而，通过使用实时位置系统（RTLS）技术进行实时位置跟踪，您可以确保工人的安全。以下，我们将探讨如何管理工人的安全并运营智能物流设施。',
      dot: ''
    },
    {
      head: '什么是RTLS技术？',
      subHead: '',
      desc: '首先，RTLS，即实时位置系统，是一种实时跟踪位置信息的技术，利用GPS、RFID（射频识别）、UWB（超宽带）、BLE（低功耗蓝牙）等多种技术。这使得能够精确跟踪物流设施内工人的位置，并可以利用此位置信息创建安全的工作环境。',
      dot: ''
    },
    {
      head: '主要优势',
      subHead: '1. 工人管理',
      desc: '通过RTLS技术，您可以实时跟踪工人的位置。通过准确了解工人在物流设施中的位置，您可以防止工人之间的碰撞以及与重型设备的碰撞，并确保在紧急情况下能够迅速做出响应。这不仅提高了安全性，还能够在工作场所有效地分配工人，提高效率。',
      dot: ''
    },
    {
      head: '',
      subHead: '2. 安全区域设置',
      desc: '物流设施内可能存在不应该进入的危险区域。通过利用RTLS，您可以建立安全区域，如果外部人员或未经授权的工人进入这些区域，即可发出危险警报。反之，也可以采取措施，例如当特定工人离开这些区域时触发警告，有助于提前防止事故。',
      dot: ''
    },
    {
      head: '',
      subHead: '3. 紧急情况响应',
      desc: '当工人面临紧急情况时，RTLS技术可用于快速获取位置信息。这使得可以迅速进行救援和紧急医疗援助，确保工人的安全。',
      dot: ''
    },
    {
      head: '',
      subHead: '4. 效率和生产力',
      desc: '通过RTLS跟踪工人的移动，不仅提高了安全性，还优化了工作流程。如果与资产跟踪解决方案结合使用，您可以运营高度先进的物流设施。',
      dot: ''
    },
    {
      head: '',
      subHead: '结论',
      desc: '物流设施中的工人安全在物流行业中至关重要，利用RTLS技术进行管理和提升安全性非常有效。通过实时跟踪工人的位置并创建安全的工作环境，您可以提高工人的安全性并有助于预防事故。RTLS技术的使用将为物流设施运营打开更加光明的未来。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: "将UWB终端机安装在工作者或设备上，帮助实时追踪位置的产品。",
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }
    ],
    solution: [
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      },
      {
        img: logisticsSafety.thumb,
        data: 'logisticsSafety',
        link: '/cn/enterprise/logistics/safety'
      }, 
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/cn/enterprise/manufacturing/asset'
      }
    ]
  }
}
