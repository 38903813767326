import {
  medicalPatient,
  officeAsset,
  retailExhibition
} from "@pages/Enterprise/Solution/components/Banner/BannerImages";

import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {
  helmet: '| What is NFC?',
  desc: 'NFC, or Near Field Communication, is a short-range wireless communication technology that enables data exchange in close proximity. It uses small radio waves to facilitate data exchange at very short distances.',
  keywords: 'nfc, uwb, wireless communication, location tracking, object location tracking, people location tracking, tags, beacons, rtls, orbro, contech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'What is NFC? | Location Tracking and Digital Twins, Orbro',
  ogUrl: 'https://orbro.io/en/blog/nfc-uwb-ble',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDlx0aose_UUwB8yAMLpMzvTdJK7dGM_ga67nWJqGp-Ad-0qXiKbUEUjAYHHCIw3c5fBJeOurZ9GbBlOLASQQ-fxRGXYfqb0RRl8SAXz05tRfZVuRggCXCN4Lhgi5bmQUDXYI9RvZTvlt-aRguSSi_Stihe1t7AUtepg-f_OTkuBEX5XDNmq1mSEBJVgqC26dYfGuJh3zgT12nYtPK60Bz8xB5ydx2cmvaAbP-No0hvZIWMciZv6XarrpKcR-Eu6YSdcsFOZ3J9wjVTeyyc6S-gVqoxW4abVCEfQaItklbow0uqPjlPT_KHGCGQa0SKOW97pUKx0PO8uSBpJMD6AzeJ4Yh-01yRQqpC6vcBsmNvEBxMvLvTm8BX5mRmBV48v6kHK_P7WycSemW5wnW58cEHX5Wjo2tV2tTJHX9y_qWwOZZWX7eFx73V0WmX-vG4ABxNwFr7u-p3TKA6nWMxJzwGyLw_9ouTexsqnfiBXf8MhruRLbrNobB19ErL_DHgZi4kPuI8PDDdT4kvxwE9lt4LpJuArzzdpvwhT4XnDrFl73C616KHcorzOcrOJxw-jzKhFvc-q17_3S--zh2PcMpG5cvL7DJ1Cgy62atJyXB5IB67bDeOUAnB_Zr3RdAUcVBg3_yADYB7htLduaNVCYlC8F9ucLtTOKYqDU_Kc0G53AHrpmiqbbWxLOyGamXVkCO9EzJaWC8EVDcclcnwMUWf_5vC_tIg8wnNp4nSycN0vVAfm-iukMGeHV3onRhtyGa--Q_31wtjzULC5n1ffWD2DgX0LCxksQOCkOmzWPFRhGC-UpLo1hUE6ar64oJbH06kISvVcqIqPYMLHIqWcMchD9FMPlBI2P0XtEJaZtNQ8OQ7XdAJzRXi6TK_jo16h0jAW9Bnh5sv9BA0eJoEQz4d0jD1wMwPB9StiMAHG_6pTNleKUOO92VU0weNP0U6p8gqceFhBSgierz4ukU8ud1rti_cnmtvPUHKNnPGoKMb-np6KexGgI3D9XWzzAsaUrAwziO87m2zHYt2SezQ3urRH9gG1yhokna4WTjfrxED9WfE2yI9uC3xMuALyZLompGIZk-mz9k1N8O4kZRHSLtIt_TQSUDILXDQsIwVMeMs1q_U9i6L10APLVQAjMZQL5dxa557zkCPB59Zytf72ASMHtp9TL6LGnpV5bPGGl3GSNPkH8sjR7UkuMmEFrUbbohLMbhHtxGneSDUtRJO7bdQsysoOK_-ZxsXqnkQVa1KX-J153QU3yI4IlaViIpjxY9w5kptwJPL_WFlXcjUnR-JnSu5c3FPe8y8gae7yt91cT6KECSmJelkYxaEan1q9_hAgXCvb24BKiRGWrNMAacUoPz8hljTI0cf8fB9GuDiPoqYgxJKcZFdicKosSD3SZMXnLbF4hZE96gFYFWuOIetfv7pzlsg08ZZ0EiklpgY1dshXnDmrLrKcnB4v_mWgvqKA1tyQnCZrAYyX-Z7zW_cj00TQX28EqAsV3cAkEVZTs_ClA1g-xHvUBIm-0logZKUhbQUT9TBz5eip_Aw_rexmWpOV4XcV=w1984-h1214',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'What is NFC?',
  date: '2024-01-11',
  tag1: '#nfc',
  tag2: '#locationtracking',
  tag3: '#wirelesscommunication',
  tag4: '#assetmanagement',

  // <br/>
  text: [
    {
      head: 'What is NFC?',
      subHead: '',
      desc: `NFC (Near Field Communication) is a short-range wireless communication technology that enables data exchange in close proximity. This technology uses small radio waves to exchange data at very short distances, typically within 10cm. NFC is primarily used for quick and easy transactions, mobile payments, access card systems, etc., between smartphones and other electronic devices.`,
      dot: ''
    },
    {
      head: 'How NFC Works',
      subHead: '',
      desc: 'The operation principle of NFC (Near Field Communication technology) is highly effective and straightforward. NFC first generates small electromagnetic fields between devices. This field, similar to the concept used in radar, serves as a conduit for exchanging data. This small electromagnetic field operates effectively only at very close distances between devices, usually within 10cm.<br/>The operation principle of NFC is based on RFID (Radio-Frequency Identification) technology. Therefore, wireless data exchange occurs between the transmitting device (reader or initialized device) and the receiving device (tag or card). The devices recognize each other through the electromagnetic field and initiate communication.<br/>One key feature is that NFC operates at a relatively short distance, providing security advantages by preventing unintended data exchange. Additionally, this operating principle allows fast data transmission in real-time.',
      dot: ''
    },
    {
      head: 'Key Applications of NFC',
      subHead: 'NFC Mobile Payments',
      desc: 'NFC is primarily used for convenient payments using smartphones. Users can quickly and conveniently complete payments by bringing their smartphones close to NFC-supported payment terminals, transmitting pre-registered payment information. Examples include Apple Pay, Google Pay, Samsung Pay, etc.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Data Transfer',
      desc: 'Simple data transfer between two devices is possible using NFC. For instance, contact information, photos, business cards, etc., can be easily exchanged between smartphones. Additionally, NFC tags can be programmed to facilitate automatic information transfer.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Smartphone Feature Extension',
      desc: 'NFC is utilized to extend the features of smartphones. For example, it can be integrated with home automation systems to control lighting, heating, security systems, etc., by tagging smartphones to specific devices.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Electronic Access Cards',
      desc: 'NFC is commonly used in electronic access card systems. It allows access to buildings or facilities using smartphones or NFC tags, providing higher security and convenience.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Medical Field',
      desc: 'NFC is effectively used in various applications in the medical field, including patient identification and access control, medical record and drug management, emergency response, and automation of clinics. NFC contributes to efficient automation of medical systems, enhances patient safety, and provides rapid and accurate medical services during emergencies.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Smart POS Technology',
      desc: 'NFC is used in conjunction with Smart Point of Sale (POS) systems in stores or cafes for payment and discount information transfer. Customers can easily make payments by bringing their smartphones close to the payment terminal.',
      dot: ''
    },
    {
      head: 'Difference Between NFC and Bluetooth',
      subHead: 'Communication Range',
      desc: '',
      dot: {
        a: '<strong> • NFC:</strong> It operates only at very close distances, typically within 10cm. It is primarily used for data transfer in contact or very short-range scenarios.',
        b: '<strong> • Bluetooth:</strong> It operates over a much wider range, typically up to 100 meters. It is used for data transfer and device connectivity over longer distances.',
      }
    },
    {
      head: '',
      subHead: 'Transfer Speed',
      desc: '',
      dot: {
        a: '<strong> • NFC:</strong> It generally has slower transfer speeds compared to Bluetooth. It is mainly used for transferring small amounts of data.',
        b: '<strong> • Bluetooth:</strong> It provides high transfer speeds and is effective for quickly transferring large amounts of data.',
      }
    },
    {
      head: '',
      subHead: 'Applications',
      desc: '',
      dot: {
        a: '<strong> • NFC:</strong> It is primarily used for short-range applications such as simple data exchange and payment systems, reading tags (reading information stored in tags).',
        b: '<strong> • Bluetooth:</strong> It is primarily used in long-range and large data transfer applications, such as headphones, speakers, keyboards, etc.',
      }
    },
    {
      head: '',
      subHead: 'Power Consumption',
      desc: '',
      dot: {
        a: '<strong> • NFC:</strong> It is known for low power consumption. It is mainly used in small devices that do not require charging.',
        b: '<strong> • Bluetooth:</strong> It has relatively high power consumption, which can impact battery life in some devices.',
      }
    },
    {
      head: '',
      subHead: 'Security',
      desc: '',
      dot: {
        a: '<strong> • NFC:</strong> Since it can only communicate at very short distances, it is generally used in relatively secure environments.',
        b: '<strong> • Bluetooth:</strong> As long-range communication is possible, security is more critical, and stronger security technology is required.',
      }
    },
    {
      head: 'Future Outlook of NFC',
      subHead: '',
      desc: 'NFC technology continues to evolve, and its applications are anticipated in various fields such as mobile payments, smart homes, and the medical industry. Ongoing technological innovations aim to provide better security and higher transmission speeds, positioning NFC to play a significant role in diverse industrial sectors.<br/>NFC, as one of the wireless communication technologies, is expected to lead innovation in various fields in the future digital environment. Its development is expected to bring more innovations to the digital landscape.',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'This product assists in real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/en/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/en/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/en/enterprise/office/asset'
      }
    ]
  }
};
