import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconLocation, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconUserCheck, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/medicalPatient/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/medicalPatient/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/medicalPatient/video/sub/rtlsVideo3.mp4";

export default {
    banner: {
      title: 'Real-time Patient Tracking',
      desc: 'Medical facilities have patients who require special care. You don\'t have to monitor them directly 24/7. Track patient locations in real-time, with greater accuracy and speed.',
    },
    video: {
      title: 'How to Manage Patients with Enhanced Safety',
      desc: 'Real-time Location Tracking System (RTLS) technology tracks the location of patients in real-time. Reduce the manpower and costs associated with patient management while building a safer system.',
      play: video,
    },
    func: [
      {
        title: 'Real-time Location Tracking',
        desc: 'Track the real-time location of patients within the medical facility. If a patient approaches a restricted area, send instant notifications to administrators.',
        sub1: 'High Accuracy',
        icon1: IconRulerGray,
        sub2: 'Fast Data Integration',
        icon2: IconShareGray,
        video: subVideo1,
      },
      {
        title: 'Epidemiological Investigation',
        desc: 'Since COVID-19, early response to infectious diseases has become even more important. Track the movement paths of infected individuals to identify contacts or suspected patients, and prevent early facility spread.',
        sub1: 'Designate Risk Assets',
        icon1: IconWarningGray,
        sub2: 'Store Data in the Cloud',
        icon2: IconCloudCheckGray,
        video: subVideo2,
      },
      {
        title: 'Fall Detection',
        desc: 'Patients, including the elderly and those with mobility issues, are always at risk of falling. Establish a fall detection system to respond immediately.',
        sub1: 'High Accuracy',
        icon1: IconRulerGray,
        sub2: 'Fast Data Integration',
        icon2: IconShareGray,
        video: subVideo3,
      },
    ],
    subfunc: {
      title: 'Various Features',
      desc: 'The most important aspect in medical facilities is the health of patients. Patient conditions should be monitored anytime, anywhere, and quick initial responses are essential. ORBRO provides the following features to maintain a safe healthcare facility.',
      data: [
        {
          icon: IconChartRadius,
          title: 'Reports and Analysis',
          desc: 'Generate statistical reports and analyze data based on patient movement paths.',
        },
        {
          icon: IconBell,
          title: 'Real-time Alarm Function',
          desc: 'Send alarms immediately when abnormal situations occur in patient movements.',
        },
        {
          icon: IconRoute,
          title: 'Asset Movement History',
          desc: 'Track the paths of specific patients to prevent safety accidents.',
        },
        {
          icon: IconDashboard,
          title: 'Status Awareness',
          desc: 'Monitor the current location of specific patients in real-time.',
        },
        {
          icon: IconLocation,
          title: 'Indoor Navigation',
          desc: 'Identify lost patients or visitors so that medical staff can provide assistance.',
        },
        {
          icon: IconUserCheck,
          title: 'Enhanced Experience',
          desc: 'Instill greater trust in people visiting hospitals.',
        },
        {
          icon: IconChainLink,
          title: 'Data Integration',
          desc: 'Efficiently utilize systems such as RFID, IoT, security, and video by connecting them together.',
        },
        {
          icon: IconSecurity,
          title: 'Data Security',
          desc: 'Maintain a high level of data security to provide a reliable solution.',
        },
      ],
    },
    system: {
      title: 'System Configuration',
      img: rtlsSystem,
    },
    productTitle: 'Product Configuration',
    product: [

      {
        tag: 'peoplePackage',
      },
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'medicalWaste',
      },
      {
        data: 'logisticsSafety',
      },
      {
        data: 'blogMedical',
      },
      {
        data: 'blogOffice',
      },
    ],
  }
  