import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const VideoPage = styled.div`
  padding-top : 128px;

  & * {
    font-family: Pretendard !important;
    font-display: swap;
  }

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    padding-top : 96px;

  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding-top : 96px;

  }
`;

export const VideoHeaderText = styled.div`
  width : 100%;
  text-align : center;

`;

export const VideoBox = styled.div`

  position: relative;
  width: 100%;
  display : flex;
  justify-content : center;
  overflow: hidden;
  
  border-radius: 8px;
  border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
  }
`;

export const VideoPlay = styled.video`
  width: 100%;
  height: 100%;
  
`;

export const VideoIconBox = styled.div`

  width : 100%;
  display : flex;
  flex-direction : row;
  gap : 24px;
  justify-content : center;
  align-items : center;

`;

export const IconGroup = styled.div`

  display : flex;
  flex-direction : column;
  gap : 6px;
  justify-content : center;
  align-items : center;
  
  width : 71px;
  cursor : pointer;
`;

export const IconBox = styled.div<{ isSelected: boolean }>`


  display : flex;
  justify-content : center;
  align-items : center;
  padding : 4px;
  width : 32px;
  height : 32px;
  object-fit : contain;

  border-radius: 8px;
  border: 1px solid var(--primitive-gray-200, #DADADA);
  background: var(--primitive-transparent, rgba(255, 255, 255, 0.00));


  ${({ isSelected }) =>
    isSelected &&
    `
    border: 1px solid var(--primitive-blue-200, #BFD4FA);
    background: var(--primitive-blue-50, #EAF1FD);
  `}

`;

export const VideoPageBottom = styled.div`

  padding-top: 96px;
  border-bottom : 1px solid rgba(0,0,0,0.08);

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{

  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding-top: 64px;

  }

`;