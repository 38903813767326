import { StyledGridContainer } from "@components/Grid/Grid.style";
import React from "react";
import { Trans } from "react-i18next";
import { StyledTitleBanner, StyledTitleBannerDescription, TechBannerBtn } from "./TitleBanner.style";
import { DisplayH1fff } from "@components/Text/HeadingTagDisplay.style";
import { HeadingColor, LabelColor, Spacing } from "@pages/CommonStyle/common.style";
import { useLocation } from "react-router-dom";
import i18n from "@pages/i18n";

interface TitleBannerProps {
  subtitle: string,
  title: string,
  description: string
  btnText? : string
  btnLink? : string
}

const TitleBanner = (props: TitleBannerProps) => {
  const { subtitle, title, description, btnText, btnLink } = props;
  const location = useLocation();
  
  return (
    <StyledGridContainer>
      <StyledTitleBanner>
        <HeadingColor color="#FFF" size="xxxsmall">
          <p>
            <Trans i18nKey={subtitle}/>
          </p>
        </HeadingColor>
      
        <Spacing pixel="16"/>
        <DisplayH1fff size="medium">
          <Trans i18nKey={title} />
        </DisplayH1fff>
        <StyledTitleBannerDescription size="xsmall">
          <Trans i18nKey={description} />
        </StyledTitleBannerDescription>
        {btnText &&
          <>
            <TechBannerBtn href={i18n.language === 'ko' ? btnLink : `/${i18n.language}${btnLink}`}>
              <LabelColor color={"#000"} size={"large"}>
                <Trans i18nKey={btnText} />
              </LabelColor>
            </TechBannerBtn>
          </>
        }
      </StyledTitleBanner>
    </StyledGridContainer>
  );
};

export default TitleBanner;