import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { logisticsAsset, manufacturingAsset, manufacturingProcess } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {

    helmet: '',
    desc: 'The solution for quality and inventory management using UWB enhances the competitiveness and maximizes efficiency through various advantages in the modern business environment.',
    keywords: 'UWB RTLS, indoor positioning, asset tracking, contactless payment system, smart home, healthcare, automotive safety, UWB, RTLS, GPS, BLE, digital twin',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'Quality and Inventory Management Solution: UWB :: Orbro Blog',
    ogUrl: 'https://orbro.io/en/blog/qa-uwb',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDlVCtmGkr2mCVSS69T6u1T4F6EZiqevSZ-7cN786FOvXV8x22WHoof7IcLlSW7d3d4DnUPZT2QfBIuUWr_weryhpgDPEa45E1edX4NBzbCnjImjTiKPVNkJrnd-hMccXsYDNleXYSmD_YNZSLPjAwSMKKN90Mx3tpkR9a7UTKQ-cImQeFt0AOvs96-ik71EqDzH3cdqAWsBQ7aU_aLz_wlgsJwZMUUAAPPY7lxS8lWkog24zYM8BWi2P4-bg6lZ0IL5Be72Kuu1Ga2-E828VnyLDcaIkXnXbFqBqFoW57RzNMYcpG-bH3VEhchLwDniUo-jBNqflu56GYJZFz_CMBt-PdbwZEXzyNVxVyomHJgFQ-_tuofm4uNMx6DdvQ3EAbi99lfqXxlwE05tg7IFIIn88NzPmt6B_D-g0Ujw73JyDNlyzOefzda1AisrhVvXXHE12gEZAQEAnDVsf3mDImb9t9WffWNd047vV9q6GWhzE9BNt5ibIfCXZxVJD3Q6xWSICvlyRJdbFKPLMbsjwe07izGimOadYEIgqo7IBq0sH9kw8I0azDR2iJKcAEFexI4TBxg0uvQJRTqbePuIdd8hvDRm7HcFvd3-X8PctgzhJPrN4y7nSMWlWSH77CEDaYptKymzQvANITzUqYwh8_MWoPcMYKdM-3z8fuRhtut9qckVa8HbX0lFmfPE1hci-P_pz7Wx-jCb2pXgdSoVENciMiiHeng7uHM3BtBM0-2GKsCINKIFKGJs8iD-kgEiH8VWr3SkIVWJzkR-Bi63wUR-khcKdv2u2jwsQV0ClNREfUWWQpiEZxfFyw2zK8KMPxRSk4hhKh_hNeIagh-ygtgeLYzblJicZL7Hbl5w7USiFatqiLkaNKzBimapAPyMde_FZuPRAQie5tC95ZtYPRyXGGMGA1qXV7oZ2GpzqwBkG9wU_MTYQDznkHb3cJvSYUWVGT6J704WQWlETRLd2Dl7N60sZ50lDknqhXG1ZfGGF8qcBgIAXqfuUF-nRIYqZqwHoObsg_F_5iBMZzejZ82s1gbIGHR3MKaOqJy2hZOFgZfKdIST5uJHCKvc22R3enzPv502iA-rkaOY5n5fbWlFGYCB7vwvoWYspA8zGdUhQAee4hAWRQrn4L65-tc4n0rKFKBUqDi_tE5q7d9CLdGirQixGEhZc1pP8SOT9wRECaNgtzQjZf5lrjBdtjBR6oi8m6_cDiZsyHY2kZZVIQBodyLG9VToaONzqqNbcfLdQJ9PnDwxV0zS2UV1hpZIJSoCjk3fLgUn_rHE4yBlkubsupv3o4b1uUuWOSmCl2z8Bbowg6pub3WJAQ92GZa9l06BsP8H7wNS5c0B-pMOockKojLd5B-uMVbslrDK1KUS-0_00CRW9N9KzUg0-jtCM62SDPsilmcnPFwxO5bcNMVXCaUwDa5GVDhKgXXsi4aZ5tbkxZhH3RHDPSLjNtVr6KNeUCBt4Nezhhyp3ETy9B9L0m28nYfY5KUDdzpzwo68QW5GQMDP4bn4vq9pl7_oKviMByvEuvJPzi4pa1LZ9HlqTCFEf3RSRvMv_UhyZQ=w1851-h1197',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'Quality and Inventory Management Solution: UWB',
    date: '2023-12-18',
    tag1: '#QualityManagement',
    tag2: '#InventoryManagement',
    tag3: '#uwb',
    tag4: '#rtls',

    // <br/>
    text: [
        {
            head: '',
            subHead: '',
            desc: 'In the modern business environment, efficient quality and inventory management play a crucial role in the success of a company. Various solutions that enhance these management processes are emerging with the adoption of the latest technology, and one of them is the solution utilizing Ultra-Wideband (UWB) technology.<br/>UWB, with its high-precision location tracking technology, can provide precise location information due to its wide bandwidth. Applying this technology to quality and inventory management can provide various benefits to businesses.',
            dot: ''
        },
        {
            video: video,
            link: '/en/enterprise/manufacturing/asset',
            head: '',
            subHead: 'High-Precision Location Information',
            desc: 'UWB technology provides high-precision location information, allowing businesses to accurately track the location of products and assets with high accuracy. This enables real-time tracking of the movement path of products and accurate identification of the location of objects, facilitating efficient operations.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Real-time Data Detection and Analysis',
            desc: 'UWB solutions collect various data in real-time. This allows rapid detection of problems or changes occurring at each stage of the production process. Through data analysis, businesses can quickly identify the root causes of issues, enabling swift response and improvement to enhance production quality.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Energy Efficiency Improvement',
            desc: 'UWB technology operates with low power consumption while providing high precision. This enhances the overall energy efficiency of the system and extends the battery life of equipment. Improved energy efficiency has a positive impact on the sustainability of the business.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Real-time Adaptive Control',
            desc: 'UWB solutions enable real-time detection and analysis of data, allowing for automated adaptive control. This optimizes the work processes in production lines or warehouses and helps improve future operations through predictive analysis based on past data.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Enhanced Security',
            desc: 'UWB uses wireless communication and provides a higher level of security compared to other wireless technologies. With extremely low physical interference, it ensures secure communication, strengthening the security of corporate assets.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Customizability',
            desc: 'UWB solutions can be applied to various industries and can be customized to meet the specific requirements of businesses. This flexibility allows businesses to improve their systems independently or introduce solutions tailored to specific operating environments.<br/><br/>The solution for quality and inventory management using UWB is an innovative technology that enhances the competitiveness and maximizes efficiency through various advantages.',
            dot: ''
        },
        {
            head: 'Use Cases',
            subHead: 'Manufacturing Industry',
            desc: 'Real-time location tracking systems using UWB are used for efficient production management in the manufacturing industry. Accurate tracking of the location of raw materials, components, and products optimizes the production process and reduces defect rates.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Logistics and Warehouse Management',
            desc: 'Introducing UWB in logistics companies or large warehouses allows real-time tracking of the movement paths of goods, optimizing inventory management. Especially in large warehouses, an automated guide system through UWB enables efficient inventory placement of goods.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Medical Field',
            desc: 'Hospitals and medical institutions use UWB to accurately track the location of medical equipment or patients, enhancing emergency response and improving the quality of medical services.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Shopping and Retail',
            desc: 'Retailers analyze the movement patterns of customers within the store using UWB, and real-time monitoring of product inventory status improves inventory management and enhances customer experience.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Construction Industry',
            desc: 'In large construction sites, UWB is used to accurately track the location of equipment and workers, enhancing safety and optimizing work schedules.<br/><br/>These examples demonstrate the success of UWB location tracking technology in various industries. Various companies are adopting UWB solutions to improve productivity and efficiency, securing competitiveness.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'A product that assists real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.assetTracking.thumbnail
            },
            {

            }
        ],
        solution: [
            {
                img: manufacturingProcess.thumb,
                data: 'manufacturingProcess',
                link: '/en/enterprise/manufacturing/process'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/en/enterprise/manufacturing/asset'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/en/enterprise/logistics/asset'
            },
        ]
    }
}
