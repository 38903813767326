import { FlexStyle } from "@assets/styled/flex";
import { StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";

interface FaqDetailProps {
  isOpen: boolean
}

export const StyledFaqItem = styled(FlexStyle)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  flex-direction: column;
  width : 100%;
`

export const StyledFaqItemTitle = styled(FlexStyle)`
  justify-content: space-between;
  align-items: center;
  padding : 20px 0;
  cursor: pointer;
`

export const StyledFaqItemTitleIcon = styled(FlexStyle)`
  width: 64px;
  height: 100%;
  user-select: none;
  justify-content: center;
  align-items: center;
  right : 0;
  
`

export const StyledFaqItemDetail = styled(FlexStyle)<FaqDetailProps>`
  display: ${({isOpen}) => isOpen ? 'flex' : 'none'};
  margin-bottom : 32px;
`

export const StyledFaqItemDescription = styled(StyledParagraph)`
  line-height: 1.5;
`

