import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| あなたの展示館の来場者が減少する理由',
  desc : 'RTLS（Real-Time Location System）は展示会場内の訪問者の移動経路を追跡し、来場者の流れを分析し、展示物の効率的な配置や戦略的マーケティングを実現することができます。また、訪問者には個別化された情報を提供し、インタラクティブな体験を提供します。',
  keywords : '展示会来場者分析、動線分析、来場者の経路分析、展示会の管理、スマート展示会、展示会運営、博物館、美術館、小売り',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'あなたの展示館の来場者が減少する理由 :: オーブロ ブログ',
  ogUrl : 'https://orbro.io/jp/blog/visitor',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4MMdkaL97d9T_gFLigXJSSbK2C0Or5jdSF6zVXkL2GI1HygPUMT2RCE0ttjlbTfLOl7PLSt3VsYQ6KBvvxEcPY3GuIa0uLL-mF35giNEbpMQNRgbpFHVcsqyA4o5rANv8gihSPfYbKM1HyvPt6_D1kZep6j_kGIaRAvWTOvABY9JXY295auK1rnwLsUAz1X331ALugJ2qNpT27T78NeS4AiEE_bH81MCbchU1l9ZcYBhY7YU7MoeuNdgO9gOc3fUkLNxPjMk9a0Egch5WFcyvcT6f6zbP4GStaxCf9r-AMdky5yya4HlObcx-cc9tJBlLRRmVjz1q73RJGDgIn8z6eIEBaOdJwi40d1Y697PV2niYZZAHlNQfCy7AaBz2m93TnUzj2I9Jf95EghdfJ2n0DE8C9g_JPlkCqJkjidGuNy8TepCsFr0_lzXEvxuYf-kgIqoykgB7hF8lkIyLegxSeY-4UtNLa63wKeVC0zuS8eMFrMyZLj8RzK5C7F4xVN546wg3P_OKi5cyKM1MEaA_E00yT-21yH5O4lo2TcH8vrAnUywRRPr6AU8nyWj3Ao3i1r7MdYeuvX00TTrkY9oCqtsPqjPBWatW5J2b1MAA9huMencK80d_RPn6K9MAJ46DxVfMEeOVGCZbVDP4Gw25D-_zZupP04L_a1yKnXuECxtYPD33mhyiT5ujUthjZENr-Iqh-8hjoblcAXTVW1B8MQVQPFwhXyg7Ku5mqxxR8qMu4XpbwPLFB0dOybWkairwNgv9Le1CI_2pTt-GkYKsC1U0ZxJUhFVd5gG6j8PPlaexJlRMIfslQVBwz6hFidjGuzeKOHz2BJBUG8iNu1DPSRGfaouJZmVtueDjlUYRXDpZbHZhmb7qtWqgX3cDComumpY4F8rE8CP3NESiewWR6aRWwuT2HM7t-9KovMjMuNC2WFZUeBFN7nGZOmXLITm4xGWZbYSsHMIoOvxVECF6sOKHdp9Hmx7veIXsSwhSdBh67hlaKJBXzXZ6uRnxi554EzB5xfZpExcxFpsEtPdFKFCPNnGVuudg_N2eYeVj6ZECRrqK7S-RUEzaiLLn5Dkw41DkxN4gJv7wxNmYNaH0q-pGU64OOMtrmJF9U9Ge9CLFze-95PFYYdjG_vO-E6ebt0N14ihC9qe1FYKkL7U3rUBKQdqGWnYU4OpKMhzmfI4U7S7yNQCbThTvPlusycMPcyuwfd_hIpmTxlwCymWUMbJKerk3mwqmyPdwhF4g5V0iYUVa2SeHyBzKAxMnulxIwCP89lUWJjUADIW_GepdLxyup3wFshCD3ljdM-KE43rP0H8ll55cJWUwwhgeuNdk4VrVNMkmsr5hqF54q15rcUE3ZeGr6wlwC0s-RskVI8B8RGU6h-ny7vy5u8Tf5YCoAm39v7KC6vG_zdOwzt5OFTZYSj1i1JYF3LmZXJmt86A6w6jN_K87xQtVOytpfk7W_Prwkm0tPBDnmTZGhe1V9qG7IH5aNI6nEEFoAYAPWwxWyCmTxBjCPgEZJoIkc0lPA9VjOeLLD88lcVF0fZHShvUOoqmGM-rBcIYwJ6dtkP9to=w2199-h1147',
  ogType : 'ウェブサイト',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'あなたの展示館の来場者が減少する理由',
  date : '2023-08-14',
  tag1 : '#展示会',
  tag2 : '#来場者分析',
  tag3 : '#経路分析',
  tag4 : '#スマート展示会',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'今の時代、展示会は単なる芸術作品を見る場所ではありません。忙しい日常から文化活動に時間を割くだけでなく、アートへの期待が高まっている今の時代において、展示会を訪れることはかなりの期待と驚きを伴うものです。<br/>より観客に優しい展示会であっても、洗練された展示会であっても、主な来場者層に合わせることが重要です。しかし、芸術的美しさの鑑賞は主観的な要素が大きいため、特定の作品が優れているか劣っているかを明確にするのは難しいです。<br/><br/>それにもかかわらず、特定の作品は人々に好まれる傾向があります。現在の時代においては、（無学だと思われることを恐れて）特定の作品が優れていると断言することが難しいかもしれませんが、滞在時間が長い場所、活気のあるエリア、頻繁に写真を撮る場所は、特定の作品の人気の指標となります。結局のところ、より多くの来場者を惹きつけるためには、人々が共感する作品を理解することが不可欠です。<br/>課題は、訪問者が滅多にこの情報を展示会の管理者に伝えないということです。では、管理者の勘に頼るべきでしょうか？<br/><br/>最近のリアルタイム位置追跡技術（RTLS）の進歩により、室内の人の移動を追跡することが可能になりました。これらのパターンは、展示会ホールの配置を反映するデジタルスクリーン上に表示され、誰にでも理解しやすくなりました。この技術は現在の場所だけでなく、さまざまな他の機能も提供します。以下でいくつかを探ってみましょう。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'人流分析',
      desc : '施設内で人々がどのように分布しているかを理解することができます。ヒートマップ技術を取り入れることで、特定の作品周辺で人々が集まるエリアを特定することができます。要するに、好みの作品を特定するプロセスが非常に簡単になります。アンケート調査を実施したり、CCTV映像を分析したり、マネージャーが施設を巡回したりする必要はなく、ヒートマップボタンを押すだけで済みます。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'資産管理',
      desc : 'リアルタイム位置追跡は人だけに限られるものではありません。特に芸術作品など特別に管理される必要がある資産や重要な設備にも、この技術は適用できます。これらの資産の移動をリアルタイムで監視することにより、安全事故を予防でき、より効率的な動線と資産管理が可能となります。また、検索を通じて必要な資産の位置を迅速に特定できます。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'セキュリティ',
      desc : 'リアルタイムの在庫追跡により、より迅速なリードタイムを実現できます。在庫の位置を正確に知るため、必要なアイテムを迅速に見つけ出し、生産または配送に必要な時間を短縮することができます。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'',
      desc : 'これらの技術はさまざまな用途に活用できます。屋内ナビゲーションを向上させることもできますし、スマートな入退室システムを導入して、より安全で効率的な施設運用を行うこともできます。さらに、これらの技術が一般の人々に知られるようになれば、自然にスマートでトレンディな展示会のイメージを提供することができるでしょう。',
      dot : ''
      
    },

  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc : 'この製品は、作業者や設備に取り付けられたUWBデバイスを使用して、リアルタイムの位置追跡支援を提供します。',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : retailExhibition.thumb,
        data : 'retailExhibition',
        link : '/jp/enterprise/retail/exhibition'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/jp/enterprise/logistics/asset'
      }, 
      {
        img : officeAsset.thumb,
        data : 'officeAsset',
        link : '/jp/enterprise/office/asset'
      }
    ]

  }


}
