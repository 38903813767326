import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| Reasons Your Exhibition Hall Visitors Are Decreasing',
  desc : 'RTLS (Real-Time Location System) can track the movement paths of visitors within an exhibition hall, analyze visitor flows, and implement efficient placement of exhibits and strategic marketing. Additionally, it provides personalized information to visitors and offers interactive experiences.',
  keywords : 'Exhibition hall visitor analysis, Movement pattern analysis, Visitor path analysis, Exhibition hall management, Smart exhibition hall, Exhibition operation, Museum, Art gallery, Retail',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'Reasons Your Exhibition Hall Visitors Are Decreasing :: Orbro Blog',
  ogUrl : 'https://orbro.io/en/blog/visitor',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4MMdkaL97d9T_gFLigXJSSbK2C0Or5jdSF6zVXkL2GI1HygPUMT2RCE0ttjlbTfLOl7PLSt3VsYQ6KBvvxEcPY3GuIa0uLL-mF35giNEbpMQNRgbpFHVcsqyA4o5rANv8gihSPfYbKM1HyvPt6_D1kZep6j_kGIaRAvWTOvABY9JXY295auK1rnwLsUAz1X331ALugJ2qNpT27T78NeS4AiEE_bH81MCbchU1l9ZcYBhY7YU7MoeuNdgO9gOc3fUkLNxPjMk9a0Egch5WFcyvcT6f6zbP4GStaxCf9r-AMdky5yya4HlObcx-cc9tJBlLRRmVjz1q73RJGDgIn8z6eIEBaOdJwi40d1Y697PV2niYZZAHlNQfCy7AaBz2m93TnUzj2I9Jf95EghdfJ2n0DE8C9g_JPlkCqJkjidGuNy8TepCsFr0_lzXEvxuYf-kgIqoykgB7hF8lkIyLegxSeY-4UtNLa63wKeVC0zuS8eMFrMyZLj8RzK5C7F4xVN546wg3P_OKi5cyKM1MEaA_E00yT-21yH5O4lo2TcH8vrAnUywRRPr6AU8nyWj3Ao3i1r7MdYeuvX00TTrkY9oCqtsPqjPBWatW5J2b1MAA9huMencK80d_RPn6K9MAJ46DxVfMEeOVGCZbVDP4Gw25D-_zZupP04L_a1yKnXuECxtYPD33mhyiT5ujUthjZENr-Iqh-8hjoblcAXTVW1B8MQVQPFwhXyg7Ku5mqxxR8qMu4XpbwPLFB0dOybWkairwNgv9Le1CI_2pTt-GkYKsC1U0ZxJUhFVd5gG6j8PPlaexJlRMIfslQVBwz6hFidjGuzeKOHz2BJBUG8iNu1DPSRGfaouJZmVtueDjlUYRXDpZbHZhmb7qtWqgX3cDComumpY4F8rE8CP3NESiewWR6aRWwuT2HM7t-9KovMjMuNC2WFZUeBFN7nGZOmXLITm4xGWZbYSsHMIoOvxVECF6sOKHdp9Hmx7veIXsSwhSdBh67hlaKJBXzXZ6uRnxi554EzB5xfZpExcxFpsEtPdFKFCPNnGVuudg_N2eYeVj6ZECRrqK7S-RUEzaiLLn5Dkw41DkxN4gJv7wxNmYNaH0q-pGU64OOMtrmJF9U9Ge9CLFze-95PFYYdjG_vO-E6ebt0N14ihC9qe1FYKkL7U3rUBKQdqGWnYU4OpKMhzmfI4U7S7yNQCbThTvPlusycMPcyuwfd_hIpmTxlwCymWUMbJKerk3mwqmyPdwhF4g5V0iYUVa2SeHyBzKAxMnulxIwCP89lUWJjUADIW_GepdLxyup3wFshCD3ljdM-KE43rP0H8ll55cJWUwwhgeuNdk4VrVNMkmsr5hqF54q15rcUE3ZeGr6wlwC0s-RskVI8B8RGU6h-ny7vy5u8Tf5YCoAm39v7KC6vG_zdOwzt5OFTZYSj1i1JYF3LmZXJmt86A6w6jN_K87xQtVOytpfk7W_Prwkm0tPBDnmTZGhe1V9qG7IH5aNI6nEEFoAYAPWwxWyCmTxBjCPgEZJoIkc0lPA9VjOeLLD88lcVF0fZHShvUOoqmGM-rBcIYwJ6dtkP9to=w2199-h1147',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'Reasons Your Exhibition Hall Visitors Are Decreasing',
  date : '2023-08-14',
  tag1 : '#ExhibitionHall',
  tag2 : '#VisitorAnalysis',
  tag3 : '#PathAnalysis',
  tag4 : '#SmartExhibitionHall',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'In today\'s era, an exhibition hall is not merely a place to see artworks. As people dedicate time to cultural activities in their busy lives, and as expectations for art have increased in our time, visiting an exhibition hall carries significant anticipation and wonder. Whether it\'s a more audience-friendly exhibition hall or one that exudes sophistication and culture, tailoring to the preferences of the main audience remains crucial. However, since the appreciation of artistic beauty is largely subjective, it\'s difficult to definitively label certain works as better or worse. <br/><br/>Nevertheless, certain works tend to be favored by people. While it may be challenging to outright declare what works are superior in this era (for fear of seeming uncultured), places where visitors linger longer, areas bustling with activity, or spots frequently photographed, serve as indicators of the popularity of particular works. Ultimately, to attract more visitors, understanding which pieces resonate with people is essential. The challenge is that visitors rarely convey this information to exhibition administrators. So, should it rely solely on the administrator\'s intuition?<br/><br/>With the recent advancement of real-time location tracking technology (RTLS), it\'s possible to track the movement of people within indoor spaces. Moreover, these patterns can be displayed on digital screens that mirror the exhibition hall layout, making them easily understandable to anyone. This technology not only tracks current locations but also offers various other functionalities. Let\'s explore a few of them below.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'Foot Traffic Analysis',
      desc : 'It\'s possible to understand how people are distributed within the facility. By incorporating heatmap technology, it becomes feasible to identify areas where people have congregated around particular artworks. In essence, the process of identifying preferred works becomes incredibly easy. Rather than conducting surveys, analyzing CCTV footage, or having managers roam around the facility, you can simply press the heatmap button.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'Asset Management',
      desc : 'Real-time location tracking isn\'t limited to people. It can also apply to unique assets that require special management, such as artworks, or to important equipment. Monitoring the movement of these assets in real-time helps prevent safety incidents and ensures more efficient movement and asset management. Furthermore, you can instantly locate desired assets through a search.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'Security',
      desc : 'Real-time inventory tracking enables faster lead times. With precise knowledge of inventory locations, you can quickly locate necessary items, reducing the time needed for production or delivery.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'',
      desc : 'These technologies offer diverse applications. Indoor navigation can be enhanced, and smart entry systems can be employed for safer and more efficient facility operations. Furthermore, these technologies can naturally promote an image of a smart and trendy exhibition hall when they become known to the public.',
      dot : ''
      
    },

  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc : 'This product uses UWB devices attached to workers or equipment to provide real-time location tracking assistance.',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : retailExhibition.thumb,
        data : 'retailExhibition',
        link : '/en/enterprise/retail/exhibition'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/en/enterprise/logistics/asset'
      }, 
      {
        img : officeAsset.thumb,
        data : 'officeAsset',
        link : '/en/enterprise/office/asset'
      }
    ]

  }


}
