import { logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@assets/images/solution/logisticsSafety/video/rtlsVideo.mp4"

export default {

    helmet: '| IPS（屋内位置情報システム）とは？',
    desc: 'IPSは、Indoor Positioning Systemの略であり、建物内でユーザーまたはオブジェクトの正確な位置を追跡するための技術です。 IPSはさまざまなアプリケーションで利用され、屋内ナビゲーション、自動化、セキュリティ、環境モニタリング、リソース管理など、さまざまな分野で必要です。',
    keywords: '',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'IPS（屋内位置情報システム）とは？ | 位置情報追跡の専門家、Orbro',
    ogUrl: 'https://orbro.io/jp/blog/indoor-positioning-system',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDk6TQ-h2KkQiYudK2spJrwM9-DVyXGqjpnyMQI2sZIQz1hxm14GXjbIy0_vPqBdcWNqBfevlZpTaHCDaKLTSInodrYeccUP3gpQNgAtrMwqEHlrjdh4WhikpPJ_iDLCi3PldIyG97g8uPiEGv2KbKurpE7JoCN5mRkfiH448dzhhpubMZxkB2ZKIJC7k8kNPYcSX1A-QOydJBw_yipl-pCZS_3oxhv2uYggjB0clzoEqTdYWRLydtMHV79DjszkbWJW3LTgrYEKIQP1Kbdw7ZItA-BwStqSuGehlMzlqxoFgQRChchtMnWac9VkUzOWnga-7_XUfmX90CtTqWRrFZg9Umjy8FX9288OQT_dXLNDDdowk_5t23b2mX-L7yW25zgxFHd3VtxgRongH9p94aLi4mMDO6fmT_8ajfcngVp9gOjPzHsmkOESsojVUQc706JPrK__OnMo7qr_aEVb15EIjZlqlJaJ4IhcIuXokNh-sp1nkFHuZ8qW_HXAB3FZu7r91T6rPAmKirnvFAtZdTghCXsX9nzmLybftlK1YYBWTAV0qcWkL5Ckpp2njt9pFjoXmTQqbdpz3duHY-AljIdMbCDzS444jPCLaKfXTkCmbV6WnZRaPfGR81_Q82hX3jxtuLKQmjMmCgLgCl3gp5L8CZi1hvvtQKMdcDSJ90ZUhsu2CZkmvwQ3Q_03VoJSlwwFZ3EUN3W_GB8e5cWXpNGvQccrSjeCnmIF9aVrHwtaIdWTvpskUJKjNzlqAafVUfTouYOSrV8OCfLPwSQTV8iSm2qnbRuoBigg9xouxN0bRwPkIiz67dGt4JKQcqivJnCoQxzvVomW1fVr4UbxMq6akImY3IxQ7ovGV81wA46n8tx5WPSPLMCEKQBK9FkzHiHqb8Pxxw9rzzuIa6TtoY6KRfti9zMv4fsmGqX6Q1_R5B-ABtExcOjVt-x_mS5Y3ZQR0jJIdKh4GEj98Pa9oTTF3saa28UWqTTjqRErmeVY04QTsy_SqFx9CyzU_2V1_zbdvez8hyZLuj5L9K-cvJyCKZTyBCrw0YzWwOX2MZ6fCyqvFzPfzzKJZRUncB0UrOVKw7dGDdDlaX5CqXBk-kR06RFpJIEf1fS0zEk2cBhS3NX2BU_PuueEkq1bQyLe9sG9kxqLXZ9jOc8ImIMr4X6JZgbKdO0-x0t_LkclmAjG3g4QuaNl63oDUgU1QUvQoQhDiCEHrK0xqXRaU7qSPbYXesuwkNkmOgVwPoJnNa-jYCoJ__RR4WZomXaiPckDuGTkSKx2dOfaSs7TbvIb7fqqcv5kVXnVeEtCheiIU220AnBQ6P4vLOzcQM3JP63j9eLnrIAgh2NMqXpyNVhSY5elNnry6i9vqriqjEwypIBg-eRxHD_S7iWd7gaTLIA_cu_QyeVrW7fYEMoc830qGVhoMH7VNFbffpm5PtC_9B2e2P4xe4fQIisHlT1tnhnFJynZshU2YAiNTWNWMEnpEsitAZMUPJ6oRGSQe_DK9xz-bmpANM-9WSuCR8vlKsLHuWX8A516ShbC0Y9wnZQrN3RK1Rt3xRlX=w2045-h1221',
    ogType: 'ウェブサイト',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'IPS（屋内位置情報システム）とは？',
    date: '2024-01-23',
    tag1: '#IPS',
    tag2: '#屋内位置情報',
    tag3: '#RTLS',
    tag4: '#UWB',

    // <br/>
    text: [
        {
            head: 'IPS（屋内位置情報システム）とは？',
            subHead: '',
            desc: 'IPSは、屋内の建物内でユーザーやオブジェクトの正確な位置を追跡する技術です。 IPSはさまざまなアプリケーションで使用され、屋内ナビゲーション、自動化、セキュリティ、環境モニタリング、リソース管理など、さまざまな分野で必要です。 GPSのような屋外の位置追跡システムが広く使用されていますが、屋内での正確な位置追跡はより複雑な課題です。この問題を解決するために開発された技術の1つがIPSです。',
            dot: ''
        },
        {
            head: 'IPSの重要性',
            subHead: '',
            desc: '現代社会でスマートフォンや他のモバイルデバイスの普及により、位置決定技術の重要性が急速に増加しました。この技術は、私たちが地図アプリケーションを使用して道を探したり、屋内で買い物をする際に効率的な情報を提供するのに重要な役割を果たします。この位置決定技術の1つが「屋内位置情報システム」またはIPSです。 IPSは、デバイスまたはユーザーの位置を正確に追跡および特定するための技術およびシステムであり、主に屋内環境で使用されます。 GPS（グローバルポジショニングシステム）とは異なり、建物内でも効果的に機能します。',
            dot: ''
        },
        {
            head: 'IPSの動作原理',
            subHead: '',
            desc: '',
            dot: {
                a: '<strong> • UWBテクノロジーの活用： </strong> UWBテクノロジーは近くのデバイスやタグとの距離を非常に正確に測定します。これを使用して、ユーザーとUWBタグ（例：スマートフォンまたはクレジットカード）間の距離を計算します。',
                b: '<strong> • BLE（Bluetooth Low Energy）：</strong> この方法は、室内の場所を特定するためにBluetooth LEビーコンを使用します。ビーコンは一意の識別子を送信し、この識別子を基に位置を推定します。高い精度を提供しますが、ビーコンの展開にはコストがかかる場合があります。',
                c: '<strong> • AIカメラの活用： </strong> AIカメラは、カメラから得た画像を分析して周囲の環境を理解し、オブジェクトまたは人物を検出できます。これにより、カメラはユーザーの位置と動きをリアルタイムで追跡します。',
                d: '<strong> • Wi-Fiベースの位置決定： </strong> Wi-Fiアクセスポイントの信号強度を基にユーザーの位置を決定します。各Wi-Fiアクセスポイントには一意のMACアドレスがあり、これを使用してユーザーの位置を特定します。ただし、精度が低い場合や信号の干渉や範囲制限がある場合があります。',
            }
        },
        {
            video: video,
            link: '/jp/enterprise/logistics/safety',
            videoDesc: 'Orbroロジスティクスデジタルツイン、クリックして移動します。',
            head: 'IPSテクノロジーの利点',
            subHead: '',
            desc: 'GPSは屋内では機能しないため、IPSは屋内位置情報の精度を向上させます。正確な位置データを基に、ユーザーや資産の正確な位置を特定できます。また、IPSはユーザーや資産をリアルタイムで追跡できるため、屋内環境でのリアルタイムモニタリングと管理が可能です。これは緊急時の対応を取るためや、重要な状況で資産を効率的に管理する際に重要です。<br/><br/>IPSを使用して、作業とプロセスを自動化および効率化できます。たとえば、自動車工場での自律型ロボットの位置決定にIPSを使用すると、生産性を向上させることができます。物流業界では、物流および資産管理を最適化し、コストを削減できます。また、病院、学校、空港などさまざまな場所で、IPSは緊急対応を向上させ、患者の追跡、学生およびスタッフの安全性確保、大規模なイベントの案内などに使用できます。',
            dot: ''
        },
        {
            head: 'IPSの応用例',
            subHead: '店内での屋内ナビゲーションとマーケティング',
            desc: '',
            dot: {
                a: ' • 顧客が店内で簡単に希望する商品を見つけるのをサポートします。',
                b: ' • 注文と支払いプロセスを簡素化して、顧客体験を向上させます。',
                c: ' • 顧客の位置を追跡し、製品のおすすめや割引情報を提供してマーケティングを改善します。'
            }
        },
        {
            head: '',
            subHead: '病院および医療施設内での患者追跡',
            desc: '',
            dot: {
                a: ' • 医療施設で患者を追跡し、リアルタイムでその位置をモニタリングします。',
                b: ' • 医療スタッフは患者を素早く見つけて緊急事態に備えることができます。',
                c: ' • 感染予防および医療機器管理に効果的なシステムとして使用されます。',
            }
        },
        {
            head: '',
            subHead: '空港内での旅客案内とセキュリティ',
            desc: '',
            dot: {
                a: ' • 旅客は空港内で搭乗ゲート、手荷物受取場、サービス施設を簡単に見つけるのに役立ちます。',
                b: ' • セキュリティ管理と待機列管理をサポートします。',
                c: ' • 適切な時間に旅客をゲートに案内して飛行スケジュールを遵守します。',
            }
        },
        {
            head: '',
            subHead: '屋内スポーツ施設およびエンターテイメント施設内の試合とイベント管理',
            desc: '',
            dot: {
                a: ' • 観客はスポーツアリーナ内で座席を見つけ、食事や飲み物を注文できます。',
                b: ' • リアルタイムの試合結果やチーム情報を提供してエンターテイメント体験を向上させます。',
                c: ' • 試合とイベントの管理を効率的に行います。',
            }
        },
        {
            head: '',
            subHead: '工場と倉庫内での物流および資産管理',
            desc: '',
            dot: {
                a: ' • 工場や倉庫内の機器、製品、資産を追跡し、その位置を特定します。',
                b: ' • 物流およびサプライチェーン管理を最適化して生産性を向上させます。',
                c: ' • 在庫管理および移動経路の最適化を支援します。',
            }
        },
        {
            head: '',
            subHead: '学校と大学キャンパス内での学生と教職員のガイダンス',
            desc: '',
            dot: {
                a: ' • 学生と教職員は、キャンパス内で教室、図書館、食堂、駐車場を簡単に見つけるのに役立ちます。',
                b: ' • 学生の出席を追跡し、教育資源の管理をサポートします。',
            }
        },
        {
            head: '',
            subHead: '屋内自律運転とロボット協働',
            desc: '',
            dot: {
                a: ' • 屋内位置決定技術は、自律型車両やロボットが正確な経路をたどるのを支援します。',
                b: ' • さまざまなタスクにおけるロボットの操作効率を向上させます。',
            }
        },
        {
            head: 'IPSの将来展望',
            subHead: '',
            desc: 'IPS技術は進化を続け、屋内位置決定の精度と効率を向上させると期待されています。5Gネットワークとの統合、人工知能と機械学習の導入、センサー技術の進化、その他の発展がIPSの未来に期待されています。より正確で迅速な位置決定が、さまざまな産業に革新と効率性をもたらすと予想されています。',
            dot: ''
        },

        {
            head: '',
            subHead: '',
            desc: '',
            dot: ''
        },
    ],
    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWBターミナルを作業者や設備に取り付けて、リアルタイムの位置追跡をサポートする製品。',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/jp/enterprise/logistics/asset'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/jp/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/jp/enterprise/manufacturing/asset'
            }
        ]
    }

}
