import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const VideoPage = styled.div`
  padding-top : 128px;


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    padding-top : 96px;

  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding-top : 96px;

  }
`

export const VideoHeaderText = styled.div`
  width : 100%;
  text-align : center;

`

export const VideoBox = styled.div`

  position: relative;
  width: 100%;
  display : flex;
  justify-content : center;
  overflow: hidden;
  
  border-radius: 8px;
  border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
  }
`

export const VideoPlay = styled.video`
  width: 100%;
  height: 100%;
  
`

