import React  from 'react';

import { StyledGridContainer } from '@components/Grid/Grid.style';
import {
  StyledFunContainer, StyledFunBox, StyledFun
  ,StyledImg, StyledTextBox
} from "./Func.style";

import rtls from '@src/lang/ko/main/rtls/rtls';
import { Trans } from 'react-i18next';
import { ParagraphColor, Spacing, SpacingDTM } from '@pages/CommonStyle/common.style';
import { DisplayH2 } from '@components/Text/HeadingTagDisplay.style';
import { HeadingH3 } from '@components/Text/Heading/Heading.style';

const Func = (): React.ReactElement => {


  const path = rtls.func.data

  return (
    <StyledGridContainer>
      <Spacing pixel='128' />
      <div>

        <DisplayH2 size='small'><Trans i18nKey={`rtls.func.title`} /></DisplayH2>
        <Spacing pixel='16' />
        <Spacing pixel='8' />
        <ParagraphColor color='#646464' size='medium'>
          <p>
            <Trans i18nKey={`rtls.func.desc`} />
          </p>
        </ParagraphColor>


        <StyledFunContainer>
          {path.map((item, index) => (
            <StyledFunBox key={index}>
              <StyledFun>
                <StyledImg src={item.img} alt='RTLS Function' />
                <StyledTextBox>
                  <HeadingH3 size='xsmall'><Trans i18nKey={`rtls.func.data.${index}.title`} /></HeadingH3>
                  <Spacing pixel='8' />
                  <ParagraphColor color='#646464' size='medium'>
                    <p>
                      <Trans i18nKey={`rtls.func.data.${index}.desc`} />
                    </p>
                  </ParagraphColor>
                </StyledTextBox>
              </StyledFun>
            </StyledFunBox>
          ))}
        </StyledFunContainer>

      </div>
      

    </StyledGridContainer>
  );
};
export default Func;

