import { StyledGridColumnBlank } from "@components/Grid/Grid.style";
import { CommonState } from "@redux/reducers/commonReducer";
import React, { useState, useCallback, PropsWithChildren, useEffect } from "react";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  StyledIconBox, StyledBlogDate, StyledBlogDateBox
  , StyledTagBox, StyledTag, StyledLinkedIn, StyledTwitter, StyledBack,
  StyledFacebook, StyledBlogParaSub, StyledTagBottom, StyledBlogShareBox,
  StyledSpacing64Border, StyledTagBoxBottom, StyledGridColumnBlog, StyledProductBox,
  StyledProductTextSide, StyledProductImgBox, StyledProductImg, StyledSolutionLayout,
  StyledSolutionImg, StyledSolutionBox, StyledSolutionTextBox, StyledShowMore, StyledBannerImg, 
  StyledBannerData, StyledIconBoxBottom, BlogImg, BlogImgBox, BlogVisual, BlogTextLink, BlogBannerText
} from "./BlogModule.style";

import { IconCalenderLine, IconArrowLeft, IconChevronRight } from "@assets/images/icon";
import { BanenrText, StyledBackLink, StyledGridContainer, StyledGridContainerAbsolute, StyledGridRowGap0, StyledTitleBanner } from "../Detail/BlogDetail.style";
import BlogNewLetter from "../Common/BlogNewLetter";
import BlogRecent from "../Common/BlogRecent";
import ModalDone from "@pages/Enterprise/Download/ModalDone/ModalDone";

import * as banner from "../Common/BlogBannerImg";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";


import { Link } from "react-router-dom";
import { setSelectedProduct, setSelectedValue } from '@redux/action-creators/common';
import { StyledHeading, StyledParagraph } from "@components/Text";
import { VideoBox, VideoPlay } from "@pages/Enterprise/Solution/components/Video/Video.style";
import { BorderBottomLine, ImgWH, LabelColor, ParagraphColor, Spacing, SpacingL, SpacingR } from "@pages/CommonStyle/common.style";
import { DivAllCenter, DivPadding, DivWidth100, FontPage } from "@pages/CommonStyle/commonDiv.style";

import { HeadingH2, HeadingH3 } from "@components/Text/Heading/Heading.style";
import { DisplayH1Blog, DisplayH2, DisplayH3 } from "@components/Text/HeadingTagDisplay.style";
import { FitAtag } from "@layouts/Default/components/NavBox/components/MenuTree/Tree3.style";
import i18n from "@pages/i18n";


interface ModalDefaultType {
  pageName: string
  path: any
  textList: any
}

const canlender = IconCalenderLine
const arrowLeft = IconArrowLeft


const BlogText = ({
  pageName, path, textList
}: PropsWithChildren<ModalDefaultType>) => {

  const currentLang = i18n.language;

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const page = 'blog' + pageName;

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const onSuccess = useCallback(() => {
    setSuccess(!isSuccess);
  }, [isSuccess]);

  const [pageUrl, setPageUrl] = useState('');


  const dispatch = useDispatch(); // 추가
  const handleIconClick = (value, productIndex) => {
    dispatch(setSelectedValue(value));
    dispatch(setSelectedProduct(productIndex)); // 추가: Redux 액션 디스패치
  };

  const [windowWidth, setInnerWidth] = useState<number>(1920);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPageUrl(window.location.href);
    }
    const handleResize = () => {
      const { innerWidth } = window;
      setInnerWidth(innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <Spacing pixel="48" />
      <FontPage>
        <DivWidth100>

          <Spacing pixel="16" />

          <StyledGridContainer>
            <StyledBack>
              <StyledBackLink href={currentLang !== 'ko' ? `/${currentLang}/blog` : "/blog"}>
                <ImgWH src={arrowLeft} alt="Left Arrow Icon" w={"20"} h={"20"} />
                <DivPadding a={"0"} b={"0"} c={"0"} d={"16"} />
                <StyledHeading size="xxxsmall">
                  <Trans i18nKey={"blog.back"} />
                </StyledHeading>
              </StyledBackLink>
            </StyledBack>


            <StyledTitleBanner >
              <StyledGridContainerAbsolute >
                <StyledBannerImg src={`${banner[page][layoutType]}`} alt="RTLS Solution" />
              </StyledGridContainerAbsolute>
              <BanenrText>
                <StyledBannerData>
                  <BlogBannerText>
                    <DisplayH1Blog size="medium">
                      <Trans i18nKey={path.title} />
                    </DisplayH1Blog>
                    <StyledBlogDateBox>
                      <ImgWH src={canlender} alt="Calender Icon" w={"20"} h={"20"} />
                      <StyledBlogDate size="medium">
                        <Trans i18nKey={path.date} />
                      </StyledBlogDate>

                    </StyledBlogDateBox>
                  </BlogBannerText>
                  <Spacing pixel="8" />

                  <StyledIconBox>
                    <StyledLinkedIn >
                      <LinkedinShareButton url={pageUrl}>
                        <LinkedinIcon size={32} round={true} borderRadius={16}></LinkedinIcon>
                      </LinkedinShareButton>
                    </StyledLinkedIn>

                    <StyledTwitter >
                      <TwitterShareButton url={pageUrl}>
                        <TwitterIcon size={32} round={true} borderRadius={16}></TwitterIcon>
                      </TwitterShareButton>
                    </StyledTwitter>

                    <StyledFacebook >
                      <FacebookShareButton url={pageUrl}>
                        <FacebookIcon size={32} round={true} borderRadius={16}></FacebookIcon>
                      </FacebookShareButton>
                    </StyledFacebook>

                  </StyledIconBox>


                  <StyledTagBox>
                    <StyledTag size="medium">
                      <Trans i18nKey={path.tag1} />
                    </StyledTag>
                    <StyledTag size="medium">
                      <Trans i18nKey={path.tag2} />
                    </StyledTag>
                    <StyledTag size="medium">
                      <Trans i18nKey={path.tag3} />
                    </StyledTag>
                    <StyledTag size="medium">
                      <Trans i18nKey={path.tag4} />
                    </StyledTag>
                  </StyledTagBox>
                </StyledBannerData>
              </BanenrText>
            </StyledTitleBanner>


            {textList.map((textNumb, index) => {

              return (
                <StyledGridRowGap0 key={index}>

                  <StyledGridColumnBlank desktop={1} tablet={1} mobile={1} />
                  <StyledGridColumnBlog desktop={10} tablet={8} mobile={4}>
                    <BlogVisual >
                      {textNumb?.img && (textNumb?.link ?
                        <a href={`${textNumb.link}`} target="_blank" rel="noopener noreferrer">
                          <BlogImgBox>
                            <BlogImg src={textNumb?.img} alt="RTLS Indoor Tracking" />
                          </BlogImgBox>
                        </a>
                        :
                        <DivAllCenter >
                          <BlogImg src={textNumb?.img} alt="RTLS Indoor Tracking" />
                        </DivAllCenter>)
                      }
                      {textNumb?.video && textNumb?.link &&
                        <a href={`${textNumb.link}`} target="_blank" rel="noopener noreferrer">
                          <Spacing pixel="24" />
                          <VideoBox>
                            <VideoPlay autoPlay loop muted playsInline>
                              <source src={textNumb?.video} type="video/mp4" />
                            </VideoPlay>
                          </VideoBox>
                        </a>
                      }
                      {textNumb?.youtube && (
                        <div>
                          <Spacing pixel="24" />
                          <iframe
                            title="YouTube Video"
                            width={windowWidth / 2.2}
                            height={windowWidth / 4}
                            src={`${textNumb.youtube}?autoplay=1`}
                            frameBorder="0"
                            allowFullScreen
                          />
                        </div>
                      )}

                    </BlogVisual>
                    {
                      textNumb?.videoDesc &&
                      <>
                        <Spacing pixel="4" />
                        <DivAllCenter>
                          <ParagraphColor size="medium" color="#BDBDBD">
                            <p>
                              <Trans i18nKey={textNumb.videoDesc} />
                            </p>
                          </ParagraphColor>
                        </DivAllCenter>
                      </>
                    }
                    <Spacing pixel="64" />

                    {
                      textNumb.head &&
                      <>
                        <HeadingH2 size={"small"}>
                          <Trans i18nKey={textNumb.head} />
                        </HeadingH2>
                        <Spacing pixel="8" />
                      </>
                    }

                    {
                      textNumb.subHead &&
                      <>
                        <HeadingH3 size="xxsmall" >
                          <Trans i18nKey={textNumb.subHead} />
                        </HeadingH3>
                        <Spacing pixel="8" />
                      </>
                    }

                    {
                      textNumb.linkText &&
                      <>
                        <FitAtag href={textNumb.link} mt={"0"}>
                          <BlogTextLink size="xxsmall" >
                            <Trans i18nKey={textNumb.linkText} />
                          </BlogTextLink>
                          <Spacing pixel="8" />
                        </FitAtag>
                      </>
                    }

                    {
                      textNumb.desc &&
                      <StyledParagraph size="medium" >
                        <p>
                          <Trans i18nKey={textNumb.desc} />
                        </p>
                      </StyledParagraph>
                    }


                    {
                      Object.keys(textNumb.dot || {}).map((key) => (
                        <StyledBlogParaSub size="medium" key={key} >
                          <p>
                            <Trans i18nKey={`${page}.text.${index}.dot.${key}`} />
                          </p>
                        </StyledBlogParaSub>
                      ))
                    }

                  </StyledGridColumnBlog>
                </StyledGridRowGap0>
              )
            })}

            {layoutType !== "desktop" &&
              <>
                <StyledSpacing64Border />
                <Spacing pixel="64" />
              </>
            }

            {/* 관련 제품 */}
            <StyledGridRowGap0>
              <StyledGridColumnBlank desktop={1} tablet={1} mobile={1} />
              <StyledGridColumnBlog desktop={10} tablet={8} mobile={4}>
                {path.link.product &&
                  <>

                    {layoutType === "desktop" &&
                      <>
                        <StyledSpacing64Border />
                        <Spacing pixel="64" />
                      </>
                    }


                    {/* 관련 제품 */}
                    <HeadingH2 size="xsmall"> <Trans i18nKey={`blog.product`} /> </HeadingH2>
                    <StyledProductBox>

                      <StyledProductTextSide>
                        <StyledHeading size="xxxxsmall"> <Trans i18nKey={`${page}.link.product.0.text`} /> </StyledHeading>
                        <Spacing pixel="4" />
                        <DisplayH3 size="small"> <Trans i18nKey={`${page}.link.product.0.title`} /> </DisplayH3>

                        <Spacing pixel="4" />
                        <LabelColor size="small" color='#818181'>  <Trans i18nKey={`${page}.link.product.0.serial`} /> </LabelColor>
                        <Spacing pixel="16" />
                        <ParagraphColor size="medium" color='#818181'>
                          <p>
                            <Trans i18nKey={`${page}.link.product.0.desc`} />
                          </p>
                        </ParagraphColor>
                        <Link to={i18n.language === 'ko' ? `/${path.link.page}` : `/${i18n.language}/${path.link.page}`}>
                          <StyledShowMore onClick={() => handleIconClick(path.link.numb, 1)}>
                            <LabelColor size="medium" color='#2A70F0'><Trans i18nKey={`home.showMore`} /> </LabelColor>
                            <DivPadding a={"0"} b={"0"} c={"0"} d={"4"} />
                            <ImgWH src={IconChevronRight} alt="Right Arrow Icon" w={"20"} h={"20"} />
                          </StyledShowMore>
                        </Link>

                      </StyledProductTextSide>

                      <StyledProductImgBox>
                        <StyledProductImg src={path.link.product[0].img} alt="ORBRO Rtls Product" />
                      </StyledProductImgBox>

                    </StyledProductBox>

                  </>
                }

              </StyledGridColumnBlog>
            </StyledGridRowGap0>

            {layoutType !== "mobile" ? (
              <>
                <StyledSpacing64Border />
                <Spacing pixel="64" />
              </>)
              :
              (<>
                <SpacingL pixel={"16"}>
                  <SpacingR pixel={"16"}>
                    <BorderBottomLine />
                  </SpacingR>
                </SpacingL>
                <Spacing pixel="64" />
              </>)
            }


            {/* 솔루션 소개 */}
            {path.link.solution && (
              <>
                <DivAllCenter>
                  <DisplayH2 size="small"> <Trans i18nKey={`blog.solution`} /> </DisplayH2>
                </DivAllCenter>

                <Spacing pixel='32' />
                <StyledSolutionLayout>
                  {path.link.solution.map((name, index) => (
                    <StyledSolutionBox key={index} >
                      <Link to={i18n.language === 'ko' ? `${name.link}` : `/${i18n.language}${name.link}`}>
                        <StyledSolutionImg src={name.img} alt="ORBRO RTLS Indoor Tracking" />
                        <StyledSolutionTextBox>
                          <Spacing pixel="24" />
                          <HeadingH3 size="xsmall">
                            <Trans i18nKey={`solutionContents.${name.data}.title`} />
                          </HeadingH3>
                          <Spacing pixel="8" />
                          <StyledParagraph size="small">
                            <p>
                              <Trans i18nKey={`solutionContents.${name.data}.desc`} />
                            </p>
                          </StyledParagraph>
                        </StyledSolutionTextBox>
                      </Link>
                    </StyledSolutionBox>
                  ))}
                </StyledSolutionLayout>
              </>
            )}


            <StyledBlogShareBox size="xxsmall">
              <Trans i18nKey={`blog.share`} />
            </StyledBlogShareBox>


            {isSuccess && (
              <ModalDone onClickToggleModal={onSuccess}>
              </ModalDone>
            )}


            <StyledIconBoxBottom>

              <StyledLinkedIn >
                <LinkedinShareButton url={pageUrl}>
                  <LinkedinIcon size={32} round={true} borderRadius={16}></LinkedinIcon>
                </LinkedinShareButton>
              </StyledLinkedIn>

              <StyledTwitter >
                <TwitterShareButton url={pageUrl}>
                  <TwitterIcon size={32} round={true} borderRadius={16}></TwitterIcon>
                </TwitterShareButton>
              </StyledTwitter>


              <StyledFacebook >
                <FacebookShareButton url={pageUrl}>
                  <FacebookIcon size={32} round={true} borderRadius={16}></FacebookIcon>
                </FacebookShareButton>
              </StyledFacebook>

            </StyledIconBoxBottom>


            <StyledTagBoxBottom>
              <StyledTagBottom size="medium">
                <Trans i18nKey={`${page}.tag1`} />
              </StyledTagBottom>
              <StyledTagBottom size="medium">
                <Trans i18nKey={`${page}.tag2`} />
              </StyledTagBottom>
              <StyledTagBottom size="medium">
                <Trans i18nKey={`${page}.tag3`} />
              </StyledTagBottom>
              <StyledTagBottom size="medium">
                <Trans i18nKey={`${page}.tag4`} />
              </StyledTagBottom>
            </StyledTagBoxBottom>

            <BlogNewLetter onSuccess={onSuccess} />
            <StyledSpacing64Border />

            <BlogRecent />


          </StyledGridContainer>
        </DivWidth100>
      </FontPage>
    </>
  )
}

export default BlogText; 