import i18n from "@pages/i18n";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const CommonLang = () => {
  const location = useLocation();
  const isEnglishPage = location.pathname.startsWith('/en/');
  const isJapanPage = location.pathname.includes('/jp/');
  const isChinaPage = location.pathname.includes('/cn/');
  const navigate = useNavigate();
  useEffect(() => {
    if (isEnglishPage) {
      i18n.changeLanguage("en").then(() => {
        navigate("", { replace: true });
      });
    } else if (isJapanPage) {
      i18n.changeLanguage("jp").then(() => {
        navigate("", { replace: true });

      });
    } else if (isChinaPage) {
      i18n.changeLanguage("cn").then(() => {
        navigate("", { replace: true });

      });
    } else {
      i18n.changeLanguage("ko").then(() => {
        navigate("", { replace: true });
      });
    }
  }, [location.pathname]);
}


export default CommonLang;