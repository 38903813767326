import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../../jp/main/product/productImg";
import productPurchase from "../../jp/main/product/productPurchase";
import video from "@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4";


export default {
  helmet: '| RTLSヘルスケア、即座に使用可能',
  desc: '患者の位置をリアルタイムで追跡し、医療機器や人員を効率的に管理することで、医療現場での作業効率を向上させ、患者の安全性を高める上で重要な役割を果たします。',
  keywords: '患者の位置追跡、患者管理、病院の位置追跡、病院管理、スマート病院、患者追跡、医療施設管理、認知症患者管理、アルツハイマー患者管理、高齢患者管理、病院運営、医療センター、介護施設管理、ORBRO、Kongtech、RTLS、orbro',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RTLSヘルスケア、即座に使用可能:: ORBROブログ',
  ogUrl: 'https://orbro.io/jp/blog/rtlsHealthcare',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxCulBiV7gg1-xmVx62eta4ZCVo7bPai3vj9SN8ajL7XdyxO5_DwTc9AopMRDeHnIP1JRQ2aZs8YXRVegMIQ3XJAzQpGZPe7LsKZRYfG2TTKDwuITV4QU1esZgJZjvVrWFNk7tdYgfPUmag4WVUhkZm9kN2q9I3i-i7g39hykefddJoB-GGyLstWZXHZPGouEpHRHO-QSDUlk-5bfgHEXekgkw8khERLg03ek2jr9aZVu73vARqYrCfRjmXxpjdzbezfvYPPNqyHHEqz7ryYxrceLzeUag7SeXqCikIehVE0j-teEEB0twcIKzxDhQ926mrdivrYUsLL7Y73cfXmCIoQrwGuj_PWfjkx8---GZuGMDu2T0xCegVf_ixBlv-_DP0s4PYYug15P9K4RRQcy8wzkEIe8DXRrNFJxK06VwMVNt7IvsyGuGpztnYoPe9DTJVmh6DgvpkLVfrWOQROJ1mY3wPnWoRGDONddVgsZOg9IFlZ8XDLxhGIwf9_NUadZ72Sx1uku7HzFPuRiPUi_jEHwpZssTqer0dHqRrmEiUm7WIfEvksaY5tlVEdKCkt6rieJQk6XkQ7HLkH9Sft-gtWyMIbXXc-ezr8CI1QW-BSqTqW1u1RHOH_U8qsyeAMRsLziuReFdGt94MU0XLtJQjzm470ZW0Lyliq6-D-ACBxago-nL7bW3hByy9u4qrPcseGt9nDBtxez21nzH4j-kk-ikAGdM2lqgNjENR3W3iBBYZxCLEgg5FaWno-JnWFODzJ2EPDkL9YgV3eJXaSav9q-K9OGrHtGcdLoOoclYQdW6HGei2jvEzIvkJ7fOrE_BkaMDvwfP_mG1Wyfmazl4ZT-E3REcF7-H8fvG1Maj9lGE47sI9NaaLiuKxMUKkoOq7ATE-CsA56qjq0om1fHUHtL3k5dJaPw5dqFFc_I1pmQF2l6v32K36AQbL6URjYW2TRB5R_7PdZbxPltDvqVhv26deM1LrtslNSaQ1y8-v60TTOuJzwdCsBF2-a1Cgad2aG4c0gxmrniSlWna_ih_LT7LTZ78ekaoZK-QZbGYBhztrbJH8pG6OtYsABLu_EJbLwCZ8gemIFAL2str5ljuZjxGa8baorSqsutD4ZWG98EcUImwgTwfsXrg_fNe0Uyl2TmD1Jnr-cpdFvRHT9CtlmxJL0XV3CuWIB36kk7Sj451LQT1d3CUGDUYSob_cs6XtFvl8zo1L1iJO3CbaIL-X7qva6sYYjidWIQqBIwseA6TVk4ZZ4AB1lm8OMbmP-VwUqs4EahKtumqMiQXZoiUFyXyTKLz-soVKV_uVW4R9xcXy-pUYHMsYmRM2uDVc0eCkPkdr5NoidDFKrf03A4kPR2s3URy-bZdCORxE0KgtlQLx1eKtVi-Ku88pwYGe1IECBztZxTUfhisovrg-CZu2B3OOTHLZEZ3DTwH9ORlbfLRdJtLl21EkzHYHX485uwK40pglWChiD4G58da5pMDp2-tZtgrkAaEUw-gf3FoUya3HD4ywea6Tzxbua0wg9oJfPQYB4jUYPE8kQiPVTpz4TgFYI8iwU9sF8yUIRbW5c7kWjwIp=w2000-h1596',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RTLSヘルスケア、即座に使用可能',
  date: '2023-11-02',
  tag1: '#ヘルスケア',
  tag2: '#患者の位置追跡',
  tag3: '#スマート医療',
  tag4: '#患者管理',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: 'RTLS（リアルタイムロケーションシステム）技術は、現代の医療分野を革命ひと巡りしています。この技術は患者の位置をリアルタイムで追跡し、医療機器と人員を効率的に管理し、医療施設での業務効率と患者の安全性を向上させる大きな役割を果たします。この記事では、RTLSヘルスケア技術の重要性と即時の利点について探究します。',
      dot: ''

    },
    {
      video : video,
      link : 'https://orbro.io/jp/enterprise/medical/patient',
      head: '今すぐソリューションを実装する場合',
      subHead: '患者の安全性の向上',
      desc: 'RTLSは患者の位置追跡を通じて患者の安全性を向上させるための主要手段です。患者の位置をリアルタイムで追跡することで、医療スタッフは患者の状態を迅速に評価し、緊急事態に即座に対応することができ、緊急事態での致命的な誤りを減少させます。',
      dot: ''
    },
    {
      head: '',
      subHead: 'リソース管理の改善',
      desc: 'RTLSは医療機器とスタッフの効率的な管理を可能にします。医療機器のリアルタイムの位置情報を取得することで、必要な機器を迅速に取得し、効率的な保守と交換作業を行うのに役立ちます。さらに、医療スタッフのスケジュールを最適化し、スケジュールの競合を回避し、患者のケアに集中することができます。',
      dot: ''
    },
    {
      head: '',
      subHead: '感染防止',
      desc: 'RTLSは効果的に病院内の患者の移動を管理し、感染症の拡散を防ぎ、病院内での患者間の接触を最小限に抑えるのに役立ちます。これにより感染病の拡散を制限し、患者と医療スタッフの両方の安全を確保します。',
      dot: ''
    },
    {
      head: '',
      subHead: '財務管理',
      desc: 'RTLSの導入により、効率的なリソース利用とコスト削減が可能です。不要な機器の購入や在庫の無駄を防ぎ、労働コストを最適化します。これにより病院の財務状況が改善し、効率的な予算利用が可能となります。',
      dot: ''
    },
    {
      head: '',
      subHead: 'リアルタイム情報',
      desc: 'RTLSはリアルタイムの位置情報を提供し、医療スタッフがより迅速で効果的な判断を行うのに役立ちます。患者の状態が変化した場合、即座に対応でき、治療プロセスを改善し、患者に最適な結果を提供します。',
      dot: ''
    },
    {
      head: '',
      subHead: 'コスト効率',
      desc: 'RTLSはコスト効率の高いソリューションを提供し、時間とリソースを節約し、組織の財務状況を改善します。投資に対する迅速なリターンが得られ、長期的なコスト効率を高めることができます。',
      dot: ''
    },
    {
      head: '',
      subHead: '患者満足度の向上',
      desc: '患者の位置追跡を通じて、患者はより個別のケアを受けることができ、安全性と便益に対する高い満足度を実感します。これは患者とその家族に信頼と安心感を提供します。',
      dot: ''
    },
    {
      head: 'まとめると、',
      subHead: '',
      desc: 'RTLSヘルスケア技術は現代の医療分野に重要な革新をもたらし、即座に実装することで患者の安全性と効率性を向上させることができます。実装は簡単で、迅速な結果を提供するため、医療機関と患者の両方に利益をもたらす重要な技術と言えます。これにより将来の医療はより安全で効率的に提供されることが期待されています。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWBデバイスを作業者や機器に取り付けてリアルタイムの位置追跡を支援する製品です。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/jp/enterprise/medical/patient'
      },
      {
        img: medicalWaste.thumb,
        data: 'medicalWaste',
        link: '/jp/enterprise/medical/waste'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/jp/enterprise/office/asset'
      }
    ]

  }
}
