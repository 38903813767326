import { HeadingColor, ImgWH, LabelColor, Spacing, SpacingDTM } from "@pages/CommonStyle/common.style";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { FileBox, FileBtn, FormGrid, FranchiseMemo, FranchiseOptionBtn, InvisibleInput, OptionSelect, SubmitBtn } from "../Franchise.style";
import franchise from "@src/lang/ko/support/franchise";
import { RequestInputBox } from "@pages/Enterprise/Solution/components/Request/Request.style";
import {
  ClearButton, ClearIcon, DialogInput, DialogInputText,
  StyledDownloadPolicyBox,
  StyledDownloadPolicyLinkText,
  StyledUnderline, DialogCheckBox, StyledDownloadPolicyLink
} from "@pages/Main/Common/Purchase/Modal/Modal.style";
import { Div100flex, DivAllCenter, DivFlexRowGap } from "@pages/CommonStyle/commonDiv.style";
import { IconCheckBoxEmpty, IconCheckBoxOn, IconCloseIcon } from "@assets/images/icon";
import ArrowChevronUpLight from '@assets/icons/ic-arrow-chevron-up-light.svg';
import ArrowChevronDownLight from '@assets/icons/ic-arrow-chevron-down-light.svg';
import Dropdown from "./Dropdown/Dropdown";
import { StyledRequestFormGroup } from "@pages/Enterprise/Request/components/RequestForm/RequestForm.style";
import { StyledLabel } from "@components/Text";
import emailjs from 'emailjs-com';


const RequestForm = () => {

  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [registNumber, setRegistNumber] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [memo, setMemo] = useState<string>('');
  const [checked, setChecked] = useState(false);

  const [isOpenRegion, setOpenRegion] = useState<boolean>(false);
  const [isOpenCategory, setOpenCategory] = useState<boolean>(false);

  const orbro = 'https://orbro.io';
  //  const orbro = 'http://localhost'

  const policyCheck = () => {
    setChecked(!checked);
  };


  const handleInput = (event, callback) => {
    const value = event.target.value;
    callback(value);
  }

  const [focus, setFocus] = useState({
    name: false,
    phone: false,
    company: false,
    registNumber: false,
  });

  const resetData = () => {
    setName('');
    setPhone('');
    setCompany('');
    setRegistNumber('');
    setRegion('');
    setCategory('');
    setFileInfo(``);
    setAttachment(null);
    setMemo('');
    setChecked(false);
    setFocus({
      name: false,
      phone: false,
      company: false,
      registNumber: false,
    });
  };


  const handleFocus = (field) => {
    setOpenRegion(false);
    setOpenCategory(false);
    setFocus((prevFocus) => {
      const updatedFocus = {} as {
        name: boolean,
        phone: boolean,
        company: boolean,
        registNumber: boolean,
      };
      for (const key in prevFocus) {
        updatedFocus[key] = key === field;
      }
      return updatedFocus;
    });
  };


  const handleKeyDown = (e, currentField) => {
    if (e.key === "Tab") {
      e.preventDefault();

      const fields = ["name", "phone", "company", "registNumber", "region", "category"];
      const currentFieldIndex = fields.indexOf(currentField);
      const nextFieldIndex = currentFieldIndex === fields.length - 1 ? 0 : currentFieldIndex + 1;
      const nextField = fields[nextFieldIndex];

      const nextInputElement = document.getElementById(`${nextField}-input`);
      if (nextInputElement) {
        nextInputElement.focus();
      }
    }
  };

  const handleClear = (i) => {
    switch (i) {
      case 0:
        setName('');
        break;
      case 1:
        setPhone('');
        break;
      case 2:
        setCompany('');
        break;
      case 3:
        setRegistNumber('');
        break;
      case 4:
        setRegion('');
        break;
      case 5:
        setCategory('');
        break;
    }
  };
  const [attachment, setAttachment] = useState<File | null>(null);

  const [files, setFiles] = useState([]);
  const [fileInfo, setFileInfo] = useState<string>(``);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFiles(Array.from(e.target.files));
    if (file) {
      const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file.size > maxFileSize) {
        alert('10mb 미만의 파일을 업로드 해주세요.');
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
          const base64 = reader.result;
          if (base64) {
            const base64Sub = base64.toString();
            setFileInfo(base64Sub);
          }
        };
        setAttachment(file);
      }
    }
  };

  const handleOpen = (type) => {
    if (type === 'region') {
      setOpenRegion(!isOpenRegion);
      setOpenCategory(false);
    } else if (type === 'category') {
      setOpenRegion(false);
      setOpenCategory(!isOpenCategory);
    }

  }


  const handleData = (data, type) => {
    if (type === 'region') {
      setRegion(data);
    } else if (type === 'category') {
      setCategory(data);
    }
    setOpenRegion(false);
    setOpenCategory(false);
  }

  const [place_holder, setPlace_holder] = useState({
    name: '홍길동',
    phone: '-없이 숫자만',
    company: '-없이 숫자만',
    registNumber: '회사명 또는 업체명',
    region: '시/도 선택',
    category: '필수 분야',
    memo: '1,000자 이내로 입력 (필수)',
    emptyMsg: '비어있는 값이 있습니다',
    successMsg: '문의 주셔서 감사합니다. 빠른 시일 내로 연락드리겠습니다.'

  });


  const [sendable, setSendable] = useState<boolean>(false);
  useEffect(() => {
    if (name && phone && company && region && registNumber && category && memo && checked) {
      setSendable(true);
    } else {
      setSendable(false);
    }
  }, [name, phone, company, region, registNumber, category, memo, checked])


  const submit = () => {
    if (sendable) {
      alert(place_holder.successMsg);
      //이메일 전송 & 첨부파일
      nodemailer();
      //노션 업데이트
      notionSubmit(name, phone, company, registNumber, region, category, memo);
      //clear data
      resetData();

    } else {
    }

  }


  const notionSubmit = async (name, phone, company, registNumber, region, category, memo) => {

    const currentDateTime = new Date();

    try {
      const response = await fetch(orbro + "/partnershipNotion", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name: name,
          phone: phone,
          company: company,
          registNumber: registNumber,
          region: region,
          category: category,
          memo: memo,
          attachment: attachment?.name ? 'O (메일 참조)' : "X",
          currentTime: currentDateTime.toLocaleString('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // 24시간 형식
            timeZone: 'Asia/Seoul', // 한국 시간대
          })
        })
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  const sendEmail = async () => {

    emailjs.init('SoQtE3CThBY_wpeDn');
    const mailForm = {
      name: name,
      phone: phone,
      company: company,
      registNumber: registNumber,
      region: region,
      category: category,
      memo: memo,
    }

    emailjs.send('service_7yv5b7d', 'template_uhm9geh', mailForm)
      .then((response) => {
        console.log('이메일 보내기 성공:', response);
      })
      .catch((error) => {
        console.error('이메일 보내기 실패:', error);
      });

  }


  const nodemailer = async () => {
    const fileName = attachment?.name;

    try {

      const response = await fetch(orbro + "/nodemailer", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name: name,
          phone: phone,
          company: company,
          registNumber: registNumber,
          region: region,
          category: category,
          memo: memo,
          fileName: fileName,
          fileData: fileInfo,
        })
      });
      console.log("Client Success");
    } catch (error) {
      console.log('error', error);
    }
  }


  return (
    <>
      <Spacing pixel="64" />
      <HeadingColor color={"#000"} size={"medium"}>
        <Trans i18nKey={`franchise.requestForm.title`} />
      </HeadingColor>
      <Spacing pixel="24" />

      <FormGrid>
        {franchise.requestForm.input.map((data, i) => (
          <>
            {data.type === '0' ?
              <RequestInputBox inputValue={focus[data.tag]} key={i}>
                <DialogInputText inputValue={focus[data.tag]}> {(data.label)} </DialogInputText>
                <Spacing pixel="4" />
                <Div100flex>
                  <DialogInput
                    value={(() => {
                      switch (i) {
                        case 0: return name;
                        case 1: return phone;
                        case 2: return company;
                        case 3: return registNumber;
                        default: return '';
                      }
                    })()}
                    id={`${data.tag}-input`}
                    placeholder={place_holder[data.tag] || ''}
                    onInput={(e) => {
                      handleInput(e, (val) => {
                        switch (i) {
                          case 0: setName(val); break;
                          case 1: setPhone(val); break;
                          case 2: setCompany(val); break;
                          case 3: setRegistNumber(val); break;
                        }
                      })
                    }}
                    onClick={() => handleFocus(data.tag)}
                    onKeyDown={(e) => handleKeyDown(e, data.tag)}
                  />
                  {(() => {
                    switch (i) {
                      case 0: return name;
                      case 1: return phone;
                      case 2: return company;
                      case 3: return registNumber;
                      default: return '';
                    }
                  })() !== '' && focus[data.tag] && (
                      <ClearButton onClick={() => handleClear(i)}>
                        <ClearIcon src={IconCloseIcon} alt="close icon" />
                      </ClearButton>
                    )}
                </Div100flex>
              </RequestInputBox>
              :
              <>
                <FranchiseOptionBtn inputValue={focus[data.tag]}
                  onClick={() => handleOpen(data.tag)}>
                  <DialogInputText inputValue={focus[data.tag]}> {(data.label)} </DialogInputText>
                  <Spacing pixel="4" />
                  <Div100flex>
                    <OptionSelect onClick={() => handleFocus(data.tag)}>
                      {
                        data.tag === 'region' &&
                        <LabelColor color={region ? "#000" : "#BDBDBD"} size={"medium"}>
                          <Trans i18nKey={region || `franchise.requestForm.input.${i}.place_holder`} />
                        </LabelColor>
                      }
                      {
                        data.tag === 'category' &&
                        <LabelColor color={category ? "#000" : "#BDBDBD"} size={"medium"}>
                          <Trans i18nKey={category || `franchise.requestForm.input.${i}.place_holder`} />
                        </LabelColor>
                      }

                    </OptionSelect>
                    <ImgWH w={"12"} h={"12"}
                      src={data.tag === 'region'
                        ? (isOpenRegion ? ArrowChevronUpLight : ArrowChevronDownLight)
                        : (data.tag === 'category'
                          ? (isOpenCategory ? ArrowChevronUpLight : ArrowChevronDownLight)
                          : ArrowChevronDownLight)}
                    />
                  </Div100flex>

                </FranchiseOptionBtn>
              </>
            }
          </>
        ))}

      </FormGrid>
      {isOpenRegion && <Dropdown type={'region'} onClickToggleModal={handleData} />}
      {isOpenCategory && <Dropdown type={'category'} onClickToggleModal={handleData} />}



      <Spacing pixel="64" />

      <HeadingColor color={"#000"} size={"medium"}>
        <Trans i18nKey={`franchise.file.title`} />
      </HeadingColor>
      <Spacing pixel="24" />


      <StyledRequestFormGroup>
        <FranchiseMemo
          value={memo}
          placeholder={place_holder.memo || ''}
          onInput={(e) => { handleInput(e, setMemo) }}
        />
      </StyledRequestFormGroup>
      <Spacing pixel="16" />

      <DivFlexRowGap gap={16}>
        <FileBox>
          <LabelColor color={attachment?.name ? '#000' : "#BDBDBD"} size={"medium"}>
            <Trans i18nKey={attachment?.name || `첨부파일/10mb이하`} />
          </LabelColor>
        </FileBox>
        <FileBtn color={"#2A70F0"} as="label" htmlFor="file-upload">
          <InvisibleInput
            type="file"
            id="file-upload"
            name="te"
            onChange={handleFileChange}
          />
          <LabelColor color={"#fff"} size={"large"}>
            <Trans i18nKey={`franchise.file.btn`} />
          </LabelColor>
        </FileBtn>
      </DivFlexRowGap>

      <Spacing pixel="32" />

      <StyledDownloadPolicyBox>
        <DialogCheckBox src={checked ? IconCheckBoxOn : IconCheckBoxEmpty} onClick={policyCheck} alt="checkbox" />
        <StyledLabel size="medium"><Trans i18nKey={"download.form.policy.check"} />  </StyledLabel>
        <StyledDownloadPolicyLink href="/terms/2" target="_blank" >
          <StyledDownloadPolicyLinkText size="medium"><Trans i18nKey={"download.form.policy.link"} /></StyledDownloadPolicyLinkText>
          <StyledUnderline />
        </StyledDownloadPolicyLink>
        <StyledLabel size="medium"><Trans i18nKey={"download.form.policy.agree"} /> </StyledLabel>
      </StyledDownloadPolicyBox>

      <Spacing pixel="64" />

      <DivAllCenter>
        <SubmitBtn color={sendable ? '#2A70F0' : '#BFD4FA'}
          onClick={submit}>
          <LabelColor color={"#fff"} size={"large"}>
            <Trans i18nKey={`franchise.submit`} />
          </LabelColor>
        </SubmitBtn>
      </DivAllCenter>

      <SpacingDTM d={"128"} t={"96"} m={"96"} />
    </>
  )
}


export default RequestForm;