import { StyledHeading } from "@components/Text";
import React, { useEffect } from "react";
import { StyledFaqItemDetail, StyledFaqItemTitleIcon,
  StyledSpecTitleBox, StyledSpecDescBox } from "./Item.style";

import ArrowUpIcon from "@assets/images/services/common/arrow-chevron-up.svg";
import ArrowDownIcon from "@assets/images/services/common/arrow-chevron-down.svg";

import { Trans } from "react-i18next"
import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";
import { StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import { useLocation } from "react-router-dom";
import i18n from "@pages/i18n";
import { StyledFaqItem, StyledFaqItemDescription, StyledFaqItemTitle } from "../Faq/Item.style";

interface FaqItemProps {
  title: string,
  description: { title: string; description: string; }[];
  isOpen: boolean,
  path : string,
  i : number
  handleClick: () => void;
}

const SpecItem = (props: FaqItemProps):React.ReactElement => {
  const { description, isOpen, path, i, handleClick } = props;
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  
  const name = `productSpec.product.${path}`;
  const dataPath = `${name}.spec`;


  const location = useLocation();
  const isEnglishPage = location.pathname.startsWith('/en');
  const isJapanPage = location.pathname.startsWith('/jp');
  const isChinaPage = location.pathname.startsWith('/cn');


  useEffect(() => {
    
    if (isEnglishPage ) {
      i18n.changeLanguage('en').then(() => {
      });
    } else if (isJapanPage ) {
      i18n.changeLanguage('jp').then(() => {
        
      });
    } else if (isChinaPage){
      i18n.changeLanguage('cn').then(() => {
        
      });
    }else {
      i18n.changeLanguage('ko').then(() => {
      });
    }
  },[location.pathname]);
  

  return (
    <StyledFaqItem >
      <StyledFaqItemTitle onClick={handleClick}>
        <StyledHeading size={layoutType === 'mobile' ? 'xxxsmall' : 'xxxsmall'}><Trans i18nKey={`${dataPath}.${i}.name`}/></StyledHeading>

        <StyledFaqItemTitleIcon>
          <img src={isOpen ? ArrowUpIcon : ArrowDownIcon} alt="faq-item-title-img" />
        </StyledFaqItemTitleIcon> 
      </StyledFaqItemTitle>

      <StyledFaqItemDetail isOpen={isOpen}>
        {description.map((item, index) => {
          if (item.title) {
            return (
              <StyledGridRow key={`faq-item-detail-${index}`}>

                <StyledGridColumn desktop={3} tablet={2} mobile={1}>
                  <StyledSpecTitleBox>
                    <StyledFaqItemDescription size="large"><Trans i18nKey={`${dataPath}.${i}.detail.${index}.title`}/></StyledFaqItemDescription>
                  </StyledSpecTitleBox>
                </StyledGridColumn>

                <StyledGridColumn desktop={9} tablet={6} mobile={3}>
                  <StyledSpecDescBox>
                    <StyledFaqItemDescription size="large"><Trans i18nKey={`${dataPath}.${i}.detail.${index}.description`}/></StyledFaqItemDescription>
                  </StyledSpecDescBox>
                </StyledGridColumn>

              </StyledGridRow>
            );
          } else {
            return null;
          }
        })}
      </StyledFaqItemDetail>

    </StyledFaqItem>
  )
}

export default SpecItem;