import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconPenSquare, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/officeAsset/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/officeAsset/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/officeAsset/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/officeAsset/video/sub/rtlsVideo3.mp4";

export default {
    banner: {
      title: 'Smart Office<br/>Asset Management',
      desc: 'Inadequate asset management leads to time and financial losses. By managing assets clearly and quickly to ensure uninterrupted operations, you can run your business smoothly.',
    },
    video: {
      title: 'More Accurate and Secure Asset Management',
      desc: 'Real-Time Location Tracking System (RTLS) technology tracks everything in real-time. Manage all assets within the office in optimal conditions, enabling the most efficient operations.',
      play: video,
    },
    func: [
      {
        title: 'Asset Location Tracking',
        desc: 'Track the real-time location of assets, including office supplies, to identify the current position. Reduce wasteful routes, time, and expenses to improve office efficiency.',
        sub1: 'Real-Time Location Tracking',
        icon1: IconBinocularsGray,
        sub2: 'High Accuracy',
        icon2: IconRulerGray,
        video: subVideo1,
      },
      {
        title: 'Asset Management',
        desc: 'Accurate asset quantity is essential. Additionally, real-time tracking of the location allows you to know where the asset is at any given moment.',
        sub1: 'Real-Time Status Monitoring',
        icon1: IconClockGray,
        sub2: 'Fast Data Integration',
        icon2: IconShareGray,
        video: subVideo2,
      },
      {
        title: 'Asset Outbound Notifications',
        desc: 'Accurately tracking the movement of items within the office is crucial. However, allocating excessive resources and expenses to this task is inefficient. Automating the process provides an efficient office environment.',
        sub1: 'Statistical Data Provision',
        icon1: IconChartBarGray,
        sub2: 'Cloud Data Storage',
        icon2: IconCloudCheckGray,
        video: subVideo3,
      },
    ],
    subfunc: {
      title: 'Various Features',
      desc: 'There are various benefits such as efficient asset management, time and cost savings, transparency, and traceability. ORBRO provides additional features to create an optimal work environment and maximize efficiency.',
      data: [
        {
          icon: IconChartRadius,
          title: 'Reports and Analysis',
          desc: 'Generate reports and analysis based on asset usage records.',
        },
        {
          icon: IconBell,
          title: 'Real-Time Alert Function',
          desc: 'Receive alerts for specific assets in case of abnormal situations, allowing for immediate response.',
        },
        {
          icon: IconRoute,
          title: 'Asset Route Tracking',
          desc: 'Track the movement path of specific assets within the office and convert it into data.',
        },
        {
          icon: IconDashboard,
          title: 'Status Monitoring',
          desc: 'Monitor the current location of specific assets.',
        },
        {
          icon: IconWarning,
          title: 'Asset Deviation Detection',
          desc: 'Receive real-time alerts when assets deviate from pre-defined areas.',
        },
        {
          icon: IconPenSquare,
          title: 'Quantity Management',
          desc: 'Monitor the remaining quantity of assets and receive notifications when it exceeds or falls below a certain threshold.',
        },
        {
          icon: IconChainLink,
          title: 'Data Integration',
          desc: 'Integrate with systems such as RFID, IoT, security, and video for efficient utilization.',
        },
        {
          icon: IconSecurity,
          title: 'Data Security',
          desc: 'Maintain a high level of data security to provide a reliable solution.',
        },
      ],
    },
    system: {
      title: 'System Configuration',
      img: rtlsSystem,
    },
    productTitle: 'Product Configuration',
    product: [
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'logisticsAsset',
      },
      {
        data: 'manufacturingAsset',
      },
      {
        data: 'blogOffice',
      },
      {
        data: 'blogRTLS',
      },
    ],
  }
  