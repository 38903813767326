import styled from "@emotion/styled";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";


export const DialogBox = styled.dialog`
  min-width: 420px;
  min-height: 384px;
  display: flex;
  padding : 24px;
  border: none;
  border-radius: 16px;
  box-shadow: 0 0 30px rgba(30, 30, 30, 0.185);
  box-sizing: border-box;
  background-color: white;
  z-index: 10000;

  

  margin: 0 auto;
  

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 361px;
    hegith : 100%;

  }


`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.25);
`;
