import { IconBell, IconBellGray, IconBinocularsGray, IconChainLink, IconChainLinkGray, IconChartBarGray, IconChartRadius, IconClockGray, IconCloudCheckGray, IconDashboard, IconRoute, IconRulerGray, IconSearch, IconSecurity, IconShareGray, IconTagGray, IconTvocGray, IconWarning, IconWarningGray, IconZoneGray } from "@assets/images/icon";
import { rtlsSystem } from "@assets/images/solution";
import video from "@src/assets/images/solution/logisticsSafety/video/rtlsVideo.mp4";

import subVideo1 from "@src/assets/images/solution/logisticsSafety/video/sub/rtlsVideo1.mp4";
import subVideo2 from "@src/assets/images/solution/logisticsSafety/video/sub/rtlsVideo2.mp4";
import subVideo3 from "@src/assets/images/solution/logisticsSafety/video/sub/rtlsVideo3.mp4";
import subVideo4 from "@src/assets/images/solution/logisticsSafety/video/sub/rtlsVideo4.mp4";

export default {
    banner: {
      title: '倉庫作業員の安全管理',
      desc: '作業員の安全は何よりも重要です。高危険な環境で作業員の安全を確保しないことは、生産性の問題だけでなく、法的責任も発生する可能性があります。また、安全な物流現場は、企業のイメージと関係者の信頼形成に重要な役割を果たします。',
    },
    video: {
      title: '倉庫作業員をより安全に',
      desc: 'リアルタイムロケーショントラッキング（RTLS）技術により、物流現場の作業員の位置情報をリアルタイムで把握できます。管理者は事前に危険な状況を検知し、問題が発生した場合に迅速に対応することができます。また、<strong>安全規則の遵守</strong>を支援し、作業員と管理者の信頼関係を築きます。',
      play: video,
    },
    func: [
      {
        title: 'リアルタイム作業員位置測位',
        desc: '作業員の位置をリアルタイムで測位し、安全な状況を監視し、危険な地域に接近したり、緊急事態が発生した場合に迅速に対応できるよう支援します。これにより、潜在的なリスクを事前に防止します。',
        sub1: '高い精度の提供',
        icon1: IconRulerGray,
        sub2: '高速なデータ連携',
        icon2: IconShareGray,
        video: subVideo1,
      },
      {
        title: 'リアルタイム重機位置測位',
        desc: '安全に留意するべきは人だけではありません。潜在的な事故リスクのある重機の位置をリアルタイムで把握し、作業員が接近したり、誤った経路に入る場合を把握し、事故を予防できます。',
        sub1: '危険な資産の指定',
        icon1: IconWarningGray,
        sub2: 'リアルタイム位置測位',
        icon2: IconBinocularsGray,
        video: subVideo2,
      },
      {
        title: '作業員の経路把握',
        desc: '作業員が向かう経路に危険な機器や物質がある場合、管理者に警告を送ることができます。これにより、迅速な対応や救助が可能になります。',
        sub1: 'データクラウドへの保存',
        icon1: IconCloudCheckGray,
        sub2: '統計データの提供',
        icon2: IconChartBarGray,
        video: subVideo3,
      },
      {
        title: '安全区域の設定',
        desc: '立ち入り禁止または注意が必要なエリアを事前に設定し、近くを通る作業員に警告したり、管理者に通知したりすることができます。これにより、事故を予防できます。',
        sub1: '自由な範囲の設定',
        icon1: IconZoneGray,
        sub2: '複数のエリアの設定',
        icon2: IconTagGray,
        video: subVideo4,
      },
    ],
    subfunc: {
      title: '様々な機能',
      desc: '作業員の安全を確保することは、効率を高め、事故による損失を減らすことにつながります。ORBROでは、物流業界の作業員の安全を確保するために最適な機能を構築しています。<br/>作業員が危険なエリアに接近したり、重機による潜在的な危険事故などが発生する場合に適切に対応できるシステムを構築しています。',
      data: [
        {
          icon: IconChartRadius,
          title: 'レポートと分析',
          desc: '現場データを基にレポートや分析データを生成できます。',
        },
        {
          icon: IconBell,
          title: 'リアルタイムアラート機能',
          desc: '異常な状況が発生した場合に作業員にアラートを送信し、即座に対応できます。',
        },
        {
          icon: IconRoute,
          title: '経路履歴の参照',
          desc: '作業員の移動経路を参照し、データ化することができます。',
        },
        {
          icon: IconDashboard,
          title: 'ステータスの監視',
          desc: '現在の作業員の位置を把握します。',
        },
        {
          icon: IconWarning,
          title: '脱線の検知',
          desc: '作業員が指定されたエリアから外れたり、間違った場所に移動することを検知します。',
        },
        {
          icon: IconSearch,
          title: '経路パターンの分析',
          desc: '作業員の位置情報を基に次の作業を指示できます。',
        },
        {
          icon: IconChainLink,
          title: 'データの統合',
          desc: 'RFID、IoT、セキュリティ、ビデオなどのシステムと統合し、効率的に活用できます。',
        },
        {
          icon: IconSecurity,
          title: 'データのセキュリティ',
          desc: '高いデータセキュリティレベルを維持し、信頼性の高いソリューションを提供します。',
        },
      ],
    },
    system: {
      title: 'システム構成',
      img: rtlsSystem,
    },
    productTitle: '製品構成',
    product: [
      {
        tag: 'assetPackage',
      },
      {
        tag: 'workerPackage',
      },
    ],
    contents: [
      {
        data: 'logisticsAsset',
      },
      {
        data: 'constructionSafety',
      },
      {
        data: 'blogForklift',
      },
      {
        data: 'blogRTLS',
      },
    ],
  }
  