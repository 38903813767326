import {
  medicalPatient,
  officeAsset,
  retailExhibition
} from "@pages/Enterprise/Solution/components/Banner/BannerImages";

import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {
  helmet: '| NFC とは？',
  desc: 'NFC（近距離通信）は、近距離でのワイヤレス通信を可能にする技術です。この技術は非常に近い距離でデータのやり取りができるように、小さな無線波を使用します。',
  keywords: 'nfc, uwb, ワイヤレス通信, 位置追跡, オブジェクトの位置追跡, 人の位置追跡, タグ, ビーコン, rtls, orbro, contech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'NFC とは？ | 位置追跡とデジタルツインの専門プラットフォーム、Orbro',
  ogUrl: 'https://orbro.io/jp/blog/nfc-uwb-ble',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDlx0aose_UUwB8yAMLpMzvTdJK7dGM_ga67nWJqGp-Ad-0qXiKbUEUjAYHHCIw3c5fBJeOurZ9GbBlOLASQQ-fxRGXYfqb0RRl8SAXz05tRfZVuRggCXCN4Lhgi5bmQUDXYI9RvZTvlt-aRguSSi_Stihe1t7AUtepg-f_OTkuBEX5XDNmq1mSEBJVgqC26dYfGuJh3zgT12nYtPK60Bz8xB5ydx2cmvaAbP-No0hvZIWMciZv6XarrpKcR-Eu6YSdcsFOZ3J9wjVTeyyc6S-gVqoxW4abVCEfQaItklbow0uqPjlPT_KHGCGQa0SKOW97pUKx0PO8uSBpJMD6AzeJ4Yh-01yRQqpC6vcBsmNvEBxMvLvTm8BX5mRmBV48v6kHK_P7WycSemW5wnW58cEHX5Wjo2tV2tTJHX9y_qWwOZZWX7eFx73V0WmX-vG4ABxNwFr7u-p3TKA6nWMxJzwGyLw_9ouTexsqnfiBXf8MhruRLbrNobB19ErL_DHgZi4kPuI8PDDdT4kvxwE9lt4LpJuArzzdpvwhT4XnDrFl73C616KHcorzOcrOJxw-jzKhFvc-q17_3S--zh2PcMpG5cvL7DJ1Cgy62atJyXB5IB67bDeOUAnB_Zr3RdAUcVBg3_yADYB7htLduaNVCYlC8F9ucLtTOKYqDU_Kc0G53AHrpmiqbbWxLOyGamXVkCO9EzJaWC8EVDcclcnwMUWf_5vC_tIg8wnNp4nSycN0vVAfm-iukMGeHV3onRhtyGa--Q_31wtjzULC5n1ffWD2DgX0LCxksQOCkOmzWPFRhGC-UpLo1hUE6ar64oJbH06kISvVcqIqPYMLHIqWcMchD9FMPlBI2P0XtEJaZtNQ8OQ7XdAJzRXi6TK_jo16h0jAW9Bnh5sv9BA0eJoEQz4d0jD1wMwPB9StiMAHG_6pTNleKUOO92VU0weNP0U6p8gqceFhBSgierz4ukU8ud1rti_cnmtvPUHKNnPGoKMb-np6KexGgI3D9XWzzAsaUrAwziO87m2zHYt2SezQ3urRH9gG1yhokna4WTjfrxED9WfE2yI9uC3xMuALyZLompGIZk-mz9k1N8O4kZRHSLtIt_TQSUDILXDQsIwVMeMs1q_U9i6L10APLVQAjMZQL5dxa557zkCPB59Zytf72ASMHtp9TL6LGnpV5bPGGl3GSNPkH8sjR7UkuMmEFrUbbohLMbhHtxGneSDUtRJO7bdQsysoOK_-ZxsXqnkQVa1KX-J153QU3yI4IlaViIpjxY9w5kptwJPL_WFlXcjUnR-JnSu5c3FPe8y8gae7yt91cT6KECSmJelkYxaEan1q9_hAgXCvb24BKiRGWrNMAacUoPz8hljTI0cf8fB9GuDiPoqYgxJKcZFdicKosSD3SZMXnLbF4hZE96gFYFWuOIetfv7pzlsg08ZZ0EiklpgY1dshXnDmrLrKcnB4v_mWgvqKA1tyQnCZrAYyX-Z7zW_cj00TQX28EqAsV3cAkEVZTs_ClA1g-xHvUBIm-0logZKUhbQUT9TBz5eip_Aw_rexmWpOV4XcV=w1984-h1214',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'NFC とは？',
  date: '2024-01-11',
  tag1: '#nfc',
  tag2: '#位置追跡',
  tag3: '#ワイヤレス通信',
  tag4: '#資産管理',

  // <br/>
  text: [
    {
      head: 'NFC とは？',
      subHead: '',
      desc: `NFC（Near Field Communication）は、近距離ワイヤレス通信技術であり、非常に近い距離でデータをやり取りすることを可能にします。この技術は、非常に短い距離でデータを交換できるように、小さな無線波を使用します。主にスマートフォンや他の電子デバイス間での簡単な情報の送信、モバイル決済、入退室カードシステムなどに利用され、10cm以内の短い距離で動作します。NFCは高速で使いやすいため、主に決済や簡単なデータ転送に利用されています。`,
      dot: ''
    },
    {
      head: 'NFC の動作原理',
      subHead: '',
      desc: 'NFC（近距離通信技術）の動作原理は非常に効果的で簡単です。まず、NFCはデバイス間で小さな電磁場を生成します。この電場は、データを交換するための通路として機能し、レーダーで使用される概念と類似しています。この小さな電磁場は、各デバイス間で非常に近い距離でのみ効果的に動作し、通常は10cm以内の距離で行われます。<br/>NFCの動作原理は、RFID（Radio-Frequency Identification）技術に基づいています。したがって、送信デバイス（リーダーまたは初期化されたデバイス）と受信デバイス（タグまたはカード）の間でワイヤレスでデータが交換されます。このとき、電磁場を介して両方のデバイスはお互いを認識し、通信を開始します。<br/>その主な特徴の1つは、NFCの動作距離が比較的短いことです。これはセキュリティの観点から利点を提供し、ユーザーが意図しないデータの交換を防ぎます。また、この動作原理は高速なデータ転送とリアルタイムのデータ交換を可能にします。',
      dot: ''
    },
    {
      head: 'NFC の主な用途',
      subHead: 'NFC モバイル決済',
      desc: 'NFCは主にスマートフォンを使用した簡単な支払いに活用されます。ユーザーはNFC対応の支払い端末にスマートフォンを近づけ、事前に登録された支払い情報を送信することで、迅速かつ便利に支払いを完了できます。代表的な例にはApple Pay、Google Pay、Samsung Payなどがあります。',
      dot: ''
    },
    {
      head: '',
      subHead: 'データ転送',
      desc: 'NFCを使用して、2つのデバイス間で簡単なデータ転送が可能です。例えば、スマートフォン間で連絡先情報、写真、名刺などを手軽に交換できます。また、NFCタグを活用して情報転送を自動化するようプログラムすることもできます。',
      dot: ''
    },
    {
      head: '',
      subHead: 'スマートフォン機能の拡張',
      desc: 'NFCはスマートフォンの機能を拡張するために使用されます。例えば、ホームオートメーションシステムと統合してスマートフォンを特定のデバイスにタッグし、照明、暖房、セキュリティシステムなどを制御できます。',
      dot: ''
    },
    {
      head: '',
      subHead: '電子アクセスカード',
      desc: 'NFCは電子アクセスカードシステムで一般的に使用されます。スマートフォンやNFCタグを使用して建物や施設にアクセスし、より高いセキュリティと利便性を提供します。',
      dot: ''
    },
    {
      head: '',
      subHead: '医療分野',
      desc: '患者の識別とアクセス制御、医療記録および薬物管理、緊急対応、およびクリニックの自動化など、さまざまな応用分野で効果的に活用されます。 NFCは医療システムを効率的に自動化し、患者の安全性を向上させ、緊急時に迅速かつ正確な医療サービスを提供するのに役立ちます。',
      dot: ''
    },
    {
      head: '',
      subHead: 'スマートPOSテクノロジー',
      desc: 'NFCは店舗やカフェなどでスマートPOS（販売時点）システムと連携し、支払いおよび割引情報の転送に使用されます。顧客はスマートフォンを支払い端末に近づけることで簡単に支払いを行うことができます。',
      dot: ''
    },
    {
      head: 'NFC とBluetoothの違い',
      subHead: '通信距離',
      desc: '',
      dot: {
        a: '<strong> • NFC：</strong>非常に近い距離でのみ通信が可能で、通常は10cm以内です。これは主に接触または非常に短い距離でのデータ転送に使用されます。',
        b: '<strong> • Bluetooth：</strong>はるかに広範囲で動作し、通常は最大100メートルまで通信が可能です。これはより遠くのデータ転送およびデバイス間の接続に使用されます。',
      }
    },
    {
      head: '',
      subHead: '転送速度',
      desc: '',
      dot: {
        a: '<strong> • NFC：</strong>通常、Bluetoothよりも転送速度が遅いです。主に小量のデータ転送に使用されます。',
        b: '<strong> • Bluetooth：</strong>高い転送速度を提供し、大容量のデータを迅速に転送するのに効果的です。',
      }
    },
    {
      head: '',
      subHead: '用途',
      desc: '',
      dot: {
        a: '<strong> • NFC：</strong>簡単なデータ交換および支払いシステム、タグの読み取り（タグに保存された情報の読み取り）など、主に短距離の応用に使用されます。',
        b: '<strong> • Bluetooth：</strong>主にイヤホン、スピーカー、キーボードなどの長距離および大容量データ転送が必要な応用分野で使用されます。',
      }
    },
    {
      head: '',
      subHead: '電力消費',
      desc: '',
      dot: {
        a: '<strong> • NFC：</strong>低い電力消費とされています。主に充電が不要な小型デバイスで使用されます。',
        b: '<strong> • Bluetooth：</strong>相対的に高い電力消費があり、一部のデバイスでバッテリー寿命に影響を与える可能性があります。',
      }
    },
    {
      head: '',
      subHead: 'セキュリティ',
      desc: '',
      dot: {
        a: '<strong> • NFC：</strong>非常に短い距離でのみ通信が可能なため、通常は比較的安全な環境で使用されます。',
        b: '<strong> • Bluetooth：</strong>長距離通信が可能なため、セキュリティがより重要で、より強力なセキュリティ技術が必要です。',
      }
    },
    {
      head: 'NFCの将来展望',
      subHead: '',
      desc: "NFC技術は進化を続け、その応用はモバイルペイメント、スマートホーム、医療産業などさまざまな分野で期待されています。進行中の技術革新はより高いセキュリティと伝送速度を提供することを目指しており、NFCは多様な産業セクターで重要な役割を果たすことが期待されています。<br/>NFCは、ワイヤレス通信技術の1つとして、将来のデジタル環境でさまざまな分野でイノベーションを牽引すると予想されています。その発展により、デジタルランドスケープにさらなるイノベーションがもたらされることが期待されています。",
      dot: ''
    },
  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWBターミナルを作業者や機器に取り付けて、リアルタイムの位置追跡を支援する製品です。',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/jp/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/jp/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/jp/enterprise/office/asset'
      }
    ]

  }

};
