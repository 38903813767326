import { accessAuto1, accessAutoMain, accessPro1, accessProMain, accesscontroller1, accesscontrollerMain } from "@assets/images/main/access/product";



export default {

    specTitle: {
    },
    
    specData: [
      {
        data: [
          {title: "Product Name"},
          {text1: "Mobile Access"},
          {text2: "Automatic Door Access"},
          {text3: "Face Recognition Access"},
        ],
      },
      {
        data: [
          {text: "Description"},
          {text: "A very convenient device that controls the entrance door with a mobile app"},
          {text: "A device that allows convenient access without running an app"},
          {text: "A device that allows various access methods such as facial recognition, NFC card at once"},
        ],
      },
      {
        data: [
          {text: "Power Supply"},
          {text: "Battery Usage (up to 3 years)"},
          {text: "DC 12V, 24V "},
          {text: "DC 12V, 3A"},
        ],
      }
    ],
    
    a: [
      {text: "Product Name"},
      {text: "Description"},
      {text: "Power Supply"},
      {text: "Communication Method"},
      {text: "Network"},
      {text: "Specifications"},
      {text: "Installation Support"},
      {text: "Person Tracking"},
      {text: "One-Pass Feature"},
      {text: "Real-Time Device Monitoring"},
      {text: "Remote Control"},
      {text: "NFC Access"},
      {text: "Facial Recognition Access"},
      {text: "QR Code Access"},
    ],
    
    b: [
      {text: "Mobile Access"},
      {text: "A very convenient device that controls the entrance door with a mobile app"},
      {text: "Battery Usage (up to 3 years)"},
      {text: "Bluetooth, BLE"},
      {text: "Not necessary"},
      {text: "85.4 x 85.4 x 24.5(mm)"},
      {text: ""},
      {text: ""},
      {text: ""},
      {text: ""},
      {text: ""},
      {text: ""},
      {text: ""},
      {text: ""},
    ],
    
    c: [
      {text: "Automatic Door Access"},
      {text: "A device that allows convenient access without running an app"},
      {text: "DC 12V, 24V"},
      {text: "Bluetooth, BLE"},
      {text: "Not necessary"},
      {text: "210.1 x 60.1 x 42.2(mm)"},
      {text: "1"},
      {text: "1"},
      {text: "1"},
      {text: ""},
      {text: ""},
      {text: ""},
      {text: ""},
      {text: ""},
    ],
    
    d: [
      {text: "Face Recognition Access"},
      {text: "A device that allows various access methods such as facial recognition, NFC card at once"},
      {text: "DC 12V, 3A"},
      {text: "Bluetooth, BLE, Wi-Fi, NFC"},
      {text: "Required"},
      {text: "256 x 273 x 24(mm)"},
      {text: "1"},
      {text: "1"},
      {text: "1"},
      {text: "1"},
      {text: "1"},
      {text: "1"},
      {text: "1"},
      {text: "1"},
    ],
    
    spec: [
      
      {
      },
    
      {
        image: accesscontrollerMain,
      },
    
      {
        image: accessAutoMain,
      },
    
      {
        image: accessProMain,
      },
        
    ],
    



}