export default {


    support : [

        {
            name : 'オブロ ーホーム',
            path : '/jp/',
            start : 'はじめる'

        },
        {
            name: 'サポート',
            submenus: [
                {text:'技術', path:'/jp/enterprise/technology/uwb'},
                {text:'ブログ', path:'/jp/blog'},
                {text:'営業問い合わせ', path:'/jp/request'},
                {text:'会社紹介', path:'/jp/company'},
            ]
        }

    ],


    lang : [
        {
            name : '言語',
            submenus :[
                {text:'한국어'},
                {text:'English'},
                {text:'日本語'},

            ]
        }


    ]


}