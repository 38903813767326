import { LabelColor, ParagraphColor, Spacing, SpacingDTM } from "@pages/CommonStyle/common.style";
import { DivAllCenter, DivFlexColumn, DivGrid } from "@pages/CommonStyle/commonDiv.style";
import digitaltwin from "@src/lang/ko/digitaltwin/digitaltwin";
import React from "react";
import { Trans } from "react-i18next";
import { StyledGridContainer } from "@components/Grid/Grid.style";
import { ContentTag } from "@pages/Enterprise/Solution/components/Contents/Contents.style";
import { ExmaImg } from "./Exam.style";
import { DisplayH2color } from "@components/Text/HeadingTagDisplay.style";
import { HeadingH3white } from "@components/Text/Heading/Heading.style";
import i18n from "@pages/i18n";
import { BannerBtnAtag } from "@pages/Main/Home/components/Banner/Banner.style";



const Exam = () => {

  return (
    <>
      <StyledGridContainer>
        <DivAllCenter>
          <DisplayH2color color="#FFF" size="small">
            <Trans i18nKey={`digitaltwin.exam.title`} />
          </DisplayH2color>
          <Spacing pixel="8" />
          <ParagraphColor color="#E0E0E0" size="large">
            <p>
              <Trans i18nKey={`digitaltwin.exam.desc`} />
            </p>
          </ParagraphColor>
          <Spacing pixel="16" />
          <BannerBtnAtag href={i18n.language === 'ko' ? '/request' : `/${i18n.language}/request`}>
            <LabelColor color={"#FFF"} size={"large"}>
              <Trans i18nKey={`home.solution.button`} />
            </LabelColor>
          </BannerBtnAtag>
          <Spacing pixel="16" />
        </DivAllCenter>
        <DivGrid cnt={3} gap={32}>
          {digitaltwin.exam.card.map((data, i) => (
            <>
              <DivFlexColumn>
                <Spacing pixel="16" />
                <a href={data.link.includes('home') ? data.link : i18n.language === 'ko' ? data.link : `/${i18n.language}${data.link}`} target="_blank" rel="noopener noreferrer">
                  <ExmaImg src={data.img} alt="ORBRO's Digital Twin Platform" />
                </a>
                <Spacing pixel="16" />
                <ContentTag bgcolor={"#D4E2FC"}>
                  <LabelColor size="xsmall" color="#2A70F0">
                    <Trans i18nKey={`digitaltwin.exam.card.${i}.tag`} />
                  </LabelColor>
                </ContentTag>
                <Spacing pixel="8" />
                <HeadingH3white size="xsmall" color="#FFF">
                  <Trans i18nKey={`digitaltwin.exam.card.${i}.title`} />
                </HeadingH3white>
                <Spacing pixel="8" />
                <ParagraphColor size="small" color="#818181">
                  <p>
                    <Trans i18nKey={`digitaltwin.exam.card.${i}.desc`} />
                  </p>
                </ParagraphColor>
              </DivFlexColumn>
            </>
          ))}
        </DivGrid>
        <SpacingDTM d={"128"} t={"96"} m={"64"} />
      </StyledGridContainer>
    </>
  )
}

export default Exam;