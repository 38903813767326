import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet : '',
  desc : 'GIS 지리정보 구현은 정밀 지도학과 3D 지형 시뮬레이션을 결합한 실시간 지리 정보 시스템을 제공합니다. 이 시스템은 전문가들이 지형 분석, 인프라 계획, 재난 관리 결정을 지원하며, ORBRO의 혁신적인 기술력을 반영합니다.',
  keywords : 'Digital Twin, Digital Twins, 디지털 트윈, gis, 디지털 시티, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle : '',
  ogDesc : '',
  ogSite : '디지털 트윈 - GIS 지리정보 구현 :: 오브로 블로그 ',
  ogUrl : 'https://orbro.io/blog/digitaltwin-gis',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',
  
  title: '디지털 트윈 - GIS 지리정보 구현',
  date : '2023-12-26',
  tag1 : '#디지털트윈',
  tag2 : '#GIS',
  tag3 : '#디지털시티',
  tag4 : '#가상현실',
  
  // <br/>
  text : [
    {
      head : '디지털 트윈 - GIS 지리정보 구현',
      subHead :'',
      desc : 'ORBRO의 GIS 지리정보 구현은 디지털 트윈을 활용하여 정밀 지도학과 3D 지형 시뮬레이션을 통합한 혁신적인 실시간 지리 정보 시스템을 제공합니다. 이 시스템은 전문가들이 지형 분석, 인프라 계획, 재난 관리 결정을 지원하며 ORBRO의 최신 기술력을 반영합니다.<br/><br/>3D 지형 모델은 지형의 정확한 모양, 경사도, 높이를 시각적으로 표현하여 환경 분석, 도시 계획, 재난 관리에 활용됩니다. 인프라 데이터는 3D 모델링과 GIS 기술을 활용하여 디지털화되며, 도시 개발과 재난 대응에 필수적입니다.<br/><br/>건물 정보는 고해상도 사진, 건축 설계 데이터, 3D 스캐닝을 활용하여 모델링되어 건축 설계 및 도시 계획에 중요한 역할을 합니다. GIS 기술은 도시 계획, 교통 흐름 최적화, 공공 서비스 배치에 필요한 정보를 제공하며, 재난 대응, 환경 변화 및 보호에도 핵심적인 역할을 수행합니다.',
      dot : ''
      
    },
    {
      youtube : 'https://www.youtube.com/embed/uUm8my-ogGs?si=UNRDMDxCeI3ZWxyt',
      head : '',
      subHead :'지구형태의 지리 표현',
      desc : 'ORBRO는 위성 이미지, 지형 데이터, 지리 정보 시스템(GIS)을 통합하여 고해상도 3D 지형 모델을 구축합니다. 이 모델은 지형의 정확한 모양, 경사도, 높이 등을 실시간으로 시각화하며, 환경 분석, 도시 계획, 재난 관리 및 구조 계획 등 다양한 분야에서 중요한 의사결정에 활용됩니다.',
      dot :''
    },
    {
      head : '',
      subHead :'지형 및 인프라 표현',
      desc : '다양한 출처로부터 인프라 데이터를 수집하고, 이를 3D 모델링과 GIS 기술로 디지털화합니다. 이 정보는 도시 및 지역 개발, 재난 대응 및 안전 계획 수립에 필수적입니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'건물 정보 3D 표현',
      desc: 'ORBRO는 고해상도 사진, 건축 설계 데이터, 3D 스캐닝 기술을 이용하여 건물의 외관과 내부 구조를 정밀하게 모델링합니다. 이 모델링은 건축 설계, 도시 계획, 부동산 개발 및 평가에 중요한 역할을 합니다.',
    },
    {
      head : '',
      subHead :'도시 계획 및 관리 개선',
      desc :'ORBRO의 GIS 기술은 복잡한 도시 환경에서의 계획 및 관리를 개선하는 데 도움을 줍니다. 이를 통해 교통 흐름 최적화, 공공 서비스 배치, 도시 확장 관리 등에 필수적인 정보를 제공합니다.',
    },
    {
      head : '',
      subHead :'재난 대응 및 준비 지원',
      desc :'GIS 기술을 활용하여 자연 재난 및 비상 상황에 대한 대응과 준비를 지원합니다. 지형과 인프라 데이터를 활용하여 위험 지역을 식별하고, 대피 경로, 구호 자원 배치 등을 계획하는 데 중요한 역할을 합니다.',
    },
    {
      head : '',
      subHead :'환경 변화 및 보호',
      desc :'ORBRO의 GIS는 환경 변화를 모니터링하고 분석하는 데 중요한 도구입니다. 이 기술을 통해 생태계 변화, 자연 보호 구역 관리, 환경 보호 정책 수립에 기여합니다.',
    },

  ],


  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      }
    ]


  }

}