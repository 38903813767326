import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { CommonState } from '@redux/reducers/commonReducer';
import { useSelector } from 'react-redux';
import { StyledGridContainer } from '@components/Grid/Grid.style';
import integration from '@src/lang/ko/main/integration/integration';
import { BorderBottomLine, Spacing } from '@pages/CommonStyle/common.style';
import Scheme from '@pages/Main/Common/Scheme/Scheme';
import { DisplayH2 } from '@components/Text/HeadingTagDisplay.style';
import { StyledControlPage } from '@pages/Main/Access/components/Main/Main.style';
import { StyledAppTextBox, StyledDesc } from '@pages/Main/Rtls/Banner/Banner.style';
import Video from '@pages/Main/Common/Video/Video';
import Detail from '../Detail/Detail';
import Lineup from '@pages/Main/Common/Lineup/Lineup';
import { DivAllCenter } from '@pages/CommonStyle/commonDiv.style';

export interface HomeNewsItem {
  title: string;
  link: string;
  image: string;
  date: string;
}

const Main = ({ handleIconClick }): React.ReactElement => {

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const handleIconClickLocal = (group) => {
    handleIconClick(group);
  };

  return (
    <>

      <Video pageName={'integration'} />

      <Detail type={'delivery'} />
      <Detail type={'loading'} />
      <Detail type={'service'} />

      <DivAllCenter>
        <DisplayH2 size={'small'}>
          <Trans i18nKey={'integration.mainPage.product.title'} />
        </DisplayH2>
        <StyledDesc size={'medium'}>
          <p>
            <Trans i18nKey={"integration.mainPage.product.desc"} />
          </p>
        </StyledDesc>
        <Spacing pixel='64' />
      </DivAllCenter>

      <Lineup handleIconClickLocal={handleIconClickLocal} product={'integration'} />
      <Spacing pixel='-32' />

      <StyledGridContainer>
        <BorderBottomLine />
      </StyledGridContainer>


    </>
  );
};

export default Main;
