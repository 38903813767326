import { Vertical } from "@assets/styled/flex";
import { StyledGridColumn, StyledGridContainer } from "@components/Grid/Grid.style";
import React, { useMemo, useState } from "react";
import { StyledRequest, StyledRequestCall, StyledRequestCallTime, 
  StyledRequestCheckItem, StyledRequestCheckItemIcon, 
  StyledRequestCheckItemName, StyledRequestCheckList, StyledRequestDisplay, 
  StyledRequestInfo, StyledRequestMain, StyledRequestMainRow } from "./Request.style";
import RequestForm from "./components/RequestForm/RequestForm";
import { useTranslation, Trans } from "react-i18next";

import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";
import { StatusCheckIcon } from "@assets/images/services/request";
import OurPartners from "@pages/Enterprise/Company/components/OurPartners/OurPartners";

import request from "@src/lang/ko/request";
import i18n from "@pages/i18n";
import SEO from "@pages/SEO";
import { HeadingColor, Spacing } from "@pages/CommonStyle/common.style";
import { StyledParagraph } from "@components/Text";
import CommonLang from "@pages/CommonStyle/commonLang";

const Request = (): React.ReactElement => {

  const { t } = useTranslation();

  CommonLang();

  // States
  const [serviceType, setServiceType] = useState<string>('ORBRO');

  // Redux
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  // Memo
  const checkList = useMemo(() => request.checkList, []);

  const currentLang = i18n.language;

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "ORBRO",
    "alternateName": "위치추적과 디지털 트윈 전문 플랫폼, ORBRO",
    "url": "https://orbro.io",
    "address": "#B-1203, 660, Daewangpangyo-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea",
    "email": "orbro@orbro.io",
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+8215229928",
      "contactType": "a sales contact point",
      "areaServed": "KR",
      "availableLanguage": "Korean"
    }],
    "sameAs": "https://kr.linkedin.com/company/kongtech"
  }

  const helmetTitle = t(`seo.request.title`);
  const key = t(`seo.request.key`);
  const desc = t(`seo.request.desc`);
  const url = t(`seo.request.url`);
  return (
    <>

      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={key}
        ogDesc={desc}
        ogImg="https://orbro.io/images/0105d9c7a8a46d66bf8140bb565f1dbc.png"
        ogUrl={url}
        jsonLd={jsonLd}
      />


      <StyledRequest>
        <StyledRequestMain>
          <StyledGridContainer>
            <StyledRequestMainRow>

              <StyledGridColumn desktop={5} tablet={8} mobile={4}>
                <StyledRequestInfo>
                  <HeadingColor size="xxsmall" color="#818181"><Trans i18nKey={"request.subtitle"} /></HeadingColor>
                  <Spacing pixel="16"/>
                  <StyledRequestDisplay size="medium">
                    <Trans
                      i18nKey={"request.title"}
                      values={{ service: serviceType }}
                    />
                  </StyledRequestDisplay>

                  <StyledParagraph size="xlarge">
                    <Trans
                      i18nKey={"request.description"}
                      values={{ service: serviceType }}
                    />
                  </StyledParagraph>

                  <StyledRequestCheckList>
                    {checkList.map((check, index) => (
                      <StyledRequestCheckItem key={`service-request-check-${index}`}>
                        <StyledRequestCheckItemIcon src={StatusCheckIcon} alt="check icon" />
                        <StyledRequestCheckItemName size="large"><Trans i18nKey={`request.checkList.${index}`} /></StyledRequestCheckItemName>
                      </StyledRequestCheckItem>
                    ))}
                  </StyledRequestCheckList>

                  <Vertical>
                    <StyledRequestCall size="xlarge">
                      <Trans i18nKey={"request.call"} />
                    </StyledRequestCall>
                    <StyledRequestCallTime size="small"><Trans i18nKey={"request.callTime"} /></StyledRequestCallTime>
                  </Vertical>
                </StyledRequestInfo>
              </StyledGridColumn>


              {layoutType === 'desktop' && <StyledGridColumn desktop={1} tablet={1} mobile={0} />}


              <StyledGridColumn desktop={6} tablet={8} mobile={4}>
                <RequestForm serviceType={serviceType} />
              </StyledGridColumn>

            </StyledRequestMainRow>
          </StyledGridContainer>
        </StyledRequestMain>


        <OurPartners />
      </StyledRequest>
    </>
  )
};

export default Request;