import { StyledGridContainer } from '@components/Grid/Grid.style';
import React, { PropsWithChildren } from 'react';
import { SubFuncBox, SubFuncIcon, SubFuncPage, SubFuncSection } from './SubFunc.style';
import { StyledParagraph } from '@components/Text';
import { Trans } from 'react-i18next';
import { DivWidth100 } from '@pages/CommonStyle/commonDiv.style';
import { ParagraphColor, Spacing } from '@pages/CommonStyle/common.style';
import { DisplayH2 } from '@components/Text/HeadingTagDisplay.style';
import { LabelH3 } from '@components/Text/Label/Label.style';



interface ModalDefaultType {
  pageName: string
  path: any
}

const SubFunc: React.FC<PropsWithChildren<ModalDefaultType>> = ({ pageName, path }) => {


  return (
    <>
      <StyledGridContainer>
        <SubFuncPage>

          <DisplayH2 size='small'>
            <Trans i18nKey={path.subfunc.title} />
          </DisplayH2>

          <Spacing pixel='16' />

          <StyledParagraph size='medium'>
            <p>
              <Trans i18nKey={path.subfunc.desc} />
            </p>
          </StyledParagraph>


          <SubFuncSection>
            {path.subfunc.data.map((data, index) => (
              <DivWidth100 key={index}>
                <SubFuncBox>
                  <Spacing pixel='48' />
                  <SubFuncIcon src={data.icon} alt='rtlsIcon' />
                  <Spacing pixel="8" />
                  <LabelH3 size='large'>
                    <Trans i18nKey={path.subfunc.data[index].title} />
                  </LabelH3>
                  <Spacing pixel='4' />
                  <ParagraphColor size='small' color='#646464'>
                    <p>
                      <Trans i18nKey={path.subfunc.data[index].desc} />
                    </p>
                  </ParagraphColor>

                </SubFuncBox>
              </DivWidth100>
            ))}
          </SubFuncSection>
        </SubFuncPage>

      </StyledGridContainer>
    </>
  )
};

export default SubFunc;