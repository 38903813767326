import styled from "@emotion/styled";

export const ProgressedBarAnimation1_2 = styled.div`

    z-index : 3;
    width: 100%;
    height : 12px;
    border-radius : 4px;

    animation: fillProgress12 1s linear forwards; 

    @keyframes fillProgress12 {
    from {
        background-color : #2A70F0;
        border-radius : 4px;
        width: 29% 
    }
    to {
        background-color: #2A70F0;
        border-radius : 4px;
        width: 0%; 
    }
    }
`;



export const ProgressedBarAnimation2_1 = styled.div`

    z-index : 3;
    width: 100%;
    height : 12px;
    border-radius : 4px;

    animation: fillProgress21 1s linear forwards; 

    @keyframes fillProgress21 {
    from {
        background-color : #2A70F0;
        border-radius : 4px;
        width: 0% 
    }
    to {
        background-color: #2A70F0;
        border-radius : 4px;
        width: 29%; 
    }
    }
`;

export const ProgressedBarAnimation2_2 = styled.div`

    z-index : 3;
    width: 100%;
    height : 12px;
    border-radius : 4px;

    animation: fillProgress22 1s linear forwards; 

    @keyframes fillProgress22 {
    from {
        background-color : #2A70F0;
        border-radius : 4px;
        width: 57% 
    }
    to {
        background-color: #2A70F0;
        border-radius : 4px;
        width: 29%; 
    }
    }
`;



export const ProgressedBarAnimation3_1 = styled.div`

    z-index : 3;
    width: 100%;
    height : 12px;
    border-radius : 4px;

    animation: fillProgress31 1s linear forwards; 

    @keyframes fillProgress31 {
    from {
        background-color : #2A70F0;
        border-radius : 4px;
        width: 29% 
    }
    to {
        background-color: #2A70F0;
        border-radius : 4px;
        width: 57%; 
    }
    }
`;

export const ProgressedBarAnimation3_2 = styled.div`

    z-index : 3;
    width: 100%;
    height : 12px;
    border-radius : 4px;

    animation: fillProgress29 1s linear forwards; 

    @keyframes fillProgress29 {
    from {
        background-color : #2A70F0;
        border-radius : 4px;
        width: 78%; 
    }
    to {
        background-color: #2A70F0;
        border-radius : 4px;
        width: 57%; 
    }
    }
`;

export const ProgressedBarAnimation4_1 = styled.div`

    z-index : 3;
    width: 100%;
    height : 12px;
    border-radius : 4px;

    animation: fillProgress41 1s linear forwards; 

    @keyframes fillProgress41 {
    from {
        background-color : #2A70F0;
        border-radius : 4px;
        width: 57% 
    }
    to {
        background-color: #2A70F0;
        border-radius : 4px;
        width: 78%; 
    }
    }
`;

export const ProgressedBarAnimation4_2 = styled.div`

    z-index : 3;
    width: 100%;
    height : 12px;
    border-radius : 4px;

    animation: fillProgress42 1s linear forwards; 

    @keyframes fillProgress42 {
    from {
        background-color : #2A70F0;
        border-radius : 4px;
        width: 96% 
    }
    to {
        background-color: #2A70F0;
        border-radius : 4px;
        width: 78%; 
    }
    }
`;

