export default {
  titleBanner: {
    subtitle: 'TDoA | Time Difference of Arrival',
    title:"TDoA定位技术",
    description:'*TDoA(Time Difference of Arrival)技术是一种定位技术,它通过测量无线电波从发报机到达的时间差来计算接收机的位置。 如果多个<br/> 的接收器从一个发报机接收无线电波，则可以通过测量接收器之间的无线电波到达时间差来计算发报机的位置。 <br/>此时接收器会同步使用正确的时间，时间同步越准确，定位精度越高。'
  },
  definition: {
    title: "TDoA技术是什么？",
    text:`TDoA是确定无线电信号到达时间差异的定位方法论。 TDoA用于实时定位系统（RTLS），实时准确计算追溯对象的位置，如附加在人力资源或重要资产上的追溯标签。 <br/><br/>例如，在Orbro的实时定位系统中，TDoA利用从UWB或芯片(RTLS)RTLS锚中跟踪标记收集的位置数据，精确计算跟踪对象的实时定位。 如附图所示，RTLS标签(T)以一定间隔连续发出RF信号，即位置BLINK。 传输标签通信范围内的多个RTLS锚接收该位置的BLINK，并用时间戳记录准确的到达时间(ToA)。 然后主播将带有该时间戳的位置数据传递给Orbro Edge位置引擎软件。 OrbroEdge通过这种方式分析接收位置BLINK的到达时间差异（如ToA1-ToA2），计算TDoA。`
  },
  sync: {
    title: "虚拟主播同步",
    text : "如果要正确操作， 固定的锚必须精确同步， 才能在Clock中运行 。 Orbro Edge的实时定位系统是通过基于无线通信的时间同步来实现的。 计算的TDoA由聚焦线(hyperbolas)表示，并代表可能的标记位置。 在一个点相交的三条相关焦线描述了标签的位置。 建议使用至少四个锚进行可靠的位置估计。 <br/><br/>结果出现的位置坐标可以可视化标签在地图上的位置，或者根据具体应用的不同用途。"
  },
  advantage: {
    title:`TDoA的主要优点`,
    items: {
      accuracy: {
        title : "高精度",
        description:"TDoA比传统方法（如基于RSSI的定位测量）保证了精度更高的定位结果。"
      },
      wait: {
        title : "低等待时间",
        description:"使用TDoA,RTLS可以处理数千个同时接收位置BLINK,确保了低延迟时间和高性能可靠性。"
      },
      expansion: {
        title:`企业扩张性`,
        description:'基于TDoA的计算满足企业要求 可以发布可扩展的RTLS。'
      }
    }
  },
}