import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4';

export default {

    desc: 'Smart Port is a comprehensive solution that monitors and manages all activities within the port in real-time using digital twin, RTLS, and artificial intelligence. This allows real-time monitoring and analysis of all objects and processes within the port.',
    keywords: 'Smart Port, Digital Twin, Digital Transformation, digital twin, beacon, RTLS, UWB, BLE, AI camera, Orbro, Kongtek',

    ogSite: 'Smart Port Solution | Digital Twin, Orbro',
    ogUrl: 'https://orbro.io/en/blog/smart-port',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmtMlMpuGaSkdrwhUm9cmk57NhnjuSqUyvAfLNAvWgGu0MM4L2R6eFvjBYvTVkkIk2f8QbkrMlttEBDfzi4pLKN9N-dhDu_JiYkV4BvDu2CLF5JFWrrMJgKJAPWObqYGbQYtBBMoquCCAYx_k8njELRIPt4_PKkk6KdhaLKf78OatyVEbgXKhqBqdCGxbhGw3jTDiqdbaImqLAMR3Wdos-IWpYXZYClx-DKC1P9dB3GLBP0ixLsNn9rpaH91rcH8RLcRUDTYzamMx1BMmNl89ZY8vTfts7eomL8NXjR9nEZZ5K-MkBjZT4KJ4o4iUQRE8CZDBT8xcueZCDOkMPkM1K3XgnM7okDR8U8xf_zJc0w6Ng2bEtDNjtB1h-vn2NNuBWuiIXgDTJG-ZlSbIFXuT1ySTt8d8L9s9vZffcfMLrKFRnY8Obtfn-WVUDAaXA68xwLtGJYP-NMEMyCqtdlswxY1nzpPdBpNTELVjP4NGkFiHk3Vb0xHr22sMgIzsoWUBuuZRiiz_Oadq3DWG64cWpzTeUzRxxnVc7lh1jqQCre0y1-PNTz8FmoHgjoNYQIGR8teD7k3s2g9v6_39LkKVnEp3LZg4K7fK_st9aGnXxXHgT38G1P_CyZ2NCfDR2h7757hijJWyc01v1e5DKey7CKzS5XKyz6VQitW_E20TWpbzbIO-rYeEzjZwRCbjcBhUVfQiSz2QcMbLTb3acfrCop4bSDap1c2tjNFn6BURfcFhtqYymgRGU1UCQ4ZuD_RO552LTgZgcyVENTq2v3A1n1qzhsNoSFHD5hJ2RA2ww4so8MDKBF2R5J6mer8Su6fEutD5F1ZUgGwSU4glTVu0jYCkS97SgUsjSJ-hWu10P40b1PbPIYjir7h7BTyDphRhnPDkigpYw4x2NeLO1ilNqDXxOLoRxOz3XM0ubgAHxvtFSl6urWE-MXOjXwCuu_8dCODgOBpzlHm_HA1FIREfuLH-sVZ9VKFvyFZZyxx4x12i7Gf8wfTFh20JL2uxFSplfv-8nvkqRaVPYrF5RGZ3jSwtPJP9OYAMhxVpgB1unGLMYL2-a0_s-q8lfbijvufnfa1O6P0UiqgkFwZ0HKLgLXHOD3DSaHMMSJkB9oSwFNFJgoIAciFzzPWPCw4Hs1wMO5q4ixJ1Jbvyu53pWL_tznPM5m5mIj1D_i2RMKAoNFDl4EMFRzXjMKHUWjTc2bt9knvzg3CUTzs7rDarMVqv2ELwEc2BJE5joJfnY7APOre8u84naPSATFCFeKvGxt0v6mruuozNIF4IZMC2XhhGDHbQasJe1Vfab9-e18wVVD6fyEM0jLw50EPpOh5rTUJE02ppq_cHwPe2ZUQ10K7oC0LZoy05ga5pL6tfHnt65GWSUg6ehpUe7RkFH0vOMvEPWln4Zl3XiUjnmVQ_SAN-gWxtOswmhrO4QavIJmTUrR9UmDxFLOAjeUDHPsYc0XYFpmyLYyiAuDr095MknMbE1K0WjCf9kfZy_O14qr-6UpIl7J8vTNKxXQX1RKB9t7N_rO0S0Q1UgEj02peJ30KvmNifvU3gDY1huNYAcxzOUSC0kfxpI=w2110-h1171',
    ogType: 'website',

    title: 'Smart Port Solution',
    date: '2024-01-25',
    tag1: '#SmartPort',
    tag2: '#RTLS',
    tag3: '#LocationTracking',
    tag4: '#DigitalTwin',

    // <br/>
    text: [
        {
            head: 'What is a Smart Port?',
            subHead: '',
            desc: 'A Smart Port is a comprehensive solution that monitors and manages all activities within the port in real-time using digital twin, RTLS, and artificial intelligence. This enables real-time monitoring and analysis of all objects and processes within the port. It helps collect and update crucial data such as the location, status, and transport information of containers, enabling port administrators to make quick decisions. In this blog, we will explore the concept and benefits of Smart Port solutions, focusing on the central technologies of digital twin and RTLS.',
            dot: ''
        },
        {
            head: 'Smart Port Technologies',
            subHead: 'Digital Twin',
            desc: 'Digital Twin is a technology that creates a virtual model accurately replicating physical systems, structures, or processes. It primarily generates a digital replica of port facilities and cargo transport processes, monitoring and optimizing them in real-time. It represents container locations, crane operations, vehicle movement paths, etc., in a digital twin model, allowing for predicting issues and enabling efficient operations.',
            dot: ''
        },
        {
            head: '',
            subHead: 'RTLS (Real-Time Location System)',
            desc: 'RTLS stands for Real-Time Location System, a technology that precisely tracks the real-time location of objects or moving objects. It mainly utilizes UWB, RFID tags, cameras, beacon technology, etc., to collect and manage accurate location information. This tracks the position of containers, trucks, and ships, contributing to the optimization of cargo transport routes, cargo status monitoring, and safety enhancement.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Artificial Intelligence (AI)',
            desc: 'AI technology identifies signs of anomalies or dangerous situations within the port using CCTV cameras. This is possible by collecting and analyzing various data generated in the port, improving logistics and transportation efficiency by developing predictive models. Additionally, using AI helps quickly detect and resolve issues during port operations.',
            dot: ''
        },
        {
            head: '',
            subHead: 'IoT',
            desc: 'Connects various systems and equipment within the port using sensor and communication technologies. It also collects necessary data to understand and manage the overall on-site environment, including temperature and humidity. This allows real-time optimization of port operations.',
            dot: ''
        },
        {
            video: video,
            link: '/en/enterprise/manufacturing/process',
            head: "Smart Port Features",
            subHead: "Real-time Inventory Management",
            desc: "Smart Port can track the real-time location of cargo using sensor technology and wireless communication. This enables port administrators to locate specific cargo at any time and efficiently manage the logistics process. Cargo tracking enables accurate ETA (Estimated Time of Arrival) planning, enhancing the efficiency and accuracy of logistics planning.",
            dot: ""
        },
        {
            head: "",
            subHead: "Operational Optimization with Digital Twin",
            desc: "Digital Twin is a technology that digitally models and manages port facilities or transportation means in real-time. It monitors the status of port facilities in real-time, optimizes maintenance schedules through predictive analysis, and efficiently operates logistics and transportation processes. Utilizing Digital Twin for operational optimization extends the life cycle of port facilities and enhances the efficiency of facility management.",
            dot: ""
        },
        {
            head: "",
            subHead: "Utilization of Autonomous Vehicles and Robots",
            desc: "Smart Port automates the transportation of cargo or specific tasks through autonomous vehicles and robots. Autonomous vehicles efficiently move cargo, while robots automatically perform necessary tasks, reducing labor costs and improving productivity. This automates cargo movement and work processes within the port, achieving higher efficiency.",
            dot: ""
        },
        {
            head: "",
            subHead: "Cost Reduction and Efficient Resource Management",
            desc: "Smart Port achieves cost savings and efficient resource management through the automation and optimization of logistics and transportation processes. The introduction of autonomous vehicles and robots reduces labor costs while increasing productivity. Additionally, optimizing facility management through Digital Twin reduces maintenance costs and improves energy efficiency.",
            dot: ""
        },
        {
            head: "",
            subHead: "Real-time Safety and Security Monitoring",
            desc: "Utilizing technologies such as RTLS and CCTV, Smart Port monitors the real-time location of transportation vehicles and personnel within the port, detecting signs of anomalies. This enhances the safety and security of the port by quickly responding to unauthorized access or safety issues. Real-time monitoring allows rapid response in case of accidents, improving safety for port facilities and personnel.",
            dot: ""
        },
        {
            head: "",
            subHead: "Data-driven Decision Making",
            desc: "Collects, analyzes, and builds predictive models using various data generated in the port using big data and artificial intelligence technologies. This allows port administrators to respond to changes in the environment in real-time and maximize operational efficiency through data-driven decision-making. Additionally, data analysis identifies and improves bottlenecks in the logistics process, enhancing overall productivity.",
            dot: ""
        },
        {
            head: "",
            subHead: "Environmentally Friendly Operation and Energy Efficiency",
            desc: "Smart Port aims for environmentally friendly operation in cargo transport and port facility management. Optimization of logistics and transportation processes minimizes fuel consumption and reduces carbon emissions by utilizing eco-friendly transportation means. Furthermore, facility management through Digital Twin and sensors optimizes energy consumption, increasing the energy efficiency of the port.",
            dot: ""
        },
        {
            head: 'Smart Port Outlook',
            subHead: '',
            desc: 'The outlook for Smart Port is expected to achieve even more innovative developments in the future. The expansion of autonomous driving technology is predicted to automate and streamline logistics and transportation processes, enhancing productivity. Operational optimization through Digital Twin and big data analysis will efficiently manage maintenance and operation schedules of port facilities. Emphasis on environmentally friendly operation and energy efficiency highlights sustainable port operations, minimizing energy consumption through the introduction of eco-friendly technology. Additionally, the advancement of real-time safety and security monitoring systems will enhance port safety and strengthen the ability to respond to unauthorized access. Smart Port is projected to provide more convenience and efficiency to the logistics industry and international trade, establishing itself as a hub for digitization and innovation.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'A product that helps with real-time location tracking by attaching UWB terminals to workers or equipment.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }
        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/en/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/en/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/en/nterprise/construction/equipment'
            }
        ]
    }
}
