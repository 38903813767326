import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

    helmet : '',
    desc:"アクティブRFID技術に基づくRTLSは電源を持つRFIDタグを使用し、より長い距離での位置追跡が可能であり、オブジェクトや人員の位置を正確にリアルタイムで把握し、さまざまな産業分野で活用されています。",
    keywords:"ActiveRFID、PassiveRFID、RTLS、位置基盤、位置測位、室内位置測位",

    ogTitle : '',
    ogDesc : '',
    ogSite : 'ActiveRFID技術基盤RTLS :: オブローブログ',
    ogUrl : 'https://orbro.io/jp/blog/activerfid',
    ogKey : '',
    ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOaF0taI55etnk-7VQVh_JecDxbhJX529VYv21nToxYe_qXD-w9k29bChDiW0Dj2JxEgZAtoeKjP5UhTJ0zB6tPZT6tGj5rygnex9J0bJt-k9_HhdDZPSetk87I3E-uDLFHG4xQF9ZgyEozwxxPAL_R0RPL-WNwKtkmGk1-d2GauxlTr2d4hGLWvllQl3_usiu46eWZqw76MEhqjCOs1HfYNn6nZM1_tRdsFDF2YeHeVM_nxLUKocvLFuA1xOhxMMkn2O2EW51rmnrEX66iYcxSgcpQix_5nMEcjBMJRqsrg2ULZuUeQ4R_-ji2u-RIAmKIt45RH7RVpxVW5jORDikiW-LbfzIQeDxdAPu8fq3k5o1IQ15lDVU7_nAae5wo1RxF_2JoYHgTqK1D5f6Zpt9eKA3OZReeim-MmTDklv6WC4CRnzWF1YS6efSa5G_vOTnieHKYY_QQ_w6mQlR-Lzzik_xNgPachPN-w_ls-iM2a-AtLnitE0S5O2xXPUlzsm_NyBM3NaA7BueOWETvdm64Es_4LjIsbnlQ-A_MIMbKZjiL7lIfWXUtNbYkpFryMZzUFeHXV7j72VtEmvkbmnMeGI120Co5kuvlpsFxOfmcuQTgwQPbRgsgFIWBkAnt9vla-Y4F3szREBBYSt44cgGIqknvJEv_7llpLaRXYYL0v1mKTnDRjXVKmGHugaxJNamcxLIHjGAgw_PfhHxMTYYeHs216wRGREOhMRsYnaIoi9httnwkNN911s1nK48m8YaTploLUIbMNoGasxH9yXlqXAcZUvDzbzAw4-bEzD4WBeX0pNQe9TuF3QnV2GlnklwVCLrWL8O4jE6jpOvW7KYMKIdAGPTkpHGBsViFHHX6nWFYHtsfaFKj8hjAUJmLrR_To65RSXQRHqfPye2voD_IDE6XhqnFK5Zi9gcsBgwZo7KQuihZcE_ctUyQOfFYvDJA8NILsJsvoGZQXQjPJvIaYmV9LfETM1MMCSL4AC4Vp4ZqQi7q6Fr1cvv1zrmYChkRYtxC00M5Eo2qg2-Qmb8SiANdUYeHxsSOLl5ybYsXBTbEUI9wY0jvT646VkLtHUf-AR9QdUnMqwj2YROz8lgVDZgZroHzXpzDUJaVt1BieJuuk5OLawO2eOrDN7VRU9i35NYRxx_eMOjeVvXJNJ7QxDISJgoCj_iLnilmShn8QmQfU5zINgqzKac6Ru0cJvScz5k_IQxEx_L9LXfqjaljUjhkI65UDijCW-u328M_DEEGeW9sYavREHwtpdY97oScs7VaBcxyOF2fi6XvP_5Gwig4GyydfN67d_49MmeMMSXVrJajT9i93QKvePas21Tiu8VaLtdSYFnuj_Vy53U1EJihuJXEQG4DoTm_I6UsnaCxf4Ymi0Asp-m2oZlcoywdlRKdj9W8x5uVqd2baDzd4mjNO_CBNMFTMmvGAMOS3XOi3jQYZ0a7tkbrcy1DSNhwv_Ds19DpQrDM5faV6GSaRtRfu-8hgESZSlBC_P3fJ9QPREO5n0TspEozjIrZbHoyhmP7yQB58K-7V3bpk-fKuaw3ruD64ZeIeFyOiJwJDig=w2059-h1216',
    ogType : 'website',

    twitterCard : '',
    twitterTitle : '',
    twitterDesc : '',
  
    
    title: 'ActiveRFID技術基盤RTLS',
    date : '2023-05-15',
    tag1 : '#RFID',
    tag2 : '#RTLS',
    tag3 : '#位置測位',
    tag4 : '#室内測位',
    
    // <br/>
    text : [
      {
        head : 'Active RFIDとは何ですか？',
        subHead :'',
        desc : 'Active RFIDは、デバイス間で情報を送信するワイヤレス通信形式です。Active RFIDタグには電源があり、長距離で信号を送信することができます。特にリアルタイムトラッキングアセットを測位するために理想的な製品です。<br/>Active RFIDタグは数百メートルまで読み取ることができるため、大規模なアセットトラッキングソリューションに最適です。',
        dot : ''
        },
        {
        head : 'Active RFIDとPassive RFIDの違い',
        subHead :'',
        desc : 'Active RFIDタグは内蔵の送信機と電源を持っているため、外部のリーダーに依存せず信号を発信することができます。Passive RFIDタグよりもはるかに高価ですが、より広い範囲をカバーします。Activeタグは通常、倉庫管理などの広範な範囲のトラッキングに使用されます。<br/><br/>Passive RFIDタグには電源がありません。彼らはRFIDリーダーから発せられる電力に依存して信号をアクティブ化および送信するため、Active RFIDタグよりもはるかに安価ですが、読み取り範囲はしばしば数メートルにすぎないことを意味します。パッシブRFIDタグは、小売在庫管理など、短い読み取り範囲で十分なアプリケーションに一般的に使用されます。',
        dot : ''
        },
        {
        head : '最近のRFIDの利用は？',
        subHead :'',
        desc : 'Active RFIDタグには、ビーコンタグとトランスポンダータグの2つの主要なタイプがあります。ビーコンタグは定期的に情報をブロードキャストし、範囲内のActive RFIDリーダーがこの情報を受信できます。トランスポンダータグは、Active RFIDリーダーから特定のクエリを受信した場合にのみ通信します。これにより、各タグはリーダーから情報をクエリするときにのみ情報を送信するため、よりターゲット指向のアセットトラッキングが可能です。',
        dot : ''
        },
        {
        head : 'Active RFID技術をRTLSソリューションと組み合わせることは可能ですか？',
        subHead :'',
        desc : 'RTLS（リアルタイムロケーションシステム）は、基本的にはActive RFIDの一種です。RTLSは、施設全体に配置された受信機が受信できる信号を発信するActive RFIDタグを通じて可能です。これにより、タグが指定されたアセットをほぼリアルタイムで測位できるため、アセットの位置に関する有益で包括的な情報を提供できます。',
        dot : ''
        },
        {
        head : 'RTLSソリューションの利点',
        subHead :'<br/>精度',
        desc : 'Active RFIDタグは、数cmまでの高い測位精度で位置することができることがあります。したがって、正確な位置情報が重要な医療や製造などのアプリケーションに適したActive RTLSを提供します。',
        dot : ''
        },
        {
        head : '',
        subHead :'リアルタイムデータ',
        desc : 'Active RTLSシステムは、タグが指定されたオブジェクトのリアルタイムの位置データを提供するため、常にアセットの位置を確認できます。これは小売業や物流など、時間が重要なアプリケーションに役立ちます。',
        dot : ''
        },
        {
        head : '',
        subHead :'柔軟な拡張性',
        desc : '人、パレット、コンテナなど、あらゆるタイプのアセットを測位するためにActive RTLSシステムを構成できます。GPS信号が弱いか存在しない屋内でもアセットを測位できます。タグと受信機の間に視線が必要ないため、高密度環境でのアセットの測位に最適です。これらの機能により、Active RTLSは幅広いアプリケーションに適しています。',
        dot : ''
        },
        {
        head : '',
        subHead :'カバレッジ',
        desc : 'Active RFIDタグの範囲は最大で100mですので、広範囲にわたって分散したアセットの測位に最適です。信号を長距離で送信できるため、車両などの大規模なアセットの測位に適しています。この情報はアセット管理やセキュリティの向上、在庫管理などの作業の自動化に活用できます。',
        dot : ''
        },
        {
        head : '<br/>Active RTLSベースのアセット測位システムの実用的な活用',
        subHead :'',
        desc : 'Active RTLSシステムは、以下のさまざまなアプリケーションに使用することができます。',
        dot : ''
        },
        {
        head : '',
        subHead :'医療',
        desc : '医療向けActive RTLSシステムは、病院内の患者、従業員、および機器の位置を測位するために使用されます。この情報は患者の管理と安全性を向上させ、病院のリソースをより効果的に管理するのに役立ちます。',
        dot : ''
        },
        {
        head : '',
        subHead :'製造業',
        desc : '製造向けActive RTLSシステムは、工場での原料、製品、および生産設備の位置を測位するために使用されます。この情報は製造の効率と品質管理を向上させるのに役立つことができます。',
        dot : ''
        },
        {
        head : '',
        subHead :'小売業',
        desc : 'Active RTLSシステムは、小売業で在庫の位置を測位するために使用されます。この情報は顧客サービスを向上させるのに役立ちます。',
        dot : ''
        },
        {
        head : '',
        subHead :'卸売業',
        desc : 'Active RTLSシステムは、倉庫や物流センターでパレット、コンテナ、およびその他のアセットの位置を測位するために使用されます。この情報は出荷の正確性と効率性を向上させるのに役立ちます。',
        dot : ''
        },
        {
        head : '',
        subHead :'',
        desc : '<br/>RTLSベースのアセット測位システムの実用性と柔軟性を考慮すると、費用対効果の高いシステムとして分類することができます。また、これらのRTLSシステムのコストは、過去数年間で着実に低下していることも注目に値します。',
        dot : ''
        }
    ],
  
  
    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/jp/enterprise/construction/asset'
        }, 
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/jp/enterprise/construction/safety'
        },
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/jp/enterprise/logistics/asset'
        }
      ]
  
    }
  
  }