
import { StyledGridContainer } from "@components/Grid/Grid.style";
import { CommonState } from "@redux/reducers/commonReducer";
import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  StyledBlog, StyledBlogCard, StyledBlogTextBox,
  StyledBlogImg, StyledBlogRow, StyledBlogMoreBtn,
  FontWeightNormal
} from "./Blog.style";

import { IconCalender, IconChevronDown } from "@assets/images/icon";
import BlogNewLetter from "../Common/BlogNewLetter";
import ModalDone from "@pages/Enterprise/Download/ModalDone/ModalDone";
import blog from "@src/lang/ko/blog/blog";

import * as banner from "../Common/BlogBannerImg";

import i18n from "@pages/i18n";
import SEO from "@pages/SEO";
import seo from "@src/lang/ko/seo";
import seoEng from "@src/lang/en/seo";
import seoJP from "@src/lang/jp/seo";
import seoCN from "@src/lang/cn/seo";
import { ImgWH, LabelColor, Spacing } from "@pages/CommonStyle/common.style";
import { DisplayH1 } from "@components/Text/HeadingTagDisplay.style";
import { HeadingH2, StyledHeading } from "@components/Text/Heading/Heading.style";
import { DivFlexRow, DivPadding } from "@pages/CommonStyle/commonDiv.style";


interface ProductCard {
  name: string;
  date: string;
  label: string;
  path: string;
}
const canlender = IconCalender
const chevronDown = IconChevronDown




const BlogPage = () => {

  const currentLang = i18n.language;

  // Translation
  const { t } = useTranslation();

  const location = useLocation();
  const isEnglishPage = location.pathname.startsWith('/en/');
  const isJapanPage = location.pathname.includes('/jp/');
  const isChinaPage = location.pathname.includes('/cn/');
  const navigate = useNavigate();
  useEffect(() => {
    if (isEnglishPage) {
      i18n.changeLanguage("en").then(() => {
        navigate("", { replace: true });
      });
    } else if (isJapanPage) {
      i18n.changeLanguage("jp").then(() => {
        navigate("", { replace: true });

      });
    } else if (isChinaPage) {
      i18n.changeLanguage("cn").then(() => {
        navigate("", { replace: true });

      });
    } else {
      i18n.changeLanguage("ko").then(() => {
        navigate("", { replace: true });
      });
    }
  }, [location.pathname]);

  // Redux
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);


  const [isSuccess, setSuccess] = useState<boolean>(false);
  const onSuccess = useCallback(() => {
    setSuccess(!isSuccess);
  }, [isSuccess]);



  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "name": "ORBRO",
      "position": 1,
      "item": "https://orbro.io"
    }, {
      "@type": "ListItem",
      "position": 2,
      "name": "Blog",
      "item": "https://orbro.io/blog"
    }, {
      "@type": "ListItem",
      "position": 3,
      "name": `blog.helmet`,
      "item": "https://orbro.io/blog"
    }]
  }

  const desc = t(`seo.blog.desc`);


  // Memos
  const productList: ProductCard[] = useMemo(() => blog.blogList, []);

  const [numberOfBlogsDisplayed, setNumberOfBlogsDisplayed] = useState(12);
  const displayedBlogs = productList.slice(0, numberOfBlogsDisplayed);

  const handleShowMoreBlogs = () => {
    setNumberOfBlogsDisplayed(numberOfBlogsDisplayed + 12);
  }

  let seoTitle;
  let seoKey;
  if (isEnglishPage) {
    seoTitle = seoEng.blog.title;
    seoKey = seoEng.blog.key;
  } else if (isJapanPage) {
    seoTitle = seoJP.blog.title;
    seoKey = seoJP.blog.key;
  } else if (location.pathname.includes('/cn/')) {
    seoTitle = seoCN.blog.title;
    seoKey = seoCN.blog.key;
  } else {
    seoTitle = seo.blog.title;
    seoKey = seo.blog.key;
  }

  return (
    <StyledBlog >

      <SEO
        lang={currentLang}
        helmetTitle={seoTitle}
        ogKey={seoKey}
        ogDesc={desc}
        ogImg='https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238'
        ogUrl={isEnglishPage ? "https://orbro.io/en/blog" : "https://orbro.io/blog"}
        jsonLd={jsonLd}
      />

      <Spacing pixel="64" />
      <StyledGridContainer>
        <DisplayH1 size="medium">
          <span>
            {'ORBRO '}
          </span>
          <FontWeightNormal>
            {"Blog"}
          </FontWeightNormal>
        </DisplayH1>
        <Spacing pixel="24"/>
        <StyledHeading size="xxsmall">
          <p>
            <Trans i18nKey={"blog.description"} />
          </p>
        </StyledHeading>

        <StyledBlogRow>
          {displayedBlogs.map((blog, index) => {
            return (
              <StyledBlogCard
                desktop={4} tablet={4} mobile={4}
                key={`blog-list-item-${index}`}
              >
                <Link to={currentLang !== 'ko' ? `/${currentLang}${blog.path}` : `${blog.path}`}>
                  <StyledBlogImg src={(banner[blog.name][layoutType])} alt="ORBRO Blog Thumbnail" />

                  <StyledBlogTextBox>
                    <Spacing pixel="24"/>
                    <DivFlexRow>
                      <ImgWH src={canlender} alt="Calender Icon" w={"16"} h={"16"} />
                      <DivPadding a={"0"} b={"0"} c={"0"} d={"6"}/>
                      <LabelColor size="small" color={"#818181"}>
                        <Trans i18nKey={`blog.blogList.${index}.date`} />
                      </LabelColor>
                    </DivFlexRow>

                    <Spacing pixel="8"/>
                    <HeadingH2 size="xsmall">
                      <Trans i18nKey={`blog.blogList.${index}.label`}/>
                    </HeadingH2>

                  </StyledBlogTextBox>
                </Link>
              </StyledBlogCard>
            )
          })}
        </StyledBlogRow>


        {productList.length > numberOfBlogsDisplayed && (
          <StyledBlogMoreBtn onClick={handleShowMoreBlogs}>
            <DivPadding a={"0"} b={"8"} c={"0"} d={"0"}/>
            <LabelColor size="medium" color="#FFF"> <Trans i18nKey={"blog.more"} /></LabelColor>
            <ImgWH src={chevronDown} alt="more" w={"16"} h={"16"} />
          </StyledBlogMoreBtn>
        )}

        {isSuccess && (
          <ModalDone onClickToggleModal={onSuccess}>
          </ModalDone>
        )}

        <BlogNewLetter onSuccess={onSuccess} />

      </StyledGridContainer>
    </StyledBlog>
  )
};

export default BlogPage; 