export default {

    banner: '現実をデジタル世界へ',
    banner2: '生々しく再創造されたデータプラットフォーム。',
    desc: '世界中には約10ゼタバイトもの膨大な情報が存在します。<br/>ORBROは、世界中の情報を仮想世界に視覚化し表現するプラットフォームです。<br/>現実と仮想、すべてがつながった新しい世界を体験してください。',
    over: 'ORBROは、地球上の意義深い情報をすべてリアルタイムに複製し、機能的に連携させるプロジェクトです。<br/>陸地、空、海、都市、人々や車の動きまで、より現実に近いデジタルの世界を感じることができます。',
    overSerial: [
    { text: '同じ世界' },
    { text: '同じ海' },
    { text: '同じ都市' },
    { text: '同じ動き' }
    ],
    
    alive: [
      {
          title: '迅速にスキャンする都市情報',
          titleColor: '#151515',
          text: '建物に関連する高さ、名称、住所、階数など、建物に関するさまざまな情報をORBROで確認できます。',
          textColor: '#151515',
          size: 'type2',
      },
      {
          title: 'より詳細なすべての建物を表示',
          titleColor: '#151515',
          text: 'ORBROでは、世界中のすべての建物とビル情報がそのまま視覚化され表示されます。アパートから公園、湖、建物、ランドマークまで、どこからでも詳細な情報を一目で直接確認できます。',
          textColor: '#151515',
          size: 'type4',
      },
      {
          title: '最も迅速に入手できる生活情報',
          titleColor: '#151515',
          text: 'バス停、ターミナル、空港、公共CCTVなどを表示し、木などのさまざまなオブジェクトも地図上に表示することができます。仮想オブジェクトにアプリを連携させて無限の拡張性を実現します。',
          textColor: '#151515',
          size: 'type4',
      },
      {
          title: '3Dを超えて映像化',
          titleColor: '#151515',
          text: '内部のIPカメラを通じて道路に設置されたCCTVなどの映像保存装置の送信情報がORBROと接続され、3Dマップ上でリアルタイムに表示されます。',
          textColor: '#151515',
          size: 'type2',
      },
      {
          title: '現実感を増した生き生きとした交通情報',
          titleColor: '#151515',
          text: '地下鉄の路線ごとの時刻表、高速道路、市外バス、航空、乗り合いなどのターミナル交通情報、公共交通機関の路線情報、さらには位置情報まで、膨大な量の公共ビッグデータをデータの可視化してリアルタイムに仮想空間に表示します。',
          textColor: '#151515',
          size: 'type5',
      },
      {
          title: '地図上を歩く人',
          titleColor: '#151515',
          text: 'ORBROインフラが設置された場所では、屋内外で動くすべての人物が地図上に表示されます。',
          textColor: '#151515',
          size: 'type2',
      },
      {
          title: '地図上を走る車両',
          titleColor: '#151515',
          text: 'ORBROでは、車両のすべての情報も地図上に表示されます。駐車された車両の様子だけでなく、リアルタイムの移動状況も一目で確認でき、交通量や混雑状況を把握し、交通の分散予測も可能にします。',
          textColor: '#151515',
          size: 'type4',
      },
      {
          title: '地図上を飛ぶ飛行機',
          titleColor: '#151515',
          text: '地下鉄の路線ごとの時刻表、高速道路、市外バス、航空、乗り合いなどのターミナル交通情報、公共交通機関の路線情報、さらには位置情報まで、膨大な量の公共ビッグデータをデータの可視化してリアルタイムに仮想空間に表示します。',
          textColor: '#151515',
          size: 'type4',
      },
      {
          title: 'リアルタイムな空港情報表示',
          titleColor: '#fff',
          text: '飛行機が到着する時間や空港のターミナル情報に基づいていつ離陸するかを一目で確認できます。ORBROは航空機の情報をリアルタイムで連携します。',
          textColor: '#fff',
          size: 'type2',
      },
      {
          title: '目で見る天気情報',
          titleColor: '#151515',
          text: 'ORBROでは、生活に密接に関連する天気情報も新しい体験として提供されます。興味のある場所の細かな環境情報など、微細粒子や超微粒子などの詳細な環境情報まで、地図上で視覚的に表示されます。',
          textColor: '#151515',
          size: 'type2',
      },
      {
          title: 'さらに詳しく知りたいですか？',
          titleColor: '#fff',
          text: 'ORBROの世界では、世界各地のすべての天気情報が接続されています。刻々と変化する世界各地の天気情報を、ORBROで出会うすべての場所で目で確認できます。',
          textColor: '#fff',
          size: 'type4',
          shadow: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(96, 96, 96, 0.2) 45%, rgba(255, 255, 255, 0) 95%)',
      },
    ],


    

    infoSub: '仮想空間で動作する現実世界',
    info: 'ORBRO、現実世界の<br/>情報を収める。',

    twinTitle: 'これが真のデジタルツインです',
    twinSub: '私たちが世界を<br/>見る方法です。',

    
    twin : [
      {
        title: 'Twin Tracker',
        text: 'Twin Trackerは、2.4GHzのBluetooth波の帯域をすべて測位する装置です。10年間の研究開発を経て、産業用普及が可能な全世界で初めての位置分析用ネットワーク装置です。',
        deviceInfo: {
          width: '95mm',
          height: '95mm',
          thickness: '32.5mm',
          specifications: ['USB C、PoE LANポート', '指向性アンテナ'],
        },
        buttonType: 'black',
      },
      {
        title: 'Twin Vision',
        text: 'Twin Visionは、ステレオカメラ技術とAI技術を活用した位置測位装置です。高度なディープラーニング技術を複合的に活用して、物体の動きをリアルタイムで測位し、ORBROの世界にターゲットオブジェクトを3Dモデリングで仮想化します。',
        deviceInfo: {
          width: '185mm',
          height: '40mm',
          thickness: '39mm',
          specifications: ['デュアル4 MPセンサー、2マイクロンピクセル', 'RTLS最適化イメージストリーミング', '高品質な画像キャリブレーション'],
        },
        buttonType: 'black',
      },
      {
        title: '室内位置を正確に分析',
        text: 'Twinデバイスを使用して、訪問者の立場と経路を検知し、滞在時間を含む正確な位置をリアルタイムで確認し、分析する最も正確な方法を提供します。',
      },
      {
        title: '細やかな経路データの蓄積',
        text: '水中に入った訪問者の様々な動きをリアルタイムで記録し、保存して、空間体験の改善に役立つデータとして活用することができます。どこを訪れ、どの速度で移動し、どれだけ滞在したかなど、詳細な分析を行います。',
      },
      {
        title: '訪問者ごとの経路測位',
        text: '訪問者は個別のID単位で区別され、動きの測位と経路分析が可能です。複数の訪問者が発生しても、訪問者ごとに経路や入退室時間、滞在時間、接触者などのすべての詳細データが記録され、タイムラインデータとして保存されます。',
      },
      {
        title: '時間帯ごとの利用者分析',
        text: '時間帯ごとに人々がどれだけ流入したかを確認できます。建物で最も多くの人々が動く時間を一度に知ることができます。流動人口分析により、マーケティング、広報、施設の再配置など、さまざまな形で応用することができます。',
      },
      {
        title: '空間のヒートマップ表現',
        text: 'ORBROと個人情報が組み合わさると、利用価値がさらに高まります。権限設定が必要な訪問者出入りシステム、出勤時間チェックなど、さまざまな管理方法に活用できます。',
      },
      {
        title: '個人情報連携の拡張性',
        text: 'ORBROとユーザー情報が組み合わさると、優れた情報に変わります。効率的なユーザー管理のために出入りシステム、既存のユーザーDB情報と連携してさまざまな方法で活用することができます。',
      },
      {
        title: '世界初の統合位置分析',
        text: 'ORBRO Twinは、世界初の映像分析と電波技術を統合した超精密な位置測位技術で、産業の革新を可能にします。',
      },
      {
        title: 'モバイル連携',
        text: 'ORBROは、電波測位技術を使用して、モバイル、タグ、電子機器のすべてを測位できます。モバイルを使用したユーザーの位置分析により、新しいインサイトを生み出すことができます。',
      },
      {
        title: '個人情報保護',
        text: 'ユーザーの貴重な個人情報の保護とセキュリティのため、ORBROは個人情報を一切サーバーに保存しません。',
      },

    ],


    accessTitle: '非接触入退室システム',
    accessSub: '未来的な<br/>新しい入退室方法',

    access: [
      {
        title: 'Access Pro',
        text: 'Access Proは、ユーザー情報に基づいて入退室権限を管理および制御するため、精密な入退室管理が必要な建物に役立ちます。',
        
        deviceInfo: {
          width: '160mm',
          height: '160mm',
          thickness: '38mm',
          weight: '258g',
          specifications: ['4チャンネル T&C エンジン', '2チャンネル Bluetooth 5.0', 'ラウンド TFT-LCD'],
        },
        buttonType: 'white',
      },
      {
        title: 'Access Lite',
        text: 'Access Liteは、特定の条件を持つすべてのユーザーに対して入退室権限を付与します。動きや簡単な認証手続きだけで入退室を許可する公共の場所やエリア管理場所に役立ちます。',
      deviceInfo: {
          width: '210mm',
          height: '60mm',
          thickness: '43mm',
          weight: '156g',
          specifications: ['レーダーセンサー', 'ノイズフィルター', 'Bluetooth 5.0'],
        },
        buttonType: 'white',
      },
      {
        title: '訪問者統計で洞察を得る',
        text: 'リアルタイムでさまざまな項目ごとの訪問者情報を統計情報として確認できます。',
      },
      {
        title: 'スペースごとの移動経路',
        text: '訪問者がどのような経路で移動しているか気になりませんか？ 個別の訪問者の移動経路を建物の階ごとに確認することができます。',
        
      },

    ],


    smartTitle: 'デバイスのリモート制御',
    smartSub: '遠くからでも作り出せる<br/>最適の空間体験',
    smart: [

      {
        title: '快適な空調制御',
        text: '',
      },
      {
        title: '簡単にライティング制御',
        text: '',
      },
      {
        title: '空気の質だけでなく<br/>温度情報も',
        text: '',
      },
      {
        title: 'すべての製品のための<br/>すべての機能',
        text: 'ORBROと対話するさまざまな製品は、オンラインとオフラインを自在に行き来しながら、完璧な体験をサポートします。',
        
      },
      
    ],


    size: 'サイズと重さ',
    width: '幅',
    thick: '厚さ',
    height: '高さ',
    weight: '重さ',
    product: '仕様',

    dream: '夢見た<br/>世界のすべての機能',
    app: '無限の拡張を目指すORBROアプリ',
    download: 'アプリをダウンロード',
    solution: 'さまざまなソリューションが用意されています。',
    more: 'もっと詳しく知る',


    platform: 'Platformを始める',
    exp: '現実とつながる新しい世界を体験してください。',
    start: '始める'






}