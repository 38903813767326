import React, { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  StyledPurchasePage, ImageWrapper, Image, StyledDescBox,
  StyledImgMain, StyledImgSubBox, OverlayImage
} from "./Purchase.style";

import { StyledGridColumn } from '@components/Grid/Grid.style';
import PurchaseDetail from "./PurchaseDetail";
import product from "@src/lang/ko/main/product/productImg";
import productDetail from '@src/lang/ko/main/product/productPurchase';
import { redDotLogo } from '@assets/images/logo';
import productPurchase from '@src/lang/ko/main/product/productPurchase';
import productPurchaseEng from '@src/lang/en/main/product/productPurchase';
import productPurchaseJP from '@src/lang/jp/main/product/productPurchase';
import productPurchaseCN from '@src/lang/cn/main/product/productPurchase';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CommonState } from '@redux/reducers/commonReducer';
import { setSelectedProduct } from '@redux/action-creators/common';
import i18n from '@pages/i18n';
import { ParagraphH1_purchase } from '@components/Text/HeadingTagParagrph.style';
import { ImgContainFit, Spacing } from '@pages/CommonStyle/common.style';
import { DivStickyTop } from '@pages/CommonStyle/commonDiv.style';




interface PurchaseProps {
  category: string;
  productName: string;
  setProduct: (value: string) => void;
  setImgGroupIndex: (i: number) => void;
}

const Purchase: FC<PurchaseProps> = ({ category, productName, setProduct, setImgGroupIndex }) => {

  //set the Product
  const productData = productDetail.purchase[category];
  const imgPath = product.product[productName];

  const location = useLocation();
  const [purchasePath, setPurchasePath] = useState<any>(productPurchase);


  useEffect(() => {


    if (location.pathname.includes('/en/')) {
      setPurchasePath(productPurchaseEng);
    } else if (location.pathname.includes('/jp/')) {
      setPurchasePath(productPurchaseJP);
    } else if (location.pathname.includes('/cn/')) {
      setPurchasePath(productPurchaseCN);
    } else {
      setPurchasePath(productPurchase);
    }

  }, [location.pathname])




  const selectedIndex = useSelector((state: CommonState) => state.selectedProduct);

  const [selectedImgIndex, setSelectedImgIndex] = useState(0);
  const [selectedImgGroupIndex, setSelectedImgGroupIndex] = useState(0);

  let subImages;
  let redDot;

  if (imgPath.length > 0) {
    subImages = [imgPath[selectedImgGroupIndex].mini1, imgPath[selectedImgGroupIndex].mini2, imgPath[selectedImgGroupIndex].mini3, imgPath[selectedImgGroupIndex].mini4];
    if (imgPath[selectedImgGroupIndex].redDot === 1) { redDot = true; }
    else { redDot = false; }
  } else {
    subImages = [imgPath?.mini1, imgPath?.mini2, imgPath?.mini3, imgPath?.mini4];
    if (imgPath.redDot === 1) { redDot = true; }
    else { redDot = false; }
  }

  const [isShowMobileNav, setIsShowMobileNav] = useState<boolean>(false);
  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;
      if (innerWidth > 768) {
        setIsShowMobileNav(false);
      } else {
        setIsShowMobileNav(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const dispatch = useDispatch();
  const [isOpenIndex, setIsOpenIndex] = useState<number>(0);

  const handleClickItem = (index: number, key: string) => {
    if (key === 'apart') {
      const languagePath = i18n.language !== 'ko' ? `/${i18n.language}` : '';
      const newWindow = window.open(`${languagePath}/request`, '_blank');
      if (newWindow) {
        newWindow.focus();
      }
    } else {
      setIsOpenIndex(index);
      setProduct(key);
    }
  }


  const handleDefaultItem = (index: number, key: string) => {
    setIsOpenIndex(index);
    setProduct(key);
  }


  useEffect(() => {
    if (selectedIndex !== 0) {
      handleDefaultItem(1, Object.keys(productData)[1]);
      dispatch(setSelectedProduct(0));
    } else if (location.pathname.includes('rtls') || location.pathname.includes('camera')) {
      handleClickItem(0, Object.keys(productData)[0]);
    } else {
      handleClickItem(0, Object.keys(productData)[0]);
    }

    //임시 하드코딩. 다른 오버뷰 페이지 제품 라인업 정리되면 통합 가능.
    if (location.pathname.includes("integration")) {
      const selectedProductString = localStorage.getItem('selectedProduct');
      const selectedProductInt = selectedProductString !== null ? parseInt(selectedProductString, 10) : 0;
      handleClickItem(selectedProductInt, Object.keys(productData)[selectedProductInt]);
    }
  }, []);


  useEffect(() => {
    // 제품 열 때마다 0번째 이미지
    setSelectedImgIndex(0);
  }, [isOpenIndex]); //


  const handleImageClick = (index) => {
    setSelectedImgIndex(index);
  };

  const handleImageGroupIndex = (index) => {
    setSelectedImgGroupIndex(index);
    setImgGroupIndex(index);
  };


  return (

    <StyledPurchasePage >


      {/** 왼쪽 이미지 **/}
      {/** 모바일 환경에서는 실행 X **/}
      {isShowMobileNav ? null : (
        <StyledGridColumn desktop={6} tablet={4} mobile={0}>
          <div>
            <DivStickyTop top={30}>
              <StyledImgMain>
                {redDot && <OverlayImage src={redDotLogo} alt='red dot winner logo' />}
                <ImgContainFit src={subImages[selectedImgIndex]} alt='RTLS Product' />
              </StyledImgMain>

              <Spacing pixel='32' />

              <StyledImgSubBox>
                <ImageWrapper>
                  <Image src={subImages[0]} isSelected={selectedImgIndex === 0} onClick={() => handleImageClick(0)} alt="RTLS product" />
                </ImageWrapper>
                <ImageWrapper>
                  <Image src={subImages[1]} isSelected={selectedImgIndex === 1} onClick={() => handleImageClick(1)} alt="RTLS product" />
                </ImageWrapper>
                <ImageWrapper>
                  <Image src={subImages[2]} isSelected={selectedImgIndex === 2} onClick={() => handleImageClick(2)} alt="RTLS product" />
                </ImageWrapper>
                <ImageWrapper>
                  <Image src={subImages[3]} isSelected={selectedImgIndex === 3} onClick={() => handleImageClick(3)} alt="RTLS product" />
                </ImageWrapper>
              </StyledImgSubBox>
            </DivStickyTop>
          </div>
        </StyledGridColumn>)
      }


      {/** 오른쪽 화면 **/}
      <StyledGridColumn desktop={6} tablet={4} mobile={4}>
        <StyledDescBox>
          <ParagraphH1_purchase size="medium">
            <Trans i18nKey={purchasePath.purchaseDesc[category]} />
          </ParagraphH1_purchase>

          {Object.keys(productData).map((key, i) => {
            const item = productData[key];
            return (
              <PurchaseDetail
                key={`purhcase-item-${i}`}
                productName={key}
                title={`productPurchase.purchase.${category}.${key}.title`}
                serial={`productPurchase.purchase.${category}.${key}.serial`}
                price={item.price}
                note={item.note}
                parts={item.parts}
                options={item.options}
                mark={item.mark}
                path={`productPurchase.purchase.${category}.${key}`}
                mainProduct={item.mainProduct}
                isOpen={isOpenIndex === i}
                handleClick={() => { handleClickItem(i, key) }}
                handleIndex={(index) => handleImageGroupIndex(index)}
              />

            );
          })}

        </StyledDescBox>

      </StyledGridColumn>


    </StyledPurchasePage>


  );
};

export default Purchase;
