import { ptzThumb } from "@assets/images/main/camera";

export default {


    default: {
        title: '오브로 | 위치추적과 디지털 트윈 전문 플랫폼',
        key: '위치추적, 자산관리, 자산추적, 창고관리, 창고 자산 관리, 실내 GPS, 실내 위치 추적, RTLS, 위치 추적 솔루션, 중대재해처벌법, 건설 현장 안전, 방문객 동선 분석, 출입 시스템, 4차산업혁명, 스마트팩토리, 디지털 트윈, 비콘, 블루투스, 콩테크, Kong-tech, Real-Time Location System, Indoor Location Tracking, Asset Tracking, asset management, asset tracking, warehouse management, warehouse asset management, indoor location tracking, RTLS, location tracking solutions, construction site safety, visitor path analysis, access system, 4th industrial revolution, smart factory, UWB RTLS, digital twin, beacon, Bluetooth, BLE, UWB, IoT',
        desc: 'uwb, rtls, 비콘, 출입 시스템, 위치추적 등, 최고 수준의 기술로 만들어진 제품과 스마트 시티, 디지털 트윈 솔루션을 제공합니다.',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: 'https://orbro.io',
    },


    orbroOs: {
        title: 'Orbro OS | 디지털 트윈 공간 관리 플랫폼',
        key: 'indoor tracking,rtls solutions,rtls,digital twin,indoor location tracking,iot rtls,실내 위치추적,위치추적,자산관리,자산추적,디지털 트윈,중대재해처벌법,콩테크, Kong-tech',
        desc: '디지털 트윈 플랫폼 오브로 OS는 현실의 공간을 가상에서 관리할 수 있습니다. 실시간 위치추적을 통해 자산을 관리하고 사람의 위치를 파악하여, 손쉽게 공간을 관리하거나 정보를 얻을 수 있습니다.',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYkZbw7aOzICkoQi8HNk9s2d6VpUL4SVgujeUxCxLThYcyYLFnsiLZyVx8NwEWTiJIKKeTqFcuFZF4-0GJiF5k8YyZKXtU1iL8Qas-_DGIZPtogRYL6cnYimywaHxSkEe97rBb410rPXE2LymoexrdTIuXxKNli61FgKo4m_7JzUZXofk9HBe1CmLCLjcaJ4N8CusSji3C-D-W9XEtpZ_pdbROqqHehQbzJf3Lu7a9r04vRKR5XY6SlOsaHQXgGvyvf2EwBUoRIK1DKgjGg5Zzpi5ql-8O8MWh8pyV_NMWerNhx8Km30j95bwAyArdMfGv2x8l5jdUBxychGtfT5SECI01jw3yjOvdPPWgw9EfhlRlUPENdWxGAM8tN8fPTKID1cOaskpvwlCYjMeExZpPCYAmw8IWLq_KmhgyxcGK287oaey7vTTQ_AFc816pvys48-p5vA5qpp1-3VCFZEEVoF90jtIKxMU5dWbpeElJFg99AybHdS6Ka8QD1-oq7K-N2rat8r8uPyJCc-UGK2DfU301JI-BlolwsoqI9JH2eomiXOFFsCyPfbMImXi-ZM0r5R7DAY5j3VqdTVq3x2AUK_VMlLkFSCcOzlpo6_hUaTgAXdIGIdtArstnx9UYRPj1qdvT2zhTSXu43gxG5dnRsO8WVC4H6qMoiotvH-f-8UkXMZOSjV3C9TmUS3zLAzMIeP5_wTbaf7Pk8kT9Cf_h6NFml9VUIPZ-0AVWUfsGfBgqD2U_ToCKiOtKLRwy1d50veOBzfrvRlhAUVUyQj3B6Jf4cXxAgJyp7PnJUXUPmpYr3UVmjjyx3-7N1f1TAf5TpHSChyMrenXXD_BlVEYe2DX2o_oaZVmkZ4K3cPSUoaQsgcalbV-QMpW-cys5PIo1H5DRYxfH5WzurFBEEqXF0h_RJZD8uBz7u-nF3a_sGEXbSV1sIj-5oqlm0WHswVhSyFLy1AsEPsWKNGoN4vAOdFN3bvKfDXcj22FbtQv7oqpj35v0fiK0uFjxzUli6LA0lOcxxBTDX8K3TGhhmgZSGVIVN-RtMSnnYrzqGJ2Gdpfhs6Z_lrTrIL4IE9EiVsbWkZRlk9Ung0oFwlX9E0bpnv_oBa8cEsinqQms8LDrCXle81kS5jg_WHHUVoTBYawhdKqeBHbGj67EcTW5OfDNT5b-0ivlXNSO2rj2MfdTIXcPXHrFF0O1x3x4e_aZhiXwhxOdQpRn38jnYIu8XKjS4Ot07PLLE0sdyPidowRf1mGv3Fe3VZbO1cPCMUGmwJwDb1ow5AYNQsCpv9wLFrfkTfb9z8_lyUPmzo4t7o8N4difD44FvoqkfHmwLBBPkYXimpJ9jrIz25oS_B70pubRYpO-ZMwo1aQ2McWHBUd9oBRTr27FuBGJS8RIlZ5nxyeOeZ-Tp_kOeKWr8bcB5yrd96BSV8Cgb5P43XLS9SMCZrZ7oM6e3JipkAMJDP7kLBtvCET1199BhDG6TF44RHfOKXYImbDVIdgmSdsIw6nhcXUIDGaQCifrmOru2TFa0v2Wwz4K-=w2006-h1238',
        url: 'https://orbro.io/os',
    },

    os: {
        beacon: {
            title: '비콘 위치추적 OS | 위치추적과 디지털 트윈 전문 플랫폼 ',
            desc: '',
        },
        rtls: {
            title: '실시간 위치추적 OS | 위치추적과 디지털 트윈 전문 플랫폼',
            desc: '',
        },
        camera: {
            title: '영상 위치추적 OS | 위치추적과 디지털 트윈 전문 플랫폼',
            desc: '',
        },
        access: {
            title: '출입 시스템 OS | 위치추적과 디지털 트윈 전문 플랫폼',
            desc: '',
        },
        sensor: {
            title: '산업용 센서 OS | 위치추적과 디지털 트윈 전문 플랫폼',
            desc: '',
        },

    },


    accessProduct: {
        title: '스마트 출입 시스템 | 오브로, 위치추적과 디지털 트윈 전문 플랫폼',
        key: 'Smart Access System, Access Control System, 실내 GPS, Access Management System, Smart Door Lock, Biometric Access System, RFID Access System, QR Code Access System, Remote Access System, Access Card System, Access Log Recording, 스마트 출입 시스템, 출입 제어 시스템, 출입 관리 시스템, 스마트 도어락, 생체 인식 출입 시스템, RFID 출입 시스템, QR 코드 출입 시스템, 원격 출입 시스템, 출입 카드 시스템, 출입 로그 기록,콩테크, Kong-tech',
        desc: '스마트폰으로 자동문을 제어할 수 있습니다. 더 빠르고 간단해진 오브로의 미래형 출입 시스템을 지금 확인해보세요.',
        img: 'https://lh3.googleusercontent.com/fife/APg5EObhIhVSc5l_YgEkV8ENDMUXJdxYXa-tWu2ob2CGj1T8JguKcr3Oh6Ywro_h3lU1aAEDTsm7Y33KDfdFlaO6IaSSuDYM6jnVKpOcIiAe0eCrjFHlDoXvptrgNbRQPpSce46Ge5-c77SpWj2XbOegeOH6WEQ54JhkhINdVTje6Mjr5QHnHYihwyoLoEY5brdu6vr7ku4EHibMaDRdaqhHwEnn_WCeTCmFTPbHm4KUoUp5saol3DT9810Q8H_Nmhgg9VvFYIn3JToTijZ5R2Vr2G7EHzbQmFSDSmkt60piBLbORnc52qceoWBGmSzhkMGsLF3sT3JdXCd2QPn-tC0MfwpaXWl5qm9-D4FMltwFgePmpiMZFDjy7O7pEwY-ScQ--sd2h6ptZnjjdO05ROX-m3AmdzXEEXvulJFRmx8ZyoryCIODzmathG0yJ0_vke5v6mE94-lyK8IFHisgptmGPzJG6m6-kkaK_EjlPxneaY8NDdT1cCxlyeAaHSCNgOps9RqcyrjM5BWr02C_P7cEswPoR9lT73V7zsWcTODPyUteFFSqvLjKrMZbQ269etOJjln97cW54V5y_3OplUpQ1Zt2-lppHEz_BnEgH_fsIfnyni9lbY1qP_-fdeXV6n3tlA3rNTSQZV5HLm5kzafsNPKWMzx3CKW-z7S6MxUpBGVCgA_fjiBt5YUoFQSwxmei1SlyV8zK-A3I5Yi-8DaPA5ty075aBejJ8r4fu0G_PvNx7dPUCjNgwfZOTgYXIXgKGZVv75WGo3qvXjP_BezP6LrsqC_Soid5hd1osgf6JmGsCSp7u0oPBw8KwjxUNHCWCIta4i0XFmK_6FkII6LV1KHbqmGiOoI75JSqjnneaPjueqkzBdZtOtDep3ynq0T_iTz_KsVWEHN9-zBjFAvintaIShl7gJRF__etrafFFB5wcImqgSJm7E5mO3CgRTRkaWfnI__wb3kx-6lby0oT2nII_K7cCJcE4fmn7xjaKW4Q6ql9_e9dHF7tri8sOpwP2AoBo7YgKD8QrNUPoPaGrUKpRLQwdnXChhEguPYTCZccG_agb8XmHBsrvRTXtMoGppbQM2j9KvMJ7VzAYAMpao7dxwFdgJwJMV_d08_UbF0k7eErXfDYFfODs6wHnTnNEVevxgq0sBog7O0-7M6CGTo-CnhP4I_gnMzeu-zHRbbjsK9cV9K7vlLnf8zhSGW9HkcZ659Gws5h13eSMKZjqqMQxZv5uRVqBCWOV3dhRbLHget2G3DfdjrJx-uFPWubEUAYfsp_vW6ZSJW6nKkY3r9WWDF-mea0DR1p72JASwZpyxzftA3-l-Lkz6Ruqk4uzF3MXpAIgsWMIfgBywUDTdxpkXEUmEWqa8CPqrfEDlNClOAOqypO2tLTeK3Fqn5ACl3jx6d3ScqvIMpqN3zW-J6HrLTpfK3cAJ1B9kxpqVzVuc0PwPYeQqOi-zFekLqzL08yNZgHmr_p7Ncq0dDn7IlkMIwfycNyiq6l9mlcQ9Q9Szk-Wze_Tcx-_rwXVmlocKD6lGRi=w2006-h1238',
        url: 'https://orbro.io/access',
    },


    rtlsProduct: {
        title: '실시간 위치추적 제품 | 오브로, 위치추적과 디지털 트윈 전문 플랫폼',
        key: '위치추적, 자산관리, 자산추적, 창고관리, 창고 자산 관리, 실내 GPS, 실내 위치 추적, RTLS, 위치 추적 솔루션, 중대재해처벌법, 건설 현장 안전, 방문객 동선 분석, 출입 시스템, 4차산업혁명, 스마트팩토리, 디지털 트윈, 비콘, 블루투스, Real-Time Location System, Indoor Location Tracking, Asset Tracking, asset management, asset tracking, warehouse management, warehouse asset management, indoor location tracking, RTLS, location tracking solutions, construction site safety, visitor path analysis, access system, 4th industrial revolution, smart factory, digital twin, beacon, Bluetooth, BLE, UWB, IoT,콩테크, Kong-tech',
        desc: '실시간 위치추적 제품은 자산 관리나 사람의 위치를 실시간으로 파악할 수 있습니다. 실내와 실외 모두 설치 가능하며 정확합니다.',
        img: 'https://lh3.googleusercontent.com/fife/APg5EObHma5EkyX2MCK6e2mdlbtvvAySBNi1Q6Sy0yCt3XGL-YOgvTEWmMgseI1eiYCBWZ6bCS-EHvV75JiwpvxCDhTKatr_wU7jMtlOwtex9EaelwxpicCYi7PgQNQIIe89wX_xVEPYAvj4dTBXP6aWIDMAio8cHy356CjoDnInZfZXqS-YvRCFK8gZBRMzv4fg1I1jHk0VTFtNo3GJEw7uBwbX5vckmGbYqiBPreTVy8b9t3iHACk5GKEEvGOH3EIN8L8g1V82KcZhn-iaqiRSpudUMASnFDyxdw-tEg7p8-qOAhypBhKaWsOFkTS_1Y3vi5hjRDHEKrNzeg7w9mDBoZdjbd3HaSR49-DFMFiaIjUjcdHeMGYNly4CBBoR0mOaNxvpFu2KLpVt683_8jSHK8YvRAZJCZFZlrUm5t1GRtOXjYbDgJ4scMmX9Agnjr24xOMup95HEUTukM9SFuxajBB8CTqfkX2g8seJ1H66X6zTjBqzzLdwQSRCvB82ItDZM1S4TCGMIQcO9gOGZM_QDFamVpM_RDBBrpMq2g5ErIYWBl3o7pZcOiAtlZOnfWCYagSLeUq4dvo963RZlRvA1XUMTAVVdTfmP8j3A0XcABOijSHaWSql7FtHiQuNE5kWlg91EZYLdnR5TmqD2ua88to5MCCiuJugKsEYTIA09FoIbPozNw3lJEvmKur3O2IRJmDjz5TA3UTHEHlLpBFACSyXXd70flICfMLlZIbkQqv9Ikzbell2A9-_cGDPw18kjU1i8doOYmKhg-fpQZhe0FRRBGtrZm2pEJruhGIjTYHfUrH5Q55qq1vMAFkb2OiCHNmhZ4IHhOL7iqZMyjuvXwMusC306K_yZFw5Md40a5K99WVQUhcXszyVefYM-tYlczn22kyfdM6hyCLQynNvs1bBNio2EdhXpe7tvwlpqQa9YJpnRfS8xS-UNd_3Tzlt5OlbXKZQFUL8NcGeeNyfYU9suciV7N2qFVw1go2Lf4HG9JlCNMgdIJG9gnM2-0bWM-E87vsxH4TvvgT8Y-8APLJlgZ5g0Y_jzTLaXWIEnn-9Z5Vw50MUNv2RTuKKzVlefLGlc24Y7bqebotC1PqdTAN5T4gWmJC33EsO7LmrZ1UpsoFhm3WzqTDTqZE_2MYpXGpNdm_FW3z44CvSgqwewSdmYW-O9kq9mTtjPGpKskUmQ7PlZJY1oKFy7f-vDHJE1PMGKunf6CtrA3pXnXNUls11PKhavf4RpmEnHzII85kSdHFKM_EJrVjVw6vb1qyW_QYLEQkUhM3PsLdc2WGbvGPiKRvIkxmeQ9cNzDPzyD8J-1tsibIhMrVrjzncEBP7r5iwtsqg5q8cZfwFJLFIF_EBh4V3JSqVHVj-pidYwaipmm0rxkhJGoppq5C3WrLM7R1uAiQBA3n1p1U2NAO2bNP3AcAsZUkuWuQbyKIm1kslrgY92ltA27GAc1crDXFbjE2M0AxBSYkKVYOcWpVRCC4FSv5itShGPqYGCd4rbtenPBRiMgtFRM32kdWGP1sWHx_g72cD=w2006-h1238',
        url: 'https://orbro.io/rtls',
    },

    cameraProduct: {
        title: 'AI 위치추적 제품 | 오브로, 위치추적과 디지털 트윈 전문 플랫폼',
        key: '위치추적, 자산관리, 자산추적, 창고관리, 창고 자산 관리, 실내 GPS, 실내 위치 추적, RTLS, 위치 추적 솔루션, 중대재해처벌법, 건설 현장 안전, 방문객 동선 분석, 출입 시스템, 4차산업혁명, 스마트팩토리, 디지털 트윈, 비콘, 블루투스, Real-Time Location System, Indoor Location Tracking, Asset Tracking, asset management, asset tracking, warehouse management, warehouse asset management, indoor location tracking, RTLS, location tracking solutions, construction site safety, visitor path analysis, access system, 4th industrial revolution, smart factory, digital twin, beacon, Bluetooth, BLE, UWB, IoT,콩테크, Kong-tech',
        desc: 'AI 카메라를 통해 실내, 실외의 유동인구와 차량의 위치를 실시간으로 파악하고 관리할 수 있습니다. 설치와 사용 모두 간단합니다.',
        img: ptzThumb,
        url: 'https://orbro.io/camera',
    },

    iotProduct: {
        title: '사물 인터넷 제품 | 오브로, 위치추적과 디지털 트윈 전문 플랫폼',
        key: 'Internet of Things, Smart Home, IoT Devices, Connected Devices, IoT Solutions, IoT Security, IoT Analytics, Industrial IoT, IoT Platform, IoT Sensors, 사물인터넷, 스마트 홈 기술, IoT 장치, 연결된 장치, IoT 솔루션, IoT 보안, IoT 분석, 산업용 사물인터넷, IoT 플랫폼, IoT 센서,콩테크, Kong-tech',
        desc: '위급 상황에서 빠른 신고를 위한 안심 벨. 당신의 안전이 조금이라도 걱정된다면, 오브로의 안심벨을 확인해보세요.',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYCH16slHwmaQG331-kV_CkBXnlfH1G9KrQOsfFjNqI7fC1rCmzgUg01Dizxv1tp9RuOIHrNhWUHRmxn0KTRaH1025_7yPSqXctHcfoKasdRVOxqYUom3UzM6ontDOvbiJwx021PbutfqbQZR8gLoT2Btt487SFAu-oBqfMkEaBftr6vSecMBx11J_KvKfDuVp79oWFJjP4HYEc9wJoel4oqj_B2CpPolo0ykC5oJ4MFipkMmGoCuDzxt-mKnQHqiGptEGd0eWtoriaBDaGGeDj18v3SSCdfRJQ9bBXN5M3YJRg2yBJVtFdlboqFrKw4CIvK0LqHyr_eZbrBFj56A_u9uLpgMoKT2azM0XOCFY4eKvjOUG26lUuFO-6TuATsfMAiHJs0pcKOEQfkj1O8ewSyT2Wn14K032rljAuc9rnAElCgFf0plndW4RmVaQAAEZeK3LFNctWNVdUvCz54tPSKulB5Om_n8r_pn-IgsU94nbquRGFkjfew_XBXhkNv5Moq90hwIyXy3WxBg0rAB8u3gkXB0EHJDBBqTOcr_-nE4F_wZ-BxsyMUURlg0m6vk23XftGlrZ1c-57Wdtgwk8YfIvK9GHsk9CwgEZ7e9JUTgb23if0YuTzlAob7bGBJGNrWbEOt35VKMT8BUzKT_lvQCALsHexYk-YO9LVzbHoAdwbYYqIyCg41xvkWltQu_w9Racs4ORCFBkfke1gsOvy51IFkmE2hqqrivGO8YLzNQ2zJwCaOw9Wsb0Cqj8PN3imRwa6VJ2IMENOkqjipW9hvnbn-U-Nm7IMjKTmCE5z-_I84D0-yJJLiKtfo3a7PJl0gCgEEBQTFseDbtyujfFv8BnW0Xvbhn6WsHIYiRmrx8HpB_8lTDuFFwFPan_SfMzoAicjyLHUQLeOSdYPhRNKbZ9Ah1i9ovTjy9rRqpF8XuHkiQPdcnOEoqmzlCdnXc0hFzORQZ6koWMG_nH8Car64KYjSp8uaPyXgKwPGRXc2F41TWuE1-kU0gjPssZAj2qNxkMVHom-lYdZ7dIabaFsmKGpQbTnafugm4e2lNmULTpFJQkzQnN25Y180bmsvRzCG-NroyymzpZGaicJCGjRKcKDMZ754a10zT3o2jAP11AwZVB3Em3mk8IRU8tMKja2TQItdDa0PXI-naiwGAbBgyo55z4gGfov-Xo-NWXbtBVMu3kZd2UYLglsjT_adJWLbPAw1Eja758K9PhE1l_9qQgBE7FNH5bYnxUD_ZvfZm_1-sP8uVnKbrgf2qcWQEnl9yaSRRWlCXwBN6HUDvMo1sM6L-t8oH-IJS40kNUQBEwfbjNXkWAFjf-QPzyvXaHrS_7qIS4-uFvl_f_lF08pp_INZIcfVz9dl2Ghi2bZ0lgUdwmxS8VeZYqIy-yFV1aImHAtYpQSV0TUw9Gvrqeh7XHSYQgEmXB5t6ky0FWmrZxsDfJ-KTbrFsZrl7Hsffa-zGtfUb4WfSmwfyfp2MamCB6S9lc9UThvhkKorxsD98tB1uDyy4Sak8o3jDNZw54krj5u=w2006-h1238',
        url: 'https://orbro.io/iot',
    },
    integration: {
        title: '연동 시스템 | 오브로, 위치추적과 디지털 트윈 전문 플랫폼',
        key: 'Internet of Things, Smart Home, IoT Devices, Connected Devices, IoT Solutions, IoT Security, IoT Analytics, Industrial IoT, IoT Platform, IoT Sensors, 사물인터넷, 스마트 홈 기술, IoT 장치, 연결된 장치, IoT 솔루션, IoT 보안, IoT 분석, 산업용 사물인터넷, IoT 플랫폼, IoT 센서,콩테크, Kong-tech',
        desc: '배송 로봇, 무인 상/하차 시스템, 소독 시스템 등 다양한 통합 솔루션을 통해 효율적이고 안전한 운영을 지원합니다. 오브로의 통합 시스템은 위치추적 기술과 실시간 모니터링을 통해 최적의 성능을 제공합니다.',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: 'https://orbro.io/integration',

    },



    home: {
        title: '오브로 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: '',
        key: '위치추적, 자산관리, 자산추적, 창고관리, 창고 자산 관리, 실내 GPS, 실내 위치 추적, RTLS, 위치 추적 솔루션, 중대재해처벌법, 건설 현장 안전, 방문객 동선 분석, 출입 시스템, 4차산업혁명, 스마트팩토리, 디지털 트윈, 비콘, 블루투스,Real-Time Location System, Indoor Location Tracking, Asset Tracking, asset management, asset tracking, warehouse management, warehouse asset management, indoor location tracking, RTLS, location tracking solutions, construction site safety, visitor path analysis, access system, 4th industrial revolution, smart factory, digital twin, beacon, Bluetooth, BLE, UWB, IoT,콩테크, Kong-tech',
        desc: '오브로 | ORBRO, 현실을 디지털 세상으로 생생히 재창조한 데이터 플랫폼.',

    },


    tech: {
        uwb: {
            img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
            url: '',
            title: 'UWB(Ultra - Wideband)란? | 오브로, 위치추적과 디지털 트윈 전문 플랫폼',
            key: '위치추적솔루션, 실내위치추적, GIS, UWB, RTLS, RTLS솔루션, UWB기술, 위치추적기술, 기술솔루션, UWB태그, Tdoa, TWR, AI기술,콩테크, Kong-tech',
            desc: 'UWB는 무선 통신 기술 중 하나로, 매우 넓은 주파수 대역폭을 사용하여 매우 짧은 시간 동안 매우 강력한 신호를 전송하는 기술입니다.',
        },
        ble: {
            img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
            url: '',
            title: 'BLE 위치추적 및 포지셔닝 기술 :: 오브로 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key: '위치추적솔루션, 실내위치추적, GIS, UWB, RTLS, RTLS솔루션, UWB기술, 위치추적기술, 기술솔루션, UWB태그, Tdoa, TWR, AI기술,콩테크, Kong-tech',
            desc: 'BLE은 무선 통신 기술 중 하나로, 전력 소모량이 적고 대신 데이터 전송 속도가 낮습니다. 주로 소형 기기나 저전력 센서에서 사용되며, 비컨과 같은 작은 발신기로도 활용됩니다.',
        },
        twr: {
            img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
            url: '',
            title: 'TWR 위치추적 기술 :: 오브로 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key: '위치추적솔루션, 실내위치추적, GIS, UWB, RTLS, RTLS솔루션, UWB기술, 위치추적기술, 기술솔루션, UWB태그, Tdoa, TWR, AI기술,콩테크, Kong-tech',
            desc: 'TWR (Two-Way Ranging) 기술은 위치 추적 기술 중 하나로, 발신기와 수신기 간에 오가는 신호의 왕복 시간을 측정하여 거리를 계산하는 방식입니다.',
        },
        tdoa: {
            img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
            url: '',
            title: 'TDoA 위치추적 기술 :: 오브로 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key: '위치추적솔루션, 실내위치추적, GIS, UWB, RTLS, RTLS솔루션, UWB기술, 위치추적기술, 기술솔루션, UWB태그, Tdoa, TWR, AI기술,콩테크, Kong-tech',
            desc: 'TDoA (Time Difference of Arrival) 기술은 위치 추적 기술 중 하나로, 발신기로부터 전파가 도착하는 시간 차이를 측정하여 수신기의 위치를 계산하는 방식입니다.',
        },
        stereo: {
            img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
            url: '',
            title: 'Stereo Vision을 이용한 위치 추적 및 포지셔닝 기술 :: 오브로 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key: 'Vision, 딥러닝, 위치, DepthEstimation, 위치추론, StereoVision, RTLS솔루션, RTLS기술,콩테크, Kong-tech',
            desc: 'Stereo Vision은 결합된 두 개의 카메라를 사용하여 객체를 3차원적으로 인식하는 기술입니다.',
        },
        mono: {
            img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
            url: '',
            title: 'Monocular Vision을 이용한 위치 추적 및 표지셔닝 기술 :: 오브로 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
            key: 'Vision, 딥러닝, 위치, DepthEstimation, 위치추론, MonocularVIsion, RTLS솔루션, RTLS기술,콩테크, Kong-tech',
            desc: 'Monocular Vision은 단안 카메라를 활용하여 이미지를 획득하고 분석하는 컴퓨터 비전 기술입니다. ',
        }


    },


    product: {
        title: '제품소개 :: 오브로 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
        key: '비콘, 실내위치추적기, UWB, BLE, RTLS, 단말기, 오브로, 오브로제품, GIS,콩테크, Kong-tech',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: '',
        desc: '오브로의 다양한 제품을 만나보세요',

    },



    blog: {
        title: '오브로 블로그 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
        key: '오브로, 오브로블로그, RTLS,콩테크, Kong-tech',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: 'https://orbro.io/blog',
        desc: 'RTLS와 관련된 블로그 글들을 확인해보세요.',
    },


    company: {
        title: '생동감 있는 가상세계 구현 :: 오브로 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
        key: '디지털트윈, DT, 메타버스, 공공정보, 3D월드, 오브로, 산업용플랫폼, 산업용메타버스플랫폼, ORBRO, 출입시스템, 공기관리, UWB, RTLS, 기술솔루션, 위치추적솔루션, 기술영업, 기술솔루션,피플앤테크놀러지',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: 'https://orbro.io/company',
        desc: '오브로는 200제타바이트 이상의 데이터를 3D 형상화하여 혁신적인 데이터 연결 플랫폼입니다.',
    },

    request: {
        title: 'ORBRO 문의하기 :: 오브로 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
        key: '오브로, ORBRO, 디지털 트윈, RTLS, IPS, GPS, GIS, MES, 중대재해처벌법, 비콘, 블루투스, 초광대역, BLE, UWB, IOT, 사물인터넷, 위치추적, 실내위치측위, 액세스, 에셋, 디지털전환, DX, 4차산업혁명, 스마트팩토리, 스마트시티, RTLS솔루션',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: 'https://orbro.io/request',
        desc: '디지털트윈, 실시간 위치추적, 실내 위치추적, 자산추적 기술과 관련해 궁금한 점이 있으시다면 편하게 문의바랍니다.',
    },

    platform: {
        title: '생동감 있는 가상세계 구현 :: 오브로 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
        key: '오브로, ORBRO, 디지털 트윈, RTLS, IPS, GPS, GIS, MES, 중대재해처벌법, 비콘, 블루투스, 초광대역, BLE, UWB, IOT, 사물인터넷, 위치추적, 실내위치측위, 액세스, 에셋, 디지털전환, DX, 4차산업혁명, 스마트팩토리, 스마트시티, RTLS솔루션',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: '',
        desc: '더 빠른 실내 위치 추적과 더 많은 자산을 간단히 관리 할 수 있습니다. 오브로 시스템은 강력합니다. 동시에 쉽고 간편합니다.',
    },

    pricing: {
        title: '견적 확인 :: 오브로 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
        key: '위치추적, 카메라 위치추적, ai 카메라, 출입 단말기, 출입 시스템, 자동문, ble, uwb, 산업용 센서, lora 중계기, 공기품질 측정기, 일산화탄소 측정기, 비상벨, 비콘, 산업용 비콘, 실시간 위치추적, 건설 안전 관리, 물류 재고 관리, 자산 관리, 환자 위치 추적, 제조 공정 관리, 스마트 솔루션, 방문객 분석, 오브로, 콩테크, orbro, rtls ',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: 'https://orbro.io/pricing/1',
        desc: '저희는 다양한 제품 및 서비스에 대한 무료 견적서를 제공하는 신뢰할 수 있는 파트너입니다. 고객의 요구에 따라 맞춤 견적을 제공하며, 가격 투명성과 고객 만족을 최우선으로 합니다. 견적 문의를 통해 효율적이고 경제적인 솔루션을 발견하고, 프로젝트를 진행하는 데 필요한 정보를 얻으세요.'
    },

    schedule: {
        title: '상담 예약하기 :: 오브로 | ORBRO, 위치추적과 디지털 트윈 전문 플랫폼',
        key: '위치추적, 카메라 위치추적, ai 카메라, 출입 단말기, 출입 시스템, 자동문, ble, uwb, 산업용 센서, lora 중계기, 공기품질 측정기, 일산화탄소 측정기, 비상벨, 비콘, 산업용 비콘, 실시간 위치추적, 건설 안전 관리, 물류 재고 관리, 자산 관리, 환자 위치 추적, 제조 공정 관리, 스마트 솔루션, 방문객 분석, 오브로, 콩테크, orbro, rtls ',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: '',
        desc: '저희는 다양한 제품 및 서비스에 대한 무료 견적서를 제공하는 신뢰할 수 있는 파트너입니다. 고객의 요구에 따라 맞춤 견적을 제공하며, 가격 투명성과 고객 만족을 최우선으로 합니다. 견적 문의를 통해 효율적이고 경제적인 솔루션을 발견하고, 프로젝트를 진행하는 데 필요한 정보를 얻으세요.'
    }



}