import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  StyledAppPage, StyledTextContainer, StyledDesc, StyledIconContainer, StyledIcon
  , StyledImageContainer, StyledImage, StyledIconWrapper, StyledIconText, StyledIconTextSub
} from './Banner.style';

import { rtlsBanner } from '@assets/images/main/index';
import rtls from '@src/lang/ko/main/rtls/rtls';
import { Link, useLocation } from 'react-router-dom';
import i18n from '@pages/i18n';
import { DisplayH1 } from '@components/Text/HeadingTagDisplay.style';
import { HeadingColor, ParagraphColor, Spacing, SpacingDTM } from '@pages/CommonStyle/common.style';
import { StyledTitleBanner } from '@pages/Main/Beacon/Beacon.style';
import { DivAllCenter, DivGrid } from '@pages/CommonStyle/commonDiv.style';
const bannerImg = rtlsBanner;

const Banner = ({ handleIconClick }): React.ReactElement => {

  const [selectedGroup, setSelectedGroup] = useState(1);


  useEffect(() => {
    setSelectedGroup(1);
  }, []); // 컴포넌트가 처음 마운트될 때 1번 이미지로 설정

  const location = useLocation();
  const handleOsType = () => {
    if(location.pathname.includes('beacon')){
      localStorage.setItem('selectedOs', `beacon`);
    } else if(location.pathname.includes('rtls')){
      localStorage.setItem('selectedOs', `rtls`);
    } else if(location.pathname.includes('camera')){
      localStorage.setItem('selectedOs', `camera`);
    } else if(location.pathname.includes('access')){
      localStorage.setItem('selectedOs', `access`);
    } else if(location.pathname.includes('sensor')){
      localStorage.setItem('selectedOs', `sensor`);
    } else {
    }
  }

  const currentLanguage = i18n.language;

  return (

    <StyledTitleBanner>
      <StyledAppPage>
        <StyledIconContainer>
          {rtls.bannerData.map((data, index) => (
            <Link to={currentLanguage !== 'ko' ? `/${currentLanguage}${data.link}` : `${data.link}`} key={index}>
              <StyledIconWrapper
                key={index}
                onClick={() => {
                  handleIconClick(index + 1);
                  handleOsType();
                }}
                selected={selectedGroup === index + 1}
              >
                <StyledIcon
                  src={data.iconInv} alt='ORBRO Rtls Product Icon'
                />
                <StyledIconText size='xsmall' color={index === 0 ? '#646464' : '#646464'}>
                  <Trans i18nKey={`rtls.bannerData.${index}.iconText`} />
                </StyledIconText>

                {data.sub === "New" &&
                  <StyledIconTextSub size='xsmall'>
                    <Trans i18nKey={data.sub} />
                  </StyledIconTextSub>}
              </StyledIconWrapper>
            </Link>
          ))}
        </StyledIconContainer>

        <StyledTextContainer>
          <DisplayH1 size="medium">
            <Trans i18nKey={`rtls.banner.title`} />
          </DisplayH1>
          <StyledDesc size='medium'>
            <p>
              <Trans i18nKey={`rtls.banner.desc`} />
            </p>
          </StyledDesc>
        </StyledTextContainer>

        <Spacing pixel='56' />
        <StyledImageContainer>
          <StyledImage src={bannerImg} alt="RTLS solution" />
        </StyledImageContainer>
        <Spacing pixel='56' />

        <DivGrid cnt={3} gap={32}>
          {rtls.banner.card.map((data, i) => (
            <>
              <DivAllCenter>
                <HeadingColor color={''} size={'xsmall'}>
                  <Trans i18nKey={`rtls.banner.card.${i}.title`} />
                </HeadingColor>
                <Spacing pixel='4' />
                <ParagraphColor color={'#818181'} size={'small'}>
                  <Trans i18nKey={`rtls.banner.card.${i}.desc`} />
                </ParagraphColor>
              </DivAllCenter>
            </>
          ))}
        </DivGrid>
        <Spacing pixel='56' />

      </StyledAppPage>
    </StyledTitleBanner>
  );
};
export default Banner;
