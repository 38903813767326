import { BleIconEnergy, BleIconMaximize, BleIconRss } from "@assets/images/technology/ble";
import React from "react";
import { useTranslation } from "react-i18next";
import Advantage, { AdvantagePropsItem } from "../Common/Advantage/Advantage";
import TechRequest from "../Common/TechRequest/TechRequest";
import TechTable from "../Common/TechTable/TechTable";
import TitleBanner from "../Common/TitleBanner/TitleBanner";
import UseCase from "../Common/UseCase/UseCase";
import { StyledTechnologyContent } from "../Technology.style";
import BleContent from "./components/BleContent/BleContent";
import SEO from "@pages/SEO";
import i18n from "@pages/i18n";
import CommonLang from "@pages/CommonStyle/commonLang";
import { FontPage } from "@pages/CommonStyle/commonDiv.style";
import { SpacingDTM } from "@pages/CommonStyle/common.style";
const advantageItems: AdvantagePropsItem[] = [
  {
    icon: BleIconEnergy,
    title: "ble.advantage.items.low.title",
    description: "ble.advantage.items.low.description",
  },
  {
    icon: BleIconRss,
    title: "ble.advantage.items.distribute.title",
    description: "ble.advantage.items.distribute.description",
  },
  {
    icon: BleIconMaximize,
    title: "ble.advantage.items.tech.title",
    description: "ble.advantage.items.tech.description",
  },
]

const BlePage = () => {

  const { t } = useTranslation();
  CommonLang();

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "headline": "BLE 위치추적 및 포지셔닝 기술",

    "datePublished": "2023-04-01T08:00:00+08:00",
    "dateModified": "2023-04-01T08:00:00+08:00",
    "author": [{
      "@type": "Organization",
      "name": "ORBRO",
      "url": "https://orbro.io"
    }]
  }
  const helmetTitle = t(`seo.tech.ble.title`);
  const key = t(`seo.tech.ble.key`);
  const desc = t(`seo.tech.ble.desc`);
  const currentLang = i18n.language;
  let isKorean;
  if (currentLang === 'ko') {
    isKorean = true;
  } else {
    isKorean = false;
  }

  return (
    <>
      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={key}
        ogDesc={desc}
        ogImg="https://orbro.io/images/efd31f916d6f6f83f983dc353992a2fd.png"
        ogUrl={isKorean ? "https://orbro.io/enterprise/technology/ble" : `https://orbro.io/${currentLang}/enterprise/technology/ble`}
        jsonLd={jsonLd}
      />

      <SpacingDTM d={"64"} t={"48"} m={"32"} />
      <FontPage>
        <TitleBanner
          subtitle={"ble.titleBanner.subtitle"}
          title={"ble.titleBanner.title"}
          description={"ble.titleBanner.description"}
        />

        <StyledTechnologyContent>
          <BleContent />

          <TechTable
            columns={['ble', 'wifi', 'uwb']}
          />

          <Advantage
            title={`ble.advantage.title`}
            items={advantageItems}
          />
        </StyledTechnologyContent>

        <UseCase type="ble" />

        <TechRequest />
      </FontPage>
    </>
  );
};

export default BlePage;