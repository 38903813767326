import { FlexStyle } from "@assets/styled/flex";
import React, { useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { StyledNews, StyledNewsArrow, StyledNewsItem, StyledNewsItemDate, StyledNewsItemIcon, StyledNewsItems, StyledNewsItemTag, StyledNewsItemTitle, StyledNewsNav, StyledNewsSubtitle, StyledNewsTitle } from "./News.style";

import ArrowLeftDisabled from "@assets/icons/ic-arrow-left-disabled.svg";
import ArrowRightDisabled from "@assets/icons/ic-arrow-right-disabled.svg";
import ArrowLeft from "@assets/icons/ic-arrow-left-light.svg"
import ArrowRight from "@assets/icons/ic-arrow-right-active.svg";

import ChevronRight from '@assets/images/company/news/icon-arrow-chevron-right.svg';
import { StyledGridContainer } from "@components/Grid/Grid.style";
import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";

import company from "@src/lang/ko/company";

import i18n from "@pages/i18n";
import { Spacing } from "@pages/CommonStyle/common.style";
export interface NewsItem {
  agency: string
  createdAt: string
  title: string
  link: string
}

const News = ():React.ReactElement => {
  // Translation
  
  // Redux
  const currentLanguage = i18n.language; // 현재 언어

  // 현재 언어를 기반으로 작업 수행
  if (currentLanguage === 'en') {
    // 영어로 작업
  } else if (currentLanguage === 'ko') {
    // 한국어로 작업
  }

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  // States
  const [totalSize, setTotalSize] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  // Memo
  const newsItems: NewsItem[] = useMemo(() => {
    const items = company.news.news;
    setTotalSize(items.length - 1);

    return items.slice(page * 10, (page + 1) * 10);
  }, [company.news.news, page]);

  // activeIcon
  const activeBefore = page != 0;
  const activeAfter = (page+1) * 10 < totalSize;

  // Icon Click
  const handleClickIcon = (type: 'before' | 'after') => {
    if (type === 'before' && activeBefore) {
      setPage(page - 1);
    } else if (type === 'after' && activeAfter) {
      setPage(page + 1);
    }
  }

  return (
    <StyledNews>
      <StyledGridContainer>
        <StyledNewsSubtitle size="xxsmall">
          <Trans i18nKey={"company.news.subtitle"} />
        </StyledNewsSubtitle>


        <StyledNewsNav>
          <StyledNewsTitle size="small">
            <Trans i18nKey={"company.news.title"} />
          </StyledNewsTitle>
          
          
          <FlexStyle>
            <StyledNewsArrow 
              src={activeBefore ? ArrowLeft : ArrowLeftDisabled} 
              width={layoutType === 'mobile' ? 24 : 36}
              onClick={() => handleClickIcon('before')}
              alt="arrow icon"
            />

            <StyledNewsArrow 
              src={activeAfter ? ArrowRight : ArrowRightDisabled} 
              width={layoutType === 'mobile' ? 24 : 36}
              onClick={() => handleClickIcon('after')}
              alt="arrow icon"
            />
          </FlexStyle>
          
        </StyledNewsNav>
        


        <StyledNewsItems>
          { newsItems.map((news, index) => (
            <StyledNewsItem key={`company-news-item-${index}`}>
              <FlexStyle>
                <StyledNewsItemTag size="xxsmall"><Trans i18nKey={ `company.news.news.${index + (page * 10)}.agency` }/></StyledNewsItemTag>
                <StyledNewsItemDate size="xxsmall"><Trans i18nKey={ `company.news.news.${index + (page * 10)}.createdAt` }/></StyledNewsItemDate>
              </FlexStyle>

              <Spacing pixel="8"/>
              <FlexStyle alignItems="center" justifyContent="space-between">
                <a href={`${news.link}`} target="_blank" rel="noreferrer">
                  <StyledNewsItemTitle size="small"><Trans i18nKey={ `company.news.news.${index + (page * 10)}.title` }/></StyledNewsItemTitle>
                </a>
                
                <a href={`${news.link}`} target="_blank" rel="noreferrer">
                  <StyledNewsItemIcon src={ChevronRight} alt="item icon"/>
                </a>
              </FlexStyle>
            </StyledNewsItem>
          )) }
        </StyledNewsItems>
        
      </StyledGridContainer>
    </StyledNews>
  );
};

export default News;