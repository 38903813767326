import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| 물류 업계 종사자가 알아야 할 최신 기술 트렌드',
  desc : '기존의 수동적인 재고 관리 방식에서 벗어나, 물품과 장비의 위치와 상태를 실시간으로 파악할 수 있게 되었습니다. 이를 통해 재고 부족이나 과잉 재고와 같은 문제를 방지하고 물류 비용을 절감할 수 있습니다.',
  keywords : '물류재고, 물류재고관리, 재고관리, 자산관리, 물류센터재고관리, 물류센터, 대형물류센터, 스마트물류, 스마트물류센터, 물류시설, 재고추적, RTLS, 실시간 위치 추적, 오브로, ORBRO, 콩테크',

  ogTitle : '',
  ogDesc : '',
  ogSite : '물류 업계 종사자가 알아야 할 최신 기술 트렌드 :: 오브로 블로그',
  ogUrl : 'https://orbro.io/blog/logisticsTech',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4PVu4kuy9M18H7FmFyW0Q0eESSyhQnDGg14oHiyiErzgaj9C6c04JTCmvEdjNFy1RXc-dFULyWRt2kEo4Cc08fuynbaorsmbVA1WZzOjFxamTouK1BSdqmJMWpihlk4UT2DwWY82EunIjppF5ShLp_E_SC2kVFAxBNtgKGuiHxsQedwiLBHpCzoo9wHtnqwDT_VLhRXu6kCMciziqklxTVzgLUnGfJx4fwSZCUeP_97fRfmoRUa74mRFh7J3r_WHTjKEM6lA3ewY1aFfygHCHH9YILBsyiOObeQqrP-rwrQsFFN9tegAJUWXqMBIdU0iZrmP8BE5aKgWUlkcv4BGfmKVIsOLypMPvOlrwLGc69gq-Ry-75Kq70RLYMXTIGNBeFCX1OoWSQXyi8xtlMbio4P-R3SYRfST4i-NaC0uTHKYNFw4X2pi32DpBC6ABiJ36qYntNOyIjav_Gxsj1J1hX7f11VXEpXn99emstdDgDQWVe0GJFpuy3S1BLOUnHHYb5Yp_2rUPdF5zaDip7wpsdirHI_gwcVPrVnx5OVX7sN5sH0v5olOvB6lhlq_3tH8L4f_Ih_I--vbGOsYnN246AvMx8BGjB3rLXnW38UK3Kv0JxhnL3evbCmOTKHnYQnAPMDby0LP_J1vGGTSKJB4saPBtfth2jWZCp_D33p2wPA9m3rIJ1GioOjoX8aVnl2McRmhcN0ee-3khHAkTH9Sw5Mselb8HbNlXC-5exUaPJUbLnSf3wU6xV6KyRieN_TsqtfixN6mgR2fSkpi2AA_Pn73zqkACZWSox-925fxgQtg_hoEFlaR4ctPdpWcXQ9V9EN1bMwxcXtV8NT2WDiF7MdEVpabwIUTZHPoFOPvkghuiqSMTiwQzCFgFwMqRR08jXN8Hbo4keOhSlyUhbuIcC0_vdkNI_yh_kUYLpdIlBOwk-amXrN-hdOwqTPkyuRmSAaKUPWgVhktIsP_WOlCXBncp4asz71pEvRywDGh_h9ou449w69BkNSjl7l-ODWpERmlmSTxjEkb3GGqjvn7S-DjtWWD1w_Xac7yZmMcNlenRvfma_bkN_2S4XW7zKnTl7H4gcmDtlt2igFE2Rdcg18vvfDs6vZJIAhmUZ_wo_-ki7qRtKsV-NZXEhxNCPtPbyxuVAdzRL7HWzn8ZpF3eK-2k87_zuS9aU9ofyaMoWiEXplANKhTzbhPz0Do_-QxjH9qHlnJot70a7mOr2kGcV9pRM_frc9CgYJC11tSxs6Mwoi3BKJkqZ7PlpCzTwyI9Fb0sBI3v-TvJO47RzrytQVLmvZdtB_divnOJkV6CNET7k5gTn2ICf7eQb_8YapYc_2eH8DW1YPqCN09n8C5uGq8lkpYedIdlXTsFHrVqUEluhRuaLZSegaV1oMdbzvS90L5WrpSKP-YPBc4D_OTgGXjA7Jha06Ccb_vGhacNAfFznQ7SJXS-Pp2ufPn2ymczEn4LHRbDw-f7upsbuw9uLEMWNSvzZsulGa4DjUZLuP2oEHwtbNu_qq8M1bYNyUzAAD4z9_lTWLQrG6ErWZVmxc1R-Qq-_71PIB1v_zizOFA2EeBYuNCXK_B0J4Hw=w2052-h1199',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '물류 업계 종사자가 알아야 할 최신 기술 트렌드',
  date : '2023-09-13',
  tag1 : '#물류센터',
  tag2 : '#글로벌물류',
  tag3 : '#SCM',
  tag4 : '#재고관리',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'물류 산업은 시간과 자원을 최적화하며 고객 서비스를 향상시키는 데 핵심 역할을 합니다. 그런데 현대 물류 업계에서 경쟁력을 유지하려면 최신 기술을 적극적으로 채택해야 합니다. 언제나 그래왔듯이, 새로운 혁신에는 새로운 기술이 따랐기 때문이죠. 최근 몇 년간 주목받고 있는 기술 중 하나가 RTLS(Real-Time Location System) 기술입니다. RTLS 기술은 여러 산업 군, 특히 물류 업계에 혁신적인 변화를 가져오고 있으며, 이 기술이 앞으로 어떻게 물류 산업을 혁신하고 성장시킬 수 있는지 살펴보겠습니다.',
      dot : ''
      
    },
    {
      head : 'RTLS 기술의 개념',
      subHead :'',
      desc : 'RTLS는 Real-Time Location System, 실시간 위치 추적 시스템을 의미합니다. 이 기술은 무선 통신, 센서, 데이터 분석 등 다양한 기술을 결합하여 물체, 장비, 또는 인력의 위치를 실시간으로 추적하고 관리하는 시스템입니다. RTLS 기술은 RFID(Radio-Frequency Identification), GPS(Global Positioning System), UWB(Ultra-Wideband) 등 다양한 기술을 활용하여 위치 정보를 수집하며, 이 정보를 중앙 시스템으로 전송하여 실시간으로 모니터링할 수 있습니다.<br/>그렇다면 이 기술이 물류 시설에 어떻게 적용될 수 있으며 무엇이 좋을까요?',
      dot : ''
      
    },
    {
      head : '물류 업계에서의 RTLS 활용 사례',
      subHead :'1. 재고 관리 및 유지',
      desc : 'RTLS 기술은 물류 업계에서 재고 관리에 혁신적인 변화를 가져왔습니다. 기존의 수동적인 재고 관리 방식에서 벗어나, 물품과 장비의 위치와 상태를 실시간으로 파악할 수 있게 되었습니다. 이를 통해 재고 부족이나 과잉 재고와 같은 문제를 방지하고 물류 비용을 절감할 수 있습니다. 그리고 기본적으로 이러한 재고 관리가, 직관적인 인터페이스 위에서 실시간으로 보여집니다. 전문 지식이 없는 누구라도 쉽게 이해하고 사용할 수 있는 시스템입니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'2. 자동화된 작업 프로세스',
      desc : 'RTLS를 활용하면 물류 작업 프로세스를 자동화할 수 있습니다. 예를 들어, 자동차 조립 라인에서 부품의 위치와 움직임을 추적하고, 로봇이 자동으로 부품을 가져다 쓰는 등의 작업이 가능해집니다. 이는 작업 효율성을 향상시키고 오류를 최소화하는 데 도움을 줍니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'3. 운송 및 배송 관리',
      desc : 'RTLS 기술은 운송 및 배송 과정에서도 중요한 역할을 합니다. 물품의 실시간 위치를 파악하면 운송 경로를 최적화하고 배송 일정을 관리하기가 더 쉬워집니다. 또한, 물품의 상태를 모니터링하여 손상을 방지하고 고객에게 더 빠르고 정확한 배송을 제공할 수 있습니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'4. 안전관리',
      desc : '실시간으로 추적할 수 있는 것은 자산 뿐만이 아닙니다. 현장에서 일을 하는 작업자와 중자비들을 추적하여, 위험 구역에 접근하거나 충돌이 위험이 있는 경우에 실시간으로 알람을 보내어 조치를 취할 수 있도록 합니다.',
      dot : ''
    },
    {
      head : 'RTLS의 장점과 가치',
      subHead :'1. 실시간 모니터링',
      desc : 'RTLS를 통해 물품, 장비 또는 작업자의 위치와 상태를 실시간으로 파악할 수 있습니다. 이로써 문제가 발생할 때 빠르게 대응하고 프로세스를 최적화할 수 있습니다.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'2. 정확한 데이터 수집',
      desc : 'RTLS는 정확한 위치 데이터를 수집하므로 재고 관리, 운송, 배송 등의 작업에서 오류를 최소화하고 물류 효율성을 높일 수 있습니다.',
      dot : ''
    },
    {
      head : '',
      subHead :'3. 비용 절감',
      desc : 'RTLS를 활용하면 인력과 자원을 효율적으로 관리할 수 있으며, 재고 관리 및 운송 과정에서 발생하는 비용을 줄일 수 있습니다.',
      dot : ''
    },
    {
      head : 'RTLS의 미래',
      subHead :'',
      desc : 'RTLS 기술은 계속해서 발전하고 물류 업계에 혁신을 불러올 것으로 예상됩니다. 머신 러닝과 인공 지능과의 결합을 통해 더 정교한 예측 및 최적화 기능을 제공하고, 보안 및 개인 정보 보호 측면에서도 발전될 것으로 기대됩니다. 물류 업계 종사자들은 이러한 기술 트렌드를 주시하고 적극적으로 도입하여 경쟁력을 유지하고 미래에 대비해야 합니다.',
      dot : ''
    },
    {
      head : '마무리',
      subHead :'',
      desc : '물류 업계 종사자들에게 RTLS 기술은 혁신적인 가능성을 제공하며, 물류 프로세스를 효율화하고 비용을 절감하는 데 도움을 줍니다. 이 기술은 물류 업계의 미래를 주도할 중요한 역할을 할 것으로 예상되므로, 적극적으로 이를 채택하고 적용하는 것이 필요합니다. RTLS 기술을 효과적으로 활용하여 물류 업계의 성장과 발전에 기여합시다.',
      dot : ''
    },

  ],

  link : {
    page : 'rtls',
    numb : '1',
    product : [
        {
          text : productPurchase.purchase.asset.assetTracking.title,
          title : productPurchase.purchase.asset.assetTracking.title,
          serial : productPurchase.purchase.asset.assetTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.assetTracking.key1
        },
      {

      }

    ],
    solution : [
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/enterprise/logistics/asset'
      },
      {
        img : logisticsSafety.thumb,
        data : 'logisticsSafety',
        link : '/enterprise/logistics/safety'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/enterprise/manufacturing/asset'
      }
    ]

  }


}