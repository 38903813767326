import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
export default {

  helmet: '',
  desc: '患者をリアルタイムで追跡し、医療スタッフと資産を効率的に管理して治療プロセスを最適化します。さらに、人工知能とデータ分析を活用して患者の健康状態を監視し、最高の医療サービスを提供します。',
  keywords: 'スマート病院、患者管理、RTLS、医療施設、患者フロー、医療機器追跡、病院機器、位置追跡、コンテック',

  ogTitle: '',
  ogDesc: '',
  ogSite: '最先端の病院が患者を管理する方法 :: オルブロ ブログ',
  ogUrl: 'https://orbro.io/jp/blog/hospital',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZqhO9ezvqYIEyhhFrpVaYpW4QqZ73QOwXB7Atab7eG0KzsPeNRlfan0xcjBoTA4f9uOZsyyO3Aa7Ssy20vkBaALX1WP-ZEFqJmbRtvf8byaL_7pF89VhW9s1FZpdgvvCcvntuFyR8L7F4TIbbBzU78ARievgQnwDB_lzVgDXzEUFwCVB3-YfvTStDIeeQbBMYwXBRqeTxImshBEbYzfSrxzifccZi8NjY7581Zfdx8FsHKQxodIhZ29ZLBf2hUBttfK25Bt_5cKO11xm0Ub77S_b5M7F80YBvm8c9d8HWNm2lKwa0B9J7xK_8AnjeHNyqgk5fDREyJMJtElXsGgAsVr4esxClp1Jo-1Bu4q7FZ6x6Vst2Bdg9fgVC2FfZUkW0TMJf0TGQk4IHEdSi_tX-lHR-NuUPpslEUUTKpNuIBLM4trADP3kW3TMh-nuxDZ-W97b7Ml0EZEEhU_byAAqaH1P86CAJailGfFlXoPXY7se0SnBVgVcp9Bq4oNt8ejbJM6DuC3jMYWKNEsdSLoS9KuVf-cxKYrIx6HNENPs_tUYxZuA8kkIdeex_X5CkYZg8Z95iFRrmuR6-k-fS0ykwlLfiCPjbw_qyngo2P9vn2q-NkvMYRE1SUtS7MaMu1kDIk_f3yCqDzaDk8LIG9f42EzTAFM1QJxb6J39afoj_lF1hQOzM3OIyqithxbE4wq6iz0b70zB9MBMH2CP8btw5pT21L7GNXDa7w7dum6t7fiFkb249GmTOEbKr4brj6ZswEBzV6IsL4E9idjImLI5m-DrXPUUItPct4kt65sjT1yGoaEwqGk9lgbJOQWbI8UIk0WxzjIQEPjGj2Lw65GMqn5jj0k1LiyzE63GmSC4jyYG01Uk1oZQKuQVAiAIc0WNEPNlZtaufLrWeok3p-c-ZYIUvROQi8hyKxfZIWTtQC689Njt_974ehQ2JaJoUdkvHm4fWMuz1O2D_spUzxRTiztHX1N0uxC0Chhro0SH5fnIte5uAmlAlKfI5rAyDpNtSj1fDdPQJkntk-13pn6xAChyiCLn3jL8NaIu14FzJC4Hv9ZejC45VzaQYxl1UJkgC4eTuaJwLW2TWhJjYNcSXBV51iVuF73QisAxqadKjRmpLmZnEob03V2CkJ7hOZK-dqMjC3dtTeuFT4cqOotx_vFMdxjW83kXdCMWXqCVnL513yFcpJwBAWdniR9rrl-xjdIGyuZH-4v_2pFtpIUpdZkPIcRkw6KdnBbfzMjjIAu6ROi9gnSv7owjl5Dk_LeLqdKH98zhYoIqXrM9Z0FqvBgFdW2JqvTxkeOfRi1893ZVLvD4gXR1Nojbgnql_FwANyT5tVh5EPYyt2XvB9fCInBjzYkhHlZ2MPF92quV6_Ym9AkThfWXdPiC7vwKrQUT9B5CD5PpaoTyOzE8g1_-HydegZuWxfPLGCfb7VajMbW2hwA0uoC6vED-mcbtpZTKu2KCMH7CMEH-oAhQhRF-k4WkAGRz0Vuj4OY5OskCr4Jq8vQl-pEdGgh8b8T6Nnxx8mw7DohsssWwC9y4T1BIrPLZYGHhmRq2JJLKbJKrMRJA=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '最先端の病院が患者を管理する方法',
  date: '2023-08-21',
  tag1: '#スマート病院',
  tag2: '#患者追跡',
  tag3: '#患者管理',
  tag4: '#患者安全管理',

  text: [
    {
      head: '',
      subHead: '',
      desc: '病院ほど健康に密接に関わる場所はあるでしょうか？健康が人生でおそらく最も重要な側面であるため、患者は病院を選ぶ際に最高の基準を設定するかもしれません。自分をしっかりとケアしてくれるという信頼と、その信念を支える医療技術。これらの2つの条件が保証されていれば（合理的な範囲内で）、患者はより良いサービスに対して積極的に支払いをする用意があるかもしれません。医療施設の管理者や業界関係者は、より良いサービスとビジネスのために先端技術にますます関心を持つ傾向があります。ただし、多くの人々が医療技術に焦点を当てる傾向があり、他の印象的な技術やシステムを見落とすことがよくあります。その代表的な例が、急速に成長しているリアルタイム位置追跡技術、RTLS技術です。',
      dot: ''
    },
    {
      head: 'リアルタイム位置追跡システム、RTLSとは？',
      subHead: '',
      desc: 'RTLSとは、文字通り対象物のリアルタイムの位置を追跡する技術です。ここまで聞く限り、おそらくお馴染みのGPSとあまり変わらないと考えるかもしれません。位置を追跡するという点では同じように思えますが、実際には2つの技術には大きな違いがあります。まず、RTLS技術は室内でもはるかに精度の高い位置追跡が可能です。例えば、迅速な判断や対応が必要な状況でも、管理者や関係者にリアルタイムのアラートを送信できます。もちろん、RTLS技術自体がこれらの機能を必ずしも意味するわけではありませんが、技術的な力量がある企業であれば、さまざまな機能を備えたシステムを構築できます。では、病院で何が可能になるのでしょうか？',
      dot: ''
    },
    {
      head: '',
      subHead: '医療機器の追跡',
      desc: '間もなく、ある病院で医療事故が発生しました。汚染された医療機器の不適切な取り扱いによる感染事故でした。そうした事故に遭遇した場合、あなたが病院の管理者だったらどうしますか？従来のプロトコルに従うのも一つの方法かもしれませんが、もし病院のスタッフに（望ましくないことを願いますが）怠慢の可能性がある場合、または病院の設備取り扱い担当者が以前に一度も間違えたことがない非常に熱心な働き手だった場合、どうでしょうか？事実として、人間のミスは起こりやすく、結果は不確実です。RTLS技術は、そのような事故の可能性を防ぐのに役立ちます。汚染された機器は、モニターを通じて管理者がリアルタイムで監視できます。特定の経路を越えると自動的にアラートを送信するシステムも可能です。完全かつ安全に処理されるまで、スマートシステムが監視します。',
      dot: ''
    },
    {
      head: '',
      subHead: '危険区域の管理',
      desc: 'RTLSシステムは安全な施設を維持するために、制限エリアを設定できます。そのエリアに誰かが入ると、迅速な対応のために管理者にアラートが送信されます。例えば、病院で働いていて、突然、患者Aが医薬品保管エリアにアクセスしているとの警告が表示されたとします。画面を見ると、患者Aが別館の医薬品保管ドアの前に立っていることがわかります。幸いにも、素早く警備員を呼び出し、事故を未然に防ぐことができました。',
      dot: ''
    },
    {
      head: '',
      subHead: '拡張性の向上',
      desc: 'RTLS技術は、上記に述べた機能を必ずしも提供するわけではありません。ただし、RTLSを通じてさまざまな機能を備えたシステムを作成できることを意味します。転倒検知技術の統合やOrbroのアクセスシステムを使用して何も見逃さないようにし、すべてが適切に処理されるスマートな医療施設を作成できます。前述のとおり、完全で安全です。また、ボトルネックのリアルタイム制御や施設内の資産の位置と数量の迅速な評価は、運用の観点から効率的に実現できます。人員とコストは自然に減少しますが、生産性は実際に向上します。',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を労働者や設備に取り付けて、位置追跡をリアルタイムで支援する製品です。',
        img: productImg.product.workerTracking.thumbnail
      },
      {
        // 追加の製品が必要な場合
      },
    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/jp/enterprise/medical/patient'
      }, 
      {
        img: medicalWaste.thumb,
        data: 'medicalWaste',
        link: '/jp/enterprise/medical/waste'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/jp/enterprise/logistics/asset'
      }
    ]
  }
}

