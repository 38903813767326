import {
    functionImg1D, functionImg1M, functionImg1T,
    functionImg2D, functionImg2M, functionImg2T,
    functionImg3D, functionImg3M, functionImg3T,
    functionImg4D, functionImg4M, functionImg4T,
    functionImg5D, functionImg5M, functionImg5T,
    functionImg6D, functionImg6M, functionImg6T,
    functionImg7D, functionImg7M, functionImg7T,
} from "@assets/images/main/access/components/index";

export default {

    functions: {

        title: 'User-Friendly Software<br/>Accessible to Everyone',
        desc: 'Orbro has developed its software based on 8 years of access management experience. Designed with user feedback and requirements in mind, it maximizes convenience in the field. This is a product developed in response to the needs of actual users, providing real benefits in access management.',
        line: 3,
        card: [
            {
                size: '4',
                tag: 'User Registration',
                title: 'Invite Users by<br/>Phone Number or Email',
                label: 'Administrator',
                img: {
                    desktop: functionImg1D,
                    tablet: functionImg1T,
                    mobile: functionImg1M
                },
            },
            {
                size: '4',
                tag: 'Access Rights Management',
                title: 'Easy<br/>Access Rights Management',
                label: 'Administrator',
                desc: 'Simply register devices allowed<br/>for access to create access rights.',
                img: {
                    desktop: functionImg2D,
                    tablet: functionImg2T,
                    mobile: functionImg2M
                },
            },
            {
                size: '2',
                tag: 'Excel Download',
                title: 'Download Access Records<br/>and User Information',
                label: 'Administrator',
                img: {
                    desktop: functionImg3D,
                    tablet: functionImg3T,
                    mobile: functionImg3M
                },
            },
            {
                size: '2',
                tag: 'Remote Control',
                title: 'Remote Control of<br/>Access Doors',
                label: 'Administrator',
                img: {
                    desktop: functionImg4D,
                    tablet: functionImg4T,
                    mobile: functionImg4M
                },
            },
            {
                size: '2',
                tag: 'Automatic Entry',
                title: 'Access Without<br/>Opening the App',
                label: 'User',
                img: {
                    desktop: functionImg5D,
                    tablet: functionImg5T,
                    mobile: functionImg5M
                },
            },
            {
                size: '4',
                tag: 'Mobile Widgets',
                title: 'Widgets for Quick App Launch',
                label: 'User',
                img: {
                    desktop: functionImg6D,
                    tablet: functionImg6T,
                    mobile: functionImg6M
                },
            },
            {
                size: '4',
                tag: 'Automatic Entry',
                title: 'Easily Set<br/>the Door Opening Distance',
                label: 'User',
                img: {
                    desktop: functionImg7D,
                    tablet: functionImg7T,
                    mobile: functionImg7M
                },
            },
        ]
    },

}
