import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
export default {

  helmet: '',
  desc: 'Tracking patients in real-time and efficiently managing medical staff and assets to optimize the treatment process. Additionally, utilizing artificial intelligence and data analysis to monitor patient health status and provide the best medical services.',
  keywords: 'Smart Hospital, Patient Management, RTLS, Medical Facilities, Patient Flow, Hospital Equipment Tracking, Hospital Equipment, Location Tracking, ConTech',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'How Cutting-Edge Hospitals Manage Patients :: Orbro Blog',
  ogUrl: 'https://orbro.io/en/blog/hospital',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/APg5EOZqhO9ezvqYIEyhhFrpVaYpW4QqZ73QOwXB7Atab7eG0KzsPeNRlfan0xcjBoTA4f9uOZsyyO3Aa7Ssy20vkBaALX1WP-ZEFqJmbRtvf8byaL_7pF89VhW9s1FZpdgvvCcvntuFyR8L7F4TIbbBzU78ARievgQnwDB_lzVgDXzEUFwCVB3-YfvTStDIeeQbBMYwXBRqeTxImshBEbYzfSrxzifccZi8NjY7581Zfdx8FsHKQxodIhZ29ZLBf2hUBttfK25Bt_5cKO11xm0Ub77S_b5M7F80YBvm8c9d8HWNm2lKwa0B9J7xK_8AnjeHNyqgk5fDREyJMJtElXsGgAsVr4esxClp1Jo-1Bu4q7FZ6x6Vst2Bdg9fgVC2FfZUkW0TMJf0TGQk4IHEdSi_tX-lHR-NuUPpslEUUTKpNuIBLM4trADP3kW3TMh-nuxDZ-W97b7Ml0EZEEhU_byAAqaH1P86CAJailGfFlXoPXY7se0SnBVgVcp9Bq4oNt8ejbJM6DuC3jMYWKNEsdSLoS9KuVf-cxKYrIx6HNENPs_tUYxZuA8kkIdeex_X5CkYZg8Z95iFRrmuR6-k-fS0ykwlLfiCPjbw_qyngo2P9vn2q-NkvMYRE1SUtS7MaMu1kDIk_f3yCqDzaDk8LIG9f42EzTAFM1QJxb6J39afoj_lF1hQOzM3OIyqithxbE4wq6iz0b70zB9MBMH2CP8btw5pT21L7GNXDa7w7dum6t7fiFkb249GmTOEbKr4brj6ZswEBzV6IsL4E9idjImLI5m-DrXPUUItPct4kt65sjT1yGoaEwqGk9lgbJOQWbI8UIk0WxzjIQEPjGj2Lw65GMqn5jj0k1LiyzE63GmSC4jyYG01Uk1oZQKuQVAiAIc0WNEPNlZtaufLrWeok3p-c-ZYIUvROQi8hyKxfZIWTtQC689Njt_974ehQ2JaJoUdkvHm4fWMuz1O2D_spUzxRTiztHX1N0uxC0Chhro0SH5fnIte5uAmlAlKfI5rAyDpNtSj1fDdPQJkntk-13pn6xAChyiCLn3jL8NaIu14FzJC4Hv9ZejC45VzaQYxl1UJkgC4eTuaJwLW2TWhJjYNcSXBV51iVuF73QisAxqadKjRmpLmZnEob03V2CkJ7hOZK-dqMjC3dtTeuFT4cqOotx_vFMdxjW83kXdCMWXqCVnL513yFcpJwBAWdniR9rrl-xjdIGyuZH-4v_2pFtpIUpdZkPIcRkw6KdnBbfzMjjIAu6ROi9gnSv7owjl5Dk_LeLqdKH98zhYoIqXrM9Z0FqvBgFdW2JqvTxkeOfRi1893ZVLvD4gXR1Nojbgnql_FwANyT5tVh5EPYyt2XvB9fCInBjzYkhHlZ2MPF92quV6_Ym9AkThfWXdPiC7vwKrQUT9B5CD5PpaoTyOzE8g1_-HydegZuWxfPLGCfb7VajMbW2hwA0uoC6vED-mcbtpZTKu2KCMH7CMEH-oAhQhRF-k4WkAGRz0Vuj4OY5OskCr4Jq8vQl-pEdGgh8b8T6Nnxx8mw7DohsssWwC9y4T1BIrPLZYGHhmRq2JJLKbJKrMRJA=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'How Cutting-Edge Hospitals Manage Patients',
  date: '2023-08-21',
  tag1: '#SmartHospital',
  tag2: '#PatientTracking',
  tag3: '#PatientManagement',
  tag4: '#PatientSafetyManagement',

  text: [
    {
      head: '',
      subHead: '',
      desc: 'Is there a place as closely tied to our health as hospitals? As health is perhaps the most critical aspect of life, patients might set the highest standards when choosing a hospital. Naturally, the factors in this decision would be trust and technology. The belief that they will be well taken care of and the medical technology to support that belief. If these two conditions are guaranteed (within reasonable limits), patients might be willing to pay more for better services. Medical facility managers and industry professionals who understand this well are increasingly interested in cutting-edge technology for better service and business. However, many tend to focus only on medical technology, often overlooking other impressive technologies and systems. One prime example of this is the rapidly growing real-time location tracking technology, RTLS technology.',
      dot: ''
    },
    {
      head: 'What is Real-Time Location Tracking System, RTLS?',
      subHead: '',
      desc: `RTLS, quite literally, is the technology to track the real-time location of an object. Up to this point, you might think it's not much different from the GPS you are familiar with. In terms of tracking location, they may seem the same, but in fact, there are significant differences between the two technologies. First of all, RTLS technology allows for much more precise indoor location tracking. For instance, even in situations where quick judgment or response is required, real-time alerts can be sent to administrators or relevant personnel. Of course, RTLS technology itself doesn’t necessarily mean these capabilities, but if a company has the technological prowess, they can build systems with a variety of functionalities. So, what becomes possible in a hospital?`,
      dot: ''
    },
    {
      head: '',
      subHead: 'Medical Equipment Tracking',
      desc: 'Not long ago, a medical accident occurred at a hospital. It was an infection incident caused by improper handling of contaminated medical equipment. If you were the hospital administrator upon encountering such an incident, what would you do? Following the conventional protocol might be a way, but what if the hospital staff (hopefully not) has the potential for negligence, or what if the equipment handler at the hospital is an exceptionally diligent worker who has never made a mistake before? The fact is, human errors are prone to happen, and the outcome is uncertain. RTLS technology can help prevent such incident possibilities. Contaminated equipment can be monitored in real-time by administrators via monitors. Automated systems that send alerts when specific paths are crossed are also possible. Until it is completely and safely handled, the smart system keeps an eye on it.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Risk Zone Management',
      desc: `RTLS systems can set restricted areas to maintain safe facilities. If someone enters those areas, an alert is sent to administrators for a swift response. For instance, imagine you're working at a hospital, and suddenly, you receive a warning that Patient A is accessing the medication storage area. Looking at the screen, you notice Patient A is standing in front of the medication storage door in the annex. Thankfully, you quickly call a security guard, preventing an accident from happening.`,
      dot: ''
    },
    {
      head: '',
      subHead: 'Greater Scalability',
      desc: 'RTLS technology doesn’t necessarily provide all these functions mentioned above. However, it means you can create systems with various functionalities through RTLS. Integrating fall detection technology or using Orbro’s access system to ensure nothing is missed, you can create a smart medical facility that ensures everything is taken care of. Complete and safe, as mentioned before. Additionally, real-time control of bottlenecks or quickly assessing the location and quantity of assets in a facility can be efficiently achieved from an operational perspective. While manpower and costs naturally decrease, productivity actually increases.',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that aids real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      {
        // Additional products if needed
      },
    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/en/enterprise/medical/patient'
      }, 
      {
        img: medicalWaste.thumb,
        data: 'medicalWaste',
        link: '/en/enterprise/medical/waste'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      }
    ]
  }
}
