import { MutableRefObject, ReactNode, useEffect, useRef } from "react";

const useClickOutside = <T = HTMLDivElement>(hanlder: () => void): MutableRefObject<T | null> => {
  // Ref
  const ref = useRef<T | null>(null);

  // window 클릭 이벤트
  const handleClickWindow = (e: MouseEvent): void => {
    const { current } = ref;
    const { target } = e;
    if (!current || !target) return;
    const result = (current as any).contains(target);
    if (!result) {
      hanlder();
    }
  };

  // Hooks
  useEffect(() => {
    window.addEventListener('click', handleClickWindow);

    return () => {
      window.removeEventListener('click', handleClickWindow);
    }
  }, []);

  return ref;
};

export default useClickOutside;
