import { medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4";


export default {
  helmet: '| RTLS Healthcare, Ready for Immediate Use',
  desc: `It plays a significant role in improving work efficiency and enhancing patient safety in medical settings by tracking the patient's location in real-time and efficiently managing medical equipment and personnel.`,
  keywords: 'Patient location tracking, Patient management, Hospital location tracking, Hospital management, Smart hospital, Patient tracking, Medical facility management, Dementia patient management, Alzheimer\'s patient management, Elderly patient management, Hospital operation, Medical center, Nursing home management, Orbro, Kongtech, RTLS, orbro',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RTLS Healthcare, Ready for Immediate Use :: Orbro Blog',
  ogUrl: 'https://orbro.io/en/blog/rtlsHealthcare',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDxCulBiV7gg1-xmVx62eta4ZCVo7bPai3vj9SN8ajL7XdyxO5_DwTc9AopMRDeHnIP1JRQ2aZs8YXRVegMIQ3XJAzQpGZPe7LsKZRYfG2TTKDwuITV4QU1esZgJZjvVrWFNk7tdYgfPUmag4WVUhkZm9kN2q9I3i-i7g39hykefddJoB-GGyLstWZXHZPGouEpHRHO-QSDUlk-5bfgHEXekgkw8khERLg03ek2jr9aZVu73vARqYrCfRjmXxpjdzbezfvYPPNqyHHEqz7ryYxrceLzeUag7SeXqCikIehVE0j-teEEB0twcIKzxDhQ926mrdivrYUsLL7Y73cfXmCIoQrwGuj_PWfjkx8---GZuGMDu2T0xCegVf_ixBlv-_DP0s4PYYug15P9K4RRQcy8wzkEIe8DXRrNFJxK06VwMVNt7IvsyGuGpztnYoPe9DTJVmh6DgvpkLVfrWOQROJ1mY3wPnWoRGDONddVgsZOg9IFlZ8XDLxhGIwf9_NUadZ72Sx1uku7HzFPuRiPUi_jEHwpZssTqer0dHqRrmEiUm7WIfEvksaY5tlVEdKCkt6rieJQk6XkQ7HLkH9Sft-gtWyMIbXXc-ezr8CI1QW-BSqTqW1u1RHOH_U8qsyeAMRsLziuReFdGt94MU0XLtJQjzm470ZW0Lyliq6-D-ACBxago-nL7bW3hByy9u4qrPcseGt9nDBtxez21nzH4j-kk-ikAGdM2lqgNjENR3W3iBBYZxCLEgg5FaWno-JnWFODzJ2EPDkL9YgV3eJXaSav9q-K9OGrHtGcdLoOoclYQdW6HGei2jvEzIvkJ7fOrE_BkaMDvwfP_mG1Wyfmazl4ZT-E3REcF7-H8fvG1Maj9lGE47sI9NaaLiuKxMUKkoOq7ATE-CsA56qjq0om1fHUHtL3k5dJaPw5dqFFc_I1pmQF2l6v32K36AQbL6URjYW2TRB5R_7PdZbxPltDvqVhv26deM1LrtslNSaQ1y8-v60TTOuJzwdCsBF2-a1Cgad2aG4c0gxmrniSlWna_ih_LT7LTZ78ekaoZK-QZbGYBhztrbJH8pG6OtYsABLu_EJbLwCZ8gemIFAL2str5ljuZjxGa8baorSqsutD4ZWG98EcUImwgTwfsXrg_fNe0Uyl2TmD1Jnr-cpdFvRHT9CtlmxJL0XV3CuWIB36kk7Sj451LQT1d3CUGDUYSob_cs6XtFvl8zo1L1iJO3CbaIL-X7qva6sYYjidWIQqBIwseA6TVk4ZZ4AB1lm8OMbmP-VwUqs4EahKtumqMiQXZoiUFyXyTKLz-soVKV_uVW4R9xcXy-pUYHMsYmRM2uDVc0eCkPkdr5NoidDFKrf03A4kPR2s3URy-bZdCORxE0KgtlQLx1eKtVi-Ku88pwYGe1IECBztZxTUfhisovrg-CZu2B3OOTHLZEZ3DTwH9ORlbfLRdJtLl21EkzHYHX485uwK40pglWChiD4G58da5pMDp2-tZtgrkAaEUw-gf3FoUya3HD4ywea6Tzxbua0wg9oJfPQYB4jUYPE8kQiPVTpz4TgFYI8iwU9sF8yUIRbW5c7kWjwIp=w2000-h1596',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RTLS Healthcare, Ready for Immediate Use',
  date: '2023-11-02',
  tag1: '#Healthcare',
  tag2: '#PatientLocationTracking',
  tag3: '#SmartMedicine',
  tag4: '#PatientManagement',

  // <br/>
  text: [
    {
      head: '',
      subHead: '',
      desc: 'RTLS (Real-Time Location System) technology is revolutionizing the modern healthcare field. This technology tracks patients\' locations in real-time, efficiently manages medical equipment and personnel, thus enhancing operational efficiency and patient safety in medical settings. In this article, we will explore the importance of RTLS healthcare technology and the immediate benefits it offers.',
      dot: ''

    },
    {
      video : video,
      link : 'https://orbro.io/en/enterprise/medical/patient',
      head: 'If You Implement the Solution Now',
      subHead: 'Enhanced Patient Safety',
      desc: 'RTLS is a key means to enhance patient safety through patient location tracking. Real-time tracking of patient locations enables medical staff to quickly assess a patient\'s condition and respond immediately to emergencies, reducing fatal errors in emergency situations.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Improved Resource Management',
      desc: 'RTLS enables efficient management of medical equipment and personnel. Obtaining real-time location information of medical equipment allows for quick equipment retrieval and efficient maintenance and replacement tasks. Additionally, it optimizes medical staff schedules, avoiding schedule conflicts and allowing them to focus on patient care.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Infection Prevention',
      desc: 'RTLS effectively manages patient movements within the hospital, helping to prevent the spread of infections and minimizing patient-to-patient contact within the hospital. This restricts the spread of infections and ensures the safety of both patients and medical staff.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Financial Management',
      desc: 'Introducing RTLS allows efficient resource utilization and cost savings. It prevents unnecessary equipment purchases and inventory waste and optimizes labor costs. This improves the hospital\'s financial situation and allows efficient budget utilization.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Real-Time Information',
      desc: `RTLS provides real-time location information, enabling medical staff to make faster and more effective decisions. Immediate responses are possible when a patient's condition changes, improving the treatment process and achieving the best outcomes for patients.`,
      dot: ''
    },
    {
      head: '',
      subHead: 'Cost Efficiency',
      desc: 'RTLS offers cost-effective solutions, saving time and resources and improving the organization\'s financial situation. Quick returns on investment can be achieved, and long-term cost efficiency can be increased.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Improved Patient Satisfaction',
      desc: 'Through patient location tracking, patients receive more personalized care, leading to higher satisfaction with their safety and convenience. This provides trust and peace of mind to patients and their families.',
      dot: ''
    },
    {
      head: 'In conclusion,',
      subHead: '',
      desc: 'RTLS healthcare technology is bringing a significant innovation to the modern medical field, and it can enhance patient safety and efficiency immediately upon implementation. It is easy to implement and provides quick results, making it an important technology that benefits both medical institutions and patients. This is expected to make future healthcare safer and more efficient.',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that assists in real-time location tracking by attaching UWB devices to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/en/enterprise/medical/patient'
      },
      {
        img: medicalWaste.thumb,
        data: 'medicalWaste',
        link: '/en/enterprise/medical/waste'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/en/enterprise/office/asset'
      }
    ]

  }
}
