import { carTracking1, peopleTracking1 } from "@assets/images/main/camera";
import { assetTracking1, workerTracking1 } from "@assets/images/main/rtls/product";

export default {


    assetPackage : {
        label : 'Assets Tracking Package',
        title : '자산 위치추적 패키지',
        product : 'TwinTracker 10 + Server 1',
        desc : 'BLE 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img : assetTracking1,
        link : '/rtls',
        index : '1',
        productIndex : 1,
    },

    workerPackage : {
        label : 'Workers Tracking Package',
        title : '작업자 위치추적 패키지',
        product : 'TwinTrackerPro 10 + Server 1',
        desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img : workerTracking1,
        link : '/rtls',
        index : '2',
        productIndex : 1,
    },


    carPackage : {
        label : 'Vehicle Location Tracking Package',
        title : '차량 위치추적 패키지',
        product : 'Server1 + NVR1 + PTZ Camera2',
        desc : '카메라를 통하여 사람, 차량, 등 다양한 객체의 위치 및 이상 행동을 감지하고 관리 할 수 있습니다.',
        img : carTracking1,
        link : '/camera',
        index : '1',
        productIndex : 1,
    },


    peoplePackage : {
        label : 'People Tracking and Analysis Package',
        title : '유동인구 분석 패키지',
        product : 'Server1 + NVR1 + IP Camera4',
        desc : '카메라를 통하여 실내 사람들의 이동경로를 분석 및 돌발 상황 감지를 할 수 있습니다.',
        img : peopleTracking1,
        link : '/camera',
        index : '2',
        productIndex : 1,
    },

}