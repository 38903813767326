export default {
  subtitle: "Inquiry",
  title: "Contact ORBRO",
  description: "If you have any questions or inquiries about ORBRO services or solutions, please feel free to contact us below.",
  checkList: [
  "How much is the service fee?",
  "Is custom service available?",
  "Is it possible to integrate with other services?",
  "I'm interested in adopting the solution.",
  "I need technical support.",
  "I want to purchase the product."
  ],
  call: "For a quick response, please call (+82) <strong>1522-9928</strong>.",
  callTime: "Weekdays (10:00 AM - 7:00 PM)",
  form: {
  name: "Name",
  namePlaceholder: "John Doe",
  title: "Title",
  titlePlaceholder: "Optional",
  companyName: "Company Name",
  companyNamePlaceholder: "Enter your company name",
  email: "Work Email",
  emailPlaceholder: "name@company.com",
  phoneNumber: "Phone Number",
  phoneNumberPlaceholder: "010-1234-5678",
  comment: "How can we assist you?",
  commentPlaceholder: "Please enter your inquiry, solution request, business proposal, etc.",
  policy: {
  require: "[Required]",
  agree: "Agree",
  policy1: "Privacy Policy"
  },
  submit: "Submit"
  }
  }