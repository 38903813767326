import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| 构建安全智能施工现场的RTLS解决方案',
  desc : '智能施工现场可以提高生产效率并确保工人安全。 RTLS技术可以成为建设这种智能施工现场系统的最合适方式。',
  keywords : '工人安全管理、施工现场安全管理、施工现场、现场安全、建设安全、智能施工、重型设备管理、实时位置追踪、安全管理、RTLS、UWB、Orbro、KonTech',

  ogTitle : '',
  ogDesc : '',
  ogSite : '构建安全智能施工现场的RTLS解决方案::Orbro博客',
  ogUrl : 'https://orbro.io/cn/blog/constructionSafety',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4ODZOuIwgi2GmgeN38XOohY2I6ZhTq1OX26xq5S1gJqAGQsMoshxsOsqKhEqbIAKQ4m2i0wnTaha9gJzWrOTQJjh-kG35v_PKDbGn6oQKyjdPrG83wKwq9aDBoWxLoDMDp5REkLIfvg86TqENuhdkKlvaCE8JtLLjZtoMWuiSQCYZYRXIk1QRwMiLlKrLdUR3Ae7WNDbQamCAEzOhA4iB7NibqGzN1cFrlfeKXbBN95eOv_6gdNvv8egQ9WRKK1ABThUboxhbiTLeGy74mZO217xUHPe9qAWl_BitXQj3yMJXo_3BQUooKtjvw8Lm1OAObOJX5ZUIyV_DF_WDnsYCP6ypezD9GmdGsasbiagdvp9zxbH2CvrIBSLYhNFv34TTAiztM6BukR-0OJInkIZHvaJQyilhLrE44lrqa8M3IzDhzWEOK-bb3Tz5mXAe370UpbaXxkMHaEJS72MZgBbyUj-J3f7J889zkMt7U7UffPNi4BGFhgb5prG4kOatNmsgh8pmVHsAk4xHZ8Fewny7ASDPV2Q4z9YuGgCynJaNDxYwb3FurwZ8G-Nfwgdt2KfslDc1yQ5I-IX-4gd8DTlSCr596Caq2a1UsBSEyl4J9lioE1CFUj22KymcIVvSBhkp285DCyho_1lztbytT6HMUyMOyaMMvEyNnug6qmzJeQEVITT9AqDTwv4crACqyBUCZXmdgcBNa45zkwL_Jsx1akoJohEvzXJ7qMFfZHXmPapV4uS5jSmJGSkvVPCjjhTqGFvy0NDStFwvdrwqGHnI34q1-p2wTpJG2kvUhUH7grFZhik9E7V--QY6HF4fkAjtjgmSiY3mrf4qGOxawVFTvH0n5R4fppcdngOJRnpXyeNUIm-1L8-Qe_A7xZl04bFY7AMwjt9u-DeXWOdd6buaBYjig4EHIovTR2IeuTI2BtFyttqCDwpea3cJuouIq4VZ_m8QEFTUGrq8hkviXmE8iVc80a_vpEfJebHjzWM5f48cjhEk-LVjsrECpYd1lU9TYRV3a6mLY0VxcVpNdN8rxI7cQe5YgRBJGSHR4ECjku3U9jvPJeMJ7hJ4V9D8donENtGVDWuiVPXUk6RH9XU9yeVxMTApzrsB58tA03IPp8f2Aejpzr8ksllmvuubwwbr3JXX6nqREowvgldTiKTGrvT0ca1ol6reTjqX5Xr06c0n5F2v-aYGnPNajdoQNBxtUcxpQRVd-yKyJryJXz56r_J6c2QiiVIADQMjYUH9ULyFT0d0UvnwJS4cTdqEGBzUoJNTLZZi2JtD3cJvG8vrrz5xHUsR2SIkyNjCGLZyOouOh7etxxjVK_MNFYqDGli4SQhp48hMgCc56c4vMuWmZXZlSgUueLUUtEBsLpUFaTERHwqNvJaRHFJOpIyNIOsibD94KowEbCGWg9pydqRNrAdY7TQ7HbAUqUTWFMdCD4Kzkdx4cKPIzL9YTVCvBtk23t0a9E343R1x38JavpVlYeMQVxXxDG5jpP5O9lXmBitXgEfDHBmTnT_BUAvgjYjkUG5djwJfZ6E8K_FRHXtgkB-M62IYcrVsaZAd_9c1Mq8YKMP-fJ=w2052-h1199',
  ogType : '网站',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '构建安全智能施工现场的RTLS解决方案',
  date : '2023-09-19',
  tag1 : '#工人安全管理',
  tag2 : '#施工现场安全管理',
  tag3 : '#智能施工',
  tag4 : '#重型设备管理',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'建筑行业在现代社会中扮演着重要的角色，因此提高建筑现场的效率和安全性对于技术引入至关重要。最近，实时定位系统（RTLS）技术为建筑现场带来了革命性的变化，该技术的应用对于构建智能施工现场至关重要。本博客将探讨RTLS技术的概念以及如何通过其构建智能施工现场。',
      dot : ''
      
    },
    
    {
        head : 'RTLS技术的概念',
        subHead : '',
        desc :'实时定位系统（RTLS）是一种利用无线通信技术实时跟踪物体或实体精确位置的技术。 RTLS利用GPS、RFID（射频识别）、UWB（超宽带）、BLE（低功耗蓝牙）等多种技术来收集和管理位置信息。在建筑现场，可以利用这些技术高效管理设备、材料和劳动力。',
        dot : ''
        
      },
      {
        head : 'RTLS技术的优势',
        subHead : '',
        desc :'将RTLS技术引入建筑现场带来了多个优势。',
        dot : {
            a :' • <strong>资产和设备管理</strong>：通过RTLS，可以实时追踪建筑设备和材料的位置和状态，从而实现更有效的库存管理和维护。',
            b :' • <strong>提高安全性</strong>：通过实时监控工人的位置，可以预防事故并在紧急情况下迅速响应。',
            c :' • <strong>提高生产力</strong>：通过管理建筑工程的进度和工作量，可以提高生产力，并防止浪费过多的时间和资源。',
            d :' • <strong>数据分析和预测</strong>：RTLS收集和分析数据，可以理解建筑工程的模式，并帮助未来的决策。通过这种方式，可以支持决策并实现资源的有效分配。',
          }
        
      },
      {
        head : '构建智能施工现场的RTLS应用案例',
        subHead : '自动化设备运营',
        desc :'利用RTLS进行自动化设备运营是提高生产力和降低成本的关键应用案例之一。将RTLS标签安装在建筑设备上，可以实时跟踪设备的位置和状态。这使得建筑管理人员可以优化设备的移动路径并最小化燃料消耗。例如，如果起重机没有有效地布置，将会浪费运送材料所需的时间和能源。通过利用RTLS，可以优化起重机的位置，从而缩短材料运输时间并节省能源。这种自动化系统实现了更快的工作进展和提高生产效率，同时提高了能源效率。',
        dot : ''
        
      },
      {
        head : '',
        subHead : '提高工人安全性',
        desc :'在建筑现场，工人的安全性至关重要。 RTLS技术对提高工人安全性非常有帮助。工人必须佩戴RTLS标签。通过这些标签，可以实时跟踪工人的位置，并在工人进入危险区域或可能发生事故时发出警告。此外，还可以监测工人的健康状况，并在恶劣环境中工作时识别并采取适当措施。这种系统有助于预防事故并提高工人的安全性。',
        dot : ''
        
      },
      {
        head : '',
        subHead : '材料和设备管理',
        desc :'材料和设备的管理是影响建筑项目成功的重要因素之一。利用RTLS，可以实时跟踪在建筑现场使用的材料和设备的位置和状态。这有助于更有效地进行库存管理，并将损失降至最低。例如，可以防止在某个地点缺少所需的材料或重复存放的情况。此外，还可以识别处于闲置状态的设备，并优化维护计划。',
        dot : ''
        
      },
      {
        head : '',
        subHead : '流程优化',
        desc :'RTLS还用于优化建筑过程。建筑项目由多个工作阶段组成，需要协调和调整这些阶段之间的关系。通过利用RTLS实时监测和分析每个阶段的工作量和进度，可以防止工序之间的瓶颈，优化流程，同时保持进度和质量。',
        dot : ''
        
      },
      {
        head : '',
        subHead : '环境管理',
        desc :'环境管理在现代建筑现场中变得越来越重要。利用RTLS监测和管理能源消耗、废物管理和老化设备的更换等方面。这有助于改善环保建设和能源效率，并为可持续的建筑现场做出贡献。',
        dot : ''
      },
      {
        head : '',
        subHead : '提高客户服务',
        desc :'使用RTLS还可以改善客户服务。客户希望实时监控项目进展，并期望在出现问题时能够迅速解决。通过利用RTLS提供项目进展的透明度，并在问题发生时迅速应对，可以提高客户满意度。',
        dot : ''
      },
      {
        head : '结论',
        subHead : '',
        desc :'引入RTLS技术对于建筑行业来说至关重要，它可以构建更智能、更安全的现场，并创建由数据分析支持的智能系统。因此，可以看出，RTLS技术的积极应用是建筑行业的未来。积极引入RTLS技术，构建更智能、更安全的建筑现场，是一项重要任务。',
        dot : ''
      },
  ],

  link : {
    page : 'rtls',
    numb : '2',
    product : [
        {
          text: productPurchase.purchase.worker.workerTracking.title,
          title: productPurchase.purchase.worker.workerTracking.title,
          serial: productPurchase.purchase.worker.workerTracking.serial,
          desc: '将UWB设备安装在工人或设备上，以支持实时位置追踪的产品。',
          img: productImg.product.workerTracking.thumbnail
        },
      {

      }

    ],
    solution : [
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/cn/enterprise/construction/safety'
        },
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/cn/enterprise/construction/asset'
        }, 
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/cn/enterprise/logistics/asset'
        }
    ]

  }


}
