export default {
  helmet: ' ',
  desc: ' ',
  keywords: ' ',

  ogTitle: ' ',
  ogSite: ' ',
  ogDesc: ' ',
  ogUrl: 'https://orbro.io/cn/blog',
  ogType: ' ',

  twitterCard: ' ',
  twitterTitle: ' ',
  twitterDesc: ' ',

  solution : '奥布罗解决方案介绍',
  product : '相关产品',

  title: '<strong>ORBRO</strong> 博客',
  description: '了解ORBRO的最新技术和新闻。',
  blogList: [

    {
      name : 'blogSmartShipyard',
      date : '2024-05-09',
      label: '智能造船厂解决方案',
      path : '/cn/blog/smart-shipyard'
    },
    {
      name : 'blogAIoT',
      date : '2024-05-09',
      label: '什么是AIoT？',
      path : '/cn/blog/what-is-aiot'
    },
    {
      name : 'blogAiCamera',
      date : '2024-02-07',
      label: 'AI摄像头位置追踪解决方案',
      path : '/cn/blog/ai-camera'
    },
    {
      name : 'blogIndoorGPS',
      date : '2024-01-31',
      label: '室内GPS解决方案',
      path : '/cn/blog/indoor-gps'
    },
    {
      name : 'blogSmartBuildings',
      date : '2024-01-30',
      label: '智能港口解决方案',
      path : '/cn/blog/smart-buildings'
    },
    {
      name : 'blogSmartPort',
      date : '2024-01-25',
      label: '智能港口解决方案',
      path : '/cn/blog/smart-port'
    },
    {
      name : 'blogSmartParking',
      date : '2024-01-24',
      label: '智能停车系统',
      path : '/cn/blog/smart-parking'
    },
    {
      name : 'blogIPS',
      date : '2024-01-23',
      label: '室内定位系统（IPS）是什么',
      path : '/cn/blog/indoor-positioning-system'
    },
    {
      name : 'blogSmartAirport',
      date : '2024-01-16',
      label: '智能机场解决方案',
      path : '/cn/blog/smart-airport-solution'
    },
    {
      name : 'blogSmartHospital',
      date : '2024-01-16',
      label: '什么是智能医院？',
      path : '/cn/blog/스마트병원이란'
    },
    {
      name: 'blogLoRa',
      date: '2024-01-15',
      label: 'LoRa是什么？',
      path: '/cn/blog/what-is-LoRa'
    },
    {
      name: 'blogSmartFactory2',
      date: '2024-01-12',
      label: '什么是智能工厂？',
      path: '/cn/blog/what-is-a-smart-factory'
    },
    {
      name: 'blogNVR',
      date: '2024-01-12',
      label: '什么是 NVR？',
      path: '/cn/blog/nvr'
    },
    {
      name: 'blogNFC',
      date: '2024-01-11',
      label: '什么是 NFC？',
      path: '/cn/blog/nfc'
    },
    {
      name: 'blogRFID',
      date: '2024-01-11',
      label: '什么是 RFID？',
      path: '/cn/blog/rfid'
    },
    {
      name: 'blogWhatIsDX',
      date: '2024-01-10',
      label: '数字化转型是什么？',
      path: '/cn/blog/what-is-digital-transformation'
    },
    {
      name: 'blogContainerTracking',
      date: '2024-01-09',
      label: '集装箱追踪',
      path: '/cn/blog/container-tracking'
    },
    {
      name: 'blogExampleOfSmartCity',
      date: '2024-01-09',
      label: '城市 (Smart City) 建设案例',
      path: '/cn/blog/example-of-smart-city'
    },
    {
      name: 'blogWhatIsSmartCity',
      date: '2024-01-08',
      label: '什么是智能城市？',
      path: '/cn/blog/what-is-smart-city'
    },
    {
      name: 'blogBLE',
      date: '2024-01-04',
      label: 'BLE是什么？| 蓝牙低功耗',
      path: '/cn/blog/ble'
    },
    {
      name: 'blogDigitalTwin3',
      date: '2023-12-28',
      label: '数字孪生是什么？| 定义、应用案例',
      path: '/cn/blog/digital-twin'
    },
    {
      name: 'blogDigitalTwinDigitalCity',
      date: '2023-12-26',
      label: 'Digital Twin(8) - 生动逼真的图形城市实现',
      path: '/cn/blog/digitaltwin-digitalcity'
    },
    {
      name: 'blogDigitalTwinCCTV',
      date: '2023-12-26',
      label: 'Digital Twin(7) - 交通监控摄像头连接',
      path: '/cn/blog/digitaltwin-cctv'
    },
    {
      name: 'blogDigitalTwinAirplane',
      date: '2023-12-26',
      label: 'Digital Twin(6) - 飞机信息连接',
      path: '/cn/blog/digitaltwin-airplane'
    },
    {
      name: 'blogDigitalTwinTraffic',
      date: '2023-12-26',
      label: 'Digital Twin(5) - 公交车和地铁信息连接',
      path: '/cn/blog/digitaltwin-traffic'
    },
    {
      name: 'blogDigitalTwinTracking',
      date: '2023-12-26',
      label: 'Digital Twin(4) - 实时跟踪人员和车辆移动连接',
      path: '/cn/blog/digitaltwin-tracking'
    },
    {
      name: 'blogDigitalTwinBuildings',
      date: '2023-12-26',
      label: 'Digital Twin(3) - 建筑和室内信息实现',
      path: '/cn/blog/digitaltwin-buildings'
    },
    {
      name: 'blogDigitalTwinWeather',
      date: '2023-12-26',
      label: 'Digital Twin(2) - 大气和天气信息实现',
      path: '/cn/blog/digitaltwin-weather'
    },
    {
      name: 'blogDigitalTwinGis',
      date: '2023-12-26',
      label: 'Digital Twin(1) - GIS地理信息实现',
      path: '/cn/blog/digitaltwin-gis'
    },
    
    {
      name: 'blogQaUwb',
      date: '2023-12-18',
      label: "质量和库存管理解决方案：UWB",
      path: '/cn/blog/qa-uwb'
    },
    {
      name: 'blogConstructionSolution',
      date: '2023-12-11',
      label: "严重事故罚则法预防解决方案：建筑工地安全管理",
      path: '/cn/blog/construction-solution'
    },
    {
      name: 'blogRfidRtls',
      date: '2023-11-28',
      label: "RFID 替代 RTLS",
      path: '/cn/blog/nfc-uwb-ble'
    },
    {
      name: 'blogNfcUwbBle',
      date: '2023-11-20',
      label: "创新的使用UWB和BLE替代NFC标签的库存管理系统",
      path: '/cn/blog/nfc-uwb-ble'
    },
    {
      name: 'blogRtlsCompany',
      date: '2023-11-15',
      label: "选择RTLS提供商时的主要考虑因素",
      path: '/cn/blog/rtlsCompany'
    },
    {
      name: 'blogRfidNfcUwb',
      date: '2023-11-10',
      label: "RFID、NFC、UWB：选择哪种技术？",
      path: '/cn/blog/RfidNfcUwb'
    },
    {
      name: 'blogBeacon',
      date: '2023-11-08',
      label: "智能工厂建设与生产自动化解决方案",
      path: '/cn/blog/beacon'
    },
    {
      name: 'blogProductionAutomation',
      date: '2023-11-06',
      label: "智能工厂建设与生产自动化解决方案",
      path: '/cn/blog/productionAutomation'
    },
    {
      name: 'blogUwbRtls',
      date: '2023-11-03',
      label: "UWB RTLS，未来工业的开端",
      path: '/cn/blog/uwbRtls'
    },
    {
      name: 'blogRtlsHealthcare',
      date: '2023-11-02',
      label: "RTLS医疗保健，即时可用",
      path: '/cn/blog/rtlsHealthcare'
    },
    {
      name: 'blogDX',
      date: '2023-11-01',
      label: "什么是数字转型（DX）？",
      path: '/cn/blog/dx'
    },
    {
      name: 'blogOfficeSolution',
      date: '2023-10-31',
      label: "智能办公室建设解决方案",
      path: '/cn/blog/officeSolution'
    },
    {
      name: 'blogDigitalTwin2',
      date: '2023-10-26',
      label: "数字双胞胎的本质和潜力",
      path: '/cn/blog/digitalTwin2'
    },
    {
      name: 'blogNextManufacturing',
      date: '2023-10-16',
      label: "制造工厂库存管理系统的未来",
      path: '/cn/blog/nextManufacturing'
    },
    {
      name: 'blogSmartFactory',
      date: '2023-10-12',
      label: "智能工厂如何改变制造业",
      path: '/cn/blog/smartFactory'
    },
    {
      name: 'blogSmartSafety',
      date: '2023-10-04',
      label: "智能安全管理的全面解决方案",
      path: '/cn/blog/smartSafety'
    },
    {
      name: 'blogHeavyEquipment',
      date: '2023-09-25',
      label: "颠覆建筑工地的智能重型设备管理技术革新",
      path: '/cn/blog/heavyEquipment'
    },
    /*
    {
      name: 'blogConstructionAsset',
      date: '2023-09-21',
      label: "建筑工地库存管理：RTLS带来的新变革",
      path: '/cn/blog/constructionAsset'
    },
    */
    {
      name: 'blogConstructionSafety',
      date: '2023-09-19',
      label: "构建安全智能施工现场的RTLS解决方案",
      path: '/cn/blog/constructionSafety'
    },
    /*
    {
      name: 'blogConstructionRTLS',
      date: '2023-09-18',
      label: "智能建筑工地工人安全管理的核心",
      path: '/cn/blog/constructionRTLS'
    },
    */
    {
      name: 'blogLogisticsTech',
      date: '2023-09-13',
      label: "物流行业从业人员需要了解的最新技术趋势",
      path: '/cn/blog/logisticsTech'
    },
    /*
    {
      name: 'blogSCM',
      date: '2023-09-11',
      label: "为全球供应链管理（SCM）的智能仓库管理",
      path: '/cn/blog/scm'
    },
    */
    {
      name: 'blogLogisticsAsset',
      date: '2023-09-08',
      label: "物流中心库存管理的进化：基于RTLS的解决方案",
      path: '/cn/blog/logisticsAsset'
    },
    {
      name: 'blogLogisticsWorker',
      date: '2023-09-06',
      label: "在智能物流设施中管理工人安全的方法",
      path: '/cn/blog/logisticsWorker'
    },
    {
      name: 'blogUwbLogistics',
      date: '2023-09-05',
      label: "利用UWB技术打造智能物流中心",
      path: '/cn/blog/uwbLogistics'
    },
    {
      name: 'blogLogistics',
      date: '2023-09-04',
      label: "智能物流中心：借助RTLS成为游戏改变者",
      path: '/cn/blog/logistics'
    },
    {
      name: 'blogUwbGeofence',
      date: '2023-09-01',
      label: "利用超宽带（UWB）技术创新地理围栏系统",
      path: '/cn/blog/uwbGeofence'
    },
    {
      name: 'blogMedicalWaste',
      date: '2023-08-30',
      label: "智能技术管理医疗废物与资产",
      path: '/cn/blog/medicalWaste'
    },
    {
      name: 'blogHospital',
      date: '2023-08-21',
      label: "最先进的医院如何管理患者",
      path: '/cn/blog/hospital'
    },
    {
      name: 'blogVisitor',
      date: '2023-08-14',
      label: "您的展览馆游客减少的原因",
      path: '/cn/blog/visitor'
    },

    {
      name: 'blogPatientTracking',
      date: '2023-08-07',
      label: "用于患者管理的实时位置追踪",
      path: '/cn/blog/patientTracking'
    },

    {
      name: 'blogSmartLogistics',
      date: '2023-08-03',
      label: "智能物流仓库引领产业的原因",
      path: '/cn/blog/smartLogistics'
    },


    {
      name: 'blogSmartConstruction',
      date: '2023-07-31',
      label: "智能建筑现场安全管理系统",
      path: '/cn/blog/smartConstruction'
    },

    /*
  {
    name: 'blogAccess',
    date: '2023-07-05',
    label: '全新的出入概念',
    path: '/cn/blog/access'
  },


  // 26 - 30
  {
    name: 'blogConstFuture',
    date: '2023-06-06',
    label: 'RTLS将带来建筑行业的未来',
    path: '/cn/blog/constfuture'
  },
  */

    {
      name: 'blogOffice',
      date: '2023-06-02',
      label: '2023年，构建智能办公室的方法',
      path: '/cn/blog/office'
    },
    /*
    {
      name: 'blogMall',
      date: '2023-05-28',
      label: '将大型购物中心推向更高级别的RTLS',
      path: '/cn/blog/mall'
    },
    
    {
      name: 'blogConstruction',
      date: '2023-05-23',
      label: '建筑工地上的RTLS',
      path: '/cn/blog/construction'
    },
    
    {
      name: 'blogWarehouse',
      date: '2023-05-21',
      label: '如何使仓库更智能化',
      path: '/cn/blog/warehouse'
    },
    */





    // 21 - 25

    /*
  {
    name: 'blogHealthcare',
    date: '2023-05-19',
    label: 'RTLS在医疗设施中的优势',
    path: '/cn/blog/healthcare'
  },
  */

    {
      name: 'blogConsider',
      date: '2023-05-19',
      label: '选择RTLS解决方案时需要考虑的因素',
      path: '/cn/blog/consider'
    },
    /*
    {
      name: 'blogPatient',
      date: '2023-05-18',
      label: 'RTLS改善医疗设施的患者流程',
      path: '/cn/blog/patient'
    },
    {
      name: 'blogBi',
      date: '2023-05-18',
      label: '利用RTLS实现商业智能（BI）的增长',
      path: '/cn/blog/bi'
    },
    */
    {
      name: 'blogForklift',
      date: '2023-05-17',
      label: '提高叉车司机的安全性的RTLS解决方案',
      path: '/cn/blog/forklift'
    },


    // 16 - 20
    /*
    {
      name: 'blogGse',
      date: '2023-05-17',
      label: '用于地面支持设备（GSE）追踪的航空RTLS指南',
      path: '/cn/blog/gse'
    },
    */

    {
      name: 'blogActiveRfid',
      date: '2023-05-16',
      label: '基于Active RFID技术的RTLS',
      path: '/cn/blog/activerfid'
    },
    /*
    {
      name: 'blogElder',
      date: '2023-05-16',
      label: '为何在老年医疗设施中使用RTLS',
      path: '/cn/blog/elder'
    },
    {
      name: 'blogAdvantage',
      date: '2023-05-16',
      label: 'RTLS的四个主要优势',
      path: '/cn/blog/advantage'
    },
    
    {
      name: 'blogAnswer',
      date: '2023-05-15',
      label: '回答：关于蓝牙定位服务的五个主要问题',
      path: '/cn/blog/answer'
    },


    // 11 - 15
    
    {
      name: 'blogFactory',
      date: '2023-05-15',
      label: '欢迎来到未来的工厂：工业4.0中的实时追踪',
      path: '/cn/blog/factory'
    },
    
    {
      name: 'blog4th',
      date: '2023-05-14',
      label: '在第四次工业革命中的实时定位系统的作用',
      path: '/cn/blog/4th'
    },
    */
    {
      name: 'blogManufacture',
      date: '2023-05-14',
      label: '如何通过RTLS和物联网改革制造业运营',
      path: '/cn/blog/manufacture'
    },
    /*
    {
      name: 'blogCorona',
      date: '2023-05-13',
      label: 'RTLS在后COVID-19时代供应链中的桥梁',
      path: '/cn/blog/corona'
    },
    
    {
      name: 'blogIot',
      date: '2023-05-12',
      label: '结合物联网过程和RTLS以创造收入机会',
      path: '/cn/blog/iot'
    },


    // 6 - 10
      
    {
      name: 'blogAlone',
      date: '2023-05-12',
      label: '保护孤独工作者免受危险',
      path: '/cn/blog/alone'
    },
    */
    {
      name: 'blogAirport',
      date: '2023-05-10',
      label: 'RTLS在机场的应用',
      path: '/cn/blog/airport'
    },
    /*
    {
      name : 'blogUwbble',
      date : '2023-05-08',
      label: 'UWB与BLE，有何区别？',
      path : '/cn/blog/uwbble'
    },
    */
    {
      name: 'blogMedical',
      date: '2023-05-04',
      label: '医疗行业中的RTLS',
      path: '/cn/blog/medical'
    },
    {
      name: 'blogGeofence',
      date: '2023-05-01',
      label: '地理围栏（Geofence）是什么？有什么用途？',
      path: '/cn/blog/geofence'
    },


    // 1 - 5
    /*
  {
    name: 'blogAsset',
    date: '2023-04-27',
    label: '在室内/室外进行顺畅的资产和流程追踪',
    path: '/cn/blog/asset'
  },
  */
    {
      name: 'blogUwb',
      date: '2023-04-25',
      label: '什么是UWB，它如何对业务有所帮助？',
      path: '/cn/blog/uwb'
    },
    {
      name: 'blogDigitalTwin',
      date: '2023-04-22',
      label: '数字孪生和实时定位系统',
      path: '/cn/blog/digitalTwin'
    },
    /*
    {
      name: 'blogSafety',
      date: '2023-04-20',
      label: '实时定位技术在工业现场对劳工安全产生的影响',
      path: '/cn/blog/safety'
    },
    */
    {
      name: 'blogRTLS',
      date: '2023-04-17',
      label: '实时定位追踪系统（RTLS）是什么？',
      path: '/cn/blog/rtls'
    },

    // 其他的部分省略
  ],
  back: '返回ORBRO博客',
  newletter: '订阅新闻通讯',
  info: '获取各种新消息！',
  placeholder: '请输入您的电子邮箱',
  subscribe: '订阅',
  terms1: '提供信息即表示同意',
  terms2: 'Orbro的',
  terms3: '隐私政策',
  terms4: '。',

  more: '查看更多',
  less: '折叠',

  recent: '最新博客文章',
  share: '喜欢这篇文章吗？<br/> 分享一下吧！',
}
