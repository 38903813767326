import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from '@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4';

export default {

    
  helmet : '| 什么是信标？ | Orbro，韩国第一信标公司',
  desc : '信标是一种小型的无线发射设备，利用无线通信将信息传递给特定位置的智能设备。它主要使用蓝牙低功耗（BLE）技术，以其小尺寸和低功耗而闻名。',
  keywords : '信标，物联网，无线通信，室内定位，位置跟踪，蓝牙，BLE，Plutocon，Orbro，韩国，Kontech',

  ogTitle : '',
  ogDesc : '',
  ogSite : '什么是信标？ | Orbro，韩国第一信标公司 :: Orbro博客',
  ogUrl : 'https://orbro.io/cn/blog/beacon',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AK0iWDxa-ucISW1k_6fC9t-ZpGPIV1qRXWXcZO1OhYuIe-j3MLl1BdXVIIv40aG69pNCT6AD0WANYJ1p4OjzDQF9yM8_m5CaFglWOtunF7ZF0uySt_dYAB4pE7shLBOrdeIkyxz_3esBC_Qd-x3FmnZ4jLNcZazyIYAPR_ZuRbLW2E_sui_6vQWc2_iABmwSImfVtoyWAhaCjoHOk7UonwVJTlXVq-NJmIqcpT0-KgFYxoTYR_i_qaS1PR8hzLVcOzzk1Z7ng11UP9IaQiWcX6pFAZnxvGsUJDNz9eiIxIRifGlSF2rGl3O6KQKRRdOU1LATUHY1Rkym6F5nLo87mxlA9iqybXGIED_3BG2ywgaf3uYgBloZH3CGqi4IMUUD-dN7cbbwxrQyiEppY9GA0xNk9uatZggX5TaYItKRL0fGmJXsHn18bQHWue-OCSVs_IjMmQ6omlyiJiQAVMSGu65C3hd8ZIQGZDq0EzutRLIXyb7f6OVVcogIw8fmAHnyhzYh-ffT1Rl0kp7BSYB0y-ooqoM_z3ut5Znu5TWC4EndXrRa4BB7KOnh--fa8H0-rbNiorUZmhoedmMZHCUuHKEjiyshQaX9UiAn2Rr-Uvtuvqs-TYIRAHroGgOBrzaGBEycwr3rifKxJx-fBbAO8fe7xhZD8RNeniCCIXkf4o_crQdpMZuDeKh5_f7URFr8NU_5vs74I1lhdV_Bgq5bYEI0-b7N7JV4-Wr_zSbOLTMb5EZ-vyj43hGUKLdgOUHTYYNP8plRV5_UMElexKEid5ZmX2aj1QrTT9zBm5c_SunfkZOEK6kY6GuV7NagmSgQDhcVmX_Qgf9tJoEw07dJxwXPxOzZPO5nZDkX_xA1oDKNNsgNpPXuVjmEI2K_O-syucjYFlVnVagL7aQ-V4givU9qKYBasb6s2hfajR4L4BAvyBp-EiItjTy3dK4H8fk1sdf6OlqMfNS3DQqBu5gpyMD28HyW0gk7wC-hs0C3EsglleW016V9ELEcMiX9OTvM1dozJQFzpMEXve9fw44h-D0lW5MuJIfDb_HvD4FB0ZVrab-8kKD-99NVk2mfZigqE3tPXhEb559joMsf5UYlRvjLz7DqnBmNwPBMQavjtKTmwq6gPHsLn4hR2ENgscKrdmGFYbt-pSwwinouRHIWBOAeLbIU4R9AOWId11R5oG0IFEuiAib3dTKuzObWCeWjN4aP-24uX46l_DnyL1K9zDS3MOZbqbQOaTjkTIsx7FlLrk1iw5P3RxjboS7VC048bElpy68OsGGr5c30D4owv38GeXx3eQm4P8RDq7Ubxe9W3Z1a-CNQhDMGEBpns6BLrZ2d8AO3TaS7HhGcvmbbGMsdgmoPRZgElmoN6l1a7ktrDjuTD2NWNvLUJ6d7WxCpEG9S5RClt8JSoR884w_PQAONR_WMbvHpL-xm_fuWqWDDpYT1_7ScbWFIwBwUTop8jrIiPrrgrHHNAVloR9YwiH3J_U5jMMK9rNENYv9rHahEOLrwcJzGzbnbbkocERqVusvbsC1pl-fPUAVDjtgKf89d9X6FPsVJ95xED_hnGthC4JfXqLnPbKG_=w3356-h2242',
  ogType : '网站',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: '什么是信标？ | Orbro，韩国第一信标公司',
  date : '2023-11-08',
  tag1 : '#信标',
  tag2 : '#蓝牙',
  tag3 : '#BLE',
  tag4 : '#Orbro',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :'信标是现代数字时代的创新技术之一，应用于我们的日常生活和业务领域。让我们了解一下什么是信标，它是如何工作的，以及它的应用领域。',
      dot : ''
    },
    {
      head : '什么是信标？',
      subHead : '',
      desc :'信标是一种小型的无线发射设备，利用无线通信将信息传递给特定位置的智能设备。它主要使用蓝牙低功耗（BLE）技术，以其小尺寸和低功耗而闻名。信标可以通知周围环境其存在和位置。',
      dot : ''
    },
    {
      head : '信标的工作原理',
      subHead : '',
      desc :'信标具有非常简单的工作原理。它定期发出信号，周围的智能设备（例如智能手机）检测到这些信号并确定信标的位置。这使得智能设备能够在特定位置执行特定操作。',
      dot : ''
    },
    {
      head : '应用领域',
      subHead : '',
      desc :'信标在各种领域中使用，并特别受到以下领域的关注:',
      dot : {
        a : '<strong>营销和广告:</strong> 商店或博物馆可以使用信标向客户提供特殊折扣或信息，从而使广告更加有效。',
        b : '<strong>基于位置的服务:</strong> 信标可以在室内提供准确的位置信息，提高导航功能。',
        c : '<strong>智能家居和物联网:</strong> 信标可以连接家庭中的各种智能设备，提高自动化和便利性。',
        d : '<strong>医疗保健:</strong> 用于患者监测和医疗设备，以提高医疗服务质量。'
      }
    },
    {
    video : video,
    link : "https://orbro.io/cn/enterprise/medical/patient",
      head : '优点',
      subHead : '',
      desc :'与其他位置信息技术相比，信标技术具有多种优点。',
      dot : {
        a : '<strong>准确性:</strong> 信标提供精确的位置信息，并即使在GPS信号无法到达的环境（如室内）中也能正常工作。',
        b : '<strong>低功耗:</strong> 由于低功耗，信标具有较长的电池寿命。',
        c : '<strong>易于使用:</strong> 安装和管理信标非常简单。'
      }
    },
    {
      head : '结论',
      subHead : '',
      desc :'信标技术是现代技术的重要组成部分之一，对我们的日常生活和业务产生深远影响。其应用领域不断扩展，预计将来将提供各种创新服务和机会。对于信标的未来有很高的期望，通过利用这项技术，可以使我们的生活更加智能和便利。',
      dot : ''
    },

  ],

  link : {
    page : 'access',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.auto.accessauto.title,
        title : productPurchase.purchase.auto.accessauto.title,
        serial : productPurchase.purchase.auto.accessauto.serial,
        desc : '这是一款专为自动门出入而设计的产品，通过更换现有的自动门传感器并安装移动应用程序，实现无接触进出。',
        img : productImg.product.accessauto.key1
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/cn/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/cn/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/cn/enterprise/logistics/asset'
      }
    ]

  }


}
