import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { logisticsAsset, manufacturingAsset, manufacturingProcess, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4"

export default {

    helmet: '',
    desc: 'UWBを活用した品質管理および在庫管理ソリューションは、様々な利点を通じて企業の競争力を向上させ、効率を最大化する革新的な技術です。',
    keywords: 'UWB RTLS、室内位置特定、資産追跡、非接触決済システム、スマートホーム、ヘルスケア、自動車安全、UWB、RTLS、GPS、BLE、デジタルツイン',

    ogTitle: '',
    ogDesc: '',
    ogSite: '品質管理と在庫管理ソリューション：UWB :: Orbroブログ',
    ogUrl: 'https://orbro.io/jp/blog/qa-uwb',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDlVCtmGkr2mCVSS69T6u1T4F6EZiqevSZ-7cN786FOvXV8x22WHoof7IcLlSW7d3d4DnUPZT2QfBIuUWr_weryhpgDPEa45E1edX4NBzbCnjImjTiKPVNkJrnd-hMccXsYDNleXYSmD_YNZSLPjAwSMKKN90Mx3tpkR9a7UTKQ-cImQeFt0AOvs96-ik71EqDzH3cdqAWsBQ7aU_aLz_wlgsJwZMUUAAPPY7lxS8lWkog24zYM8BWi2P4-bg6lZ0IL5Be72Kuu1Ga2-E828VnyLDcaIkXnXbFqBqFoW57RzNMYcpG-bH3VEhchLwDniUo-jBNqflu56GYJZFz_CMBt-PdbwZEXzyNVxVyomHJgFQ-_tuofm4uNMx6DdvQ3EAbi99lfqXxlwE05tg7IFIIn88NzPmt6B_D-g0Ujw73JyDNlyzOefzda1AisrhVvXXHE12gEZAQEAnDVsf3mDImb9t9WffWNd047vV9q6GWhzE9BNt5ibIfCXZxVJD3Q6xWSICvlyRJdbFKPLMbsjwe07izGimOadYEIgqo7IBq0sH9kw8I0azDR2iJKcAEFexI4TBxg0uvQJRTqbePuIdd8hvDRm7HcFvd3-X8PctgzhJPrN4y7nSMWlWSH77CEDaYptKymzQvANITzUqYwh8_MWoPcMYKdM-3z8fuRhtut9qckVa8HbX0lFmfPE1hci-P_pz7Wx-jCb2pXgdSoVENciMiiHeng7uHM3BtBM0-2GKsCINKIFKGJs8iD-kgEiH8VWr3SkIVWJzkR-Bi63wUR-khcKdv2u2jwsQV0ClNREfUWWQpiEZxfFyw2zK8KMPxRSk4hhKh_hNeIagh-ygtgeLYzblJicZL7Hbl5w7USiFatqiLkaNKzBimapAPyMde_FZuPRAQie5tC95ZtYPRyXGGMGA1qXV7oZ2GpzqwBkG9wU_MTYQDznkHb3cJvSYUWVGT6J704WQWlETRLd2Dl7N60sZ50lDknqhXG1ZfGGF8qcBgIAXqfuUF-nRIYqZqwHoObsg_F_5iBMZzejZ82s1gbIGHR3MKaOqJy2hZOFgZfKdIST5uJHCKvc22R3enzPv502iA-rkaOY5n5fbWlFGYCB7vwvoWYspA8zGdUhQAee4hAWRQrn4L65-tc4n0rKFKBUqDi_tE5q7d9CLdGirQixGEhZc1pP8SOT9wRECaNgtzQjZf5lrjBdtjBR6oi8m6_cDiZsyHY2kZZVIQBodyLG9VToaONzqqNbcfLdQJ9PnDwxV0zS2UV1hpZIJSoCjk3fLgUn_rHE4yBlkubsupv3o4b1uUuWOSmCl2z8Bbowg6pub3WJAQ92GZa9l06BsP8H7wNS5c0B-pMOockKojLd5B-uMVbslrDK1KUS-0_00CRW9N9KzUg0-jtCM62SDPsilmcnPFwxO5bcNMVXCaUwDa5GVDhKgXXsi4aZ5tbkxZhH3RHDPSLjNtVr6KNeUCBt4Nezhhyp3ETy9B9L0m28nYfY5KUDdzpzwo68QW5GQMDP4bn4vq9pl7_oKviMByvEuvJPzi4pa1LZ9HlqTCFEf3RSRvMv_UhyZQ=w1851-h1197',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '品質管理と在庫管理ソリューション：UWB',
    date: '2023-12-18',
    tag1: '#品質管理',
    tag2: '#在庫管理',
    tag3: '#uwb',
    tag4: '#rtls',

    // <br/>
    text: [
        {
            head: '',
            subHead: '',
            desc: '現代のビジネス環境では、効率的な品質管理と在庫管理が企業の成功に重要な役割を果たしています。最新の技術の導入により、これらの管理プロセスを向上させるさまざまなソリューションが登場しており、その中の1つがUltra-Wideband（UWB）技術を活用したソリューションです。<br/>UWBは高精度の位置追跡技術で、非常に広い帯域幅を持つため、精密な位置情報を提供できます。この技術を品質管理と在庫管理に適用することで、企業はさまざまな利点を得ることができます。',
            dot: ''
        },
        {
            video: video,
            link: '/jp/enterprise/manufacturing/asset',
            head: '',
            subHead: '高精度の位置情報提供',
            desc: 'UWB技術は高精度な位置情報を提供するため、企業は製品および資産の位置を高精度で把握できます。これにより、製品の移動経路をリアルタイムで追跡し、オブジェクトの位置を正確に把握して効率的な運用を実現できます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'リアルタイムデータ検知および分析',
            desc: 'UWBソリューションはリアルタイムでさまざまなデータを収集します。これにより、生産プロセスの各段階で発生する問題や変化を迅速に検知し、データ分析を通じて問題の根本原因を把握できます。これにより、迅速な対応と改善が可能となり、生産品質が向上します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'エネルギー効率向上',
            desc: 'UWB技術は低消費電力で動作し、同時に高精度を提供します。これにより、システム全体のエネルギー効率が向上し、機器のバッテリ寿命が延長できます。エネルギー効率の向上は企業の持続可能性にも肯定的な影響を与えます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'リアルタイム適応制御',
            desc: 'UWBソリューションはデータをリアルタイムで検知および分析し、自動適応制御を可能にします。これにより、生産ラインや倉庫の作業プロセスを最適化し、過去のデータを基に予測分析を行い将来の運用を改善するのに役立ちます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'セキュリティの強化',
            desc: 'UWBは無線通信を使用し、他の無線技術と比較して高いセキュリティレベルを提供します。物理的な干渉が非常に低く、安全な通信を保証することで企業資産のセキュリティを強化します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'カスタマイズ可能性',
            desc: 'UWBソリューションはさまざまな産業分野に適用可能で、企業の特定の要件に合わせてカスタマイズできます。これにより、企業は独自にシステムを改善したり、特定の運用環境に最適化されたソリューションを導入したりする柔軟性を提供します。<br/><br/>UWBを活用した品質管理および在庫管理ソリューションは、様々な利点を通じて企業の競争力を向上させ、効率を最大化する革新的な技術です。',
            dot: ''
        },
        {
            head: 'ユースケース',
            subHead: '製造業',
            desc: 'UWBを活用したリアルタイム位置追跡システムは製造業で効率的な生産管理に使用されます。原材料、部品、製品などの位置を正確に把握して生産プロセスを最適化し、不良率を低減できます。',
            dot: ''
        },
        {
            head: '',
            subHead: '物流および倉庫管理',
            desc: '物流会社や大規模な倉庫でUWBを導入することで、商品の移動経路をリアルタイムで追跡し、在庫管理を最適化できます。特にUWBを使用した自動化ガイドシステムは大量の商品の効率的な在庫配置を可能にします。',
            dot: ''
        },
        {
            head: '',
            subHead: '医療分野',
            desc: '病院や医療機関では、UWBを使用して医療機器や患者の位置を正確に把握することで、緊急事態への対応や医療サービスの品質向上が可能です。',
            dot: ''
        },
        {
            head: '',
            subHead: 'ショッピングおよび小売業',
            desc: '小売業者はUWBを使用して店内の顧客の移動パターンを分析し、製品の在庫状況をリアルタイムで把握して在庫管理および顧客体験を向上させることができます。',
            dot: ''
        },
        {
            head: '',
            subHead: '建設業',
            desc: '大規模な建設現場では、UWBを活用して機器や作業員の位置を正確に把握し、安全性を向上させ作業スケジュールを最適化できます。<br/><br/>これらの例はUWBを使用した位置追跡技術が様々な産業分野で成果を上げていることを示しています。様々な企業がUWBソリューションを導入することで生産性と効率性を向上させ、競争力を確保しています。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB端末を作業者や機器に取り付け、リアルタイムで位置追跡を支援する製品です。',
                img: productImg.product.assetTracking.thumbnail
            },
            {

            }
        ],
        solution: [
            {
                img: manufacturingProcess.thumb,
                data: 'manufacturingProcess',
                link: '/jp/enterprise/manufacturing/process'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/jp/enterprise/manufacturing/asset'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/jp/enterprise/logistics/asset'
            },
        ]
    }
}
