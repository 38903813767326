import { IconOsOff, IconOsOn } from '@assets/images/main/icon';
import { industryOn, industryOff, tagOn, tagOff, assetTagOn, assetTagOff, assetOn, assetOff } from '@assets/images/main/index';

export default {
  bannerData: [
    {
        icon: assetOn,
        iconInv: assetOff,
        image: '',
        iconText: 'Bluetooth(BLE)<br/>位置測位',
        sub: 'New',
        link: '/jp/beacon/ble'
    },
    {
      icon: industryOn,
      iconInv: industryOff,
      image: '',
      iconText: '産業用ビーコン',
      sub: '',
      link: '/jp/beacon/industry'
    },
    {
      icon: tagOn,
      iconInv: tagOff,
      image: '',
      iconText: '小型ビーコン',
      sub: '',
      link: '/jp/beacon/tag'
    },
    {
      icon: assetTagOn,
      iconInv: assetTagOff,
      image: '',
      iconText: '資産用タグ',
      sub: 'New',
      link: '/jp/beacon/asset-tag'
  },
  {
      icon: IconOsOn,
      iconInv: IconOsOff,
      image: '',
      iconText: 'ORBRO OS',
      sub: 'New',
      link: '/jp/os'
  },
  
  ],
}
