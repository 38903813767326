import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/retailExhibition/video/rtlsVideo.mp4';

export default {

    desc: 'AIoT（人工知能 IoT）は、人工知能（AI）と物のインターネット（IoT）を組み合わせた概念であり、機械学習、データ解析、認知技術などの人工知能技術を活用して、物のインターネットデバイスがスマートに相互作用し、学習し、知性を持つことを意味します。',
    keywords: 'AIoT (人工知能・モノのインターネット), IoT (モノのインターネット), 人工知能カメラ, デジタルツイン, デジタル変革, ビーコン, RTLS (リアルタイム位置システム), UWB (超広帯域), BLE (Bluetooth Low Energy), Orbro, KongTech.',

    ogSite: 'AIoTとは？ | 定義、技術、事例、利点',
    ogUrl: 'https://orbro.io/jp/blog/what-is-aiot',
    ogImg: 'https://lh3.google.com/u/0/d/1beXwJR6Bc3ap2Kb_H7OaW4vPVlq3ovWM=w2000-h7622-iv1',
    ogType: 'website',

    title: 'AIoTとは？ | 定義、技術、事例、利点',
    date: '2024-05-09',
    tag1: '#AIoT',
    tag2: '#IoT',
    tag3: '#AI',
    tag4: '#人工知能融合技術',

    text: [
        {
            head: 'AIoTとは？',
            subHead: '',
            desc: 'AIoT（人工知能 IoT）は、人工知能（AI）と物のインターネット（IoT）を組み合わせた概念です。これにより、機械学習、データ解析、認知技術などのAI技術を活用して、物のインターネットデバイスがスマートに相互作用し、学習し、知性を持つことができます。AIoTは既存のIoT技術にAI技術を統合し、より効率的な自動化とデータ駆動型の意思決定を可能にします。これにより、生産性向上と便利なサービス提供が可能になります。',
            dot: ''
        },
        {
            head: '代表的なAIoT技術',
            subHead: '人工知能（AI）',
            desc: 'AI技術には、機械学習、ディープラーニング、自然言語処理などが含まれます。これらの技術は、データを分析し、パターンを認識し、IoTデバイス間でスマートな相互作用を可能にします。',
            dot: ''
        },
        {
            head: '',
            subHead: '物のインターネット（IoT）',
            desc: 'IoTは、さまざまなオブジェクトがネットワークに接続されてデータを交換できる技術を指します。センサー、デバイス、家電などのオブジェクトがインターネットに接続され、互いに対話し、データを収集、送信、処理します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'センサーとアクチュエータ',
            desc: 'センサーは周囲の環境からデータを収集し、アクチュエータは外部環境に影響を与えるデバイスです。これらはIoTデバイスの重要な構成要素です。',
            dot: ''
        },
        {
            head: '',
            subHead: 'クラウドコンピューティング',
            desc: 'AIoTでは、大規模なデータ処理と分析にクラウドサービスが活用されます。クラウドベースのサーバーはデータを保存し、処理してユーザーに意思決定能力を提供します。',
            dot: ''
        },
        {
            video: video,
            link: '/jp/enterprise/retail/exhibition',
            head: 'AIoTの使用事例',
            subHead: 'スマートホーム',
            desc: 'AI技術を使用して、家庭内のさまざまなデバイスを接続して便利な生活環境を提供します。たとえば、スマートホームハブは、照明、暖房、セキュリティシステムなどを制御し、ユーザーの習慣を学習して最適なエネルギー消費方法を提案できます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'スマートヘルスケア',
            desc: 'ウェアラブルデバイスやセンサーを使用して個人の健康状態をモニタリングし、個別に適したヘルスケアサービスを提供します。心拍数、睡眠パターン、活動量などのデータを収集し、AIアルゴリズムを使用して健康状態を予測したり、疾患を早期に検出したりできます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'スマート農業',
            desc: 'IoTセンサーを使用して土壌湿度、温度、pHなどの情報を収集し、作物の栽培プロセスを最適化します。農家はスマートフォンアプリを介して作物の状態をリアルタイムで監視し、必要な対策を取ることができます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'スマートシティ',
            desc: '都市内のさまざまな施設やインフラを接続して効率を高め、市民の利便性を向上させます。たとえば、スマートライティングは環境条件に基づいて調整し、スマートトランスポーテーションシステムはリアルタイムで交通状況をモニタリングし、渋滞を予防したり最適な経路を提案したりします。',
            dot: ''
        },
        {
            head: '',
            subHead: 'スマート製造',
            desc: '製造業では、IoTセンサーやロボットを使用して生産ラインを自動化し、生産プロセスを監視します。AIアルゴリズムは生産データを分析して出力を最適化し、欠陥を事前に予測して生産効率を向上させます。',
            dot: ''
        },
        {
            head: 'AIoTの利点',
            subHead: '知的自動化',
            desc: 'AIoTは、AI技術を使用してIoTデバイスをスマートに制御および管理できます。これにより、タスクの自動化と最適化が可能になり、人間の介入を減らして生産性を向上させます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'データ駆動型の意思決定',
            desc: 'AIoTは大規模なデータを収集し、分析して意思決定に活用します。これにより、リアルタイムの状況把握と最適な行動が可能になります。',
            dot: ''
        },
        {
            head: '',
            subHead: '予測分析とメンテナンス',
            desc: 'AIoTはセンサーデータとAIアルゴリズムを使用してシステムの異常を事前に検出し、予測分析を行います。これにより、機器の故障を予防し、メンテナンススケジュールを最適化できます。',
            dot: ''
        },
        {
            head: '',
            subHead: '個別化されたサービス提供',
            desc: 'AIoTはユーザーデータを分析して個別化されたサービスを提供できます。例えば、スマートホームシステムはユーザーの習慣や好みに基づいて照明や暖房を調整し、便利で安全な環境を提供します。',
            dot: ''
        },
        {
            head: '',
            subHead: 'エネルギー効率',
            desc: 'AIoTはエネルギー消費を最適化するのに役立ちます。センサーデータとAIアルゴリズムを分析して、最適な省エネ方法を提案し、エネルギー効率を向上させます。',
            dot: ''
        },
        {
            head: '',
            subHead: '時間とコストの節約',
            desc: 'AIoTはタスクの自動化と最適化により、労力と時間を節約できます。また、予測分析により、機器の故障を事前に防止し、コストを削減できます。',
            dot: ''
        },
        {
            head: '',
            subHead: '',
            desc: 'AIoT技術は人工知能と物のインターネットを組み合わせて、知的自動化、データ駆動型の意思決定、予測分析、個別化されたサービス提供など、さまざまな利点を提供します。これにより、生産性の向上、コスト削減、エネルギー効率の向上などが実現でき、さまざまな産業や日常生活のシナリオでの革新と効率化が可能となります。',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB端末を作業者や機器に取り付けて、リアルタイムで位置追跡を支援する製品です。',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/jp/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/jp/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/jp/enterprise/construction/equipment'
            }
        ]


    }


}
