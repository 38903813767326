import styled from "@emotion/styled";
import { FlexStyle, Vertical } from "@assets/styled/flex";
import { StyledGridRow } from "@components/Grid/Grid.style";
import { BLACK, GRAY_200 } from "@assets/styled/color.style";

export const StyledRequestForm = styled(Vertical)`
  align-items: flex-start;
`

export const StyledRequestFormRow = styled(StyledGridRow)`
  row-gap: 48px;
`

export const StyledRequestFormGroup = styled(Vertical)`
  gap: 8px;
`

export const StyledRequestFormInput = styled.input`
  width: 100%;
  height: 44px;
  padding: 12px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.08);
`

export const StyledRequestFormTextarea = styled.textarea`
  width: 100%;
  height: 400px;
  padding: 12px;
  border-radius: 4px;
  resize: none;
  border: solid 1px rgba(0, 0, 0, 0.08);
`

export const StyledRequestFormPolicies = styled(Vertical)`
  margin-top: 32px;
`

export const StyledRequestFormPolicy = styled(FlexStyle)`
  gap: 16px;

  & a {
    color: ${BLACK};
    text-decoration: underline;
  }
`

export const StyledRequestFormCheck = styled.input`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px ${GRAY_200};
`

export const StyledRequestFormSubmit = styled(FlexStyle)`
  margin-top: 32px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 24px;
  border-radius: 100px;
  background-color: ${BLACK};
  flex: none;
  user-select: none;
  cursor: pointer;
`