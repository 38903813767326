import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import React, { useEffect, useState } from "react";
import { 
  BoxLayout,
  CalenderIcon,
  CallButton,
  Dashbox,
  InputBox,
  Noon, 
  NoonBox, 
  Picker, 
  PickerBox, 
  Placeholder, 
  PricingPage, PricingSpacingTop, ScheduleBox, ScheduleSideBox, SelectTime } from "./Pricing.style";
import { StyledDisplay, StyledLabel } from "@components/Text";
import { Trans } from "react-i18next";
import pricing from "@src/lang/ko/pricing/pricing";
import { Icon3dSchedule, IconCalender} from "@assets/images/icon";
import { useLocation, useNavigate } from "react-router-dom";
import seo from '@src/lang/ko/seo';
import seoEN from '@src/lang/en/seo';
import seoJP from '@src/lang/jp/seo';
import seoCN from '@src/lang/cn/seo';
import SEO from '@pages/SEO';
import i18n from "@pages/i18n";
import emailjs from 'emailjs-com';
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";
import { ImgWH, LabelColor, Spacing } from "@pages/CommonStyle/common.style";
import { DivFlexRow } from "@pages/CommonStyle/commonDiv.style";

const CallSchedule = (): React.ReactElement => {
  
  const [ipAddress, setIp] = useState();

  useEffect(() => {
    axios.get('https://geolocation-db.com/json/')
      .then((res) => {
        setIp(res.data.state)
      })
  }, [])

  const location = useLocation();
  const currentDateTime = new Date();

  const personalID = location.state && location.state.personalID ? location.state.personalID : '-';

  const selectedIndexes = location.state && location.state.selectedIndexes ? location.state.selectedIndexes : [];
  const selectedOptions = location.state && location.state.selectedOptions ? location.state.selectedOptions : [];
  const selectedProducts = location.state && location.state.selectedProducts ? location.state.selectedProducts : [];
  const email = location.state && location.state.email ? location.state.email : '';
  const phone = location.state && location.state.phone ? location.state.phone : '-';
  const memo = location.state && location.state.memo ? location.state.memo : '-';
  
  let placeholder;
  let alertMsg;
  let emptyMsg;
  let holder1;
  let holder2;
  let holder3;
  let successMsg;

  if (location.pathname.startsWith('/en/')) {
    placeholder = 'Please enter an email'
    alertMsg = 'Email format is invalid'
    emptyMsg = 'There are empty values'
    holder1 = 'ORBRO'
    holder2 = 'John Doe'
    holder3 = '00'
    successMsg = `Thank you for your inquiry. We'll get back to you as soon as possible.`
  } else if (location.pathname.startsWith('/jp/')) {
    placeholder = 'メールを入力してください'
    alertMsg = 'メール形式が正しくありません'
    emptyMsg = '空の値があります'
    holder1 = 'ORBRO'
    holder2 = '山田太郎'
    holder3 = '00'
    successMsg = 'お問い合わせありがとうございます。 早いうちにご連絡いたします。'
  } else if (location.pathname.includes('/cn/')) {
    placeholder = "请输入电子邮箱。"
    alertMsg = "电子邮箱格式不正确"
    emptyMsg = "存在空值"
    holder1 = 'ORBRO'
    holder2 = '张三'
    holder3 = '00'
    successMsg = '谢谢，我会尽快联系您的。'
  } else {
    placeholder = '이메일을 입력해 주세요.'
    alertMsg = '이메일 형식이 올바르지 않습니다'
    emptyMsg = '비어있는 값이 있습니다'
    holder1 = '오브로'
    holder2 = '홍길동'
    holder3 = '00'
    successMsg = '문의 주셔서 감사합니다. 빠른 시일 내로 연락드리겠습니다.'
  }

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date, e) => {
    setSelectedDate(date);
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
  };
  
  const [schedule, setSchedule] = useState<boolean>(true);
  const [hour,setHour] = useState<string>('');
  const [miniute, setMiniute] = useState<string>('');

  // Input event
  const handleInput = (event, callback) => {
    const value = event.target.value;
    if (/^\d{0,2}$/.test(value)) {
      callback(value);
    }
  }
  const setTime = (click : boolean) =>{
    if (click !== schedule) setSchedule(!schedule);
  }

  const navigate = useNavigate();
  const handleNextStep = () => {
    notionSubmit(personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule);
    alert(successMsg);
    sendEmail();
    if(currentLang === 'ko') { navigate('/', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule } });}
    else { navigate(`/${currentLang}/`, { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule } });}
  }

  const notionSubmit = async (personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule) => {
    let date;
    if(schedule === false){
      date = '오후';
    } else{
      date = '오전';
    }

    try {
      const response = await fetch("https://orbro.io/pricingNotion", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          personalID : personalID,
          industries : convertString(selectedIndexes),
          products : convertString2(selectedProducts),
          demand : convertString3(selectedProducts, selectedOptions),
          email : email,
          name : name,
          phone : phone,
          memo : memo,
          ipAddress : ipAddress,
          type : '전화 상담',
          schedule : selectedDate.toDateString().split(' ').slice(1, 4).join(' ') + " / " + date + " " + hour + "시 " + miniute + "분",
          currentTime : currentDateTime.toLocaleString('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // 24시간 형식
            timeZone: 'Asia/Seoul', // 한국 시간대
          })
        })
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  const sendEmail = () => {
    emailjs.init('SoQtE3CThBY_wpeDn');
    const mailForm ={
      created_time: new Date().toLocaleString()
    }
    emailjs.send('service_7yv5b7d', "template_j441mrk", mailForm)
    .then((response) => {
    })
    .catch((error) => {
    });

  };

  const convertString = (selectedIndexes) =>{
    const selectedIndustries = selectedIndexes.map(index => {
      return pricing.step1.industry[index].title;
    });
    return selectedIndustries.join(', ');
  }

  const convertString2 = (selectedProducts) =>{
    const selectedProduct = selectedProducts.map(index => {
      return pricing.step2.product[index].title;
    });
    return selectedProduct.join(', ');
  }

  const convertString3 = (selectedProducts, selectedOptions) => {
    let combinedText = ''; // 문자열을 저장할 변수 초기화
  
    selectedProducts.forEach((i) => {
      selectedOptions[i].forEach((jData, j) => {
        jData.forEach((value, k) => {
          if (
            value === 1 &&
            j < pricing.step3.question[i].parts.length &&
            k < pricing.step3.question[i].parts[j].option.length
          ) {
            // 문자열을 추가
            combinedText += pricing.step3.question[i].parts[j].option[k].text + ' ';
            if (i !== selectedProducts.length - 1) {
              // i 값이 마지막이 아니면 쉼표를 추가
              combinedText += ', ';
            }
          }
        });
      });
    });
  
    return combinedText; // 결합된 문자열 반환
  };
  

  let seoPath;
  
  if(location.pathname.includes('/en/')){
    seoPath = seoEN;
  } else if (location.pathname.includes('/jp/')){
    seoPath = seoJP;
  } else if (location.pathname.includes('/cn/')){
    seoPath = seoCN;
  } else{
    seoPath = seo;
  }
  
  const helmetTitle = seoPath.schedule.title;
  const ogKey = seoPath.schedule.key;
  const ogDesc = seoPath.schedule.desc;
  const ogImg = seoPath.schedule.img;
  const ogUrl = seoPath.schedule.url;
  const currentLang = i18n.language;
  const jsonLd = {
    "@context" : "https://schema.org",
    "@type": "Organization",
    "name": "ORBRO",
    "description": `${ogDesc}`,
    "alternateName": `${helmetTitle}`,
    "url":  `${ogUrl}`,
    "keywords": `${ogKey}`,
    "image": `${ogImg}`,
    "address": "#B-1203, 660, Daewangpangyo-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea",
    "email" : "orbro@orbro.io",
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+8215229928",
      "contactType": "a sales contact point",
      "areaServed": "KR",
      "availableLanguage": "Korean"
    }],
      "sameAs": "https://kr.linkedin.com/company/kongtech"
  }

  return (
    <>
    <SEO
      lang={currentLang}
      helmetTitle={helmetTitle}
      ogKey={ogKey}
      ogDesc={ogDesc}
      ogImg={ogImg}
      ogUrl={ogUrl}
      jsonLd={jsonLd}
    />
    <Spacing pixel="64"/>
      <StyledGridContainer>

        <StyledGridRow>
          <StyledGridColumn desktop={2} tablet={1} mobile={0}>

          </StyledGridColumn>
          <StyledGridColumn desktop={8} tablet={6} mobile={4}>

            <PricingPage>


              {/** 이미지 **/}
              <BoxLayout>
                <ImgWH src={Icon3dSchedule}  w={"80"} h={"80"} alt="calender icon"/>
              </BoxLayout>
              <Spacing pixel="32"/>
              {/** 타이틀 **/}
              <StyledDisplay size={"small"}>
                <Trans i18nKey={`pricing.schedule.title`} />
              </StyledDisplay>
            
            {/** 스케쥴 **/}
            <ScheduleBox>
              
              <ScheduleSideBox>
                <StyledLabel size="small">
                  <Trans i18nKey={`pricing.schedule.date`} />
                </StyledLabel>
                <PickerBox onClick={() => setIsCalendarOpen(!isCalendarOpen)}>
                  <Picker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="yyyy.MM.dd"
                      minDate={new Date()} // 이전 날짜 선택 방지
                  >
                  </Picker>
                  <CalenderIcon src={IconCalender} alt="calender icon"/>

                </PickerBox>
              </ScheduleSideBox>
              
              <ScheduleSideBox>
                <StyledLabel size="small">
                  <Trans i18nKey={`pricing.schedule.time`} />
                </StyledLabel>
                <Spacing pixel="8"/>
                <DivFlexRow>
                  <InputBox>
                    <Placeholder
                        i={hour.length}
                        value={hour}
                        placeholder={ holder3 }
                        onInput={(e) => { handleInput(e, setHour) }}
                    />
                  </InputBox>
                  <Dashbox>
                    {':'}
                  </Dashbox>
                  <InputBox>
                    <Placeholder
                        i={hour.length}
                        value={miniute}
                        placeholder={ holder3 }
                        onInput={(e) => { handleInput(e, setMiniute) }}
                    />
                  </InputBox>
                  <NoonBox>
                    <SelectTime>
                      <Noon onClick={() => setTime(true)} time={schedule}>
                        <StyledLabel size="small">
                          <Trans i18nKey={`pricing.schedule.morning`} />
                        </StyledLabel>
                      </Noon>
                      <Noon onClick={() => setTime(false)} time={!schedule}>
                        <StyledLabel size="small">
                          <Trans i18nKey={`pricing.schedule.noon`} />
                        </StyledLabel>
                      </Noon>
                    </SelectTime>
                  </NoonBox>

                </DivFlexRow>

              </ScheduleSideBox>
            </ScheduleBox>



              {/** 버틈 **/}
              <PricingSpacingTop/>
              <BoxLayout>
                <CallButton onClick={() => handleNextStep()}>
                  <LabelColor size="large" color='#FFF'>
                    <Trans i18nKey={`pricing.schedule.submit`} />
                  </LabelColor>
                </CallButton>
              </BoxLayout>

              <Spacing pixel="64"/>


            </PricingPage>


          </StyledGridColumn>


        </StyledGridRow>
      </StyledGridContainer>

    </>
  )
};

export default CallSchedule;
