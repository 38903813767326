import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: '通过逼真的图形创建城市的数字孪生，增强城市可视化。LDO5级图形表达，外部图形数据连接，基于经纬度的图形数据支持，可互动的3D模型，数字化保护历史和文化遗产，通过城市规划和开发模拟用于城市规划，设计和营销。',
  keywords: 'Digital Twin, Digital Twins, 数字孪生, gis, 数字城市, RTLS, 位置跟踪系统, 位置跟踪技术, 体育, 制造, 物流, 医疗, 医院',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - 生动的图形城市实现 :: ORBRO博客',
  ogUrl: 'https://orbro.io/cn/blog/digitaltwin-digitalcity',
  ogKey: '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twin - 生动的图形城市实现',
  date: '2023-12-26',
  tag1: '#数字孪生',
  tag2: '#CCTV',
  tag3: '#数字城市',
  tag4: '#虚拟现实',

  text: [
    {
      head: 'Digital Twin - 生动的图形城市实现',
      subHead: '',
      desc:'ORBRO利用数字孪生技术实现城市生动图形的逼真表现，增强城市可视化。为此，提供LDO5级别的高分辨率图形、外部图形数据集成、基于纬度和经度的准确图形数据支持、互动式3D模型、历史和文化遗产的数字保存，以及城市规划和发展模拟。<br/><br/>ORBRO的高分辨率图形表现逼真地展示城市，为用户提供沉浸感。外部图形数据的集成有效整合了准确且多样化的城市信息。此外，基于纬度和经度的图形数据支持精确的位置信息和地理分析。<br/><br/>互动式3D模型帮助用户实时探索和分析城市的各个部分。历史和文化遗产的数字保存保护了城市的价值并增强了其教育方面。此外，城市规划和发展模拟支持有效的战略规划和发展决策，促进城市的可持续发展。',
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/KkvO62eClcU?si=AQmNMfH6nmQ5Aa3Z',
      head: '',
      subHead: 'LDO5级生动的图形表达',
      desc: '通过具有最高级别的细节和分辨率的图形来逼真可视化城市的样貌。 这用于城市规划、设计、营销，并通过与虚拟现实和增强现实的整合提供新的用户体验。',
      dot: ''
    },
    {
      head: '',
      subHead: '外部图形数据连接',
      desc: '从各种外部来源收集和整合图形数据，提供更准确和丰富的城市模型。 这有助于提高城市的视觉表现，并为准确的城市分析和规划做出贡献。',
    },
    {
      head: '',
      subHead: '基于经纬度的图形数据支持',
      desc: '支持基于经纬度坐标的图形数据，实现准确的位置信息和地理分析。 这用于城市规划、房地产开发、旅游业等。',
    },
    {
      head: '',
      subHead: '可互动的3D模型提供',
      desc: '提供用户可以互动的3D模型，使其能够实时探索和分析城市的各个要素。 这用于规划、教育、模拟等。',
    },
    {
      head: '',
      subHead: '数字化保护历史和文化遗产',
      desc: '通过将历史建筑或文化遗产数字化保存，有助于提高文化遗产的保护和教育价值。',
    },
    {
      head: '',
      subHead: '城市规划和开发模拟',
      desc: '使用实际和相似的图形进行城市规划和开发场景的模拟。 这为有效的规划和开发决策提供必要的信息。',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: '将UWB终端安装在工人或设备上，支持实时位置跟踪的产品。',
        img: productImg.product.workerTracking.thumbnail
      },
      {
        text: '',
        title: '',
        serial: '',
        desc: '',
        img: ''
      }
    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]
  }
}
