import { Vertical } from "@assets/styled/flex";
import React from "react";
import { Trans } from "react-i18next";
import { StyledUseCaseCard } from "./UseCaseCard.style";
import { StyledHeading, StyledParagraph } from "@components/Text";
import { Spacing } from "@pages/CommonStyle/common.style";
import { StyledUseCaseCardIcon } from "../../Common/UseCaseCard/UseCaseCard.style";

interface UseCaseCardProps {
  icon: string,
  title: string,
  description: string,
  items: string
}

const UseCaseCard = (props: UseCaseCardProps) => {
  // Props
  const { icon, items } = props;

  return (
    <StyledUseCaseCard desktop={4} tablet={4} mobile={4}>
      <Vertical>
        <StyledUseCaseCardIcon src={icon} alt="example icon"/>
        <Spacing pixel="16"/>
        <StyledHeading size="small"><Trans i18nKey={ `stereo.useCase.card.${items}.title` }/></StyledHeading>
        <Spacing pixel="8"/>
        <StyledParagraph size="medium"><Trans i18nKey={  `stereo.useCase.card.${items}.description` }/></StyledParagraph>
        <Spacing pixel="4"/>

      </Vertical>
    </StyledUseCaseCard>
  );
};

export default UseCaseCard; 