export default {
  data: [
    {
      title: '建設',
      page: [
        { name: '作業員の安全管理', link: '/jp/enterprise/construction/safety' },
        { name: '建設資材の測位と管理', link: '/jp/enterprise/construction/asset' },
        { name: '重機の管理', link: '/jp/enterprise/construction/equipment' },
      ]
    },
    {
      title: '物流',
      page: [
        { name: '在庫の測位と管理', link: '/jp/enterprise/logistics/asset' },
        { name: '倉庫作業員の安全管理', link: '/jp/enterprise/logistics/safety' },
      ]
    },
    {
      title: 'オフィス',
      page: [
        { name: '資産管理', link: '/jp/enterprise/office/asset' },
      ]
    },
    {
      title: '医療',
      page: [
        { name: '患者の位置測位', link: '/jp/enterprise/medical/patient' },
        { name: '医療廃棄物の管理', link: '/jp/enterprise/medical/waste' },
      ]
    },
    {
      title: '製造',
      page: [
        { name: 'リアルタイムの工程管理', link: '/jp/enterprise/manufacturing/process' },
        { name: 'リアルタイムの在庫管理', link: '/jp/enterprise/manufacturing/asset' },
      ]
    },
    {
      title: '小売',
      page: [
        { name: '展示場の来場者分析', link: '/jp/enterprise/retail/exhibition' },
      ]
    },
    {
      title: 'ダウンロード',
      page: [
        { name: 'iOS', link: '/jp/enterprise/logistics/safety' },
        { name: 'Android', link: '/jp/enterprise/logistics/safety' },
      ]
    },
    {
      title: 'サポート',
      page: [
        { name: '技術', link: '/enterprise/technology/uwb' },
        { name: 'ブログ', link: '/blog' },
        {name : '営業問い合わせ', link : '/request'},
        { name: '会社紹介', link: '/company' },
      ]
    },
    {
      title: 'パートナー',
      page: [
        { name: 'ORBROホーム', link: '/' },
        { name: 'コンチェック', link: '/jp/enterprise/logistics/safety' },
        { name: 'プルートコン', link: '/jp/enterprise/logistics/safety' },
      ]
    },
    {
      title: 'お問い合わせ',
      page: [
        { name: '電話: 1522 - 9928' },
        { name: 'メール: orbro@orbro.io' },
      ]
    },
  ]
}
