import React, { PropsWithChildren, useEffect, useState } from "react";

import { Trans } from "react-i18next";
import { ModalContainer, DialogBox, DialogPolicyBox, DialogBtnBox, DialogBtn, DialogBorder, DialogSpanText, PolicySpanText, DialogBtnCancel, ModalEmailInput } from "./Modal.style";

import { StyledDownloadBtnlTextCancel, StyledDownloadBtnlTextSubmit } from "./ModalDownload.style";

import { ClearButton, ClearIcon, DialogCheckBox, DialogInput, DialogInputText, StyledDownloadPolicyLink, StyledUnderline } from "@pages/Main/Common/Purchase/Modal/Modal.style";
import { useLocation } from "react-router-dom";
import { IconCheckBoxBlack, IconCheckBoxEmpty, IconCloseIcon, IconXMark } from "@assets/images/icon/";
import emailjs from 'emailjs-com';
import { RequestInputBox } from "../../Request/Request.style";

import Template from "./Template";
import i18n from "@pages/i18n";
import { DivFlexRow, DivWidth100 } from "@pages/CommonStyle/commonDiv.style";
import { ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import { CloseBtn } from "@pages/CommonStyle/commonBtn";
import { Backdrop } from "@pages/Enterprise/Download/Modal/Modal.style";
import { StyledHeading } from "@components/Text";

interface ModalDefaultType {
  onClickToggleModal: () => void;
  content: string
  onSubscribe: () => void;
  onSaveEmail: (email: string) => void;
  pageName: string
}




function ModalDone({
  onClickToggleModal,
  content,
  onSubscribe,
  onSaveEmail,
  pageName

}: PropsWithChildren<ModalDefaultType>) {


  let placeholder;
  const location = useLocation();
  if (location.pathname.startsWith('/en/')) {
    placeholder = 'Please enter an email'
  } else if (location.pathname.startsWith('/jp/')) {
    placeholder = 'メールを入力してください'
  } else if (location.pathname.includes('/cn/')) {
    placeholder = "请输入电子邮箱。"
  } else {
    placeholder = '이메일을 입력해 주세요.'
  }

  const [templateID, setTemplate] = useState<string>('');
  const [page, setPageName] = useState<string>('');

  const [focus, setFocus] = useState({
    email: false,
  });

  const handleClearEmail = () => {
    setEmail('');
  };
  const handleFocus = (field) => {
    setFocus((prevFocus) => {
      const updatedFocus = {} as {
        email: boolean;
      };
      for (const key in prevFocus) {
        updatedFocus[key] = key === field;
      }
      return updatedFocus;
    });
  };

  const handleKeyDown = (e, currentField) => {
    if (e.key === "Tab") {
      e.preventDefault();

      const fields = ["email"];
      const currentFieldIndex = fields.indexOf(currentField);
      const nextFieldIndex = currentFieldIndex === fields.length - 1 ? 0 : currentFieldIndex + 1;
      const nextField = fields[nextFieldIndex];

      const nextInputElement = document.getElementById(`${nextField}-input`);
      if (nextInputElement) {
        nextInputElement.focus();
      }
    }
  };


  const handleModalClose = () => {
    onClickToggleModal();
  }
  const [email, setEmail] = useState<string>('');

  const [checked, setChecked] = useState(false);
  const [submit, setSubmit] = useState(false);

  const onDownload = () => {
    if (submit) {
      //이메일 보내기
      onSendEmail();
      //클릭 이벤트 알림 이메일
      onClickEmail();
      //다음 모달 연결
      onSubscribe();
      //다음 모달로 데이터 전달
      onSaveEmail(email);
      //창 닫기
      onClickToggleModal();
    }

  }
  const onSendEmail = () => {
    emailjs.init('SoQtE3CThBY_wpeDn');
    const mailForm = {
      email: email,
    }

    emailjs.send('service_7yv5b7d', templateID, mailForm)
      .then((response) => {
        console.log('이메일 보내기 성공:', response);
      })
      .catch((error) => {
        console.error('이메일 보내기 실패:', error);
      });

  }

  const onClickEmail = () => {
    emailjs.init('SoQtE3CThBY_wpeDn');
    const mailForm = {
      email: email,
      page: page,
      lang: i18n.language,

    }

    emailjs.send('service_7yv5b7d', 'template_bhg67ab', mailForm)
      .then((response) => {
        console.log('이메일 보내기 성공:', response);
      })
      .catch((error) => {
        console.error('이메일 보내기 실패:', error);
      });

  }

  const policyCheck = () => {
    setChecked(!checked);
  };

  const onChange = (e) => {
    //Email 콜백
    const email = e.target.value;
    setEmail(email);
  }


  useEffect(() => {

    let emailCheck = false;
    if (email.includes('@') && email.includes('.')) {
      emailCheck = true;
    }

    if (emailCheck && checked) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  }, [email, checked]);


  // Input event
  const handleInput = (event, callback) => {
    const value = event.target.value;
    callback(value);
  }



  return (

    <ModalContainer>
      <DialogBox>
        <Template
          pageName={pageName}
          setPageName={setPageName}
          templateID={setTemplate}
        />
        <DivWidth100>


          <DivFlexRow>
            <StyledHeading size="small"> <Trans i18nKey={"solutionCommon.modal.download.title"} /> </StyledHeading>
            <Spacing pixel="8" />
            <CloseBtn src={IconXMark}
              onClick={onClickToggleModal} size={"20"} alt="close mark" />
          </DivFlexRow>

          <DialogBorder />

          <div>

            <DialogSpanText deco={false} color={"#000"}>
              <Trans i18nKey={"solutionCommon.modal.download.desc"} />
            </DialogSpanText>
            <DialogSpanText deco={true} color={"#000"}>
              <Trans i18nKey={"solutionCommon.modal.download.phone"} />
            </DialogSpanText>
            <DialogSpanText deco={false} color={"#000"}>
              <Trans i18nKey={"solutionCommon.modal.download.descTail"} />
            </DialogSpanText>

            <Spacing pixel="8" />
            <ParagraphColor size="small" color='#818181' ><Trans i18nKey={"solutionCommon.modal.download.time"} /> </ParagraphColor>

            <Spacing pixel="32" />
          </div>

          <RequestInputBox inputValue={focus.email}>
            <DialogInputText inputValue={focus.email} >
              {focus.email ?
                ('email')
                :
                (<>{' '}</>)
              }

            </DialogInputText>
            <ModalEmailInput focus={focus.email}>
              <DialogInput
                value={email}
                placeholder={placeholder}
                onInput={(e) => { handleInput(e, setEmail) }}
                onChange={onChange}
                onClick={() => handleFocus('email')}
                onKeyDown={(e) => handleKeyDown(e, "name")}
              />

              {email !== '' && focus.email && (
                <ClearButton onClick={handleClearEmail}>
                  <ClearIcon src={IconCloseIcon} alt="close mark" />
                </ClearButton>
              )}
            </ModalEmailInput>
          </RequestInputBox>


          <Spacing pixel="24" />

          <DialogPolicyBox >
            {checked ? (
              <DialogCheckBox src={IconCheckBoxBlack} onClick={policyCheck} alt="checkbox" />
            ) : (
              <DialogCheckBox src={IconCheckBoxEmpty} onClick={policyCheck} alt="checkbox" />
            )}

            {location.pathname.includes('/en/') ?
              (<>
                <PolicySpanText color='#000' ><Trans i18nKey={"solutionCommon.modal.download.agree1"} />  </PolicySpanText>
                <PolicySpanText color='#000' ><Trans i18nKey={"solutionCommon.modal.download.agree3"} /> </PolicySpanText>
                <StyledDownloadPolicyLink href="/terms/2" target="_blank" >
                  <PolicySpanText color='#2A70F0' ><Trans i18nKey={"solutionCommon.modal.download.agree2"} /></PolicySpanText>
                  <StyledUnderline />
                </StyledDownloadPolicyLink>

              </>)
              :
              (<>
                <PolicySpanText color='#000'><Trans i18nKey={"solutionCommon.modal.download.agree1"} />  </PolicySpanText>
                <StyledDownloadPolicyLink href="/terms/2" target="_blank" >
                  <PolicySpanText color='#2A70F0' ><Trans i18nKey={"solutionCommon.modal.download.agree2"} /></PolicySpanText>
                  <StyledUnderline />
                </StyledDownloadPolicyLink>
                <PolicySpanText color='#000'><Trans i18nKey={"solutionCommon.modal.download.agree3"} /> </PolicySpanText>

              </>)
            }

          </DialogPolicyBox>

          <Spacing pixel="32" />

          <DialogBtnBox>

            <DialogBtnCancel onClick={handleModalClose}>
              <StyledDownloadBtnlTextCancel size="medium" color='#000000' ><Trans i18nKey={"solutionCommon.modal.download.cancel"} /> </StyledDownloadBtnlTextCancel>
            </DialogBtnCancel>

            <DialogBtn onClick={onDownload} bgcolor={submit ? '#000000' : '#BDBDBD'}>
              <StyledDownloadBtnlTextSubmit size="medium" color={'#FFF'} ><Trans i18nKey={"solutionCommon.modal.download.confirm"} /> </StyledDownloadBtnlTextSubmit>
            </DialogBtn>

          </DialogBtnBox>

        </DivWidth100>

      </DialogBox>


      <Backdrop
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();

          if (onClickToggleModal) {
            onClickToggleModal();
          }
        }}
      />

    </ModalContainer>


  );
}

export default ModalDone;