import { SCREEN_BREAKPOINT, StyledGridContainer } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";



export const StyledAppPage = styled(StyledGridContainer)`

  overflow:hidden;
  margin-top : 64px;

  & * {
    font-family: Pretendard !important;
    font-display: swap;
  } 


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
    
  }
`;