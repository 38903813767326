import { ptzThumb } from "@assets/images/main/camera";

import * as banner from "@src/pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  home: {
    title: 'オブロ | ORBRO、デジタル変革の始まり',
    key: '屋内位置測位、RTLS、リアルタイム位置測位、資産管理、資産測位、人口カウンティング、リアルタイムロケーションシステム',
    desc: 'さまざまなオブロ製品をご覧ください',
    img : '',
    url : 'https://orbro.io/jp/'
  },
  
  solution: {
    constructionAsset: {
      title: '建設資材の測位と管理 | ORBRO、デジタル変革の始まり',
      key: '建設資材管理、建設現場管理、資材管理、資材測位、物品測位、建設現場測位、屋内位置測位、資産管理、労働者の安全管理、労働基準法、Orbro',
      desc: '正確な資材管理は最終的には生産性を向上させます。資源の無駄を減らし、品質を維持し、さらには安全性にも影響を与えるため、資材管理は建設現場で重要な要素です。',
      img: banner.constructionAsset.banner,
      url: 'https://orbro.io/jp/enterprise/construction/asset',
    },
    constructionSafety: {
      title: '労働者の安全管理 | ORBRO、デジタル変革の始まり',
      key: '労働者の安全、労働者の安全管理、安全管理、労働者の安全、建設の安全、建設現場の安全、屋内位置測位、資産管理、労働者の安全管理、労働基準法、Orbro、RTLS、リアルタイム位置測位、労働者の測位、位置測位、屋外位置測位',
      desc: '労働者の安全は何よりも重要です。高リスク環境での労働者の安全を保証しないことは、生産性に影響を及ぼすだけでなく、法的責任も生じる可能性があります。さらに、安全な建設現場は企業のイメージ形成やステークホルダーの信頼構築において重要な役割を果たします。',
      img: banner.constructionSafety.banner,
      url: 'https://orbro.io/jp/enterprise/construction/safety',
    },
    constructionEquipment: {
      title: '重機の管理 | ORBRO、デジタル変革の始まり',
      key: '建設重機管理、建設機器管理、重機管理、重機保守、クレーン管理、建設重機、ブルドーザー管理、エクスカベーター管理、リアルタイム位置測位、屋外位置測位、RTLS',
      desc: '重機は建設現場での重要な要素です。適切な保守と事故予防は必須です。重機を適切に管理することで、現場の安全性を維持しながらコストを削減し、生産性を大幅に向上させることができます。',
      img: banner.constructionEquipment.banner,
      url: 'https://orbro.io/jp/enterprise/construction/equipment',
    },
    logisticsAsset: {
      title: '在庫の位置測位と管理 | ORBRO、デジタル変革の始まり',
      key: '在庫管理、倉庫資産管理、倉庫在庫管理、倉庫在庫測位、在庫位置測位、倉庫内位置測位、屋内位置測位、RTLS、倉庫管理、資産管理、倉庫物品管理、在庫測位、リアルタイム位置測位',
      desc: '在庫管理はサプライチェーンの効率とスムーズな運用を支える重要な要素です。在庫の位置をリアルタイムで把握することで、在庫レベルを効果的にモニタリングし、在庫移動を最適化することができます。例えば、アイテムの人気度に応じて在庫を配置し、在庫回転率を最適化することは、コスト削減や在庫不足または過剰を防止するのに役立ちます。',
      img: banner.logisticsAsset.banner,
      url: 'https://orbro.io/jp/enterprise/logistics/asset',
    },
    logisticsSafety: {
      title: '倉庫労働者の安全管理 | ORBRO、デジタル変革の始まり',
      key: '倉庫の安全、倉庫の安全管理、倉庫労働者の安全管理、倉庫労働者管理、労働者の安全、倉庫管理、安全管理、倉庫労働者の安全、RTLS、位置測位、リアルタイム位置測位、屋内位置測位、労働者測位',
      desc: '労働者の安全は何よりも重要です。高リスク環境での労働者の安全を保証しないことは、生産性に影響を及ぼすだけでなく、法的責任も生じる可能性があります。さらに、安全な物流現場は企業のイメージ形成やステークホルダーの信頼構築において重要な役割を果たします。',
      img: banner.logisticsSafety.banner,
      url: 'https://orbro.io/jp/enterprise/logistics/safety',
    },
    officeAsset: {
      title: '資産管理 | ORBRO、デジタル変革の始まり',
      key: 'オフィス用品管理、資産管理、オフィス資産管理、オフィス在庫管理、オフィス管理、在庫管理、オフィス管理、スマートオフィス、屋内位置測位、在庫測位、資産測位、RTLS',
      desc: '効果的な資産管理は時間的・経済的な損失を引き起こします。明確で迅速な資産管理により、スムーズなビジネス運営を実現することができます。',
      img: banner.officeAsset.banner,
      url: 'https://orbro.io/jp/enterprise/office/asset',
    },
    medicalPatient: {
      title: '患者の位置測位 | ORBRO、デジタル変革の始まり',
      key: '患者の位置測位、患者管理、感染者測位、病院管理、スマート病院、屋内位置測位、リアルタイム位置測位、患者測位、病室管理、患者安全、患者モニタリング、リアルタイムモニタリング、屋内位置測位、RTLS',
      desc: '医療施設では管理が特に重要な患者が存在します。24時間直接監視する必要はありません。より正確で迅速に患者の位置をリアルタイムで測位します。',
      img: banner.medicalPatient.banner,
      url: 'https://orbro.io/jp/enterprise/medical/patient',
    },
    medicalWaste: {
      title: '医療廃棄物管理 | ORBRO、デジタル変革の始まり',
      key: '医療廃棄物管理、医療事故管理、医療事故予防、医療事故、廃棄物管理、廃棄物測位、屋内位置測位、リアルタイム位置測位、位置測位、資産管理、病院資産管理、病院在庫管理、リアルタイムモニタリング、廃棄物モニタリング、病院安全、RTLS',
      desc: '医療廃棄物管理は、医療施設で発生するさまざまな種類の廃棄物を安全かつ適切に処理し、環境汚染を防止し、感染リスクを最小限に抑え、法的規制を遵守して人の健康と資源保護に貢献する必須の作業です。廃棄物のリアルタイムな状況と位置を測位することで、医療事故を予防し、安全な医療サービスを提供することができます。',
      img: banner.medicalWaste.banner,
      url: 'https://orbro.io/jp/enterprise/medical/waste',
    },
    manufacturingProcess: {
      title: 'リアルタイムプロセス管理 | ORBRO、デジタル変革の始まり',
      key: '製造プロセス管理、製造プロセス、製造施設管理、在庫位置測位、リアルタイム位置測位、プロセス管理、プロセスモニタリング、リアルタイムモニタリング、位置測位、スマート製造、生産ラインモニタリング、屋内位置測位、プロセス分析、RTLS、製造施設資産管理',
      desc: '製造施設の進行状況を一目で把握し、管理することができます。正確で客観的なデータを使用してプロセスラインを改善し、効率性を向上させることができます。これは製造業の核心です。',
      img: banner.manufacturingProcess.banner,
      url: 'https://orbro.io/jp/enterprise/manufacturing/process',
    },
    manufacturingAsset: {
      title: 'リアルタイム在庫管理 | ORBRO、デジタル変革の始まり',
      key: '製造在庫管理、在庫管理、スマート製造、プロセスモニタリング、プロセス管理、原材料管理、モニタリング、リアルタイムモニタリング、リアルタイム位置測位、位置測位、RTLS、屋内位置測位、生産ラインモニタリング、プロセス分析、RTLS',
      desc: '製造施設では、原材料や部品など、数多くの在庫を保有しています。これらを適切に管理することは、時間と経済の面で非常に重要です。つまり、製造業の効率性に影響を与える重要な要素です。',
      img: banner.manufacturingAsset.banner,
      url: 'https://orbro.io/jp/enterprise/manufacturing/asset',
    },
    retailExhibition: {
      title: '展示場の来場者管理と分析 | ORBRO、デジタル変革の始まり',
      key: '来場者の動線分析、来場者分析、見学者の動線分析、見学者分析、動線分析、屋内位置測位、リアルタイム位置測位、来場者分析、来場者把握、位置測位、RTLS',
      desc: '正確で客観的なデータはビジネスの成長を促進することができます。無駄な人員やコストを最小限に抑え、スマートな展示場のイメージを提供し、差別化された運営が可能です。',
      img: banner.retailExhibition.banner,
      url: 'https://orbro.io/jp/enterprise/retail/exhibition',
    },
  },
}
