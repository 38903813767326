import { accessAuto1, accessAutoMain, accessPro1, accessProMain, accesscontroller1, accesscontrollerMain } from "@assets/images/main/access/product";
import { assetTracking1, workerTracking1 } from "@assets/images/main/rtls/product";


export default {

    specTitle : {

    },
    
    specData : [
        {
          data: [
            {title : "Product Name"},
            {text1 : "Mobile Entry"},
            {text2 : "Automatic Door Entry"},
            {text3 : "Facial Recognition Entry"},
          ],
    
        },
        {
          data: [
            {text : "Description"},
            {text : "A very convenient device that controls the entrance door with a mobile app"},
            {text : "A convenient device for entry without running the app"},
            {text : "A device that allows various entries such as face recognition, NFC cards, etc. all at once"},
          ],
    
        },
        {
          data: [
            {text : "Power Supply"},
            {text : "Battery Use (up to 3 years)"},
            {text : "DC 12V, 24V "},
            {text : "DC 12V, 3A"},
          ],
    
        }
    ],
    
    a: [
      {text : "Product Name"},
      {text : "Description"},
      {text : "Bracket"},
      {text : "Precision"},
      {text : "Communication Method"},
      {text : "Tag Terminal Power"},
      {text : "Tag Terminal Usage Period"},
      {text : "Installation Support"},
      {text : "Heatmap"},
      {text : "Bracket"},
      {text : "Tag Terminal Search"},
      {text : "Route Tracking"}
    ],
    
    b: [
      {text : "Bluetooth (BLE) Tracking"},
      {text : "Attach a BLE tag terminal to the target you want to monitor or track as an asset, enabling real-time location tracking."},
      {text : "Ceiling, rail, etc."},
      {text : "Up to 10m"},
      {text : "BLE"},
      {text : "CR 2430"},
      {text : "Up to 1 year"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : ""},
    
    ],
    
    c: [
      {text : "UWB Precision Tracking"},
      {text : "Attach a UWB tag terminal to the target you want to monitor or track as an asset, enabling real-time location tracking."},
      {text : "Ceiling, rail, etc."},
      {text : "Up to 10cm"},
      {text : "UWB"},
      {text : "USB - C"},
      {text : "Up to 2 weeks"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
      {text : "1"},
    
    ],
    
    spec: [
      {
    
      },
    
      {
        image : assetTracking1,
    
      },
    
      {
        image : workerTracking1,
    
      },
      
        
    ],

    
}