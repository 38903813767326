import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4";

export default {

    helmet : '지오팬스란 무엇인가요?',
    desc : '지오펜스(Geofence)는 지리(Geography)와 울타리(Fence)를 결합한 단어로, 현실 지역에 가상구역이나 가상의 울타리 경계를 만드는 기술입니다.',
    keywords : '지오팬스, 지오팬싱, geofence, geofencing, RTLS, 실내위치추적, 실내네비게이션, 위치추적솔루션, 실시간위치추적, 자산추적, 스마트오피스, GPS, 실내위치추적솔루션',
    
    ogTitle : '지오팬스란 무엇인가요? :: 오브로 블로그',
    ogDesc :'지오펜스(Geofence)는 지리(Geography)와 울타리(Fence)를 결합한 단어로, 현실 지역에 가상구역이나 가상의 울타리 경계를 만드는 기술입니다.',
    ogSite : '지오팬스의 기능',
    ogUrl : 'https://orbro.io/blog/geofence',
    ogKey : '',
    ogImg : "https://lh3.googleusercontent.com/fife/APg5EOZ2VWDCFEvSR2JhseSzS-2cQQnuMSFaM4obXlnNyqODBBoOLK1WTlmDiYajp8HYXztTLEp85J505ZV5DYOw4XK3f1-uBLTCKdymXB4nkl-0IPByDA2WVhTi9FpkH6to_X-pf_w4WnDhm_8vFh7GpJQoCH2jVpjtqLF8i-1MvlxeG-_wdQc3GSE5gyxieKJPBXZ8-h0_v0W275RZ1buY2zhtDYW-Je3wEqRDi5ZAsE_4zx3kHHfBjUgPf7tXIf74EEuIJb11hTQvdt9_n0xPGdgUFS1jN_qXOt0I693By1-jF0KtPqBaUdKQLY0gRf72BYwMTcCZRXQVo33POJ_kdczRYP9s1aOYSsbil2Xm21xAepKz9UeeByVEcX4Xj-yHE0JQh7B3-djLNtAGljujc4PvseRrPymDygPFx9o46B9PNRNGuFrORa_oJ1MLhFeqyW9xNQQu2a84bqh7v9ZOh714RBLDjAqUSDa0ZRXXWhKJqcMGjSWnjPyfFw7ILKzwJGKoiFmCCwFqlovHO3zTKM3Zem4TEk4g2msD6wwMQ3Ptsft6tcszn1HdpRRZ2l6Qq3ha5k8ewubMa06Q-aFkk-4apY3DsX-8Ey4b9kTIQqFNLLKHFNpFZdC5iPT-LYi56yuQUJPpJ7KF6RRexa3HfDTWeT8FNwnqNuDyCrbQ06Ix4Es5S1jXOW8ofiK2NplaeNg_1tca9QbIe-FDTOPqTeEHqMwCfDVncRYh0Z3e0x4Ywk6ebn2F0kccqqb6F7cVLGaVLOTsRs4rQ1r43TmZoG2elSGIsBntEp49Zn521JghIhCHcDIFjaYb9-RcgDF5oO7GIJT_YhTgPnWvKmGoBuesfZQaVSWgY5lhRHjULVAv-sg9X_EMWY3VhMkHAeGJlePyaD1531N_6-6UAs-QY7Ny66DVNen_QukuG1oAfQ29qDuvq9zJz2RrbafnZ2rantG3F_r2W0jvZiWuI-V_qiu4xBaQAzNklEly2t1aC7QDEW5qgzvbiS3hjC7U9KaRgsKFl_IhwaEjJMa_jv8JyNItI4P0XMjpADTrBPEgLFIbKGPiwtK2Ijjj91Cx-y2ElqS_-fmTQd63XX7c3Mhy4gK6SISrs24Zx9VdSKueoy7cBXubjcSj9QghIZsDvu3UpcZBq9EF8zYSf9-WGJIPj9qFf5_x4S8Ty_cD_XKF_Ic6bRMa_jZToGam_G5X94ag30kD3Wnrlw4AI3YFIHn_AYPVRXyz9aWY4QL1hiMrAHHLhGNd0ozBYqtmxSxga0ZI9UcIhlolXBSRe4D2JksdHn1ixx_WgBPto76Lt99eGq30wecQJbnSQlylNuqtkAvO1XBneOkYEEI0BT8eNwwz1R910N416ZXKPkgxujAs-FZHJoZxlp90d9Kv1UzKcKNKCbZgO6x3No9f-nkHzKtMmz5pVltLwkh5Hb7R-hcshSq2N4Vr1fpbMOpaJumw-fa-XiCszh7LwMXHgUO_8_071RRZfW0tBNV2OSKvdLNUv7yM_MrJ71sMx88Dbz0L1g=w1680-h1184",
    ogType : 'website',

    
    twitterCard : 'summary',
    twitterTitle : '지오팬스의 기능',
    twitterDesc : '지오펜스(Geofence)는 지리(Geography)와 울타리(Fence)를 결합한 단어로, 현실 지역에 가상구역이나 가상의 울타리 경계를 만드는 기술입니다.',


    title: '지오팬스(Geofence)란 무엇이며<br/>어떤 일을 하나요?',
    date : '2023-05-01',
    tag1 : '#지오팬스',
    tag2 : '#자산추적',
    tag3 : '#RTLS',
    tag4 : '#안전',
    
    // <br/>
    text : [
      {
        head : '지오팬스(Geofence)란 무엇이며, 어떤 일을 하나요?',
        subHead :'',
        desc : '지오펜스(Geofence)는 지리(Geography)와 울타리(Fence)를 결합한 단어로, 현실 지역에 가상구역이나 가상의 울타리 경계를 만드는 기술입니다. <br/> 실시간 위치 추적 서비스(RTLS)를 사용하여 사람들이 해당 구역을 지나가는지 모니터링할 수 있으며, 이는 민간 사업, 의료, 군사, 항공 등등 대부분의 분야에서 활발하게 활용될 수 있습니다. <br/>또한 지오펜싱 시장은 2022년 19억 달러에서, 2032년 95억 달러를 넘어설 것으로 예측될 정도로 미래 산업에 있어서 큰 가치를 창출해 낼 것입니다.',
        dot : '',
      },
      {
        videoDesc : '<br/><br/>(지오펜스 기술을 활용한 건설현장 안전 관리 솔루션)',
        video : video,
        link : '/enterprise/construction/equipment',
        head : '',
        subHead :'지오펜싱은 실제로 어떻게 작동합니까?',
        desc : '위치 기반 서비스 장치가 일차적으로 특정 경계 및 구역을 설정하고, 개발자가 해당 특정 위치 주변에 가상 경계선을 설정합니다. 이후 지오펜스는 와이파이, 셀룰러 데이터 등의 네트워크나 비콘 등 다른 보조 기술과 결합해서 사용자의 실시간 위치와 출입 정보를 파악합니다. 위치 정보를 담은 모바일 기기가 지정된 구역에 들어오면, 조건에 따라 텍스트 메시지, 이메일, 앱 알림 등 미리 정해진 특정 동작을 실행하게 됩니다. <br/>보통은 모바일을 통해 프로세스가 이루어지며 사용자가 앱 위치의 접근을 거부할 수도, 별도로 설정할 수도 있습니다.<br/><br/>위치 기반 서비스 사용에 대해서는 기본적으로 사용자 동의를 우선으로 합니다.',
        dot :''
      },
      {
        head : '',
        subHead :'지오펜싱, 어디에서 사용할까?',
        desc : '',
        dot : {
          a :' • <strong>자산</strong> : 자산(차량, 트랙터, 컴퓨터 등)에 지오펜싱 기술을 설치하여 해당 자산이 지오펜스 위치 외부로 이동하는 시기를 추적할 수 있습니다.',
          b :' • <strong>인적 자원</strong> : 직원이 회사에 얼마나 오랫동안 머물렀는지 그리고 출퇴근 여부를 정확히 파악할 수 있습니다. 현장 근로자의 경우, 출입 통제 지역을 더 효율적으로 관리할 수 있습니다.',
          c :' • <strong>매장</strong> : 방문을 유도하기 위해 관광객이 매장을 지나갈 때 홍보 메시지를 보냅니다.',
          d :' • <strong>홈 IoT</strong> : 스마트폰 사용자가 지오펜스된 집을 떠나면 온도 조절 장치가 내려갑니다.',
          e :' • <strong>군사</strong> : GPS 등의 위치 정보 기술을 기반으로 한 지오펜싱 기술이 공항 및 군사 지역 가까이에 위험한 물체나 비행이 접근하지 못하도록 제한하는 서비스를 제공합니다.',
          f :' • <strong>공항</strong> : 인근 시설에 관여한 메시지를 전달받을 수가 있습니다. 무료 와이파이 서비스 및 공항 주변 가이드 서비스를 제공받을 수도 있습니다.',
        }
      },
      {
        head : '지오펜싱, 얼마나 도움이 될까?',
        subHead :'1. 안전 및 보안',
        desc : '건설 현장, 병원, 군사시설 등등 안전 또는 보안에 예민한 구역을 지정하고 이에 접근하는 사람들을 모니터링 할 수 있습니다.<br/>이로써 안전사고와 사건으로부터 예방 및 빠른 대처가 가능합니다.<br/>',
        dot : ''
      },
      {
        head : '',
        subHead :'2. 근무 효율',
        desc : '지오펜싱 기술은 작업자와 작업장의 안전 및 효율과 직접적인 연관이 있습니다. <br/> 예를 들어, 회사에서 근무하는 직원 또는 작업현장에서 일을하는 작업자들의 근무 상태를 모니터링 합니다.<br/>일정시간동안 근무지에서 벗어나 있거나 근무 도중 가서는 안되는 지역으로 접근하는 경우,<br/>관리자는 이에 적절한 조치를 취하여 추후에 발생할 수 있는 문제에 대해 예방할 수 있습니다.',
      },
      {
        head : '',
        subHead :'3. 개인화된 고객 경험',
        desc : '특정 지역을 지나가는 사람을 대상으로 쿠폰, 혜택을 제공함으로써 고객 경험을 개인화 합니다. <br/> 이로써 이전에는 낭비되었던 마케팅 비용을 절감할 수 있습니다.   <br/>최근 국내 여러 카드사에서 사용자가 백화점에 접근하였을 때, 해당 구역의 사람들에게 알림을 보내는 것 또한 지오펜싱 기술을 활용한 덕분입니다.',
      },

    ],

    link : {
      page : 'rtls',
      numb : '2',
      product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
          img : productImg.product.workerTracking.thumbnail
        },
        {
  
        }
  
      ],
      solution : [
        {
          img : constructionAsset.thumb,
          data : 'constructionAsset',
          link : '/enterprise/construction/asset'
        }, 
        {
          img : constructionSafety.thumb,
          data : 'constructionSafety',
          link : '/enterprise/construction/safety'
        },
        {
          img : logisticsAsset.thumb,
          data : 'logisticsAsset',
          link : '/enterprise/logistics/asset'
        }
      ]
  
  
    }
  

  }