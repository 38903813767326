import { constructionAsset, constructionEquipment, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| スマート建設現場安全管理システム',
  desc : '建設現場でIoT技術とRTLSを活用し、作業者の位置をリアルタイムで追跡し、危険なエリアに入ると警告を発生させることで安全性を向上させます。これにより、作業者の安全を確保し、事故の予防を改善し、現場管理の効率を向上させることができます。',
  keywords : '建設安全、建設現場作業者安全、スマート建設、労働者安全、重大災害処罰法、建設労組、安全労働',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'スマート建設現場安全管理システム :: ORBRO Blog',
  ogUrl : 'https://orbro.io/jp/blog/smartConstruction',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4OLTH19o0lofjwwmkKAz-9CGPCQcIHeGEvzHeubn5er_MCfU-q7GYUawQUgiBhV0p2H76qh1eSAjvENS8D30uK8wsm0dvRVz9fbghVm-npKypYxWtsi_kYTYd1uZYhCTnPpxWWxuJjMsmCvjKN2aY8L6u8uqXYCtcS2irDtBPmqv0nFkB22q83mh1u0tHLtyMOjggWKiJKLrctPn4GE5QW3s9JSv6OmA8ZhKbRghTN42Hq8Et9cH8kU-Nkbv_b26v6aQUdGmrak5jXiAKE4sXCbRnLX5IT_XnZo660WsMWHlDftI9hI392gO7mJYmKHNYA0Hhdh8rCxv2YcyNz4KSiwSz9Jp3W-OhtcoLEyNuTfLi_3AS4__oyVTxTqqP61RHt_sRKPpHWzYWKD2-lRFFqGAQnxfdW-cS3KHJFgwx2bn3SlV2YmG8WDnLTN2xFmd3_t6DTG8QVZbDg2gHQz4sqmC91sVjpA-UACZ0Ea_8Z5tsVSsIUcZeOLqJ2OSf4CgoOXD7KX5QgQz0O8OI6hq8riIT0Q_ubXhp_-gNUyjqFj6XUxFp1N8l1vWcJz_vaq817pMKLQOh5tUhXUl1QPqo43LWZGk6FCPDhUDVOZbq731ORKRpWvfDieeQpaAHXBh1TIb104d8BxMZNoVwDMtQjqysfBeMFBDTJDq1cQOGrhcT1uLJ8p0sdYxZQrKha8F66CA9glk9n61-IowjHJKAFd8H0Rrapwp7UJwhug12o4UvffLxnbqNzmdX1RraJoFrfcs6Fhp8da4-TdfOtEqhwoTkqobcXUN07zWEQUl2CRHHRUG82xw4wlcF9BIunVPJe894pfmXE4C0ETayUHYXDJcIYK7SQUS08H7Xi2fyQHw_zZIHg8Lj_w3ejIUGBANJuIxQglvskR5g7NjbJ0EBv2bCKLqkGEsPZMG5ypfBu2BtfKkDZT4kekWaO9TXJrrVDYa_A18PBQSN9hv2G8JwBGt_bvRlGZMDsGu6VCwq3fJAB8gtzb_0U8PrztEHGSsojNVoVZ7BN6OUROQFTH9IQZHIv_NC9jPraNFThG8ydegAos8aSaqEMeohU8FOv1Lck-kOCLo-HB0qSX11IkppPvCaSgblbcpB9ET8m0I3gQGkJpyBdwmY70NMVKUe1az2MUpZRUgoQaLP7ZKujMw7syYLaZ_hCiXuYqsF_Fn7yZ9nmG1FjxDGVKEbijl33lTSgLAWVc1yhzuS-8__AcbhG2nI2aAii6ZxqZAfHMBHQQ5BLYQTJCpJZjLeJKgBg_XT6Gl56dGOq8vhdrl8NdBqsL54EVg_a_qn4cvrSaQ5Lt8IDGgYgNFvrPBwLgnqWb2Ll6oLL1MvhV2vru9BFadHclWr0Ly4nPy5DI1Lr4ghP2biSKLMQeXyUo97JePQN6ei9jdi7k1hzr9Kk1vrBIFz5Okp_FaZeKDikQ5oZ7yVctdAjvZIBO-h5i_Zin6sl878TaGDErBn301m-gHvF1G8AMlU46qZnNBPlPld-YUOqM6V79RFxmf_wpJWAFld9xB4ud-CLWzo6xdWzpanyQoaf7cj9Myian97M297lV5zEBj-Q=w2295-h1233',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'スマート建設現場安全管理システム',
  date : '2023-07-31',
  tag1 : '#建設安全',
  tag2 : '#労働者の安全',
  tag3 : '#建設現場安全',
  tag4 : '#建設安全管理',
    
  text: [
    {
      head : '',
      subHead : '',
      desc :'建設現場は他の産業よりもリスクが高い場合が多くあります。したがって、労働者の安全は何よりも優先されるべきです。このような高リスクの環境で労働者の安全を保証しないことは、プロジェクトに支障が生じるだけでなく、法的な責任を問われることもあります。<br/>つまり、建設業界において最も致命的であり、最も重要だと言えるでしょう。<br/><br/>多くの安全事故は、労働者が安全装置を適切に着用しなかった場合に発生します。また、事故は常に非常に短い瞬間に発生するため、迅速な対応を取らないと命を失うこともあります。しかし、リアルタイム位置追跡技術、RTLSと呼ばれる技術を使用すると、建設現場の労働者をはじめ、現場の状況をリアルタイムで追跡、管理できるため、より安全な建設現場を作ることができます。安全だけでなく、建設現場で使用される資材管理をはじめ、重機を管理し、労働者の動線を分析してプロジェクトの効率性を大幅に改善することができます。<br/><br/>以下では、このような技術が建設現場をどのように変化させ成長させるかについて説明します。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'1.安全装置着用管理',
      desc : '安全装置着用は建設現場で最も基本的で必須の安全プロトコルです。しかし、何らかの理由で労働者が適切に装置を着用しないことがあります。手間がかかる、不便だ、油断が結局致命的な事故につながる可能性があります。<br/>リアルタイムで労働者が安全装置を着用しているか判断し、現在の位置が危険要因が多い現場であればより注意して管理できます。管理者はこの状況をリアルタイムでモニタリングして注意を促したり、それに応じた措置を取ることができます。<br/>もし事故が発生した場合、当時の状況を把握する重要なデータとして使用されることもあります。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'2.リアルタイム位置追跡',
      desc : '時折、迅速に特定の労働者の位置を把握する必要があるか、現場全体の労働者状況を把握する必要がある場合、リアルタイムで追跡される労働者の位置がモニターに表示されます。現場と同じ地図上にリアルタイムで動く労働者の位置は、状況を迅速に把握し判断できるよう支援します。<br/>またはジオフェンス（geofence）という技術を活用して、特定のエリアを危険エリアと設定し、現場の労働者がそのエリアに入る場合、リアルタイムでアラートを送るようにすることもできます。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'3.労働効率管理',
      desc : '労働者の動線や滞在時間、休憩時間などを把握することができます。労働者ごとにどのような勤務特性を持っているか把握し、全体的な流れとプロジェクトの効率を改善するために必要なデータを得ることができます。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'4.重機管理',
      desc : '現場に労働者だけが移動するわけではありません。特に安全に関しては重機も重要に考慮すべき対象です。重機をリアルタイムで追跡し、現場の労働者と近接する場合、即座にアラートを送ります。管理者は衝突の危険を事前に検知することができます。だけでなく、重機をリアルタイムで追跡することは、重機の効率性にも関連しています。現場で必要な最適の配置をし、最も速い動線を把握して時間を節約することができます。また、在庫管理や保守面からも有利です。',
      dot : ''
      
    },
    {
      head : '',
      subHead :'結論',
      desc : 'これまでは管理者が直接現場を見て判断し指示を出すことが多かったです。<br/>しかし、今では新しい技術を通じてスマートな建設現場安全管理が可能になりました。<br/>安全を超え、建設プロジェクトの効率性と生産性を高め、より体系的な作業も実現します。',
      dot : ''
      
    },
  ],


  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/jp/enterprise/construction/safety'
      },
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/jp/enterprise/construction/asset'
      }, 
      {
        img : constructionEquipment.thumb,
        data : 'constructionEquipment',
        link : '/jp/enterprise/construction/equipment'
      }
    ]

  }


}