import { TypeMap } from '@core/util';
import styled from '@emotion/styled';
import { CommonText, CommonTextProps } from '../CommonText.style';

type SizeTypes = 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' | 'xxsmall' | 'xxxsmall' | 'xxxxsmall';
interface StyledHeadingProps extends CommonTextProps {
  size: SizeTypes;
}

type HeadingData = {
  size: string,
  weight: string,
  height: string,
}

const sizeMap: TypeMap<SizeTypes, HeadingData> = {
  xxlarge: { size: '40px', weight: 'bold', height: '1.3' },
  xlarge: { size: '36px', weight: 'bold', height: '1.22' },
  large: { size: '32px', weight: 'bold', height: '1.25' },
  medium: { size: '28px', weight: 'bold', height: '1.29' },
  small: { size: '24px', weight: 'bold', height: '1.33' },
  xsmall: { size: '20px', weight: '600', height: '1.4' },
  xxsmall: { size: '18px', weight: '600', height: '1.33' },
  xxxsmall: { size: '16px', weight: '600', height: '1.25' },
  xxxxsmall: { size: '14px', weight: '600', height: '1.14' }
};

export const StyledHeading = styled(CommonText)<StyledHeadingProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  font-weight: ${({ size }) => sizeMap[size].weight};
  line-height: ${({ size }) => sizeMap[size].height};
`;



export const HeadingH2 = styled.h2<StyledHeadingProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  font-weight: ${({ size }) => sizeMap[size].weight};
  line-height: ${({ size }) => sizeMap[size].height};
  color : #000;
`;

export const HeadingH2blue = styled.h2<StyledHeadingProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  font-weight: ${({ size }) => sizeMap[size].weight};
  line-height: ${({ size }) => sizeMap[size].height};
  color : #2A70F0;
`;

export const HeadingH3 = styled.h3<StyledHeadingProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  font-weight: ${({ size }) => sizeMap[size].weight};
  line-height: ${({ size }) => sizeMap[size].height};
  color : #000;
`;


export const HeadingH3white = styled.h3<StyledHeadingProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  font-weight: ${({ size }) => sizeMap[size].weight};
  line-height: ${({ size }) => sizeMap[size].height};
  color : #FFF;
`;



export const HeadingH3blue = styled.h3<StyledHeadingProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  font-weight: ${({ size }) => sizeMap[size].weight};
  line-height: ${({ size }) => sizeMap[size].height};
  color : #2A70F0;
`;
