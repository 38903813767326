import { accessAutoThumb, deadboltThumb, emlockThumb } from '@assets/images/main/access/product';
import { IconOsOff, IconOsOn } from '@assets/images/main/icon';
import { 
accessKey1, accessKey2, accessKey3, accessKey4,
accessOutlook,
IconBox, IconComputer, IconGuarantee,
assetOn, workerOn, workerOff, IconPath, IconLocation, IconAnalyze, IconAlarm, IconExcel, IconSDK, assetOff } from '@assets/images/main/index';
import { RtlsFunctions1D, RtlsFunctions1M, RtlsFunctions1T, RtlsFunctions2D, RtlsFunctions2M, RtlsFunctions2T, RtlsFunctions3D, RtlsFunctions3M, RtlsFunctions3T, RtlsFunctions4D, RtlsFunctions4M, RtlsFunctions4T, RtlsFunctions5D, RtlsFunctions5M, RtlsFunctions5T } from '@assets/images/main/rtls/main/functions';
import { RtlsHardware1D, RtlsHardware1M, RtlsHardware1T, RtlsHardware2D, RtlsHardware2M, RtlsHardware2T, RtlsHardware3D, RtlsHardware3M, RtlsHardware3T, RtlsHardware4D, RtlsHardware4M, RtlsHardware4T } from '@assets/images/main/rtls/main/hardware';
import { assetTag, twinTracker1, twinTrackerBracket } from '@assets/images/main/rtls/product';




export default {
    banner : {
        title : 'Start of Location Tracking',
        desc : 'Start productivity and safety management through the introduction of a location tracking system.',
    
        schemeTitle :'Choose the right location tracking for you.',
        schemeDesc : 'Do you need help deciding? ',
        schemeDesc2 : '<br/>contact us we will have an access expert assist you.',
        phone : '(+82)1522-9928',

        card: [
            {
                title: 'The Only One in Korea',
                desc: 'Applied for location tracking<br/>Manufacturing network devices',
            },
            {
                title: 'Over 5 Billion Records',
                desc: `Asia's largest-scale<br/>real-time location data`,
            },
            {
                title: 'World-Class Performance',
                desc: 'Highest level of location accuracy<br/>using AI algorithms',
            },
        ]
        
    },
    
    mainPage : {
        product : {
            title : 'Learn about the product configuration',
            desc :'Check out the innovative location tracking product configuration.',
            asset :{
                title : 'Bluetooth (BLE) Location Tracking',
                desc :'Attach a BLE tag device to the subject you want to monitor or track as an asset, enabling real-time location tracking.',
            },
            worker :{
                title : 'UWB Precision Tracking',
                desc :'Attach a UWB tag device to the subject you want to monitor or track as an asset, enabling real-time location tracking.',

            }
        }

    },
    
    video : {
        title: 'ORBRO positioning system',
        desc : [
            {text: 'The dashboard function allows you to quickly check the status of the device,<br/>the classification status of the tag terminal by category, and the status of each area at a glance.'},
            {text: 'You can quickly determine the location and status of the target based on the device.<br/>Provides accurate data and an easy interface.'},
            {text: 'Quickly set up workspaces to hazardous areas<br/>and get a quick view of the status of each area.'},
            {text: 'Quickly send alarms for various situations,<br/>including when entering a hazardous area.'},
        ]
       
    },

    functions: {
        title: 'Innovative Experience with Location Tracking System',
        desc: `ORBRO's location tracking system offers users the best experience by providing both web-based administration and a mobile app. Through this, you can experience the highest level of user convenience among existing location tracking systems.`,
    
        line: 2,
        card: [
            {
                size: '3',
                tag: 'Integrated Management Software',
                title: 'Software Based on Indoor Maps',
                label: 'Administrator',
                img: {
                    desktop: RtlsFunctions1D,
                    tablet: RtlsFunctions1T,
                    mobile: RtlsFunctions1M
                },
            },
            {
                size: '1',
                tag: 'Mobile App Provided',
                title: 'Location Tracking via Mobile App',
                label: 'Common Offer',
                img: {
                    desktop: RtlsFunctions2D,
                    tablet: RtlsFunctions2T,
                    mobile: RtlsFunctions2M
                },
            },
            {
                size: '2',
                tag: 'Device History Management',
                title: 'Real-Time Recording of Device Locations by Zone',
                label: 'Administrator',
                img: {
                    desktop: RtlsFunctions3D,
                    tablet: RtlsFunctions3T,
                    mobile: RtlsFunctions3M
                },
            },
            {
                size: '2',
                tag: 'Zone Configuration',
                title: 'Real-Time Device Tracking by Zone',
                label: 'Administrator',
                img: {
                    desktop: RtlsFunctions4D,
                    tablet: RtlsFunctions4T,
                    mobile: RtlsFunctions4M
                },
            },
            {
                size: '2',
                tag: 'Device Management',
                title: 'Convenient Device Management',
                label: 'Administrator',
                img: {
                    desktop: RtlsFunctions5D,
                    tablet: RtlsFunctions5T,
                    mobile: RtlsFunctions5M
                },
            },
        ]
    },
    
    
    hardware: {
        title: 'The Core is Hardware, Everything Innovates Lightly',
        desc: `Our location tracking system's devices are specially crafted with directional antennas and designed considering on-site environments. Applying directional antennas for optimal performance and stability, it operates effectively in various situations.`,
        card: [
            {
                img: {
                    desktop: RtlsHardware1D,
                    tablet: RtlsHardware1T,
                    mobile: RtlsHardware1M
                },
                tag: 'Directional Antenna',
                title: 'Accurate Location Tracking with Directional Antenna',
                desc: 'The TwinTracker (Location Tracking Device) directional antenna can infer the direction of wireless signals.'
            },
            {
                img: {
                    desktop: RtlsHardware2D,
                    tablet: RtlsHardware2T,
                    mobile: RtlsHardware2M
                },
                tag: 'Various Brackets',
                title: 'Ceiling Flush Mount, Ray Installation Possible',
                falldown: [
                    {
                        title: 'Ceiling Flush Mount Bracket',
                        desc: 'Various ceiling installations possible,<br/>including plaster, tex, SMC, Myton, etc.'
                    },
                    {
                        title: 'Rail-type Bracket',
                        desc: 'Same specifications as the rail used for lighting,<br/>making it easy to install anytime if the ceiling has the rail in place.'
                    },
                ]
                
            },
            {
                img: {
                    desktop: RtlsHardware3D,
                    tablet: RtlsHardware3T,
                    mobile: RtlsHardware3M
                },
                tag: 'Innovative Technology',
                title: 'Minimum Installation, Maximum Performance Using TDoA Technology',
                desc: 'TDoA technology is a communication technology that maintains high-performance communication while innovatively reducing battery consumption. It operates in real-time, yet records much higher location precision compared to traditional methods.'
            },
            {
                img: {
                    desktop: RtlsHardware4D,
                    tablet: RtlsHardware4T,
                    mobile: RtlsHardware4M
                },
                tag: 'Various Communication Methods',
                title: 'Wired Communication, Wireless Communication, Both Connectable',
            },
        ]
    },
    

    FAQ: [
        {
          title :'I have no expertise in technology. Can I still use it?',
          description: 'ORBRO system provides a simple but powerful interface for those who have no knowledge of technology. <br/>Above all, we created a system that enables intuitive analysis and use through visualized data.<br/><br/>'
        },
        {
  
          title: 'How sophisticated is the location tracking?',
          description: 'The accuracy of the ORBRORTLS system is very sophisticated.<br/>As it is important to reflect real-time data, it is possible to link data accurately and quickly with little error.<br/><br/>',
        },
        {
  
          title :`Is it only possible to track the location with these products? I'd like to add other features.`,
          description: `ORBRO's technology has a lot of scalability.<br/>Not only location tracking, but also various application technologies such as identifying routes, deviating routes, and setting up no-access zones can be applied.<br/><br/>`,
        }
      ],
      
    bannerData : [
        {
            icon:workerOn,
            iconInv:workerOff,
            image:'',
            iconText: 'UWB Precision<br/>Tracking',
            sub: 'New',
            link : '/en/rtls/uwb'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/en/os'
        },

        
    ],
        
        product : {
            asset : {
                func : 'The product, which has a built-in battery, can easily and quickly control the entrance door via mobile without the need for power construction by attaching it next to the entrance door.',
                key1 : accessKey1,
                key2 : accessKey2,
                key3 : accessKey3,
                key4 : accessKey4,
                outlook : accessOutlook,
            },
        },
        
        purchaseDesc : {
            asset : "Attach a BLE tag device to the subject you want to monitor or track as an asset, enabling real-time location tracking.",
            worker : "Attach a UWB tag device to the subject you want to monitor or track as an asset, enabling real-time location tracking.",
        },
        
        purchase : {
            //Mobile
            asset : {
                assetTracking :{
                    icon : accessKey1,
                    title : 'Asset Location Tracking',
                    serial : 'Asset-Tracking',
                    price : '5,900,000',
                    
                    note : [
                        { text : 'TwinTracker (BLE) 10 units', icon : IconBox},
                        { text : 'Asset tracking tag 30 units', icon : IconBox},
                        { text : 'Server PC 1 unit', icon : IconComputer},
                        { text : '3-year warranty', icon : IconGuarantee},
                    ],
        
                    parts : [
                        {
                            title : 'Asset Tag(BLE)',
                            price : '30,000',
                            img : assetTag,
                        },
                        {
                            title : 'TwinTracker(BLE)',
                            price : '300,000',
                            img : twinTracker1,
                        },
                        {
                            title : 'TwinTracker Bracket',
                            price : '100,000',
                            img : twinTrackerBracket,
                        },
                    ],
                    mark : ""
                },
            },
        
            //Automatic door entry
            worker : {
                workerTracking :{
                    icon : accessKey1,
                    title : 'Worker Location Tracking',
                    serial : 'Worker-Tracking',
                    price : '5,000,000',
                    
                    note : [
                        { text : 'TwinTracker (UWB) 10 units', icon : IconBox},
                        { text : 'Worker tracking tag 10 units', icon : IconBox},
                        { text : 'Server PC 1 unit', icon : IconComputer},
                        { text : '3-year warranty', icon : IconGuarantee},
                    ],
        
                    parts : [
                        {
                            title : 'Tag(UWB)',
                            price : '60,000',
                            img : emlockThumb,
                        },
                        {
                            title : 'TwinTracker(UWB)',
                            price : '300,000',
                            img : deadboltThumb,
                        },
                        {
                            title : 'TwinTracker Bracket',
                            price : '100,000',
                            img : accessAutoThumb,
                        },
                    ],
                    mark : ""
                },
            },
        },
        
        func : {
            title : 'Learn about location tracking features',
            desc : 'Check out the various features of location tracking.',
            
            data : [
            {
                img : IconPath,
                title : 'Path Analysis',
                desc : 'You can analyze the route by time zone by accumulating data in real time.'
            },
            {
                img : IconLocation,
                title : 'Real-time Location Tracking',
                desc : `ORBRO's location tracking system tracks locations in real time. You can monitor the site lively with the truly analyzed function.`
            },
            {
                img : IconAnalyze,
                title : 'Analytical Tools',
                desc : 'Collect numerous location data to get statistics and insights.'
            },
            {
                img : IconAlarm,
                title : 'Notification Settings',
                desc : 'You can set up a danger zone in an area where people should not enter and receive notifications.'
            },
            {
                img : IconExcel,
                title : 'Excel Download',
                desc : 'You can refine the necessary data and download it as an Excel data.'
            },
            {
                img : IconSDK,
                title : 'SDK Provision',
                desc : `Orbro's location tracking system provides an SDK to support development according to customer needs.`
            },
            ]
        }
        


}