import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: `Through tags on forklifts and within the workplace, the driver's location is tracked in real-time, triggering warnings upon entering hazardous zones to prevent accidents beforehand. Additionally, this solution monitors driver activity to enhance operational efficiency and reinforce compliance with safety regulations.`,
  keywords: 'RTLS, forklift, worker safety, labor safety, indoor location tracking, location tracking, asset tracking, worker safety, logistics tracking',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Improving Forklift Operator Safety with RTLS Solutions :: Orbro Blog',
  ogUrl: 'https://orbro.io/en/blog/forklift',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/APg5EOYIuJxCZK1FJlYG0LyjYKrQCmcid1qnH-Lohcv1uYTGKb_GPVqY_SN8WE9jRoShLP0EX6b_VBXeTRzsO7mTk-z8QCbptyDW5Xe2QJeYxoTzMEDRgwOv8DvtKIbf4NcZZT_Zlplcg3Trlui87z3ElC0nCnI7pf1dZarBcSrb0LNt-ppIe2rFLvzS2ENcB9eWuw4z305W5GaBK0I-WjR0cY8MqBSBwIHoTY9di2xcTpbNtP65TVZDYzTDl7CQF157USeD7Tv9QhJl0LkphloREDPEYsqCkBmLCcGYAQju-OGqng3VUbIdyWaVOBofa0mNlwvO6D_amlwyTTiePAAysdHcsybGY8vPLjCQiidDTnfLYbE1UiI5Jl66uSVD0I6N3f9v7vospII2i5_YUcBaEEs4Uzb_F7XB5DIE3c7JrxIA_e6-nrn_YtHrMgid-5asfdU0L-Mu9fphtnVDL8fqDR7OZjb6-BISal_s9i8ORnx_iHMIcTCc9vXcP1Rq7C98YnMeMns2CqYZDaRVt-Pfmd_9ewR9v1hu0_YJG2TX-Je9BsZ0SXOx6fKHVjmWAppLp1kksJJxNxiIBWnSksv1hKhrn30d_QQmvYLDj-Jo88sbktqoX6Z-2TLIv3p2MSpCcGP7EwCnHLVPgc5ERRnv7zJkVHqh-07x-w5dq1R8qbdi7uRnG6yU3wAYTgilNH-USBwM2tL4FnMgAcZmQbSvFN61xcK3W1hGqOwfChKQ9aszr3gQdhYuf0zA-rBWbbuzB3mevQ5-MZPjI9p2tsS17nuFTROO8LR-WcMHO0bUmUfPbstaC4lgbyvZNrT2ZAMp9-4vQb8UzGs1SZTBzJFW8aqKfoKAvRIs9jMfZ0W7RDdjqTy6y4CYyQMyJXerwdeo8ODGTqvNux6rZeWraDDHjEhIJwHa68ntfE17VTeU9IhCi5Ax1xG5JKfDi89fGmyo0UGkByOqKXAUzQ0kb855-2_iUgo8bPO1rR4bdseYSRmP0A7VvhsrkW_cAe7shFm1dq5npgpxdlyqp7YSlii5O5nkSKPUTfBojpAN6nQQ31UtAIPu509dljo1GS1WzSIPWyw2LReT9-Cw6wR53NlceY4lcuqoVYfBHWxo7pz2gLr-0VfRlnJsTOa7u5skuZ6gxqycQuGkh1eWRDVwU9kPjERqK5HvRXQg2fmZgnb3ZLK8SAOpADOFjbSFGBizZAyNK5dP-73XpmaMWv60i64qqNS9zEEUtOzGyEhaAsBddF7caUjqcMq48beLtFcWzVl6nKMtZWS9v4GbJ79FqktHsqD2VNVKl5psGiqmamA97oR8Foggtisgpo_tXzUChfzvEPo4iLwNIS4e7cArRNt5MlE2L9My_ch8hwqmwWOjlLat1WgDZefk1f8TEzObNLoqW_fl0vZ2224rKOy8uRgK-smLBAJRvqxvrLdc8jqrYtsBmQbRW_5F9O38XaXXU86yJ6u4un2v2bZQs9lbD1U-Nj8419GHFhfQEyczZxSV2__aPX540BJW1T-CKQYO2-3UWpAuGLZnQuABSy6v5TAT3Zo6qgQgjs1CCnkpXLUnQZ_FCA=w2059-h1216',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Improving Forklift Operator Safety<br/>with RTLS Solutions',
  date: '2023-05-17',
  tag1: '#RTLS',
  tag2: '#workersafety',
  tag3: '#assetmanagement',
  tag4: '#locationtracking',

  text: [
    {
      head: 'Regular Forklift Inspection',
      subHead: '',
      desc: 'Forklifts need to be inspected daily to ensure their operational condition is good. The inspection checklist includes parking brakes, lights, hydraulic oil levels, etc. Any issues should be immediately repaired. It is recommended to have a qualified technician conduct more thorough inspections at least once a month. <br/>However, human errors can occur, and as the number of equipment to be inspected increases, time consumption and errors also increase proportionally. <br/>Using RTLS technology, sensors attached to forklifts or cargo equipment can provide real-time inspections of their conditions. Efficient asset management directly contributes to safety and cost savings.',
      dot: ''
    },
    {
      head: 'Proximity Warning System',
      subHead: '',
      desc: 'Forklifts are large and can cause severe damage if they collide with someone or something. Therefore, operators need to be constantly aware of their surroundings and cautious about people and obstacles. If they encounter someone or an object on their path, they should slow down and proceed with caution. This is particularly crucial in busy warehouses with high activity. <br/>In such cases, using signage to visually indicate hazardous areas is still a good practice. However, sometimes people, including workers, may overlook or miss the signs. In these situations, attaching a proximity warning system is also a great solution. It can detect the presence of people or objects near the forklift and provide real-time warnings. This not only benefits forklift operators but also helps ensure compliance with safety regulations by providing real-time monitoring to managers.',
      dot: ''
    },
    {
      head: 'Operating at the Right Speed',
      subHead: '',
      desc: 'Driving at excessive speed increases the risk of accidents, while driving too slowly directly impacts productivity. It is essential to operate forklifts safely and efficiently. However, when focusing on tasks, operators may sometimes fail to comply with speed regulations. In such cases, an RTLS system can automatically determine if the speed is being complied with and provide warnings.',
      dot: ''
    },
    {
      head: 'Loading Risks',
      subHead: '',
      desc: 'Operators should always be aware of how much weight they are carrying. If the load is too heavy, the forklift can tip over. Operators should transport loads without endangering themselves or others. Similarly, forklifts should never be used to transport hazardous materials. These materials can cause leaks, spills, or fires, posing serious safety risks to nearby workers. <br/>An RTLS system can monitor such situations and provide data for prediction and contingency planning.',
      dot: ''
    },
    {
      head: 'Blind Spot Risks',
      subHead: '',
      desc: `The blind spot of a forklift is typically the area directly behind the machine. If there are people or objects in the path of the forklift, unforeseen injuries can occur, posing a significant safety risk. <br/>Several factors contribute to blind spot risks. One is that forklifts often carry large amounts of cargo, obstructing the operator's visibility. Another is that forklifts frequently operate in areas with poor lighting, making it much more challenging to see behind. Finally, frequent stopping and starting of forklifts can also be a cause of blind spot accidents. <br/><br/>While humans have limitations, RTLS technology can smartly predict such safety accidents.`,
      dot: ''
    },
    {
      head: 'Asset Management',
      subHead: '',
      desc: 'RTLS solutions are also effective in equipment management! It allows monitoring the location of required equipment at once and leads to optimal equipment placement, enhancing productivity. Furthermore, it enables tracking of stolen or lost equipment, reducing incidents such as work stoppages. RTLS technology ensures efficient asset management and safety for forklift operators and managers alike.',
      dot: ''
    }
  ],


  link : {
    page : 'rtls',
    numb : '2',
    product : [
      {
        text : productPurchase.purchase.worker.workerTracking.title,
        title : productPurchase.purchase.worker.workerTracking.title,
        serial : productPurchase.purchase.worker.workerTracking.serial,
        desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
        img : productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution : [
      {
        img : constructionAsset.thumb,
        data : 'constructionAsset',
        link : '/en/enterprise/construction/asset'
      }, 
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/en/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/en/enterprise/logistics/asset'
      }
    ]


  }
  
}
