import { TechTitleBannerDesktop, TechTitleBannerMobile } from "@assets/images/technology/common";
import { Vertical } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";

export const StyledTitleBanner = styled(Vertical)`
  width: 100%;
  height: 360px;
  padding: 48px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.CONTENT_PRIMARY};
  margin-top: 64px;
  background-image: url(${TechTitleBannerDesktop});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    background-image: url(${TechTitleBannerDesktop});
    padding: 32px;
    margin-top: 48px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 24px;
    margin-top: 32px;
    background-image: url(${TechTitleBannerMobile});
  }
`;


export const StyledTitleBannerDescription = styled(StyledParagraph)`
  color: ${({ theme }) => theme.CONTENT_DISABLED};
  margin-top: auto;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    & br {
      display: none;
    }
  }
`;



export const TechBannerBtn = styled.a`
  padding : 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius : 12px;
  width : fit-content;
  cursor : pointer;

  background-color : #FFFFFF;
`;