import { BACKGROUND_DARK_SECONDARY, CONTENT_DARK_PRIMARY, CONTENT_DARK_SECONDARY } from "@assets/styled/color.style";
import { SCREEN_BREAKPOINT, StyledGridColumn } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading } from "@components/Text";
import styled from "@emotion/styled";

export const StyledOurCultureCard = styled(StyledGridColumn)<{img :string}>`
  height: 540px;
  padding: 40px;
  border-radius: 16px;
  background-size: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: ${BACKGROUND_DARK_SECONDARY};

  ${({img})=> img&& `background-image : url(${img});`}

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    height: 440px;
    padding: 40px 30px;
    background-size: auto 100%;
  }
`;

export const StyledOurCultureCardTitle = styled(StyledDisplay)`
  color: ${CONTENT_DARK_PRIMARY};
`;

export const StyledOurCultureCardDescription = styled(StyledHeading)`
  margin-top: 8px;
  color: #9f9f9f;
`;