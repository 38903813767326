import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/medicalWaste/video/rtlsVideo.mp4";

export default {

    
  helmet : '| BLE とは？ | Bluetooth Low Energy',
  desc : 'BLE（Bluetooth Low Energy）は、主に低電力デバイス間のデータ通信に使用されるワイヤレス通信技術です。低電力消費と簡単な実装が特徴のBLEは、建設、製造、医療分野などで広く使用されています。',
  keywords : 'BLE、Bluetooth Low Energy、低電力消費、Bluetooth、ビーコン、Bluetoothビーコン、rtls orbro、オブロ、コンテック',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'BLE とは？ | Bluetooth Low Energy :: オブロ ブログ',
  ogUrl : 'https://orbro.io/jp/blog/ble',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AGXqzDnfNjLOQqRH35cwkWdJ-bHoz620gNvKlc65H16bDwbDmM9mn2WV9CZBz5ngF-qPG8Kaio7OwcThigmqtAyLII5-_7o_wuEr9IGXgBBhy_HG2TtVg8JvI3jjx47Vjw5bkjNMP9pqYdZG-uQE7MSvCn-fUZW5RKVlHT6Wej9ZUjr8AuM0noE2LJXIBs8o7Ik2bNeGQcps-3AfWrgOGhlIFNJuCK2uSB1AW_cjwmHTkEIusWJalOUeSVOcaarM8cTR1xUNWmao0lb1KbxQ8qqbL4_XhFZkkUct1UG8RrIN6Psi-AsTpgkFOrBdDPlqH541BEN9bwdOETx3Kgc9iRes1JUSRoP7nrOyakxoo3aqXTFdAoosGnVLKOiER0DPiU3G07ZA9bJlehSeDDCzu8kynXgnTFz91gKfMi3avcdxHqpzaiBhQCXLBUaUM95BEkE-EXHcK8Nl2WmqiTNP7CThyNvqwZdyy1JVK3ZAc5waSTB186EjteUi4k9pd65QfukNba5px9D4ul0TIzbpY_aBS2bxSfdqlASAVpqQacahwDH0yFO0S1gPaNEMW4Rcqk4iQBol7VLpCY1Snlty4mEaUfgoKOxdkkZU3nUI7YT5EXM3uWYUNTI7a2y9qeT4hrtloASb9mN6DcMg_aqnP7emz1CZ-imi3vFJic3ohSrWlQulA-3p__vjLjaVuslbfw63OjFvQAtmnYu1VRZInPMAtDL_vflRts6E5KekPnuL4Ca5Kze4Wh9CVzuPlcdqh4jXIkUI4GEE6n9fX8ZU3eWJ3vJXWl5AsFXSA5fbMZ399h7V9B5DzuThH9vBR99mQqMnDcu-wMSq8GtgfrQvtysw0KoPKwz2uo8D1Wg5qhsJ9KCDj_gm3BY67-wftHaGOtlwve9hBoHyAlPwsV0BA4t6_tpuqzg3jfkv8hlWyWZ7x9KUguu0Im_zMOGYfPw_YdhWZoe_erVB6bGz7UHbyN2Vo-aMOK4eAjPDtOgP0qR90Sg-KjmiPwCEGL1SFTjzDht-pBQVVLCOMRzOuRArCvUZai0a4G2-JBnIxVPxNfCsLrLu33X6-UgxzpkrQfBr0YcAINxqDA_IfOOJc3C9PKFPyhTgp7cP_KVk2QsY25pHgMDTc49Ui4nwk2FxSdvlshqEZHbPKbtQHQ_JK-y3veLdrgV9ADCimrqBeHANlcWKuovdxhGvrr2mlRG-t2pD_62qO-Gh0t93pn9qocK1oVmPweHfGPMC2E1Z_GtmeOr33-mwXkKK-R7LUT7aLOO359xDj0C__aXlIFScOt-8RVul4IJ8HQoENLTkwNTs-jt-Z_LMbO73LvC5YlngDpmg1n9_NLCcomYIxw6PBKksDaqWJM732sqJiEsvmm9NYyVnBneRLbCAIKbq6xsH1y98rbeCc8tlWYSBfUNFx-CvWG-L_8IrgL0bhnMKFzN4bHqJCVezRfIHgDdfdOwOSN3xxFZmg4KSWjdPA3VtyO57X_zgNjlVtEE6PDawKLNXUiaAd6nyZtpP-FLbph9jZ7-Ws8g_A_gHtSv2NQOq3CQ73x9_YgopHxq4rJ3W=w2189-h1213',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'BLE とは？ | Bluetooth Low Energy',
  date : '2024-01-04',
  tag1 : '#BLE',
  tag2 : '#BluetoothLowEnergy',
  tag3 : '#Bluetooth',
  tag4 : '#ビーコン',
    
  text : [
    { 
      head : 'BLE とは？',
      subHead : '',
      desc :'BLE（Bluetooth Low Energy）は、主に低電力デバイス間のデータ通信に使用されるワイヤレス通信技術です。低電力消費と簡単な実装が特徴のBLEは、建設、製造、医療分野などで広く使用されています。このブログでは、BLEの基本的な概念、動作原理、主要な特徴、およびさまざまな応用事例について詳しく説明します。',
      dot : ''
    },
    {
      videoDesc : '(病院廃棄物管理ソリューション、クリックして移動)',
      video: video,
      link : '/jp/enterprise/medical/waste',
      head : 'BLEの基本概念',
      subHead :'',
      desc : 'BLE（Bluetooth Low Energy）は、主にスマートフォン、ウェアラブルデバイス、センサー、ヘルスケアデバイスなどの低電力デバイス間の通信のために設計されています。Bluetoothと同様に2.4GHz周波数帯域を使用しますが、電力消費を最小限に抑えて長寿命のバッテリーをサポートします。',
      dot : ''
    },
    {
      head : 'BLEの動作原理',
      subHead :'',
      desc : 'BLEは基本的に、定期的な広告（Advertising）と接続（Connection）の2つのモードで動作します。広告モードでは、デバイスは周囲に存在を知らせ、接続モードでは実際のデータ交換が行われます。これにより、低電力状態でも効率的な通信が可能になります。',
      dot : ''
    },
    {
      head : 'BLEの主な特徴',
      subHead :'低電力消費',
      desc : 'BLEは主にバッテリーで動作するデバイスに適して設計されています。広告モードでは低電力で動作し、接続時にも最適な電力消費を維持します。',
      dot : ''
    },
    {
      head : '',
      subHead :'さまざまな応用分野',
      desc : 'BLEはスマートホーム、スマートヘルスケア、スマートシティなどで活用されます。センサーネットワーキング、位置情報サービス、屋内位置追跡など、さまざまな応用が可能です。',
      dot : ''
    },
    {
      head : 'BLEの応用事例：RTLS技術',
      subHead :'リアルタイム位置追跡システム（RTLS）',
      desc : 'BLEはRTLS技術で重要な役割を果たしています。RTLSは物体や対象のリアルタイムの位置を追跡する技術で、さまざまな産業分野で利用されています。',
      dot : ''
    },
    {
      head : '',
      subHead :'物流および倉庫管理',
      desc : '物流および倉庫では、BLEタグを使用して商品や資産のリアルタイムの位置を追跡できます。これにより在庫管理、物流調達、および倉庫内の移動経路を最適化し、効率を向上させることができます。',
      dot : ''
    },
    {
      head : '',
      subHead :'屋内位置情報サービス（IPS）',
      desc : 'BLEはIPSで重要な役割を果たしています。ショッピングモール、空港、博物館などの公共の場で、スマートフォンアプリを介して訪問者に室内地図、特別な割引情報、または展示物の情報を提供するために使用されます。',
      dot : ''
    },
    {
      head : '',
      subHead :'製造および生産プロセス管理',
      desc : '製造業では、BLEを使用して生産プロセス内の機器、部品、または製品のリアルタイムの位置をモニタリングできます。これによりプロセスの効率向上と障害処理時間の最小化が可能です。',
      dot : ''
    },
    {
      head : '',
      subHead :'ヘルスケアシステム',
      desc : '病院や医療施設では、BLEを使用して患者の位置を追跡し、医療機器の状態をモニタリングします。これにより緊急時の迅速な対応と患者のモニタリングが支援されます。',
      dot : ''
    },
    {
      head : '展望',
      subHead :'',
      desc : 'BLEは引き続き進化し、より多くの産業および日常生活の分野に適用されると予想されています。より優れたセキュリティ機能、より高い伝送速度、およびより広範囲の接続が、将来のBLEの発展の方向の一部となるでしょう。これらの進展は、より効率的で便利な無線通信環境を築くことが期待されています。',
      dot : ''
    },
  ],

  link : {
    page : 'rtls',
    numb : '1',
    product : [
        {
          text : productPurchase.purchase.worker.workerTracking.title,
          title : productPurchase.purchase.worker.workerTracking.title,
          serial : productPurchase.purchase.worker.workerTracking.serial,
          desc : 'UWB端末を作業者や機器に取り付けて、リアルタイムの位置追跡を支援する製品です。',
          img : productImg.product.workerTracking.key1
        },
      {

      }

    ],
    solution : [
      {
        img : constructionSafety.thumb,
        data : 'constructionSafety',
        link : '/jp/enterprise/construction/safety'
      },
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/jp/enterprise/logistics/asset'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/jp/enterprise/manufacturing/asset'
      }
    ]

  }


}
