import { constructionSafety, logisticsAsset, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

import video from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

export default {

  helmet: '| What is Digital Transformation (DX)?',
  desc: 'Digital transformation refers to the process of using digital technologies to digitize the way daily life and business operations work. It enables more efficient and productive methods, allowing businesses to adapt to market changes and create new value. For example, it allows real-time tracking and management of workers and inventory.',
  keywords: 'Digital transformation, digital twin, digital innovation, smart city, business intelligence, BI, innovation, smart solution, fourth industrial revolution, business, dx, orbro, contech, rtls, orbro, location tracking',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'What is Digital Transformation (DX)? :: Orbro Blog',
  ogUrl: 'https://orbro.io/en/blog/dx',
  ogKey: '',
  ogImg: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'What is Digital Transformation (DX)?',
  date: '2023-11-01',
  tag1: '#DigitalTransformation',
  tag2: '#DigitalTwin',
  tag3: '#SmartCity',
  tag4: '#ORBRO',

  text: [
    {
      head: '',
      subHead: '',
      desc: 'Digital transformation refers to the process of replacing or improving traditional processes and business models with digital technology. It involves upgrading traditional ways of doing business to modern and efficient methods and finding future opportunities through the use of digital technology. Digital transformation affects a wide range of industries and sectors, from businesses to governments, healthcare, education, and various other fields, and its importance continues to grow.<br/><br/>The key objectives of digital transformation are as follows:<br/><br/><br/><strong>Enhancing Efficiency: </strong>Increasing productivity and reducing costs through better processes and working methods.<br/><br/><strong>Improving Customer Experience: </strong>Providing better services and experiences to customers through digital technology.<br/><br/><strong>Promoting Innovation: </strong>Developing new ideas and business models to gain competitiveness in the market.<br/><br/><strong>Data Utilization: </strong>Collecting, analyzing, and utilizing data to aid in decision-making.',
      dot: ''
    },
    {
      head: 'RTLS Technology and Digital Twin',
      subHead: '',
      desc: 'Real-Time Location Tracking System (RTLS) technology is one of the core components of digital transformation, accurately tracking the location of objects, resources, or individuals in physical spaces. RTLS collects and provides real-time location information through sensors, wireless communication, data analysis, and visualization technology. Digital twin is closely related to RTLS technology. It is a digital replica of real-world physical objects, allowing real-time monitoring and simulation of the physical object\'s state, location, and interactions. RTLS technology provides essential data to maintain and update the digital twin.',
      dot: ''
    },
    {
      video: video,
      link: "https://orbro.io/enterprise/logistics/asset",
      head: 'Real-Life Examples',
      subHead: '',
      desc: 'When integrated into digital twins, it allows the simulation of physical conditions in industrial environments. In manufacturing, it monitors and optimizes production line movements, while in healthcare, it tracks patient locations and manages medical equipment. In smart city projects, it enhances road traffic and disaster management, and in logistics and distribution, it optimizes inventory management and order processing. For example, in scenarios where autonomous vehicles move and place goods, RTLS technology tracks the positions of these vehicles, builds predictive models through digital twins to avoid collisions, and optimize routes. Additionally, it optimizes inventory levels and prevents inventory shortages or excess, and using these data-based insights, it improves the efficiency of logistics processes and enhances customer service quality.',
      dot: ''
    },
    {
      head: 'Conclusion',
      subHead: '',
      desc: 'Digital transformation (DX) has emerged as a crucial factor for the survival and growth of businesses and organizations in modern society. RTLS technology and digital twin are key technologies driving this transformation, converting the physical world into digital to prepare for the future. Through digital transformation, we can enhance efficiency and prepare for future challenges.',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: 'This product attaches UWB terminals to workers or equipment to assist in real-time location tracking.',
        img: productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution: [
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      },
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/en/enterprise/manufacturing/asset'
      }
    ]

  }

}
