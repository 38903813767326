import { LabelColor, ParagraphColor, Spacing } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { BannerBtn, BannerImageSection, BannerLayout, BannerSection, BannerSpacing, BannerVideoSection, ImageInner, VideoBox } from "./Banner.style";
import { BigBannerImg } from "@assets/images/main/home";
import { DisplayH1fff } from "@components/Text/HeadingTagDisplay.style";
import { VideoPlay } from "@pages/Enterprise/Solution/components/Video/Video.style";
import video from '@assets/images/main/home/banner/bannerVideo.mp4';

const Banner = ({ onScrollButton }) => {

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]); 
  
  return (
    <>
    <BannerLayout>
      <BannerSection>
        <ImageInner scrollPosition={scrollPosition}>
          <DivAllCenter>
            <Spacing pixel="64" />
            <BannerSpacing/>
            <Spacing pixel="32" />
            <DisplayH1fff size={"medium"}>
              <Trans i18nKey={`home.titleBanner.title`} />
            </DisplayH1fff>
            <Spacing pixel="8" />
            <ParagraphColor color="#FFF" size="medium">
              <p>
                <Trans i18nKey={`home.titleBanner.description`} />
              </p>
            </ParagraphColor>
            <Spacing pixel="16" />
            <BannerBtn onClick={() => onScrollButton()}>
              <LabelColor color={"#FFF"} size={"large"}>
                <Trans i18nKey={`home.showMore`} />
              </LabelColor>
            </BannerBtn>
          </DivAllCenter>
        </ImageInner>
        <BannerVideoSection scrollPosition={scrollPosition}>
          <VideoBox>
            <VideoPlay
              autoPlay
              loop muted
              playsInline>
              <source src={video} type="video/mp4" />
            </VideoPlay>
          </VideoBox>
        </BannerVideoSection>
        <BannerImageSection img={BigBannerImg} />
      </BannerSection>
      </BannerLayout>
    </>
  )
}

export default Banner;
