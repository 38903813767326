import { accessAuto1, accessAutoThumb, accesscontrollerThumb, deadboltThumb, emlockThumb } from '@assets/images/main/access/product';
import {
    accessKey1,
    accessOutlook,
    IconBattery, IconBluetooth,
    IconBox, IconComputer,
    IconCard, IconFacetime, IconGuarantee,
    IconQR, IconTemp, IconUsbc,
    IconWarn, IconWaterproof, IconCamera, IconZoom, IconTopology
} from '@assets/images/main/index';
import { bell1, esl1, idCard1, mCradle1, mCradleThumb, mTagThumb, switch1 } from '@assets/images/main/iot/product';
import { assetTag, assetTracking2, tagUwb1, twinTrackerBracket } from '@assets/images/main/rtls/product';
import { IconAtomGray, IconChainLinkGray, IconEyeline, IconInfo, IconMail, IconMonitorLineGray, IconPhone, IconPower, IconRoom, IconRulerGray, IconSignal, IconThumbUp, IconToolsGray, IconTvocGray, IconWifi } from '@assets/images/icon';
import { accessKeyThumbnail, accessproThumbnail, deadboltThumbnail, emlockThumbnail, eslThumbnail, orbroServerThumbnail, safetyBellThumbnail, switchThumbnail, twinTrackerThumbnail } from '@assets/images/main/thumbnail';
import { ipThumb, ptzThumb } from '@assets/images/main/camera';
import { loraGateway1, loraSensor1 } from '@assets/images/main/sensor';
import { assetTag1 } from "@assets/images/main/beacon/product";




export default {



    purchaseDesc: {
        mobile: "内置电池的产品,无需电源工程,可贴在出入门旁边,方便快捷地用手机控制出入门。",
        auto: "这是一款专门用于出入自动门的产品通过更换现有的自动门传感器后安装应用程序，实现非接触式出入。",
        face: "Access Pro是具有控制门厅门禁、呼叫功能的产品。用该产品呼叫用户，用户就可以通过手机应用程序进行视频通话，还可以远程控制门禁。并且可以使用NFC卡和密码解锁，是代替现有门厅门禁的最佳产品。",
        accessory: "",

        asset: "将BLE标签设备附着到您要监视或追踪的对象，实现实时位置追踪。",
        worker: '将UWB标签设备附着到您要监视或追踪的对象，实现实时位置追踪。',

        outdoor: '通过PTZ户外摄像机追踪停车场车辆的位置。',
        indoor: '通过摄像机分析室内人员的移动路径。',
        monitoring: "Safety Bell是一款可以在任何具有Wi-Fi的地方轻松安装和立即使用的产品。",

        energy: "Smart Switch是可以轻松将普通开关更换为智能开关的产品。",
        display: "ESL是基于信标系统的显示设备。 因为使用了E-ink Display，所以可以以非常低的电力使用显示器。",
        cradle: "可以无线管理智能手机,不会给电缆锁定装置带来不便。",
        idCard: "员工证是以挂绳形式制作，可在工业和服务领域都能高度兼容地使用。可以与欧博罗 RTLS 系统以及蓝牙系统进行联动。",

        loraGateway: 'ALESS_GW设备是一种为工业环境优化的智能无线通信集中器，可作为智能无线网关，将传感器数据传输到网络服务器。',
        loraSensor: '此产品与现场所需的各种传感器（如温度传感器、压力传感器、泄漏传感器、湿度传感器等）进行接口。它通过无线通信测量传感器数据并进行传输。',
        airQuality: '高精度空气质量监测仪内置微粒物、二氧化碳、挥发性有机化合物、温度和湿度传感器。它在显示屏上实时显示每个传感器值的测量，并可与服务器系统集成以进行数据监测。',
        coMeasure: '除一氧化碳外，它还内置了测量微粒物（PM1.0/PM2.5/PM4.0/PM10）、温度和湿度的传感器，实时测量数据。',

        beaconIndustry: '支持防水和防尘，经过工业验证，可使用长达3年。',
        beaconTag: '可以附在任何地方的小型标签信标终端。',
        assetTag: "用于资产管理的超小型蓝牙标签终端",


    },



    purchase: {

        mobile: {
            accesscontroller: {
                icon: accessKey1,
                title: 'Access Controller',
                serial: 'KB-BK02',
                price: '50,000',

                note: [
                    { text: '支持通过手机实现非接触出入功能', icon: IconInfo },
                    { text: '最新版本的蓝牙技术', icon: IconBluetooth },
                    { text: '精确的10米范围内出入管理', icon: IconWarn },
                    { text: `IP65级的防水防尘（-10~40°C）`, icon: IconWaterproof },
                    { text: "最长3年电池使用时间", icon: IconBattery },
                    { text: '1年质保期', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'EM Lock',
                        price: '150,000',
                        img: emlockThumb,
                    },
                    {
                        title: '磁性门锁',
                        price: '150,000',
                        img: deadboltThumb,
                    },

                ],
                options: [],
                mark: "2",


                mainProduct: [
                    {
                        img: accesscontrollerThumb,
                        title: 'Access Controller',
                        price: '50,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },



        //자동문 출입
        auto: {
            accessauto: {
                icon: accessKey1,
                title: 'Access Auto',
                serial: 'OAC-AUBT5091',
                price: '150,000',

                note: [
                    { text: '支持通过手机实现非接触出入功能', icon: IconInfo },
                    { text: '最新版本的蓝牙技术', icon: IconBluetooth },
                    { text: '精确的2米范围内出入管理', icon: IconWarn },
                    { text: '0 ~ 50°C工作温度', icon: IconTemp },
                    { text: '1年质保期', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'EM Lock',
                        price: '150,000',
                        img: emlockThumb,
                    },
                    {
                        title: '磁性门锁',
                        price: '150,000',
                        img: deadboltThumb,
                    },
                    {
                        title: '室内自动门传感器',
                        price: '99,000',
                        img: accessAutoThumb,
                    }

                ],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: accessAutoThumb,
                        title: 'Access Auto',
                        price: '150,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
            apart: {
                icon: accessOutlook,
                title: '公寓套餐',
                serial: 'Access Auto 20 + Server 1',
                price: '5,000,000',

                note: [
                    { text: '支持通过手机实现非接触出入功能', icon: IconInfo },
                    { text: '包含ORBRO OS的ORBRO服务器', icon: IconComputer },
                    { text: '1年质保期', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'EM Lock',
                        price: '150,000',
                        img: emlockThumb,
                    },
                    {
                        title: '磁性门锁',
                        price: '150,000',
                        img: deadboltThumb,
                    },
                    {
                        title: '室内自动门传感器',
                        price: '99,000',
                        img: accessAutoThumb,
                    }

                ],

                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '2,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: accessAutoThumb,
                        title: 'Access Auto',
                        price: '150,000',
                        min: 20,
                        max: 999,
                    }
                ]

            },
            cloud: {
                icon: accessOutlook,
                title: 'Access Auto (Cloud)',
                serial: 'OAC-AUBT5091',
                price: '29,000',
                priceSub: '/月',

                note: [
                    { text: '支持通过手机实现非接触出入功能', icon: IconInfo },
                    { text: '最新版本的蓝牙技术', icon: IconBluetooth },
                    { text: '精确的2米范围内出入管理', icon: IconWarn },
                    { text: '0 ~ 50°C工作温度', icon: IconTemp },
                    { text: '1年质保期', icon: IconGuarantee },
                ],
                parts: [],
                options: [],
                mark: "1",

                mainProduct: [
                    {
                        img: accessAutoThumb,
                        title: 'Access Auto (Cloud)',
                        price: '29,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },

        //얼굴 인식 출입
        face: {
            accesspro: {
                title: 'Access Pro',
                serial: 'KSB-SL02',
                price: '1,200,000',

                note: [
                    { text: '支持通过手机实现非接触出入和视频通话功能', icon: IconInfo },
                    { text: '最新版本的蓝牙技术', icon: IconBluetooth },
                    { text: 'NFC卡出入', icon: IconCard },
                    { text: '访客视频通话', icon: IconFacetime },
                    { text: 'QR码出入', icon: IconQR },
                    { text: '0 ~ 40°C工作温度', icon: IconTemp },
                    { text: '1年质保期', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'EM Lock',
                        price: '150,000',
                        img: emlockThumb,
                    },
                    {
                        title: '磁性门锁',
                        price: '150,000',
                        img: deadboltThumb,
                    },
                    {
                        title: '室内自动门传感器',
                        price: '99,000',
                        img: accessAutoThumb,
                    },
                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: accessproThumbnail,
                        title: 'Access Pro',
                        price: '1,200,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },


        },


        //출입 액세서리
        accessory: {
            accesskey: {
                title: 'Access Key',
                serial: 'KSB-AKC01',
                price: '30,000',

                note: [
                    { text: '支持使用信标密钥进行非接触式出入功能', icon: IconInfo },
                    { text: '最新版本的蓝牙技术', icon: IconBluetooth },
                    { text: 'USB-C充电', icon: IconUsbc },
                    { text: '1年质保期', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: " ",

                mainProduct: [
                    {
                        img: accessKeyThumbnail,
                        title: 'Access Key',
                        price: '30,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

            emlock: {
                title: 'EM Lock',
                serial: 'EM-Lock',
                price: '150,000',

                note: [
                    { text: '电磁锁是一种主要用于防火门或木门等单向出入的电动锁，通过磁力将出入门固定的装置。', icon: '' },
                    { text: '1年质保期', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: " ",

                mainProduct: [
                    {
                        img: emlockThumbnail,
                        title: 'EM Lock',
                        price: '150,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

            deadbolt: {
                title: '磁性门锁',
                serial: 'Deadbolt Lock',
                price: '150,000',
                note: [
                    { text: '磁性门锁是一种在关闭门时自动锁定的自动锁定磁性门锁。可用于公寓，办公室等入口，大门等，可实现自动锁定。', icon: '' },
                    { text: '1年质保期', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: " ",

                mainProduct: [
                    {
                        img: deadboltThumbnail,
                        title: '磁性门锁',
                        price: '150,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },


            autoDoorSensor: {
                title: '室内自动门传感器',
                serial: 'Automatic Door Sensor',
                price: '99,000',
                note: [
                    { text: '室内自动门传感器是一种用于控制类似于自动门的设备，可以检测人体或物体的移动，用于自动门的开闭。', icon: '' },
                    { text: '1年质保期', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: " ",

                mainProduct: [
                    {
                        img: accessAuto1,
                        title: '室内自动门传感器',
                        price: '99,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },


        },


        //자산 추적
        asset: {
            assetTracking: {
                icon: accessKey1,
                title: '资产定位套餐',
                serial: 'TwinTracker 10 + Server 1',
                price: '9,500,000',

                note: [
                    { text: '通过蓝牙定位器追踪资产位置', icon: IconInfo },
                    { text: '每套包，最大安装面积为1,000平方米', icon: IconBox },
                    { text: 'ORBRO服务器配备ORBRO OS', icon: IconComputer },
                    { text: '1年质保期', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: 'Asset Tag(BLE)',
                        price: '30,000',
                        img: assetTracking2,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },

                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTracker',
                        price: '450,000',
                        min: 10,
                        max: 30,
                    }
                ]

            },
            twinTracker: {
                icon: twinTrackerThumbnail,
                title: "TwinTracker",
                serial: 'OTR-TTBT0001',
                price: '450,000',
                note: [
                    { text: '使用蓝牙标签终端进行位置追踪', icon: IconInfo },
                    { text: '以太网和Wi-Fi网络连接', icon: IconTopology },
                    { text: 'PoE和USB Type-C电源供应', icon: IconPower },
                    { text: '最高10米精度', icon: IconRulerGray },
                    { text: '1年保修期', icon: IconGuarantee },

                ],
                parts: [
                    {
                        title: "Asset Tag (BLE)",
                        price: "30,000",
                        img: assetTracking2,
                    },
                    {
                        title: "TwinTracker Bracket",
                        price: "150,000",
                        img: twinTrackerBracket,
                    },
                ],
                mark: "2",

                mainProduct: [
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTracker',
                        price: '450,000',
                        min: 10,
                        max: 999,
                    }
                ]
            },

        },


        //작업자 위치 추적
        worker: {
            workerTracking: {
                icon: accessKey1,
                title: '工人定位套餐',
                serial: 'TwinTrackerPro 10 + Server 1',
                price: '11,000,000',

                note: [
                    { text: '使用UWB定位器进行高精度定位', icon: IconInfo },
                    { text: '精度在10cm以内', icon: IconWarn },
                    { text: '每套包，室内最大安装面积为600平方米', icon: IconRoom },
                    { text: '每套包，室外最大安装面积为1,000平方米', icon: IconRoom },
                    { text: 'ORBRO服务器配备ORBRO OS', icon: IconComputer },
                    { text: '1年质保期', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'Tag(UWB)',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    }

                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 10,
                        max: 30,

                    }
                ]

            },
            twinTrackerPro: {
                icon: twinTrackerThumbnail,
                title: 'TwinTracker Pro',
                serial: 'OTR-TTUW0001',
                price: '600,000',

                note: [
                    { text: '使用UWB标签终端进行位置追踪', icon: IconInfo },
                    { text: '以太网和Wi-Fi网络连接', icon: IconTopology },
                    { text: 'PoE和USB Type-C电源供应', icon: IconPower },
                    { text: '最高10cm精度', icon: IconRulerGray },
                    { text: '1年保修期', icon: IconGuarantee },

                ],

                parts: [
                    {
                        title: 'Tag(UWB)',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker Bracket',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },

                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTracker Pro',
                        price: '600,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },


        //차량 위치 추적
        outdoor: {
            carTracking: {
                icon: accessKey1,
                title: '车辆定位套餐',
                serial: 'Server 1 + NVR 1 + PTZ Camera 2',
                price: '11,000,000',

                note: [
                    { text: '通过PTZ摄像头追踪停车场车辆位置', icon: IconInfo },
                    { text: '防护等级IP67防水防尘', icon: IconWaterproof },
                    { text: '4MP高分辨率图像', icon: IconCamera },
                    { text: '最大400m红外拍摄距离', icon: IconWarn },
                    { text: '42倍光学，16倍数字变焦', icon: IconZoom },
                    { text: '1年质保期', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server + NVR',
                        price: '7,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: ptzThumb,
                        title: 'PTZ Camera',
                        price: '4,000,000',
                        min: 2,
                        max: 4,

                    }
                ]

            },
            ptz: {
                icon: ptzThumb,
                title: 'PTZ Camera',
                serial: 'OTR-CAPT0001',
                price: '2,000,000',

                note: [
                    { text: '通过PTZ摄像头追踪停车场车辆的位置。', icon: IconInfo },
                    { text: '防护等级IP67防水防尘', icon: IconWaterproof },
                    { text: '4MP高分辨率图像', icon: IconCamera },
                    { text: '最大400m红外拍摄距离', icon: IconWarn },
                    { text: '42倍光学，16倍数字变焦', icon: IconZoom },
                    { text: '1年质保期', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: ptzThumb,
                        title: 'PTZ Camera',
                        price: '2,000,000',
                        min: 1,
                        max: 999,

                    }
                ]

            },
        },


        //유동 인구 위치 추적
        indoor: {
            peopleTracking: {
                icon: accessKey1,
                title: '人流分析套餐',
                serial: 'Server 1 + NVR 1 + IP Camera 4',
                price: '8,600,000',

                note: [
                    { text: '通过室内IP摄像头追踪人员位置', icon: IconInfo },
                    { text: '防护等级IP67防水防尘', icon: IconWaterproof },
                    { text: '2MP高分辨率图像', icon: IconCamera },
                    { text: '1年质保期', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "0",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO Server + NVR',
                        price: '7,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: ipThumb,
                        title: 'IP Camera',
                        price: '1,600,000',
                        min: 4,
                        max: 8,

                    }
                ]

            },
            ip: {
                icon: ipThumb,
                title: 'IP Camera',
                serial: 'OTR-CAIP0001',
                price: '1,600,000',

                note: [
                    { text: '通过室内IP摄像头进行位置追踪和异常行为检测。', icon: IconInfo },
                    { text: '防护等级IP67防水防尘', icon: IconWaterproof },
                    { text: '2MP高分辨率图像', icon: IconCamera },
                    { text: '1年质保期', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: ipThumb,
                        title: 'IP Camera',
                        price: '1,600,000',
                        min: 1,
                        max: 999,

                    }
                ]

            },
        },



        monitoring: {
            safetyBell: {
                icon: bell1,
                title: '安全铃',
                serial: 'KB-EW02',
                price: '100,000',

                note: [
                    { text: '紧急情况下按下按钮向管理员发送短信报警', icon: IconInfo },
                    { text: '最新版本的蓝牙', icon: IconBluetooth },
                    { text: '通过Wi-Fi连接发送报警', icon: IconWifi },
                    { text: '可发送100条报警短信', icon: IconMail },
                    { text: '使用AAA电池', icon: IconBattery },
                    { text: '1年质保期', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: safetyBellThumbnail,
                        title: 'Safety Bell',
                        price: '100,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },




        energy: {
            smartSwitch: {
                icon: switch1,
                title: '智能开关',
                serial: 'KSB-SS01',
                price: '80,000',

                note: [
                    { text: '远程可控智能开关', icon: IconInfo },
                    { text: '蓝牙5.0', icon: IconBluetooth },
                    { text: '通过手机应用控制照明', icon: IconPhone },
                    { text: '非触碰触摸开关控制', icon: IconThumbUp },
                    { text: '1年质保期', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: switchThumbnail,
                        title: 'Smart Switch',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
        },


        display: {
            esl: {
                icon: esl1,
                title: 'ESL',
                serial: 'KI-MDE1',
                price: '80,000',

                note: [
                    { text: '使用墨水显示信息', icon: IconInfo },
                    { text: '蓝牙5.0', icon: IconBluetooth },
                    { text: 'USB-C供电', icon: IconPower },
                    { text: '230dpi分辨率', icon: IconEyeline },
                    { text: 'NFC标签', icon: IconSignal },
                    { text: '1年质保期', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: eslThumbnail,
                        title: 'ESL',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },
        },


        cradle: {
            mTag: {
                icon: mTagThumb,
                title: 'Mobile Tag',
                serial: 'KI-MT02',
                price: '80,000',

                note: [
                    { text: '智能手机无线安全装置', icon: IconInfo },
                    { text: '蓝牙5.2', icon: IconBluetooth },
                    { text: '兼容iPhone、Android', icon: IconPhone },
                    { text: '1年质保期', icon: IconGuarantee },
                ],

                options: [
                    { select: 'Lightning', imgIndex: 0 },
                    { select: 'USB-C', imgIndex: 1 },
                ],


                parts: [],
                mark: "",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: 'Mobile Tag',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

            mCradle: {
                icon: mCradle1,
                title: 'Mobile Cradle',
                serial: 'KI-MC02',
                price: '80,000',

                note: [
                    { text: '智能手机无线安全装置', icon: IconInfo },
                    { text: '蓝牙5.2', icon: IconBluetooth },
                    { text: 'USB-C充电', icon: IconPhone },
                    { text: '1年质保期', icon: IconGuarantee },

                ],

                parts: [],
                options: [],
                mark: "",

                mainProduct: [
                    {
                        img: mCradleThumb,
                        title: 'Mobile Cradle',
                        price: '80,000',
                        min: 1,
                        max: 999,
                    }
                ]

            },

        },

        idCard: {
            idCard: {
                icon: idCard1,
                title: 'ID02',
                serial: 'KB-ID02',
                price: '100,000',

                note: [
                    { text: '基于蓝牙的电子员工证', icon: IconInfo },
                    { text: '蓝牙 5.0', icon: IconBluetooth },
                    { text: 'USB-C 供电', icon: IconPower },
                    { text: '1年保修期', icon: IconGuarantee },
                ],

                options: [],
                parts: [],
                mark: "",

                mainProduct: [
                    {
                        img: idCard1,
                        title: 'ID02',
                        price: '100,000',
                        min: 1,
                        max: 999,
                    }
                ]
            }
        },


        loraGateway: {
            loraGateway: {
                icon: loraGateway1,
                title: 'LoRa 网关',
                serial: 'ALESS-GW',
                price: '1,500,000',

                note: [
                    { text: 'LoRa传感器终端的数据收集', icon: IconInfo },
                    { text: 'IP67级防水防尘', icon: IconWaterproof },
                    { text: '-20 ~ 60°C工业规格环境下运行', icon: IconTemp },
                    { text: '24VDC电源供应', icon: IconPower },
                    { text: '最大6km通信距离', icon: IconWarn },
                    { text: '最多可容纳150个传感器', icon: IconSignal },
                    { text: '可选择LTE/Wi-Fi通信', icon: IconWifi },
                    { text: '1年保修期', icon: IconGuarantee },
                ],

                parts: [],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: loraGateway1,
                        title: 'LoRa 网关',
                        price: '1,500,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        loraSensor: {
            loraSensor: {
                icon: loraSensor1,
                title: 'LoRa 传感器',
                serial: 'ALESS-BA',
                price: '500,000',

                note: [
                    { text: '支持通用输入传感器连接，支持多种传感器接口', icon: IconInfo },
                    { text: 'IP67级防水防尘', icon: IconWaterproof },
                    { text: '-20 ~ 60°C工业规格环境下运行', icon: IconTemp },
                    { text: '可使用电池和外部DC24V供电', icon: IconPower },
                    { text: '最大6km通信距离', icon: IconWarn },
                    { text: '支持多种输入接口', icon: IconChainLinkGray },
                    { text: '1年保修期', icon: IconGuarantee },
                ],

                parts: [],
                options: [
                    { select: '紧急铃', imgIndex: 0 },
                    { select: '火警检测', imgIndex: 1 },
                    { select: '温度/湿度', imgIndex: 0 },
                    { select: '漏水检测', imgIndex: 1 },
                    { select: '压力', imgIndex: 0 },
                    { select: '入侵检测', imgIndex: 1 },
                ],

                mark: "2",

                mainProduct: [
                    {
                        img: loraSensor1,
                        title: 'LoRa 传感器 - 紧急铃',
                        price: '500,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },

        airQuality: {
            airQuality: {
                icon: mTagThumb,
                title: '空气质量测量仪',
                serial: 'VENTAX',
                price: '800,000',

                note: [
                    { text: '工业用空气质量测量仪', icon: IconInfo },
                    { text: '内置彩色LCD显示屏', icon: IconMonitorLineGray },
                    { text: '支持RS-485和LoRa通信', icon: IconSignal },
                    { text: '自动清洁功能', icon: IconToolsGray },
                    { text: '韩国环境公园颁发的细颗粒物一级认证', icon: IconAtomGray },
                    { text: '1年保修期', icon: IconGuarantee },
                ],

                parts: [],
                options: [],

                mark: "2",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: '空气质量测量仪',
                        price: '800,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        coMeasure: {

            coMeasure: {
                icon: mTagThumb,
                title: '一氧化碳测量仪',
                serial: 'VENTAX co+',
                price: '1,500,000',

                note: [
                    { text: '一氧化碳和空气质量测量仪', icon: IconInfo },
                    { text: '支持RS-485和Wi-Fi通信', icon: IconSignal },
                    { text: '微粒子、一氧化碳、温度/湿度测量', icon: IconTvocGray },
                    { text: '1年保修期', icon: IconGuarantee },
                ],

                parts: [],
                options: [],

                mark: "0",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: '一氧化碳测量仪',
                        price: '1,500,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        beaconIndustry: {

            plutoconPro: {
                icon: mTagThumb,
                title: 'Plutocon Pro',
                serial: 'KB-BK02',
                price: '50,000',

                note: [
                    { text: '工业用Beacon终端', icon: IconInfo },
                    { text: '最新版本的蓝牙', icon: IconBluetooth },
                    { text: 'IP65级防水防尘(-10 ~ 60°C)', icon: IconWaterproof },
                    { text: '最长3年电池使用寿命', icon: IconBattery },
                    { text: '1年保修期', icon: IconGuarantee },
                ],

                parts: [],
                options: [],

                mark: "0",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: 'Plutocon Pro',
                        price: '50,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },
        beaconTag: {

            orbroTag: {
                icon: mTagThumb,
                title: 'ORBRO标签',
                serial: 'OAS-OTBT1991',
                price: '30,000',

                note: [
                    { text: '小型Beacon终端', icon: IconInfo },
                    { text: '最新版本的蓝牙', icon: IconBluetooth },
                    { text: 'IP65级防水防尘(-10 ~ 60°C)', icon: IconWaterproof },
                    { text: '最长2年电池使用寿命', icon: IconBattery },
                    { text: '1年保修期', icon: IconGuarantee },
                ],

                parts: [],
                options: [],

                mark: "0",

                mainProduct: [
                    {
                        img: mTagThumb,
                        title: 'ORBRO标签',
                        price: '30,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },

        assetTag: {
            assetTag: {
                icon: assetTag1,
                title: 'Asset Tag (1,000EA)',
                serial: 'OAS-ABBT1991',
                price: '20,000,000',

                note: [
                    { text: '用于资产管理的超小型信标终端', icon: IconInfo },
                    { text: '最新版本的蓝牙', icon: IconBluetooth },
                    { text: '最长1年的电池使用期限', icon: IconBattery },
                    { text: '1年保修期', icon: IconGuarantee },
                ],

                parts: [],
                options: [],

                mark: "2",

                mainProduct: [
                    {
                        img: assetTag1,
                        title: 'Asset Tag (1,000EA)',
                        price: '20,000,000',
                        min: 1,
                        max: 999,
                    }
                ]
            },
        },

        robot: {
            robotBasic: {
                icon: accessKey1,
                title: '配送机器人基本套装',
                serial: '配送机器人 + TwinTrackerPro 10 + 服务器 1',
                price: '价格另询',

                note: [
                    { text: '室内定位系统与机器人系统的协调，简单配置实现快速部署', icon: IconInfo },
                    { text: '配送机器人载重量 20L', icon: IconWaterproof },
                    { text: '精度在10cm以内', icon: IconCamera },
                    { text: '室内最大安装面积600平方米', icon: IconGuarantee },
                    { text: '室外最大安装面积1,000平方米', icon: IconGuarantee },
                    { text: 'ORBRO服务器包含ORBRO OS', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: '标签（UWB）',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker 导轨支架',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },
                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO 服务器',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 100,
                        max: 300,
                    },
                    {
                        img: ipThumb,
                        title: '配送机器人',
                        price: '价格另询',
                        min: 1,
                        max: 10,
                    }
                ]
            },

            delivery: {
                icon: accessKey1,
                title: '无人装卸包',
                serial: '配送机器人 + 装载终端 + 协作机器人 + TwinTrackerPro 10 + 服务器 1',
                price: '价格另询',

                note: [
                    { text: '使配送机器人能够无人装卸的套装', icon: IconInfo },
                    { text: '配送机器人载重量 20L', icon: IconWaterproof },
                    { text: '协作机器人最大负载 12kg', icon: IconCamera },
                    { text: '精度在10cm以内', icon: IconGuarantee },
                    { text: '室内最大安装面积600平方米', icon: IconGuarantee },
                    { text: '室外最大安装面积1,000平方米', icon: IconGuarantee },
                    { text: 'ORBRO服务器包含ORBRO OS', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: '标签（UWB）',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker 导轨支架',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },
                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO 服务器',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 100,
                        max: 300,
                    },
                    {
                        img: ipThumb,
                        title: '配送机器人',
                        price: '价格另询',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: '装载终端',
                        price: '价格另询',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: '协作机器人',
                        price: '价格另询',
                        min: 1,
                        max: 10,
                    }
                ]
            },

            disinfection: {
                icon: accessKey1,
                title: '高风险管理包',
                serial: '配送机器人 + 装载终端 + 协作机器人 + 消毒设备 + 其他',
                price: '价格另询',

                note: [
                    { text: '配置用于管理高风险医疗物品配送的套装', icon: IconInfo },
                    { text: '配送机器人载重量 20L', icon: IconWaterproof },
                    { text: '协作机器人最大负载 12kg', icon: IconCamera },
                    { text: '99.9%的消毒和灭菌', icon: IconGuarantee },
                    { text: '精度在10cm以内', icon: IconGuarantee },
                    { text: '室内最大安装面积600平方米', icon: IconGuarantee },
                    { text: '室外最大安装面积1,000平方米', icon: IconGuarantee },
                    { text: 'ORBRO服务器包含ORBRO OS', icon: IconGuarantee },
                ],

                parts: [
                    {
                        title: '标签（UWB）',
                        price: '60,000',
                        img: tagUwb1,
                    },
                    {
                        title: 'TwinTracker 导轨支架',
                        price: '150,000',
                        img: twinTrackerBracket,
                    },
                ],
                options: [],
                mark: "2",

                mainProduct: [
                    {
                        img: orbroServerThumbnail,
                        title: 'ORBRO 服务器',
                        price: '5,000,000',
                        min: 1,
                        max: 999,
                    },
                    {
                        img: twinTrackerThumbnail,
                        title: 'TwinTrackerPro',
                        price: '600,000',
                        min: 100,
                        max: 300,
                    },
                    {
                        img: ipThumb,
                        title: '配送机器人',
                        price: '价格另询',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: '装载终端',
                        price: '价格另询',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: '协作机器人',
                        price: '价格另询',
                        min: 1,
                        max: 10,
                    },
                    {
                        img: ipThumb,
                        title: '消毒设备套件',
                        price: '价格另询',
                        min: 1,
                        max: 10,
                    }
                ]
            },
        }



    },

    modal: {

        title: '产品咨询',
        descHead: '请填写以下信息，我们的负责人将通过邮箱发送给您报价单。',
        descMid: '如果您需要快速回复，请拨打电话',
        phone: '1522-9928',
        descTail: '，我们将非常感谢。',
        time: '工作日（10:00 - 19:00）',

        theme: {
            name: '姓名 *',
            company: '公司名称 *',
            email: '工作用电子邮箱 *',
            phone: '联系电话',
        },
        placeholder: {
            name: '张三',
            company: 'ORBRO股份有限公司',
            email: 'example@orbro.io',
            phone: '010-1234-5678',
            ask: '请填写其他咨询内容。（选填）'
        }


    },

    accessory: '配件',
    soldOut: '售罄',
    new: '新产品',
    request: '产品咨询',
    select: '选择',

    submit: '提交',
    cancel: '取消'

}