import { IconOsOff, IconOsOn } from '@assets/images/main/icon';
import {
    monitoringOn, monitoringOff, controlOn, controlOff,
    displayOn, displayOff, cradleOn, cradleOff, idCardOn, idCardOff
} from '@assets/images/main/index';


export default {


    banner: {
        title: '출입 시스템의 혁신',
        desc: 'Orbro의 출입시스템 도입을 통해 여러분의 출입 시스템을 혁신하세요.',

        schemeTitle: '당신을 위한 출입 시스템을 선택하세요.',
        schemeDesc: '결정하는데 도움이 필요하신가요? ',
        schemeDesc2: '로 연락 주시면<br/>출입 전문가가 도와드리도록 하겠습니다.',
        phone: '1522-9928',
    },

    mainPage: {
        product: {
            title: '제품 구성 알아보기',
            desc: '혁신적인 출입시스템 제품 구성을 확인 해 보세요.',
            asset: {
                title: '모바일 출입',
                desc: '모바일로 출입하는 출입 시스템의 시작',
            },
            worker: {
                title: '자동문 출입',
                desc: '자동문에 특화된 출입 시스템의 시작<br/>모든 출입문 적용 가능',

            }
        }

    },


    bannerData: [
        {
            icon: monitoringOn,
            iconInv: monitoringOff,
            image: '',
            iconText: '비상벨',
            sub: '',
            link: '/iot/bell'
        },
        {
            icon: controlOn,
            iconInv: controlOff,
            image: '',
            iconText: '전력제어',
            sub: '',
            link: '/iot/energy'
        },
        {
            icon: displayOn,
            iconInv: displayOff,
            image: '',
            iconText: 'E-Ink<br/>디스플레이',
            sub: '',
            link: '/iot/display'
        },
        {
            icon: cradleOn,
            iconInv: cradleOff,
            image: '',
            iconText: '모바일<br/>보안장치',
            sub: '',
            link: '/iot/cradle'
        },
        {
            icon: idCardOn,
            iconInv: idCardOff,
            image: '',
            iconText: '사원증',
            sub: '',
            link: '/iot/id'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/iot/os'
        },


    ],





}