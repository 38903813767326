

export default {


  data : [
    {
      title : '建筑',
      page : [
        {name : '工人安全管理', link : '/cn/enterprise/construction/safety'},
        {name : '建筑材料定位和管理', link : '/cn/enterprise/construction/asset'},
        {name : '重型设备管理', link : '/cn/enterprise/construction/equipment'},
      ]
    },
    {
      title : '物流',
      page : [
        {name : '库存定位和管理', link : '/cn/enterprise/logistics/asset'},
        {name : '仓库工人安全管理', link : '/cn/enterprise/logistics/safety'},
      ]
    },
    {
      title : '办公',
      page : [
        {name : '资产管理', link : '/cn/enterprise/office/asset'},
      ]
    },
    {
      title : '医疗',
      page : [
        {name : '患者定位', link : '/cn/enterprise/medical/patient'},
        {name : '医疗废弃物管理', link : '/cn/enterprise/medical/waste'},
      ]
    },
    {
      title : '制造',
      page : [
        {name : '实时工艺管理', link : '/cn/enterprise/manufacturing/process'},
        {name : '实时库存管理', link : '/cn/enterprise/manufacturing/asset'},
      ]
    },
    {
      title : '零售',
      page : [
        {name : '展览馆访客分析', link : '/cn/enterprise/retail/exhibition'},
      ]
    },
    {
      title : '下载',
      page : [
        {name : 'iOS', link : 'https://apps.apple.com/us/app/orbro-service/id1673356996', backLink:'1'},
        {name : 'Android', link : 'https://play.google.com/store/apps/details?id=com.orbro.mobile', backLink:'1'},
      ]
    },
    {
      title : '支持',
      page : [
        {name : '技术', link : '/cn/enterprise/technology/uwb'},
        {name : '博客', link : '/cn/blog'},
        {name : '销售咨询', link : '/cn/request'},
        {name : '公司介绍', link : '/cn/company'},
      ]
    },
    {
      title : '合作伙伴',
      page : [
        {name : 'ORBRO主页',link : '/cn/'},
        {name : 'Kongcheck', link : 'https://check.kong-tech.com/', backLink:'1'},
        {name : 'Plutocon', link : 'https://plutocon.io/', backLink:'1'},
      ]
    },
    {
      title : '客户中心',
      page : [
        {name : '电话 : 1522 - 9928'},
        {name : '电子邮箱 : orbro@orbro.io'},
      ]
    },
    

  ]


}