import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionEquipment, logisticsAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4';

export default {
   
    desc: '屋内GPSは、屋内で移動する物体の位置を測定するシステムです。人や物体の位置をリアルタイムで追跡することができます。',
    keywords: '屋内GPS、GPS、RTLS、UWB、BLE、ビーコン、AIカメラ、位置追跡、屋内位置追跡、リアルタイム位置追跡、ビーコン、orbro、コンテック',

    ogSite: '屋内GPSソリューション | 位置追跡専門プラットフォーム、オーブロ',
    ogUrl: 'https://orbro.io/jp/blog/indoor-gps',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDldPhj_PKmAYMbJqfxzx90kvwqvmaEJvuGa-Tilhn_LDEQtT2ogbSz96n2WA7ZAkr8WCRqOH4FmFoBufrCikZrXDwtb5It8GdPL_VxgMjGS1EnQAjJ4qrWYj4KcPq2uUwiEvls5sC1jYuNZWL9bM_ehwpvaclUZmuxqNlqIHYdw-FwmLbIsnVL148GQBP4aPXNwhvDLGmbonOGByrjok58YvYwxO339qVTHGs3T_pNB3bpk3KtiI87LFAQTozPEkOlHvJdDGXaK_LwvsTpFUd_Y38nRFI6ff6khHkuBibyrmO3mFIAaSxXYgd--mNSgro0BX8zdHQ8oeun8sW6yug8ZCW_V0fedhTrafZhPhB6nDSrdpkoQs10G4ftI0mB_kPLYFSeyTOOORJzy5jXFMNQmlE9TwHl3RNxv32lu3iDvi1odQiYyAywd92rwhHhlULlmvPWd111HclIULU9SqZ4agxxncGZtw6e2TnyrWoNGJueJfvaH5fNe1AEUTSlxwvK6z_lSaG4AO6elSZZcwVN5_z_GK5f9GbYZWX98FSOF8GuENEjrNIi-rqewypjL2c50sP3_C6v3rzWQnZeXsW31fgBWLAeWZWuJTLN0s0dJ5qPgh_FJliOSsh8w5i9RXV-6kBIjI4EOxtOvLP47aYE1GhfOvxS4ckLRSt_qxWTxU4D8gTX55bKtdogVsY85YNRVjOWb1XPLXo1-28zu_1LePcA2zEaUuqqntLjWTlSpr6Z-wLGNgRAg_T9hl3DPo2Rt04piFBRqjrdIpuwE-YH4n9Z7mlUXuAcPiq6n8RyQzWRG_c-6K1wE9RTRoEtR4qOPvVOCp58VLuf9ZyZ3fc12wznVHAaMFUA5k6_hWeGX-Lo_Fa5qY0yAgq6xhHE3qmu9iNue_6L3rSRz2JDy8FJjGddpCtZAZESd-tbSTG4-IlytOnvM1CViy2ygKKcbWecEfllglOtxIWzclw3EZ8wXQFBFSmmhnnoMVXv5ksBL6JpLrVF3rOyGKZWISqxoMn_KdCKyBVHDjkCKsEDSyHsUfvdlJIdsYzown--og4tGE0XLrE4pNXLyIVp45JOS0yUaykHqkRPQDs9g-iioTlotza8AE5gBtWK_SF_-ULnujQu1fKBH8CyAYkUVSHJ0wWxk8kIYcpT6AagBiYU9-jqARQkc5x9PghJFx6rTa61P615QnzFKlWCDYKYxkNmwMFutN-ucVMK2MwUCjp_gt5h6xBiTT3eOg1S0F7KupXENgRSYm7E0s4GYje4Eizvlcfe-0eLS8yENjj4sYl2DORd_mAlF-5DX37Dssx_oov83DQQe6W_8GjOw5ieJpA-klmSxjRGZ5vrMc4U3EY8D-cejqv61EFJo8BPbbZMzgj6no9VNG1TSQ2vuaj0VA5vDl03Hx_MKtsHsGR47umJQDLT2Zk6jOmcSN65erXXx4SqDnrqJn9xL8iky-Z9ZWHL_Jdl9eTPERAcH0OmmHzq8ltKodC4bSz63jP2XGVBtdrPJBvwYVJVrbXoLsVsNNRQx1TSSPO_HA8XpPrbumKkuRJiUhBaCrX7j-6PK_b3e_nP2PMpzIXBD=w1595-h1272',
    ogType: 'ウェブサイト',

    title: '屋内GPSソリューション',
    date: '2024-01-31',
    tag1: '#屋内GPS',
    tag2: '#RTLS',
    tag3: '#UWB',
    tag4: '#位置追跡',

    text: [
        {
            head: '屋内GPSとは？',
            subHead: '',
            desc: '屋内GPSは、屋内で移動する物体の位置を測定するシステムです。人や物体の位置をリアルタイムで追跡することができます。GPSは主に屋外で使用され、正確な位置情報を得るために衛星信号を利用します。しかし、屋内ではGPS信号が弱まるかブロックされることが多く、正確な屋内追跡が難しいです。',
            dot: ''
        },
        {
            head: '屋内GPS技術',
            subHead: '',
            desc: 'これらの制限を克服するために、屋内GPSはさまざまな技術を活用しています。その中でもキーテクノロジーの1つはUltra-Wideband（UWB）技術です。UWBは非常に広い帯域幅を使用して屋内での精密な位置測定を実現するワイヤレス通信技術です。UWBタグと受信機を使用して建物内で正確な位置データを提供できます。また、AI（人工知能）カメラを使用して屋内環境で物体を認識し、それに基づいて位置追跡を行うこともできます。AIカメラは画像やビデオを解析して物体を識別し、その位置を特定するのに役立ちます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'UWB',
            desc: 'Ultra-Wideband（UWB）技術は屋内GPSを実現するための主要な技術の1つです。UWBは非常に広い帯域幅を使用して非常に短時間で多数の信号を生成します。この広帯域は正確な時間と距離の測定を可能にし、屋内空間での正確な位置追跡に不可欠です。以下はUWB技術の主な特徴と屋内GPSへの実装に関する説明です：',
            dot: {
                a: '<strong> • Ultra-Wideband（UWB）: </strong>UWB技術は非常に広い帯域幅を使用して非常に短い時間で多数の信号を生成します。この広帯域は正確な時間と距離の測定を可能にします。',
                b: '<strong> • タグと受信機: </strong>UWB技術を使用した屋内GPSシステムは主に「タグ」と「受信機」で構成されます。タグは物体、人、または動物に取り付けられ、UWB信号を生成します。受信機はこれらの信号を受信し、位置を計算します。',
                c: '<strong> • Time-of-Flight（ToF）測定: </strong>UWBシステムはタグから生成された信号が受信機に到達するまでの時間を非常に正確に測定します。これにより、室内空間内で2つのデバイス間の距離を計算できます。複数の受信機が使用される場合、物体の位置を3次元で計算できます。',
                d: '<strong> • リアルタイム位置追跡: </strong>UWBを使用したシステムは迅速かつ正確な位置追跡をサポートするため、リアルタイムの位置データを提供できます。これは室内環境で物体や人の位置をリアルタイムで追跡するのに非常に役立ちます。',
            }
        },
        {
            head: '',
            subHead: 'AIカメラ',
            desc: 'AIカメラは画像データを分析し、それを基にさまざまなタスクを実行するカメラシステムです。AIカメラの動作方法は次のとおりです：',
            dot: {
                a: '<strong> • 映像収集: </strong>AIカメラは環境からビデオまたは画像をキャプチャします。これにはレンズ、画像センサー、およびその他のハードウェア構成要素が使用されます。環境で収集した映像はデジタルイメージに変換されます。',
                b: '<strong> • データ前処理: </strong>収集した映像データは最適化のために前処理プロセスを経ます。この段階では、解像度の調整、ノイズの除去、色補正などの処理が行われます。',
                c: '<strong> • 特徴抽出: </strong>AIカメラは映像データから重要な特徴を抽出します。これらの特徴には物体、顔、動きなどに関連する情報が含まれます。特徴抽出は主にコンピュータビジョンアルゴリズムを使用して行われます。',
                d: '<strong> • ディープラーニングモデルの適用: </strong>AIカメラは抽出された特徴をディープラーニング技術を使用して深層ニューラルネットワークモデルに入力します。このモデルはトレーニングされた状態で入力データを分析し、判断します。ディープラーニングモデルはリアルタイムで識別、分類、追跡、予測を実行できます。',
                e: '<strong> • 分析と判断: </strong>AIカメラはディープラーニングモデルを使用して分析と判断を行います。たとえば、顔認識システムは顔を識別し、顔の特徴を抽出してその顔のIDを確認できます。また、物体追跡システムはリアルタイムで物体の位置を検出し、追跡できます。',
                f: '<strong> • 結果の出力: </strong>AIカメラは分析と判断の結果を出力形式で提供します。これらの結果はさまざまな形式で表示でき、通常はユーザーまたは関連システムに情報を提供します。',
            }
        },
        {
            video: video,
            link: '/jp/enterprise/logistics/asset',
            head: '一般的なGPSとの違い',
            subHead: '',
            desc: '屋内GPSと一般的なGPS（屋外GPS）の間にはいくつか重要な違いがあります。これらの違いは、GPSシステムが主に屋外環境で動作し、屋内での正確な位置追跡が難しいことに関連しています。以下は屋内GPSと一般的なGPSの主な違いです：',
            dot: ''
        },
        {
            head: '',
            subHead: '信号強度と障害',
            desc: '',
            dot: {
                a: '<strong> • 屋外GPS: </strong>一般的なGPSは衛星信号を使用して位置を決定します。信号は空中で自由に伝播できるため、通常は強力で建物や障害物による妨害が少ないです。',
                b: '<strong> • 屋内GPS: </strong>建物の内部では、GPS信号が弱まるかブロックされる可能性が高いです。壁、天井、床、金属構造物などがGPS信号を劣化させたり遮断したりすることがあります。',
            }
        },{
            head: '',
            subHead: '衛星信号受信',
            desc: '',
            dot: {
                a: '<strong> • 屋外GPS: </strong> 通常、従来のGPSは正確な位置情報を得るために少なくとも3つの衛星から信号を受信する依存関係があります。通常、4つ以上の衛星を使用すると、さらに正確な位置情報を提供できます。',
                b: '<strong> • 屋内GPS: </strong> 屋内では、通常、少なくとも衛星信号を受信しないか、受信することが難しい場合があります。これにより、屋内での正確な位置追跡が難しくなります。',
            }
        },
        {
            head: '',
            subHead: '補正と強化技術',
            desc: '',
            dot: {
                a: '<strong> • 屋内GPS: </strong> 屋内の位置追跡には、Ultra-Wideband（UWB）、RFID（Radio-Frequency Identification）、Wi-Fi、Bluetooth、および地上ベースの位置追跡システムなど、さまざまな他の技術が使用されています。これらの技術を組み合わせることで、屋内GPSシステムが強化され、精度が向上します。',
                b: '',
            }
        },
        {
            head: '',
            subHead: '精度と正確性',
            desc: '',
            dot: {
                a: '<strong> • 屋外GPS: </strong> 従来のGPSは、最適な条件下で約1メートル以下の高い精度を達成できます。',
                b: '<strong> • 屋内GPS: </strong> 屋内GPSシステムは、正確な位置測定にはより多くの努力が必要であり、環境、使用される技術、実装に応じて精度が異なります。一般的に、屋内GPSの精度は低いです。',
            }
        },
        {
            head: '屋内GPSの利用事例',
            subHead: '',
            desc: '屋内GPS技術は、利便性と効率を向上させるためにさまざまなアプリケーション領域で活用されています。ユーザーは建物内でも正確な位置情報を得ることができ、関連するサービスとアプリケーションの利便性と効率を向上させることができます。',
            dot: ''
        },
        {
            head: '',
            subHead: 'ショッピングモール内での製品の位置追跡',
            desc: '消費者がショッピングモールを訪れる際、屋内GPSを備えたスマートフォンアプリを使用して、希望する製品のリアルタイムの位置を検索できます。これにより、消費者は迅速に欲しい製品の位置を特定でき、ショッピング体験を向上させ、店舗の効率を向上させることができます。',
            dot: ''
        },
        {
            head: '',
            subHead: '病院内での患者の位置追跡',
            desc: '病院は屋内GPSを使用して患者のリアルタイムの位置を追跡し、医療スタッフと患者の安全を確保することができます。たとえば、医療スタッフは迅速に患者の位置を特定し、緊急事態に対応できます。',
            dot: ''
        },
        {
            head: '',
            subHead: '空港での旅客案内',
            desc: '大規模な空港は、旅客がゲート、レストラン、ショップなどを簡単に見つけるのに屋内GPSを利用しています。旅客はスマートフォンアプリを使用して空港内で自分の位置を特定し、必要な情報にアクセスできます。',
            dot: ''
        },
        {
            head: '',
            subHead: '工場内の自動化とロボット制御',
            desc: '屋内GPSは、ロボットと自動化装置の位置を正確に追跡できるようにします。これにより、生産プロセスの最適化が実現し、運用効率が向上します。',
            dot: ''
        },
        {
            head: '',
            subHead: '屋内ナビゲーション',
            desc: '建物内での屋内ナビゲーションは、博物館、大学、複合施設などの場所で有用です。訪問者は建物内で目的地を簡単に見つけることができ、観光名所や教育施設を探索しやすくなります。',
            dot: ''
        },
        {
            head: '',
            subHead: '駐車場の管理',
            desc: '屋内GPSは、駐車場内の空き駐車スペースを特定し、運転手を利用可能なスポットに案内するために使用されます。これにより、駐車時間が短縮され、駐車場の効率が向上します。',
            dot: ''
        },
        {
            head: '結論',
            subHead: '',
            desc: '屋内GPSは、建物内での位置を正確に追跡する技術であり、UWBやAIカメラなどの技術を利用しています。これはショッピングモール内での製品の位置追跡、病院内での患者の位置追跡、空港での旅客案内、工場内の自動化とロボット制御、屋内ナビゲーション、駐車場の管理など、さまざまな用途で利便性と効率を向上させます。ただし、信号強度と障害、衛星信号受信、精度、および補正技術の観点から、屋内GPSと従来のGPSの間には違いがあります。屋内GPSは主に屋内位置追跡に特化しています。',
            dot: ''
        }
        
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB端末を作業者または装備に付着し、リアルタイムで位置測位ができる製品です。',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }
        ],
        solution: [
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/jp/enterprise/retail/exhibition'
            },
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/jp/enterprise/logistics/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/jp/enterprise/construction/equipment'
            }
        ]
    }
}
