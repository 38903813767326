import { FlexStyle } from '@assets/styled/flex';
import { SCREEN_BREAKPOINT, StyledGridContainer } from '@components/Grid/Grid.style';
import { StyledDisplay, StyledHeading, StyledLabel, StyledParagraph } from '@components/Text';
import { TypeMap } from '@core/util';
import styled from '@emotion/styled';
import { LayoutType } from '@redux/actions/common';

export const newsItemWidthMap: TypeMap<LayoutType, number> = { 
  desktop: 389,
  tablet: 320,
  mobile: 300,
};

export const newsItemGapMap: TypeMap<LayoutType, number> = { 
  desktop: 32,
  tablet: 32,
  mobile: 16,
};

export const careerItemGapMap: TypeMap<LayoutType, number> = { 
  desktop: 32,
  tablet: 32,
  mobile: 16,
};


export const StyledAppPage = styled(StyledGridContainer)`

  overflow:hidden;
  display : flex;
  align-items : center;
  justify-content : center;
  position : relative;

  margin-top : 64px;

  & * {
    font-family: Pretendard !important;
    font-display: swap;
  } 


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
    
  }
`;


export const StyledDetailPage = styled(StyledGridContainer)`

  margin-top : 64px;

  & * {
    font-family: Pretendard !important;
    font-display: swap;
  } 


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
    
  }
`;

export const StyledTitle = styled(StyledDisplay)`
  text-align : center;
`;


export const StyledDesc = styled(StyledParagraph)`
  margin-top : 16px;
  text-align : center;

  color : #646464;

`;



export const StyledIconContainer = styled.div<{w : number, mw : number}>`
  display : flex;
  justify-content : center;
  margin : 16px 0px;
  
  overflow : hidden;
  position : reltaive;
  z-index : 4;

  ${({w, mw})=> w && mw && `width : ${w}; max-width : ${mw};` }

  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  
  
`;

export const StyledIconWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width : 80px;
  height : fit-content;
`;

export const StyledIcon = styled.img`
  height: 36px;
  width: 80px;
  object-fit: contain;

`;

export const StyledIconText = styled(StyledLabel)<{color : string}>`
  margin-top: 8px;

  text-align : center;
  ${({color})=> color && `color : ${color};`}
  `;

export const StyledIconTextSub = styled(StyledLabel)`
  margin-top: 2px;
  color: #00C37D;


`;


export const StyledTextContainer = styled.div`
  padding-top : 64px;
  text-align : center;
  

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding-top : 48px;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-top : 48px;
    
    
  }
`;



export const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width : 100%;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-top : 54px;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-top : 77px;
    
    
  }
`;

export const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const StyledCameraBannerImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top : 140px;
  margin-bottom : 90px;


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    margin-top : 70px;
  margin-bottom : 90px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-top : 0px;
    margin-bottom : 0px;
  }
`;


export const StyledCareerSeciton = styled.div`
  width: 100%;
  overflow-x: hidden;
  & * {
    font-family: Pretendard !important;
    font-display: swap;
  }

  padding-top: 128px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding-top: 96px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-top: 96px;
    
  }
`;

export const SliderPage = styled.div`
  overflow : hidden;
  display : flex;
  align-items : center;
  justify-content : center;
  position : relative;
`



export const SliderSection = styled.div`
  display : flex;
  padding : 0 24px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{ 
    padding : 0 16px;
  }
`


export const SliderLayout = styled.div`
  display : flex; 
  gap : 16px;
  width : 100%;



`

export const StyledCareerListWrapper = styled.div`
  width: 100%;
  height: 74px;
  
  display : flex;
  justify-content : center;
  
  &::-webkit-scrollbar {
    display: none;
  }


`;

export const StyledCareerList = styled(FlexStyle)`
  width: fit-content;

  height: 100%;
  column-gap: ${careerItemGapMap.desktop}px;
  transition: left .4s ease-in;

  gap : 16px;
  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
  }



  @media (max-width: 360px) {
    height : auto;
    position : relative;
    display : flex;
    margin-left : 40px;
  }



`;


export const SliderButton = styled.div<{direction : boolean, bgcolor : string}>`
  display : flex;
  align-items: center;
  justify-content : center;
  
  height : 106px;
  width : 40px;
  cursor : pointer;
  
  z-index : 4;


  
  ${({ direction }) =>
    direction
      ? 'border-right: 1px solid rgba(0, 0, 0, 0.08); transform: translateX(12px); left: 0;'
      : 'border-left: 1px solid rgba(0, 0, 0, 0.08); transform: translateX(-12px); margin-left: auto;'}
  ${({ bgcolor }) => bgcolor && `background-color: ${bgcolor};`}
  
`




export const StyledImg = styled.img`
    object-fit : contain;
    width : 100%;
    height: 200px;

    cursor : pointer;
    
`

export const StyledTextBox = styled.div`
    height : 156px;
    width : 100%;

`
export const StyledTextHead = styled(StyledHeading)`
    text-align : center;
    width : 100%;
`


export const StyledTextPara = styled(StyledParagraph)`
    margin-top : 8px;
    text-align : center;
    width : 100%;
    height: 48px;

`

export const StyledBtnBox = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom : 32px;

`


export const StyledBtn = styled.div`
    margin : auto;
    padding: 12px;
    text-align : center;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    cursor : pointer;
    background-color : #2A70F0;

`


export const BorderBottom = styled.div`

  padding-bottom : 128px;
  border-bottom : 1px solid rgba(0, 0, 0, 0.08);


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{ 
    margin : 0 16px;
    padding-bottom : 96px;
  }

`

export const ImgScale = styled.img<{w : string, h : string, trans : boolean}>`

${({w, h})=> w && h && `width : ${w}px; height : ${h}px;`}
${({trans}) => trans && 'transform : scaleX(-1);'}
`;