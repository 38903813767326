import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {

    
  helmet : '| Why Smart Logistics Warehouses Lead the Industry',
  desc : 'Smart warehouses lead the industry by optimizing logistics processes and maximizing efficiency through real-time inventory tracking. This is achieved by accurate inventory tracking and efficient inventory movement, resulting in cost savings and improved customer service levels.',
  keywords : 'large logistics warehouse management, logistics center management, logistics warehouse management, smart logistics, inventory management, smart inventory management, logistics warehouse inventory management, large logistics warehouse inventory management',

  ogTitle : '',
  ogDesc : '',
  ogSite : 'Why Smart Logistics Warehouses Lead the Industry :: ORBRO Blog',
  ogUrl : 'https://orbro.io/en/blog/smartLogistics',
  ogKey : '',
  ogImg : 'https://lh3.googleusercontent.com/fife/AKsag4NDUS2r1YTByIJmtp6T8gd_h-Y0FvrgnSd4VlXMvLXHrcsoDQuYCCpyNiEnagSHA56J_cXX6ma4jVfg2btnQwzHH1T8dty-PlOi09CLRr0QCFPG5Y4vVqfn7C5PnTTsR0x0nbT2ON5OhY0dk5d-xXzCLE6vUBjcUa8fBILF1gIuf_N84MNO3N-VeWiksJFzVJc-xgHqholB4vdavvEz7lQXy-2H0Bd4mRgkpdEB_TKat6ecFPI1Iicj74Quxd7X80Jsf4K-0CLRxPmsRzqHK-9OTe65ZtvBh6uCdL9EkTWgEqIhYkBZurnVB2Xu1w5ehFm0YWCO4mUMXNlZ-Dl5zLrTMwASpHVsXgOer9HduJZ_tpy7yNSd1_eqR0YtzmRoll-I0lVS6kZFRtI8DFYaLVzENwJgYYzd7g6722BEKL9hnLql3Q5hQpwBGPeiSfZBLuwjIxTUqbuTlemEXD9BykaU6SchggZ0JNi9oy4MVSmKjvnHMAInxjwv2dhy0MyVb7f8gdr8lK41OhMhRx4OiMYRfCoJjXc6Ee9DOX-Z7g2HReiWKhMK4gGpS9wKp_fsj9PATynJFs2AJY8G1c4G84Vqpv7jFwYbpgyp11npXaIaG12FHyF7hBdvDtwmWQ2Ebb1M7Fl2CEQFB7GZREh7wslvCX6Tst9-y6HysrikAuUYviPj_IFrmp4eDDGbQIznKzXVpeegI-fbzkPhiPv5MREXUt_gCYR_CKVy916MV0gpoSXeKYuSjDEih9ebiLmq-kpGGAb6Cl3G9s5zsiWOZLaZlZKjoDCI9Gujoy7FTRDGFM3r6HaIFY7mehUppvhCS_9nsSGgk32eoWdThQu3oUDCJMbf4vDIWM7bBa6LW4_OqliCAqREvMwSx8EezvOQf01DLRZ1UMbWyA_QQl4qIa_g7P8_NAUSWbFSZf2rjA5WRMJ12rKhQrUsxiSTUOnFjee6CSe693z1ccTi0XCbjaPdu7LEg9Mbcb2wmJ-0Jcxjmggf_jEWTjwwGIGUrzcIPJ0PqDXNiCW5esg5ksxHkcunw8ra44iq0DjQx8vE2BZ62e4i5meo_tR91eEqPecKgYFSVnNfBmE7mPfBo5W-Y_rrffkTnIQxlgR8zFUselSqB-KFuai7uIoAniJzBPc8ReLT7OJzprQQyMgdDtCW3epD-bbknMF4stChNDuYgGa85BsShBc3_pEuvsoxINtMIUXtpn5s7IS18mmnVLyK8C-jqpisEXwk8A4CZjv3tU4GWJyb_ZZoL9ZpCb6h6w4Brla59Ia3dRU-ZUKtVXg7CNzMdllUkdXV64VHxjSmpvNtAJ92aE_qUOj8Q1BOwQuAh71_yBTW0zKAxhme_coBD55dETFQakhVS484NQqw2sj2Ypm7qUDu6UWHB8TZVpogTjCpoKhjT84dNSVIMMqHk6UT-4BiaXv1NMyFb6LtFhGmbUO8iEX1n9RsO2Sn5bNAwjs8aFmh_P5eX9Qfj3HJ5YX_ChNlBlkwTDgdj_V3_uJm_DErrCc4YCi_ODmOv_0Gj-2o3wiULGzOPSZQdtnhCSOCNOEokcoQSRMcy1l4IBEW5Hs=w1470-h1233',
  ogType : 'website',

  twitterCard : '',
  twitterTitle : '',
  twitterDesc : '',

  title: 'Why Smart Logistics Warehouses<br/>Lead the Industry',
  date : '2023-08-03',
  tag1 : '#LargeLogisticsCenter',
  tag2 : '#SmartLogistics',
  tag3 : '#InventoryManagement',
  tag4 : '#SmartInventory',
    
  // <br/>
  text : [
    {
      head : '',
      subHead : '',
      desc :`Inventory management is the core of a logistics center's supply chain. Therefore, logistics managers striving for business growth are constantly seeking more efficient inventory management. Beyond just inventory management, they aim to find the smartest methods to enhance overall logistics system efficiency and productivity.<br/>With recent advancements in Real-time Location System (RTLS), a real-time location tracking system, warehouses have evolved into a different form of systems compared to the past.<br/>Now, everything inside a logistics warehouse can be tracked. The positions of inventory, workers, forklifts, and more are displayed in real-time on a map resembling the layout of the warehouse. This sophisticated technology with minimal errors allows for comprehensive insights into the entire site. It holds potential for various applications beyond simple location tracking.<br/><br/>Below, we will explain the various functionalities that can be achieved through this technology.`,
      dot : ''
      
    },
    {
      head : 'Inventory Location Tracking',
      subHead :'',
      desc : 'You can track the locations of inventory items inside the logistics warehouse. By selecting a specific area, you can pinpoint the positions and quantities of inventory in that area. If you are looking for a particular item, you can use the search function to identify its location within the warehouse. If the inventory is in transit, you can track its real-time movement. Based on item popularity, you can optimize inventory placement and turnover rate, which helps in cost reduction and prevention of shortages or excess inventory. With accurate inventory location information, logistics workers can quickly locate items and move them to the required positions, reducing work time and increasing productivity.',
      dot : ''
      
    },
    {
      head : 'Automated Inbound and Outbound Processes',
      subHead :'',
      desc : 'Inventory items passing through pre-designated points have their inbound and outbound times recorded in real-time. This accurate data prevents human errors and saves manpower. Furthermore, this data is stored in the cloud, eliminating the hassle of manual management or the risk of loss.',
      dot : ''
      
    },
    {
      head : 'Reduced Lead Time',
      subHead :'',
      desc : 'Real-time inventory tracking enables quicker lead time implementation. Knowing the exact inventory location allows you to quickly find and retrieve the required items, reducing production or shipping time.',
      dot : ''
      
    },
    {
      head : 'Safety Management',
      subHead :'1. Worker Tracking',
      desc : 'Real-time worker tracking allows for monitoring safety situations and enables rapid responses to workers approaching hazardous areas or during emergencies, helping to prevent potential risks proactively.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'2. Hazard Zone Setting',
      desc : 'Pre-set hazardous or restricted areas can be configured, and workers passing nearby can be warned or administrators can be alerted in real-time. This helps prevent accidents.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'3. Forklift Management',
      desc : 'Safety concerns extend beyond workers to include heavy equipment. Real-time tracking of heavy equipment with potential accident risks allows detection of worker proximity or unauthorized entry, preventing accidents.',
      dot : ''
      
    },
    {
      head : '',
      subHead :'4. Asset Management',
      desc : 'This technology aids in preventing inventory losses and theft. With inventory assigned and tracked in real-time at each location, inventory losses or theft can be quickly detected, minimizing losses and enabling recovery measures.',
      dot : ''
      
    },

  ],


  link : {
    page : 'rtls',
    numb : '1',
    product : [
      {
        text : productPurchase.purchase.asset.assetTracking.title,
        title : productPurchase.purchase.asset.assetTracking.title,
        serial : productPurchase.purchase.asset.assetTracking.serial,
        desc: "This product is a UWB device that can be attached to workers or equipment to assist in real-time location tracking.",
        img : productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution : [
      {
        img : logisticsAsset.thumb,
        data : 'logisticsAsset',
        link : '/en/enterprise/logistics/asset'
      },
      {
        img : logisticsSafety.thumb,
        data : 'logisticsSafety',
        link : '/en/enterprise/logistics/safety'
      }, 
      {
        img : manufacturingAsset.thumb,
        data : 'manufacturingAsset',
        link : '/en/enterprise/manufacturing/asset'
      }
    ]

  }


}