
import { outdoorOff, outdoorOn, indoorOn, indoorOff, IconAlarm, IconAnalyze, IconSDK, IconExcel, IconImage, IconTracking, IconLocation, IconHeatmap } from '@assets/images/main/index';
import { CameraFunctions1D, CameraFunctions1M, CameraFunctions1T,
    CameraFunctions2D, CameraFunctions2M, CameraFunctions2T, 
    CameraFunctions3D, CameraFunctions3M, CameraFunctions3T, 
    CameraFunctions4D, CameraFunctions4M, CameraFunctions4T, 
    CameraFunctions5D, CameraFunctions5M, CameraFunctions5T, 
    CameraFunctions6D, CameraFunctions6M, CameraFunctions6T, 
    CameraFunctions7D, CameraFunctions7M, CameraFunctions7T } from '@assets/images/main/camera/main/functions';
import { IconOsOff, IconOsOn } from '@assets/images/main/icon';


export default {

    mainPage: {
        product: {
            title: '製品構成を確認する',
            desc: '革新的な位置測位製品の構成を確認してください。',
            outdoor: {
                title: '屋外カメラ',
                desc: 'カメラを使用して人、車両などさまざまなオブジェクトの位置と異常な行動を検知し、管理することができます。',
            },
            indoor: {
                title: '屋内カメラ',
                desc: 'カメラを使用して屋内の人々の移動経路を分析することができます。',
    
            }
        }
    
    },
    
    banner: {
        title: 'オブロAIを利用したカメラ位置追跡',
        desc: 'オブロカメラシステムは人、車両などさまざまなオブジェクトの位置と異常な行動を管理します。',
    
        schemeTitle: 'オブロが提案するカメラシステム。',
        schemeDesc: '決定を支援が必要ですか？',
        schemeDesc2: 'ご連絡いただければ、出入り専門家がお手伝いいたします。',
        phone: '1522-9928',
    },
    
    bannerData: [
        {
            icon: indoorOn,
            iconInv: indoorOff,
            image: '',
            iconText: '屋内カメラ',
            sub: '',
            link : '/jp/camera/indoor'
        },
        {
            icon: outdoorOn,
            iconInv: outdoorOff,
            image: '',
            iconText: '屋外カメラ',
            sub: 'New',
            link : '/jp/camera/outdoor'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/jp/os'
        },
    
    ],
    
    video: {
        title: 'ORBRO人工知能カメラシステム',
        desc: [
            {text: '屋外空間にある車両や人の位置情報を収集し、分析します。'},
            {text: '屋内で移動する人々の位置情報を把握します。'},
            {text: '公共CCTVなどの既存のカメラインフラと連携が可能です。'},
            {text: '必要なカメラ機器を簡単に追加して使用できます。'},
        ]
    },
    
    
    
    func: {
        title: 'カメラの機能を確認する',
        desc: 'カメラのさまざまな機能をチェックしてください。',
    
        data: [
            {
                img: IconHeatmap,
                title: 'ヒートマップ',
                desc: 'リアルタイムにデータを蓄積し、時間帯別のヒートマップを提供します。'
    
            },
            {
                img: IconLocation,
                title: 'リアルタイム位置測位',
                desc: 'オブロの位置測位システムはリアルタイムで位置を測位します。生き生きとした現場を分析的に監視できます。'
    
            },
            {
                img: IconAnalyze,
                title: '分析ツール',
                desc: '多くの位置データを収集し、統計データとインサイトを取得できます。'
    
            },
            {
                img: IconAlarm,
                title: 'アラーム設定',
                desc: '人が入れない危険なエリアに警告エリアを設定して通知を受け取ることができます。'
    
            },
            {
                img: IconImage,
                title: 'オブジェクト認識',
                desc: '撮影した画像を解析して、さまざまな種類のオブジェクトを認識します。'
    
            },
            {
                img: IconTracking,
                title: 'SDK提供',
                desc: 'タグ端末なしで空間を分析し、異常な兆候を検知します。'
    
            },
            {
                img: IconExcel,
                title: 'Excelダウンロード',
                desc: '必要なデータを整理してExcelデータをダウンロードできます。'
    
            },
            {
                img: IconSDK,
                title: 'SDK提供',
                desc: 'オブロの位置測位システムは、SDKを提供して顧客のニーズに合った開発をサポートします。'
    
            },
        ]
    
    },
    
    functions: {
        title: 'AIを活用したデジタルツイン位置追跡技術',
        desc: 'ORBROのAIカメラシステムは高い精度と安定性を誇ります。<br/>屋内および屋外で人や車両の位置を追跡し、ユーザーに必要なデータを提供します。',
    
        line: 3,
        card: [
            {
                size: '3',
                tag: '主要機能',
                title: 'ビデオで検出されたオブジェクトは、地図上でリアルタイムに表示されます',
                label: '管理者',
                img: {
                    desktop: CameraFunctions1D,
                    tablet: CameraFunctions1T,
                    mobile: CameraFunctions1M
                },
            },
            {
                color: '#fff',
                size: '1',
                tag: 'モバイルサービス',
                title: 'すべてのスペースでのモバイル利便性の提供',
                label: '共通提供',
                img: {
                    desktop: CameraFunctions2D,
                    tablet: CameraFunctions2T,
                    mobile: CameraFunctions2M
                },
            },
            {
                size: '2',
                tag: '既存のCCTVとの互換性',
                title: 'すべてのビデオデータとの互換性',
                label: '管理者',
                img: {
                    desktop: CameraFunctions3D,
                    tablet: CameraFunctions3T,
                    mobile: CameraFunctions3M
                },
            },
            {
                size: '2',
                tag: '人物と車両のオブジェクト認識',
                title: '人物と車両の検出、高い認識率',
                label: '管理者',
                img: {
                    desktop: CameraFunctions4D,
                    tablet: CameraFunctions4T,
                    mobile: CameraFunctions4M
                },
            },
            {
                size: '2',
                tag: '現地サーバーのセットアップ',
                title: '最も信頼性の高く安全なセキュリティシステム',
                label: 'オンプレミス',
                img: {
                    desktop: CameraFunctions5D,
                    tablet: CameraFunctions5T,
                    mobile: CameraFunctions5M
                },
            },
            {
                size: '1',
                tag: '屋外駐車場などのエンタープライズグレードのインストール',
                title: '大規模駐車施設に適用可能なインストール',
                label: 'エンタープライズ',
                img: {
                    desktop: CameraFunctions6D,
                    tablet: CameraFunctions6T,
                    mobile: CameraFunctions6M
                },
            },
            {
                size: '3',
                tag: 'ダッシュボード',
                title: 'データ駆動の意思決定のためのデータインサイト',
                label: '管理者',
                img: {
                    desktop: CameraFunctions7D,
                    tablet: CameraFunctions7T,
                    mobile: CameraFunctions7M
                },
            },
        ]
    },
    
    FAQ: [
        {
            title: '1つのサーバーに何台のカメラを設置できますか？',
            description: 'カメラを使用した位置測位は、主にコンピュータビジョン技術を利用してオブジェクトを認識し、その位置を測位する技術です。これを実現するために、カメラで撮影された画像をリアルタイムで解析してオブジェクトを検出し、その後オブジェクトの動きを測位します。<br/><br/>画像解析は計算能力を要求するタスクです。各カメラが撮影したビデオストリームを処理し、オブジェクトの測位計算を行うために、1つのサーバーには最大で8台のカメラを推奨します。<br/><br/>'
        },
        {
            title: 'NVRの購入は必須ですか？',
            description: 'カメラを使用した位置測位にはNVRが必須ではありません。<br/><br/>ただし、オブロOSは画像を解析してオブジェクトの位置を測位する機能に特化したソフトウェアであり、NVRの固有機能を提供しません。CCTVの映像をリアルタイムでモニタリングし、映像を保存して活用したい場合は、NVRの使用をお勧めします。<br/><br/>',
        },
        {
            title: '既存のCCTVを使用してシステムを運用できますか？',
            description: 'はい、既存のCCTVを使用してシステムを運用することが可能です。<br/><br/>ただし、既存のCCTVの性能はオブロOSが提案する仕様と類似している必要があります。一般的に既に設置されているCCTVは位置測位を目的としておらず、セキュリティの目的で設置されていることが一般的です。より包括的な位置測位インフラを構築したい場合は、別途カメラを設置することをお勧めします。<br/><br/>',
        },
        {
            title: '自社サービスデータと連動できますか？',
            description: 'はい、カメラを使用した位置測位データは、オブロOSというソフトウェアを使用して位置を分析し、保存しています。希望すれば、オブロOSで管理されているデータを呼び出して、自社のシステムと連動させることが可能です。<br/><br/>',
        }
    ],
    



}