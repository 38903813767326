import { Spacing } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";
import React, { PropsWithChildren, useEffect, useState } from "react";


interface ModalDefaultType {
    link : string
  }
  
  const Youtube = ({
    link
  }: PropsWithChildren<ModalDefaultType>)  => {


    const [windowWidth, setInnerWidth] = useState<number>(1920);
    useEffect(() => {
      if (typeof window !== 'undefined') { 
      }
      const handleResize = () => {
        const { innerWidth } = window;
        setInnerWidth(innerWidth);
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    const ratio = 1.65;
  
    return (
        <DivAllCenter>
          <iframe
            title="YouTube Video"
            width={windowWidth / ratio}
            height={(windowWidth / ratio) * (9 / 16)} 
            src={`${link}?autoplay=1`}
            frameBorder="0"
            allowFullScreen
          />
          <Spacing pixel="128"/>
        </DivAllCenter>
    )
}


export default Youtube;