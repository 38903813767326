import { FlexStyle, Vertical } from '@assets/styled/flex';
import { SCREEN_BREAKPOINT } from '@components/Grid/Grid.style';
import { StyledHeading } from '@components/Text';
import { TypeMap } from '@core/util';
import styled from '@emotion/styled';
import { LayoutType } from '@redux/actions/common';


export const careerItemGapMap: TypeMap<LayoutType, number> = { 
  desktop: 32,
  tablet: 32,
  mobile: 16,
};

export const StyledCareerSeciton = styled.div`
  width: 100%;
  overflow-x: hidden;
  & * {
    font-family: Pretendard !important;
    font-display: swap;
  }

`;

export const StyledCareerListWrapper = styled.div`
  width: 100%;
  height: 500px;
  position: relative;
  

  &::-webkit-scrollbar {
    display: none;
  }


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height: auto;
  }

`;

export const StyledCareerList = styled(FlexStyle)`
  width: fit-content;
  position: absolute;
  left: 0;
  height: 100%;
  column-gap: ${careerItemGapMap.desktop}px;
  transition: left .4s ease-in;

  
  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
    height : auto;
    position : relative;
    display : flex;
    flex-direction : column;
    gap : 16px;
  }



`;

export const StyledCareerController = styled(FlexStyle)`
  column-gap: 16px;
  align-items: center;
`;

export const StyledCareerControllerItem = styled.div<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const StyledCareerItem = styled(Vertical)`
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: ${({ theme }) => theme.BACKGROUND_PRIMARY};
  padding: 32px 24px;
  justify-content: space-between;
  align-items: flex-start;
  text-align : center;
  width : 389px;


  &:hover {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  }


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    width : 232px;
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
  }
`;

export const StyledCareerItemRequirementBox = styled.div`
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  padding: 6px 8px;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => (theme.BORDER_SELECTED)};
  column-gap: 4px;
`;

export const StyledCareerItemLink = styled.a`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const StyledCareerItemTitle = styled(StyledHeading)`
  font-size: 24px;
  color: ${({ theme }) => (theme.CONTENT_PRIMARY)};
  font-weight: 700;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    font-size: 20px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    font-size: 18px;
  }
`;



export const StyledSliderBtnBox = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  z-index : 999;
  align-items : center;
  

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    display : none;
  }
`;

export const StyledSliderBtn = styled.button  `
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;


  padding: 16px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 100px;

  width : 56px;
  height : 56px;
  display: flex;
  align-items: center;
  justify-content : center;



  &:first-child {
    left: -105px;
  }

  &:last-child {
    right: -105px;  
  }

  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 1468px) {
    &:first-child {
      left: 0px;  
    }

    &:last-child {
      right: 0px;  
    }
  }

  
`;



export const StyledBorderBottom = styled.div`

  padding-bottom : 96px;
  border-bottom : 1px solid rgba(0, 0, 0, 0.08);


`