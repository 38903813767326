import gis from "@src/assets/images/digitaltwin/video/gis.mp4";
import weather from "@src/assets/images/digitaltwin/video/weather.mp4";
import buildings from "@src/assets/images/digitaltwin/video/buildings.mp4";
import tracking from "@src/assets/images/digitaltwin/video/tracking.mp4";
import traffic from "@src/assets/images/digitaltwin/video/traffic.mp4";
import airplane from "@src/assets/images/digitaltwin/video/airplane.mp4";
import cctv from "@src/assets/images/digitaltwin/video/cctv.mp4";

import video from "@src/assets/images/digitaltwin/video/traffic.mp4";

import {
    Icon3d3d, Icon3dCycle, Icon3dLink, Icon3dLocation, Icon3dMap,
    Icon3dMap2, Icon3dMeter, Icon3dPlus, Icon3dServer,
} from "@assets/images/icon";
import { DTexam1, DTexam2, DTexam3, headerImg, layerEngImg, usaPatent, verifyPatent } from "@assets/images/digitaltwin";



export default {

    banner: {
        title: '世界初の3世代デジタル変革<br/>デジタルツイン専門プラットフォーム',
        desc : `One step advanced Orbro's digital twin. Experience the innovation.`,
        btn: {
            left: '体験する',
            right: '紹介ビデオ'
        }
    },

    header: {

        box: [
            {
                title: '世界初',
                desc: 'Webベースのバーチャルワールドプラットフォーム'
            },
            {
                title: 'No.1',
                desc: '産業用メタバースの実績（韓国基準）'
            },
            {
                title: '500億以上',
                desc: 'アジア最大規模の位置情報データ'
            },
            {
                title: '20,000以上',
                desc: 'ORBROに接続されたリアルタイムなオブジェクトの数'
            },
        ],

        title: 'デジタル変革、デジタルツイン',
        desc: '現実世界の人々、物体、建物、および都市のデータを受信し、デジタル画面上でリアルタイムに監視できます。これにより生産性向上、問題の予防が可能であり、主に産業および製造分野で使用されています。',
        img: headerImg
    },

    connection: {
        title: 'ORBRO World、高度なデジタルツイン',
        desc: '基本的な地理情報やインフラ情報を提供するだけでなく、リアルワールドで起こる出来事をリアルタイムに理解します。ORBROは最先端の技術で高度なデジタルツイン技術を構築しました。',
        img: layerEngImg
    },

    func: {
        title: 'デジタル転換の時代<br/>どこでも適用可能なデジタルツイン',
        desc: '世界には無数のデータが存在します。ORBROは私たちが送信するデータを収集し、世界をより理解しやすくします。',
        card: [
            {
                video: gis,
                title: '3Dベースの視覚デバイス',
                desc: '現実の世界に同じ3D形状の視覚データで情報を提供します。<br/>建物や建物の内部まで、3Dマップ上にリアルタイムで反映された情報をすべて表示します。これにより、より生き生きとしたバーチャルワールドを体験できます。',
            },
            {
                video: weather,
                title: '大気および気象情報の連携',
                desc: '大気および気象情報をORBROサーバーと連動させ、実際の雨や雪が降る天気も同様に実装されるようにしました。より没入感があり、実際のような世界を確認できます。産業によっては、このような情報が不可欠な場合があります。',
            },
            {
                video: buildings,
                title: '建物情報の実装',
                desc: '施設の状態をリアルタイムでモニタリングし、保守スケジュールを最適化し、機械のセンサーデータを活用して故障を予測し、効率的に計画できます。また、建物内のエネルギー使用をモニタリングして最適化し、エネルギー効率を向上させ、環境への影響を最小限に抑えることができます。',
            },
            {
                video: tracking,
                title: '屋内建物マップの実装',
                desc: '外部データだけでなく、屋内の物品やそこを歩く人々までがリアルタイムで地図に表示されます。特に流動人口や訪問者の情報や、セキュリティが重要な施設の場合、より有意義なデータになります。',
            },
            {
                video: traffic,
                title: 'バス、地下鉄の交通情報の連携',
                desc: 'さまざまな交通手段間の協力とスマートな移動を可能にします。リアルタイムの位置追跡を通じて正確な運行情報を提供し、乗客は到着予定時間を事前に把握できます。関連する交通情報は最適な経路を提供し、これにより交通渋滞を回避し、効率的な移動が可能になります。',
            },
            {
                video: airplane,
                title: '航空機情報の連携',
                desc: '連動した情報は、航空便の遅延や変更に対する迅速な通知を提供し、旅程を最適化します。スマートゲートや輸送システムと統合された航空機情報は、旅行者の移動をスムーズに調整し、輸送ネットワークの効率を向上させます。また、予測分析を活用して航空運行を最適化し、燃料消費を減少させ環境的効果を最大化します。',
            },
            {
                video: cctv,
                title: 'CCTV映像情報の連携',
                desc: '犯罪を防ぐためにCCTV映像を連動させると、異常なしるしを検知し、それに対する警告を発生させて地域の安全を強化できます。交通フローのモニタリングは、道路交通を最適化し、交通渋滞を予防するためにリアルタイムでCCTV映像を連携させて交通状況をモニタリングおよび管理し、様々なシステムと統合することで、データを総合的に活用してスマートシティの機能を強化します。',
            },
        ]
    },
    video: {
        title: 'リアルな高画質グラフィック連動',
        desc: '現実の世界の正確なモデリングとシミュレーションを可能にし、リアルタイムの状況をより詳しく理解し、効果的に管理できます。',
        video: video
    },
    patent: {
        card: [
            {
                title: '個人の位置情報事業者認証<br/>',
                desc : '317C-0052-69BC-05A3',
                img: verifyPatent,
            },
            {
                title: '無線周波数位置追跡 米国特許<br/>',
                desc : 'DFS ID: 37686035',
                img: usaPatent,
            }
        ]
    },
    
    summary: {
        title: '主要機能の要約',
        desc: '',
        card: [
            {
                img: Icon3d3d,
                title: '3Dに基づく視覚デバイス',
                desc: '都市プラットフォームは3Dで実装されており、より生き生きとした仮想世界を体験できます。',
            },
            {
                img: Icon3dServer,
                title: 'データ収集',
                desc: 'さまざまなソースからデータを収集し、中央化された環境に安全に保存します。',
            },
            {
                img: Icon3dMap,
                title: '位置ベースのサービス',
                desc: 'ユーザーの位置情報を活用して、カスタマイズされたサービスを提供します。',
            },
            {
                img: Icon3dMap2,
                title: '緯度経度ベースのプラットフォーム',
                desc: '位置情報はすべて地理座標情報に変換され、地図上で確認が可能です。',
            },
            {
                img: Icon3dLocation,
                title: '空間に優しいサービス',
                desc: 'インテリジェントなキオスク、メディアウォールなどのハードウェアデバイスを使用して、位置ベースのサービスを提供します。',
            },
            {
                img: Icon3dLink,
                title: 'データ連携',
                desc: '公共情報とIoT/IIoTセンサーデータなどを連携させて包括的なデータ環境を形成します。',
            },
            {
                img: Icon3dCycle,
                title: 'さまざまな公共情報の連携',
                desc: 'ORBRO Worldは国や地域で発生するさまざまなデータを統合し、管理して提供します。',
            },
            {
                img: Icon3dMeter,
                title: 'レポートおよび分析',
                desc: 'データ分析の結果をレポート形式で提供し、効果的な分析をサポートします。',
            },
            {
                img: Icon3dPlus,
                title: 'さまざまなアプリケーション提供',
                desc: '収集されたデータを分析して、交通情報、環境モニタリングツール、スマートシティ管理などさまざまなアプリケーションを提供します。',
            },
        ]
    },
    exam: {
        title: 'ORBRO Worldのさまざまな活用',
        desc: 'デジタルツイン技術を活用したさまざまな分野のソリューション',
        card: [
            {
                img: DTexam1,
                tag: 'スマートシティ',
                title: '都市情報の連携',
                desc: '都市の物理的な側面をデジタルでモデリングし、リアルタイムでモニタリングして都市の運営と発展にさまざまな良い影響をもたらします。これにより、都市は効率的な資源管理を実現し、スマートシティの開発を推進し、市民に向上した生活の品質を提供できます。',
            },
            {
                img: DTexam2,
                tag: 'スマートビルディング',
                title: '屋内位置追跡',
                desc: 'ショッピングモールや複雑な建物内で正確なナビゲーションを提供し、便利な移動をサポートし、緊急時には迅速な避難をサポートします。また、屋内位置追跡は個別化された広告やサービスの提供を可能にし、ユーザーエクスペリエンスを向上させます。ビジネス環境では資産管理と生産性向上に役立ち、スマートホームでは自動化された制御システムと連携して利便性を向上させます。',
            },
            {
                img: DTexam3,
                tag: 'スマート倉庫',
                title: '物流状況の確認',
                desc: '製品の位置、移動経路、および在庫の状態などを正確に把握することで、物流ネットワークの効率を向上させ、在庫管理を最適化できます。リアルタイム情報に基づく意思決定は物流プロセスを迅速に調整できるようにし、問題が発生した場合には迅速に対応できます。',
            },
        ]
    },

}