import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";

export const VideoPlayCover = styled.video`
    width: 100%;
    display : flex;
    object-fit : cover;
    object-position: top left; 

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        object-position : center center;
    }
    
`;