import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset, medicalPatient, medicalWaste, officeAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


    helmet: '',
    desc: '실시간 위치 추적 시스템(RTLS)은 의료 폐기물을 안전하게 처리할 수 있는 완벽한 방법입니다. 위험이 사라질 때까지 모니터링하여 안전사고를 예방할 수 있습니다.',
    keywords: '의료 폐기물 관리, 의료 폐기물 처리, 폐기물 관리, 폐기물 처리, 의료 폐기물, 의료 사고 관리, 의료 장비 관리, 안전사고 관리, 의료 시설, 병원 폐기물 관리, 병원 폐기물 처리, 병원 폐기물, 오염 장비 관리, 오염 장비 처리, 실시간 위치 추적, RTLS, 오브로, 콩테크, ORBRO',

    ogTitle: '',
    ogDesc: '',
    ogSite: '의료 폐기물과 자산을 관리하는 스마트 기술 :: 오브로 블로그',
    ogUrl: 'https://orbro.io/blog/medicalWaste',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AKsag4MONQZidMWIaTrmLqrkg911u867GiKnQvRp2J_6pXN-o_ulq8j5B2yFxT7ir2txi5c7bIVIt8hiyAS_8bBcYHCvfHVgU19Cv-9Zti_3iLiSQjzwRtscyVXg8ra_zcueLOOTV98WMwQPJvqq6lLm9WCS1uuU0kQTDiObKEJgcPUH1VWuCsr2icfJp2XeQo37pAjWJRRYRO0V94Rfg7K5kCNY2i6xyCb9v7ScCCWn1TDV770PCeZCTaqxvMsBaKLqMGHrg7rel0zDkwCNNCmA3Ztbw-uSIi1yvmAFmD-UwvSJ_CDlAoODePdXtGUuvvbo7nwKL38Ku_KgOEwMjm_fu5OmSTGuXLj-6GyiDXO_BGO4kRGAVRu1XLrfWPPX4GUQvDghijtlavji5s42a6EmSNBQKQ5PNV6Xi1LKTyhsOFRT6H69kayRpXz0PGQr9o9PwF9V8wif_gyo5Yp3WupUyxj2Bjc7jiHRBLBbeT8jDbZzdNEcYtdGJm6AiYBu1d3eNxk3awkxVXesGtS7MS_zXLKg03XqO_AyofzksiJ4jqyJBJu-atlMmflMnpcow2pp_JmyyTwQqPnikDNlTZ3iywdxdsseVVU_LfDL_koySbuLXVfcbrwXThmZI0aBrW-7R3HsTN_axhMhnxoIf16Le7Oe_9dD2o29Udu271POE6W1-FFfn7mj9Qh3td3uOl5KqXAZKoy3pB9Zw5bn3wgCANBWgV5BuOaQS5tPDxrFloyRt8VHGipdKgP5Cvz8M0ZiHdq_S3Ykh3eXE2z8Mu5e1ruL-FRtmbxQTl-PaOT6LrZS3WPhkOZrgEPel4AFv93GXcBg1YXw4MHsW-w6k_pJWGv43aL2AOsJ0G-xSso8gxdCh8Z6A3j75V0HVSHZ77OVUrJsYgEIXN-XGaCdMmDEaIJEGaJkC6ryDiPWnbogd0em8sf7YiKictJq9jl-aD_OGuOALvvAAmKdAsVzzZGMUp19SWDeI4R1UT_uYZ1_vC8ocRAurJg-kwM_gZu2TlLRPMkLUdDFDNVDoOYGyq47vgWJNg6KX-VtP5NwZ1K0afRlbOl1ho7d69Hl15XsQiqOQla9c1j7YFVQiUFWpx6GR3QhPt6XHSOufQfw6OOqtMz5s3Ibt69c0hgIzV1I2dVXetfGUDWGSoYXmpbxpF2M0KrnAjCojSgEXMU6X4DEcdo_yJrru5Tl8J1_cBMr0nm09QJSNg-qXDoYtS6wq-L1L4uy-MlHr_N4G6minr4oerAf1zu8o9jL3e9XpOkJdIo0sYln8FRjzLZ4J8AdpQF4pDo768VucYPzCgSrOJ_4-ARZoCuJKqB_lQJ2bIAIST0_STyL4tpQPSFWwnrMgAdgMgAams6EhbAQCgMV1xTZjRvd5DptWwTMknoV2YJ4sVcLtlZ3sdE4wOhJH7Oucjab0uDCuYhl3AjrdtZB3TgemIMOWwuji09pERKa7q1rMo6mwI5TiHI6vt4OzEshaO1UQRMcB0mxtkJsU7xT7bTkz1c2i1SRNWplYz9TzJnWVFqcQQeQ5XcTMaHkjM1_774K3ZNMFShH_yqajpvElOIl_J4JSnz0n1gneniKhbI3DQ=w2000-h1794',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',


    title: '의료 폐기물과 자산을 관리하는 스마트 기술',
    date: '2023-08-30',
    tag1: '#의료폐기물',
    tag2: '#의료사고예방',
    tag3: '#의료장비관리',
    tag4: '#폐기물처리',

    // <br/>
    text: [
        {
            head: '',
            subHead: '',
            desc: '관리자가 의료 시설에서 신경 써야 할 부분은 환자들뿐만이 아닙니다. 의료 폐기물과 의료 장비들은 환자들에게 직접적인 영향을 미치는 중요한 자산이므로 환자 못지않게 각별한 관리와 주의가 필요합니다. 실례로, 오염된 주사기나 폐기물을 제대로 처리하지 않아서 2차 감염 사고가 발생한 경우는 쉽게 찾아볼 수 있습니다. 게다가 해마다 관리해야 할 의료 폐기물의 양은 증가하는 추세이지만, 정작 의료 시설에서 이를 위한 시스템을 구축한 사례는 잘 찾아보기 어렵습니다. 안전 관리 프로토콜을 강화하여 직원들에게 교육을 하는 것이 아마 전부일 것입니다.<br/>하지만 의료 사고는 생명과 직결된 중대한 사안이므로 더욱 안전한 솔루션이 필요합니다. 최근 실시간 위치 추적 기술(RTLS)의 성장으로 의료 산업의 이런 문제들을 해결할 방법이 나타났습니다. 여기서 실시간 위치 추적 기술이란, 대상의 현재 위치를 실시간으로 파악할 수 있는 기술입니다.<br/>그렇다면 과연 이런 기술이 의료 시설에 적용되면 어떤 것들이 가능해질지 알아볼까요?',
            dot: ''

        },
        {
            head: '',
            subHead: '1. 의료 폐기물 및 의료 장비 수량 관리',
            desc: '만약 당신이 폐기물 처리 담당자라면, 처리하고자 하는 폐기물이 시설 내 몇 번 창고에 있는지, 폐기물의 양은 얼마나 되는지 파악할 필요가 있습니다. 직접 시설을 전부 돌아다니는 것도 방법이지만, RTLS 솔루션을 도입한다면, 검색을 통해서 쉽고 빠르게 파악할 수 있습니다. 마찬가지로 수술이나 진료에 필요하거나, 고장이나 분실이 발생한 경우, 시설 내에 있는 의료 장비들의 위치를 빠르고 정확하게 파악할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '2. 실시간 위치 추적',
            desc: '폐기물을 처리하는 과정에서 다른 물건과 섞이거나 교환되는 경우가 발생할 수 있습니다.이를 예방하고 모니터링하기 위해서 실시간으로 폐기물이 이동하는 동선을 파악합니다.<br/>만약 처리가 끝나지 않은 폐기물이 환자들이 있는 병실이나 접근 금지 구역으로 이동하는 경우에는, 즉시 자동으로 알람을 보내어 대응할 수 있도록 합니다. 이 외에도 의료 장비가 시설에서 반출되거나 반입되는 상황도 모니터링할 수 있으므로, 자산 관리의 효율을 굉장히 높일 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '3. 자동 입출고',
            desc: '직접 수기로 작성하거나 컴퓨터로 입력하지 않아도, 입출고 되는 장비나 폐기물들에 대해서 기록하고 저장합니다. 이 과정은 클라우드에 저장되어 보관됩니다. 시설을 운영하는 데에 필요한 정보가 될 수도 있고, 만약 사고가 발생하여 법적인 근거가 필요한 경우에도 유용하게 사용될 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '4. 또 다른 기능',
            desc: '실시간 위치 추적 기술은 물체에만 적용되는 것이 아닙니다. 일반 환자나 특별한 관리가 필요한 환자들도 실시간으로 위치를 추적할 수 있습니다. 오히려 환자들이 폐기물을 비롯한 시설 내 접근금지 구역에 접근하면 자동으로 경고를 보낼 수도 있고, 특정 환자의 위치가 어디에 있는지 빠르게 파악할 수도 있습니다.<br/>이를 기반으로 환자의 동선을 저장할 수 있기 때문에, 만약 코로나와 같은 감염사태가 발생하였다면, 빠르고 정확한 역학조사가 가능해집니다. 실시간 위치 추적을 기반으로 더욱 진화된 환자 관리 시스템을 구축할 수 있는 것입니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '결론',
            desc: '많은 산업이 4차 산업이 시작되고 나서 변화를 보이고 있습니다. 위에서 언급한 일렬의 과정들은 기존에 사람들이 (실수 가능성을 가지고)작업하던 방식을 변화시킵니다. 인력과 경비는 줄이고 효율성과 생산성을 높이면서 동시에 더욱 안전한 시설을 구축할 수 있습니다.',
            dot: ''
        },
    ],


    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/enterprise/medical/patient'
            },
            {
                img: medicalWaste.thumb,
                data: 'medicalWaste',
                link: '/enterprise/medical/waste'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/enterprise/office/asset'
            }
        ]


    }

}