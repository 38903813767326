import { TechLogo1, TechLogo10, TechLogo2, TechLogo3, TechLogo4, TechLogo5, TechLogo6, TechLogo7, TechLogo8, TechLogo9 } from "@assets/images/company";
import { StyledGridContainer } from "@components/Grid/Grid.style";
import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import TechCard from "./components/TechCard";
import { StyledOurTechnology, StyledOurTechnologyDisplay, StyledOurTechnologyHeading, StyledOurTechnologyItems, StyledOurTechnologyParagraph } from "./OurTechnology.style";

import company from "@src/lang/ko/company";


const OurTechnology = () => {
  
  // Items
  const items  = useMemo(() => company.ourTechnology.items, []);
  const techLogos = useMemo(() => [
    TechLogo1,
    TechLogo2,
    TechLogo3,
    TechLogo4,
    TechLogo5,
    TechLogo6,
    TechLogo7,
    TechLogo8,
    TechLogo9,
    TechLogo10
  ], []);

  return (
    <StyledOurTechnology>
      <StyledGridContainer>
        <StyledOurTechnologyHeading size="xxsmall">
          <Trans i18nKey={"company.ourTechnology.subtitle"} />
        </StyledOurTechnologyHeading>

        <StyledOurTechnologyDisplay size="medium">
          <Trans i18nKey={"company.ourTechnology.title"} />
        </StyledOurTechnologyDisplay>

        <StyledOurTechnologyParagraph size="large">
          <Trans i18nKey={"company.ourTechnology.description"} />
        </StyledOurTechnologyParagraph>

        <StyledOurTechnologyItems>
          { items.map((item, index) => (
            <TechCard 
              title={ `company.ourTechnology.items.${index}.title` }
              description={ `company.ourTechnology.items.${index}.description` }  
              policy={item.policy}
              image={techLogos[index]}
              count = {index}
              key={`ourTechnology-item-${index}`}
            />
          )) }
        </StyledOurTechnologyItems>
      </StyledGridContainer>
    </StyledOurTechnology>
  );
};

export default OurTechnology;