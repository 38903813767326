import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4';

export default {

    desc: 'AIカメラ位置追跡ソリューションは人工知能技術を活用してカメラから取得した映像データを分析し、処理して人や車両の位置をリアルタイムで追跡します。',
    keywords: 'スマートパーキング、AIカメラ、人工知能カメラ、デジタルツイン、デジタルトランスフォーメーション、digital twin、orbro、ビーコン、rtls、uwb、ble、ai camera、オルブロ、コンテック',

    ogSite: 'AIカメラ位置追跡ソリューション | デジタルツインプラットフォーム、オルブロ',
    ogUrl: 'https://orbro.io/jp/blog/ai-camera',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkc4tGW9Sjxkfqer33MMng2ket3NNLJzV7Iz2LLirs_1Vbk4Lhm63aO_z8WWd2dIov4g4YuW4_LF8fiQn1v8CAQMxvfMbY58snCZaz5-tmYX7MljahebCQULlqUN3wwe4XqTP-9XdR2jE5QpHWOWD2ddnpV3jfnK3qIltSnKR3JEpPdmrECN49rXQbSa7jNtNAFPC71gTTRBtV-koIsqQYpX0un1kLVJJZxwlqQNH5_tGeA9HHAmQRhtGxmF0IkPCicaA2wexfmrwboGdPR9_tkKklNF54F6EulnMKypq_qaskVAEZvDlYArvBFhnatgUVy0Uh4yo-mw9wek4fNrsUOfIb2QjUoGzYVul27F9GFN5eXULvD3zPSTOeZHF_Fy7_VBfp_A0ugMGL_EuF2olRd-eBnVJt3xGOvMOIfgcA6AfXHOmhu7sbHo1yBoJ6cyTvJQzLMafMxmStxH5CKMzD_3FKEw0pJ25EuuAy7I5e8Pp_LxdZg_GjN8cj5qXbhIpnxCfSmd5KOol3tgfS1jCnXsAbDJ_XSVp4EBn_zgXhTswmP02m9R2XxafKdWMa-ZhZE5l4o_T_ZBOoWKwRfJXJtMcTJspT2tOfode7mVpQFhU5pa6KtAqcJtVuS1i2r4rjQDEI92DQCh0CDmnkM041jtKKL9oFXlO0GQLOX0j_Z7O1R3qZ0awsO_O7gBkBGK0CJ0PlT2_JW3eGFtPu5zROSwOKF-7rcJMH1f6R29Nf1bhejjB2zRRyISB5J6Psjf7wk3LffQaSUsmJy1Wl_J11fQUBTzMAl-mUnwqquWh2eEijlvT-y4Ixu8zeRJSM7Fk61_nWCu6F48eu4Ge_4geMR2DOjfQOnLqKKfSWY_XdzxDDeACmf6PTAgeWFDzx7MZJ6vN7yp75G8Ez9xTKMQGpcfn4qwI8oA2W3qx_NtVJ6AFT0dD7vLS87ML4D7eHI8uB2pjBlqb6v4rbxp41pQGAQ_VuldNTZNUZH8q2ddGnDJ1PVh_eKBB7uoGEPUO2Lbvws6VRw93FJDgIzqXUkPwDHMVOhhjsFBWBcBQtOh2fbVWyT1k9_jRFfeo2zJ3hPWat4kTns5Ew671LTXXGJwXGHGQB0bqGCqwhwBJGjwlPS7NFxhhDYocq8P-a1r6dyKzlEmh18hbWIc-Rz7z8h0_Fv-a4EeOmoNwTQMQl1QaWugyzqkqs1wUDKtJb7dHQBcMSkzJe-fylN3_peFQAyizDmhXmlZmjYqOOCHEXHuEyxbL8ILOK_8KxHyahhYwMSk13KrKVMR0ThHJkCcsfyA-hS9jzo4Ww8q3ARcr0R7Yb-361rI8aluKtbzPbGZHWwOQCMnDv8itfAOTiVU8l3vEfE6TPQUytUs03-_CGTmMrgpoxFxRDPkJFNwEa0671QbXV5AwJNTviSSe9q27H-I59Id0McwUZGQBn0z8XOjoJyCBZ27zRD9tSbBg-B1s1crfHk5RU8bIvgfwCsov_1oVufbxVy223DtdDzlernhw9DYzq5IUYKPoL-lZXZiS4er28lhBzPfXz4feDNHNSzB0cP_-4p-UlNtXaQtKzLGIu3WX-VqHW-=w2059-h1270',
    ogType: 'website',

    title: 'AIカメラ位置追跡ソリューション',
    date: '2024-02-07',
    tag1: '#aiカメラ',
    tag2: '#位置追跡',
    tag3: '#車両追跡',
    tag4: '#デジタルツイン',

    text: [
        {
            head: 'AIカメラとは？',
            subHead: '',
            desc: 'AIカメラの位置追跡ソリューションは、組み込まれた人工知能技術を活用してカメラから受信した映像データを分析し、人や車の位置をリアルタイムで追跡します。これにより、セキュリティ、交通管理、ビデオモニタリングなど、さまざまな分野で活用できます。つまり、従来のカメラとは異なり、映像データを分析し処理して、より知的に動作することができます。',
            dot: ''
        },
        {
            head: "AIカメラの主な機能",
            subHead: "リアルタイム位置追跡",
            desc: "AIカメラは、人や車の動きを迅速かつ正確にリアルタイムで追跡し、彼らの現在の位置を識別して記録します。これにより、セキュリティシステムは迅速に潜在的な脅威を検出し、交通管理システムはリアルタイムで交通流をモニタリングし、状況に応じて対策を講じることができます。",
        },
        {
            head: "",
            subHead: "ビデオ分析と認識",
            desc: "AIカメラは、ビデオ分析と物体認識を通じて、ビデオ内のさまざまな物体を自動的に識別し分類できます。これにより、セキュリティシステムは侵入者や異常な行動を迅速に検出し、交通管理システムは交通状況を正確に分析して問題を予防し効率的に管理できます。",
        },
        {
            head: "",
            subHead: "複数カメラのサポート",
            desc: "AIカメラは複数のカメラを統合して広範囲を同時にモニタリングできます。これにより、広範囲のエリアをカバーし、さまざまな角度から情報を効果的に収集できます。さらに、複数カメラのサポートを活用することで、交差点や大規模建物などのさまざまな環境で、より強力なセキュリティおよび安全管理ソリューションを提供できます。",
        },
        {
            head: "",
            subHead: "カスタマイズ可能な機能",
            desc: "AIカメラは、ユーザーが必要に応じて追加したり設定したりする機能を提供します。カスタマイズ可能な機能は、さまざまな環境に柔軟に調整でき、特定の要件に応じて正確で効果的なモニタリングを提供できます。さらに、カスタマイズ可能な機能を使用すると、AIカメラの性能を最適化およびアップグレードできます。",
        },
        {
            video : video,
            link : '/jp/enterprise/construction/equipment',
            head: "AIカメラ技術",
            subHead: "ビデオ処理と分析",
            desc: "AIカメラは、カメラが撮影したビデオデータをリアルタイムで迅速かつ正確に分析します。これには、オブジェクト検出、追跡、分類など、最新の人工知能アルゴリズムが使用されます。これらの技術により、AIカメラはビデオデータを迅速に処理し、人や車などのオブジェクトを正確に識別して抽出できます。",
        },
        {
            head: "",
            subHead: "オブジェクト検出",
            desc: "AIカメラはビデオ映像内の人や車などのオブジェクトを迅速に検出します。最新のディープラーニング技術を活用して、オブジェクトを正確に識別し抽出できます。これにより、AIカメラはさまざまな環境でオブジェクトを迅速に検出し、これに基づいて追加の分析やアクションを取ることができます。",
        },
        {
            head: "",
            subHead: "追跡と位置特定",
            desc: "検出されたオブジェクトの動きをリアルタイムで追跡してその位置を特定します。これにより、AIカメラは人や車の動きを継続的に監視し、必要に応じて迅速に対応できます。さらに、追跡されたオブジェクトの移動経路を分析することで、追加のインサイトを提供することもできます。",
        },
        {
            head: "",
            subHead: "データ分析と保存",
            desc: "追跡されたデータは分析および保存されます。これにより、AIカメラは追跡されたオブジェクトの移動パターンや行動を分析し、必要に応じてデータベースに保存して後でレビューしたり、レポートを作成したりすることができます。これにより、セキュリティシステムはイベントの原因と進行を分析してより効果的に対応できます。",
        },
        {
            head: "応用分野",
            subHead: "セキュリティシステム",
            desc: "AIカメラは、建物内や駐車場などのセキュリティが必要な場所で人や車の移動をリアルタイムで追跡し、セキュリティを強化できます。さらに、このデータを分析することで、より正確なリスク予測と対応が可能です。",
        },
        {
            head: "",
            subHead: "交通管理",
            desc: "AIカメラを使用して、道路や交差点などの施設で交通状況をリアルタイムでモニタリングおよび管理できます。これにより、交通渋滞や事故の発生を事前に防ぎ、交通フローを最適化できます。",
        },
        {
            head: "",
            subHead: "商業利用",
            desc: "店舗やショッピングモールなどの商業施設では、AIカメラを使用して顧客の移動経路や行動を追跡し、店舗レイアウトを最適化したり、マーケティング戦略を立案したりできます。さらに、これにより顧客の嗜好を分析し改善することができます。",
        },
        {
            head: "",
            subHead: "スマートシティ",
            desc: "都市のさまざまな場所にAIカメラを設置することで、都市計画者は市民の移動パターンを理解し、都市のインフラを効率的に管理できます。これにより、都市の安全性と利便性を向上させ、スマートシティの構築に貢献できます。",
        },
        {
            head: 'まとめ',
            subHead: '',
            desc: 'AIを活用したカメラ位置追跡ソリューションは、人や車の位置をリアルタイムで追跡し、さまざまな分野で活用できる効率的な技術です。セキュリティ、交通管理、商業利用など、さまざまな産業分野でその活用可能性を確認し、より安全で効率的なサービスを提供できます。',
            dot: ''
        },
        {
            head: '',
            subHead: '',
            desc: '',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB端末を作業者や機器に取り付けて、リアルタイムの位置追跡を支援する製品です。',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/jp/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/jp/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/jp/enterprise/construction/equipment'
            }
        ]


    }


}
