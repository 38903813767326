
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const Card23Page = styled.div`

    padding : 128px 0;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        padding : 96px 0 64px 0;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 96px 0 64px 0;
    }

`;



export const BigCard = styled.div<{ w: number, img: string }>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;
    border-radius: 16px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));

    width : 100%;
    ${({ img }) => img && `background-image : url(${img});`}
    padding : 32px;

    aspect-ratio : 1232 / 600 ;

    
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        aspect-ratio : 912 / 500 ;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 24px;
        width : 100%;
        aspect-ratio : 361 / 360 ;
    }
`;


export const SmallCard = styled.div<{ w: number, img: string }>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;
    border-radius: 16px;
    ${({ w }) => w && `width : calc(50% - 16px);`} 
    ${({ img }) => img && `background-image : url(${img});`}
    padding : 32px;

    max-width : 600px;
    aspect-ratio : 600 / 600 ;
    
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        ${({ w }) => w && `width : calc(50% - 16px);`} 
        max-width: calc(50% - 16px);
        aspect-ratio : 440 / 500 ;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 24px;
        width : 100%;
        max-width : 100%;
        aspect-ratio : 361 / 360 ;
    }
`;


export const CardBox = styled.div`
    display : flex;
    flex-wrap: wrap;
    gap : 32px;
    
    
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        gap : 32px;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        flex-direction : column;
        gap : 16px;
    }
`;


export const CardTextBox = styled.div`
    display : flex;
    flex-direction : column;
    height : 100%;
`;

export const DescBox = styled.div`
    margin-top : auto;
`;

export const Icon = styled.img`
    width : 48px;
    height: 48px;
    display : flex;
    object-fit : contain;
`;


export const LableImage = styled.img<{ h: string }>`
    object-fit : cover;

    width : 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display : flex;

  ${({ h }) => h && `height : ${h}px;`}
`




export const VideoBoxBig = styled.div`

  position: relative;
  display : flex;
  overflow: hidden;
  
  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: darken;
  border-radius: 16px;
  border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));

  width : 100%;

  aspect-ratio : 1232 / 600 ;
  object-fit : cover;

  
  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
      aspect-ratio : 912 / 500 ;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
      width : 100%;
      aspect-ratio : 361 / 360 ;
  }

`



export const VideoBoxSmall = styled.div`

  position: relative;
  display : flex;
  overflow: hidden;
  object-fit : cover;
  
  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: darken;
  border-radius: 16px;
  border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
  
  max-width : 600px;
  aspect-ratio : 600 / 600 ;
  width : calc(50% - 16px);
  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
      max-width: calc(50% - 16px);
      width : calc(50% - 16px);
    aspect-ratio : 440 / 500 ;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
      width : 100%;
      max-width : 100%;
      aspect-ratio : 361 / 360 ;
  }
`

export const VideoPlay = styled.video`
  width: 100%;
  height: 100%;
  object-fit : cover;
  
`;



export const VideoTextBox = styled.div`
    display : flex;
    flex-direction : column;
    height : 100%;
    position : absolute;
    margin : 32px;
     ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        margin : 24px;
  }
`;

