import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from "@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4";

export default {

  helmet: `Geofence的功能`,
  desc: "地理围栏（Geofence）是将地理和围栏结合起来的技术，可以在现实世界的位置创建虚拟区域或虚拟围栏边界。",
  keywords: `Geofence, Geofencing, RTLS,室内定位,室内导航,定位解决方案,实时定位、资产追踪、智能办公、GPS和室内定位解决方案`,

  ogTitle: `Geofence是什么？ :: Orbro Blog`,
  ogDesc: "让我们来看看什么是Geofence，如何在现实生活中运作。",
  ogSite: `Geofence的功能`,

  ogUrl: 'https://orbro.io/cn/blog/geofence',
  ogKey: '',
  ogImg: "https://lh3.googleusercontent.com/fife/APg5EOZ2VWDCFEvSR2JhseSzS-2cQQnuMSFaM4obXlnNyqODBBoOLK1WTlmDiYajp8HYXztTLEp85J505ZV5DYOw4XK3f1-uBLTCKdymXB4nkl-0IPByDA2WVhTi9FpkH6to_X-pf_w4WnDhm_8vFh7GpJQoCH2jVpjtqLF8i-1MvlxeG-_wdQc3GSE5gyxieKJPBXZ8-h0_v0W275RZ1buY2zhtDYW-Je3wEqRDi5ZAsE_4zx3kHHfBjUgPf7tXIf74EEuIJb11hTQvdt9_n0xPGdgUFS1jN_qXOt0I693By1-jF0KtPqBaUdKQLY0gRf72BYwMTcCZRXQVo33POJ_kdczRYP9s1aOYSsbil2Xm21xAepKz9UeeByVEcX4Xj-yHE0JQh7B3-djLNtAGljujc4PvseRrPymDygPFx9o46B9PNRNGuFrORa_oJ1MLhFeqyW9xNQQu2a84bqh7v9ZOh714RBLDjAqUSDa0ZRXXWhKJqcMGjSWnjPyfFw7ILKzwJGKoiFmCCwFqlovHO3zTKM3Zem4TEk4g2msD6wwMQ3Ptsft6tcszn1HdpRRZ2l6Qq3ha5k8ewubMa06Q-aFkk-4apY3DsX-8Ey4b9kTIQqFNLLKHFNpFZdC5iPT-LYi56yuQUJPpJ7KF6RRexa3HfDTWeT8FNwnqNuDyCrbQ06Ix4Es5S1jXOW8ofiK2NplaeNg_1tca9QbIe-FDTOPqTeEHqMwCfDVncRYh0Z3e0x4Ywk6ebn2F0kccqqb6F7cVLGaVLOTsRs4rQ1r43TmZoG2elSGIsBntEp49Zn521JghIhCHcDIFjaYb9-RcgDF5oO7GIJT_YhTgPnWvKmGoBuesfZQaVSWgY5lhRHjULVAv-sg9X_EMWY3VhMkHAeGJlePyaD1531N_6-6UAs-QY7Ny66DVNen_QukuG1oAfQ29qDuvq9zJz2RrbafnZ2rantG3F_r2W0jvZiWuI-V_qiu4xBaQAzNklEly2t1aC7QDEW5qgzvbiS3hjC7U9KaRgsKFl_IhwaEjJMa_jv8JyNItI4P0XMjpADTrBPEgLFIbKGPiwtK2Ijjj91Cx-y2ElqS_-fmTQd63XX7c3Mhy4gK6SISrs24Zx9VdSKueoy7cBXubjcSj9QghIZsDvu3UpcZBq9EF8zYSf9-WGJIPj9qFf5_x4S8Ty_cD_XKF_Ic6bRMa_jZToGam_G5X94ag30kD3Wnrlw4AI3YFIHn_AYPVRXyz9aWY4QL1hiMrAHHLhGNd0ozBYqtmxSxga0ZI9UcIhlolXBSRe4D2JksdHn1ixx_WgBPto76Lt99eGq30wecQJbnSQlylNuqtkAvO1XBneOkYEEI0BT8eNwwz1R910N416ZXKPkgxujAs-FZHJoZxlp90d9Kv1UzKcKNKCbZgO6x3No9f-nkHzKtMmz5pVltLwkh5Hb7R-hcshSq2N4Vr1fpbMOpaJumw-fa-XiCszh7LwMXHgUO_8_071RRZfW0tBNV2OSKvdLNUv7yM_MrJ71sMx88Dbz0L1g=w1680-h1184",
  ogType: 'website',


  twitterCard: 'summary',
  twitterTitle: "Geofence的功能",
  twitterDesc: "我们来看一下什么是Geofence，如何在现实生活中运作。",


  title: '地理围栏（Geofence）是什么以及它是如何工作的？',
  date: '2023-05-01',
  tag1: '#地理围栏',
  tag2: '#资产追踪',
  tag3: '#RTLS',
  tag4: '#安全',

  // <br/>
  text: [
    {
      head: '地理围栏（Geofence）是什么以及它是如何工作的？',
      subHead: '',
      desc: '地理围栏（Geofence）是地理学（Geography）和栅栏（Fence）两个词的结合，是一种在现实世界中创建虚拟区域或虚拟围栏边界的技术。<br/>通过实时定位追踪服务（RTLS），可以监测人们是否穿越该区域。这在民用业务、医疗、军事、航空等大多数领域都得到了广泛应用。<br/>预计到2032年，地理围栏市场价值将从2022年的19亿美元增长到95亿美元，对未来的产业将创造巨大的价值。',
      dot: ''
    },
    {
      videoDesc: '<br/><br/>(利用地理围栏技术的建筑工地安全管理解决方案)',
      video: video,
      link : '/cn/enterprise/construction/equipment',
      head: '',
      subHead: '地理围栏实际上是如何工作的？',
      desc: '首先，定位服务设备首先设定特定边界和区域，然后开发人员在该特定位置周围设置虚拟边界线。然后，地理围栏与其他辅助技术（如Wi-Fi，蜂窝数据等网络或信标）相结合，以获取用户的实时位置和进出信息。移动设备携带位置信息进入指定区域时，根据条件执行预先设定的特定操作，例如发送文本消息、电子邮箱、应用程序通知等。<br/>通常，此过程通过移动设备进行，用户可以拒绝或单独设置应用程序位置的访问。<br/><br/>就位置服务使用而言，首要考虑是用户同意。',
      dot: ''
    },
    {
      head: '',
      subHead: '地理围栏在哪些地方使用？',
      desc: '',
      dot: {
        a: ' • <strong>资产</strong>：通过将地理围栏技术安装在资产（如车辆、拖拉机、计算机等）上，可以跟踪该资产何时离开地理围栏位置。',
        b: ' • <strong>人员资源</strong>：可以准确地追踪员工在公司停留的时间以及其上下班情况。对于现场工人，可以更有效地管理进出控制区域。',
        c: ' • <strong>商店</strong>：当游客经过商店时，可以发送促销消息以促进访问。',
        d: ' • <strong>家庭物联网</strong>：当智能手机用户离开地理围栏设定的房屋时，温度控制设备将被关闭。',
        e: ' • <strong>军事</strong>：基于GPS等位置信息技术的地理围栏技术可提供服务，限制危险物体或飞行接近机场和军事区域。',
        f: ' • <strong>机场</strong>：可以接收与附近设施相关的信息。可以提供免费Wi-Fi服务和机场周边指南服务。',
      }
    },
    {
      head: '地理围栏，有何帮助？',
      subHead: '1. 安全与保安',
      desc: '在建筑工地、医院、军事设施等对安全或保安敏感的区域设定并监测人员进出，从而预防和迅速处理安全事故和事件。',
      dot: ''
    },
    {
      head: '',
      subHead: '2. 工作效率',
      desc: '地理围栏技术与工人和工作场所的安全和效率直接相关。例如，对于在公司工作的员工或在工作现场工作的工人，监测其工作状态。<br/>当他们在规定时间内离开工作地点或进入不得进入的区域时，管理员可以采取适当的措施，预防潜在问题。',
      dot: ''
    },
    {
      head: '',
      subHead: '3. 个性化客户体验',
      desc: '通过向穿过特定区域的人提供优惠券和优惠活动来个性化客户体验。<br/>这样一来，可以节省以前浪费的营销成本。近期，韩国国内多家信用卡公司也利用地理围栏技术，当用户接近百货店时，就向该区域的人发送通知消息，这也是利用地理围栏技术的好处。',
      dot: ''
    },

  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: "将UWB终端机安装在工作者或设备上，帮助实时追踪位置的产品。",
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/cn/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/cn/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/cn/enterprise/logistics/asset'
      }
    ]


  }


}