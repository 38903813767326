import { IconOsOff, IconOsOn } from '@assets/images/main/icon';
import { monitoringOn, monitoringOff, controlOn, controlOff, displayOn, displayOff, cradleOn, cradleOff, idCardOn, idCardOff } from '@assets/images/main/index';




export default {

  banner: {
    title: 'Innovation of Access Systems',
    desc: `Innovate your access systems through the introduction of ORBRO's access systems.`,

    schemeTitle: 'Choose an access system for you.',
    schemeDesc: 'Need help deciding?',
    schemeDesc2: '<br/>Contact us at and an access expert will help you.',
    phone: '(+82)1522-9928',
  },

  mainPage: {
    product: {
      title: 'Learn about Product Configuration',
      desc: 'Check out the innovative access system product configuration.',
      asset: {
        title: 'Mobile Access',
        desc: 'The start of the access system that enters with mobile',
      },
      worker: {
        title: 'Automatic Door Access',
        desc: 'The start of the access system specialized for automatic doors<br/>Applicable to all access doors',
      }
    }
  },

  bannerData: [
    {
      icon: monitoringOn,
      iconInv: monitoringOff,
      image: '',
      iconText: 'Safety Bell',
      sub: '',
      link: '/en/iot/bell'
    },
    {
      icon: controlOn,
      iconInv: controlOff,
      image: '',
      iconText: 'Power Control',
      sub: '',
      link: '/en/iot/energy'
    },
    {
      icon: displayOn,
      iconInv: displayOff,
      image: '',
      iconText: 'E-Ink<br/>Display',
      sub: '',
      link: '/en/iot/display'
    },
    {
      icon: cradleOn,
      iconInv: cradleOff,
      image: '',
      iconText: 'Mobile Security Device',
      sub: '',
      link: '/en/iot/cradle'
    },
    {
      icon: idCardOn,
      iconInv: idCardOff,
      image: '',
      iconText: 'ID<br/>Card',
      sub: '',
      link: '/en/iot/id'
    },
    {
      icon: IconOsOn,
      iconInv: IconOsOff,
      image: '',
      iconText: 'ORBRO OS',
      sub: 'New',
      link: '/en/iot/os'
    },



  ],



}