export default {
  titleBanner: {
    subtitle: 'TDoA | Time Difference of Arrival',
    title: 'TDoA 位置測位技術',
    description: '*TDoA (Time Difference of Arrival) は、位置測位技術の一種であり、送信機からの信号到着時間の差を測定して受信機の位置を計算する方法です。<br/>複数の受信機が1つの送信機から信号を受信すると、受信機間の信号到着時間の差を測定して送信機の位置を計算することができます。<br/>この際、受信機は正確な時刻同期を行い、時刻同期が正確であるほど位置測定の精度が高くなります。'
  },
  definition: {
    title: 'TDoA 技術とは何ですか？',
    text: `TDoAは、無線信号の到着時間の差に基づいて位置を決定する位置決定手法です。TDoAはリアルタイム位置システム（RTLS）で使用され、トラッキングタグなどの測位対象の位置をリアルタイムで正確に計算します。<br/><br/>例えば、Orbroのリアルタイム位置測位システムでは、TDoAはUWBまたはチップ（RTLS）RTLSアンカーから測位タグへの位置データを収集し、測位対象のリアルタイム位置を正確に計算します。添付された図を見ると、RTLSタグ（T）は一定の間隔でRF信号、つまり位置ブリンクを継続的に送信します。送信タグの通信範囲内にある複数のRTLSアンカーは、この位置ブリンクを受信し、到着時間（ToA）をタイムスタンプとして記録します。その後、アンカーはこのタイムスタンプを含む位置データをOrbro Edgeの位置エンジンソフトウェアに送信します。Orbro Edgeは、これらの受信位置ブリンクの到着時間差（例：ToA1-ToA2）を分析してTDoAを計算します。`
  },
  sync: {
    title: '仮想アンカー同期',
    text: `正常に動作するためには、固定アンカーは正確に同期されたクロックで実行できるように正確に同期される必要があります。Orbro Edgeのリアルタイム位置測位システムは、無線通信ベースの時刻同期によってこれを実現します。計算されたTDoAは、ハイパーボラを使って表示され、可能なタグの位置を示します。交差する3つの関連するハイパーボラは、タグの位置を説明します。信頼性のある位置推定のためには、少なくとも4つのアンカーを使用することが推奨されます。<br/><br/>このような結果として得られる位置座標は、マップ上でタグの位置を可視化したり、特定のアプリケーションに応じて異なる目的で使用することができます。`
  },
  advantage: {
    title: 'TDoAの主な利点',
    items: {
      accuracy: {
        title: '高い精度',
        description: 'TDoAは、従来のRSSIベースの位置測定などよりも高い精度の位置結果を保証します。'
      },
      wait: {
        title: '低い待ち時間',
        description: 'TDoAを使用すると、RTLSは数千の同時受信位置ブリンクを処理でき、低遅延と高いパフォーマンス信頼性を保証します。'
      },
      expansion: {
        title: 'エンタープライズ拡張性',
        description: 'TDoAベースの計算は、エンタープライズ要件を満たすスケーラブルなRTLS展開を可能にします。'
      }
    }
  },
}
