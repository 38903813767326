import { FlexStyle, Vertical } from "@assets/styled/flex";
import { StyledDisplay, StyledLabel, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";

export const StyledTechRequest = styled.div`
  width: 100%;
  padding: 64px 0;
`;

export const StyledTechRequestBanner = styled(Vertical)<{img : string}>`
  width: 100%;
  height: 400px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  background-color: #111;
  background-size: auto 100%;
  background-repeat: no-repeat;

  ${({img})=> img&& `background-image : url(${img});`}
`;

export const StyledTechRequestBannerTitle = styled(StyledDisplay)`
  color: ${({ theme }) => theme.BACKGROUND_PRIMARY};
  text-align: center;
`;    

export const StyledTechRequestBannerDescription = styled(StyledParagraph)`
  color: ${({ theme }) => theme.BACKGROUND_PRIMARY};
  text-align: center;
  margin-top: 16px;
`;

export const StyledTechRequestBannerButton = styled(FlexStyle)`
  padding: 16px 24px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.BACKGROUND_PRIMARY};
  user-select: none;
  margin-top: 32px;
`;  

export const StyledTechRequestBannerButtonText = styled(StyledLabel)`

`; 